<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">TDS Report</div>
                </div>
                <div class="ibox-body">

                    <div class="tab-pane fade show active" id="tab-1">
                        <br>

                        <!-- <div class="row" style="margin-left: 2%;margin-right: 2%;">
                            <div class="col-4 text-left">
                                <h6>From Date : <span style="color: red;">*</span> </h6>
                            </div>
                            <div class="col-4">
                                <input type="date" class="form-control" [(ngModel)]="obj['from_dt']">
                            </div></div><br> <div class="row" style="margin-left: 2%;margin-right: 2%;">
                            <div class="col-4 text-left">
                                <h6>To Date : <span style="color: red;">*</span> </h6>
                            </div>
                            <div class="col-4">
                                <input type="date" class="form-control" [(ngModel)]="obj['to_dt']">

                            </div>
                        </div>
                        <br>
                        <div class="row" style="margin-left: 2%;margin-right: 2%;">
                            <div class="col-4 text-left">
                                <h6>Select Party :</h6>
                            </div>
                            <div class="col-4">
                                <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id" [multiple]="false" placeholder="Select Party" [(ngModel)]="obj['le_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="le_id">
                                </ng-select>
                            </div>

                        </div>
                        <br>
                        <div class="row">
                            <div class="col-6 text-right">
                                <button class="btn btn-primary" (click)="getreportoutput()">Submit</button>
                            </div>

                        </div>
                        <br> <hr/>-->
                        <form name="form" (ngSubmit)="f.form.valid && getreportoutput()" #f="ngForm">
                            <div class="row" style="margin-left: 2%;margin-right: 2;">
                                <div class="col-4 text-left">
                                    <h6>From Date : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">

                                    <input type="date" class="form-control" [(ngModel)]="obj['from_dt']"
                                        name="from_dt1" #from_dt1="ngModel"
                                        [ngClass]="{ 'is-invalid': f.submitted && from_dt1.invalid }" required>

                                    <!--------------------------------------------- validation Start ------------------- -->

                                    <!-- border color green -->
                                    <div [class.border_green]="from_dt1.dirty && from_dt1.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f.submitted && from_dt1.invalid" class="error-message">
                                        <div *ngIf="from_dt1.errors?.['required']">Please Fill From Date </div>
                                    </div>

                                    <!--------------------------------------------- validation End ------------------- -->

                                </div>
                            </div><br>
                            <div class="row" style="margin-left: 2%;margin-right: 2;">
                                <div class="col-4 text-left">
                                    <h6>To Date : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="obj['to_dt']" name="to_dt"
                                        #to_dt2="ngModel" [ngClass]="{ 'is-invalid': f.submitted && to_dt2.invalid }"
                                        required>

                                    <!--------------------------------------------- validation Start ------------------- -->

                                    <!-- border color green -->
                                    <div [class.border_green]="to_dt2.dirty && to_dt2.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f.submitted && to_dt2.invalid" class="error-message">
                                        <div *ngIf="to_dt2.errors?.['required']">Please Fill To Date </div>
                                    </div>


                                      <!-- If from date is greater than to date  -->
                                      <div *ngIf="from_dt1.value > to_dt2.value" class="error-message">
                                        Please Select Correct Date
                                    </div>
                                    <!--------------------------------------------- validation End ------------------- -->

                                </div>
                            </div>
                            <br>
                            <div class="row" style="margin-left: 2%;margin-right: 2;">
                                <div class="col-4 text-left">
                                    <h6>Select Party :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                                        [multiple]="false" placeholder="Select Party" [(ngModel)]="obj['le_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="le_id">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6 text-right">
                                    <button class="btn btn-success d-none" id="output" type="reset">Reset</button>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>

                            </div>
                            <br>
                            <hr>
                        </form>


                        <div class="row">

                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="print()">Print</button> &nbsp;&nbsp;

                                <button class="btn btn-success" (click)="export()">Export</button> &nbsp;&nbsp;
                            </div>


                        </div>

                        <br>
                        <div id="e">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <h6>{{acount_name}}</h6>
                                </div>

                            </div>
                            <br>
                            <!-- <div class="row">
                                    <div class="col-12 text-center">
                                        Equity ({{obj['acc_desc']}})
                                    </div>
                                </div>
                                <br> -->
                            <div class="row">
                                <!--  <div class="col-2 text-right">
                                        <h6>Financial Year :</h6>
                                    </div>
                                    <div class="col-2">{{res_obj['fin_year']}}</div> -->
                                <div class="col-2 text-right">
                                    <h6>From :</h6>
                                </div>
                                <div class="col-2" *ngIf="obj['from_dt']">{{mainService.dateFormatChange(obj['from_dt'])}}</div>
                                <div class="col-2 text-right">
                                    <h6>To :</h6>
                                </div>
                                <div class="col-2" *ngIf="obj['to_dt']">{{mainService.dateFormatChange(obj['to_dt'])}}</div>

                            </div>
                            <br>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>SNO</th>
                                        <th>Date of Deduction</th>
                                        <th>Party Name</th>
                                        <th>Section of IT Act</th>
                                        <!-- <th>Rate of TDS</th> -->
                                        <th>PAN of Party</th>
                                        <th>Gross Amount (Event Value)</th>
                                        <th>TDS amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of reportdata; let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item['ded_date'] | date:"dd/MM/yyyy"}}</td>
                                        <td>{{item['party_name']}}</td>
                                        <td>{{item['it_act']}}</td>
                                        <!-- <td style="text-align:right">{{item['rate']}}%</td> -->
                                        <td style="text-align:right">{{item['pan']}}</td>
                                        <td style="text-align:right">{{item['amount']}}</td>
                                        <td style="text-align:right">{{item['deduction_amount']}}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>





                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
