<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Mutation Rule</div>
                </div>

                <div class="ibox-body">

                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                Mutation Rule List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>
                                Create Mutation Rule</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" data-toggle="tab"><i class="ti-settings"></i>
                                Update Mutatation Rule</a>
                        </li>
                    </ul>
                    <br>
                    <div class="tab-content">

                        <div class="tab-pane fade show active" *ngIf="list_flag">

                            <!-- table 1 -->
                            <div class="example-container">

                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)"
                                                placeholder="Search">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-8 text-right">
                                        <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                    </div>

                                </div>

                                <table mat-table [dataSource]="dataSource2" matSort style="width: 100%">
                                    <!-- id -->
                                    <ng-container matColumnDef="mutation_rule_id">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <h6>Id</h6>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                            class="right">
                                            {{element.mutation_rule_id}}
                                        </td>
                                    </ng-container>

                                    <!-- party_id Column -->
                                    <ng-container matColumnDef="mutation_application_fee">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Application Fee </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.mutation_application_fee}}
                                        </td>
                                    </ng-container>

                                    <!-- property_type Column -->
                                    <ng-container matColumnDef="advertisement_fee">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Advertisement Fee</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.advertisement_fee}}
                                        </td>
                                    </ng-container>
                                    <!--  -->
                                    <ng-container matColumnDef="mutation_charge">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Mutation Charge</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.mutation_charge}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="application_disposal_time">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Appl Disposal Time</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.application_disposal_time}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="application_disposal_str">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Appl Disposal Str</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.application_disposal_str}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="time_limit_for_objection">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Limit for Objection </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.time_limit_for_objection}}
                                        </td>
                                    </ng-container>
                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Action</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                            class="right">
                                            <!-- <button class="btn btn-outline-primary " (click)="deleteData(element,i)">
                                                Update</button> &#160; -->
                                                <button class="btn btn-outline-danger " (click)="deleteData(element,i)">
                                                    Delete</button>
                                        </td>

                                    </ng-container>
                                    <!--  -->
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>

                                <!-- mat table  -->
                            </div>
                        </div>
                        <!-- table 2 -->
                        <br>
                        <!-- box -1 -->
                        <!--  -->
                        <div *ngIf="create_flag">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    Mutation Application Fee (Rs) :

                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" class="form-control" placeholder="Fee for Mutation Application"
                                        [(ngModel)]="obj['mutation_application_fee']">
                                </div>
                            </div>
                            <br>
                            <!-- btn -->
                            <!--  -->
                            <div class="row mt-2 ">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    Advertisement Fee (Rs) :

                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" class="form-control"
                                        placeholder="Fee to publish notice in news paper"
                                        [(ngModel)]="obj['advertisement_fee']">
                                </div>
                            </div>
                            <br>
                            <!-- btn -->
                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    Mutation Charge (Rs) :

                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" class="form-control" placeholder="Percentage Value"
                                        [(ngModel)]="obj['mutation_charge']">
                                </div>
                                <h6 class="pt-2">

                                    of
                                </h6>
                                <div class="col-3 text-left">
                                    <ng-select [items]="price" bindLabel="name" bindValue="name"
                                        [(ngModel)]="obj['price_type']" placeholder="Salable Price">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <!-- btn -->
                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    Mutation Application Disposal Time (Days):

                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" class="form-control" placeholder="No of Days"
                                        [(ngModel)]="obj['application_disposal_time']">
                                </div>
                            </div>
                            <br>
                            <!-- btn -->
                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    Application Disposal Time Start from :

                                </div>
                                <div class="col-3 text-left">
                                    <input type="date" class="form-control"
                                        [(ngModel)]="obj['application_disposal_str']"
                                        placeholder="Mutation Application Date">
                                </div>
                            </div>
                            <br>
                            <!-- btn -->
                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    Time Limit for Objections (Days) :
                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" class="form-control" placeholder="No of Days"
                                        [(ngModel)]="obj['time_limit_for_objection']">
                                </div>
                            </div>
                            <br>
                            <!-- btn -->
                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    Effective From Date :
                                </div>
                                <div class="col-3 text-left">
                                    <input type="date" class="form-control" placeholder="DD/MM/YY"
                                        [(ngModel)]="obj['effective_date']">
                                </div>
                            </div>
                            <br>
                            <!-- btn -->
                            <!-- box1 end  -->
                            <!-- box  - 2  -->
                            <div class="row mt-3">
                                <div class="col text-right">
                                    Document Purpose :
                                </div>
                                <div class="col-3 text-left ">
                                    <ng-select [items]="this.mainService.codeValueTechObj['PROPMP01']" bindLabel="code"
                                        bindValue="value" placeholder="Death / Gift / Sale" [multiple]="false"
                                        [(ngModel)]="docobj['document_purpose']">
                                    </ng-select>
                                </div>
                                <!-- <div class="col-1">
                                </div> -->
                                <div class="col text-right">
                                    Document Type :
                                </div>
                                <div class="col-3 text-left ">
                                    <ng-select [items]="document_type" bindLabel="name" bindValue="name"
                                        bindValue="name" [multiple]="false" [(ngModel)]="docobj['document_type']"
                                        placeholder="General / Applicant
                                    ">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <!-- 2nd row  -->
                            <div class="row mt-3">
                                <div class="col text-right">
                                    Document Name :
                                </div>
                                <div class="col-3 text-left">
                                    <ng-select [items]="this.mainService.codeValueTechObj['PROPMP02']" bindLabel="code"
                                        bindValue="value" [multiple]="false" [(ngModel)]="docobj['document_name']"
                                        placeholder="List of All Documents ">
                                    </ng-select>
                                </div>
                                <!-- <div class="col-1">
                                </div> -->
                                <div class="col text-right ">
                                    Document Option :
                                </div>
                                <div class="col-3 text-left">
                                    <ng-select [items]="document_option" bindLabel="name" bindValue="name"
                                        [multiple]="false" [(ngModel)]="docobj['document_option']" placeholder="Mandatory / Optional
                                    ">
                                    </ng-select>
                                </div>
                            </div>
                            <!-- box 2end  -->
                            <br>
                            <!-- ADD btn  -->
                            <div class="d-flex justify-content-center">
                                <button class="btn b btn-outline-primary mr-2" (click)="add()">ADD</button>
                            </div>
                            <!-- add btn end  -->
                            <br>
                            <!-- table start -->
                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
                                    <!-- id -->
                                    <ng-container matColumnDef="ID">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <h6>Document Pupose</h6>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                            class="right">
                                            {{element.document_purpose}}
                                        </td>
                                    </ng-container>
                                    <!-- party_id Column -->
                                    <ng-container matColumnDef="Property_Category">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Document Type </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.document_type}}
                                        </td>
                                    </ng-container>
                                    <!-- property_type Column -->
                                    <ng-container matColumnDef="Property_Type">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Document Name</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.document_name}}
                                        </td>
                                    </ng-container>
                                    <!--  -->
                                    <ng-container matColumnDef="Min_Amount">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Document Option</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.document_option}}
                                        </td>
                                    </ng-container>
                                    <!-- Weight Column -->
                                    <ng-container matColumnDef="Action">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Action</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                            class="right">
                                            <button class="btn btn-outline-danger but-w mt-1" (click)="delete(i)">
                                                Delete</button>
                                        </td>
                                    </ng-container>
                                    <!--  -->
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
                                <!-- mat table  -->
                            </div>
                            <br>
                            <!-- SUBMIT btn  -->
                            <div class="d-flex justify-content-center">
                                <button class="btn b btn-outline-primary mr-2" (click)="submit()">SUBMIT</button>
                            </div>
                            <!-- add btn end  -->
                            <!-- table end -->
                        </div>
                        <div class="tab-pane fade show " id="tab-2">

                            <br>

                            <!-- box -1 -->
                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    <h6>Mutation Application Fee (Rs):
                                    </h6>
                                </div>
                                <div class="col-2 text-left">
                                    <input type="number" class="form-control" placeholder="Fee for Mutation Application"
                                        [(ngModel)]="updateobj['mutation_application_fee']">
                                </div>
                            </div>
                            <!-- btn -->

                            <!--  -->
                            <div class="row mt-2 ">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    <h6>Advertisement Fee (Rs):
                                    </h6>
                                </div>
                                <div class="col-2 text-left">
                                    <input type="number" class="form-control"
                                        placeholder="Fee to publish notice in news paper"
                                        [(ngModel)]="updateobj['advertisement_fee']">
                                </div>
                            </div>
                            <!-- btn -->



                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    <h6>Mutation Charge (Rs):
                                    </h6>
                                </div>
                                <div class="col-2 text-left">
                                    <input type="number" class="form-control" placeholder="Percentage Value"
                                        [(ngModel)]="updateobj['mutation_charge']">
                                </div>
                                <h6 class="pt-2">

                                    of
                                </h6>
                                <div class="col-2 text-left">
                                    <ng-select [items]="price" bindLabel="name" bindValue="name"
                                        [(ngModel)]="updateobj['price_type']" placeholder="Salable Price">
                                    </ng-select>
                                </div>
                            </div>
                            <!-- btn -->


                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    <h6>Mutation Application Disposal Time(Days):
                                    </h6>
                                </div>
                                <div class="col-2 text-left">
                                    <input type="number" class="form-control" placeholder="No of Days"
                                        [(ngModel)]="updateobj['application_disposal_time']">
                                </div>
                            </div>
                            <!-- btn -->

                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    <h6>Application Disposal Time Start from:
                                    </h6>
                                </div>
                                <div class="col-2 text-left">
                                    <input type="date" class="form-control"
                                        [(ngModel)]="updateobj['application_disposal_str']"
                                        placeholder="Mutation Application Date">
                                </div>

                            </div>
                            <!-- btn -->


                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    <h6>Time Limit for Objections (Days):
                                    </h6>
                                </div>
                                <div class="col-2 text-left">
                                    <input type="number" class="form-control" placeholder="No of Days"
                                        [(ngModel)]="updateobj['time_limit_for_objection']">
                                </div>
                            </div>
                            <!-- btn -->

                            <!--  -->
                            <div class="row mt-2">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    <h6>Effective From Date:
                                    </h6>
                                </div>
                                <div class="col-2 text-left">

                                    <input type="date" class="form-control" placeholder="DD/MM/YY"
                                        [(ngModel)]="updateobj['effective_date']">
                                </div>
                            </div>
                            <!-- btn -->

                            <!-- box1 end  -->
                            <br>
                            <!-- box  - 2  -->
                            <div class="row mt-3">
                                <div class="col text-right">
                                    Document Purpose:
                                </div>

                                <div class="col-3 text-left ">
                                    <ng-select [items]="this.mainService.codeValueTechObj['PROPMP01']" bindLabel="code"
                                        bindValue="value" placeholder="Death / Gift / Sale" [multiple]="false"
                                        [(ngModel)]="updatedocobj['document_purpose']">
                                    </ng-select>
                                </div>

                                <div class="col-1">

                                </div>
                                <div class="col text-right">
                                    Document Type:
                                </div>

                                <div class="col-3 text-left ">
                                    <ng-select [items]="document_type" bindLabel="name" bindValue="name"
                                        bindValue="name" [multiple]="false" [(ngModel)]="updatedocobj['document_type']"
                                        placeholder="General / Applicant
                ">
                                    </ng-select>
                                </div>

                            </div>

                            <!-- 2nd row  -->
                            <div class="row mt-3">
                                <div class="col text-right">
                                    Document Name:
                                </div>

                                <div class="col-3 text-left">
                                    <ng-select [items]="this.mainService.codeValueTechObj['PROPMP02']" bindLabel="code"
                                        bindValue="value" [multiple]="false" [(ngModel)]="updatedocobj['document_name']"
                                        placeholder="List of All Documents ">
                                    </ng-select>
                                </div>

                                <div class="col-1">

                                </div>
                                <div class="col text-right ">
                                    Document Option: uuuu
                                </div>

                                <div class="col-3 text-left">
                                    <ng-select [items]="document_option" bindLabel="name" bindValue="name"
                                        [multiple]="false" [(ngModel)]="updatedocobj['document_option']" placeholder="Mandatory / Optional
                ">
                                    </ng-select>
                                </div>

                            </div>
                            <!-- box 2end  -->
                            <br>
                            <!-- ADD btn  -->
                            <div class="d-flex justify-content-center">
                                <button class="btn b btn-outline-primary mr-2" >ADD</button>
                            </div>
                            <!-- add btn end  -->

                            <!-- table start -->
                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource2" matSort style="width: 100%">
                                    <!-- id -->
                                    <ng-container matColumnDef="ID">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <h6>Document Pupose</h6>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                            class="right">
                                            {{element.document_purpose}}
                                        </td>
                                    </ng-container>

                                    <!-- party_id Column -->
                                    <ng-container matColumnDef="Property_Category">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Document Type </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.document_type}}
                                        </td>
                                    </ng-container>

                                    <!-- property_type Column -->
                                    <ng-container matColumnDef="Property_Type">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Document Name</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.document_name}}
                                        </td>
                                    </ng-container>
                                    <!--  -->
                                    <ng-container matColumnDef="Min_Amount">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Document Option</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.document_option}}
                                        </td>
                                    </ng-container>

                                    <!-- Weight Column -->


                                    <ng-container matColumnDef="Action">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            <b>Action</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                            class="right">
                                            <button class="btn btn-outline-danger but-w mt-1" (click)="delete(i)">
                                                Delete</button>

                                        </td>
                                    </ng-container>
                                    <!--  -->
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>

                                <!-- mat table  -->
                            </div>
                            <br>


                            <!-- SUBMIT btn  -->
                            <div class="d-flex justify-content-center">
                                <button class="btn b btn-outline-primary mr-2" >UPDATE</button>
                            </div>
                            <!-- add btn end  -->



                            <!-- table end -->

                        </div>



                    </div>
                </div>





                <hr class="hrline">
                <br>
                <br>
            </div>
        </div>

        <br>
    </div>
</div>
