import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ComplainServiceService } from '../../service/complain-service.service';
import { SettingService } from '../../service/setting.service'
import { MainService as md_mainService } from '../../../md/service/main.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-recovery-calculation',
  templateUrl: './recovery-calculation.component.html',
  styleUrls: ['./recovery-calculation.component.css']
})
export class RecoveryCalculationComponent implements OnInit {

  constructor(private settingService: SettingService, private complainServiceService: ComplainServiceService,
    private mainService: md_mainService, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer,) { }
  b_acct_id:any;
  current_user:any;
  ebillUser:any;
  uploader1:any;
  selectedData:any = {}
  httpUrl:any
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.current_user = this.ebillUser.party_name;
    this.uploader1 = new FileUploader({ url: this.mainService.httpUrl, itemAlias: 'image' });
    this.uploader1.onAfterAddingFile = (file:any) => { file.withCredentials = false; };
    this.selectedData['demolition'] = "Demolition";
    this.httpUrl = this.mainService.httpUrl;
    await this.getInspectionData();
  }

  inspection_data :any= []
  async getInspectionData() {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.complainServiceService.getInspectionData(obj);
    if (resp['error'] == false) {
      this.inspection_data = []
      this.inspection_data = resp['data'];

    } else {
      Swal.fire('Error', 'Error While Getting Court Data', 'error');
    }
  }
  court_data = []
  async getOrderDetails() {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['case_id'] = this.selectedData['case_id']
    let resp = await this.settingService.getOrderDataOnCaseID(obj);
    if (resp['error'] == false) {
      this.court_data = []
      this.court_data = resp['data'];
      console.log(this.court_data)
    } else {
      Swal.fire('Error', 'Error While Getting Court Data', 'error');
    }
  }


  selectedFile1: any = null;
  public imagePath1:any;
  onFileUpload1(event:any, files:any) {
    this.viewButton = false;
    this.selectedFile1 = null;
    this.selectedFile1 = <File>event.target.files[0];
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath1 = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };
  }


  async submit() {
    this.spinner.show()
    this.selectedData['order_id'] = this.court_data[0]['co_ord_id'];
    this.selectedData['b_acct_id'] = this.b_acct_id;
    this.selectedData['user_id'] = this.ebillUser.user_id;
    let resp2 = await this.settingService.insertRecoveryCalculationData(this.selectedData)
    if (resp2['error'] == false) {
      this.spinner.hide()
      this.selectedData = new Object();
      this.selectedData['demolition'] = "Demolition";
      this.selectedFile1 = null;
      Swal.fire('Success', 'Success', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occurred', 'error');
    }
  }


  docUrl:any;
  viewButton: boolean = false;


  async upload() {
    if (this.selectedFile1 != null) {
      this.spinner.show();
      const formData = new FormData();
      formData.append('image', this.selectedFile1, this.selectedFile1.name);
      const obj:any = new Object();
      obj['b_acct_id'] = this.b_acct_id;
      obj['file_name'] = this.uploader1.queue[this.uploader1.queue.length-1].some.name;
      obj['doc_type'] = 'RECOVERYCALCULATION';
      obj['life_cycle_status'] = 'ACTIVE';
      obj['create_user_id'] = this.ebillUser.user_id;
      obj['complain_id'] = this.court_data[0]['upload_id'] // form arr_upld_order
      const params = JSON.stringify(obj);
      this.uploader1.queue[this.uploader1.queue.length-1].url = this.httpUrl + '/icms/upload/uploadRecoveryDoc' + params;
      this.uploader1.queue[this.uploader1.queue.length-1].upload();
      this.uploader1.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        let resp1 = await JSON.parse(response);
        console.log(resp1)
        if (resp1['error'] == false) {
          this.spinner.hide();
          this.viewButton = true;
          Swal.fire('Success', "Uploaded", 'success');
        } else {
          this.spinner.hide();
          Swal.fire('Error', "Error Occurred While Uploading", 'error');
        }
        console.log(107, resp1);
      }
    }
  }


  async viewDoc() {
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_type'] = 'RECOVERYCALCULATION';
    obj['file_name'] = this.uploader1.queue[this.uploader1.queue.length-1].some.name;
    obj['complain_id'] = this.court_data[0]['upload_id']
    const res1 = await this.settingService.getDocumentDetails(JSON.stringify(obj));
    if (res1) {
      console.log(res1);
    }
    obj["doc_id"] = res1['data'][0]["doc_id"];
    this.spinner.show()
    if (obj["doc_id"] != undefined) {
      let res = await this.settingService.viewDefendentImage(obj);
      if (res) {
        var docname = obj["file_name"];
        var ext = docname.split('.');
        if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
          const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
          this.docUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          this.spinner.hide()
        } else if (ext[1].toLowerCase() == 'xlsx') {
          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {
          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        } else {
          let file = new Blob([res], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        }
      } else {
        this.spinner.hide()
      }
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'No document Uploaded !', 'error');
    }
  }
}
