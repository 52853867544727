import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-lease',
  templateUrl: './property-lease.component.html',
  styleUrls: ['./property-lease.component.css']
})
export class PropertyLeaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
