<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Users</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" (click)="refresh()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> User List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" (click)="refresh()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i> Create User
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-7-3" data-toggle="tab"><i class="ti-settings"
                                    aria-hidden="true"> </i> Update User
                            </a>
                        </li> -->

                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-7 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Map User</button>
                                </div>


                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>



                                    <ng-container matColumnDef="user_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>User ID


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.user_id }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>User Name


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Phone No


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.phone_no }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="email">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Email
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.contact_email}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="role_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Role
                                            Description
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.role_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button (click)="modalOpen(element)"
                                                class="btn btn-outline-danger">Delete</button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>

                        <div *ngIf="create_flag">
                            <div class="card">
                                <div style="margin: 5%;">
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-primary">Open
                                                List</button>
                                        </div>
                                    </div>

                                    <!-- <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        Enter Mobile Number : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-3">
                                    <input type="email" placeholder="Enter Mobile Number" class="form-control" [(ngModel)]="Obj['credential']">
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button (click)="search()" class="btn btn-primary">Search</button>
                                </div>
                            </div>
                            <br>
                            <hr> -->
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6 style="margin-left: 2%;">Select User : <span style="color: red;">
                                                    *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="allSearchableEmp" bindLabel="desc" bindValue="user_id"
                                                [multiple]="false" placeholder="Select User"
                                                [(ngModel)]="Obj['user_id']" [selectableGroup]="true"
                                                (change)="setDetails()" [selectableGroupAsModel]="true"
                                                name="chart_of_account0">res
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6 style="margin-left: 2%;"> Select Role : <span
                                                    style="color: red;">*</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [multiple]="false" placeholder="Select Role"
                                                [items]="current_role" bindLabel="role_name" bindValue="role_cd"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                aria-placeholder="Select Role" [closeOnSelect]="true" name="hierarchy"
                                                [(ngModel)]="Obj['role_cd']">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>


                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-primary" (click)="submit()">Submit</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="update_flag">
                            <div class="card">
                                <div style="margin: 5%;">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-primary">Open
                                                List</button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <h6 style="margin-left: 2%;">
                                                Enter Email :</h6>
                                        </div>
                                        <div class="col-4">
                                            <input type="email" placeholder="Enter email" class="form-control"
                                                [(ngModel)]="Obj['email']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4">
                                            <h6 style="margin-left: 2%;"> Enter Phone Number : <span
                                                    style="color: red;">*</span></h6>
                                        </div>
                                        <div class="col-4">
                                            <input type="number" placeholder="Enter Phone Number" class="form-control"
                                                [(ngModel)]="Obj['phone_no']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-primary" (click)="update()">Update</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>Loading....</ngx-spinner>
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">All Roles</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="card">
                    <div style="margin: 2%;">
                        <table class="table table-borederd" style="width: 80%; margin-left: 10%;">
                            <thead>
                                <tr>
                                    <th>S No.</th>
                                    <th>Role Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of single_users_role,let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{item['role_desc']}}</td>
                                    <td>
                                        <button class="btn btn-outline-danger"
                                            *ngIf="single_users_role.length==1 && item['role_cd']=='PROP_EMP'"
                                            (click)="delete(item)">Delete</button>
                                        <button class="btn btn-outline-danger"
                                            *ngIf="single_users_role.length==1 && item['role_cd']!='PROP_EMP'"
                                            (click)="delete(item)">Delete</button>
                                        <button class="btn btn-outline-danger"
                                            *ngIf="single_users_role.length!=1 && item['role_cd']!='PROP_EMP'"
                                            (click)="delete(item)">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
