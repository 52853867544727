import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class CompoundingService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/icms";
  }

  async getcompoundingdata(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/compounding/getAllcompoundingdetails/' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async insert(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.post(this.httpUrl + '/compounding/map_insert' , obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
       return
  }

  async updateMapStatus(obj:any) {
    const resp = await this.http.put(this.httpUrl + '/compounding/updateMapStatus/' , obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
       return
  }


  async getdefenderdocumentdetails(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/compounding/getdefdocumentdetail/' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
       return
  }

  async view_Doc(obj:any) {
    console.log(obj)
    const resp = await this.http.post(this.httpUrl + '/compounding/viewDocument/', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res
    });
    return resp
  }

}
