import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { HrmsReportService } from '../../service/hrms-report.service';
import { MainService } from '../../service/main.service';
import { AllEmpComponent } from '../../party/all-emp/all-emp.component';
import { MainService as MD_mainService } from '../../../md/service/main.service';
import { UserService as MD_UserService } from '../../../md/service/user.service';
import swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { ExcelService } from '../../service/file-export.service';
import { AllEmpService } from '../../service/all-emp.service';
import Swal from 'sweetalert2';
//import { ConsoleReporter } from 'jasmine';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
declare var $: any
@Component({
  selector: 'app-emp-report',
  templateUrl: './emp-report.component.html',
  styleUrls: ['./emp-report.component.css']
})
export class EmpReportComponent implements OnInit {

  constructor(private excl: ExcelService, public mainService: MainService, private MD_mainService: MD_mainService, private MD_UserService: MD_UserService, private hrmsReportService: HrmsReportService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private allEmpService: AllEmpService) { }
  erpUser:any ;
  b_acct_id:any ;
  allReport :any = [];
  filter :any = {};
  project :any = [];
  Obj :any = {}
  joiningObj:any  = {}
  report_name:any ;
  report_id:any ;
  emp_id :any = false
  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  //  @ViewChild(MatSort, { static: false }) sort: MatSort;
  //   @ViewChild(MatPaginator, {static: false})
  //  set paginator(value: MatPaginator) {
  //    if (this.datasource){
  //      this.datasource.paginator = value;
  //    }
  //  }
  //  @ViewChild(MatSort, {static: false})
  //  set sort(value: MatSort) {
  //    if (this.datasource){
  //      this.datasource.sort = value;
  //    }
  //  }
  //  @ViewChild(MatPaginator, {static: false})
  //  set paginator1(value: MatPaginator) {
  //    if (this.datasource1){
  //      this.datasource1.paginator = value;
  //    }
  //  }
  //  @ViewChild(MatSort, {static: false})
  //  set sortCol2(value: MatSort) {
  //    if (this.datasource1){
  //      this.datasource1.sort = value;
  //    }
  //  }
  @ViewChild('paginator0', { static: false }) paginator0!: MatPaginator;
  @ViewChild('sortCol0', { static: false }) sortCol0!: MatSort;

  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol1', { static: false }) sortCol1!: MatSort;


  // @ViewChild('paginator1', { static: false }) paginator1: MatPaginator;
  //  @ViewChild('sortCol2', { static: false }) sortCol2: MatSort;


  displayedColumns:any  = ['report_id', 'report_name', 'action'];
  displayedColumns1 :any = [];
  displayedColumns2:any  = [];


  datasource:any ;
  datasource1:any ;



  project_fields = [{ field_technical_name: 'pay_scale_code', field_business_name: 'Pay Scale' }
    , { field_technical_name: 'class_code', field_business_name: 'Class Code' }
    , { field_technical_name: 'emp_id', field_business_name: 'Employee ID' }
    , { field_technical_name: 'emp_name', field_business_name: 'Employee Name' }
    , { field_technical_name: 'arr_id', field_business_name: 'Employee Arrangement Id' }
    , { field_technical_name: 'level_code', field_business_name: 'Level Code' }
    , { field_technical_name: 'employee_current_type_code', field_business_name: 'Employee Current Type Code' }
    , { field_technical_name: 'establishment_type_code', field_business_name: 'Establishment Type Code' }
    , { field_technical_name: 'cadre_code', field_business_name: 'Cadre Code' }
    , { field_technical_name: 'grade_pay_code', field_business_name: 'Grade Pay Code' }
    , { field_technical_name: 'pay_commission_code', field_business_name: 'Pay Commission Code' }
    , { field_technical_name: 'emp_status_code', field_business_name: 'Employee Status Code' }
    , { field_technical_name: 'retirement_age', field_business_name: 'Retirement Age' }
    , { field_technical_name: 'designation_code', field_business_name: 'Designation Code' }
    , { field_technical_name: 'order_id', field_business_name: 'Order ID' }
    , { field_technical_name: 'promotion_type_code', field_business_name: 'Promotion Type Code' }
    , { field_technical_name: 'inc_month', field_business_name: 'Inc Month' }
    , { field_technical_name: 'joining_date', field_business_name: 'Joining Date' }
    , { field_technical_name: 'joining_type_code', field_business_name: 'Joining Type Code' }
    , { field_technical_name: 'joining_service_date', field_business_name: 'Joining Service Date' }
    , { field_technical_name: 'retirement_date', field_business_name: 'Retirement Date' }
    , { field_technical_name: 'ordering_authority', field_business_name: 'Ordering Authority' }
    , { field_technical_name: 'probation_end_dt', field_business_name: 'Probation End Date' }
    , { field_technical_name: 'probation_feedback', field_business_name: 'Probation Feedback' }
    , { field_technical_name: 'notice_period', field_business_name: 'Notice Period' }
    , { field_technical_name: 'designation_group_code', field_business_name: 'Designation Group Code' }
    , { field_technical_name: 'uniform_ind', field_business_name: 'Uniform Ind' }
    , { field_technical_name: 'conv_code', field_business_name: 'Conv Code' }
    , { field_technical_name: 'family_planning', field_business_name: 'Family Planning' }
    , { field_technical_name: 'emp_sex', field_business_name: 'Employee Sex' }
    , { field_technical_name: 'emp_email', field_business_name: 'Employee Email' }
    , { field_technical_name: 'emp_phone_no', field_business_name: 'Employee Phone Number' }
    , { field_technical_name: 'emp_dob', field_business_name: 'Employee Date Of Birth' }
    , { field_technical_name: 'emp_father_name', field_business_name: 'Employee Father Name' }
    , { field_technical_name: 'emp_pan_no', field_business_name: 'Employee Pan Number' }
    , { field_technical_name: 'emp_adhar_no', field_business_name: 'Employee Addhar Number' }
    , { field_technical_name: 'emp_gst_no', field_business_name: 'Employee GST Number' }
    , { field_technical_name: 'bank_code', field_business_name: 'Employee Bank Code' }
    , { field_technical_name: 'branch_code', field_business_name: 'Employee  Branch Code' }
    , { field_technical_name: 'ifsc_code', field_business_name: 'Employee IFSC Code' }
    , { field_technical_name: 'acct_no', field_business_name: 'Employee Account Number' }
    , { field_technical_name: 'pf_acct_no', field_business_name: 'Employee PF Account Number' }
    , { field_technical_name: 'identification_mark', field_business_name: 'Identification Mark' }
    , { field_technical_name: 'gis_no', field_business_name: 'Employee GIS Number' }
    , { field_technical_name: 'nps_no', field_business_name: 'Employee NPS Number' }
    , { field_technical_name: 'reservation_category_code', field_business_name: 'Reservation Category Code' }
    , { field_technical_name: 'marital_status', field_business_name: 'Marital Status' }
    , { field_technical_name: 'emp_husband_name', field_business_name: 'Employee Husband Name' }
    , { field_technical_name: 'emp_religeon', field_business_name: 'Employee Religeon' }
    , { field_technical_name: 'emp_nationality', field_business_name: 'Employee Nationality' }
    , { field_technical_name: 'emp_mother_name', field_business_name: 'Employee Mother Name' }
    , { field_technical_name: 'posting_date', field_business_name: 'Posting Date' }
    , { field_technical_name: 'posting_status_code', field_business_name: 'Posting_Status Code' }
    , { field_technical_name: 'section_code', field_business_name: 'Section Code' }
    , { field_technical_name: 'posting_type_code', field_business_name: 'Posting Type Code' }
    , { field_technical_name: 'education_name', field_business_name: 'Education Name' }
    , { field_technical_name: 'education_type_code', field_business_name: 'Education Type Code' }
    , { field_technical_name: 'pass_year_code', field_business_name: 'Pass Year' }



    , { field_technical_name: 'pay_comp', field_business_name: 'pay_component_code' }
    , { field_technical_name: 'rate', field_business_name: 'pay_component_amt' }
    , { field_technical_name: 'valid_from', field_business_name: 'Effective Start Date' }
    , { field_technical_name: 'valid_upto', field_business_name: 'Effective End Date' }
    , { field_technical_name: 'pay_code', field_business_name: 'Pay Code' },
  ];

  table_name :any = {
    pay_scale_code: 'establishment_info',
    class_code: 'establishment_info',
    emp_id: 'establishment_info',
    emp_name: 'establishment_info',
    level_code: 'establishment_info',
    employee_current_type_code: 'establishment_info',
    establishment_type_code: 'establishment_info',
    arr_id: 'establishment_info',
    cadre_code: 'establishment_info',
    grade_pay_code: 'establishment_info',
    pay_commission_code: 'establishment_info',
    emp_status_code: 'establishment_info',
    retirement_age: 'establishment_info',
    designation_code: 'establishment_info',
    order_id: 'establishment_info',
    promotion_type_code: 'establishment_info',
    inc_month: 'establishment_info',
    joining_date: 'establishment_info',
    joining_type_code: 'establishment_info',
    joining_service_date: 'establishment_info',
    retirement_date: 'establishment_info',
    ordering_authority: 'establishment_info',
    probation_end_dt: 'establishment_info',
    probation_feedback: 'establishment_info',
    notice_period: 'establishment_info',
    designation_group_code: 'establishment_info',
    uniform_ind: 'establishment_info',
    conv_code: 'establishment_info',
    family_planning: 'establishment_info',
    emp_sex: 'emp_personal_info',
    emp_email: 'emp_personal_info',
    emp_phone_no: 'emp_personal_info',
    emp_dob: 'emp_personal_info',
    emp_father_name: 'emp_personal_info',
    emp_pan_no: 'emp_personal_info',
    emp_adhar_no: 'emp_personal_info',
    bank_code: 'emp_personal_info',
    emp_gst_no: 'emp_personal_info',
    branch_code: 'emp_personal_info',
    ifsc_code: 'emp_personal_info',
    acct_no: 'emp_personal_info',
    pf_acct_no: 'emp_personal_info',
    identification_mark: 'emp_personal_info',
    gis_no: 'emp_personal_info',
    nps_no: 'emp_personal_info',
    reservation_category_code: 'emp_personal_info',
    marital_status: 'emp_personal_info',
    emp_husband_name: 'emp_personal_info',
    emp_religeon: 'emp_personal_info',
    emp_nationality: 'emp_personal_info',
    emp_mother_name: 'emp_personal_info',
    posting_date: 'post',
    posting_status_code: 'post',
    section_code: 'post',
    posting_type_code: 'post',
    education_name: 'education',
    education_type_code: 'education',
    pass_year_code: 'education',

    pay_comp: 'fixed_pay_amount',
    rate: 'fixed_pay_amount',
    valid_from: 'fixed_pay_amount',
    valid_upto: 'fixed_pay_amount',
    pay_code: 'fixed_pay_amount',
  }
  datatype:any  = {
    pay_scale_code: 'establishment_info',
    class_code: 'establishment_info',
    emp_id: 'establishment_info',
    emp_name: 'establishment_info',
    level_code: 'establishment_info',
    employee_current_type_code: 'establishment_info',
    establishment_type_code: 'establishment_info',
    valid_from: 'establishment_info',
    valid_upto: 'establishment_info',

    cadre_code: 'establishment_info',
    grade_pay_code: 'establishment_info',
    pay_commission_code: 'establishment_info',
    emp_status_code: 'establishment_info',
    retirement_age: 'date',
    designation_code: 'establishment_info',
    order_id: 'establishment_info',
    promotion_type_code: 'establishment_info',
    inc_month: 'establishment_info',
    joining_date: 'date',
    joining_type_code: 'establishment_info',
    joining_service_date: 'establishment_info',
    retirement_date: 'date',
    ordering_authority: 'establishment_info',
    probation_end_dt: 'date',
    probation_feedback: 'establishment_info',
    notice_period: 'establishment_info',
    designation_group_code: 'establishment_info',
    uniform_ind: 'establishment_info',
    conv_code: 'establishment_info',
    family_planning: 'establishment_info',
    emp_sex: 'emp_personal_info',
    emp_email: 'emp_personal_info',
    emp_phone_no: 'emp_personal_info',
    emp_dob: 'date',
    emp_father_name: 'emp_personal_info',
    emp_pan_no: 'emp_personal_info',
    emp_adhar_no: 'emp_personal_info',
    bank_code: 'emp_personal_info',
    emp_gst_no: 'emp_personal_info',
    branch_code: 'emp_personal_info',
    ifsc_code: 'emp_personal_info',
    acct_no: 'emp_personal_info',
    pf_acct_no: 'emp_personal_info',
    identification_mark: 'emp_personal_info',
    gis_no: 'emp_personal_info',
    nps_no: 'emp_personal_info',
    reservation_category_code: 'emp_personal_info',
    marital_status: 'emp_personal_info',
    emp_husband_name: 'emp_personal_info',
    emp_religeon: 'emp_personal_info',
    emp_nationality: 'emp_personal_info',
    emp_mother_name: 'emp_personal_info',
    posting_date: 'date',
    posting_status_code: 'post',
    section_code: 'post',
    posting_type_code: 'post',
    education_name: 'education',
    education_type_code: 'education',
    pass_year_code: 'education',

    pay_comp: 'fixed_pay_amount',
    rate: 'fixed_pay_amount',
    // valid_from: 'fixed_pay_amount',
    effective_end_dt: 'date',
    pay_code: 'fixed_pay_amount',
  }

  ObjFieldTechNameToBusinessName :any = {};
  Status = [{ code: 'ALL', value: 'ALL' }, { code: 'ACTIVE', value: 'ACTIVE' }, { code: 'INACTIVE', value: 'INACTIVE' }];


  CadreArr:any  = [];
  ClassArr:any  = [];
  DesignationArr :any = [];
  SectionArr :any = [];
  EducationArr :any = [];
  PayCommissionArr :any = [];
  result:any  = [];
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAccountProjectHier();
    await this.getAllReport();
    await this.createFilterData();
    await this.getAllAccountModule()
    this.ObjFieldTechNameToBusinessName['serial_no'] = 'Serial No'
    for (let i = 0; i < this.project_fields.length; i++) {
      this.ObjFieldTechNameToBusinessName[this.project_fields[i]['field_technical_name']] = this.project_fields[i]['field_business_name'];
    }

  }
  allModule = [];
  moduleObj = {}
  list_flag = true;
  view_flag = false;
  create_flag = false;
  update_flag = false;
  async open_list() {
    this.list_flag = true;
    this.view_flag = false;
    this.create_flag = false;
    this.update_flag = false;
    await this.getAllReport()
  }
  async open_create() {
    this.filter = {}
    this.project = null
    this.Obj['path_desc'] = null;
    this.list_flag = false;
    this.view_flag = false;
    this.create_flag = true;
    this.planing_arrproj = []
    this.update_flag = false;
  }
  async getAllAccountModule() {
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_mainService.getAccountModules(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allModule = resp.data;

      for (let i = 0; i < this.allModule.length; i++) {
        this.moduleObj[this.allModule[i]['module_cd']] = this.allModule[i]['module_name']
      }

      for (let i = 0; i < this.allModule.length; i++) {
        if (this.allModule[i]['module_cd'] == 'ENG') {
          this.allModule.splice(i, 1)
        }
      }
      console.log(this.moduleObj)
    } else {
      // swal.fire('Error', 'Some Error Occured', 'error')
    }
  }
  selectedModule(module_cd:any ) {
    console.log(module_cd)
    this.planing_arrproj = [];
    this.Obj = {}
  }
  getNumberFormat(num:any ) {
    return num.toString().padStart(3, "0")
  }


  createFilterData() {
    var PayCommissionArr_temp = [];
    PayCommissionArr_temp.push({ code: 'ALL', value: 'ALL' });
    for (let i = 0; i < this.mainService.codeValueTechObj['HR0016'].length; i++) {
      PayCommissionArr_temp.push(this.mainService.codeValueTechObj['HR0016'][i]);
    }
    this.PayCommissionArr = PayCommissionArr_temp;




    var EducationArr_temp = [];
    EducationArr_temp.push({ code: 'ALL', value: 'ALL' });

    for (let i = 0; i < this.mainService.codeValueTechObj['HR0005'].length; i++) {
      EducationArr_temp.push(this.mainService.codeValueTechObj['HR0005'][i]);
    }
    this.EducationArr = EducationArr_temp;



    var SectionArr_temp = [{ code: 'ALL', value: 'ALL' }]
    for (let i = 0; i < this.mainService.codeValueTechObj['HR0031'].length; i++) {
      SectionArr_temp.push(this.mainService.codeValueTechObj['HR0031'][i]);
    }
    this.SectionArr = SectionArr_temp;



    var DesignationArr_temp = [{ code: 'ALL', value: 'ALL' }];
    for (let i = 0; i < this.mainService.codeValueTechObj['HR0011'].length; i++) {
      DesignationArr_temp.push(this.mainService.codeValueTechObj['HR0011'][i]);
    }
    this.DesignationArr = DesignationArr_temp;



    var ClassArr_temp = [{ code: 'ALL', value: 'ALL' }];
    for (let i = 0; i < this.mainService.codeValueTechObj['HR0014'].length; i++) {
      ClassArr_temp.push(this.mainService.codeValueTechObj['HR0014'][i]);
    }
    this.ClassArr = ClassArr_temp;



    var CadreArr_temp = [{ code: 'ALL', value: 'ALL' }];
    for (let i = 0; i < this.mainService.codeValueTechObj['HR0013'].length; i++) {
      CadreArr_temp.push(this.mainService.codeValueTechObj['HR0013'][i]);
    }
    this.CadreArr = CadreArr_temp;

  }

  open_update(element:any ) {
    this.list_flag = false;
    this.view_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    this.node_cd = null
    var temp = JSON.parse(element.filter_and_project);
    this.filter = Object.assign({}, temp['filter']);
    this.project = temp['project'];
    this.report_name = element.report_name;
    this.report_id = element.report_id;
    if (this.node_cd == undefined || this.node_cd == null) {
      this.node_cd = this.filter['node_cd']
    }

    $('.nav-tabs a[href="#tab-4"]').tab('show');
    // element.node_code=73
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]['leaf_cd'] == this.node_cd) {
        this.data1 = this.projecthier[i]


      }



    }
    this.planing_arrproj = []

    let path_desc = []

    //



    this.planing_arrproj = []
    for (let i = 1; i < 8; i++) {
      console.log(this.data1['lvl' + (i) + '_user_cd'])
      if (this.data1['lvl' + (i) + '_user_cd'] != null) {
        if (i == 1) {
          //  this.planing_arrproj.push({hierarchy_type:data['lvl'+(i)+'_user_cd'],level:1})
          this.down({ hierarchy_type: this.data1['lvl' + (i) + '_cd'] }, -1)
          this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] = this.data1['lvl' + (i) + '_cd']
          console.log(this.planing_arrproj)
        } else {
          console.log("welcome")
          //  this.planing_arrproj.push({hierarchy_type:data['lvl'+(i)+'_user_cd']})
          this.down({ hierarchy_type: this.data1['lvl' + (i - 1) + '_cd'] }, i - 2)
          console.log("welcome")
          this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] = this.data1['lvl' + (i) + '_cd']
          console.log(this.planing_arrproj)






          console.log(this.planing_arrproj[this.planing_arrproj.length - 1])
          this.setHierarchyNodeitem(this.planing_arrproj[this.planing_arrproj.length - 1], i - 1)

        }

      }
      this.Obj['node_type'] = this.data1['leaf_node_type']
      this.Obj['node_cd'] = this.data1['leaf_user_cd']
      this.Obj['node_desc'] = this.data1['leaf_value']




    }


  }
  submitCheck() {
    if (this.checkRequired()) {
      swal.fire("Warning", "* Field Required !", 'warning')
      return;
    }
    else {

      $('#save-report').modal('show');

    }
  }

  updateCheck() {
    if (this.checkRequired()) {
      swal.fire("Warning", "* Field Required !", 'warning')
      return;
    }
    else {
      $('#update-report').modal('show')
    }
  }

  checkRequired() {
    if (this.Obj['path_desc'] == '' || this.Obj['path_desc'] == null || this.Obj['path_desc'] == undefined ||
      this.filter['cadre_code'] == '' || this.filter['cadre_code'] == null || this.filter['cadre_code'] == undefined ||
      this.filter['class_code'] == '' || this.filter['class_code'] == null || this.filter['class_code'] == undefined ||
      this.filter['designation_code'] == '' || this.filter['designation_code'] == null || this.filter['designation_code'] == undefined ||
      this.filter['education_name'] == '' || this.filter['education_name'] == null || this.filter['education_name'] == undefined ||
      this.filter['pay_commission_code'] == '' || this.filter['pay_commission_code'] == null || this.filter['pay_commission_code'] == undefined ||
      this.filter['emp_status_code'] == '' || this.filter['emp_status_code'] == null || this.filter['emp_status_code'] == undefined ||
      this.project == null || this.project == undefined) {

      return true
    }
    else {
      return false
    }

  }


  async submit_and_view() {
    if (this.checkRequired()) {
      swal.fire("Warning", "* Field Required !", 'warning')
      return;
    }


    await this.submit();
    this.list_flag = false;
    this.view_flag = true;
    this.create_flag = false;
    this.update_flag = false;
    this.planing_arrproj = []
    $('.nav-tabs a[href="#tab-2"]').tab('show');

  }

  createObj() {
    var education = [];
    var post = [];
    var emp_personal_info = [];
    var establishment_info = [];
    var fixed_pay_amount = [];


    for (let i = 0; i < this.project.length; i++) {
      if (this.table_name[this.project[i]] == 'education') {
        education.push(this.project[i]);
      } else if (this.table_name[this.project[i]] == 'post') {
        post.push(this.project[i]);

      } else if (this.table_name[this.project[i]] == 'emp_personal_info') {
        emp_personal_info.push(this.project[i]);

      } else if (this.table_name[this.project[i]] == 'establishment_info') {
        establishment_info.push(this.project[i]);

      } else if (this.table_name[this.project[i]] == 'fixed_pay_amount') {
        fixed_pay_amount.push(this.project[i]);
      }
    }

    var project_temp:any  = new Object();
    project_temp['establishment_info'] = establishment_info;
    project_temp['emp_personal_info'] = emp_personal_info;
    project_temp['post'] = post;
    project_temp['education'] = education;
    project_temp['fixed_pay_amount'] = fixed_pay_amount;
    var filter_temp :any = new Object();
    var arr = []
    if (this.filter['cadre_code'] != 'ALL') {
      arr.push({ cadre_code: this.filter['cadre_code'] })
    }
    if (this.filter['class_code'] != 'ALL') {
      arr.push({ class_code: this.filter['class_code'] })
    }

    if (this.filter['designation_code'] != 'ALL') {
      arr.push({ designation_code: this.filter['designation_code'] })
    }

    if (this.filter['pay_commission_code'] != 'ALL') {
      arr.push({ pay_commission_code: this.filter['pay_commission_code'] })
    }

    if (this.filter['emp_status_code'] != 'ALL') {
      arr.push({ emp_status_code: this.filter['emp_status_code'] })
    }

    // var arr1 = []
    // if (this.filter['section_code'] != 'ALL') {
    //   arr1.push({ section_code: this.filter['section_code'] })
    // }

    var arr2 = [];
    if (this.filter['education_name'] != 'ALL') {
      arr2.push({ education_name: this.filter['education_name'] })
    }


    filter_temp['establishment_info'] = arr;
    filter_temp['emp_personal_info'] = [];
    //  filter_temp['post'] = arr1;
    filter_temp['education'] = arr2;
    filter_temp['fixed_pay_amount'] = [];
    var obj :any  = new  Object();
    obj['project'] = project_temp;
    obj['filter'] = filter_temp;
    return obj;
  }

  // ngAfterViewInit(){
  //   //this.datasource_report = new MatTableDataSource(resp.data)
  // this.cdr.detectChanges();
  //   this.datasource_report.paginator = this.paginator1;
  //   this.datasource_report.sort = this.sort1;
  // }
  num1 :any = []
  flag1 :any = false;
  async submit() {
    if (this.checkRequired()) {
      swal.fire("Warning", '* Field Required !', 'warning')
      return;
    }
    this.num1 = []
    var obj :any = this.createObj();
    var final_Query:any  = this.createQuery(obj);
    var obj1 :any = new Object();
    obj1['b_acct_id'] = this.b_acct_id;
    console.log(final_Query)
    obj1['query'] = final_Query;
    this.spinner.show()
    var resp = await this.hrmsReportService.getReport(obj1);
    if (resp['error'] == false) {
      this.displayedColumns1 = []
      this.displayedColumns2 = []
      if (resp.data.length > 0) {
        var arr_id_exist = this.project.includes('arr_id');

        var column = Object.keys(resp.data[0]);
        this.displayedColumns1.push('serial_no')
        for (let i = 0; i < column.length; i++) {
          if (arr_id_exist) {
            this.displayedColumns1.push(column[i])

          } else {
            if ('arr_id' != column[i]) {
              this.displayedColumns1.push(column[i])
            }
          }
        }
      }
      this.displayedColumns2.push(this.displayedColumns1)
      console.log(this.displayedColumns2)

      // this.cdr.detectChanges();
      var allEmplyees_new = resp.data
      //   var allEmplyees_new = [];
      var allEmplyees_new1 = [];
      var allEmplyees_new2 = [];

      // for (let i = 0; i < allEmplyees.length; i++) {
      //   var obj :any  = new  Object();
      //   obj = allEmplyees[i];
      //   if (obj['emp_id'] != undefined) {
      //     this.emp_id = true
      //     obj['serial_no'] = i + 1
      //     allEmplyees_new.push(obj)
      //   }
      //   else {
      //     allEmplyees[i]['serial_no'] = i + 1
      //     allEmplyees_new.push(allEmplyees[i])
      //   }
      // }



      console.log(this.node_cd)
      var flag = false;


      for (let j = 0; j < this.projecthier.length; j++) {
        flag = false;
        for (let i = 1; i < 8; i++) {

          if (this.projecthier[j]['lvl' + i + '_node_type'] == 'HREMP') {
            flag = true;
            console.log("hello")
            break;
          }
        }
        if (flag == true) {
          console.log("welcome")
          this.data2.push(this.projecthier[j])
        }
      }
      this.data3 = []
      for (let j = 0; j < this.data2.length; j++) {
        flag = false;
        for (let i = 1; i < 8; i++) {

          if (this.data2[j]['lvl' + i + '_cd'] == this.node_cd) {
            flag = true;

            break;
          }
        }
        if (flag == true) {

          this.data3.push(this.data2[j])
        }
      }

      console.log(this.data3)

      for (let i = 0; i < this.data3.length; i++) {
        this.num1.push(this.data3[i]['leaf_user_cd'].match(/\d+/g));

      }
      console.log(this.num1)

      console.log(allEmplyees_new)
      for (let i = 0; i < allEmplyees_new.length; i++) {
        for (let j = 0; j < this.num1.length; j++) {
          console.log(this.num[j])
          if (allEmplyees_new[i]['arr_id'] == this.num1[j]) {
            console.log("princy")
            if (allEmplyees_new1.indexOf(allEmplyees_new[i]) === -1) allEmplyees_new1.push(allEmplyees_new[i])
          }
        }
      }

      console.log(allEmplyees_new1);
      for (let i = 0; i < allEmplyees_new1.length; i++) {

        allEmplyees_new1[i]['serial_no'] = i + 1
        allEmplyees_new2.push(allEmplyees_new1[i])

      }

      this.result = allEmplyees_new2;
      console.log(this.result)
      this.datasource1 = new MatTableDataSource(allEmplyees_new2)
      // this.datasource1.paginator = this.paginator1;
      // this.datasource1.sort = this.sortCol2;
      this.datasource1.paginator = this.paginator1;
      this.datasource1.sort = this.sortCol1;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire('Error', "Error while getting Report Data", 'error')
      // this.snackBar.open("Error while getting Report Data", 'Error', {
      //   duration: 5000
      // });
    }
  }

  refresh() {
    this.filter = {};
    this.project = [];
  }


deleteData(element:any )
{

  swal.fire({
    title: 'Are You Sure?',
    // text:'Do You Want To Delete',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes'
  }).then(async (result) => {
    if (result.value) {
      await this.delete(element)
    }
    else{
    // this.getEmpSuspensionReinstate();
   // this.reinstate();
      }
    })

  }

  async delete(element:any ) {
    var obj :any  = new  Object();
    obj['report_id'] = element.report_id;
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.hrmsReportService.deleteReport(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.getAllReport();
      // this.snackBar.open(resp.data, 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", 'Delete Successfully ', 'success')
    } else {
      this.spinner.hide()
      // this.snackBar.open("Error while deleting Report List", 'Error', {
      //   duration: 5000
      // });
      swal.fire("Error", 'Error while deleting Report List', 'error');
    }

  }
  obj3 :any = {}
  node_code:any ;
  datasource2:any
  async getAllReport() {
    this.spinner.show()
    var resp = await this.hrmsReportService.getAllSavedreports(this.b_acct_id);
    if (resp['error'] == false) {
      console.log(resp)
      var obj = resp.data


      // for (let i = 0; i < obj.length; i++) {

      //   console.log(obj[i]['filter_and_project'])
      //   var obj2 = obj[i]['filter_and_project']

      //   obj2 = JSON.parse(obj2)
      //   console.log(obj2)
      //   console.log(obj2['filter']['cadre_code'])

      //   this.obj3[i] = obj2['filter']['node_cd']
      //   console.log(this.obj3)
      //   resp.data[i]['node_code'] = this.obj3[i]
      // }

      this.spinner.hide()

      this.allReport = resp.data;
      this.datasource = new MatTableDataSource(this.allReport)
//       let arr = []
//       this.allReport.map(x => {
//         arr.push({ 'report_name': x['report_name'], 'report_id': x['report_id'] })
//       })

// console.log(arr);


      // this.datasource2 = new MatTableDataSource(arr)


      // this.datasource.paginator = this.paginator;
      // this.datasource.sort = this.sort;

      this.datasource.paginator = this.paginator0;
      this.datasource.sort = this.sortCol0
      // this.datasource2.paginator = this.paginator0;
      // this.datasource2.sort = this.sortCol0

      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting  Report List ', 'error')
      // this.snackBar.open("Error while getting  Report List", 'Error', {
      //   duration: 5000
      // });
    }
  }


  async update() {
    if (this.report_name == '' || this.report_name == null || this.report_name == undefined) {
      swal.fire("Warning", 'Report Name Required !', 'warning');
      return;
    }
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['report_name'] = this.report_name;
    obj['filter_and_project'] = JSON.stringify({ filter: this.filter, project: this.project });
    obj['update_user_id'] = this.erpUser.user_id;
    obj['report_id'] = this.report_id;
    var resp = await this.hrmsReportService.updateReport(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.getAllReport();
      $('#update-report').modal('hide');
      //  await this.open_create()
      await this.open_list()
      // this.snackBar.open(resp.data, 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", 'Update Successfully !', 'success')
    } else {
      this.spinner.hide();
      $('#update-report').modal('hide');

      // this.snackBar.open("Error while saved Report ", 'Error', {
      //   duration: 5000
      // });
      swal.fire("Error", 'Error while saved Report !', 'error')
    }


  }
  async save() {
    if (this.report_name == '' || this.report_name == null || this.report_name == undefined) {
      swal.fire("Warning", 'Report name Is Required !', 'warning');
      return;
    }
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['report_name'] = this.report_name;
    //  filter['node_cd']=this.filter
    obj['filter_and_project'] = JSON.stringify({ filter: this.filter, project: this.project });
    obj['create_user_id'] = this.erpUser.user_id;
    var resp = await this.hrmsReportService.createReport(obj);
    if (resp['error'] == false) {
      console.log(resp);
      this.getAllReport();
      $('#save-report').modal('hide');
      this.spinner.hide();
      // this.snackBar.open("Saved Report Successfully!!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", 'Saved Report Successfully!', 'success')
      // this.open_create();
      await this.open_list()

    } else {
      $('#save-report').modal('hide');
      this.spinner.hide()
      // this.snackBar.open("Error while saved Report ", 'Error', {
      //   duration: 5000
      // });
      swal.fire("Error", 'Error while saved Report !', 'error')
    }
  }

  data1:any  = []
  data :any = []
  data2 :any = []
  data3 :any = []
  num :any = [];
  element1:any ;
  async submit_for_view(element:any ) {

    this.list_flag = false;
    this.view_flag = true;
    this.create_flag = false;
    this.update_flag = false;
    this.datasource1 = new MatTableDataSource([]);
    console.log(element)
    // this.element1=element;
    this.node_cd = null
    var temp = JSON.parse(element.filter_and_project);

    this.filter = Object.assign({}, temp['filter']);
    this.project = temp['project'];
    this.node_cd = this.filter['node_cd']
    // await this.submit();
    $('.nav-tabs a[href="#tab-2"]').tab('show');

    console.log(this.filter)
    console.log(this.filter['node_cd'])

    if (this.node_cd == undefined || this.node_cd == null) {
      this.node_cd = this.filter['node_cd']
    }

    console.log(this.node_cd)

    console.log(this.node_cd)
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]['leaf_cd'] == this.node_cd) {
        this.data1 = this.projecthier[i]
        break
      }
    }
    this.planing_arrproj = []

    let path_desc = []

    // for(let i=1;i<8;i++)
    // {
    //      if(this.data1['lvl' + i + '_node_type']=='HREMP')
    //      {
    //        break;
    //      }
    //      path_desc.push(this.data1['lvl' + i + '_value'])

    //  this.down({hierarchy_type:this.data1['lvl'+(i)+'_cd']},-1)
    //     this.planing_arrproj[this.planing_arrproj.length-1]['hierarchy_type'] = this.data1['lvl'+(i)+'_cd']
    //     console.log(this.planing_arrproj)
    //     this.Obj['node_type'] = this.data1['leaf_node_type']
    //     this.Obj['node_cd'] = this.data1['leaf_user_cd']
    //     this.Obj['node_desc'] = this.data1['leaf_value']


    // if(this.data1['lvl4_node_type']=='HREMP'){
    // path_desc.push(this.data1['lvl1_value'],this.data1['lvl2_value'],this.data1['lvl3_value'])}
    // else{
    //   path_desc.push(this.data1['lvl1_value'],this.data1['lvl2_value'])
    // }
    // this.Obj['path_desc'] = path_desc.join(' --> ')
    // console.log(this.Obj['path_desc']);
    console.log(this.data1)
    var flag = false;


    for (let j = 0; j < this.projecthier.length; j++) {
      flag = false;
      for (let i = 1; i < 8; i++) {

        if (this.projecthier[j]['lvl' + i + '_node_type'] == 'HREMP') {
          flag = true;
          break;
        }
      }
      if (flag == true) {
        this.data2.push(this.projecthier[j])
      }
    }
    this.data3 = []
    for (let j = 0; j < this.data2.length; j++) {
      flag = false;
      for (let i = 1; i < 8; i++) {

        if (this.data2[j]['lvl' + i + '_cd'] == this.node_cd) {
          flag = true;

          break;
        }
      }
      if (flag == true) {

        this.data3.push(this.data2[j])
      }
    }

    console.log(this.data3)

    for (let i = 0; i < this.data3.length; i++) {
      this.num.push(this.data3[i]['leaf_user_cd'].match(/\d+/g));

    }

    this.planing_arrproj = []
    this.Obj['path_desc'] = []
    for (let i = 1; i < 8; i++) {
      console.log(this.data1['lvl' + (i) + '_user_cd'])
      if (this.data1['lvl' + (i) + '_user_cd'] != null) {
        if (i == 1) {
          this.down({ hierarchy_type: this.data1['lvl' + (i) + '_cd'] }, -1)
          this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] = this.data1['lvl' + (i) + '_cd']
        } else {
          this.down({ hierarchy_type: this.data1['lvl' + (i - 1) + '_cd'] }, i - 2)
          this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] = this.data1['lvl' + (i) + '_cd']
          this.setHierarchyNodeitem(this.planing_arrproj[this.planing_arrproj.length - 1], i)

        }

      }
      this.Obj['node_type'] = this.data1['leaf_node_type']
      this.Obj['node_cd'] = this.data1['leaf_user_cd']
      this.Obj['node_desc'] = this.data1['leaf_value']
    }
    this.result = []
  }




  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter1(filterValue: string) {
    this.datasource1.filter = filterValue.trim().toLowerCase();
  }
  export() {
    if (this.result.length == 0) {
      swal.fire('Warning', 'No Data Present For Export !', 'warning')
      return;
    }
    var ar = []



    for (var i = 0; i < this.result.length; i++) {
      var obj :any  = new  Object();
      var keys = Object.keys(this.result[i]);
      obj['Sr No'] = this.result[i]['serial_no'];
      var arr_id_exist = this.project.includes('arr_id');

      for (var j = 0; j < keys.length; j++) {
        if (keys[j] != 'serial_no') {
          if (this.datatype[keys[j]] != 'date') {
            if (arr_id_exist) {
              obj[this.ObjFieldTechNameToBusinessName[keys[j]]] = this.result[i][keys[j]]


            } else {
              if ('arr_id' != keys[j]) {
                obj[this.ObjFieldTechNameToBusinessName[keys[j]]] = this.result[i][keys[j]]

              }

            }

          }


          else {
            obj[this.ObjFieldTechNameToBusinessName[keys[j]]] = this.result[i][keys[j]].split('T')[0];

          }

        }
      }

      ar.push(obj);

    }
    this.excl.exportAsExcelFile(ar, 'down')
  }


  print() {
    let printContents, popupWin;
    printContents = document.getElementById('p')?.innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin?.document.open();
    popupWin?.document.write(`
    <html>
      <head>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
      </head>
      <style>
      #tbl {
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        max-width: 2480px;
        page-break-after:auto
    }

    #tbl td,
    #tbl th {
        border: 1px solid #ddd;
        padding: 8px;
        width: auto;
        word-wrap: break-word;
        page-break-inside:avoid; page-break-after:auto
    }



    #tbl th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #d9edf7;
      color: black;
  }
      </style>
  <body onload="window.print();window.close()">${printContents}</body>
    </html>`
    );
    popupWin?.document.close();


  }

  /***************************************************kunal Quer Create Code********************************** */

  createQuery1(obj:any ) {
    var query = ""
    query = this.createprojectPart(obj) + " " + this.createJoinPart(obj) + " " + this.createFilterPart(obj)

    return query;

  }
  createprojectPart1(obj:any ) {
    var str = 'select '
    if (obj['project']['establishment_info'].length > 0) {
      str += "est."
      str += obj['project']['establishment_info'].join(',est.')
      str += ','
    }
    if (obj['project']['emp_personal_info'].length > 0) {
      str += "emp."
      str += obj['project']['emp_personal_info'].join(',emp.')
      str += ','
    }
    if (obj['project']['post'].length > 0) {
      str += "posti."
      str += obj['project']['post'].join(',posti.')
      str += ','
    }

    if (obj['project']['education'].length > 0) {
      str += "edu."
      str += obj['project']['education'].join(',edu.')
      str += ','
    }
    if (obj['project']['fixed_pay_amount'].length > 0) {
      str += "fpa."
      str += obj['project']['fixed_pay_amount'].join(',fpa.')
      str += ','
    }
    str = str.substring(0, str.length - 1) + " from"
    return str
  }
  createJoinPart1(obj:any ) {
    let db = "pcs_" + this.b_acct_id + "_hr";

    let str = " (Select *,rank() over(partition by emp_id order by effective_timestamp desc) as svm_rank from " + db + ".establishment_info )est "
    if (obj['project']['emp_personal_info'].length > 0 || obj['filter']['emp_personal_info'].length > 0) {
      str += " join " + db + ".emp_personal_info emp on est.emp_id=emp.emp_id"
    }
    if (obj['project']['post'].length > 0 || obj['filter']['post'].length > 0) {
      str += " join " + db + ".post posti on est.emp_id=posti.emp_id"
    } if (obj['project']['education'].length > 0 || obj['filter']['education'].length > 0) {
      str += " join " + db + ".education edu on est.emp_id=edu.emp_id"
    }
    if (obj['project']['fixed_pay_amount'].length > 0 || obj['filter']['fixed_pay_amount'].length > 0) {
      str += " join " + db + ".fixed_pay_amount fpa on est.emp_id=fpa.emp_id"
    }
    return str;
  }

  createFilterPart1(obj:any ) {
    var str = ' where  est.svm_rank=1 AND '
    if (obj['filter']['establishment_info'].length > 0) {
      let arr = obj['filter']['establishment_info']
      for (let i = 0; i < arr.length; i++) {
        str += "est." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]].replace("'", "\\'") + "' AND "
        //str += "est." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]] + "' AND "

      }

    }
    if (obj['filter']['emp_personal_info'].length > 0) {
      let arr = obj['filter']['emp_personal_info']
      for (let i = 0; i < arr.length; i++) {
        str += "emp." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]].replace("'", "\\'") + "' AND "
      }

    }
    if (obj['filter']['post'].length > 0) {
      let arr = obj['filter']['post']
      for (let i = 0; i < arr.length; i++) {
        str += "posti." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]].replace("'", "\\'") + "' AND "
      }

    }

    if (obj['filter']['education'].length > 0) {
      let arr = obj['filter']['education']
      for (let i = 0; i < arr.length; i++) {
        str += "edu." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]].replace("'", "\\'") + "' AND "
      }

    }
    str = str.substring(0, str.length - 4)
    return str
  }



  ///////////////////////////////////////////////////////princy Quer Create code////////////////////////////////

  createQuery(obj:any ) {
    var query = ""
    query = this.createprojectPart(obj) + " " + this.createJoinPart(obj) + " " + this.createFilterPart(obj)

    return query;

  }
  createprojectPart(obj:any ) {
    var str = 'select '
    if (obj['project']['establishment_info'].length > 0) {
      if (!obj['project']['establishment_info'].includes('arr_id')) {
        str += "est.arr_id,"
      }

      str += "est."
      str += obj['project']['establishment_info'].join(',est.')
      str += ','
    } else {
      str += "est.arr_id,"
    }
    if (obj['project']['emp_personal_info'].length > 0) {
      str += "emp."
      str += obj['project']['emp_personal_info'].join(',emp.')
      str += ','
    }
    // if (obj['project']['post'].length > 0) {
    //   str += "posti."
    //   str += obj['project']['post'].join(',posti.')
    //   str += ','
    // }

    if (obj['project']['education'].length > 0) {
      str += "edu."
      str += obj['project']['education'].join(',edu.')
      str += ','
    }
    if (obj['project']['fixed_pay_amount'].length > 0) {
      str += "fpa."
      str += obj['project']['fixed_pay_amount'].join(',fpa.')
      str += ','
    }
    str = str.substring(0, str.length - 1) + " from"
    return str
  }

  fix_filter :any = {};
  createJoinPart(obj:any ) {
    this.fix_filter = {};

    this.fix_filter['establishment_info'] = 'est.valid_from<=NOW() and  est.valid_upto>NOW()';

    if (obj['project']['emp_personal_info'].length > 0 || obj['filter']['emp_personal_info'].length > 0) { this.fix_filter['emp_personal_info'] = 'emp.valid_from<=NOW() and  emp.valid_upto>NOW()' }
    if (obj['project']['education'].length > 0 || obj['filter']['education'].length > 0) { this.fix_filter['education'] = `edu.status='ACTIVE'` }
    if (obj['project']['fixed_pay_amount'].length > 0 || obj['filter']['fixed_pay_amount'].length > 0) { this.fix_filter['fixed_pay_amount'] = 'fpa.valid_from<=NOW() and  fpa.valid_upto>NOW()' }



    let db = "pcs_" + this.b_acct_id + "_hr";

    let str = " (Select * from " + db + ".emp_arr )est "
    if (obj['project']['emp_personal_info'].length > 0 || obj['filter']['emp_personal_info'].length > 0) {
      str += " join " + db + ".emp_personal_info emp on est.cp_le_id=emp.le_id"
    }
    if (obj['project']['education'].length > 0 || obj['filter']['education'].length > 0) {
      str += " join " + db + ".education edu on est.cp_le_id=edu.le_id"
    }
    if (obj['project']['fixed_pay_amount'].length > 0 || obj['filter']['fixed_pay_amount'].length > 0) {
      str += " join " + db + ".emp_arr_sal_detail fpa on est.arr_id=fpa.arr_id"
    }
    return str;
  }

  createFilterPart(obj:any ) {
    var str = ' where  '
    let arr = Object.values(this.fix_filter)
    arr.map(x => str = str + x + " AND ")
    console.log(str)
    if (obj['filter']['establishment_info'].length > 0) {
      let arr = obj['filter']['establishment_info']
      for (let i = 0; i < arr.length; i++) {
        str += "est." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]].replace("'", "\\'") + "' AND "
        //str += "est." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]] + "' AND "

      }

    }
    if (obj['filter']['emp_personal_info'].length > 0) {
      let arr = obj['filter']['emp_personal_info']
      for (let i = 0; i < arr.length; i++) {
        str += "emp." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]].replace("'", "\\'") + "' AND "
      }

    }
    // if (obj['filter']['post'].length > 0) {
    //   let arr = obj['filter']['post']
    //   for (let i = 0; i < arr.length; i++) {
    //     str += "posti." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]].replace("'", "\\'") + "' AND "
    //   }

    // }

    if (obj['filter']['education'].length > 0) {
      let arr = obj['filter']['education']
      for (let i = 0; i < arr.length; i++) {
        str += "edu." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]].replace("'", "\\'") + "' AND "
      }
      if (obj['filter']['fixed_pay_amount'].length > 0) {
        let arr = obj['filter']['fixed_pay_amount']
        for (let i = 0; i < arr.length; i++) {
          str += "fpa." + Object.keys(arr[i])[0] + "='" + arr[i][Object.keys(arr[i])[0]].replace("'", "\\'") + "' AND "
        }


      }
    }
    str = str.substring(0, str.length - 4)
    return str
  }



  ////////////////////////////////////////////////////////////////////////
  planing_arrproj :any = []
  projecthier :any = []
  projhierobj :any = {}
  projhierobjtype:any  = {}
  projhierobjtypecd :any = {}
  projhierobjisleaf:any  = {}

  allProject = [];
  async getAccountProjectHier() {
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAccountProjectHier(obj);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'HR-root'
        }
      }
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR"))
          && (resp.data[i]['module_cd'] != null || resp.data[i]['module_cd'] != undefined)) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;
      this.allProject = resp.data;
      this.projecthier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_user_cd']
          this.projhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']
        }
      }
    } else {
      console.log("Error in getting all project hier")
    }
  }

  open_activitypopup() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1)
    }

    console.log("Open Pop Up")
    $('#ACTHIER').modal('show');
  }


  down(item:any , k:any ) {
    console.log(k)
    console.log(item)
    console.log(this.planing_arrproj)
    let patharr = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      console.log(this.planing_arrproj[i])

      patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])


    }
    this.Obj['path_desc'] = patharr.join("-->")
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [] })
    console.log(this.planing_arrproj, item)
    let arr:any  = []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_cd'] != null) {

        if ((this.projecthier[i]['lvl' + (k + 1) + '_cd'] == item['hierarchy_type'] || k == -1) && this.projecthier[i]['leaf_node_type'] != 'WORK') {

          this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'] })
          arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])

        }

      }
      /*   if(this.projecthier[i]['lvl'+(k+1)+'_user_cd'] == item['node_cd']){

        } */

    }
  }

  remove(item:any , i:any ) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i])

        patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
      }
      this.Obj['path_desc'] = patharr.join("-->")
    }

  }
  check:any
  setHierarchyNodeitem(item:any , i:any ) {
    console.log(item, i);
    this.check = item['hierarchy_type']
    console.log(this.check);
    item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']]
    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }
    this.Obj['path_desc'] = dummy2.join(' --> ');

  }

  // async updateprojhier() {
  //   let obb = new Object()
  //   for (let i = 0; i < this.projecthier.length; i++) {
  //     if (this.projecthier[i]['leaf_user_cd'] == 'HRARR' + this.joiningObj['arr_id']) {
  //       obb['id'] = this.projecthier[i]['id']
  //     }
  //   }
  //   let level = 0
  //   obb['b_acct_id'] = this.b_acct_id
  //   console.log(this.planing_arrproj)
  //   for (let i = 0; i < this.planing_arrproj.length; i++) {
  //     console.log(this.planing_arrproj[i])
  //     obb['lvl' + this.planing_arrproj[i]['level'] + '_cd'] = this.planing_arrproj[i]['hierarchy_type']
  //     if (this.planing_arrproj[i]['level'] == 1) obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = 'All Projects'
  //     else obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = this.projhierobj[this.planing_arrproj[i]['hierarchy_type']]
  //     obb['lvl' + this.planing_arrproj[i]['level'] + '_node_type'] = this.projhierobjtype[this.planing_arrproj[i]['hierarchy_type']]
  //     obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'] = this.projhierobjtypecd[this.planing_arrproj[i]['hierarchy_type']]
  //     console.log(this.planing_arrproj[i]['level'], obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'])
  //     level = this.planing_arrproj[i]['level']

  //   }
  //   obb['lvl' + (level + 1) + '_user_cd'] = 'HRARR' + this.joiningObj['arr_id']
  //   obb['lvl' + (level + 1) + '_cd'] = this.joiningObj['node_cd']
  //   obb['leaf_cd'] = this.joiningObj['node_cd']
  //   obb['lvl' + (level + 1) + '_node_type'] = 'HREMP'
  //   obb['lvl' + (level + 1) + '_value'] = this.joiningObj['emp_name']
  //   obb['lvl' + (level + 1) + '_type'] = 'text'
  //   obb['leaf_user_cd'] = 'HRARR' + this.joiningObj['arr_id']
  //   obb['leaf_node_type'] = 'HREMP'
  //   obb['leaf_value'] = this.joiningObj['emp_name']
  //   obb['leaf_type'] = 'text'
  //   obb['module_cd'] = 'HR'
  //   console.log(obb);
  //   await this.update();

  //   // this.spinner.show()
  //   // var resp = await this.allEmpService.projectupdateHierarchy(obb);
  //   // if (resp['error'] == false) {
  //   //   await this.update();
  //   //   this.spinner.hide();
  //   // }
  //   // else {
  //   //   this.spinner.hide();
  //   //   Swal.fire('Error...', resp['data'], 'error');
  //   // }
  // }






  printPdf() {
    if (this.result.length == 0) {
      swal.fire("Warning", 'No Data Present For Print !', 'warning');
      return;
    }
    var ar = []
    var header = []
    for (var i = 0; i < this.result.length; i++) {
      if (i == 0) {
        header.push("Sr No");


      }
      var obj = [];
      var keys = []
      var column = Object.keys(this.result[i]);
      obj.push(this.result[i]['serial_no']);
      var arr_id_exist = this.project.includes('arr_id');
      for (let i = 0; i < column.length; i++) {
        if (arr_id_exist) {
          keys.push(column[i])

        } else {
          if ('arr_id' != column[i]) {
            keys.push(column[i])
          }
        }
      }
      for (var j = 0; j < keys.length; j++) {
        if (keys[j] != 'serial_no') {
          if (i == 0) {

            header.push(this.ObjFieldTechNameToBusinessName[keys[j]]);

          }
          if (this.datatype[keys[j]] != 'date') {
            obj.push(this.result[i][keys[j]]);

          }
          else {
            obj.push(this.result[i][keys[j]].split('T')[0]);

          }

        }
      }
      ar.push(obj);

    }
    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")";
    var dd :any = {
      pageMargins: [40, 60, 40, 60],
      pageSize: 'A4',
      header: [
        // { canvas: [{ type: 'line', x1: 10, y1: 30, x2: 595-10, y2: 30, lineWidth: 0.5 }] },

        { text: txt, alignment: 'center', margin: [72, 40] }
        //return obj;
      ],


      pageOrientation: 'portrait',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      //pageMargins: [ 40, 60, 40, 60 ],

      content: [

      ]
    };

    var tbl:any  = {

      layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: [],
        layout: "headerLineOnly",
        body: [




          //[ 'Section Detail', 'Basic\nPay', 'Dep. \nAllow', 'DA/Relief','Medical \nAllow','Vehicle\nAllow','HRA','Wash\nAllow','Misc\nAllow','Total','LIC\n(1,2,3,4,5,6,7)','PF\nDed','Group\nIns.','IT','House\n Rent','Vehicle\n Ded','Vehicle\n Adv.','Bld Adv.\n(1,2,3)','PF Adv.\n(1,2,3)','Bank\n Adv.','EWF','Misc\nDed','Net. Sal.' ]



        ]
      }
    };
    var width = [];
    for (var i = 0; i < header.length; i++) {
      width.push('*');
    }
    tbl.table.widths = width;

    tbl.table.body.push(header);
    //tbl.table.body.push(ar);
    for (var i = 0; i < ar.length; i++)
      tbl.table.body.push(ar[i]);

    dd.content.push("\n")
    dd.content.push({ text: "EMPLOYEE REPORT", alignment: 'center', bold: true });
    dd.content.push("\n")
    dd.content.push(tbl);


    pdfMake.createPdf(dd).download("employee_report.pdf");
  }
  node_cd:any
  node_cd1:any
  async submit_act() {
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']);
    // if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
    //   Swal.fire("Warning", "Please Select at least two level", 'warning')
    // }
    //   else {

    $('#ACTHIER').modal('hide');
    // }
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'])
    this.filter['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']
    this.node_cd = this.filter['node_cd']



  }

}
