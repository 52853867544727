import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EmbHierService } from '../../../emb/service/emb-hier.service';
import { NgxSpinnerService } from "ngx-spinner";
// import { ThrowStmt } from '@angular/compiler';
declare var $: any;
import Swal from 'sweetalert2';
import { AdministrationService } from '../../service/administration.service';
import { MainService as mainService_MD } from '../../../md/service/main.service'
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import { MainService } from '../../service/main.service';
import { PropCommonSService } from '../../service/prop-common-s.service';
@Component({
  selector: 'app-prop-data-assignment',
  templateUrl: './prop-data-assignment.component.html',
  styleUrls: ['./prop-data-assignment.component.css']
})
export class PropDataAssignmentComponent implements OnInit {

  ebillUser:any ;
  b_acct_id:any ;
  Obj :any = {}
  ActiveNode :any = [];
  planing_arr :any = [{}]
  allRoles:any  = [];
  allComp:any ;
  selectedComp:any  = [];
  dataSource:any ;
  data:any  = [{}]
  level1:any  = []
  level2:any  = []
  level3:any  = []
  level4:any  = []
  level5 :any = []
  level6 :any = []
  level7 :any = []
  role_name:any ;
  role_id:any ;
  displayedColumns: string[] = ['node_cd', 'node_desc', 'name', 'mobile', 'status', 'action'];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  constructor(private embHierService: EmbHierService, private commonS: PropCommonSService, private hierarchyS: PropHierarchyService, private mainServiceMD: mainService_MD, private adminstration_S: AdministrationService, private spinner: NgxSpinnerService) { }

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getproject()
    await this.getAllEmployees()
    await this.getList()
    // await this.getAllHeirarchy()
  }
  refresh() {
    this.data = []
    this.data.push({})
    this.Obj = {}
  }
  projecthier:any  = []
  projhierobj:any  = {}
  projhierobjtype:any  = {}
  projhierobjtypecd :any = {}
  project_hier :any = []
  async getproject() {
    let obj:any  = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'PROPERTY'
    let resp = await this.hierarchyS.getprojectHierarchy(obj)
    console.log(resp)
    for (let i = 0; i < resp.data.length; i++) {
      if (resp.data[i]['lvl1_value'] == "All Projects") {
        resp.data[i]['lvl1_value'] = 'PROPERTY-root'

      }
    }
    this.project_hier = resp['data']
    let arr :any = []
    let duumy :any = []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!arr.includes(this.project_hier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.project_hier[i]['lvl1_cd'], lvl1_value: this.project_hier[i]['lvl1_value'], lvl1_node_type: this.project_hier[i]['lvl1_node_type'] })
        arr.push(this.project_hier[i]['lvl1_cd'])
      }
    }
    this.level1 = duumy
    console.log(this.project_hier, this.level1, 'klklklklkllllllllllllllllklklklk')
  }
  allEmplyees_new:any  = []
  allEmployees:any  = []
  allSearchableEmp :any = []
  userObj:any  = {}
  async getAllEmployees() {
    console.log('all emp')
    this.spinner.show()
    var arr = []
    var obj : any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'PROPERTY'
    var resp = await this.mainServiceMD.getuserformodulerolemapping(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      console.log(resp, 'users')
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
      }
      this.allSearchableEmp = arr

    } else {
      this.spinner.hide()
    }
    console.log(this.allEmployees, this.allEmplyees_new, 'employees')
  }
  // existing_heirarchy = []
  // planaing_hierarchy = []
  // acquisition_hierarchy = []
  // async getAllHeirarchy() {
  //   this.spinner.show()
  //   let obj :any= {}
  //   obj['b_acct_id'] = this.ebillUser['b_acct_id']
  //   console.log(obj, 'obj')
  //   var resp = await this.hierarchyS.getHierarchyData(JSON.stringify(obj));
  //   console.log(resp, 'list all heirarchy')
  //   if (resp['error'] == false) {
  //     this.existing_heirarchy = resp['data']
  //     for (let i = 0; i < this.existing_heirarchy.length; i++) {
  //       this.existing_heirarchy[i]['desc'] = this.existing_heirarchy[i]['hier_ident_code'] + ' - ' + this.existing_heirarchy[i]['hier_buss_name']
  //     }
  //     let acq = []
  //     let planing = []
  //     for (let i = 0; i < this.existing_heirarchy.length; i++) {
  //       if (this.existing_heirarchy[i]['hier_type'] == "Acquisition") {
  //         acq.push(this.existing_heirarchy[i])
  //       }
  //       if (this.existing_heirarchy[i]['hier_type'] == "Planing") {
  //         planing.push(this.existing_heirarchy[i])
  //       }
  //     }
  //     this.planaing_hierarchy = planing
  //     this.acquisition_hierarchy = acq

  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire('Error', 'Error While Getting Data', 'error')
  //   }
  // }
  // existing_heirarchy_nodes = []
  // async getAllpropHierarchynodes() {
  //   // this.spinner.show()
  //   this.existing_heirarchy_nodes = []
  //   for (let i = 0; i < this.existing_heirarchy.length; i++) {
  //     if (this.Obj['hier_ident_code'] == this.existing_heirarchy[i]['hier_ident_code']) {
  //       this.Obj['hier_buss_name'] = this.existing_heirarchy[i]['hier_buss_name']
  //       this.Obj['hier_type'] = this.existing_heirarchy[i]['hier_type']
  //       break;
  //     }
  //   }
  //   let obj :any= {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['hier_ident_code'] = this.Obj['hier_ident_code']
  //   obj['status'] = 'ACTIVE'
  //   //console.log(obj)
  //   var resp = await this.hierarchyS.getAllpropHierarchynodes(JSON.stringify(obj));
  //   console.log(resp, 'list all heirarchy')
  //   if (resp['error'] == false) {
  //     this.existing_heirarchy_nodes = resp['data']
  //     let dummy = []
  //     for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
  //       if (1 == this.existing_heirarchy_nodes[i]['level']) {
  //         dummy.push(this.existing_heirarchy_nodes[i])
  //       }
  //     }
  //     this.planing_arr[0]['existing_heirarchy_nodes'] = dummy
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire('Error', 'Error While Getting Data', 'error')
  //   }
  // }
  allHier = []
  async add_dropdown(data:any , index:any ) {
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.data.push({})
    }
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.project_hier, this.Obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.project_hier, this.Obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.project_hier, this.Obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.project_hier, this.Obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.project_hier, this.Obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.project_hier, this.Obj)
    }
    console.log(this.Obj)
  }
  leaf_level = 0
  async remove_dropdown(data:any , index:any ) {
    if (this.data.length > 1) {
      this.data.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.Obj['lvl' + i + '_cd'];
    }
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
    await this.set_Path_desc(9)
  }
  async set_Path_desc(index:any ) {
    if (index == 0) {
      this.Obj['lvl1_value'] = this.level1[0]['lvl1_value']
    }
    let dummy = []
    for (let i = 0; i <= 7; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        // this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        // this.leaf_level = i
        this.Obj['node_cd'] = this.Obj['lvl' + i + '_cd']
        dummy.push(this.Obj['lvl' + i + '_value'])
      }
    }
    this.Obj['path_desc'] = dummy.join('-->')
    console.log(this.Obj)
  }
  // NodeCodeToNodeDesc = {};
  // async getAllHeirNodeList() {
  //   this.spinner.show();
  //   var obj : any= new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   var resp = await this.adminstration_S.getAllPropHierarchy(JSON.stringify(obj));
  //   console.log(resp)
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     console.log(resp['data']);
  //     this.ActiveNode = [];
  //     this.NodeCodeToNodeDesc = {};
  //     for (let i = 0; i < resp.data.length; i++) {
  //       if (resp.data[i]['status'] == 'ACTIVE' && resp.data[i]['is_leaf'] == 0) {
  //         resp['data'][i]['desc'] = resp['data'][i]['node_cd'] + " - " + resp['data'][i]['node_desc'];
  //         this.ActiveNode.push(resp.data[i])
  //       }
  //       this.NodeCodeToNodeDesc[resp['data'][i]['node_cd']] = resp['data'][i]
  //     }
  //     console.log(this.ActiveNode, 'all active node');
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire('Error...', 'Error While HR HIER', 'error')
  //   }
  // }

  // used_nodes = []
  // selected_row = {}
  // add_dropdown(data, index) {

  //   //console.log(data, index)
  //   let flag = true
  //   let obj :any= {}
  //   for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
  //     if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
  //       obj = Object.assign({}, this.existing_heirarchy_nodes[i])
  //     }
  //   }
  //   this.selected_row = Object.assign({}, obj)
  //   this.selected_row['index'] = index

  //   this.filter_data()
  // }
  // filter_data() {
  //   let dummy = []
  //   let max_level = 0
  //   for (let i = 0; i < this.existing_heirarchy.length; i++) {
  //     if (this.Obj['hier_ident_code'] == this.existing_heirarchy[i]['hier_ident_code']) {
  //       max_level = this.existing_heirarchy[i]['level_count']
  //       break;
  //     }
  //   }
  //   //console.log(max_level, this.selected_row, this.existing_heirarchy_nodes)
  //   for (let j = 1; j <= max_level; j++) {
  //     for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
  //       let node_arr = []
  //       node_arr = this.existing_heirarchy_nodes[i]['path'].split('|')
  //       //console.log(node_arr, 'noida arr', this.existing_heirarchy_nodes[i], node_arr.includes(this.selected_row['node_cd']), this.selected_row['node_cd'])
  //       if (node_arr.includes(this.selected_row['node_cd'])) {
  //         if (this.existing_heirarchy_nodes[i]['level'] == j && this.existing_heirarchy_nodes[i]['level'] > this.selected_row['level']) {
  //           dummy.push(this.existing_heirarchy_nodes[i])
  //         }
  //       }
  //     }
  //     if (dummy.length > 0) {
  //       break;
  //     }
  //   }
  //   this.planing_arr.push({})
  //   this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = []
  //   this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = dummy
  //   console.log(dummy, 'dummy', this.planing_arr)
  // }
  // remove_dropdown(data, i) {
  //   console.log(data, i)
  //   if (this.planing_arr.length > 1) {
  //     this.planing_arr.pop()
  //   }
  //   let dummy = []
  //   for (let i = 0; i < this.planing_arr.length; i++) {
  //     dummy.push(this.planing_arr[i]['hierarchy_type'])
  //   }
  //   let dummy2 = []
  //   if (dummy.length > 0) {
  //     for (let i = 0; i < dummy.length; i++) {
  //       for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
  //         if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
  //           this.Obj['node_cd']=this.existing_heirarchy_nodes[j]['node_cd']
  //           dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
  //         }
  //       }
  //     }
  //   }
  //   this.Obj['path_desc'] = dummy2.join(' --> ')
  // }
  // setHierarchyNode(item, i) {
  //   this.Obj['node_cd'] = item['hierarchy_type']
  //   let dummy = []
  //   console.log(item, i)
  //   for (let i = 0; i < this.planing_arr.length; i++) {
  //     dummy.push(this.planing_arr[i]['hierarchy_type'])
  //   }
  //   let dummy2 = []
  //   if (dummy.length > 0) {
  //     for (let i = 0; i < dummy.length; i++) {
  //       for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
  //         if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
  //           dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
  //         }
  //       }
  //     }
  //   }
  //   this.Obj['path_desc'] = dummy2.join(' --> ')
  // }


  async getList() {
    this.spinner.show();
    let obj :any = {}
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    var resp = await this.adminstration_S.getPropertyAssigneddata2(JSON.stringify(obj));
    console.log(resp, resp, 'get list')
    if (resp['error'] == false) {
      this.spinner.hide();
      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['name'] = this.userObj[resp['data'][i]['user_id']]['emp_name']
        resp['data'][i]['mobile'] = this.userObj[resp['data'][i]['user_id']]['emp_phone_no']
        for (let j = 0; j < this.project_hier.length; j++) {
          if (this.project_hier[j]['leaf_cd'] == resp['data'][i]['node_cd']) {
            let ob:any  = {}
            ob = Object.assign({}, this.project_hier[j])
            let dummy = []
            for (let k = 0; k <= 7; k++) {
              if (ob['lvl' + k + '_cd']) {
                dummy.push(ob['lvl' + k + '_value'])
              }
            }
            resp['data'][i]['desc'] = dummy.join('-->')
          }

        }
      }
      this.dataSource = new MatTableDataSource(resp['data'])
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Some Error Occured', 'error');
    }

  }

  // flag = false
  // open_update(element:any) {
  //   this.Obj = Object.assign({}, element)
  //   this.Obj['res_cd'] = this.Obj['res_cd'].split(",")
  //   this.flag = false
  //   if (this.Obj['role_cd'] == 'AA' || this.Obj['role_cd'] == 'ZA') {
  //     this.flag = true;
  //     this.Obj['res_cd'] = []
  //     for (let i = 0; i < this.allResources.length; i++) {
  //       this.Obj['res_cd'].push(this.allResources[i]['res_cd'])
  //     }
  //   }

  //   $('.nav-tabs a[href="#tab-7-4"]').tab('show');
  // }

  async inactive(element:any) {
    console.log(element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Inactive it!'
    }).then((result) => {
      if (result.value) {
        this.finalInactive(element)
      }
    })
  }
  async finalInactive(element:any) {

    this.spinner.show();
    var obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    obj['update_user_id'] = this.ebillUser.user_id;
    obj['status'] = 'INACTIVE';
    console.log(obj)
    var resp = await this.adminstration_S.updatePropdataAssign(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getList();
      Swal.fire("Success", "... Node InActive", 'success');

    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Some Error Occured', 'error');
    }

  }

  async submit() {
    console.log(this.Obj)
    if (this.data.length < 2) {
      Swal.fire('info..', 'Please Select The Node Atleast Two Level', 'info')
      return
    }
    if (this.Obj['node_cd'] && this.Obj['user_id']) {
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['create_user_id'] = this.ebillUser.user_id;
      this.Obj['status'] = 'ACTIVE';
      console.log(this.Obj)
      var resp = await this.adminstration_S.insertPropDataAssign(this.Obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getList();
        this.data = []
        this.Obj = Object.assign({}, {})
        Swal.fire("Success..", "Created Successfully", 'success');
        $('.nav-tabs a[href="#tab-7-1"]').tab('show');
      }
      else {
        this.spinner.hide();
        Swal.fire('Error...', 'Error While Create A New Node..', 'error');
      }
    } else {
      Swal.fire('Warning...', 'Please Select All Fields', 'error')
    }
  }


  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
