

import { Component, OnInit,AfterViewInit, ViewEncapsulation } from '@angular/core';
import {Helpers} from "../../helpers";
import {Router} from '@angular/router'
import { NgxSpinnerService } from "ngx-spinner";
import {MainService} from '../service/main.service';
import {TrackingSettingService} from '../service/tracking-setting.service'
import { AuthenticationService } from '../../authentication.service';
import { Mainservice2Service } from '../service/mainservice2.service';
import {MainService as partalmain} from '../../portal/service/main.service';
import { CommonDataService } from '../../emb/common _services/common-data.service';


@Component({
  selector: '.page-wrapper',
  templateUrl: './tracking-layout.component.html',
  styleUrls: ['./tracking-layout.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TrackingLayoutComponent implements OnInit {
  allLanguage: any;
  language_cd: any;
  constructor(private settingService: TrackingSettingService,
    private AuthenticationService:AuthenticationService,
    private spinner:NgxSpinnerService,
     private mainService: MainService,private router : Router,
     private partalmain:partalmain,
     public mainService2:Mainservice2Service,
     private CommonDataService:CommonDataService) { }
  b_acct_id=-1;
  erpUser :any;
  allFields :any=[];
  allCodeValue :any=[];
  codeValueObj:any={};
  codeValueTechObj:any={};
  codeValueShowObj:any={};
  codeValueShowTempObj:any={};

  async ngOnInit() {
    this.getAllLanguage()
     console.log("i am in")
    if(localStorage.getItem('erpUser')==undefined || localStorage.getItem('erpUser')==null){
      this.router.navigate(['/login']);
    }else{
      this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
      this.b_acct_id = this.erpUser.b_acct_id;
      await this.getCodeValue();
      await this.getCodeValue2();

    }
    await this.CommonDataService.getWorkOrderTOWorkIdInfo(this.b_acct_id);
    await this.CommonDataService.getAssignedWorkInfo(this.b_acct_id,this.erpUser['user_id']);
    await this.CommonDataService.getAllRolesWIthResource(this.b_acct_id);
    await this.getAllLabel()
    this.mainService2.getAllLanguage()
    this.mainService2.getAllLabel()
      this.CommonDataService.getAllWorkInfo(this.b_acct_id)
  }
  allLabel :any= []
	async getAllLabel() {
		var obj:any = new Object();
		obj['module_cd']='PROPERTY'
		console.log(obj,'obj for get all lebels')
		var resp = await this.AuthenticationService.getcomponentLabelvalueswithLanguage(JSON.stringify(obj));
		console.log(resp);
		if (resp['error'] == false) {
			this.AuthenticationService.allLabelProp = resp.data;
			var obj:any = new Object();
			for (let i = 0; i < resp.data.length; i++) {
				obj[resp.data[i]['language_cd'] + resp.data[i]['component_cd'] + resp.data[i]['label_no']] = resp.data[i]['value_to_show'];
			}
			console.log(obj)
			this.AuthenticationService.allLableShowObjProp = obj;
      console.log(this.AuthenticationService.allLableShowObjProp)
		} else {

		}
	}

  // async getAllLabel1() {
	// 	var obj = new Object();
	// 	obj['module_cd']='ICMS'
	// 	console.log(obj,'obj for get all lebels')
	// 	var resp = await this.AuthenticationService.getcomponentLabelvalueswithLanguage1(JSON.stringify(obj));
	// 	console.log(resp);
	// 	if (resp['error'] == false) {
	// 		this.AuthenticationService.allLabelProp = resp.data;
	// 		var obj = new Object();
	// 		for (let i = 0; i < resp.data.length; i++) {
	// 			obj[resp.data[i]['language_cd'] + resp.data[i]['component_cd'] + resp.data[i]['label_no']] = resp.data[i]['value_to_show'];
	// 		}
	// 		console.log(obj)
	// 		this.AuthenticationService.allLableShowObjProp = obj;
  //     console.log(this.AuthenticationService.allLableShowObjProp)
	// 	} else {

	// 	}
	// }


  async getCodeValue(){
   this.spinner.show()
    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj :any={}
    var codeValueShowTempObj:any={};
    if(resp['error']==false){
      this.spinner.hide()
      for(var i=0;i<resp.data.length;i++){
        if(codeValueTempObj[resp.data[i]['field_code']]== undefined){
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] ={}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
    }else{
      this.spinner.hide()
    }
  }
  async getAllLanguage() {
    console.log("i am in 2")
    var obj = new Object();
    var resp = await this.mainService2.getAllLanguages(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.allLanguage = resp.data;
      // this.language_cd = this.auth.language_cd
    } else {

    }
  }

  async getCodeValue2(){
    console.log("i am in here")
    this.spinner.show()
     var resp = await this.settingService.getCodeValue(this.b_acct_id);
     var codeValueTempObj:any={}
     var codeValueShowTempObj:any={};
     if(resp['error']==false){
       this.spinner.hide()
       for(var i=0;i<resp.data.length;i++){
         if(codeValueTempObj[resp.data[i]['field_code']]== undefined){
           codeValueTempObj[resp.data[i]['field_code']] = [];
           codeValueShowTempObj[resp.data[i]['field_code']] ={}
         }
         codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
         codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
       }
       this.codeValueObj = codeValueTempObj;
       this.codeValueShowObj = codeValueShowTempObj;
       this.mainService2.codeValueTechObj = this.codeValueObj;
       this.mainService2.codeValueShowObj = this.codeValueShowObj;
     }else{
       this.spinner.hide()
     }
   }
   async getAllTaskOfUser() {
    var obj = Object()
    obj['b_acct_id'] = this.erpUser.b_acct_id
    obj['user_id'] = this.erpUser.user_id
    obj["status"] = 'PENDING'
    console.log(obj)
    var resp = await this.partalmain.getAllTaskOfUser(JSON.stringify(obj))
    if (resp['error'] == false) {
      // this.spinner.hide();
      console.log(resp['data'])
      this.mainService2.count = resp['data'].length
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      // this.spinner.hide();
      //swal.fire("Error", resp['data'], 'error');
    }
  }

  ngAfterViewInit() {

    // initialize layout: handlers, menu ...
    Helpers.initLayout();

  }

}

