<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="col-12 text-right">
                        <h6> {{current_user}}</h6>
                    </div>
                    <div class="ibox-title"></div>
                </div>
                <div class="ibox-body">

                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item active">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="fa fa-line-chart"></i>
                                Recovery Calculation
                            </a>
                        </li>


                    </ul>

                    <div class="tab-content">
                        <div class="active" id="tab-1">
                            <div class="tab-content">

                                <div class="row ">
                                    <div class="col-3"></div>
                                    <div class="col-3">
                                        <h6>Order Type: </h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [multiple]="false" [(ngModel)]="selectedData['demolition']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="true" disabled>
                                        </ng-select>
                                    </div>
                                    <div class="col-3"></div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3">
                                        <h6>Case ID : </h6>
                                    </div>
                                    <div class="col-3">

                                        <ng-select [items]="inspection_data" bindLabel="case_id" bindValue="case_id"
                                            [multiple]="false" placeholder="Select Order ID"
                                            (change)="getOrderDetails()" [(ngModel)]="selectedData['case_id']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="true">
                                        </ng-select>
                                    </div>
                                    <div class="col-3"></div>
                                </div>
                                <br>
                                <div *ngIf="court_data.length>0">
                                    <!-- <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Order ID: </h6>
                                        </div>
                                        <div class="col-3">{{court_data[0]['co_ord_id']}} </div>
                                        <div class="col-3 text-left">
                                            <h6>Order Date: </h6>
                                        </div>
                                        <div class="col-3">{{court_data[0]['co_ord_date']}}</div>
                                    </div> -->


                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Order ID : </h6>
                                        </div>
                                        <div class="col-3">{{court_data[0]['co_ord_id']}} </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Order Date: </h6>
                                        </div>
                                        <div class="col-3">{{court_data[0]['co_ord_date']}}</div>
                                        <div class="col-3"></div>
                                    </div>

                                    <br>
                                    <!-- <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Case ID: </h6>
                                        </div>
                                        <div class="col-3"> {{court_data[0]['case_id_f']}} </div>
                                        <div class="col-3 text-left">
                                            <h6>Order Compilation Time: </h6>
                                        </div>
                                       
                                        <div class="col-3">
                                            <span
                                                *ngIf="court_data[0]['comp_time_limit'] != 'Null' || court_data[0]['comp_time_limit'] != ''">
                                                {{court_data[0]['comp_time_limit']}}</span>
                                            <span
                                                *ngIf="court_data[0]['comp_day_limit'] != 'Null' || court_data[0]['comp_day_limit'] != 'Null'">
                                                {{court_data[0]['comp_day_limit']}}</span>
                                        </div>

                                    </div> -->




                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Case ID: </h6>
                                        </div>
                                        <div class="col-3">{{court_data[0]['case_id_f']}}</div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Order Compilation Time: </h6>
                                        </div>
                                        <div class="col-3"> <span
                                                *ngIf="court_data[0]['comp_time_limit'] != 'Null' || court_data[0]['comp_time_limit'] != ''">
                                                {{court_data[0]['comp_time_limit']}}</span>
                                            <span
                                                *ngIf="court_data[0]['comp_day_limit'] != 'Null' || court_data[0]['comp_day_limit'] != 'Null'">
                                                {{court_data[0]['comp_day_limit']}}</span>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <!-- <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Order Type: </h6>
                                        </div>
                                        <div class="col-3"> {{court_data[0]['co_ord_type']}} </div>
                                        <div class="col-3 text-left">
                                            <h6>Order Description: </h6>
                                        </div>
                                        <div class="col-3"> {{court_data[0]['co_summary']}} </div>
                                    </div> -->


                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Order Type: </h6>
                                        </div>
                                        <div class="col-3">{{court_data[0]['co_ord_type']}} </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Order Description: </h6>
                                        </div>
                                        <div class="col-3">{{court_data[0]['co_summary']}} </div>
                                        <div class="col-3"></div>
                                    </div>

                                    <br>
                                    <div class="row text-left">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Order Execution date : <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="date" class="form-control"
                                                [(ngModel)]="selectedData['exe_date']">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Recovery Amount : <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" class="form-control"
                                                [(ngModel)]="selectedData['rec_amt']">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Payment Due Date : <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="date" class="form-control"
                                                [(ngModel)]="selectedData['due_date']">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row ">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Attachment (if any): </h6>
                                        </div>
                                        <div class="col-3">
                                            <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader1"
                                                class="form-control" style="border-color: black"
                                                (change)="onFileUpload1($event,file.files)">
                                        </div>
                                        <div class="col-3">
                                            <button *ngIf="viewButton==false" class="btn btn-outline-success"
                                                (click)="upload()">Upload</button>
                                            <button *ngIf="viewButton" class="btn btn-outline-success"
                                                (click)="viewDoc()">View</button>
                                        </div>
                                    </div>
                                </div>
                                <br><br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-outline-primary" (click)="submit()">Submit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>