import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmdLayoutComponent } from './emd-layout.component';
import { RoleGuardService } from '../../role-guard.service';
import { EmdDashboardComponent } from '../emd-dashboard/emd-dashboard.component';
import { EmdAdministrationComponent } from '../emd-administration/emd-administration.component';
import { EmdRoleComponent } from '../emd-administration/emd-role/emd-role.component';
import { EmdUserComponent } from '../emd-administration/emd-user/emd-user.component';
import { DataAssignmentComponent } from '../emd-administration/data-assignment/data-assignment.component';
import { WorkflowComponent } from '../emd-setting/workflow/workflow.component';
import { EmdSettingComponent } from '../emd-setting/emd-setting.component';
import { FieldComponent } from '../emd-setting/field/field.component';
import { CodeValueComponent } from '../emd-setting/code-value/code-value.component';
import { HierarchyComponent } from '../emd-setting/hierarchy/hierarchy.component';
import { EmdHelpComponent } from '../emd-help/emd-help.component';
import { TenderComponent } from '../tender/tender.component';
import { VendorComponent } from '../vendor/vendor.component';
import { PaymentComponent } from '../payment/payment.component';
import { AssignTenderComponent } from '../assign-tender/assign-tender.component';
import { EmdRefundComponent } from '../emd-refund/emd-refund.component';


const routes: Routes = [
  {
    path: "emd-index",
    component: EmdDashboardComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R1' }
  },
  {
    path: "tender",
    component: TenderComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R12' }
  },
  {
    path: "vendor",
    component: VendorComponent,
    canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R13' }
  },
  {
    path: "payment",
    component: PaymentComponent,
     canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R14' }
  },
  {
    path: "assign-tender",
    component: AssignTenderComponent,
     canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R15' }
  },
  {
    path: "emd-refund",
    component:EmdRefundComponent,

    canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R16' }
  },
 
  {
    "path": "administration",
    "component": EmdAdministrationComponent,
    "children": [

      {
        path: "roles",
        component: EmdRoleComponent, 
         canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R5' }
      },
      {
        path: "users",
        component: EmdUserComponent, 
         canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R6' }
      },

      {
        path: "data-assignment",
        component: DataAssignmentComponent, 
         canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R7' }
      },

    ]
  },

  {

    "path": "emd-setting",
    "component": EmdSettingComponent,
    "children": [
      {
        path: "code-value",
        component: CodeValueComponent, 
         canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R10' }
      },
      {
        path: "field",
        component: FieldComponent, 
         canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R9' }
      },
    
      {
        path: "workflow",
        component: WorkflowComponent, 
        canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R8' }
      },
      {
        path: "hierarchy",
        component: HierarchyComponent, 
        canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R11' }

      }
      
    ]

  },




  {
    "path": "help",
    "component": EmdHelpComponent,
    "children": [
    //   {
    //     path: "faq",
    //     component: EMDFaqComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R8' }
    //   },
    //   {
    //     path: "user-manual",
    //     component: EMDUserManualComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R8' }
    //   },
    //   {
    //     path: "tutorial",
    //     component: EMDTutorialComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R8' }
    //   },
    //   {
    //     path: "online-support",
    //     component: EMDOnlineSupportComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R8' }
    //   },
    ]
  }




];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EMDLayoutRoutingModule { }
