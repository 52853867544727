import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AuthenticationService } from '../../authentication.service';
@Injectable({
  providedIn: 'root'
})
export class MainService {


  constructor(private http: HttpClient, private auth: AuthenticationService) { }
  profileImageUrl: any;
  codeValueTechObj: any = {};
  accountImageUrl: any;
  accInfo : any= {}
  codeValueShowObj: any = {};
  allLableShowObj: any = {}
  allLabel: any = []
  //httpUrl="http://localhost:30001";
  //httpUrl="http://139.59.61.84:3000";
  uiUrl="https://erpuat.upda.co.in/public/#/prop/sale"
  // httpUrl="https://vdaerp.pcstech.com:3002";
  httpUrl = this.auth.httpUrl;
  dateFormatChange(date: any) {
    var datear1 = date.split('T')[0]
    var datearr = datear1.split("-")
    return datearr[2] + '/' + datearr[1] + '/' + datearr[0]
  }

  async getFields(obj: any) {

    const res = await this.http.get<any>(this.httpUrl + '/metadata/fields/getfields' + obj).toPromise().then(res=> {
      return res;

    });
    return res;

  }

  changeDate(date_inp: any) {
    let date = new Date(date_inp)
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var day = date.getDate(), monthIndex = date.getMonth(), year = date.getFullYear()
    return monthNames[monthIndex] + " " + day + ',' + year;
  }
  async getCodeValue(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/metadata/codeValue/getCodeValues' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createLegalEntity(obj: any) {
    const res = await this.http.post<any>(this.httpUrl + '/systemdata/legalentity/createindentity', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getCurrentLegalEntity(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/systemdata/legalentity/getcurrentlegalentity' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}

