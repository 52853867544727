import { Injectable } from '@angular/core';
import { MainService } from '../service/main.service';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { stringify } from 'querystring';
import { TokenService } from '../../token.service';
@Injectable({
  providedIn: 'root'
})
export class AttendaceMangService {

  httpUrl;

  employee_id: any;
  //Avinash
  constructor(private http: HttpClient, private main: MainService, private token: TokenService) {
    this.httpUrl = this.main.httpUrl;
  }
  async holiday_list(obj: any) {
    console.log(obj, "from services 8-3-22")
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/calendar_rule/get_holiday_list', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insert_holiday(obj: any) {
    console.log(obj, "from services 8-3-22")
    console.log(this.httpUrl, "this.httpUrl  from services 8-3-22")
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/calendar_rule/insert_holiday', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async update_holiday(obj: any) {
    console.log(obj, "from services 8-3-22")
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/calendar_rule/update_holiday', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async delete(obj: any) {
    console.log(obj, "from services 8-3-22")
    const resp = this.http.put(this.httpUrl + '/hr/attendance_mang/calendar_rule/delete', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // Avinash Mishra

  async listOfExtraWork( obj:any  ) {
    let resp = this.http.post<any>(this.httpUrl + '/hr/attendance_mang/extrawork/listOfWork', obj).toPromise().then((res) => {
      return res;
    })
    return resp;
  }


  async getRuleOfTime( obj:any  ) {
    let resp = this.http.post(this.httpUrl + '/hr/attendance_mang/extrawork/getRule', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async saveExtWork( obj:any  ) {
    let resp = this.http.post(this.httpUrl + '/hr/attendance_mang/extrawork/saveExtraWork', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async changeStatusOfExtraWork( obj:any  ) {
    let resp = this.http.put<any>(this.httpUrl + '/hr/attendance_mang/extrawork/changeStatusOfExtraWork', obj).toPromise().then((res) => {
      return res;
    })
    return resp;
  }

  async insertTaskworkFlow(obj: any) {
    // console.log("attend service 000000000000000")
    const res = await this.http.post<any>(this.httpUrl + '/hr/attendance_mang/extrawork/insertTask', obj, { headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session) }).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async insert_rule(obj: any) {
    // console.log(obj, "from services 8-3-22")
    // console.log(this.httpUrl, "this.httpUrl  from services 8-3-22")
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/extrawork/insert_rule', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async get_rule(obj: any) {
    // console.log(obj, "from services 8-3-22")
    // console.log(this.httpUrl, "this.httpUrl  from services 8-3-22")
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/extrawork/get_rule', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getMorningExtra( obj:any  ) {
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/extrawork/getMorExt', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getMorningLate( obj:any  ) {
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/extrawork/getMorLate', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async allowLate( obj:any  ) {
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/extrawork/allowLate', obj).toPromise().then((res) => {
      return res;
    })
    return resp;
  }
  async getEveExtra( obj:any  ) {
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/extrawork/getEveExt', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // Leave List

  async getLeaveList( obj:any  ) {
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/extrawork/getLeaveList', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // ectra leave
  async getExtraLeave( obj:any  ) {
    const resp = this.http.post(this.httpUrl + '/hr/attendance_mang/extrawork/extraLeaveList', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // apply for extra leave

  async applyForExtraLeave(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/attendance_mang/extrawork/applyForExtraLeave' + obj, "").toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //leaveListForAllow

  async leaveListForAllow(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/attendance_mang/extrawork/leaveListForAllow', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  // allowed for extra leave
  async allowLeave(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/attendance_mang/extrawork/allowedExtraLeave', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // already presenty check

  async checkAllreadyPresenty(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/attendance_mang/extrawork/checkAllreadyPresent', obj).toPromise().then(res => {
      return res;
    })

    // .catch()
    // {
    //    let error= new Object();
    //    error['data']='THis is hte catch part '
    //    return error;
    // };
    return resp;
  }
  // chceck presenty of emp
  async checkPresenty(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/attendance_mang/extrawork/totalPresent', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getallworkingdays(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/attendance_mang/extrawork/getallworkingdays', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // Avinash
  // total present on which day
  async totalPresent( obj:any  ) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/attendance_mang/extrawork/dayWhenPresent', obj).toPromise().then(res => {
      return res;
    })
    return resp
  }

  // m_avinash
  // get calender rule data
  async getCalRule( obj:any  ) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/attendance_mang/extrawork/getCalRule', obj).toPromise().then(res => {
      return res
    })
    return resp
  }



}
