<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Trail Balance Report</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <!--

    <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i
                                    class="ti-bar-chart"></i>
                                Trial Balance 1</a>
                        </li> -->

                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-2" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Trial Balance </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <!--   <div class="tab-pane fade show active" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Process Date :
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['proc_dt']">
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select As On Date :
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['acct_dt']">

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Ledger Type :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="ledger" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="Obj['ledger_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Financial Year :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['ACC0025']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="" [(ngModel)]="Obj['fin_year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getTrailBalance()">GET TRIAL BALANCE</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4"></div>
                                <div class="col-4 text-right">
                                    <button class="btn btn-primary" (click)="print1()">PRINT</button>
                                </div>


                            </div>


                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ i+1 }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="lvl1_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 1 Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl1_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl1_value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 1 Value

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl1_value }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl2_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 2 Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl2_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl2_value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 2 Value

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl2_value }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl3_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 3 Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl3_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl3_value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 3 Value

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl3_value }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl4_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 4 Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl4_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl4_value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 4 Value

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl4_value }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl5_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 5 Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl5_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl5_value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 5 Value

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl5_value }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl7_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 7 Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl7_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl7_value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 7 Value

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl7_value }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl6_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 6 Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl6_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lvl6_value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 6 Value

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lvl6_value }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="cr">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Credit

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.cr }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="db">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Debit

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.db }} </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div> -->
                        <div class="tab-pane fade show active" id="tab-2">
                            <br>

                            <!--                          <div class="row text-left"  style="margin-left: 2%;margin-right: 2;">
                                <div class="col-4 text-left">
                                    Select Process Date :
                                </div>
                                <div class="col-4 text-left" >
                                    <input type="date" class="form-control" [(ngModel)]="Obj['proc_dt']">
                                </div>

                            </div>
 -->
                            <br>
                            <form name="form" (ngSubmit)="f.form.valid && getTrailBalance()" #f="ngForm">
                                <div class="row text-left" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        Select As On Date :
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="Obj['acct_dt']"
                                            name="as_on_date" #as_on_date="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && as_on_date.invalid }" required>



                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="as_on_date.dirty && as_on_date.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && as_on_date.invalid" class="error-message">
                                            <div *ngIf="as_on_date.errors?.['required']">Please Select As On Date</div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->








                                    </div>

                                </div>
                                <br>
                                <div class="row text-left" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        Select Organisation :
                                    </div>
                                    <div class="col-4">
                                        <!-- <input type="date" class="form-control" [(ngModel)]="Obj['acct_dt']"> -->
                                        {{orgObj[Obj['org_cd']]}}
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-primary"
                                            (click)="selectorg()">Select</button>
                                    </div>
                                </div>

                                <!-- <br>
                            <div class="row text-left" style="margin-left: 2%;margin-right: 2;">
                                <div class="col-4 text-left" >
                                    Select Ledger Type :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="ledger" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="Obj['ledger_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row text-left" style="margin-left: 2%;margin-right: 2;">
                                <div class="col-4 text-left" >
                                    Financial Year :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['ACC0025']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="" [(ngModel)]="Obj['fin_year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div> -->
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary" type="submit">GET TRIAL
                                            BALANCE</button>

                                    </div>
                                </div>
                            </form>
                            <br>
                            <hr>
                            <div class="text-right">
                                <button class="btn btn-primary" (click)="print5()"
                                    style="margin-left: 5%;">Print</button> &nbsp;&nbsp;
                                <button class="btn btn-success" (click)="export()">Export</button> &nbsp;&nbsp;
                            </div>
                            <div id="e">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>{{mainService.accInfo['account_name']}}</h6>
                                    </div>

                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-right">
                                        <h6>Financial Year :</h6>
                                    </div>
                                    <div class="col-3">{{Obj['fin_year']}}</div>
                                    <div class="col-3 text-right">
                                        <h6>Till Date :</h6>
                                    </div>
                                    <div class="col-3">
                                        <p *ngIf="Obj['acct_dt']">{{mainService.dateFormatChange(Obj['acct_dt'])}}
                                        </p>
                                    </div>


                                </div>
                                <br>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Chart Of Account</th>


                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="asset.length>0"><b>ASSET</b></tr>
                                        <tr *ngFor="let item of asset">
                                            <td> <b (click)="getDetailsLele5(item.lvl4_code , item.lvl4_value)"
                                                    style="color: #217dbb;cursor: pointer;"> {{item['lvl4_code']}} -
                                                    {{item['lvl4_value']}} </b> </td>
                                            <td style="text-align:right">{{allAmountDb[item['lvl4_code']]}}</td>
                                            <td style="text-align:right">{{allAmountCr[item['lvl4_code']]}}</td>

                                            <td style="text-align:right">

                                                <label
                                                    *ngIf="(allAmountDb[item['lvl4_code']]>allAmountCr[item['lvl4_code']]);else elseBlock">
                                                    {{allAmountDb[item['lvl4_code']] -
                                                    allAmountCr[item['lvl4_code']]}}(DR)</label>
                                                <ng-template #elseBlock>
                                                    {{allAmountCr[item['lvl4_code']] -
                                                    allAmountDb[item['lvl4_code']]
                                                    }}(CR)</ng-template>
                                            </td>

                                        </tr>
                                        <tr *ngIf="liability.length>0"><b>LIABILITIES</b></tr>
                                        <tr *ngFor="let item of liability">

                                            <td> <b (click)="getDetailsLele5(item.lvl4_code , item.lvl4_value)"
                                                    style="color: #217dbb;cursor: pointer;"> {{item['lvl4_code']}} -
                                                    {{item['lvl4_value']}} </b> </td>
                                            <td style="text-align:right">{{allAmountDb[item['lvl4_code']]}}</td>
                                            <td style="text-align:right">{{allAmountCr[item['lvl4_code']]}}</td>
                                            <td style="text-align:right"><label
                                                    *ngIf="(allAmountDb[item['lvl4_code']]>allAmountCr[item['lvl4_code']]);else elseBlock">
                                                    {{allAmountDb[item['lvl4_code']] -
                                                    allAmountCr[item['lvl4_code']]}}(DR)</label>
                                                <ng-template #elseBlock>
                                                    {{allAmountCr[item['lvl4_code']] -
                                                    allAmountDb[item['lvl4_code']]
                                                    }}(CR)</ng-template>
                                            </td>
                                        </tr>
                                        <tr *ngIf="equity.length>0"><b>EQUITIES</b></tr>
                                        <tr *ngFor="let item of equity">


                                            <td> <b (click)="getDetailsLele5(item.lvl4_code , item.lvl4_value)"
                                                    style="color: #217dbb;cursor: pointer;"> {{item['lvl4_code']}} -
                                                    {{item['lvl4_value']}} </b> </td>
                                            <td style="text-align:right">{{allAmountDb[item['lvl4_code']]}}</td>
                                            <td style="text-align:right">{{allAmountCr[item['lvl4_code']]}}</td>
                                            <td style="text-align:right"><label
                                                    *ngIf="(allAmountDb[item['lvl4_code']]>allAmountCr[item['lvl4_code']]);else elseBlock">
                                                    {{allAmountDb[item['lvl4_code']] -
                                                    allAmountCr[item['lvl4_code']]}}(DR)</label>
                                                <ng-template #elseBlock>
                                                    {{allAmountCr[item['lvl4_code']] -
                                                    allAmountDb[item['lvl4_code']]
                                                    }}(CR)</ng-template>
                                            </td>
                                        </tr>
                                        <tr *ngIf="income.length>0"><b>INCOME</b></tr>
                                        <tr *ngFor="let item of income">

                                            <td> <b (click)="getDetailsLele5(item.lvl4_code , item.lvl4_value)"
                                                    style="color: #217dbb;cursor: pointer;"> {{item['lvl4_code']}} -
                                                    {{item['lvl4_value']}} </b> </td>
                                            <td style="text-align:right">{{allAmountDb[item['lvl4_code']]}}</td>
                                            <td style="text-align:right">{{allAmountCr[item['lvl4_code']]}}</td>
                                            <td style="text-align:right"><label
                                                    *ngIf="(allAmountDb[item['lvl4_code']]>allAmountCr[item['lvl4_code']]);else elseBlock">
                                                    {{allAmountDb[item['lvl4_code']] -
                                                    allAmountCr[item['lvl4_code']]}}(DR)</label>
                                                <ng-template #elseBlock>
                                                    {{allAmountCr[item['lvl4_code']] -
                                                    allAmountDb[item['lvl4_code']]
                                                    }}(CR)</ng-template>
                                            </td>
                                        </tr>
                                        <tr *ngIf="expense.length>0"><b>EXPENSE</b></tr>
                                        <tr *ngFor="let item of expense">

                                            <td> <b (click)="getDetailsLele5(item.lvl4_code , item.lvl4_value)"
                                                    style="color: #217dbb;cursor: pointer;"> {{item['lvl4_code']}} -
                                                    {{item['lvl4_value']}} </b> </td>

                                            <td style="text-align:right">{{allAmountDb[item['lvl4_code']]}}</td>
                                            <td style="text-align:right">{{allAmountCr[item['lvl4_code']]}}</td>

                                            <td style="text-align:right"><label
                                                    *ngIf="(allAmountDb[item['lvl4_code']]>allAmountCr[item['lvl4_code']]);else elseBlock">
                                                    {{allAmountDb[item['lvl4_code']] -
                                                    allAmountCr[item['lvl4_code']]}}(DR)</label>
                                                <ng-template #elseBlock>
                                                    {{allAmountCr[item['lvl4_code']] -
                                                    allAmountDb[item['lvl4_code']]
                                                    }}(CR)</ng-template>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:right"><b>TOTAL</b></td>
                                            <td style="text-align:right"><b>{{totaldb}}</b></td>
                                            <td style="text-align:right"><b>{{totalcr}}</b></td>
                                            <td style="text-align:right">{{totalbal}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="selectOrg">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> Select Organisation:</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <br>
                <div class="row">
                    <div class="col-3 text-center">


                    </div>
                    <div class="col-3 text-center"
                        style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                        <h6><b>Code</b></h6>


                    </div>
                    <div class="col-3 text-center"
                        style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                        <h6><b> Value</b></h6>


                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        Level 1:
                    </div>
                    <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl1_cd']" disabled>
                    </div>

                    <div class="col-3">
                        <ng-select [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd" (change)="onChangeLvl1()"
                            [multiple]="false" placeholder="Select Level 1 " [(ngModel)]="HierSelectObj['lvl1_cd']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="lvl1_value">
                        </ng-select>
                    </div>

                </div>
                <br>

                <div class="row">
                    <div class="col-3 text-right">
                        Level 2 :
                    </div>
                    <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl2_cd']" disabled>

                    </div>

                    <div class="col-3">
                        <ng-select [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd" (change)="onChangeLvl2()"
                            [multiple]="false" placeholder="Select Level 2" [(ngModel)]="HierSelectObj['lvl2_cd']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="lvl2_value">
                        </ng-select>
                    </div>


                </div>
                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6> Level 3 :</h6>
                    </div>
                    <div class="col-3">

                        <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl3_cd']" disabled>

                    </div>

                    <div class="col-3">
                        <ng-select [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd" (change)="onChangeLvl3()"
                            [multiple]="false" placeholder="Select Level 3" [(ngModel)]="HierSelectObj['lvl3_cd']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="lvl3_value">
                        </ng-select>
                    </div>


                </div>
                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6> Level 4 :</h6>
                    </div>
                    <div class="col-3">

                        <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl4_cd']" disabled>

                    </div>


                    <div class="col-3">
                        <ng-select [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd" (change)="onChangeLvl4()"
                            [multiple]="false" placeholder="Select Level 4" [(ngModel)]="HierSelectObj['lvl4_cd']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="lvl4_value">
                        </ng-select>
                    </div>

                </div>
                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6> Level 5 :</h6>
                    </div>
                    <div class="col-3">

                        <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl5_cd']" disabled>

                    </div>

                    <div class="col-3">

                        <ng-select [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd" (change)="onChangeLvl5()"
                            [multiple]="false" placeholder="Select Level 5" [(ngModel)]="HierSelectObj['lvl5_cd']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="lvl5_value">
                        </ng-select>
                    </div>

                </div>
                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6> Level 6 :</h6>
                    </div>
                    <div class="col-3">

                        <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl6_cd']" disabled>

                    </div>


                    <div class="col-3">

                        <ng-select [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd" (change)="onChangeLvl6()"
                            [multiple]="false" placeholder="Select Level 6" [(ngModel)]="HierSelectObj['lvl6_cd']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="lvl6_value">
                        </ng-select>
                    </div>

                </div>
                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6> Level 7 :</h6>
                    </div>
                    <div class="col-3">

                        <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl7_cd']" disabled>

                    </div>

                    <div class="col-3">

                        <ng-select [items]="level7" (change)="onChangeLvl7()" bindLabel="lvl7_value" bindValue="lvl7_cd"
                            [multiple]="false" placeholder="Select Level 7" [(ngModel)]="HierSelectObj['lvl7_cd']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="lvl7_value">
                        </ng-select>
                    </div>

                </div>

                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6> Leaf :</h6>
                    </div>
                    <div class="col-3">

                        <input type="text" class="form-control" [(ngModel)]="HierSelectObj['leaf_cd']" disabled>

                    </div>

                    <div class="col-3">

                        <input type="text" class="form-control" [(ngModel)]="HierSelectObj['leaf_value']" disabled>

                    </div>
                </div>
                <br>






                <div class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" (click)="SubmitSelectedListHier()"
                            data-dismiss="modal">Submit</button>

                    </div>
                </div>




            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="getDetailsLelve5">
    <div class="modal-xl">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <h5> {{coa_code}} - {{coa_name}} </h5>
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <br>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Chart Of Account</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of datatoshow">
                                <td class="clickable" *ngIf="sel_lvl_code[0] != null">
                                    <b (click)="getDetailsLele6(item.lvl5_code , item.lvl5_value)">
                                        {{item['lvl5_code']}} -
                                        {{char_of_name[item['lvl5_code']]}} </b>
                                </td>
                                <td *ngIf="sel_lvl_code[0] == null">{{coa_code}} -{{coa_name}}
                                </td>
                                <td style="text-align:right">{{item['dr']}}</td>
                                <td style="text-align:right">{{item['cr']}}</td>
                                <td style="text-align:right">
                                    {{item['net'] + item['ind']}}
                                </td>
                            </tr>


                            <tr>
                                <td style="text-align:right"><b>TOTAL</b></td>
                                <td style="text-align:right"><b>{{totaldb_amt | number:'1.2-2'}}</b></td>
                                <td style="text-align:right"><b>{{totalcr_amt | number:'1.2-2'}}</b></td>
                                <td style="text-align:right"> {{ total_amt | number:'1.2-2' }} </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div>

        </div>
    </div>
</div>

<!-- Level 6 model  -->
<!-- <div class="modal" id="getDetailsLelve6">
    <div class="modal-xl">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    <h5> {{coa_code}} - {{char_of_name[coa_code]}} </h5>
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <br>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Chart Of Account</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of datatoshow6">
                                <td class="clickable" *ngIf="sel_lvl_code[0] != null">
                                     <b (click)="getDetailsLele7(item.lvl6_code , item.lvl6_value)">
                                        {{item['lvl5_code']}} -
                                        {{char_of_name[item['lvl5_code']]}} </b> </td>
                                <td *ngIf="sel_lvl_code[0] == null">{{coa_code}} - {{char_of_name[coa_code]}}
                                </td>
                                <td style="text-align:right">{{item['dr']}}</td>
                                <td style="text-align:right">{{item['cr']}}</td>
                                <td style="text-align:right">
                                    {{item['net'] + item['ind']}}
                                </td>
                            </tr>


                            <tr>
                                <td style="text-align:right"><b>TOTAL</b></td>
                                <td style="text-align:right"><b>{{totaldb_amt}}</b></td>
                                <td style="text-align:right"><b>{{totalcr_amt}}</b></td>
                                <td style="text-align:right"> {{total_amt}} </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div>

        </div>
    </div>
</div> -->

<!--  -->
<ngx-spinner>Loading....</ngx-spinner>
