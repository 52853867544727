import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class EstablishService {
  httpUrl:any;

  employee_id:any;
  complaint_detail:any;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl+"/hr";
  }
  //  all employee

  async  getAllArrangment(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl+'/establishment/getAllArrangment' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  getEmployeeMasterData(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl+'/establishment/getAllCurrentEstablishementInfo' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getEmployeePersonalInfo(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl+'/emp_info/empInfo/getPersonalInfo' + (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  //arrangment
  async addArrangment(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment/updateAllEstablishmentInfo', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCurrentEstablishementInfo(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getCurrentEstablishementInfo'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getCurrentEstablishementInfo1(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/2getCurrentEstablishementInfo'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getArrangmentSalComponent(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getArrangmentSalComponent'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getArrangmentAllSalComponent(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getArrangmentAllSalComponent'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async addVariablePayComp(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment/addVariablePayComp',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addVariablePayComp1(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment/updateVariablePayComp1',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

    //fix_salary
    async addFixedPayComponent(obj :any) {
      const resp = await this.http.post<any>(this.httpUrl+'/payroll_info/fixedPay/addFixedPayComponent', (obj)).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async addFixedPay(obj :any) {
      const resp = await this.http.post<any>(this.httpUrl+'/payroll_info/fixedPayAmount/addFixedPay', (obj)).toPromise().then(res => {
        return res;
      });
      return resp;
    }

    async getPartyFixedPay(obj :any) {
      const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/fixedPayAmount/ruleGetAllFixedPayForRule'+JSON.stringify(obj)).toPromise().then(res => {
        return res;
      });

      return resp;
    }

      //variable_salary
  async addVariablePay(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/payroll_info/variablePay/addVariablePay', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateVariablePayComp(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl+'/establishment/updateVariablePayComp',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deleteVariablePayComp(obj :any) {
    const resp = await this.http.delete<any>(this.httpUrl+'/establishment/deleteVariablePayComp'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllVariablePay(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/variablePay/getVariablePay'+JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

    //noticePeriod
    async defineNoticePeriod(obj :any) {
      const resp = await this.http.post<any>(this.httpUrl+'/establishment_info/noticePeriod/defineNoticePeriod',(obj)).toPromise().then(res => {
        return res;
      });
      return resp;
    }

    async getPartyNoticePeriod(obj :any) {
      const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/noticePeriod/getPartyNoticePeriod'+JSON.stringify(obj)).toPromise().then(res => {
        return res;
      });
      return resp;
    }

     //leave
  async defineLeaveForParty(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/leaveInfo/addLeaveInfo', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getLeavesOfParty(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/leave/getAllEmpLeaves'+JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
//Probation
  async addPartyProbation(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment_info/probation/addPartyProbation', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getPartyProbationInfo(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/probation/getPartyProbationInfo'+JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  //allDone
  async addNewEstabishment(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment/addEstablishment', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //shweta
  async addNewEstabishment1(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment/saddEstablishment', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addNewleaveEstabishment(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment/addNewleaveEstabishment', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addcalendardays(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment/addcalendardays', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
//shweta update calendar days

  async updatecalendardays(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl+'/establishment/updatecalendaralldays',obj).toPromise().then(res => {
      return res;
    });
    return resp;

  }
  async updateNewEstabishment(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl+'/establishment/updateNewEstabishment',obj).toPromise().then(res => {
      return res;
    });
    return resp;

  }

  async getFixPayStatus(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl+'/establishment/getFixPayStatus'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //shweta get data
  async getNewEstabishment(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl+'/establishment/getNewEstabishment'+obj).toPromise().then(res => {
      return res;
    });
    console.log("service running")
    return resp;
  }
  async getleave(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl+'/establishment/getleave'+obj).toPromise().then(res => {
      return res;
    });
    console.log("service running")
    return resp;
  }
  async getemployement(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl+'/establishment/getemployement'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateStatusOfArrangement(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl+'/establishment/updateEstablishmentInfo', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getEstablishementInfo(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getEstablishementInfo'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCurrentEstablishementOnDate(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getCurrentEstablishementOnDate'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async insertEstablishmentInfo(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment/insertEstablishmentInfo', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  updateAllEstablishmentInfo(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment/updateAllEstablishmentInfo', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  updateServiceInfo(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment/updateServiceInfo', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  updateEstablishmentInfo(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment/updateEstablishmentInfo', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async  updateOrgInfo(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+'/establishment/updateOrgInfo', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //salary Defination
  async getComponentDefinition(obj :any) {
    const resp = await this.http.get<any>(this.main.httpUrl+'/hr/payroll_info/salaryComponents/getComponentDefinition'+JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getRuleInfo(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl+'/establishment/getRuleInfo'+JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //matrix
  async getMatrix(obj :any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/getPayMatrix'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getHRAMatrix(obj :any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/getHRAMatrix'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getGISMatrix(obj :any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/getGISMatrix'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getCCAMatrix(obj :any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/getCCAMatrix'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  getAllRules(obj :any) {
    const resp = await this.http.get<any>(this.main.httpUrl+'/hr/leaveRule/getAllRules'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //Calender Rule
  async  getCalenderRule(obj :any) {
    const resp = await this.http.get<any>(this.main.httpUrl+'/hr/leaveRule/getCalenderRule'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getDate(){
    const resp = await this.http.get<any>(this.main.httpUrl+'/portal/getServerDate').toPromise().then(res => {
      return res;
    });
    return resp;
  }


}
