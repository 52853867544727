import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { MainService } from "../../../service/main.service";
import { LoginService } from "../../../service/login.service";
import { CommonDataService } from "../../../common _services/common-data.service";
import { MainService as mainServiceMD } from "../../../../md/service/main.service";
import Swal from "sweetalert2";

declare var $: any;
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  role_flage = true;
  maprole_flage = false;
  displayedColumns: string[] = ["user_id", "name", "role_desc", "delete"];
  email:any;
  password:any;
  confirm_pass:any;
  users:any = [];
  datasource:any;
  user_id:any;
  index:any;
  Obj:any = {};
  FirstName:any;
  LastName:any;
  User_Email:any;

  b_acct_id:any;
  erpUser:any;
  account:any;
  roles:any = [];
  roleObj:any = {};


  constructor(
    public commonDataService: CommonDataService,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public mainService: MainService,
    private mainServiceMD: mainServiceMD
  ) {}

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser") as any))
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAccountUserInfo();
    await this.getAllRoles();
    await this.getAllCurrentUserList();
    await this.getCurrentRole();
  }


  async maprole() {
    this.role_flage = false;
    this.maprole_flage = true;
  }

  current_role = [];
  async getCurrentRole() {
    this.spinner.show();
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    console.log(obj);
    var resp = await this.mainServiceMD.getCurrentMdRole(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.current_role = resp["data"];
    } else {
      this.spinner.hide();
      Swal.fire("Error", "..Error while getting  roles", "error");
    }
  }

  single_users_role = [];
  selected_row = {};
  modalOpen(data:any) {
    this.selected_row = {};
    this.selected_row = Object.assign({}, data);
    this.single_users_role = [];
    let dummy:any= [];
    for (let i = 0; i < this.allUsers.length; i++) {
      if (data["user_id"] == this.allUsers[i]["user_id"]) {
        dummy.push(this.allUsers[i]);
      }
    }
    for (let i = 0; i < dummy.length; i++) {
      for (let j = 0; j < this.current_role.length; j++)
        if (dummy[i]["role_cd"] == this.current_role[j]["role_cd"]) {
          dummy[i]["role_desc"] = this.current_role[j]["role_desc"];
        }
    }
    this.single_users_role = dummy;
    $("#myModal1").modal("show");
  }



  async delete(element:any) {
    console.log(element);
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element);
      }
    });
  }


  async finaldelete(element:any) {
    let obj:any = {};
    obj = Object.assign({}, element);
    obj["b_acct_id"] = this.b_acct_id;
    this.spinner.show();
    var resp = await this.mainServiceMD.deleteUser_MD(obj);
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getAllCurrentUserList();
      await this.modalOpen(this.selected_row);
      Swal.fire("Success", "Deleted Successfully", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp["data"], "error");
    }
  }

  allUsers = [];
  async getAllCurrentUserList() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    var resp = await this.mainServiceMD.getCurrentMdUser(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      this.allUsers = resp["data"];
      var emp_id:any = new Object();
      for (let i = 0; i < resp["data"].length; i++) {
        for (let j = 0; j < this.allEMBRole.length; j++) {
          if (this.allEMBRole[j]["role_cd"] == resp["data"][i]["role_cd"]) {
            if (emp_id[resp["data"][i]["user_id"]] == undefined) {
              resp["data"][i]["role_name"] = this.allEMBRole[j]["role_name"];
              emp_id[resp["data"][i]["user_id"]] = resp["data"][i];
            } else {
              emp_id[resp["data"][i]["user_id"]]["role_name"] =
                emp_id[resp["data"][i]["user_id"]]["role_name"] +
                " , " +
                this.allEMBRole[j]["role_name"];
            }
          }
        }
      }
      console.log(emp_id);
      var data = Object.values(emp_id);
      console.log(data);
      this.datasource = new MatTableDataSource(data);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    }
  }

  userObj:any = {};
  async getAccountUserInfo() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    console.log(obj);
    var resp = await this.mainServiceMD.getuserformodulerolemapping(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.users = resp.data;
      console.log(this.users);
      for (let i = 0; i < this.users.length; i++) {
        this.userObj[this.users[i]["user_id"]] = this.users[i]["emp_name"];
        this.users[i]["desc"] =
          this.users[i]["user_id"] +
          " - " +
          this.users[i]["emp_name"] +
          " - " +
          this.users[i]["emp_phone_no"];
      }
    }
  }

  roleCodeToAccess = {};
  allEMBRole = [];
  async getAllRoles() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    var resp = await this.mainServiceMD.getCurrentMdRole(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      this.roles = [];
      this.allEMBRole = resp.data;
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["is_system_role"] == 0) {
          this.roles.push(resp.data[i]);
        }
        this.roleObj[resp.data[i]["role_cd"]] = resp.data[i]["role_name"];
      }
      console.log(this.roles);
    }
  }



  async gotolist() {
    this.role_flage = true;
    this.maprole_flage = false;
    await this.getAccountUserInfo();
    await this.getAllRoles();
    await this.getAllCurrentUserList();
    await this.getCurrentRole();
  }

  async submit() {
    var obj:any = new Object();
    this.spinner.show();
    obj["b_acct_id"] = this.b_acct_id;
    obj["valid_upto"] = "2030-04-30";
    obj["user_id"] = this.Obj["user_id"];
    obj["role_cd"] = this.Obj["role_cd"];
    obj["create_user_id"] = this.erpUser["user_id"];
    if (obj["role_cd"] == undefined) {
      this.spinner.hide();
      Swal.fire("Error", "Plz Select Any Role....! ", "error");
      return;
    }
    if (obj["user_id"] == undefined) {
      this.spinner.hide();
      Swal.fire("Error", "Plz Select Any User....! ", "error");
      return;
    }

    var resp = await this.mainServiceMD.createMdUser(obj);
    console.log(resp);
    if (resp["error"] == false) {
      console.log(resp["data"]);
      await this.getAllCurrentUserList();
      Swal.fire("Success..", "Successfully Created", "success");
      this.spinner.hide();
    } else {
      this.spinner.hide();
      if (resp["data"] == "Duplicate entry") {
        Swal.fire("Error...", "This User Is Already Exists.", "error");
      } else {
        Swal.fire("Error", "Error While Creating", "error");
      }
    }
    this.gotolist();
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  zone_flag = false;

  ChangeRole() {
    for (let i = 0; i < this.roles.length; i++) {
      if (
        this.Obj["role_cd"] == this.roles[i]["role_cd"] &&
        this.roles[i]["access"] == "ZONEWISE" &&
        this.erpUser["role_cd"] == "AA"
      ) {
        this.zone_flag = true;
        this.Obj["zone_cd"] = null;
        break;
      } else {
        this.zone_flag = false;
        this.Obj["zone_cd"] = null;
      }
    }
  }

  phone_number:any;
  search_flag = false;

  async checkUserExistOrNot(phone_no:any) {
    var tt = false;
    console.log(this.phone_number);
    var obj:any = new Object();
    obj["phone_no"] = phone_no;
    console.log(obj);
    var resp = await this.loginService.manualloginWithOtp(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false && resp["data"].length > 0) {
      for (let i = 0; i < resp.data.length; i++) {
        if (this.b_acct_id == resp.data[i]["b_acct_id"]) {
          tt = true;
        }
      }
    }

    console.log(tt);
    return tt;
  }

  update_user_info_Obj:any = {};
  open_update(element:any) {
    this.update_user_info_Obj = {};
    this.update_user_info_Obj["user_id"] = element["user_id"];
    this.update_user_info_Obj["old_phone_no"] = element["phone_no"];
    this.update_user_info_Obj["old_email"] = element["email"];
    this.update_user_info_Obj["old_name"] = element["name"];
    this.update_user_info_Obj["phone_no"] = element["phone_no"];
    this.update_user_info_Obj["email"] = element["email"];
    this.update_user_info_Obj["name"] = element["name"];
    $("#update_user_info").modal("show");
  }
}

