<div class="page-content fade-in-up">
    <!-- {{imgURL}} -->

    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Lottery</div>
                    <!--Property-->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> Lottery List</a>
                            <!--Property List-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Create Lottery </a>
                            <!--Create Property-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Update Lottery</a>
                        </li>



                    </ul>
                    <div class="tab-content">

                        <div *ngIf="list_flag">

                            <br>

                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="Search...">
                                        <!--Filter-->
                                    </mat-form-field>
                                </div>
                                <div class="col-6 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                </div>
                            </div>


                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            ID

                                        </th>
                                        <!--Property Number-->
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--Property type-->
                                            Offer code


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.offer_cd }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Offer Name

                                        </th>
                                        <!--Description-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['offer_name']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="no_of_prop">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Number Of Property

                                        </th>
                                        <!--Description-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['sum(pro.no_of_pro)']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="start_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Offer Start Date

                                        </th>
                                        <!--Total
                                            Area-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{mainService.dateFormatChange(element.application_start_dt) }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="end_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Offer End Date

                                        </th>
                                        <!--Total
                                            Area-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateFormatChange(element.application_end_dt)}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Status

                                        </th>
                                        <!--Total
                                            Area-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.life_cycle_status }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action</th>
                                        <!--Action-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-primary" (click)="open_update(element)"
                                                *ngIf="element['life_cycle_status'] == 'ACTIVE'">{{auth.allLableShowObjProp[auth.language_cd+'property11']}}</button>&nbsp;
                                            <!--Update-->
                                            <button class="btn btn-outline-danger" (click)="delete(element)"
                                                *ngIf="element['life_cycle_status'] == 'ACTIVE'">{{auth.allLableShowObjProp[auth.language_cd+'property12']}}</button>&nbsp;
                                            <button class="btn btn-outline-info" (click)="Appr(element)"
                                                *ngIf="element['life_cycle_status'] == 'ACTIVE' && apprflag == true">Add
                                                Approval
                                            </button>&nbsp;
                                            <button class="btn btn-outline-info"
                                                *ngIf="element.life_cycle_status=='UNDERAPPROVAL' || element.life_cycle_status=='APPROVED'"
                                                (click)="viewStatus(element)"> View Status </button>&nbsp;
                                            <button class="btn btn-outline-success"
                                                *ngIf="element.life_cycle_status=='UNDERAPPROVAL' || element.life_cycle_status=='APPROVED' || element['life_cycle_status'] == 'ACTIVE' "
                                                (click)="viewDetails(element)"> View Details </button>&nbsp;

                                            <!--Delete-->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns_1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns_1;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        <div *ngIf="create_flag">

                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first" label="Offer">
                                    <br>
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Project :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectProject()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{project_obj['project_path_desc']}}
                                            </div>

                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Activity :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectActivity()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{act_obj['project_path_desc']}}
                                            </div>

                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Budget :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectBudget()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{bud_obj['project_path_desc']}}
                                            </div>

                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Product :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectProduct()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{prod_obj['project_path_desc']}}
                                            </div>

                                        </div>
                                        <!-- <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Selected Project Path:</h6>
                                            </div>
                                            <div class="col-3">

                                            </div>
                                            <div class="col-3">
                                            </div>
                                        </div> -->
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Offer code : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" placeholder="Enter Offer Code"
                                                    [(ngModel)]="Obj['offer_cd']" class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Offer Name : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" placeholder="Enter Offer Name"
                                                    [(ngModel)]="Obj['offer_name']" class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Offer Description : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                    [(ngModel)]="Obj['offer_desc']"
                                                    placeholder="Enter Offer Description"
                                                    class="form-control"></textarea>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Mode Of Sale :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" placeholder="Lottery" disabled
                                                    [(ngModel)]="Obj['mode_of_sale']" class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4"></div>
                                            <div class="col-3">
                                                <h6>Lottery Date : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" [(ngModel)]="Obj['lottery_date']" [min]="currentDate()"
                                                    class="form-control">
                                            </div>
                                            <div class="col-2"></div>
                                        </div>
                                        <br>
                                        <!-- {{Obj['lottery_date']}} -->
                                        <div class="row">
                                            <div class="col-4"></div>
                                            <div class="col-3">
                                                <h6>Allotment Date : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" [(ngModel)]="Obj['allotment_date']" [min]="Obj['lottery_date']"
                                                    class="form-control">
                                            </div>
                                            <div class="col-2"></div>
                                        </div>
                                        <br><br>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property18']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="first" label="Costing">
                                    <br>
                                    <div class="card">
                                        <br>
                                        <div style="margin: 2%;">
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>Premium Costing :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <button (click)="addPremiunCosting()"
                                                        class="btn btn-outline-primary">
                                                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                                <div class="col-3"></div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">

                                            </div>
                                            <div *ngFor="let item of premium_costing_arr;let i=index">
                                                <div class="row" style="border-width: 0.1px;">
                                                    <div class="col-1">
                                                        <input type="checkbox" style="width: 20px;height: 20px;"
                                                            [(ngModel)]="item['is_checked']" name="" id="">
                                                    </div>
                                                    <div class="col-1">
                                                        <h6> Select Feature</h6>
                                                    </div>
                                                    <div class="col-2">
                                                        <ng-select [items]="all_premium_feature"
                                                            bindLabel="feature_desc" bindValue="feature_code"
                                                            [multiple]="false" placeholder="Select ..."
                                                            [(ngModel)]="item['feature_type']"
                                                            (change)="setFilteredData(item,'premium',i)"
                                                            [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                            [closeOnSelect]="true" name="chart_of_account0">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-1">
                                                        <h6> Costing Method</h6>
                                                    </div>
                                                    <div class="col-2">
                                                        <ng-select [items]="costing_method" bindLabel="id"
                                                            bindValue="id" [multiple]="false"
                                                            placeholder="Select Feature Group"
                                                            [(ngModel)]="item['costing_method']"
                                                            [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                            [closeOnSelect]="true" name="chart_of_account0">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <h6>Percenatge</h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                            <h6>Amount</h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <h6>Rate</h6>
                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control"
                                                                placeholder="Enter Value (0 to 100)">
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control" placeholder="Enter Amount">

                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control"
                                                                placeholder="Enter Rate In Amount Per Feature Unit">

                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <h6>Of </h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                            <!-- <h6>Amount</h6> -->
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <h6>X</h6>
                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <ng-select [items]="item['arr']" bindLabel="feature_desc"
                                                                bindValue="feature_code" [multiple]="false"
                                                                placeholder="Select Feature Group"
                                                                [(ngModel)]="item['feature_code']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" name="chart_of_account0">
                                                            </ng-select>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <ng-select [items]="item['arr']" bindLabel="feature_desc"
                                                                bindValue="feature_code" [multiple]="false"
                                                                placeholder="Select Feature Group"
                                                                [(ngModel)]="item['feature_code']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" name="chart_of_account0">
                                                            </ng-select>
                                                        </span>

                                                    </div>

                                                    <div class="col-1">
                                                        <button (click)="removePremiunCosting(i,item)"
                                                            class="btn btn-outline-danger">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>Additional Costing :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <button (click)="addAdditionalCosting()"
                                                        class="btn btn-outline-primary">
                                                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                                <div class="col-3"></div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">

                                            </div>
                                            <div *ngFor="let item of add_costing_arr;let i=index">
                                                <div class="row" style="border-width: 0.1px;">
                                                    <div class="col-1">
                                                        <input type="checkbox" style="width: 20px;height: 20px;"
                                                            [(ngModel)]="item['is_checked']" name="" id="">
                                                    </div>
                                                    <div class="col-1">
                                                        <h6> Select Feature</h6>
                                                    </div>
                                                    <div class="col-2">
                                                        <ng-select [items]="all_add_feature" bindLabel="feature_desc"
                                                            bindValue="feature_code" [multiple]="false"
                                                            placeholder="Select Feature Group"
                                                            (change)="setFilteredData(item,'additional',i)"
                                                            [(ngModel)]="item['feature_type']" [selectableGroup]="true"
                                                            [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                            name="chart_of_account0">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-1">
                                                        <h6> Costing Method</h6>
                                                    </div>
                                                    <div class="col-2">
                                                        <ng-select [items]="costing_method" bindLabel="id"
                                                            bindValue="id" [multiple]="false"
                                                            placeholder="Select Feature Group"
                                                            [(ngModel)]="item['costing_method']"
                                                            [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                            [closeOnSelect]="true" name="chart_of_account0">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <h6>Percenatge</h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                            <h6>Amount</h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <h6>Rate</h6>
                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control"
                                                                placeholder="Enter Value (0 to 100)">
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control" placeholder="Enter Amount">

                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control"
                                                                placeholder="Enter Rate In Amount Per Feature Unit">

                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <h6>Of </h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <h6>X</h6>
                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <ng-select [items]="item['arr']" bindLabel="feature_desc"
                                                                bindValue="feature_code" [multiple]="false"
                                                                placeholder="Select Feature Group"
                                                                [(ngModel)]="item['feature_code']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" name="chart_of_account0">
                                                            </ng-select>
                                                        </span>

                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <ng-select [items]="item['arr']" bindLabel="feature_desc"
                                                                bindValue="feature_code" [multiple]="false"
                                                                placeholder="Select Feature Group"
                                                                [(ngModel)]="item['feature_code']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" name="chart_of_account0">
                                                            </ng-select>
                                                        </span>

                                                    </div>

                                                    <div class="col-1">

                                                        <button (click)="removeAdditionalCosting(i,item)"
                                                            class="btn btn-outline-danger">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <br>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext
                                                (click)="set_costingData()">{{auth.allLableShowObjProp[auth.language_cd+'property18']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="first" label="Payment Option">
                                    <br>
                                    <div class="card">
                                        <br>

                                        <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Details</th>
                                                    <th>Mode</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of paymentDiscriptionList;let i =index">
                                                    <td>{{item['description']}}</td>
                                                    <td>{{item['mode_of_payment']}}</td>
                                                    <td>
                                                        <button (click)="remove_paymentDiscription_item(item,i)"
                                                            class="btn btn-outline-danger">Remove</button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>
                                        <br>

                                        <div class="row">
                                            <!-- <div class="col-3"></div> -->
                                            <div class="col-6 text-right">
                                                <h6>Select Mode Of Payment
                                                    :<span style="color: red;"> *</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="modeOfPayment" bindLabel="id" bindValue="id"
                                                    [multiple]="false" placeholder="Select Required Document"
                                                    (change)="paymentOptionData()" [(ngModel)]="Obj['payment_option']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <!-- <div class="col-3"></div> -->
                                            <div class="col-6 text-right">
                                                <h6>Select Payment Options that you would like to include in the offer
                                                    :<span style="color: red;"> *</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="paymentOptionList"
                                                    bindLabel="frequency_of_installment" bindValue="pay_option_id"
                                                    [multiple]="false" placeholder="Select Payment Option"
                                                    [(ngModel)]="paymentOptionObj['option_list']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <span (click)="checkInPaymentDescriptionList(item)">
                                                            <!-- remove here -->
                                                            {{item.mode_of_payment}}-{{item.pro_type_or_sale_value}}-{{item.interest_rate}}(%)
                                                        </span>
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                                        <span (click)="checkInPaymentDescriptionList(item)">
                                                            {{item.mode_of_payment}}-{{item.pro_type_or_sale_value}}-{{item.interest_rate}}(%)
                                                        </span>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>

                                        <br>
                                        <div class="row" >
                                            <div class="col-2">
                                                <h6>Select Gatway :</h6>
                                            </div>
                                            <div class="col-2">
                                                <ng-select [items]="admindata" bindLabel="gateway_name"
                                                    bindValue="gateway_id" [multiple]="false"
                                                    placeholder="Select..."
                                                     [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <div (click)="getAccountbyGatewayID(item.gateway_id , event2)">
                                                            <!-- remove here -->
                                                            {{item.gateway_name}}
                                                        </div>
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                                        <div (click)="getAccountbyGatewayID(item.gateway_id , event2)">
                                                            {{item.gateway_name}}
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>


                                        <div class="row" *ngIf="gateWayId" >
                                            <!-- {{gateWayId}} -->
                                            <!-- {{gatWayList[i] | json }} -->
                                            <div class="col-12 ml-3" >
                                                <table class="table">
                                                    <thead>
                                                      <tr>
                                                        <th scope="row">#</th>
                                                        <th scope="col">Purpose</th>
                                                        <th scope="col">Account Number</th>
                                                        <th scope="col">Action</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr  *ngFor="let item of gatWayList;let i=index " >
                                                        <ng-container  *ngIf="gatWayList[i].gateway_id==gateWayId" >
                                                            <th scope="row" class="text-left" >{{i+1}}</th>
                                                            <td  class="text-left">{{item.purpose}}</td>
                                                            <td  class="text-left">{{item.account_no}}</td>
                                                            <td  class="text-left"> <input type="checkbox" [checked]="gatWayList[i].selected"  (change)="clickingcheckbox(i,item.id)"></td>
                                                        </ng-container>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                            </div>
                                        </div>


                                        <br>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property18']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second" label="Properties">
                                    <!--Property Details-->
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6 style="color: red;">Selected Property</h6>
                                            </div>
                                            <div class="col-3"></div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Property Type</th>
                                                    <th>From Inventory</th>
                                                    <th>Number Of Property</th>
                                                    <th>Application Amount type</th>
                                                    <th>Application Amount </th>
                                                    <th>Cancellation Amount type</th>
                                                    <th>Cancellation Amount </th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of property_group_data;let i =index">
                                                    <td>{{item['property_type']}}</td>
                                                    <td>{{item['from_inventory']}}</td>
                                                    <td>{{item['no_of_pro']}}</td>
                                                    <td>{{item['app_amt_type']}}</td>
                                                    <td>{{item['count']}}</td>
                                                    <td>{{item['canc_amt_type']}}</td>
                                                    <td>{{item['canc_count']}}</td>
                                                    <td>
                                                        <button (click)="remove(item,i)"
                                                            class="btn btn-outline-danger">Remove</button> &nbsp;
                                                        <button (click)='viewdata(item,i)'
                                                            class="btn btn-outline-primary" data-toggle="modal"
                                                            data-target=".bd-modal-lg">View</button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>

                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Property Type : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0015']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select Property Type" (change)="reset_property()"
                                                    [(ngModel)]="propObj['property_type']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                        </div>

                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Application Amount
                                                    Type : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="type" bindLabel="id" bindValue="id"
                                                    [multiple]="false" placeholder="Select Property Type"
                                                    [(ngModel)]="propObj['app_amt_type']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Application Amount
                                                    : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" *ngIf="propObj['app_amt_type']=='Amount'"
                                                    class="form-control" placeholder="Enter Amount"
                                                    [(ngModel)]="propObj['count']" name="" id="">
                                                <input type="number" *ngIf="propObj['app_amt_type']!='Amount'"
                                                    class="form-control" placeholder="Enter Amount in %"
                                                    [(ngModel)]="propObj['count']" name="" id="">

                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Cancellation Amount
                                                    Type : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="type" bindLabel="id" bindValue="id"
                                                    [multiple]="false" placeholder="Select Property Type"
                                                    [(ngModel)]="propObj['canc_amt_type']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Cancellation Amount
                                                    : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" *ngIf="propObj['canc_amt_type']=='Amount'"
                                                    class="form-control" placeholder="Enter Amount"
                                                    [(ngModel)]="propObj['canc_count']" name="" id="">
                                                <input type="number" *ngIf="propObj['canc_amt_type']!='Amount'"
                                                    class="form-control" placeholder="Enter Amount in %"
                                                    [(ngModel)]="propObj['canc_count']" name="" id="">

                                                <!--Select Location Type-->

                                            </div>
                                        </div>
                                        <br>

                                        <!-- <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>
                                                    Select Feature Group : <span style="color: red;"> *</span>
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROPFLD52']"
                                                    bindLabel="code" bindValue="value" [multiple]="false"
                                                    placeholder="Select Feature Group" [(ngModel)]="propObj['feature_type']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    (change)="getFeatures()"
                                                    [closeOnSelect]="true" name="chart_of_account0"  >
                                                </ng-select>
                                            </div>
                                        </div> -->
                                        <!-- <br> -->
                                        <!-- <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>
                                                    Select Feature : <span style="color: red;"> *</span>
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="feature_list"
                                                    bindLabel="feature_desc" bindValue="feature_id" [multiple]="true"
                                                    placeholder="Select Feature " [(ngModel)]="propObj['feature_id_arr']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0"  >
                                                </ng-select>
                                            </div>
                                        </div> -->
                                        <!-- <br> -->
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6> Select Location Hierarchy :</h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="first_list" bindLabel="hier_buss_name"
                                                    bindValue="hier_type_id" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['hier_type_id']" [selectableGroup]="true"
                                                    (change)="getAllLevls()" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row" *ngFor="let item of data,let i=index">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Level {{i+1}} :</h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value"
                                                    bindValue="lvl1_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl1_cd']" (change)='set_layout_data(item,i)'
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value"
                                                    bindValue="lvl2_cd" [multiple]="false"
                                                    (change)='set_layout_data(item,i)' placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==2" [items]="level3"
                                                    (change)='set_layout_data(item,i)' bindLabel="lvl3_value"
                                                    bindValue="lvl3_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl3_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==3" [items]="level4"
                                                    (change)='set_layout_data(item,i)' bindLabel="lvl4_value"
                                                    bindValue="lvl4_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl4_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==4" [items]="level5"
                                                    (change)='set_layout_data(item,i)' bindLabel="lvl5_value"
                                                    bindValue="lvl5_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl5_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==5" [items]="level6"
                                                    (change)='set_layout_data(item,i)' bindLabel="lvl6_value"
                                                    bindValue="lvl6_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl6_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==6" [items]="level7"
                                                    (change)='set_layout_data(item,i)' bindLabel="lvl7_value"
                                                    bindValue="lvl7_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl7_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3" *ngIf="i==data.length-1">
                                                <button (click)="add_dropdown(item,i)"
                                                    class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                                        aria-hidden="true"></i></button>
                                                &nbsp;&nbsp;
                                                <button (click)="remove_dropdown(item,i)" *ngIf="i!=0"
                                                    class="btn btn-outline-danger">
                                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Plan Layout:</h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="plan_layout" bindLabel="desc"
                                                    bindValue="property_group_id" [multiple]="true"
                                                    placeholder="Select Plan Layout" [(ngModel)]="propObj['id']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="false" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <button class="btn btn-outline-success" (click)="show_proprties()">Show
                                                    Propeties</button>
                                            </div>
                                        </div>
                                        <br>
                                        <!-- //mat table -->

                                        <div class="row align-items-end">
                                            <div class="col-8" ></div>
                                            <div class="col-2">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="selectall" (click)="selectAll()">
                                                    <label class="form-check-label" for="selectall">
                                                      Select all
                                                    </label>
                                                  </div>
                                            </div>
                                        </div>
                                        <div class="example-container" style="width: 80%; margin-left: 10%;">
                                            <table mat-table [dataSource]="datasource2" matSort>
                                                <ng-container matColumnDef="assigned_property_number">
                                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                                        Property number

                                                    </th>
                                                    <!--Property Number-->
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                                        class="right">
                                                        {{ element.assigned_property_number }}</td>
                                                </ng-container>

                                                <ng-container matColumnDef="property_type">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        <!--Property type-->
                                                        Property Type


                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        {{ element.property_type }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="description">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        Area

                                                    </th>
                                                    <!--Description-->
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        {{ element.area }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="area">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        Unit

                                                    </th>
                                                    <!--Total
                                                        Area-->
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        {{ element.unit }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        {{auth.allLableShowObjProp[auth.language_cd+'property10']}}</th>
                                                    <!--Action-->
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        <input type="checkbox" style="width: 20px;height: 20px;"
                                                            [(ngModel)]="element['is_checked']">
                                                    </td>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                                </tr>
                                            </table>
                                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                            </mat-paginator>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <button class="btn btn-success" (click)="save_property()">Save</button>
                                            </div>
                                        </div>
                                        <br>
                                    </div>

                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                (click)="save_At_step2()"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property35']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="third" label="Booklet">
                                    <!--Property Details-->
                                    <div class="card">

                                        <br>
                                        <div class="row">
                                            <div class="col-3">
                                            </div>
                                            <div class="col-3">
                                                <h6>Booklet :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="checkbox" class="form-control" (change)="show_booklet()"
                                                    style="width: 20px;height: 20px;" value="false" [(ngModel)]="radioBooklet">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Product :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectProduct_booklet()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{prod_obj_booklet['project_path_desc']}}
                                            </div>
                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Budget :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectBudget_booklet()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{bud_obj_booklet['project_path_desc']}}
                                            </div>
                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Activity :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectActivity_booklet()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{act_obj_booklet['project_path_desc']}}
                                            </div>
                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <div class="row" *ngIf="radioBooklet">

                                            <div class="col-3">
                                            </div>
                                            <div class="col-3">
                                                <h6>Booklet Sale Price :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" class="form-control" placeholder="Enter Sale Price"
                                                    value="false" [(ngModel)]="Obj['booklet_price']">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-3">
                                            </div>
                                            <div class="col-3">
                                                <h6>Booklet Sale Start Date :</h6>
                                            </div>
                                            <div class="col-3">
                                                <!-- {{currentDate()}} -->
                                                <input type="date" value="false" [(ngModel)]="Obj['booklet_start_date']" [min]="currentDate()"
                                                    class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-3">
                                            </div>
                                            <div class="col-3">
                                                <h6>Booklet End Date :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" value="false" [(ngModel)]="Obj['booklet_end_date']" [min]="Obj['booklet_start_date']"
                                                    class="form-control" [disabled]="!Obj['booklet_start_date']" >
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <br *ngIf="radioBooklet">
                                        <div *ngIf="radioBooklet">
                                            <div class="row" *ngIf="radioBooklet">
                                                <div class="col-3">
                                                </div>
                                                <div class="col-3">
                                                    <h6>Booklet Pdf :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <input #file type="file" name="pimage" ng2FileSelect
                                                        [uploader]="uploader" class="form-control" id="filesecletor"
                                                        style="border-color: black"
                                                        (change)="onFileUpload($event,file.files)">
                                                    <span>(pdf max size:3MB)</span>
                                                </div>
                                                <div class="col-3">
                                                    <button type="button" class="btn btn-outline-info"
                                                        (click)='upload()'><i class="fa fa-upload"
                                                            aria-hidden="true">&nbsp;</i>{{auth.allLableShowObjProp[auth.language_cd+'p_layout25']}}</button>
                                                    <!--Upload-->

                                                    &nbsp;&nbsp; <button class="btn btn-outline-info"
                                                        (click)="view()">View
                                                        Document</button>
                                                    <!--View Plan-->
                                                    <!-- <a [href]="imgURL">hello</a> -->

                                                </div>

                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Is Booklet Price Including GST :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="yesNo_arr" bindLabel="id" bindValue="id"
                                                        [multiple]="false" placeholder="Select Required Document"
                                                        [(ngModel)]="Obj['is_gst_included']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row" *ngIf="Obj['is_gst_included']=='YES'">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Enter GST Percentage :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <input type="number" value="false" [(ngModel)]="Obj['gst_per']"
                                                        class="form-control">
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row" *ngIf="Obj['is_gst_included']=='NO'">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Enter GST Percentage :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <input type="number" value="false" [(ngModel)]="Obj['gst_per']"
                                                         class="form-control">
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br *ngIf="radioBooklet">
                                            <div class="row" *ngIf="radioBooklet">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Booklet GST Budget :</h6>
                                                </div>
                                                <div class="col-1">
                                                    <button (click)="selectBudget_booklet_gst()"
                                                        class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                                </div>
                                                <div class="col-5">
                                                    {{bud_obj_booklet_gst['project_path_desc']}}
                                                </div>

                                            </div>
                                            <br *ngIf="radioBooklet">
                                            <div class="row" *ngIf="radioBooklet">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Booklet GST Activity :</h6>
                                                </div>
                                                <div class="col-1">
                                                    <button (click)="selectActivity_booklet_gst()"
                                                        class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                                </div>
                                                <div class="col-5">
                                                    {{act_obj_booklet_gst['project_path_desc']}}
                                                </div>

                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property35']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="fourth" label="Application">
                                    <!--Property Details-->
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-2"></div>

                                            <div class="col-2">
                                                <h6>Application Details</h6>
                                            </div>
                                            <div class="col-3"></div>
                                            <div class="col-4"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Application Start Date : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">

                                                <input type="date" [(ngModel)]="Obj['application_start_date']" [min]="applicationEndDate" (change)="define_end_date_of_app_end()"
                                                    class="form-control">
                                            </div>
                                            <div class="col-4"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Application End Date : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" [(ngModel)]="Obj['application_end_date']" [min]="appEndDate"
                                                    class="form-control" [disabled]="!Obj['application_start_date']" >
                                            </div>
                                            <div class="col-4"></div>
                                        </div>
                                        <br>
                                        <!-- <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Application Amount : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" placeholder="Enter Application Amount"
                                                    [(ngModel)]="Obj['application_amt']" class="form-control">
                                            </div>
                                            <div class="col-4"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Cancillation Amount (%) : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="                                            <div class="col-3"></div>
                                        number" placeholder="Enter Cancillation Amount"
                                                    [(ngModel)]="Obj['cancillation_amt']" class="form-control">
                                            </div>
                                            <div class="col-4"></div>
                                        </div>
                                        <br> -->
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Required Document : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0022']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select Required Document"
                                                    [(ngModel)]="Obj['required_doc']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                            <div class="col-3">
                                                <button (click)="add_document('Required')"
                                                    class="btn btn-outline-primary">Add</button>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Optional Document :</h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0023']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select Optional Document"
                                                    [(ngModel)]="Obj['optional_doc']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                            <div class="col-3">
                                                <button (click)="add_document2('Optional')"
                                                    class="btn btn-outline-primary">Add</button>
                                            </div>
                                        </div>
                                        <br>
                                        <br>
                                        <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Required/Optional</th>
                                                    <th>Document</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of requied_doc;let i =index">
                                                    <td>{{item['is_req']}}</td>
                                                    <td>{{item['desc']}}</td>
                                                    <td>
                                                        <button (click)="remove_doc(i)"
                                                            class="btn btn-outline-danger">Remove</button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>
                                        <br>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property35']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="fifth" label="Relaxation">
                                    <!--Property Details-->
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <!-- <h6>Subsidized :</h6> -->
                                                <h6>Relaxation : </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="checkbox" [(ngModel)]="subRadio"
                                                    style="width: 20px;height: 20px;" class="form-control"
                                                    value="false">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div *ngIf="subRadio">
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Quota :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROP0019']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="Select Quota" [(ngModel)]="subsObj['quota']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Sub Quota :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROP0020']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="Select sub Quota"
                                                        [(ngModel)]="subsObj['sub_quota']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Subsidy type :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="subsidy_type" bindLabel="id" bindValue="id"
                                                        [multiple]="false" placeholder="Select Subsidy Type"
                                                        [(ngModel)]="subsObj['subs_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Subsidy Quanity :</h6>
                                                </div>
                                                <div class="col-3">

                                                    <input type="number" class="form-control"
                                                        placeholder="Enter quantity"
                                                        [(ngModel)]="subsObj['subs_quantity']" name="" id="">
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Cost type :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="cost_type" bindLabel="id" bindValue="id"
                                                        [multiple]="false" placeholder="Select Property Type"
                                                        [(ngModel)]="subsObj['cost_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <button (click)="save_subsidy()"
                                                        class="btn btn-outline-primary">Save</button>
                                                </div>
                                            </div>
                                            <br>
                                            <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                                <thead>
                                                    <tr>
                                                        <th>Quota</th>
                                                        <th>Sub Quota</th>
                                                        <th>Subsidy Type</th>
                                                        <th>Subsidy Quanity</th>
                                                        <th>Cost type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of subs_arr;let i =index">
                                                        <td>{{quota_obj[item['quota']]}}</td>
                                                        <td>{{subQuota_obj[item['sub_quota']]}}</td>
                                                        <td>{{item['subs_type']}}</td>
                                                        <td>{{item['subs_quantity']}}</td>
                                                        <td>{{item['cost_type']}}</td>
                                                        <td>
                                                            <button (click)="remove_subs(item,i)"
                                                                class="btn btn-outline-danger">Remove</button>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property35']}}</button>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="sixth" label="done">
                                    <br>
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Offer </h6>
                                            </div>
                                            <div class="col-2"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Offer Code </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control" disabled
                                                    [(ngModel)]="Obj['offer_cd']">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Offer Name </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control" disabled
                                                    [(ngModel)]="Obj['offer_name']">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Lottery date :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['lottery_date']}}</div>
                                            <div class="col-2">
                                                <h6>Allotment Date :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['allotment_date']}}</div>
                                            <div class="col-1"></div>
                                        </div>
                                        <br>
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Booklet Start date : </h6>
                                            </div>
                                            <div class="col-2">{{Obj['booklet_start_date']}}</div>
                                            <div class="col-2">
                                                <h6>Booklet End Date :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['booklet_end_date']}}</div>
                                            <div class="col-1"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Application Start date : </h6>
                                            </div>
                                            <div class="col-2">{{Obj['application_start_date']}}</div>
                                            <div class="col-2">
                                                <h6> Application End Date :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['application_end_date']}}</div>
                                            <div class="col-1"></div>
                                        </div>
                                        <br>
                                        <div class="row" *ngIf="radioBooklet" >
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Booklet Amount :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['booklet_price']}}</div>
                                            <!-- <div class="col-2">
                                                <h6>Application Amount :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['application_amt']}}</div> -->
                                            <div class="col-1"></div>
                                        </div>
                                        <br>
                                        <!-- <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Cancillation Amount : </h6>
                                            </div>
                                            <div class="col-2">{{Obj['cancillation_amt']}}</div>

                                        </div>
                                        <br> -->
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-11">
                                                <h6>Required Document :</h6>
                                            </div>
                                        </div>
                                        <br>
                                        <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Required/Optional</th>
                                                    <th>Document</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of requied_doc;let i =index">
                                                    <td>{{item['is_req']}}</td>
                                                    <td>{{item['desc']}}</td>

                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-11">
                                                <h6>Selected Inventory :</h6>
                                            </div>
                                        </div>
                                        <br>
                                        <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Property Type</th>
                                                    <th>From Inventory</th>
                                                    <th>Number Of Property</th>
                                                    <!-- <th>Property Cost ( INR )</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of property_group_data;let i =index">
                                                    <td>{{item['property_type']}}</td>
                                                    <td>{{item['from_inventory']}}</td>
                                                    <td>{{item['no_of_pro']}}</td>
                                                    <!-- <td>{{item['property_cost']}}</td> -->

                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>
                                        <div class="row" *ngIf="subRadio">
                                            <div class="col-1"></div>
                                            <div class="col-11">
                                                <h6>Subsidy:</h6>
                                            </div>
                                        </div>
                                        <br *ngIf="subRadio">
                                        <table *ngIf="subRadio" class="table table-bordered"
                                            style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Quota</th>
                                                    <th>Sub Quota</th>
                                                    <th>Subsidy Type</th>
                                                    <th>Subsidy Quanity</th>
                                                    <th>Cost type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of subs_arr;let i =index">
                                                    <td>{{quota_obj[item['quota']]}}</td>
                                                    <td>{{subQuota_obj[item['sub_quota']]}}</td>
                                                    <td>{{item['subs_type']}}</td>
                                                    <td>{{item['subs_quantity']}}</td>
                                                    <td>{{item['cost_type']}}</td>

                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>

                                    </div>

                                    <br>
                                    <br>
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property47']}}</button>
                                        &nbsp;&nbsp;
                                        <button class="btn btn-outline-success"
                                            (click)="submit()">{{auth.allLableShowObjProp[auth.language_cd+'property48']}}</button>&nbsp;&nbsp;
                                    </div>

                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                        <div *ngIf="update_flag">
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper2>
                                <mat-step [stepControl]="first" label="Offer">
                                    <br>
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Project :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectProject()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{project_obj['project_path_desc']}}
                                            </div>

                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Activity :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectActivity()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{act_obj['project_path_desc']}}
                                            </div>

                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Budget :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectBudget()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{bud_obj['project_path_desc']}}
                                            </div>

                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Product :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectProduct()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{prod_obj['project_path_desc']}}
                                            </div>

                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Offer code : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" placeholder="Enter Offer Code" disabled
                                                    [(ngModel)]="Obj['offer_cd']" class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Offer Name : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" placeholder="Enter Offer Name"
                                                    [(ngModel)]="Obj['offer_name']" class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Offer Description : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                    [(ngModel)]="Obj['offer_desc']"
                                                    placeholder="Enter Offer Description"
                                                    class="form-control"></textarea>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Mode Of Sale :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" placeholder="Lottery" disabled
                                                    [(ngModel)]="Obj['mode_of_sale']" class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4"></div>
                                            <div class="col-3">
                                                <h6>Lottery Date : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" [(ngModel)]="Obj['lottery_date']" min="currentDate()"
                                                    class="form-control">
                                            </div>
                                            <div class="col-2"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4"></div>
                                            <div class="col-3">
                                                <h6>Allotment Date : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" [(ngModel)]="Obj['allotment_date']" [min]="Obj['lottery_date']"
                                                    class="form-control">
                                            </div>
                                            <div class="col-2"></div>
                                        </div>
                                        <br><br>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property18']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="first" label="Costing">
                                    <br>
                                    <div class="card">
                                        <br>
                                        <div style="margin: 2%;">
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>Premium Costing :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <button (click)="addPremiunCosting()"
                                                        class="btn btn-outline-primary">
                                                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                                <div class="col-3"></div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">

                                            </div>
                                            <div *ngFor="let item of premium_costing_arr;let i=index">
                                                <div class="row" style="border-width: 0.1px;">
                                                    <div class="col-1">
                                                        <input type="checkbox" style="width: 20px;height: 20px;"
                                                            [(ngModel)]="item['is_checked']" name="" id="">
                                                    </div>
                                                    <div class="col-1">
                                                        <h6> Select Feature</h6>
                                                    </div>
                                                    <div class="col-2">
                                                        <ng-select [items]="all_premium_feature"
                                                            bindLabel="feature_desc" bindValue="feature_code"
                                                            [multiple]="false" placeholder="Select ..."
                                                            [(ngModel)]="item['feature_type']"
                                                            (change)="setFilteredData(item,'premium',i)"
                                                            [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                            [closeOnSelect]="true" name="chart_of_account0">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-1">
                                                        <h6> Costing Method</h6>
                                                    </div>
                                                    <div class="col-2">
                                                        <ng-select [items]="costing_method" bindLabel="id"
                                                            bindValue="id" [multiple]="false"
                                                            placeholder="Select Feature Group"
                                                            [(ngModel)]="item['costing_method']"
                                                            [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                            [closeOnSelect]="true" name="chart_of_account0">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <h6>Percenatge</h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                            <h6>Amount</h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <h6>Rate</h6>
                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control"
                                                                placeholder="Enter Value (0 to 100)">
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control" placeholder="Enter Amount">

                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control"
                                                                placeholder="Enter Rate In Amount Per Feature Unit">

                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <h6>Of </h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                            <!-- <h6>Amount</h6> -->
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <h6>X</h6>
                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <ng-select [items]="item['arr']" bindLabel="feature_desc"
                                                                bindValue="feature_code" [multiple]="false"
                                                                placeholder="Select Feature Group"
                                                                [(ngModel)]="item['feature_code']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" name="chart_of_account0">
                                                            </ng-select>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <ng-select [items]="item['arr']" bindLabel="feature_desc"
                                                                bindValue="feature_code" [multiple]="false"
                                                                placeholder="Select Feature Group"
                                                                [(ngModel)]="item['feature_code']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" name="chart_of_account0">
                                                            </ng-select>
                                                        </span>

                                                    </div>

                                                    <div class="col-1">
                                                        <button (click)="removePremiunCosting(i,item)"
                                                            class="btn btn-outline-danger">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>Additional Costing :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <button (click)="addAdditionalCosting()"
                                                        class="btn btn-outline-primary">
                                                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                                <div class="col-3"></div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">

                                            </div>
                                            <div *ngFor="let item of add_costing_arr;let i=index">
                                                <div class="row" style="border-width: 0.1px;">
                                                    <div class="col-1">
                                                        <input type="checkbox" style="width: 20px;height: 20px;"
                                                            [(ngModel)]="item['is_checked']" name="" id="">
                                                    </div>
                                                    <div class="col-1">
                                                        <h6> Select Feature</h6>
                                                    </div>
                                                    <div class="col-2">
                                                        <ng-select [items]="all_add_feature" bindLabel="feature_desc"
                                                            bindValue="feature_code" [multiple]="false"
                                                            placeholder="Select Feature Group"
                                                            (change)="setFilteredData(item,'additional',i)"
                                                            [(ngModel)]="item['feature_type']" [selectableGroup]="true"
                                                            [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                            name="chart_of_account0">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-1">
                                                        <h6> Costing Method</h6>
                                                    </div>
                                                    <div class="col-2">
                                                        <ng-select [items]="costing_method" bindLabel="id"
                                                            bindValue="id" [multiple]="false"
                                                            placeholder="Select Feature Group"
                                                            [(ngModel)]="item['costing_method']"
                                                            [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                            [closeOnSelect]="true" name="chart_of_account0">
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <h6>Percenatge</h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                            <h6>Amount</h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <h6>Rate</h6>
                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control"
                                                                placeholder="Enter Value (0 to 100)">
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control" placeholder="Enter Amount">

                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <input type="number" [(ngModel)]="item['count']"
                                                                class="form-control"
                                                                placeholder="Enter Rate In Amount Per Feature Unit">

                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <h6>Of </h6>
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Fixed'">
                                                        </span>
                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <h6>X</h6>
                                                        </span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span *ngIf="item['costing_method']=='Variable %'">
                                                            <ng-select [items]="item['arr']" bindLabel="feature_desc"
                                                                bindValue="feature_code" [multiple]="false"
                                                                placeholder="Select Feature Group"
                                                                [(ngModel)]="item['feature_code']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" name="chart_of_account0">
                                                            </ng-select>
                                                        </span>

                                                        <span *ngIf="item['costing_method']=='Variable Rate'">
                                                            <ng-select [items]="item['arr']" bindLabel="feature_desc"
                                                                bindValue="feature_code" [multiple]="false"
                                                                placeholder="Select Feature Group"
                                                                [(ngModel)]="item['feature_code']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" name="chart_of_account0">
                                                            </ng-select>
                                                        </span>

                                                    </div>

                                                    <div class="col-1">

                                                        <button (click)="removeAdditionalCosting(i,item)"
                                                            class="btn btn-outline-danger">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <br>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext
                                                (click)="set_costingData()">{{auth.allLableShowObjProp[auth.language_cd+'property18']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="first" label="Payment Option">
                                    <br>
                                    <div class="card">
                                        <br>
                                        <!-- {{paymentDiscriptionList | json }} -->
                                        <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Details</th>
                                                    <th>Mode</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of paymentDiscriptionList;let i =index">
                                                    <td>{{item['description']}}</td>
                                                    <td>{{item['mode_of_payment']}}</td>
                                                    <td>
                                                        <button (click)="remove_paymentDiscription_item(item,i)"
                                                            class="btn btn-outline-danger">Remove</button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>
                                        <br>
                                        <!-- <div class="row">

                                            <div class="col-6 text-right">
                                                <h6>Select Payment Options that you would like to include in the offer
                                                    :<span style="color: red;"> *</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="modeOfPayment" bindLabel="id" bindValue="id"
                                                    [multiple]="false" placeholder="Select Required Document"
                                                    [(ngModel)]="Obj['payment_option']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">

                                                </ng-select>
                                            </div>
                                        </div> -->
                                        <div class="row">
                                            <!-- <div class="col-3"></div> -->
                                            <div class="col-6 text-right">
                                                <h6>Select Mode Of Payment
                                                    :<span style="color: red;"> *</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="modeOfPayment" bindLabel="id" bindValue="id"
                                                    [multiple]="false" placeholder="Select Required Document"
                                                    (change)="paymentOptionData()" [(ngModel)]="Obj['payment_option']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <!-- <div class="col-3"></div> -->
                                            <div class="col-6 text-right">
                                                <h6>Select Payment Options that you would like to include in the offer
                                                    :<span style="color: red;"> *</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="paymentOptionList"
                                                    bindLabel="frequency_of_installment" bindValue="pay_option_id"
                                                    [multiple]="false" placeholder="Select Payment Option"
                                                    [(ngModel)]="paymentOptionObj['option_list']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <span (click)="checkInPaymentDescriptionList(item)">
                                                            <!-- remove here -->
                                                            {{item.mode_of_payment}}-{{item.pro_type_or_sale_value}}-{{item.interest_rate}}(%)
                                                        </span>
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                                        <span (click)="checkInPaymentDescriptionList(item)">
                                                            {{item.mode_of_payment}}-{{item.pro_type_or_sale_value}}-{{item.interest_rate}}(%)
                                                        </span>
                                                    </ng-template>
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>

                                        </div>
                                        <br>
                                        <!-- <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Enter Message : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                    [(ngModel)]="Obj['payment_option_desc']"
                                                    placeholder="Enter Message..." class="form-control"></textarea>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br> -->
                                        <div class="row">
                                            <div class="col-12 text-right">
                                                <button style="margin-right: 3%;" (click)="addrow()"
                                                    class="btn btn-outline-success">+
                                                    Add</button>

                                            </div>
                                        </div>
                                        <br>
                                        <div *ngFor="let item of gatwayData;let i=index "
                                            style="margin-left:3%;margin-right: 3%;">
                                            <div class="row">
                                                <div class="col-1">
                                                    <h6>Purpose</h6>
                                                </div>
                                                <div class="col-2">
                                                    <ng-select [items]="purpose_arr" bindLabel="code" bindValue="code"
                                                        [multiple]="false" placeholder="Select..."
                                                        (change)="setAccountData(item,i)"
                                                        [(ngModel)]="item['payment_purpose']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>

                                                <div class="col-2">
                                                    <h6>Select Gatway :</h6>
                                                </div>
                                                <div class="col-2">
                                                    <ng-select [items]="admindata" bindLabel="gateway_name"
                                                        bindValue="gateway_id" [multiple]="false"
                                                        placeholder="Select..." (change)="setAccountData(item,i)"
                                                        [(ngModel)]="item['gateway_id']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-2">
                                                    <h6> Select Account :</h6>
                                                </div>
                                                <div class="col-2">
                                                    <ng-select [items]="item['arr']" bindLabel="account_no"
                                                        bindValue="mid_id" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="item['mid_id']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true"
                                                        (change)="checkDuplicate(item,i)" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-1">
                                                    <button (click)="removerow(i)"
                                                        class="btn btn-outline-danger">Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property18']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second" label="Properties">
                                    <!--Property Details-->
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6 style="color: red;">Selected Property</h6>
                                            </div>
                                            <div class="col-3"></div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Property Type</th>
                                                    <th>From Inventory</th>
                                                    <th>Number Of Property</th>
                                                    <th>Application Amount type</th>
                                                    <th>Application Amount </th>
                                                    <th>Cancellation Amount type</th>
                                                    <th>Cancellation Amount </th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of property_group_data;let i =index">
                                                    <td>{{item['property_type']}}</td>
                                                    <td>{{item['from_inventory']}}</td>
                                                    <td>{{item['no_of_pro']}}</td>
                                                    <td>{{item['app_amt_type']}}</td>
                                                    <td>{{item['count']}}</td>
                                                    <td>{{item['canc_amt_type']}}</td>
                                                    <td>{{item['canc_count']}}</td>
                                                    <td>
                                                        <button (click)="remove(item,i)"
                                                            class="btn btn-outline-danger">Remove</button> &nbsp;
                                                        <button (click)='viewdata(item,i)'
                                                            class="btn btn-outline-primary" data-toggle="modal"
                                                            data-target=".bd-modal-lg">View</button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <h6> Select Inventory
                                                </h6>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Property Type : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0015']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select Property Type" (change)="reset_property()"
                                                    [(ngModel)]="propObj['property_type']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Application Amount
                                                    Type : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="type" bindLabel="id" bindValue="id"
                                                    [multiple]="false" placeholder="Select Property Type"
                                                    [(ngModel)]="propObj['app_amt_type']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Application Amount
                                                    : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" *ngIf="propObj['app_amt_type']=='Amount'"
                                                    class="form-control" placeholder="Enter Amount"
                                                    [(ngModel)]="propObj['count']" name="" id="">
                                                <input type="number" *ngIf="propObj['app_amt_type']!='Amount'"
                                                    class="form-control" placeholder="Enter Amount in %"
                                                    [(ngModel)]="propObj['count']" name="" id="">

                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Cancellation Amount Type : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="type" bindLabel="id" bindValue="id"
                                                    [multiple]="false" placeholder="Select Property Type"
                                                    [(ngModel)]="propObj['canc_amt_type']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Cancellation Amount
                                                    : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" *ngIf="propObj['canc_amt_type']=='Amount'"
                                                    class="form-control" placeholder="Enter Amount"
                                                    [(ngModel)]="propObj['canc_count']" name="" id="">
                                                <input type="number" *ngIf="propObj['canc_amt_type']!='Amount'"
                                                    class="form-control" placeholder="Enter Amount in %"
                                                    [(ngModel)]="propObj['canc_count']" name="" id="">

                                                <!--Select Location Type-->

                                            </div>
                                        </div>

                                        <!-- <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Basic Cost: <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="propObj['basic_cost']" (change)="sum_amt()"
                                                    placeholder="Enter Basic Cost">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Additional Cost: <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" class="form-control" (change)="sum_amt()"
                                                    [(ngModel)]="propObj['additional_cost']"
                                                    placeholder="Enter Additional Cost">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Premium Cost: <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="propObj['premium_cost']" (change)="sum_amt()"
                                                    placeholder="Enter Premium Cost">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Total Cost:</h6>
                                            </div>
                                  Select Property Inventory Hierarchy              <div class="col-3">
                                                <input type="number" class="form-control" disabled
                                                    [(ngModel)]="propObj['total_cost']" placeholder="Enter Basic Cost">
                                            </div>
                                        </div> -->
                                        <!-- <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>
                                                    Select Feature Group : <span style="color: red;"> *</span>
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROPFLD52']"
                                                    bindLabel="code" bindValue="value" [multiple]="false"
                                                    placeholder="Select Feature Group" [(ngModel)]="propObj['feature_type']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    (change)="getFeatures()"
                                                    [closeOnSelect]="true" name="chart_of_account0"  >
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>
                                                    Select Feature : <span style="color: red;"> *</span>
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="feature_list"
                                                    bindLabel="feature_desc" bindValue="feature_id" [multiple]="true"
                                                    placeholder="Select Feature " [(ngModel)]="propObj['feature_id_arr']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0"  >
                                                </ng-select>
                                            </div>
                                        </div> -->
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6> Select Location Hierarchy :</h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="first_list" bindLabel="hier_buss_name"
                                                    bindValue="hier_type_id" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['hier_type_id']" [selectableGroup]="true"
                                                    (change)="getAllLevls()" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row" *ngFor="let item of data,let i=index">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Level {{i+1}} :</h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value"
                                                    bindValue="lvl1_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl1_cd']" (change)='set_layout_data(item,i)'
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value"
                                                    bindValue="lvl2_cd" [multiple]="false"
                                                    (change)='set_layout_data(item,i)' placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==2" [items]="level3"
                                                    (change)='set_layout_data(item,i)' bindLabel="lvl3_value"
                                                    bindValue="lvl3_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl3_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==3" [items]="level4"
                                                    (change)='set_layout_data(item,i)' bindLabel="lvl4_value"
                                                    bindValue="lvl4_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl4_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==4" [items]="level5"
                                                    (change)='set_layout_data(item,i)' bindLabel="lvl5_value"
                                                    bindValue="lvl5_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl5_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==5" [items]="level6"
                                                    (change)='set_layout_data(item,i)' bindLabel="lvl6_value"
                                                    bindValue="lvl6_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl6_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                                <ng-select *ngIf="i==6" [items]="level7"
                                                    (change)='set_layout_data(item,i)' bindLabel="lvl7_value"
                                                    bindValue="lvl7_cd" [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="Obj['lvl7_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3" *ngIf="i==data.length-1">
                                                <button (click)="add_dropdown(item,i)"
                                                    class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                                        aria-hidden="true"></i></button>
                                                &nbsp;&nbsp;
                                                <button (click)="remove_dropdown(item,i)" *ngIf="i!=0"
                                                    class="btn btn-outline-danger">
                                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Plan Layout:</h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="plan_layout" bindLabel="desc"
                                                    bindValue="property_group_id" [multiple]="true"
                                                    placeholder="Select Plan Layout" [(ngModel)]="propObj['id']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="false" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <button class="btn btn-outline-success" (click)="show_proprties()">Show
                                                    Propeties</button>
                                            </div>
                                        </div>
                                        <br>
                                        <!-- //mat table -->
                                        <div class="example-container" style="width: 80%; margin-left: 10%;">
                                            <table mat-table [dataSource]="datasource2" matSort>
                                                <ng-container matColumnDef="assigned_property_number">
                                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                                        Property number

                                                    </th>
                                                    <!--Property Number-->
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                                        class="right">
                                                        {{ element.assigned_property_number }}</td>
                                                </ng-container>

                                                <ng-container matColumnDef="property_type">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        <!--Property type-->
                                                        Property Type


                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        {{ element.property_type }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="description">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        Area

                                                    </th>
                                                    <!--Description-->
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        {{ element.area }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="area">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        Unit

                                                    </th>
                                                    <!--Total
                                                        Area-->
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        {{ element.unit }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        {{auth.allLableShowObjProp[auth.language_cd+'property10']}}</th>
                                                    <!--Action-->
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                                        <input type="checkbox" style="width: 20px;height: 20px;"
                                                            [(ngModel)]="element['is_checked']">
                                                    </td>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                                </tr>
                                            </table>
                                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                            </mat-paginator>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <button class="btn btn-outline-success"
                                                    (click)="save_property()">Save</button>
                                            </div>
                                        </div>
                                        <br>
                                    </div>

                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                (click)="save_At_step2()"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property35']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="third" label="Booklet">
                                    <!--Property Details-->
                                    <div class="card">

                                        <br>
                                        <div class="row">
                                            <div class="col-3">
                                            </div>
                                            <div class="col-3">
                                                <h6>Booklet :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="checkbox" class="form-control" (change)="show_booklet()"
                                                    style="width: 20px;height: 20px;" value="false" [(ngModel)]="radioBooklet">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Product :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectProduct_booklet()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{prod_obj_booklet['project_path_desc']}}
                                            </div>

                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Budget :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectBudget_booklet()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{bud_obj_booklet['project_path_desc']}}
                                            </div>

                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Activity :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectActivity_booklet()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{act_obj_booklet['project_path_desc']}}
                                            </div>

                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <div class="row" *ngIf="radioBooklet">

                                            <div class="col-3">
                                            </div>
                                            <div class="col-3">
                                                <h6>Booklet Sale Price :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" class="form-control" placeholder="Enter Sale Price"
                                                    value="false" [(ngModel)]="Obj['booklet_price']">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-3">
                                            </div>
                                            <div class="col-3">
                                                <h6>Booklet Sale Start Date :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" value="false" [(ngModel)]="Obj['booklet_start_date']" [min]="currentDate()"
                                                    class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-3">
                                            </div>
                                            <div class="col-3">
                                                <h6>Booklet End Date :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" value="false" [(ngModel)]="Obj['booklet_end_date']" [min]="Obj['booklet_start_date']"
                                                    class="form-control" [disabled]="!Obj['booklet_start_date']" >
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br *ngIf="radioBooklet">
                                        <br *ngIf="radioBooklet">
                                        <div *ngIf="radioBooklet">
                                            <div class="row" *ngIf="radioBooklet">
                                                <div class="col-3">
                                                </div>
                                                <div class="col-3">
                                                    <h6>Booklet Pdf :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <input #file type="file" name="pimage" ng2FileSelect
                                                        [uploader]="uploader" class="form-control"
                                                        style="border-color: black"
                                                        (change)="onFileUpload($event,file.files)">
                                                </div>
                                                <div class="col-3">
                                                    <button type="button" class="btn btn-outline-info"
                                                        (click)='upload()'><i class="fa fa-upload"
                                                            aria-hidden="true">&nbsp;</i>{{auth.allLableShowObjProp[auth.language_cd+'p_layout25']}}</button>
                                                    <!--Upload-->

                                                    &nbsp;&nbsp; <button class="btn btn-outline-info"
                                                        (click)="view()">View
                                                        Document</button>
                                                    <!--View Plan-->

                                                </div>

                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Is Booklet Price Including GST :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="yesNo_arr" bindLabel="id" bindValue="id"
                                                        [multiple]="false" placeholder="Select Required Document"
                                                        [(ngModel)]="Obj['is_gst_included']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br *ngIf="Obj['is_gst_included']=='YES'">
                                            <div class="row" *ngIf="Obj['is_gst_included']=='YES'">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Enter GST Percentage :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <input type="number" value="false" [(ngModel)]="Obj['gst_per']"
                                                        class="form-control">
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br *ngIf="Obj['is_gst_included']=='NO'">
                                            <div class="row" *ngIf="Obj['is_gst_included']=='NO'">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Enter GST Percentage :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <input type="number" value="false" [(ngModel)]="Obj['gst_per']"
                                                        class="form-control">
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br *ngIf="radioBooklet">
                                            <div class="row" *ngIf="radioBooklet">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Booklet GST Budget :</h6>
                                                </div>
                                                <div class="col-1">
                                                    <button (click)="selectBudget_booklet_gst()"
                                                        class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                                </div>
                                                <div class="col-5">
                                                    {{bud_obj_booklet_gst['project_path_desc']}}
                                                </div>

                                            </div>
                                            <br *ngIf="radioBooklet">
                                            <div class="row" *ngIf="radioBooklet">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Booklet GST Activity :</h6>
                                                </div>
                                                <div class="col-1">
                                                    <button (click)="selectActivity_booklet_gst()"
                                                        class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                                </div>
                                                <div class="col-5">
                                                    {{act_obj_booklet_gst['project_path_desc']}}
                                                </div>

                                            </div>
                                            <br>

                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property35']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="fourth" label="Application">
                                    <!--Property Details-->
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-2"></div>

                                            <div class="col-2">
                                                <h6>Application Details</h6>
                                            </div>
                                            <div class="col-3"></div>
                                            <div class="col-4"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Application Start Date : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">

                                                <input type="date" [(ngModel)]="Obj['application_start_date']" [min]="applicationEndDate" (change)="define_end_date_of_app_end()"
                                                    class="form-control">
                                            </div>
                                            <div class="col-4"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Application End Date : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" [(ngModel)]="Obj['application_end_date']" [min]="appEndDate"
                                                    class="form-control" [disabled]="!Obj['application_start_date']" >
                                            </div>
                                            <div class="col-4"></div>
                                        </div>
                                        <br>
                                        <br>
                                        <!-- <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Application Amount : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" placeholder="Enter Application Amount"
                                                    [(ngModel)]="Obj['application_amt']" class="form-control">
                                            </div>
                                            <div class="col-4"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Cancillation Amount (%) : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" placeholder="Enter Cancillation Amount"
                                                    [(ngModel)]="Obj['cancillation_amt']" class="form-control">
                                            </div>
                                            <div class="col-4"></div>
                                        </div>
                                        <br> -->
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Required Document : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0022']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select Required Document"
                                                    [(ngModel)]="Obj['required_doc']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                            <div class="col-3">
                                                <button (click)="add_document('Required')"
                                                    class="btn btn-outline-primary">Add</button>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Optional Document :</h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0023']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select Optional Document"
                                                    [(ngModel)]="Obj['optional_doc']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </div>
                                            <div class="col-3">
                                                <button (click)="add_document2('Optional')"
                                                    class="btn btn-outline-primary">Add</button>
                                            </div>
                                        </div>
                                        <br>
                                        <br>
                                        <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Required/Optional</th>
                                                    <th>Document</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of requied_doc;let i =index">
                                                    <td>{{item['is_req']}}</td>
                                                    <td>{{item['desc']}}</td>
                                                    <td>
                                                        <button (click)="remove_doc(i)"
                                                            class="btn btn-outline-danger">Remove</button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>
                                        <br>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property35']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>

                                <mat-step [stepControl]="fifth" label="subsidy">
                                    <!--Property Details-->
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Subsidized :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="checkbox" [(ngModel)]="subRadio"
                                                    style="width: 20px;height: 20px;" class="form-control"
                                                    value="false">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div *ngIf="subRadio">
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Quota :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROP0019']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="Select  Quota" [(ngModel)]="subsObj['quota']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Sub Quota :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROP0020']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="Select Sub Quota"
                                                        [(ngModel)]="subsObj['sub_quota']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Subsidy type :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="subsidy_type" bindLabel="id" bindValue="id"
                                                        [multiple]="false" placeholder="Select  Subsidy Type"
                                                        [(ngModel)]="subsObj['subs_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Subsidy Quanity :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <!-- <ng-select [items]="subsidy_type"
                                                        bindLabel="id" bindValue="id" [multiple]="false"
                                                        placeholder="Select Property Type"
                                                        [(ngModel)]="subsObj['subs_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                    </ng-select> -->
                                                    <!-- <input type="number" class="form-control"
                                                        placeholder="Enter Quantity"
                                                        [(ngModel)]="subsObj['subs_quantity']"> -->
                                                    <input type="number" class="form-control"
                                                        placeholder="Enter quantity"
                                                        [(ngModel)]="subsObj['subs_quantity']" name="" id="">
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Cost type :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="cost_type" bindLabel="id" bindValue="id"
                                                        [multiple]="false" placeholder="Select  Cost Type"
                                                        [(ngModel)]="subsObj['cost_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <button (click)="save_subsidy()"
                                                        class="btn btn-outline-primary">Save</button>
                                                </div>
                                            </div>
                                            <br>
                                            <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                                <thead>
                                                    <tr>
                                                        <th>Quota</th>
                                                        <th>Sub Quota</th>
                                                        <th>Subsidy Type</th>
                                                        <th>Subsidy Quanity</th>
                                                        <th>Cost type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of subs_arr;let i =index">
                                                        <td>{{quota_obj[item['quota']]}}</td>
                                                        <td>{{subQuota_obj[item['sub_quota']]}}</td>
                                                        <td>{{item['subs_type']}}</td>
                                                        <td>{{item['subs_quantity']}}</td>
                                                        <td>{{item['cost_type']}}</td>
                                                        <td>
                                                            <button (click)="remove_subs(item,i)"
                                                                class="btn btn-outline-danger">Remove</button>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property35']}}</button>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="sixth" label="done">
                                    <br>
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Offer </h6>
                                            </div>
                                            <div class="col-2"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Offer Code </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control" disabled
                                                    [(ngModel)]="Obj['offer_cd']">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Offer Name </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control" disabled
                                                    [(ngModel)]="Obj['offer_name']">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Lottery date :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['lottery_date']}}</div>
                                            <div class="col-2">
                                                <h6>Allotment Date :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['allotment_date']}}</div>
                                            <div class="col-1"></div>
                                        </div>
                                        <br>
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Booklet Start date : </h6>
                                            </div>
                                            <div class="col-2">{{Obj['booklet_start_date']}}</div>
                                            <div class="col-2">
                                                <h6>Booklet End Date :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['booklet_end_date']}}</div>
                                            <div class="col-1"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Application Start date : </h6>
                                            </div>
                                            <div class="col-2">{{Obj['application_start_date']}}</div>
                                            <div class="col-2">
                                                <h6> Application End Date :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['application_end_date']}}</div>
                                            <div class="col-1"></div>
                                        </div>
                                        <br>
                                        <div class="row" *ngIf="radioBooklet">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Booklet Amount :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['booklet_price']}}</div>
                                            <!-- <div class="col-2">
                                                <h6>Application Amount :</h6>
                                            </div>
                                            <div class="col-2">{{Obj['application_amt']}}</div> -->
                                            <div class="col-1"></div>
                                        </div>
                                        <br>
                                        <!-- <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-2">
                                                <h6>Cancillation Amount : </h6>
                                            </div>
                                            <div class="col-2">{{Obj['cancillation_amt']}}</div>

                                        </div>
                                        <br> -->
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-11">
                                                <h6>Required Document :</h6>
                                            </div>
                                        </div>
                                        <br>
                                        <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Required/Optional</th>
                                                    <th>Document</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of requied_doc;let i =index">
                                                    <td>{{item['is_req']}}</td>
                                                    <td>{{item['desc']}}</td>

                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-11">
                                                <h6>Selected Inventory :</h6>
                                            </div>
                                        </div>
                                        <br>
                                        <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Property Type</th>
                                                    <th>From Inventory</th>
                                                    <th>Number Of Property</th>
                                                    <!-- <th>Property Cost ( INR )</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of property_group_data;let i =index">
                                                    <td>{{item['property_type']}}</td>
                                                    <td>{{item['from_inventory']}}</td>
                                                    <td>{{item['no_of_pro']}}</td>
                                                    <!-- <td>{{item['property_cost']}}</td> -->

                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>
                                        <div class="row" *ngIf="subRadio">
                                            <div class="col-1"></div>
                                            <div class="col-11">
                                                <h6>Subsidy:</h6>
                                            </div>
                                        </div>
                                        <br *ngIf="subRadio">
                                        <table *ngIf="subRadio" class="table table-bordered"
                                            style="width: 80%; margin-left: 10%;">
                                            <thead>
                                                <tr>
                                                    <th>Quota</th>
                                                    <th>Sub Quota</th>
                                                    <th>Subsidy Type</th>
                                                    <th>Subsidy Quanity</th>
                                                    <th>Cost type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of subs_arr;let i =index">
                                                    <td>{{quota_obj[item['quota']]}}</td>
                                                    <td>{{subQuota_obj[item['sub_quota']]}}</td>
                                                    <td>{{item['subs_type']}}</td>
                                                    <td>{{item['subs_quantity']}}</td>
                                                    <td>{{item['cost_type']}}</td>

                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>

                                    </div>

                                    <br>
                                    <br>
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property47']}}</button>
                                        &nbsp;&nbsp;
                                        <button class="btn btn-outline-success"
                                            (click)="update()">Update</button>&nbsp;&nbsp;
                                    </div>

                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>{{auth.allLableShowObjProp[auth.language_cd+'property82']}}</ngx-spinner>
<div class="modal fade bd-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">

        <div class="modal-content" style="background-color: white;">
            <div class="modal-header">
                <h6 class="modal-title">Property Information :</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <table class="table table-bordered" style="width: 80%; margin: 10%;">
                <thead>
                    <tr>
                        <th>Property Type</th>
                        <th>Property Number</th>
                        <th>Area</th>
                        <th>Unit</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of property_group_check_data;let i =index">
                        <td>{{item['property_type']}}</td>
                        <td>{{item['property_no']}}</td>
                        <td>{{item['area']}}</td>
                        <td>{{item['unit']}}</td>

                    </tr>

                </tbody>
            </table>
        </div>
        <div class="modal-footer" style="background-color: white;">
            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        </div>
    </div>
</div>


<div class="modal fade bd-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">

        <div class="modal-content" style="background-color: white;">
            <div class="modal-header">
                <h6 class="modal-title">Property Information :</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe src='' frameBorder="0" scrolling="auto" height="100%" width="100%"></iframe>
            </div>
        </div>
        <div class="modal-footer" style="background-color: white;">
            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        </div>
    </div>
</div>

<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Project</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">


                    <div class="col-12">
                        <div class="row" *ngFor="let item of project_arr,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==project_arr.length-1">
                                <button (click)="add_dropdown2(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2(item,i)" *ngIf="i!=0" class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{project_obj['project_path_desc']}}
                    </div>

                </div>
                <br>


                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="ACTHIER_act">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Activity</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">


                    <div class="col-12">
                        <div class="row" *ngFor="let item of act_arr,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==act_arr.length-1">
                                <button (click)="add_dropdown2_act(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_act(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{act_obj['project_path_desc']}}
                    </div>

                </div>
                <br>


                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_act()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_act()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="ACTHIER_bud">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Budget</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">


                    <div class="col-12">
                        <div class="row" *ngFor="let item of bud_arr,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==bud_arr.length-1">
                                <button (click)="add_dropdown2_bud(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_bud(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{bud_obj['project_path_desc']}}
                    </div>

                </div>
                <br>


                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_bud()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_bud()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="ACTHIER_prod">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Product</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">


                    <div class="col-12">
                        <div class="row" *ngFor="let item of prod_arr,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==prod_arr.length-1">
                                <button (click)="add_dropdown2_prod(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_prod(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{prod_obj['project_path_desc']}}
                    </div>

                </div>
                <br>


                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_prod()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_prod()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="ACTHIER_booklet_prod">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Product For Booklet</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="row" *ngFor="let item of prod_arr_booklet,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj_booklet['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj_booklet['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj_booklet['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj_booklet['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj_booklet['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj_booklet['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj_booklet['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==prod_arr_booklet.length-1">
                                <button (click)="add_dropdown2_prod_booklet(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_prod_booklet(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{prod_obj_booklet['project_path_desc']}}
                    </div>

                </div>
                <br>
                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">
                    </div>
                </div>
                <br> -->
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_prod_booklet()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_prod_booklet()"
                        data-dismiss="modal">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="ACTHIER_booklet_bud">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Budget For Booklet </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">


                    <div class="col-12">
                        <div class="row" *ngFor="let item of bud_arr_booklet,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj_booklet['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj_booklet['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj_booklet['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj_booklet['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj_booklet['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj_booklet['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj_booklet['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==bud_arr_booklet.length-1">
                                <button (click)="add_dropdown2_bud_booklet(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_bud_booklet(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{bud_obj_booklet['project_path_desc']}}
                    </div>
                </div>
                <br>
                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_bud_booklet()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_bud_booklet()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="ACTHIER_booklet_bud_gst">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Budget For GST </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">


                    <div class="col-12">
                        <div class="row" *ngFor="let item of bud_arr_booklet_gst,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="bud_obj_booklet_gst['lvl1_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_bud_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="bud_obj_booklet_gst['lvl2_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_bud_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="bud_obj_booklet_gst['lvl3_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_bud_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="bud_obj_booklet_gst['lvl4_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_bud_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="bud_obj_booklet_gst['lvl5_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_bud_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="bud_obj_booklet_gst['lvl6_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_bud_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="bud_obj_booklet_gst['lvl7_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_bud_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==bud_arr_booklet_gst.length-1">
                                <button (click)="add_dropdown2_bud_booklet_gst(item,i)"
                                    class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                        aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_bud_booklet_gst(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{bud_obj_booklet_gst['project_path_desc']}}
                    </div>
                </div>
                <br>
                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_bud_booklet_gst()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_bud_booklet_gst()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <!-- Status -->Status
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">
                    <a *ngFor="let ob of statusArr;let i=index">
                        <!-- <h6>{{ob['status']}}</h6> -->
                        <div class="step completed" *ngIf="ob.status=='APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;"
                                    *ngIf="i <statusArr.length-1"></div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                            </div>
                        </div>
                        <div class="step"
                            *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">
                        <!-- Close -->Close
                    </button>
                    <!-- <button type="button" class="btn btn-success" data-dismiss="modal" (click)="certificate()">Download
                        Certificate </button> -->

                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="ACTHIER_act_booklet_gst">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Activity For Booklet GST</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">


                    <div class="col-12">
                        <div class="row" *ngFor="let item of act_arr_booklet_gst,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="act_obj_booklet_gst['lvl1_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_act_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="act_obj_booklet_gst['lvl2_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_act_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="act_obj_booklet_gst['lvl3_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_act_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="act_obj_booklet_gst['lvl4_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_act_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="act_obj_booklet_gst['lvl5_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_act_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="act_obj_booklet_gst['lvl6_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_act_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="act_obj_booklet_gst['lvl7_cd']" [selectableGroup]="true"
                                    (change)="set_Path_desc_act_booklet_gst(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==act_arr_booklet_gst.length-1">
                                <button (click)="add_dropdown2_act_booklet_gst(item,i)"
                                    class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                        aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_act_booklet_gst(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{act_obj_booklet_gst['project_path_desc']}}
                    </div>

                </div>
                <br>


                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_act_booklet_gst()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_act_booklet_gst()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="ACTHIER_act_booklet">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Activity For Booklet</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">


                    <div class="col-12">
                        <div class="row" *ngFor="let item of act_arr_booklet,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj_booklet['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj_booklet['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj_booklet['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj_booklet['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj_booklet['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj_booklet['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj_booklet['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act_booklet(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==act_arr_booklet.length-1">
                                <button (click)="add_dropdown2_act_booklet(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_act_booklet(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{act_obj_booklet['project_path_desc']}}
                    </div>

                </div>
                <br>


                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_act_booklet()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_act_booklet()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                            [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                            placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div>
                    <table class="table table-bordered"
                        style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">
                                    <!--  <button class="btn btn-success" (click)="deleteLine(i)"><i class="fa fa-check"
                                        style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button>
                                <button class="btn btn-danger" (click)="copy(ln)">
                                     <i class="fa fa-times"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i>

                                        </button> -->
                                    <!-- <button class="btn btn-warning" (click)="paste(i)"> -->
                                    <!-- <i class="fas fa-paste"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->

                                    <!-- </button>  -->
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" style="border: black;">
                    <div class="col-4 text-right">
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit
                        </button>
                    </div>
                </div>
                <br>
                <!-- <div *ngIf="vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <h6>OR</h6>
                    </div>
                </div> -->
                <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal " id="viewdoc">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <!-- Status -->Status
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <embed [src]='imgURL' type="application/pdf" />

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">
                        <!-- Close -->Close
                    </button>
                    <!-- <button type="button" class="btn btn-success" data-dismiss="modal" (click)="certificate()">Download
                        Certificate </button> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="viewDetails">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content rounded-50">

            <!-- Modal Header -->
            <div class="modal-header bg-primary">
                <h4 class="modal-title text-white" style="font: bold; "> Offer {{offerDetails['mode_of_sale']}} Details :</h4>
                <button type="button text-white" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body" style="border-radius: 10px;">
                <div class="card" style="border: 2px solid  rgb(24, 24, 97);">
                    <div style="margin: 3%;">
                        <div class="content-container">
                            <div class="content">
                                <div class="left-content">
                                    <h5 style=" width: 50%;"><b>Offer Code :</b></h5>
                                    <h5 style=" width: 50%">{{offerDetails['offer_cd']}}</h5>
                                </div>
                                <div class="right-content">
                                    <h5 style="width:50%;"><b>Offer Name :</b></h5>
                                    <h5 style="width:50%;">{{offerDetails['offer_name']}}</h5>
                                </div>
                            </div>
                            <div class="content mt-2">
                                <div class="left-content">
                                    <h5 style="width:50%;"><b>Offer Description :</b></h5>
                                    <h5 style="width:50%;">{{offerDetails['offer_desc']}}</h5>
                                </div>
                                <div class="right-content">
                                    <h5 style="width:50%;"><b>Lottery Date :</b></h5>
                                    <h5 style="width:50%;">{{offerDetails['lottery_date']}}</h5>
                                </div>
                            </div>
                            <div class="content mt-2">
                                <div class="left-content">
                                    <h5 style="width:50%;"><b>Allotment Date :</b></h5>
                                    <h5 style="width:50%;">{{offerDetails['allotment_date']}}</h5>
                                </div>
                                <div class="right-content" *ngIf='dispBook'>
                                    <h5 style="width:50%;"><b>Booklet Start Date :</b></h5>
                                    <h5 style="width:50%;">{{offerDetails['booklet_start_dt']}}</h5>
                                </div>
                            </div>
                            <div class="content mt-2">
                                <div class="left-content" *ngIf='dispBook'>
                                    <h5 style="width:50%;"><b>Booklet End Date :</b></h5>
                                    <h5 style="width:50%;">{{offerDetails['booklet_end_dt']}}</h5>
                                </div>
                                <div class="right-content">
                                    <h5 style="width:50%; "><b>Application Start Date :</b></h5>
                                    <h5 style="width:50%; ">{{offerDetails['application_start_dt']}}</h5>
                                </div>
                            </div>
                            <div class="content mt-2">
                                <div class="left-content">
                                    <h5 style="width:50%;"><b>Application End Date :</b></h5>
                                    <h5 style="width:50%;">{{offerDetails['application_end_dt']}}</h5>
                                </div>
                                <div class="right-content" *ngIf='dispBook'>
                                    <h5 style="width:50%;"><b>Booklet Price :</b></h5>
                                    <h5 style="width:50%;">{{offerDetails['booklet_price']}}</h5>
                                </div>
                            </div>
                            <div class="content mt-2">
                                <div class="left-content">
                                    <h5 style="width:50%;"><b>Total No of Property :</b></h5>
                                    <h5 style="width:50%;">{{offerDetails['sum(pro.no_of_pro)']}}</h5>
                                </div>
                                <!-- <div class="right-content">
                                    <p>Application End Date : <p>
                                    <p>{{offerDetails['application_end_dt']}}<p>
                                </div> -->
                            </div>
                        </div>

                        <h4 class="text-center mt-3 mb-3">Property Cost</h4>
                        <table class="tables1">
                            <tr>
                                <th>SNo.</th>
                                <th>Property type</th>
                                <th>Property rate</th>
                                <!-- <th>Unit</th> -->
                                <th>Total Property</th>
                            </tr>
                            <tr *ngFor="let item of offercost; let i =index">
                                <td><b>{{i+1}}</b></td>
                                <td>{{item.property_type}}</td>
                                <td>{{item.pro_rate}}</td>
                                <!-- <td>{{item.unit}}</td> -->
                                <td>{{item.no_of_pro}}</td>
                            </tr>
                        </table>

                        <h4 class="text-center mt-3 mb-3">Document</h4>
                        <table class="tables1">
                            <tr>
                                <th>SNo.</th>
                                <th>Offer Code</th>
                                <th>Document Type</th>
                                <th>required/optional</th>
                            </tr>
                            <tr *ngFor="let item of documents; let i =index">
                                <td><b>{{i+1}}</b></td>
                                <td>{{item.offer_cd}}</td>
                                <td>{{item.doc_type}}</td>
                                <td>{{item.is_req}}</td>
                            </tr>
                        </table>
                        <!-- <div class="row">
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Applicant Name </h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['party_name']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Mobile No. </h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['party_phone_no']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Email </h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['party_email']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Category </h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['quota_cd']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Property Type </h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['property_type']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Alloted Property No</h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['assigned_property_number']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Registration Amount (₹)</h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['application_amount']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Property  Cost (₹)</h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['pro_rate']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Payment Mode</h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['mode_of_payment']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>
                        <div class="row" *ngIf="view_infoObj['mode_of_payment']=='Installment'">
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Frequency of Installment</h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['frequency_of_installment']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br> -->
                        <!-- <div class="row" >
                            <div class="col-2"></div>
                            <div class="col-3">
                                <h5>Total No of Payment</h5>
                            </div>

                            <div class="col-6">
                                : &nbsp; {{offerDetails['no_of_payment']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-3">
                                <h5>Total Offerd Property </h5>
                            </div>

                            <div class="col-2">
                                : &nbsp; {{offerDetails['sum(pro.no_of_pro)']}}
                            </div>
                            <div class="col-6"></div>
                        </div>


                        <br> -->
                        <!-- <div class="row" *ngIf="view_infoObj['mode_of_payment']=='Installment'">
                            <table class="table table-bordered"
                                style="width: 80%; margin-left: 10%;color:white; background-color: rgb(118, 118, 118);">
                                <thead>
                                    <tr>
                                        <th>S NO.</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Installment Amout</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="view_infoObj['first_lumsum']">
                                        <td>{{0}}</td>
                                        <td>{{view_infoObj['first_lumsum_start_date']}}</td>
                                        <td>{{view_infoObj['first_lumsum_end_date']}}</td>
                                        <td>{{view_infoObj['first_lumsum']}}</td>
                                    </tr>
                                    <tr *ngIf="view_infoObj['second_lumsum']">
                                        <td>{{0}}</td>
                                        <td>{{view_infoObj['second_lumsum_start_date']}}</td>
                                        <td>{{view_infoObj['second_lumsum_end_date']}}</td>
                                        <td>{{view_infoObj['second_lumsum']}}</td>
                                    </tr>
                                    <tr *ngFor="let item of  all_installment;let i =index">
                                        <td>{{i+1}}</td>
                                        <td>{{item['emi_start_date']}}</td>
                                        <td>{{item['emi_end_date']}}</td>
                                        <td>{{item['emi_amount']}}</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div> -->


                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <!-- <b>Download Allotment </b><button class="btn btn-outline-primary"
                         (click)="download(data)">Download</button> -->
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
