import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  token = "work@pcs";
  session='S@1';
  async getIPAddress() {
    await this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.session = res.ip;
    });
  }
  constructor(private http: HttpClient) { 
    this.token=this.encryptUsingAES256(this.token);
    this.getIPAddress();
  }
   ///********************************OTP **********************************************************************************
   tokenFromUI = 'd6387ed9704181f860122340e5a042d661afb50211cf45903c01026db4d756cd'

   encryptUsingAES256(request:any) {
     let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
     let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
     let encrypted = CryptoJS.AES.encrypt(
       JSON.stringify(request), _key, {
       keySize: 16,
       iv: _iv,
       mode: CryptoJS.mode.ECB,
       padding: CryptoJS.pad.Pkcs7
     });
     return encrypted.toString();
   }
   ///********************************OTP **********************************************************************************
}
