<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Salary Rules -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual1']}}
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i
                                    class="ti-bar-chart"></i>
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual2']}}   <!-- Annual Increment -->
                                </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                       Select Finance Year : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="inc['fin_year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual3']}} : <span style="color:red">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="inc['inc_month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual4']}} :
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]=" this.inc['effective_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="annualIncrement()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual5']}} <!-- Submit -->

                                    </button>
                                </div>
                            </div>
                            <br>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual6']}}">
                                    </mat-form-field>
                                </div>
                                <div class="col-6 text-right">
                                    <button class="btn btn-info" (click)="print()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual7']}} <!-- Print -->
                                    </button>
                                    <button class="btn btn-success" (click)="export()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual8']}}    <!-- Export -->
                                    </button>
                                    <button class="btn btn-primary" (click)="incReq()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual9']}}    <!-- Increment -->
                                    </button>

                                </div>
                            </div>



                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual10']}}<!-- Employee ID -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{element.emp_id}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual11']}} <!-- Employee Name -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{element.emp_name}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="designation_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual12']}}<!-- Designation -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.designation_code }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_component_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual13']}}<!-- Component -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_component_code}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_component_amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual14']}} <!-- Amount -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_component_amt}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual15']}} <!-- Type -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_code}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual16']}} <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                                            <button class="btn btn-danger" (click)="deleteRow(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual17']}}    <!-- Delete -->
                                            </button>
                                        </td>
                                    </ng-container>



                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                            <div id="p" [hidden]="true">
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img [src]="mainService.accountImageUrl" class="img-circle" width="90px" />

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h4>{{mainService.accInfo['account_name']}} ({{mainService.accInfo['account_short_name']}})
                                        </h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>
                                            <!-- Increment Report For  -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual18']}} {{mainService.codeValueShowObj['HR0024'][inc['inc_month']]}}
                                        </h6>
                                    </div>
                                </div>
                                <table class="table" style="border: black;">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual19']}} <!-- Serial No. -->

                                            </th>
                                            <th>
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual10']}} <!-- Employee ID -->
                                            </th>
                                            <th>
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual11']}} <!-- Employee Name -->
                                            </th>
                                            <th>
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual12']}} <!-- Designation -->
                                            </th>
                                            <th>
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual13']}} <!-- Component -->
                                            </th>

                                            <th>
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual14']}} <!-- Amount -->
                                            </th>
                                            <th>
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual15']}} <!-- Type -->
                                            </th>
                                            <th>
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual20']}}   <!-- Update Date -->
                                            </th>





                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ob of salArr;let i = index">
                                            <td>
                                                <h6>{{i+1}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['emp_id']}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['emp_name']}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['designation_code']}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['pay_component_code']}}</h6>
                                            </td>

                                            <td>
                                                <h6>{{ob['pay_component_amt']}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['pay_code']}}</h6>
                                            </td>

                                            <td>
                                                <h6>{{ob['effective_start_dt']}}</h6>
                                            </td>




                                        </tr>

                                    </tbody>
                                </table>
                                <hr>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'hr_annual21']}} </ngx-spinner>
