import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
import { MainService } from '../../service/main.service';
import Swal from 'sweetalert2';
declare var $: any
@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {

  constructor(private settingService: SettingService, public mainService: MainService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser: any;
  b_acct_id: any;

  allSection: any = [];
  obj: any = {};

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }

  displayedColumns = ['id', 'department_code', 'office_code', 'section_code', 'action'];
  datasource:any;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    console.log("Mohit ");
    console.log(this.b_acct_id);
    await this.getAllSection();
  }

  open_update(element:any) {
    this.obj = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.obj = {};
  }

  async getAllSection() {
    this.spinner.show()
    var resp = await this.settingService.getAllSections(this.b_acct_id);
    console.log(resp)
    if (resp['error'] == false) {
      this.allSection = resp.data;
      this.datasource = new MatTableDataSource(this.allSection)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting  all Section list ', 'error')
      // this.snackBar.open("Error while getting  all Section list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async save() {
    this.spinner.show();
    this.obj['create_user_id'] = this.erpUser.user_id;
    this.obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.createSection(this.obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllSection();
      Swal.fire('Success', 'Section Added Successfully! ', 'success')
      // this.snackBar.open("Section Added Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while Adding Section', 'error')
      // this.snackBar.open("Error while Adding Section", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async update() {
    this.spinner.show();
    this.obj['update_user_id'] = this.erpUser.user_id;
    this.obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.updateSection(this.obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllSection();
      Swal.fire('Success', 'Section Update Successfully! ', 'success')
      // this.snackBar.open("Section Update Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while updating Section ', 'error')
      // this.snackBar.open("Error while updating Section", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async delete(element:any) {
    this.spinner.show();
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = element.id;
    var resp = await this.settingService.deleteSection(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllSection();
      Swal.fire('Success', 'Section Delete Successfully! ', 'success')
      // this.snackBar.open("Section Delete Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while deleting Section ', 'error')
      // this.snackBar.open("Error while deleting Section", 'Error', {
      //   duration: 5000
      // });
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


}
