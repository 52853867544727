import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class  EmpTrackingService {
  httpUrl;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/tracking";
  }

  async getuser(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/emp-tracking/getuserbyzone/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async getallcoordinates(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/emp-tracking/getallzonecordinate/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async getuser_coordinates(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.get(this.httpUrl + '/user_track/getuserzonecordinates' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async getzone_coordinates(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.get(this.httpUrl + '/emp-tracking/getall_zonecordinate/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async geo_coder(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.post(this.httpUrl + '/user_track/repeted_data' , obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async getuser_z(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.get(this.httpUrl + '/user_track/getalluser_zone/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async getalluser(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.get(this.httpUrl + '/user_track/getalluser/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }




  async getUploaddocumentrimg(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.post(this.httpUrl + '/document/getuser_img', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getdistance(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.post(this.httpUrl + '/user_track/calculate_distance' , obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }



}


