
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AllotmentService } from '../../service/allotment.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchemeService } from '../../service/scheme.service';
import { SubSchemeService } from '../../service/sub-scheme.service';
import { RegistryService } from '../../service/registry.service'
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { ExcelService } from '../../service/file-export.service';
import { AuthenticationService } from '../../../authentication.service';
import { MainService } from '../../service/main.service'
import { SaleService } from '../../service/sale.service';
import Swal from 'sweetalert2';
import { MetadataService } from '../../service/metadata.service';
// import { element } from 'protractor';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Component({
  selector: 'app-allotment',
  templateUrl: './allotment.component.html',
  styleUrls: ['./allotment.component.css']
})
export class AllotmentComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  Obj : any= {}
  globleElement: any = {}
  constructor(public auth: AuthenticationService, private metadataService: MetadataService, private sale_service: SaleService, private SchemeService: SchemeService, private registerService: RegistryService, public mainService: MainService, private excl: ExcelService, private SubSchemeService: SubSchemeService, private service: AllotmentService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService) { }
  dataSource: any;
  //  displayedColumns = ['party_id', 'arr_effective_date', 'property_code', 'measurement_unit', 'property_no', 'quota_code', 'scheme_code', 'sub_scheme_code', 'final_amount', 'status', 'action'];
  displayedColumns = ['party_id', 'arr_effective_date', 'property_code', 'measurement_unit', 'property_no', 'quota_code', 'scheme_code', 'status', 'action'];

  partyArr : any= [];
  partyObj: any = {};
  allBookletPurchase: any = [];
  bookletPurchaseObj: any = {};
  allotmentObj : any= {};
  allSchemes: any = [];
  b_acct_id: any;
  erpUser: any;
  selectedSchemeCode: any = "";
  user_id: any
  obj: any = {}
  propertyArr: any = []
  arrArr : any= []
  subschemeArr: any;
  subschemeArr1: any;
  subschemeObject: any = {}
  subselectedSchemeCode: any;
  schemeArr: any;
  // samarjeet
  schemeObject: any = {}
  _date: any = new Date();

  applicantPaymentMethod: any;
  selectedApplicant: any;
  selectedProNumber: any;
  selectedPropertyNumber: any = [];
  seletedPaymentMethod: any;
  resObj: any = { 'lottery_date': this.currentDate() }
  //
  paymentOptionDetail() {
    // just for giving selected object
    let obj: any = this.paymentOptionArr.filter((ele: any) => ele.payment_option_id == this.resObj['payment_option_id'])[0]
    console.log(obj)
    this.resObj = { ...this.resObj, ...obj }
  }

  async filterApplicantUsinProType() {
    // it will give selected property numbers on the behalf of selected property type
    // it will give selected applicant on the behalf of selected property type
    this.resObj['id'] = null
    this.resObj['alloted_property_id'] = null
    this.resObj['mode_of_payment'] = null
    this.resObj['payment_option_id'] = null
    // resObj['property_type']
    let obj = this.setPropData[3].filter((ele: any) => ele.property_type == this.resObj['property_type'])[0]
    this.resObj = Object.assign(this.resObj, obj)
    this.selectedApplicant = this.arrArr.filter((ele: any) => this.resObj['property_type'] == ele['property_type']) // array 1
    //  console.log(this.propertyArr2)
  }
  async allPaymetOption() {
    // console.log(this. paymentOption)
    this.paymentOptionArr = this.paymentOption.filter((item: any) => (item.mode_of_payment == this.resObj['mode_of_payment'] && item.pro_type_or_sale_value == this.resObj["property_type"]))
    // console.log(this.paymentOptionArr)
  }

  // samarjeet
  current_date: any;
  currentDate() {
    let m: any = this._date.getMonth() + 1;
    let d: any = this._date.getDate()
    if (d < 10) {
      d = '0' + d
    }
    if (m < 10) {
      m = "0" + m
    }

    let c = this._date.getFullYear() + "-" + m + "-" + d
    this.current_date = c
    return c

  }
  async ngOnInit() {
    this.Obj['reason'] = ''

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    //await this.getAllSchemesforAllotment();
    // await this.getAllSchemes();
    await this.getAllOffers()
  }

  allOffer: any = []
  async getAllOffers() {
    this.resObj = { 'lottery_date': this.currentDate() }
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    console.log(obj)
    var resp: any = await this.sale_service.getAllOffers(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.allOffer = resp['data']
      console.log(resp['data'], 'fetched data')
      let dummy = []
      for (let i = 0; i < this.allOffer.length; i++) {
        this.allOffer[i]['application_start_dt'] = this.allOffer[i]['application_start_dt'].split('T')[0]
        this.allOffer[i]['application_end_dt'] = this.allOffer[i]['application_end_dt'].split('T')[0]
        this.allOffer[i]['desc'] = this.allOffer[i]['offer_cd'] + ' - ' + this.allOffer[i]['offer_name']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  allProOffer: any = []
  async getAllPropOffers() {
    this.spinner.show()
    let obj : any= {}
    this.allProOffer = []
    this.obj1['property_type'] = null
    obj['b_acct_id'] = this.b_acct_id
    // obj['life_cycle_status'] = 'ACTIVE'
    obj['offer_cd'] = this.obj1['offer_cd']
    console.log(obj)
    var resp: any = await this.sale_service.getAllPropOffers(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allProOffer = resp['data']
      console.log(this.allProOffer, 'fetched offer porperty data')

      // for( let i=0 ; i < resp['data'].length ; i++){
      //   this.allProOffer[i] = resp['data'].pro_type
      //   console.log(this.allProOffer,'DATA')
      // }
      //  console.log(this.allProOffer, 'fetched offer porperty data')

      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  async getAllSchemes() {
    this.spinner.show();

    var resp: any = await this.SchemeService.getScheme(this.b_acct_id);
    if (resp['error'] == false) {
      this.schemeArr = resp.data;
      for (let i = 0; i < this.schemeArr.length; i++) {
        this.schemeObject[this.schemeArr[i]['scheme_code']] = this.schemeArr[i]['scheme_name']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.snackBar.open("Error occured while getting Schemes", 'Error', {
        duration: 5000,
      });
    }
  }
  all_installment: any;
  view_infoObj: any = {}
  async view_info(element: any, i: any) {
    this.allotmentObj = element
    // offer_cd  party_id
    let resp2 = await this.service.getAllInstallment(JSON.stringify({
      'party_id': this.allotmentObj['party_id'],
      'offer_cd': this.allotmentObj['offer_cd'],
      'b_acct_id': this.b_acct_id
    }))
    this.all_installment = resp2['data']

    console.log(resp2['data'])

    console.log(this.allotmentObj, 'testing')
    this.view_infoObj = Object.assign({}, element, i)
    $('#myModal10').modal('show');
    // await this.getAllPropertyOffer(data['offer_cd'])

  }

  async getSubSchemesAllforAllotment() {
    this.spinner.show();

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['scheme_code'] = this.obj['scheme_code'];
    this.obj['sub_scheme_code'] = ""
    var resp: any = await this.SubSchemeService.getsubScheme(obj);
    if (resp['error'] == false) {
      this.subschemeArr1 = resp.data;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.snackBar.open("Error occured while getting Sub Schemes", 'Error', {
        duration: 5000,
      });
    }
  }
  async getAllSubschemes() {
    this.spinner.show();

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['scheme_code'] = this.selectedSchemeCode;
    var resp: any = await this.SubSchemeService.getsubScheme(obj);
    if (resp['error'] == false) {
      this.subschemeArr = resp.data;

      for (let i = 0; i < this.subschemeArr.length; i++) {
        this.subschemeObject[this.subschemeArr[i]['sub_scheme_code']] = this.subschemeArr[i]['sub_scheme_name']
      }
      this.spinner.hide();

    } else {

      this.spinner.hide();
      this.snackBar.open("Error occured while getting Sub Schemes", 'Error', {
        duration: 5000,
      });
    }
  }
  obj1: any = {}
  async getAllAllotment() {
    this.spinner.show();
    this.Obj['reason'] = ''
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['offer_cd'] = this.obj1['offer_cd']
    obj['property_type'] = this.obj1['property_type']
    console.log(obj)
    var resp: any = await this.service.getAllAllotment(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {

      this.allBookletPurchase = resp.data;
      this.dataSource = new MatTableDataSource(this.allBookletPurchase);
      // console.log(this.allBookletPurchase)
      this.dataSource.sort = this.sort;

      this.dataSource.paginator = this.paginator;
      this.spinner.hide();


    } else {
      this.spinner.hide();

      this.snackBar.open("Error occured while getting Allotments", 'Error', {
        duration: 5000,
      });
    }
  }

  duration: any = { "Monthly": 12, "Bi_Monthly": 6, "Quarterly": 4, "Half_Yearly": 2, "Yearly": 1 }
  gap: any = { "Monthly": 1, "Bi_Monthly": 2, "Quarterly": 3, "Half_Yearly": 6, "Yearly": 12 }

  formatDate(dateObject: any) {
    let m: any = dateObject.getMonth() + 1;
    let d: any = dateObject.getDate();
    if (d < 10) {
      d = "0" + d
    }
    if (m < 10) {
      m = "0" + m
    }
    return dateObject.getFullYear() + "-" + m + "-" + d
  }


  async giveAllInstallment() {
    let emi_payments = this.genrateEmi() // here i genrated the emi amount

    let overAllPaymentByEmi = parseFloat(emi_payments) * this.resObj['no_of_payment']
    let total_Princpal = parseFloat((this.resObj['principal_amount']).toFixed(2));
    console.log(total_Princpal)
    let total_intrest = parseFloat((overAllPaymentByEmi - total_Princpal).toFixed(2))
    console.log(total_Princpal, total_intrest, overAllPaymentByEmi)
    console.log(this.resObj['first_lumsum_day'])
    let k = new Date(this.resObj['allotment_date'])
    //k is holding the allotement date object
    let emis: any = {}
    let first_duration_date: any;
    let last_duration_date: any;
    console.log(this.r_factor)
    let current_Interest_given = parseFloat((total_Princpal * this.r_factor).toFixed(2)) // right
    let current_principal_given = parseFloat(emi_payments) - current_Interest_given; // right
    let current_principal_not_due = parseFloat((total_Princpal - current_principal_given).toFixed(2))  //  principal
    let current_Interest_not_due = parseFloat((total_intrest - current_Interest_given).toFixed(2))    //  principal
    console.log(current_Interest_given, current_principal_given, current_principal_not_due, current_Interest_not_due)

    // let date_of_all = new Date(2022,0,31)
    if (this.resObj['first_lumsum']) {
      first_duration_date = this.formatDate(k) // it will hold the
      k.setDate(k.getDate() + this.resObj['first_lumsum_day'])
      last_duration_date = this.formatDate(k)

      console.log(first_duration_date, last_duration_date)
      emis["first_lumsum_start_date"] = first_duration_date
      emis["first_lumsum_end_date"] = last_duration_date
      emis["first_lumsum"] = this.resObj['first_lumsum']
      console.log(first_duration_date, last_duration_date)
      //  here i am sending data in emis
    }
    if (this.resObj['second_lumsum']) {
      k.setDate(k.getDate() + 1)
      first_duration_date = this.formatDate(k) // it will hold the
      k.setDate(k.getDate() + this.resObj['second_lumsum_day'])
      last_duration_date = this.formatDate(k)
      emis["second_lumsum_start_date"] = first_duration_date
      emis["second_lumsum_end_date"] = last_duration_date
      emis["second_lumsum"] = this.resObj['first_lumsum']
    }

    emis['emi_data'] = []
    //  taking the effective date in loop no.--1 it will be increses by 1
    k = new Date(this.resObj['effective_date'])
    let helper: Date
    let gap: any = this.gap[this.resObj['frequency_of_installment']]

    for (let index = 1; index < this.resObj['no_of_payment'] + 1; index++) {
      // finding first date

      if (index != 1) { //kyoki k pahlibar me index 1 hoga is liye ye nahi chakega
        // pahli bar effective date ko as it le lega bad me fist_duration
        // bad me 1 increase kar ke lega
        current_Interest_given = parseFloat((current_principal_not_due * this.r_factor).toFixed(2))
        current_principal_given = parseFloat((parseFloat(emi_payments) - current_Interest_given).toFixed(2));
        current_principal_not_due = parseFloat((current_principal_not_due - current_principal_given).toFixed(2))
        current_Interest_not_due = parseFloat((current_Interest_not_due - current_Interest_given).toFixed(2))

        k.setDate(k.getDate() + 1);
      }
      first_duration_date = this.formatDate(k)  // first date is taken

      // work for last date
      let current_month = k.getMonth()
      let next_month = (current_month + gap) % 12
      let gap_year = (current_month + gap) > 11 ? 1 : 0

      // let I = overAllprincipalByEmi*R

      if (k.getDate() == 1) {
        // ager day 1 hoto us month
        //lst_duration date ka next
        k = new Date(k.getFullYear() + gap_year, next_month, 0);
      } else if (next_month == 1 && k.getDate() - 1 > 29) {
        // special case for 31-january 30-january
        console.log(next_month)
        k = new Date(k.getFullYear() + gap_year, next_month, 0)
      } else {
        k = new Date(k.getFullYear() + gap_year, next_month, k.getDate() - 1)
      }
      last_duration_date = this.formatDate(k)
      // finding start date
      // finding last date

      // if(index!=this.resObj['no_of_payment']){
      emis['emi_data'].push({
        "no_of_pay": index,
        "emi_start_date": first_duration_date,
        "emi_end_date": last_duration_date,
        "emi_amount": emi_payments,

        "cur_principal_amt": current_principal_given,
        "cur_interest_amt": current_Interest_given,
        "principal_amt_not_yet_due": current_principal_not_due,
        "interest_amt_not_yet_due": current_Interest_not_due
      })
    }
    console.log(emis)

    this.resObj = { ...this.resObj, ...emis }

  }
  simplePrincipal: any;
  r_factor: any;
  genrateEmi() {
    // TODO
    // still working in this function
    let totale_amount = this.resObj['pro_rate']
    //  this.resObj['pro_rate']
    let registration_amount = this.resObj['registration_amount']
    // this.resObj['registration_amount']
    let P = totale_amount - registration_amount;

    if (this.resObj['first_lumsum']) {
      P = P - this.resObj['first_lumsum']
    }
    if (this.resObj['second_lumsum']) {
      P = P - this.resObj['second_lumsum']
    }
    let n = this.resObj['no_of_payment']

    // this.resObj['no_of_payment'];
    let R = this.resObj['interest_rate']
    // this.resObj['interest_rate'];
    // this.duration[this.resObj['frequency_of_installment']];
    // for monthly
    // For monthly r = R / (12*100)
    // For Bi-Monthly r = R / (6*100)
    // For Quarterly r = R / (4*100)
    // For Half-Yearly r = R / (2*100)
    // For Yearly r = R / 100
    let r = R / (this.duration[this.resObj['frequency_of_installment']] * 100)
    this.r_factor = r
    let EMI = Math.round((P * r * ((r + 1) ** n)) / (((r + 1) ** n) - 1))
    console.log(EMI.toFixed(2))
    this.resObj['principal_amount'] = P //principal amount
    this.resObj['principal_amt_not_yet_due'] = P
    this.resObj['interest_amt_not_yet_due'] = parseFloat((EMI * n - P).toFixed(2))
    return EMI.toFixed(2)
  }


  // async createInstallment(){
  //   let res = this.service.partyEmi(this.resObj)
  //   return res['error']==false
  // }

  async addAllotment() {
    this.resObj['period_start_from'] = "Allotment"
    this.allotmentObj['id'] = this.resObj['id']
    this.allotmentObj['b_acct_id'] = this.b_acct_id;
    this.resObj['b_acct_id'] = this.b_acct_id;
    this.allotmentObj['create_user_id'] = this.user_id
    this.resObj['user_id'] = this.user_id
    this.resObj['life_cycle_status'] = 'ACTIVE'
    this.allotmentObj['txn_gateway_id'] = null;
    this.allotmentObj['alloted_property_id'] = this.resObj['alloted_property_id']
    // for party_id and registration_amount
    this.arrArr.forEach((ele: any) => {
      if (this.resObj['id'] == ele['id']) {
        this.resObj['party_id'] = ele['party_id']
        this.resObj['registration_amount'] = ele['registration_amount']

      }
    })
    for (let i = 0; i < this.arrArr.length; i++) {
      if (this.arrArr[i]['id'] == this.resObj['id']) {
        this.allotmentObj['party_id'] = this.arrArr[i]['party_id'];
        break;
      }
    }
    this.spinner.show();
    // console.log(this.allotmentObj,'ggggghh')
    if (this.resObj['mode_of_payment'] != "Cash Down") {
      this.giveAllInstallment();
    } else {
      // this is for casedown
      let k = new Date(this.resObj['allotment_date'])
      k.setDate(k.getDate() + this.resObj['first_pay_day'])
      // console.log(k)
      this.resObj['first_lumsum_start_date'] = this.resObj['allotment_date']
      this.resObj['first_lumsum'] = this.resObj['pro_rate'] - this.resObj['registration_amount']
      this.resObj['principal_amount'] = this.resObj['pro_rate'] - this.resObj['registration_amount']
      this.resObj['first_lumsum_end_date'] = this.formatDate(k)
    }

    console.log(this.resObj)

    this.genrateEmi()
    let resp: any = await this.service.createAllotment(this.resObj);
    // console.log(resp)
    // resp2 = this.
    console.log(resp)
    if (resp['error'] == false) {
      console.log(this.allotmentObj['offer_cd'])
      console.log(this.allotmentObj)
      this.spinner.hide();
      Swal.fire('Info', resp['data'], 'info')
      await this.getAllAllotment() ///get all allotement
      // task error find path1
      await this.fetchDteaildFprAllotment()
      this.displayTableFlag = false;
      let temp = this.resObj['allotment_date']
      this.resObj = { 'lottery_date': this.currentDate() }
      this.resObj['allotment_date'] = temp;
      let evntResp = await this.service.sendEventForTestingProperty({ party_id: this.allotmentObj['party_id'], b_acct_id: this.b_acct_id })
      if (evntResp['error']) {
        this.spinner.hide();
        Swal.fire('Error...', 'Error While Sending Data To Account', 'error')
      }
    } else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While Adding', 'error')
    }

  }

  async openUpdate(element: any) {
    this.allotmentObj = element;
    this.obj['offer_cd'] = element['offer_cd']
    // this.obj['sub_scheme_code'] = element['sub_scheme_code']
    this.obj['b_acct_id'] = this.b_acct_id
    this.allotmentObj['property_id'] = this.allotmentObj['alloted_property_id']
    await this.fetchDteaildFprAllotment()
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }
  async updateAllotment() {
    this.allotmentObj['b_acct_id'] = this.b_acct_id;
    this.allotmentObj['old_property_id'] = this.allotmentObj['property_id']
    this.allotmentObj['update_user_id'] = this.user_id
    this.allotmentObj['arr_effective_date'] = this.allotmentObj['arr_effective_date'].split('T')[0];
    console.log(this.allotmentObj)
    if (this.allotmentObj['old_property_id'] == this.allotmentObj['alloted_property_id']) {
      Swal.fire('Warining...', 'Newly Alloted And Previously Alloted Property Is Same', 'warning')
    } else {
      this.spinner.show();
      var resp: any = await this.service.updateAllotment(this.allotmentObj);
      console.log(resp)
      if (resp['error'] == false) {
        $('.nav-tabs a[href="#tab-1"]').tab('show')
        await this.getAllAllotment()
        this.allotmentObj = {}
        this.obj = {}
        this.spinner.hide();
        Swal.fire('Success...', resp['data'], 'success')
        // this.snackBar.open("Updated Successfully", 'Success', {
        //   duration: 5000,
        // });
      } else {

        this.spinner.hide();
        // this.snackBar.open("Error occured while Updating Allotment", 'Error', {
        //   duration: 5000,
        // });
        Swal.fire('Error...', 'Some Error Occured While Updating...', 'error')
      }
    }

  }
  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  setPropData: any = []
  unique_proType: any = []
  paymentOption : any= []
  paymentOptionArr: any = []
  mode_of_sale = "no";
  async fetchDteaildFprAllotment() {
    this.resObj = { 'lottery_date': this.currentDate() }
    this.propertyArr = []
    this.arrArr = []
    this.unique_proType = []
    console.log(this.allotmentObj)
    var obj = Object.assign({}, this.allotmentObj)
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    console.log(obj)
    var resp: any = await this.service.getDataForAllotment(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.propertyArr = resp.data[0]
      this.unique_proType = [...new Set(this.propertyArr.map((item: any) => item.pro_type))]

      for (let i = 0; i < this.propertyArr.length; i++) {
        this.propertyArr[i]['desc'] = this.propertyArr[i]['property_id'] + ' - ' + this.propertyArr[i]['assigned_property_number']
      }
      this.arrArr = resp.data[1]
      // samarjeet
      this.paymentOption = resp.data[2]
      console.log(this.paymentOption)

      this.paymentOption[0] ? (this.resObj['allotment_date'] = this.paymentOption[0].allotment_date) : (this.resObj['allotment_date'] = "")
      this.mode_of_sale = this.paymentOption[0].mode_of_sale
      // console.log(this.arrArr)
      this.setPropData = resp['data']
      this.spinner.hide();
      // this.snackBar.open("Added Successfully", 'Success', {
      //   duration: 5000,
      // });
    } else {

      this.spinner.hide();
      this.snackBar.open(" Some Error occured!", 'Error', {
        duration: 5000,
      });
    }
  }
  // resObjList=[this.resObj]
  displayTableFlag = false;
  displayTableData: any;
  displayTable() {

    if (!this.resObj['property_type']) {
      Swal.fire('Property type not selected', 'not filled', 'error')
      return;
    }
    else if (!this.resObj['id']) {
      Swal.fire('Applicant not selected', 'not filled', 'error')
      return;
    }
    else if (!this.resObj['alloted_property_id']) {
      Swal.fire('Property Number  not selected', 'not filled', 'error')
      return;
    }
    else if (!this.resObj['mode_of_payment']) {
      Swal.fire('Payment not selected', 'not filled', 'error')
      return;
    }
    else if (!this.resObj['payment_option_id']) {
      Swal.fire('Payment Option not selected', 'not filled', 'error')
      return;
    }
    else if (!this.resObj['first_lumsum']) {
      Swal.fire('Please Fill', 'at least first lumpsum', 'error')
      return;
    }
    else {
      if (this.resObj['first_lumsum']) {
        // if first amount is given
        if (!this.resObj['first_lumsum_day']) {
          //  if fist amount given but duration not
          Swal.fire('First lumpsum day', 'not filled', 'error')
          return;
        }
      } else {
        // if first ammount is not given
        if (this.resObj['first_lumsum_day']) {
          // amount is  not given but duration is given
          Swal.fire('First lumpsum amount', 'not filled', 'error')
          return;
        }
      }
      // -----
      if (this.resObj['second_lumsum']) {
        // second lumsum amoount is given
        if ((!this.resObj['first_lumsum']) && !this.resObj['first_lumsum_day']) {
          // second lumsum amount is given but first lumsum amount is not given
          Swal.fire('First lumpsum detail', 'not filled', 'error')
          return;
        }
        if (!this.resObj['second_lumsum_day']) {
          //  first lumsum detail and secod lumsum amount is given but not second lumsum amount
          Swal.fire('second lumpsum day', 'not filled', 'error')
          return;
        }
      } else {
        if ((!this.resObj['first_lumsum']) && this.resObj['first_lumsum_day']) {
          // second lumsum amount is given but first lumsum amount is not given
          Swal.fire('First lumpsum detail', 'not filled', 'error')
          return;
        }
        if (this.resObj['second_lumsum_day']) {
          Swal.fire('second lumpsum amount', 'not filled', 'error')
          return;
        }
      }
    }
    this.displayTableData = this.resObj;
    this.displayTableFlag = true;
    console.log(this.displayTableData, 'uuuuuuu')
  }
  propertyArr2: any = []
  async setProperty() {
    this.propertyArr2 = []
    this.allotmentObj['property_id'] = ''
    // samarjeet
    let dum: any = {}
    console.log(this.arrArr, this.resObj['id'])
    this.arrArr.filter((ele: any) => ele.id == this.resObj['id']).forEach((ele: any) => dum = Object.assign(dum, ele))
    if (dum['property_id'] == null) {
      this.propertyArr2 = this.setPropData[0]
    } else {
      for (let i = 0; i < this.setPropData[0].length; i++) {
        if (dum['property_id'] == this.setPropData[0][i]['property_id']) {
          this.propertyArr2.push(this.setPropData[0][i])
          this.allotmentObj['property_id'] = this.propertyArr2[0]['property_id']
          break;
        }
      }
    }

    this.seletedPaymentMethod = this.arrArr.filter((ele: any) => ele['id'] == this.resObj['id'])

    if (this.mode_of_sale == "LOTTERY") {
      this.selectedPropertyNumber = this.propertyArr2.filter((ele: any) => ele['pro_type'] == this.resObj['property_type'])
    } else if (this.mode_of_sale == "FCFS") {
      this.selectedPropertyNumber = this.propertyArr2.filter((ele: any) => ele['pro_type'] == this.resObj['property_type'])
        .filter((e: any) => e['property_id'] == this.seletedPaymentMethod[0]['property_applied_for'])
      // console.log(this.selectedPropertyNumber,this.seletedPaymentMethod)
      this.resObj['alloted_property_id'] = this.selectedPropertyNumber[0].property_id
    }
    this.resObj['mode_of_payment'] = this.seletedPaymentMethod[0].payment_option
    this.allPaymetOption()
  }

  async cancel(element: any, i: any) {
    $('#myModal1').modal('show');
    if (this.Obj['reason'] != '') {
      this.allotmentObj = element;
      var obj = Object.assign({}, this.allotmentObj);
      obj['msg'] = "Your Application number " + element.party_id + " having  some inforamtion issue .That's why your application is being  REJECTED . please go through the below given link and reasion is : " + this.Obj['reason']

      obj['b_acct_id'] = this.b_acct_id
      console.log(obj)
      var resp: any = await this.service.cancelAllotment(obj);
      console.log(resp)
      this.spinner.show();

      if (resp['error'] == false) {
        $('.nav-tabs a[href="#tab-1"]').tab('show')
        await this.getAllAllotment()
        this.allotmentObj = {}
        this.obj = {}
        this.spinner.hide();
        this.snackBar.open("Cancelled Successfully", 'Success', {
          duration: 5000,
        });

        // obj['msg']="Your Application number "+ element.party_id +" having  some inforamtion issue .That's why your application is being  REJECTED . please go through the below given link and reasion is : "+this.Obj['reason']
        obj['url'] = this.mainService.uiUrl + "?acct=" + this.b_acct_id
        obj['subject'] = 'Application Status'
        obj['reciever_mail'] = element.party_email
        obj['account'] = this.erpUser['account_name']

        var resp2 = await this.metadataService.SendEmail(obj)
        if (resp2['error'] != false) {
          this.snackBar.open("Error while Sending mail", 'Error', {
            duration: 2000,
          });
        }

      } else {
        this.spinner.hide();
        this.snackBar.open("Error occured while Updating Allotment", 'Error', {
          duration: 5000,
        });
      }
    }
    else {
      Swal.fire('Information', 'Please Give The Reason', 'info')
      this.globleElement['element'] = element
      this.globleElement['i'] = i

    }
  }

  i : any
  async approve(element: any, i: any) {

    var obj: any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['update_user_id'] = this.user_id
    obj['arr_status_code'] = 'ALLOTED'
    obj['id'] = element.id

    var resp: any = await this.service.approveAllotment(obj);
    this.spinner.show();
    if (resp['error'] == false) {
      this.getAllAllotment()

      this.spinner.hide();
      Swal.fire('Success...', 'Approved Successfully', 'success')
      // this.snackBar.open("Approved Successfully", 'Success', {
      //   duration: 5000,
      // });

      obj['msg'] = "Congratulations!! You have been alloted with the propery " + element['assigned_property_number'] + " in offer " + element['offer_name'] + ". Who's total area is " + element['area'] + " " + element['unit'] + " and the total amount of property is " + element['application_amount'] + " INR. Now you are requested to fullfill all the formalities related to the property .You will get all the updates related to this over a time."
      obj['url'] = this.mainService.uiUrl + "?acct=" + this.b_acct_id
      obj['subject'] = 'Allotment Status'
      obj['reciever_mail'] = element.party_email
      obj['account'] = this.erpUser['account_name']
      var resp2 = await this.metadataService.SendEmail(obj)
      if (resp2['error'] != false) {
        this.snackBar.open("Error while Sending mail", 'Error', {
          duration: 2000,
        });
      }
    } else {

      this.spinner.hide();
      this.snackBar.open("Error occured while Updating Allotment", 'Error', {
        duration: 5000,
      });
    }
  }
  export1() {
    var exp = []
    for (var i = 0; i < this.allBookletPurchase.length; i++) {
      var obj: any = new Object();
      obj['SNO'] = i + 1;
      obj['Applicant ID'] = this.allBookletPurchase[i]['party_id']
      obj['Effective Date'] = this.mainService.dateFormatChange(this.allBookletPurchase[i]['arr_effective_date'])
      obj['Subsidised/Non-Subsidised'] = this.allBookletPurchase[i]['subsidised_or_non_subsidised']
      obj['Property Type'] = this.allBookletPurchase[i]['property_type']
      // obj['Length'] = this.allBookletPurchase[i]['length']
      // obj['Width'] = this.allBookletPurchase[i]['width']
      obj['measurement unit'] = this.allBookletPurchase[i]['unit']
      obj['Property Number'] = this.allBookletPurchase[i]['alloted_property_id']
      obj['Quota'] = this.allBookletPurchase[i]['quota_cd']
      obj['Sub Quota'] = this.allBookletPurchase[i]['sub_quota_cd']
      obj['Residential/Commercial'] = this.allBookletPurchase[i]['residential_or_commercial']
      obj['Offer Name'] = this.allBookletPurchase[i]['offer_name']
      obj['Offer Code'] = this.allBookletPurchase[i]['offer_cd']
      // obj['Final Amount'] = this.allBookletPurchase[i]['final_amount']
      obj['Status'] = this.allBookletPurchase[i]['arr_status_code']
      exp.push(obj);
    }
    this.excl.exportAsExcelFile(exp, 'allotment')
  }

  async print1() {
    this.spinner.show()
    let data = []
    // var obj: any = new Object();
    // obj['b_acct_id'] = this.b_acct_id;
    // var resp: any = await this.partyService.getPartydetail(JSON.stringify(obj));
    for (let i = 0; i < this.allBookletPurchase.length; i++) {
      let obj = this.allBookletPurchase[i]

      if (this.allBookletPurchase[i]['subsidised_or_non_subsidised']) {
        obj['subsidised_or_non_subsidised'] = this.allBookletPurchase[i]['subsidised_or_non_subsidised']
      } else {
        obj['subsidised_or_non_subsidised'] = ''
      }
      if (this.allBookletPurchase[i]['property_type']) {
        obj['property_type'] = this.allBookletPurchase[i]['property_type']
      } else {
        obj['property_type'] = ''
      }
      if (this.allBookletPurchase[i]['unit']) {
        obj['unit'] = this.allBookletPurchase[i]['unit']
      } else {
        obj['unit'] = ''
      }
      if (this.allBookletPurchase[i]['alloted_property_id']) {
        obj['alloted_property_id'] = this.allBookletPurchase[i]['alloted_property_id']
      } else {
        obj['alloted_property_id'] = ''
      }
      if (this.allBookletPurchase[i]['quota_cd']) {
        obj['quota_cd'] = this.allBookletPurchase[i]['quota_cd']
      } else {
        obj['quota_cd'] = ''
      }
      if (this.allBookletPurchase[i]['residential_or_commercial']) {
        obj['residential_or_commercial'] = this.allBookletPurchase[i]['residential_or_commercial']
      } else {
        obj['residential_or_commercial'] = ''
      }
      // if (this.allBookletPurchase[i]['scheme_code']) {
      //   obj['scheme_code'] = this.allBookletPurchase[i]['scheme_code']
      // } else {
      //   obj['scheme_code'] = ''
      // }
      // if (this.allBookletPurchase[i]['sub_scheme_code']) {
      //   obj['sub_scheme_code'] = this.allBookletPurchase[i]['sub_scheme_code']
      // } else {
      //   obj['sub_scheme_code'] = ''
      // }
      if (this.allBookletPurchase[i]['area']) {
        obj['area'] = this.allBookletPurchase[i]['area']
      } else {
        obj['area'] = ''
      }
      if (this.allBookletPurchase[i]['arr_status_code']) {
        obj['arr_status_code'] = this.allBookletPurchase[i]['arr_status_code']
      } else {
        obj['arr_status_code'] = ''
      }
      data.push(obj)
    }
    let sch = '';
    let sub_sch = ''
    var txt = this.erpUser['account_name'] + '(' + this.erpUser['account_short_name'] + ')'
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },

      pageOrientation: 'landscape',

      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    var header0 = {
      columns: [
        {
          width: '*',
          text: 'Allotments',
          bold: true,
          alignment: 'center'
        }

      ],
    }
    var header9 = {
      columns: [
        {
          width: '*',
          text: '* Note : This is a computer generated document.',
          bold: true,
          alignment: 'left'
        }

      ],
    }


    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    // dd.content.push(header1);
    // dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    var tbl = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
        body: [
          ['Application ID', 'Applicant Name', 'Applicant Mobile', 'Property Number', 'Property Type', 'Area', 'Unit', 'Subsidised/Non-Subsidised', 'Residential/Commercial', 'Status']
        ],
      }
    };
    console.log(data, 'data')
    dd.content.push(tbl);
    for (var i = 0; i < data.length; i++) {
      var arr = []
      arr.push(data[i]['party_id']);
      arr.push(data[i]['party_name']);
      arr.push(data[i]['party_phone_no']);
      arr.push(data[i]['alloted_property_id']);
      arr.push(data[i]['property_type']);
      arr.push(data[i]['area']);
      arr.push(data[i]['unit']);

      // arr.push(data[i]['length']);
      // arr.push(data[i]['width']);
      arr.push(data[i]['subsidised_or_non_subsidised']);
      arr.push(data[i]['residential_or_commercial']);
      arr.push(data[i]['arr_status_code']);
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push(header9);
    this.spinner.hide()
    pdfMake.createPdf(dd).download("allotment");
  }

  // print button for allotment

  show_table: boolean = false;
  scheme_Data: any = {}

  data: any;
  data1 : any= []
  filter: any
  ob: any = {}
  code_to_value : any= {}
  term: any
  feature_list: any = []
  feature_obj: any = {}

  async getAllEMI(item: any) {
    ////console.log(item, 'item')
    this.spinner.show()
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['party_id'] = item['party_id']
    obj['offer_cd'] = item['offer_cd']

    var resp: any = await this.service.getAllInstallment(JSON.stringify(obj));
    // console.log(resp,'emi data')
    if (resp['data'].length > 0) {
      this.spinner.hide()
      //////console.log(resp, 'allotment info')
      return resp
    } else {
      this.spinner.hide()
      Swal.fire('No Record Found', '', 'info')
    }

  }

  async allApp() {
    this.spinner.show()
    this.adata = []
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['offer_cd'] = this.scheme_Data['offer_cd']
    // obj['sub_scheme_code'] = this.scheme_Data['sub_scheme_code']
    //////console.log(obj)
    var resp: any = await this.service.getallallotment(JSON.stringify(obj));
    //////console.log(resp)
    if (resp['data'].length > 0) {
      this.spinner.hide()
      this.show_table = true
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['party_id'] == this.ob['app_id']) {
          this.adata.push(resp['data'][i])
        }
      }
    } else {
      this.spinner.hide()
      Swal.fire('No Record Found', '', 'info')
    }
    this.data = [];
  }
  async getAllotmentInfo(item: any) {
    ////console.log(item, 'item')
    this.spinner.show()
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['party_id'] = item['party_id']
    obj['property_id'] = item['alloted_property_id']
    // obj['sub_scheme_code'] = this.scheme_Data['sub_scheme_code']
    ////console.log(obj)
    var resp: any = await this.service.getallotmentInfo(JSON.stringify(obj));
    //////console.log(resp)
    if (resp['data'].length > 0) {
      this.spinner.hide()
      //////console.log(resp, 'allotment info')
      return resp
    } else {
      this.spinner.hide()
      Swal.fire('No Record Found', '', 'info')
    }

  }
  async property_feature(item: any) {
    this.spinner.show()
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['property_group_id'] = item['property_group_id']
    obj['property_id'] = item['assigned_property_number']
    // obj['sub_scheme_code'] = this.scheme_Data['sub_scheme_code']
    var resp: any = await this.service.propertyFeature(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      return resp
    } else {
      this.spinner.hide()
      Swal.fire('info..', 'Something Went Wrong..! Please try after some time..! Please try after some time', 'info')
    }

  }
  async getCostingByOffer(offer_cd: any) {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['offer_cd'] = offer_cd
    //////console.log(obj)
    var resp: any = await this.service.offer_costing(JSON.stringify(obj));
    //console.log(resp, 'costing arr')
    if (resp['error'] == false) {
      let data = resp['data']
      //////console.log(data, 'costing data')
      // for (let i = 0; i < data.length; i++) {
      // }
      this.spinner.hide()
      return resp
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  adata: any = []


  key: string = 'id'; //set default
  reverse: boolean = false;
  sort1(key: any) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  p: number = 1;
  getBase64FromUrl = async (url: any) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }
  sortArrayOfObjects = (arr: any, key: any) => {
    return arr.sort((a: any, b: any) => {
      return b[key] - a[key];
    });
  };


  print_obj = {
    note: [{ id: 'A special discount of 2% will be admissible on the payment of the total value of the plot within 30 days from the date of issue of allotment letter. Total of the plot from the date of issue of allotment letter No interest will be payable on payment of the value within 45 days.' }
      , { id: 'According to the rules, the price of the plot and all other bills will have to be paid by the applicant in the council account before registration / saledeed. Stamp duty payable at the rates fixed by the government And physical possession will be handed over after registration and payment of registration fee.' }
      , { id: 'If there is a change in the said price due to the order of the court or any other reason, then the payment will have to be made accordingly.' }
      , { id: 'A special discount of 2% will be admissible on the payment of the total value of the plot within 30 days from the date of issue of allotment letter. Total of the plot from the date of issue of allotment letter No interest will be payable on payment of the value within 45 days.' }
      , { id: 'According to the rules, the price of the plot and all other bills will have to be paid by the applicant in the council account before registration / saledeed. Stamp duty payable at the rates fixed by the government And physical possession will be handed over after registration and payment of registration fee.' }
      , { id: 'If there is a change in the said price due to the order of the court or any other reason, then the payment will have to be made accordingly.' }
      , { id: 'A special discount of 2% will be admissible on the payment of the total value of the plot within 30 days from the date of issue of allotment letter. Total of the plot from the date of issue of allotment letter No interest will be payable on payment of the value within 45 days.' }
      , { id: 'According to the rules, the price of the plot and all other bills will have to be paid by the applicant in the council account before registration / saledeed. Stamp duty payable at the rates fixed by the government And physical possession will be handed over after registration and payment of registration fee.' }
      , { id: 'If there is a change in the said price due to the order of the court or any other reason, then the payment will have to be made accordingly.' }
    ],
    footer: [{ id: 'Uttar Pradesh Housing and Development has no objection in mortgaging the plot in favor of any loan giving institution .' }]
  }
  allEmi: any;
  async allotmentPrint(item: any) {
    let alt_dt = ''
    let alt_info = await this.getAllotmentInfo(item)
    let costing_data = await this.getCostingByOffer(item['offer_cd'])
    let all_emi_info = await this.getAllEMI(item)
    this.allEmi = all_emi_info['data']
    console.log(this.allEmi, 'emi_data')
    // this.spinner.show()
    // console.log(alt_info,"allotment_info")
    // console.log(costing_data,"costing_info")
    let basic_info: any = {}
    let property_feature: any = {}
    if (alt_info['basic_info'].length > 0) {
      basic_info = alt_info['basic_info'][0]
      property_feature = await this.property_feature(basic_info)
    } else {
      basic_info = {}
      Swal.fire('Information...', 'Something Went Wrong..! Please try after some time...', 'info')
      return true
    }
    ////console.log(property_feature, 'property_feature')
    let data_obj: any = {}
    if (alt_info['data'].length > 0) {
      data_obj = alt_info['data'][0]
    } else {
      data_obj = {}
      Swal.fire('Information...', 'Something Went Wrong..! Please try after some time...', 'info')
      return true
    }
    let hierObj: any = {}
    if (alt_info['hier'].length > 0) {
      hierObj = alt_info['hier'][0]
    } else {
      hierObj = {}
      Swal.fire('Information...', 'Something Went Wrong..! Please try after some time...', 'info')
      return true
    }
    //console.log(item, 'item', data_obj, basic_info)

    ////console.log(hierObj, data_obj, basic_info, 'info')
    if (data_obj['allotment_date']) {
      alt_dt = this.mainService.dateFormatChange(data_obj['allotment_date'])
    }
    var dd: any = {
      pageSize: 'A4',
      header: {
        margin: 8,
        columns: [{
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['30%', '40%', '30%'],

            body: [
              [
                {
                  image: await this.getBase64FromUrl('../../assets/avas.png'),
                  width: 100,
                  height: 60,
                  alignment: 'center'
                },

                {
                  text: 'UP Housing and Development Council',
                  alignment: 'center',
                  bold: true,
                  margin: 6,
                  fontSize: 18
                },
                {
                  image: await this.getBase64FromUrl('../../assets/avas2.png'),
                  width: 100,
                  height: 60,
                  alignment: 'center'
                },

              ],

            ]
          },
          layout: 'noBorders',
          height: '210'
        },
        ],

      },



      pageOrientation: 'portrait',
      pageMargins: [40, 80, 40, 60],
      content: [
      ]
    };
    var header1 = {
      columns: [
        {
          width: '33%',
          text: 'Letter Number - 1',
          bold: false,
          alignment: 'center',
          fontSize: 9
        },
        {
          width: '33%',
          text: "Land Number - " + data_obj['alloted_property_id'],
          bold: false,
          alignment: 'center',
          fontSize: 9

        },
        {
          width: '33%',
          text: "Date : " + alt_dt,
          bold: false,
          alignment: 'center',
          fontSize: 9


        },
      ],
    }
    var header2 = {
      columns: [
        {
          width: '33%',
          text: '',
          bold: false,
          alignment: 'center',
          fontSize: 9
        },
        {
          width: '33%',
          text: 'Application Letter',
          bold: false,
          alignment: 'center',
          fontSize: 12

        },
        {
          width: '33%',
          text: "",
          bold: false,
          alignment: 'center',
          fontSize: 9


        },
      ],
    }
    var header3 = {
      columns: [
        {
          width: '33%',
          text: 'To',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: ' ',
          bold: false,
          alignment: 'left',
          fontSize: 9

        },
        {
          width: '33%',
          text: "",
          bold: false,
          alignment: 'center',
          fontSize: 9


        },
      ],
    }
    var header4 = {
      columns: [
        {
          width: '33%',
          text: 'Mr./Mrs ',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: data_obj['party_name'],
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: "",
          bold: false,
          alignment: 'center',
          fontSize: 9


        },
      ],
    }
    var header5 = {
      columns: [
        {
          width: '33%',
          text: 'Husband/Son/Daughter -',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: data_obj['party_father_or_husband_name'],
          bold: false,
          alignment: 'left',
          fontSize: 9

        },
        {
          width: '33%',
          text: "",
          bold: false,
          alignment: 'center',
          fontSize: 9


        },
      ],
    }
    var header6 = {
      columns: [
        {
          width: '33%',
          text: 'Address -',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: data_obj['party_permanent_addr_line'],
          bold: false,
          alignment: 'left',
          fontSize: 9

        },
        {
          width: '33%',
          text: "",
          bold: false,
          alignment: 'center',
          fontSize: 9


        },
      ],
    }
    var header7 = {
      columns: [
        {
          width: '33%',
          text: 'Sir / Ma`am',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: ' ',
          bold: false,
          alignment: 'center',
          fontSize: 12

        },
        {
          width: '33%',
          text: "",
          bold: false,
          alignment: 'center',
          fontSize: 9


        },
      ],
    }
    let start_dt = '';
    let end_dt = ''

    if (alt_info['data'][0]['application_start_dt']) {
      start_dt = this.mainService.dateFormatChange(alt_info['data'][0]['application_start_dt'])
    }
    if (alt_info['data'][0]['application_end_dt']) {
      end_dt = this.mainService.dateFormatChange(alt_info['data'][0]['application_end_dt'])
    }

    var header8 = {
      columns: [
        {
          width: '100%',
          text: ' \u200B\t \u200B\t \u200B\t \u200B\t \u200B\t Scheme of UP Housing and Development Council, of ' + hierObj['leaf_value'] + ' from ' + start_dt + ' to ' + end_dt + ' Online registration was opened. As a result of selection from the public lottery draw completed among the registered applicants on ' + alt_dt + ', you will get plot number-' + basic_info['assigned_property_number'] + ' has been allotted. The details of the amount payable against the allotted property are as under:-',
          bold: false,
          alignment: 'left',
          fontSize: 9,
          style: 'para'
        },
      ],
    }
    var header9 = {
      columns: [
        {
          width: '33%',
          text: 'City',
          bold: true,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: hierObj['leaf_value'],
          bold: true,
          alignment: 'left',
          fontSize: 9

        },
        {
          width: '33%',
          text: "Form Number -",
          bold: true,
          alignment: 'left',
          fontSize: 9


        },
      ],
    }
    var header10 = {
      columns: [
        {
          width: '33%',
          text: 'Plan Name - ',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: data_obj['offer_desc'],
          bold: false,
          alignment: 'left',
          fontSize: 9

        },
        {
          width: '33%',
          text: 'Property Category -' + basic_info['property_type'],
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
      ],
    }
    var header11 = {
      columns: [
        {
          width: '33%',
          text: 'Plot Number - ',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: basic_info['assigned_property_number'],
          bold: false,
          alignment: 'left',
          fontSize: 9

        },

      ],
    }
    var header12 = {
      columns: [
        {
          width: '33%',
          text: 'Application Amount-',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: data_obj['application_amount'] + ' INR',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },

      ],
    }

    var header13 = {
      columns: [
        {
          width: '33%',
          text: 'Area of the plot -',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: basic_info['area'] + '  ' + basic_info['unit'],
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
      ],
    }
    var header13_2 = {
      columns: [
        {
          width: '33%',
          text: 'Land Basic Cost -',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
        {
          width: '33%',
          text: basic_info['pro_rate'] + ' INR',
          bold: false,
          alignment: 'left',
          fontSize: 9
        },
      ],
    }
    var header14 = {
      columns: [
        {
          width: '100%',
          text: 'Total Rate Of Property :',
          bold: true,
          alignment: 'left',
          fontSize: 9,
          style: 'para'
        },

      ],
    }
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header2);
    dd.content.push({ text: " " });
    dd.content.push(header3);
    dd.content.push(header4);
    dd.content.push(header5);
    dd.content.push(header6);
    dd.content.push({ text: " " });
    dd.content.push(header7);
    dd.content.push(header8);
    dd.content.push({ text: " " });
    dd.content.push(header9);
    dd.content.push(header10);
    dd.content.push(header11);
    dd.content.push(header12);
    dd.content.push(header13);
    dd.content.push(header13_2);
    dd.content.push({ text: " " });
    dd.content.push(header14);
    dd.content.push({ text: " " });
    let cost_obj: any = {}
    let dummy_arr: any = []
    if (property_feature['data'].length > 0) {
      for (let i = 0; i < property_feature['data'].length; i++) {
        if (property_feature['data'][i]['feature_type'] == 'Total Area') {
          let obj : any= {}
          obj['id'] = property_feature['data'][i]['feature_ident']
          obj['value'] = property_feature['data'][i]['feature_value']
          dummy_arr.push(obj)
        }
        if (property_feature['data'][i]['feature_type'] != 'Total Area') {
          let obj : any= {}
          obj['id'] = property_feature['data'][i]['feature_ident']
          obj['value'] = property_feature['data'][i]['feature_value']
          dummy_arr.push(obj)
        }
      }
    }

    let data: any = costing_data['data']
    cost_obj['BASIC'] = basic_info['pro_rate']
    let basicObj : any= {}
    basicObj['id'] = 'Basic Cost'
    basicObj['value'] = cost_obj['BASIC']
    let sum = cost_obj['BASIC']
    dummy_arr.push(basicObj)
    //for premium costing
    for (let i = 0; i < data.length; i++) {
      if (data[i]['costing_type'] == 'PERMIUM') {
        let object = Object.assign({}, this.feature_obj[data[i]['feature_type']])
        let ob: any = {}
        if (data[i]['costing_method'] == 'Fixed') {
          ob['id'] = object['feature_desc']
          ob['value'] = data[i]['count']
          ob['feature_code'] = data[i]['feature_code']
          ob['feature_type'] = data[i]['feature_type']
          cost_obj[data[i]['feature_type']] = ob['value']
        }
        if (data[i]['costing_method'] == 'Variable %') {
          ob['id'] = object['feature_desc']
          ob['value'] = (cost_obj[data[i]['feature_code']] * data[i]['count']) / 100
          cost_obj[data[i]['feature_type']] = ob['value']
          ob['feature_code'] = data[i]['feature_code']
          ob['feature_type'] = data[i]['feature_type']
        }
        if (data[i]['costing_method'] == 'Variable Rate') {
          ob['id'] = object['feature_desc']
          ob['value'] = data[i]['count'] * cost_obj[data[i]['feature_code']]
          cost_obj[data[i]['feature_type']] = ob['value']
          ob['feature_code'] = data[i]['feature_code']
          ob['feature_type'] = data[i]['feature_type']
        }
        ////console.log(cost_obj[data[i]['feature_type']], cost_obj)
        if (ob['id']) {
          ob['id'] = ob['id']
        } else {
          ob['id'] = ''
        }
        if (ob['value']) {
          ob['value'] = ob['value']
        } else {
          ob['value'] = cost_obj[object['feature_type']]
        }
        sum = sum + Number(ob['value'])
        dummy_arr.push(ob)
      }

    }
    //additional heading
    let addobj: any = {}
    addobj['id'] = 'Additional Rate'
    addobj['value'] = ''
    dummy_arr.push(addobj)
    for (let i = 0; i < data.length; i++) {
      if (data[i]['costing_type'] != 'PERMIUM') {
        let object = Object.assign({}, this.feature_obj[data[i]['feature_type']])
        let ob: any = {}
        if (data[i]['costing_method'] == 'Fixed') {
          ob['id'] = object['feature_desc']
          ob['value'] = data[i]['count']
          ob['feature_code'] = data[i]['feature_code']
          ob['feature_type'] = data[i]['feature_type']
          cost_obj[data[i]['feature_type']] = ob['value']
        }
        if (data[i]['costing_method'] == 'Variable %') {
          ob['id'] = object['feature_desc']
          ob['value'] = (cost_obj[data[i]['feature_code']] * data[i]['count']) / 100
          cost_obj[data[i]['feature_type']] = ob['value']
          ob['feature_code'] = data[i]['feature_code']
          ob['feature_type'] = data[i]['feature_type']
        }
        if (data[i]['costing_method'] == 'Variable Rate') {
          ob['id'] = object['feature_desc']
          ob['value'] = data[i]['count'] * cost_obj[data[i]['feature_code']]
          cost_obj[data[i]['feature_type']] = ob['value']
          ob['feature_code'] = data[i]['feature_code']
          ob['feature_type'] = data[i]['feature_type']
        }
        ////console.log(cost_obj[data[i]['feature_type']], cost_obj)
        if (ob['id']) {
          ob['id'] = ob['id']
        } else {
          ob['id'] = ''
        }
        if (ob['value']) {
          ob['value'] = ob['value']
        } else {
          ob['value'] = cost_obj[object['feature_type']]
        }
        sum = sum + Number(ob['value'])
        dummy_arr.push(ob)

      }

    }
    let sum_obj: any = {}
    sum_obj['id'] = 'Total Amount'
    sum_obj['value'] = sum
    ////console.log(sum, dummy_arr)
    dummy_arr.push(sum_obj)
    let dummy2 = []

    for (var i = 0; i < dummy_arr.length; i++) {
      var arr = []
      if (dummy_arr[i]['id'] == "Basic Cost") {
        dummy2.push([{ text: dummy_arr[i]['id'], bold: true }, { text: dummy_arr[i]['value'], bold: true }]);
      } else if (dummy_arr[i]['id'] == 'Total Amount') {
        dummy2.push([{ text: dummy_arr[i]['id'], bold: true }, { text: dummy_arr[i]['value'], bold: true }]);
      } else if (dummy_arr[i]['id'] == "Additional Rate") {
        dummy2.push([{ text: dummy_arr[i]['id'], bold: true, colSpan: 2 }]);
      }
      else {
        dummy2.push([{ text: dummy_arr[i]['id'] }, { text: dummy_arr[i]['value'] }]);
      }
    }
    var tbl1 = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*', '*'],
        body: dummy2,
        margin: 4
      }
    };
    dd.content.push(tbl1);
    dd.content.push({ text: " " });

    var tbl5 = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      alignment: 'center',
      table: {

        headerRows: 1,
        widths: ['*', '*', '*'],
        body: [
          ['Emi Start Date', 'Emi End Date', 'EMI Amount']
        ],
        margin: 4
      }
    };
    dd.content.push(tbl5);
    for (var i = 0; i < this.allEmi.length; i++) {
      var uttam = []
      uttam.push(this.allEmi[i]['emi_start_date']);
      uttam.push(this.allEmi[i]['emi_end_date']);
      uttam.push(this.allEmi[i]['emi_amount']);
      dd.content[dd.content.length - 1].table.body.push(uttam);
    }

    var header14 = {
      columns: [
        {
          width: '100%',
          text: 'Note :-',
          bold: true,
          alignment: 'left',
          fontSize: 9,
          style: 'para'
        },

      ],
    }
    var tbl = {
      layout: 'noBorders',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*'],
        body: [
          ['']
        ],
        margin: 4
      }
    };
    dd.content.push(tbl);
    for (var i = 0; i < this.print_obj['note'].length; i++) {
      var arr = []
      arr.push('(' + (i + 1) + ')\u200B\t' + this.print_obj['note'][i]['id']);

      dd.content[dd.content.length - 1].table.body.push(arr);
    }

    dd.content.push({ text: " " });

    let dummy = []
    for (var i = 0; i < this.print_obj['footer'].length; i++) {
      var arr = []

      dummy.push([{ text: this.print_obj['footer'][i]['id'], alignment: 'center' }]);
    }
    var tbl2 = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*'],
        body: dummy,
        margin: 4
      }
    };
    dd.content.push(tbl2);

    var header16 = {
      columns: [
        {
          border: [true, true, false, false],
          width: '100%',
          text: 'Property Manager',
          alignment: 'right',
          fontSize: 9
        },

      ],
    }
    dd.content.push({ text: " " });
    dd.content.push(header16);
    dd.content.push({ text: " " });
    this.spinner.hide()
    pdfMake.createPdf(dd).download("Applicant Application Challan ");
    return
  }

}
