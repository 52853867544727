import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proterty-administration',
  templateUrl: './proterty-administration.component.html',
  styleUrls: ['./proterty-administration.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class ProtertyAdministrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
