<div class="page-brand">
    <a class="link">
        <span class="brand">Online
            <span class="brand-tip">ERP</span>
        </span>
        <span class="brand-mini">OE</span>
    </a>
</div>
<div class="flexbox flex-1">
    <!-- START TOP-LEFT TOOLBAR-->
    <ul class="nav navbar-toolbar">
        <li>
            <a class="nav-link sidebar-toggler js-sidebar-toggler"><i class="ti-menu"></i></a>
        </li>
        <li>
            <div style="margin-left: 350px; margin-top: 5px; margin-bottom: 5px;" class="admin-block d-flex">
                <div style="border-radius: 20px 0px 0px 20px;">
                    <!-- <img [src]="acc_imgURL" class="img-circle" width="50px"
                        style="max-width: 75%;padding-left: 3px;padding-top: 2px" /> -->
                    <img src="./../../../assets/img/dash/admin.png" class="img-circle" width="50px" style="max-width: 75%;padding-left: 3px;padding-top: 2px" />
                </div>
                <div class="admin-info" style="color: white;  border-radius: 0px 10px 10px 0px;width:230px;">

                    <div class="font-strong" style="text-align: center;color: white;">{{erpUser['account_name']}}</div>
                    <div style="text-align: center;"> Online Management System </div>

                </div>
            </div>
        </li>
    </ul>
    <!-- END TOP-LEFT TOOLBAR-->
    <!-- START TOP-RIGHT TOOLBAR-->
    <ul class="nav navbar-toolbar">
        <li style="color: white; margin-right: 15px;">
            Select Language :
        </li>
        <li>
            <ng-select [multiple]="false" style="margin-right: 2%; width: 150px;" placeholder="Select Language" [items]="auth.allLanguage" bindLabel="language_desc" bindValue="language_cd" [selectableGroup]="true" [selectableGroupAsModel]="false" aria-placeholder="Select Language Code"
                [closeOnSelect]="true" name="language_cd" [(ngModel)]="auth.language_cd" (change)="auth.changeLanguage()">
            </ng-select>
        </li>
        <!-- <li class="dropdown dropdown-inbox">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown"><i class="fa fa-envelope-o"></i>
                <span class="badge badge-primary envelope-badge">0</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-right dropdown-menu-media">

            </ul>
        </li>
        <li class="dropdown dropdown-notification">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown"><i class="fa fa-bell-o rel"><span
                        class="notify-signal"></span></i></a>
            <ul class="dropdown-menu dropdown-menu-right dropdown-menu-media">
               </ul>
        </li> -->
        <li class="dropdown dropdown-user">
            <a class="nav-link dropdown-toggle link" data-toggle="dropdown">
                <img [src]="mainService.profileImageUrl" />
                <span></span>{{this.erpUser['party_name']}}<i class="fa fa-angle-down m-l-5"></i></a>
            <ul class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" [routerLink]="['/solution-admin/profile']" routerLinkActive="router-link-active"><i
                        class="fa fa-user"></i>{{auth.allLableShowObjSA[auth.language_cd+'admin_h4']}}</a>
                <li class="dropdown-divider"></li>
                <a class="dropdown-item" [routerLink]="['/login']" routerLinkActive="router-link-active"><i
                        class="fa fa-power-off"></i>
                    <!-- Logout -->{{auth.allLableShowObjSA[auth.language_cd+'admin_h5']}}
                </a>
            </ul>
        </li>
    </ul>
    <!-- END TOP-RIGHT TOOLBAR-->
</div>