import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class DataControlService {
  httpUrl
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/icms";
  }

  // async getCostCenterAndHierarchy(obj:any) {
  //   const resp = await this.http.get<any>(this.httpUrl + '/user_track/getCostCenterAndHierarchy' + obj).toPromise().then((res) => {
  //     return res;
  //   })
  //   await this.getmappedcomplain(obj);
  //   return resp;
  // }

  // async ZonemapedComplain(obj:any) {
  //   const resp = await this.http.get<any>(this.httpUrl + '/user_track/ZonemapedComplain' + obj).toPromise().then((res) => {
  //     return res;
  //   })
  //   return resp;
  // }

  async getmappedcomplain(obj:any) {
    console.log(obj)
    const resp = await this.http.get<any>(this.httpUrl + '/icmsdataAssign/getmappedcomplain' + JSON.stringify(obj)).toPromise().then((res) => {
      return res;
    })
    return resp;
  }





}
