import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AttendaceMangService } from '../attendace-mang.service';
import Swal from 'sweetalert2';
import { MainService } from '../../service/main.service';

declare var $: any;

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-late-sitting',
  templateUrl: './late-sitting.component.html',
  styleUrls: ['./late-sitting.component.css']
})
export class LateSittingComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;
  @ViewChild('paginator1', { static: false }) paginator2!: MatPaginator;
  // @ViewChild('sortCol2', { static: false }) sortCol2: MatSort;
  dataSource1: any;
  dataSource2: any;
  constructor(private attendService: AttendaceMangService, public mainService: MainService,) { }
  // displayedColumns1 = ['work_id', 'emp_name', 'purpose', 'start_date', 'end_date','mor_late','action'];
  displayedColumns1 = ['s_no', 'emp_name', 'start_date', 'mor_late', 'action'];
  displayedColumns2 = ['work_id', 'emp_name', 'purpose', 'start_date', 'end_date', 'eve_late', 'action'];
  datasource:any ;


  erpUser:any ;
  b_acct_id:any ;
  ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.getMorningLateComming()

  }

  async getMorningLateComming() {
    var obj:any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    let date :any = new Date();
    obj['month'] = date.getMonth() + 1;
    obj['year'] = date.getFullYear()

    // let resp= await this.attendService.getMorningExtra(obj);
    let resp:any  = await this.attendService.getMorningLate(obj);
    console.log(" resp --", resp)
    if (resp['error'] == false) {
      this.dataSource1 = new MatTableDataSource(resp['data']);
      this.dataSource1.paginator = this.paginator1
      this.dataSource1.sort = this.sortCol2;
      console.log("datasource1", resp);
    }
    else {
      Swal.fire('Error', 'Error In Getting List', 'error');
      return;
    }

  }

  async getEveLateSitting() {
    var obj:any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp :any = await this.attendService.getEveExtra(obj)
    console.log("datasource 2 --", resp);
    if (resp['error'] == false) {
      this.dataSource2 = new MatTableDataSource(resp['data']);
      this.dataSource2.paginator = this.paginator2
      this.dataSource2.sort = this.sortCol2;
      console.log("datasource2", resp);
    }
    else {
      Swal.fire('Error', 'Error In Getting List', 'error');
      return;
    }
  }

  async allow(element:any ) {
    console.log('this is the allow ', element)
    var obj:any  = new  Object();

    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = element.id;
    obj['count'] = 0
    let resp:any  = await this.attendService.allowLate(obj)
    console.log(resp);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Allowed', 'success');
      this.getMorningLateComming();

    }
    else {
      Swal.fire('Error', ' Not Allowed ', 'error');
      return;
    }
  }


  async downloadPDF(element:any , i:any ) {
    let obj = element;
    console.log("pdf -", element);
    var txt = '';
    if (i == 1) {
      txt = ' Morning Late Sitting '
    }
    else {
      txt = 'Evening Late Sitting'
    }
    // start


    var dd:any  = {
      pageSize: 'A4',
      header: function (currentPage:any , pageCount:any ) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40], fontSize: 10, bold: true };
        return obj;
      },

      //footer: function(currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },


      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [10, 60, 10, 60],
      //pageMargins: [ 40, 20, 20, 20 ],
      content: [

      ]
    };

    var head = {
      columns: [

        {},
        // {
        //   width: '*',
        //   align:'right',
        //   // text: 'Annual Statement of Employee '+this.mainService.codeValueShowObj['HR0023'][this.selectObj['fin_year']] ,
        //   text: 'Annual Statement of Employee ' ,
        //   bold: true
        // },

        {}

      ],
    }

    var sign1 = {
      columns: [
        {
          width: '*',
          text: 'Employee Name : ' + obj['emp_name'],
          bold: true
        },
        // {
        //   width: '*',
        //   align:'right',
        //   text: obj['emp_name'] ,
        //   bold: true
        // },




      ],

    }


    dd.content.push(sign1  );

    let list = ['Emp\nDetail'];
    //  let data_keys = [];

    let width = ['auto'];
    //   width.push('200')
    let s = [];
    let v = [];

    s = Object.keys(obj)
    v = Object.values(obj)
    var arr = []
    arr.push('->')

    if (i == 2) {
      list.push('Work Id', 'Purpose', 'Start Date', 'End Date', 'Total Late Sitting')
      arr.push(obj['work_id'], obj['purpose'], this.mainService.dateformatchange(obj['start_date']), this.mainService.dateformatchange(obj['end_date']), obj['eve_late_sitting'])
    }
    else {
      for (let i = 0; i < s.length; i++) {

        switch (s[i]) {
          case 'work_id':
            console.log('work id', v[i])

            list.push('Work Id')
            arr.push(v[i])
            break;

          case 'purpose':
            console.log('purpose ', v[i])
            list.push('Purpose');
            arr.push(v[i])
            break;
          case 'start_date':
            console.log('start date')
            list.push('Start Date')
            arr.push(this.mainService.dateformatchange(v[i]))
            break;
          case 'end_date':
            console.log('found emp 2')
            list.push('End Date')
            arr.push(this.mainService.dateformatchange(v[i]))
            break;
          case 'mor_late_sitting':
            console.log('found emp 2')
            list.push('Total Late Sitting')
            arr.push(v[i])
            break;

        }

        width.push('auto')

      }
    }
    var tbl:any  = {

      layout: 'lightHorizontalLines',
      fontSize: 8,
      table: {
        headerRows: 1,
        widths: [],
        body: [
          []
        ]
      }
    };

    for (let i = 0; i < list.length; i++) {
      tbl.table.body[0].push(list[i]);
      console.log(list[i])
      tbl.table.widths.push('*')
    }
    dd.content.push(tbl);
    // var arr = []
    // arr.push(' ')
    // for(let j=0;j<list.length;j++)
    // {
    //   if(list[j]=='work_id')
    //   {
    //    arr.push(v[j])
    //   }
    //   else if(list[j]=='purpose')
    //   {
    //     arr.push(v[j])
    //   }
    //   else if(list[j]=='start_date')
    //   {
    //     arr.push(totalamtnet)
    //   }
    //   else if(list[j]=='end_date')
    //   {
    //     arr.push(totalamtnet)
    //   }
    //   else if(list[j]=='mor_late_sitting')
    //   {
    //     arr.push(totalamtnet)
    //   }

    //  else
    //  {

    // arr.push(this.totalobj[list[j]])
    //  }
    // }
    dd.content[dd.content.length - 1].table.body.push(arr);
    if (i == 1) {
      pdfMake.createPdf(dd).download(obj['emp_name'] + '-' + 'Morning late Sitting');
    }
    else {
      pdfMake.createPdf(dd).download(obj['emp_name'] + '-' + 'Evening late Sitting');
    }

  } // end











  applyFilter1(filterValue: string) {

    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {

    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }
}
