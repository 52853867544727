import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
// import { ThrowStmt } from '@angular/compiler';
declare var $: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.css']
})
export class CreateRoleComponent implements OnInit {
  ebillUser:any;
  b_acct_id:any;
  allRoles:any = [];
  allComp:any;
  selectedComp:any = [];
  dataSource:any;
  role_name:any;
  role_id:any;
  Obj:any = {}
  access:any = [{ code: 'ZONEWISE', value: 'Specific Zone' }, { code: 'ACCESSALL', value: 'Access ALL Zones' }]
  accessobj :any= [{ ZONEWISE: 'Specific Zone' }, { ACCESSALL: 'Access ALL Zones' }]
  displayedColumns: string[] = ['role_name', 'role_cd', 'update', 'access', 'res_cd', 'delete'];
  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService) { }
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('ebillUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    // await this.getAllResources()
    // await this.getAllRolesWIthResource();
  }
  allResources = []
  rolenamebycode = {}

  submit(){

  }

  update(){

  }
  addNewRole(){

  }
  updateRole(){

  }

  checkBoxValue: any = false;
  // checkCheckBoxvalue() {
  //   let dummy = []
  //   if (this.checkBoxValue == false) {
  //     for (let i = 0; i < this.resource.length; i++) {
  //       dummy.push(this.resource[i]['resource_cd'])
  //     }
  //     this.Obj['res'] = dummy
  //   } else {
  //     this.Obj['res'] = null
  //   }
  //   console.log(this.checkBoxValue, this.Obj)
  // }

  flag = false
  open_update(element:any) {
    this.Obj = Object.assign({}, element)
    this.Obj['res_cd'] = this.Obj['res_cd'].split(",")
    this.flag = false
    if (this.Obj['role_cd'] == 'AA' || this.Obj['role_cd'] == 'ZA') {
      this.flag = true;
      this.Obj['res_cd'] = []
      for (let i = 0; i < this.allResources.length; i++) {
        this.Obj['res_cd'].push(this.allResources[i]['res_cd'])
      }
    }

    $('.nav-tabs a[href="#tab-7-4"]').tab('show');
  }



  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  clickUpdate(element:any, j:any) {
    var ids = element.interface_id.split(',');
    var newIds = [];
    for (var i = 0; i < ids.length; i++) {
      newIds.push(parseInt(ids[i]));
    }
    this.selectedComp = newIds;
    this.role_name = element.role_name;
    this.role_id = element.role_id;

  }


  async  deleteRole(element:any, i:any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
      }
    })
  }



}
