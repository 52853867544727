import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-hrms-footer]',
  templateUrl: './hrms-footer.component.html',
  styleUrls: ['./hrms-footer.component.css']
})
export class HrmsFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
