<!-- samarjeet code for editor -->
<div class="editor-container">
  <div class="options">
    <!-- Text Format -->
    <button id="bold" class="option-button format">
      <i class="fa-solid fa-bold"></i>
    </button>
    <button id="italic" class="option-button format">
      <i class="fa-solid fa-italic"></i>
    </button>
    <button id="underline" class="option-button format">
      <i class="fa-solid fa-underline"></i>
    </button>
    <button id="strikethrough" class="option-button format">
      <i class="fa-solid fa-strikethrough"></i>
    </button>
    <button id="superscript" class="option-button script">
      <i class="fa-solid fa-superscript"></i>
    </button>
    <button id="subscript" class="option-button script">
      <i class="fa-solid fa-subscript"></i>
    </button>

    <!-- List -->
    <button id="insertOrderedList" class="option-button">
      <div class="fa-solid fa-list-ol"></div>
    </button>
    <button id="insertUnorderedList" class="option-button">
      <i class="fa-solid fa-list"></i>
    </button>

    <!-- Undo/Redo -->
    <button id="undo" class="option-button">
      <i class="fa-solid fa-rotate-left"></i>
    </button>
    <button id="redo" class="option-button">
      <i class="fa-solid fa-rotate-right"></i>
    </button>

    <!-- Link -->
    <button id="createLink" class="adv-option-button">
      <i class="fa fa-link"></i>
    </button>
    <button id="unlink" class="option-button">
      <i class="fa fa-unlink"></i>
    </button>

    <!-- Alignment -->
    <button id="justifyLeft" class="option-button align">
      <i class="fa-solid fa-align-left"></i>
    </button>
    <button id="justifyCenter" class="option-button align">
      <i class="fa-solid fa-align-center"></i>
    </button>
    <button id="justifyRight" class="option-button align">
      <i class="fa-solid fa-align-right"></i>
    </button>
    <button id="justifyFull" class="option-button align">
      <i class="fa-solid fa-align-justify"></i>
    </button>
    <button id="indent" class="option-button spacing">
      <i class="fa-solid fa-indent"></i>
    </button>
    <button id="outdent" class="option-button spacing">
      <i class="fa-solid fa-outdent"></i>
    </button>

    <!-- Headings -->
    <!-- <select id="formatBlock" class="adv-option-button">
      <option value="H1">H1</option>
      <option value="H2">H2</option>
      <option value="H3">H3</option>
      <option value="H4">H4</option>
      <option value="H5">H5</option>
      <option value="H6">H6</option>
    </select> -->

    <!-- Font -->
    <!-- <select id="fontName" class="adv-option-button"></select>
    <select id="fontSize" class="adv-option-button"></select> -->

    <!-- Color -->
    <!-- <div class="input-wrapper">
      <input type="color" id="foreColor" class="adv-option-button" />
      <label for="foreColor">Font Color</label>
    </div> -->
    <!-- <div class="input-wrapper">
      <input type="color" id="backColor" class="adv-option-button" />
      <label for="backColor">Highlight Color</label>
    </div> -->
  </div>



  <div id="text-input" contenteditable="true" (blur)="getAllcontent()">
    <div id="header">

      <div style="text-align: center;display: block; margin: auto;" class="module_img">
        <img [src]="mainService.accountImageUrl" class="img-circle img-width" style="max-width: 60px;" />
        <!-- <img src="../../../assets/avas2.png" class="img-circle img-width" style="max-width: 60px;" /> -->
      </div>
      <div contenteditable="false" style="text-align: center;"><span style="font-weight: 700; font-size: 1rem;">
          {{ebillUser.account_name}}
          &nbsp;</span></div>
      <div contenteditable="false" style="text-align: center;"><span
          style="font-weight: 700; font-size: 1rem;"><br></span></div>
      <div contenteditable="false" style="text-align: center;"><span style="font-weight: 700; font-size: 1rem;">SUPPORT
          LETTER &nbsp;</span></div>
      <div contenteditable="false" style="text-align: center;"><br></div>
      <div contenteditable="false" style="text-align: center;"><br></div>
      <!-- <div><span style="font-weight: 700;">Letter No.:&nbsp; &nbsp; &nbsp;
          &nbsp;</span>&nbsp;&nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span style="font-weight: 700;">&nbsp; &nbsp; &nbsp; Date:
          {{current_date}}</span></div> -->
      <div contenteditable="false" class="row"></div>
      <div contenteditable="false" class="row"></div>


      <label>
        <span contenteditable="false" style="font-weight: 700;"> Letter No.:</span>
        <span style="padding-left:20px"> </span>
      </label>
      <label style=" float: right;">
        <span contenteditable="false"></span>
        <span contenteditable="false" style="font-weight: 700;" class="ml-5">Date: </span>
        <span contenteditable="false" style="padding-left:20px"> {{current_date}} </span>
      </label>


      <div><br></div>
      <div><br></div>
      <!-- <div><br></div> -->

      <!-- <div contenteditable="false">
        <div><span style="font-weight: 700;" contenteditable="false">To</span>,</div>
        <div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{to_data['officer_desig']}}&nbsp;</div>
        <div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{to_data['dep_name']}} - {{to_data['branch_name']}}</div>
        <div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{to_data['branch_address']}}&nbsp;</div>
        <div><br></div>
      </div> -->
      <div class="row flex-column" contenteditable="false">
        <div class="col-3" style="font-weight: bolder;">To , </div>
        <div class="col-3">{{to_data['officer_desig']}}</div>
        <div class="col-3">{{to_data['dep_name']}} - {{to_data['branch_name']}}</div>
        <div class="col-3">{{to_data['branch_address']}}</div>
      </div>

    </div>
    <div id="sub-body">
      <div>
        <br contenteditable="false">
        <span style="font-size: 1rem;"><span style="font-weight: 700;">Subject:</span>&nbsp; &nbsp;</span>
        <span style="font-size: 1rem;">{{to_data['letter_sub']}}</span>
      </div>
      <div><span style="font-size: 1rem;"><br></span>
        <!-- letter body is here  -->

      </div>

      <div><span style="font-size: 1rem;">&nbsp;
          <div><span style="font-size: 1rem;"><br></span></div>
          <div><span style="font-size: 1rem;"><br></span></div>
          <div><span style="font-size: 1rem;"><br></span></div>
          <div><br></div>
        </span>
      </div>
    </div>


    <div id="footer" contenteditable="false">
      <!-- <div><span style="font-size: 1rem;"><br></span></div>
    <div><span style="font-size: 1rem;"><br></span></div>
    <div><span style="font-size: 1rem;"><br></span></div> -->

      <div style="position: relative; right:-79%;display: block;width: 22%;">

        <div> {{to_data['requested_by']}}</div>
        <div>( {{to_data['req_role_desc']}} )</div>
        <div>{{ebillUser.account_name}} </div>

      </div>

    </div>
    <!-- <div class="bottom">
      <button class="" (click)="getAllcontent()" >get html</button>
    </div> -->
  </div>


</div>


<!-- your code end here -->