import { Component, OnInit } from '@angular/core';
import { LedgerService } from '../../../service/ledger.service';
import { MainService } from "../../../service/main.service";
import { NgxSpinnerService } from "ngx-spinner";
import * as  pdfMake from  "pdfmake/build/pdfmake";
import Swal from "sweetalert2";
import * as fs from 'file-saver';
@Component({
  selector: 'app-receipts-payment',
  templateUrl: './receipts-payment.component.html',
  styleUrls: ['./receipts-payment.component.css']
})
export class ReceiptsPaymentComponent implements OnInit {
  mainObj:any = {}
  constructor(private ledgerService: LedgerService, private spinner: NgxSpinnerService, private mainService: MainService) {

  }
  erpUser:any;
  b_acct_id:any;
  ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
  }

  paymentRepDB :any= [];
  paymentRepCR :any= [];
  tot_db:any = 0;
  tot_cr:any = 0;
  openingBank:any = 0;
  openingCash:any = 0;
  closingBank:any = 0;
  closingCash:any = 0;

  async refresh() {
    this.paymentRepDB = [];
    this.paymentRepCR = [];
    this.tot_db = 0;
    this.tot_cr = 0;
    this.openingBank = 0;
    this.openingCash = 0;
    this.closingBank = 0;
    this.closingCash = 0;
  }
  async getReceiptPaymnet() {
    await this.refresh();
    this.spinner.show()
    this.mainObj['b_acct_id'] = this.b_acct_id;

    // if (!this.mainObj['from_dt']) {
    //   Swal.fire("Info", "Please Fill From Date", 'info');
    //   this.spinner.hide();
    //   return;
    // }
    // if (!this.mainObj['to_dt']) {
    //   Swal.fire("Info", "Please Fill To Date", 'info');
    //   this.spinner.hide();
    //   return;
    // }
    if(this.mainObj['from_dt'] > this.mainObj['to_dt']){
      return;
    }
    await this.getOpeningBalance();
    await this.getClosingBalance();
    let resp = await this.ledgerService.getReceiptPaymnet(JSON.stringify(this.mainObj));
    if (resp['error'] == false) {

      console.log(resp['data'])

      // for DB
      resp['data'].map((x:any) => { // -1 * txn_amt is done by API
        if (x['db_cd_ind'] == "DB") {
          this.paymentRepDB.push()
          this.tot_db += x['txn_amt']
        }
      })

      //adding into total db opening and closing balance

      if (this.openingBank < 0) {
        this.tot_db += this.openingBank;
      }
      if (this.openingCash < 0) {
        this.tot_db += this.openingCash;
      }
      if (this.closingBank > 0) {
        this.tot_db += this.closingBank;
      }
      if (this.closingCash > 0) {
        this.tot_db += this.closingCash;
      }

      //for CR
      resp['data'].map((x:any) => {
        if (x['db_cd_ind'] == "CR") {
          this.paymentRepCR.push(x);
          this.tot_cr += x['txn_amt']
        }
      })

      //adding into total cr opening and closing balance

      if (this.openingBank > 0) {
        this.tot_cr += this.openingBank;
      }
      if (this.openingCash > 0) {
        this.tot_cr += this.openingCash;
      }
      if (this.closingBank < 0) {
        this.tot_cr += this.closingBank;
      }
      if (this.closingCash < 0) {
        this.tot_cr += this.closingCash;
      }

      // console.log("paymentRepDB : ", this.paymentRepDB)
      // console.log("paymentRepCR : ", this.paymentRepCR)
      this.spinner.hide()
    } else {
      this.spinner.hide();
      Swal.fire('ERROR', 'Error While Getting Receipts And Payment Account', 'error');
    }
  }

  async getOpeningBalance() {
    this.spinner.show()
    this.mainObj['b_acct_id'] = this.b_acct_id;
    let resp = await this.ledgerService.getReceiptPaymnetOpening(JSON.stringify(this.mainObj));
    if (resp['error'] == false) {
      console.log("OPENING : ", resp['data']);
      if (!resp['data'][0][0]['txn_amt']) {
        this.openingBank = 0 ;
      }else{
        this.openingBank = resp['data'][0][0]['txn_amt'];
      }
      if (!resp['data'][1][0]['txn_amt']) {
        this.openingCash = 0;
      }else{
        this.openingCash = resp['data'][1][0]['txn_amt'];
      }

      console.log("Opening bank ", this.openingBank)
      console.log("Opening cash ", this.openingCash)

      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("ERRRO", "Error While Geting Opening Balance ", 'error');
    }

  }
  async getClosingBalance() {

    this.spinner.show()
    this.mainObj['b_acct_id'] = this.b_acct_id;
    let resp = await this.ledgerService.getReceiptPaymnetClosing(JSON.stringify(this.mainObj));
    if (resp['error'] == false) {
      console.log("CLOSING : ", resp['data']);
      if (!resp['data'][0][0]['txn_amt']) {
        this.closingBank = 0
      }else{
        this.closingBank = resp['data'][0][0]['txn_amt'];
      }
      if (!resp['data'][1][0]['txn_amt']) {
        this.closingCash = 0
      }else{
        this.closingCash = resp['data'][1][0]['txn_amt'];
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("ERRRO", "Error While Geting Opening Balance ", 'error');
    }
  }
  async export() {
    let obj :any= new Object();
    obj['title'] = this.mainService.accInfo["account_name"] + "(" + this.mainService.accInfo["account_short_name"] + ")" +
      " RECEIPT AND PAYMENT REPORT FROM " + this.mainObj['from_dt'] + " TO " + this.mainObj['to_dt'];
    obj['closingCash'] = this.closingCash;
    obj['closingBank'] = this.closingBank;
    obj['openingCash'] = this.openingCash;
    obj['openingBank'] = this.openingBank;
    obj['paymentRepDB'] = this.paymentRepDB;
    obj['paymentRepCR'] = this.paymentRepCR;
    obj['tot_cr'] = this.tot_cr;
    obj['tot_db'] = this.tot_db;
    obj['b_acct_id'] = this.b_acct_id;

    let resp:any = await this.ledgerService.receiptPaymentExcel(obj);
    let blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'Receipt Payment.xlsx');
  }



  async print() {
    this.spinner.show();
    var txt = this.mainService.accInfo["account_name"] + "(" + this.mainService.accInfo["account_short_name"] + ")" +
      " RECEIPT AND PAYMENT REPORT FROM " + this.mainObj['from_dt'] + " TO " + this.mainObj['to_dt'] + " ";
    let dd :any= {
      pageSize: "A4",
      header: function (currentPage:any, pageCount:any) {
        let obj = {
          text: txt + "Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
        };
        return obj;
      },
      pageOrientation: "landscape",
      pageMargins: [10, 60, 40, 10],
      content: [],
    };

    let tab = {
      fontSize: 10,
      table: {
        widths: ["*", "*"],
        body: [
          [
            [
              { text: " DR ", alignment: "center", fontSize: 20 },
              {
                table: {
                  widths: ["*", "*"],

                  body: [
                    [
                      {
                        text: "Receipt ",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                      {
                        text: "Amount",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                    ],
                  ],
                },
              },
            ],

            [
              { text: " CR ", alignment: "center", fontSize: 20 },
              {
                table: {
                  widths: ["*", "*"],
                  body: [
                    [
                      {
                        text: "Payment",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                      {
                        text: " Amount ",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                    ],
                  ],
                },
              },
            ],
          ],
        ],
      },
    };
    dd.content.push(tab);


    ///////////////////////////////////////DB SIDE ////////////////////////////////////////////////////////
    let opBnk = []

    if (this.openingBank < 0) {
      opBnk.push({ text: '', colSpan: 2, margin: [5, 5, 5, 5] })
      opBnk.push({})
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(opBnk);

      opBnk = []
      opBnk.push({ text: "To Balance b/d (Opening Balance)", colSpan: 2, bold: true });
      opBnk.push({});
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(opBnk);

      opBnk = []

      opBnk.push({ text: "Bank : ", bold: true });
      opBnk.push({ text: this.openingBank, bold: true });
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(opBnk);

    }

    let opCash = []

    if (this.openingCash < 0) {
      if (this.openingBank > 0) {
        opCash.push({ text: '', colspan: 2, margin: [5, 5, 5, 5] })
        opCash.push({})
        dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(opCash);
        opCash = []

        opCash.push({ text: "To Balance b/d (Opening Balance)", colSpan: 2, bold: true });
        opCash.push({});
        dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(opCash);

      }
      opCash = []
      opCash.push({ text: "Cash : ", bold: true });
      opCash.push({ text: this.openingCash, bold: true });
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(opCash);
    }

    for (let i = 0; i < this.paymentRepDB.length; i++) {

      let paymentDB = []
      let obj = {
        columns: [
          {
            width: '*',
            text: this.paymentRepDB[i]['jrnl_desc']
          }
        ]
      }
      paymentDB.push(obj);

      let obj2 = {
        columns: [
          {
            width: '*',
            text: this.paymentRepDB[i]['txn_amt'].toFixed(2)
          }
        ]
      }

      paymentDB.push(obj2);

      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(paymentDB);
    }

    // //closing Balance
    let bankClo = []
    if (this.closingBank >= 0) {
      bankClo.push({ text: '', colSpan: 2, margin: [5, 5, 5, 5] })
      bankClo.push({})
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(bankClo);
      bankClo = []

      bankClo.push({ text: " To Balance c/d (Closing Balance)", bold: true, colspan: 2 });
      bankClo.push({})
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(bankClo);
      bankClo = []

      bankClo.push({ text: "Bank ", bold: true });
      if (this.closingBank == 0) {
        bankClo.push({ text: '0', bold: true });
      } else {
        bankClo.push({ text: this.closingBank, bold: true });
      }
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(bankClo);
    }

    let cashClo = []
    if (this.closingCash >= 0) {
      if (this.closingBank < 0) {

        cashClo.push({ text: '', colSpan: 2, margin: [5, 5, 5, 5] })
        cashClo.push({})
        dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(cashClo);
        cashClo = []


        cashClo.push({ text: " To Balance c/d (Closing Balance)", colSpan: 2, blod: true });
        cashClo.push({})
        dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(cashClo);
      }
      cashClo = []
      cashClo.push({ text: "Cash ", bold: true });
      if (this.closingCash == 0) {
        cashClo.push({ text: '0', bold: true });
      } else {
        cashClo.push({ text: this.closingCash, bold: true });
      }
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(cashClo);

    }

    /////////////////////////////////////// CR SIDE ////////////////////////////////////////////////////////

    let opBnkcr = []
    if (this.openingBank >= 0) {
      //inserting space
      opBnkcr.push({ text: '', colSpan: 2, margin: [5, 5, 5, 5] })
      opBnkcr.push({})
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(opBnkcr);
      opBnkcr = []

      opBnkcr.push({ text: "To Balance b/d (Opening Balance)", bold: true, colSpan: 2 });
      opBnkcr.push({});
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(opBnkcr);

      opBnkcr = []

      opBnkcr.push({ text: "Bank : ", bold: true });
      if (this.openingBank == 0) {
        opBnkcr.push({ text: '0', bold: true });
      } else {
        opBnkcr.push({ text: this.openingBank, bold: true });
      }
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(opBnkcr);

    }

    let opCashcr = []

    if (this.openingCash >= 0) {
      if (this.openingBank < 0) {
        //adding space
        opCashcr.push({ text: '', margin: [5, 5, 5, 5], colSpan: 2 })
        opCashcr.push({})
        dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(opCashcr);

        opCashcr = []
        opCashcr.push({ text: "To Balance b/d (Opening Balance)", bold: true, colSpan: 2 });
        opCashcr.push({});
        dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(opCashcr);

      }
      opCashcr = []
      opCashcr.push({ text: "Cash : ", bold: true });
      opCashcr.push({ text: this.openingCash, bold: true });
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(opCashcr);
    }

    // adding space
    opCashcr = []
    opCashcr.push({ text: '', colSpan: 2, margin: [5, 5, 5, 5] })
    opCashcr.push({})
    dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(opCashcr);

    for (let i = 0; i < this.paymentRepCR.length; i++) {

      let paymentCR = []
      let obj = {
        columns: [
          {
            width: '*',
            text: this.paymentRepCR[i]['jrnl_desc']
          }
        ]
      }
      paymentCR.push(obj);

      let obj2 = {
        columns: [
          {
            width: '*',
            text: this.paymentRepCR[i]['txn_amt'].toFixed(2)
          }
        ]
      }

      paymentCR.push(obj2);

      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(paymentCR);
    }

    // //closing Balance
    let bankCloCR = []
    if (this.closingBank <= 0) {

      //adding space
      bankCloCR.push({ text: '', cloSpan: 2, margin: [5, 5, 5, 5] })
      bankCloCR.push({})
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(bankCloCR);
      bankCloCR = []

      bankCloCR.push({ text: " To Balance c/d (Closing Balance)", bold: true, colspan: 2 });
      bankCloCR.push({})
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(bankCloCR);
      bankCloCR = []

      bankCloCR.push({ text: "Bank ", bold: true });
      if (this.closingBank == 0) {
        bankCloCR.push({ text: '0' });
      } else {
        bankCloCR.push({ text: this.closingBank });
      }
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(bankCloCR);
    }

    let cashCloCR = []
    if (this.closingCash <= 0) {
      if (this.closingBank > 0) {
        //adding space
        cashCloCR.push({ text: '', margin: [5, 5, 5, 5], colSpan: 2 })
        cashCloCR.push({})
        dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(cashCloCR);
        cashCloCR = []

        cashCloCR.push({ text: " To Balance c/d (Closing Balance)", bold: true, colspan: 2 });
        cashCloCR.push({})
        dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(cashCloCR);
      }
      cashCloCR = []
      cashCloCR.push({ text: " Cash ", blod: true });
      if (this.closingCash == 0) {
        cashCloCR.push({ text: '0', bold: true });
      } else {
        cashCloCR.push({ text: this.closingCash, bold: true });
      }
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(cashCloCR);

    }

    ////////////////////////////////////Total balance ////////////////////////////////////

    let obj = {
      columns: [
        {
          width: '*',
          text: "Total",
          bold: true
        },
        {
          width: '*',
          text: this.tot_db,
          bold: true
        },
      ]
    }


    let obj1 = {
      columns: [
        {
          width: '*',
          text: "Total",
          bold: true
        },
        {
          width: '*',
          text: this.tot_cr,
          bold: true
        },
      ]
    }

    let tot = []
    tot.push(obj);
    tot.push(obj1);

    dd.content[dd.content.length - 1].table.body.push(tot);

    pdfMake.createPdf(dd).download("Receipt-payment");
    this.spinner.hide();
  }

}
