<div class="sidebar">
  <div id="sidebar-collapse">
    <div class="admin-block d-flex" style="align-items: center;">
      <div>
        <img src="./../../../assets/img/dash/hrms.png" class="img-circle" width="45px" />
      </div>
      <div class="admin-info">
        <div class="font-strong" (click)="home()">{{auth.allSystemModuleObj['HR']}}</div>
      </div>
    </div>
    <ul class="side-menu metismenu">
      <li>
        <a class="text-decoration-none" (click)="refreshCol()" routerLink="/hrms/hrms-index"><i class="sidebar-item-icon fa fa-th-large"></i>
          <!-- <a class = "text-decoration-none" [hidden]="dashborad_hidden"   routerLink="/hrms/index"><i class="sidebar-item-icon fa fa-th-large"></i> -->
          <span class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar1']}}</span>
        </a>
      </li>
      <li>
        <a class="text-decoration-none" (click)="refreshCol()" routerLink="/hrms/myinfo"><i class="sidebar-item-icon fa fa-users"></i>
          <span class="nav-label">My Info</span>
        </a>
      </li>
      <li>
        <a class="text-decoration-none"  (click)="refreshCol()" routerLink="/hrms/mark-attendance"><i
            class="sidebar-item-icon  fa fa-check-square" aria-hidden="true"></i>
          <span class="nav-label">

            Mark Attendance
          </span>
        </a>
      </li>
      <!-- <li>
                <a class = "text-decoration-none"   routerLink="/hrms/mkattend"><i class="sidebar-item-icon  fa fa-check-square" aria-hidden="true"></i>
                    <span
                        class="nav-label">
                        Mark Attendance
                    </span>
                </a>
            </li> -->

      <li>
        <a class="text-decoration-none" (click)="refreshCol()" routerLink="/hrms/notice"><i class="sidebar-item-icon fa fa-bell-o rel"></i>
          <span class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar3']}}
            <!-- Notice -->
          </span>
        </a>
      </li>
      <li [hidden]="sideBarFlag['HR-R5']">
        <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#collapseReport"
          aria-expanded="false" aria-controls="collapseReport"><i class="sidebar-item-icon fa fa-bar-chart"></i>
          <!-- <a class = "text-decoration-none" [hidden]="report_hidden" href="javascript:;"><i class="sidebar-item-icon fa fa-bar-chart"></i> -->
          <span class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar4']}}
            <!-- Report -->
          </span>
          <i class="fa fa-angle-left arrow"></i>

        </a>
        <ul class="nav-2-level collapse" id="collapseReport" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/hrms-report/emp-report">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar5']}}
              <!-- Employee -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/hrms-report/payroll-report">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar6']}}
              <!-- Salary Component -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/hrms-report/bill-report">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar7']}}
              <!-- Bill -->
            </a>
          </li>
          <!--
                    <li>
                        <a class = "text-decoration-none" routerLink="/hrms/hrms-report/lpc"
                             >{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar8']}}
                    -->
          <!-- LPC -->
          <!--
                        </a>
                    </li>
                -->
          <!-- <li>
                        <a class = "text-decoration-none" routerLink="/hrms/hrms-report/lpc"
                             >{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar8']}} -->
          <!-- LPC -->
          <!-- </a>
                    </li> -->
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/hrms-report/lpctransfer">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar69']}}
              <!-- LPC-TRANSFER -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/hrms-report/pay-slip">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar10']}}
              <!-- Pay Slip -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/hrms-report/pention-contribution">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar11']}}
              <!-- Pension Cont. -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/hrms-report/deduction-report">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar12']}}
              <!-- PF/IT -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/hrms-report/hold">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar13']}}
              <!-- Salary Hold -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/hrms-report/annl">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar14']}}
              <!-- Annual Salary Statement -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/hrms-report/varannl">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar68']}}
              <!-- Annual Salary Statement -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/hrms/hrms-report/atten-report">
              Attendance Report
            </a>
          </li>
        </ul>
      </li>

      <li [hidden]="sideBarFlag['HR-R2']">
        <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#collapseEmp"
          aria-expanded="false" aria-controls="collapseEmp"><i class="sidebar-item-icon fa fa-users"></i>
          <!-- <a class = "text-decoration-none" [hidden]="party_hidden" href="javascript:;"><i class="sidebar-item-icon fa fa-users"></i> -->
          <span class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar15']}}
            <!-- Employee -->
          </span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="collapseEmp" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/hrms/party/list">LIST
              <!-- List-->
            </a>
          </li>
          <li>
            <!-- Joining -->
            <a class="text-decoration-none"
              routerLink="/hrms/party/allemp">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar16']}}
              <!-- All Employee -->
            </a>
          </li>
          <!-- Joining -->
          <!--  <li>
                        <a class = "text-decoration-none" routerLink="/hrms/party/joining"  >{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar17']}}
                           </a>
                    </li> -->
          <!-- <li>
                        <a class = "text-decoration-none" routerLink="/hrms/party/personal-info"  >Personal Info</a>
                    </li> -->
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/party/edu-info">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar18']}}
              <!-- Education Info -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/party/nom-info">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar19']}}
              <!-- Nominee Info -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/party/bank-dtl-info">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar20']}}
              <!-- Bank Detail -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/party/lic">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar21']}}
              <!-- LIC -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/party/dependent-info">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar22']}}
              <!-- Dependent Info -->
            </a>
          </li>




        </ul>
      </li>

      <li [hidden]="sideBarFlag['HR-R7']">
        <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#collapseEst"
          aria-expanded="false" aria-controls="collapseEst"><i class="sidebar-item-icon fa fa-gear"></i>
          <!-- <a class = "text-decoration-none" [hidden]="arr_hidden" href="javascript:;"><i class="sidebar-item-icon fa fa-gear"></i> -->
          <span class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar23']}}
            <!-- Establishment -->
          </span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="collapseEst" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/establish">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar24']}}
              <!-- Establish -->
            </a>
          </li>
          <!-- <li>
                        <a class = "text-decoration-none" routerLink="/hrms/arr/show-data"
                             >
                             All Data
                        </a>
                    </li> -->
          <li>
            <a class="text-decoration-none" routerLink="/hrms/payroll/fixed-pay">
              Variable Pay</a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/posting">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar26']}}
              <!-- Posting -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/promotion">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar27']}}
              <!-- Promotion -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/probation">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar28']}}
              <!-- Probation -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/complaint">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar29']}}
              <!-- Complaint -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/enquiry">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar30']}}
              <!-- Enquiry -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/suspension">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar31']}}
              <!-- Suspension -->
            </a>
          </li>

          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/termination">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar33']}}
              <!-- Termination -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/retirement">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar34']}}
              <!-- Retirement -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/death">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar35']}}
              <!-- Death -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/reappointment">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar36']}}
              <!-- Reappointment -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/resign">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar37']}}
              <!-- Resign -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/transfer">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar38']}}
              <!-- Transfer -->
            </a>
          </li>





        </ul>
      </li>

      <li [hidden]="sideBarFlag['HR-R4']">
        <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#collapseleave"
          aria-expanded="false" aria-controls="collapseleave">
          <i class="sidebar-item-icon  fa fa-commenting" aria-hidden="true"></i>
          <!-- <a class = "text-decoration-none" [hidden]="leave_hidden" href="javascript:;"><i class="sidebar-item-icon fa fa-gear"></i> -->
          <span class="nav-label">Leave Management</span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="collapseleave" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/attendance">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar25']}}
              <!-- Attendance -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/leaves">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar32']}}
              <!-- Leaves -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/arr/leaves-apply">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar66']}}
              <!-- Leave Application -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/hrms/arr/leave-list">Leave List
              <!-- Leave List -->
            </a>
          </li>

        </ul>
      </li>

      <li>
        <!--   <i class="fa-solid fa-laptop-code"></i> -->
        <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#collapseTreavel"
          aria-expanded="false" aria-controls="collapseTreavel">
          <!-- <i class="sidebar-item-icon fa fa-address-book" aria-hidden="true"> -->
          <!-- <i class="sidebar-item-icon icon-rocket"></i> -->
          <i class="sidebar-item-icon fa fa-plane" aria-hidden="true"></i>
          <span class="nav-label">Travel Reimbursement</span>
          <i class="fa fa-angle-left arrow"></i>
        </a>
        <ul class="nav-2-level collapse" id="collapseTreavel" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/hrms/travelling-reimburement/tour-detail">

              <!-- <span class="nav-label"> -->
              Tour Detail
              <!-- </span> -->
            </a>
            <a class="text-decoration-none" routerLink="/hrms/travelling-reimburement/ta-bill">

              <!-- <span class="nav-label"> -->
              TA Bill
              <!-- </span> -->
            </a>
          </li>

        </ul>
      </li>

      <li [hidden]="sideBarFlag['HR-R3']">
        <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePayroll"
        aria-expanded="false" aria-controls="collapsePayroll"><i class=" sidebar-item-icon fa fa-usd"></i>
          <!-- <a class = "text-decoration-none" [hidden]="payroll_hidden" href="javascript:;"><i class=" sidebar-item-icon fa fa-usd"></i> -->
          <span class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar39']}}
            <!-- Payroll -->
          </span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="collapsePayroll" data-bs-parent="#sidebar-collapse">
          <!--   <li>
                        <a class = "text-decoration-none" routerLink="/hrms/payroll/fixed-pay"  >{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar40']}}
                            Fix Pay</a>
                    </li> -->
          <!-- <li>
                        <a class = "text-decoration-none" href="javascript:;">
                            <span
                                class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar41']}}
                                Arear
                            </span><i class="fa fa-angle-left arrow"></i></a>
                        <ul class="nav-3-level collapse"  >
                            <li>
                                <a class = "text-decoration-none" routerLink="/hrms/payroll/variable-pay/leaveel-arrear"
                                     >{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar42']}}
                                    Encashment Arrear
                                </a>
                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/hrms/payroll/variable-pay/da-arrear"
                                     >{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar43']}}
                                    DA Arrear
                                </a>
                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/hrms/payroll/variable-pay/all-arrear"  >
                                    Arrear
                                </a>
                            </li>
                        </ul>
                    </li> -->
          <li>
            <a class="text-decoration-none" routerLink="/hrms/payroll/variable-pay/all-arrear">
              Arrear
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/payroll/sal-bill">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar44']}}
              <!-- Salary Bill -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/payroll/sal-start-stop">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar45']}}
              <!-- Salary Start/Stop -->
            </a>
          </li>
          <!-- <li>
                        <a class = "text-decoration-none" routerLink="/hrms/payroll/pay-slip"  >Pay Slip</a>
                    </li>
                    <li>
                        <a class = "text-decoration-none" routerLink="/hrms/payroll/other-bill"  >Other Bill</a>
                    </li> -->
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/payroll/ann_inc">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar46']}}
              <!-- Annual Increment -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/payroll/loan">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar47']}}
              <!-- Advances -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/payroll/el-encash">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar48']}}
              <!-- Leave Encashment -->
            </a>
          </li>
        </ul>
      </li>



      <li [hidden]="sideBarFlag['HR-R6']">
        <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#collapseAdmin"
        aria-expanded="false" aria-controls="collapseAdmin"><i class="sidebar-item-icon fa fa-database"></i>
          <span
            class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar56']}}</span><i
            class="fa fa-angle-left arrow"></i></a>
        <!-- Administration -->
        <ul class="nav-2-level collapse" id="collapseAdmin" data-bs-parent="#sidebar-collapse">
          <div id="adminParent">


            <li>
              <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#set"
              aria-expanded="false" aria-controls="set">
                <span class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar49']}}</span><i
                  class="fa fa-angle-left arrow"></i>
                </a>
              <!-- Master Data -->
              <ul class="nav-3-level collapse" id="set" data-bs-parent="#adminParent">
                <li>
                  <a class="text-decoration-none"
                    routerLink="/hrms/setting/fields">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar50']}}
                    <!-- Fields -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none"
                    routerLink="/hrms/setting/code-value">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar51']}}
                    <!-- Code Value -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none"
                    routerLink="/hrms/setting/leave-def">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar52']}}
                    <!--  Leave Rule --></a>
                </li>

                <li>
                  <a class="text-decoration-none"
                    routerLink="/hrms/setting/salary-rule">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar70']}}
                    <!--  Salary Rule --></a>
                </li>

                <li>
                  <a class="text-decoration-none"
                    routerLink="/hrms/setting/matrix">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar54']}}
                    <!-- Pay Matrix -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/setting/city-matrix">
                    City Group</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/setting/cca-matrix">
                    CCA Matrix</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/setting/hra-matrix">
                    HRA Matrix</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/setting/pbr-matrix">
                    PBR Matrix</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/setting/welfare-fund">
                    WF MATRIX</a>
                </li>
                <li>
                  <a class="text-decoration-none"
                    routerLink="/hrms/setting/salary-rules">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar55']}}
                    <!-- Pay Rule -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/setting/budget-info">
                    Budget </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/setting/product-info">
                    Product</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/setting/pay-component">
                    Activity</a>
                </li>
                <!-- <li>
                                          <a class = "text-decoration-none" routerLink="/hrms/setting/appr"  >Document Tracking</a>
                                      </li> -->
              </ul>
            </li>

            <li>
              <!-- security -->

              <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#sec"
              aria-expanded="false" aria-controls="sec">
                <span class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar67']}}</span><i
                  class="fa fa-angle-left arrow"></i></a>
              <ul class="nav-3-level collapse" id="sec" data-bs-parent="#adminParent">
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/administration/roles">
                    {{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar57']}}
                    <!-- Role -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none"
                    routerLink="/hrms/administration/users">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar58']}}
                    <!-- Users -->
                  </a>
                </li>

                <li>
                  <a class="text-decoration-none" routerLink="/hrms/administration/hierarchy">
                    {{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar59']}}
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/administration/data-assignment">
                    {{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar60']}}
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/administration/hr-workflow">
                    WorkFlow
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/hrms/arr/show-data">
                    <!-- {{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar24']}} -->
                    All Data
                  </a>
                </li>
              </ul>

            </li>

            <!-- Avinash Mishra/ Attend-management -->
            <li>
              <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#attendM"
              aria-expanded="false" aria-controls="attendM">
                <span class="nav-label">Attend-Management</span><i class="fa fa-angle-left arrow"></i></a>
              <ul class="nav-3-level collapse" id="attendM" data-bs-parent="#adminParent">
                <li>

                  <a class="text-decoration-none" routerLink="/hrms/attendanceManagement">
                    Attandance Rule
                  </a>
                  <a class="text-decoration-none" routerLink="/hrms/attendance_manag/calendar-rule">
                    Calendar Rule
                  </a>
                  <a class="text-decoration-none" routerLink="/hrms/attendance_manag/boundry-map">
                    Boundary Map
                  </a>
                </li>

              </ul>
            </li>
          </div>
        </ul>
      </li>

      <!-- Extrawork Management -->
      <li>
        <!--   <i class="fa-solid fa-laptop-code"></i> -->
        <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#collapseExtra"
        aria-expanded="false" aria-controls="collapseExtra">

          <!-- <i class="sidebar-item-icon fa fa-address-book" aria-hidden="true"> -->
          <!-- <i class="sidebar-item-icon icon-rocket"></i> -->
          <i class="sidebar-item-icon fa fa-file-word-o" aria-hidden="true"></i>
          <span class="nav-label">Extra Work</span>
          <i class="fa fa-angle-left arrow"></i>
        </a>
        <!-- <a class = "text-decoration-none" href="javascript:;"> <i class="fa fa-wordpress" ></i>
                    <span class="nav-label">Extra Work </span><i class="fa fa-angle-left arrow"></i></a> -->
        <ul class="nav-2-level collapse" id="collapseExtra" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/hrms/extrawork"><i class="fa-solid fa-laptop-code"></i>
              <span class="nav-label">Extra Work Permission
                <!-- Extra Work -->
              </span>
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/hrms/late_sitting"><i class="fa-solid fa-laptop-code"></i>
              <span class="nav-label">Late Sitting
                <!-- Extra Work -->
              </span>
            </a>
          </li>
        </ul>
      </li>

      <li>
        <!--   <i class="fa-solid fa-laptop-code"></i> -->
        <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#collapseACR"
        aria-expanded="false" aria-controls="collapseACR"><i class="sidebar-item-icon fa fa-address-book"
            aria-hidden="true"></i>
          <span class="nav-label">ACR</span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-1-level collapse" id="collapseACR" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/hrms/annual_conf_report/acr">

              <span class="nav-label">
                Annual Confidential Report
              </span>
            </a>
          </li>

        </ul>
      </li>
      <li>
        <a class="text-decoration-none" href="#" data-bs-toggle="collapse" data-bs-target="#collapseHelp"
        aria-expanded="false" aria-controls="collapseHelp"><i class="sidebar-item-icon fa fa-question-circle"></i>
          <span class="nav-label">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar61']}}
            <!-- Help -->
          </span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="collapseHelp" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/help/faq">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar62']}}
              <!-- FAQ -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/help/doc">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar63']}}
              <!-- User Manual -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/help/videos">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar64']}}
              <!-- Tutorial Videos -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none"
              routerLink="/hrms/help/online-support">{{this.mainService.allLableShowObjHR[mainService.language_cd+'hr_sidebar65']}}
              <!-- Online Support -->
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
