import { Component, OnInit, ViewChild } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';
import { SettingService } from "../../../service/setting.service";
import { MainService } from '../../../service/main.service';
import { ChartOfAccountService } from '../../../service/chart-of-account.service';
import { MasterDataService } from "../../../../emb/service/master-data.service";
import { MainService as MdMainService } from '../../../../md/service/main.service';
import { StoreKeeperService } from '../../../service/store-keeper.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort'
import { LedgerService } from '../../../service/ledger.service';

import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import * as  pdfMake from  "pdfmake/build/pdfmake";
import { ProjectBankAccService } from '../../../service/project-bank-acc.service';
import { MatStepper } from '@angular/material/stepper'
declare var $: any;
@Component({
  selector: 'app-store-keeper-register',
  templateUrl: './store-keeper-register.component.html',
  styleUrls: ['./store-keeper-register.component.css']
})



export class StoreKeeperRegisterComponent implements OnInit {
  constructor(private storeKeeperService: StoreKeeperService,
    private spinner: NgxSpinnerService,
    private settingService: SettingService,
    public mainService: MainService,
    private chartOfAccountService: ChartOfAccountService,
    private mdService: MdMainService,
    private projectBankAccService: ProjectBankAccService,
    private ledgerService: LedgerService
  ) { }
  ebillUser:any;
  b_acct_id:any;
  user_id:any;
  role_cd:any;

  @ViewChild('stepper', { static: false }) stepper!: MatStepper;

  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol1', { static: false }) sortCol1!: MatSort;
  @ViewChild('paginator2', { static: false }) paginator2!: MatPaginator;
  @ViewChild("sortCol2", { static: false }) sortCol2!: MatSort;

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.ebillUser["b_acct_id"];
    this.user_id = this.ebillUser.user_id
    this.role_cd = this.ebillUser.role_cd;
    await this.getrepresentative();
    await this.getparty();
    await this.getChartOfAccount();


    // await this.getAllAccountRole();
    // await this.getAllAccountEmployees();
    await this.getAllAccountEmployees();
    // await this.getCurrentLegalEntity()
    await this.getCurrentRole()
    await this.getCurrentUsers()
    await this.getworkFlowData();
    await this.getFixAssetsMapping();
    await this.getFinYear();
    await this.getActiveFinYear()
  }


  displayedColumns2: string[] = ['Sr.No', 'item_name', 'uni_id_num', 'pur_cost', 'pur_date', 'status', 'Action'];
  displayedColumns: string[] = ['Sr.No', 'item_name', 'uni_id_num', 'pur_cost', 'pur_date', 'status', 'Action'];
  dataSource: any = [];
  dataSource2: any = [];
  isLinear = false;
  first :any= true;
  second :any= true;
  third :any= true;
  fourth:any = true;
  itemObj :any= new Object();  // First Screen
  add_in_cost :any= []
  show_but:any = false;
  final_arr:any = []
  sel_fin_year:any;

  addRow() {
    let index = this.add_in_cost.length + 1;
    this.add_in_cost.push({
      item_id: index,
      addition_cd: '',
      addition_amt: 0,
    })
  }

  deleteRowFiles(i:any) {
    for (let j = 0; j < this.add_in_cost.length; j++) {
      if (j > i) {
        this.add_in_cost[j]["item_id"] = this.add_in_cost[j]["item_id"] - 1;
      }
    }
    this.add_in_cost.splice(i, 1);

    if (this.add_in_cost.length == 0) {
      this.show_but = false;
    }
  }

  reperesentative:any = {}
  async getrepresentative() {
    let resp = await this.settingService.getAllrepresentative(JSON.stringify({ b_acct_id: this.b_acct_id }));
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        this.reperesentative[resp['data'][i]['rep_le_id']] = resp['data'][i]['party_leagal_name']

      }


    }
  }

  all_party:any = []
  async getparty() {
    let resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['party_type'] == 'ORG') {
          resp['data'][i]['party_leagal_name'] = resp['data'][i]['party_leagal_name'] + ' ( ' + this.reperesentative[resp['data'][i]['rep_le_id']] + ')'
        }
      }
      this.all_party = []
      resp['data'].map((x:any) => {
        this.all_party.push(x);
      });
      console.log("/All Party : ", this.all_party);
    }
  }

  all_coa:any = []
  fixed_coa:any = []
  allcoaObj :any= {}
  async getChartOfAccount() {

    this.spinner.show();
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.chartOfAccountService.getchartofaccount(obj);
    if (resp['error'] == false) {
      // this.all_coa = resp['data'];
      this.fixed_coa = []
      this.allcoaObj = {}

      resp['data'].map((x:any) => {
        this.allcoaObj[x['leaf_code']] = x['leaf_value']
        if (x["lvl4_code"] == "CH1121" && x['is_leaf'] == 1) {
          this.fixed_coa.push(x);
        }
      })
      this.all_coa = []
      resp['data'].map((x:any) => {
        if (x["lvl4_code"] != "CH1121" && x['is_leaf'] == 1) {
          this.all_coa.push(x);
        }
      })


      // console.log("Fixed Assets ", this.fixed_coa);
      // console.log("All COA ", this.all_coa);
      // console.log("All COA OBJ", this.allcoaObj);

      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('ERROR', 'Error While getting Chart Of Account', 'error');
    }
  }

  goToNext(form:any) {
    console.log("From Function", form)
    let formControl = form.controls;
    if (formControl.item_name.status == "VALID" && formControl.uni_id_num.status == "VALID" && formControl.pur_cost.status == "VALID"
      && formControl.pur_date.status == "VALID" && formControl.remark.status == "VALID") {
      this.stepper.next();
    }
  }

  goToNextSecond(form:any) {
    let formControl = form.controls;
    console.log("From Function2", form)
    if (formControl.asset_location.status == "VALID" && formControl.resp_per.status == "VALID") {
      this.stepper.next();
    }
  }

  gotoSubmit(form:any) {
    let formControl = form.controls;
    console.log("From Function2", form)

    if (formControl.dep_method.status == "VALID" && formControl.cr_coa.status == "VALID" && formControl.fix.status == "VALID"
      && formControl.party.status == "VALID") {
      this.submit();
    }
  }

  async submit() {
    this.itemObj['b_acct_id'] = this.b_acct_id;
    this.itemObj['user_id'] = this.user_id;
    this.itemObj['status'] = "GENERATED";
    this.itemObj['module_cd'] = "ACCOUNT";

    this.itemObj['addition_cost'] = this.add_in_cost;
    console.log(this.itemObj);
    let resp = await this.storeKeeperService.createStoreKeeper(this.itemObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      Swal.fire("SUCCESS", 'Inserted Successfully', 'success');
      this.stepper.reset();
      this.itemObj = {}
      this.add_in_cost = []
      $('.nav-tabs a[href="#tab-1"]').tab("show");
    } else {
      Swal.fire("ERROR", "Error while inserting data", 'error');
    }

  }

  account_emp:any = []
  account_emp_obj:any = {}
  async getAllAccountEmployees() {
    this.spinner.show()
    let arr:any = []
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'ACCOUNT'
    let resp = await this.mdService.getuserformodulerolemapping(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.account_emp = resp['data']

      this.account_emp.map((x:any) => {
        this.account_emp_obj[x['user_id']] = x['emp_name']
        x['desc'] = x['user_id'] + " - " + x['emp_name'] + " - " + x['emp_phone_no'];
      })

      console.log("All account employee : ", this.account_emp);
      this.spinner.hide()
      arr = resp.data;

    } else {
      this.spinner.hide()

    }

  }

  current_role = []
  async getCurrentRole() {
    this.spinner.show()
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'ACCOUNT'
    let resp = await this.mdService.getCurrentMdRole(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.current_role = resp['data']
      console.log("All Account Role : ", this.current_role);
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }

  allUsers = []
  async getCurrentUsers() {
    this.spinner.show()
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'ACCOUNT'
    let resp = await this.mdService.getCurrentMdUser(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log("All User With Role : ", resp['data']);
      this.allUsers = resp['data']



      // this.allUsers = [...new Map(this.allUsers.map(item => [item['user_id'], item])).values()];

      this.spinner.hide()
      let emp_id :any= new Object();
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 0; j < this.current_role.length; j++) {
          resp['data'][i]['user_name'] = this.account_emp_obj[resp['data'][i]['user_id']]
          if (this.current_role[j]['role_cd'] == resp['data'][i]['role_cd']) {
            if (emp_id[resp['data'][i]['user_id']] == undefined) {
              resp['data'][i]['role_name'] = this.current_role[j]['role_name']
              emp_id[resp['data'][i]['user_id']] = resp['data'][i]
            } else {
              // let arr = emp_id[resp['data'][i]['user_id']]['role_name'].split(" , ");
              // console.log(arr)
              // if(!arr.includes(emp_id[resp['data'][i]['user_id']]['role_name'])){
              //   emp_id[resp['data'][i]['user_id']]['role_name'] = emp_id[resp['data'][i]['user_id']]['role_name'] + " , " + this.current_role[j]['role_name']
              // }
              emp_id[resp['data'][i]['user_id']]['role_name'] = emp_id[resp['data'][i]['user_id']]['role_name'] + " , " + this.current_role[j]['role_name']
            }
          }
        }
      }


      let data = Object.values(emp_id);

      // finding unique role

      data.map((x:any) => {
        let arr:any = x['role_name'].split(" , ");
        let uni = [...new Set(arr)]
        x['role_name'] = uni.join(" , ");
      })

      this.final_arr = []
      data.map((x:any) => {
        let role = x['role_name'].split(" , ");
        role.map((y:any) => {
          let roleObj:any = {}
          roleObj['role_cd'] = y;
          roleObj['user_id'] = x['user_id'];
          roleObj['desc'] = x['user_id'] + " - " + x['user_name'] + " - " + y;
          this.final_arr.push(roleObj);
        })
      })

      console.log(data)
      console.log("Final Data : ", this.final_arr)
      // this.datasource = new MatTableDataSource(data);
      // this.datasource.paginator = this.paginator;
      // this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }

  async refresh() {
    await this.getworkFlowData();
  }

  async refresh2() {
    await this.getPendingTask();
  }

  workflow_data = []
  async getworkFlowData() {
    this.spinner.show()
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id;
    let resp = await this.storeKeeperService.getworkFlowData(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.workflow_data = resp['data'];
      console.log(this.workflow_data)
      this.dataSource = new MatTableDataSource(this.workflow_data);
      this.dataSource.paginator = this.paginator1;
      this.dataSource.sort = this.sortCol1;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("ERROR", "Error while getting data", 'error');
    }
  }

  async changeStatus(status:any, element:any) {
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = status;
    obj['asset_reg_id'] = element.id;
    // obj['data'] = element;
    let resp = await this.storeKeeperService.changeStatus(obj);
    if (resp['error'] == false) {
      Swal.fire("SUCCESS", `File Status Changed`, 'success');
      await this.getworkFlowData();
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("ERROR", `Error While  Changing File Status `, 'error');
    }
  }
  fixed_ass_map :any= []
  fixedAssetObj:any = {}
  async getFixAssetsMapping() {
    this.spinner.show()
    let obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj['fin_year'] = 2022
    let resp = await this.projectBankAccService.getFixedAssetMapping(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.fixed_ass_map = resp['data'];
      console.log("FIXED ASSETS MAPPING : ", this.fixed_ass_map);
      this.fixed_ass_map.map((x:any) => {
        this.fixedAssetObj[x['f_coa']] = x['d_rate']
      })

      console.log("fixed Obj", this.fixedAssetObj)
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While getting data', 'error');
    }
  }

  allFinYear = []
  async getFinYear() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getAllFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allFinYear = resp.data;
      console.log("All Fin Year : ", this.allFinYear)
      this.spinner.hide();
    } else {
      Swal.fire("ERROR", "Error While Getting Financial Year ");
      this.spinner.hide()
    }
  }

  active_FinYear:any;
  async getActiveFinYear() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getActiveFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.active_FinYear;
      this.active_FinYear = resp.data[0]['fin_year'];
      console.log("Active Fin Year : ", this.active_FinYear)
      this.spinner.hide();
    } else {
      Swal.fire("ERROR", "Error While Getting Financial Year ");
      this.spinner.hide()
    }
  }

  end_date:any;
  start_date:any;
  finYearObj :any= {}
  findFinYear(sel_date:any) { //end_date
    // let date = new Date(sel_date)
    this.sel_fin_year = ''
    this.start_date = ''
    this.end_date = ''
    this.allFinYear.map(x => {
      this.finYearObj[x['fin_year']] = x;
      if (x['start_date'] <= sel_date) {
        if (sel_date <= x['end_date']) {
          this.sel_fin_year = x['fin_year'];
          this.start_date = x['start_date'];
          this.end_date = x['end_date'];
        }
      }
    })
    console.log(this.sel_fin_year);
  }

  approval_data:any = {}
  async addToApproval(element:any) {
    $('#addToApprove').modal('show');
    console.log(element);
    this.approval_data = element;
  }

  async approveSubmit() {
    console.log(this.approval_data);
    $('#addToApprove').modal('hide');
    this.approval_data['b_acct_id'] = this.b_acct_id;
    this.approval_data['status'] = "PENDING"
    this.approval_data['forwarded_by'] = this.ebillUser.party_name;
    // this.approval_data['user_id'] = this.ebillUser.user_id;

    let resp = await this.storeKeeperService.fileApproved(this.approval_data);
    if (resp['error'] == false) {
      Swal.fire("SUCCESS", `File Status Changed`, 'success');
      await this.getworkFlowData();
      await this.getPendingTask();
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("ERROR", `Error While  Changing File Status `, 'error');
    }
  }
  async delete(element:any) {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['asset_reg_id'] = element.id;
    let resp = await this.storeKeeperService.deleteItem(JSON.stringify(obj));
    if (resp['error'] == false) {
      Swal.fire("DELETED", "Deleted Successfully", 'success');
      await this.getworkFlowData();
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("ERROR", "Error While Deleting ", 'error');
    }
  }

  async getPendingTask() {
    this.spinner.show()
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id;
    console.log(obj);
    let resp = await this.storeKeeperService.getPandingTask(JSON.stringify(obj));
    if (resp['error'] == false) {
      let data2 = resp['data'];
      console.log(data2)
      this.dataSource2 = []
      this.dataSource2 = new MatTableDataSource(data2);
      this.dataSource2.paginator = this.paginator2;
      this.dataSource2.sort = this.sortCol2;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("ERROR", "Error while getting workflow data", 'error');
    }
  }

  reject_data :any= {}
  async reject(element:any) {
    this.reject_data = {}
    $('#reject').modal('show');
    console.log("reject ", element);
    this.reject_data = element;
  }

  async rejectSubmit() {
    // let obj :any= {};
    // obj['user_id'] = this.user_id;
    // obj['status'] = "REJECTED"
    // obj['b_acct_id'] = this.b_acct_id;
    // obj['asset_reg_id'] = element.id;
    // console.log(obj);
    this.reject_data['status'] = "REJECTED"
    this.reject_data['b_acct_id'] = this.b_acct_id;
    this.reject_data['user_id'] = this.user_id;
    this.reject_data['module_cd'] = "ACCOUNT";
    this.reject_data['forwarded_by'] = this.ebillUser.party_name;

    console.log(this.reject_data)
    let resp = await this.storeKeeperService.viewIteminfo(this.reject_data);
    if (resp['error'] == false) {
      Swal.fire("SUCCESS", `File Status Changed`, 'success');
      await this.getworkFlowData();
      await this.getPendingTask();
      $('#reject').modal('hide');
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("ERROR", `Error While  Changing File Status `, 'error');
    }
  }

  dataObj :any= {}
  loc_data:any = []

  async view(element:any) {
    console.log(element)
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id;
    obj['asset_reg_id'] = element.id;

    let resp = await this.storeKeeperService.viewIteminfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp['data']);
      this.loc_data = []
      let ass_data = resp['data'][0][0];
      this.loc_data = resp['data'][1];

      this.findFinYear(ass_data.pur_date);
      let amt = 0;
      amt = ass_data.pur_cost;
      let rate = this.fixedAssetObj[ass_data.db_chart_of_acct];
      this.loc_data.map((x:any) => {
        amt += x['addition_amt']
      })
      let dep_amt = (amt * rate) / 100;

      this.dataObj['chart_of_account'] = this.allcoaObj[ass_data.db_chart_of_acct];
      this.dataObj['item_name'] = ass_data.item_name;
      this.dataObj['uni_id_num'] = ass_data.uni_id_num;
      this.dataObj['asset_location'] = ass_data.asset_location;
      this.dataObj['pur_date'] = ass_data.pur_date;
      this.dataObj['resp_person'] = ass_data.resp_person;
      this.dataObj['d_rate'] = this.fixedAssetObj[ass_data.db_chart_of_acct];
      this.dataObj['provision_dep'] = "No ANS "
      this.dataObj['remark'] = ass_data.remark;
      this.dataObj['dep_method'] = ass_data.dep_method;
      this.dataObj['end_date'] = this.end_date; //fin year end date
      this.dataObj['start_date'] = this.start_date;//fin year start date
      this.dataObj['pur_cost'] = ass_data.pur_cost;
      this.dataObj['tot_amt'] = dep_amt;
      this.dataObj['WDV'] = (amt - dep_amt);

      console.log(this.dataObj);
      await this.print();
    } else {
      Swal.fire("ERROR", "ERROR WHILE Getting Item info", 'error');
    }

  }
  async print() {


    // calculating the provision for depreciation amount
    let provision_amt = 0
    let dep_amt = 0;
    let amt = this.dataObj['WDV'];

    provision_amt += this.dataObj['tot_amt'];
    let len = this.active_FinYear - this.sel_fin_year;
    for (let i = 0; i < len; i++) {
      dep_amt = 0;
      let rate = this.dataObj['d_rate'];
      dep_amt = (amt * rate) / 100;
      provision_amt += dep_amt;
      amt = Math.abs(amt - dep_amt);
    }


    let txt = " Fixed Assets Register ";
    var dd:any = {
      pageSize: "A4",
      header: function (currentPage:any, pageCount:any) {
        var arr = [];
        var obj = {
          text: txt + " Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj);
        var obj1 = {
          text: "Store Keeper",
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj1);

        return arr;
      },

      pageOrientation: "landscape",
      pageMargins: [40, 60, 40, 60],
      content: [],
    };

    let printdata = []

    let header1 = {
      columns: [
        {
          width: "*",
          text: "ITEM :",
          bold: true
        },
        {
          width: "*",
          text: this.dataObj["item_name"],
        },
        {
          width: "*",
          text: "CHART OF ACCOUNTS:",
          bold: true,
        },
        {
          width: "*",
          text: this.dataObj['chart_of_account'],
        }
      ]
    };
    printdata.push(header1);


    let header2 = {
      columns: [
        {
          width: "*",
          text: "IDENTIFICATION NUMBER:",
          bold: true
        },
        {
          width: "*",
          text: this.dataObj["uni_id_num"],
        },
        {
          width: "*",
          text: "ASSET LOCATION:",
          bold: true,
        },
        {
          width: "*",
          text: this.dataObj["asset_location"],
        }
      ]
    };
    printdata.push(header2);


    let header3 = {
      columns: [
        {
          width: "*",
          text: "DATE OF PURCHASE:",
          bold: true
        },
        {
          width: "*",
          text: this.dataObj["pur_date"],
        },
        {
          width: "*",
          text: "PERSON RESPONSIBLE:",
          bold: true,
        },
        {
          width: "*",
          text: this.dataObj["resp_person"],
        }
      ]
    };
    printdata.push(header3);


    let header4 = {
      columns: [
        {
          width: "*",
          text: "PROVISION FOR DEPRECIATION:",
          bold: true
        },
        {
          width: "*",
          text: provision_amt,
        },
        {
          width: "*",
          text: "RATE OF DEPRECIATION:",
          bold: true,
        },
        {
          width: "*",
          text: this.dataObj["d_rate"],
        }
      ]
    };
    printdata.push(header4);

    let header5 = {
      columns: [
        {
          width: "*",
          text: "DEPRECIATION METHOD:",
          bold: true
        },
        {
          width: "*",
          text: this.dataObj["dep_method"],
        },
        {
          width: "*",
          text: "DESCRIPTION OF ASSET:",
          bold: true,
        },
        {
          width: "*",
          text: this.dataObj["remark"],
        }
      ]
    };

    printdata.push(header5);

    dd.content.push({ text: " " });
    dd.content.push(printdata);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    // var provision_for_dep = 0;
    var tbl1 = {
      fontSize: 10,
      table: {
        headerRows: 3,
        widths: ["*", "*", "*", "*", "*", "*"],
        body: [
          [{ text: 'Date', style: 'tableHeader', alignment: 'center', bold: true }, { text: 'Particulars', style: 'tableHeader', alignment: 'center', bold: true },
          { text: 'Amount', style: 'tableHeader', alignment: 'center', bold: true }, { text: 'Amount', style: 'tableHeader', alignment: 'center', bold: true }
            , { text: 'Particulars', style: 'tableHeader', alignment: 'center', bold: true }, { text: 'Amount', style: 'tableHeader', alignment: 'center', bold: true }]
        ],
      },
    };

    dd.content.push(tbl1);

    ///First Line Of Table
    let arr = []
    arr.push({ text: this.dataObj['pur_date'], alignment: 'center' });
    arr.push({ text: this.dataObj['item_name'], alignment: 'center' });
    arr.push({ text: this.dataObj['pur_cost'], alignment: 'center' });
    arr.push({ text: this.end_date, alignment: 'center' });
    arr.push({ text: "Depreciation ", alignment: 'center' });
    arr.push({ text: this.dataObj['tot_amt'], alignment: 'center' });

    dd.content[dd.content.length - 1].table.body.push(arr);

    //Additional Charges Line
    for (let i = 0; i < this.loc_data.length; i++) {
      let arr = []
      arr.push({ text: ' ' });
      arr.push({ text: this.loc_data[i]['addition_cd'], alignment: 'center' });
      arr.push({ text: this.loc_data[i]['addition_amt'], alignment: 'center' });
      if ((this.loc_data.length - 1) == i) {
        arr.push({ text: this.end_date, alignment: 'center' });
        arr.push({ text: "WDV", alignment: 'center' });
        arr.push({ text: this.dataObj['WDV'], alignment: 'center' });
      } else {
        arr.push({ text: '' })
        arr.push({ text: '' })
        arr.push({ text: '' })
      }
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content[dd.content.length - 1].table.body.push([{ text: '', colSpan: 6, margin: [5, 5, 5, 5] }, {}, {}, {}, {}, {}])

    for (let i = 0; i < len; i++) {
      let amt = this.dataObj['WDV'];
      let rate = this.dataObj['d_rate'];
      let data = [];
      data.push({ text: this.finYearObj[(Number(this.sel_fin_year) + i + 1)]['start_date'], alignment: 'center' });
      data.push({ text: "WDV", alignment: 'center' });
      data.push({ text: this.dataObj['WDV'], alignment: 'center' });
      data.push({ text: this.finYearObj[(Number(this.sel_fin_year) + i + 1)]['end_date'], alignment: 'center' });
      data.push({ text: 'Depreciation', alignment: 'center' });
      let dep_amt = (amt * rate) / 100;
      this.dataObj['dep_amt'] = dep_amt;
      data.push({ text: this.dataObj['dep_amt'], alignment: 'center' });
      // provision_for_dep += this.dataObj['dep_amt'];
      dd.content[dd.content.length - 1].table.body.push(data);
      let arr2 = []
      arr2.push({ text: '', alignment: 'center' })
      arr2.push({ text: '', alignment: 'center' })
      arr2.push({ text: '', alignment: 'center' })
      arr2.push({ text: this.finYearObj[(Number(this.sel_fin_year) + i + 1)]['end_date'], alignment: 'center' })
      arr2.push({ text: 'WDV', alignment: 'center' })
      this.dataObj['WDV'] = amt - this.dataObj['dep_amt'];
      arr2.push({ text: this.dataObj['WDV'], alignment: 'center' })

      dd.content[dd.content.length - 1].table.body.push(arr2);
      if (len - 1 != i) {
        dd.content[dd.content.length - 1].table.body.push([{ text: '', margin: [5, 5, 5, 5], colSpan: 6 }, {}, {}, {}, {}, {}])
      }
    }
    pdfMake.createPdf(dd).download("FixedAssets.pdf");
  }


}
