import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { MainService } from '../../service/main.service';
import { SalaryHoldAndStartService } from '../../service/salary-hold-and-start.service';
import { Router } from '@angular/router';
declare var $: any;
import swal from 'sweetalert2';
import { EstablishmentService } from '../../service/establishment.service';


@Component({
  selector: 'app-salary-hold-and-start',
  templateUrl: './salary-hold-and-start.component.html',
  styleUrls: ['./salary-hold-and-start.component.css']
})
export class SalaryHoldAndStartComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  status = [{ id: 'Currently Stopped' }, { id: 'Previously Stopped' }]
  displayedColumns = ['id', 'emp_id', 'emp_name', 'fin_year', 'fin_year2', 'month', 'month2', 'status', 'action'];
  datasource:any;
  b_acct_id:any
  erpUser:any
  status_s:any;
  allStop :any= []
  allEmplyees :any= [];
  allEmplyees_new :any= [];
  selectEmpObj:any = {};
  codeValueTechObj:any = {}
  Obj :any= {}
  project_short_name:any;
  activeEmpArr :any= [];
  newallEmplyees :any= [];
  stopSalaryArr :any= [];

 sort1 = 'Ascending';
  constructor(public mainService: MainService ,private establishmentService: EstablishmentService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private SalaryHoldAndStartService: SalaryHoldAndStartService) { }
  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.project_short_name = this.mainService.accInfo['account_short_name'];
    //await this.getAllEmployees();
    await this.getAllActiveEmployees()
    await this.getsalarystatus();
    this.status_s='Currently Stopped'
    this.changeTable()

  }


  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }

  // async getAllEmployees() {
  //   this.spinner.show()
  //   var arr = []
  //   var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['emp_status_code'] = ['JOINING', 'JOINED', 'LEFT']
  //   var resp = await this.SalaryHoldAndStartService.getEmployeeMasterData(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     arr = resp.data;
  //     for (let i = 0; i < arr.length; i++) {
  //       var obj:any = new Object();
  //       obj = Object.assign({}, arr[i]);
  //       obj['tempid'] = /* this.mainService.accInfo['account_short_name'] +  */this.getNumberFormat(obj['emp_id'])
  //       this.allEmplyees.push(obj)
  //     }
  //     this.allEmplyees_new = [];
  //     for (let i = 0; i < resp.data.length; i++) {
  //       var obj:any = new Object();
  //       obj = Object.assign({}, resp.data[i]);
  //       obj['emp_name'] = /* this.mainService.accInfo['account_short_name'] + */ this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
  //       this.allEmplyees_new.push(obj)
  //     }

  //   } else {
  //     this.spinner.hide()
  //     this.snackBar.open("Error while getting employee list", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }

  async getAllActiveEmployees() {
    var obj:any=new Object();
     obj['b_acct_id']=this.b_acct_id;
     obj['emp_status_code'] = ['ACTIVE','INACTIVE']
    var resp = await this.establishmentService.getArrayAllCurrentEstablishementInfo(obj);
  //this.allActiveEmplyees=[]

    if (resp['error'] == false) {
      this.allEmplyees = resp.data;
      this.activeEmpArr = []

      for (let i = 0; i < this.allEmplyees.length; i++) {
        if(this.allEmplyees[i].emp_status_code == "ACTIVE") {
          this.activeEmpArr.push(this.allEmplyees[i])
         }
      }
      this.newallEmplyees = [];
      for(let i=0;i<this.activeEmpArr.length;i++){
        var obj:any=new Object();
        obj=Object.assign({},this.activeEmpArr[i]);
        obj['emp_name']=/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])+"-"+obj['emp_name']
        this.newallEmplyees.push(obj)
      }
      }
     else {
      swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async changeEmployee() {
    for(let i =0;i<this.stopSalaryArr.length;i++){
      if(this.stopSalaryArr[i]['emp_id'] == this.selectEmpObj['emp_id'])
      {
        swal.fire("Warning",'The employee you have selected already stopped salary process. Please Refresh page and again select another employee','warning');
        return
      }
  }
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectEmpObj['emp_id'];

  }
  data :any= []
  async getsalarystatus() {
    this.data=[]
    this.spinner.show()
    var arr = []
    var holdsalary = []
    this.allStop = [];
    var resp = await this.SalaryHoldAndStartService.getsalarystatus(this.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      var dt = resp.data;
      // for (var i = 0; i < dt.length; i++){
      //   if (dt[i]['status'] == 'STOP'){
      //     this.allStop.push(dt[i]);
      //   }
      // }
      arr = dt;
      for (let i = 0; i < arr.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = /* this.mainService.accInfo['account_short_name'] + */ this.getNumberFormat(obj['emp_id'])
        holdsalary.push(obj)
      }
      this.data = holdsalary

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee all  list ', 'error')
      // this.snackBar.open("Error while getting employee all  list", 'Error', {
      //   duration: 5000
      // });
    }
  }
 async changeTable() {
    await this.getsalarystatus();
    let dummy = []
    this.datasource=[]
    // only status stop is going to add in the array

    if (this.status_s == 'Currently Stopped') {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i]['status'] == 'STOP') {
          this.data[i]['start_month'] = 'Not Started'
          this.data[i]['start_fin_year'] = 'Not Started'
          this.data[i]['stop_month'] = this.mainService.codeValueShowObj['HR0024'][this.data[i]['stop_month']]
          this.data[i]['stop_fin_year'] = this.mainService.codeValueShowObj['HR0023'][this.data[i]['stop_fin_year']]
          dummy.push(this.data[i])
          this.stopSalaryArr.push(this.data[i])
        }
      }
    }
    if (this.status_s == 'Previously Stopped') {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i]['status'] == 'START') {
          this.data[i]['start_month'] = this.mainService.codeValueShowObj['HR0024'][this.data[i]['start_month']]
          this.data[i]['start_fin_year'] = this.mainService.codeValueShowObj['HR0023'][this.data[i]['start_fin_year']]
          this.data[i]['stop_month'] = this.mainService.codeValueShowObj['HR0024'][this.data[i]['stop_month']]
          this.data[i]['stop_fin_year'] = this.mainService.codeValueShowObj['HR0023'][this.data[i]['stop_fin_year']]
          dummy.push(this.data[i])
        }
      }
    }
    this.datasource = new MatTableDataSource(dummy)
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }
  view_data:any = []
  view_data_header:any={}
  viewPaidStatus(data:any) {
    this.view_data_header=data;
    this.view_data = JSON.parse(data['paid']);
    console.log("this is the ---", data);
    $('#myModal2').modal('show');
  }

// getAscending()
// {
//   for(let i of this.mainService.codeValueTechObj['HR0023'])
//   {
//     console.log("this is the dropdown",i)
//   }
// }

  async submit() {
    for(let i =0;i<this.stopSalaryArr.length;i++){
      if(this.stopSalaryArr[i]['emp_id'] == this.selectEmpObj['emp_id'])
      {
        swal.fire("Error",'This Employee you have selected already stooped salary process Plz Refresh Page and again select other Employee','error');
        return
      }
  }
    if(this.selectEmpObj['emp_id']==null || this.selectEmpObj['emp_id']==undefined || this.selectEmpObj['emp_id']==''){
      swal.fire("Error",'Please Select Employee First','error')
        return
    }
    if(this.Obj['stop_fin_year']==null || this.Obj['stop_fin_year']==undefined || this.Obj['stop_fin_year']==''){
      swal.fire("Error",'Please Select Financial Year','error')
        return
    }
    if(this.Obj['stop_month']==null || this.Obj['stop_month']==undefined || this.Obj['stop_month']==''){
      swal.fire("Error",'Please Select Month','error')
        return
    }
    this.Obj['b_acct_id'] = this.b_acct_id;
    this.Obj['emp_id'] = this.selectEmpObj['emp_id'];
    for (let i = 0; i < this.allEmplyees.length; i++) {
      if (this.Obj['emp_id'] == this.allEmplyees[i]['emp_id'])
        this.Obj['emp_name'] = this.allEmplyees[i]['emp_name']
    }
    this.Obj['status'] = 'STOP';
    this.Obj['paid'] = JSON.stringify([])
    this.Obj['create_user_id'] = this.erpUser.user_id;
    this.spinner.show();
    var resp = await this.SalaryHoldAndStartService.holdsalary(this.Obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getsalarystatus();
      this.changeTable()
      this.selectEmpObj = {};
      this.Obj['stop_month']=''
      this.Obj['stop_fin_year']=''
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      // this.snackBar.open(" Added Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success",'Added Successfully ','success');

    } else {
      this.spinner.hide();
      // this.snackBar.open("Error while Holding  Of Employee Salary", 'Error', {
      //   duration: 5000
      // });
      swal.fire('Error','Error while Holding  Of Employee Salary !','error')
    }
  }
  async changeStatus(element:any) {
    this.Obj = []
    /* var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['start_fin_year'] = this.Obj['start_fin_year']
    obj['start_month'] = this.Obj['start_month'] */

    this.spinner.show();
    this.Obj['id'] = element['id'];
    $('#myModal').modal('show');
    this.spinner.hide();
    //
  }
  async changeStatusstart() {
    if(this.Obj['start_fin_year']==null ||this.Obj['start_fin_year']==undefined || this.Obj['start_fin_year']==''
      || this.Obj['start_month']==null ||this.Obj['start_month']==undefined || this.Obj['start_month']==''
    )
    {
      swal.fire('info',"Firstly Fill All Required (*) Field",'info')
      return ;
    }
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['start_fin_year'] = this.Obj['start_fin_year']
    obj['start_month'] = this.Obj['start_month']
    obj['id'] = this.Obj['id']
    this.spinner.show();
    var resp = await this.SalaryHoldAndStartService.changeStatusOfsalary(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      swal.fire('Success',' Status Changed Successfully','success')
      await this.getsalarystatus();
      this.changeTable()
      $('#myModal').modal('hide');

    } else {
      this.spinner.hide();
      swal.fire('Error','Error Occured','error')

    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
