import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { Injectable,AfterContentInit } from '@angular/core';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class UserlogService {

  httpUrl:any
  erpUser: any;
  b_acct_id: any;
  user_id: any;

  constructor(private http: HttpClient, private main: MainService,
    private datePipe: DatePipe) {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    this.httpUrl = this.main.httpUrl + "/tracking";
    this.user_id = this.erpUser.le_id
  }


  myDate = new Date();

  lat: any;
  long: any;
  ipAddress:any;
  ipAddress2: any;
  browser:any;
  AllRoleZone :any= []
  data_auth_id:any;
  lng:any;
  zoom:any;
  login_obj :any= {
    user_id: null, login_time: null, login_ip: null, login_browser: null,
    latitude: null, longitude: null, active_from_time: null, logout_time: null,
    login_type: 'ONLOAD', status: 'NA', login_id: null, password: null,
  }

  getIPAddress() {
    console.log("i am in ip")
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
      this.login_obj['login_ip'] = this.ipAddress;
    });
    console.log(this.login_obj['login_ip'])
    }
  geoLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;
        this.zoom = 16;
        this.login_obj['latitude'] = lat;
        this.login_obj['longitude'] = lng;
      });
      console.log(this.login_obj['latitude']);
    }
    else {
      alert('Browser Does`t Supported')
    }
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }



  // async insertLoginInfo() {
  //   // console.log("i am here")

  //   this.login_obj['b_acct_id'] = this.b_acct_id
  //   // console.log(this.login_obj)
  //   // var resp = await this.sale_service.createloginInfo(this.login_obj);
  // }





  async insertLoginInfo(message:any) {
    setTimeout(async () => {
      await this.geoLocation();
    }, 500);
    setTimeout(async () => {
      await this.getIPAddress();
    }, 500)

    //browser set
    this.browser = await this.getBrowserName();
    this.login_obj['login_browser'] = this.browser
    this.login_obj['active_from_time'] = this.datePipe.transform(this.myDate, 'yyyy-MM-dd')+ "__"+ new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })
    this.login_obj['b_acct_id'] = this.b_acct_id
    this.login_obj['user_id'] = this.user_id
    this.login_obj['message'] = message
    console.log(this.login_obj)
    const resp = await this.http.post<any>(this.httpUrl + '/log/createloginInfo', this.login_obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}
