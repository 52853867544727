<div class="page-content fade-in-up">
    <br>
    <div class="row">
        <!-- <button style="margin-left:0.5%;" class="btn btn-link" (click)="back()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i> Go Back</button> -->
        <button style="margin-left:0.5%;" class="btn btn-link" (click)="back()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i> Go Back</button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <br>
                <div class="ibox-head">
                    <div class="ibox-title">TASK MANAGEMENT</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> All Task</a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-7-1">
                            <br>
                            <table id="tbl1">

                                <tr>
                                    <th>
                                        S.NO.
                                    </th>
                                    <th>
                                        Document Type
                                    </th>
                                    <th>
                                        Document No
                                    </th>
                                    <th>
                                        Document Description
                                    </th>
                                    <th>
                                        Role
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                    <th>
                                        View Details
                                    </th>
                                </tr>
                                <tr *ngFor="let ob of allTaskArr; let i = index">
                                    <td>{{i+1}}</td>
                                    <td> {{ob.doc_type}}</td>
                                    <td> {{ob.doc_local_no}}</td>
                                    <td> {{ob.doc_desc}}</td>
                                    <td> {{ob.role_cd}}</td>
                                    <td>


                                        <button class="btn btn-primary" (click)="Appr(ob)">Approve1</button>
                                        <button class="btn btn-primary"
                                            *ngIf="ob['action']==1 && ob['last_approval_level']==ob['last_level']"
                                            (click)="taskStatusChange(ob)">Approve2</button>

                                        <button class="btn btn-danger" (click)="reject(ob)">REJECT</button>


                                    </td>
                                    <td>
                                        <button class="btn btn-info" (click)="status(ob)">Status</button>

                                        <button class="btn btn-info" *ngIf="ob['doc_type']!='EXTRA_WORK' || ob['doc_type']!='ACR' " (click)="download(ob)">Download</button>
                                        <button class="btn btn-primary" *ngIf="ob['doc_type']=='SALBILL'"
                                            (click)="getAllBill(ob,'')">Summary</button>
                                        <button class="btn btn-primary" *ngIf="ob['doc_type']=='LEAVE'"
                                            (click)="ViewLeave(ob)">View</button>
                                        <button class="btn btn-primary" *ngIf="ob['doc_type']=='EBILL'"
                                            (click)="print_emb_for_bill(ob)">Print EMB FOR Bill</button>


                                    </td>
                                </tr>
                            </table>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <img [src]="imgURL" />
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>
<div class="modal" id="myModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Status</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="container">
                    <!-- completed -->
                    <a *ngFor="let ob of statusArr;let i=index">
                        <div class="step completed" *ngIf="ob.status=='APPROVED'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;"
                                    *ngIf="i <statusArr.length-1"></div>
                            </div>
                            <!-- user name is added start -->
                            <div class="content">
                                {{statusArr.length- i}}-{{ob['user_name']}}-{{ob['role_cd']}}-{{ob['status']}} ({{ob.name}})
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='REJECTED'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{statusArr.length- i}}-{{ob['user_name']}}-{{ob['role_cd']}}-{{ob['status']}} ({{ob.name}})
                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='PENDING'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{statusArr.length- i}}-{{ob['user_name']}}-{{ob['role_cd']}}-{{ob['status']}} ({{ob.name}})
                            </div>
                        </div>
                    </a>

                    <!-- user name is added end -->
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-success"data-bs-dismiss="modal" (click)="certificate()">Download
                        Certificate</button>
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Purpose :</h6>
                    </div>
                    <div class="col-4">
                        {{ApprovalUserService.level1['success_action']}}
                    </div>


                </div>
                <br>
                <div class="row"
                    *ngIf="ApprovalUserService.vendorflag==false && ApprovalUserService.approvalflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                            [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                            placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div *ngIf="tender_apprdata['doc_type']!='EXTRA_WORK' || 'ACR' ">
                    <table class="table table-bordered"
                        style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" style="border: black;">
                    <div class="col-4 text-right">
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row"
                    *ngIf="ApprovalUserService.vendorflag==false  && ApprovalUserService.approvalflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="Approvedapproval()">Submit

                        </button>
                    </div>
                </div>
                <br>


                <div *ngIf="ApprovalUserService.vendorflag==false  && ApprovalUserService.approvalflag==true"
                    class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="approvedocment()">Approve
                        </button>
                    </div>
                </div>
                <div *ngIf="ApprovalUserService.vendorflag==true  && ApprovalUserService.approvalflag==false"
                    class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>
                <br>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Reject Approval</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                            placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div class="row" style="border: black;">
                    <div class="col-4 text-right">
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="rejectedapproval()">Submit

                        </button>
                    </div>
                </div>
                <br>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>