<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Manage DA Arrear  -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa1']}}
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="chamgeTab()"><i class="ti-bar-chart"></i>
                                <!-- Employee All Arrear List -->
                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa2']}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle" (click)="chamgeTab()"></i>
                                 <!-- Add DA Arrear Detail -->
                                 {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa3']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                                <!-- Update DA Arrear Detail -->
                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa4']}}
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>




                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa5']}}">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            <!-- Employee ID -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa6']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            <!--  {{mainService.accInfo['account_short_name']}} -->{{ element.emp_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Employee Name -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa7']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="designation_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Employee Designation -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0011'][element.designation_code]}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="areear_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Arrear Type -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.arrear_type}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Amount -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa10']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.amount}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="arrear_start_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Arrear Start Date -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa11']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.arrear_start_dt)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="arrear_end_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Arrear End Date -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.arrear_end_dt)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Status -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa13']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.status}} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Action -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa14']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" (click)="openUpdate(element)">
                                                <!-- Update -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa15']}}</button>
                                            <button class="btn btn-outline-danger" (click)="delete(element)">
                                                <!-- Delete -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa16']}}</button>


                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa17']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_desc" bindValue="emp_id" [multiple]="true" placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa17']}}" [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="false" name="field_name">
                                    </ng-select>


                                </div>
                            </div>
                            <br>





                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- NEW DA: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa30']}}
                                </div>
                                <div class="col-4">
                                    <input type="number" (change)="changeda()" class="form-control" [(ngModel)]="selectEmpObj['new_da']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Start Date: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa19']}}
                                </div>
                                <div class="col-4">
                                    <input type="date" (change)="changeda()" class="form-control" [(ngModel)]="selectEmpObj['arrear_start_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- End Date: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa20']}}
                                </div>
                                <div class="col-4">
                                    <input type="date" (change)="changeda()" class="form-control" [(ngModel)]="selectEmpObj['arrear_end_dt']">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="arear()">
                                        <!-- Submit -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa12']}}</button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa17']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_desc" bindValue="emp_id" [multiple]="false" placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa17']}}" [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>


                                </div>
                            </div>
                            <br>



                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- BASIC: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa22']}}
                                </div>
                                <div class="col-4">
                                    {{selectEmpObj['basic']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- OLD DA: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa23']}}
                                </div>
                                <div class="col-4">
                                    {{selectEmpObj['da']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- NEW DA: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa30']}}
                                </div>
                                <div class="col-4">
                                    <input type="number" (change)="changedaupdate()" class="form-control" [(ngModel)]="selectEmpObj['new_da']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Start Date: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa19']}}
                                </div>
                                <div class="col-4">
                                    <input type="date" (change)="changedaupdate()" class="form-control" [(ngModel)]="selectEmpObj['arrear_start_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- End Date: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa20']}}
                                </div>
                                <div class="col-4">
                                    <input type="date" (change)="changedaupdate()" class="form-control" [(ngModel)]="selectEmpObj['arrear_end_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Total Arrear : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa24']}}
                                </div>
                                <div class="col-4">
                                    {{selectEmpObj['amount']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">
                                        <!-- Update -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa15']}}</button>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
