
import { Component, OnInit } from '@angular/core';
import { MainService } from '../../service/main.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MainService as portal_mainService } from '../../../portal/service/main.service';

import { AuthenticationService } from '../../../authentication.service';
import { ProfileService } from '../../service/profile.service';
@Component({
  selector: '[app-tracking-header]',
  templateUrl: './tracking-header.component.html',
  styleUrls: ['./tracking-header.component.css']
})
export class TrackingHeaderComponent implements OnInit {

  constructor(private router: Router, public profileService: ProfileService, private sanitizer: DomSanitizer, public portal_main: portal_mainService,

    public mainService: MainService, public auth: AuthenticationService) { }

  erpUser:any;
  hidden = true;
  // home() {
  //   this.router.navigate(['/icms/icms-index'])
  // }
  accInfo = {
    account_name: 'ABC'
  }

  noti_count = 0;
  noti_data:any;
  message_count:any;
  async ngOnInit() {

    console.log(this.auth.allLanguage, 'allLanguage')
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));

    let dd = [];
    dd = this.erpUser.role_cd
    if (dd.includes("AA")) {
      this.hidden = false;
    } else {
      this.hidden = true
    }
    await this.portal_main.getUserImage(this.erpUser['user_id']);
    await this.portal_main.getAccImage(this.erpUser['b_acct_id']);
    await this.portal_main.getAccountInfo(this.erpUser['b_acct_id']);
    await this.portal_main.getHeaderInfo(this.erpUser['b_acct_id']);
    await this.changeLanguage();
    let data = await this.portal_main.getNotification(this.erpUser['b_acct_id'], this.erpUser['user_id']);
    this.noti_count = data.noti_count;
    this.noti_data = data.noti_data;
    let message = await this.portal_main.getMessageCount(this.erpUser['b_acct_id'], this.erpUser['user_id']);
    this.message_count = message.length;
  }


  goToIndex(data:any) {
    this.portal_main.icms_notification_data = data;
    this.router.navigate(['/notification']);
    console.log("Go to notification ")
  }

  changeLanguage() {
    console.log("laungage Change");
    this.portal_main.language_cd = this.auth.language_cd
    this.portal_main.allLanguage = this.auth.allLanguage
  }





}
