<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">COGS Report</div>
                </div>
                <div class="ibox-body">

                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <form name="form" (ngSubmit)="f.form.valid && getClosingStock()" #f="ngForm">

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Select Financial Year </h6>
                                    </div>
                                    <div class="col-3 text-center">
                                        <ng-select [items]="all_fin_yr" bindLabel="fin_ye_desc" bindValue="fin_year"
                                            [multiple]="false" [(ngModel)]="sel_fin_yr" placeholder="Select Fin Year"
                                            name="fin_yr" (change)="setDate()" name="fin_year" #fin_year="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && fin_year.invalid }" required>
                                        </ng-select>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="fin_year.dirty && fin_year.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && fin_year.invalid" class="error-message text-left">
                                            <div *ngIf="fin_year.errors?.['required']">Please Select Financial Year </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> {{"From :-"+ mainObj['st_date']}} </h6>
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>{{"To :-"+mainObj['en_date']}}</h6>
                                    </div>
                                </div>
                                <br><br>
                                <div class="row">
                                    <div class="col-6 text-right">
                                        <!-- <button class="btn btn-primary" (click)="getClosingStock()">Submit</button> -->
                                        <button class="btn btn-primary" type="submit">Submit</button>

                                    </div>
                                </div>
                            </form>
                            <br><br>
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr style="background-color:#1589ff;color: #fff;">
                                        <th style="width: 10%;"> Fig at end of Pre. Year</th>
                                        <th style="width: 20%;"> Particulars</th>
                                        <th> Fig at end of reporting year</th>
                                        <th> Fig at end of Pre. Year</th>
                                        <th style="width: 20%;"> Particulars</th>
                                        <th style="width: 10%;"> Fig at end of reporting year</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th colspan="3" class="text-center">To Opening Stock</th>
                                        <th colspan="2" class="text-center">By Closing Stock</th>
                                        <th class=" text-right ">{{dataObj['next_total']}}</th>
                                    </tr>
                                    <tr>
                                        <td class=" text-right ">{{dataObj['pre_row_m']}}</td>
                                        <td>Raw Material</td>
                                        <td class=" text-right ">{{dataObj['curr_row_m']}}</td>
                                        <td class=" text-right ">{{dataObj['curr_row_m']}}</td>
                                        <td>Raw Material</td>
                                        <td class=" text-right ">{{dataObj['next_row_m']}}</td>
                                    </tr>
                                    <tr>
                                        <td class=" text-right ">{{dataObj['pre_work_p']}}</td>
                                        <td>Work in Progress</td>
                                        <td class=" text-right ">{{dataObj['curr_work_p']}}</td>
                                        <td class=" text-right ">{{dataObj['curr_work_p']}}</td>
                                        <td>Work in Progress</td>
                                        <td class=" text-right ">{{dataObj['next_work_p']}}</td>
                                    </tr>
                                    <tr>
                                        <td class=" text-right ">{{dataObj['pre_finished_st']}}</td>
                                        <td>Finished Stock</td>
                                        <td class=" text-right ">{{dataObj['curr_finished_st']}}</td>
                                        <td class=" text-right ">{{dataObj['curr_finished_st']}}</td>
                                        <td>Finished Stock</td>
                                        <td class=" text-right ">{{dataObj['next_finished_st']}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row">

                                <div class="col-6">
                                    <table style="border : 1px solid #fff;width: 105%;">
                                        <tbody>
                                            <tr *ngFor="let e of cogs_entry">
                                                <td style="width: 20%;"></td>
                                                <td style="width: 32%;"> {{e['jrnl_desc']}}</td> <br>
                                                <td style="width: 5%;"></td>
                                                <td style="width: 20%;text-align: end;"> {{e['txn_amt']}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-6">
                                    <table style="border : 1px solid #fff;width: 105%;" class=" text-center ">
                                        <tbody>
                                            <tr>
                                                <td style="width: 38%;"></td>
                                                <td style="width: 34%;"> By Cost of Properties Stock Sold Tr to
                                                    Income
                                                    Expenditure</td>
                                                <td style="width: 9%;"></td>
                                                <td style="width: 17%;text-align: center;">{{dataObj['curr_total'] -
                                                    dataObj['next_total']}}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <br><br>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-2 text-center">
                                    <h6>Total</h6>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-2 text-center">
                                    <h6>{{dataObj['curr_total'] | number : '1.2-2' }}</h6>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-2 text-center">
                                    <h6>Total</h6>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-1 text-right">
                                    <h6>{{closing_totl | number : '1.2-2' }} </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
