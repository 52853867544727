<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Salary Rules</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" *ngIf="list_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                All Rules</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-2"   *ngIf="create_flag"  data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i> Create Rule</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-3"  *ngIf="update_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i> Update Rule</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" style="border-radius: 20px;" (click)=open_create()>Add New Component Rule</button>
                                </div>
                            </div>

                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    Select Component :
                                </div>
                                <div class="col-4 text-center">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0021']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="selectedComponentCode" (change)="changeComponent()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="component_code">
                                    </ng-select>
                                </div>
                            </div> -->

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="effective_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Effective Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.effective_dt) }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="component_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Component
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.component_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="dependent_component">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Dependent Component
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.dependent_component }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="rate_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Rule Type
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.rate_type }} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="upper_limit">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Upper LIMIT
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.upper_limit }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="lower_limit">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Lower LIMIT
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lower_limit }} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Amount
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.amount }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Status
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.status }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_type_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Pay Type
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0022'][element.pay_code] }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button *ngIf="element.status=='INACTIVE'" class="btn btn-success" (click)="chnageStatus(element,'ACTIVE')">ACTIVATE</button>
                                            <button *ngIf="element.status=='ACTIVE'" class="btn btn-danger" (click)="chnageStatus(element,'INACTIVE')">INACTIVE</button>
                                            <button *ngIf="element.rate_type!='CAL'"  class="btn btn-primary" (click)="open_update(element)">UPDATE</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator  [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade show active"  *ngIf="create_flag" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" style="border-radius: 20px;"  (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Component :</h6>
                                </div>
                                <div class="col-4 text-center">
                                    <input class="form-control" [(ngModel)]="obj['component_code']">
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Component Description:</h6>
                                </div>
                                <div class="col-4 text-center">
                                    <input class="form-control" [(ngModel)]="obj['component_desc']">
                                </div>
                            </div>
                            <br> -->
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    Select Rule For:
                                </div>
                                <div class="col-4 text-center">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0021']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['component_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="component_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Is it Special Allowance:</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0042']" (change)="changeIs_spe_allowd()" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['is_spe_allowd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="is_spe_allowd">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Rule Type:</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="rateType" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['rate_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="rate_type">
                                    </ng-select>
                                </div>
                            </div>

                            <br>
                            <hr>


                            <br *ngIf="obj['rate_type']=='PERCENTAGE'">
                            <div *ngIf="obj['rate_type']=='PERCENTAGE'" class="row">
                                <div class="col-3 text-right">
                                    <h6>Percentage of:</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0021']" bindLabel="value" bindValue="code"  placeholder="" [(ngModel)]="obj['dependent_component']" [selectableGroup]="true" [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false" name="dependent_component">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-right">
                                    <h6>Enter Percentage:</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" [(ngModel)]="obj['amount']">
                                </div>
                            </div>



                            <br *ngIf="obj['rate_type']=='FIX'">
                            <div   *ngIf="obj['rate_type']=='FIX'  && obj['is_spe_allowd']!=0" class="row">
                                <div class="col-4 text-right">
                                    <h6>Amount is variable Employee wise:</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0042']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['fix_is_var']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fix_is_var">
                                    </ng-select>
                                </div>
                            </div>
                            <div   *ngIf="obj['rate_type']=='FIX'  && obj['is_spe_allowd']==0" class="row">
                                <div class="col-4 text-right">
                                    <h6>Amount is variable Employee wise:</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select disabled [items]="mainService.codeValueTechObj['HR0042']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['fix_is_var']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fix_is_var">
                                    </ng-select>
                                </div>
                            </div>
                            <br *ngIf="obj['rate_type']=='FIX' && obj['fix_is_var']==0">
                            <div *ngIf="obj['rate_type']=='FIX' && obj['fix_is_var']==0"  class="row">
                                <div class="col-4 text-right">
                                    <h6>Amount:</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="obj['amount']" name="amount">
                                </div>
                            </div>

                            <br  *ngIf="obj['rate_type']=='CAL'" >
                            <div  *ngIf="obj['rate_type']=='CAL'" class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Function Name:</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="obj['function_name']" name="function_name">
                                </div>
                            </div>
                            <br>
                            <hr>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6>Payable / Deduction:</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0022']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['pay_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="pay_type_code">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-right">
                                    <h6>Effective Date:</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" [(ngModel)]="obj['effective_dt']" name="effective_date">
                                </div>
                            </div>
                            <br>
                            <!-- <div *ngIf="obj['rate_type']=='LIMIT'" class="row">
                                <div class="col-3 text-right">
                                    <h6>Enter Grade Pay Start:</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="number" [(ngModel)]="obj['lower_limit']" name="upper_limit">

                                </div>
                                <div class="col-3 text-right">
                                    <h6>Enter Grade Pay End:</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="number" [(ngModel)]="obj['upper_limit']" name="lower_limit">

                                </div>
                            </div> -->
                            <!-- <br>

                            <div *ngIf="obj['rate_type']=='LIMIT' " class="row">
                                <div class="col-3 text-right">
                                    <h6>Amount:</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="number" [(ngModel)]="obj['amount']" name="amount">
                                </div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="save()">Submit</button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active"  *ngIf="update_flag" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" style="border-radius: 20px;"  (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Component :</h6>
                                </div>
                                <div class="col-4 text-center">
                                    <input class="form-control" [(ngModel)]="obj['component_code']" disabled>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Component Description:</h6>
                                </div>
                                <div class="col-4 text-center">
                                    <input class="form-control" [(ngModel)]="obj['component_desc']">
                                </div>
                            </div>
                            <br> -->
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    Select Rule For:
                                </div>
                                <div class="col-4 text-center">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0021']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['component_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="component_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Is it Special Allowance:</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select disabled [items]="mainService.codeValueTechObj['HR0042']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['is_spe_allowd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="is_spe_allowd">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Rule Type:</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select disabled [items]="rateType" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['rate_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="rate_type">
                                    </ng-select>
                                </div>
                            </div>

                            <br>
                            <hr>


                            <br *ngIf="obj['rate_type']=='PERCENTAGE'">
                            <div *ngIf="obj['rate_type']=='PERCENTAGE'" class="row">
                                <div class="col-3 text-right">
                                    <h6>Percentage of:</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0021']" bindLabel="value" bindValue="code"  placeholder="" [(ngModel)]="obj['dependent_component']" [selectableGroup]="true" [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false" name="dependent_component">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-right">
                                    <h6>Enter Percentage:</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" [(ngModel)]="obj['amount']">
                                </div>
                            </div>



                            <br *ngIf="obj['rate_type']=='FIX'">
                            <div   *ngIf="obj['rate_type']=='FIX'" class="row">
                                <div class="col-4 text-right">
                                    <h6>Amount is variable Employee wise:</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select disabled [items]="mainService.codeValueTechObj['HR0042']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['fix_is_var']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fix_is_var">
                                    </ng-select>
                                </div>
                            </div>
                            <br *ngIf="obj['rate_type']=='FIX' && obj['fix_is_var']==0">
                            <div *ngIf="obj['rate_type']=='FIX' && obj['fix_is_var']==0"  class="row">
                                <div class="col-4 text-right">
                                    <h6>Amount:</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="obj['amount']" name="amount">
                                </div>
                            </div>

                            <br  *ngIf="obj['rate_type']=='CAL'" >
                            <div  *ngIf="obj['rate_type']=='CAL'" class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Function Name:</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="obj['function_name']" name="function_name" disabled>
                                </div>
                            </div>
                            <br>
                            <hr>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6>Payable / Deduction:</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select disabled [items]="mainService.codeValueTechObj['HR0022']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="obj['pay_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="pay_type_code">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-right">
                                    <h6>Effective Date:</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" [(ngModel)]="obj['effective_dt']" name="effective_date">
                                </div>
                            </div>
                            <br>
                            <!-- <div *ngIf="obj['rate_type']=='LIMIT'" class="row">
                                <div class="col-3 text-right">
                                    <h6>Enter Grade Pay Start:</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="number" [(ngModel)]="obj['lower_limit']" name="upper_limit">

                                </div>
                                <div class="col-3 text-right">
                                    <h6>Enter Grade Pay End:</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="number" [(ngModel)]="obj['upper_limit']" name="lower_limit">

                                </div>
                            </div> -->
                            <!-- <br>

                            <div *ngIf="obj['rate_type']=='LIMIT' " class="row">
                                <div class="col-3 text-right">
                                    <h6>Amount:</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="number" [(ngModel)]="obj['amount']" name="amount">
                                </div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>

                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
