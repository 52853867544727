<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Online Support</div>
                </div>
                <div class="ibox-body">
                    <br>
                    <br>
                    <div class="container">
                        <form id="contact" action="" method="post">
                            <h3>Quick Contact</h3>
                            <h4>Contact us today, and get reply with in 24 hours!</h4>
                            <fieldset>
                                <input placeholder="Subject" type="text" tabindex="1" [(ngModel)]="Subject" name="subject" required autofocus>
                            </fieldset>

                            <fieldset>
                                <textarea placeholder="Type your Message Here...." tabindex="5" [(ngModel)]="Message" name="meg" required></textarea>
                            </fieldset>
                            <fieldset>
                                <button name="submit" type="submit" id="contact-submit" class="float-right" (click)="Submit()">Submit</button>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>