import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { JoiningService } from '../../service/joining.service';
import { HrHierService } from '../../service/hr-hier.service';
import { from, empty } from 'rxjs';
import swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { EstablishmentService } from '../../service/establishment.service';
import { MainService as MD_mainService } from '../../../md/service/main.service';
import { RoleService as MD_RoleService } from '../../../md/service/role.service';
import { UserService as MD_UserService } from '../../../md/service/user.service';
import { MainService as portal_mainService } from '../../../portal/service/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import Swal from 'sweetalert2';
import { AllEmpService } from '../../service/all-emp.service';
import { DataAccessService } from '../../service/data-access.service';
declare var $: any;

@Component({
  selector: 'app-emplist',
  templateUrl: './emplist.component.html',
  styleUrls: ['./emplist.component.css']
})
export class EmplistComponent implements OnInit {
  Obj1: any = {};
  // displayedColumns = ['emp_id', 'emp_name', 'emp_dob', 'emp_email', 'emp_phone_no', 'emp_ass_fun', 'effective_timestamp'];
  displayedColumns = ['emp_id', 'emp_name', 'emp_dob', 'emp_email', 'emp_phone_no', 'emp_fun', 'cost_center', 'action'];
  datasource: any;
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }

  constructor(private dataAccessService: DataAccessService, private hrHierService: HrHierService, private establishmentService: EstablishmentService, private MD_UserService: MD_UserService, private MD_RoleService: MD_RoleService, public mainService: MainService, private portal_mainS: portal_mainService, private MD_mainService: MD_mainService, private joiningservice: JoiningService, private snackBar: MatSnackBar, private router: Router, private spinner: NgxSpinnerService, private allEmpService: AllEmpService) { }



  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;
  first: any
  second:any
  third: any
  fourth: any
  isLinear: any
  status: any = [{ code: "ACTIVE", value: "ACTIVE" }, { code: "INACTIVE", value: "INACTIVE" }];
  obj: any = {}

  joiningObj: any = {};
  empNameObj: any = {}
  addEstObj: any = {}
  probation: any = false;
  b_acct_id: any;
  erpUser: any;
  show_org_msg: any;
  user_id: any
  emp_id: any = ''
  AllCCRow: any = []
  allEmplyees: any = [];
  allEmplyees_new: any = []
  modobj: any = {}

  ////////////////////////princy//////////////////////////////////
  CC: any = []

  cost_center: any = [];
  cost_centerOnPRI: any = [];
  ///////////////////////////////////////////////////
  emp_status: any = 'ACTIVE';

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;

    await this.getCostCenter();
    await this.getAccountProjectHier();
    await this.ccData();
    await this.getAllProduct();
    await this.getAllBudget();


    await this.getAllSystemRole();
    await this.getAllAccountModule();
    await this.getListOfAllEmployee();
    // await this.changeEmployee();
    await this.AllEmployeeAssigedPost();
    await this.getEmployeeFunctionArea();


    this.emp_status = 'ACTIVE';

    console.log(this.mainService.codeValueTechObj['HR0056'])
  }

  empIdAssignedModule: any = {};
  allEmpAssignedModule: any = []
  async getEmployeeFunctionArea() {
    console.log("All Emp Function Area")
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.joiningservice.getEmployeeFunctionArea(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allEmpAssignedModule = resp.data;
      this.empIdAssignedModule = {};

      var data = resp.data;

      for (let i = 0; i < data.length; i++) {
        if (data[i]['role_cd'] == null || data[i]['role_cd'] == undefined) {

        } else {
          var dd = data[i]['role_cd'].split(",");
          for (let j = 0; j < dd.length; j++) {
            if (this.empIdAssignedModule[data[i]['arr_id']] == undefined) {
              this.empIdAssignedModule[data[i]['arr_id']] = this.moduleObj[this.roleToModule[dd[j]]]
            } else {
              this.empIdAssignedModule[data[i]['arr_id']] = this.empIdAssignedModule[data[i]['arr_id']] + "," + this.moduleObj[this.roleToModule[dd[j]]]
            }
          }
        }

      }

    } else {
      swal.fire('Error', 'Some Error Occured', 'error')
    }

    console.log(this.empIdAssignedModule)

  }



  ///****************************Select Node*********************************************** */
  planing_arrproj: any = []
  projecthier: any = []
  projhierobj: any = {}
  projhierobjtype: any = {}
  projhierobjtypecd: any = {}
  projhierobjisleaf: any = {}

  allProject: any = [];

  async getAccountProjectHier() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAccountProjectHier(obj);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'HR-root'
        }
      }
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {

        var tt = []
        for (let k = 1; k < 8; k++) {
          if (resp.data[i]['lvl' + k + '_cd'] != null) {
            tt.push(resp.data[i]['lvl' + k + '_cd'])
          }
        }
        this.dataAccessService.data_assigment = this.dataAccessService.data_assigment.filter((x: any) => x.user_id == this.user_id)
        var exit_flag = false;
        for (let j = 0; j < this.dataAccessService.data_assigment.length; j++) {
          if (tt.includes(parseInt(this.dataAccessService.data_assigment[j]['node_cd']))) {
            exit_flag = true;
          }
        }

        if (tt.length == 1) {
          exit_flag = true;
        }
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR"))
          && (resp.data[i]['module_cd'] != null || resp.data[i]['module_cd'] != undefined) && exit_flag) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;
      this.allProject = resp.data;
      this.projecthier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_user_cd']
          this.projhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']
        }
      }
    } else {
      console.log("Error in getting all project hier")
    }
  }

  open_activitypopup() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1)
    }

    console.log("Open Pop Up")
    $('#ACTHIER').modal('show');
  }


  down(item: any, k: any) {
    console.log(k)
    console.log(item)
    console.log(this.planing_arrproj)
    let patharr = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      console.log(this.planing_arrproj[i])

      patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])


    }
    this.Obj['path_desc'] = patharr.join("-->")
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [] })
    console.log(this.planing_arrproj, item)
    let arr: any = []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_cd'] != null) {

        if ((this.projecthier[i]['lvl' + (k + 1) + '_cd'] == item['hierarchy_type'] || k == -1) && this.projecthier[i]['leaf_node_type'] != 'HREMP') {

          if (k == 0) {
            arr = []
            for (let q = 0; q < this.joiningObj['module_cd'].length; q++) {
              if ((this.joiningObj['module_cd'][q] == this.projecthier[i]['module_cd']) && (this.projecthier[i]['lvl2_cd'] == this.projecthier[i]['leaf_cd'])) {
                console.log(this.projecthier[i])
                this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'] })
                arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
              }
            }
          }
          else {
            this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'] })
            arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
          }
        }
      }
    }
  }

  remove(item: any, i: any) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i])

        patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
      }
      this.Obj['path_desc'] = patharr.join("-->")
    }

  }


  async open_update(element: any) {
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
    this.joiningObj = Object.assign({}, element)
    console.log(element);
    this.joiningObj['old_emp_id'] = this.joiningObj['emp_id'];

    // this.joiningObj['module_cd'] = [];
    // this.joiningObj['old_module_cd'] = [];
    // this.joiningObj['old_module_cd_data'] = [];
    // for (let i = 0; i < this.allEmpPost.length; i++) {
    //   if (this.allEmpPost[i]['arr_id'] == element['arr_id']) {
    //     this.joiningObj['module_cd'].push(this.allEmpPost[i]['module_cd'])
    //     this.joiningObj['old_module_cd'].push(this.allEmpPost[i]['module_cd'])
    //     this.joiningObj['old_module_cd_data'].push(this.allEmpPost[i])
    //   }
    // }
    // let data = {}
    // for (let i = 0; i < this.projecthier.length; i++) {
    //   if (this.projecthier[i]['leaf_user_cd'] == 'HRARR' + element['arr_id']) {
    //     data = this.projecthier[i]
    //     break
    //   }
    // }
    // console.log(data)
    // this.planing_arrproj = []
    // for (let i = 1; i < 8; i++) {
    //   console.log(data['lvl' + (i) + '_user_cd'])
    //   if (data['lvl' + (i) + '_user_cd'] != null && data['leaf_user_cd'] != data['lvl' + (i) + '_user_cd']) {
    //     if (i == 1) {
    //       this.down({ hierarchy_type: data['lvl' + (i) + '_cd'] }, -1)
    //       this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] = data['lvl' + (i) + '_cd']
    //       console.log(this.planing_arrproj)
    //     } else {
    //       this.down({ hierarchy_type: data['lvl' + (i - 1) + '_cd'] }, i - 2)
    //       this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] = data['lvl' + (i) + '_cd']
    //       this.setHierarchyNodeitem(this.planing_arrproj[this.planing_arrproj.length - 1], i - 1)
    //     }
    //   }
    //   this.Obj['node_type'] = data['leaf_node_type']
    //   this.Obj['node_cd'] = data['leaf_user_cd']
    //   this.Obj['node_desc'] = data['leaf_value']
    // }
    // this.Obj['old_path_desc'] = this.Obj['path_desc']

    console.log(this.joiningObj)
  }
  check: any
  setHierarchyNodeitem(item: any, i: any) {
    console.log(item, i);
    this.check = item['hierarchy_type']
    console.log(this.check);
    item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']]
    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }
    this.Obj['path_desc'] = dummy2.join(' --> ');

  }

  async updateprojhier() {
    let obb: any = new Object()
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]['leaf_user_cd'] == 'HRARR' + this.joiningObj['arr_id']) {
        obb['id'] = this.projecthier[i]['id']
      }
    }
    let level = 0
    obb['b_acct_id'] = this.b_acct_id
    console.log(this.planing_arrproj)
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      console.log(this.planing_arrproj[i])
      obb['lvl' + this.planing_arrproj[i]['level'] + '_cd'] = this.planing_arrproj[i]['hierarchy_type']
      if (this.planing_arrproj[i]['level'] == 1) obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = 'All Projects'
      else obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = this.projhierobj[this.planing_arrproj[i]['hierarchy_type']]
      obb['lvl' + this.planing_arrproj[i]['level'] + '_node_type'] = this.projhierobjtype[this.planing_arrproj[i]['hierarchy_type']]
      obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'] = this.projhierobjtypecd[this.planing_arrproj[i]['hierarchy_type']]
      console.log(this.planing_arrproj[i]['level'], obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'])
      level = this.planing_arrproj[i]['level']

    }
    obb['lvl' + (level + 1) + '_user_cd'] = 'HRARR' + this.joiningObj['arr_id']
    obb['lvl' + (level + 1) + '_cd'] = this.joiningObj['node_cd']
    obb['leaf_cd'] = this.joiningObj['node_cd']
    obb['lvl' + (level + 1) + '_node_type'] = 'HREMP'
    obb['lvl' + (level + 1) + '_value'] = this.joiningObj['emp_name']
    obb['lvl' + (level + 1) + '_type'] = 'text'
    obb['leaf_user_cd'] = 'HRARR' + this.joiningObj['arr_id']
    obb['leaf_node_type'] = 'HREMP'
    obb['leaf_value'] = this.joiningObj['emp_name']
    obb['leaf_type'] = 'text'
    obb['module_cd'] = 'HR'
    console.log(obb);
    await this.update();

    // this.spinner.show()
    // var resp = await this.allEmpService.projectupdateHierarchy(obb);
    // if (resp['error'] == false) {
    //   await this.update();
    //   this.spinner.hide();
    // }
    // else {
    //   this.spinner.hide();
    //   Swal.fire('Error...', resp['data'], 'error');
    // }
  }

  async updateEmployeeID() {
    if (this.joiningObj['emp_id'] == null || this.joiningObj['emp_id'] == undefined || this.joiningObj['emp_id'] == ' ' || this.joiningObj['emp_id'].length == 0) {
      swal.fire('Warning...!', 'Emp-ID Should Not Be Null!', 'warning');
      return;
    }
    this.spinner.show();
    this.joiningObj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.updateEmployeeId(this.joiningObj);
    if (resp['error'] == false) {
      await this.getListOfAllEmployee();
      this.open_list();
      this.spinner.hide();
      swal.fire('Success', 'Employee ID Updated..!', 'success')
    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', resp['data'], 'error');
    }
  }


  Obj: any = {}
  submit_act() {
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']);
    if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
      Swal.fire("Warning", "Please Select at least two level", 'warning')
    }
    else {

      $('#ACTHIER').modal('hide');
    }
    this.joiningObj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']
  }

  //update posting of employee
  async update() {
    // this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.joiningObj['emp_id'];
    obj['le_id'] = this.joiningObj['le_id'];
    obj['arr_id'] = this.joiningObj['arr_id'];


    var role = ['HR_EMP'];
    var post_data = [];


    var temp_module
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.planing_arrproj[1]['hierarchy_type'] == this.projecthier[i]['leaf_cd']) {
        temp_module = this.projecthier[i]['module_cd']
        post_data.push({ cost_center: this.projecthier[i]['leaf_user_cd'], module_cd: this.projecthier[i]['module_cd'], is_primary: 1 })
      }
    }

    for (let i = 0; i < this.joiningObj['module_cd'].length; i++) {
      if (temp_module != this.joiningObj['module_cd'][i]) {
        post_data.push({ cost_center: 'NA', module_cd: this.joiningObj['module_cd'][i], is_primary: 0 })
      }
      if (role.includes(this.moduleToRole[this.joiningObj['module_cd'][i]])) {
      } else {
        role.push(this.moduleToRole[this.joiningObj['module_cd'][i]])
      }
    }

    // post_data.push({ cost_center: this.joiningObj['cost_center'], module_cd: this.joiningObj['module_cd'], is_primary: 1 })
    // for (let i = 0; i < post_data.length; i++) {
    //   post_data[i]['cost_center_desc'] = this.ccCodeToDesc[post_data[i]['cost_center']]
    //   if (role.includes(this.moduleToRole[post_data[i]['module_cd']])) {
    //   } else {
    //     role.push(this.moduleToRole[post_data[i]['module_cd']])
    //   }
    // }

    obj['role_data'] = role;
    obj['posting_data'] = post_data;
    console.log(obj);

    // var resp = await this.establishmentService.updateEmpPosting(obj);
    // if (resp['error'] == false) {
    //   setTimeout(async () => {
    //     await this.getListOfAllEmployee();
    //     await this.AllEmployeeAssigedPost();
    //     // await this.changeEmployee();
    //     await this.open_list();
    //     this.spinner.hide()
    //     swal.fire('Success', 'Assignment Detail Updated..!', 'success')
    //   }, 1000);

    // } else {
    //   this.spinner.hide()
    //   swal.fire('Error', 'Some Error Occured', 'error')
    // }
  }

  selectedModule(module_cd: any) {
    console.log(module_cd)
    this.planing_arrproj = [];
    this.Obj = {}
  }

  ///****************************Select Node*********************************************** */

  allBudget: any = []
  async getAllBudget() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.allEmpService.getAllBugdet(obj);
    console.log(resp);
    if (resp['error'] == false) {
      this.allBudget = resp.data;
    }
  }

  allProduct: any = []
  async getAllProduct() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.allEmpService.getAllProduct(obj);
    console.log(resp);
    if (resp['error'] == false) {
      this.allProduct = resp.data;
    }
  }

  async getCostCenter() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getCostCenter(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.CC = [];
      for (let i = 0; i < resp.data.length; i++) {
        var obj: any = new Object();
        obj['module_code'] = resp.data[i]['cc_func']
        obj['cost_center_code'] = resp.data[i]['cc_code']
        obj['cost_center_desc'] = resp.data[i]['cc_name']
        this.CC.push(obj)
      }

    } else {

    }
  }



  empIdPost: any = {};
  allEmpPost: any = []
  async AllEmployeeAssigedPost() {
    console.log("All Emp Posting")
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.establishmentService.getCurrentlyAssignedPost(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allEmpPost = resp.data;
      this.empIdPost = {};

      var data = resp.data;
      for (let i = 0; i < data.length; i++) {
        if ((data[i]['posting_type']) == 1) {
          this.empIdPost[data[i]['arr_id']] = data[i]['module_cd'] + "-" + data[i]['cost_center']
        }
      }
      for (let i = 0; i < data.length; i++) {
        if ((data[i]['posting_type']) != 1) {
          this.empIdPost[data[i]['arr_id']] = this.empIdPost[data[i]['arr_id']] + ",(" + data[i]['module_cd']/*  + "-" + data[i]['cost_center_desc'] */ + ")"
        }
      }
    } else {
      swal.fire('Error', 'Some Error Occured', 'error')
    }

    console.log(this.empIdPost)
  }

  ccCodeToDesc: any = {};
  async ccData() {
    for (let i = 0; i < this.CC.length; i++) {
      this.ccCodeToDesc[this.CC[i]['cost_center_code']] = this.CC[i]['cost_center_desc']
    }
    console.log(this.ccCodeToDesc)
  }


  allRole: any = []
  allModuleRole: any = []
  moduleToRole: any = {};
  roleToModule: any = {};

  async getAllSystemRole() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_RoleService.getCurrentMdrole(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['is_system_role'] == 1) {
          this.allModuleRole.push(resp.data[i])
          this.moduleToRole[resp.data[i]['module_cd']] = resp.data[i]['role_cd']
          this.roleToModule[resp.data[i]['role_cd']] = resp.data[i]['module_cd']

        }
      }
    } else {
      swal.fire('Error', 'Some Error Occured', 'error')
    }

    console.log(this.allModuleRole)
  }


  async getListOfAllEmployee() {
    console.log("All Emoplyee")
    this.spinner.show();
    this.allEmplyees = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAllPartyFields(JSON.stringify(obj));
    if (resp['error'] == false) {
      var arr = resp.data;
      var assignedEmp = this.dataAccessService.assignedEmp;
      console.log(assignedEmp)
      for (let i = 0; i < arr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, arr[i]);
        if (assignedEmp[arr[i]['arr_id']] != undefined) {
          this.allEmplyees.push(obj)
        }
      }


      console.log(this.allEmplyees)
      this.datasource = new MatTableDataSource(this.allEmplyees)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide()
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Some Error Occured', 'error')
    }
  }


  async changeEmployee() {
    // var emp_data = [];
    // for (let i = 0; i < this.allEmplyees.length; i++) {
    //   if (this.allEmplyees[i]['emp_status'] == this.emp_status) {
    //     emp_data.push(this.allEmplyees[i]);
    //   }
    // }
    // console.log(emp_data)
    // this.datasource = new MatTableDataSource(emp_data)
    // this.datasource.paginator = this.paginator;
    // this.datasource.sort = this.sort;
  }

  allModule: any = [];
  moduleObj: any = {}
  async getAllAccountModule() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_mainService.getAccountModules(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allModule = resp.data;

      for (let i = 0; i < this.allModule.length; i++) {
        this.moduleObj[this.allModule[i]['module_cd']] = this.allModule[i]['module_name']
      }

      for (let i = 0; i < this.allModule.length; i++) {
        if (this.allModule[i]['module_cd'] == 'ENG') {
          this.allModule.splice(i, 1)
        }
      }
      console.log(this.moduleObj)
    } else {
      swal.fire('Error', 'Some Error Occured', 'error')
    }
  }


  ChangeModuleOnPrimary(module_cd: any) {
    this.joiningObj['cost_center'] = null
    this.cost_centerOnPRI = []
    for (let i = 0; i < this.CC.length; i++) {
      if (module_cd == this.CC[i]['module_code'] || module_cd == 'HR' || module_cd == 'ACCOUNT') {
        this.cost_centerOnPRI.push(this.CC[i])
      }
    }
  }

  ChangeModule(module_cd: any, i: any) {
    this.AllCCRow[i]['cost_center'] = null
    this.cost_center = []
    for (let i = 0; i < this.CC.length; i++) {
      if (module_cd == this.CC[i]['module_code'] || module_cd == 'HR' || module_cd == 'ACCOUNT') {
        this.cost_center.push(this.CC[i])
      }
    }
  }



  list_flag: any = true
  create_flag: any = false
  update_flag: any = false
  open_create() {
    this.avivalid = false;
    this.spanAvi = false;
    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
  }

  OldAllCCRow: any = [];




  async open_list() {
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    this.joiningObj = {};
    this.AllCCRow = [];
  }

  addRow() {
    let index = this.AllCCRow.length + 1
    this.AllCCRow.push({ row_id: index })
  }
  deleteRow(i: any) {
    for (let j = 0; j < this.AllCCRow.length; j++) {
      if (j > i) {
        this.AllCCRow[j]['row_id'] = this.AllCCRow[j]['row_id'] - 1
      }
    }
    this.AllCCRow.splice(i, 1);
  }




  data: any = []
  disable_flag: boolean = false;
  party_exit: boolean = false;

  exitingEmployee: any
  findEmployeeInAccount() {
    var get = false;
    if (isNaN(this.joiningObj['credential'])) {
      for (let i = 0; i < this.allEmplyees.length; i++) {
        if (this.allEmplyees[i]['emp_email'] == this.joiningObj['credential']) {
          get = true;
          this.exitingEmployee = this.allEmplyees[i]
          console.log(this.exitingEmployee);
          this.modobj = this.exitingEmployee

        }
      }
    } else {
      for (let i = 0; i < this.allEmplyees.length; i++) {

        if (this.allEmplyees[i]['emp_phone_no'] == this.joiningObj['credential']) {
          get = true;
          this.exitingEmployee = this.allEmplyees[i];
          console.log(this.exitingEmployee);
          this.modobj = this.exitingEmployee
        }
      }
    }

    console.log(get)
    return get;
  }
  stepperIndex: any = 0;
  dob_blank: any = true;
  async search() {
    if (this.joiningObj['credential'] == null || this.joiningObj['credential'] == undefined || this.joiningObj['credential'] == '') {
      Swal.fire("Warning", 'Mobile Number or Email can not be null', 'warning')
      return
    }
    if (isNaN(this.joiningObj['credential'])) {
      // var emailRegx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/im;
      var emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (!this.joiningObj['credential'].match(emailRegx)) {
        // if (emailRegx.test(this.joiningObj['credential'])){
        Swal.fire("Error", 'Invalid Email', 'error')
        return
      }
    } else {
      let mobRegx = /^[0-9]{10}$/;
      if (!mobRegx.test((this.joiningObj['credential']))) {
        Swal.fire("Error", 'Mobile Number in 10 digit', 'error')
        return
      }
    }

    this.disable_flag = false
    this.joiningObj['emp_name'] = null;
    this.joiningObj['emp_email'] = null;
    this.joiningObj['emp_phone_no'] = null;
    this.joiningObj['emp_dob'] = null;
    this.joiningObj['le_id'] = null;
    console.log(this.joiningObj['credential'])
    if (await this.findEmployeeInAccount()) {
      this.spanAvi = true
      $('#myModal1').modal('show');
      return;
    }


    this.joiningObj['b_acct_id'] = this.b_acct_id
    console.log(isNaN(this.joiningObj['credential']))

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    if (isNaN(this.joiningObj['credential'])) {
      obj['contact_email'] = this.joiningObj['credential']
      this.joiningObj['emp_email'] = this.joiningObj['credential']
    } else {
      obj['phone_no'] = this.joiningObj['credential']
      this.joiningObj['emp_phone_no'] = this.joiningObj['credential']
    }

    console.log(this.joiningObj)
    if (!this.joiningObj['credential']) {
      swal.fire('Error', 'Please Enter Valid Credential', 'error')
    } else {
      this.spinner.show()
      var resp = await this.MD_mainService.getCurrentLegalEntity(JSON.stringify(obj));
      console.log(resp)
      if (resp['error'] == false) {
        this.spinner.hide();
        this.data = resp['data']
        if (resp['data'].length > 0) {

          if (resp['data'][0]['party_type'] == 'ORG') {
            this.spinner.hide();
            swal.fire('Error', 'Please Enter Valid Legal Entity Not Org..', 'error');
            return;

          }

          // this.avivalid=true;
          this.spanAvi = true;
          this.avivalid = true;
          this.disable_flag = true;
          this.party_exit = true;
          this.joiningObj['emp_name'] = resp['data'][0]['party_name']
          this.joiningObj['emp_email'] = resp['data'][0]['contact_email']
          this.joiningObj['emp_phone_no'] = resp['data'][0]['phone_no']
          this.joiningObj['le_id'] = resp['data'][0]['le_id'];
          this.joiningObj['le_id'] = resp['data'][0]['le_id'];
          if (resp['data'][0]['party_dob'] != null) {
            this.dob_blank = false;
            this.joiningObj['emp_dob'] = resp['data'][0]['party_dob'].split('T')[0];
          } else {
            this.dob_blank = true;

          }
          this.joiningObj['new_entity'] = false;
          this.joiningObj['ident_verify_secret'] = 'User@1';
          Swal.fire('Warning...', 'A person with searched email/phone number is already available. Following are the details for this person. If you want to add this person as employee then you can continue by clcking "next". If this is not the person that you wanted to add then please click "Back" and search using different phone and/or email.', 'warning')

        } else {
          this.party_exit = false;
          this.dob_blank = true;

          this.joiningObj['new_entity'] = true;
          this.joiningObj['ident_verify_secret'] = 'User@1';
          this.joiningObj['party_type'] = "IND";
          this.spinner.hide()
          Swal.fire('Info...', 'No person found with searched email/phone in the system. Please fill the details to create identity for the employee that you want to add', 'info')
        }
      } else {
        this.spinner.hide()
        swal.fire('Error', 'Some Error Occured', 'error')
      }
    }
    this.stepper1.next();
    console.log(resp);
  }

  validate(email: any) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return (false);
    } else {
      return true
    }
  }
  errorMsg: any = ""
  underAgeValidate(j: any, d: any) {
    var dob = d.split('-');
    var year = parseInt(dob[0]);
    var month = parseInt(dob[1]) - 1;
    var day = parseInt(dob[2]);
    var today = new Date(j);
    var age = today.getFullYear() - year;
    if (today.getMonth() < month || (today.getMonth() == month && today.getDate() < day)) {
      age--;
    }
    return age;
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  async inactiveEmployee(element: any) {
    this.spinner.show()
    this.allEmplyees = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = element.emp_id;
    var resp = await this.allEmpService.inActiveEmployee(obj);
    if (resp['error'] == false) {
      setTimeout(async () => {
        await this.getListOfAllEmployee();
        // await this.changeEmployee();
        this.spinner.hide()
        Swal.fire('Success', 'Inactive Successfully', 'success')
      }, 1000);
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Some Error Occured', 'error')
    }
  }

  async activeEmployee(element: any) {
    this.spinner.show()
    this.allEmplyees = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = element.emp_id;
    obj['id'] = element.emp_line_id;
    var resp = await this.allEmpService.activeEmployee(obj);
    if (resp['error'] == false) {
      setTimeout(async () => {
        await this.getListOfAllEmployee();
        // await this.changeEmployee();
        this.spinner.hide()
        Swal.fire('Success', 'Active Successfully', 'success')
      }, 1000);
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Some Error Occured', 'error')
    }
  }

  async addJoining() {

    console.log(this.joiningObj)
    this.errorMsg = ''
    if (this.joiningObj['emp_phone_no'] == null || this.joiningObj['emp_phone_no'] == undefined || this.joiningObj['emp_phone_no'] == ""
      || this.joiningObj['emp_dob'] == null || this.joiningObj['emp_dob'] == undefined || this.joiningObj['emp_dob'] == ""
      || this.joiningObj['emp_name'] == null || this.joiningObj['emp_name'] == undefined || this.joiningObj['emp_name'] == ""
      || this.joiningObj['emp_email'] == null || this.joiningObj['emp_email'] == undefined || this.joiningObj['emp_email'] == ""
      || this.joiningObj['module_cd'] == null || this.joiningObj['module_cd'] == undefined || this.joiningObj['module_cd'] == ""
      || this.joiningObj['node_cd'] == null || this.joiningObj['node_cd'] == undefined || this.joiningObj['node_cd'] == ""
    ) {
      swal.fire("Warning", " * Fields Required.....!", 'warning');

    }
    else if (this.joiningObj['emp_phone_no'].toString().length != 10) {
      swal.fire("Info", "...Incorrect Phone Number....!", 'info');
    }

    else {
      swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.value) {
          this.finalAdd()
        }
      })
    }
  }

  getNumberFormat(num: any) {
    if (num != undefined) {
      return num.toString().padStart(3, "0")

    } else {
      return '000';
    }
  }


  async addAccountHier(final_obj: any, emp_name: any) {

    console.log(final_obj)
    console.log(emp_name)
    let level = 0
    let obb: any = new Object()
    obb['b_acct_id'] = this.b_acct_id

    for (let i = 0; i < this.planing_arrproj.length; i++) {
      console.log(this.planing_arrproj[i])
      //obb['lvl'+ this.planing_arrproj[i]['level']+'_user_cd'] = this.planing_arrproj[i]['level']
      if (this.planing_arrproj[i]['level'] == 1) obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = 'All Projects'

      obb['lvl' + this.planing_arrproj[i]['level'] + '_cd'] = this.planing_arrproj[i]['hierarchy_type']
      obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = this.projhierobj[this.planing_arrproj[i]['hierarchy_type']]
      obb['lvl' + this.planing_arrproj[i]['level'] + '_node_type'] = this.projhierobjtype[this.planing_arrproj[i]['hierarchy_type']]
      obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'] = this.projhierobjtypecd[this.planing_arrproj[i]['hierarchy_type']]
      console.log(this.planing_arrproj[i]['level'], obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'])
      level = this.planing_arrproj[i]['level']

    }

    obb['b_acct_id'] = this.erpUser.b_acct_id;
    obb['create_user_id'] = this.erpUser.user_id;

    obb['lvl' + (level + 1) + '_user_cd'] = 'HRARR' + final_obj['emp_arr_id']
    obb['lvl' + (level + 1) + '_node_type'] = 'HREMP' //obb['node_type']
    obb['lvl' + (level + 1) + '_value'] = emp_name
    obb['lvl' + (level + 1) + '_type'] = 'text'
    obb['leaf_user_cd'] = 'HRARR' + final_obj['emp_arr_id']
    obb['leaf_node_type'] = 'HREMP' //obb['node_type']
    obb['leaf_value'] = emp_name
    obb['leaf_type'] = 'text'
    obb['module_cd'] = 'HR'
    console.log(obb);

    var resp = await this.allEmpService.createAccountproject(obb);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp.data);
      console.log('Account Hier Project Added...')
      await this.UpdateNodeCode(resp.data, final_obj)
    } else {
      console.log('Error in Account Hier Project Added...')
      swal.fire("Sorry", resp.data, 'warning');

    }
  }
  async UpdateNodeCode(node_cd: any, final_obj: any) {
    let proObj: any = {};
    proObj['b_acct_id'] = this.b_acct_id
    proObj['id'] = final_obj['emp_line_id'];
    proObj['node_cd'] = node_cd;
    console.log(proObj);
    var resp = await this.joiningservice.updateNodeCode(proObj);
    if (resp['error'] == false) {
      console.log('Node Code Updated....')
      await this.addDataAssnigment(node_cd, final_obj['le_id'])
    } else {
      console.log('Error in Node Code Updated....')
    }
  }


  async addDataAssnigment(node_cd: any, le_id: any) {
    let proObj1: any = {};
    proObj1['b_acct_id'] = this.b_acct_id
    proObj1['user_id'] = le_id;
    proObj1['node_cd'] = node_cd;
    proObj1['status'] = 'ACTIVE';
    proObj1['create_user_id'] = this.erpUser.user_id;

    console.log(proObj1);
    var resp = await this.hrHierService.insertHrdataAssign(proObj1);
    if (resp['error'] == false) {
      console.log("Data Assigment Done...")
    } else {
      console.log("Error in Data Assigment Done...")
    }
  }


  async finalAdd() {
    this.spinner.show();
    console.log(this.joiningObj)
    var obj = Object.assign({}, this.joiningObj);
    obj['b_acct_id'] = this.b_acct_id;
    obj['create_user_id'] = this.user_id;
    obj['status'] = "ACTIVE";
    obj['life_cycle_status'] = "ACTIVE";
    obj['emp_status_code'] = 'JOINING';
    obj['user_id'] = obj['le_id'];
    var today = new Date();
    var ddt = this.joiningObj['emp_dob'];
    var yr = this.underAgeValidate(today, ddt);

    var role = ['HR_EMP'];
    var post_data = [];
    //set node
    obj['node_cd'] = this.joiningObj['node_cd'];
    //set product
    if (this.allProduct[0]['product_cd'] != undefined) {
      obj['product'] = this.allProduct[0]['product_cd'];
    } else {
      swal.fire("Sorry", "...Please Set A Product Before Adding any new Employee !", 'warning');
      this.spinner.hide();
      return;
    }
    //set budget
    if (this.allBudget[0]['budget_cd'] != undefined) {
      obj['budget'] = this.allBudget[0]['budget_cd'];
    } else {
      swal.fire("Sorry", "...Please Set A Budget Before Adding any new Employee !", 'warning');
      this.spinner.hide();
      return;
    }
    var temp_module
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.planing_arrproj[1]['hierarchy_type'] == this.projecthier[i]['leaf_cd']) {
        temp_module = this.projecthier[i]['module_cd']
        post_data.push({ cost_center: this.projecthier[i]['leaf_user_cd'], module_cd: this.projecthier[i]['module_cd'], is_primary: 1 })
      }
    }

    for (let i = 0; i < this.joiningObj['module_cd'].length; i++) {
      if (temp_module != this.joiningObj['module_cd'][i]) {
        // post_data.push({ cost_center: 'NA', module_cd: this.joiningObj['module_cd'][i], is_primary: 0 })
      }
      if (role.includes(this.moduleToRole[this.joiningObj['module_cd'][i]])) {
      } else {
        role.push(this.moduleToRole[this.joiningObj['module_cd'][i]])
      }
    }

    obj['role_data'] = role;
    obj['posting_data'] = post_data;
    obj["zone"] = this.joiningObj['zone']
    console.log(obj)

    if (yr >= 18) {
      console.log(obj)
      var resp = await this.joiningservice.insertEmployeeMasterData(obj);
      console.log(resp, 'qwertyo')
      if (resp['error'] == false) {

        setTimeout(async () => {
          this.joiningObj = {};
          await this.addAccountHier(resp.data, obj['emp_name'])
          // await this.getListOfAllEmployee();
          await this.dataAccessService.getCurrentDataAccess(this.b_acct_id, this.erpUser['user_id'])
          // await this.changeEmployee();
          await this.AllEmployeeAssigedPost();
          await this.open_list();
          await this.getListOfAllEmployee()
          this.spinner.hide();
          swal.fire("Success", "...and Joining Completed!", 'success');
        }, 1000);
      } else {

        this.spinner.hide();
        var str = resp.data;
        if (str.includes("Duplicate")) {
          swal.fire("Sorry", resp.data, 'warning');
        } else {
          swal.fire("Sorry", "...There looks some error, it  will be rectify soon!", 'warning');
        }

      }
    } else {
      this.spinner.hide();

      swal.fire("Sorry", "...You are below 18 years!", 'warning');
    }


  }


  //delete in active employee of employee

  async deleteEmployee(element: any) {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = element['emp_id'];
    obj['arr_id'] = element['arr_id'];
    obj['le_id'] = element['le_id'];
    console.log(obj)
    var resp = await this.allEmpService.deleteEmployee(JSON.stringify(obj));
    if (resp['error'] == false) {

      setTimeout(async () => {
        await this.getListOfAllEmployee();
        // await this.changeEmployee()
        this.spinner.hide()
        swal.fire('success', resp.data, 'success');
      }, 1000);

    } else {
      this.spinner.hide();
      swal.fire('Error', resp.data, 'error')
    }
  }



  avivalid: boolean = false
  spanAvi: boolean = false;
  gAvi: boolean = false;
  checkEmail(obj: any) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (obj.match(mailformat)) {


      let d = obj.split('.');
      if ((d != undefined || d != null) && (d[(d.length) - 1] == 'com')) {
        this.avivalid = true;
        this.spanAvi = true;
      }
    }
    else {
      this.avivalid = false;
      this.spanAvi = false;
    }
  }

  //   {
  // let c=0;
  //  let e=0;
  //     let d=obj.split('.');
  //     if((d!=undefined || d!=null)&&( d[(d.length)-1]=='com'))
  //     {
  //       let position= d[0].substring(d[0].length-6);
  //       if(position=="@gmail")
  //       {
  //      e=2;
  //     }
  //     }
  //   let b=obj.split('');
  //     b.map((x)=>{
  //       if(x=="." || x=="@")
  //         {
  //         ++c;
  //         }
  //           });

  //           if(c==2 && e==2)
  //           {
  //             this.avivalid=true;
  //             this.spanAvi=true;
  //           }

  //           else{

  //            this.spanAvi=false;
  //             this.avivalid=false;
  //           }

  //   }



}
