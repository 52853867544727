import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { LeaseService } from '../../service/lease.service';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import { PropCommonSService } from '../../service/prop-common-s.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
declare var $: any;

@Component({
  selector: 'app-lease-mutation',
  templateUrl: './lease-mutation.component.html',
  styleUrls: ['./lease-mutation.component.css']
})
export class LeaseMutationComponent implements OnInit {


  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  constructor(private spinner: NgxSpinnerService,
    private leaseservice: LeaseService,
    private propHierS: PropHierarchyService,
    private commonS: PropCommonSService,) { }

  ebillUser: any
  b_acct_id: any
  first_list: any = []
  level1: any = []
  level2: any = []
  level3: any = []
  level4: any = []
  level5: any = []
  level6: any = []
  level7 : any= []
  leaf_level : any= 0
  data: any = [{}]
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getLeasemutation()
    await this.getMutationFileNum()
    await this.getAllHier()
    await this.getAllLevls2()
    await this.getAllLevls()
  }
  dataSource: any;
  displayedColumns: string[] = ['lease_type', 'file_no', 'plot_no', 'lease_details', 'mutaion_plot_area', 'action'];
  Obj: any = {}
  list_flag: any = true
  create_flag : any= false
  allfile: any = []

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async getAllHier() {
    this.first_list = []
    var obj  : any= new  Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['buss_ent_type'] = 'Location'
    //console.log(obj)
    var resp = await this.propHierS.getLocationHierarchy(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.first_list = resp['data'].filter((x : any )=> { return x['hier_type'] == "Planing" });
      for (let i = 0 + 1; i <= 8; i++) {
        delete this.Obj['lvl' + i + '_cd'];
      }
      this.data = []
      this.data = [{}]
    } else {

    }
  }

  allHier : any = []
  hier_node_obj : any = {}
  async getAllLevls() {
    var obj  : any= new  Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['hier_type_id'] = this.Obj['hier_type_id']
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      // console.log(resp, ' data')
      this.allHier = resp.data;
      this.level1 = await this.commonS.refresh(this.allHier)
    } else {
    }

    this.Obj['hier_type_id'] = this.first_list[0]['hier_type_id']
  }
  async getAllLevls2() {
    var obj  : any= new  Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      //console.log(resp, ' data')
      let data = resp.data;
      for (let i = 0; i < data.length; i++) {
        this.hier_node_obj[data[i]['prop_hier_id']] = data[i]
      }
      // console.log(this.hier_node_obj, 'hier_node_obj')

    } else {
    }
  }

  setLeafNode() {
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        // this.objToSubmit['project_cd']=this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }


  allPlan_layout: any = []
  async getAllplanLayoutForOffer(data : any) {
    this.spinner.show()
    let obj : any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['group_type'] = "LEASELAYOUT"
    var resp = await this.leaseservice.getpropertygroup(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allPlan_layout = resp['data']
      // console.log(this.allPlan_layout)
      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['desc'] = 'Lease Layout ' + resp['data'][i]['leaf_value'] + ' Having Total Property - ' + resp['data'][i]['count']
        if (data['prop_hier_id'] == resp['data'][i]['prop_hier_id']) {
          this.Obj['property_lease_group_id'] = this.allPlan_layout[i]['property_lease_group_id']
        }
        // resp['data'][i]['desc'] = 'Plan Layout Number ' + resp['data'][i]['property_group_id'] + ' Having Propetry ' + ' - ' + resp['data'][i]['count']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data6', 'error')
    }
  }

  async setPreDefinedHierrarchy(data: any) {
    this.data = []
    let ob: any = this.hier_node_obj[data['prop_hier_id']]
    await this.commonS.refresh(this.allHier)
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        let index = j
        if (index == 1) {
          this.level2 = await this.commonS.onChangeLvl1(this.allHier, ob)
        } else if (index == 2) {
          this.level3 = await this.commonS.onChangeLvl2(this.allHier, ob)
        }
        else if (index == 3) {
          this.level4 = await this.commonS.onChangeLvl3(this.allHier, ob)
        }
        else if (index == 4) {
          this.level5 = await this.commonS.onChangeLvl4(this.allHier, ob)
        }
        else if (index == 5) {
          this.level6 = await this.commonS.onChangeLvl5(this.allHier, ob)
        }
        else if (index == 6) {
          this.level7 = await this.commonS.onChangeLvl6(this.allHier, ob)
        }
        this.data.push(ob)
      }
    }
    for (let i = 1; i <= 8; i++) {
      if (ob['lvl' + i + '_cd']) {
        this.Obj['lvl' + i + '_cd'] = ob['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
    // console.log(ob, this.first_list, this.allHier)
  }
  async getLeaseGroupdata(object: any) {
    let obj : any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['property_lease_group_id'] = object['property_lease_group_id']
    obj['status'] = 'ACTIVE'
    var resp = await this.leaseservice.getLeaseGroupdata(JSON.stringify(obj));
    if (resp['error'] == false) {
      let data = resp['data']
      // console.log(data)
      for (let i = 0; i < data.length; i++) {
        if (object['alloted_property_id'] == data[i]['property_id']) {
          this.Obj['lease_type'] = data[i]['property_type']
          this.Obj['plotno'] = data[i]['assigned_property_number']
          this.Obj['address'] = data[i]['address']
          this.Obj['areaOfPlot'] = data[i]['area']
        }
      }
      // console.log(this.Obj)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  create(){
    this.list_flag = false
    this.create_flag = true
  }
  goback(){
    this.list_flag = true
  this.create_flag = false
  }

  async getMutationFileNum(){
    this.spinner.show()
    let obj  : any= new  Object();
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.leaseservice.getMutationFileNum(JSON.stringify(obj))
    if (resp.error == false) {
      this.allfile = resp.data
      // console.log(this.allfile)
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Something Went Wrong!', 'error')

    }
  }

  async getAllData() {
    let data: any = {}
    this.allfile.map((x: any )=> {
      if (this.Obj['fileno'] == x['file_num']) {
        data['prop_hier_id'] = x['prop_hier_id']
        data['alloted_property_id'] = x['alloted_property_id']
        data['transferor'] = x['leasee_name']
        this.Obj['transferor'] = x['leasee_name']
        this.Obj['leasee_id'] = x['leasee_id']
        this.Obj['prop_hier_id'] = x['prop_hier_id']
        this.Obj['alloted_property_id'] = x['alloted_property_id']
        this.Obj['arr_id'] = x['arr_id']
      }
    })
    // console.log(data)
    await this.getAllplanLayoutForOffer(data)
    await this.setPreDefinedHierrarchy(data)
    data['property_lease_group_id'] = this.Obj['property_lease_group_id']
    // data['prop_hier_id'] = this.Obj['prop_hier_id']
    // data['alloted_property_id'] = this.Obj['alloted_property_id']
    await this.getLeaseGroupdata(data)
  }

  // View Button Code
  viewdata:any = []
  mutation:any = []
  async view(element: any){
    $("#viewDetails").modal("show");
    this.Mutation_data.map ((x: any) =>{
      if(x['file_num'] ==  element['file_num']){
        x['leasee_id'] = element['leasee_id']
      }
    })
    // console.log(element)
    this.spinner.show()
    let obj : any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['leasee_id'] = element['leasee_id']
    obj['file_num'] = element['file_num']
    var resp = await this.leaseservice.getMutationDetails(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      // console.log(resp)
      this.viewdata = resp.data[0]
      // this.mutation = this.Mutation_data[element.sno]
    }else {
      this.spinner.hide()
      Swal.fire('Error', 'Something Went Wrong!', 'error')

    }
  }

  // Submit Button code
  async createMutation(){
    if(!this.Obj['fileno']||
      !this.Obj['plotno']||
      !this.Obj['areaOfPlot']||
      !this.Obj['transferor']||
      !this.Obj['transfree']||
      !this.Obj['date']){
      Swal.fire("Inforamtion", "Please Select All * Fields", "info");
      return
    }
    this.Obj['life_cycle_status'] = 'ACTIVE'
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['user_id'] = this.ebillUser.user_id
    // console.log(this.Obj)
    var resp = await this.leaseservice.createMutation(this.Obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      // console.log(resp)
      Swal.fire('Success', 'Successfully Submitted', 'success')
      this.goback()
      this.getLeasemutation()
      this.Obj = {}
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  Mutation_data = []
  async getLeasemutation(){
    this.spinner.show()
    let obj : any = {}
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.leaseservice.getLeasemutation(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.Mutation_data = resp.data
      // console.log(this.Mutation_data)
      this.dataSource = new MatTableDataSource(this.Mutation_data);
      this.dataSource.sort = this.sort.toArray()[0];
      this.dataSource.paginator = this.paginator.toArray()[0];
    }else {
      this.spinner.hide()
      Swal.fire('Error', 'Something Went Wrong!', 'error')

    }
    // Serial Number for getting the index
    for (let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].sno = i;
    }

  }
  add_dropdown(item:any,i:any){

  }
  remove_dropdown(item:any,i:any){

  }

}
