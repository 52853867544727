import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AdvanceService } from '../../service/advance.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import Swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { LandInputService } from '../../service/land-input.service';
import { last, max } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { FileUploader } from 'ng2-file-upload';
declare var $: any;
import { AuthenticationService } from '../../../authentication.service';
import { SaleService } from '../../service/sale.service';
import { DocumentService } from '../../service/document.service';
import { MatStepper } from '@angular/material/stepper';
import { PropCostingService } from '../../service/prop-costing.service';
//
import { DefaulterService } from "../../service/defaulter.service";
import { DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'app-possession-rule',
  templateUrl: './possession-rule.component.html',
  styleUrls: ['./possession-rule.component.css']
})
export class PossessionRuleComponent implements OnInit {
 obj:any  = {}
  b_acct_id: any;
  pro_category: any;
  ebillUser: any;
  user_id: any;
  pro_type: any;

  constructor(private adv_ser : AdvanceService, public mainService: MainService) { }

  ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.user_id = this.ebillUser.le_id;
    console.log(this.b_acct_id);
    this.user_id = this.ebillUser.le_id;
    this.getAllPossessionRule()
  }

  selectedCar: any;
  displayedColumns: string[] = ['ID','Possession_cd', 'Property_Category', 'Property_Type', 'Min_Amount','Action'];
  dataSource: any;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  amount = [
      { id: 'Property_Cost', name: 'Property_Cost' },
      { id: 'Base_Cost', name: 'Base_Cost' },
      { id: 'Random_Cost', name: 'Random_Cost' }
  ];

  cal_fee_type = [
    { id: 'Day', name: 'Day' },
    { id: 'Month', name: 'Month' },

];


  async todayformatedDate(){
    let d = new Date()
    let day:any = d.getDate()
    if(day<10){
      day = "0"+day
    }

    let month:any = d.getMonth()+1
    if(month<10){
      month = "0"+month
    }
    return d.getFullYear()+"-"+month+"-"+day
  }


   async submit(){
     if( this.obj['possession_cd'] == null || this.obj['property_category'] == null || this.obj['property_type'] == null || this.obj['amount']==null|| this.obj['type']==null){
       Swal.fire('info', 'please select all the Mandatery fields', 'info')
       return
     }
     this.obj['b_acct_id']= this.b_acct_id
    this.obj['user_id'] = this.user_id
    this.obj['life_cycle_status'] ='ACTIVE'
    console.log(this.obj)
    var resp:any  = await this.adv_ser.createPossessionRule(this.obj);
   if (resp['error'] == false) {
    Swal.fire('Possession Rule Created', resp['data'], "success")
    this.getAllPossessionRule();
      }else{
        Swal.fire('Info', resp['data'], 'info')
      }

  }

  async getAllPossessionRule(){
    this.obj['b_acct_id']= this.b_acct_id;
    var resp:any  = await this.adv_ser.getAllPossessionRule(JSON.stringify(this.obj));
    if(resp['error']==false){
      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.sort = this.sort.toArray()[0];
      this.dataSource.paginator = this.paginator.toArray()[0];
      console.log(resp['data'])
    }else{
        return;
    }
  }

  async inactivePossessionRule(element:any ){
    this.obj['b_acct_id'] = this.b_acct_id;
    this.obj['user_id'] =   this.ebillUser.le_id;
    this.obj['possession_id'] = element.possession_id
    var resp = await this.adv_ser.inactivePossessionRule(this.obj);
    if(resp['error']==false){
      Swal.fire('Success', resp['data'], 'success')
     this.getAllPossessionRule()
    }else{
      console.log(resp['data'])
    }

  }


}
