import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { EmbHierService } from "../../../emb/service/emb-hier.service";
import { NgxSpinnerService } from "ngx-spinner";

import Swal from "sweetalert2";
import { Mainservice2Service } from "../../service/mainservice2.service";
declare var $: any;

@Component({
  selector: 'app-icms-hierachy',
  templateUrl: './icms-hierachy.component.html',
  styleUrls: ['./icms-hierachy.component.css']
})
export class IcmsHierachyComponent implements OnInit {
  list_flage = true;
  create_flage = false;
  update_flage = false;

  ebillUser:any;
  b_acct_id:any;
  Obj:any = {};
  ActiveNode:any = [];

  allRoles:any = [];
  allComp:any;
  selectedComp:any = [];
  dataSource:any;
  role_name:any;
  role_id:any;
  planing_arr :any= [{}];
  access :any= [];
  existing_heirarchy_nodes:any = [];
  displayedColumns:any = [
    "id",
    // "lvl1_user_cd",
    // "lvl1_node_type",
    // "lvl1_value",
    // "lvl2_user_cd",
    // "lvl2_node_type",
    // "lvl2_value",
    // "lvl3_user_cd",
    // "lvl3_node_type",
    // "lvl3_value",
    // "lvl4_user_cd",
    // "lvl4_node_type",
    // "lvl4_value",
    // "lvl5_user_cd",
    // "lvl5_node_type",
    // "lvl5_value",
    // "lvl6_user_cd",
    // "lvl6_node_type",
    // "lvl6_value",
    // "lvl7_user_cd",
    // "lvl7_node_type",
    // "lvl7_value",
    "is_leaf",
    "leaf_cd",
    'leaf_user_cd',
    "leaf_node_type",
    "leaf_value",
    "action",
  ];
  constructor(
    private embHierService: EmbHierService,
    public mainService: Mainservice2Service,
    private spinner: NgxSpinnerService
  ) {}

  allNodeType=[];
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem("erpUser")as any))
    console.log( this.ebillUser.b_acct_id)
    this.b_acct_id = this.ebillUser.b_acct_id;
    console.log(this.b_acct_id)
    await this.getcostcenters();
    await this.getProjectHierFromAccount();
    console.log(this.mainService);
    this.allNodeType=this.mainService.codeValueTechObj['ICMS002']
    console.log(this.allNodeType);

  }
  allcostcenters :any= [];
  // ***********************************Account Integration******************************************************************
  async getcostcenters() {
    let obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "ICMS";
    let resp = await this.embHierService.getCost(obj);
    console.log(resp);
    if (resp["error"] == false) {
      this.allcostcenters = [];
      console.log(resp["data"]);
      for (let i = 0; i < resp["data"].length; i++) {
        if (resp["data"][i]["cc_func"] == "ICMS") {
          let obb = Object.assign({}, resp["data"][i]);
          obb["node_cd"] = obb["cc_code"];
          obb["desc"] = obb["cc_code"] + "-" + obb["cc_name"];
          obb["node_desc"] = obb["desc"];
          this.allcostcenters.push(obb);
        }
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While Getting Cost Center", "error");
    }
  }
  projecthier:any = [];
  projhierobj:any = {};
  projhierobjtype:any = {};
  projhierobjtypecd:any = {};
  async getProjectHierFromAccount() {
    let obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "ICMS";
    let resp = await this.embHierService.getprojectHierarchy(obj);
    console.log(resp);
    for (let i = 0; i < resp.data.length; i++) {
      if (resp.data[i]["lvl1_cd"] == 1) {
        resp.data[i]["lvl1_value"] = "ICMS-root";
      }
    }
    if (resp["error"] == false) {
      this.projecthier = resp["data"];
      for (let i = 0; i < resp["data"].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp["data"][i]["lvl" + j + "_user_cd"]] =
            resp["data"][i]["lvl" + j + "_value"];
          this.projhierobjtype[resp["data"][i]["lvl" + j + "_user_cd"]] =
            resp["data"][i]["lvl" + j + "_node_type"];
          this.projhierobjtypecd[resp["data"][i]["lvl" + j + "_user_cd"]] =
            resp["data"][i]["lvl" + j + "_cd"];
        }
      }
      this.dataSource = new MatTableDataSource(resp["data"]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While Getting Cost Center", "error");
    }
  }
  down(item:any, k:any) {
    this.check = true;
    let patharr = [];
    for (let i = 0; i < this.planing_arr.length; i++) {
      patharr.push(this.projhierobj[this.planing_arr[i]["hierarchy_type"]]);
    }

    this.Obj["path_desc"] = patharr.join("-->");
    this.planing_arr.push({ level: k + 2, existing_heirarchy_nodes: [] });
    let arr:any = [];
    for (let i = 0; i < this.projecthier.length; i++) {
      if (
        !arr.includes(this.projecthier[i]["lvl" + (k + 2) + "_user_cd"]) &&
        this.projecthier[i]["lvl" + (k + 2) + "_user_cd"] != null &&
        this.projecthier[i]["lvl" + (k + 2) + "_node_type"] != "WORK" &&
        this.projecthier[i]["lvl" + (k + 2) + "_node_type"] != "Work"
      ) {
        if (
          (this.projecthier[i]["lvl" + (k + 1) + "_user_cd"] ==
            item["hierarchy_type"] ||
            k == -1) &&
          this.projecthier[i]["leaf_node_type"] != "WORK"
        ) {
          this.planing_arr[k + 1]["existing_heirarchy_nodes"].push({
            node_cd: this.projecthier[i]["lvl" + (k + 2) + "_user_cd"],
            desc:
              this.projecthier[i]["lvl" + (k + 2) + "_user_cd"] +
              " - " +
              this.projecthier[i]["lvl" + (k + 2) + "_value"] +
              " - " +
              this.projecthier[i]["lvl" + (k + 2) + "_node_type"],
          });
          arr.push(this.projecthier[i]["lvl" + (k + 2) + "_user_cd"]);
        }
      }
    }
  }
  remove(item:any, i:any) {
    if (this.planing_arr.length > 2) {
      this.planing_arr.pop();
      let patharr = [];
      for (let i = 0; i < this.planing_arr.length; i++) {
        patharr.push(this.projhierobj[this.planing_arr[i]["hierarchy_type"]]);
      }
      this.Obj["path_desc"] = patharr.join("-->");
    } else {
      Swal.fire("Oops!!!", "You Must Select Upto Coscenter", "warning");
    }
    if (
      this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"] != null ||
      this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"] !=
        undefined
    ) {
      this.check = false;
    }
  }
  check = true;

  async submithier() {
    let level = 0;
    this.Obj["b_acct_id"] = this.b_acct_id;
    for (let i = 0; i < this.planing_arr.length; i++) {
      this.Obj["lvl" + this.planing_arr[i]["level"] + "_user_cd"] =
        this.planing_arr[i]["hierarchy_type"];
      if (this.planing_arr[i]["level"] == 1)
        this.Obj["lvl" + this.planing_arr[i]["level"] + "_value"] =
          "All Projects";
      else
        this.Obj["lvl" + this.planing_arr[i]["level"] + "_value"] =
          this.projhierobj[this.planing_arr[i]["hierarchy_type"]];
      this.Obj["lvl" + this.planing_arr[i]["level"] + "_node_type"] =
        this.projhierobjtype[this.planing_arr[i]["hierarchy_type"]];
      this.Obj["lvl" + this.planing_arr[i]["level"] + "_cd"] =
        this.projhierobjtypecd[this.planing_arr[i]["hierarchy_type"]];
      console.log(
        this.planing_arr[i]["level"],
        this.Obj["lvl" + this.planing_arr[i]["level"] + "_user_cd"]
      );
      level = this.planing_arr[i]["level"];
    }
    this.Obj["lvl" + (level + 1) + "_user_cd"] = this.Obj["node_cd"];
    this.Obj["lvl" + (level + 1) + "_node_type"] = this.Obj["node_type"];
    this.Obj["lvl" + (level + 1) + "_value"] = this.Obj["node_desc"];
    this.Obj["lvl" + (level + 1) + "_type"] = "text";
    this.Obj["leaf_user_cd"] = this.Obj["node_cd"];
    this.Obj["leaf_node_type"] = this.Obj["node_type"];
    this.Obj["leaf_value"] = this.Obj["node_desc"];
    this.Obj["leaf_type"] = "text";
    this.Obj["module_cd"] = "ICMS";
    console.log(this.Obj);
    var resp = await this.embHierService.createProjectHierarchyOnAccount(
      this.Obj
    );
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getProjectHierFromAccount();
      await this.reset();
      Swal.fire("Success...", "Successfuly Created", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While Create A New Node..", "error");
    }
  }

  // ***********************************Account Integration******************************************************************
  setHierarchyNode(item:any, i:any) {
    if (this.planing_arr.length >= 2) {
      this.check = false;
    } else {
      this.check = true;
    }
    let dummy2 = [];
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arr[i]["hierarchy_type"]]);
    }
    this.Obj["path_desc"] = dummy2.join(" --> ");
  }
  show_msg() {
    Swal.fire(
      "Warning..",
      "This Action Can Not Be Perform For Update",
      "warning"
    );
  }
  used_nodes = [];
  selected_row:any = {};

  add_dropdown(data:any, index:any) {
    let flag = true;
    let obj = {};
    if (this.planing_arr.length >= 5) {
      return;
    }
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (
        data["hierarchy_type"] == this.existing_heirarchy_nodes[i]["node_cd"]
      ) {
        obj = Object.assign({}, this.existing_heirarchy_nodes[i]);
      }
    }
    this.selected_row = Object.assign({}, obj);
    this.selected_row["index"] = index;

    this.filter_data();
  }
  filter_data() {
    console.log(this.planing_arr, this.existing_heirarchy_nodes);

    let dummy = [];
    let max_level = 0;
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (this.existing_heirarchy_nodes[i]["level"] >= max_level) {
        max_level = this.existing_heirarchy_nodes[i]["level"];
      }
    }
    for (let i = 0; i < this.allcostcenters.length; i++) {
      if (!this.existing_heirarchy_nodes.includes(this.allcostcenters[i]))
        this.existing_heirarchy_nodes.push(this.allcostcenters[i]);
    }

    console.log(this.planing_arr, this.existing_heirarchy_nodes);
    console.log(max_level, this.selected_row, this.existing_heirarchy_nodes);
    for (let j = 1; j <= max_level; j++) {
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        let node_arr = [];
        node_arr = this.existing_heirarchy_nodes[i]["path"].split("|");
        if (node_arr.includes(this.selected_row["node_cd"])) {
          if (
            this.existing_heirarchy_nodes[i]["level"] == j &&
            this.existing_heirarchy_nodes[i]["level"] >
              this.selected_row["level"]
          ) {
            dummy.push(this.existing_heirarchy_nodes[i]);
          }
        }
      }
      if (dummy.length > 0) {
        break;
      }
    }
    this.planing_arr.push({});
    this.planing_arr[this.selected_row["index"] + 1][
      "existing_heirarchy_nodes"
    ] = [];
    this.planing_arr[this.selected_row["index"] + 1][
      "existing_heirarchy_nodes"
    ] = dummy;
    console.log(dummy, "dummy", this.planing_arr);
  }
  remove_dropdown(data:any, i:any) {
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop();
    }
    let dummy = [];
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy.push(this.planing_arr[i]["hierarchy_type"]);
    }
    let dummy2 = [];
    if (dummy.length > 0) {
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dummy[i] == this.existing_heirarchy_nodes[j]["node_cd"]) {
            dummy2.push(this.existing_heirarchy_nodes[j]["node_desc"]);
          }
        }
      }
    }
    this.Obj["path_desc"] = dummy2.join(" --> ");
  }
  NodeCodeToNodeDesc:any = {};

  async getAllHeirNodeList() {
    this.spinner.show();
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    console.log(obj);
    var resp = await this.embHierService.getAllEmbHierarchy(
      JSON.stringify(obj)
    );
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      console.log(resp["data"]);
      this.ActiveNode = [];
      this.existing_heirarchy_nodes = [];
      this.NodeCodeToNodeDesc = {};
      for (let i = 0; i < resp.data.length; i++) {
        if (
          resp.data[i]["status"] == "ACTIVE" &&
          resp.data[i]["is_leaf"] == 0
        ) {
          if (!resp["data"][i]["node_type"]) {
            resp["data"][i]["desc"] = resp["data"][i]["node_desc"];
          } else {
            resp["data"][i]["desc"] =
              resp["data"][i]["node_desc"] +
              " - (" +
              this.mainService.codeValueShowObj["ICMS002"][
                resp["data"][i]["node_type"]
              ] +
              ")";
          }
          this.ActiveNode.push(resp.data[i]);
          this.existing_heirarchy_nodes.push(resp["data"][i]);
        }
        this.NodeCodeToNodeDesc[resp["data"][i]["node_cd"]] = resp["data"][i];
      }

      for (let i = 0; i < this.allcostcenters.length; i++) {
        this.allcostcenters[i]["level"] = 2;
        this.allcostcenters[i]["is_leaf"] = 0;
        this.allcostcenters[i]["node_type"] = "CC";
        this.allcostcenters[i]["path"] =
          "ICMS|" + this.allcostcenters[i]["node_cd"];

        this.NodeCodeToNodeDesc[this.allcostcenters[i]["node_cd"]] =
          this.allcostcenters[i];
      }
      let dummy = [];
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        if (1 == this.existing_heirarchy_nodes[i]["level"]) {
          dummy.push(this.existing_heirarchy_nodes[i]);
        }
      }
      this.planing_arr[0]["existing_heirarchy_nodes"] = dummy;
      console.log(this.ActiveNode);
      this.dataSource = new MatTableDataSource(resp["data"]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While ICMS HIER", "error");
    }
  }
  async gotolist() {
    this.list_flage = true;
    this.create_flage = false;
    this.update_flage = false;
    await this.getProjectHierFromAccount();
  }
  createnode() {
    this.reset();
    this.list_flage = false;
    this.create_flage = true;
    this.update_flage = false;
  }

  ParentNodeChange() {
    console.log(this.Obj);
    if (
      this.Obj["parent_node_cd"] != null ||
      this.Obj["parent_node_cd"] != undefined
    ) {
      var dd =
        this.NodeCodeToNodeDesc[this.Obj["parent_node_cd"]]["path"].split("|");
      var str = "";
      for (let i = 0; i < dd.length; i++) {
        str = str + this.NodeCodeToNodeDesc[dd[i]]["node_desc"] + " -> ";
      }
      this.Obj["path_desc"] = str;
    } else {
      this.Obj["path_desc"] = null;
    }
  }

  data_to_update = {};
  async open_update(data:any) {
    this.list_flage = false;
    this.create_flage = false;
    this.update_flage = true;

    this.planing_arr = [];
    for (let i = 1; i < 8; i++) {
      if (
        data["lvl" + i + "_user_cd"] != null &&
        data["leaf_user_cd"] != data["lvl" + i + "_user_cd"]
      ) {
        if (i == 1) {
          this.down({ hierarchy_type: data["lvl" + i + "_user_cd"] }, -1);
          this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"] =
            data["lvl" + i + "_user_cd"];
        } else {
          this.down(
            { hierarchy_type: data["lvl" + (i - 1) + "_user_cd"] },
            i - 2
          );
          this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"] =
            data["lvl" + i + "_user_cd"];
          this.setHierarchyNode(
            this.planing_arr[this.planing_arr.length - 1],
            i - 1
          );
        }
      }
      this.Obj["node_type"] = data["leaf_node_type"];
      this.Obj["node_cd"] = data["leaf_user_cd"];
      this.Obj["node_desc"] = data["leaf_value"];
    }
    $('.nav-tabs a[href="#tab-7-3"]').tab("show");
  }
  sortArrayOfObjects = (arr:any, key:any) => {
    return arr.sort((a:any, b:any) => {
      return a[key] - b[key];
    });
  };
  async inactive(element:any) {
    this.spinner.show();
    var obj = Object.assign({}, element);
    obj["b_acct_id"] = this.b_acct_id;
    obj["update_user_id"] = this.ebillUser.user_id;
    obj["status"] = "INACTIVE";
    console.log(obj);
    var resp = await this.embHierService.updateEmbHierarchyNode(obj);
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getAllHeirNodeList();
      Swal.fire(
        "Successfully Update",
        "Successfully Status Changed To Incative",
        "success"
      );
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While change status...", "error");
    }
  }
  async submit() {
    let parent_node_cd;
    let parent_node_cd_flag = false;
    let duplicate_flag = false;
    if (this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"]) {
      parent_node_cd =
        this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"];
    } else {
      parent_node_cd_flag = true;
    }
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (
        this.Obj["node_cd"] == this.existing_heirarchy_nodes[i]["node_cd"] &&
        this.existing_heirarchy_nodes[i]["status"] == "ACTIVE"
      ) {
        duplicate_flag = true;
      }
    }
    if (parent_node_cd_flag == true) {
      Swal.fire(
        "Error",
        "You Have Not Selected Node At The Last Level",
        "error"
      );
    } else if (duplicate_flag == true) {
      Swal.fire("Error", "This Node Code Is Already Exists", "error");
    } else if (!this.Obj["node_type"]) {
      Swal.fire("Warning...", "Please Enter All Fields Marked As *", "warning");
    } else if (!this.Obj["node_cd"]) {
      Swal.fire("Warning...", "Please Enter All Fields Marked As *", "warning");
    } else {
      this.Obj["parent_node_cd"] = parent_node_cd;
      this.Obj["b_acct_id"] = this.b_acct_id;
      this.Obj["create_user_id"] = this.ebillUser.user_id;
      this.Obj["is_leaf"] = 0;
      this.Obj["status"] = "ACTIVE";
      console.log(this.Obj, this.NodeCodeToNodeDesc);

      this.Obj["level"] = this.NodeCodeToNodeDesc[parent_node_cd]["level"] + 1;
      this.Obj["path"] =
        this.NodeCodeToNodeDesc[parent_node_cd]["path"] +
        "|" +
        this.Obj["node_cd"];
      console.log(this.Obj);
      this.spinner.show();
      var resp = await this.embHierService.insertEmbHierarchyNode(this.Obj);
      if (resp["error"] == false) {
        this.spinner.hide();
        await this.getAllHeirNodeList();
        Swal.fire("Success...", "Successfuly Created", "success");
      } else {
        this.spinner.hide();
        Swal.fire("Error...", "Error While Create A New Node..", "error");
      }
      this.gotolist();
    }
  }
  async update() {
    let level = 0;
    this.Obj["b_acct_id"] = this.b_acct_id;
    console.log(this.planing_arr);
    for (let i = 0; i < this.planing_arr.length; i++) {
      console.log(this.planing_arr[i]);
      this.Obj["lvl" + this.planing_arr[i]["level"] + "_user_cd"] =
        this.planing_arr[i]["hierarchy_type"];
      if (this.planing_arr[i]["level"] == 1)
        this.Obj["lvl" + this.planing_arr[i]["level"] + "_value"] =
          "All Projects";
      else
        this.Obj["lvl" + this.planing_arr[i]["level"] + "_value"] =
          this.projhierobj[this.planing_arr[i]["hierarchy_type"]];
      this.Obj["lvl" + this.planing_arr[i]["level"] + "_node_type"] =
        this.projhierobjtype[this.planing_arr[i]["hierarchy_type"]];
      this.Obj["lvl" + this.planing_arr[i]["level"] + "_cd"] =
        this.projhierobjtypecd[this.planing_arr[i]["hierarchy_type"]];
      console.log(
        this.planing_arr[i]["level"],
        this.Obj["lvl" + this.planing_arr[i]["level"] + "_user_cd"]
      );
      level = this.planing_arr[i]["level"];
    }
    this.Obj["lvl" + (level + 1) + "_user_cd"] = this.Obj["node_cd"];
    this.Obj["lvl" + (level + 1) + "_node_type"] = this.Obj["node_type"];
    this.Obj["lvl" + (level + 1) + "_value"] = this.Obj["node_desc"];
    this.Obj["lvl" + (level + 1) + "_type"] = "text";
    this.Obj["leaf_user_cd"] = this.Obj["node_cd"];
    this.Obj["leaf_node_type"] = this.Obj["node_type"];
    this.Obj["leaf_value"] = this.Obj["node_desc"];
    this.Obj["leaf_type"] = "text";
    this.Obj["module_cd"] = "ICMS";
    console.log(this.Obj);
    this.spinner.show();
    var resp = await this.embHierService.projectupdateHierarchy(this.Obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getAllHeirNodeList();
      Swal.fire("Success...", "Updated Successfully", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While Updating Node..", "error");
    }
  }

  reset() {
    this.Obj = {};
    this.planing_arr = [];
    this.down({}, -1);
  }


  async delete(element:any) {
    Swal.fire({
      title: "Are You Sure ?",
      text: "If You Delete This Node Then All Nodes Below This Node Will Be Deleted...!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element);
      }
    });

  }

  async finaldelete(element:any) {
    let obj:any = {};
    console.log(this.projecthier);
    let index = this.projecthier
      .map(function (e:any) {
        return e.leaf_cd;
      })
      .indexOf(element["leaf_cd"]);
    obj = Object.assign({}, this.projecthier[index]);
    obj["b_acct_id"] = this.b_acct_id;
    obj["update_user_id"] = this.ebillUser.user_id;
    obj["leaf_cd"] = element["leaf_cd"];
    this.spinner.show();
    var resp = await this.embHierService.deleteEMB_Hierarchy(obj);
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getProjectHierFromAccount();
      Swal.fire("Success", resp["data"], "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp["data"], "error");
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
