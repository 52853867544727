import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class VmsHierService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl ;
  }



  // async getAllEmbHierarchy(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/administration/embHier/getAllEmbHierarchy' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async getAllPropHierarchy(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/propHier/getAllpropHierarchy' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async insertEmbHierarchyNode(obj:any) {
  //   const resp = await this.http.post<any>(this.main.httpUrl + '/administration/embHier/insertEmbHierarchyNode' ,obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }

  // async updateEmbHierarchyNode(obj:any) {
  //   const resp = await this.http.put<any>(this.main.httpUrl + '/administration/embHier/updateEmbHierarchyNode' ,obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  ////iiiiiiiiii
  async deleteVMS_Hierarchy(obj:any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/project/deleteHierarchy' ,obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // async  getCost(obj:any) {
  //   const resp = await this.http.post<any>(this.main.httpUrl2 + '/accounts/costCenter/getCostCenter' , obj).toPromise().then(res => {
  //     return res
  //   });
  //   console.log(resp)
  //   return resp
  // }


  //iiiiiiiiiiiiii
  async  getBudgetHierarchy(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/budget/getHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  // async  getactHierarchy(obj:any) {
  //   const resp = this.http.post<any>(this.main.httpUrl2 + '/accounts/hierarchies/activity/getHierarchy',obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
  // async  projectIEupdateHierarchy(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl2 + '/accounts/hierarchies/project/updateIncEstHierarchy',obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }

  // /iiiiiiiiiiiii
  async  projectupdateHierarchy(obj:any) {
    const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/project/updateHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  ////////iiiiiiiiiiii
  async  createProjectHierarchyOnAccount(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl +'/accounts/hierarchies/project/createHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  ////iiiiiiiiiiiiii
  async  getprojectHierarchy(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/project/getHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async  getactHierarchy(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/activity/getHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  ///////iiiiiiiiiiiiiiiiiiii
  async  getproductHierarchy(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/product/getHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }


  //data assign



  async getAllVmsdataAssigned(obj:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/vms/administration/data-assignment/getAllVmsdataAssigned' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // async insertEmbdataAssign(obj:any) {
  //   const resp = await this.http.post<any>(this.main.httpUrl + '/administration/embdataAssign/insertEmbdataAssign' ,obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }

  // async updateEmbdataAssign(obj:any) {
  //   const resp = await this.http.put<any>(this.main.httpUrl + '/administration/embdataAssign/updateEmbdataAssign' ,obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }


  // /iiiiiiiii
  async getuserAssigneddata(obj:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/vms/administration/data-assignment/getuserAssigneddata'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}
