import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../../service/establishment.service';
import { MainService } from '../../service/main.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { getLocalePluralCase } from '@angular/common';
import Swal from 'sweetalert2';
import { AttendaceMangService } from '../../attendance_manag/attendace-mang.service';
declare var $: any
@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {

  selectedFile: any = null;
  isUpload: any;
  public imagePath: any;
  httpUrl: any;
  uploader: any;
  monthEnd: any = { '1': 31, '2': 28, '3': 31, '4': 30, '5': 31, '6': 30, '7': 31, '8': 31, '9': 30, '10': 31, '11': 30, '12': 31 }

  constructor(private sanitizer: DomSanitizer, public mainService: MainService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private establishmentService: EstablishmentService, public attendService: AttendaceMangService) { }

  erpUser: any;
  b_acct_id: any;
  allEmplyees: any = [];
  idToNameObj: any = {};
  codeValueTechObj: any = {};
  allAttendance: any = [];
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  @ViewChild('paginator2', { static: false }) paginator2!: MatPaginator;
  @ViewChild('sortCol2', { static: true }) sortCol2!: MatSort;
  displayedColumns = ['emp_id', 'emp_name', 'total_days', 'working_days', 'present_days', 'absent_days', 'leave_days', 'action'];
  datasource: any;
  // Avinash
  displayedColumns2: any = ['s.n', 'date', 'status', 'reason'];
  datasource2: any;

  attendanceObj: any = {};
  getObj: any = {};
  updateObj: any = {};
  newallEmplyees: any = []

  allEmp: any = [];
  employeeObj: any = {};
  allEmployees: any = [];
  monthobj: any = []
  async ngOnInit() {
    this.httpUrl = this.mainService.httpUrl;

    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'attendanceFile' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllEmployees();
    await this.getAllActiveEmployees()
    console.log(this.attendanceObj)
    this.monthobj = this.mainService.codeValueTechObj['HR0024']
    console.log(this.monthobj)
    //await this.getAllAttendance();
  }
  getNumberFormat(num: any) {
    return num.toString().padStart(3, "0")
  }


  allCurrentArrangements: any = [];
  activeEmpArr: any = [];
  // empNameMap
  async getAllActiveEmployees() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    // obj['emp_status_code'] = ['ACTIVE']
    var resp = await this.establishmentService.getArrayAllCurrentEstablishementInfo(obj);
    // this.empServiceDateMap=new Map;
    // this.empNameMap=new Map;
    if (resp['error'] == false) {
      this.allCurrentArrangements = resp['data'];
      this.allEmplyees = resp['data'];

      this.newallEmplyees = []
      this.activeEmpArr = [];
      for (let i = 0; i < this.allEmplyees.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, this.allEmplyees[i]);
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
        if (obj['emp_status_code'] == "ACTIVE") {
          this.activeEmpArr.push(obj);
        }
      }
      // this.activeEmpArr=[...new Set(this.activeEmpArr)]

      // for (let i = 0; i < this.allCurrentArrangements.length; i++) {
      //   this.empNameMap.set(this.allCurrentArrangements[i]['emp_id'], this.allCurrentArrangements[i]['emp_name'])
      //   this.empServiceDateMap.set(this.allCurrentArrangements[i]['emp_id'], this.allCurrentArrangements[i]['joining_service_date']);
      // }
    }
  }


  async getAllEmployees() {
    this.spinner.show()
    var arr = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.establishmentService.getEmployeeMasterData(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;

      for (let i = 0; i < arr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])
        this.allEmplyees.push(obj)
      }
      this.newallEmplyees = []
      for (let i = 0; i < resp.data.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, resp.data[i]);
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
      }
      console.log('thisis the newallEmployee', this.newallEmplyees)
      // this.spinner.show()
      // var obj1 = new Object();
      // obj1['b_acct_id'] = this.b_acct_id;
      // obj1['emp_status_code'] = ["ACTIVE", "INACTIVE"];
      // var resp = await this.establishmentService.getArrayAllCurrentEstablishementInfo(obj1);
      // if (resp['error'] == false) {
      //   this.spinner.hide()
      //   this.allEmployees = resp.data;


      // } else{
      //   this.spinner.hide()
      //   this.snackBar.open("Error while getting employee list" ,'Error',{
      //     duration:5000
      //   });
      // }
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  // checkDesignation(){
  //   console.log(this.attendanceObj);
  //   for(let i=0;i<this.allEmplyees.length;i++)
  //   {
  //     for(let j=0;j<this.allEmployees.length;j++)
  //     {
  //       if(this.allEmplyees[i]['emp_id'] == this.allEmployees[j]['emp_id'])
  //       {
  //         this.attendanceObj['section_code'] = this.allEmployees[j]['designation_code'];
  //         break;
  //       }
  //     }
  //   }
  // }
  calabsday() {
    if (this.attendanceObj['working_days'] >= this.attendanceObj['present_days']) {
      this.attendanceObj['absent_days'] = this.attendanceObj['working_days'] - this.attendanceObj['present_days']
      this.attendanceObj['leave_days'] = this.attendanceObj['absent_days']
      console.log(this.attendanceObj['absent_days'], "absent days", this.attendanceObj['working_days'], "working days", this.attendanceObj['present_days'], "present days")
    }
    else {
      Swal.fire("info", "Working days is less then present days", 'info')
      this.attendanceObj['leave_days'] = ''
      this.attendanceObj['absent_days'] = ''
    }
  }
  updcalabsday() {
    if (this.updateObj['working_days'] >= this.updateObj['present_days']) {
      this.updateObj['absent_days'] = this.updateObj['working_days'] - this.updateObj['present_days']
      this.updateObj['leave_days'] = this.updateObj['absent_days']
      console.log(this.updateObj['absent_days'], "absent days", this.updateObj['working_days'], "working days", this.updateObj['present_days'], "present days")
    }
    else {
      Swal.fire("info", "Working days is less then present days", 'info')
      this.updateObj['leave_days'] = ''
      this.updateObj['absent_days'] = ''
    }
  }
  async getAllAttendance() {
    if (this.getObj['year'] == null || this.getObj['month'] == null || this.getObj['section_code'] == null) {
      Swal.fire('Warning', "Firstly fill all required filled", 'warning')
      this.datasource = new MatTableDataSource()
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      return;
    }
    this.spinner.show()
    var arr = []
    var Attendance = []
    this.getObj['b_acct_id'] = this.b_acct_id;
    var resp = await this.establishmentService.gatAllAttendance(JSON.stringify(this.getObj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allAttendance = resp.data;
      arr = resp.data;
      for (let i = 0; i < arr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])
        Attendance.push(obj)
      }
      console.log(arr, "shweta attendance")
      console.log(Attendance, "shweta attendance")
      this.datasource = new MatTableDataSource(Attendance)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting attendance list", 'Error', {
        duration: 5000
      });
    }
  }
  getLeap(year:any) {
    //var year = this.attendanceObj['year'];
    var leap = false;
    if (year % 4 == 0) {
      if (year % 100 == 0) {
        // year is divisible by 400, hence the year is a leap year
        if (year % 400 == 0)
          leap = true;
        else
          leap = false;
      }
      else
        leap = true;
    }
    else
      leap = false;
    if (leap == true) {
      this.monthEnd[2] = 29;
    }


  }
  async getmonth() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.establishmentService.getallmonth(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log("working")
    }
  }
  empty() {
    this.attendanceObj['emp_id'] = ''
    this.attendanceObj['year'] = ''
    this.attendanceObj['month'] = ''
    this.attendanceObj['section_code'] = ''
    this.attendanceObj['working_days'] = ''
    this.attendanceObj['present_days'] = ''
    this.attendanceObj['absent_days'] = ''
    this.attendanceObj['leave_days'] = ''
  }
  async submitAttendanceInfo() {

    if (this.attendanceObj['emp_id'] == null || this.attendanceObj['year'] == null || this.attendanceObj['month'] == null || this.attendanceObj['section_code'] == null || this.attendanceObj['working_days'] == null ||
      this.attendanceObj['absent_days'] == null || this.attendanceObj['leave_days'] == null) {
      Swal.fire("Warning", "Firstly filled all required fields", 'warning')
      return;
    }
    this.spinner.show()
    var obj: any = new Object();
    await this.getmonth()
    obj['b_acct_id'] = this.b_acct_id;
    this.getLeap(parseInt(this.attendanceObj['year']));
    this.attendanceObj['total_days'] = this.monthEnd[this.attendanceObj['month']];
    this.attendanceObj['b_acct_id'] = this.b_acct_id;
    this.attendanceObj['create_user_id'] = this.erpUser.user_id;
    //this.attendanceObj['party_name'] = this.idToNameObj[this.attendanceObj['party_id']];
    obj['attendance_detail'] = [];
    obj['attendance_detail'].push(this.attendanceObj);
    var resp = await this.establishmentService.addAttendanceInfo(obj);
    if (resp['error'] == false) {
      this.total_p = false;
      this.spinner.hide();
      // await this.getAllAttendance();
      Swal.fire('Success', "Attendance Info Added", 'success');

      // this.snackBar.open("Attendance Info Added", 'Success', {
      //   duration: 5000
      // });
      this.empty()
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while Adding Attendance Info Of Employee', 'error')
      // this.snackBar.open("Error while Adding Attendance Info Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }
  openUpdate(element:any) {
    this.updateObj = Object.assign({}, element);
    // this.updateObj['year'] = parseInt(this.updateObj['year'])
    // this.updateObj['month'] = parseInt(this.updateObj['month'])
    this.updateObj['year'] = this.updateObj['year'].toString();
    this.updateObj['month'] = this.updateObj['month'].toString()
    $('.nav-tabs a[href="#tab-3"]').tab('show');
    console.log(this.updateObj, "update all data")
  }
  async updateAttendanceInfo() {

    if (this.updateObj['emp_id'] == null || this.updateObj['emp_id'] == undefined || this.updateObj['emp_id'] == ''
      || this.updateObj['section_code'] == null || this.updateObj['section_code'] == undefined || this.updateObj['section_code'] == ''
      || this.updateObj['absent_days'] == null || this.updateObj['absent_days'] == undefined || this.updateObj['absent_days'] == ''
      || this.updateObj['year'] == undefined || this.updateObj['year'] == null || this.updateObj['year'] == ''
      || this.updateObj['month'] == undefined || this.updateObj['month'] == null || this.updateObj['month'] == ''
      || this.updateObj['working_days'] == undefined || this.updateObj['working_days'] == null || this.updateObj['working_days'] == ''
      || this.updateObj['present_days'] == undefined || this.updateObj['present_days'] == null || this.updateObj['present_days'] == ''
      || this.updateObj['leave_days'] == undefined || this.updateObj['leave_days'] == null || this.updateObj['leave_days'] == ''
    ) {
      Swal.fire('Warning', '* Field Required', 'warning');
      return;
    }


    this.spinner.show()
    this.updateObj['b_acct_id'] = this.b_acct_id;
    this.updateObj['total_days'] = this.monthEnd[this.updateObj['month']];

    this.updateObj['update_user_id'] = this.erpUser.user_id;
    console.log(this.updateObj)
    var resp = await this.establishmentService.updateAttendanceInfo(this.updateObj);
    if (resp['error'] == false) {
      this.updateObj = {};
      this.spinner.hide();
      await this.getAllAttendance();
      Swal.fire("Success", "Attendance Info Updated", 'success');

      // this.snackBar.open("Attendance Info Updated", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      Swal.fire('Error', "Error while Updating Attendance Info Of Employee", 'error');
      // this.snackBar.open("Error while Updating Attendance Info Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.datasource2.filter = filterValue.trim().toLowerCase();
  }
  async upload() {
    this.spinner.show()
    const formData = new FormData();
    console.log(this.selectedFile, this.selectedFile.name)
    formData.append('image', this.selectedFile, this.selectedFile.name);
    const obj:any = new Object();
    obj['b_acct_id'] = this.erpUser.b_acct_id;
    obj['file_name'] = this.uploader.queue[0].some.name;
    obj['is_header_present'] = 1;
    obj['create_user_id'] = this.erpUser.user_id;
    const params = JSON.stringify(obj);
    this.uploader.queue[0].url = this.httpUrl + '/hr/establishment_info/attendance/processAttendanceFile' + params;
    this.uploader.queue[0].upload();
    this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      if (!response.error) {
        this.spinner.hide()
        Swal.fire('Success', 'Data Imported Successfully', 'success')
        // this.snackBar.open("Data Imported Successfully", 'Success', {
        //   duration: 5000
        // });


      } else {
        this.spinner.hide()
        Swal.fire('Error', `${response.data}`, 'error');
        // this.snackBar.open(response.data, 'Error', {
        //   duration: 5000
        // });

      }
    };
  }


  onFileUpload(event:any, files:any) {
    console.log(event)
    console.log(event.target.files[0], "jjj")
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };

  }

  // m_Avinash
  async checkAllReadyAdd() {
    if (this.attendanceObj['year'] == undefined || this.attendanceObj['year'] == null ||
      this.attendanceObj['emp_id'] == undefined || this.attendanceObj['emp_id'] == null ||
      this.attendanceObj['month'] == undefined || this.attendanceObj['month'] == null ||
      this.attendanceObj['section_code'] == undefined || this.attendanceObj['section_code'] == null) {
      this.attendanceObj['section_code'] = ''
      Swal.fire('Warning', '* Field Required', 'warning');
      return;

    }
    let obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.attendanceObj['emp_id'];
    obj['year'] = this.attendanceObj['year'];
    obj['month'] = this.attendanceObj['month'];
    obj['section'] = this.attendanceObj['section_code'];
    let resp = await this.attendService.checkAllreadyPresenty(obj);
    console.log(' this is the check presenty resp ', resp);
    if (resp['error'] == false) {
      if (resp['data'].length != 0) {

        Swal.fire('Info', ' Data Already Set Please Update Not Add', 'info');
        this.total_p = false;
        this.attendanceObj = {}
        return
      }

    }
    else {
      Swal.fire('Warning', 'Some Error In Server Side ', 'warning');
      this.attendanceObj = {}
      this.total_p = false;
      return;
    }

  }

  refreshalldata() {
    this.attendanceObj['year'] = ''
    this.attendanceObj['month'] = ''
    this.attendanceObj['section_code'] = ''
    this.attendanceObj['working_days'] = ''
    this.attendanceObj['present_days'] = ''
    this.attendanceObj['absent_days'] = ''
    this.attendanceObj['leave_days'] = ''
  }

  // Avinash
  changeYear() {
    this.attendanceObj['month'] = ''
    this.total_p = false;
    //  this.agreeHr_flag=false;
    this.attendanceObj['present_days'] = ''
  }

  // m_avinash
  total_p = false;
  leId:any
  async getemployeeworkingday() {

    const filteredArray = this.newallEmplyees.filter((item:any) => {
      for (const key in this.attendanceObj) {
        // console.log(key,"keyyy")
        if (item[key] == this.attendanceObj[key]) {
          return true;
        }
      }
      return false;
    });
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['node_code'] = filteredArray[0]['node_code']
    obj["month"] = this.attendanceObj['month']
    console.log(obj, "send to api--")
    var resp = await this.attendService.getallworkingdays(obj)
    console.log(resp)
    if (resp["error"] == false) {

      if (resp.data.length != 0) {

        if (this.attendanceObj['month'] == '2') {
          if ((this.attendanceObj['year']) % 4 == 0) {
            this.attendanceObj['working_days'] = resp.data[0].no_of_days_leap
          }
          else {
            this.attendanceObj['working_days'] = resp.data[0].no_of_days_gen
          }
        }
        else {
          this.attendanceObj['working_days'] = resp.data[0].no_of_days_gen
        }
        this.calabsday()
      }
      else {
        this.attendanceObj['working_days'] = ''
        Swal.fire('Info', "Firstly fill the workings days on salary rule component", 'info')
      }
    }


  }
  async checkPresenty() {
    if (this.attendanceObj['year'] == undefined || this.attendanceObj['year'] == null ||
      this.attendanceObj['emp_id'] == undefined || this.attendanceObj['emp_id'] == null ||
      this.attendanceObj['month'] == undefined || this.attendanceObj['month'] == null) {
      Swal.fire('Warning', '* Field Required', 'warning');
      return;

    }
    for (let i = 0; i < this.monthobj.length; i++) {
      if (this.monthobj[i]['code'] == this.attendanceObj['month']) {
        this.attendanceObj['month1'] = this.monthobj[i]['value']
      }
    }

    // console.log(this.monthobj,"monthobjett")
    console.log('This is the attendance obj --', this.attendanceObj);
    let obj:any = new Object();
    obj['emp_id'] = this.attendanceObj['emp_id'];
    obj['year'] = this.attendanceObj['year']
    obj['month'] = this.attendanceObj['month']
    if (obj['month'] < 10) {
      obj['month'] = "0" + this.attendanceObj['month']
    }
    this.allEmplyees.filter((x:any) => {
      // console.log(x)
      if (x.emp_id == obj['emp_id']) {
        obj['le_id'] = x.le_id;
        this.leId = x.le_id;
        return
      }
    })
    console.log(" this is the leId --", this.leId);

    console.log(" this is hge obj ", obj)
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.attendService.checkPresenty(obj);
    console.log("resp --", resp);
    if (resp['error'] == false) {

      let present_date_count = 0
      let avinash = await this.disAgreeHr(1)

      console.log(" Aivnash ", avinash)
      for (let i of avinash['total_date']) {
        if (i.present == 1) {
          present_date_count++;
        }
      }
      this.spinner.hide();
      // this.attendanceObj['present_days']=resp['data'][0]['total']
      this.attendanceObj['present_days'] = present_date_count;
      // if(this.attendanceObj['present_days']==0 )
      // {
      //   this.agreeHr_flag=false;
      // }
      if (this.attendanceObj['year'] == undefined || this.attendanceObj['month'] == undefined) {
        this.total_p = false

      }
      else {
        this.total_p = true
        // this.calabsday()
      }
      console.log(" this is hte total present ", this.attendanceObj)
      this.getemployeeworkingday()
    }


  }

  // m_avinash
  // agreeHr_flag=false;
  async agreeHr() {
    // this.agreeHr_flag=true;
    this.total_p = false;
  }
  // m_avinash

  async makeCalendar() {
    // ==============making calender----------
    this.disAgreeHrObj['total_date'] = []
    for (let j = 1; j <= this.disAgreeHrObj['last_date']; j++) {
      var datatoday = new Date(this.disAgreeHrObj['start_date']);
      var datatodays = datatoday.setDate(new Date(datatoday).getDate() + j);
      this.disAgreeHrObj['total_date'].push({
        date: new Date(datatodays).toISOString().slice(0, 10),
        present: this.count
      })
    }
    //---------------- calender end-------------

  }


  //m_avinash
  from_date_p:any
  to_date_p:any
  length1:any

  async getDataPresentAndMarkAttandance() {
    // -------getting the data when he present
    let resp = await this.attendService.totalPresent(this.disAgreeHrObj)
    console.log('WHEN he presend', resp, resp['data'].length);
    // ---------------end ---------------
    if (resp['error'] == false) {

      if (resp['data'].length == 0) {
        this.spinner.hide();
        this.attendanceObj['leave_days'] = ''
        this.attendanceObj['absent_days'] = ''
        this.attendanceObj['present_days'] = ''
        Swal.fire('Info', 'Employee Not Present In This Month', 'info');
        return;

      }

      // mark present on the date he present
      for (let i in this.disAgreeHrObj['total_date']) {
        for (let j of resp['data']) {
          if (this.disAgreeHrObj['total_date'][i].date == j.present_date) {
            //  console.log(" hii",j)
            this.disAgreeHrObj['total_date'][i].present = 1;
            this.disAgreeHrObj['total_date'][i].reason = 'P';
          }
        }
      }
      //-------- end mark present  ----------
      // ------- employee present from the fist day of month to last day of the month -----
      this.from_date_p = resp['data'][0]['present_date']

      this.length1 = resp['data'].length
      this.to_date_p = resp['data'][this.length1 - 1]['present_date']
    }
    else {
      Swal.fire('Error', 'Some error in getting attendance', 'error');
      return;
    }



  }

  // m_avinash
  days:any = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  d:any;
  dayName:any;
  datatoday:any
  datatoy:any
  async getLastDayName() {


    this.d = new Date(this.to_date_p);
    this.dayName = this.days[this.d.getDay()]
    if (this.dayName == 'Friday') {

      this.datatoday = new Date(this.to_date_p);
      this.datatoy = new Date(this.datatoday.setDate(new Date(this.datatoday).getDate() + 2)).toISOString().slice(0, 10);
      this.to_date_p = this.datatoy;

    }
    else if (this.dayName == 'Saturday') {
      this.datatoday = new Date(this.to_date_p);
      this.datatoy = new Date(this.datatoday.setDate(new Date(this.datatoday).getDate() + 1)).toISOString().slice(0, 10);
      this.to_date_p = this.datatoy;
    }


  }
  refress() {
    this.attendanceObj = {}
  }

  // m_aivnash

  async holidayRule() {
    //---- getting data from calendar rule
    let calRule = await this.attendService.getCalRule(this.disAgreeHrObj)
    console.log(" calendar rule --", calRule);



    // addinf the present when any gested holiday( not sat/sun)
    for (let i of this.disAgreeHrObj['total_date']) {
      //  if(i.date>=from_date_p && i.date<= to_date_p)
      if (i.date <= this.to_date_p) {
        // working  getting day name
        //           d=new Date(i.date) ;
        //           dayName=days[d.getDay()]
        // //            console.log(dayName)
        //            if(dayName=='Saturday' || dayName=='Sunday' )
        //            {
        //                i.present=1
        //            }
        // //            // if last presenty on friday then he will present next comming two day already
        //            if(dayName=='Friday' )
        //            {

        //                 var datatoday = new Date(to_date_p);
        //          var  datatoy = new Date(datatoday.setDate(new Date(datatoday).getDate() + 2)).toISOString().slice(0, 10);
        //             // console.log(datatodays)
        //            if(i.date<=datatoy)
        //            {
        //                i.present=1
        //            }
        //        }
        calRule['data'].filter((x:any) => {
          if (x.date == i.date) {
            i.present = 1,
              i.reason = (x.holiday_purpose).toUpperCase();
            console.log(" --enter rule-- ")
          }

        })



      }

    }

  }

  // m_aivnash
  disAgreeHrObj:any = {}
  count :any= 0
  element:any
  async disAgreeHr(i:any) {
    this.spinner.show();
    this.disAgreeHrObj['month'] = this.attendanceObj['month']
    this.disAgreeHrObj['year'] = this.attendanceObj['year']
    this.disAgreeHrObj['b_acct_id'] = this.b_acct_id;
    this.disAgreeHrObj['le_id'] = this.leId;

    this.disAgreeHrObj['start_date'] = `${this.disAgreeHrObj['month']}-01-${this.disAgreeHrObj['year']}`
    // getting the total no of days in the month1
    for (let i in this.monthEnd) {
      if (i == this.disAgreeHrObj['month']) {
        this.disAgreeHrObj['last_date'] = this.monthEnd[i]
      }
    }
    // -----------end 1----------------

    // *********************************** makeCalendar()
    // ==============making calender----------
    await this.makeCalendar()
    //  this.disAgreeHrObj['total_date']=[]
    //  for(let j=1;j<=this.disAgreeHrObj['last_date']; j++)
    //  {
    //  var datatoday = new Date(this.disAgreeHrObj['start_date']);
    //  var datatodays = datatoday.setDate(new Date(datatoday).getDate() + j);
    //  this.disAgreeHrObj['total_date'].push({
    // date: new Date(datatodays).toISOString().slice(0, 10),
    //  present: this.count
    //  })
    //  }
    //---------------- calender end-------------
    //###########################################################


    //***********************************getDataPresentAndMarkAttandance() */
    // -------getting the data when he present
    //shweta await this.getDataPresentAndMarkAttandance()
    console.log(' this is the idsagreeHrObj--', this.disAgreeHrObj)
    // let resp= await this.attendService.totalPresent(this.disAgreeHrObj)
    // console.log('WHEN he presend', resp, resp['data'].length);
    // ---------------end ---------------

    // mark present on the date he present
    // for(let i in this.disAgreeHrObj['total_date'])
    // {
    //     for(let j of resp['data'])
    //     {
    //   if(this.disAgreeHrObj['total_date'][i].date==j.present_date)
    //       {
    //         //  console.log(" hii",j)
    //          this.disAgreeHrObj['total_date'][i].present=1;
    //          this.disAgreeHrObj['total_date'][i].reason='P';
    //       }
    //     }
    // }

    //-------- end mark present  ----------


    // && getting the first and last day of presenty
    //############################################


    // from hear to add the sat sunday presenty


    //  getting the first and last day of presenty

    //  let count=0

    // let f_date;
    // let first_date;

    // let l_date;
    // let lp_date=0;
    // let last_date

    // for(let d of resp['data'])
    // {

    //      let day2= new Date(d.date);
    //      l_date= day2.getDate()

    // if(count==0)
    // {
    //     console.log(" cou")
    //     let date = new Date(d.date)
    //     f_date=date.getDate()
    //     first_date= d.date

    //     count++;

    // }
    //     //  console.log( "cnodicion", f_date,l_date, f_date > l_date,count)
    //     if( f_date > l_date)
    //     {
    //         let date3= new Date(d.date)
    //         f_date=date3.getDate()

    //           first_date=d.date
    //     }
    //     else
    //     {
    //         if(lp_date<l_date)
    //         {
    //             lp_date=l_date
    //             last_date=d.date;
    //         }


    //     }
    //   count++;

    // }

    // working fine above code but i short

    //                     ------- employee present from the fist day of month to last day of the month -----
    //                        let  from_date_p= resp['data'][0]['present_date']

    //                     let length1=  resp['data'].length
    //                     let to_date_p= resp['data'][length1-1]['present_date']

    //                     chceck last date is friday or saturday

    // ******************************* getLastDayName() */


    //   var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    //   var d;
    // var dayName;
    //                     {
    //                       d=new Date(this.to_date_p) ;
    //                      dayName=days[d.getDay()]
    //                      if(dayName=='Friday' )
    //                          {

    //                        var datatoday = new Date(this.to_date_p);
    //                        var  datatoy = new Date(datatoday.setDate(new Date(datatoday).getDate() + 2)).toISOString().slice(0, 10);
    //                        this.to_date_p= datatoy;

    //                      }
    //                      else if(dayName=='Saturday')
    //                      {
    //                       var datatoday = new Date(this.to_date_p);
    //                       var  datatoy = new Date(datatoday.setDate(new Date(datatoday).getDate() + 1)).toISOString().slice(0, 10);
    //                       this.to_date_p= datatoy;
    //                      }

    //                   }
    await this.getLastDayName()

    console.log(" present s and e date 0", this.from_date_p, this.to_date_p);


    // #############################################################
    // -------------- end getting the first and last day of presenty ----------------

    // -----checking the day anf mark present 1 on sat and sun between the fist and last day of presenty


    //---- getting data from calendar rule

    //***************************************** holidayRule()          */

    await this.holidayRule();

    //  let calRule= await this.attendService.getCalRule( this.disAgreeHrObj)
    //   console.log(" calendar rule --",calRule);



    // // addinf the present when any gested holiday( not sat/sun)
    //    for(let i of this.disAgreeHrObj['total_date'])
    //    {
    //       //  if(i.date>=from_date_p && i.date<= to_date_p)
    //        if( i.date<= this.to_date_p)
    //        {
    //         // working  getting day name
    // //           d=new Date(i.date) ;
    // //           dayName=days[d.getDay()]
    // // //            console.log(dayName)
    // //            if(dayName=='Saturday' || dayName=='Sunday' )
    // //            {
    // //                i.present=1
    // //            }
    // // //            // if last presenty on friday then he will present next comming two day already
    // //            if(dayName=='Friday' )
    // //            {

    // //                 var datatoday = new Date(to_date_p);
    // //          var  datatoy = new Date(datatoday.setDate(new Date(datatoday).getDate() + 2)).toISOString().slice(0, 10);
    // //             // console.log(datatodays)
    // //            if(i.date<=datatoy)
    // //            {
    // //                i.present=1
    // //            }
    // //        }
    //       calRule['data'].filter((x)=>{
    //         if(x.date==i.date)
    //         {
    //           i.present=1,
    //           i.reason= (x.holiday_purpose).toUpperCase();
    //         console.log(" --enter rule-- ")
    //         }

    //        })



    //    }

    //   }

    /*  ##################################################  */





    // this.checkboxNumericValue()
    this.datasource2 = new MatTableDataSource(this.disAgreeHrObj['total_date'])
    this.datasource2.paginator = this.paginator2;
    this.paginatorSize = this.disAgreeHrObj['total_date'];
    this.datasource2.sort = this.sortCol2;

    if (i != 1) {
      this.spinner.hide();

      $('#disAgreeHrList').modal('show')
    }
    return this.disAgreeHrObj
  }
  // checkboxNumericValue(): number {
  //   for(let i=0;i<this.disAgreeHrObj['total_date'].length;i++){
  //     return this.disAgreeHrObj['total_date'][i].present ? 1 : 0;
  //   }
  //   console.log(this.disAgreeHrObj['total_date'])
  // }
  validateKey(event: KeyboardEvent): void {
    const key = event.key;
    if (key !== '0' && key !== '1') {
      event.preventDefault();
    }
  }
  paginatorSize:any;
  // m_avinash

  async hrAttendanceApp() {
    let disAgreeHr_count = 0
    console.log(' button submit click ', this.disAgreeHrObj['total_date'])
    this.disAgreeHrObj['total_date'].filter((x:any) => {
      if (x.present == 1) {
        disAgreeHr_count++;
      }
    })

    this.attendanceObj['present_days'] = disAgreeHr_count;
    this.attendanceObj['absent_days'] = this.attendanceObj['working_days'] - this.attendanceObj['present_days']
    this.attendanceObj['leave_days'] = this.attendanceObj['working_days'] - this.attendanceObj['present_days']

    $('#disAgreeHrList').modal('hide')

  }




}



