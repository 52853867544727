import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LedgerService } from '../../../service/ledger.service';
import { ChartOfAccountService } from '../../../service/chart-of-account.service';
import { ProjectBankAccService } from '../../../service/project-bank-acc.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-fixed-assets-mapping',
  templateUrl: './fixed-assets-mapping.component.html',
  styleUrls: ['./fixed-assets-mapping.component.css']
})
export class FixedAssetsMappingComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private ledgerService: LedgerService, private chartOfAccountService: ChartOfAccountService, private projectBankAccService: ProjectBankAccService) { }
  b_acct_id:any;
  erpUser:any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getFinYear();
    await this.getChartOfAccount();
    await this.changeFinYear();
  }


  allFinYear:any
  sel_fin_yr:any
  table_data :any= []
  async getFinYear() {

    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getAllFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allFinYear = resp.data;
      this.allFinYear.map((x :any)=> {
        x['fin_year_desc']=x['fin_year']+"-"+(Number(x['fin_year'])+1)
        if (x['status'] == 'OPEN') {
          this.sel_fin_yr = x['fin_year']
        }
      })
      console.log(this.allFinYear)
      this.spinner.hide();
    } else {
      Swal.fire("ERROR", "Error While Getting Financial Year ");
      this.spinner.hide()
    }
  }

  all_coa = []
  f_coa = []
  d_coa = []
  allCOAObj:any = {};
  async getChartOfAccount() {
    this.spinner.show();
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.chartOfAccountService.getchartofaccount(obj);
    if (resp['error'] == false) {
      this.all_coa = resp['data'];
      this.table_data = [];
      this.allCOAObj = {};
      this.all_coa.map(x => {
        this.allCOAObj[x['leaf_code']] = x['leaf_value'];
        if (x["lvl4_code"] == "CH1121" && x['is_leaf'] == 1) {
          this.f_coa.push(x);
          this.table_data.push({ f_coa: x['leaf_code'], d_coa: null, rate: 0 })
        }
      })

      this.all_coa.map(x => {
        if (x["lvl4_code"] == "CH1123" && x['is_leaf'] == 1) {
          this.d_coa.push(x);
        }
      })
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('ERROR', 'Error While getting Chart Of Account', 'error');
    }
  }
  async submit() {
    this.spinner.show()

    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['fin_year'] = this.sel_fin_yr;
    obj['data'] = this.table_data;
    let resp = await this.projectBankAccService.saveFixedAssetMapping(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      Swal.fire('SUCCESS', 'Successfully Mapped', 'success');
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While Mapping', 'error');
    }

  }
  async changeFinYear() {
    this.spinner.show()
    let obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj['fin_year'] = this.sel_fin_yr;
    let resp = await this.projectBankAccService.getFixedAssetMapping(JSON.stringify(obj));
    if (resp['error'] == false) {
      let data = resp['data'];
      this.table_data.map((x:any) => {
        x['d_coa'] = ''
        x['d_rate'] = 0
        data.map((y:any) => {
          if (x['f_coa'] == y['f_coa']) {
            x['d_coa'] = y['d_coa']
            x['d_rate'] = y['d_rate']
          }
        })
      })

      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While getting data', 'error');
    }
  }

}
