import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../../service/main.service';
import { AuthenticationService } from '../../../authentication.service';
import { ProfileService } from '../../../portal/service/profile.service';
import { MainService as portal_mainService } from '../../../portal/service/main.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: '[app-accounts-header]',
  templateUrl: './accounts-header.component.html',
  styleUrls: ['./accounts-header.component.css']
})
export class AccountsHeaderComponent implements OnInit {

  constructor(public auth: AuthenticationService, private router: Router, public portal_main: portal_mainService,
    public mainService: MainService, private profileService: ProfileService, private sanitizer: DomSanitizer) { }
  isDropdownOpen = false;
  erpUser: any = {};
  placeholderText: any
  selectedAction: any
  action = [
    { id: 1, name: 'Profile', route: "profile" },
    { id: 2, name: 'Logout', route: "login" },
  ];

  @ViewChild('ngSelectElement') ngSelectElement!: NgSelectComponent;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    await this.portal_main.getUserImage(this.erpUser['user_id']);
    await this.portal_main.getAccImage(this.erpUser['b_acct_id']);
    await this.portal_main.getAccountInfo(this.erpUser['b_acct_id']);
    await this.portal_main.getHeaderInfo(this.erpUser['b_acct_id']);
    await this.changeLanguage();
    this.mainService.accInfo = this.portal_main.accInfo;
    this.placeholderText = this.erpUser['party_name']

  }

  changeLanguage() {
    console.log("laungage Change");
    this.mainService.language_cd = this.auth.language_cd
  }

  home() {
    this.router.navigate(['/index'])
  }

  ngAfterViewInit() {
  }
  onSelectionChange2() {
    const selectedVal = this.selectedAction.route
    this.router.navigate([selectedVal]);
  }
  toggleSelect() {

    if (this.isDropdownOpen) {
      this.ngSelectElement.close();
    } else {
      this.ngSelectElement.open();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

}
