<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObjHR[mainService.language_cd+'hr_death11']}} </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_death12']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_death13']}} </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_death14']}}">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_death15']}}<!-- Employee ID -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{this.mainService.accInfo['account_short_name']}}{{getNumberFormat(element['emp_id'])}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_death16']}}<!-- Employee Name -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{employeeObj[element.emp_id]}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="effective_timestamp">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Effective Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_death17']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <p *ngIf="element['effective_timestamp']">{{mainService.dateformatchange(element.effective_timestamp)}} </p>

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="order_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Order ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_death18']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.order_id}} </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>


                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_death19']}}  :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">


                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="" [(ngModel)]="selectEmpObj" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="emp_id">
                                    </ng-select>


                                </div>
                            </div>
                            <br>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Order ID : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_death18']}}  :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input *ngIf="mainService.codeValueTechObj['order_id']==undefined" class="form-control" [(ngModel)]="deathObj['order_id']">
                                    <select *ngIf="mainService.codeValueTechObj['order_id']!=undefined" class="form-control" [(ngModel)]="deathObj['order_id']">
                                        <option *ngFor="let ob of mainService.codeValueTechObj['order_id']"
                                            (ngValue)="ob['code']">{{ob['value']}}</option>
                                    </select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Date Of Death : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_death110']}} :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" (change)="dateValid()" [(ngModel)]="deathObj['date_of_death']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Would You Like To Fetch Previous Salaries : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_death111']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="ftechSalaryArr" bindLabel="value" bindValue="value" [multiple]="false" placeholder="" [(ngModel)]="deathObj['fetch_salary']" (change)="getBillDetailForDeath()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="fetch_salary">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div *ngIf="totalAmtToPay!=0">
                                <div class="row">
                                    <div class="col-4 text-right">
                                        <!-- Total Amount To Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_death112']}}
                                    </div>
                                    <div class="col-4">
                                    <input class="form-control" value="{{totalAmtToPay}}" disabled>
                                        <!-- <b>{{totalAmtToPay}}</b> -->

                                    </div>
                                </div>
                                <br>
                                <div *ngIf="allNominee.length>0">

                                    <div *ngFor="let allNominee of allNominee">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                {{allNominee['nom_name']}} :
                                            </div>
                                            <div class="col-4">
                                                {{allNominee['other_pay_component_amount']}}


                                            </div>
                                        </div>
                                        <br>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitDeath()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_death113']}}    <!-- Submit -->
                                    </button>

                                </div>
                            </div>
                        </div>




                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
