import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { VendorService } from '../service/vendor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {

  constructor(private vendorService: VendorService, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer,private changeDetectorRef:ChangeDetectorRef) { }



  @ViewChild('paginate',{static:false}) paginate!: MatPaginator;
  erpUser:any;
  b_acct_id:any;
  user_id:any = 0;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.getAllVendors();
  }


  dataSource:any;
  displayedColumns:any = ['Sr.No', 'vendor_id', 'vender_type', 'category', 'vender_name', 'reg_date', 'life_cycle_status', 'action']

  list: boolean = true;
  details: boolean = false;

  async backToList() {
    await this.getAllVendors();
    this.list = true;
    this.details = false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  async getAllVendors() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp:any = await this.vendorService.getVendor(JSON.stringify(obj))
    if (resp['error'] == false) {

      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.paginator = this.paginate;
      this.changeDetectorRef.detectChanges()
      // this.dataSource.sort = this.sortCol2;
      console.log(this.dataSource)
    } else {
      Swal.fire('ERROR', 'ERROR While Getting Vendor', 'error');
    }
  }

  vendor_details:any;
  async viewDetails(ele:any) {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['vendor_id'] = ele.vendor_id;
    console.log(ele);
    let resp:any = await this.vendorService.viewDetails(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.vendor_details = resp['data'][0];
      console.log(this.vendor_details)
      this.list = false;
      this.details = true;

    } else {
      Swal.fire('ERROR', 'ERROR WHile Getting Vendor Details', 'error');
    }
  }

  imgURL:any

  async viewDoc(vender_id:any, data_type:any) {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['type'] = 'VENDOR_DOC'
    obj['doc_type'] = data_type;
    obj['vendor_id'] = vender_id;
    const resp:any = await this.vendorService.viewDocument(JSON.stringify(obj));
    console.log(resp['data'])
    if (resp['error'] == false) {
      let data = resp['data'][0]
      obj['doc_name'] = data['doc_name']
      const res = await this.vendorService.viewDoc(obj);
      this.spinner.show()
      if (res) {
        let docname = obj['doc_name']
        let ext = docname.split('.');
        if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
          const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
          this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          this.spinner.hide()
        } else if (ext[1].toLowerCase() == 'xlsx') {
          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL,'shani.pdf');

          this.spinner.hide()
        } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {
          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL,'shani.pdf');
          this.spinner.hide()
        } else {
          let file = new Blob([res], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL,'shani.pdf');
          this.spinner.hide()
        }
      } else {
        this.spinner.hide()
      }
    } else {
      Swal.fire('ERROR', 'Error While Getting Document', 'error');
    }

  }
  // node --max_old_space_size=5048 ./node_modules/@angular/cli/bin/ng s --aot
}
