<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'md_role1']}}</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-1" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-line-chart"></i>&nbsp;{{mainService.allLableShowObj[mainService.language_cd+'md_role2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-7-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'md_role3']}}

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-7-3" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'md_role4']}}
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-7-1">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role5']}}">
                                    </mat-form-field>
                                </div>
                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>



                                    <!-- <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Id
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.id}} </td>
                                    </ng-container> -->


                                    <ng-container matColumnDef="role_cd">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'md_role6']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.role_cd}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="role_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'md_role7']}}
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.role_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="role_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'md_role8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.role_desc}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="role_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'md_role9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.role_type}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="res">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'md_role10']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.resource_desc}} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="valid_from">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Valid From
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.valid_from}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="valid_upto">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Valid Upto
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.valid_upto}} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'md_role11']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button (click)="viewDetails(element)"
                                                class="btn btn-primary">View</button>&nbsp;&nbsp; -->
                                            <button (click)="open_update(element)"
                                                class="btn btn-success">{{mainService.allLableShowObj[mainService.language_cd+'md_role12']}}</button>&nbsp;&nbsp;
                                            <button *ngIf="element['role_cd']!='AA' && element['role_cd']!='ADM_EMP' && element['is_system_role']!=1 " (click)="delete(element)"
                                                class="btn btn-danger">{{mainService.allLableShowObj[mainService.language_cd+'md_role13']}}</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>


                        <div class="tab-pane fade" id="tab-7-2">
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role6']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_cd']"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role6']}}"
                                        class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role7']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_name']"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role7']}}"
                                        class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role8']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_desc']"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role8']}}"
                                        class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role9']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="role_type" bindLabel="value" bindValue="code"
                                        (change)="set_flag()" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role9']}}"
                                        [(ngModel)]="Obj['role_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="role_type">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br *ngIf="Obj['role_type'] == 'Module  Admin'">
                            <div class="row" *ngIf="Obj['role_type'] == 'Module  Admin'">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role14']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>

                                <div class="col-3">
                                    <ng-select [items]="all_modules" bindLabel="module_name" bindValue="module_cd"
                                        (change)="set_res()" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role14']}}"
                                        [(ngModel)]="Obj['module_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="role_type">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role10']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="resource2" bindLabel="desc" bindValue="resource_cd"
                                        [multiple]="true"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role10']}}"
                                        [(ngModel)]="Obj['res']" [selectableGroup]="true" (change)="show()"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="false"
                                        name="chart_of_account0">
                                    </ng-select>
                                </div>
                                <div class="col-3">
                                    <mat-checkbox  [(ngModel)]="checkBoxValue"
                                        [ngModelOptions]="{standalone: true}" (click)="checkCheckBoxvalue()">
                                        {{mainService.allLableShowObj[mainService.language_cd+'md_role16']}}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <!-- <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid from : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_from']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid Upto : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_upto']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div> -->
                            <br><br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="submit()">{{mainService.allLableShowObj[mainService.language_cd+'md_role15']}}</button>
                                </div>

                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-7-3">
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role6']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_cd']"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role6']}}"
                                        class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role7']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_name']"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role7']}}"
                                        class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role8']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_desc']"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role8']}}"
                                        class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role9']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="role_type" bindLabel="value" bindValue="code"
                                        (change)="set_flag()" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role9']}}"
                                        [(ngModel)]="Obj['role_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="role_type">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br *ngIf="Obj['role_type'] == 'Module  Admin'">
                            <div class="row" *ngIf="Obj['role_type'] == 'Module  Admin'">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role14']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>

                                <div class="col-3">
                                    <ng-select [items]="all_modules" bindLabel="module_name" bindValue="module_cd"
                                        (change)="set_res()" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_role14']}}"
                                        [(ngModel)]="Obj['module_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="role_type">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'md_role10']}} : <span
                                            style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="resource2" bindLabel="desc" bindValue="resource_cd"
                                        [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res']"
                                        [selectableGroup]="true" (change)="show()" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="false" name="chart_of_account0">
                                    </ng-select>
                                </div>
                                <div class="col-3">
                                    <mat-checkbox  [(ngModel)]="checkBoxValue"
                                        [ngModelOptions]="{standalone: true}" (click)="checkCheckBoxvalue()">
                                        {{mainService.allLableShowObj[mainService.language_cd+'md_role16']}}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <!-- <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid from : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_from']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid Upto : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_upto']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div> -->
                            <br><br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="update()">{{mainService.allLableShowObj[mainService.language_cd+'md_role12']}}</button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="tab-7-3">
                            <br>

                        </div> -->
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>