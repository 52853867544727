<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Geometry </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Geometry List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i> Create Geometry</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i> Update
                                Geometry</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="geo">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Geometry
                                            Name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.geometry }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="unit">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Unit

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.unit }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="meas">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Measurement

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.measurement }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="formula">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Formula
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.formual }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-primary"
                                                (click)="open_update(element)">Update</button>
                                            <button class="btn btn-danger" (click)="delete(element)">Delete</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>



                        </div>
                        <div class="tab-pane fade" id="tab-2">

                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Select Unit : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Select Unit"
                                        [items]="mainService.codeValueTechObj['EMB009']" bindLabel="value"
                                        bindValue="code" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="hierarchy" [(ngModel)]="geo['unit']">
                                    </ng-select>
                                </div>
                                <div class="col-3">

                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Enter Shape : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">

                                    <input type="text" class="form-control" placeholder="Enter Shape"
                                        [(ngModel)]="geo['geometry']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Select Measurement : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="true" placeholder="Select Measurement"
                                        [items]="mainService.codeValueTechObj['EMB010']" bindLabel="value"
                                        (change)="get_role()" bindValue="value" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy"
                                        [(ngModel)]="geo['meas']">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Enter Formula : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="" [(ngModel)]="geo['formual']"
                                        placeholder="Enter Formula">
                                </div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-secondary" data-toggle="modal"
                                        data-target="#exampleModal">
                                        Instructions for formula
                                    </button>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="create()">Submit</button>

                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="tab-3">

                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Select Unit : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Select Unit"
                                        [items]="mainService.codeValueTechObj['EMB009']" bindLabel="value"
                                        bindValue="code" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="hierarchy" [(ngModel)]="geo['unit']">
                                    </ng-select>
                                </div>
                                <div class="col-3">

                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Enter Shape : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">

                                    <input type="text" class="form-control" placeholder="Enter Shape"
                                        [(ngModel)]="geo['geometry']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Select Measurement : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="true" placeholder="Select Measurement"
                                        [items]="mainService.codeValueTechObj['EMB010']" bindLabel="value"
                                        (change)="get_role()" bindValue="value" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy"
                                        [(ngModel)]="geo['meas']">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Enter Formula : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="" [(ngModel)]="geo['formual']"
                                        placeholder="Enter Formula">
                                </div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-secondary" data-toggle="modal"
                                        data-target="#exampleModal">
                                        Instructions for formula
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

</div>
<ngx-spinner>Loading....</ngx-spinner>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-12">
                        <h3 style="background-color: rgb(223, 223, 223); font:bold;">Instructions :</h3>
                        <br>
                        <p>
                            When You are entering the formula , you should keep in mind that the spellings of
                            measurement should be same as you have selected in the measurement dropdown.
                            else you will not be able to calculate.
                        </p>

                      <h6>  Example :</h6>
                        <br>

                        <div class="row">
                            <div class="col-4">
                                Shape :
                            </div>
                            <div class="col-4">
                                Cuboid
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-4">
                                Measurement :
                            </div>
                            <div class="col-6">
                                Length,Width,Height
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-4">
                                Formula :
                            </div>
                            <div class="col-6">
                                Length* Width*Height
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
