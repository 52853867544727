import { Injectable } from '@angular/core';
import { WorkService } from './work.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
@Injectable({
  providedIn: 'root'
})
export class DataControlService {
  erpUser:any;
  b_acct_id:any
  userId:any
  uni_cc: any = []
  cc_mapped_work = []
  constructor(private workService: WorkService, private spinner: NgxSpinnerService) { }

  async gettingLogInfo() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.userId = this.erpUser.user_id;
  }

  async costCenterMapped() {
    await this.gettingLogInfo();
    this.spinner.show();
    let cc:any = []
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    // obj['user_id'] = 15362;
    obj['user_id'] = this.userId;
    console.log("User Id Is : ", this.userId)
    console.log("Obj to send ", obj)
    let resp = await this.workService.costCenterMapped(JSON.stringify(obj))
    if (resp['error'] == false) {
      let orgHier_data = resp['data'][0];
      let data_assign = resp['data'][1];
      for (let i = 0; i < orgHier_data.length; i++) {
        for (let j = 1; j < 8; j++) {
          for (let k = 0; k < data_assign.length; k++) {
            if (orgHier_data[i]['lvl' + j + '_cd'] == data_assign[k]['node_cd']) {
              cc.push(orgHier_data[i]['leaf_cd']);
            }
          }
        }
      }
      this.uni_cc = new Set(cc);
      console.log(" cost center is : ", (this.uni_cc));
      console.log("Number of Cost Center is : ", (this.uni_cc.size));
      this.spinner.hide();
      // Swal.fire('SUCCESS','Successfully Cost Center Get','success')
    }
    else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While Getting Assigned Cost Center', 'error');
    }
  }

  async getWork() {
    this.spinner.show();
    await this.costCenterMapped();
    let work_arry :any= [];
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.workService.workMapped(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.cc_mapped_work = resp['data'];
      // console.log("worked mapped on cost center is : ", this.cc_mapped_work)
      for (let x of this.uni_cc.values()) {
        for (let y of this.cc_mapped_work) {
          if (x == y['cc_code']) {
            work_arry.push(y['work_id'])
          }
        }
      }
      console.log("How many work you can see : ", work_arry)
      this.spinner.hide();
      return work_arry;
    }
    else {
      this.spinner.hide()
      Swal.fire("ERROR", 'Error while getting work mapped on cost center', 'error');
      return " Somthing Went worng while getting work ";
    }
  }

}
