<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS1']}}<!-- Users --></div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="role_flage" class="nav-link active" href="#tab-7-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> {{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS2']}}<!-- User-Role List --></a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="maprole_flage"  class="nav-link" href="#tab-7-2" data-toggle="tab"><i class="fa fa-plus-circle"
                                    aria-hidden="true"> </i> {{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS3']}}<!-- Map User And Role -->
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-7-3" data-toggle="tab"><i class="ti-settings"
                                    aria-hidden="true"> </i> Update User
                            </a>
                        </li> -->

                    </ul>
                    <div class="tab-content">
                        <div *ngIf="role_flage"  >
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="maprole()">Map User And Role </button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS13']}}"><!-- Filter -->
                                    </mat-form-field>
                                </div>

                            </div>


                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="user_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS4']}}<!-- User ID -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.user_id }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> {{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS5']}}<!-- User Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ userObj[element.user_id]}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="role_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS6']}}<!-- Role Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <div>{{element.role_name}}</div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="delete">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS7']}}<!-- Action --></th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button class="btn btn-danger" (click)="Re_Assign(element)">Re-Assign
                                                User</button>
                                            <button class="btn btn-info" (click)="open_update(element)">Update
                                                User</button>&nbsp; -->
                                            <button (click)="modalOpen(element)" class="btn btn-danger">{{mainService.allLableShowObj[mainService.language_cd+'EMB-ROLE5']}}<!-- Delete --></button>

                                        </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button (click)="modalOpen(element)" class="btn btn-danger">Delete</button>
                                             </td>
                                    </ng-container> -->

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5,10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>

                        <div *ngIf="maprole_flage" >
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Go to User-Role List</button>
                            </div>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS10']}}<!-- Select User --> : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS10']}}" [items]="users"
                                        bindLabel="desc" bindValue="user_id" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS10']}}"
                                        [closeOnSelect]="true" name="hierarchy" [(ngModel)]="Obj['user_id']">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS11']}}<!-- Select Role --> : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS11']}}" [items]="roles" bindLabel="role_name"
                                        bindValue="role_cd" [selectableGroup]="true" (change)="ChangeRole()"
                                        [selectableGroupAsModel]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS11']}}"
                                        [closeOnSelect]="true" name="hierarchy" [(ngModel)]="Obj['role_cd']">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                           
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS12']}}<!-- Submit --></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS14']}}<!-- Loading.... --></ngx-spinner>

<!-- <div class="modal" id="update_user_info">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Update User Info</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body" style="padding-left: 15%">
                <div class="row">
                    <div class="col-6">
                        User ID
                    </div>
                    <div class="col-6">
                        {{update_user_info_Obj['user_id']}}
                    </div>
                </div>
                <br>
                <hr>
                <div class="row">
                    <div class="col-6">
                        <h4>Current User Details</h4>
                    </div>
                </div>
                <br>
                <hr>
                <br>
                <div class="row">
                    <div class="col-6">
                        User Name
                    </div>
                    <div class="col-6">
                        {{update_user_info_Obj['old_name']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-6">
                        User Phone Number
                    </div>
                    <div class="col-6">
                        {{update_user_info_Obj['old_phone_no']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-6">
                        User Email
                    </div>
                    <div class="col-6">
                        {{update_user_info_Obj['old_email']}}
                    </div>
                </div>
                <br>
                <hr>
                <div class="row">
                    <div class="col-6">
                        <h4>Enter New User Details</h4>
                    </div>
                </div>
                <br>
                <hr>
                <br>
                <div class="row">
                    <div class="col-6">
                        User Name
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Enter Name" class="form-control" [(ngModel)]="update_user_info_Obj['name']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-6">
                        User Phone Number
                    </div>
                    <div class="col-6">
                       
                        <input class="form-control" oninput="javascript: if (this.value.length > this.maxLength) 
                         this.value = this.value.slice(0, this.maxLength);" onkeypress="return event.charCode >= 48" min="0" type="number" name="mobile_number" pattern="[0-9]{10}}" maxlength="10" placeholder="Enter Phone Number" autocomplete="off" [(ngModel)]="update_user_info_Obj['phone_no']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-6">
                        User Email
                    </div>
                    <div class="col-6">
                        <input type="email" placeholder="Enter Email" class="form-control" [(ngModel)]="update_user_info_Obj['email']">
                    </div>
                </div>
                <br>


            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-info" (click)="updateUserInfo()">Update</button>
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div> -->



<!-- <div class="modal" id="update_role_info">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">User Role Info</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body" style="padding-left: 15%">
                <div class="row">
                    <div class="col-6">
                        User ID
                    </div>
                    <div class="col-6">
                        {{reAssignObj['user_id']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-6">
                        User Name
                    </div>
                    <div class="col-6">
                        {{reAssignObj['name']}}
                    </div>
                </div>
                <br>
                <hr>
                <div class="row">
                    <div class="col-6">
                        <h4>Role - {{commonDataService.zone_field_business_name}} Assignments</h4>
                    </div>
                </div>
                <br>

                <hr>
                <div style="overflow-x: scroll;">

                    <table style="width: 100%;" class="table  table-bordered">
                        <thead style="background-color: blue; color: white;">
                            <tr>
                                <th>Role</th>
                                <th>{{commonDataService.zone_field_business_name}}</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ob of roleZoneArr;let i=index">
                                <td>{{mainService.roleCodeToRoleDescription[ob['role_cd']]}}</td>
                                <td *ngIf="ob['zone_cd']==null">All {{commonDataService.zone_field_business_name}}</td>
                                <td *ngIf="ob['zone_cd']!=null">
                                    {{mainService.codeValueShowObj['EMB003'][ob['zone_cd']]}}</td>
                                <td><button type="button" class="btn btn-danger" (click)="deleteRoleZone(ob)">Delete</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div> -->
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">All Roles</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="card">
                    <div style="margin: 2%;">
                        <table class="table table-borederd" style="width: 80%; margin-left: 10%;">
                            <thead>
                                <tr>
                                    <th>S No.</th>
                                    <th>Role Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of single_users_role,let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{item['role_desc']}}</td>
                                    <td>
                                        <button class="btn btn-danger"
                                            *ngIf="single_users_role.length==1 && item['role_cd']=='EMB_EMP'"
                                            (click)="delete(item)">Delete</button>
                                        <button class="btn btn-danger"
                                            *ngIf="single_users_role.length==1 && item['role_cd']!='EMB_EMP'"
                                            (click)="delete(item)">Delete</button>
                                        <button class="btn btn-danger"
                                            *ngIf="single_users_role.length!=1 && item['role_cd']!='EMB_EMP'"
                                            (click)="delete(item)">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>