<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{auth.allLableShowObjProp[auth.language_cd+'Location1']}}</div>
                    <!--Geographic Location -->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                {{auth.allLableShowObjProp[auth.language_cd+'Location2']}}</a>
                            <!-- Geographic Location List-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag"  (click)="reset()" data-toggle="tab"><i class="fa fa-plus-circle"
                                    aria-hidden="true" > </i>
                                {{auth.allLableShowObjProp[auth.language_cd+'Location3']}}</a>
                            <!-- Create Geographic Location-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" (click)="reset()" data-toggle="tab"><i class="fa fa-plus-circle"
                                    aria-hidden="true" > </i>
                                {{auth.allLableShowObjProp[auth.language_cd+'Location4']}}</a>
                            <!-- Update Geographic Location-->
                        </li>



                    </ul>
                    <br>
                    <div class="tab-content">

                        <div *ngIf="list_flag">

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)"
                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location5']}}">
                                <!--Filter-->
                            </mat-form-field>
                            <br>
                            <div class="col-11 text-right">
                                <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                            </div>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'Location6']}}

                                        </th>
                                        <!--ID-->
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="loc_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'Location7']}}

                                        </th>
                                        <!--Location
                                            Type-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.loc_type_cd }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="loc_id_std">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'Location141']}}

                                        </th>
                                        <!--Short Name-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.loc_short_nm }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="loc_id_str">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'Location9']}}

                                        </th>
                                        <!--Geography-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.loc_geo_cd }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="loc_short_nm">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'Location10']}}
                                        </th>
                                        <!--Unit-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.loc_geo_ent_value }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="loc_long_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'Location11']}}
                                        </th>
                                        <!--Quantity-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.loc_geo_calc_value }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'Location12']}}</th>
                                        <!--Action-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">{{auth.allLableShowObjProp[auth.language_cd+'Location13']}}</button>
                                            <!--Update-->
                                            &nbsp;&nbsp;
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element)">{{auth.allLableShowObjProp[auth.language_cd+'Location14']}}</button>
                                            <!--Delete-->

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>



                        </div>
                        <div *ngIf="create_flag">
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Location136']}}">
                                    <!--Identification-->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location15']}}<span
                                                        style="color: red;"> *</span>
                                                </div>
                                                <!--Location Type :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD5']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location15']}}"
                                                        (change)="set_data(1)" [(ngModel)]="Obj['loc_type_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location17']}} <span
                                                        style="color: red;">
                                                        *</span><!-- Location Identification Standered :-->
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD6']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location17']}} "
                                                        (change)="set_data(2)" [(ngModel)]="Obj['loc_id_std']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <!---->
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location19']}} <span
                                                        style="color: red;"> *</span>

                                                </div>
                                                <!--Location Business Identifier :-->
                                                <div class="col-3">
                                                    <input type="text" class="form-control"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location20']}}"
                                                        [(ngModel)]="Obj['loc_id_str']" name="" id="">
                                                </div>
                                                <!--Enter  Location Business Identifier-->
                                                <div class="col-3">

                                                </div>

                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location21']}}
                                                </div>
                                                <!--Location Short Name :-->
                                                <div class="col-3">
                                                    <input type="text"
                                                        placeholder=" {{auth.allLableShowObjProp[auth.language_cd+'Location22']}}"
                                                        class="form-control" [(ngModel)]="Obj['loc_short_nm']" name=""
                                                        id="">
                                                    <div class="col-3"></div>
                                                    <!--Enter Location Short Name-->
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location23']}}
                                                </div>
                                                <!--Location Long Description :-->
                                                <div class="col-3">
                                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                        [(ngModel)]="Obj['loc_long_desc']"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location24']}}"
                                                        class="form-control"></textarea>
                                                    <!--Enter  Location Long Description-->
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location25']}}
                                                </div>
                                                <!--Location Use Type :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD7']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location26']}}"
                                                        [(ngModel)]="Obj['location_use_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Use Type-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location27']}}
                                                </div>
                                                <!--Location Property Type :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD8']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location27']}}"
                                                        [(ngModel)]="Obj['location_property_type']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                        <!--Select Location Property Type-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location29']}}
                                                </div>
                                                <!--Location Admin Code :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD9']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location29']}}"
                                                        [(ngModel)]="Obj['loc_admin_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Property Type-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>

                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'Location31']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Location137']}}">
                                    <!--Geography-->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-2"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location32']}}
                                                </div>
                                                <!--Location Geography :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD4']"
                                                        bindLabel="value" bindValue="code" (change)="set_data(3)"
                                                        [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location32']}}"
                                                        [(ngModel)]="Obj['loc_geo_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Geography-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>

                                            <br>
                                            <div class="card">
                                                <br>
                                                <div class="row">
                                                    <div class="col-3 text-right">
                                                        <input type="radio" class="form-check-input" name="radio" checked="checked"
                                                            [(ngModel)]="radio" [value]="false"
                                                            style="height: 20px; width: 20px;">
                                                    </div>
                                                    <div class="col-3">
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location47']}}
                                                    </div>
                                                    <!--Upload GIS Details-->
                                                    <div class="col-3">
                                                    </div>
                                                    <div class="col-3">
                                                        <!-- <button class="btn btn-primary">Add</button> -->
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location46']}}
                                                    </div><!-- OR-->
                                                </div>
                                                <div class="row">
                                                    <div class="col-3 text-right">
                                                        <input type="radio" class="form-check-input"
                                                            name="radio" [(ngModel)]="radio" [value]="true"
                                                            style="height: 20px; width: 20px;">
                                                    </div>
                                                    <div class="col-2">
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location37']}}
                                                    </div>
                                                    <!--Enter GIS Details-->
                                                    <div class="col-2">
                                                    </div>
                                                    <div class="col-2">
                                                        <button *ngIf="radio" class="btn btn-primary btn-sm btn-block"
                                                            (click)="addRowInGISArr()">{{auth.allLableShowObjProp[auth.language_cd+'Location38']}}</button>
                                                        <!--Add-->
                                                    </div>
                                                </div>
                                                <br>
                                                <div *ngIf="radio">
                                                    <!-- {{GISArr |json}} -->
                                                    <table class="table table-bordered"
                                                        style="width: 90%; margin-left: 5%;">
                                                        <tbody>
                                                            <tr *ngFor="let item of GISArr;let i =index">
                                                                <td> {{i+1}} Line </td>
                                                                <td>{{auth.allLableShowObjProp[auth.language_cd+'Location39']}}
                                                                </td><!-- 1 Point-->
                                                                <td>
                                                                    <input type="number" [(ngModel)]="GISArr[i]['lat1']"
                                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location40']}}"
                                                                        class="form-control" name="" id="">
                                                                    <!--Enter Latitude-->
                                                                </td>
                                                                <td>
                                                                    <input type="number" [(ngModel)]="GISArr[i]['long1']"
                                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location41']}}"
                                                                        class="form-control" name="" id="">
                                                                    <!--Enter Longitude-->
                                                                </td>
                                                                <td> {{auth.allLableShowObjProp[auth.language_cd+'Location42']}}
                                                                </td><!-- 2 Point-->
                                                                <td>
                                                                    <input type="number" [(ngModel)]="GISArr[i]['lat2']"
                                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location43']}}"
                                                                        class="form-control" name="" id="">
                                                                    <!--Enter Latitude-->
                                                                </td>
                                                                <td>
                                                                    <input type="number" [(ngModel)]="GISArr[i]['long2']"
                                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location44']}}"
                                                                        class="form-control" name="" id="">
                                                                    <!--Enter Logitude-->
                                                                </td>
                                                                <td>
                                                                    <button (click)="deleteGISRow(item,i)"
                                                                        class="btn btn-danger">{{auth.allLableShowObjProp[auth.language_cd+'Location45']}}</button>
                                                                    <!--Delete-->
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>


                                                </div>



                                                <div *ngIf="!radio">
                                                    <br>
                                                    <div class="row">
                                                        <div class="col-3 text-right">
                                                            <h6>{{auth.allLableShowObjProp[auth.language_cd+'Location106']}}
                                                            </h6>
                                                            <!--Choose File :-->
                                                        </div>
                                                        <div class="col-3">
                                                            <input type="file" style="display: inline-block;"
                                                                (change)="onFileChange($event)"
                                                                placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location107']}}"
                                                                accept=".xls">
                                                            <!--Upload file-->
                                                        </div>

                                                        <div class="col-2"></div>
                                                        <!-- <div class="col-3">
                                                            {{auth.allLableShowObjProp[auth.language_cd+'Location108']}}
                                                        </div> -->
                                                        <!--Download format :-->
                                                        <div class="col-2 text-right">
                                                            <button class="btn btn-primary btn-sm btn-block" (click)="export()">
                                                                <!-- {{auth.allLableShowObjProp[auth.language_cd+'Location109']}} -->
                                                                Download Excel Format
                                                            </button>
                                                            <!--Download-->

                                                        </div>
                                                    </div>
                                                    <br>
                                                </div>

                                                <br>
                                                <div class="row">
                                                    <div class="col-1"></div>
                                                    <div class="col-3">
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location34']}}
                                                    </div><!-- Location Geography Area :-->
                                                    <div class="col-2">
                                                        <input type="number"
                                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location34']}}"
                                                            [(ngModel)]="location_area" class="form-control"
                                                            name="" id="">
                                                        <!--Enter Location Geography Area-->
                                                    </div>
                                                    <div class="col-2">
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location36']}}

                                                        <!--Unit :-->
                                                    </div>
                                                    <div class="col-2">
                                                        <input type="text" [(ngModel)]="Obj['loc_geo_ent_value']"
                                                            value="SQM"
                                                            disabled name="" id="">
                                                        <!--Hectare-->

                                                    </div>
                                                    <div class="col-2">
                                                        <button (click)="calculate_area()" >Calculate Area</button>
                                                    </div>
                                                </div>
                                                <div class="row mt-3 justify-content-center">

                                                    <div class="col-2">
                                                        <button class="btn btn-primary btn-sm btn btn-block"
                                                            (click)="modalOpen(1)">{{auth.allLableShowObjProp[auth.language_cd+'Location50']}}</button>
                                                        <!--View On Map-->
                                                    </div>
                                                </div>
                                                <br>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'Location135']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'Location53']}}</button>
                                            <!--Next-->


                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="third"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Location138']}}">
                                    <!--Document-->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <div class="col-12 text-right">
                                                <button (click)="addDocArr()"
                                                    class="btn btn-outline-primary">{{auth.allLableShowObjProp[auth.language_cd+'Location54']}}</button>
                                                <!--Add-->
                                            </div>
                                            <br>
                                            <table class="table table-bordered">
                                                <tbody *ngFor="let item of docArr;let i =index"
                                                    style="width: 96%; margin-left: 2%; margin-top: 2%; margin-bottom: 2%;">
                                                    <!-- <tr>
                                                        <br>
                                                        <hr *ngIf="i != docArr.length-1">
                                                    </tr> -->
                                                    <div class="col-12">
                                                        <div class="row" style="margin: 2%;margin-bottom: 2%;">
                                                            <div class="col-1">
                                                                {{i+1}}
                                                            </div>
                                                            <div class="col-2">
                                                                {{auth.allLableShowObjProp[auth.language_cd+'Location55']}}
                                                            </div>
                                                            <!--Document Type :-->
                                                            <div class="col-3">
                                                                <ng-select
                                                                    [items]="mainService.codeValueTechObj['PROPFLD3']"
                                                                    bindLabel="value" bindValue="code"
                                                                    [multiple]="false"
                                                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location56']}}"
                                                                    [(ngModel)]="item['doc_type_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                    <!--Select Document Type -->
                                                                </ng-select>
                                                            </div>
                                                            <div class="col-3">
                                                                {{auth.allLableShowObjProp[auth.language_cd+'Location57']}}
                                                            </div>
                                                            <!--Document Format :-->
                                                            <div class="col-2">
                                                                {{auth.allLableShowObjProp[auth.language_cd+'Location58']}}
                                                            </div>
                                                            <!--PDF-->
                                                            <div class="col-1" *ngIf="i>0" >
                                                                <Button (click)="deleteDocArr(item,i)"
                                                                    class="btn btn-outline-danger">{{auth.allLableShowObjProp[auth.language_cd+'Location59']}}</Button>
                                                                <!--Delete-->
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="row" style="margin-bottom: 2%;">
                                                            <div class="col-3"></div>
                                                            <div class="col-3">
                                                                <input #file type="file" name="pimage" ng2FileSelect
                                                                    [uploader]="uploader" class="form-control"
                                                                    style="border-color: black"
                                                                    (change)="onFileUpload($event,file.files)">
                                                            </div>
                                                            <div class="col-3">
                                                                <button *ngIf="!item['doc_id']" type="button"
                                                                    class="btn btn-outline-info" (click)='upload(item,i)'><i
                                                                        class="fa fa-upload"
                                                                        aria-hidden="true">&nbsp;</i>{{auth.allLableShowObjProp[auth.language_cd+'Location60']}}</button>
                                                                <!--Upload-->
                                                            </div>
                                                            <div class="col-3">
                                                                <button class="btn btn-outline-info" *ngIf="item['doc_id']"
                                                                    (click)="view(item)">{{auth.allLableShowObjProp[auth.language_cd+'Location61']}}</button>
                                                                <!--View-->
                                                            </div>
                                                        </div>
                                                    </div>

                                                </tbody>
                                            </table>
                                            <br>
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                        matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'Location62']}}</button>
                                                    &nbsp;&nbsp;
                                                    <!--Back-->
                                                    <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                        matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'Location63']}}</button>
                                                </div>
                                                <!--Next-->
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                </mat-step>
                                <mat-step [stepControl]="fourth"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Location139']}}">
                                    <!--Done-->
                                    <!-- <ng-template matStepLabel></ng-template> -->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location64']}}
                                                </div><!-- Location Type :-->
                                                <div class="col-3">
                                                    : {{Obj['v1']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div> <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location65']}}

                                                </div>
                                                <!--Location Identification Standered :-->
                                                <div class="col-3">
                                                    : {{Obj['v2']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location66']}}

                                                </div>
                                                <!--Location Business Identifier :-->
                                                <div class="col-3">
                                                    : {{Obj['loc_id_str']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location67']}}

                                                </div>
                                                <!--Location Short Name :-->
                                                <div class="col-3">
                                                    : {{Obj['loc_short_nm']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location68']}}

                                                </div><!-- Location Long Description :-->
                                                <div class="col-3">
                                                    : {{Obj['loc_long_desc']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location69']}}

                                                </div>
                                                <!--Location Geography :-->
                                                <div class="col-3">
                                                    : {{Obj['v4']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>

                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location70']}}

                                                </div><!--  Unit-->
                                                <div class="col-3">
                                                    : {{Obj['loc_geo_ent_value']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>

                                        </div>
                                    </div>
                                    <br>
                                    <!-- <button (click)="submit()">/submit</button> -->
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'Location71']}}</button>
                                        &nbsp;&nbsp;
                                        <!--Back-->
                                        <button class="btn btn-outline-success"
                                            (click)="submit()">{{auth.allLableShowObjProp[auth.language_cd+'Location72']}}</button>&nbsp;&nbsp;
                                        <!--SUBMIT-->
                                        <!-- <button class="btn btn-warning" (click)="reset()">RESET</button>&nbsp;&nbsp; -->
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>

                        <div *ngIf="update_flag">
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper2>
                                <mat-step [stepControl]="first"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Location136']}}">
                                    <!--Identification-->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location73']}} <span
                                                        style="color: red;"> *</span>
                                                </div><!-- Location Type :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD5']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location73']}}"
                                                        (change)="set_data(1)" [(ngModel)]="Obj['loc_type_cd']"
                                                        [selectableGroup]="true" disabled
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location75']}} <span
                                                        style="color: red;">
                                                        *</span>
                                                    <!--Location Identification Standered :-->
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD6']"
                                                        bindLabel="value" disabled bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location75']}} "
                                                        (change)="set_data(2)" [(ngModel)]="Obj['loc_id_std']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                        <!--Select Location Identification Standered-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location77']}} <span
                                                        style="color: red;"> *</span>

                                                </div><!-- Location Business Identifier :-->
                                                <div class="col-3">
                                                    <input type="text" disabled class="form-control"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location78']}}"
                                                        [(ngModel)]="Obj['loc_id_str']" name="" id="">
                                                </div>
                                                <!--Enter  Location Business Identifier-->
                                                <div class="col-3">

                                                </div>

                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location79']}}
                                                </div>
                                                <!--Location Short Name :-->
                                                <div class="col-3">
                                                    <input type="text"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location80']}}"
                                                        class="form-control" [(ngModel)]="Obj['loc_short_nm']" name=""
                                                        id="">
                                                    <div class="col-3"></div>
                                                    <!--Enter Location Short Name-->
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location81']}}
                                                </div>
                                                <!--Location Long Description :-->
                                                <div class="col-3">
                                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                        [(ngModel)]="Obj['loc_long_desc']"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location82']}}"
                                                        class="form-control"></textarea>
                                                    <!--Enter  Location Long Description-->
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location83']}}
                                                </div>
                                                <!--Location Use Type :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD7']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location84']}}"
                                                        [(ngModel)]="Obj['location_use_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Use Type-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location85']}}
                                                </div><!-- Location Property Type :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD8']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location86']}}"
                                                        [(ngModel)]="Obj['location_property_type']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                        <!--Select Location Property Type-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location87']}}
                                                </div>
                                                <!--Location Admin Code :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD9']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location87']}}"
                                                        [(ngModel)]="Obj['loc_admin_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Property Type-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'Location89']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Location137']}}">
                                    <!--Geography-->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-2"></div>

                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location90']}}
                                                </div>
                                                <!--Location Geography :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD4']"
                                                        bindLabel="value" bindValue="code" (change)="set_data(3)"
                                                        [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location91']}}"
                                                        [(ngModel)]="Obj['loc_geo_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Geography-->
                                                    </ng-select>
                                                    <div class="col-3"></div>
                                                </div>
                                            </div>
                                            <br>

                                            <br>
                                            <div class="card">
                                                <br>
                                                <div class="row">
                                                    <div class="col-3 text-right">
                                                        <input type="radio" class="form-check-input" name="radio2" checked="checked"
                                                            [(ngModel)]="radio2" [value]="false"
                                                            style="height: 20px; width: 20px;">
                                                    </div>
                                                    <div class="col-3">
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location105']}}
                                                    </div>
                                                    <!--Upload GIS Details-->
                                                    <div class="col-3">
                                                    </div>
                                                    <div class="col-3">
                                                        <!-- <button class="btn btn-primary">Add</button> -->
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-12 text-center">
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location104']}}
                                                    </div>
                                                    <!--OR-->
                                                </div>
                                                <br>
                                                <br>
                                                <div class="row">
                                                    <div class="col-3 text-right">
                                                        <input type="radio" class="form-check-input"
                                                            name="radio2" [(ngModel)]="radio2" [value]="true"
                                                            style="height: 20px; width: 20px;">
                                                    </div>
                                                    <div class="col-2">

                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location95']}}
                                                    </div>
                                                    <!--Enter GIS Details-->
                                                    <div class="col-2">
                                                    </div>
                                                    <div class="col-2">
                                                        <button *ngIf="radio2" class="btn btn-primary btn-sm btn-block"
                                                            (click)="addRowInGISArr()">
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location96']}}</button>
                                                        <!--Add-->
                                                    </div>
                                                </div>
                                                <div *ngIf="radio2" class="row mt-3">
                                                    <table class="table table-bordered"
                                                        style="width: 90%; margin-left: 5%;">
                                                        <tbody>
                                                            <tr *ngFor="let item of GISArr;let i =index">
                                                                <td> {{i+1}} Line </td>
                                                                <td>{{auth.allLableShowObjProp[auth.language_cd+'Location97']}}
                                                                </td><!-- 1 Point-->
                                                                <td>
                                                                    <input type="number" [(ngModel)]="item['lat1']"
                                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location98']}}"
                                                                        class="form-control" name="" id="">
                                                                    <!--Enter Latitude-->
                                                                </td>
                                                                <td>
                                                                    <input type="number" [(ngModel)]="item['long1']"
                                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location99']}}"
                                                                        class="form-control" name="" id="">
                                                                    <!--Enter Longitude-->
                                                                </td>
                                                                <td>{{auth.allLableShowObjProp[auth.language_cd+'Location100']}}
                                                                </td>
                                                                <!--2 Point-->
                                                                <td>
                                                                    <input type="number" [(ngModel)]="item['lat2']"
                                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location101']}}"
                                                                        class="form-control" name="" id="">
                                                                    <!--Enter Latitude-->
                                                                </td>
                                                                <td>
                                                                    <input type="number" [(ngModel)]="item['long2']"
                                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location102']}}"
                                                                        class="form-control" name="" id="">
                                                                    <!--Enter Logitude-->
                                                                </td>
                                                                <td>
                                                                    <button (click)="deleteGISRow(item,i)"
                                                                        class="btn btn-outline-danger">{{auth.allLableShowObjProp[auth.language_cd+'Location103']}}</button>
                                                                    <!--Delete-->
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>



                                                <br>
                                                <div *ngIf="!radio2">
                                                    <br>
                                                    <div class="row">
                                                        <div class="col-3 text-right">
                                                            <h6>{{auth.allLableShowObjProp[auth.language_cd+'Location106']}}
                                                            </h6>
                                                            <!--Choose File :-->
                                                        </div>
                                                        <div class="col-3">
                                                            <input type="file" style="display: inline-block;"
                                                                (change)="onFileChange($event)"
                                                                placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location107']}}"
                                                                accept=".xls">
                                                            <!--Upload file-->
                                                        </div>

                                                        <div class="col-2"></div>
                                                        <!-- <div class="col-3">
                                                            {{auth.allLableShowObjProp[auth.language_cd+'Location108']}}
                                                        </div> -->
                                                        <!--Download format :-->
                                                        <div class="col-2 text-right">
                                                            <button class="btn btn-primary btn-sm btn-block" (click)="export()">
                                                                <!-- {{auth.allLableShowObjProp[auth.language_cd+'Location109']}} -->
                                                                Download Excel Format
                                                            </button>
                                                            <!--Download-->

                                                        </div>
                                                    </div>

                                                    <br>
                                                </div>

                                                <div class="row mt-2">
                                                    <div class="col-1"></div>
                                                    <div class="col-3">
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location92']}}
                                                    </div>
                                                    <!--Location Geography Area :-->
                                                    <div class="col-2">
                                                        <input type="number"
                                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location93']}}"
                                                            [(ngModel)]="location_area" class="form-control"
                                                            name="" id="">
                                                        <!--Enter Location Geography Area-->
                                                    </div>
                                                    <div class="col-2">
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Location94']}}
                                                    </div><!-- Unit :-->
                                                    <div class="col-1">
                                                        <input type="text" value="SQM" disabled name="" id="">
                                                    </div>
                                                    <div class="col-2">
                                                        <button (click)="calculate_area()" >Calculate Area</button>
                                                    </div>
                                                </div>
                                                <div class="row mt-3 justify-content-center">

                                                    <div class="col-2">
                                                        <button class="btn btn-primary btn-sm btn btn-block"
                                                            (click)="modalOpen(2)">{{auth.allLableShowObjProp[auth.language_cd+'Location50']}}</button>
                                                        <!--View On Map-->
                                                    </div>
                                                </div>
                                                <br>
                                            </div>


                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'Location111']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'Location112']}}</button>
                                            <!--Next-->


                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="third"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Location138']}}">
                                    <br>
                                    <!--Document-->
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <div class="col-12 text-right">
                                                <button (click)="addDocArr()"
                                                    class="btn btn-outline-primary">{{auth.allLableShowObjProp[auth.language_cd+'Location113']}}</button>
                                                <!--Add-->
                                            </div>
                                            <br>
                                            <table class="table table-bordered">
                                                <tbody *ngFor="let item of docArr;let i =index"
                                                    style="width: 96%; margin-left: 2%; margin-top: 2%; margin-bottom: 2%;">
                                                    <!-- <tr>


                                                        <br>

                                                        <hr *ngIf="i != docArr.length-1">
                                                    </tr> -->
                                                    <div class="col-12">
                                                        <div class="row" style="margin: 2%;margin-bottom: 2%;">
                                                            <div class="col-1">
                                                                {{i+1}}
                                                            </div>
                                                            <div class="col-2">
                                                                {{auth.allLableShowObjProp[auth.language_cd+'Location114']}}
                                                            </div>
                                                            <!--Document Type :-->
                                                            <div class="col-3">
                                                                <ng-select
                                                                    [items]="mainService.codeValueTechObj['PROPFLD3']"
                                                                    bindLabel="value" bindValue="code"
                                                                    [multiple]="false"
                                                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location115']}}"
                                                                    [(ngModel)]="item['doc_type_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                    <!--Select Document Type-->
                                                                </ng-select>
                                                            </div>
                                                            <div class="col-3">
                                                                {{auth.allLableShowObjProp[auth.language_cd+'Location116']}}
                                                            </div>
                                                            <!--Document Format :-->
                                                            <div class="col-2">
                                                                {{auth.allLableShowObjProp[auth.language_cd+'Location117']}}
                                                            </div><!-- PDF-->
                                                            <div class="col-1" >
                                                                <Button (click)="deleteDocArr(item,i)" *ngIf="i>0"
                                                                    class="btn btn-outline-danger">{{auth.allLableShowObjProp[auth.language_cd+'Location118']}}</Button>
                                                                <!--Delete-->
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="row" style="margin-bottom: 2%;">
                                                            <div class="col-3"></div>
                                                            <div class="col-3">
                                                                <input #file type="file" *ngIf="!item['doc_id']"
                                                                    name="pimage" ng2FileSelect [uploader]="uploader"
                                                                    class="form-control" style="border-color: black"
                                                                    (change)="onFileUpload($event,file.files)">
                                                            </div>
                                                            <div class="col-3">
                                                                <button *ngIf="!item['doc_id']" type="button"
                                                                    class="btn btn-outline-info" (click)='upload(item,i)'><i
                                                                        class="fa fa-upload"
                                                                        aria-hidden="true">&nbsp;</i>{{auth.allLableShowObjProp[auth.language_cd+'Location119']}}</button>
                                                                <!--Upload-->
                                                            </div>
                                                            <div class="col-3">
                                                                <button class="btn btn-outline-info" *ngIf="item['doc_id']"
                                                                    (click)="view(item)">{{auth.allLableShowObjProp[auth.language_cd+'Location120']}}</button>
                                                                <!--View-->
                                                            </div>
                                                        </div>
                                                    </div>

                                                </tbody>
                                            </table>


                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'Location121']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'Location122']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="fourth"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Location139']}}">
                                    <!--Done-->
                                    <!-- <ng-template matStepLabel></ng-template> -->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location123']}}
                                                </div>
                                                <!--Location Type :-->
                                                <div class="col-3">
                                                    : {{Obj['v1']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div> <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location124']}}

                                                </div>
                                                <!--Location Identification Standered :-->
                                                <div class="col-3">
                                                    : {{Obj['v2']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location125']}}

                                                </div>
                                                <!--Location Business Identifier :-->
                                                <div class="col-3">
                                                    : {{Obj['loc_id_str']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location126']}}

                                                </div>
                                                <!--Location Short Name :-->
                                                <div class="col-3">
                                                    : {{Obj['loc_short_nm']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location127']}}

                                                </div>
                                                <!--Location Long Description :
                                            -->
                                                <div class="col-3">
                                                    : {{Obj['loc_long_desc']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location128']}}

                                                </div>
                                                <!--Location Geography :-->
                                                <div class="col-3">
                                                    : {{Obj['v4']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>

                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Location129']}}

                                                </div>
                                                <!--Unit-->
                                                <div class="col-3">
                                                    : {{Obj['loc_geo_ent_value']}}
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <!-- <button (click)="submit()">/submit</button> -->
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'Location130']}}</button>
                                        &nbsp;&nbsp;
                                        <!--Back-->
                                        <button class="btn btn-outline-success"
                                            (click)="update()">{{auth.allLableShowObjProp[auth.language_cd+'Location131']}}</button>&nbsp;&nbsp;
                                        <!--Update-->
                                        <!-- <button class="btn btn-warning" (click)="reset()">RESET</button>&nbsp;&nbsp; -->
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>{{auth.allLableShowObjProp[auth.language_cd+'Location132']}}</ngx-spinner>
<!--Loading....-->


<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">
                    {{auth.allLableShowObjProp[auth.language_cd+'Location133']}}
                </h4>
                <!--Location-->
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
                    [zoomControl]="false">

                    <agm-polygon [paths]="paths" [clickable]="false" [visible]="true" (polyClick)="onPolyClick($event)">
                    </agm-polygon>
                </agm-map> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger"
                        data-dismiss="modal">{{auth.allLableShowObjProp[auth.language_cd+'Location134']}}</button>
                    <!--Close-->
                </div>

            </div>
        </div>
    </div>
</div>
