import { Injectable } from '@angular/core';

import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class DashboardServiceService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/icms";
  }


  async getAllDashCount(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/dashboard/getAllDashCount' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }
  async getcomplaint(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/dashboard/getZonedata' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getuser(obj:any) {
    console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/user_track/getuserbyzone' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }
  async getusercordinates(obj:any) {
    console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/user_track/getuserzonecordinates' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }



  async getzonecomplaint(obj:any) {
    console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/getZoneComplaint' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async getallcomplaintdetails(obj:any) {
    console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/getallcomplaintDetails/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async getallassignedcomplaintdetails(obj:any) {
    console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/getallassignedcomplaintDetails/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async getcompletedcomplaint(obj:any) {
    console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/getcomplaintcompleted/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async getnoticedetails(obj:any) {
    // console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/getnoticedetails/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async getAllCasesDtls(obj:any) {
    // console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/getAllCasesDtls/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async CaseInProgress(obj:any) {
    // console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/CaseInProgress/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async Demolitionorders(obj:any) {
    // console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/getDemolitionorder/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async Compunding(obj:any) {
    // console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/getCompundingorder/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async SelfDemolition(obj:any) {
    // console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/getSelfDemolitionorder/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async getLocationData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/zone/getZonedata' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async getuserbyzone(obj:any) {
    // console.log(obj, "fromservice");
    const resp = await this.http.get(this.httpUrl + '/dashboard/getuserbyzone/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  // async  getuserattdenece(obj:any) {
  //   console.log(obj)
  //   const resp = await this.http.get<any>(this.httpUrl +'/dashboard/get_user_attendence/' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
}
