import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AttendaceMangService } from '../attendace-mang.service'
declare var $: any
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-calendar-rule',
  templateUrl: './calendar-rule.component.html',
  styleUrls: ['./calendar-rule.component.css']
})
export class CalendarRuleComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  dataSource:any
  displayedColumns = ['s_no', 'date', 'month', 'year', 'Purpose', 'Action'];
  startYear = new Date().getFullYear();
  // range:{
  //   year:any
  // }[] = [];
  obj: any = {}
  range: any = []
  monthObj:any  = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  }
  erpUser: any;
  ebillUser: any;
  b_acct_id: any;
  constructor(private sanitizer: DomSanitizer, public attendance: AttendaceMangService, private spinner: NgxSpinnerService,) { }
  // calendarOptions: CalendarOptions = {
  //   plugins: [dayGridPlugin],
  //   initialView: 'dayGridMonth',
  //   weekends: false,
  //   events: [
  //     { title: 'Meeting', start: new Date() }
  //   ]
  // };
  list_flag = true
  create_flag = false
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    await this.get_holidaylist();
    for (let i = 0; i < 15; i++) {
      this.range.push({
        year: this.startYear + i
      });
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectedDate = [
    {
      date: null,
    }
  ];
  async open_list() {

    this.list_flag =true
    this.create_flag = false
    await this.get_holidaylist()

  }
  async create_holiday() {
    this.list_flag =  false
    this.create_flag = true
    // await this.get_holidaylist()
  }
  holyday_list = []
  async get_holidaylist() {
    let obj:any  = {}
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show();
    let resp :any = await this.attendance.holiday_list(obj);
    console.log(resp)
    if (resp['error'] == false) {

      resp['data'].map((x:any ) => {
        let date = new Date(x['date'])
        x['year'] = date.getFullYear();
        x['month'] = this.monthObj[date.getMonth() + 1];
        x['day'] = date.getDate();
      })
      this.spinner.hide();
      this.holyday_list = []
      this.holyday_list = resp['data'];
      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.sort = this.sort.toArray()[0];
      this.dataSource.paginator = this.paginator.toArray()[0];
    } else {
      Swal.fire('error', 'server side error', 'error');
      return
    }
  }
  async submit() {
    let obj:any  = {}
    obj['b_acct_id'] = this.b_acct_id
    // obj['year'] = this.obj['year']
    // obj['month'] = this.obj['month']
    obj['date'] = this.selectedDate
    obj['life_cycle_status'] = 'ACTIVE'
    obj['holiday_purpose'] = this.obj['Purpose']
    obj['user_id'] = this.erpUser.le_id

    if (!this.obj['Purpose']) {
      Swal.fire('info', 'All Field are Mandetory', 'info');
      return
    }
    let isEmpty: boolean = false;
    this.selectedDate.map(x => {
      if (!x['date']) {
        isEmpty = true;
      }
    })
    if (isEmpty) {
      Swal.fire('info', 'All Field are Mandetory', 'info');
      return
    }
    this.spinner.show()
    let resp:any  = await this.attendance.insert_holiday(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide();
      Swal.fire('success', resp['data'], 'success');
      await this.get_holidaylist();
      await this.open_list()
      this.obj = {}
      this.selectedDate = []
      this.selectedDate.push({
        date: null
      })
      return
    }
    else {
      Swal.fire('error', 'server side error', 'error');
      return
    }

  }
  addForm() {
    this.selectedDate.push({
      date: null,
    });
  }
  remove(i:any ) {
    this.selectedDate.splice(i, 1)
  }
  update(element:any ) {
    this.selectedDate = []
    // this.obj['year'] = element.year
    // this.obj['month'] = element.month
    this.obj['Purpose'] = element.holiday_purpose
    this.selectedDate.push({ date: element.date })
    this.obj['holiday_id'] = element.holiday_id
    $('#update').modal('show');

  }
  update_day() {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update!'
    }).then(async (result) => {
      if (result.value) {
        let obj:any  = {}
        obj['b_acct_id'] = this.b_acct_id
        // obj['year'] = this.obj['year']
        // obj['month'] = this.obj['month']
        obj['date'] = this.selectedDate[0]['date']
        obj['life_cycle_status'] = 'ACTIVE'
        obj['holiday_purpose'] = this.obj['Purpose']
        obj['user_id'] = this.erpUser.le_id
        obj['holiday_id'] = this.obj['holiday_id']
        this.spinner.show()
        let resp:any  = await this.attendance.update_holiday(obj);
        if (resp['error'] == false) {
          this.spinner.hide();
          Swal.fire('sucess', resp['data'], 'success');
          this.get_holidaylist();
          this.obj = {}
          this.selectedDate = []
          this.selectedDate.push({ date: null, });
          $('#update').modal('hide');

          return
        } else {
          Swal.fire('error', 'server side error', 'error');
          return
        }


      }
    })
  }
  delete(element:any ) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete!'
    }).then(async (result) => {
      if (result.value) {
        let obj:any  = {}
        obj['b_acct_id'] = this.b_acct_id
        obj['holiday_id'] = element.holiday_id
        this.spinner.show()
        let resp:any  = await this.attendance.delete(obj);
        if (resp['error'] == false) {
          this.spinner.hide();
          Swal.fire('success', 'Deleted successfully', 'success');
          this.get_holidaylist();
          return
        } else {
          Swal.fire('error', 'server side error', 'error');
          return
        }

      }
    })
  }
  refresh() {
    this.selectedDate = []
    this.obj = new Object()
    this.selectedDate.push({ date: null, });
  }
  print() {
    // console.log("shwetaa")
    let account_name = this.erpUser['account_name'];
    let account_short_name = this.erpUser['account_short_name'];
    let txt = account_name + "  (" + account_short_name + ") \n";
    let dd:any  = {//margin: [72, 40]
      pageSize: 'A4',
      header: function (currentPage:any , pageCount:any ) {
        let arr = [
          { text: new Date().toDateString() + " " + new Date().toLocaleTimeString(), alignment: 'right', margin: [20, 5], fontSize: 9 },
          { text: txt, alignment: 'center', fontSize: 15, margin: [72, 15], bold: true },
        ]
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    let h1txt = "HOLYDAY CALANDER"
    var header1 = {
      columns: [
        {
          width: '*',
          text: h1txt,
          bold: true,
          alignment: 'center'
        }
      ],
    }

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 510, y2: 0, lineWidth: 0.5, margin: [0, 5] }] }); //x2:760
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });

    let tbl1 = {
      fontSize: 10,
      table: {
        headerRows: 3,
        widths: ['*', '*', '*', '*', '*'],
        body: [
          [{ text: "S.No", alignment: 'center' }, { text: "Date", alignment: 'center' }, { text: "Month", alignment: 'center' }, { text: "Year", alignment: 'center' }, { text: "Purpose", alignment: 'center' }]
        ],
      }
    };

    dd.content.push(tbl1);

    for (let i = 0; i < this.holyday_list.length; i++) {
      let arr = [];
      arr.push({ text: i + 1, alignment: 'center' });
      arr.push({ text: this.holyday_list[i]['day'], alignment: 'center' });
      arr.push({ text: this.holyday_list[i]['month'], alignment: 'center' });
      arr.push({ text: this.holyday_list[i]['year'], alignment: 'center' });
      arr.push({ text: this.holyday_list[i]['holiday_purpose'], alignment: 'center' });

      dd.content[dd.content.length - 1].table.body.push(arr);
    }

    pdfMake.createPdf(dd).download('Holy Day Calander' + '.pdf');

  }
}
