import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import swal from 'sweetalert2';
import { ExcelService } from '../../service/file-export.service';
import { Router } from '@angular/router';
import { PayrollService } from '../../service/payroll.service';
import { MainService } from '../../service/main.service';
import { EstablishmentService } from '../../service/establishment.service';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NgxSpinnerService } from "ngx-spinner";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import * as moment from 'moment';

declare var $: any

@Component({
  selector: 'app-payroll-report',
  templateUrl: './payroll-report.component.html',
  styleUrls: ['./payroll-report.component.css']
})
export class PayrollReportComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private establishmentService: EstablishmentService, private excl: ExcelService, public mainService: MainService, private router: Router, private payableService: PayrollService) { }
  erpUser:any ;
  b_acct_id:any ;
  salaryObj:any  = { accrual_date: '', b_acct_id: '', fin_year: '', month: '' }
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  result:any  = []
  fixedpay:any  = []
  displayedColumns:any  = ['serial_no', 'emp_id', 'emp_name', 'designation_code', 'pay_component_amt', 'start_dt', 'end_dt'];
  datasource:any ;
  allEmplyees:any  = []
  allVariablePay:any  = [];
  obj :any = {}
  reqObj :any = {}
  empObj :any = {};
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;

    await this.getAllEmployees();
    await this.getallcomponent();

  }
  getNumberFormat(num:any ) {
    return num.toString().padStart(3, "0")
  }
  all_component :any = []
  async getallcomponent() {
    var obj :any  = new  Object()
    obj['b_acct_id'] = this.b_acct_id
    var resp1 = await this.payableService.getAllComponent(JSON.stringify(obj));
    if (resp1['error'] == false) {
      // this.all_component = [{ code: '', value: '' }]
      this.all_component = []
      for (let i = 0; i < resp1.data.length; i++) {
        let obj11:any  = new Object();
        obj11['code'] = resp1.data[i]['component_code']
        obj11['value'] = resp1.data[i]['component_code']
        this.all_component.push(obj11)

      }
    }
    console.log(this.all_component)
  }
  async getAllEmployees() {
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    // obj['emp_status_code'] = ["ACTIVE", "INACTIVE"];
    // Avinash
    obj['emp_status_code'] = ["ACTIVE"];

    var resp = await this.establishmentService.getArrayAllCurrentEstablishementInfo(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      var dt1 = resp.data;
      var dt = []
      dt.push({ arr_id: "-1", emp_name: "All Employee", emp_desc: "All Employee" })

      for (var i = 0; i < dt1.length; i++) {
        this.empObj[dt1[i]['emp_id']] = dt1[i];
        dt[i + 1] = dt1[i];
        this.empObj
        dt[i + 1]['emp_desc'] = /* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(dt1[i]['emp_id']) + " - " + dt1[i]['emp_name'];
      }
      this.allEmplyees = dt;



    } else {
      this.spinner.hide()
      swal.fire("Error", "Error while getting All employee Info", "error");
    }
  }


  x :any = [];
  async getFixPay() {


    if (this.reqObj['pay_component_code'] == '' || this.reqObj['pay_component_code'] == null || this.reqObj['pay_component_code'] == undefined ||
      this.reqObj['arr_id'] == '' || this.reqObj['arr_id'] == null || this.reqObj['arr_id'] == undefined) {
      this.datasource = new MatTableDataSource([])
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort
      swal.fire("Warning", '* Field Required', 'warning');
      this.fixedpay = []
      this.x = []
      return;
    }
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    if (this.reqObj['arr_id'] == '' || this.reqObj['arr_id'] == null || this.reqObj['arr_id'] == undefined || this.reqObj['arr_id'] == -1)
    // if(this.reqObj['arr_id'] !=-1)
    {
      obj['arr_id'] = 'All_User';
    }
    else {
      if (this.reqObj['arr_id'] != -1) {
        obj['arr_id'] = this.reqObj['arr_id'];
      }

    }

    if (this.reqObj['pay_component_code'] != undefined) {
      obj['pay_component_code'] = this.reqObj['pay_component_code'];
    }

    var resp = await this.payableService.getAllFixedPay(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      var dt = resp['data'];
      this.x = [];
      for (var i = 0; i < dt.length; i++) {
        if (dt[i]['pay_component_code'] == this.reqObj['pay_component_code']) {
          this.x.push(dt[i])
        }
      }
      //console.log(this.allEmplyees)
      for (var i = 0; i < this.x.length; i++) {
        for (var j = 0; j < this.allEmplyees.length; j++) {
          if (this.x[i]['arr_id'] == this.allEmplyees[j]['arr_id']) {
            this.x[i]['emp_id_desc'] = this.allEmplyees[j]['emp_id'];
            this.x[i]['emp_name'] = this.allEmplyees[j]['emp_name'];
            this.x[i]['designation_code'] = this.allEmplyees[j]['designation_code'];
          }
        }
      }


      for (let i = 0; i < this.x.length; i++) {
        if (this.x[i]['effective_start_dt'] != undefined) {
          this.x[i]['effective_start_dt'] = this.mainService.dateformatchange(this.x[i]['effective_start_dt'])
          this.x[i]['effective_end_dt'] = this.mainService.dateformatchange(this.x[i]['effective_end_dt'])
        }
      }
      this.fixedpay = this.x;
      if (this.x.length == 0) {


        this.datasource = new MatTableDataSource([])
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort

        swal.fire('Info', 'Data Not Found !', 'info');
        return;
      }
      let k = 1
      for (let i = 0; i < this.x.length; i++) {
        this.x[i]['new_index'] = k
        k = k + 1
      }
      this.datasource = new MatTableDataSource(this.x)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort

    } else {

      this.spinner.hide();
      swal.fire("Error", "Error while getting data");
    }

  }



  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }


  export() {
    if (this.fixedpay.length <= 0) {
      swal.fire("Warning", 'No Data Present For Export !', 'warning')
      return;
    }
    var exp = []
    for (var i = 0; i < this.fixedpay.length; i++) {
      var obj :any  = new  Object();
      obj['SNO'] = i + 1;
      obj['EMPLOYEE ID'] = this.fixedpay[i]['emp_id_desc'];
      obj['EMPLOYEE NAME'] = this.fixedpay[i]['emp_name'];
      obj['DESIGNATION'] = this.fixedpay[i]['designation_code'];
      obj['AMOUNT'] = this.fixedpay[i]['pay_component_amt'];
      obj['START DATE'] = this.fixedpay[i]['effective_start_dt'];
      if (this.fixedpay[i]['effective_end_dt'] == "2090-10-10") {
        obj['END DATE'] = "Till Service Period";

      } else {
        obj['END DATE'] = this.fixedpay[i]['effective_end_dt'];

      }
      // obj['END DATE'] = this.fixedpay[i]['effective_end_dt'];


      exp.push(obj);
    }
    this.excl.exportAsExcelFile(exp, this.reqObj['pay_component_code'])
  }
  print() {

    if (this.x.length <= 0) {
      swal.fire("Warning", 'No Data Present For Print !', 'warning')
      return;
    }

    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + " " + this.reqObj['pay_component_code'] + " List";
    var dd:any  = {
      pageSize: 'A4',
      header: function (currentPage:any , pageCount:any ) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },


      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    var tbl = {

      layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*', '*'],

        body: [
          ['SNO', 'Employee ID', 'Employee Name', 'Designation', 'Amount', 'Start Date', 'End Date']


        ]
      }
    };
    dd.content.push(tbl);
    for (var i = 0; i < this.fixedpay.length; i++) {
      var arr = []
      arr.push(i + 1);
      arr.push(this.fixedpay[i]['emp_id_desc'])
      arr.push(this.fixedpay[i]['emp_name']);
      arr.push(this.fixedpay[i]['designation_code']);
      arr.push(this.fixedpay[i]['pay_component_amt']);
      arr.push(this.fixedpay[i]['effective_start_dt']);
      //
      if (this.fixedpay[i]['effective_end_dt'] == "2037-12-01") {
        arr.push("Till Service Period")

      } else {
        arr.push(this.fixedpay[i]['effective_end_dt'])

      }




      dd.content[dd.content.length - 1].table.body.push(arr);

    }






    pdfMake.createPdf(dd).download(this.reqObj['pay_component_code']);
  }
}
