<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!--Land Input--> {{auth.allLableShowObjProp[auth.language_cd+'Land Input1']}}
                    </div>
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item" *ngIf="list_flag">
                            <a class="nav-link active" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i><!-- Land Input List-->
                                {{auth.allLableShowObjProp[auth.language_cd+'Land Input2']}}</a>
                        </li>
                        <li class="nav-item" *ngIf="create_flag">
                            <a class="nav-link" (click)="reset()" data-toggle="tab"><i class="fa fa-plus-circle"
                                    aria-hidden="true"> </i>
                                <!--Create Land Input--> {{auth.allLableShowObjProp[auth.language_cd+'Land Input3']}}
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="update_flag">
                            <a class="nav-link" (click)="reset()" data-toggle="tab"><i class="fa fa-plus-circle"
                                    aria-hidden="true"> </i>
                                {{auth.allLableShowObjProp[auth.language_cd+'Land Input33']}} </a>
                        </li>
                    </ul>
                    <div class="tab-content">

                        <div *ngIf="list_flag">

                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'Land Input11']}}
                                        <span style="color: red;"> *</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="location_hier" bindLabel="hier_buss_name"
                                        bindValue="hier_type_id" [multiple]="false" placeholder="Select..."
                                        [(ngModel)]="Obj['hier_type_id']" (change)="changeList()"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0"
                                        [disabled] = true
                                        >
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Land Input4']}}">
                                    </mat-form-field>
                                </div>
                                <div class="col-6 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                </div>
                            </div>


                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="group_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.group_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="hier_ident_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Land Input
                                            Target Type


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.target_type }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="target_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Land Input
                                            Target Name


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.target_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="life_cycle_status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Planning
                                            Hierarchy

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.path_desc }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="total_area">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--Total Land Input Area-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                            Input8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.total_area }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="unit">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Area Unit
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            hectare </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--Action-->{{auth.allLableShowObjProp[auth.language_cd+'Land Input9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" (click)="open_update(element)">
                                                <!--UPDATE-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                Input34']}}
                                            </button>
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element)">{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                Input24']}}</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>



                        </div>
                        <div *ngIf="create_flag">
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Land Input10']}}">
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Select Location Hierarchy :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="location_hier" bindLabel="hier_buss_name"
                                                        bindValue="hier_type_id" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                                        [selectableGroup]="true" (change)="getAllLevls()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0"
                                                        [disabled] = true
                                                        >
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row" *ngFor="let item of data,let i=index">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Level {{i+1}} :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value"
                                                        bindValue="lvl1_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl1_cd']" (change)='setLeafNode()'
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value"
                                                        bindValue="lvl2_cd" [multiple]="false" (change)='setLeafNode()'
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl2_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()'
                                                        bindLabel="lvl3_value" bindValue="lvl3_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()'
                                                        bindLabel="lvl4_value" bindValue="lvl4_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()'
                                                        bindLabel="lvl5_value" bindValue="lvl5_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()'
                                                        bindLabel="lvl6_value" bindValue="lvl6_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()'
                                                        bindLabel="lvl7_value" bindValue="lvl7_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-3" *ngIf="i==data.length-1">
                                                    <button (click)="add_dropdown(item,i)"
                                                        class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                                            aria-hidden="true"></i></button>
                                                    &nbsp;&nbsp;
                                                    <button (click)="remove_dropdown(item,i)" *ngIf="i!=0"
                                                        class="btn btn-outline-danger">
                                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                            <br>


                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext (click)="setPath_desc()">
                                                <!--Next-->{{auth.allLableShowObjProp[auth.language_cd+'Land Input16']}}
                                            </button>
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Land Input17']}}">
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>
                                                        <!--Selected Target :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input18']}}
                                                    </h6>
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['path_desc']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>
                                                        <!--Select Acquisition Target :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input19']}} <span style="color: red;"> *</span>
                                                    </h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="acq_hier" bindLabel="hier_buss_name"
                                                        bindValue="hier_type_id" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['hier_type_id_acq']"
                                                        [selectableGroup]="true" (change)="getAllLevels_acq()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0"
                                                        [disabled] = true>
                                                    </ng-select>
                                                </div>
                                                <div class="col-2">
                                                    <h6>
                                                        <!--Area :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input20']}}
                                                    </h6>
                                                </div>
                                                <div class="col-2">{{Obj['total_area']}}</div>
                                                <!-- <div class="col-1">
                                                    <h6>
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input21']}}
                                                    </h6>
                                                </div>
                                                <div class="col-1"> <b>
                                                         {auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input22']}}
                                                    </b>
                                                </div> -->
                                            </div>
                                            <br>

                                            <div class="card">
                                                <div class="row" style="margin: 3%;"
                                                    *ngFor="let item of outer_acq_row,let i =index">
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <div class="col-3" *ngIf="i==0">
                                                                <h6>
                                                                    <!--Select Planning Target :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input14']}}
                                                                </h6>
                                                            </div>
                                                            <div class="col-3"></div>
                                                            <div class="col-3">
                                                                <button *ngIf="i==0" (click)="add(i)"
                                                                    class="btn btn-outline-primary"><i
                                                                        class="fa fa-plus-circle"
                                                                        aria-hidden="true"></i></button>
                                                                <button (click)="remove()"
                                                                    class="btn btn-outline-danger">
                                                                    <i class="fa fa-trash-o"
                                                                        aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row" *ngFor="let ob of item['data'],let k=index">
                                                            <div class="col-3"></div>
                                                            <div class="col-3">
                                                                <h6>Select Level {{k+1}} :</h6>
                                                            </div>
                                                            <div class="col-3">
                                                                <ng-select *ngIf="k==0" [items]="item['level1']"
                                                                    bindLabel="lvl1_value" bindValue="lvl1_cd"
                                                                    [multiple]="false" placeholder="Select..."
                                                                    [(ngModel)]="item['lvl1_cd']"
                                                                    [selectableGroup]="true"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==1" [items]="item['level2']"
                                                                    bindLabel="lvl2_value" bindValue="lvl2_cd"
                                                                    [multiple]="false" (change)="setLeaf_node(item,i,k)"
                                                                    placeholder="Select..."
                                                                    [(ngModel)]="item['lvl2_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==2" [items]="item['level3']"
                                                                    bindLabel="lvl3_value"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    bindValue="lvl3_cd" [multiple]="false"
                                                                    placeholder="Select..."
                                                                    [(ngModel)]="item['lvl3_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==3" [items]="item['level4']"
                                                                    bindLabel="lvl4_value" bindValue="lvl4_cd"
                                                                    [multiple]="false" placeholder="Select..."
                                                                    [(ngModel)]="item['lvl4_cd']"
                                                                    [selectableGroup]="true"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==4" [items]="item['level5']"
                                                                    bindLabel="lvl5_value"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    bindValue="lvl5_cd" [multiple]="false"
                                                                    placeholder="Select..."
                                                                    [(ngModel)]="item['lvl5_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==5" [items]="item['level6']"
                                                                    bindLabel="lvl6_value"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    bindValue="lvl6_cd" [multiple]="false"
                                                                    placeholder="Select..."
                                                                    [(ngModel)]="item['lvl6_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==6" [items]="item['level7']"
                                                                    bindLabel="lvl7_value" bindValue="lvl7_cd"
                                                                    [multiple]="false" placeholder="Select..."
                                                                    [(ngModel)]="item['lvl7_cd']"
                                                                    [selectableGroup]="true"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                            </div>
                                                            <div class="col-3" *ngIf="k==item['data'].length-1">
                                                                <button (click)="add_dropdown_acq(item,i,k)"
                                                                    class="btn btn-outline-primary"><i
                                                                        class="fa fa-arrow-down"
                                                                        aria-hidden="true"></i></button>
                                                                &nbsp;&nbsp;
                                                                <button *ngIf="k==item['data'].length-1"
                                                                    (click)="remove_dropdown_acq(item,i,k)"
                                                                    class="btn btn-outline-danger">
                                                                    <i class="fa fa-arrow-up"
                                                                        aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="col-12" style="margin-top: 2%;">
                                                        <div class="row"
                                                            *ngFor="let ob2 of item['location'],let k =index">
                                                            <div class="col-2">
                                                                <ng-select
                                                                    [items]="mainService.codeValueTechObj['PROPFLD5']"
                                                                    bindLabel="value" bindValue="code"
                                                                    [multiple]="false"
                                                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Land Input13']}}"
                                                                    (change)="change_data(ob2,i,k)"
                                                                    [(ngModel)]="ob2['loc_type_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                            </div>
                                                            <div class="col-2">
                                                                <ng-select [items]="ob2['area']"
                                                                    bindLabel="loc_short_nm" bindValue="loc_id"
                                                                    [multiple]="false"
                                                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Land Input13']}}"
                                                                    [(ngModel)]="ob2['node_cd']"
                                                                    (change)="setRowData(ob2)" [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                            </div>
                                                            <div class="col-1">
                                                                <h6>
                                                                    <!--area-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input20']}}
                                                                </h6>
                                                            </div>
                                                            <div class="col-1">
                                                                {{ob2['loc_geo_calc_value']}}
                                                            </div>
                                                            <div class="col-1">
                                                                <h6>
                                                                    <!--Unit-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input21']}}
                                                                </h6>
                                                            </div>
                                                            <div class="col-1">
                                                                <!--Hectare-->
                                                                {{ob2['loc_geo_ent_value']}}
                                                            </div>
                                                            <div class="col-1">
                                                                <h6>
                                                                    <!--Land Use-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input30']}}
                                                                </h6>
                                                            </div>
                                                            <div class="col-1">
                                                                {{ob2['location_use_type']}}
                                                            </div>
                                                            <div class="col-2">
                                                                <button *ngIf="k==item['location'].length-1"
                                                                    (click)="add_data(i,k)"
                                                                    class="btn btn-outline-primary"><i
                                                                        class="fa fa-plus-circle"
                                                                        aria-hidden="true"></i></button>

                                                                <button (click)="remove_data(ob2,i,k)"
                                                                    class="btn btn-outline-danger">
                                                                    <i class="fa fa-trash-o"
                                                                        aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                Input26']}}</button> &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext (click)="setDone_data()">
                                                <!--Next-->{{auth.allLableShowObjProp[auth.language_cd+'Land Input16']}}
                                            </button>


                                        </div>
                                    </div>
                                </mat-step>

                                <mat-step [stepControl]="third"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Land Input27']}}">
                                    <!-- <ng-template matStepLabel></ng-template> -->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>
                                                        <!--Selected Target :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input28']}}
                                                    </h6>
                                                </div>
                                                <div class="col-7">
                                                    {{Obj['path_desc']}}
                                                </div>
                                                <div class="col-1"></div>

                                                <!-- <div class="col-2">
                                                Hierarchy code
                                            </div>
                                            <div class="col-2">
                                                <input type="text" [(ngModel)]="Obj['hier_ident_code']" disabled
                                                    class="form-control" name="" id="">
                                            </div>
                                            <div class="col-2">
                                                Hierarchy Name
                                            </div>
                                            <div class="col-2">
                                                <input type="text" [(ngModel)]="Obj['hier_buss_name']" disabled
                                                    class="form-control" name="" id="">
                                            </div>
                                            <div class="col-2">
                                                Hierarchy Type
                                            </div>
                                            <div class="col-2">
                                                <input type="text" [(ngModel)]="Obj['hier_type']" disabled
                                                    class="form-control" name="" id="">
                                            </div> -->
                                            </div>

                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>
                                                        <!--Total Land Input :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input29']}}
                                                    </h6>
                                                </div>
                                                <div class="col-7">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <!--Land Use-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input30']}}
                                                                </th>
                                                                <th>
                                                                    <!--Area (Hectare)-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input31']}}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of done_Table">
                                                                <td>{{item['location_use_type']}}</td>
                                                                <td>{{item['loc_geo_calc_value']}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="col-1"></div>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>
                                            <!--Back-->{{auth.allLableShowObjProp[auth.language_cd+'Land Input26']}}
                                        </button> &nbsp;&nbsp;
                                        <button class="btn btn-outline-success" (click)="submit()">
                                            <!--SUBMIT-->{{auth.allLableShowObjProp[auth.language_cd+'Land Input32']}}
                                        </button>&nbsp;&nbsp;
                                        <!-- <button class="btn btn-warning" >RESET</button>&nbsp;&nbsp; -->
                                    </div>

                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>

                        <div *ngIf="update_flag">
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Land Input10']}}">
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Select Location Hierarchy :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="location_hier" bindLabel="hier_buss_name"
                                                        bindValue="hier_type_id" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                                        [selectableGroup]="true" (change)="getAllLevls()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row" *ngFor="let item of data,let i=index">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Level {{i+1}} :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value"
                                                        bindValue="lvl1_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl1_cd']" (change)='setLeafNode()'
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value"
                                                        bindValue="lvl2_cd" [multiple]="false" (change)='setLeafNode()'
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl2_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()'
                                                        bindLabel="lvl3_value" bindValue="lvl3_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()'
                                                        bindLabel="lvl4_value" bindValue="lvl4_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()'
                                                        bindLabel="lvl5_value" bindValue="lvl5_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()'
                                                        bindLabel="lvl6_value" bindValue="lvl6_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()'
                                                        bindLabel="lvl7_value" bindValue="lvl7_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-3" *ngIf="i==data.length-1">
                                                    <button (click)="add_dropdown(item,i)"
                                                        class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                                            aria-hidden="true"></i></button>
                                                    &nbsp;&nbsp;
                                                    <button (click)="remove_dropdown(item,i)" *ngIf="i!=0"
                                                        class="btn btn-outline-danger">
                                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                            <br>


                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext (click)="setPath_desc()">
                                                <!--Next-->{{auth.allLableShowObjProp[auth.language_cd+'Land Input16']}}
                                            </button>
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Land Input17']}}">
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>
                                                        <!--Selected Target :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input18']}}
                                                    </h6>
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['path_desc']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>
                                                        <!--Select Acquisition Target :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input19']}} <span style="color: red;"> *</span>
                                                    </h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="acq_hier" bindLabel="hier_buss_name"
                                                        bindValue="hier_type_id" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['hier_type_id_acq']"
                                                        [selectableGroup]="true" (change)="getAllLevels_acq()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-2">
                                                    <h6>
                                                        <!--Area :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input20']}}
                                                    </h6>
                                                </div>
                                                <div class="col-2">{{Obj['total_area']}}</div>
                                                <!-- <div class="col-1">
                                                    <h6>
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input21']}}
                                                    </h6>
                                                </div>
                                                <div class="col-1"> <b>
                                                        {{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input22']}}
                                                    </b>
                                                </div> -->
                                            </div>
                                            <br>

                                            <div class="card">
                                                <div class="row" style="margin: 3%;"
                                                    *ngFor="let item of outer_acq_row,let i =index">
                                                    <div class="col-6">
                                                        <div class="row">
                                                            <div class="col-3" *ngIf="i==0">
                                                                <h6>
                                                                    <!--Select Planning Target :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input14']}}
                                                                </h6>
                                                            </div>
                                                            <div class="col-3"></div>
                                                            <div class="col-3">
                                                                <button *ngIf="i==0" (click)="add(i)"
                                                                    class="btn btn-outline-primary"><i
                                                                        class="fa fa-plus-circle"
                                                                        aria-hidden="true"></i></button>
                                                                <button (click)="remove()"
                                                                    class="btn btn-outline-danger">
                                                                    <i class="fa fa-trash-o"
                                                                        aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row" *ngFor="let ob of item['data'],let k=index">
                                                            <div class="col-3"></div>
                                                            <div class="col-3">
                                                                <h6>Select Level {{k+1}} :</h6>
                                                            </div>
                                                            <div class="col-3">
                                                                <ng-select *ngIf="k==0" [items]="item['level1']"
                                                                    bindLabel="lvl1_value" bindValue="lvl1_cd"
                                                                    [multiple]="false" placeholder="Select..."
                                                                    [(ngModel)]="item['lvl1_cd']"
                                                                    [selectableGroup]="true"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==1" [items]="item['level2']"
                                                                    bindLabel="lvl2_value" bindValue="lvl2_cd"
                                                                    [multiple]="false" (change)="setLeaf_node(item,i,k)"
                                                                    placeholder="Select..."
                                                                    [(ngModel)]="item['lvl2_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==2" [items]="item['level3']"
                                                                    bindLabel="lvl3_value"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    bindValue="lvl3_cd" [multiple]="false"
                                                                    placeholder="Select..."
                                                                    [(ngModel)]="item['lvl3_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==3" [items]="item['level4']"
                                                                    bindLabel="lvl4_value" bindValue="lvl4_cd"
                                                                    [multiple]="false" placeholder="Select..."
                                                                    [(ngModel)]="item['lvl4_cd']"
                                                                    [selectableGroup]="true"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==4" [items]="item['level5']"
                                                                    bindLabel="lvl5_value"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    bindValue="lvl5_cd" [multiple]="false"
                                                                    placeholder="Select..."
                                                                    [(ngModel)]="item['lvl5_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==5" [items]="item['level6']"
                                                                    bindLabel="lvl6_value"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    bindValue="lvl6_cd" [multiple]="false"
                                                                    placeholder="Select..."
                                                                    [(ngModel)]="item['lvl6_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                                <ng-select *ngIf="k==6" [items]="item['level7']"
                                                                    bindLabel="lvl7_value" bindValue="lvl7_cd"
                                                                    [multiple]="false" placeholder="Select..."
                                                                    [(ngModel)]="item['lvl7_cd']"
                                                                    [selectableGroup]="true"
                                                                    (change)="setLeaf_node(item,i,k)"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                            </div>
                                                            <div class="col-3" *ngIf="k==item['data'].length-1">
                                                                <button (click)="add_dropdown_acq(item,i,k)"
                                                                    class="btn btn-outline-primary"><i
                                                                        class="fa fa-arrow-down"
                                                                        aria-hidden="true"></i></button>
                                                                &nbsp;&nbsp;
                                                                <button *ngIf="k==item['data'].length-1"
                                                                    (click)="remove_dropdown_acq(item,i,k)"
                                                                    class="btn btn-outline-danger">
                                                                    <i class="fa fa-arrow-up"
                                                                        aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="col-12" style="margin-top: 2%;">
                                                        <div class="row"
                                                            *ngFor="let ob2 of item['location'],let k =index">
                                                            <div class="col-2">
                                                                <ng-select
                                                                    [items]="mainService.codeValueTechObj['PROPFLD5']"
                                                                    bindLabel="value" bindValue="code"
                                                                    [multiple]="false"
                                                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Land Input13']}}"
                                                                    (change)="change_data(ob2,i,k)"
                                                                    [(ngModel)]="ob2['loc_type_cd']"
                                                                    [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                            </div>
                                                            <div class="col-2">
                                                                <ng-select [items]="ob2['area']"
                                                                    bindLabel="loc_short_nm" bindValue="loc_id"
                                                                    [multiple]="false"
                                                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Land Input13']}}"
                                                                    [(ngModel)]="ob2['node_cd']"
                                                                    (change)="setRowData(ob2)" [selectableGroup]="true"
                                                                    [selectableGroupAsModel]="true"
                                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                                </ng-select>
                                                            </div>
                                                            <div class="col-1">
                                                                <h6>
                                                                    <!--area-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input20']}}
                                                                </h6>
                                                            </div>
                                                            <div class="col-1">
                                                                {{ob2['loc_geo_calc_value']}}
                                                            </div>
                                                            <div class="col-1">
                                                                <h6>
                                                                    <!--Unit-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input21']}}
                                                                </h6>
                                                            </div>
                                                            <div class="col-1">
                                                                {{ob2['loc_geo_ent_value']}}
                                                            </div>
                                                            <div class="col-1">
                                                                <h6>
                                                                    <!--Land Use-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input30']}}
                                                                </h6>
                                                            </div>
                                                            <div class="col-1">
                                                                {{ob2['location_use_type']}}
                                                            </div>
                                                            <div class="col-2">
                                                                <button *ngIf="k==item['location'].length-1"
                                                                    (click)="add_data(i,k)"
                                                                    class="btn btn-outline-primary"><i
                                                                        class="fa fa-plus-circle"
                                                                        aria-hidden="true"></i></button>

                                                                <button (click)="remove_data(ob2,i,k)"
                                                                    class="btn btn-outline-danger">
                                                                    <i class="fa fa-trash-o"
                                                                        aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                Input26']}}</button> &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext (click)="setDone_data()">
                                                <!--Next-->{{auth.allLableShowObjProp[auth.language_cd+'Land Input16']}}
                                            </button>


                                        </div>
                                    </div>
                                </mat-step>

                                <mat-step [stepControl]="third"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'Land Input27']}}">
                                    <!-- <ng-template matStepLabel></ng-template> -->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>
                                                        <!--Selected Target :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input28']}}
                                                    </h6>
                                                </div>
                                                <div class="col-7">
                                                    {{Obj['path_desc']}}
                                                </div>
                                                <div class="col-1"></div>

                                                <!-- <div class="col-2">
                                                Hierarchy code
                                            </div>
                                            <div class="col-2">
                                                <input type="text" [(ngModel)]="Obj['hier_ident_code']" disabled
                                                    class="form-control" name="" id="">
                                            </div>
                                            <div class="col-2">
                                                Hierarchy Name
                                            </div>
                                            <div class="col-2">
                                                <input type="text" [(ngModel)]="Obj['hier_buss_name']" disabled
                                                    class="form-control" name="" id="">
                                            </div>
                                            <div class="col-2">
                                                Hierarchy Type
                                            </div>
                                            <div class="col-2">
                                                <input type="text" [(ngModel)]="Obj['hier_type']" disabled
                                                    class="form-control" name="" id="">
                                            </div> -->
                                            </div>

                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>
                                                        <!--Total Land Input :-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                        Input29']}}
                                                    </h6>
                                                </div>
                                                <div class="col-7">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <!--Land Use-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input30']}}
                                                                </th>
                                                                <th>
                                                                    <!--Area (Hectare)-->{{auth.allLableShowObjProp[auth.language_cd+'Land
                                                                    Input31']}}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of done_Table">
                                                                <td>{{item['location_use_type']}}</td>
                                                                <td>{{item['loc_geo_calc_value']}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="col-1"></div>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>
                                            <!--Back-->{{auth.allLableShowObjProp[auth.language_cd+'Land Input26']}}
                                        </button> &nbsp;&nbsp;
                                        <button class="btn btn-outline-success" (click)="update()">
                                            <!--SUBMIT--> Update
                                        </button>&nbsp;&nbsp;
                                        <!-- <button class="btn btn-warning" >RESET</button>&nbsp;&nbsp; -->
                                    </div>

                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>

                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>Loading....</ngx-spinner>
