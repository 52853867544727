import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TooltipPosition } from "@angular/material/tooltip";
import { AuthenticationService } from "../../../authentication.service";
import { MainService } from "../../service/main.service";
import { MainService as mainserviceEmb } from "../../../emb/service/main.service";
import { MainService as Portalmain } from "../../../portal/service/main.service";
import { ApprovalUserService } from "../../../portal/service/approval-user.service";
import { SettingService } from "../../service/setting.service";
import { PropHierarchyService } from "../../service/prop-hierarchy.service";
import { PropCommonSService } from "../../service/prop-common-s.service";
import { LocationService } from "../../service/location.service";
import { LeaseService } from "../../service/lease.service";
import { DomSanitizer } from "@angular/platform-browser";
import { LandInputService } from "../../service/land-input.service";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
import Swal from "sweetalert2";
import { SaleService } from "../../service/sale.service";
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
@Component({
  selector: "app-lease-allotment",
  templateUrl: "./lease-allotment.component.html",
  styleUrls: ["./lease-allotment.component.css"],
})
export class LeaseAllotmentComponent implements OnInit {
  data : any= [{}];
  propObj: any = {};
  b_acct_id: any;
  ebillUser: any;
  // 'address', 'details', , 'start date', 'end date',
  displayedColumns: string[] = [
    "sno",
    "target_path",
    "lease type",
    "file no",
    "plot no",
    "area",
    "action",
  ];
  dataSource: any;
  list_flag: any = true;
  create_flag: any = false;

  // Rent duration hardcoded
  rent_duration = ["Life Time", "Yearly"];

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  constructor(
    private spinner: NgxSpinnerService,
    public auth: AuthenticationService,
    public ApprovalUserService: ApprovalUserService,
    public mainserviceEmb: mainserviceEmb,
    private settingService: SettingService,
    private propHierS: PropHierarchyService,
    private commonS: PropCommonSService,
    public mainService: MainService,
    private hierarchyS: PropHierarchyService,
    private leaseservice: LeaseService
  ) {}

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getAllHier();
    await this.getAllLevls2();
    await this.getAllplanLayoutForOffer();
    await this.getproject();
    await this.getLeaseAllotment();
  }
  Obj : any= {};
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  open_create() {
    this.list_flag = false;
    this.create_flag = true;
  }
  open_list() {
    this.getAllLevls();
    this.list_flag = true;
    this.create_flag = false;
    this.Obj = {};
    this.propObj = {};
  }

  propertyHierNodes : any= {};
  existing_heirarchy_nodes : any= [];
  async getAllpropHierarchynodes() {
    this.spinner.show();
    this.existing_heirarchy_nodes = [];
    let obj : any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["hier_ident_code"] = this.propObj["hier_ident_code"];
    obj["status"] = "ACTIVE";
    ////console(obj)
    var resp  : any= await this.hierarchyS.getAllpropHierarchynodes(
      JSON.stringify(obj)
    );
    //console(resp, 'list all heirarchy')
    if (resp["error"] == false) {
      this.existing_heirarchy_nodes = resp["data"];
      let dummy = [];
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        this.propertyHierNodes[this.existing_heirarchy_nodes[i]["leaf_cd"]] =
          this.existing_heirarchy_nodes[i];
        if (1 == this.existing_heirarchy_nodes[i]["level"]) {
          dummy.push(this.existing_heirarchy_nodes[i]);
        }
      }
      // this.planing_arr[0]['existing_heirarchy_nodes'] = dummy
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error While Getting Data5", "error");
    }
  }

  plan_layout : any = [];
  selected_hier_id : any = 0;
  set_layout_data(item : any, i : any) {
    this.plan_layout = [];
    let n = 0;
    for (let j = 1; j <= 8; j++) {
      if (this.Obj["lvl" + j + "_cd"]) {
        n = j;
      }
    }
    let hier_id = 0;
    for (let i = 0; i < this.allHier.length; i++) {
      if (
        this.allHier[i]["lvl" + n + "_cd"] == this.Obj["lvl" + n + "_cd"] &&
        this.Obj["lvl" + n + "_cd"] == this.allHier[i]["leaf_cd"]
      ) {
        {
          console.log(this.allHier[i]);
          hier_id = this.allHier[i]["prop_hier_id"];
          this.selected_hier_id = hier_id;
        }
      }
    }
    let dummy : any = [];
    for (let i = 0; i < this.allPlan_layout.length; i++) {
      if (
        this.allPlan_layout[i]["hier_type_id"] == this.Obj["hier_type_id"] &&
        this.allPlan_layout[i]["prop_hier_id"] >= hier_id
      ) {
        dummy.push(this.allPlan_layout[i]);
        console.log(
          hier_id,
          this.allPlan_layout[i]["prop_hier_id"],
          this.allPlan_layout[i]["hier_type_id"]
        );
      }
    }
    this.plan_layout = dummy;
    console.log(this.plan_layout, "plan_layout");
  }
  async add_dropdown(data : any, index : any) {
    if (index > 5) {
      Swal.fire("Information...", "You Can Select Till Level 7 Only", "info");
    } else {
      this.data.push({});
    }
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.allHier, this.Obj);
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.allHier, this.Obj);
    } else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.allHier, this.Obj);
    } else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.allHier, this.Obj);
    } else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.allHier, this.Obj);
    } else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.allHier, this.Obj);
    }
  }

  level1 : any = [];
  level2 : any = [];
  level3 : any = [];
  level4 : any = [];
  level5  : any= [];
  level6 : any = [];
  level7 : any = [];
  leaf_level : any = 0;
  remove_dropdown(data : any, index : any) {
    if (this.data.length > 1) {
      this.data.splice(index, 1);
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.Obj["lvl" + i + "_cd"];
    }
    let dummy = [];
    for (let i = 1; i <= 8; i++) {
      if (this.Obj["lvl" + i + "_cd"]) {
        this.Obj["leaf_cd"] = this.Obj["lvl" + i + "_cd"];
        this.Obj["node_cd"] = this.Obj["lvl" + i + "_cd"];
        this.leaf_level = i;
        dummy.push(this.Obj["lvl" + i + "_value"]);
      } else {
        break;
      }
    }
    // this.project_obj['project_path_desc'] = dummy.join('-->')
  }
  first_list : any = [];

  async getAllHier() {
    this.first_list = [];
    var obj : any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["status"] = "ACTIVE";
    obj["buss_ent_type"] = "Location";
    //console.log(obj)
    var resp  : any= await this.propHierS.getLocationHierarchy(JSON.stringify(obj));
    if (resp["error"] == false) {
      //console.log(resp, 'list data')
      this.first_list = resp["data"];
      for (let i = 0; i < this.first_list.length; i++) {
        if (this.first_list[i]["hier_type"] != "Planing") {
          this.first_list.splice(i, 1);
        }
      }
      for (let i = 0 + 1; i <= 8; i++) {
        delete this.Obj["lvl" + i + "_cd"];
      }
      this.data = [];
      this.data = [{}];
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error While Getting Data5", "error");
    }
  }
  allHier : any = [];
  hier_node_obj : any = {};
  async getAllLevls() {
    var obj  : any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["status"] = "ACTIVE";
    obj["hier_type_id"] = this.Obj["hier_type_id"];
    console.log(obj);
    var resp  : any= await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp["error"] == false) {
      console.log(resp, " data");
      this.allHier = resp.data;
      this.level1 = await this.commonS.refresh(this.allHier);
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error While Getting Data5", "error");
    }
  }
  async getAllLevls2() {
    var obj  : any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["status"] = "ACTIVE";
    var resp  : any= await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp["error"] == false) {
      //console.log(resp, ' data')
      let data = resp.data;
      for (let i = 0; i < data.length; i++) {
        this.hier_node_obj[data[i]["prop_hier_id"]] = data[i];
      }
      console.log(this.hier_node_obj, "hier_node_obj");
    } else {
    }
  }
  allPlan_layout : any = [];
  async getAllplanLayoutForOffer() {
    this.spinner.show();
    let obj : any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["group_type"] = "LEASELAYOUT";
    var resp  : any= await this.leaseservice.getpropertygroup(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.allPlan_layout = resp["data"];
      for (let i = 0; i < resp["data"].length; i++) {
        resp["data"][i]["desc"] =
          "Lease Layout " +
          resp["data"][i]["leaf_value"] +
          " Having Total Property - " +
          resp["data"][i]["count"];
        // resp['data'][i]['desc'] = 'Plan Layout Number ' + resp['data'][i]['property_group_id'] + ' Having Propetry ' + ' - ' + resp['data'][i]['count']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error While Getting Data6", "error");
    }
  }
  allProTypeAvailable : any = [];
  async getAllProType() {
    let obj : any= {};
    if (!this.propObj["id"]) {
      return;
    }
    this.allProTypeAvailable = [];
    let protype : any = []; // just for making allProtypeAvailable Unique obj
    obj["b_acct_id"] = this.b_acct_id;
    obj["property_lease_group_id"] = this.propObj["id"];
    let resp  : any= await this.leaseservice.getpropertyGroupdata(
      JSON.stringify(obj)
    ); // getting all free property
    console.log(resp);
    if (resp["error"] == false) {
      this.allFetchedProToShow = resp["data"];
      console.log(resp["data"]);
      for (let index = 0; index < resp["data"].length; index++) {
        const element = resp["data"][index];
        // console.log(!this.allProTypeAvailable.includes(element.property_type))
        if (!protype.includes(element.property_type)) {
          this.allProTypeAvailable.push({
            property_type: element.property_type,
          });
          protype.push(element.property_type); //used as helper of making allprotype unique
        }
      }
      console.log(this.allProTypeAvailable);
    } else {
      Swal.fire("Error", "Error While Getting Properties", "error");
    }
  }
  allFetchedProToShow : any;

  allProperties : any;
  async show_proprties() {
    console.log(this.propObj);
    let obj : any= {};
    if (this.propObj["property_type"]) {
      this.propObj["b_acct_id"] = this.b_acct_id;
      this.propObj["property_lease_group_id"] = this.propObj["id"];
      let resp = this.allFetchedProToShow;
      let dummy = [];
      for (let i = 0; i < resp.length; i++) {
        resp[i]["is_checked"] = false;
        resp[i]["index"] = i;
        resp[i]["property_no"] = resp[i]["assigned_property_number"];
        if (this.propObj["property_type"] == resp[i]["property_type"]) {
          dummy.push(resp[i]);
        }
      }
      console.log(dummy);
      this.allProperties = dummy;
    } else {
      Swal.fire("Error", "Error While Getting Properties", "error");
    }
  }

  async submit() {
    if (
      !this.Obj["file_number"] ||
      !this.Obj["hier_type_id"] ||
      !this.propObj["id"] ||
      !this.propObj["property_type"] ||
      !this.propObj["property_id"] ||
      !this.Obj["applicant_name"] ||
      !this.Obj["start_date"] ||
      !this.Obj["duration"] ||
      !this.Obj["renewal_period"] ||
      !this.Obj["rent"] ||
      !this.Obj["rent_duration"] ||
      !this.Obj["rent_increment"] ||
      !this.Obj["rent_increment_period"]
    ) {
      Swal.fire("Inforamtion", "Please Select All * Fields", "info");
    } else {
      let dt = new Date();
      let hh: string | number = dt.getHours();
      let mm: string | number = dt.getMinutes();
      let ss: string | number = dt.getSeconds();
      if (hh < 10) {
        hh = "0" + hh;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      let currentTime = hh + ":" + mm + ":" + ss;
      console.log(currentTime);
      let MM: string | number = dt.getMonth() + 1;
      let dd: string | number = dt.getDate();
      let yyyy: string | number = dt.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }
      if (MM < 10) {
        MM = "0" + MM;
      }
      let currentDate = yyyy + "-" + MM + "-" + dd;
      console.log(currentDate);
      this.Obj["arr_effective_date"] = currentDate;
      this.Obj["arr_type_code"] = "APPLIED";
      this.Obj["arr_status_code"] = "ALLOTED";
      this.Obj["user_id"] = this.ebillUser.user_id;
      this.Obj["prop_hier_id"] = this.selected_hier_id;
      this.Obj["data"] = this.propObj;
      this.Obj["b_acct_id"] = this.b_acct_id;
      this.Obj["lease_duration_type"] = "Years";
      this.Obj["allotement_time"] = currentTime;
      this.spinner.show();
      console.log(this.Obj);
      var resp  : any= await this.leaseservice.createLeaseAllotment(this.Obj);
      if (resp["error"] == false) {
        console.log(resp, " data");
        Swal.fire("success", "Successfully submitted", "success");
        this.open_list();
        this.getLeaseAllotment();
      } else {
        this.spinner.hide();
        Swal.fire("Error", "Something Went Wrong!", "error");
      }
    }
  }
  async checkFilenum() {
    this.spinner.show();
    let obj : any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["file_number"] = this.Obj["file_number"];
    console.log(obj);
    var resp  : any= await this.leaseservice.checkFilenum(obj);
    if (resp["error"] == false) {
      console.log(resp, " data");
      if (resp.data.length > 0) {
        Swal.fire("Info", "File number Already Exist", "info");
        this.Obj["file_number"] = null;
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Something Went Wrong!", "error");
    }
  }
  projecthier : any = [];
  projhierobj  : any= {};
  projhierobjtype : any = {};
  projhierobjtypecd  : any= {};
  project_hier  : any= [];
  projectKeyValue  : any= {};
  leaf_user_cd : any = [];
  async getproject() {
    let obj  : any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "PROPERTY";
    let resp  : any= await this.hierarchyS.getprojectHierarchy(obj);
    console.log(resp);
    for (let i = 0; i < resp.data.length; i++) {
      this.projectKeyValue[resp["data"][i]["leaf_cd"]] = resp["data"][i];
      if (resp.data[i]["lvl1_value"] == "All Projects") {
        resp.data[i]["lvl1_value"] = "PROPERTY-root";
      }
    }
    this.project_hier = resp["data"];
    let arr : any = [];
    let duumy : any = [];
    this.leaf_user_cd = [];
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!arr.includes(this.project_hier[i]["lvl1_cd"])) {
        duumy.push({
          lvl1_cd: this.project_hier[i]["lvl1_cd"],
          lvl1_value: this.project_hier[i]["lvl1_value"],
          lvl1_node_type: this.project_hier[i]["lvl1_node_type"],
        });
        arr.push(this.project_hier[i]["lvl1_cd"]);
      }
      if (!this.leaf_user_cd.includes(this.project_hier[i]["leaf_user_cd"])) {
        this.leaf_user_cd.push(this.project_hier[i]["leaf_user_cd"]);
      }
    }
    this.level1 = duumy;
    console.log(
      this.project_hier,
      this.level1,
      "klklklklkllllllllllllllllklklklk"
    );
    console.log("Leaf User Code : ", this.leaf_user_cd);
  }
  codeValueObj : any = {};
  codeValueShowObj : any = {};
  async getCodeValue_obj() {
    this.spinner.show();
    var resp  : any= await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj : any = {};
    var codeValueShowTempObj : any = {};
    if (resp["error"] == false) {
      this.spinner.hide();
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]["field_code"]] == undefined) {
          codeValueTempObj[resp.data[i]["field_code"]] = [];
          codeValueShowTempObj[resp.data[i]["field_code"]] = {};
        }
        codeValueShowTempObj[resp.data[i]["field_code"]][resp.data[i].code] =
          resp.data[i].value;
        codeValueTempObj[resp.data[i]["field_code"]].push(resp.data[i]);
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide();
    }
  }
  setLeafNode() {
    for (let i = 1; i <= 8; i++) {
      if (this.Obj["lvl" + i + "_cd"]) {
        this.Obj["leaf_cd"] = this.Obj["lvl" + i + "_cd"];
        // this.objToSubmit['project_cd']=this.Obj['lvl' + i + '_cd']
        this.leaf_level = i;
      } else {
        break;
      }
    }
  }
  setPath_desc() {
    let n = 0;
    for (let j = 1; j <= 8; j++) {
      if (this.Obj["lvl" + j + "_cd"]) {
        n = j;
      }
    }
    let hier_id = 0;
    for (let i = 0; i < this.allHier.length; i++) {
      if (
        this.allHier[i]["lvl" + n + "_cd"] == this.Obj["lvl" + n + "_cd"] &&
        this.Obj["lvl" + n + "_cd"] == this.allHier[i]["leaf_cd"]
      ) {
        {
          console.log(this.allHier[i]);
          hier_id = this.allHier[i]["prop_hier_id"];
        }
      }
    }
    let ob = this.hier_node_obj[hier_id];
    console.log(this.allPlan_layout, this.hier_node_obj, this.allHier, ob);
    let dummy2 = [];
    for (let j = 1; j <= 8; j++) {
      if (ob["lvl" + j + "_cd"]) {
        dummy2.push(ob["lvl" + j + "_value"]);
      }
    }
    this.Obj["path_desc"] = dummy2.join(" --> ");
  }

  leaseAllotment_data : any = [];
  async getLeaseAllotment() {
    this.spinner.show();
    let obj : any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["arr_status_code"] = "ALLOTED";
    var resp  : any= await this.leaseservice.getLeaseAllotment(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.leaseAllotment_data = resp.data;
      for (let i = 0; i < this.leaseAllotment_data.length; i++) {
        // let dt = new Date()
        let dt = new Date(this.leaseAllotment_data[i]["lease_start_date"]);
        let end_date: string | number = dt.getDate();
        let end_month: string | number = dt.getMonth() + 1;
        let end_year: string | number =
          dt.getFullYear() + this.leaseAllotment_data[i]["lease_duration"];
        if (end_date < 10) {
          end_date = "0" + end_date;
        }
        if (end_month < 10) {
          end_month = "0" + end_month;
        }
        this.leaseAllotment_data[i]["lease_end_date"] =
          end_date + "-" + end_month + "-" + end_year;
      }

      let dummy : any = [];
      // console.log(this.allPlan_layout)
      for (let i = 0; i < this.allPlan_layout.length; i++) {
        // if (this.allPlan_layout[i]['hier_type_id'] == this.Obj['hier_type_id']) {
        let ob = this.hier_node_obj[this.allPlan_layout[i]["prop_hier_id"]];
        // console.log(ob, this.hier_node_obj)
        let dummy2 = [];
        for (let j = 1; j <= 8; j++) {
          if (ob["lvl" + j + "_cd"]) {
            dummy2.push(ob["lvl" + j + "_value"]);
          }
        }
        this.allPlan_layout[i]["path_desc"] = dummy2.join(" --> ");
        this.allPlan_layout[i]["target_name"] = ob["leaf_value"];
        this.allPlan_layout[i]["target_type"] = ob["leaf_node_type"];
        dummy.push(this.allPlan_layout[i]);
        // }
      }
      for (let i = 0; i < dummy.length; i++) {
        console.log(
          this.projectKeyValue[dummy[i]["project_node_cd"]],
          dummy[i]["project_node_cd"]
        );
        let ob : any = {};
        let desc : any = [];
        ob = Object.assign(
          {},
          this.projectKeyValue[dummy[i]["project_node_cd"]]
        );
        // console.log(ob, this.projectKeyValue[dummy[i]['project_node_cd']], dummy[i]['project_node_cd'])
        for (let j = 1; j <= 8; j++) {
          if (ob["lvl" + j + "_cd"]) {
            desc.push(ob["lvl" + j + "_value"]);
          }
        }
        dummy[i]["project_desc"] = desc.join(" --> ");
      }
      this.leaseAllotment_data.map((x : any) => {
        dummy.map((y : any) => {
          if (x["prop_hier_id"] == y["prop_hier_id"]) {
            x["project_desc"] = y["project_desc"];
          }
        });
      });

      console.log(dummy, this.projectKeyValue);

      console.log(this.leaseAllotment_data, " data");

      this.spinner.hide();
      this.dataSource = new MatTableDataSource(this.leaseAllotment_data);
      this.dataSource.sort = this.sort.toArray()[0];
      this.dataSource.paginator = this.paginator.toArray()[0];
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Something Went Wrong!", "error");
    }
    for (let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].sno = i + 1;
    }
  }

  objDetails : any = [];
  async viewDetails(element : any) {
    this.objDetails = this.leaseAllotment_data[element - 1];
    $("#viewDetails").modal("show");
  }

  async updateDetails(element : any) {
    this.objDetails = this.leaseAllotment_data[element];
    console.log(this.leaseAllotment_data);
  }

  open_update(element : any) {
    this.list_flag = false;
    this.create_flag = true;
    this.objDetails = this.leaseAllotment_data[element];
    console.log(this.objDetails);
    this.Obj["duration"] = this.objDetails["lease_duration"];
    this.Obj["file_number"] = this.objDetails["file_num"];
    this.Obj["applicant_name"] = this.objDetails["leasee_name"];
    this.Obj["start_date"] = this.objDetails["lease_start_date"];

  }
}
