import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AllEmpService } from '../../service/all-emp.service';
import { MainService } from '../../service/main.service';
import { ExcelService } from '../../../emb/service/file-export.service';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import { ZoneService } from '../../service/zone.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

declare var $: any;
declare const google: any;
// import { PropHierarchyService } from '../';
@Component({
  selector: 'app-boundry-map',
  templateUrl: './boundry-map.component.html',
  styleUrls: ['./boundry-map.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})

export class BoundryMapComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(private spinner: NgxSpinnerService, private excel: ExcelService, private allEmpService: AllEmpService, public mainService: MainService, public zone: ZoneService) { }
  ebillUser:any
  b_acct_id:any
  center!:google.maps.LatLngLiteral ;
  vertices !:  google.maps.LatLngLiteral[]
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  red:any;
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    // console.log(this.ebillUser, 'b_acct_id')
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.paths = []
    this.Obj['loc_geo_ent_value'] = 'SQM'
    await this.getLocationList()
    this.paths = []
    this.Obj['loc_geo_ent_value'] = 'SQM'
    //  await this.open_activitypopup()
  }
  zoom: any;
  datasource:any
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  list_flag = true
  create_flag = false
  update_flag = false

 addMarker(event: google.maps.MapMouseEvent) {
    this.markerPositions.push(event.latLng!.toJSON());
  }
  open_create() {

    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
  }
  // //////////////////////hierachy /////////////////
  data:any = [{}]
  project_hier:any = []
  level1:any = []
  level2:any = []
  level3:any = []
  level4:any = []
  level5:any = []
  level6:any = []
  level7:any = []
  planing_arrproj:any = [];

  projecthier:any = []
  projhierobj:any = {}
  projhierobjtype:any = {}
  projhierobjtypecd:any = {}
  projhierobjisleaf:any = {}
  Obj:any = {}
  allProject:any = [];
  joiningObj:any = {};
  displayedColumns:any = ['id', 'zone_name', 'zone_cd', 'action'];

  calculate_area() {
    if (!(this.uploaded_file_data)) {
      swal.fire('Info', 'Enter location detail', 'info')
      return
    }
    let dummy = []

    // else {
    for (let i = 0; i < this.uploaded_file_data.length; i++) {
      let obj2:any = {}

      obj2['lat'] = this.uploaded_file_data[i]['loc_gis_x_long']
      obj2['lng'] = this.uploaded_file_data[i]['loc_gis_y_lat']

      this.lat = obj2['lat']
      this.lng = obj2['lng']
      dummy.push(obj2)
    }
    // }
    this.paths = dummy
    console.log(this.paths)
    //console.log(this.paths)
    this.Obj['loc_geo_calc_value'] = google.maps.geometry.spherical.computeArea(this.paths)
    this.location_area = parseFloat(this.Obj['loc_geo_calc_value']).toFixed(2);

  }

  export() {
    let ExpData = [{
      'one': 'S No.',
      'two': 'Point 1',
      'three': 'Latitude',
      'four': 'longitude',
      'five': 'Point 2',
      'six': 'Latitude ',
      'seven': 'longitude ',
    },
    ]
    var exp = []
    for (var i = 0; i < ExpData.length; i++) {
      var obj:any = new Object();
      obj['1'] = ExpData[i]['one']
      obj['2'] = ExpData[i]['two']
      obj['3'] = ExpData[i]['three']
      obj['4'] = ExpData[i]['four']
      obj['5'] = ExpData[i]['five']
      obj['6'] = ExpData[i]['six']
      obj['7'] = ExpData[i]['seven']
      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, 'Map-cordinates_format')
  }
  uploaded_file_data:any
  async onFileChange(event: any) {
    /* wire up file reader */
    this.uploaded_file_data = []
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      //console.log(data)
      this.setData(data);
      // Data will be logged in array format containing objects
    };
  }
  lat: number = 26.850000;
  lng: number = 80.949997;
  async setData(data:any) {
    console.log(data)
    this.uploaded_file_data = []
    let sum_lat = 0
    let sum_lng = 0
    //console.log(data)
    let dummy = []
    for (let i = 1; i < data.length; i++) {
      let obj:any = {}
      obj['loc_ln_seq_num'] = 1
      obj['loc_pt_seq_num'] = 1
      obj['loc_gis_x_long'] = data[i][3]
      obj['loc_gis_y_lat'] = data[i][4]
      let obj2:any = {}
      obj2['loc_ln_seq_num'] = 1
      obj2['loc_pt_seq_num'] = 2
      obj2['loc_gis_x_long'] = data[i][6]
      obj2['loc_gis_y_lat'] = data[i][7]
      // console.log()
      this.lat = obj2['loc_gis_y_long']
      this.lng = obj2['loc_gis_x_lat']

      dummy.push(obj)
      dummy.push(obj2)
    }
    // console.log(sum_lat/data.length)
    // console.log(sum_lng/data.length)
    // this.lat = sum_lat/data.length
    // this.lng = sum_lng/data.length
    // this.lat = obj2['loc_gis_y_long']
    // this.lng = obj2['loc_gis_x_lat']
    this.uploaded_file_data = dummy

    console.log(dummy, 'set data')
  }
  async view_on_map(element:any) {
    let dummy = []
    let dummy2 = []
    this.paths = null;
    this.zoom = null
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_id'] = element['zone_id']
    console.log(obj);
    var resp:any = await this.zone.getcordinateLocationData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == true) {
      swal.fire('error', 'server side error', 'error');
      return
    }
    else {
      dummy = resp['data']
      console.log(dummy)
    }
    for (let i = 0; i < dummy.length; i++) {
      let obj2:any = {}

      obj2['lat'] = dummy[i]['loc_gis_x_long']
      obj2['lng'] = dummy[i]['loc_gis_y_lat']

      this.lat = obj2['lat']
      this.lng = obj2['lng']
      dummy2.push(obj2)
    }

    this.paths = dummy2
    console.log(this.paths)
    this.Obj['loc_geo_calc_value'] = google.maps.geometry.spherical.computeArea(this.paths)
    console.log(this.Obj['loc_geo_calc_value'])
    this.location_area = parseFloat(this.Obj['loc_geo_calc_value']).toFixed(2);
    this.zoom = 18
    $('#myModal2').modal('show');
  }
  docArr = [{}]

  async open_list() {
    this.location_area = 0
    this.Obj = {}
    this.paths = []

    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    await this.getLocationList()
  }


  async getLocationList() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    var resp:any = await this.zone.getLocationData(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      console.log(resp['data'])
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }


  async submit() {
    // Obj['loc_type_cd'] ✔
    // Obj['loc_id_std'] ✔
    // Obj['loc_id_str'] ✔
    // Obj['loc_short_nm']
    // Obj['loc_long_desc']
    // Obj['location_use_type']
    // Obj['location_property_type']
    // Obj['loc_admin_cd']
    console.log(this.Obj)
    console.log(this.Obj['loc_type_cd'])
    let dummy = []
    if (!this.Obj['zone']) {
      Swal.fire('info', 'Plz Select boundry', 'info')
      return
    }
    // else if(!this.Obj['zone_cd']){
    //   Swal.fire('info', 'Plz fill zone code ', 'info')
    //   return
    // }
    // else if(!this.Obj['zone_name']){
    //   Swal.fire('info', 'Plz fill Zone Name Type', 'info')
    //   return
    // }
    // else if(this.GISArr.length <= 0 || this.docArr.length <= 0){
    //   Swal.fire('info', 'Plz Complete GIS Details', 'info')
    //   return
    // }
    else {

      //  else if (this.radio == false) {
      dummy = this.uploaded_file_data
      // }
      let dummy2 = []

      this.Obj['coordinates_data'] = dummy
      let obj:any = {}
      obj = Object.assign({}, this.Obj)
      obj['zone_cd'] = this.Obj['zone']
      // this.Obj['node_cd']
      obj['b_acct_id'] = this.b_acct_id
      obj['life_cycle_status'] = 'ACTIVE'
      obj['user_id'] = this.ebillUser.user_id
      console.log(obj)
      this.spinner.show()
      var resp:any = await this.zone.createLocation(obj)
      console.log(resp)
      if (resp['error'] == false) {
        // this.reset()
        this.spinner.hide()
        this.open_list();
        // await this.getLocationList()
        Swal.fire('info', resp['data'], 'info')
        return
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Some Error Occured While Creating', 'error')
        return
      }
    }
  }

  paths: any = []
  Obj_test:any = {}
  location_area:any
  element2:any
  async modalOpen(i:any) {
    this.paths = null
    this.zoom = 18
    let dummy = []

    if (!(this.uploaded_file_data)) {
      swal.fire('Info', "First Fill  The Data In The Choose File", 'info')
      return;
    }
    for (let i = 0; i < this.uploaded_file_data.length; i++) {
      let obj2:any = {}

      obj2['lat'] = this.uploaded_file_data[i]['loc_gis_x_long']
      obj2['lng'] = this.uploaded_file_data[i]['loc_gis_y_lat']

      this.lat = obj2['lat']
      this.lng = obj2['lng']
      dummy.push(obj2)
    }

    this.paths = dummy
    console.log(this.paths)
    console.log(this.paths)


    this.center =   {
      lat: 26.851991 , lng: 80.954479
    };
    this.vertices  = [
      {lat: 26.851991, lng: 80.954479},
      {lat: 26.852013, lng: 80.954675},
      {lat: 26.851907, lng: 80.954534},
    ];
    // this.Obj_test['loc_geo_calc_value'] = google.maps.geometry.spherical.computeArea(this.paths)
    console.log(this.Obj_test['loc_geo_calc_value'])
    // this.location_area = parseFloat(this.Obj_test['loc_geo_calc_value']).toFixed(2);

    $('#myModal1').modal('show');

  }
  onPolyClick($event: any) {
    // console.log("polyClicked: ", $event.latLng.lat(), $event.latLng.lng());
  }
  isLinear:any
  first:any
  second:any
  async delete(element:any, i:any) {
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      console.log(result, result.value)
      if (result.value) {

        this.finaldelete(element)

      }
    })
  }

  async finaldelete(data:any) {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = data.zone_cd
    obj['zone_id'] = data.zone_id

    console.log(obj)
    this.spinner.show()
    var resp = await this.zone.deletezone(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      await this.getLocationList()
      this.spinner.hide();
      Swal.fire('Success...', 'Deleted Successfully.', 'success')

    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  reset(){
  }


}
