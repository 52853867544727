import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { MainService as md_mainService } from '../../../md/service/main.service';
import { MainService } from '../../service/main.service';
import { AllEmpService } from '../../service/all-emp.service';
import { DataAccessService } from '../../service/data-access.service';
declare var $: any;
@Component({
  selector: 'app-hr-users',
  templateUrl: './hr-users.component.html',
  styleUrls: ['./hr-users.component.css']
})
export class HrUsersComponent implements OnInit {
  displayedColumns: string[] = ['user_id', 'name', 'phone_no', 'email', 'role_desc','delete'];
  email:any ;
  password:any ;
  confirm_pass:any ;
  users :any = [];
  datasource:any ;
  user_id:any ;
  index:any ;
  Obj :any = {};
  FirstName:any ;
  LastName:any ;
  User_Email:any ;
  b_acct_id:any ;
  ebillUser:any
  constructor(private dataAccessService:DataAccessService,private spinner: NgxSpinnerService, public mainService: MainService, private allEmpService: AllEmpService, private mainServiceMD: md_mainService, public toastr: ToastrService, private sanitizer: DomSanitizer) { }
  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.datasource){
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.datasource){
      this.datasource.sort = value;
    }
  }

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id
    await this.getCurrentLegalEntity()
    await this.getCurrentRole()
    await this.getCurrentUsers()
    await this.getAllEmployees()
  }

  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.Obj={};
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }
  single_users_role :any = []
  selected_row :any = {}
  modalOpen(data:any ) {
    this.selected_row = {}
    this.selected_row = Object.assign({}, data)
    this.single_users_role = []
    let dummy :any = []
    for (let i = 0; i < this.allUsers.length; i++) {
      if (data['user_id'] == this.allUsers[i]['user_id']) {
        dummy.push(this.allUsers[i])
      }
    }
    for (let i = 0; i < dummy.length; i++) {
      for (let j = 0; j < this.current_role.length; j++)
        if (dummy[i]['role_cd'] == this.current_role[j]['role_cd']) {
          dummy[i]['role_desc'] = this.current_role[j]['role_desc']
        }
    }
    this.single_users_role = dummy
    console.log(data, this.allUsers)

    $('#myModal1').modal('show');

  }
  async delete(element:any ) {
    console.log(element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  async finaldelete(element:any ) {
    let obj:any  = {}
    obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    var resp = await this.mainServiceMD.deleteUser_MD(obj)
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getCurrentUsers()
      await this.modalOpen(this.selected_row)
      Swal.fire('Success', 'Deleted Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }
  }
  getNumberFormat(num:any ) {
    return num.toString().padStart(3, "0")
  }
  allEmplyees_new:any  = []
  allEmployees :any = []
  allSearchableEmp :any = []
  async getAllEmployees() {
    console.log('all emp')
    this.spinner.show()
    var arr :any = []
    var obj :any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd']="HR"
    var resp = await this.mainServiceMD.getuserformodulerolemapping(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < this.current_LE.length; j++) {
          if (this.current_LE[j]['phone_no'] == arr[i]['emp_phone_no']) {
            arr[i]['le_id'] = this.current_LE[j]['le_id'];
            arr[i]['desc'] = arr[i]['le_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
          }
        }
      }
      this.allSearchableEmp = arr;
    } else {
      this.spinner.hide()
    }
  }

  allHRRole :any = []
  current_role:any  = [];
  async getCurrentRole() {
    this.spinner.show()
    let obj :any  = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = "HR";
    console.log(obj)
    var resp = await this.mainServiceMD.getcurrentroleswithresources(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.current_role = [];
      this.allHRRole = resp.data;
      for (let i = 0; i < resp.data.length; i++) {
        // if (resp.data[i]['is_system_role'] == 0) {
          this.current_role.push(resp['data'][i])
        // }
      }
      console.log(this.current_role)
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }
  allUsers = []
  async getCurrentUsers() {
    console.log('called this funnction')
    this.spinner.show()
    let obj :any  = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'HR';
    console.log(obj);
    var resp :any = await this.mainServiceMD.getCurrentMdUser(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.allUsers = resp['data']
      var emp_id :any = new Object();
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 0; j < this.allHRRole.length; j++) {
          if (this.allHRRole[j]['role_cd'] == resp['data'][i]['role_cd']) {
            if (emp_id[resp['data'][i]['user_id']] == undefined) {
              resp['data'][i]['role_name'] = this.allHRRole[j]['role_name']
              emp_id[resp['data'][i]['user_id']] = resp['data'][i]
            } else {
              emp_id[resp['data'][i]['user_id']]['role_name'] = emp_id[resp['data'][i]['user_id']]['role_name'] + " , " + this.allHRRole[j]['role_name']
            }
          }
        }
      }
      console.log(emp_id)
      var data:any  = Object.values(emp_id);

      console.log(data)
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.current_LE.length; j++) {
          if (this.current_LE[j]['le_id'] == data[i]['user_id']) {
            data[i]['party_name'] = this.current_LE[j]['party_name']
            data[i]['contact_email'] = this.current_LE[j]['contact_email']
            data[i]['phone_no'] = this.current_LE[j]['phone_no']
          }
        }
      }
      // data=await this.dataAccessService.filterEmployeeData(data)
      var temp_data=await this.dataAccessService.filterEmployeeDataByUserId(data)
      this.datasource = new MatTableDataSource(temp_data);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }
  current_LE = []
  ind_current_LE = []
  org_current_LE = []
  async getCurrentLegalEntity() {
    this.current_LE = []
    this.ind_current_LE = []
    this.org_current_LE = []
    var resp = await this.mainServiceMD.getCurrentLegalEntity(this.b_acct_id);
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp);
      this.current_LE = resp['data']
      let ind:any  = []
      let org :any = []
      for (let i = 0; i < this.current_LE.length; i++) {
        if (this.current_LE[i]['party_type'] == 'IND') {
          ind.push(this.current_LE[i])
        } else {
          org.push(this.current_LE[i])
        }
      }
      this.ind_current_LE = ind
      this.org_current_LE = org
    } else {
      Swal.fire("Error", "..Error while getting current Legal Entity", 'error');
    }
  }
  setDetails() {
    if (this.Obj['user_id']) {
      for (let i = 0; i < this.allSearchableEmp.length; i++) {
        if (this.Obj['user_id'] == this.allSearchableEmp[i]['le_id']) {
          this.Obj['name'] = this.allSearchableEmp[i]['emp_name']
          this.Obj['email'] = this.allSearchableEmp[i]['emp_email']
          this.Obj['phone'] = this.allSearchableEmp[i]['emp_phone_no']
          this.Obj['user_id'] = this.allSearchableEmp[i]['le_id']
          this.Obj['allow_create'] = 'YES' // let flag
          console.log(this.allSearchableEmp[i])
          break;
        }
      }
    } else {
      this.Obj['name'] = null
      this.Obj['email'] = null
      this.Obj['phone_no'] = null
    }
  }
  open_update(element:any   ) {
    this.Obj = element;
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    $('.nav-tabs a[href="#tab-7-3"]').tab('show');
  }

  async update() {

  }

  account:any





  async deleteUser(element:any ) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
      }
    })
  }

  email_Validate(emailAdress:any ) {
    var EMAIL_REGEXP = new RegExp('^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$', 'i');
    return EMAIL_REGEXP.test(emailAdress)
  }
  CheckPassword(inputtxt:any ) {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (passw.test(this.Obj['password'])) {
      return true;
    }
    else {
      return false;
    }
  }
  data = []
  async search() {
    let flag = false
    this.spinner.show()
    this.Obj['name'] = null
    this.Obj['email'] = null
    this.Obj['phone_no'] = null
    console.log(this.allSearchableEmp)
    if (this.Obj['credential']) {
      for (let i = 0; i < this.allSearchableEmp.length; i++) {
        if (this.Obj['credential'] == this.allSearchableEmp[i]['emp_phone_no']) {
          this.Obj['name'] = this.allSearchableEmp[i]['emp_name']
          this.Obj['email'] = this.allSearchableEmp[i]['emp_email']
          this.Obj['phone'] = this.allSearchableEmp[i]['emp_phone_no']
          this.Obj['user_id'] = this.allSearchableEmp[i]['le_id']
          this.Obj['allow_create'] = 'YES' // let flag
          flag = true
          this.spinner.hide()
          break
        } else {
          let flag = false
          this.spinner.hide()
          this.Obj['name'] = null
          this.Obj['email'] = null
          this.Obj['phone'] = null
          this.Obj['user_id'] = null
          this.Obj['phone_no'] = null
        }
      }
    } else {
      Swal.fire('Error', 'Please Enter Valid Mobile Number', 'error')
      this.spinner.hide()
      this.Obj['name'] = null
      this.Obj['email'] = null
      this.Obj['phone'] = null
      this.Obj['user_id'] = null
      this.Obj['phone_no'] = null
    }
    if (flag == false) {
      Swal.fire('Error', 'This Mobile Number`s Record Not Found', 'error')

    }
  }

  async submit() {
    console.log('yes....')
    console.log(this.Obj)
    if (!this.Obj['user_id']) {
      Swal.fire('Error', 'Please Select All * Fields', 'error')
    } else if (!this.Obj['role_cd']) {
      Swal.fire('Error', 'Please Select Role', 'error')
    } else {
      console.log(this.Obj)
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['valid_upto'] = '2030-04-30'
      this.Obj['module_cd']='HR'
      this.spinner.show()
      var resp = await this.mainServiceMD.createMdUser(this.Obj)
      console.log(resp)
      if (resp['error'] == false) {
        setTimeout(() => {
          this.spinner.hide()
          Swal.fire('Success...', 'Created Successfully', 'success')
          this.getCurrentUsers()
        }, 500);
      } else {
        this.spinner.hide()
        if (resp['data'] == "Duplicate entry") {
          Swal.fire('Error...', 'This User Is Already Exists.', 'error')
        } else {
          Swal.fire('Error', 'Error While Creating', 'error')
        }
      }
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  refresh() {
    this.Obj = {}
    this.data = []
  }


}
