import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { PaymentService } from '../service/payment.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  b_acct_id: any;
  erpUser:any;
  user_id: any;
  constructor(private paymentService: PaymentService) { }
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any))
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;

    await this.getApprovedTender();
  }


  // displayedColumns = ['Sr.No', 'txn_id', 'payment_type', 'amt', 'pay_mode', 'pay_date', 'bank_ref_no', 'challan_no', 'pay_status', 'remark', 'action']
  displayedColumns = ['Sr.No', 'txn_id', 'pay_for', 'amt', 'pay_mode', 'pay_date', 'bank_ref_no', 'challan_no', 'pay_status', 'action']
  selectedObj :any= new Object();
  dataSource: any = []

  list: boolean = true;
  details: boolean = false;

  backtoList() {
    this.list = true;
    this.details = false;
  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  async getdetails() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['tender_id'] = this.selectedObj['tender_id'];
    let resp:any = await this.paymentService.getdetails(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp['data'])
      this.dataSource = new MatTableDataSource(resp['data'])
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
    } else {
      this.dataSource = []
      Swal.fire('ERROR', 'Some Erorr Occurred While Getting Data', 'error');
    }
  }

  tender_data:any = []
  async getApprovedTender() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp :any= await this.paymentService.getApprovedTender(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.tender_data = resp['data'];
      this.tender_data.map((x:any) => {
        x['desc'] = x['nic_tender_id'] + ' - ' + x['work_name']
      })
    } else {
      Swal.fire('ERROR', 'Error While Getting Tender Information', 'error');
    }
  }

  vendor_details:any = [];
  payment_details=[]
  async viewDetails(element:any) {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['tender_id'] = element.tender_id;
    obj['vendor_id'] = element.vendor_id;
    obj['arr_id'] = element.arr_id;

    let resp :any= await this.paymentService.viewDetails(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.vendor_details = resp['data'][0];
      this.payment_details = resp['data'];
      console.log(this.payment_details)




      this.list = false;
      this.details = true;
    } else {

      Swal.fire('ERROR', 'Some Erorr Occurred While Getting Data', 'error');
    }
  }




}
