<!--------Old Code S-->
<!-- <app-sor></app-sor> -->
<!-------Old Code E-->

<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title"></div>
        </div>
        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link {{list_flag}}" href="#tab-7-1" data-toggle="tab" (click)="morthList()"><i
                  class="fa fa-line-chart"></i>
                Morth Item List
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link {{upload_m_flag}}" href="#tab-7-2" data-toggle="tab"><i class="fa fa-plus-circle"
                  aria-hidden="true">
                </i>
                Upload Morth items
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link {{create_m_flag}}" href="#tab-7-3" data-toggle="tab" (click)="createMorth()"><i
                  class="ti-settings" aria-hidden="true"> </i>
                Create Morth
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link {{update_m_flag}}" href="#tab-7-4" data-toggle="tab"><i class="ti-settings"
                  aria-hidden="true"> </i>
                Update Morth Item
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link {{map_m_flag}}" href="#tab-7-5" data-toggle="tab" (click)="mapMorth()"><i
                  class="ti-settings" aria-hidden="true"> </i>
                Map morth items
              </a>
            </li>
            <li class="nav-item" *ngIf="this._erp_user.role_cd.includes('AA')">
              <a class="nav-link {{create_m_excel_flag}}" href="#tab-7-6" data-toggle="tab"
                (click)="createMorthByExcel()"><i class="ti-settings" aria-hidden="true"> </i>
                Create Morth By Excel
              </a>
            </li>
          </ul>

          <div class="tab-content">

            <div id="tab-7-1" *ngIf="m_item_list">
              <!-- fin year  -->
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Year of Costing Rate Standard : <span class="text-danger">*</span>
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['EMB056']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="Select Year of Costing Rate Standard" [(ngModel)]="finYear"
                    (change)="finYearCh(finYear)" [selectableGroup]="true" [selectableGroupAsModel]="false"
                    [closeOnSelect]="true" name="work_order_id">
                  </ng-select>
                </div>
                <div class="col-2"></div>
              </div>
              <br />
              <!-- region  -->
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Region <span class="text-danger">*</span>
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['EMB058']" [multiple]="false" bindLabel="value"
                    bindValue="code" placeholder="Select Region" [(ngModel)]="selectedRegion" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" (change)="regaionCh(selectedRegion)">
                  </ng-select>
                </div>
                <div class="col-2"></div>
              </div>
              <br />
              <!-- chapter no / name  -->
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Chapter / Sub-Head: <span class="text-danger">*</span>
                </div>
                <div class="col-3">
                  <ng-select [items]="chap_lists" [multiple]="false" bindLabel="chap_desc" bindValue="bind_val"
                    placeholder="Select Chapter / Sub-Head" [(ngModel)]="chapter_obj" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" (change)="chapterCh()">
                  </ng-select>

                </div>
                <div class="col-2"></div>
              </div>
              <br />
              <br />

              <!-- table (Morth) -->
              <div class="example-container">
                <table mat-table [dataSource]="datasource" matSort style="border: 2px solid #ddd;">


                  <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef> Sr No. </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="showonhover" (mouseover)="handleMouseOver(element.sno)">
                        {{element.sno}}
                        <span class="hoverText">
                          <!-- Morth id  {{ morth_id }} -->
                          <table>
                            <thead>
                              <tr>
                                <th class="table-cell">Sr No.</th>
                                <th class="table-cell">Ref. to Morth&H specifi.</th>
                                <th class="table-cell"> </th>
                                <th class="table-cell"> Item Description</th>
                                <th class="table-cell"> Unit </th>
                                <th class="table-cell"> Quantity </th>
                                <th class="table-cell"> Taking Output </th>


                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let t of table_data">
                                <td class="table-cell">{{ t.sno }}</td>
                                <td class="table-cell">{{ t.ref_to_morth }}</td>
                                <td class="table-cell">{{ t.level_cd }}</td>
                                <td class="table-cell">{{ t.item_desc }}</td>
                                <td class="table-cell">{{ t.unit }}</td>
                                <td class="table-cell">{{ t.quantity }}</td>
                                <td class="table-cell">{{ t.tak_op }}</td>

                              </tr>
                            </tbody>
                          </table>
                        </span>
                      </div>


                    </td>
                  </ng-container>

                  <ng-container matColumnDef="ref_to_morth">
                    <th mat-header-cell *matHeaderCellDef> Ref. to Morth &H specifi. </th>
                    <td mat-cell *matCellDef="let element"> {{element.ref_to_morth}} </td>
                  </ng-container>

                  <ng-container matColumnDef="level_cd">
                    <th mat-header-cell *matHeaderCellDef> Level Code </th>
                    <td mat-cell *matCellDef="let element"> {{element.level_cd}} </td>
                  </ng-container>


                  <ng-container matColumnDef="item_desc">
                    <th mat-header-cell *matHeaderCellDef>
                      Item Description </th>
                    <td mat-cell *matCellDef="let element" class="text-start"> {{element.item_desc}} </td>
                  </ng-container>


                  <!-- <ng-container matColumnDef="unit">
                        <th mat-header-cell *matHeaderCellDef>
                          Unit </th>
                        <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
                    </ng-container> -->


                  <!-- <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef> Quantity </th>
                        <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
                    </ng-container> -->

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element">
                      <button class=" btn btn-outline-danger" (click)="deleteMorth(element)"> Delete </button>
                      <button class="btn btn-outline-primary" [hidden]=true (click)="open_update(element)"> Update
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                  </tr>
                </table>
                <mat-paginator #paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                </mat-paginator>

              </div>


            </div>

            <div id="tab-7-2">
            </div>
            <div id="tab-7-3" *ngIf="create_m_item">
              <br>
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Costing Rate Standard : <span class="text-danger">*</span>
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['EMB055']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="Select Costing Rate Standard" [(ngModel)]="obj['costing_rate_std']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                    name="work_order_id" disabled>
                  </ng-select>
                </div>
                <div class="col-2"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Year of Costing Rate Standard : <span class="text-danger">*</span>
                </div>
                <div class="col-3" *ngIf="!update_flag">
                  <ng-select [items]="mainService.codeValueTechObj['EMB056']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="Select Year of Costing Rate Standard" [(ngModel)]="obj['fin_year']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                    name="work_order_id">
                  </ng-select>
                </div>

                <div class="col-3" *ngIf="update_flag">
                  <input type="text" class="form-control" [(ngModel)]="obj['fin_year']" disabled>
                </div>

                <div class="col-2"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Region <span class="text-danger">*</span>
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['EMB058']" [multiple]="false" bindLabel="value"
                    bindValue="code" placeholder="Select Region" [(ngModel)]="selectedRegion" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true">
                  </ng-select>
                </div>
                <div class="col-2"></div>
              </div>
              <br>
              <!-- Estimate Ref  -->
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Select Estimate Type : <span class="text-danger">*</span>
                </div>
                <div class="col-3" *ngIf="!update_flag">
                  <ng-select [items]="type_of_Estimate" bindLabel="code" bindValue="value" [multiple]="false"
                    placeholder="Select  Estimate Type" [(ngModel)]="obj['est_type']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="false" [multiple]="true">
                  </ng-select>
                </div>
                <div class="col-3" *ngIf="update_flag">
                  <input type="text" class="form-control" [(ngModel)]="obj['est_type']" disabled>
                </div>
                <div class="col-2"></div>
              </div>
              <br>
              <!-- Estimate Ref  -->
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Select Chapter No : <span class="text-danger">*</span>
                </div>
                <div class="col-3" *ngIf="!update_flag">
                  <ng-select [items]="mainService.codeValueTechObj['EMB057']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="Select Chapter No" [(ngModel)]="obj['chapter_no']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                    name="work_order_id">
                  </ng-select>
                </div>
                <div class="col-3" *ngIf="update_flag">
                  <input type="text" class="form-control" [(ngModel)]="obj['chap_no']" disabled>
                </div>
                <div class="col-2"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Chapter Name : <span class="text-danger">*</span>
                </div>
                <div class="col-3">
                  <input type="text" class="form-control" [(ngModel)]="obj['chapter_name']"
                    placeholder="Enter Chapter Name" [disabled]="isDisable">
                </div>
                <div class="col-2"></div>
              </div>
              <br />

              <div class="row">
                <div class="col-1">
                  <h6>No</h6>
                </div>
                <div class="col-1">
                  <h6>S. No</h6>
                </div>
                <div class="col-1">
                  <h6>Ref. to Morth Specification</h6>
                </div>
                <div class="col-1">
                  <h6>code</h6>
                </div>
                <div class="col-2">
                  <h6>Item Description</h6>
                </div>
                <div class="col-1 text-center">
                  <h6>Unit</h6>
                </div>
                <div class="col-2 text-center">
                  <h6> Quantity</h6>
                </div>
                <div class="col-3 text-center action">
                  <h6>Action</h6>
                </div>
              </div>
              <br>
              <div class='row' *ngIf="!update_flag" style="text-align: right;">
                <div class="col-12" style="display: flex;justify-content: flex-end;">

                  <button class=" btn btn-outline-primary" style="margin-right:0.5%" (click)="level(1,-1)">Level
                  </button>
                  <div class="myToggele">
                    <p style="align-items: center;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    top: 7px;">
                      <mat-slide-toggle [(ngModel)]="insertSubAnalysisEnabled" (change)="onToggleChange($event)">
                        <span style="position: relative;
                        top: 4px;
                        left: 3px;
                        font-weight: 600;" [ngClass]="{'blue-text': isBlue, 'black-text': !isBlue}">
                          Insert SubAnalysis Items
                        </span>
                      </mat-slide-toggle>
                    </p>
                  </div>
                </div>
              </div>
              <br>
              <div class="row bottom-margin" *ngFor="let obj of _level_arr; let i = index">

                <div class="col-1 " *ngIf="_level_arr[i]['no']!=null && _level_arr[i]['tbl_level_cd']==1">
                  {{_level_arr[i]['no']}}
                </div>
                <div class="col-1 "
                  *ngIf="_level_arr[i]['no']==null && _level_arr[i]['ref_to_morth']!=null && _level_arr[i]['level_cd']!=null && _level_arr[i]['tbl_level_cd']==1||_level_arr[i]['tbl_level_cd']==2">
                  {{_level_arr[i]['no']}}
                </div>
                <div class="col-1 "
                  *ngIf="_level_arr[i]['sno']!=null && _level_arr[i]['tbl_level_cd']==1||_level_arr[i]['tbl_level_cd']==2">
                  <input type="text" [(ngModel)]="_level_arr[i]['sno']" class="form-control" placeholder="Enter S. No.">
                </div>
                <div class="col-1"
                  *ngIf="_level_arr[i]['ref_to_morth']!=null && _level_arr[i]['tbl_level_cd']==1||_level_arr[i]['tbl_level_cd']==2">
                  <input type="text" [(ngModel)]="_level_arr[i]['ref_to_morth']" class="form-control"
                    placeholder="Enter Ref to Morth " [disabled]="_level_arr[i]['ref_to_morth'] == 'Sub Analysis' ">
                </div>
                <div class="col-1"
                  *ngIf="_level_arr[i]['level_cd']!=null && _level_arr[i]['tbl_level_cd']==1||_level_arr[i]['tbl_level_cd']==2">
                  <input type="text" [(ngModel)]="_level_arr[i]['level_cd']" class="form-control"
                    placeholder="Enter level Code">
                </div>
                <div class="col-2"
                  *ngIf="_level_arr[i]['item_desc']!=null && _level_arr[i]['level_cd']!=null && _level_arr[i]['tbl_level_cd']==1||_level_arr[i]['tbl_level_cd']==2">
                  <textarea rows="1" [(ngModel)]="_level_arr[i]['item_desc']" class="form-control" cols="50"
                    placeholder="Enter Item Description"></textarea>
                </div>
                <div class="col-3"
                  *ngIf="_level_arr[i]['no']!=null && _level_arr[i]['tbl_level_cd']==1||_level_arr[i]['tbl_level_cd']==2">

                </div>
                <!-- <div class="col-3" *ngIf="_level_arr[i]['no']==null && _level_arr[i]['ref_to_morth']!=null && _level_arr[i]['level_cd']!=null && _level_arr[i]['tbl_level_cd']==1||_level_arr[i]['tbl_level_cd']==2">

                                </div>  -->




                <div class="col-4"
                  *ngIf="_level_arr[i]['unit']!=null || _level_arr[i]['tak_op']!=null && _level_arr[i]['tbl_level_cd']==3">

                </div>
                <div class="col-1 top-margin"
                  *ngIf="_level_arr[i]['unit']!=null || _level_arr[i]['tak_op']!=null && _level_arr[i]['tbl_level_cd']==3">
                  <h6>Unit</h6>
                </div>
                <div class="col-1"
                  *ngIf="_level_arr[i]['unit']!=null || _level_arr[i]['tak_op']!=null && _level_arr[i]['tbl_level_cd']==3">
                  <ng-select [items]="mainService.codeValueTechObj['EMB059']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="Select Unit" [(ngModel)]="_level_arr[i]['unit']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                    name="work_order_id">
                  </ng-select>
                </div>
                <div class="col-1 top-margin" *ngIf="_level_arr[i]['tak_op']!=null && _level_arr[i]['tbl_level_cd']==3">
                  <h6 style="white-space: nowrap;">Taking Output</h6>
                </div>
                <div class="col-2" *ngIf="_level_arr[i]['tak_op']!=null && _level_arr[i]['tbl_level_cd']==3">
                  <input type="text" [(ngModel)]="_level_arr[i]['tak_op']" class="form-control"
                    placeholder="Enter the quantity">
                </div>





                <div class="col-4" *ngIf="_level_arr[i]['tbl_level_cd']==4">

                </div>
                <div class="col-2" *ngIf="_level_arr[i]['tbl_level_cd']==4">
                  <textarea rows="1" [(ngModel)]="_level_arr[i]['item_desc']" class="form-control" cols="50"
                    placeholder="Enter Item Description"></textarea>
                </div>
                <div class="col-1" *ngIf="_level_arr[i]['tbl_level_cd']==4">
                  <ng-select [items]="mainService.codeValueTechObj['EMB059']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="Select Unit" [(ngModel)]="_level_arr[i]['item_unit']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                    name="work_order_id" (change)="skip(_level_arr[i]['quantity'],i,'quantity')">
                  </ng-select>
                </div>
                <div class="col-2 d-flex" *ngIf="_level_arr[i]['tbl_level_cd']==4">

                  <input type="number" [(ngModel)]="_level_arr[i]['quantity']" class="form-control" style="width: 70%;"
                    placeholder="Enter Quantity" (keydown)="skip(_level_arr[i]['item_unit'],i,'item_unit')">

                  <!-- Sub analysis Refrences  -->
                  <ng-select [items]="sNoForRef" *ngIf="!insertSubAnalysisEnabled" matTooltip="Refrence For Rate"
                    matTooltipPosition="above" style="width: 43%;margin-left: 15px;" [multiple]="false"
                    placeholder="Reference Item" [(ngModel)]="_level_arr[i]['sNoRef']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true">
                  </ng-select>

                </div>


                <div class="col-3" *ngIf="_level_arr[i]['tbl_level_cd']==5"></div>
                <div class="col-1" *ngIf="_level_arr[i]['tbl_level_cd']==5">
                  <input type="text" [(ngModel)]="_level_arr[i]['level_cd']" class="form-control"
                    placeholder="Enter level Code">
                </div>
                <div class="col-2 " *ngIf="_level_arr[i]['tbl_level_cd']==5">
                  <h6> <textarea rows="1" [(ngModel)]="_level_arr[i]['item_desc']" class="form-control" cols="50"
                      placeholder="Enter Item Description"></textarea></h6>
                </div>
                <div class="col-1" *ngIf="_level_arr[i]['tbl_level_cd']==5">

                </div>
                <div class="col-2" *ngIf="_level_arr[i]['tbl_level_cd']==5">
                  <input type="number" [(ngModel)]="_level_arr[i]['quantity']" class="form-control" placeholder="">
                </div>

                <div class="col-3" *ngIf="_level_arr[i]['tbl_level_cd']==6"></div>
                <div class="col-1" *ngIf="_level_arr[i]['tbl_level_cd']==6">
                  <input type="text" [(ngModel)]="_level_arr[i]['level_cd']" class="form-control"
                    placeholder="Enter level Code">
                </div>
                <div class="col-2 top-margin" *ngIf="_level_arr[i]['tbl_level_cd']==6">
                  <h6>{{_level_arr[i]['item_desc']}}</h6>
                </div>
                <div class="col-1" *ngIf="_level_arr[i]['tbl_level_cd']==6">

                </div>
                <div class="col-2" *ngIf="_level_arr[i]['tbl_level_cd']==6">
                  <input type="number" [(ngModel)]="_level_arr[i]['quantity']" class="form-control" placeholder="">
                </div>

                <div class="col-3" *ngIf="_level_arr[i]['tbl_level_cd']==7"></div>
                <div class="col-1" *ngIf="_level_arr[i]['tbl_level_cd']==7">
                  <input type="text" [(ngModel)]="_level_arr[i]['level_cd']" class="form-control"
                    placeholder="Enter level Code">
                </div>
                <div class="col-2 top-margin" *ngIf="_level_arr[i]['tbl_level_cd']==7">
                  <h6>{{_level_arr[i]['item_desc']}}</h6>
                </div>
                <div class="col-1" *ngIf="_level_arr[i]['tbl_level_cd']==7">

                </div>
                <div class="col-2" *ngIf="_level_arr[i]['tbl_level_cd']==7">
                  <input type="number" [(ngModel)]="_level_arr[i]['quantity']" class="form-control" placeholder="">
                </div>

                <div class="col-3" *ngIf="_level_arr[i]['tbl_level_cd']==8"></div>
                <div class="col-1" *ngIf="_level_arr[i]['tbl_level_cd']==8">
                  <input type="text" [(ngModel)]="_level_arr[i]['level_cd']" class="form-control"
                    placeholder="Enter level Code">
                </div>
                <div class="col-2 top-margin" *ngIf="_level_arr[i]['tbl_level_cd']==8">
                  <h6>{{_level_arr[i]['item_desc']}}</h6>
                </div>
                <div class="col-1" *ngIf="_level_arr[i]['tbl_level_cd']==8">

                </div>
                <div class="col-2" *ngIf="_level_arr[i]['tbl_level_cd']==8">
                  <input type="number" [(ngModel)]="_level_arr[i]['quantity']" class="form-control" placeholder="">
                </div>


                <div class="col-3 action">
                  <button class=" btn btn-outline-primary" style="margin-right:0.5%;padding: 3px;" (click)="level(2,i)">
                    L. 2 </button>
                  <button class=" btn btn-outline-primary" style="margin-right:0.5%;padding: 3px;" (click)="level(3,i)">
                    L. 3 </button>
                  <button class=" btn btn-outline-primary" style="margin-right:0.5%;padding: 3px;" (click)="level(4,i)">
                    L. 4 </button>
                  <button class=" btn btn-outline-primary" style="margin-right:0.5%;padding: 3px;" (click)="level(5,i)">
                    F.W. </button>
                  <button class=" btn btn-outline-primary" style="margin-right:0.5%;padding: 3px;" (click)="level(6,i)">
                    O.C. </button>
                  <button class=" btn btn-outline-primary" style="margin-right:0.5%;padding: 3px;" (click)="level(7,i)">
                    C.P. </button>
                  <button class=" btn btn-outline-primary" style="margin-right:0.5%;padding: 3px;" (click)="level(8,i)">
                    Royality </button>
                  <button class=" btn btn-outline-danger" style="margin-right:0.5%;padding: 3px;" *ngIf="i!=0"
                    (click)="delete(i)">Delete</button>
                </div>




              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <button class="btn btn-outline-primary" *ngIf="!update_flag" style="margin-right:0.5%"
                    (click)="submit()">Submit</button>
                  <button class="btn btn-outline-primary" *ngIf="update_flag" style="margin-right:0.5%"
                    (click)="update()">Update</button>
                </div>
              </div>
            </div>
            <div id="tab-7-4">
            </div>
            <div id="tab-7-5" *ngIf="map_m_item">

            </div>
            <div id="tab-7-6" *ngIf="create_m_excel_item">
              <br>
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Costing Rate Standard : <span class="text-danger">*</span>
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['EMB055']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="Select Costing Rate Standard" [(ngModel)]="obj['costing_rate_std']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                    name="work_order_id" disabled>
                  </ng-select>
                </div>
                <div class="col-2"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Year of Costing Rate Standard : <span class="text-danger">*</span>
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['EMB056']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="Select Year of Costing Rate Standard" [(ngModel)]="obj['fin_year']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                    name="work_order_id">
                  </ng-select>
                </div>
                <div class="col-2"></div>
              </div>
              <br>

              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Region <span class="text-danger">*</span>
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['EMB058']" [multiple]="false" bindLabel="value"
                    bindValue="code" placeholder="Select Region" [(ngModel)]="obj['region']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true">
                  </ng-select>
                </div>
                <div class="col-2"></div>
              </div>
              <br>

              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Select Chapter No : <span class="text-danger">*</span>
                </div>
                <div class="col-3" *ngIf="!update_flag">
                  <ng-select [items]="mainService.codeValueTechObj['EMB057']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="Select Chapter No" [(ngModel)]="obj['chapter_no']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                    name="work_order_id">
                  </ng-select>
                </div>
                <div class="col-2"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-left">
                  Chapter Name : <span class="text-danger">*</span>
                </div>
                <div class="col-3">
                  <input type="text" class="form-control" [(ngModel)]="obj['chapter_name']"
                    placeholder="Enter Chapter Name">
                </div>
                <div class="col-2"></div>
              </div>
              <!-- Excel  -->
              <br>
              <br>

              <div class="row">
                <div class="col-6 text-right">

                  <h6>
                    Download Excel Format :
                  </h6>

                </div>
                <div class="col-6">

                  <button class="btn btn-link " (click)="exportExcelFormat()">
                    Download
                  </button>

                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-12 text-center">
                  <input type="file" onclick="this.value=null" (change)="onFileChange1($event)"
                    placeholder="Upload file" accept=".xlsx" />
                </div>
              </div>
              <br />
              <br />
              <!-- +++++++++++++++++++++++++++++++ -->
              <div>
                <!-- <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">
                <div class="col-12 text-center">
                  <h5 style="margin-top: 8px;">MORTH </h5>
                </div>
              </div> -->

                <div class="row border border-secondary" style="margin-left:0px; margin-right:0px">

                  <div class="col-1">Sr No.</div>
                  <div class="col-1">Ref. to Morth&H specifi.</div>
                  <div class="col-1">Level Code</div>
                  <div class="col-3">Item Description</div>
                  <div class="col-1">Unit</div>
                  <div class="col-1">Item Unit</div>
                  <div class="col-1">Quantity</div>
                  <div class="col-1">Tak_Op</div>

                  <div class="row border border-secondary" *ngFor="let  itemObj of showExcelData; index as j"
                    style="margin-left:0px; margin-right:0px">
                    <div class="col-1">{{itemObj['sno']}}</div>
                    <div class="col-1">{{itemObj['ref_to_morth']}} </div>
                    <div class="col-1">{{itemObj['level_cd']}} </div>
                    <div class="col-3">{{itemObj['item_desc']}} </div>
                    <div class="col-1"> {{itemObj['unit']}}</div>
                    <div class="col-1"> {{itemObj['item_unit']}}</div>
                    <div class="col-1">{{itemObj['quantity']}} </div>
                    <div class="col-1" *ngIf="itemObj['tak_op']">{{itemObj['tak_op']}} </div>
                  </div>
                </div>
                <!-- ++++++++++++++++++++++ -->
                <br />
                <div class="row">
                  <div class="col-12 text-center">
                    <button class="btn btn-outline-primary" style="margin-right:0.5%"
                      (click)="submitByExcel()">Submit</button>
                  </div>
                </div>
              </div>

            </div>
            <br>
          </div>

        </div>
      </div>

    </div>
