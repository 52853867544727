<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Default Method</div>
                </div>

                <div class="ibox-body">
                    <!--  -->
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab"><i class="fa fa-line-chart"></i>Default Method
                                List</a>
                        </li>
                    </ul>
                    <!--  -->

                    <div class="tab-content tabcontent">


                        <div *ngIf="flag">
                            <br />
                            <br />
                            <!-- create btn  start -->

                            <div class="row">
                                <div class="col-10"></div>
                                <div class="col-2 text-center">
                                    <button class="btn btn-outline-primary" (click)="show()">
                                        Create
                                    </button>
                                </div>
                            </div>
                            <br />
                            <br />
                            <!-- create btn  end  -->
                            <!-- table code start -->

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="search">
                            </mat-form-field>

                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                                    <ng-container matColumnDef="defualt_method_id">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="left">
                                            ID.
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i=
                                            index" class="right">
                                            {{ element.defualt_method_id }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="no_of_failed_inst_default">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            No of failed installments to default
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.no_of_failed_inst_default
                                            }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="no_of_notice_before_cancellation">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                             No of Notices before Cancellation
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{
                                            element.no_of_notice_before_cancellation
                                            }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="seize_period">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Sieze Period
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.seize_period }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right">
                                            Action
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button class="btn
                                                btn-outline-primary">Update</button>
                                            &nbsp; -->
                                            <button class="btn
                                                btn-outline-danger" (click)="delete(element)">Remove</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns:
                                        displayedColumns"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>

                        <!-- create section -->
                        <div *ngIf="!flag">
                            <div class="row">
                                <div class="col-10"></div>
                                <div class="col-2 text-center">
                                    <button class="btn btn-outline-primary" (click)="show()">
                                        Show List
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3 text-right xyz">
                                            <h6>No of Failed Installments to
                                                default :</h6>
                                        </div>
                                        <div class="col-3 text-left">
                                            <div class="form-outline">
                                                <input type="number" id="typeText" class="form-control"
                                                    placeholder="enter no of failed installment" #input
                                                    [(ngModel)]="obj['no_of_failed_inst_default']" />
                                            </div>
                                        </div>
                                    </div>

                                    <br />
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3 text-right xyz">
                                            <h6>No. of Notices before
                                                Cancellation :</h6>
                                        </div>
                                        <div class="col-3 text-left">
                                            <div class="form-outline">
                                                <input type="number" id="typeText" class="form-control"
                                                    placeholder="No. of Notices before Cancellation" #input
                                                    [(ngModel)]="obj['no_of_notice_before_cancellation']"
                                                    (keyup)="testinput()" />
                                            </div>
                                        </div>
                                        <!-- <div class="col-3 text-left">
                                            <button class="btn btn-outline-primary" (click)="testinput()">
                                                Add Row
                                            </button>
                                        </div> -->
                                    </div>

                                    <div class="row input" *ngFor="let in of inputarray; let i=
                                        index">
                                        <br />
                                        <div class="col-3"></div>
                                        <div class="col-3 text-right xyz">
                                            <h6>Time Period Allowed in Notice {{
                                                i + 1 }} :</h6>
                                        </div>
                                        <div class="col-3 text-left">
                                            <div class="form-outline">
                                                <input type="number" id="typeText" class="form-control"
                                                    placeholder="No of Days" #input [(ngModel)]="nobj[i]" />
                                            </div>
                                        </div>
                                    </div>

                                    <br />


                                    <br />
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3 text-right xyz">
                                            <h6>Property Seize Period :</h6>
                                        </div>
                                        <div class="col-3 text-left">
                                            <div class="form-outline">
                                                <input type="number" id="typeText" class="form-control"
                                                    placeholder="No of Days" #input [(ngModel)]="obj['seize_period']" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col-5"></div>

                                <div class="col-3 text-center">
                                    <button class="btn btn-outline-primary" (click)="postdata()" (click)="show()">
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                            <br />
                        </div>

                        <!-- end create secttion -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
