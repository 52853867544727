<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users1']}}
                        <!-- Users -->
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-1" *ngIf="list_flag" (click)="refresh()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users2']}} <!-- User List -->
                                    </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-2" *ngIf="create_flag" (click)="refresh()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users3']}} <!-- Create User -->
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-7-3" data-toggle="tab"><i class="ti-settings"
                                    aria-hidden="true"> </i> Update User
                            </a>
                        </li> -->

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-7-1">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_create()>Map User</button>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_assignment4']}}">
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                </div>
                                <div class="col-4">
                                    <!-- <button class="btn btn-danger" (click)="deleteAll()">Delete All</button> -->
                                </div>
                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>



                                    <ng-container matColumnDef="user_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users4']}}
                                            <!-- User ID -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.user_id }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users5']}}
                                            <!-- User Name -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users6']}}
                                            <!-- Phone No -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.phone_no }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="email">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users7']}}
                                            <!-- Email -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.contact_email}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="role_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users8']}}
                                            <!-- Role Description -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.role_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="delete">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button class="btn btn-danger" (click)="Re_Assign(element)">Re-Assign
                                                User</button>
                                            <button class="btn btn-info" (click)="open_update(element)">Update
                                                User</button>&nbsp; -->
                                            <button (click)="modalOpen(element)" class="btn btn-danger">Delete</button>

                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>

                        <div class="tab-pane fade  show active" *ngIf="create_flag" id="tab-7-2">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users9']}}
                                        <!-- Select Party  -->
                                        : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="allSearchableEmp" bindLabel="desc" bindValue="user_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_users9']}}" [(ngModel)]="Obj['user_id']" [selectableGroup]="true" (change)="setDetails()"
                                        [selectableGroupAsModel]="true" name="chart_of_account0">res
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users10']}}
                                        <!-- Name  -->
                                        :</h6>
                                </div>
                                <div class="col-3">
                                    <input type="email" disabled placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users10']}}" class="form-control" [(ngModel)]="Obj['name']">
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users7']}}
                                        <!-- Email -->
                                        :</h6>
                                </div>
                                <div class="col-3">
                                    <input type="email" disabled placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users11']}}" class="form-control" [(ngModel)]="Obj['email']">
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users6']}}
                                        <!-- Phone Number : -->
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="number" disabled placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_users12']}}" class="form-control" [(ngModel)]="Obj['phone']">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users11']}}
                                        <!-- Select Role  -->
                                        : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_users13']}}" [items]="current_role" bindLabel="role_name" bindValue="role_cd" [selectableGroup]="true" [selectableGroupAsModel]="false" aria-placeholder="Select Role"
                                        [closeOnSelect]="true" name="hierarchy" [(ngModel)]="Obj['role_cd']">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>


                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">{{mainService.allLableShowObjHR[mainService.language_cd+'hr_assignment12']}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-7-3">
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users7']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_users11']}}" class="form-control" [(ngModel)]="Obj['email']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"> {{mainService.allLableShowObjHR[mainService.language_cd+'hr_users6']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="number" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_users12']}}" class="form-control" [(ngModel)]="Obj['phone_no']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role10']}}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'hr_assignment14']}}</ngx-spinner>
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">All Roles</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="card">
                    <div style="margin: 2%;">
                        <table class="table table-borederd" style="width: 80%; margin-left: 10%;">
                            <thead>
                                <tr>
                                    <th>S No.</th>
                                    <th>Role Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of single_users_role,let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{item['role_desc']}}</td>
                                    <td>
                                        <button class="btn btn-danger" *ngIf="single_users_role.length==1 && item['role_cd']=='HR_EMP'" (click)="delete(item)">Delete</button>
                                        <button class="btn btn-danger" *ngIf="single_users_role.length==1 && item['role_cd']!='HR_EMP'" (click)="delete(item)">Delete</button>
                                        <button class="btn btn-danger" *ngIf="single_users_role.length!=1 && item['role_cd']!='HR_EMP'" (click)="delete(item)">Delete</button> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
