import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingService } from '../../service/setting.service'
import { Router } from '@angular/router';

import swal from 'sweetalert2';
const moment = require('moment');
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MainService as portal_mainService } from '../../../portal/service/main.service';


declare var $: any
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor(private settingService: SettingService, private router: Router, private portal_service: portal_mainService) { }


  displayedColumns = ['complain_id', 'complian_type', 'complain_from', 'owner_name', 'property_address', 'status'];

  erpUser:any;
  b_acct_id:any;
  user_id:any;
  datasource:any;
  async ngOnInit() {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.getPendingTask();


  }



  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  notification :any= [];





  back() {
    this.router.navigate(['/icms/icms-index'])
  }

  async JE_AE_Notification(data:any) {
    data.map((x:any) => {
      let d1 = moment(x['comp_assign_date'])
      let complain_date = moment(d1).add(3,'days')['_d'];
      let today = moment()['_d']
      if (today > complain_date && x['status'] == "UNDER-INSPECTION") {
        this.notification.push(x);
      }
    })
  }
  async Zonal_notification(data:any) {
    this.notification=[]
    data.map((x:any) => {
      let d1 = moment(x['comp_assign_date'])
      let complain_date :any = moment(d1).add(5, 'days')['_d'];
      let today = moment()['_d']
      if (today > complain_date && x['status'] == "UNDER-INSPECTION") {
        this.notification.push(x);
      }
    })
  }




  async getPendingTask() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id;
    console.log("Checking user id while getting notification : ", obj)
    let resp = await this.settingService.getPendingTask(JSON.stringify(obj));

    console.log("notification data  : ", resp['data']);

    if (resp['error'] == false) {

      if (this.erpUser.role_cd.includes('ICMS_JE') || this.erpUser.role_cd.includes('ICMS_AE')) {
        await this.JE_AE_Notification(resp['data'])
      }

      if (this.erpUser.role_cd.includes('ICMS_ZO')) {
        await this.Zonal_notification(resp['data'])
      }

      // resp['data'].map(x => {
      //   let d1 = moment(x['comp_assign_date'])
      //   let complain_date = moment(d1).add(3, 'days')['_d'];
      //   let today = moment()['_d']
      //   if (today > complain_date && x['status'] == "UNDER-INSPECTION") {
      //     this.notification.push(x);
      //   }
      // })
      console.log(this.notification)
      this.datasource = new MatTableDataSource(this.notification)

    } else {
      swal.fire('ERROR', 'Error Occurred While Getting Pending Task', 'error');
    }
  }



}
