<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Financial Year Manage</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" *ngIf="list_flag==true" data-toggle="tab"><i
                                    class="ti-bar-chart"></i>
                                All Financial Year List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-2" *ngIf="create_flag==true" data-toggle="tab"><i
                                    class="ti-settings"></i> Create Financial Year</a>
                        </li>



                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag==true" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-9">
                                    <h5><b>Current Fiscal Year</b></h5>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-10">
                                    <table>
                                        <tr>
                                            <th>Fiscal Year</th>
                                            <th>Calendar</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Status</th>
                                            <th>Actions</th>

                                        </tr>
                                        <tr>
                                            <td>{{mainService.codeValueShowObj['ACC0025'][openFinYear['fin_year']]}}
                                            </td>
                                            <td>{{openFinYear['calender']}}</td>
                                            <td>{{openFinYear['start_date']}}</td>
                                            <td>{{openFinYear['end_date']}}</td>
                                            <td>{{openFinYear['status']}}</td>

                                            <td><button class="btn btn-success"
                                                    (click)="closeFinYear(openFinYear)">Close Year</button></td>

                                        </tr>
                                    </table>
                                </div>

                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-8"></div>

                                <div class="col-1"><button class="btn btn-primary" (click)="gotocreate();">Add
                                        Next</button></div>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-7">
                                    <h5><b>Future Fiscal Year</b></h5>
                                </div>

                            </div>
                            <br>

                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-8">
                                    <table>
                                        <tr>
                                            <th>Fiscal Year</th>
                                            <th>Calendar</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Status</th>

                                        </tr>
                                        <tr *ngFor="let futureObj of futureFinYear">
                                            <td>{{mainService.codeValueShowObj['ACC0025'][futureObj['fin_year']]}}</td>
                                            <td>{{futureObj['calender']}}</td>
                                            <td>{{futureObj['start_date']}}</td>
                                            <td>{{futureObj['end_date']}}</td>
                                            <td>{{futureObj['status']}}</td>


                                        </tr>
                                    </table>
                                </div>


                            </div>
                            <br>

                            <br>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-8">
                                    <h5><b>Closed Fiscal Year</b></h5>
                                </div>


                            </div>

                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-8">
                                    <table>
                                        <tr>
                                            <th>Fiscal Year</th>
                                            <th>Calendar</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Status</th>

                                        </tr>
                                        <tr *ngFor="let closedObj of closedFinYear">
                                            <td>{{mainService.codeValueShowObj['ACC0025'][closedObj['fin_year']]}}</td>
                                            <td>{{closedObj['calender']}}</td>
                                            <td>{{closedObj['start_date']}}</td>
                                            <td>{{closedObj['end_date']}}</td>
                                            <td>{{closedObj['status']}}</td>


                                        </tr>
                                    </table>
                                </div>


                            </div>



                        </div>
                        <div class="tab-pane fade show active" *ngIf="create_flag==true" id="tab-2">
                            <br>
                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="goBack()">Back to list</button>
                            </div>
                            <br>
                            <form name="form" (ngSubmit)="f.form.valid && addNewRow()" #f="ngForm">
                                {{f.form.valid}}
                                <div class="row">
                                    <div class="col-4 text-right">
                                        <h6> Select Fiscal Calendar :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="calender" bindLabel="code" bindValue="code"
                                            [multiple]="false" placeholder="Select Fiscal Calendar"
                                            [(ngModel)]="obj['calender']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                            (change)="calenderChange()" name="fin_cal" #cal="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && cal.invalid }" required>
                                        </ng-select>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="cal.dirty && cal.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && cal.invalid" class="error-message">
                                            <div *ngIf="cal.errors?.['required']">Please select fiscal calendar </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-2">

                                    </div>
                                    <div class="col-8" style=" border-style: groove ;">

                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Fiscal Year </h6>

                                            </div>
                                            <div class="col-4">
                                                <input disabled type="text" [(ngModel)]="obj['fin_year']"
                                                    class="form-control" name="fin_year">


                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6> Fiscal Year Start Date</h6>

                                            </div>
                                            <div class="col-4">
                                                <input disabled type="text" [(ngModel)]="obj['start_date']"
                                                    class="form-control" name="start_date" >


                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Fiscal Year End Date</h6>

                                            </div>
                                            <div class="col-4">
                                                <input disabled type="text" [(ngModel)]="obj['end_date']"
                                                    class="form-control" name="end_date">


                                            </div>
                                        </div>
                                        <br>

                                    </div>
                                    <div class="col-2">

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary" type="submit">Submit</button>

                                    </div>
                                </div>
                            </form>
                        </div>




                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
