import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import * as  pdfMake from "pdfmake/build/pdfmake";
import Swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { LandInputService } from '../../service/land-input.service';
import { last, max } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { FileSelectDirective, FileDropDirective,  FileUploader } from 'ng2-file-upload';
declare var $: any;
import { AuthenticationService } from '../../../authentication.service';
import { MatStepper } from '@angular/material/stepper';
import { PropCostingService } from '../../service/prop-costing.service';
import { PropCommonSService } from '../../service/prop-common-s.service';

function readBase64(file:any): Promise<any> {
  var reader  = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);

    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);

    reader.readAsDataURL(file);
  });
  return future;
}

const URL = '/api/';

@Component({
  selector: 'app-property-inventory',
  templateUrl: './property-inventory.component.html',
  styleUrls: ['./property-inventory.component.css'],
})
export class PropertyInventoryComponent implements OnInit {

  public uploader: FileUploader = new FileUploader({url: this.mainService.httpUrl + '/property/properties/uploadpropertiesimages'});
  public hasBaseDropZoneOver:boolean = false;
  public mimeType: any;

  // URL =   this.mainService.httpUrl + '/property/properties/uploadpropertiesimages' ;
  datasource:any;
  isLinear:any = false;
  first:any = true;
  selectedFile: any = null;
  second:any = true;
  radio:any = true;
  displayedColumns = ['assigned_property_number', 'property_type', 'description', 'area', 'action'];
  GISArr:any = []
  third:any = true;
  fourth:any = true;
  work_id:any;
  BoqTable:any = [];
  total_sum:any = 0;
  imgURL:any;
  Obj :any= {}
  docArr :any= [{}]
  enableStep3: any;
  b_acct_id:any;
  ebillUser:any;
  planing_arr :any= [{}]
  acp_arr:any = [{}]
  public imagePath:any;
  httpUrl:any;
// samar

// samar

  choosen_file = false
  choosen_file_uploaded = false
  outer_acq_row = [{
    dropDown: [{
      existing_heirarchy_nodes: []
    }], data: [{}]
  }]
  data :any= [{}]
  features = [{ type: 1, feature_ident: 'Corner', feature_value: 'YES' },
  { type: 1, feature_ident: 'Park Facing', feature_value: 'YES' },
  { type: 2, feature_ident: 'On 60 Mt Road', feature_value: '60 Mt' },
  { type: 2, feature_ident: 'On 45 Mt Road', feature_value: '45 Mt' },
  { type: 2, feature_ident: 'On 25 Mt Road', feature_value: '25 Mt' },
  { type: 2, feature_ident: 'On 18 Mt Road', feature_value: '18 Mt' },
  { type: 2, feature_ident: 'On 12 Mt Road', feature_value: '12 Mt' },
  { type: 2, feature_ident: 'On 9 Mt Road', feature_value: '9 Mt' },
  { type: 2, feature_ident: 'On 6 Mt Road', feature_value: '6 Mt' },
  { type: 3, feature_ident: 'Total Area', feature_value: 0 },
  { type: 3, feature_ident: 'Carpet Area', feature_value: 0 },
  { type: 4, feature_ident: 'Acquisition Location', feature_value: '', area: 60, unit: 'Meter' },
  { type: 4, feature_ident: 'Planing Location', feature_value: '', area: 60, unit: 'Meter' }]
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;
  leaf_level: any;

  constructor(private spinner: NgxSpinnerService, private costingService: PropCostingService,
     private propHierS: PropHierarchyService, private commonS: PropCommonSService,
     public auth: AuthenticationService, public mainService: MainService,
     private landInput_S: LandInputService, private hierarchyS: PropHierarchyService, public toastr: ToastrService, private sanitizer: DomSanitizer) {

  }
  // samar

  // samar



  allProjects = []
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;

    await this.getAllHier()
    await this.getAllLevls2()
    await this.getpropertygroup()
    await this.open_list()
    await this.getFeatures()
    this.Obj['total_area'] = 0
    this.httpUrl = this.mainService.httpUrl

    this.uploader = new FileUploader({
      url: this.mainService.httpUrl + '/property/properties/uploadpropertiesimages',
      allowedMimeType: ['image/jpeg', 'image/png', 'image/jpg'],
      maxFileSize:20 * 1024,
      // ,disableMultipart: true
      // ,allowedMimeType: ['application/pdf']
     });
     this.uploader.onAfterAddingFile = (file)=> {
      file.withCredentials = false;
      // let url = (window.URL) ? window.URL.createObjectURL(file._file) : (window as any).webkitURL.createObjectURL(fileItem._file);
    // this.localImageUrl = url
     };
  // this.uploader.onAfterAddingFile = f => {
  //     if (this.uploader.queue.length > 1) {
  //         this.uploader.removeFromQueue(this.uploader.queue[0]);
  //     }
  // };

  this.uploader.onBeforeUploadItem = (item)=> {console.log("Item"); console.log(item)};

  this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
    console.log("ImageUpload:uploaded:", item, status, response);
  };
  this.uploader.onWhenAddingFileFailed = (item) => {
      console.log(item);
  }
  }
  // all image related work
  returnURL(item:any){
    let url = (window.URL) ? window.URL.createObjectURL(item._file) : (window as any).webkitURL.createObjectURL(item._file)
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  uploadSingle(item:any){
    const obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.Obj['user_id'];
    obj['property_id'] = this.Obj['property_id'];
    console.log(obj);
    // console.log(this.uploader.queue.length)
    // for (let index = 0; index < this.uploader.queue.length; index++) {
      obj['doc_name'] = item.file.name;
      let params:any = JSON.stringify(obj);
      item.url = this.mainService.httpUrl + '/property/properties/uploadpropertiesimages'+params
      item.upload()
    // }
  }
  uploadAll(){
    // console.log(this.uploader.queue.length)
    const obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.Obj['user_id'];
    obj['property_id'] = this.Obj['property_id'];
    console.log(obj);
    console.log(this.uploader.queue.length)
    for (let index = 0; index < this.uploader.queue.length; index++) {
      obj['doc_name'] = this.uploader.queue[index].file.name;
      let params = JSON.stringify(obj);
      this.uploader.queue[index].url = this.mainService.httpUrl + '/property/properties/uploadpropertiesimages'+params
    }
    this.uploader.uploadAll()

  }
  fileObject: any;


  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  public onFileSelected(event:any) {
    const file: File = event[0];

    console.log(file);
    console.log(file.size)

    readBase64(file)
      .then(function(data) {
      console.log(data);
    })

  }

  setMimeType(mimeType:any) {
    this.mimeType = mimeType;
  }


  fileURL:any;
  // imageUrls = []
  // async getViewOfAllImage(){
  //   this.imageUrls = []
  //   this.selectedImg= {}
  //   for (let index = 0; index < this.proImages.length; index++) {
  //        let url = await this.view(this.proImages[index])
  //        this.imageUrls.push(url)
  //    }
  // }
  // openImgNextModel(url){
  //   this.fileURL = url
  //   $('#myModal2').modal('show');
  // }


  // imgListFlag = false
  // async showImgListflag(){
  //   this.imgListFlag = ! this.imgListFlag
  //   if(this.imgListFlag){
  //     await this.getAllpropertyimagesObj()
  //     await this.getViewOfAllImage()
  //     this.selectAllimg = false
  //   }
  // }
  // selectedImg={}
  // async deleteSelImg(){
  //   let obj :any= {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['imgObj'] = []
  //   for(let i of Object.keys(this.selectedImg)){
  //     if(this.selectedImg[i]){
  //       console.log(this.proImages[parseInt(i)])
  //       obj['imgObj'].push(this.proImages[parseInt(i)])
  //     }
  //   }
  //   this.spinner.show()
  //   let resp:any = await this.landInput_S.deleteImg(obj)
  //   if(resp['error']){
  //     this.spinner.hide()
  //     Swal.fire('Error','Server Side Error','error')
  //   }else{
  //     for(let i of Object.keys(this.selectedImg)){
  //       if(this.selectedImg[i]){
  //         this.imageUrls.splice(parseInt(i),1)
  //         console.log(this.imageUrls)
  //       }
  //     }
  //     this.spinner.hide()
  //     Swal.fire('Deleted',"Succesfully deleted",'success')
  //     this.selectedImg ={}
  //   }
  // }
  // selectAllimg = false
  // slecectAllimg(){
  //   this.selectAllimg = !this.selectAllimg
  //   for (let index = 0; index < this.proImages.length; index++) {
  //     this.selectedImg[index]=this.selectAllimg;
  //   }
  // }

  // async view(item) {
  //   this.spinner.show()
  //   var obj :any= {}
  //   obj['property_id'] = item['property_id']
  //   obj['image_no'] = item['image_no']
  //   obj['doc_format'] = item['doc_format']
  //   obj['b_acct_id'] = this.b_acct_id
  //   console.log(obj, item)
  //   this.spinner.show()
  //   const res = await this.landInput_S.getProImages(obj);
  //   console.log(res)
  //   if (res) {
  //     this.spinner.hide()
  //     // var docname = obj['doc_name'];
  //     var ext = obj['doc_format'];
  //     if (ext == 'png' || ext == 'jpeg' || ext == 'jpg' || ext == 'PNG' || ext == 'JPEG' || ext == 'JPG') {
  //       const unsafeImageUrl = window.URL.createObjectURL(res);
  //       return this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
  //     }
  //   } else {
  //     this.spinner.hide()
  //   }
  // }

  // proVideoObj = {}
  // async getVideos(){
  //   let obj :any= {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['property_id'] = this.Obj['property_id'];
  //   console.log(obj)
  //   this.spinner.show()
  //   let vidOvj= await this.landInput_S.getAllVideo(obj)
  //   console.log(vidOvj)
  //   if(!vidOvj['error']){
  //     this.proVideoObj = vidOvj['data']
  //     this.nowAddedVideo = this.proVideoObj['video_link']
  //   }
  //   this.spinner.hide()
  // }

  //   {
  //     "video_id": 1,
  //     "property_id": 31,
  //     "video_link": "https://youtu.be/YtYq5prElVE"
  // }
  // async deleteVid(){
  // // this.spinner.show()
  // let res = await this.landInput_S.delProVid({b_acct_id:this.b_acct_id, 'vidObj':[this.proVideoObj]})
  //  if(!res['error']){
  //   // this.spinner.hide()
  //   Swal.fire('Deleted',"succesfully deleted",'success')
  //   this.nowAddedVideo = ""
  //  }else{
  //   // this.spinner.hide()
  //   Swal.fire('Error',"Server Error",'error')
  //  }
  // }

  // youtubevideo="";
  // nowAddedVideo=""
  // async addVideo(){
  //   var obj :any= {}
  //   obj['video_link'] = this.youtubevideo
  //   obj['user_id'] = this.ebillUser.user_id
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['property_id'] = this.Obj['property_id'];
  //   console.log(obj)
  //   this.spinner.show()
  //   const res = await this.landInput_S.addProVid(obj);
  //   if(!res['error']){
  //     Swal.fire("Succes","video added",'success')
  //     this.nowAddedVideo = this.youtubevideo
  //   }
  //   this.spinner.hide()
  // }





  list_flag = true
  create_flag = false
  update_flag = false
  async open_create() {

    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
    await this.setPreDefinedHierrarchy()

  }
  async open_list() {
    this.Obj = {}
    this.data = []
    this.data = [{}]
    this.planing_arr = []
    this.planing_arr = [{}]
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    await this.getAllProperty()
  }
  setLeafNode() {
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }

  first_list = []
  async getAllHier() {
    this.first_list = []
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['buss_ent_type'] = 'Location'
    //console.log(obj)
    var resp:any = await this.propHierS.getLocationHierarchy(JSON.stringify(obj));
    if (resp['error'] == false) {
      //console.log(resp, 'list data')
      this.first_list = resp['data']
      for (let i = 0 + 1; i <= 8; i++) {
        delete this.Obj['lvl' + i + '_cd'];
      }
      this.data = []
      this.data = [{}]
    } else {

    }
  }
  allHier:any = []
  hier_node_obj :any= {}
  async getAllLevls() {
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['hier_type_id'] = this.Obj['hier_type_id']
    console.log(obj)
    var resp:any = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp, ' data')
      this.allHier = resp.data;
      this.level1 = await this.commonS.refresh(this.allHier)

    } else {
    }
  }
  async getAllLevls2() {
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    var resp:any = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp, ' data')
      let data = resp.data;
      for (let i = 0; i < data.length; i++) {
        this.hier_node_obj[data[i]['prop_hier_id']] = data[i]
      }
      console.log(this.hier_node_obj, 'hier_node_obj')

    } else {
    }
  }
  level1:any = []
  level2:any = []
  level3:any = []
  level4:any = []
  level5:any = []
  level6:any = []
  level7:any = []
  data_list:any = []
  async setPreDefinedHierrarchy() {
    this.data_list = []
    let data:any = {}
    for (let i = 0; i < this.allPlan_layout.length; i++) {
      if (this.allPlan_layout[i]['property_group_id'] == this.Obj['property_group_id']) {
        data = Object.assign({}, this.allPlan_layout[i])
        this.Obj['hier_type_id'] = data['hier_type_id']
        await this.getAllLevls()
        await this.getpropertyGroupdata(this.Obj['property_group_id'])
      }
    }
    let ob = this.hier_node_obj[data['prop_hier_id']]
    console.log(data, this.allPlan_layout, this.hier_node_obj, this.allHier, ob)
    let dummy2 = []
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        dummy2.push(ob['lvl' + j + '_value'])
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
    await this.commonS.refresh(this.allHier)
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        this.data_list.push(ob)
        let index = j
        if (index == 1) {
          this.level2 = await this.commonS.onChangeLvl1(this.allHier, ob)
        } else if (index == 2) {
          this.level3 = await this.commonS.onChangeLvl2(this.allHier, ob)
        }
        else if (index == 3) {
          this.level4 = await this.commonS.onChangeLvl3(this.allHier, ob)
        }
        else if (index == 4) {
          this.level5 = await this.commonS.onChangeLvl4(this.allHier, ob)
        }
        else if (index == 5) {
          this.level6 = await this.commonS.onChangeLvl5(this.allHier, ob)
        }
        else if (index == 6) {
          this.level7 = await this.commonS.onChangeLvl6(this.allHier, ob)
        }

      }
    }
    for (let i = 1; i <= 8; i++) {
      if (ob['lvl' + i + '_cd']) {
        this.Obj['lvl' + i + '_cd'] = ob['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }
  allPlan_layout = []
  async getpropertygroup() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['group_type'] = "PLANLAYOUT"
    console.log(obj)
    var resp:any = await this.landInput_S.getpropertygroup(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allPlan_layout = resp['data']

      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['desc'] = 'Plan Layout ' + resp['data'][i]['leaf_value'] + ' Having Total Property - ' + resp['data'][i]['count']

        // resp['data'][i]['desc'] = 'Plan Layout Number ' + resp['data'][i]['property_group_id'] + ' Having Total Property - ' + resp['data'][i]['count']
      }
      console.log(resp['data'], 'fetched data')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  propObj:any = {}
  feature_list :any= []
  async getFeatures() {

    this.spinner.show()
    this.feature_list = []
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['life_cycle_status'] = 'ACTIVE'
    console.log(this.Obj)
    var resp:any = await this.costingService.getFeatures(JSON.stringify(this.Obj));
    if (resp['error'] == false) {
      console.log(resp['data'], 'list')
      this.feature_list=resp['data']
      // let dup = []
      // for (let i = 0; i < resp['data'].length; i++) {
      //   if (resp['data'][i]['feature_type'] == 'Property') {
      //     let basic=
      //     this.feature_list.push(resp['data'][i])
      //   }
      // }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  allProp :any= []
  planLayoutName:any
  async getAllProperty() {
    // this.planLayoutName =this.allPlan_layout.filter(ele=>ele.property_group_id==this.Obj['property_group_id'])[0]['desc']
    this.Obj['property_group_id']
    this.allProp = []
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['property_group_id'] = this.Obj['property_group_id']
    console.log(obj)
    var resp:any = await this.landInput_S.getpropertiesData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allProp = resp['data']
      this.datasource = new MatTableDataSource(this.allProp);
      this.datasource.sort = this.sort.toArray()[0];
      this.datasource.paginator = this.paginator.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  Obj2:any = {}
  async open_update(data:any) {
    if (!this.Obj['property_group_id']) {
      Swal.fire('Error', 'First ** Select Plan Layout', 'error')
    } else {
      console.log(data)
      this.list_flag = false
      this.create_flag = false
      this.Obj = Object.assign({}, data)
      this.Obj2 = Object.assign({}, data)
      await this.getAllLevls()
      await this.setPreDefinedHierrarchy()
      // await this.setPlanLayoutHierarchy()
      this.Obj['unit'] = this.Obj2['unit']
      this.Obj['area'] = this.Obj2['area']
      this.Obj['assigned_property_number'] = this.Obj2['assigned_property_number']
      this.Obj['description'] = this.Obj2['description']
      this.Obj['property_group_id'] = this.Obj2['property_group_id']
      this.Obj['property_id'] = this.Obj2['property_id']
      this.Obj['property_type'] = this.Obj2['property_type']
      this.Obj['hier_ident_code2'] = this.Obj2['hier_ident_code2']
      this.Obj['feature_type'] = data['feature_type']
      console.log(data['feature_id'], JSON.parse(data['feature_id']))
      this.Obj['feature_id_arr'] = JSON.parse(data['feature_id'])
      console.log(this.Obj, 'obj')
      // await this.getAllpropertyimagesObj()
      // await this.getViewOfAllImage()
      // this.getVideos()

      await this.getAllFeaturesDataForUpdate()
      $('.nav-tabs a[href="#tab-7-3"]').tab('show')

      this.update_flag = true
    }
  }
  // allPlan_layout = []
  async getAllFeaturesDataForUpdate() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['property_id'] = this.Obj['property_id']
    console.log(obj)
    var resp:any = await this.landInput_S.getpropertiesWithFeature(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.data = resp['data']
      for (let i = 0; i < this.data.length; i++) {
        let obj :any= {}
        this.data[i]['feature'] = this.data[i]['feature_ident']
        this.data[i]['feature_value'] = this.data[i]['feature_value']
        this.setRowValues2(this.data[i], i)
        console.log(this.data[i], this.HierarchyWithLocationInfo, 'data')
      }
      if (this.data.length == 0) {
        this.data = [{}]
      }
      console.log(resp['data'], 'fetched data')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  f_arr:any = []

  setRowValues(data:any, index:any) {
    console.log(data, index)
    for(let i = 0; i<this.f_arr.length; i ++){
      console.log(this.f_arr[i]['feature'])
      console.log(data)

      if (this.f_arr[i]['feature'] == data['feature']) {
        console.log(1)
        Swal.fire('allready selected', 'select other option', 'warning')

        return
      }

    }
    this.f_arr.push(data)
    console.log(this.f_arr)

        console.log(3)


    for (let i = 0; i < this.features.length; i++) {
      if (data['feature'] == this.features[i]['feature_ident']) {
        data['area'] = this.features[i]['area']
        data['unit'] = this.features[i]['unit']
        data['type'] = this.features[i]['type']
        data['feature_value'] = this.features[i]['feature_value']
      }
    }
  }
  setRowValues2(data:any, index:any) {
    console.log(data, index)
    for (let i = 0; i < this.features.length; i++) {
      if (data['feature'] == this.features[i]['feature_ident']) {
        data['area'] = this.features[i]['area']
        data['unit'] = this.features[i]['unit']
        data['type'] = this.features[i]['type']
        // data['feature_value'] = this.features[i]['feature_value']
      }
    }
  }
  async setPlanLayoutHierarchy() {
    for (let i = 0; i < this.allPlan_layout.length; i++) {
      if (this.Obj['property_group_id'] == this.allPlan_layout[i]['property_group_id']) {
        this.Obj = Object.assign({}, this.allPlan_layout[i])
      }
    }
    console.log(this.Obj, this.allPlan_layout)
    let all_path:any = []
    // console.log(data)
    await this.getAllpropHierarchynodes()
    let planing_path = ''
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (this.Obj['node_cd'] == this.existing_heirarchy_nodes[i]['node_cd']) {
        // console.log()
        planing_path = this.existing_heirarchy_nodes[i]['path']
      }
    }
    let dum :any= []
    dum = planing_path.split('|')
    let dummy2:any = []
    if (dum.length > 0) {
      for (let i = 0; i < dum.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dum[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
    let dummy_data:any = []
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (planing_path.includes(this.existing_heirarchy_nodes[i]['path'])) {
        all_path.push(this.existing_heirarchy_nodes[i]['path'])
        let obj:any = {}
        obj['existing_heirarchy_nodes'] = [this.existing_heirarchy_nodes[i]]
        obj['hierarchy_type'] = this.existing_heirarchy_nodes[i]['node_cd']
        // dummy_data[i]['existing_heirarchy_nodes'] =
        // dummy_data[i]['hierarchy_type'] = this.existing_heirarchy_nodes[i]['node_cd']
        dummy_data.push(obj)
      }
    }
    console.log(dummy_data, 'dummy_data')
    let dummy:any = []
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      // dummy_data[dummy_data.length - 1]['existing_heirarchy_nodes'] = []
      if (!all_path.includes(this.existing_heirarchy_nodes[i]['path'])) {
        dummy_data[dummy_data.length - 1]['existing_heirarchy_nodes'].push(this.existing_heirarchy_nodes[i])
      }
    }
    // dummy_data[dummy_data.length - 1]['existing_heirarchy_nodes']=dummy
    this.planing_arr = dummy_data
    await this.getpropertyGroupdata(this.Obj['property_group_id'])

  }
  setSelectPropertyDetails() {
    for (let i = 0; i < this.old_table_data.length; i++) {
      if (this.Obj['assigned_property_number'] == this.old_table_data[i]['assigned_property_number']) {
        console.log(this.old_table_data[i])
        this.Obj['area'] = this.old_table_data[i]['area']
        this.Obj['unit'] = this.old_table_data[i]['unit']
        this.Obj['property_type'] = this.old_table_data[i]['property_type']
        this.Obj['description'] = this.Obj['assigned_property_number'] + ' - ' + this.old_table_data[i]['property_type']
      } else {
        if (!this.Obj['assigned_property_number']) {
          this.Obj['description'] = null
          this.Obj['area'] = null
          this.Obj['unit'] = null
          this.Obj['property_type'] = null
        }
      }
    }
  }
  setFeature() {
    console.log(this.selected_feature_index, this.selected_feature, this.Obj)
    this.data[this.selected_feature_index]['feature_value'] = this.Obj['loc_type_cd']
    this.data[this.selected_feature_index]['feature_value2'] = this.Obj['loc_type_cd']
    for (let i = 0; i < this.HierarchyWithLocationInfo.length; i++) {
      if (this.Obj['loc_type_cd'] == this.HierarchyWithLocationInfo[i]['node_cd']) {
        this.data[this.selected_feature_index]['feature_value2'] = this.Obj['loc_type_cd'] + ' - ' + this.HierarchyWithLocationInfo[i]['node_desc']
      }
    }
  }
  selected_feature:any;
  selected_feature_index:any;
  open_modal(data:any, index:any) {
    this.selected_feature = data['feature']
    this.selected_feature_index = index
    this.planing_arr2 = []
    this.planing_arr2 = [{}]
    this.Obj['hier_ident_code2'] = null
    this.Obj['loc_type'] = null
    this.Obj['loc_type_cd'] = null
    $('#myModal1').modal('show');
  }
  old_table_data = []
  async getpropertyGroupdata(group_id:any) {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['property_group_id'] = group_id
    obj['status'] = 'ACTIVE'
    console.log(obj)
    var resp:any = await this.landInput_S.getpropertyGroupdata(JSON.stringify(obj));
    console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.old_table_data = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  remove() {
    if (this.outer_acq_row.length > 1) {
      this.outer_acq_row.pop()
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  addRow(data:any, i:any) {
    this.data.push({})
  }
  removeRow(data:any, i:any) {

    console.log(data, i)

     this.f_arr = this.f_arr.filter((element:any) => {
      return element.feature !== data['feature'];
    });

    console.log(this.f_arr)



    if (this.data.length > 1) {
      this.data.splice(i, 1)
    }
  }
  reset() {
    this.stepper1.selectedIndex = 0
    this.stepper2.selectedIndex = 0
    this.Obj = {}
    this.outer_acq_row = []
    this.planing_arr = []
    this.planing_arr = [{}]
    this.planing_arr2 = []
    this.planing_arr2 = [{}]
    this.data = []
    this.data = [{}]

    console.log(this.planing_arr, this.outer_acq_row, this.Obj)
  }
  existing_heirarchy:any = []
  planaing_hierarchy :any= []
  acquisition_hierarchy:any = []
  async getAllHeirarchy() {
    this.spinner.show()
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    var resp:any = await this.hierarchyS.getHierarchyData(JSON.stringify(obj));
    console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy = resp['data']
      for (let i = 0; i < this.existing_heirarchy.length; i++) {
        this.existing_heirarchy[i]['desc'] = this.existing_heirarchy[i]['hier_ident_code'] + ' - ' + this.existing_heirarchy[i]['hier_buss_name']
      }
      let acq = []
      let planing = []
      for (let i = 0; i < this.existing_heirarchy.length; i++) {
        if (this.existing_heirarchy[i]['hier_type'] == "Acquisition") {
          acq.push(this.existing_heirarchy[i])
        }
        if (this.existing_heirarchy[i]['hier_type'] == "Planing") {
          planing.push(this.existing_heirarchy[i])
        }
      }
      this.planaing_hierarchy = planing
      this.acquisition_hierarchy = acq

      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  HierarchyWithLocationInfo:any = []
  async getHierarchyWithLocationInfo() {
    this.spinner.show()
    let obj :any= {}
    // console.log(item)
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.Obj['hier_ident_code2']
    var resp:any = await this.hierarchyS.getHierarchyWithLocationInfo(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.HierarchyWithLocationInfo = resp['data']
      // this.features[index]['arr'] = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  existing_heirarchy_nodes:any = []
  async getAllpropHierarchynodes() {
    // this.spinner.show()
    this.existing_heirarchy_nodes = []
    for (let i = 0; i < this.existing_heirarchy.length; i++) {
      if (this.Obj['hier_ident_code'] == this.existing_heirarchy[i]['hier_ident_code']) {
        this.Obj['hier_buss_name'] = this.existing_heirarchy[i]['hier_buss_name']
        this.Obj['hier_type'] = this.existing_heirarchy[i]['hier_type']
        break;
      }
    }
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.Obj['hier_ident_code']
    obj['status'] = 'ACTIVE'
    //console.log(obj)
    var resp:any = await this.hierarchyS.getAllpropHierarchynodes(JSON.stringify(obj));
    console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy_nodes = resp['data']
      let dummy:any = []
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        if (1 == this.existing_heirarchy_nodes[i]['level']) {
          dummy.push(this.existing_heirarchy_nodes[i])
        }
      }
      this.planing_arr[0]['existing_heirarchy_nodes'] = dummy
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  planing_arr2:any = [{}]
  async getAllpropHierarchynodes2() {
    // this.spinner.show()
    this.existing_heirarchy_nodes = []
    for (let i = 0; i < this.existing_heirarchy.length; i++) {
      if (this.Obj['hier_ident_code2'] == this.existing_heirarchy[i]['hier_ident_code']) {
        this.Obj['hier_buss_name'] = this.existing_heirarchy[i]['hier_buss_name']
        this.Obj['hier_type'] = this.existing_heirarchy[i]['hier_type']
        break;
      }
    }
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.Obj['hier_ident_code2']
    obj['status'] = 'ACTIVE'
    //console.log(obj)
    var resp:any = await this.hierarchyS.getAllpropHierarchynodes(JSON.stringify(obj));
    console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy_nodes = resp['data']
      let dummy:any = []
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        if (1 == this.existing_heirarchy_nodes[i]['level']) {
          dummy.push(this.existing_heirarchy_nodes[i])
        }
      }
      this.planing_arr2[0]['existing_heirarchy_nodes'] = dummy
      await this.getHierarchyWithLocationInfo()
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  aleratMsg() {
    Swal.fire('Information', "can't change !!!", 'info')
  }
  used_nodes:any = []
  selected_row:any = {}
  add_dropdown(data:any, index:any) {
    // Swal.fire('Information', "can't change !!!", 'error')
    //console.log(data, index)
    let flag = true
    let obj :any= {}
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
        obj = Object.assign({}, this.existing_heirarchy_nodes[i])
      }
    }
    this.selected_row = Object.assign({}, obj)
    this.selected_row['index'] = index

    this.filter_data()
  }
  filter_data() {
    let dummy:any = []
    let max_level = 0
    for (let i = 0; i < this.planaing_hierarchy.length; i++) {
      if (this.Obj['hier_ident_code'] == this.planaing_hierarchy[i]['hier_ident_code']) {
        max_level = this.planaing_hierarchy[i]['level_count']
        break;
      }
    }
    //console.log(max_level, this.selected_row, this.existing_heirarchy_nodes)
    for (let j = 1; j <= max_level; j++) {
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        let node_arr:any = []
        node_arr = this.existing_heirarchy_nodes[i]['path'].split('|')
        //console.log(node_arr, 'noida arr', this.existing_heirarchy_nodes[i], node_arr.includes(this.selected_row['node_cd']), this.selected_row['node_cd'])
        if (node_arr.includes(this.selected_row['node_cd'])) {
          if (this.existing_heirarchy_nodes[i]['level'] == j && this.existing_heirarchy_nodes[i]['level'] > this.selected_row['level']) {
            dummy.push(this.existing_heirarchy_nodes[i])
          }
        }
      }
      if (dummy.length > 0) {
        break;
      }
    }
    this.planing_arr.push({})
    this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = []
    this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = dummy
    console.log(dummy, 'dummy', this.planing_arr)
  }
  used_nodes2:any = []
  selected_row2:any = {}
  add_dropdown2(data:any, index:any) {
    //console.log(data, index)
    let flag = true
    let obj :any= {}
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
        obj = Object.assign({}, this.existing_heirarchy_nodes[i])
      }
    }
    this.selected_row2 = Object.assign({}, obj)
    this.selected_row2['index'] = index

    this.filter_data2()
  }


  locationInfoData :any= []
  used_node_arr:any = []
  filterRow(data:any, outer_index:any, inner_index:any) {
    console.log(this.outer_acq_row, 'outer data')
  }

  async submit() {
    if (!this.Obj['property_group_id']) {
      Swal.fire('Error', 'Please Selcet Plan Layout', 'error')
    } else if (!this.Obj['assigned_property_number']) {
      Swal.fire('Error', 'Please Selcet Property Number', 'error')
    }
    else if (!this.Obj['description']) {
      Swal.fire('Error', 'Please Enter Description ', 'error')
    } else {
      console.log(this.Obj, this.data)
      let data_to_send = []
      this.Obj['user_id'] = this.ebillUser.user_id
      this.Obj['b_acct_id'] = this.ebillUser.b_acct_id
      this.Obj['life_cycle_status'] = 'ACTIVE'
      this.Obj['feature_id_arr'] = JSON.stringify(this.Obj['feature_id_arr'])
      for (let i = 0; i < this.data.length; i++) {
        let obj :any= {}
        obj['feature_type'] = this.data[i]['feature']
        obj['feature_ident'] = this.data[i]['feature']
        obj['feature_value'] = this.data[i]['feature_value']
        obj['life_cycle_status'] = 'ACTIVE'
        data_to_send.push(obj)
      }
      this.Obj['data'] = data_to_send
      console.log(this.Obj)
      this.spinner.show()
      var resp:any = await this.landInput_S.createproperties(this.Obj)
      console.log(resp)
      if (resp['error'] == false) {
        this.spinner.hide()
        this.open_list()
        await this.getAllProperty()
        Swal.fire('Success', 'Created Successfully', 'success')
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Some Error Occured While Creating', 'error')
      }
    }

  }
  async update() {
    if (!this.Obj['property_group_id']) {
      Swal.fire('Error', 'Please Selcet Plan Layout', 'error')
    } else if (!this.Obj['assigned_property_number']) {
      Swal.fire('Error', 'Please Selcet Property Number', 'error')
    }
    else if (!this.Obj['description']) {
      Swal.fire('Error', 'Please Enter Description ', 'error')
    } else {
      console.log(this.Obj, this.data)
      let data_to_send = []
      this.Obj['user_id'] = this.ebillUser.user_id
      this.Obj['b_acct_id'] = this.ebillUser.b_acct_id
      this.Obj['life_cycle_status'] = 'ACTIVE'
      for (let i = 0; i < this.data.length; i++) {
        let obj :any= {}
        obj['feature_type'] = this.data[i]['feature']
        obj['feature_ident'] = this.data[i]['feature']
        obj['feature_value'] = this.data[i]['feature_value']
        // obj['feature_obj']=''
        obj['life_cycle_status'] = 'ACTIVE'
        data_to_send.push(obj)
      }
      this.Obj['data'] = data_to_send
      this.Obj['feature_id_arr'] = JSON.stringify(this.Obj['feature_id_arr'])
      console.log(this.Obj)
      this.spinner.show()
      var resp:any = await this.landInput_S.updateproperties(this.Obj)
      console.log(resp)
      if (resp['error'] == false) {
        Swal.fire('Success', 'Updated Successfully', 'success')
        this.spinner.hide()
        this.open_list()
        await this.getAllProperty()
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Some Error Occured While Updating', 'error')
      }
    }

  }

  // validateSize(input) {
  //   const fileSize = input.target.files[0].size / 1024;
  //   let numb: any = fileSize.toFixed(2);
  //   if (numb > 20) {
  //     // alert('to big, maximum is 2MiB. You file size is: ' + numb + ' KB');
  //     Swal.fire('Information', 'to big, maximum is 20KB. You file size is: ' + numb + ' KB', 'info')
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  filter_data2() {
    let dummy:any = []
    let max_level = 0
    if (this.selected_feature != 'Acquisition Location') {
      for (let i = 0; i < this.planaing_hierarchy.length; i++) {
        if (this.Obj['hier_ident_code2'] == this.planaing_hierarchy[i]['hier_ident_code']) {
          max_level = this.planaing_hierarchy[i]['level_count']
          break;
        }
      }
    } else {
      for (let i = 0; i < this.acquisition_hierarchy.length; i++) {
        if (this.Obj['hier_ident_code2'] == this.acquisition_hierarchy[i]['hier_ident_code']) {
          max_level = this.acquisition_hierarchy[i]['level_count']
          break;
        }
      }
    }
    //console.log(max_level, this.selected_row, this.existing_heirarchy_nodes)
    for (let j = 1; j <= max_level; j++) {
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        let node_arr = []
        node_arr = this.existing_heirarchy_nodes[i]['path'].split('|')
        //console.log(node_arr, 'noida arr', this.existing_heirarchy_nodes[i], node_arr.includes(this.selected_row['node_cd']), this.selected_row['node_cd'])
        if (node_arr.includes(this.selected_row2['node_cd'])) {
          if (this.existing_heirarchy_nodes[i]['level'] == j && this.existing_heirarchy_nodes[i]['level'] > this.selected_row2['level']) {
            dummy.push(this.existing_heirarchy_nodes[i])
          }
        }
      }
      if (dummy.length > 0) {
        break;
      }
    }
    this.planing_arr2.push({})
    this.planing_arr2[this.selected_row2['index'] + 1]['existing_heirarchy_nodes'] = []
    this.planing_arr2[this.selected_row2['index'] + 1]['existing_heirarchy_nodes'] = dummy
    console.log(dummy, 'dummy', this.planing_arr2)
  }



  setHierarchyNode(item:any, i:any) {
    let dummy:any = []
    //console.log(this.planing_arr, this.existing_heirarchy_nodes, item, i)
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy.push(this.planing_arr[i]['hierarchy_type'])
    }
    let dummy2 = []
    if (dummy.length > 0) {
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }
  remove_dropdown(data:any, i:any) {
    //console.log(data, i)
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop()
    }
    let dummy:any = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy.push(this.planing_arr[i]['hierarchy_type'])
    }
    let dummy2 = []
    if (dummy.length > 0) {
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }
  setLocationData(item:any, index:any) {
    let obj :any= {}
    console.log(item, index, this.planing_arr2)
    for (let i = 0; i < this.planing_arr2.length; i++) {
      let data = this.planing_arr2[i]['existing_heirarchy_nodes']
      for (let j = 0; j < data.length; j++) {
        if (item['hierarchy_type'] == data[j]['node_cd']) {
          obj['path'] = data[j]['path']
          obj['level'] = data[j]['level']
        }
      }
    }
    let dummy2:any = []
    let id_arr:any = []
    // remove last path row data
    for (let i = 0; i < this.HierarchyWithLocationInfo.length; i++) {
      if (!id_arr.includes(this.HierarchyWithLocationInfo[i]['node_cd']) && this.HierarchyWithLocationInfo[i]['path'] != obj['path'] && this.HierarchyWithLocationInfo[i]['path'].includes(obj['path'])) {
        id_arr.push(this.HierarchyWithLocationInfo[i]['node_cd'])
        dummy2.push(this.HierarchyWithLocationInfo[i])
      }
    }
    this.locationInfoData = dummy2
    console.log(dummy2, ' data filtered', this.locationInfoData)
  }
  remove_dropdown2(data:any, i:any) {
    console.log(data, i)
    if (this.planing_arr2.length > 1) {
      this.planing_arr2.pop()
    }
    // if (this.outer_acq_row[i]['dropDown'].length > 1) {
    //   this.outer_acq_row[i]['dropDown'].pop()
    // }
    let node_to_add = data['hierarchy_type']
    for (let i = 0; i < this.used_node_arr.length; i++) {
      if (this.used_node_arr[i] == node_to_add) {
        this.used_node_arr.splice(i, 1)
        break
      }
    }
    let dummy2 = []
    dummy2 = this.HierarchyWithLocationInfo
    for (let i = 0; i < this.HierarchyWithLocationInfo.length; i++) {
      if (this.HierarchyWithLocationInfo[i]['node_cd'] == node_to_add) {
        this.locationInfoData.push(this.HierarchyWithLocationInfo[i])
      }
    }
    console.log(this.locationInfoData, 'locationInfoData')
  }
  filtered_location_data:any = []
  change_data() {
    this.Obj['loc_type_cd'] = null
    this.filtered_location_data = []
    let dummy:any = []
    for (let i = 0; i < this.locationInfoData.length; i++) {
      this.locationInfoData[i]['desc'] = this.locationInfoData[i]['loc_id_str'] + ' - ' + this.locationInfoData[i]['loc_type_cd']
      if (this.Obj['loc_type'] == this.locationInfoData[i]['loc_type_cd']) {
        dummy.push(this.locationInfoData[i])
      }
    }
    this.filtered_location_data = dummy
  }
  uploaded_file_data = []
  async setData(data:any) {
    this.uploaded_file_data = []
    //console.log(data)
    let dummy:any = []
    for (let i = 1; i < data.length; i++) {
      let obj :any= {}
      obj['loc_ln_seq_num'] = 1
      obj['loc_pt_seq_num'] = 1
      obj['loc_gis_x_long'] = data[i][3]
      obj['loc_gis_y_lat'] = data[i][4]
      let obj2:any = {}
      obj2['loc_ln_seq_num'] = 1
      obj2['loc_pt_seq_num'] = 2
      obj2['loc_gis_x_long'] = data[i][6]
      obj2['loc_gis_y_lat'] = data[i][7]
      dummy.push(obj)
      dummy.push(obj2)
    }
    this.uploaded_file_data = dummy

    //console.log(dummy, 'set data')
  }
  // async open_update(data) {
  //   this.Obj = Object.assign({}, data)
  //   let all_path = []
  //   await this.getAllpropHierarchynodes()
  //   let planing_path = ''
  //   for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
  //     if (data['node_cd'] == this.existing_heirarchy_nodes[i]['node_cd']) {
  //       // console.log()
  //       planing_path = this.existing_heirarchy_nodes[i]['path']
  //     }
  //   }
  //   let dum = []
  //   dum = planing_path.split('|')
  //   let dummy2 = []
  //   if (dum.length > 0) {
  //     for (let i = 0; i < dum.length; i++) {
  //       for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
  //         if (dum[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
  //           dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
  //         }
  //       }
  //     }
  //   }
  //   this.Obj['path_desc'] = dummy2.join(' --> ')

  //   let dummy_data = []
  //   for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
  //     if (planing_path.includes(this.existing_heirarchy_nodes[i]['path'])) {
  //       all_path.push(this.existing_heirarchy_nodes[i]['path'])
  //       dummy_data.push(this.existing_heirarchy_nodes[i])
  //       dummy_data[i]['existing_heirarchy_nodes'] = [this.existing_heirarchy_nodes[i]]
  //       dummy_data[i]['hierarchy_type'] = this.existing_heirarchy_nodes[i]['node_cd']
  //     }
  //   }
  //   let dummy:any = []
  //   for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
  //     // dummy_data[dummy_data.length - 1]['existing_heirarchy_nodes'] = []
  //     if (!all_path.includes(this.existing_heirarchy_nodes[i]['path'])) {
  //       dummy_data[dummy_data.length - 1]['existing_heirarchy_nodes'].push(this.existing_heirarchy_nodes[i])
  //     }
  //   }
  //   // dummy_data[dummy_data.length - 1]['existing_heirarchy_nodes']=dummy
  //   this.planing_arr = dummy_data
  //   // console.log(data, dummy_data, 'filter', all_path, dummy_data[dummy_data.length - 1]['existing_heirarchy_nodes'])
  //   await this.getlocationGroupTargetdata(data['group_id'])
  //   $('.nav-tabs a[href="#tab-7-3"]').tab('show')
  // }
  // async getlocationGroupTargetdata(group_id) {
  //   let obj :any= {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['group_id'] = group_id
  //   obj['status'] = 'ACTIVE'
  //   //console.log(obj)
  //   var resp:any = await this.landInput_S.getlocationGroupTargetdata(JSON.stringify(obj));
  //   console.log(resp, 'list all heirarchy')
  //   if (resp['error'] == false) {
  //     await this.set_data_for_update(resp['data'])
  //     // this.Obj['hier_ident_code_acq'] = resp['data'][0]['hier_ident_code']
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire('Error', 'Error While Getting Data', 'error')
  //   }
  // }
  // async set_data_for_update(data) {
  //   let max_target_no = 0
  //   let final_data = []
  //   for (let i = 0; i < data.length; i++) {
  //     if (data[i]['target_no'] && data[i]['target_no'] > max_target_no) {
  //       max_target_no = data[i]['target_no']
  //     }
  //   }
  //   for (let i = 0; i <= max_target_no; i++) {
  //     let dummy:any = []
  //     let dummy2 = []
  //     let obj :any= {}
  //     for (let j = 0; j < data.length; j++) {
  //       if (data[j]['target_no'] == i) {
  //         this.Obj['hier_ident_code_acq'] = data[j]['hier_ident_code']
  //         dummy.push(data[j])
  //       }
  //     }
  //     for (let k = 0; k < data.length; k++) {
  //       if (data[k]['target_no'] == i) {
  //         dummy2.push(data[k])
  //       }
  //     }
  //     obj['data'] = dummy
  //     obj['dropDown'] = dummy2
  //     final_data.push(obj)
  //   }
  //   // //// set drop down data
  //   let obj :any= {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['hier_ident_code'] = this.Obj['hier_ident_code_acq']
  //   obj['status'] = 'ACTIVE'
  //   this.spinner.show()
  //   var resp:any = await this.hierarchyS.getAllpropHierarchynodes(JSON.stringify(obj));
  //   console.log(resp, 'list all heirarchy')
  //   if (resp['error'] == false) {
  //     await this.getHierarchyWithLocationInfo()
  //     this.existing_heirarchy_nodes_acq = resp['data']
  //     for (let a = 0; a < final_data.length; a++) {
  //       let planing_path = ''
  //       console.log(final_data[a], 'aaaaaaaa')
  //       let lowest_node_cd = final_data[a]['dropDown'][0]['target_cd']
  //       console.log(planing_path,'planing_path')
  //       for (let i = 0; i < this.existing_heirarchy_nodes_acq.length; i++) {
  //         if (lowest_node_cd == this.existing_heirarchy_nodes_acq[i]['node_cd']) {
  //           planing_path = this.existing_heirarchy_nodes_acq[i]['path']
  //         }
  //       }
  //       let dummy_data = []
  //       let all_path = []
  //       console.log(planing_path,lowest_node_cd,'planing_path',all_path)
  //       for (let i = 0; i < this.existing_heirarchy_nodes_acq.length; i++) {
  //         if (planing_path.includes(this.existing_heirarchy_nodes_acq[i]['path'])) {
  //           all_path.push(this.existing_heirarchy_nodes_acq[i]['path'])
  //           dummy_data.push(this.existing_heirarchy_nodes_acq[i])
  //           dummy_data[i]['existing_heirarchy_nodes'] = [this.existing_heirarchy_nodes_acq[i]]
  //           dummy_data[i]['hierarchy_type'] = this.existing_heirarchy_nodes_acq[i]['node_cd']
  //         }
  //       }
  //       for (let i = 0; i < this.existing_heirarchy_nodes_acq.length; i++) {
  //         console.log(all_path,this.existing_heirarchy_nodes_acq[i]['path'],'this.existing_heirarchy_nodes_acq[i]')
  //         if (all_path.includes(this.existing_heirarchy_nodes_acq[i]['path'])) {
  //           dummy_data[dummy_data.length - 1]['existing_heirarchy_nodes'].push(this.existing_heirarchy_nodes_acq[i])
  //         }
  //       }
  //       final_data[a]['dropDown'] = dummy_data
  //     }
  //     for (let i = 0; i < final_data.length; i++) {
  //       let data = final_data[i]['data']

  //       for (let k = 0; k < data.length; k++) {
  //         let dummy:any = []
  //         for (let j = 0; j < this.HierarchyWithLocationInfo.length; j++) {
  //           if (data[k]['loc_type_cd'] == this.HierarchyWithLocationInfo[j]['loc_type_cd']) {
  //             data[k]['loc_geo_calc_value'] = this.HierarchyWithLocationInfo[j]['loc_geo_calc_value']
  //             data[k]['location_use_type'] = this.HierarchyWithLocationInfo[j]['location_use_type']
  //             data[k]['loc_geo_ent_value'] = this.HierarchyWithLocationInfo[j]['loc_geo_ent_value']
  //             dummy.push(this.HierarchyWithLocationInfo[j])
  //           }
  //         }
  //         data[k]['area'] = dummy
  //       }

  //     }
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire('Error', 'Error While Getting Data', 'error')
  //   }
  //   this.outer_acq_row = final_data
  //   console.log(max_target_no, final_data, 'max no')
  // }
  // async update() {
  //   let dummy:any = []
  //   //console.log(this.Obj, this.outer_acq_row)
  //   for (let i = 0; i < this.outer_acq_row.length; i++) {
  //     let obj :any= {}
  //     let data = this.outer_acq_row[i]['data']
  //     for (let j = 0; j < data.length; j++) {
  //       let area = data[j]['area']
  //       for (let k = 0; k < area.length; k++) {
  //         if (data[j]['loc_geo_calc_value'] == area[k]['loc_geo_calc_value']) {
  //           obj['level'] = area[k]['level']
  //           obj['node_cd'] = area[k]['node_cd']
  //           obj['loc_type_cd'] = area[k]['loc_type_cd']
  //           obj['target_no'] = i
  //           dummy.push(obj)
  //         }
  //       }
  //     }
  //   }
  //   for (let i = 0; i < dummy.length; i++) {
  //     for (let k = 0; k < this.outer_acq_row.length; k++) {
  //       if (k == dummy[i]['target_no']) {
  //         let dropDown = this.outer_acq_row[k]['dropDown']
  //         let nodes = dropDown[dropDown.length - 1]['existing_heirarchy_nodes']
  //         for (let j = 0; j < nodes.length; j++) {
  //           if (dropDown[dropDown.length - 1]['hierarchy_type'] == nodes[j]['node_cd']) {
  //             dummy[i]['target_cd'] = nodes[j]['node_cd']
  //             dummy[i]['target_level'] = nodes[j]['level']
  //             dummy[i]['hier_ident_code'] = 'ACTIVE'
  //             dummy[i]['hier_ident_code'] = this.Obj['hier_ident_code_acq']
  //           }
  //         }
  //       }
  //     }
  //   }
  //   this.Obj['data'] = dummy
  //   this.Obj['b_acct_id'] = this.b_acct_id
  //   this.Obj['user_id'] = this.ebillUser.user_id
  //   this.Obj['group_type'] = 'LANDINPUT'
  //   console.log(this.Obj, 'object', this.outer_acq_row, dummy)
  //   this.spinner.show()
  //   var resp:any = await this.landInput_S.updateLandInput(this.Obj)
  //   console.log(resp, 'response')
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     await this.getAllLandInput()
  //     Swal.fire('Success', 'Updated Successfully', 'success')
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire('Error', 'Some Error Occured While Updating', 'error')
  //   }
  //   console.log(this.Obj, this.outer_acq_row, this.planing_arr)
  // }
  async delete(element:any) {
    console.log(element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  async finaldelete(element:any) {
    let obj :any= {}
    obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    var resp:any = await this.landInput_S.deleteProperties(obj)
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getAllProperty()
      Swal.fire('Success', 'Deleted Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured While Deleting', 'error')
    }
  }

  async print1() {
    this.spinner.show()
    let data:any = []
    data = this.allProp
    var txt:any = this.ebillUser['account_name'] + '(' + this.ebillUser['account_short_name'] + ')'
    var dd :any= {
      pageSize: 'A4',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },
      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    var header0 = {
      columns: [
        {
          width: '*',
          text: 'All Properties',
          bold: true,
          alignment: 'center'
        }

      ],
    }
    var header9 = {
      columns: [
        {
          width: '*',
          text: '* Note : This is a computer generated document.',
          bold: true,
          alignment: 'left'
        }

      ],
    }
    var header1 = {
      columns: [
        {
          width: '*',
          text: '	Architecture Plan:',
          bold: true
        },

        {
          width: '*',
          text: data[0]['leaf_value'],
          bold: true
        },
        {
          width: '*',
          text: ' ',
          bold: true
        },

        {
          width: '*',
          text: ''
        }

      ],
    }

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
    var tbl = {
      // layout: 'lightHorizontalLines',

      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ["10%",'*', '*', '*', '10%'],

        body: [
          [{text: 'Sr No', bold:true}, {text: 'Property type',  bold:true}, {text: 'Property Number',  bold:true},{text: 'Area',  bold:true},{text: 'Unit',  bold:true}]
          // ["Sr No.",'Property type',  'Property Number','Area', 'Unit']
        ],
      }
    };
    // area
    // description
    // assigned_property_number
    // property_type
    console.log(data, 'data')
    dd.content.push(tbl);
    for (var i = 0; i < data.length; i++) {
      var arr = []
      arr.push(i+1);
      arr.push(data[i]['property_type']);
      arr.push(data[i]['assigned_property_number']);
      arr.push(data[i]['area']);
      // arr.push(data[i]['description']);
      arr.push("Sqm");
      // arr.push(this.mainService.dateFormatChange(data[i].applied_date));
      // arr.push(data[i]['application_amount']);
      // arr.push(data[i]['arr_status_code']);
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push(header9);
    this.spinner.hide()
    pdfMake.createPdf(dd).download("all-properties");
  }

}
