<div class="page-content fade-in-up">

  <div class="row">
    <div class="col-lg-3 col-md-6">
      <div class="ibox bg-success color-white widget-stat">
        <div class="ibox-body">
          <h2 class="m-b-5 font-strong">{{incompleteCount}}</h2>
          <button class="btn btn-primary" (click)="modalOpen(1)">VIEW</button>
          <div class="m-b-5">INCOMPLETE EMPLOYEE</div><i class="fa fa-tasks widget-stat-icon"></i>
          <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="ibox bg-info color-white widget-stat">
        <div class="ibox-body">
          <h2 class="m-b-5 font-strong">{{activeCount}}</h2>
          <button class="btn btn-primary" (click)="modalOpen(2)">VIEW</button>
          <div class="m-b-5">ACTIVE EMPLOYEE</div><i class="fa fa-users widget-stat-icon"></i>
          <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="ibox bg-warning color-white widget-stat">
        <div class="ibox-body">
          <h2 class="m-b-5 font-strong">{{leftCount}}</h2>
          <button class="btn btn-primary" (click)="modalOpen(3)">VIEW</button>
          <div class="m-b-5">LEFT EMPLOYEE</div><i class="fa fa-remove widget-stat-icon"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="ibox bg-success color-white widget-stat">
        <div class="ibox-body">
          <h2 class="m-b-5 font-strong">{{salarycount}}</h2>
          <button class="btn btn-primary" (click)="modalOpen(4)">VIEW</button>
          <div class="m-b-5">SALARY ON HOLD</div><i class="fa fa-tasks widget-stat-icon"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="ibox bg-success color-white widget-stat">
        <div class="ibox-body">
          <h2 class="m-b-5 font-strong">{{retirecount}}</h2>
          <button class="btn btn-primary" (click)="modalOpen(5)">VIEW</button>
          <div class="m-b-5">RETIREMENT LIST (NEXT 7 MONTHS)</div><i class="fa fa-tasks widget-stat-icon"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="ibox">
        <div class="ibox-body">


          <div>
            <!-- oc  -->
            <!-- <canvas height="200" baseChart [datasets]="barChartData" [labels]="mbarChartLabels"
              [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend" [chartType]="barChartType"
              (chartClick)="chartClicked($event)"></canvas> -->
              <!-- ec  -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="ibox">

        <div class="ibox-body">
          <div>
            <!-- oc  -->
            <!-- <canvas height="200" baseChart [datasets]="barChartData1" [labels]="mbarChartLabels1"
              [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend" [chartType]="barChartType"
              (chartClick)="chartClicked1($event)"></canvas> -->
              <!-- ec  -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-body">


          <div>
            <!-- oc  -->
            <!-- <canvas height="200" baseChart [datasets]="barChartData2" [labels]="mbarChartLabels2"
              [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend" [chartType]="barChartType"
              (chartClick)="chartClicked2($event)"></canvas> -->
              <!-- ec  -->
          </div>
        </div>
      </div>

    </div>
    <!-- closed by requirement team
            <div class="col-lg-6">

        </div>
         <div class="col-lg-6">

            <div class="ibox">

                <div class="ibox-body">
                    <div>
                        <canvas height="200" baseChart [datasets]="barChartData1" [labels]="mbarChartLabels1" [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend" [chartType]="barChartType" (chartClick)="chartClicked1($event)"></canvas>
                    </div>
                </div>
            </div>
        </div> -->
  </div>



  <style>
    .visitors-table tbody tr td:last-child {
      display: flex;
      align-items: center;
    }

    .visitors-table .progress {
      flex: 1;
    }

    .visitors-table .progress-parcent {
      text-align: right;
      margin-left: 10px;
    }
  </style>
</div>

<div class="modal" id="myModal">
  <div class="modal-dialog modal-lg" style="max-width: 70% ; ">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Employee LIST</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">


        <table style="width: 100%;">
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Employee Designation</th>
          </tr>
          <tr *ngFor="let ob of showArr;let i=index">
            <td>
              <!--  {{mainService.accInfo['account_short_name']}} -->{{getNumberFormat(ob.emp_id)}}
            </td>
            <td> {{ob.emp_name}}</td>
            <td> {{ob.designation_code}}</td>
          </tr>

        </table>



        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="modal" id="myModal3">
  <div class="modal-dialog modal-lg" style="max-width: 70% ; ">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Employee LIST</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">


        <table style="width: 100%;">
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Employee Designation</th>
            <th>Employee Retirement Date</th>
          </tr>
          <tr *ngFor="let ob of showArr;let i=index">
            <td>
              <!--  {{mainService.accInfo['account_short_name']}} -->{{getNumberFormat(ob.emp_id)}}
            </td>
            <td> {{ob.emp_name}}</td>
            <td> {{ob.designation_code}}</td>
            <td> {{ob.retirement_date}}</td>
          </tr>

        </table>



        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="modal" id="myModal2">
  <div class="modal-dialog modal-lg" style="max-width: 70% ; ">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Employee Salary Stop List</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">


        <table style="width: 100%;">
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>

          </tr>
          <tr *ngFor="let ob of showArr;let i=index">
            <td>
              <!-- {{mainService.accInfo['account_short_name']}} -->{{getNumberFormat(ob.emp_id)}}
            </td>
            <td> {{ob.emp_name}}</td>

          </tr>

        </table>



        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="modal" id="myModal1">
  <div class="modal-dialog modal-lg" style="max-width: 70% ; ">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Employee LIST</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">


        <table style="width: 100%;">
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Employee Designation</th>
          </tr>
          <tr *ngFor="let ob of showArr1;let i=index">
            <td>
              <!-- {{mainService.accInfo['account_short_name']}} -->{{getNumberFormat(ob.emp_id)}}
            </td>
            <td> {{ob.emp_name}}</td>
            <td> {{ob.designation_code}}</td>
          </tr>

        </table>



        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="modal" id="myModal4">
  <div class="modal-dialog modal-lg" style="max-width: 70% ; ">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">All Left Employees</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">


        <table style="width: 100%;">
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Employee Designation</th>
            <th>Reason</th>
          </tr>
          <tr *ngFor="let ob of showArr;let i=index">
            <td>
              <!-- {{mainService.accInfo['account_short_name']}} -->{{getNumberFormat(ob.emp_id)}}
            </td>
            <td> {{ob.emp_name}}</td>
            <td> {{ob.designation_code}}</td>
            <td> {{ob.employee_current_type_code}}</td>

          </tr>

        </table>



        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>
