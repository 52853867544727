<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role1']}}
                        <!-- Role -->
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-1" *ngIf="list_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-line-chart"></i>&nbsp;
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role2']}}    <!-- Role List -->
                                </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-2" *ngIf="create_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"></i>
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role3']}} <!-- Create Role -->
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a class="nav-link active" href="#tab-7-3" *ngIf="update_flag" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role4']}} <!-- Update Role -->
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-7-1">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_create()>Create New Role</button>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_assignment4']}}">
                                    </mat-form-field>
                                </div>
                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>
                                    <!-- <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Id
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.id}} </td>
                                    </ng-container> -->


                                    <ng-container matColumnDef="role_cd">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role5']}}
                                            <!-- Role Code -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.role_cd}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="role_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role6']}}
                                            <!-- Role Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.role_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="role_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role7']}}
                                            <!-- Role Description -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.role_desc}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="role_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role8']}}
                                            <!-- Role Type -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.role_type}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="res">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role9']}}
                                            <!-- Resource -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.resource_desc}} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="valid_from">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Valid From
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.valid_from}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="valid_upto">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Valid Upto
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.valid_upto}} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_assignment8']}}
                                            <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button (click)="viewDetails(element)"
                                                class="btn btn-primary">View</button>&nbsp;&nbsp; -->
                                            <button (click)="open_update(element)" class="btn btn-success">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role10']}}    <!-- Update -->
                                            </button>&nbsp;&nbsp;
                                            <button *ngIf="element['role_cd']!='HR_EMP'" (click)="delete(element)" class="btn btn-danger">Delete</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>


                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-7-2">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role11']}}
                                        <!-- Enter Role Code -->
                                        : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_cd']" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role11']}}" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role12']}}
                                        <!-- Enter Role Name  -->
                                        : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_name']" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role12']}}" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role13']}}
                                        <!-- Enter Role Description  -->
                                        : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_desc']" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role13']}}" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <!-- <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select Role Type : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="role_type" bindLabel="code" bindValue="code" [multiple]="false" placeholder="Select Role Type" [(ngModel)]="Obj['role_type']" [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true" name="role_type">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div> -->
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role14']}}
                                        <!-- Select Resource  -->
                                        : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="resource" bindLabel="desc" bindValue="resource_cd" [multiple]="true" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role14']}}" [(ngModel)]="Obj['res']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="false" name="chart_of_account0">
                                    </ng-select>
                                </div>
                                <div class="col-3">
                                    <mat-checkbox [(ngModel)]="checkBoxValue" [ngModelOptions]="{standalone: true}" (click)="checkCheckBoxvalue()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_role15']}}
                                        <!-- Select All Resource -->
                                    </mat-checkbox>
                                </div>
                            </div>
                            <!-- <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid from : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_from']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid Upto : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_upto']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div> -->
                            <br><br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">{{mainService.allLableShowObjHR[mainService.language_cd+'hr_assignment12']}}</button>
                                </div>

                            </div>
                        </div>
                        <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-7-3">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role11']}} : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_cd']" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role11']}}" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role12']}} : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_name']" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role12']}}" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role13']}} : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['role_desc']" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role13']}}" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <!-- <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select Role Type : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="role_type" bindLabel="code" bindValue="code" [multiple]="false" placeholder="Select Role Type" [(ngModel)]="Obj['role_type']" [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true" name="role_type">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div> -->
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role14']}} : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="resource" bindLabel="desc" bindValue="resource_cd" [multiple]="true" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role14']}}" [(ngModel)]="Obj['res']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="false" name="chart_of_account0">
                                    </ng-select>
                                </div>
                                <div class="col-3">
                                    <mat-checkbox [(ngModel)]="checkBoxValue" [ngModelOptions]="{standalone: true}" (click)="checkCheckBoxvalue()">{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role15']}}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <!-- <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid from : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_from']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid Upto : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_upto']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div> -->
                            <br><br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">{{mainService.allLableShowObjHR[mainService.language_cd+'hr_role10']}}</button>
                                </div>

                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="tab-7-3">
                            <br>

                        </div> -->
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'hr_assignment14']}}</ngx-spinner>
