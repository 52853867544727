import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//oc
// import { AgmCoreModule } from '@agm/core';
//ec
import { ToastrModule } from 'ngx-toastr';

import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from 'ng2-file-upload';
// import { ChartsModule } from 'ng2-charts';
import { NgChartsModule,NgChartsConfiguration } from 'ng2-charts';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgtreegridModule } from 'ngtreegrid';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { NgApexchartsModule } from "ng-apexcharts";

// import { ToastrModule } from 'ng6-toastr-notifications';
import { EmbLayoutsRoutingModule } from './emb-layouts-routing.module';
import { AdministrationComponent } from '../administration/administration.component';
import { CreateRoleComponent } from '../administration/authorization/create-role/create-role.component';
import { DataAssignmentComponent } from '../administration/authorization/data-assignment/data-assignment.component';
import { EmbHierarchyComponent } from '../administration/authorization/emb-hierarchy/emb-hierarchy.component';
import { UsersComponent } from '../administration/authorization/users/users.component';
import { BudComponent } from '../administration/master-data/bud/bud.component';
import { CodeValueComponent } from '../administration/master-data/code-value/code-value.component';
import { DeductionComponent } from '../administration/master-data/deduction/deduction.component';
import { DefApprovalComponent } from '../administration/master-data/def-approval/def-approval.component';
import { FieldComponent } from '../administration/master-data/field/field.component';
import { GeometryComponent } from '../administration/master-data/geometry/geometry.component';
import { RolePrintComponent } from '../administration/master-data/role-print/role-print.component';
import { UndertakingComponent } from '../administration/master-data/undertaking/undertaking.component';
import { WorkInfoComponent } from '../administration/master-data/work-info/work-info.component';
import { CbComponent } from '../cb/cb.component';
import { CompletionComponent } from '../completion/completion.component';
import { CreateEmbComponent } from '../create-emb/create-emb.component';
import { DevComponent } from '../dev/dev.component';
import { EbillCreateComponent } from '../ebill-create/ebill-create.component';
import { DocComponent } from '../help/doc/doc.component';
import { FaqComponent } from '../help/faq/faq.component';
import { HelpComponent } from '../help/help.component';
import { OnlineSupportComponent } from '../help/online-support/online-support.component';
import { VideosComponent } from '../help/videos/videos.component';
import { ReportsComponent } from '../reports/reports.component';
import { TenderCreateComponent } from '../tender-create/tender-create.component';
import { PartyComponent as EmbPartyComponent } from '../administration/master-data/party/party.component';
import { AuthorizationComponent } from '../administration/authorization/authorization.component';
import { MasterDataComponent } from '../administration/master-data/master-data.component';
import { DashboardComponent as DashboardComponentEMB } from '../../emb/dashboard/dashboard.component'
import { EmbFooterComponent } from './emb-footer/emb-footer.component';
import { EmbHeaderComponent } from './emb-header/emb-header.component';
import { EmbLayoutsComponent } from './emb-layouts.component';
import { EmbSidebarComponent } from './emb-sidebar/emb-sidebar.component';
import { SharedModule } from '../../shared/shared.module';
import { SplitPipe } from '../../emb/pipe/split.pipe'

import { EstimateComponent } from '../depricated/estimation/estimate/estimate.component';

import { EngMasterDataComponent } from '../new-eng/administration/eng-master-data/eng-master-data.component';

import {EngSorItemsComponent}  from '../depricated/item-list/eng-sor-items/eng-sor-items.component';
import { MorthComponent } from '../depricated/item-list/morth/morth.component';
import { DsrComponent } from '../depricated/item-list/dsr/dsr.component';
import { DepricatedComponent } from '../depricated/depricated.component';
import { ItemListComponent } from '../depricated/item-list/item-list.component';
import { AdministrationComponent as depAdministration} from '../depricated/administration/administration.component';
import { EstimationComponent } from '../depricated/estimation/estimation.component';
import { InceptionComponent } from '../depricated/estimation/inception/inception.component';
import { MasterDataComponent as DepMasterDataComponent} from '../depricated/administration/master-data/master-data.component';
import { SorComponent } from '../depricated/item-list/sor/sor.component';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { HighchartsChartModule } from 'highcharts-angular';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    EmbLayoutsComponent,
    EmbHeaderComponent,
    EmbFooterComponent,
    EmbSidebarComponent,
    AdministrationComponent,
    EmbPartyComponent,
    OnlineSupportComponent,
    VideosComponent,
    FaqComponent,
    AuthorizationComponent,
    EngMasterDataComponent,
    UndertakingComponent,
    DocComponent,
    HelpComponent,
    UsersComponent,
    DashboardComponentEMB,
    CreateRoleComponent,
    CodeValueComponent,
    WorkInfoComponent,
    FieldComponent,
    SorComponent,
    DeductionComponent,
    GeometryComponent,
    BudComponent,
    MasterDataComponent,
    DefApprovalComponent,
    DevComponent,
    CompletionComponent,
    CbComponent,
    CreateEmbComponent,
    EbillCreateComponent,
    TenderCreateComponent,
    EmbHierarchyComponent,
    DataAssignmentComponent,
    ReportsComponent,
    RolePrintComponent,
    DepMasterDataComponent,
    EngSorItemsComponent,
    SplitPipe,
    EstimateComponent,
    MorthComponent,
    DsrComponent,
    DepricatedComponent,
    ItemListComponent,
    depAdministration,
    EstimationComponent,
    InceptionComponent,
  ],
  exports: [
    EmbLayoutsComponent,
    EmbHeaderComponent,
    EmbFooterComponent,
    EmbSidebarComponent,
  ],
  imports: [
    CanvasJSAngularChartsModule,
    HighchartsChartModule,
    SharedModule,
    CommonModule,
    EmbLayoutsRoutingModule,
    MatTooltipModule,
    //oc
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    //   apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    //ec
    NgSelectModule,
    // NgChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    MatSlideToggleModule,
    FormsModule,
    // NgtreegridModule,
    MatStepperModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    MatTabsModule,
    ToastrModule.forRoot(),
    NgChartsModule.forRoot()
  ],
  providers: [
    { provide: NgChartsConfiguration, useValue: { generateColors: false }}
  ]
})
export class EmbLayoutsModule { }
