import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { mainModule } from 'process';

@Injectable({
  providedIn: 'root'
})
export class LedgerService {


  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/accounts/journal";
  }

  async getGstOutputReport(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getGstOutputReport' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getTDSONGstOutputReport(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getTDSONGstOutputReport' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getdemandGstInputReport(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getdemandGstInputReport' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getGstInputReport(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getGstInputReport' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getTDSONGstInputReport(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getTDSONGstInputReport' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getAllDeduction(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getAllDeduction' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getTDSDeduction(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getTDSDeduction' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  //party
  async getPartyInfo(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/party/getPartyInfo' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getLedgerReport(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getledgerreport' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //jrnl listing
  async getJournalList(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/journal/getJournalList' + obj).toPromise().then(res => {
      return res;
    });
    console.log(resp)
    return resp;
  }

  async getAllArrangement(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/account/ip/getsal' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //arr listing
  async getarrList(obj:any){
    console.log(obj);
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getarrList' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //get Account Type
  async getAccountInfo(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/settings/accountInfo/getAccountInfo' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getchartofaccount(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + '/accounts/chartofaccount/getchartofaccount' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  //journal
  //*********************************Fin Year*********************************************
  async getActiveFinYear(obj:any){
    const res = await this.http.get<any>(this.httpUrl + '/getActiveFinYear' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getAllFinYear(obj:any){
    const res = await this.http.get<any>(this.httpUrl + '/getAllFinYear' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async createfinyear(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/createfinyear', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // async  updatefinyear(obj:any){
  //   const resp = await this.http.put<any>(this.httpUrl+'/updatefinyear',obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }

  async closeFinYear(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/closefinyear', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //***************************************************** */

  async getMaxJournalLineID(b_acct_id:any) {
    const res = await this.http.get<any>(this.main.httpUrl + '/account/setting/gst/getmaxjrnlid' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getAllJournalInfo(obj:any){
    const res = await this.http.get<any>(this.httpUrl + '/getAllJournal' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getPayableJournalID(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/account/journal/getPayableJournalID' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  //unposted Journal

  async getAllUnpostedJournalInfo(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/account/journal/getAllUnpostedJournal' + obj).toPromise().then(res => {
      return res;
    });

    return res;
  }

  async addUnpostedJournal(obj:any){
    const res = await this.http.post<any>(this.main.httpUrl + '/account/journal/postUnpostedJournal', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async updateUnpostedJournal(obj:any){
    const res = await this.http.put<any>(this.main.httpUrl + '/account/journal/updateUnpostedJournal', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async updateUnpostedJournalStatus(obj:any){
    const res = await this.http.put<any>(this.main.httpUrl + '/account/journal/updateUnpostedJournalStatus', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async deleteUnpostedJournal(obj:any){
    const res = await this.http.delete<any>(this.main.httpUrl + '/account/journal/deleteUnpostedJournal' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getJournalDetail(obj:any){
    const res = await this.http.get<any>(this.httpUrl + '/getJournalDetail' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async postingJournal(obj:any){
    const res = await this.http.post<any>(this.main.httpUrl + '/account/Journal/postjournal', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getevents(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + '/account/event/getevents' + JSON.stringify(obj)).toPromise().then(res => {
      return res
    });
    return resp
  }


  ///////////////////////////

  async getTrailBalance(obj:any){
    const res = await this.http.post<any>(this.main.httpUrl + '/accounts/report/gettrailbalance', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getDetailsPopUp(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getDetailsByLevel' + JSON.stringify(obj)).toPromise().then((res) => {
      return res;
    })
    return resp
  }
  async getDrillDownTrailBalance(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getDrillDownTrailBalance' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getPartyListing(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getPartyListing' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getJournalListing(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/journal/getJournalListing' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getBankPayment(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getBankPayment' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getAllBankPaymentList(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getAllBankPaymentList' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async getAnyBankPayment(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getAnyBankPayment' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async changeBPstatus(obj:any){
    const res = await this.http.put<any>(this.main.httpUrl + '/accounts/report/changeBPstatus', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getAdhocReport(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + '/accounts/report/getAdhocReport' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getNewAdhocReport(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + '/accounts/report/getNewAdhocReport' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }


  async addBankPayment(obj:any){
    const res = await this.http.post<any>(this.main.httpUrl + '/accounts/report/addBankPayment', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async getFields(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/metadata/fields/getfields' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getjrnldata(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/metadata/eventlayout/getJournal' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getMaxJournalId(obj:any){
    const res = await this.http.get<any>(this.httpUrl + '/getMaxJournalId' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getContingentBill(obj:any){
    const res = await this.http.get<any>(this.httpUrl + '/getContingentBill' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async postJournal(obj:any){
    const res = await this.http.put<any>(this.httpUrl + '/postJournal', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async createJournal(obj:any){
    const res = await this.http.post<any>(this.httpUrl + '/createJournal', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async deleteJournal(obj:any){
    const res = await this.http.delete<any>(this.httpUrl + '/deleteJournal' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }



  /****************************balance sheet ****************************************** */
  async getBalanceSheet(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getBalanceSheet' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async balancesheetExcel(obj:any){
    const res = await this.http.post(this.main.httpUrl + '/accounts/report/balancesheetExcel', obj, { responseType: 'blob' }).toPromise().then(resp => {
      return resp;
    })
    return res;
  }
  async incomeExcel(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/accounts/report/incomeExcel', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  //*******************************************fix assest register***************************************************************** */


  async getFixAssestOpening(obj:any){
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getFixAssestOpening' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async createExcel(obj:any){
    const res = await this.http.post(this.main.httpUrl + '/accounts/report/createExcel', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getFixAssestTrx(obj:any){
    console.log(obj)
    const res = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getFixAssestTrx' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async fixAssetRate(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/projectBank/getfixedAssetMapping' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  /*****************************************************COGS ***************************************************************** */
  async getClosingStock(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getClosingStock' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async getCogsEntry(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getCogsEntry' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  /*******************************************************receipts and payment account**************************** */

  async getReceiptPaymnet(obj:any){
    console.log(obj)
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getReceiptPaymnet' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async getReceiptPaymnetOpening(obj:any){
    console.log(obj)
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/report/getReceiptPaymentOpening' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


  async getReceiptPaymnetClosing(obj:any){
    console.log(obj)
    const resp = await this.http.get<any>(this.main.httpUrl + '/accounts/report/receiptPaymentClosing' + obj).toPromise().then(res => {
      return res;
    })
    return resp; //getReceiptPaymnetClosing
  }
  async receiptPaymentExcel(obj:any){
    const res = await this.http.post(this.main.httpUrl + '/accounts/report/receiptPaymentExcel', obj, { responseType: 'blob' }).toPromise().then(resp => {
      return resp;
    })
    return res;
  }

}
