<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC1']}}
                        <!-- Employee LIC Report Info -->
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" data-toggle="tab" (click)="chamgeTab()"><i
                                    class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC2']}}
                                <!-- Employee LIC Report --></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="create_flag" data-toggle="tab" (click)="chamgeTab()"><i
                                    class="ti-settings"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC3']}}
                                <!-- Add Employee LIC Detail --></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="update_flag" data-toggle="tab" (click)="chamgeTab()"><i
                                    class="ti-announcement"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC4']}}
                                <!-- Update Employee LIC Detail --></a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_create()>Add Employee Lic Detail</button>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC5']}}
                                    <!-- Select Employee -->: <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" (change)="changeEmployee()" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC5']}}" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        <!-- Select Employee -->
                                    </ng-select>
                                </div>

                            </div>

                            <hr>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC6']}}">
                                <!-- Filter -->
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="company_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC7']}}
                                            <!-- Company Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.company_name }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="lic_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC8']}}
                                            <!-- LIC No -->.
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lic_no}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="acct_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC9']}}
                                            <!-- Account Number -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.acct_no}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="tensure_of_deduction">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC10']}}
                                            <!-- Tenure Of Deduction -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.tensure_of_deduction}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="ifsc_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC11']}}
                                            <!-- Bank IFSC Code -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.ifsc_code}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="nom_branch_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC12']}}
                                            <!-- Branch Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.nom_branch_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="deduction_from">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC13']}}
                                            <!-- Deduction From -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.deduction_from}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="deduction_to">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC14']}}
                                            <!-- Deduction To -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.deduction_to}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC15']}}
                                            <!-- Amount -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.amount}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC16']}}
                                            <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" (click)="openUpdate(element)">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC17']}}
                                                <!-- Update --></button>
                                            <button class="btn btn-outline-danger" (click)="deleteLICInfo(element)">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC18']}}
                                                <!-- Delete --></button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <!--  <div class="row"  *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC5']}}
                                    <!-- Select Employee -->  <span style="color:red">*</span>:
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC5']}}" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name" > -->
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC5']}}"  (change)="changeEmployee()"  [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name" >
                                        <!-- Select Employee -->
                                    </ng-select>
                                </div>



                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC7']}}
                                    <!-- Company Name --> <span style="color:red">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="Obj['company_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC19']}}
                                    <!-- LIC Number -->  <span style="color:red">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="Obj['lic_no']" maxlength="15">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC9']}}
                                    <!-- Account Number -->  <span style="color:red">*</span>:
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control" type="number" [(ngModel)]="Obj['acct_no']"> -->
                                    <input class="form-control" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  type="number" [(ngModel)]="Obj['acct_no']" maxlength="16" >
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC10']}}
                                    <!-- Tenure Of Deduction -->  <span style="color:red">*</span>:
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control" type="text" [(ngModel)]="Obj['tensure_of_deduction']"> -->

                                    <ng-select [items]="tenure" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select One" [(ngModel)]="Obj['tensure_of_deduction']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                    [closeOnSelect]="true" name="field_name1">

                                </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC20']}}
                                    <!-- IFSC CODE --> <span style="color:red">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="Obj['ifsc_code']" maxlength="11">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC13']}}
                                    <!-- Deduction From --> <span style="color:red">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="Obj['deduction_from']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC14']}}
                                    <!-- Deduction To --> <span style="color:red">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="Obj['deduction_to']">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC15']}}
                                    <!-- Amount --> <span style="color:red">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="Obj['amount']">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitLICInfo()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC21']}}
                                        <!-- Submit --></button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-3">
                            <br>
                            <!--  <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC5']}}
                                    <!-- Select Employee --> <span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC5']}}" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" disabled="true">
                                        <!-- Select Employee -->
                                    </ng-select>
                                </div>



                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC7']}}
                                    <!-- Company Name --><span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="Obj['company_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC19']}}
                                    <!-- LIC Number --><span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="Obj['lic_no']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC9']}}
                                    <!-- Account Number --><span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="Obj['acct_no']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC10']}}
                                    <!-- Tenure Of Deduction --><span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control" type="text" [(ngModel)]="Obj['tensure_of_deduction']"> -->

                                    <ng-select [items]="tenure" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select One" [(ngModel)]="Obj['tensure_of_deduction']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                    [closeOnSelect]="true" ></ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC20']}}
                                    <!-- IFSC CODE --><span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="Obj['ifsc_code']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC13']}}
                                    <!-- Deduction From --><span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="Obj['deduction_from']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC14']}}
                                    <!-- Deduction To --><span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="Obj['deduction_to']">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC15']}}
                                    <!-- Amount --><span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="Obj['amount']">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateLICInfo()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LIC17']}}
                                        <!-- Update --></button>

                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
