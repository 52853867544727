<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping1']}}
                        <!-- CHART OF ACCOUNT MAPPING -->
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping2']}}
                                <!-- Relation List -->
                            </a>
                        </li>




                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping3']}}
                                <!-- Create Relation -->
                            </a>
                        </li>


                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" (click)="refresh()" data-toggle="tab"><i
                                    class="ti-settings"></i>{{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping4']}}
                                <!-- Update Relation -->
                            </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping5']}}">
                                        <!-- Filter -->
                                    </mat-form-field>
                                </div>

                            </div>
                            <br>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping6']}}
                                            <!-- Relation Id -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="chart_acc">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping7']}}
                                            <!-- Chart Of Account -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.chart_of_account }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping8']}}
                                            <!-- Type Of Relation -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.type}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="relation">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>

                                            Relation (Bank Account)

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{bankObj[element.relation]['bank_acct_no']}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping10']}}
                                            <!-- Action -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary"
                                                (click)="open_update(element)">{{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping11']}}
                                                <!-- Update -->
                                            </button>

                                            <button class="btn btn-danger" style="margin-left: 2px;"
                                                (click)="delete(element)">{{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping12']}}
                                                <!-- Delete -->
                                            </button>


                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="list"></tr>
                                    <tr mat-row *matRowDef="let row; columns: list;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                            <br>

                        </div>

                        <div class="tab-pane fade" id="tab-2">

                            <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>


                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping7']}}
                                        <!-- Chart Of Account --> :
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="chartOfAcc" bindLabel="leaf_value" bindValue="leaf_code"
                                            [multiple]="false"
                                            placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping13']}}"
                                            [(ngModel)]="obj['chart_of_account']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="coa"
                                            #coa="ngModel" required>
                                            <!-- Select chart of account -->
                                        </ng-select>



                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.selection-box]="coa.dirty && coa.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && coa.invalid" class="error-message">
                                            <div *ngIf="coa.errors?.['required']">Please select chart of account
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->



                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping14']}}
                                        <!-- Select Type --> :
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="type" bindLabel="id" bindValue="code" [multiple]="false"
                                            (change)="selectedType()"
                                            placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping14']}}"
                                            [(ngModel)]="obj['type']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="typ"
                                            #typ="ngModel" required>
                                            <!-- Select type -->
                                        </ng-select>


                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.selection-box]="typ.dirty && typ.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && typ.invalid" class="error-message">
                                            <div *ngIf="typ.errors?.['required']">Please select type
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->


                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row" *ngIf="obj['type'] != 'CASH'">
                                    <div class="col-3 text-right">
                                        Relation (Bank Account):
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="allBankAccounts" bindLabel="desc" bindValue="id"
                                            [multiple]="false" placeholder="Select Bank" [(ngModel)]="obj['relation']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="b_relation" #b_relation="ngModel" required>
                                        </ng-select>

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.selection-box]="b_relation.dirty && b_relation.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && b_relation.invalid" class="error-message">
                                            <div *ngIf="b_relation.errors?.['required']">Please select Relation (Bank Account
                                                )
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->


                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>

                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary"
                                            type="submit">{{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping15']}}
                                            <!-- Submit -->
                                        </button>
                                        <button class="btn btn-success d-none" id="subreset" type="reset">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <form name="form" (ngSubmit)="f2.form.valid && update()" #f2="ngForm" novalidate>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping7']}}
                                        <!-- Chart Of Account --> :
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="chartOfAcc" bindLabel="leaf_value" bindValue="leaf_code"
                                            [multiple]="false"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping13']}}"
                                            [(ngModel)]="obj['chart_of_account']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="coa"
                                            #coa="ngModel" required>
                                            <!-- Select chart of account -->
                                        </ng-select>



                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.selection-box]="coa.dirty && coa.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && coa.invalid" class="error-message">
                                            <div *ngIf="coa.errors?.['required']">Please select chart of account
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->


                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping14']}}
                                        <!-- Select Type --> :
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="type" bindLabel="id" bindValue="code" [multiple]="false"
                                            (change)="selectedType()"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping14']}}"
                                            [(ngModel)]="obj['type']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="typ"
                                            #typ="ngModel" required>
                                            <!-- Select type -->
                                        </ng-select>


                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.selection-box]="typ.dirty && typ.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && typ.invalid" class="error-message">
                                            <div *ngIf="typ.errors?.['required']">Please select type
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->


                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row" *ngIf="obj['type'] != 'CASH'">
                                    <div class="col-3 text-right">
                                        Relation (Bank Account):
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="allBankAccounts" bindLabel="desc" bindValue="id"
                                            [multiple]="false" placeholder="Select Bank" [(ngModel)]="obj['relation']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true"name="b_relation" #b_relation="ngModel" required>
                                        </ng-select>

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.selection-box]="b_relation.dirty && b_relation.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && b_relation.invalid" class="error-message">
                                            <div *ngIf="b_relation.errors?.['required']">Please select Relation (Bank Account
                                                )
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>

                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <!-- <button class="btn btn-primary" (click)="update()">
                                            {{mainService.allLableShowObj[mainService.language_cd+'Account_Mapping11']}}

                                        </button> -->
                                        <button class="btn btn-primary" type="submit">Update</button>
                                        <button class="btn btn-success d-none" id="upreset" type="reset" >Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>Loading....</ngx-spinner>
