<!-- START HEADER-->
<header class="header" app-portal-header></header>
<!-- END HEADER-->
<!-- START SIDEBAR-->
<!-- <nav class="page-sidebar" id="sidebar" app-portal-sidebar></nav> -->
<!-- END SIDEBAR-->

<!-- START PAGE CONTENT-->
<br>
<br>
<div style="margin-left: 5px; margin-right: 5px;">
    <router-outlet></router-outlet>
    <!-- <div app-banner></div> -->
</div>
<br>
<br>
<!-- <footer class="page-footer" app-portal-footer></footer> -->
<div class="row">
    <div class="col-12 text-center">
        <div class="font-13">2020 © <b>PCS Management Consultancy Pvt. Ltd.</b> - All rights reserved..</div>
    </div>
    <div class="col-12 text-center">
        <div class="font-13">build=erpbuild</div>
    </div>
</div>

<!-- END PAGE CONTENT-->
