<div class="sidebar" style="position: fixed;">
    <div id="sidebar-collapse">
        <div class="admin-block d-flex" style="align-items: center;">
            <div>
                <img src="./../../../assets/img/dash/admin.png" class="img-circle" width="45px" />
            </div>
            <div class="admin-info">
                <div class="font-strong">{{auth.allSystemModuleObj['SA']}}</div>
            </div>
        </div>
        <ul class="side-menu metismenu">
            <li>
                <a routerLinkActive="active" routerLink="/solution-admin/index"><i class="sidebar-item-icon fa fa-th-large"></i>
                    <span class="nav-label">{{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar1']}}</span>
                </a>
            </li>
            <li>
                <a routerLinkActive="active" routerLink="/solution-admin/product"><i class="sidebar-item-icon fa fa-product-hunt"></i>
                    <span class="nav-label">
                        <!-- Product -->{{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar13']}}
                    </span>
                </a>
            </li>

            <li>
                <a routerLinkActive="active" routerLink="/solution-admin/create-account"><i class="sidebar-item-icon fa fa-user-circle"></i>
                    <span class="nav-label">
                        <!-- Account -->{{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar2']}}
                    </span>
                </a>
            </li>

            <li>
                <a routerLinkActive="active" routerLink="/solution-admin/legal-entity"><i class="sidebar-item-icon fa fa-object-ungroup"></i>
                    <span class="nav-label">
                        <!-- Party -->{{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar3']}}

                    </span>
                </a>
            </li>
            <!-- <li>
                <a routerLinkActive="active" routerLink="/solution-admin/add-user"><i class="sidebar-item-icon fa fa-user-circle"></i>
                    <span class="nav-label">
                         {{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar4']}}
                    </span>
                </a>
            </li> -->
            <li routerLinkActive="active">
                <a href="javascript:;"><i class="sidebar-item-icon fa fa-database"></i>
                    <span class="nav-label">
                        <!-- Administration -->{{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar5']}}
                    </span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" routerLinkActive="in">

                    <li>
                        <a routerLinkActive="active" routerLink="/solution-admin/administration/resource">
                            <span class="nav-label">
                                <!-- Resource -->{{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar6']}}
                            </span>
                        </a>
                    </li>
                    <li>
                        <a routerLinkActive="active" routerLink="/solution-admin/administration/role">
                            <span class="nav-label">
                                <!-- Role -->{{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar7']}}
                            </span>
                        </a>
                    </li>
                    <li>
                        <a routerLinkActive="active" routerLink="/solution-admin/administration/user">
                            <span class="nav-label">
                                <!-- User -->{{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar8']}}
                            </span>
                        </a>
                    </li>
                    <li>
                        <a routerLinkActive="active" routerLink="/solution-admin/administration/language">
                            <span class="nav-label">
                                <!-- Language -->{{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar9']}}
                            </span>
                        </a>
                    </li>

                    <li>
                        <a routerLinkActive="active" routerLink="/solution-admin/administration/component">
                            <span class="nav-label">
                                {{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar10']}}    <!-- Component -->
                            </span>
                        </a>
                    </li>
                    <li>
                        <a routerLinkActive="active" routerLink="/solution-admin/administration/label">
                            <span class="nav-label">
                                {{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar11']}}    <!-- Label -->
                            </span>
                        </a>
                    </li>

                    <!-- <li>
                        <a routerLinkActive="active" routerLink="/solution-admin/administration/automatic-logout">
                            <span class="nav-label">Automatic Logout</span>
                        </a>
                    </li>
                    <li>
                        <a routerLinkActive="active" routerLink="/solution-admin/administration/login-info">
                            <span class="nav-label">Login Page</span>
                        </a>
                    </li> -->

                </ul>
            </li>
            <li>
                <a routerLinkActive="active" routerLink="/solution-admin/upload-vedio"><i class="sidebar-item-icon fa fa-upload" aria-hidden="true"></i>
                    <span class="nav-label">
                   Upload Video
                    </span>
                </a>
            </li>
            <li>
                <a routerLinkActive="active" routerLink="/solution-admin/help"><i class="sidebar-item-icon fa fa-question"></i>
                    <span class="nav-label">
                        {{auth.allLableShowObjSA[auth.language_cd+'SA_side_bar12']}}    <!-- Help -->
                    </span>
                </a>
            </li>
            <li>
                <a routerLinkActive="active" routerLink="/solution-admin/embReport"><i class="sidebar-item-icon fa fa-id-card"></i>
                    <span class="nav-label">
                        EMB Report
                    </span>
                </a>
            </li>
            <!-- <li routerLinkActive="active">
                <a href="javascript:;"><i class="sidebar-item-icon fa fa-question-circle"></i>
                    <span class="nav-label">Help</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" routerLinkActive="in">

                    <li>
                        <a routerLink="/solution-admin/administration/login-info" routerLinkActive="active">FAQ</a>
                    </li>
                    <li>
                        <a routerLink="/solution-admin/administration/login-info" routerLinkActive="active">User Manual</a>
                    </li>
                    <li>
                        <a routerLink="/solution-admin/administration/login-info" routerLinkActive="active">Tutorial Videos</a>
                    </li>
                    <li>
                        <a routerLink="/solution-admin/administration/login-info" routerLinkActive="active">Online Support</a>
                    </li>
                </ul>
            </li> -->
            <!-- <li routerLinkActive="active">
                <a href="javascript:;"><i class="sidebar-item-icon fa fa-building-o"></i>
                    <span class="nav-label"> Activity</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" routerLinkActive="in">
                    <li>
                        <a routerLink="/property/activity/booklet-purchase" routerLinkActive="active">Booklet Purchase</a>
                    </li>
                    <li>
                        <a routerLink="/property/activity/applications" routerLinkActive="active">Applicants</a>
                    </li>
                    <li>
                        <a routerLink="/property/activity/allotment" routerLinkActive="active">Allotment</a>
                    </li>
                    <li>
                        <a routerLink="/property/activity/applications-refund" routerLinkActive="active">Refund</a>
                    </li>
                    <li>
                        <a routerLink="/property/activity/payment-schedule" routerLinkActive="active">Payment Schedule</a>
                    </li>
                    <li>
                        <a routerLink="/property/activity/registry" routerLinkActive="active">Registry</a>
                    </li>
                    <li>
                        <a routerLink="/property/activity/restore" routerLinkActive="active">Restore</a>
                    </li>
                </ul>
            </li>
            <li routerLinkActive="active">
                <a href="javascript:;"><i class="sidebar-item-icon fa fa-cogs"></i>
                    <span class="nav-label">Setting</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" routerLinkActive="in">
                    <li>
                        <a routerLink="/property/property-setting/prop-code-value" routerLinkActive="active">Code Value</a>
                    </li>
                    <li>
                        <a routerLink="/property/property-setting/prop-field" routerLinkActive="active">Field</a>
                    </li>
                    <li>
                        <a routerLink="/property/property-setting/scheme" routerLinkActive="active">Scheme</a>
                    </li>
                    <li>
                        <a routerLink="/property/property-setting/subscheme" routerLinkActive="active">Sub Scheme</a>
                    </li>
                    <li>
                        <a routerLink="/property/property-setting/property-info" routerLinkActive="active">Property Type</a>
                    </li>

                    <li>
                        <a routerLink="/property/property-setting/property-def" routerLinkActive="active">Enter Property</a>
                    </li>
                    <li routerLinkActive="active">
                        <a href="javascript:;"><i class="sidebar-item-icon fa fa-gavel"></i>
                            <span class="nav-label"> Auction</span><i class="fa fa-angle-left arrow"></i></a>
                        <ul class="nav-3-level collapse" routerLinkActive="in">
                            <li>
                                <a routerLink="/property/activity/auction" routerLinkActive="active">Auction</a>
                            </li>
                            <li>
                                <a routerLink="/property/activity/auction-application" routerLinkActive="active">Auction Applicants</a>
                            </li>
                            <li>
                                <a routerLink="/property/activity/bid-details" routerLinkActive="active">Auction Bid Detsils</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li> -->
        </ul>
    </div>
</div>
