import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../../service/main.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllEmpService } from '../../service/all-emp.service';
import Swal from 'sweetalert2';
import { DataAccessService } from '../../service/data-access.service';
import { AttendaceMangService } from '../attendace-mang.service';
import { findIndex } from 'rxjs/operators';
// import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
  selector: 'app-leave-list',
  templateUrl: './leave-list.component.html',
  styleUrls: ['./leave-list.component.css']
})
export class LeaveListComponent implements OnInit {


  constructor(public mainService: MainService, private spinner: NgxSpinnerService, private allEmpService: AllEmpService,
    private dataAccessService: DataAccessService, private attendService: AttendaceMangService,) { }
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild('paginator2', { static: false }) paginator2!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns = ['s.n', 'emp_name', 't_of_lev', 'month', 'taken_lev', 'app_lev', 'all_lev', 'all_by_dy', 'rem_lev', 'dis_alw_elv', 'action'];
  displayedColumns2 = ['s.n', 'taken_lev', 'dis_allw_leave', 'allw_lev', 'from_date', 'to_date', 'reasion', 'action'];
  datasource2: any;
  datasource: any;
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.datasource2.filter = filterValue.trim().toLowerCase();
  }


  erpUser: any;
  b_acct_id: any;
  codeValueTechObj: any = {};
  searchObj: any = {};
  async ngOnInit() {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    console.log(this.mainService.codeValueTechObj['HR0024'])
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllEmployees();
    await this.getAccountProjectHier();
  }

  // All employee

  arr_id:any ;
  newallEmplyees:any  = [];
  allEmplyees:any ;
  allEmplyees_new:any ;
  async getAllEmployees() {
    this.spinner.show()
    var arr :any = []
    var obj :any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';
    var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data
      this.allEmplyees = arr;
      this.newallEmplyees = []
      for (let i = 0; i < resp.data.length; i++) {
        var obj:any  = new Object();
        obj = Object.assign({}, resp.data[i]);
        obj['emp_name'] = obj['emp_name']
        this.newallEmplyees.push(obj)
      }


      this.allEmplyees_new = [];
      for (let i = 0; i < resp.data.length; i++) {
        var obj:any  = new  Object();
        obj = Object.assign({}, resp.data[i]);
        obj['emp_name'] = this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.allEmplyees_new.push(obj)
      }


      console.log(this.allEmplyees)
      console.log(this.newallEmplyees);
      console.log(this.allEmplyees_new);
      this.newallEmplyees = await this.dataAccessService.filterEmployeeData(this.newallEmplyees)
      console.log(" this is the newallEmp--", this.newallEmplyees);
    } else {
      this.spinner.hide()
      Swal.fire('Error', "Error while getting employee list", 'error')
    }


  }


  getNumberFormat(num:any ) {
    return num.toString().padStart(3, "0")
  }

  //end all emp

  //---------------- project herarchy--------

  // node
  planing_arrproj :any = [];
  projecthier :any = []
  projhierobj:any  = {}
  projhierobjtype :any = {}
  projhierobjtypecd:any  = {}
  projhierobjisleaf :any = {}
  Obj :any = {}
  allProject:any  = [];
  joiningObj:any  = {};
  ind_emp_id:any


  async getAccountProjectHier() {
    var obj:any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAccountProjectHier(obj);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'HR-root'
        }
      }
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {

        var tt = []
        for (let k = 1; k < 8; k++) {
          if (resp.data[i]['lvl' + k + '_cd'] != null) {
            tt.push(resp.data[i]['lvl' + k + '_cd'])
          }
        }
        this.dataAccessService.data_assigment = this.dataAccessService.data_assigment.filter((x:any ) => x.user_id == this.erpUser.user_id)
        var exit_flag = false;
        for (let j = 0; j < this.dataAccessService.data_assigment.length; j++) {
          if (tt.includes(parseInt(this.dataAccessService.data_assigment[j]['node_cd']))) {
            exit_flag = true;
          }
        }

        if (tt.length == 1) {
          exit_flag = true;
        }
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR"))
          && (resp.data[i]['module_cd'] != null || resp.data[i]['module_cd'] != undefined) && exit_flag) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;
      this.allProject = resp.data;
      this.projecthier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_user_cd']
          this.projhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']
        }
      }
    } else {
      console.log("Error in getting all project hier")
    }
  }

  open_activitypopup() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1)
    }

    console.log("Open Pop Up")
    $('#ACTHIER').modal('show');
  }

  officeTime = { fromTime: '', toTime: "", grace: "", number: '', frequency: '' }
  down(item:any , k:any ) {
    let patharr = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }
    this.officeTime = { fromTime: '', toTime: "", grace: "", number: '', frequency: '' }
    this.Obj['path_desc'] = patharr.join("-->")
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [], office_time: [] })


    console.log("planing_arrproj---", this.planing_arrproj, "item", item)
    let arr :any = []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_cd'] != null) {

        if ((this.projecthier[i]['lvl' + (k + 1) + '_cd'] == item['hierarchy_type'] || k == -1)/*  && this.projecthier[i]['leaf_node_type'] != 'HREMP' */) {
          // this.officeTime={}
          //  for( let a=0; a<this.ruleTime[0].length;a++)
          //  {
          //   if(this.ruleTime[0][a]['heirarchy_type']==item['hierarchy_type'] )
          //   {
          //     // officeTime.push(this.ruleTime[0][a]['fromTime'],this.ruleTime[0][a]['toTime'],this.ruleTime[0][a]['grace']);
          //   this.officeTime['fromTime']=this.ruleTime[0][a]['fromTime'];
          //   this.officeTime['toTime']=this.ruleTime[0][a]['toTime'];
          //   this.officeTime['grace']=this.ruleTime[0][a]['grace'];
          //   this.officeTime['number']=this.ruleTime[0][a]['number'];
          //   this.officeTime['frequency']=this.ruleTime[0][a]['frequency'];
          //   }
          //  }

          this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'], officeRule: this.officeTime })
          arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
          // this.planing_arrproj[k+1]['office_time'].push({node_cd:this.projecthier[i]['lvl' + (k + 2) + '_cd'],...this.officeTime});

        }

        // this.planing_arrproj['office_time'].push(this.officeTime);

      }
      // this.planing_arrproj['Office_time'].push({ offICe_TIME:this.officeTime});
    }
  }

  remove(item:any , i:any ) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i])

        patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
      }
      this.Obj['path_desc'] = patharr.join("-->")
    }

  }
  emp_id:any  = [];
  emmm:any
  // ruleOffice=[]
  async submitProjectHierNode() {


    if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
      Swal.fire("Warning", "Please Select at least two level", 'warning')
    }
    else {
      $('#ACTHIER').modal('hide');
    }
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'])
    console.log(this.planing_arrproj.length)
    this.Obj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'];
    var lvl_no = this.planing_arrproj.length;

    // this.ruleOffice= this.planing_arrproj[this.planing_arrproj.length - 1]['office_time'].filter((avi)=> avi.node_cd==this.Obj['node_cd'])
    // console.log("this is thge ruleoffice",this.ruleOffice)

    this.ind_emp_id = [];
    var temp = [];
    this.emp_id = [];
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]['lvl' + lvl_no + '_cd'] == this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] && this.projecthier[i]['leaf_node_type'] == 'HREMP') {
        temp.push(this.projecthier[i]['leaf_user_cd'])
      }
    }

    for (let i = 0; i < temp.length; i++) {
      for (let j = 0; j < this.allEmplyees_new.length; j++) {
        if (temp[i] == 'HRARR' + this.allEmplyees_new[j]['arr_id']) {
          this.ind_emp_id.push(this.allEmplyees_new[j]['arr_id'])
          this.emp_id.push(this.allEmplyees_new[j]['emp_id'])

        }

      }
    }
    console.log(" allEmployees_new --", this.allEmplyees_new)
    console.log(this.ind_emp_id)
    console.log(this.emp_id)


  }

  check1:any
  setHierarchyNodeitem(item:any , i:any ) {
    console.log(item, i);
    this.check1 = item['hierarchy_type']
    console.log(this.check1);
    item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']]

    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }

    this.Obj['path_desc'] = dummy2.join(' --> ')
  }

  // ---end herarchy -----------





  insertDatasource = [];
  async getAllLeaveList() {
    if (this.ind_emp_id == null || this.ind_emp_id == undefined || this.searchObj['month'] == null
      || this.searchObj['year'] == null) {
      Swal.fire('Warning', "* Fields  required", 'warning');
      return;
    }
    this.insertDatasource = [];
    let obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.emp_id;
    obj['month'] = this.searchObj['month']
    obj['year'] = this.searchObj['year']
    console.log("this the obj---", obj);

    let resp :any = await this.attendService.getLeaveList(obj);
    let resp2:any  = await this.attendService.getExtraLeave(obj)
    console.log(resp['data']);
    console.log(resp2['data']);

    if (resp['error'] == false) {

      if (resp['data'].length == 0) {
        Swal.fire('Info', 'Data Not Available', 'info');
        this.datasource = new MatTableDataSource();
        this.datasource.sort = this.sort;

        this.datasource.paginator = this.paginator;
        return;
      }


      // for emp name
      for (let i = 0; i < resp['data'].length; i++) {
        this.newallEmplyees.filter((x:any ) => {
          if (x['emp_id'] == resp['data'][i]['emp_id']) {
            resp['data'][i].name = x['emp_name']
            resp['data'][i].no_of_leave_taken = parseInt(resp['data'][i]['num_of_leaves']) - parseInt(resp['data'][i]['leaves_remaining'])
            resp['data'][i].approved_leave = parseInt(resp['data'][i]['num_of_leaves']) - parseInt(resp['data'][i]['leaves_remaining'])
            resp['data'][i].allowed_leave = parseInt(resp['data'][i]['num_of_leaves']) - parseInt(resp['data'][i]['leaves_remaining'])
            resp['data'][i].allByDir = 0
            //  console.log()
            //  resp['data'][i].no_of_leave_taken= x['']
          }
        })

      }
      // for extra leave application
      for (let j = 0; j < resp2['data'].length; j++) {
        resp['data'].filter((x:any , ind:any ) => {
          console.log(" tis isthe item and index", x, ind)
          if (x['emp_id'] == resp2['data'][j]['emp_id'] && x['leave_code'] == resp2['data'][j]['leave_code'] && x['month'] == resp2['data'][j]['month'] && x['year'] == resp2['data'][j]['year']) {
            resp['data'][ind]['no_of_leave_taken'] = x['no_of_leave_taken'] + resp2['data'][j]['num_of_leaves'];
            resp['data'][ind]['allByDir'] = parseInt(resp['data'][ind]['allByDir']) + parseInt(resp2['data'][j]['all_admin'])
            // resp['data'][ind].leaves_remaining=  parseInt( resp['data'][ind]['leaves_remaining'])+ parseInt(resp2['data'][j]['leaves_remaining'])
            resp['data'][ind].leave_remaining = (parseInt(resp['data'][ind]['no_of_leave_taken']) - parseInt(resp['data'][ind]['allowed_leave'])) - parseInt(resp2['data'][j]['all_admin'])
            resp['data'][ind].disall_leave = (parseInt(resp['data'][ind]['no_of_leave_taken']) - parseInt(resp['data'][ind]['allowed_leave'])) - parseInt(resp2['data'][j]['all_admin'])
            // adding data for dir alloewed
            resp['data'][ind].leave_reasion = resp2['data'][j]['leave_reason']
            resp['data'][ind]['approved_leave'] = parseInt(resp['data'][ind]['approved_leave']) + parseInt(resp2['data'][j]['all_admin']);
            resp['data'][ind]['allowed_leave'] = parseInt(resp['data'][ind]['allowed_leave']) + parseInt(resp2['data'][j]['all_admin']);

          }

        })

      }

      // employee name /type of leave month/ no of leave taken
      this.insertDatasource = resp['data']
      // -- end

      console.log("this is the sss==", this.insertDatasource);


      this.datasource = new MatTableDataSource(this.insertDatasource);
      this.datasource.sort = this.sort;

      this.datasource.paginator = this.paginator;
    }




  }
  leave_code:any ;
  dirAllow:any  = {}
  async approve(element:any ) {
    // $('#leaveListForAllow').modal('');
    console.log(" this is the allowed leave approvea  ", element);
    this.dirAllow['b_acct_id'] = this.b_acct_id;
    this.dirAllow['emp_id'] = element.emp_id;
    this.dirAllow['leave_reason'] = element.leave_reasion;
    this.dirAllow['leave_code'] = element.leave_code
    this.dirAllow['month'] = element.month
    this.dirAllow['year'] = element.year
    // this.dirAllow['no_of_disallow']= element.disall_leave
    // this.disallowLeave= element.disall_leave
    // this.leave_reason= this.dirAllow['leave_reason']
    this.leave_code = this.dirAllow['leave_code']
    // this.no_ofLeave= element.disall_leave
    console.log(" this is thghe ", this.dirAllow);
    await this.leaveListForAllow();
    $('#leaveListForAllow').modal('show')
    // $('#ACTHIER').modal('show');
  }


  async leaveListForAllow() {

    let resp = await this.attendService.leaveListForAllow(this.dirAllow)
    console.log(" this is hte resp of leace listtttttt", resp);
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['num_of_leaves'] = parseInt(resp['data'][i]['num_of_leaves'])
        resp['data'][i].num_of_dis_allowed_leave = parseInt(resp['data'][i]['num_of_leaves']) - parseInt(resp['data'][i]['all_admin'])

        // resp['data'][i]['num_of_leaves'] =parseInt( resp['data'][i]['num_of_leaves']) - parseInt(resp['data'][i]['all_admin'])
        // if(resp['data'][i]['num_of_leaves']==0)
        // {
        //   // All
        // }
      }
      // resp['data']['num_of_leaves']
      this.datasource2 = new MatTableDataSource(resp['data']);
      this.datasource2.sort = this.sort;

      this.datasource2.paginator = this.paginator2;
    }
    else {
      Swal.fire("Error", "Error While Getting List", 'error');
      return
    }
  }
  leave_reason:any ;
  no_ofLeave:any ;
  disallowLeave:any ;
  allowLev:any ;
  async allowLeave(element:any ) {
    console.log('this is applw leave ', element)
    this.allowLev = ''
    // $('#leaveListForAllow').modal('hide')
    this.leave_reason = element.leave_reason;
    // this.no_ofLeave=element.num_of_leaves;
    this.disallowLeave = element.num_of_dis_allowed_leave
    // this.dirAllow['no_of_disallow']=element.num_of_leaves;
    this.dirAllow['no_of_leave'] = element.num_of_leaves;
    this.dirAllow['id'] = element.id;
    this.dirAllow['le_id'] = element.le_id;
    this.dirAllow['from_date'] = element.from_date;
    this.dirAllow['to_date'] = element.to_date;
    this.dirAllow['zone'] = element.zone

    $('#viewreason').modal('show');
  }


  async allowLeaveByDir() {
    if (this.allowLev > this.disallowLeave) {
      Swal.fire('Warning', 'Not Accept More Than Disallowed Leave', 'warning');
      return;
    }
    this.dirAllow['no_of_allw_leave'] = this.allowLev;
    this.dirAllow['approval_date'] = new Date().toISOString().slice(0, 10);
    this.dirAllow['appr_user_id'] = this.erpUser.user_id

    console.log(this.dirAllow)
    let resp = await this.attendService.allowLeave(this.dirAllow)
    console.log("this is hte resp-", resp)
    if (resp['error'] == false) {
      Swal.fire("Success", 'Leave Allowed Successfully', 'success');
      $('#viewreason').modal('hide');
      this.getAllLeaveList();
      this.leaveListForAllow();
      // $('#leaveListForAllow').modal('hide')
      // $('#viewreason').modal('show');
      return;
    }
    else {
      Swal.fire("Error", 'Error In Leave Allowed !', 'error');
      return;
    }
  }

  async checkValue() {
    if (this.allowLev > this.disallowLeave || this.allowLev == 0) {
      //  console.log("not accepted ", this.allowLev);
      // //  this.allowLev=0;
      //  console.log(" value --",  this.allowLev)
      this.allowLev = ''
      Swal.fire('Info', `You can't enter 0 or grater value than No Of Leaves (${this.disallowLeave})`, 'info');
      return;
    }
    else {
      // console.log(" this is hte element data ", this.dirAllow);
      this.dirAllow['attendance_date'] = []
      let avid1 = new Date(this.dirAllow['from_date'])
      let allReadyApprove = parseInt(this.dirAllow['no_of_leave']) - parseInt(this.disallowLeave)
      let allReady_P_Date = avid1.setDate(new Date(avid1).getDate() + allReadyApprove);

      console.log(" this is the data1111 ---", allReadyApprove, allReady_P_Date)
      for (let i = 0; i < this.allowLev; i++) {
        var datatoday = new Date(allReady_P_Date);
        var datatodays = datatoday.setDate(new Date(datatoday).getDate() + i);
        this.dirAllow['attendance_date'].push(new Date(datatodays).toISOString().slice(0, 10))
      }


      console.log(this.dirAllow['attendance_date']);

      console.log(" accepted", this.dirAllow);
      return;
    }

    // console.log(" this isthe value of the insert vlaue ",value)
  }





}  // end of the page


