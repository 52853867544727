import { Component, OnInit, ViewChild  } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { MutationService } from '../../service/mutation.service';
import { MainService } from '../../service/main.service';




@Component({
  selector: 'app-mutation-rule',
  templateUrl: './mutation-rule.component.html',
  styleUrls: ['./mutation-rule.component.css']
})
export class MutationRuleComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;


  displayedColumns: string[] = ['ID', 'Property_Category', 'Property_Type', 'Min_Amount', 'Action'];
  displayedColumns2 = ['mutation_rule_id', 'mutation_application_fee', 'advertisement_fee', 'mutation_charge', 'application_disposal_time', 'application_disposal_str', 'time_limit_for_objection', 'action'];

  ebillUser: any;
  b_acct_id: any;
  dataSource: any

  dataSource2:any ;
  Obj:any ;
  obj:any  = {}
  docobj :any = {}
  updatedocobj:any  = {}
  docobjx:any  = [];
  updatedocobjx:any  = [];
  updateobj :any = {}

  constructor(private spinner: NgxSpinnerService, private mutationservice: MutationService, public mainService: MainService) { }

  ngOnInit() {
    this.ebillUser =  JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.getMutationRule()

  }
  list_flag = true
  create_flag = false
  update_flag = false

  open_create() {

    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
  }
  open_list() {
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
  }
  refresh() {
    this.obj = {}
    this.dataSource = new MatTableDataSource([]);
    // this.data = []
    // this.data1 = []
  }

  price = [
    { id: 1, name: 'Salable Price' },

  ];

  document_type = [
    { id: 1, name: 'Mutation Document' },
    { id: 2, name: 'Applicant Document' },
  ];
  document_option = [
    { id: 1, name: 'Mandatory' },
    { id: 2, name: 'Optional' },
  ];


  delete(index:any ) {
    console.log(index)
    console.log(this.docobjx)
    this.docobjx.splice(index, 1)
    this.dataSource = new MatTableDataSource(this.docobjx);

  }

  add() {
    console.log(this.docobj)
    // this.dataSource.push()
    // console.log(this.dataSource)
    this.docobj['life_cycle_status'] = 'ACTIVE'
    this.docobjx.push(this.docobj)
    this.docobj = {}
    console.log(this.docobjx)
    this.dataSource = new MatTableDataSource(this.docobjx);
  }
  // updateadd() {
  //   console.log(this.updatedocobj)
  //   this.updatedocobj['life_cycle_status'] = 'ACTIVE'
  //   this.updatedocobjx.push(this.updatedocobj)
  //   this.updatedocobj = {}
  //   console.log(this.updatedocobjx)
  //   this.dataSource2 = new MatTableDataSource(this.updatedocobjx);
  // }
  async submit() {
    console.log(this.docobjx)
    console.log(this.obj)

     this.docobjx['life_cycle_status'] = 'ACTIVE'
    this.obj['life_cycle_status'] = 'ACTIVE'
    this.obj['user_id'] = this.ebillUser['user_id']
    this.obj['b_acct_id'] = this.b_acct_id
    this.obj['doc_arr'] = this.docobjx
    console.log(this.obj);
    var res:any  = await this.mutationservice.createMutationRule(this.obj)
    if (res['error']! = true) {
      this.open_list()
      swal.fire('Success', 'Rule created successfully', 'success')
    }
    await this.getMutationRule()
  }

  // async update() {
  //   console.log(this.updatedocobjx)
  //   console.log(this.updateobj)

  //   this.updatedocobjx['life_cycle_status'] = 'ACTIVE'
  //   this.updateobj['life_cycle_status'] = 'ACTIVE'
  //   this.updateobj['user_id'] = this.ebillUser['user_id']
  //   this.updateobj['b_acct_id'] = this.b_acct_id
  //   this.updateobj['doc_arr'] = this.updatedocobjx

  //   console.log(this.updateobj);

  //   var res = await this.mutationservice.createMutationRule(this.updateobj)
  //   if (res['error']! = true) {
  //     swal.fire('Success', 'rule created successfully', 'success')
  //   }
  // }

  async getMutationRule() {
    var obj:any  = {}
    this.spinner.show();
    obj['b_acct_id'] = this.b_acct_id
    var resp :any = await this.mutationservice.getMutationRule(obj)
    if (resp['error'] == false) {
      //console.log(resp['data'])

      this.dataSource2 = new MatTableDataSource(resp['data']);
      this.dataSource2.sort = this.sort;
      this.dataSource2.paginator = this.paginator;
      this.spinner.hide();

    } else {
      this.spinner.hide();
      console.log("eror while geting data")
    }

  }
   i :any
    async deleteData(element:any,i :any) {
      //console.log(element)
      this.Obj = Object.assign({}, element)
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then((result) => {
        if (result.value) {
          this.finaldelete()
        }
      })


    }
    async finaldelete() {
      this.Obj['b_acct_id'] = this.b_acct_id;
      this.spinner.show()
      let resp = await this.mutationservice.inactiveMutationRule(this.Obj);
      console.log(this.Obj);
      if (resp['error'] == false) {
        this.spinner.hide()
        await this.getMutationRule()
        this.list_flag = true
        this.create_flag = false
        this.update_flag = false
        Swal.fire('Success...', 'Deleted Successfully.', 'success')
      } else {
        this.spinner.hide()
        Swal.fire('Error', resp['data'], 'error')
      }


    }


  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
