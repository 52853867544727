<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves1']}}   <!-- Manage Employee Leaves -->
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves2']}}     <!-- Employee All Apply Leave List -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>
                                <!-- Apply Leave Application -->{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves3']}}
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus"></i>
                                Adjust Leave</a>
                        </li> -->

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Leave Status : -->{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves4']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="leaveStatus" bindLabel="value" bindValue="code"
                                        [multiple]="false" placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves4']}}"
                                        [(ngModel)]="selectObj['leave_status_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="leave_status_code">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Year : -->{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves5']}}
                                </div>
                                <div class="col-4">


                                    <ng-select [items]="mainService.codeValueTechObj['HR0030']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves5']}}"
                                        [(ngModel)]="selectObj['year']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="year">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitFilter()">
                                        <!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves6']}}
                                    </button>
                                </div>
                            </div>

                            <hr>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves7']}}">
                            </mat-form-field>

                            <div class=" example-container">

                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves8']}} <!-- Employee Name -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.emp_name }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves9']}}<!-- Employee ID -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{mainService.accInfo['account_short_name']}}{{getNumberFormat(element['emp_id'])}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="leave_code">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves10']}} <!-- Leave Type -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ mainService.codeValueShowObj['HR0026'][element.leave_code] }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="num_of_leaves">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Number of Leaves -->{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves11']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.num_of_leaves }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="leave_status_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves12']}} <!-- Leave Status -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.leave_status_code}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="from_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves13']}} <!-- From Date -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.from_date)}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="leave_reason">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves16']}} <!-- Reason -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!--  {{ element.leave_reason}}  -->


                                            <button class="btn btn-primary" (click)="view_reason(element)"
                                                data-toggle="modal" data-target="#view-reason"
                                                data-backdrop="false">{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves16']}}</button>
                                        </td>
                                    </ng-container>



                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves14']}}    <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button *ngIf="element.leave_status_code=='APPLIED'" class="btn btn-primary"
                                                (click)="approvalMessage(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves15']}}    <!-- APPROVE -->
                                            </button>&nbsp;&nbsp;
                                            <button *ngIf="element.leave_status_code=='APPLIED'" class="btn btn-danger"
                                                (click)="reject(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves17']}}    <!-- REJECT -->
                                            </button>&nbsp;&nbsp;
                                            <button *ngIf="element.leave_status_code=='APPLIED'" class="btn btn-danger"
                                                (click)="deleteMessage(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves18']}}    <!-- DELETE -->
                                            </button>&nbsp;&nbsp;
                                            <button *ngIf="element.document_id!=null" class="btn btn-info"
                                                (click)="view(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves19']}}    <!-- VIEW -->
                                            </button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                            <br>
                            <div  *ngIf="imageOpen==true">
                                <div class="row" style="justify-content: center;">

                                            <button class="btn btn-info" style="margin: 5px 4px;
                                            width: 5rem;" (click)="clearImage()">Close</button>
                                 </div>

                                    <br>
                                    <div class="row">
                                <div class="col-12 text-center">
                                    <img [src]="imgURL"  alt="Photo" />
                                </div>
                            </div>
                            </div>


                        </div>

                        <div class="tab-pane fade" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee ID : -->{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves20']}} : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="name" bindValue="emp_id"
                                        [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves20']}}" [(ngModel)]="applyLeaveObj['emp_id']"
                                        [selectableGroup]="true" (change)="changeName()"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves8']}} : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" disabled [(ngModel)]="applyLeaveObj['emp_name']">
                                </div>
                            </div>
                            <br>

                            <hr>

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves11']}} : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">


                                    <ng-select [items]="allLeaveRuleInfo" bindLabel="leave_value" bindValue="leave_code"
                                        [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves10']}}" [(ngModel)]="applyLeaveObj['leave_code']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>
                            <!--  <div class="row">
                                <div class="col-4 text-right">
                                    Number of Leaves :
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="applyLeaveObj['num_of_leaves']">

                                </div>
                            </div>
                            <br> -->

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves13']}}: <span style="color: red;">*</span>    <!-- From Date: -->
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="applyLeaveObj['from_date']">

                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves21']}} : <span style="color: red;">*</span><!-- To Date: -->
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="applyLeaveObj['to_date']">

                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves22']}}        <!-- Upload File: -->
                                </div>
                                <div class="col-4">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                        class="form-control" style="border-color: black"
                                        (change)="onFileUpload($event,file.files)">
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves14']}}: <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%;" id="re" name="re" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves14']}}" class="form-control"
                                        [(ngModel)]="applyLeaveObj['leave_reason']"></textarea>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="applyLeave()">{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves6']}}</button>

                                </div>
                            </div>

                        </div>
                        <!-- <div class="tab-pane fade" id="tab-3">
                            <br>
                            <div class="row"



                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="changeLeaveStatus()">Submit</button>

                                </div>
                            </div>

                        </div> -->

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves23']}}.</ngx-spinner>

<div class="modal" id="view-reason">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves16']}}</h4>
            </div>


            <div class="modal-body">

                <div class="row">
                    <label class="col-lg-4 control-label" style="font-size: 15px ;"><b>{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves16']}}:</b></label>
                    <div class="col-lg-8">
                        {{leave_reason}}
                    </div>
                </div>

            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves24']}}</button>
            </div>
        </div>
    </div>
</div>
