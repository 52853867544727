<div *ngIf="false">
  <div class="page-content fade-in-up">
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Auction</div>
            <!--Property-->
            <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
          </div>
          <div class="ibox-body">
            <ul class="nav nav-tabs tabs-line">
              <li class="nav-item">
                <a class="nav-link active" *ngIf="list_flag" data-toggle="tab"><i class="fa fa-line-chart"></i> Auction
                  List</a>
                <!--Property List-->
              </li>
              <li class="nav-item">
                <a class="nav-link" *ngIf="create_flag" data-toggle="tab"><i class="fa fa-plus-circle"
                    aria-hidden="true">
                  </i> Create
                  Auction
                </a>
                <!--Create Property-->
              </li>
              <li class="nav-item">
                <a class="nav-link" *ngIf="update_flag" data-toggle="tab"><i class="fa fa-plus-circle"
                    aria-hidden="true">
                  </i> Update
                  Auction</a>
              </li>
            </ul>

            <div class="tab-content">
              <div *ngIf="list_flag">
                <br />
                <div class="row">
                  <div class="col-6">
                    <mat-form-field style="margin-left: 2%">
                      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{
                        auth.allLableShowObjProp[auth.language_cd + 'property5']
                      }}" />
                    </mat-form-field>
                  </div>
                  <div class="col-6 text-right">
                    <button (click)="open_create()" class="btn btn-outline-primary">
                      Create
                    </button>
                  </div>
                </div>
                <div class="example-container">
                  <table mat-table [dataSource]="datasource" matSort>
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                        ID
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                        {{ element.auction_id }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="code">
                      <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                        Auction Code
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{ element.auction_cd }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                        Auction Name
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{ element["offer_name"] }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="no_of_prop">
                      <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                        Number Of Property
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{ element["no_property"] }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="start_dt">
                      <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                        Auction Start Date
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{
                        mainService.dateFormatChange(
                        element.application_start_dt
                        )
                        }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="end_dt">
                      <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                        Auction End Date
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{
                        mainService.dateFormatChange(element.application_end_dt)
                        }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                        Status
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{ element.life_cycle_status }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                        Action
                      </th>

                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        <button class="btn btn-outline-primary" (click)="open_update(element)"
                          *ngIf="element['life_cycle_status'] == 'ACTIVE'">
                          Update</button>&nbsp;
                        <button class="btn btn-outline-danger" (click)="Delete(element)"
                          *ngIf="element['life_cycle_status'] == 'ACTIVE'">
                          Delete</button>&nbsp;

                        <button class="btn btn-outline-info" (click)="Appr(element)" *ngIf="
                          element['life_cycle_status'] == 'ACTIVE' &&
                          apprflag == true
                        ">
                          Add Approval</button>&nbsp;

                        <button class="btn btn-outline-info" *ngIf="
                          element.life_cycle_status == 'UNDERAPPROVAL' ||
                          element.life_cycle_status == 'APPROVED'
                        " (click)="viewStatus(element)">
                          View Status</button>&nbsp;
                        <button class="btn btn-outline-success" *ngIf="
                          element.life_cycle_status == 'UNDERAPPROVAL' ||
                          element.life_cycle_status == 'APPROVED'
                        " (click)="viewDetails(element)">
                          View Details</button>&nbsp;
                      </td>
                    </ng-container>
                    <!-- displayedColumns_1 = ['id', 'code', 'name', 'no_of_prop', 'start_dt', 'end_dt', 'status', 'action'] -->

                    <tr mat-header-row *matHeaderRowDef="displayedColumns_1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns_1"></tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                </div>
              </div>

              <!-- {{admindata}} -->
              <div *ngIf="create_flag">
                <br />
                <div class="row">
                  <div class="col-12 text-right">
                    <button (click)="open_list()" class="btn btn-outline-primary">
                      Go To List
                    </button>
                  </div>
                </div>
                <br />
                <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                  <mat-step [stepControl]="first" label="Offer">
                    <br />
                    <div class="card">
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Project :</h6>
                        </div>
                        <div class="col-1">
                          <button (click)="selectProject()" class="btn btn-outline-primary">
                            Select
                          </button>
                          &nbsp;&nbsp;
                        </div>
                        <div class="col-5">
                          {{ project_obj["project_path_desc"] }}
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Activity :</h6>
                        </div>
                        <div class="col-1">
                          <button (click)="selectActivity()" class="btn btn-outline-primary">
                            Select
                          </button>
                          &nbsp;&nbsp;
                        </div>
                        <div class="col-5">
                          {{ act_obj["project_path_desc"] }}
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Budget :</h6>
                        </div>
                        <div class="col-1">
                          <button (click)="selectBudget()" class="btn btn-outline-primary">
                            Select
                          </button>
                          &nbsp;&nbsp;
                        </div>
                        <div class="col-5">
                          {{ bud_obj["project_path_desc"] }}
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Product :</h6>
                        </div>
                        <div class="col-1">
                          <button (click)="selectProduct()" class="btn btn-outline-primary">
                            Select
                          </button>
                          &nbsp;&nbsp;
                        </div>
                        <div class="col-5">
                          {{ prod_obj["project_path_desc"] }}
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Offer code : <span style="color: red"> *</span></h6>
                        </div>
                        <div class="col-3">
                          <input type="text" placeholder="Enter Offer Code"
                            oninput="javascript: if (this.value.includes('/')) this.value = this.value.replace('/','');"
                            [(ngModel)]="Obj['auction_cd']" class="form-control" />
                        </div>
                        <div class="col-3"></div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Offer Name : <span style="color: red"> *</span></h6>
                        </div>
                        <div class="col-3">
                          <input type="text" placeholder="Enter Offer Name"
                            oninput="javascript: if (this.value.includes('/')) this.value = this.value.replace('/','');"
                            [(ngModel)]="Obj['auction_name']" class="form-control" />
                        </div>
                        <div class="col-3"></div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>
                            Offer Description : <span style="color: red"> *</span>
                          </h6>
                        </div>
                        <div class="col-3">
                          <textarea style="width: 100%; height: 80px" id="re" name="re"
                            oninput="javascript: if (this.value.includes('/')) this.value = this.value.replace('/','');"
                            [(ngModel)]="Obj['auction_desc']" placeholder="Enter Offer Description"
                            class="form-control"></textarea>
                        </div>
                        <div class="col-3"></div>
                      </div>
                      <br />
                      <!-- {{this.mainService.codeValueTechObj['PROP0025'] |json }} -->

                      <ng-container *ngFor="let _ of uploadDocLength">
                        <div class="row center d-flex justify-content-center">
                          <div class="col">
                            <h4 class="text-center">
                              Upload Documents
                            </h4>
                          </div>
                        </div>
                        <div class="row p-2 mt-1">
                          <div class="col-3"></div>
                          <div class="col-3">
                            <ng-select [(ngModel)]="currentDocType" placeholder="Select Doc Type">
                              <ng-option *ngFor="let item of mainService.codeValueTechObj['PROP0025'] "
                                [value]="item.code">
                                {{ item.value }}
                              </ng-option>
                            </ng-select>
                          </div>
                          <div class="col-3">
                            <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                              class="form-control" [(ngModel)]="fileChooser" style="border-color: black"
                              (change)="onFileUpload($event, file.files)" />
                          </div>
                          <div class="col-3">
                            <button (click)="upload()" class="btn btn-outline-primary">
                              Upload
                            </button>
                          </div>
                        </div>
                      </ng-container>

                      <div class="row my-3">
                        <table *ngIf="Obj['doc_ids'] && Obj['doc_ids'].length" class="table table-bordered"
                          style="width: 80%; margin-left: 10%">
                          <thead>
                            <tr>
                              <th>Sr.no</th>
                              <th>Doc Type</th>
                              <th>Doc Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- id:resp['data'],type:this.currentDocType,name:obj['doc_name']} -->
                            <tr *ngFor="let item of Obj['doc_ids']; let j = index">
                              <td>{{ j + 1 }}</td>
                              <td>{{ item["type"] }}</td>
                              <td>{{ item["name"] }}</td>
                              <td>
                                <button class="btn btn-danger" (click)="removeDoc(j)">
                                  Delete
                                </button>
                                <button class="btn btn-danger" (click)="view(j)">
                                  view
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <!-- <div class="row" *ngIf="showAddDocButton" >
                                            <div class="col-10 text-right">
                                                <button (click)="uploadDocLengthInc()" class="btn btn-outline-primary" >Add Doc</button>
                                            </div>
                                        </div> -->
                      <br />
                    </div>
                    <br />
                    <br />
                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperNext>
                          Next
                        </button>
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [stepControl]="second" label="Properties">
                    <div class="card">
                      <br />
                      <h6 style="color: red" class="text-center">
                        Selected Property
                      </h6>
                      <div class="row text-center" *ngIf="property_group_data.length">
                        <table class="table table-bordered" style="width: 80%; margin-left: 10%">
                          <thead>
                            <tr>
                              <th>Property Number</th>
                              <th>Property Type</th>
                              <th>Area</th>
                              <th>Unit</th>
                              <th>Emd Type</th>
                              <th>EMD Amount</th>
                              <th>Quota</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="
                                                            let item of property_group_data;
                                                            let i = index
                                                            ">
                              <tr *ngFor="
                                                                let property of item.property_arr;
                                                                let j = index
                                                            ">
                                <td>
                                  {{ property["assigned_property_number"] }}
                                </td>
                                <td>{{ property["property_type"] }}</td>
                                <td>{{ property["area"] }}</td>
                                <td>{{ property["unit"] }}</td>
                                <td>{{ property["emd_amt_type"] }}</td>
                                <td>{{ property["emd_amt"] }}</td>
                                <td>{{ property["quota"] }}</td>
                                <td>
                                  <button class="btn btn-danger" (click)="removeProperty(i, j)">
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Location Hierarchy :</h6>
                        </div>
                        <div class="col-3">
                          <ng-select [items]="first_list" bindLabel="hier_buss_name" bindValue="hier_type_id"
                            [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                            [selectableGroup]="true" (change)="getAllLevls()" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                        </div>
                      </div>
                      <br />
                      <div class="row" *ngFor="let item of data; let i = index">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Level {{ i + 1 }} :</h6>
                        </div>
                        <div class="col-3">
                          <ng-select *ngIf="i == 0" [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                            [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl1_cd']"
                            (change)="set_layout_data(item, i)" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                            [multiple]="false" (change)="set_layout_data(item, i)" placeholder="Select..."
                            [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 2" [items]="level3" (change)="set_layout_data(item, i)"
                            bindLabel="lvl3_value" bindValue="lvl3_cd" [multiple]="false" placeholder="Select..."
                            [(ngModel)]="Obj['lvl3_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 3" [items]="level4" (change)="set_layout_data(item, i)"
                            bindLabel="lvl4_value" bindValue="lvl4_cd" [multiple]="false" placeholder="Select..."
                            [(ngModel)]="Obj['lvl4_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 4" [items]="level5" (change)="set_layout_data(item, i)"
                            bindLabel="lvl5_value" bindValue="lvl5_cd" [multiple]="false" placeholder="Select..."
                            [(ngModel)]="Obj['lvl5_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 5" [items]="level6" (change)="set_layout_data(item, i)"
                            bindLabel="lvl6_value" bindValue="lvl6_cd" [multiple]="false" placeholder="Select..."
                            [(ngModel)]="Obj['lvl6_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 6" [items]="level7" (change)="set_layout_data(item, i)"
                            bindLabel="lvl7_value" bindValue="lvl7_cd" [multiple]="false" placeholder="Select..."
                            [(ngModel)]="Obj['lvl7_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                        </div>
                        <div class="col-3" *ngIf="i == data.length - 1">
                          <button (click)="add_dropdown(item, i)" class="btn btn-outline-primary">
                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                          </button>
                          &nbsp;&nbsp;
                          <button (click)="remove_dropdown(item, i)" *ngIf="i != 0" class="btn btn-outline-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Plan Layout:</h6>
                        </div>
                        <div class="col-3">
                          <ng-select [items]="plan_layout" bindLabel="desc" bindValue="property_group_id"
                            (change)="getAllProType()" placeholder="Select Plan Layout" [(ngModel)]="propObj['id']"
                            [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                            name="chart_of_account0">
                          </ng-select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>
                            Property Type : <span style="color: red"> *</span>
                          </h6>
                        </div>
                        <div class="col-3">
                          <ng-select [(ngModel)]="propObj['property_type']" placeholder="Select Property Type">
                            <ng-option *ngFor="let item of allProTypeAvailable" [value]="item.property_type">
                              {{ item.property_type }}
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-12 text-center">
                          <button class="btn btn-outline-success" (click)="show_proprties()">
                            Show Propeties
                          </button>
                        </div>
                      </div>
                      <br />
                      <div class="row align-items-end">
                        <div class="col-8"></div>
                        <div class="col-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="selectall"
                              (click)="selectAll()" />
                            <label class="form-check-label" for="selectall">
                              Select all
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- samarjeet uncommnet this -->
                      <div class="example-container" style="width: 80%; margin-left: 10%">
                        <table mat-table [dataSource]="datasource2" matSort>
                          <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              Action
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                              <input type="checkbox" style="width: 20px; height: 20px"
                                [(ngModel)]="element['is_checked']" (click)="chooseProperty(element)" />
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="assigned_property_number">
                            <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                              Property number
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                              {{ element.assigned_property_number }}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="quota">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              quota
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              <ng-select [(ngModel)]="element['quota']" [disabled]="!element['is_checked']"
                                placeholder="Select  Quota">
                                <ng-option *ngFor="
                                                                let item of mainService.codeValueTechObj[
                                                                    'PROP0019'
                                                                ]
                                                                " [value]="item.code">
                                  {{ item.value }}
                                </ng-option>
                              </ng-select>

                              <!-- <div class="row no-gutters" style="width: 318px;" >
                                                            <div class="col no-gutters">
                                                                <select [disabled]="!element['is_checked']" class="form-control form-control-lg"  style="width: 250px;" >
                                                                    <ng-container *ngFor="let item of mainService.codeValueTechObj['PROP0019']" >
                                                                        <option value="item.code" style="width: 250px;">
                                                                                {{item.value}}
                                                                        </option>
                                                                    </ng-container>
                                                                </select>
                                                            </div>
                                                        </div> -->
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="property_type">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              Property Type
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.property_type }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              Area
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.area }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="area">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              Unit
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.unit }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="emd_type">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              EMD Type
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              <ng-select [disabled]="!element['is_checked']" [(ngModel)]="element['emd_amt_type']"
                                placeholder="EMD Type">
                                <ng-option value="fixed">Fixed</ng-option>
                                <ng-option value="percentage">Percentage</ng-option>
                              </ng-select>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="emd_amount">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              EMD
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              <input type="number" class="form-control" [disabled]="!element['is_checked']"
                                [(ngModel)]="element['emd_amt']" />
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                        </mat-paginator>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-12 text-center">
                          <button class="btn btn-success" (click)="save_property()">
                            Save
                          </button>
                        </div>
                      </div>
                      <br />
                    </div>

                    <br />
                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>
                          {{
                          auth.allLableShowObjProp[
                          auth.language_cd + "property34"
                          ]
                          }}
                        </button>
                        &nbsp;&nbsp;
                        <!--Back-->
                        <button mat-button mat-raised-button class="btn btn-outline-primary" (click)="save_At_step2()"
                          matStepperNext>
                          {{
                          auth.allLableShowObjProp[
                          auth.language_cd + "property35"
                          ]
                          }}
                        </button>
                        <!--Next-->
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [stepControl]="third" label="Auction Scheduling Costing">
                    <!--Property Details-->
                    <div class="row mt-2">
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Application Start Date :</h6>
                          </div>
                          <div class="col text-left">

                            <input type="date" class="form-control" [(ngModel)]="Obj['application_start_date']"
                              (change)="SendDataonChange($event)" min="{{applicationStartDate}}" />

                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 mt-2">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Application End Date:</h6>
                          </div>
                          <div class="col text-left">

                            <input type="date" class="form-control" [(ngModel)]="Obj['application_end_date']"
                              (change)="SendDataonChangeAppEnd($event)" min="{{applicationStartDate}}" />

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Auction Start Date :</h6>
                          </div>
                          <!-- {{currentDat}} -->
                          <div class="col text-left">

                            <input type="date" class="form-control" [(ngModel)]="Obj['auction_start_date']"
                              (change)="SendDataonChangeAucationStart($event)" min="{{applicationEndDate}}" />

                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Auction End Date:</h6>
                          </div>
                          <div class="col text-left">

                            <input type="date" class="form-control" [(ngModel)]="Obj['auction_end_date']"
                              min="{{auctionStartDate}}" />

                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- auction_start_time -->
                    <div class="row mt-2">
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Bidding Start Time :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="time" class="form-control" (click)="onclicktime()" [min]="currentTime"
                              [(ngModel)]="Obj['auction_start_time']" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Bidding End Time :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="time" class="form-control" (click)="onclicktime()" [min]="currentTime"
                              [(ngModel)]="Obj['auction_end_time']" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Time Extension(In minutes) :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="number" class="form-control" [(ngModel)]="Obj['auction_time_extension']" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>No Of Extension :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="number" class="form-control" [(ngModel)]="Obj['auction_max_extension']" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Begin Extension befor ending(In minutes) :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="number" class="form-control" [(ngModel)]="Obj['ext_within']" />
                          </div>
                        </div>
                      </div>

                    </div>

                    <br />

                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>
                          {{
                          auth.allLableShowObjProp[
                          auth.language_cd + "property34"
                          ]
                          }}
                        </button>
                        &nbsp;
                        <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperNext>
                          Next
                        </button>
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [stepControl]="fourth" label="Payment Option">
                    <br />
                    <div class="card">
                      <ng-container *ngIf="paymentDiscriptionList.length">
                        <br />
                        <table class="table table-bordered" style="width: 80%; margin-left: 10%">
                          <thead>
                            <tr>
                              <th>Details</th>
                              <th>Mode</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="
                                                        let item of paymentDiscriptionList;
                                                        let i = index
                                                        ">
                              <td>{{ item["description"] }}</td>
                              <td>{{ item["mode_of_payment"] }}</td>
                              <td>
                                <button (click)="
                                                                remove_paymentDiscription_item(item, i)
                                                                " class="btn btn-outline-danger">
                                  Remove
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <br />
                      <div class="row">
                        <div class="col-6 text-right">
                          <h6>
                            Select Mode Of Payment :<span style="color: red">
                              *</span>
                          </h6>
                        </div>
                        <div class="col-3">
                          <ng-select [items]="modeOfPayment" bindLabel="id" bindValue="id" [multiple]="false"
                            placeholder="Select Required Document" (change)="paymentOptionData()"
                            [(ngModel)]="Obj['payment_option']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-6 text-right">
                          <h6>
                            Select Payment Options that you would like to include
                            in the offer :<span style="color: red"> *</span>
                          </h6>
                        </div>
                        <div class="col-3">
                          <ng-select [items]="paymentOptionList" bindLabel="frequency_of_installment"
                            bindValue="pay_option_id" [multiple]="false" placeholder="Select Payment Option"
                            [(ngModel)]="paymentOptionObj['option_list']" [selectableGroup]="true"
                            [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                            <ng-template ng-label-tmp let-item="item">
                              <div (click)="checkInPaymentDescriptionList(item)">
                                {{ item.mode_of_payment }}-{{
                                item.range_start_from
                                }}-{{ item.range_end_at }}(%)
                              </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                              <div (click)="checkInPaymentDescriptionList(item)">
                                {{ item.mode_of_payment }}-{{
                                item.range_start_from
                                }}-{{ item.range_end_at }}(%)
                              </div>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                      <br />
                      <!-- {{ admindata |json }}
                                        {{ListGot}} -->
                      <ng-container *ngIf="ListGot">
                        <div class="row mt-5" style="width: 80%; margin-left: 10%" *ngFor="let data of admindata">
                          <div class="col-12 text-center">
                            <h5 class="text-uppercase font-weight-bold">
                              {{data.gateway_name}}
                            </h5>
                          </div>
                          <div class="col-12 ml-3">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="row">#</th>
                                  <th scope="col">Purpose</th>
                                  <th scope="col">Account number</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let gatway of data['list']; let i = index">
                                  <th scope="row" class="text-left">{{ i + 1 }}</th>
                                  <td class="text-left">{{ gatway.purpose }}</td>
                                  <td class="text-left">{{ gatway.account_no }}</td>
                                  <td class="text-left">
                                    <input type="checkbox" [checked]="admindata[i]?.list[j]?.selected"
                                      (change)="clickingcheckbox(i, gatway.id)" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </ng-container>

                      <br />
                    </div>
                    <br />
                    <br />
                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>
                          {{
                          auth.allLableShowObjProp[
                          auth.language_cd + "property34"
                          ]
                          }}
                        </button>
                        &nbsp;
                        <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperNext>
                          {{
                          auth.allLableShowObjProp[
                          auth.language_cd + "property18"
                          ]
                          }}
                        </button>
                        <!--Next-->
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [stepControl]="fifth" label="Term And Condition">
                    <br />
                    <div class="card">
                      <div class="row">
                        <div class="col-12">
                          <h5 class="text-centerm my-3">
                            Select Term And Condition
                          </h5>
                        </div>
                        <table class="table table-bordered">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>Sr. No.</th>
                              <th>T&C Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of tAndcListDB; let i = index">
                              <td>
                                <input type="checkbox" [checked]="item.selected" class="text-left"
                                  (change)="selectTandC(i, item.id)" />
                              </td>
                              <th scope="row">{{ i + 1 }}.</th>
                              <td>{{ item.tc }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row">
                        <div class="col-12 text-center">
                          <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>
                            {{
                            auth.allLableShowObjProp[
                            auth.language_cd + "property34"
                            ]
                            }}
                          </button>
                          &nbsp;
                          <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperNext>
                            {{
                            auth.allLableShowObjProp[
                            auth.language_cd + "property18"
                            ]
                            }}
                          </button>
                          <!--Next-->
                        </div>
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [stepControl]="sixth" label="done">
                    <br />
                    <div class="container-fluid">
                      <div class="card">
                        <div class="card-header g-0">
                          <h5 class="text-center">Offer Details</h5>
                        </div>

                        <div class="card-body">
                          <div class="row">
                            <div class="col-12 g-9">
                              <div class="row g-0">
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">Offer Code</h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj['auction_cd'] }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <!-- <div class="row g-0">
                                                                    <div class="col-5 g-0">
                                                                        <h6 class="text-left">
                                                                            Basic Bid Price
                                                                        </h6>
                                                                    </div>:
                                                                    <div class="col-6 g-0 ">
                                                                        {{Obj['auction_cd']}}
                                                                    </div>
                                                                </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-12 g-9">
                              <div class="row g-0">
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">Offer Name</h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj['auction_name'] }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">
                                        Application Start date
                                      </h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj['application_start_date'] | date:'longDate' }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-12 g-9">
                              <div class="row g-0">
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">
                                        Application End Date :
                                      </h6>
                                    </div>
                                    <div class="col-6 g-0">
                                      {{ Obj['application_end_date'] | date:'longDate' }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">
                                        Auction Start Date And Time
                                      </h6>
                                    </div>
                                    <div class="col-6 g-0">

                                      :{{ Obj['auction_start_date'] | date:'longDate' }} {{
                                      transformTime(Obj['auction_start_time']) }}

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-12 g-9">
                              <div class="row g-0">
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">
                                        Auction End Date And Time :
                                      </h6>
                                    </div>:
                                    <div class="col-6 g-0">
                                      {{ Obj['auction_end_date'] | date:'longDate' }} {{
                                      transformTime(Obj['auction_end_time']) }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">Auction Time Extension</h6>
                                    </div>:
                                    <div class="col-6 g-0">

                                      {{ Obj['auction_time_extension'] }}

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-12 g-9">
                              <div class="row g-0">

                                <div class="col-12 col-md-6">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">
                                        No Of Extension
                                      </h6>
                                    </div>:
                                    <div class="col-6 g-0">
                                      {{ Obj["auction_max_extension"] }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">Extension Before End Time </h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj['ext_within'] }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row my-3">
                            <div class="col-12 mt-2 text-center">
                              <h4>Auction Documents</h4>
                            </div>
                          </div>

                          <div class="row">
                            <table class="table table-bordered" style="width: 80%; margin-left: 10%">
                              <thead>
                                <tr>
                                  <th>Sr.no</th>
                                  <th>Doc Type</th>
                                  <th>Doc Name</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of Obj['doc_ids']; let j = index">
                                  <td>{{ j + 1 }}</td>
                                  <td>{{ item["type"] }}</td>
                                  <td>{{ item["name"] }}</td>
                                  <td>
                                    <button class="btn btn-danger" (click)="view(j)">
                                      view
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="row my-3">
                            <div class="col-12">
                              <h4 class="text-center">
                                Auction Properties
                              </h4>
                            </div>
                          </div>

                          <div class="row text-center">
                            <table class="table table-bordered" style="width: 80%; margin-left: 10%">
                              <thead>
                                <tr>
                                  <th>Property Number</th>
                                  <th>Property Type</th>
                                  <th>Area</th>
                                  <th>Unit</th>
                                  <th>Emd Type</th>
                                  <th>EMD Amount</th>
                                  <th>Quota</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngFor="let item of property_group_data;let i = index">
                                  <tr *ngFor="let property of item.property_arr;let j = index">
                                    <td>{{ property["assigned_property_number"] }}</td>
                                    <td>{{ property["property_type"] }}</td>
                                    <td>{{ property["area"] }}</td>
                                    <td>{{ property["unit"] }}</td>
                                    <td>{{ property["emd_amt_type"] }}</td>
                                    <td>{{ property["emd_amt"] }}</td>
                                    <td>{{ property["quota"] }}</td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>



                          <div class="row my-3">
                            <div class="col-12">
                              <h4 class="text-center">
                                Payment Options
                              </h4>
                            </div>
                          </div>

                          <div class="row">
                            <br />
                            <table class="table table-bordered" style="width: 80%; margin-left: 10%">
                              <thead>
                                <tr>
                                  <th>Details</th>
                                  <th>Mode</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="
                                                                    let item of paymentDiscriptionList;
                                                                    let i = index;
                                                                    ">
                                  <td>{{ item["description"] }}</td>
                                  <td>{{ item["mode_of_payment"] }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="row">
                            <div class="col-12">
                              <h4 class="text-center">
                                Auction Accounts
                              </h4>
                            </div>
                          </div>

                          <!-- <ng-container *ngIf="ListGot"> -->
                          <div class="row mt-5" style="width: 80%; margin-left: 10%">
                            <!-- <div class="col-12 text-center">
                                                            <h5 class="text-uppercase font-weight-bold">
                                                                {{data.bank_name}}
                                                            </h5>
                                                        </div> -->
                            <div class="col-12 ml-3">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="row">#</th>
                                    <th scope="col">Purpose</th>
                                    <th scope="col">Bank Name</th>
                                    <th scope="col">Account number</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngFor="let data of gatwayData;let i = index">
                                    <!-- *ngIf="admindata[i]?.list[j]?.selected" -->
                                    <tr>
                                      <th scope="row" class="text-left">{{ i + 1 }}</th>
                                      <td class="text-left">{{ data.purpose }}</td>
                                      <td class="text-left">{{ data.gateway_name }}</td>
                                      <td class="text-left">{{ data.account_no }}</td>
                                    </tr>
                                  </ng-container>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div class="row" style="width: 80%; margin-left: 10%">
                            <div class="col-12">
                              <h5 class="text-center my-3">
                                Selected Term And Condition
                              </h5>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 text-center">
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>T&C Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let item of tAndcListDB; let i = index">
                                    <ng-container *ngIf="item.selected">
                                      <th scope="row">{{ i + 1 }}.</th>
                                      <td>{{ item.tc }}</td>
                                    </ng-container>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <!-- </ng-container> -->
                        </div>
                      </div>
                    </div>

                    <br />
                    <div class="col-12 text-center">
                      <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>
                        {{
                        auth.allLableShowObjProp[
                        auth.language_cd + "property47"
                        ]
                        }}
                      </button>
                      &nbsp;&nbsp;
                      <button class="btn btn-outline-success" (click)="submit()">
                        {{
                        auth.allLableShowObjProp[
                        auth.language_cd + "property48"
                        ]
                        }}</button>&nbsp;&nbsp;
                    </div>
                  </mat-step>
                </mat-horizontal-stepper>
              </div>

              <!-- samarjeet -->
              <div *ngIf="update_flag">
                <br />
                <div class="row">
                  <div class="col-12 text-right">
                    <button (click)="open_list()" class="btn btn-outline-primary">
                      Go To List
                    </button>
                  </div>
                </div>
                <br />
                <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                  <mat-step [stepControl]="first" label="Offer">
                    <br />
                    <div class="card">
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Project :</h6>
                        </div>
                        <div class="col-1">
                          <button (click)="selectProject()" class="btn btn-outline-primary">
                            Select
                          </button>
                          &nbsp;&nbsp;
                        </div>
                        <div class="col-5">
                          {{ project_obj["project_path_desc"] }}
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Activity :</h6>
                        </div>
                        <div class="col-1">
                          <button (click)="selectActivity()" class="btn btn-outline-primary">
                            Select
                          </button>
                          &nbsp;&nbsp;
                        </div>
                        <div class="col-5">
                          {{ act_obj["project_path_desc"] }}
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Budget :</h6>
                        </div>
                        <div class="col-1">
                          <button (click)="selectBudget()" class="btn btn-outline-primary">
                            Select
                          </button>
                          &nbsp;&nbsp;
                        </div>
                        <div class="col-5">
                          {{ bud_obj["project_path_desc"] }}
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Product :</h6>
                        </div>
                        <div class="col-1">
                          <button (click)="selectProduct()" class="btn btn-outline-primary">
                            Select
                          </button>
                          &nbsp;&nbsp;
                        </div>
                        <div class="col-5">
                          {{ prod_obj["project_path_desc"] }}
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Offer code : <span style="color: red"> *</span></h6>
                        </div>
                        <div class="col-3">
                          <input type="text" placeholder="Enter Offer Code"
                            oninput="javascript: if (this.value.includes('/')) this.value = this.value.replace('/','');"
                            [(ngModel)]="Obj['auction_cd']" class="form-control" />
                        </div>
                        <div class="col-3"></div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Offer Name : <span style="color: red"> *</span></h6>
                        </div>
                        <div class="col-3">
                          <input type="text" placeholder="Enter Offer Name"
                            oninput="javascript: if (this.value.includes('/')) this.value = this.value.replace('/','');"
                            [(ngModel)]="Obj['auction_name']" class="form-control" />
                        </div>
                        <div class="col-3"></div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>
                            Offer Description : <span style="color: red"> *</span>
                          </h6>
                        </div>
                        <div class="col-3">
                          <textarea style="width: 100%; height: 80px" id="re" name="re"
                            oninput="javascript: if (this.value.includes('/')) this.value = this.value.replace('/','');"
                            [(ngModel)]="Obj['auction_desc']" placeholder="Enter Offer Description"
                            class="form-control"></textarea>
                        </div>
                        <div class="col-3"></div>
                      </div>
                      <br />
                      <!-- {{Obj['doc_ids'] | json }} -->
                      <div class="row my-3">
                        <table *ngIf="Obj['doc_ids'].length" class="table table-bordered"
                          style="width: 80%; margin-left: 10%">
                          <thead>
                            <tr>
                              <th>Sr.no</th>
                              <th>Doc Type</th>
                              <th>Doc Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of Obj['doc_ids']; let j = index">
                              <td>{{ j + 1 }}</td>
                              <td>{{ item["type"] }}</td>
                              <td>{{ item["name"] }}</td>
                              <td>
                                <button class="btn btn-danger" (click)="removeDoc(j)">
                                  Delete
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <ng-container *ngFor="let _ of uploadDocLength">
                        <div class="row p-2 mt-3">
                          <div class="col-3"></div>
                          <div class="col-3">
                            <ng-select [(ngModel)]="currentDocType" placeholder="Select Doc Type">
                              <ng-option *ngFor="let item of mainService.codeValueTechObj['PROP0025']"
                                [value]="item.code">
                                {{ item.value }}
                              </ng-option>
                            </ng-select>
                          </div>
                          <div class="col-3">
                            <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                              class="form-control" style="border-color: black"
                              (change)="onFileUpload($event, file.files)" />
                          </div>
                          <div class="col-3">
                            <button (click)="upload()" class="btn btn-outline-primary">
                              Upload
                            </button>
                          </div>
                        </div>
                      </ng-container>

                      <!-- <div class="row" *ngIf="showAddDocButton" >
                                            <div class="col-10 text-right">
                                                <button (click)="uploadDocLengthInc()" class="btn btn-outline-primary" >Add Doc</button>
                                            </div>
                                        </div> -->
                      <br />
                    </div>
                    <br />
                    <br />
                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperNext>
                          Next
                        </button>
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [stepControl]="second" label="Properties">
                    <div class="card">
                      <br />
                      <h6 style="color: red" class="text-center">
                        Selected Property
                      </h6>
                      <div class="row text-center" *ngIf="property_group_data.length">
                        <table class="table table-bordered" style="width: 80%; margin-left: 10%">
                          <thead>
                            <tr>
                              <th>Property Number</th>
                              <th>Property Type</th>
                              <th>Area</th>
                              <th>Unit</th>
                              <th>Emd Type</th>
                              <th>EMD Amount</th>
                              <th>Quota</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let item of property_group_data;let i = index">
                              <tr *ngFor="let property of item.property_arr; let j = index
">
                                <td>
                                  {{ property["assigned_property_number"] }}
                                </td>
                                <td>{{ property["property_type"] }}</td>
                                <td>{{ property["area"] }}</td>
                                <td>{{ property["unit"] }}</td>
                                <td>{{ property["emd_amt_type"] }}</td>
                                <td>{{ property["emd_amt"] }}</td>
                                <td>{{ property["quota"] }}</td>
                                <td>
                                  <button class="btn btn-danger" (click)="removeProperty(i, j)">
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Location Hierarchy :</h6>
                        </div>
                        <div class="col-3">
                          <ng-select [items]="first_list" bindLabel="hier_buss_name" bindValue="hier_type_id"
                            [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                            [selectableGroup]="true" (change)="getAllLevls()" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                        </div>
                      </div>
                      <br />
                      <div class="row" *ngFor="let item of data; let i = index">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Level {{ i + 1 }} :</h6>
                        </div>
                        <div class="col-3">
                          <ng-select *ngIf="i == 0" [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                            [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl1_cd']"
                            (change)="set_layout_data(item, i)" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                            [multiple]="false" (change)="set_layout_data(item, i)" placeholder="Select..."
                            [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 2" [items]="level3" (change)="set_layout_data(item, i)"
                            bindLabel="lvl3_value" bindValue="lvl3_cd" [multiple]="false" placeholder="Select..."
                            [(ngModel)]="Obj['lvl3_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 3" [items]="level4" (change)="set_layout_data(item, i)"
                            bindLabel="lvl4_value" bindValue="lvl4_cd" [multiple]="false" placeholder="Select..."
                            [(ngModel)]="Obj['lvl4_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 4" [items]="level5" (change)="set_layout_data(item, i)"
                            bindLabel="lvl5_value" bindValue="lvl5_cd" [multiple]="false" placeholder="Select..."
                            [(ngModel)]="Obj['lvl5_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 5" [items]="level6" (change)="set_layout_data(item, i)"
                            bindLabel="lvl6_value" bindValue="lvl6_cd" [multiple]="false" placeholder="Select..."
                            [(ngModel)]="Obj['lvl6_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                          <ng-select *ngIf="i == 6" [items]="level7" (change)="set_layout_data(item, i)"
                            bindLabel="lvl7_value" bindValue="lvl7_cd" [multiple]="false" placeholder="Select..."
                            [(ngModel)]="Obj['lvl7_cd']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                        </div>
                        <div class="col-3" *ngIf="i == data.length - 1">
                          <button (click)="add_dropdown(item, i)" class="btn btn-outline-primary">
                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                          </button>
                          &nbsp;&nbsp;
                          <button (click)="remove_dropdown(item, i)" *ngIf="i != 0" class="btn btn-outline-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>Select Plan Layout:</h6>
                        </div>
                        <div class="col-3">
                          <ng-select [items]="plan_layout" bindLabel="desc" bindValue="property_group_id"
                            (change)="getAllProType()" placeholder="Select Plan Layout" [(ngModel)]="propObj['id']"
                            [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="false"
                            name="chart_of_account0">
                          </ng-select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3">
                          <h6>
                            Property Type : <span style="color: red"> *</span>
                          </h6>
                        </div>
                        <div class="col-3">
                          <ng-select [(ngModel)]="propObj['property_type']" placeholder="Select Property Type">
                            <ng-option *ngFor="let item of allProTypeAvailable" [value]="item.property_type">
                              {{ item.property_type }}
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-12 text-center">
                          <button class="btn btn-outline-success" (click)="show_proprties()">
                            Show Propeties
                          </button>
                        </div>
                      </div>
                      <br />
                      <div class="row align-items-end">
                        <div class="col-8"></div>
                        <div class="col-2">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="selectall"
                              (click)="selectAll()" />
                            <label class="form-check-label" for="selectall">
                              Select all
                            </label>
                          </div>
                        </div>
                      </div>
                      <!-- samarjeet uncommnet this -->
                      <div class="example-container" style="width: 80%; margin-left: 10%">
                        <table mat-table [dataSource]="datasource2" matSort>
                          <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              Action
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                              <input type="checkbox" style="width: 20px; height: 20px"
                                [(ngModel)]="element['is_checked']" (click)="chooseProperty(element)" />
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="assigned_property_number">
                            <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                              Property number
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                              {{ element.assigned_property_number }}
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="quota">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              quota
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              <ng-select [(ngModel)]="element['quota']" [disabled]="!element['is_checked']"
                                placeholder="Select  Quota">
                                <ng-option *ngFor="
                                                                    let item of mainService.codeValueTechObj[
                                                                        'PROP0019'
                                                                    ]
                                                                    " [value]="item.code">
                                  {{ item.value }}
                                </ng-option>
                              </ng-select>

                              <!-- <div class="row no-gutters" style="width: 318px;" >
                                                            <div class="col no-gutters">
                                                                <select [disabled]="!element['is_checked']" class="form-control form-control-lg"  style="width: 250px;" >
                                                                    <ng-container *ngFor="let item of mainService.codeValueTechObj['PROP0019']" >
                                                                        <option value="item.code" style="width: 250px;">
                                                                                {{item.value}}
                                                                        </option>
                                                                    </ng-container>
                                                                </select>
                                                            </div>
                                                        </div> -->
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="property_type">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              Property Type
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.property_type }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              Area
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.area }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="area">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              Unit
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.unit }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="emd_type">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              EMD Type
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              <ng-select [disabled]="!element['is_checked']" [(ngModel)]="element['emd_amt_type']"
                                placeholder="EMD Type">
                                <ng-option value="fixed">Fixed</ng-option>
                                <ng-option value="percentage">Percentage</ng-option>
                              </ng-select>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="emd_amount">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                              EMD
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              <input type="number" class="form-control" [disabled]="!element['is_checked']"
                                [(ngModel)]="element['emd_amt']" />
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                        </mat-paginator>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-12 text-center">
                          <button class="btn btn-success" (click)="save_property()">
                            Save
                          </button>
                        </div>
                      </div>
                      <br />
                    </div>

                    <br />
                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>
                          {{
                          auth.allLableShowObjProp[
                          auth.language_cd + "property34"
                          ]
                          }}
                        </button>
                        &nbsp;&nbsp;
                        <!--Back-->
                        <button mat-button mat-raised-button class="btn btn-outline-primary" (click)="save_At_step2()"
                          matStepperNext>
                          {{
                          auth.allLableShowObjProp[
                          auth.language_cd + "property35"
                          ]
                          }}
                        </button>
                        <!--Next-->
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [stepControl]="third" label="Auction Scheduling Costing">
                    <!--Property Details-->
                    <div class="row mt-2">
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Application Start Date :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="date" class="form-control" [(ngModel)]="Obj['application_start_date']" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 mt-2">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Application End Date:</h6>
                          </div>
                          <div class="col text-left">
                            <input type="date" class="form-control" [(ngModel)]="Obj['application_end_date']" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Auction Start Date :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="date" class="form-control" [(ngModel)]="Obj['auction_start_date']" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Auction End Date:</h6>
                          </div>
                          <div class="col text-left">
                            <input type="date" class="form-control" [(ngModel)]="Obj['auction_end_date']" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                    <!-- auction_start_time -->
                    <div class="row mt-2">
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Bidding Start Time :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="time" class="form-control" [(ngModel)]="Obj['auction_start_time']" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Bidding End Time :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="time" class="form-control" [(ngModel)]="Obj['auction_end_time']" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Time Extension(In minutes) :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="number" class="form-control" [(ngModel)]="Obj['auction_time_extension']" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>No Of Extension :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="number" class="form-control" [(ngModel)]="Obj['auction_max_extension']" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 col-md-6">
                        <div class="row">
                          <div class="col text-right">
                            <h6>Begin Extension Befor Ending(In Minutes) :</h6>
                          </div>
                          <div class="col text-left">
                            <input type="number" class="form-control" [(ngModel)]="Obj['ext_within']" />
                          </div>
                        </div>
                      </div>

                    </div>

                    <br />

                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>
                          {{auth.allLableShowObjProp[auth.language_cd + "property34"]}}
                        </button>
                        &nbsp;
                        <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperNext>
                          Next
                        </button>
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [stepControl]="fourth" label="Payment Option">
                    <br />
                    <div class="card">
                      <ng-container *ngIf="paymentDiscriptionList.length">
                        <br />
                        <table class="table table-bordered" style="width: 80%; margin-left: 10%">
                          <thead>
                            <tr>
                              <th>Details</th>
                              <th>Mode</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of paymentDiscriptionList;let i = index">
                              <td>{{ item["description"] }}</td>
                              <td>{{ item["mode_of_payment"] }}</td>
                              <td>
                                <button (click)="remove_paymentDiscription_item(item, i)"
                                  class="btn btn-outline-danger">
                                  Remove
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <br />
                      <div class="row">
                        <div class="col-6 text-right">
                          <h6>
                            Select Mode Of Payment :<span style="color: red">
                              *</span>
                          </h6>
                        </div>
                        <div class="col-3">
                          <ng-select [items]="modeOfPayment" bindLabel="id" bindValue="id" [multiple]="false"
                            placeholder="Select Required Document" (change)="paymentOptionData()"
                            [(ngModel)]="Obj['payment_option']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true" name="chart_of_account0">
                          </ng-select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-6 text-right">
                          <h6 class="title">
                            Select Payment Options that you would like to include
                            in the offer :<span style="color: red"> *</span>
                          </h6>
                        </div>
                        <div class="col-3">
                          <ng-select [items]="paymentOptionList" bindLabel="frequency_of_installment"
                            bindValue="pay_option_id" [multiple]="false" placeholder="Select Payment Option"
                            [(ngModel)]="paymentOptionObj['option_list']" [selectableGroup]="true"
                            [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                            <ng-template ng-label-tmp let-item="item">
                              <span (click)="checkInPaymentDescriptionList(item)">
                                {{ item.mode_of_payment }}-
                                {{item.range_start_from}}-
                                {{ item.range_end_at }}(%)
                              </span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                              <span (click)="checkInPaymentDescriptionList(item)">
                                {{ item.mode_of_payment }}-
                                {{item.range_start_from}}-
                                {{ item.range_end_at }}(%)
                              </span>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
                      <br />
                      <div class="row mt-5" style="width: 80%; margin-left: 10%"
                        *ngFor="let data of admindata; let i = index">
                        <div class="col-12 text-center">
                          <h5 class="text-uppercase font-weight-bold">
                            {{ data.gateway_name }}
                          </h5>
                        </div>
                        <div class="col-12 ml-3">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="row">#</th>
                                <th scope="col">Purpose</th>
                                <th scope="col">Account number</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let gatway of data['list']; let j = index">
                                <th scope="row" class="text-left">{{ i + 1 }}</th>
                                <td class="text-left">{{ gatway.purpose }}</td>
                                <td class="text-left">{{ gatway.account_no }}</td>
                                <td class="text-left">
                                  <input type="checkbox" [checked]="admindata[i]['list'][j].selected"
                                    (change)="clickingcheckbox(i, gatway.id)" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <br />
                    </div>
                    <br />
                    <br />
                    <div class="row">
                      <div class="col-12 text-center">
                        <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>
                          {{
                          auth.allLableShowObjProp[
                          auth.language_cd + "property34"
                          ]
                          }}
                        </button>
                        &nbsp;
                        <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperNext>
                          {{
                          auth.allLableShowObjProp[
                          auth.language_cd + "property18"
                          ]
                          }}
                        </button>
                        <!--Next-->
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [stepControl]="fifth" label="Term And Condition">
                    <br />
                    <div class="card">
                      <div class="row">
                        <div class="col-12">
                          <h5 class="text-centerm my-3">
                            Select Term And Condition
                          </h5>
                        </div>
                        <!-- {{tAndcListDB | json}} -->
                        <table class="table table-bordered">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>Sr. No.</th>
                              <th>T&C Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of tAndcListDB; let i = index">
                              <td>
                                <input type="checkbox" [checked]="item.selected" class="text-left"
                                  (change)="selectTandC(i, item.id)" />
                              </td>
                              <th scope="row">{{ i + 1 }}.</th>
                              <td>{{ item.tc }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row">
                        <div class="col-12 text-center">
                          <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>
                            {{
                            auth.allLableShowObjProp[
                            auth.language_cd + "property34"
                            ]
                            }}
                          </button>
                          &nbsp;
                          <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperNext>
                            {{
                            auth.allLableShowObjProp[
                            auth.language_cd + "property18"
                            ]
                            }}
                          </button>
                          <!--Next-->
                        </div>
                      </div>
                    </div>
                  </mat-step>
                  <mat-step [stepControl]="sixth" label="done">
                    <br />
                    <div class="container-fluid">
                      <div class="card">
                        <div class="card-header g-0">
                          <h5 class="text-center">Offer Details</h5>
                        </div>

                        <div class="card-body">
                          <div class="row">
                            <div class="col-12 g-9">
                              <div class="row g-0">
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">Offer Code</h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj["auction_cd"] }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <!-- <div class="row g-0">
                                                                    <div class="col-5 g-0">
                                                                        <h6 class="text-left">
                                                                            Basic Bid Price
                                                                        </h6>
                                                                    </div>:
                                                                    <div class="col-6 g-0 ">
                                                                        {{Obj['auction_cd']}}
                                                                    </div>
                                                                </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-12 g-9">
                              <div class="row g-0">
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">Offer Name</h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj["auction_name"] }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">
                                        Application Start date
                                      </h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj["auction_start_time"] }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-12 g-9">
                              <div class="row g-0">
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">
                                        Application End Date :
                                      </h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj["application_end_date"] }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">
                                        Auction Start date
                                      </h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      :{{ Obj["auction_start_time"] }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-12 g-9">
                              <div class="row g-0">
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">Auction End Date</h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj["auction_end_time"] }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">
                                        TIME EXTENSION(MIN)
                                      </h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj["auction_time_extension"] }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-12 g-9">
                              <div class="row g-0">
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">NO OF EXTENSION</h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj["auction_max_extension"] }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">View Document</h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj["auction_cd"] }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-12 g-9">
                              <div class="row g-0">
                                <div class="col-12 col-md-6 g-0">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">Offer Code</h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj["auction_cd"] }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="row g-0">
                                    <div class="col-5 g-0">
                                      <h6 class="text-left">Offer Code</h6>
                                    </div>
                                    :
                                    <div class="col-6 g-0">
                                      {{ Obj["auction_cd"] }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-5">
                            <div class="col-12">
                              <h5 class="text-center">Property Details</h5>
                            </div>
                            <div class="col-12 g-0 text-center">
                              <div class="row text-center" *ngIf="property_group_data.length">
                                <table class="table table-bordered" style="width: 80%; margin-left: 10%">
                                  <thead>
                                    <tr>
                                      <th>Property Number</th>
                                      <th>Property Type</th>
                                      <th>Area</th>
                                      <th>Unit</th>
                                      <th>Emd Type</th>
                                      <th>EMD Amount</th>
                                      <th>Quota</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <ng-container *ngFor="
                                                                            let item of property_group_data;
                                                                            let i = index
                                                                            ">
                                      <tr *ngFor="
                                                                                let property of item.property_arr;
                                                                                let j = index
                                                                            ">
                                        <td>{{ property["property_no"] }}</td>
                                        <td>{{ property["property_type"] }}</td>
                                        <td>{{ property["area"] }}</td>
                                        <td>{{ property["unit"] }}</td>
                                        <td>{{ property["emd_type"] }}</td>
                                        <td>{{ property["emd_amount"] }}</td>
                                        <td>{{ property["quota"] }}</td>
                                        <td>
                                          <button class="btn btn-danger" (click)="removeProperty(i, j)">
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    </ng-container>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="row"></div>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div class="col-12 text-center">
                      <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>
                        {{
                        auth.allLableShowObjProp[
                        auth.language_cd + "property47"
                        ]
                        }}
                      </button>
                      &nbsp;&nbsp;
                      <button class="btn btn-outline-success" (click)="save_update()">
                        {{
                        auth.allLableShowObjProp[
                        auth.language_cd + "property48"
                        ]
                        }}</button>&nbsp;&nbsp;
                    </div>
                  </mat-step>
                </mat-horizontal-stepper>
              </div>
              <!-- samarjeet -->
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner>{{
    auth.allLableShowObjProp[auth.language_cd + "property82"]
    }}</ngx-spinner>
  <div class="modal fade bd-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content" style="background-color: white">
        <div class="modal-header">
          <h6 class="modal-title">Property Information :</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <table class="table table-bordered" style="width: 80%; margin: 10%">
          <thead>
            <tr>
              <th>Property Type</th>
              <th>Property Number</th>
              <th>Area</th>
              <th>Unit</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of property_group_check_data; let i = index">
              <td>{{ item["property_type"] }}</td>
              <td>{{ item["property_no"] }}</td>
              <td>{{ item["area"] }}</td>
              <td>{{ item["unit"] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer" style="background-color: white">
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>

  <div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Select Project</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row" *ngFor="let item of project_arr; let i = index">
                <div class="col-3"></div>
                <div class="col-3">
                  <h6>Select Level {{ i + 1 }} Node:</h6>
                </div>
                <div class="col-3">
                  <ng-select *ngIf="i == 0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl1_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl2_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl3_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl4_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl5_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl6_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl7_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                </div>
                <div class="col-3" *ngIf="i == project_arr.length - 1">
                  <button (click)="add_dropdown2(item, i)" class="btn btn-outline-primary">
                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                  </button>
                  &nbsp;&nbsp;
                  <button (click)="remove_dropdown2(item, i)" *ngIf="i != 0" class="btn btn-outline-danger">
                    <i class="fa fa-arrow-up" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-3"></div>
            <div class="col-3">
              <h6 style="margin-left: 2%">
                Selected Path Description : <span style="color: red">*</span>
                <!-- Current Node Path : -->
              </h6>
            </div>
            <div class="col-6">
              <ng-container *ngIf="project_obj">
                {{ project_obj["project_path_desc"] }}
              </ng-container>
            </div>
          </div>
          <br />

          <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->

          <!-- Modal footer -->
          <div class="modal-footer">
            <button class="btn btn-primary" (click)="submit_prj_hier()">
              Submit
            </button>
            <button type="button" class="btn btn-danger" (click)="submit_prj_hier()" data-dismiss="modal">
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="ACTHIER_act">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Select Activity</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row" *ngFor="let item of act_arr; let i = index">
                <div class="col-3">
                  <!-- <h6>Select Node :</h6> -->
                </div>
                <div class="col-3">
                  <h6>Select Level {{ i + 1 }} Node:</h6>
                </div>
                <div class="col-3">
                  <ng-select *ngIf="i == 0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl1_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_act(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl2_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_act(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl3_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_act(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl4_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_act(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl5_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_act(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl6_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_act(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl7_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_act(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                </div>
                <div class="col-3" *ngIf="i == act_arr.length - 1">
                  <button (click)="add_dropdown2_act(item, i)" class="btn btn-outline-primary">
                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                  </button>
                  &nbsp;&nbsp;
                  <button (click)="remove_dropdown2_act(item, i)" *ngIf="i != 0" class="btn btn-outline-danger">
                    <i class="fa fa-arrow-up" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-3"></div>
            <div class="col-3">
              <h6 style="margin-left: 2%">
                Selected Path Description : <span style="color: red">*</span>
                <!-- Current Node Path : -->
              </h6>
            </div>
            <div class="col-6">
              {{ act_obj["project_path_desc"] }}
            </div>
          </div>
          <br />

          <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->

          <!-- Modal footer -->
          <div class="modal-footer">
            <button class="btn btn-primary" (click)="submit_prj_hier_act()">
              Submit
            </button>
            <button type="button" class="btn btn-danger" (click)="submit_prj_hier_act()" data-dismiss="modal">
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- \!/ understood very well and defined -->
  <div class="modal" id="ACTHIER_bud">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Select Budget</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row" *ngFor="let item of bud_arr; let i = index">
                <div class="col-3">
                  <!-- <h6>Select Node :</h6> -->
                </div>
                <div class="col-3">
                  <h6>Select Level {{ i + 1 }} Node:</h6>
                </div>
                <div class="col-3">
                  <ng-select *ngIf="i == 0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl1_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_bud(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl2_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_bud(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl3_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_bud(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl4_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_bud(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl5_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_bud(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl6_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_bud(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl7_cd']" [selectableGroup]="true"
                    (change)="set_Path_desc_bud(i)" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0">
                  </ng-select>
                </div>
                <div class="col-3" *ngIf="i == bud_arr.length - 1">
                  <button (click)="add_dropdown2_bud(item, i)" class="btn btn-outline-primary">
                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                  </button>
                  &nbsp;&nbsp;
                  <button (click)="remove_dropdown2_bud(item, i)" *ngIf="i != 0" class="btn btn-outline-danger">
                    <i class="fa fa-arrow-up" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-3"></div>
            <div class="col-3">
              <h6 style="margin-left: 2%">
                Selected Path Description : <span style="color: red">*</span>
                <!-- Current Node Path : -->
              </h6>
            </div>
            <div class="col-6">
              {{ bud_obj["project_path_desc"] }}
            </div>
          </div>
          <br />

          <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->

          <!-- Modal footer -->
          <div class="modal-footer">
            <button class="btn btn-primary" (click)="submit_prj_hier_bud()">
              Submit
            </button>
            <button type="button" class="btn btn-danger" (click)="submit_prj_hier_bud()" data-dismiss="modal">
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- \!/ anylysis done -->
  <div class="modal" id="ACTHIER_prod">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Select Product</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row" *ngFor="let item of prod_arr; let i = index">
                <div class="col-3">
                  <!-- <h6>Select Node :</h6> -->
                </div>
                <div class="col-3">
                  <h6>Select Level {{ i + 1 }} Node:</h6>
                </div>
                <div class="col-3">
                  <ng-select *ngIf="i == 0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl1_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl2_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl3_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl4_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl5_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl6_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                  <ng-select *ngIf="i == 6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl7_cd']"
                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)" [selectableGroupAsModel]="true"
                    [closeOnSelect]="true" name="chart_of_account0">
                  </ng-select>
                </div>
                <div class="col-3" *ngIf="i == prod_arr.length - 1">
                  <button (click)="add_dropdown2_prod(item, i)" class="btn btn-outline-primary">
                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                  </button>
                  &nbsp;&nbsp;
                  <button (click)="remove_dropdown2_prod(item, i)" *ngIf="i != 0" class="btn btn-outline-danger">
                    <i class="fa fa-arrow-up" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-3"></div>
            <div class="col-3">
              <h6 style="margin-left: 2%">
                Selected Path Description : <span style="color: red">*</span>
                <!-- Current Node Path : -->
              </h6>
            </div>
            <div class="col-6">
              {{ prod_obj["project_path_desc"] }}
            </div>
          </div>
          <br />

          <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->

          <!-- Modal footer -->
          <div class="modal-footer">
            <button class="btn btn-primary" (click)="submit_prj_hier_prod()">
              Submit
            </button>
            <button type="button" class="btn btn-danger" (click)="submit_prj_hier_prod()" data-dismiss="modal">
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title"><!-- Status -->Status</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <div class="container">
            <a *ngFor="let ob of statusArr; let i = index">
              <!-- <h6>{{ob['status']}}</h6> -->
              <div class="step completed" *ngIf="ob.status == 'APPROVED'">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="barblue" style="color: blue; margin-left: 40%" *ngIf="i < statusArr.length - 1"></div>
                </div>

                <div class="content">
                  {{ i + 1 }} - {{ ob.role_cd }} - {{ ob.name }} - {{ ob.status }}
                </div>
              </div>
              <div class="step" *ngIf="
                ob.status == 'REJECTED' ||
                ob.status == 'REVOKED' ||
                ob.status == 'SYSTEM REJECTED'
              ">
                <div class="v-stepper">
                  <div class="circles"></div>
                </div>

                <div class="content">
                  {{ i + 1 }} - {{ ob.role_cd }} - {{ ob.name }} - {{ ob.status }}
                </div>
              </div>
              <!-- active -->
              <div class="step" *ngIf="ob.status == 'UNDERAPPROVAL'">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="bar" style="color: black; margin-left: 40%" *ngIf="i < statusArr.length - 1"></div>
                </div>

                <div class="content">
                  {{ i + 1 }} - {{ ob.role_cd }} - {{ ob.name }} - {{ ob.status }}

                  <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                </div>
              </div>
              <div class="step" *ngIf="ob.status == 'PENDING'">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="bar" style="color: black; margin-left: 40%" *ngIf="i < statusArr.length - 1"></div>
                </div>

                <div class="content">
                  {{ i + 1 }} - {{ ob.role_cd }} - {{ ob.name }} - {{ ob.status }}

                  <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                </div>
              </div>
            </a>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">
              <!-- Close -->Close
            </button>
            <!-- <button type="button" class="btn btn-success" data-dismiss="modal" (click)="certificate()">Download
                        Certificate </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Approval</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <div class="row" *ngIf="ApprovalUserService.vendorflag == false">
            <div class="col-4 text-right">
              <h6>Select Forwarded To :</h6>
            </div>
            <div class="col-4">
              <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true"
                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
              </ng-select>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-4 text-right">
              <h6>Remark :</h6>
            </div>
            <div class="col-4">
              <textarea style="width: 100%; height: 80px" id="re" name="re" [(ngModel)]="appr_remark"
                placeholder="Enter Your Remark" class="form-control"></textarea>
            </div>
          </div>
          <br />

          <div>
            <table class="table table-bordered" style="
              width: 90%;
              margin-left: 5%;
              overflow-x: scroll;
              scroll-margin-block: 1;
            ">
              <thead>
                <tr style="background-color: white">
                  <th style="width: 20%">S No.</th>
                  <th style="width: 20%">Decription</th>

                  <th style="width: 60%">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ln of undertakings; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ ln["desc"] }}</td>
                  <td style="width: 60%">
                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green" />
                    <!--  <button class="btn btn-success" (click)="deleteLine(i)"><i class="fa fa-check"
                                        style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button>
                                <button class="btn btn-danger" (click)="copy(ln)">
                                     <i class="fa fa-times"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i>

                                        </button> -->
                    <!-- <button class="btn btn-warning" (click)="paste(i)"> -->
                    <!-- <i class="fas fa-paste"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->

                    <!-- </button>  -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div class="row" style="border: black">
            <div class="col-4 text-right">
              <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike" />
            </div>
            <div class="col-4">Notify Them</div>
          </div>
          <br />
          <div class="row" *ngIf="ApprovalUserService.vendorflag == false">
            <div class="col-12 text-center">
              <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">
                Submit
              </button>
            </div>
          </div>
          <br />
          <!-- <div *ngIf="vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <h6>OR</h6>
                    </div>
                </div> -->
          <div *ngIf="ApprovalUserService.vendorflag == true" class="row">
            <div class="col-12 text-center">
              <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">
                Forward To Vendor
              </button>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="viewDetails">
    <div class="modal-dialog modal-lg" style="max-width: 80%">
      <div class="modal-content rounded-50">
        <!-- Modal Header -->
        <div class="modal-header bg-primary">
          <h4 class="modal-title text-white" style="font: bold">
            {{ offerDetails["mode_of_sale"] }} Details :
          </h4>
          <button type="button" class="close text-white" data-dismiss="modal">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body" style="border-radius: 10px">
          <div class="card" style="border: 2px solid rgb(24, 24, 97)">
            <div style="margin: 2%">
              <div class="content-container">
                <div class="content">
                  <div class="left-content">
                    <h5 style="width: 50%"><b>Auction Code :</b></h5>
                    <h5 style="width: 50%">{{ offerDetails["auction_cd"] }}</h5>
                  </div>
                  <div class="right-content">
                    <h5 style="width: 50%"><b>Auction Name :</b></h5>
                    <h5 style="width: 50%">{{ offerDetails["auction_name"] }}</h5>
                  </div>
                </div>
                <div class="content mt-2">
                  <div class="left-content">
                    <h5 style="width: 50%"><b>Total No of Property :</b></h5>
                    <h5 style="width: 50%">
                      {{ offerDetails["no_property"] }}
                    </h5>
                  </div>
                  <div class="right-content">
                    <h5 style="width: 50%"><b>Auction Description :</b></h5>
                    <h5 style="width: 50%">{{ offerDetails["auction_desc"] }}</h5>
                  </div>
                </div>
                <div class="content mt-2">
                  <div class="left-content">
                    <h5 style="width: 50%"><b>Auction Start Date :</b></h5>
                    <h5 style="width: 50%">{{ offerDetails["auction_start_dt"] }}</h5>
                  </div>
                  <div class="right-content">
                    <h5 style="width: 50%"><b>Auction End Date :</b></h5>
                    <h5 style="width: 50%">{{ offerDetails["auction_end_dt"] }}</h5>
                  </div>

                </div>
                <div class="content mt-2">
                  <div class="left-content">
                    <h5 style="width: 50%"><b>Application Start Date :</b></h5>
                    <h5 style="width: 50%">
                      {{ offerDetails["application_start_dt"] }}
                    </h5>
                  </div>
                  <div class="right-content">
                    <h5 style="width: 50%"><b>Application End Date :</b></h5>
                    <h5 style="width: 50%">
                      {{ offerDetails["application_end_dt"] }}
                    </h5>
                  </div>
                </div>
                <!-- <div class="content mt-2">
                                <div class="left-content">
                                    <h5 style="width: 50%"><b>Total No of Property :</b></h5>
                                    <h5 style="width: 50%">
                                        {{ offerDetails["no_property"] }}
                                    </h5>
                                </div>
                                <div class="right-content">
                                    <p>Application End Date : <p>
                                    <p>{{offerDetails['application_end_dt']}}<p>
                                </div>
                            </div> -->
              </div>

              <h4 class="text-center mt-3 mb-3">Property Cost</h4>
              <table class="tables1">
                <tr>
                  <th>SNo.</th>
                  <th>Property type</th>
                  <th>Property rate</th>
                  <!-- <th>Unit</th> -->
                  <th>Total Property</th>
                </tr>
                <tr *ngFor="let item of offercost; let i = index">
                  <td>
                    <b>{{ i + 1 }}</b>
                  </td>
                  <td>{{ item.property_type }}</td>
                  <td>{{ item.pro_rate }}</td>
                  <!-- <td>{{item.unit}}</td> -->
                  <td>{{ item.no_of_pro }}</td>
                </tr>
              </table>

              <h4 class="text-center mt-3 mb-3">Document</h4>
              <table class="tables1">
                <tr>
                  <th>SNo.</th>
                  <th>Auction Code</th>
                  <th>Document Type</th>
                  <th>required/optional</th>
                </tr>
                <tr *ngFor="let item of documents; let i = index">
                  <td>
                    <b>{{ i + 1 }}</b>
                  </td>
                  <td>{{ item.auction_cd }}</td>
                  <td>{{ item.doc_type }}</td>
                  <td>{{ item.is_req }}</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <!-- <b>Download Allotment </b><button class="btn btn-outline-primary"
                         (click)="download(data)">Download</button> -->
            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
