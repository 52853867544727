<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Public Portal Configuration</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item" *ngIf="list_screen">
                            <a class="nav-link"  data-toggle="tab" ><i class="fa fa-photo"
                                    aria-hidden="true"> Links List</i>  </a>
                        </li>
                        <li class="nav-item" *ngIf="create_screen">
                            <a class="nav-link"  data-toggle="tab" ><i class="fa fa-pencil-square-o"
                                    aria-hidden="true"></i> Add Links</a>
                        </li>
                    </ul>
                    <br>
                    <div class="tab-content" *ngIf="create_screen">
                        <div class="row">
                            <div class="col-12 text-right">
                                <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                            </div>
                        </div>
                        <div class="row mt-2" *ngFor="let value of link_info ; let i = index">
                            <div class="col-2 text-right">
                                <h6>Select Type<span style="color: red;"> *</span></h6>
                            </div>
                            <div class="col-3">
                                <ng-select [items]="all_links_type" [multiple]="false" [(ngModel)]="value.type"
                                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                </ng-select>
                            </div>
                            <div class="col-2 text-right">
                                <h6>Enter Links<span style="color: red;"> *</span></h6>
                            </div>
                            <div class="col-3 text-right">
                                <input type="text" class="form-control" [(ngModel)]="value.link">
                            </div>
                            <div class="col-1"><button class="btn btn-info" (click)="addMore(i)"><i class="fa fa-plus"
                                        aria-hidden="true"></i>
                                </button> </div>
                            <div class="col-1" *ngIf="i>0">
                                <button class="btn btn-danger" (click)="remove(i)"><i class="fa fa-minus"
                                        aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row text-center mt-5">
                            <div class="col-1"></div>
                            <div class="col-4 ">
                                <h6> About Authority <span style="color: red;"> *</span></h6>
                            </div>

                            <div class="col-5">
                                <textarea rows="5" cols="80" [(ngModel)]="Obj['desc']"></textarea>
                            </div>
                            <div class="col-1"></div>
                        </div>
                        <div class="row text-center mt-3">
                            <div class="col-12">
                                <button class="btn btn-success" (click)="submit()"> Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content" *ngIf="list_screen">
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field style="margin-left: 2%">
                                    <mat-label>Search</mat-label>
                                    <input matInput (keyup)="applyFilter($event)" placeholder="Search here..." #input>
                                </mat-form-field>
                            </div>
                            <div class="col-6 text-right">
                                <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                            </div>
                        </div>


                        <div class="example-container">
                            <table mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="Sno">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No.</th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; " >{{i+1}}</td>
                                </ng-container>

                                <ng-container matColumnDef="link_type">
                                    <th mat-header-cell *matHeaderCellDef  mat-sort-header>Link Type</th>
                                    <td mat-cell flex-stretch *matCellDef="let element" >{{element.link_type}}</td>
                                </ng-container>
                                <ng-container matColumnDef="link">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Link </th>
                                    <td mat-cell flex-stretch *matCellDef="let element">{{element.image_link}}</td>
                                </ng-container>

                                <ng-container matColumnDef="User Id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>User Id</th>
                                    <td mat-cell flex-stretch *matCellDef="let element" >{{element.user_id}}</td>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        <button class="btn btn-outline-primary" (click)="open_update(element)"> Update</button>&nbsp;

                                        <!-- <button class="btn btn-outline-danger" (click)="deleteLink(element)">Delete</button>&nbsp; -->

                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                    <div class="tab-content" *ngIf="update_screen">
                        <div class="row">
                            <div class="col-12 text-right">
                                <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                            </div>
                        </div>
                        <div class="row mt-2" >  <!-- *ngFor="let value of link_info "-->
                            <div class="col-2 text-right">
                                <h6>Select Type<span style="color: red;"> *</span></h6>
                            </div>
                            <div class="col-3">
                                <ng-select [items]="all_links_type" [multiple]="false" [(ngModel)]="Obj2.type"
                                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true" [disabled]="true">
                                </ng-select>
                            </div>
                            <div class="col-2 text-right">
                                <h6>Enter Links<span style="color: red;"> *</span></h6>
                            </div>
                            <div class="col-3 text-right">
                                <input type="text" class="form-control" [(ngModel)]="Obj2.link">
                            </div>
                            <!-- <div class="col-1"><button class="btn btn-info" (click)="addMore()"><i class="fa fa-plus"
                                        aria-hidden="true"></i>
                                </button> </div>
                            <div class="col-1" *ngIf="i>0">
                                <button class="btn btn-danger" (click)="remove(i)"><i class="fa fa-minus"
                                        aria-hidden="true"></i>
                                </button>
                            </div> -->
                        </div>
                        <div class="row text-center mt-5">
                            <div class="col-1"></div>
                            <div class="col-4 ">
                                <h6> About Authority <span style="color: red;"> *</span></h6>
                            </div>

                            <div class="col-5">
                                <textarea rows="5" cols="80" [(ngModel)]="Obj2['desc']"></textarea>
                            </div>
                            <div class="col-1"></div>
                        </div>
                        <div class="row text-center mt-3">
                            <div class="col-12">
                                <button class="btn btn-success" (click)="update()"> Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
