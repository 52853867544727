<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">EMD REFUND</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item" *ngIf="list">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                EMD REFUND LIST</a>
                        </li>

                        <li class="nav-item" *ngIf="details">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"></i>
                                Vendor Details </a>
                        </li>
                        <li class="nav-item" *ngIf="refund_flag">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="fa fa-plus-circle"></i>
                                Refund List</a>
                        </li>

                    </ul>
                    <div class="tab-content">

                        <div *ngIf="list" class="active" id="tab-1">

                            <br>
                            <!-- <br> -->
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="openRefund()">Create
                                        Refund</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select Tender:</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="tender_data" bindLabel="desc" bindValue="tender_id"
                                        [multiple]="false" placeholder="Select Module"
                                        [(ngModel)]="selectedObj['tender_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        (change)="listOfRefundedVendor()">
                                    </ng-select>
                                </div>

                                <div class="col-3"></div>
                            </div>

                            <br>


                            <div class="col-4">
                                <mat-form-field style="margin-left: 2%">
                                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                </mat-form-field>
                            </div>
                            <br>

                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource" matSort class="text-center">

                                    <ng-container matColumnDef="Sr.No">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Sr. No </th>
                                        <td mat-cell *matCellDef="let element let i = index"> {{i+1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="txn_id">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Transation ID </th>
                                        <td mat-cell *matCellDef="let element"> {{element.txn_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pay_for">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Payment Type </th>
                                        <td mat-cell *matCellDef="let element"> {{element.pay_for.split('_').join(' ')}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="amt">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Amount </th>
                                        <td mat-cell *matCellDef="let element"> {{element.total_amt}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pay_mode">
                                        <th mat-header-cell *matHeaderCellDef class="text-center">Payment Mode</th>
                                        <td mat-cell *matCellDef="let element"> {{element.payment_mode}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pay_date">
                                        <th mat-header-cell *matHeaderCellDef>Payment Date </th>
                                        <td mat-cell *matCellDef="let element"> {{element.payment_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bank_ref_no">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Bank Reference Number
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.gtwy_txn_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="challan_no">
                                        <th mat-header-cell *matHeaderCellDef> Challan Number </th>
                                        <td mat-cell *matCellDef="let element"> {{element.challan_no}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pay_status">
                                        <th mat-header-cell *matHeaderCellDef class="text-center">Payment Status</th>
                                        <td mat-cell *matCellDef="let element"> {{element.transation_status}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                                        <td mat-cell *matCellDef="let element" style="width: 20%;">
                                            <button class="btn btn-outline-success" (click)="viewDetails(element, b)">View
                                                Details</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                        </div>

                        <div *ngIf="details" class="active" id="tab-2">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="backtoList()">Back To
                                        List</button>
                                </div>
                            </div>
                            <br>


                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 7rem;">
                                    <h6>Basic Details</h6>
                                </legend>


                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Mobile Number: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['mobile_no']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Email ID : <span class="text-danger">*</span> </h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['email']}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Vender Type : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['vendor_type']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> Category : </h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['category']}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Vender Name : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['vendor_name']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>PAN : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['pan']}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>GSTIN : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['gstin']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> District : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['district']}}
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>State : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['state']}}
                                    </div>
                                </div>
                                <br>
                            </fieldset>
                            <br> <br>

                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 13rem;">
                                    <h6>BANK ACCOUNT DETAILS</h6>
                                </legend>
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>IFSC : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['ifsc']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Bank Name : <span class="text-danger">*</span> </h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['bank_name']}}
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Account Number : </h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['account_no']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> Account Holder Name : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-1 d-flex">
                                        {{vendor_details['account_holder_name']}}
                                    </div>
                                </div>
                                <br>


                            </fieldset>
                            <br> <br>

                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 4px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 10rem;">
                                    <h6>Fee Details </h6>
                                </legend>
                                <br>
                                <div *ngFor="let item of payment_details">
                                    <div class="row" *ngIf="item['pay_for'] == 'DOCUMENT_FEE' ">
                                        <div class="col-3 text-center">
                                            <h6>Document Fee : <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-3"> {{item['total_amt']}}</div>
                                        <div class="col-3 text-center">
                                            <h6>Challan Number : <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-3"> {{item['document_challan_no']}} </div>
                                        <br><br>
                                        <div class="col-3 text-center" text-center>
                                            <h6>Document Fee Status : <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-3">{{item['status']}} </div>
                                        <div class="col-3"></div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row" *ngIf="item['pay_for'] == 'EMD_FEE' ">
                                        <div class="col-3 text-center" text-center>
                                            <h6>EMD Fee : <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-3"> {{item['total_amt']}}</div>
                                        <div class="col-3 text-center">
                                            <h6>Challan Number : <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-3"> {{item['emd_challan_no']}} </div>
                                        <br><br>
                                        <div class="col-3 text-center" text-center>
                                            <h6>EMD Fee Status : <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="col-3">{{item['status']}} </div>
                                        <div class="col-3"></div>
                                        <div class="col-3"></div>
                                    </div>

                                </div>
                            </fieldset>
                            <br><br>
                        </div>

                        <div *ngIf="refund_flag" class="active" id="tab-3">

                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-info" (click)="backtoList()"> Back To List
                                    </button>
                                </div>
                            </div>

                            <br><br>

                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select Tender : <span class="text-danger">*</span></h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="tender_data" bindLabel="desc" bindValue="tender_id"
                                        [multiple]="false" placeholder="Select Module"
                                        [(ngModel)]="selectedObj['tender_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        (change)="getListForRefund()">
                                    </ng-select>
                                </div>

                                <div class="col-3"></div>
                            </div>
                            <br><br>
                            <div class="row">
                                <table class="table table-bordered table-primary">
                                    <tr>
                                        <th *ngFor="let item of displayedColumns2">{{item}}</th>
                                    </tr>
                                    <tr *ngFor="let element of  listOfRefund; let i = index;" >
                                        <td *matCellDef="let element; let i = index; "> <input type="checkbox" [(ngModel)]="element.select_flag"
                                                class="form-control">
                                        </td>
                                        <td>{{element['vendor_id']}}</td>
                                        <td>{{element['vendor_name']}}</td>
                                        <td mat-cell *matCellDef="let element"> {{element['pay_for'].split('_').join(' ')}}</td>
                                        <td> {{element['emd_amount']}}</td>
                                        <td> {{element['bank_name']}}</td>
                                        <td> {{element['ifsc']}}</td>
                                        <td> {{element['account_no']}}</td>
                                        <td> {{element['status']}}</td>
                                        <td> <button class="btn btn-outline-success"
                                                (click)="viewDetails(element,'refund_tab')">View
                                                Details</button></td>
                                    </tr>
                                </table>

                            </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-outline-danger"
                                            (click)="backtoList()">Cancle</button> &nbsp; &nbsp;
                                        <button class="btn btn-outline-primary" (click)="submitRefund()">Submit</button>
                                    </div>
                                </div>
                            <!-- <div class="col-4">
                                <mat-form-field style="margin-left: 2%">
                                    <input matInput (keyup)="applyFilter2($any($event.target).value)" placeholder="Filter">
                                </mat-form-field>
                            </div>
                            <br>
                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource2" matSort class="text-center">

                                    <ng-container matColumnDef="select_flag">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> # </th>
                                        <td mat-cell *matCellDef="let element let i = index"> <input type="checkbox"
                                                [(ngModel)]="element.select_flag" class="form-control"> </td>
                                    </ng-container>

                                    <ng-container matColumnDef="vendor_id">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Vendor ID </th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="vendor_name">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Vendor_name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor_name}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pay_type">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Payment Type </th>
                                        <td mat-cell *matCellDef="let element"> {{element.pay_for.split('_').join(' ')}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emd_amt">
                                        <th mat-header-cell *matHeaderCellDef class="text-center">EMD Amount</th>
                                        <td mat-cell *matCellDef="let element"> {{element.emd_amount}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bank_name">
                                        <th mat-header-cell *matHeaderCellDef>Bank Name</th>
                                        <td mat-cell *matCellDef="let element"> {{element.bank_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="ifsc">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> IFSC
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.ifsc}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="acc_no">
                                        <th mat-header-cell *matHeaderCellDef> Account No. </th>
                                        <td mat-cell *matCellDef="let element"> {{element.account_no}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="assign_status">
                                        <th mat-header-cell *matHeaderCellDef class="text-center">Assignment Status</th>
                                        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                                        <td mat-cell *matCellDef="let element" style="width: 20%;">
                                            <button class="btn btn-outline-success" (click)="viewDetails(element)">View
                                                Details</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div> -->


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
