import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import {MainService } from './main.service'

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private http:HttpClient,private MainService:MainService) { }
  httpUrl = this.MainService.httpUrl
  async getTandC(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/metadata/vendor/getTandC' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addTandC(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/metadata/vendor/addTandC' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateTandC(obj:any) {
    const resp = await this.http.put<any>(this.httpUrl + '/metadata/vendor/updateTandC' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteTandC(obj:any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/metadata/vendor/deleteTandC' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getFee(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/metadata/vendor/getFee' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addFee(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/metadata/vendor/addFee' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateFee(obj:any) {
    const resp = await this.http.put<any>(this.httpUrl + '/metadata/vendor/updateFee' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteFee(obj:any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/metadata/vendor/deleteFee' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}
