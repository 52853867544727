<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment1']}} <!-- Manage Leave Encashment -->
                         </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment2']}}    <!-- Employee Encashment List -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment3']}} <!-- Generate Employee Leave Encashment -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment4']}} <!-- Update Employee Leave Encashment -->
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment5']}}">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>


                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment6']}} <!-- Employee ID -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.emp_id1}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment7']}} <!-- Employee Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="total_el">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment8']}} <!-- Total EL -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.total_el }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="basic">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment9']}} <!-- BASIC -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.basic }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="da">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>DA
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.da }} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment10']}} <!-- Amount -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.amount }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="retirement_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment11']}} <!-- Retirement Date -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{  mainService.dateformatchange(element.retirement_date) }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment12']}}    <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">


                                            <button class="btn btn-primary" (click)="openUpdate(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment13']}}    <!-- Update -->
                                            </button>
                                            <button class="btn btn-danger" (click)="deletefLeaveEncashment(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment14']}}    <!-- Delete -->
                                            </button>
                                            <button class="btn btn-info" (click)="download(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment15']}}    <!-- Download -->
                                            </button>



                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment16']}}<!-- Select Employee : -->: <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_desc" bindValue="emp_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment16']}}" (change)="changeEmployee()" [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment11']}}  <!-- Retirement Date: -->
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="selectEmpObj['tempretirement_date']" disabled>     <!--{{selectEmpObj['tempretirement_date']}}</label>-->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment17']}} <!-- EL Remaining : -->
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="selectEmpObj['total_el']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment18']}}    <!-- Submit -->
                                    </button>

                                </div>
                            </div>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment19']}} <!-- BASIC: -->
                                </div>
                                <div class="col-4">
                                    <label class="form-control"> {{selectEmpObj['basic']}}</label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment20']}} <!-- DA: -->
                                </div>
                                <div class="col-4">
                                    <label class="form-control"> {{selectEmpObj['da']}}</label>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment21']}} <!-- Already Paid: -->
                                </div>
                                <div class="col-4">
                                    <input type="number" (change)="changePaidAmount()" class="form-control" [(ngModel)]="selectEmpObj['paid']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment22']}} <!-- Total Encashment : -->
                                </div>
                                <div class="col-4">
                                    <label class="form-control">  {{selectEmpObj['amount']}}</label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitLeaveEncashment()">{{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment18']}}</button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment16']}} :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_desc" bindValue="emp_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment16']}}" (change)="changeEmployee()" [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment11']}} <!-- Retirement Date: -->
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="this.selectEmpObj['tempretirement_date']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment17']}} <!-- EL Remaining : -->
                                </div>
                                <div class="col-4">
                                  <label class="form-control"> {{selectEmpObj['total_el']}}</label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">{{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment18']}}</button>

                                </div>
                            </div>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment19']}}:
                                </div>
                                <div class="col-4">
                                <label class="form-control"> {{selectEmpObj['basic']}}</label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment20']}}:
                                </div>
                                <div class="col-4">
                                <label class="form-control"> {{selectEmpObj['da']}}</label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment21']}}:
                                </div>
                                <div class="col-4">
                                    <input type="number" (change)="changePaidAmount()" class="form-control" [(ngModel)]="selectEmpObj['paid']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment22']}} :
                                </div>
                                <div class="col-4">
                                    <label class="form-control"> {{selectEmpObj['amount']}}</label>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateLeaveEncashment()">{{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment13']}}</button>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'leave_encashment23']}}</ngx-spinner>
