<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Auction Allotment</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>All Auction Allotment </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"></i> Offline </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                                {{auth.allLableShowObjProp[auth.language_cd+'Allotment4']}}</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab"><i class="fa fa-plus-circle"></i>Allotment Status</a>
                        </li> -->
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Select Offer : </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allAuction" bindLabel="auction_desc" bindValue="auction_cd"
                                    [multiple]="false" placeholder=" Select Offer" (change)="getAllPropType()"
                                    [(ngModel)]="obj['auction_cd']"  [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                    <!--  -->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Select Property Type : </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="auct_propType" bindLabel="pro_type" bindValue="pro_type"
                                     [multiple]="false"  placeholder=" Select Property Type" (change)="getAllAuctionAllotment()"
                                    [(ngModel)]="obj['pro_type']"  [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                </ng-select>
                                <!--  -->
                                <!-- -->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Allotment9']}}">
                                    </mat-form-field>
                                </div>
                                <div class="col-8 text-right">
                                    <button class="btn btn-outline-success"
                                        (click)="export1()">{{auth.allLableShowObjProp[auth.language_cd+'Allotment10']}} Excel</button>
                                    &nbsp;&nbsp;
                                    <button class="btn btn-outline-primary"
                                        (click)="print1()">{{auth.allLableShowObjProp[auth.language_cd+'Allotment11']}}</button>
                                </div>
                            </div>

                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                                    <ng-container matColumnDef="party_id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment12']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_id}} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="arr_effective_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment13']}}</b>
                                        </th>

                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{mainService.dateFormatChange(element.arr_effective_date)}}
                                         </td>
                                    </ng-container>



                                    <ng-container matColumnDef="property_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment15']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.property_type }}
                                        </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="length">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment16']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.length }} </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="measurement_unit">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment17']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.unit }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="property_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment18']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.assigned_property_number }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quota_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment19']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.quota_cd }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="scheme_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Offer code</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.offer_cd }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment25']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.arr_status_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'Allotment26']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button class="btn btn-outline-primary"
                                                *ngIf="element['arr_status_code']=='ALLOTMENT_PENDING'"
                                                (click)="approve(element)">{{auth.allLableShowObjProp[auth.language_cd+'Allotment27']}}</button>
                                            <button class="btn btn-outline-info"
                                                *ngIf="element['arr_status_code']=='ALLOTMENT_PENDING'"
                                                (click)="openUpdate(element)">{{auth.allLableShowObjProp[auth.language_cd+'Allotment28']}}</button>
                                            <button class="btn btn-outline-danger"
                                                *ngIf="element['arr_status_code']=='ALLOTMENT_PENDING'"
                                                (click)="cancel(element)">{{auth.allLableShowObjProp[auth.language_cd+'Allotment29']}}</button>
                                                <button class="btn btn-outline-success"
                                                *ngIf="element['arr_status_code']=='ALLOTED'"
                                                (click)="view_info(element)">View Details</button>
                                                <button class="btn btn-outline-primary"
                                                *ngIf="element['arr_status_code']=='ALLOTED'"
                                                (click)="allotmentPrint(element)">Download</button> -->
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20,30]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Select Auction : </h6>
                                </div>

                                <div class="col-4">
                                    <ng-select [items]="allAuction" bindLabel="auction_desc" bindValue="auction_cd"
                                    [multiple]="false" placeholder=" Select Auction" [(ngModel)]="allotmentObj['auction_cd']"  [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="FetchDetailAuction()"> Fetch </button>
                                </div>
                            </div>
                            <div class="container-fluid mt-5 p-5 border border-5 border-info rounded">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Allotment Date : </h6>
                                            </div>
                                            <div class="col-6">
                                                <input type="date" class="form-control" [(ngModel)]="resObj['allotment_date']" >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Select Property Type : </h6>
                                            </div>
                                            <div class="col-6">
                                                <ng-select
                                                    [multiple]="false" placeholder=" Select property Type"
                                                    [(ngModel)]="resObj['property_type']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">

                                                </ng-select>
                                                <!-- [items]="unique_proType" bindLabel="pro_type" bindValue="pro_type" (change)="filterApplicantUsinProType()"-->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Select Applicant : </h6>
                                            </div>

                                            <div class="col-6">
                                                <ng-select
                                                [multiple]="false" placeholder=" Select Applicant Name"
                                                [(ngModel)]="resObj['id']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                            <!-- [items]="selectedApplicant" bindLabel="party_name" bindValue="id" (change)="setProperty()"-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <!-- applicant -->
                                    <div class="col-md-6">
                                         <!-- property number -->
                                         <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Select Property Number : </h6>
                                            </div>

                                            <div class="col-6">
                                                <!-- selected from propertyArr2 in setProperty function -->
                                                <ng-select
                                                [multiple]="false" placeholder="Select Property Number"
                                                [(ngModel)]="resObj['alloted_property_id']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                            <!-- [items]="selectedPropertyNumber" bindLabel="desc" bindValue="property_id" -->
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Payment mode : </h6>
                                            </div>

                                            <div class="col-6">
                                                <ng-select  [readonly] = true
                                                [multiple]="false" placeholder=" Select Payment"
                                                [(ngModel)]="resObj['mode_of_payment']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                            <!-- [items]="seletedPaymentMethod" bindLabel="payment_option" bindValue="payment_option" (change)="allPaymetOption()"-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="container-fluid mt-5 p-5 border border-2 border-info rounded">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Select Payment Option : </h6>
                                            </div>
                                            <div class="col-6">
                                                <ng-select
                                                [multiple]="false" placeholder=" Select payment option"
                                                [(ngModel)]="resObj['payment_option_id']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                            <!-- [items]="paymentOptionArr" bindLabel="description" bindValue="payment_option_id" (change)="paymentOptionDetail()" -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Period Starts From : </h6>
                                            </div>
                                            <div class="col-6">
                                                <input [(ngModel)]="resObj['period_start_from']"  name="" class="form-control" id="" disabled placeholder="Allotment" >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="resObj['mode_of_payment']=='Installment'">
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>First Lumpsum Amount in ₹: </h6>
                                            </div>

                                            <div class="col-6">
                                                <mat-form-field class="example-full-width" appearance="fill">
                                                    <mat-label>First Lumpsum Amount in ₹</mat-label>
                                                    <input matInput placeholder="Enter lumpsum amount in ₹" type="number" [(ngModel)]="resObj['first_lumsum']">
                                                  </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Within : </h6>
                                            </div>

                                            <div class="col-6">
                                                <mat-form-field class="example-full-width" appearance="fill">
                                                    <mat-label>Enter number of days</mat-label>
                                                    <input matInput placeholder="example: 30 days" type="number" [(ngModel)]="resObj['first_lumsum_day']">
                                                  </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Second Lumpsum Amount in ₹: </h6>
                                            </div>
                                            <div class="col-6">
                                                <mat-form-field class="example-full-width" appearance="fill">
                                                    <mat-label>Second Lumpsum Amount in ₹</mat-label>
                                                    <input matInput placeholder="Enter lumpsum amount in ₹" type="number" [(ngModel)]="resObj['second_lumsum']" >
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>Within : </h6>
                                            </div>

                                            <div class="col-6">
                                                <mat-form-field class="example-full-width" appearance="fill">
                                                    <mat-label>Enter number of days</mat-label>
                                                    <input matInput placeholder="example: 30 days" type="number" [(ngModel)]="resObj['second_lumsum_day']">
                                                  </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="container mt-4">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary" >  <!-- (click)="displayTable()" -->
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="container-fluid mt-5 p-5 border border-2 border-info rounded">
                                <table mat-table [dataSource]="resObjList" class="mat-elevation-z8">


                                    <ng-container matColumnDef="lottery_date">
                                      <th mat-header-cell *matHeaderCellDef> Lottery Date </th>
                                      <td mat-cell *matCellDef="let element"> {{element.lottery_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="allotment_date">
                                      <th mat-header-cell *matHeaderCellDef> Allotment Date </th>
                                      <td mat-cell *matCellDef="let element"> {{element.allotment_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="property_type">
                                      <th mat-header-cell *matHeaderCellDef>Property Type</th>
                                      <td mat-cell *matCellDef="let element"> {{element.property_type}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_name">
                                      <th mat-header-cell *matHeaderCellDef>Applicant</th>
                                      <td mat-cell *matCellDef="let element"> {{element.party_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>Property Number</th>
                                      <td mat-cell *matCellDef="let element"> {{element.property_number}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>Payment method</th>
                                      <td mat-cell *matCellDef="let element"> {{element.payment_method}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>Payment Option</th>
                                      <td mat-cell *matCellDef="let element"> {{element.payment_option}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>First Lumpsum Amount</th>
                                      <td mat-cell *matCellDef="let element"> {{element.first_lumsum}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>First Lumpsum pay days</th>
                                      <td mat-cell *matCellDef="let element"> {{element.first_lumsum_within}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>Second Lumpsum Amount</th>
                                      <td mat-cell *matCellDef="let element"> {{element.second_lumsum}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>Second Lumpsum pay days</th>
                                      <td mat-cell *matCellDef="let element"> {{element.second_lumsum_within}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                  </table>
                            </div> -->


                            <div class="container-fluid mt-5" *ngIf="displayTableFlag">
                                <div class="row">


                                  <table class="table-responsive table-striped table-hover table-bordered">
                                <thead>
                                       <tr>
                                         <th  scope="col" >Lottery Date</th>
                                         <th  scope="col" >Allotment Date</th>
                                         <th  scope="col" >Property Type</th>
                                         <th  scope="col" >Applicant</th>
                                         <th  scope="col" >Property Number</th>
                                         <th  scope="col" >Payment method </th>
                                         <th  scope="col" >Payment Option</th>
                                         <th  scope="col" *ngIf="resObj['mode_of_payment']=='Installment'" >First Lumpsum Amount</th>
                                         <th  scope="col" *ngIf="resObj['mode_of_payment']=='Installment'" >first Lumpsum Amount Within day</th>
                                         <th  scope="col" *ngIf="resObj['mode_of_payment']=='Installment'" >Second Lumpsum Amount</th>
                                         <th  scope="col" *ngIf="resObj['mode_of_payment']=='Installment'" >second Lumpsum Amount Within day</th>
                                       </tr>
                                   </thead>
                                   <tbody>
                                       <tr>
                                           <td>
                                             <h6>
                                               {{displayTableData['lottery_date']}}
                                             </h6>
                                           </td>
                                           <td>
                                               <h6>
                                                   {{displayTableData['allotment_date']}}
                                               </h6>
                                            </td>
                                           <td>
                                            <h6>
                                                {{displayTableData['property_type']}}
                                            </h6>
                                           </td>
                                           <td>
                                              <h6>
                                                  {{resObj['id']}}
                                              </h6>
                                            </td>
                                           <td>
                                            <h6>
                                                {{displayTableData['alloted_property_id']}}
                                            </h6>
                                           </td>
                                           <td>
                                              <h6>
                                                  {{displayTableData['mode_of_payment']}}
                                              </h6>
                                            </td>
                                           <td>
                                              <h6>
                                                  {{displayTableData['description']}}
                                              </h6>
                                           </td>
                                           <td *ngIf="resObj['mode_of_payment']=='Installment'" >
                                                <h6>
                                                    {{displayTableData['first_lumsum']}}
                                                </h6>
                                            </td>
                                           <td *ngIf="resObj['mode_of_payment']=='Installment'" >
                                               <h6>
                                                   {{displayTableData['first_lumsum_day']}}
                                               </h6>
                                           </td>
                                           <td *ngIf="resObj['mode_of_payment']=='Installment'">
                                               <h6>
                                                   {{displayTableData['second_lumsum']}}
                                               </h6>
                                            </td>
                                           <td *ngIf="resObj['mode_of_payment']=='Installment'">
                                               <h6>
                                                   {{displayTableData['second_lumsum_day']}}
                                               </h6>
                                            </td>
                                       </tr>
                                   </tbody>
                                  </table>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary"  > <!-- (click)="addAllotment()" -->
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>

<!-- samarjeet -->

                            <br>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
