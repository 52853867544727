import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root'
})
export class FaqService {


  httpUrl;
  constructor(private http: HttpClient, private main: MainService,
    private spinner: NgxSpinnerService) {
    this.httpUrl = this.main.httpUrl;
  }


  async getfaq(obj:any) {

    const res = await this.http.post(this.httpUrl + '/vms/faq/getfaq', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getFaqDataUsingId(obj:any) {
    const res = await this.http.get(this.httpUrl + '/vms/faq/getFaqDataUsingId'+ obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async addAllfaqs(obj:any) {
    const resp = this.http.post<any>(this.httpUrl + '/vms/faq/addAllFaques', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async updatefaqs(obj:any) {
    const resp = this.http.put<any>(this.httpUrl + '/vms/faq/updatefaqs', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async deletefaqs(obj:any) {
    const resp = this.http.delete<any>(this.httpUrl + '/vms/faq/deletefaqs'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
}
