import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from "@angular/material/sort";
import { MainService } from './../../service/main.service';
import { MainService as mainService_MD } from '../../../md/service/main.service'
import { MainService as AdminMainService } from '../../../admin/service/main.service'
import { MainService as mainserviceEmb } from '../../../emb/service/main.service';
import { AuthenticationService } from '../../../authentication.service';
import { SaleService } from '../../service/sale.service';
import Swal from 'sweetalert2';
import { AdvanceService } from '../../service/advance.service';
// import { element } from 'protractor';
//
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
declare var $: any;
@Component({
  selector: 'app-property-cancellation',
  templateUrl: './property-cancellation.component.html',
  styleUrls: ['./property-cancellation.component.css']
})
export class PropertyCancellationComponent implements OnInit {

  ebillUser:any;
  b_acct_id:any;
  user_id:any
  obj:any = {};
  details1:any;
  details:any;
  allOffer:any;
  allData:any;
  offer_code_to_value:any = {}
  pro_type:any;
  selected_auth:any;

  //flag values//
  body1:boolean = true;
  body2:boolean = false;
  body3:boolean = false;
  body4:boolean = false;
  body5:boolean = false;
  allSearchableEmp = []
  flagdata:any;
  dfemidata:any;
  displayedColumns: string[] = ['select', 'party_id', 'property_type','assigned_property_number', 'party_name', 'notice_no', 'status', 'Action'];
  dataSource: any;
  codevalueData: any

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  cancel_resp: any;
  cancel_resp2: any;
  view_emi: any;
  gn_table: any;
  currentDate: any;
  party: any;
  //




  constructor(  public auth: AuthenticationService, public mainService: MainService, private adminMainService: AdminMainService,private mainServiceMD: mainService_MD, public mainserviceEmb: mainserviceEmb , private spinner : NgxSpinnerService, private sale_service: SaleService, private adv_ser : AdvanceService ) { }

   ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.user_id = this.ebillUser.le_id;
    console.log(this.b_acct_id);
    this.getofferdata()
    this.getAllEmployees()
    console.log()
    // this.codevalueData= this.mainService.codeValueTechObj['PROPDS11'];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  allEmplyees_new: any
  allEmployees: any
  userObj:any = []
  async getAllEmployees() {
    console.log('all emp')
    this.spinner.show()
    var arr: any = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'PROPERTY'
    var   resp : any = await this.mainServiceMD.getuserformodulerolemapping(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp)
      this.spinner.hide()
      arr = resp.data;
      console.log(resp, 'users')
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
      }
      this.allSearchableEmp = arr

    } else {
      this.spinner.hide()
    }
    console.log(this.allEmployees, this.allEmplyees_new, 'employees')
  }

async getofferdata() {
    this.spinner.show()
    this.obj['b_acct_id'] = this.b_acct_id

    var   resp : any = await this.sale_service.getAllOffers(this.obj);
    console.log(resp)

      if (resp['error'] == false) {
        this.allOffer = resp['data']
        console.log(resp['data'], 'fetched data')
        let dummy = []

        console.log(this.allOffer, " before loop ")

        for (let i = 0; i < this.allOffer.length; i++) {
          this.offer_code_to_value[this.allOffer[i]['offer_cd']] = this.allOffer[i]['offer_name']
          this.allOffer[i]['offer_name'] = this.allOffer[i]['offer_cd'] + '-' + this.allOffer[i]['offer_name']
          dummy.push(this.allOffer[i])
          console.log(this.allOffer[i]['offer_cd'])
        }
        console.log(this.allOffer, " after loop ")
       console.log(dummy, "dummy test")
      }
   this.spinner.hide()
 }


async getpropertytype(){
  // let obj :any= {}
   this.obj['b_acct_id'] = this.b_acct_id
   this.obj['offer_cd'] =this.obj['offer_cd']
   console.log(this.obj)
   var resp: any = await this.adv_ser.getpropertytype(JSON.stringify(this.obj));
   //console.log(resp)
   if (resp['error'] == false) {
     this.pro_type = resp['data']
     console.log(this.pro_type, 'fetched property typedata')
   }
}

tempNotice: any;
priviousNoticeData: any;
currentNoticeData: any={};
noticeDataObj: any;

async getCancelltionData(){
        //let obj :any= {}
        this.obj['b_acct_id'] = this.b_acct_id
         this.obj['property_type'] = this.obj['pro_type']
        //  console.log(this.obj)

        this.allData= null
        if(this.obj['offer_cd']){

          var resp1: any = await this.adv_ser.getCancellationParty(JSON.stringify(this.obj));

          if (resp1['error'] == false) {
            this.allData = resp1['data']
            console.log(this.allData);
           // this.dataSource = this.allData[0];

           let data = this.allData[0]
           data = data.filter((ele:any)=>ele.no_of_failed_inst_default<=ele.no_paid_count)
           let test

           this.dfemidata =  this.allData[1];
            test = data.map((ele: any)=>{
              let temp =  this.dfemidata.filter((ele2: any)=>{
                return ele.party_id==ele2.party_id
               })[0]
               return {...ele,status:'Not Generated',notice_no:0,...temp}
             })

           console.log(test)
           this.dataSource = new MatTableDataSource(test);
           this.dataSource.sort = this.sort.toArray()[0];
           this.dataSource.paginator = this.paginator.toArray()[0];

           // this.dataSource.sort = this.sort.toArray()[0];
           // this.dataSource.paginator = this.paginator.toArray()[0];
           // console.log(resp['data'], 'fetched data')
           // console.log(this.allData.length);
          }else{
            Swal.fire('Server Error', resp1['data'], 'error')
          }
        }else{
          Swal.fire('Information','Offer not selected','info')
        }
      }

async flag1(element:any){
        this.flagdata=element
        console.log(this.dfemidata, this.flagdata)
        let data = this.dfemidata.filter((ele: any)=> ele['party_id'] == this.flagdata['party_id']
        )
        console.log(data)

        this.obj['offer_cd'] = element.offer_cd
        this.obj['party_id'] = element.party_id
        this.obj['no_of_pay'] = element.no_of_pay
        this.obj['defualt_method_id'] = element.defualt_method_id

        let resp: any = await this.adv_ser.AvailabledForCancellation(JSON.stringify(this.obj));
        if(resp['error']){
          Swal.fire('Error',resp['data'],'error')
          return
        }
        console.log(resp['data'])
        this.view_emi= resp['data'][3]
        // this.flagdata['emi_amount'] = resp['data'][0]['emi_amount']

        this.body1 = false;
        this.body2 = true;
        this.body3 = false;
        this.body4 = false;
        this.body5 = false;

      }

async flag2(ele: any){
        this.flagdata = ele
        console.log(ele)
        let today = new Date()
        if(ele['no_of_failed_inst_default'] > ele['no_paid_count'] ){
          Swal.fire('Information',"Not Elligble For Notice "+ele['no_paid_count'] + "/"+ele['no_of_failed_inst_default'] , 'info')
          return
        }

        this.obj['notice_no'] = ele.notice_no?undefined:ele.notice_no
        this.obj['offer_cd'] = ele.offer_cd
        this.obj['party_id'] = ele.party_id
        this.obj['no_of_pay'] = ele.no_of_pay
        this.obj['defualt_method_id'] = ele.defualt_method_id

        let resp: any = await this.adv_ser.AvailabledForCancellation(JSON.stringify(this.obj));
        //
        console.log(resp['data'])
        // console.log(data2,'hhhh');
          if (!resp['error']) {
            let data1 = resp['data'][0]
            let data2 = resp['data'][1]
            let data3 = resp['data'][2]
            console.log(resp['data'])
            this.gn_table= resp['data'][3]

            this.noticeDataObj= data1[0]
            this.spinner.hide()
            this.minDate = this.formateDateToUse(today)
            let lst_emi_end_date = new Date(this.noticeDataObj['emi_end_date'])
            if(today>lst_emi_end_date){
              // iske andar check kro
                      this.priviousNoticeData =  data3[0]
                      if(!this.priviousNoticeData){
                        //it is working
                        this.currentNoticeData['notice_no'] = 1
                        this.tempNotice = data2.filter((ele1: any)=>ele1['no_of_notice']==1)[0]
                        let peried = this.tempNotice.notice_period
                        this.currentNoticeData['period'] = peried
                        console.log(peried)
                      }else{
                        //  console.log(req_date,this.priviousNoticeData['send_date'])
                        //  console.log(data2,this.priviousNoticeData)
                        let send_date
                        if(this.priviousNoticeData['send_date']){
                          send_date = new Date(this.priviousNoticeData['send_date'])//send date mil gai
                          console.log(this.priviousNoticeData)
                          this.tempNotice = data2.filter((ele: any)=>ele['no_of_notice']==this.priviousNoticeData['notice_no'])[0] // jo send notice send jui h usko nikal lo
                          let period = this.tempNotice['notice_period']  //us notice notice perio nikal lo
                          console.log(period)
                          send_date.setDate(send_date.getDate() + period); // send_date + period_date
                          console.log(send_date)
                          if(send_date >=today){
                            console.log(this.priviousNoticeData)

                            this.currentNoticeData['notice_no'] = this.priviousNoticeData['notice_no']
                            this.currentNoticeData['period']  = period
                          }
                          else{
                            this.tempNotice =  data2.filter((ele: any)=>ele['no_of_notice']==(this.priviousNoticeData['notice_no']+1))[0]
                            console.log(this.tempNotice)
                            this.currentNoticeData['notice_no'] = this.tempNotice['no_of_notice']
                            this.currentNoticeData['period']  = this.tempNotice['notice_period']
                          }
                        }
                        else{
                          this.tempNotice = data2.filter((ele: any)=>ele['no_of_notice']==this.priviousNoticeData['notice_no'])[0]
                          let period = this.tempNotice['notice_period']

                          this.currentNoticeData['notice_no'] = this.priviousNoticeData['notice_no']
                          this.currentNoticeData['period']  = period

                        }
              }

              this.body1 = false;
              this.body2 = false;
              this.body3 = true;
              this.body4 = false;
              this.body5 = false;
            }else{
              Swal.fire('Information','Not Elligable For Notice','info')
              return
            }
          }else{
               this.spinner.hide()
               Swal.fire('Error',"Server Error",'error')
          }

}



minDate: any;
forUpdate: any
async flag3(ele: any){
  this.flagdata = ele
  if(ele['notice_no']==0){
       Swal.fire('Info','No Notice is genrated','info')
       return
      }
  console.log("printing element data ",ele)
  let obj: any = {}
  obj['offer_cd'] = ele.offer_cd
  obj['b_acct_id'] = this.b_acct_id
  obj['party_id'] = ele.party_id
  obj['no_of_pay'] = ele.no_of_pay
  obj['defualt_method_id'] = ele.defualt_method_id
  console.log(obj)
  // this.spinner.show()
  let resp: any = await this.adv_ser.AvailabledForCancellation(JSON.stringify(obj));
  console.log(resp)
  if(resp['error']){
    Swal.fire('Error',resp['data'],'error')
    return
  }
  console.log(resp)
  this.forUpdate =  resp['data'][2][0]
  this.codevalueData = [{value:"NOTICE_SEND"}]

  console.log(this.minDate)
  // let data1 = resp['data'][0]
  // let data2 = resp['data'][1]
   this.flagdata=ele
   this.body1 = false;
   this.body2 = false;
   this.body3 = false;
   this.body4 = true;
   this.body5 = false;
}
formateDateToUse(obj: any){
  let day = obj.getDate()
  let mon = obj.getMonth()+1
  let year = obj.getFullYear()
  if(mon<10){
     mon = '0'+mon
    }
  if(day<10){
    day = '0'+day
  }
 return year+'-'+mon+'-'+day
}
async updateNotice(){
  let obj : any={}
  console.log(this.forUpdate)
  let data3: any = this.forUpdate
  obj['status'] =  this.obj['status']
  if(!obj['status']){
    Swal.fire("Select Notice",'','info')
    return
  }
  obj['party_notice_id'] =  data3.party_notice_id
  obj['b_acct_id'] = this.b_acct_id
  console.log(obj);
  let resp : any= await this.adv_ser.updateNoticeStatus(obj)
  console.log(resp)
   if(resp['error']){
     Swal.fire('Error','server error','error')
     return
   }else{
     if(resp['data']=='This Notice is Already Updated')
       Swal.fire('Info',resp['data'],'info')
     else
       Swal.fire('Success',resp['data'],'success')
    this.close();
   }
}


 async flag4(element: any){

        var data = this.allData[1].filter(function(ele: any){
          return ele.party_id = element.party_id;
        });

        console.log(data)
        if(element.no_of_notice_before_cancellation <= data.notice_no){
          this.flagdata=element
          this.body1 = false;
          this.body2 = false;
          this.body3 = false;
          this.body4 = false;
          this.body5 = true;

          this.obj['offer_cd'] = element.offer_cd
          this.obj['party_id'] = element.party_id
          this.obj['no_of_pay'] = element.no_of_pay
          this.obj['defualt_method_id'] = element.defualt_method_id

          let   resp : any = await this.adv_ser.AvailabledForCancellation(JSON.stringify(this.obj));
          if(resp['error']){
              Swal.fire('Error',resp['data'],'success')
              return
          }
          console.log(resp['data'])
          this.cancel_resp = resp['data'][3]
          this.cancel_resp2 = resp['data'][4]
          console.log(this.cancel_resp2)
          //
          //
          var notice_period_obj = resp['data'][1].filter(function(ele: any){
            return ele.defualt_method_id = element.defualt_method_id;
          });
          console.log(notice_period_obj)

          for(let i=0; i<resp['data'][4].length; i++){
            resp['data'][4][i]['notice_period'] = notice_period_obj
          }
          console.log(resp['data'][4]);

        }
        else{
          Swal.fire('error', 'Not   Eligible for Property Cancellation', 'warning')
        }





      }

close(){
        this.getCancelltionData()
        this.body1 = true;
        this.body2 = false;
        this.body3 = false;
        this.body4 = false;
        this.body5 = false;
      }


async createGenerateNotice(){
    let flagPrevious = false
    if(this.priviousNoticeData){
       this.noticeDataObj['req_date']
    }
    console.log(this.tempNotice)
    let obj: any={}
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.user_id
    obj['party_id'] = this.flagdata['party_id']
    obj['notice_no'] = this.currentNoticeData['notice_no'] // first time automatically will be 1
    obj['defualt_method_id'] = this.flagdata['defualt_method_id']
    obj['offer_cd'] = this.flagdata['offer_cd']
    obj['status'] = 'Generated'

    if(!this.flagdata['selected_auth']){
      Swal.fire('Chose Authority ', 'chose a Authority ', 'info')
      return
    }

    obj['data'] = []
    this.flagdata['selected_auth'].forEach((element: any) => {
      obj['data'].push({'authorized_person':element})
    });

    obj['life_cycle_status'] = 'ACTIVE'
    console.log("createGenerateNotice",obj)
    this.spinner.show()
    let today = new Date()
    this.flagdata['date'] = this.formateDateToUse(today)
    // if(!this.flagdata['date']){
    //   Swal.fire('Enter Date', 'chose a date', 'info')
    //   return
    // }
    obj['notice_date'] = this.flagdata['date']

    var   resp : any = await this.adv_ser.createGenerateNotice(obj);
      if (resp['error'] == false) {
         this.spinner.hide()
         if(resp['data']=='This Notice is Already Generated'){
          Swal.fire('Information',resp['data'],'info')
        }else{
          Swal.fire('Success',resp['data'],'success')
          this.close()
        }
        console.log(resp)
      }
      else{
        console.log(resp)
        Swal.fire('Error','Server side error','error')
      }
  }
  //
  async CancelAllotment(){
    let obj: any ={}
    obj['alloted_property_id'] = this.flagdata.alloted_property_id;
     obj['b_acct_id'] = this.b_acct_id
    obj['update_user_id'] = this.flagdata.le_id
    obj['id'] = this.flagdata.id
    console.log(obj)
    let   resp : any = await this.adv_ser.CancelAllotment(obj);
    if(resp['error'] == false){
      Swal.fire('Succues','Succues','success')
      this.close()
    }else{
      Swal.fire('Error',resp['error'],'error')
    }
    console.log(resp)
  }

  //

  async printpdf() {
    let data = []
    for (let i = 0; i < this.allData[0].length; i++) {
      let obj = this.allData[0][i]

      if (this.allData[i][0]['party_id']) {
        obj['party_id'] = this.allData[0][i]['party_id']
      } else {
        obj['party_id'] = ''
      }

      if (this.allData[i][0]['property_type']) {
        obj['property_type'] = this.allData[0][i]['property_type']
      } else {
        obj['property_type'] = ''
      }

      if (this.allData[i][0]['assigned_property_number']) {
        obj['assigned_property_number'] = this.allData[i][0]['assigned_property_number']
      } else {
        obj['assigned_property_number'] = ''
      }

      if (this.allData[i][0]['party_name']) {
        obj['party_name'] = this.allData[i][0]['party_name']
      } else {
        obj['party_name'] = ''
      }


      if (this.allData[i][0]['rem_count']) {
        obj['rem_count'] = this.allData[i][0]['rem_count']
      } else {
        obj['rem_count'] = ''
      }
      data.push(obj)
    }
    console.log(data,"data objrct after loop")


    let sch = '';
    let sub_sch = ''
    var txt = 'Defaulter Notice'
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },

      pageOrientation: 'landscape',

      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    var header0 = {
      columns: [
        {
          width: '*',
          text: this.obj['offer_cd'],
          bold: true,
          alignment: 'center'
        }

      ],
    }
    var header9 = {
      columns: [
        {
          width: '*',
          text: '* Note : This is a computer generated document.',
          bold: true,
          alignment: 'left'
        }

      ],
    }


    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });


    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    var tbl = {

      fontSize: 10,
      alignment : 'center',
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*'],
        body: [
          ['Property id', 'Property type', 'Property Number', 'Allotment Name', 'No of Notice']
        ],
      }
    };
    // console.log(data, 'data')
    dd.content.push(tbl);
    for (var i = 0; i < data.length; i++) {
      var arr = []
      arr.push(data[i]['party_id']);
      arr.push(data[i]['property_type']);
      arr.push(data[i]['assigned_property_number']);
      arr.push(data[i]['party_name']);
      arr.push(data[i]['rem_count']);

      // arr.push(data[i]['area']);
      // arr.push(data[i]['unit']);
      // arr.push(data[i]['subsidised_or_non_subsidised']);
      // arr.push(data[i]['residential_or_commercial']);
      // arr.push(data[i]['arr_status_code']);

      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push(header9);

    pdfMake.createPdf(dd).download("Defaulter List");
  }

  PrintNotice(){
    let data: any = []
    for (let i = 0; i < this.allData[0].length; i++) {
      let obj = this.allData[0][i]

      if (this.allData[i][0]['party_id']) {
        obj['party_id'] = this.allData[0][i]['party_id']
      } else {
        obj['party_id'] = ''
      }

      if (this.allData[i][0]['property_type']) {
        obj['property_type'] = this.allData[0][i]['property_type']
      } else {
        obj['property_type'] = ''
      }

      if (this.allData[i][0]['assigned_property_number']) {
        obj['assigned_property_number'] = this.allData[i][0]['assigned_property_number']
      } else {
        obj['assigned_property_number'] = ''
      }

      if (this.allData[i][0]['party_name']) {
        obj['party_name'] = this.allData[i][0]['party_name']
      } else {
        obj['party_name'] = ''
      }


      if (this.allData[i][0]['rem_count']) {
        obj['rem_count'] = this.allData[i][0]['rem_count']
      } else {
        obj['rem_count'] = ''
      }
      data.push(obj)
    }
    console.log(data,"data objrct after loop")

    let sch = '';
    let sub_sch = ''
    var txt = 'NOTICE OF DEFAULT IN PAYMENT'

    var dd: any = {
      pageSize: 'A4',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt ,
           alignment: 'center',
            margin: [72, 40] };
        return obj;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    // var header0 = {
    //   columns: [
    //     {
    //       width: '*',
    //       text: this.obj['offer_cd'],
    //       bold: true,
    //       alignment: 'center'
    //     }

    //   ],
    // }
    var last = {
      columns: [
        {
          width: '*',
          text: '* Note : This is a computer generated document.',
          fontSize: 12,
          alignment: 'left'
        }

      ],
    }


    this.currentDate = new Date()

    this.party = this.flagdata.party_name

    var send_date = {
      columns: [
        {
          width: '*',
          text: this.currentDate + '\n \n ' + 'Dear Mr. '+ this.party,
          alignment: 'left'
        }

      ],
    }
    var party_name = {
      columns: [
        {
          width: '*',
          text: ' \n \n ' + this.obj['party_name'],

          alignment: 'left'
        }

      ],
    }
    var objective = {
      columns: [
        {
          width: '*',
          text: 'OBJECT: NOTICE TO DEFAULT \n ',
          bold: true,
          alignment: 'left'
        }

      ],
    }
    var authority = {
      columns: [
        {
          width: '*',
          text: 'authority',
          bold: true,
          alignment: 'left'
        }

      ],
    }

    var body = {
      columns: [
        {
          width: '*',
          text: " This letter is a formal notification that you are in default of your obligation to make payments on your EMI, Property Id " + data['assigned_property_number'] + " This current account holds the sum of {{due amount}}, payable by {{notice end date}} \n \n \n This amount has been not paid since {{last paid date }} and you have ignored {{no of notice}} request to make a payment or reconsolidate your due amount. \n \n \n Unless the full amount  is received within 15 days, we have no choice but to begin the foreclosure process on your property . We have given you more than adequate notice on this issue, and we have no other choice. \n \n \n \n \n Please act accordingly, \n {{sender}} ",

          alignment: 'justify',
          font : 'Roboto'
        }

      ],
    }



    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    // dd.content.push(header0);
    dd.content.push({ text: " " });
    // dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    // dd.content.push({ text: " " });
    // dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(send_date);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(objective);
    dd.content.push(body);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(authority);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(last);

    pdfMake.createPdf(dd).download("allotment");

  }





}



