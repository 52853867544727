import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgtreegridModule } from 'ngtreegrid';

import {NgChartsModule,ThemeService} from 'ng2-charts';

import { NgApexchartsModule } from "ng-apexcharts";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupComponent } from './signup/signup.component';
import { SigninComponent } from './signin/signin.component';
// import { NgChartsModule } from 'ng2-charts';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
// import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from "ngx-spinner";
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ToastrModule } from 'ng6-toastr-notifications';
// import { AgmCoreModule } from '@agm/core';
import { TokenInterCepterService } from './token-inter-cepter.service';
// import { NgxEchartsModule } from 'ngx-echarts';
import { DatePipe } from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import { GoogleMapsModule } from '@angular/google-maps';

import {MatDividerModule} from '@angular/material/divider';
// import { AuthServiceConfig } from 'angular-6-social-login';
// import { GoogleLoginProvider, FacebookLoginProvider } from 'angular-6-social-login';
import { MdLayoutsModule } from './md/md-layouts/md-layouts.module';
import { PortalLayoutsModule } from './portal/portal-layouts/portal-layouts.module';
import { EmbLayoutsModule } from './emb/emb-layouts/emb-layouts.module';
import { TrackingLayoutModule } from './tracking/tracking-layout/tracking-layout.module';
import { IcmsLayoutModule } from './icms/icms-layout/icms-layout.module';
import { HrmsLayoutsModule } from './hrms/hrms-layouts/hrms-layouts.module';
import { ScriptLoaderService } from './_services/script-loader.service';
import { AccountsLayoutsModule } from './accounts/accounts-layouts/accounts-layouts.module';
import { PropertyLayoutsModule } from './property/property-layouts/property-layouts.module';
import { VmsLayoutModule } from './vms/vms-layout/vms-layout.module';
import { EmdLayoutModule } from './emd/emd-layout/emd-layout.module';

// oc
// export function socialConfigs() {

//   const config = new AuthServiceConfig(
//     [
//       {
//         id: FacebookLoginProvider.PROVIDER_ID,
//         provider: new FacebookLoginProvider('310166580213096')
//       },
//       {
//         id: GoogleLoginProvider.PROVIDER_ID,
//         provider: new GoogleLoginProvider('1042901570212-e2l3f0ja5h9uvhot3i8echbhsp4pupa3.apps.googleusercontent.com')
//       }
//     ]
//   );

//   return config;
// }
// ec
@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SigninComponent
  ],
  imports: [
    PortalLayoutsModule,
    TrackingLayoutModule,
    IcmsLayoutModule,
    PropertyLayoutsModule,
    AccountsLayoutsModule,
    MdLayoutsModule,
    EmbLayoutsModule,
    HrmsLayoutsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    FileUploadModule,
    NgSelectModule,
    // NgtreegridModule,
    GoogleMapsModule,
    VmsLayoutModule,
    // ChartsModule,
    NgChartsModule,
    NgApexchartsModule,
    EmdLayoutModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatFormFieldModule,
   MatExpansionModule  ,
   MatSelectModule  ,
   MatTableModule,
   MatTabsModule,
   MatSortModule,
   MatPaginatorModule,
    MatIconModule,
    NgxSpinnerModule,
    MatSortModule,
    // NgbModule,
    // ToastrModule,
    // AgmCoreModule
    // TokenInterCepterService,
    // NgxEchartsModule,
    DatePipe,
    MatTooltipModule,
    MatDividerModule,
  ],
  providers: [
    ScriptLoaderService,
    // AuthService,
    // ThemeService,
    DatePipe,
    // {
    //   provide: AuthServiceConfig,
    //   useFactory: socialConfigs
    // }, {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterCepterService,
    //   multi: true
    // }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
