<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{auth.allLableShowObjProp[auth.language_cd+'property1']}} </div>
                    <!--Property-->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                    <div class="ibox-body">
                        <ul class="nav nav-tabs tabs-line">
                            <li class="nav-item">
                                <a class="nav-link" *ngIf="create_flag" (click)="openImageTab()" data-toggle="tab"><i
                                    class="fa fa-photo " aria-hidden="true"> </i>
                                    Upload Images </a>
                                <!--Property List-->
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" *ngIf="update_flag" (click)="openVideoTab()" data-toggle="tab"><i
                                        class="fa fa-video-camera" aria-hidden="true"> </i>
                                    Videos</a><!-- Update Property -->
                            </li>
                        </ul>
                        <br>
                        <div class="tab-content">
                            <div >
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3">
                                        <h6>{{auth.allLableShowObjProp[auth.language_cd+'property14']}}<span
                                                style="color: red;"> *</span></h6>
                                    </div>
                                    <!--Select Plan Layout :-->
                                    <!-- (change)="" -->
                                    <div class="col-3">
                                        <ng-select [items]="allPlan_layout" bindLabel="desc"
                                            bindValue="property_group_id" [multiple]="false"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property14']}}"
                                            [(ngModel)]="Obj['property_group_id']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                            name="chart_of_account0">
                                            <ng-template ng-label-tmp let-item="item">
                                                <b> {{item.desc}}  </b>
                                             </ng-template>
                                             <ng-template ng-option-tmp let-item="item" >
                                                 <div (click)="getPropertytype(item)" >{{item.desc}}</div>
                                             </ng-template>
                                        </ng-select>
                                        <!--Select Location Type-->
                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>

                            </div>
                        </div>
                        <div class="tab-content" >
                            <div >
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3">
                                        <h6>Property Type<span
                                                style="color: red;"> *</span></h6>
                                    </div>
                                    <!--Select Plan Layout :-->
                                    <!-- (change)="" -->
                                    <div class="col-3">
                                        <ng-select [items]="allPropertType" bindLabel="property_type"
                                            bindValue="property_type" [multiple]="false"
                                            (change)="getAllpropertyimagesObj()"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property14']}}"
                                            [(ngModel)]="property_type" [selectableGroup]="true"
                                            [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                            name="chart_of_account0">

                                        </ng-select>
                                        <!--Select Location Type-->
                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>

                            </div>
                        </div>
                        <div class="tab-content" *ngIf="ImageTab" >
                            <div class="row my-3">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="showImgListflag()">{{imgListFlag?"Show All Images":"Uploded Images"}}</button>
                                </div>
                            </div>
                            <div >
                            <div class="card m-2" *ngIf="imgListFlag" >
                                <div style="margin: 2%;">

                                    <!-- uploaderS start -->
                                    <div class="container">

                                        <div class="row">
                                            <div class="col-md-12 " >
                                                <!-- <h3 class="w-100 text-center">Select document to upload</h3>
                                                <h6 class="w-100 text-center">(You can select one file at a time. Supported file format - PDF. Max file size - 500mb)</h6>             -->
                                                <div ng2FileDrop
                                                     [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                                                     (fileOver)="fileOverBase($event)"
                                                     [uploader]="uploader"
                                                     (onFileDrop)="onFileSelected($event)"
                                                     (click)="fileInput.click()"
                                                     class="well my-drop-zone mt-2" style="height: 200px ;">
                                                    Drag and drop files here or click to select
                                                </div>
                                                <div *ngIf=""
                                                     class="well my-drop-zone mt-3 cursor-default text-secondary">
                                                    Drag and drop files here or click to select (Image more then 20kb can not be uploaded)
                                                </div>
                                                <input type="file" #fileInput ng2FileSelect multiple [uploader]="uploader"  (onFileSelected)="onFileSelected($event)" class="d-none"/>
                                            </div>
                                        </div>

                                        <div>

                                    </div>

                                        <div *ngIf="uploader?.queue?.length > 0" class="row mt-4">
                                            <div class="col-md-12">
                                                <h5>Upload Property Photoes</h5>

                                                <table class="table mt-3">
                                                    <thead>
                                                    <tr class="text-primary">
                                                        <th width="50%">Name</th>
                                                        <th width="50%">image</th>
                                                        <th class="text-right wp-10">Size</th>
                                                        <th class="text-center wp-10">Status</th>
                                                        <th class="text-center wp-10">Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr *ngFor="let item  of uploader.queue">
                                                        <td>{{ item?.file?.name }}</td>
                                                        <td><img [src]="returnURL(item)" height="40" width="40" alt="" srcset=""></td>
                                                        <td *ngIf="uploader.options.isHTML5" nowrap class="text-right wp-10">{{ item?.file?.size/1024 | number:'.2' }} KB</td>
                                                        <td class="text-center">
                                                            <span *ngIf="!item.isError && item.isUploaded"  class="text-success">Succeeded</span>
                                                            <span *ngIf="!item.isError && !item.isUploaded && !item.isUploading" class="text-warning">waiting</span>
                                                            <span *ngIf="!item.isError && item.isUploading" class="text-primary">loading</span>
                                                            <span *ngIf="item.isError " class="text-danger">Failed</span>
                                                        </td>
                                                        <td nowrap class="text-center">
                                                            <button *ngIf="!item.isUploading && (!item.isError || (item.isError && item.file.name.split('.').pop() !== 'pdf'))" type="button" class="btn btn-outline-primary btn-sm mr-1"
                                                                    (click)="uploadSingle(item); setMimeType(item.file.name.split('.').pop());">
                                                                    <i class="fas fa-upload" title="Upload file"></i>
                                                            </button>
                                                            <!-- <button *ngIf="item.isUploading" type="button" class="btn btn-outline-danger btn-xs mr-2"
                                                                    (click)="item.cancel()">
                                                                <span class="fas fa-times-circle"></span> Cancel
                                                            </button> -->
                                                            <button *ngIf="!item.isUploading && (!item.isError || (item.isError && item.file.name.split('.').pop() !== 'pdf'))" type="button" class="btn btn-outline-danger btn-sm"
                                                                    (click)="item.remove()">
                                                                <i class="fas fa-trash-alt" title="Remove file"></i>
                                                            </button>

                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <div class="mt-2 mb-4">
                                                    <!-- <div class="my-5" *ngIf="uploader.isUploading">                                         -->
                                                        <h6>Upload progress:</h6>
                                                        <div class="progress">
                                                            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn btn-primary mr-2"
                                                            (click)="uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
                                                            <i class="fas fa-upload pr-2" title="Upload all files"></i> Upload All
                                                    </button>
                                                    <!-- <button type="button" class="btn btn-warning mr-2"
                                                            (click)="uploader.cancelAll()" *ngIf="uploader.isUploading">
                                                            <i class="fas fa-ban pr-2" title="Cancel uploading"></i> Cancel All
                                                    </button> -->
                                                    <button type="button" class="btn btn-warning mr-2"
                                                            (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                                                            <i class="fas fa-trash-alt pr-2" title="Clear file list in queue"></i> Clear Queue
                                                    </button>



                                                <!-- <div *ngIf="mimeType !== 'pdf' && uploader.progress === 100">
                                                    <div class="mt-5 text-center">
                                                      <a href="https://ks95bh.axshare.com/document_new.html" target="_parent" class="btn btn-primary" role="button">Done</a>
                                                    </div>
                                                </div>             -->

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card m-2" *ngIf="!imgListFlag">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                          <input type="checkbox" name="" id="" (click)="slecectAllimg()"> Select All
                                          <button class="btn btn-outline-danger mr-3" (click)="deleteSelImg()"><i class="fas fa-trash-alt" title="Remove file"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <table class="table">
                                            <thead>
                                                    <tr class="text-primary">
                                                        <th class="text-center">Sr. No</th>
                                                        <th class="text-center">Image</th>
                                                        <th class="text-center">Action</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let url of imageUrls;let i=index" class="text-center">
                                                    <td width="10%" class="text-primary">{{i+1}}.</td>
                                                    <td width="50%" ><img [src]="url" height="60" width="60" alt="" srcset="" (click)="openImgNextModel(url)" data-toggle="modal" data-target="#modal3" ></td>
                                                    <td><input type="checkbox" name="" id="" [(ngModel)]="selectedImg[i]" ></td>
                                                </tr>
                                                <tr *ngIf="!(imageUrls.length>0)">
                                                    <td colspan="3" class="text-center">No Image Uploaded...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <!-- <div class="card mt-4">
                                <div class="row" *ngIf="!nowAddedVideo">
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="youtubevideo">
                                    </div>
                                    <div class="col-3">
                                        <button class="btn btn-outline-primary" (click)="addVideo()">Add Video</button>
                                    </div>
                                </div>
                                <div class="row mb-2" *ngIf="nowAddedVideo">
                                    <div class="col-12">
                                        <h1 class="text-center" >The video element</h1>
                                    </div>
                                    <div class="col-12 text-center ">
                                        <iframe width="420" height="345" [src]="nowAddedVideo">
                                        </iframe>
                                    </div>
                                    <div class="col-12 my-5 text-center"> <button class="btn btn-danger" (click)="deleteVid()" >delete</button> </div>
                                </div>

                            </div> -->
                            <br>
                            <!-- <div class="row">
                                <div class="col-12 text-center">
                                    <button  class="btn btn-outline-danger"
                                        matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property68']}}</button>
                                    &nbsp;&nbsp; -->
                                    <!--Back-->
                                    <!-- <button  class="btn btn-outline-primary"
                                        matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property69']}}</button> -->
                                    <!--Next-->


                                <!-- </div>
                            </div> -->
                        </div>
                            <br>
                        </div>


                        <!-- for video tab -->
                        <div  class="tab-content" *ngIf="!ImageTab" >
                            <div class="card mt-4">
                                <div class="card-header">
                                   <h4>Property Video</h4>
                                </div>
                                <div class="card-body">
                                    <div class="row my-3" *ngIf="!nowAddedVideo">
                                        <div class="col-12">
                                            <h1 class="text-center" >Copy And Paste Youtube Link</h1>
                                        </div>
                                        <div class="col-12 col-md-9">
                                            <input type="text" class="form-control" placeholder="example:https://www.youtube.com/watch?v=xyzabcd" [(ngModel)]="youtubevideo">
                                        </div>
                                        <div class="col-12 col-md-3">
                                            <button class="btn btn-outline-primary" (click)="addVideo()">Add Video</button>
                                        </div>
                                    </div>
                                    <div class="row my-3" *ngIf="nowAddedVideo">
                                        <div class="col-12">
                                            <h1 class="text-center" >Uploaded Video For The Property Type</h1>
                                        </div>
                                        <div class="col-12 text-center mt-3 ">
                                            <iframe width="420" height="345" [src]="nowAddedVideo" allowFullScreen="allowFullScreen" >
                                            </iframe>
                                        </div>
                                        <div class="col-12 my-5 text-center"> <button class="btn btn-danger" (click)="deleteVid()" >delete</button> </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-12 text-center">
                                    <button  class="btn btn-outline-danger"
                                        matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property68']}}</button>
                                    &nbsp;&nbsp; -->
                                    <!--Back-->
                                    <!-- <button  class="btn btn-outline-primary"
                                        matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property69']}}</button> -->
                                    <!--Next-->


                                <!-- </div>
                            </div> -->
                        </div>
                            <br>
                        </div>
                    </div>
                    <br>
                </div>
            </div>


</div>

<!-- <div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">
                    {{auth.allLableShowObjProp[auth.language_cd+'property83']}}
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <img [src]="fileURL" alt="" srcset="">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger"
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" id="modal3" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">

      <!--Content-->
      <div class="modal-content">

        <!--Body-->
        <div class="modal-body mb-0 p-0">

          <div class="row embed-responsive embed-responsive-8by z-depth-1-half">
            <div class="col-12 text-center">
                <img [src]="fileURL" alt="" height="500" width="500" >
            </div>
          </div>

        </div>

        <!--Footer-->
        <div class="modal-footer d-block d-md-flex justify-content-center">
          <button type="button" class="btn btn-outline-primary btn-rounded btn-md ml-4" data-dismiss="modal">Close</button>

        </div>

      </div>
      <!--/.Content-->

    </div>
  </div>
<ngx-spinner>{{auth.allLableShowObjProp[auth.language_cd+'property82']}}</ngx-spinner>

<ngx-spinner>Loading...</ngx-spinner>
