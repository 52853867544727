<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Restoration Management</div>
          <!--Property-->
          <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
        </div>

        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <!-- <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" *ngIf="!restore_flag"><i class="fa fa-clock-o"> &nbsp; </i>Seized Property </a>
              <a class="nav-link active" data-toggle="tab" *ngIf="restore_flag" ><i class="fa fa-clock-o"> &nbsp; </i>Seized Property Restoration</a>
            </li> -->
            <!--  -->
            <li class="nav-item">
              <a class="nav-link active" href="#tab-1" data-toggle="tab"><i
                  class="ti-bar-chart"></i>
                Restore Request </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tab-2" data-toggle="tab"><i
                   class="ti-settings mr-1"></i>Restored property List</a>
            </li>

          </ul>

          <div class="tab-content">

            <div class="tab-pane fade show active" id="tab-1">

              <div *ngIf="search_flag">
                <div class="row mt-5">
                  <div class="col-2"></div>
                  <div class="col-3 text-right">
                    <h6>Scheme / Offer :*</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="allOffer" bindLabel="offer_name"
                      bindValue="offer_cd" [multiple]="false"
                      placeholder="Select Offer" [(ngModel)]="obj['offer_cd']"
                      [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true"
                      >

                    </ng-select>
                  </div>
                </div>

                <!-- <div class="row">
                  <div class="col-2"></div>
                  <div class="col-3 text-right">
                    <h6>Property Category :</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="pro_category"
                      bindLabel="property_category"
                      bindValue="property_category"
                      placeholder="Select property Category"
                      [(ngModel)]="obj['pro_category']">
                    </ng-select>
                  </div>
                </div> -->

                <!-- <br /> -->

                <!-- <div class="row">
                  <div class="col-2"></div>
                  <div class="col-3 text-right">
                    <h6>Property Type :</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="pro_type" bindLabel="pro_type"
                      bindValue="pro_type" [multiple]="false"
                      placeholder="Select property type"
                      [(ngModel)]="obj['pro_type']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>
                </div> -->


                <div class="row mt-3">
                  <div class="col-4"></div>
                  <div class="col-3 text-center">
                    <button class="btn btn-outline-primary"
                      (click)="getSeizedDetails()">
                      Search
                    </button>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col-4">
                    <mat-form-field style="margin-left: 2%">
                      <input matInput (keyup)="applyFilter($any($event.target).value)"
                        placeholder="search">
                    </mat-form-field>
                  </div>
                  <div class="col-8 text-right">
                    <button class="btn btn-outline-success">Add approvel</button>
                    &nbsp;&nbsp;
                    <button class="btn btn-outline-primary">Download</button>
                  </div>
                </div>
                <!--btn-->
                <br />
                <br />
                <div class="example-container">
                  <table mat-table [dataSource]="dataSource" matSort
                    style="width: 100%" *ngIf="dataSource">
                    <!-- selection -->
                    <ng-container matColumnDef="select">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>select</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        <input class="form-check-input" type="checkbox" value=""
                          id="flexCheckDefault" />
                      </td>
                    </ng-container>

                    <!-- party_id Column -->
                    <ng-container matColumnDef="party_id">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Property Id</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.alloted_property_id }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="property_category">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Property Category</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.property_category }}
                      </td>
                    </ng-container>

                    <!-- property_type Column -->
                    <ng-container matColumnDef="property_type">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Property type</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.property_type }}
                      </td>
                    </ng-container>
                    <!--  -->
                    <ng-container matColumnDef="assigned_property_number">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Property No</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.assigned_property_number }}
                      </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="allotment_id">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Allotment ID</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.allotement_id }}
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="party_name">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b> Allottee Name</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.party_name }}
                      </td>
                    </ng-container>
                    <!--  -->
                    <ng-container matColumnDef="notice_no">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b> Seize Date</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.seized_date }}
                      </td>
                    </ng-container>

                    <!-- Name Column -->
                    <!-- <ng-container matColumnDef="Notice_Date">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  <h6 class="text-center" >Notic Date</h6>
                 </th>
                  <td mat-cell *matCellDef="let element">null</td>
                  </ng-container> -->
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="status">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Status</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.request_status }}
                      </td>
                    </ng-container>
                    <!--  -->
                    <ng-container matColumnDef="Action">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Action</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element; let i=
                        index" class="right">
                        <button class="btn btn-outline-primary but-w mt-1 btnsize"
                          (click)="details_function(element)">
                          View Details</button><br />
                        <button class="btn btn-outline-success but-w mt-1 btnsize  "
                          (click)="restore_function(element)">
                          Restore </button><br />
                        <button class="btn btn-outline-danger but-w mt-1 btnsize "
                          >
                          Reject </button>
                      </td>
                    </ng-container>
                    <!--  -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[5, 10]"
                    showFirstLastButtons></mat-paginator>

                  <!-- mat table  -->
                </div>
              </div>

              <div *ngIf="details_flag" class="mt-5">
                <div class="row justify-content-end mt-2">
                  <div class="col-1">
                    <button class="btn btn-outline-primary but-w mt-1" (click)="close()">
                      Go to List
                    </button>
                  </div>
                </div>
                <div class="card mt-2">
                  <div style="margin: 2%">
                    <br />
                    <div class="container">
                      <div class="m-b-5" style="margin: 2%">
                        <div class="row m-t-5">
                          <div class="col">
                            <h6>Offer Description :  {{details_obj.offer_desc}}</h6>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col">
                            <h6>Allotment ID</h6>
                          </div>
                          <div class="col">
                            {{details_obj.allotement_id}}
                          </div>
                          <div class="col">
                            <h6>Property ID</h6>
                          </div>
                          <div class="col">
                            {{details_obj.alloted_property_id}}
                          </div>
                          <div class="col">
                            <h6>Allottee Name</h6>
                          </div>
                          <div class="col">
                            {{details_obj.party_name}}
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Property Category</h6>
                          </div>
                          <div class="col">
                            {{details_obj.property_category}}
                          </div>
                          <div class="col">
                            <h6>allotteement Date</h6>
                          </div>
                          <div class="col">
                            {{details_obj.allotment_date}}
                          </div>
                          <div class="col">
                            <h6>Property Type</h6>
                          </div>
                          <div class="col">
                            {{details_obj.property_type}}
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Property Seize Date</h6>
                          </div>
                          <div class="col">
                            {{details_obj.seized_date}}
                          </div>
                          <div class="col">
                            <h6>Property Number</h6>
                          </div>
                          <div class="col">
                            {{details_obj.assigned_property_number}}
                          </div>
                          <div class="col">
                            <h6>Payment Mode</h6>
                          </div>
                          <div class="col">

                            {{details_obj.payment_option}}
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Property Cost(Rs.)</h6>
                          </div>
                          <div class="col">
                            {{details_obj.pro_rate}}
                          </div>
                          <div class="col">
                            <h6>Total Paid Amount (Rs)</h6>
                          </div>
                          <div class="col">
                            {{details_obj.total_paid_amount}}

                          </div>
                          <div class="col">
                            <!-- <h6>Balance Amount (Rs)</h6> -->
                          </div>
                          <div class="col">
                            <!-- *null -->
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col">
                            <h6>Last Payment Date</h6>
                          </div>
                          <div class="col">
                            {{details_obj.last_paid_date}}
                          </div>
                          <div class="col">
                            <h6>Last Paid Amount</h6>
                          </div>
                          <div class="col">
                            {{details_obj.last_paid_amount}}
                          </div>
                          <div class="col">
                            <h6></h6>
                          </div>
                          <div class="col">
                            <h6></h6>
                          </div>
                        </div>
                        <br />
                        <br />
                        <!-- <div class="row">
                        <div class="col text-right">
                          <button class="btn btn-outline-danger" (click)="close()">CLOSE</button>
                        </div>
                        <div class="col text-left">
                          <button class="btn btn-outline-success" (click)="restore_function()">
                            RESTORE
                          </button>
                        </div>
                      </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div *ngIf="restore_flag">
                <div class="row justify-content-end mt-2">
                  <div class="col-1">
                    <button class="btn btn-outline-primary but-w mt-1" (click)="close()">
                      Go to List
                    </button>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col">
                    <h6>Offer Description :  {{restore_obj.offer_desc}}</h6>
                  </div>
                </div>
                <div class="card mt-3">
                  <div style="margin: 2%">
                    <br />

                    <br />
                    <h6>Previous Allotment Details:</h6>
                    <br />
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <h6>Allotment ID :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.alloted_property_id}}
                        </div>

                        <div class="col">
                          <h6>Property ID :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.alloted_property_id}}
                        </div>
                      </div>
                      <br>

                      <div class="row">
                        <div class="col">
                          <h6>Allottee Name :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.party_name}}
                        </div>

                        <div class="col">
                          <h6>Property Category :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.property_category}}
                        </div>
                      </div>
                      <br>

                      <div class="row">
                        <div class="col">
                          <h6>Allotment Date :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.allotment_date}}
                        </div>

                        <div class="col">
                          <h6>Property Type :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.property_type}}
                        </div>
                      </div>
                      <br>

                      <div class="row">
                        <div class="col">
                          <h6>Property Seize Date :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.seized_date}}
                        </div>

                        <div class="col">
                          <h6>Property No. :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.assigned_property_number}}
                        </div>
                      </div>
                      <br>

                      <div class="row">
                        <div class="col">
                          <h6>Payment Mode :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.payment_option}}
                        </div>

                        <div class="col">
                          <h6>Property Cost (Rs) :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.pro_rate}}
                        </div>
                      </div>
                      <br>

                      <div class="row">
                        <div class="col">
                          <h6>No of Installments :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.no_of_payment}}
                        </div>

                        <div class="col">
                          <h6>Total Paid Amount (Rs)</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.total_paid_amount}}

                        </div>
                      </div>
                      <br>

                      <div class="row">
                        <div class="col">
                          <h6>Rate of Interest (%) :</h6>
                        </div>
                        <div class="col">
                          {{restore_obj.interest_rate}}

                        </div>

                        <div class="col">
                          <!-- <h6>Balance Amount (Rs)</h6> -->
                        </div>
                        <div class="col">
                          <!-- NULL -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />

                <div class="card">
                  <div style="margin: 2%">
                    <br />
                    <div>
                      <br />
                      <div class="container">
                        <div class="row">
                          <div class="col text-left">
                            <h6>Resoration Date:*</h6>
                          </div>
                          <div class="col text-left">
                            <input type="date" class="form-control"
                              [(ngModel)]="obj['restoration_date']">
                          </div>
                          <div class="col"></div>
                          <div class="col text-left"></div>
                          <div class="col text-left"></div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col-6 text-left">
                            <h6>
                              Is Restoration done as per Previous Terms &
                              Conditions?*
                            </h6>
                          </div>
                          <div class="col text-left">
                            <ng-select
                              [items]="yes_no_flag"
                              bindLabel="name"
                              bindValue="id"
                              [(ngModel)]="input_flag">
                            </ng-select>
                          </div>
                          <div class="col"></div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col text-left">
                            <h6>Resoration Charges (if any):</h6>
                          </div>
                          <div class="col text-left">
                            <input type="text" class="form-control"
                              placeholder="0.00"
                              [(ngModel)]="obj['res_other_charge']" />
                          </div>
                          <div class="col"></div>
                          <div class="col"></div>
                        </div>
                        <br />

                        <!-- flag input start  -->
                        <!-- <div *ngIf="!input_flag">
                        <div class="row">
                          <div class="col text-left">
                            <h6>No of Installments :*</h6>
                          </div>
                          <div class="col text-left">
                            <input type="text" class="form-control"  placeholder="New No of Installments"/>
                          </div>
                          <div class="col">
                            <h6>Frequency of Installments:*</h6>
                          </div>
                          <div class="col">
                            <ng-select
                                [items]="installments_frequency"
                                bindLabel="name"
                                bindValue="id"
                                placeholder="Monthly / Quarterly"
                              >
                              </ng-select>
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col text-left">
                            <h6>New Rate of Interest (%):*</h6>
                          </div>
                          <div class="col text-left">
                            <input type="text" class="form-control"  placeholder="New Rate of Interest in %"/>
                          </div>

                          <div class="col">
                            <h6>New Penal Interest (%):*</h6>
                          </div>
                          <div class="col">
                            <input type="text" class="form-control"  placeholder="New Penal Interest in %"/>

                          </div>
                        </div> -->
                        <!-- </div> flag div -->
                        <!-- flag input ends -->
                        <br />
                        <!-- <div class="row">
                        <div class="col-6 text-left">
                          <h6>
                            Upload Challan Receipt: (if offline payment done)
                          </h6>
                        </div>
                        <div class="col text-left">
                          <input type="file" id="myFile" name="filename" accept="application/pdf"  />
                        </div>

                        <div class="col">
                          <button class="btn btn-outline-primary">
                            Upload
                          </button>
                          <button class="btn btn-outline-primary">
                            View Doc
                          </button>
                        </div>
                        <div class="col"></div>
                      </div> -->
                      </div>

                      <!-- btn -->
                      <div class="row">
                        <div class="col text-right">
                          <button class="btn btn-outline-danger"
                            (click)="close()">CANCEL</button>
                        </div>
                        <div class="col text-left">
                          <button class="btn btn-outline-primary"
                            (click)="restore()">SUBMIT</button>
                        </div>
                      </div>

                      <!--  -->
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <!-- secound tab  -->

            <div class="tab-pane fade"id="tab-2">
              <div *ngIf="search_flag_tab2">
                <div class="row mt-5">
                  <div class="col-2"></div>
                  <div class="col-3 text-right">
                    <h6>Scheme / Offer :*</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="allOffer" bindLabel="offer_name"
                      bindValue="offer_cd" [multiple]="false"
                      placeholder="Select Offer" [(ngModel)]="obj['offer_cd']"
                      [selectableGroup]="true"
                      (click)="getpropertytype()"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-2"></div>
                  <div class="col-3 text-right">
                    <h6>Property Category :</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="pro_category"
                      bindLabel="property_category"
                      bindValue="property_category"
                      placeholder="Select property Category"
                      [(ngModel)]="obj['pro_category']">
                    </ng-select>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col-2"></div>
                  <div class="col-3 text-right">
                    <h6>Property Type :</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="pro_type" bindLabel="pro_type"
                      bindValue="pro_type" [multiple]="false"
                      placeholder="Select property type"
                      [(ngModel)]="obj['pro_type']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>
                </div>

                <br />


                <div class="row">
                  <div class="col-4"></div>
                  <div class="col-3 text-center">
                    <button class="btn btn-outline-primary"
                      (click)="AllRestoredproperty()">
                      Search
                    </button>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col-4">
                    <mat-form-field style="margin-left: 2%">
                      <input matInput (keyup)="applyFilter_tab2($any($event.target).value)"
                        placeholder="search">
                    </mat-form-field>
                  </div>
                  <div class="col-8 text-right">
                    <button class="btn btn-outline-success">Add approvel</button>
                    &nbsp;&nbsp;
                    <button class="btn btn-outline-primary">Download</button>
                  </div>
                </div>
                <!--btn-->
                <br />
                <br />
                <div class="example-container">
                  <table mat-table [dataSource]="dataSource2" matSort
                    style="width: 100%">
                    <!-- selection -->
                    <ng-container matColumnDef="select">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>select</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        <input class="form-check-input" type="checkbox" value=""
                          id="flexCheckDefault" />
                      </td>
                    </ng-container>

                    <!-- party_id Column -->
                    <ng-container matColumnDef="party_id">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Property Id</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.party_id }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="property_category">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Property Category</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.property_category }}
                      </td>
                    </ng-container>

                    <!-- property_type Column -->
                    <ng-container matColumnDef="property_type">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Property type</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.property_type }}
                      </td>
                    </ng-container>
                    <!--  -->
                    <ng-container matColumnDef="assigned_property_number">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Property No</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.assigned_property_number }}
                      </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="allotment_id">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Allotment ID</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.allotment_no }}
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="party_name">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b> Allottee Name</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.party_name }}
                      </td>
                    </ng-container>
                    <!--  -->
                    <ng-container matColumnDef="notice_no">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b> Seize Date</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.seized_date }}
                      </td>
                    </ng-container>

                    <!-- Name Column -->
                    <!-- <ng-container matColumnDef="Notice_Date">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  <h6 class="text-center" >Notic Date</h6>
                 </th>
                  <td mat-cell *matCellDef="let element">null</td>
                  </ng-container> -->
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="status">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Status</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element"
                        class="right">
                        {{ element.life_cycle_status }}
                      </td>
                    </ng-container>
                    <!--  -->
                    <ng-container matColumnDef="Action">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <b>Action</b>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element; let i=
                        index" class="right">
                        <button class="btn btn-outline-primary but-w mt-1"
                          (click)="details_function_tab2(element)">
                          View Details</button><br />
                      </td>
                    </ng-container>
                    <!--  -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[5, 10]"
                    showFirstLastButtons></mat-paginator>

                  <!-- mat table  -->
                </div>
              </div>

              <div *ngIf="details_flag_tab2" class="mt-5">
                <div class="row justify-content-end mt-2">
                  <div class="col-1">
                    <button class="btn btn-outline-primary but-w mt-1" (click)="close_tab2()">
                      Go to List
                    </button>
                  </div>
                </div>
                <div class="card mt-2">
                  <div style="margin: 2%">
                    <br />
                    <div class="container">
                      <div class="m-b-5" style="margin: 2%">
                        <div class="row m-t-5">
                          <div class="col">
                            <h6>Offer Description :  {{details_obj.offer_desc}}</h6>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col">
                            <h6>Allotment ID</h6>
                          </div>
                          <div class="col">
                            {{details_obj.allotment_no}}
                          </div>
                          <div class="col">
                            <h6>Property ID</h6>
                          </div>
                          <div class="col">
                            {{details_obj.alloted_property_id}}
                          </div>
                          <div class="col">
                            <h6>Allottee Name</h6>
                          </div>
                          <div class="col">
                            {{details_obj.party_name}}
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Property Category</h6>
                          </div>
                          <div class="col">
                            {{details_obj.property_category}}
                          </div>
                          <div class="col">
                            <h6>allotteement Date</h6>
                          </div>
                          <div class="col">
                            {{details_obj.allotment_date}}
                          </div>
                          <div class="col">
                            <h6>Property Type</h6>
                          </div>
                          <div class="col">
                            {{details_obj.property_type}}
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Property Seize Date</h6>
                          </div>
                          <div class="col">
                            {{details_obj.seized_date}}
                          </div>
                          <div class="col">
                            <h6>Property Number</h6>
                          </div>
                          <div class="col">
                            {{details_obj.assigned_property_number}}
                          </div>
                          <div class="col">
                            <h6>Payment Mode</h6>
                          </div>
                          <div class="col">

                            {{details_obj.payment_option}}
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Property Cost(Rs.)</h6>
                          </div>
                          <div class="col">
                            {{details_obj.pro_rate}}
                          </div>
                          <div class="col">
                            <h6>Total Paid Amount (Rs)</h6>
                          </div>
                          <div class="col">
                            {{details_obj.total_paid_amount}}

                          </div>
                          <div class="col">
                            <h6>Last Payment Date</h6>
                          </div>
                          <div class="col">
                            {{details_obj.last_paid_date}}

                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col">
                            <h6>Last Paid Amount</h6>
                          </div>
                          <div class="col">
                            {{details_obj.last_paid_amount}}

                          </div>
                          <div class="col">

                          </div>
                          <div class="col">

                          </div>
                          <div class="col">
                            <h6></h6>
                          </div>
                          <div class="col">
                            <h6></h6>
                          </div>
                        </div>
                        <br />
                        <br />
                        <!-- <div class="row">
                        <div class="col text-right">
                          <button class="btn btn-outline-danger" (click)="close()">CLOSE</button>
                        </div>
                        <div class="col text-left">
                          <button class="btn btn-outline-success" (click)="restore_function()">
                            RESTORE
                          </button>
                        </div>
                      </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
            </div>

          </div>




          <hr class="hrline" />
          <br />
          <br />
        </div>
      </div>

      <br />
    </div>
  </div>
</div>
