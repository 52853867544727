import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query } from '@angular/core';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FileUploader } from 'ng2-file-upload';
import { MainService } from '../../service/main.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllotmentLetterConfigurationService } from '../../service/allotment-letter-configuration.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Inject } from '@angular/core';
@Component({
  selector: 'app-letter-configuration',
  templateUrl: './letter-configuration.component.html',
  styleUrls: ['./letter-configuration.component.css']
})
export class LetterConfigurationComponent implements OnInit {
  datasource:any ;
  selectedFile: any = null;
  level1_acq:any ;
  outer_acq_row :any = [{
    'level1': [],
    location: [{}],
    data: [{}]
  }]
  imgURL:any
  httpUrl:any
  uploader:any
  b_acct_id:any
  ebillUser: any;
  co_party_info: any;
  table_data :any = []
  updateObj :any = {}
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  constructor(private mainService: MainService,  @Inject(DomSanitizer)  private sanitizer: DomSanitizer, private spinner: NgxSpinnerService, private letterConfigure: AllotmentLetterConfigurationService) { }
  Obj :any = {}
  displayedColumns:any  = ['id', 'desc1', 'desc2', 'action'];
  public imagePath:any ;
  list_flag:any  = true
  create_flag:any  = false
  update_flag :any = false
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.httpUrl = this.mainService.httpUrl + '/property/PropertyDoc';
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file:any ) => { file.withCredentials = false; };
    await this.getAllotmentLetterData()
  }
  add() {
    let obj:any  = {}
    obj['user_id'] = this.ebillUser['user_id']
    obj['life_cycle_status'] = 'ACTIVE'
    this.table_data.push(obj)
  }
  remove(item:any , i:any ) {
    this.table_data.splice(i, 1)
  }
  open_create() {
    if (this.list.length > 0) {
      Swal.fire('Inforamtion...', 'You Can Update This Record Only', 'info')
    } else {
      this.list_flag = false
      this.create_flag = true
      this.update_flag = false
    }

  }
  async open_update(element:any) {
    console.log(element)
    this.Obj = Object.assign({}, element)
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
    await this.getChildAllotmentLetterData(element)
  }
  open_list() {
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  validateSize(input:any ) {
    const fileSize = input.target.files[0].size / 1024;
    let numb: any = fileSize.toFixed(2);
    if (numb > 20) {
      // alert('to big, maximum is 2MiB. You file size is: ' + numb + ' KB');
      Swal.fire('Information', 'to big, maximum is 20KB. You file size is: ' + numb + ' KB', 'info')
      return false
    } else {
      return true
    }
  }
  async onFileUpload(event:any , files:any ) {
    let resp = await this.validateSize(event)
    if (!resp) {
      return
    }
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    //console.log(this.selectedFile)
  }
  fileURL:any ;
  async view(item:any ) {
    this.spinner.show()
    var obj : any= new Object
    obj['doc_name'] = item['img1_name']
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj, item)
    const res = await this.letterConfigure.getUploadedFileofparty(obj);
    console.log(res)
    if (res) {
      this.spinner.hide()
      var docname = obj['doc_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    } else {
      this.spinner.hide()
    }
  }
  async view2(item:any ) {
    this.spinner.show()
    var obj : any= new Object
    obj['doc_name'] = item['img2_name']
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj, item)
    const res = await this.letterConfigure.getUploadedFileofparty(obj);
    console.log(res)
    if (res) {
      // const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      // this.fileURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.spinner.hide()
      var docname = obj['doc_name'];
      var ext = docname.split('.');


      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

    } else {
      this.spinner.hide()

    }
  }
  async onFileUpload2(event:any , files:any ) {
    let resp = await this.validateSize(event)
    if (!resp) {
      return
    }
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    //console.log(this.selectedFile)
  }


  async Upload(key:any ) {
    this.spinner.show();

    const formData = new FormData();
    formData.append('image', this.selectedFile, this.selectedFile['name']);
    const obj :any = new Object();
    // obj['user_id'] = 0;
    obj['doc_name'] = this.uploader.queue[0].some.name;
    this.Obj['img1_name'] = obj['doc_name']
    this.Obj['file_name'] = obj['doc_name']

    obj['b_acct_id'] = this.b_acct_id;
    // obj['party_id'] = this.party_info['party_id'];
    // obj['id'] = this.new_co_applicant_id + 1;
    // obj['update_key'] = key;
    // obj['offer_cd'] = this.mainService.offer_details['offer_cd'];
    console.log(obj);
    const params = JSON.stringify(obj);
    this.uploader.queue[0].url = this.mainService.httpUrl + '/property/allotmentletter/uploadImage' + params;
    this.uploader.queue[0].upload();
    console.log(this.uploader.queue,'queue')
    this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      if (!response.error) {
        // console.log(JSON.parse(response)['data'],response)
        // this.Obj['img1_id'] = JSON.parse(response)['data']
        this.uploader.queue = []
        this.selectedFile = null
        Swal.fire('Success...', 'Successfully Uploaded', 'success')
        this.spinner.hide();

      } else {

        this.spinner.hide();
        Swal.fire('Error', 'All Files are not uploaded', 'error')
      }
    };
  }

  async Upload2(key:any ) {
    this.spinner.show();

    const formData = new FormData();
    formData.append('image', this.selectedFile, this.selectedFile['name']);
    const obj:any  = new Object();
    // obj['user_id'] = 0;
    obj['doc_name'] = this.uploader.queue[0].some.name;
    this.Obj['img2_name'] = obj['doc_name']
    obj['b_acct_id'] = this.b_acct_id;
    this.Obj['file_name'] = obj['doc_name']
    // obj['party_id'] = this.party_info['party_id'];
    // obj['id'] = this.new_co_applicant_id + 1;
    // obj['update_key'] = key;
    // obj['offer_cd'] = this.mainService.offer_details['offer_cd'];
    console.log(obj);
    const params = JSON.stringify(obj);
    this.uploader.queue[0].url = this.mainService.httpUrl + '/property/allotmentletter/uploadImage' + params;
    this.uploader.queue[0].upload();
    this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      if (!response.error) {
        console.log("Uploaded Done!!")
        this.uploader.queue = []
        this.selectedFile = null
        Swal.fire('Success...', 'Successfully Uploaded', 'success')
        this.spinner.hide();
      } else {
        this.spinner.hide();
        Swal.fire('Error', 'All Files are not uploaded', 'error')
      }
    };
  }
  list = []
  async getAllotmentLetterData() {
    let dummy
    let obj :any = {}
    obj['b_acct_id'] = this.b_acct_id
    let resp = await this.letterConfigure.getAllAllotmentLetterData(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == false) {
      dummy = resp['data']
      this.list = dummy
      this.datasource = new MatTableDataSource(dummy);
      this.datasource.sort = this.sort.toArray()[0];
      this.datasource.paginator = this.paginator.toArray()[0];

    } else {
      Swal.fire('Error', 'Sarver side errror ', 'error')
    }
  }

  async getChildAllotmentLetterData(data:any ) {
    let dummy
    let obj = Object.assign({}, data)
    obj['b_acct_id'] = this.b_acct_id
    let resp = await this.letterConfigure.getAllChildAllotmentLetterData(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == false) {
      this.table_data = resp['data']

    } else {
      Swal.fire('Error', 'Sarver side errror ', 'error')
    }
  }

  async submit() {
    let obj:any  = {}
    obj = this.Obj
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['data'] = this.table_data
    if(!this.table_data.length){
      Swal.fire('Info','Insert Atleast One Data','info')
      return
    }
    console.log(obj)
    let resp = await this.letterConfigure.insertAllotmentLetterData(obj)
    console.log(resp)
    if (resp['error'] == false) {
      await this.getAllotmentLetterData()
      await this.open_list()
      Swal.fire('Success...', 'Created Successfully..', 'success')
      console.log(resp)
    } else {
      console.log(resp, "error")
      Swal.fire('Error', 'Sarver side errror in Child table', 'error')
    }


  }
  async update() {
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['data'] = this.table_data
    console.log(this.Obj)
    let resp = await this.letterConfigure.updateAllotmentLetterData(this.Obj)
    if (resp['error'] == false) {
      Swal.fire('Success', 'Successfully Update', 'success')
      await this.getAllotmentLetterData()
      await this.open_list()
    } else {
      Swal.fire('Error', 'Sarver side errror', 'error')
    }

  }

}
