import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from '../../service/main.service';

@Injectable({
  providedIn: 'root'
})
export class EstimateService {
  httpUrl;
  constructor(private http: HttpClient, private main: MainService) { this.httpUrl = this.main.httpUrl; }
  async getItems(obj: any) {
    // console.log(this.httpUrl)
    const resp = await this.http.get<any>(this.httpUrl + '/depricated/estimation/estimate/getItems' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async getestimate(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/depricated/estimation/estimate/getestimate' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async getData(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/depricated/estimation/estimate/getData' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  // async getBridgeInputs(obj: any) {
  //   const resp = await this.http.get<any>(this.httpUrl + '/depricated/estimation/estimate/getBridgeInputs' + obj).toPromise().then(res => {
  //     return res;
  //   })
  //   return resp;
  // }

  async viewdocs(obj: any) {
    const resp = await this.http.post(this.httpUrl + '/depricated/estimation/estimate/viewdocs', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    //oc
    //   if (resp) {
    //     return resp;
    // }
    //ec
    return resp;
  }
  async getdocslist(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/depricated/estimation/estimate/getdocslist' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async getShudExtraOtherData(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/depricated/estimation/estimate/getShudExtraOtherData' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async getAnalysisItems(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/depricated/estimation/estimate/getAnalysisItems', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async createAnalysis(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/depricated/estimation/estimate/createAnalysis', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async estimateBridge(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/depricated/estimation/estimate/estimateBridge', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


  async measurement(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/depricated/estimation/estimate/measurement', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


  async insertAnaData(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/depricated/estimation/estimate/insertAnaData', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async insertBoqBridge(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/depricated/estimation/estimate/insertBoqBridge', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }







  async getBridgeItems(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/depricated/item_list/item_list/getBridgeItems', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async uploadDOc(obj: any) {
    console.log(this.httpUrl)
    const resp = await this.http.post<any>(this.httpUrl + '/depricated/estimation/estimate/estDocUpload', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async createestimate(obj: any) {
    const res = await this.http.post<any>(this.httpUrl + '/depricated/estimation/estimate/createestimate', obj).toPromise().then(res => {
      return res;
    })
    return res;
  }

  async createEstimateForBridge(obj: any) {
    const res = await this.http.post<any>(this.httpUrl + '/depricated/estimation/estimate/createestimateForBridge', obj).toPromise().then(res => {
      return res;
    })
    return res;
  }

  async bridgeInput(obj: any) {
    const res = await this.http.post<any>(this.httpUrl + '/depricated/estimation/estimate/brideInput', obj).toPromise().then(res => {
      return res;
    })
    return res;
  }

  async updateeestimate(obj: any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/depricated/estimation/estimate/updateestimate', obj).toPromise().then(res => {
      return res;
    });
    return resp;

  }
  async updateestStatus(obj: any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/depricated/estimation/estimate/updateestStatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;

  }
  async updateLifeCycleStatus(obj: any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/depricated/estimation/estimate/updateLifeCycleStatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateLifeCycleStatusForBridgeInput(obj: any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/depricated/estimation/estimate/updateLifeCycleStatusForBridgeInput', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateLifeCycleStatusForMeas(obj: any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/depricated/estimation/estimate/updateLifeCycleStatusForMeas', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateLifeCycleStatusForAna(obj: any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/depricated/estimation/estimate/updateLifeCycleStatusForAna', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateLifeCycleStatusForBoq(obj: any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/depricated/estimation/estimate/updateLifeCycleStatusForBoq', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deletepreimage(obj: any) {

    const resp = await this.http.delete<any>(this.main.httpUrl + '/depricated/estimation/estimate/deletepreimage' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteimage(obj: any) {

    const resp = await this.http.delete<any>(this.main.httpUrl + '/depricated/estimation/estimate/deleteimage' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteline(obj: any) {
    const resp = await this.http.delete<any>(this.main.httpUrl + '/depricated/estimation/estimate/deleteline' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  // async deleteest(obj: any) {

  //   const resp = await this.http.put<any>(this.main.httpUrl + '/depricated/estimation/estimate/deleteest2' , obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }

  async deleteest(obj: any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/depricated/estimation/estimate/deleteest2', obj).toPromise().then(res => {
      return res;
    });
    return resp;

  }


  async getAllBridgeInputs(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/depricated/estimation/estimate/getallbridgeinputs' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
}















