import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { AllEmpService } from '../../service/all-emp.service'
import { MainService } from './../../service/main.service';
import swal from 'sweetalert2'

@Component({
  selector: 'app-hr-budget-info',
  templateUrl: './hr-budget-info.component.html',
  styleUrls: ['./hr-budget-info.component.css']
})
export class HrBudgetInfoComponent implements OnInit {
text: any;


  constructor(private allEmpService: AllEmpService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService, public mainService: MainService) { }
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  displayedColumns = ['desc', 'proposed_amount', 'actual_amount', 'action'];
  datasource:any ;
  erpUser:any
  obj :any = {};
  b_acct_id:any
  allHier:any  = [];
  allHier1 :any = []
  allHier2 :any = [];
  allProjectHier :any = [];
  allProductHier :any = [];
  allActivityHier :any = [];
  unique :any = []
  unique_leaf :any = []
  level1:any  = [];
  level2:any  = []
  level3:any  = []
  level4:any  = []
  level5:any  = []
  level6:any  = []
  level7 :any = []

  async ngOnInit() {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllHier()
    await this.getAllBudget()

  }
  update_flag = true;
  allBudget = []
  async getAllBudget() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.allEmpService.getAllBugdet(obj);
    console.log(resp);
    if (resp['error'] == false) {
      //  this.update_flag=true;
      this.allBudget = resp.data;
      if (this.allBudget.length > 0) {
        this.update_flag = false;


      }
      else {
        this.update_flag = true;
      }
    }
  }






  async getAllHier() {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'bud_hier';
    var resp = await this.allEmpService.getBudgetHierarchy(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.allHier = resp.data;
      this.allHier1 = resp.data;

      for (let i = 0; i < this.allHier.length; i++) {
        console.log(this.allHier[i])
        let str = ""
        for (let j = 1; j < 8; j++) {
          if (this.allHier[i]['lvl' + j + "_cd"] != null && this.allHier[i]['lvl' + j + "_cd"] != "") {
            str += this.allHier[i]['lvl' + j + "_value"] + " > "

          }
        }

        str = str.substring(0, str.length - 2)
        this.allHier[i]['desc'] = str;
        console.log(this.allHier)
      }
      // this.datasource = new MatTableDataSource(this.allHier)
      // this.datasource.paginator = this.paginator;
      // this.datasource.sort = this.sort;
      this.spinner.hide();
    } else {
      this.spinner.hide()
    }
  }



  event_arr :any = []

  makeEventArr(budObj:any , prodArr:any , projArr:any , avtArr:any ) {
    this.event_arr = []
    for (let i = 0; i < avtArr.length; i++) {

      for (let j = 0; j < prodArr.length; j++) {

        for (let k = 0; k < projArr.length; k++) {
          let obj :any = new Object;
          obj["event_code"] = budObj["leaf_cd"] + " - " + prodArr[j]["leaf_cd"] + " - " + projArr[k]["leaf_cd"] + " - " + avtArr[i]["leaf_cd"]
          obj["event_desc"] = budObj["leaf_value"] + " from " + avtArr[i]["leaf_value"] + " of " + prodArr[j]["leaf_value"] + " for " + projArr[k]["leaf_value"]
          obj["event_record_code"] = "R101"
          this.event_arr.push(obj)
        }

      }

    }

  }
  async create() {
    this.refresh()
  }


  flag = 0


  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  async save() {
    this.spinner.show();
    var obj1:any  = new Object()
    obj1 = this.obj;

    for (let i = 0; i < this.allHier1.length; i++) {

      for (let j = 1; j < 8; j++) {

        if (this.allHier1[i]['leaf_cd'] == obj1['leaf_cd']) {


          this.allHier2 = this.allHier1[i];
        }
      }

    }
    console.log(this.allHier2)
    if (this.allHier2['is_leaf'] == 0) {
      swal.fire('Error', 'Please Select The Leaf', 'error');
      this.spinner.hide()
    }
    else {

      this.finalsave();
    }
  }



  async finalsave() {
    this.spinner.show();
    var obj1:any  = new Object()
    obj1['b_acct_id'] = this.b_acct_id;
    obj1['budget_cd'] = this.obj['leaf_cd'];
    obj1['budget_desc'] = this.obj['leaf_value'];


    console.log(obj1)


    var resp = await this.allEmpService.insertBudgetInfo(obj1);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllBudget();
      //  await this.getAllHier();
      swal.fire('Success', ' Add Successfully!', 'success')
      // this.snackBar.open("Add Successfully!", 'Success', {
      //   duration: 5000
      // });
      await this.getAllBudget();
      await this.listfunc();
    } else {
      this.spinner.hide();
    }
  }



  create_flag = false;

  list_flag = true;


  async listfunc() {
    this.list_flag = true
    this.create_flag = false
    //   this.update_flag = false
    this.obj = {}

  }
  updateLevel = 0;
  async createNew() {
    this.create_flag = true;
    this.list_flag = false;
    await this.refresh()
    //
    if (this.update_flag == false) {

      for (let i = 0; i < this.allHier1.length; i++) {


        for (let j = 1; j < 8; j++) {

          if (this.allHier1[i]['leaf_cd'] == this.allBudget[0]['budget_cd']) {
            //  str += this.allHier[i]['lvl' + j + "_value"] + " > "

            this.allHier2 = this.allHier1[i];
          }
        }

      }

      await this.refresh()



      //    $('#myModal1').modal('show');
      for (let i = 0; i < 8; i++) {
        console.log(this.allHier2['leaf_cd'])
        if (this.allHier2['lvl' + i + "_cd"] == this.allHier2['leaf_cd']) {
          this.updateLevel = i
        }

      }


      this.obj = Object.assign({}, this.allHier2);
      await this.onChangeLvl1();
      this.obj = Object.assign({}, this.allHier2);
      console.log(this.obj)

      await this.onChangeLvl2();
      this.obj = Object.assign({}, this.allHier2);
      console.log(this.obj)

      await this.onChangeLvl3();
      this.obj = Object.assign({}, this.allHier2);
      console.log(this.obj)

      await this.onChangeLvl4();
      this.obj = Object.assign({}, this.allHier2);
      console.log(this.obj)

      await this.onChangeLvl5();
      this.obj = Object.assign({}, this.allHier2);
      console.log(this.obj)

      await this.onChangeLvl6();
      this.obj = Object.assign({}, this.allHier2);
      console.log(this.obj)

      this.spinner.hide()
      //  $('.nav-tabs a[href="#tab-3"]').tab('show')
      for (let i = 0; i < this.allHier.length; i++) {
        if (this.allHier[i]['lvl1_cd'] == this.obj['lvl1_cd']) {
          let hier_row = this.allHier[i]
          let keys = Object.keys(hier_row)
          //  console.log(keys)
          // console.log(hier_row)
          for (let j = 0; j < keys.length; j++) {
            if (!this.unique.includes(hier_row[keys[j]])) {
              this.unique.push(hier_row[keys[j]])
              //   console.log(this.unique)
            }

          }
          this.unique_leaf.push(hier_row['leaf_cd'])
          console.log(this.unique_leaf)

        }









      }

    }

  }










  async onChangeLvl1() {
    for (let i = 0; i < this.level1.length; i++) {
      if (this.level1[i]['lvl1_cd'] == this.obj['lvl1_cd']) {
        this.obj['lvl1_value'] = this.level1[i]['lvl1_value']
      }
    }
    let temp :any = []
    this.level2 = []

    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl1_cd'] == this.obj['lvl1_cd']) {
        let hier_row = this.allHier[i]
        let keys = Object.keys(hier_row)
        for (let j = 0; j < keys.length; j++) {
          if (!this.unique.includes(hier_row[keys[j]])) {
            this.unique.push(hier_row[keys[j]])
          }
        } this.unique_leaf.push(hier_row['leaf_cd'])
        if (this.allHier[i]['lvl2_cd'] != null && this.allHier[i]['lvl2_cd'] != '') {
          if (!temp.includes(this.allHier[i]['lvl2_cd'])) {
            temp.push(this.allHier[i]['lvl2_cd'])
            let ob:any  = new   Object()
            ob['lvl2_cd'] = this.allHier[i]['lvl2_cd']
            ob['lvl2_value'] = this.allHier[i]['lvl2_value']
            this.level2.push(ob)
          }
        }
      }
    }
    this.level3 = []
    this.level4 = []
    this.level5 = []
    this.level6 = []
    this.level7 = []

    for (let i = 2; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null

    }

    await this.makingLeafValues()


  }

  async onChangeLvl2() {
    for (let i = 0; i < this.level2.length; i++) {
      if (this.level2[i]['lvl2_cd'] == this.obj['lvl2_cd']) {
        this.obj['lvl2_value'] = this.level2[i]['lvl2_value']
      }
    }
    let temp:any  =[]
    this.level3 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl2_cd'] == this.obj['lvl2_cd']) {
        if (this.allHier[i]['lvl3_cd'] != null && this.allHier[i]['lvl3_cd'] != '') {

          if (!temp.includes(this.allHier[i]['lvl3_cd'])) {
            temp.push(this.allHier[i]['lvl3_cd'])
            let ob:any  = new   Object()
            ob['lvl3_cd'] = this.allHier[i]['lvl3_cd']
            ob['lvl3_value'] = this.allHier[i]['lvl3_value']
            this.level3.push(ob)
          }
        }
      }

    }
    this.level4 = []
    this.level5 = []
    this.level6 = []
    this.level7 = []

    for (let i = 3; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null

    }

    await this.makingLeafValues()


  }


  async onChangeLvl3() {
    for (let i = 0; i < this.level3.length; i++) {
      if (this.level3[i]['lvl3_cd'] == this.obj['lvl3_cd']) {
        this.obj['lvl3_value'] = this.level3[i]['lvl3_value']
      }
    }
    let temp:any  =[]
    this.level4 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl3_cd'] == this.obj['lvl3_cd']) {
        if (this.allHier[i]['lvl4_cd'] != null && this.allHier[i]['lvl4_cd'] != '') {

          if (!temp.includes(this.allHier[i]['lvl4_cd'])) {
            temp.push(this.allHier[i]['lvl4_cd'])
            let ob:any  = new   Object()
            ob['lvl4_cd'] = this.allHier[i]['lvl4_cd']
            ob['lvl4_value'] = this.allHier[i]['lvl4_value']
            this.level4.push(ob)
          }
        }
      }
    }
    this.level5 = []
    this.level6 = []
    this.level7 = []

    for (let i = 4; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null

    }


    await this.makingLeafValues()

  }

  async onChangeLvl4() {
    for (let i = 0; i < this.level4.length; i++) {
      if (this.level4[i]['lvl4_cd'] == this.obj['lvl4_cd']) {
        this.obj['lvl4_value'] = this.level4[i]['lvl4_value']
      }
    }
    let temp:any  =[]
    this.level5 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl4_cd'] == this.obj['lvl4_cd']) {
        if (this.allHier[i]['lvl5_cd'] != null && this.allHier[i]['lvl5_cd'] != '') {

          if (!temp.includes(this.allHier[i]['lvl5_cd'])) {
            temp.push(this.allHier[i]['lvl5_cd'])
            let ob:any  = new   Object()
            ob['lvl5_cd'] = this.allHier[i]['lvl5_cd']
            ob['lvl5_value'] = this.allHier[i]['lvl5_value']
            this.level5.push(ob)
          }
        }
      }
    }
    this.level6 = []
    this.level7 = []

    for (let i = 5; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null

    }


    await this.makingLeafValues()

  }

  async onChangeLvl5() {
    for (let i = 0; i < this.level5.length; i++) {
      if (this.level5[i]['lvl5_cd'] == this.obj['lvl5_cd']) {
        this.obj['lvl5_value'] = this.level5[i]['lvl5_value']
      }
    }
    let temp:any  =[]
    this.level6 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl5_cd'] == this.obj['lvl5_cd']) {
        if (this.allHier[i]['lvl6_cd'] != null && this.allHier[i]['lvl6_cd'] != '') {

          if (!temp.includes(this.allHier[i]['lvl6_cd'])) {
            temp.push(this.allHier[i]['lvl6_cd'])
            let ob:any  = new   Object()
            ob['lvl6_cd'] = this.allHier[i]['lvl6_cd']
            ob['lvl6_value'] = this.allHier[i]['lvl6_value']
            this.level6.push(ob)
          }
        }
      }

    }
    this.level7 = []

    for (let i = 6; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null

    }

    await this.makingLeafValues()


  }


  async onChangeLvl6() {
    for (let i = 0; i < this.level6.length; i++) {
      if (this.level6[i]['lvl6_cd'] == this.obj['lvl6_cd']) {
        this.obj['lvl6_value'] = this.level6[i]['lvl6_value']
      }
    }
    let temp:any  =[]
    this.level7 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl6_cd'] == this.obj['lvl6_cd']) {
        if (this.allHier[i]['lvl7_cd'] != null && this.allHier[i]['lvl7_cd'] != '') {

          if (!temp.includes(this.allHier[i]['lvl7_cd'])) {
            temp.push(this.allHier[i]['lvl7_cd'])
            let ob:any  = new   Object()
            ob['lvl7_cd'] = this.allHier[i]['lvl7_cd']
            ob['lvl7_value'] = this.allHier[i]['lvl7_value']
            this.level7.push(ob)
          }
        }
      }

    }

    for (let i = 7; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null

    }

    await this.makingLeafValues()


  }
  async onChangeLvl7() {
    for (let i = 0; i < this.level7.length; i++) {
      if (this.level7[i]['lvl7_cd'] == this.obj['lvl7_cd']) {
        this.obj['lvl7_value'] = this.level7[i]['lvl7_value']
      }
    }

    await this.makingLeafValues()



  }


  allocationParentObj:any  = {}
  realAllocationObj :any = {}
  childrenArr:any  = []
  realchildrenArr :any = []
  async allocate(element:any ) {
    this.childrenArr = []
    this.realchildrenArr = []

    this.refresh()
    //  $('#myModal3').modal('show');
    this.allocationParentObj = Object.assign({}, element)
    this.realAllocationObj = Object.assign({}, element)

    let temp_lvl = 0
    for (let i = 1; i < 8; i++) {
      if (element["lvl" + i + "_cd"] == element['leaf_cd']) {
        temp_lvl = i
      }
    }
    let next_lvl = temp_lvl + 1
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]["lvl" + temp_lvl + "_cd"] == element['leaf_cd']) {
        if (this.allHier[i]["lvl" + next_lvl + "_cd"] == this.allHier[i]['leaf_cd']) {
          this.childrenArr.push(Object.assign({}, this.allHier[i]))
          this.realchildrenArr.push(Object.assign({}, this.allHier[i]))
        }
      }
    }

  }


  check(i:any ) {

    if (this.childrenArr[i]['proposed_amount'] < this.childrenArr[i]['allocated_amount']) {
      swal.fire('Error', "Proposed Amount should be greater than or equal to the Allocated Amount");
      this.childrenArr[i] = this.realchildrenArr[i]
      return;
    } else {
      this.childrenArr[i]['remaining_amount'] = this.childrenArr[i]['proposed_amount'] - this.childrenArr[i]['allocated_amount']

    }
    let parentallocatedAmount = 0
    for (let j = 0; j < this.childrenArr.length; j++) {
      parentallocatedAmount = parentallocatedAmount + Number(this.childrenArr[j]['proposed_amount'])
    }
    if (this.allocationParentObj['proposed_amount'] < parentallocatedAmount) {
      swal.fire('Error', "Sum of all children's proposed Amount should be less than or equal to the parent's proposed amount");
      this.childrenArr[i] = this.realchildrenArr[i]
      return;
    }
    this.allocationParentObj['allocated_amount'] = parentallocatedAmount
    this.allocationParentObj['remaining_amount'] = this.allocationParentObj['proposed_amount'] - parentallocatedAmount

  }
  checkRoot() {
    let parentallocatedAmount = 0
    for (let j = 0; j < this.childrenArr.length; j++) {
      parentallocatedAmount = parentallocatedAmount + Number(this.childrenArr[j]['proposed_amount'])
    }
    if (this.allocationParentObj['proposed_amount'] < parentallocatedAmount) {
      swal.fire('Error', "Proposed Amount should be greator than or equal to the allocated amount");
      this.allocationParentObj['proposed_amount'] = this.realAllocationObj['proposed_amount']
      return;
    }
    this.allocationParentObj['remaining_amount'] = this.allocationParentObj['proposed_amount'] - this.allocationParentObj['allocated_amount']

  }


  async open_update(element:any ) {
    //  this.spinner.show()
    this.create_flag = false;
    this.list_flag = false;

    await this.refresh()



    for (let i = 0; i < 8; i++) {
      if (element['lvl' + i + "_cd"] == element['leaf_cd']) {
        this.updateLevel = i
      }
    }

    //   $('#myModal1').modal('show');
    for (let i = 0; i < 8; i++) {
      if (element['lvl' + i + "_cd"] == element['leaf_cd']) {
        this.updateLevel = i
      }
    }
    this.obj = Object.assign({}, element);
    await this.onChangeLvl1();
    this.obj = Object.assign({}, element);

    await this.onChangeLvl2();
    this.obj = Object.assign({}, element);

    await this.onChangeLvl3();
    this.obj = Object.assign({}, element);

    await this.onChangeLvl4();
    this.obj = Object.assign({}, element);

    await this.onChangeLvl5();
    this.obj = Object.assign({}, element);

    await this.onChangeLvl6();
    this.obj = Object.assign({}, element);

    this.spinner.hide()
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl1_cd'] == this.obj['lvl1_cd']) {
        let hier_row = this.allHier[i]
        let keys = Object.keys(hier_row)
        for (let j = 0; j < keys.length; j++) {
          if (!this.unique.includes(hier_row[keys[j]])) {
            this.unique.push(hier_row[keys[j]])

          }

        }
        this.unique_leaf.push(hier_row['leaf_cd'])
      }
    }
  }



  refresh() {
    let temp:any  =[]
    this.level1 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (!temp.includes(this.allHier[i]['lvl1_cd'])) {
        temp.push(this.allHier[i]['lvl1_cd'])
        let ob:any  = new   Object()
        ob['lvl1_cd'] = this.allHier[i]['lvl1_cd']
        ob['lvl1_value'] = this.allHier[i]['lvl1_value']
        this.level1.push(ob)
      }
    }
    this.obj = {};
    this.level2 = []

    this.level3 = []
    this.level4 = []
    this.level5 = []
    this.level6 = []
    this.level7 = []
    for (let i = 0; i < this.allHier.length; i++) {


      let hier_row = this.allHier[i]
      let keys = Object.keys(hier_row)
      for (let j = 0; j < keys.length; j++) {
        if (!this.unique.includes(hier_row[keys[j]])) {
          this.unique.push(hier_row[keys[j]])
        }
      } this.unique_leaf.push(hier_row['leaf_cd'])


    }
  }

  async addNew(i:any ) {
    if (this.obj['lvl' + i + '_type'] == 'text') {
      this.obj['lvl' + i + '_type'] = ''

    } else {
      this.obj['lvl' + i + '_type'] = 'text'

    }
    this.obj['lvl' + i + '_cd'] = null
    this.obj['lvl' + i + '_value'] = null
    for (let j = i; j < 8; j++) {
      if (this.obj['lvl' + j + '_type'] != 'text') {
        this.obj['lvl' + j + '_cd'] = null
        this.obj['lvl' + j + '_value'] = null
      }

    }
    await this.makingLeafValues()

  }


  async makingLeafValues() {

    if (this.obj['lvl7_cd'] != undefined && this.obj['lvl7_cd'] != '' && this.obj['lvl7_cd'] != null) {
      this.obj['leaf_cd'] = this.obj['lvl7_cd']
      this.obj['leaf_value'] = this.obj['lvl7_value']
    } else if (this.obj['lvl6_cd'] != undefined && this.obj['lvl6_cd'] != '' && this.obj['lvl6_cd'] != null) {
      this.obj['leaf_cd'] = this.obj['lvl6_cd']
      this.obj['leaf_value'] = this.obj['lvl6_value']
    } else if (this.obj['lvl5_cd'] != undefined && this.obj['lvl5_cd'] != '' && this.obj['lvl5_cd'] != null) {
      this.obj['leaf_cd'] = this.obj['lvl5_cd']
      this.obj['leaf_value'] = this.obj['lvl5_value']
    } else if (this.obj['lvl4_cd'] != undefined && this.obj['lvl4_cd'] != '' && this.obj['lvl4_cd'] != null) {
      this.obj['leaf_cd'] = this.obj['lvl4_cd']
      this.obj['leaf_value'] = this.obj['lvl4_value']
    } else if (this.obj['lvl3_cd'] != undefined && this.obj['lvl3_cd'] != '' && this.obj['lvl3_cd'] != null) {
      this.obj['leaf_cd'] = this.obj['lvl3_cd']
      this.obj['leaf_value'] = this.obj['lvl3_value']
    } else if (this.obj['lvl2_cd'] != undefined && this.obj['lvl2_cd'] != '' && this.obj['lvl2_cd'] != null) {
      this.obj['leaf_cd'] = this.obj['lvl2_cd']
      this.obj['leaf_value'] = this.obj['lvl2_value']
    } else if (this.obj['lvl1_cd'] != undefined && this.obj['lvl1_cd'] != '' && this.obj['lvl1_cd'] != null) {
      this.obj['leaf_cd'] = this.obj['lvl1_cd']
      this.obj['leaf_value'] = this.obj['lvl1_value']
    }


  }

  imageBlobUrl:any ;
  imgURL:any
  selectedFile:any  = null;
  isUpload:any ;
  public imagePath:any ;
  httpUrl:any ;
  uploader:any ;

  onFileUpload(event:any , files:any ) {
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };

  }


  is_header_present = false;

  leafDetection() {
  }
  async upload() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('image', this.selectedFile, this.selectedFile.name);
    const obj :any = new Object();
    obj['b_acct_id'] = this.erpUser.b_acct_id;
    obj['file_name'] = this.uploader.queue[0].some.name;
    var extention = obj['file_name'].split(".")
    obj['create_user_id'] = this.erpUser.user_id;
    obj['is_header_present'] = 1;
    obj['table_name'] = 'bud_hier';

    obj["event_record_code"] = "R101"
    this.spinner.show()
    if (extention[1].toLowerCase() == 'xlsx') {
      const params = JSON.stringify(obj);
      this.uploader.queue[0].url = this.httpUrl + '/accounts/hierarchy/processHierarchyFile' + params;
      this.uploader.queue[0].upload();
      this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        var resp = JSON.parse(response)
        if (resp.error == false) {
          await this.getAllHier();
          this.spinner.hide();

          swal.fire('Success', 'File Uploaded Successfully!!', 'success');
        } else {
          this.spinner.hide()
          swal.fire('Error', resp.data, 'error');
        }
      };
    }
    else {
      this.spinner.hide();
      swal.fire('Error', 'Please Upload Our Template File !!', 'error');
    }
  }

}
