import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class EstablishmentService {

  httpUrl: any;

  employee_id: any;
  complaint_detail: any;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/hr";
  }
  //  all employee
  async getEmployeeMasterData(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/emp_info/empInfo/getAllEmployee' + (b_acct_id)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //arrangment
  async addEstablishment(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment/createArrangement', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async getallEstablishment(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getAllArrangement' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }










  //complaint
  async createComplaint(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + `/establishment_info/complaint/createComplaint`, obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createCompWithoutdoc(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/complaint/createCompWithoutdoc', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //Deepankar
  async updateComplaintDetail(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/complaint/updateComplaintDetail', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateComplaintDetail1(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/complaint/upadteWithoutDoc', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async closeComplaint(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/complaint/closeComplaint', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async forwardComplaint(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/complaint/forwardComplaint', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async approveComplaint(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/complaint/approveComplaint', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async suspendCompEmployee(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/complaint/suspendCompEmployee', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getComplaintStatus(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/complaint/getComplaintStatus' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getComplaintFile(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/complaint/getComplaintFile' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getUploadedFileData_N(obj: any) {
    const resp = await this.http.post(this.httpUrl + '/establishment_info/complaint/getUploadedFileData_N', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }
  //Deepankar
  async changeComplaintStatus(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/complaint/changeComplaintStatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAllComplaints(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/complaint/getAllComplaints' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  //Enquiry
  async setupEnquiry(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/enquiry/setupEnquiry', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateEnquiry(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/enquiry/updateEnquiry', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async closeEnquiry(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/enquiry/closeEnquiry', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // async submitChargesheet(obj :any) {
  //   const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/enquiry/submitChargesheet',obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  async submitChargesheet(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/enquiry/submitChargeSheetWithoutDoc', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getEnquiryForComplaint(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/enquiry/getEnquiryForComplaint' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }




  //Leaves
  async adjustLeave(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/leaveInfo/adjustLeaves', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async carryForwardLeav(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/leaveInfo/carryForwardLeaves', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getLeaveInfo(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/leaveInfo/getLeaveInfo' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getRemainingLeave(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/leaveInfo/getRemainingLeave' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async checkLeaveInfo(obj: any) {
    const resp = await this.http.post(this.httpUrl + '/leaveInfo/checkLeaveInfo', obj).toPromise().then(res => {
      return res
    })
    return resp
  }
  async addLeaveInfo(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/leaveInfo/addLeaveInfo', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateLeaveInfo(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/leaveInfo/updateLeaveInfo', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteLeaveInfo(obj: any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/leaveInfo/deleteLeaveInfo' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // Leave Apply
  async getAllLeaveLedger(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/leaveLedger/getLeaveRecords' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getRemainingLeaves(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/leaveInfo/getRemainingLeaves' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async applyForLeave(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/leaveLedger/applyForLeave' + obj, "").toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteLeaveRecord(obj: any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/leaveLedger/deleteLeaveRecord' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  // hr approve extra leave
  async deleteByHr(obj: any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/leaveLedger/deleteLeaveRecord' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async approveLeave(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/leaveLedger/approveLeave', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async rejectLeave(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/leaveLedger/rejectLeave', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //termination
  async getAllTerminatedEmployees(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/termination/getAllTerminatedEmployees' + (b_acct_id)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getBillDetailForTermination(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/termination/getBillDetailForTermination' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async terminateEmployee(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/termination/terminateEmployee', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getEnquiryForTermination(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/termination/getEnquiryForTermination' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getComp(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/termination/getComp' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getTermination(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/termination/getTermination' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async approve(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/termination/approve', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async finalterminate(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/termination/finalTerminateEmployee', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getUploadedFileData_T(obj: any) {
    const resp = await this.http.post(this.httpUrl + '/establishment_info/termination/getUploadedFileData_T', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getTerminateFile(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/termination/getTerminateFile' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //retirement
  async retireEmployee(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/retirement/retireEmployee', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addretirement(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/retirement/addretirement', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllRetireEmployee(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/retirement/getretirementinfo' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async getBillDetailForRetirement(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/retirement/getBillDetailForRetirement' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async onTimeRetireEmployee(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/retirement/onTimeRetireEmployee', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //death



  async getNominee(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/party/nominee/getNominee' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }



  async getDeadEmployees(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/death/getDeadEmployees' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async deathOfEmployee(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/death/deathOfEmployee', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getBillDetailForDeath(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/death/getBillDetailForDeath' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async getNomineePayments(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/death/getNomineePayments' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async nomineePaymentAccrued(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/death/nomineePaymentAccrued', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async nomineePaymentComplete(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/death/nomineePaymentComplete', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  //reappointment


  async updateArrangement(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment/updateArrangement', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // Employee Transfer (Anant)
  async getAllTransferredEmployees(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/transfer/getAllTransferredEmployees' + (b_acct_id)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addEmpTransfer(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/transfer/transferEmployee', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async revoketransfer(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/transfer/revoketransfer', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // Employee Resignation (Anant)
  async getAllResignation(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/resignation/getallresignations' + (b_acct_id)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addResign(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/resignation/resign', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async approveResignation(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/resignation/approveResignation', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;

  }
  async resignationComplete(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/resignation/resignationComplete', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;

  }
  async getCurrentArrangement(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getCurrentArrangementOfParty' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async addPostingInfo(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/post/assignPost', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCurrentlyAssignedPost(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/post/getCurrentlyAssignedPost' + (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateEmpPosting(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/post/updateEmpPosting', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async getPostingInfo(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/post/getAllAssignedPosts' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCurrentlyAssignedPosts(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/post/getCurrentlyAssignedPosts' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deactivatePost(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/post/deactivatePost', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updatePost(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/post/updatePost', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async addDCPPromtion(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/promotion/addDCPPromtion', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addACP(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/promotion/addACP', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteACP(obj: any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/establishment_info/promotion/deleteACP' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getPreviousPromotions(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/promotion/getAllPromotions' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllFixedPay(obj: any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/fixedPayAmount/ruleGetAllFixedPayForRule' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async addPromtion(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/promotion/addPromtion', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async promoteEmployee(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/promotion/promoteEmployee', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getDate() {
    const resp = await this.http.get<any>(this.main.httpUrl + '/portal/getServerDate').toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async rejectPromotion(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/promotion/rejectPromotion', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //Probation

  async getAllProbationInfo(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/probation/getAllProbationInfo' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getPartyProbationInfo(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/probation/getPartyProbationInfo' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async addPartyProbation(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/probation/addPartyProbation', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateProbation(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/probation/updateProbation', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //arrangement

  async getAllCurrentArrangements(obj: any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/establishment/getAllCurrentEstablishementInfo' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getArrayAllCurrentEstablishementInfo(obj: any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/establishment/getArrayAllCurrentEstablishementInfo' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //attendance info

  async gatAllAttendance(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/attendance/getAttendanceDetail' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addAttendanceInfo(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/attendance/addEmployeesAttendanceDetail', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateAttendanceInfo(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/attendance/updateAttendanceDetail', (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //shweta code
  async getallmonth(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/attendance/getallmonth' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getMatrix(obj: any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/getPayMatrix' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //Suspension
  async getAllsuspensions(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/suspension/getAllsuspensions' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //deepankar
  async getEmpSuspension(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/suspension/getEmpSuspension' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async changeSuspensionStatus(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/suspension/changeSuspensionStatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async restructure(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/suspension/restructure', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async reinstate(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/suspension/reinstate', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //deepankar
  async suspensionWithdraw(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/suspension/suspensionWithdraw', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async suspendEmployee(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/establishment_info/suspension/suspendEmployee', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updatesuspension(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/establishment_info/suspension/updatesuspension', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getbillforSuspension(obj: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getbillforSuspension' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async addarrayVariablePay(obj: any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/variablePay/addarrayVariablePay', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }



  async getUploadedFileData(obj: any) {

    const resp = await this.http.post(this.httpUrl + '/setting/notice/getUploadedFileData', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }
  async getAllNotice(b_acct_id: any) {
    const resp = await this.http.get<any>(this.httpUrl + '/setting/notice/getuploadednotice' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deleteNotice(obj: any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/setting/notice/deletenotice' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateNotice(obj: any) {
    const resp = await this.http.put<any>(this.httpUrl + '/setting/notice/updateNotice', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


}
