<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails1']}}
                        <!-- Employee Bank Info -->
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" *ngIf="list_flag" data-toggle="tab" (click)="chamgeTab()"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails2']}}<!-- Employee Bank Detail -->
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="chamgeTab()"><i class="ti-settings"></i> Add Employee Bank Detail</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" *ngIf="update_flag" data-toggle="tab" (click)="chamgeTab()"><i class="ti-announcement"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails3']}}<!-- Update Employee Bank Detail -->
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">
                            <br>

                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_create()>Add/Update Bank Detail</button>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails4']}}
                                    <!-- Select Employee --> :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmployees" bindLabel="emp_name" (change)="changeEmployee()" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails4']}}" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        <!-- Select Employee -->
                                    </ng-select>

                                </div>

                            </div>

                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails5']}}
                                    <!-- Bank -->:
                                </div>
                                <div class="col-3 ">
                                    {{bankaccObj1['bank_code']}}
                                </div>
                                <div class="col-3 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails6']}}
                                    <!-- Branch -->:
                                </div>
                                <div class="col-3">
                                    {{bankaccObj1['branch_code']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails7']}}
                                    <!-- IFSC Code -->:
                                </div>
                                <div class="col-3 ">
                                    {{[bankaccObj1['ifsc_code']]}}
                                </div>
                                <div class="col-3 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails8']}}
                                    <!-- Account No -->:
                                </div>
                                <div class="col-3">
                                    {{ bankaccObj1['acct_no']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails9']}}
                                    <!-- PF Account No -->:
                                </div>
                                <div class="col-3 ">
                                    {{ bankaccObj1['pf_acct_no']}}
                                </div>
                                <div class="col-3 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails10']}}
                                    <!-- GIS No -->:
                                </div>
                                <div class="col-3">
                                    {{ bankaccObj1['gis_no']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails11']}}
                                    <!-- PF Type -->:
                                </div>
                                <div class="col-3 ">
                                    {{ bankaccObj1['pf_type']}}
                                </div>
                                <div class="col-3 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails12']}}
                                    <!-- PF IFSC Code -->:
                                </div>
                                <div class="col-3">
                                    {{ bankaccObj1['pf_ifsc_code']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails13']}}
                                    <!-- PRAN NPS No -->:
                                </div>
                                <div class="col-3 ">
                                    {{ bankaccObj1['nps_no']}}
                                </div>

                            </div>

                            <!-- <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="openUpdate()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails14']}}</button>
                                </div>
                            </div> -->

                            <!-- <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="bank_code">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Bank

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ mainService.codeValueShowObj['HR0001'][element.bank_code]}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="branch_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Branch

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0002'][element.branch_code]}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="ifsc_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>IFSC Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0003'][element.ifsc_code]}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="acct_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Account Number
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.acct_no}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pf_acct_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>PF Account Number
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pf_acct_no}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" (click)="openUpdate(element)">Update</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div> -->



                        </div>
                        <!-- <div class="tab-pane fade" id="tab-2">

                            <br>
                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Employee :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmployees" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="Select Employee" [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Employee ID :
                                </div>
                                <div class="col-4">
                                    <h6>{{selectEmpObj['emp_id']}}</h6>
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Bank Name * :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0001']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="bankaccObj['bank_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>


                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Branch Name * :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0002']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="bankaccObj['branch_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    IFSC Code * :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0003']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="bankaccObj['ifsc_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Account Number * :
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="bankaccObj['acct_no']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    PF Account Number * :
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="bankaccObj['pf_acct_no']">

                                </div>
                            </div>

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    GIS No :
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="bankaccObj['gis_no']">

                                </div>
                            </div>

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    NPS No :
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="bankaccObj['nps_no']">

                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="addBankAcctInfo()">Submit</button>

                                </div>
                            </div>
                        </div> -->
                        <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                            <br>

                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails4']}}
                                    <!-- Select Employee -->:
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="allEmployees" disabled bindLabel="emp_name" (change)="changeEmployee()" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails4']}}" [(ngModel)]="selectEmpObj['le_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        -->
                                        <ng-select [items]="allEmployees" bindLabel="emp_name" (change)="changeEmployee()" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails4']}}" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name" disabled>

                                        <!-- Select Employee -->
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    Employee ID
                                </div>
                                <div class="col-4">
                                    <h6>
                                       {{getNumberFormat(emp_id)}}</h6>
                                </div>
                            </div>
                            <br> -->

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails7']}}
                                    <!-- IFSC Code -->:<span style="color:red">*</span>
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="mainService.codeValueTechObj['HR0003']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="bankaccObj['ifsc_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->
                                    <!-- <input class="form-control" (change)="checkIFSC()" [(ngModel)]="bankaccObj['ifsc_code']"> -->
                                    <input class="form-control"  #ngInput (ngModelChange)="checkIFSC(ngInput.value)" [(ngModel)]="bankaccObj['ifsc_code']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails16']}}
                                    <!-- Bank Name -->:
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="mainService.codeValueTechObj['HR0001']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="bankaccObj['bank_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->
                                    <input class="form-control"  [(ngModel)]="bankaccObj['bank_code']" disabled>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails17']}}<!--Branch Name :-->
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="mainService.codeValueTechObj['HR0002']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="bankaccObj['branch_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->
                                    <input class="form-control"  [(ngModel)]="bankaccObj['branch_code']" disabled>
                                </div>
                            </div>



                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails18']}}
                                    <!-- Account Number -->:<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control" [(ngModel)]="bankaccObj['acct_no']"> -->
                                    <input class="form-control" type="number"  #ngInput1 (ngModelChange)="checkAccount(ngInput1.value)"  [(ngModel)]="bankaccObj['acct_no']"> <br>
                                    <!-- <span *ngIf="accNoLen==false" style="color: red;">Ac.No. Must be in range of 8 to 18 </span>
                                    <span *ngIf="accNoLen==true" style="color: green;">Your Ac.No. is {{acnolen}} digit long </span> -->

                                    <ng-container *ngIf="accNoLen==false; else elseTemplate"  style="color: red">
                                        <span style="color: red"> <i>Ac.No. Must be in range of 8 to 18 </i>  </span>
                                     </ng-container>
                                                <ng-template #elseTemplate  style="color: green">
                                                             <span style="color: green"> <i>Your Ac.No. is {{acnolen}} digit long </i></span>
                                                 </ng-template>



                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails19']}}
                                    <!-- PF Account Number -->:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="bankaccObj['pf_acct_no']">

                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails10']}}
                                    <!-- GIS No -->:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="bankaccObj['gis_no']">

                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails11']}}
                                    <!-- PF Type -->:
                                </div>
                                <div class="col-4 ">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0048']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="bankaccObj['pf_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails12']}}
                                    <!-- PF IFSC Code -->:
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0049']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="bankaccObj['pf_ifsc_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails13']}}
                                    <!-- PRAN NPS No -->:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="bankaccObj['nps_no']">

                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateBankAcctInfo()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails14']}}<!-- Update -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-BankDetails20']}}
    <!-- Loading -->....</ngx-spinner>
