import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import * as pdfMake from "pdfmake/build/pdfmake";
import { DomSanitizer } from '@angular/platform-browser';
import * as XLSX from 'xlsx';
import swal from 'sweetalert2';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MainService } from '../../service/main.service';
declare var $: any;
import { FileUploader } from 'ng2-file-upload';
import Swal from 'sweetalert2';
import { LocationService } from '../../service/location.service';
import { ExcelService } from '../../../emb/service/file-export.service';
import { AuthenticationService } from '../../../authentication.service';
// import { AgmCoreModule } from '@agm/core';
import { MatStepper } from '@angular/material/stepper';
import { ZoneService } from '../../service/zone.service';
import { HierarchyService } from '../../service/hierarchy.service';
import { TrackingCommonService } from '../../service/tracking-common.service';
import { UserlogService } from '../../service/userlog.service';
declare const google: any;
@Component({
  selector: 'app-boundry-map',
  templateUrl: './boundry-map.component.html',
  styleUrls: ['./boundry-map.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class BoundryMapComponent implements OnInit {
  datasource:any;
  datasource2:any
  isLinear = false;
  first:any = true;
  second:any = true;
  radio :any= false;
  radio2 :any= false;
  displayedColumns = ['id', 'loc_type', 'zone_name', 'zone_cd', 'action'];
  GISArr:any = []
  third:any = true;
  fourth:any = true;
  work_id:any;
  BoqTable :any= [];
  total_sum:any = 0;
  Obj :any= {}
  docArr :any= [{}]
  enableStep3: any;
  b_acct_id:any;
  ebillUser:any;
  erpUser:any;
  imgURL:any;
  updateObj :any= {};
  selectedFile: any = null;
  isUpload:any;
  state_arr = [];
  public imagePath:any
  httpUrl:any;
  uploader:any;
  choosen_file = false
  choosen_file_uploaded = false
  center!: google.maps.LatLngLiteral ;
  markerPositions: google.maps.LatLngLiteral[] = [];
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;
  //for map
  zoom: any;
  // initial center position for the map
  lat: number = 26.850000;
  lng: number = 80.949997;

  paths: any = []

  loaction_type = [
    { id: 1, name: 'ZONE' },

  ]

  async view_on_map(element:any) {
    let dummy = []
    let dummy2 = []
    this.paths = null;
    this.zoom = null
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_id'] = element['zone_id']
    console.log(obj);
    var resp :any= await this.zone.getcordinateLocationData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == true) {
      swal.fire('error', 'server side error', 'error');
      return
    }
    else {
      dummy = resp['data']
      console.log(dummy)
    }
    for (let i = 0; i < dummy.length; i++) {
      let obj2 :any= {}

      obj2['lat'] = dummy[i]['loc_gis_x_long']
      obj2['lng'] = dummy[i]['loc_gis_y_lat']

      this.lat = obj2['lat']
      this.lng = obj2['lng']
      dummy2.push(obj2)
    }

    this.paths = dummy2
    console.log(this.paths)
    this.center =   {
      lat: 26.851991 , lng: 80.954479
    };

    this.Obj['loc_geo_calc_value'] = google.maps.geometry.spherical.computeArea(this.paths)
    console.log(this.Obj['loc_geo_calc_value'])
    // this.location_area = parseFloat(this.Obj['loc_geo_calc_value']).toFixed(2);
    this.zoom = 10

    $('#myModal2').modal('show');
  }

  addMarker(event: google.maps.MapMouseEvent) {
    this.markerPositions.push(event.latLng!.toJSON());
  }



  reset() {
    this.Obj = {}
    this.docArr = [{}]
    this.GISArr = []
    // this.stepper1.selectedIndex = 0
    // this.stepper2.selectedIndex = 0
    this.Obj['loc_geo_ent_value'] = 'SQM'
  }
  constructor(
    private spinner: NgxSpinnerService,
    private commonS: TrackingCommonService,
    public auth: AuthenticationService,
    private hierarchyS: HierarchyService,
    private userlog : UserlogService,
    private locationS: LocationService,
    private excel: ExcelService, public mainService: MainService, public zone: ZoneService, public toastr: ToastrService, private sanitizer: DomSanitizer) { }
  allProjects = []
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    // console.log(this.ebillUser, 'b_acct_id')
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file:any) => { file.withCredentials = false; };
    this.httpUrl = this.mainService.httpUrl
    this.choosen_file = false
    // this.radio = true
    this.choosen_file_uploaded = false
    this.Obj['loc_geo_ent_value'] = 'SQM'
    await this.getLocationList()
    this.paths = []
    this.Obj['loc_geo_ent_value'] = 'SQM'
    this.getproject();
  }


  // //////////////////////hierachy /////////////////
  data = [{}]
  project_hier:any = []
  level1:any = []
  level2:any = []
  level3:any = []
  level4:any = []
  level5:any = []
  level6:any = []
  level7:any = []
  async getproject() {
    let obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'TRACKING'
    let resp = await this.hierarchyS.getprojectHierarchy(obj)
    console.log(resp)
    console.log(this.data)
    for (let i = 0; i < resp.data.length; i++) {
      if (resp.data[i]['lvl1_value'] == "All Projects") {
        resp.data[i]['lvl1_value'] = 'TRACKING-root'

      }
    }
    console.log(this.data)

    this.project_hier = resp['data']
    let arr :any= []
    let duumy:any = []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!arr.includes(this.project_hier[i]['lvl1_cd'])) {
        duumy.push({
          lvl1_cd: this.project_hier[i]['lvl1_cd'], leaf_value: this.project_hier[i]['leaf_value'],
          lvl1_value: this.project_hier[i]['lvl1_value'], lvl1_node_type: this.project_hier[i]['lvl1_node_type']
        })
        arr.push(this.project_hier[i]['lvl1_cd'])
      }
    }
    this.level1 = duumy
    console.log(this.project_hier, this.level1, 'klklklklkllllllllllllllllklklklk')
  }

  allHier = []
  // ////addd hierachy
  async add_dropdown(data:any, index:any) {
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.data.push({})
    }
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.project_hier, this.Obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.project_hier, this.Obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.project_hier, this.Obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.project_hier, this.Obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.project_hier, this.Obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.project_hier, this.Obj)
    }
    console.log(this.Obj)
  }

  // //remove
  leaf_level = 0
  async remove_dropdown(data:any, index:any) {
    if (this.data.length > 1) {
      this.data.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.Obj['lvl' + i + '_cd'];
    }
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
    await this.set_Path_desc(9)
  }
  async set_Path_desc(index:any) {
    console.log("i am in")
    if (index == 0) {
      this.Obj['lvl1_value'] = this.level1[0]['lvl1_value']
    }
    let dummy = []
    for (let i = 0; i <= 7; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        // this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        // this.leaf_level = i
        this.Obj['node_cd'] = this.Obj['lvl' + i + '_cd']
        dummy.push(this.Obj['lvl' + i + '_value'])
      }
    }
    this.Obj['path_desc'] = dummy.join('-->')
    console.log(this.Obj)
  }






  ////////////////////hierachy end///////////////





















  list_flag = true
  create_flag = false
  update_flag = false
  message : any;
 async open_create() {
   this.list_flag = false
   this.create_flag = true
   this.update_flag = false
   this.message = "come on create zone"
    let resp = await this.userlog.insertLoginInfo(this.message);
    console.log(resp);
  }
  async open_list() {
    this.location_area = 0
    this.Obj = {}
    this.paths = []
    this.GISArr = []
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    await this.getLocationList()
  }

  onPolyClick($event: any) {
    // console.log("polyClicked: ", $event.latLng.lat(), $event.latLng.lng());
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  location_area:any
  Obj_test:any = {}







  async modalOpen(i:any) {
    this.paths = null

    console.log(this.GISArr)
    let dummy = []
    if (this.radio == true) {
      for (let i = 0; i < this.GISArr.length; i++) {
        let obj:any= {}
        obj['loc_ln_seq_num'] = 1
        obj['loc_pt_seq_num'] = 1
        obj['lng'] = this.GISArr[i]['long1']
        obj['lat'] = this.GISArr[i]['lat1']
        let obj2 :any= {}
        obj2['loc_ln_seq_num'] = 1
        obj2['loc_pt_seq_num'] = 2
        obj2['lng'] = this.GISArr[i]['long2']
        obj2['lat'] = this.GISArr[i]['lat2']

        this.lat = obj2['lat']
        this.lng = obj2['lng']

        dummy.push(obj)
        dummy.push(obj2)
      }
    } else {
      for (let i = 0; i < this.uploaded_file_data.length; i++) {
        let obj2 :any= {}

        obj2['lat'] = this.uploaded_file_data[i]['loc_gis_x_long']
        obj2['lng'] = this.uploaded_file_data[i]['loc_gis_y_lat']

        this.lat = obj2['lat']
        this.lng = obj2['lng']
        dummy.push(obj2)
      }
    }
    this.paths = dummy
    console.log(this.paths)
    console.log(this.paths)
    this.Obj_test['loc_geo_calc_value'] = google.maps.geometry.spherical.computeArea(this.paths)
    console.log(this.Obj_test['loc_geo_calc_value'])
    this.location_area = parseFloat(this.Obj_test['loc_geo_calc_value']).toFixed(2);

    $('#myModal1').modal('show');
  }
  async getLocationList() {
    let obj:any= {}
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    var resp:any = await this.zone.getLocationData(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  i:any
  async delete(element:any, i:any) {
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      console.log(result, result.value)
      if (result.value) {

        this.finaldelete(element)

      }
    })
  }


  async finaldelete(data:any) {
    let obj:any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = data.zone_cd
    obj['zone_id'] = data.zone_id
    console.log(obj)
    this.message = " zone deleted where zone_cd" + data.zone_cd
    let resp1 = await this.userlog.insertLoginInfo(this.message);
    console.log(resp1);
    this.spinner.show()
    var resp = await this.zone.deletezone(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      await this.getLocationList()
      this.spinner.hide();
      Swal.fire('Success...', 'Deleted Successfully.', 'success')

    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  //  async finaldelete() {
  //   this.Obj['b_acct_id'] = this.b_acct_id;
  //   this.spinner.show()
  //   let resp = await this.costingService.inactivePaymentOption(this.Obj);
  //   console.log(this.Obj);
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     await this.getPaymentOption()
  //     this.list_flag = true
  //     this.create_flag = false
  //     this.update_flag = false
  //     Swal.fire('Success...', 'Deleted Successfully.', 'success')
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire('Error', resp['data'], 'error')
  //   }


  // }

  addRowInGISArr() {
    this.GISArr.push({})
  }
  addDocArr() {
    if (this.choosen_file == false && this.choosen_file_uploaded == false) {
      Swal.fire('Warning', 'Upload Document in the opened row first', 'warning')
    }
    else if (this.choosen_file == true && this.choosen_file_uploaded == false) {
      Swal.fire('Warning', 'Upload The Document First', 'warning')
    }
    else if (this.choosen_file == true && this.choosen_file_uploaded == true) {
      this.docArr.push({})
      this.choosen_file = false
      this.choosen_file_uploaded = false
    }

  }
  deleteDocArr(data:any, i:any) {
    this.docArr.splice(i, 1)
  }
  onFileUpload(event:any, files:any) {
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.choosen_file = true
    //console.log(this.selectedFile)
  }
  // async view(data) {

  //   //console.log(resp)
  // }
  fileURL:any;
  async view(data:any) {
    this.spinner.show();
    let obj:any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = data['doc_id']
    obj['file_name'] = data['file_name']
    // console.log(obj)
    var res = await this.locationS.view_Doc(obj);
    // console.log(res)
    if (res) {
      var docname = data['file_name']
      var ext = docname.split('.');
      let a = document.createElement('a'), file;
      if (ext[1].toLowerCase() == 'png' || ext[1].toLowerCase() == 'jpeg' || ext[1].toLowerCase() == 'jpg') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {

        // let file = new Blob([res], { type: 'application/pdf/image' });
        // var fileURL = URL.createObjectURL(file);
        // window.open(fileURL);

        let file = new Blob([res], { type: 'application/pdf/image' });
        // var fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        a.href = window.URL.createObjectURL(file);
        a.download = data['file_name'];

        a.click();

      }
      this.spinner.hide();
    }
  }

  async submit() {
    // Obj['loc_type_cd'] ✔
    // Obj['loc_id_std'] ✔
    // Obj['loc_id_str'] ✔
    // Obj['loc_short_nm']
    // Obj['loc_long_desc']
    // Obj['location_use_type']
    // Obj['location_property_type']
    // Obj['loc_admin_cd']
    console.log(this.Obj)
    console.log(this.Obj['loc_type_cd'])
    let dummy = []
    if (!this.Obj['node_cd']) {
      Swal.fire('info', 'Plz Select boundry', 'info')
      return
    }
    // else if(!this.Obj['zone_cd']){
    //   Swal.fire('info', 'Plz fill zone code ', 'info')
    //   return
    // }
    // else if(!this.Obj['zone_name']){
    //   Swal.fire('info', 'Plz fill Zone Name Type', 'info')
    //   return
    // }
    // else if(this.GISArr.length <= 0 || this.docArr.length <= 0){
    //   Swal.fire('info', 'Plz Complete GIS Details', 'info')
    //   return
    // }
    else {
      if (this.radio == true) {
        for (let i = 0; i < this.GISArr.length; i++) {
          let obj:any= {}
          obj['loc_ln_seq_num'] = 1
          obj['loc_pt_seq_num'] = 1
          obj['loc_gis_x_long'] = this.GISArr[i]['long1']
          obj['loc_gis_y_lat'] = this.GISArr[i]['lat1']
          let obj2 :any= {}
          obj2['loc_ln_seq_num'] = 1
          obj2['loc_pt_seq_num'] = 2
          obj2['loc_gis_x_long'] = this.GISArr[i]['long2']
          obj2['loc_gis_y_lat'] = this.GISArr[i]['lat2']

          dummy.push(obj)
          dummy.push(obj2)
        }
      } else if (this.radio == false) {
        dummy = this.uploaded_file_data
      }
      let dummy2 = []
      for (let i = 0; i < this.docArr.length; i++) {
        if (this.docArr[i]['doc_id']) {
          dummy2.push(this.docArr[i]['doc_id'])
        }
      }
      if (dummy2.length > 0) {
        this.Obj['data'] = dummy2
      } else {
        this.Obj['data'] = [-1]
      }
      this.Obj['coordinates_data'] = dummy
      let obj:any= {}
      obj = Object.assign({}, this.Obj)
      obj['zone_cd'] = this.Obj['node_cd']
      obj['b_acct_id'] = this.b_acct_id
      obj['life_cycle_status'] = 'ACTIVE'
      obj['user_id'] = this.ebillUser.user_id
      console.log(obj)
      this.spinner.show()
      var resp:any = await this.zone.createLocation(obj)
      console.log(resp)
      if (resp['error'] == false) {
        this.reset()
        this.spinner.hide()
        this.open_list();
        await this.getLocationList()
        this.message = "zone created where zone_cd is " +  obj['zone_cd']
        let resp = await this.userlog.insertLoginInfo(this.message);
        console.log(resp);
        Swal.fire('info', resp['data'], 'info')
        return
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Some Error Occured While Creating', 'error')
        return
      }
    }
  }
  async update() {
    let dummy = []
    if (this.radio2 == true) {
      for (let i = 0; i < this.GISArr.length; i++) {
        let obj:any= {}
        obj['loc_ln_seq_num'] = 1
        obj['loc_pt_seq_num'] = 1
        obj['loc_gis_x_long'] = this.GISArr[i]['long1']
        obj['loc_gis_y_lat'] = this.GISArr[i]['lat1']
        let obj2 :any= {}
        obj2['loc_ln_seq_num'] = 1
        obj2['loc_pt_seq_num'] = 2
        obj2['loc_gis_x_long'] = this.GISArr[i]['long2']
        obj2['loc_gis_y_lat'] = this.GISArr[i]['lat2']
        dummy.push(obj)
        dummy.push(obj2)
      }
    } else if (this.radio2 == false) {
      dummy = this.uploaded_file_data
    }
    let dummy2 = []
    for (let i = 0; i < this.docArr.length; i++) {
      if (this.docArr[i]['doc_id']) {
        dummy2.push(this.docArr[i]['doc_id'])
      }
    }
    if (dummy2.length > 0) {
      this.Obj['data'] = dummy2
    } else {
      this.Obj['data'] = [-1]
    }
    this.Obj['coordinates_data'] = dummy
    let obj:any= {}
    obj = Object.assign({}, this.Obj)

    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'GENERATED'
    obj['update_user_id'] = this.ebillUser.user_id
    obj['user_id'] = this.ebillUser.user_id
    //console.log(obj)
    this.spinner.show()
    var resp = await this.locationS.updateLocation(obj)
    //console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.reset()
      this.open_list();
      await this.getLocationList()
      Swal.fire('Success', 'Updated Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured While Updating', 'error')
    }
  }
  async open_update(data:any) {
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
    console.log(data)
    this.Obj = Object.assign({}, data)
    //console.log(this.Obj)
    this.choosen_file_uploaded = true
    this.choosen_file = true

    for (let i = 0; i < this.mainService.codeValueTechObj['PROPFLD5'].length; i++) {
      if (this.mainService.codeValueTechObj['PROPFLD5'][i]['code'] == this.Obj['loc_type_cd']) {
        this.Obj['v1'] = this.mainService.codeValueTechObj['PROPFLD5'][i]['value']
      }
    }

    for (let i = 0; i < this.mainService.codeValueTechObj['PROPFLD6'].length; i++) {
      if (this.mainService.codeValueTechObj['PROPFLD6'][i]['code'] == this.Obj['loc_id_std']) {
        this.Obj['v2'] = this.mainService.codeValueTechObj['PROPFLD6'][i]['value']
      }
    }

    for (let i = 0; i < this.mainService.codeValueTechObj['PROPFLD4'].length; i++) {
      if (this.mainService.codeValueTechObj['PROPFLD4'][i]['code'] == this.Obj['loc_geo_cd']) {
        this.Obj['v4'] = this.mainService.codeValueTechObj['PROPFLD4'][i]['value']
      }
    }
    await this.allGISLocation()
    await this.allLocationDoc()
    $('.nav-tabs a[href="#tab-7-3"]').tab('show')
  }
  async allGISLocation() {
    // this.spinner.show()
    this.GISArr = []
    let obj:any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['loc_id'] = this.Obj['loc_id']
    var resp = await this.locationS.getAllGisLocation(JSON.stringify(obj));
    console.log(resp, 'allGISLocation')
    if (resp['error'] == false) {
      this.GISArr = []
      for (let i = 0; i < resp['data'].length; i++) {
        let obj:any= {}
        obj['lat1'] = resp['data'][i]['loc_gis_y_lat']
        obj['long1'] = resp['data'][i]['loc_gis_x_long']
        obj['lat2'] = resp['data'][i + 1]['loc_gis_y_lat']
        obj['long2'] = resp['data'][i + 1]['loc_gis_x_long']
        this.GISArr.push(obj)
        i++;
      }
      //console.log(this.GISArr, 'gis arr')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async allLocationDoc() {
    this.docArr = []
    // this.spinner.show()
    let obj:any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['loc_id'] = this.Obj['loc_id']
    var resp = await this.locationS.getLocationDoc(JSON.stringify(obj));
    console.log(resp, 'allLocationDoc')
    if (resp['error'] == false) {
      this.docArr = resp['data']
      if (this.docArr.length < 1) {
        this.docArr = [{}]
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  export() {
    let ExpData = [{
      'one': 'S No.',
      'two': 'Point 1',
      'three': 'Latitude',
      'four': 'longitude',
      'five': 'Point 2',
      'six': 'Latitude ',
      'seven': 'longitude ',
    },
    ]
    var exp = []
    for (var i = 0; i < ExpData.length; i++) {
      var obj:any = new Object();
      obj['1'] = ExpData[i]['one']
      obj['2'] = ExpData[i]['two']
      obj['3'] = ExpData[i]['three']
      obj['4'] = ExpData[i]['four']
      obj['5'] = ExpData[i]['five']
      obj['6'] = ExpData[i]['six']
      obj['7'] = ExpData[i]['seven']
      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, 'Map-cordinates_format')
  }
  set_data(i:any) {

    if (i == 1) {
      for (let i = 0; i < this.mainService.codeValueTechObj['PROPFLD5'].length; i++) {
        if (this.mainService.codeValueTechObj['PROPFLD5'][i]['code'] == this.Obj['loc_type_cd']) {
          this.Obj['v1'] = this.mainService.codeValueTechObj['PROPFLD5'][i]['value']
        }
      }
    }
    if (i == 2) {
      for (let i = 0; i < this.mainService.codeValueTechObj['PROPFLD6'].length; i++) {
        if (this.mainService.codeValueTechObj['PROPFLD6'][i]['code'] == this.Obj['loc_id_std']) {
          this.Obj['v2'] = this.mainService.codeValueTechObj['PROPFLD6'][i]['value']
        }
      }
    }
    if (i == 3) {
      for (let i = 0; i < this.mainService.codeValueTechObj['PROPFLD4'].length; i++) {
        if (this.mainService.codeValueTechObj['PROPFLD4'][i]['code'] == this.Obj['loc_geo_cd']) {
          this.Obj['v4'] = this.mainService.codeValueTechObj['PROPFLD4'][i]['value']
        }
      }
    }
  }
  async upload(data:any, i:any) {
    let index = i
    if (!data['doc_type_cd']) {
      Swal.fire('Error', 'Please Select All Fields', 'error')
    } else {
      this.spinner.show();
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      let obj:any = new Object();
      obj = Object.assign({}, data)
      obj['b_acct_id'] = this.b_acct_id;
      console.log
      obj['file_name'] = this.uploader.queue[0].some.name;
      obj['doc_name'] = this.uploader.queue[0].some.name;

      obj['doc_file_type_cd'] = 'pdf'
      this.spinner.show()
      //console.log(obj)
      const params = JSON.stringify(obj);
      console.log(obj)
      this.uploader.queue[0].url = this.httpUrl + '/property/location/uploadlocationDoc' + params;
      this.uploader.queue[0].upload();
      this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        console.log(response)
        var resp = JSON.parse(response);
        if (resp.error == false) {
          //console.log(resp, 'resp after success')
          this.spinner.hide();
          this.choosen_file = true
          this.choosen_file_uploaded = true
          this.docArr[index]['doc_id'] = resp['data']
          this.docArr[index]['file_name'] = obj['file_name']
          this.uploader.queue[0] = null
          Swal.fire('Success', 'File Uploaded Successfully!!', 'success');
        } else {
          this.spinner.hide()
          Swal.fire('Error', resp.data, 'error');
        }
      };
    }


  }

  deleteGISRow(data:any, i:any) {
    this.GISArr.splice(i, 1)
  }
  async onFileChange(event: any) {
    /* wire up file reader */
    this.uploaded_file_data = []
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      //console.log(data)
      this.setData(data);
      // Data will be logged in array format containing objects
    };
  }

  uploaded_file_data:any = []
  async setData(data:any) {
    this.uploaded_file_data = []
    let sum_lat = 0
    let sum_lng = 0
    //console.log(data)
    let dummy = []
    for (let i = 1; i < data.length; i++) {
      let obj:any= {}
      obj['loc_ln_seq_num'] = 1
      obj['loc_pt_seq_num'] = 1
      obj['loc_gis_x_long'] = data[i][3]
      obj['loc_gis_y_lat'] = data[i][4]
      let obj2 :any= {}
      obj2['loc_ln_seq_num'] = 1
      obj2['loc_pt_seq_num'] = 2
      obj2['loc_gis_x_long'] = data[i][6]
      obj2['loc_gis_y_lat'] = data[i][7]
      // console.log()
      this.lat = obj2['loc_gis_y_long']
      this.lng = obj2['loc_gis_x_lat']

      dummy.push(obj)
      dummy.push(obj2)
    }
    // console.log(sum_lat/data.length)
    // console.log(sum_lng/data.length)
    // this.lat = sum_lat/data.length
    // this.lng = sum_lng/data.length
    // this.lat = obj2['loc_gis_y_long']
    // this.lng = obj2['loc_gis_x_lat']
    this.uploaded_file_data = dummy

    //console.log(dummy, 'set data')
  }
  calculate_area() {
    if (!(this.uploaded_file_data || !this.GISArr)) {
      swal.fire('Info', 'Enter location detail', 'info')
      return
    }
    console.log(this.GISArr)
    let dummy = []
    if (this.radio == true) {
      for (let i = 0; i < this.GISArr.length; i++) {
        let obj:any= {}
        obj['loc_ln_seq_num'] = 1
        obj['loc_pt_seq_num'] = 1
        obj['lng'] = this.GISArr[i]['long1']
        obj['lat'] = this.GISArr[i]['lat1']
        let obj2 :any= {}
        obj2['loc_ln_seq_num'] = 1
        obj2['loc_pt_seq_num'] = 2
        obj2['lng'] = this.GISArr[i]['long2']
        obj2['lat'] = this.GISArr[i]['lat2']

        this.lat = obj2['lat']
        this.lng = obj2['lng']

        dummy.push(obj)
        dummy.push(obj2)
      }
    } else {
      for (let i = 0; i < this.uploaded_file_data.length; i++) {
        let obj2 :any= {}

        obj2['lat'] = this.uploaded_file_data[i]['loc_gis_x_long']
        obj2['lng'] = this.uploaded_file_data[i]['loc_gis_y_lat']

        this.lat = obj2['lat']
        this.lng = obj2['lng']
        dummy.push(obj2)
      }
    }
    this.paths = dummy
    console.log(this.paths)
    //console.log(this.paths)
    this.Obj['loc_geo_calc_value'] = google.maps.geometry.spherical.computeArea(this.paths)
    this.location_area = parseFloat(this.Obj['loc_geo_calc_value']).toFixed(2);

  }







}
