<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Geometry -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY1']}} </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="l_geo_flag"class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                <!-- Geometry List -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="c_geo_flag" class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i> <!-- Create Geometry -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY3']}}</a>
                        </li>

                        <li class="nav-item">
                            <a  *ngIf="u_geo_flag" class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i> <!-- Update Geometry -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY4']}}</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="l_geo_flag" >

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotocrete()">Create Geometry</button>
                            </div>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- ID -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY5']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="geo">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Geometry
                                            Name -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.geometry }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="unit">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Unit -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.unit }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="meas">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Measurement -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.measurement }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="formula">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Formula -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.formual }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY10']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-primary"
                                                (click)="open_update(element)"><!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY11']}}</button>
                                            <button class="btn btn-danger" (click)="delete(element)"><!-- Delete -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY12']}}</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>



                        </div>
                        <div *ngIf="c_geo_flag" >

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Geometry List</button>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Select Unit -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY13']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Select Unit"
                                        [items]="mainService.codeValueTechObj['EMB009']" bindLabel="value"
                                        bindValue="code" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="hierarchy" [(ngModel)]="geo['unit']">
                                    </ng-select>
                                </div>
                                <div class="col-3">

                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Enter Shape -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY14']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">

                                    <input type="text" class="form-control" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY14']}}"
                                        [(ngModel)]="geo['geometry']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Select Measurement -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY15']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="true" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY15']}}"
                                        [items]="mainService.codeValueTechObj['EMB010']" bindLabel="value"
                                        (change)="get_role()" bindValue="value" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy"
                                        [(ngModel)]="geo['meas']">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Enter Formula -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY16']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="" [(ngModel)]="geo['formual']"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY16']}}">
                                </div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-secondary" data-toggle="modal"
                                        data-target="#exampleModal">
                                        <!-- Instructions for formula -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY17']}}
                                    </button>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="create()"><!-- Submit -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY18']}}</button>

                                </div>
                            </div>
                        </div>

                        <div *ngIf="u_geo_flag" >

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Geometry List</button>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Select Unit --> {{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY13']}}: <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY13']}}"
                                        [items]="mainService.codeValueTechObj['EMB009']" bindLabel="value"
                                        bindValue="code" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="hierarchy" [(ngModel)]="geo['unit']">
                                    </ng-select>
                                </div>
                                <div class="col-3">

                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Enter Shape -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY14']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">

                                    <input type="text" class="form-control" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY14']}}"
                                        [(ngModel)]="geo['geometry']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Select Measurement -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY15']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="true" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY15']}}"
                                        [items]="mainService.codeValueTechObj['EMB010']" bindLabel="value"
                                        (change)="get_role()" bindValue="value" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy"
                                        [(ngModel)]="geo['meas']">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Enter Formula  -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY16']}}: <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="" [(ngModel)]="geo['formual']"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY16']}}">
                                </div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-secondary" data-toggle="modal"
                                        data-target="#exampleModal">
                                        <!-- Instructions for formula -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY17']}}
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()"><!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY11']}}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

</div>
<ngx-spinner type="ball-atom"><!-- Loading -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY27']}}....</ngx-spinner>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-12">
                        <h3 style="background-color: rgb(223, 223, 223); font:bold;"><!-- Instructions -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY19']}} :</h3>
                        <br>
                        <p>
                           <!--  When You are entering the formula , you should keep in mind that the spellings of
                            measurement should be same as you have selected in the measurement dropdown.
                            else you will not be able to calculate. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY20']}}
                        </p>

                      <h6>  <!-- Example -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY21']}} :</h6>
                        <br>

                        <div class="row">
                            <div class="col-4">
                                <!-- Shape -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY22']}} :
                            </div>
                            <div class="col-4">
                                <!-- Cuboid -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY23']}}
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-4">
                                <!-- Measurement -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY8']}} :
                            </div>
                            <div class="col-6">
                                <!-- Length,Width,Height -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY24']}}
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-4">
                                <!-- Formula -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY9']}} :
                            </div>
                            <div class="col-6">
                                <!-- Length* Width*Height -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY25']}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"data-bs-dismiss="modal"><!-- Close -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-GEOMETRY26']}}</button>
            </div>
        </div>
    </div>
</div>