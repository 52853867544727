import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from '../../../service/main.service';
import { MainService as mainserviceEmb } from '../../../../emb/service/main.service';
import { AllEmpService } from '../../../service/all-emp.service';
import { DataAccessService } from '../../../service/data-access.service';
import { ApprovalUserService } from '../../../../portal/service/approval-user.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AttendaceMangService } from '../../attendace-mang.service';
declare var $: any;
// import { MainService } from 'src/app/accounts/service/main.service';
// import { AllEmpService } from 'src/app/hrms/service/all-emp.service';
// import { DataAccessService } from 'src/app/hrms/service/data-access.service';




@Component({
  selector: 'app-extra-work',
  templateUrl: './extra-work.component.html',
  styleUrls: ['./extra-work.component.css']
})
export class ExtraWorkComponent implements OnInit {


  constructor(private dataAccessService: DataAccessService, private spinner: NgxSpinnerService, public mainService: MainService, private allEmpService: AllEmpService,
    public ApprovalUserService: ApprovalUserService, private attendService: AttendaceMangService, private mainserviceEmb: mainserviceEmb) { }


  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;
  dataSource1: any;
  displayedColumns1: any = ['work_id', 'emp_name', 'purpose', 'start_date', 'end_date', 'start_time', 'end_time', 'status', 'action'];
  datasource: any;


  arr_id: any;
  newallEmplyees: any = [];
  allEmplyees: any;
  b_acct_id: any;
  erpUser: any;
  empIdToName: any



  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllEmployees();
    await this.getAccountProjectHier();
    await this.listOfWork()
    await this.getRule();
  }

  fromDate:any ;
  toDate:any ;
  purpose:any ;
  fromTime:any ;
  toTime:any ;
  remark:any ;
  ind_emp_id:any
  ruleTime:any  = []
  // ---------------- first load ---------------

  async listOfWork() {
    let obj:any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp :any  = await  this.attendService.listOfExtraWork(obj);
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        Swal.fire('Info', "No Data To Show", "info");
        return;

      }

      this.dataSource1 = new MatTableDataSource(resp['data']);
      this.dataSource1.paginator = this.paginator1
      this.dataSource1.sort = this.sortCol2;
      console.log("datasource", resp);
    }
  }


  async getempName(value:any ) {

    let v:any  = value.split(' --> ')

    this.Obj['emp_name'] = v.pop();

    // console.log(value.split(' --> '),"asd",v,"popo1", this.Obj['emp_name'])

  }

  async getRule() {
    let obj:any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp :any  = await  this.attendService.getRuleOfTime(obj);
    console.log(" this is the resp data ---", resp['data']);
    if (resp['error'] == false) {
      this.ruleTime.push(resp['data']);
    }
  }

  //-------------------- end first load--------------

  // --------------- office timing check start --------------
  async dateSplit(value:any ) {
    let d = value.split('-');
    let date = d[1] + "/" + d[2] + "/" + d[0];
    return date;
  }
  // date diff
  date1:any
  date2:any
  diffTime:any
  diffDays :any = 1
  async dateDiff(d1:any , d2:any ) {
    this.diffDays = 1;
    this.date1 = new Date(`${d1}`); // MM:DD:YYYY
    this.date2 = new Date(`${d2}`);
    this.diffTime = Math.abs(this.date2 - this.date1);
    this.diffDays += Math.ceil(this.diffTime / (1000 * 60 * 60 * 24));
    // console.log(diffTime + " milliseconds");
    console.log(this.diffDays + " days"); // give the on of the days
    return this.diffDays;

  }
  msec:any ;
  totalHOurs:any ;
  async checkOfficeTiming(from:any , to:any ) {
    var H, M, S
    var date1 = new Date(`08/05/2015 ${from}`);
    var date2 = new Date(`08/05/2015 ${to}`);

    var diff = date2.getTime() - date1.getTime();

    this.msec = diff * this.diffDays;
    var hh = Math.floor(this.msec / 1000 / 60 / 60);
    this.msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(this.msec / 1000 / 60);
    this.msec -= mm * 1000 * 60;
    var ss = Math.floor(this.msec / 1000);
    this.msec -= ss * 1000;
    this.totalHOurs = hh + ":" + mm + ":" + ss
    // this.totalHOurs=H + ":" + M + ":" + S
    console.log(hh + ":" + mm + ":" + ss);
    console.log(this.totalHOurs);
    return this.totalHOurs;
  }


  //--------------------- office timing check end =-----------------
  async submit() {

    let obj:any  = new  Object()
    obj['startDate'] = this.fromDate
    obj['endDate'] = this.toDate
    obj['startTime'] = this.fromTime
    obj['endTime'] = this.toTime
    obj['purpose'] = this.purpose
    obj['remark'] = this.remark
    await this.getempName(this.Obj['path_desc'])
    obj['emp_name'] = this.Obj['emp_name'];
    obj['emp_id'] = this.emp_id
    obj['node_code'] = this.check1;
    obj['branch'] = 1;
    obj['leave'] = 1;
    obj['arr_id'] = this.ind_emp_id[0];
    obj['b_acct_id'] = this.erpUser.b_acct_id;
    obj['user_id'] = this.erpUser.user_id;


    //  only for calculation

    let avi1 = await this.dateSplit(this.fromDate);
    let avi2 = await this.dateSplit(this.toDate);
    let diffDateavi = await this.dateDiff(avi1, avi2)

    obj['evn_late'] = (await this.checkOfficeTiming(`${this.ruleOffice[0]['toTime']}`, `${obj['endTime']}`))
    // mcs Date
    obj['mor_late'] = await this.checkOfficeTiming(`${obj['startTime']}`, `${this.ruleOffice[0]['fromTime']}`)

    //  end calculation


    console.log(obj)

    let resp:any  = await this.attendService.saveExtWork(obj)
    console.log(" this is the resp--", resp);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Data Save Successfully', 'success');
      await this.listOfWork();
      return;
    }
    else {
      Swal.fire('Error', 'Data Not Save !', 'error');
      return;

    }

  }


  // select employee
  allEmplyees_new:any
  async getAllEmployees() {
    this.spinner.show()
    var arr = []
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';
    var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data
      this.allEmplyees = arr;
      this.newallEmplyees = []
      for (let i = 0; i < resp.data.length; i++) {
        var obj:any  = new Object();
        obj = Object.assign({}, resp.data[i]);
        obj['emp_name'] = obj['emp_id'] + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
      }


      this.allEmplyees_new = [];
      for (let i = 0; i < resp.data.length; i++) {
        var obj:any  = new Object();
        obj = Object.assign({}, resp.data[i]);
        obj['emp_name'] = this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.allEmplyees_new.push(obj)
      }







      console.log(this.allEmplyees)
      console.log(this.newallEmplyees);
      console.log(this.allEmplyees_new);
      this.newallEmplyees = await this.dataAccessService.filterEmployeeData(this.newallEmplyees)
      console.log(" this is the newallEmp--", this.newallEmplyees);
    } else {
      this.spinner.hide()
      Swal.fire('Error', "Error while getting employee list", 'error')
    }


  }
  getNumberFormat(num:any ) {
    return num.toString().padStart(3, "0")
  }
  //---------------- project herarchy--------

  // node
  planing_arrproj:any  = [];
  projecthier:any  = []
  projhierobj:any  = {}
  projhierobjtype :any = {}
  projhierobjtypecd:any  = {}
  projhierobjisleaf:any  = {}
  Obj :any = {}
  allProject :any = [];
  joiningObj :any = {};





  async getAccountProjectHier() {
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAccountProjectHier(obj);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'HR-root'
        }
      }
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {

        var tt = []
        for (let k = 1; k < 8; k++) {
          if (resp.data[i]['lvl' + k + '_cd'] != null) {
            tt.push(resp.data[i]['lvl' + k + '_cd'])
          }
        }
        this.dataAccessService.data_assigment = this.dataAccessService.data_assigment.filter((x:any ) => x.user_id == this.erpUser.user_id)
        var exit_flag = false;
        for (let j = 0; j < this.dataAccessService.data_assigment.length; j++) {
          if (tt.includes(parseInt(this.dataAccessService.data_assigment[j]['node_cd']))) {
            exit_flag = true;
          }
        }

        if (tt.length == 1) {
          exit_flag = true;
        }
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR"))
          && (resp.data[i]['module_cd'] != null || resp.data[i]['module_cd'] != undefined) && exit_flag) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;
      this.allProject = resp.data;
      this.projecthier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_user_cd']
          this.projhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']
        }
      }
    } else {
      console.log("Error in getting all project hier")
    }
  }

  open_activitypopup() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1)
    }

    console.log("Open Pop Up")
    $('#ACTHIER').modal('show');
  }

  officeTime = { fromTime: '', toTime: "", grace: "", number: '', frequency: '' }
  down(item:any , k:any ) {
    let patharr = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }
    this.officeTime = { fromTime: '', toTime: "", grace: "", number: '', frequency: '' }
    this.Obj['path_desc'] = patharr.join("-->")
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [], office_time: [] })


    console.log("planing_arrproj---", this.planing_arrproj, "item", item)
    let arr :any = []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_cd'] != null) {

        if ((this.projecthier[i]['lvl' + (k + 1) + '_cd'] == item['hierarchy_type'] || k == -1)/*  && this.projecthier[i]['leaf_node_type'] != 'HREMP' */) {
          // this.officeTime={}
          for (let a = 0; a < this.ruleTime[0].length; a++) {
            if (this.ruleTime[0][a]['heirarchy_type'] == item['hierarchy_type']) {
              // officeTime.push(this.ruleTime[0][a]['fromTime'],this.ruleTime[0][a]['toTime'],this.ruleTime[0][a]['grace']);
              this.officeTime['fromTime'] = this.ruleTime[0][a]['fromTime'];
              this.officeTime['toTime'] = this.ruleTime[0][a]['toTime'];
              this.officeTime['grace'] = this.ruleTime[0][a]['grace'];
              this.officeTime['number'] = this.ruleTime[0][a]['number'];
              this.officeTime['frequency'] = this.ruleTime[0][a]['frequency'];
            }
          }

          this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'], officeRule: this.officeTime })
          arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
          this.planing_arrproj[k + 1]['office_time'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], ...this.officeTime });

        }

        // this.planing_arrproj['office_time'].push(this.officeTime);

      }
      // this.planing_arrproj['Office_time'].push({ offICe_TIME:this.officeTime});
    }
  }

  remove(item:any , i:any ) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i])

        patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
      }
      this.Obj['path_desc'] = patharr.join("-->")
    }

  }
  emp_id:any ;
  ruleOffice = []
  async submitProjectHierNode() {
    // console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']);
    // if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
    //   Swal.fire("Warning", "Please Select at least two level", 'warning')
    // }
    // else {
    //   $('#ACTHIER').modal('hide');
    // }
    // console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'])
    // console.log(this.planing_arrproj.length)
    // this.Obj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'];
    // var lvl_no = this.planing_arrproj.length;


    if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
      Swal.fire("Warning", "Please Select at least two level", 'warning')
    }
    else {
      $('#ACTHIER').modal('hide');
    }
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'])
    console.log(this.planing_arrproj.length)
    this.Obj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'];
    var lvl_no = this.planing_arrproj.length;

    this.ruleOffice = this.planing_arrproj[this.planing_arrproj.length - 1]['office_time'].filter((avi:any ) => avi.node_cd == this.Obj['node_cd'])
    console.log("this is thge ruleoffice", this.ruleOffice)

    this.ind_emp_id = [];
    var temp = [];
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]['lvl' + lvl_no + '_cd'] == this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] && this.projecthier[i]['leaf_node_type'] == 'HREMP') {
        temp.push(this.projecthier[i]['leaf_user_cd'])
      }
    }

    for (let i = 0; i < temp.length; i++) {
      for (let j = 0; j < this.allEmplyees_new.length; j++) {
        if (temp[i] == 'HRARR' + this.allEmplyees_new[j]['arr_id']) {
          this.ind_emp_id.push(this.allEmplyees_new[j]['arr_id'])
          this.emp_id = (this.allEmplyees_new[j]['emp_id'])
        }
      }
    }

    console.log(this.ind_emp_id)





  }

  check1:any
  setHierarchyNodeitem(item:any , i:any ) {
    console.log(item, i);
    this.check1 = item['hierarchy_type']
    console.log(this.check1);
    item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']]

    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }

    this.Obj['path_desc'] = dummy2.join(' --> ')
  }

  // ---end herarchy -----------

  // -------Approval --------------
  forwarded_user_id:any ;
  appr_remark:any ;
  undertakings:any ;
  //-------------- Submit Approval ----------


  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any  = new Object();

      obb['user_id'] = this.erpUser.user_id
      obb['b_acct_id'] = this.erpUser.b_acct_id
      obb['forwarded_by'] = this.erpUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'Extra_Work'
      // obb['work_local_no'] = this.extraWorkApprove['work_local_no']
      obb['doc_local_no'] = this.extraWorkApprove['work_local_no']
      obb['doc_desc'] = this.extraWorkApprove['work_desc']
      obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
      obb['module_cd'] = 'HR'
      obb['node_cd'] = this.extraWorkApprove['node_cd']
      obb['level'] = this.ApprovalUserService.level1['level']
      obb['undertaking'] = JSON.stringify([])
      obb['branch'] = this.ApprovalUserService.level1['branch']
      obb['field_value'] = 0
      console.log(" this is the Approval Approved", obb);
      var resp = await this.mainserviceEmb.insertTask(obb)
      // var resp = await this.attendService.insertTaskworkFlow(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        // await this.ChangestatusExtraWork(this.extraWorkApprove['work_local_no'], 'UNDERAPPROVAL')
        await this.ChangestatusExtraWork(this.extraWorkApprove['work_local_no'], 'UNDERAPPROVAL')
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          // obb['user_id'] = -1
          // obb['vendor_id'] = this.selectedTender['party_id']
          if (this.message_flag == true) {
            /* await this.getle(this.selectedTender['party_id']) */
          }

        } else {
          if (this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        // this.toastr.successToastr("Approval Forwarded Successfully!!")
        console.log("Approval Forwarded Successfully!!")
        await this.listOfWork()
        Swal.fire('Success', 'Forwarded...', 'success');

      } else {
        this.spinner.hide();
        Swal.fire("Error", 'Some Error Occured !', 'error');

        console.log(resp['data'])
        // this.toastr.errorToastr(resp['data'])
      }
    } else {
      // this.toastr.errorToastr("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb :any = new Object();

      obb["user_id"] =
        this.ApprovalUserService.rolebyid[this.forwarded_user_id]["user_id"];

      for (let i = 0; i < this.ApprovalUserService.workuser.length; i++) {
        if (this.ApprovalUserService.workuser[i]["user_id"] == obb["user_id"]) {
          let h = this.ApprovalUserService.workuser[i]["desc"].split("-");
          obb["user_name"] = h[1];
        }
      }
      // var obb = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        // if(this.selectedTender['party_id'] == null || this.selectedTender['party_id'] == undefined){
        //   Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
        //   return
        // }
        // obb['user_id'] = -1
        // obb['vendor_id'] = this.selectedTender['party_id']
      }
      // else {
      //   obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      // }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.erpUser.b_acct_id
      obb['forwarded_by'] = this.erpUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'EXTRA_WORK'
      // obb['work_local_no'] = this.extraWorkApprove['work_local_no']
      obb['doc_local_no'] = this.extraWorkApprove['work_local_no']
      obb['doc_desc'] = this.extraWorkApprove['work_desc']
      // obb['undertaking'] = JSON.stringify([])
      // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'HR'
      obb['node_cd'] = this.extraWorkApprove['node_cd']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.extraWorkApprove['level']
        obb['branch'] = this.extraWorkApprove['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      // obb['field_value'] =0
      var resp = await this.mainserviceEmb.insertTask(obb)
      // var resp = await this.attendService.insertTaskworkFlow(obb)
      if (resp['error'] == false) {

        //await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        //this.toastr.successToastr("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        Swal.fire("Success", resp["data"], "success");
        // this.toastr.errorToastr(resp['data'])
      }
    } else {
      Swal.fire("Error", "Please Select Any User..!", "error");
      this.spinner.hide();
    }
  }

  //----------- end sub approval ---------
  //================== Send To Approve ==============
  extraWorkApprove :any = {};
  async Appr(element:any ) {
    this.extraWorkApprove = Object.assign({}, element);

    // console.log("this is obj =-- ele",eleObj);
    // let eleObj=Object.assign({},element);
    //   this.extraWorkApprove['purpose']=eleObj['purpose']
    //   this.extraWorkApprove['fromDate']=element['start_date']
    // this.extraWorkApprove['remark']=eleObj['remark']
    // this.extraWorkApprove['node_cd']=eleObj['node_cd'] ;
    // this.extraWorkApprove['work_id']=eleObj['work_id'] ;
    // this.extraWorkApprove['emp_id']=eleObj['emp_id'] ;
    // this.extraWorkApprove['arr_id']=eleObj['arr_id'] ;
    // this.extraWorkApprove['toDate']=eleObj['end_date']
    // this.extraWorkApprove['fromTime']=eleObj['start_time']
    // this.extraWorkApprove['toTime']=eleObj['end_time']
    this.extraWorkApprove['doc_type'] = 'EXTRA_WORK';
    this.extraWorkApprove['work_local_no'] = this.extraWorkApprove['work_id'];
    this.extraWorkApprove['doc_local_no'] = this.extraWorkApprove['work_id'];

    this.extraWorkApprove['module_cd'] = 'HR';
    this.extraWorkApprove['level'] = 1;
    this.extraWorkApprove['branch'] = 1;
    this.extraWorkApprove['work_desc'] = `Approval For ${this.extraWorkApprove['emp_id']}`
    console.log("Work approval obj ---", this.extraWorkApprove)
    await this.ApprovalUserService.getWorkflowlog(this.erpUser, 'EXTRA_WORK', 'HR');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      await this.ChangestatusExtraWork(this.extraWorkApprove['work_id'], 'APPROVED')
      return;
    }
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.erpUser, this.extraWorkApprove)
    await this.ApprovalUserService.getWorkflowloguser(this.erpUser, this.extraWorkApprove)

    $('#myModal3').modal('show');

  }

  message_flag :any = false
  async getle(user_id:any ) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp :any  = await  this.mainserviceEmb.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      await this.sendmessage(resp['data'][0]['phone_no'], resp['data'][0]['party_name']
      )
      this.spinner.hide();
      this.message_flag = false
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }
  async sendmessage(mobile:any , user_name:any ) {
    //console.log(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['work_local_no'])
    let resp :any  = await  this.mainserviceEmb.sendMsg(mobile, this.extraWorkApprove['doc_type'], this.extraWorkApprove['work_local_no'], user_name)

    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }
  }

  async ChangestatusExtraWork(work_id:any , work_status_code:any ) {
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['update_user_id'] = this.erpUser.user_id;
    obj['work_id'] = work_id;
    obj['work_status_code'] = work_status_code;

    this.spinner.show();
    console.log(obj)
    var resp = await this.attendService.changeStatusOfExtraWork(obj);
    if (resp['error'] == false) {

      this.spinner.hide();
      // Swal.fire('Success', 'Extra-Work Approved Successfully');

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error in Extra-Work Generation', 'error');
    }

  }
  //-----------------------------------------

  async approvedocment() {
    await this.lastapprandrejectedappr("VERIFIED");
  }
  async lastapprandrejectedappr(status:any ) {
    // if (status == "REJECTED") {
    //   this.defendant_apprdata = Object.assign({}, this.rejectdata);
    // }
    // // console.log(this.rejectdata);

    var obb:any  = new Object();

    obb["user_id"] = this.erpUser.user_id;

    obb["user_name"] = this.erpUser.party_name;
    obb["b_acct_id"] = this.erpUser.b_acct_id;
    obb["forwarded_by"] = this.erpUser.user_id;
    obb["forward_msg"] = this.appr_remark;
    obb["remark"] = "";
    obb["status"] = status;
    obb["doc_type"] = "Extra_Work";
    obb["doc_local_no"] = this.extraWorkApprove['work_local_no']
    obb['doc_desc'] = this.extraWorkApprove['work_desc']
    obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
    obb['module_cd'] = 'HR'
    obb['node_cd'] = this.extraWorkApprove['node_cd']
    obb['level'] = this.ApprovalUserService.level1['level']
    obb['undertaking'] = JSON.stringify([])
    obb['branch'] = this.ApprovalUserService.level1['branch']
    // obb['field_value'] =0

    // // console.log(obb);

    var resp = await this.mainserviceEmb.insertTask(obb)
    if (resp["error"] == false) {
      await this.ChangestatusExtraWork(this.extraWorkApprove['work_local_no'], status)
      if (status == "REJECTED") {
        Swal.fire("Success", "Rejected Successfully!!", "success");
      }
      if (status == "VERIFIED") {
        Swal.fire("Success", "Verified Successfully!!", "success");
      }
    } else {
      Swal.fire("Error", resp["data"], "error");
    }
  }




  //-----end approval-------------

  applyFilter1(filterValue: string) {

    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

}
