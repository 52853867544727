import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../../service/establishment.service';
import { MainService } from '../../service/main.service';
import Swal from 'sweetalert2';
declare var $: any

@Component({
  selector: 'app-probation',
  templateUrl: './probation.component.html',
  styleUrls: ['./probation.component.css']
})
export class ProbationComponent implements OnInit {


  constructor(public mainService: MainService, private router: Router, private spinner: NgxSpinnerService, private snackBar:MatSnackBar, private establishmentService: EstablishmentService) { }
  erpUser: any;
  b_acct_id: any;

  codeValueTechObj: any = {};

  allEmplyees: any = [];
  selectEmpObj: any = {};
  probationObj: any = {};
  update_probationObj: any = {}

  emp_id: any;


  empIdToName: any = {};
  newallEmplyees: any = []


  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns: any = ['id', 'emp_id', 'probation_days', 'probation_status_code', 'action'];
  datasource: any;

  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllEmployees();

  }
  openUpdate(element: any) {
    this.update_probationObj = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show');

  }
  async getAllProbation() {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectEmpObj['emp_id']
    var resp = await this.establishmentService.getPartyProbationInfo(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      if (resp['data'].length == 0) {
        Swal.fire("Info", "No Data Present", 'info');
      }


      this.datasource = new MatTableDataSource(resp.data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;

    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee all Probation list ', 'error')
      // this.snackBar.open("Error while getting employee all Probation list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  cleanId() {
    this.selectEmpObj['emp_id'] = '';
    this.datasource = new MatTableDataSource()
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }
  async getAllProbation1() {

    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectEmpObj['emp_id']
    var resp = await this.establishmentService.getPartyProbationInfo(obj);
    if (resp['error'] == false) {

      if (resp.data.length > 0) {
        Swal.fire("Warning", "Probation detail already added", 'warning');
        await this.getAllProbation()
        $('.nav-tabs a[href="#tab-1"]').tab('show');
        return
      }
      // else{
      //   Swal.fire("info", "No Data Present" , 'info');
      // }
    } else {
      Swal.fire('Error', 'Error while getting employee all Probation list', 'error')
      // this.snackBar.open("Error while getting employee all Probation list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }
  async getAllEmployees() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.establishmentService.getEmployeeMasterData(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allEmplyees = resp.data;

      this.newallEmplyees = []
      for (let i = 0; i < this.allEmplyees.length; i++) {
        var obj :any= new Object();
        obj = Object.assign({}, this.allEmplyees[i]);
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
      }
      for (let i = 0; i < this.allEmplyees.length; i++) {
        this.empIdToName[this.allEmplyees[i].emp_id] = this.allEmplyees[i].emp_name;
      }
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async changeEmployee() {
    // var obj = new Object();
    // obj['b_acct_id'] = this.b_acct_id;
    // obj['emp_id'] = this.selectEmpObj['emp_id'];

    // var resp = await this.establishmentService.getCurrentArrangement(obj);
    // if (resp['error'] == false) {
    //   this.emp_id = resp.data[0]['emp_id'];
    // } else {
    //   this.snackBar.open("Error while getting current arrangment ", 'Error', {
    //     duration: 5000
    //   });
    // }

    await this.getAllProbation();
  }






  async submitProbation() {

    if (this.selectEmpObj['emp_id'] == undefined || this.selectEmpObj['emp_id'] == null || this.selectEmpObj['emp_id'] == '' ||
      this.probationObj['probation_days'] == undefined || this.probationObj['probation_days'] == null || this.probationObj['probation_days'] == '' ||
      this.probationObj['effective_dt'] == undefined || this.probationObj['effective_dt'] == null || this.probationObj['effective_dt'] == '') {
      Swal.fire("Warning", "* Field Required ", 'warning');
      return;
    }
    this.probationObj['b_acct_id'] = this.b_acct_id;
    this.probationObj['emp_id'] = this.selectEmpObj['emp_id'];
    this.probationObj['create_user_id'] = this.erpUser.user_id;
    this.probationObj['probation_status_code'] = 'ACTIVE';

    this.spinner.show();
    var resp = await this.establishmentService.addPartyProbation(this.probationObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllProbation();
      this.probationObj = {};
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      Swal.fire('Success', "Probation Added Successfully!", 'success');
      // this.snackBar.open("Probation Added Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      this.snackBar.open("Error while Adding Probation  Of Employee", 'Error', {
        duration: 5000
      });
    }
  }







  async updateProbation() {
    if (this.update_probationObj['effective_dt'] == undefined || this.update_probationObj['effective_dt'] == null || this.update_probationObj['effective_dt'] == '' ||
      this.update_probationObj['probation_status_code'] == undefined || this.update_probationObj['probation_status_code'] == null || this.update_probationObj['probation_status_code'] == '' ||
      this.update_probationObj['assessment_feedback'] == undefined || this.update_probationObj['assessment_feedback'] == null || this.update_probationObj['assessment_feedback'] == '' ||
      this.update_probationObj['probation_days'] == undefined || this.update_probationObj['probation_days'] == null || this.update_probationObj['probation_days'] == ''

    ) {
      Swal.fire("Warning", "* Fields  required", 'warning')
      return
    }
    this.spinner.show();
    this.update_probationObj['b_acct_id'] = this.b_acct_id;
    this.update_probationObj['update_user_id'] = this.erpUser.user_id;

    var resp = await this.establishmentService.updateProbation(this.update_probationObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllProbation();
      this.update_probationObj = {};
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      Swal.fire('Success', 'Probation Updated Successfully!', 'success');
      // this.snackBar.open("Probation Updated Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while updating Probation  Of Employee', 'error')
      // this.snackBar.open("Error while updating Probation  Of Employee", 'Error', {
      //   duration: 5000
      // });
    }

  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  updateRef() {
    this.update_probationObj['emp_id'] = ''
    this.update_probationObj['probation_days'] = ''
    this.update_probationObj['effective_dt'] = ''
    this.update_probationObj['probation_status_code'] = ''
    this.update_probationObj['assessment_feedback'] = ''
    this.update_probationObj['probation_status_code'] = ''


  }

}
