import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '../../../authentication.service';
import { ProfileService } from '../../../portal/service/profile.service';
import { MainService as Portal_main } from '../../../portal/service/main.service';
import { MainService } from '../../service/main.service';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: '[app-hrms-header]',
  templateUrl: './hrms-header.component.html',
  styleUrls: ['./hrms-header.component.css']
})

export class HrmsHeaderComponent implements AfterViewInit {
  constructor(private router: Router, public portal_main: Portal_main, public mainService: MainService,
    public auth: AuthenticationService, private profileService: ProfileService, private sanitizer: DomSanitizer) { }
  isDropdownOpen = false;

  erpUser :any = {};
  placeholderText: any
  action = [
    { id: 1, name: 'Profile', route: "profile" },
    { id: 2, name: 'Logout', route: "login" },
  ];
  selectedAction: any

  @ViewChild('ngSelectElement') ngSelectElement!: NgSelectComponent;

  async ngOnInit() {
    console.log(this.portal_main.accInfo)
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    await this.portal_main.getUserImage(this.erpUser['user_id']);
    await this.portal_main.getAccImage(this.erpUser['b_acct_id']);
    await this.portal_main.getAccountInfo(this.erpUser['b_acct_id']);
    await this.portal_main.getHeaderInfo(this.erpUser['b_acct_id']);
    await this.changeLanguage();
    this.placeholderText = this.erpUser['party_name']

  }

  changeLanguage() {
    console.log("laungage Change");
    this.mainService.language_cd = this.auth.language_cd
  }


  ngAfterViewInit() {
  }
  toggleSelect() {

    if (this.isDropdownOpen) {
      this.ngSelectElement.close();
    } else {
      this.ngSelectElement.open();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  onSelectionChange2() {
    const selectedVal = this.selectedAction.route
    this.router.navigate([selectedVal]);
  }

}
