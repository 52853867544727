<div class="page-content fade-in-up">
    <br>
    <div class="row">
        <button style="margin-left:0.5%;" class="btn btn-link" (click)="back()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i> Go Back</button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <br>
                <div class="ibox-head">
                    <div class="ibox-title">WORKFLOW MANAGEMENT</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> Employee Complaint Detail List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-7-2" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> Suspend After Complaint Forward</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-7-1">
                            <br>
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Complaint ID <!--{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint5']}}-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="complaint_type_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Complaint Type <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint6']}}-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.complaint_type_code}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="complaint_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Complaint Desc<!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint7']}}-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.complaint_desc}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="complaint_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Complaint Date<!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint8']}}-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.complaint_dt)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Employee ID <!--{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint8']}}-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Employee name<!--{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint8']}} -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{empIdToName[element.emp_name] }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="complaint_status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Complaint Status<!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint9']}}-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.complaint_status}} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Action<!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint10']}}--></th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <h6 *ngIf="element.complaint_status == 'FORWARD'"  >Forward To {{element.forward_to}}</h6>
                                            <button class="btn btn-primary" *ngIf="element.complaint_status == 'FORWARD' && element.forward_status == '1'" (click)="open_forward(element)"> Forward</button>
                                            <button class="btn btn-success" *ngIf="element.complaint_status != 'APPROVED' && element.complaint_status != 'CLOSED' && element.complaint_status != 'SUSPENDED'" (click)="approve(element)"> Approve</button>
                                            <button class="btn btn-secondary" *ngIf="element.complaint_status != 'SUSPENDED' && element.complaint_status != 'APPROVED' && element.complaint_status != 'CLOSED'" (click)="open_suspend(element)">Suspend</button>
                                            <button class="btn btn-danger" *ngIf="element.complaint_status != 'CLOSED' && element.complaint_status != 'APPROVED' && element.complaint_status != 'SUSPENDED'" (click)="open_close(element)">Close</button>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="view_details">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> View Details<!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint10']}}--></th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-warning" (click)="view(element)"> View Support Document<!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint11']}}--></button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <img [src]="imgURL" />

                                    <!-- <a download="file" [href]="imgURL">{{filename}}</a> -->
                                    <!-- <div>{{imgURL}}</div> -->
                                </div>

                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-7-2">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Employee : 
                                  <!--   {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code15']}}-->
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="newallEmplyees" (change)="getFixedPayDetails()" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="" [(ngModel)]="suspensionCompObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->
                                    <input class="form-control" type="text" [(ngModel)]="suspensionCompObj['emp_name']" disabled>
                                </div>
                            </div>
                            <br>

                            <hr>

                            <div class="row">
                                <div class="col-4 text-right">
                                    Order ID :
                                    <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code16']}} -->
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="suspensionCompObj['order_id']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Suspension Start Date :
                                    <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code17']}} -->
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="suspensionCompObj['suspension_start_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Suspension Note :
                                    <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code31']}} -->
                                </div>
                                <div class="col-4">
                                    <textarea placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code32']}}" type="text" class="form-control" [(ngModel)]="suspensionCompObj['suspension_note']"></textarea>
                                </div>
                            </div>
                            <br>
                            <br>
                               <div class="row">
                                 <h6 class="text-center">Payment Fractions<!--{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code27']}}--></h6> 
                                 <div class="col-lg-2 col-md-2 col-sm-12"></div>
                                 <div class="col-lg-4 col-md-4 col-sm-12">
                                    
                                     <table>
                                         <tr>
                                         <th>Pay<!--{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code28']}}--></th>
                                         <th>Fraction(%)<!--{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code29']}}--></th>
                                         </tr>
                                         <tr *ngFor="let pay of payCompArr;let i=index">
                                            <td>{{pay.pay_component_code}}</td>
                                            <td><input class="form-control" type="number" [(ngModel)]="payCompObj[i]"></td>
                                         </tr>
                                     </table>
                                 </div>
                                 <div class="col-lg-4 col-md-4 col-sm-12">
                                     <table>
                                     <tr>
                                    <th>Deduction<!--{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code30']}}--></th>
                                    <th>Fraction(%)<!--{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code29']}}--></th>
                                     </tr>
                                     <tr *ngFor="let ded of dedCompArr;let j=index">
                                        <td>{{ded.pay_component_code}}</td>
                                        <td><input class="form-control" type="number" [(ngModel)]="dedCompObj[j]" ></td>
                                     </tr>
                                    </table>
                                 </div>
                                 <div class="col-lg-2 col-md-2 col-sm-12"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitCompSuspension()">
                                         Submit     <!--{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code21']}}--> 
                                    </button>

                                </div>
                            </div>
                        </div>
                       </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>

<!-- FOR OPEN FORWARD DIALOG BOX-->
<div class="modal" id="FORWARD">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Forwarding Complaint</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;">Complaint ID :</h6>
                    </div>
                    <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="forObj['id']" disabled>
                    </div>
                    
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;">Select Module/Department :</h6>
                    </div>
                    <div class="col-3">
                        <ng-select [items]="allModule" (change)="selectedModule(forObj['module_cd'])" bindLabel="module_name" bindValue="module_cd" [multiple]="false" placeholder="" [(ngModel)]="forObj['module_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name">
                        </ng-select>
                    </div>
                    
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;">Select Role :</h6>
                    </div>
                    <div class="col-3">
                        <ng-select [items]="current_role" (change)="selectedRole(forObj['role_desc'],forObj['module_cd'])" bindLabel="role_name" bindValue="role_desc" [multiple]="false" placeholder="" [(ngModel)]="forObj['role_desc']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name">
                        </ng-select>
                    </div>
                    
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;">Select User :</h6>
                    </div>
                    <div class="col-3">
                        <ng-select [items]="allModuleWiseUser" (change)="selectUser(forObj['user_id'])" bindLabel="party_name" bindValue="user_id" [multiple]="false" placeholder="" [(ngModel)]="forObj['user_id']"  [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name">
                        </ng-select>
                    </div>
                    
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                <div class="col-3">
                    <h6 style="margin-left: 2%;">Note :</h6>
                </div>
                <div class="col-3">
                    <textarea style="width: 100%;" id="desc" name="desc" [(ngModel)]="forObj['note']"></textarea>
                </div>
                
                <div class="col-3"></div>
            </div>
                <br>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="forwardComplaint()" >Forward</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- FOR OPEN CLOSE DIALOG BOX-->
<div class="modal" id="CLOSE">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Close Complaint</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;">Complaint ID :</h6>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="closeObj['id']" class="form-control" disabled>
                    </div>
                    
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;">Complaint Description :</h6>
                    </div>
                    <div class="col-3">
                        <textarea style="width: 100%;" [(ngModel)]="closeObj['complaint_desc']"  id="desc" name="desc" disabled></textarea>
                    </div>
                    
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                <div class="col-3">
                    <h6 style="margin-left: 2%;">Closing Note :</h6>
                </div>
                <div class="col-3">
                    <textarea style="width: 100%;" [(ngModel)]="closeObj['closing_note']" id="desc" name="desc"></textarea>
                </div>
                
                <div class="col-3"></div>
            </div>
                <br>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal" (click)="popup_cancel()">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="closeComplaint()">Submit</button>
                </div>

            </div>
        </div>
    </div>
</div>
