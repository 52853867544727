
<!-- <div class="modal-dialog modal-lg mt-0 myDiv" style="max-width:100%;"> -->
  <div>

    <div class="grad4" style="color: white;">
        <div class="row">
            <div class="col-9">
                <h4 class="mt-3"  style="font: bold;">
                    TRACKING MANAGEMENT SYSTEM (TMS)
                </h4>
            </div>
            <div class="col-3 text-right">
                <button type="button" class="btn btn-success mt-3" *ngIf="live" (click)="Go_live()" >Go Live</button>
                <button type="button" class="btn btn-danger mt-3" (click)="Go_offline()" *ngIf="Offline">Offline</button>
                <button type="button" class="btn btn-danger mt-3" (click)="dashboard1()">Go To Dashboard</button>
            </div>
        </div>

    </div>

    <mat-drawer-container class="example-container2">
        <mat-drawer mode="side" opened>
            <div style=" padding-left: 10px; overflow-y: auto;">

                <div class="row p-2 inf" style="border: 1px solid black; height: 40px;  background-color: gray;" >
                    <h5 class="pl-2 mt-2" style="color: white;">All Employees List</h5>
                </div>

                <div class="row inf" >
                    <div class="col-12 d-flex" style="border:1px solid gray;height:30px; " *ngIf="live">
                        <input type="checkbox" data-md-icheck [(ngModel)]="binding1"
                            (change)="toggleVisibility2($event,i)" />
                        <h6 class="pl-3 mt-2">All Employees</h6>


                    </div>
                    <ng-container>
                        <div class="inf" style="border:1px solid gray;">
                            <div class="row  " *ngFor="let item2 of userlist_z ; let i = index;">
                                <div class="col-12  d-flex ">
                                    <label class="container pl-5">
                                        <input type="checkbox" data-md-icheck
                                            (change)="toggleVisibilitySub($event,i)" [(ngModel)]="this.selectObj[i]" />
                                        {{item2.emp_name}}
                                    </label>

                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>



                <!-- <table>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr >
                            <td *ngFor="let item of allzone">
                                <input type="checkbox" (change)="checkuser(i)">
                                <h6 class="pl-3 mt-2">{{item.zone_name}}</h6></td>

                                <td *ngFor="let item of userlist_z ;">
                                    <input type="checkbox">
                                    <h6 class="pl-3 mt-2">{{item.emp_name}}</h6>
                                </td>
                        </tr>

                       </table> -->

                       <div *ngIf="live">
                        <div class="row inf" style="border: 1px solid black; height: 40px;  background-color: gray;">
                            <h5 class="pl-2 mt-2" style="color: white;">Time Period</h5>
                        </div>
                        <div class="row mt-2 inf">
                            <h6 class="mt-1 pl-2">From Date/time</h6> :
                        </div>
                        <div class="row mt-1 inf">

                            <input type="date" class="form-control" [(ngModel)]="Date_Obj['From_date']" (change)="chektime()">
                            <div class="div"></div>
                        </div>
                        <div class="row mt-1 inf" *ngIf="time_hide">
                            <input type="time" class="form-control" placeholder="select time"
                                [(ngModel)]="time_Obj['From_time']" >

                        </div>
                        <div class="row mt-2 pl-2 inf">
                            <h6 class="mt-1">To Date</h6> :
                        </div>
                        <div class="row mt-1 inf">
                            <input type="date" class="form-control" [(ngModel)]="Date_Obj['To_date']" (change)="chektime()">
                        </div>
                        <div class="row mt-1 inf" *ngIf="time_hide">
                            <input type="time" class="form-control" placeholder="select time" [(ngModel)]="time_Obj['to_time']" >

                        </div>
                    </div>
                    <div class="inf" style=" margin-left: -13px;" *ngIf="live">
                        <button type="button" class="btn btn-primary" style="width:90%;" (click)="Apply_track()"> Apply
                        </button>
                    </div>
                    <div class="inf mt-3" style=" margin-left: -13px;" *ngIf="Offline">
                        <button type="button" class="btn btn-success" style="width:90%;" (click)="live_track()"> Apply
                        </button>
                    </div>
            </div>

        </mat-drawer>
        <mat-drawer-content>
            <div class="address">
              <!-- ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"  -->
                <input class="form-control check_map" (onAddressChange)="handleAddressChange($event)"/>

            </div>
            <div #mapContainer  class="sidemap">
              <google-map   height="100%" width="100%" [center]="center" [zoom]=10.5 >
                 <map-marker *ngFor="let markerPosition of markerPositions" [position]="markerPosition"
                  [options]="markerOptions">
                </map-marker>
              </google-map>
                <!-- <agm-map
                [latitude]="lat"
                [longitude]="lng"
                [zoom]="zoom" (mapReady)="onMapReady($event)" [fullscreenControl]='true' [zoomControl]="true"
                 [disableDefaultUI]="false" [mapTypeControl]='true'>



                    <agm-marker *ngFor="let m of end; let i = index" (markerClick)="clickedMarker(m.label, i)"
                        [markerDraggable]="m.draggable" [latitude]="m.lat" [longitude]="m.lng" [label]="m.label">
                        <agm-info-window>
                            <strong>User Name:{{m.location}}</strong><br />
                            <strong>Latitude:{{m.lat}}</strong><br />
                            <strong>Longitude:{{m.lng}}</strong><br />
                            <strong>Date:{{m.currentdate}}</strong><br />
                            <strong>Time:{{m.currenttime}}</strong><br />
                        </agm-info-window>

                    </agm-marker>


                </agm-map> -->
            </div>

        </mat-drawer-content>

    </mat-drawer-container>







    <!--
    <div class="row mt-1 " style="margin-left:30px;">
        <div class="col-2 justify-content-center">
            <div class="row">
                <div class="col-12 text-center">

                </div>
            </div>


        </div>
        <div class="col-9">
            <div class="row mt-1">
                <div class="d-flex" *ngFor="let item of allzone;" style="margin-left: 10px;">
                    <input type="chekbox" [ngStyle]="{'background-color':item.color }"
                     style="width: 25px; height: 20px;">&nbsp;<h6 style=" margin-top:3px; padding-left :5px; padding-right:50px;">{{item.zone_name}}</h6>&nbsp;&nbsp;
                    </div>

            </div>
        </div>


    </div> -->





</div>
<!-- </div> -->
<ngx-spinner>Loading....</ngx-spinner>
