import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
// import { ThrowStmt } from '@angular/compiler';
declare var $: any;
import Swal from 'sweetalert2';
import { MainService } from './../../service/main.service';
import { AllEmpService } from './../../service/all-emp.service';
import { HrHierService } from '../../service/hr-hier.service';

@Component({
  selector: 'app-hr-hierarchy',
  templateUrl: './hr-hierarchy.component.html',
  styleUrls: ['./hr-hierarchy.component.css']
})
export class HrHierarchyComponent implements OnInit {
  list_flage = true;
  create_flage = false;
  update_flage = false;

  ebillUser:any ;
  b_acct_id:any ;
  Obj :any = {}
  ActiveNode :any = [];


  allRoles :any = [];
  allComp:any ;
  selectedComp :any = [];
  dataSource:any ;
  role_name:any ;
  role_id:any ;
  planing_arr :any = [{}]
  access :any = [];
  existing_heirarchy_nodes :any = []
  displayedColumns :any = ['id', 'lvl1_user_cd', 'lvl1_node_type', 'lvl1_value', 'lvl2_user_cd', 'lvl2_node_type', 'lvl2_value', 'lvl3_user_cd', 'lvl3_node_type', 'lvl3_value', 'lvl4_user_cd', 'lvl4_node_type', 'lvl4_value', 'lvl5_user_cd', 'lvl5_node_type', 'lvl5_value', 'lvl6_user_cd', 'lvl6_node_type', 'lvl6_value', 'lvl7_user_cd', 'lvl7_node_type', 'lvl7_value', 'is_leaf', 'leaf_cd', 'leaf_node_type', 'leaf_value', 'action'];
  constructor(private allEmpService: AllEmpService, private hrHierService: HrHierService, public mainService: MainService, private spinner: NgxSpinnerService) { }
  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.dataSource){
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.dataSource){
      this.dataSource.sort = value;
    }
  }
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getcostcenters()
    await this.getproject()
    console.log(this.ActiveNode)
  }
  allcostcenters :any = []
  // ***********************************Account Integration******************************************************************
  async getcostcenters() {
    let obj :any  =new  Object()
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getCostCenter(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.allcostcenters = []
      console.log(resp['data'])
      for (let i = 0; i < resp['data'].length; i++) {
        let obb = Object.assign({}, resp['data'][i])
        obb['node_cd'] = obb['cc_code']
        obb['desc'] = obb['cc_code'] + '-' + obb['cc_name']
        obb['node_desc'] = obb['desc']
        this.allcostcenters.push(obb)
      }
      console.log(this.allcostcenters)
      console.log(this.planing_arr);



    } else {
      this.spinner.hide()
      Swal.fire('Error...', 'Error While Getting Cost Center', 'error')
    }
  }
  projecthier :any = []
  projhierobj :any = {}
  projhierobjtype :any = {}
  projhierobjtypecd:any  = {}
  async getproject() {
    let obj :any  =new  Object()
    obj['b_acct_id'] = this.b_acct_id
    let resp = await this.hrHierService.getprojectHierarchy(obj);

    console.log(resp)
    for (let i = 0; i < resp.data.length; i++) {
      if (resp.data[i]['lvl1_value'] == "All Projects") {
        resp.data[i]['lvl1_value'] = 'HR-root'

      }
    }
    if (resp['error'] == false) {
      console.log(resp['data']);
      var data = []
      for (let i = 0; i < resp.data.length; i++) {
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd'])|| (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR")) && (resp.data[i]['module_cd'] != null ||resp.data[i]['module_cd'] != undefined) ) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;

      this.projecthier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_user_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_user_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_user_cd']] = resp['data'][i]['lvl' + (j) + '_cd']

        }
      }
      console.log(resp.data)
      console.log(this.allcostcenters)
      console.log(this.planing_arr);
      this.dataSource = new MatTableDataSource(resp['data'])
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;


    } else {
      this.spinner.hide()
      Swal.fire('Error...', 'Error While Getting Cost Center', 'error')
    }
  }
  down(item:any , k:any ) {
    this.check = true
    let patharr = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      patharr.push(this.projhierobj[this.planing_arr[i]['hierarchy_type']])
    }
    this.Obj['path_desc'] = patharr.join("-->")
    this.planing_arr.push({ level: k + 2, existing_heirarchy_nodes: [] })
    console.log(this.planing_arr, item)
    let arr:any  = []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_user_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] != null) {
        if ((this.projecthier[i]['lvl' + (k + 1) + '_user_cd'] == item['hierarchy_type'] || k == -1) && this.projecthier[i]['leaf_node_type'] != 'HREPM') {
          this.planing_arr[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'] })
          arr.push(this.projecthier[i]['lvl' + (k + 2) + '_user_cd'])
        }
      }
    }
  }
  remove(item:any , i:any ) {
    if (this.planing_arr.length > 2) {
      this.planing_arr.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arr.length; i++) {
        console.log(this.planing_arr[i])
        patharr.push(this.projhierobj[this.planing_arr[i]['hierarchy_type']])
      }
      this.Obj['path_desc'] = patharr.join("-->")
    }
    else {
      Swal.fire('Oops!!!', 'You Must Select Upto Costcenter', 'warning')
    }

    if (this.planing_arr[this.planing_arr.length - 1]['hierarchy_type'] != null || this.planing_arr[this.planing_arr.length - 1]['hierarchy_type'] != undefined) {
      this.check = false;
    }

  }
  check = true;
  ///********************************cretae hier*************************************** */

  async submithier() {
    let level = 0
    this.Obj['b_acct_id'] = this.b_acct_id
    console.log(this.planing_arr)
    for (let i = 0; i < this.planing_arr.length; i++) {
      console.log(this.planing_arr[i])
      //this.Obj['lvl'+ this.planing_arr[i]['level']+'_user_cd'] = this.planing_arr[i]['level']
      this.Obj['lvl' + this.planing_arr[i]['level'] + '_user_cd'] = this.planing_arr[i]['hierarchy_type']
      this.Obj['lvl' + this.planing_arr[i]['level'] + '_value'] = this.projhierobj[this.planing_arr[i]['hierarchy_type']]
      this.Obj['lvl' + this.planing_arr[i]['level'] + '_node_type'] = this.projhierobjtype[this.planing_arr[i]['hierarchy_type']]
      this.Obj['lvl' + this.planing_arr[i]['level'] + '_cd'] = this.projhierobjtypecd[this.planing_arr[i]['hierarchy_type']]
      console.log(this.planing_arr[i]['level'], this.Obj['lvl' + this.planing_arr[i]['level'] + '_user_cd'])
      level = this.planing_arr[i]['level']

    }
    this.Obj['lvl' + (level + 1) + '_user_cd'] = this.Obj['node_cd']
    this.Obj['lvl' + (level + 1) + '_node_type'] = this.Obj['node_type']
    this.Obj['lvl' + (level + 1) + '_value'] = this.Obj['node_desc']
    this.Obj['lvl' + (level + 1) + '_type'] = 'text'
    this.Obj['leaf_user_cd'] = this.Obj['node_cd']
    this.Obj['leaf_node_type'] = this.Obj['node_type']
    this.Obj['leaf_value'] = this.Obj['node_desc']
    this.Obj['leaf_type'] = 'text'
    this.Obj['module_cd'] = 'HR'
    console.log(this.Obj);
    this.spinner.hide();

    var resp = await this.hrHierService.createHierarchy(this.Obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getproject();
      await this.reset();
      Swal.fire("Success...", "Successfuly Created", 'success');
    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While Create A New Node..', 'error');
    }
  }
  reset() {
    this.Obj = {}
    this.planing_arr = []
    this.down({}, -1)
  }
  // ***********************************Account Integration******************************************************************

  setHierarchyNode(item:any , i:any ) {
    if (this.planing_arr.length >= 2) {
      this.check = false
    }
    else {
      this.check = true
    }
    console.log(item, i);

    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arr[i]['hierarchy_type']])
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }
  show_msg() {
    Swal.fire('Warning..', 'This Action Can Not Be Perform For Update', 'warning')
  }
  used_nodes :any = []
  selected_row :any = {}
  add_dropdown(data:any , index:any ) {
    //console.log(data, index)
    let flag = true
    let obj = {}
    if (this.planing_arr.length >= 5) {
      Swal.fire('Warning..', '', 'warning');
      return
    }
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
        obj = Object.assign({}, this.existing_heirarchy_nodes[i])
      }
    }
    this.selected_row = Object.assign({}, obj)
    this.selected_row['index'] = index

    this.filter_data()
  }
  filter_data() {
    console.log(this.planing_arr, this.existing_heirarchy_nodes);

    let dummy = []
    let max_level = 0
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (this.existing_heirarchy_nodes[i]['level'] >= max_level) {
        max_level = this.existing_heirarchy_nodes[i]['level']
      }

    }
    for (let i = 0; i < this.allcostcenters.length; i++) {
      if (!this.existing_heirarchy_nodes.includes(this.allcostcenters[i]))
        this.existing_heirarchy_nodes.push(this.allcostcenters[i])
    }


    console.log(this.planing_arr, this.existing_heirarchy_nodes);
    console.log(max_level, this.selected_row, this.existing_heirarchy_nodes)
    for (let j = 1; j <= max_level; j++) {
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        let node_arr = []
        node_arr = this.existing_heirarchy_nodes[i]['path'].split('|')
        if (node_arr.includes(this.selected_row['node_cd'])) {
          if (this.existing_heirarchy_nodes[i]['level'] == j && this.existing_heirarchy_nodes[i]['level'] > this.selected_row['level']) {
            dummy.push(this.existing_heirarchy_nodes[i])
          }
        }
      }
      if (dummy.length > 0) {
        break;
      }
    }
    this.planing_arr.push({})
    this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = []
    this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = dummy
    console.log(dummy, 'dummy', this.planing_arr)
  }
  remove_dropdown(data:any , i:any ) {
    //console.log(data, i)
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop()
    }
    let dummy = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy.push(this.planing_arr[i]['hierarchy_type'])
    }
    let dummy2 = []
    if (dummy.length > 0) {
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }
  NodeCodeToNodeDesc :any = {};

  async gotolist() {
    this.list_flage = true;
    this.create_flage = false;
    this.update_flage = false;
    await this.getproject();
  }


  createnode() {
    this.reset()
    this.list_flage = false;
    this.create_flage = true;
    this.update_flage = false;
  }


  sortArrayOfObjects = (arr:any , key:any ) => {
    return arr.sort((a:any , b:any ) => {
      return a[key] - b[key];
    });
  };


  ParentNodeChange() {
    console.log(this.Obj);
    if (this.Obj['parent_node_cd'] != null || this.Obj['parent_node_cd'] != undefined) {
      var dd = this.NodeCodeToNodeDesc[this.Obj['parent_node_cd']]['path'].split("|");
      var str = ''
      for (let i = 0; i < dd.length; i++) {
        str = str + this.NodeCodeToNodeDesc[dd[i]]['node_desc'] + " -> "
      }
      this.Obj['path_desc'] = str;
    } else {
      this.Obj['path_desc'] = null;
    }
  }



  ///********************************update hier*************************************** */
  element:any
  data_to_update :any = {}
  async open_update(data:any ) {
    this.list_flage = false;
    this.create_flage = false;
    this.update_flage = true;
    console.log(data)
    this.element=data
   /*  if (data['is_leaf'] == 1 || data['level'] == 1) {
      Swal.fire('Warning..', 'You Can Not Update This Hierarchy', 'warning')
    } else  */{
      // this.Obj = Object.assign({}, data)
      // this.data_to_update = Object.assign({}, data)
      // let all_path = []
      // let planing_path = ''
      // for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      //   if (data['node_cd'] == this.existing_heirarchy_nodes[i]['node_cd']) {
      //     // console.log()
      //     planing_path = this.existing_heirarchy_nodes[i]['path']
      //   }
      // }
      // let dum = []
      // dum = planing_path.split('|')
      // let dummy2 = []
      // if (dum.length > 0) {
      //   for (let i = 0; i < dum.length-1; i++) {
      //     for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
      //       if (dum[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
      //         dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
      //       }
      //     }
      //   }
      // }
      this.planing_arr = []
      for (let i = 1; i < 8; i++) {
        if (data['lvl' + (i) + '_user_cd'] != null && data['leaf_user_cd'] != data['lvl' + (i) + '_user_cd']) {
          if (i == 1) {
            //  this.planing_arr.push({hierarchy_type:data['lvl'+(i)+'_user_cd'],level:1})
            this.down({ hierarchy_type: data['lvl' + (i) + '_user_cd'] }, -1)
            this.planing_arr[this.planing_arr.length - 1]['hierarchy_type'] = data['lvl' + (i) + '_user_cd']
          } else {
            //  this.planing_arr.push({hierarchy_type:data['lvl'+(i)+'_user_cd']})
            this.down({ hierarchy_type: data['lvl' + (i - 1) + '_user_cd'] }, i - 2)
            this.planing_arr[this.planing_arr.length - 1]['hierarchy_type'] = data['lvl' + (i) + '_user_cd']
            this.setHierarchyNode(this.planing_arr[this.planing_arr.length - 1], i - 1)
          }

        }
        this.Obj['node_type'] = data['leaf_node_type']
        this.Obj['node_cd'] = data['leaf_user_cd']
        this.Obj['node_desc'] = data['leaf_value']
        /* let arr= []
        for (let i = 0; i < this.projecthier.length; i++) {
          if(!arr.includes(this.projecthier[i]['lvl'+(k+2)+'_user_cd']) && this.projecthier[i]['lvl'+(k+2)+'_user_cd'] != null){
            if(this.projecthier[i]['lvl'+(k+1)+'_user_cd'] == item['hierarchy_type'] || k == -1){
              this.planing_arr[k+1]['existing_heirarchy_nodes'].push({node_cd:this.projecthier[i]['lvl'+(k+2)+'_user_cd'],desc:this.projecthier[i]['lvl'+(k+2)+'_value']})
              arr.push(this.projecthier[i]['lvl'+(k+2)+'_user_cd'])

            }

          } */
        /*   if(this.projecthier[i]['lvl'+(k+1)+'_user_cd'] == item['node_cd']){

          } */

        //}

      }
    //  this.Obj['path_desc'] = dummy2.join(' --> ')
    //  let dummy_data = []
    /*   for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        if (planing_path.includes(this.existing_heirarchy_nodes[i]['path'])) {
          all_path.push(this.existing_heirarchy_nodes[i]['path'])
          let obj = {}
          obj['existing_heirarchy_nodes'] = [this.existing_heirarchy_nodes[i]]
          obj['hierarchy_type'] = this.existing_heirarchy_nodes[i]['node_cd']
          obj['level'] = this.existing_heirarchy_nodes[i]['level']
          dummy_data.push(obj)
        }
      }
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        if (!all_path.includes(this.existing_heirarchy_nodes[i]['path'])) {
          dummy_data[dummy_data.length - 1]['existing_heirarchy_nodes'].push(this.existing_heirarchy_nodes[i])
        }
      } */
     /*  let arr = this.sortArrayOfObjects(dummy_data, 'level')
      let dummy = []
      for (let i = 0; i < arr.length - 1; i++) {
        dummy.push(arr[i])
      }
      this.planing_arr = dummy
      // this.planing_arr=arr
      console.log(data, dummy_data, 'filter', all_path, dummy_data[dummy_data.length - 1]['existing_heirarchy_nodes'])
     */  $('.nav-tabs a[href="#tab-7-3"]').tab('show')
    }
  }



  async update() {

    let level = 0
    this.Obj['b_acct_id'] = this.b_acct_id
    console.log(this.planing_arr)
    for (let i = 0; i < this.planing_arr.length; i++) {
      this.Obj['lvl' + this.planing_arr[i]['level'] + '_user_cd'] = this.planing_arr[i]['hierarchy_type']
      this.Obj['lvl' + this.planing_arr[i]['level'] + '_value'] = this.projhierobj[this.planing_arr[i]['hierarchy_type']]
      this.Obj['lvl' + this.planing_arr[i]['level'] + '_node_type'] = this.projhierobjtype[this.planing_arr[i]['hierarchy_type']]
      this.Obj['lvl' + this.planing_arr[i]['level'] + '_cd'] = this.projhierobjtypecd[this.planing_arr[i]['hierarchy_type']]
      level = this.planing_arr[i]['level']

    }
    this.Obj['lvl' + (level + 1) + '_user_cd'] = this.Obj['node_cd']
    this.Obj['lvl' + (level + 1) + '_node_type'] = this.Obj['node_type']
    this.Obj['lvl' + (level + 1) + '_value'] = this.Obj['node_desc']
    let temp=this.projecthier[this.projecthier.map(function(e:any ){return e.leaf_user_cd}).indexOf(this.Obj['node_cd'])]
    this.Obj['lvl' + (level + 1) + '_cd'] = this.element['lvl' + (level + 1) + '_cd']
    this.Obj['lvl' + (level + 1) + '_type'] = 'text'
    this.Obj['leaf_user_cd'] = this.Obj['node_cd']
    this.Obj['leaf_node_type'] = this.Obj['node_type']
    this.Obj['leaf_value'] = this.Obj['node_desc']
    this.Obj['leaf_type'] = 'text';
    this.Obj['leaf_cd'] = this.element['leaf_cd']
    this.Obj['module_cd'] = 'HR'
    console.log(this.Obj)
    this.spinner.show()
    var resp = await this.hrHierService.projectupdateHierarchy(this.Obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getproject();
      await this.reset();

      Swal.fire("Success...", "Updated Successfully", 'success');
    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While Updating Node..', 'error');
    }

  }




  //***************************delete hier************************* */
  async delete(element:any ) {

    Swal.fire({
      title: 'Are You Sure ?',
      text: "If You Delete This Node Then All Nodes Below This Node Will Be Deleted...!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })

    console.log(element)

  }
  async finaldelete(element:any ) {
    let obj:any  = {}
    console.log(this.projecthier)
    let index = this.projecthier.map(function (e:any ) { return e.leaf_cd; }).indexOf(element['leaf_cd'])
    console.log(index)
    obj = Object.assign({}, this.projecthier[index])
    obj['b_acct_id'] = this.b_acct_id
    obj['update_user_id'] = this.ebillUser.user_id
    obj['leaf_cd'] = element['leaf_cd']
    this.spinner.show()
    console.log(obj)
    var resp = await this.hrHierService.deleteHierarchy(obj)
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getproject()
      Swal.fire('Success', resp['data'], 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }
  }






  /////////////////////////////////////
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  //****************************************Unused Code**************************************************** */



  // async getAllHeirNodeList() {
  //   this.spinner.show();
  //   var obj = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   console.log(obj)
  //   var resp = await this.embHierService.getAllEmbHierarchy(JSON.stringify(obj));
  //   console.log(resp)
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     console.log(resp['data']);
  //     this.ActiveNode = [];
  //     this.existing_heirarchy_nodes = []
  //     this.NodeCodeToNodeDesc = {};
  //     for (let i = 0; i < resp.data.length; i++) {
  //       if (resp.data[i]['status'] == 'ACTIVE' && resp.data[i]['is_leaf'] == 0) {
  //         if (!resp['data'][i]['node_type']) {
  //           resp['data'][i]['desc'] = resp['data'][i]['node_desc'];
  //         } else {
  //           resp['data'][i]['desc'] = resp['data'][i]['node_desc'] + ' - (' + this.mainService.codeValueShowObj['EMB003'][resp['data'][i]['node_type']] + ')';
  //         }
  //         this.ActiveNode.push(resp.data[i])
  //         this.existing_heirarchy_nodes.push(resp['data'][i])

  //       }
  //       this.NodeCodeToNodeDesc[resp['data'][i]['node_cd']] = resp['data'][i]
  //     }

  //     for(let i=0;i<this.allcostcenters.length;i++)
  //     {
  //       this.allcostcenters[i]['level']=2;
  //       this.allcostcenters[i]['is_leaf']=0;
  //       this.allcostcenters[i]['node_type']='CC';
  //       this.allcostcenters[i]['path']='EMB|'+ this.allcostcenters[i]['node_cd'];


  //       this.NodeCodeToNodeDesc[this.allcostcenters[i]['node_cd']] = this.allcostcenters[i];
  //     }
  //     let dummy = []
  //     for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
  //       if (1 == this.existing_heirarchy_nodes[i]['level']) {
  //         dummy.push(this.existing_heirarchy_nodes[i])
  //       }
  //     }
  //     this.planing_arr[0]['existing_heirarchy_nodes'] = dummy
  //     console.log(this.ActiveNode);
  //     this.dataSource = new MatTableDataSource(resp['data'])
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire('Error...', 'Error While EMB HIER', 'error')
  //   }
  // }


  // async inactive(element) {

  //   this.spinner.show();
  //   var obj = Object.assign({}, element)
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['update_user_id'] = this.ebillUser.user_id;
  //   obj['status'] = 'INACTIVE';
  //   console.log(obj)
  //   var resp = await this.embHierService.updateEmbHierarchyNode(obj);
  //   console.log(resp)
  //   if (resp['error'] == false) {
  //     this.spinner.hide();
  //     await this.getAllHeirNodeList();
  //     Swal.fire("Successfully Update", "Successfully Status Changed To Incative", 'success');

  //   }
  //   else {
  //     this.spinner.hide();
  //     Swal.fire('Error...', 'Error While change status...', 'error');
  //   }

  // }
  // async submit() {
  //   let parent_node_cd;
  //   let parent_node_cd_flag = false
  //   let duplicate_flag = false
  //   if (this.planing_arr[this.planing_arr.length - 1]['hierarchy_type']) {
  //     parent_node_cd = this.planing_arr[this.planing_arr.length - 1]['hierarchy_type']
  //   } else {
  //     parent_node_cd_flag = true
  //   }
  //   for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
  //     if (this.Obj['node_cd'] == this.existing_heirarchy_nodes[i]['node_cd'] && this.existing_heirarchy_nodes[i]['status'] == 'ACTIVE') {
  //       duplicate_flag = true
  //     }
  //   }
  //   if (parent_node_cd_flag == true) {
  //     Swal.fire('Error', 'You Have Not Selected Node At The Last Level', 'error')
  //   } else if (duplicate_flag == true) {
  //     Swal.fire('Error', 'This Node Code Is Already Exists', 'error')
  //   } else if (!this.Obj['node_type']) {
  //     Swal.fire('Warning...', 'Please Enter All Fields Marked As *', 'warning')
  //   }
  //   else if (!this.Obj['node_cd']) {
  //     Swal.fire('Warning...', 'Please Enter All Fields Marked As *', 'warning')
  //   } else {
  //     this.Obj['parent_node_cd'] = parent_node_cd
  //     this.Obj['b_acct_id'] = this.b_acct_id
  //     this.Obj['create_user_id'] = this.ebillUser.user_id;
  //     this.Obj['is_leaf'] = 0
  //     this.Obj['status'] = 'ACTIVE';
  //     console.log(this.Obj,this.NodeCodeToNodeDesc)

  //     this.Obj['level'] = this.NodeCodeToNodeDesc[parent_node_cd]['level'] + 1;
  //     this.Obj['path'] = this.NodeCodeToNodeDesc[parent_node_cd]['path'] + "|" + this.Obj['node_cd'];
  //     console.log(this.Obj)
  //     this.spinner.show()
  //     var resp = await this.embHierService.insertEmbHierarchyNode(this.Obj);
  //     if (resp['error'] == false) {
  //       this.spinner.hide();
  //       await this.getAllHeirNodeList();
  //       Swal.fire("Success...", "Successfuly Created", 'success');
  //     }
  //     else {
  //       this.spinner.hide();
  //       Swal.fire('Error...', 'Error While Create A New Node..', 'error');
  //     }
  //   this.gotolist()
  //   }
  // }

  // clickUpdate(element, j) {
  //   var ids = element.interface_id.split(',');
  //   var newIds = [];
  //   for (var i = 0; i < ids.length; i++) {
  //     newIds.push(parseInt(ids[i]));
  //   }
  //   this.selectedComp = newIds;
  //   this.role_name = element.role_name;
  //   this.role_id = element.role_id;

  // }
  // async addNewRole() {
  //   this.spinner.show();
  //   var obj = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['interface_id'] = this.selectedComp;
  //   obj['role_name'] = this.role_name;
  //   var resp = await this.userService.addNewRole(obj);
  //   if (resp['error'] == false) {
  //     await this.getAllRoles(this.b_acct_id);
  //     this.spinner.hide();
  //     this.toastr.successToastr('Added Successfully', 'Success!');
  //   } else {
  //     this.spinner.hide();
  //     this.toastr.errorToastr(resp['data'], 'Oops!');
  //   }
  // }


  // async  deleteRole(element, i) {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Delete'
  //   }).then((result) => {
  //     if (result.value) {
  //       this.finaldelete(element, i)
  //     }
  //   })
  // }

  // async finaldelete(element, i) {
  //   var obj=new Object();
  //   obj['b_acct_id']=this.b_acct_id;
  //   obj['role_cd']=element['role_cd']

  //   console.log(obj);
  //   if(element['role_cd']=='AA' || element['role_cd']=='ZA'){
  //     this.toastr.errorToastr('You can not delete this System Role.....', 'Oops!');
  //     return;
  //   }
  //   this.spinner.show();
  //   var resp = await this.userService.deleteUnUsedRole(obj);
  //   if (resp['error'] == false) {
  //     this.allRoles.splice(i, 1);
  //     this.dataSource = new MatTableDataSource(this.allRoles);
  //     this.dataSource.paginator = this.paginator;

  //     this.dataSource.sort = this.sort;
  //     this.spinner.hide();
  //     this.toastr.successToastr('Deleted Successfully', 'Success!');
  //   } else {
  //     this.spinner.hide();
  //     this.toastr.errorToastr(resp['data'], 'Oops!');
  //   }

  // }
  // async updateRole() {
  //   this.spinner.show();
  //   var obj = new Object();
  //   obj['role_id'] = this.role_id;
  //   obj['interface_id'] = this.selectedComp;
  //   obj['role_name'] = this.role_name;
  //   var resp = await this.userService.updateRole(obj);
  //   if (resp['error'] == false) {
  //     await this.getAllRoles(this.b_acct_id);
  //     this.spinner.hide();
  //     this.toastr.successToastr('Updated Successfully', 'Success!');
  //   } else {
  //     this.spinner.hide();
  //     this.toastr.errorToastr(resp['data'], 'Oops!');
  //   }
  // }
}
