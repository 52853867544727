import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../../service/main.service';
import { NgxSpinnerService } from 'ngx-spinner';
// import { DataAccessService } from '../../service/data-access.service';
// import { AllEmpService } from '../../service/all-emp.service';
import { AcrService } from '../../service/acr.service';
import Swal from 'sweetalert2';
import { async } from '@angular/core/testing';
import { MainService as mainserviceEmb } from '../../../emb/service/main.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApprovalUserService } from '../../../portal/service/approval-user.service';
import { Router } from '@angular/router';
// import { LetterEditorComponent } from 'src/app/icms/letter-editor/letter-editor.component';
// import { element } from 'protractor';
declare var $: any
@Component({
  selector: 'app-acr',
  templateUrl: './acr.component.html',
  styleUrls: ['./acr.component.css']
})
export class AcrComponent implements OnInit {
applyFilter1(arg0: any) {
// throw new Error('Method not implemented.');
}


  casier_flag: boolean = false;
  casier_flag_admin: boolean = false
  gairtakniki_flag: boolean = false
  gairtakniki_admin: boolean = false
  officer: boolean = false
  officer_admin: boolean = false
  pending_acr: boolean = false;
  list_flag: boolean = true
  review_screen: boolean = false;
  review_screen_1: boolean = false;
  historyView_flag_h: boolean = false
  // first label employee
  firstLabel: boolean = false
  secondLabel: boolean = false
  constructor(public acrService: AcrService, public mainService: MainService, private mainserviceEmb: mainserviceEmb, private spinner: NgxSpinnerService,
    public ApprovalUserService: ApprovalUserService, private router: Router) { }

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('paginator2', { static: false }) paginator2!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;
  @ViewChild('sortCol3', { static: false }) sortCol3!: MatSort;
  dataSource1: any;
  dataSource2: any
  // displayedColumns1 = ['sr_no', 'emp_name', 'akh_from','akh_to', 'post', 'status', 'action', 'status'];
  displayedColumns2: any = ['sr_no1', 'emp_name1', 'akh_from1', 'akh_to1', 'post1', 'action1'];
  displayedColumns1: any = ['sr_no', 'emp_name', 'akh_from', 'akh_to', 'post', 'status', 'action'];
  datasource: any;

  erpUser: any
  role_cd: any = []
  check_best: any = [{ code: '1', value: 'उत्कृष्ट' }, { code: '2', value: 'अति उत्तम' }, { code: '3', value: 'उत्तम' }, { code: '4', value: 'अच्छा' }, { code: '5', value: 'खराब' }]
  t_f = [{ code: 'YES', value: 'YES' }, { code: 'NO', value: 'NO' }]

  b_acct_id: any;
  user_id: any;
  emp_name: any
  emp_role: any;
  form_type: any;
  // shreni = ['उत्कृष्ट', 'अति उत्तम', 'उत्तम', 'अच्छा', 'खराब']
  // shreniObj;

  doc_type: any;

  anudesh_flag: boolean = false;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    console.log(this.erpUser)
    this.user_id = this.erpUser.user_id
    this.emp_name = this.erpUser.party_name
    this.b_acct_id = this.erpUser.b_acct_id

    this.role_cd = this.erpUser.role_cd
    console.log(this.role_cd)

    // role check
    for (let i = 0; i < this.role_cd.length; i++) {
      if (this.role_cd[i] == "ACR_11") {
        this.emp_role = this.role_cd[i]
        this.form_type = 'form_1'
        this.doc_type = 'ACR'
        this.firstLabel = true;
        await this.getCashierList()

      }
      else if (this.role_cd[i] == "ACR_22") {
        this.emp_role = this.role_cd[i]
        this.form_type = 'form_2'
        this.doc_type = 'ACR_2'
        this.firstLabel = true;
        // this.anudesh_flag=true;
        await this.getCashierList()

      }
      else if (this.role_cd[i] == "ACR_33") {
        this.emp_role = this.role_cd[i]
        this.form_type = 'form_3'
        this.firstLabel = true;
        this.doc_type = 'ACR_3'
        // this.anudesh_flag=true
        await this.getCashierList()

      }
      else if (this.role_cd[i] == "off1" || this.role_cd[i] == "D11" || this.role_cd[i] == "E11") {
        this.emp_role = this.role_cd[i]
        this.form_type = 'form_1'
        this.doc_type = 'ACR'
        this.secondLabel = true;
        // this.pending_acr=true;
        await this.specificACR()

      }
      // else if (this.role_cd[i] == "D11") {
      //   this.emp_role=this.role_cd[i]
      //   this.form_type='form_1'
      //   this.doc_type='ACR'
      //   this.secondLabel=true
      //   await this.specificACR()

      // }
      // else if (this.role_cd[i] == "E11") {
      //   this.emp_role=this.role_cd[i]
      //   this.form_type='form_1'
      //   this.doc_type='ACR'
      //   this.secondLabel=true;
      //   await this.specificACR()

      // }
      else if (this.role_cd[i] == "ACR_22_OFFICER" || this.role_cd[i] == 'ACR_22_OFFICER_2' || this.role_cd[i] == 'ACR_22_OFFICER_3') {
        this.emp_role = this.role_cd[i]
        this.form_type = 'form_2'
        this.doc_type = 'ACR_2'
        this.secondLabel = true;
        // this.anudesh_flag=true
        await this.specificACR()
      }
      // else if(this.role_cd[i]=='ACR_22_OFFICER_2'){
      //   this.emp_role=this.role_cd[i]
      //   this.form_type='form_2'
      //   this.doc_type='ACR_2'
      //   this.secondLabel=true;
      //   // this.anudesh_flag=true
      //   await this.specificACR()

      // }
      // else if(this.role_cd[i]=='ACR_22_OFFICER_3'){
      //   this.emp_role=this.role_cd[i]
      //   this.form_type='form_2'
      //   this.doc_type='ACR_2'
      //   this.secondLabel=true;
      //   // this.anudesh_flag=true;
      //   await this.specificACR()

      // }
      else if (this.role_cd[i] == "ACR_33_OFFICER_1" || this.role_cd[i] == "ACR_33_OFFICER_2" || this.role_cd[i] == "ACR_33_OFFICER_3") {
        this.form_type = 'form_3'
        this.doc_type = 'ACR_3'
        this.secondLabel = true;
        this.emp_role = this.role_cd[i]

        await this.specificACR()
      }
      // else if (this.role_cd[i] == "ACR_33_OFFICER_2" ) {
      //   this.form_type='form_3'
      //   this.doc_type='ACR_3'
      //   this.secondLabel=true;
      //   this.emp_role=this.role_cd[i]
      //   // this.anudesh_flag=true;
      //   await this.specificACR()
      // }
      // else if (this.role_cd[i] == "ACR_33_OFFICER_3") {
      //   this.form_type='form_3'
      //   this.doc_type='ACR_3'
      //   this.secondLabel=true;
      //   this.emp_role=this.role_cd[i]
      //   // this.anudesh_flag=true;
      //   await this.specificACR()
      // }
      else if (this.role_cd[i] == 'AA') {
        this.historyView_flag_h = true;
        await this.historyView()
      }

    }


    // functjion add
    // get data afor list for cashier list

    await this.getNodeCode()

  }

  navigateToNextPage(element:any) {
    console.log('you click --send to approve m', element)
    let dataToSend = {}
    if (this.emp_role == 'off1' || this.emp_role == 'ACR_22_OFFICER' || this.emp_role == 'ACR_33_OFFICER_1') {
      dataToSend = {
        key1: element.work_id,
        key2: element.first_id,
        key3: this.form_type,
        second_id: element.second_id,
        self_id: element.self_id,
        role: this.emp_role,
        action: element.action
      };
    }
    if (this.emp_role == 'D11' || this.emp_role == 'E11' || this.emp_role == 'ACR_22_OFFICER_2' ||
      this.emp_role == 'ACR_22_OFFICER_3' || this.emp_role == 'ACR_33_OFFICER_2' || this.emp_role == 'ACR_33_OFFICER_3') {
      dataToSend = {
        key1: element.work_id,
        key2: element.first_id,
        key3: this.form_type,
        second_id: element.second_id,
        self_id: element.self_id,
        review_id: element.review_id,
        role: this.emp_role,
        action: element.action
      };
    }

    this.router.navigate(['/task'], { state: dataToSend });
  }

  async navigateToNextPage_forReject(data:any) {
    console.log(" this is hte reject data previous  ", data)
    if (data.length == 0) {
      Swal.fire("Warning", 'This Is Template Not Original Form Go To Pending Acr And Then Add/Reject Report', 'warning');
      return;

    }
    let dataToSend = {}
    dataToSend = {
      work_id: data.work_id,
      first_id: data.first_id,
      form_type: this.form_type,
      second_id: data.second_id,
      self_id: data.self_id,
      review_id: data.review_id,
      role: this.emp_role,
      action: 'Reject',
      track_id: data.track_id

    };

    this.router.navigate(['/task'], { state: dataToSend });

  }


  // getting data for node code
  node_code:any;
  async getNodeCode() {
    this.node_code = ""
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.erpUser.user_id
    let resp :any= await this.acrService.getNodecode(obj);
    console.log("this is the node code ---", resp)
    if (resp['error'] == false) {
      this.node_code = resp['data'][0]['node_cd']
      return;
    }
    else {
      Swal.fire('Warning', 'Node Code Not Get', 'warning');
      return;
    }
  }

  // ---------------- save self form --------------------------

  async selfFormSave(sendToSaveData:any) {
    var obj :any=new  Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.user_id;
    obj['emp_name'] = this.emp_name;
    obj['data'] = sendToSaveData
    obj['role'] = this.emp_role
    obj['form_type'] = this.form_type;
    let resp:any = await this.acrService.saveForm1(obj);
    console.log('this is the resp communication ', resp);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Data Save Successfully', 'success');
      await this.getCashierList()
      $('.nav-tabs a[href="#tab-2.1"]').tab('show');

      return;

    }
    else {
      Swal.fire('Error', 'Error in submiting', 'error');
      return;
    }
  }
  // update self form

  async selfFormUpdate(sendToUpdate:any) {
    var obj :any=new  Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.user_id;
    obj['emp_name'] = this.emp_name;
    obj['data'] = sendToUpdate
    obj['role'] = this.emp_role
    obj['form_type'] = this.form_type;
    let resp :any= await this.acrService.update_Form1(obj);
    console.log('this is the resp of update ', resp);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Data Update Successfully', 'success');
      await this.getCashierList()
      $('.nav-tabs a[href="#tab-2.1"]').tab('show');

      return;

    }
    else {
      Swal.fire('Error', 'Error in Updation', 'error');
      return;
    }
  }
  // ----------------- end save self form ----------------------

  // goinf to next tab    $('.nav-tabs a[href="#tab-3"]').tab('show');

  // getting the respective form according the role

  async getTheForm() {
    this.rejectData = {}
    for (let i = 0; i < this.role_cd.length; i++) {
      if (this.role_cd[i] == "ACR_11") {
        this.emp_role = this.role_cd[i]
        this.CasrObj = {}
        this.updateFlag = false
        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "ACR_22") {
        this.emp_role = this.role_cd[i]
        this.gtFormObj = {};
        this.updateFlag = false

        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "ACR_33") {
        this.emp_role = this.role_cd[i]
        this.upOffObj = {}
        this.updateFlag = false
        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "off1") {
        this.emp_role = this.role_cd[i]
        this.CasrObjadmin = {};
        this.updateFlag = false
        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "D11") {
        this.emp_role = this.role_cd[i]
        this.reviewObj = {};
        this.updateFlag = false
        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "E11") {
        this.emp_role = this.role_cd[i]
        this.reviewObj = {};
        this.updateFlag = false
        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "ACR_22_OFFICER") {
        this.emp_role = this.role_cd[i]
        this.updateFlag = false
        this.gtadminObj = {}
        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "ACR_22_OFFICER_2") {
        this.emp_role = this.role_cd[i]
        this.updateFlag = false
        this.reviewObj = {}
        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "ACR_22_OFFICER_3") {
        this.emp_role = this.role_cd[i]
        this.updateFlag = false
        this.reviewObj = {}
        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "ACR_33_OFFICER_1") {
        this.emp_role = this.role_cd[i]
        this.updateFlag = false
        this.upOffAdmin = {}
        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "ACR_33_OFFICER_2") {
        this.emp_role = this.role_cd[i]
        this.updateFlag = false
        this.reviewObj = {}
        this.getTheACRepo()

      }
      else if (this.role_cd[i] == "ACR_33_OFFICER_3") {
        this.emp_role = this.role_cd[i]
        this.updateFlag = false
        this.reviewObj = {}
        this.getTheACRepo()

      }

    }
  }


  async getTheACRepo() {
    this.pending_acr = false;

    if (this.emp_role == 'ACR_11') {
      this.casier_flag = true
      this.casier_flag_admin = false
      this.list_flag = false
      this.review_screen = false
      this.review_screen_1 = false
    }
    else if (this.emp_role == 'ACR_22') {

      this.gairtakniki_flag = true
      this.gairtakniki_admin = false
      this.list_flag = false
      this.review_screen = false
      this.review_screen_1 = false
      this.anudesh_flag = true
    }
    else if (this.emp_role == "ACR_33") {

      this.officer = true
      this.officer_admin = false
      this.list_flag = false
      this.review_screen = false
      this.review_screen_1 = false
      this.anudesh_flag = true
    }
    else if (this.emp_role == "off1") {

      this.casier_flag_admin = true
      this.list_flag = false;
      this.review_screen = false
      this.review_screen_1 = false
    }
    else if (this.emp_role == "D11") {

      this.list_flag = false;
      this.review_screen = true;
      this.review_screen_1 = false;
    }
    else if (this.emp_role == 'E11') {

      this.list_flag = false;
      this.review_screen = true;
    }
    else if (this.emp_role == "ACR_22_OFFICER") {

      this.gairtakniki_admin = true
      this.officer_admin = false
      this.list_flag = false
      this.anudesh_flag = true
    }
    else if (this.emp_role == "ACR_22_OFFICER_2" || this.emp_role == "ACR_22_OFFICER_3"
      || this.emp_role == "ACR_33_OFFICER_2" || this.emp_role == "ACR_33_OFFICER_3") {

      this.list_flag = false
      this.review_screen = true;
      this.anudesh_flag = true
    }
    else if (this.emp_role == "ACR_33_OFFICER_1") {
      this.officer_admin = true
      this.list_flag = false
      this.anudesh_flag = true
    }



    // previous

    // switch(this.emp_role)
    // {
    //   case 'ACR_11':


    //       this.casier_flag = true
    //       this.officer = false
    //       this.gairtakniki_flag = false
    //       this.casier_flag_admin = false
    //       this.gairtakniki_admin = false
    //       this.officer_admin = false
    //       this.list_flag=false
    //       this.review_screen=false
    //       this.review_screen_1=false
    //       // this.anudesh_flag=true
    //       break;
    //   case 'ACR_22':

    //       this.casier_flag = false
    //       this.officer = false
    //       this.gairtakniki_flag = true
    //       this.casier_flag_admin = false
    //       this.gairtakniki_admin = false
    //       this.officer_admin = false
    //       this.list_flag=false
    //       this.review_screen=false
    //       this.review_screen_1=false
    //       this.anudesh_flag=true
    //       break;
    //   case "ACR_33":

    //       // this.casier_flag = false
    //       // this.updateFlag=false
    //       this.officer = true
    //       // this.gairtakniki_flag = false
    //       // this.casier_flag_admin = false
    //       // this.gairtakniki_admin = false
    //       this.officer_admin = false
    //       this.list_flag=false
    //       this.review_screen=false
    //       this.review_screen_1=false
    //       this.anudesh_flag=true
    //       break;
    //   case "off1":

    //     //  this.officer = false
    //       // this.gairtakniki_flag = false
    //       // this.updateFlag=false
    //       this.casier_flag_admin = true
    //       // this.gairtakniki_admin = false
    //       // this.officer_admin = false
    //       this.list_flag=false;
    //       this.review_screen=false
    //       this.review_screen_1=false
    //       break;
    //   case "D11":

    //       // this.officer = false
    //       // this.gairtakniki_flag = false
    //       // this.gairtakniki_admin = false
    //       // this.officer_admin = false
    //       this.list_flag=false;
    //       // this.updateFlag=false
    //       this.review_screen=true;
    //       this.review_screen_1=false;
    //       break;
    //   case "E11":

    //       // this.officer = false
    //       // this.gairtakniki_flag = false
    //       // this.gairtakniki_admin = false
    //       // this.officer_admin = false
    //       this.list_flag=false;
    //       // this.updateFlag=false
    //       this.review_screen=true;
    //       break;
    //   case "ACR_22_OFFICER":

    //     //  this.casier_flag = false
    //     //  this.officer = false
    //     //  this.gairtakniki_flag = false
    //     //  this.casier_flag_admin = false
    //      this.gairtakniki_admin = true
    //      this.officer_admin = false
    //      this.list_flag=false
    //      this.anudesh_flag=true
    //      break;
    //   case "ACR_22_OFFICER_2":
    //     this.review_screen=true;
    //     //  this.gairtakniki_admin = false
    //     //  this.officer_admin = false
    //      this.list_flag=false
    //      this.anudesh_flag=true
    //      break;
    //   case "ACR_22_OFFICER_3":
    //     this.review_screen=true;
    //     //  this.gairtakniki_admin = false

    //     //  this.officer_admin = false
    //      this.list_flag=false
    //      this.anudesh_flag=true
    //      break;
    //  case "ACR_33_OFFICER_1":

    //     //  this.casier_flag = false
    //     //  this.officer = false
    //     //  this.gairtakniki_flag = false/
    //     //  this.casier_flag_admin = false
    //     //  this.gairtakniki_admin = false
    //     //  this.updateFlag=false
    //      this.officer_admin = true
    //      this.list_flag=false
    //      this.anudesh_flag=true
    //      break;
    //  case "ACR_33_OFFICER_2":

    //     //  this.casier_flag = false/
    //     //  this.officer = false
    //     //  this.gairtakniki_flag = false
    //     //  this.casier_flag_admin = false
    //     //  this.gairtakniki_admin = false
    //     //  this.updateFlag=false
    //     //  this.officer_admin = false
    //      this.review_screen=true;
    //      this.list_flag=false
    //      this.anudesh_flag=true
    //      break;
    //  case "ACR_33_OFFICER_3":

    //     //  this.casier_flag = false
    //     //  this.officer = false
    //     //  this.officer_admin = false
    //      this.review_screen=true;
    //       this.list_flag=false
    //       this.anudesh_flag=true

    //      break;


    // }




    // for (let i = 0; i < this.role_cd.length; i++) {
    //   if (this.role_cd[i] == "ACR_11") {



    //   }
    //   else if (this.role_cd[i] == "ACR_22") {
    //     this.emp_role=this.role_cd[i]

    //   }
    //   else if (this.role_cd[i] == "ACR_33") {
    //     this.emp_role=this.role_cd[i]

    //   }
    //   else if (this.role_cd[i] == "off1") {
    //     this.emp_role=this.role_cd[i]
    //     // this.casier_flag = false

    //   }
    //   else if (this.role_cd[i] == "D11") {
    //     this.emp_role=this.role_cd[i]


    //   }
    //   else if (this.role_cd[i] == "E11") {
    //     this.emp_role=this.role_cd[i]

    //     // this.review_screen_1=false;

    //   }
    //   else if (this.role_cd[i] == "ACR_22_OFFICER") {

    //   }
    //   else if (this.role_cd[i] == "ACR_33_OFFICER") {
    //     this.emp_role=this.role_cd[i]

    //   }
    //   // else
    //   // {
    //   //   Swal.fire('Info','YOur Role Is Not Abel To Open Form','warning');
    //   //   return
    //   // }
    // }
  }



  // -------------------------  start  getting the pending acr task /    List For Send ACR To Upper officer before add report

  async getPendingAcr() {
    this.list_flag = false;
    this.casier_flag = false
    this.officer = false
    this.gairtakniki_flag = false
    this.casier_flag_admin = false
    this.gairtakniki_admin = false
    this.officer_admin = false
    this.pending_acr = true;
    this.review_screen = false;
    this.review_screen_1 = false;
    this.anudesh_flag = false;
    this.updateFlag = false
    console.log('get pengif acr');

    if (this.emp_role == 'off1' || this.emp_role == 'ACR_22_OFFICER' ||
      this.emp_role == 'ACR_22_OFFICER_2' || 'ACR_33_OFFICER_1' ||
      this.emp_role == 'ACR_33_OFFICER_2') {
      await this.getPendingCase1Acr();
    }
    else if (this.emp_role == 'D11') {
      await this.getPendingCase2Acr();
    }
    else if (this.emp_role == 'E11' || this.emp_role == 'ACR_22_OFFICER_3' || this.emp_role == 'ACR_33_OFFICER_3') {
      await this.getPendingCase3Acr();
    }


    // previous

    // switch(this.emp_role)
    // {

    //     // case 'off1':
    //   //  await this.getPendingCase1Acr();
    //     // break;
    //     // case 'D11':
    //     //   await this.getPendingCase2Acr();;
    //     //   break;
    //     case 'E11' :
    //       await this.getPendingCase3Acr();
    //       break;
    //     // case 'ACR_22_OFFICER':
    //       // await this.getPendingCase1Acr();
    //       // break;
    //     // case 'ACR_22_OFFICER_2':
    //       // await this.getPendingCase1Acr();
    //       // break;
    //     case 'ACR_22_OFFICER_3':
    //       await this.getPendingCase3Acr();
    //       break;
    //     // case 'ACR_33_OFFICER_1':
    //       // await this.getPendingCase1Acr();
    //       // break;
    //     // case 'ACR_33_OFFICER_2':
    //       // await this.getPendingCase1Acr();
    //       // break;
    //     case 'ACR_33_OFFICER_3':
    //       await this.getPendingCase3Acr();
    //       break;
    // }

  }



  async getPendingCase1Acr() {
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id // this.erpuser.user_id
    let resp :any= await this.acrService.getPendingCas1Acr(obj);
    console.log(" this is the get pending task work ", resp);
    console.log(resp);
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        Swal.fire('Info', 'No ACR in Pending', 'info');
        // return;
      }

      this.dataSource2 = new MatTableDataSource(resp['data']);
      this.dataSource2.paginator = this.paginator2
      this.dataSource2.sort = this.sortCol2;
      //  console.log("datasource",resp);

    }
    else {
      Swal.fire('Error', 'Something Went Worng', 'error');
      return;

    }
  }

  async getPendingCase2Acr() {
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id;
    let resp :any= await this.acrService.getCase2Data(obj);
    console.log(" this is the case 2 data resp--", resp)
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        Swal.fire('Info', 'No ACR in Pending', 'info');

      }


      this.dataSource2 = new MatTableDataSource(resp['data']);
      this.dataSource2.paginator = this.paginator2
      this.dataSource2.sort = this.sortCol2;
    }
    else {
      Swal.fire('Error', 'Something Went Worng', 'error');
      return;

    }


  }
  async getPendingCase3Acr() {
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id;
    obj['form_type'] = this.form_type;
    let resp :any= await this.acrService.getCase3Data(obj);
    console.log(" this is the case 3  data resp--", resp)
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        Swal.fire('Info', 'No ACR in Pending', 'info');
        // return;
      }

      this.dataSource2 = new MatTableDataSource(resp['data']);
      this.dataSource2.paginator = this.paginator2
      this.dataSource2.sort = this.sortCol2;
    }
    else {
      Swal.fire('Error', 'Something Went Worng', 'error');
      return;

    }


  }






  //------------ end of getting pending acr /    List For Send ACR To Upper officer


  // form1 cashir acr list

  async getCashierList() {
    this.list_flag = true;
    this.casier_flag = false
    this.officer = false;
    this.gairtakniki_flag = false;
    this.casier_flag_admin = false;
    this.gairtakniki_admin = false;
    this.officer_admin = false;
    this.anudesh_flag = false
    this.pending_acr = false;

    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id;
    obj['emp_role'] = this.emp_role;
    let resp :any= await this.acrService.gettingCashierList(obj);
    console.log(resp);
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        Swal.fire('Info', 'No Data To Show', 'info');
        return;
      }
      this.dataSource1 = new MatTableDataSource(resp['data']);
      this.dataSource1.paginator = this.paginator1
      this.dataSource1.sort = this.sortCol2;
      console.log("datasource", resp);

    }
    else {
      Swal.fire('Error', 'Something Went Worng', 'error');
      return;

    }

  }

  // ---------------- Start List For Send ACR To Upper officer

  async specificACR() {

    if (this.emp_role == 'off1') {
      await this.getSendAcr()
    }
    else if (this.emp_role == 'ACR_22_OFFICER' || this.emp_role == 'ACR_33_OFFICER_1') {
      this.anudesh_flag = false;
      await this.getSendAcr_Form2();
    }
    else if (this.emp_role == 'D11' || this.emp_role == 'E11' || this.emp_role == 'ACR_22_OFFICER_2'
      || this.emp_role == 'ACR_22_OFFICER_3' || this.emp_role == 'ACR_33_OFFICER_2' || this.emp_role == 'ACR_33_OFFICER_3') {
      this.anudesh_flag = false;
      await this.getSendAcr2();
    }

    // prevoius

    // switch(this.emp_role)
    // {
    //   case 'off1' :

    //    break;
    //   case 'D11':

    //     break;
    // }

  }




  async getSendAcr() {

    this.list_flag = true;

    this.casier_flag = false
    this.officer = false
    this.gairtakniki_flag = false
    this.casier_flag_admin = false
    this.gairtakniki_admin = false
    this.officer_admin = false
    this.pending_acr = false;

    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id;
    let resp :any= await this.acrService.getSendAcrOff1(obj);
    console.log("this is the resp of upper send ", resp);
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        Swal.fire('Info', 'No Data At This Time', 'info');
        // return;
      }

      console.log(" this is the getsend acr list button click", this.form_type)
      this.dataSource1 = new MatTableDataSource(resp['data']);
      this.dataSource1.paginator = this.paginator1
      this.dataSource1.sort = this.sortCol2;
    }
    else {
      Swal.fire('Error', 'Something Went Worng', 'error');
      return;

    }
    // console.log("datasource",resp);
  }

  // async getSendAcr3()
  // {
  //   this.list_flag=true;

  //   this.casier_flag = false
  //   this.officer = false
  //   this.gairtakniki_flag = false
  //   this.casier_flag_admin = false
  //   this.gairtakniki_admin = false
  //   this.officer_admin = false
  // this.pending_acr=false;
  // this.review_screen=false;
  // this.review_screen_1=false;

  // let obj=new Object();
  // obj['b_acct_id']=this.b_acct_id;
  // obj['user_id']=this.user_id;
  // let resp=await this.acrService.getsendscr3(obj)
  // console.log(" D11/E11 role send to upper officer ", resp);
  // this.dataSource1 = new MatTableDataSource(resp['data']);
  // this.dataSource1.paginator = this.paginator1
  // this.dataSource1.sort = this.sortCol2;
  // }
  async getSendAcr2() {
    this.list_flag = true;
    this.casier_flag = false
    this.officer = false
    this.gairtakniki_flag = false
    this.casier_flag_admin = false
    this.gairtakniki_admin = false
    this.officer_admin = false
    this.pending_acr = false;
    this.review_screen = false;
    this.review_screen_1 = false;

    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id;
    let resp :any= await this.acrService.getsendscr2(obj)
    console.log(" D11/E11 role send to upper officer ", resp);
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        Swal.fire('Info', 'No Data To Show', 'info');
        // return;
      }

      this.dataSource1 = new MatTableDataSource(resp['data']);
      this.dataSource1.paginator = this.paginator1
      this.dataSource1.sort = this.sortCol2;
    }
    else {
      Swal.fire('Error', 'Something Went Worng', 'error');
      return;

    }
  }

  async getSendAcr_Form2() {
    this.list_flag = true; this.gairtakniki_flag = false
    this.gairtakniki_admin = false
    this.officer_admin = false
    this.officer = false;
    this.pending_acr = false;
    this.review_screen = false;
    this.review_screen_1 = false;
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.user_id;
    let resp:any = await this.acrService.getsendacr_off1(obj)
    console.log(" ACR_22_OFFICER/ACR_33_OFFICER_1 role send to upper officer ", resp);
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        Swal.fire('Info', 'No Data To Show', 'info');
        // return;
      }

      this.dataSource1 = new MatTableDataSource(resp['data']);
      this.dataSource1.paginator = this.paginator1
      this.dataSource1.sort = this.sortCol2;
    }
    else {
      Swal.fire('Error', 'Something Went Worng', 'error');
      return;

    }

  }


  //------------------ end of  List For Send ACR To Upper officer

  // m_avinash (code for open page accorging to role)
  positive: boolean = false
  negative: boolean = false;

  // --------------------------- Update Button Start -------------------
  updateFlag: boolean = false;
  async updateRecord(element:any) {
    console.log("Update Button is clicked", element)

    this.updateFlag = true
    await this.addReportCondition(element)

  }



  // -------------------------- Update Button End -----------------




  //  Self Data save -------------------------------------


  CasrObj:any = {}
  async cashierSelf() {
    this.CasrObj['acr_name'] = ''
    await this.self_Save(this.CasrObj)
  }




  async self_Save(data:any) {
    if (this.emp_role == 'ACR_11') {
      console.log("self save data cashier ", data)

      await this.selfFormSave(data)
    }
    else if (this.emp_role == 'ACR_22' || this.emp_role == 'ACR_33') {
      await this.selfFormSave(data)
    }

  }



  async self_Update() {
    if (this.emp_role == 'ACR_11') {
      this.CasrObj['acr_name'] = ''
      console.log(this.CasrObj)
    }
    await this.selfFormUpdate(this.CasrObj)

  }



  // self save data end ---------------------

  //------------- Main data (first officer Save Data) All form
  CasrObjadmin :any= {}
  async casadmin_admin_save() {
    console.log(" this is the check box positive", this.positive, "this is negative ", this.negative)
    if (this.positive == true) {
      this.CasrObjadmin['integrity_type'] = 'positive'

      this.CasrObjadmin['inti_name'] = this.CasrObjadmin['p_name']
      this.CasrObjadmin['inti_text'] = 'no'
      this.CasrObjadmin['inti_sign'] = this.CasrObjadmin['p_sign']
    }
    else if (this.negative == true) {
      this.CasrObjadmin['integrity_type'] = 'negative'
      this.CasrObjadmin['inti_name'] = this.CasrObjadmin['n_name']
      this.CasrObjadmin['inti_text'] = this.CasrObjadmin['n_text']
      this.CasrObjadmin['inti_sign'] = this.CasrObjadmin['n_sign']
    }
    console.log("this is the obj-- ", this.CasrObjadmin)
    this.CasrObjadmin['b_acct_id'] = this.b_acct_id
    this.CasrObjadmin['status'] = 'Report Added'
    let resp:any = await this.acrService.addRecord(this.CasrObjadmin);

    console.log("Resp of insert report --", resp);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Record Added Successfully', 'success');



      $('.nav-tabs a[href="#tab-2"]').tab('show')
      //  this.casier_flag_admin=false
      await this.specificACR()
      //  await this.getTheACRepo()

      // this.list_flag=tru/e
      return;
    }
    else {
      Swal.fire('Error', "Some Error Occured Please Try Again", 'error');
      return;

    }
  }

  async casadmin_admin_Update() {
    console.log(" this is the check box positive", this.positive, "this is negative ", this.negative)
    if (this.positive == true) {
      this.CasrObjadmin['integrity_type'] = 'positive'

      this.CasrObjadmin['inti_name'] = this.CasrObjadmin['p_name']
      this.CasrObjadmin['inti_text'] = 'no'
      this.CasrObjadmin['inti_sign'] = this.CasrObjadmin['p_sign']
    }
    else if (this.negative == true) {
      this.CasrObjadmin['integrity_type'] = 'negative'
      this.CasrObjadmin['inti_name'] = this.CasrObjadmin['n_name']
      this.CasrObjadmin['inti_text'] = this.CasrObjadmin['n_text']
      this.CasrObjadmin['inti_sign'] = this.CasrObjadmin['n_sign']
    }
    this.CasrObjadmin['b_acct_id'] = this.b_acct_id

    console.log("this is the obj-- ", this.CasrObjadmin)


    let resp:any = await this.acrService.addRecord_Update(this.CasrObjadmin);

    console.log("Resp of insert report --", resp);

    if (resp['error'] == false) {
      Swal.fire('Success', 'Record Update Successfully', 'success');



      $('.nav-tabs a[href="#tab-2"]').tab('show')
      //  this.casier_flag_admin=false
      await this.specificACR()
      //  await this.getTheACRepo()

      // this.list_flag=tru/e
      return;
    }
    else {
      Swal.fire('Error', "Some Error Occured Please Try Again", 'error');
      return;

    }
  }

  gtadminObj:any = {}
  async save_GT_officer1() {
    console.log(" this is the check box positive", this.positive, "this is negative ", this.negative)
    if (this.positive == true) {
      this.gtadminObj['integrity_type'] = 'positive'

      this.gtadminObj['inti_name'] = this.gtadminObj['p_name']
      this.gtadminObj['inti_text'] = 'no'
      this.gtadminObj['inti_sign'] = this.gtadminObj['p_sign']
    }
    else if (this.negative == true) {
      this.gtadminObj['integrity_type'] = 'negative'
      this.gtadminObj['inti_name'] = this.gtadminObj['n_name']
      this.gtadminObj['inti_text'] = this.gtadminObj['n_text']
      this.gtadminObj['inti_sign'] = this.gtadminObj['n_sign']
    }
    console.log("this is the obj-- ", this.gtadminObj)
    this.gtadminObj['b_acct_id'] = this.b_acct_id
    this.gtadminObj['status'] = 'Report Added'
    console.log("Officer save button click ", this.gtadminObj)
    let resp:any = await this.acrService.form2OfficerReport(this.gtadminObj);
    console.log(" this is the second form resp ", resp);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Record Update Successfully', 'success');

      $('.nav-tabs a[href="#tab-2"]').tab('show')

      await this.specificACR()

      return;
    }
    else {
      Swal.fire('Error', "Some Error Occured Please Try Again", 'error');
      return;

    }


  }

  async save_GT_officer1_Update() {
    if (this.positive == true) {
      this.gtadminObj['integrity_type'] = 'positive'

      this.gtadminObj['inti_name'] = this.gtadminObj['p_name']
      this.gtadminObj['inti_text'] = 'no'
      this.gtadminObj['inti_sign'] = this.gtadminObj['p_sign']
    }
    else if (this.negative == true) {
      this.gtadminObj['integrity_type'] = 'negative'
      this.gtadminObj['inti_name'] = this.gtadminObj['n_name']
      this.gtadminObj['inti_text'] = this.gtadminObj['n_text']
      this.gtadminObj['inti_sign'] = this.gtadminObj['n_sign']
    }


    this.gtadminObj['b_acct_id'] = this.b_acct_id
    console.log("update button click ", this.gtadminObj);
    let resp:any = await this.acrService.form2OfficerReport_update(this.gtadminObj);
    console.log(" resp of update ", resp);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Record Update Successfully', 'success');
      $('.nav-tabs a[href="#tab-2"]').tab('show')

      await this.specificACR()

      return;
    }
    else {
      Swal.fire('Error', "Some Error Occured Please Try Again", 'error');
      return;

    }
  }

  upOffAdmin :any= {}
  async save_UpOff_officer1() {
    console.log(" this is the check box positive", this.positive, "this is negative ", this.negative)
    if (this.positive == true) {
      this.upOffAdmin['integrity_type'] = 'positive'

      this.upOffAdmin['inti_name'] = this.upOffAdmin['p_name']
      this.upOffAdmin['inti_text'] = 'no'
      this.upOffAdmin['inti_sign'] = this.upOffAdmin['p_sign']
    }
    else if (this.negative == true) {
      this.upOffAdmin['integrity_type'] = 'negative'
      this.upOffAdmin['inti_name'] = this.upOffAdmin['n_name']
      this.upOffAdmin['inti_text'] = this.upOffAdmin['n_text']
      this.upOffAdmin['inti_sign'] = this.upOffAdmin['n_sign']
    }
    console.log("this is the obj-- ", this.upOffAdmin)
    this.upOffAdmin['b_acct_id'] = this.b_acct_id
    this.upOffAdmin['status'] = 'Report Added'
    console.log("Officer save button click ", this.upOffAdmin)
    let resp:any = await this.acrService.form2OfficerReport(this.upOffAdmin);
    console.log(" this is the second form resp ", resp);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Record Update Successfully', 'success');

      $('.nav-tabs a[href="#tab-2"]').tab('show')

      await this.specificACR()

      return;
    }
    else {
      Swal.fire('Error', "Some Error Occured Please Try Again", 'error');
      return;
    }

  }
  async update_UpOff_officer1() {
    console.log(" this is the check box positive", this.positive, "this is negative ", this.negative)
    if (this.positive == true) {
      this.upOffAdmin['integrity_type'] = 'positive'

      this.upOffAdmin['inti_name'] = this.upOffAdmin['p_name']
      this.upOffAdmin['inti_text'] = 'no'
      this.upOffAdmin['inti_sign'] = this.upOffAdmin['p_sign']
    }
    else if (this.negative == true) {
      this.upOffAdmin['integrity_type'] = 'negative'
      this.upOffAdmin['inti_name'] = this.upOffAdmin['n_name']
      this.upOffAdmin['inti_text'] = this.upOffAdmin['n_text']
      this.upOffAdmin['inti_sign'] = this.upOffAdmin['n_sign']
    }
    console.log("this is the obj-- ", this.upOffAdmin)
    this.upOffAdmin['b_acct_id'] = this.b_acct_id
    this.upOffAdmin['status'] = 'Report Added'
    console.log("Officer save button click ", this.upOffAdmin)
    let resp :any= await this.acrService.form2OfficerReport_update(this.upOffAdmin);
    console.log(" this is the second form resp ", resp);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Record Update Successfully', 'success');

      $('.nav-tabs a[href="#tab-2"]').tab('show')

      await this.specificACR()

      return;
    }
    else {
      Swal.fire('Error', "Some Error Occured Please Try Again", 'error');
      return;
    }

  }



  //------------- Main data (first officer) End



  // save  the task in acr track

  async acrTrackInsert(obb:any) {
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['work_id'] = obb.doc_local_no // its same work_id
    obj['user_id_from'] = obb.forwarded_by
    obj['name_from'] = this.emp_name
    obj['user_id_to'] = obb.user_id
    obj['name_to'] = obb.user_name
    obj['direction'] = 'forward';
    obj['flag'] = 1;
    obj['form_no'] = this.form_type;
    obj['self_id'] = this.approvalObjData['self_id']
    let resp :any= await this.acrService.insertAcrTrack(obj);
    console.log(" this is the  acr Track Insert ", resp);

  }

  // add Report function
  // rejectData=[];

  rejectData = {}
  casierDisabled: boolean = false;
  cashierAdminDisabled: boolean = false;
  gtDisabled: boolean = false;
  gtAdminDisabled: boolean = false;
  async addReportCondition(element:any) {
    // this.rejectData= element
    // element={action:'Reject'}
    this.rejectData = Object.assign({}, element)

    console.log(" this is the reject data---", this.rejectData)
    if (this.emp_role == 'ACR_11') {
      this.casierDisabled = false;
      this.CasrObj['work_id'] = element.work_id
      await this.addDataInCasherForm(element)
    }

    if (this.emp_role == 'off1' || this.emp_role == 'ACR_22_OFFICER' || this.emp_role == 'ACR_33_OFFICER_1') {
      this.CasrObjadmin['first_id'] = element.first_id
      this.gtadminObj['second_id'] = element.second_id
      this.upOffAdmin['second_id'] = element.second_id
      await this.addReport(element);
      return;
    }
    else if (this.emp_role == 'D11' || this.emp_role == 'E11') {

      await this.addRepoerCase2(element)
      return;
    }

    if (this.emp_role == 'ACR_22') {
      this.gtFormObj['work_id'] = element.work_id
      await this.addDataInGtForm(element)
      this.anudesh_flag = true
    }
    if (this.emp_role == 'ACR_33') {
      this.upOffObj['work_id'] = element.work_id

      await this.addDataInUpOff(element)
      this.anudesh_flag = true
    }

    if (this.emp_role == 'ACR_22_OFFICER_2' || this.emp_role == 'ACR_22_OFFICER_3') {
      await this.addDataIn_Form2(element)
    }
    if (this.emp_role == 'ACR_33_OFFICER_2' || this.emp_role == 'ACR_33_OFFICER_3') {
      await this.add_DataIn_Form3(element);
      return;
    }



  }



  async addReport(element:any) {

    console.log("Add repory button click", element);
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;

    obj['form_no'] = element.form_no;
    obj['work_id'] = element.work_id
    obj['user_id'] = element.user_id_from;

    if (this.emp_role == 'off1') {
      obj['first_id'] = element.first_id
      obj['second_id'] = null
    }
    else {
      obj['first_id'] = null
      obj['second_id'] = element.second_id
    }
    // obj['flag']=element.flag;
    this.pending_acr = false
    console.log('obj to find previous task', obj);
    let resp :any= await this.acrService.getForm(obj)
    console.log("get the form data ", resp);
    if (resp['error'] == false) {
      // let formData= resp['data'][0][0];
      let formData;

      if (element.form_no == 'form_1') {

        if (element.first_id == null) {
          formData = resp['data'][0]
        }
        else {
          formData = resp['data'][0][0];
        }
        this.CasrObjadmin['track_id'] = element.track_id
        this.casierDisabled = true;
        await this.addDataInCasherForm(formData)

        if (element.first_id != null) {
          this.CasrObjadmin['track_id'] = element.track_id
          this.cashierAdminDisabled = false;
          await this.addData_cash_adm_form(resp['data'][1][0])
        }

        $('.nav-tabs a[href="#tab-1"]').tab('show')
        await this.getTheACRepo()
      }

      if (element.form_no == 'form_2') {
        this.gtadminObj['track_id'] = element.track_id


        if (element.second_id == null) {
          formData = resp['data'][0]
        }
        else {

          formData = resp['data'][0][0];
        }

        //  this.gtadminObj['track_id']=element.track_id

        this.gtDisabled = true;
        await this.addDataInGtForm(formData)

        if (element.second_id != null) {

          // this.gtadminObj['track_id']=element.track_id
          this.gtAdminDisabled = false;
          //  this.gtadminObj={}
          await this.addData_form2_off1(resp['data'][1][0])
        }

        $('.nav-tabs a[href="#tab-1"]').tab('show')
        await this.getTheACRepo()
      }
      if (element.form_no == 'form_3') {

        this.upOffAdmin['track_id'] = element.track_id

        if (element.second_id == null) {
          formData = resp['data'][0]
        }
        else {
          formData = resp['data'][0][0];
        }

        //  this.gtadminObj['track_id']=element.track_id

        //  this.upofficedisable=true;
        this.upoffDisable = true;
        await this.addDataInUpOff(formData)

        if (element.second_id != null) {

          // this.gtadminObj['track_id']=element.track_id
          this.upoffAdminDisable = false
          this.upOffAdmin['second_id'] = element.second_id
          await this.addData_form3_off2(resp['data'][1][0])
        }

        $('.nav-tabs a[href="#tab-1"]').tab('show')
        await this.getTheACRepo()
      }
    }


  }

  // add data in form 1
  async addRepoerCase2(element:any) {
    console.log('add report case case2 button click ', element)
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['first_id'] = element.first_id;
    let resp :any= await this.acrService.searchFirstAcr(obj);
    if (resp['error'] == false) {
      this.casierDisabled = true;
      await this.addDataInCasherForm(resp['self_form'][0])
      this.cashierAdminDisabled = true
      await this.addData_cash_adm_form(resp['first_acr'][0])
      if (this.emp_role == 'D11') {
        await this.addData_review_form_Update(element);
      }
      if (this.emp_role == 'E11') {
        let data = []
        data.push(element)
        if (this.updateFlag == true) {
          obj['self_id'] = element.self_id;
          obj['id'] = element.review_id
          let resp :any= await this.acrService.getPreviousReview(obj)
          console.log("this is the resp of ", resp);
          await this.addData_review_form_Update(element)
          await this.addData_review_form(resp['data']);
        }
        else {
          // await this.addData_review_form(data); // previous
          //new
          obj['self_id'] = element.self_id;
          obj['id'] = element.review_id
          let resp :any= await this.acrService.getPreviousReview(obj)
          await this.addData_review_form(resp['data']);
          // new end
        }

      }
      this.reviewObj['first_id'] = resp['first_acr'][0]['first_id']
      this.reviewObj['self_id'] = resp['first_acr'][0]['self_id']
      this.reviewObj['track_id'] = element.track_id

    }


    console.log(resp);
    $('.nav-tabs a[href="#tab-1"]').tab('show')
    await this.getTheACRepo()
    console.log(this.review_screen_1_data)
  }


  async addDataIn_Form2(element:any) {
    console.log('add report case case3 button click ', element)
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['second_id'] = element.second_id;
    let resp :any= await this.acrService.searchSecondAcr(obj);
    if (resp['error'] == false) {
      this.gtDisabled = true;
      await this.addDataInGtForm(resp['self_form'][0])
      this.gtAdminDisabled = true;
      this.gtadminObj = {}
      await this.addData_form2_off1(resp['second_acr'][0])

      if (this.emp_role == 'ACR_22_OFFICER_2') {
        await this.addData_review_form_Update(element);
      }
      if (this.emp_role == 'ACR_22_OFFICER_3') {
        let data = []
        data.push(element)
        obj['self_id'] = element.self_id;
        obj['id'] = element.review_id
        let resp :any= await this.acrService.getPreviousReview(obj)
        console.log("this is the resp of ", resp);
        if (this.updateFlag == true) {

          await this.addData_review_form_Update(element)
          await this.addData_review_form(resp['data']);
        }
        else {

          // await this.addData_review_form(data);  // prevoius
          // new

          await this.addData_review_form(resp['data']);
          //new end
        }

      }


      this.reviewObj['second_id'] = resp['second_acr'][0]['second_id']
      this.reviewObj['self_id'] = resp['second_acr'][0]['self_id']
      this.reviewObj['track_id'] = element.track_id

    }


    console.log(resp);
    $('.nav-tabs a[href="#tab-1"]').tab('show')
    await this.getTheACRepo()
    console.log(this.review_screen_1_data)
  }

  async add_DataIn_Form3(element:any) {
    console.log('add report case case3 button click ', element)
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['second_id'] = element.second_id;
    let resp :any= await this.acrService.searchSecondAcr(obj);
    if (resp['error'] == false) {
      this.upoffDisable = true;
      await this.addDataInUpOff(resp['self_form'][0])
      this.upoffAdminDisable = true;

      this.upOffAdmin['second_id'] = element.second_id
      await this.addData_form3_off2(resp['second_acr'][0])

      if (this.emp_role == 'ACR_33_OFFICER_2') {
        await this.addData_review_form_Update(element);
      }
      if (this.emp_role == 'ACR_33_OFFICER_3') {
        let data = []
        data.push(element)
        obj['self_id'] = element.self_id;
        obj['id'] = element.review_id
        let resp :any= await this.acrService.getPreviousReview(obj)
        if (this.updateFlag == true) {

          console.log("this is the resp of ", resp);
          await this.addData_review_form_Update(element)
          await this.addData_review_form(resp['data']);
        }
        else {
          // await this.addData_review_form(data); // previous
          // new
          await this.addData_review_form(resp['data'])
          // new end

        }

      }


      this.reviewObj['second_id'] = resp['second_acr'][0]['second_id']
      this.reviewObj['self_id'] = resp['second_acr'][0]['self_id']
      this.reviewObj['track_id'] = element.track_id

    }


    console.log(resp);
    $('.nav-tabs a[href="#tab-1"]').tab('show')
    await this.getTheACRepo()
    console.log(this.review_screen_1_data)
  }


  async checkCheckbox() {
    // console.log("obj is --",obj)
    var checkbox1:any = document.getElementById("myCheckbox1");
    var checkbox2:any = document.getElementById("myCheckbox2")

    console.log("this is check box 1", checkbox1['checked'], "this is check box 2", checkbox2['checked']);

    if (checkbox1['checked'] == true && checkbox2['checked'] == true) {
      this.positive = false;
      this.negative = false;
      checkbox1['checked'] = false
      checkbox2['checked'] = false
    }
    else if (checkbox1['checked'] == true) {
      this.negative = false
      checkbox2['checked'] = false;

    }
    else if (checkbox2['checked'] == true) {
      this.positive = false;
      checkbox1['checked'] = false;
    }



  }


  //---------------------------- Data Add In Multiple Form Start ---------------------------
  // form -1 data
  async addDataInCasherForm(formData:any) {
    this.casier_flag = true;
    if (this.updateFlag == true) {
      $('.nav-tabs a[href="#tab-1"]').tab('show')
      this.list_flag = false
      // this.casierDisabled=false;
      // this.CasrObj['work_id']=formData.work_id
    }
    else {
      this.casierDisabled = true;
    }
    // $('.nav-tabs a[href="#tab-1"]').tab('show')
    // assisning data on the open form (form 1 )
    this.CasrObj['from_time'] = formData.akh_from_date
    this.CasrObj['to_time'] = formData.akh_to_date
    this.CasrObj['full_name'] = formData.name
    this.CasrObj['father_name'] = formData.f_name
    this.CasrObj['post'] = formData.post
    this.CasrObj['dob'] = formData.dob
    this.CasrObj['first_nyukti_date'] = formData.first_aptmt_date
    this.CasrObj['panoti_date'] = formData.promotion_date
    this.CasrObj['curr_post_date'] = formData.curr_pos_date
    this.CasrObj['stbl_unstabl'] = formData.stability
    this.CasrObj['unit_name'] = formData.unit_name
    this.CasrObj['unit_from_date_1'] = formData.akh_unit_a_from
    this.CasrObj['unit_to_date_1'] = formData.akh_unit_a_to
    this.CasrObj['unit_from_office_1'] = formData.akh_unit_a_office
    this.CasrObj['unit_from_date_2'] = formData.akh_unit_b_from
    this.CasrObj['unit_to_date_2'] = formData.akh_unit_b_to
    this.CasrObj['unit_from_office_2'] = formData.akh_unit_b_office
    this.CasrObj['unit_from_date_3'] = formData.akh_unit_c_from
    this.CasrObj['unit_to_date_3'] = formData.akh_unit_c_to
    this.CasrObj['unit_from_office_3'] = formData.akh_unit_c_office
    this.CasrObj['unexpected_holiday'] = formData.option10_a
    this.CasrObj['accur_holiday'] = formData.option10_b
    this.CasrObj['medical_holiday'] = formData.option10_c
    this.CasrObj['other_holiday'] = formData.option10_d

    this.CasrObjadmin['self_id'] = formData.self_id
    // this.CasrObjadmin['track_id']=element.track_id
    this.CasrObjadmin['user_id'] = this.user_id
    this.CasrObjadmin['user_name'] = this.emp_name
    this.CasrObjadmin['user_role'] = this.emp_role
    this.spinner.hide();
  }

  async addData_cash_adm_form(data:any) {
    this.casier_flag_admin = true;

    //  this.cashierAdminDisabled=true;

    this.CasrObjadmin['option1_a'] = data.option1_a
    this.CasrObjadmin['option1_b'] = data.option1_b
    this.CasrObjadmin['option2_a'] = data.option2_a
    this.CasrObjadmin['option2_b'] = data.option2_b
    this.CasrObjadmin['option3_a'] = data.option3_a
    this.CasrObjadmin['option3_b'] = data.option3_b
    this.CasrObjadmin['option3_c'] = data.option3_c
    this.CasrObjadmin['option3_d'] = data.option3_d
    this.CasrObjadmin['option4_a'] = data.option4_a
    this.CasrObjadmin['option4_b'] = data.option4_b
    this.CasrObjadmin['option5_a'] = data.option5_a
    this.CasrObjadmin['option5_b'] = data.option5_b
    this.CasrObjadmin['option6'] = data.option6
    this.CasrObjadmin['option7'] = data.option7
    this.CasrObjadmin['option8'] = data.option8
    this.CasrObjadmin['option9_a'] = data.option9_a
    this.CasrObjadmin['option9_b'] = data.option9_b
    this.CasrObjadmin['option9_c'] = data.option9_c
    this.CasrObjadmin['option9_d'] = data.option9_d
    this.CasrObjadmin['option9_e'] = data.option9_e
    this.CasrObjadmin['option10'] = data.option10


    if (data.integrity_type == "positive") {
      // if(this.historyView_flag!=true && )
      if (this.emp_role == 'off1') {
        this.positive = true
        this.negative = false
      }
      this.CasrObjadmin['p_name'] = data.inti_name;
      this.CasrObjadmin['p_sign'] = data.inti_sign;
    }
    if (data.integrity_type == 'negative') {
      // if(this.historyView_flag!=true)
      if (this.emp_role == 'off1') {
        // var checkbox2=document.getElementById("myCheckbox2")
        // checkbox2['checked']==true
        this.negative = true
        this.positive = false
      }
      this.CasrObjadmin['n_name'] = data.inti_name
      this.CasrObjadmin['n_text'] = data.inti_text
      this.CasrObjadmin['n_sign'] = data.inti_sign
    }



    this.spinner.hide();
  }

  // form -2 data
  async addDataInGtForm(formData:any) {
    // this.gairtakniki_flag=true
    console.log('this is form data', formData);

    this.gairtakniki_flag = true;

    if (this.updateFlag == true) {
      $('.nav-tabs a[href="#tab-1"]').tab('show')
      this.list_flag = false
    }
    else {
      this.gtDisabled = true;
    }

    this.gtFormObj['acr_name'] = formData.acr_name
    this.gtFormObj['from_time'] = formData.akh_from_date
    this.gtFormObj['to_time'] = formData.akh_to_date
    this.gtFormObj['full_name'] = formData.name
    this.gtFormObj['father_name'] = formData.f_name
    this.gtFormObj['post'] = formData.post
    this.gtFormObj['dob'] = formData.dob
    this.gtFormObj['first_nyukti_date'] = formData.first_aptmt_date
    this.gtFormObj['panoti_date'] = formData.promotion_date
    this.gtFormObj['curr_post_date'] = formData.curr_pos_date
    this.gtFormObj['stbl_unstabl'] = formData.stability
    this.gtFormObj['unit_name'] = formData.unit_name
    this.gtFormObj['unit_from_date_1'] = formData.akh_unit_a_from
    this.gtFormObj['unit_to_date_1'] = formData.akh_unit_a_to
    this.gtFormObj['unit_from_office_1'] = formData.akh_unit_a_office
    this.gtFormObj['unit_from_date_2'] = formData.akh_unit_b_from
    this.gtFormObj['unit_to_date_2'] = formData.akh_unit_b_to
    this.gtFormObj['unit_from_office_2'] = formData.akh_unit_b_office
    this.gtFormObj['unit_from_date_3'] = formData.akh_unit_c_from
    this.gtFormObj['unit_to_date_3'] = formData.akh_unit_c_to
    this.gtFormObj['unit_from_office_3'] = formData.akh_unit_c_office
    this.gtFormObj['unexpected_holiday'] = formData.option10_a
    this.gtFormObj['accur_holiday'] = formData.option10_b
    this.gtFormObj['medical_holiday'] = formData.option10_c
    this.gtFormObj['other_holiday'] = formData.option10_d

    // if(this.emp_role=='ACR_22_OFFICER')
    // {
    this.gtadminObj['self_id'] = formData.self_id
    // this.gtadminObj['track_id']=element.track_id
    this.gtadminObj['user_id'] = this.user_id
    this.gtadminObj['user_name'] = this.emp_name
    this.gtadminObj['user_role'] = this.emp_role
    // }
    // if(this.emp_role=='ACR_33_OFFICER_1')
    // {
    //    this.upOffAdmin['self_id']=formData.self_id
    //    this.upOffAdmin['user_id']=this.user_id
    //    this.upOffAdmin['user_name']=this.emp_name
    //    this.upOffAdmin['user_role']=this.emp_role
    // }
  }

  async addData_form2_off1(data:any) {
    this.gairtakniki_admin = true;
    // this.gtadminObj={}
    this.gtadminObj['option1_a'] = data.option1_a
    this.gtadminObj['option1_b'] = data.option1_b
    this.gtadminObj['option1_c'] = data.option1_c
    this.gtadminObj['option1_d'] = data.option1_d
    this.gtadminObj['option1_e'] = data.option1_e

    this.gtadminObj['option2_a'] = data.option2_a
    this.gtadminObj['option2_b'] = data.option2_b
    this.gtadminObj['option2_c'] = data.option2_c
    this.gtadminObj['option2_d'] = data.option2_d
    this.gtadminObj['option2_e'] = data.option2_e

    this.gtadminObj['option3_a'] = data.option3_a
    this.gtadminObj['option3_b'] = data.option3_b
    this.gtadminObj['option3_c'] = data.option3_c

    this.gtadminObj['option4'] = data.option4

    this.gtadminObj['option5_a'] = data.option5_a
    this.gtadminObj['option5_b'] = data.option5_b


    if (data.integrity_type == "positive") {
      if (this.emp_role == 'ACR_22_OFFICER') {

        this.positive = true

      }
      this.gtadminObj['p_name'] = data.inti_name;
      this.gtadminObj['p_sign'] = data.inti_sign;
    }
    if (data.integrity_type == 'negative') {
      if (this.emp_role == 'ACR_22_OFFICER') {

        this.negative = true
      }
      this.gtadminObj['n_name'] = data.inti_name
      this.gtadminObj['n_text'] = data.inti_text
      this.gtadminObj['n_sign'] = data.inti_sign
    }


    this.spinner.hide();

  }

  // form 3 data
  upoffDisable: boolean = false
  async addDataInUpOff(formData:any) {

    console.log(" upoff formdata ", formData)

    this.officer = true;

    if (this.updateFlag == true) {
      $('.nav-tabs a[href="#tab-1"]').tab('show')
      this.list_flag = false
    }
    else {
      this.upoffDisable = true;
    }

    this.upOffObj['acr_name'] = formData.acr_name
    this.upOffObj['from_time'] = formData.akh_from_date
    this.upOffObj['to_time'] = formData.akh_to_date
    this.upOffObj['full_name'] = formData.name
    this.upOffObj['father_name'] = formData.f_name
    this.upOffObj['post'] = formData.post
    this.upOffObj['dob'] = formData.dob
    this.upOffObj['first_nyukti_date'] = formData.first_aptmt_date
    this.upOffObj['panoti_date'] = formData.promotion_date
    this.upOffObj['curr_post_date'] = formData.curr_pos_date
    this.upOffObj['stbl_unstabl'] = formData.stability
    this.upOffObj['unit_name'] = formData.unit_name
    this.upOffObj['unit_from_date_1'] = formData.akh_unit_a_from
    this.upOffObj['unit_to_date_1'] = formData.akh_unit_a_to
    this.upOffObj['unit_from_office_1'] = formData.akh_unit_a_office
    this.upOffObj['unit_from_date_2'] = formData.akh_unit_b_from
    this.upOffObj['unit_to_date_2'] = formData.akh_unit_b_to
    this.upOffObj['unit_from_office_2'] = formData.akh_unit_b_office
    this.upOffObj['unit_from_date_3'] = formData.akh_unit_c_from
    this.upOffObj['unit_to_date_3'] = formData.akh_unit_c_to
    this.upOffObj['unit_from_office_3'] = formData.akh_unit_c_office
    this.upOffObj['unexpected_holiday'] = formData.option10_a
    this.upOffObj['accur_holiday'] = formData.option10_b
    this.upOffObj['medical_holiday'] = formData.option10_c
    this.upOffObj['other_holiday'] = formData.option10_d

    this.upOffAdmin['self_id'] = formData.self_id
    this.upOffAdmin['user_id'] = this.user_id
    this.upOffAdmin['user_name'] = this.emp_name
    this.upOffAdmin['user_role'] = this.emp_role
  }

  upoffAdminDisable: boolean = false
  async addData_form3_off2(data:any) {
    this.officer_admin = true;
    // this.upOffAdmin={}
    this.upOffAdmin['option1_a'] = data.option1_a
    this.upOffAdmin['option1_b'] = data.option1_b
    this.upOffAdmin['option1_c'] = data.option1_c
    this.upOffAdmin['option1_d'] = data.option1_d
    this.upOffAdmin['option1_e'] = data.option1_e

    this.upOffAdmin['option2_a'] = data.option2_a
    this.upOffAdmin['option2_b'] = data.option2_b
    this.upOffAdmin['option2_c'] = data.option2_c
    this.upOffAdmin['option2_d'] = data.option2_d
    this.upOffAdmin['option2_e'] = data.option2_e

    this.upOffAdmin['option3_a'] = data.option3_a
    this.upOffAdmin['option3_b'] = data.option3_b
    this.upOffAdmin['option3_c'] = data.option3_c

    this.upOffAdmin['option4'] = data.option4

    this.upOffAdmin['option5_a'] = data.option5_a
    this.upOffAdmin['option5_b'] = data.option5_b




    if (data.integrity_type == "positive") {
      if (this.emp_role == 'ACR_33_OFFICER_1') {
        this.positive = true
        this.negative = false
      }
      this.upOffAdmin['p_name'] = data.inti_name;
      this.upOffAdmin['p_sign'] = data.inti_sign;
    }
    if (data.integrity_type == 'negative') {
      if (this.emp_role == 'ACR_33_OFFICER_1') {
        this.negative = true
        this.positive = false
      }
      this.upOffAdmin['n_name'] = data.inti_name
      this.upOffAdmin['n_text'] = data.inti_text
      this.upOffAdmin['n_sign'] = data.inti_sign
    }


    this.spinner.hide();

  }
  // universal
  review_screen_1_data :any= []
  reviedDisObj :any= {}
  reviewDisabled: boolean = false;
  async addData_review_form(data:any) {
    if (this.historyView_flag_h == true) {
      this.review_screen = false;
    }
    else {
      this.review_screen = true;
    }
    this.review_screen_1_data = []
    this.review_screen_1_data = data
    this.reviewDisabled = true;

    // this.reviedDisObj['sign1']=data.sign
    // this.reviedDisObj['message1']=data.review
    // this.reviedDisObj['date1']=data.date1
    this.review_screen_1 = true;
    this.spinner.hide();
    console.log("this is the review data add function data ", data);
  }




  //---------------------------- Data Add In Multiple Form End ---------------------------


  reviewObj:any = {}
  async saveReview() {

    this.reviewObj['user_id'] = this.erpUser.user_id
    this.reviewObj['user_role'] = this.emp_role;

    this.reviewObj['b_acct_id'] = this.b_acct_id;

    console.log(' this is the dave button of the review click', this.reviewObj);
    let resp :any= await this.acrService.addReview(this.reviewObj)
    if (resp['error'] == false) {
      Swal.fire('Success', 'Rceord Added', 'success');
      console.log(" save revuew resp", resp);

      await this.specificACR()
      $('.nav-tabs a[href="#tab-2"]').tab('show')


    }

  }

  async addData_review_form_Update(data:any) {

    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['review_id'] = data.review_id;

    if (data.review_id != null || data.review_id != undefined) {

      this.reviewObj['id'] = data.review_id;
      let resp :any= await this.acrService.findReview_data(obj)
      console.log("this is the review data ", resp);
      if (resp['error'] == false) {

        this.reviewObj['date'] = resp['data']['date']
        this.reviewObj['message'] = resp['data']['review']
        this.reviewObj['sign'] = resp['data']['sign']

      }
    }
    // else{
    //   this.reviewObj['date']=resp['data']['date']
    //   this.reviewObj['message']=resp['data']['review']
    //   this.reviewObj['sign']=resp['data']['sign']
    // }
  }

  async saveReview_Update() {
    this.reviewObj['b_acct_id'] = this.b_acct_id;
    console.log(' this is the update button ', this.reviewObj);
    let resp :any= await this.acrService.addReview_update(this.reviewObj)
    console.log("revire update resp", resp)
    if (resp['error'] == false) {
      Swal.fire("Success", "Update Review Successfuly", 'success');
      await this.specificACR()
      $('.nav-tabs a[href="#tab-2"]').tab('show')
    }
    else {
      Swal.fire("Error", "Please try after some time", 'error');

    }


  }


  // ----------------------------------- GT START -----------------------------------
  gtFormObj :any= {}
  async saveGtForm() {
    console.log("this is the gt form obj", this.gtFormObj);
    await this.self_Save(this.gtFormObj)
  }

  async updateGtForm() {

    console.log("Update ACR_22 click ", this.gtFormObj);
    await this.selfFormUpdate(this.gtFormObj)
  }
  // ----------------------------------- GT END -------------------------------------

  // -------------------------- up adhikari start start --------------------
  upOffObj :any= {}
  async save_Upoff_self() {
    console.log("this is the upadhikari data for save ", this.upOffObj)
    this.self_Save(this.upOffObj)
  }
  async update_Upoff_self() {
    console.log("this is the data of thge update upadhikari ", this.upOffObj)
    await this.selfFormUpdate(this.upOffObj)

  }



  // -------------------------- up adhikari start end --------------------




  // ----------------------- History page -----------------------------

  //--- get the form for specific role

  async getSpecificFormBody(openRole:any, data:any) {

    if (openRole == "ACR_11") {

      this.casier_flag = true
      this.officer = false
      this.gairtakniki_flag = false
      this.casier_flag_admin = false
      this.gairtakniki_admin = false
      this.officer_admin = false
      this.list_flag = false
      this.review_screen = false
      this.review_screen_1 = false
    }
    else if (openRole == "ACR_22") {

      this.casier_flag = false
      this.officer = false
      this.gairtakniki_flag = true
      this.casier_flag_admin = false
      this.gairtakniki_admin = false
      this.officer_admin = false
      this.list_flag = false
      this.review_screen = false
      this.review_screen_1 = false

    }
    else if (openRole == "ACR_33") {

      this.casier_flag = false
      this.officer = true
      this.gairtakniki_flag = false
      this.casier_flag_admin = false
      this.gairtakniki_admin = false
      this.officer_admin = false
      this.list_flag = false
      this.review_screen = false
      this.review_screen_1 = false
    }
    else if (openRole == "off1") {

      this.officer = false
      this.gairtakniki_flag = false
      this.casier_flag_admin = true

      this.cashierAdminDisabled = true;
      await this.addData_cash_adm_form(data)

      this.gairtakniki_admin = false
      this.officer_admin = false
      this.list_flag = false;
      this.review_screen = false
      this.review_screen_1 = false
    }
    else if (openRole == "D11" || openRole == "E11") {

      this.officer = false
      this.gairtakniki_flag = false
      this.gairtakniki_admin = false
      this.officer_admin = false
      this.list_flag = false;
      this.review_screen = false;

      // this.review_screen_1=true;
      await this.addData_review_form(data)

    }
    else if (openRole == "ACR_22_OFFICER") {

      this.casier_flag = false
      this.officer = false
      // this.gairtakniki_flag = true
      this.casier_flag_admin = false
      this.gairtakniki_admin = true
      this.officer_admin = false
      this.list_flag = false
      // await this.addDataIn_Form2(data)
      this.gtAdminDisabled = true
      this.gtadminObj = {}
      await this.addData_form2_off1(data)
    }
    else if (openRole == "ACR_22_OFFICER_2" || openRole == "ACR_22_OFFICER_3"
      || openRole == "ACR_33_OFFICER_2" || openRole == "ACR_33_OFFICER_3") {
      await this.addData_review_form(data)
    }
    else if (openRole == "ACR_33_OFFICER_1") {

      this.casier_flag = false
      // this.officer = false
      this.gairtakniki_flag = false
      this.casier_flag_admin = false
      this.gairtakniki_admin = false
      // this.officer_admin = true
      this.list_flag = false
      this.upoffAdminDisable = true;
      await this.addData_form3_off2(data)
    }

  }


  async backToHistory() {
    this.list_flag = true;
    this.casier_flag = false
    this.officer = false;
    this.gairtakniki_flag = false;
    this.casier_flag_admin = false;
    this.gairtakniki_admin = false;
    this.officer_admin = false;
    this.pending_acr = false;
    this.review_screen = false;
    this.review_screen_1 = false;
    this.historyView_flag = false;
    await this.historyView()
  }


  historyView_flag: boolean = false
  async historyView() {
    this.list_flag = true;
    this.casier_flag = false
    this.officer = false;
    this.gairtakniki_flag = false;
    this.casier_flag_admin = false;
    this.gairtakniki_admin = false;
    this.officer_admin = false;
    this.pending_acr = false;
    this.review_screen = false;
    this.review_screen_1 = false;
    this.historyView_flag = false;
    this.anudesh_flag = false;
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp :any= await this.acrService.getHistoryRecord(obj);
    console.log(" history button is clicked", resp);

    this.dataSource1 = new MatTableDataSource(resp['data']);
    this.dataSource1.paginator = this.paginator1
    this.dataSource1.sort = this.sortCol2;

  }

  async getRecordOfForm(element:any) {
    this.spinner.show();
    this.historyView_flag = true;
    this.list_flag = false;
    console.log(" see record is clicked ", element);

    if (element.form_type == 'form_1') {
      this.casier_flag = true;
      await this.addDataInCasherForm(element)
    }
    if (element.form_type == 'form_2') {
      this.gairtakniki_flag = true;
      await this.addDataInGtForm(element)
    }
    if (element.form_type == 'form_3') {
      this.officer = true;
      await this.addDataInUpOff(element)
    }


    if (element.status == 'GENERATED') {

      if (element.form_type == 'form_3' || element.form_type == 'form_2') {
        this.anudesh_flag = true;
      }

      this.spinner.hide();
      return;
    }

    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['self_id'] = element.self_id;
    obj['form_type'] = element.form_type;
    obj['work_id'] = element.work_id;
    let resp :any= await this.acrService.getDetailOfForm(obj);
    console.log(' get record resp ', resp);
    if (resp['error'] == false) {
      if (resp['addRecord'] != undefined) {

        if (resp['addRecord'][0] != undefined) {
          await this.getSpecificFormBody(resp['addRecord'][0]['user_role'], resp['addRecord'][0])

        }
        else {

          await this.getSpecificFormBody(resp['addRecord']['user_role'], resp['addRecord'])


        }

      }

      if (resp['reviewData'] != undefined) {
        await this.getSpecificFormBody(resp['reviewData'][0]['user_role'], resp['reviewData'])
      }

      if (element.form_type == 'form_2' || element.form_type == 'form_3') {
        this.anudesh_flag = true;
      }
      else {
        this.anudesh_flag = false;
      }

    }



  }


  // ---------------------- History page End --------------------------



  //---------------- View Reject Reason ---------------------------
  reason:any
  async viewRejectReason(element:any) {
    var obj :any=new  Object();
    obj['b_acct_id'] = this.b_acct_id;


    if (element.review_id != undefined && element.review_id != null) {
      obj['user_id'] = this.erpUser.user_id;
      // let resp :any= await this.acrService.getRejectReason(obj)
      let resp :any= await this.acrService.getRejectReason_withSelf(obj)
      console.log("responce wher review id ", resp)
      if (resp['error'] == false) {
        this.reason = resp['data']['reason']
      } else {
        Swal.fire('Warning', 'Server Side Error Please Check After Some Time', 'warning')
        return;
      }
    }
    // else if(element.track_id!=undefined || )
    // {
    //   let resp= await this.
    // }
    else {
      obj['user_id'] = this.user_id
      let resp :any= await this.acrService.getRejectReason_withSelf(obj)
      console.log("this is the self reject responce ", resp)
      if (resp['error'] == false) {
        this.reason = resp['data']['reason']
      }
      else {
        Swal.fire('Warning', 'Server Side Error Please Check After Some Time', 'warning')
        return;
      }
    }
    console.log("this is hte reason button ", element, this.reason)

    $('#myModal4').modal('show');
  }




  // -------Approval /Workflow --------------
  forwarded_user_id:any;
  appr_remark:any;
  undertakings:any;
  //-------------- Submit Approval ----------


  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb :any= new Object();

      obb['user_id'] = this.erpUser.user_id
      obb['b_acct_id'] = this.erpUser.b_acct_id
      obb['forwarded_by'] = this.erpUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = this.doc_type          //'ACR'
      // obb['work_local_no'] = this.approvalObjData['work_local_no']
      obb['doc_local_no'] = this.approvalObjData['work_local_no']
      obb['doc_desc'] = this.approvalObjData['work_desc']
      obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
      obb['module_cd'] = 'HR'
      obb['node_cd'] = this.approvalObjData['node_cd']
      obb['level'] = this.ApprovalUserService.level1['level']
      obb['undertaking'] = JSON.stringify([])
      obb['branch'] = this.ApprovalUserService.level1['branch']
      obb['field_value'] = 0
      obb['user_name'] = this.emp_name
      console.log(" this is the Approval Approved", obb);
      // getting the user name and id
      // return;

      var resp = await this.mainserviceEmb.insertTask(obb)
      // var resp = await this.attendService.insertTaskworkFlow(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        // await this.changeStatusSelfAcr(this.approvalObjData['work_local_no'], 'UNDERAPPROVAL')
        await this.changeStatusSelfAcr(this.approvalObjData['work_local_no'], 'UNDERAPPROVAL')
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          // obb['user_id'] = -1
          // obb['vendor_id'] = this.selectedTender['party_id']
          if (this.message_flag == true) {
            /* await this.getle(this.selectedTender['party_id']) */
          }

        } else {
          if (this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        // this.toastr.successToastr("Approval Forwarded Successfully!!")
        console.log("Approval Forwarded Successfully!!")
        await this.getCashierList()
        Swal.fire('Success', 'Forwarded...', 'success');

      } else {
        this.spinner.hide();
        Swal.fire("Error", 'Some Error Occured !', 'error');

        console.log(resp['data'])
        // this.toastr.errorToastr(resp['data'])
      }
    } else {
      // this.toastr.errorToastr("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb :any= new Object();

      obb["user_id"] =
        this.ApprovalUserService.rolebyid[this.forwarded_user_id]["user_id"];

      for (let i = 0; i < this.ApprovalUserService.workuser.length; i++) {
        if (this.ApprovalUserService.workuser[i]["user_id"] == obb["user_id"]) {
          let h = this.ApprovalUserService.workuser[i]["desc"].split("-");
          obb["user_name"] = h[1];
        }
      }






      // var obb = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        // if(this.selectedTender['party_id'] == null || this.selectedTender['party_id'] == undefined){
        //   Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
        //   return
        // }
        // obb['user_id'] = -1
        // obb['vendor_id'] = this.selectedTender['party_id']
      }
      // else {
      //   obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      // }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.erpUser.b_acct_id
      obb['forwarded_by'] = this.erpUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = this.doc_type  //'ACR'
      // obb['work_local_no'] = this.approvalObjData['work_local_no']
      obb['doc_local_no'] = this.approvalObjData['work_local_no']
      obb['doc_desc'] = this.approvalObjData['work_desc']
      // obb['undertaking'] = JSON.stringify([])
      // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'HR'
      obb['node_cd'] = this.approvalObjData['node_cd']

      console.log('this is thge pending approval ---', obb);


      // save data in the acr track

      await this.acrTrackInsert(obb);



      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.approvalObjData['level']
        obb['branch'] = this.approvalObjData['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      // obb['field_value'] =0
      var resp = await this.mainserviceEmb.insertTask(obb)
      // var resp = await this.attendService.insertTaskworkFlow(obb)
      if (resp['error'] == false) {

        //await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        //this.toastr.successToastr("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        Swal.fire("Success", resp["data"], "success");
        // this.toastr.errorToastr(resp['data'])
      }
    } else {
      Swal.fire("Error", "Please Select Any User..!", "error");
      this.spinner.hide();
    }
  }

  //----------- end sub approval ---------
  //================== Send To Approve ==============
  approvalObjData :any= {};
  async Appr(element:any) {
    this.appr_remark = ''
    this.approvalObjData = Object.assign({}, element);
    console.log("send to aprove click--", element)
    this.approvalObjData['doc_type'] = this.doc_type//'ACR';
    this.approvalObjData['work_local_no'] = this.approvalObjData['work_id'];
    this.approvalObjData['doc_local_no'] = this.approvalObjData['work_id'];

    this.approvalObjData['module_cd'] = 'HR';
    this.approvalObjData['level'] = 1;
    this.approvalObjData['branch'] = 1;
    this.approvalObjData['work_desc'] = `Approval For ${this.approvalObjData['user_id']}`
    this.approvalObjData['node_cd'] = this.node_code;
    console.log("Acr Workflow  approval obj ---", this.approvalObjData)
    await this.ApprovalUserService.getWorkflowlog(this.erpUser, this.doc_type, 'HR');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      await this.changeStatusSelfAcr(this.approvalObjData['work_id'], 'APPROVED')
      return;
    }




    await this.ApprovalUserService.getMostLatestWorkflowlog(this.erpUser, this.approvalObjData)
    await this.ApprovalUserService.getWorkflowloguser(this.erpUser, this.approvalObjData)

    $('#myModal3').modal('show');

  }

  message_flag = false
  async getle(user_id:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp :any= await this.mainserviceEmb.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      await this.sendmessage(resp['data'][0]['phone_no'], resp['data'][0]['party_name']
      )
      this.spinner.hide();
      this.message_flag = false
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }
  async sendmessage(mobile:any, user_name:any) {
    //console.log(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['work_local_no'])
    let resp :any= await this.mainserviceEmb.sendMsg(mobile, this.approvalObjData['doc_type'], this.approvalObjData['work_local_no'], user_name)

    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }
  }

  async changeStatusSelfAcr(work_id:any, work_status_code:any) {
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['update_user_id'] = this.erpUser.user_id;
    obj['work_id'] = work_id;
    obj['work_status_code'] = work_status_code;
    obj['reject'] = 'no';
    this.spinner.show();
    console.log(obj)
    var resp = await this.acrService.changeStatusOfAcrSelf(obj);
    // var resp
    if (resp['error'] == false) {

      this.spinner.hide();
      // Swal.fire('Success', 'Extra-Work Approved Successfully');

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error in Extra-Work Generation', 'error');
    }

  }
  //-----------------------------------------

  async approvedocment() {
    await this.lastapprandrejectedappr("VERIFIED");
  }
  async lastapprandrejectedappr(status:any) {
    // if (status == "REJECTED") {
    //   this.defendant_apprdata = Object.assign({}, this.rejectdata);
    // }
    // // console.log(this.rejectdata);

    var obb:any = new Object();

    obb["user_id"] = this.erpUser.user_id;

    obb["user_name"] = this.erpUser.party_name;
    obb["b_acct_id"] = this.erpUser.b_acct_id;
    obb["forwarded_by"] = this.erpUser.user_id;
    obb["forward_msg"] = this.appr_remark;
    obb["remark"] = "";
    obb["status"] = status;
    obb["doc_type"] = this.doc_type   //"Extra_Work";
    obb["doc_local_no"] = this.approvalObjData['work_local_no']
    obb['doc_desc'] = this.approvalObjData['work_desc']
    obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
    obb['module_cd'] = 'HR'
    obb['node_cd'] = this.approvalObjData['node_cd']
    obb['level'] = this.ApprovalUserService.level1['level']
    obb['undertaking'] = JSON.stringify([])
    obb['branch'] = this.ApprovalUserService.level1['branch']
    // obb['field_value'] =0

    // // console.log(obb);

    var resp = await this.mainserviceEmb.insertTask(obb)
    if (resp["error"] == false) {
      await this.changeStatusSelfAcr(this.approvalObjData['work_local_no'], status)
      if (status == "REJECTED") {
        Swal.fire("Success", "Rejected Successfully!!", "success");
      }
      if (status == "VERIFIED") {
        Swal.fire("Success", "Verified Successfully!!", "success");
      }
    } else {
      Swal.fire("Error", resp["data"], "error");
    }
  }


  //-----end approval-------------



}  // main end
