import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MainService } from '../service/main.service';
import { ProfileService } from '../service/profile.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '../service/user.service';





@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  // selectedType
  user_id:any;
  user_email:any;
  ebillUser:any;
  b_acct_id:any;
 
 
  constructor(private router: Router, private spinner: NgxSpinnerService,
    private userService: UserService, private profileService: ProfileService,
    private sanitizer: DomSanitizer, private mainService: MainService) {
  }

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
  }
 


}
