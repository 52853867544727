import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { RequestService } from '../service/request.service';
declare var $: any;

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {
  erpUser: any;
  ebillUser: any;
  b_acct_id: any;
  user_id: any;
  datasource: any;
  displayedColumns = ['s.no', 'Request_Id', 'case_ID', 'Request_Date', 'Request_Purpose', 'status', 'action',];
  requestdtls: any = {}

  constructor(private spinner: NgxSpinnerService, private request: RequestService) { }

  ngOnInit() {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    // this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')));
    this.b_acct_id = this.erpUser.b_acct_id
    // this.user_id = this.erpUser.le_id
    this.getrequest();
  }

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }




  async getrequest() {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show();
    var resp:any = await this.request.getrequestData(JSON.stringify(obj));
    // console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.spinner.hide();
      this.datasource = resp['data']
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
    }
  }

  viewdetails(element:any) {
    this.requestdtls = element
    $('#staticBackdrop').modal('show');

  }
  async accept(element:any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Accept it!'
    }).then((result) => {
      // console.log(result, result.value)
      if (result.value) {

        this.accepted(element);

      }
    })
  }

  async reject(element:any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reject it!'
    }).then((result) => {
      // console.log(result, result.value)
      if (result.value) {

        this.rejected(element);

      }
    })
  }

  async rejected(element:any) {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['case_id'] = element.case_id
    obj['decision'] = 'REJECTED'
    this.spinner.show();
    var resp :any= await this.request.insertdecision(obj);
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.getrequest();
      this.spinner.hide();
      Swal.fire('success', "Rejected successfully",'success')
    }


  }
  async accepted(element:any) {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['case_id'] = element.case_id
    obj['decision'] = 'ACCEPTED'
    this.spinner.show();
    var resp :any= await this.request.insertdecision(obj);
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.getrequest();
      this.spinner.hide();
      Swal.fire('success', "Accepted successfully",'success')
    }


  }
  documnet_detail : any ={}
 async viewdocument(element:any){
  let obj :any= {}
  obj['b_acct_id'] = this.b_acct_id
  obj['complain_id'] = element.request_id
  this.spinner.show()
  var resp :any= await this.request.getrequestdoc(JSON.stringify(obj));
  if (resp['error'] == true) {
    Swal.fire('error', "server side error", 'error')
    return
  } else {
    this.documnet_detail = resp['data']
    obj['doc_id'] = this.documnet_detail[0]['doc_id']
    obj['file_name'] = this.documnet_detail[0]['file_name']
    // console.log(obj)
    var res :any= await this.request.view_Doc(obj);
    // console.log(res);
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    }else{
      this.spinner.hide();
       var docname = this.documnet_detail[0]['file_name']
       var ext = docname.split('.');
        if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
          let file = new Blob([res], { type: 'image/png' });
          var fileURL = window.URL.createObjectURL(file);
          this.spinner.hide()
          window.open(fileURL, '_blank');
        } else if (ext[1].toLowerCase() == 'xlsx') {
          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {
          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        } else {
          let file = new Blob([res], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()

        }
    }
  }


  }




}
