import { Component, OnInit, ViewChild, } from '@angular/core';
import { MainService } from '../../service/main.service';
import { AllEmpService } from '../../service/all-emp.service';
import { DataAccessService } from '../../service/data-access.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { AttendaceMangService } from '../attendace-mang.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;

@Component({
  selector: 'app-create-rule',
  templateUrl: './create-rule.component.html',
  styleUrls: ['./create-rule.component.css']
})
export class CreateRuleComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  datasource:any ;
  constructor(private dataAccessService: DataAccessService, private spinner: NgxSpinnerService, public mainService: MainService, private allEmpService: AllEmpService, private attendService: AttendaceMangService) { }

  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  arr_id:any ;
  newallEmplyees:any  = [];
  allEmplyees:any ;
  b_acct_id:any ;
  erpUser:any ;
  empIdToName:any

  // node
  planing_arrproj :any = [];
  projecthier :any = []
  projhierobj :any = {}
  projhierobjtype :any = {}
  projhierobjtypecd :any = {}
  projhierobjisleaf :any = {}
  Obj :any = {}
  allProject :any = [];
  joiningObj :any = {};
  list_flag:any  = true
  create_flag :any = false
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    //  await this.getAllEmployees();
    await this.getAccountProjectHier();
    await this.getrule()
  }

  fromTime:any ;
  toTime:any ;
  grace:any  = `00+':'+00+':'+00`;
  inNo:any ;
  frequency:any ;
  frequencyPeriod :any = ['Monthly', 'Quarterly', 'Half-Yearly', 'Yearly'];
  objHeir :any = {}
  displayedColumns = ['si_no',  'section', 'frequency', 'number', 'grace', 'from_time', 'to_time'];

  async submit() {
    if((this.fromTime =='')||(this.fromTime==null )||(this.toTime=='')||(this.toTime==null)
    ||(this.frequency=='')||(this.frequency==null)||(this.inNo=='')||(this.inNo==null)|| (this.grace=='')||(this.grace==null )){
      Swal.fire('Info',"firstly fill all required (*) field",'info')
      return;
    }
    var obj :any = new Object()
    obj["b_acct_id"] = this.b_acct_id
    obj['fromDate'] = this.fromTime
    obj['toDate'] = this.toTime
    obj['grace'] = this.grace
    obj['inNo'] = this.inNo
    obj['user_id'] = this.erpUser.user_id
    obj['frequency'] = this.frequency
    obj["heirarchy_type"] = this.check1
    obj["is_leaf"] = this.objHeir['is_leaf']
    obj["level"] = this.objHeir['level']
    console.log(obj)
    let resp:any  = await this.attendService.insert_rule(obj);
    console.log(resp)
    if (resp["error"] == false) {

      this.spinner.hide()
      Swal.fire('Success', "Successfully Submitted", 'success')
      // this.empty()
      this.Obj['path_desc'] = ''
      this.fromTime = ''
      this.toTime = ''
      this.grace = ''
      this.inNo = ''

      this.frequency = ''
     await this.open_list()
      await this.getrule()
       this.planing_arrproj=[]

    }

    else {
      if(resp["data"]=="Rule is already Created "){
        Swal.fire('info',"Rule is already Created",'info')
        this.Obj['path_desc'] = ''
        this.fromTime = ''
        this.toTime = ''
        this.grace = ''
        this.inNo = ''

        this.frequency = ''
        this.planing_arrproj=[]
        return

      }
      else{
      Swal.fire('Error', "some Error occured", 'error')
      }
    }
  }
  reset(){

  }
  open_create() {

    this.list_flag = false
    this.create_flag = true

  }
  async open_list() {

    this.list_flag = true
    this.create_flag = false
    await this.getrule()
  }
  async getrule() {
    let obj:any  = new Object()
    obj["b_acct_id"] = this.b_acct_id
    var resp:any  = await this.attendService.get_rule(obj);
    console.log(resp)
    if (resp["error"] == false) {
      this.spinner.hide()
      console.log(resp['data'])
      this.datasource = new MatTableDataSource(resp['data']);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    }
    else {
      Swal.fire('Error', "some Error occured", 'error')
    }
  }














  // select employee

  // async getAllEmployees() {
  //   this.spinner.show()
  //   var arr = []
  //   var obj = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['emp_status'] = 'ACTIVE';
  //   var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
  //   console.log(resp)
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     arr = resp.data
  //     this.allEmplyees = arr;
  //     this.newallEmplyees = []
  //     for (let i = 0; i < resp.data.length; i++) {
  //       var obj = new Object();
  //       obj = Object.assign({}, resp.data[i]);
  //       obj['emp_name'] = obj['emp_id'] + "-" + obj['emp_name']
  //       this.newallEmplyees.push(obj)
  //     }
  //     // for (var i = 0; i < this.newallEmplyees.length; i++) {
  //     //   this.empIdToName[this.newallEmplyees[i].emp_id] = this.newallEmplyees[i].joining_date;
  //     // }
  //     console.log(this.allEmplyees)
  //     console.log(this.newallEmplyees);
  //     this.newallEmplyees = await this.dataAccessService.filterEmployeeData(this.newallEmplyees)
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire('Error',"Error while getting employee list",'error')
  //   }


  // }

  //---------------- project herarchy--------
  async getAccountProjectHier() {
    var obj :any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAccountProjectHier(obj);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'HR-root'
        }
      }
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {

        var tt = []
        for (let k = 1; k < 8; k++) {
          if (resp.data[i]['lvl' + k + '_cd'] != null) {
            tt.push(resp.data[i]['lvl' + k + '_cd'])
          }
        }
        this.dataAccessService.data_assigment = this.dataAccessService.data_assigment.filter((x:any)  => x.user_id == this.erpUser.user_id)
        var exit_flag = false;
        for (let j = 0; j < this.dataAccessService.data_assigment.length; j++) {
          if (tt.includes(parseInt(this.dataAccessService.data_assigment[j]['node_cd']))) {
            exit_flag = true;
          }
        }

        if (tt.length == 1) {
          exit_flag = true;
        }
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR"))
          && (resp.data[i]['module_cd'] != null || resp.data[i]['module_cd'] != undefined) && exit_flag) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;
      this.allProject = resp.data;
      this.projecthier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_user_cd']
          this.projhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']
        }
      }
      // console.log( "projectObj--", this.projhierobj,"project hierobj --", this.projhierobjtype, "photype cd--",  this.projhierobjtypecd, "pho leaf --",this.projhierobjisleaf  )
    } else {
      console.log("Error in getting all project hier")
    }
  }

  open_activitypopup() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1)
    }

    console.log("Open Pop Up")
    $('#ACTHIER').modal('show');

  }


  down(item:any , k:any ) {
    let patharr = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }
    this.Obj['path_desc'] = patharr.join("-->")
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [] })
    console.log(this.planing_arrproj, item)
    let arr:any  = []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_cd'] != null) {

        if ((this.projecthier[i]['lvl' + (k + 1) + '_cd'] == item['hierarchy_type'] || k == -1)/*  && this.projecthier[i]['leaf_node_type'] != 'HREMP' */) {
          this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'] })
          arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
        }
      }
    }
  }

  remove(item:any , i:any ) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i])

        patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
      }
      this.Obj['path_desc'] = patharr.join("-->")
    }

  }

  // ind_emp_id=[];
  async submitProjectHierNode() {
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']);
    if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
      Swal.fire("Warning", "Please Select at least two level", 'warning')
    }
    else {
      $('#ACTHIER').modal('hide');
    }
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'])
    console.log(this.planing_arrproj.length)
    this.Obj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'];
    var lvl_no = this.planing_arrproj.length;


    //     this.ind_emp_id = [];
    //     var temp = [];
    //     for (let i = 0; i < this.projecthier.length; i++) {
    //       if (this.projecthier[i]['lvl' + lvl_no + '_cd'] == this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] && this.projecthier[i]['leaf_node_type'] == 'HREMP') {
    //         temp.push(this.projecthier[i]['leaf_user_cd'])
    //       }
    //     }
    // console.log("temp data--",temp);
    // for (let i = 0; i < temp.length; i++) {
    //   for (let j = 0; j < this.allEmplyees_new.length; j++) {
    //     if (temp[i] == 'HRARR' + this.allEmplyees_new[j]['arr_id']) {
    //       this.ind_emp_id.push(this.allEmplyees_new[j]['arr_id'])
    //       this.emp_id.push(this.allEmplyees_new[j]['emp_id'])
    //     }
    //   }
    // }

    // console.log(this.ind_emp_id)
    // var obj111 = Object.assign({}, this.salaryObj);
    // // obj111['arr_of_all_dates']=this.arr_of_all_dates;
    // obj111['ind_emp_id'] = [this.ind_emp_id];
    // obj111['b_acct_id'] = this.b_acct_id;
    // console.clear();

    // var resp = await this.payableService.getMonthAndYearForBill(obj111);
    // console.log(resp);
    // console.log(resp)
    // if (resp['error'] == false) {
    //      var obj=resp.data;
    // this.salaryObj['fin_year']=obj.fin_year
    // this.salaryObj['month']=obj['month'].toString()
    // console.log( this.salaryObj['fin_year'],
    // this.salaryObj['month'])

  }

  check1:any
  setHierarchyNodeitem(item:any , i:any ) {
    this.objHeir = new Object();
    console.log(item, i);
    this.check1 = item['hierarchy_type']
    item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']];

    this.objHeir['hierarchy_type'] = this.check1;
    this.objHeir['is_leaf'] = item['is_leaf'];
    this.objHeir['level'] = item['level'];

    console.log("objheirarchy ----", this.objHeir);


    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }

    this.Obj['path_desc'] = dummy2.join(' --> ')
  }





}
