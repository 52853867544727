import { Injectable } from '@angular/core';
import { MainService } from '../service/main.service';
import { HttpClient, HttpEventType ,HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TourDetailService {


  httpUrl;


  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;
   }


   async gettourdetail(obj :any ) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/travelreimbrusment/tour/gettourdetail'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async approvestatus(obj :any ) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/hr/travelreimbrusment/tour/approvestatus',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async gettravelmember(obj :any ) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/travelreimbrusment/tour/gettravelmemberdata'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}
