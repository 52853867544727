<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="col-12 text-right">
                        <h6> {{current_user}}</h6>
                    </div>
                    <div class="ibox-title"></div>
                </div>
                <div class="ibox-body">

                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item {{act_list}}">
                            <a class="nav-link" *ngIf="list_head" (click)="list()" href="#tab-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                ORDER LIST
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link {{act_upload}}" *ngIf="upload_fg" (click)="uploadOrder()" href="#tab-2"
                                data-toggle="tab"><i class="fa fa-line-chart"></i>
                                Upload Order
                            </a>
                        </li> -->

                        <!-- Report Sub button  -->

                        <!-- <li class="nav-item active" *ngIf="order_exe">
                            <a class="nav-link " href="#tab-4" data-toggle="tab"><i class="ti-settings"
                                    aria-hidden="true"> </i>
                                Order Execution
                            </a>
                        </li> -->

                        <!-- when click on recovery cal  -->
                        <!-- <li class="nav-item active" *ngIf="update">
                            <a class="nav-link " href="#tab-5" data-toggle="tab"><i class="ti-settings"
                                    aria-hidden="true"> </i>
                                Update Order
                            </a>
                        </li> -->

                    </ul>

                    <div class="tab-content">
                        <div *ngIf="list_fg" class="active" id="tab-1">
                            <br>

                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-2 mt-3">
                                    <h6> Order Status : </h6>
                                </div>
                                <div class="col-3 text-left">

                                    <ng-select [items]="orderData" bindLabel="order_st" bindValue="order_status"
                                        [multiple]="false" placeholder="Select Order" [(ngModel)]="seleOrderStatus"
                                        (change)="getCourtData()" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true">
                                    </ng-select>

                                </div>
                                <br><br> <br>

                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="col-4">
                                <mat-form-field style="margin-left: 2%">
                                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                </mat-form-field>
                            </div>
                            <br>

                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource" matSort class="text-center">


                                    <ng-container matColumnDef="Sr.No">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Sr. No </th>
                                        <td mat-cell *matCellDef="let element let i = index"> {{i+1}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="case_id_f">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Case ID </th>
                                        <td mat-cell *matCellDef="let element"> {{element.case_id_f}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="co_ord_id">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Order ID </th>
                                        <td mat-cell *matCellDef="let element"> {{element.co_ord_id}} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="co_ord_date">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Order Date </th>
                                        <td mat-cell *matCellDef="let element"> {{element.co_ord_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="co_ord_type">
                                        <th mat-header-cell *matHeaderCellDef class="text-center">Order Type </th>
                                        <td mat-cell *matCellDef="let element"> {{element.co_ord_type}} </td>
                                    </ng-container>


                                    <!-- <ng-container matColumnDef="property_address">
                                        <th mat-header-cell *matHeaderCellDef>  </th>
                                        <td mat-cell *matCellDef="let element"> {{element.property_address}} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="compliner_name">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Opponent Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.owner_name}} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="owner_number">
                                        <th mat-header-cell *matHeaderCellDef> Mobile No. </th>
                                        <td mat-cell *matCellDef="let element"> {{element.owner_number}} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="order_status">
                                        <th mat-header-cell *matHeaderCellDef class="text-center">Order Status </th>
                                        <td mat-cell *matCellDef="let element"> {{element.order_status.split('_').join('
                                            ')}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                                        <td mat-cell *matCellDef="let element" style="width: 20%;">
                                            <button class="btn btn-outline-warning" (click)="viewDetails(element)">View
                                                Details</button>

                                            <button class="btn btn-outline-success"
                                                (click)="downloadDocument(element)">Download Order
                                                <!-- getAttachedDocumnet(element) -->
                                            </button>

                                            <button class="btn btn-outline-primary"
                                                *ngIf="element.order_status =='INITIATED' && element.co_ord_type != 'SelfDemolition' || element.order_status =='SELF_DEMOLITION_DONE' "
                                                (click)="forwordFile(element)">Forward</button>
                                            <button class="btn btn-outline-primary"
                                                *ngIf="element.order_status =='SELF_DEMOLITION_VERIFIED' || element.order_status =='SELF_DEMOLITION_DONE' || element.order_status =='COMPOUNDING_DONE' ||
                                                element.order_status =='DEMOLITION_DONE' ||  element.order_status =='SEALING_DONE'|| element.order_status =='UNSEALING_DONE'"
                                                (click)="defendentDocList(element)">{{element.co_ord_type + "
                                                Report"}}</button>

                                            <button class="btn btn-outline-primary" type="button"
                                                style="margin-left: 2px; margin-top:2px;"
                                                *ngIf="element.order_status == 'UNDERAPPROVAL' || element.order_status=='ORDER_EXECUTED' || element.order_status=='REJECTED'"
                                                (click)="viewStatus(element)"> View Status </button>
                                            <!--
                                            <button class="btn btn-outline-danger" (click)="orderExeFg(element)">Report
                                                Sub</button> -->

                                            <!-- <button class="btn btn-outline-success" (click)="viewReport()">View
                                                Report </button> -->


                                            <!-- <button class="btn btn-outline-info" (click)="addApproval()">Add
                                                Approval</button> -->
                                            <!-- <button class="btn btn-outline-primary" (click)="downloadOrder()">Download
                                                Order</button> -->


                                            <!--

                                            <button class="btn btn-outline-warning" (click)="recoveryOrder()">Recovery
                                                Cal </button> -->

                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>


                        </div>

                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>

    <!-- Order Details model  -->
    <div class="modal fade" id="orderDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
            <div class="modal-content">
                <div class="modal-header" style="background-color: #AED6F1;">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                        <h6>ORDER DETAILS</h6>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div style="border: 2px solid gray; border-radius:10px;padding: 2% 5%;">
                        <div class="row">
                            <div class="col-2 ">
                                <h6>Order ID </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3 ">
                                <h6> {{veiw_details['co_ord_id']}}</h6>

                            </div>
                            <div class="col-2 ">
                                <h6>Order Date </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3 ">
                                <h6> {{veiw_details['co_ord_date']}}</h6>
                            </div>

                        </div>
                        <br>

                        <div class="row">
                            <div class="col-2 ">
                                <h6>Order Type </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3 ">
                                <h6> {{veiw_details['co_ord_type']}}</h6>

                            </div>
                            <!-- <div class="col-3">Type of Construction : </div>
                            <div class="col-3">
                                {{veiw_details['co_ord_type']}}
                            </div> -->

                        </div>
                        <br>

                        <div class="row">
                            <div class="col-2 ">
                                <h6>Order Description </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-9">
                                <h6>{{veiw_details['co_summary']}}</h6>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <br>
                            <div class="col-5">
                                <hr class="hrline">
                            </div>
                            <div class="col-3">
                                <h6 id="h" style="color: brown;min-height: 1px;margin: 6px 1px;text-align: left;">
                                    Compilation Time Limit
                                </h6>
                            </div>
                            <div class="col-4">
                                <hr class="hrline">
                            </div>
                            <br>
                            <br>
                            <div class="col-2">
                                <h6>Date</h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3 ">
                                <span *ngIf="veiw_details['comp_time_limit'] != 'Null' ">
                                    <h6> {{veiw_details['comp_time_limit']}} </h6>
                                </span>
                                <span *ngIf="veiw_details['comp_day_limit'] != 'Null' ">
                                    <h6>{{veiw_details['comp_time_limit']}}</h6>
                                </span>
                            </div>
                            <!-- <div class="col-3">Day : </div>
                            <div class="col-3">
                                {{veiw_details['comp_day_limit']}}
                            </div> -->
                        </div>
                        <br>

                        <div class="row">
                            <br>
                            <div class="col-5">
                                <hr class="hrline">
                            </div>
                            <div class="col-3 text-center">
                                <h6 id="h" style="color: brown;min-height: 1px;margin: 6px 1px;text-align: left;">
                                    Address of Property
                                </h6>
                            </div>
                            <div class="col-4">
                                <hr class="hrline">
                            </div>
                            <br>
                            <br>
                            <div class="col-2">
                                <h6>Property Address </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>

                            <div class="col-9">
                                <h6> {{veiw_details['property_address']}}</h6>

                            </div>
                        </div>

                        <br>

                        <div class="row">
                            <div class="col-2">
                                <h6>Zone</h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3">
                                <h6>{{veiw_details['leaf_value']}}</h6>
                            </div>
                            <div class="col-2 ">
                                <h6>District </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3">
                                <h6>{{veiw_details['dist']}}</h6>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-2">
                                <h6>State </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3">
                                <h6>{{veiw_details['state']}}</h6>
                            </div>

                            <div class="col-2">
                                <h6>Country </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3 ">
                                <h6>{{veiw_details['country']}}</h6>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-2 ">
                                <h6>Landmark </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-9">
                                <h6> {{veiw_details['landmark']}}</h6>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <br>
                            <div class="col-5">
                                <hr class="hrline">
                            </div>
                            <div class="col-3 text-center">
                                <h6 id="h" style="color: brown;min-height: 1px;margin: 6px 1px;text-align: left;">
                                    Opponent Party Details
                                </h6>
                            </div>
                            <div class="col-4">
                                <hr class="hrline">
                            </div>
                            <br>
                            <br>

                            <div class="col-2">
                                <h6> Name </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3">
                                <h6>{{veiw_details['owner_name']}}</h6>
                            </div>
                            <div class="col-2 ">
                                <h6>Phone No. </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3">
                                <h6>{{veiw_details['owner_mobile']}}</h6>
                            </div>
                        </div>
                        <br>
                        <!-- <div class="row">
                            <div class="col-3">Phone/Mobile No. : </div>
                            <div class="col-3">
                                {{veiw_details['compliner_phone_no']}}
                            </div>
                             <div class="col-3">Email : </div>
                            <div class="col-3">
                                {{veiw_details['compliner_email']}}
                            </div>
                        </div>
                        <br> -->


                        <!-- <div class="row">
                            <div class="col-3">Address : </div>
                            <div class="col-9">
                                {{veiw_details['property_address']}}
                            </div>
                        </div> -->
                        <br>

                        <div class="row">
                            <div class="col-2">
                                <h6>Order Status </h6>
                            </div>
                            <div class="col-1 text-center">
                                <h6>:</h6>
                            </div>
                            <div class="col-3">
                                <h6>{{veiw_details['order_status']}}</h6>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="veiw_details['order_status'] == 'EXECUTION_COMPLETED' ">

                            <br>
                            <div class="row">
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                                <div class="col-2 text-center">
                                    <h6 id="h" style="color: brown;min-height: 1px;margin: 6px 1px;text-align:left;">
                                        EXECUTION DETAILS
                                    </h6>

                                </div>
                                <div class="col-1 text-center">
                                    <h6>:</h6>
                                </div>
                                <div class="col-4">
                                    <hr class="hrline">
                                </div>
                            </div>
                            <br>



                            <div class="row">
                                <div class="clo-2">
                                    <h6>Before Execution </h6>
                                </div>
                                <div class="col-1 text-center">
                                    <h6>:</h6>
                                </div>
                                <div class="col-9"></div>
                            </div>
                            <br>
                            <div class="row" *ngFor="let item of before_exe; let i = index;">
                                <div class="col-1">
                                    <h6>Longitude </h6>
                                </div>
                                <div class="col-1 text-center">
                                    <h6>:</h6>
                                </div>
                                <div class="col-2">
                                    <h6>{{item['longitude']}}</h6>
                                </div>
                                <div class="col-1">
                                    <h6>Latitude</h6>
                                </div>
                                <div class="col-1">
                                    <h6>:</h6>
                                </div>
                                <div class="col-2">
                                    <h6>{{item['latitude']}}</h6>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-outline-success" (click)="downloadexecutionImage(item)">View
                                        Image</button>
                                </div>
                                <div class="2"></div>
                                <br><br>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <h6> Description </h6>
                                </div>
                                <div class="col-1">
                                    <h6>:</h6>
                                </div>
                                <div class="col-10">
                                    <h6> {{before_exe[0]['description']}}</h6>
                                </div>
                            </div>
                            <br><br><br>

                            <br>
                            <div class="row">
                                <div class="clo-2">
                                    <h6>After Execution </h6>
                                </div>
                                <div class="col-1">
                                    <h6>:</h6>
                                </div>
                                <div class="col-9"></div>
                            </div>
                            <br>
                            <div class="row" *ngFor="let item of after_exe">
                                <div class="col-1 ">
                                    <h6> Longitude </h6>
                                </div>
                                <div class="col-1 text-center">:</div>
                                <div class="col-2">
                                    <h6>{{item['longitude']}}</h6>
                                </div>
                                <div class="col-1">
                                    <h6> Latitude </h6>
                                </div>
                                <div class="col-1 text-center">:</div>
                                <div class="col-2">
                                    <h6> {{item['latitude']}}</h6>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-outline-success" (click)="downloadexecutionImage(item)">View
                                        Image</button>
                                </div>
                                <div class="2"></div>
                                <br><br>
                            </div>
                            <div class="row">
                                <div class="col-1">
                                    <h6> Description </h6>
                                </div>
                                <div class="col-1 text-center">
                                    <h6>:</h6>
                                </div>
                                <div class="col-10">
                                    <h6> {{after_exe[0]['description']}}</h6>
                                </div>
                            </div>
                            <br><br><br>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <div class="row m-auto">
                            <div class="col-12 text-center">

                                <button type="button" class="btn btn-outline-danger "
                                    data-dismiss="modal">Close</button>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Forword Modal -->
    <div class="modal fade" id="forword" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width:70%; ">
            <div class="modal-content">
                <div class="modal-header" style="background-color: #AED6F1;">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                        <h6>FORWARD ORDER</h6>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div style="border: 2px solid gray; border-radius:10px;">
                        <br>
                        <div class="row">
                            <div class="col-3"></div>
                            <div class="col-3"><b>Order ID : </b> <span class="text-danger">*</span> </div>
                            <div class="col-3">
                                <input type="text" class="form-control" [(ngModel)]="forwardData['co_ord_id']" disabled>
                            </div>
                            <div class="col-3"></div>
                        </div>
                        <br>
                        <!-- <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3"> Select User : <span class="text-danger">*</span> </div>
                        <div class="col-3">
                            <ng-select [items]="allSearchableEmp" bindLabel="desc" bindValue="user_id"
                                [multiple]="false" placeholder="Select User" [(ngModel)]="forwardData['user_id']"
                                [selectableGroup]="true" [selectableGroupAsModel]="true" name="chart_of_account0">res
                            </ng-select>


                        </div>
                        <div class="col-3"></div>
                    </div> -->
                        <div class="row">
                            <div class="col-3"></div>
                            <div class="col-3"><b>Select User :</b> <span class="text-danger">*</span> </div>

                            <div class="col-3" *ngIf="Inzone">
                                <ng-select [items]="userbyzone" [multiple]="false"
                                    placeholder="Select User of {{forwardData.leaf_value}}" bindLabel="desc"
                                    bindValue="user_id" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                    [closeOnSelect]="true" name="hierarchy" [(ngModel)]="forwardData['user_id']">
                                </ng-select>

                            </div>


                            <div class="col-3" *ngIf="OuterZone">
                                <ng-select [items]="userbynotzone" [multiple]="false"
                                    placeholder="Select User from other zone" bindLabel="desc" bindValue="user_id"
                                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="hierarchy" [(ngModel)]="forwardData['user_id']">
                                </ng-select>
                            </div>
                            <div class="col-3">
                                <input class=" pt-1" type="checkbox" (click)='sameAddress()' maxlength="50" value="">
                                &nbsp;select user from other zone
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-3"></div>
                            <div class="col-3"> <b> Note :</b> </div>
                            <div class="col-3"> <textarea class="form-control" placeholder="Write Your Note"
                                    [(ngModel)]="forwardData['desc']" id="floatingTextarea"></textarea></div>
                            <div class="col-3"></div>
                        </div>
                        <br>

                    </div>
                    <div class="modal-footer">
                        <div class="row m-auto">
                            <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button>
                            &nbsp;&nbsp;
                            <button type="button" class="btn btn-outline-primary"
                                (click)="submitForword()">Forward</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Preview model  -->
    <div class="modal fade" id="preview" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog exemodel-dialog modal-dialog-centered" role="document">
            <!-- style="max-width: 30%;" -->
            <div class="modal-content execontent">
                <div class="modal-header exeheader">
                    <h6>Image Preview</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close-icon">
                        <span aria-hidden="true" (click)="reOpenMOdel()">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-0">
                    <div>
                        <!-- style="border: 2px solid gray; border-radius:10px;padding: 2% 5%;display: contents;" -->
                        <img [src]="executionImage" class="exeImage" />

                        <!-- <div class="modal-footer">
                            <div class="row m-auto">
                                <div class="col-12 text-center">
                                    <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button>

                                </div>
                            </div>

                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Show Attached Document -->

    <div class="modal fade" id="defendent_photo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
            <div class="modal-content">
                <div class="modal-header" style="background-color: #AED6F1;">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                        <h6>DOC DETAILS</h6>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" (click)="clearImageData()">&times;</span>
                    </button>
                </div>
                <div class="modal-body">


                    <fieldset *ngIf="doc_before_exe.length>0"
                        style="border: 2px solid gray; border-radius:10px;margin-top: 3%;">
                        <legend
                            style="color: brown;border-radius: 10%;padding: 1%;margin-left: 3%;font-size: 19px;width: 17rem;">
                            {{img_before_heading}} </legend>

                        <div class="row">
                            <div class="col-12 text-center">
                                <span *ngFor="let item of doc_before_exe ;let i =index">
                                    <button class="btn btn-outline-success"
                                        (click)="viewDefendentImage(item , 'before')">view
                                        Image{{i+1}}</button>
                                </span>
                            </div>

                        </div>
                        <hr>

                        <div class="row mt-2 justify-content-center" *ngIf="before_img">

                            <img [src]="imgURL" height="400px" width="auto" object-fit="contain" />

                        </div>
                    </fieldset>

                    <fieldset *ngIf="doc_after_exe.length>0"
                        style="border: 2px solid gray; border-radius:10px;margin-top: 3%;">
                        <legend
                            style="color: brown;border-radius: 10%;padding: 1%;margin-left: 3%;font-size: 19px;width: 17rem;">
                            {{img_after_heading}} </legend>
                        <div class="row">
                            <div class="col-12 text-center">
                                <span *ngFor="let item of doc_after_exe ;let i =index">
                                    <button class="btn btn-outline-success"
                                        (click)="viewDefendentImage(item , 'after')">view
                                        Image{{i+1}}</button>
                                </span>
                            </div>

                        </div>
                        <hr>
                        <div class="row mt-2 justify-content-center" *ngIf="after_img">
                            <img [src]="imgURL" height="400px" width="auto" object-fit="contain" />
                        </div>
                    </fieldset>




                    <div class="modal-footer">
                        <div class="row m-auto">
                            <div class="col-12 text-center">

                                <button type="button" class="btn btn-outline-danger " data-dismiss="modal"
                                    (click)="clearImageData()">Close</button>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- VIEW WORKFLOW STATUS -->
    <div class="modal" id="workflowStatus">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">
                        <!-- Status -->Status
                    </h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <div class="modal-body">

                    <div class="container">
                        <a *ngFor="let ob of statusArr1;let i=index">
                            <!-- <h6>{{ob['status']}}</h6> -->
                            <div class="step completed" *ngIf="ob.status=='APPROVED'" style="width: 200%;">
                                <div class="v-stepper">
                                    <div class="circle"></div>
                                    <div class="barblue" style="color: blue;margin-left: 40%;"
                                        *ngIf="i <statusArr1.length-1"></div>
                                </div>

                                <div class="content">
                                    {{statusArr1.length-i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                    ({{ob.user_name}})

                                </div>
                            </div>
                            <div class="step"
                                *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'"
                                style="width: 200%;">
                                <div class="v-stepper">
                                    <div class="circles"></div>

                                </div>

                                <div class="content">
                                    {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                    ({{ob.user_name}})

                                </div>
                            </div>
                            <!-- active -->
                            <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'" style="width: 200%;">
                                <div class="v-stepper">
                                    <div class="circle"></div>
                                    <div class="bar" style="color: black;margin-left: 40%;"
                                        *ngIf="i <statusArr1.length-1">
                                    </div>
                                </div>

                                <div class="content">
                                    {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                    ({{ob.user_name}})

                                    <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                                </div>
                            </div>
                            <div class="step" *ngIf="ob.status=='PENDING'" style="width: 200%;">
                                <div class="v-stepper">
                                    <div class="circle"></div>
                                    <div class="bar" style="color: black;margin-left: 40%;"
                                        *ngIf="i <statusArr1.length-1">
                                    </div>
                                </div>

                                <div class="content">
                                    {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                    ({{ob.user_name}})

                                    <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                                </div>
                            </div>
                        </a>


                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">
                            <!-- Close -->Close
                        </button>
                        <!-- <button type="button" class="btn btn-success" data-dismiss="modal" (click)="certificate()">Download
                        Certificate </button> -->

                    </div>

                </div>
            </div>
        </div>
    </div>






</div>
