import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthenticationService } from '../../../authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AuctionAllotmentService } from '../../service/auction-allotment.service';
import { MainService } from '../../service/main.service';

@Component({
  selector: 'app-auction-allotment',
  templateUrl: './auction-allotment.component.html',
  styleUrls: ['./auction-allotment.component.css']
})
export class AuctionAllotmentComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  constructor(public auth: AuthenticationService, private spinner: NgxSpinnerService, private auctionService : AuctionAllotmentService , public mainService : MainService) { }
  dataSource: any;
  displayedColumns = ['party_id', 'arr_effective_date', 'property_code', 'measurement_unit', 'property_no', 'quota_code', 'scheme_code', 'status', 'action'];
  b_acct_id: any;
  erpUser: any;
  user_id: any
  obj: any = {}
  unique_proType: any =[]
  displayTableData: any = []
  allotmentObj : any= {}
  displayTableFlag: any = false
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.getAuctions()
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  allAuction: any = []
  async getAuctions() {
    // this.resObj = { 'lottery_date': this.currentDate() }
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'APPROVED'
    console.log(obj)
    var resp = await this.auctionService.getAuctions(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allAuction = resp['data']
      console.log(resp['data'], 'fetched data')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

 auct_propType = []
  async getAllPropType() {
    this.spinner.show()
    let obj : any= {}
    this.auct_propType = []
    this.obj['property_type'] = null
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['auction_cd'] = this.obj['auction_cd']
    console.log(obj)
    var resp = await this.auctionService.getAllPropType(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.auct_propType = resp['data']
      console.log(this.auct_propType, 'fetched offer porperty data')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  async getAllAuctionAllotment(){

  }

  export1() {}
  print1() {}

  resObj:any = {}
  async FetchDetailAuction(){
    this.spinner.show()
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['auction_cd'] = this.allotmentObj['auction_cd']
    console.log(obj)
    var resp = await this.auctionService.FetchDetailAuction(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      // this.auct_propType = resp['data']
      console.log(resp['data'], 'fetched data')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

}
