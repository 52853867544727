import { Component } from '@angular/core';
import { MainService } from '../../service/main.service';
import { ProfileService } from '../../service/profile.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from '../../../authentication.service'
import { Inject } from '@angular/core';

@Component({
  selector: '[app-property-sidebar]',
  templateUrl: './property-sidebar.component.html',
  styleUrls: ['./property-sidebar.component.css']
})
export class PropertySidebarComponent {

  user_id: any;
  imgURL: any;
  erpUser: any;
  accInfo: any;
  sidebar: any = {}
  assigned_compoment: any = []
  userInfo = { emp_name: 'Ram Prasad', designation: 'Accountant' }
  constructor(public auth: AuthenticationService, private router: Router, private spinner: NgxSpinnerService,
    private profileService: ProfileService, @Inject(DomSanitizer)  private sanitizer: DomSanitizer, public mainService: MainService) {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.user_id = this.erpUser.user_id;
    this.assigned_compoment = this.erpUser.assigned_component;
    console.log(this.assigned_compoment)

    //Inventory Start
    this.sidebar['dashboard'] = !this.assigned_compoment.includes('PROPERTY-R1')
    this.sidebar['inventory'] = !this.assigned_compoment.includes('PROPERTY-R18')
    this.sidebar['plan_layout'] = !this.assigned_compoment.includes('PROPERTY-R19')
    this.sidebar['property'] = !this.assigned_compoment.includes('PROPERTY-R14')
    this.sidebar['property_costing'] = !this.assigned_compoment.includes('PROPERTY-R20')
    this.sidebar['property_images'] = !this.assigned_compoment.includes('PROPERTY-R21')
    //Inventory End

    //Offer Start
    this.sidebar['offer'] = !this.assigned_compoment.includes('PROPERTY-R22')
    //Sale Start
    this.sidebar['sale'] = !this.assigned_compoment.includes('PROPERTY-R23')
    this.sidebar['lottery'] = !this.assigned_compoment.includes('PROPERTY-R24')
    this.sidebar['fcfs'] = !this.assigned_compoment.includes('PROPERTY-R25')
    this.sidebar['auction'] = !this.assigned_compoment.includes('PROPERTY-R26')
    //Sale End
    //Progress Strat
    this.sidebar['progress'] = !this.assigned_compoment.includes('PROPERTY-R27')
    this.sidebar['booklet_purchase'] = !this.assigned_compoment.includes('PROPERTY-R2')
    this.sidebar['offline_booklet'] = !this.assigned_compoment.includes('PROPERTY-R28')
    this.sidebar['aplicants'] = !this.assigned_compoment.includes('PROPERTY-R29')
    this.sidebar['lottery_processing'] = !this.assigned_compoment.includes('PROPERTY-R30')
    this.sidebar['aution_application'] = !this.assigned_compoment.includes('PROPERTY-R31')
    this.sidebar['auction_bid_dtl'] = !this.assigned_compoment.includes('PROPERTY-R32')
    //Progress End
    //Offer End

    //service Start
    this.sidebar['service'] = !this.assigned_compoment.includes('PROPERTY-R33')
    this.sidebar['allotment'] = !this.assigned_compoment.includes('PROPERTY-R34')
    this.sidebar['allotment_preporation'] = !this.assigned_compoment.includes('PROPERTY-R35')
    //service End


    //Advanced Services
    this.sidebar['advanced_services'] = !this.assigned_compoment.includes('PROPERTY-R36')
    this.sidebar['default_party'] = !this.assigned_compoment.includes('PROPERTY-R37')
    this.sidebar['property_cancilation'] = !this.assigned_compoment.includes('PROPERTY-R38')
    this.sidebar['restoration'] = !this.assigned_compoment.includes('PROPERTY-R8')
    this.sidebar['procession'] = !this.assigned_compoment.includes('PROPERTY-R39')
    this.sidebar['nominee'] = !this.assigned_compoment.includes('PROPERTY-R40')
    this.sidebar['refund_request'] = !this.assigned_compoment.includes('PROPERTY-R41')
    //Advanced End



    //Mutation Start
    this.sidebar['mutation'] = !this.assigned_compoment.includes('PROPERTY-R42')
    this.sidebar['mut_appication'] = !this.assigned_compoment.includes('PROPERTY-R43')
    //Mutation End


    //Property Report Start
    this.sidebar['pro_report'] = !this.assigned_compoment.includes('PROPERTY-R44')
    this.sidebar['general_ladger'] = !this.assigned_compoment.includes('PROPERTY-R45')
    //Property Report End




    //Administration Start
    this.sidebar['administration'] = !this.assigned_compoment.includes('PROPERTY-R46')
    //Master data Start
    this.sidebar['master'] = !this.assigned_compoment.includes('PROPERTY-R9')
    this.sidebar['code_value'] = !this.assigned_compoment.includes('PROPERTY-R3')
    this.sidebar['field'] = !this.assigned_compoment.includes('PROPERTY-R5')
    this.sidebar['hierarchy'] = !this.assigned_compoment.includes('PROPERTY-R7')
    this.sidebar['location_hierarchy'] = !this.assigned_compoment.includes('PROPERTY-R7')
    this.sidebar['location'] = !this.assigned_compoment.includes('PROPERTY-R12')
    this.sidebar['payment_option'] = !this.assigned_compoment.includes('PROPERTY-R11')
    this.sidebar['default_method'] = !this.assigned_compoment.includes('PROPERTY-R13')
    this.sidebar['auction_bid_rule'] = !this.assigned_compoment.includes('PROPERTY-R15')
    this.sidebar['property_feature'] = !this.assigned_compoment.includes('PROPERTY-R16')
    this.sidebar['slip_header_config'] = !this.assigned_compoment.includes('PROPERTY-R17')
    this.sidebar['term-and-cond'] = !this.assigned_compoment.includes('PROPERTY-R52')
    this.sidebar['possesion_rule'] = !this.assigned_compoment.includes('PROPERTY-R54')
    this.sidebar['mutation_reule'] = !this.assigned_compoment.includes('PROPERTY-R55')
    //Master data End

    //Security Start
    this.sidebar['security'] = !this.assigned_compoment.includes('PROPERTY-R47')
    this.sidebar['role'] = !this.assigned_compoment.includes('PROPERTY-R48')
    this.sidebar['user'] = !this.assigned_compoment.includes('PROPERTY-R49')
    this.sidebar['data_auth'] = !this.assigned_compoment.includes('PROPERTY-R50')
    this.sidebar['workflow'] = !this.assigned_compoment.includes('PROPERTY-R51')
    this.sidebar['pub-portal-config'] = !this.assigned_compoment.includes('PROPERTY-R53')
    //Security End

    //Administration End




  }
  hidden = true;
  async ngOnInit() {

    if (this.erpUser.is_admin == 0) {
      this.hidden = true;
    } else {
      this.hidden = false
    }
    this.imgURL = './assets/img/admin-avatar.png';
    await this.getUserImage();
    // await this.getUserInfo();
    await this.getAccImage();
    // await this.getAccInfo();
  }

  home() {
    this.router.navigate(['/index'])
  }





  async getUserImage() {
    const res = await this.profileService.getImage(this.user_id);
    if (res) {
      const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.mainService.profileImageUrl = this.imgURL;

    }
  }
  async getUserInfo() {
    this.spinner.show()
    var resp = await this.profileService.getUserProfileInfo(this.user_id);
    if (resp['error'] == false) {
      this.spinner.hide()

      this.userInfo = resp.data[0];
    } else {

      this.spinner.hide()
    }
  }
  async getAccImage() {
    const res = await this.profileService.getAccImage(this.erpUser.b_acct_id);
    if (res) {
      const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.mainService.accountImageUrl = this.imgURL;

    }
  }
  async getAccInfo() {
    this.spinner.show()
    var resp = await this.profileService.getAccProfileInfo(this.erpUser.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.accInfo = resp.data[0];
      this.mainService.accInfo = this.accInfo;
    } else {
      this.spinner.hide()

    }

  }

  refreshCol() {
    var col = document.getElementsByClassName('collapse')

    for (let i = 0; i <= col.length; i++) {
      if (col[i].classList.contains('show')) {
        col[i].classList.remove('show');
      }
    }

  }

  refreshCol2(){
  var col = document.getElementsByClassName('collapse2')
  for (let i = 0; i <= col.length; i++) {
    if (col[i].classList.contains('show')) {
      col[i].classList.remove('show');
    }
  }
  }
}


