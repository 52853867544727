<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">MANAGE Sub-Scheme</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" (click)="refressadd()" data-toggle="tab"><i
                                    class="ti-bar-chart"></i> All
                                Sub-scheme List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refressadd()"><i
                                    class="fa fa-plus-circle"></i> Create
                                Sub-Scheme</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refressadd()"><i
                                    class="ti-settings"></i> Update
                                Sub-scheme</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab" (click)="refressadd()"><i
                                    class="ti-settings"></i> Update
                                File</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>


                            <div class=" table-container ">
                                <div class="row">
                                    <div class="col-4 text-right">
                                        Select Scheme :
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="schemeArr" bindLabel="scheme_name" bindValue="scheme_code" (change)="getAllsubSchemes()" [multiple]="false" placeholder="" [(ngModel)]="selectedSchemeCode" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                            name="field_name">
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4 text-center">

                                    </div>

                                </div>

                                <div class="example-container">
                                    <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                                        <ng-container matColumnDef="scheme_code">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Scheme Code</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.scheme_code}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="sub_scheme_code">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Subscheme Code</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.sub_scheme_code }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="sub_scheme_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Subscheme Name</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.sub_scheme_name }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="mode_of_allotement">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>Mode
                                                    Of Allotement</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.mode_of_allotement }} </td>
                                        </ng-container>


                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                                <button class="btn btn-outline-primary" (click)="open_update(element,i)">Update</button>

                                                <button class="btn btn-outline-info" (click)="file_open_update(element,i)">Update File</button>
                                            </td>

                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Select Scheme :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="schemeArr" bindLabel="scheme_name" bindValue="scheme_code" [multiple]="false" placeholder="" [(ngModel)]="obj['scheme_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Subscheme Code :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="text" [(ngModel)]="obj['sub_scheme_code']" name="sub_scheme_code" placeholder="Subscheme Code">

                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-3 text-right">
                                    <h6> Subscheme Name :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="sub_scheme_name" [(ngModel)]="obj['sub_scheme_name']" placeholder="Subscheme Name">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Sector Name :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="sector" [(ngModel)]="obj['sector']" placeholder="Sector Name">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> City :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="street" [(ngModel)]="obj['city']" placeholder="City">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Locality :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="locality" [(ngModel)]="obj['locality']" placeholder="Locality">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> District :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="district" [(ngModel)]="obj['district']" placeholder="District">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> State :</h6>
                                </div>
                                <div class="col-3">
                                    <!-- <input class="form-control" name="state" [(ngModel)]="obj['state']" placeholder="State"> -->
                                    <ng-select [items]="mainService.codeValueTechObj['PROP0004']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="State" [(ngModel)]="obj['state']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="state">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Landmark :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="landmark" [(ngModel)]="obj['landmark']" placeholder="Landmark">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Booklet Amount :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="booklet" [(ngModel)]="obj['booklet_amount']" placeholder="Booklet Amount ">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Mode Of Allotement :</h6>
                                </div>
                                <div class="col-3">



                                    <ng-select [items]="mainService.codeValueTechObj['PROP0009']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Mode Of Allotement" [(ngModel)]="obj['mode_of_allotement']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="mode_of_allotement">
                                    </ng-select>

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Booklet Purchase Start Date :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" name="booklet_purchase_start_date" [(ngModel)]="obj['booklet_purchase_start_date']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Booklet Purchase End Date :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" name="booklet_purchase_end_date" [(ngModel)]="obj['booklet_purchase_end_date']">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Application Start Date :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" name="application_start_date" [(ngModel)]="obj['application_start_date']">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Application End Date :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" name="application_end_date" [(ngModel)]="obj['application_end_date']">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Allotment Start Date :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" name="allotment_start_date" [(ngModel)]="obj['allotment_start_date']">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Sub Scheme Status :</h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="statusArr" bindLabel="value" bindValue="code" [multiple]="true" placeholder="Status" [(ngModel)]="obj['sub_scheme_status']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="scheme_status">
                                    </ng-select>
                                </div>
                                <div class="col-2 text-right">
                                    <h6> Select PDF :</h6>

                                </div>
                                <div class="col-3 text-center">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader" class="form-control" style="border-color: black" (change)="onFileUpload($event,file.files)">
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Booklet Purchase Bank Name :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="mainService.codeValueTechObj['PROP0005']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Booklet Purchase Bank Name" [(ngModel)]="obj['booklet_purchase_bank_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="booklet_purchase_bank_code">
                                    </ng-select>
                                </div>
                                <div class="col-2 text-right">
                                    <h6> Booklet Purchase Bank Branch Name :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="mainService.codeValueTechObj['PROP0010']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Booklet Purchase Bank Branch Name" [(ngModel)]="obj['booklet_purchase_branch_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="booklet_purchase_branch_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Booklet Purchase Account No :</h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="mainService.codeValueTechObj['PROP0007']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Booklet Purchase Account No " [(ngModel)]="obj['booklet_purchase_acct_no']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="booklet_purchase_acct_no">
                                    </ng-select>
                                </div>
                                <div class="col-2 text-right">
                                    <h6> Booklet Purchase IFSC Code :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="mainService.codeValueTechObj['PROP0008']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Booklet Purchase IFSC Code" [(ngModel)]="obj['booklet_purchase_ifsc_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="booklet_purchase_ifsc_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Application Bank Name :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="mainService.codeValueTechObj['PROP0005']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Application Bank Name" [(ngModel)]="obj['application_bank_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="application_bank_code">
                                    </ng-select>
                                </div>
                                <div class="col-2 text-right">
                                    <h6> Application Bank Branch Name :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="mainService.codeValueTechObj['PROP0010']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Application Bank Branch Name" [(ngModel)]="obj['application_branch_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="application_branch_code">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Application Account No :</h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="mainService.codeValueTechObj['PROP0007']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Application Account No" [(ngModel)]="obj['application_acct_no']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="application_acct_no">
                                    </ng-select>
                                </div>
                                <div class="col-2 text-right">
                                    <h6> Application IFSC Code :</h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="mainService.codeValueTechObj['PROP0008']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Application IFSC Code" [(ngModel)]="obj['application_ifsc_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="application_ifsc_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">

                                    <button class="btn btn-primary" [disabled]="!uploader.getNotUploadedItems().length" (click)="save()">Save</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Select Scheme :</h6>
                                </div>
                                <div class="col-3">


                                    <!-- <ng-select [items]="schemeArr" bindLabel="scheme_name" bindValue="scheme_code"
                                        [multiple]="false" placeholder="" [(ngModel)]="obj['scheme_code']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name" >
                                    </ng-select> -->
                                    <h6> {{schemeObject[obj['scheme_code']]}}</h6>


                                </div>
                                <div class="col-2 text-right">
                                    <h6> Subscheme Code :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="text" [(ngModel)]="obj['sub_scheme_code']" name="sub_scheme_code" placeholder="Subscheme Code" disabled>

                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-3 text-right">
                                    <h6> Subscheme Name :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="sub_scheme_name" [(ngModel)]="obj['sub_scheme_name']" placeholder="Subscheme Name">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Sector Name :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="sector" [(ngModel)]="obj['sector']" placeholder="Sector Name">

                                </div>
                            </div>
                            <br>


                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> City :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="street" [(ngModel)]="obj['city']" placeholder="City">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Locality :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="locality" [(ngModel)]="obj['locality']" placeholder="Locality">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> District :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="district" [(ngModel)]="obj['district']" placeholder="District">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> State :</h6>
                                </div>
                                <div class="col-3">
                                    <!-- <input class="form-control" name="state" [(ngModel)]="obj['state']" placeholder="State"> -->
                                    <ng-select [items]="mainService.codeValueTechObj['PROP0004']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="State" [(ngModel)]="obj['state']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="state">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Landmark :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="landmark" [(ngModel)]="obj['landmark']" placeholder="Landmark">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Booklet Amount :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" name="booklet" [(ngModel)]="obj['booklet_amount']" placeholder="Booklet Amount ">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Mode Of Allotement :</h6>
                                </div>
                                <div class="col-3">



                                    <ng-select [items]="mainService.codeValueTechObj['PROP0009']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Mode Of Allotement" [(ngModel)]="obj['mode_of_allotement']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="mode_of_allotement">
                                    </ng-select>

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Booklet Purchase Start Date :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" name="booklet_purchase_start_date" [(ngModel)]="obj['booklet_purchase_start_date']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Booklet Purchase End Date :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" name="booklet_purchase_end_date" [(ngModel)]="obj['booklet_purchase_end_date']">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Application Start Date :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" name="application_start_date" [(ngModel)]="obj['application_start_date']">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Application End Date :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" name="application_end_date" [(ngModel)]="obj['application_end_date']">

                                </div>
                                <div class="col-2 text-right">
                                    <h6> Allotment Start Date :</h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" type="date" name="allotment_start_date" [(ngModel)]="obj['allotment_start_date']">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Sub Scheme Status :</h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="statusArr" bindLabel="value" bindValue="code" [multiple]="true" placeholder="Status" [(ngModel)]="obj['sub_scheme_status']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="scheme_status">
                                    </ng-select>
                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Booklet Purchase Bank Name :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="mainService.codeValueTechObj['PROP0005']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Booklet Purchase Bank Name" [(ngModel)]="obj['booklet_purchase_bank_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="booklet_purchase_bank_code">
                                    </ng-select>
                                </div>
                                <div class="col-2 text-right">
                                    <h6> Booklet Purchase Bank Branch Name :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="mainService.codeValueTechObj['PROP0010']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Booklet Purchase Bank Branch Name" [(ngModel)]="obj['booklet_purchase_branch_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="booklet_purchase_branch_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Booklet Purchase Account No :</h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="mainService.codeValueTechObj['PROP0007']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Booklet Purchase Account No" [(ngModel)]="obj['booklet_purchase_acct_no']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="booklet_purchase_acct_no">
                                    </ng-select>
                                </div>
                                <div class="col-2 text-right">
                                    <h6> Booklet Purchase IFSC Code :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="mainService.codeValueTechObj['PROP0008']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Booklet Purchase IFSC Code" [(ngModel)]="obj['booklet_purchase_ifsc_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="booklet_purchase_ifsc_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Application Bank Name :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="mainService.codeValueTechObj['PROP0005']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Application Bank Name" [(ngModel)]="obj['application_bank_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="application_bank_code">
                                    </ng-select>
                                </div>
                                <div class="col-2 text-right">
                                    <h6> Application Bank Branch Name :</h6>
                                </div>
                                <div class="col-3">


                                    <ng-select [items]="mainService.codeValueTechObj['PROP0010']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Application Bank Branch Name" [(ngModel)]="obj['application_branch_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="application_branch_code">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Application Account No :</h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="mainService.codeValueTechObj['PROP0007']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Application Account No" [(ngModel)]="obj['application_acct_no']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="application_acct_no">
                                    </ng-select>
                                </div>
                                <div class="col-2 text-right">
                                    <h6> Application IFSC Code :</h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="mainService.codeValueTechObj['PROP0008']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Application IFSC Code" [(ngModel)]="obj['application_ifsc_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="application_ifsc_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">

                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> Select Scheme :</h6>
                                </div>
                                <div class="col-4">


                                    <!-- <ng-select [items]="schemeArr" bindLabel="scheme_name" bindValue="scheme_code"
                                        [multiple]="false" placeholder="" [(ngModel)]="obj['scheme_code']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name" >
                                    </ng-select> -->
                                    <h6> {{schemeObject[obj['scheme_code']]}}</h6>


                                </div>

                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6> Subscheme Name :</h6>
                                </div>
                                <div class="col-4">

                                    <h6> {{obj['sub_scheme_code']}}</h6>
                                </div>

                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6> Select PDF :</h6>

                                </div>
                                <div class="col-4 text-center">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader" class="form-control" style="border-color: black" (change)="onFileUpload($event,file.files)">
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">

                                    <button class="btn btn-primary" [disabled]="!uploader.getNotUploadedItems().length" (click)="UpdateFile()">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
