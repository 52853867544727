import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { AllEmpService } from '../../service/all-emp.service';
import swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { DataAccessService } from '../../service/data-access.service';
import { HttpClient } from '@angular/common/http';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


declare var $: any
@Component({
  selector: 'app-nominee-info',
  templateUrl: './nominee-info.component.html',
  styleUrls: ['./nominee-info.component.css']
})
export class NomineeInfoComponent implements OnInit {


  constructor(private http: HttpClient, private dataAccessService: DataAccessService, public mainService: MainService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private allEmpService: AllEmpService) { }
  personalInfoObj: any = {};
  errorMsg: any = ''
  codeValueTechObj: any = {};

  erpUser: any;
  // allEmplyees
  user_id: any;
  b_acct_id: any;
  allEmployees: any = [];
  selectEmpObj: any = {};
  selectedEmpNominee: any = [];
  nomineeObj: any = {};
  //  le_id;
  nomineeArr: any = [];
  allEmplyees_new: any = []
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }

  displayedColumns :any= ['nom_id', 'nom_name', 'nom_email', 'nom_phone_no', 'nominee_relation_code', 'nom_share', 'nom_bank_name', 'nom_branch_name', 'nom_ifsc_code', 'nom_bank_acct_no', 'action'];
  datasource:any;

  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.dataAccessService.getAssignedEmpInfo1(this.b_acct_id, this.user_id, true);
    await this.getAllEmployees();
    // this.selectEmpObj['le_id'] = this.erpUser.user_id;
    await this.changeEmployee();
  }
  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }
  // async getAllEmployees1() {
  //   var arr = []
  //   this.spinner.show()
  //   var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['emp_status'] = 'ACTIVE';
  //   var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     arr = resp.data;
  //     this.allEmployees = []
  //     console.log(arr)
  //     var assigned_emp = this.dataAccessService.assignedEmp;

  //     for (let i = 0; i < arr.length; i++) {
  //       var obj:any = new Object();
  //       obj = Object.assign({}, arr[i]);
  //       obj['tempid'] = this.getNumberFormat(obj['emp_id'])
  //       if (assigned_emp[arr[i]['arr_id']] != undefined) {
  //         this.allEmployees.push(obj)
  //       }
  //     }
  //     this.allEmplyees_new = [];
  //     for (let i = 0; i < this.allEmployees.length; i++) {
  //       var obj:any = new Object();
  //       obj = Object.assign({}, this.allEmployees[i]);
  //       obj['emp_name'] = this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
  //       this.allEmplyees_new.push(obj)
  //     }
  //   } else {
  //     this.spinner.hide()
  //     this.snackBar.open("Error while getting employee list", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }
  async getAllEmployees() {
    this.spinner.show()
    var arr = []
    this.allEmployees = []
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';

    var resp = await this.allEmpService.getAllPartyFields(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      this.allEmployees = [];
      var assignedEmp = this.dataAccessService.assignedEmp2;
      console.log(assignedEmp)
      for (let i = 0; i < arr.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = this.getNumberFormat(obj['emp_id']);
        if (assignedEmp[arr[i]['arr_id']] != undefined) {
          this.allEmployees.push(obj)
        }
      }

      this.allEmplyees_new = [];
      for (let i = 0; i < this.allEmployees.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, this.allEmployees[i]);
        obj['emp_name'] = this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.allEmplyees_new.push(obj)
      }

    }
  }
  list_flag :any= true;
  create_flag:any = false;
  update_flag :any= false;
  open_list() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {

    if (this.selectEmpObj['le_id'] == null || this.selectEmpObj['le_id'] == undefined || this.selectEmpObj['le_id'] == "") {
      swal.fire('Warning', "Please select at least one employee", 'warning')
      return;
    }

    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
    this.spanAvi = false;
    this.refrace();

  }

  selectedEmpName = "";
  async changeEmployee() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    for (let i = 0; i < this.allEmplyees_new.length; i++) {
      if (this.allEmplyees_new[i]['le_id'] == this.selectEmpObj['le_id']) {
        obj['le_id'] = this.allEmplyees_new[i]['le_id']
      }
    }
    console.log(obj);
    this.spinner.show();
    this.selectedEmpName = this.allEmployees['emp_name']
    console.log("thgis is sel--", this.selectedEmpName);
    var resp = await this.allEmpService.getNominee(obj);
    this.selectedEmpNominee = [];

    if (resp['error'] == false) {
      this.spinner.hide()
      if (resp.data.length > 0) {
        this.selectedEmpNominee = resp.data;
        this.datasource = new MatTableDataSource(this.selectedEmpNominee)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort
      }
      else {
        this.datasource = new MatTableDataSource(this.selectedEmpNominee)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort
      }

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  openUpdate(element:any) {
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    this.spanAvi = true;
    this.errorMsg = ''
    $('.nav-tabs a[href="#tab-3"]').tab('show');
    this.nomineeObj = Object.assign({}, element);
    console.log("this si update button select button ", this.selectEmpObj['le_id'], this.selectedEmpName);

  }
  //   async checkIFSC(bankIfsc:any){
  //     // let bankIfsc = this.nomineeObj['nom_ifsc_code'];
  //     console.log("this is enter", bankIfsc);
  //  let resp=this.http.get('https://ifsc.razorpay.com/'+bankIfsc).subscribe(data=>{

  //    this.nomineeObj['nom_bank_name'] = data['BANK'];
  //    this.nomineeObj['nom_branch_name'] = data['BRANCH'];
  //  })
  //  console.log("this si the length of hte key ",resp);
  //  }
  ifc :any= []
  async checkIFSC(bankIfsc: any) {
    // let bankIfsc = this.nomineeObj['nom_ifsc_code'];
    console.log("this is enter", bankIfsc);
    this.ifc = [];
    let resp :any= await this.http.get('https://ifsc.razorpay.com/' + bankIfsc).subscribe((data:any) => {

      //  this.nomineeObj['nom_bank_name'] = data['BANK'];
      //  this.nomineeObj['nom_branch_name'] = data['BRANCH'];
      //  console.log("this si the length of hte key ",data);
      if (Object.values(data).length == 17) {

        this.ifc.push({
          bank: data['BANK'],
          branck: data['BRANCH']
        })
        this.nomineeObj['nom_bank_name'] = data['BANK'];
        this.nomineeObj['nom_branch_name'] = data['BRANCH'];
      }

    })

    console.log("this is hte resp 1 ", this.ifc)
    console.log("this si the length of hte key12", this.ifc);
    if (this.ifc.length == 1) {
      this.nomineeObj['nom_bank_name'] = this.ifc[0]['bank'];
      this.nomineeObj['nom_branch_name'] = this.ifc[0]['branch'];
    }
    else {
      this.nomineeObj['nom_bank_name'] = "";
      this.nomineeObj['nom_branch_name'] = "";
    }
  }

  async submitNomineeInfo() {
    this.getNominee();
    let nameRegx = /[A-Za-z]$/;
    let mobRegx = /^[0-9]{10}$/;
    let emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let accNoRegx = /[0-9]{9,18}$/;
    this.errorMsg = ''
    if (
      this.nomineeObj['nom_name'] == "" || this.nomineeObj['nom_name'] == undefined
      || this.nomineeObj['nom_email'] == "" || this.nomineeObj['nom_email'] == undefined
      || this.nomineeObj['nom_phone_no'] == null || this.nomineeObj['nom_phone_no'] == undefined
      || this.nomineeObj['nom_bank_name'] == "" || this.nomineeObj['nom_bank_name'] == undefined
      || this.nomineeObj['nom_branch_name'] == "" || this.nomineeObj['nom_branch_name'] == undefined
      || this.nomineeObj['nom_ifsc_code'] == "" || this.nomineeObj['nom_ifsc_code'] == undefined
      || this.nomineeObj['nom_bank_acct_no'] == "" || this.nomineeObj['nom_bank_acct_no'] == undefined
      || this.nomineeObj['nom_share'] == null || this.nomineeObj['nom_share'] == undefined
      || this.selectEmpObj['le_id'] == null || this.selectEmpObj['le_id'] == undefined || this.selectEmpObj['le_id'] == ""
      || this.nomineeObj['nominee_relation_code'] == null || this.nomineeObj['nominee_relation_code'] == undefined || this.nomineeObj['nominee_relation_code'] == ""
    ) {

      swal.fire("Warning !", " * Fields Required ")
      // this.errorMsg = "* fields required."
    }
    else if (!nameRegx.test((this.nomineeObj['nom_name']))) {
      swal.fire("Error", 'Nominee Name in Alphabetic Form', 'error')
      return
    }
    else if (!emailRegx.test((this.nomineeObj['nom_email']))) {
      swal.fire("Error", 'Email is not Correct Format', 'error')
      return
    }
    else if (!mobRegx.test((this.nomineeObj['nom_phone_no']))) {
      swal.fire("Error", 'Mobile Number not in 10 digit', 'error')
      return
    }
    else if (!accNoRegx.test((this.nomineeObj['nom_bank_acct_no']))) {
      swal.fire("Error", 'Bank Account Number 9 to 18 digit format', 'error')
      return
    }
    else if (this.nomineeObj['nom_bank_acct_no'] >= 0 && this.nomineeObj['nom_bank_acct_no'] <= 100) {
      swal.fire("Error", 'Nominee Share must be 0 to 100% ', 'error')
      return
    }
    // else if (this.nomineeObj['nom_phone_no'].toString().length != 10) {

    //   this.errorMsg = "Incorrect Phone Number."
    // }
    else {

      swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.value) {
          this.finalsubmitNomineeInfo()
        }
      })


    }
  }

  async finalsubmitNomineeInfo() {
    this.nomineeObj['b_acct_id'] = this.b_acct_id;
    this.nomineeObj['create_user_id'] = this.user_id;
    for (let i = 0; i < this.allEmplyees_new.length; i++) {
      if (this.allEmplyees_new[i]['le_id'] == this.selectEmpObj['le_id']) {
        this.nomineeObj['le_id'] = this.allEmplyees_new[i]['le_id']
      }
    }

    //console.log(this.nomineeObj);
    this.spinner.show();
    var resp = await this.allEmpService.addNominee(this.nomineeObj);
    if (resp['error'] == false) {
      this.nomineeObj = {}
      this.spanAvi = false
      this.spinner.hide();
      await this.changeEmployee();

      swal.fire("Success", "Nominee Info Added", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Sorry", "...Some Error Occured!", 'error');

    }
  }

  async updateNomineeInfo() {
    this.getNomineeUpdate();
    let nameRegx = /[A-Za-z]$/;
    let mobRegx = /^[0-9]{10}$/;
    let emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let accNoRegx = /[0-9]{9,18}$/;
    this.errorMsg = ''
    if (
      this.nomineeObj['nom_name'] == "" || this.nomineeObj['nom_name'] == undefined
      || this.nomineeObj['nom_email'] == "" || this.nomineeObj['nom_email'] == undefined
      || this.nomineeObj['nom_phone_no'] == null || this.nomineeObj['nom_phone_no'] == undefined
      || this.nomineeObj['nom_bank_name'] == "" || this.nomineeObj['nom_bank_name'] == undefined
      || this.nomineeObj['nom_branch_name'] == "" || this.nomineeObj['nom_branch_name'] == undefined
      || this.nomineeObj['nom_ifsc_code'] == "" || this.nomineeObj['nom_ifsc_code'] == undefined
      || this.nomineeObj['nom_bank_acct_no'] == "" || this.nomineeObj['nom_bank_acct_no'] == undefined
      || this.nomineeObj['nom_share'] == null || this.nomineeObj['nom_share'] == undefined
      || this.nomineeObj['nominee_relation_code'] == null || this.nomineeObj['nominee_relation_code'] == undefined || this.nomineeObj['nominee_relation_code'] == ""

    ) {

      this.errorMsg = "* fields required."
      swal.fire("Warning !", "* Field Require");
    }
    else if (!nameRegx.test((this.nomineeObj['nom_name']))) {
      swal.fire("Error", 'Nominee Name in Alphabetic Form', 'error')
      return
    }
    else if (!emailRegx.test((this.nomineeObj['nom_email']))) {
      swal.fire("Error", 'Email is not Correct Format', 'error')
      return
    }
    else if (!mobRegx.test((this.nomineeObj['nom_phone_no']))) {
      swal.fire("Error", 'Mobile Number not in 10 digit', 'error')
      return
    }
    else if (!accNoRegx.test((this.nomineeObj['nom_bank_acct_no']))) {
      swal.fire("Error", 'Bank Account Number 9 to 18 digit format', 'error')
      return
    }
    // else if (this.nomineeObj['nom_phone_no'].toString().length != 10) {

    //   this.errorMsg = "Incorrect Phone Number."
    // }
    else {

      swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Update it!'
      }).then((result) => {
        if (result.value) {
          this.finalupdateNomineeInfo()
        }
      })


    }
  }
  async finalupdateNomineeInfo() {
    this.nomineeObj['b_acct_id'] = this.b_acct_id;
    this.nomineeObj['update_user_id'] = this.user_id;

    this.spinner.show();
    var resp = await this.allEmpService.updateNominee(this.nomineeObj);
    if (resp['error'] == false) {
      this.nomineeObj = {}
      this.spinner.hide();
      this.list_flag = true;
      this.create_flag = false;
      this.update_flag = false;
      this.spanAvi = false;
      await this.changeEmployee();
      swal.fire("Success", "Nominee Info Updated", 'success');


    } else {
      this.spinner.hide();
      swal.fire("Sorry", "...Some Error Occured!", 'error');

    }
  }

  async eduDelete(element:any) {
    swal.fire({
      title: 'Are you Sure?',
      // text:'Do YOu Want To Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.deleteEducation(element)
      }
    })

  }






  async deleteEducation(element:any) {
    var obj:any = new Object();

    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = element['id'];
    obj['le_id'] = element['le_id']
    obj['nom_seq_num'] = element['nom_seq_num']
    console.log(obj['nom_seq_num']);


    this.spinner.show();
    var resp = await this.allEmpService.deleteNominee((obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.changeEmployee();

      swal.fire("Success", "Nominee Info Deleted", 'success');

    } else {
      this.spinner.hide();

      swal.fire("Sorry", "Error while deleting Nominee Info Of Employee", 'error');

    }
  }
  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  chamgeTab() {
    this.errorMsg = ''
    this.nomineeObj = {}
  }

  async getNominee() {
    let sum = 0;
    this.nomineeObj['b_acct_id'] = this.b_acct_id;
    this.nomineeObj['create_user_id'] = this.user_id;
    for (let i = 0; i < this.allEmplyees_new.length; i++) {
      if (this.allEmplyees_new[i]['le_id'] == this.selectEmpObj['le_id']) {
        this.nomineeObj['le_id'] = this.allEmplyees_new[i]['le_id']
      }
    }

    var resp = await this.allEmpService.getNominee(this.nomineeObj);
    if (resp['error'] == false) {
      this.nomineeArr = resp.data;
      if (this.nomineeArr.length != 0) {
        for (let i = 0; i < this.nomineeArr.length; i++) {
          sum = sum + this.nomineeArr[i]['nom_share'];
        }
        if (sum != 100 || sum < 100) {
          if (sum < this.nomineeObj['nom_share']) {
            swal.fire("Sorry", "...you have only remaning " + (100 - sum) + "% share", 'error');
          }
          else if (this.nomineeObj['nom_share'] <= (100 - sum)) {

          }

          else {
            swal.fire("Sorry", "...you are trying to consume more than 100 % share", 'error');
          }
        }
        else {
          swal.fire("Sorry", "...you have used 100 % share", 'error');
        }
      }
    }
  }

  async getNomineeUpdate() {
    let sum = 0;
    this.nomineeObj['b_acct_id'] = this.b_acct_id;
    this.nomineeObj['create_user_id'] = this.user_id;
    for (let i = 0; i < this.allEmplyees_new.length; i++) {
      if (this.allEmplyees_new[i]['le_id'] == this.selectEmpObj['le_id']) {
        this.nomineeObj['le_id'] = this.allEmplyees_new[i]['le_id']
      }
    }

    var resp = await this.allEmpService.getNominee(this.nomineeObj);
    if (resp['error'] == false) {
      this.nomineeArr = resp.data;
      if (this.nomineeArr.length != 0) {
        for (let i = 0; i < this.nomineeArr.length; i++) {
          if (this.nomineeObj['id'] != this.nomineeArr['id']) {
            sum = sum + this.nomineeArr[i]['nom_share'];
          }
        }
        var remShare = (100 - sum);
        if (sum != 100 || sum < 100) {
          if (sum < this.nomineeObj['nom_share']) {
            swal.fire("Sorry", "...you have only remaning " + (100 - sum) + "% share", 'error');
          }
          else if (this.nomineeObj['nom_share'] <= (100 - sum)) {

          }

          else {
            swal.fire("Sorry", "...you are trying to consume more than 100 % share", 'error');
          }
        }
        else {
          swal.fire("Sorry", "...you have used 100 % share", 'error');
        }
      }
    }
  }




  spanAvi: boolean = false;

  checkEmail(obj: any) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (obj.match(mailformat)) {

      let d = obj.split('.');

      if ((d != undefined || d != null) && (d[(d.length) - 1] == 'com')) {
        this.spanAvi = true
      }
    }
    else {
      this.spanAvi = false
    }
  }
  //   {
  // let c=0;
  //  let e=0;
  //     let d=obj.split('.');

  //     if((d!=undefined || d!=null)&&( d[(d.length)-1]=='com'))
  //     {
  //       let position= d[0].substring(d[0].length-6);
  //       if(position==="@gmail")
  //       {
  //      e=2;
  //     }
  //     }
  //   let b=obj.split('');
  //     b.map((x)=>{
  //       if(x=="." || x=="@")
  //         {
  //         ++c;
  //         }
  //           });

  //           if(c==2 && e==2)
  //           {

  //             this.spanAvi=true;
  //           }

  //           else{


  //             this.spanAvi=false;
  //           }

  //   }

  refrace() {
    this.nomineeObj['nom_name'] = "";
    this.nomineeObj['nom_email'] = "";
    this.nomineeObj['nom_phone_no'] = "";
    this.nomineeObj['nom_ifsc_code'] = "";
    this.nomineeObj['nom_bank_name'] = "";
    this.nomineeObj['nom_branch_name'] = "";
    this.nomineeObj['nom_bank_acct_no'] = "";
    this.nomineeObj['nom_share'] = "";
    this.nomineeObj['nominee_relation_code'] = "";
  }


}
