import { Component } from '@angular/core';
import { MainService } from '../../service/main.service';
import { ProfileService } from '../../../portal/service/profile.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '../../../authentication.service';

@Component({
  selector: '[app-hrms-sidebar]',
  templateUrl: './hrms-sidebar.component.html',
  styleUrls: ['./hrms-sidebar.component.css']
})
export class HrmsSidebarComponent {
  user_id:any ;
  imgURL:any ;
  erpUser:any ;
  accInfo:any ;
  userInfo:any  = { emp_name: 'Ram Prasad', designation: 'Accountant' }
  // sideBarFlag={'HR-R1':true,'HR-R2':true,'EMB-R3':true,'EBILL-R4':true,'DEVIATION-R5':true,'Completion-R6':true,
  // 'TERM-R7':true,'CODE-VALUE-R8':true,'Vendors-R9':true,'Deduction-R12':true,'Geometry-R13':true,'Budget-R19':true,'Work-R11':true,
  // 'DEFAPPR-R21':true,'Users-R14':true,'undertaking':true,'Roles-R15':true,'EMBHIER-R22':true,'Authorization-R16':true
  // ,'sor-R24':true,"morth-R26":true,"dsr-R25":true}
  sideBarFlag:any ={'HR-R1':true,'HR-R2':true,'HR-R3':true,'HR-R4':true,'HR-R5':true,'HR-R6':true,'HR-R7':true,'HR-R8':true}
  constructor(private router: Router, public auth: AuthenticationService, private profileService: ProfileService, private sanitizer: DomSanitizer, public mainService: MainService) { }
  hidden :any = true;
  report_hidden :any = true;
  dashborad_hidden:any  = true;
  party_hidden:any  = true;
  arr_hidden :any = true;
  leave_hidden :any = true;
  payroll_hidden:any  = true;
  setting_hidden :any = true;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.sideBarFlag['HR-R1']=!this.erpUser['assigned_component'].includes('HR-R1')
    this.sideBarFlag['HR-R2']=!this.erpUser['assigned_component'].includes('HR-R2')
    this.sideBarFlag['HR-R3']=!this.erpUser['assigned_component'].includes('HR-R3')
    this.sideBarFlag['HR-R4']=!this.erpUser['assigned_component'].includes('HR-R4')
    this.sideBarFlag['HR-R5']=!this.erpUser['assigned_component'].includes('HR-R5')
    this.sideBarFlag['HR-R6']=!this.erpUser['assigned_component'].includes('HR-R6')
    this.sideBarFlag['HR-R7']=!this.erpUser['assigned_component'].includes('HR-R7')
    this.sideBarFlag['HR-R8']=!this.erpUser['assigned_component'].includes('HR-R8')
    var component = this.erpUser['assigned_component'];
    for (let i = 0; i < component.length; i++) {

      if (component[i] == 'HR-R1') {
        this.dashborad_hidden = false
      }
      if (component[i] == 'HR-R2') {
        this.party_hidden = false
      }
      if (component[i] == 'HR-R3') {
        this.payroll_hidden = false
      }
      if (component[i] == 'HR-R4') {
        this.leave_hidden = false
      }
      if (component[i] == 'HR-R5') {
        this.report_hidden = false
      }
      if (component[i] == 'HR-R6') {
        this.setting_hidden = false
      }
      if (component[i] == 'HR-R7') {
        this.arr_hidden = false
      }

    }
  }


  home() {
    this.router.navigate(['/index'])
  }
  refreshCol() {
    var col = document.getElementsByClassName('collapse')

    for (let i = 0; i <= col.length; i++) {
      if (col[i].classList.contains('show')) {
        col[i].classList.remove('show');
      }
    }

  }
}

