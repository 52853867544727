<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Manage All Notice </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                All Notice List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refraceCreate()"><i class="ti-settings"></i> Create
                                Notice
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refraceCreate()"><i class="ti-settings"></i> Update
                                Notice
                            </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Notice ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="subject">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            Notice Subject
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{element.subject}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="description">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Notice
                                            Description

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.description }} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">Update</button>

                                            &nbsp;&nbsp;
                                            <button class="btn btn-outline-info"
                                                (click)="view(element)">View</button>

                                            &nbsp;&nbsp;

                                            <button class="btn btn-outline-danger"
                                                (click)="deleteConformation(element)">Delete</button>

                                        </td>
                                    </ng-container>



                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>

                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <img [src]="imgURL" />
                                </div>

                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-2">


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Notice Subject :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">

                                    <input class="form-control" type="text" [(ngModel)]="Obj['subject']">


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Notice Description :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">


                                    <textarea name="res" id="res" class="form-control" type="text"
                                        [(ngModel)]="Obj['description']"></textarea>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                            Select Document: <span style="color: red;">*</span>
                                </div>
                                <div class="col-4 text-center">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                        class="form-control" style="border-color: black"
                                        (change)="onFileUpload($event,file.files)" id="uploadDoc">
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button type="button" [disabled]="!uploadDisabled"
                                        class="btn btn-info" (click)='upload()'><i class="fa fa-upload"
                                            aria-hidden="true">&nbsp;</i>Upload</button>
                                    <!-- <button type="button" [disabled]="!uploader.getNotUploadedItems().length"
                                        class="btn btn-info" (click)='upload()'><i class="fa fa-upload"
                                            aria-hidden="true">&nbsp;</i>Upload</button> -->

                                </div>
                            </div>

                        </div>

                        <div class="tab-pane fade" id="tab-3">


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Notice Subject :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="Obj['subject']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Notice Description :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <textarea name="res" id="res" class="form-control" type="text"
                                        [(ngModel)]="Obj['description']"></textarea>
                                </div>
                            </div>
                            <br>


                            <div class="row">
                                <div class="col-12 text-center">
                                    <button type="button" class="btn btn-info" (click)='update()'>Update</button>

                                </div>
                            </div>

                        </div>


                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
