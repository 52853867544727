import { Component, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MainService as md_mainService } from '../../../md/service/main.service';
import { SettingService } from '../../service/setting.service'
import { ComplainServiceService } from '../../service/complain-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUploader } from 'ng2-file-upload';
import swal from 'sweetalert2';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
import { DomSanitizer } from '@angular/platform-browser';
import { ApprovalUserService } from '../../../portal/service/approval-user.service';
import { MainService as mainserviceEmb } from '../../../emb/service/main.service';
import { MainService as Portalmain } from '../../../portal/service/main.service';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../service/report.service'

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Component({
  selector: 'app-assigned-order',
  templateUrl: './assigned-order.component.html',
  styleUrls: ['./assigned-order.component.css']
})
export class AssignedOrderComponent implements OnInit {

  constructor(private settingService: SettingService, private spinner: NgxSpinnerService,
    private mainService: md_mainService, private sanitizer: DomSanitizer,
    public ApprovalUserService: ApprovalUserService,
    public mainserviceEmb: mainserviceEmb,
    private portalmain: Portalmain,
    public toastr: ToastrService,
    private ReportService: ReportService,
    private complainServiceService: ComplainServiceService
  ) { }

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  ebillUser:any;
  b_acct_id:any;
  current_user_id: any;
  current_user:any;
  identity:any;
  uploader1:any;
  httpUrl:any
  imgURLBefore:any
  imgURLAfter:any
  // displayedColumns = ["Sr.No", "co_ord_id", "co_ord_date", "comp_assign_date", "co_summary", "property_address", "compliner_name", "compliner_phone_no", "order_status", "action"];
  displayedColumns:any = ["Sr.No", "case_id_f", "co_ord_id", "co_ord_date", "co_ord_type", "compliner_name", "order_status", "action"];
  dataSource:any;

  attachedDocDatasource:any;
  // attachedDocDatasource = [1, "1/2022", "Shani.pdf"]
  attachedDocCoulmns = ["Sr.No", "doc_id", "case_id", "document_type_code", "action"];


  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.current_user_id = this.ebillUser.user_id;
    this.current_user = this.ebillUser.party_name
    await this.getAssignedOrder();
    await this.getAllEmployees();
    await this.getCodeValue();
    this.uploader1 = new FileUploader({ url: this.mainService.httpUrl, itemAlias: 'image' });
    this.uploader1.onAfterAddingFile = (file:any) => { file.withCredentials = false; };

  }


  supportDisplayCol = ['dep_name', 'branch_office', 'officer_name', 'letter_sub', 'signed_by', 'date', 'action']
  supportDatsource: any = [{ dep_name: "Account", branch_office: "Hazratgunj", officer_name: 'Inspector', letter_sub: 'Subject', signed_by: 'JE Mishra' }]


  orderListObj:any = new Object(); // firs
  seleOrderStatus:any;
  orderData:any;
  forwardData :any= new Object()
  repSubData:any = new Object();
  all_court_data:any = []
  depdata = new Object();
  // flag variable Start
  list_fg: boolean = true;
  upload: boolean = false;
  update: boolean = false;
  order_exe: boolean = false;
  upload_fg: boolean = true;
  list_head: boolean = true;
  act_list = 'active';
  act_upload = 'deactive';
  // flag variable End

  // List functions Start
  list() {
    this.list_fg = true;
    this.upload = false;
    this.upload_fg = true;
    this.list_head = true;
    this.update = false;
    this.order_exe = false;
    this.act_list = 'active';
    this.act_upload = 'deactive';
  }

  uploadOrder() {
    this.list_fg = false;
    this.upload = true;
    this.upload_fg = true;
    this.list_head = true;
    this.update = false;
    this.order_exe = false;
    this.act_list = 'deactive';
    this.act_list = 'active';

  }

  codeValueTechObj:any = {}
  codeValueObj :any= {}
  codeValueShowObj:any = {}
  async getCodeValue() {
    this.spinner.show()
    var resp:any = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj:any = {}
    var codeValueShowTempObj:any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.codeValueTechObj = this.codeValueObj;
      console.log(this.codeValueTechObj, 'uuuu')
      this.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }
  }
  // async getCourtData() {
  //   let obj = {}
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['order_status'] = this.seleOrderStatus;
  //   let resp = await this.settingService.getCourtData(JSON.stringify(obj));

  //   if (resp['error'] == false) {

  //     console.log("court data : ", resp['data'])
  //     this.all_court_data = resp['data']
  //     // this.dataSource = resp['data']
  //     // this.dataSource = new MatTableDataSource(resp['data']);
  //     // this.dataSource.paginator = this.paginator;
  //     // this.dataSource.sort = this.sort;
  //     this.dataSource = new MatTableDataSource(resp['data'])
  //     this.dataSource.paginator = this.paginator.toArray()[0];
  //     this.dataSource.sort = this.sort.toArray()[0];

  //   } else {
  //     swal.fire('ERROR', 'Error Occurred While Getting Department', 'error');
  //   }
  // }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  allSearchableEmp :any= []
  async getAllEmployees() {
    console.log('all emp')
    this.spinner.show()
    var arr:any = []
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'ICMS'
    var resp:any = await this.mainService.getuserformodulerolemapping(JSON.stringify(obj));

    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;

      for (let i = 0; i < arr.length; i++) {
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
      }
      this.allSearchableEmp = arr
      console.log(this.allSearchableEmp)
    } else {
      this.spinner.hide()
    }
  }

  veiw_details:any = new Object();
  execution_flag:boolean=false;
  execution_data=[]
 async viewDetails(element:any) {
    $('#orderDetails').modal('show');
    this.veiw_details = element;
   this.execution_data=[]
    this.before_exe=[]
    this.after_exe=[]
    console.log(element)
    if(element['order_status'] === 'EXECUTION_COMPLETED'){
        await  this.getExecutionData(element);
    }
  }

  before_exe=[]
  after_exe=[]
 async getExecutionData(view_dtl:any){
  let obj:any = new Object();
  obj['b_acct_id'] = this.b_acct_id;
  obj['co_ord_id'] = view_dtl['co_ord_id']
  obj['order_status'] = view_dtl['order_status']
  this.spinner.show();
  let resp = await this.settingService.getExecutionData(JSON.stringify(obj));
  if(resp['error'] == false){
    this.spinner.hide()
    this.execution_flag=true;
    this.execution_data= resp['data']
    this.before_exe=[]
    this.after_exe=[]
    this.execution_data.map(x=>{
        if(x['doc_type']=="BEFORE_EXECUTION"){
        this.before_exe.push(x);
        }if(x['doc_type']=="AFTER_EXECUTION"){
         this.after_exe.push(x);
       }
})

    console.log(this.execution_data)
  }else{
    this.spinner.hide();
    console.log("Error while getting execution data");
  }
 }


 reOpenMOdel(){

   $('#preview').modal('hide');
  $('#orderDetails').modal('show');
 }
  //Forword screen start


  forwordFile(element:any) {
    this.forwardData = {}
    this.forwardData = element;
    console.log(element)
    $('#forword').modal('show');
    this.condtion = "BY_ZONE"
    this.getuserinfobyzone();
  }
  same = false;
  condtion = {}
  Inzone: boolean = true;
  OuterZone: boolean = false;
  userbyzone = []
  userbynotzone = []
  // selected_user=[]
  sameAddress() {
    if (!this.same) {
      this.Inzone = false;
      this.OuterZone = true;
      this.same = true;
      this.condtion = "NOT_BY_ZONE"

      this.getuserinfobyzone();
    } else {
      this.Inzone = true;
      this.OuterZone = false;
      this.same = false;
      this.condtion = "BY_ZONE"

      this.getuserinfobyzone();

    }

  }

  async getuserinfobyzone() {
    let arr = []
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['condition'] = this.condtion;
    obj['node_cd'] = this.forwardData['zone_cd']
    var resp:any = await this.complainServiceService.getuserinfoByzone(JSON.stringify(obj));

    if (resp['error'] == false) {
      arr = resp['data'];

      resp['data'].map((x:any, i:any, data:any) => {
        if (x['user_id'] == this.current_user_id) {
          data.splice(i, i);
        } else {
          x['desc'] = x['user_id'] + ' - ' + x['emp_name'] + ' - ' + x['emp_phone_no'] + ' ' + x['zone_name']
        }
      })

      if (this.condtion == "BY_ZONE") {
        this.userbyzone = resp['data'];
        return
      } else {
        this.userbynotzone = resp['data'];
      }
      // console.log(this.userbyzone);
    } else {
      swal.fire('error', 'server side error', 'error');
    }


  }


  async submitForword() {
    this.spinner.show();
    this.forwardData['b_acct_id'] = this.b_acct_id;
    this.forwardData['life_cycle_status'] = "ASSIGNED"
    // this.forwardData['order_status'] = "UNDER_EXECUTION"
    this.forwardData['type'] = "COURT-ORDER"
    this.forwardData['current_user'] = this.current_user_id;
    let resp = await this.settingService.forwordFile(this.forwardData);
    if (resp['error'] == false) {
      this.spinner.hide()
      swal.fire('SUCCESS', 'Your File Will Forworded ', 'success');
      $('#forword').modal('hide');
    } else {
      this.spinner.hide()
      swal.fire('ERROR', 'Error Occurred While Forrwording File', 'error');
    }
  }

  // async getAttachedDocumnet(data:any) {

  //   let ob = new Object();
  //   ob['b_acct_id'] = this.b_acct_id;
  //   ob['case_id'] = data.case_id_f;
  //   ob["document_name"] = "Uploadcourtorder"
  //   let resp = await this.settingService.getAttachedDocumnet(ob);
  //   if (resp['error'] == false) {
  //     this.attachedDocDatasource = resp['data'];
  //     $('#attachedDoc').modal('show');
  //     console.log("Atached Doc : ", this.attachedDocDatasource)
  //   } else {
  //     swal.fire('ERROR', 'Error Occurred While Getting Department', 'error');
  //   }
  // }


  async downloadDocument(data:any) {
    this.spinner.show();
    let obj = Object.assign({}, data);
    obj['b_acct_id'] = this.b_acct_id
    obj["case_id"] = data.case_id_f;
    obj["doc_id"] = data.doc_id;
    obj["document_name"] = "Uploadcourtorder"
    let resp :any= await this.settingService.getAttachedDocumnet(obj);
    if (resp['error'] == false) {
      obj["document_type_code"] = resp['data'][0]['document_type_code'];
      const res = await this.settingService.downloadDocument(obj);
      if (res) {
        let ext = obj['document_type_code'].split('.')
        if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
          const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
          let fileURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          await this.downloadDoc(obj, fileURL)
          console.log("This is image : ", fileURL)
          this.spinner.hide()
        } else if (ext[1].toLowerCase() == 'xlsx') {
          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          let fileURL = URL.createObjectURL(file);
          await this.downloadDoc(obj, fileURL)
          this.spinner.hide()
        } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {
          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          let fileURL = URL.createObjectURL(file);
          await this.downloadDoc(obj, fileURL)
          this.spinner.hide()
        } else {
          let file = new Blob([res], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          await this.downloadDoc(obj, fileURL)
          this.spinner.hide()
        }
      } else {
        swal.fire('Error', 'Error while downloading document', 'error');
        this.spinner.hide()
      }
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while downloading document', 'error');
    }

    // if (res) {
    //   let file = new Blob([res], { type: 'application/pdf' });
    //   let fileURL = URL.createObjectURL(file);



    //   $('#attachedDoc').modal('hide');
    //   this.spinner.hide()

    // } else {
    //   this.spinner.hide()
    //   swal.fire('Error', 'No document Uploaded !', 'error');
    // }
  }

  async downloadDoc(data:any, fileURL:any) {
    //following code for download document
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = fileURL;
    a.download = data.document_type_code
    a.click();
    window.URL.revokeObjectURL(fileURL);
    a.remove();
  }

  async getAssignedOrder() {
    this.spinner.show();
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.current_user_id;
    var resp:any = await this.settingService.getAssignedOrder(JSON.stringify(obj));
    console.log("Assigned Data : ", resp.data)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.dataSource = new MatTableDataSource(resp['data'])
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
    } else {
      this.spinner.hide()
    }
  }


  /**************************************************************Approval Code Start******************************************* */
  undertakings:any
  salBill_apprdata:any
  async Appr(element :any) {
    $('#workflowmodel').modal('show');

    console.log(element)
    this.salBill_apprdata = Object.assign({}, element)
    this.salBill_apprdata['bill_desc'] = 'Approval for ' + element['upload_id']
    this.salBill_apprdata['level'] = 1
    this.salBill_apprdata['branch'] = 1
    this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['upload_id']
    this.salBill_apprdata['doc_type'] = 'ORDER EXECUTION REPORT'   //this.salBill_apprdata['id']
    this.salBill_apprdata['node_cd'] = element['zone_cd']
    this.salBill_apprdata['module_cd'] = 'ICMS';
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'ORDER EXECUTION REPORT', 'ICMS');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      // await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'APPROVED')
      return;
    }
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.ebillUser, this.salBill_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.salBill_apprdata)
  }


  forwarded_user_id:any;
  appr_remark:any
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();
      obb['user_id'] = this.ebillUser.user_id
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'ORDER EXECUTION REPORT'
      obb['doc_local_no'] = this.salBill_apprdata['upload_id']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
      obb['module_cd'] = 'ICMS'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      obb['level'] = this.ApprovalUserService.level1['level']
      obb['undertaking'] = JSON.stringify([])
      obb['branch'] = this.ApprovalUserService.level1['branch']
      obb['user_name'] = this.ebillUser.party_name;
      obb['field_value'] = 0
      var resp:any = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.ChangeComplainStatus(this.salBill_apprdata['upload_id'], 'UNDERAPPROVAL')
        await this.getAssignedOrder();
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          if (this.message_flag == true) {
          }

        } else {
          if (this.message_flag == true) await this.getcurrentlegalentity(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        this.toastr.success("Approval Forwarded Successfully!!")
        console.log("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        console.log(resp['data'])
      }
    } else {
      this.spinner.hide();
    }
  }
  message_flag = false

  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb :any= new Object();

      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'ORDER EXECUTION REPORT'
      obb['doc_local_no'] = this.salBill_apprdata['upload_id']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['user_name'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['emp_name']
      obb['module_cd'] = 'ICMS'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.salBill_apprdata['level']
        obb['branch'] = this.salBill_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      obb['field_value'] = 0
      var resp:any = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {

        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
    }
  }

  async ChangeComplainStatus(data:any, status:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = status;
    obj['id'] = data
    this.spinner.show();
    var resp:any = await this.ReportService.changeOrderStatus(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log('Successfully Chaged to ' + status)
    }
    else {
      this.spinner.hide();
      this.toastr.error(resp['data']);
    }
  }


  async getcurrentlegalentity(user_id:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp = await this.mainserviceEmb.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      await this.sendmessage(resp['data'][0]['phone_no'], resp['data'][0]['party_name'])
      this.spinner.hide();
      this.message_flag = false
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');
    }
  }

  async sendmessage(mobile:any, user_name:any) {
    let resp = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'], this.salBill_apprdata['upload_id'], user_name)
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');
    }
  }

  statusArr1:any = [];
  selectedEle:any;
  userRoles:any = []

  async viewStatus(element :any) {
    this.selectedEle = Object.assign({}, element);
    var obj:any = new Object();
    obj['doc_local_no'] = element.upload_id;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = 'ORDER EXECUTION REPORT'
    obj['module_cd'] = 'ICMS'
    var resp:any = await this.mainserviceEmb.getWorkflowlog(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.salBill_apprdata = Object.assign({}, element)
      this.salBill_apprdata['level'] = 1
      this.salBill_apprdata['branch'] = 1
      this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['upload_id']
      this.salBill_apprdata['doc_type'] = 'ORDER EXECUTION REPORT'//this.salBill_apprdata['id']
      this.salBill_apprdata['node_cd'] = element['project_cd']
      this.salBill_apprdata['module_cd'] = 'ICMS';
      await this.getWorkflowloguser()
      console.log(this.userRoles, resp.data, 'user')
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = "Contractor"
          resp.data[i]['role_cd'] = "Contractor"
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }
        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }
      this.statusArr1 = resp.data
      console.log(this.statusArr1);
      $('#workflowStatus').modal('show');
    } else {
      this.toastr.error(resp['data'])
    }
  }
  workflowuser = []
  rolecdtolevelobj = {}
  selectedTender = {}
  async getWorkflowloguser() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'ICMS'
    obj['status'] = 'ACTIVE'
    obj['node_cd'] = this.salBill_apprdata['node_cd']
    console.log(obj, this.selectedTender)
    var resp:any = await this.mainserviceEmb.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflowuser = resp['data']

    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }



  }
  executionImage:any;
  /**************************************************************Approval Code End******************************************* */

  async downloadexecutionImage(item:any){
    this.spinner.show();
    this.executionImage=''
    let obj = Object.assign({}, item);
    obj['b_acct_id'] = this.b_acct_id
    let resp = await this.settingService.downloadexecutionImage(obj);
    if(resp){
      $('#preview').modal('show');
      const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
      this.executionImage = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);

  this.spinner.hide()
    }else{
      swal.fire('ERROR',"Error While Getting Image",'error');
    }
  }
}

  /*************************************un Used Code******************************* */
  // download report from view details screen
  //  downloadReport(veiw_details) {

  //   var txt = (" ORDER DETAILS ");
  //   var dd = {
  //     pageSize: 'A3',
  //     header: function (currentPage, pageCount) {
  //       var arr = []
  //       var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
  //       arr.push(obj);
  //       return arr;
  //     },

  //     pageOrientation: 'portrait',
  //     pageMargins: [40, 60, 40, 60],
  //     content: [

  //     ]
  //   };

  //   var tbl = {

  //     fontSize: 10,
  //     table: {
  //       headerRows: 0,
  //       widths: ['*', '*'],
  //       body: [
  //       ]
  //     }
  //   };

  //   var tbl1 = {

  //     fontSize: 10,
  //     table: {
  //       headerRows: 0,
  //       widths: ['*', '*'],
  //       body: [
  //       ]
  //     }
  //   };

  //   var tbl2 = {

  //     fontSize: 10,
  //     table: {
  //       headerRows: 0,
  //       widths: ['*', '*'],
  //       body: [
  //       ]
  //     }
  //   };

  //   let arr = [];

  //   arr.push({ text: "Order ID  " });
  //   arr.push(veiw_details['order_id']);
  //   tbl.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Order Date" });
  //   arr.push(veiw_details['order_date']);
  //   tbl.table.body.push(arr);


  //   arr = [];
  //   arr.push({ text: "Order Type" });
  //   arr.push(veiw_details['order_type']);
  //   tbl.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Type of Construction" });
  //   arr.push(veiw_details['const_type']);
  //   tbl.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Compilation Time Limit" });
  //   arr.push(veiw_details['com_time_lmt']);
  //   tbl.table.body.push(arr);

  //   dd.content.push(tbl);
  //   dd.content.push({ text: "Address Of Property -  ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });




  //   arr = [];
  //   arr.push({ text: "Plot/House No" });
  //   arr.push(veiw_details['property_address']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Mohalla" });
  //   arr.push(veiw_details['property_address']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Area" });
  //   arr.push(veiw_details['property_address']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Zone/Division" });
  //   arr.push(veiw_details['zone_cd']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "District" });
  //   arr.push(veiw_details['dist']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "State" });
  //   arr.push(veiw_details['state']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Country" });
  //   arr.push(veiw_details['country']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Landmark" });
  //   arr.push(veiw_details['landmark']);
  //   tbl1.table.body.push(arr);


  //   dd.content.push(tbl1);
  //   dd.content.push({ text: "Opponent Party Details -  ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });


  //   arr = [];
  //   arr.push({ text: "Name" });
  //   arr.push(veiw_details['compliner_name']);
  //   tbl2.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Phone/Mobile No." });
  //   arr.push(veiw_details['compliner_phone_no']);
  //   tbl2.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Email" });
  //   arr.push(veiw_details['compliner_email']);
  //   tbl2.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Address" });
  //   arr.push(veiw_details['property_address']);
  //   tbl2.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Order Status" });
  //   arr.push(veiw_details['order_status']);
  //   tbl2.table.body.push(arr);

  //   dd.content.push(tbl2)
  //   pdfMake.createPdf(dd).download('Report.pdf');
  //   // swal.fire("INFO", "PDF will download soon", 'info')

  // }
  // async getorderData() {
  //   let obj = {}
  //   obj['b_acct_id'] = this.b_acct_id;
  //   let resp = await this.settingService.getOrderData(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.orderData = resp['data']
  //   } else {
  //     swal.fire('ERROR', 'Error Occurred While Getting Department', 'error');
  //   }
  // }



  // recoveryOrder() {
  //   this.list_fg = false;
  //   this.upload = false;
  //   this.update = true;
  //   this.order_exe = false;
  //   this.upload_fg = false;
  //   this.list_head = false;
  // }

  // orderExeFg(element :any) {
  //   this.list_fg = false;
  //   this.upload = false;
  //   this.order_exe = true;
  //   this.upload_fg = false;
  //   this.list_head = false;
  //   this.repSubData = element;
  //   this.repSubData['sub_by'] = this.ebillUser.party_name;
  //   this.repSubData['sub_user_id'] = this.ebillUser.user_id;
  //   console.log(this.repSubData)
  // }
  // List functions End

  //Forword screen end


  // async getcomplaindata() {
  //   const obj = {}
  //   console.log(this.b_acct_id)
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['user_id'] = this.ebillUser.user_id;
  //   var resp:any = await this.ReportService.allAssignComplain(JSON.stringify(obj));
  //   console.log(resp)
  //   if (resp['error'] == true) {
  //     swal.fire('error', "server side error", 'error')
  //     return
  //   } else {
  //     this.datasource = []
  //     // this.datasource = resp['data']
  //     this.datasource = new MatTableDataSource(resp['data'])
  //     this.datasource.paginator = this.paginator.toArray()[0];
  //     this.datasource.sort = this.sort.toArray()[0];
  //   }

  // }

  // //report submission screen start
  // saveReportSub() {
  //   console.log(this.repSubData);
  //   swal.fire("INFO", 'Your data will Save soon', 'info');
  //   this.repSubData = {}
  //   this.list()
  // }

  // async submitReportSub() {
  //   this.repSubData['b_acct_id'] = this.b_acct_id;
  //   this.repSubData['doc_name'] = this.doc_name;
  //   this.repSubData['doc_local_name'] = this.doc_local_name;
  //   this.repSubData['create_user_id'] = this.ebillUser.user_id;
  //   this.repSubData['update_user_id'] = this.ebillUser.user_id;

  //   console.log("Form Data : ", this.repSubData)
  //   this.formData = new FormData();
  //   for (let i = 0; i < this.selectedFile.length; i++) {
  //     this.formData.append("image", this.selectedFile[i]);
  //   }

  //   this.formData.append("data", JSON.stringify(this.repSubData))
  //   let resp = await this.settingService.submitReportSub(this.formData);
  //   if (resp['error'] == false) {
  //     swal.fire("Success", "Report Submmision successfull", 'success');
  //     this.repSubData = {}
  //     this.doc_name = []
  //     this.selectedFile = []
  //     this.imgURLBefore = []
  //     this.imgURLAfter = []
  //     this.formData = new FormData();
  //     this.list();
  //   } else {
  //     swal.fire('ERROR', 'Error Occurred While Report Submmision', 'error');
  //   }

  // }



  // selectedFile: any = [];
  // imgURLBefore = [];
  // imgURLAfter = [];
  // doc_name = []
  // doc_local_name = []
  // formData = new FormData();
  // async onFileUpload(event: any, files, identity) {
  //   if (event.target.files && event.target.files[0]) {
  //     for (let i = 0; i < event.target.files.length; i++) {
  //       this.selectedFile.push(event.target.files[i]);
  //       this.doc_name.push(identity + "_" + event.target.files[i]['name']);
  //       this.doc_local_name.push(event.target.files[i]['name'])
  //       let reader = new FileReader();
  //       reader.onload = (event: any) => {
  //         // console.log(event.target.result);
  //         if (identity == 'before') {
  //           this.imgURLBefore.push(event.target.result);
  //         }
  //         if (identity == 'after') {
  //           this.imgURLAfter.push(event.target.result);
  //         }
  //       }
  //       reader.readAsDataURL(event.target.files[i]);
  //     }

  //   }
  //   console.log(this.doc_name)
  //   console.log("selectedFile : ", this.selectedFile)

  // }

  // async validateSize(input) {
  //   const fileSize = input.target.files[0].size / 1024;
  //   let numb: any = fileSize.toFixed(2);
  //   if (numb > 1024 * 10) {
  //     // alert('to big, maximum is 2MiB. You file size is: ' + numb + ' KB');
  //     swal.fire('Information', 'to big, maximum is 10MB. You file size is: ' + numb + ' KB', 'info')
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  // identity;
  // viewPhoto(identity) {
  //   this.identity = identity;
  //   $('#preview').modal('show');
  // }

  // async cancleSubReport() {
  //   this.repSubData = {}
  //   this.doc_name = []
  //   this.selectedFile = []
  //   this.imgURLBefore = []
  //   this.imgURLAfter = []

  //   this.formData = new FormData();
  //   this.list();
  // }
  //report submission screen end

