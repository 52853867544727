<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Complaint Enquiry Register -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry1']}} </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                 <!-- Complaint  Enquiry List -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"></i>
                                <!-- Add  Complaint Enquiry Detail -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry3']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="#tab-3" (click)="refrace(0)" data-toggle="tab"><i class="ti-bar-chart"></i>
                                 Update Enquiry<!--{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry2']}}--></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4" (click)="refrace(1)" data-toggle="tab"><i class="fa fa-plus-circle"></i>
                               Submit Chargesheet <!--{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry3']}}--></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-5"  (click)="refrace(2)" data-toggle="tab"><i class="fa fa-plus-circle"></i>
                               Close Enquiry <!--{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry3']}}--></a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Enter Complaint ID : {{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry4']}}-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="selectCompObj['complaint_id']"> -->

                                <!-- </div>
                                <div class="col-4">
                                    <button class="btn btn-primary" (click)="getEnquiry()">GET ENQUIRY STEPS{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry5']}}</button> -->
                                <!-- </div>

                            </div> -->

                            <hr>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- Enquiry ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="en_type_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Enquiry Type -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0036'][element.en_type_code]}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="en_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Enquiry Desc -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.en_desc}} And Enquiry about Complaint Id {{element.complaint_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="en_status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Enquiry Status--> {{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry18']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.en_status}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="en_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Enquiry Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry9']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{mainService.dateformatchange(element.en_dt)}} </td>
                                    </ng-container>





                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry10']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" *ngIf="element.en_status != 'CLOSED'"  (click)="update(element)"><!--Update--> {{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry19']}}</button>
                                            <button class="btn btn-outline-success" *ngIf="element.en_status != 'CLOSED' && element.chargesheet_status != 'ACTIVE'"  (click)="chargesheet(element)"><!--File Chargesheet-->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry20']}}</button>
                                            <button class="btn btn-outline-danger" *ngIf="element.chargesheet_status == 'ACTIVE' && element.en_status != 'CLOSED'" (click)="close(element)"><!--Close Enquiry-->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry21']}}</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                  <!--  Select Complaint ID : --> {{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry22']}} <span style="color:red">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allApprovedComplaint" bindLabel="id" bindValue="id" (change)="checkComplaint()" [multiple]="false" placeholder="" [(ngModel)]="enquiryObj['complaint_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="field_name">
                                </ng-select>
                               </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enquiry Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry13']}} :<span style="color:red">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0036']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="enquiryObj['en_type_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                   <!--  Enquiry Desc : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry14']}}:<span style="color:red">*</span>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%;" id="desc" name="desc" [(ngModel)]="enquiryObj['en_desc']"></textarea>
                                </div>
                            </div>
                            <br>


                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enquiry Date : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry15']}}:<span style="color:red">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="enquiryObj['en_dt']">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                <!--     Enquiry Officer Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry23']}} :<span style="color:red">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="enquiryObj['en_officer_name']">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitEnquiry()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry16']}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enquiry ID : --> {{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry24']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="updtEnquiryObj['id']" disabled>
                               </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                  <!--    Complaint ID : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry25']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="updtEnquiryObj['complaint_id']" disabled>
                               </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enquiry Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry13']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="updtEnquiryObj['en_type_code']" disabled>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enquiry Date : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry15']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="updtEnquiryObj['en_dt']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                  <!--   Enquiry Progress Note :-->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry26']}} <span style="color:red">*</span>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%;" id="desc" name="desc" [(ngModel)]="updtEnquiryObj['en_progress_note']"></textarea>
                                </div>
                            </div>
                            <br>
                             <div class="row">
                                <div class="col-4 text-right">
                                   <!--  Update Date :-->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry27']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="updtEnquiryObj['update_dt']" >
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateEnquiry()"> {{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry19']}}<!--Update--></button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-4">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                 <!--   Enquiry ID : --> {{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry24']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="chargesheetObj['id']" disabled>
                               </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                <!--    Complaint ID : --> {{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry25']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="chargesheetObj['complaint_id']" disabled>
                               </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enquiry Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry13']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="chargesheetObj['en_type_code']" disabled>
                                </div>
                            </div>
                            <br>

                               <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enquiry Date : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry15']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="chargesheetObj['en_dt']" disabled>
                                </div>
                              </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                  <!--   Enquiry Officer Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry23']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="chargesheetObj['en_officer_name']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                 <!--    Chargesheet Date :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry28']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="chargesheetObj['chargesheet_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                   <!--  Chargesheet Summary : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry29']}} : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%;" id="desc" name="desc" [(ngModel)]="chargesheetObj['chargesheet_summary']"></textarea>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                   <!--  Enquiry Result :-->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry30']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0055']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="chargesheetObj['en_result']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="field_name">
                                </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                 <!--   Upload Chargesheet : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint31']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4 text-center">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                    class="form-control" style="border-color: black"
                                    (change)="onFileUpload($event,file.files)" id="uploadFile">
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitChargesheet()"> <!--Submit Chargesheet -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry32']}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-5">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                   <!-- Enquiry ID :  -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry24']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="closeObj['id']" disabled>
                               </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enquiry Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry13']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="closeObj['en_type_code']" disabled>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enquiry Date : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry15']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="closeObj['en_dt']" disabled>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enquiry Officer Name :-->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry23']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="closeObj['en_officer_name']" disabled>
                                </div>
                            </div>
                             <br>
                             <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Chargesheet Date :-->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry28']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="closeObj['chargesheet_dt']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                   <!--  Closing Note : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry34']}} : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%;" id="desc" name="desc" [(ngModel)]="closeObj['en_closing_note']"></textarea>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="closeEnquiry()">{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry33']}}<!-- Close--></button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner><!-- Loading.... -->{{mainService.allLableShowObjHR[mainService.language_cd+'Enquiry17']}}</ngx-spinner>
