import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { constants } from 'buffer';
import { MainService } from '../../../service/main.service';
// import { NewEngService } from '../../../service/new-eng.service';
import {MasterDataService as DepMasterDataComponent} from '../../service/master-data.service'
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { Pipe, PipeTransform } from "@angular/core";

declare var $: any

@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.css']
})
export class MasterDataComponent implements OnInit {

  constructor(public mainService: MainService, private depMasterDataComponent: DepMasterDataComponent, private spinner: NgxSpinnerService) { }
  ebillUser:any;
  b_acct_id:any;
  ad_tab_list = 'active'
  ad_tab_c = 'deactive'
  ad_tab_u = 'deactive'
  update_flag:any;
  ngOnInit() {
    this.ad_tab_list = 'active'
    this.ad_tab_c = 'deactive'
    this.update_flag = false
    this.ebillUser = JSON.parse(atob(localStorage.getItem("erpUser") as any));
    this.b_acct_id = this.ebillUser["b_acct_id"];
    this.l_flag = true;
  }
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns = ['id', 'Chapter_no', 'Chapter_desc', 'action'];
  datasource:any;

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  l_flag:any;
  c_flag:any;
  u_flag:any;

  list() {
    this.l_flag = true
    this.c_flag = false;
    this.u_flag = false;
    this.obj = {}
    this.ad_tab_list = 'active'
    this.ad_tab_c = 'deactive'
    this.ad_tab_u = 'deactive'
    this.datasource = new MatTableDataSource([])
    this.update_flag = false
  }

  chaptermaping() {
    this.obj = {}
    this.l_flag = false;
    this.c_flag = true;
    this.u_flag = false;
    this.item = {}
    this.item['Item Description'] = 1
    this.item['Costing Rate'] = 1
    this.item['Quantity Unit'] = 1
    this.item['Item Quantity'] = 1
    this.item['Costing Rate Unit'] = 1
    // this.item['effective_date']=1
    this.ad_tab_c = 'active'
    this.ad_tab_list = 'deactive'
    this.ad_tab_u = 'deactive'
    this.update_flag = false
  }
  id:any;
  openupdate(element:any) {
    this.update_flag = true
    console.log(element)
    this.id = element.id
    this.item = element.field_data
    this.obj['chapter_no'] = element.chapter_no
    this.obj['chapter_desc'] = element.chapter_desc
    this.obj['fin_year'] = element.fin_year
    this.l_flag = false;
    this.c_flag = false;
    this.u_flag = true;
    this.ad_tab_u = 'active'
    this.ad_tab_c = 'deactive'
    this.ad_tab_list = 'deactive'
  }




  obj:any = {}
  item:any = {}
  isCheckModel:any;
  async submit() {
    this.obj['b_acct_id'] = this.b_acct_id;
    this.obj['field_data'] = this.item
    console.log(this.item)
    this.spinner.show();
    var resp = await this.depMasterDataComponent.createfieldsmapping(this.obj);
    if (resp["error"] == false) {
      Swal.fire("Success", "Submited Successfully", "success");
      this.spinner.hide();
      this.obj = {}
      this.item = {}
      this.item['Item Description'] = 1
      this.item['Costing Rate'] = 1
      this.item['Quantity Unit'] = 1
      this.item['Item Quantity'] = 1
      this.item['Costing Rate Unit'] = 1
      // this.item['effective_date']=1
      this.list();
    } else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While Submitting', 'error')
    }

  }


  async fetch() {
    this.obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.depMasterDataComponent.getChapterField(JSON.stringify(this.obj));
    console.log(resp.data)
    if (resp["error"] == false) {
      this.spinner.hide();
      resp.data.map((x:any) => {
        x['field_data'] = JSON.parse(x['field_data'])
        this.datasource = new MatTableDataSource(resp.data)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      })

    } else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While geting data', 'error')
    }

    console.log(this.datasource)
  }
  async update() {
    this.obj['b_acct_id'] = this.b_acct_id;
    this.obj['field_data'] = this.item
    this.obj['id'] = this.id
    var resp = await this.depMasterDataComponent.updateChapterField(this.obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      Swal.fire("Success", "Updated Successfully", "success");
      this.item = {}
      this.l_flag = true
      this.c_flag = false;
      this.u_flag = false;
      this.obj = {}
      this.datasource = new MatTableDataSource([])
    } else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While Updating', 'error')
    }

  }

}
