<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal1']}}
                        <!-- Manage Journal -->
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal2']}}
                                <!-- All UnPosted Journal List -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal3']}}
                                <!-- Create Journal -->
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal4']}}
                                <!-- Update Journal -->
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">


                            <br>
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)"
                                    placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal5']}}">
                                <!-- Filter -->
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="org_unit_cd">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal6']}}
                                            <!-- Organization -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.org_unit_cd }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="acct_dt">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal7']}}
                                            <!-- Accounting Date -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            <!-- {{ element.acct_dt }} -->
                                            <p *ngIf="element.acct_dt ">{{mainService.dateFormatChange(element.acct_dt
                                                )}}</p>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="prep_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>

                                            Preparer Name


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ allUserRoles[element.prep_id ]}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="appr_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Approver Name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{allUserRoles[element.appr_id] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="preparer_comment">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal10']}}
                                            <!-- Preparer Comment -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.preparer_comment }} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal12']}}
                                            <!-- Status -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.status }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal13']}}
                                            <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">


                                            <button class="btn btn-outline-danger" *ngIf="element.status=='GENERATED'"
                                                (click)="delete(element)">{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal14']}}
                                                <!-- Delete -->
                                            </button>&nbsp;&nbsp;
                                            <button class="btn btn-outline-primary" *ngIf="element.status=='GENERATED'"
                                                type="button"
                                                (click)="open_update(element)">{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal15']}}
                                                <!-- Update -->
                                            </button>&nbsp;&nbsp;
                                            <button class="btn btn-primary" *ngIf="element.status=='GENERATED'"
                                                type="button" (click)="changeMJStatus(element)">
                                                Approve
                                            </button>
                                            &nbsp;&nbsp;
                                            <button *ngIf="element.status=='APPROVED'" class="btn btn-outline-info"
                                                type="button"
                                                (click)="post(element)">{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal16']}}
                                                <!-- Post -->
                                            </button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <form name="form" (ngSubmit)="f.form.valid && checkBeforeSave(f.form)" #f="ngForm">

                                <div>
                                    <br>

                                    <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                        <div class="col-2 text-left">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal6']}}
                                                <!-- Organization --> :
                                            </b>
                                        </div>
                                        <div class="col-10">
                                            {{this.mainService.accInfo['account_name']}}
                                            ({{this.mainService.accInfo['account_short_name']}})
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                    <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                        <div class="col-2 text-left">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal19']}}
                                                <!-- Journal Id -->:
                                            </b>
                                        </div>
                                        <div class="col-2">
                                            {{jrnl_id}}
                                        </div>

                                        <div class="col-2">
                                            <b>
                                                Preparer Name:
                                            </b>
                                        </div>
                                        <div class="col-1">
                                            {{allUserRoles[obj['prep_id']]}}
                                        </div>
                                        <div class="col-2">
                                            <b>
                                                Approver Name:
                                            </b>
                                        </div>
                                        <div class="col-3">
                                            {{allUserRoles[obj['appr_id']]}}
                                        </div>

                                    </div>
                                    <br>
                                    <hr>

                                    <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                        <div class="col-2 text-left">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal20']}}
                                                <!-- Accounting Date -->:
                                            </b>
                                        </div>
                                        <div class="col-3">
                                            <input class="form-control" type="date" [(ngModel)]="obj['acct_dt']"
                                                name="acct_dt" #acct_dt="ngModel"
                                                [ngClass]="{ 'is-invalid': f.submitted && acct_dt.invalid }" required>


                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.border_green]="acct_dt.dirty && acct_dt.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f.submitted && acct_dt.invalid" class="error-message">
                                                <div *ngIf="acct_dt.errors?.['required']">Please Select Accounting Date
                                                </div>
                                            </div>

                                            <!--------------------------------------------- validation End ------------------- -->
                                        </div>

                                        <div class="col-2">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal21']}}
                                                <!-- Remark --> :
                                            </b>
                                        </div>
                                        <div class="col-5">
                                            <textarea style="width: 100%;" id="re" name="re" class="form-control"
                                                [(ngModel)]="obj['preparer_comment']"></textarea>
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                    <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                        <div class="col-3 text-center">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal22']}}
                                                <!-- Total Credits --> :
                                            </b>
                                        </div>
                                        <div class="col-3 text-center">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal23']}}
                                                <!-- Total Debits -->:
                                            </b>
                                        </div>
                                        <div class="col-3 text-center">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal24']}}
                                                <!-- Currency -->:
                                            </b>
                                        </div>

                                        <div class="col-3 text-center">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal18']}}
                                                <!-- Status -->:
                                            </b>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                        <div class="col-3 text-center">
                                            {{cr}}
                                        </div>
                                        <div class="col-3 text-center">
                                            {{db}}

                                        </div>
                                        <div class="col-3 text-center">
                                            {{obj['tgt_curr_cd']}}
                                        </div>

                                        <div class="col-3 text-center">

                                            {{obj['status']}}

                                        </div>
                                    </div>
                                    <br>



                                </div>

                                <br>


                                <hr>
                                <div class="row">



                                    <div class="col-1 text-center">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal26']}}
                                            <!-- S NO -->:
                                        </h6>
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal27']}}
                                        </h6>
                                        <!-- Chart Of Account -->
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal28']}}
                                        </h6>
                                        <!-- Select Debit Credit Ind -->
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal29']}}
                                        </h6>
                                        <!-- Amount -->
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal21']}}
                                        </h6>
                                        <!-- Remark -->
                                    </div>


                                    <div class="col-1">
                                        <button class="btn btn-primary" type="button" (click)="addRowCredit()"><i
                                                class="fa fa-plus" aria-hidden="true"></i></button>

                                    </div>

                                </div>
                                <hr>
                                <br>
                                <table>


                                    <div class="row" *ngFor="let ln of AllJournalRow_credit; let i = index;">
                                        <br>

                                        <div class="col-1 text-center">
                                            {{i+1}}
                                        </div>


                                        <div class="col-3 text-center">
                                            <ng-select [items]="allChartOfAccount" bindLabel="leaf_value"
                                                bindValue="leaf_code" [multiple]="false"
                                                placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal30']}}"
                                                (change)="changecoa(i)" [(ngModel)]="ln['chart_of_account']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="field_name_{{i}}" #coa="ngModel"
                                                [ngClass]="{ 'is-invalid': f.submitted && coa.invalid }" required>
                                                <!-- Select Chart Of Account -->
                                            </ng-select>

                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.selection-box]="coa.dirty && coa.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f.submitted && coa.invalid" class="error-message">
                                                <div *ngIf="coa.errors?.['required']">Please select chart of account </div>
                                            </div>

                                            <!--------------------------------------------- validation End ------------------- -->

                                        </div>



                                        <div class="col-2 text-center">
                                            <ng-select [items]="allDBIND" bindLabel="value" bindValue="code"
                                                [multiple]="false"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal31']}}"
                                                (change)="changedbcr(i)" [(ngModel)]="ln['db_cd_ind']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="db_cd_{{i}}" #db_cd="ngModel"
                                                [ngClass]="{ 'is-invalid': f.submitted && db_cd.invalid }" required>
                                            </ng-select>

                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.selection-box]="db_cd.dirty && db_cd.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f.submitted && db_cd.invalid" class="error-message">
                                                <div *ngIf="db_cd.errors?.['required']"> Please select db cd ind </div>
                                            </div>

                                            <!--------------------------------------------- validation End ------------------- -->

                                        </div>



                                        <div class="col-2 text-center">
                                            <input class="form-control" type="number" (change)="changeamt(i)"
                                                [(ngModel)]="ln['txn_amt']" name="txn_amount_{{i}}"
                                                #txn_amount="ngModel"
                                                [ngClass]="{ 'is-invalid': f.submitted && txn_amount.invalid }"
                                                required>

                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.border_green]="txn_amount.dirty && txn_amount.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f.submitted && txn_amount.invalid" class="error-message">
                                                <div *ngIf="txn_amount.errors?.['required']">Please fill transaction amount
                                                </div>
                                            </div>
                                            <!-- <div class="error-message" *ngIf="txn_amount.value <= 0">
                                                Please fill transaction amount
                                            </div> -->
                                            <!--------------------------------------------- validation End ------------------- -->
                                        </div>
                                        <div class="col-3 text-center">

                                            <textarea style="width: 100%;" id="re" name="re_{{i}}" class="form-control"
                                                [(ngModel)]="ln['remark']" (change)="changeremark(i)"></textarea>

                                        </div>
                                        <div class="col-1 text-center">
                                            <button type="button" class="btn btn-danger "
                                                (click)="deleteRowCredit(i)"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></button>

                                            <button type="button" class="btn btn-primary " data-toggle="modal"
                                                data-target="#change" data-backdrop="false"
                                                (click)="changeRowCredit(i)"><i class="fa fa-edit"></i></button>
                                        </div>
                                    </div>


                                </table>

                                <br>
                                <div class="row">
                                    <div class="col-6 text-right">
                                        <button type="button" class="btn btn-primary" (click)="check()">Check</button>
                                    </div>
                                    <div class="col-6 text-left">
                                        <!-- <button type="submit" class="btn btn-primary"
                                            (click)=" f.form.valid && checkBeforeSave()">Submit</button> -->
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                        <button type="reset" class="btn btn-outline-info d-none"
                                            id="reset">Reset</button>

                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="tab-pane fade" id="tab-3">
                            <form name="form" (ngSubmit)="f2.form.valid && update(f2.form)" #f2="ngForm">

                                <div>
                                    <br>

                                    <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                        <div class="col-2 text-left">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal6']}}
                                                <!-- Organization --> :
                                            </b>
                                        </div>
                                        <div class="col-10">
                                            {{this.mainService.accInfo['account_name']}}
                                            ({{this.mainService.accInfo['account_short_name']}})
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                    <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                        <div class="col-2 text-left">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal19']}}
                                                <!-- Journal Id -->:
                                            </b>
                                        </div>
                                        <div class="col-2">
                                            {{jrnl_id}}
                                        </div>

                                        <div class="col-2">
                                            <b>
                                                Preparer Name:
                                            </b>
                                        </div>
                                        <div class="col-1">
                                            {{allUserRoles[obj['prep_id']]}}
                                        </div>
                                        <div class="col-2">
                                            <b>
                                                Approver Name:
                                            </b>
                                        </div>
                                        <div class="col-3">
                                            {{allUserRoles[obj['appr_id']]}}
                                        </div>

                                    </div>
                                    <br>
                                    <hr>

                                    <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                        <div class="col-2 text-left">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal20']}}
                                                <!-- Accounting Date -->:
                                            </b>
                                        </div>
                                        <div class="col-3">
                                            <input class="form-control" type="date" [(ngModel)]="obj['acct_dt']"
                                                name="acct_dt" #acct_dt="ngModel"
                                                [ngClass]="{ 'is-invalid': f2.submitted && acct_dt.invalid }" required>


                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.border_green]="acct_dt.dirty && acct_dt.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f2.submitted && acct_dt.invalid" class="error-message">
                                                <div *ngIf="acct_dt.errors?.['required']">Please Select Accounting Date
                                                </div>
                                            </div>

                                            <!--------------------------------------------- validation End ------------------- -->
                                        </div>

                                        <div class="col-2">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal21']}}
                                                <!-- Remark --> :
                                            </b>
                                        </div>
                                        <div class="col-5">
                                            <textarea style="width: 100%;" id="re" name="re" class="form-control"
                                                [(ngModel)]="obj['preparer_comment']"></textarea>
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                    <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                        <div class="col-3 text-center">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal22']}}
                                                <!-- Total Credits --> :
                                            </b>
                                        </div>
                                        <div class="col-3 text-center">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal23']}}
                                                <!-- Total Debits -->:
                                            </b>
                                        </div>
                                        <div class="col-3 text-center">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal24']}}
                                                <!-- Currency -->:
                                            </b>
                                        </div>

                                        <div class="col-3 text-center">
                                            <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal18']}}
                                                <!-- Status -->:
                                            </b>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                        <div class="col-3 text-center">
                                            {{cr}}
                                        </div>
                                        <div class="col-3 text-center">
                                            {{db}}

                                        </div>
                                        <div class="col-3 text-center">
                                            {{obj['tgt_curr_cd']}}
                                        </div>

                                        <div class="col-3 text-center">

                                            {{obj['status']}}

                                        </div>
                                    </div>
                                    <br>



                                </div>

                                <br>


                                <hr>
                                <div class="row">



                                    <div class="col-1 text-center">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal26']}}
                                            <!-- S NO -->:
                                        </h6>
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal27']}}
                                        </h6>
                                        <!-- Chart Of Account -->
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal28']}}
                                        </h6>
                                        <!-- Select Debit Credit Ind -->
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal29']}}
                                        </h6>
                                        <!-- Amount -->
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal21']}}
                                        </h6>
                                        <!-- Remark -->
                                    </div>


                                    <div class="col-1">
                                        <button class="btn btn-primary" type="button" (click)="addRowCredit()"><i
                                                class="fa fa-plus" aria-hidden="true"></i></button>

                                    </div>

                                </div>
                                <hr>
                                <br>
                                <table>


                                    <div class="row" *ngFor="let ln of AllJournalRow_credit; let i = index;">
                                        <br>

                                        <div class="col-1 text-center">
                                            {{i+1}}
                                        </div>


                                        <div class="col-3 text-center">
                                            <ng-select [items]="allChartOfAccount" bindLabel="leaf_value"
                                                bindValue="leaf_code" [multiple]="false"
                                                placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal30']}}"
                                                (change)="changecoa(i)" [(ngModel)]="ln['chart_of_account']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="field_name_{{i}}" #coa="ngModel"
                                                [ngClass]="{ 'is-invalid': f2.submitted && coa.invalid }" required>
                                                <!-- Select Chart Of Account -->
                                            </ng-select>

                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.selection-box]="coa.dirty && coa.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f2.submitted && coa.invalid" class="error-message">
                                                <div *ngIf="coa.errors?.['required']">Please select chart of account </div>
                                            </div>

                                            <!--------------------------------------------- validation End ------------------- -->

                                        </div>



                                        <div class="col-2 text-center">
                                            <ng-select [items]="allDBIND" bindLabel="value" bindValue="code"
                                                [multiple]="false"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal31']}}"
                                                (change)="changedbcr(i)" [(ngModel)]="ln['db_cd_ind']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="db_cd_{{i}}" #db_cd="ngModel"
                                                [ngClass]="{ 'is-invalid': f2.submitted && db_cd.invalid }" required>
                                            </ng-select>

                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.selection-box]="db_cd.dirty && db_cd.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f2.submitted && db_cd.invalid" class="error-message">
                                                <div *ngIf="db_cd.errors?.['required']"> Please select db cd ind </div>
                                            </div>

                                            <!--------------------------------------------- validation End ------------------- -->

                                        </div>



                                        <div class="col-2 text-center">
                                            <input class="form-control" type="number" (change)="changeamt(i)"
                                                [(ngModel)]="ln['txn_amt']" name="txn_amount_{{i}}"
                                                #txn_amount="ngModel"
                                                [ngClass]="{ 'is-invalid': f2.submitted && txn_amount.invalid }"
                                                required>

                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.border_green]="txn_amount.dirty && txn_amount.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f2.submitted && txn_amount.invalid" class="error-message">
                                                <div *ngIf="txn_amount.errors?.['required']">Please fill transaction amount
                                                </div>
                                            </div>
                                            <!-- <div class="error-message" *ngIf="txn_amount.value <= 0">
                                                Please fill transaction amount
                                            </div> -->
                                            <!--------------------------------------------- validation End ------------------- -->
                                        </div>
                                        <div class="col-3 text-center">

                                            <textarea style="width: 100%;" id="re" name="re_{{i}}" class="form-control"
                                                [(ngModel)]="ln['remark']" (change)="changeremark(i)"></textarea>

                                        </div>
                                        <div class="col-1 text-center">
                                            <button type="button" class="btn btn-danger "
                                                (click)="deleteRowCredit(i)"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></button>

                                            <button type="button" class="btn btn-primary " data-toggle="modal"
                                                data-target="#change" data-backdrop="false"
                                                (click)="changeRowCredit(i)"><i class="fa fa-edit"></i></button>
                                        </div>
                                    </div>


                                </table>

                                <br>
                                <div class="row">
                                    <div class="col-6 text-right">
                                        <button type="button" class="btn btn-primary" (click)="check()">Check</button>
                                    </div>
                                    <div class="col-6 text-left">

                                        <button type="submit" class="btn btn-primary">Update</button>
                                        <button type="reset" class="btn btn-outline-info d-none"
                                            id="reset">Reset</button>

                                    </div>
                                </div>
                            </form>
                        </div>


                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>

<div class="modal" id="change">
    <div class="modal-dialog modal-lg  modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal35']}}
                    <!-- Journal -->
                </h4>
            </div>


            <div class="modal-body">
                <br>
                <div class="row">
                    <div class="col-3 text-left">
                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal34']}}
                            <!-- Event Code -->:
                        </h6>
                    </div>

                    <div class="col-9 ">
                        <ng-select [items]="allEventList" bindLabel="event_desc" bindValue="event_code"
                            [multiple]="false"
                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal1']}}"
                            [(ngModel)]="jrnlLineObj['event_code']" (change)="setAllCode()" [selectableGroup]="true"
                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="event_code"
                            #event_code="ngModel" [ngClass]="{ 'is-invalid': f.submitted && event_code.invalid }"
                            required>
                        </ng-select><!-- Select Event -->


                        <!--------------------------------------------- validation Start ------------------- -->

                        <!-- border color green -->
                        <div [class.selection-box]="event_code.dirty && event_code.valid">
                        </div>

                        <!-- error handling  -->
                        <div *ngIf="f.submitted && event_code.invalid" class="error-message">
                            <div *ngIf="event_code.errors?.['required']"> Please select event </div>
                        </div>

                        <!--------------------------------------------- validation End ------------------- -->




                    </div>
                    <br> <br>
                    <div class="col-3 text-left">
                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal35']}}
                            <!-- Event Id -->:
                        </h6>
                    </div>

                    <div class="col-9 text-left">
                        {{jrnlLineObj['event_id']}}
                    </div>
                    <br> <br>

                </div>
                <div class="row">
                    <div class="col-3 text-left">
                        <h6>Project Code : </h6>
                    </div>
                    <div class="col-9 ">
                        {{projectHier[jrnlLineObj['proj_cd']]}}
                    </div>
                    <br> <br>
                    <div class="col-3 text-left">
                        <h6>Product Code : </h6>
                    </div>

                    <div class="col-9">
                        {{productHier[jrnlLineObj['prod_cd']]}}
                    </div>
                    <br> <br>
                    <div class="col-3 text-left">
                        <h6>Activity Code :</h6>
                    </div>
                    <div class="col-9 ">
                        {{activityHier[jrnlLineObj['act_cd']]}}
                    </div>
                    <br> <br>
                    <div class="col-3 text-left">
                        <h6> Budget Code : </h6>
                    </div>
                    <div class="col-9">
                        {{budgetHier[jrnlLineObj['bud_cd']]}}
                    </div>
                    <br> <br>
                </div>

                <div class="row">
                    <div class="col-3 text-left">
                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal36']}}
                            <!-- Select Party --> :
                        </h6>
                    </div>

                    <div class="col-9 ">
                        <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                            [multiple]="false" placeholder="Select Party" [(ngModel)]="jrnlLineObj['ar_cntr_ip_id']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="ar_cntr_ip_id" #cntr_ip="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && cntr_ip.invalid }" required>
                        </ng-select>

                        <!--------------------------------------------- validation Start ------------------- -->

                        <!-- border color green -->
                        <div [class.selection-box]="cntr_ip.dirty && cntr_ip.valid">
                        </div>

                        <!-- error handling  -->
                        <div *ngIf="f.submitted && cntr_ip.invalid" class="error-message">
                            <div *ngIf="cntr_ip.errors?.['required']"> Please select party</div>
                        </div>

                        <!--------------------------------------------- validation End ------------------- -->



                    </div>

                    <br> <br>
                </div>

                <hr>


            </div>


            <div class="modal-footer">
                <!--  <button type="button" class="btn btn-primary" (click)="ok()">OK</button> -->
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="saveline()">{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal38']}}
                    <!-- Save -->
                </button>
            </div>
        </div>
    </div>
</div>




<!------------------------------------- BEFORE VALIDATION -------------------- -->


<!--
<div class="tab-pane fade" id="tab-3">
    <div>
        <br>

        <div class="row" style="margin-left: 2%;margin-right: 2%;">
            <div class="col-2 text-left">
                <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal6']}}
                    <!-- Organization -> :
                </b>
            </div>
            <div class="col-10">
                {{this.mainService.accInfo['account_name']}}
                ({{this.mainService.accInfo['account_short_name']}})
            </div>
        </div>
        <br>
        <hr>
        <div class="row" style="margin-left: 2%;margin-right: 2%;">
            <div class="col-2 text-left">
                <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal19']}}
                    <!-- Journal Id ->:
                </b>
            </div>
            <div class="col-2">
                {{jrnl_id}}
            </div>

            <div class="col-2">
                <b>
                    Preparer Name:
                </b>
            </div>
            <div class="col-1">
                {{allUserRoles[obj['prep_id']]}}
            </div>
            <div class="col-2">
                <b>
                    Approver Name:
                </b>
            </div>
            <div class="col-3">
                <!--  <input class="form-control" type="text" [(ngModel)]="obj['appr_id']"> ->
                {{allUserRoles[obj['appr_id']]}}
            </div>

        </div>
        <br>
        <hr>
        <div class="row" style="margin-left: 2%;margin-right: 2%;">
            <div class="col-2 text-left">
                <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal20']}}
                    <!-- Accounting Date ->:
                </b>
            </div>
            <div class="col-3">
                <input class="form-control" type="date" [(ngModel)]="obj['acct_dt']">
            </div>

            <div class="col-2">
                <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal21']}}
                    <!-- Remark -> :
                </b>
            </div>
            <div class="col-5">
                <textarea style="width: 100%;" id="re" name="re" class="form-control"
                    [(ngModel)]="obj['preparer_comment']"></textarea>
            </div>
        </div>
        <br>
        <hr>
        <div class="row" style="margin-left: 2%;margin-right: 2%;">
            <div class="col-2 text-left">
                <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal22']}}
                    <!-- Total Credits -> :
                </b>
            </div>
            <div class="col-2 text-left">
                <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal23']}}
                    <!-- Total Debits ->:
                </b>
            </div>
            <div class="col-2 text-left">
                <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal24']}}
                    <!-- Currency ->:
                </b>
            </div>
            <!-- <div class="col-3 text-left">

            </div> ->
            <div class="col-3 text-left">
                <b>{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal18']}}
                    <!-- Status ->:
                </b>
            </div>
        </div>
        <br><br>
        <div class="row" style="margin-left: 2%;margin-right: 2%;">
            <div class="col-2 text-left">
                {{cr}}
            </div>
            <div class="col-2 text-left">
                {{db}}

            </div>
            <div class="col-2 text-left">
                {{obj['tgt_curr_cd']}}
            </div>
            <div class="col-3 text-left">

            </div>
            <div class="col-3 text-left">

                {{obj['status']}}

            </div>
        </div>
        <br>



    </div>

    <br>


    <hr>
    <div class="row">



        <div class="col-1 text-center">
            {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal26']}}
            <!-- S NO ->:
        </div>
        <div class="col-3 text-center">
            {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal27']}}
            <!-- Chart Of Account ->
        </div>
        <div class="col-2 text-center">
            {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal28']}}
            <!-- Select Debit Credit Ind ->
        </div>
        <div class="col-2 text-center">
            {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal29']}}
            <!-- Amount ->
        </div>
        <div class="col-3 text-center">
            {{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal21']}}
            <!-- Remark ->
        </div>


        <div class="col-1">
            <button class="btn btn-primary" (click)="addRowCredit()"><i class="fa fa-plus"
                    aria-hidden="true"></i></button>

        </div>

    </div>
    <hr>
    <br>
    <table>


        <div class="row" *ngFor="let ln of AllJournalRow_credit; let i = index;">
            <br>

            <div class="col-1 text-center">
                {{i+1}}
            </div>
            <div class="col-3 text-center">
                <ng-select [items]="allChartOfAccount" bindLabel="leaf_value"
                    bindValue="leaf_code" [multiple]="false"
                    placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal30']}}"
                    (change)="changecoa(i)" [(ngModel)]="ln['chart_of_account']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                    [closeOnSelect]="true" name="field_name">
                </ng-select><!-- Select Chart Of Account ->
            </div>
            <div class="col-2 text-center">
                <ng-select [items]="allDBIND" bindLabel="value" bindValue="code"
                    [multiple]="false"
                    placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Manage_Journal31']}}"
                    (change)="changedbcr(i)" [(ngModel)]="ln['db_cd_ind']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                    [closeOnSelect]="true" name="field_name">
                    <!-- Select Indicater ->
                </ng-select>
            </div>
            <div class="col-2 text-center">
                <input class="form-control" type="number" (change)="changeamt(i)"
                    [(ngModel)]="ln['txn_amt']">


            </div>
            <div class="col-3 text-center">

                <textarea style="width: 100%;" id="re" name="re" class="form-control"
                    [(ngModel)]="ln['remark']"></textarea>

            </div>
            <div class="col-1 text-center">
                <button class="btn btn-danger " type="button" (click)="deleteRowCredit(i)"><i
                        class="fa fa-trash" aria-hidden="true"></i></button>

                <button class="btn btn-primary "  type="button" data-toggle="modal" data-target="#change"
                    data-backdrop="false" (click)="changeRowCredit(i)"><i
                        class="fa fa-edit"></i></button>
            </div>
        </div>

    </table>

    <br>
    <div class="row">
        <div class="col-12 text-center">
            <button class="btn btn-primary" type="button" (click)="checkOnUpdate()">Check
            </button>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="update()">
                Update
            </button>
        </div>
    </div>
</div> -->
