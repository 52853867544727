<!-- Tree-map -->

<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD1']}} ,{{ebillUser.party_name}} !</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                    {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD2']}} <!-- At A Glance --></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-spinner" aria-hidden="true"></i>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD3']}} <!-- Physical Progress --></a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="fa fa-money"></i>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD4']}} <!-- Financial
                                Progress --></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab"><i class="fa fa-bar-chart"></i>
                                {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD5']}} <!-- Performance --></a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <div class="row">
                                <!-- <div class="col-lg-3 col-md-6">
                                    <div class="ibox bg-success color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{emb_count}}</h2>
                                            <div class="m-b-5">ALL EMB</div><i class="fa fa-tasks widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen(1)">VIEW</button>

                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-info color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{party_count}}</h2>
                                            <div class="m-b-5">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD6']}} <!-- All VENDORS / CONTRACTORS --> </div><i class="fa fa-users widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen(2)">VIEW</button>
                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-warning color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{ebill_count}}</h2>
                                            <div class="m-b-5">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD7']}}<!-- ALL E-BILLS --></div><i class="fa fa-remove widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen2(3)">VIEW</button>

                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small>22% higher</small></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-primary color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{work_count}}</h2>
                                            <div class="m-b-5">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD8']}}<!-- ALL WORKS --></div><i class="fa fa-remove widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen3(3)">VIEW</button>

                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small>22% higher</small></div> -->
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="row">
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD9']}}  <!-- Physical -->
                                        </div>
                                    </div>
                                    <div class="row" style="align-items: center;">
                                        <div id="chart "  style="width: 100%;">
                                            <!--oc-->
                                            <!-- <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [plotOptions]="chartOptions.plotOptions" [title]="chartOptions.title" [legend]="chartOptions.legend" [dataLabels]="chartOptions.dataLabels"  ></apx-chart> -->
                                            <!--ec-->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="physicaltab()">View</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD10']}}  <!--  Financial -->

                                        </div>

                                    </div>
                                    <div style="text-align: center;">
                                       <!--  {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD11']}} -->   ( Work Orders - Orig. BOQ and all approved deviations.<br> Work - Value of all completed measurement till now )
                                    </div>
                                    <div class="row"  style="text-align: center;">
                                        <div id="chart">
                                            <!-- <apx-chart [series]="chartOptions2.series" [chart]="chartOptions2.chart"
                                                [dataLabels]="chartOptions2.dataLabels"
                                                [plotOptions]="chartOptions2.plotOptions" [yaxis]="chartOptions2.yaxis">
                                            </apx-chart> -->

                                            <!-- oc  -->
                                            <!-- <canvas baseChart [datasets]="barChartData1b" [labels]="barChartLabels2_1" [options]="barChartOptions" [plugins]="barChartPlugins" [colors]="barChartColors" [legend]="barChartLegend" height="370px" width="500px" [chartType]="barChartType">
                                            </canvas> -->
                                            <!-- ec  -->
                                            <highcharts-chart
                                        [Highcharts]="Highcharts"
                                        [options]="chartOptionss"
                                        id="chart-container"
                                        
                                        style="width: 100%; height: 400px; display: block;"
                                      ></highcharts-chart>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="financialtab()">View</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD12']}}    <!-- Performance -->
                                        </div>
                                    </div>
                                    <div  style="text-align: center;">
                                    <!-- ( {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD13']}} )  -->   ( EMB - Measurement till EMB approval. <br> now EBill - EMB approval to EBill approval )
                                    </div>
                                    <div class="row">
                                        <div id="chart" style="width: 100%;">
                                            <!--oc-->
                                             <!-- <apx-chart [series]="chartOptions3.series" [chart]="chartOptions3.chart" [plotOptions]="chartOptions3.plotOptions" [xaxis]="chartOptions3.xaxis">
                                            </apx-chart>  -->
                                            <!--ec-->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="performancetab()">View</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>

                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <!-- <div class="col-lg-3 col-md-6">
                                    <div class="ibox bg-success color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{emb_count}}</h2>
                                            <div class="m-b-5">ALL EMB</div><i class="fa fa-tasks widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen(1)">VIEW</button>

                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-info color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{running_late}}</h2>
                                            <div class="m-b-5">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD14']}}<!-- Running Late --> </div><i class="fa fa-users widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen2a(2)">VIEW</button>

                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-warning color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{in_progress_count}}</h2>
                                            <div class="m-b-5">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD15']}}<!-- In Progress --></div><i class="fa fa-remove widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen2b(3)">VIEW</button>

                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small>22% higher</small></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-primary color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{work_count}}</h2>
                                            <div class="m-b-5">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD16']}}<!-- ALL WORKS --></div><i class="fa fa-remove widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen3(3)">VIEW</button>

                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small>22% higher</small></div> -->
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="row">

                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD17']}}  <!-- Top 3 Late Work by Delay Duration -->
                                        </div>
                                    </div>
                                    <div>
                                        ( {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD18']}}a ) <!-- Delay Duration - Num of days from work end date till now. -->
                                    </div>
                                    <div class="row">
                                      <!-- oc  -->
                                        <!-- <canvas baseChart [datasets]="barChartData2a" [labels]="barChartLabels2a" [options]="barChartOptions" [plugins]="barChartPlugins"  [legend]="barChartLegend" height="300px"  >
                                        </canvas> -->
                                        <!-- ec  -->
                                        <highcharts-chart
                                        [Highcharts]="Highcharts"
                                        [options]="chartOptionss"
                                        id="chart-container"
                                        
                                        style="width: 100%; height: 400px; display: block;"
                                      ></highcharts-chart>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="modalOpen2ba()">Viewa</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD19']}}   <!--  Top 3 Late Work by Completion % -->
                                        </div>
                                    </div>
                                    <div>
                                        ( {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD20']}} )<!-- Delay Duration - Num of days from work end date till now. -->
                                    </div>
                                    <div class="row">
                                      <!-- oc  -->
                                        <!-- <canvas baseChart [datasets]="barChartData2b" [labels]="barChartLabels2a" [options]="barChartOptions" [plugins]="barChartPlugins" [colors]="barChartColors2" [legend]="barChartLegend" height="300px" [chartType]="barChartType">
                                        </canvas> -->
                                        <!-- ec  -->
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="modalOpen2bb()">View</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD21']}}   <!-- Top 3 Work by Financial Deviation % -->
                                        </div>
                                    </div>
                                    <div>
                                        ( {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD22']}} )<!-- Delay Duration - Num of days from work end date till now. -->
                                    </div>

                                    <div class="row">
                                      <!-- oc  -->
                                        <!-- <canvas baseChart [datasets]="barChartData2c" [labels]="barChartLabels2c" [options]="barChartOptions" [plugins]="barChartPlugins" [colors]="barChartColors" [legend]="barChartLegend" height="300px" [chartType]="barChartType">
                                        </canvas> -->
                                        <!-- ec  -->
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="modalOpen2bc()">View</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <br>
                            <div class="row">
                                <!-- <div class="col-lg-3 col-md-6">
                                    <div class="ibox bg-success color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{emb_count}}</h2>
                                            <div class="m-b-5">ALL EMB</div><i class="fa fa-tasks widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen(1)">VIEW</button>

                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-lg-4 col-md-6">

                                    <div class="ibox bg-info color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">Rs . {{bill_expence}} INR</h2>
                                            <div class="m-b-5">Approved Ebill </div><i class="fa fa-users widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen3a(2)">VIEW</button>

                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-warning color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">Rs . {{emb_expence}} INR</h2>
                                            <div class="m-b-5">Approved Emb</div><i class="fa fa-remove widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen3b(3)">VIEW</button>

                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small>22% higher</small></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-primary color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">Rs . {{work_amt}} INR</h2>
                                            <div class="m-b-5">All Work</div><i class="fa fa-remove widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen3(1)">VIEW</button>

                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small>22% higher</small></div> -->
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD23']}} <!--  Budget versus Work Orders (Top 3) -->
                                        </div>
                                    </div>
                                    <div>
                                        (  {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD24']}} )<!-- Budget - Any budget line item used for funding works.Work Order - Orig BOQ and all approved Deviations. -->
                                    </div>
                                    <div class="row">
                                      <!-- oc  -->
                                        <!-- <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" height="300px" [chartType]="barChartType">
                                        </canvas> -->
                                        <!-- ec  -->
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="myModal2ab1()">View</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD25']}} <!--  Work Order Utilization (Top 3) -->
                                        </div>
                                    </div>
                                    <div>
                                        (  {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD26']}} )<!-- EMB - Approved embs amount as % of work. EBill - Approved EBill amount as % of work -->
                                    </div>
                                    <div class="row">
                                      <!-- oc  -->
                                        <!-- <canvas baseChart [datasets]="barChartData2" [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" height="300px" [chartType]="barChartType">
                                        </canvas> -->
                                        <!-- ec  -->
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary">View</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD27']}}   <!-- Over Utilization ( Top 3 ) -->
                                        </div>
                                    </div>
                                    <div>
                                        ( {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD28']}}  )<!-- Work exceeding Work Order requiring deviations. -->
                                    </div>
                                    <div class="row">
                                      <!-- oc  -->
                                        <!-- <canvas baseChart [datasets]="barChartData2c" [labels]="barChartLabels2c" [options]="barChartOptions" [plugins]="barChartPlugins" [colors]="barChartColors" [legend]="barChartLegend" height="300px" [chartType]="barChartType">
                                        </canvas> -->
                                        <!-- ec  -->
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="myModal2ab2()">View</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <div class="row">
                                <!-- <div class="col-lg-3 col-md-6">
                                    <div class="ibox bg-success color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{emb_count}}</h2>
                                            <div class="m-b-5">ALL EMB</div><i class="fa fa-tasks widget-stat-icon"></i>
                                            <button class="btn btn-primary" (click)="modalOpen(1)">VIEW</button>

                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-info color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{avg_emb_apr_days}}</h2>
                                            <br>
                                            <div class="m-b-5">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD29']}}<!-- Average number of days for emb approval  --></div><i class="fa fa-users widget-stat-icon"></i>
                                            <!-- <button class="btn btn-primary" (click)="modalOpen3a(2)">VIEW</button> -->

                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-warning color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong"> {{avg_ebilll_apr_days}}</h2>
                                            <br>
                                            <div class="m-b-5">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD30']}}<!-- Average number of days for ebill approval --></div>
                                            <i class="fa fa-remove widget-stat-icon"></i>
                                            <!-- <button class="btn btn-primary" (click)="modalOpen3b(3)">VIEW</button> -->

                                            <!-- <div><i class="fa fa-level-up m-r-5"></i><small>22% higher</small></div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="ibox bg-primary color-white widget-stat">
                                        <div class="ibox-body">
                                            <h2 class="m-b-5 font-strong">{{login_User['name']}}</h2><i class="fa fa-remove widget-stat-icon"></i>
                                            <div class="m-b-5 font-strong" *ngIf="login_User['user_id']">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD31']}}<!-- Maximum Avergae Approval Time --></div>
                                            <!-- <h2 class="m-b-5 font-strong" *ngIf="login_User['user_id']">{{login_User['name']}}</h2> -->
                                            <!-- <h2 class="m-b-5 font-strong" *ngIf="!login_User['user_id']">You Did Not Approved Any File Yet</h2> -->
                                            <!-- <h2 class="m-b-5 font-strong" *ngIf="!login_User['user_id']"></h2> -->
                                            <br>

                                            <!-- <div class="m-b-5">Average Approval Time</div><i
                                                class="fa fa-remove widget-stat-icon"></i> -->
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD32']}}  <!-- EMB Approval Time in Days (Top 3) -->
                                        </div>
                                    </div>
                                    <div>
                                        ( {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD33']}} ) <!--  Average of time taken for all measurements included in the approved EMBs from measurement date to EMB approval date.  -->
                                    </div>
                                    <div class="row">
                                      <!-- oc  -->
                                        <!-- <canvas baseChart [datasets]="barChartData4a" [labels]="chart_data4a_lebel" [options]="barChartOptions" [plugins]="barChartPlugins" [colors]="barChartColors" [legend]="barChartLegend" height="300px" [chartType]="barChartType">
                                        </canvas> -->
                                        <!-- ec  -->
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="modalOpen4a()">View</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD34']}} <!-- EBILL Approval Time in Days (Top 3) -->
                                        </div>
                                    </div>
                                    <div>
                                        ( {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD35']}} )<!-- Average of time taken in weeks for all EBills approvals from EMB approval date to EBill approval Date. -->
                                    </div>
                                    <div class="row">
                                      <!-- oc  -->
                                        <!-- <canvas baseChart [datasets]="barChartData4b" [labels]="chart_data4b_lebel" [options]="barChartOptions" [plugins]="barChartPlugins" [colors]="barChartColors2" [legend]="barChartLegend" height="300px" [chartType]="barChartType">
                                        </canvas> -->
                                        <!-- ec  -->

                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="modalOpen4b()">View</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <div class="col-lg-4 col-md-6" style="border: solid;">
                                    <br>
                                    <div class="row bg-primary color-white">
                                        <div class="col-12 text-center">
                                            {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD36']}}  <!--  Average File Approval Time (Top 3) -->
                                        </div>
                                    </div>
                                    <div>
                                        ( {{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD37']}} ) <!-- Average of all time taken in days from workflow file assignment to action time clearing the file. -->
                                    </div>
                                    <div class="row">
                                      <!-- oc -->
                                        <!-- <canvas baseChart [datasets]="barChartData4c" [labels]="barChartLabels4c" [options]="barChartOptions" [plugins]="barChartPlugins" [colors]="barChartColors" [legend]="barChartLegend" height="300px" [chartType]="barChartType">
                                        </canvas> -->
                                        <!-- ec  -->
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-primary" (click)="modalOpen4c()">View</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD38']}}<!-- All Party / Vendors --></h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD39']}}<!-- Party ID --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD40']}}<!-- Party Type --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD41']}}<!-- Party Name --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD42']}}<!-- Email --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD43']}}<!-- Mobile Number --></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of party">
                                <td>{{item['party_id']}}</td>
                                <td>{{item['party_type_cd']}}</td>
                                <td>{{item['party_leagal_name']}}</td>
                                <td>{{item['party_email']}}</td>
                                <td>{{item['party_phone_no']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD16']}}<!-- All Work --></h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD44']}}<!-- Work Order Number --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD45']}}<!-- Work Order Name --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD46']}}<!-- Work Order Value --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD47']}}<!-- Start Date --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD48']}}<!-- End Date --></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of work_list">
                                <td>{{item['work_order_no']}}</td>
                                <td>{{item['work_order_name']}}</td>
                                <td>{{item['work_order_value']}}</td>
                                <td>{{item['start_date']}}</td>
                                <td>{{item['end_date']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2a">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Late Work</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD44']}}<!-- Work Order Number --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD45']}}<!-- Work Order Name --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD46']}}<!-- Work Order Value --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD47']}}<!-- Start Date --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD48']}}<!-- End Date --></th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of running_late_data">
                                <td>{{item['work_order_no']}}</td>
                                <td>{{item['work_order_name']}}</td>
                                <td>{{item['work_order_value']}}</td>
                                <td>{{item['start_date']}}</td>
                                <td>{{item['end_date']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2ab1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Budget versus Work Orders</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD44']}}<!-- Work Order Number --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD45']}}<!-- Work Order Name --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD46']}}<!-- Work Order Value --></th>


                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD49']}}<!-- work Order amount --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD50']}}<!-- Budget Name --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD51']}}<!-- Budget Code --></th>
                                <!-- <th>Budget Code</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of workmodal">
                                <td>{{item['work_order_no']}}</td>
                                <td>{{allworklist[item['work_id']]['work_order_name']}}</td>
                                <td>{{item['budget_value']}}</td>
                                <td>{{item['amount']}}</td>
                                <td>{{item['budget_name']}}</td>
                                <td>{{item['budget_cd']}}</td>

                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModalChart11">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">{{chart1_list_msg}}</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Work Order Number</th>
                                <th>Work Order Name</th>
                                <th>Work Order Value</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of chart1_list">
                                <td>{{item['work_order_no']}}</td>
                                <td>{{item['work_order_name']}}</td>
                                <td>{{item['work_order_value']}}</td>
                                <td>{{item['start_date']}}</td>
                                <td>{{item['end_date']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2ab2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Work exceeding Work Order requiring deviations</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD44']}}<!-- Work Order Number --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD45']}}<!-- Work Order Name --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD46']}}<!-- Work Order Value --></th>

                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD53']}}<!-- Deviation Percentage --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD47']}}<!-- Start Date --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD48']}}<!-- End Date --></th>

                                <!-- <th>Budget Code</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of dev_work_for_modal">
                                <td>{{item['work_order_no']}}</td>
                                <td>{{item['work_order_name']}}</td>
                                <td>{{item['work_order_value']}}</td>
                                <td>{{(item['per']).toFixed(4)}}</td>
                                <td>{{item['start_date']}}</td>
                                <td>{{item['end_date']}}</td>

                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2b">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">In Progress Work</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD44']}}<!-- Work Order Number --></th>

                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD46']}}<!-- Work Order Value --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD47']}}<!-- Start Date --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD48']}}<!-- End Date --></th>>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of in_progress_data">
                                <td>{{item['work_order_no']}}</td>
                                <!-- <td>{{item['work_order_name']}}</td> -->
                                <td>{{item['work_order_value']}}</td>
                                <td>{{item['start_date']}}</td>
                                <td>{{item['end_date']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2ba">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Running Late Work</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD54']}}<!-- Days --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD44']}}<!-- Work Order Number --></th>
                                 <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD45']}}<!-- Work Order Name --></th>
                                   <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD46']}}<!-- Work Order Value --></th>
                                   <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD47']}}<!-- Start Date --></th>
                                   <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD48']}}<!-- End Date --></th>>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of running_late_data">
                                <td>{{item['days']}}</td>

                                <td>{{item['work_order_no']}}</td>
                                <td>{{item['work_order_name']}}</td>
                                <td>{{item['work_order_value']}}</td>
                                <td>{{item['start_date']}}</td>
                                <td>{{item['end_date']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2bb">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Late Work By Completion(%)</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD55']}}<!-- Delay(%) --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD44']}}<!-- Work Order Number --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD45']}}<!-- Work Order Name --></th>
                                  <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD46']}}<!-- Work Order Value --></th>
                                  <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD47']}}<!-- Start Date --></th>
                                  <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD48']}}<!-- End Date --></th>>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of rauuning_late_by_per">
                                <td>{{item['amt'].toFixed(3)}}</td>
                                <td>{{item['work_order_no']}}</td>
                                <td>{{item['work_order_name']}}</td>
                                <td>{{item['work_order_value']}}</td>
                                <td>{{item['start_date']}}</td>
                                <td>{{item['end_date']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2bc">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Running Late Work By Daviation</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD55']}}<!-- Delay(%) --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD44']}}<!-- Work Order Number --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD45']}}<!-- Work Order Name --></th>
                                  <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD46']}}<!-- Work Order Value --></th>
                                  <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD47']}}<!-- Start Date --></th>
                                  <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD48']}}<!-- End Date --></th>>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of dev_late_work">
                                <td>{{item['per'].toFixed(3)}}</td>
                                <td>{{item['work_order_no']}}</td>
                                <td>{{item['work_order_name']}}</td>
                                <td>{{item['work_order_value']}}</td>
                                <td>{{item['start_date']}}</td>
                                <td>{{item['end_date']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal4a">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Average number of days for emb approval</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>S No.</th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD56']}}</th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD44']}}<!-- Work Order Number --></th>
                                <!-- <th>Work Order Name</th>chart_data4a -->
                                <!-- <th>Work Order Value</th> -->
                                <!-- <th>Start Date</th>
                                <th>End Date</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data_for_4a_tab;let i=index">
                                <td>{{i+1}}</td>
                                <td>{{item['avg_days'].toFixed(3)}}</td>
                                <td>{{item['work_order_no']}}</td>
                                <!-- <td>{{item['work_order_name']}}</td> -->
                                <!-- <td>{{item['work_order_value']}}</td> -->
                                <!-- <td>{{item['start_date']}}</td>  -->
                                <!-- <td>{{item['end_date']}}</td>  -->
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal4b">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Average number of days for ebill approval</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>S No.</th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD56']}}</th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD44']}}<!-- Work Order Number --></th>
                                <!-- <th>Work Order Name</th>chart_data4a -->
                                <!-- <th>Work Order Value</th> -->
                                <!-- <th>Start Date</th>
                                <th>End Date</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data_for_4b_tab;let i=index">
                                <td>{{i+1}}</td>
                                <td>{{item['avg_days'].toFixed(3)}}</td>
                                <td>{{item['work_order_no']}}</td>
                                <!-- <td>{{item['work_order_name']}}</td> -->
                                <!-- <td>{{item['work_order_value']}}</td> -->
                                <!-- <td>{{item['start_date']}}</td>  -->
                                <!-- <td>{{item['end_date']}}</td>  -->
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">All Bill</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD57']}}<!-- Bill ID --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD58']}}<!-- Bill Description --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD59']}}<!-- Bill Amount --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD60']}}<!-- Status --></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of billInfo">
                                <td>{{item['id']}}</td>
                                <td>{{item['bill_desc']}}</td>
                                <td>{{item['bill_amt']}}</td>
                                <td>{{item['status']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal3a">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">All Approved Ebill</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD61']}}<!-- Work ID --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD57']}}<!-- Bill ID --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD59']}}<!-- Bill Amount --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD60']}}<!-- Status --></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of allApproved_EBILL">
                                <td>{{item['work_id']}}</td>
                                <td>{{item['ebill_id']}}</td>
                                <td>{{item['bill_amt']}}</td>
                                <td>{{item['s']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal3b">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">All Approved EMB</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD61']}}<!-- Work ID --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD62']}}<!-- Emb ID --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD63']}}<!-- EMB Amount --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD60']}}<!-- Status --></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of allApproved_EMB">
                                <td>{{item['work_id']}}</td>
                                <td>{{item['emb_id']}}</td>
                                <td>{{item['amount_bill']}}</td>
                                <td>APPROVED</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal4c">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">List Of Average Time For Approval</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD54']}}<!-- Days --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD64']}}<!-- User Description --></th>
                                <th>{{main.allLableShowObj[main.language_cd+'EMB-DASHBOARD65']}}<!-- Name --></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tab4c_data">
                                <td>{{item['days']}}</td>
                                <td>{{item['desc']}}</td>
                                <td>{{item['name']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
