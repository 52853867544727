
<div class="page-content fade-in-up">
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Compounding</div>
          </div>
          <div class="ibox-body">
            <ul class="nav nav-tabs tabs-line">
              <li class="nav-item">
                <a class="nav-link active"  data-toggle="tab"><i class="fa fa-bars" ></i>&nbsp;
                   Compounding List</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" *ngIf="complaint_inspection" data-toggle="tab"><i class="fa fa-plus-circle"
                    aria-hidden="true"></i> Assign complaint inspection
                </a>
              </li> -->

            </ul>
            <div class="tab-content">
              <div >

                <br>
                <div class="row">
                  <div class="col-4">
                    <mat-form-field style="margin-left: 2%">
                      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                    </mat-form-field>
                  </div>

                </div>


                <div class="example-container">

                  <table mat-table [dataSource]="datasource" matSort class="text-center">
                    <ng-container matColumnDef="s.no">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                          S.No
                      </th>
                      <td mat-cell *matCellDef="let element ; let i = index "> {{i+1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="order_id">
                        <th mat-header-cell *matHeaderCellDef class="text-center">Order ID
                        </th>
                        <td mat-cell flex-stretch *matCellDef="let element">
                          {{element.order_id}} </td>
                      </ng-container>

                    <!-- <ng-container matColumnDef="case_ID">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Case ID
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="order_date">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Order Date
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                       {{element.co_ord_date}} </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="order_type">
                      <th mat-header-cell *matHeaderCellDef class="text-center"> Order Type
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                       </td>
                    </ng-container> -->

                    <!-- <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Date
                        Date
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                         </td>
                    </ng-container> -->

                    <ng-container matColumnDef="compound_area">
                        <th mat-header-cell *matHeaderCellDef class="text-center">Compound Area
                        </th>
                        <td mat-cell flex-stretch *matCellDef="let element">  {{element.comp_area}}
                          </td>
                      </ng-container>

                    <ng-container matColumnDef="compound_fee">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Compounding Fee
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">{{element.comp_fee}}
                         </td>
                    </ng-container>

                    <ng-container matColumnDef="payment_status">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Payment Status
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">{{element.arr_status_code}}
                         </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="payment_status">
                        <th mat-header-cell *matHeaderCellDef class="text-center">Payment Status
                        </th>
                        <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.arr_status_code}}     </td>
                      </ng-container> -->

                      <ng-container matColumnDef="doc_status">
                        <th mat-header-cell *matHeaderCellDef class="text-center">Doc Status
                        </th>
                        <td mat-cell flex-stretch *matCellDef="let element">{{element.life_cycle_status}}
                          </td>
                      </ng-container>

                      <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
                        <td mat-cell flex-stretch *matCellDef="let element">
                          <button  class="btn btn-outline-primary" (click)="view_details(element)">View Details</button>&nbsp;&nbsp;
                            <button  class="btn btn-outline-primary" (click)="view_document(element)">View Document</button>&nbsp;&nbsp;
                          <button
                            class="btn btn-outline-primary" (click)="Processing(element)" *ngIf="element['life_cycle_status']=='SUBMITED' && element['arr_status_code']='SUCCESS' "
                            >Processing</button> &nbsp;&nbsp;

                          <button  class="btn btn-outline-success"
                           (click)="Appr(element)" *ngIf="element['life_cycle_status']=='ACCEPTED' && element['arr_status_code']='SUCCESS' " >Add Approval</button>
                        </td>
                      </ng-container>





                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

                    </tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                </div>

              </div>




            </div>
            <br>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="modal fade" id="details" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #AED6F1;">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>Compounding Details</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mt-3">
          <div class="col-2"></div>
          <div class="col-2">
            <h6>Order ID</h6>
          </div>:
          <div class="col-2">{{compounding_details.order_id}}</div>
          <div class="col-1"></div>
          <div class="col">
            <h6>Case ID</h6>
          </div>:
          <div class="col">{{compounding_details.case_id}}</div>
          <div class="col-1"></div>
        </div>
        <div class="row mt-3">
          <div class="col-2"></div>
          <div class="col-2">
            <h6>Order Date</h6>
          </div>:
          <div class="col-2">{{compounding_details.co_ord_date}}</div>
          <div class="col-1"></div>
          <div class="col">
            <h6>Compounding Date</h6>
          </div>:
          <div class="col">{{compounding_details.comp_date}}</div>
          <div class="col-1"></div>
        </div>
        <div class="row mt-3">
          <div class="col-2"></div>
          <div class="col-2">
            <h6>Compounding Area</h6>
          </div>:
          <div class="col-2">{{compounding_details.comp_area}}</div>
          <div class="col-1"></div>
          <div class="col">
            <h6>Compounding Fees</h6>
          </div>:
          <div class="col">{{compounding_details.comp_fee}}</div>
          <div class="col-1"></div>
        </div>
        <div class="row mt-3">
          <div class="col-2"></div>
          <div class="col-2">
            <h6>Payment Status</h6>
          </div>:
          <div class="col-2">{{compounding_details.arr_status_code}}</div>
          <div class="col-1"></div>
          <div class="col">
            <h6>Document Status</h6>
          </div>:
          <div class="col">{{compounding_details.life_cycle_status}}</div>
          <div class="col-1"></div>
        </div>



      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

      </div>

    </div>
  </div>
</div>
<div class="modal fade" id="map_processing" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
  <div class="modal-content">
    <div class="modal-header" style="background-color: #AED6F1;">
      <h5 class="modal-title" id="exampleModalLongTitle">
        <h6>Processing The Revised Map Approval</h6>
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     <div class="row mt-3">
      <div class="col-3"></div>
      <div class="col-3">Revised Map Status:<span style="color: red">*</span></div>
      <div class="col-2">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" (change)="which_id_type('ACCEPTED')">
        <label class="form-check-label" for="flexRadioDefault1">
          Accepted
        </label>
      </div>
      <div class="col-2">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" (change)="which_id_type('REJECTED')">
        <label class="form-check-label" for="flexRadioDefault1">
          Rejected
        </label>
      </div>
      <div class="col-2"></div>
     </div>
     <div class="row mt-3">
      <div class="col-3"></div>
      <div class="col-3">
        Document verification:<span style="color: red">*</span>
      </div>
      <div class="col-4">
        <ng-select [items]="decision" [multiple]="false"
                placeholder="Verified / Not Verified" bindLabel="desc" bindValue="obj"
                [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy"
                [(ngModel)]="assign['doc_verification']">
              </ng-select>
      </div>

      <div class="col-2"></div>
     </div>
     <div class="row mt-3">
      <div class="col-3"></div>
      <div class="col-3">Reason:<span style="color: red">*</span></div>
      <div class="col-4"> <input type="text" class="form-control" [(ngModel)]="assign['reason']" placeholder="Reason about action" ></div>
      <div class="col-2"></div>
     </div>
     <div class="row mt-3">
      <div class="col-3"></div>
      <div class="col-3">Remark:</div>
      <div class="col-4">
        <textarea class="form-control" rows="4" cols="50" type="text" name="note" maxlength="150"
        [(ngModel)]="assign['remark']" placeholder="Remark"></textarea>
      </div>
      <div class="col-2"></div>
     </div>

     <div class="row mt-3">
      <div class="col-12 text-center">
        <button  class="btn btn-outline-primary" (click)="submit()" >Submit</button>
      </div>
     </div>
      </div>




    <div class="modal-footer">
      <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

    </div>

  </div>
</div>
</div>

<div class="modal fade" id="def_details" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
  <div class="modal-content">
    <div class="modal-header" style="background-color: #AED6F1;">
      <h5 class="modal-title" id="exampleModalLongTitle">
        <h6>Defendent Document</h6>
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <table  border="1">

        <tr>
            <td><b>S.No</b></td>
            <td><b>Order ID</b></td>
            <td><b>Document</b></td>
            <td><b>Action</b></td>




        </tr>
          <tr *ngFor="let item of def_doc_details ; let i = index">
               <td>{{i+1}}</td>
               <td>{{item['order_id']}}</td>
               <td>{{item['doc_purpose']}}</td>
               <td>
                <button class="btn btn-sm btn-outline-primary" (click)="view_doc_def(i)">View</button>

               </td>



          </tr>



        </table>



    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

    </div>

  </div>
</div>
</div>
<!-- WORKFLOW MODEL -->
<div class="modal" id="workflow">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Approval</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">

        <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
          <div class="col-4 text-right">
            <h6>Select Forwarded To :</h6>
          </div>
          <div class="col-4">
            <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
              [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true"
              [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
            </ng-select>
          </div>


        </div>
        <br>
        <div class="row">
          <div class="col-4 text-right">
            <h6>Remark :</h6>
          </div>
          <div class="col-4">
            <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
              placeholder="Enter Your Remark" class="form-control"></textarea>

          </div>


        </div>
        <br>

        <div>
          <table class="table table-bordered"
            style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
            <thead>
              <tr style="background-color:  white;">
                <th style="width: 20%;">S No.</th>
                <th style="width: 20%;">Decription</th>



                <th style="width: 60%;">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ln of undertakings;let i = index">
                <td>{{i+1}}</td>
                <td>{{ln['desc']}}</td>
                <td style="width: 60%;">
                  <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">
                  <!--  <button class="btn btn-success" (click)="deleteLine(i)"><i class="fa fa-check"
                                      style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button>
                              <button class="btn btn-danger" (click)="copy(ln)">
                                   <i class="fa fa-times"
                                          style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i>

                                      </button> -->
                  <!-- <button class="btn btn-warning" (click)="paste(i)"> -->
                  <!-- <i class="fas fa-paste"
                                          style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->

                  <!-- </button>  -->
                </td>
              </tr>

            </tbody>

          </table>
        </div>
        <br>
        <div class="row" style="border: black;">
          <div class="col-4 text-right">
            <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
          </div>
          <div class="col-4">
            Notify Them
          </div>
        </div>
        <br>
        <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
          <div class="col-12 text-center">
            <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit
            </button>
          </div>
        </div>
        <br>
        <!-- <div *ngIf="vendorflag==true" class="row">
                  <div class="col-12 text-center">
                      <h6>OR</h6>
                  </div>
              </div> -->
        <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
          <div class="col-12 text-center">
            <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
              Vendor</button>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- VIEW WORKFLOW STATUS -->
<div class="modal" id="workflowStatus">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">
          <!-- Status -->Status
        </h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">

        <div class="container">
          <a *ngFor="let ob of statusArr1;let i=index">
            <!-- <h6>{{ob['status']}}</h6> -->
            <div class="step completed" *ngIf="ob.status=='APPROVED'" style="width: 200%;">
              <div class="v-stepper">
                <div class="circle"></div>
                <div class="barblue" style="color: blue;margin-left: 40%;" *ngIf="i <statusArr1.length-1"></div>
              </div>

              <div class="content">
                {{statusArr1.length-i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}} ({{ob.user_name}})

              </div>
            </div>
            <div class="step" *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'" style="width: 200%;">
              <div class="v-stepper">
                <div class="circles"></div>

              </div>

              <div class="content">
                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}} ({{ob.user_name}})

              </div>
            </div>
            <!-- active -->
            <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'" style="width: 200%;">
              <div class="v-stepper">
                <div class="circle"></div>
                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr1.length-1">
                </div>
              </div>

              <div class="content">
                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}} ({{ob.user_name}})

                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                              {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
              </div>
            </div>
            <div class="step" *ngIf="ob.status=='PENDING'" style="width: 200%;">
              <div class="v-stepper">
                <div class="circle"></div>
                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr1.length-1">
                </div>
              </div>

              <div class="content">
                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}} ({{ob.user_name}})

                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                              {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
              </div>
            </div>
          </a>


        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">
            <!-- Close -->Close
          </button>
          <!-- <button type="button" class="btn btn-success" data-dismiss="modal" (click)="certificate()">Download
                      Certificate </button> -->

        </div>

      </div>
    </div>
  </div>
</div>



  <ngx-spinner>Loading....</ngx-spinner>

