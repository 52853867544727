<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Lease Renewal</div>
        </div>
        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link active" *ngIf="list_flag" data-toggle="tab">
                <i class="fa fa-line-chart"></i>Lease Renewal List</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" *ngIf="create_flag" data-toggle="tab">
                <i class="fa fa-plus-circle" aria-hidden="true"></i> Create </a>
            </li>
          </ul>
          <div class="tab-content" *ngIf="list_flag">
            <div class="row">
              <div class="col-12 text-right">
                <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
              </div>
            </div>
            <br>
            <div class="example-container">

              <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="lease type">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Lease Type </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.lease_type}} </td>
                </ng-container>

                <ng-container matColumnDef="file no">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> File Number </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.file_num}} </td>
                </ng-container>

                <ng-container matColumnDef="plot no">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Plot/Khasra no </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{element.assigned_property_number}}</td>
                </ng-container>

                <!-- <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Address Of Property </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.address}} </td>
                </ng-container> -->

                <ng-container matColumnDef="details">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Leasee Details </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.leasee_name}} </td>
                </ng-container>

                <ng-container matColumnDef="area">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Total Area </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">{{element.area}} </td>
                </ng-container>

                <!-- <ng-container matColumnDef="start date">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Lease Start Date </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.lease_start_date |
                    date: 'dd-MM-yyyy' }} </td>
                </ng-container>

                <ng-container matColumnDef="end date">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Lease End Date </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">{{element.lease_end_date }} </td>
                </ng-container>

                <ng-container matColumnDef="duration">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Lease Duration (Year) </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">{{element.lease_duration}} </td>
                </ng-container> -->

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Action</th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    <button class="btn btn-outline-success"
                    (click)='view(element)'>View</button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </div>
            <!-- {{datasource}} -->
          </div>

          <div class="tab-content" *ngIf="create_flag">
            <br>
            <div class="row">
              <div class="col-12 text-right">
                <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
              </div>
            </div>
            <br>
            <div class="row">

              <div class="col-4 text-right">
                <h6 class="mt-2">File Number :<span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <ng-select [multiple]="false" placeholder="File Number" [selectableGroup]="true" [items]="allfile"
                  bindLabel="file_num" bindValue="file_num" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                  [(ngModel)]="Obj['fileno']" (change)="getAllData()">
                </ng-select>
              </div>
              <div class="col-4"></div>

            </div>
            <br>

            <div class="row mt-3" *ngFor="let item of data,let i=index">
              <!-- <div class="col-3"></div> -->
              <div class="col-4 text-right">
                <h6>Select Level {{i+1}} :<span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd" [multiple]="false"
                  placeholder="Select..." [(ngModel)]="Obj['lvl1_cd']" (change)='setLeafNode()' [selectableGroup]="true"
                  [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0" disabled>
                </ng-select>
                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd" [multiple]="false"
                  (change)='setLeafNode()' placeholder="Select..." [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true"
                  [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0" disabled>
                </ng-select>
                <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()' bindLabel="lvl3_value"
                  bindValue="lvl3_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0" disabled>
                </ng-select>
                <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()' bindLabel="lvl4_value"
                  bindValue="lvl4_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0" disabled>
                </ng-select>
                <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()' bindLabel="lvl5_value"
                  bindValue="lvl5_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0" disabled>
                </ng-select>
                <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()' bindLabel="lvl6_value"
                  bindValue="lvl6_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0" disabled>
                </ng-select>
                <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()' bindLabel="lvl7_value"
                  bindValue="lvl7_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0" disabled>
                </ng-select>

              </div>
              <div class="col-3" *ngIf="i==data.length-1">
                <button (click)="add_dropdown(item,i)" class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                    aria-hidden="true"></i></button>
                &nbsp;&nbsp;
                <button (click)="remove_dropdown(item,i)" *ngIf="i!=0" class="btn btn-outline-danger">
                  <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
              </div>
            </div>
            <br>
            <div class="row">

              <div class="col-4 text-right">
                <h6 class="mt-2">Select Plot Number :<span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <ng-select [multiple]="false" placeholder="Total Area of Plot" [selectableGroup]="true"
                  [selectableGroupAsModel]="false" [closeOnSelect]="true" [(ngModel)]="Obj['plotno']" disabled>
                </ng-select>
              </div>
              <div class="col-4"></div>

            </div>
            <br>


            <div class="row">

              <div class="col-4 text-right">
                <h6 class="mt-2">Total Area of Plot :<span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <ng-select [multiple]="false" placeholder="Total Area of Plot" [selectableGroup]="true"
                  [selectableGroupAsModel]="false" [closeOnSelect]="true" [(ngModel)]="Obj['areaOfPlot']" disabled>
                </ng-select>
              </div>
              <div class="col-4"></div>

            </div>
            <br>

            <div class="row">

              <div class="col-4 text-right">
                <h6 class="mt-2">Leasee Name :<span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <ng-select [multiple]="false" placeholder="Leasee Name" [selectableGroup]="true"
                  [selectableGroupAsModel]="false" [closeOnSelect]="true" [(ngModel)]="Obj['leasee_name']" disabled>
                </ng-select>
              </div>
              <div class="col-4"></div>

            </div>
            <br>
            <div class="row ">
              <div class="col-4 text-right">
                <h6 class="mt-2">Lease End Date : <span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <input type="date" placeholder="Lease End Date" [(ngModel)]="Obj['lease_end_date']"
                  class="form-control">
              </div>
              <div class="col-4"></div>
            </div>
            <br>
            <div class="row ">
              <div class="col-4 text-right">
                <h6 class="mt-2">Lease Expired :<span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <ng-select [multiple]="false" placeholder="Lease Expired" [selectableGroup]="true"
                  [selectableGroupAsModel]="false" [closeOnSelect]="true" [(ngModel)]="Obj['lease_expired']" disabled>
                </ng-select>
              </div>
              <div class="col-4"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-4 text-right">
                <h6 class="mt-2">
                  Upload Document :

                </h6>
              </div>
              <div class="col-4 ">
                <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader" class="form-control"
                  style="border-color: black" [(ngModel)]="Obj['newleaseedoc']">
              </div>
              <div class="col-4"></div>

            </div>
            <br>
            <div class="row">
              <div class="col-4 text-right">
                <h6 class="mt-2"> Renewal Date : <span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <input type="date" placeholder="Renewal Date" [(ngModel)]="Obj['lease_renewal_date']"
                  class="form-control">
              </div>
              <div class="col-4"> </div>
            </div>
            <br>
            <div class="row">
              <div class="col-4 text-right">
                <h6 class="mt-2"> Renewal Period : <span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <input type="number" placeholder="Renewal Period" [(ngModel)]="Obj['renewal_period']"
                  class="form-control">
              </div>
              <div class="col-4"> </div>
            </div>
            <br>
            <div class="row">
              <div class="col-4 text-right">
                <h6 class="mt-2"> Lease Rent :<span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <input type="number" placeholder="Lease Rent" [(ngModel)]="Obj['lease_rent']" class="form-control">
              </div>
              <div class="col-4"> </div>
            </div>
            <br>
            <div class="row ">
              <div class="col-4 text-right">
                <h6 class="mt-2">
                  Lease Rent Duration :
                  <span style="color: red;"> *</span>
                </h6>
              </div>
              <div class="col-4">
                <ng-select [items]="rent_duration" [(ngModel)]="Obj['lease_rent_duration']" placeholder="Lease Rent Duration">
                </ng-select>
              </div>
              <div class="col-4"></div>

            </div>
            <br>
            <div class="row">
              <div class="col-4 text-right">
                <h6 class="mt-2"> Lease Rent Increment (%) : <span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <input type="number" placeholder="Lease Rent Increment" [(ngModel)]="Obj['lease_rent_increment']"
                  class="form-control">
              </div>
              <div class="col-2">
                <h6 class="mt-2"> After period : <span style="color: red;"> *</span></h6>
              </div>
              <div class="col-2 text-left">
                <input type="number" placeholder="After period" [(ngModel)]="Obj['after_period']" class="form-control">
              </div>
            </div>
            <br>
            <!-- <div class="row">
              <div class="col-4 text-right">
                <h6 class="mt-2"> After period : <span style="color: red;"> *</span></h6>
              </div>
              <div class="col-4">
                <input type="text" placeholder="After period" [(ngModel)]="Obj['after_period']" class="form-control">
              </div>
              <div class="col-4"> </div>
            </div> -->
            <div class="col-12 text-center mt-5" style="margin-left: -4rem;">
              <button class="btn btn-outline-success" (click)="createRenewal()">Submit</button>
            </div>
          </div>
          <div class="modal" id="viewDetails">
            <div class="modal-dialog modal-lg" style="max-width: 90%">
                <div class="modal-content rounded-50">
                    <!-- Modal Header -->
                    <div class="modal-header bg-primary">
                        <h4 class="modal-title text-white" style="font: bold">
                            Renewal Details :
                        </h4>
                        <button type="button" class="close text-white" data-dismiss="modal">
                            &times;
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body" style="border-radius: 10px">
                        <div class="card" style="border: 2px solid rgb(24, 24, 97)">
                            <div style="margin: 3%">
                                <div class="content-container">
                                    <div class="row mt-2">
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>File Number :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["file_num"] }}
                                            </h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                <b>Alloted Property ID :</b>
                                            </h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["alloted_property_id"] }}
                                            </h5>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                <b>Assigned Property Number :</b>
                                            </h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["assigned_property_number"] }}
                                            </h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>Address :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["address"] }}
                                            </h5>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                <b>Leasee Name :</b>
                                            </h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["leasee_name"] }}
                                            </h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>Total Area :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["area"] }}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>Lease Type :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["lease_type"] }}
                                            </h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>Lease ID :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["leasee_id"] }}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>Lease Rent :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["lease_rent"] }}
                                            </h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>Lease Rent Duration :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["lease_rent_duration"] }}
                                            </h5>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>Lease Rent Increment(%) :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["lease_rent_inc"] }}
                                            </h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>Lease Expired :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["lease_expired"]}}
                                            </h5>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>Renewal ID :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["renewal_id"] }}
                                            </h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%"><b>Renewal Duration :</b></h5>
                                        </div>
                                        <div class="col-3">
                                            <h5 style="width: 100%">
                                                {{ renewal["renewal_duration"] }}
                                            </h5>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                      <div class="col-3">
                                          <h5 style="width: 100%"><b>Renewal Applied Date :</b></h5>
                                      </div>
                                      <div class="col-3">
                                          <h5 style="width: 100%">
                                              {{ renewal["renewal_applied_date"] | date }}
                                          </h5>
                                      </div>
                                      <div class="col-3"></div>
                                      <div class="col-3"></div>
                                  </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <!-- <b>Download Allotment </b><button class="btn btn-outline-primary"
                                 (click)="download(data)">Download</button> -->
                        <button type="button" class="btn btn-outline-danger" data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
