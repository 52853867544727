<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Work </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item" *ngIf="list_flag==true">
                            <a class="nav-link" (click)="refresh()"><i class="fa fa-line-chart"></i>
                                Work List</a>
                        </li>
                        <li class="nav-item" *ngIf="allWork_flage==true">
                            <a class="nav-link" (click)="allWorkList()"><i class="fa fa-line-chart"></i>
                                All Work List</a>
                        </li>
                        <li class="nav-item" *ngIf="create_flag==true">
                            <a class="nav-link" (click)="refresh()"><i class="fa fa-plus-circle"></i>
                                Create Work </a>
                        </li>
                        <li class="nav-item" *ngIf="update_flag==true">
                            <a class="nav-link" (click)="refresh()"><i class="ti-settings"></i>
                                Update Work</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1" *ngIf="list_flag==true">
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4 text-center">
                                </div>
                                <div class="col-4 text-right">
                                    <button type="button" class="btn btn-primary" href="#tab-4" data-toggle="tab"
                                        (click)="allWorkList()">See All Work
                                    </button> &nbsp;&nbsp;&nbsp;
                                    <button type="button" class="btn btn-primary" href="#tab-2" data-toggle="tab"
                                        (click)="createNew()">Create New
                                    </button>
                                </div>
                            </div>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort #sortCol1="matSort">
                                    <ng-container matColumnDef="work_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.work_id }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="work_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Work Order
                                            Number

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_order_no }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="work_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Work
                                            Description

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_desc }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="party_le_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Party name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_legal_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="start_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Work Start
                                            Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_start_dt | date: 'dd/MM/yyyy'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="end_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Work End
                                            Date
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_end_dt | date: 'dd/MM/yyyy'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Work Order
                                            Amount
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_order_amt }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-primary" (click)="open_update(element)" href="#tab-3"
                                                data-toggle="tab">Update</button>
                                            <button class="btn btn-danger" (click)="delete(element)">Delete</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>

                            </div>
                        </div>
                        <div class="tab-pane fade" *ngIf="create_flag==true" id="tab-2">
                            <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
                                <br>
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" type="button" (click)="listfunc()">Back to
                                        list</button>
                                </div>
                                <br>
                                <br>
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            Work Info:
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Work Order Number :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" placeholder="Enter work order number"
                                            [(ngModel)]="obj['work_order_no']" class="form-control"
                                            name="work_order_num" #work_order_num="ngModel" required>
                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="work_order_num.dirty && work_order_num.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && work_order_num.invalid" class="error-message">
                                            <div *ngIf="work_order_num.errors?.['required']">Please enter work order number
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->
                                    </div>
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Work Order Description :</h6>
                                    </div>
                                    <div class="col-3">
                                        <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                            class="form-control" placeholder="Enter work order description"
                                            [(ngModel)]="obj['work_desc']" name="desc" #desc="ngModel" required>
                                        </textarea>


                                        <!---------------- validation start-----------------------------  -->


                                        <!-- border color green -->
                                        <div [class.border_green]="desc.dirty && desc.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && desc.invalid" class="error-message">
                                            <div *ngIf="desc.errors?.['required']">Please enter work order description</div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->


                                    </div>
                                </div>

                                <br>
                                <div class="row">
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Work Start Date :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="date" [(ngModel)]="obj['work_start_dt']" class="form-control"
                                            name="work_start" #work_start="ngModel" [min]="current_date" required>

                                        <!---------------- validation start-----------------------------  -->

                                        <!-- border color green -->
                                        <div [class.border_green]="work_start.dirty && work_start.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && work_start.invalid" class="error-message">
                                            <div *ngIf="work_start.errors?.['required']">Please enter work start date </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->
                                    </div>
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Work End Date :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="date" [(ngModel)]="obj['work_end_dt']" class="form-control"
                                            (change)="checkDate()" name="work_end" #work_end="ngModel" [min]="current_date" required>



                                        <!---------------- validation start-----------------------------  -->


                                        <!-- border color green -->
                                        <div [class.border_green]="work_end.dirty && work_end.valid">
                                        </div>

                                        <div *ngIf="work_start.value >= work_end.value" class="error-message">
                                            Please enter correct end date
                                        </div>
                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && work_end.invalid" class="error-message">
                                            <div *ngIf="work_end.errors?.['required']">Please enter work end date </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                </div>
                                <br>
                                <div class="row">

                                    <div class="col-3">
                                        <h6 style="margin-left:2%">Work Order Amount :</h6>
                                    </div>
                                    <div class="col-3">

                                        <input type="text" class="form-control" [(ngModel)]="obj['work_order_amt']"
                                            class="form-control" placeholder="Enter work order amount"
                                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                            name="work_order_am" #work_order_am="ngModel" required>

                                        <!---------------- validation start-----------------------------  -->

                                        <!-- border color green -->
                                        <div *ngIf="work_order_am.value > 0 " [class.border_green]="work_order_am.dirty && work_order_am.valid">
                                        </div>
                                        <div *ngIf="work_order_am.value <= 0 " [class.border_red]="work_order_am.dirty && work_order_am.valid">
                                        </div>
                                        <div *ngIf="work_order_am.value <= 0 " class="error-message">
                                            Please Amount Should Be Greater Than Zero
                                        </div>
                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && work_order_am.invalid" class="error-message">
                                            <div *ngIf="work_order_am.errors?.['required']">Please enter work order amount
                                            </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->

                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            Party Info:
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                                <br>
                                <div class="row">

                                    <div class="col-3">
                                        <h6 style="margin-left:2%">Select Party :</h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                                            [multiple]="false" placeholder="Select Vender"
                                            [(ngModel)]="obj['party_le_id']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                            (click)="setPartyDetails()" name="party_id" #party_id="ngModel" required>
                                        </ng-select>
                                        <!---------------- validation start-----------------------------  -->

                                        <!-- border color green -->
                                        <div [class.border_green]="party_id.dirty && party_id.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && party_id.invalid" class="error-message">
                                            <div *ngIf="party_id.errors?.['required']">Please select party
                                            </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Party GSTIN :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control" [(ngModel)]="obj['party_gstin_no']"
                                            name="party_gst" disabled>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Party Email :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control" [(ngModel)]="obj['party_email']"
                                            name="party_email" disabled>

                                    </div>
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Party Mobile Number :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="number" class="form-control" [(ngModel)]="obj['party_phone_no']"
                                            name="party_mobile" disabled>
                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            Project-Budget-Product Info:
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%">Select Budget: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{budDesc[obj['bud_cd']]}}
                                        <div *ngIf="f.submitted && !budDesc[obj['bud_cd']]" class="error-message">
                                            Please select budget hierarchy </div>

                                    </div>
                                    <div class="col-1">
                                        <button class="btn btn-primary" type="button"
                                            (click)="selectCreate('budget')">Select</button>
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%"> Select Project: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{projectObj[obj['proj_cd']]}}
                                        <div *ngIf="f.submitted && !projectObj[obj['proj_cd']]" class="error-message">
                                            Please select project hierarchy </div>

                                    </div>
                                    <div class="col-1">
                                        <button class="btn btn-primary" type="button"
                                            (click)="selectCreate('project')">Select</button>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%"> Select Product:
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        {{productObj[obj['prod_cd']]}}
                                        <div *ngIf="f.submitted && !productObj[obj['prod_cd']]" class="error-message">
                                            Please select product hierarchy </div>

                                    </div>
                                    <div class="col-1">
                                        <button class="btn btn-primary" type="button"
                                            (click)="selectCreate('product')">Select</button>
                                    </div>
                                </div>

                                <br>

                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%"> Select Activity:
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        {{activityObj[obj['act_cd']]}}
                                        <div *ngIf="f.submitted && !activityObj[obj['act_cd']]" class="error-message">
                                            Please select activity hierarchy </div>

                                    </div>
                                    <div class="col-1">
                                        <button class="btn btn-primary" type="button"
                                            (click)="selectCreate('activity')">Select</button>
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-12 text-center">
                                        <!-- <button (click)="submit()" class="btn btn-primary">Submit</button> -->
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                        <button class="btn btn-success d-none" type="reset" id="resetSub">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="tab-3" *ngIf="update_flag==true">
                            <br>
                            <div class="col-12 text-right">

                                <button class="btn btn-primary" type="button" (click)="listfunc()">Back to list</button>
                            </div>
                            <br>
                            <form name="form" (ngSubmit)="f2.form.valid && update()" #f2="ngForm" novalidate>
                                <div class="row">
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Work Order Number :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" placeholder="enter work order number"
                                            [(ngModel)]="obj['work_order_no']" class="form-control"
                                            name="work_order_num" #work_order_num="ngModel" required>
                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="work_order_num.dirty && work_order_num.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && work_order_num.invalid" class="error-message">
                                            <div *ngIf="work_order_num.errors?.['required']">Please enter work order number
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->
                                    </div>
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Work Order Description :</h6>
                                    </div>
                                    <div class="col-3">
                                        <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                            class="form-control" placeholder="Enter work order description"
                                            [(ngModel)]="obj['work_desc']" name="desc" #desc="ngModel" required>
                                    </textarea>

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="desc.dirty && desc.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && desc.invalid" class="error-message">
                                            <div *ngIf="desc.errors?.['required']">Please enter work order description</div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->


                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Work Start Date :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="date" [(ngModel)]="obj['work_start_dt']" class="form-control"
                                            name="work_start" #work_start="ngModel" required>

                                        <!---------------- validation start-----------------------------  -->

                                        <!-- border color green -->
                                        <div [class.border_green]="work_start.dirty && work_start.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && work_start.invalid" class="error-message">
                                            <div *ngIf="work_start.errors?.['required']">Please enter work start date </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->
                                    </div>
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Work End Date :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="date" [(ngModel)]="obj['work_end_dt']" class="form-control"
                                            (change)="checkDate()" name="work_end" #work_end="ngModel" required>

                                        <!---------------- validation start-----------------------------  -->


                                        <!-- border color green -->
                                        <div [class.border_green]="work_end.dirty && work_end.valid">
                                        </div>

                                        <div *ngIf="work_start.value > work_end.value" class="error-message">
                                            Please enter correct end date
                                        </div>
                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && work_end.invalid" class="error-message">
                                            <div *ngIf="work_end.errors?.['required']">Please enter work end date </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%">Work Order Amount : Value of maximum amount  {{max_amt_fg }} </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control" [(ngModel)]="obj['work_order_amt']"
                                            class="form-control" placeholder="enter work order amount"
                                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                            name="work_order_am" #work_order_am="ngModel" required>

                                        <!---------------- validation start-----------------------------  -->

                                        <!-- border color green -->
                                        <div [class.border_green]="work_order_am.dirty && work_order_am.valid">
                                        </div>

                                        <div *ngIf="work_order_am.value <= 0 " class="error-message">
                                            Please Amount Should Be Greater Than Zero
                                        </div>
<!--
                                        <div *ngIf="max_amt_fg==true" class="error-message">

                                            Work Order Amount is greater Than Proposed Amount (
                                            {{ budAmtObj[obj['bud_cd']] }} ) Of Selected Budget Hierarchy
                                        </div> -->

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && work_order_am.invalid" class="error-message">
                                            <div *ngIf="work_order_am.errors?.['required']">Please enter work order amount
                                            </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            Party Info:
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                                <br>
                                <div class="row">

                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Select Party :</h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                                            [multiple]="false" placeholder="Select Vender"
                                            [(ngModel)]="obj['party_le_id']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                            (click)="setPartyDetails()" name="party_le_id" #party_legal="ngModel"
                                            required>


                                            <!---------------- validation start-----------------------------  -->

                                            <!-- border color green -->
                                            <div [class.selection-box]="party_legal.dirty && party_legal.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f2.submitted && party_legal.invalid" class="error-message">
                                                <div *ngIf="party_legal.errors?.['required']">Please select party
                                                </div>
                                            </div>

                                            <!---------------- validation end-----------------------------  -->
                                        </ng-select>
                                    </div>
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Party GSTIN :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control" [(ngModel)]="obj['party_gstin_no']"
                                            name="gst" disabled>
                                    </div>
                                </div>
                                <br>
                                <div class="row">

                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Party Email :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control" [(ngModel)]="obj['party_email']"
                                            name="email" disabled>

                                    </div>
                                    <div class="col-3 ">
                                        <h6 style="margin-left:2%">Party Mobile Number :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="number" class="form-control" [(ngModel)]="obj['party_phone_no']"
                                            name="mn" disabled>
                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            Project-Budget-Product Info:
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%">Select Budget:
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        {{budDesc[obj['bud_cd']]}}
                                        <div *ngIf="f2.submitted && !budDesc[obj['bud_cd']]" class="error-message">
                                            Please select budget hierarchy </div>


                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-primary"
                                            (click)="selectCreate('budget')">Select</button>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%"> Select Project:
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        {{projectObj[obj['proj_cd']]}}
                                        <div *ngIf="f2.submitted && !projectObj[obj['proj_cd']]" class="error-message">
                                            Please select project hierarchy </div>


                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-primary"
                                            (click)="selectCreate('project')">Select</button>
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%"> Select Product :
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        {{productObj[obj['prod_cd']]}}

                                        <div *ngIf="f2.submitted && !productObj[obj['prod_cd']]" class="error-message">
                                            Please select product hierarchy </div>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-primary"
                                            (click)="selectCreate('product')">Select</button>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%"> Select Activity:
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        {{activityObj[obj['act_cd']]}}

                                        <div *ngIf="f2.submitted && !activityObj[obj['act_cd']]" class="error-message">
                                            Please select activity hierarchy </div>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-primary"
                                            (click)="selectCreate('activity')">Select</button>
                                    </div>
                                </div>
                                <br>
                                <br><br>

                                <div class="row">
                                    <div class="col-12 text-center">
                                        <!-- <button (click)="update()" class="btn btn-primary">Update</button> -->
                                        <button type="submit" class="btn btn-primary">Update</button>
                                        <button class="btn btn-success d-none" id="reset2" type="reset">Rest</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="tab-4" *ngIf="allWork_flage==true">
                            <br>
                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="listfunc()">Back to list</button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)"
                                            placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4 text-center">
                                </div>

                            </div>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasourceWork" matSort #sortCol2="matSort">
                                    <ng-container matColumnDef="work_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.work_id }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="work_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Work Order
                                            Number

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_order_no }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="work_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Work
                                            Description

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_desc }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="party_le_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Party name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_legal_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="start_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Work Start
                                            Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_start_dt | date: 'dd/MM/yyyy'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="end_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Work End
                                            Date
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_end_dt | date: 'dd/MM/yyyy'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Work Order
                                            Amount
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_order_amt }} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="allWorkdisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: allWorkdisplayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator2 [pageSizeOptions]="[5,10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="modal" id="selectCreate">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> Select Event :</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <br>
                <div class="row">
                    <div class="col-3 text-center">


                    </div>
                    <div class="col-3 text-center"
                        style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                        <h6><b>Code</b></h6>


                    </div>
                    <div class="col-3 text-center"
                        style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                        <h6><b> Value</b></h6>
                    </div>


                </div>
                <br>
                <form name="form" (ngSubmit)="f3.form.valid && SubmitListHierforCreate()" #f3="ngForm" novalidate>
                    <div class="row">
                        <div class="col-3 text-right">
                            Level 1:
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl1_cd']"
                                name="lvl1_cd" disabled>
                        </div>

                        <div class="col-3">
                            <ng-select [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                (change)="onChangeLvl1()" [multiple]="false" placeholder="Select Level 1 "
                                [(ngModel)]="HierSelectObj['lvl1_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl1_value"
                                #lvl1_value="ngModel" required>
                            </ng-select>

                            <!---------------- validation start-----------------------------  -->

                            <!-- border color green -->
                            <div [class.border_green]="lvl1_value.dirty && lvl1_value.valid">
                            </div>

                            <!-- error handling  -->
                            <div *ngIf="f3.submitted && lvl1_value.invalid" class="error-message">
                                <div *ngIf="lvl1_value.errors?.['required']">*Root is required</div>
                            </div>

                            <!---------------- validation end-----------------------------  -->

                        </div>

                    </div>
                    <br>

                    <div class="row">
                        <div class="col-3 text-right">
                            Level 2 :
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl2_cd']"
                                name="lvl2_cd" disabled>

                        </div>

                        <div class="col-3">
                            <ng-select [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                (change)="onChangeLvl2()" [multiple]="false" placeholder="Select Level 2"
                                [(ngModel)]="HierSelectObj['lvl2_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl2_value">
                            </ng-select>
                        </div>


                    </div>
                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Level 3 :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl3_cd']"
                                name="lvl3_cd" disabled>
                        </div>

                        <div class="col-3">
                            <ng-select [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                (change)="onChangeLvl3()" [multiple]="false" placeholder="Select Level 3"
                                [(ngModel)]="HierSelectObj['lvl3_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl3_value">
                            </ng-select>
                        </div>


                    </div>
                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Level 4 :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl4_cd']"
                                name="lvl4_cd" disabled>
                        </div>
                        <div class="col-3">
                            <ng-select [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                (change)="onChangeLvl4()" [multiple]="false" placeholder="Select Level 4"
                                [(ngModel)]="HierSelectObj['lvl4_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl4_value">
                            </ng-select>
                        </div>
                    </div>
                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Level 5 :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl5_cd']"
                                name="lvl5_cd" disabled>
                        </div>

                        <div class="col-3">
                            <ng-select [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                (change)="onChangeLvl5()" [multiple]="false" placeholder="Select Level 5"
                                [(ngModel)]="HierSelectObj['lvl5_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl5_value">
                            </ng-select>
                        </div>

                    </div>
                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Level 6 :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl6_cd']"
                                name="lvl6_cd" disabled>
                        </div>


                        <div class="col-3">
                            <ng-select [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                (change)="onChangeLvl6()" [multiple]="false" placeholder="Select Level 6"
                                [(ngModel)]="HierSelectObj['lvl6_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl6_value">
                            </ng-select>
                        </div>

                    </div>
                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Level 7 :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl7_cd']"
                                name="lvl7_cd" [ngModelOptions]="{standalone: true}" disabled>
                        </div>

                        <div class="col-3">

                            <ng-select [items]="level7" (change)="onChangeLvl7()" bindLabel="lvl7_value"
                                bindValue="lvl7_cd" [multiple]="false" placeholder="Select Level 7"
                                [(ngModel)]="HierSelectObj['lvl7_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl7_value">
                            </ng-select>
                        </div>

                    </div>

                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Leaf :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['leaf_cd']"
                                name="leaf_cd" disabled>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['leaf_value']"
                                name="leaf_value" disabled>
                        </div>
                    </div>
                    <br>


                    <div class="row" *ngIf="leaf_msg">
                        <div class="col-11 text-center error-message">
                            Please select till leaf.
                        </div>
                        <br>
                    </div>

                    <div class="row">
                        <div class="col-12 text-center">
                            <!-- <button class="btn btn-primary" (click)="SubmitListHierforCreate()">Submit</button> -->
                            <button class="btn btn-primary" type="submit">Submit</button>
                            <button class="btn btn-success d-none" type="reset" id="hiereset">Submit</button>
                        </div>
                    </div>

                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
