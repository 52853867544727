import { Component, EventEmitter, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../authentication.service';
import { NgxSpinnerService } from "ngx-spinner";
import { LandInputService } from '../../service/land-input.service';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { MainService } from '../../service/main.service';
declare var $: any;

function readBase64(file:any): Promise<any> {
  var reader  = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);

    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);

    reader.readAsDataURL(file);
  });
  return future;
}

@Component({
  selector: 'app-prop-images',
  templateUrl: './prop-images.component.html',
  styleUrls: ['./prop-images.component.css']
})
export class PropImagesComponent implements OnInit {

  constructor(public auth: AuthenticationService,public mainService: MainService, public sanitizer: DomSanitizer,private spinner: NgxSpinnerService,private landInput_S: LandInputService, ) { }
  create_flag:any=true
  update_flag:any=true
  ebillUser:any
  b_acct_id:any
  youtubevideo:any="";
  nowAddedVideo:any
  public uploader: any = new FileUploader({url: this.mainService.httpUrl + '/property/properties/uploadpropertiesimages'});
  public hasBaseDropZoneOver:any = false;
  public mimeType: any;
  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  public onFileSelected(event:any) {
    const file: File = event[0];

    console.log(file);
    console.log(file.size)

    readBase64(file)
    .then(function(data) {
      console.log(data);
    })

  }

  setMimeType(mimeType:any) {
    this.mimeType = mimeType;
  }
  httpUrl =""
  ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.httpUrl = this.mainService.httpUrl

    this.uploader = new FileUploader({
      url: this.mainService.httpUrl + '/property/properties/uploadpropertiesimages',
      allowedMimeType: ['image/jpeg', 'image/png', 'image/jpg'],
      maxFileSize:200 * 1024,
      // ,disableMultipart: true
      // ,allowedMimeType: ['application/pdf']
    });
    this.uploader.onAfterAddingFile = (file:any)=> {
      file.withCredentials = false;
      // let url = (window.URL) ? window.URL.createObjectURL(file._file) : (window as any).webkitURL.createObjectURL(fileItem._file);
      // this.localImageUrl = url
    };
    this.getpropertygroup()
  }
  Obj:any={}

  allPlan_layout:any = []
  async getpropertygroup() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['group_type'] = "PLANLAYOUT"
    console.log(obj)
    var resp = await this.landInput_S.getpropertygroup(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allPlan_layout = resp['data']

      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['desc'] = 'Plan Layout ' + resp['data'][i]['leaf_value'] + ' Having Total Property - ' + resp['data'][i]['count']
        // resp['data'][i]['desc'] = 'Plan Layout Number ' + resp['data'][i]['property_group_id'] + ' Having Total Property - ' + resp['data'][i]['count']
      }
      console.log(resp['data'], 'fetched data')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  //    {
    //     "group_type": "PLANLAYOUT",
    //     "hier_type_id": 2,
    //     "prop_hier_id": 1,
    //     "property_group_id": 2,
    //     "project_node_cd": 2,
    //     "count": 25,
    //     "total_area": 17750,
    //     "desc": "Plan Layout Number 2 Having Total Property - 25"
    // }
    allPropertType:any = []
    property_type:any
    hier_type_id:any
    prop_hier_id:any
    async getPropertytype(item:any){
      this.property_type=undefined
      let obj :any= {}
      obj["b_acct_id"] =this.b_acct_id
      obj['hier_type_id'] = item['hier_type_id']
      this.hier_type_id = item['hier_type_id']
      obj['prop_hier_id'] = item['prop_hier_id']
      this.prop_hier_id = item['prop_hier_id']
      console.log(item)
      console.log('hellojljl')
      obj[''] = item
      let resp:any = await this.landInput_S.getPropertyTypeXrePlan(JSON.stringify(obj))
      if(!resp['error']){
        console.log(resp["data"])
      this.allPropertType = resp["data"]
    }else{

    }
  }
  uploadAll(){
    // console.log(this.uploader.queue.length)
    const obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.Obj['user_id'];
    obj['prop_hier_id'] = this.prop_hier_id
    obj['hier_type_id'] = this.hier_type_id
    obj['property_type'] = this.property_type
    console.log(obj);
    console.log(this.uploader.queue.length)
    for (let index = 0; index < this.uploader.queue.length; index++) {
      obj['doc_name'] = this.uploader.queue[index].file.name;
      let params = JSON.stringify(obj);
      this.uploader.queue[index].url = this.mainService.httpUrl + '/property/properties/uploadpropertiesimages'+params
    }
    this.uploader.uploadAll()

  }
  returnURL(item:any){
    let url = (window.URL) ? window.URL.createObjectURL(item._file) : (window as any).webkitURL.createObjectURL(item._file)
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  uploadSingle(item:any){
    const obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.ebillUser.user_id
    obj['prop_hier_id'] = this.prop_hier_id
    obj['hier_type_id'] = this.hier_type_id
    obj['property_type'] = this.property_type
    console.log(obj);
    // console.log(this.uploader.queue.length)
    // for (let index = 0; index < this.uploader.queue.length; index++) {
      obj['doc_name'] = item.file.name;
      let params = JSON.stringify(obj);
      item.url = this.mainService.httpUrl + '/property/properties/uploadpropertiesimages'+params
      item.upload()
      // }
  }
  fileObject: any;

  proImages =[]
  async getAllpropertyimagesObj(){
    if(!this.prop_hier_id && !this.hier_type_id && !this.property_type ){
      Swal.fire('Info','Select Plan and Property type','info')
      return
    }

    await this.uploader.clearQueue()
    let obj :any= {}
    obj['hier_type_id'] = this.hier_type_id
    obj['prop_hier_id'] = this.prop_hier_id
    obj['property_type'] = this.property_type
    obj['user_id'] = this.ebillUser.user_id

    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    this.spinner.show()
    let imgObjs= await this.landInput_S.getPropertyImagesObj(JSON.stringify(obj))
    console.log(imgObjs)
    if(!imgObjs['error']){
      this.proImages = imgObjs['data']
    }
    await this.getViewOfAllImage()
    console.log("99")
    await this.getVideos()
    this.spinner.hide()
  }
  imageUrls:any = []
  async getViewOfAllImage(){
    this.imageUrls = []
    this.selectedImg= {}
    for (let index = 0; index < this.proImages.length; index++) {
      let url:any = await this.view(this.proImages[index])
      this.imageUrls.push(url)
    }
  }
  fileURL:any;
  openImgNextModel(url:any){
    this.fileURL = url
    $('#myModal2').modal('show');
  }

  imgListFlag = false
  async showImgListflag(){
    this.imgListFlag = ! this.imgListFlag
    if(this.imgListFlag){
      await this.uploader.clearQueue()
      this.selectAllimg = false
    }
    await this.getAllpropertyimagesObj()
    await this.getViewOfAllImage()
  }
  selectedImg:any={}
  async deleteSelImg(){
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['imgObj'] = []
    console.log(this.selectedImg)
    for(let i of Object.keys(this.selectedImg)){
      if(this.selectedImg[i]){
        console.log(parseInt(i))
        console.log(this.proImages[parseInt(i)])
        obj['imgObj'].push(this.proImages[parseInt(i)])
      }
    }
    this.spinner.show()
    console.log(obj)
    let resp:any = await this.landInput_S.deleteImg(obj)
    if(resp['error']){
      this.spinner.hide()
      Swal.fire('Error','Server Side Error','error')
    }else{
      for(let i of Object.keys(this.selectedImg).reverse()){
        if(this.selectedImg[i]){
          this.imageUrls.splice(parseInt(i),1)
          console.log(this.imageUrls)
        }
      }
      this.spinner.hide()
      Swal.fire('Deleted',"Succesfully deleted",'success')
      this.selectedImg ={}
    }
  }
  selectAllimg = false
  slecectAllimg(){
    this.selectAllimg = !this.selectAllimg
    for (let index = 0; index < this.proImages.length; index++) {
      this.selectedImg[index]=this.selectAllimg;
    }
  }

  async view(item:any) {
    this.spinner.show()
    var obj:any = {}
    obj['prop_image_id'] = item['prop_image_id']
    obj['doc_format'] = item['doc_format']
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj, item)
    this.spinner.show()
    const res = await this.landInput_S.getProImages(obj);
    console.log(res)
    if (res) {
      this.spinner.hide()
      // var docname = obj['doc_name'];
      var ext = obj['doc_format'];
      if (ext == 'png' || ext == 'jpeg' || ext == 'jpg' || ext == 'PNG' || ext == 'JPEG' || ext == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        return this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      }
    } else {
      this.spinner.hide()
    }
    return
  }

  proVideoObj :any= {}
  async getVideos(){
    this.youtubevideo = ""
    console.log("get video called")
    if(!this.prop_hier_id && !this.hier_type_id && !this.property_type ){
      Swal.fire('Info','Select Plan and Property type','info')
      return
    }
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_type_id'] = this.hier_type_id
    obj['prop_hier_id'] = this.prop_hier_id
    obj['property_type'] = this.property_type
    console.log(obj)
    this.spinner.show()
    let vidOvj:any= await this.landInput_S.getAllVideo(obj)
    console.log(vidOvj)
    if(!vidOvj['error']){
      this.proVideoObj = vidOvj['data']
      if(!this.proVideoObj){
        // Swal.fire('Error',"Server Side Error","error")
        this.nowAddedVideo = undefined
        // Swal.fire('Info',"No Video Found Upload Link",'info')
        return
      }
      this.nowAddedVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.proVideoObj['prop_vedio_link'])
      this.spinner.hide()
    }else{
      // if(!this.proVideoObj){
      //   Swal.fire('Error',"Server Side Error","error")
      //   return
      // }
      // this.nowAddedVideo = undefined
      this.spinner.hide()
    }
  }

  async deleteVid(){
    console.log(this.proVideoObj)
  // this.spinner.show()
  let res:any = await this.landInput_S.delProVid({b_acct_id:this.b_acct_id, 'vidObj':[this.proVideoObj]})
   if(!res['error']){
    // this.spinner.hide()
    Swal.fire('Deleted',"succesfully deleted",'success')
    this.nowAddedVideo = ""
    this.youtubevideo = undefined
   }else{
    // this.spinner.hide()
    Swal.fire('Error',"Server Error",'error')
   }
  }

  async addVideo(){

    if(!this.prop_hier_id && !this.hier_type_id && !this.property_type ){
      Swal.fire('Info','Select Plan and Property type','info')
      return
    }
    var obj:any = {}
    let key = this.getYoutubeVideoId(this.youtubevideo)
    if(!key){
      Swal.fire('Info','please paste the video link','info')
      return
    }
    const const_vid_link = "https://www.youtube.com/embed/"+key

    // "https://www.youtube.com/embed/" +  this.getYoutubeVideoId(this.youtubevideo);
    obj['video_link'] = const_vid_link
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.ebillUser.user_id
    obj['prop_hier_id'] = this.prop_hier_id
    obj['hier_type_id'] = this.hier_type_id
    obj['property_type'] = this.property_type
    console.log(obj)
    this.spinner.show()
    const res:any = await this.landInput_S.addProVid(obj);
    if(!res['error']){
      Swal.fire("Succes","video added",'success')
      // this.nowAddedVideo = this.sanitizer.bypassSecurityTrustResourceUrl(const_vid_link)
      this.getVideos()
    }
    this.spinner.hide()
  }

  getYoutubeVideoId(url:any) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
   console.log(match)
    return (match && match[2].length === 11)
      ? match[2]
      : null;
}





  ImageTab = true
  openVideoTab(){
    this.ImageTab= false
  }
  openImageTab(){
    this.ImageTab = true
  }

  reset(){}
}
