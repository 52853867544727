import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BillService } from "../service/bill.service";
import { HierarchyService } from "../service/hierarchy.service";
import { LedgerService } from "../service/ledger.service";
import { MainService } from "../service/main.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CreateEventService } from "../service/create-event.service";
import { SettingService } from "../service/setting.service";
import { DataControlService } from "../service/data-control.service";
import swal from "sweetalert2";
import * as pdfMake from "pdfmake/build/pdfmake";
import Swal from "sweetalert2";

import { FormGroup, FormControl, FormControlName, Validators, FormBuilder } from '@angular/forms'
import  * as pdfFonts from "pdfmake/build/vfs_fonts";


(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


// import { BudgetService } from "../service/budget.service";
// import { EventsService } from "../service/events.service";
// import { UserAddService } from "../../../app/portal/service/user-add.service";
// import { RuleProcessService } from "../service/rule-process.service";
// import { RuleService } from "../service/rule.service";
// import { Router } from "@angular/router";

declare var $: any;

@Component({
  selector: "app-bill",
  templateUrl: "./bill.component.html",
  styleUrls: ["./bill.component.css"],
})
export class BillComponent implements OnInit {
  constructor(
    private createEventService: CreateEventService,
    private settingService: SettingService,
    public mainService: MainService,
    private ledgerService: LedgerService,
    private billService: BillService,
    private spinner: NgxSpinnerService,
    private hierarchyService: HierarchyService,
    private dataControlService: DataControlService,
    // private userAdd: UserAddService,
    // private router: Router,
    // private ruleService: RuleService,
    // private ruleProcessService: RuleProcessService,
    // private budgetService: BudgetService,
    // private EventsService: EventsService,


  ) { }
  erpUser:any;
  b_acct_id:any;

  fin_year:any;
  gstArr:any = [
    { code: "cgst_sgst", value: "CGST/SGST" },
    { code: "igst", value: "IGST" },
  ];
  gstArrObj:any = { cgst_sgst: "CGST/SGST", igst: "IGST" };
  amountTypeArr:any = [
    { code: "fixed", value: "FIXED" },
    { code: "percentage", value: "PERCENTAGE" },
  ];
  amountTypeArrObj:any = { fixed: "FIXED", percentage: "PERCENTAGE" };

  partyArr:any = [];
  partyArrObj :any= {};
  obj:any = {};
  selectAccountType:any;
  allCBInfo:any = [];

  cb_status:any = [
    { code: "GENERATED", value: "GENERATED" },
    { code: "REJECTED", value: "REJECTED" },
  ];

  allAccountInfo:any = [];
  AllAccountObj:any = {};
  hsnArr:any = [];
  gstObj:any = {};
  hsnObj :any= {};

  AllBillRow:any= [];
  AllDedRow:any = [];
  rateObj:any = {};

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild("paginator1", { static: false }) paginator1!: MatPaginator;
  @ViewChild("sortCol2", { static: false }) sortCol2!: MatSort;

  displayedColumns1:any = ["event_code", "event_desc", "action"];
  dataSource1:any;

  displayedColumns = [
    "cb_id",
    "party_id",
    "work_order_no",
    "work_or_service_name",
    "bill_no",
    "bill_date",
    "cb_date",
    "status",
    "action",
  ];
  datasource:any;

  total_amt:any = 0;
  total_ded_amount:any = 0;
  net_amount :any= 0;
  cgstAmount:any = 0;
  sgstAmount :any= 0;
  igstAmount:any = 0;
  totalAmount :any= 0;
  TaxAmount :any= 0;
  selectedStatus:any = "";
  systemDate:any = "";
  allEVents :any= [];
  dedTypeObj:any = {};

  allAllocationArr :any= [];
  allBudgetHier :any= [];
  allProjectHier:any = [];
  allProductHier:any = [];
  allActivityHier :any= [];
  orgShortName:any;
  allRule:any = [];
  eventArr :any= [];
  allocationAmountObj :any= {};
  acctinfo :any= {};

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;

    var resp = await this.billService.getSystemDate();
    this.systemDate = resp.data;
    this.orgShortName = await this.mainService.accInfo["account_short_name"];
    this.acctinfo = await this.mainService.accInfo;
    this.spinner.show();
    //master data
    await this.getgst();
    await this.getDedType();
    await this.getDedData();
    await this.getrepresentative();
    await this.getparty();
    await this.getworkInfo();

    await this.getAllBudget();
    await this.getAllActivity();
    await this.getAllProject();
    await this.getAllProduct();

    await this.getActiveFinYear();
    await this.getAllCBInfo();

    this.spinner.hide();
  }

  no_bill: boolean = false;

  //******************************Party Info*********************************************** */
  reperesentative:any = {};
  async getrepresentative() {
    var resp = await this.settingService.getAllrepresentative(
      JSON.stringify({ b_acct_id: this.b_acct_id })
    );
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        this.reperesentative[resp["data"][i]["rep_le_id"]] =
          resp["data"][i]["party_leagal_name"];
      }
    }
  }
  all_party :any= [];
  partyNameObj :any= {};
  async getparty() {
    var resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp["error"] == false) {
      this.partyNameObj = {};

      for (let i = 0; i < resp["data"].length; i++) {
        if (resp["data"][i]["party_type"] == "ORG") {
          resp["data"][i]["party_leagal_name"] =
            resp["data"][i]["party_leagal_name"] +
            " ( " +
            this.reperesentative[resp["data"][i]["rep_le_id"]] +
            ")";
        }
        this.partyNameObj[resp["data"][i]["le_id"]] =
          resp["data"][i]["party_leagal_name"];
      }
      this.all_party = resp["data"];
      this.obj["party_type"];
    }
  }

  setPartyDetails() {
    for (let i = 0; i < this.all_party.length; i++) {
      if (this.obj["party_le_id"] == this.all_party[i]["le_id"]) {
        this.obj["party_phone_no"] = this.all_party[i]["party_phone_no"];
        this.obj["party_gstin_no"] = this.all_party[i]["party_gstin_no"];
        this.obj["party_email"] = this.all_party[i]["party_email"];
        break;
      }
    }
  }


  allWork: any = [];
  AllworkObj :any= {};
  assingedWork:any = []
  async getworkInfo() {

    let assigned_work_ids: any = [];
    assigned_work_ids = await this.dataControlService.getWork();
    console.log("Assigned work id is : ", assigned_work_ids)


    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.billService.getworkInfo(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.allWork = [];
      this.AllworkObj = {};

      this.allWork = resp.data;
      this.allWork.map((x:any) => {
        if (assigned_work_ids.includes(x['work_id'])) {
          this.assingedWork.push(x)
        }
      })
      this.allWork = this.assingedWork;
      for (let i = 0; i < this.allWork.length; i++) {
        this.AllworkObj[this.allWork[i]["work_id"]] = this.allWork[i];
        this.allWork[i]["temp_desc"] = this.allWork[i].work_order_no + "-" + this.allWork[i].work_desc;
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();

    }
  }


  changework() {
    this.obj["bill_date"] = new Date().toISOString().slice(0, 10);
    this.obj["bill_no"] = 0;

    this.obj["party_le_id"] =
      this.AllworkObj[this.obj["work_id"]]["party_le_id"];
    this.obj["proj_cd"] = this.AllworkObj[this.obj["work_id"]]["proj_cd"];
    this.obj["bud_cd"] = this.AllworkObj[this.obj["work_id"]]["bud_cd"];
    this.obj["prod_cd"] = this.AllworkObj[this.obj["work_id"]]["prod_cd"];
    this.obj["act_cd"] = this.AllworkObj[this.obj["work_id"]]["act_cd"];
    this.obj["work_desc"] = this.AllworkObj[this.obj["work_id"]]["work_desc"];
    for (let i = 0; i < this.all_party.length; i++) {
      if (this.obj["party_le_id"] == this.all_party[i]["le_id"]) {
        this.obj["party_phone_no"] = this.all_party[i]["party_phone_no"];
        this.obj["party_gstin_no"] = this.all_party[i]["party_gstin_no"];
        this.obj["party_email"] = this.all_party[i]["party_email"];
        break;
      }
    }
  }

  checkAmount(data:any) {
    this.amt_flag = false;
    if (this.obj['work_id'] == undefined) {
      swal.fire("Warning", "Please Select Work ", 'warning');
      return;
    }
    if ((data['amount'] + this.amountObj[this.obj['work_id']]) > this.AllworkObj[this.obj['work_id']]['work_order_amt']) {
      let def = Math.abs((this.amountObj[this.obj['work_id']]) - this.AllworkObj[this.obj['work_id']]['work_order_amt']);
      Swal.fire("Warning", "Amount should be less than : " + def, 'warning');
      data['amount'] = 0
      return;
    }
  }


  checkUpdateAmount(data:any) {
    this.amt_flag = false;
    if (this.obj['work_id'] == undefined) {
      swal.fire("Warning", "Please Select Work ", 'warning');
      return;
    }
    if ((data['amount']) > this.AllworkObj[this.obj['work_id']]['work_order_amt']) {
      let def = Math.abs((this.amountObj[this.obj['work_id']]) - this.AllworkObj[this.obj['work_id']]['work_order_amt']);
      Swal.fire("Warning", "Amount should be less than : " + def, 'warning');
      data['amount'] = 0
      return;
    }
  }


  //************************************All Hier************************************************ */


  level1:any = [];
  level2:any = [];
  level3:any = [];
  level4:any = [];
  level5:any = [];
  level6 :any= [];
  level7:any = [];

  Hier = [];
  type:any;
  budgetObj :any= {};
  hierobj :any= { budget: {}, activity: {}, product: {}, project: {} };
  async getAllBudget() {
    this.spinner.show();
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "bud_hier";
    var resp = await this.hierarchyService.getBudgetHierarchy(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.allBudgetHier = resp.data;

      for (let i = 0; i < this.allBudgetHier.length; i++) {
        console.log(this.allBudgetHier[i]);
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allBudgetHier[i]["lvl" + j + "_cd"] != null &&
            this.allBudgetHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allBudgetHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allBudgetHier[i]["desc"] = str;
        this.budgetObj[this.allBudgetHier[i]["leaf_cd"]] = str;
        this.hierobj["budget"][this.allBudgetHier[i]["leaf_cd"]] =
          this.allBudgetHier[i];
      }
    } else {
      this.spinner.hide();
    }
  }


  projectObj :any= {};
  projectLeafUserObj:any = {};

  async getAllProject() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "proj_hier";
    var resp = await this.hierarchyService.getProjectHierarchy(obj);
    if (resp["error"] == false) {
      this.allProjectHier = resp.data;
      for (let i = 0; i < this.allProjectHier.length; i++) {

        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allProjectHier[i]["lvl" + j + "_cd"] != null &&
            this.allProjectHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allProjectHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allProjectHier[i]["desc"] = str;
        this.projectObj[this.allProjectHier[i]["leaf_cd"]] = str;
        this.projectLeafUserObj[this.allProjectHier[i]["leaf_user_cd"]] =
          this.allProjectHier[i];
        this.hierobj["project"][this.allProjectHier[i]["leaf_cd"]] =
          this.allProjectHier[i];
      }
    } else {
    }
  }



  activityObj :any= {};
  async getAllActivity() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "activity_hier";
    var resp = await this.hierarchyService.getActivityHierarchy(obj);
    if (resp["error"] == false) {
      this.allActivityHier = resp.data;
      for (let i = 0; i < this.allActivityHier.length; i++) {
        // console.log(this.allActivityHier[i]);
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allActivityHier[i]["lvl" + j + "_cd"] != null &&
            this.allActivityHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allActivityHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allActivityHier[i]["desc"] = str;
        this.activityObj[this.allActivityHier[i]["leaf_cd"]] = str;
        this.hierobj["activity"][this.allActivityHier[i]["leaf_cd"]] =
          this.allActivityHier[i];
      }
    } else {
    }
  }



  productObj :any= {};
  async getAllProduct() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "prod_hier";
    var resp = await this.hierarchyService.getProductHierarchy(obj);
    if (resp["error"] == false) {
      this.allProductHier = resp.data;
      for (let i = 0; i < this.allProductHier.length; i++) {
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allProductHier[i]["lvl" + j + "_cd"] != null &&
            this.allProductHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allProductHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allProductHier[i]["desc"] = str;
        this.productObj[this.allProductHier[i]["leaf_cd"]] = str;
        this.hierobj["product"][this.allProductHier[i]["leaf_cd"]] =
          this.allProductHier[i];
      }
    } else {
    }
  }

  hier_type:any;
  HierSelectObj:any = {};
  selectObj:any = {};
  HierSelectobj:any;
  Chartobj:any = {};

  async Select(type:any) {
    $('#select').modal('show');
    this.type = type;
    if (type == 'budget') {
      this.Hier = this.allBudgetHier;
    } else if (type == 'activity') {
      this.Hier = this.allActivityHier;
    } else if (type == 'project') {
      this.Hier = this.allProjectHier;
    } else if (type == 'product') {
      this.Hier = this.allProductHier;
    }

    await this.getLevel1();
    this.Chartobj = {};
  }


  async selectCreate(type:any) {
    $("#selectCreate").modal("show");
    this.hier_type = type;
    let ob :any= {};
    if (type == "budget") {
      this.Hier = this.allBudgetHier;
      if (this.obj["bud_cd"] != null && this.obj["bud_cd"] != "") {
        ob = this.hierobj["budget"][this.obj["bud_cd"]];
      }
    } else if (type == "activity") {
      this.Hier = this.allActivityHier;
      if (this.obj["act_cd"] != null && this.obj["act_cd"] != "") {
        ob = this.hierobj["activity"][this.obj["act_cd"]];
      }
    }
    this.HierSelectObj = {};
    await this.getLevel1();
    if (Object.keys(ob).length != 0) {
      this.HierSelectObj = Object.assign({}, ob);
      await this.onChangeLvl1();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl2();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl3();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl4();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl5();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl6();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl7();
    }
  }



  async getLevel1() {
    this.level1 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]["lvl1_cd"] == this.Hier[i]["leaf_cd"]) {
        let ob :any= new Object();
        ob["lvl1_cd"] = this.Hier[i]["lvl1_cd"];
        ob["lvl1_value"] = this.Hier[i]["lvl1_value"];
        ob["is_leaf"] = this.Hier[i]["is_leaf"];

        this.level1.push(ob);
      }
    }

    this.level2 = [];
    this.level3 = [];
    this.level4 = [];
    this.level5 = [];
    this.level6 = [];
    this.level7 = [];
    console.log(this.level1);
  }

  async onChangeLvl1() {
    if (
      this.HierSelectObj["lvl1_cd"] == "" ||
      this.HierSelectObj["lvl1_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level1.length; i++) {
      if (this.level1[i]["lvl1_cd"] == this.HierSelectObj["lvl1_cd"]) {
        this.HierSelectObj["lvl1_value"] = this.level1[i]["lvl1_value"];
        // if (this.level1[i]["is_leaf"] == 1) {
        console.log(this.level1[i]);
        await this.makingLeafValues();
        // } else {
        this.HierSelectObj["leaf_cd"] = "";
        this.HierSelectObj["leaf_value"] = "";
        // }
      }
    }
    let temp = [];
    this.level2 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl1_cd"] == this.HierSelectObj["lvl1_cd"] &&
        this.Hier[i]["lvl2_cd"] != null
      ) {
        if (this.Hier[i]["lvl2_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl2_cd"] = this.Hier[i]["lvl2_cd"];
          ob["lvl2_value"] = this.Hier[i]["lvl2_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level2.push(ob);
        }
      }
    }
    this.level3 = [];
    this.level4 = [];
    this.level5 = [];
    this.level6 = [];
    this.level7 = [];

    for (let i = 2; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }
  async onChangeLvl2() {
    if (
      this.HierSelectObj["lvl2_cd"] == "" ||
      this.HierSelectObj["lvl2_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level2.length; i++) {
      if (this.level2[i]["lvl2_cd"] == this.HierSelectObj["lvl2_cd"]) {
        this.HierSelectObj["lvl2_value"] = this.level2[i]["lvl2_value"];
        // if (this.level2[i]["is_leaf"] == 1) {
        await this.makingLeafValues();
        // } else {
        this.HierSelectObj["leaf_cd"] = "";
        this.HierSelectObj["leaf_value"] = "";
        // }
      }
    }
    let temp = [];
    this.level3 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl2_cd"] == this.HierSelectObj["lvl2_cd"] &&
        this.Hier[i]["lvl3_cd"] != null
      ) {
        if (this.Hier[i]["lvl3_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl3_cd"] = this.Hier[i]["lvl3_cd"];
          ob["lvl3_value"] = this.Hier[i]["lvl3_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level3.push(ob);
        }
      }
    }

    this.level4 = [];
    this.level5 = [];
    this.level6 = [];
    this.level7 = [];

    for (let i = 3; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl3() {
    if (
      this.HierSelectObj["lvl3_cd"] == "" ||
      this.HierSelectObj["lvl3_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level3.length; i++) {
      if (this.level3[i]["lvl3_cd"] == this.HierSelectObj["lvl3_cd"]) {
        this.HierSelectObj["lvl3_value"] = this.level3[i]["lvl3_value"];
        // if (this.level3[i]["is_leaf"] == 1) {
        await this.makingLeafValues();
        // } else {
        this.HierSelectObj["leaf_cd"] = "";
        this.HierSelectObj["leaf_value"] = "";
        // }
      }
    }
    let temp = [];
    this.level4 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl3_cd"] == this.HierSelectObj["lvl3_cd"] &&
        this.Hier[i]["lvl4_cd"] != null
      ) {
        if (this.Hier[i]["lvl4_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl4_cd"] = this.Hier[i]["lvl4_cd"];
          ob["lvl4_value"] = this.Hier[i]["lvl4_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level4.push(ob);
        }
      }
    }

    this.level5 = [];
    this.level6 = [];
    this.level7 = [];

    for (let i = 4; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl4() {
    if (
      this.HierSelectObj["lvl4_cd"] == "" ||
      this.HierSelectObj["lvl4_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level4.length; i++) {
      if (this.level4[i]["lvl4_cd"] == this.HierSelectObj["lvl4_cd"]) {
        this.HierSelectObj["lvl4_value"] = this.level4[i]["lvl4_value"];
        // if (this.level4[i]["is_leaf"] == 1) {
        await this.makingLeafValues();
        // } else {
        this.HierSelectObj["leaf_cd"] = "";
        this.HierSelectObj["leaf_value"] = "";
        // }
      }
    }
    let temp = [];
    this.level5 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl4_cd"] == this.HierSelectObj["lvl4_cd"] &&
        this.Hier[i]["lvl5_cd"] != null
      ) {
        if (this.Hier[i]["lvl5_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl5_cd"] = this.Hier[i]["lvl5_cd"];
          ob["lvl5_value"] = this.Hier[i]["lvl5_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level5.push(ob);
        }
      }
    }
    this.level6 = [];
    this.level7 = [];

    for (let i = 5; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl5() {
    if (
      this.HierSelectObj["lvl5_cd"] == "" ||
      this.HierSelectObj["lvl5_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level5.length; i++) {
      if (this.level5[i]["lvl5_cd"] == this.HierSelectObj["lvl5_cd"]) {
        this.HierSelectObj["lvl5_value"] = this.level5[i]["lvl5_value"];
        // if (this.level5[i]["is_leaf"] == 1) {
        await this.makingLeafValues();
        // } else {
        this.HierSelectObj["leaf_cd"] = "";
        this.HierSelectObj["leaf_value"] = "";
        // }
      }
    }
    let temp = [];
    this.level6 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl5_cd"] == this.HierSelectObj["lvl5_cd"] &&
        this.Hier[i]["lvl6_cd"] != null
      ) {
        if (this.Hier[i]["lvl6_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl6_cd"] = this.Hier[i]["lvl6_cd"];
          ob["lvl6_value"] = this.Hier[i]["lvl6_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level6.push(ob);
        }
      }
    }
    this.level7 = [];

    for (let i = 6; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl6() {
    if (
      this.HierSelectObj["lvl7_cd"] == "" ||
      this.HierSelectObj["lvl7_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level6.length; i++) {
      if (this.level6[i]["lvl6_cd"] == this.HierSelectObj["lvl6_cd"]) {
        this.HierSelectObj["lvl6_value"] = this.level6[i]["lvl6_value"];
        // if (this.level6[i]["is_leaf"] == 1) {
        await this.makingLeafValues();
        // } else {
        this.HierSelectObj["leaf_cd"] = "";
        this.HierSelectObj["leaf_value"] = "";
        // }
      }
    }
    let temp = [];
    this.level7 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl6_cd"] == this.HierSelectObj["lvl6_cd"] &&
        this.Hier[i]["lvl7_cd"] != null
      ) {
        if (this.Hier[i]["lvl7_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl7_cd"] = this.Hier[i]["lvl7_cd"];
          ob["lvl7_value"] = this.Hier[i]["lvl7_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level7.push(ob);
        }
      }
    }

    for (let i = 7; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl7() {
    for (let i = 0; i < this.level7.length; i++) {
      if (this.level7[i]["lvl7_cd"] == this.HierSelectObj["lvl7_cd"]) {
        this.HierSelectObj["lvl7_value"] = this.level7[i]["lvl7_value"];
      }
    }

    await this.makingLeafValues();
  }

  async makingLeafValues() {
    if (
      this.HierSelectObj["lvl7_cd"] != undefined &&
      this.HierSelectObj["lvl7_cd"] != "" &&
      this.HierSelectObj["lvl7_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl7_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl7_value"];
    } else if (
      this.HierSelectObj["lvl6_cd"] != undefined &&
      this.HierSelectObj["lvl6_cd"] != "" &&
      this.HierSelectObj["lvl6_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl6_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl6_value"];
    } else if (
      this.HierSelectObj["lvl5_cd"] != undefined &&
      this.HierSelectObj["lvl5_cd"] != "" &&
      this.HierSelectObj["lvl5_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl5_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl5_value"];
    } else if (
      this.HierSelectObj["lvl4_cd"] != undefined &&
      this.HierSelectObj["lvl4_cd"] != "" &&
      this.HierSelectObj["lvl4_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl4_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl4_value"];
    } else if (
      this.HierSelectObj["lvl3_cd"] != undefined &&
      this.HierSelectObj["lvl3_cd"] != "" &&
      this.HierSelectObj["lvl3_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl3_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl3_value"];
    } else if (
      this.HierSelectObj["lvl2_cd"] != undefined &&
      this.HierSelectObj["lvl2_cd"] != "" &&
      this.HierSelectObj["lvl2_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl2_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl2_value"];
    } else if (
      this.HierSelectObj["lvl1_cd"] != undefined &&
      this.HierSelectObj["lvl1_cd"] != "" &&
      this.HierSelectObj["lvl1_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl1_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl1_value"];
    }
  }
  async SubmitSelectedListHier() {
    console.log(this.HierSelectObj);
    console.log(this.hier_type);
    let levelnum = 0;
    for (let i = 1; i < 8; i++) {
      if (
        this.HierSelectObj["lvl" + i + "_cd"] != null &&
        this.HierSelectObj["lvl" + i + "_cd"] != ""
      ) {
        levelnum = i;
      }
    }
    if (this.hier_type == "budget") {
      for (let i = 0; i < this.allBudgetHier.length; i++) {
        if (
          this.allBudgetHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["bud_desc"] = this.allBudgetHier[i]["desc"];
          this.selectObj["bud_cd"] = this.allBudgetHier[i]["leaf_cd"];
          this.selectObj["bud_lvl"] = this.allBudgetHier[i]["level"];
          this.selectObj["bud_obj"] = this.allBudgetHier[i];
        }
      }
    } else if (this.hier_type == "activity") {
      for (let i = 0; i < this.allActivityHier.length; i++) {
        if (
          this.allActivityHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["act_desc"] = this.allActivityHier[i]["desc"];
          this.selectObj["act_cd"] = this.allActivityHier[i]["leaf_cd"];
          this.selectObj["act_lvl"] = this.allActivityHier[i]["level"];
          this.selectObj["act_obj"] = this.allActivityHier[i];
        }
      }
    } else if (this.hier_type == "project") {
      for (let i = 0; i < this.allProjectHier.length; i++) {
        if (
          this.allProjectHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["proj_desc"] = this.allProjectHier[i]["desc"];
          this.selectObj["proj_cd"] = this.allProjectHier[i]["leaf_cd"];
          this.selectObj["proj_lvl"] = this.allProjectHier[i]["level"];
          this.selectObj["proj_obj"] = this.allProjectHier[i];
        }
      }
    } else if (this.hier_type == "product") {
      for (let i = 0; i < this.allProductHier.length; i++) {
        if (
          this.allProductHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["prod_cd"] = this.allProductHier[i]["leaf_cd"];
          this.selectObj["prod_desc"] = this.allProductHier[i]["desc"];
          this.selectObj["prod_lvl"] = this.allProductHier[i]["level"];
          this.selectObj["prod_obj"] = this.allProductHier[i];
        }
      }
    }

    $("#select").modal("hide");
  }

  SubmitListHierforCreate() {
    if (
      this.HierSelectObj["leaf_cd"] == "" ||
      this.HierSelectObj["leaf_cd"] == null
    ) {
      swal.fire("Error", "Please select till leaf.");
      return;
    }
    if (this.hier_type == "budget") {
      for (let i = 0; i < this.allBudgetHier.length; i++) {
        if (this.allBudgetHier[i]["leaf_cd"] == this.HierSelectObj["leaf_cd"]) {
          this.obj["bud_desc"] = this.allBudgetHier[i]["desc"];
          this.obj["bud_cd"] = this.allBudgetHier[i]["leaf_cd"];
          this.obj["bud_lvl"] = this.allBudgetHier[i]["level"];
          this.obj["bud_obj"] = this.allBudgetHier[i];
        }
      }
      console.log(this.obj);
    } else if (this.hier_type == "activity") {
      for (let i = 0; i < this.allActivityHier.length; i++) {
        if (
          this.allActivityHier[i]["leaf_cd"] == this.HierSelectObj["leaf_cd"]
        ) {
          this.obj["act_desc"] = this.allActivityHier[i]["desc"];
          this.obj["act_cd"] = this.allActivityHier[i]["leaf_cd"];
          this.obj["act_lvl"] = this.allActivityHier[i]["level"];
          this.obj["act_obj"] = this.allActivityHier[i];
        }
      }
    }

    $("#selectCreate").modal("hide");
  }


  async getDedType() {
    var dedArr:any = [];
    if (this.mainService.codeValueTechObj["ACC0057"] != undefined) {
      this.mainService.codeValueTechObj["ACC0057"].map((x:any) => {
        if (
          !(x["code"] == "CGSTOUTPUT" || x["code"] == "SGSTOUTPUT" || x["code"] == "IGSTOUTPUT"
            || x["code"] == "CGSTINPUT" || x["code"] == "SGSTINPUT" || x["code"] == "IGSTINPUT")
        )
          this.allUserDeduction.push(x);
      });
      dedArr = this.allUserDeduction;
    }
    this.dedTypeObj = new Object();
    for (let i = 0; i < dedArr.length; i++) {
      this.dedTypeObj[dedArr[i]["code"]] = dedArr[i]["value"];
    }
  }


  dedDataArr:any = [];
  dedtoActivity:any = {};
  dedtoBudeget:any = {};
  allUserDeduction:any = [];
  allDeduction:any = [];
  async getDedData() {
    var resp = await this.settingService.getDeductionMappingList(
      this.b_acct_id
    );
    if (resp["error"] == false) {
      this.allDeduction = resp.data;
      var dd = Object.keys(this.dedTypeObj);
      for (let i = 0; i < dd.length; i++) {
        if (!(dd[i] == "IGSTOUTPUT" || dd[i] == "SGSTOUTPUT" || dd[i] == "CGSTOUTPUT" || dd[i] == "IGSTINPUT" || dd[i] == "SGSTINPUT" || dd[i] == "CGSTINPUT"))
          this.dedDataArr[dd[i]] = [];
      }

      for (let j = 0; j < this.allDeduction.length; j++) {
        this.dedtoActivity[this.allDeduction[j]["ded_code"] + this.allDeduction[j]["gov_rule"]] =
          this.allDeduction[j]["act_cd"];
        this.dedtoBudeget[this.allDeduction[j]["ded_code"] + this.allDeduction[j]["gov_rule"]] =
          this.allDeduction[j]["bud_cd"];
        if (this.allDeduction[j]["ded_owner"] != "SYSTEM") {
          this.dedDataArr[this.allDeduction[j]["ded_code"]].push(this.allDeduction[j]);
        }
      }
      console.log(this.dedtoActivity);
      console.log(this.dedtoBudeget);
    } else {
      swal.fire("Error", "...Error while getting list!", "error");
    }
  }

  ChangeDed(deduction_type:any, j:any) {
    this.AllBillRow[0]["ded"][j]["ded_amount"] =
      this.dedDataArr[deduction_type][0]["amount"];
    this.AllBillRow[0]["ded"][j]["gov_rule"] =
      this.dedDataArr[deduction_type][0]["gov_rule"];
    this.AllBillRow[0]["ded"][j]["amt_type"] =
      this.dedDataArr[deduction_type][0]["ded_type"];
  }


  ChangeRule(gov_rule:any, deduction_type:any, j:any) {
    for (let p = 0; p < this.dedDataArr[deduction_type].length; p++) {
      if (gov_rule == this.dedDataArr[deduction_type][p]["gov_rule"]) {
        this.AllBillRow[0]["ded"][j]["ded_amount"] =
          this.dedDataArr[deduction_type][p]["amount"];
        this.AllBillRow[0]["ded"][j]["amt_type"] =
          this.dedDataArr[deduction_type][p]["ded_type"];
      }
    }
  }


  // ChangeDed(deduction_type, i, j) {
  //   this.AllBillRow[i]["ded"][j]["ded_amount"] =
  //     this.dedDataArr[deduction_type][0]["amount"];
  //   this.AllBillRow[i]["ded"][j]["gov_rule"] =
  //     this.dedDataArr[deduction_type][0]["gov_rule"];
  //   this.AllBillRow[i]["ded"][j]["amt_type"] =
  //     this.dedDataArr[deduction_type][0]["ded_type"];
  // }
  // ChangeRule(gov_rule, deduction_type, i, j) {
  //   for (let p = 0; p < this.dedDataArr[deduction_type].length; p++) {
  //     if (gov_rule == this.dedDataArr[deduction_type][p]["gov_rule"]) {
  //       this.AllBillRow[i]["ded"][j]["ded_amount"] =
  //         this.dedDataArr[deduction_type][p]["amount"];
  //       this.AllBillRow[i]["ded"][j]["amt_type"] =
  //         this.dedDataArr[deduction_type][p]["ded_type"];
  //     }
  //   }
  // }

  async getgst() {
    var resp = await this.billService.getgst(this.b_acct_id);
    if (resp["error"] == false) {
      this.hsnArr = resp.data;
      this.gstObj = new Object();
      this.hsnObj = new Object();
      this.rateObj = new Object();
      for (let i = 0; i < this.hsnArr.length; i++) {
        this.gstObj[this.hsnArr[i]["hsn_code"]] = this.hsnArr[i];
        this.hsnObj[this.hsnArr[i]["hsn_code"]] = this.hsnArr[i]["hsn_desc"];
        this.rateObj[this.hsnArr[i]["hsn_code"]] = this.hsnArr[i]["igst"];
      }
      console.log(this.rateObj);
    } else {
      swal.fire("Error", "..Error while getting GST!", "error");
    }
  }


  async getActiveFinYear() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.ledgerService.getActiveFinYear(JSON.stringify(obj));
    if (resp["error"] == false) {
      if (resp.data.length == 0) {
        swal.fire("Warning", "..Active Financial Year Not set!", "warning");
      } else {
        this.fin_year = resp.data[0].fin_year;
      }
    } else {
      swal.fire("Error", "..Error while getting active  fin year!", "error");
    }
  }



  addRow() {
    let index = this.AllBillRow.length + 1;
    this.AllBillRow.push({
      event_id: index,
      amount: 0,
      tax_amount: 0,
      cgst: 0,
      sgst: 0,
      igst: 0,
      ded: [],
      payable_amount: 0,
      deduction_amount: 0,
    });
  }
  dedAdd() {
    this.AllBillRow[0]["ded"].push({ deduction_type: null, ded_amount: 0 });
  }
  deleteDed(j:any) {
    this.AllBillRow[0]["ded"].splice(j, 1);
  }

  async changeGst(i:any) {
    this.AllBillRow[i]["cgst"] = 0;
    this.AllBillRow[i]["sgst"] = 0;
    this.AllBillRow[i]["igst"] = 0;
    if (this.AllBillRow[i]["gst_type"] == "cgst_sgst") {
      let amt = 0;
      amt =
        (this.AllBillRow[i]["amount"] *
          this.rateObj[this.AllBillRow[i]["hsn_code"]]) /
        100;

      this.AllBillRow[i]["cgst"] = parseFloat((amt / 2).toFixed(2));
      this.AllBillRow[i]["sgst"] = parseFloat((amt / 2).toFixed(2));
    }

    if (this.AllBillRow[i]["gst_type"] == "igst") {
      let amt = 0;
      amt =
        (this.AllBillRow[i]["amount"] *
          this.rateObj[this.AllBillRow[i]["hsn_code"]]) /
        100;

      this.AllBillRow[i]["igst"] = parseFloat(amt.toFixed(2));
    }

    let tax_amount =
      this.AllBillRow[i]["cgst"] +
      this.AllBillRow[i]["sgst"] +
      this.AllBillRow[i]["igst"];

    this.AllBillRow[i]["tax_amount"] = parseFloat(tax_amount.toFixed(2));
    this.AllBillRow[i]["total_amount"] =
      this.AllBillRow[i]["amount"] + parseFloat(tax_amount.toFixed(2));
  }

  deleteRow(i:any) {
    for (let j = 0; j < this.AllBillRow.length; j++) {
      if (j > i) {
        this.AllBillRow[j]["event_id"] = this.AllBillRow[j]["event_id"] - 1;
      }
    }
    this.AllBillRow.splice(i, 1);
  }

  amountObj:any = {}
  async getAllCBInfo() {
    var resp = await this.billService.getAllContingentBills(this.b_acct_id);
    if (resp["error"] == false) {
      this.allCBInfo = resp.data;
      console.log(this.allCBInfo)
      for (let i = 0; i < this.allCBInfo.length; i++) {
        this.allCBInfo[i]["select"] = false;
        let data = JSON.parse(this.allCBInfo[i]['data']);

        if (this.amountObj[this.allCBInfo[i]['work_id']] == undefined) {
          this.amountObj[this.allCBInfo[i]['work_id']] = Number(data['payable_rows'][0]['amount'])
        } else {
          this.amountObj[this.allCBInfo[i]['work_id']] += Number(data['payable_rows'][0]['amount'])
        }
      }
      let cb = [];
      for (let i = 0; i < this.allCBInfo.length; i++) {
        for (let j = 0; j < this.allWork.length; j++) {
          if (this.allCBInfo[i]['work_id'] == this.allWork[j]['work_id']) {
            cb.push(this.allCBInfo[i]);
          }
        }
      }
      this.allCBInfo = cb;
      console.log(this.allCBInfo)
      this.datasource = new MatTableDataSource(this.allCBInfo);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();
      console.log(this.amountObj)
    } else {
      this.spinner.hide();
      swal.fire("Error", "..Error while getting  all CB list!", "error");
    }
  }

  async printData(element:any) {
    this.obj = new Object();
    this.obj["work_id"] = element.work_id;
    this.obj["cb_id"] = element.cb_id;
    this.obj["cb_date"] = element.cb_date;
    this.obj["bill_date"] = element.bill_date;
    this.obj["bill_no"] = element.bill_no;
    this.obj["status"] = element.status;

    this.obj["party_le_id"] =
      this.AllworkObj[this.obj["work_id"]]["party_le_id"];
    this.obj["party_legal_name"] =
      this.partyNameObj[this.AllworkObj[this.obj["work_id"]]["party_le_id"]];
    this.obj["work_desc"] = this.AllworkObj[this.obj["work_id"]]["work_desc"];
    this.obj["work_order_no"] =
      this.AllworkObj[this.obj["work_id"]]["work_order_no"];
    for (let i = 0; i < this.all_party.length; i++) {
      if (this.obj["party_le_id"] == this.all_party[i]["le_id"]) {
        this.obj["party_phone_no"] = this.all_party[i]["party_phone_no"];
        this.obj["party_gstin_no"] = this.all_party[i]["party_gstin_no"];
        this.obj["party_email"] = this.all_party[i]["party_email"];
        break;
      }
    }

    console.log(this.obj);
    let ob :any= JSON.parse(element.data);
    this.AllBillRow = ob["payable_rows"];
    await this.check();
    await this.print1();
  }
  async open_update(element:any) {
    console.log(element);
    this.obj = new Object();
    this.obj["cb_id"] = element.cb_id;
    this.obj["cb_date"] = element.cb_date;
    this.obj["bill_date"] = element.bill_date;
    this.obj["bill_no"] = element.bill_no;
    this.obj["status"] = element.status;
    this.obj["work_id"] = element.work_id;
    this.obj["act_cd"] = element.act_cd;

    this.obj["work_desc"] = this.AllworkObj[this.obj["work_id"]]["work_desc"];
    this.obj["party_le_id"] =
      this.AllworkObj[this.obj["work_id"]]["party_le_id"];
    this.obj["proj_cd"] = this.AllworkObj[this.obj["work_id"]]["proj_cd"];
    this.obj["bud_cd"] = this.AllworkObj[this.obj["work_id"]]["bud_cd"];
    this.obj["prod_cd"] = this.AllworkObj[this.obj["work_id"]]["prod_cd"];
    this.obj["act_cd"] = this.AllworkObj[this.obj["work_id"]]["act_cd"];
    console.log(this.obj);

    let ob :any= JSON.parse(element.data);
    this.AllBillRow = ob["payable_rows"];

    for (let i = 0; i < this.all_party.length; i++) {
      if (this.obj["party_le_id"] == this.all_party[i]["le_id"]) {
        this.obj["party_phone_no"] = this.all_party[i]["party_phone_no"];
        this.obj["party_gstin_no"] = this.all_party[i]["party_gstin_no"];
        this.obj["party_email"] = this.all_party[i]["party_email"];
        break;
      }
    }

    this.selectObj = Object.assign({}, ob["selectObj"]);
    // await this.FilteredEvents();
    await this.check();

    $('.nav-tabs a[href="#tab-3"]').tab("show");
  }

  refresh() {
    this.obj = {};
    this.obj["cb_date"] = this.systemDate;
    this.AllBillRow = [];
    this.AllDedRow = [];
    this.total_ded_amount = 0;
    this.net_amount = 0;
    this.total_amt = 0;
    this.cgstAmount = 0;
    this.sgstAmount = 0;
    this.igstAmount = 0;
    this.totalAmount = 0;
    this.TaxAmount = 0;
    document.getElementById('subreset')!.click();
    document.getElementById('upreset')!.click();
  }


  amt_flag = false;

  async validateBill(form:any) {

    let zero_amt = false;
    let isvalidate = true;

    if (this.AllBillRow.length == 0) {
      form.status = "INVAILID"
      this.amt_flag = true;
      return isvalidate = false;
    }

    this.AllBillRow.map((x:any) => {
      if (x.amount <= 0 || x.amount == '' || x.payable_amount == 0) {
        zero_amt = true;
      }
    })

    if (zero_amt) {
      this.amt_flag = true;
      return isvalidate = false
    }

    return isvalidate;
  }

  async save(form:any) {
    let flag = await this.check();
    let fg = await this.validateBill(form);

    if (flag && fg) {
      this.spinner.show();
      let data = Object.assign({}, this.obj);
      data["payable_rows"] = this.AllBillRow;
      data["selectObj"] = this.selectObj;
      data["net_amt"] = this.net_amount.toFixed(2);
      let obj:any = Object.assign({}, this.obj);
      obj["b_acct_id"] = this.b_acct_id;
      obj["net_amt"] = this.net_amount.toFixed(2);
      obj["create_user_id"] = this.erpUser.user_id;
      obj["data"] = JSON.stringify(data);
      obj["status"] = "GENERATED";
      var resp = await this.billService.addgenericcb(obj);
      if (resp["error"] == false) {
        this.spinner.hide();
        this.obj["cb_id"] = resp.data;
        await this.getAllCBInfo();
        swal.fire("Success", "Bill Added Successfully!", "success");
        $('.nav-tabs a[href="#tab-1"]').tab("show");
        this.refresh();
        document.getElementById('subreset')!.click();

      } else {
        this.spinner.hide();
        swal.fire("Error", "Error while Adding Bill!", "error");
      }
    }
  }
  async check() {
    // await this.allAllocation()
    var total1 = 0;
    var cgstAmount = 0;
    var sgstAmount = 0;
    var igstAmount = 0;
    var totalAmount = 0;
    this.net_amount = 0;
    let obj:any = new Object();
    let obj_ded_rule:any = new Object();
    this.total_ded_amount = 0;
    this.AllDedRow = [];


    let tot_bill_amt = 0;

    this.AllBillRow.map((x:any) => {
      tot_bill_amt += x['amount']
    })





    for (let i = 0; i < this.AllBillRow.length; i++) {
      await this.changeGst(i);
      let totalDed = 0;
      cgstAmount = cgstAmount + this.AllBillRow[i]["cgst"];
      sgstAmount = sgstAmount + this.AllBillRow[i]["sgst"];
      igstAmount = igstAmount + this.AllBillRow[i]["igst"];
      let tax_amount = this.AllBillRow[i]["cgst"] + this.AllBillRow[i]["sgst"] + this.AllBillRow[i]["igst"];

      this.AllBillRow[i]["tax_amount"] = parseFloat(tax_amount.toFixed(2));
      this.AllBillRow[i]["payable_amount"] = this.AllBillRow[i]["amount"] + parseFloat(tax_amount.toFixed(2));
      this.AllBillRow[i]["total_amount"] = this.AllBillRow[i]["amount"] + parseFloat(tax_amount.toFixed(2));


      for (let j = 0; j < this.AllBillRow[i]["ded"].length; j++) {
        if (obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] == undefined) {
          obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] = 0;
        }
        if (obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] == undefined) {
          obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] = "";
        }
        obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] = obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] +
          "@" + this.AllBillRow[i]["ded"][j]["gov_rule"];

        if (this.AllBillRow[i]["ded"][j]["amt_type"] == "percentage") {
          // totalDed = totalDed + (this.AllBillRow[i]["amount"] * this.AllBillRow[i]["ded"][j]["ded_amount"]) / 100;
          totalDed = totalDed + (tot_bill_amt * this.AllBillRow[i]["ded"][j]["ded_amount"]) / 100;
          // obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] = obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] +
          //   (this.AllBillRow[i]["amount"] * this.AllBillRow[i]["ded"][j]["ded_amount"]) / 100;

          this.AllDedRow.push({
            deduction_type: this.AllBillRow[0]["ded"][j]["deduction_type"],
            // ded_amount: (this.AllBillRow[0]["amount"] * this.AllBillRow[0]["ded"][j]["ded_amount"]) / 100,
            ded_amount: (tot_bill_amt * this.AllBillRow[0]["ded"][j]["ded_amount"]) / 100,
            gov_rule: this.AllBillRow[0]["ded"][j]["gov_rule"],
          });

        } else {
          totalDed = totalDed + this.AllBillRow[0]["ded"][j]["ded_amount"];
          // obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] =
          //   obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] +
          //   this.AllBillRow[i]["ded"][j]["ded_amount"];


          this.AllDedRow.push({
            deduction_type: this.AllBillRow[i]["ded"][j]["deduction_type"],
            ded_amount: this.AllBillRow[i]["ded"][j]["ded_amount"],
            gov_rule: this.AllBillRow[i]["ded"][j]["gov_rule"],
          });
        }

        // this.AllDedRow.push({
        //   deduction_type: this.AllBillRow[i]["ded"][j]["deduction_type"],
        //   ded_amount: (this.AllBillRow[i]["amount"] * this.AllBillRow[i]["ded"][j]["ded_amount"]) / 100,
        //   gov_rule: this.AllBillRow[i]["ded"][j]["gov_rule"],
        // });
        // this.total_ded_amount = this.total_ded_amount + (this.AllBillRow[i]["amount"] * this.AllBillRow[i]["ded"][j]["ded_amount"]) / 100
      }


      this.total_ded_amount += totalDed;
      this.AllBillRow[i]["payable_amount"] = this.AllBillRow[i]["payable_amount"] - totalDed;
      this.AllBillRow[i]["deduction_amount"] = totalDed;
      this.net_amount = this.net_amount + this.AllBillRow[i]["payable_amount"];
      // total1 = total1 + this.AllBillRow[i]["amount"] + this.AllBillRow[i]["tax_amount"];
      total1 = total1 + tot_bill_amt + this.AllBillRow[i]["tax_amount"];
      totalAmount = totalAmount + this.AllBillRow[i]["amount"];
    }
    this.total_amt = parseFloat(total1.toFixed(2));
    this.totalAmount = parseFloat(totalAmount.toFixed(2));
    this.cgstAmount = parseFloat(cgstAmount.toFixed(2));
    this.sgstAmount = parseFloat(sgstAmount.toFixed(2));
    this.igstAmount = parseFloat(igstAmount.toFixed(2));

    // var keys = Object.keys(obj);
    // this.total_ded_amount = 0;
    // this.AllDedRow = [];
    // for (let i = 0; i < keys.length; i++) {
    //   this.AllDedRow.push({
    //     deduction_type: keys[i],
    //     ded_amount: obj[keys[i]],
    //     gov_rule: obj_ded_rule[keys[i]],
    //   });
    //   this.total_ded_amount = this.total_ded_amount + obj[keys[i]];
    // }
    // let flag = await this.checkBudgetAmount()
    return true;
  }

  async check1() {
    var total1 = 0;
    var cgstAmount = 0;
    var sgstAmount = 0;
    var igstAmount = 0;
    var totalAmount = 0;
    this.net_amount = 0;
    let obj :any= new Object();
    let obj_ded_rule:any = new Object();
    for (let i = 0; i < this.AllBillRow.length; i++) {
      await this.changeGst(i);

      cgstAmount = cgstAmount + this.AllBillRow[i]["cgst"];
      sgstAmount = sgstAmount + this.AllBillRow[i]["sgst"];
      igstAmount = igstAmount + this.AllBillRow[i]["igst"];
      let tax_amount =
        this.AllBillRow[i]["cgst"] +
        this.AllBillRow[i]["sgst"] +
        this.AllBillRow[i]["igst"];

      this.AllBillRow[i]["tax_amount"] = parseFloat(tax_amount.toFixed(2));
      this.AllBillRow[i]["payable_amount"] =
        this.AllBillRow[i]["amount"] + parseFloat(tax_amount.toFixed(2));
      this.AllBillRow[i]["total_amount"] =
        this.AllBillRow[i]["amount"] + parseFloat(tax_amount.toFixed(2));
      let totalDed = 0;
      for (let j = 0; j < this.AllBillRow[i]["ded"].length; j++) {
        if (obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] == undefined) {
          obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] = 0;
        }
        if (
          obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] ==
          undefined
        ) {
          obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] = "";
        }
        obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] =
          obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] +
          "@" +
          this.AllBillRow[i]["ded"][j]["gov_rule"];

        if (this.AllBillRow[i]["ded"][j]["amt_type"] == "percentage") {
          totalDed =
            totalDed +
            (this.AllBillRow[i]["amount"] *
              this.AllBillRow[i]["ded"][j]["ded_amount"]) /
            100;
          obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] =
            obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] +
            (this.AllBillRow[i]["amount"] *
              this.AllBillRow[i]["ded"][j]["ded_amount"]) /
            100;
        } else {
          totalDed = totalDed + this.AllBillRow[i]["ded"][j]["ded_amount"];
          obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] =
            obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] +
            this.AllBillRow[i]["ded"][j]["ded_amount"];
        }
      }

      this.AllBillRow[i]["payable_amount"] =
        this.AllBillRow[i]["payable_amount"] - totalDed;
      this.AllBillRow[i]["deduction_amount"] = totalDed;

      this.net_amount = this.net_amount + this.AllBillRow[i]["payable_amount"];

      total1 =
        total1 +
        this.AllBillRow[i]["amount"] +
        this.AllBillRow[i]["tax_amount"];
      totalAmount = totalAmount + this.AllBillRow[i]["amount"];
    }
    this.total_amt = parseFloat(total1.toFixed(2));
    this.totalAmount = parseFloat(totalAmount.toFixed(2));
    this.cgstAmount = parseFloat(cgstAmount.toFixed(2));
    this.sgstAmount = parseFloat(sgstAmount.toFixed(2));
    this.igstAmount = parseFloat(igstAmount.toFixed(2));

    var keys = Object.keys(obj);
    this.total_ded_amount = 0;
    this.AllDedRow = [];
    for (let i = 0; i < keys.length; i++) {
      this.AllDedRow.push({
        deduction_type: keys[i],
        ded_amount: obj[keys[i]],
        gov_rule: obj_ded_rule[keys[i]],
      });
      this.total_ded_amount = this.total_ded_amount + obj[keys[i]];
    }
  }

  async update(form:any) {
    let flag = await this.check();
    let fg = await this.validateBill(form);
    console.log(await this.validateBill(form))
    if (flag && fg) {
      let data = Object.assign({}, this.obj);
      data["payable_rows"] = this.AllBillRow;
      data["net_amt"] = this.net_amount.toFixed(2);
      let obj:any = Object.assign({}, this.obj);
      obj["b_acct_id"] = this.b_acct_id;
      obj["net_amt"] = this.net_amount.toFixed(2);
      obj["update_user_id"] = this.erpUser.user_id;
      obj["data"] = JSON.stringify(data);

      this.spinner.show();

      var resp = await this.billService.updateGenericCb(obj);
      if (resp["error"] == false) {
        await this.getAllCBInfo();
        this.spinner.hide();
        swal.fire("Success", "Bill Updated Successfully!", "success");
      } else {
        this.spinner.hide();
        swal.fire("Error", "...Error while updating bill !", "error");
      }
    }
  }

  async delete(element:any) {
    this.spinner.show();
    var obj1:any = new Object();
    obj1["b_acct_id"] = this.b_acct_id;
    obj1["cb_id"] = element.cb_id;
    var resp = await this.billService.deleteContingentBill(
      JSON.stringify(obj1)
    );
    if (resp["error"] == false) {
      await this.getAllCBInfo();
      this.spinner.hide();
      swal.fire("Success", "Bill Delete Successfully!", "success");
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while delete bill !", "error");
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter1(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }


  async changeCBStatus(cb_id:any, status:any) {
    var obj1:any = new Object();
    obj1["b_acct_id"] = this.b_acct_id;
    obj1["cb_id"] = [cb_id];
    obj1["status"] = status;
    obj1["update_user_id"] = this.erpUser.user_id;

    console.log(obj1);
    var resp = await this.billService.changeCbStatus(obj1);
    if (resp["error"] == false) {
      await this.getAllCBInfo();
      this.spinner.hide();
      swal.fire("Success", "Update Successfully!", "success");
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while updating bill !", "error");
    }
  }
  //Finding Bill Number


  countBill(element:any) {
    let count = 1;
    this.allCBInfo.map((x:any) => {
      if (element['work_id'] == x['work_id'] && x['status'] == 'ACCEPTED') {
        count++;
      }
    })
    return count;
  }


  postpixAdd(i:any) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }


  async accptedCB(element:any) {
    this.spinner.show()
    let countBill = this.countBill(element)


    let cb_id = [element["cb_id"]];
    let event = [];

    this.obj = new Object();
    this.obj["cb_id"] = element.cb_id;
    this.obj["cb_date"] = element.cb_date;
    this.obj["bill_date"] = element.bill_date;
    this.obj["bill_no"] = element.bill_no;
    this.obj["status"] = element.status;
    this.obj["work_id"] = element.work_id;
    this.obj["act_cd"] = element.act_cd;

    this.obj["work_desc"] = this.AllworkObj[this.obj["work_id"]]["work_desc"];
    this.obj["party_le_id"] =
      this.AllworkObj[this.obj["work_id"]]["party_le_id"];
    this.obj["proj_cd"] = this.AllworkObj[this.obj["work_id"]]["proj_cd"];
    this.obj["bud_cd"] = this.AllworkObj[this.obj["work_id"]]["bud_cd"];
    this.obj["prod_cd"] = this.AllworkObj[this.obj["work_id"]]["prod_cd"];
    this.obj["act_cd"] = this.AllworkObj[this.obj["work_id"]]["act_cd"];
    console.log(this.obj);

    let ob :any= JSON.parse(element.data);
    this.AllBillRow = ob["payable_rows"];

    for (let j = 0; j < this.all_party.length; j++) {
      if (this.obj["party_le_id"] == this.all_party[j]["le_id"]) {
        this.obj["party_phone_no"] = this.all_party[j]["party_phone_no"];
        this.obj["party_gstin_no"] = this.all_party[j]["party_gstin_no"];
        this.obj["party_email"] = this.all_party[j]["party_email"];
        break;
      }
    }

    this.selectObj = Object.assign({}, ob["selectObj"]);
    await this.check();

    let total_net :any= new Object();
    total_net["proj_cd"] =
      this.projectLeafUserObj["ACCOUNTWORK" + this.obj["work_id"]]["leaf_cd"];
    total_net["bud_cd"] = this.obj["bud_cd"];
    total_net["prod_cd"] = this.obj["prod_cd"];
    total_net["act_cd"] = this.obj["act_cd"];
    total_net["amt"] = this.totalAmount;
    total_net["net_amount"] = this.net_amount;
    total_net["gross_amount"] = this.totalAmount;
    total_net["party_id"] = this.obj["party_le_id"];
    total_net["work_id"] = this.obj["work_id"];
    total_net["bill_date"] = this.obj["bill_date"];
    total_net["cb_id"] = this.obj["cb_id"];
    total_net["event_cd"] = this.obj["cb_id"];
    total_net["event_rule"] = "NA";
    if (total_net["amt"] != 0) {
      event.push(total_net);
    }
    let total_cgst:any = new Object();
    total_cgst["proj_cd"] =
      this.projectLeafUserObj["ACCOUNTWORK" + this.obj["work_id"]]["leaf_cd"];
    total_cgst["bud_cd"] = this.dedtoBudeget["CGSTINPUTNA"];
    total_cgst["prod_cd"] = this.obj["prod_cd"];
    total_cgst["act_cd"] = this.dedtoActivity["CGSTINPUTNA"];
    total_cgst["amt"] = this.cgstAmount;
    total_cgst["net_amount"] = this.net_amount;
    total_cgst["gross_amount"] = this.totalAmount;
    total_cgst["party_id"] = this.obj["party_le_id"];
    total_cgst["work_id"] = this.obj["work_id"];
    total_cgst["bill_date"] = this.obj["bill_date"];

    total_cgst["cb_id"] = this.obj["cb_id"];
    total_cgst["event_cd"] = "CGSTINPUT";
    total_cgst["event_rule"] = "NA";

    if (total_cgst["amt"] != 0) {
      event.push(total_cgst);
    }

    let total_sget:any = new Object();
    total_sget["proj_cd"] =
      this.projectLeafUserObj["ACCOUNTWORK" + this.obj["work_id"]]["leaf_cd"];
    total_sget["bud_cd"] = this.dedtoBudeget["SGSTINPUTNA"];
    total_sget["prod_cd"] = this.obj["prod_cd"];
    total_sget["act_cd"] = this.dedtoActivity["SGSTINPUTNA"];
    total_sget["amt"] = this.sgstAmount;
    total_sget["net_amount"] = this.net_amount;
    total_sget["gross_amount"] = this.totalAmount;
    total_sget["party_id"] = this.obj["party_le_id"];
    total_sget["work_id"] = this.obj["work_id"];
    total_sget["bill_date"] = this.obj["bill_date"];

    total_sget["cb_id"] = this.obj["cb_id"];
    total_sget["event_cd"] = "SGSTINPUT";
    total_sget["event_rule"] = "NA";

    if (total_sget["amt"] != 0) {
      event.push(total_sget);
    }

    let total_igst:any = new Object();
    total_igst["proj_cd"] =
      this.projectLeafUserObj["ACCOUNTWORK" + this.obj["work_id"]]["leaf_cd"];
    total_igst["bud_cd"] = this.dedtoBudeget["IGSTINPUTNA"];
    total_igst["prod_cd"] = this.obj["prod_cd"];
    total_igst["act_cd"] = this.dedtoActivity["IGSTINPUTNA"];
    total_igst["amt"] = this.igstAmount;
    total_igst["net_amount"] = this.net_amount;
    total_igst["gross_amount"] = this.totalAmount;
    total_igst["party_id"] = this.obj["party_le_id"];
    total_igst["work_id"] = this.obj["work_id"];
    total_igst["bill_date"] = this.obj["bill_date"];

    total_igst["cb_id"] = this.obj["cb_id"];
    total_igst["event_cd"] = "IGSTINPUT";
    total_igst["event_rule"] = "NA";

    if (total_igst["amt"] != 0) {
      event.push(total_igst);
    }

    for (let k = 0; k < this.AllDedRow.length; k++) {
      let all_ded:any = new Object();
      all_ded["proj_cd"] =
        this.projectLeafUserObj["ACCOUNTWORK" + this.obj["work_id"]]["leaf_cd"];
      all_ded["bud_cd"] =
        this.dedtoBudeget[this.AllDedRow[k]["deduction_type"] + this.AllDedRow[k]["gov_rule"]];
      all_ded["prod_cd"] = this.obj["prod_cd"];
      all_ded["act_cd"] =
        this.dedtoActivity[this.AllDedRow[k]["deduction_type"] + this.AllDedRow[k]["gov_rule"]];
      all_ded["amt"] = this.AllDedRow[k]["ded_amount"];
      all_ded["net_amount"] = this.net_amount;
      all_ded["gross_amount"] = this.totalAmount;
      all_ded["party_id"] = this.obj["party_le_id"];
      all_ded["work_id"] = this.obj["work_id"];
      all_ded["bill_date"] = this.obj["bill_date"];
      all_ded["cb_id"] = this.obj["cb_id"];
      all_ded["event_cd"] = this.AllDedRow[k]["deduction_type"];
      all_ded["event_rule"] = this.AllDedRow[k]["gov_rule"];

      if (all_ded["amt"] != 0) {
        event.push(all_ded);
      }
    }

    if (event.length == 0) {
      swal.fire("Warning", "All Event are with 0 amount...", "warning");
      return;
    }

    let event_code_data = [];
    for (let p = 0; p < event.length; p++) {

      if (!this.hierobj["activity"][event[p]["act_cd"]] || !this.hierobj["budget"][event[p]["bud_cd"]]
        || !this.hierobj["product"][event[p]["prod_cd"]] || !this.hierobj["project"][event[p]["proj_cd"]]) {
        Swal.fire("error", 'Please Check Deduction Mapping For ' + event[p]['event_cd'], 'error');
        this.spinner.hide();
        return;
      }

      if (this.hierobj["activity"][event[p]["act_cd"]] == undefined) {
        Swal.fire("error", 'Please check Activity Mapping ' + event[p], 'error');
        this.spinner.hide();
        return;
      } else if (this.hierobj["budget"][event[p]["bud_cd"]]["leaf_value"] == undefined) {
        Swal.fire("error", 'Please check Budget Mapping ' + event[p], 'error');
        this.spinner.hide();
        return;
      } else if (this.hierobj["product"][event[p]["prod_cd"]]["leaf_value"] == undefined) {
        Swal.fire("error", 'Please check Product Mapping ' + event[p], 'error');
        this.spinner.hide();
      } else if (this.hierobj["project"][event[p]["proj_cd"]]["leaf_value"] == undefined) {
        Swal.fire("error", 'Please check Project Mapping ' + event[p], 'error');
        this.spinner.hide();
        return;
      }
      event_code_data.push({
        act_value: this.hierobj["activity"][event[p]["act_cd"]]["leaf_value"],
        bud_value: this.hierobj["budget"][event[p]["bud_cd"]]["leaf_value"],
        prod_value: this.hierobj["product"][event[p]["prod_cd"]]["leaf_value"],
        proj_value: this.hierobj["project"][event[p]["proj_cd"]]["leaf_value"],
        act_cd: event[p]["act_cd"],
        bud_cd: event[p]["bud_cd"],
        prod_cd: event[p]["prod_cd"],
        proj_cd: event[p]["proj_cd"],
      });



    }

    // console.log(event_code_data);
    let objdata :any= new Object();

    objdata["b_acct_id"] = this.b_acct_id;
    objdata["data"] = event_code_data;
    console.log(objdata);
    let datae = await this.createEventService.getMultipleEventForModule(
      objdata
    );
    // console.log(datae);

    let event_group_data = [];
    for (let i = 0; i < event.length; i++) {
      let obj:any = new Object();
      console.log(event[i]);
      obj["evt_grp_dt"] = new Date().toISOString().split("T")[0];
      obj["bus_event_type"] = "CB";
      obj["demand_id"] = event[i]["cb_id"];
      obj["party_id"] = event[i]["party_id"];
      let ev_cd =
        event[i]["act_cd"] +
        event[i]["bud_cd"] +
        event[i]["prod_cd"] +
        event[i]["proj_cd"];
      obj["ev_ln_dist_bus_ev_cd"] = datae["data"][ev_cd]["event_code"];
      obj["bank_acct_num"] = null;
      obj["invoice_id"] = event[i]["cb_id"];
      obj["create_user_id"] = this.erpUser["user_id"];
      obj["arr_num"] = event[i]["work_id"];
      obj["ar_num_in_src"] = event[i]["work_id"];
      obj["ar_instr_cd"] = event[i]["work_id"];
      obj["cp_num_in_src"] = event[i]["party_id"];
      obj["cp_nm"] =
        this.partyNameObj[this.AllworkObj[event[i]["work_id"]]["party_le_id"]];
      console.log(event[i]);
      obj["ev_grp_amt"] = event[i]["gross_amount"];
      obj["ev_grp_cts"] = event[i]["bill_date"].split("T")[0];
      obj["ev_grp_ets"] = new Date().toISOString().split("T")[0];
      obj["ev_grp_id"] = event[i]["cb_id"];
      obj["ev_grp_lcl_unq_num_in_src"] = event[i]["cb_id"];
      obj["ev_grp_src_cd"] = "ACCOUNT";
      obj["ev_grp_tcd"] = "CB";
      obj["ev_ln_amt"] = Number(event[i]["amt"].toFixed(2));
      obj["ev_ln_cmnt"] = event[i]["event_rule"]; //
      obj["ev_ln_dist_aff_cc_cd"] = 0;
      obj["ev_ln_dist_bdgt_cd"] = event[i]["bud_cd"];
      obj["ev_ln_dist_cc_cd"] =
        this.hierobj["project"][event[i]["proj_cd"]]["lvl2_cd"]; //cost center
      obj["ev_ln_dist_pd_cd"] = event[i]["prod_cd"];
      obj["ev_ln_dist_prj_cd"] = event[i]["proj_cd"];
      obj["ev_ln_num"] = event[i]["cb_id"];
      obj["ev_ln_org_cd"] = this.erpUser["account_short_name"];
      obj["ev_ln_sku_desc"] = 0;
      obj["ev_ln_sku_tcd"] = 0;
      obj["ev_ln_bndl_qty"] = 0;
      obj["ev_ln_calc_total_item_qty"] = 0;
      obj["ev_ln_dist_amt"] = Number(event[i]["amt"].toFixed(2));
      obj["ev_ln_dist_curr_cd"] = "INR";
      obj["ev_ln_dist_num"] = 0;
      obj["ev_ln_dist_rt"] = 0;
      obj["ev_ln_dscnt_amt"] = 0;
      obj["ev_ln_dscnt_rt"] = 0;
      obj["ev_ln_eff_calc_item_rate"] = 0;
      obj["ev_ln_item_per_pkt_qty"] = 0;
      obj["ev_ln_item_rt"] = 0;
      obj["ev_ln_manual_override_item_rt"] = 0;
      obj["ev_ln_pkt_per_bndl_qty"] = 0;
      obj["ev_ln_tax_incl_rt"] = 0;
      obj["pmt_txn_id"] = 0;
      obj["ev_ln_dist_id"] = 0;
      obj["ev_ln_dist_tcd"] = "DOCUMENT LINE";
      obj["ev_ln_id"] = event[i]["event_cd"];
      obj["ev_ln_tcd"] = "DOCUMENT LINE";
      obj["pmt_cp_ar_src_cd"] = 0;
      obj["pmt_cp_ar_num_in_src"] = 0;
      obj["pmt_cp_ar_instr_cd"] = 0;
      obj["pmt_cp_num_in_src"] = 0;
      obj["pmt_cp_ev_grp_id"] = 0;
      obj["pmt_cp_ev_grp_src_cd"] = 0;
      obj["pmt_cp_ev_grp_tcd"] = 0;
      obj["pmt_cp_ev_grp_lcl_unq_num_in_src"] = 0;
      obj["pmt_cp_nm"] = 0;
      obj["pmt_rp_bnk_acct_id"] = 0;
      obj["pmt_cp_bnk_acct_id"] = 0;
      obj["pmt_cp_txn_id"] = 0;

      event_group_data.push(Object.assign({}, obj));
    }

    // console.log(event_group_data);
    let eventgroupobj:any = new Object();

    let netPayable:any = Number(event[0]["net_amount"].toFixed(0));
    eventgroupobj["is_rec_or_pay"] = "PAYABLE";
    eventgroupobj["local_doc_no"] = event[0]["cb_id"];
    eventgroupobj["local_doc_desc"] = this.postpixAdd(countBill) + " bill of " + this.partyNameObj[this.AllworkObj[event[0]["work_id"]]["party_le_id"]]
      + " for " + this.AllworkObj[event[0]["work_id"]]["work_desc"];
    eventgroupobj["module_cd"] = "ACCOUNT";
    eventgroupobj["module_doc_type"] = "CB";
    eventgroupobj["status"] = "APPROVED";
    eventgroupobj["total_amt"] = netPayable;
    eventgroupobj["doc_type"] = "CB";
    eventgroupobj["data"] = event_group_data;
    eventgroupobj["create_user_id"] = this.erpUser["user_id"];
    eventgroupobj["b_acct_id"] = this.erpUser["b_acct_id"];
    eventgroupobj["req_data"] = { bill_id: event[0]["cb_id"], node_cd: event[0]["work_id"] }
    let resp1 = await this.createEventService.createevent(eventgroupobj);
    if (resp1["error"] == false) {
      var obj1:any = new Object();
      obj1["b_acct_id"] = this.b_acct_id;
      obj1["cb_id"] = cb_id;
      obj1["status"] = "ACCEPTED";
      obj1["update_user_id"] = this.erpUser.user_id;
      this.spinner.show();
      var resp = await this.billService.acceptCb(obj1);
      if (resp["error"] == false) {
        await this.getAllCBInfo();
        this.spinner.hide();
        swal.fire("Success", "Accepted Successfully!", "success");
      } else {
        this.spinner.hide();
        swal.fire("Error", "...Error while accepting bill !", "error");
      }
    } else {
      swal.fire("Error", "...Error while accepting bill !", "error");
    }
    this.spinner.hide();
  }

  async print1() {
    var txt = "CB(CONTINGENT BILL)";
    var dd:any = {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        var arr = [];
        var obj = {
          text: txt + "     Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj);
        var obj1 = {
          text: "CONTINGENT BILL",
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj1);

        return arr;
      },

      pageOrientation: "landscape",
      pageMargins: [40, 60, 40, 60],
      content: [],
    };
    var header1 = {
      columns: [
        {
          width: "*",
          text: "Party Name :",

        },
        {
          width: "*",
          text: this.obj["party_legal_name"],
        },
        {
          width: "*",
          text: "CB No. :",
          bold: true,
        },
        {
          width: "*",
          text: this.obj["cb_id"],
        },
      ],
    };
    var header2 = {
      columns: [
        {
          width: "*",
          text: "Work Order No. :",
          bold: true,
        },
        {
          width: "*",
          text: this.obj["work_order_no"],
        },
        {
          width: "*",
          text: "Date :",
          bold: true,
        },
        {
          width: "*",
          text: this.mainService.dateFormatChange(this.obj["cb_date"]),
        },
      ],
    };
    var header3 = {
      columns: [
        {
          width: "*",
          text: "Work/Service Name :",
          bold: true,
        },
        {
          width: "*",
          text: this.obj["work_desc"],
        },
        {
          width: "*",
          text: "Invoice No. :",
          bold: true,
        },
        {
          width: "*",
          text: this.obj["bill_no"],
        },
      ],
    };
    var header4 = {
      columns: [
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "Invoice Date :",
          bold: true,
        },
        {
          width: "*",
          text: this.mainService.dateFormatChange(this.obj["bill_date"]),
        },
      ],
    };
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });

    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header2);
    dd.content.push({ text: " " });
    dd.content.push(header3);
    dd.content.push({ text: " " });
    dd.content.push(header4);
    dd.content.push({ text: " " });

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });

    var header5 = {
      columns: [
        {
          width: "*",
          text: "DESCRIPTION",
          bold: true,
        },
        {
          width: "*",
          text: "HSN",
          bold: true,
        },
        {
          width: "*",
          text: "RATE",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "AMOUNT",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "CGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "SGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "IGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "TOTAL",
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(header5);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });

    for (var i = 0; i < this.AllBillRow.length; i++) {
      var objRow = {
        columns: [
          {
            width: "*",
            text: this.AllBillRow[i]["event_desc"],
            bold: true,
          },
          {
            width: "*",
            text: this.hsnObj[this.AllBillRow[i]["hsn_code"]],
          },
          {
            width: "*",
            text: this.rateObj[this.AllBillRow[i]["hsn_code"]],
            alignment: "right",
          },
          {
            width: "*",
            text: this.AllBillRow[i]["amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.AllBillRow[i]["cgst"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.AllBillRow[i]["sgst"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.AllBillRow[i]["igst"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.AllBillRow[i]["total_amount"],
            bold: true,
            alignment: "right",
          },
        ],
      };

      dd.content.push(objRow);
      dd.content.push({ text: " " });
      if (this.AllBillRow[i]["ded"].length > 0) {
        var objRow1 = {
          columns: [
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "Deductions",
              bold: true,
            },
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "",
            },
          ],
        };
        dd.content.push(objRow1);
        dd.content.push({ text: " " });
      }

      for (let j = 0; j < this.AllBillRow[i]["ded"].length; j++) {
        if (this.AllBillRow[i]["ded"][j]["amt_type"] == "percentage") {
          var dedRowObj = {
            columns: [
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: j + 1 + ".",
              },
              {
                width: "*",
                text: this.dedTypeObj[
                  this.AllBillRow[i]["ded"][j]["deduction_type"]
                ],
              },

              {
                width: "*",
                text: (
                  (this.AllBillRow[i]["amount"] *
                    this.AllBillRow[i]["ded"][j]["ded_amount"]) /
                  100
                ).toFixed(2),
              },
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: "",
              },
            ],
          };
          dd.content.push(dedRowObj);
        } else {
          var dedRowObj = {
            columns: [
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: j + 1 + ".",
              },
              {
                width: "*",
                text: this.dedTypeObj[
                  this.AllBillRow[i]["ded"][j]["deduction_type"]
                ],
              },

              {
                width: "*",
                text: this.AllBillRow[i]["ded"][j]["ded_amount"].toFixed(2),
              },
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: "",
              },
            ],
          };
          dd.content.push(dedRowObj);
        }

        dd.content.push({ text: " " });
      }
      var dedObj = {
        columns: [
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "DEDUCTION AMOUNT:",
            bold: true,
          },
          {
            width: "*",
            text: this.AllBillRow[i]["deduction_amount"].toFixed(2),
            bold: true,
            alignment: "right",
          },
        ],
      };
      var paybObj = {
        columns: [
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "TOTAL PAYABLE AMOUNT:",
            bold: true,
          },
          {
            width: "*",
            text: this.AllBillRow[i]["payable_amount"].toFixed(2),
            bold: true,
            alignment: "right",
          },
        ],
      };
      dd.content.push(dedObj);
      dd.content.push({ text: " " });
      dd.content.push(paybObj);
      dd.content.push({ text: " " });
      dd.content.push({
        canvas: [
          { type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 },
        ],
      });
      dd.content.push({ text: " " });
    }

    var totalObjRow = {
      columns: [
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "TOTAL",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: this.totalAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.cgstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.sgstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.igstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.net_amount.toFixed(2),
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(totalObjRow);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 2 }],
    });
    dd.content.push({ text: " " });

    var netPayRow = {
      columns: [
        {
          width: "*",
          text: "NET PAYABLE AMOUNT:   " + this.net_amount.toFixed(2),
          bold: true,
          fontSize: 20,
          alignment: "right",
        },
      ],
    };
    dd.content.push(netPayRow);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 2 }],
    });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    var header7 = {
      columns: [
        {
          width: "*",
          text: "DEDUCTIONS",
          bold: true,
        },
      ],
    };
    dd.content.push(header7);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });

    var header6 = {
      columns: [
        {
          width: "*",
          text: "DESCRIPTION",
          bold: true,
        },
        {
          width: "*",
          text: "AMOUNT",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
      ],
    };
    dd.content.push(header6);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });

    for (var i = 0; i < this.AllDedRow.length; i++) {
      var ded = {
        columns: [
          {
            width: "*",
            text: this.dedTypeObj[this.AllDedRow[i]["deduction_type"]],
          },
          {
            width: "*",
            text: this.AllDedRow[i]["ded_amount"],
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
        ],
      };
      dd.content.push(ded);
      dd.content.push({ text: " " });
    }

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });
    var dedPayRow = {
      columns: [
        {
          width: "*",
          text: "TOTAL DEDUCTION AMOUNT:   " + this.total_ded_amount.toFixed(2),
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(dedPayRow);
    dd.content.push({ text: " " });

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    dd.content.push({
      text: "Passed for payment of Rupees.........................................................................................................................(in word)",
    });

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    var sig = {
      columns: [
        {
          width: "*",
          text: "Accounts Clerk",
          bold: true,
        },
        {
          width: "*",
          text: "Accountant",
          bold: true,
        },
        {
          width: "*",
          text: "AAO/AO",
          bold: true,
        },
        {
          width: "*",
          text: "FC",
          bold: true,
        },
      ],
    };
    dd.content.push(sig);

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({
      text: "Above paypent of Rupees.........................................................................................................................(in word) sanctioned",
    });
    pdfMake.createPdf(dd).download("bill.pdf");
  }
  SubmitSelectedEvnetHier() { }

  //*************************************************Unused Code********************************************************************** */
  // async acceptSelected() {
  //   var processed = [];
  //   var generated = [];
  //   var rejected = [];
  //   var accepted = [];
  //   for (let i = 0; i < this.allCBInfo.length; i++) {
  //     if (this.allCBInfo[i]['select'] == true && this.allCBInfo[i]['status'] == 'APPROVED') {
  //       generated.push(this.allCBInfo[i]);
  //     } else if (this.allCBInfo[i]['select'] == true && this.allCBInfo[i]['status'] == 'ACCEPTED') {
  //       accepted.push(this.allCBInfo[i]);
  //     } else if (this.allCBInfo[i]['select'] == true && this.allCBInfo[i]['status'] == 'REJECTED') {
  //       rejected.push(this.allCBInfo[i]);
  //     } else if (this.allCBInfo[i]['select'] == true && this.allCBInfo[i]['status'] == 'PROCESSED') {
  //       processed.push(this.allCBInfo[i]);
  //     }
  //   }

  //   if (accepted.length != 0 || rejected.length != 0 || processed.length != 0) {
  //     swal.fire("Error", "...In the Selected Bill " + accepted.length + " Of ACCEPTED Bill And " + rejected.length + " Of REJECTED Bill And " + processed.length + " Of PROCESSED Bill!!", 'error');
  //     this.spinner.hide();
  //     return;
  //   } else {

  //     let cb_id = [];
  //     let event = [];
  //     console.log(generated)

  //     for (let i = 0; i < generated.length; i++) {
  //       cb_id.push(generated[i]['cb_id']);

  //       let element = generated[i];
  //       console.log(element)
  //       this.obj = new Object();
  //       this.obj['cb_id'] = element.cb_id
  //       this.obj['cb_date'] = element.cb_date
  //       this.obj['bill_date'] = element.bill_date
  //       this.obj['bill_no'] = element.bill_no
  //       this.obj['status'] = element.status
  //       this.obj['work_id'] = element.work_id;
  //       this.obj['act_cd'] = element.act_cd;

  //       this.obj['work_desc'] = this.AllworkObj[this.obj['work_id']]['work_desc'];
  //       this.obj['party_le_id'] = this.AllworkObj[this.obj['work_id']]['party_le_id'];
  //       this.obj['proj_cd'] = this.AllworkObj[this.obj['work_id']]['proj_cd'];
  //       this.obj['bud_cd'] = this.AllworkObj[this.obj['work_id']]['bud_cd'];
  //       this.obj['prod_cd'] = this.AllworkObj[this.obj['work_id']]['prod_cd'];
  //       console.log(this.obj)

  //       let ob :any= JSON.parse(element.data)
  //       this.AllBillRow = ob['payable_rows'];

  //       for (let j = 0; j < this.all_party.length; j++) {
  //         if (this.obj['party_le_id'] == this.all_party[j]['le_id']) {
  //           this.obj['party_phone_no'] = this.all_party[j]['party_phone_no']
  //           this.obj['party_gstin_no'] = this.all_party[j]['party_gstin_no']
  //           this.obj['party_email'] = this.all_party[j]['party_email']
  //           break;
  //         }
  //       }

  //       this.selectObj = Object.assign({}, ob['selectObj']);
  //       await this.check();

  //       let total_net = new Object();
  //       total_net['proj_cd'] =this.projectLeafUserObj['ACCOUNTWORK'+this.obj['work_id']]['leaf_cd']
  //       total_net['bud_cd'] = this.obj['bud_cd']
  //       total_net['prod_cd'] = this.obj['prod_cd']
  //       total_net['act_cd'] = this.obj['act_cd']
  //       total_net['amt'] = this.totalAmount;
  //       total_net['net_amount'] = this.net_amount;
  //       total_net['party_id'] = this.obj['party_le_id'];
  //       total_net['work_id'] = this.obj['work_id'];
  //       total_net['bill_date'] = this.obj['bill_date'];
  //       total_net['cb_id'] = this.obj['cb_id'];
  //       event.push(total_net);
  //       let total_cgst = new Object();
  //       total_cgst['proj_cd'] = this.projectLeafUserObj['ACCOUNTWORK'+this.obj['work_id']]['leaf_cd']
  //       total_cgst['bud_cd'] = this.dedtoBudeget['CGST']
  //       total_cgst['prod_cd'] = this.obj['prod_cd']
  //       total_cgst['act_cd'] = this.dedtoActivity['CGST']
  //       total_cgst['amt'] = this.cgstAmount;
  //       total_cgst['net_amount'] = this.net_amount;
  //       total_cgst['party_id'] = this.obj['party_le_id'];
  //       total_cgst['work_id'] = this.obj['work_id'];
  //       total_cgst['bill_date'] = this.obj['bill_date'];

  //       total_cgst['cb_id'] = this.obj['cb_id'];
  //       event.push(total_cgst)

  //       let total_sget = new Object();
  //       total_sget['proj_cd'] =this.projectLeafUserObj['ACCOUNTWORK'+this.obj['work_id']]['leaf_cd']
  //       total_sget['bud_cd'] = this.dedtoBudeget['SGST']
  //       total_sget['prod_cd'] = this.obj['prod_cd']
  //       total_sget['act_cd'] = this.dedtoActivity['SGST']
  //       total_sget['amt'] = this.sgstAmount;
  //       total_sget['net_amount'] = this.net_amount;
  //       total_sget['party_id'] = this.obj['party_le_id'];
  //       total_sget['work_id'] = this.obj['work_id'];
  //       total_sget['bill_date'] = this.obj['bill_date'];

  //       total_sget['cb_id'] = this.obj['cb_id'];

  //       event.push(total_sget)

  //       let total_igst = new Object();
  //       total_igst['proj_cd'] =this.projectLeafUserObj['ACCOUNTWORK'+this.obj['work_id']]['leaf_cd']
  //       total_igst['bud_cd'] = this.dedtoBudeget['IGST']
  //       total_igst['prod_cd'] = this.obj['prod_cd']
  //       total_igst['act_cd'] = this.dedtoActivity['IGST']
  //       total_igst['amt'] = this.igstAmount;
  //       total_igst['net_amount'] = this.net_amount;
  //       total_igst['party_id'] = this.obj['party_le_id'];
  //       total_igst['work_id'] = this.obj['work_id'];
  //       total_igst['bill_date'] = this.obj['bill_date'];

  //       total_igst['cb_id'] = this.obj['cb_id'];
  //       event.push(total_igst)

  //       for (let k = 0; k < this.AllDedRow.length; k++) {
  //         let all_ded = new Object();
  //         all_ded['proj_cd'] =this.projectLeafUserObj['ACCOUNTWORK'+this.obj['work_id']]['leaf_cd']
  //         all_ded['bud_cd'] = this.dedtoBudeget[this.AllDedRow[k]['deduction_type']]
  //         all_ded['prod_cd'] = this.obj['prod_cd']
  //         all_ded['act_cd'] = this.dedtoActivity[this.AllDedRow[k]['deduction_type']]
  //         all_ded['amt'] = this.AllDedRow[k]['ded_amount']
  //         all_ded['net_amount'] = this.net_amount;
  //         all_ded['party_id'] = this.obj['party_le_id'];
  //         all_ded['work_id'] = this.obj['work_id'];
  //         all_ded['bill_date'] = this.obj['bill_date'];
  //         all_ded['cb_id'] = this.obj['cb_id'];
  //         event.push(all_ded)

  //       }

  //     }
  //     console.table(event);
  //     console.log(this.hierobj)

  //     let event_code_data = [];
  //     for (let p = 0; p < event.length; p++) {
  //       event_code_data.push({
  //         act_value: this.hierobj['activity'][event[p]['act_cd']]['leaf_value'] , bud_value: this.hierobj['budget'][event[p]['bud_cd']]['leaf_value'],
  //         prod_value: this.hierobj['product'][event[p]['prod_cd']]['leaf_value'] , proj_value: this.hierobj['project'][event[p]['proj_cd']]['leaf_value'] ,
  //         act_cd: event[p]['act_cd'], bud_cd: event[p]['bud_cd'], prod_cd: event[p]['prod_cd'], proj_cd: event[p]['proj_cd']
  //       })
  //     }

  //     console.log(event_code_data);
  //     let objdata = new Object();

  //     objdata['b_acct_id'] = this.b_acct_id;
  //     objdata['data'] = event_code_data
  //     console.log(objdata)
  //     let datae = await this.createEventService.getMultipleEventForModule(objdata)
  //     console.log(datae);

  //     let event_group_data=[];
  //     for (let i = 0; i < event.length; i++) {
  //       let obj:any = new Object()
  //       console.log(event[i])
  //       obj['evt_grp_dt'] = new Date().toISOString().split("T")[0]
  //       obj['bus_event_type'] = 'CB'
  //       obj['demand_id'] = event[i]['cb_id']
  //       obj['party_id'] = event[i]['party_id']
  //       let ev_cd=event[i]['act_cd'] + event[i]['bud_cd'] +  event[i]['prod_cd']  +  event[i]['proj_cd'] ;
  //       obj['ev_ln_dist_bus_ev_cd'] = datae['data'][ev_cd]['event_code']
  //       obj['event_code'] = datae['data'][ev_cd]['event_code']
  //       obj['event_id'] = event[i]['cb_id']
  //       obj['event_ln_id'] = event[i]['cb_id']
  //       obj['bank_acct_num'] = null;
  //       obj['event_desc'] = datae['data'][ev_cd]['event_desc']
  //       obj['txn_amt'] = Number((event[i]['amt']).toFixed(2))
  //       obj['invoice_id'] = event[i]['cb_id']
  //       obj['create_user_id'] = this.erpUser['user_id']
  //       obj['arr_num'] = event[i]['work_id']
  //       obj['ar_num_in_src'] = event[i]['work_id']
  //       obj['ar_instr_cd'] = event[i]['work_id']
  //       obj['cp_num_in_src'] = event[i]['work_id']
  //       obj['cp_nm'] = this.partyNameObj[this.AllworkObj[event[i]['work_id']]['party_le_id']]
  //       obj['ev_grp_amt'] =Number((event[i]['amt']).toFixed(2))
  //       obj['ev_grp_cts'] = event[i]['bill_date'].split("T")[0]
  //       obj['ev_grp_ets'] = new Date().toISOString().split("T")[0]
  //       obj['ev_grp_id'] = event[i]['cb_id']
  //       obj['ev_grp_lcl_unq_num_in_src'] =event[i]['cb_id']
  //       obj['ev_grp_src_cd'] = 'ACCOUNT'
  //       obj['ev_grp_tcd'] = 'CB'
  //       obj['ev_ln_amt'] =Number((event[i]['amt']).toFixed(2))
  //       obj['ev_ln_cmnt'] = 0
  //       obj['ev_ln_dist_aff_cc_cd'] = 0
  //       obj['ev_ln_dist_bdgt_cd'] = event[i]['bud_cd']
  //       obj['ev_ln_dist_cc_cd'] = this.hierobj['project'][event[i]['proj_cd']]['lvl2_cd']//cost center
  //       obj['ev_ln_dist_pd_cd'] = event[i]['prod_cd']
  //       obj['ev_ln_dist_prj_cd'] =event[i]['proj_cd']
  //       obj['ev_ln_num'] = event[i]['cb_id']
  //       obj['ev_ln_org_cd'] = this.erpUser['account_short_name']
  //       obj['ev_ln_sku_desc'] = 0
  //       obj['ev_ln_sku_tcd'] = 0
  //       obj['ev_ln_bndl_qty'] = 0
  //       obj['ev_ln_calc_total_item_qty'] = 0
  //       obj['ev_ln_dist_amt'] = Number((event[i]['amt']).toFixed(2))
  //       obj['ev_ln_dist_curr_cd'] = 'INR'
  //       obj['ev_ln_dist_num'] = 0
  //       obj['ev_ln_dist_rt'] = 0
  //       obj['ev_ln_dscnt_amt'] = 0
  //       obj['ev_ln_dscnt_rt'] = 0
  //       obj['ev_ln_eff_calc_item_rate'] = 0
  //       obj['ev_ln_item_per_pkt_qty'] = 0
  //       obj['ev_ln_item_rt'] = 0
  //       obj['ev_ln_manual_override_item_rt'] = 0
  //       obj['ev_ln_pkt_per_bndl_qty'] = 0
  //       obj['ev_ln_tax_incl_rt'] = 0
  //       obj['pmt_txn_id'] = 0
  //       obj['ev_ln_dist_id'] = 0
  //       obj['ev_ln_dist_tcd'] = 'DOCUMENT LINE'
  //       obj['ev_ln_id'] = event[i]['cb_id']
  //       obj['ev_ln_tcd'] = 'DOCUMENT LINE'
  //       obj['pmt_cp_ar_src_cd'] = 0
  //       obj['pmt_cp_ar_num_in_src'] = 0
  //       obj['pmt_cp_ar_instr_cd'] = 0
  //       obj['pmt_cp_num_in_src'] = 0
  //       obj['pmt_cp_ev_grp_id'] = 0
  //       obj['pmt_cp_ev_grp_src_cd'] = 0
  //       obj['pmt_cp_ev_grp_tcd'] = 0
  //       obj['pmt_cp_ev_grp_lcl_unq_num_in_src'] = 0
  //       obj['pmt_cp_nm'] = 0
  //       obj['pmt_rp_bnk_acct_id'] = 0
  //       obj['pmt_cp_bnk_acct_id'] = 0
  //       obj['pmt_cp_txn_id'] = 0

  //       event_group_data.push(Object.assign({}, obj));

  //     }

  //     console.log(event_group_data)
  //     let eventgroupobj = new Object()

  //     let netPayable = Number(event[0]['net_amount'].toFixed(0))
  //     eventgroupobj['is_rec_or_pay'] = 'PAYABLE'
  //     eventgroupobj['local_doc_no'] = event[0]['cb_id'];
  //     eventgroupobj['local_doc_desc'] = '1st bill of'+this.AllworkObj[event[0]['work_id']]['work_desc']
  //     +' for '+this.partyNameObj[this.AllworkObj[event[0]['work_id']]['party_le_id']]

  //     eventgroupobj['module_cd'] = 'ACCOUNT'
  //     eventgroupobj['module_doc_type'] = 'CB'
  //     eventgroupobj['status'] = 'APPROVED';
  //     eventgroupobj['total_amt'] = netPayable
  //     eventgroupobj['doc_type'] = 'CB'
  //     eventgroupobj['data'] = event_group_data
  //     eventgroupobj['create_user_id'] = this.erpUser['user_id']
  //     eventgroupobj['b_acct_id'] = this.erpUser['b_acct_id']
  //     eventgroupobj['req_data'] = JSON.stringify({bill_id :event[0]['cb_id'],node_cd: event[0]['work_id']})
  //     console.log(eventgroupobj)

  //     let resp1 = await this.createEventService.createevent(eventgroupobj)
  //     var obj1:any = new Object();
  //     obj1['b_acct_id'] = this.b_acct_id;
  //     obj1['cb_id'] = cb_id;
  //     obj1['status'] = 'ACCEPTED';
  //     obj1['update_user_id'] = this.erpUser.user_id;
  //     this.spinner.show();
  //     var resp = await this.billService.acceptCb(obj1);
  //     if (resp['error'] == false) {
  //       await this.getAllCBInfo();
  //       this.spinner.hide();
  //       swal.fire("Success", "Accepted Successfully!", 'success');
  //     } else {
  //       this.spinner.hide();
  //       swal.fire("Error", "...Error while accepting bill !", 'error');
  //     }
  //   }
  // }

  // async rejectSelected() {
  //   this.spinner.show();
  //   var processed = [];
  //   var generated = [];
  //   var rejected = [];
  //   var accepted = [];
  //   for (let i = 0; i < this.allCBInfo.length; i++) {
  //     if (
  //       this.allCBInfo[i]["select"] == true &&
  //       this.allCBInfo[i]["status"] == "APPROVED"
  //     ) {
  //       generated.push(this.allCBInfo[i]);
  //     } else if (
  //       this.allCBInfo[i]["select"] == true &&
  //       this.allCBInfo[i]["status"] == "ACCEPTED"
  //     ) {
  //       accepted.push(this.allCBInfo[i]);
  //     } else if (
  //       this.allCBInfo[i]["select"] == true &&
  //       this.allCBInfo[i]["status"] == "REJECTED"
  //     ) {
  //       rejected.push(this.allCBInfo[i]);
  //     } else if (
  //       this.allCBInfo[i]["select"] == true &&
  //       this.allCBInfo[i]["status"] == "PROCESSED"
  //     ) {
  //       processed.push(this.allCBInfo[i]);
  //     }
  //   }

  //   if (accepted.length != 0 || rejected.length != 0 || processed.length != 0) {
  //     swal.fire(
  //       "Error",
  //       "...In the Selected Bill " +
  //         accepted.length +
  //         " Of ACCEPTED Bill And " +
  //         rejected.length +
  //         " Of REJECTED Bill And " +
  //         processed.length +
  //         " Of PROCESSED Bill!!",
  //       "error"
  //     );
  //     this.spinner.hide();
  //     return;
  //   } else {
  //     var cb_id = [];
  //     for (let i = 0; i < generated.length; i++) {
  //       cb_id.push(generated[i]["cb_id"]);
  //     }

  //     var obj1:any = new Object();
  //     obj1["b_acct_id"] = this.b_acct_id;
  //     obj1["cb_id"] = cb_id;
  //     obj1["status"] = "REJECTED";
  //     obj1["update_user_id"] = this.erpUser.user_id;

  //     console.log(obj1);
  //     var resp = await this.billService.changeCbStatus(obj1);
  //     if (resp["error"] == false) {
  //       await this.getAllCBInfo();
  //       this.spinner.hide();
  //       swal.fire("Success", "Rejected Successfully!", "success");
  //     } else {
  //       this.spinner.hide();
  //       swal.fire("Error", "...Error while rejecting bill !", "error");
  //     }
  //   }
  // }

  // async print() {
  //   await this.check();
  //   let printContents, popupWin;
  //   printContents = document.getElementById("p").innerHTML;
  //   popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
  //   popupWin.document.open();
  //   popupWin.document.write(`
  //     <html>
  //       <head>
  //       <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
  //       </head>
  //       <style>
  //       #tbl {
  //         font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  //         border-collapse: collapse;
  //         width: 100%;
  //         max-width: 2480px;
  //     }

  //     #tbl td,
  //     #tbl th {
  //         border: 1px solid #ddd;
  //         padding: 8px;
  //         width: auto;
  //         overflow: hidden;
  //         word-wrap: break-word;
  //     }

  //     #tbl tr:nth-child(even) {
  //         background-color: #f2f2f2;
  //     }

  //     #tbl tr:hover {
  //         background-color: #ddd;
  //     }

  //     #tbl th {
  //         padding-top: 12px;
  //         padding-bottom: 12px;
  //         text-align: left;
  //         background-color: rgb(63, 24, 233);
  //         color: white;
  //     }
  //     tr.mat-header-row {
  //       height: 40px !important;
  //   }

  //   tr.mat-row {
  //       height: 30px !important;
  //       font-size: 12px;
  //   }

  //   .example-container {
  //       max-height: 720px;
  //       overflow: auto;
  //   }

  //   table {
  //       width: 100%;
  //   }

  //   .mat-cell {
  //       padding: 5px 10px 5px 0;
  //   }

  //   .mat-header-cell {
  //       padding: 5px 10px 5px 0;
  //   }

  //   .mat-row:nth-child(even) {
  //       background-color: rgb(238, 224, 226);
  //   }

  //   .mat-row:nth-child(odd) {
  //       background-color: white;
  //   }
  //       </style>
  //   <body onload="window.print();window.close()">${printContents}</body>
  //     </html>`);
  //   popupWin.document.close();
  // }

  // statusArr = [];

  //***************************************************************************** */
  // async schedule(element:any) {
  //   console.log(element)
  //   var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['data'] = [];
  //   for (var i = 0; i < this.allApproval.length; i++) {
  //     if (this.allApproval[i]['doc_type'] == 'BILL') {
  //       obj['data'].push({
  //         user_id: this.allApproval[i]['user_id'],
  //         level_of_approval: this.allApproval[i]['level_of_approval'],
  //         doc_type: this.allApproval[i]['doc_type'],
  //         create_user_id: this.erpUser.user_id,
  //         doc_local_no: element['cb_id'],
  //         doc_local_desc: element['cb_id'] + "-" + element['work_or_service_name'],
  //         status: 'PENDING'
  //       })
  //     }
  //   }

  //   var resp = await this.billService.sendToApproval(obj);
  //   if (resp['error'] == false) {
  //     await this.changeCBStatus(element['cb_id'], 'SCHEDULED');
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide();
  //     swal.fire('Error', 'Error while sending the bill to approvel', 'error');
  //   }
  // }

  // statusArr = [];
  // async status(element:any) {
  //   var obj:any = new Object();
  //   console.log(element);
  //   obj["b_acct_id"] = this.b_acct_id;
  //   obj["bill_id"] = element.cb_id;
  //   console.log(obj)
  //   this.spinner.show();
  //   var resp = await this.billService.getDocumentStatus(obj);
  //   console.log(resp);
  //   if (resp['error'] == false) {
  //     this.statusArr = resp['data'];
  //     $('#myModal_Status').modal('show');

  //     this.spinner.hide()
  //   } else {
  //     this.spinner.hide();
  //     swal.fire("Error", "Error while getting status", 'error');
  //   }
  // }
  // allApproval = [];
  // levelOfApproval = {};
  // async getAllApproveRule() {
  //   this.spinner.show()
  //   var resp = await this.billService.getAllApproval(this.b_acct_id);
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     this.allApproval = resp['data'];
  //     for (var i = 0; i < this.allApproval.length; i++) {
  //       if (this.allApproval[i]['doc_type'] == 'BILL') {
  //         this.levelOfApproval[this.allApproval[i]['level_of_approval']] = this.allApproval[i];
  //       }
  //     }
  //   } else {
  //     this.spinner.hide()
  //   }
  // }

  // allUser = [];
  // UserObj = {};
  // async getUsersInfo() {
  //   var obj = { b_acct_id: this.b_acct_id };
  //   var resp = await this.userAdd.getAllUsersInfo(JSON.stringify(obj));
  //   console.log(resp);
  //   if (resp['error'] == false) {
  //     this.allUser = resp['data'];
  //     for (let i = 0; i < this.allUser.length; i++) {
  //       this.UserObj[this.allUser[i]['user_id']] = this.allUser[i]['first_name'] + " " + this.allUser[i]['last_name']
  //       this.allUser[i]['name'] = this.allUser[i]['first_name'] + " " + this.allUser[i]['last_name']
  //     }
  //   } else {

  //   }
  // }

  //allBudgetHier = [];


  // async processSelected() {
  //   this.spinner.show();
  //   var processed = [];
  //   var generated = [];
  //   var rejected = [];
  //   var accepted = [];
  //   for (let i = 0; i < this.allCBInfo.length; i++) {
  //     if (
  //       this.allCBInfo[i]["select"] == true &&
  //       this.allCBInfo[i]["status"] == "GENERATED"
  //     ) {
  //       generated.push(this.allCBInfo[i]);
  //     } else if (
  //       this.allCBInfo[i]["select"] == true &&
  //       this.allCBInfo[i]["status"] == "ACCEPTED"
  //     ) {
  //       accepted.push(this.allCBInfo[i]);
  //     } else if (
  //       this.allCBInfo[i]["select"] == true &&
  //       this.allCBInfo[i]["status"] == "REJECTED"
  //     ) {
  //       rejected.push(this.allCBInfo[i]);
  //     } else if (
  //       this.allCBInfo[i]["select"] == true &&
  //       this.allCBInfo[i]["status"] == "PROCESSED"
  //     ) {
  //       processed.push(this.allCBInfo[i]);
  //     }
  //   }

  //   if (
  //     processed.length != 0 ||
  //     rejected.length != 0 ||
  //     generated.length != 0
  //   ) {
  //     swal.fire(
  //       "Error",
  //       "...In the Selected Bill " +
  //         processed.length +
  //         " Of PROCESSED Bill And " +
  //         rejected.length +
  //         " Of REJECTED Bill And " +
  //         generated.length +
  //         " Of GENERATED Bill!!",
  //       "error"
  //     );
  //     this.spinner.hide();
  //     return;
  //   } else {
  //     this.eventArr = [];
  //     for (let i = 0; i < accepted.length; i++) {
  //       await this.accept(accepted[i]);
  //     }

  //     var processed_data = await this.ruleProcessService.startProcessing(
  //       this.eventArr,
  //       this.allRule,
  //       this.systemDate,
  //       this.fin_year,
  //       this.orgShortName
  //     );
  //     if (processed_data["event"].length == 0) {
  //       var cb_id = [];
  //       for (let i = 0; i < accepted.length; i++) {
  //         cb_id.push(accepted[i]["cb_id"]);
  //       }

  //       var obj1:any = new Object();
  //       obj1["b_acct_id"] = this.b_acct_id;
  //       obj1["cb_id"] = cb_id;
  //       obj1["status"] = "PROCESSED";
  //       obj1["update_user_id"] = this.erpUser.user_id;
  //       obj1["jrnl"] = processed_data["jrnl"];
  //       var resp = await this.billService.insertProcessedCBData(obj1);
  //       if (resp["error"] == false) {
  //         this.spinner.hide();
  //         await this.getAllCBInfo();
  //         swal.fire("Success", "Processed Successfully!", "success");
  //       } else {
  //         this.spinner.hide();
  //         swal.fire("Error", "...Error while Processed  Bill Insert!", "error");
  //       }
  //     } else {
  //       console.log(processed_data);
  //       this.spinner.hide();
  //       swal.fire("Error", "...Some Events does not have rule  !", "error");
  //     }
  //   }
  // }

  // async accept(element:any) {
  //   this.obj = new Object();
  //   this.obj["party_id"] = element.party_id;
  //   this.obj["cb_id"] = element.cb_id;
  //   this.obj["cb_date"] = element.cb_date;
  //   this.obj["bill_date"] = element.bill_date;
  //   this.obj["bill_no"] = element.bill_no;
  //   this.obj["status"] = element.status;
  //   this.obj["work_order_no"] = element.work_order_no;
  //   this.obj["work_or_service_name"] = element.work_or_service_name;
  //   let ob :any= JSON.parse(element.data);
  //   this.AllBillRow = ob["payable_rows"];
  //   await this.process();
  // }

  // async process() {
  //   for (let i = 0; i < this.AllBillRow.length; i++) {
  //     var obj_temp1 = new Object();
  //     obj_temp1["evt_grp_dt"] = this.obj["cb_date"];
  //     obj_temp1["bus_event_type"] = "BILL";
  //     obj_temp1["invoice_id"] = this.obj["bill_no"];
  //     obj_temp1["party_id"] = this.obj["party_id"];
  //     obj_temp1["event_code"] = this.AllBillRow[i]["event_code"];
  //     obj_temp1["ev_desc"] = this.AllBillRow[i]["event_desc"];
  //     obj_temp1["event_id"] = this.obj["cb_id"];
  //     obj_temp1["event_ln_id"] = i + 1;
  //     obj_temp1["event_desc"] = this.obj["work_or_service_name"];
  //     obj_temp1["txn_amt"] = this.AllBillRow[i]["amount"];
  //     obj_temp1["create_user_id"] = this.erpUser.user_id;
  //     obj_temp1["arr_num"] = this.obj["party_id"];
  //     obj_temp1["party_name"] = this.partyArrObj[this.obj["party_id"]];

  //     this.eventArr.push(obj_temp1);

  //     if (this.AllBillRow[i]["cgst"] != 0) {
  //       var obj_temp2 = new Object();
  //       obj_temp2["evt_grp_dt"] = this.obj["cb_date"];
  //       obj_temp2["bus_event_type"] = "BILL";
  //       obj_temp2["invoice_id"] = this.obj["bill_no"];
  //       obj_temp2["party_id"] = this.obj["party_id"];
  //       obj_temp2["ev_desc"] = "CGSTINPUT";
  //       obj_temp2["event_code"] = "CGSTINPUT";
  //       obj_temp2["event_id"] = this.obj["cb_id"];
  //       obj_temp2["event_ln_id"] = i + 1;
  //       obj_temp2["event_desc"] = this.obj["work_or_service_name"];
  //       obj_temp2["txn_amt"] = this.AllBillRow[i]["cgst"];
  //       obj_temp2["create_user_id"] = this.erpUser.user_id;
  //       obj_temp2["arr_num"] = this.obj["party_id"];
  //       obj_temp2["party_name"] = this.partyArrObj[this.obj["party_id"]];

  //       this.eventArr.push(obj_temp2);
  //     }

  //     if (this.AllBillRow[i]["sgst"] != 0) {
  //       var obj_temp3 = new Object();
  //       obj_temp3["evt_grp_dt"] = this.obj["cb_date"];
  //       obj_temp3["bus_event_type"] = "BILL";
  //       obj_temp3["invoice_id"] = this.obj["bill_no"];
  //       obj_temp3["party_id"] = this.obj["party_id"];
  //       obj_temp3["ev_desc"] = "SGSTINPUT";
  //       obj_temp3["event_code"] = "SGSTINPUT";
  //       obj_temp3["event_id"] = this.obj["cb_id"];
  //       obj_temp3["event_ln_id"] = i + 1;
  //       obj_temp3["event_desc"] = this.obj["work_or_service_name"];
  //       obj_temp3["txn_amt"] = this.AllBillRow[i]["sgst"];
  //       obj_temp3["create_user_id"] = this.erpUser.user_id;
  //       obj_temp3["arr_num"] = this.obj["party_id"];
  //       obj_temp3["party_name"] = this.partyArrObj[this.obj["party_id"]];
  //       this.eventArr.push(obj_temp3);
  //     }
  //     if (this.AllBillRow[i]["igst"] != 0) {
  //       var obj_temp4 = new Object();
  //       obj_temp4["evt_grp_dt"] = this.obj["cb_date"];
  //       obj_temp4["bus_event_type"] = "BILL";
  //       obj_temp4["invoice_id"] = this.obj["bill_no"];
  //       obj_temp4["ev_desc"] = "IGSTINPUT";
  //       obj_temp4["party_id"] = this.obj["party_id"];
  //       obj_temp4["event_code"] = "IGSTINPUT";
  //       obj_temp4["event_id"] = this.obj["cb_id"];
  //       obj_temp4["event_ln_id"] = i + 1;
  //       obj_temp4["event_desc"] = this.obj["work_or_service_name"];
  //       obj_temp4["txn_amt"] = this.AllBillRow[i]["igst"];
  //       obj_temp4["create_user_id"] = this.erpUser.user_id;
  //       obj_temp4["arr_num"] = this.obj["party_id"];
  //       obj_temp4["party_name"] = this.partyArrObj[this.obj["party_id"]];
  //       this.eventArr.push(obj_temp4);
  //     }

  //     for (let j = 0; j < this.AllBillRow[i]["ded"].length; j++) {
  //       var amount = 0;
  //       if (this.AllBillRow[i]["ded"][j]["amt_type"] == "percentage") {
  //         if (this.AllBillRow[i]["ded"][j]["deduction_type"] == "TDS") {
  //           if (this.AllBillRow[i]["cgst"] != 0) {
  //             amount =
  //               (this.AllBillRow[i]["amount"] *
  //                 this.AllBillRow[i]["ded"][j]["ded_amount"]) /
  //               100 /
  //               2;
  //           } else {
  //             amount =
  //               (this.AllBillRow[i]["amount"] *
  //                 this.AllBillRow[i]["ded"][j]["ded_amount"]) /
  //               100;
  //           }
  //         } else {
  //           amount =
  //             (this.AllBillRow[i]["amount"] *
  //               this.AllBillRow[i]["ded"][j]["ded_amount"]) /
  //             100;
  //         }
  //         amount =
  //           (this.AllBillRow[i]["amount"] *
  //             this.AllBillRow[i]["ded"][j]["ded_amount"]) /
  //           100;
  //       } else {
  //         amount = this.AllBillRow[i]["ded"][j]["ded_amount"];
  //       }

  //       if (
  //         amount != 0 &&
  //         this.AllBillRow[i]["ded"][j]["deduction_type"] != "TDS"
  //       ) {
  //         var obj_temp = new Object();
  //         obj_temp["evt_grp_dt"] = this.obj["cb_date"];
  //         obj_temp["bus_event_type"] = "BILL";
  //         obj_temp["invoice_id"] = this.obj["bill_no"];
  //         obj_temp["party_id"] = this.obj["party_id"];
  //         obj_temp["event_code"] =
  //           this.AllBillRow[i]["ded"][j]["deduction_type"];
  //         obj_temp["event_id"] = this.obj["cb_id"];
  //         obj_temp["event_ln_id"] = i + 1;
  //         obj_temp["event_desc"] = this.obj["work_or_service_name"];
  //         obj_temp["txn_amt"] = amount;
  //         obj_temp["create_user_id"] = this.erpUser.user_id;
  //         obj_temp["arr_num"] = this.obj["party_id"];
  //         obj_temp["ev_desc"] = this.AllBillRow[i]["event_desc"];
  //         this.eventArr.push(obj_temp);
  //       } else if (this.AllBillRow[i]["ded"][j]["deduction_type"] == "TDS") {
  //         if (this.AllBillRow[i]["cgst"] != 0) {
  //           var obj_temp8 = new Object();
  //           obj_temp8["evt_grp_dt"] = this.obj["cb_date"];
  //           obj_temp8["bus_event_type"] = "BILL";
  //           obj_temp8["invoice_id"] = this.obj["bill_no"];
  //           obj_temp8["party_id"] = this.obj["party_id"];
  //           obj_temp8["ev_desc"] = this.AllBillRow[i]["event_desc"];
  //           obj_temp8["event_code"] = "TDSC";
  //           obj_temp8["event_id"] = this.obj["cb_id"];
  //           obj_temp8["event_ln_id"] = i + 1;
  //           obj_temp8["event_desc"] = this.obj["work_or_service_name"];
  //           obj_temp8["txn_amt"] = amount;
  //           obj_temp8["create_user_id"] = this.erpUser.user_id;
  //           obj_temp8["arr_num"] = this.obj["party_id"];
  //           obj_temp8["party_name"] = this.partyArrObj[this.obj["party_id"]];

  //           this.eventArr.push(obj_temp8);
  //         }

  //         if (this.AllBillRow[i]["sgst"] != 0) {
  //           var obj_temp6 = new Object();
  //           obj_temp6["evt_grp_dt"] = this.obj["cb_date"];
  //           obj_temp6["bus_event_type"] = "BILL";
  //           obj_temp6["invoice_id"] = this.obj["bill_no"];
  //           obj_temp6["party_id"] = this.obj["party_id"];
  //           obj_temp6["ev_desc"] = this.AllBillRow[i]["event_desc"];
  //           obj_temp6["event_code"] = "TDSS";
  //           obj_temp6["event_id"] = this.obj["cb_id"];
  //           obj_temp6["event_ln_id"] = i + 1;
  //           obj_temp6["event_desc"] = this.obj["work_or_service_name"];
  //           obj_temp6["txn_amt"] = amount;
  //           obj_temp6["create_user_id"] = this.erpUser.user_id;
  //           obj_temp6["arr_num"] = this.obj["party_id"];
  //           obj_temp6["party_name"] = this.partyArrObj[this.obj["party_id"]];
  //           this.eventArr.push(obj_temp6);
  //         }
  //         if (this.AllBillRow[i]["igst"] != 0) {
  //           var obj_temp9 = new Object();
  //           obj_temp9["evt_grp_dt"] = this.obj["cb_date"];
  //           obj_temp9["bus_event_type"] = "BILL";
  //           obj_temp9["invoice_id"] = this.obj["bill_no"];
  //           obj_temp9["ev_desc"] = this.AllBillRow[i]["event_desc"];
  //           obj_temp9["party_id"] = this.obj["party_id"];
  //           obj_temp9["event_code"] = "TDSI";
  //           obj_temp9["event_id"] = this.obj["cb_id"];
  //           obj_temp9["event_ln_id"] = i + 1;
  //           obj_temp9["event_desc"] = this.obj["work_or_service_name"];
  //           obj_temp9["txn_amt"] = amount;
  //           obj_temp9["create_user_id"] = this.erpUser.user_id;
  //           obj_temp9["arr_num"] = this.obj["party_id"];
  //           obj_temp9["party_name"] = this.partyArrObj[this.obj["party_id"]];
  //           this.eventArr.push(obj_temp9);
  //         }
  //       }
  //     }
  //   }
  // }




  // getLevel1() {
  //   let temp = []
  //   this.level1 = []
  //   for (let i = 0; i < this.Hier.length; i++) {
  //     if (!temp.includes(this.Hier[i]['lvl1_cd'])) {
  //       temp.push(this.Hier[i]['lvl1_cd'])
  //       let ob :any= new Object();
  //       ob['lvl1_cd'] = this.Hier[i]['lvl1_cd']
  //       ob['lvl1_value'] = this.Hier[i]['lvl1_value']
  //       this.level1.push(ob)
  //     }
  //   }

  //   this.level2 = []
  //   this.level3 = []
  //   this.level4 = []
  //   this.level5 = []
  //   this.level6 = []
  //   this.level7 = []
  // }

  // async getIp() {
  //   this.spinner.show();

  //   var resp = await this.billService.getPartyInfo(this.b_acct_id);
  //   if (resp['error'] == false) {
  //     this.partyArr = resp.data
  //     this.partyArrObj = new Object
  //     for (let i = 0; i < this.partyArr.length; i++) {
  //       this.partyArrObj[this.partyArr[i]['party_id']] = this.partyArr[i]['party_name']
  //     }

  //     this.spinner.hide();

  //   } else {
  //     this.spinner.hide();
  //     this.snackBar.open("Error occured while getting Schemes", 'Error', {
  //       duration: 5000,
  //     });
  //   }
  // }

  // async getIp() {
  //   this.spinner.show();

  //   // var resp = await this.billService.getPartyInfo(this.b_acct_id);
  //   var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   var resp = await this.billService.getPartyInfo(JSON.stringify(obj));
  //   console.log(resp);
  //   if (resp['error'] == false) {
  //     this.partyArr = resp.data
  //     this.partyArrObj = new Object
  //     for (let i = 0; i < this.partyArr.length; i++) {
  //       this.partyArrObj[this.partyArr[i]['le_id']] = this.partyArr[i]['party_legal_name']
  //     }
  //     console.log(this.partyArrObj);

  //     this.spinner.hide();

  //   } else {
  //     this.spinner.hide();
  //     this.snackBar.open("Error occured while getting Schemes", 'Error', {
  //       duration: 5000,
  //     });
  //   }
  // }

  ////////////////////////////////////////////////////////////////////////////////////////////

  // async FilteredEvents() {
  //   this.selectObj["b_acct_id"] = this.b_acct_id;
  //   console.log(this.selectObj);
  //   var resp = await this.EventsService.getFilteredEvents(this.selectObj);
  //   if (resp["error"] == false) {
  //     this.dataSource1 = new MatTableDataSource(resp.data);
  //     this.dataSource1.sort = this.sortCol2;
  //     this.dataSource1.paginator = this.paginator1;
  //   } else {
  //   }
  // }

  // selectedEventData;

  // select(element:any) {
  //   this.selectedEventData = Object.assign({}, element);
  //   console.log(element);
  //   this.AllBillRow[this.index]["event_code"] = element["event_code"];
  //   this.AllBillRow[this.index]["event_desc"] = element["event_desc"];
  //   this.AllBillRow[this.index]["event_Obj"] = element;
  //   $("#myModal").modal("hide");
  // }

  // changeProject() {
  //   console.log(this.allProject);
  //   for (let i = 0; i < this.allProject.length; i++) {
  //     if (this.selectObj["proj_cd"] == this.allProject[i]["code"]) {
  //       this.selectObj["proj_desc"] = this.allProject[i]["desc"];
  //       this.selectObj["proj_lvl"] = this.allProject[i]["level"];
  //     }
  //   }
  // }

  // changeProduct() {
  //   for (let i = 0; i < this.allProduct.length; i++) {
  //     if (this.selectObj["prod_cd"] == this.allProduct[i]["code"]) {
  //       this.selectObj["prod_desc"] = this.allProduct[i]["desc"];
  //       this.selectObj["prod_lvl"] = this.allProduct[i]["level"];
  //     }
  //   }
  // }

  // changeActivity() {
  //   for (let i = 0; i < this.allActivity.length; i++) {
  //     if (this.selectObj["act_cd"] == this.allActivity[i]["code"]) {
  //       this.selectObj["act_desc"] = this.allActivity[i]["desc"];
  //       this.selectObj["act_lvl"] = this.allActivity[i]["level"];
  //     }
  //   }
  // }

  // changeBudget() {
  //   for (let i = 0; i < this.allBudget.length; i++) {
  //     if (this.selectObj["bud_cd"] == this.allBudget[i]["code"]) {
  //       this.selectObj["bud_desc"] = this.allBudget[i]["desc"];
  //       this.selectObj["bud_lvl"] = this.allBudget[i]["level"];
  //     }
  //   }
  // }
  // allBudget = [];
  // allProject = [];
  // allProduct = [];
  // allActivity = [];
  // setData() {
  //   this.allBudget = [];
  //   var temp = [];
  //   for (let i = 0; i < this.allBudgetHier.length; i++) {
  //     for (let j = 1; j <= 7; j++) {
  //       var obj:any = new Object();
  //       obj["code"] = this.allBudgetHier[i]["lvl" + j + "_cd"];
  //       obj["value"] = this.allBudgetHier[i]["lvl" + j + "_value"];
  //       obj["level"] = j;
  //       obj["desc"] =
  //         this.allBudgetHier[i]["lvl" + j + "_cd"] +
  //         " - " +
  //         this.allBudgetHier[i]["lvl" + j + "_value"] +
  //         " - " +
  //         "Level " +
  //         j;
  //       if (
  //         temp.indexOf(this.allBudgetHier[i]["lvl" + j + "_cd"]) < 0 &&
  //         this.allBudgetHier[i]["lvl" + j + "_cd"] != null
  //       ) {
  //         this.allBudget.push(obj);
  //         temp.push(this.allBudgetHier[i]["lvl" + j + "_cd"]);
  //       }
  //     }

  //     var obj:any = new Object();
  //     obj["code"] = this.allBudgetHier[i]["leaf_cd"];
  //     obj["value"] = this.allBudgetHier[i]["leaf_value"];
  //     obj["level"] = "L";
  //     obj["desc"] =
  //       this.allBudgetHier[i]["leaf_cd"] +
  //       " - " +
  //       this.allBudgetHier[i]["leaf_value"] +
  //       " - Leaf";
  //     var p = temp.indexOf(this.allBudgetHier[i]["leaf_cd"]);
  //     this.allBudget.splice(p, 1);
  //     this.allBudget.push(obj);
  //   }

  //   temp = [];
  //   this.allProduct = [];
  //   for (let i = 0; i < this.allProductHier.length; i++) {
  //     for (let j = 1; j <= 7; j++) {
  //       var obj:any = new Object();
  //       obj["code"] = this.allProductHier[i]["lvl" + j + "_cd"];
  //       obj["value"] = this.allProductHier[i]["lvl" + j + "_value"];
  //       obj["level"] = j;
  //       obj["desc"] =
  //         this.allProductHier[i]["lvl" + j + "_cd"] +
  //         " - " +
  //         this.allProductHier[i]["lvl" + j + "_value"] +
  //         " - " +
  //         "Level " +
  //         j;
  //       if (
  //         temp.indexOf(this.allProductHier[i]["lvl" + j + "_cd"]) < 0 &&
  //         this.allProductHier[i]["lvl" + j + "_cd"] != null
  //       ) {
  //         this.allProduct.push(obj);
  //         temp.push(this.allProductHier[i]["lvl" + j + "_cd"]);
  //       }
  //     }
  //     var obj:any = new Object();
  //     obj["code"] = this.allProductHier[i]["leaf_cd"];
  //     obj["value"] = this.allProductHier[i]["leaf_value"];
  //     obj["level"] = "L";
  //     obj["desc"] =
  //       this.allProductHier[i]["leaf_cd"] +
  //       " - " +
  //       this.allProductHier[i]["leaf_value"] +
  //       " - Leaf";
  //     var p = temp.indexOf(this.allProductHier[i]["leaf_cd"]);
  //     this.allProduct.splice(p, 1);
  //     this.allProduct.push(obj);
  //   }

  //   temp = [];
  //   this.allProject = [];
  //   for (let i = 0; i < this.allProjectHier.length; i++) {
  //     for (let j = 1; j <= 7; j++) {
  //       var obj:any = new Object();
  //       obj["code"] = this.allProjectHier[i]["lvl" + j + "_cd"];
  //       obj["value"] = this.allProjectHier[i]["lvl" + j + "_value"];
  //       obj["level"] = j;
  //       obj["desc"] =
  //         this.allProjectHier[i]["lvl" + j + "_cd"] +
  //         " - " +
  //         this.allProjectHier[i]["lvl" + j + "_value"] +
  //         " - " +
  //         "Level " +
  //         j;
  //       if (
  //         temp.indexOf(this.allProjectHier[i]["lvl" + j + "_cd"]) < 0 &&
  //         this.allProjectHier[i]["lvl" + j + "_cd"] != null
  //       ) {
  //         this.allProject.push(obj);
  //         temp.push(this.allProjectHier[i]["lvl" + j + "_cd"]);
  //       }
  //     }
  //     var obj:any = new Object();
  //     obj["code"] = this.allProjectHier[i]["leaf_cd"];
  //     obj["value"] = this.allProjectHier[i]["leaf_value"];
  //     obj["level"] = "L";
  //     obj["desc"] =
  //       this.allProjectHier[i]["leaf_cd"] +
  //       " - " +
  //       this.allProjectHier[i]["leaf_value"] +
  //       " - Leaf";
  //     var p = temp.indexOf(this.allProjectHier[i]["leaf_cd"]);
  //     this.allProject.splice(p, 1);
  //     this.allProject.push(obj);
  //   }

  //   temp = [];
  //   this.allActivity = [];
  //   for (let i = 0; i < this.allActivityHier.length; i++) {
  //     for (let j = 1; j <= 7; j++) {
  //       var obj:any = new Object();
  //       obj["code"] = this.allActivityHier[i]["lvl" + j + "_cd"];
  //       obj["value"] = this.allActivityHier[i]["lvl" + j + "_value"];
  //       obj["level"] = j;
  //       obj["desc"] =
  //         this.allActivityHier[i]["lvl" + j + "_cd"] +
  //         " - " +
  //         this.allActivityHier[i]["lvl" + j + "_value"] +
  //         " - " +
  //         "Level " +
  //         j;
  //       if (
  //         temp.indexOf(this.allActivityHier[i]["lvl" + j + "_cd"]) < 0 &&
  //         this.allActivityHier[i]["lvl" + j + "_cd"] != null
  //       ) {
  //         this.allActivity.push(obj);
  //         temp.push(this.allActivityHier[i]["lvl" + j + "_cd"]);
  //       }
  //     }
  //     var obj:any = new Object();
  //     obj["code"] = this.allActivityHier[i]["leaf_cd"];
  //     obj["value"] = this.allActivityHier[i]["leaf_value"];
  //     obj["level"] = "L";
  //     obj["desc"] =
  //       this.allActivityHier[i]["leaf_cd"] +
  //       " - " +
  //       this.allActivityHier[i]["leaf_value"] +
  //       " - Leaf";
  //     var p = temp.indexOf(this.allActivityHier[i]["leaf_cd"]);
  //     this.allActivity.splice(p, 1);
  //     this.allActivity.push(obj);
  //   }
  // }
  // index;
  // open_event_popup(i) {
  //   this.index = i;
  //   $("#myModal").modal("show");
  // }
  // async getAllRuleList() {
  //   var resp = await this.ruleService.getAllRules(this.b_acct_id);
  //   if (resp["error"] == false) {
  //     this.allRule = resp.data;
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide();
  //     this.snackBar.open("Error while getting  all rule list", "Error", {
  //       duration: 5000,
  //     });
  //   }
  // }
  // async allAllocation() {
  //   var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   var resp1 = await this.budgetService.getAllAllocation(JSON.stringify(obj));
  //   if (resp1['error'] == false) {
  //     let arr = resp1.data;
  //     this.allAllocationArr = []
  //     this.allocationAmountObj = new Object
  //     for (let i = 0; i < arr.length; i++) {
  //       if (arr[i]['status'] == 'ACTIVE') {
  //         this.allAllocationArr.push(arr[i])
  //         this.allocationAmountObj[arr[i]['allocation_id']] = arr[i]['amount']

  //       }
  //     }
  //   } else {
  //   }
  // }

  // async allAllocation() {
  //   var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   var resp1 = await this.budgetService.getAllAllocation(JSON.stringify(obj));
  //   if (resp1['error'] == false) {
  //     let arr = resp1.data;
  //     this.allAllocationArr = []
  //     this.allocationAmountObj = new Object
  //     for (let i = 0; i < arr.length; i++) {
  //       if (arr[i]['status'] == 'ACTIVE') {
  //         this.allAllocationArr.push(arr[i])
  //         this.allocationAmountObj[arr[i]['allocation_id']] = arr[i]['amount']

  //       }
  //     }
  //   } else {
  //   }
  // }

  // async allAllocation() {
  //   console.log(this.selectedEventData);

  //   var obj:any = new Object();
  //   obj['create_user_id'] = this.erpUser.user_id;
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['act_cd'] = this.selectedEventData['act_cd']
  //   obj['bud_cd'] = this.selectedEventData['bud_cd']
  //   obj['prod_cd'] = this.selectedEventData['prod_cd']
  //   obj['proj_cd'] = this.selectedEventData['proj_cd']
  //   obj['table_name'] = 'bud_hier';
  //   console.log(obj);
  //   // var resp1 = await this.budgetService.getAllAllocation(JSON.stringify(obj));
  //   var resp1 = await this.hierarchyService.getBudgetHierarchyWithAllocation(obj);
  //   console.log(resp1);
  //   if (resp1['error'] == false) {
  //     let arr = resp1.data;
  //     this.allAllocationArr = []
  //     this.allocationAmountObj = new Object
  //     for (let i = 0; i < arr.length; i++) {
  //       console.log(arr[i]);
  //       if (arr[i]['status'] == 'ACTIVE') {
  //         console.log(arr[i]);
  //         this.allAllocationArr.push(arr[i])
  //         this.allocationAmountObj[arr[i]['allocation_id']] = arr[i]['amount']

  //       }
  //     }
  //     console.log(arr);
  //     console.log(this.allAllocationArr);
  //     console.log(this.allocationAmountObj);
  //   } else {
  //   }
  // }
  // async checkBudgetAmount() {
  //   console.log(this.AllBillRow);

  //   for (let i = 0; i < this.AllBillRow.length; i++) {
  //     let eventObj = this.AllBillRow[i]["event_Obj"];

  //     var budObj = new Object();
  //     var actObj = new Object();
  //     var prodObj = new Object();
  //     var projObj = new Object();

  //     var budArr = [];
  //     var actArr = [];
  //     var prodArr = [];
  //     var projArr = [];

  //     console.log(this.allBudgetHier);
  //     console.log(eventObj);

  //     for (let j = 0; j < this.allBudgetHier.length; j++) {
  //       if (eventObj["bud_cd"] == this.allBudgetHier[j]["leaf_cd"]) {
  //         budObj = this.allBudgetHier[j];
  //         for (let k = 1; k < 8; k++) {
  //           if (budObj["lvl" + k + "_cd"] != null) {
  //             budArr.push(budObj["lvl" + k + "_cd"].toString());
  //           }
  //         }
  //       }
  //     }
  //     for (let j = 0; j < this.allActivityHier.length; j++) {
  //       if (eventObj["act_cd"] == this.allActivityHier[j]["leaf_cd"]) {
  //         actObj = this.allActivityHier[j];
  //         for (let k = 1; k < 8; k++) {
  //           if (actObj["lvl" + k + "_cd"] != null) {
  //             actArr.push(actObj["lvl" + k + "_cd"].toString());
  //           }
  //         }
  //       }
  //     }
  //     for (let j = 0; j < this.allProductHier.length; j++) {
  //       if (eventObj["prod_cd"] == this.allProductHier[j]["leaf_cd"]) {
  //         prodObj = this.allProductHier[j];
  //         for (let k = 1; k < 8; k++) {
  //           if (prodObj["lvl" + k + "_cd"] != null) {
  //             prodArr.push(prodObj["lvl" + k + "_cd"].toString());
  //           }
  //         }
  //       }
  //     }

  //     for (let j = 0; j < this.allProjectHier.length; j++) {
  //       if (eventObj["proj_cd"] == this.allProjectHier[j]["leaf_cd"]) {
  //         projObj = this.allProjectHier[j];
  //         for (let k = 1; k < 8; k++) {
  //           if (projObj["lvl" + k + "_cd"] != null) {
  //             projArr.push(projObj["lvl" + k + "_cd"].toString());
  //           }
  //         }
  //       }
  //     }

  //     let tempAllocation = [];

  //     console.log(this.allAllocationArr);
  //     console.log(budArr);
  //     console.log(actArr);
  //     console.log(projArr);
  //     console.log(prodArr);

  //     for (let j = 0; j < this.allAllocationArr.length; j++) {
  //       console.log(
  //         budArr.includes(this.allAllocationArr[j]["bud_cd"].toString())
  //       );
  //       console.log(
  //         actArr.includes(this.allAllocationArr[j]["act_cd"].toString())
  //       );
  //       console.log(
  //         projArr.includes(this.allAllocationArr[j]["proj_cd"].toString())
  //       );
  //       console.log(
  //         prodArr.includes(this.allAllocationArr[j]["prod_cd"].toString())
  //       );
  //       if (
  //         budArr.includes(this.allAllocationArr[j]["bud_cd"].toString()) &&
  //         actArr.includes(this.allAllocationArr[j]["act_cd"].toString()) &&
  //         projArr.includes(this.allAllocationArr[j]["proj_cd"].toString()) &&
  //         prodArr.includes(this.allAllocationArr[j]["prod_cd"].toString())
  //       ) {
  //         console.log("Inside");
  //         tempAllocation.push(this.allAllocationArr[j]);
  //       }
  //     }

  //     console.log(tempAllocation);

  //     this.AllBillRow[i]["allocation_id"] = 0;
  //     this.AllBillRow[i]["allocation_amount"] = 0;

  //     if (tempAllocation.length == 1) {
  //       this.AllBillRow[i]["allocation_id"] =
  //         tempAllocation[0]["allocation_id"];
  //       this.AllBillRow[i]["allocation_amount"] = tempAllocation[0]["amount"];
  //     } else if (tempAllocation.length > 1) {
  //       for (let j = 0; j < tempAllocation.length; j++) {
  //         if (
  //           this.AllBillRow[i]["allocation_id"] <
  //           tempAllocation[j]["allocation_id"]
  //         ) {
  //           this.AllBillRow[i]["allocation_id"] =
  //             tempAllocation[j]["allocation_id"];
  //           this.AllBillRow[i]["allocation_amount"] =
  //             tempAllocation[j]["amount"];
  //         }
  //       }
  //     }

  //     if (this.AllBillRow[i]["allocation_amount"] == 0) {
  //       swal.fire(
  //         "Error!",
  //         "No Allocation found for this Event (" +
  //           this.AllBillRow[i]["event_desc"] +
  //           ").",
  //         "error"
  //       );
  //       return false;
  //     }

  //     this.allocationAmountObj[this.AllBillRow[i]["allocation_id"]] =
  //       this.allocationAmountObj[this.AllBillRow[i]["allocation_id"]] -
  //       this.AllBillRow[i]["amount"];

  //     if (this.allocationAmountObj[this.AllBillRow[i]["allocation_id"]] < 0) {
  //       let tempAmount =
  //         this.allocationAmountObj[this.AllBillRow[i]["allocation_id"]] * -1;
  //       swal.fire(
  //         "Error!",
  //         "Allocation amount ( Need " +
  //           tempAmount.toFixed(2) +
  //           " more in allocation id " +
  //           this.AllBillRow[i]["allocation_id"] +
  //           " ) is less for this Event (" +
  //           this.AllBillRow[i]["event_desc"] +
  //           ") amount.",
  //         "error"
  //       );
  //       return false;

  //       break;
  //     }
  //     if (
  //       this.AllBillRow[i]["amount"] > this.AllBillRow[i]["allocation_amount"]
  //     ) {
  //       let tempAmount =
  //         this.AllBillRow[i]["amount"] -
  //         this.AllBillRow[i]["allocation_amount"];
  //       swal.fire(
  //         "Error!",
  //         "Allocation amount ( Need " +
  //           tempAmount.toFixed(2) +
  //           " more in allocation id " +
  //           this.AllBillRow[i]["allocation_id"] +
  //           " ) is less for this Event (" +
  //           this.AllBillRow[i]["event_desc"] +
  //           ") amount.",
  //         "error"
  //       );
  //       return false;

  //       break;
  //     }
  //   }
  //   return true;
  // }













  // async Select(type) {
  //   $("#select").modal("show");
  //   this.hier_type = type;
  //   let ob :any= {};

  //   if (type == "budget") {
  //     this.Hier = this.allBudgetHier;
  //     if (this.selectObj["bud_cd"] != null && this.selectObj["bud_cd"] != "") {
  //       ob = this.hierobj["budget"][this.selectObj["bud_cd"]];
  //     }
  //   } else if (type == "activity") {
  //     this.Hier = this.allActivityHier;
  //     if (this.selectObj["act_cd"] != null && this.selectObj["act_cd"] != "") {
  //       ob = this.hierobj["activity"][this.selectObj["act_cd"]];
  //     }
  //   }

  //   this.HierSelectObj = {};
  //   console.log(this.HierSelectObj);
  //   await this.getLevel1();
  //   if (Object.keys(ob).length != 0) {
  //     this.HierSelectObj = Object.assign({}, ob);
  //     await this.onChangeLvl1();
  //     this.HierSelectObj = Object.assign({}, ob);

  //     await this.onChangeLvl2();
  //     this.HierSelectObj = Object.assign({}, ob);

  //     await this.onChangeLvl3();
  //     this.HierSelectObj = Object.assign({}, ob);

  //     await this.onChangeLvl4();
  //     this.HierSelectObj = Object.assign({}, ob);

  //     await this.onChangeLvl5();
  //     this.HierSelectObj = Object.assign({}, ob);

  //     await this.onChangeLvl6();
  //     this.HierSelectObj = Object.assign({}, ob);

  //     await this.onChangeLvl7();
  //   }
  // }
}
