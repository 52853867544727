import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { JoiningService } from '../../service/joining.service';
import { from, empty } from 'rxjs';
import swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { MainService as MD_mainService } from '../../../md/service/main.service';
import { RoleService as MD_RoleService } from '../../../md/service/role.service';
import { UserService as MD_UserService } from '../../../md/service/user.service';
import { MainService as portal_mainService } from '../../../portal/service/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-joining',
  templateUrl: './joining.component.html',
  styleUrls: ['./joining.component.css']
})
export class JoiningComponent implements OnInit {

  constructor(private MD_UserService: MD_UserService, private MD_RoleService: MD_RoleService, public mainService: MainService, private portal_mainS: portal_mainService, private MD_mainService: MD_mainService, private joiningservice: JoiningService, private snackBar: MatSnackBar, private router: Router, private spinner: NgxSpinnerService) { }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  joiningObj :any= {};
  probation :any= false;
  datasource:any;
  b_acct_id:any;
  erpUser:any;
  show_org_msg:any;
  user_id:any
  emp_id:any = ''


  AllCCRow:any = []

  obj = [{ code: 'CENTRALISED', 'value': 'Centralised Services' }, { code: 'NONCENTRALISED', 'value': 'Non Centralised Services' }];
  ts = [{ code: "MORNING", value: "MORNING" }, { code: "AFTERNOON", value: "AFTERNOON" }];
  ////////////////////////princy//////////////////////////////////
  CC = [{ module_code: "ACCOUNT", cost_center_desc: 'Zone 1', cost_center_code: 'Z1' },
  { module_code: "HR", cost_center_desc: 'Zone 2', cost_center_code: 'Z2' },
  { module_code: "AA", cost_center_desc: 'Zone 3', cost_center_code: 'Z3' },
  { module_code: "EMB", cost_center_desc: 'Zone 4', cost_center_code: 'Z4' },
  { module_code: "PROPERTY", cost_center_desc: 'Zone 5', cost_center_code: 'Z5' }]

  cost_center :any= [];
  ///////////////////////////////////////////////////

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.getEmployeeid();
    await this.getAllSystemRole();
    await this.getAllAccountModule();
    await this.ccData();

    this.disable_flag = true
  }
  ccCodeToDesc :any={};
  async  ccData() {
    for (let i = 0; i < this.CC.length; i++) {
      this.ccCodeToDesc[this.CC[i]['cost_center_code']] = this.CC[i]['cost_center_desc']
    }
    console.log(this.ccCodeToDesc)
  }



  allRole :any= []
  allModuleRole :any= [];
  moduleToRole :any= {};

  async getAllSystemRole() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_RoleService.getCurrentMdrole(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['is_system_role'] == 1) {
          this.allModuleRole.push(resp.data[i])
          this.moduleToRole[resp.data[i]['module_cd']] = resp.data[i]['role_cd']
        }
      }
    } else {

    }

    console.log(this.allModuleRole)
    console.log(this.moduleToRole)
  }


  allModule = [];
  async getAllAccountModule() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_mainService.getAccountModules(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allModule = resp.data
      for (let i = 0; i < this.allModule.length; i++) {
        if (this.allModule[i]['module_cd'] == 'ENG') {
          this.allModule.splice(i, 1)
        }
      }
    } else {

    }
  }


  ChangeModule(module_cd:any, i:any) {

    this.joiningObj['role_cd'] = null;



    this.allRole = []

    //////////////////////////////////////////////////princy/////////////////
    console.log(module_cd);
    console.log(this.AllCCRow[i]['cost_center'])

    this.AllCCRow[i]['cost_center'] = null

    this.cost_center = []
    for (let i = 0; i < this.CC.length; i++) {
      if (module_cd == this.CC[i]['module_code']) {
        this.cost_center.push(this.CC[i])
      }
    }

    ///////////////////////////////////////////////////


    // if (module_cd != null) {
    //   for (let i = 0; i < this.allModuleRole.length; i++) {
    //     if (this.allModuleRole[i]['module_cd'] == module_cd) {
    //       this.allRole.push(this.allModuleRole[i]);
    //       this.joiningObj['role_cd'] = this.allModuleRole[i]['role_cd']
    //     }
    //   }
    // }
  }





  async assignNewUserRole(role_cd:any, user_id:any) {
    console.log(role_cd, user_id)
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['role_cd'] = role_cd;
    obj['user_id'] = user_id;
    obj['valid_upto'] = '2030-04-30';
    console.log(obj)
    this.spinner.show();
    var resp = await this.MD_UserService.createMdUser(obj)
    console.log(resp)
    if (resp['error'] == false) {
      setTimeout(() => {
        this.spinner.hide()
      }, 500);
    } else {
      this.spinner.hide()
      if (resp['data'] == "Duplicate entry") {
      } else {

      }
    }
  }
  isLinear:any
  data:any = []
  first:any
  second:any
  third:any
  disable_flag: boolean = false;
  party_exit: boolean = false;
  async search() {
    this.disable_flag = false
    this.joiningObj['emp_name'] = null;
    this.joiningObj['emp_email'] = null;
    this.joiningObj['emp_phone_no'] = null;
    this.joiningObj['emp_dob'] = null;
    this.joiningObj['le_id'] = null;
    this.joiningObj['b_acct_id'] = this.b_acct_id
    console.log(isNaN(this.joiningObj['credential']))

    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    if (isNaN(this.joiningObj['credential'])) {
      obj['contact_email'] = this.joiningObj['credential']
      this.joiningObj['contact_email'] = this.joiningObj['credential']
    } else {
      obj['phone_no'] = this.joiningObj['credential']
      this.joiningObj['phone_no'] = this.joiningObj['credential']
    }

    console.log(this.joiningObj)
    if (!this.joiningObj['credential']) {
      swal.fire('Error', 'Please Enter Valid Credential', 'error')
    } else {
      this.spinner.show()
      var resp = await this.MD_mainService.getCurrentLegalEntity(JSON.stringify(obj));
      console.log(resp)
      if (resp['error'] == false) {
        this.spinner.hide();
        this.data = resp['data']
        if (resp['data'].length > 0) {

          this.disable_flag = true;
          this.party_exit = true;
          this.joiningObj['emp_name'] = resp['data'][0]['party_name']
          this.joiningObj['emp_email'] = resp['data'][0]['contact_email']
          this.joiningObj['emp_phone_no'] = resp['data'][0]['phone_no']
          this.joiningObj['le_id'] = resp['data'][0]['le_id'];
          this.joiningObj['emp_dob'] = resp['data'][0]['party_dob'].split('T')[0];
          this.joiningObj['new_entity'] = false;

        } else {
          this.party_exit = false;
          this.joiningObj['new_entity'] = true;
          this.joiningObj['party_type'] = "IND";
          this.spinner.hide()
          Swal.fire('Warning...', 'No Record Found, Please Enter All Details.', 'warning')
        }
      } else {
        this.spinner.hide()
        swal.fire('Error', 'Some Error Occured', 'error')
      }
    }
    console.log(resp);
  }

  create_flag: boolean = true
  async create_legal_entity() {
    var obj = Object.assign({}, this.joiningObj);
    obj['login_user_id'] = obj['contact_phone'] + "-" + obj['contact_email'];
    obj['user_id'] = this.erpUser['le_id'];
    obj['contact_email'] = this.joiningObj['emp_email']
    obj['contact_phone'] = this.joiningObj['emp_phone_no']
    obj['nick_name'] = this.joiningObj['nick_name']
    obj['party_name'] = this.joiningObj['emp_name']
    obj['party_type'] = 'IND'
    obj['valid_upto'] = '2030-10-22'
    obj['ident_verify_secret'] = this.joiningObj['ident_verify_secret']
    console.log(obj)
    this.spinner.show()
    var resp = await this.mainService.createLegalEntity(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.joiningObj['le_id'] = resp.data;
    } else {
      this.create_flag = false
      this.spinner.hide()
      if (resp['data'] == "Duplicate entry") {
        Swal.fire('Error...', 'Either Mobile Or Email Is Already Exists.', 'error')
      } else {
        Swal.fire('Error', 'Error While Creating', 'error')
      }
    }

  }


  validate(email:any) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return (false);
    } else {
      return true
    }
  }
  errorMsg = ""
  underAgeValidate(j:any , d :any) {
    var dob = d.split('-');
    var year = parseInt(dob[0]);
    var month = parseInt(dob[1]) - 1;
    var day = parseInt(dob[2]);
    var today = new Date(j);
    var age = today.getFullYear() - year;
    if (today.getMonth() < month || (today.getMonth() == month && today.getDate() < day)) {
      age--;
    }
    return age;
  }
  current_LE = []
  ind_current_LE = []
  org_current_LE = []
  // async getCurrentLegalEntity() {
  //   this.current_LE = []
  //   this.ind_current_LE = []
  //   this.org_current_LE = []
  //   let obj = {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['phone_no'] = this.joiningObj['emp_phone_no'];
  //   console.log(obj)
  //   var resp = await this.mainService.getCurrentLegalEntity(JSON.stringify(obj));
  //   console.log(resp);
  //   if (resp['error'] == false) {
  //     console.log(resp);
  //     this.joiningObj['le_id'] = Object.assign({}, resp['data'][0]['le_id'])
  //   } else {
  //     Swal.fire("Error", "..Error while getting current Legal Entity", 'error');
  //   }
  // }
  async add_legal_entity() {
    this.joiningObj['b_acct_id'] = this.b_acct_id;
    console.log(this.joiningObj, 'add legal entity')


    var resp = await this.mainService.add_legal_entity(this.joiningObj);
    console.log(resp);
    if (resp['error'] == false) {
      console.log("New Employee Created WIth le_id---------->" + resp.data);
      return resp['data'];
    } else {
      Swal.fire("Error", "..Error while getting current Legal Entity", 'error');
      return 0;

    }
  }

  async addJoining() {

    console.log(this.joiningObj)
    this.errorMsg = ''
    if (this.joiningObj['emp_phone_no'] == null || this.joiningObj['emp_phone_no'] == undefined ||
      this.joiningObj['joining_order_id'] == "" || this.joiningObj['joining_order_id'] == undefined || this.joiningObj['joining_date'] == undefined
      || this.joiningObj['joining_service_date'] == undefined || (this.disable_flag == false && !this.joiningObj['ident_verify_secret']) || (this.disable_flag == false && !this.joiningObj['nick_name'])
      || this.joiningObj['emp_father_name'] == undefined || this.joiningObj['emp_father_name'] == "" || this.joiningObj['emp_dob'] == undefined
      || this.joiningObj['emp_name'] == undefined || this.joiningObj['emp_name'] == ""
      || this.joiningObj['emp_email'] == undefined || this.joiningObj['emp_email'] == ""
      || this.joiningObj['joining_time'] == undefined || this.joiningObj['joining_time'] == "") {
      // this.errorMsg = "* fields required.";
      swal.fire("Sorry", "... * fields required.....!", 'warning');

    }
    else if (this.joiningObj['emp_phone_no'].toString().length != 10) {
      swal.fire("Sorry", "...Incorrect Phone Number....!", 'warning');
    }

    else {
      if (this.joiningObj['joining_service_date'] < this.joiningObj['joining_date']) {
        this.spinner.hide();
        swal.fire("Sorry", "...the joining service date should be greater or same as the joining date ...!", 'warning');
        return;
      }
      swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.value) {
          this.finalAdd()
        }
      })
    }
  }

  getNumberFormat(num:any) {
    if (num != undefined) {
      return num.toString().padStart(3, "0")

    } else {
      return '000';
    }
  }
  async getEmployeeid() {
    this.joiningObj['emp_id_show'] =null
      this.joiningObj['emp_id'] =null
    // var arr = []
    // this.spinner.show()
    // var obj:any = new Object();
    // obj['b_acct_id'] = this.b_acct_id;
    // var resp = await this.joiningservice.getMaxEmployee(this.b_acct_id);
    // console.log(resp, 'max emp id')
    // if (resp['error'] == false) {
    //   this.spinner.hide()
    //   this.joiningObj['emp_id_show'] = /* this.mainService.accInfo['account_short_name'] + */ this.getNumberFormat(resp.data[0]['emp_id'] + 1);
    //   this.joiningObj['emp_id'] = this.getNumberFormat(resp.data[0]['emp_id'] + 1);
    //   this.emp_id = this.joiningObj['emp_id']
    //   this.emp_id.toString
    // } else {
    //   this.spinner.hide()
    //   this.snackBar.open("Error while getting employee list", 'Error', {
    //     duration: 5000
    //   });
    // }
  }
  //////////////////////////////princy/////
  addRow() {
    let index = this.AllCCRow.length + 1
    this.AllCCRow.push({ row_id: index })
  }

  deleteRow(i:any) {
    for (let j = 0; j < this.AllCCRow.length; j++) {
      if (j > i) {
        this.AllCCRow[j]['row_id'] = this.AllCCRow[j]['row_id'] - 1
      }
    }
    this.AllCCRow.splice(i, 1);
  }
  ////////////////////////////////////////


  async finalAdd() {
    // this.spinner.show();
    console.log(this.joiningObj)
    var obj = Object.assign({}, this.joiningObj);
    obj['b_acct_id'] = this.b_acct_id;
    obj['create_user_id'] = this.user_id;
    obj['status'] = "ACTIVE";
    obj['life_cycle_status'] = "ACTIVE";
    obj['emp_status_code'] = 'JOINING';
    obj['user_id'] = obj['le_id'];
    var jdt = this.joiningObj['joining_date'];
    var ddt = this.joiningObj['emp_dob'];
    var yr = this.underAgeValidate(jdt, ddt);


    ///////////////////////////////////////////////////////

    obj['posting_data'] = this.AllCCRow;
    var role :any= [];

    for (let i = 0; i < this.AllCCRow.length; i++) {
      this.AllCCRow[i]['cost_center_desc']=this.ccCodeToDesc[this.AllCCRow[i]['cost_center']]
      if (this.AllCCRow[i]['is_primary']) {
        this.AllCCRow[i]['is_primary'] = 1
      } else {
        this.AllCCRow[i]['is_primary'] = 0
      }
      if (role.includes(this.moduleToRole[this.AllCCRow[i]['module_cd']])) {

      } else {
        role.push(this.moduleToRole[this.AllCCRow[i]['module_cd']])
      }
    }

    obj['role_data'] = role;
    ///////////////////////////////////////////////////////

    if (yr >= 18) {
      console.log(obj)
      var resp = await this.joiningservice.insertEmployeeMasterData(obj);
      console.log(resp, 'qwertyo')
      if (resp['error'] == false) {
        this.spinner.hide();
        this.joiningObj = {};
        await this.getEmployeeid();
        swal.fire("Success", "...and Joining Completed!", 'success');
      } else {
        this.spinner.hide();
        var str = resp.data;
        if (str.includes("Duplicate")) {
          swal.fire("Sorry", resp.data, 'warning');
        } else {
          swal.fire("Sorry", "...There looks some error, it  will be rectify soon!", 'warning');
        }
      }
    } else {
      swal.fire("Sorry", "...You are below 18 years!", 'warning');
    }

    this.spinner.hide();

  }

}
