import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../../service/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { PayrollService } from '../../service/payroll.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


//import { PayrollService } from '../../service/payroll.service';


/*
import { SalaryService } from '../service/salary.service'; */
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-annual-statement',
  templateUrl: './annual-statement.component.html',
  styleUrls: ['./annual-statement.component.css']
})
export class AnnualStatementComponent implements OnInit {
  b_acct_id: any;
  erpUser: any;
  constructor(private payroll: PayrollService, private spinner: NgxSpinnerService, public mainService: MainService, private payableService: PayrollService) { }
  monthArr: any = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3]
  monthObj: any = { 1: 'January', 2: 'Febuary', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December' }
  selectObj: any = {};
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllCurrentArrangements();
  }
  personalInfoObj: any = {};
  personalInfoObj1: any = { emp_phone_no: '0000000000' };
  itreportdata: any = []
  itreportobj: any = {}
  totalobj: any = {}//={'BASIC':0.00,'CCA':0.00,'CONV':0.00,'CPF/GPF Ded':0.00,'Comp Alw':0.00,'Comp. Loan':0.00,'Cons. Loan':0.00,'DA':0.00,'DEP-ALLOW':0.00,'Fest Adv':0.00,'GIS':0.00,'H RENT':0.00,'Special Allowance':0.00,'HB Adv':0.00,'HDFC':0.00,'HRA':0.00,'HVDT Allow':0.00,'I. Tax':0.00,'MED':0.00,'Misc Ded.':0.00,'NPS':0.00,'Other Adv':0.00,'Other Ded':0.00,'PF Advance':0.00,'Per Pay':0.00,'Res Alw':0.00,'Veh Loan':0.00,'WA':0.00,'totalpay':0.00,'totalded':0.00,'net_pay':0.00,'PF':0.00}
  // totalobj ={'BASIC':0.00,'HRA':0.00,'CCA':0.00,'DEP-ALLOW':0.00,'MED':0.00,'Per Pay':0.00,'CONV':0.00,'WA':0.00,'HVDT Allow':0.00,LIC2:0.00,LIC3:0.00,LIC4:0.00,LIC5:0.00,LIC6:0.00,LIC7:0.00,PF:0.00,NPS:0.00,GIS:0.00,'I .Tax':0.00,HRR:0.00,VD:0.00,VADV:0.00,BLDADV1:0.00,BLDADV2:0.00,BLDADV3:0.00,PFADV:0.00,PFADV1:0.00,PFADV2:0.00,BADV:0.00,EWF:0.00,miscded:0.00,total:0.00,net:0.00};
  async getPersonalInfo() {
    this.personalInfoObj1 = { emp_phone_no: '0000000000' };
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectObj['emp_id'];
    // console.log(this.mainService.emp_id);
    //this.spinner.show();
    var resp = await this.payroll.getPersonalInfo(obj);
    console.log(resp);
    if (resp['error'] == false) {
      if (resp.data.length > 0) {
        this.personalInfoObj1 = resp.data[0];
      }
      //this.personalInfoObj1['emp_name'] = /* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(this.personalInfoObj1['emp_id'])+" - "+ this.personalInfoObj1['emp_name'];
      this.personalInfoObj1['emp_name'] = /* this.mainService.accInfo['account_short_name']+ */this.personalInfoObj1['emp_id'] + " - " + this.personalInfoObj1['emp_name'];
      console.log(this.personalInfoObj1)
    }
  }
  empidtodesignation: any = {}
  getNumberFormat(num: any) {
    return num.toString().padStart(3, "0")
  }
  allArr: any = [];
  allEmplyees_new: any = []
  async getAllCurrentArrangements() {
    //this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.payroll.getAllCurrentArrangements(JSON.stringify(obj));
    if (resp['error'] == false) {
      //  this.spinner.hide()
      this.allArr = resp['data'];
      this.allEmplyees_new = [];
      for (let i = 0; i < this.allArr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, this.allArr[i]);
        obj['tempemp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + " - " + obj['emp_name'];
        this.allEmplyees_new.push(obj)
        this.empidtodesignation[obj['emp_id']] = obj['designation_code'];
      } console.log(this.empidtodesignation)

    } else {
      // this.spinner.hide()

    }
  }

  changeName() {
    this.selectObj['print_flag'] = false
    console.log(this.allEmplyees_new)

    if (this.selectObj['emp_id']) {
      for (let i = 0; i < this.allEmplyees_new.length; i++) {
        if (this.selectObj['emp_id'] == this.allEmplyees_new[i]['emp_id']) {
          this.selectObj['emp_name'] = this.allEmplyees_new[i]['emp_name']
        }
      }
    } else {
      this.selectObj['emp_name'] = ''
    }
  }
  reset_flag() {
    this.selectObj['print_flag'] = false

  }
  group_concat(data: any, group_key: any, concatKey: any) {

    let result: any = {}

    for (let i = 0; i < data.length; i++) {
      let key: any = ""
      let temp: any = {}
      for (let j = 0; j < group_key.length; j++) {
        key += "_" + data[i][group_key[j]]
        temp[group_key[j]] = data[i][group_key[j]]
      }

      if (result[key] == undefined) {
        result[key] = temp
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] = data[i][concatKey[j]]
        }

      } else {
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] += data[i][concatKey[j]]
          // result[key][concatKey[j]] += "+" + data[i][concatKey[j]]
        }
      }


    }
    var ret_data: any = Object.values(result);
    for (let i = 0; i < ret_data.length; i++) {
      ret_data[i]['pay_component_amt'] = ret_data[i]['pay_component_amt'].toFixed(0)
    }
    return ret_data
  }
  pay_comp: any = []
  obj2: any = {}
  all_component: any
  itreportdata1: any
  async itreport() {
    if (this.selectObj['emp_id'] == '' || this.selectObj['emp_id'] == null || this.selectObj['emp_id'] == undefined ||
      this.selectObj['fin_year'] == '' || this.selectObj['fin_year'] == null || this.selectObj['fin_year'] == undefined) {
      Swal.fire("Warning", '* Field Required', 'warning');
      return;

    }
    this.spinner.show()
    var obb: any = new Object()
    obb['b_acct_id'] = this.b_acct_id
    obb['emp_id'] = this.selectObj['emp_id'];//this.mainService.emp_id;
    obb['fin_year'] = this.selectObj['fin_year'];
    var resp = await this.payroll.getItReport(JSON.stringify(obb))
    if (resp['error'] == false) {
      this.spinner.hide()
      this.selectObj['print_flag'] = true
      this.itreportdata = resp.data
      this.itreportdata1 = resp.data

      this.itreportdata = this.group_concat(this.itreportdata, ['emp_id', 'pay_component_code', 'pay_code', 'month', 'fin_year'], ['pay_component_amt', 'num_of_days']);
      console.table(this.itreportdata)
      var all_pay_component_code = []
      for (let i = 0; i < this.itreportdata.length; i++) {
        all_pay_component_code.push(this.itreportdata[i]['pay_component_code']);
      }


      //   console.log(all_emp_id)
      this.pay_comp = Array.from(new Set(all_pay_component_code));


      this.itreportobj = {}
      await this.getPersonalInfo();
      var obj: any = new Object();
      obj['b_acct_id'] = this.b_acct_id;

      // this.spinner.show();

      var resp1 = await this.payableService.getAllComponent(JSON.stringify(obj));
      if (resp1['error'] == false) {
        this.all_component = resp1.data;
        console.log(this.all_component)



      } else {
        return;
      }
      //console.log(this.obj2)
      this.itreportobj = {};
      for (let i = 0; i < this.all_component.length; i++) {
        this.totalobj[this.all_component[i]['component_code']] = 0.00
        this.obj2[this.all_component[i]['component_code']] = 0.00
      }

      for (let i = 0; i < this.itreportdata.length; i++) {
        //  this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']]=0.00
        if (this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']] == undefined) {
          //console.log(this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']])
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']] = {}
        }
        if (this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'] == undefined) {

          for (let j = 0; j < this.all_component.length; j++) {
            this.obj2[this.all_component[j]['component_code']] = 0.00
            //this.totalobj[this.all_component[i]['component_code']]=0.00
            //component[this.all_component[i]['component_code']]=0.00
          }
          var comp = Object.assign({}, this.obj2);

          //this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total']= component;
          // this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total']={'BASIC':0.00,'CCA':0.00,'CONV':0.00,'CPF/GPF Ded':0.00,'Comp Alw':0.00,'Comp. Loan':0.00,'Cons. Loan':0.00,'DA':0.00,'DEP-ALLOW':0.00,'Fest Adv':0.00,'GIS':0.00,'H RENT':0.00,'Special Allowance':0.00,'HB Adv':0.00,'HDFC':0.00,'HRA':0.00,'HVDT Allow':0.00,'I. Tax':0.00,'MED':0.00,'Misc Ded.':0.00,'NPS':0.00,'Other Adv':0.00,'Other Ded':0.00,'PF Advance':0.00,'Per Pay':0.00,'Res Alw':0.00,'Veh Loan':0.00,'WA':0.00,'totalpay':0.00,'totalded':0.00,'net_pay':0.00,'PF':0.00};
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'] = comp;
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalpay'] = 0.00
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalded'] = 0.00
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['net_pay'] = 0.00
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['fin_year'] = this.itreportdata[i]['fin_year']
          console.log(this.itreportdata[i]['fin_year'])

        }
        if (this.itreportdata[i]['pay_code'] == 'PAY') {
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalpay'] += parseInt(this.itreportdata[i]['pay_component_amt'])
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['net_pay'] += parseInt(this.itreportdata[i]['pay_component_amt'])
          this.totalobj[this.itreportdata[i]['pay_component_code']] += parseInt(this.itreportdata[i]['pay_component_amt'])


        } else {
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalded'] += parseInt(this.itreportdata[i]['pay_component_amt'])
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['net_pay'] -= parseInt(this.itreportdata[i]['pay_component_amt'])
          this.totalobj[this.itreportdata[i]['pay_component_code']] += parseInt(this.itreportdata[i]['pay_component_amt'])

        }
        if (this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']] != undefined) {

          //console.log(this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']])
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']] += parseInt(this.itreportdata[i]['pay_component_amt'])
          //console.log(this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']])
        } else {
          if (this.itreportdata[i]['pay_code'] == 'PAY') {
            // this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total']['pay_component_code']=this.itreportdata[i]['pay_component_amt']
            //  this.totalobj['miscpay'] +=this.itreportdata[i]['pay_component_amt']
          }
          else {
            // this.totalobj['miscded'] +=this.itreportdata[i]['pay_component_amt']

            // this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total']['pay_component_code']=this.itreportdata[i]['pay_component_amt']

          }

        }
        //this.itreportobj=this.itreportobj
      }
      console.log(this.itreportobj, this.itreportdata, this.totalobj)
    }

    else {
      this.spinner.hide()
      Swal.fire('Error..', 'Error while getting ITR', 'error')
    }
    console.log(this.itreportobj, this.itreportdata)
    // this.print1()
  }
  print1() {
    this.spinner.show()
    //var txt = "VARANASASI DEVELOPMENT AUTHORITY(VDA)   Officers/THIRD/FOURTH Category EMPLOYEES STATEMENT FOR THE MONTH OF June,2020   PIRNT DATE: 2020-10-10"
    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")";
    var dd: any = {
      pageSize: 'A2',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40], fontSize: 10, bold: true };
        return obj;
      },

      //footer: function(currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [10, 60, 10, 60],
      //pageMargins: [ 40, 20, 20, 20 ],
      content: [

      ]
    };
    // console.log(dd)
    // dd.content.push("\n\n");
    var head = {
      columns: [

        {},
        {
          width: '*',
          align: 'right',
          text: 'Annual Statement of Employee ' + this.mainService.codeValueShowObj['HR0023'][this.selectObj['fin_year']],
          bold: true
        }

        , {}

      ],

    }
    dd.content.push(head);
    var sign1 = {
      columns: [
        {
          width: '*',
          text: 'Employee Name :',
          bold: true
        },

        {
          width: '*',
          align: 'left',
          text: this.personalInfoObj1['emp_name'],
          bold: true
        },
        {
          width: '*',
          text: 'PAN NUMBER :',
          bold: true
        },
        {
          width: '*',
          align: 'left',
          text: this.personalInfoObj1['emp_pan_no'],
          bold: true
        }, {
          width: '*',
          text: 'Designation :',
          bold: true
        }, {
          width: '*',
          align: 'left',
          text: this.empidtodesignation[this.personalInfoObj1['emp_id']],
          bold: true
        }


      ],

    }
    dd.content.push(sign1);
    var count = 0;
    let list = ['Emp\nDetail'];
    let data_keys = [];

    let width = ['auto'];
    //   width.push('200')
    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'PAY') {
        list.push(this.all_component[i]['component_code'])
        data_keys.push(this.all_component[i]['component_code'])
        width.push('auto')
      }
    }
    list.push('total\npay');
    width.push('auto');
    //    data_keys.push('gross_pay');

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'DED') {
        list.push(this.all_component[i]['component_code']);
        data_keys.push(this.all_component[i]['component_code'])

        width.push('auto')
      }
    }
    list.push('Total\nDed');
    //  data_keys.push('total');
    width.push('auto');
    list.push('Net.\nSal.');
    width.push('auto');
    // data_keys.push('net');

    //console.log(width)



    var tbl :any = {

      layout: 'lightHorizontalLines',
      fontSize: 8,
      table: {

        headerRows: 1,
        //   widths: [200,'auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto'],
        widths: [],
        body: [
          // [ 'Month', 'Basic\nPay','HRA' ,'CCA','DEP\n Allow','MED','Per\npay','CONV','WA','HVDVT\nAllow','Comp\nAlw','Res\nAlw','DA','Total','LIC','PF','NPS','GIS','CPF\nGPF\nDed','Veh\nLoan','HB\nAdv','Fest\nAdv','I.\nTax','Other\nDed','HDFC','H\nRent','Misc\nDed','Cons.\nLoan','Other\nAdv','Total\nDed','Net.\nSal.' ]

          []

        ]
      }
    };
    for (let i = 0; i < list.length; i++) {
      tbl.table.body[0].push(list[i]);
      // console.log(list[i])
      tbl.table.widths.push('auto')
    }
    dd.content.push(tbl);
    //  console.log(tbl1)
    console.log(dd.content)
    var totalamtpay = 0
    var totalamtded = 0
    var totalamtnet = 0
    var itarr = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3] //Object.keys(this.itreportobj)
    var fin = this.selectObj['fin_year']
    // itarr[i]+'-'+fin
    for (let i = 0; i < itarr.length; i++) {
      if (itarr[i] == 3 && i == 0) {
        fin = this.selectObj['fin_year'] - 1
      } else {
        fin = this.selectObj['fin_year']
      }
      if (this.itreportobj[itarr[i] + '-' + fin] == undefined || (itarr[i] == 3 && (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']) + 1) == this.selectObj['fin_year'] && i != 0)) {
        continue;
      }

      var arr = []
      if (itarr[i] == 1 || itarr[i] == 2 || (itarr[i] == 3 && (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']) + 1) == this.selectObj['fin_year'])) {
        var str = this.monthObj[itarr[i]] + "\n" + (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']) + 1)
        // arr.push(this.monthObj[itarr[i]] +'-'+(Number(this.itreportobj[itarr[i]+'-'+fin]['fin_year'])+ 1))
        arr.push(str)
      } else {
        str = this.monthObj[itarr[i]] + "\n" + (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']))
        // arr.push(this.monthObj[itarr[i]] +'-'+this.itreportobj[itarr[i]+'-'+fin]['fin_year'])
        arr.push(str)
      }
      //////////////////////////////////////////princy//////////////////////////////////////////



      for (let j = 1; j < list.length; j++) {
        //      console.log(list[j])
        //  arr.push(this.itreportobj[itarr[i]+'-'+fin][list[i]])
        // console.log(arr)
        //   console.log(this.itreportobj[list[i]])
        //  console.log(this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]])
        // this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]]=this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]]
        if (list[j] == 'total\npay') {
          arr.push(this.itreportobj[itarr[i] + '-' + fin]['totalpay'])
          totalamtpay += this.itreportobj[itarr[i] + '-' + fin]['totalpay']
        }
        else if (list[j] == 'Total\nDed') {
          arr.push(this.itreportobj[itarr[i] + '-' + fin]['totalded'])
          totalamtded += this.itreportobj[itarr[i] + '-' + fin]['totalded']
        }
        else if (list[j] == 'Net.\nSal.') {
          arr.push(this.itreportobj[itarr[i] + '-' + fin]['net_pay'])
          totalamtnet += this.itreportobj[itarr[i] + '-' + fin]['net_pay']
        }
        else {


          arr.push(this.itreportobj[itarr[i] + '-' + fin]['total'][list[j]])

        }








      }





      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['BASIC'])
      //  console.log(this.itreportobj[itarr[i]+'-'+fin]['total']['BASIC'])
      // arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['HRA']);
      // arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['CCA']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['DEP-ALLOW']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['MED']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Per Pay']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['CONV']);
      //   console.log(this.itreportobj[itarr[i]+'-'+fin]['total']['CONV'])
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['WA']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['HVDT Allow']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Comp Alw']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['DA']);

      // arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['WA']);
      //   var miscpay = this.itreportobj[itarr[i]+'-'+fin]['total']['miscpay'];
      //    var str  = '0'
      //   for (let j = 0; j < miscpay.length; j++) {
      //    if(j == 0){
      //     str = miscpay[j]
      //    }else{
      //     str = str +'/n'+miscpay[j]
      //    }

      //   }
      // arr.push(str)
      //       arr.push(this.itreportobj[itarr[i]+'-'+fin]['totalpay'])
      // totalamtpay += this.itreportobj[itarr[i]+'-'+fin]['totalpay']
      //       var amt1 = this.itreportobj[itarr[i]+'-'+fin]['total']['LIC1']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC2']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC3']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC4']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC5']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC6']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC7']
      //var licstr=obj['LIC1']+"\n"+obj['LIC2']+"\n"+obj['LIC3']+"\n"+obj['LIC4']+"\n"+obj['LIC5']+"\n"+obj['LIC6']+"\n"+obj['LIC7'];
      //var licstr={ text: 'Section : '+sections[i]+"\nTotal Employees : "+emps.length, fontSize: 8};
      //   arr.push(amt1);
      // arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['PF']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['NPS']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['GIS']);
      // //  arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['GIS']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['CPF/GPF Ded']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Veh Loan']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['HB Adv']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Fest Adv'])
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['I. Tax']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Other Ded']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['HDFC']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['H RENT']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Misc Ded.']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Comp. Loan']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Cons. Loan']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Other Adv']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Total Ded']);


      //   var amt
      //   amt = this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV1']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV2']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV3'];
      //   //var bldstr=this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV1']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV2']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV3']
      //   arr.push(amt);
      //   amt = this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV1']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV2'];
      //   //var pfstr=this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV1']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV2']
      //   arr.push(amt);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['BADV']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['EWF']);
      // var miscded = this.itreportobj[itarr[i]+'-'+fin]['total']['miscded'];
      // var str1  = '0'
      // for (let j = 0; j < miscded.length; j++) {
      //  if(j == 0){
      //   str1 = miscded[j]
      //  }else{
      //   str1 = str1 +'/n'+miscded[j]
      //  }

      // }
      // arr.push(str1)
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['net_pay'])
      //   //arr.push(itarr[i]+'-'+fin)
      //   totalamtnet += this.itreportobj[itarr[i]+'-'+fin]['net_pay']

      dd.content[dd.content.length - 1].table.body.push(arr);
    }

    var arr = []
    arr.push('Final\nTotal')
    for (let j = 1; j < list.length; j++) {
      if (list[j] == 'total\npay') {
        arr.push(totalamtpay)
      }
      else if (list[j] == 'Total\nDed') {
        arr.push(totalamtded)
      }
      else if (list[j] == 'Net.\nSal.') {
        arr.push(totalamtnet)
      }

      else {

        arr.push(this.totalobj[list[j]])
      }
      // arr.push(this.totalobj['HRA'])
      // arr.push(this.totalobj['CCA'])
      //  arr.push(this.totalobj['DEP-ALLOW']);
      //  arr.push(this.totalobj['MED']);
      //  arr.push(this.totalobj['Per Pay']);
      //  arr.push(this.totalobj['CONV']);
      //  arr.push(this.totalobj['WA']);
      //  arr.push(this.totalobj['HVDT Allow']);
      //  arr.push(this.totalobj['Comp Alw']);
      //  arr.push(this.totalobj['DA']);

      //    var miscpay = this.totalobj['miscpay'];


      //  arr.push(miscpay)
      // arr.push(totalamtpay)
      //  var amt1 = this.totalobj['LIC1']+"\n"+this.totalobj['LIC2']+"\n"+this.totalobj['LIC3']+"\n"+this.totalobj['LIC4']+"\n"+this.totalobj['LIC5']+"\n"+this.totalobj['LIC6']+"\n"+this.totalobj['LIC7']
      //var licstr=obj['LIC1']+"\n"+obj['LIC2']+"\n"+obj['LIC3']+"\n"+obj['LIC4']+"\n"+obj['LIC5']+"\n"+obj['LIC6']+"\n"+obj['LIC7'];
      //var licstr={ text: 'Section : '+sections[i]+"\nTotal Employees : "+emps.length, fontSize: 8};
      //  arr.push(amt1);
      //  arr.push(this.totalobj['PF']);
      //    arr.push(this.totalobj['NPS']);
      //    arr.push(this.totalobj['GIS']);
      //    arr.push(this.totalobj['IT']);
      //    arr.push(this.totalobj['HRR']);
      //    arr.push(this.totalobj['VD']);
      //    arr.push(this.totalobj['VADV'])
      //    var amt
      //    amt = this.totalobj['BLDADV1']+"\n"+this.totalobj['BLDADV2']+"\n"+this.totalobj['BLDADV3'];
      //var bldstr=this.totalobj['BLDADV1']+"\n"+this.totalobj['BLDADV2']+"\n"+this.totalobj['BLDADV3']
      // arr.push(amt);
      // amt = this.totalobj['PFADV']+"\n"+this.totalobj['PFADV1']+"\n"+this.totalobj['PFADV2'];
      //var pfstr=this.totalobj['PFADV']+"\n"+this.totalobj['PFADV1']+"\n"+this.totalobj['PFADV2']
      //    arr.push(amt);
      //    arr.push(this.totalobj['BADV']);
      //    arr.push(this.totalobj['EWF']);
      //  var miscded = this.totalobj['miscded'];

      //  arr.push(miscded)
      //  arr.push(totalamtnet)
      //   arr.push(itarr[i])
    }

    dd.content[dd.content.length - 1].table.body.push(arr);
    console.log(dd)
    /*  console.log(dd)
      dd.content.push("\n\n");
      var sign1={
       columns: [
         {
           width: '*',
           text: 'PREPARED BY:',
           bold: true
         },

         {
           width: '*',
           text: 'CHECKED BY:' ,
           bold: true
         },
         {
           width: '*',
           text: 'SIGNED BY:',
           bold: true
         }


       ],

     } */
    /*   dd.content.push("\n\n\n");
      dd.content.push(sign1);
      dd.content.push("\n\n");
      dd.content.push({text:"CERTIFIED:",bold: true})
      dd.content.push("\n\n"); */
    /*  dd.content.push({text:"1. That I have satisfied myself that all the salaries included in the bills drawn in the month of "+this.monthObj[month]+"/"+fin_year+" [the last preceding month] with the exception of those detailed below of which total has been refunded by deduction from the bill has been distributed to the proper persons and their receipts have been taken in acquittance rolls field in my office with reciept-stamp dully cancelled for every payment in access of Rs. 20 and that all leaves and promotions etc have been in the service book of the official concerned."})
     dd.content.push("\n"); */
    /*   dd.content.push({text:"2. That all persons for whom pay has been drawn in this bill have actually been entertained during the month."})
      dd.content.push("\n");

      dd.content.push({text:"3. That all the persons for whom house-rent allowance has been shown in this bill actually occupied a rented house for which they paid rent as shown in this bill and are entitled to the allowance under the standing instructions."})
      dd.content.push("\n");

      dd.content.push({text:"4. That all the persons in respect of whom conveyance allowance has been drawn in the bill have satisfied me that they have actually maintained the conveyance in a workable condition and have been using them."})
      dd.content.push("\n");

      dd.content.push({text:"5. That the bill has been checked with the sanctioned in the scale register."})
      dd.content.push("\n");

      dd.content.push({text:"Date :                                                    Signature Of Drawing Officer:"})
      dd.content.push("\n");

      dd.content.push({text:"Pay Rs. ....................................."})
   */
    this.spinner.hide()
    pdfMake.createPdf(dd).download(this.personalInfoObj1['emp_name'] + '-' + this.selectObj['fin_year']);
  }
}
