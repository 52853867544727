import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from "../../helpers";
import { Router } from '@angular/router'
import { NgxSpinnerService } from "ngx-spinner";
import { MainService } from '../service/main.service';
import { MasterDataService } from '../service/master-data.service';
import { CommonDataService } from '../common _services/common-data.service';
import {SettingService} from '../../property/service/setting.service'
import {MainService as partalmain} from '../../portal/service/main.service'

@Component({
  selector: '.page-wrapper',
  templateUrl: './emb-layouts.component.html',
  styleUrls: ['./emb-layouts.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EmbLayoutsComponent implements AfterViewInit {


  constructor(private SettingService:SettingService,private partalmain:partalmain,private CommonDataService:CommonDataService,private spinner:NgxSpinnerService, private mainService: MainService,private router : Router) { }
  b_acct_id=-1;
  erpUser:any;
  allFields = [];
  allCodeValue = [];
  codeValueObj = {};
  codeValueTechObj = {};
  codeValueShowObj = {};
  codeValueShowTempObj = {};
  interval:any;
  async ngOnInit() {
      this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
      this.b_acct_id = this.erpUser.b_acct_id;
      await this.getAllTaskOfUser()
      await this.getCodeValue();
      await this.CommonDataService.getWorkOrderTOWorkIdInfo(this.b_acct_id);
      await this.CommonDataService.getAssignedWorkInfo(this.b_acct_id,this.erpUser['user_id']);
      await this.CommonDataService.getAllRolesWIthResource(this.b_acct_id);   
      this.mainService.getAllLanguage()
      this.mainService.getAllLabel()
      this.CommonDataService.getAllWorkInfo(this.b_acct_id)
  }
  // ngOnDestroy() {
  //   clearInterval(this.interval)
  // }
  async getCodeValue() {
    this.spinner.show();
    var resp = await this.SettingService.getCodeValue(this.b_acct_id);
    console.log(resp)
    var codeValueTempObj:any = {}
    var codeValueShowTempObj:any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        resp['data'][i]['desc'] = resp['data'][i]['code'] + '-' + resp['data'][i]['value']
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;

        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }

  }
  async getAllTaskOfUser() {
    var obj = Object()
    obj['b_acct_id'] = this.erpUser.b_acct_id
    obj['user_id'] = this.erpUser.user_id
    obj["status"] = 'PENDING'
    console.log(obj)
    var resp = await this.partalmain.getAllTaskOfUser(JSON.stringify(obj))
    if (resp['error'] == false) {
      // this.spinner.hide();
      console.log(resp['data'])
      this.mainService.count = resp['data'].length
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      // this.spinner.hide();
      //swal.fire("Error", resp['data'], 'error');
    }
  }
  ngAfterViewInit() {
    // initialize layout: handlers, menu ...
    Helpers.initLayout();
  }

}

