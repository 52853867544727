<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-4 col-md-6">
            <div class="ibox bg-info color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{total_offered}}</h2>
                    <div class="m-b-5">Total Offer</div><i class="fa fa-building  widget-stat-icon"></i>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="ibox bg-warning color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{total_offered_property}}</h2>
                    <div class="m-b-5">Total Offered Property</div><i class="fa fa-shopping-cart widget-stat-icon"></i>
                    <button type="button" class="btn btn-primary view-offer"(click)="totalProOffer()"> View</button>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-4 col-md-6">
            <div class="ibox bg-success color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{total_boolet_purchase}}</h2>
                    <div class="m-b-5">Total Booklet Purchase</div><i class="fa fa-book widget-stat-icon"></i>
                </div>
            </div>
        </div> -->
        <div class="col-lg-4 col-md-6">
            <div class="ibox bg-success color-white widget-stat">
                <div class="ibox-body offer-content">
                    <h2 class="m-b-5 font-strong">{{total_applicant}}</h2>
                    <div class="m-b-5">Total Applicant</div><i class="fa fa-book widget-stat-icon"></i>
                    <button type="button" class="btn btn-primary view-offer"
                     (click)="getAllApplicant()">
                        View
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div class="row">

        <div class="col-lg-4 col-md-6">
            <div class="ibox bg-secondary color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{total_reserved_property}}</h2>
                    <div class="m-b-5">Total Reserved Property</div><i class="fa fa-list widget-stat-icon"></i>
                    <button type="button" class="btn btn-primary view-offer"
                    (click)="allVacantANDResevedPro('RESERVED')">
                       View
                   </button>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="ibox bg-danger color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{total_remaining_property}}</h2>
                    <div class="m-b-5">Total Vacant Property</div><i class="fa fa-list widget-stat-icon"></i>
                    <button type="button" class="btn btn-primary view-offer"
                    (click)="allVacantANDResevedPro('ACTIVE')">
                       View
                   </button>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="ibox bg-primary color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{total_property_cost}}</h2>
                    <div class="m-b-5">Total Property Cost</div><i class="fa fa-inr widget-stat-icon"></i>
                    <button type="button" class="btn btn-primary view-offer"
                    (click)="propertyCostDetails()">
                       View
                   </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="ibox bg-warning color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{total_sold_amount}}</h2>
                    <div class="m-b-5">Total Registration Amount </div><i class="fa fa-list widget-stat-icon"></i>
                    <button type="button" class="btn btn-primary view-offer"
                    (click)="totalSoldProperties()">
                       View
                   </button>
                </div>
            </div>
        </div>



    </div>
    <!-- <div class="row">
        <div class="col-lg-8">
            <div class="ibox">
                <div class="ibox-body">


                    <div>
                        <div id="chartContainer" style="width: 100%; height: 400px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="ibox">

                <div class="ibox-body">
                    <div>
                        <div id="chartContainer1" style="width: 100%; height: 400px;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <style>
        .visitors-table tbody tr td:last-child {
            display: flex;
            align-items: center;
        }

        .visitors-table .progress {
            flex: 1;
        }

        .visitors-table .progress-parcent {
            text-align: right;
            margin-left: 10px;
        }
    </style>
</div>
<ngx-spinner>Loading....</ngx-spinner>
<!-- for total applicant -->
<div class="modal" id="viewDetails3">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content rounded-50">

            <div class="modal-header bg-primary">
                <h4 class="modal-title text-white" style="font: bold; "> Total Applicant Approved :</h4>
                <button type="button" class="close text-white" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body" style="border-radius: 10px;">
                <div class="card" style="border: 2px solid  rgb(24, 24, 97);">
                    <div style="padding: 3%;">
                        <div class="row">
                            <div class="col-3"></div>
                            <div class="col-2 text-right">
                              <h6>Architecture Plan :</h6>
                            </div>
                            <div class="col-3 text-left">
                              <ng-select [items]="allOffer" bindLabel="offer_name" (change)="filtByOffer()"
                              [(ngModel)]="curOffer"
                                bindValue="offer_cd" [multiple]="false" placeholder="Select Offer"
                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                [closeOnSelect]="true" name="field_name">
                              </ng-select>
                            </div>
                          </div>
                          <br>
                        <div class="row" *ngIf="showChooseProType">
                            <div class="col-3"></div>
                            <div class="col-2 text-right">
                              <h6>Propert Type :</h6>
                            </div>
                            <div class="col-3 text-left">
                              <ng-select [items]="availbleProType" bindLabel="property_type" (change)="filtByOfferAndType()"
                                [(ngModel)]="curProType"
                                bindValue="property_type" [multiple]="false" placeholder="Select Offer"
                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                [closeOnSelect]="true" name="field_name">
                              </ng-select>
                            </div>
                          </div>
                        <div style="width: 200px;">
                          <!-- oc  -->
                          <!-- appearance="standard" -->
                          <!-- ec -->
                            <mat-form-field >
                                <mat-label>Filter</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                            </mat-form-field>
                        </div>
                        <div class="row" *ngIf="onApplicant">
                            <div class="col-12 text-right" >
                                 <button class="btn btn-outline-success" (click)="printProperty()" >Download List</button>
                            </div>
                        </div>
                        <div style="overflow-x: auto;">
                            <table mat-table [dataSource]="dataSource" matSort class="w-100" class="text-center">

                                <ng-container matColumnDef="sr">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> S.No </th>
                                    <td class="text-center" mat-cell *matCellDef="let element ; let i = index ">{{element.sr}}</td>
                                </ng-container>

                                <ng-container matColumnDef="offer_cd" *ngIf="displayedColumns.includes('offer_cd')" >
                                    <th class="text-center" mat-header-cell *matHeaderCellDef> Offer Code </th>
                                    <td mat-cell *matCellDef="let element" title="{{element.offer_name}}" >{{element.offer_cd}} </td>
                                </ng-container>

                                <ng-container matColumnDef="leaf_value">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef  > Architecture Plan </th>
                                    <td mat-cell *matCellDef="let element">{{element.leaf_value}} </td>
                                </ng-container>

                                <ng-container matColumnDef="party_name" *ngIf="displayedColumns.includes('party_name')" >
                                    <th class="text-center" mat-header-cell *matHeaderCellDef> Party Name </th>
                                    <td mat-cell *matCellDef="let element">{{element.party_name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="party_phone_no" *ngIf="displayedColumns.includes('party_phone_no')">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef> Party Mobile No. </th>
                                    <td mat-cell *matCellDef="let element"> {{element.party_phone_no}}</td>
                                </ng-container>



                                <ng-container matColumnDef="property_no" *ngIf="displayedColumns.includes('property_no')">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef> Property Number </th>
                                    <td mat-cell *matCellDef="let element"> {{element.property_no}}</td>
                                </ng-container>

                                <ng-container matColumnDef="property_type">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>Property type </th>
                                    <td mat-cell *matCellDef="let element">{{element.property_type}}</td>
                                </ng-container>

                                <ng-container matColumnDef="pro_rate" *ngIf="displayedColumns.includes('pro_rate')">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>Property Rate </th>
                                    <td mat-cell *matCellDef="let element">{{element.pro_rate}}</td>
                                </ng-container>
                                <ng-container matColumnDef="registration_amt" *ngIf="displayedColumns.includes('registration_amt')">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>Registration Amount </th>
                                    <td mat-cell *matCellDef="let element">{{element.registration_amt}}</td>
                                </ng-container>


                                <ng-container matColumnDef="no_of_pro" *ngIf="displayedColumns.includes('no_of_pro')">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>Property's Count</th>
                                    <td mat-cell *matCellDef="let element">{{element.no_of_pro}}</td>
                                </ng-container>

                                <ng-container matColumnDef="total_property_cost" *ngIf="displayedColumns.includes('total_property_cost')">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>Total Cost</th>
                                    <td mat-cell *matCellDef="let element">{{element.total_property_cost}}</td>
                                </ng-container>

                                <ng-container matColumnDef="form_status" *ngIf="displayedColumns.includes('form_status')">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef> Application Status </th>
                                    <td mat-cell *matCellDef="let element">{{element.form_status}}</td>
                                </ng-container>
                                <ng-container matColumnDef="pro_status" *ngIf="displayedColumns.includes('pro_status')">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef> Proprty Status </th>
                                    <td mat-cell *matCellDef="let element">{{element.pro_status}}</td>
                                </ng-container>
                                <ng-container matColumnDef="Action" *ngIf="displayedColumns.includes('Action')">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef > Action </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div>
                                            <button class="btn btn-outline-success"style="margin-bottom: 2px;margin-top:2px;"
                                            *ngIf="element.form_status=='COMPLETED' || element.form_status=='UNDERAPPROVAL' || element.form_status=='APPROVED' "
                                             (click)="PdfPrint(element)" >Download</button>&nbsp;

                                            <button class="btn btn-outline-success"style="margin-bottom: 2px;margin-top:2px;"
                                            *ngIf="element.form_status=='COMPLETED'|| element.form_status=='UNDERAPPROVAL' || element.form_status=='APPROVED' "
                                             (click)="viewDocuments(element)" >Documents</button><br>

                                             <button class="btn btn-outline-success"style="margin-bottom: 2px;"
                                             *ngIf="element['form_status'] == 'COMPLETED' && apprflag == true"
                                             (click)="Appr(element)" >Add Approval</button> &nbsp;

                                            <button class="btn btn-outline-success"
                                             *ngIf="element.form_status=='UNDERAPPROVAL' || element.form_status=='APPROVED' || element.form_status=='REJECTED'"
                                             (click)="viewStatus(element)"> View Status </button>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                            <mat-paginator [pageSizeOptions]="[10]" (click)="paginatorPageChange()" showFirstLastButtons class="mat-paginator-sticky"></mat-paginator>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">Uploded Documents</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <table style="width: 100%;" class="table table-bordered">
                    <thead style="background-color: blue ; color: black;">
                            <th>S No.</th>
                            <th>Document Name</th>
                            <th>Sction</th>
                            <!-- <th>Role Name</th> -->
                    </thead>
                    <tbody>
                        <tr *ngFor="let ob of allUploadedDoc;let i=index">
                            <td>{{i+1}}</td>
                            <td>{{ob['doc_type']}}</td>
                            <td>
                                <button (click)="view_doc2(ob)" style="width: 80px; border-radius: 10px;"
                                    class="btn btn-success">View</button>
                            </td>

                        </tr>
                    </tbody>

                </table>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- WORKFLOW MODEL -->
<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                            [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="work_order_id">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                            placeholder="Enter Your Remark" class="form-control"></textarea>
                    </div>


                </div>
                <br>

                <div>
                    <table class="table table-bordered"
                        style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">
                                    <!--  <button class="btn btn-success" (click)="deleteLine(i)"><i class="fa fa-check"
                                        style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button>
                                <button class="btn btn-danger" (click)="copy(ln)">
                                     <i class="fa fa-times"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i>

                                        </button> -->
                                    <!-- <button class="btn btn-warning" (click)="paste(i)"> -->
                                    <!-- <i class="fas fa-paste"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->

                                    <!-- </button>  -->
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" style="border: black;">
                    <div class="col-4 text-right">
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit
                        </button>
                    </div>
                </div>
                <br>
                <!-- <div *ngIf="vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <h6>OR</h6>
                    </div>
                </div> -->
                <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- VIEW WORKFLOW STATUS -->

<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <!-- Status -->Status
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">
                    <a *ngFor="let ob of statusArr;let i=index">
                        <!-- <h6>{{ob['status']}}</h6> -->
                        <div class="step completed" *ngIf="ob.status=='APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;"
                                    *ngIf="i <statusArr.length-1"></div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                            </div>
                        </div>
                        <div class="step"
                            *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">
                        <!-- Close -->Close
                    </button>
                    <!-- <button type="button" class="btn btn-success" data-dismiss="modal" (click)="certificate()">Download
                        Certificate </button> -->

                </div>

            </div>
        </div>
    </div>
</div>
