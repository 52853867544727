<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account1']}}
                        <!-- Project Bank Account -->
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>{{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account2']}}
                                <!-- Project Bank Account Item List -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-7-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"
                                    aria-hidden="true"></i>{{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account3']}}
                                <!-- Create Project Bank Account -->
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-7-3" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings"> </i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account5']}}
                                <!--Update Project Bank Account -->
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-7-1">

                            <br>

                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account6']}}">
                                        <!-- Filter -->
                                    </mat-form-field>
                                </div>


                            </div>

                            <br>
                            <table mat-table [dataSource]="datasource" matSort #sort1="matSort" style="width: 100%;">

                                <ng-container matColumnDef="SR No">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account7']}}
                                        <!-- SR No. -->
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element ;let i = index;" class="right">
                                        {{i+1 }} </td>
                                </ng-container>

                                <ng-container matColumnDef="Project_Code">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account8']}}
                                        <!-- Project Code -->
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{ element.project_code}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Project_Description">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account9']}}
                                        <!-- Project Description -->

                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{ projectObject[element.project_code]}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Bank_Account_No">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account10']}}
                                        <!-- Bank Account Number -->

                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        <!-- {{element.bank_acct_no}}  -->
                                        {{bankDetailsObject[element.bank_acct_no]['bank_acct_no']}}
                                    </td>
                                </ng-container>


                                <ng-container matColumnDef="IFSC_Code">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account11']}}
                                        <!-- IFSC Code -->

                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{bankDetailsObject[element.bank_acct_no]['ifsc_code']}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Branch_Name">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account12']}}
                                        <!-- Branch Name -->

                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{bankDetailsObject[element.bank_acct_no]['branch_code']}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Bank_Name">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account13']}}
                                        <!-- Bank Name -->

                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{bankDetailsObject[element.bank_acct_no]['bank_code']}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Bank_Description">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account14']}}
                                        <!-- Bank Description -->
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{bankDetailsObject[element.bank_acct_no]['bank_acct_desc']}} </td>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account1']}}
                                        <!-- Action -->
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                        <button class="btn btn-outline-primary"
                                            (click)="open_update(element)">Update</button>&nbsp;&nbsp;
                                        <button class="btn btn-outline-danger"
                                            (click)="delete(element)">{{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account16']}}
                                            <!-- Delete -->
                                        </button>

                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                </tr>
                            </table>
                            <br>

                            <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                            </mat-paginator>


                        </div>


                        <div class="tab-pane fade" id="tab-7-2">
                            <br>

                            <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

                                <div class="row">
                                    <div class="col-4 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account17']}}
                                        <!-- Select Project --> :
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="allProjectHier" bindLabel="leaf_value" bindValue="leaf_cd"
                                            [multiple]="false" placeholder="" [(ngModel)]="bankObj['project_code']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="field_name" #projHier="ngModel" required>
                                        </ng-select>

                                        <!---------------- validation start-----------------------------  -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="projHier.dirty && projHier.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && projHier.invalid" class="error-message">
                                            <div *ngIf="projHier.errors?.['required']">Please select project
                                            </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->



                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account19']}}
                                        <!--  Select Bank Account Description --> :
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="allBankAccounts" bindLabel="bank_acct_desc" bindValue="id"
                                            [multiple]="false" placeholder="" [(ngModel)]="bankObj['bank_acct_no']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="bank_acct" #bank_acct="ngModel" required>
                                        </ng-select>

                                        <!---------------- validation start-----------------------------  -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="bank_acct.dirty && bank_acct.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && bank_acct.invalid" class="error-message">
                                            <div *ngIf="bank_acct.errors?.['required']">Please select project
                                            </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->


                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center ">
                                        <button class="btn btn-primary"
                                            type="submit">{{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account20']}}
                                            <!-- Submit -->
                                            <button class="btn btn-success d-none" id="reset"
                                                type="reset">Reset</button>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <div class="tab-pane fade" id="tab-7-3">
                            <br>
                            <form name="form" (ngSubmit)="f2.form.valid && update()" #f2="ngForm" novalidate>

                                <div class="row">
                                    <div class="col-4 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account17']}}
                                        <!-- Select Project --> :
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="allProjectHier" bindLabel="leaf_value" bindValue="leaf_cd"
                                            [multiple]="false" placeholder="" [(ngModel)]="bankObj['project_code']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="field_name" #projHier="ngModel" required>
                                        </ng-select>

                                        <!---------------- validation start-----------------------------  -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="projHier.dirty && projHier.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && projHier.invalid" class="error-message">
                                            <div *ngIf="projHier.errors?.['required']">Please select project
                                            </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account19']}}
                                        <!-- Select Bank Account Description --> :
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="allBankAccounts" bindLabel="bank_acct_desc" bindValue="id"
                                            [multiple]="false" placeholder="" [(ngModel)]="bankObj['bank_acct_no']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="bank_acct" #bank_acct="ngModel" required>
                                        </ng-select>

                                        <!---------------- validation start-----------------------------  -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="bank_acct.dirty && bank_acct.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && bank_acct.invalid" class="error-message">
                                            <div *ngIf="bank_acct.errors?.['required']">Please select project
                                            </div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->


                                    </div>
                                </div>

                                <br>
                                <div class="row">
                                    <div class="col-12 text-center ">
                                        <button class="btn btn-primary"
                                            type="submit">{{mainService.allLableShowObj[mainService.language_cd+'Project_Bank_Account5']}}
                                            <!-- Update -->
                                        </button>
                                        <button class="btn btn-success d-none" id="reset2">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-beat" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
