
import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from "../../helpers";
import { Router } from '@angular/router'
import { MainService } from '../service/main.service';
import { SettingService } from '../service/setting.service';
import { ProfileService } from '../../portal/service/profile.service';
import { AuthenticationService } from '../../authentication.service';
import { DataAccessService } from './../service/data-access.service';
@Component({
  selector: '.page-wrapper',
  templateUrl: './hrms-layouts.component.html',
  styleUrls: ['./hrms-layouts.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HrmsLayoutsComponent implements AfterViewInit {

  constructor(private dataAccessService:DataAccessService,private settingService: SettingService, public AuthenticationService: AuthenticationService, private profileService: ProfileService, private mainService: MainService, private router: Router) { }
  b_acct_id:any = -1;
  erpUser:any;
  allFields:any = [];
  allCodeValue:any = [];
  codeValueObj :any= {};
  codeValueTechObj:any = {};
  codeValueShowObj:any = {};
  codeValueShowTempObj :any= {};

  accInfo = {}
  async ngOnInit() {
    if (localStorage.getItem('erpUser') == undefined || localStorage.getItem('erpUser') == null) {
      this.router.navigate(['/login']);
    } else {
      this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
      this.b_acct_id = this.erpUser.b_acct_id;
      await this.dataAccessService.getCurrentDataAccess(this.b_acct_id,this.erpUser['user_id'])

      await this.getCodeValue();
      await this.getAccInfo();
    }
    this.mainService.language_cd='ENG'
    await this.getAllLabel()
  }
  allLabel = []
  async getAllLabel() {
    var obj :any= new Object();
    obj['module_cd'] = "HR"
    console.log(obj, 'obj for get all lebels')
    var resp = await this.mainService.getcomponentLabelvalueswithLanguage(JSON.stringify(obj));
    console.log(resp,'data of language');
    if (resp['error'] == false) {
      this.mainService.allLabelHR = resp.data;
      var obj :any= new Object();
      for (let i = 0; i < resp.data.length; i++) {
        obj[resp.data[i]['language_cd'] + resp.data[i]['component_cd'] + resp.data[i]['label_no']] = resp.data[i]['value_to_show'];
      }
      this.mainService.allLableShowObjHR = obj;
    } else {
    }
    console.log( this.mainService.allLableShowObjHR ,' this.mainService.allLableShowObjHR ')
  }
  async getAccInfo() {
    var obj :any= new Object();
    obj['b_acct_id'] = this.erpUser.b_acct_id;
    console.log(obj)
    var resp = await this.profileService.getaccountmodule(JSON.stringify(obj));
    console.log(resp, 'hrms acc info')
    if (resp['error'] == false) {
      this.accInfo = resp.data[0];
      this.mainService.accInfo = this.accInfo;
    } else {

    }

  }
  async getCodeValue() {

    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    console.log(resp)
    var codeValueTempObj :any= {}
    var codeValueShowTempObj :any= {};
    if (resp['error'] == false) {
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;

    } else {

    }

  }
  ngAfterViewInit() {
    // initialize layout: handlers, menu ...
    Helpers.initLayout();
  }

}
