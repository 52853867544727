import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
import * as XLSX from "xlsx";

//************************************************* */
import { VendorService } from "../../md/service/vendor.service";
import Swal from "sweetalert2";
import { ExcelService } from "../../emb/service/file-export.service";

@Component({
  selector: 'app-vendor-t',
  templateUrl: './vendor-t.component.html',
  styleUrls: ['./vendor-t.component.css']
})
export class VendorTComponent implements OnInit {

  constructor(private excel:ExcelService,private vendorService: VendorService, private spinner: NgxSpinnerService) { }
  Obj:any = { purpose: 'Vender Registration' };
  l_ved_flag = true;
  c_ved_flag = false;
  u_ved_flag = false;
  up_ved_flag = false;
  ebillUser:any;
  b_acct_id:any;

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  datasource:any

  purpose = [{ code: "Vender Registration", value: "Vender Registration" }];

  displayedColumns = ["id", "purpose", "tc", "action"];


  async ngOnInit() {
    this.ebillUser =  JSON.parse(atob(localStorage.getItem("erpUser") as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.Obj['purpose'] = 'Vender Registration';
    await this.getAllTandC();
  }


  export() {
    let ExpData = [
      {
        one: "S No",
        two: "T&C Description",
      },
    ];
    var exp = [];
    for (var i = 0; i < ExpData.length; i++) {
      var obj:any = new Object();
      obj["1"] = ExpData[i]["one"];
      obj["2"] = ExpData[i]["two"];
      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, "tcFormat");
  }


  table_data:any=[];
  async onFileChange1(event: any) {
    this.table_data=[];

    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data:any = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data);
     
      for(let i=1;i<data.length;i++){
        let ob:any={sno:data[i]['1'],tc:data[i]['2'],purpose:this.Obj['purpose']}
        this.table_data.push(ob)
      }
      // Data will be logged in array format containing objects
    };
  }

  deleteItem(ob:any,j:any){
    this.table_data.splice(j,1)
  }



  async open_update(element:any) {
    this.spinner.show();
    this.Obj = Object.assign({}, element);
    this.l_ved_flag = false;
    this.c_ved_flag = false;
    this.u_ved_flag = true;
    this.up_ved_flag = false;
    this.spinner.hide();
  }

  async delete(element:any) {
    this.spinner.show()
    let obj = { id: element['id'], b_acct_id: this.b_acct_id };
    var resp = await this.vendorService.deleteTandC(JSON.stringify(obj));
    if (resp["error"] == false) {
      await this.getAllTandC();
      this.spinner.hide();
      Swal.fire('success', 'Deleted Successfully...', 'success')
    } else {
      this.spinner.hide();
      Swal.fire('error', 'Error Occered...', 'error')
    }

  }

  async update() {
    this.spinner.show()

    this.Obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.vendorService.updateTandC(this.Obj);
    if (resp["error"] == false) {
      await this.getAllTandC();
      await this.gotolist();
      this.spinner.hide();
      Swal.fire('success', 'Updated Successfully...', 'success')
    } else {
      this.spinner.hide();
      Swal.fire('error', 'Error Occered...', 'error')
    }

  }

  async submit() {
    this.spinner.show()
    let ob:any = new Object();
    ob['b_acct_id'] = this.b_acct_id;
    ob['create_user_id'] = this.ebillUser['user_id'];
    ob['rows'] = [this.Obj];
    console.log(ob)
    var resp = await this.vendorService.addTandC(ob);
    if (resp["error"] == false) {
      await this.getAllTandC();
      await this.gotolist();
      this.spinner.hide();
      Swal.fire('success', 'Added Successfully...', 'success')
    } else {
      this.spinner.hide();
      Swal.fire('error', 'Error Occered...', 'error')
    }

  }
  async submitUpload(){
    this.spinner.show()
    let ob:any = new Object();
    ob['b_acct_id'] = this.b_acct_id;
    ob['create_user_id'] = this.ebillUser['user_id'];
    ob['rows'] = this.table_data;
    console.log(ob)
    var resp = await this.vendorService.addTandC(ob);
    if (resp["error"] == false) {
      await this.getAllTandC();
      await this.gotolist();
      this.spinner.hide();
      this.table_data=[]
      Swal.fire('success', 'Uploaded Successfully...', 'success')
    } else {
      this.spinner.hide();
      Swal.fire('error', 'Error Occered...', 'error')
    }
  }

  changePurpose() {
    let data:any = [];
    this.allTandC.map(x => {
      if (x['purpose'] == this.Obj['purpose']) {
        data.push(x)
      }
    })
    this.datasource = new MatTableDataSource(data);
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }

  allTandC = [];
  async getAllTandC() {
    this.spinner.show();
    var resp = await this.vendorService.getTandC(JSON.stringify({ b_acct_id: this.b_acct_id }));
    console.log(resp);
    if (resp["error"] == false) {
      this.allTandC = resp.data;
      await this.changePurpose();
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('error', 'Error Occered...', 'error')
    }
  }


  gotolist() {
    this.Obj = { purpose: 'Vender Registration' }
    this.l_ved_flag = true;
    this.c_ved_flag = false;
    this.u_ved_flag = false;
    this.up_ved_flag = false;
    this.getAllTandC();
  }

  gotocrete() {
    this.Obj = { purpose: 'Vender Registration' }
    this.l_ved_flag = false;
    this.c_ved_flag = true;
    this.u_ved_flag = false;
    this.up_ved_flag = false;
  }
  gotoUpload() {
    this.Obj = { purpose: 'Vender Registration' }
    this.l_ved_flag = false;
    this.c_ved_flag = false;
    this.u_ved_flag = false;
    this.up_ved_flag = true;
  }

  refresh() {

  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
