<div class="sidebar">
    <div id="sidebar-collapse">

        <div class="admin-block d-flex" style="align-items: center;">
            <div>
                <img src="./../../../assets/img/dash/ICMS.jpg" class="img-circle" width="45px" />
            </div>
            <div class="admin-info">
                <div class="font-strong" (click)="home()">{{auth.allSystemModuleObj['ICMS']}}</div>
            </div>
        </div>
        <ul class="side-menu metismenu">

            <li [hidden]="sidebar['dashbord']">
                <a class = "text-decoration-none" routerLinkActive="active" (click)="refreshCol()"  routerLink="/icms/icms-index"><i class="sidebar-item-icon fa fa-th-large"></i>
                    <span class="nav-label"> Dashboard </span>
                </a>
            </li>

            <li routerLinkActive="active" [hidden]="sidebar['complaints']" >

                <a class = "text-decoration-none" href="#comp" data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="reportColapse"><i class="sidebar-item-icon fa fa-archive"></i>
                    <span class="nav-label">Complaints</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" id="comp" data-bs-parent="#sidebar-collapse" >

                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/complaint/icma-public-complaint" [hidden]="sidebar['public_complain']" routerLinkActive="active">Public Complaint</a>
                    </li>
                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/complaint/icma-user-complaint" [hidden]="sidebar['user_complain']" routerLinkActive="active">Employee Complaint</a>
                    </li>
                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/complaint/assign-complaint"  [hidden]="sidebar['assigned_complain']" routerLinkActive="active">Assigned Complaint</a>
                    </li>
                    <!-- <li>
                        <a class = "text-decoration-none" routerLink="" routerLinkActive="active">Emp Complaint</a>
                    </li> -->
                </ul>
            </li>


            <li routerLinkActive="active" [hidden]="sidebar['inspection_report']"  >
                <a class = "text-decoration-none" href="#insp" data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="reportColapse"><i class="sidebar-item-icon fa fa-file"></i>

                    <span class="nav-label">Inspection </span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" id="insp" data-bs-parent="#sidebar-collapse">

                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/reports/icms-inspection-report" [hidden]="sidebar['inspection_report']" routerLinkActive="active">Inspection Report</a>
                    </li>

                </ul>
            </li>


       <!--  litigation  -->

       <li routerLinkActive="active" [hidden]="sidebar['e_court']" >
        <a class = "text-decoration-none"  href="#e-court" data-bs-toggle="collapse" aria-expanded="false"
        aria-controls="reportColapse"><i class="sidebar-item-icon fa fa-archive"></i>
            <span class="nav-label">
                <!-- {{this.auth.allLableShowObjProp[auth.language_cd+'icms_sidebar3']}} -->

                E-Court
            </span><i
                class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="e-court" data-bs-parent="#sidebar-collapse">

            <li>
                <a class = "text-decoration-none" routerLink="/icms/litigation" routerLinkActive="active" [hidden]="sidebar['litigation']">
                    <!-- {{this.auth.allLableShowObjProp[auth.language_cd+'icms_sidebar1']}} -->
                  <!-- {{this.auth.allLableShowObjProp}} -->
                    Case List
                </a>
            </li>
            <li>
                <a class = "text-decoration-none" routerLink="/icms/cause-list" routerLinkActive="active"  [hidden]="sidebar['cause_list']">
                    <!-- {{this.auth.allLableShowObjProp[auth.language_cd+'icms_sidebar2']}} -->

                    Cause List
                </a>
            </li>
            <li>
                <a class = "text-decoration-none" routerLink="/icms/request" routerLinkActive="active"  [hidden]="sidebar['request']" >
                    Request
                </a>
            </li>

        </ul>
    </li>
            <!-- ending  -->

            <!-- <li>
        <a class = "text-decoration-none" routerLinkActive="active" routerLink="/icms/litigation"><i class="sidebar-item-icon fa fa-th-large"></i>
            <span class="nav-label"> Litigation</span>
        </a>
       </li> -->
            <li routerLinkActive="active" [hidden]="sidebar['court_order']">
                <a class = "text-decoration-none"  href="#courtOrder" data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="reportColapse"><i class="sidebar-item-icon fa fa-balance-scale"></i>
                    <span class="nav-label">Court Orders</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" id="courtOrder" data-bs-parent="#sidebar-collapse">
                    <li>

                        <a class = "text-decoration-none" routerLink="/icms/court/court-order"  [hidden]="sidebar['order']" routerLinkActive="active">Order</a>

                    </li>

                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/court/assigned-order" [hidden]="sidebar['assigned_order']" routerLinkActive="active">Assigned Order</a>
                    </li>
                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/court/support-letters" [hidden]="sidebar['support_letter']" routerLinkActive="active">Support Letters</a>
                    </li>
                    <li>

                        <a class = "text-decoration-none" routerLink="/icms/court/compounding-process" [hidden]="sidebar['compounding_process']" routerLinkActive="active">Compounding Process</a>

                        <a class = "text-decoration-none" routerLink="/icms/court/recovery-calculation" [hidden]="sidebar['recovery_calculation']" routerLinkActive="active">Recovery Calculation</a>

                    </li>
                </ul>
            </li>


            <li >
                <!-- [hidden]="sidebar['all_report']" -->
                <a class = "text-decoration-none" routerLinkActive="active" (click)="refreshCol()" routerLink="/icms/reports/icms-all-report"><i class="sidebar-item-icon fa fa-file"></i>
                    <span class="nav-label"> All Reports </span>
                </a>
            </li>
    <!-- //////////////////////////////////////////////////////////////////////////////// -->

            <li routerLinkActive="active" [hidden]="sidebar['payment']">
                <a class = "text-decoration-none" href="#payments" data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="reportColapse"><i class="sidebar-item-icon fa fa-shopping-cart"></i>
                    <span class="nav-label">Payments</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse"  id="payments" data-bs-parent="#sidebar-collapse">
                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/payment/penality"  [hidden]="sidebar['penality']"  routerLinkActive="active">Penality</a>
                    </li>
                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/payment/demolition-charges"  [hidden]="sidebar['demolition_charges']" routerLinkActive="active">Demolition</a>
                    </li>
                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/payment/compounding" [hidden]="sidebar['compounding']"  routerLinkActive="active">Compounding</a>
                    </li>
                </ul>
            </li>

            <!-- <li routerLinkActive="active">
                <a class = "text-decoration-none" href="javascript:;"><i class="sidebar-item-icon fa fa-shopping-cart"></i>
                    <span class="nav-label">Reports</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" routerLinkActive="in">


                </ul>
            </li> -->
    <!-- ////////////////////////////////////////////////////////////////////////////////////////////// -->

            <li routerLinkActive="active" [hidden]="sidebar['administration']">
                <a class = "text-decoration-none" href="#admin" data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="reportColapse"><i class="sidebar-item-icon fa fa-database"></i>
                    <span class="nav-label">Administration</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" id="admin" data-bs-parent="#sidebar-collapse">
                  <div id="adminChild">
                    <li>
                        <a class = "text-decoration-none" href="#md" data-bs-toggle="collapse" aria-expanded="false"
                        aria-controls="settingCol"  [hidden]="sidebar['master']">
                            <span class="nav-label">Master Data</span><i class="fa fa-angle-left arrow"></i></a>
                        <ul class="nav-3-level collapse" id="md"  data-bs-parent="#adminChild" >

                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/icms-setting/icms-code-value" [hidden]="sidebar['code_value']" routerLinkActive="active">Code Value</a>
                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/icms-setting/icms-field" [hidden]="sidebar['field']"  routerLinkActive="active">Field</a>
                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/icms-setting/section-map" [hidden]="sidebar['section_map']" routerLinkActive="active">Section Map</a>
                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/icms-setting/icms-zone" [hidden]="sidebar['boundry_map']" routerLinkActive="active">Boundary Map</a>

                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/icms-setting/icms-campus-boundray" [hidden]="sidebar['camp_boundry_map']" routerLinkActive="active">Campus Boundary </a>

                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/icms-setting/hierachy" [hidden]="sidebar['hierarchy']" routerLinkActive="active">
                                    Hierachy
                                </a>
                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/icms-setting/support-department" [hidden]="sidebar['support_deparment']" routerLinkActive="active">
                                    Support Department
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a class = "text-decoration-none" href="#sec" href="#sec" data-bs-toggle="collapse" aria-expanded="false"
                        aria-controls="settingCol" [hidden]="sidebar['security']">
                            <span class="nav-label">Security</span><i class="fa fa-angle-left arrow"></i></a>
                        <ul class="nav-3-level collapse"  id="sec"  data-bs-parent="#adminChild"  >
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/administration/roles" [hidden]="sidebar['role']" routerLinkActive="active">
                                    Role
                                </a>
                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/administration/users" [hidden]="sidebar['user']" routerLinkActive="active">User</a>
                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/administration/data-assignment" [hidden]="sidebar['data_assignment']" routerLinkActive="active">
                                    Data Assignment
                                </a>
                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/administration/workflow" [hidden]="sidebar['workflow']" routerLinkActive="active">
                                    Workflow
                                </a>
                            </li>
                            <li>
                                <a class = "text-decoration-none" routerLink="/icms/administration/payment-gateway" [hidden]="sidebar['payment_gatway']" routerLinkActive="active">
                                   Payment Gateway
                                </a>
                            </li>
                        </ul>
                    </li>
                  </div>
                </ul>
            </li>

            <li routerLinkActive="active">
                <a class = "text-decoration-none" href="#help" data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="reportColapse"><i class="sidebar-item-icon fa fa-question-circle"></i>
                    <span class="nav-label">Help</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" id="help" data-bs-parent="#sidebar-collapse">
                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/help/faq"
                            routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar20']}}</a>
                    </li>
                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/help/user-manual"
                            routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar21']}}</a>
                    </li>
                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/help/tutorial"
                            routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar22']}}</a>
                    </li>
                    <li>
                        <a class = "text-decoration-none" routerLink="/icms/help/online-support"
                            routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar23']}}</a>
                    </li>
                </ul>
            </li>

        </ul>

    </div>

</div>
