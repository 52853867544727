<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{auth.allLableShowObjProp[auth.language_cd+'acution_bd1']}}</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{auth.allLableShowObjProp[auth.language_cd+'acution_bd2']}}</a>
                        </li>
                    </ul>

                    <div class="tab-content">

                        <div class="tab-pane fade show active" id="tab-1">
                            <br>

                            <!-- <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 text-left">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'acution_bd3']}}  :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="schemeArr" bindLabel="scheme_name" (change)='getAllSubschemes()'
                                        bindValue="scheme_code" [multiple]="false" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'acution_bd3']}}"
                                        [(ngModel)]="obj['scheme_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>

                                <div class="col-3 text-left">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'acution_bd4']}}  :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="subschemeArr" bindLabel="sub_scheme_name"
                                        bindValue="sub_scheme_code" [multiple]="false" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'acution_bd4']}}"
                                        [(ngModel)]="obj['sub_scheme_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" (change)='getAllPropertyInfos()'
                                        [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>

                                <div class="col-3 text-left">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'acution_bd5']}} :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="data" bindLabel="property_type_id"
                                        bindValue="property_type_id" [multiple]="false" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'acution_bd5']}}"
                                        [(ngModel)]="obj['property_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    <h6>Select Offer</h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="allOffer" bindLabel="auction_name" (change)='getPropertyData()' bindValue="auction_cd" [multiple]="false" placeholder="Select Offer" [(ngModel)]="obj['auction_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                </div>

                            </div><br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction10']}}</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="offerObj" bindLabel="pro_type"  bindValue="auction_xref_pro_type_id" (change)="getPro_nodata()" [multiple]="false" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'auction10']}}" [(ngModel)]="obj['auction_xref_pro_type_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                    </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-3 text-right">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction26']}}.</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="allFilteredProperty" bindLabel="assigned_property_number" bindValue="property_id" (change)='setofferProNo()' [multiple]="false" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'auction26']}}." [(ngModel)]="obj['property_no']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button (click)='show()' class="btn btn-outline-primary">{{auth.allLableShowObjProp[auth.language_cd+'acution_bd6']}}</button>
                                </div>
                            </div>
                            <br>
                            <hr>
                            <div class="example-container">
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)"
                                                placeholder="{{auth.allLableShowObjProp[auth.language_cd+'acution_bd7']}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4 text-center">

                                    </div>
                                    <div class="col-4 text-right">
                                        <button (click)="print1()" class="btn btn-outline-success">Download List</button>
                                    </div>

                                </div>

                                <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                                    <ng-container matColumnDef="s_no">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>{{auth.allLableShowObjProp[auth.language_cd+'acution_bd8']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_id}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="app_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                            {{auth.allLableShowObjProp[auth.language_cd+'acution_bd10']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- {{allUserObj[element.name]['name'] }}  -->
                                            {{ element.name}}
                                        </td>
                                    </ng-container>
                                     <ng-container matColumnDef="phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                            {{auth.allLableShowObjProp[auth.language_cd+'acution_bd11']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- {{allUserObj[element.party_id]['phone_no'] }}  -->
                                            {{ element.mobile_no}}
                                        </td>
                                    </ng-container>
                                       <ng-container matColumnDef="email">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                            {{auth.allLableShowObjProp[auth.language_cd+'acution_bd12']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- {{allUserObj[element.party_id]['email'] }}  -->
                                            {{ element.email}}
                                         </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bid_amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'acution_bd13']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.bidding_amount}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
