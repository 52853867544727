import { Component, ElementRef, ViewChild } from '@angular/core';
import { MainService } from '../../service/main.service';
import { ProfileService } from '../../service/profile.service';
import { AuthenticationService } from '../../../authentication.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: '[app-accounts-sidebar]',
  templateUrl: './accounts-sidebar.component.html',
  styleUrls: ['./accounts-sidebar.component.css']
})
export class AccountsSidebarComponent {

  user_id: any;
  imgURL: any;
  erpUser: any;
  isRotated: boolean = false;
  assigned_compoment: any = []
  sidebar: any = {}
  userInfo: any = { emp_name: 'Ram Prasad', designation: 'Accountant' }
  @ViewChild('rotateButtonElem') rotateButtonElem!: ElementRef;
  @ViewChild('rotateButtonElem2') rotateButtonElem2!: ElementRef;

  constructor(public auth: AuthenticationService, private router: Router, private profileService: ProfileService,
    private sanitizer: DomSanitizer, public mainService: MainService) {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.user_id = this.erpUser.user_id;
    this.assigned_compoment = this.erpUser.assigned_component;

    //Dashboard
    this.sidebar['dashbord'] = !this.assigned_compoment.includes('ACCOUNT-R1')

    //Report Start
    this.sidebar['report'] = !this.assigned_compoment.includes('ACCOUNT-R2')
    this.sidebar['ledger_report'] = !this.assigned_compoment.includes('ACCOUNT-R46')
    this.sidebar['bank_book'] = !this.assigned_compoment.includes('ACCOUNT-R47')
    this.sidebar['gst_report'] = !this.assigned_compoment.includes('ACCOUNT-R48')
    this.sidebar['tds_on_gst'] = !this.assigned_compoment.includes('ACCOUNT-R49')
    this.sidebar['tds'] = !this.assigned_compoment.includes('ACCOUNT-R50')
    this.sidebar['deduction'] = !this.assigned_compoment.includes('ACCOUNT-R51')
    this.sidebar['balance_sheet'] = !this.assigned_compoment.includes('ACCOUNT-R52')
    this.sidebar['inc_exp_report'] = !this.assigned_compoment.includes('ACCOUNT-R53')
    this.sidebar['fixed_assets_report'] = !this.assigned_compoment.includes('ACCOUNT-R54')
    this.sidebar['cogs'] = !this.assigned_compoment.includes('ACCOUNT-R55')
    this.sidebar['receipt_payment'] = !this.assigned_compoment.includes('ACCOUNT-R56')
    this.sidebar['fixed_assest_register'] = !this.assigned_compoment.includes('ACCOUNT-R57')
    this.sidebar['adhoc_report'] = !this.assigned_compoment.includes('ACCOUNT-R58')
    //Report End


    //Account Payable Start
    this.sidebar['acc_payble'] = !this.assigned_compoment.includes('ACCOUNT-R3')
    this.sidebar['bill'] = !this.assigned_compoment.includes('ACCOUNT-R33')
    this.sidebar['dep_bill'] = !this.assigned_compoment.includes('ACCOUNT-R34')
    this.sidebar['bank_payment'] = !this.assigned_compoment.includes('ACCOUNT-R35')
    this.sidebar['advice'] = !this.assigned_compoment.includes('ACCOUNT-R36')
    //Account Payable End

    //Account Receivable Start
    this.sidebar['acc_receivable'] = !this.assigned_compoment.includes('ACCOUNT-R4')
    this.sidebar['challan'] = !this.assigned_compoment.includes('ACCOUNT-R37')
    this.sidebar['dep_challan'] = !this.assigned_compoment.includes('ACCOUNT-R38')
    //Account Receivable End


    //Trasure Start
    this.sidebar['trasury'] = !this.assigned_compoment.includes('ACCOUNT-R5')
    this.sidebar['contra'] = !this.assigned_compoment.includes('ACCOUNT-R39')
    this.sidebar['bank_account'] = !this.assigned_compoment.includes('ACCOUNT-R40')
    this.sidebar['gateway_mid_config'] = !this.assigned_compoment.includes('ACCOUNT-R41')
    this.sidebar['closing_stock'] = !this.assigned_compoment.includes('ACCOUNT-R42')
    //Trasure End


    // Ledger Start
    this.sidebar['ledger'] = !this.assigned_compoment.includes('ACCOUNT-R6')
    this.sidebar['journal'] = !this.assigned_compoment.includes('ACCOUNT-R43')
    this.sidebar['trail_balance'] = !this.assigned_compoment.includes('ACCOUNT-R44')
    this.sidebar['manual_jrnl'] = !this.assigned_compoment.includes('ACCOUNT-R45')
    //Ledger End



    // Administration start
    this.sidebar['administration'] = !this.assigned_compoment.includes('ACCOUNT-R8')
    //Master screen Start
    this.sidebar['master'] = !this.assigned_compoment.includes('ACCOUNT-R7')
    this.sidebar['code_value'] = !this.assigned_compoment.includes('ACCOUNT-R11')
    this.sidebar['fin_year'] = !this.assigned_compoment.includes('ACCOUNT-R12')
    this.sidebar['organisation_hier'] = !this.assigned_compoment.includes('ACCOUNT-R13')
    this.sidebar['cost_center'] = !this.assigned_compoment.includes('ACCOUNT-R14')
    this.sidebar['business_event'] = !this.assigned_compoment.includes('ACCOUNT-R15')
    this.sidebar['acc_rule'] = !this.assigned_compoment.includes('ACCOUNT-R16')
    //MasterScreen End

    //Hierarchy start
    this.sidebar['hierarchy'] = !this.assigned_compoment.includes('ACCOUNT-R17')
    this.sidebar['bud_hier'] = !this.assigned_compoment.includes('ACCOUNT-R18')
    this.sidebar['act_hier'] = !this.assigned_compoment.includes('ACCOUNT-R19')
    this.sidebar['proj_hier'] = !this.assigned_compoment.includes('ACCOUNT-R20')
    this.sidebar['prod_hier'] = !this.assigned_compoment.includes('ACCOUNT-R21')
    this.sidebar['coa'] = !this.assigned_compoment.includes('ACCOUNT-R22')
    this.sidebar['acc_rule'] = !this.assigned_compoment.includes('ACCOUNT-R16')
    //Hierarchy end

    // Security Screen Start
    this.sidebar['security'] = !this.assigned_compoment.includes('ACCOUNT-R10')
    this.sidebar['role'] = !this.assigned_compoment.includes('ACCOUNT-R23')
    this.sidebar['user'] = !this.assigned_compoment.includes('ACCOUNT-R24')
    this.sidebar['data_auth'] = !this.assigned_compoment.includes('ACCOUNT-R25')
    this.sidebar['workflow'] = !this.assigned_compoment.includes('ACCOUNT-R26')
    //Security Screen End
    this.sidebar['party'] = !this.assigned_compoment.includes('ACCOUNT-R59')
    this.sidebar['work'] = !this.assigned_compoment.includes('ACCOUNT-R27')
    this.sidebar['proj_bank'] = !this.assigned_compoment.includes('ACCOUNT-R28') // Total access of court order
    this.sidebar['fixed_assets_mapping'] = !this.assigned_compoment.includes('ACCOUNT-R29')
    this.sidebar['deduction_mapping'] = !this.assigned_compoment.includes('ACCOUNT-R30')
    this.sidebar['gst'] = !this.assigned_compoment.includes('ACCOUNT-R31')
    this.sidebar['accoun_mapping'] = !this.assigned_compoment.includes('ACCOUNT-R32')
    //Administration End

  }
  hidden = true;
  accInfo = {}
  async ngOnInit() {

    if (this.erpUser.is_admin == 0) {
      this.hidden = true;
    } else {
      this.hidden = false
    }
    this.imgURL = './assets/img/admin-avatar.png';
    // await this.getUserImage();
    // await this.getUserInfo();
    // await this.getAccImage();
    // await this.getAccInfo();
  }


  home() {
    this.router.navigate(['/index'])
  }
  refreshCol() {
    var col = document.getElementsByClassName('collapse')

    for (let i = 0; i <= col.length; i++) {
      if (col[i].classList.contains('show')) {
        col[i].classList.remove('show');
      }
    }

  }
  refreshCol2() {
    var col = document.getElementsByClassName('collapse')

    for (let i = 0; i <= col.length; i++) {
      if (col[i].classList.contains('show')) {
        col[i].classList.remove('show');
      }
    }
  }
  rotateIcon(e: any) {
    // console.log('what is in e ' + e)
    // this.isRotated = !this.isRotated;
    // const angle = this.isRotated ? '270deg' : '0deg';
    // this.rotateButtonElem.nativeElement.style.transform = `rotate(${angle})`;
    // console.log('r?', this.isRotated)
  }



  // async getUserImage(){
  //   const res = await this.profileService.getImage(this.user_id);
  //   if (res) {
  //     const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
  //     this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  //     this.mainService.profileImageUrl=this.imgURL;

  //   }
  // }
  // async getUserInfo(){
  //   var resp= await this.profileService.getUserProfileInfo(this.user_id);
  //   if(resp['error']==false){

  //     this.userInfo=resp.data[0];
  //   }else{

  //   }
  // }
  // async getAccImage() {
  //   const res = await this.profileService.getAccImage(this.erpUser.b_acct_id);
  //   if (res) {
  //     const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
  //     this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  //     this.mainService.accountImageUrl = this.imgURL;
  //   }
  // }
  // async getAccInfo() {
  //   var resp = await this.profileService.getAccProfileInfo(this.erpUser.b_acct_id);
  //   if (resp['error'] == false) {
  //     this.accInfo = resp.data[0];
  //     this.mainService.accInfo = this.accInfo;
  //   } else {

  //   }

  // }

}
