<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Fixed Asset Register </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                Item List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Add Item </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="#tab-3" data-toggle="tab" (click)="refresh2()"><i
                                    class="ti-bar-chart"></i>
                                Task </a>
                        </li>

                    </ul>
                    <br><br>
                    <div class="tab-content">
                        <!-- <div class="row">
                            <div class="col-12 text-right">
                                <button class="btn btn-outline-success text-center"
                                (click)="print()">Print</button>
                            </div>
                        </div> -->
                        <br>
                        <div class="tab-pane fade  show active" id="tab-1">
                            <div class="mat-elevation-z8">
                                <table mat-table [dataSource]="dataSource" matSort #sortCol1="matSort">

                                    <ng-container matColumnDef="Sr.No">
                                        <th mat-header-cell *matHeaderCellDef> Sr No. </th>
                                        <td mat-cell *matCellDef="let element;let i = index"> {{(i+1)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="item_name">
                                        <th mat-header-cell *matHeaderCellDef> Item Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.item_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="uni_id_num">
                                        <th mat-header-cell *matHeaderCellDef> UNIQUE IDENTIFICATION NUMBER </th>
                                        <td mat-cell *matCellDef="let element"> {{element.uni_id_num}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pur_cost">
                                        <th mat-header-cell *matHeaderCellDef> PURCHASE COST </th>
                                        <td mat-cell *matCellDef="let element"> {{element.pur_cost}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pur_date">
                                        <th mat-header-cell *matHeaderCellDef> DATE OF PURCHASE </th>
                                        <td mat-cell *matCellDef="let element"> {{element.pur_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef> Status </th>
                                        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Action">
                                        <th mat-header-cell *matHeaderCellDef> Action </th>
                                        <td mat-cell *matCellDef="let element">
                                            <button *ngIf="element.status=='GENERATED'" class="btn btn-outline-success"
                                                (click)="view(element)">View</button>
                                            <button *ngIf="element.status=='GENERATED'" class="btn btn-outline-primary"
                                                (click)="addToApproval(element)">Add
                                                To Approval</button>
                                            <button *ngIf="element.status=='GENERATED'" class="btn btn-outline-danger"
                                                (click)="delete(element)">Delete</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>

                                <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                                    aria-label="Select page of periodic elements">
                                </mat-paginator>
                            </div>

                        </div>

                        <div class="tab-pane fade" id="tab-2">
                            <!-- <form name="form" (ngSubmit)="f.form.valid && getReceiptPaymnet()" #f="ngForm"> -->
                            <mat-horizontal-stepper [linear]="isLinear" #stepper>
                                <br>
                                <mat-step [stepControl]="first" label="Item Details">
                                    <form name="form" #f="ngForm">
                                        <div class="row">

                                            <div class="col-3">
                                                <h6>Item Name : </h6>
                                            </div>
                                            <div class="col-3 text-left ">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="itemObj['item_name']" name="item_name"
                                                    #item_name="ngModel"
                                                    [ngClass]="{ 'is-invalid': f.submitted && item_name.invalid }"
                                                    required>

                                                <!---------------- validation start-----------------------------  -->

                                                <div [class.border_green]="item_name.dirty && item_name.valid">
                                                </div>


                                                <div *ngIf="f.submitted && item_name.invalid" class="error-message">
                                                    <div *ngIf="item_name.errors?.['required']">Please Enter Item Name
                                                    </div>
                                                </div>
                                                <!---------------- validation end-----------------------------  -->


                                            </div>
                                            <div class="col-3">
                                                <h6> UNIQUE IDENTIFICATION NUMBER : </h6>
                                            </div>
                                            <div class="col-3 text-left">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="itemObj['uni_id_num']" name="uni_id_num"
                                                    #uni_id_num="ngModel"
                                                    [ngClass]="{ 'is-invalid': f.submitted && item_name.invalid }"
                                                    required>

                                                <!---------------- validation start-----------------------------  -->

                                                <div [class.border_green]="uni_id_num.dirty && uni_id_num.valid">
                                                </div>

                                                <div *ngIf="f.submitted && uni_id_num.invalid" class="error-message">
                                                    <div *ngIf="uni_id_num.errors?.['required']">Please Enter Unique
                                                        Identification Number
                                                    </div>
                                                </div>

                                                <!---------------- validation end-----------------------------  -->

                                            </div>
                                        </div>
                                        <br><br>

                                        <div class="row">
                                            <div class="col-3">
                                                <h6> PURCHASE COST : </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="itemObj['pur_cost']" name="pur_cost"
                                                    #pur_cost="ngModel"
                                                    [ngClass]="{'is-invalid':f.submitted && pur_cost.invalid}" required>


                                                <!---------------- validation start-----------------------------  -->

                                                <div [class.border_green]="pur_cost.dirty && pur_cost.valid">
                                                </div>


                                                <div *ngIf="f.submitted && pur_cost.invalid" class="error-message">
                                                    <div *ngIf="pur_cost.errors?.['required']">Please Enter Purchase Cost
                                                    </div>
                                                </div>
                                                <!---------------- validation end-----------------------------  -->

                                            </div>
                                            <div class="col-3">
                                                <h6>DATE OF PURCHASE : </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" class="form-control"
                                                    [(ngModel)]="itemObj['pur_date']"
                                                    (change)="findFinYear(itemObj['pur_date'])" name="pur_date"
                                                    #pur_date="ngModel"
                                                    [ngClass]="{'is-invalid':f.submitted && pur_date.invalid}" required>

                                                <!--------------------------------------------- validation Start ------------------- -->

                                                <!-- border color green -->
                                                <div [class.border_green]="pur_date.dirty && pur_date.valid">
                                                </div>

                                                <!-- error handling  -->
                                                <div *ngIf="f.submitted && pur_date.invalid" class="error-message">
                                                    <div *ngIf="pur_date.errors?.['required']">Please Fill Date Of Purchase
                                                    </div>
                                                </div>

                                                <!--------------------------------------------- validation End ------------------- -->

                                            </div>
                                        </div>

                                        <br><br>
                                        <div class="row">

                                            <div class="col-5">
                                                <h6> Remark : </h6>
                                            </div>
                                            <div class="col-7">
                                                <textarea class="form-control rounded-0" [(ngModel)]="itemObj['remark']"
                                                    name="remark" cols="32" rows="2" name="remark" #remark="ngModel"
                                                    [ngClass]="{'is-invalid':f.submitted && remark.invalid}"
                                                    required></textarea>


                                                <!--------------------------------------------- validation Start ------------------- -->

                                                <!-- border color green -->
                                                <div [class.border_green]="remark.dirty && remark.valid">
                                                </div>

                                                <!-- error handling  -->
                                                <div *ngIf="f.submitted && remark.invalid" class="error-message">
                                                    <div *ngIf="remark.errors?.['required']">Please Fill Remark </div>
                                                </div>

                                                <!--------------------------------------------- validation End ------------------- -->


                                            </div>
                                        </div>
                                        <br><br>


                                        <div class="row">
                                            <div class="col-3">
                                                <h6>ADDITION IN COST : </h6>
                                            </div>
                                            <div style="width: 7%;">
                                                <button class="btn btn-primary" (click)="addRow()"><i class="fa fa-plus"
                                                        aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                        <br><br>
                                        <div *ngFor="let ln of add_in_cost; let i = index;">
                                            <div class="row">
                                                <div class="col-2"></div>
                                                <div style="width: 1%;">
                                                    <h6><b> {{ln['item_id']}}.</b></h6>
                                                </div>
                                                &nbsp;
                                                <div class="col-2">
                                                    <ng-select [items]="mainService.codeValueTechObj['ACC204']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="Select Item" [(ngModel)]="ln['addition_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                        [closeOnSelect]="true" name="addition_{{i}}">
                                                    </ng-select>
                                                </div>
                                                &nbsp;
                                                <div class="text-center" class="col-1">
                                                    <input type="text" id=""
                                                        [(ngModel)]="ln['addition_amt']" class="form-control" name="addition_amt_{{i}}">
                                                </div>
                                                &nbsp;
                                                <div class="col-1">
                                                    <button class="btn btn-danger " (click)="deleteRowFiles(i)"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                            <br>
                                        </div>

                                        <br><br>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <!-- <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                    matStepperNext>Next</button> -->
                                                <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                    (click)="goToNext(f.form)">Next</button>
                                            </div>
                                        </div>
                                    </form>
                                </mat-step>

                                <mat-step [stepControl]="second" label="Item Location">
                                    <form name="form" #f2="ngForm">
                                        <div class="row">

                                            <div class="col-3">
                                                <h6>Item Name : </h6>
                                            </div>
                                            <div class="col-3 text-left ">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="itemObj['item_name']" name="item_name2" disabled>
                                            </div>
                                            <div class="col-3">
                                                <h6> UNIQUE IDENTIFICATION NUMBER : </h6>
                                            </div>
                                            <div class="col-3 text-left">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="itemObj['uni_id_num']" name="uni_id_num2" disabled>
                                            </div>
                                        </div>
                                        <br><br>
                                        <div class="row">
                                            <div class="col-3">
                                                <h6> PURCHASE COST : </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="itemObj['pur_cost']" name="pur_cost2" disabled>
                                            </div>
                                            <div class="col-3">
                                                <h6>DATE OF PURCHASE : </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" class="form-control"
                                                    [(ngModel)]="itemObj['pur_date']" name="pur_date2" disabled>
                                            </div>
                                        </div>

                                        <br><br>
                                        <div class="row">
                                            <div class="col-5">
                                                <h6> Remark : </h6>
                                            </div>
                                            <div class="col-7">
                                                <textarea class="form-control rounded-0" [(ngModel)]="itemObj['remark']"
                                                    name="remark2" cols="32" rows="2" disabled></textarea>
                                            </div>
                                        </div>
                                        <br><br>
                                        <div class="row" *ngIf="add_in_cost.length>0">
                                            <div class="col-2">
                                                <h6>ADDITION IN COST: </h6>
                                            </div>
                                        </div>
                                        <div *ngFor="let ln of add_in_cost; let i = index;">
                                            <div class="row">
                                                <div class="col-1">
                                                    <h6> {{ln['item_id']}}.</h6>
                                                </div>

                                                <div class="col-3 text-center">
                                                    <h6> {{ln['addition_cd']}} </h6>
                                                </div>
                                                <div class="col-1 text-center">
                                                    :
                                                </div>
                                                <div class="col-2 text-center">
                                                    <h6> {{ln['addition_amt']}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <br><br>
                                        <div class="row">
                                            <div class="col-3">
                                                <h6>ASSET LOCATION :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" [(ngModel)]="itemObj['asset_location']"
                                                    class="form-control" name='asset_location'
                                                    #asset_location="ngModel"
                                                    [ngClass]="{'is-invalid':f2.submitted && asset_location.invalid}"
                                                    required>


                                                <!--------------------------------------------- validation Start ------------------- -->

                                                <!-- border color green -->
                                                <div
                                                    [class.border_green]="asset_location.dirty && asset_location.valid">
                                                </div>

                                                <!-- error handling  -->
                                                <div *ngIf="f2.submitted && asset_location.invalid"
                                                    class="error-message">
                                                    <div *ngIf="asset_location.errors?.['required']">Please Enter Asset
                                                        Location
                                                    </div>
                                                </div>

                                                <!--------------------------------------------- validation End ------------------- -->

                                            </div>
                                            <div class="col-3">

                                                <h6>PERSON RESPONSIBLE :</h6>
                                            </div>
                                            <div class="col-3">

                                                <input type="text" [(ngModel)]="itemObj['resp_person']"
                                                    class="form-control" name="resp_per" #resp_per="ngModel"
                                                    [ngClass]="{'is-invalid':f2.submitted && resp_per.invalid}"
                                                    required>

                                                <!--------------------------------------------- validation Start ------------------- -->

                                                <!-- border color green -->
                                                <div [class.border_green]="resp_per.dirty && resp_per.valid">
                                                </div>

                                                <!-- error handling  -->
                                                <div *ngIf="f2.submitted && resp_per.invalid" class="error-message">
                                                    <div *ngIf="resp_per.errors?.['required']">Please Fill From Date </div>
                                                </div>

                                                <!--------------------------------------------- validation End ------------------- -->
                                            </div>
                                        </div>

                                        <br><br>
                                        <div class="row">
                                            <div class="col-12 text-center">

                                                <button mat-button matStepperPrevious
                                                    class="btn btn-outline-success text-center">Back</button>
                                                &nbsp;&nbsp;
                                                <!-- <button mat-button matStepperNext
                                                    class="btn btn-outline-primary text-center">Next</button> -->
                                                <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                    (click)="goToNextSecond(f2.form)">Next</button>
                                            </div>
                                        </div>
                                    </form>
                                </mat-step>

                                <mat-step [stepControl]="third" label="Accounts">
                                    <form name="form" #f3="ngForm">
                                        <div class="row">

                                            <div class="col-3">
                                                <h6>Item Name : </h6>
                                            </div>
                                            <div class="col-3 text-left ">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="itemObj['item_name']" name="item_name3" disabled>
                                            </div>
                                            <div class="col-3">
                                                <h6> UNIQUE IDENTIFICATION NUMBER : </h6>
                                            </div>
                                            <div class="col-3 text-left">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="itemObj['uni_id_num']" name="uni_id_num3" disabled>
                                            </div>
                                        </div>
                                        <br><br>
                                        <div class="row">
                                            <div class="col-3">
                                                <h6> PURCHASE COST : </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="itemObj['pur_cost']" name="pur_cost3" disabled>
                                            </div>
                                            <div class="col-3">
                                                <h6>DATE OF PURCHASE : </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" class="form-control"
                                                    [(ngModel)]="itemObj['pur_date']" name="pur_date3" disabled>
                                            </div>
                                        </div>

                                        <br><br>
                                        <div class="row">
                                            <div class="col-5">
                                                <h6> Remark : </h6>
                                            </div>
                                            <div class="col-7">
                                                <textarea class="form-control rounded-0" [(ngModel)]="itemObj['remark']"
                                                    name="remark3" cols="32" rows="2" disabled></textarea>
                                            </div>
                                        </div>
                                        <br><br>
                                        <div class="row" *ngIf="add_in_cost.length>0">
                                            <div class="col-3">
                                                <h6>ADDITION IN COST : </h6>
                                            </div>

                                        </div>

                                        <div *ngFor="let ln of add_in_cost; let i = index;">
                                            <div class="row">
                                                <div class="col-1">
                                                    <h6> {{ln['item_id']}}.</h6>
                                                </div>

                                                <div class="col-3 text-center">
                                                    <h6> {{ln['addition_cd']}} </h6>
                                                </div>
                                                <div class="col-1 text-center">
                                                    :
                                                </div>
                                                <div class="col-2 text-center">
                                                    <h6> {{ln['addition_amt']}}</h6>
                                                </div>
                                            </div>
                                            <br>
                                        </div>

                                        <br><br>
                                        <div class="row">
                                            <div class="col-3">
                                                <h6>ASSET LOCATION :</h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" [(ngModel)]="itemObj['asset_location']" name="2"
                                                    class="form-control" disabled>
                                            </div>
                                            <div class="col-3">

                                                <h6>PERSON RESPONSIBLE :</h6>
                                            </div>
                                            <div class="col-3">
                                                <!-- <ng-select [items]="addCostArr" bindLabel="name" bindValue="branch"
                                            [multiple]="false" placeholder="Select Item"
                                            [(ngModel)]="itemObj['resp_person']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true">
                                        </ng-select> -->
                                                <input type="text" name="" [(ngModel)]="itemObj['resp_person']"
                                                    name="resp_per2" class="form-control" disabled>
                                            </div>
                                        </div>
                                        <br><br>

                                        <fieldset>
                                            <br>
                                            <legend>
                                                <h6> Asset Booking </h6>
                                            </legend>

                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>Depreciation Method : </h6>
                                                </div>
                                                <div class="col-3">

                                                    <ng-select [items]="mainService.codeValueTechObj['ACC205']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="Select Item" [(ngModel)]="itemObj['dep_method']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                        [closeOnSelect]="true" name="dep_method" #dep_method="ngModel"
                                                        [ngClass]="{'is-invalid':f3.submitted && dep_method.invalid}"
                                                        required>
                                                    </ng-select>

                                                    <!--------------------------------------------- validation Start ------------------- -->

                                                    <!-- border color green -->
                                                    <div [class.selection-box]="dep_method.dirty && dep_method.valid">
                                                    </div>

                                                    <!-- error handling  -->
                                                    <div *ngIf="f3.submitted && dep_method.invalid"
                                                        class="error-message">
                                                        <div *ngIf="dep_method.errors?.['required']">Please Select
                                                            Depreciation Method
                                                        </div>
                                                    </div>

                                                    <!--------------------------------------------- validation End ------------------- -->

                                                </div>

                                                <div class="col-3">
                                                    <h6>Credit Chart of Account : </h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="all_coa" bindLabel="leaf_value"
                                                        bindValue="leaf_code" [multiple]="false"
                                                        placeholder="Select Item"
                                                        [(ngModel)]="itemObj['cr_chart_of_acct']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                        [closeOnSelect]="true" name="cr_coa" #cr_coa="ngModel"
                                                        [ngClass]="{'is-invailid':f3.submitted && cr_coa.invalid}"
                                                        required>
                                                    </ng-select>



                                                    <!--------------------------------------------- validation Start ------------------- -->

                                                    <!-- border color green -->
                                                    <div [class.selection-box]="cr_coa.dirty && cr_coa.valid">
                                                    </div>

                                                    <!-- error handling  -->
                                                    <div *ngIf="f3.submitted && cr_coa.invalid" class="error-message">
                                                        <div *ngIf="cr_coa.errors?.['required']">Please Select Chart Of
                                                            Account
                                                        </div>
                                                    </div>

                                                    <!--------------------------------------------- validation End ------------------- -->
                                                </div>
                                            </div>

                                            <br><br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>Class of Fixed Asset : </h6>
                                                </div>
                                                <div class="col-3">


                                                    <ng-select [items]="fixed_coa" bindLabel="leaf_value"
                                                        bindValue="leaf_code" [multiple]="false"
                                                        placeholder="Fixed Assets COA"
                                                        [(ngModel)]="itemObj['db_chart_of_acct']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                        [closeOnSelect]="true" name="fix" #fix_coa="ngModel"
                                                        [ngClass]="{'is-invailid':f3.submitted && fix_coa.invalid}"
                                                        required>
                                                    </ng-select>
                                                    <!--------------------------------------------- validation Start ------------------- -->

                                                    <!-- border color green -->
                                                    <div [class.selection-box]="fix_coa.dirty && fix_coa.valid">
                                                    </div>

                                                    <!-- error handling  -->
                                                    <div *ngIf="f3.submitted && fix_coa.invalid" class="error-message">
                                                        <div *ngIf="fix_coa.errors?.['required']">Please Select Fixed Assets
                                                            Chart Of Account
                                                        </div>
                                                    </div>

                                                    <!--------------------------------------------- validation End ------------------- -->

                                                </div>

                                                <div class="col-3">
                                                    <h6>Party/Vendor : </h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="all_party" bindLabel="party_leagal_name"
                                                        bindValue="le_id" [multiple]="false" placeholder="Select Item"
                                                        [(ngModel)]="itemObj['party_le_id']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                        name="party" #party="ngModel"
                                                        [ngClass]="{'is-invailid':f3.submitted && party.invalid}"
                                                        required>
                                                    </ng-select>

                                                    <!--------------------------------------------- validation Start ------------------- -->

                                                    <!-- border color green -->
                                                    <div [class.selection-box]="party.dirty && party.valid">
                                                    </div>

                                                    <!-- error handling  -->
                                                    <div *ngIf="f3.submitted && party.invalid" class="error-message">
                                                        <div *ngIf="party.errors?.['required']">Please Select Chart Of
                                                            Account
                                                        </div>
                                                    </div>

                                                    <!--------------------------------------------- validation End ------------------- -->

                                                </div>
                                            </div>
                                        </fieldset>
                                        <br><br>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <button mat-button matStepperPrevious
                                                    class="btn btn-outline-success text-center">Back</button>
                                                &nbsp;&nbsp;
                                                <!-- <button class="btn btn-outline-primary text-center"
                                                    (click)="f3.form.valid && submit()">Submit</button> -->
                                                <button class="btn btn-outline-primary text-center"
                                                    (click)="gotoSubmit(f3.form)">Submit</button>





                                                <!-- <button type="reset" id="clean"
                                                    class="btn btn-outline-success textCenter d-none">Reset</button> -->
                                            </div>
                                        </div>
                                    </form>
                                </mat-step>

                            </mat-horizontal-stepper>
                            <!-- </form> -->
                        </div>

                        <div class="tab-pane fade" id="tab-3">

                            <div class="mat-elevation-z8">
                                <table mat-table [dataSource]="dataSource2" matSort #sortCol2="matSort">

                                    <ng-container matColumnDef="Sr.No">
                                        <th mat-header-cell *matHeaderCellDef> Sr No. </th>
                                        <td mat-cell *matCellDef="let element;let i = index"> {{(i+1)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="item_name">
                                        <th mat-header-cell *matHeaderCellDef> Item Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.item_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="uni_id_num">
                                        <th mat-header-cell *matHeaderCellDef> UNIQUE IDENTIFICATION NUMBER </th>
                                        <td mat-cell *matCellDef="let element"> {{element.uni_id_num}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pur_cost">
                                        <th mat-header-cell *matHeaderCellDef> PURCHASE COST </th>
                                        <td mat-cell *matCellDef="let element"> {{element.pur_cost}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pur_date">
                                        <th mat-header-cell *matHeaderCellDef> DATE OF PURCHASE </th>
                                        <td mat-cell *matCellDef="let element"> {{element.pur_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef> Status </th>
                                        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Action">
                                        <th mat-header-cell *matHeaderCellDef> Action </th>
                                        <td mat-cell *matCellDef="let element">

                                            <button *ngIf="element.status=='PENDING'" class="btn btn-outline-danger"
                                                (click)="reject(element)">Reject</button>
                                            <button *ngIf="element.status=='PENDING'" class="btn btn-outline-primary"
                                                (click)="addToApproval(element)">Add To Approval</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                                </table>

                                <mat-paginator #paginator2 [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                                    aria-label="Select page of periodic elements">
                                </mat-paginator>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>


<div class="modal" id="addToApprove">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment10']}}
                    <!--Remark--> :
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Forworded To : </h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="final_arr" bindLabel="desc" bindValue="user_id" [multiple]="false"
                            [(ngModel)]="approval_data['user_id']" placeholder="Select Person" [selectableGroup]="true"
                            [selectableGroupAsModel]="false" [closeOnSelect]="true">
                        </ng-select>
                    </div>
                    <div class="col-4"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Forworded Message : </h6>
                    </div>
                    <div class="col-4">
                        <textarea type="date" class="form-control" placeholder="Your Message..."
                            [(ngModel)]="approval_data['forward_msg']"></textarea>
                    </div>
                    <div class="col-4">

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-outline-success" (click)="approveSubmit()">Submit </button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close </button>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="reject">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment10']}}
                    <!--Remark--> :
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Forworded To : </h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="final_arr" bindLabel="desc" bindValue="user_id" [multiple]="false"
                            [(ngModel)]="reject_data['f_user_id']" placeholder="Select Person" [selectableGroup]="true"
                            [selectableGroupAsModel]="false" [closeOnSelect]="true">
                        </ng-select>
                    </div>
                    <div class="col-4"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Forworded Message : </h6>
                    </div>
                    <div class="col-4">
                        <textarea type="date" class="form-control" placeholder="Your Message..."
                            [(ngModel)]="reject_data['forward_msg']"></textarea>
                    </div>
                    <div class="col-4">

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-outline-success" (click)="rejectSubmit()">Submit </button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close </button>
            </div>
        </div>
    </div>
</div>
