<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                <div class="ibox-title">
             LATE SITTING LIST
                </div>
            </div>
            <div class="ibox-body">
                <ul class="nav nav-tabs tabs-line">

                    <li class="nav-item">
                        <a class="nav-link active" href="#tab-1" (click)="getMorningLateComming()" data-toggle="tab"><i class="ti-settings"></i>
                          MORNING LATE LIST </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " href="#tab-2" data-toggle="tab" (click)="getEveLateSitting()"><i class="ti-bar-chart"></i>
                            EVENING LATE SITTING </a>
                    </li>
                </ul>



                <div class="tab-content">
 <div class="tab-pane fade  show active" id="tab-1">
                        <br>

                    <div class="row">
               <div class="col-6">
                    <mat-form-field style="margin-left: 2%">
                         <input matInput (keyup)="applyFilter1($any($event.target).value)"
                       placeholder="Filter">
                     </mat-form-field>
                      </div>
                       </div>

    <div class=" example-container">
        <table mat-table [dataSource]="dataSource1" matSort #sortCol2="matSort">
            <ng-container matColumnDef="s_no">
                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Work Id

                </th>
                <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                    class="right">
                    <!-- {{ element['work_id'] }}</td> -->
                    {{i+1}}
            </ng-container>

            <ng-container matColumnDef="emp_name">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                    Name

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ element.emp_name }} </td>
            </ng-container>


            <!-- <ng-container matColumnDef="purpose">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Purpose

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ element.purpose }} </td>
            </ng-container> -->

            <ng-container matColumnDef="start_date">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Date

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ mainService.dateformatchange(element.p_date)}} </td>
            </ng-container>
            <!-- <ng-container matColumnDef="end_date">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>End Date

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ mainService.dateformatchange(element.end_date)}} </td>
            </ng-container> -->
            <ng-container matColumnDef="mor_late">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Late In Morning

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{element.mor_late_time}}
                   <!-- <input disabled type="time"  [(ngModel)]="element.start_time"> </td> -->
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                       <!-- morning late -->
                    <button class="btn btn-info"
                        (click)="allow(element)">Allow</button>
                    <!-- <button class="btn btn-info"
                        (click)="downloadPDF(element,1)">Download</button> -->

                </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
            </tr>
        </table>
        <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
        </mat-paginator>
    </div>
 </div>

                    <div class="tab-pane fade " id="tab-2">
                        <br>
                    <div class="row">
                        <div class="col-6">
                             <mat-form-field style="margin-left: 2%">
                                  <input matInput (keyup)="applyFilter2($any($event.target).value)"
                                placeholder="Filter">
                              </mat-form-field>
                               </div>
                                </div>

                                <div class=" example-container">
                                    <table mat-table [dataSource]="dataSource2" matSort #sortCol2="matSort">
                                        <ng-container matColumnDef="work_id">
                                            <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Work Id

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                                class="right">
                                                {{ element['work_id'] }}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="emp_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Name

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.emp_name }} </td>
                                        </ng-container>


                                        <ng-container matColumnDef="purpose">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Purpose

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.purpose }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="start_date">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Start Date

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ mainService.dateformatchange(element.start_date)}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="end_date">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>End Date

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ mainService.dateformatchange(element.end_date)}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="eve_late">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Extra In Evening

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{element.eve_late_sitting}}

                                        </ng-container>
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                                <button class="btn btn-info"
                                                    (click)="downloadPDF(element,2)">Download</button>

                                            </td>
                                        </ng-container>



                                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                                        </tr>
                                    </table>
                                    <mat-paginator #paginator2 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                    </mat-paginator>
                                </div>


                </div>

              </div>
            </div>
        </div>
    </div>

</div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
