<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment1']}}
                        <!--Manage Bank Payment-->
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment2']}}
                                <!--Bank Payment List-->
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment3']}}
                                <!--GENERATE BP-->
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <div class="row ">
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" style="margin-left: 2px;"
                                        (click)="addRemark()">Generate Advice</button>
                                </div>
                            </div>
                            <br>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="select_flag">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment5']}}
                                            <!--Select-->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            <input class="form-check-input" type="checkbox"
                                                [(ngModel)]="element.select_flag">
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment6']}}
                                            <!--Party ID-->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.party_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            Bank Payment ID
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>



                                    <ng-container matColumnDef="party_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment7']}}
                                            <!--Party Name-->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{party[element.party_id]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment8']}}
                                            <!--Amount-->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.bp_amount}}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="bp_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment9']}}
                                            <!--BP Date-->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- {{element.bp_date}} -->
                                            <p *ngIf="element.bp_date">{{mainService.dateFormatChange(element.bp_date)}}
                                            </p>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="remark">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment10']}}
                                            <!--Remark-->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.remark}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment11']}}
                                            <!--Status-->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.status}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment12']}}
                                            <!--Action-->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <!-- <button class="btn btn-primary" *ngIf="element.status=='GENERATED'" (click)="schedule(element)">Schedule</button> -->
                                            <button class="btn btn-primary" *ngIf="element.status=='GENERATED'"
                                                (click)="changeBPStatus(element)">Approve</button>
                                            <button class="btn btn-danger" *ngIf="element.status=='GENERATED'"
                                                (click)="delete(element)">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment14']}}
                                                <!--Delete-->
                                            </button>
                                            <!-- <button class="btn btn-primary" *ngIf="element.status=='SCHEDULED' ||  element.status=='REJECTED'" (click)="status(element)">View Status</button> -->

                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="list"></tr>
                                    <tr mat-row *matRowDef="let row; columns: list;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                            <br>

                        </div>

                        <div class="tab-pane fade" id="tab-2">
                            <form name="form" (ngSubmit)="f.form.valid" #f="ngForm">

                                <br>
                                <div class="row">
                                    <div class="col-3">

                                    </div>


                                    <div class="col-3">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment16']}}
                                            <!--Select Chart Of Account--> : <span style="color: red;">*</span>
                                        </h6>
                                    </div>
                                    <div class="col-3">

                                        <ng-select [items]="allChartOfAccount" bindLabel="leaf_value"
                                            bindValue="leaf_code" [multiple]="false"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment16']}}"
                                            [(ngModel)]="search_obj['chart_of_account']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="true" name="coa" #coa="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && coa.invalid }" required>
                                        </ng-select>

                                        <!-- validation start  -->

                                        <div [class.selection-box]="coa.dirty && coa.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && coa.invalid" class="error-message">
                                            <div *ngIf="coa.errors?.['required']">Please Select Chart Of Account
                                            </div>
                                        </div>
                                        <!-- validation end  -->


                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment17']}}
                                            <!--Select Party--> :
                                        </h6>
                                    </div>
                                    <div class="col-3">


                                        <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                                            [multiple]="false"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment17']}}"
                                            [(ngModel)]="search_obj['party_id']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="true" name="party_id">
                                            <!-- #party_id="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && party_id.invalid }" required> -->
                                        </ng-select>

                                        <!-- validation start  -->

                                        <!-- <div [class.selection-box]="party_id.dirty && party_id.valid">
                                        </div> -->

                                        <!-- error handling  -->
                                        <!-- <div *ngIf="f.submitted && party_id.invalid" class="error-message">
                                            <div *ngIf="party_id.errors?.['required']">Please select Party
                                            </div>
                                        </div> -->
                                        <!-- validation end  -->


                                    </div>
                                    <div class="col-3">
                                        <button (click)="searchByParty()" type="submit"
                                            class="btn btn-primary">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment18']}}
                                            <!--Search-->
                                        </button>

                                    </div>
                                </div>

                                <br>
                                <div class="row">
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">
                                        <h6>Select JV Number:</h6>
                                    </div>
                                    <div class="col-3">
                                        <!-- <input type="number" [(ngModel)]="search_obj['jrnl_id']" class="form-control" /> -->

                                        <ng-select [items]="allJVId" bindLabel="jrnl_id" bindValue="jrnl_id"
                                            [multiple]="false" placeholder="Select JV Number"
                                            [(ngModel)]="search_obj['jrnl_id']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="true" name="jrnl_id">
                                            <!--    name="jrnl_id" #jrnl_id="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && jrnl_id.invalid }" required> -->
                                        </ng-select>


                                        <!-- validation start  -->

                                        <!-- <div [class.selection-box]="jrnl_id.dirty && jrnl_id.valid">
                                        </div> -->

                                        <!-- error handling  -->
                                        <!-- <div *ngIf="f.submitted && jrnl_id.invalid" class="error-message">
                                            <div *ngIf="jrnl_id.errors?.['required']">Please select Journal id
                                            </div>
                                        </div> -->
                                        <!-- validation end  -->





                                    </div>
                                    <div class="col-3">
                                        <button (click)="searchByJVNumber()" type="submit"
                                            class="btn btn-primary">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment18']}}
                                            <!--Search-->
                                        </button>

                                    </div>

                                </div>
                            </form>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                    </div>
                                </div>
                                <hr>
                                <div class="row" *ngIf='show_selected_table==1'>
                                    <div class="col-4 text-right">
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment10']}}
                                            <!--Remark--> :
                                        </h6>
                                    </div>
                                    <div class="col-4">
                                        <textarea type="date" class="form-control" placeholder=""
                                            [(ngModel)]="search_obj['remark']"></textarea>
                                    </div>
                                    <div class="col-4">

                                    </div>
                                </div>
                                <br>

                                <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                                    aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content"
                                            style="height: 80%; width: 90%; margin-left: 5%; margin-top: 100px;">

                                            <table class="table ">
                                                <thead style="background-color:#1589FF ; color: white;">
                                                    <tr>
                                                        <th scope="col">
                                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment6']}}
                                                            <!--Party ID-->
                                                        </th>
                                                        <th scope="col">
                                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment7']}}
                                                            <!--Party Name-->
                                                        </th>
                                                        <th scope="col">
                                                            JV Number
                                                        </th>
                                                        <th scope="col">
                                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment8']}}
                                                            <!--Amount-->
                                                        </th>
                                                        <th>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment5']}}
                                                            <!--Select-->
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let i of popUpTableData;let j=index">
                                                        <td>{{i['party_id']}}</td>
                                                        <td>{{i['party_leagal_name']}}</td>
                                                        <td>{{i['jrnl_id']}}</td>
                                                        <td style="text-align: center;">{{i['txn_amt']}}</td>
                                                        <td> <input class="form-check-input"
                                                                style="width:20px; text-align: center;" type="checkbox"
                                                                [(ngModel)]="i['select']">
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            <br>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary"
                                                    data-dismiss="modal">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment19']}}
                                                    <!--Close-->
                                                </button>
                                                <button type="button" class="btn btn-primary" data-dismiss="modal"
                                                    (click)="selected_table()">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment20']}}
                                                    <!--Save changes-->
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <br>

                                <!-- =========== -->
                                <!-- [(ngModel)]="sel['chart_of_account']" -->
                                <table class="table" *ngIf="show_selected_table==1">
                                    <thead style="background-color: #1589FF; color: white;">
                                        <tr>
                                            <th scope="col">Account Head</th>
                                            <th scope="col">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment6']}}
                                                <!--Party ID-->
                                            </th>
                                            <th scope="col">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment7']}}
                                                <!--Party Name-->
                                            </th>
                                            <th>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment9']}}
                                                <!--BP Date-->
                                            </th>
                                            <th>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment21']}}
                                                <!--Party Bank-->
                                            </th>
                                            <th> Bank Account </th>
                                            <th scope="col">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment8']}}
                                                <!--Amount-->
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let i of selected_list;let j=index">
                                            <td>{{allChartOfAccountObj[i['chart_of_account']]}}</td>
                                            <td>{{i['party_id']}}</td>
                                            <td>{{i['party_leagal_name']}}</td>
                                            <td *ngIf="i['bp_date']">{{mainService.dateFormatChange(i['bp_date'])}}
                                            </td>
                                            <td>{{i['party_acct_no']}} - {{i['bank_name']}} - {{i['bank_branch_name']}}
                                                -
                                                {{i['ifsc_code']}}
                                            </td>
                                            <td>
                                                <ng-select [items]="BankChartOfAccount" bindLabel="desc"
                                                    bindValue="relation" [multiple]="false" placeholder="Bank Account"
                                                    [(ngModel)]="i['relation']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" name="chart_of_account"
                                                    [disabled]="i['desabled']">
                                                    <!--Select Chart Of Account-->
                                                </ng-select>
                                            </td>
                                            <td><input type="number" class="form-control" [(ngModel)]="i['txn_amt']">
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center" *ngIf="show_selected_table==1">
                                        <button (click)="submit()" class="btn btn-primary">
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment23']}}
                                            <!--Submit-->
                                        </button>
                                    </div>
                                </div>
                            </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="myModal_Status">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment11']}}
                    <!--Status-->
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="container">
                    <a *ngFor="let ob of statusArr;let i=index">
                        <div class="step completed" *ngIf="ob['status']=='APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;"
                                    *ngIf="i <statusArr.length-1"></div>
                            </div>

                            <div class="content">
                                {{UserObj[levelOfApproval[ob['level_of_approval']]['user_id']]}}
                            </div>
                        </div>
                        <div class="step" *ngIf="ob['status']=='REJECTED'">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>
                            <div class="content">
                                {{UserObj[levelOfApproval[ob['level_of_approval']]['user_id']]}}
                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob['status']=='PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{UserObj[levelOfApproval[ob['level_of_approval']]['user_id']]}}
                            </div>
                        </div>
                    </a>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"
                        data-dismiss="modal">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment19']}}
                        <!--Close-->
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment10']}}
                    <!--Remark--> :
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-4 text-right">
                        {{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment10']}}
                        <!--Remark--> :
                    </div>
                    <div class="col-4">
                        <textarea type="date" class="form-control" placeholder="" [(ngModel)]="remark"></textarea>

                    </div>
                    <div class="col-4">

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button (click)="GenerateAdvice()"
                            class="btn btn-primary">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment23']}}
                            <!---- Submit-->
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger"
                    data-dismiss="modal">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Payment19']}}
                    <!--Close-->
                </button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>Loading....</ngx-spinner>
