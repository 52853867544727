<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- LIC Deduction Report -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report1']}}</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                               <!-- LIC Report -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report2']}}</a>
                        </li>



                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Financial Year : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report3']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="licObj['fin_year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Month : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report4']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="licObj['month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-12  text-center">
                                    <button class="btn btn-primary" (click)="getPfDed()"><!-- SUBMIT -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report5']}}</button> &nbsp;&nbsp;

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Total LIC Amount : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report6']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" [(ngModel)]="totalLIC" disabled>

                                </div>
                                <div class="col-4 text-right">


                                </div>
                            </div>
                            <div id="p" [hidden]="true">
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img [src]="mainService.accountImageUrl" class="img-circle" width="90px" />

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h4>{{mainService.accInfo['account_name']}}
                                            ({{mainService.accInfo['account_short_name']}})
                                        </h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>
                                           LIC Report for {{mainService.codeValueShowObj['HR0024'][licObj['month']]}} / {{mainService.codeValueShowObj['HR0023'][licObj['fin_year']]}}
                                        </h6>
                                    </div>
                                </div>

                                <table class="table" style="border: black;">
                                    <thead class="thead-light">
                                        <tr>
                                            <th><!-- Serial No. -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report7']}}</th>
                                            <th><!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report8']}}</th>
                                            <th><!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report9']}}
                                            </th>
                                            <th><!-- Designation -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report10']}}
                                            </th>
                                            <th><!-- LIC1 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report11']}}
                                            </th>

                                            <th><!-- LIC2 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report12']}}
                                            </th>
                                            <th><!-- LIC3 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report13']}}
                                            </th>
                                            <th><!-- LIC4 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report14']}}
                                            </th>
                                            <th><!-- LIC5 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report15']}}
                                            </th>
                                            <th><!-- LIC6 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report16']}}
                                            </th>
                                            <th><!-- LIC7 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report17']}}
                                            </th>
                                            <th><!-- Total Amount -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report18']}}
                                            </th>




                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ob of lic; let i= index">
                                            <td>
                                                <h6>{{i+1}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{mainService.accInfo['account_short_name']}}{{getNumberFormat(ob['emp_id'])}}</h6>


                                            </td>
                                            <td>
                                                <h6>{{ob['emp_name']}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['designation_code']}}</h6>
                                            </td>
                                            <td>
                                                <!-- <h6>{{ob['LIC1']}}</h6> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ob['LIC1']}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{allLicObj[ob['emp_id']][ob['LIC1']]}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <!-- <h6>{{ob['LIC2']}}</h6> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ob['LIC2']}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{allLicObj[ob['emp_id']][ob['LIC2']]}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <!-- <h6>{{ob['LIC3']}}</h6> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ob['LIC3']}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{allLicObj[ob['emp_id']][ob['LIC3']]}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <!-- <h6>{{ob['LIC4']}}</h6> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ob['LIC4']}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{allLicObj[ob['emp_id']][ob['LIC4']]}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <!-- <h6>{{ob['LIC5']}}</h6> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ob['LIC5']}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{allLicObj[ob['emp_id']][ob['LIC5']]}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <!-- <h6>{{ob['LIC6']}}</h6> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ob['LIC6']}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{allLicObj[ob['emp_id']][ob['LIC6']]}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <!-- <h6>{{ob['LIC7']}}</h6> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{ob['LIC7']}}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        {{allLicObj[ob['emp_id']][ob['LIC7']]}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <h6>{{ob['total']}}</h6>
                                            </td>
                                            <td>
                                                <h6></h6>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <hr>
                                <div class="row">
                                    <div class="col-11 text-right">
                                        <h4><!-- Total LIC Amount: -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report6']}} <b>{{totalLIC}}</b>
                                        </h4>
                                    </div>
                                </div>
                                <hr>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-3 text-right">
                                    <button class="btn btn-primary" (click)="print()"><!-- Print -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report19']}}</button> &nbsp;&nbsp;
                                </div>
                                <div class="col-3 text-right">
                                    <button class="btn btn-primary" (click)="export()"><!-- Export -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report20']}}</button> &nbsp;&nbsp;
                                </div>


                            </div>


                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="s_no">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- S No. -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report21']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{i+1}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{mainService.accInfo['account_short_name']}}{{getNumberFormat(element.emp_id)}}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report9']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="designation">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Designation -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report10']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.designation_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="LIC1">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- LIC1 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report11']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.LIC1 }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="LIC2">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- LIC2 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report12']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.LIC2 }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="LIC3">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- LIC3 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report13']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.LIC3 }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="LIC4">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- LIC4 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report14']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.LIC4 }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="LIC5">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- LIC5 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report15']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.LIC5 }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="LIC6">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- LIC6 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report16']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.LIC6 }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="LIC7">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- LIC7 -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report17']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.LIC7 }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Total Amount -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report18']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.total }} </td>
                                    </ng-container>

                                    <!--  <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-warning" (click)="openUpdate(element)">UPDATE</button>
                                        </td>
                                    </ng-container> -->

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner><!-- Loading.... -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report22']}</ngx-spinner>
