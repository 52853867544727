<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Vendor List</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item" *ngIf="list">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                All Vendor List</a>
                        </li>

                        <li class="nav-item" *ngIf="details">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"></i> Vendor
                                Details </a>
                        </li>

                    </ul>
                    <div class="tab-content">

                        <div *ngIf="list" id="tab-1">

                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4"></div>
                                <div class="col-4">
                                </div>
                            </div>
                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource" matSort #sortCol2="matSort">


                                    <ng-container matColumnDef="Sr.No">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Sr No.
                                        </th>
                                        <td mat-cell *matCellDef="let element let i = index"> {{i+1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="vendor_id">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Vendor ID
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor_id}}

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="vender_type">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>Vendor Type
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor_type}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="category">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Vendor
                                            Category

                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.category}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="vender_name">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Vendor
                                            Name

                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor_name}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="reg_date">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            Registration Date
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.reg_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="life_cycle_status">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Status
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.life_cycle_status}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Action
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="width: 20%;">
                                            <button class="btn btn-outline-info" (click)="viewDetails(element)">View
                                                Details</button>
                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginate [pageSizeOptions]="[5,10, 15]" showFirstLastButtons>
                                </mat-paginator>
                            </div>

                        </div>

                        <div *ngIf="details" id="tab-2">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="backToList()">Back To List</button>
                                </div>
                            </div>




                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 7rem;">
                                    <h6>Basic Details</h6>
                                </legend>


                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Mobile Number: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['mobile_no']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Email ID : <span class="text-danger">*</span> </h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['email']}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Vender Type : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['vendor_type']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> Category : </h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['category']}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Vender Name : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['vendor_name']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>PAN : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['pan']}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>GSTIN : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['gstin']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> District : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['district']}}
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>State : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['state']}}
                                    </div>
                                </div>
                                <br>
                            </fieldset>
                            <br> <br>

                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 13rem;">
                                    <h6>BANK ACCOUNT DETAILS</h6>
                                </legend>
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>IFSC : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['ifsc']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Bank Name : <span class="text-danger">*</span> </h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['bank_name']}}
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Account Number : </h6>
                                    </div>
                                    <div class="col-3">
                                        {{vendor_details['account_no']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> Account Holder Name : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-1 d-flex">
                                        {{vendor_details['account_holder_name']}}
                                    </div>
                                </div>
                                <br>


                            </fieldset>
                            <br> <br>

                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 13rem;">
                                    <h6>UPLOADED DOCUMENTS</h6>
                                </legend>



                                <br>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-3 text-center" text-center>
                                        <h6>1. PAN Card : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3 text-center">
                                        <button class="btn btn-outline-primary"
                                            (click)="viewDoc(vendor_details['vendor_id'],'PAN')">View Doc</button>
                                    </div>
                                    <div class="col-2"> </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-3 text-center">
                                        <h6>2. GSTIN : <span class="text-danger">*</span> </h6>
                                    </div>
                                    <div class="col-3 text-center">
                                        <button class="btn btn-outline-primary"
                                            (click)="viewDoc(vendor_details['vendor_id'],'GSTIN')">View Doc</button>
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                                <br>
                            </fieldset>
                            <br><br>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
