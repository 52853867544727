<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Employee Salary Register </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <!--  <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Employee Salary Generation</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="fa fa-plus-circle"></i> Release
                                Salary   </a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-2" data-toggle="tab"><i class="ti-settings"></i>
                                Employee
                                Salary Bill Detail </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="#tab-4" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Employee Salary </a>
                        </li>
                       <!--  <li class="nav-item">
                            <a class="nav-link " href="#tab-5" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Selected Employee Salary </a>
                        </li> -->


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade " id="tab-1">

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Year :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="salaryObj['fin_year']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Month :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="salaryObj['month']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Individual or Category Wise :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="t" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="" [(ngModel)]="salaryObj['type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>
                            <div *ngIf="salaryObj['type'] == 'sec'" class="row">
                                <div class="col-4 text-right">
                                    Select Category :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0046']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="salaryObj['emp_cat_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <div *ngIf="salaryObj['type'] == 'ind'" class="row">
                                <div class="col-4 text-right">
                                    Select Employee :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="emp_id"
                                        [multiple]="true" placeholder="" [(ngModel)]="ind_emp_id"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>

                            </div>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Bill Date :
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="salaryObj['accrual_date']">

                                </div>
                            </div>
                            <br>
                            <div class="row text-center">
                                <div class="col-5">

                                </div>
                                <button class="btn btn-outline-primary" (click)="getSalary()">Get Salary </button>
                                &nbsp;&nbsp;
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>

                                <div class="col-6 text-right">
                                    <button class="btn btn-outline-primary" (click)="generateSalary()">Salary
                                        Accrual</button>

                                </div>
                            </div>


                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Employee ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element['emp_id'] }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee
                                            Name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ salaryObj['emp_info'][element.emp_id]['emp_name'] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee
                                            Phone No

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ salaryObj['emp_info'][element.emp_id]['emp_phone_no'] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_component_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Pay
                                            Component

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_component_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_component_amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Amount

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_component_amt }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pay_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Payable /
                                            Deduction

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-warning"
                                                (click)="openUpdate(element)">UPDATE</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade " id="tab-3">

                            <br>

                            <!--   <div class="row">
                                <div class="col-4 text-right">
                                    Select Year :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="salaryObj['fin_year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Month :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="salaryObj['month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Individual or Category Wise :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="t" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="salaryObj['type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>
                            <div *ngIf="salaryObj['type'] == 'sec'" class="row">
                                <div class="col-4 text-right">
                                    Select Category :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0046']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="salaryObj['emp_cat_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Employee :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="data" bindLabel="tempid" bindValue="id" [multiple]="false"
                                        placeholder="" [(ngModel)]="hold_emp_id" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Bill Date :
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="salaryObj['accrual_date']">

                                </div>
                            </div>
                            <br>
                            <div class="row text-center">
                                <div class="col-5">

                                </div>
                                <button class="btn btn-outline-primary" (click)="getSalaryhold()">Submit</button>
                                &nbsp;&nbsp;
                            </div>

                            <hr>
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter3($any($event.target).value)"
                                            placeholder="Filter">
                                    </mat-form-field>
                                </div>

                                <div class="col-6 text-right">
                                    <button class="btn btn-outline-primary" (click)="generateholdSalary()">Salary
                                        Accrual</button>

                                </div>
                            </div>


                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource3" matSort #sortCol3="matSort">
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Employee ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            VDA{{ element['emp_id'] }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee
                                            Name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ salaryObj['emp_info'][element.emp_id]['emp_name'] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee
                                            Phone No

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ salaryObj['emp_info'][element.emp_id]['emp_phone_no'] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_component_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Pay
                                            Component

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_component_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_component_amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Amount

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_component_amt }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pay_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Payable /
                                            Deduction

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-warning"
                                                (click)="openUpdate(element)">UPDATE</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns3;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator3 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>



                        </div>
                        <div class="tab-pane fade  show active" id="tab-2">

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Year :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="billIdObj['fin_year']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Month :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="billIdObj['month']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getbillbydate()">GET ALL BILL</button>
                                </div>
                            </div>


                            <hr>

                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)"
                                            placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-4 text-right">
                                    <button class="btn btn-primary" (click)="downloadFile()">Download Excel</button>
                                </div>
                                <div class="col-2 text-right">
                                    <button class="btn btn-primary" (click)="print()">Download Pdf</button>
                                </div> -->


                            </div>


                            <div class=" example-container">
                                <table mat-table [dataSource]="dataSource1" matSort #sortCol2="matSort">
                                    <ng-container matColumnDef="bill_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Bill Id

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element['bill_id'] }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="bill_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bill
                                            Description

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.bill_desc }} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="section_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Section

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.section_code }} </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="bill_status_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bill Status

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.bill_status_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bill_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bill Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.accrual_date)}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="print">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Download

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary"
                                                (click)="getAllBill(element,'bill')">Bill</button>
                                            <!-- <button class="btn btn-primary" (click)="getAllBill(element,'summary')">Summary</button> -->
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button *ngIf="element.bill_status_code == 'GENERATED'" class="btn btn-info"
                                                (click)="statusChange(element)">APPROVED</button> -->
                                            <button *ngIf="element.bill_status_code == 'GENERATED'" class="btn btn-info"
                                                (click)="Appr(element)">Send TO Approval</button>

                                            <button *ngIf="element.bill_status_code == 'APPROVED'" class="btn btn-info"
                                                (click)="sendTOAccount(element)">sendTOAccount</button>
                                            <button
                                                *ngIf="element.bill_status_code == 'GENERATED' || element.bill_status_code == 'REJECTED'"
                                                class="btn btn-danger" (click)="deleteApproval(element)">Delete</button>
                                            <button *ngIf="element.bill_status_code != 'GENERATED' "
                                                class="btn btn-primary" (click)="status(element)">View Status</button>


                                        </td>
                                    </ng-container>



                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 style="margin-left: 2%;">
                                        Select Node :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                        Node</button>
                                </div>

                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 style="margin-left: 2%;">
                                        Selected Node :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>


                            <br>
                            <div *ngIf="salaryObj['type'] == 'sec'" class="row">
                                <div class="col-4 text-right">
                                    Select Category :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0046']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="salaryObj['emp_cat_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <div *ngIf="salaryObj['type'] == 'ind'" class="row">
                                <div class="col-4 text-right">
                                    <h6>Select Employee :<span style="color: red;">*</span></h6>
                                </div>

                                <div class="col-4">
                                    <ng-select disabled [items]="allEmplyees_new" bindLabel="emp_name"
                                        bindValue="arr_id" [multiple]="true" placeholder="" [(ngModel)]="ind_emp_id"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Year :<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="salaryObj['fin_year']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Month :<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="salaryObj['month']"  [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>


                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    Select Individual or Category Wise :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="t" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="salaryObj['type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br> -->





                            <hr>


                            <div class="row text-center">
                                <div class="col-5">

                                </div>
                                <button class="btn btn-outline-primary" (click)="getSalaryForView()">Get Salary </button>
                                &nbsp;&nbsp;
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> Bill Date :</h6>
                                </div>
                                <div class="col-4">
                                    <input type="date"  class="form-control" [(ngModel)]="salaryObj['accrual_date']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter4($any($event.target).value)"
                                            placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4 text-center">
                                    <h6>Total Bill Amount:</h6>{{bill_total}}
                                </div>
                                <div class="col-4 text-right">
                                    <button class="btn btn-primary" (click)="generateSalary()">Salary Accrual</button>
                                </div>
                            </div>


                            <div class=" example-container">
                                <table mat-table [dataSource]="dataSource4" matSort #sortCol4="matSort">
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Employee ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{salaryObj['emp_info'][element.arr_id]['emp_id']}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee
                                            Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ salaryObj['emp_info'][element.arr_id]['emp_name'] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee
                                            Phone No

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ salaryObj['emp_info'][element.arr_id]['emp_phone_no'] }} </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="pay_comp">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Pay
                                            Component

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_comp }} </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="rate">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Total Amount

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.rate }} </td>
                                    </ng-container>
                                    <!--
                                    <ng-container matColumnDef="pay_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Payable /
                                            Deduction

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_code }} </td>
                                    </ng-container> -->
                                    <!-- <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.date }} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-warning" (click)="openview(element)">View
                                                Details</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns4;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator4 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade " id="tab-5">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Start Date :
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="salaryObj['start_dt']">
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select End Date :
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="salaryObj['end_dt']">
                                </div>
                            </div>
                            <br>


                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Employee :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="filterallEmplyees_new" bindLabel="emp_name" bindValue="arr_id"
                                        [multiple]="false" placeholder="" [(ngModel)]="ind_emp_id"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>

                            </div>


                            <br>

                            <div class="row text-center">
                                <div class="col-5">

                                </div>
                                <button class="btn btn-outline-primary" (click)="getSalaryForViewByDate()">Get
                                    Salary 3</button> &nbsp;&nbsp;
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Bill Date :
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="salaryObj['accrual_date']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter5($any($event.target).value)"
                                            placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4 text-center">
                                    <h6>Total Bill Amount:</h6>{{bill_total}}
                                </div>
                                <div class="col-4 text-right">
                                    <button class="btn btn-primary" (click)="generateSalary()">Salary Accrual</button>
                                </div>
                            </div>


                            <div class=" example-container">
                                <table mat-table [dataSource]="dataSource5" matSort #sortCol5="matSort">
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Employee ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{salaryObj['emp_info'][element.arr_id]['emp_id']}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee
                                            Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ salaryObj['emp_info'][element.arr_id]['emp_name'] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee
                                            Phone No

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ salaryObj['emp_info'][element.arr_id]['emp_phone_no'] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_comp">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Pay
                                            Component

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_comp }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="rate">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Amount

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.rate }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pay_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Payable /
                                            Deduction

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.date }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-warning"
                                                (click)="openUpdate(element)">UPDATE</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns5;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator5 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>

<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> Paid Salary Details :</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <!--  <div class="row">
                    <div class="col-3 text-right"><h6>Employee ID :</h6></div>
                    <div class="col-3"> {{ind_emp_id[0]}}</div>
                    <div class="col-3 text-right"><h6>Employee Name :</h6></div>
                    <div class="col-3">{{view_data_header['emp_name']}}</div>
                </div> -->
                <br>
                <div class="row text-center">
                    <div class="col-5">

                    </div>
                    &nbsp;&nbsp;
                </div>
                <div class="">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Select</th>
                                <!-- <th>Account Code</th> -->
                                <th>Finacial Year</th>
                                <th>Month</th>


                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of paid_arr;let i = index;">
                                <td><input type="checkbox" (click)="check(item,i)"></td>
                                <!-- <td>{{obj['c_acc']}}</td> -->
                                <td>{{item['fin_year']}}</td>
                                <td>{{monthObj[item['month']]}}</td>


                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-outline-primary" data-dismiss="modal" (click)="generateSalaryhold()">Generate
                        Salary</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>


<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Project</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                    <div class="col-3"></div>
                    <div class="col-3" *ngIf="i==0">
                        <h6 style="margin-left: 2%;">Select Node :<span style="color: red;"> *</span></h6>
                        <!-- Select Node : -->
                    </div>
                    <div class="col-3" *ngIf="i!=0"></div>
                    <div class="col-1">

                        <span *ngIf="item['level']">Level</span>{{item['level']}}
                        <!-- Level -  -->
                    </div>
                    <div class="col-3">
                        <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd"
                            [multiple]="false" placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']"
                            [selectableGroup]="true" (change)="setHierarchyNodeitem(item,i)"
                            [selectableGroupAsModel]="true" [closeOnSelect]="true">
                        </ng-select>
                        <!-- Select  Node -->
                    </div>
                    <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                        <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                aria-hidden="true"></i></button>
                        &nbsp;&nbsp;
                        <button (click)="remove(item,i)" class="btn btn-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Current Node Path : <span style="color: red;">*</span>
                        </h6>
                    </div>
                    <div class="col-6">
                        {{Obj['path_desc']}}
                    </div>

                </div>
                <br>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submitProjectHierNode()">Submit</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                            [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                            placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div>
                    <table class="table table-bordered"
                        style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">
                                    <!--  <button class="btn btn-success" (click)="deleteLine(i)"><i class="fa fa-check"
                                        style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button>
                                <button class="btn btn-danger" (click)="copy(ln)">
                                     <i class="fa fa-times"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i>

                                        </button> -->
                                    <!-- <button class="btn btn-warning" (click)="paste(i)"> -->
                                    <!-- <i class="fas fa-paste"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->

                                    <!-- </button>  -->
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" style="border: black;">
                    <div class="col-4 text-right">
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit

                        </button>
                    </div>
                </div>
                <br>
                <!-- <div *ngIf="vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <h6>OR</h6>
                    </div>
                </div> -->
                <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Status</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="container">
                    <!-- completed -->
                    <a *ngFor="let ob of statusArr;let i=index">
                        <div class="step completed" *ngIf="ob.status=='APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;"
                                    *ngIf="i <statusArr.length-1"></div>
                            </div>

                            <div class="content">
                                {{UserObj[ob['user_id']]}}-{{ob['role_cd']}}-{{ob['status']}}
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='REJECTED'">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{UserObj[ob['user_id']]}}-{{ob['role_cd']}}-{{ob['status']}}
                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{UserObj[ob['user_id']]}}-{{ob['role_cd']}}-{{ob['status']}}
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal fade right" id="Z" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"
    aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" style="text-align: center; color: darkblue;">Salary Details for Month
                    {{this.salaryObj['month'] + " Year " + this.salaryObj['fin_year']}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>
            <div class="modal-body">
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee ID</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_id']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Employee Name</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_name']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee Phone no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_phone_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Employee Pan no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_pan_no']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee Adhar no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_adhar_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Bank Name</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['bank_code']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Account No.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['acct_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Ifsc Code</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['ifsc_code']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <table class="table table-striped"
                        style="width: 40%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color: #011831; color: white; width: 50%;">

                                <th style="width: 20%;">Payable Component</th>
                                <th style="width: 20%;">Amount</th>




                            </tr>

                        </thead>
                        <tbody>

                            <tr *ngFor="let fd of arr; index as i">

                                <td style="width: 20%;">
                                    {{fd['pay_comp']}}
                                </td>
                                <td style="width: 20%;">
                                    {{fd['rate']}}
                                </td>



                            </tr>




                        </tbody>

                    </table>
                    <table class="table table-striped"
                        style="width: 40%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color: #011831; color: white; width: 50%;">

                                <th style="width: 10%;">Deduction Component</th>
                                <th style="width: 10%;">Amount</th>




                            </tr>

                        </thead>
                        <tbody>

                            <tr *ngFor="let fd of arr1; index as i">

                                <td style="width: 20%;height:5px;">
                                    <div style="height: 5px;">{{fd['pay_comp']}}</div>
                                </td>
                                <td style="width: 20%; height:5px;">
                                    <div style="height: 5px;">{{fd['rate']}}</div>
                                </td>



                            </tr>




                        </tbody>

                    </table>
                </div>

                <hr>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Total Payble Amount:</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['Total_payble']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Total Deductions:</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['Total_Deductions']}}
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6 text-right">
                        <h6>
                            Net Payble:</h6>
                    </div>
                    <div class="col-6">
                        {{this.info['Net_Payble']}}
                    </div>


                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>

                </div>
            </div>
        </div>
    </div>
</div>
