import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import { LedgerService } from "../../service/ledger.service";
import { MainService } from "../../service/main.service";
import { BillService } from "../../service/bill.service";
import { SettingService } from "../../service/setting.service";
import { HierarchyService } from "../../service/hierarchy.service";
import { MainService as MainServiceMD } from "../../../md/service/main.service";
import Swal from "sweetalert2";
declare var $: any;

@Component({
  selector: "app-acc-journal",
  templateUrl: "./acc-journal.component.html",
  styleUrls: ["./acc-journal.component.css"],
})
export class AccJournalComponent implements OnInit {
  constructor(
    private billService: BillService,
    private hierarchyService: HierarchyService,
    public mainService: MainService,
    private ledgerService: LedgerService,
    private spinner: NgxSpinnerService,
    private settingService: SettingService,
    private mainServiceMD: MainServiceMD
  ) { }
  erpUser:any;
  b_acct_id:any;
  obj:any = {
    prep_id: 0,
    appr_id: 0,
    status: "Generated",
    tgt_curr_cd: "INR",
    preparer_comment: "",
  };
  allUnPostedJournalInfo:any = [];
  AllPartyObj :any= {};
  allAccountInfo:any = [];
  AllAccountObj:any = {};
  user_id:any;

  ledger :any= [
    { code: "A", value: "Actual" },
    { code: "B", value: "Budget" },
  ];
  ledgerObj:any = { A: "Actual", B: "Budget" };
  Status:any = [
    { code: "GENERATED", value: "GENERATED" },
    { code: "Approved", value: "Approved" },
    { code: "Rejected", value: "Rejected" },
  ];

  headerJournal:any = [
    { full_name: "S No." },
    { full_name: "Select Account" },
    { full_name: "Debit Credit Indicater" },
    { full_name: "txn_amt" },
    { full_name: "Action" },
  ];
  AllJournalRow_credit :any= [];
  AllJournalRow_debit :any= [];
  allDBIND :any= [
    { code: "CR", value: "CR" },
    { code: "DB", value: "DB" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  datasource :any= new MatTableDataSource([]);
  displayedColumns = [
    "org_unit_cd",
    "acct_dt",
    "prep_id",
    "appr_id",
    "preparer_comment",
    "status",
    "action",
  ];

  // datasource1 = new MatTableDataSource([]);
  // displayedColumns1 = [
  //   "jrnl_dtl_ln_id",
  //   "jrnl_desc",
  //   "db_cd_ind",
  //   "txn_amt",
  //   "action",
  // ];

  // techToBusNameObj1 = {};
  // field_code = [];
  allChartOfAccount = [];
  fin_year = "";
  db = 0;
  cr = 0;

  allEventList = [];
  jrnlLineObj = {
    txn_amt: 0,
    db_cd_ind: "NA",
    chart_of_account: 0,
    acct_dt: this.obj["acct_dt"],
    proc_dt: "",
    fin_year: "",
    jrnl_desc: this.obj["preparer_comment"],
    ledger_type: "",
    jrnl_ln_id: 0,
    jrnl_dtl_ln_id: 0,
    prep_id: 0,
    appr_id: 0,
    tgt_curr_cd: "INR",
    org_unit_cd: "",
    jrnl_id: 0,
    event_code: "",
    event_id: 0,
    bud_cd: "",
    proj_cd: "",
    prod_cd: "",
    act_cd: "",
    ar_cntr_ip_name: "",
    ar_cntr_ip_id: "",
    remark: ''
  };

  allFields:any = [];
  ChartOfAccountObj :any= {};
  jrnl_id:any;
  systemDate:any;

  async ngOnInit() {
    this.spinner.show();
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    this.obj["prep_id"] = this.user_id;
    this.obj["appr_id"] = this.user_id;
    var resp = await this.billService.getSystemDate();
    this.systemDate = resp.data;

    await this.getAllRolls();

    await this.getCurrentJrnlId();

    await this.getrepresentative();
    await this.getparty();

    await this.getallChartOfAccount();

    await this.getAllManualJournalInfo();

    await this.FinYear();

    await this.getActivityHierarchy();
    await this.getBudgetHierarchy();
    await this.getProductHierarchy();
    await this.getProjectHierarchy();

    await this.getevents();

    this.jrnl_id = this.obj["jrnl_id"];
    this.spinner.hide();
  }
  allUserRoles:any = {};
  async getAllRolls() {
    this.spinner.show();
    let obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    let resp = await this.mainServiceMD.getuserforrolemapping(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.spinner.hide();
      resp["data"].map((x:any) => {
        this.allUserRoles[x["user_id"]] = x["emp_name"];
      });
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error While Getting All Role", "error");
    }
  }
  async changeMJStatus(element:any) {
    this.spinner.show();
    let obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["id"] = element.id;
    obj["status"] = "APPROVED";
    obj["update_user_id"] = this.erpUser.user_id;
    var resp = await this.ledgerService.updateUnpostedJournalStatus(obj);
    if (resp["error"] == false) {
      await this.getAllManualJournalInfo();
      this.spinner.hide();
      Swal.fire("Success", "Approve Successfully!", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error", "...Error while Approving Manual Journal !", "error");
    }
  }

  allUser = [];
  UserObj = {};
  // bud_cd;
  // prod_cd;
  // proj_cd;
  // act_cd;
  // event_id;

  // bud_code;
  // proj_code;
  // prod_code;
  // act_code;
  // event_code
  setAllCode() {
    console.log(this.jrnlLineObj['event_code'])
    this.spinner.show();
    this.allEventList.map((x) => {
      if (this.jrnlLineObj["event_code"] == x["event_code"]) {
        this.jrnlLineObj['event_id'] = x["id"];
        this.jrnlLineObj['bud_cd'] = x["bud_cd"];
        this.jrnlLineObj['proj_cd'] = x["proj_cd"];
        this.jrnlLineObj['prod_cd'] = x["prod_cd"];
        this.jrnlLineObj['act_cd'] = x["act_cd"];
      }
    });
    this.spinner.hide();
  }
  /*****************************************Hier start**************************************** */

  budgetHier :any= {};
  productHier :any= {};
  projectHier:any = {};
  activityHier:any = {};

  async getBudgetHierarchy() {
    this.spinner.show();
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "bud_hier";
    let resp = await this.hierarchyService.getBudgetHierarchy(obj);
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        let str = [];
        for (let j = 1; j < 8; j++) {
          if (resp["data"][i]["lvl" + j + "_value"] != null) {
            str.push(resp["data"][i]["lvl" + j + "_value"]);
          }
        }
        let val = str.join("->");
        this.budgetHier[resp["data"][i]["leaf_cd"]] = val;
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error while getting Budget Hierarchy", "error");
    }
  }

  async getProjectHierarchy() {
    this.spinner.show();
    let obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    let resp = await this.hierarchyService.getProjectHierarchy(obj);
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        let str = [];
        for (let j = 1; j < 8; j++) {
          if (resp["data"][i]["lvl" + j + "_value"] != null) {
            str.push(resp["data"][i]["lvl" + j + "_value"]);
          }
        }
        let val = str.join("->");
        this.projectHier[resp["data"][i]["leaf_cd"]] = val;
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error While Getting Project Hierarchy", "error");
    }
  }

  async getProductHierarchy() {
    this.spinner.show();
    let obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "prod_hier";
    let resp = await this.hierarchyService.getProductHierarchy(obj);
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        let str = [];
        for (let j = 1; j < 8; j++) {
          if (resp["data"][i]["lvl" + j + "_value"] != null) {
            str.push(resp["data"][i]["lvl" + j + "_value"]);
          }
        }
        let val = str.join("->");
        this.productHier[resp["data"][i]["leaf_cd"]] = val;
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Eror While Getting Product Hierarchy", "error");
    }
  }
  async getActivityHierarchy() {
    this.spinner.show();
    let obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "activity_hier";
    let resp = await this.hierarchyService.getActivityHierarchy(obj);
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        let str = [];
        for (let j = 1; j < 8; j++) {
          if (resp["data"][i]["lvl" + j + "_value"] != null) {
            str.push(resp["data"][i]["lvl" + j + "_value"]);
          }
        }
        let val = str.join("->");
        this.activityHier[resp["data"][i]["leaf_cd"]] = val;
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Eror While Gwtting Activity Hierarchy", "error");
    }
  }

  /*****************************************Hier End **************************************** */
  async getevents() {
    this.spinner.show();
    var obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.ledgerService.getevents(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.allEventList = resp.data;
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error occured while getting all event", "error");
    }
  }

  async FinYear() {
    var obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.ledgerService.getActiveFinYear(JSON.stringify(obj));
    if (resp["error"] == false) {
      if (resp.data.length == 0) {
        Swal.fire("WARNING", "Active Financial Year Not set!", "warning");
      } else {
        this.fin_year = resp.data[0].fin_year;
      }
    } else {
      Swal.fire("ERROR", "Error while getting active  fin year", "error");
    }
  }

  async getallChartOfAccount() {
    this.spinner.show();
    this.allChartOfAccount = [];
    var obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.ledgerService.getchartofaccount(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.allChartOfAccount = resp.data;
      for (let i = 0; i < this.allChartOfAccount.length; i++) {
        this.ChartOfAccountObj[this.allChartOfAccount[i]["leaf_code"]] =
          this.allChartOfAccount[i]["leaf_value"];
      }

      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire(
        "ERROR",
        "Error while getting  all chart of account info",
        "error"
      );
    }
  }

  reperesentative:any = {};
  async getrepresentative() {
    this.spinner.show();
    var resp:any = await this.settingService.getAllrepresentative(
      JSON.stringify({ b_acct_id: this.b_acct_id })
    );
    if (resp["error"] == false) {
      this.spinner.hide();
      for (let i = 0; i < resp["data"].length; i++) {
        this.reperesentative[resp["data"][i]["rep_le_id"]] =
          resp["data"][i]["party_leagal_name"];
      }
    } else {
      this.spinner.hide();
    }
  }
  party :any= {};

  all_party:any;
  async getparty() {
    this.spinner.show();
    var resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.all_party = [{ party_leagal_name: "N/A", le_id: 0 }];
      resp["data"].map((x:any) => {
        this.all_party.push(x);
      });

      for (let i = 0; i < this.all_party.length; i++) {
        if (this.all_party[i]["party_type"] == "ORG") {
          this.all_party[i]["party_leagal_name"] =
            this.all_party[i]["party_leagal_name"] +
            " ( " +
            this.reperesentative[this.all_party[i]["rep_le_id"]] +
            ")";
        }
        this.party[this.all_party[i]["le_id"]] =
          this.all_party[i]["party_leagal_name"];
      }
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error while getting party details", "error");
    }
  }

  async addRowCredit() {
    this.AllJournalRow_credit.push({
      chart_of_account: "",
      txn_amt: 0,
    });

    await this.changeRowCredit(this.AllJournalRow_credit.length - 1);
    await this.saveline();

  }
  changecoa(i:any) {
    this.AllJournalRow_credit[i]["chart_of_account"] =
      this.AllJournalRow_credit[i]["chart_of_account"];
  }
  changedbcr(i:any) {
    this.AllJournalRow_credit[i]["db_cd_ind"] =
      this.AllJournalRow_credit[i]["db_cd_ind"];
  }
  changeamt(i:any) {
    this.AllJournalRow_credit[i]["txn_amt"] =
      this.AllJournalRow_credit[i]["txn_amt"];
  }
  changeremark(i:any) {
    this.AllJournalRow_credit[i]["remark"] =
      this.AllJournalRow_credit[i]["remark"];
  }
  // addRowDebit() {
  //   this.AllJournalRow_debit.push({
  //     chart_of_account: "",
  //     txn_amt: 0,

  //   });
  // }

  deleteRowCredit(i:any) {
    this.AllJournalRow_credit.splice(i, 1);
  }

  // deleteRowDebit(i) {
  //   this.AllJournalRow_debit.splice(i, 1);
  // }

  row_type:any;
  row_index:any;

  // row_table_data(data) {
  //   this.datasource1 = new MatTableDataSource(data);
  //   this.datasource1.paginator = this.paginator;
  //   this.datasource1.sort = this.sort;
  // }

  async changeRowCredit(i:any) {
    console.log(i);
    this.row_index = i;
    this.jrnlLineObj = Object.assign({}, this.AllJournalRow_credit[i]);

  }

  async saveline() {
    this.spinner.show();

    this.jrnlLineObj = {
      txn_amt: this.AllJournalRow_credit[this.row_index]["txn_amt"],
      db_cd_ind: this.AllJournalRow_credit[this.row_index]["db_cd_ind"],
      chart_of_account: this.AllJournalRow_credit[this.row_index]["chart_of_account"],
      acct_dt: this.obj["acct_dt"],
      proc_dt: this.systemDate,
      fin_year: this.fin_year,
      jrnl_desc: this.AllJournalRow_credit[this.row_index]["remark"],
      remark: this.AllJournalRow_credit[this.row_index]["remark"],
      ledger_type: "",
      jrnl_ln_id: this.row_index + 1,
      jrnl_dtl_ln_id: this.row_index + 1,
      prep_id: this.obj["prep_id"],
      appr_id: this.obj["appr_id"],
      jrnl_id: this.jrnl_id,
      tgt_curr_cd: this.obj["tgt_curr_cd"],
      org_unit_cd: this.mainService.accInfo["account_short_name"],

      event_code: this.jrnlLineObj['event_code'],
      bud_cd: this.jrnlLineObj['bud_cd'],
      prod_cd: this.jrnlLineObj['prod_cd'],
      proj_cd: this.jrnlLineObj['proj_cd'],
      act_cd: this.jrnlLineObj['act_cd'],
      event_id: this.jrnlLineObj['event_id'],
      ar_cntr_ip_name: this.party[this.jrnlLineObj["ar_cntr_ip_id"]],
      ar_cntr_ip_id: this.jrnlLineObj["ar_cntr_ip_id"],
    };

    this.spinner.hide();
    this.AllJournalRow_credit[this.row_index] = this.jrnlLineObj;
  }


  delete_row(element:any, i:any) {
    if (this.row_type == "CR") {
      this.AllJournalRow_credit[this.row_index].splice(i, 1);
      // this.row_table_data(this.AllJournalRow_credit[this.row_index]);
    } else if (this.row_type == "DB") {
      this.AllJournalRow_debit[this.row_index].splice(i, 1);
      // this.row_table_data(this.AllJournalRow_debit[this.row_index]);
    }
  }

  update_row(element:any, i:any) {
    if (this.row_type == "CR") {
      this.jrnlLineObj = Object.assign({}, element);
      this.AllJournalRow_credit[this.row_index].splice(i, 1);
      // this.row_table_data(this.AllJournalRow_credit[this.row_index]);
    } else if (this.row_type == "DB") {
      this.jrnlLineObj = Object.assign({}, element);
      this.AllJournalRow_debit[this.row_index].splice(i, 1);
      // this.row_table_data(this.AllJournalRow_debit[this.row_index]);
    }
  }

  async getAllManualJournalInfo() {
    this.spinner.show();
    var resp = await this.ledgerService.getAllUnpostedJournalInfo(
      this.b_acct_id
    );
    if (resp["error"] == false) {
      this.allUnPostedJournalInfo = resp.data;
      this.datasource = new MatTableDataSource(this.allUnPostedJournalInfo);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire(
        "ERROR",
        "Error while getting  all Unposted Journal info list",
        "error"
      );
    }
  }

  open_update(element:any) {
    $('.nav-tabs a[href="#tab-3"]').tab("show");
    this.obj = Object.assign({}, element);

    this.AllJournalRow_credit = [];
    this.AllJournalRow_debit = [];
    this.cr = 0;
    this.db = 0;

    var all_account_lines = JSON.parse(element.data_lines);
    for (let i = 0; i < all_account_lines.length; i++) {
      if (all_account_lines[i]["type"] == "credit") {
        this.AllJournalRow_credit.push(all_account_lines[i]["data"]);
        this.cr = this.cr + all_account_lines[i]["data"]["txn_amt"];
      }
      if (all_account_lines[i]["type"] == "debit") {
        this.AllJournalRow_credit.push(all_account_lines[i]["data"]);
        this.db = this.db + all_account_lines[i]["data"]["txn_amt"];
      }
    }
  }

  async refresh() {
    this.obj = {
      prep_id: this.user_id,
      appr_id: this.user_id,
      tgt_curr_cd: "INR",
      status: "GENERATED",
      preparer_comment: "",
    };
    this.AllJournalRow_credit = [];
    this.AllJournalRow_debit = [];
    this.cr = 0;
    this.db = 0;
  }

  async post(element:any) {
    this.spinner.show();
    const d :any= new Date();
    let month :any= d.getMonth();
    var data:any = [];
    let all_account_lines:any = JSON.parse(element.data_lines);
    // console.log("Post Data :", all_account_lines)
    // let desc_obj = new Object();

    //     for (let p = 0; p < all_account_lines; p++) {
    //       desc_obj[all_account_lines[p]["data"]['db_cd_ind']] = all_account_lines[p]["data"]['db_cd_ind']['chart_of_account'];
    //     }
    // console.log(desc_obj)


    for (let i = 0; i < all_account_lines.length; i++) {
      if (all_account_lines[i]["type"] == "credit") {
        let ob :any= new Object();

        ob["arr_id"] = all_account_lines[i]["data"]["jrnl_id"];
        ob["ar_cntr_ip_id"] =
          all_account_lines[i]["data"]["ar_cntr_ip_id"];
        ob["ar_cntr_ip_name"] =
          all_account_lines[i]["data"]["ar_cntr_ip_name"];
        ob["event_id"] = all_account_lines[i]["data"]["event_id"];
        ob["ld_prj_cd"] = all_account_lines[i]["data"]["proj_cd"];
        ob["ld_pd_cd"] = all_account_lines[i]["data"]["prod_cd"];
        ob["ld_bugt_cd"] = all_account_lines[i]["data"]["bud_cd"];
        ob["txn_amt"] = parseInt(
          all_account_lines[i]["data"]["txn_amt"]
        );
        ob["chart_of_account"] =
          all_account_lines[i]["data"]["chart_of_account"];
        ob["db_cd_ind"] =
          all_account_lines[i]["data"]["db_cd_ind"];
        ob["ar_actvty_tcd"] = "MANUAL_JRNL";
        ob["jrnl_dtl_ln_id"] =
          all_account_lines[i]["data"]["jrnl_dtl_ln_id"];
        ob["jrnl_desc"] = " By " + this.ChartOfAccountObj[all_account_lines[i]["data"]["chart_of_account"]];
        ob["org_unit_cd"] = this.b_acct_id;
        ob["tgt_curr_cd"] = "INR";
        ob["jrnl_ln_id"] = "_" + i;
        ob["fin_year"] = this.fin_year;
        ob["ev_cts"] = this.systemDate;
        ob["ld_fm"] = month + 1;
        ob["acct_dt"] = all_account_lines[i]["data"]["acct_dt"];

        ob["ledger_type"] = "ACT";
        ob["prep_id"] = all_account_lines[i]["data"]["prep_id"];
        ob["appr_id"] = this.erpUser.user_id;
        ob["ar_actvty_lcl_num"] = element['jrnl_id'] // all_account_lines[i]["data"]["jrnl_id"];
        ob["ar_src_cd"] = "ACCOUNT";
        ob["cp_bnk_acct_id"] = 0;
        ob["event_code"] =
          all_account_lines[i]["data"]["event_code"];
        ob["ld_jrnl_ref_num"] =
          "ACCOUNT-MANUAL_JOURNAL-" +
          all_account_lines[i]["data"]["jrnl_id"];
        ob["ld_jrnl_lfcycl_st_cd"] = "POSTED";
        ob["ld_jrnl_unpost_seq_num"] = 0;
        ob["ld_acct_per"] = 0;
        ob["ld_cat_cd"] = "FIN";
        ob["ld_jrnl_rvrsl_tcd"] = "I";
        ob["ld_tgt_cur_tcd"] = "FN";
        ob["ld_jrnl_entry_cd"] = "Auto";
        ob["ld_jrnl_ln_ksn"] =
          all_account_lines[i]["data"]["jrnl_id"] +
          "-" +
          "MAUAL_JOURNAL";
        ob["ld_jrnl_ln_desc"] =
          "ACCOUNT" +
          "-MANUAL JURNAL-" +
          all_account_lines[i]["data"]["jrnl_id"] +
          "-" +
          all_account_lines[i]["data"]["jrnl_desc"] +
          "-" +
          all_account_lines[i]["data"]["ar_cntr_ip_name"];
        ob["ld_src_cd"] = "ACCOUNT";
        ob["ld_tech_comp_cd"] = "ARE";
        ob["ld_jrnl_dtln_desc"] =
          "ACCOUNT" +
          "-MANUAL JURNAL-" +
          all_account_lines[i]["data"]["jrnl_id"] +
          "-" +
          all_account_lines[i]["data"]["jrnl_desc"];
        ob["ld_bus_func_cd"] = "MAUAL_JOURNAL2JV";
        ob["ld_unadj_acct_dt"] = this.systemDate;
        ob["pmt_bnk_acct_id"] = 0;
        ob["ld_aff_cc_cd"] = 0;
        ob["ld_cc_cd"] = 0;
        ob["ld_jrnl_dtl_ln_ksn"] = "_" + i + "_" + i;
        data.push(ob);
      }
      if (all_account_lines[i]["type"] == "debit") {
        let ob:any = new Object();
        ob["ld_prj_cd"] = all_account_lines[i]["data"]["proj_cd"];
        ob["ld_pd_cd"] = all_account_lines[i]["data"]["prod_cd"];
        ob["ld_bugt_cd"] = all_account_lines[i]["data"]["bud_cd"];
        ob["txn_amt"] =
          -1 * parseInt(all_account_lines[i]["data"]["txn_amt"]);
        ob["ar_actvty_tcd"] = "MANUAL_JRNL";
        ob["db_cd_ind"] =
          all_account_lines[i]["data"]["db_cd_ind"];
        ob["chart_of_account"] =
          all_account_lines[i]["data"]["chart_of_account"];
        ob["arr_id"] = all_account_lines[i]["data"]["jrnl_id"];
        ob["event_code"] =
          all_account_lines[i]["data"]["event_code"];
        ob["jrnl_dtl_ln_id"] =
          all_account_lines[i]["data"]["jrnl_dtl_ln_id"];
        ob["org_unit_cd"] = this.b_acct_id;
        ob["tgt_curr_cd"] = "INR";
        ob["jrnl_ln_id"] =
          all_account_lines[i]["data"]["jrnl_ln_id"];
        ob["fin_year"] = this.fin_year;
        ob["ev_cts"] = this.systemDate;
        ob["ld_fm"] = month + 1;
        ob["acct_dt"] = all_account_lines[i]["data"]["acct_dt"];
        ob["jrnl_desc"] = " To " + this.ChartOfAccountObj[all_account_lines[i]["data"]["chart_of_account"]];
        ob["ledger_type"] = "ACT";
        ob["prep_id"] = all_account_lines[i]["data"]["prep_id"];
        ob["appr_id"] = this.erpUser.user_id;
        ob["ar_actvty_lcl_num"] = element['jrnl_id'] // all_account_lines[i]["data"]["jrnl_id"];
        ob["ar_src_cd"] = "ACCOUNT";
        ob["ar_cntr_ip_id"] =
          all_account_lines[i]["data"]["ar_cntr_ip_id"];
        ob["ar_cntr_ip_name"] =
          all_account_lines[i]["data"]["ar_cntr_ip_name"];
        ob["event_id"] = all_account_lines[i]["data"]["event_id"];
        ob["cp_bnk_acct_id"] = 0;
        ob["ld_jrnl_ref_num"] =
          "ACCOUNT-MANUAL_JOURNAL-" +
          all_account_lines[i]["data"]["jrnl_id"];
        ob["ld_jrnl_lfcycl_st_cd"] = "POSTED";
        ob["ld_jrnl_unpost_seq_num"] = 0;
        ob["ld_acct_per"] = 0;
        ob["ld_cat_cd"] = "FIN";
        ob["ld_jrnl_rvrsl_tcd"] = "I";
        ob["ld_tgt_cur_tcd"] = "FN";
        ob["ld_jrnl_entry_cd"] = "Auto";
        ob["ld_jrnl_ln_ksn"] =
          all_account_lines[i]["data"]["jrnl_id"] +
          "-" +
          "MAUAL_JOURNAL";
        ob["ld_jrnl_ln_desc"] =
          "ACCOUNT" +
          "-MANUAL JURNAL-" +
          all_account_lines[i]["data"]["jrnl_id"] +
          "-" +
          all_account_lines[i]["data"]["jrnl_desc"] +
          "-" +
          all_account_lines[i]["data"]["ar_cntr_ip_name"];
        ob["ld_src_cd"] = "ACCOUNT";
        ob["ld_tech_comp_cd"] = "ARE";
        ob["ld_jrnl_dtln_desc"] =
          "ACCOUNT" +
          "-MANUAL JURNAL-" +
          all_account_lines[i]["data"]["jrnl_id"] +
          "-" +
          all_account_lines[i]["data"]["jrnl_desc"];
        ob["ld_bus_func_cd"] = "MAUAL_JOURNAL2JV";
        ob["ld_unadj_acct_dt"] = this.systemDate;
        ob["pmt_bnk_acct_id"] = 0;
        ob["ld_aff_cc_cd"] = 0;
        ob["ld_cc_cd"] = 0;
        ob["ld_jrnl_dtl_ln_ksn"] = "_" + i + "_" + i;
        data.push(ob);
      }
    }
    let obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["data"] = data;
    obj["id"] = element["id"];
    obj["status"] = "POSTED";
    this.spinner.hide()
    // return;
    var resp = await this.ledgerService.postingJournal(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getAllManualJournalInfo();
      Swal.fire("SUCCESS", "Journal Posted Successfully!", "success");
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error while posting journal", "error");
    }
  }

  async check() {
    this.db = 0;
    this.cr = 0;
    for (var i = 0; i < this.AllJournalRow_credit.length; i++) {
      if (this.AllJournalRow_credit[i]["db_cd_ind"] == "CR") {
        this.cr += this.AllJournalRow_credit[i]["txn_amt"];
        this.AllJournalRow_credit[i]["db_cd_ind"] = "CR";
      } else {
        this.db += this.AllJournalRow_credit[i]["txn_amt"];
        this.AllJournalRow_credit[i]["db_cd_ind"] = "DB";
      }
    }
  }

  async getCurrentJrnlId() {
    this.spinner.show();
    let obj1:any = new Object();
    obj1["b_acct_id"] = this.b_acct_id;

    var resp = await this.ledgerService.getMaxJournalId(JSON.stringify(obj1));
    if (resp["error"] == false) {
      this.spinner.hide();
      if (resp.data[0][0].jrnl_id == null && resp.data[1][0].jrnl_id == null) {
        this.obj["jrnl_id"] = 1;
      }
      else if (resp.data[1][0].jrnl_id == null && resp.data[0][0].jrnl_id != null) {
        this.obj["jrnl_id"] = resp.data[0][0].jrnl_id + 1;
      }
      else if (resp.data[1][0].jrnl_id != null && resp.data[0][0].jrnl_id == null) {
        this.obj["jrnl_id"] = resp.data[1][0].jrnl_id + 1;
      } else if (resp.data[1][0].jrnl_id != null && resp.data[0][0].jrnl_id != null) {
        this.obj["jrnl_id"] = resp.data[1][0].jrnl_id + 1;
        if (resp.data[1][0].jrnl_id > resp.data[0][0].jrnl_id) {
          this.obj["jrnl_id"] = resp.data[1][0].jrnl_id + 1;
        } else {
          this.obj["jrnl_id"] = resp.data[0][0].jrnl_id + 1;
        }
      }
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error while Adding Journal", "error");
      return;
    }
  }

  checkBeforeSave(form:any) {
    if (this.AllJournalRow_credit.length == 0) {
      this.addRowCredit();
      return;
    }
    if (!this.jrnlLineObj['event_code'] || !this.jrnlLineObj['ar_cntr_ip_id']) {
      $('#change').modal('show');
      form.status = 'INVALID';
      return;
    }

    this.db = 0;
    this.cr = 0;
    this.AllJournalRow_credit.map((x :any)=> {
      if (x['db_cd_ind'] == "CR") this.cr += x['txn_amt'];
      else if (x['db_cd_ind'] == "DB") this.db += x['txn_amt'];
    })

    let total = this.cr - this.db;
    if (total != 0 ) {
      Swal.fire({
        title: "Debit and Credit are not equal",
        icon: "error",
        showCancelButton: true,
      });
      return;
    } else {
      Swal.fire({
        title:
          "Are You Sure? Total Debit is " +
          this.db +
          ", Total Credit is " +
          this.cr,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Add it!",
      }).then((result) => {
        if (result.value) {
          this.save();
        }
      });
    }
  }

  async save() {
    this.spinner.show();
    await this.getCurrentJrnlId();
    var data = [];
    for (let i = 0; i < this.AllJournalRow_credit.length; i++) {
      if (this.AllJournalRow_credit[i]["db_cd_ind"] == "CR") {
        data.push({ type: "credit", data: this.AllJournalRow_credit[i] });
      } else {
        data.push({ type: "debit", data: this.AllJournalRow_credit[i] });
      }
    }
    this.obj["org_unit_cd"] = this.mainService.accInfo["account_short_name"];
    this.obj["data_lines"] = JSON.stringify(data);
    this.obj["b_acct_id"] = this.b_acct_id;
    this.obj["tgt_curr_cd"] = "INR";
    this.obj["status"] = "GENERATED";

    // console.log("This data will go into database : ", this.obj);
    // return;
    var resp = await this.ledgerService.addUnpostedJournal(this.obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getAllManualJournalInfo();
      await this.getCurrentJrnlId();
      this.AllJournalRow_credit = []
      $('.nav-tabs a[href="#tab-1"]').tab("show");
      document.getElementById('reset')!.click();
      Swal.fire("SUCCESS!", "Created Successfully", "success");
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error while Adding Journal", "error");
    }
  }

  async checkOnUpdate() {
    this.db = 0;
    this.cr = 0;
    for (var i = 0; i < this.AllJournalRow_debit.length; i++) {
      this.db += this.AllJournalRow_debit[i]["txn_amt"];
      this.AllJournalRow_debit[i]["db_cd_ind"] = "DB";
    }
    for (var i = 0; i < this.AllJournalRow_credit.length; i++) {
      if (this.AllJournalRow_credit[i]["db_cd_ind"] == "CR") {
        this.cr += this.AllJournalRow_credit[i]["txn_amt"];
        this.AllJournalRow_credit[i]["db_cd_ind"] = "CR";
      } else {
        this.db += this.AllJournalRow_credit[i]["txn_amt"];
        this.AllJournalRow_credit[i]["db_cd_ind"] = "DB";
      }
    }
  }

  async update(form:any) {

    if (this.AllJournalRow_credit.length == 0) {
      this.addRowCredit();
      return;
    }
    if (!this.jrnlLineObj['event_code'] || !this.jrnlLineObj['ar_cntr_ip_id']) {
      $('#change').modal('show');
      form.status = 'INVALID';
      return;
    }


    this.spinner.show();
    var obj1:any = new Object();
    obj1["b_acct_id"] = this.b_acct_id;

    var resp = await this.ledgerService.getMaxJournalId(JSON.stringify(obj1));
    if (resp["error"] == false) {
      this.spinner.hide();
      if (
        resp.data[0][0].jrnl_line_id == null &&
        resp.data[1][0].jrnl_line_id == null
      ) {
        this.obj["jrnl_line_id"] = 1;
      } else if (
        resp.data[1][0].jrnl_line_id == null &&
        resp.data[0][0].jrnl_line_id != null
      ) {
        this.obj["jrnl_line_id"] = resp.data[0][0].jrnl_line_id + 1;
      } else if (
        resp.data[1][0].jrnl_line_id != null &&
        resp.data[0][0].jrnl_line_id == null
      ) {
        this.obj["jrnl_line_id"] = resp.data[1][0].jrnl_line_id + 1;
      } else if (
        resp.data[1][0].jrnl_line_id != null &&
        resp.data[0][0].jrnl_line_id != null
      ) {
        this.obj["jrnl_line_id"] = resp.data[1][0].jrnl_line_id + 1;
        if (resp.data[1][0].jrnl_line_id > resp.data[0][0].jrnl_line_id) {
          this.obj["jrnl_line_id"] = resp.data[1][0].jrnl_line_id + 1;
        } else {
          this.obj["jrnl_line_id"] = resp.data[0][0].jrnl_line_id + 1;
        }
      }
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error while Adding Journal", "error");
      return;
    }

    let data = [];
    for (let i = 0; i < this.AllJournalRow_credit.length; i++) {
      if (this.AllJournalRow_credit[i]["db_cd_ind"] == "CR") {
        data.push({ type: "credit", data: this.AllJournalRow_credit[i] });
      } else {
        data.push({ type: "debit", data: this.AllJournalRow_credit[i] });
      }
    }
    this.obj["org_unit_cd"] = this.mainService.accInfo["account_short_name"];
    this.obj["data_lines"] = JSON.stringify(data);
    this.obj["b_acct_id"] = this.b_acct_id;
    this.obj["tgt_curr_cd"] = "INR";

    var resp = await this.ledgerService.updateUnpostedJournal(this.obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getAllManualJournalInfo();
      Swal.fire("SUCCESS", "Journal Update Successfully!", "success");
      $('.nav-tabs a[href="#tab-1"]').tab("show");
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error while update Journal", "error");
    }
  }

  async delete(element:any) {
    this.spinner.show();
    var obj1:any = new Object();
    obj1["b_acct_id"] = this.b_acct_id;
    obj1["id"] = element.id;
    var resp = await this.ledgerService.deleteUnpostedJournal(
      JSON.stringify(obj1)
    );
    if (resp["error"] == false) {
      await this.getAllManualJournalInfo();
      this.spinner.hide();
      Swal.fire("SUCCESS", "Journal Delete Successfully!", "success");
    } else {
      this.spinner.hide();
      Swal.fire("ERROR", "Error while delete Journal ", "error");
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}

// ***********************************************Unused Code ****************************************************///

// ok() {
//   $('#change').modal('hide');
//   if (this.row_type == 'CR') {
//     this.AllJournalRow_credit[this.row_index]['txn_amt'] = 0;
//     for (let i = 0; i < this.AllJournalRow_credit[this.row_index]['lines'].length; i++) {
//       this.AllJournalRow_credit[this.row_index]['txn_amt'] = this.AllJournalRow_credit[this.row_index]['txn_amt'] + parseInt(this.AllJournalRow_credit[this.row_index]['lines'][i]['txn_amt'])
//     }

//     this.cr = 0;
//     for (let i = 0; i < this.AllJournalRow_credit.length; i++) {
//       this.cr = this.cr + this.AllJournalRow_credit[i]['txn_amt'];
//     }
//   } else if (this.row_type == 'DB') {
//     this.AllJournalRow_debit[this.row_index]['txn_amt'] = 0;
//     for (let i = 0; i < this.AllJournalRow_debit[this.row_index]['lines'].length; i++) {
//       this.AllJournalRow_debit[this.row_index]['txn_amt'] = this.AllJournalRow_debit[this.row_index]['txn_amt'] + parseInt(this.AllJournalRow_debit[this.row_index]['lines'][i]['txn_amt'])
//     }
//     this.db = 0;
//     for (let i = 0; i < this.AllJournalRow_debit.length; i++) {
//       this.AllJournalRow_debit[i]['txn_amt'] = this.AllJournalRow_debit[i]['txn_amt'];
//       this.db = this.db + this.AllJournalRow_debit[i]['txn_amt'];
//     }
//   }

// }

// async getAllAccountInfo() {
//   var obj :any= new Object();
//   obj['b_acct_id'] = this.b_acct_id;
//   var resp = await this.ledgerService.getAccountInfo(JSON.stringify(obj));
//   if (resp['error'] == false) {
//     this.allAccountInfo = resp.data;
//     for (let i = 0; i < this.allAccountInfo.length; i++) {
//       this.AllAccountObj[this.allAccountInfo[i].account_code] = this.allAccountInfo[i].account_desc
//     }
//     console.log(this.AllAccountObj)
//     this.spinner.hide();
//   } else {
//     this.spinner.hide()
//     this.snackBar.open("Error while getting  all account info", 'Error', {
//       duration: 5000
//     });
//   }
// }
// async getAllParty() {
//   var obj :any= new Object();
//   obj['b_acct_id'] = this.b_acct_id;
//   var resp = await this.ledgerService.getPartyInfo(JSON.stringify(obj));
//   if (resp['error'] == false) {
//     this.allParty = resp.data;
//     for (let i = 0; i < this.allParty.length; i++) {
//       this.AllPartyObj[this.allParty[i]['party_id']] = this.allParty[i].party_legal_name;
//     }
//     this.allParty.map(x => {
//       if (x['party_type_code'] == 'ORG') {
//         x['party_legal_name'] = x['legal_name'] + "(" + x['party_name'] + ")"
//       }
//     })
//     console.log("All Party : ", this.allParty)
//     this.spinner.hide();
//   } else {
//     this.spinner.hide()
//     this.snackBar.open("Error while getting  all party list", 'Error', {
//       duration: 5000
//     });
//   }
// }
// async getUsersInfo() {
//   var obj = { b_acct_id: this.b_acct_id };
//   var resp = await this.userAddService.getAllUsersInfo(JSON.stringify(obj));
//   console.log(resp);
//   if (resp['error'] == false) {
//     this.allUser = resp['data'];
//     for (let i = 0; i < this.allUser.length; i++) {
//       this.UserObj[this.allUser[i]['user_id']] = this.allUser[i]['first_name'] + " " + this.allUser[i]['last_name']
//       this.allUser[i]['name'] = this.allUser[i]['first_name'] + " " + this.allUser[i]['last_name']
//     }
//   } else {

//   }
// }

/////********************************************* */

// partyArr = []
// partyArrObj = {}
// async getIp() {
//   this.spinner.show();
//   let obj :any= {}
//   obj['b_acct_id'] = this.b_acct_id;
//   var resp = await this.billService.getPartyInfo(obj);
//   if (resp['error'] == false) {
//     this.partyArr = resp.data
//     console.log(this.partyArr)
//     this.partyArrObj = new Object()
//     for (let i = 0; i < this.partyArr.length; i++) {
//       this.partyArrObj[this.partyArr[i]['le_id']] = this.partyArr[i]['party_legal_name']
//     }

//     this.spinner.hide();

//   } else {
//     this.spinner.hide();
//     this.snackBar.open("Error occured while getting Schemes", 'Error', {
//       duration: 5000,
//     });
//   }
// }

// async schedule(element:any) {
//   console.log(element)
//   var obj :any= new Object();
//   obj['b_acct_id'] = this.b_acct_id;
//   obj['data'] = [];
//   for (var i = 0; i < this.allApproval.length; i++) {
//     if (this.allApproval[i]['doc_type'] == 'MJ') {
//       obj['data'].push({
//         user_id: this.allApproval[i]['user_id'],
//         level_of_approval: this.allApproval[i]['level_of_approval'],
//         doc_type: this.allApproval[i]['doc_type'],
//         create_user_id: this.erpUser.user_id,
//         doc_local_no: element['id'],
//         doc_local_desc: element['id'] + "-" + element['preparer_comment'],
//         status: 'PENDING'
//       })
//     }
//   }

//   var resp = await this.billService.sendToApproval(obj);
//   if (resp['error'] == false) {
//     await this.changeMJStatus(element);
//     this.spinner.hide();
//   } else {
//     this.spinner.hide();
//     Swal.fire('Error', 'Error while sending the bill to approvel', 'error');
//   }
// }

// statusArr = [];
// async status(element:any) {
//   var obj :any= new Object();
//   console.log(element);
//   obj["b_acct_id"] = this.b_acct_id;
//   obj["bill_id"] = element.id;
//   console.log(obj)
//   this.spinner.show();
//   var resp = await this.billService.getDocumentStatus(obj);
//   console.log(resp);
//   if (resp['error'] == false) {
//     this.statusArr = resp['data'];
//     $('#myModal_Status').modal('show');
//     this.spinner.hide()
//   } else {
//     this.spinner.hide();
//     Swal.fire("Error", "Error while getting status", 'error');
//   }
// }
// allApproval = [];
// levelOfApproval = {};
// async getAllApproveRule() {
//   this.spinner.show()
//   var resp = await this.billService.getAllApproval(this.b_acct_id);
//   if (resp['error'] == false) {
//     this.spinner.hide()
//     this.allApproval = resp['data'];
//     for (var i = 0; i < this.allApproval.length; i++) {
//       if (this.allApproval[i]['doc_type'] == 'MJ') {
//         this.levelOfApproval[this.allApproval[i]['level_of_approval']] = this.allApproval[i];
//       }
//     }
//   } else {
//     this.spinner.hide()
//   }
// }

// async getallFields() {

//   var obj :any= new Object();
//   obj['table_name'] = 'field_info';
//   obj['b_acct_id'] = this.b_acct_id;
//   var resp = await this.ledgerService.getFields(obj);
//   this.allFields = [];
//   if (resp['error'] == false) {
//     this.allFields = resp.data;
//     console.log(this.allFields);

//   } else {
//     this.snackBar.open("Error while getting Fields", "Error", {
//       duration: 5000,
//     });
//   }

// }
// header = []
// async getJrnlTRecordDetail() {
//   var resp = await this.ledgerService.getjrnldata(this.b_acct_id);
//   if (resp['error'] == false) {
//     console.log(resp)
//     var ip_dtl = resp.data[0];
//     this.displayedColumns_temp1 = [];
//     this.techToBusNameObj1 = {};
//     var ip_fields_code = ip_dtl.field_code.split(",");
//     let isHeader = ip_dtl.is_header.split(",");
//     let isLine = ip_dtl.is_line.split(",");
//     let isDetailLine = ip_dtl.is_detail_line.split(",");

//     this.field_code = [];
//     for (let i = 0; i < ip_fields_code.length; i++) {

//       for (let j = 0; j < this.allFields.length; j++) {
//         if (ip_fields_code[i] == this.allFields[j]['field_code']) {
//           if (isHeader[i] == 1) {
//             this.header.push(
//               { field_business_name: this.allFields[i]['field_business_name'] })
//             // console.log(this.header);
//           }
//           if (
//             this.allFields[j]['field_technical_name'] == 'id' ||
//             this.allFields[j]['field_technical_name'] == 'create_timestamp' ||
//             this.allFields[j]['field_technical_name'] == 'create_user_id' ||
//             this.allFields[j]['field_technical_name'] == 'update_timestamp' ||
//             this.allFields[j]['field_technical_name'] == 'update_user_id') {
//           } else {
//             this.displayedColumns_temp1.push(this.allFields[j]['field_technical_name']);
//             this.techToBusNameObj1[this.allFields[j]['field_technical_name']] = this.allFields[j]['field_business_name'];

//             var datatype = this.allFields[j]['datatype_code'];
//             var temp_type;
//             if (datatype == 'bigint(20)' || datatype == 'double' || datatype == 'int(11)') {
//               temp_type = 'number';
//             } else if (datatype == 'date') {
//               temp_type = 'date';
//             } else if (datatype == 'varchar(200)' || datatype == 'varchar(50)' || datatype == 'text') {
//               temp_type = 'text';
//             }
//             this.field_code.push({
//               field_business_name: this.allFields[j]['field_business_name'],
//               field_technical_name: this.allFields[j]['field_technical_name'],
//               field_code: ip_fields_code[i],
//               type: temp_type
//             })
//           }
//         }
//       }
//     }

//   } else {
//     this.snackBar.open("Error while getting ip Records", "Error", {
//       duration: 5000,
//     });

//   }
// }
 // async changeRowDebit(i) {
  //   var ledger_type_value = this.obj["ledger_type"];
  //   this.row_type = "DB";
  //   this.row_index = i;
  //   // this.objrefresh()
  //   this.jrnlLineObj = {
  //     txn_amt: 0,
  //     db_cd_ind: "DB",
  //     chart_of_account: this.AllJournalRow_debit[i]["chart_of_account"],
  //     acct_dt: this.obj["acct_dt"],
  //     proc_dt: this.systemDate,
  //     fin_year: this.fin_year,
  //     jrnl_desc: this.obj["preparer_comment"],
  //     ledger_type: ledger_type_value,
  //     jrnl_ln_id: i + 1,
  //     jrnl_dtl_ln_id:
  //       this.AllJournalRow_debit[this.row_index]["lines"].length + 1,
  //     prep_id: this.obj["prep_id"],
  //     appr_id: this.obj["appr_id"],
  //     jrnl_id: this.jrnl_id,
  //     tgt_curr_cd: this.obj["tgt_curr_cd"],
  //     org_unit_cd: this.mainService.accInfo["account_short_name"],
  //     event_code: this.event_code,
  //     bud_cd: this.bud_code,
  //     prod_cd: this.prod_code,
  //     proj_cd: this.proj_code,
  //     act_cd: this.act_code,
  //     event_id: this.event_id,
  //     ar_cntr_ip_name: this.party[this.jrnlLineObj["ar_cntr_ip_id"]],
  //     ar_cntr_ip_id: this.jrnlLineObj["ar_cntr_ip_name"],
  //   };

  //   // if (this.AllJournalRow_debit[i]["lines"].length == 0) {
  //   //   this.addLineItems();
  //   // }
  //   // await this.row_table_data(this.AllJournalRow_debit[i]["lines"]);
  // }

  // async addLineItems() {
  //   var ledger_type_value = this.obj["ledger_type"];

  //   if (this.row_type == "CR") {
  //     await this.AllJournalRow_credit[this.row_index]["lines"].push(this.jrnlLineObj);
  //   } else if (this.row_type == "DB") {
  //     this.AllJournalRow_debit[this.row_index]["lines"].push(this.jrnlLineObj);
  //     this.jrnlLineObj = {
  //       txn_amt: 0,
  //       db_cd_ind: "DB",
  //       chart_of_account:
  //         this.AllJournalRow_debit[this.row_index]["chart_of_account"],
  //       acct_dt: this.obj["acct_dt"],
  //       proc_dt: this.systemDate,
  //       fin_year: this.fin_year,
  //       jrnl_desc: this.obj["preparer_comment"],
  //       ledger_type: ledger_type_value,
  //       jrnl_ln_id: this.row_index + 1,
  //       jrnl_dtl_ln_id:
  //         this.AllJournalRow_debit[this.row_index]["lines"].length + 1,
  //       prep_id: this.obj["prep_id"],
  //       appr_id: this.obj["appr_id"],
  //       jrnl_id: this.jrnl_id,
  //       tgt_curr_cd: this.obj["tgt_curr_cd"],
  //       org_unit_cd: this.mainService.accInfo["account_short_name"],
  //       event_code: this.event_code,
  //       bud_cd: this.bud_code,
  //       prod_cd: this.prod_code,
  //       proj_cd: this.proj_code,
  //       act_cd: this.act_code,
  //       event_id: this.event_id,
  //       ar_cntr_ip_name: this.party[this.jrnlLineObj["ar_cntr_ip_id"]],
  //       ar_cntr_ip_id: this.jrnlLineObj["ar_cntr_ip_id"],
  //     };
  //     this.row_table_data(this.AllJournalRow_debit[this.row_index]["lines"]);
  //   }
  // }
