<div class="sidebar">
  <div id="sidebar-collapse">
    <div class="admin-block d-flex" style="align-items: center;">
      <div>
        <img src="./../../../assets/img/dash/ap.png" class="img-circle" width="45px" />
      </div>
      <div class="admin-info">
        <div class="font-strong" (click)="home()">{{auth.allSystemModuleObj['ACCOUNT']}}</div>
      </div>
    </div>
    <ul class="side-menu metismenu">

      <li>
        <a class="text-decoration-none"     [hidden]="sidebar['dashbord']" (click)="refreshCol()"
          routerLink="/accounts/accounts-index"><i class="sidebar-item-icon fa fa-th-large"></i>
          <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'Sidebar1']}}
            <!-- Dashboard -->
          </span>
        </a>
      </li>

      <li    >
        <a class="text-decoration-none" href="#reportColapse"  (click)="rotateIcon('rotateButtonElem')" data-bs-toggle="collapse" [hidden]="sidebar['report']"><i
            class="sidebar-item-icon fa fa-bar-chart"></i>
          <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'Sidebar2']}}
            <!-- Report -->
          </span><i class="fa fa-angle-left arrow"  #rotateButtonElem></i></a>
        <ul class="nav-2-level collapse"    id="reportColapse" data-bs-parent="#sidebar-collapse">

          <li>
            <a class="text-decoration-none"    routerLink="/accounts/ledger/report/ledger-report"
              [hidden]="sidebar['ledger_report']"

                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar4']}}
              <!-- Ledger Report -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/bank-report"
              [hidden]="sidebar['bank_book']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar5']}}
              <!-- Bank Book -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/gst" [hidden]="sidebar['gst_report']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar6']}}
              <!-- GST -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/tds-gst"
              [hidden]="sidebar['tds_on_gst']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar7']}}
              <!-- TDS on GST -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/tds" [hidden]="sidebar['tds']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar8']}}
              <!-- TDS -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/all-ded" [hidden]="sidebar['deduction']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar9']}}
              <!-- Deductions -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/balance-sheet-report"
              [hidden]="sidebar['balance_sheet']"    >Balance Sheet</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/income-expence-report"
              [hidden]="sidebar['inc_exp_report']"    >INC/EXP Report</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/fix-asset"
              [hidden]="sidebar['fixed_assets_report']"    >Fixed Assets Report</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/cogs-report" [hidden]="sidebar['cogs']"
                 >COGS Report</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/receipts-payment"
              [hidden]="sidebar['receipt_payment']"    >Receipts & Payment</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/store-keeper-register"
              [hidden]="sidebar['fixed_assest_register']"    >Fixed Assets Register</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/adhoc-report"
              [hidden]="sidebar['adhoc_report']"    >Adhoc Report</a>
          </li>
          <!--  <li>
                            <a class = "text-decoration-none"  routerLink="/accounts/ledger/report/trail-balance"    >GIS</a>
                        </li>
                        <li>
                            <a class = "text-decoration-none"  routerLink="/accounts/ledger/report/trail-balance"    >Labour Cess</a>
                        </li>
                        <li>
                            <a class = "text-decoration-none"  routerLink="/accounts/ledger/report/trail-balance"    >Royality</a>
                        </li> -->
          <!-- <li>
                            <a class = "text-decoration-none"  routerLink="/accounts/ledger/report/trail-balance"    >LIC</a>
                        </li> -->



        </ul>
      </li>


      <li    >
        <a class="text-decoration-none" href="#payableCol" (click)="rotateIcon('rotateButtonElem2')"  data-bs-toggle="collapse" [hidden]="sidebar['acc_payble']"><i
            class="sidebar-item-icon fa fa-money"></i>
          <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'Sidebar10']}}
            <!-- Accounts Payable -->
          </span><i class="fa fa-angle-left arrow" #rotateButtonElem2 ></i></a>
        <ul class="nav-2-level collapse"    id="payableCol" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/accounts/bill" [hidden]="sidebar['bill']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar11']}}
              <!-- Bills -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/dep-bill" [hidden]="sidebar['dep_bill']"
                 >Department Bill
              <!-- Bills -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/bank-payment" [hidden]="sidebar['bank_payment']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar12']}}
              <!-- Bank Payment -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/advice" [hidden]="sidebar['advice']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar13']}}
              <!-- Advice -->
            </a>
          </li>

        </ul>
      </li>

      <li    >
        <a class="text-decoration-none" href="#recCol" data-bs-toggle="collapse" [hidden]="sidebar['acc_receivable']"><i
            class="sidebar-item-icon fa fa-get-pocket"></i>
          <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'Sidebar14']}}
            <!-- Accounts Receivable -->
          </span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse"    id="recCol" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/accounts/challan" [hidden]="sidebar['challan']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar15']}}
              <!-- Challans -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/dep-challan" [hidden]="sidebar['dep_challan']"
                 >Department Challan
              <!-- Bills -->
            </a>
          </li>
          <!-- <li>
                            <a class = "text-decoration-none"  routerLink="/accounts/demand"
                                   >
                                Demand
                            </a>
                        </li> -->
          <!-- <li>
                            <a class = "text-decoration-none"  routerLink="/accounts/challan"
                                   >
                                Challan Settlement
                            </a>
                        </li> -->

        </ul>
      </li>
      <!-- <li>
                    <a class = "text-decoration-none"  href="javascript:;"      routerLink="/accounts/event-group"><i class="sidebar-item-icon fa fa-th-large"></i>
                        <span class="nav-label"></span>Event-Group
                    </a>
                </li> -->
      <li    >
        <a class="text-decoration-none" href="#tresCol" data-bs-toggle="collapse" [hidden]="sidebar['trasury']"><i
            class="sidebar-item-icon fa fa-id-card"></i>
          <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'Sidebar17']}}
            <!-- Treasury -->
          </span><i class="fa fa-angle-left arrow"></i></a>
        <ul id="tresCol" data-bs-parent="#sidebar-collapse" class="nav-2-level collapse"   >
          <li>
            <a class="text-decoration-none" routerLink="/accounts/tresuary/contra" [hidden]="sidebar['contra']"
                 >
              Contra
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/tresuary/bank-account"
              [hidden]="sidebar['bank_account']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar20']}}
              <!-- Bank Account -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/tresuary/gateway-mid-configuration"
              [hidden]="sidebar['gateway_mid_config']"    >Gateway MID
              Configuration
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/closing-stock" [hidden]="sidebar['closing_stock']"
                 >Closing Stock
            </a>
          </li>
        </ul>
      </li>


      <li    >
        <a class="text-decoration-none" href="#ledgerCol" data-bs-toggle="collapse" [hidden]="sidebar['ledger']"><i
            class="sidebar-item-icon fa fa-book"></i>
          <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'Sidebar21']}}
            <!-- Ledger -->
          </span><i class="fa fa-angle-left arrow"></i></a>
        <ul id="ledgerCol" data-bs-parent="#sidebar-collapse" class="nav-2-level collapse"   >
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/jv" [hidden]="sidebar['journal']"
                 >
              Journals
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/report/trail-balance"
              [hidden]="sidebar['trail_balance']"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar3']}}
              <!-- Trial Balance -->
            </a>
          </li>

          <!--   <li>
                            <a class = "text-decoration-none"  routerLink="/accounts/budget"    >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar24']}}
                                    Budget</a>
                        </li> -->

          <li>
            <a class="text-decoration-none" routerLink="/accounts/ledger/jrnl" [hidden]="sidebar['manual_jrnl']"
                 >
              Manual Journal
            </a>
          </li>


        </ul>
      </li>

      <li    >
        <a class="text-decoration-none"   href="#admincol" data-bs-toggle="collapse"
          [hidden]="sidebar['administration']"><i class="sidebar-item-icon fa fa-user-plus"></i>
          <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'Sidebar29']}}
            <!-- Administration -->
          </span><i class="fa fa-angle-left arrow"></i></a>
        <ul id="admincol" data-bs-parent="#sidebar-collapse" class="nav-2-level collapse"   >

          <div id="child1"  >



            <li    >
              <a class="text-decoration-none" href="#codeValue" data-bs-toggle="collapse" [hidden]="sidebar['master']"><i
                  class="sidebar-item-icon "></i>
                <span class="nav-label">
                  <!-- {{mainService.allLableShowObj[mainService.language_cd+'Sidebar21']}} -->
                  Master Data
                </span><i class="fa fa-angle-left arrow"></i></a>
              <ul class="nav-3-level collapse collapse2" id="codeValue" data-bs-parent="#child1"    >
                <li>
                  <a class="text-decoration-none" routerLink="/accounts/setting/code-value"
                    [hidden]="sidebar['code_value']"
                       >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar41']}}
                    <!-- Code-Value -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/accounts/setting/fin-year" [hidden]="sidebar['fin_year']"
                       >
                    <!-- {{mainService.allLableShowObj[mainService.language_cd+'Sidebar23']}} -->
                    Fiscal Year
                  </a>
                </li>
                <!-- <li>
                                    <a class = "text-decoration-none"  routerLink="/accounts/ledger/coa"    >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar33']}} -->
                <!-- Chart Of Account -->
                <!-- </a>
                                </li> -->
                <li>
                  <a class="text-decoration-none" routerLink="/accounts/ledger/org-hier"
                    [hidden]="sidebar['organisation_hier']"    >Organization
                  </a>
                </li>

                <li>
                  <a class="text-decoration-none" href="javascript:;"
                    [hidden]="sidebar['cost_center']" routerLink="/accounts/cost-center">
                    <span class="nav-label"></span>Cost Center
                  </a>
                </li>

                <li>
                  <a class="text-decoration-none" routerLink="/accounts/ledger/events"
                    [hidden]="sidebar['business_event']"    >
                    <!-- {{mainService.allLableShowObj[mainService.language_cd+'Sidebar31']}} -->
                    Business Event
                  </a>
                </li>
                <!--  <li>
                                    <a class = "text-decoration-none"  routerLink="/accounts/ledger/report/party-report"    >Chart of Account (Ledger)</a>
                                </li> -->
                <li>
                  <a class="text-decoration-none" routerLink="/accounts/ledger/rule" [hidden]="sidebar['acc_rule']"
                       >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar32']}}
                    <!-- Accounting Rule -->
                  </a>
                </li>


              </ul>
            </li>

            <!--
                        <li>
                            <a class = "text-decoration-none"  routerLink="/accounts/setting/hsn"    >
                                GST
                            </a>
                        </li>
                        <li>
                            <a class = "text-decoration-none"  routerLink="/accounts/setting/deduction-mapping"    >
                                Deduction Mapping
                            </a>
                        </li> -->

            <li    >
              <a class="text-decoration-none" href="#hier" data-bs-toggle="collapse" [hidden]="sidebar['hierarchy']">
                <span class="nav-label">
                  Hierarchy
                </span><i class="fa fa-angle-left arrow"></i></a>
              <ul class="nav-2-level collapse collapse2" id="hier" data-bs-parent="#child1"   >
                <li>
                  <a class="text-decoration-none" routerLink="/accounts/ledger/bud-hier" [hidden]="sidebar['bud_hier']"
                       >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar24']}}
                    <!-- Budget Hierarchy -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/accounts/ledger/activity-hier"
                    [hidden]="sidebar['act_hier']"
                       >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar36']}}
                    <!-- Activity Hierarchy -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/accounts/ledger/proj-hier"
                    [hidden]="sidebar['proj_hier']"
                       >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar35']}}
                    <!-- Project Hierarchy -->
                  </a>
                </li>

                <li>
                  <a class="text-decoration-none" routerLink="/accounts/ledger/prod-hier"
                    [hidden]="sidebar['prod_hier']"
                       >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar37']}}
                    <!-- Product Hierarchy -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/accounts/ledger/coa" [hidden]="sidebar['coa']"
                       >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar33']}}
                    <!-- Chart Of Account -->
                  </a>
                </li>
              </ul>
            </li>

            <li    >
              <a class="text-decoration-none" href="#sec" data-bs-toggle="collapse" [hidden]="sidebar['security']">
                <span class="nav-label">
                  <!-- {{mainService.allLableShowObj[mainService.language_cd+'Sidebar42']}} -->
                  Security
                </span><i class="fa fa-angle-left arrow"></i></a>
              <ul class="nav-2-level collapse collapse2"  id="sec" data-bs-parent="#child1"   >


                <li>
                  <a class="text-decoration-none" routerLink="/accounts/administration/roles" [hidden]="sidebar['role']"
                       >
                    {{mainService.allLableShowObj[mainService.language_cd+'Sidebar43']}}
                    <!-- Role -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/accounts/administration/users" [hidden]="sidebar['user']"
                       >
                    <!-- {{mainService.allLableShowObj[mainService.language_cd+'Sidebar44']}} -->
                    Functional Authorization
                    <!-- Users Role Authorization -->
                  </a>
                </li>

                <li>
                  <a class="text-decoration-none" routerLink="/accounts/administration/dataAuth"
                    [hidden]="sidebar['data_auth']"    >
                    <!-- {{mainService.allLableShowObj[mainService.language_cd+'Sidebar44']}} -->
                    Data Authorization
                    <!-- Users Role Authorization -->
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/accounts/administration/account-appr"
                    [hidden]="sidebar['workflow']"    >
                    WorkFlow
                  </a>
                </li>

                <!-- <li>
                                    <a class = "text-decoration-none"  routerLink="/accounts/administration/hierarchy"    >
                                            {{mainService.allLableShowObj[mainService.language_cd+'Sidebar45']}}
                                             Hierarchy
                                        </a>
                                </li> -->
                <!-- <li>
                                    <a class = "text-decoration-none"  routerLink="/accounts/administration/data-assignment"    >
                                            {{mainService.allLableShowObj[mainService.language_cd+'Sidebar47']}}
                                            Data Assignment
                                        </a>
                                </li> -->
                <!-- <li>
                                    <a class = "text-decoration-none"  routerLink="/accounts/administration/account-appr"    >
                                        WorkFlow
                                    </a>
                                </li> -->
              </ul>

            </li>
            <li>
              <a class="text-decoration-none"        routerLink="/accounts/setting/party"
                [hidden]="sidebar['party']">
                <span class="nav-label" >
                  Party/Vendor
                </span>
              </a>
            </li>
            <li>
              <a class="text-decoration-none"      routerLink="/accounts/setting/work"
                [hidden]="sidebar['work']">
                <span class="nav-label">
                  Work
                </span>
              </a>
            </li>

            <li>
              <a class="text-decoration-none"   routerLink="/accounts/setting/project-bank"
                [hidden]="sidebar['proj_bank']"    >
                Project Bank Account
              </a>
            </li>
            <li>
              <a class="text-decoration-none"   routerLink="/accounts/setting/fixed-assets-mapping"
                [hidden]="sidebar['fixed_assets_mapping']"    >
                Fixed Asset Mapping
              </a>
            </li>




            <!-- <li>
                            <a class = "text-decoration-none"  routerLink="/accounts/setting/fields"    >Fields</a>
                        </li> -->
            <li>
              <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/accounts/setting/deduction-mapping"
                [hidden]="sidebar['deduction_mapping']"    >
                Deduction Mapping
              </a>
            </li>
            <li>
              <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/accounts/setting/hsn" [hidden]="sidebar['gst']"
                   >GST</a>
            </li>
            <li>
              <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/accounts/setting/acc-mapping"
                [hidden]="sidebar['accoun_mapping']"    > Account Mapping
              </a>
            </li>

          </div>
        </ul>
      </li>


      <li    >
        <a class="text-decoration-none" href="#helpCol" data-bs-toggle="collapse"><i
            class="sidebar-item-icon fa fa-question-circle"></i>
          <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'Sidebar48']}}
            <!-- Help -->
          </span><i class="fa fa-angle-left arrow"></i></a>
        <ul id="helpCol" data-bs-parent="#sidebar-collapse" class="nav-2-level collapse"   >
          <li>
            <a class="text-decoration-none" routerLink="/accounts/help/faq"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar49']}}
              <!-- FAQ -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/help/doc"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar50']}}
              <!-- User Manual -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/help/videos"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar51']}}
              <!-- Tutorial Videos -->
            </a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/accounts/help/online-support"
                 >{{mainService.allLableShowObj[mainService.language_cd+'Sidebar52']}}
              <!-- Online Support -->
            </a>
          </li>
        </ul>
      </li>



    </ul>
  </div>
</div>
