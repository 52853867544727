import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
declare var $: any
import swal from 'sweetalert2';
import { MainService } from '../../service/main.service';

@Component({
  selector: 'app-hra-matrix',
  templateUrl: './hra-matrix.component.html',
  styleUrls: ['./hra-matrix.component.css']
})
export class HraMatrixComponent implements OnInit {
applyFilter(arg0: any) {

}

  constructor(public mainService: MainService, private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser: any;
  b_acct_id: any;
  errorMsg: any = ''

  allMatrix: any = [];
  hraMatrix: any = {};


  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }

  displayedColumns :any = ['id', 'city_group',/* 'lower_limit','upper_limit', */ 'level_code', 'effective_dt', 'amount', 'action'];
  datasource:any ;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllHraMatrix();
    await this.getBasicMatrix();

  }
  open_update(element:any ) {
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    this.errorMsg = ''
    this.hraMatrix = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.hraMatrix = {};
    this.errorMsg = ''
  }
  allPayMatrix = [];
  level_code = [];
  async getBasicMatrix() {
    this.level_code = []
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.getMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allPayMatrix = resp.data;
      for (let i = 0; i < this.allPayMatrix.length; i++) {
        if (!this.level_code.includes(this.allPayMatrix[i]['level_code'])) this.level_code.push(this.allPayMatrix[i]['level_code'])
      }
      console.log(this.level_code)
      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire('Error', "Error while getting  All CCA Matrix", 'error')
      // this.snackBar.open("Error while getting  All CCA Matrix", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async getAllHraMatrix() {
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'ACTIVE';

    var resp = await this.settingService.gethraMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allMatrix = resp.data;
      console.log(this.allMatrix)
      this.datasource = new MatTableDataSource(this.allMatrix)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting  All CCA Matrix ', 'error')
      // this.snackBar.open("Error while getting  All CCA Matrix", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async save() {
    this.errorMsg = '';
    console.log(this.hraMatrix)
    if (
      this.hraMatrix['city_group'] == "" || this.hraMatrix['city_group'] == undefined
      || this.hraMatrix['lower_limit'] == "" || this.hraMatrix['lower_limit'] == undefined
      || this.hraMatrix['upper_limit'] == "" || this.hraMatrix['upper_limit'] == undefined
      || this.hraMatrix['effective_dt'] == "" || this.hraMatrix['effective_dt'] == undefined
      || this.hraMatrix['amount'] == "" || this.hraMatrix['amount'] == undefined
    ) {
      this.errorMsg = "* fields required."
    }

    else {

      swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.value) {
          this.finalsave()
        }
      })


    }
  }
  async finalsave() {
    this.spinner.show();
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;

    this.hraMatrix['create_user_id'] = this.erpUser.user_id;
    this.hraMatrix['b_acct_id'] = this.b_acct_id;
    obj['matrix_data'] = [this.hraMatrix];
    var resp = await this.settingService.addhraMatrix(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllHraMatrix();
      this.hraMatrix = {}
      swal.fire("Success", "Matrix Added Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Adding Matrix!", 'error');

    }
  }
  async update() {
    this.errorMsg = ''
    if (
      this.hraMatrix['city_group'] == "" || this.hraMatrix['city_group'] == undefined
      || this.hraMatrix['lower_limit'] == "" || this.hraMatrix['lower_limit'] == undefined
      || this.hraMatrix['upper_limit'] == "" || this.hraMatrix['upper_limit'] == undefined
      || this.hraMatrix['effective_dt'] == "" || this.hraMatrix['effective_dt'] == undefined
      || this.hraMatrix['amount'] == "" || this.hraMatrix['amount'] == undefined

    ) {

      this.errorMsg = "* fields required."
    }

    else {

      swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Update it!'
      }).then((result) => {
        if (result.value) {
          this.finalupdate()
        }
      })


    }
  }


  async finalupdate() {
    this.spinner.show();
    this.hraMatrix['update_user_id'] = this.erpUser.user_id;
    this.hraMatrix['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.updatehraMatrix(this.hraMatrix);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.hraMatrix = {}
      await this.getAllHraMatrix();
      // this.snackBar.open("Matrix Update Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Matrix Update Successfully!", 'success');


    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Updating Matrix!", 'error');

    }
  }
  async delete(element:any ) {
    this.spinner.show();
    var obj :any  = new  Object();
    obj['id'] = element.id;
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.settingService.deletehraMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllHraMatrix();
      swal.fire("Success", "Matrix Delete Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Deleting Matrix!", 'error');
    }
  }
  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {

    this.hraMatrix = {}
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }


}
