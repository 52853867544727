import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { AgmCoreModule } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
// import { ChartsModule } from 'ng2-charts';
import { NgChartsModule } from 'ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ToastrModule } from 'ngx-toastr';
import { MatTabsModule } from '@angular/material/tabs';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgtreegridModule } from 'ngtreegrid';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { NgApexchartsModule } from 'ng-apexcharts';
// import { ToastrModule } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import { HrmsLayoutsRoutingModule } from './hrms-layouts-routing.module';
import { AttendanceComponent } from "../arr/attendance/attendance.component";
import { ComplaintComponent } from "../arr/complaint/complaint.component";
import { DeathComponent } from "../arr/death/death.component";
import { EmpPromotionComponent } from "../arr/emp-promotion/emp-promotion.component";
import { EmpResignComponent } from "../arr/emp-resign/emp-resign.component";
import { EmpTransferComponent } from "../arr/emp-transfer/emp-transfer.component";
import { EnquiryComponent } from "../arr/enquiry/enquiry.component";
import { EstablishmentComponent } from "../arr/establishment/establishment.component";
import { LeavesApplyComponent } from "../arr/leaves-apply/leaves-apply.component";
import { LeavesComponent } from "../arr/leaves/leaves.component";
import { PostingComponent } from "../arr/posting/posting.component";
import { ProbationComponent } from "../arr/probation/probation.component";
import { ReappointmentComponent } from "../arr/reappointment/reappointment.component";
import { RetirementComponent } from "../arr/retirement/retirement.component";
import { SuspensionComponent } from "../arr/suspension/suspension.component";
import { TerminationComponent } from "../arr/termination/termination.component";
import { ShowDataComponent } from '../arr/show-data/show-data.component';

import { HrAdministrationComponent } from "../hr-administration/hr-administration.component";
import { HrDataAssignmentComponent } from "../hr-administration/hr-data-assignment/hr-data-assignment.component";
import { HrHierarchyComponent } from "../hr-administration/hr-hierarchy/hr-hierarchy.component";
import { HrRolesComponent } from "../hr-administration/hr-roles/hr-roles.component";
import { HrUsersComponent } from "../hr-administration/hr-users/hr-users.component";
import { HrWorkflowComponent } from "../hr-administration/hr-workflow/hr-workflow.component";
import { HrmsDashComponent } from "../hrms-dash/hrms-dash.component";
import { HrmsFaqComponent } from "../hrms-help/hrms-faq/hrms-faq.component";
import { HrmsHelpComponent } from "../hrms-help/hrms-help.component";
import { HrmsOnlineSupportComponent } from "../hrms-help/hrms-online-support/hrms-online-support.component";
import { HrmsTutorialComponent } from "../hrms-help/hrms-tutorial/hrms-tutorial.component";
import { HrmsUserManualComponent } from "../hrms-help/hrms-user-manual/hrms-user-manual.component";
import { HrmsNotificationComponent } from "../hrms-notification/hrms-notification.component";
import { AnnualStatementComponent } from "../hrms-report/annual-statement/annual-statement.component";
import { BillReportComponent } from "../hrms-report/bill-report/bill-report.component";
import { DeductionReportComponent } from "../hrms-report/deduction-report/deduction-report.component";
import { EmpReportComponent } from "../hrms-report/emp-report/emp-report.component";
import { HrmsReportComponent } from "../hrms-report/hrms-report.component";
import { LicReportComponent } from "../hrms-report/lic-report/lic-report.component";
import { LpcComponent } from "../hrms-report/lpc/lpc.component";
import { LpctransferComponent } from "../hrms-report/lpctransfer/lpctransfer.component";
import { PayrollReportComponent } from "../hrms-report/payroll-report/payroll-report.component";
import { PensionContributionComponent } from "../hrms-report/pension-contribution/pension-contribution.component";
import { SalarySlipComponent } from "../hrms-report/salary-slip/salary-slip.component";
import { StopReportComponent } from "../hrms-report/stop-report/stop-report.component";
import { VarAnnualStatementComponent } from "../hrms-report/var-annual-statement/var-annual-statement.component";
import { CcaMatrixComponent } from "../hrms-setting/cca-matrix/cca-matrix.component";
import { CityMatrixComponent } from "../hrms-setting/city-matrix/city-matrix.component";
import { HrBudgetInfoComponent } from "../hrms-setting/hr-budget-info/hr-budget-info.component";
import { HrProductInfoComponent } from "../hrms-setting/hr-product-info/hr-product-info.component";
import { HraMatrixComponent } from "../hrms-setting/hra-matrix/hra-matrix.component";
import { HrmsCodeValueComponent } from "../hrms-setting/hrms-code-value/hrms-code-value.component";
import { HrmsFieldComponent } from "../hrms-setting/hrms-field/hrms-field.component";
import { HrmsSettingComponent } from "../hrms-setting/hrms-setting.component";
import { LeaveInfoComponent } from "../hrms-setting/leave-info/leave-info.component";
import { PayComponentComponent } from "../hrms-setting/pay-component/pay-component.component";
import { PayMatrixComponent } from "../hrms-setting/pay-matrix/pay-matrix.component";
import { SalaryComponentDefinitionComponent } from "../hrms-setting/salary-component-definition/salary-component-definition.component";
import { SectionComponent } from "../hrms-setting/section/section.component";
import { SettingPensionContributionComponent } from "../hrms-setting/setting-pension-contribution/setting-pension-contribution.component";
import { MyinfoComponent } from "../myinfo/myinfo.component";
import { AllEmpComponent } from "../party/all-emp/all-emp.component";
import { BankAccountInfoComponent } from "../party/bank-account-info/bank-account-info.component";
import { DependentInfoComponent } from "../party/dependent-info/dependent-info.component";
import { EducationInfoComponent } from "../party/education-info/education-info.component";
import { EmplistComponent } from "../party/emplist/emplist.component";
import { JoiningComponent } from "../party/joining/joining.component";
import { LicComponent } from "../party/lic/lic.component";
import { NomineeInfoComponent } from "../party/nominee-info/nominee-info.component";
import { PartyComponent } from "../party/party.component";
import { PersonalInfoComponent } from "../party/personal-info/personal-info.component";
import { AnnualIncrementComponent } from "../payroll/annual-increment/annual-increment.component";
import { FixedPayComponent } from "../payroll/fixed-pay/fixed-pay.component";
import { LeaveEncashmentComponent } from "../payroll/leave-encashment/leave-encashment.component";
import { LoanComponent } from "../payroll/loan/loan.component";
import { OtherPaymentComponent } from "../payroll/other-payment/other-payment.component";
import { PaidSalaryComponent } from "../payroll/paid-salary/paid-salary.component";
import { PayrollComponent } from "../payroll/payroll.component";
import { SalaryBillComponent } from "../payroll/salary-bill/salary-bill.component";
import { SalaryHoldAndStartComponent } from "../payroll/salary-hold-and-start/salary-hold-and-start.component";
import { AllArrearComponent } from "../payroll/variable-pay/all-arrear/all-arrear.component";
import { DaArrearComponent } from "../payroll/variable-pay/da-arrear/da-arrear.component";
import { LeaveencashArrearComponent } from "../payroll/variable-pay/leaveencash-arrear/leaveencash-arrear.component";
import { VariablePayComponent } from "../payroll/variable-pay/variable-pay.component";
import { ArrComponent } from '../arr/arr.component';
import { HrmsFooterComponent } from './hrms-footer/hrms-footer.component';
import { HrmsHeaderComponent } from './hrms-header/hrms-header.component';
import { HrmsLayoutsComponent } from './hrms-layouts.component';
import { HrmsSidebarComponent } from './hrms-sidebar/hrms-sidebar.component';
import { SharedModule } from '../../shared/shared.module';
import { SalaryRuleComponent } from '../hrms-setting/salary-rule/salary-rule.component';

import { AttenReportComponent } from '../hrms-report/atten-report/atten-report.component'


// Avinash
import { CreateRuleComponent } from '../attendance_manag/create-rule/create-rule.component';

import { ExtraWorkComponent } from '../attendance_manag/extra_work_management/extra-work/extra-work.component';
import { LateSittingComponent } from '../attendance_manag/late-sitting/late-sitting.component';
import { LeaveListComponent } from '../attendance_manag/leave-list/leave-list.component';

//import { CalendarRuleComponent } from '../attendance_manag/calendar-rule/calendar-rule.component';
import { CalendarRuleComponent } from '../attendance_manag/calendar-rule/calendar-rule.component';
// import { FullCalendarModule } from '@fullcalendar/angular';

import { AcrComponent } from '../annual_conf_report/acr/acr.component';
import { BoundryMapComponent } from '../attendance_manag/boundry-map/boundry-map.component';
// import { MarkAttendnaceComponent } from '../mark-attendnace/mark-attendnace.component';
import { PbrMatrixComponent } from '../hrms-setting/pbr-matrix/pbr-matrix.component';
import { MarkAttendanceComponent } from '../mark-attendance/mark-attendance.component'
// import { WebcamModule } from 'ngx-webcam';
import { WelfareFundComponent } from '../hrms-setting/welfare-fund/welfare-fund.component';
import { TaBillComponent } from '../travelling-reimburement/ta-bill/ta-bill.component';
import { TravellingReimburementComponent } from '../travelling-reimburement/travelling-reimburement.component';
import { TourDetailComponent } from '../travelling-reimburement/tour-detail/tour-detail.component';
import { MkattendComponent } from '../mkattend/mkattend.component';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [
    ShowDataComponent,
    HrmsLayoutsComponent,
    HrmsHeaderComponent,
    HrmsFooterComponent,
    HrmsSidebarComponent,
    PensionContributionComponent,
    LeaveencashArrearComponent,
    DaArrearComponent,
    LicReportComponent,
    LeaveEncashmentComponent,
    AnnualIncrementComponent,
    SettingPensionContributionComponent,
    HrmsNotificationComponent,
    SalaryHoldAndStartComponent,
    LicComponent,
    HrmsReportComponent,
    EmpReportComponent,
    BillReportComponent,
    PayrollReportComponent,
    LpcComponent,
    HrmsDashComponent,
    ArrComponent,
    PartyComponent,
    AllEmpComponent,
    DependentInfoComponent,
    JoiningComponent,
    PersonalInfoComponent,
    EducationInfoComponent,
    NomineeInfoComponent,
    BankAccountInfoComponent,
    EstablishmentComponent,
    DeductionReportComponent,
    EmpPromotionComponent,
    EnquiryComponent,
    PostingComponent,
    ComplaintComponent,
    PayrollComponent,
    VariablePayComponent,
    FixedPayComponent,
    SalaryBillComponent,
    SuspensionComponent,
    PaidSalaryComponent,
    LoanComponent,
    LeavesComponent,
    LeavesApplyComponent,
    RetirementComponent,
    DeathComponent,
    TerminationComponent,
    ReappointmentComponent,
    EmpResignComponent,
    EmpTransferComponent,
    ProbationComponent,
    AttendanceComponent,
    HrmsSettingComponent,
    HrmsCodeValueComponent,
    HrmsFieldComponent,
    LeaveInfoComponent,
    EmplistComponent,
    HrBudgetInfoComponent,
    HrProductInfoComponent,
    PayComponentComponent,
    CityMatrixComponent,
    CcaMatrixComponent,
    HraMatrixComponent,
    MyinfoComponent,
    HrWorkflowComponent,
    AllArrearComponent,
    OtherPaymentComponent,
    SalarySlipComponent,
    SalaryComponentDefinitionComponent,
    SectionComponent,
    PayMatrixComponent,
    StopReportComponent,
    AnnualStatementComponent,
    HrAdministrationComponent,
    HrRolesComponent,
    HrUsersComponent,
    HrHierarchyComponent,
    HrDataAssignmentComponent,
    HrmsHelpComponent,
    HrmsFaqComponent,
    HrmsOnlineSupportComponent,
    HrmsTutorialComponent,
    HrmsUserManualComponent,
    LpctransferComponent,
    VarAnnualStatementComponent,
    SalaryRuleComponent,

    AttenReportComponent,
    CreateRuleComponent,
    ExtraWorkComponent,
    CalendarRuleComponent,
    LateSittingComponent,
    LeaveListComponent,
    AcrComponent,
    BoundryMapComponent,
    MarkAttendanceComponent,
    // MarkAttendnaceComponent,
    PbrMatrixComponent,
    WelfareFundComponent,
    TaBillComponent,
    TravellingReimburementComponent,
    TourDetailComponent,
    MkattendComponent,
  ],
  exports: [
    HrmsLayoutsComponent,
    HrmsHeaderComponent,
    HrmsFooterComponent,
    HrmsSidebarComponent,

  ],
  imports: [
    // WebcamModule,
    SharedModule,
    CommonModule,
    HrmsLayoutsRoutingModule,
    // FullCalendarModule,
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    //   apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    NgSelectModule,
    NgChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    MatSlideToggleModule,
    FormsModule,
    // NgtreegridModule,
    MatStepperModule,
    MatCheckboxModule,
    GoogleMapsModule,
    MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    MatTabsModule,
    ToastrModule.forRoot(),
  ],
  // schemas:[
  //   NO_ERRORS_SCHEMA
  // ]
})
export class HrmsLayoutsModule { }
