import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { MainService } from '../../service/main.service';
import { ComplainServiceService } from '../../service/complain-service.service';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService as mainService_MD } from '../../../md/service/main.service'
import {DataControlService} from '../../service/data-control.service'
declare var $: any;
@Component({
  selector: 'app-user-complaint',
  templateUrl: './user-complaint.component.html',
  styleUrls: ['./user-complaint.component.css']
})
export class UserComplaintComponent implements OnInit {
  erpUser: any;
  b_acct_id: any;
  displayedColumns = ['s.no', 'Complaint_ID', 'zone_cd', 'Complaint_type', 'Complaint_Date',  'Complaint_Status', 'action'];
  datasource: any;
  complaint = true
  complaint_detail: any = {};
  complaint_image: any = [];
  userdetail: any = [];
  inspection_detail: any = {};
  assign_Obj: any = {};
  description: any = [];
  imageflag = false;
  imgURL1: any;
  complaint_inspection = false
  inspection_image: any = [];
  section_detail: any;
  memoimageflag = false;
  memo_image: any = [];
  imgURL3: any;
  user_id: any;
  imgURL2: any;
  feedbackimage: boolean = false;;
  imgURL4: any;

  constructor(public ComplainService: ComplainServiceService, private sanitizer: DomSanitizer,
     private spinner: NgxSpinnerService, private mainServiceMD: mainService_MD, private dataControlService:DataControlService) { }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  accessed_zone=[];
  accessed_complain =[]
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    this.user_id = this.erpUser.le_id
    let asscssedData = await this.dataControlService.getmappedcomplain({ user_id: this.user_id, b_acct_id: this.b_acct_id });
    this.accessed_zone = asscssedData.zone_array;
    this.accessed_complain = asscssedData.compain_array;
    console.log("asscssedData : ", asscssedData)

    await this.getcomplaindata()
  }



  //complaint = true


  async getcomplaindata() {
    const obj :any= {}
    // console.log(this.b_acct_id)
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_from'] = 'Employee'
    obj['user_id'] = this.user_id;
    obj['zone_cd'] = this.accessed_zone;

    var resp :any= await this.ComplainService.getComplainData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      // this.datasource = resp['data']
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
    }

  }
  async viewDetails(element:any) {
    this.complaint_detail = element
    $('#myModal1').modal('show');
    this.getphotodetail();
    this.getuserinfo();
    this.inspection(element);
    this.getfeedbackdetails();
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getinspectionDescription(JSON.stringify(obj));
    // console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error');
      return
    } else {
      this.description = resp['data'];
      console.log(this.description);
    }

  }

  async getphotodetail() {
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_type'] = "Inspection"
    obj['complain_id'] = this.complaint_detail['complain_id']
    // console.log(obj)
    var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.complaint_image = resp['data']
      // console.log(this.complaint_image);
    }
  }
  async getuserinfo() {
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getuserinfo(JSON.stringify(obj));
    // console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {

      this.userdetail = resp['data']
      this.complaint_detail['party_name'] = this.userdetail[0]['party_name']
      for(let i=0;i<this.userdetail.length;i++){
        if(this.userdetail[i]['role_cd'] == "ICMS_EMP" ){
         this.userdetail[i]['role_name'] = undefined

        }

   }
      // console.log(this.userdetail);
    }


  }
  async getinspectionphotodetail() {
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Inspection";
    // console.log(obj)
    var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.inspection_image = resp['data']
      // console.log(this.inspection_image)
    }
  }
  async inspection(element:any) {
    this.complaint_detail = element
    this.getinspectionphotodetail();
    this.getmemophoto();
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getinspectionData(JSON.stringify(obj))
    // console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    }
    else {
      this.inspection_detail = Object.assign({}, ...resp['data'][0]);
      this.section_detail = resp['data'][1]
    }

  }
  view_inspection_photo() {
    this.imageflag = false
    $('#inspection_photo').modal('show');

  }
  view_memo_img() {
    this.memoimageflag = false;
    $('#memo_photo').modal('show');
    this.view_memo()
  }

  async getmemophoto() {
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Memo";
    // console.log(obj)
    var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.memo_image = resp['data']
      // console.log(this.memo_image)
    }
  }




  view_photo() {
    // console.log(this.complaint_image.length)
    this.imageflag = false
    if (this.complaint_image.length > 0) {
      $('#staticBackdrop').modal('show');
      return
    }
    else {
      Swal.fire('info', "No image found", 'info');
    }

  }
  async view(i:any) {
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.complaint_image[i]['doc_id']
    obj['file_name'] = this.complaint_image[i]['file_name']
    obj['complain_id'] = this.complaint_image[i]['complain_id']
    // console.log(obj)
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.imageflag = true
      // console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL1 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL1 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL1 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
      // console.log(this.imgURL1, 'imgURL1')

    }


  }
  async view_inspection(i:any) {
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.inspection_image[i]['doc_id']
    obj['file_name'] = this.inspection_image[i]['file_name']
    obj['complain_id'] = this.inspection_image[i]['complain_id']
    // console.log(obj)
    this.spinner.show();
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.imageflag = true
      // console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL2 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.spinner.hide();
        this.imgURL2 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.spinner.hide();
        this.imgURL2 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
      // console.log(this.imgURL1, 'imgURL1')

    }


  }
  async view_memo(){

    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.memo_image[0]['doc_id']
    obj['file_name'] = this.memo_image[0]['file_name']
    obj['complain_id'] = this.memo_image[0]['complain_id']
    // console.log(obj)
    this.spinner.show();
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.memoimageflag = true;
      // console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL3 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.spinner.hide();
        this.imgURL3 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL3 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
    // console.log(this.imgURL3)

    }



  }
  // forward(element) {
  //   // this.complaint_detail = {};
  //   this.complaint_detail = element
  //   $('#staticBackdrop2').modal('show');

  // }
  // async forward2() {
  //   this.assign_Obj['b_acct_id'] = this.b_acct_id
  //   this.assign_Obj['life_cycle_status'] = "ASSIGNED"
  //   this.assign_Obj['complain_id'] = this.complaint_detail['complain_id']
  //   this.assign_Obj['status'] = "UNDER-INSPECTION"
    // console.log(this.assign_Obj);
  //   var resp:any = await this.ComplainService.insertassigncomplain(this.assign_Obj)
    // console.log(resp)
  //   if (resp['error'] == true) {
  //     Swal.fire('error', 'server side error', 'error');
  //     return
  //   } else {
  //     Swal.fire('success', 'compalint forward successfully', 'success');
  //     this.getcomplaindata();
  //     $('#staticBackdrop2').modal('hide');
  //   }
  //   this.assign_Obj = {};


  // }



// get feedback details
view_feedback(){
  $('#feedback_img').modal('show');

}
feedbackdetail = []
async getfeedbackdetails(){

  console.log( this.complaint_detail)
  let obj:any = {}
  obj['b_acct_id'] = this.b_acct_id
  obj['complaint_id'] = this.complaint_detail['complain_id']
  var resp:any = await this.ComplainService.getfeedbackdetails(JSON.stringify(obj));
  if (resp['error'] == true) {
    Swal.fire('error', "server side error", 'error');
    return
  } else {
    this.feedbackdetail = resp['data'];
    console.log(this.feedbackdetail);
  }
 this.getfeedbackdocument();

}
feedbackimg:any = [];
async getfeedbackdocument(){
console.log(this.feedbackdetail)
  console.log( this.complaint_detail)
  let obj :any= {}
  obj['b_acct_id'] = this.b_acct_id
  obj['complain_id'] = this.feedbackdetail[0]['feedback_id']
  var resp:any = await this.ComplainService.getfeedbackdocuments(JSON.stringify(obj));
  console.log(resp)
  if (resp['error'] == true) {
    Swal.fire('error', "server side error", 'error');
    return
  } else {
    this.feedbackimg = resp['data'];
    // console.log(this.description);
  }


}

async view_feedback_photo(i:any) {
  this.feedbackimage = false;

  const obj :any= {}
  obj['b_acct_id'] = this.b_acct_id
  obj['doc_id'] = this.feedbackimg[i]['doc_id']
  obj['file_name'] = this.feedbackimg[i]['file_name']

  console.log(obj)
  this.spinner.show();
  let resp: any = await this.ComplainService.feedback(obj);
  console.log(resp);
  if (resp) {
    this.feedbackimage = true
    console.log(resp, 'res')
    this.spinner.hide();
    this.imgURL4 = resp;
    var docname = obj['file_name'];
    var ext = docname.split('.');
    if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
      const unsafeImageUrl = window.URL.createObjectURL(resp);
      this.spinner.hide();
      this.imgURL4 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
    } else {
      const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
      this.imgURL4 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    }

  console.log(this.imgURL4)
  }



}






}
