import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
import { MainService } from '../../service/main.service';
import { Console } from 'console';
import { IfStmt } from '@angular/compiler';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EstablishmentService } from '../../service/establishment.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-leave-info',
  templateUrl: './leave-info.component.html',
  styleUrls: ['./leave-info.component.css']
})
export class LeaveInfoComponent implements OnInit {


  constructor(private payableService: EstablishmentService, public mainService: MainService, private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser: any;
  b_acct_id: any;

  allLeaveInfo: any = [];
  obj: any = {};
  leaveObj: any = {}
  // print_flag = true
  flag_cl: any = true
  flag_ml: any = true
  flag_md: any = true
  //[disabled]="booleanValue"
  print_flag: boolean = true
  print_flag_1: boolean = true
  //print_flag: boolean= false
  // print_flag1: boolean= true
  ALLleave: any
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }

  displayedColumns = ['id', 'leave_code', 'leave_rate', 'is_leave_carry_fwd', 'renew_ind_on_year_change', 'num_of_leaves', 'action'];
  datasource:any ;

  async ngOnInit() {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllLeaveRuleDetails();
  }

  open_update(element :any  ) {
    this.obj = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.obj = {};
  }

  async getAllLeaveRuleDetails() {
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.getAllRules(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allLeaveInfo = resp.data;

      this.datasource = new MatTableDataSource(this.allLeaveInfo)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();

    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting  all Leave Rule list ', 'error')
      // this.snackBar.open("Error while getting  all Leave Rule list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async adjustLeave() {
    this.leaveObj['b_acct_id'] = this.b_acct_id;
    this.leaveObj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.payableService.adjustLeave(this.leaveObj);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Adjust Leave Operation Successfully! ', 'success')
      // this.snackBar.open("Adjust Leave Operation Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      Swal.fire('Error', ' Operation Failed', 'error')
      // this.snackBar.open("Operation Failed", 'Error', {
      //   duration: 5000
      // });
    }
  }

  // async carrForward() {
  //   this.leaveObj['b_acct_id'] = this.b_acct_id;
  //   this.leaveObj['create_user_id'] = this.b_acct_id;
  //   var obj=new Object();
  //   obj['b_acct_id']=this.b_acct_id;
  //   obj['leave_data']= this.createLeave(parseInt(this.leaveObj['year']),parseInt(this.leaveObj['month']),this.allLeaveInfo);


  //   this.spinner.show();
  //   var resp = await this.payableService.addLeaveInfo(obj);
  //   if (resp['error'] == false) {
  //     this.spinner.hide();
  //    // this.getAllLeaveInfo();
  //     this.snackBar.open("Carry Forward Operation Successfully!", 'Success', {
  //       duration: 5000
  //     });
  //   } else {
  //     this.spinner.hide();
  //     this.snackBar.open("Operation Failed", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }

  //
  // createLeave(year, month, allLeaveInfo) {
  //   var returnArr = []
  //   let carryYear = 0
  //   let carryMonth = 0

  //   //if let year end is 12th month its working on calendar year

  //   if (month == 12) {
  //       carryMonth = 1
  //       carryYear = year + 1
  //   } else {
  //       carryMonth = month + 1
  //       carryYear = year
  //   }

  //   //LeaveInfo Filter start//

  //   var temp=[];
  //   for(let i=0;i<allLeaveInfo.length;i++){
  //     if(allLeaveInfo[i]['month']==month && allLeaveInfo[i]['year']==year){
  //       temp.push(allLeaveInfo[i]);
  //     }
  //   }

  //   allLeaveInfo=temp;
  // //LeaveInfo Filter end //
  //   var ruleObj = new Object;
  //   // for (let i = 0; i < allLeaveRule.length; i++) {
  //   //     ruleObj[allLeaveRule[i]['leave_code']] = Object.assign({}, allLeaveRule[i])
  //   // }

  //   for (let j = 0; j < allLeaveInfo.length; j++) {
  //       let obj = new Object
  //       obj['month'] = carryMonth
  //       obj['year'] = carryYear;
  //       obj['create_user_id']=this.erpUser.user_id;
  //       obj['emp_id'] = allLeaveInfo[j]['emp_id']
  //       obj['leave_code'] = allLeaveInfo[j]['leave_code']
  //       let leaveRuleObj = Object.assign({}, ruleObj[allLeaveInfo[j]['leave_code']])

  //       ////************************************************************LIFETIME*********************************** */
  //       if (leaveRuleObj['leave_rate'] == 'LIFETIME') {
  //           obj['num_of_leaves'] = allLeaveInfo[j]['num_of_leaves']
  //           obj['leaves_remaining'] = allLeaveInfo[j]['leaves_remaining']
  //           returnArr.push(obj)
  //       }
  //       ////************************************************************MONTHLY*********************************** */

  //       if (leaveRuleObj['leave_rate'] == 'MONTHLY') {
  //           if (month < 12) {
  //               if (leaveRuleObj['is_leave_carry_fwd'] == 1) {
  //                   obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                   obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                   returnArr.push(obj)
  //               } else {
  //                   obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
  //                   obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
  //                   returnArr.push(obj)

  //               }

  //           }
  //           if (month == 12) {
  //               if (leaveRuleObj['is_leave_carry_fwd'] == 1 && leaveRuleObj['renew_ind_on_year_change'] == 0) {
  //                   obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                   obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                   returnArr.push(obj)
  //               } else {
  //                   obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
  //                   obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
  //                   returnArr.push(obj)

  //               }
  //           }

  //       }

  //       ////************************************************************QUATERLY*********************************** */

  //       if (leaveRuleObj['leave_rate'] == 'QUATERLY') {
  //           let quaterArr = [3, 6, 9, 12]
  //           if (quaterArr.includes(month)) {
  //               if (month < 12) {
  //                   if (leaveRuleObj['is_leave_carry_fwd'] == 1) {
  //                       obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                       obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                       returnArr.push(obj)
  //                   } else {
  //                       obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
  //                       obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
  //                       returnArr.push(obj)

  //                   }

  //               }
  //               if (month == 12) {
  //                   if (leaveRuleObj['is_leave_carry_fwd'] == 1 && leaveRuleObj['renew_ind_on_year_change'] == 0) {
  //                       obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                       obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                       returnArr.push(obj)
  //                   } else {
  //                       obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
  //                       obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
  //                       returnArr.push(obj)

  //                   }
  //               }
  //           }
  //           else {
  //               obj['num_of_leaves'] = allLeaveInfo[j]['num_of_leaves']
  //               obj['leaves_remaining'] = allLeaveInfo[j]['leaves_remaining']
  //               returnArr.push(obj)
  //           }


  //       }


  //       ////************************************************************HALFYEARLY*********************************** */

  //       if (leaveRuleObj['leave_rate'] == 'HALFYEARLY') {
  //           let halfYearlyArr = [6, 12]
  //           if (halfYearlyArr.includes(month)) {
  //               if (month < 12) {
  //                   if (leaveRuleObj['is_leave_carry_fwd'] == 1) {
  //                       obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                       obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                       returnArr.push(obj)
  //                   } else {
  //                       obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
  //                       obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
  //                       returnArr.push(obj)

  //                   }

  //               }
  //               if (month == 12) {
  //                   if (leaveRuleObj['is_leave_carry_fwd'] == 1 && leaveRuleObj['renew_ind_on_year_change'] == 0) {
  //                       obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                       obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                       returnArr.push(obj)
  //                   } else {
  //                       obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
  //                       obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
  //                       returnArr.push(obj)

  //                   }
  //               }
  //           }
  //           else {
  //               obj['num_of_leaves'] = allLeaveInfo[j]['num_of_leaves']
  //               obj['leaves_remaining'] = allLeaveInfo[j]['leaves_remaining']
  //               returnArr.push(obj)
  //           }


  //       }




  //       ////************************************************************YEARLY*********************************** */

  //       if (leaveRuleObj['leave_rate'] == 'YEARLY') {


  //           if (month == 12) {
  //               if (leaveRuleObj['is_leave_carry_fwd'] == 1 && leaveRuleObj['renew_ind_on_year_change'] == 0) {
  //                   obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                   obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
  //                   returnArr.push(obj)
  //               } else {
  //                   obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
  //                   obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
  //                   returnArr.push(obj)

  //               }
  //           }

  //           else {
  //               obj['num_of_leaves'] = allLeaveInfo[j]['num_of_leaves']
  //               obj['leaves_remaining'] = allLeaveInfo[j]['leaves_remaining']
  //               returnArr.push(obj)
  //           }


  //       }
  //   }
  // return returnArr;
  // }
  //
  //

  async save() {
    this.spinner.show();
    this.obj['create_user_id'] = this.erpUser.user_id;
    this.obj['b_acct_id'] = this.b_acct_id;

    //this.leaveObj['emp_id'] = this.selectEmpObj['emp_id'];
    this.leaveObj['create_user_id'] = this.erpUser.user_id;
    this.leaveObj['leaves_remaining'] = this.leaveObj['num_of_leaves'];
    this.leaveObj['carry_forward_leaves'] = 0;
    this.leaveObj['adjust_remaining_leaves'] = 0;
    //var sp = this.leaveObj['eff_dt'].split("-");
    // this.leaveObj['year'] = sp[0];
    // this.leaveObj['month'] = parseInt(sp[1])
    this.obj['leave_data'] = [this.leaveObj];
    var resp = await this.settingService.addLeaveRule(this.obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide();
      this.ALLleave = resp.data
      console.log(this.ALLleave)
      await this.getAllLeaveRuleDetails();
      Swal.fire('Success', 'Leave Rule Added Successfully! ', 'success')
      // this.snackBar.open("Leave Rule Added Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while Adding Leave Rule ', 'error')
      // this.snackBar.open("Error while Adding Leave Rule", 'Error', {
      //   duration: 5000
      // });
    }
  }
  //
  changeLeaveType() {
    console.log(this.obj)
    console.log(this.obj['leave_code'])
    if (this.obj['leave_rate'] == "LIFETIME") {
      this.print_flag = false
      this.print_flag_1 = false
      console.log(this.print_flag)
      console.log(this.print_flag_1)
    }
    else if (this.obj['leave_rate'] == "YEARLY") {
      this.print_flag_1 = true
      this.print_flag = false
      //this.print_flag=this.flag_yl
    }
    else {
      this.print_flag = true
      this.print_flag_1 = true
    }

  }
  async update() {
    this.spinner.show();
    this.obj['update_user_id'] = this.erpUser.user_id;
    this.obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.updateLeaveRule(this.obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllLeaveRuleDetails();
      Swal.fire('Success', 'Leave Rule Update Successfully!', 'success')
      // this.snackBar.open("Leave Rule Update Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while updating Leave Rule ', 'error')
      // this.snackBar.open("Error while updating Leave Rule", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async delete(element :any  ) {
    this.spinner.show();
    var obj :any  = new  Object();
    obj['id'] = element.id;
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.settingService.deleteLeaveRule(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllLeaveRuleDetails();
      Swal.fire('Success', 'Leave Rule Delete Successfully! ', 'success')
      // this.snackBar.open("Leave Rule Delete Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while deleting Leave Rule ', 'error')
      // this.snackBar.open("Error while deleting Leave Rule ", 'Error', {
      //   duration: 5000
      // });
    }
  }


  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }



}
