import { Injectable } from '@angular/core';
import { SettingService } from './setting.service'
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class EditorServiceService {
  ebillUser:any;
  b_acct_id:any;
  current_date:any;
  to_data:any = {};
  constructor(private settingService: SettingService) {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id
    // getting current date
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    this.current_date = mm + '/' + dd + '/' + yyyy;

    console.log("editor service ")
    this.getDepartmentData();
  }

  department_data :any= [];
  async getDepartmentData() {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.settingService.getDepartmentData(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log("department data : ", resp['data'])
      this.department_data = resp['data'];

    } else {
      swal.fire('ERROR', 'Error Occurred While Getting Department', 'error');
    }
  }




}
