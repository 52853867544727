import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from '../service/dashboard.service'
import Swal from 'sweetalert2';

import { TenderService } from '../service/tender.service'
import { VendorService } from '../service/vendor.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-emd-dashboard',
  templateUrl: './emd-dashboard.component.html',
  styleUrls: ['./emd-dashboard.component.css']
})
export class EmdDashboardComponent implements OnInit {

  constructor(private dashboardService: DashboardService, private tenderService: TenderService, private vendorService: VendorService,
    private spinner: NgxSpinnerService) { }
  erpUser:any;
  b_acct_id:any;
  user_id:any;


  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    this.user_id = this.erpUser.le_id


    await this.getAllDashData();
  }


  tender_count = 0;
  vendor_count = 0;
  emd_count = 0;
  assign_count = 0
  tot_refund = 0;
  tot_applied_vendor = 0;
  displayedColumns :any= []
  displayedColumnsWithKeys:any = {}
  dataSource: any = []
  list: boolean = true;
  table_show: boolean = false;
  header_name = ' DETAILS '

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  dashboard() {
    this.list = true;
    this.table_show = false;
  }

  async getAllDashData() {
    this.spinner.show()
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id

    let resp:any = await this.dashboardService.getAllDashData(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp['data'])
      this.spinner.hide()
      this.tender_count = resp['data'][0][0]['tender_count']
      this.vendor_count = resp['data'][1][0]['vendor_count']
      this.emd_count = resp['data'][2][0]['emd_amt']
      this.assign_count = resp['data'][3][0]['assign_count']
      this.tot_refund = resp['data'][4][0]['refund']
      this.tot_applied_vendor = resp['data'][5][0]['applied_vendor']



    } else {
      this.spinner.hide()
      Swal.fire('error', "server side error", 'error')
      return
    }
  }



  async getAllTender() {
    this.header_name = 'TENDER DETAILS'
    this.spinner.show()
    this.table_show = true;
    this.list = false;
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;

    let resp:any = await this.tenderService.getTender(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp['data']);
      resp['data'].map((x:any) => {
        if (x['doc_amount_type'] == "Percentage") {
          x['doc_amount'] = ((x['tender_value'] * x['doc_amount']) / 100).toFixed(2);
        }
        if (x['emd_amount_type'] == "Percentage") {
          x['emd_amount'] = ((x['tender_value'] * x['emd_amount']) / 100).toFixed(2);
        }
      })



      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
      this.displayedColumns = ['nic_tender_id', 'tender_ref_no', 'work_name', 'publish_date', 'sale_end_date', 'bid_opening_date', 'tender_value', 'doc_amount', 'emd_amount', 'life_cycle_status']
      this.displayedColumnsWithKeys = { 'nic_tender_id': 'Tender ID', 'tender_ref_no': 'Reference Number', 'work_name': 'Work Name', 'publish_date': 'Publish Date', 'sale_end_date': 'Last Date For Bid Submission', 'bid_opening_date': 'Bid Opening Date', 'tender_value': 'Tender Amount', 'doc_amount': 'Document Amount', 'emd_amount': 'EMD Amount', 'life_cycle_status': 'Status' }
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'ERROR While Getting Data', 'error');
    }
  }


  async getAllVendors() {
    this.header_name = 'VENDOR DETAILS'
    this.table_show = true;
    this.list = false;
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;

    let resp:any = await this.vendorService.getVendor(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp['data']);
      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
      this.displayedColumns = ['vendor_id', 'vendor_type', 'category', 'vendor_name', 'reg_date', 'life_cycle_status']
      this.displayedColumnsWithKeys = { 'vendor_id': 'Vendor ID', 'vendor_type': 'Vendor Type', 'category': 'Category', 'vendor_name': 'Vendor Name', 'reg_date': 'Registration Date', 'life_cycle_status': 'Status' }

    } else {
      Swal.fire('ERROR', 'ERROR While Getting Vendor', 'error');
    }
  }


  async getAllAssignment() {
    this.header_name = 'ASSIGNED TENDER DETAILS'
    this.spinner.show()
    this.table_show = true;
    this.list = false;
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp:any = await this.tenderService.getAssignedTender(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log("resp", resp['data'])
      this.spinner.hide()
      console.log(resp['data'][0]);
      this.dataSource = new MatTableDataSource(resp['data'][0]);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
      this.displayedColumns = ['nic_tender_id', 'tender_ref_no', 'work_name', 'bid_opening_date', 'vendor_id', 'vendor_name', 'allotment_date', 'life_cycle_status']
      this.displayedColumnsWithKeys = { 'nic_tender_id': 'Tender ID', 'tender_ref_no': 'Reference Number', 'work_name': 'Work Name', 'bid_opening_date': 'Bid Opening Date', 'vendor_id': 'Vendor ID', 'vendor_name': 'Vendor Name', 'allotment_date': 'Tender Allotment Date', 'life_cycle_status': 'Status' }
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While Getting Assign Tender', 'error');
    }
  }


  async getAppliedVendor() {
    this.header_name = 'APPLIED VENDOR DETAILS'
    this.spinner.show()
    this.table_show = true;
    this.list = false;
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp:any = await this.dashboardService.getAppliedVendor(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log(resp['data']);
      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
      this.displayedColumns = ['nic_tender_id', 'work_name', 'vendor_id', 'vendor_name', 'mobile_no', 'tender_value']
      this.displayedColumnsWithKeys = { 'nic_tender_id': 'Tender ID', 'vendor_id': 'Vendor ID ', 'work_name': 'Work Name', 'vendor_name': 'Vendor Name', 'mobile_no': 'Mobile Number', 'tender_value': 'Tender Value' }
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While Getting Applied Vendor', 'error');
    }
  }

  emdData = []
  async getEMDFeeDetails() {
    this.header_name = 'EMD AMOUNT DETAILS'
    this.spinner.show()
    this.table_show = true;
    this.list = false;
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp:any = await this.dashboardService.getEmdDetails(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.emdData = []
      console.log(resp['data']);
      this.emdData = resp['data'];
      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];


      this.displayedColumns = ['vendor_id', 'vendor_name', 'work_name', 'total_amt', 'gstin']
      this.displayedColumnsWithKeys = { 'vendor_id': 'Vendor ID ', 'vendor_name': 'Vendor Name', 'work_name': 'Work Name', 'total_amt': 'Amount', 'gstin': 'GSTI No.' }

    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While Getting EMD Fee Details', 'error');
    }
  }
refundData=[]
  async getRefundDetails() {
    this.header_name = 'REFUND AMOUNT DETAILS'
    this.spinner.show()
    this.table_show = true;
    this.list = false;
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp:any = await this.dashboardService.getRefundDetails(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.refundData=[]
      console.log(resp['data']);
      this.refundData=resp['data'];

      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];

      this.displayedColumns = ['vendor_id', 'vendor_name', 'work_name', 'total_amt', 'gstin','status']
      this.displayedColumnsWithKeys = { 'vendor_id': 'Vendor ID ', 'vendor_name': 'Vendor Name', 'work_name': 'Work Name', 'total_amt': 'Amount', 'gstin': 'GSTI No.','status':'Status' }

    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While Getting Applied Vendor', 'error');
    }
  }


  downloadEMD() {
    let txt = ("EMD FEES DETAILS");
    let dd :any= {
      pageSize: 'A4',
      header: function (currentPage:any, pageCount:any) {
        let arr = []
        let obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },

      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    let tbl = {

      fontSize: 10,
      table: {
        headerRows: 0,
        widths: ['*', '*', '*', '*', '*'],
        body: [
          [
            { text: 'Vendor ID', alignment: 'center' },
            { text: 'Vendor Name', alignment: 'center' },
            { text: 'Work Name', alignment: 'center' },
            { text: 'Amount', alignment: 'center' },
            { text: 'GSTIN', alignment: 'center' }]
        ]
      }
    };
    dd.content.push(tbl);
    let total_amount = 0;
    this.emdData.map(x => {
      let arr = []
      arr.push({ text: x['vendor_id'], alignment: 'center' })
      arr.push({ text: x['vendor_name'], alignment: 'center' })
      arr.push({ text: x['work_name'], alignment: 'center' })
      arr.push({ text: x['total_amt'], alignment: 'center' })
      arr.push({ text: x['gstin'], alignment: 'center' })
      total_amount += x['total_amt'];
      dd.content[dd.content.length - 1].table.body.push(arr);
    })

    let tatalAmtRow = [{text:'Total',alignment:'center'},'','',{text:total_amount.toFixed(2),alignment:'center'},'']
    dd.content[dd.content.length - 1].table.body.push(tatalAmtRow);
    pdfMake.createPdf(dd).download('EMD Amount.pdf');
  }





  downloadRefund(){
      let txt = ("REFUND DETAILS");
      let dd :any= {
        pageSize: 'A4',
        header: function (currentPage:any, pageCount:any) {
          let arr = []
          let obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
          arr.push(obj);
          return arr;
        },
        pageOrientation: 'portrait',
        pageMargins: [40, 60, 40, 60],
        content: []
      };

      let tbl = {
        fontSize: 10,
        alignmet:'center',
        table: {
          headerRows: 0,
          // widths: ['*', '*', '*', '*', '*','*'],
          widths: [50, 90, 100, 60, 100,50],

          body: [
            [
              { text: 'Vendor ID', alignment: 'center' },
              { text: 'Vendor Name', alignment: 'center' },
              { text: 'Work Name', alignment: 'center' },
              { text: 'Amount', alignment: 'center' },
              { text: 'GSTIN', alignment: 'center' },
              { text: 'Status', alignment: 'center' }]
          ]
        }
      };
      dd.content.push(tbl);
      let total_amount = 0;
      this.refundData.map(x => {
        let arr = []
        arr.push({ text: x['vendor_id'], alignment: 'center' })
        arr.push({ text: x['vendor_name'], alignment: 'center' })
        arr.push({ text: x['work_name'], alignment: 'center' })
        arr.push({ text: x['total_amt'], alignment: 'center' })
        arr.push({ text: x['gstin'], alignment: 'center' })
        arr.push({ text: x['status'], alignment: 'center' })
        total_amount += x['total_amt'];
        dd.content[dd.content.length - 1].table.body.push(arr);
      })
      let tatalAmtRow = [{text:'Total',alignment:'center'},'','',{text:total_amount.toFixed(2),alignment:'center'},'','']
      dd.content[dd.content.length - 1].table.body.push(tatalAmtRow);
      pdfMake.createPdf(dd).download('Refund.pdf');
    }


}
