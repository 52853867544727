<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">FAQ's</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="list_flag" class="nav-link active" href="#tab-1" data-toggle="tab"
                                (click)="goToList()"><i class="fa fa-line-chart" aria-hidden="true"></i>
                                FAQ's List
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="create_flag" class="nav-link" href="#tab-2" data-toggle="tab"
                                (click)="goToCreate()"><i class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Create FAQ's
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="update_flag" class="nav-link" href="#tab-2" data-toggle="tab"
                                (click)="goToUpdate()"><i class="ti-settings" aria-hidden="true"> </i>
                                Update FAQ's
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">

                        <div *ngIf="list_flag" id="tab-1">
                            <br><br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select FAQ's Category : </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['VMS005']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="Select FAQ's Category"
                                        [(ngModel)]="faqObj['category']" [selectableGroup]="true"
                                        (change)="getfaqData()" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="faq's">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>


                            <br><br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="goToCreate()"> Create FAQ's</button>
                            </div>
                            <div class="row">

                                <mat-form-field>
                                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                </mat-form-field>

                            </div>
                            <br>

                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            ID
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            {{ i+1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="category">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Category

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.category }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="question">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Question
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.question}} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="answer">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Answer
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.answer}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-danger" (click)="detele(element)">
                                                Delete
                                            </button>&nbsp;&nbsp;
                                            <button class="btn btn-outline-primary" (click)="open_update(element)">
                                                Update
                                            </button>&nbsp;&nbsp;
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>
                        <div *ngIf="create_flag || update_flag" id="tab-2">



                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-success" (click)="goToList()">Go To List</button>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h5><b>Add FAQ's : </b></h5>
                                </div>
                                <div class="col-3 text-left" *ngIf="!update_flag">
                                    <button class="btn addrow" (click)="addQuestionRow()"><i
                                            class="fa fa-plus-circle" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br>
                            <div>

                                <div *ngFor="let item of addquestions;let i = index">
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>{{i+1}}. Please Select Category: </h6>
                                        </div>
                                        <div class="col-3 text-left">
                                            <ng-select [items]="mainService.codeValueTechObj['VMS005']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                placeholder="Select FAQ's Category" [(ngModel)]="item['category']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="faq's">
                                            </ng-select>
                                        </div>
                                        <div class="col-3 text-left" *ngIf="!update_flag">
                                            <button [disabled]="is_update_open" class="btn btn-danger "
                                                (click)="deleteQuestionRow(i)"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></button>

                                        </div>
                                    </div>
                                    <br>

                                    <div *ngFor="let ln of item['question_data']; let j = index;">
                                        <div class="row">
                                            <fieldset id="fieldset">
                                                <legend id="legend">
                                                    Question {{j+1}} </legend>

                                                <div class="row">
                                                    <div class="col-2 text-center inner-heading" style="color:#151B8D">
                                                        <h6>Question</h6>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-11 m-auto">
                                                        <input type="text" class="form-control" style="font-weight: 600;"
                                                            [(ngModel)]="ln['question']">
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-2 text-center inner-heading" style="color:#151B8D">
                                                        <h6>Answer</h6>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-11 m-auto">
                                                        <!-- <input type="text" class="form-control"
                                                            [(ngModel)]="ln['answer']"> -->
                                                        <textarea class="form-control" [(ngModel)]="ln['answer']"
                                                            rows="3" style="overflow: hidden;"></textarea>
                                                    </div>
                                                </div>



                                                <div class="row" *ngIf="!update_flag">
                                                    <div class="col-12 text-right my-2">
                                                        <button class="btn addrow"
                                                            *ngIf="item['question_data'].length-1 == j"
                                                            (click)="addRow(i,j)"><i class="fa fa-plus-circle"
                                                                aria-hidden="true"></i></button> &nbsp;
                                                        <!-- </div> -->

                                                        <!-- <div class="col-1 text-left" *ngIf="j!=0"> -->
                                                        <!-- delete  -->
                                                        <button *ngIf="j!=0" class="btn btn-danger"
                                                            (click)="deleteRow(i,j)"><i class="fa fa-trash"
                                                                aria-hidden="true"></i></button> &nbsp;&nbsp;
                                                    </div>
                                                </div>

                                                <br *ngIf="update_flag">
                                            </fieldset>
                                        </div>
                                    </div>
                                    <!-- <hr> -->
                                </div>
                            </div>
                            <br>
                            <div class="row" *ngIf="addquestions.length>0">
                                <div class="col-12 text-center">
                                    <button *ngIf="create_flag" class="btn btn-outline-primary"
                                        (click)="submit()">Submit</button>
                                    <button *ngIf="update_flag" class="btn btn-outline-primary"
                                        (click)="update()">Update</button>
                                </div>
                            </div>


                        </div>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner>Loading....</ngx-spinner>
