<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"></div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link {{list_fg}}" (click)="list()" href="#tab-7-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                {{screen}} ITEM LIST
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link {{upload_fg}}" (click)="uploaditems()" href="#tab-7-2"
                                data-toggle="tab"><i class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Upload {{screen}} items
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link {{create_fg}}" (click)="createitem()" href="#tab-7-3"
                                data-toggle="tab"><i class="ti-settings" aria-hidden="true"> </i>
                                Create {{screen}} item
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="u_flag">
                            <a class="nav-link {{update_fg}}" href="#tab-7-3" data-toggle="tab"><i class="ti-settings"
                                    aria-hidden="true"> </i>
                                Update {{screen}} item
                            </a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div *ngIf="l_flag" id="tab-7-1">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-success" style="margin-right:0.5%"
                                    (click)="createitem()">Create {{screen}} Items</button>
                                <button class=" btn btn-outline-warning" style="margin-right:0.5%"
                                    (click)="uploaditems()">Upload {{screen}} Item</button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Year of {{screen}}:*
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['EMB056']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="Select Year of {{screen}}"
                                        [(ngModel)]="dataObj['fin_year']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Region:*
                                </div>
                                <div class="col-4">
                                    <!-- {{mainService.codeValueTechObj['EMB058'] | json}} -->
                                    <ng-select [items]="mainService.codeValueTechObj['EMB058']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="Select Region"
                                        [(ngModel)]="dataObj['region']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Chapter / Sub-Head:*
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="chap_lists" bindLabel="chap_desc" bindValue="id"
                                        [multiple]="false" placeholder="Select Chapter / Sub-Head"
                                        [(ngModel)]="dataObj['chapter_desc']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button mat-button mat-raised-button class="btn btn-primary"
                                        (click)="fetch()">FETCH</button>
                                </div>
                            </div>
                            <br>
                            <div class="row" *ngIf="fetch_fg">
                                <div class="col-12">
                                    <mat-form-field>
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>


                                    <div class="example-container">
                                        <table mat-table [dataSource]="datasource" matSort
                                            style="border: 2px solid #ddd;">

                                            <!-- <ng-container matColumnDef="{{item}}" *ngFor="let item of displayedColumns">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    {{item | Split}}
                                                </th>
                                                <span *ngIf="item != 'Action' && item != 'category' ">
                                                    <td mat-cell flex-stretch *matCellDef="let element">
                                                        {{ element[item] }}

                                                    </td>
                                                </span>

                                                <span *ngIf="item == 'category'">
                                                    <td mat-cell flex-stretch *matCellDef="let element">
                                                        <span *ngFor="let il of mainService.codeValueTechObj['EMB061']">
                                                            <span *ngIf="il['code'] == element.category">
                                                                {{il['value']}}
                                                            </span>
                                                        </span>
                                                    </td>
                                                </span>

                                                <span *ngIf="item == 'Action'">
                                                    <td mat-cell flex-stretch *matCellDef="let element">
                                                        <button class="btn btn-outline-primary"
                                                            (click)="openupdate(element)">Update</button>
                                                    </td>
                                                </span>

                                            </ng-container> -->
                                            <!-- ID Column -->
                                            <ng-container matColumnDef="id">
                                                <th mat-header-cell *matHeaderCellDef> ID </th>
                                                <td mat-cell *matCellDef="let element let i = index"> {{i+1}} </td>
                                            </ng-container>

                                            <!-- Category Column -->
                                            <ng-container matColumnDef="cate">
                                                <th mat-header-cell *matHeaderCellDef> Category </th>
                                                <td mat-cell *matCellDef="let element"> {{element.category}} </td>
                                            </ng-container>

                                            <!-- SI No. Column -->
                                            <ng-container matColumnDef="si_no">
                                                <th mat-header-cell *matHeaderCellDef> SI No. </th>
                                                <td mat-cell *matCellDef="let element"> {{element.sed_item_no}} </td>
                                            </ng-container>

                                            <!-- SCH PWD / Ref. to DAR Column -->
                                            <ng-container matColumnDef="sch_or_dar">
                                                <th mat-header-cell *matHeaderCellDef> SCH PWD / Ref. to DAR </th>
                                                <td mat-cell *matCellDef="let element"> {{element.ref_to_dr}} </td>
                                            </ng-container>

                                            <!--Group Column -->
                                            <ng-container matColumnDef="group">
                                                <th mat-header-cell *matHeaderCellDef> Group </th>
                                                <td mat-cell *matCellDef="let element"> {{element.group_desc}} </td>
                                            </ng-container>

                                            <!--  Sub-group Column -->
                                            <ng-container matColumnDef="sub_group">
                                                <th mat-header-cell *matHeaderCellDef> Sub Group </th>
                                                <td mat-cell *matCellDef="let element"> {{element.sub_group_desc}} </td>
                                            </ng-container>

                                            <!-- Item Description Column -->
                                            <ng-container matColumnDef="item_desc">
                                                <th mat-header-cell *matHeaderCellDef> Item Description </th>
                                                <td mat-cell *matCellDef="let element"> {{element.item_desc}} </td>
                                            </ng-container>

                                            <!-- Quantity Unit Column -->
                                            <ng-container matColumnDef="qty_unit">
                                                <th mat-header-cell *matHeaderCellDef> Quantity Unit </th>
                                                <td mat-cell *matCellDef="let element"> {{element.qty_unit}} </td>
                                            </ng-container>

                                            <!-- Item Unit Column -->
                                            <ng-container matColumnDef="item_qty">
                                                <th mat-header-cell *matHeaderCellDef> Item Unit </th>
                                                <td mat-cell *matCellDef="let element"> {{element.item_qty}} </td>
                                            </ng-container>

                                            <!-- Caste Rate Unit Column -->
                                            <ng-container matColumnDef="caste_rate_unit">
                                                <th mat-header-cell *matHeaderCellDef> Caste Rate Unit </th>
                                                <td mat-cell *matCellDef="let element"> {{element.costing_rate_unit}}
                                                </td>
                                            </ng-container>

                                            <!-- Caste Rate Column -->
                                            <ng-container matColumnDef="cast_rate">
                                                <th mat-header-cell *matHeaderCellDef> Caste Rate </th>
                                                <td mat-cell *matCellDef="let element"> {{element.costing_rate}} </td>
                                            </ng-container>

                                            <!-- Effective Date Column -->
                                            <ng-container matColumnDef="eff_date">
                                                <th mat-header-cell *matHeaderCellDef> Effective Date </th>
                                                <td mat-cell *matCellDef="let element"> {{element.effective_date}} </td>
                                            </ng-container>

                                            <!-- Action Column -->
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                                <td mat-cell *matCellDef="let element"> <button
                                                        class="btn btn-outline-primary"
                                                        (click)="openupdate(element)">Update</button>
                                                </td>
                                            </ng-container>


                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                        </mat-paginator>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div *ngIf="upload_flag" id="tab-7-2" class="active">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-success" style="margin-right:0.5%" (click)="list()">{{screen}}
                                    Item list</button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 ">
                                    <h6>
                                        Costing Rate Standard:*
                                    </h6>
                                </div>
                                <div class="col-3">

                                    <!-- <ng-select [items]="newEngService.sorStd" bindLabel="value" bindValue="code"
                                        [multiple]="false" placeholder="Select Costing Rate Standard"
                                        [(ngModel)]="obj['costing_rate_std']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"
                                        >
                            </ng-select> -->


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 ">
                                    <h6>
                                        Costing Rate Standard Year:*
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['EMB056']" bindLabel="value"
                                        bindValue="code" [multiple]="false"
                                        placeholder="Select Costing Rate Standard Year" [(ngModel)]="dataObj['fin_year']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="work_order_id">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 ">
                                    <h6>
                                        Region:*
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['EMB058']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="Select Region"
                                        [(ngModel)]="dataObj['region']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 ">
                                    <h6>
                                        Rate Effective Date::*
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="dataObj['effective_date']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 ">
                                    <h6>
                                        Chapter Details:*
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="chap_lists" bindLabel="chap_desc" bindValue="id"
                                        [multiple]="false" placeholder="Select Chapter Details"
                                        [(ngModel)]="dataObj['chapter_desc']" [selectableGroup]="true" (change)="fields()"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-6 text-right">
                                    <h6>
                                        Upload Item List File(.xlsx file only) :*
                                    </h6>
                                    <h6>
                                        Download format :.xls / .xlsx / .csv
                                    </h6>
                                    <button class="btn btn-link" (click)="export()" *ngIf="excelflag">
                                        Download
                                    </button>
                                </div>
                                <div class="col-3">
                                    <input type="file" style="display: inline-block;" (change)="onFileChange($event)"
                                        placeholder="" accept=".xlsx"> Upload file
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button mat-button mat-raised-button class="btn btn-primary"
                                        (click)="upload()">Upload</button>
                                </div>
                            </div>

                            <br><br><br>
                            <div class="row" *ngIf="excel1">
                                <div class="col-12">
                                    <mat-form-field>
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)"
                                            placeholder="Filter">
                                    </mat-form-field>

                                    <div class="example-container">
                                        <table mat-table [dataSource]="datasource1" matSort sort1="matSort"
                                            style="border: 2px solid #ddd;">
                                            <ng-container matColumnDef="{{column}}" *ngFor="let column of fields_list">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    {{column | Split}}
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element[column]}} </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="fields_list"></tr>
                                            <tr mat-row *matRowDef="let row; columns: fields_list;">
                                            </tr>
                                        </table>
                                        <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                        </mat-paginator>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div *ngIf="c_flag || update_flag" id="tab-7-3" class="active">
                            <br>
                            <div *ngIf="c_flag">
                                <div style="text-align: right;">
                                    <button class=" btn btn-outline-success" style="margin-right:0.5%"
                                        (click)="list()">{{screen}} Item list</button>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3 ">
                                        <h6>
                                            Costing Rate Standard:*
                                        </h6>
                                    </div>
                                    <div class="col-3">

                                        <!-- <ng-select [items]="newEngService.sorStd" bindLabel="value" bindValue="code"
                                            [multiple]="false" placeholder="Select Costing Rate Standard"
                                            [(ngModel)]="dataObj['costing_rate_std']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                            name="work_order_id">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="dataObj['costing_rate_std']" class="form-control"
                                        disabled>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3 ">
                                        <h6>
                                            {{screen}} Year:*
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['EMB056']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder="Select {{screen}} Year"
                                            [(ngModel)]="dataObj['fin_year']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                            name="work_order_id">
                                        </ng-select>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3 ">
                                        <h6>
                                            Region:*
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['EMB058']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder="Select Region"
                                            [(ngModel)]="dataObj['region']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                            name="work_order_id">
                                        </ng-select>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3 ">
                                        <h6>
                                            Rate Effective Date::*
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="date" class="form-control" [(ngModel)]="dataObj['effective_date']">
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3 ">
                                        <h6>
                                            Chapter Details:*
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="chap_lists" bindLabel="chap_desc" bindValue="id"
                                            [multiple]="false" placeholder="Select Chapter Details"
                                            [(ngModel)]="dataObj['chapter_desc']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" (change)="fields()"
                                            name="work_order_id">
                                        </ng-select>
                                    </div>
                                </div>
                                <br>
                            </div>
                            <div *ngIf="update_flag">
                                <div style="text-align: right;">
                                    <button class=" btn btn-outline-primary" style="margin-right:0.5%"
                                        (click)="list()">{{screen}} Item list</button>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3 ">
                                        <h6>
                                            Costing Rate Standard:*
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <!-- <ng-select [items]="mainService.codeValueTechObj['EMB055']" bindLabel="value"
                                            bindValue="code" [multiple]="false"
                                            placeholder="Select Costing Rate Standard"
                                            [(ngModel)]="obj['costing_rate_std']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"
                                            disabled>
                                        </ng-select> -->
                                        <input type="text" class="form-control" [(ngModel)]="dataObj['costing_rate_std']"
                                            disabled>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3 ">
                                        <h6>
                                            {{screen}} Year:*
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['EMB056']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder="Select {{screen}} Year"
                                            [(ngModel)]="dataObj['fin_year']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"
                                            disabled>
                                        </ng-select>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3 ">
                                        <h6>
                                            Region:*
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['EMB058']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder="Select Region"
                                            [(ngModel)]="dataObj['region']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"
                                            disabled>
                                        </ng-select>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3 ">
                                        <h6>
                                            Rate Effective Date::*
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="date" class="form-control" [(ngModel)]="dataObj['effective_date']">
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                    <div class="col-3">
                                        <h6>
                                            Chapter Details:*
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <!-- <ng-select [items]="chap_lists" bindLabel="chap_desc" bindValue="id"
                                            [multiple]="false" placeholder="Select Chapter Details"
                                            [(ngModel)]="desc" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" (change)="fields()"
                                            name="work_order_id">
                                        </ng-select> -->
                                        <input type="text" class="form-control" [(ngModel)]="chap_desc" disabled>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 mt-4" *ngIf="fieldsObj['qty_unit']!=undefined">
                                    <h6>
                                        Item ID:
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['qty_unit']!=undefined">
                                    <input type="text" class="form-control" [(ngModel)]="dataObj['id']"
                                        placeholder="Item Id is System Generated" disabled>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['category']">
                                    <h6>
                                        Category:*
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['category']">
                                    <ng-select [items]="mainService.codeValueTechObj['EMB061']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="Select Category"
                                        [(ngModel)]="data['category']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>

                                <div class="col-3 mt-4" *ngIf="fieldsObj['sed_item_no']">
                                    <h6>
                                        SI No.:*
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['sed_item_no']">
                                    <input type="text" class="form-control" [(ngModel)]="data['sed_item_no']"
                                        placeholder="Enter SI No.">
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['ref_to_dar']">
                                    <h6>
                                        Ref. to DAR:*
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['ref_to_dar']">
                                    <input type="text" class="form-control" [(ngModel)]="data['ref_to_dr']"
                                        placeholder="Enter Ref. to DAR">
                                </div>

                                <div class="col-3 mt-4" *ngIf="fieldsObj['group']">
                                    <h6>
                                        Group Code:*
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['group']">
                                    <input type="text" class="form-control" [(ngModel)]="data['group_desc']"
                                        placeholder="Enter Group Code">
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['sub_group']">
                                    <h6>
                                        Sub-Group Code:
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['sub_group']">
                                    <input type="text" class="form-control" [(ngModel)]="data['sub_group_desc']"
                                        placeholder="Enter Sub-Group Code">
                                </div>

                                <div class="col-3 mt-4" *ngIf="fieldsObj['pwd_sch']">
                                    <h6>
                                        PWD SCH

                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['pwd_sch']">
                                    <input type="text" class="form-control" [(ngModel)]="data['pwd_sch']"
                                        placeholder="Enter PWD SCH">
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['code_no']">
                                    <h6>
                                        Code No.
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['code_no']">
                                    <input type="text" class="form-control" [(ngModel)]="data['code_no']"
                                        placeholder="Enter Code No.">
                                </div>

                                <div class="col-3 mt-4" *ngIf="fieldsObj['item_desc']">
                                    <h6>
                                        Item Description:*

                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['item_desc']">
                                    <textarea class="form-control" rows="4" cols="50" [(ngModel)]="data['item_desc']"
                                        placeholder="Enter Item Description"></textarea>
                                </div>

                                <div class="col-3 mt-4" *ngIf="fieldsObj['qty_unit']">
                                    <h6>
                                        Quantity Unit:*
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['qty_unit']">
                                    <ng-select [items]="mainService.codeValueTechObj['EMB059']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="Select Costing Rate Standard"
                                        [(ngModel)]="data['qty_unit']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="data['qty_unit']"
                                        placeholder="Enter  Quantity Unit"> -->
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['item_qty']">
                                    <h6>
                                        Item Quantity:*
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['item_qty']">
                                    <input type="text" class="form-control" [(ngModel)]="data['item_qty']"
                                        placeholder="Enter Item Quantity">
                                </div>

                                <div class="col-3 mt-4" *ngIf="fieldsObj['costing_rate_unit']">
                                    <h6>
                                        Costing Rate Unit:*
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['costing_rate_unit']">
                                    <!-- <input type="text" class="form-control" [(ngModel)]="data['costing_rate_unit']"
                                        placeholder="Enter Costing Rate Unit"> -->
                                    <ng-select [items]="mainService.codeValueTechObj['EMB059']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="Select Costing Rate Standard"
                                        [(ngModel)]="data['costing_rate_unit']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['costing_rate']">
                                    <h6>
                                        Costing Rate:*
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['costing_rate']">
                                    <input type="text" class="form-control" [(ngModel)]="data['costing_rate']"
                                        placeholder="Enter Costing Rate">
                                </div>

                                <div class="col-3 mt-4" *ngIf="fieldsObj['distance']">
                                    <h6>
                                        Distance
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['distance']">
                                    <input type="text" class="form-control" [(ngModel)]="data['distance']"
                                        placeholder="Enter Distance">
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['distance_unit']">
                                    <h6>
                                        Distance Unit

                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['distance_unit']">
                                    <input type="text" class="form-control" [(ngModel)]="data['distance_unit']"
                                        placeholder="Enter Distance Unit">
                                </div>

                                <!-- <div class="col-3 mt-4" *ngIf="fieldsObj['effective_date']">
                                    <h6>
                                        Effective Date:*
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['effective_date']">
                                    <input type="date" class="form-control" [(ngModel)]="data['effective_date']"
                                        placeholder="Enter Effective Date">
                                </div> -->
                                <div class="col-3 mt-4" *ngIf="fieldsObj['laber_rate']">
                                    <h6>
                                        Labour Rate:
                                    </h6>
                                </div>
                                <div class="col-3 mt-4" *ngIf="fieldsObj['laber_rate']">
                                    <input type="text" class="form-control" [(ngModel)]="data['laber_rate']"
                                        placeholder="Enter Labour Rate">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center" *ngIf="c_flag">
                                    <button mat-button mat-raised-button class="btn btn-primary"
                                        (click)="submit()">Submit</button>
                                </div>
                            </div>
                            <br>
                            <div class="row" *ngIf="update_flag">
                                <div class="col-12 text-center">
                                    <button mat-button mat-raised-button class="btn btn-primary"
                                        (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
                <br>
            </div>

        </div>
    </div>

</div>
