<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">MANAGE Accounts</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" (click)="refressadd()" data-toggle="tab"><i class="ti-bar-chart"></i> All
                                Accounts List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refressadd()"><i class="ti-settings"></i> Create
                                Accounts</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refressadd()"><i class="ti-announcement"></i> Update
                                Accounts</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">



                            <div class=" table-container ">
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4 text-center">

                                    </div>

                                </div>

                                <div class="example-container">
                                    <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                                        <ng-container matColumnDef="party_id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Party ID</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.party_id}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="party_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>Party Name</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ partyObj[element.party_id] }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="party_account_no">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>Account No.</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.party_account_no }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="party_bank_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>Bank Name</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.party_bank_name }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="party_branch_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>Branch Name</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.party_branch_name }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="party_ifsc_code">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>IFSC</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.party_ifsc_code }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                                <button class="btn btn-outline-primary" (click)="openUpdate(element,i)">Update</button>


                                            </td>

                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> party :</h6>
                                </div>
                                <div class="col-4">
                                    <select class="form-control" name="party" [(ngModel)]="obj['party_id']" placeholder="Party Info">
                                        <option *ngFor="let partyArr of partyArr" ngValue="{{partyArr.party_id}}">{{partyArr.party_name}}

                                        </option>
                                    </select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> Account Number :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="party_account_no" [(ngModel)]="obj['party_account_no']" placeholder="Account Number">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> Bank Name :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['PROP0005']" bindLabel="value" bindValue="value"
                                    [multiple]="false" placeholder="Bank Name" [(ngModel)]="obj['party_bank_name']" [selectableGroup]="true"
                                     [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                    <!-- <input class="form-control" name="party_bank_name" [(ngModel)]="obj['party_bank_name']" placeholder="Bank Name"> -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> Branch Name :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="party_branch_name" [(ngModel)]="obj['party_branch_name']" placeholder="Branch Name">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> IFSC Code :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="party_ifsc_code" [(ngModel)]="obj['party_ifsc_code']" placeholder="IFSC Code">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">

                                    <button class="btn btn-primary" (click)="addNewRow()">Save</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> party :</h6>
                                </div>
                                <div class="col-4">
                                    <!-- <select  class="form-control" name="party_id" [(ngModel)]="obj.party_id" placeholder="Party Info">
                                        <option *ngFor="let partyArr of partyArr" ngValue="{{partyArr.party_id}}">{{partyArr.party_name}}

                                        </option>
                                    </select> -->

                                    <input class="form-control" name="party_id" [(ngModel)]="obj['party_name']" placeholder="Party Info" disabled>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> Account Number :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="party_account_no" [(ngModel)]="obj['party_account_no']" placeholder="Account Number">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> Bank Name :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['PROP0005']" bindLabel="value" bindValue="value"
                                    [multiple]="false" placeholder="Bank Name" [(ngModel)]="obj['party_bank_name']" [selectableGroup]="true"
                                     [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                    <!-- <input class="form-control" name="party_bank_name" [(ngModel)]="obj['party_bank_name']" placeholder="Bank Name"> -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> Branch Name :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="party_branch_name" [(ngModel)]="obj['party_branch_name']" placeholder="Branch Name">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> IFSC Code :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="party_ifsc_code" [(ngModel)]="obj['party_ifsc_code']" placeholder="IFSC Code">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">

                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
