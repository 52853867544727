import { Injectable } from '@angular/core';
import { MainService } from '../service/main.service';
import { HttpClient, HttpEventType ,HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TaBillService {

  httpUrl;


  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;
   }

   async gettabilldata(obj :any ) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/travelreimbrusment/tabill/gettabilldata'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async approvetabillstatus(obj :any ) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/hr/travelreimbrusment/tabill/approvetabbillstatus',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getUploadedtabbillData(obj :any ) {
    const resp = await this.http.post(this.httpUrl+'/hr/travelreimbrusment/tabill/getUploadedtabbillData', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
  }
  return
  }
}
