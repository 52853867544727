import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackingLayoutComponent } from './tracking-layout.component';
import { TrackingSidebarComponent } from './tracking-sidebar/tracking-sidebar.component';
import { TrackingFooterComponent } from './tracking-footer/tracking-footer.component';
import { TrackingHeaderComponent } from './tracking-header/tracking-header.component';
import { SharedModule } from '../../shared/shared.module';
import { TrackingDashboardComponent } from '../tracking-dashboard/tracking-dashboard.component';
import { TRACKINGLayoutRoutingModule } from './tracking-layout-routing.module';
import { TrackingAdministrationComponent } from '../tracking-administration/tracking-administration.component';
import { TrackingRoleComponent } from '../tracking-administration/tracking-role/tracking-role.component';
import { TrackingUserComponent } from '../tracking-administration/tracking-user/tracking-user.component';
import { RoleAuthorizationComponent } from '../tracking-administration/role-authorization/role-authorization.component';
import { TrackingWorkflowComponent } from '../tracking-administration/tracking-workflow/tracking-workflow.component';
import { TrackingSettingComponent } from '../tracking-setting/tracking-setting.component';
import { CodeValueComponent } from '../tracking-setting/code-value/code-value.component';
import { FieldComponent } from '../tracking-setting/field/field.component';
import { TrackingHelpComponent } from '../tracking-help/tracking-help.component';
import { HierachyComponent } from '../tracking-setting/hierachy/hierachy.component';
import { BoundryMapComponent } from '../tracking-setting/boundry-map/boundry-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
// import { AgmCoreModule } from '@agm/core';
// import { ChartsModule } from 'ng2-charts';
import { NgChartsModule } from 'ng2-charts';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';

// import { MatCheckboxModule, MatInputModule, MatPaginatorModule, MatSelectModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { EmpTrackingComponent } from '../emp-tracking/emp-tracking.component';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import {MatSidenavModule} from '@angular/material/sidenav'
import { SelfEmpTrackingComponent } from '../self-emp-tracking/self-emp-tracking.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";


@NgModule({
  declarations: [
    TrackingLayoutComponent,
    TrackingSidebarComponent,
    TrackingFooterComponent,
    TrackingHeaderComponent,
    TrackingDashboardComponent,
    TrackingAdministrationComponent,
    TrackingRoleComponent,
    TrackingUserComponent,
    RoleAuthorizationComponent,
    TrackingWorkflowComponent,
    TrackingSettingComponent,
    CodeValueComponent,
    FieldComponent,
    HierachyComponent,
    BoundryMapComponent,
    TrackingHelpComponent,
    EmpTrackingComponent,
    SelfEmpTrackingComponent
  ],
  exports: [
    TrackingLayoutComponent,
    TrackingHeaderComponent,
    TrackingFooterComponent,
    TrackingSidebarComponent,

],

  imports: [
    SharedModule,
    CommonModule,
    TRACKINGLayoutRoutingModule,
    NgSelectModule,
    NgChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    GoogleMapsModule,
    // oc
    // GooglePlaceModule,
    // ec
    MatSlideToggleModule,
    FormsModule,
    // NgtreegridModule,
    MatStepperModule,
    MatCheckboxModule,
    // MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    // MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    // NgApexchartsModule,
    // ReactiveFormsModule,
    MatTabsModule,
    // GooglePlaceModule,
    // NgxEchartsModule,
    // NgxQRCodeModule

   // AngularEditorModule,
  // RichTextEditorModule,

    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    //   apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    // AgmCoreModule
  ]
})
export class TrackingLayoutModule { }
