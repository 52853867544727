import { Component, OnInit, ViewChild } from '@angular/core';
 import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import { LedgerService } from '../../service/ledger.service';
import { MainService } from '../../service/main.service';
import swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-fin-year',
  templateUrl: './fin-year.component.html',
  styleUrls: ['./fin-year.component.css']
})
export class FinYearComponent implements OnInit {
  displayedColumns = ['id', 'fin_year', 'status', 'action'];
  calender = [{ code: 'APR-MAR' }, { code: 'JAN-DEC' }]
  constructor(public mainService: MainService, private spinner: NgxSpinnerService,private ledgerService: LedgerService) { }
  datasource:any;
  erpUser:any
  create_user_id:any;
  b_acct_id:any
  allFinYear:any = []
  user_id:any
  obj :any= {}
  openFinYear :any= {}
  futureFinYear :any= []
  closedFinYear :any= []
  list_flag:any=true;
  create_flag:any=false;

  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.create_user_id = this.erpUser.le_id;
    await this.getFinYear();
  }

  goBack(){
    this.create_flag=false;
    this.list_flag=true;
  }
  async getFinYear() {
    this.refresh()
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getAllFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allFinYear = resp.data;
      this.datasource = new MatTableDataSource(this.allFinYear)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();
      for (let i = 0; i < this.allFinYear.length; i++) {
        if (this.allFinYear[i]['status'] == 'OPEN' || this.allFinYear[i]['status'] == 'UNDERAPPROVAL') {
          this.openFinYear = Object.assign({}, this.allFinYear[i])
        }
        if (this.allFinYear[i]['status'] == 'FUTURE') {
          this.futureFinYear.push(this.allFinYear[i])
        }
        if (this.allFinYear[i]['status'] == 'CLOSED') {
          this.closedFinYear.push(this.allFinYear[i])
        }
      }
      if (Object.keys(this.openFinYear).length > 0) {
        this.obj['calender'] = this.openFinYear['calender']
      }
      await this.calenderChange()

    } else {
      this.spinner.hide()

    }
  }

  async addNewRow() {
    let obj = Object.assign({}, this.obj);
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'FUTURE';
    obj['calander'] = this.obj['calender'];
    obj['create_user_id'] = this.create_user_id;
    this.spinner.show();
    var resp = await this.ledgerService.createfinyear(obj);
    if (resp['error'] == false) {
      await this.getFinYear();
      this.spinner.hide();
      swal.fire("Success", "...Added Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Request Failed!", 'error');
    }
  }

  refresh() {
    this.obj = {};
    this.openFinYear = {}
    this.futureFinYear = [];
    this.closedFinYear = [];
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  async calenderChange() {

    let year = 0
    if (Object.keys(this.openFinYear).length > 0) {
      year = Number(this.openFinYear['fin_year']) + 1 + this.futureFinYear.length
    }

    if (this.obj['calender'] == "APR-MAR") {
      let end_year = year + 1
      this.obj['fin_year'] = year
      this.obj['start_date'] = year + '-04-01'
      this.obj['end_date'] = end_year + '-03-31'

    } else if (this.obj['calender'] == "JAN-DEC") {
      this.obj['fin_year'] = year

      this.obj['start_date'] = year + '-01-01'
      this.obj['end_date'] = year + '-12-31'
    } else {
      this.obj['fin_year'] = year

      this.obj['start_date'] = ''
      this.obj['end_date'] = ''
    }
  }

  gotocreate() {
    // $('.nav-tabs a[href="#tab-2"]').tab("show");
    this.create_flag=true;
    this.list_flag=false;
  }

  async closeFinYear(element:any) {
    this.spinner.show();

    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    let finYear = Number(element.fin_year) + 1
    obj['fin_year'] = finYear;
    obj['status'] = "CLOSED";
    obj['id'] = element.id;
    if (this.futureFinYear.length == 0) {
      this.spinner.hide();
      swal.fire("Worning", "Please Add Next Fin Year Before Delete", 'warning');
      return;
    }
    let resp = await this.ledgerService.closeFinYear(obj);
    if (resp['error'] == false) {
      await this.getFinYear();
      this.spinner.hide();
      swal.fire("Success", ' Fin Year Successfully Closed ', 'success');
    }
    else {
      this.spinner.hide();
      swal.fire('ERROR', "Some error occured Please try again ", 'error');
    }
  }
}
