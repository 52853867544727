import { Component, OnInit, ViewChild } from '@angular/core';

import { AuthenticationService } from '../../authentication.service';
import { BillService } from '../service/bill.service';
import { EbillPrintService } from '../../portal/service/ebill-print.service'
import { EventGroupService } from '../service/event-group.service'
import { EventsService } from '../service/events.service';
import { JournalService } from '../service/journal.service';
import { LedgerService } from '../service/ledger.service';
import { MainService } from '../../portal/service/main.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from "ngx-spinner";
import { PrintSalBillService } from '../../portal/service/print-sal-bill.service'
import { RuleProcessService } from '../service/rule-process.service';
import { BudgetEffectService } from '../service/budget-effect.service';
import { RuleProcessingNewService } from '../service/rule-processing-new.service';
import { PrintCbService } from '../service/print-cb.service';
import { RuleService } from '../service/rule.service';
import { ChartOfAccountService } from '../service/chart-of-account.service';
import * as  pdfMake from  "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import swal from 'sweetalert2';
import { FormGroup, FormControl, FormControlName, Validators, FormBuilder } from '@angular/forms'

declare var $: any;

@Component({
  selector: 'app-department-bill',
  templateUrl: './department-bill.component.html',
  styleUrls: ['./department-bill.component.css']
})

export class DepartmentBillComponent {
  dataSource: any;
  allEventLayouts:any
  fieldTechnicalNames:any = {}
  fieldBusinessNames:any = {}
  selectLayoutFields :any= []
  fieldTechNameTOBusinessName:any = {}
  allFields :any= []
  allRule:any = []
  fin_year:any
  constructor(private budgetEffectService: BudgetEffectService, private printCbService: PrintCbService, private printSalBillService: PrintSalBillService, private chartOfAccountService: ChartOfAccountService,
    private ebillPrintService: EbillPrintService, private billService: BillService, public mainService: MainService,
    private ledgerService: LedgerService, private ruleProcessService: RuleProcessService, private ruleProcessingNewService: RuleProcessingNewService,
    private ruleService: RuleService, private journalService: JournalService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService,
    private EventsService: EventsService, public auth: AuthenticationService, public eventGroupService: EventGroupService) { }
  @ViewChild('paginator', { static: true }) paginator !: MatPaginator;
  @ViewChild('sort', { static: true }) sort!: MatSort;
  @ViewChild('paginator1', { static: true }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: true }) sortCol2!: MatSort;

  displayedColumns = ['id', 'local_doc_no', 'module_doc_type', 'desc', 'doc_type', 'amt', 'Action'];
  displayedColumns1 = ['ar_num_in_src', 'cp_nm', 'ev_grp_id', 'ev_grp_tcd', 'ev_ln_dist_bus_ev_cd', 'ev_ln_amt'];

  datasource:any;
  datasource1:any
  ListObj :any= {}
  erpUser:any
  b_acct_id:any
  moduleArr :any= []
  statusArr:any = [
    { value: "APPROVED" },
    { value: "PROCESSED" },
    { value: "REJECTED" }
  ];

  systemDate:any
  orgShortName:any
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    var resp = await this.billService.getSystemDate();
    this.systemDate = resp.data
    this.orgShortName = await this.mainService.accInfo['account_short_name']
    console.log(this.orgShortName)
    await this.getallFields();
    await this.getAllEventLayouts();
    await this.eventChange()
    await this.getAccountModule()
    await this.getAllRuleList();
    await this.getActiveFinYear();
    await this.allChartOfAccount();

  }
  chartOfAcc = []
  async allChartOfAccount() {
    this.spinner.show()
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.chartOfAccountService.getchartofaccount(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.chartOfAcc = resp['data']
    } else {
      this.spinner.hide()
    }
  }
  async getActiveFinYear() {
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getActiveFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp.data.length == 0) {
        swal.fire("Warning", "..Active Financial Year Not set!", 'warning');
      } else {
        this.fin_year = resp.data[0].fin_year;
      }
    } else {
      swal.fire("Error", "..Error while getting active  fin year!", 'error');
    }
  }

  async getAllRuleList() {
    var resp = await this.ruleService.getAllRules(this.b_acct_id);
    if (resp['error'] == false) {
      this.allRule = resp.data;
      this.spinner.hide();
    } else {
      this.spinner.show()
      this.snackBar.open("Error while getting  all rule list", 'Error', {
        duration: 5000
      });
    }
  }
  async getallFields() {
    var obj :any= new Object();
    obj['domain_code'] = 'ACCOUNT';
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.journalService.getFields(obj);
    this.allFields = [];
    if (resp['error'] == false) {
      this.allFields = resp.data;
      this.fieldTechNameTOBusinessName = {};

      for (let i = 0; i < this.allFields.length; i++) {
        this.fieldTechnicalNames[this.allFields[i]['field_code']] = this.allFields[i]['field_technical_name']
        this.fieldBusinessNames[this.allFields[i]['field_code']] = this.allFields[i]['field_business_name'];
        this.fieldTechNameTOBusinessName[this.allFields[i]['field_technical_name']] = this.allFields[i]['field_business_name']

      }
    } else {
      this.snackBar.open("Error while getting Fields", "Error", {
        duration: 5000,
      });
    }
  }

  async getAllEventLayouts() {
    this.spinner.show()
    var resp = await this.EventsService.getEventLayoutss(this.b_acct_id);
    if (resp['error'] == false) {
      this.allEventLayouts = resp.data;
      this.spinner.hide()
    } else {
      this.spinner.show()
      this.snackBar.open("Error while getting Event Records", 'Error', {
        duration: 5000
      });
    }
  }


  eventChange() {
    let selectedLayout:any = ''
    this.selectLayoutFields = []
    for (let j = 0; j < this.allEventLayouts.length; j++) {
      if ("R101" == this.allEventLayouts[j]['record_code']) {
        selectedLayout = this.allEventLayouts[j]
      }
    }
    let fieldsCodes = selectedLayout['field_code'].split(",")

    for (let i = 0; i < fieldsCodes.length; i++) {
      let ob:any = new Object
      ob['code'] = this.fieldTechnicalNames[fieldsCodes[i]]
      ob['value'] = this.fieldBusinessNames[fieldsCodes[i]]
      this.selectLayoutFields.push(ob)
    }
    console.log(this.selectLayoutFields)
  }
  async getAccountModule() {
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.auth.getaccountmodule(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.moduleArr = []
      let arr :any= resp['data'][0]['module_cd'].split(",");
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] != 'AA' && arr[i] != 'ENG') {
          let ob:any = Object.assign({}, {})
          ob['module_cd'] = arr[i]
          this.moduleArr.push(ob)

        }
      }
    } else {

    }
  }



  async submit() {
    this.spinner.show();


    // if (!this.ListObj['module_cd']) {
    //   this.spinner.hide();
    //   swal.fire("WARNING", 'Please Select Module Code ', 'warning');
    //   return;
    // }
    // if (!this.ListObj['status']) {
    //   this.spinner.hide();
    //   swal.fire("WARNING", 'Please Select Status ', 'warning');
    //   return
    // }
    var obj :any= Object.assign({}, this.ListObj);
    obj['b_acct_id'] = this.b_acct_id
    obj['is_rec_or_pay'] = 'PAYABLE'
    console.log(obj)
    var resp = await this.eventGroupService.geteventlist(JSON.stringify(obj));

    // if(this.ListObj['module_cd'] == 'ACCOUNT'){
    //   var resp = await this.billService.getAllContingentBills(this.b_acct_id);
    // }else{
    //   var resp = await this.eventGroupService.geteventlist(JSON.stringify(obj));
    // }

    console.log('value', resp);
    if (resp['error'] == false) {
      let data = []
      for (let i = 0; i < resp.data.length; i++) {
        if (resp['data'][i]['status'] == this.ListObj['status']) {
          data.push(resp['data'][i])
        }
      }
      console.log(data)
      this.datasource = new MatTableDataSource(data);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
      this.spinner.hide()
    }
    if (resp['error'] == true) {
      console.log('ERROR')
      this.spinner.show()
      swal.fire("Error", "Error While Gettuumg Challan ", 'error');


    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.datasource1.filter = filterValue.trim().toLowerCase();
  }

  async view(element:any) {
    var obj:any = Object.assign({}, {});
    obj['b_acct_id'] = this.b_acct_id
    obj['event_group_id'] = [element['id']]
    console.log(element);

    var resp = await this.eventGroupService.geteventxref(JSON.stringify(obj));
    console.log('value', resp);


    let eventdata = []
    eventdata = resp['data'];
    this.datasource1 = new MatTableDataSource(eventdata);
    this.datasource1.sort = this.sortCol2;
    this.datasource1.paginator = this.paginator1;
    $('#A').modal('show');


  }


  async process(element:any) {
    this.spinner.show();
    var obj :any= Object.assign({}, {});
    console.log(element);

    obj['b_acct_id'] = this.b_acct_id
    obj['event_group_id'] = [element['id']]
    var resp = await this.eventGroupService.geteventxref(JSON.stringify(obj));

    // let event_group_id = element['id'];


    let eventdata = []
    eventdata = resp['data'];
    console.log(eventdata)
    var processed_data = await this.ruleProcessingNewService.startProcessing(eventdata);
    console.log(processed_data);
    var bud_obj = await this.budgetEffectService.getBudgetObj(eventdata, this.b_acct_id);
    if(!bud_obj){
      this.spinner.hide();
      return;
    }
    if (processed_data['error'] == true) {
      this.spinner.hide();
      swal.fire("Error", processed_data['data'], 'error');
      return;
    }
    if (processed_data['data'].length > 0) {
      var obj1 = Object.assign({}, element);
      obj1['b_acct_id'] = this.b_acct_id;
      obj1['status'] = 'PROCESSED';
      obj1['update_user_id'] = this.erpUser.user_id;
      obj1['jrnl'] = processed_data['data'];
      obj1['budget'] = bud_obj;
      obj1['fin_year'] = this.fin_year;
      console.log(obj1);

      var resp = await this.eventGroupService.insertProcessedData(obj1);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.submit();
        swal.fire("Success", "Processed Successfully!", 'success');
      } else {
        this.spinner.hide();
        swal.fire("Error", "...Error while Processed  Bill Insert!", 'error');
      }
    } else {
      console.log(processed_data)
      this.spinner.hide();
      swal.fire("Error", "...Some Events does not have rule  !", 'error');
    }
  }
  async reject(element:any) {
    this.spinner.show();
    let obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'REJECTED';
    obj['update_user_id'] = element.update_user_id;
    obj['id'] = element.id;
    let resp = await this.eventGroupService.rejectevent(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      swal.fire('REJECTED', 'Department Bill rejected', 'success')
      this.submit();
    }
    else {
      this.spinner.hide();
      swal.fire('Error', 'Error while Contingent Bills rejecting ', 'error')
    }
  }

  async download(element:any) {

    if (element['module_doc_type'] == 'EBILL') {
      let req = JSON.parse(element['req_data'])
      await this.ebillPrintService.printEbill(req, this.erpUser)
    }
    if (element['module_doc_type'] == 'SAL_BILL') {
      let req = JSON.parse(element['req_data'])
      await this.printSalBillService.printSalbill(req, this.erpUser)
    }
    if (element['module_doc_type'] == 'CB') {
      let req1 = JSON.parse(element['req_data'])
      // let req = JSON.parse(req1)
      console.log(element);
      let obj :any= new Object();
      obj['node_cd'] = req1['node_cd'];
      obj['bill_id'] = req1['bill_id'];
      obj['b_acct_id'] = this.erpUser.b_acct_id;

      let resp = await this.billService.printDepBill(obj);
      console.log(resp['data'])
      pdfMake.createPdf(resp['data']).download("CB_Bill.pdf");

      // await this.printCbService.printCB(req, this.erpUser)
    }
  }
  //*********view preducted JV************ */

  async getProcessData(element:any) {
    var obj :any= Object.assign({}, {});
    obj['b_acct_id'] = this.b_acct_id
    obj['event_group_id'] = [element['id']]
    var resp = await this.eventGroupService.geteventxref(JSON.stringify(obj));
    let eventdata = []
    eventdata = resp['data'];
    var processed_data = await this.ruleProcessingNewService.startProcessing(eventdata);
    console.log(processed_data)
    if (processed_data['error'] == true) {
      this.spinner.hide();
      swal.fire("Error", processed_data['data'], 'error');
      return;
    }
    if (processed_data['data'].length > 0) {
      this.processedData = processed_data['data'];
    } else {
      this.processedData = []
    }
  }

  processedData :any= [];
  new_data:any = [];
  view_header:any;
  print_data :any= [];

  async showJV(element:any) {
    this.spinner.show();
    this.new_data = []
    this.processedData = [];
    this.view_header = Object.assign({}, element)
    this.print_data = []
    await this.getProcessData(element);
    this.view_header['status'] = 'NA';

    for (let i = 0; i < this.processedData.length; i++) {
      for (let j = 0; j < this.chartOfAcc.length; j++) {
        if (this.processedData[i]['chart_of_account'] == this.chartOfAcc[j]['leaf_code']) {
          this.processedData[i]['chart_acct'] = this.chartOfAcc[j]['leaf_value'] + ' (' + this.chartOfAcc[j]['leaf_code'] + ')'
        }
      }
    }

    for (let i = 0; i < this.processedData.length; i++) {
      let obj3:any = {}
      if (this.processedData[i]['db_cd_ind'] == 'DB') {
        obj3['db'] = -1 * this.processedData[i]['txn_amt']
        obj3['cr'] = 0
        obj3['chart_acct'] = this.processedData[i]['chart_acct']
        obj3['ld_jrnl_ln_desc'] = ''
        // obj3['ld_jrnl_ln_desc'] = this.processedData[i]['ld_jrnl_ln_desc']
      }
      else if (this.processedData[i]['db_cd_ind'] == 'CR') {
        obj3['cr'] = this.processedData[i]['txn_amt']
        obj3['db'] = 0
        obj3['chart_acct'] = this.processedData[i]['chart_acct']
        obj3['ld_jrnl_ln_desc'] = ''
        // obj3['ld_jrnl_ln_desc'] = this.processedData[i]['ld_jrnl_ln_desc']
      }
      this.print_data.push(obj3)
    }

    let ar:any = [];
    for (let i = 0; i < this.print_data.length; i++) {
      let db :any= 0
      let cr :any= 0
      let obj :any= {};
      for (let j = 0; j < this.print_data.length; j++) {
        if (this.print_data[i]['chart_acct'] == this.print_data[j]['chart_acct'] && this.print_data[i]['ld_jrnl_ln_desc'] == this.print_data[j]['ld_jrnl_ln_desc']) {
          db = this.print_data[j]['db'] + db
          cr = this.print_data[j]['cr'] + cr
          obj['db'] = db
          obj['cr'] = cr
          obj['chart_acct'] = this.print_data[i]['chart_acct']
          obj['ld_jrnl_ln_desc'] = this.print_data[i]['ld_jrnl_ln_desc']
        }
      }
      if (!ar.includes(this.print_data[i]['chart_acct'] + this.print_data[i]['ld_jrnl_ln_desc'])) {
        this.new_data.push(obj);
      }
      ar.push(this.print_data[i]['chart_acct'] + this.print_data[i]['ld_jrnl_ln_desc'])

    }


    let db2 = 0;
    let cr2 = 0;
    for (let i = 0; i < this.new_data.length; i++) {
      db2 = db2 + this.new_data[i]['db']
      cr2 = cr2 + this.new_data[i]['cr']
    }
    this.new_data.map((x :any)=> {
      if (x['db'] > x['cr']) {
        x['db'] = x['db'] - x['cr']
        x['cr'] = 0
      } else {
        x['cr'] = x['cr'] - x['db']
        x['db'] = 0
      }
    })

    let modified_data:any = []
    this.new_data.map((x:any) => {
      if (x['db'] > 0) {
        modified_data.push(x);
      }
    })
    this.new_data.map((x:any) => {
      if (x['cr'] > 0) {
        modified_data.push(x);
      }
    })


    this.new_data = modified_data;



    let no :any= {}
    no['db'] = db2
    no['cr'] = cr2
    no['chart_acct'] = ''
    no['ld_jrnl_ln_desc'] = 'Total Amount'
    this.new_data.push(no);
    this.spinner.hide();
    $('#exampleModal').modal('show');
  }
  ///************************************************************************************* */
  refresh() {

  }
}
