<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Location Hierarchy </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list1_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create1_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings"></i> Create Hierarchy</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update1_flag" data-toggle="tab"><i class="ti-announcement"></i>
                                Update
                                Hierarchy</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab"><i class="ti-announcement"></i> Upload
                                Activity File</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create2_flag" data-toggle="tab"><i class="ti-announcement"></i>
                                Create Hierarchy</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update2_flag" data-toggle="tab"><i class="ti-announcement"></i>
                                Update Hierarchy</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="list2_flag" data-toggle="tab"><i class="ti-announcement"></i>
                                View Hierarchy</a>
                        </li>
                    </ul>
                    <br>
                    <div class="tab-content">
                        <div *ngIf='list1_flag'>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-2">
                                    <h6>Business Entity Type :</h6>
                                </div>
                                <div class="col-2">
                                    <ng-select [items]="buss_ent_type" disabled bindLabel="id" bindValue="id"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['buss_ent_type']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                        <!--Select Location Type-->
                                    </ng-select>
                                </div>
                                <div class="col-2">
                                    <button (click)="getAllHier()" class="btn btn-outline-success">List</button>
                                </div>
                                <div class="col-2">
                                    <button (click)="open_create()" class="btn btn-outline-success">Create</button>

                                </div>
                            </div>
                            <br>
                            <br>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Hierarchy ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.hier_type_id }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="hier_type">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Hierarchy
                                            Purpose

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.hier_type }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="hier_ident_code">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Hierarchy
                                            code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.hier_ident_code }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="hier_buss_name">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Hierarchy
                                            Description

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.hier_buss_name }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button (click)="view_hier(element)"
                                                class="btn btn-outline-success">View</button>
                                            <!-- <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">Update</button> -->
                                            <button class="btn btn-outline-danger"
                                                (click)="delete_hier(element)">Delete</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>
                        <div *ngIf="create1_flag">
                            <div class="card">
                                <div style="margin: 2%; margin-left: 10%;">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-success">Go to
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-2 text-center"
                                            style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                                            <h6> Node Type</h6>

                                        </div>
                                        <div class="col-2 text-center"
                                            style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                                            <h6><b>Code</b></h6>


                                        </div>
                                        <div class="col-2 text-center"
                                            style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                                            <h6><b> Value</b></h6>
                                        </div>


                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-2 text-right">
                                            Level 1:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl1_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD5']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl1_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl1_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD5']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl1_node_type']" (change)="set_leaf_node_type(1)"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl1_type']!='text'">
                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl1_cd']"
                                                disabled>

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl1_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                disabled [(ngModel)]="obj['lvl1_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl1_type']!='text'">
                                            <ng-select [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                                (change)="onChangeLvl1()" type='text' [multiple]="false"
                                                placeholder="Select Level 1 " [(ngModel)]="obj['lvl1_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl1_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl1_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl1_value']">

                                        </div>
                                        <!-- <div id="text" class="col-2" (click)="addNew('1')"
                                            *ngIf="obj['lvl1_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl1_type']=='text'"
                                            (click)="addNew('1')" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Select </u></b></h6>
                                        </div> -->
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-2 text-right">
                                            Level 2:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl2_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                               [(ngModel)]="obj['lvl2_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl2_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl2_node_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" (change)="set_leaf_node_type(2)"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl2_type']!='text'">
                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl2_cd']"
                                                disabled>

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl2_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                disabled [(ngModel)]="obj['lvl2_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl2_type']!='text'">
                                            <ng-select [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                                (change)="onChangeLvl2()" type='text' [multiple]="false"
                                                placeholder="Select Level 2" [(ngModel)]="obj['lvl2_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl2_value">
                                            </ng-select>
                                        </div>

                                        <div class="col-2" *ngIf="obj['lvl2_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl2_value']">

                                        </div>
                                        <!-- <div id="text" class="col-2" (click)="addNew('2')"
                                            *ngIf="obj['lvl2_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl2_type']=='text'"
                                            (click)="addNew('2')" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Select </u></b></h6>
                                        </div> -->
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Level 3:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl3_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl3_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl3_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl3_node_type']" (change)="set_leaf_node_type(3)"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl3_type']!='text'">

                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl3_cd']" disabled>

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl3_type']=='text'">

                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl3_cd']" onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl3_type']!='text'">
                                            <ng-select [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                                (change)="onChangeLvl3()" [multiple]="false"
                                                placeholder="Select Level 3" [(ngModel)]="obj['lvl3_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl3_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl3_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl3_value']">

                                        </div>
                                        <div id="text" class="col-2"
                                            *ngIf="obj['lvl3_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u (click)="addNew('3')">Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl3_type']=='text'"
                                             style="color: rgb(37, 130, 236);">
                                            <h6><b><u (click)="addNew('3')">Select </u></b></h6>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Level 4:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl4_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl4_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl4_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl4_node_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" (change)="set_leaf_node_type(4)"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl4_type']!='text'">

                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl4_cd']"
                                                disabled>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl4_type']=='text'">

                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl4_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>

                                        <div class="col-2" *ngIf="obj['lvl4_type']!='text'">
                                            <ng-select [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                                (change)="onChangeLvl4()" [multiple]="false"
                                                placeholder="Select Level 4" [(ngModel)]="obj['lvl4_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl4_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl4_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl4_value']">

                                        </div>
                                        <div id="text" class="col-2"
                                            *ngIf="obj['lvl4_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b ><u (click)="addNew('4')">Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl4_type']=='text'"
                                             style="color: rgb(37, 130, 236);">
                                            <h6><b><u (click)="addNew('4')">Select </u></b></h6>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Level 5:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl5_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl5_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl5_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl5_node_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" (change)="set_leaf_node_type(5)"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl5_type']!='text'">

                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl5_cd']"
                                                disabled>

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl5_type']=='text'">

                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl5_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl5_type']!='text'">

                                            <ng-select [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                                (change)="onChangeLvl5()" [multiple]="false"
                                                placeholder="Select Level 5" [(ngModel)]="obj['lvl5_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl5_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl5_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl5_value']">

                                        </div>
                                        <div id="text" class="col-2"
                                            *ngIf="obj['lvl5_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u (click)="addNew('5')">Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl5_type']=='text'"
                                             style="color: rgb(37, 130, 236);">
                                            <h6><b><u (click)="addNew('5')">Select </u></b></h6>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Level 6:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl6_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl6_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl6_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl6_node_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" (change)="set_leaf_node_type(6)"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl6_type']!='text'">

                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl6_cd']"
                                                disabled>

                                        </div>

                                        <div class="col-2" *ngIf="obj['lvl6_type']=='text'">

                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl6_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl6_type']!='text'">

                                            <ng-select [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                                (change)="onChangeLvl6()" [multiple]="false"
                                                placeholder="Select Level 6" [(ngModel)]="obj['lvl6_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl6_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl6_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl6_value']">

                                        </div>
                                        <div id="text" class="col-2"
                                            *ngIf="obj['lvl6_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u (click)="addNew('6')">Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl6_type']=='text'"
                                             style="color: rgb(37, 130, 236);">
                                            <h6><b><u (click)="addNew('6')">Select </u></b></h6>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Level 7:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl7_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl7_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl7_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl7_node_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" (change)="set_leaf_node_type(7)"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl7_type']!='text'">

                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl7_cd']"
                                                disabled>

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl7_type']=='text'">

                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl7_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl7_type']!='text'">

                                            <ng-select [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                                [multiple]="false" placeholder="Select Level 7"
                                                [(ngModel)]="obj['lvl7_cd']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                (change)="onChangeLvl7()" name="lvl7_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl7_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl7_value']">

                                        </div>
                                        <div id="text" class="col-2"
                                            *ngIf="obj['lvl7_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u (click)="addNew('7')">Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl7_type']=='text'"
                                           style="color: rgb(37, 130, 236);">
                                            <h6><b><u (click)="addNew('7')">Select </u></b></h6>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-2 text-right">
                                            Leaf:
                                        </div>
                                        <div class="col-2">
                                            <ng-select [items]="mainService.codeValueTechObj['PROPFLD12']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                placeholder="Select..." [(ngModel)]="obj['leaf_node_type']"
                                                [selectableGroup]="true" disabled [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                            </ng-select>
                                        </div>


                                        <div class="col-2">

                                            <input type="text" disabled class="form-control"
                                                [(ngModel)]="obj['leaf_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>

                                        <div class="col-2">

                                            <input type="text" disabled class="form-control"
                                                [(ngModel)]="obj['leaf_value']">

                                        </div>







                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-primary" (click)="save()">Submit</button>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div *ngIf="update1_flag">
                            <div class="card">
                                <div style="margin: 2%; margin-left: 10%;">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-success">Go to
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-2 text-center"
                                            style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                                            <h6> Node Type</h6>

                                        </div>
                                        <div class="col-2 text-center"
                                            style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                                            <h6><b>Code</b></h6>


                                        </div>
                                        <div class="col-2 text-center"
                                            style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                                            <h6><b> Value</b></h6>
                                        </div>


                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-2 text-right">
                                            Level 1:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl1_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl1_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl1_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl1_node_type']" (change)="set_leaf_node_type(1)"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl1_type']!='text'">
                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl1_cd']"
                                                disabled>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl1_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                disabled [(ngModel)]="obj['lvl1_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl1_type']!='text'">
                                            <ng-select [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                                (change)="onChangeLvl1()" [multiple]="false"
                                                placeholder="Select Level 1 " [(ngModel)]="obj['lvl1_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl1_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl1_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl1_value']">

                                        </div>
                                        <!-- <div id="text" class="col-2" (click)="addNew('1')"
                                            *ngIf="obj['lvl1_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl1_type']=='text'"
                                            (click)="addNew('1')" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Select </u></b></h6>
                                        </div> -->
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-2 text-right">
                                            Level 2:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl2_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl2_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl2_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl2_node_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" (change)="set_leaf_node_type(2)"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl2_type']!='text'">
                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl2_cd']"
                                                disabled>

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl2_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                disabled [(ngModel)]="obj['lvl2_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl2_type']!='text'">
                                            <ng-select [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                                (change)="onChangeLvl2()" [multiple]="false"
                                                placeholder="Select Level 2" [(ngModel)]="obj['lvl2_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl2_value">
                                            </ng-select>
                                        </div>

                                        <div class="col-2" *ngIf="obj['lvl2_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl2_value']">

                                        </div>
                                        <!-- <div id="text" class="col-2" (click)="addNew('2')"
                                            *ngIf="obj['lvl2_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl2_type']=='text'"
                                            (click)="addNew('2')" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Select </u></b></h6>
                                        </div> -->
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Level 3:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl3_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl3_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl3_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl3_node_type']" (change)="set_leaf_node_type(3)"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl3_type']!='text'">

                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl3_cd']"
                                                disabled>

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl3_type']=='text'">

                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl3_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl3_type']!='text'">
                                            <ng-select [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                                (change)="onChangeLvl3()" [multiple]="false"
                                                placeholder="Select Level 3" [(ngModel)]="obj['lvl3_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl3_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl3_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl3_value']">

                                        </div>
                                        <div id="text" class="col-2" (click)="addNew('3')"
                                            *ngIf="obj['lvl3_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl3_type']=='text'"
                                            (click)="addNew('3')" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Select </u></b></h6>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Level 4:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl4_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl4_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl4_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl4_node_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" (change)="set_leaf_node_type(4)"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl4_type']!='text'">

                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl4_cd']"
                                                disabled>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl4_type']=='text'">

                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl4_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>

                                        <div class="col-2" *ngIf="obj['lvl4_type']!='text'">
                                            <ng-select [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                                (change)="onChangeLvl4()" [multiple]="false"
                                                placeholder="Select Level 4" [(ngModel)]="obj['lvl4_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl4_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl4_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl4_value']">

                                        </div>
                                        <div id="text" class="col-2" (click)="addNew('4')"
                                            *ngIf="obj['lvl4_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl4_type']=='text'"
                                            (click)="addNew('4')" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Select </u></b></h6>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Level 5:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl5_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl5_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl5_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl5_node_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" (change)="set_leaf_node_type(5)"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl5_type']!='text'">

                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl5_cd']"
                                                disabled>

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl5_type']=='text'">

                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl5_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl5_type']!='text'">

                                            <ng-select [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                                (change)="onChangeLvl5()" [multiple]="false"
                                                placeholder="Select Level 5" [(ngModel)]="obj['lvl5_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl5_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl5_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl5_value']">

                                        </div>
                                        <div id="text" class="col-2" (click)="addNew('5')"
                                            *ngIf="obj['lvl5_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl5_type']=='text'"
                                            (click)="addNew('5')" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Select </u></b></h6>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Level 6:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl6_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl6_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl6_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl6_node_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" (change)="set_leaf_node_type(6)"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl6_type']!='text'">

                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl6_cd']"
                                                disabled>

                                        </div>

                                        <div class="col-2" *ngIf="obj['lvl6_type']=='text'">

                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl6_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl6_type']!='text'">

                                            <ng-select [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                                (change)="onChangeLvl6()" [multiple]="false"
                                                placeholder="Select Level 6" [(ngModel)]="obj['lvl6_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="lvl6_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl6_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl6_value']">
                                        </div>
                                        <div id="text" class="col-2" (click)="addNew('6')"
                                            *ngIf="obj['lvl6_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl6_type']=='text'"
                                            (click)="addNew('6')" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Select </u></b></h6>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Level 7:
                                        </div>
                                        <div class="col-2">
                                            <ng-select *ngIf="obj['lvl7_type']!='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl7_node_type']" disabled [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                            <ng-select *ngIf="obj['lvl7_type']=='text'"
                                                [items]="mainService.codeValueTechObj['PROPFLD12']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="obj['lvl7_node_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" (change)="set_leaf_node_type(7)"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl7_type']!='text'">

                                            <input type="text" class="form-control" [(ngModel)]="obj['lvl7_cd']"
                                                disabled>

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl7_type']=='text'">

                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl7_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl7_type']!='text'">

                                            <ng-select [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                                [multiple]="false" placeholder="Select Level 7"
                                                [(ngModel)]="obj['lvl7_cd']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                (change)="onChangeLvl7()" name="lvl7_value">
                                            </ng-select>
                                        </div>
                                        <div class="col-2" *ngIf="obj['lvl7_type']=='text'">
                                            <input type="text" class="form-control" (change)="makingLeafValues()"
                                                [(ngModel)]="obj['lvl7_value']">

                                        </div>
                                        <div id="text" class="col-2" (click)="addNew('7')"
                                            *ngIf="obj['lvl7_type']!='text'" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Add New </u></b></h6>
                                        </div>
                                        <div id="text" class="col-2" *ngIf="obj['lvl7_type']=='text'"
                                            (click)="addNew('7')" style="color: rgb(37, 130, 236);">
                                            <h6><b><u>Select </u></b></h6>
                                        </div>
                                    </div>

                                    <br>

                                    <div class="row">

                                        <div class="col-2 text-right">
                                            Leaf:
                                        </div>
                                        <div class="col-2">
                                            <ng-select [items]="mainService.codeValueTechObj['PROPFLD12']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                placeholder="Select..." [(ngModel)]="obj['leaf_node_type']"
                                                [selectableGroup]="true" disabled [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                            </ng-select>
                                        </div>
                                        <div class="col-2">

                                            <input type="text" disabled class="form-control"
                                                [(ngModel)]="obj['leaf_cd']"  onkeypress="return /[a-z0-9_-]/i.test(event.key)"
                                                oninput="this.value = this.value.toUpperCase()">

                                        </div>

                                        <div class="col-2">

                                            <input type="text" disabled class="form-control"
                                                [(ngModel)]="obj['leaf_value']">

                                        </div>







                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-primary"
                                                (click)="update_hier()">Update</button>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div *ngIf="create2_flag">
                            <div class="card">
                                <div style="margin: 2%;">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-success">Go to
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Hierarchy Identification Business Code : <span style="color: red;">
                                                    *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" [(ngModel)]="Obj['hier_ident_code']" class="form-control"
                                                placeholder="Enter Hierarchy Identification Business Code ">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Hierarchy Name : <span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">

                                            <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                [(ngModel)]="Obj['hier_buss_name']" placeholder="Enter Hierarchy Name "
                                                class="form-control"></textarea>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Hierarchy Purpose : <span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="mainService.codeValueTechObj['PROPFLD10']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                placeholder="Select..." [(ngModel)]="Obj['hier_type']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="submit()" class="btn btn-outline-primary">Submit</button>
                                        </div>
                                    </div>
                                    <br>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="update2_flag">
                            <div class="card">
                                <div style="margin: 2%;">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-success">Go to
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Hierarchy Identification Business Code : <span style="color: red;">
                                                    *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" disabled [(ngModel)]="Obj['hier_ident_code']"
                                                class="form-control"
                                                placeholder="Enter Hierarchy Identification Business Code ">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Hierarchy Name : <span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">

                                            <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                [(ngModel)]="Obj['hier_buss_name']" placeholder="Enter Hierarchy Name "
                                                class="form-control"></textarea>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Hierarchy Purpose : <span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="mainService.codeValueTechObj['PROPFLD10']"
                                                bindLabel="value" disabled bindValue="code" [multiple]="false"
                                                placeholder="Select..." [(ngModel)]="Obj['hier_type']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="update()" class="btn btn-outline-primary">Update</button>
                                        </div>
                                    </div>
                                    <br>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="list2_flag">

                            <div class="card">
                                <div style="margin: 2%;">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-success">Go to
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-1"></div>
                                        <div class="col-2">
                                            <h6>Business Entity Type :</h6>
                                        </div>
                                        <div class="col-2">
                                            <ng-select [items]="buss_ent_type" bindLabel="id" disabled bindValue="id"
                                                [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="Obj['buss_ent_type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-2">
                                            <h6>Hierarchy Purpose :</h6>
                                        </div>
                                        <div class="col-2">
                                            <ng-select [items]="mainService.codeValueTechObj['PROPFLD10']"
                                                bindLabel="value" disabled bindValue="code" [multiple]="false"
                                                placeholder="Select..." [(ngModel)]="Obj['hier_type']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                                <!--Select Location Type-->
                                            </ng-select>
                                        </div>
                                        <div class="col-1"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-1"></div>
                                        <div class="col-2">
                                            <h6>Hierarchy code :</h6>
                                        </div>
                                        <div class="col-2">
                                            <input type="text" disabled [(ngModel)]="Obj['hier_ident_code']"
                                                class="form-control"
                                                placeholder="Enter Hierarchy Identification Business Code ">
                                        </div>
                                        <div class="col-2">
                                            <h6>Hierarchy Name :</h6>
                                        </div>
                                        <div class="col-2">
                                            <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                [(ngModel)]="Obj['hier_buss_name']" placeholder="Enter Hierarchy Name "
                                                disabled class="form-control"></textarea>
                                        </div>
                                        <div class="col-1">
                                            <button (click)="open_update_hier()"
                                                class="btn btn-outline-success">Update</button>
                                        </div>
                                    </div>
                                    <br>


                                    <div class="row">
                                        <div class="col-6">
                                            <mat-form-field style="margin-left: 2%">
                                                <input matInput (keyup)="applyFilter($any($event.target).value)"
                                                    placeholder="Filter">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button class="btn btn-outline-success" (click)="open_create_node()"> +
                                                Add</button>
                                        </div>
                                    </div>
                                    <div class=" example-container">
                                        <table mat-table [dataSource]="datasource" matSort>
                                            <ng-container matColumnDef="id">
                                                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Leaf Node Type
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                                    style="background-color: rgb(221, 221, 221); color: black;"
                                                    class="right">
                                                    {{ element.leaf_node_type }}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="lvl1_cd">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 1 Code

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl1_cd }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lvl1_value">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 1
                                                    Value

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl1_value }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lvl2_cd">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 2 Code

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl2_cd }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lvl2_value">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 2
                                                    Value

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl2_value }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lvl3_cd">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 3 Code

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl3_cd }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lvl3_value">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 3
                                                    Value

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl3_value }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lvl4_cd">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 4 Code

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl4_cd }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lvl4_value">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 4
                                                    Value

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl4_value }} </td>
                                            </ng-container>



                                            <ng-container matColumnDef="lvl5_cd">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 5 Code
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl5_cd }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lvl5_value">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 5
                                                    Value
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl5_value }} </td>
                                            </ng-container>




                                            <ng-container matColumnDef="lvl6_cd">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 6 Code
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl6_cd }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lvl6_value">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 6
                                                    Value
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl6_value }} </td>
                                            </ng-container>




                                            <ng-container matColumnDef="lvl7_cd">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 7 Code
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl7_cd }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lvl7_value">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Level 7
                                                    Value
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.lvl7_value }} </td>
                                            </ng-container>


                                            <ng-container matColumnDef="leaf_cd">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">Leaf
                                                    Code -Desc
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right"
                                                    style="background-color: rgb(221, 221, 221); color: rgb(22, 1, 1);">
                                                    {{ element.leaf_cd }} - {{ element.leaf_value }} </td>
                                            </ng-container>
                                            <!-- <ng-container matColumnDef="leaf_value">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">Leaf
                                                    Value

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right"
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    {{ element.leaf_value }} </td>
                                            </ng-container> -->
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header
                                                    style="background-color: rgb(221, 221, 221); color: black;">
                                                    Action</th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    <button *ngIf="element['count'] > 2" class="btn btn-outline-primary"
                                                        (click)="open_update(element)">Update</button>
                                                    <button *ngIf="element['count'] > 2" class="btn btn-outline-danger"
                                                        (click)="delete(element)">Delete</button>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                        </mat-paginator>
                                    </div>


                                </div>
                            </div>

                        </div>



                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
