<div *ngIf="list">
    <div class="page-content fade-in-up">

        <div class="ibox">
            <div class="ibox-head">
                <div class="ibox-title">
                    Welcome , {{erpUser.party_name}}
                </div>
            </div>
            <div>


                <br><br>
                <div class="row">
                    <div class="col-5">
                        <hr class="hrline" />
                    </div>
                    <div class="col-2 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px; margin-top:5px;">
                            Tender Details
                        </h6>
                    </div>
                    <div class="col-5">
                        <hr class="hrline" />
                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="ibox color-white widget-stat" style="background-color: #e59704;">
                            <div class="ibox-body">


                                <h2 class="m-b-5 font-strong">{{tender_count}}</h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8">Total Tender </div>
                                    </div>
                                    <div class="col-2"></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            (click)="getAllTender()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <!-- <div class="ibox cardgreen color-white widget-stat"> -->
                        <div class="ibox color-white widget-stat" style="background-color:#27a844 ;">
                            <div class="ibox-body">
                                <h2 class="m-b-5 font-strong">{{vendor_count}}</h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8"> Total Vendor </div>
                                    </div>
                                    <div class="col-2"></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            (click)="getAllVendors()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <!-- <div class="ibox cardRed color-white widget-stat"> -->
                        <div class="ibox color-white widget-stat" style="background-color: #5e9ea0;">
                            <div class="ibox-body">
                                <h2 class="m-b-5 font-strong">{{assign_count}}</h2>
                                <div class="row d-flex">
                                    <div class="col-7">
                                        <div class="m-b-8">Total Assign Vendor </div>
                                    </div>
                                    <div class="col-1"></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            (click)="getAllAssignment()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>


                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <!-- <div class="ibox cardRed color-white widget-stat"> -->
                        <div class="ibox color-white widget-stat" style="background-color: #5e9ea0;">
                            <div class="ibox-body">
                                <h2 class="m-b-5 font-strong">{{tot_applied_vendor}}</h2>
                                <div class="row d-flex">
                                    <div class="col-7">
                                        <div class="m-b-8">Total Applied Vendor </div>
                                    </div>
                                    <div class="col-1"></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            (click)="getAppliedVendor()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>


                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="ibox cardblue  color-white widget-stat">
                            <div class="ibox-body">


                                <h2 class="m-b-5 font-strong">{{emd_count}}</h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8">Total EMD Amount</div>
                                    </div>
                                    <div class="col-2"></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            (click)="getEMDFeeDetails()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <!-- <div class="ibox cardYello color-white widget-stat"> -->
                        <div class="ibox  color-white widget-stat" style="background-color: #ea783c;">
                            <div class="ibox-body">
                                <h2 class="m-b-5 font-strong">{{tot_refund}}</h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8">Total Refund </div>
                                    </div>
                                    <div class="col-2"></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            (click)="getRefundDetails()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-5">
                        <hr class="hrline" />
                    </div>
                    <div class="col-2 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px; margin-top:5px;">
                            Payment
                        </h6>
                    </div>
                    <div class="col-5">
                        <hr class="hrline" />
                    </div>
                </div> -->

                <div class="row">
                    <!-- <div class="col-lg-4 col-md-6">
                        <div class="ibox cardblue  color-white widget-stat">
                            <div class="ibox-body">


                                <h2 class="m-b-5 font-strong">{{emd_count}}</h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8">Total EMD Amount</div>
                                    </div>
                                    <div class="col-2"></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0" (click)="getEMDFeeDetails()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="ibox  color-white widget-stat" style="background-color: #ea783c;">
                            <div class="ibox-body">
                                <h2 class="m-b-5 font-strong">{{tot_refund}}</h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8">Total Refund </div>
                                    </div>
                                    <div class="col-2"></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0" (click)="getRefundDetails()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-lg-4 col-md-6">
                        <div class="ibox cardblck color-white widget-stat">
                            <div class="ibox-body">
                                <h2 class="m-b-5 font-strong">0</h2>
                                <div class="row d-flex">
                                    <div class="col-7">
                                        <div class="m-b-8">OTHER</div>
                                    </div>
                                    <div class="col-1"></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>


                            </div>
                        </div>
                    </div> -->
                </div>
                <hr>

            </div>


        </div>

    </div>

</div>

<div *ngIf="table_show">
    <div class="page-content fade-in-up">
        <div class="row">
            <div class="col-12">
                <div class="ibox">
                    <div class="ibox-head">
                        <div class="ibox-title">{{header_name}}</div>
                    </div>
                    <div class="ibox-body">
                        <div class="tab-content">
                            <div>
                                <div class="row mt-2">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)"
                                                placeholder="Filter">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">

                                    </div>
                                    <div class="col-4 text-right">
                                        <button class="btn btn-outline-primary" (click)="dashboard()">Dashboard</button>
                                    </div>
                                </div>

                                <div class="row" *ngIf="header_name =='EMD AMOUNT DETAILS'">
                                    <br><br>
                                    <div class="col-12 text-right">
                                        <button class="btn btn-outline-success"
                                            (click)="downloadEMD()">Print</button>
                                    </div>
                                </div>
                                <div class="row" *ngIf="header_name =='REFUND AMOUNT DETAILS'">
                                    <br><br>
                                    <div class="col-12 text-right">
                                        <button class="btn btn-outline-success"
                                            (click)="downloadRefund()">Print</button>
                                    </div>
                                </div>
                                <div class="example-container">
                                    <mat-table [dataSource]="dataSource">
                                        <ng-container *ngFor="let disCol of displayedColumns;"
                                            matColumnDef="{{disCol}}">
                                            <mat-header-cell *matHeaderCellDef> <span
                                                    class="text-center">{{displayedColumnsWithKeys[disCol]}}</span>
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element "> <span class="text-center">
                                                    {{element[disCol]}} </span> </mat-cell>
                                        </ng-container>
                                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                    </mat-table>
                                    <mat-paginator [pageSizeOptions]="[5,10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
