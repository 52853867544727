import { Injectable } from '@angular/core';
// import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import {io} from 'socket.io-client'
@Injectable({
  providedIn: 'root'
})
export class SocketHandleService {
  socket:any
  url:any="https://tracking.upda.co.in:3028"
  // url:any="http://192.168.29.96:3002"

  socketId:string=""
  // currentUser:{ userName: String; name: String; id: any; } | undefined
  constructor() {
    this.socket = io(this.url+'/tracking')
    this.socket.on('connect', () => {
      this.socketId = this.socket.id
      // if(this.currentUser?.userName)
         this.socket.emit('dashboardUser',JSON.parse(atob(localStorage.getItem('erpUser')as any)).le_id)
    });
   }

  listen(eventName:string){
    console.log(this.socket.id)
    return new Observable((subscriber)=>{
      this.socket.on(eventName,(data:any)=>{
        subscriber.next(data)
      })
    })
  }

  emit(eventName:string,data:any){
    this.socket.emit(eventName,data);
  }
  emitWithCB(eventName:string,data:any,cb:any){
    this.socket.emit(eventName,data,cb);
  }

  // user entry api
  userEntry(userData:any){
     this.socket.emit('usr-entry',userData)
  }
}
