import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../../service/establishment.service';
import { AllEmpService } from '../../service/all-emp.service';
import { MainService } from '../../service/main.service';
import { PayrollService } from '../../service/payroll.service';
declare var $: any
import swal from 'sweetalert2';
import { resolve } from 'path';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-emp-transfer',
  templateUrl: './emp-transfer.component.html',
  styleUrls: ['./emp-transfer.component.css']
})
export class EmpTransferComponent implements OnInit {

  constructor(public mainService: MainService, private payrollService: PayrollService, private allEmpService: AllEmpService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private estabService: EstablishmentService) { }
  erpUser:any;
  b_acct_id:any;
  user_id:any;
  popFlagTransfer :any= false;
  submitFlag:any;
  id:any;
  activeEmpArr :any= [];
  allEmployees:any = [];
  selectEmpObj:any = {};
  transfernObj :any= {};
  codeValueTechObj:any = {};
  transferdArr :any= [];
  allArr:any = []
  employeeObj:any = {};
  newallEmplyees:any = [];
  revokeObj:any = {};
  old_fix_pay :any= [];
  new_fix_pay :any= [];
  allEmp:any = [];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns = ['emp_id', 'emp_name', 'transfer_date', 'order_id', 'action'];
  datasource:any;

  async ngOnInit() {
    this.submitFlag = true;
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllEmployees();
    await this.getAllCurrentArrangements();

  }

  async getAllCurrentArrangements() {
    this.spinner.show();
    this.transferdArr = [];
    this.allArr = [];
    var transferdArr1 = []
    var arr:any = []
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status_code'] = 'INACTIVE';

    var resp = await this.estabService.getAllCurrentArrangements(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allArr = resp.data;
      for (let i = 0; i < this.allArr.length; i++) {
        if (this.allArr[i].employee_current_type_code == "TRANSFERRED") {
          this.transferdArr.push(this.allArr[i]);

        }

      }
      arr = this.transferdArr
      for (let i = 0; i < arr.length; i++) {
        var obj :any= new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])
        transferdArr1.push(obj)
      }
      this.spinner.hide();
      for (let i = 0; i < transferdArr1.length; i++) {
        if (transferdArr1[i]['effective_timestamp']) {
          transferdArr1[i]['effective_timestamp'] = this.mainService.dateformatchange(transferdArr1[i]['effective_timestamp'].split(' ')[0])
          transferdArr1[i]['transfer_date'] = this.mainService.dateformatchange(transferdArr1[i]['valid_from'].split(' ')[0])
        }
      }
      this.datasource = new MatTableDataSource(transferdArr1)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting All Transfer Employee', 'error')
      // this.snackBar.open("Error while getting All Transfer Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }
  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }
  // comment by deepankar
  // async getAllEmployees() {
  //   this.spinner.show()
  //   var obj :any= new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['emp_status_code'] = ["JOINING", "JOINED", "LEFT"];
  //   var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.allEmployees = resp.data;

  //     this.newallEmplyees = []
  //     for(let i=0;i<this.allEmployees.length;i++){
  //       var obj=new Object();
  //       obj=Object.assign({},this.allEmployees[i]);
  //       obj['emp_name']=/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])+"-"+obj['emp_name']
  //       this.newallEmplyees.push(obj)
  //     }
  //     for (let i=0; i<this.allEmployees.length ;i++){
  //       this.employeeObj[this.allEmployees[i]['emp_id']]=this.allEmployees[i]['emp_name'];
  //     }
  //     this.spinner.hide()
  //   } else {
  //     this.spinner.hide()
  //     this.snackBar.open("Error while getting employee list", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }

  async getAllEmployees() {
    this.spinner.show()
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status_code'] = ["ACTIVE", "INACTIVE"];
    var resp = await this.estabService.getArrayAllCurrentEstablishementInfo(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allEmployees = resp.data;
      this.allEmp = resp.data;
      this.activeEmpArr = []

      for (let i = 0; i < this.allEmployees.length; i++) {
        this.employeeObj[this.allEmployees[i]['emp_id']] = this.allEmployees[i]['emp_name'];
        if (this.allEmployees[i].emp_status_code == "ACTIVE") {
          this.activeEmpArr.push(this.allEmployees[i])
        }
      }
      this.newallEmplyees = []
      for (let i = 0; i < this.activeEmpArr.length; i++) {
        var obj :any= new Object();
        obj = Object.assign({}, this.activeEmpArr[i]);
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
      }

    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  changeTransfer() {
    this.popFlagTransfer = false;
    this.submitFlag = true;
  }

  async submitTransfer() {
    if (this.selectEmpObj == "" || this.selectEmpObj == null || this.selectEmpObj == undefined ||
      this.transfernObj['date_of_transfer'] == "" || this.transfernObj['date_of_transfer'] == null || this.transfernObj['date_of_transfer'] == undefined) {
      Swal.fire("Warning", "* Field Required !", 'warning');
      return;
    }


    swal.fire({
      title: 'Are you sure want to Post?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#66',
      confirmButtonText: 'Yes, post it!'
    }).then((result) => {
      if (result.value) {
        this.submitPost(this.id)
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.popFlagTransfer = true;
        this.submitFlag = false;
      }
      else {
      }
    }).catch(e);

  }
  async submitPost(id:any) {
    this.router.navigate(['/hrms/arr/posting']);
  }
  async submitTransfer1() {
    if (this.selectEmpObj == "" || this.selectEmpObj == null || this.selectEmpObj == undefined ||
      this.transfernObj['date_of_transfer'] == "" || this.transfernObj['date_of_transfer'] == null || this.transfernObj['date_of_transfer'] == undefined) {
      Swal.fire("Warning", "* Field Required !", 'warning');
      return;
    }

    this.transfernObj['b_acct_id'] = this.b_acct_id;
    this.transfernObj['emp_id'] = this.selectEmpObj;
    this.transfernObj['update_user_id'] = this.erpUser.user_id;
    this.transfernObj['emp_status_code'] = "INACTIVE";
    this.transfernObj['employee_current_type_code'] = "TRANSFERRED";
    for (let i = 0; i < this.allEmployees.length; i++) {
      if (this.transfernObj['emp_id'] == this.allEmployees[i]['emp_id']) {
        var arr_id = this.allEmployees[i]['arr_id']
      }
    }
    this.transfernObj['arr_id'] = arr_id;
    //this.transfernObj['calculation_code'] = "NA";
    this.spinner.show();
    var resp = await this.estabService.addEmpTransfer(this.transfernObj);
    if (resp['error'] == false) {
      await this.getAllCurrentArrangements();
      this.transfernObj = {};
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      this.spinner.hide();
      // this.snackBar.open("Transfer  Added Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Transfer  Added Successfully!", 'success')
    } else {
      this.spinner.hide();
      // this.snackBar.open("Error while Adding Transfer Of Employee", 'Error', {
      //   duration: 5000
      // });
      swal.fire("Error", "Error while Adding Transfer Of Employee", 'error')
    }
  }
  async revoke_transfer(element:any) {
    this.revokeObj = Object.assign({}, element);
    await this.getFixPayOfTransferredEmp(element['emp_id'], element['valid_from']);
    $('#revoke').modal('show');
  }
  async SubmitRevokeTransfer() {
    if (this.revokeObj['order_id'] == null || this.revokeObj['order_id'] == undefined) {
      // this.snackBar.open("Please enter order id.....", 'Error', {
      //   duration: 5000
      // });

      swal.fire("Warning", "Please enter order id.....", 'warning')
      return;
    }
    else if (this.revokeObj['revoked_date'] == "" || this.revokeObj['revoked_date'] == null || this.revokeObj['revoked_date'] == undefined) {
      swal.fire("Warning", "Please enter revoked_date.....", 'warning')
      return;

    }
    this.revokeObj['b_acct_id'] = this.b_acct_id;
    this.revokeObj['update_user_id'] = this.erpUser.user_id;
    this.revokeObj['emp_status_code'] = "ACTIVE";
    this.revokeObj['employee_current_type_code'] = "TRANSFERRED_REVOKED";
    this.revokeObj['ids'] = [];
    this.new_fix_pay.map((x:any) => { this.revokeObj['ids'].push(x['id']) });
    this.spinner.show();
    var resp = await this.estabService.revoketransfer(this.revokeObj);
    if (resp['error'] == false) {
      await this.getAllCurrentArrangements();
      this.spinner.hide();
      this.revokeObj = {};
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      // this.snackBar.open("Transfer Revoked Added Successfully!", 'Success', {
      //   duration: 5000
      // });
      await this.getAllEmployees();
      swal.fire('Success', 'Transfer Revoked Added Successfully!', 'success')
    } else {
      this.spinner.hide();
      // this.snackBar.open("Error while Adding Transfer Revoked Of Employee", 'Error', {
      //   duration: 5000
      // });
      swal.fire('Erronr', "Error while Adding Transfer Revoked Of Employee", 'error')
    }
    $('#revoke').modal('hide');
  }
  // async rejoin_transfer(){
  //  this.router.navigate(["/hrms/arr/establish"])
  // }

  async getFixPayOfTransferredEmp(emp_id:any, valid_from:any) {

    this.old_fix_pay = [];
    this.new_fix_pay = [];
    this.spinner.show()
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    //obj['emp_id'] = emp_id;
    obj['valid_from'] = valid_from;
    for (let i = 0; i < this.allEmp.length; i++) {
      if (emp_id == this.allEmp[i]['emp_id']) {
        obj['arr_id'] = this.allEmp[i]['arr_id'];
        break;
      }
    }
    var resp = await this.payrollService.getAllFixedPay(obj);
    if (resp['error'] == false) {
      this.old_fix_pay = resp.data;
      this.new_fix_pay = [];
      this.old_fix_pay.map((x:any) => {
        let ob = Object.assign({}, x)
        ob['effective_end_dt'] = '2037-12-01';
        this.new_fix_pay.push(ob)
      })
      this.spinner.hide()
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee old fix pay..', 'error')
      // this.snackBar.open("Error while getting employee old fix pay..", 'Error', {
      //   duration: 5000
      // });
    }
  }
  // dateformatchange(date){
  //   if(date==null || date == undefined){
  //     return "";
  //   }
  //   var datear1 = date.split('T')[0]
  //   var datearr = datear1.split("/")
  //   return datearr[2]+'-'+datearr[1]+'-'+datearr[0]
  // }
  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }

}
function e(reason: any): PromiseLike<never> {
  throw new Error('Function not implemented');
}

