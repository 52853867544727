import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AllotmentLetterConfigurationService } from '../../service/allotment-letter-configuration.service';

@Component({
  selector: 'app-public-config',
  templateUrl: './public-config.component.html',
  styleUrls: ['./public-config.component.css'],

})
export class PublicConfigComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  constructor(private spinner: NgxSpinnerService, private allotment_service: AllotmentLetterConfigurationService) { }
  erpUser:any
  b_acct_id:any
  ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    console.log(this.erpUser)
    this.getImageDetails()
  }


  dataSource: any
  displayedColumns: string[] = ['Sno', 'link_type', 'link', 'User Id', 'action'];
  all_links_type = ['Image', 'Facebook', 'Instagram', 'Youtube', 'Twitter']


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  create_screen = false
  list_screen = true
  update_screen = false
  Obj:any  = {}

  link_info = [{ type: null, link: '' }]

  addMore(i:any ) {
    // for( i< this.link_info.length ; i++){
    this.link_info.push({ type: null, link: '' });
    // }
  }
  remove(i: number) {
    this.link_info.splice(i, 1);
  }


  open_create() {
    this.create_screen = true
    this.list_screen = false
    this.update_screen = false
  }

  open_list() {
    this.create_screen = false
    this.list_screen = true
    this.update_screen = false
  }
  async submit() {
    this.spinner.show()
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['link_info'] = this.link_info
    this.Obj['life_cycle_status'] = 'ACTIVE'
    this.Obj['user_id'] = this.erpUser.user_id

    let resp = await this.allotment_service.submit(this.Obj)
    if (resp.error == false) {
      Swal.fire('Success', 'Successfully Submitted!', 'success')
      // console.log(resp.data)
      this.link_info = [{ type: null, link: '' }]
      this.Obj['desc'] = null
      // this.Obj['type'] = null
      this.getImageDetails()
      this.spinner.hide()
    } else {
      this.spinner.hide()
      console.log(resp)
      Swal.fire('Error', 'Something Went Wrong!', 'error')
    }

  }

  async deleteLink(element:any) {
    let obj:any  = {}
    obj['id'] = element.id
    obj['b_acct_id'] = this.b_acct_id
    Swal.fire({
      title: 'Are you sure?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        let resp = await this.allotment_service.deleteLink(obj)
        if (resp.error == false) {
          // console.log(resp)
          this.getImageDetails()
          Swal.fire('Success', 'Deleted Successfully', 'success')
          this.spinner.hide()
        } else {
          this.spinner.hide()
          console.log(resp)
          Swal.fire('Error', 'Something Went Wrong!', 'error')
        }
      }
    })
  }

image_dtls = []
  async getImageDetails() {
  this.spinner.show()
  this.Obj['b_acct_id'] = this.b_acct_id
  let resp = await this.allotment_service.getImageDetails(JSON.stringify(this.Obj))
  if (resp.error == false) {
    this.image_dtls = resp.data
    // console.log(this.image_dtls)
    this.dataSource = new MatTableDataSource(this.image_dtls);
    this.dataSource.sort = this.sort.toArray()[0];
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.spinner.hide()
  } else {
    this.spinner.hide()
    console.log(resp)
    Swal.fire('Error', 'Something Went Wrong!', 'error')
  }
}


Obj2:any  = {}
open_update(element:any) {
  // console.log(element)
  this.create_screen = false
  this.list_screen = false
  this.update_screen = true
  this.Obj2  = element
  this.Obj2['link'] = element.image_link
  this.Obj2['type'] = element.link_type
  this.Obj2['id'] = element.id
  this.Obj2['pub_portal_config_id'] = element.pub_portal_config_id
}


async update() {
  this.spinner.show()
  this.Obj2['user_id'] = this.erpUser.user_id;
  this.Obj2['b_acct_id'] = this.b_acct_id;
  let resp = await this.allotment_service.updateLink(this.Obj2);
  if (resp['error'] == false) {
    this.spinner.hide()
    this.getImageDetails();
    this.open_list();
    Swal.fire('SUCCESS', 'Updated Successfully', 'success')
  } else {
    this.spinner.hide()
    Swal.fire('ERROR', 'Error While Assigning Tender', 'error');
  }
}
}
