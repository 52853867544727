<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING1']}}
                        <!-- DEDUCTION MAPPING -->
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING2']}}
                                <!-- Deduction Mapping List -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="#tab-2" data-toggle="tab"><i class="ti-bar-chart"></i>
                                System Deduction
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING3']}}
                                <!-- Create Deduction Mapping -->
                            </a>
                        </li>
                        <li class="nav-item disabled">
                            <a class="nav-link" href="#tab-4" data-toggle="tab"><i class="ti-settings"></i>
                                Update System Deduction
                            </a>
                        </li>
                        <li class="nav-item disabled">
                            <a class="nav-link" href="#tab-5" data-toggle="tab" disabled><i class="ti-settings"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING4']}}
                                <!-- Update
                                Deduction Mapping -->
                            </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING5']}}">
                                        <!-- Filter -->
                                    </mat-form-field>
                                </div>

                            </div>
                            <br>
                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort #sortCol1="matSort">
                                    <ng-container matColumnDef="Sr_n">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING6']}}
                                            <!-- Sr. No. -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="center">
                                            {{ i+1 }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="ded_code">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING7']}}
                                            <!-- Deduction Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{element.ded_code}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ded_type">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING8']}}
                                            <!-- Deduction Type -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{element.ded_type}}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="amount">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING9']}}
                                            <!-- Amount -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{element.amount +"%"}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ded_date">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING10']}}
                                            <!-- Date -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{element.ded_date}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="gov_rule">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING11']}}
                                            <!-- Rule -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{element.gov_rule}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="act_cd">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>Activity
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{activityObj[element.act_cd]}}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="bud_cd">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>Budget
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{budgetObj[element.bud_cd]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING12']}}
                                            <!-- Action -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            <button class="btn btn-primary" type="button"
                                                (click)="open_update(element)">{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING13']}}
                                                <!-- Update -->
                                            </button>

                                            <button class="btn btn-danger" style="margin-left: 2px;" type="button"
                                                (click)="delete(element)">Delete</button>


                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="list"></tr>
                                    <tr mat-row *matRowDef="let row; columns: list;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                            <br>

                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter2($any($event.target).value)"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING5']}}">
                                        <!-- Filter -->
                                    </mat-form-field>
                                </div>

                            </div>
                            <br>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource1" matSort #sortCol2="matSort">
                                    <ng-container matColumnDef="Sr_n">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING6']}}
                                            <!-- Sr. No. -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="center">
                                            {{ i+1 }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="ded_code">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING7']}}
                                            <!-- Deduction Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{element.ded_code}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ded_type">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING8']}}
                                            <!-- Deduction Type -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{element.ded_type}}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="amount">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING9']}}
                                            <!-- Amount -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{element.amount}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ded_date">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING10']}}
                                            <!-- Date -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{element.ded_date}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="gov_rule">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING11']}}
                                            <!-- Rule -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{element.gov_rule}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="act_cd">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>Activity
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{activityObj[element.act_cd]}}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="bud_cd">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>Budget
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            {{budgetObj[element.bud_cd]}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING12']}}
                                            <!-- Action -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="center">
                                            <button class="btn btn-primary" type="button"
                                                (click)="open_update_sys(element)">{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING13']}}
                                                <!-- Update -->
                                            </button>

                                            <!-- <button class="btn btn-danger" *ngIf="element.ded_owner!='SYSTEM'"  style="margin-left: 2px;" (click)="delete(element)">Delete</button> -->


                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="list2"></tr>
                                    <tr mat-row *matRowDef="let row; columns: list2;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator2 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                            <br>

                        </div>
                        <div class="tab-pane fade" id="tab-3">

                            <form name="form" (ngSubmit)="f3.form.valid && submit()" #f3="ngForm" novalidate>
                                <!--validating current form and hierarchy-->
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING7']}}
                                        <!--  Deduction Name --> :
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['ACC0057']"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING15']}}"
                                            bindLabel="value" bindValue="code" [multiple]="false"
                                            [(ngModel)]="Obj['ded_code']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="ded_name"
                                            #ded_name="ngModel" required>
                                            <!-- Select Deduction Name -->
                                        </ng-select>
                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.selection-box]="ded_name.dirty && ded_name.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f3.submitted && ded_name.invalid" class="error-message">
                                            <div *ngIf="ded_name.errors?.['required']">Please select deduction name
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING8']}}
                                        <!-- Deduction Type -->:
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="ded_type"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING16']}}"
                                            bindLabel="value" bindValue="code" [multiple]="false"
                                            [(ngModel)]="Obj['ded_type']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="ded_tpy"
                                            #ded_tpy="ngModel" required>
                                            <!-- Select Deduction Type -->
                                        </ng-select>


                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.selection-box]="ded_tpy.dirty && ded_name.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f3.submitted && ded_tpy.invalid" class="error-message">
                                            <div *ngIf="ded_tpy.errors?.['required']">Please select deduction type
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->


                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING9']}}
                                        <!-- Amount -->:
                                    </div>
                                    <div class="col-3">
                                        <input type="number" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING9']}}"
                                            [(ngModel)]="Obj['amount']" name="ded_amt" #ded_amt="ngModel" required>
                                        <!-- Amount -->

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="ded_amt.dirty && ded_amt.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f3.submitted && ded_amt.invalid" class="error-message">
                                            <div *ngIf="ded_amt.errors?.['required']">Please select deduction amount
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING17']}}
                                        <!-- Deduction Date -->:
                                    </div>
                                    <div class="col-3">
                                        <input type="date" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING17']}}"
                                            [(ngModel)]="Obj['ded_date']" name="d_date" #d_date="ngModel">
                                        <!-- Deduction Date -->

                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row" *ngIf="Obj['ded_code']=='IT'">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING18']}}
                                        <!-- Gov. Rule -->:
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING19']}}"
                                            [(ngModel)]="Obj['gov_rule']" name="rule_gov" #rule_gov="ngModel" required>

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="rule_gov.dirty && rule_gov.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f3.submitted && rule_gov.invalid" class="error-message">
                                            <div *ngIf="rule_gov.errors?.['required']">Please write rule
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->
                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br *ngIf="Obj['ded_code']=='IT'">
                                <br>
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%">Select Budget: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{budgetObj[Obj['bud_cd']]}}
                                        <div *ngIf="f3.submitted && !budgetObj[Obj['bud_cd']]" class="error-message">
                                            Please select budget hierarchy
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-primary"
                                            (click)="selectCreate('budget')">Select</button>
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%"> Select Activity: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{activityObj[Obj['act_cd']]}}
                                        <div *ngIf="f3.submitted && !activityObj[Obj['act_cd']]" class="error-message">
                                            Please select activity hierarchy
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-primary"
                                            (click)="selectCreate('activity')">Select</button>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary"
                                            type="submit">{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING20']}}
                                            <!-- Submit -->
                                        </button>
                                        <button class="btn btn-success d-none" id="subreset">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="tab-4">
                            <br>

                            <div class="row">
                                <div class="col-3">
                                    <h6 style="margin-left:2%">Select Budget: </h6>
                                </div>
                                <div class="col-3">
                                    {{budgetObj[Obj['bud_cd']]}}

                                </div>
                                <div class="col-1">
                                    <button type="button" class="btn btn-primary"
                                        (click)="selectCreate('budget')">Select</button>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3">
                                    <h6 style="margin-left:2%"> Select Activity: </h6>
                                </div>
                                <div class="col-3">
                                    {{activityObj[Obj['act_cd']]}}
                                    <!-- <div *ngIf="f.submitted && !activityObj[Obj['act_cd']]" class="error-message">
                                        Please select activity hierarchy
                                    </div> -->
                                </div>
                                <div class="col-1">
                                    <button type="button" class="btn btn-primary"
                                        (click)="selectCreate('activity')">Select</button>
                                </div>
                            </div>
                            <br>

                            <div class="row" *ngIf="hier_fg">
                                <div class="col-12 error-message text-center"> Please select budget hierarchy </div>
                                <br>
                            </div>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="update('tab_2')">{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING13']}}
                                        <!-- Update -->
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-5">
                            <br>
                            <form name="form" (ngSubmit)="f.form.valid && update('tab_1')" #f="ngForm">

                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING7']}}
                                        <!-- Deduction Name --> :
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['ACC0057']"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING15']}}"
                                            bindLabel="value" bindValue="code" [multiple]="false"
                                            [(ngModel)]="Obj['ded_code']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" disabled
                                            name="field_name">
                                            <!-- Select Deduction Name -->
                                        </ng-select>
                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING8']}}
                                        <!-- Deduction Type: -->
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="ded_type"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING16']}}"
                                            bindLabel="value" bindValue="code" [multiple]="false"
                                            [(ngModel)]="Obj['ded_type']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="type_ded"
                                            #type_ded="ngModel" required>
                                            <!-- Select Deduction Type -->
                                        </ng-select>

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.selection-box]="type_ded.dirty && type_ded.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && type_ded.invalid" class="error-message">
                                            <div *ngIf="type_ded.errors?.['required']">Please select deduction type
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>


                                <div class="row">
                                    <!-- <div class="col-3 text-right" *ngIf="Obj['ded_type'] == 'percentage' ">
                                        Percentage :
                                    </div>
                                    <div class="col-3 text-right" *ngIf="Obj['ded_type'] == 'fixed'">
                                        Amount :
                                    </div> -->

                                    <div class="col-3 text-right">
                                        <div *ngIf="Obj['ded_type'] == 'percentage'; else elseBlock">Percentage :</div>
                                        <ng-template #elseBlock>Amount :</ng-template>
                                    </div>

                                    <div class="col-3">
                                        <input type="number" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING9']}}"
                                            [(ngModel)]="Obj['amount']" name="num" #num="ngModel" required>
                                        <!-- Amount -->

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="num.dirty && num.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && num.invalid" class="error-message">
                                            <div *ngIf="num.errors?.['required']">Please enter amount
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->


                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING17']}}
                                        <!-- Deduction Date -->:
                                    </div>
                                    <div class="col-3">
                                        <input type="date" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING17']}}"
                                            [(ngModel)]="Obj['ded_date']" name="ded_date" #date_ded="ngModel" required>
                                        <!-- Deduction Date -->

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="date_ded.dirty && date_ded.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && date_ded.invalid" class="error-message">
                                            <div *ngIf="date_ded.errors?.['required']">Please select deduction date
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>
                                <br>
                                <div class="row" *ngIf="Obj['ded_code']=='IT'">
                                    <div class="col-3 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING18']}}
                                        <!-- Gov. Rule -->:
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING19']}}"
                                            [(ngModel)]="Obj['gov_rule']" name="rule_name"><!-- Rule Name -->

                                    </div>
                                    <div class="col-3">

                                    </div>
                                    <div class="col-3">

                                    </div>
                                </div>

                                <br *ngIf="Obj['ded_code']=='IT'">
                                <br>
                                <div class="row">
                                    <div class="col-3"></div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%">Select Budget: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{budgetObj[Obj['bud_cd']]}}
                                        <div *ngIf="f.submitted && !budgetObj[Obj['bud_cd']]" class="error-message">Please select
                                            budget hierarchy </div>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-primary"
                                            (click)="selectCreate('budget')">Select</button>
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3">
                                        <h6 style="margin-left:2%"> Select Activity: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{activityObj[Obj['act_cd']]}}
                                        <div *ngIf=" f.submitted && !activityObj[Obj['act_cd']]" class="error-message">Please select
                                            activity hierarchy </div>

                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-primary"
                                            (click)="selectCreate('activity')">Select</button>
                                    </div>
                                </div>
                                <br>


                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary"
                                            type="submit">{{mainService.allLableShowObj[mainService.language_cd+'DEDUCTION_MAPPING13']}}
                                            <!-- Update -->
                                        </button>
                                        <button class="btn btn-success d-none" id="tab5" type="reset">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>Loading....</ngx-spinner>

<div class="modal" id="selectCreate">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> Select Event :</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <br>
                <div class="row">
                    <div class="col-3 text-center">


                    </div>
                    <div class="col-3 text-center"
                        style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                        <h6><b>Code</b></h6>


                    </div>
                    <div class="col-3 text-center"
                        style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                        <h6><b> Value</b></h6>
                    </div>


                </div>
                <br>

                <form name="form" (ngSubmit)="f2.form.valid && SubmitListHierforCreate()" #f2="ngForm">

                    <div class="row">
                        <div class="col-3 text-right">
                            Level 1:
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl1_cd']"
                                name="lvl1_code" #lvl1_code="ngModel" required disabled>

                            <!-- validation start  -->
                            <!--
                          <div [class.border_green]="lvl1_code.dirty && lvl1_code.valid">
                        </div> -->

                            <!-- error handling  -->
                            <!-- <div *ngIf="f.submitted && lvl1_code.invalid" class="error-message">
                            <div *ngIf="lvl1_code.errors?.['required']">*Root is required
                            </div>
                        </div> -->
                            <!-- validation end  -->
                        </div>

                        <div class="col-3">
                            <ng-select [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                (change)="onChangeLvl1()" [multiple]="false" placeholder="Select Level 1 "
                                [(ngModel)]="HierSelectObj['lvl1_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl1_value"
                                #lvl1_value="ngModel" required>
                            </ng-select>

                            <!-- validation start  -->

                            <div [class.border_green]="lvl1_value.dirty && lvl1_value.valid">
                            </div>

                            <!-- error handling  -->
                            <div *ngIf="f2.submitted && lvl1_value.invalid" class="error-message">
                                <div *ngIf="lvl1_value.errors?.['required']">*Root is required
                                </div>
                            </div>
                            <!-- validation end  -->
                        </div>

                    </div>
                    <br>

                    <div class="row">
                        <div class="col-3 text-right">
                            Level 2 :
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl2_cd']" name="lvl2"
                                disabled>

                        </div>

                        <div class="col-3">
                            <ng-select [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                (change)="onChangeLvl2()" [multiple]="false" placeholder="Select Level 2"
                                [(ngModel)]="HierSelectObj['lvl2_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl2_value">
                            </ng-select>
                        </div>


                    </div>
                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Level 3 :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl3_cd']"
                                name="lvl3_cd" disabled>
                        </div>

                        <div class="col-3">
                            <ng-select [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                (change)="onChangeLvl3()" [multiple]="false" placeholder="Select Level 3"
                                [(ngModel)]="HierSelectObj['lvl3_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl3_value">
                            </ng-select>
                        </div>


                    </div>
                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Level 4 :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl4_cd']"
                                name="lvl4_cd" disabled>
                        </div>
                        <div class="col-3">
                            <ng-select [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                (change)="onChangeLvl4()" [multiple]="false" placeholder="Select Level 4"
                                [(ngModel)]="HierSelectObj['lvl4_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl4_value">
                            </ng-select>
                        </div>
                    </div>
                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Level 5 :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl5_cd']"
                                name="lvl5_cd" disabled>
                        </div>

                        <div class="col-3">
                            <ng-select [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                (change)="onChangeLvl5()" [multiple]="false" placeholder="Select Level 5"
                                [(ngModel)]="HierSelectObj['lvl5_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl5_value">
                            </ng-select>
                        </div>

                    </div>
                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Level 6 :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl6_cd']"
                                name="lvl6_cd" disabled>
                        </div>


                        <div class="col-3">
                            <ng-select [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                (change)="onChangeLvl6()" [multiple]="false" placeholder="Select Level 6"
                                [(ngModel)]="HierSelectObj['lvl6_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl6_value">
                            </ng-select>
                        </div>

                    </div>
                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Level 7 :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['lvl7_cd']"
                                name="lvl7_cd" disabled>
                        </div>

                        <div class="col-3">

                            <ng-select [items]="level7" (change)="onChangeLvl7()" bindLabel="lvl7_value"
                                bindValue="lvl7_cd" [multiple]="false" placeholder="Select Level 7"
                                [(ngModel)]="HierSelectObj['lvl7_cd']" [selectableGroup]="true"
                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl7_value">
                            </ng-select>
                        </div>

                    </div>

                    <br>

                    <div class="row">

                        <div class="col-3 text-right">
                            <h6> Leaf :</h6>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['leaf_cd']"
                                name="leaf_cd" disabled>
                        </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="HierSelectObj['leaf_value']"
                                name="leaf_value" disabled>
                        </div>
                    </div>
                    <br>
                    <div *ngIf="leaf_msg" class=" row error-message">
                        <div class="col-11 text-center">
                            Please select till leaf.
                        </div>
                        <br>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <!-- <button class="btn btn-primary" (click)="SubmitListHierforCreate()">Submit</button> -->
                            <button class="btn btn-primary" type="submit">Submit</button>
                            <button class="btn btn-success d-none" type="reset" id="resetbtn">Reset</button>
                        </div>
                    </div>


                </form>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
