<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Budget -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET1']}} </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li  class="nav-item">
                            <a *ngIf="list_bud_flag" class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                <!-- Budget List -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="crete_bud_flag" class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i> <!-- Create Budget -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET3']}}</a>
                        </li>

                        <li  class="nav-item">
                            <a *ngIf="update_bud_flag" class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i> <!-- Update
                                Budget -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET4']}}</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_bud_flag" >

                            <br>
                            <div style="text-align: right;">
                                <button class="btn btn-outline-primary" (click)="gotocrete()">Create Budget</button>
                            </div>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- ID -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET5']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.id }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="bud_cd">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> <!-- Budget Code -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.bud_cd }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="bud_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Budget Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET7']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.bud_desc }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bud_amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Budget Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.bud_amt }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="expense_amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Expense Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET9']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.expense_amount }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="create_bud_amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Update Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET10']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.create_bud_amt }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET11']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary" (click)="getAllexportBudget(element)"><!-- Download Certificate -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET12']}}</button>
                                            <button class="btn btn-primary" (click)="open_update(element)"><!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET13']}}</button>
                                            <!-- <button class="btn btn-danger" (click)="delete(element)">Delete</button> -->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div *ngIf="crete_bud_flag" >

                            <br>
                            <div style="text-align: right;">
                                <button class="btn btn-outline-primary" (click)="gotolist()">Budget List</button>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Budget Code -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET6']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <!-- <input type="text" class="form-control" [(ngModel)]="bud['bud_cd']" placeholder="Enter Budget Code"> -->
                                    <input class="form-control" [(ngModel)]="bud['bud_cd']"  type="text" placeholder="Enter Budget Code" />
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Budget Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET7']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" [(ngModel)]="bud['bud_desc']" placeholder="Enter Budget Name">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Budget Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET8']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="bud['bud_amt']" placeholder="Enter Budget Amount">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Expense Amount --> {{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET9']}}: <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="bud['expense_amount']" placeholder="Enter Expense Amount">
                                </div>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="create()"><!-- Submit -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET14']}}</button>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="update_bud_flag">

                            <br>
                            <div style="text-align: right;">
                                <button class="btn btn-outline-primary" (click)="gotolist()">Budget List</button>
                            </div>
                            <br>


                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Budget Code --> {{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET6']}}: <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <!-- <input type="text" class="form-control" [(ngModel)]="bud['bud_cd']" placeholder="Enter Budget Code" disabled> -->
                                    <input class="form-control" [(ngModel)]="bud['bud_cd']"  type="text" placeholder="Enter Budget Code" disabled />

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Budget Description  -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET7']}}: <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" [(ngModel)]="bud['bud_desc']" placeholder="Enter Budget Name">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Budget Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET8']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="bud['bud_amt']" placeholder="Enter Budget Amount">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"><!-- Expense Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET9']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="bud['expense_amount']" placeholder="Enter Expense Amount">
                                </div>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()"><!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-BUDGET13']}}</button>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>