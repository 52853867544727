import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MainService } from "../../service/main.service";
import { MasterDataService } from "../../service/master-data.service";
import { DomSanitizer } from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";
import { VmsHierService } from "../../service/vms-hier.service";
import { CommonDataService } from "../../service/common-data.service";
import { FileUploader } from "ng2-file-upload";
import { MainService as mainServiceMd } from "../../../md/service/main.service";
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
declare let $: any;
@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css']
})
export class WorkComponent implements OnInit {

  constructor(
    private mainServiceMd: mainServiceMd,
    private vmsHierService: VmsHierService,
    public commonDataService: CommonDataService,
    private masterDataService: MasterDataService,
    public toastr: ToastrService,
    public mainService: MainService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService
  ) { }

  uploadDocObj:any  = {};
  uploadDocArr:any  = [];
  fileURL:any ;
  select:any ;
  ebillUser:any ;
  b_acct_id:any ;
  Obj :any = {};
  data:any  = [];
  user:any  = [];
  designation :any = [];
  AllProjectList:any  = [];

  l_work_flag = true;
  c_work_flag = false;
  u_work_flag = false;
  d_work_flag = false;

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('file', { static: false }) fileInput!: ElementRef;
  displayedColumns = [
    "id",
    "work_id",
    "work_name",
    "work_desc",
    "tender_id",
    "tender_name",
    "tender_ref_no",
    "tender_nic_id",
    "node_cd",
    "action",
  ];
  datasource:any ;

  project :any = [];
  projectObj :any = {};
  httpUrl:any ;
  // embMode=[{mode:"GENERAL" ,value:"GEN"},{mode:"EPC",value:"EPC"}];

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.ebillUser["b_acct_id"];

    this.httpUrl = this.mainService.httpUrl;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: "file" });
    this.uploader.onAfterAddingFile = (file:any ) => {
      file.withCredentials = false;
    };
    this.uploader_arg = new FileUploader({
      url: this.httpUrl,
      itemAlias: "file",
    });
    this.uploader_arg.onAfterAddingFile = (file:any ) => {
      file.withCredentials = false;
    };
    //from account module data
    await this.getAccountBudgetHier();
    await this.getAccountProductHier();
    await this.getAccountProjectHier();
    await this.getactivity()
    //for party detail
    await this.getrepresentative();
    await this.getparty();

    //for user and data authorization.....

    await this.getUsersRoles();
    await this.getuserAssigneddata();
    await this.getcurrentroleswithresources();

    await this.commonDataService.getWorkOrderTOWorkIdInfo(this.b_acct_id)
    await this.commonDataService.getAssignedWorkInfo(this.b_acct_id, this.ebillUser["user_id"]);
    await this.getAllWorkInfo();

    this.Obj["node_type"] = "Work";
    this.Obj["work_mode"] = 'GEN';

    // if(this.mainService.codeValueTechObj["VMS001"]){
    //   this.mainService.codeValueTechObj["VMS001"].push({ code: "Work", value: "WORK" });
    // }
    this.codeValueTechType = "WORK"
  }

  selectProjectPopUp() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1);
    }
    $("#ACTHIER").modal("show");
  }

  setHierarchyNodeitem(item:any , i:any ) {
    this.check = item["hierarchy_type"];
    item["is_leaf"] = this.projhierobjisleaf[item["hierarchy_type"]];
    let dummy2:any  = [];
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]]);
    }
    this.Obj["path_desc"] = dummy2.join(" --> ");
  }

  planing_arrproj:any  = [];
  projecthier:any  = [];
  projhierobj :any = {};
  projhierobjtype:any  = {};
  projhierobjtypecd :any = {};
  projhierobjisleaf :any = {};
  async getAccountProjectHier() {
    let obj:any  = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "VMS";
    let resp = await this.vmsHierService.getprojectHierarchy(obj);
    if (resp["error"] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["lvl1_cd"] == 1) {
          resp.data[i]["lvl1_value"] = "VMS-root";
        }
      }
      this.projecthier = resp["data"];
      for (let i = 0; i < resp["data"].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["lvl" + j + "_value"];
          this.projhierobjtype[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["lvl" + j + "_node_type"];
          this.projhierobjtypecd[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["lvl" + j + "_user_cd"];
          this.projhierobjisleaf[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["is_leaf"];
        }
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While Getting Cost Center", "error");
    }
  }

  check:any ;

  activityhier:any  = []
  activityhierobjtype :any = {}
  activityhierobjtypecd:any  = {}
  activityhierobjisleaf:any  = {}
  activityobj:any = {}
  planing_arract:any  = []
  async getactivity() {
    this.spinner.show()
    let obj:any  = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'VMS'
    obj['table_name'] = 'activity_hier';
    let resp = await this.vmsHierService.getactHierarchy(obj)
    if (resp['error'] == false) {
      // this.allcostcenters = []
      this.activityhier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.activityobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.activityhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.activityhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_cd']
          this.activityhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']

        }
        /*  if(resp['data'][i]['cc_func']=="EMB")
         {
           let obb = Object.assign({},resp['data'][i])
           obb['node_cd'] = obb['cc_code']
           obb['desc'] = obb['cc_code'] +'-'+obb['cc_name']
           obb['node_desc'] = obb['desc']
           this.allcostcenters.push(obb)

         } */

      }
      // this.allcostcenters = [...resp['data']]
      //console.log(this.allcostcenters)
      // this.planing_arr[1] = {existing_heirarchy_nodes:[...this.allcostcenters]}


      // this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('Error...', 'Error While Getting Cost Center', 'error')
    }
  }
  activityPath:any ;

  submit_act() {
    if (
      this.planing_arrproj.length < 2 ||
      this.planing_arrproj[this.planing_arrproj.length - 1]["hierarchy_type"] ==
      null ||
      this.planing_arrproj[this.planing_arrproj.length - 1]["hierarchy_type"] ==
      null
    ) {
      Swal.fire("Warning", "Please Select at least two level", "warning");
    } else {
      $("#ACTHIER").modal("hide");
    }
  }

  down(item:any , k:any ) {
    let patharr = [];
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      patharr.push(this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]]);
    }
    this.Obj["path_desc"] = patharr.join("-->");
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [] });
    let arr:any  = [];
    for (let i = 0; i < this.projecthier.length; i++) {
      if (
        !arr.includes(this.projecthier[i]["lvl" + (k + 2) + "_cd"]) &&
        this.projecthier[i]["lvl" + (k + 2) + "_cd"] != null
      ) {
        if (
          (this.projecthier[i]["lvl" + (k + 1) + "_cd"] ==
            item["hierarchy_type"] ||
            k == -1) &&
          this.projecthier[i]["leaf_node_type"] != "WORK"
        ) {
          this.planing_arrproj[k + 1]["existing_heirarchy_nodes"].push({
            node_cd: this.projecthier[i]["lvl" + (k + 2) + "_cd"],
            desc:
              this.projecthier[i]["lvl" + (k + 2) + "_user_cd"] +
              " - " +
              this.projecthier[i]["lvl" + (k + 2) + "_value"] +
              " - " +
              this.projecthier[i]["lvl" + (k + 2) + "_node_type"],
            is_leaf: this.projecthier[i]["is_leaf"],
          });
          arr.push(this.projecthier[i]["lvl" + (k + 2) + "_cd"]);
        }
      }
    }
  }

  remove(item:any , i:any ) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop();
      let patharr = [];
      for (let i = 0; i < this.planing_arrproj.length; i++) {

        patharr.push(
          this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]]
        );
      }
      this.Obj["path_desc"] = patharr.join("-->");
    }
  }

  async submithier(id:any ) {
    let level = 0;
    let obb:any  = new Object();
    obb["b_acct_id"] = this.b_acct_id;
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      obb["lvl" + this.planing_arrproj[i]["level"] + "_cd"] =
        this.planing_arrproj[i]["hierarchy_type"];
      if (this.planing_arrproj[i]["level"] == 1)
        obb["lvl" + this.planing_arrproj[i]["level"] + "_value"] =
          "All Projects";
      else
        obb["lvl" + this.planing_arrproj[i]["level"] + "_value"] =
          this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]];
      obb["lvl" + this.planing_arrproj[i]["level"] + "_node_type"] =
        this.projhierobjtype[this.planing_arrproj[i]["hierarchy_type"]];
      obb["lvl" + this.planing_arrproj[i]["level"] + "_user_cd"] =
        this.projhierobjtypecd[this.planing_arrproj[i]["hierarchy_type"]];
      this.planing_arrproj[i]["level"],
        obb["lvl" + this.planing_arrproj[i]["level"] + "_user_cd"]
      level = this.planing_arrproj[i]["level"];
    }

    obb["lvl" + (level + 1) + "_user_cd"] = "VMSWORK" + id;
    obb["lvl" + (level + 1) + "_node_type"] = "WORK";
    obb["lvl" + (level + 1) + "_value"] = this.Obj["work_order_name"];
    obb["lvl" + (level + 1) + "_type"] = "text";
    obb["leaf_user_cd"] = "VMSWORK" + id;
    obb["leaf_node_type"] = "WORK";
    obb["leaf_value"] = this.Obj["work_order_name"];
    obb["module_cd"] = "VMS";

    let resp = await this.vmsHierService.createProjectHierarchyOnAccount(obb);
    if (resp["error"] == false) {
      this.spinner.hide();
      let proObj :any = {};
      proObj["b_acct_id"] = this.b_acct_id;
      proObj["id"] = id;
      proObj["project_cd"] = resp["data"];
      let project_res = await this.masterDataService.updateProjectcode(proObj);
      if (project_res["error"] == false) {
        Swal.fire("Success...", "Successfuly Created", "success");
      } else {
        Swal.fire("Error...", "Error While Project Code Creation..", "error");
      }
      await this.getAccountProjectHier();
      await this.reset();
      // Swal.fire("Success...", "Successfuly Created", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While Create A New Node..", "error");
    }
  }

  budhier = [];
  async getAccountBudgetHier() {
    this.spinner.show()
    let obj:any  = Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "bud_hier";
    // this.spinner.show();
    let resp = await this.vmsHierService.getBudgetHierarchy(obj);
    if (resp["error"] == false) {
      // this.spinner.hide();
      let data = resp["data"];
      this.budhier = [];
      this.budhier = data;
    } else {
      this.spinner.hide();

      this.toastr.error(resp["data"]);

    }
  }

  prodhier = [];
  async getAccountProductHier() {
    let obj:any  = Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "prod_hier";
    this.spinner.show();
    let resp = await this.vmsHierService.getproductHierarchy(obj);
    if (resp["error"] == false) {
      // this.spinner.hide();
      let data = resp["data"];
      this.prodhier = [];
      this.prodhier = data;
    } else {
      this.spinner.hide();

      this.toastr.error(resp["data"]);

    }
  }

  assignedNode:any  = [];
  not_leaf_arr:any  = [];
  async getuserAssigneddata() {
    this.spinner.show()
    let obj:any  = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["user_id"] = this.ebillUser.user_id;
    let resp :any = await this.vmsHierService.getuserAssigneddata(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.assignedNode = [];
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["is_leaf"] == 0) {
          if (!resp["data"][i]["node_type"]) {
            resp["data"][i]["node_desc"] = resp["data"][i]["node_desc"];
          } else {
            // resp["data"][i]["node_desc"] = resp["data"][i]["node_desc"] + " - (" + this.mainService.codeValueShowObj["VMS001"][resp["data"][i]["node_type"]] +")";
            resp["data"][i]["node_desc"] = resp["data"][i]["node_desc"] + " - (" + this.codeValueTechType + ")";
          }
          this.assignedNode.push(resp.data[i]);
        } else {
          this.not_leaf_arr.push(resp.data[i]);
        }
      }
      // this.spinner.hide()
    }
    this.spinner.hide()
  }

  accounts_hier = [];

  workdocdata:any  = {};
  uploadDocremarkObj:any  = {};
  open_document(element:any ) {
    this.getuploaddocument(element["id"]);
    this.workdocdata = Object.assign({}, element);
    this.l_work_flag = false;
    this.c_work_flag = false;
    this.u_work_flag = false;
    this.d_work_flag = true;
    $('.nav-tabs a[href="#tab-7-4"]').tab("show");
  }

  uploadDoctypeObj:any  = { AGREEMENT: null, WORKORDER: null };
  async getuploaddocument(file_id:any ) {
    let ob :any = new Object();
    ob["b_acct_id"] = this.b_acct_id;
    ob["doc_type"] = ["AGREEMENT", "WORKORDER"];
    ob["related_to"] = "WORK";
    ob["work_id"] = file_id;
    let resp = await this.masterDataService.getDocInfo(JSON.stringify(ob));
    if (resp["error"] == false) {
      this.uploadDoctypeObj["AGREEMENT"] = null;
      this.uploadDoctypeObj["WORKORDER"] = null;
      this.uploadDocArr = resp["data"];
      this.uploadDocObj = new Object();
      for (let i = 0; i < this.uploadDocArr.length; i++) {
        this.uploadDocObj[this.uploadDocArr[i]["id"]] =
          this.uploadDocArr[i]["doc_name"];
        this.uploadDoctypeObj[this.uploadDocArr[i]["doc_type"]] =
          this.uploadDocArr[i]["id"];
        this.uploadDocremarkObj[this.uploadDocArr[i]["doc_type"]] =
          this.uploadDocArr[i]["remark"];
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error while getting Upload Documents!", "error");
    }
  }

  async gotolist() {
    this.l_work_flag = true;
    this.c_work_flag = false;
    this.u_work_flag = false;
    this.d_work_flag = false;

    this.budpath = "";
    this.prodpath = "";
    this.planing_arr1 = [];
    this.planing_arr = [];
    this.path_arr = [];
    this.path_arr1 = [];
    this.bud_head_cd = "";
    this.bud_amt = "";
    this.is_leaf = "";
    this.is_leaf_prod = "";
    this.prod_cd = "";
    this.bud_name = "";

    // await this.getrepresentative();
    // await this.getUsersRoles();
    // await this.getuserAssigneddata();
    // await this.getparty();
    await this.getAllWorkInfo();
    // await this.getcurrentroleswithresources();
  }

  async gotocrete() {
    this.budpath = "";
    this.prodpath = "";
    this.planing_arr1 = [];
    this.planing_arrproj = [];
    this.planing_arr = [];
    this.path_arr = [];
    this.path_arr1 = [];
    this.bud_head_cd = "";
    this.bud_amt = "";
    this.is_leaf = "";
    this.is_leaf_prod = "";
    this.prod_cd = "";
    this.bud_name = "";
    this.planing_arrprod = [];

    this.refresh();
    this.l_work_flag = false;
    this.c_work_flag = true;
    this.u_work_flag = false;
    this.d_work_flag = false;
    this.Obj['gst_type1'] = "EXCLUDING";



  }

  updoc() {
    this.refresh();
    this.l_work_flag = false;
    this.c_work_flag = false;
    this.u_work_flag = false;
    this.d_work_flag = true;
  }

  async view(file_id:any ) {
    let obj:any  = new Object();
    obj["id"] = file_id;
    obj["b_acct_id"] = this.b_acct_id;
    obj["work_id"] = this.workdocdata["id"];
    obj["doc_name"] = this.uploadDocObj[file_id];
    this.spinner.show();
    if (obj["doc_name"] != undefined) {
      const res = await this.masterDataService.viewDoc(obj);
      if (res) {
        let docname = obj["doc_name"];
        let ext = docname.split(".");
        if (ext[1] == "png" || ext[1] == "jpeg" || ext[1] == "jpg" || ext[1] == "PNG" || ext[1] == "JPEG" || ext[1] == "JPG" || ext[1] == "gif" || ext[1] == "GIF") {
          let file = new Blob([res], { type: "image/png" });
          let fileURL = window.URL.createObjectURL(file);
          this.imgURL = this.sanitizer.bypassSecurityTrustUrl(fileURL);
          this.spinner.hide();
          window.open(fileURL, "_blank");
        } else if (ext[1].toLowerCase() == "xlsx") {
          let file = new Blob([res], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide();
        } else if (
          ext[1].toLowerCase() == "docx" ||
          ext[1].toLowerCase() == "doc"
        ) {
          let file = new Blob([res], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide();
        } else {
          let file = new Blob([res], { type: "application/pdf" });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide();
        }
      } else {
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error", "No document Uploaded !", "error");
    }
  }

  ///**********************************upload for work order document*********************************** */

  imageBlobUrl:any ;
  imgURL:any ;
  selectedFile: any
  isUpload:any ;
  public imagePath:any ;
  uploader:any ;
  onFileUpload(event:any , files:any ) {
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => { };
  }

  async upload() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("image", this.selectedFile, this.selectedFile.name);
    const obj:any  = new Object();

    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_name"] = this.uploader.queue[0].some.name;
    obj["doc_type"] = this.doc_type;
    obj["doc_local_no"] = this.workdocdata["id"];
    obj["create_user_id"] = this.ebillUser.user_id;
    obj["work_id"] = this.workdocdata["id"];
    obj["related_to"] = "WORK";
    obj["remark"] = this.doc_remark;
    this.spinner.show();
    const params = JSON.stringify(obj);
    this.uploader.queue[0].url =
      this.httpUrl + "/vms/upload/uploadDoc" + params;
    this.uploader.queue[0].upload();
    this.uploader.onCompleteItem = async (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let resp = JSON.parse(response);

      if (resp.error == false) {

        this.getuploaddocument(this.workdocdata["id"]);
        this.spinner.hide();
        $("#select").modal("hide");
        // Swal.fire('Success', 'File Uploaded Successfully!!','success');
      } else {
        this.spinner.hide();
        // Swal.fire('Error', resp.data,'error');
      }
    };
  }

  doc_remark:any ;
  upload_work_id:any ;
  async upload_work(work_id:any ) {
    if (this.selectedFile == null) {
      // console.log("Work Order File Not Selected For Upload....!!");
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    formData.append("image", this.selectedFile, this.selectedFile.name);
    const obj:any  = new Object();

    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_name"] = this.uploader.queue[0].some.name;
    obj["doc_type"] = "WORKORDER";
    obj["doc_local_no"] = work_id;
    obj["create_user_id"] = this.ebillUser.user_id;
    obj["work_id"] = work_id;
    obj["related_to"] = "WORK";
    obj["remark"] = this.doc_remark;
    this.spinner.show();
    const params = JSON.stringify(obj);
    this.uploader.queue[0].url =
      this.httpUrl + "/vms/upload/uploadDoc" + params;
    this.uploader.queue[0].upload();
    this.uploader.onCompleteItem = async (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let resp = JSON.parse(response);

      if (resp.error == false) {
        /*  if (this.doc_type == 'BOQ') {
          this.Obj['boq_document_id'] = resp.data;

        } else if (this.doc_type == 'TENDER') {
          this.Obj['dataArr'][this.index]['document_id'] = resp.data;

        }else if (this.doc_type == 'TENDER DOC') {
          this.Obj['tender_doc_dataarr'][this.index]['document_id'] = resp.data;
        }
        else if(this.doc_type == 'loa_document_id'){
          this.Obj['loa_document_id']=resp['data']
        }
        else if(this.doc_type == 'agreement_doc_id'){
          this.Obj['agreement_doc_id']=resp['data']
        }
        else if(this.doc_type == 'completion_cert_document_id'){
          this.Obj['completion_cert_document_id']=resp['data']
        }
        else if(this.doc_type == 'blacklist_doc_id'){
          this.Obj['blacklist_doc_id']=resp['data']
        } */

        this.getuploaddocument(work_id);
        this.spinner.hide();
        // console.log("Work File Uploaded Successfully!!");
        this.selectedFile = null;
        // $('#select').modal('hide');
        // Swal.fire('Success', 'File Uploaded Successfully!!','success');
      } else {
        this.spinner.hide();
        // Swal.fire('Error', resp.data,'error');
      }
    };
  }

  ///**********************************upload for work order document*********************************** */

  ///**********************************upload for aggrement document*********************************** */
  imageBlobUrl_arg:any ;
  imgURL_arg:any ;
  selectedFile_arg: any
  isUpload_arg:any ;
  public imagePath_arg:any ;
  uploader_arg:any ;
  onFileUpload_arg(event:any , files:any ) {
    this.selectedFile_arg = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath_arg = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => { };
  }

  async upload_arg(work_id:any ) {
    if (this.selectedFile_arg == null) {
      console.log("Aggrement File Not Selected For Upload....!!");
      return;
    }
    this.spinner.show();
    const formData = new FormData();

    formData.append("image", this.selectedFile_arg, this.selectedFile_arg.name);

    const obj:any  = new Object();
    obj["b_acct_id"] = this.b_acct_id;

    obj["doc_name"] = this.uploader_arg.queue[0].some.name;
    obj["doc_type"] = "AGREEMENT";
    obj["doc_local_no"] = work_id;
    obj["create_user_id"] = this.ebillUser.user_id;
    obj["work_id"] = work_id;
    obj["related_to"] = "WORK";
    obj["remark"] = this.doc_remark;
    this.spinner.show();
    const params = JSON.stringify(obj);
    this.uploader_arg.queue[0].url =
      this.httpUrl + "/vms/upload/uploadDoc" + params;
    this.uploader_arg.queue[0].upload();
    this.uploader_arg.onCompleteItem = async (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let resp = JSON.parse(response);
      if (resp.error == false) {
        this.spinner.hide();
        this.selectedFile_arg = null;
        Swal.fire(
          "Success",
          "Aggrement File Uploaded Successfully!!",
          "success"
        );
      } else {
        this.spinner.hide();
        Swal.fire("Error", resp.data, "error");
      }
    };
  }
  ///**********************************upload for aggrement document*********************************** */

  doc_type:any ;
  openUpload(doc_type:any ) {
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: "file" });
    this.uploader.onAfterAddingFile = (file:any ) => {
      file.withCredentials = false;
    };
    this.doc_remark = "";
    $("#select").modal("show");
    this.doc_type = doc_type;
  }
  // *****************************************************CODE OF ACCOUNT INTEGRATION  budget code----------------------------------

  openupdateUpload(doc_type:any ) {
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: "file" });
    this.uploader.onAfterAddingFile = (file:any ) => {
      file.withCredentials = false;
    };
    this.doc_remark = this.uploadDocremarkObj[doc_type];
    $("#select1").modal("show");

    this.doc_type = doc_type;
  }
  async updateupload() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("image", this.selectedFile, this.selectedFile.name);

    const obj:any  = new Object();

    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_name"] = this.uploader.queue[0].some.name;
    obj["doc_type"] = this.doc_type;
    obj["doc_local_no"] = this.workdocdata["id"];
    obj["create_user_id"] = this.ebillUser.user_id;
    obj["work_id"] = this.workdocdata["id"];
    obj["related_to"] = "WORK";
    obj["id"] = this.uploadDoctypeObj[this.doc_type];
    obj["remark"] = this.doc_remark;
    this.spinner.show();
    const params = JSON.stringify(obj);
    this.uploader.queue[0].url =
      this.httpUrl + "/vms/upload/updateuploadDoc" + params;
    this.uploader.queue[0].upload();
    this.uploader.onCompleteItem = async (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let resp = JSON.parse(response);

      if (resp.error == false) {
        this.getuploaddocument(this.workdocdata["id"]);
        this.spinner.hide();
        $("#select1").modal("hide");
       await this.removeLoadedFile()
        Swal.fire("Success", "File Uploaded Successfully!!", "success");
      } else {
        this.spinner.hide();
        Swal.fire("Error", resp.data, "error");
      }
    };
  }


  async removeLoadedFile() {
    this.fileInput.nativeElement.value = "";
  }


  userRoles:any  = [];
  table_data:any  = [];
  role_name :any = [];
  allusers :any = [];
  async getUsersRoles() {
    this.spinner.show()
    let obj1 :any = new Object();
    obj1["b_acct_id"] = this.b_acct_id;
    obj1["module_cd"] = ["VMS", "AA"];
    let resp = await this.mainServiceMd.getAllUsersForAllModules(
      JSON.stringify(obj1)
    );
    if (resp["error"] == false) {
      let dt = resp.data;
      this.allusers = dt;
      let arr:any  = [];
      let arruser = [];
      for (let i = 0; i < dt.length; i++) {
        if (!arr.includes(dt[i]["user_id"])) {
          arr.push(dt[i]["user_id"]);
          dt[i]["desc"] = dt[i]["user_id"] + " - " + dt[i]["emp_name"];
          arruser.push(dt[i]);
        }
      }
      this.userRoles = arruser;
      // this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire("Oops", "Error while getting all users roles", "error");
    }
  }
  userrolearr: any = [];

  changesantion_user() {
    this.spinner.show()
    let arr = [];
    this.Obj["sanction_by_role_cd"] = null;

    for (let i = 0; i < this.allusers.length; i++) {
      if (this.Obj["sanction_by"] == this.allusers[i]["user_id"]) {
        let roles = this.allusers[i]["role_name"].split(",");
        for (let oneRole in roles) arr.push(roles[oneRole]);
      }
    }
    this.userrolearr = arr;
    this.spinner.hide()
  }

  changesantion_user_onUpdate() {
    let arr = [];
    for (let i = 0; i < this.allusers.length; i++) {
      if (this.Obj["sanction_by"] == this.allusers[i]["user_id"]) {
        let roles = this.allusers[i]["role_name"].split(",");
        for (let oneRole in roles) arr.push(roles[oneRole]);
      }
    }
    this.userrolearr = arr;
  }

  allroles:any    = {};
  async getcurrentroleswithresources() {
    this.spinner.show();
    let obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    let resp = await this.mainService.getcurrentroleswithresources(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      // this.spinner.hide();
      let data = [];
      for (let i = 0; i < resp.data.length; i++) {
        this.allroles[resp.data[i]["role_cd"]] = resp.data[i];
        data.push(resp.data[i]);
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error", "..Error while getting  roles", "error");
    }
  }

  changeuser(obj1:any ) {
    for (let i = 0; i < this.userRoles.length; i++) {
      if (obj1["user"] == this.userRoles[i]["user_id"]) {
        obj1["designation"] = this.userRoles[i]["role_cd"];
      }
    }
  }

  CodeValue = [];
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  setPartyDetails() {
    for (let i = 0; i < this.all_party.length; i++) {
      if (this.Obj["party_id"] == this.all_party[i]["rep_le_id"]) {
        this.Obj["party_phone_no"] = this.all_party[i]["party_phone_no"];
        this.Obj["party_gstin_no"] = this.all_party[i]["party_gstin_no"];
        this.Obj["party_email"] = this.all_party[i]["party_email"];
        break;
      }
    }
  }

  reperesentative :any = {};
  async getrepresentative() {
    this.spinner.show()
    let resp = await this.masterDataService.getAllrepresentative(
      JSON.stringify({ b_acct_id: this.b_acct_id })
    );
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        this.reperesentative[resp["data"][i]["rep_le_id"]] =
          resp["data"][i]["party_leagal_name"];
      }
      // this.spinner.hide()
    }else{
      this.spinner.hide()
    }

  }
  all_party = [];
  async getparty() {
    this.spinner.show()

    let resp = await this.masterDataService.getAllparties(this.b_acct_id);
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        if (resp["data"][i]["party_type"] == "ORG") {
          resp["data"][i]["party_leagal_name"] =
            resp["data"][i]["party_leagal_name"] +
            " ( " +
            this.reperesentative[resp["data"][i]["rep_le_id"]] +
            ")";
        }
      }
      this.all_party = resp["data"];
      this.Obj["party_type"];
      // this.spinner.hide()

    }else{
      this.spinner.hide()
    }

  }

  async getAllWorkInfo() {
    // this.spinner.show()
    let obj:any  = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    let resp = await this.masterDataService.getWorkInfo(JSON.stringify(obj));
    if (resp["error"] == false) {
      let data = resp.data;
      for (let i = 0; i < data.length; i++) {
        data[i]["tempwork_order_dt"] = this.mainService.dateformatchange(
          data[i]["work_order_dt"]
        );
      }
      let tt = [];
      let arr = Object.values(this.commonDataService.assignedWork);
      for (let i = 0; i < data.length; i++) {
        if (arr.includes(data[i]["id"])) {
          tt.push(data[i]);
        }
      }
      this.datasource = new MatTableDataSource(tt);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
      this.spinner.hide();
    } else {
      this.spinner.hide();

      this.toastr.error(resp["data"]);

    }
  }

  async submit() {

    let data1 = "";
    this.Obj["product_cd"] = this.prod_cd;
    this.Obj["activity_cd"] = this.activity_cd;
    this.Obj["budget_name"] = this.bud_name;
    if (!this.Obj["product_cd"] || !this.Obj["budget_name"] || !this.Obj["path_desc"] || !this.Obj["product_cd"] ||
      !this.Obj["activity_cd"] || !this.Obj["budget_name"] || !this.Obj["path_desc"]) {
      Swal.fire("Error", "Select All Feild ", "error");
    } else {
      for (let i = 0; i < this.assignedNode.length; i++) {
        if (this.Obj["node_cd"] == this.assignedNode[i]["node_cd"]) {
          this.Obj["level"] = this.assignedNode[i]["level"] + 1;
          this.Obj["path"] = this.assignedNode[i]["path"] + "|";
          this.Obj["status"] = "ACTIVE";
        }
      }
      this.Obj["node_cd"] = this.Obj["path_desc"];
      this.Obj["status"] = "ACTIVE";
      this.Obj["b_acct_id"] = this.b_acct_id;
      this.Obj["create_user_id"] = this.ebillUser.user_id;
      this.Obj["data"] = data1;
      // this.Obj["product_cd"] = this.prod_cd;
      // this.Obj["budget_name"] = this.bud_name;

      let g1 = new Date(this.Obj["start_date"]);
      let g2 = new Date(this.Obj["end_date"]);
      if (g1.getTime() > g2.getTime()) {

        this.toastr.error(
          "Start Date Should be less Than or Equals To End Date "
        );

        return;
      }
      if (
        this.Obj["work_category"] == null ||
        this.Obj["work_category"] == undefined
      ) {

        this.toastr.error("Please Select Work Category");

        return;
      }
      this.spinner.show();

      let resp = await this.masterDataService.createWorkInfo(this.Obj);
      if (resp["error"] == false) {
        // this.upload_work(resp["work_id"]);
        // this.upload_arg(resp["work_id"]);

        await this.submithier(resp['work_id']);
        await this.commonDataService.getWorkOrderTOWorkIdInfo(this.b_acct_id);
        await this.commonDataService.getAssignedWorkInfo(this.b_acct_id, this.ebillUser["user_id"]);
        this.refresh();
        this.spinner.hide();
        await this.getAllWorkInfo();
        this.toastr.success(resp['data']);
        this.gotolist();
      } else {
        this.spinner.hide();

        this.toastr.error(resp["data"]);

      }
    }
  }
  async createaccounthier(id:any ) {
    let obj:any  = Object.assign({}, {});
    obj["b_acct_id"] = this.b_acct_id;
    for (let i = 0; i < this.accounts_hier.length; i++) {
      let obb = Object.keys(this.accounts_hier[i]);
      obj[obb[0]] = this.accounts_hier[i][obb[0]];
      obj[obb[1]] = this.accounts_hier[i][obb[1]];
    }
    let level = this.accounts_hier.length + 1;
    let code = "lvl" + level + "_cd";
    let value = "lvl" + level + "_value";
    obj[value] = this.Obj["work_order_name"];
    obj[code] = "VMSWORK" + id;
    obj["leaf_value"] = this.Obj["work_order_name"];
    obj["leaf_cd"] = "VMSWORK" + id;
    obj["table_name"] = "proj_hier";
    obj["module_cd"] = "VMS";
    let resp = await this.masterDataService.addModulesHierarchy(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();

      this.toastr.error(resp["data"]);

    }
  }

  addNew() {
    let obj:any  = { designation: "", user: 0 };
    this.data.push(obj);

    this.toastr.success("Check the last row.", "Create New Row", {
      positionClass: "bottom-left",
      closeButton: true,
    });

  }

  delete_user(element:any , i:any ) {
    if (element.is_mandatory != 1) {
      this.data.splice(i, 1);
    } else {

      this.toastr.success("This Field Can't Delete!");

    }
  }

  chekforbud = false;
  chekforprod = false;
  chekforactivity = false;

  codeValueTechType = ''


  async open_update(element:any ) {
    this.reset();
    this.spinner.show();
    this.Obj = Object.assign({}, element);
    this.Obj['vms_mode'] = this.Obj['vms_mode'];
    this.setPartyDetails();
    this.changesantion_user_onUpdate();
    this.codeValueTechType = 'WORK';
    // this.mainService.codeValueTechObj["VMS001"].push({
    //   code: "Work",
    //   value: "WORK",
    // });

    this.Obj["node_type"] = "Work";
    await this.getproductforupdate();
    await this.getbudgetforupdate();
    await this.getactivityforupdate();
    if (this.planing_arr1.length == 0) {
      this.chekforbud = true;
    }
    if (this.planing_arrprod.length == 0) {
      this.chekforprod = true;
    }
    if (this.planing_arract.length == 0) {
      this.chekforactivity = true;
    }
    this.is_leaf_prod = 1;
    this.is_leaf = 1;
    this.bud_head_cd = this.Obj["budget_cd"];
    this.bud_amt = this.Obj["budget_value"];

    this.planing_arr = [];

    this.l_work_flag = false;
    this.c_work_flag = false;
    this.u_work_flag = true;
    this.d_work_flag = false;

    let data:any  = {};
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]["leaf_user_cd"] == "VMSWORK" + element["id"]) {
        data = this.projecthier[i];
        break;
      }
    }
    this.planing_arrproj = [];
    for (let i = 1; i < 8; i++) {
      if (
        data["lvl" + i + "_user_cd"] != null &&
        data["leaf_user_cd"] != data["lvl" + i + "_user_cd"]
      ) {
        if (i == 1) {
          this.down({ hierarchy_type: data["lvl" + i + "_cd"] }, -1);
          this.planing_arrproj[this.planing_arrproj.length - 1][
            "hierarchy_type"
          ] = data["lvl" + i + "_cd"];
        } else {
          this.down({ hierarchy_type: data["lvl" + (i - 1) + "_cd"] }, i - 2);
          this.planing_arrproj[this.planing_arrproj.length - 1][
            "hierarchy_type"
          ] = data["lvl" + i + "_cd"];
          this.setHierarchyNodeitem(
            this.planing_arrproj[this.planing_arrproj.length - 1],
            i - 1
          );
        }
      }
      this.Obj["node_type"] = data["leaf_node_type"];
      this.Obj["node_cd"] = data["leaf_user_cd"];
      this.Obj["node_desc"] = data["leaf_value"];
    }
    this.activity_cd = this.Obj['activity_cd']
    this.prod_cd = this.Obj["product_cd"];
    this.bud_name = this.Obj["budget_name"];
    this.Obj['vms_mode'] = this.Obj['vms_mode']
    $('.nav-tabs a[href="#tab-7-3"]').tab("show");
    this.spinner.hide();
  }
  async updateprojhier() {
    let obb:any  = new Object();
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]["leaf_user_cd"] == "VMSWORK" + this.Obj["id"]) {
        obb["id"] = this.projecthier[i]["id"];
      }
    }
    let level = 0;
    obb["b_acct_id"] = this.b_acct_id;
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      obb["lvl" + this.planing_arrproj[i]["level"] + "_cd"] =
        this.planing_arrproj[i]["hierarchy_type"];
      if (this.planing_arrproj[i]["level"] == 1)
        obb["lvl" + this.planing_arrproj[i]["level"] + "_value"] =
          "All Projects";
      else
        obb["lvl" + this.planing_arrproj[i]["level"] + "_value"] =
          this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]];
      obb["lvl" + this.planing_arrproj[i]["level"] + "_node_type"] =
        this.projhierobjtype[this.planing_arrproj[i]["hierarchy_type"]];
      obb["lvl" + this.planing_arrproj[i]["level"] + "_user_cd"] =
        this.projhierobjtypecd[this.planing_arrproj[i]["hierarchy_type"]];

      level = this.planing_arrproj[i]["level"];
    }
    obb["lvl" + (level + 1) + "_user_cd"] = "VMSWORK" + this.Obj["id"];
    obb["lvl" + (level + 1) + "_cd"] = this.Obj["project_cd"];
    obb["leaf_cd"] = this.Obj["project_cd"];
    obb["lvl" + (level + 1) + "_node_type"] = "WORK";
    obb["lvl" + (level + 1) + "_value"] = this.Obj["work_order_name"];
    obb["lvl" + (level + 1) + "_type"] = "text";
    obb["leaf_user_cd"] = "VMSWORK" + this.Obj["id"];
    obb["leaf_node_type"] = "WORK";
    obb["leaf_value"] = this.Obj["work_order_name"];

    obb["leaf_type"] = "text";
    obb["module_cd"] = "VMS";
    this.spinner.show();
    let resp = await this.vmsHierService.projectupdateHierarchy(obb);
    if (resp["error"] == false) {
      // await this.update();
      Swal.fire('SUCCESS', 'Success', 'success');
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error...", resp["data"], "error");
    }
  }
  async update() {
    let data1 = "";
    let g1 = new Date(this.Obj["start_date"]);
    let g2 = new Date(this.Obj["end_date"]);
    if (g1.getTime() > g2.getTime()) {

      this.toastr.error(
        "Start Date Should be Less Than or Equals To End Date "
      );

      return;
    }
    if (
      this.Obj["work_category"] == null ||
      this.Obj["work_category"] == undefined
    ) {
      // oc
      this.toastr.error("Please Select Work Category");
      // ec
      return;
    }
    this.Obj["product_cd"] = this.prod_cd;
    this.Obj["activity_cd"] = this.activity_cd;
    this.Obj["budget_name"] = this.bud_name;
    if (
      this.Obj["product_cd"] == null ||
      this.Obj["budget_name"] == null ||
      this.Obj["path_desc"] == null ||
      this.Obj["product_cd"] == undefined ||
      this.Obj["activity_cd"] == undefined ||
      this.Obj["budget_name"] == undefined ||
      this.Obj["path_desc"] == undefined
    ) {
      Swal.fire("Error", "Please fill Required Filled ", "error");
      return;
    }
    for (let i = 0; i < this.assignedNode.length; i++) {
      if (this.Obj["node_cd"] == this.assignedNode[i]["node_cd"]) {
        this.Obj["level"] = this.assignedNode[i]["level"] + 1;
        this.Obj["path"] = this.assignedNode[i]["path"] + "|";
        this.Obj["status"] = "ACTIVE";
      }
    }
    this.Obj["node_cd"] = this.Obj["path_desc"];
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]["leaf_user_cd"] == "VMSWORK" + this.Obj["id"]) {
        this.Obj["project_cd"] = this.projecthier[i]["leaf_cd"];
        break;
      }
    }

    this.Obj["b_acct_id"] = this.b_acct_id;
    this.Obj["update_user_id"] = this.ebillUser.user_id;
    this.Obj["data"] = data1;
    this.Obj["budget_name"] = this.bud_name;


    let resp = await this.masterDataService.updateWorkInfo(this.Obj);
    if (resp["error"] == false) {
      await this.updateprojhier()
      await this.getAllWorkInfo();
      await this.commonDataService.getWorkOrderTOWorkIdInfo(this.b_acct_id);
      await this.commonDataService.getAssignedWorkInfo(
        this.b_acct_id,
        this.ebillUser["user_id"]
      );
      this.refresh();
      this.spinner.hide();
      // this.toastr.success("Updated ........");
      this.gotolist();
    } else {
      this.spinner.hide();
      // oc
      this.toastr.error(resp["data"]);
      // ec
    }
  }
  async updateaccounthier(id:any ) {
    let obj:any  = Object.assign({}, {});
    obj["b_acct_id"] = this.b_acct_id;
    for (let i = 0; i < this.accounts_hier.length; i++) {
      let obb = Object.keys(this.accounts_hier[i]);
      obj[obb[0]] = this.accounts_hier[i][obb[0]];
      obj[obb[1]] = this.accounts_hier[i][obb[1]];
    }
    let level = this.accounts_hier.length + 1;
    let code = "lvl" + level + "_cd";
    let value = "lvl" + level + "_value";
    obj[value] = this.Obj["work_order_name"];
    obj[code] = "VMSWORK" + id;
    obj["leaf_value"] = this.Obj["work_order_name"];
    obj["leaf_cd"] = "VMSWORK" + id;
    obj["table_name"] = "proj_hier";
    obj["module_cd"] = "VMS";
    let resp = await this.masterDataService.updateModulesHierarchy(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      // oc
      this.toastr.error(resp["data"]);
      // ec
    }
  }
  refresh() {
    this.Obj = {};
    this.Obj["node_type"] = "Work";
    this.Obj["vms_mode"] = 'GEN';
    this.data = [];
    this.activityPath = ""
    this.planing_arract = []
  }
  async delete(element:any ) {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element);
      }
    });
  }
  async finaldelete(element:any ) {
    let obj:any  = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["id"] = element["id"];
    let resp = await this.masterDataService.deleteWorkInfo(JSON.stringify(obj));
    if (resp["error"] == false) {
      await this.finaldeleteprojectInAccountProjHier(element);
      await this.getAllWorkInfo();
      this.refresh();
      this.spinner.hide();
      // oc
      this.toastr.success(resp["data"]);
      // ec
    } else {
      this.spinner.hide();
      // oc
      this.toastr.error(resp["data"]);
      // ec
    }
  }
  async finaldeleteprojectInAccountProjHier(element:any ) {
    let obj :any= {};
    let index = this.projecthier
      .map(function (e:any ) {
        return e.leaf_cd;
      })
      .indexOf(parseInt(element["project_cd"]));
    obj = Object.assign({}, this.projecthier[index]);
    obj["b_acct_id"] = this.b_acct_id;
    obj["update_user_id"] = this.ebillUser.user_id;
    obj["leaf_cd"] = element["project_cd"];

    this.spinner.show();
    let resp = await this.vmsHierService.deleteVMS_Hierarchy(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp["data"], "error");
    }
  }

  Obj1:any  = {};
  ActiveNode:any  = [];

  allRoles :any = [];
  allComp:any ;
  selectedComp:any  = [];
  dataSource:any ;
  role_name1:any ;
  role_id:any ;
  planing_arr:any  = [];
  planing_arr1:any  = [{}];
  access:any  = [];
  existing_heirarchy_nodes = [];

  budget_flag = false;

  selectbudgetbtn() {
    if (this.planing_arr.length == 0) {
      this.planing_arr = [];
      this.budpath = "";
      this.budget_flag = true;
      this.Obj["budget_cd"] = "";
      this.Obj["budget_value"] = "";
      let b_cd = "";
      let desc = "";
      this.path_arr = [];
      this.path_arr1 = [];
      let existing_heirarchy_nodes = [];
      let flag = 0;
      for (let i = 0; i < this.budhier.length; i++) {
        flag = 0;
        desc = this.budhier[i]["lvl1_cd"] + "-" + this.budhier[i]["lvl1_value"];
        b_cd = this.budhier[i]["lvl1_cd"];
        let obj:any  = { code: b_cd, desc: desc };

        for (let j = 0; j < existing_heirarchy_nodes.length; j++) {
          if (existing_heirarchy_nodes[j]["code"] == obj["code"]) {
            flag++;
          }
        }
        if (flag == 0) {
          existing_heirarchy_nodes.push(obj);
        }
      }

      this.planing_arr = [
        {
          level: 1,
          hier_desc: desc,
          is_leaf: 0,
          existing_heirarchy_nodes: existing_heirarchy_nodes,
        },
      ];
    }
    this.bud_level = 1;
    $("#select33").modal("show");
  }


  // selectbudgetbtn()

  ///acthier
  selectactivitybtn() {
    if (this.planing_arract.length == 0) {
      this.planing_arract = [];
      this.activityPath = "";
      let b_cd = "";
      let desc = "";
      this.path_arr = [];
      this.path_arr1 = [];
      let existing_heirarchy_nodes = [];
      let flag = 0;
      for (let i = 0; i < this.activityhier.length; i++) {
        flag = 0;
        desc = this.activityhier[i]["lvl1_cd"] + "-" + this.activityhier[i]["lvl1_value"];
        b_cd = this.activityhier[i]["lvl1_cd"];
        let obj:any  = { code: b_cd, desc: desc };

        for (let j = 0; j < existing_heirarchy_nodes.length; j++) {
          if (existing_heirarchy_nodes[j]["code"] == obj["code"]) {
            flag++;
          }
        }
        if (flag == 0) {
          existing_heirarchy_nodes.push(obj);
        }
      }

      this.planing_arract = [
        {
          level: 1,
          hier_desc: desc,
          is_leaf: 0,
          existing_heirarchy_nodes: existing_heirarchy_nodes,
        },
      ];
    }

    this.activity_level = 1;
    $('#ACTHIER_act').modal('show');
  }


  planing_arrprod :any = [];
  selectproductbtn() {
    if (this.planing_arrprod.length == 0) {
      this.planing_arrprod = [];
      this.prodpath = "";
      let b_cd = "";
      let desc = "";
      this.path_arr = [];
      this.path_arr1 = [];
      let existing_heirarchy_nodes = [];
      let flag = 0;
      for (let i = 0; i < this.prodhier.length; i++) {
        flag = 0;
        desc = this.prodhier[i]["lvl1_cd"] + "-" + this.prodhier[i]["lvl1_value"];
        b_cd = this.prodhier[i]["lvl1_cd"];
        let obj:any  = { code: b_cd, desc: desc };

        for (let j = 0; j < existing_heirarchy_nodes.length; j++) {
          if (existing_heirarchy_nodes[j]["code"] == obj["code"]) {
            flag++;
          }
        }
        if (flag == 0) {
          existing_heirarchy_nodes.push(obj);
        }
      }

      this.planing_arrprod = [
        {
          level: 1,
          hier_desc: desc,
          is_leaf: 0,
          existing_heirarchy_nodes: existing_heirarchy_nodes,
        },
      ];
    }
    this.prod_level = 1;
    $("#select44").modal("show");
  }


  activity_level = 0;




  submit_activity() {
    if (this.planing_arract[this.planing_arract.length - 1]['is_leaf'] == 0) {
      Swal.fire('Error', 'You Have Not Selected Leaf At The Last Level', 'error')

    } else {
      $('#ACTHIER_act').modal('hide');
    }
  }

  async selectbudgetbtnupdate() {
    this.planing_arr1 = [];
    this.budpath = "";
    this.budget_flag = true;
    await this.getbudgetforupdate();
    $("#select3").modal("show");
  }

  async selectactivitybtnupdate() {
    this.prodpath = "";
    await this.getactivityforupdate();
    $("#ACTHIER_act").modal("show");
  }




  async selectproductbtnupdate() {
    this.prodpath = "";
    await this.getproductforupdate();
    $("#select4").modal("show");
  }
  budpath:any ;
  prodpath:any ;
  bud_head_cd:any ;
  bud_amt:any ;
  is_leaf:any ;
  is_leaf_prod:any ;
  prod_cd:any ;
  bud_name:any ;
  setHierarchyNode(item:any , i:any ) {
    this.is_leaf = 0;

    for (let i = 0; i < item["existing_heirarchy_nodes"].length; i++) {

      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"]
      ) {
        this.bud_head_cd = item["existing_heirarchy_nodes"][i]["code"];
        this.bud_amt = item["existing_heirarchy_nodes"][i]["remaining_amount"];
        this.is_leaf = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.bud_name = item["existing_heirarchy_nodes"][i]["desc"];
      }
    }

    if (item["level"] < this.bud_level) {
      for (let j = item["level"]; j < this.bud_level; j++) {
        this.planing_arr.pop();
        this.path_arr1.pop();
      }

      this.bud_level = item["level"];

      this.path_arr1[item["level"] - 1] = "";
      this.budpath = this.path_arr1.join(" --> ");
    } else {
      this.path_arr1[item["level"] - 1] = this.bud_name;
      this.budpath = this.path_arr1.join(" --> ");
    }
  }

  setprodHierarchyNode(item:any , i:any ) {
    this.is_leaf_prod = 0;
    let prod_des = "";
    for (let i = 0; i < item["existing_heirarchy_nodes"].length; i++) {


      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"]
      ) {
        this.is_leaf_prod = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.prod_cd = item["existing_heirarchy_nodes"][i]["code"];
        prod_des = item["existing_heirarchy_nodes"][i]["desc"];
      }
    }

    let flag = false;
    let dummy = [];
    if (item["level"] < this.prod_level) {
      for (let j = item["level"]; j < this.prod_level; j++) {
        this.planing_arrprod.pop();
        this.path_arr.pop();
      }

      this.prod_level = item["level"];

      this.path_arr[item["level"] - 1] = "";
      this.prodpath = this.path_arr.join(" --> ");
    } else {
      this.path_arr[item["level"] - 1] = prod_des;
      this.prodpath = this.path_arr.join(" --> ");
    }
  }
  is_leaf_activity:any ;
  activity_cd:any ;
  setActivityHierarchyNode(item:any , i:any ) {
    this.is_leaf_activity = 0;
    let prod_des = "";
    for (let i = 0; i < item["existing_heirarchy_nodes"].length; i++) {

      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"]
      ) {
        this.is_leaf_activity = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.activity_cd = item["existing_heirarchy_nodes"][i]["code"];
        prod_des = item["existing_heirarchy_nodes"][i]["desc"];
      }
    }

    if (item["level"] < this.activity_level) {
      for (let j = item["level"]; j < this.activity_level; j++) {
        this.planing_arract.pop();
        this.path_arr.pop();
      }

      this.activity_level = item["level"];

      this.path_arr[item["level"] - 1] = "";
      this.activityPath = this.path_arr.join(" --> ");
    } else {
      this.path_arr[item["level"] - 1] = prod_des;
      this.activityPath = this.path_arr.join(" --> ");
    }

  }

  setprodHierarchyNodeforupdate(item:any , i:any ) {
    this.is_leaf_prod = 0;
    let make_desc = "";
    for (let i = 0; i < item["existing_heirarchy_nodes"].length; i++) {

      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"]
      ) {
        this.is_leaf_prod = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.prod_cd = item["existing_heirarchy_nodes"][i]["code"];
        make_desc = item["existing_heirarchy_nodes"][i]["desc"];
      }

      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"] &&
        item["level"] == this.prod_level
      ) {
        this.is_leaf_prod = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.prod_cd = item["existing_heirarchy_nodes"][i]["code"];
        make_desc = item["existing_heirarchy_nodes"][i]["desc"];
      }
    }

    if (item["level"] < this.prod_level) {
      for (let j = item["level"]; j < this.prod_level; j++) {
        this.planing_arrprod.pop();
        this.path_arr.pop();
      }

      this.prod_level = item["level"];
      this.path_arr[item["level"] - 1] = make_desc;
      this.prodpath = this.path_arr.join(" --> ");
    } else {
      this.path_arr[item["level"] - 1] = make_desc;
      this.prodpath = this.path_arr.join(" --> ");
    }
  }

  setbudHierarchyNodeforupdate(item:any , i:any ) {
    this.is_leaf_prod = 0;
    this.bud_name = "";
    for (let i = 0; i < item["existing_heirarchy_nodes"].length; i++) {
      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"]
      ) {
        this.is_leaf = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.bud_name = item["existing_heirarchy_nodes"][i]["desc"];
      }

      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"] &&
        item["existing_heirarchy_nodes"][i]["is_leaf"] == 1
      ) {
        this.bud_head_cd = item["existing_heirarchy_nodes"][i]["code"];
        this.bud_amt = item["existing_heirarchy_nodes"][i]["remaining_amount"];
        this.is_leaf = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.bud_name = item["existing_heirarchy_nodes"][i]["desc"];
      }
    }

    if (item["level"] < this.bud_level) {
      for (let j = item["level"]; j < this.bud_level; j++) {
        this.planing_arr1.pop();
        this.path_arr1.pop();
      }

      this.bud_level = item["level"];

      this.path_arr1[item["level"] - 1] = this.bud_name;
      this.budpath = this.path_arr1.join(" --> ");
    } else {
      this.path_arr1[item["level"] - 1] = this.bud_name;
      this.budpath = this.path_arr1.join(" --> ");
    }
  }

  add_dropdownforupdate(data:any , index:any) {
    let obj1 = {};
    if (this.planing_arr1.length >= 8) {
      return;
      Swal.fire("Warning..", "", "warning");
    }
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (
        data["hierarchy_type"] == this.existing_heirarchy_nodes[i]["node_cd"]
      ) {
        obj1 = Object.assign({}, this.existing_heirarchy_nodes[i]);
      }
    }
    this.selected_row = Object.assign({}, obj1);
    this.selected_row["index"] = index;
    this.bud_level++;
    this.filter_data_update();
  }
  remove_dropdownupdate(data:any, i:any) {
    if (this.planing_arr1.length > 1) {
      this.planing_arr1.pop();
    }

    let arr =
      this.planing_arr1[this.planing_arr1.length - 1][
      "existing_heirarchy_nodes"
      ];
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i]["code"] ==
        this.planing_arr1[this.planing_arr1.length - 1]["hierarchy_type"]
      )
        this.is_leaf = arr[i]["is_leaf"];
    }
    let dummy = [];

    if (i > 0) {
      this.path_arr1.pop();
      this.bud_level = i;
    }

    this.budpath = this.path_arr1.join(" --> ");
  }

  show_msg() {
    Swal.fire(
      "Warning..",
      "This Action Can Not Be Perform For Update",
      "warning"
    );
  }
  used_nodes :any = [];
  selected_row:any  = {};

  add_dropdown(data:any , index:any) {
    let flag = true;
    let obj1 = {};
    if (this.planing_arr.length >= 8) {
      return;
      Swal.fire("Warning..", "", "warning");
    }
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (
        data["hierarchy_type"] == this.existing_heirarchy_nodes[i]["node_cd"]
      ) {
        obj1 = Object.assign({}, this.existing_heirarchy_nodes[i]);
      }
    }
    this.selected_row = Object.assign({}, obj1);
    this.selected_row["index"] = index;

    this.filter_data();
  }

  async add_dropdown_prod(data:any , index:any) {
    let flag = true;
    let obj1 = {};
    if (this.planing_arrprod.length >= 7) {
      return;
      Swal.fire("Warning..", "", "warning");
    }
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (
        data["hierarchy_type"] == this.existing_heirarchy_nodes[i]["node_cd"]
      ) {
        obj1 = Object.assign({}, this.existing_heirarchy_nodes[i]);
      }
    }
    this.selected_row = Object.assign({}, obj1);
    this.selected_row["index"] = index;

    await this.filter_data_prod();
  }


  async add_dropdown_activity(data:any , index:any) {
    let flag = true;
    let obj1 = {};
    if (this.planing_arract.length >= 7) {
      return;
      Swal.fire("Warning..", "", "warning");
    }
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (
        data["hierarchy_type"] == this.existing_heirarchy_nodes[i]["node_cd"]
      ) {
        obj1 = Object.assign({}, this.existing_heirarchy_nodes[i]);
      }
    }
    this.selected_row = Object.assign({}, obj1);
    this.selected_row["index"] = index;

    await this.filter_data_activity();
  }



  existing_activity:any = []
  async filter_data_activity() {
    let flag = false;
    let lv = [];
    let exist:any  = {};
    let desc = "";
    let is_leaf = 0;
    this.existing_activity = await [...this.activityhier];

    let l = this.selected_row["index"] + 2;

    for (let i = 0; i < this.existing_activity.length; i++) {
      flag = false;

      if (this.existing_activity[i]["lvl" + l + "_cd"] != null && this.existing_activity[i]["lvl" + (l - 1) + "_cd"] == this.planing_arract[this.selected_row["index"]]["hierarchy_type"]) {
        desc = this.existing_activity[i]["lvl" + l + "_cd"] + "-" + this.existing_activity[i]["lvl" + l + "_value"];

        is_leaf = this.existing_activity[i]["is_leaf"];
        exist = { code: this.existing_activity[i]["lvl" + l + "_cd"], desc: desc, leaf_cd: this.existing_activity[i]["lvl" + l + "_cd"], is_leaf: is_leaf, };

        for (let j = 0; j < lv.length; j++) {
          if (exist["code"] == lv[j]["code"]) flag = true;
        }

        if (!flag) lv.push(exist);
      }
    }
    let x = { level: l, existing_heirarchy_nodes: lv };
    this.planing_arract.push(x);
    this.path_arr.push("");
    this.activity_level++;
  }




  existing_prod = [];

  async filter_data_prod() {
    let flag = false;
    let lv = [];
    let exist:any  = {};
    let desc = "";
    let is_leaf = 0;
    this.existing_prod = await [...this.prodhier];

    let l = this.selected_row["index"] + 2;

    for (let i = 0; i < this.existing_prod.length; i++) {
      flag = false;

      if (this.existing_prod[i]["lvl" + l + "_cd"] != null && this.existing_prod[i]["lvl" + (l - 1) + "_cd"] == this.planing_arrprod[this.selected_row["index"]]["hierarchy_type"]) {
        desc = this.existing_prod[i]["lvl" + l + "_cd"] + "-" + this.existing_prod[i]["lvl" + l + "_value"];

        is_leaf = this.existing_prod[i]["is_leaf"];
        exist = { code: this.existing_prod[i]["lvl" + l + "_cd"], desc: desc, leaf_cd: this.existing_prod[i]["lvl" + l + "_cd"], is_leaf: is_leaf, };

        for (let j = 0; j < lv.length; j++) {
          if (exist["code"] == lv[j]["code"]) flag = true;
        }

        if (!flag) lv.push(exist);
      }
    }
    let x = { level: l, existing_heirarchy_nodes: lv };
    this.planing_arrprod.push(x);
    this.path_arr.push("");
    this.prod_level++;
  }

  existing = [];
  async filter_data() {
    let flag = false;
    let lv = [];
    let exist:any  = {};
    let desc = "";
    let is_leaf = 0;
    this.existing = await [...this.budhier];

    let l = this.selected_row["index"] + 2;

    for (let i = 0; i < this.existing.length; i++) {
      if (
        this.existing[i]["lvl" + l + "_cd"] != null &&
        this.existing[i]["lvl" + (l - 1) + "_cd"] ==
        this.planing_arr[this.selected_row["index"]]["hierarchy_type"]
      ) {
        flag = false;
        desc =
          this.existing[i]["lvl" + l + "_cd"] +
          "-" +
          this.existing[i]["lvl" + l + "_value"];
        is_leaf = this.existing[i]["is_leaf"];

        exist = {
          code: this.existing[i]["lvl" + l + "_cd"],
          desc: desc,
          is_leaf: is_leaf,
          leaf_cd: this.existing[i]["leaf_cd"],
          remaining_amount: this.existing[i]["remaining_amount"],
        };
        for (let j = 0; j < lv.length; j++) {
          if (exist["code"] == lv[j]["code"]) flag = true;
        }

        if (!flag) lv.push(exist);
      }
    }
    let x = { level: l, existing_heirarchy_nodes: lv };
    this.planing_arr.push(x);
    this.bud_level++;
    this.path_arr1.push("");
  }
  async filter_data_update() {
    let flag = false;
    let lv = [];
    let exist:any  = {};
    let desc = "";
    let is_leaf = 0;
    this.existing = await [...this.budhier];

    let l = this.selected_row["index"] + 2;

    for (let i = 0; i < this.existing.length; i++) {
      if (
        this.existing[i]["lvl" + l + "_cd"] != null &&
        this.existing[i]["lvl" + (l - 1) + "_cd"] ==
        this.planing_arr1[this.selected_row["index"]]["hierarchy_type"]
      ) {
        flag = false;
        desc =
          this.existing[i]["lvl" + l + "_cd"] +
          "-" +
          this.existing[i]["lvl" + l + "_value"];
        is_leaf = this.existing[i]["is_leaf"];

        exist = {
          code: this.existing[i]["lvl" + l + "_cd"],
          desc: desc,
          is_leaf: is_leaf,
          leaf_cd: this.existing[i]["leaf_cd"],
          remaining_amount: this.existing[i]["remaining_amount"],
        };
        for (let j = 0; j < lv.length; j++) {
          if (exist["code"] == lv[j]["code"]) flag = true;
        }

        if (!flag) lv.push(exist);
      }
    }
    let x = { level: l, existing_heirarchy_nodes: lv, hierarchy_type: "l" };
    this.planing_arr1.push(x);
    this.path_arr1.push("");
  }
  path_arr :any = [];
  path_arr1:any  = [];
  prod_level:any ;
  bud_level:any ;
  async getproductforupdate() {
    if (this.planing_arrprod.length == 0) {
      this.planing_arrprod = [];
      let flag = false;
      let lv :any = [];
      let exist:any  = {};
      let desc:any  = "";
      let is_leaf :any = 0;
      let lvel :any = 0;
      let desired_hier:any   = {};
      this.existing_prod = await [...this.prodhier];
      for (let i = 0; i < this.existing_prod.length; i++) {
        for (let j = 1; j < 8; j++) {
          if (
            this.existing_prod[i]["lvl" + j + "_cd"] ==
            this.Obj["product_cd"] &&
            this.existing_prod[i]["lvl" + j + "_cd"] != null
          ) {
            lvel = j;
            desired_hier = this.existing_prod[i];
            break;
          }
        }
      }


      this.prod_level = lvel;
      for (let j = 1; j <= lvel; j++) {
        let l = j;
        lv = [];
        for (let i = 0; i < this.existing_prod.length; i++) {
          flag = false;
          if (this.existing_prod[i]["lvl" + l + "_cd"] != null) {
            desc =
              this.existing_prod[i]["lvl" + l + "_cd"] +
              "-" +
              this.existing_prod[i]["lvl" + l + "_value"];

            is_leaf = this.existing_prod[i]["is_leaf"];
            exist = {
              code: this.existing_prod[i]["lvl" + l + "_cd"],
              desc: desc,
              leaf_cd: this.existing_prod[i]["leaf_cd"],
              is_leaf: is_leaf,
            };

            for (let j = 0; j < lv.length; j++) {
              if (exist["code"] == lv[j]["code"]) flag = true;
            }

            if (!flag) lv.push(exist);
          }
        }
        let x = {
          level: l,
          hier_desc: desc,
          existing_heirarchy_nodes: lv,
          hierarchy_type: desired_hier["lvl" + l + "_cd"],
        };

        this.planing_arrprod.push(x);
      }

      for (let i = 0; i < 7; i++) {
        let x = i + 1;
        if (desired_hier["lvl" + x + "_cd"] != null)
          this.path_arr[i] =
            desired_hier["lvl" + x + "_cd"] +
            "-" +
            desired_hier["lvl" + x + "_value"];
      }
    }
    this.prodpath = this.path_arr.join(" --> ");
  }


  async getactivityforupdate() {
    if (this.planing_arract.length == 0) {
      this.planing_arract = [];
      let flag = false;
      let lv = [];
      let exist:any  = {};
      let desc = "";
      let is_leaf:any  = 0;
      let lvel :any = 0;
      let desired_hier :any = {};
      this.existing_activity = await [...this.activityhier];
      for (let i = 0; i < this.existing_activity.length; i++) {
        for (let j = 1; j < 8; j++) {
          if (
            this.existing_activity[i]["lvl" + j + "_cd"] ==
            this.Obj["activity_cd"] &&
            this.existing_activity[i]["lvl" + j + "_cd"] != null
          ) {
            lvel = j;
            desired_hier = this.existing_activity[i];
            break;
          }
        }
      }


      this.activity_level = lvel;
      for (let j = 1; j <= lvel; j++) {
        let l = j;
        lv = [];
        for (let i = 0; i < this.existing_activity.length; i++) {
          flag = false;
          if (this.existing_activity[i]["lvl" + l + "_cd"] != null) {
            desc =
              this.existing_activity[i]["lvl" + l + "_cd"] +
              "-" +
              this.existing_activity[i]["lvl" + l + "_value"];

            is_leaf = this.existing_activity[i]["is_leaf"];
            exist = {
              code: this.existing_activity[i]["lvl" + l + "_cd"],
              desc: desc,
              leaf_cd: this.existing_activity[i]["leaf_cd"],
              is_leaf: is_leaf,
            };

            for (let j = 0; j < lv.length; j++) {
              if (exist["code"] == lv[j]["code"]) flag = true;
            }

            if (!flag) lv.push(exist);
          }
        }
        let x = {
          level: l,
          hier_desc: desc,
          existing_heirarchy_nodes: lv,
          hierarchy_type: desired_hier["lvl" + l + "_cd"],
        };

        this.planing_arract.push(x);
      }

      this.path_arr = [];
      for (let i = 0; i < 7; i++) {
        let x = i + 1;
        if (desired_hier["lvl" + x + "_cd"] != null)
          this.path_arr[i] =
            desired_hier["lvl" + x + "_cd"] +
            "-" +
            desired_hier["lvl" + x + "_value"];
      }
    }
    this.activityPath = this.path_arr.join(" --> ");
  }








  existing_bud:any ;
  async getbudgetforupdate() {

    this.planing_arr1 = [];
    let flag = false;
    let lv = [];
    let exist:any  = {};
    let desc = "";
    let is_leaf = 0;
    let lvel = 0;
    let desired_hier:any  = {};
    this.existing_bud = await [...this.budhier];
    for (let i = 0; i < this.existing_bud.length; i++) {
      for (let j = 1; j < 8; j++) {
        if (
          this.existing_bud[i]["lvl" + j + "_cd"] == this.Obj["budget_cd"] &&
          this.existing_bud[i]["lvl" + j + "_cd"] != null
        ) {
          lvel = j;
          desired_hier = this.existing_bud[i];
          break;
        }
      }
    }

    this.bud_level = lvel;

    for (let j = 1; j <= lvel; j++) {
      let l = j;
      lv = [];
      for (let i = 0; i < this.existing_bud.length; i++) {
        flag = false;
        if (this.existing_bud[i]["lvl" + l + "_cd"] != null) {
          desc =
            this.existing_bud[i]["lvl" + l + "_cd"] +
            "-" +
            this.existing_bud[i]["lvl" + l + "_value"];

          is_leaf = this.existing_bud[i]["is_leaf"];

          exist = {
            code: this.existing_bud[i]["lvl" + l + "_cd"],
            desc: desc,
            is_leaf: is_leaf,
            leaf_cd: this.existing_bud[i]["leaf_cd"],
            remaining_amount: this.existing_bud[i]["remaining_amount"],
          };
          for (let j = 0; j < lv.length; j++) {
            if (exist["code"] == lv[j]["code"]) flag = true;
          }

          if (!flag) lv.push(exist);
        }
      }
      let x = {
        level: l,
        hier_desc: desc,
        existing_heirarchy_nodes: lv,
        hierarchy_type: desired_hier["lvl" + l + "_cd"],
      };
      this.planing_arr1.push(x);
    }

    this.path_arr1 = [];
    for (let i = 0; i < 7; i++) {
      let x = i + 1;
      if (desired_hier["lvl" + x + "_cd"] != null)
        this.path_arr1[i] =
          desired_hier["lvl" + x + "_cd"] +
          "-" +
          desired_hier["lvl" + x + "_value"];
    }

    this.budpath = this.path_arr1.join(" --> ");
  }

  remove_dropdown_prod(data:any , i:any ) {
    if (this.planing_arrprod.length > 1) {
      this.planing_arrprod.pop();
    }
    let arr =
      this.planing_arrprod[this.planing_arrprod.length - 1][
      "existing_heirarchy_nodes"
      ];
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i]["code"] ==
        this.planing_arrprod[this.planing_arrprod.length - 1]["hierarchy_type"]
      )
        this.is_leaf_prod = arr[i]["is_leaf"];
    }

    let dummy = [];
    for (let i = 0; i < this.planing_arrprod.length; i++) {
      dummy.push(this.planing_arrprod[i]["hier_desc"]);
    }
    if (i > 0) {
      this.prod_level = i;
      this.path_arr.pop();
    }

    this.prodpath = this.path_arr.join(" --> ");
  }


  remove_dropdown_activity(data:any , i:any ) {
    if (this.planing_arract.length > 1) {
      this.planing_arract.pop();
    }
    let arr =
      this.planing_arract[this.planing_arract.length - 1][
      "existing_heirarchy_nodes"
      ];
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i]["code"] ==
        this.planing_arract[this.planing_arract.length - 1]["hierarchy_type"]
      )
        this.is_leaf_activity = arr[i]["is_leaf"];
    }

    let dummy = [];
    for (let i = 0; i < this.planing_arract.length; i++) {
      dummy.push(this.planing_arract[i]["hier_desc"]);
    }
    if (i > 0) {
      this.activity_level = i;
      this.path_arr.pop();
    }

    this.activityPath = this.path_arr.join(" --> ");
  }

  remove_dropdown(data:any , i:any ) {
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop();
    }

    let arr =
      this.planing_arr[this.planing_arr.length - 1]["existing_heirarchy_nodes"];
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i]["code"] ==
        this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"]
      )
        this.is_leaf = arr[i]["is_leaf"];
    }
    let dummy = [];
    this.path_arr1.pop();
    this.bud_level = i;
    this.budpath = this.path_arr1.join(" --> ");
  }
  NodeCodeToNodeDesc :any = {};

  ParentNodeChange() {
    if (
      this.Obj1["parent_node_cd"] != null ||
      this.Obj1["parent_node_cd"] != undefined
    ) {
      let dd =
        this.NodeCodeToNodeDesc[this.Obj1["parent_node_cd"]]["path"].split("|");
      let str = "";
      for (let i = 0; i < dd.length; i++) {
        str = str + this.NodeCodeToNodeDesc[dd[i]]["node_desc"] + " -> ";
      }
      this.Obj1["path_desc"] = str;
    } else {
      this.Obj1["path_desc"] = null;
    }
  }

  data_to_update = {};

  sortArrayOfObjects = (arr:any , key:any ) => {
    return arr.sort((a:any , b:any ) => {
      return a[key] - b[key];
    });
  };

  async submitbud() {

    if (this.is_leaf == 0) {
      Swal.fire("Error", "You Have Not Selected Leaf Node", "error");
    } else {
      this.Obj["budget_cd"] = this.bud_head_cd;
      this.Obj["budget_value"] = this.bud_amt;


      $("#select3").modal("hide");
      $("#select33").modal("hide");
    }
  }

  async submitprod() {
    if (this.is_leaf_prod == 0) {
      Swal.fire("Error", "You Have Not Selected Leaf Node", "error");
    } else {
      $("#select4").modal("hide");
      $("#select44").modal("hide");
    }
  }

  reset() {
    this.Obj1 = {};
    this.planing_arr = [];
    this.planing_arr = [{ hierarchy_type: "VMS", level: 1 }];
    this.planing_arr1 = [];
    this.planing_arrprod = [];
  }

  // **************************************************************END OF THE CODE OF ACCOUNT INTEGRATION budget code*************************************************
}
