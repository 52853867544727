import { Component, OnInit, ViewChild } from '@angular/core';

import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { TourDetailService } from '../../service/tour-detail.service';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-tour-detail',
  templateUrl: './tour-detail.component.html',
  styleUrls: ['./tour-detail.component.css']
})
export class TourDetailComponent implements OnInit {

  constructor(private tourdetail: TourDetailService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser:any
  b_acct_id:any
  displayedColumns :any= ['s_no', 'le_id', 'emp_name', 'trip_name', 'start_trip_date', 'end_trip_date', 'no_of_member', 'applied_date', 'status', 'action'];
  displayedColumns1 = ['s_no', 'le_id', 'emp_name']
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  datasource1:any
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter1(filterValue: string) {
    this.datasource1.filter = filterValue.trim().toLowerCase();
  }
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol1', { static: false }) sortCol1!: MatSort;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  user_id:any
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    // console.log(this.erpUser)
    this.user_id = this.erpUser.user_id
    await this.gettourdetaildata()
  }
  datasource:any
  async gettourdetaildata() {
    this.spinner.show()
    var obj :any= new Object()
    obj["b_acct_id"] = this.b_acct_id

    var resp = await this.tourdetail.gettourdetail(JSON.stringify(obj))

    if (resp["error"] == false) {
      // console.log(resp.data)
      this.spinner.hide();
      let j = 1
      for (let i = 0; i < resp.data.length; i++) {
        resp.data[i]['new_index'] = j
        j = j + 1
      }
      // console.log(resp.data)
      this.datasource = new MatTableDataSource(resp.data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;

    }
    else {
      Swal.fire('Error', "Some Error Ocuured At server Side", 'error')
    }
  }
  aaproveObj :any= {}
  async approve(element:any) {

    this.aaproveObj = Object.assign({}, element)

    Swal.fire({
      title: 'Are you Sure?',
      text: 'Do You Want To Approve',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approved it!'
    }).then((result) => {
      if (result.value) {
        this.finalapprove()
      }
    })



  }
  async finalapprove() {

    var obj :any= new Object()
    obj["b_acct_id"] = this.b_acct_id
    obj["emp_travel_id"] = this.aaproveObj['emp_travel_id']
    obj["life_cycle_status"] = 'APPROVED'
    obj["user_id"] = this.user_id
    var resp = await this.tourdetail.approvestatus(obj)
    if (resp["error"] == false) {
      Swal.fire('Success', "Successfully Approved It", 'success')
      await this.gettourdetaildata()

    }
    else {
      Swal.fire('Error', "Some Error Occurred At Server Side", 'error')
    }

  }
  async cancel(element:any) {

    this.aaproveObj = Object.assign({}, element)

    Swal.fire({
      title: 'Are you Sure?',
      text: 'Do You Want To Reject',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reject it!'
    }).then((result) => {
      if (result.value) {
        this.finalcancel()
      }
    })



  }
  async finalcancel() {

    var obj :any= new Object()
    obj["b_acct_id"] = this.b_acct_id
    obj["emp_travel_id"] = this.aaproveObj['emp_travel_id']
    obj["life_cycle_status"] = 'REJECTED'
    obj["user_id"] = this.user_id
    var resp = await this.tourdetail.approvestatus(obj)
    if (resp["error"] == false) {
      Swal.fire('Success', "Successfully Rejected", 'success')
      await this.gettourdetaildata()

    }
    else {
      Swal.fire('Error', "Some Error Occurred At Server Side", 'error')
    }
  }
  userObj:any = {}
  async view(element:any) {
    this.userObj = Object.assign({}, element)
    $("#myModal").modal("show");
    var obj :any= new Object()
    obj["b_acct_id"] = this.b_acct_id
    obj["emp_travel_id"] = this.userObj['emp_travel_id']
    var resp = await this.tourdetail.gettravelmember(JSON.stringify(obj))
    //  console.log(resp)
    if (resp["error"] == false) {
      // console.log(resp.data)
      this.spinner.hide();

      this.datasource1 = new MatTableDataSource(resp.data)
      this.datasource1.paginator = this.paginator1;
      this.datasource1.sort = this.sortCol1;
    }
  }
}
