<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Mapping for Fixed assets and Depreciation</div>
                </div>
                <div class="ibox-body">

                    <div class="tab-pane fade show active" id="tab-1">
                        <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
                            <div class="row">
                                <br>
                                <div class="col-3"> </div>
                                <div class="col-3">
                                    <h4>Select Financial Year: </h4>
                                </div>
                                <div class="col-3 text-center">
                                    <ng-select [items]="allFinYear" bindLabel="fin_year_desc" bindValue="fin_year"
                                        [multiple]="false" placeholder="" [(ngModel)]="sel_fin_yr"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                       (change)="changeFinYear()" name="fin_year" #fin_desc="ngModel" required>
                                    </ng-select>

                                    <!---------------- validation start-----------------------------  -->
                                    <!-- border color green -->
                                    <div [class.selection-box]="fin_desc.dirty && fin_desc.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f.submitted && fin_desc.invalid" class="error-message">
                                        <div *ngIf="fin_desc.errors?.['required']">Please select financial year
                                        </div>
                                    </div>
                                    <!---------------- validation end-----------------------------  -->
                                </div>
                            </div>
                            <br>
                            <table class="table table-bordered table-sm table-hover">
                                <thead>
                                    <tr class="text-center">
                                        <th>Sr No </th>
                                        <th>Fixed Asset </th>
                                        <th>Depreciation </th>
                                        <th>Rate </th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr *ngFor="let coa of table_data  let i = index;">
                                        <td style="width:5%;"> {{i+1}}</td>
                                        <td style="width:40%;"> {{allCOAObj[coa['f_coa']]}} </td>
                                        <td style="width: 40%;">
                                            <ng-select [items]="d_coa" bindLabel="leaf_value" bindValue="leaf_code"
                                                [multiple]="false" [(ngModel)]="coa['d_coa']" name="d_coa"> </ng-select>
                                        </td>
                                        <td style="width:15% ;">
                                            <input type="number" class="form-control" [(ngModel)]="coa['d_rate']" name="d_rate"/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 text-right">

                                    <!-- <button class="btn btn-primary" (click)="submit()">Submit</button> -->
                                    <button class="btn btn-primary" type="submit">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>





                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
