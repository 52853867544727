<div class="page-content fade-in-up">

<div class="row">

    <div class="col-lg-12 col-md-12">

        <div class="ibox">
            <br>
            <div class="ibox-head">
                <div class="ibox-title">Employee Variable Annual Salary Statement<!-- Annual Salary Statement --> </div>
            </div>

            <div class="ibox-body">
                <div class="row">
                    <div class="col-4 text-right">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement2']}} :<span style="color: red;">*</span> <!-- Select Employee -->
                    </div>
                    <div class="col-4">
                        <ng-select [items]="allEmplyees_new" bindLabel="tempemp_name" bindValue="emp_id"
                            [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Report2']}}" [(ngModel)]="selectObj['emp_id']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" (change)="changeName()"
                            [closeOnSelect]="true" name="field_name"> 
                            <!-- Select Employee -->
                        </ng-select>

                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement3']}}<!-- Name Of Employee --> :
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" disabled [(ngModel)]="selectObj['emp_name']" name="" id="">

                    </div>

                </div>
                <br>
                <div class="row">
                    <!--  -->
                    <div class="col-lg-6 col-md-6 col-sm-12">
                       From  {{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement4']}}<!-- Select Year --> :<span style="color: red;">*</span>
                    <!-- </div> -->
                    <!-- <div class="col-4"> -->
                        <ng-select [items]="mainService.codeValueTechObj['HR0030']" bindLabel="value" bindValue="code"
                            [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Report4']}}" [(ngModel)]="selectObj['fin_year']"
                            [selectableGroup]="true" (change)="reset_flag()" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name"> <!-- Select Year -->
                        </ng-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                       To {{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement4']}}<!-- Select Year --> :<span style="color: red;">*</span>
                    <!-- </div> -->
                    <!-- <div class="col-4"> -->
                        <ng-select [items]="mainService.codeValueTechObj['HR0030']" bindLabel="value" bindValue="code"
                            [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Report4']}}" [(ngModel)]="selectObj['end_fin_year']"
                            [selectableGroup]="true" (change)="reset_flag()" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name"> <!-- Select Year -->
                        </ng-select>
                    </div>

                </div>
                <br>
                <div class="row">
                    <!-- month -->
                    <div class="col-lg-6 col-md-6 col-sm-12">
                       From  {{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report4']}}<!-- Select Month --> :<span style="color: red;">*</span>
                   
                        <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="selectObj['month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                        </ng-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                       To {{mainService.allLableShowObjHR[mainService.language_cd+'hrms-lic-report4']}} <!-- Select Month --> :<span style="color: red;">*</span>
                   
                        <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="selectObj['month_1']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                        </ng-select>
                    </div>

                </div>
                 
            <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-success" (click)="itreport()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement5']}}<!-- Submit --></button>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="this.selectObj['print_flag']">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" (click)="print1()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement6']}}<!-- Download ITR --></button>
                    </div>
                </div>
              
                <br>
            </div>
        </div>
    </div>
</div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement7']}}<!-- Loading -->...</ngx-spinner>