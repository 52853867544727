import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
// import { HierarchyService } from '../../service/hierarchy.service';
import { FileUploader } from 'ng2-file-upload';
import { MainService } from '../../service/main.service';
import swal from 'sweetalert2';
import { HierarchyService } from '../../../accounts/service/hierarchy.service';
import { OrgHierService } from '../../../accounts/service/org-hier.service';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';

import Swal from 'sweetalert2';
declare var $: any
@Component({
  selector: 'app-prop-heirarchy',
  templateUrl: './prop-heirarchy.component.html',
  styleUrls: ['./prop-heirarchy.component.css']
})
export class PropHeirarchyComponent implements OnInit {

  constructor(public mainService: MainService, private router: Router, private propHierService: PropHierarchyService,
    private spinner: NgxSpinnerService, private snackBar: MatSnackBar,
    private hierarchyService: HierarchyService,
    private orgHierService: OrgHierService
  ) { }
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  displayedColumns2 = ['id', 'leaf_cd', 'lvl1_cd', 'lvl1_value', 'lvl2_cd', 'lvl2_value', 'lvl3_cd', 'lvl3_value', 'lvl4_cd', 'lvl4_value', 'lvl5_cd', 'lvl5_value', 'lvl6_cd', 'lvl6_value', 'lvl7_cd', 'lvl7_value', 'action'];
  displayedColumns1 = ['id', 'hier_type', 'hier_ident_code', 'hier_buss_name', 'action'];
  datasource: any;
  erpUser: any
  buss_ent_type: any = [{ id: 'Location' }]

  obj: any = {};
  b_acct_id: any
  allHier: any = [];
  Obj : any= {}

  allBudgetHier: any = [];
  allProjectHier: any = [];
  allProductHier: any = [];
  level1: any = [];
  level2 : any= []
  level3: any = []
  level4: any = []
  level5 : any= []
  level6 : any= []
  level7 : any= []
  list1_flag: any = true
  list2_flag: any = false
  create1_flag : any= false
  create2_flag : any= false
  update1_flag : any= false
  update2_flag : any= false

  async ngOnInit() {
    setTimeout(() => {
      this.getAllHier()
    }, 20);


    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.httpUrl = this.mainService.httpUrl;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    this.Obj['buss_ent_type'] = 'Location'
    // this.getAllHier();
    this.list1_flag = true
    await this.getproject()
    // await this.nextIdToInsert_fun()
    console.log(this.mainService.codeValueTechObj)
  }
  projecthier: any = []
  projhierobj: any = {}
  projhierobjtype: any = {}
  projhierobjtypecd: any = {}
  project_hier: any = []
  projectLeafCode: any = {}
  projectKeyValue: any = {}
  unique: any = []
  orgHierarchy: any = []
  leafObj : any= new Object()

  async getProjectXREFHierarchy() {
    let   obj : any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'PROPERTY'
    obj['org_hier_code'] = 'FIN'
    this.orgHierarchy = []
    let resp = await this.orgHierService.getHierarchy(obj)  /******************************************************/
    resp['data'].map((x: any) => {
      if (x['is_cc'] != 1) {
        this.orgHierarchy.push(x)
      }
    })
    this.unique = []
    for (let i = 0; i < this.orgHierarchy.length; i++) {
      let hier_row = this.orgHierarchy[i]
      let keys = Object.keys(hier_row)
      for (let j = 0; j < keys.length; j++) {
        if (!this.unique.includes(hier_row[keys[j]])) {
          this.unique.push(hier_row[keys[j]])
        }
      }
    }
    console.log("line No. 888 :", this.unique);
  }

  async getproject() {
    let   obj : any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'PROPERTY'
    let resp = await this.propHierService.getprojectHierarchy(obj)
    for (let i = 0; i < resp.data.length; i++) {
      this.projectLeafCode[resp['data'][i]['leaf_user_cd']] = resp['data'][i]
      this.projectKeyValue[resp['data'][i]['leaf_cd']] = resp['data'][i]
      this.leafObj[resp['data'][i]['leaf_user_cd']] = resp['data'][i]['leaf_cd'];
      if (resp.data[i]['lvl1_value'] == "All Projects") {
        resp.data[i]['lvl1_value'] = 'PROPERTY-root'
      }
      for (let j = 1; j < 8; j++) {
        if (resp.data[i]['lvl' + j + "_cd"] && (j == 1 || j == 2)) {
          resp['data'][i]['lvl' + j + "_cd"] = resp['data'][i]['lvl' + j + "_cd"].toString()
        }
      }
    }
    this.project_hier = resp['data']
    // if (resp['error'] == false) {
    //   // this.allcostcenters = []
    //   this.projecthier = resp['data']
    //   for (let i = 0; i < resp['data'].length; i++) {
    //     for (let j = 1; j < 8; j++) {
    //       this.projhierobj[resp['data'][i]['lvl' + (j) + '_user_cd']] = resp['data'][i]['lvl' + (j) + '_value']
    //       this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_user_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
    //       this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_user_cd']] = resp['data'][i]['lvl' + (j) + '_cd']
    //     }
    //   }
    // } else {
    //   this.spinner.hide()
    //   Swal.fire('Error...', 'Error While Getting Cost Center', 'error')
    // }
  }
  nextIDToInsert = 0
  async nextIdToInsert_fun() {
    let   obj : any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'PROPERTY'
    this.spinner.show()
    let resp = await this.propHierService.nextIdToInsert(JSON.stringify(obj))
    if (resp['error']) {
      swal.fire('Error', 'Something is wrong', 'error')
      this.spinner.hide()
      return
    }
    this.spinner.hide()
    if (resp['data'].length > 0) {
      this.nextIDToInsert = resp['data'][0]['id']
    }

  }
  // async makingLeafValues2() {
  //   if (this.obj['lvl7_cd'] != undefined && this.obj['lvl7_cd'] != '' && this.obj['lvl7_cd'] != null) {
  //     this.obj['leaf_cd'] = this.obj['lvl7_cd']
  //     this.obj['leaf_value'] = this.obj['lvl7_value']

  //     this.obj['lvl7_node_type'] = this.Obj['lvl7_node_type']

  //     // this.set_leaf_node_type(7)
  //   } else if (this.obj['lvl6_cd'] != undefined && this.obj['lvl6_cd'] != '' && this.obj['lvl6_cd'] != null) {
  //     this.obj['leaf_cd'] = this.obj['lvl6_cd']
  //     this.obj['leaf_value'] = this.obj['lvl6_value']

  //     this.obj['lvl6_node_type'] = this.Obj['lvl6_node_type']

  //     // this.set_leaf_node_type(6)
  //   } else if (this.obj['lvl5_cd'] != undefined && this.obj['lvl5_cd'] != '' && this.obj['lvl5_cd'] != null) {
  //     this.obj['leaf_cd'] = this.obj['lvl5_cd']
  //     this.obj['leaf_value'] = this.obj['lvl5_value']

  //     this.obj['lvl5_node_type'] = this.Obj['lvl5_node_type']
  //     // this.set_leaf_node_type(5)
  //   } else if (this.obj['lvl4_cd'] != undefined && this.obj['lvl4_cd'] != '' && this.obj['lvl4_cd'] != null) {
  //     this.obj['leaf_cd'] = this.obj['lvl4_cd']
  //     this.obj['leaf_value'] = this.obj['lvl4_value']
  //     this.obj['lvl4_node_type'] = this.Obj['lvl4_node_type']
  //     // this.set_leaf_node_type(4)
  //   } else if (this.obj['lvl3_cd'] != undefined && this.obj['lvl3_cd'] != '' && this.obj['lvl3_cd'] != null) {
  //     this.obj['leaf_cd'] = this.obj['lvl3_cd']
  //     this.obj['leaf_value'] = this.obj['lvl3_value']

  //     this.obj['lvl3_node_type'] = this.Obj['lvl3_node_type']
  //     // this.set_leaf_node_type(3)
  //   } else if (this.obj['lvl2_cd'] != undefined && this.obj['lvl2_cd'] != '' && this.obj['lvl2_cd'] != null) {
  //     this.obj['leaf_cd'] = this.obj['lvl2_cd']
  //     this.obj['leaf_value'] = this.obj['lvl2_value']

  //     this.obj['lvl2_node_type'] = this.Obj['lvl2_node_type']
  //     // this.set_leaf_node_type(2)
  //   } else if (this.obj['lvl1_cd'] != undefined && this.obj['lvl1_cd'] != '' && this.obj['lvl1_cd'] != null) {
  //     this.obj['leaf_cd'] = this.obj['lvl1_cd']
  //     this.obj['leaf_value'] = this.obj['lvl1_value']

  //     this.obj['lvl1_node_type'] = this.Obj['lvl1_node_type']
  //     // this.set_leaf_node_type(1)
  //   }
  //   for (let i = 1; i < 8; i++) {
  //     if (this.leaf_obj['lvl' + i + '_node_type']) {
  //       this.obj['lvl' + i + '_node_type'] = this.leaf_obj['lvl' + i + '_node_type']
  //       this.obj['lvl' + i + '_user_cd'] = this.leaf_obj['lvl' + i + '_cd']
  //       this.obj['leaf_node_type'] = this.leaf_obj['lvl' + i + '_node_type']
  //     } else {
  //       break;
  //     }
  //   }
  // }
  async getAllBudget() {
    var   obj : any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'bud_hier';
    var resp = await this.hierarchyService.getHierarchy(obj);
    if (resp['error'] == false) {
      this.allBudgetHier = resp.data;
    } else {
    }
  }

  async getAllProject() {
    var   obj : any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'proj_hier';
    var resp = await this.hierarchyService.getHierarchy(obj);
    if (resp['error'] == false) {
      this.allProjectHier = resp.data;
    } else {
    }
  }

  async getAllProduct() {
    var   obj : any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'prod_hier';
    var resp = await this.hierarchyService.getHierarchy(obj);
    if (resp['error'] == false) {
      this.allProductHier = resp.data;
    } else {
    }
  }

  event_arr: any = [];

  makeEventArr(budArr: any, prodArr: any, projArr: any, avtObj: any) {
    this.event_arr = []
    for (let i = 0; i < budArr.length; i++) {

      for (let j = 0; j < prodArr.length; j++) {

        for (let k = 0; k < projArr.length; k++) {
          let   obj : any = new Object;
          obj["event_code"] = budArr[i]["leaf_cd"] + " - " + prodArr[j]["leaf_cd"] + " - " + projArr[k]["leaf_cd"] + " - " + avtObj["leaf_cd"]
          obj["event_desc"] = budArr[i]["leaf_value"] + " from " + avtObj["leaf_value"] + " of " + prodArr[j]["leaf_value"] + " for " + projArr[k]["leaf_value"]
          obj["event_record_code"] = "R101"
          this.event_arr.push(obj)
        }

      }

    }

  }

  first_list = []
  async getAllHier() {
    this.first_list = []
    var   obj : any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'ACTIVE';
    obj['buss_ent_type'] = this.Obj['buss_ent_type']
    this.spinner.show()
    var resp = await this.propHierService.getLocationHierarchy(JSON.stringify(obj));
    if (!resp['error']) {
      this.first_list = resp['data']
      this.allHier = resp['data']
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
      // await this.nextIdToInsert_fun()
    } else {
      Swal.fire('Error...', 'Some Error Occured', 'error')
      this.spinner.hide()
    }
  }


  async save() {
    // this.spinner.show();
    this.obj['create_user_id'] = this.erpUser.user_id;
    this.obj['b_acct_id'] = this.b_acct_id;
    this.obj['hier_type_id'] = this.hier_evt_no
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.obj['lvl' + i + '_type'] == 'text') {
        let   obj : any = {}
        obj['loc_type_cd'] = this.obj['lvl' + i + '_node_type']
        obj['loc_id_std'] = this.erpUser['account_short_name']
        obj['loc_id_str'] = this.obj['lvl' + i + '_cd']
        obj['life_cycle_status'] = 'ACTIVE'
        obj['loc_short_nm'] = this.obj['lvl' + i + '_cd'] + ' - ' + this.obj['lvl' + i + '_value']
        dummy.push(obj)
      }

      if (this.obj['lvl' + i + '_type'] != undefined) {
        if (this.obj['lvl' + i + '_type'] != '') {
          if (this.unique.includes(this.obj['lvl' + i + '_cd'])) {
            swal.fire('Error', "Duplicate value " + this.obj['lvl' + i + '_cd'], 'error');
            return;
          }
        }
      }
    }
    for (let i = 1; i <= 8; i++) {
      if (this.obj['lvl' + i + '_cd']) {
        this.obj['lvl' + i + "_user_cd"] = this.obj['lvl' + i + "_cd"]
      }
    }
    if (this.allHier.length < 1) {
      this.obj['lvl1_type'] = 'text'
      this.obj['lvl2_type'] = 'text'
    } else {
      this.obj['lvl1_type'] = 'Select'
      this.obj['lvl2_type'] = 'Select'
    }
    this.obj['data'] = dummy// loactions to be created
    var resp = await this.propHierService.createLocationHierarchyNodes(this.obj);
    if (resp['error'] == false) {
      await this.submithier_inAccount()
      this.spinner.hide();
      await this.open_list()
      // Swal.fire('Success..', resp.data, 'success')
    } else {
      this.spinner.hide();
      Swal.fire('Error...', 'Errro while Creating', 'error')
    }
  }
  async submithier_inAccount() {
    let obj2: any = {}
    obj2 = Object.assign({}, this.obj)
    this.obj['lvl1_type'] = 'Select'
    this.obj['lvl2_type'] = 'Select'
    for (let i = 1; i <= 7; i++) {
      if (this.obj['lvl' + i + '_cd']) {
        obj2['lvl' + i + '_cd'] = this.obj['lvl' + i + '_cd'].toString()
        obj2['leaf_value'] = obj2['lvl' + i + '_value']
        obj2['leaf_node_type'] = this.obj['lvl' + i + '_node_type']
        obj2['leaf_user_cd'] = obj2['lvl' + i + '_cd']
      }

      obj2['lvl1_user_cd'] = this.level1[0]['lvl1_user_cd']
      if (this.obj['lvl' + i + '_cd'] && this.obj['lvl' + i + '_type'] == 'text') {
        obj2['lvl' + i + '_cd'] = null
      }
      obj2['leaf_cd'] = null
      if (i > 2) {
        obj2['lvl' + i + '_cd'] = this.leafObj[obj2['lvl' + i + '_cd']];
      }
    }
    for (let i = 0; i < this.project_hier.length; i++) {
      if (obj2['lvl2_cd'] == this.project_hier[i]['leaf_cd']) {
        obj2['lvl1_user_cd'] = this.project_hier[i]['lvl1_user_cd']
        obj2['lvl2_user_cd'] = this.project_hier[i]['lvl2_user_cd']
      }
    }
    obj2['module_cd'] = 'PROPERTY'
    console.log(this.leafObj, " kjafksdhgkjdfngdkfj", obj2);

    var resp = await this.propHierService.getprojectcreateHierarchy(obj2);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getproject();
      Swal.fire("Success...", "Successfuly Created in account", 'success');
    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While Create A New Node..', 'error');
    }
  }

  async open_list() {
    this.list2_flag = false
    this.list1_flag = true
    this.create1_flag = false
    this.create2_flag = false
    this.update1_flag = false
    this.update2_flag = false
    this.Obj['buss_ent_type'] = 'Location'
    await this.getAllHier()
  }
  async submit() {
    await this.getAllHier()
    let stop_create_flag = false
    for (let i = 0; i < this.first_list.length; i++) {
      if (this.Obj['hier_ident_code'] == this.first_list[i]['hier_ident_code']) {
        stop_create_flag = true
      }
    }
    for (let i = 0; i < this.first_list.length; i++) {
      if (this.Obj['hier_type'] == this.first_list[i]['hier_type']) {
        Swal.fire('Info...', 'Can Not Created More Than One Hierarchy Of The Same Purpose', 'info')
        return
      }
    }
    if (!this.Obj['hier_ident_code'] || !this.Obj['hier_buss_name'] || !this.Obj['hier_type']) {
      Swal.fire('Information..', 'Please Enter All * fields', 'info')
      return
    } else if (stop_create_flag == true) {
      Swal.fire('Information..', 'This Hierarchy Business Code Is Already Exists', 'info')
      return
    }
    else {
      let   obj : any = {}
        obj  = Object.assign({}, this.Obj)
      obj['b_acct_id'] = this.b_acct_id
      obj['buss_ent_type'] = 'Location'
      obj['life_cycle_status'] = 'ACTIVE'
      obj['user_id'] = this.erpUser['user_id']
      let dummy = []
      for (let i = 1; i <= 8; i++) {
        if (this.obj['lvl' + i + '_type'] == 'text') {
          let   obj : any = {}
          obj['loc_type_cd'] = this.obj['lvl' + i + '_node_type']
          obj['loc_id_std'] = this.erpUser['account_short_name']
          obj['loc_id_str'] = this.obj['lvl' + i + '_cd']
          obj['life_cycle_status'] = 'ACTIVE'
          obj['loc_short_nm'] = this.obj['lvl' + i + '_cd'] + ' - ' + this.obj['lvl' + i + '_value']
          dummy.push(obj)
        }
      }
      let dummy2 = []
      for (let i = 1; i <= 8; i++) {
        if (this.obj['lvl' + i + '_type'] == 'text') {
          let   obj : any = {}
          obj['loc_type_cd'] = this.objToUpdate['lvl' + i + '_node_type']
          obj['loc_id_std'] = this.erpUser['account_short_name']
          obj['loc_id_str'] = this.objToUpdate['lvl' + i + '_cd']
          obj['life_cycle_status'] = 'ACTIVE'
          obj['loc_short_nm'] = this.objToUpdate['lvl' + i + '_cd'] + ' - ' + this.objToUpdate['lvl' + i + '_value']
          dummy2.push(obj)
        }
      }
      obj['old_data'] = dummy2
      obj['data'] = dummy
      this.spinner.show()
      console.log(407, obj);
      let resp = await this.propHierService.createLocationHierarchy(obj)
      if (resp['error'] == false) {
        this.spinner.hide();
        this.list2_flag = false
        this.list1_flag = true
        this.create1_flag = false
        this.create2_flag = false
        this.update1_flag = false
        this.update2_flag = false
        this.open_list()
        Swal.fire('Success...', 'Created Successfully', 'success')
      } else {
        this.spinner.hide();
        Swal.fire('Error...', 'Error While Creating', 'error')
      }


    }
  }

  async setObjectForProjectHierarchy_update() {
    let new_obj2: any = {}
    new_obj2 = this.projectLeafCode[this.objToUpdate['leaf_cd']]
    let obj2: any = {}
    obj2 = Object.assign({}, this.obj)
    this.obj['lvl1_type'] = 'Select'
    this.obj['lvl2_type'] = 'Select'
    for (let i = 1; i <= 7; i++) {
      if (this.obj['lvl' + i + '_cd']) {
        obj2['lvl' + i + '_cd'] = this.obj['lvl' + i + '_cd'].toString()
        obj2['leaf_value'] = obj2['lvl' + i + '_value']
        obj2['leaf_node_type'] = this.obj['lvl' + i + '_node_type']
        obj2['leaf_user_cd'] = obj2['lvl' + i + '_cd']

        obj2['lvl' + i + '_user_cd'] = this.obj['lvl' + i + '_user_cd']
      }
      obj2['lvl1_user_cd'] = this.level1[0]['lvl1_user_cd']
      // if (this.obj['lvl' + i + '_cd'] && this.obj['lvl' + i + '_type'] == 'text') {
      //   obj2['lvl' + i + '_cd'] = null
      // }
      // obj2['leaf_cd'] = null
      obj2['lvl' + i + '_cd'] = new_obj2['lvl' + i + '_cd']
    }
    for (let i = 1; i <= 7; i++) {
      if (this.obj['lvl' + i + '_cd']) {
        obj2['lvl' + i + '_user_cd'] = this.obj['lvl' + i + '_user_cd']
      }

    }
    obj2['leaf_cd'] = new_obj2['leaf_cd']
    for (let i = 0; i < this.project_hier.length; i++) {
      if (obj2['lvl2_cd'] == this.project_hier[i]['leaf_cd']) {
        obj2['lvl1_user_cd'] = this.project_hier[i]['lvl1_user_cd']
        obj2['lvl2_user_cd'] = this.project_hier[i]['lvl2_user_cd']
      }
    }
    obj2['b_acct_id'] = this.b_acct_id
    obj2['module_cd'] = 'PROPERTY'
    obj2['id'] = new_obj2['id']

    var resp = await this.propHierService.projectupdateHierarchy(obj2);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getproject();
      // Swal.fire("Success...", "Successfuly Created in account", 'success');
    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While Create A New Node..', 'error');
    }
  }
  async update_hier() {
    let   obj : any = {}
      obj  = Object.assign({}, this.obj)
    obj['b_acct_id'] = this.b_acct_id
    obj['buss_ent_type'] = 'Location'
    obj['life_cycle_status'] = 'ACTIVE'
    obj['user_id'] = this.erpUser['user_id']
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.obj['lvl' + i + '_type'] == 'text') {
        let   obj : any = {}
        obj['loc_type_cd'] = this.obj['lvl' + i + '_node_type']
        obj['loc_id_std'] = this.erpUser['account_short_name']
        obj['loc_id_str'] = this.obj['lvl' + i + '_cd']
        obj['life_cycle_status'] = 'ACTIVE'
        obj['loc_short_nm'] = this.obj['lvl' + i + '_cd'] + ' - ' + this.obj['lvl' + i + '_value']
        dummy.push(obj)
      }
    }
    let dummy2 = []
    for (let i = 1; i <= 8; i++) {
      if (this.obj['lvl' + i + '_type'] == 'text') {
        let   obj : any = {}
        obj['loc_type_cd'] = this.objToUpdate['lvl' + i + '_node_type']
        obj['loc_id_std'] = this.erpUser['account_short_name']
        obj['loc_id_str'] = this.objToUpdate['lvl' + i + '_cd']
        obj['life_cycle_status'] = 'ACTIVE'
        obj['loc_short_nm'] = this.objToUpdate['lvl' + i + '_cd'] + ' - ' + this.objToUpdate['lvl' + i + '_value']
        dummy2.push(obj)
      }
    }

    obj['old_data'] = dummy2
    obj['data'] = dummy
    // this.spinner.show()
    let resp = await this.propHierService.update_Location_hier_nodes(obj)
    if (resp['error'] == false) {
      await this.setObjectForProjectHierarchy_update()
      this.spinner.hide();
      await this.open_list()
      Swal.fire('Success...', 'Updated Successfully', 'success')
    } else {
      this.spinner.hide();
      Swal.fire('Error...', resp['data'], 'error')
    }
  }
  async update() {
    if (!this.Obj['hier_ident_code'] || this.Obj['hier_buss_name'] == '' || !this.Obj['hier_buss_name'] || !this.Obj['hier_type']) {
      Swal.fire('Information..', 'Please Enter All * fields', 'info')
      return
    } else {
      this.spinner.show();
      let   obj : any = {}
        obj  = Object.assign({}, this.Obj)
      obj['b_acct_id'] = this.b_acct_id
      obj['buss_ent_type'] = 'Location'
      obj['life_cycle_status'] = 'ACTIVE'
      obj['user_id'] = this.erpUser['user_id']
      // this.obj['table_name'] = 'activity_hier';
      var resp = await this.propHierService.update_Location_hier(obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        this.open_list()
        Swal.fire('Success...', 'Updated Successfully', 'success')
      } else {
        this.spinner.hide();
        Swal.fire('Error..', resp['data'], 'error')
      }
    }

  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


  open_update_hier() {
    this.obj = Object.assign({}, this.Obj)
    this.list2_flag = false
    this.list1_flag = false
    this.create1_flag = false
    this.create2_flag = false
    this.update1_flag = false
    this.update2_flag = true
  }
  delete_hier(element: any) {
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete1(element)
      }
    })
  }
  async finaldelete1(element: any) {
    var   obj : any = new Object();
      obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.propHierService.deleteLocationHierarchy(obj);
    if (resp['error'] == false) {
      await this.open_list()
      this.spinner.hide();
      Swal.fire('Success', 'Deleted Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error...', resp['data'], 'error')
    }
  }
  async delete(element : any) {
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete2(element)
      }
    })
  }
  async finaldelete2(element: any) {
    var   obj : any = new Object();
      obj  = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id;
    this.spinner.show()
    var resp = await this.propHierService.deleteLocationHierarchyNodes(obj);
    if (resp['error'] == false) {
      await this.open_list()
      this.spinner.hide();
      Swal.fire('Success', 'Deleted Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error...', resp['data'], 'error')
    }
  }

  //Sample vale
  //Sample vale
  //Sample vale
  //Sample vale
  //Sample vale

  // ob['lvl2_cd'] = this.orgHierarchy[i]['lvl2_cd']
  // ob['lvl2_value'] = this.orgHierarchy[i]['lvl2_value']
  // ob['lvl2_node_type'] = this.orgHierarchy[i]['lvl2_node_type']

  async onChangeLvl1() {

    for (let i = 0; i < this.level1.length; i++) {
      if (this.level1[i]['lvl1_cd'] == this.obj['lvl1_cd']) {
        this.obj['lvl1_value'] = this.level1[i]['lvl1_value']
        this.obj['lvl1_cd'] = this.level1[i]['lvl1_cd']
        this.obj['lvl1_node_type'] = this.level1[i]['lvl1_node_type']
        // this.obj['leaf_node_type'] = this.level1[i]['lvl1_node_type']
      }
    }
    if (!this.obj['lvl1_cd']) {
      this.obj['lvl1_value'] = ''
      this.obj['lvl1_node_type'] = ''
      this.obj['leaf_cd'] = ''
      this.obj['leaf_value'] = ''
      this.obj['leaf_node_type'] = ''
    }

    let temp: any = []
    this.level2 = []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (this.project_hier[i]['lvl2_cd'] == null) {
        continue;
      }
      if (this.project_hier[i]['lvl1_cd'] == this.obj['lvl1_cd']) {
        if (!temp.includes(this.project_hier[i]['lvl2_cd'])) {
          temp.push(this.project_hier[i]['lvl2_cd'])
          this.level2.push(this.project_hier[i])
        }
      }
    }
    this.level3 = []
    this.level4 = []
    this.level5 = []
    this.level6 = []
    this.level7 = []
    for (let i = 2; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null
      this.obj['lvl' + i + '_node_type'] = null
    }
    await this.makingLeafValues()
  }

  async onChangeLvl2() {
    for (let i = 0; i < this.level2.length; i++) {
      if (this.level2[i]['lvl2_cd'] == this.obj['lvl2_cd']) {
        this.obj['lvl2_value'] = this.level2[i]['lvl2_value']
        this.obj['lvl2_cd'] = this.level2[i]['lvl2_cd']
        this.obj['lvl2_node_type'] = this.level2[i]['lvl2_node_type']
      }
    }

    if (!this.obj['lvl2_cd']) {
      this.obj['lvl2_value'] = ''
      this.obj['lvl2_node_type'] = ''

    }
    let temp: any = []
    this.level3 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl2_cd'] == this.obj['lvl2_cd']) {
        if (this.allHier[i]['lvl3_cd']) {
          if (!temp.includes(this.allHier[i]['lvl3_cd'])) {
            temp.push(this.allHier[i]['lvl3_cd'])
            this.level3.push({ ...this.project_hier[i], ...this.allHier[i] })
          }

        }
      }
    }
    this.level4 = []
    this.level5 = []
    this.level6 = []
    this.level7 = []

    for (let i = 3; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null
      this.obj['lvl' + i + '_node_type'] = null
    }

    await this.makingLeafValues()
  }


  async onChangeLvl3() {
    for (let i = 0; i < this.level3.length; i++) {
      if (this.level3[i]['lvl3_cd'] == this.obj['lvl3_cd']) {
        this.obj['lvl3_value'] = this.level3[i]['lvl3_value']
        this.obj['lvl3_node_type'] = this.level3[i]['leaf_node_type']

      }
    }
    if (!this.obj['lvl3_cd']) {
      this.obj['lvl3_value'] = ''
      this.obj['lvl3_node_type'] = ''
    }
    let temp: any = []
    this.level4 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl3_cd'] == this.obj['lvl3_cd']) {
        if (this.allHier[i]['lvl4_cd']) {
          if (!temp.includes(this.allHier[i]['lvl4_cd'])) {
            temp.push(this.allHier[i]['lvl4_cd'])
            let ob = new Object()
            // ob['lvl4_cd'] = this.allHier[i]['lvl4_cd']
            // ob['lvl4_value'] = this.allHier[i]['lvl4_value']
            this.level4.push({ ...this.project_hier[i], ...this.allHier[i] })
          }
        }
      }
    }
    this.level5 = []
    this.level6 = []
    this.level7 = []
    for (let i = 4; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null
      this.obj['lvl' + i + '_node_type'] = null
    }
    await this.makingLeafValues()

  }
  async onChangeLvl4() {
    for (let i = 0; i < this.level4.length; i++) {
      if (this.level4[i]['lvl4_cd'] == this.obj['lvl4_cd']) {
        this.obj['lvl4_value'] = this.level4[i]['lvl4_value']
        this.obj['lvl4_node_type'] = this.level4[i]['leaf_node_type']
      }
    }
    if (!this.obj['lvl4_cd']) {
      this.obj['lvl4_value'] = ''
      this.obj['lvl4_node_type'] = ''
    }
    let temp : any= []
    this.level5 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl4_cd'] == this.obj['lvl4_cd']) {
        if (this.allHier[i]['lvl5_cd']) {
          if (!temp.includes(this.allHier[i]['lvl5_cd'])) {
            temp.push(this.allHier[i]['lvl5_cd'])
            // ob['lvl5_cd'] = this.allHier[i]['lvl5_cd']
            // ob['lvl5_value'] = this.allHier[i]['lvl5_value']
            this.level5.push({ ...this.project_hier[i], ...this.allHier[i] })
          }
        }
      }
    }
    this.level6 = []
    this.level7 = []

    for (let i = 5; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null
      this.obj['lvl' + i + '_node_type'] = null

    }

    await this.makingLeafValues()


  }

  async onChangeLvl5() {
    for (let i = 0; i < this.level5.length; i++) {
      if (this.level5[i]['lvl5_cd'] == this.obj['lvl5_cd']) {
        this.obj['lvl5_value'] = this.level5[i]['lvl5_value']
        this.obj['lvl5_node_type'] = this.level5[i]['leaf_node_type']


      }
      if (!this.obj['lvl5_cd']) {
        this.obj['lvl5_value'] = ''
        this.obj['lvl5_node_type'] = ''
      }
    }
    let  temp : any= []
    this.level6 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl5_cd'] == this.obj['lvl5_cd']) {
        if (this.allHier[i]['lvl6_cd']) {
          if (!temp.includes(this.allHier[i]['lvl6_cd'])) {
            temp.push(this.allHier[i]['lvl6_cd'])
            let ob = new Object()
            // ob['lvl6_cd'] = this.allHier[i]['lvl6_cd']
            // ob['lvl6_value'] = this.allHier[i]['lvl6_value']
            this.level6.push({ ...this.project_hier[i], ...this.allHier[i] })
          }
        }
      }

    }
    this.level7 = []

    for (let i = 6; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null
      this.obj['lvl' + i + '_node_type'] = null
    }

    await this.makingLeafValues()


  }


  async onChangeLvl6() {
    for (let i = 0; i < this.level6.length; i++) {
      if (this.level6[i]['lvl6_cd'] == this.obj['lvl6_cd']) {
        this.obj['lvl6_value'] = this.level6[i]['lvl6_value']
        this.obj['lvl6_node_type'] = this.level6[i]['leaf_node_type']

      }
      if (!this.obj['lvl6_cd']) {
        this.obj['lvl6_value'] = ''
        this.obj['lvl6_node_type'] = ''
      }
    }
    let  temp : any= []
    this.level7 = []
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl6_cd'] == this.obj['lvl6_cd']) {
        if (this.allHier[i]['lvl7_cd']) {
          if (!temp.includes(this.allHier[i]['lvl7_cd'])) {
            temp.push(this.allHier[i]['lvl7_cd'])
            let ob = new Object()
            // ob['lvl7_cd'] = this.allHier[i]['lvl7_cd']
            // ob['lvl7_value'] = this.allHier[i]['lvl7_value']
            this.level7.push({ ...this.project_hier[i], ...this.allHier[i] })
          }
        }


      }

    }

    for (let i = 7; i < 8; i++) {
      this.obj['lvl' + i + '_cd'] = null
      this.obj['lvl' + i + '_value'] = null
      this.obj['lvl' + i + '_node_type'] = null
    }

    await this.makingLeafValues()


  }

  async onChangeLvl7() {
    for (let i = 0; i < this.level7.length; i++) {
      if (this.level7[i]['lvl7_cd'] == this.obj['lvl7_cd']) {
        this.obj['lvl7_value'] = this.level7[i]['lvl7_value']
        this.obj['lvl7_node_type'] = this.level7[i]['leaf_node_type']
        this.obj['leaf_node_type'] = this.level7[i]['leaf_node_type']
      }
      if (!this.obj['lvl7_cd']) {
        this.obj['lvl7_value'] = ''
        this.obj['lvl7_node_type'] = ''
        this.obj['leaf_node_type'] = ''
        this.obj['leaf_cd'] = ''
        this.obj['leaf_value'] = ''
      }
    }
    await this.makingLeafValues()
  }
  async open_create_node() {
    await this.getProjectXREFHierarchy();
    this.list2_flag = false
    this.list1_flag = false
    this.create1_flag = true
    this.create2_flag = false
    this.update1_flag = false
    this.update2_flag = false
    this.spinner.show()
    let element = []

    if (this.allHier.length > 0) {
      element = this.allHier[this.allHier.length - 1]
    }
    this.Obj = Object.assign({}, element)
    let ob : any= {}
    ob = Object.assign({}, element)
    this.refresh()
    // if (element['lvl1_cd']) {
    //   this.obj = Object.assign(this.obj, element);
    //   await this.onChangeLvl1();
    // }
    // if (element['lvl2_cd']) {
    //   this.obj = Object.assign(this.obj, element);
    //   await this.onChangeLvl2();
    // }
    // if (element['lvl3_cd']) {
    //   this.obj = Object.assign(this.obj, element);
    //   await this.onChangeLvl3();
    // }
    // if (element['lvl4_cd']) {
    //   this.obj = Object.assign(this.obj, element);
    //   await this.onChangeLvl4();
    // }
    // if (element['lvl5_cd']) {
    //   this.obj = Object.assign(this.obj, element);
    //   await this.onChangeLvl5();
    // }
    // if (element['lvl6_cd']) {
    //   this.obj = Object.assign(this.obj, element);
    //   await this.onChangeLvl6();
    // }
    // if (element['lvl7_cd']) {
    //   this.obj = Object.assign(this.obj, element);
    //   await this.onChangeLvl7();
    // // }
    // this.obj = Object.assign({}, element);
    // await this.onChangeLvl6();
    // this.obj = Object.assign({}, element);
    // await this.onChangeLvl7()
    this.spinner.hide()
    let n : any= 0
    for (let i = 1; i <= 8; i++) {
      if (ob['lvl' + i + '_cd'] != null) {
        n = i
        // this.obj['lvl' + i + '_node_type'] = this.Obj['lvl' + i + '_node_type']
        this.leaf_obj['lvl' + i + '_node_type'] = this.obj['lvl' + i + '_node_type']
      } else {
        this.obj['lvl' + i + '_node_type'] = null
        this.leaf_obj['lvl' + i + '_node_type'] = null
      }

    }
    for (let j = 0; j <= 7; j++) {
      await this.set_leaf_node_type(j)

      if (this.obj['lvl' + j + '_cd']) {
        if (isNaN(this.obj['lvl' + j + '_cd'])) {
          let n = this.obj['lvl' + j + "_cd"]
          this.obj['lvl' + j + "_cd"] = null
          this.obj['lvl' + j + "_cd"] = n
        } else if (!isNaN(this.obj['lvl' + j + '_cd'])) {
          let n = this.obj['lvl' + j + "_cd"]
          this.obj['lvl' + j + "_cd"] = null
          this.obj['lvl' + j + "_cd"] = n
        } else {
          let n = this.obj['lvl' + j + "_cd"]
          this.obj['lvl' + j + "_cd"] = null
          this.obj['lvl' + j + "_cd"] = n
        }
      }
    }
    this.obj = {}
  }
  objToUpdate: any = {}
  async open_update(element: any) {
    this.list2_flag = false
    this.list1_flag = false
    this.create1_flag = false
    this.create2_flag = false
    this.update1_flag = true
    this.update2_flag = false
    let ob : any= {}
    this.spinner.show()
    this.Obj = Object.assign({}, element)
    ob = Object.assign({}, element)
    this.refresh()   // to intiatialize all the detail to starting point

    this.obj = Object.assign({}, element);

    await this.onChangeLvl1();

    this.obj = { ...this.obj, ...element };

    await this.onChangeLvl2();
    this.obj = { ...this.obj, ...element };
    await this.onChangeLvl3();
    this.obj = { ...this.obj, ...element };
    await this.onChangeLvl4();
    this.obj = { ...this.obj, ...element };
    await this.onChangeLvl5();
    this.obj = { ...this.obj, ...element };
    await this.onChangeLvl6();
    this.obj = { ...this.obj, ...element };
    await this.onChangeLvl7()
    this.spinner.hide()
    let n = 0
    for (let i = 1; i <= 8; i++) {
      if (ob['lvl' + i + '_cd']) {
        // this.obj['lvl' + i + '_node_type'] = this.Obj['lvl' + i + '_node_type']
        this.leaf_obj['lvl' + i + '_node_type'] = this.obj['lvl' + i + '_node_type']
        this.leaf_obj['lvl' + i + '_cd'] = this.obj['lvl' + i + '_cd']
      } else {
        this.obj['lvl' + i + '_node_type'] = null
        this.leaf_obj['lvl' + i + '_node_type'] = null
      }
    }

    for (let j = 1; j <= 7; j++) {
      await this.set_leaf_node_type(j)

      if (!this.obj['lvl' + j + '_cd']) {
        this.obj['lvl' + j + "_cd"] = null
        this.obj['lvl' + j + "_cd"] = this.obj['lvl' + j + "_cd"]
      }
    }
    this.objToUpdate = Object.assign({}, this.obj)
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }
  open_create() {
    this.list2_flag = false
    this.list1_flag = false
    this.create1_flag = false
    this.create2_flag = true
    this.update1_flag = false
    this.update2_flag = false
  }
  hier_evt_no = 0
  async view_hier(data: any) {
    this.list2_flag = true
    this.list1_flag = false
    this.create1_flag = false
    this.create2_flag = false
    this.update1_flag = false
    this.update2_flag = false
    this.Obj = Object.assign(this.Obj, data)
    this.hier_evt_no = this.Obj['hier_type_id']
    await this.getAllLevls(this.hier_evt_no)
    $('.nav-tabs a[href="#tab-7"]').tab('show')
  }
  async getAllLevls(hier_id: any) {
    var   obj : any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'ACTIVE';
    obj['hier_type_id'] = hier_id
    this.datasource = new MatTableDataSource([])
    this.datasource.paginator = this.paginator.toArray()[0];
    this.datasource.sort = this.sort.toArray()[0];
    this.spinner.show()
    var resp = await this.propHierService.getAllHierNodes(JSON.stringify(obj)); // taking herarcy to display
    if (resp['error'] == false) {
      this.allHier = resp.data;
      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['count'] = i + 1
      }
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      Swal.fire('Error...', 'Some Error Occured', 'error')
      this.spinner.hide()
    }
  }
  refresh() {
    let  temp : any= []
    this.level1 = []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!temp.includes(this.project_hier[i]['lvl1_cd'])) {
        temp.push(this.project_hier[i]['lvl1_cd'])
        this.level1.push({ ...this.project_hier[i], ...this.allHier[i] })
      }
    }
    // for (let i = 0; i < this.allHier.length; i++) {
    //   if (!temp.includes(this.allHier[i]['lvl2_cd'])) {
    //     temp.push(this.allHier[i]['lvl2_cd'])
    //     this.level2.push({ ...this.project_hier[i], ...this.allHier[i] })
    //   }
    // }
    // for (let i = 0; i < this.allHier.length; i++) {
    //   if (!temp.includes(this.allHier[i]['lvl3_cd'])) {
    //     temp.push(this.allHier[i]['lvl3_cd'])
    //     this.level3.push({ ...this.project_hier[i], ...this.allHier[i] })
    //   }
    // }
    // for (let i = 0; i < this.allHier.length; i++) {
    //   if (!temp.includes(this.allHier[i]['lvl4_cd'])) {
    //     temp.push(this.allHier[i]['lvl4_cd'])
    //     this.level4.push({ ...this.project_hier[i], ...this.allHier[i] })
    //   }
    // }
    // for (let i = 0; i < this.allHier.length; i++) {
    //   if (!temp.includes(this.allHier[i]['lvl5_cd'])) {
    //     temp.push(this.allHier[i]['lvl5_cd'])
    //     this.level5.push({ ...this.project_hier[i], ...this.allHier[i] })
    //   }
    // }
    // for (let i = 0; i < this.allHier.length; i++) {
    //   if (!temp.includes(this.allHier[i]['lvl6_cd'])) {
    //     temp.push(this.allHier[i]['lvl6_cd'])
    //     this.level6.push({ ...this.project_hier[i], ...this.allHier[i] })
    //   }
    // }
    // for (let i = 0; i < this.allHier.length; i++) {
    //   if (!temp.includes(this.allHier[i]['lvl7_cd'])) {
    //     temp.push(this.allHier[i]['lvl7_cd'])
    //     this.level7.push({ ...this.project_hier[i], ...this.allHier[i] })
    //   }
    // }
    this.obj = {};
    // this.level2 = []
    // this.level3 = []
    // this.level4 = []
    // this.level5 = []
    // this.level6 = []
    // this.level7 = []
  }

  async addNew(i: any) {

    let index = i
    if (this.obj['lvl' + i + '_type'] == 'text') {
      this.obj['lvl' + i + '_type'] = ''
    } else {
      this.obj['lvl' + i + '_type'] = 'text'
    }
    this.obj['lvl' + i + '_cd'] = null
    this.obj['lvl' + i + '_value'] = null
    this.obj['lvl' + i + '_node_type'] = null
    for (let j = i; j < 8; j++) {
      if (this.obj['lvl' + j + '_type'] != 'text') {
        this.obj['lvl' + j + '_cd'] = null
        this.obj['lvl' + j + '_value'] = null
        this.obj['lvl' + j + '_node_type'] = null
        this.Obj['lvl' + j + '_node_type'] = null
      }
    }
    for (let i = 1; i <= 8; i++) {
      if (this.obj['lvl' + i + '_cd']) {
        this.obj['leaf_cd'] = this.obj['lvl' + i + '_cd']
        this.obj['leaf_value'] = this.obj['lvl' + i + '_value']
      } else {
        break
      }
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.obj['lvl' + i + '_node_type'];
    }
    let num = index - 1
    if (num < 0) {
      num = 0
    }
    this.obj['leaf_node_type'] = this.obj['lvl' + num + '_node_type']
    this.obj['leaf' + num + '_cd'] = this.obj['lvl' + num + '_cd']
    this.obj['leaf' + num + '_value'] = this.obj['lvl' + num + '_value']
    let n = 0
    // this.obj['lvl' + i + "_cd"] = this.nextIDToInsert + Number(i)

  }

  async makingLeafValues() {
    if (this.obj['lvl7_cd']) {
      this.obj['leaf_cd'] = this.obj['lvl7_cd']
      this.obj['leaf_value'] = this.obj['lvl7_value']
      this.obj['leaf_node_type'] = this.obj['lvl7_node_type']
    } else if (this.obj['lvl6_cd']) {
      this.obj['leaf_cd'] = this.obj['lvl6_cd']
      this.obj['leaf_value'] = this.obj['lvl6_value']
      this.obj['leaf_node_type'] = this.obj['lvl6_node_type']
    } else if (this.obj['lvl5_cd']) {
      this.obj['leaf_cd'] = this.obj['lvl5_cd']
      this.obj['leaf_value'] = this.obj['lvl5_value']
      this.obj['leaf_node_type'] = this.obj['lvl5_node_type']
    } else if (this.obj['lvl4_cd']) {
      this.obj['leaf_cd'] = this.obj['lvl4_cd']
      this.obj['leaf_value'] = this.obj['lvl4_value']
      this.obj['leaf_node_type'] = this.obj['lvl4_node_type']
    } else if (this.obj['lvl3_cd']) {
      this.obj['leaf_cd'] = this.obj['lvl3_cd']
      this.obj['leaf_value'] = this.obj['lvl3_value']
      this.obj['leaf_node_type'] = this.obj['lvl3_node_type']
    } else if (this.obj['lvl2_cd']) {
      this.obj['leaf_cd'] = this.obj['lvl2_cd']
      this.obj['leaf_value'] = this.obj['lvl2_value']
      this.obj['leaf_node_type'] = this.obj['lvl2_node_type']
    } else if (this.obj['lvl1_cd']) {
      this.obj['leaf_cd'] = this.obj['lvl1_cd']
      this.obj['leaf_value'] = this.obj['lvl1_value']
      this.obj['leaf_node_type'] = this.obj['lvl1_node_type']
    }

  }
  leaf_obj: any = {}
  set_leaf_node_type(n: any) {

    this.leaf_obj['lvl' + n + '_node_type'] = this.obj['lvl' + n + '_node_type']
    this.obj['leaf_node_type'] = null
    for (let i = 8; i >= 1; i--) {
      if (this.leaf_obj['lvl' + i + '_node_type']) {
        this.obj['leaf_node_type'] = this.leaf_obj['lvl' + i + '_node_type']
        break
      }
    }
    // 'lvl3_node_type'
    let num = n
    if (num < 0) {
      num = 0
    }
    if (this.obj['lvl' + num + '_cd']) {
      this.obj['leaf_node_type'] = this.obj['lvl' + num + '_node_type']
    }
  }

  imageBlobUrl: any;
  imgURL: any
  selectedFile: any = null;
  isUpload: any;
  public imagePath: any;
  httpUrl: any;
  uploader: any;

  onFileUpload(event: any, files: any) {
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };

  }


  // is_header_present = false;
  // exportTemplate(){
  //   var arr1=[["","","","","Instruction to Fill The data in Sheet 2","","","",""],
  //   ["1","Please use only Numbers and character and sign to fill the data","","","","","","",""],
  //   ["2","All Value are alpha neumeric","","","","","","",""],
  //   ["3","Duplicate value will be removed while processing the file","","","","","","",""]];
  //   var arr2=[["lvl1_code",	"lvl1_value",	"lvl2_code",	"lvl2_value",	"lvl3_code",	"lvl3_value",	"lvl4_code"	,"lvl4_value",	"lvl5_code",	"lvl5value"	,"lvl6_code",	"lvl6_value",	"lvl7_code",	"lvl7_value",	"leaf_code",	"leaf_value"]]
  //   this.mainService.exportAsExcelFile(arr1,arr2,"Activity-Template")
  // }
  leafDetection() {
  }
  async upload() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('image', this.selectedFile, this.selectedFile.name);
    const   obj : any = new Object();

    obj['b_acct_id'] = this.erpUser.b_acct_id;
    obj['file_name'] = this.uploader.queue[0].some.name;
    var extention = obj['file_name'].split(".")
    obj['create_user_id'] = this.erpUser.user_id;
    obj['is_header_present'] = 1;
    obj['table_name'] = 'activity_hier';
    var data = []


    obj["event_record_code"] = "R101"



    this.spinner.show()
    if (extention[1].toLowerCase() == 'xlsx') {
      const params = JSON.stringify(obj);
      this.uploader.queue[0].url = this.httpUrl + '/accounts/hierarchy/processHierarchyFile' + params;
      this.uploader.queue[0].upload();
      this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        var resp = JSON.parse(response)
        if (resp.error == false) {
          await this.getAllHier();
          this.spinner.hide();

          swal.fire('Success', 'File Uploaded Successfully!!', 'success');
        } else {
          this.spinner.hide()
          swal.fire('Error', resp.data, 'error');
        }
      };
    }
    else {
      this.spinner.hide();
      swal.fire('Error', 'Please Upload Our Template File !!', 'error');
    }
  }
}
