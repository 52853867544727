<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Fixed Assest Report</div>
                </div>
                <div class="tab-pane fade show active" id="tab-1">
                    <br>

                    <div class="ibox-body">
                        <br>


                        <form name="form" (ngSubmit)="f.form.valid && allFixAssetReport()" #f="ngForm">

                            <div class="row">
                                <div class="col-3 text-center">
                                    <h6> Select Year : </h6>
                                </div>
                                <!-- <div class="col-3 text-center">
                                    <ng-select [items]="allFinYear" bindLabel="fin_year_desc" bindValue="fin_year"
                                        (change)="setFinYear()" [multiple]="false" placeholder="Select Year"
                                        [(ngModel)]="selectedFinYear" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fin_year">
                                    </ng-select>
                                </div> -->
                                <div class="col-3">
                                    <ng-select [items]="allFinYear" bindLabel="fin_year_desc" bindValue="fin_year"
                                        (change)="setFinYear()" [multiple]="false" placeholder="Select Year"
                                        [(ngModel)]="selectedFinYear" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fin_year"
                                        #fin_year="ngModel"
                                        [ngClass]="{ 'is-invalid': f.submitted && fin_year.invalid }" required>
                                    </ng-select>
                                    <!--------------------------------------------- validation Start ------------------- -->

                                    <!-- border color green -->
                                    <div [class.selection-box]="fin_year.dirty && fin_year.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f.submitted && fin_year.invalid" class="error-message">
                                        <div *ngIf="fin_year.errors?.['required']">Please Select Financial Year </div>
                                    </div>

                                    <!--------------------------------------------- validation End ------------------- -->


                                </div>
                                <div class="col-3 text-center">
                                    <h6> Strat Date : {{mainObj['start_date'] | date: 'dd/MM/yyyy'}} </h6>
                                </div>

                                <div class="col-3 text-center">
                                    <h6> End Date : {{mainObj['end_date'] | date: 'dd/MM/yyyy'}} </h6>
                                </div>

                            </div>
                            <br>

                            <div class="row">
                                <div class="col-6 text-right">
                                    <!-- <button class="btn btn-primary" (click)="allFixAssetReport()">Submit</button> -->
                                    <button class="btn btn-primary" type="submit">Submit</button>
                                </div>
                            </div>
                        </form>
                        <br>
                        <div class="row">
                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="print()">Print</button>&nbsp;&nbsp;
                                <button class="btn btn-success" (click)="export()">Export</button>&nbsp;&nbsp;
                            </div>
                        </div>
                        <br>
                        <div>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th rowspan="3">SNO</th>
                                        <th rowspan="3">Details of Fixed Assets</th>
                                        <th rowspan="3">Rate %</th>
                                        <th colspan="5">Gross Block</th>
                                        <th colspan="4">Depreciation Block</th>
                                        <th colspan="2">Net Block</th>
                                    </tr>

                                    <tr>
                                        <th rowspan="2">Op Bal. As on {{mainObj['start_date'] | date: 'dd/MM/yyyy'}}
                                        </th>
                                        <th colspan="2">Addition</th>
                                        <th rowspan="2">Sale</th>
                                        <th rowspan="2">Total as on {{mainObj['end_date'] | date: 'dd/MM/yyyy'}}
                                        </th>
                                        <th rowspan="2">Op Bal. As on {{mainObj['start_date'] | date: 'dd/MM/yyyy'}}
                                        </th>
                                        <th colspan="2">For {{fin_yr}}</th>
                                        <th rowspan="2">Total Upto {{mainObj['end_date'] | date: 'dd/MM/yyyy'}}</th>
                                        <th rowspan="2">W.D.V As on {{mainObj['end_date'] | date: 'dd/MM/yyyy'}}
                                        </th>
                                        <th rowspan="2">W.D.V As on {{mainObj['start_date'] | date: 'dd/MM/yyyy'}}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>180 days or more </th>
                                        <th>less than 180 days</th>
                                        <th>180 days or more </th>
                                        <th>less than 180 days</th>
                                    </tr>
                                    <tr>
                                        <th>(1)</th>
                                        <th>(2)</th>
                                        <th>(3)</th>
                                        <th>(4)</th>
                                        <th>(5)</th>
                                        <th>(6)</th>
                                        <th>(7)</th>
                                        <th>(8)</th>
                                        <th>(9)</th>
                                        <th>(10)</th>
                                        <th>(11)</th>
                                        <th>(12)</th>
                                        <th>(13)</th>
                                        <th>(14)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of table_data; let i= index">
                                        <td>{{item['sno']}}</td>
                                        <td>{{item['chart_of_account']}}</td>
                                        <td>{{item['rate']}} % </td>
                                        <td>{{item['opening_bal']}}</td>
                                        <td>{{item['more_180']}}</td>
                                        <td>{{item['less_180']}}</td>
                                        <td>{{item['sale']}}</td>
                                        <td>{{item['total_gross']}}</td>
                                        <td>{{item['d_opening']}}</td>
                                        <td>{{item['d_more_180']}}</td>
                                        <td>{{item['d_less_180']}}</td>
                                        <td>{{item['total_d']}}</td>
                                        <td>{{item['wdv_closing']}}</td>
                                        <td>{{item['wdv_opening']}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td></td>
                                        <td><b>{{totalObj['opening_bal']}}</b></td>
                                        <td><b>{{totalObj['more_180']}}</b></td>
                                        <td><b>{{totalObj['less_180']}}</b></td>
                                        <td><b>{{totalObj['sale']}}</b></td>
                                        <td><b>{{totalObj['total_gross']}}</b></td>
                                        <td><b>{{totalObj['d_opening']}}</b></td>
                                        <td><b>{{totalObj['d_more_180']}}</b></td>
                                        <td><b>{{totalObj['d_less_180']}}</b></td>
                                        <td><b>{{totalObj['total_d']}}</b></td>
                                        <td><b>{{totalObj['wdv_closing']}}</b></td>
                                        <td><b>{{totalObj['wdv_opening']}}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
