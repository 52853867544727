import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vms-administration',
  templateUrl: './vms-administration.component.html',
  styleUrls: ['./vms-administration.component.css']
})
export class VmsAdministrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
