import { Injectable } from "@angular/core";
import { EbillService } from "../service/ebill.service";
import { MasterDataService } from "../service/master-data.service";
import { TenderService } from "../service/tender.service";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import Swal from "sweetalert2";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { NgxSpinnerService } from "ngx-spinner";
import { MainService } from "../service/main.service";

@Injectable({
  providedIn: 'root'
})
export class PrintAbstractOfCostService {

  partyObj: any;

  alreadypaid:any = {};
  allDed:any = [];
  boqObj:any = {};
  boqItems:any = [];
  overunderobj:any = {};
  runningBill:any = [];
  prevbillpartrate:any = {};
  prevbillquantity:any = {};
  workObj:any = {};
  ebillUser:any;
  b_acct_id:any;
  constructor(
    public mainService: MainService,
    private ebillService: EbillService,
    private tenderService: TenderService,
    private masterDataService: MasterDataService,
    private spinner: NgxSpinnerService
  ) {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.ebillUser.b_acct_id;

  }

  async getPreviousBillItemsabstractofcost(tender_id:any, selectedTenderInfo:any) {
    var obj:any = {
      b_acct_id: this.b_acct_id,
      tender_id: tender_id,
    };


    //get Complete BOQ Detail;
    await this.getBoqItems(tender_id);

    let ebill = await this.ebillService.getBill(JSON.stringify(obj));
    console.log(ebill)
    if (ebill["data"].length != 0) {
      obj["bill_no"] = ebill["data"][ebill["data"].length - 1]["bill_no"];
    } else {
      obj["bill_no"] = 0;
    }
    let bill_no = obj["bill_no"] + 1;

    let emb = await this.ebillService.getgenemb(JSON.stringify(obj));
    let embdata = emb["data"];
    let snotoq:any = {};
    for (let i = 0; i < embdata.length; i++) {
      snotoq[embdata[i]["sno"]] = embdata[i]["q"];
    }
    var resp = await this.ebillService.getLastBill(JSON.stringify(obj));
    if (resp["error"] == false) {
      for (var i = 0; i < this.boqItems.length; i++) {
        this.runningBill[i] = Object.assign(this.boqItems[i]);
        this.runningBill[i]["billRate"] = 0;
        this.runningBill[i]["billCurrent"] = 0;
        this.runningBill[i]["billPrev"] = 0;
      }
      var dt = resp["data"];
      console.log(dt)

      await this.getlastbillrate(tender_id);
      await this.getpreviousbillquantity(tender_id);



      for (let i = 0; i < this.runningBill.length; i++) {
        this.alreadypaid[this.runningBill[i]["sno"]] =
          this.runningBill[i]["already_measured"];
      }


      for (var i = 0; i < dt.length; i++) {
        for (var j = 0; j < this.runningBill.length; j++) {


          if (this.runningBill[j]["currbillamt"] == undefined) {
            this.runningBill[j]["currbillamt"] = 0;
            this.runningBill[j]["prevbillamt"] = 0;
            this.runningBill[j]["uptodatebillamt"] = 0;
          }



          if (dt[i]["sno"] == this.runningBill[j]["sno"]) {
            this.runningBill[j]["prevbillamt"] += dt[i]["quantity"] * this.prevbillpartrate[dt[i]["sno"]];
            if (dt[i]["is_part"] == 2) {
              this.runningBill[j]["already_measured"] -= dt[i]["quantity"];
            } else {
              if (dt[i]["part_rate_id"] == -1) {
                this.runningBill[j]["billPrev"] += dt[i]["quantity"];
              }
            }
            this.runningBill[j]["uptodatebillamt"] += dt[i]["quantity"] * dt[i]["rate"];
            this.runningBill[j]["billRate"] = dt[i]["rate"];
          }


        }
      }




      for (let i = 0; i < this.runningBill.length; i++) {
        if (this.prevbillpartrate[this.runningBill[i]["sno"]] == undefined) {
          this.prevbillpartrate[this.runningBill[i]["sno"]] = 0;
        }
        if (this.prevbillpartrate[this.runningBill[i]["sno"]] == undefined) {
          this.prevbillpartrate[this.runningBill[i]["sno"]] = 0;
        }
        if (
          snotoq[this.runningBill[i]["sno"]] == undefined ||
          snotoq[this.runningBill[i]["sno"]] == null
        ) {
          snotoq[this.runningBill[i]["sno"]] = 0;
        }
        this.runningBill[i]["billCurrent"] = snotoq[this.runningBill[i]["sno"]];

        if (this.runningBill[i]["currbillrate"] == undefined)
          this.runningBill[i]["currbillrate"] = 0;

        this.runningBill[i]["prevbillamt"] =
          Number(
            (
              this.runningBill[i]["already_measured"] +
              this.runningBill[i]["billPrev"]
            ).toFixed(3)
          ) * this.prevbillpartrate[this.runningBill[i]["sno"]];

        let qty = this.runningBill[i]["already_measured"] +
          this.runningBill[i]["billPrev"] +
          this.runningBill[i]["billCurrent"]

        if ((this.runningBill[i]['eff_quantity']+this.runningBill[i]["already_measured"]) < qty) {
          this.runningBill[i]["billCurrent"] = this.runningBill[i]['eff_quantity'] - (this.runningBill[i]["billPrev"])
        }
        this.runningBill[i]["uptodatebillamt"] =
          Number(
            (
              this.runningBill[i]["already_measured"] +
              this.runningBill[i]["billPrev"] +
              this.runningBill[i]["billCurrent"]
            ).toFixed(3)
          ) * this.runningBill[i]["eff_rate"];
        this.runningBill[i]["currbillamt"] =
          this.runningBill[i]["uptodatebillamt"] -
          this.runningBill[i]["prevbillamt"];
      }
      this.print_abstractofcost({ bill_no: bill_no }, selectedTenderInfo);
    } else {
      Swal.fire("Error", "Error while printing bill", "error");
    }
  }


  async getAllWorkInfo() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.masterDataService.getWorkInfo(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.workObj = new Object();
      for (let i = 0; i < resp["data"].length; i++) {
        resp.data[i]["desc"] =
          resp.data[i]["work_order_no"] +
          " - " +
          resp.data[i]["work_order_name"];
        this.workObj[resp.data[i]["id"]] = resp.data[i];
      }
    } else {
      Swal.fire("Oops", "Error while getting work info");
    }
  }


  async getAllparty() {
    var resp = await this.masterDataService.getparty(this.b_acct_id);
    if (resp["error"] == false) {
      this.partyObj = new Object();
      let arr = [];
      for (let i = 0; i < resp.data.length; i++) {
        this.partyObj[resp.data[i]["rep_le_id"]] =
          resp.data[i]["party_leagal_name"];
        arr[resp.data[i]["rep_le_id"]] = resp.data[i];
      }
      return arr;
    } else {
      Swal.fire("Error", "Error while getting Vendor Info", "error");
      return ;
    }
  }


  async print_abstractofcost(element:any, selectedTenderInfo:any) {
    this.spinner.show();

    var ebillUser =  JSON.parse(atob(localStorage.getItem("erpUser")as any));


    //get All Work  Data********************
    await this.getAllWorkInfo();

    //get All Party  Data********************
    await this.getAllparty();


    //Print Code Start********************

    var account_name = ebillUser["account_name"];
    var account_short_name = ebillUser["account_short_name"];
    var txt = account_name + "(" + account_short_name + ") \n";
    var dd:any = {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        var arr = [];
        var obj = {
          text: txt,
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj);
        return arr;
      },
      pageOrientation: "portrait",
      pageMargins: [40, 60, 40, 60],
      content: [],
    };

    let h1txt = "Abstract of Cost";

    var header1 = {
      columns: [
        {
          width: "*",
          text: h1txt,
          bold: true,
          alignment: "center",
        },
      ],
    };

    var tbl:any = {
      fontSize: 10,
      table: {
        headerRows: 0,
        widths: [370, 375],
        body: [],
      },
    };

    var workObj = this.workObj[selectedTenderInfo["work_id"]];
    var partyObj = this.partyObj[selectedTenderInfo["party_id"]];

    if (partyObj == undefined) partyObj = "Vendor Not Mapped";
    var arr = [];
    arr.push({ text: "Name Of Contractor ", bold: true });
    arr.push(partyObj);
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "Name Of Work ", bold: true });
    arr.push(workObj["work_order_name"]);
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Bill No ", bold: true });
    arr.push(element["bill_no"]);
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Budget Head ", bold: true });
    arr.push(workObj["budget_cd"]);
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Budget Name ", bold: true });
    arr.push(workObj["budget_name"]);
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Estimate No ", bold: true });
    arr.push("");
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Work Order No ", bold: true });
    arr.push(workObj["work_order_no"]);
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Agreement Dated ", bold: true });
    arr.push(workObj["agr_dt"]);
    tbl.table.body.push(arr);

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }],
    });

    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(tbl);

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    var tbl1 = {
      fontSize: 10,
      table: {
        headerRows: 3,
        widths: [40, 40, 40, 250, 50, 50, 70, 70, 70],
        body: [
          [
            { rowSpan: 3, text: "upto date Qty" },
            { rowSpan: 3, text: "Paid Last Qty", alignment: "Right" },
            { rowSpan: 3, text: "To Pay Qty ", alignment: "right" },
            { rowSpan: 3, text: "Items", alignment: "center" },
            { rowSpan: 3, text: "Rate", alignment: "right" },
            { text: "Per", rowSpan: 3 },
            { text: "AMOUNT", alignment: "center", colSpan: 3 },
            {},
            {},
          ],
          [
            "",
            {},
            {},
            {},
            {},
            "",
            { text: "Upto Date Amount", alignment: "center" },
            { text: "Paid Last Amount", alignment: "center" },
            { text: "To pay Amount", alignment: "center" },
          ],
          [
            "",
            {},
            {},
            {},
            {},
            "",
            { text: "Rs.", alignment: "center" },
            { text: "Rs.", alignment: "center" },
            { text: "Rs.", alignment: "center" },
          ],
        ],
      },
    };

    dd.content.push(tbl1);
    var extra_itemarr = [];
    var printObj:any = {
      uptodatetotal: 0,
      lastpaidtotal: 0,
      topaytotal: 0,
      c1Over: 0,
      c2Over: 0,
      c3Over: 0,
      c1AfterOver: 0,
      c2AfterOver: 0,
      c3AfterOver: 0,
      c1PreGst: 0,
      c2PreGst: 0,
      c3PreGst: 0,
      c1GTotal: 0,
      c2GTotal: 0,
      c3GTotal: 0,
      c1CGST: 0,
      c2CGST: 0,
      c3CGST: 0,
      c1SGST: 0,
      c2SGST: 0,
      c3SGST: 0,
      c1WithHeld: 0,
      c2WithHeld: 0,
      c3WithHeld: 0,
      c1Sec: 0,
      c2Sec: 0,
      c3Sec: 0,
      c1AfterGST: 0,
      c2AfterGST: 0,
      c3AfterGST: 0,
      c1Net: 0,
      c2Net: 0,
      c3Net: 0,
      c1Ded: 0,
      c2Ded: 0,
      c3Ded: 0,
    };
    for (var i = 0; i < this.runningBill.length; i++) {
      if (
        this.runningBill[i]["currbillamt"] != 0 ||
        this.runningBill[i]["uptodatebillamt"] != 0
      ) {
        if (this.runningBill[i]["is_extra_item"] == 1) {
          extra_itemarr.push(this.runningBill[i]);
          continue;
        }
        var arr = [];
        arr.push(
          (
            this.alreadypaid[this.runningBill[i]["sno"]] +
            this.runningBill[i]["billCurrent"] +
            this.runningBill[i]["billPrev"]
          ).toFixed(3)
        );
        arr.push({
          text: (
            this.alreadypaid[this.runningBill[i]["sno"]] +
            this.runningBill[i]["billPrev"]
          ).toFixed(3),
          alignment: "right",
        });
        arr.push(this.runningBill[i]["billCurrent"].toFixed(3));
        arr.push({
          text:
            "(" +
            this.runningBill[i]["sno"] +
            ") " +
            this.runningBill[i]["item_desc"],
          alignment: "left",
        });
        arr.push({ text: this.runningBill[i]["eff_rate"], alignment: "right" });
        arr.push({ text: this.runningBill[i]["unit"], alignment: "right" });
        arr.push({
          text: Number(this.runningBill[i]["uptodatebillamt"].toFixed(2)),
          alignment: "right",
        });
        printObj["uptodatetotal"] =
          printObj["uptodatetotal"] +
          Number(Number(this.runningBill[i]["uptodatebillamt"]).toFixed(2));
        arr.push({
          text: Number(this.runningBill[i]["prevbillamt"].toFixed(2)),
          alignment: "right",
        });
        printObj["lastpaidtotal"] =
          printObj["lastpaidtotal"] +
          Number(this.runningBill[i]["prevbillamt"].toFixed(2));
        arr.push({
          text: this.runningBill[i]["currbillamt"].toFixed(2),
          alignment: "right",
        });
        printObj["topaytotal"] =
          printObj["topaytotal"] +
          Number(this.runningBill[i]["currbillamt"].toFixed(2));
        dd.content[dd.content.length - 1].table.body.push(arr);
      }
    }
    var arrtotal = [];
    arrtotal.push("");
    arrtotal.push("");
    arrtotal.push("");
    arrtotal.push({
      text: "Total",
      alignment: "right",
      bold: true,
      colSpan: 3,
    });
    arrtotal.push("");
    arrtotal.push("");

    arrtotal.push({
      text: Number(printObj["uptodatetotal"].toFixed(2)),
      alignment: "right",
    });
    arrtotal.push({
      text: Number(printObj["lastpaidtotal"].toFixed(2)),
      alignment: "right",
    });
    arrtotal.push({
      text: Number(printObj["topaytotal"].toFixed(2)),
      alignment: "right",
    });
    dd.content[dd.content.length - 1].table.body.push(arrtotal);

    var TOTALoverunderpertotalcurrentbill = 0
    var TOTALoverunderpertotallastbill = 0
    var TOTALoverunderpertotaluptodatebill = 0
    var updatetotal = printObj['uptodatetotal']
    var lastpaidtotal = printObj['lastpaidtotal']
    var topaytotal = printObj['topaytotal']
    printObj['uptodatetotal'] = 0
    printObj['lastpaidtotal'] = 0
    printObj['topaytotal'] = 0


    var finaloverunder:any = new Object()
    finaloverunder['LS'] = new Object()
    finaloverunder['ATPR'] = new Object()
    finaloverunder['EXC'] = new Object()

    for (let i = 0; i < this.runningBill.length; i++) {
      var overunderpertotalcurrentbill = 0
      var overunderpertotallastbill = 0
      var overunderpertotaluptodatebill = 0
      console.log(this.runningBill[i]['sno'])
      if (this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] == -1) {
        printObj['uptodatetotal'] = printObj['uptodatetotal'] + Number((this.runningBill[i]['uptodatebillamt']).toFixed(2))
        printObj['lastpaidtotal'] = printObj['lastpaidtotal'] + Number((this.runningBill[i]['prevbillamt']).toFixed(2))
        printObj['topaytotal'] = printObj['topaytotal'] + Number((this.runningBill[i]['currbillamt']).toFixed(2))
      } else {
        if (this.overunderobj[this.runningBill[i]['sno']]['over_under'] == 'LS') {
          if (finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] == undefined) {
            finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] = new Object()
            finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = 0
            finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = 0
            finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = 0
          }

          overunderpertotalcurrentbill = Number(((Number((Number((this.runningBill[i]['currbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
          overunderpertotallastbill = Number(((Number((Number((this.runningBill[i]['prevbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
          overunderpertotaluptodatebill = Number(((Number((Number((this.runningBill[i]['uptodatebillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
          TOTALoverunderpertotalcurrentbill = TOTALoverunderpertotalcurrentbill - overunderpertotalcurrentbill
          TOTALoverunderpertotallastbill = TOTALoverunderpertotallastbill - overunderpertotallastbill
          TOTALoverunderpertotaluptodatebill = TOTALoverunderpertotaluptodatebill - overunderpertotaluptodatebill
          finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] + overunderpertotalcurrentbill
          finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] + overunderpertotallastbill
          finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] + overunderpertotaluptodatebill

        }
        if (this.overunderobj[this.runningBill[i]['sno']]['over_under'] == 'EXC') {
          if (finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] == undefined) {
            finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] = new Object()
            finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = 0
            finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = 0
            finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = 0
          }

          overunderpertotalcurrentbill = Number(((Number((Number((this.runningBill[i]['currbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
          overunderpertotallastbill = Number(((Number((Number((this.runningBill[i]['prevbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
          overunderpertotaluptodatebill = Number(((Number((Number((this.runningBill[i]['uptodatebillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
          TOTALoverunderpertotalcurrentbill = TOTALoverunderpertotalcurrentbill + overunderpertotalcurrentbill
          TOTALoverunderpertotallastbill = TOTALoverunderpertotallastbill + overunderpertotallastbill
          TOTALoverunderpertotaluptodatebill = TOTALoverunderpertotaluptodatebill + overunderpertotaluptodatebill
          finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] + overunderpertotalcurrentbill
          finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] + overunderpertotallastbill
          finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] + overunderpertotaluptodatebill


        } if (this.overunderobj[this.runningBill[i]['sno']]['over_under'] == 'ATPR') {
          if (finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] == undefined) {
            finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] = new Object()
            finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = 0
            finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = 0
            finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = 0
          }
          overunderpertotalcurrentbill = Number(((Number((Number((this.runningBill[i]['currbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
          overunderpertotallastbill = Number(((Number((Number((this.runningBill[i]['prevbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
          overunderpertotaluptodatebill = Number(((Number((Number((this.runningBill[i]['uptodatebillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
          finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] + overunderpertotalcurrentbill
          finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] + overunderpertotallastbill
          finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] + overunderpertotaluptodatebill
        }
      }

    }
    if (selectedTenderInfo['over_under'] == 'EXC') {
      printObj['c1Over'] = Number((printObj['uptodatetotal'] * selectedTenderInfo['over_under_per'] / 100).toFixed(2));
      printObj['c2Over'] = Number((printObj['lastpaidtotal'] * selectedTenderInfo['over_under_per'] / 100).toFixed(2));
      printObj['c3Over'] = Number((printObj['topaytotal'] * selectedTenderInfo['over_under_per'] / 100).toFixed(2));
      printObj['c1AfterOver'] = Number((updatetotal + printObj['c1Over']).toFixed(2));
      printObj['c2AfterOver'] = Number((lastpaidtotal + printObj['c2Over']).toFixed(2));
      printObj['c3AfterOver'] = Number((topaytotal + printObj['c3Over']).toFixed(2));
      var ctotal = []
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push({ text: 'Add @ ' + selectedTenderInfo['over_under_per'] + ' %', alignment: 'right', bold: true, colSpan: 3 })
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      // ctotal.push({ text: printObj['c1Over'], alignment: 'right' })
      // ctotal.push({ text: printObj['c2Over'], alignment: 'right' })

      ctotal.push({ text: printObj['c3Over'], alignment: 'right' })
      dd.content[dd.content.length - 1].table.body.push(ctotal);



    } else if (selectedTenderInfo['over_under'] == 'LS') {
      printObj['c1Over'] = Number((printObj['uptodatetotal'] * selectedTenderInfo['over_under_per'] / 100).toFixed(2));
      printObj['c2Over'] = Number((printObj['lastpaidtotal'] * selectedTenderInfo['over_under_per'] / 100).toFixed(2));
      console.log(printObj['topaytotal'] ,selectedTenderInfo['over_under_per'])
      printObj['c3Over'] = Number((printObj['topaytotal'] * selectedTenderInfo['over_under_per'] / 100).toFixed(2));
      printObj['c1AfterOver'] = Number((updatetotal - printObj['c1Over']).toFixed(2));
      printObj['c2AfterOver'] = Number((lastpaidtotal - printObj['c2Over']).toFixed(2));
      printObj['c3AfterOver'] = Number((topaytotal - printObj['c3Over']).toFixed(2));
      var ctotal = []
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push({ text: 'Sub @ ' + selectedTenderInfo['over_under_per'] + ' %', alignment: 'right', bold: true, colSpan: 3 })
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      // ctotal.push({ text: printObj['c1Over'], alignment: 'right' })
      // ctotal.push({ text: printObj['c2Over'], alignment: 'right' })

      ctotal.push({ text: printObj['c3Over'], alignment: 'right' })
      dd.content[dd.content.length - 1].table.body.push(ctotal);

    } else {
      printObj['c1Over'] = 0;
      printObj['c2Over'] = 0;
      printObj['c3Over'] = 0;
      printObj['c1AfterOver'] = Number((updatetotal - printObj['c1Over']).toFixed(2));
      printObj['c2AfterOver'] = Number((lastpaidtotal - printObj['c2Over']).toFixed(2));
      printObj['c3AfterOver'] = Number((topaytotal - printObj['c3Over']).toFixed(2));
      var ctotal = []
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push({ text: ' At Par @ ', alignment: 'right', bold: true, colSpan: 3 })
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      // ctotal.push({ text: 0, alignment: 'right' })
      // ctotal.push({ text: 0, alignment: 'right' })

      ctotal.push({ text: 0, alignment: 'right' })
      dd.content[dd.content.length - 1].table.body.push(ctotal);

    }
    var ctotal = []
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')
    ctotal.push({ text: ' Amount After Over Under ', alignment: 'right', bold: true, colSpan: 3 })
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')
    // ctotal.push({ text: Number((printObj['c1AfterOver']).toFixed(2)), alignment: 'right', })
    // ctotal.push({ text: Number((printObj['c2AfterOver']).toFixed(2)), alignment: 'right' })
    ctotal.push({ text: Number((printObj['c3AfterOver']).toFixed(2)), alignment: 'right' })

    dd.content[dd.content.length - 1].table.body.push(ctotal);





    ///************************************EXTRA ITEMS********************************************************* */

    var ctotal = []
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')
    ctotal.push({ text: ' EXTRA ITEMS ', alignment: 'left', bold: true, colSpan: 3 })
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')


    if(extra_itemarr.length>0){
      dd.content[dd.content.length - 1].table.body.push(ctotal);
    }


    printObj['uptodatetotalextra'] = 0
    printObj['lastpaidtotalextra'] = 0
    printObj['topaytotalextra'] = 0
    for (var i = 0; i < extra_itemarr.length; i++) {
      if (
        extra_itemarr[i]["currbillamt"] != 0 ||
        extra_itemarr[i]["uptodatebillamt"] != 0
      ) {
        
        var arr = [];
        arr.push(
          (
            this.alreadypaid[extra_itemarr[i]["sno"]] +
            extra_itemarr[i]["billCurrent"] +
            extra_itemarr[i]["billPrev"]
          ).toFixed(3)
        );
        arr.push({
          text: (
            this.alreadypaid[extra_itemarr[i]["sno"]] +
            extra_itemarr[i]["billPrev"]
          ).toFixed(3),
          alignment: "right",
        });
        arr.push(extra_itemarr[i]["billCurrent"].toFixed(3));
        arr.push({
          text:
            "(" +
            extra_itemarr[i]["sno"] +
            ") " +
            extra_itemarr[i]["item_desc"],
          alignment: "left",
        });
        arr.push({ text: extra_itemarr[i]["eff_rate"], alignment: "right" });
        arr.push({ text: extra_itemarr[i]["unit"], alignment: "right" });
        arr.push({
          text: Number(extra_itemarr[i]["uptodatebillamt"].toFixed(2)),
          alignment: "right",
        });
        printObj["uptodatetotalextra"] =
          printObj["uptodatetotalextra"] +
          Number(Number(extra_itemarr[i]["uptodatebillamt"]).toFixed(2));
        arr.push({
          text: Number(extra_itemarr[i]["prevbillamt"].toFixed(2)),
          alignment: "right",
        });
        printObj["lastpaidtotalextra"] =
          printObj["lastpaidtotalextra"] +
          Number(extra_itemarr[i]["prevbillamt"].toFixed(2));
        arr.push({
          text: extra_itemarr[i]["currbillamt"].toFixed(2),
          alignment: "right",
        });
        printObj["topaytotalextra"] =
          printObj["topaytotalextra"] +
          Number(extra_itemarr[i]["currbillamt"].toFixed(2));
        dd.content[dd.content.length - 1].table.body.push(arr);
      }
    }


    var arrtotalextra = []
    arrtotalextra.push('')
    arrtotalextra.push('')
    arrtotalextra.push('')
    arrtotalextra.push({ text: 'Total EXTRA ITEMS', alignment: 'right', bold: true, colSpan: 3 })
    arrtotalextra.push('')
    arrtotalextra.push('')
    arrtotalextra.push('')
    arrtotalextra.push('')

    // arrtotalextra.push({ text: Number(printObj['uptodatetotalextra'].toFixed(2)), alignment: 'right' })
    // arrtotalextra.push({ text: Number(printObj['lastpaidtotalextra'].toFixed(2)), alignment: 'right' })
    arrtotalextra.push({ text: Number(printObj['topaytotalextra'].toFixed(2)), alignment: 'right' })
    if (extra_itemarr.length > 0) {
      dd.content[dd.content.length - 1].table.body.push(arrtotalextra);
    }


    var lsarr = Object.keys(finaloverunder['LS'])
    var excarr = Object.keys(finaloverunder['EXC'])
    var atpararr = Object.keys(finaloverunder['ATPR'])
    for (let i = 0; i < lsarr.length; i++) {
      var ctotal = []
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push({ text: 'Sub @ ' + lsarr[i] + ' % ', alignment: 'right', bold: true, colSpan: 3 })
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      // ctotal.push({ text: finaloverunder['LS'][lsarr[i]]['overunderpertotaluptodatebill'].toFixed(2), alignment: 'right' })
      // ctotal.push({ text: finaloverunder['LS'][lsarr[i]]['overunderpertotallastbill'].toFixed(2), alignment: 'right' })
      ctotal.push({ text: finaloverunder['LS'][lsarr[i]]['overunderpertotalcurrentbill'].toFixed(2), alignment: 'right' })

      
    if(extra_itemarr.length>0){
      dd.content[dd.content.length - 1].table.body.push(ctotal);
    }

    }
    for (let i = 0; i < excarr.length; i++) {
      var ctotal = []
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push({ text: 'Add @ ' + excarr[i] + ' % ', alignment: 'right', bold: true, colSpan: 3 })
      ctotal.push('')
      ctotal.push('')
      ctotal.push({ text: finaloverunder['EXC'][excarr[i]]['overunderpertotaluptodatebill'].toFixed(2), alignment: 'right' })
      ctotal.push({ text: finaloverunder['EXC'][excarr[i]]['overunderpertotallastbill'].toFixed(2), alignment: 'right' })
      ctotal.push({ text: finaloverunder['EXC'][excarr[i]]['overunderpertotalcurrentbill'].toFixed(2), alignment: 'right' })

      if(extra_itemarr.length>0){
        dd.content[dd.content.length - 1].table.body.push(ctotal);
      }

    }
    if (atpararr.length > 0) {
      var ctotal = []
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push({ text: ' At Par @ ', alignment: 'right', bold: true, colSpan: 3 })
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')

      // ctotal.push({ text: 0, alignment: 'right' })
      // ctotal.push({ text: 0, alignment: 'right' })

      ctotal.push({ text: 0, alignment: 'right' })
      
      if(extra_itemarr.length>0){
        dd.content[dd.content.length - 1].table.body.push(ctotal);
      }

    }



    var ctotal = []
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')
    ctotal.push({ text: ' Amount After Over Under in Extra Items', alignment: 'right', bold: true, colSpan: 3 })
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')


    // ctotal.push({ text: Number((TOTALoverunderpertotaluptodatebill + printObj['uptodatetotalextra']).toFixed(2)), alignment: 'right', })
    // ctotal.push({ text: Number((TOTALoverunderpertotallastbill + printObj['lastpaidtotalextra']).toFixed(2)), alignment: 'right' })
    ctotal.push({ text: Number((TOTALoverunderpertotalcurrentbill + printObj['topaytotalextra']).toFixed(2)), alignment: 'right' })

    if(extra_itemarr.length>0){
      dd.content[dd.content.length - 1].table.body.push(ctotal);
    }

    printObj['c1AfterOver'] = Number((printObj['c1AfterOver'] + TOTALoverunderpertotaluptodatebill + printObj['uptodatetotalextra']).toFixed(2));
    printObj['c2AfterOver'] = Number((printObj['c2AfterOver'] + TOTALoverunderpertotallastbill + printObj['lastpaidtotalextra']).toFixed(2));
    printObj['c3AfterOver'] = Number((printObj['c3AfterOver'] + TOTALoverunderpertotalcurrentbill + printObj['topaytotalextra']).toFixed(2));

    var ctotal = []
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')
    ctotal.push({ text: ' Amount After Over Under ', alignment: 'right', bold: true, colSpan: 3 })
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')
    ctotal.push('')

    // ctotal.push({ text: Number((printObj['c1AfterOver']).toFixed(2)), alignment: 'right' })
    // ctotal.push({ text: Number((printObj['c2AfterOver']).toFixed(2)), alignment: 'right' })
    ctotal.push({ text: Number((printObj['c3AfterOver']).toFixed(2)), alignment: 'right' })
    if(extra_itemarr.length>0){
      dd.content[dd.content.length - 1].table.body.push(ctotal);
    }

    if (selectedTenderInfo['discount'] != 0) {

      var ctotal = []
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push({ text: ' Discount @ ' + selectedTenderInfo['discount'], alignment: 'right', bold: true, colSpan: 3 })
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')
      ctotal.push('')

      // ctotal.push({ text: Number((printObj['c1AfterOver'] * selectedTenderInfo['discount'] / 100).toFixed(2)), alignment: 'right' })
      // ctotal.push({ text: Number((printObj['c2AfterOver'] * selectedTenderInfo['discount'] / 100).toFixed(2)), alignment: 'right' })
      ctotal.push({ text: Number((printObj['c3AfterOver'] * selectedTenderInfo['discount'] / 100).toFixed(2)), alignment: 'right' })
      dd.content[dd.content.length - 1].table.body.push(ctotal);
      printObj['c1AfterOver'] = Number((printObj['c1AfterOver'] - ((printObj['c1AfterOver'] * selectedTenderInfo['discount'] / 100))).toFixed(2))
      printObj['c2AfterOver'] = Number((printObj['c2AfterOver'] - ((printObj['c2AfterOver'] * selectedTenderInfo['discount'] / 100))).toFixed(2))
      printObj['c3AfterOver'] = Number((printObj['c3AfterOver'] - ((printObj['c3AfterOver'] * selectedTenderInfo['discount'] / 100))).toFixed(2))
    }

    var total1 = []
    total1.push('')
    total1.push('')
    total1.push('')
    total1.push({ text: 'Sub Total', alignment: 'right', bold: true, colSpan: 3 });
    total1.push('')
    total1.push('');
    total1.push('')
    total1.push('');

    // total1.push({ text: printObj['c1AfterOver'], alignment: 'right' })
    // total1.push({ text: printObj['c2AfterOver'], alignment: 'right' })
    total1.push({ text: printObj['c3AfterOver'], alignment: 'right' })
    dd.content[dd.content.length - 1].table.body.push(total1);



    this.spinner.hide();

    pdfMake.createPdf(dd).download("running-bill" + ".pdf");
  }


  async getBoqItems(tender_id:any) {
    var obj = { b_acct_id: this.b_acct_id, tender_id: tender_id };
    var resp = await this.tenderService.getItem(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.boqItems = resp["data"];
      this.boqObj = {};
      this.overunderobj = {};
      for (var i = 0; i < this.boqItems.length; i++) {
        this.boqObj[this.boqItems[i]["sno"]] = this.boqItems[i];
        this.overunderobj[this.boqItems[i]["sno"]] = {
          over_under_per: this.boqItems[i]["over_under_per"],
          over_under: this.boqItems[i]["over_under"],
        };
      }
    } else {
      Swal.fire("Oops", "Error while getting Boq Items");
    }
  }


  async getlastbillrate(tender_id:any) {
    var obj = {
      b_acct_id: this.b_acct_id,
      tender_id: tender_id
    };
    var resp = await this.ebillService.getlastbillrate(JSON.stringify(obj));
    if (resp["error"] == false) {
      if (resp["data"].length > 0) {
        this.prevbillpartrate = new Object();
        for (let i = 0; i < resp["data"].length; i++) {
          this.prevbillpartrate[resp["data"][i]["sno"]] =
            resp["data"][i]["rate"];
        }
      } else {
        for (let i = 0; i < this.boqItems.length; i++) {
          this.prevbillpartrate[this.boqItems[i]["sno"]] =
            this.boqItems[i]["part_rate"];
          this.prevbillquantity[this.boqItems[i]["sno"]] = 0;
        }
      }
    } else {
      Swal.fire(
        "Error",
        "Error while getting Previous Bill rate  data",
        "error"
      );
    }
  }


  async getpreviousbillquantity(tender_id:any) {
    var obj = { b_acct_id: this.b_acct_id, tender_id: tender_id };
    var resp = await this.ebillService.getquantityofprevbill(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        this.prevbillquantity[resp["data"][i]["sno"]] =
          resp["data"][i]["quantity"];
      }
    } else {
      Swal.fire(
        "Error",
        "Error while getting Previous Bill Quantity  data",
        "error"
      );
    }
  }
}
