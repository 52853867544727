import { NgxSpinnerService } from "ngx-spinner";
import { MasterDataService } from "../service/master-data.service";
import { TenderService } from "../service/tender.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatStepper } from "@angular/material/stepper";
import { MatPaginator } from "@angular/material/paginator";
import { ToastrService } from 'ngx-toastr';
import { MatSort } from "@angular/material/sort";
import { ReferenceDataService } from "../service/reference-data.service";
import { CommonDataService } from "../common _services/common-data.service";
import { MainService } from "../service/main.service";
import Swal from "sweetalert2";
import * as  pdfMake from "pdfmake/build/pdfmake";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EmbService } from "../service/emb.service";
import { UserService } from "../service/user.service";
import { ApproveService } from "../service/approve.service";
import { FilterService } from "../service/filter.service";
import { ApprovalUserService } from "../../portal/service/approval-user.service";
import { WorkFlowService } from "../../portal/service/work-flow.service";
import { EmbHierService } from "../service/emb-hier.service";
import { MainService as Portalmain } from "../../portal/service/main.service";
import { EbillRunningBillPrintService } from "../common _services/ebill-running-bill-print.service";
import { PrintAbstractOfCostService } from "../common _services/print-abstract-of-cost.service";
import { EmbPrintService } from "../common _services/emb-print.service";
import * as moment from "moment";
declare var $: any;

@Component({
  selector: "app-create-emb",
  templateUrl: "./create-emb.component.html",
  styleUrls: ["./create-emb.component.css"],
})

export class CreateEmbComponent implements OnInit {
  constructor(
    private ebillRunningBillPrintService: EbillRunningBillPrintService,
    private portalmain: Portalmain,
    public WorkFlowService: WorkFlowService,
    private embHierService: EmbHierService,
    public ApprovalUserService: ApprovalUserService,
    private CommonDataService: CommonDataService,
    public filterService: FilterService,
    private userService: UserService,
    private embService: EmbService,
    private snackBar: MatSnackBar,
    private approveService: ApproveService,
    private spinner: NgxSpinnerService,
    public mainService: MainService,
    private referenceDataS: ReferenceDataService,
    private toastr: ToastrService,
    private MSS: MasterDataService,
    private masterDataService: MasterDataService,
    private tenderService: TenderService,
    private embPrintService: EmbPrintService,
    private printAbstractOfCostService: PrintAbstractOfCostService,
  ) { }
  updateId:any;
  ebillUser:any;
  calculate_flage = true;
  len: boolean = false;
  width: boolean = false;
  height: boolean = false;
  isLinear = false;
  b_acct_id:any;
  allWork:any = [];
  embData: any = {
    dtlLines: [],
    sides: [],
    sides2: [],
    lines: [],
  };
  inner_table:any = [];
  boqItems:any = [];
  lastEmb:any = [];
  undertakings:any = [];
  lastEmbDetails:any = {};
  stepperIndex:any = 0;
  datasource:any;
  allFields:any = [];
  datasource2:any;
  first:any = true;
  vendorflag:any = false;
  apprflag:any = false;
  idtovalue:any = { 0: "NO", 1: "YES" };
  operator:any = [
    { code: "ADD", value: "+" },
    { code: "SUB", value: "-" },
  ];
  record_measurment:any = [
    { code: "Record Measurment", value: "Record Measurment" },
    { code: "Measurment", value: "Measurment" },
  ];
  fourth:any = true;
  datasource3:any;
  displayedColumns2: string[] = [
    "check",
    "line_id",
    "line_desc",
    "quantity",
    "date",
    "status",
    "action",
  ];
  displayedColumns4: string[] = [
    "line_id",
    "line_desc",
    "quantity",
    "emb",
    "action",
  ];
  displayedColumns: string[] = [
    "sn",
    "line_desc",
    "rate",
    "work_order_rate",
    "cal_bill_q",
  ];
  displayedColumns3: string[] = [
    "sn",
    "line_desc",
    "rate",
    "work_order_rate",
    "cal_bill_q",
  ];
  compute: any = [
    { id: "ACTUAL", value: "ACTUAL" },
    { id: "AVERAGE", value: "AVERAGE" },
  ];

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  @ViewChild("stepper", { static: false })  stepper!: MatStepper;
  @ViewChild("stepper", { static: false })  stepper1!: MatStepper;

  role_cd:any;

  create_flag: boolean = false;
  myapprov = false;
  mylist = true;
  update_flag: boolean = false;
  list_flag: boolean = true;
  app_flag: boolean = false;
  flag1: boolean = true;

  createtab() {
    this.refresh(this.stepper1);
    this.mylist = false;
    this.create_flag = true;
    this.myapprov = false;
    this.list_flag = false;
    this.update_flag = false;
    this.app_flag = false;
    this.calculate_flage = true;
  }

  listbtab() {
    this.refresh(this.stepper1);
    this.mylist = true;
    this.list_flag = true;
    this.myapprov = false;
    this.create_flag = false;
    this.update_flag = false;
    this.app_flag = false;
    this.flag1 = true;
    this.calculate_flage = false;
  }
  apptab() {
    this.refresh(this.stepper1);
    this.mylist = false;
    this.myapprov = true;
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
    this.app_flag = true;
    this.flag1 = false;
  }
  async downloadabstractofcost() {
    this.spinner.show()
    await this.printAbstractOfCostService.getPreviousBillItemsabstractofcost(this.selectedTender["tender_id"], this.selectedTender);
    this.spinner.hide();
  }

  async ngOnInit() {
    this.ebillUser =  JSON.parse(atob(localStorage.getItem("erpUser") as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.role_cd = this.ebillUser.role_cd;
    console.log(this.role_cd);

    this.allProject = this.mainService.codeValueTechObj["EMB001"];
    await this.getAllHeadRecord();
    await this.getAllWorkInfo();
    await this.getAllFields();
    await this.getundertakings();
    await this.getAllHeirNodeList();
    // console.log(this.filterService.filterObj["project"]);
    // if (this.level1["role_cd"] == this.role_cd) {
    //   this.apprflag = true;
    // }
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    await this.getWorkflowlog();
  }
  c_index:any;

  //emb delete workflow Effect
  async getdocumentstatus(element:any) {
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_local_no"] = element["id"];
    obj["doc_type"] = "EMB";
    obj["module_cd"] = "EMB";
    obj["status"] = "PENDING";

    var resp = await this.portalmain.getWorkflowlog(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      if (resp["data"].length > 0) {
        return resp["data"][0];
      }
    }
  }


  async pendingrevoked() {
    this.spinner.show();

    var obb:any = new Object();

    obb["b_acct_id"] = this.ebillUser.b_acct_id;
    obb["remark"] = this.appr_remark;
    obb["status"] = "EMB DELETED";
    obb["id"] = this.tender_apprdata["id"];

    var resp = await this.portalmain.updatetask(obb);
    if (resp["error"] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp["data"], "error");
    }
  }



  //message
  message_flag:any = false;
  async getle(user_id:any) {
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["le_id"] = user_id;
    let resp = await this.mainService.getcurrentlegalentity(
      JSON.stringify(obj)
    );
    console.log(resp["data"]);
    if (resp["error"] == false) {
      await this.sendmessage(resp["data"][0]["phone_no"] ,resp['data'][0]['party_name']
      );
      this.spinner.hide();
      this.message_flag = false;
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp["data"], "error");
    }
  }

  async sendmessage(mobile:any,user_name:any) {
    //console.log(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['doc_local_no'])
    let resp = await this.mainService.sendMsg(mobile, this.tender_apprdata['doc_type'] , this.tender_apprdata['doc_local_no'],user_name)
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }
  }
  // async sendmessage(mobile) {
  //   console.log(
  //     mobile,
  //     this.tender_apprdata["doc_type"] +
  //     " Number " +
  //     this.tender_apprdata["doc_local_no"]
  //   );
  //   let resp = await this.mainService.sendMsg(
  //     mobile,
  //     this.tender_apprdata["doc_type"] +
  //     " Number " +
  //     this.tender_apprdata["doc_local_no"]
  //   );
  //   if (resp["error"] == false) {
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide();
  //     Swal.fire("Error", resp["data"], "error");
  //   }
  // }



  existing_heirarchy_nodes = [];
  async getAllHeirNodeList() {
    this.spinner.show();
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    console.log(obj);
    var resp = await this.embHierService.getAllEmbHierarchy(
      JSON.stringify(obj)
    );
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      console.log(resp["data"]);
      this.existing_heirarchy_nodes = resp["data"];
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While EMB HIER", "error");
    }
  }



  //udertakings************************************//
  async getundertakings() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    obj["doc_type"] = "EMB";
    obj["role_cd"] = this.level1["role_cd"];
    var resp = await this.mainService.getundertakingsWithWorkflow(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.spinner.hide();
      console.log(resp["data"]);
      if (resp["data"].length > 0) {
        this.undertakings = JSON.parse(resp["data"][0]["data"]);
      }
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }

  //workflow*********************************************//
  level_for_approval:any = [];
  workflow_level1:any = {};
  async getWorkflowlog() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["status"] = "ACTIVE";
    obj["doc_type"] = "EMB";
    var resp = await this.mainService.getWorkflows(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      console.log(resp["data"]);
      if (resp.data.length == 0) {
        return;
      }
      this.workflow_level1 = resp["data"][0];
      this.level_for_approval = resp["data"];
      var currentuser_role = this.ebillUser.role_cd;
      for (let i = 0; i < currentuser_role.length; i++) {
        let role_Cd=this.workflow_level1['role_cd'].split(",")
        if (role_Cd.includes(currentuser_role[i])) {
          this.apprflag = true;
        }
      }
      this.level1 = this.workflow_level1;
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }


  async Approvedapproval() {
    this.spinner.show();
    if (
      this.forwarded_user_id != null ||
      this.ApprovalUserService.vendorflag == true
    ) {
      var obb:any = new Object();

      obb["user_id"] = this.ebillUser.user_id;
      obb['user_name'] = this.ebillUser.party_name
      obb["b_acct_id"] = this.ebillUser.b_acct_id;
      obb["forwarded_by"] = this.ebillUser.user_id;
      obb["forward_msg"] = this.appr_remark;
      obb["remark"] = "";
      obb["status"] = "APPROVED";
      obb["doc_type"] = "EMB";
      obb["doc_local_no"] = this.tender_apprdata["id"];
      obb["doc_desc"] = this.tender_apprdata["emb_desc"];
      obb["role_cd"] = this.level1["role_cd"];
      obb["module_cd"] = "EMB";
      obb["node_cd"] = this.selectedTender["work_id"];
      obb["level"] = this.level1["level"];
      obb["branch"] = this.level1["branch"];
      obb["field_value"] = 0;
      var resp = await this.mainService.insertTask(obb);
      if (resp["error"] == false) {
        await this.pendingapproval();
        await this.updatestatus(this.tender_apprdata["id"]);
        this.spinner.hide();
        if (
          this.forwarded_user_id == null &&
          this.ApprovalUserService.vendorflag == true
        ) {
          if (this.message_flag == true)
            await this.getle(this.selectedTender["party_id"]);
        } else {
          if (this.message_flag == true)
            await this.getle(
              this.ApprovalUserService.rolebyid[this.forwarded_user_id][
              "user_id"
              ]
            );
        }
        this.toastr.success("Approval Forwarded Successfully!!");
      } else {
        this.spinner.hide();
        this.toastr.error(resp["data"]);
      }
    } else {
      this.toastr.error("Please Select Any User..!");
      this.spinner.hide();
    }
  }



  async pendingapproval() {
    this.spinner.show();
    if (
      this.forwarded_user_id != null ||
      this.ApprovalUserService.vendorflag == true
    ) {
      var obb:any = new Object();
      if (
        this.forwarded_user_id == null &&
        this.ApprovalUserService.vendorflag == true
      ) {
        obb["user_id"] = -1;
        if (
          this.selectedTender["party_id"] == null ||
          this.selectedTender["party_id"] == undefined
        ) {
          Swal.fire("Error", "Please Map Verdor with Work..!", "error");
          return;
        }
        obb["vendor_id"] = this.selectedTender["party_id"];
      } else {
        obb["user_id"] =
          this.ApprovalUserService.rolebyid[this.forwarded_user_id]["user_id"];
      }

      if (obb["user_id"] == -1) {
        let userData:any = {};
        userData['b_acct_id'] = this.b_acct_id;
        userData['user_id'] = obb["vendor_id"];
        let venderInfo = await this.mainService.getVenderName(JSON.stringify(obb));
        if (venderInfo["error"] == false) {
          obb['user_name'] = venderInfo["data"]['party_leagal_name']
        }
      } else {
        for (let i = 0; i < this.ApprovalUserService.workuser.length; i++) {
          if (this.ApprovalUserService.workuser[i]['user_id'] == obb["user_id"]) {
            let h = this.ApprovalUserService.workuser[i]['desc'].split("-");
            obb['user_name'] = h[1];
          }
        }
      }
      console.log(this.forwarded_user_id);
      obb["b_acct_id"] = this.ebillUser.b_acct_id;
      obb["forwarded_by"] = this.ebillUser.user_id;
      obb["forward_msg"] = this.appr_remark;
      obb["remark"] = "";
      obb["status"] = "PENDING";
      obb["doc_type"] = "EMB";
      obb["doc_local_no"] = this.tender_apprdata["doc_local_no"];
      obb["doc_desc"] = this.tender_apprdata["emb_desc"];
      obb["module_cd"] = "EMB";
      obb["node_cd"] = this.tender_apprdata["node_cd"];
      if (this.ApprovalUserService.vendorflag == true) {
        obb["level"] = this.tender_apprdata["level"];
        obb["branch"] = this.tender_apprdata["branch"];
      } else {
        obb["role_cd"] =
          this.ApprovalUserService.rolebyid[this.forwarded_user_id]["role_cd"];
        obb["level"] =
          this.ApprovalUserService.rolebyid[this.forwarded_user_id]["level"];
        obb["branch"] =
          this.ApprovalUserService.rolebyid[this.forwarded_user_id]["branch"];
      }
      obb["field_value"] = 0;
      var resp = await this.mainService.insertTask(obb);
      if (resp["error"] == false) {
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error(resp["data"]);
      }
    } else {
      this.toastr.error("Please Select Any User..!");
      this.spinner.hide();
    }
  }




  // re assign
  venderid:any;
  async reassign(element:any) {
    await this.getzoneusers();
    await this.getapprdata(element);
    await this.getpendingdata(element);
    await this.getAllRulere("EMB");
    await this.getparty();
    this.venderid = this.selectedTender["party_id"];
    this.appr_remark = "";
    this.forwarded_user_id = null;
    $("#x").modal("show");
  }


  party = [];
  partyObj:any = {};
  async getparty() {
    this.spinner.show();
    var resp = await this.masterDataService.getparty(this.b_acct_id);
    if (resp["error"] == false) {
      this.party = resp.data;
      this.partyObj = new Object();
      for (let i = 0; i < this.party.length; i++) {
        this.partyObj[this.party[i]["rep_le_id"]] =
          this.party[i]["party_leagal_name"];
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error while getting Vendor Info", "error");
    }
  }
  async saveapprpending() {
    this.spinner.show();
    if (this.forwarded_user_id != null || this.flag == true) {
      var obb:any = new Object();
      if (this.forwarded_user_id == null && this.flag == true) {
        obb["user_id"] = -1;
        obb["vendor_id"] = this.selectedTender["party_id"];
      } else {
        obb["user_id"] = this.forwarded_user_id;
      }

      obb["b_acct_id"] = this.ebillUser.b_acct_id;
      obb["forwarded_by"] = this.ebillUser.user_id;
      obb["forward_msg"] = this.appr_remark;
      obb["remark"] = "";
      obb["status"] = "PENDING";
      obb["doc_type"] = this.tender_apprdata["doc_type"];
      obb["doc_local_no"] = this.tender_apprdata["doc_local_no"];
      obb["doc_desc"] = this.tender_apprdata["doc_desc"];
      obb["role_cd"] = this.rolebyid[this.forwarded_user_id];
      obb["zone_cd"] =
        this.CommonDataService.ProjectCodeToZoneCodeObj[this.selectedProject];
      obb["flag"] = this.tender_apprdata["flag"] + 1;
      var resp = await this.approveService.insertForAppr(obb);
      if (resp["error"] == false) {
        await this.updatepending(this.tender_apprdata);
        this.spinner.hide();
        this.toastr.success("Approval Forwarded Successfully!!");
      } else {
        this.spinner.hide();
        this.toastr.error(resp["data"]);
      }
    } else {
      this.toastr.error("Please Select Any User..!");
      this.spinner.hide();
    }
  }


  async Approveswal() {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.approve();
      }
    });
  }

  async approve() {
    this.spinner.show();
    var obb:any = new Object();
    obb["b_acct_id"] = this.ebillUser.b_acct_id;
    obb["flag"] = this.tender_apprdata["flag"];
    obb["doc_type"] = this.tender_apprdata["doc_type"];
    obb["doc_local_no"] = this.tender_apprdata["doc_local_no"];
    obb["user_id"] = this.tender_apprdata["user_id"];
    obb["role_cd"] = this.tender_apprdata["role_cd"];
    obb["status"] = "APPROVED";
    obb["remark"] = this.appr_remark;
    var resp = await this.approveService.updateappr(obb);
    if (resp["error"] == false) {

      await this.updatestatuspending(this.tender_apprdata["doc_local_no"]);
      this.toastr.success("Approved Successfully", "Success");
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  async updatestatuspending(id:any) {
    var obb:any = new Object();
    obb["b_acct_id"] = this.ebillUser.b_acct_id;
    obb["id"] = id;
    obb["update_user_id"] = this.ebillUser.user_id;
    obb["status"] = "APPROVED";
    var resp = await this.embService.changestatus(obb);
    if (resp["error"] == false) {
      await this.getTenderId();
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }


  async updatepending(id:any) {
    var obb:any = new Object();
    obb["b_acct_id"] = this.ebillUser.b_acct_id;
    obb["flag"] = this.tender_apprdata["flag"];
    obb["doc_type"] = this.tender_apprdata["doc_type"];
    obb["doc_local_no"] = this.tender_apprdata["doc_local_no"];
    if (this.tender_apprdata["user_id"] != -1) {
      obb["user_id"] = this.tender_apprdata["user_id"];
    }
    obb["role_cd"] = this.tender_apprdata["role_cd"];
    obb["status"] = "REVOKED";
    obb["remark"] = this.appr_remark;
    obb["vendor_id"] = this.tender_apprdata["vendor_id"];
    var resp = await this.approveService.updateappr(obb);
    if (resp["error"] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }


  pending_apprarr:any = [];
  async getpendingdata(element:any) {
    var obj:any = new Object();
    this.pending_apprarr = [];
    obj["doc_local_no"] = element.id;
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    obj["doc_type"] = "EMB";
    var resp:any = await this.approveService.getPendingApprbydoclocalno(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.tender_apprdata = Object.assign({}, resp["data"][0]);
      this.pending_apprarr.push(resp.data[0]);
    } else {
      this.toastr.error(resp["data"]);
    }
  }
  ApprArrdata:any = [];
  async getapprdata(element:any) {
    var obj:any = new Object();
    this.selectedEle = Object.assign({}, element);

    obj["doc_local_no"] = element.id;
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    obj["doc_type"] = "EMB";
    var resp = await this.approveService.getApprbydoclocalno(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.ApprArrdata = resp.data;
    } else {
      this.toastr.error(resp["data"]);
    }
  }


  level1re:any = {};
  workuserre:any = [];
  allDocTypere:any = [];
  allApprovalre:any = [];
  rolebyidre:any = {};
  flag:any = false;
  rejectuser:any = [];
  approveflag:any = false;
  useridtoname:any = {};
  async getAllRulere(doc_type:any) {
    this.allDocTypere = [];
    this.spinner.show();
    var resp = await this.referenceDataS.getApprovalLevels(
      JSON.stringify({ b_acct_id: this.b_acct_id })
    );
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.allApprovalre = resp["data"];
      var level2:any = {};
      this.rejectuser = [];
      console.log(this.ApprArrdata);
      console.log(this.zoneusers);
      console.log(this.allApprovalre);

      for (let i = 0; i < this.zoneusers.length; i++) {
        this.zoneusers[i]["desc"] =
          this.zoneusers[i]["user_id"] +
          "-" +
          this.zoneusers[i]["name"] +
          "-" +
          this.zoneusers[i]["role_cd"];
        this.useridtoname[this.zoneusers[i]["user_id"]] =
          this.zoneusers[i]["name"];
      }
      let vendorrevokedflag = false;
      for (let i = 0; i < this.ApprArrdata.length; i++) {
        if (this.ApprArrdata[i].user_id == -1) {
          vendorrevokedflag = true;
        }
        for (let j = 0; j < this.zoneusers.length; j++) {
          if (
            this.ApprArrdata[i].user_id == this.zoneusers[j]["user_id"] &&
            this.ApprArrdata[i].role_cd == this.zoneusers[j]["role_cd"]
          ) {
            this.rejectuser.push(this.zoneusers[j]);
            this.zoneusers.splice(j, 1);
            break;
          }
        }
      }

      console.log(this.zoneusers);

      for (let i = 0; i < this.rejectuser.length; i++) {
        if (
          this.rejectuser[i].user_id == this.ebillUser.user_id &&
          this.rejectuser[i].role_cd == this.tender_apprdata["role_cd"]
        ) {
          this.rejectuser.splice(i, 1);
        }
      }

      for (var i = 0; i < this.allApprovalre.length; i++) {
        if (this.allApprovalre[i]["doc_type"] == doc_type) {
          if (this.allApprovalre[i]["sent_to_vendor"] == 1) {
            this.level1re = Object.assign({}, this.allApprovalre[i]);
          }
          if (
            this.allApprovalre[i]["role_cd"] == this.tender_apprdata["role_cd"]
          ) {
            level2 = Object.assign({}, this.allApprovalre[i]);
          }
          if (this.tender_apprdata["user_id"] == -1) {
            if (this.allApprovalre[i]["sent_to_vendor"] == 1) {
              level2 = Object.assign({}, this.allApprovalre[i]);
            }
          }
          this.allDocTypere.push(this.allApprovalre[i]);
        }
      }
      console.log(level2, this.tender_apprdata);
      var arr = [];
      for (let j = 0; j < this.allDocTypere.length; j++) {
        for (let i = 0; i < this.zoneusers.length; i++) {
          if (
            this.allDocTypere[j]["role_cd"] == this.zoneusers[i]["role_cd"] &&
            this.allDocTypere[j]["level_of_approval"] >=
            level2["level_of_approval"]
          ) {
            arr.push(this.zoneusers[i]);
          }
        }
        if (arr.length > 0) {
          break;
        }
      }

      this.workuserre = arr;
      console.log(this.zoneusers);
      this.flag = false;
      for (let i = 0; i < this.zoneusers.length; i++) {
        if (this.level1re["role_cd"] == this.zoneusers[i]["role_cd"]) {
          this.flag = false;
          break;
        }
        if (
          this.zoneusers.length - 1 == i &&
          this.level1re["role_cd"] == this.tender_apprdata["role_cd"] &&
          vendorrevokedflag == false
        ) {
          this.flag = true;
        }
      }
      for (let i = 0; i < this.workuserre.length; i++) {
        if (this.workuserre[i]["role_cd"] != undefined) {
          this.rolebyid[this.workuserre[i]["user_id"]] =
            this.workuserre[i]["role_cd"];
        }
      }

      this.approveflag = false;
      if (this.workuserre.length == 0) {
        this.approveflag = true;
      }

      console.log(this.workuserre);
    } else {
      this.spinner.hide();
    }
  }
  dtlworkflow:any = [];
  async seeworkflow(element:any) {
    this.tender_apprdata = Object.assign({}, element);
    this.tender_apprdata["level"] = 1;
    this.tender_apprdata["branch"] = 1;
    this.tender_apprdata["doc_local_no"] = this.tender_apprdata["id"];
    this.tender_apprdata["doc_type"] = "EMB";
    this.tender_apprdata["node_cd"] = this.selectedTender["work_id"];
    this.tender_apprdata["module_cd"] = "EMB";
    await this.WorkFlowService.getWorkflowlog(this.ebillUser, "EMB", "EMB");
    await this.WorkFlowService.getWorkflowloguser(
      this.ebillUser,
      this.tender_apprdata
    );
    console.log(this.WorkFlowService.level_for_approval);
    this.dtlworkflow = this.WorkFlowService.level_for_approval;
    for (let i = 0; i < this.dtlworkflow.length; i++) {
      if (this.dtlworkflow[i]["data"] != null) {
        let data = JSON.parse(this.dtlworkflow[i]["data"]);
        let dummy = [];
        for (let j = 0; j < data.length; j++) {
          let ob;
          ob =
            data[j]["selected_field"] +
            "( " +
            data[j]["selected_oprater"] +
            "  -  " +
            data[j]["value"] +
            ")";
          dummy.push(ob);
        }
        this.dtlworkflow[i]["data_desc"] = dummy.join();
      }
    }
    $("#Z").modal("show");
  }
  allApproval:any = [];
  allDocType:any = [];
  level1:any = {};
  workuser:any = [];
  async getAllRule() {
    this.allDocType = [];
    this.spinner.show();
    var resp = await this.referenceDataS.getApprovalLevels(
      JSON.stringify({ b_acct_id: this.b_acct_id })
    );
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.allApproval = resp["data"];
      var obj:any = {};
      var level2:any = {};
      for (let i = 0; i < this.zoneusers.length; i++) {
        this.zoneusers[i]["desc"] =
          this.zoneusers[i]["user_id"] +
          "-" +
          this.zoneusers[i]["name"] +
          "-" +
          this.zoneusers[i]["role_cd"];
      }
      for (var i = 0; i < this.allApproval.length; i++) {
        if (this.allApproval[i]["doc_type"] == "EMB") {
          if (this.allApproval[i]["level_of_approval"] == 1) {
            this.level1 = Object.assign({}, this.allApproval[i]);
          }
          if (this.allApproval[i]["level_of_approval"] == 2) {
            level2 = Object.assign({}, this.allApproval[i]);
          }
          this.allDocType.push(this.allApproval[i]);
        }
      }
      var arr = [];
      for (let i = 0; i < this.zoneusers.length; i++) {
        if (
          this.level1["role_cd"] == this.zoneusers[i]["role_cd"] &&
          this.ebillUser.user_id != this.zoneusers[i]["user_id"]
        ) {
          arr.push(this.zoneusers[i]);
        }
      }
      this.vendorflag = false;
      if (arr.length == 0) {
        if (this.level1["sent_to_vendor"] == 1) {
          this.vendorflag = true;
        }
        for (let i = 0; i < this.zoneusers.length; i++) {
          if (level2["role_cd"] == this.zoneusers[i]["role_cd"]) {
            arr.push(this.zoneusers[i]);
          }
        }
      }
      this.workuser = arr;
      for (let i = 0; i < this.workuser.length; i++) {
        if (this.workuser[i]["role_cd"] != undefined) {
          this.rolebyid[this.workuser[i]["user_id"]] =
            this.workuser[i]["role_cd"];
        }
      }

      console.log(this.allDocType);
    } else {
      this.spinner.hide();
    }
  }
  zoneusers:any = [];
  rolebyid:any = {};

  async getzoneusers() {
    var Obj = Object();
    Obj["b_acct_id"] = this.b_acct_id;
    Obj["zone_cd"] =
      this.CommonDataService.ProjectCodeToZoneCodeObj[this.selectedProject];
    Obj["work_id"] = this.selectedWork;
    Obj["proj_cd"] = this.selectedProject;

    var resp = await this.userService.getAllWorkRelatedUser(
      JSON.stringify(Obj)
    );
    if (resp["error"] == false) {
      this.spinner.hide();
      this.zoneusers = resp["data"];
    } else {
      this.spinner.hide();
    }
  }

  async forwardToVendor() {
    var obb:any = new Object();
    obb["user_id"] = -1;
    obb["remark"] = "";
    obb["b_acct_id"] = this.b_acct_id;
    obb["forwarded_by"] = this.ebillUser.user_id;
    obb["status"] = "PENDING";
    obb["doc_type"] = "EMB";
    obb["doc_local_no"] = this.tender_apprdata["id"];
    obb["doc_desc"] = this.tender_apprdata["emb_desc"];
    obb["vendor_id"] = this.selectedTender["party_id"];
    obb["flag"] = 2;
    obb["zone_cd"] = this.ebillUser.zone_cd;
    console.log(obb);
    await this.vendorsaveappr();
    var resp = await this.approveService.insertForAppr(obb);
    if (resp["error"] == false) {
      await this.updatestatus(this.tender_apprdata["id"]);
      await this.getAllApprovalStatus();

      this.spinner.hide();
      this.toastr.success("Approval Added Successfully!!");
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  async vendorsaveappr() {
    var obb:any = new Object();
    obb["user_id"] = this.ebillUser.user_id;
    obb["remark"] = "";
    obb["b_acct_id"] = this.b_acct_id;
    obb["forwarded_by"] = this.ebillUser.user_id;
    obb["forward_msg"] = this.appr_remark;

    obb["status"] = "APPROVED";
    obb["doc_type"] = "EMB";
    obb["doc_local_no"] = this.tender_apprdata["id"];
    obb["doc_desc"] = this.tender_apprdata["emb_desc"];
    obb["role_cd"] = this.ebillUser.role_cd;
    obb["zone_cd"] = this.ebillUser.zone_cd;
    obb["flag"] = 1;
    var resp = await this.approveService.insertForAppr(obb);
    if (resp["error"] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  firstStep(): void {
    this.stepper.linear = false;
    this.stepper.selectedIndex = 0;
    setTimeout(() => {
      this.stepper.linear = true;
    });
  }

  selectedTender:any;
  selectedProject:any;
  selectedWork:any;
  async getTenderId() {
    var obj = new Object();
    obj = { b_acct_id: this.b_acct_id, work_id: this.selectedWork };
    var resp = await this.tenderService.getBoq(JSON.stringify(obj));
    if (resp["error"] == false) {
      if (resp.data.length == 0) {
        Swal.fire(
          "WARNING",
          "You can not created EMB for the selected work because there is no BOQ created for this work yet. Please create a BOQ for this work first before selecting this work or select a different work for which BOQ has been created",
          "warning"
        );
        this.selectedWork = '';

      }else{
      console.log(resp.data)
      this.selectedTender = resp["data"][resp["data"].length - 1];
      console.log(this.selectedTender);
      //oc
      return
      //ec
      }
      await this.getAllEmbList();
    } else {
      Swal.fire("Oops", "Error while getting tender information", "error");
    }
  }
  async getTenderIdDami() {
    var obj = new Object();
    obj = { b_acct_id: this.b_acct_id, work_id: this.selectedWork };
    var resp = await this.tenderService.getBoq(JSON.stringify(obj));
    if (resp["error"] == false) {
      if (resp.data.length == 0) {
        Swal.fire(
          "WARNING",
          "You can not created EMB for the selected work because there is no BOQ created for this work yet. Please create a BOQ for this work first before selecting this work or select a different work for which BOQ has been created",
          "warning"
        );
        this.selectedWork = '';
        return 1;
      }else{
        this.selectedTender = resp["data"][resp["data"].length - 1];
        //oc
        return
        //ec
      }

    } else {
      Swal.fire("Oops", "Error while getting tender information", "error");
      //oc
      return
      //ec
    }
  }


  async getTenderIdtab4() {

    var obj = new Object();
    obj = { b_acct_id: this.b_acct_id, work_id: this.selectedWork };
    var resp = await this.tenderService.getBoq(JSON.stringify(obj));
    if (resp["error"] == false) {
      if (resp.data.length == 0) {
        Swal.fire(
          "WARNING",
          "You can not created EMB for the selected work because there is no BOQ created for this work yet. Please create a BOQ for this work first before selecting this work or select a different work for which BOQ has been created",
          "warning"
        );
        this.selectedWork = '';
        this.datasource2 = [];
        return;
      }
      this.selectedTender = resp["data"][resp["data"].length - 1];
      console.log(this.selectedTender);
      await this.getAllEmbList();

      await this.getAllEmbListtab4();
    } else {
      Swal.fire("Oops", "Error while getting tender information", "error");
    }
  }
  emb_status:any = {};
  calculateEmbStatus(data:any) {
    this.emb_status = {};

    for (let i = data.length - 1; i >= 0; i--) {
      this.emb_status[data[i]["emb_no"]] = data[i]["status"];
    }

    var emb_num = Object.keys(this.emb_status);
    var fl = false;
    var st = "";
    for (let i = emb_num.length - 1; i >= 0; i--) {
      if (this.emb_status[emb_num[i]] == "UNDERAPPROVAL") {
        fl = true;
        st = "UNDERAPPROVAL";
      } else if (this.emb_status[emb_num[i]] == "APPROVED") {
        fl = true;
        st = "APPROVED";
      } else if (this.emb_status[emb_num[i]] == "REJECTED") {
        fl = true;
        st = "REJECTED";
      }
      if (fl && this.emb_status[emb_num[i]] == "GENERATED") {
        this.emb_status[emb_num[i]] = st;
      }
    }
    console.log(this.emb_status);
  }

  embdata:any = [];
  embdeleteflag:any;

  idarr:any = [];
  emblistdata:any = [];
  datasource5:any;
  allEmbList:any = []
  async getAllEmbList() {
    this.datasource5 = new MatTableDataSource([]);
    this.datasource5.sort = this.sort;
    this.datasource5.paginator = this.paginator;

    var obj = new Object();
    obj = {
      b_acct_id: this.b_acct_id,
      tender_id: this.selectedTender["tender_id"],
    };
    var resp = await this.embService.getAllEmbList(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.lastEmbDetails = {};
      console.log(resp);
      if (resp["data"][resp["data"].length - 1] != undefined) {
        this.lastEmbDetails = resp["data"][resp["data"].length - 1];
      }
      this.allEmbList = resp["data"];
      console.log(this.allEmbList)
      await this.calculateEmbStatus(this.allEmbList);

      this.embdeleteflag = 0;
      this.idarr = [];
      let data:any = [];
      for (let i = 0; i < this.allEmbList.length; i++) {
        if (this.allEmbList[i]["status"] == "GENERATED") {
          this.allEmbList[i]["check"] = false;
          data.push(this.allEmbList[i]);
        }
        this.idarr.push(this.allEmbList[i]["id"]);
        if (this.allEmbList[i]["status"] != "GENERATED") {
          this.embdeleteflag = this.allEmbList[i]["id"];
          this.idarr = [];
        }
      }

      this.emblistdata = data;
      this.datasource5 = new MatTableDataSource(data);
      this.datasource5.sort = this.sort;
      this.datasource5.paginator = this.paginator;
    } else {
      Swal.fire("Oops", "Error while getting tender information", "error");
    }
  }
  updateemblist:any = [];
  open_updatemodal(element:any) {
    let arr = JSON.parse(element["data"]);
    for (let i = 0; i < this.allEmbList.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        if (arr[j] == this.allEmbList[i]["id"])
          this.updateemblist.push(this.allEmbList[i]);
        console.log(this.updateemblist)
      }
    }
    $("#Zreject").modal("show");
  }


  async getAllEmbListtab4() {
    this.datasource2 = new MatTableDataSource([]);
    this.datasource2.sort = this.sort;
    this.datasource2.paginator = this.paginator;

    var obj = new Object();
    obj = {
      b_acct_id: this.b_acct_id,
      tender_id: this.selectedTender["tender_id"],
    };
    var resp = await this.embService.getapprovexrefembxrefappr(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      let embobj:any = {};
      for (let i = 0; i < this.allEmbList.length; i++) {
        embobj[this.allEmbList[i]["id"]] = this.allEmbList[i]["emb_no"];
      }
      let data = resp["data"];
      for (let i = 0; i < data.length; i++) {
        let arr = JSON.parse(data[i]["data"]);
        for (let j = 0; j < arr.length; j++) {
          if (data[i]["appr_emb"] == undefined && data[i]["appr_emb"] == null)
            data[i]["appr_emb"] = embobj[arr[j]];
          else data[i]["appr_emb"] = data[i]["appr_emb"] + "," + embobj[arr[j]];
        }
      }

      this.datasource2 = new MatTableDataSource(data);
      this.datasource2.sort = this.sort;
      this.datasource2.paginator = this.paginator;
    } else {
      Swal.fire("Oops", "Error while getting EMB information", "error");
    }
  }


  changeembnumber() {
    var projectDesc = "";
    var workDesc = "";
    for (var i = 0; i < this.allProject.length; i++) {
      if (this.allProject[i]["code"] == this.selectedProject) {
        projectDesc = this.allProject[i]["value"];
      }
    }
    this.embData["project_desc"] = projectDesc;
    for (var i = 0; i < this.allWList.length; i++) {
      if (this.allWList[i]["id"] == this.selectedWork) {
        workDesc = this.allWList[i]["work_order_name"];
      }
    }
    this.embData["emb_desc"] =
      this.and_st_nd_rd_at_Number(this.emb_no) +
      "" +
      " Emb Calculation" +
      " - " +
      projectDesc +
      " - " +
      workDesc;
  }
  emb_no = -1;
  e_no = -1;
  obj:any = {};
  create_emb = true;
  async changeWork() {
    this.create_emb = true;

    this.e_no = 0;
    let planing_path = "";
    console.log(this.allWork)
    for (let i = 0; i < this.allWork.length; i++) {
      if (this.allWork[i]["id"] == this.selectedWork) {
        this.obj["node_cd"] = this.allWork[i]["node_cd"];
      }
    }
    this.obj["path_desc"] = null;
    if (!this.selectedWork) {
      this.obj["path_desc"] = null;
      this.obj["node_cd"] = null;
    }

    this.obj["path_desc"] = this.obj["node_cd"]
    console.log(this.obj, "path desc");

    this.allProject = this.mainService.codeValueTechObj["EMB001"];
    let noUploadBoq:any = await this.getTenderId();
    if (noUploadBoq == 1) {
      this.obj["path_desc"] = '';
      this.embData['emb_dt'] = null;
      this.e_no = 0;
      this.selectedWork = '';
    } else {
      await this.getBoqItems();
      await this.getEmbTotal();
    }
    var projectDesc = "";
    var workDesc = "";
    this.embData["project_desc"] = projectDesc;
    for (var i = 0; i < this.allWork.length; i++) {
      if (this.allWork[i]["id"] == this.selectedWork) {
        workDesc = this.allWork[i]["work_order_name"];
      }
    }
    this.embData["work_order"] = workDesc;
    this.embData["emb_desc"] = "";
    var n = 0;

    if (
      this.lastEmbDetails == undefined ||
      this.lastEmbDetails["emb_no"] == undefined
    ) {
      n = 1;

      this.embData["emb_desc"] =
        this.and_st_nd_rd_at_Number(n) +
        "" +
        " Emb Calculation" +
        " - " +
        workDesc;
      this.emb_no = n;
      this.e_no = n;
    } else {
      n = this.lastEmbDetails["emb_no"] + 1;
      this.embData["emb_desc"] =
        this.and_st_nd_rd_at_Number(n) +
        "" +
        " Emb Calculation" +
        " - " +
        workDesc;
      this.emb_no = n;
    }
    this.embData.lines = [];
    let today = new Date().toISOString().slice(0, 10);
    this.embData['emb_dt'] = today;
    if (noUploadBoq == 1) {
      this.obj["path_desc"] = '';
      this.embData['emb_desc'] = '';
      this.e_no = 0;
      this.datasource = [];
      this.selectedWork = '';
      this.embData['emb_dt'] = '';
    }
    this.calculate_flage = true;
  }
  measureindex:any = 0;
  selectedItem:any = {};
  selectedIndex:any;
  lines:any = [
    {
      line_no: -1,
      sno: -1,
      line_desc: "",
      unit: "",
      total: 0,
      rate: 0,
      dtlLines: {},
    },
  ];
  lineTotal = 0;
  avlShap:any = [];
  openCalculatePopup(element:any, i:any) {
    console.log(this.embData);
    for (var j = 0; j < this.embData.lines.length; j++) {
      if (
        this.embData["lines"][j]["shape"] == undefined ||
        this.embData["lines"][j]["shape"] == null
      ) {
        this.embData["lines"].splice(j, 1);
      }
    }
    this.selectedItem = Object.assign({}, element);
    this.selectedIndex = i;
    this.lines = [];
    this.lineTotal = 0;
    this.avlShap = [];
    this.inner_table = [];
    for (var j = 0; j < this.embData.lines.length; j++) {
      if (this.embData.lines[j]["sno"] == this.selectedItem["sno"]) {
        this.inner_table[this.lines.length] = "true";
        console.log(this.embData.lines[j]);
        this.measurearr.push([
          ...this.embData.lines[j]["dtlLines"]["measures"],
        ]);
        this.measureobj.push([
          ...this.embData.lines[j]["dtlLines"]["measureobj"],
        ]);
        this.lines.push(this.embData.lines[j]);
        if (this.embData.lines[j]["quantity"] != undefined) {
          this.lineTotal += this.embData.lines[j]["quantity"];
        }
      }
    }
    var alreadVisited:any = {};
    for (var j = 0; j < this.allFields.length; j++) {
      if (
        this.allFields[j]["unit"].toLowerCase() ==
        element["unit"].toLowerCase() &&
        alreadVisited[this.allFields[j]["geometry"]] == undefined
      ) {
        this.avlShap.push(this.allFields[j]);
        alreadVisited[this.allFields[j]["geometry"]] = 1;
      }
    }
    this.calculatelinetotal();
    this.calculate_flage = false;
  }
  measures:any = [];
  sides:any = [];
  cons:any = [];
  mul:any = [];
  calculatepartrate(element:any) {
    for (var j = 0; j < this.embData.lines.length; j++) {
      if (this.embData.lines[j]["sno"] == element["sno"]) {
        this.embData.lines[j]["rate"] = element["part_rate"];
      }
    }
  }
  changeShape(ln2:any, i:any) {
    this.sides = [];
    this.cons = [];
    this.mul = [];
    this.measures = [];
    var sh = this.lines[i]["shape"];
    var formula = [];
    let formulastr = "";
    for (var j = 0; j < this.allFields.length; j++) {
      if (this.allFields[j]["id"] == sh) {
        this.measures = this.allFields[j]["measurement"].split(",");
        formula = this.allFields[j]["formual"].split("*");
        this.lines[i]["dtlLines"]["formulastr"] = this.allFields[j]["formual"];
      }
    }
    this.lines[i]["dtlLines"]["formula"] = formula;

    this.lines[i]["dtlLines"]["measures"] = this.measures;
    for (var j = 0; j < this.measures.length; j++) {
      if (
        Number(this.measures[j]) == undefined ||
        Number.isNaN(Number(this.measures[j]))
      ) {
        this.sides.push(this.measures[j]);
        this.lines[i]["dtlLines"][this.measures[j]] = [{ mes: 0, desc: "" }];
      }
    }
    for (var j = 0; j < formula.length; j++) {
      if (!Number.isNaN(Number(formula[j]))) {
        this.cons.push(formula[j]);
      } else {
        this.mul.push(formula[j]);
      }
    }
    if (this.lines[i]['compute'] != undefined) {
      ln2['compute'] = this.lines[i]['compute'];
      this.popupmeasure1(ln2, i);
    }

  }
  measurearr:any = [];
  measureobj:any = [];
  computeq:any = "";
  add_n(data:any, index:any, i:any) {
    console.log(data, index);
    console.log(this.measurearr);
    this.measureobj[i].splice(index + 1, 0, { measure: data });
    this.measurearr[i].splice(index + 1, 0, data);
  }
  delete(data:any, index:any, i:any) {
    console.log(data, index);
    console.log(this.measurearr);
    this.measureobj[i].splice(index, 1);
    this.measurearr[i].splice(index, 1);
    this.calculate1(i);
  }

  popupmeasure(ln:any, i:any) {
    this.lines[i]["total"] = 0;
    if (ln["shape"] == null && !ln["compute"]) {
      Swal.fire("WARNING", "Please Select All Fields", "warning");
      return;
    } else {
      this.inner_table[i] = "true";
      this.computeq = ln["compute"];

      this.measurearr = [];
      this.measurearr = this.lines[i]["dtlLines"]["measures"];

      this.measureobj = [];
      if (!this.lines[i]["dtlLines"]["measureobj"]) {
        for (let k = 0; k < this.measurearr.length; k++) {
          this.measureobj.push({ measure: this.measurearr[k], flag: true });
        }
        if (!this.measureobj[this.measureobj.length - 1]["number"]) {
          this.lines[this.measureindex]["dtlLines"]["formula"].push("number");
          this.measureobj.push({ measure: "number", mes: "1", flag: true });
          this.measurearr.push("number");
        }
      } else {
        this.measureobj = this.lines[i]["dtlLines"]["measureobj"];
      }

      this.lines[i]["dtlLines"]["measureobj"] = this.measureobj;
    }
  }
  popupmeasure1(ln:any, i:any) {
    if (ln["compute"] == "ACTUAL") {
      this.sides = [];
      this.cons = [];
      this.mul = [];
      this.measures = [];
      var sh = this.lines[i]["shape"];
      var formula = [];
      let formulastr = "";
      for (var j = 0; j < this.allFields.length; j++) {
        if (this.allFields[j]["id"] == sh) {
          this.measures = this.allFields[j]["measurement"].split(",");
          formula = this.allFields[j]["formual"].split("*");
          this.lines[i]["dtlLines"]["formulastr"] = this.allFields[j]["formual"];
        }
      }
      this.lines[i]["dtlLines"]["formula"] = formula;

      this.lines[i]["dtlLines"]["measures"] = this.measures;
      for (var j = 0; j < this.measures.length; j++) {
        if (
          Number(this.measures[j]) == undefined ||
          Number.isNaN(Number(this.measures[j]))
        ) {
          this.sides.push(this.measures[j]);
          this.lines[i]["dtlLines"][this.measures[j]] = [{ mes: 0, desc: "" }];
        }
      }
      for (var j = 0; j < formula.length; j++) {
        if (!Number.isNaN(Number(formula[j]))) {
          this.cons.push(formula[j]);
        } else {
          this.mul.push(formula[j]);
        }
      }
    }
    console.log(ln, i);
    if (ln["shape"] == null && !ln["compute"]) {
      Swal.fire("WARNING", "Please Select All Fields", "warning");
      return;
    } else {
      this.inner_table[i] = "true";
      this.computeq = ln["compute"];
      this.measureindex = i;
      console.log(this.measurearr[i])
      this.measurearr[i] = [];
      console.log(this.lines)
      this.measurearr[i] = this.lines[i]["dtlLines"]["measures"];
      console.log(this.measurearr[i])
      console.log(Array.isArray(this.measurearr[i]))
      for (let d = 0; d < this.measurearr[i].length; d++) {
        if (this.measurearr[i][d] == "number") {
          this.measurearr[i].pop(this.measurearr[i][d])
        }
      }
      console.log(this.measurearr[i])
      this.lines[i]["dtlLines"]["measureobj"] = undefined;
      this.lines[this.measureindex]["total"] = 0;
      console.log(this.lines[i]["dtlLines"]["measureobj"])
      if (!this.lines[i]["dtlLines"]["measureobj"]) {
        this.measureobj[i] = [];
        for (let k = 0; k < this.measurearr[i].length; k++) {
          this.measureobj[i].push({
            measure: this.measurearr[i][k],
            flag: true,
          });
        }
        if (!this.measureobj[i][this.measureobj[i].length - 1]["number"]) {
          this.lines[this.measureindex]["dtlLines"]["formula"].push("number");
          this.measureobj[i].push({ measure: "number", mes: "1", flag: true });
          this.measurearr[i].push("number");
        }
      } else {
        for (let j = 0; j < this.measureobj[i].length; j++) {
          if (this.measureobj[i][j]["flag"] != undefined) {
            if (this.measureobj[i][j]["flag"]) {
              this.measureobj[i][j]["desc"] = "";
            }
          } else {
            this.measureobj[i].splice(j, 1);
            this.measurearr[i].splice(j, 1);
          }
        }
      }
      this.lines[i]["dtlLines"]["measureobj"] = this.measureobj[i];
    }
    console.log(this.measureobj[i], this.measurearr[i], this.lines);
  }


  async savepopup() {
    if (
      this.embData["emb_dt"] == null ||
      this.embData["emb_dt"] == undefined
    ) {
      this.spinner.hide();
      Swal.fire("Warning", "Please Enter Date of Measurement", "warning");
      return;
    }

    this.datasource = new MatTableDataSource([]);
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
    this.spinner.show();
    console.log(this.boqItems)
    console.log(this.selectedItem)
    for (let i = 0; i < this.boqItems.length; i++) {
      if (this.boqItems[i]["sno"] == this.selectedItem["sno"]) {
        if (this.measurecheck[this.boqItems[i]["sno"]] == undefined || this.measurecheck[this.boqItems[i]["sno"]] == null)
          this.measurecheck[this.boqItems[i]["sno"]] = 0;

        if (this.boqItems[i]["eff_quantity"] + this.boqItems[i]["already_measured"] < 0) {
          if (this.lineTotal + this.measurecheck[this.boqItems[i]["sno"]] + this.boqItems[i]["already_measured"] > 0) {
            Swal.fire(
              "Oops",
              "This Measurement is not Valid Please Check",
              "error"
            );
            console.log(this.embData.lines);
            return;
          }
        } else if (this.boqItems[i]["eff_quantity"] + this.boqItems[i]["already_measured"] > 0) {
          if (this.lineTotal + this.measurecheck[this.boqItems[i]["sno"]] + this.boqItems[i]["already_measured"] < 0) {
            Swal.fire(
              "Oops",
              "This Measurement is not Valid Please Check",
              "error"
            );
            console.log(this.embData.lines);
            return;
          }
        }

        break;
      }
    }
    var tempArr = [];
    for (var j = 0; j < this.embData.lines.length; j++) {
      if (this.embData.lines[j]["sno"] == this.selectedItem["sno"]) {
      } else {
        tempArr.push(this.embData.lines[j]);
      }
    }
    this.embData["lines"] = tempArr;
    for (let i = 0; i < this.lines.length; i++) {
      var obj = Object.assign({}, this.lines[i]);
      this.embData.lines.push(obj);
    }
    for (var j = 0; j < this.embData.lines.length; j++) {
      if (
        this.embData["lines"][j]["shape"] == undefined ||
        this.embData["lines"][j]["shape"] == null
      ) {
        this.embData["lines"].splice(j, 1);
      }
    }

    for (let i = 0; i < this.boqItems.length; i++) {
      if (this.boqItems[i]["sno"] == this.selectedItem["sno"]) {
        this.boqItems[i]["ebill_quantity"] = this.lineTotal;
        if (this.boqItems[i]["remaining_quantity"] < 0) {
          if (this.boqItems[i]["ebill_quantity"] > 0) {
            Swal.fire(
              "Oops",
              "This Measurement is not Valid Please Check",
              "error"
            );
          }
        }

        break;
      }
    }

    this.spinner.hide();
    console.log(this.boqItems);

    this.getEmbTotal();
    this.calculate_flage = true;
    if (this.create_emb) {
      await this.submit()
    }
    else {
      await this.update_new(true,'notFinalSubmit')
    }

  }
  refreshSavePopup() {
    this.calculate_flage = true;
  }


  /*-------------submit run time calculate ------------*/
  async submit() {
    this.spinner.show();
    if (
      this.embData["emb_dt"] == null ||
      this.embData["emb_dt"] == undefined
    ) {
      this.spinner.hide();
      Swal.fire("Warning", "Please Enter Date of Measurement", "warning");
      return;
    }
    let obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["create_user_id"] = this.ebillUser.user_id;
    obj["work_order_id"] = this.selectedWork;
    obj["emb_desc"] = this.embData["emb_desc"];
    obj["emb_dt"] = this.embData["emb_dt"];
    obj["status"] = "GENERATED";
    obj["emb_no"] = this.emb_no;
    obj["tender_id"] = this.boqItems[0]["tender_id"];
    obj["project_cd"] = this.selectedProject;
    obj["data"] = [];
    for (let i = 0; i < this.embData.lines.length; i++) {
      this.embData.lines[i]["dtlLines"]["rec_measurment"] =
        this.embData.lines[i]["rec_measurment"];
      this.embData.lines[i]["dtlLines"]["operator"] =
        this.embData.lines[i]["operator"];
      this.embData.lines[i]["calc"] = JSON.stringify(
        this.embData.lines[i]["dtlLines"]
      );
      if (this.embData.lines[i]["operator"] == "SUB") {
        this.embData.lines[i]["quantity"] = this.embData.lines[i]["total"] * -1;
      } else {
        this.embData.lines[i]["quantity"] = this.embData.lines[i]["total"];
      }
      if (this.embData.lines[i]["quantity"] != 0) {
        obj["data"].push(this.embData.lines[i]);
      }
    }
    console.log(obj);
    let resp = await this.referenceDataS.createEMB(obj);

    if (resp["error"] == false) {
      this.embData['id'] = resp.data
      await this.getTenderIdDami()
      this.spinner.hide();
      this.create_emb = false;

    } else {
      this.spinner.hide();
      Swal.fire("Error", "Some Error Occured while creating Emb", "error");
    }
  }



  /*-------------update run time calculate ------------*/
  async update_new(f:any,status:any) {
    this.spinner.show();
    if (this.embData["emb_desc"] == null || this.embData["emb_desc"] == undefined) {
      this.spinner.hide();
      Swal.fire("Warning", "Please Enter Date of Measurement", "warning");
      return;
    }
    console.log(this.embData)
    let obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["create_user_id"] = this.ebillUser.user_id;
    obj["work_order_id"] = this.selectedWork;
    obj["emb_desc"] = this.embData["emb_desc"];
    obj["emb_dt"] = this.embData["emb_dt"];
    obj["id"] = this.embData["id"]
    obj["emb_no"] = this.emb_no
    console.log(obj["emb_no"])
    obj["tender_id"] = this.boqItems[0]["tender_id"];
    obj["project_cd"] = this.selectedProject;
    obj["data"] = [];
    for (let i = 0; i < this.embData.lines.length; i++) {
      this.embData.lines[i]["dtlLines"]["rec_measurment"] =
        this.embData.lines[i]["rec_measurment"];
      this.embData.lines[i]["dtlLines"]["operator"] =
        this.embData.lines[i]["operator"];
      this.embData.lines[i]["calc"] = JSON.stringify(
        this.embData.lines[i]["dtlLines"]
      );
      if (this.embData.lines[i]["operator"] == "SUB") {
        this.embData.lines[i]["quantity"] = this.embData.lines[i]["total"] * -1;
      } else {
        this.embData.lines[i]["quantity"] = this.embData.lines[i]["total"];
      }

      if (this.embData.lines[i]["quantity"] != 0) {
        obj["data"].push(this.embData.lines[i]);
      }
    }

    console.log(this.embData["emd_no"])
    let resp = await this.referenceDataS.updateEMB(obj);
    if (resp["error"] == false) {
      await this.getTenderIdDami()
      this.spinner.hide();
      if(status=='finalSubmit'){
        Swal.fire("Success", "EMB Added  Successfuly", "success");
        this.listbtab()
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Some Error Occured", "error");
    }
  }
  savemeasure() {

    this.lines[this.measureindex]["dtlLines"]["measureobj"] =
      this.measureobj[this.measureindex];
    this.lines[this.measureindex]["dtlLines"]["formul"] =
      this.lines[this.measureindex]["formula"];

    this.calculatelinetotal();
  }
  calculatelinetotal() {
    this.lineTotal = 0;
    for (let i = 0; i < this.lines.length; i++) {
      if (this.lines[i]["operator"] == "ADD") {
        this.lineTotal = this.lineTotal + this.lines[i]["total"];
      } else if (this.lines[i]["operator"] == "SUB") {
        this.lineTotal = this.lineTotal - this.lines[i]["total"];
      }
    }
    this.lineTotal = this.mainService.trunc(this.lineTotal);
  }
  totaline = 0;
  calculate() {
    this.totaline = 0;
    this.lines[this.measureindex]["total"] = 1;
    let obj4:any = Object();
    if (this.lines[this.measureindex]["compute"] == "ACTUAL") {
      for (let i = 0; i < this.measureobj.length; i++) {
        if (this.measureobj[i]["mes"] != undefined) {
          obj4[this.measureobj[i]["measure"]] = this.measureobj[i]["mes"];
        } else {
          obj4[this.measureobj[i]["measure"]] = 0;
        }
      }
    } else if (this.lines[this.measureindex]["compute"] == "AVERAGE") {
      obj4 = new Object();
      var obj21:any = new Object();
      for (let i = 0; i < this.measureobj.length; i++) {
        if (this.measureobj[i]["measure"] == "number") {
          obj4[this.measureobj[i]["measure"]] = this.measureobj[i]["mes"];
          obj21[this.measureobj[i]["measure"]] = 1;
          continue;
        }
        if (this.measureobj[i]["mes"] != undefined) {
          if (obj4[this.measureobj[i]["measure"]] == undefined) {
            obj4[this.measureobj[i]["measure"]] = 0;
            obj21[this.measureobj[i]["measure"]] = 0;
          }
          obj4[this.measureobj[i]["measure"]] += this.measureobj[i]["mes"];
          obj21[this.measureobj[i]["measure"]] += 1;
        } else {
          obj4[this.measureobj[i]["measure"]] = 0;
          obj21[this.measureobj[i]["measure"]] = 1;
        }
      }
      var measure = [];
      for (var j = 0; j < this.allFields.length; j++) {
        if (this.allFields[j]["id"] == this.lines[this.measureindex]["shape"]) {
          var arr = this.allFields[j]["measurement"].split(",");
          measure = [];
          for (let k = 0; k < arr.length; k++) {
            measure.push(arr[k]);
          }
        }
      }
      var obj3 = new Object();
      for (let i = 0; i < measure.length; i++) {
        if (measure[i]) {
          obj4[measure[i]] = obj4[measure[i]] / obj21[measure[i]];
        }
      }
    }

    let cal_str = "";

    for (
      let i = 0;
      i < this.lines[this.measureindex]["dtlLines"]["formula"].length;
      i++
    ) {
      if (!isNaN(this.lines[this.measureindex]["dtlLines"]["formula"][i])) {
        this.lines[this.measureindex]["total"] =
          this.lines[this.measureindex]["total"]
        this.lines[this.measureindex]["dtlLines"]["formula"][i];
        if (i < 1) {
          cal_str = cal_str.concat(
            this.lines[this.measureindex]["dtlLines"]["formula"][i]
          );
        }
        if (
          i > 0 &&
          i < this.lines[this.measureindex]["dtlLines"]["formula"].length
        ) {
          cal_str = cal_str.concat(
            "*" + this.lines[this.measureindex]["dtlLines"]["formula"][i]
          );
        }
      } else {
        if (
          this.lines[this.measureindex]["dtlLines"]["formula"][i] == "number"
        ) {
          var nos =
            obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]].split(
              "x"
            );
          for (var j = 0; j < nos.length; j++) {
            this.lines[this.measureindex]["total"] =
              this.lines[this.measureindex]["total"] * Number(nos[j]);
            if (i < 1 && j < 1) {
              cal_str = cal_str.concat(
                obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]]
              );
            }
            if (
              i > 0 &&
              i < this.lines[this.measureindex]["dtlLines"]["formula"].length
            ) {
              cal_str = cal_str.concat(
                "*" +
                obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]]
              );
            }
          }
        } else {
          this.lines[this.measureindex]["total"] =
            this.lines[this.measureindex]["total"] *
            obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]];
          if (i < 1) {
            cal_str = cal_str.concat(
              obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]]
            );
          }
          if (
            i > 0 &&
            i < this.lines[this.measureindex]["dtlLines"]["formula"].length
          ) {
            cal_str = cal_str.concat(
              "*" +
              obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]]
            );
          }
        }
      }
    }
    let keys:any = Object.keys(obj4);
    console.log(obj4);
    for (let i = 0; i < keys.length; i++) {
      console.log(obj4[keys[i]]);

      if (keys[i] == "number") {
        let number_1:any = eval(obj4[keys[i]]);
        console.log(number_1);
        //oc
        // window[keys[i]] = +number_1;
        //ec
      } else {
        //oc
        // window[keys[i]] = +obj4[keys[i]];
      }
      //ec
    }
    console.log(this.lines[this.measureindex]["dtlLines"]);
    let formul =
      this.lines[this.measureindex]["dtlLines"]["formulastr"] + "*number";
    console.log(eval(formul));
    this.lines[this.measureindex]["formula"] = cal_str;
    this.lines[this.measureindex]["total"] = this.mainService.trunc(
      eval(formul)
    );
    this.totaline = eval(formul);

    this.savemeasure();
  }
  calculate1(l:any) {
    this.c_index = l;
    this.totaline = 0;
    this.measureindex = l;
    console.log(this.measureobj, this.measureobj[l][0]);
    this.lines[this.measureindex]["total"] = 1;
    let obj4:any = Object();
    if (this.lines[this.measureindex]["compute"] == "ACTUAL") {
      for (let i = 0; i < this.measureobj[l].length; i++) {
        if (this.measureobj[l][i]["mes"] != undefined) {
          obj4[this.measureobj[l][i]["measure"]] = this.measureobj[l][i]["mes"];
        } else {
          obj4[this.measureobj[l][i]["measure"]] = 0;
        }
      }
    } else if (this.lines[this.measureindex]["compute"] == "AVERAGE") {
      let obj4:any = new Object();
      var obj21:any = new Object();
      for (let i = 0; i < this.measureobj[l].length; i++) {
        if (this.measureobj[l][i]["measure"] == "number") {
          obj4[this.measureobj[l][i]["measure"]] = this.measureobj[l][i]["mes"];
          obj21[this.measureobj[l][i]["measure"]] = 1;
          continue;
        }
        if (this.measureobj[l][i]["mes"] != undefined) {
          if (obj4[this.measureobj[l][i]["measure"]] == undefined) {
            obj4[this.measureobj[l][i]["measure"]] = 0;
            obj21[this.measureobj[l][i]["measure"]] = 0;
          }
          obj4[this.measureobj[l][i]["measure"]] +=
            this.measureobj[l][i]["mes"];
          obj21[this.measureobj[l][i]["measure"]] += 1;
        } else {
          obj4[this.measureobj[l][i]["measure"]] = 0;
          obj21[this.measureobj[l][i]["measure"]] = 1;
        }
      }
      var measure = [];
      for (var j = 0; j < this.allFields.length; j++) {
        if (this.allFields[j]["id"] == this.lines[this.measureindex]["shape"]) {
          var arr = this.allFields[j]["measurement"].split(",");
          measure = [];
          for (let k = 0; k < arr.length; k++) {
            measure.push(arr[k]);
          }
        }
      }
      var obj3 = new Object();
      for (let i = 0; i < measure.length; i++) {
        if (measure[i]) {
          obj4[measure[i]] = obj4[measure[i]] / obj21[measure[i]];
        }
      }
    }

    let cal_str = "";

    for (
      let i = 0;
      i < this.lines[this.measureindex]["dtlLines"]["formula"].length;
      i++
    ) {
      if (!isNaN(this.lines[this.measureindex]["dtlLines"]["formula"][i])) {
        this.lines[this.measureindex]["total"] =
          this.lines[this.measureindex]["total"] *
          this.lines[this.measureindex]["dtlLines"]["formula"][i];
        if (i < 1) {
          cal_str = cal_str.concat(
            this.lines[this.measureindex]["dtlLines"]["formula"][i]
          );
        }
        if (
          i > 0 &&
          i < this.lines[this.measureindex]["dtlLines"]["formula"].length
        ) {
          cal_str = cal_str.concat(
            "*" + this.lines[this.measureindex]["dtlLines"]["formula"][i]
          );
        }
      } else {
        if (
          this.lines[this.measureindex]["dtlLines"]["formula"][i] == "number"
        ) {
          var nos =
            obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]].split(
              "x"
            );
          for (var j = 0; j < nos.length; j++) {
            this.lines[this.measureindex]["total"] =
              this.lines[this.measureindex]["total"] * Number(nos[j]);
            if (i < 1 && j < 1) {
              cal_str = cal_str.concat(
                obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]]
              );
            }
            if (
              i > 0 &&
              i < this.lines[this.measureindex]["dtlLines"]["formula"].length
            ) {
              cal_str = cal_str.concat(
                "*" +
                obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]]
              );
            }
          }
        } else {
          this.lines[this.measureindex]["total"] =
            this.lines[this.measureindex]["total"] *
            obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]];
          if (i < 1) {
            cal_str = cal_str.concat(
              obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]]
            );
          }
          if (
            i > 0 &&
            i < this.lines[this.measureindex]["dtlLines"]["formula"].length
          ) {
            cal_str = cal_str.concat(
              "*" +
              obj4[this.lines[this.measureindex]["dtlLines"]["formula"][i]]
            );
          }
        }
      }
    }
    let keys:any = Object.keys(obj4);
    console.log(obj4);
    for (let i = 0; i < keys.length; i++) {
      console.log(obj4[keys[i]]);

      if (keys[i] == "number") {
        let number_1:any = eval(obj4[keys[i]]);
        console.log(number_1);
        //oc
        // window[keys[i]] = +number_1;
        //ec
      } else {
        //oc
        // window[keys[i]] = +obj4[keys[i]];
        //ec
      }
    }
    console.log(this.lines[this.measureindex]["dtlLines"]);
    let formul =
      this.lines[this.measureindex]["dtlLines"]["formulastr"] + "*number";
    console.log(eval(formul));
    this.lines[this.measureindex]["formula"] = cal_str;
    this.lines[this.measureindex]["total"] = this.mainService.trunc(
      eval(formul)
    );
    this.totaline = eval(formul);

    this.savemeasure();
  }
  no_of_lines = 1;
  addLine() {
    this.sides = [];
    this.cons = [];
    this.mul = [];
    this.measures = [];
    for (let i = 0; i < this.no_of_lines; i++) {
      this.lines.push({
        line_no: this.lines.length,
        sno: this.selectedItem["sno"],
        line_desc: this.selectedItem["item_desc"],
        unit: this.selectedItem["unit"],
        rate: this.selectedItem["part_rate"],
        total: 0,
        dtlLines: {},
      });
      this.inner_table.push("false");
      this.lines[this.lines.length - 1]["operator"] = "ADD";
    }
    console.log(this.lines);
  }
  addDtlLine(i:any, sd:any) {
    this.lines[i]["dtlLines"][sd].push({ mes: 0, desc: "" });
  }
  deleteLine(i:any) {
    this.lines.splice(i, 1);
    this.calculatelinetotal();
    console.log(this.inner_table);

    this.inner_table.pop();
    console.log(this.measurearr, this.measureobj);

    this.measurearr.splice(i, 1);
    this.measureobj.splice(i, 1);

    console.log(this.measurearr, this.measureobj);

    console.log(this.inner_table);
  }
  copy_obj:any = {
    line_no: -1,
    sno: this.selectedItem["sno"],
    line_desc: this.selectedItem["item_desc"],
    unit: this.selectedItem["unit"],
    rate: this.selectedItem["part_rate"],
    total: 0,
    dtlLines: {},
  };

  copy(ln:any) {
    console.log(ln);
    this.copy_obj = {
      line_no: -1,
      sno: this.selectedItem["sno"],
      line_desc: this.selectedItem["item_desc"],
      unit: this.selectedItem["unit"],
      rate: this.selectedItem["part_rate"],
      total: 0,
      dtlLines: {},
    };
    this.copy_obj = Object.assign({}, ln);
    this.copy_obj["dtlLines"] = Object.assign({}, ln["dtlLines"]);
    console.log(ln["dtlLines"]["measureobj"][0].length);
    console.log(ln["dtlLines"]["measureobj"]);

    if (ln["dtlLines"]["measureobj"]) {
      this.copy_obj["dtlLines"]["measureobj"] = [];
      this.copy_obj["dtlLines"]["measureobj"] = [
        ...ln["dtlLines"]["measureobj"],
      ];
    }
    console.log(this.copy_obj["dtlLines"]["measureobj"]);

    this.snackBar.open("Data Copied!", "Success", {
      duration: 5000,
    });
  }

  paste(i:any) {
    console.log(this.measureobj);
    console.log(this.copy_obj);
    console.log(this.copy_obj["line_no"]);
    let l = this.copy_obj["line_no"];
    this.copy_obj["line_no"] = i;
    this.lines[i] = Object.assign({}, this.copy_obj);
    this.lines[i]["dtlLines"] = Object.assign({}, this.copy_obj["dtlLines"]);
    if (this.copy_obj["dtlLines"]["measureobj"]) {
      this.lines[i]["dtlLines"]["measureobj"] = [];
      for (let j = 0; j < this.copy_obj["dtlLines"]["measureobj"].length; j++) {
        this.lines[i]["dtlLines"]["measureobj"].push(
          Object.assign({}, this.copy_obj["dtlLines"]["measureobj"][j])
        );
      }
    }


    this.measurearr[i] = [...this.lines[i]["dtlLines"]["measures"]];
    this.inner_table[i] = "true";
    this.measureobj[i] = [...this.lines[i]["dtlLines"]["measureobj"]];

    this.calculate1(this.copy_obj["line_no"]);
    this.snackBar.open("Data Pasted!", "Success", {
      duration: 5000,
    });
  }
  and_st_nd_rd_at_Number(i:any) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  async getBoqItems() {
    var obj = {
      b_acct_id: this.b_acct_id,
      tender_id: this.selectedTender["tender_id"],
    };
    var resp = await this.tenderService.getItem(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.boqItems = resp["data"];
      console.log(this.boqItems);
    } else {
      Swal.fire("Oops", "Error while getting Boq Items");
    }
  }
  boqobj:any = {};
  measurecheck:any = {};
  async getEmbTotal() {
    var obj = {
      b_acct_id: this.b_acct_id,
      tender_id: this.selectedTender["tender_id"],
    };
    var resp = await this.embService.getLastEMB(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      var dt = resp["data"];
      console.log(this.boqItems);
      for (var i = 0; i < this.boqItems.length; i++) {
        this.boqItems[i]["part_rate"] = this.boqItems[i]["rate"];
        this.boqItems[i]["remaining_quantity"] =
          this.boqItems[i]["eff_quantity"];
        for (var j = 0; j < dt.length; j++) {
          if (this.boqItems[i]["sno"] == dt[j]["sno"]) {
            if (
              this.measurecheck[this.boqItems[i]["sno"]] == undefined ||
              this.measurecheck[this.boqItems[i]["sno"]] == null
            )
              this.measurecheck[this.boqItems[i]["sno"]] = 0;
            this.measurecheck[this.boqItems[i]["sno"]] += dt[j]["q"];
            this.boqItems[i]["remaining_quantity"] -= dt[j]["q"];
          }
        }
        this.boqItems[i]["remaining_quantity"] = this.mainService.trunc(
          this.boqItems[i]["remaining_quantity"]
        );
      }

      this.datasource = new MatTableDataSource(this.boqItems);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
      for (var i = 0; i < this.boqItems.length; i++) {
        this.boqobj[this.boqItems[i]["sno"]] = this.boqItems[i];
      }
    } else {
      Swal.fire("Oops", "Error while getting previous emb", "error");
    }
  }
  allWList: any = [];

  result:any = [];
  nextClick() {
    this.result = [];
    for (let i = 0; i < this.embData.lines.length; i++) {
      if (this.embData.lines[i]["total"] != 0) {
        this.result.push(this.embData.lines[i]);
        console.log(this.result)
      }
    }
  }
  async getAllFields() {
    this.spinner.show();
    let obj = {};
    var resp = await this.referenceDataS.getGeometry(
      JSON.stringify(this.b_acct_id)
    );
    if (resp["error"] == false) {
      this.allFields = resp.data;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Some Error Occured");
    }
  }
  reset(stepper1: MatStepper) {
    stepper1.selectedIndex = 0;
    stepper1.previous();
  }
  async refresh(stepper1: MatStepper) {
    this.selectedWork = "";
    this.embData = {};
    this.allEmbList = []
    this.obj["path_desc"] = '';
    this.datasource3 = new MatTableDataSource(this.allEmbList);
    this.datasource = new MatTableDataSource(this.allEmbList);
    this.datasource2 = new MatTableDataSource(this.allEmbList);
    this.datasource5 = new MatTableDataSource(this.allEmbList);
  }
  workObj:any = {};
  assignedWorks:any = {};
  async getAllHeadRecord() {
    this.spinner.show();
    var work_ids:any = [];
    work_ids = Object.values(this.CommonDataService.assignedWork);
    for (var i = 0; i < work_ids.length; i++) {
      this.assignedWorks[work_ids[i]] = 1;
    }
  }
  async getAllWorkInfo() {
    this.spinner.show();
    var obj:any = new Object();
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    var resp = await this.MSS.getWorkInfo(JSON.stringify(obj));
    console.log(resp["data"], "all work");
    if (resp["error"] == false) {
      var dt = [];
      var temp = resp["data"];
      for (var i = 0; i < temp.length; i++) {
        if (this.assignedWorks[temp[i]["id"]] != undefined) {
          dt.push(temp[i]);
        }
      }
      this.allWork = dt;
      for (var i = 0; i < this.allWork.length; i++) {
        this.allWork[i]["work_des"] =
          this.allWork[i]["work_order_no"] +
          " - " +
          this.allWork[i]["work_order_name"];
        this.workObj[this.allWork[i]["id"]] = this.allWork[i];
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }
  allProject = [];

  async getAllApprovalStatus() {
    let obj:any = {};
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    obj["status"] = ["UNDERAPPROVAL"];
    obj["designation_cd"] = this.ebillUser.role_cd;
    var resp = await this.approveService.getApprovalStatus(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.mainService.taskcount = resp.data.length;
    } else {
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.datasource2.filter = filterValue.trim().toLowerCase();
  }
  applyFilter4(filterValue: string) {
    this.datasource5.filter = filterValue.trim().toLowerCase();
  }
  async insertForApproval(doc_local_no:any) {
    var obj:any = new Object();
    obj["b_acct_id"] = this.ebillUser["b_acct_id"];
    obj["data"] = this.getApprovalData(doc_local_no);
    var resp = await this.approveService.insertForApproval(obj);
    if (resp["error"] == false) {
    } else {
      this.toastr.error(resp["data"]);
    }
  }

  approvalHierForEMB:any = [];
  async getAllApprovalHier() {
    this.spinner.show();
    let obj:any = {};
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    var resp = await this.approveService.getApprovalHier(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.approvalHierForEMB = [];
      this.spinner.hide();
      var temp = resp.data;

      for (let i = 0; i < temp.length; i++) {
        if ("EMB" == temp[i]["doc_type"]) {
          this.approvalHierForEMB.push(temp[i]);
        }
      }
    } else {
      this.spinner.hide();
    }
  }
  getApprovalData(doc_local_no:any) {
    var arr = [];
    for (let i = 0; i < this.approvalHierForEMB.length; i++) {
      var obj:any = new Object();
      obj["level_of_approval"] =
        this.approvalHierForEMB[i]["level_of_approval"];
      obj["order_of_approval"] =
        this.approvalHierForEMB[i]["order_of_approval"];
      obj["doc_type"] = "EMB";
      obj["doc_desc"] = "EMB";
      if (this.approvalHierForEMB[i]["order_of_approval"] == "F") {
        obj["status"] = "UNDERAPPROVAL";
      } else {
        obj["status"] = "PENDING";
      }
      obj["doc_local_no"] = doc_local_no;
      obj["create_user_id"] = this.ebillUser.user_id;
      arr.push(obj);
    }

    return arr;
  }
  workflowuser = [];
  rolecdtolevelobj = {};
  async getWorkflowloguser() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    obj["status"] = "ACTIVE";
    obj["node_cd"] = this.selectedTender["work_id"];
    console.log(obj, this.selectedTender);
    var resp = await this.mainService.getUsersForModuleWorkflow(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.spinner.hide();
      console.log(resp["data"]);
      this.workflowuser = resp["data"];
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  statusArr:any = [];
  selectedEle:any;
  async viewStatus(element:any) {
    this.selectedEle = Object.assign({}, element);
    var obj:any = new Object();
    obj["doc_local_no"] = element.id;
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    obj["doc_type"] = "EMB";
    obj["module_cd"] = "EMB";
    var resp = await this.mainService.getWorkflowlog(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      await this.getWorkflowloguser();
      console.log(this.userRoles, resp.data);
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["user_id"] == -1) {
          resp.data[i]["name"] = "Contractor";
          resp.data[i]["role_cd"] = "Contractor";
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (
            resp.data[i]["user_id"] == this.workflowuser[j]["user_id"] &&
            resp.data[i]["role_cd"] == this.workflowuser[j]["role_cd"]
          ) {
            resp.data[i]["name"] = this.workflowuser[j]["emp_name"];
            resp.data[i]["role_cd"] = this.workflowuser[j]["role_cd"];
          }
        }
        if (resp.data[i]["name"] == undefined) {
          resp.data[i]["name"] = "";
        }
      }
      this.statusArr = resp.data;
      console.log(this.statusArr);
      $("#myModal1").modal("show");
    } else {
      this.toastr.error(resp["data"]);
    }
  }
  certificate() {
    var txt =
      "LUCKNOW DEVELOPMENT AUTHORITY" +
      "(" +
      "LDA" +
      ") , LUCKNOW\n" +
      "VIPIN KHAND,GOMTI NAGAR , LUCKNOW - 226010";
    var dd:any = {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        var arr = [];
        var obj = {
          text: txt,
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj);
        return arr;
      },
      pageOrientation: "portrait",
      pageMargins: [40, 60, 40, 60],
      content: [],
    };

    var header1 = {
      columns: [
        {
          width: "*",
          text:
            "APPROVAL LOG FOR EMB FOR FILE/EMB NO - " +
            this.selectedTender["already_emb"],
          bold: true,
          alignment: "center",
        },
      ],
    };

    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    var tbl:any = {
      fontSize: 10,
      table: {
        headerRows: 0,
        widths: [370, 375],

        body: [],
      },
    };

    var workObj = this.workObj[this.selectedTender["work_id"]];
    var workObj = this.workObj[this.selectedTender["work_id"]];

    var arr = [];
    arr.push({ text: "Name Of Work ", bold: true });
    arr.push(workObj["work_order_name"]);
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Work Order No ", bold: true });
    arr.push(workObj["work_order_no"]);
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Last Date of Measurement ", bold: true });
    arr.push(this.mainService.dateformatchange(this.selectedEle["emb_dt"]));
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Last Measurement No ", bold: true });
    arr.push(this.selectedEle["emb_no"]);
    tbl.table.body.push(arr);
    dd.content.push(tbl);

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    var tbl1 = {
      fontSize: 15,
      table: {
        headerRows: 1,
        widths: [70, 124, 124, 124, 144, 125],

        body: [
          ["User ID", "User Name", "Designation", "Action", "Date", "Remark"],
        ],
      },
    };
    for (var i = 0; i < this.statusArr.length; i++) {
      var arr = [];
      arr.push(this.statusArr[i]["user_id"]);
      arr.push(this.statusArr[i]["user_name"]);
      if (this.statusArr[i]["role_cd"] != "Contractor") {
        arr.push(
          this.mainService.roleCodeToRoleDescription[
          this.statusArr[i]["role_cd"]
          ]
        );
      } else {
        arr.push(this.statusArr[i]["role_cd"]);
      }
      arr.push(this.statusArr[i]["status"]);
      arr.push(this.statusArr[i]["timestamp"]);
      arr.push(this.statusArr[i]["remark"]);
      tbl1.table.body.push(arr);
    }
    dd.content.push(tbl1);

    pdfMake.createPdf(dd).download("emb-cert" + ".pdf");
  }

  async open_update(element:any) {
    this.mylist = false;
    this.list_flag = false;
    this.myapprov = false;
    this.update_flag = true;
    this.create_flag = false;
    this.app_flag = false;

    this.lines = [];
    this.calculate_flage = true;

    this.embData = Object.assign({}, element);
    if (this.embData["emb_dt"] != undefined) {
      this.embData["emb_dt"] = element["emb_dt"].split("T")[0];
    }
    console.log(element);
    await this.getBoqItems();
    await this.getEmbTotal();
    var obj = {
      b_acct_id: this.b_acct_id,
      tender_id: this.selectedTender["tender_id"],
      emb_no: element["emb_no"],
    };
    var resp = await this.referenceDataS.getEmbforupdate(JSON.stringify(obj));
    if (resp["error"] == false) {
      var dt = resp.data;
      this.embData.lines = [];
      for (let i = 0; i < dt.length; i++) {
        dt[i]["dtlLines"] = JSON.parse(dt[i]["calc"]);
        dt[i]["rec_measurment"] = dt[i]["dtlLines"]["rec_measurment"];
        dt[i]["operator"] = dt[i]["dtlLines"]["operator"];

        dt[i]["shape"] = Number(dt[i]["shape"]);
        if (dt[i]["operator"] == "ADD") {
          dt[i]["total"] = dt[i]["quantity"];
        } else if (dt[i]["operator"] == "SUB") {
          dt[i]["quantity"] = dt[i]["quantity"] * -1;
          dt[i]["total"] = dt[i]["quantity"];
        }
        this.embData.lines.push(dt[i]);
      }
      console.log("2", this.embData)
      for (var i = 0; i < this.boqItems.length; i++) {

        this.boqItems[i]["ebill_quantity"] = 0;
        for (var j = 0; j < dt.length; j++) {
          if (this.boqItems[i]["sno"] == dt[j]["sno"]) {
            this.boqItems[i]["remaining_quantity"] += dt[j]["quantity"];
            this.boqItems[i]["remaining_quantity"] = this.mainService.trunc(
              this.boqItems[i]["remaining_quantity"]
            );

            if (dt[j]["operator"] == "ADD") {
              this.boqItems[i]["ebill_quantity"] += dt[j]["quantity"];
            } else if (dt[j]["operator"] == "SUB") {
              this.boqItems[i]["ebill_quantity"] -= dt[j]["quantity"];
            }
          }
        }
      }
    } else {
      this.toastr.error(resp["data"]);
    }
    var projectDesc = "";
    var workDesc = "";

    for (var i = 0; i < this.allWork.length; i++) {
      if (this.allWork[i]["id"] == this.selectedWork) {
        workDesc = this.allWork[i]["work_order_name"];
      }
    }
    //set work`s path description
    let planing_path = "";
    for (let i = 0; i < this.allWork.length; i++) {
      if (this.allWork[i]["id"] == this.selectedWork) {
        this.obj["node_cd"] = this.allWork[i]["node_cd"];
      }
    }
    this.obj["path_desc"] = null;
    if (!this.selectedWork) {
      this.obj["path_desc"] = null;
      this.obj["node_cd"] = null;
    }

    this.obj["path_desc"] = this.obj["node_cd"]
    console.log(this.obj, "path desc");

    this.embData["work_order"] = workDesc;
    this.datasource3 = new MatTableDataSource(this.boqItems);
    this.datasource3.sort = this.sort;
    this.datasource3.paginator = this.paginator;
    this.create_emb = false;

  }
  async deleteemb(element:any) {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element);
      }
    });
  }
  async finaldelete(element:any) {
    this.spinner.show();
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["id"] = element["id"];
    obj["tender_id"] = element["tender_id"];
    obj["emb_no"] = element["emb_no"];
    var resp = await this.referenceDataS.deleteEMB(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      if (element["status"] != "GENERATED") {
        this.tender_apprdata = await this.getdocumentstatus(element);
        await this.pendingrevoked();
      }
      Swal.fire("Success", "EMB Deleted Successfuly", "success");
      await this.getTenderId();
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Some Error Occured while deleting Emb", "error");
    }
  }

  async update() {
    this.spinner.show();
    if (this.embData["emb_dt"] == null || this.embData["emb_dt"] == undefined) {
      this.spinner.hide();
      Swal.fire("Warning", "Please Enter Date of Measurement", "warning");
      return;
    }
    let obj:any = new Object();
    console.log(this.embData)
    obj["b_acct_id"] = this.b_acct_id;
    obj["create_user_id"] = this.ebillUser.user_id;
    obj["work_order_id"] = this.selectedWork;
    obj["emb_desc"] = this.embData["emb_desc"];
    obj["emb_dt"] = this.embData["emb_dt"];
    obj["id"] = this.embData["id"];
    obj["emb_no"] = this.embData["emb_no"];
    obj["tender_id"] = this.boqItems[0]["tender_id"];
    obj["project_cd"] = this.selectedProject;
    obj["data"] = [];
    for (let i = 0; i < this.embData.lines.length; i++) {
      this.embData.lines[i]["dtlLines"]["rec_measurment"] =
        this.embData.lines[i]["rec_measurment"];
      this.embData.lines[i]["dtlLines"]["operator"] =
        this.embData.lines[i]["operator"];
      this.embData.lines[i]["calc"] = JSON.stringify(
        this.embData.lines[i]["dtlLines"]
      );
      if (this.embData.lines[i]["operator"] == "SUB") {
        this.embData.lines[i]["quantity"] = this.embData.lines[i]["total"] * -1;
      } else {
        this.embData.lines[i]["quantity"] = this.embData.lines[i]["total"];
      }

      if (this.embData.lines[i]["quantity"] != 0) {
        obj["data"].push(this.embData.lines[i]);
      }
    }
    let resp = await this.referenceDataS.updateEMB(obj);
    if (resp["error"] == false) {
      await this.getTenderId();
      this.spinner.hide();
      Swal.fire("Success", "Emb Update Successfully ", "success");
      this.selectedWork = '';
      this.datasource5 = [];
      this.list_flag = true;
      this.update_flag = false;
       } else {
      this.spinner.hide();
      Swal.fire("Error", "Some Error Occured", "error");
    }
  }
  table_header = [
    { key: "s_no", value: "S No.", width: 10 },
    { key: "line_desc", value: "Line Description", width: 20 },
    { key: "quantity", value: "Work Order Quantity", width: 10 },
    { key: "rate", value: "Work Order Rate", width: 10 },
    { key: "already_m_q", value: "Already Measured Quantity", width: 10 },
    { key: "cal_quantity", value: "Calculated Quantity", width: 10 },
    { key: "part_rate", value: "Rate", width: 10 },
    { key: "amount", value: "Amount", width: 10 },
  ];
  emb_desc:any;
  work_order_id:any;
  project_cd:any;
  print_data:any = [];

  /*  async print(element) {
     for (let i = 0; i < this.allWork.length; i++) {
       if (element["work_order_id"] == this.allWork[i]["id"]) {
         this.work_order_id =
           this.allWork[i]["id"] + " - " + this.allWork[i]["work_order_name"];
       }
     }
     for (
       let i = 0;
       i < this.mainService.codeValueTechObj["EMB001"].length;
       i++
     ) {
       if (
         element["project_cd"] ==
         this.mainService.codeValueTechObj["EMB001"][i]["code"]
       ) {
         this.project_cd =
           this.mainService.codeValueTechObj["EMB001"][i]["value"] +
           " - " +
           this.mainService.codeValueTechObj["EMB001"][i]["value"];
       }
     }
     var obj = {
       b_acct_id: this.b_acct_id,
       tender_id: element["tender_id"],
       emb_no: element["emb_no"],
     };
     var resp = await this.referenceDataS.getEmbforupdate(JSON.stringify(obj));
     var dtlLines = {};
     for (let i = 0; i < resp["data"].length; i++) {
       if (dtlLines[resp["data"][i]["sno"]] == undefined) {
         dtlLines[resp["data"][i]["sno"]] = [];
         resp["data"][i]["dtlLines"] = JSON.parse(resp["data"][i]["calc"]);
         dtlLines[resp["data"][i]["sno"]].push(resp["data"][i]);
       } else {
         resp["data"][i]["dtlLines"] = JSON.parse(resp["data"][i]["calc"]);
         dtlLines[resp["data"][i]["sno"]].push(resp["data"][i]);
       }
     }
     var obj = {
       b_acct_id: this.b_acct_id,
       tender_id: element["tender_id"],
       emb_no: element["emb_no"],
     };
     var res = await this.referenceDataS.getembforprint(JSON.stringify(obj));

     let data = res.data;
     if (element["emb_dt"]) {
       element["emb_dt"] = element["emb_dt"];
     }

     var txt = "EMB Report";
     var dd = {
       pageSize: "A3",
       header: function (currentPage, pageCount) {
         var arr = [];
         var obj = {
           text: txt + "  Page No. - " + currentPage,
           alignment: "center",
           margin: [72, 40],
           fontSize: 15,
           bold: true,
         };
         arr.push(obj);
         return arr;
       },
       pageOrientation: "landscape",
       pageMargins: [40, 60, 40, 60],
       content: [],
     };

     var header1 = {
       columns: [
         {
           width: "*",
           text: "EMB NO. :",
           bold: true,
         },

         {
           width: "*",
           text: element["emb_no"],
         },
         {
           width: "*",
           text: "PROJECT :",
           bold: true,
         },

         {
           width: "*",
           text: this.project_cd,
         },
       ],
     };
     var header2 = {
       columns: [
         {
           width: "*",
           text: "DATE OF MEASUREMENT:",
           bold: true,
         },
         {
           width: "*",
           text: element["emb_dt"],
         },
         {
           width: "*",
           text: "WORK :",
           bold: true,
         },

         {
           width: "*",
           text: this.work_order_id,
         },
       ],
     };
     var header3 = {
       columns: [
         {
           width: "*",
           text: "EMB DESCRIPTION :",
           bold: true,
         },
         {
           width: "*",
           text: element["emb_desc"],
         },
         {
           width: "*",
           text: "",
         },

         {
           width: "*",
           text: "",
         },
       ],
     };

     dd.content.push({
       canvas: [
         { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
       ],
     });
     dd.content.push({ text: " " });
     dd.content.push(header1);
     dd.content.push({ text: " " });
     dd.content.push(header2);
     dd.content.push({ text: " " });
     dd.content.push(header3);
     dd.content.push({ text: " " });
     dd.content.push({
       canvas: [
         { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
       ],
     });
     var tbl = {
       fontSize: 10,
       table: {
         headerRows: 1,
         widths: [70, 300, 100, 100, 120, 120, 120, 120],
         body: [
           [
             { text: "S.NO", alignment: "left", bold: true },
             { text: "Line Description", alignment: "left", bold: true },
             { text: "Work Order Quantity ", alignment: "right", bold: true },
             { text: "Work Order Rate", alignment: "right", bold: true },
             {
               text: "Already Measured  Quantity",
               alignment: "right",
               bold: true,
             },
             { text: "Measurement", alignment: "right", bold: true },
             { text: "Calculated Quantity", alignment: "right", bold: true },
             { text: "Next To Pay Quantity", alignment: "right", bold: true },
           ],
         ],
       },
     };
     dd.content.push(tbl);
     for (var i = 0; i < data.length; i++) {
       var arr = [];
       arr.push({ text: data[i]["sno"], bold: true });
       arr.push({ text: data[i]["item_desc"], alignment: "left", bold: true });
       arr.push({ text: data[i]["woq"], bold: true });
       arr.push({ text: data[i]["wor"], alignment: "right", bold: true });
       arr.push({ text: data[i]["al_quan"], alignment: "right", bold: true });
       arr.push("");

       arr.push({ text: data[i]["cal_quan"], alignment: "right", bold: true });
       var alreadyCal = 0;
       var currCal = 0;
       if (!isNaN(Number(data[i]["al_quan"]))) {
         alreadyCal = Number(data[i]["al_quan"]);
       }

       if (!isNaN(Number(data[i]["cal_quan"]))) {
         currCal = Number(data[i]["cal_quan"]);
       }
       var nextToPay = 0;
       nextToPay = alreadyCal + currCal - Number(data[i]["woq"]);
       if (Number(data[i]["woq"]) > alreadyCal + currCal) {
         arr.push({ text: "0", alignment: "right", bold: true });
       } else {
         arr.push({
           text: this.mainService.trunc(nextToPay),
           alignment: "right",
           bold: true,
         });
       }
       dd.content[dd.content.length - 1].table.body.push(arr);
       let data2 = [];
       let inner_data = [];
       if (dtlLines[data[i]["sno"]] != undefined) {
         inner_data = dtlLines[data[i]["sno"]];
       } else {
         inner_data = [];
       }

       var quantity = 0;
       for (let n = 0; n < inner_data.length; n++) {
         let ob = {};
         if (inner_data[n]["measure_dt"]) {
           inner_data[n]["measure_dt"] = this.mainService.dateformatchange(
             inner_data[n]["measure_dt"]
           );
         }
         if (inner_data[n]["measure_desc"]) {
           ob["line_desc"] =
             "Date :" +
             inner_data[n]["measure_dt"] +
             "  " +
             inner_data[n]["measure_desc"];
         } else {
           ob["line_desc"] = "Date :" + inner_data[n]["measure_dt"];
         }
         if (inner_data[n]["quantity"]) {
           ob["quantity"] = data[i]["quantity"];
         } else {
           ob["quantity"] = data[i]["quantity"];
         }
         if (inner_data[n]["dtlLines"]["formul"] != undefined) {
           ob["cal_formula"] = inner_data[n]["dtlLines"]["formul"];
         } else {
           ob["cal_formula"] = 0;
         }
         ob["s_no"] = "";
         ob["rate"] = data[i]["rate"];
         ob["part_rate"] = data[i]["part_rate"];
         ob["already_m_q"] = Number(data[i]["al_quan"]) + quantity;
         ob["ebill_quantity"] = inner_data[n]["quantity"];
         ob["lime_amt_without_tax"] = "";
         ob["formula"] = inner_data[n]["formula"];
         data2.push(ob);
         quantity = Number(inner_data[n]["quantity"]);
       }
       let arr2 = [];

       for (let n = 0; n < data2.length; n++) {
         arr2 = [];
         arr2.push(data2[n]["s_no"]);
         arr2.push({
           text: data2[n]["line_desc"],
           alignment: "right",
           colSpan: 3,
         });
         arr2.push("");
         arr2.push("");
         arr2.push({ text: data2[n]["already_m_q"], alignment: "right" });
         arr2.push({ text: data2[n]["cal_formula"], alignment: "right" });
         arr2.push({ text: data2[n]["ebill_quantity"], alignment: "right" });
         arr2.push({ text: "", bold: true });
         dd.content[dd.content.length - 1].table.body.push(arr2);
       }
     }
     dd.content.push({ text: " " });
     this.spinner.hide();
     pdfMake.createPdf(dd).download("EMBReport");
   } */
  async print1() {

    if (this.allEmbList.length != 0) {
      var element = this.allEmbList[this.allEmbList.length - 1];
      this.embPrintService.print1(element['emb_no'], element['work_order_id'])
    } else {
      Swal.fire("warning", "No Emb Found..", 'warning');
    }

    return;

    /*
        var element = this.allEmbList[this.allEmbList.length - 1];
        for (let i = 0; i < this.allWork.length; i++) {
          if (element["work_order_id"] == this.allWork[i]["id"]) {
            this.work_order_id = this.allWork[i]["work_order_name"];
          }
        }

        var obj = {
          b_acct_id: this.b_acct_id,
          tender_id: element["tender_id"],
          emb_no: element["emb_no"],
        };
        var resp = await this.referenceDataS.getdataforprint(JSON.stringify(obj));
        var dtlLines = {};
        for (let i = 0; i < resp["data"].length; i++) {
          if (dtlLines[resp["data"][i]["sno"]] == undefined) {
            dtlLines[resp["data"][i]["sno"]] = [];
            resp["data"][i]["dtlLines"] = JSON.parse(resp["data"][i]["calc"]);
            dtlLines[resp["data"][i]["sno"]].push(resp["data"][i]);
          } else {
            resp["data"][i]["dtlLines"] = JSON.parse(resp["data"][i]["calc"]);
            dtlLines[resp["data"][i]["sno"]].push(resp["data"][i]);
          }
        }
        var obj = {
          b_acct_id: this.b_acct_id,
          tender_id: element["tender_id"],
          emb_no: element["emb_no"],
        };
        var res = await this.referenceDataS.getembforprint(JSON.stringify(obj));
        if (element["emb_dt"]) {
          element["emb_dt"] = element["emb_dt"];
        }
        let data = res.data;
        var pay_quan = await this.embService.getembbillforprint(
          JSON.stringify(obj)
        );
        var pay_quanobj = {};
        for (let i = 0; i < pay_quan["data"].length; i++) {
          pay_quanobj[pay_quan["data"][i]["sno"]] = pay_quan["data"][i]["q"];
        }
        var txt = "EMB Report for EMB NO - " + this.selectedTender["already_emb"];
        var dd = {
          pageSize: "A3",
          header: function (currentPage, pageCount) {
            var arr = [];
            var obj = {
              text: txt + "  Page No. - " + currentPage,
              alignment: "center",
              margin: [72, 40],
              fontSize: 15,
              bold: true,
            };
            arr.push(obj);
            return arr;
          },
          pageOrientation: "landscape",
          pageMargins: [40, 60, 40, 60],
          content: [],
        };
        var tbl1 = {
          fontSize: 10,
          table: {
            headerRows: 0,
            widths: [526, 570],

            body: [],
          },
        };
        var workObj = this.workObj[this.selectedTender["work_id"]];


        var arr = [];
        arr.push({ text: "Name Of Work ", bold: true });
        arr.push(workObj["work_order_name"]);
        tbl1.table.body.push(arr);

        var arr = [];
        arr.push({ text: "Work Order No ", bold: true });
        arr.push(workObj["work_order_no"]);
        tbl1.table.body.push(arr);

        var arr = [];
        arr.push({ text: "Last Date of Measurement ", bold: true });
        arr.push(this.mainService.dateformatchange(element["emb_dt"]));
        tbl1.table.body.push(arr);

        dd.content.push({ text: " " });
        dd.content.push(tbl1);

        dd.content.push({ text: " " });
        dd.content.push({ text: " " });

        dd.content.push({
          canvas: [
            { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
          ],
        });
        var tbl = {
          fontSize: 10,
          table: {
            headerRows: 2,
            widths: [30, 250, 60, 60, 100, 40, 50, 50, 50, 70, 65, 60, 50, 50],
            body: [
              [
                { text: "S NO.", bold: true },
                { text: "Line Description", alignment: "left", bold: true },
                { text: "Work Order Quantity ", alignment: "right", bold: true },
                {
                  text: "Already Measured  Quantity",
                  alignment: "right",
                  bold: true,
                },
                {
                  text: "Measurement",
                  bold: true,
                  colSpan: 5,
                  alignment: "center",
                },
                {},
                {},
                {},
                {},
                { text: "Calculated Quantity", alignment: "right", bold: true },
                { text: "Up to date Qty", alignment: "right", bold: true },
                { text: "Paid Last Qty", alignment: "right", bold: true },
                { text: "To Pay Qty", alignment: "right", bold: true },
                { text: "Excess for BOQ QTY", alignment: "right", bold: true },
              ],
              [
                { text: "", bold: true },
                { text: "", alignment: "left", bold: true },
                { text: "", alignment: "right", bold: true },
                { text: "", alignment: "right", bold: true },
                { text: "NO", bold: true },
                { text: "L", bold: true },
                { text: "B", bold: true },
                { text: "H", bold: true },
                { text: "Unit Wieght", bold: true },
                { text: "", alignment: "right", bold: true },
                { text: "", alignment: "right", bold: true },
                { text: "", alignment: "right", bold: true },
                { text: "", alignment: "right", bold: true },
                { text: "", alignment: "right", bold: true },
              ],
            ],
          },
        };

        dd.content.push(tbl);
        for (var i = 0; i < data.length; i++) {
          if (pay_quanobj[data[i]["sno"]] == undefined) {
            pay_quanobj[data[i]["sno"]] = 0;
          }
          var arr = [];
          arr.push({ text: data[i]["sno"], bold: true });
          arr.push({ text: data[i]["item_desc"], alignment: "left", bold: true });
          arr.push({
            text: this.mainService.trunc(
              data[i]["eff_quantity"] + data[i]["already_measured"]
            ),
            alignment: "right",
            bold: true,
          });
          arr.push({
            text: data[i]["already_measured"],
            alignment: "right",
            bold: true,
          });
          arr.push("");
          arr.push("");
          arr.push("");
          arr.push("");
          arr.push("");
          arr.push("");
          arr.push("");
          arr.push("");
          arr.push("");
          arr.push("");
          dd.content[dd.content.length - 1].table.body.push(arr);
          let inner_data = [];
          if (dtlLines[data[i]["sno"]] != undefined) {
            inner_data = dtlLines[data[i]["sno"]];
          } else {
            inner_data = [];
          }
          var obb = {};
          var flag = [];
          var flag1 = [];
          for (let j = 0; j < inner_data.length; j++) {
            if (!flag.includes(inner_data[j]["measure_dt"])) {
              obb[inner_data[j]["measure_dt"]] = {};
              obb[inner_data[j]["measure_dt"]][
                inner_data[j]["dtlLines"]["rec_measurment"]
              ] = [];
              obb[inner_data[j]["measure_dt"]][
                inner_data[j]["dtlLines"]["rec_measurment"]
              ].push(inner_data[j]);
              flag.push(inner_data[j]["measure_dt"]);
            } else {
              if (
                obb[inner_data[j]["measure_dt"]][
                inner_data[j]["dtlLines"]["rec_measurment"]
                ] == undefined
              ) {
                obb[inner_data[j]["measure_dt"]][
                  inner_data[j]["dtlLines"]["rec_measurment"]
                ] = [];
              }
              obb[inner_data[j]["measure_dt"]][
                inner_data[j]["dtlLines"]["rec_measurment"]
              ].push(inner_data[j]);
            }
          }
          var arr12 = Object.keys(obb);
          var quan = 0;
          quan = data[i]["already_measured"];
          for (let j = 0; j < arr12.length; j++) {
            var arr321 = Object.keys(obb[arr12[j]]);

            for (let k = 0; k < arr321.length; k++) {
              var totaal = 0;
              var ar = [];
              ar.push("");
              ar.push({ text: arr321[k], alignment: "left", bold: true });
              ar.push("");
              ar.push("");
              ar.push("");
              ar.push("");
              ar.push("");
              ar.push("");
              ar.push("");
              ar.push("");
              ar.push("");
              ar.push("");
              ar.push("");
              ar.push("");
              dd.content[dd.content.length - 1].table.body.push(ar);
              var date = [];
              date.push("");
              date.push({
                text:
                  "Measurment Date " + this.mainService.dateformatchange(arr12[j]),
                alignment: "left",
                bold: true,
              });
              date.push("");
              date.push("");
              date.push("");
              date.push("");
              date.push("");
              date.push("");
              date.push("");
              date.push("");
              date.push("");
              date.push("");
              date.push("");
              date.push("");
              dd.content[dd.content.length - 1].table.body.push(date);
              var arr3210 = obb[arr12[j]][arr321[k]];

              for (let l = 0; l < arr3210.length; l++) {
                var measure = [];
                measure.push("");
                measure.push({
                  text: arr3210[l]["measure_desc"],
                  alignment: "left",
                });
                measure.push("");
                measure.push({ text: quan, alignment: "right" });
                quan = this.mainService.trunc(quan + arr3210[l]["quantity"]);
                totaal = this.mainService.trunc(totaal + arr3210[l]["quantity"]);
                var number = 1;
                var length = null;
                var lengthcount = 1;
                var heightcount = 1;
                var widthcount = 1;
                var height = null;
                var width = null;
                var cons = null;
                var radius = null;
                var radiusCount = 1;
                var nom = null;
                for (
                  let m = 0;
                  m < arr3210[l]["dtlLines"]["measureobj"].length;
                  m++
                ) {
                  if (
                    arr3210[l]["dtlLines"]["measureobj"][m]["measure"] == "number"
                  ) {
                    number = arr3210[l]["dtlLines"]["measureobj"][m]["mes"];
                  } else if (
                    arr3210[l]["dtlLines"]["measureobj"][m]["measure"] == "length"
                  ) {
                    if (length != null) {
                      length =
                        length +
                        "+" +
                        arr3210[l]["dtlLines"]["measureobj"][m]["mes"];
                      lengthcount = lengthcount + 1;
                    } else {
                      length = arr3210[l]["dtlLines"]["measureobj"][m]["mes"];
                    }
                  } else if (
                    arr3210[l]["dtlLines"]["measureobj"][m]["measure"] == "height"
                  ) {
                    if (height != null) {
                      height =
                        height +
                        "+" +
                        arr3210[l]["dtlLines"]["measureobj"][m]["mes"];
                      heightcount = heightcount + 1;
                    } else {
                      height = arr3210[l]["dtlLines"]["measureobj"][m]["mes"];
                    }
                  } else if (
                    arr3210[l]["dtlLines"]["measureobj"][m]["measure"] == "width"
                  ) {
                    if (width != null) {
                      width =
                        width +
                        "+" +
                        arr3210[l]["dtlLines"]["measureobj"][m]["mes"];
                      widthcount = widthcount + 1;
                    } else {
                      width = arr3210[l]["dtlLines"]["measureobj"][m]["mes"];
                    }
                  } else if (
                    arr3210[l]["dtlLines"]["measureobj"][m]["measure"] ==
                    "Unitwieght"
                  ) {
                    cons = arr3210[l]["dtlLines"]["measureobj"][m]["mes"];
                  } else if (
                    arr3210[l]["dtlLines"]["measureobj"][m]["measure"] == "Const"
                  ) {
                    cons = arr3210[l]["dtlLines"]["measureobj"][m]["mes"];
                  }
                }
                for (let m = 0; m < arr3210[l]["dtlLines"]["formula"].length; m++) {
                  if (!isNaN(arr3210[l]["dtlLines"]["formula"][m])) {
                    cons = arr3210[l]["dtlLines"]["formula"][m];
                  }
                }
                var sideobj = new Object();
                var sideobjcount = new Object();

                for (let m = 0; m < arr3210[l]["dtlLines"]["formula"].length; m++) {
                  if (
                    isNaN(arr3210[l]["dtlLines"]["formula"][m]) &&
                    arr3210[l]["dtlLines"]["formula"][m] != "number" &&
                    arr3210[l]["dtlLines"]["formula"][m] != "Unitwieght" &&
                    arr3210[l]["dtlLines"]["formula"][m] != "Const"
                  ) {
                    if (
                      sideobj[arr3210[l]["dtlLines"]["formula"][m]] != undefined
                    ) {
                      sideobj[arr3210[l]["dtlLines"]["formula"][m] + m] =
                        sideobj[arr3210[l]["dtlLines"]["formula"][m]];
                      sideobjcount[arr3210[l]["dtlLines"]["formula"][m] + m] =
                        sideobjcount[arr3210[l]["dtlLines"]["formula"][m]];
                      sideobj[arr3210[l]["dtlLines"]["formula"][m]] = undefined;
                    }
                    for (
                      let n = 0;
                      n < arr3210[l]["dtlLines"]["measureobj"].length;
                      n++
                    ) {
                      if (
                        arr3210[l]["dtlLines"]["measureobj"][n]["measure"] ==
                        arr3210[l]["dtlLines"]["formula"][m]
                      ) {
                        if (
                          sideobj[arr3210[l]["dtlLines"]["formula"][m]] != undefined
                        ) {
                          sideobj[arr3210[l]["dtlLines"]["formula"][m]] =
                            sideobj[arr3210[l]["dtlLines"]["formula"][m]] +
                            "+" +
                            arr3210[l]["dtlLines"]["measureobj"][n]["mes"];
                          sideobjcount[arr3210[l]["dtlLines"]["formula"][m]] =
                            sideobjcount[arr3210[l]["dtlLines"]["formula"][m]] + 1;
                        } else {
                          sideobj[arr3210[l]["dtlLines"]["formula"][m]] =
                            arr3210[l]["dtlLines"]["measureobj"][n]["mes"];
                          sideobjcount[arr3210[l]["dtlLines"]["formula"][m]] = 1;
                        }
                      }
                    }
                  }
                }
                measure.push({ text: number, alignment: "right" });
                var sidepbjarr = Object.keys(sideobj);
                if (sidepbjarr.length < 3) {
                  for (let o = 0; o < sidepbjarr.length; o++) {
                    if (sideobjcount[sidepbjarr[o]] > 1) {
                      measure.push({
                        text:
                          "(" +
                          sideobj[sidepbjarr[o]] +
                          ")/" +
                          sideobjcount[sidepbjarr[o]],
                        alignment: "right",
                      });
                    } else {
                      measure.push({
                        text: sideobj[sidepbjarr[o]],
                        alignment: "right",
                      });
                    }
                  }
                  for (let o = 0; o < 3 - sidepbjarr.length; o++) {
                    measure.push("");
                  }
                } else {
                  for (let o = 0; o < 3; o++) {
                    if (sideobjcount[sidepbjarr[o]] > 1) {
                      measure.push({
                        text:
                          "(" +
                          sideobj[sidepbjarr[o]] +
                          ")/" +
                          sideobjcount[sidepbjarr[o]],
                        alignment: "right",
                      });
                    } else {
                      measure.push({
                        text: sideobj[sidepbjarr[o]],
                        alignment: "right",
                      });
                    }
                  }
                }
                measure.push({ text: cons, alignment: "right" });
                measure.push({ text: arr3210[l]["quantity"], alignment: "right" });
                measure.push("");
                measure.push("");
                measure.push("");
                measure.push("");
                dd.content[dd.content.length - 1].table.body.push(measure);
              }
              var total = [];
              total.push("");
              total.push({ text: "S. TOTAL  ", alignment: "left", bold: true });
              total.push("");
              total.push({
                text: this.mainService.trunc(quan - totaal),
                alignment: "right",
                bold: true,
              });

              total.push("");
              total.push("");
              total.push("");
              total.push("");
              total.push("");
              total.push({ text: totaal, alignment: "right", bold: true });

              total.push({ text: quan, alignment: "right", bold: true });

              //new Code
              console.log(arr12.length, j);
              if (j < arr12.length - 1 || k < arr321.length - 1) {
                total.push("");
                total.push("");
              } else {
                total.push({
                  text: this.mainService.trunc(
                    pay_quanobj[data[i]["sno"]] + data[i]["already_measured"]
                  ),
                  alignment: "right",
                  bold: true,
                });

                //50,10,70
                if (data[i]["eff_quantity"] + data[i]["already_measured"] > 0) {
                  if (
                    data[i]["eff_quantity"] + data[i]["already_measured"] - quan <
                    0
                  ) {
                    total.push({
                      text: this.mainService.trunc(
                        data[i]["eff_quantity"] - pay_quanobj[data[i]["sno"]]
                      ),
                      alignment: "right",
                      bold: true,
                    });
                  } else {
                    total.push({
                      text: this.mainService.trunc(
                        quan -
                        this.mainService.trunc(
                          pay_quanobj[data[i]["sno"]] +
                          data[i]["already_measured"]
                        )
                      ),
                      alignment: "right",
                      bold: true,
                    });
                  }
                }
              }
              if (
                data[i]["eff_quantity"] + data[i]["already_measured"] - quan <
                0
              ) {
                total.push({
                  text:
                    -1 *
                    this.mainService.trunc(
                      data[i]["eff_quantity"] + data[i]["already_measured"] - quan
                    ),
                  alignment: "right",
                  bold: true,
                });
              } else {
                total.push("");
              }

              dd.content[dd.content.length - 1].table.body.push(total);
            }
          }
        }

        dd.content.push({ text: " " });
        this.spinner.hide();
        pdfMake.createPdf(dd).download("EMBReport"); */
  }

  rejectemb:any = [];
  updatetab2(element:any) {
    let data = JSON.parse(element["data"]);
    for (let i = 0; i < this.embData.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (data[j] == this.embData[i]["id"]) {
          this.rejectemb.push(Object.assign(this.embData[i]));
        }
      }
    }
  }
  checkclick(element:any) {
    console.log(this.emblistdata);
    let flag = false;
    let flagcheck = false;
    let data = this.emblistdata;
    setTimeout(function () {
      console.log(element["check"]);
      for (let i = 0; i < data.length; i++) {
        console.log(data[i]["check"]);
        if (data[i]["check"]) {
          if (flagcheck) flag = true;
        } else {
          flagcheck = true;
        }
      }
      if (flag) {
        Swal.fire("Oops", "Please Select Right Sequence ", "warning");
        element["check"] = false;
        return;
      }
    }, 100);
    console.log(flag);
  }
  tender_apprdata:any = {};
  async Appr() {
    this.tender_apprdata = Object.assign({}, this.lastEmbDetails);
    if (this.lastEmbDetails["status"] != "GENERATED") {
      Swal.fire("Oops", "Emb Already in Approval ", "warning");
      return;
    }

    this.tender_apprdata["level"] = 1;
    this.tender_apprdata["branch"] = 1;
    this.tender_apprdata["doc_local_no"] = this.tender_apprdata["id"];
    this.tender_apprdata["doc_type"] = "EMB";
    this.tender_apprdata["node_cd"] = this.selectedTender["work_id"];
    this.tender_apprdata["module_cd"] = "EMB";
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, "EMB", "EMB");
    await this.ApprovalUserService.getMostLatestWorkflowlog(
      this.ebillUser,
      this.tender_apprdata
    );
    await this.ApprovalUserService.getWorkflowloguser(
      this.ebillUser,
      this.tender_apprdata
    );

    $("#myModal2").modal("show");
  }

  async saveappr() {
    var obb:any = new Object();
    obb["user_id"] = this.ebillUser.user_id;
    obb["remark"] = this.appr_remark;

    obb["forward_msg"] = "";
    obb["b_acct_id"] = this.b_acct_id;
    obb["forwarded_by"] = this.ebillUser.user_id;
    obb["status"] = "APPROVED";
    obb["doc_type"] = "EMB";
    obb["doc_local_no"] = this.tender_apprdata["id"];
    obb["doc_desc"] = this.tender_apprdata["emb_desc"];
    obb["role_cd"] = this.ebillUser.role_cd;
    obb["zone_cd"] = this.ebillUser.zone_cd;
    obb["flag"] = 1;
    console.log(this.forwarded_user_id, obb);
    var resp = await this.approveService.insertForAppr(obb);
    if (resp["error"] == false) {
      await this.saveapprPENDING();
      await this.updatestatus(this.tender_apprdata["id"]);
      await this.getAllApprovalStatus();
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  async saveapprPENDING() {
    console.log(this.rolebyid);
    var obb:any = new Object();
    obb["user_id"] = this.forwarded_user_id;
    obb["remark"] = "";
    obb["b_acct_id"] = this.b_acct_id;
    obb["forwarded_by"] = this.ebillUser.user_id;
    obb["forward_msg"] = this.appr_remark;
    obb["status"] = "PENDING";
    obb["doc_type"] = "EMB";
    obb["doc_local_no"] = this.tender_apprdata["id"];
    obb["doc_desc"] = this.tender_apprdata["emb_desc"];
    obb["role_cd"] = this.rolebyid[this.forwarded_user_id];
    obb["zone_cd"] = this.ebillUser.zone_cd;
    obb["flag"] = 2;
    console.log(this.forwarded_user_id, obb);
    var resp = await this.approveService.insertForAppr(obb);
    if (resp["error"] == false) {

      this.spinner.hide();
      this.toastr.success("Approval Added Successfully!!");
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  async updatestatus(id:any) {
    var obb:any = new Object();
    obb["b_acct_id"] = this.ebillUser.b_acct_id;
    obb["id"] = this.idarr;
    obb["update_user_id"] = this.ebillUser.user_id;
    obb["status"] = "UNDERAPPROVAL";
    var resp = await this.embService.changestatus(obb);
    if (resp["error"] == false) {
      await this.insertembxrefappr(id);
      await this.getTenderId();
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  async insertembxrefappr(id:any) {
    var obb:any = new Object();
    obb["b_acct_id"] = this.ebillUser.b_acct_id;
    obb["doc_local_no"] = id;
    obb["data"] = JSON.stringify(this.idarr);

    var resp = await this.embService.createembxrefappr(obb);
    if (resp["error"] == false) {
      await this.getTenderId();
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  appr_remark:any;
  forwarded_user_id:any;
  userRoles = [];
  role_name = [];
  async getUsersRoles() {
    var resp = await this.userService.getUsersRoles(this.b_acct_id);
    if (resp["error"] == false) {
      var dt = resp.data;
      for (var i = 0; i < dt.length; i++) {
        dt[i]["desc"] =
          dt[i]["user_id"] + " - " + dt[i]["name"] + " - " + dt[i]["role_cd"];
      }
      this.userRoles = dt;
    } else {
      Swal.fire("Oops", "Error while getting all users", "error");
    }
  }
}
