import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import {MainService} from './main.service';
@Injectable({
  providedIn: 'root'
})
export class VendorService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + '/vendor';
  }

  async searchVendor(mobile_number:any){
    const res = await this.http.get<any>(this.httpUrl + '/vendor/searchVendor'+mobile_number).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async submitVendor(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/vendor/submitVendor',obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async getBankDtlByIfsc(ifsc:any){
    const res = await this.http.get<any>(this.httpUrl + '/vendor/getBankDtlByIfsc'+ifsc).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getVendor(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/all_vender' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getvendorDetails(vendor_id:any){
    console.log("i am here")
    const res = await this.http.get<any>(this.httpUrl + '/getVendorDtl' + vendor_id).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async inactiveVendor(obj:object){
    const res = await this.http.put<any>(this.httpUrl + '/vendor/inactiveVendor',obj).toPromise().then(res => {
      return res;
    });
    return res;
  }



  async viewDoc(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/document', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  async getSavedEmpData(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/getAllEmpDtl' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async getAllDirData(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/getDirDtl' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAllDirDataoneByOne(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/dirSingle' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllMacData(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/getMachinaryDtl' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async getAllBankData(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/getBankDtl' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async printApi(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/printApi' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async registervender(obj:any) {
    console.log(obj,"from service")
    const res = await this.http.post<any>(this.httpUrl + '/le_id_insertion',obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


}
