import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {MainService} from './main.service';
@Injectable({
  providedIn: 'root'
})
export class AuctionBidService {


  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl+"/property/auction";
   }

   async insertAuctionBid(obj:any){
    console.log("obj from service", obj)
    const resp = this.http.post<any>(this.httpUrl + '/insert-auction-bid-rule' , obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getAuctionBidRule(obj:any){
    const resp = this.http.post<any>(this.httpUrl + '/get-auction-bid-rule' , obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async DelAuctionBidRule(obj:any){
    console.log(obj);
    const resp = this.http.delete<any>(this.httpUrl + '/del-auction-bid-rule' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
}
