<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Matrix Manage</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" href="#tab-1" data-toggle="tab"
                                (click)="refresh()"><i class="ti-bar-chart"></i>
                                All HRA Matrix List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" href="#tab-2" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-plus-circle"></i> Create HRA Matrix</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" href="#tab-3" data-toggle="tab"><i
                                    class="ti-settings"></i> Update
                                HRA Matrix</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">

                            <br>

                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-danger"
                                        style="border-radius: 20px;" (click)=open_create()>Create HRA Matrix</button>
                                </div>
                            </div>

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="city_group">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>City Group

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{mainService.codeValueShowObj['HR0052'][element.city_group] }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="lower_limit">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Lower Limit

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.lower_limit }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="upper_limit">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Upper Limit

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.upper_limit }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="level_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.level_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="effective_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Effective
                                            Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.effective_dt }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Amount

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.amount }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">Update</button>
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element)">Delete</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary"
                                        style="border-radius: 20px;" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>

                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Select City group * :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0052']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="hraMatrix['city_group']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Grade Pay Lower Limit * :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="hraMatrix['lower_limit']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Grade Pay Upper Limit * :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="hraMatrix['upper_limit']">
                                </div>
                            </div>
                            <br> -->

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Select Level Code:</h6>
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control" [(ngModel)]="hraMatrix['level_code']"> -->
                                    <ng-select [items]="level_code" [multiple]="false" placeholder="Select Level"
                                        [(ngModel)]="hraMatrix['level_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Amount :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="hraMatrix['amount']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Effective From * :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="hraMatrix['effective_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="save()">Submit</button>

                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-3">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary"
                                        style="border-radius: 20px;" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Select City group * :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0052']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="hraMatrix['city_group']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Grade Pay Lower Limit * :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="hraMatrix['lower_limit']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Grade Pay Upper Limit * :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="hraMatrix['upper_limit']">
                                </div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Select Level Code:</h6>
                                </div>
                                <div class="col-4">
                                    <!-- ? <input class="form-control" [(ngModel)]="hraMatrix['level_code']"> -->
                                    <ng-select [items]="level_code" [multiple]="false" placeholder="Select Level"
                                        [(ngModel)]="hraMatrix['level_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Amount :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="hraMatrix['amount']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Effective From * :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="hraMatrix['effective_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
