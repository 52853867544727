import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  httpUrl;


  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl+"/hr/setting";
  }
  //hra matrix
  async gethraMatrix(obj :any ) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/hraMatrixNew/gethraMatrix'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addhraMatrix(obj :any ) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/hr/payroll_info/hraMatrixNew/addhraMatrix',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updatehraMatrix(obj :any ) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/hraMatrixNew/updatehraMatrix',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deletehraMatrix(obj :any ) {
    const resp = await this.http.delete<any>(this.main.httpUrl + '/hr/payroll_info/hraMatrixNew/deletehraMatrix'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //fields
  async getFields(obj :any ) {

    const res = await this.http.get<any>(this.main.httpUrl + '/metadata/fields/getfields'+obj).toPromise().then(res => {
      return res;

    });
    return res;

  }
  // Avinash Mishra
  async getPaymatrixcode(obj :any ) {

    const res = await this.http.get<any>(this.main.httpUrl + '/metadata/fields/allPaymatrixCode'+obj).toPromise().then(res => {
      return res;

    });
    return res;

  }



  async createFields(obj :any ) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/metadata/fields/createField', obj).toPromise().then(res => {
      return res;
     });
    return resp;
   }
   async updateFields(obj :any ) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/metadata/fields/updateField', obj).toPromise().then(res => {
      return res;
     });
    return resp;
   }
   //codeValue
   async getCodeValue(b_acct_id:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/metadata/codeValue/getCodeValues'+b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getmonthValue(b_acct_id:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/metadata/codeValue/getmonthValue'+b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getFieldCodeValues(obj :any ) {
    const resp = await this.http.get<any>(this.main.httpUrl+'/metadata/codeValue/getFieldCodeValues' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertCodeValue(obj :any ) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/metadata/codeValue/createCodeValue',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateCodeValues(obj :any ) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/metadata/codeValue/updateCodeValue',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteCodeValue(obj :any ) {
    const resp = await this.http.delete<any>(this.main.httpUrl + '/metadata/codeValue/deleteCodeValue'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //hrms-fileds
  async addcontribution(obj :any ) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/hr/setting/pensioncontribution/addcontribution',obj).toPromise().then(res => {
    return res;
    });
    return resp;
    }
    async getcontribution(b_acct_id:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/setting/pensioncontribution/getcontribution'+b_acct_id).toPromise().then(res => {
    return res;
    });
    return resp;
    }
    async deletecontribution(obj :any ) {
    const resp = await this.http.delete<any>(this.main.httpUrl + '/hr/setting/pensioncontribution/deletecontribution'+obj).toPromise().then(res => {
    return res;
    });
    return resp;
    }
  //leave-reference-data
  async  getAllRules(obj :any ) {
    const resp = await this.http.get<any>(this.main.httpUrl+'/hr/leaveRule/getAllRules'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async  addLeaveRule(obj :any ) {
    const resp = await this.http.post<any>(this.main.httpUrl+'/hr/leaveRule/addLeaveRule',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async  updateLeaveRule(obj :any ) {
    const resp = await this.http.put<any>(this.main.httpUrl+'/hr/leaveRule/updateLeaveRule' ,obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  deleteLeaveRule(obj :any ) {
    const resp = await this.http.delete<any>(this.main.httpUrl+'/hr/leaveRule/deleteLeaveRule'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
 //delete EstablishServiceleave

 async  deleteLeaveRuleInfo(obj :any ) {
  const resp = await this.http.delete<any>(this.main.httpUrl+'/hr/leaveRule/deleteLeaveRuleInfo'+obj).toPromise().then(res => {
    return res;
  });
  return resp;
}
//  async  deleteLeaveRuleInfo(obj :any ) {
//   const resp = await this.http.delete<any>(this.main.httpUrl+'/hr/leaveRule/deleteLeaveRuleInfo'+ obj).toPromise().then(res => {
//     console.log("woring")
//     return res;

//   });
//   return resp;
// }

  // salary
  async getAllSalaryCD(obj :any ) {

    const res = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/salaryComponents/getComponentDefinition'+JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async createSalaryCD(obj :any ) {
    const resp = await this.http.post<any>(this.main.httpUrl+'/hr/payroll_info/salaryComponents/addComponentDefinition',obj).toPromise().then(res => {
      return res;
     });
    return resp;
   }
   async updateSalaryCD(obj :any ) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/salaryComponents/activateComponent', obj).toPromise().then(res => {
      return res;
     });
    return resp;
   }
   async changeStatusComponent(obj :any ) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/salaryComponents/changeStatusComponent', obj).toPromise().then(res => {
      return res;
     });
    return resp;
   }
   async changeprbStatusComponent(obj :any ) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/salaryComponents/changeprbStatusComponent', obj).toPromise().then(res => {
      return res;
     });
    return resp;
   }
   async deleteSalaryCD(obj :any ) {
    const resp = await this.http.delete<any>(this.main.httpUrl + '/hr/payroll_info/salaryComponents/deleteComponentDefinition'+obj).toPromise().then(res => {
      return res;
     });
    return resp;
   }
   async getAllCurrentArrangements(b_acct_id:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/establishment/getAllCurrentEstablishementInfo'+b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getArrayAllCurrentEstablishementInfo(b_acct_id:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/establishment/getArrayAllCurrentEstablishementInfo'+b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getAllPartyFields(obj :any ) {
    const resp = await this.http.get<any>(this.main.httpUrl+'/hr/emp_info/empInfo/getAllEmployee' + (obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

    //section
    async getAllSections(obj :any ) {
      const res = await this.http.get<any>(this.main.httpUrl + '/hr/establishment_info/section/getAllSections'+obj).toPromise().then(res => {
        return res;
      });
      return res;
    }
    async createSection(obj :any ) {
      const resp = await this.http.post<any>(this.main.httpUrl+'/hr/establishment_info/section/createSection',obj).toPromise().then(res => {
        return res;
       });
      return resp;
     }
     async updateSection(obj :any ) {
      const resp = await this.http.put<any>(this.main.httpUrl + '/hr/establishment_info/section/updateSection', obj).toPromise().then(res => {
        return res;
       });
      return resp;
     }
     async deleteSection(obj :any ) {
      const resp = await this.http.delete<any>(this.main.httpUrl + '/hr/establishment_info/section/deleteSection'+obj).toPromise().then(res => {
        return res;
       });
      return resp;
     }

     //city matrix
     async getCityMatrix(obj :any ) {
      const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/cityMatrix/getCityMatrix'+obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }

    async addCityMatrix(obj :any ) {
      const resp = await this.http.post<any>(this.main.httpUrl + '/hr/payroll_info/cityMatrix/addCityMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async updateCityMatrix(obj :any ) {
      const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/cityMatrix/updateCityMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async deleteCityMatrix(obj :any ) {
      const resp = await this.http.delete<any>(this.main.httpUrl + '/hr/payroll_info/cityMatrix/deleteCityMatrix'+obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }

     //matrix
     async getMatrix(obj :any ) {
      const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/getPayMatrix'+obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }

    async addMatrix(obj :any ) {
      const resp = await this.http.post<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/addPayMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async addpbrMatrix(obj :any ) {
      const resp = await this.http.post<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/addpbrMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }

     //matrix
     async getpbrMatrix(obj :any ) {
      const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/getpbrMatrix'+obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async getallgradepaycode(obj :any ) {

      const res = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/getallgradepaycode'+obj).toPromise().then(res => {
        return res;

      });
      return res;

    }
    async addwfMatrix(obj :any ) {
      const resp = await this.http.post<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/addwfMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async getwfMatrix(obj :any ) {
      const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/getwfMatrix'+obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async updateMatrix(obj :any ) {
      const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/updatePayMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async updatepbrMatrix(obj :any ) {
      const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/updatepbrMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async deleteMatrix(obj :any ) {
      const resp = await this.http.delete<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/deletePayMatrix'+obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }

    async deletepbrMatrix(obj :any ) {
      const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/deletepbrMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async deletewfMatrix(obj :any ) {
      const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/deletewfMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async updatewfMatrix(obj :any ) {
      const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/payMatrix/updatewfMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    //cca Matrix
     async getCCAMatrix(obj :any ) {
      const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/ccaMatrix/getCCAMatrix'+obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }

    async addCCAMatrix(obj :any ) {
      const resp = await this.http.post<any>(this.main.httpUrl + '/hr/payroll_info/ccaMatrix/addCCAMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async updateCCAMatrix(obj :any ) {
      const resp = await this.http.put<any>(this.main.httpUrl + '/hr/payroll_info/ccaMatrix/updateCCAMatrix',obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async deleteCCAMatrix(obj :any ) {
      const resp = await this.http.delete<any>(this.main.httpUrl + '/hr/payroll_info/ccaMatrix/deleteCCAMatrix'+obj).toPromise().then(res => {
        return res;
      });
      return resp;
    }


    async getAllFixedPay(obj :any ) {
      const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/fixedPayAmount/ruleGetAllFixedPayForRule'+JSON.stringify(obj)).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    //deepankar
  async getFixedPayDetails(obj :any ) {
      const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/fixedPayAmount/getFixedPayDetails'+JSON.stringify(obj)).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    //deepankar
    async addFixedPay(obj :any ) {
      const resp = await this.http.post<any>(this.main.httpUrl+'/hr/payroll_info/fixedPayAmount/addFixedPay', (obj)).toPromise().then(res => {
        return res;
      });
      return resp;
    }
    async addFixedPayPromotion(obj :any ) {
      const resp = await this.http.post<any>(this.main.httpUrl+'/hr/payroll_info/fixedPayAmount/addFixedPayPromotion', (obj)).toPromise().then(res => {
        return res;
      });
      return resp;
    }

    // async  deleteLeaveRuleInfo(obj :any ) {
    //   const resp = await this.http.delete<any>(this.main.httpUrl+'/hr/leaveRule/deleteLeaveRuleInfo'+obj).toPromise().then(res => {
    //     return res;
    //   });
    //   return resp;
    // }
    async getDate(){
      const resp = await this.http.get<any>(this.main.httpUrl+'/portal/getServerDate').toPromise().then(res => {
        return res;
      });
      return resp;
    }

 // ------Avinash

async getIncrementDetails(obj:any)
{
  const resp = await this.http.post<any>(this.main.httpUrl+'/hr/payroll_info/fixedPayAmount/getIncrementDetails', (obj)).toPromise().then(res => {
    return res;
  });
  return resp;
}
async addIncDetail(obj :any ) {
  const resp = await this.http.post<any>(this.main.httpUrl+'/hr/payroll_info/fixedPayAmount/addIncDetail', (obj)).toPromise().then(res => {
    return res;
  });
  return resp;
}
 // ---------
}
