import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { LedgerService } from "../../../service/ledger.service";
import { ChartOfAccountService } from "../../../service/chart-of-account.service";
import { MainService } from "../../../service/main.service";
import { NgxSpinnerService } from "ngx-spinner";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as  pdfMake from  "pdfmake/build/pdfmake";
import Swal from "sweetalert2";

import * as fs from 'file-saver'
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-income-expense-report",
  templateUrl: "./income-expense-report.component.html",
  styleUrls: ["./income-expense-report.component.css"],
})
export class IncomeExpenseReportComponent implements OnInit {
  erpUser:any;
  b_acct_id:any;
  allFinYear:any;
  currentFinYear:any;
  selectedFinYear:any;
  mainObj:any = {}
  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  constructor(
    private ledgerService: LedgerService,
    private chartOfAccountService: ChartOfAccountService,
    private spinner: NgxSpinnerService,
    private mainService: MainService
  ) { }
  displayedColumns = ["Expenditure", "Income"];
  innerTableHeader = [
    "Figure at the end of previous year",
    "Chart Of Account",
    // "Sch No.",
    "Figure at the end of reporting year",
  ];

  async refresh() {
    this.lvl4_exp_data = {};
    this.lvl3_in_data = [];
    this.objExp = {};
    this.incObj0 = {};
    this.obj0 = [];
    this.in_op_bal = {};
    this.ex_op_bal = {};
    this.obj = {};
    this.incomeObj = {};
    this.inc_total = 0;
    this.exp_total = 0;
    this.exp_prop = 0;

  }

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;

    await this.getAllFinYear();
    await this.getAllChartOfAccount();
    await this.getAllAccData();
    await this.setFinYear();
    await this.incomeExpenseReport();
  }

  async getAllFinYear() {
    this.spinner.show();
    let obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    let resp = await this.ledgerService.getAllFinYear(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.allFinYear = resp["data"];
      this.allFinYear.map((x:any) => {
        x['fin_year_desc'] = x['fin_year'] + "-" + (Number(x['fin_year']) + 1)
        if (x['status'] == "OPEN") {
          this.selectedFinYear = x['fin_year']
        }
      })
      console.log(this.allFinYear);
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error while getting all field", "error");
    }
  }

  allData:any;
  allCoaObj:any = [];
  end_fin_date:any;
  start_fin_date:any;

  obj0:any = new Object();
  ex_op_bal:any = new Object();
  lvl4_exp_data:any = new Object();
  current_finYear:any;
  lvl3_exp_data:any = [];
  objExp:any;
  lvl3_in_data:any;
  in_op_bal :any= new Object();
  incomeObj:any = new Object();
  incObj:any = new Object();
  incObj0 :any= new Object();
  obj:any = new Object();


  // async getIncoseExpenseReport() {



  //   //Finding details of selected fin year
  //   this.allFinYear.map((x) => {
  //     if (x["fin_year"] === this.selectedFinYear) {
  //       this.current_finYear = x;
  //     }
  //   });
  //   this.start_fin_date = this.current_finYear["start_date"];
  //   this.end_fin_date = this.current_finYear["end_date"];
  //   await this.refresh();


  //   let expense = this.allData.filter((y) => {
  //     let d1 = new Date(this.end_fin_date);
  //     let d2 = new Date(y["acct_dt"]);
  //     return (y["lvl3_code"] == "CH12" && d1 >= d2);
  //   });
  //   console.log("Expenditure : ", expense);

  //   for (let i = 0; i < expense.length; i++) {
  //     if (this.obj[expense[i]["lvl3_code"]] == undefined) {
  //       this.obj[expense[i]["lvl3_code"]] = {};
  //       this.obj0[expense[i]["lvl3_code"]] = expense[i]["txn_amt"];
  //     } else {
  //       this.obj0[expense[i]["lvl3_code"]] =
  //         this.obj0[expense[i]["lvl3_code"]] + expense[i]["txn_amt"];
  //     }
  //   }

  //   for (let i = 0; i < expense.length; i++) {
  //     if (expense[i]["lvl4_code"] != null) {
  //       if (
  //         this.obj[expense[i]["lvl3_code"]][expense[i]["lvl4_code"]] ==
  //         undefined
  //       ) {
  //         this.obj[expense[i]["lvl3_code"]][expense[i]["lvl4_code"]] =
  //           expense[i]["txn_amt"];
  //       } else {
  //         this.obj[expense[i]["lvl3_code"]][expense[i]["lvl4_code"]] =
  //           this.obj[expense[i]["lvl3_code"]][expense[i]["lvl4_code"]] +
  //           expense[i]["txn_amt"];
  //       }
  //     }
  //   }

  //   //Expenditure opening balance

  //   for (let i = 0; i < expense.length; i++) {
  //     if (this.ex_op_bal[expense[i]["lvl3_code"]] == undefined) {
  //       this.ex_op_bal[expense[i]["lvl3_code"]] = {};
  //     }
  //     if (expense[i]["lvl4_code"] != null) {
  //       if (
  //         this.ex_op_bal[expense[i]["lvl3_code"]][expense[i]["lvl4_code"]] ==
  //         undefined
  //       ) {
  //         this.ex_op_bal[expense[i]["lvl3_code"]][expense[i]["lvl4_code"]] =
  //           expense[i]["txn_amt"];

  //         //if from date is grater than account start date then it add all amount above from date
  //         if (this.start_fin_date <= this.allData["acct_dt"]) {
  //           this.ex_op_bal[expense[i]["lvl3_code"]][expense[i]["lvl4_code"]] +=
  //             expense[i]["txn_amt"];
  //         }
  //       }
  //     }
  //   }
  //   console.log("Expenditure Opening balance is :", this.ex_op_bal);

  //   //Converting the object into array of object

  //   this.lvl3_exp_data = Object.keys(this.obj0);
  //   console.log(this.obj);
  //   let aa = Object.keys(this.obj);
  //   console.log(aa);
  //   this.lvl4_exp_data = new Object();

  //   aa.map((x) => {
  //     this.lvl4_exp_data[x] = [];
  //     let tt = Object.keys(this.obj[x]);
  //     console.log(tt);
  //     tt.map((y) => {
  //       let oo = new Object();
  //       oo["lvl4_code"] = y;
  //       oo["lvl4_value"] = this.obj[x][y];
  //       this.lvl4_exp_data[x].push(oo);
  //     });
  //   });

  //   console.log("Expense level 4 data", this.lvl4_exp_data);
  //   console.log("Expense lvl 3  data", this.lvl3_exp_data);

  //   //This code is for Income
  //   let income = this.allData.filter((x) => {
  //     let d1 = new Date(this.end_fin_date);
  //     let d2 = new Date(x["acct_dt"]);
  //     return x["lvl3_value"] == "Income" && d1 >= d2;
  //   });
  //   console.log("Income is : ", income);
  //   for (let i = 0; i < income.length; i++) {
  //     if (this.incObj[income[i]["lvl3_code"]] == undefined) {
  //       this.incObj[income[i]["lvl3_code"]] = {};
  //       this.incObj0[income[i]["lvl3_code"]] = income[i]["txn_amt"];
  //     } else {
  //       this.incObj0[income[i]["lvl3_code"]] =
  //         this.incObj0[income[i]["lvl3_code"]] + income[i]["txn_amt"];
  //     }
  //   }
  //   for (let i = 0; i < income.length; i++) {
  //     if (income[i]["lvl4_code"] != null) {
  //       if (
  //         this.incObj[income[i]["lvl3_code"]][income[i]["lvl4_code"]] ==
  //         undefined
  //       ) {
  //         this.incObj[income[i]["lvl3_code"]][income[i]["lvl4_code"]] =
  //           income[i]["txn_amt"];
  //       } else {
  //         this.incObj[income[i]["lvl3_code"]][income[i]["lvl4_code"]] =
  //           this.incObj[income[i]["lvl3_code"]][income[i]["lvl4_code"]] +
  //           income[i]["txn_amt"];
  //       }
  //     }
  //   }
  //   console.log(this.incObj);
  //   this.lvl3_in_data = Object.keys(this.incObj);
  //   console.log(this.lvl3_in_data);
  //   // Converting the object into array of object
  //   let bb = Object.keys(this.incObj0);
  //   console.log("incObj", this.incObj);
  //   console.log("incObj0", this.incObj0);

  //   console.log(bb);
  //   this.incomeObj = new Object();

  //   bb.map((x) => {
  //     this.incomeObj[x] = [];
  //     let ss = Object.keys(this.incObj[x]);
  //     console.log(ss);
  //     ss.map((y) => {
  //       let oo = new Object();
  //       oo["lvl4_code"] = y;
  //       oo["lvl4_value"] = this.incObj[x][y];
  //       this.incomeObj[x].push(oo);
  //     });
  //   });

  //   console.log("Income level 4  data is  ", this.incomeObj);
  //   console.log("Income level 3  data is ", this.lvl3_in_data);

  //   //Income opening balance

  //   for (let i = 0; i < income.length; i++) {
  //     if (this.in_op_bal[income[i]["lvl3_code"]] == undefined) {
  //       this.in_op_bal[income[i]["lvl3_code"]] = {};
  //     }
  //     if (income[i]["lvl4_code"] != null) {
  //       if (
  //         this.in_op_bal[income[i]["lvl3_code"]][income[i]["lvl4_code"]] ==
  //         undefined
  //       ) {
  //         this.in_op_bal[income[i]["lvl3_code"]][income[i]["lvl4_code"]] =
  //           income[i]["txn_amt"];

  //         //if from date is grater than account start date then it add all amount above from date
  //         if (this.start_fin_date <= this.allData["acct_dt"]) {
  //           this.in_op_bal[income[i]["lvl3_code"]][income[i]["lvl4_code"]] +=
  //             income[i]["txn_amt"];
  //         }
  //       }
  //     }
  //   }
  //   console.log(" Income Opening basslance is :", this.in_op_bal);
  // }
  expence:any = [];
  income:any = [];
  first_line:any
  exp_total:any = 0;
  inc_total:any = 0;
  inc_prop:any = 0;
  exp_prop:any = 0;
  inc_op_total :any= 0;
  exp_op_total :any= 0;
  async incomeExpenseReport() {
    // if (!this.selectedFinYear) {
    //   Swal.fire("Opps..", 'Please Select Fin Year', 'info');
    //   return;
    // }
    this.spinner.show();
    await this.refresh();

    //***********************************Cloasing of Expense****************************************************************************** */

    let obj1 = Object.assign({}, this.mainObj);
    obj1["b_acct_id"] = this.b_acct_id;
    obj1["chart_of_account"] = 'CH121';// Expenditure A/C
    obj1["lvl_number"] = 3;//Level Number
    var resp1 = await this.ledgerService.getBalanceSheet(JSON.stringify(obj1));
    this.expence = resp1.data
    //  console.log("expense : ", this.expence);
    this.exp_total = 0;
    this.exp_op_total = 0;
    this.expence.map((x:any) => {
      x['txn_amt'] = (-1) * x['txn_amt'];
      this.exp_total += x['txn_amt'];
      this.exp_op_total += x['opening']

    })
    //********************************************cloing of Income********************************************************************* */

    let obj2 = Object.assign({}, this.mainObj);
    obj2["b_acct_id"] = this.b_acct_id;
    obj2["chart_of_account"] = 'CH122';//Income
    obj2["lvl_number"] = 3;//Level Number
    var resp2 = await this.ledgerService.getBalanceSheet(JSON.stringify(obj2));
    console.log("income : ", resp2);

    console.table(resp2.data)
    this.income = resp2.data
    this.inc_op_total = 0;
    this.inc_total = 0;
    this.income.map((x:any) => {
      this.inc_total += x['txn_amt'];
      this.inc_op_total += x['opening'];
    })

    this.first_line = await this.getClosingStock(this.selectedFinYear)
    this.inc_prop = 0;
    this.exp_prop = 0;
    if (this.exp_total > this.inc_total) {
      this.inc_prop += (this.exp_total - this.inc_total);
    }
    if (this.exp_total < this.inc_total) {
      this.exp_prop += (this.inc_total - this.exp_total);
    }
    // console.log(this.inc_total)
    // console.log(this.inc_prop)
    this.spinner.hide();
  }


  async setFinYear() {
    if (this.selectedFinYear == undefined) {
      Swal.fire("warning", 'Please select any fin year...', "warning");
      this.mainObj['start_date'] = null;
      this.mainObj['end_date'] = null;
      return;
    }
    this.allFinYear.map((x:any) => {
      if (x['fin_year'] == this.selectedFinYear) {
        this.mainObj['start_date'] = x['start_date'];
        this.mainObj['end_date'] = x['end_date'];
      }
    })
  }
  async getAllAccData() {
    this.spinner.show();
    let objToSend = Object();
    objToSend["b_acct_id"] = this.b_acct_id;
    var resp = await this.ledgerService.getBalanceSheet(JSON.stringify(objToSend));
    if (resp["error"] == false) {
      this.allData = resp["data"];
      console.log("All Account Data : ", this.allData);
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("error", resp.data, "error");
    }
  }

  async getAllChartOfAccount() {
    this.spinner.show();
    let obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    let resp = await this.chartOfAccountService.getchartofaccount(obj);
    if (resp["error"] == false) {
      resp["data"].map((x:any) => {
        this.allCoaObj[x["leaf_code"]] = x["leaf_value"];
      });
      console.log("all coa", this.allCoaObj);
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire(
        "Error",
        "Error while getting all chart Of Account...",
        "error"
      );
    }
  }

  closing_st:any
  dataObj:any
  async getClosingStock(year:any) {
    this.mainObj['b_acct_id'] = this.b_acct_id;
    let resp = await this.ledgerService.getClosingStock(JSON.stringify(this.mainObj));
    if (resp['error'] == false) {
      this.closing_st = resp['data'];
      console.log(this.closing_st)
      let next_fin = (Number(year) + 1)
      let pre_fin = (Number(year) - 1)
      this.dataObj = { 'curr_total': 0, 'next_total': 0, 'pre_total': 0 }
      await this.getCogsEntry();

      this.closing_st.map((x:any) => {
        if (this.selectedFinYear == x['fin_year']) {
          this.dataObj['curr_total'] += x['final_stock_amt'];
        }
        // next Fin year
        if (next_fin == x['fin_year']) {
          this.dataObj['next_total'] += x['final_stock_amt'];

        }
        //pre finyear
        if (pre_fin == x['fin_year']) {
          this.dataObj['pre_total'] += x['final_stock_amt'];
        }
      })
    }
    let profit = this.dataObj['curr_total'] - this.dataObj['next_total'];
    console.log(profit)
    return profit;
  }
  async getCogsEntry() {
    let cogs_entry = []
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['start_date'] = this.mainObj['st_date'];
    obj['end_date'] = this.mainObj['en_date'];
    let resp = await this.ledgerService.getCogsEntry(JSON.stringify(obj));
    if (resp['error'] == false) {
      cogs_entry = resp['data'];
      console.log(cogs_entry);
      cogs_entry.map((x:any) => {
        this.dataObj['curr_total'] += x['txn_amt'];

      })
    }
  }

  async export() {
    let obj:any = new Object();
    obj['acc_name'] = this.mainService.accInfo["account_name"];
    obj['acc_sort_name'] = this.mainService.accInfo["account_short_name"];
    obj['year'] = this.selectedFinYear + '-' + (Number(this.selectedFinYear) + 1);
    obj['end_date'] = this.mainObj['end_date']
    obj['expence'] = this.expence;
    obj['exp_total'] = this.exp_total;
    obj['exp_op_total'] = this.exp_op_total;
    obj['exp_prop'] = this.exp_prop;
    obj['first_line'] = this.first_line;
    obj['income'] = this.income;
    obj['inc_total'] = this.inc_total;
    obj['inc_prop'] = this.inc_prop;
    obj['inc_op_total'] = this.inc_op_total;
    obj['b_acct_id'] = this.b_acct_id;
    let resp :any= await this.ledgerService.incomeExcel(obj);
    let blob :any= new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'income-expense-report.xlsx');
  }
  print() {
    this.spinner.show();
    var txt =
      this.mainService.accInfo["account_name"] +
      "(" +
      this.mainService.accInfo["account_short_name"] +
      ")" +
      " INCOME AND EXPENDITURE ACCOUNT (FOR THE YEAR END ON ) " +
      this.mainObj['end_date'] +
      " ";
    var dd :any= {
      pageSize: "A4",
      header: function (currentPage:any, pageCount:any) {
        var obj = {
          text: txt + "Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
        };
        return obj;
      },

      pageOrientation: "landscape",

      pageMargins: [10, 60, 40, 10],
      content: [],
    };

    let tab = {
      fontSize: 10,
      table: {
        widths: ["*", "*"],
        body: [
          [
            [
              { text: "Expenditure ", alignment: "center", fontSize: 20 },
              {
                table: {
                  widths: [90, 95, 90, 90],

                  body: [
                    [
                      {
                        text: "Figure at the end of the Privious year",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                      {
                        text: "Liability",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                      // {
                      //   text: "Sch No.",
                      //   style: "tableHeader",
                      //   bold: true,
                      //   alignment: "center",
                      // },
                      {
                        text: "Figure at the end of the Reporting year",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                    ],
                  ],
                },
              },
            ],

            [
              { text: "Income ", alignment: "center", fontSize: 20 },
              {
                table: {
                  widths: [90, 95, 90, 90],
                  body: [
                    [
                      {
                        text: "Figure at the end of the Privious year",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                      {
                        text: "Assets",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                      // {
                      //   text: "Sch No.",
                      //   style: "tableHeader",
                      //   bold: true,
                      //   alignment: "center",
                      // },
                      {
                        text: "Figure at the end of the Reporting year",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                    ],
                  ],
                },
              },
            ],
          ],
        ],
      },
    };
    dd.content.push(tab);



    // Expence Print
    let spe = []
    spe.push({ text: '0', bold: true });
    spe.push({ text: 'To Cost of Property Stock Sold [As per properties Stock Account]', bold: true });
    // spe.push({ text: 'COGS', bold: true });
    spe.push({ text: this.first_line, bold: true });
    dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(spe);


    for (let i = 0; i < this.expence.length; i++) {

      let printLib = []

      let obj = {
        columns: [
          {
            width: '*',
            text: this.expence[i]['opening']
          }
        ]
      }
      printLib.push(obj);

      if (this.expence[i]['lvl5_code'] != null) {
        let obj2 = {
          columns: [
            {
              width: '*',
              text: this.expence[i]['lvl4_value'],
              bold: true
            }
            ,
            {
              width: '*',
              text: "- " + this.expence[i]['lvl5_value'],
            }
          ]
        }
        printLib.push(obj2);
      } else {
        let obj2 = {
          columns: [{
            width: '*',
            text: this.expence[i]['lvl4_value'],
            bold: true
          }]
        }
        printLib.push(obj2);
      }


      //code show

      // if (this.expence[i]['lvl5_code'] != null) {
      //   let obj3 = {
      //     columns: [
      //       {
      //         width: '*',
      //         text: this.expence[i]['lvl4_code'],
      //         bold: true
      //       }
      //       ,
      //       {
      //         width: '*',
      //         text: "- " + this.expence[i]['lvl5_code'],
      //       }
      //     ]
      //   }
      //   printLib.push(obj3);
      // } else {
      //   let obj3 = {
      //     columns: [{
      //       width: '*',
      //       text: this.expence[i]['lvl4_code'],
      //       bold: true
      //     }]
      //   }
      //   printLib.push(obj3);
      // }

      let obj5 = {
        columns: [
          {
            width: '*',
            text: (-1 * this.expence[i]['txn_amt'])
          }
        ]
      }
      printLib.push(obj5);


      // printLib.push(obj5);
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(printLib);

    }
    let printLib = []
    if (this.exp_total < this.inc_total) {
      printLib.push({ text: "", alignment: "left", bold: true });
      printLib.push({ text: "To Excess of Income Over Expenditure", alignment: "center", bold: true });
      // printLib.push({ text: "", alignment: "center", bold: true });
      printLib.push({ text: (this.inc_total - this.exp_total), alignment: "left", bold: true });
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(printLib);
    }

    printLib = []
    printLib.push({ text: this.exp_op_total, alignment: "left", bold: true });
    printLib.push({ text: "TOTAL", alignment: "center", bold: true, });
    // printLib.push({ text: "", alignment: "center", bold: true });
    printLib.push({ text: (this.exp_total + this.exp_prop + this.first_line), alignment: "left", bold: true });
    // dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(printLib);

    // Income Print

    for (let i = 0; i < this.income.length; i++) {


      let printAss = []

      let obj = {
        columns: [
          {
            width: '*',
            text: this.income[i]['opening']
          }
        ]
      }
      printAss.push(obj);

      if (this.income[i]['lvl5_code'] != null) {
        let obj2 = {
          columns: [
            {
              width: '*',
              text: this.income[i]['lvl4_value'],
              bold: true
            }
            ,
            {
              width: '*',
              text: "- " + this.income[i]['lvl5_value'],
            }
          ]
        }
        printAss.push(obj2);
      } else {
        let obj2 = {
          columns: [{
            width: '*',
            text: this.income[i]['lvl4_value'],
            bold: true
          }]
        }
        printAss.push(obj2);
      }


      //code show

      // if (this.income[i]['lvl5_code'] != null) {
      //   let obj3 = {
      //     columns: [
      //       {
      //         width: '*',
      //         text: this.income[i]['lvl4_code'],
      //         bold: true
      //       }
      //       ,
      //       {
      //         width: '*',
      //         text: "- " + this.income[i]['lvl5_code'],
      //       }
      //     ]
      //   }
      //   printAss.push(obj3);
      // } else {
      //   let obj3 = {
      //     columns: [{
      //       width: '*',
      //       text: this.income[i]['lvl4_code'],
      //       bold: true
      //     }]
      //   }
      //   printAss.push(obj3);
      // }

      let obj5 = {
        columns: [
          {
            width: '*',
            text: this.income[i]['txn_amt']
          }
        ]
      }
      printAss.push(obj5);
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(printAss);

    }
    let printAss = []
    if (this.exp_total > this.inc_total) {
      printAss.push({ text: "", alignment: "center", bold: true });
      printAss.push({ text: "To Excess of Income Over Expenditure", alignment: "center", bold: true });
      // printAss.push({ text: "", alignment: "center", bold: true });
      printAss.push({ text: (this.exp_total - this.inc_total), alignment: "left", bold: true });
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(printAss);
    }

    printAss = []
    printAss.push({ text: this.inc_op_total, alignment: "left", bold: true });
    printAss.push({ text: "TOTAL", alignment: "center", bold: true, colSpan: 2 });
    // printAss.push({ text: "", alignment: "center", bold: true });
    printAss.push({ text: (this.inc_total + this.inc_prop), alignment: "left", bold: true });
    dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(printAss);
    dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(printLib);
    pdfMake.createPdf(dd).download("Income-Expense report");

    this.spinner.hide();

  }
}
