<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Employee Salary Register  -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip1']}}</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                              <!-- Individual Pay Slip -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i
                                    class="ti-settings"></i> <!-- One Click Pay Slip -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip3']}}</a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                   <!--  Select Year : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip4']}}<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="selectObj['fin_year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Month : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip5']}} :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="selectObj['month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip7']}} : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="" [(ngModel)]="selectObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getPaySlip()"><!-- Download -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip8']}}</button>
                                </div>
                            </div>
                            <br>




                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Year : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip4']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="selectObj['fin_year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Month : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip5']}} :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="selectObj['month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getoneclickPaySlip()"><!-- GET PAY SLIP -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip9']}}</button>
                                </div>
                            </div>
                            <br>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner><!-- Loading.... -->{{mainService.allLableShowObjHR[mainService.language_cd+'hrms-pay-slip10']}}</ngx-spinner>