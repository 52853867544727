<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"> Data Assignment</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag"  data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>&nbsp; Data Assignment List</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link"  *ngIf="create_flag"  data-toggle="tab"><i
                                    class="ti-settings"></i> Create
                                icms Data Assignment
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-7-3" data-toggle="tab"><i class="ti-settings"></i> Update
                                Hierarchy Node
                            </a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-7-4" data-toggle="tab"><i class="ti-settings"></i> Update
                                User Role
                            </a>
                        </li> -->


                    </ul>
                    <div class="tab-content">
                        <div   *ngIf="list_flag">
                            <br>
                            <div class=" table-container ">

                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-7 text-right">
                                        <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                    </div>
                                </div>
                                <div class="example-container">
                                    <table style="width: 100%" mat-table [dataSource]="dataSource" matSort>

                                        <ng-container matColumnDef="node_cd" sticky>
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Node Code</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element">
                                                {{element.node_cd}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="node_desc">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>Node Description</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.desc}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>User Name</b>

                  </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.name}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="mobile">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>User Mobile</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.mobile}}
                                            </td>
                                        </ng-container>

                                        <!-- <ng-container matColumnDef="update">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>Role Description</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.role_desc}}
                                            </td>
                                        </ng-container> -->



                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>Status</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.status}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>Action</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                <button class="btn btn-outline-primary" *ngIf="element.status=='ACTIVE'"
                                                    (click)="inactive(element)">INACTIVE</button>
                                                <!-- <button class="btn btn-danger" (click)="deleteRole(element,i)">Delete</button> -->
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </div>
                        <div  *ngIf="create_flag">
                            <div class="card" >

                                <div style="margin: 5%;">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-primary"> Go to
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">


                                        <div class="col-12">
                                            <div class="row" *ngFor="let item of data,let i=index">
                                                <div class="col-3">
                                                    <!-- <h6>Select Node :</h6> -->
                                                </div>
                                                <div class="col-3">
                                                    <h6>Select Level {{i+1}} Node:</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value"
                                                        bindValue="lvl1_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl1_cd']" [selectableGroup]="true"
                                                        (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value"
                                                        bindValue="lvl2_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true"
                                                        (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value"
                                                        bindValue="lvl3_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl3_cd']" [selectableGroup]="true"
                                                        (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value"
                                                        bindValue="lvl4_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl4_cd']" [selectableGroup]="true"
                                                        (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value"
                                                        bindValue="lvl5_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl5_cd']" [selectableGroup]="true"
                                                        (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value"
                                                        bindValue="lvl6_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl6_cd']" [selectableGroup]="true"
                                                        (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value"
                                                        bindValue="lvl7_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl7_cd']" [selectableGroup]="true"
                                                        (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-3" *ngIf="i==data.length-1">
                                                    <button (click)="add_dropdown(item,i)"
                                                        class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                                            aria-hidden="true"></i></button>
                                                    &nbsp;&nbsp;
                                                    <button (click)="remove_dropdown(item,i)" *ngIf="i!=0"
                                                        class="btn btn-outline-danger">
                                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <!-- <div class="row">
            <div class="col-3"></div>
            <div class="col-3">
                <h6> Selected Node`s Path :</h6>
            </div>
            <div class="col-6">
                {{Obj['path_desc']}}
            </div>
        </div>
        <br> -->
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6> Select User : <span style="color: red;">*</span> </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="allSearchableEmp" [multiple]="false"
                                                placeholder="Select User" bindLabel="desc" bindValue="user_id"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="hierarchy" [(ngModel)]="Obj['user_id']">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>

                                    <!-- <br>
        <div class="row">
            <div class="col-4 text-left">
                <h6 style="margin-left: 2%;">Select Resource :<span style="color: red;">*</span>
                </h6>
            </div>
            <div class="col-4">
                <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">
                </ng-select>
            </div>
            <div class="col-4">

            </div>
        </div> -->
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-primary" (click)="submit()">Submit</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-7-3">
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Select Node : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="ActiveNode" [multiple]="false" bindLabel="desc"
                                        bindValue="node_cd" [selectableGroup]="true" (change)="ParentNodeChange()"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy"
                                        [(ngModel)]="Obj['parent_node_cd']">
                                    </ng-select> -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;">Current Node Path : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>
                            <br>
                            <hr>

                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Node Code : <span style="color: red;">*</span> </h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="Obj['node_cd']" onkeypress='return (event.charCode > 96 && event.charCode < 123) ||
                                    (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48
                                    && event.charCode <= 57) || event.charCode <= 31' type="text"
                                        placeholder="Enter Node Code" />
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Node Description : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                        [(ngModel)]="Obj['node_desc']" placeholder="Enter Node Description"
                                        class="form-control"></textarea>
                                </div>
                            </div>
                            <!-- <br>
                            <div class="row">
                                <div class="col-4 text-left">
                                    <h6 style="margin-left: 2%;">Select Resource :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">
                                    </ng-select>
                                </div>
                                <div class="col-4">

                                </div>
                            </div> -->
                            <!-- <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="tab-pane fade" id="tab-7-4">
                            <br>

                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Code : <span style="color: red;">*</span> </h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="Enter Role Code" class="form-control" [(ngModel)]="Obj['role_cd']" disabled>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Name : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="Enter Name" class="form-control" [(ngModel)]="Obj['role_name']">


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Description : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="Obj['role_desc']" placeholder="Enter Role Description" class="form-control"></textarea>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Select Access : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Select Access " [items]="access" bindLabel="value" bindValue="code" [selectableGroup]="true" [selectableGroupAsModel]="false" aria-placeholder="Select Access" [closeOnSelect]="false" name="hierarchy" [(ngModel)]="Obj['access']">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-left">
                                    <h6 style="margin-left: 2%;">Select Resource :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4" *ngIf="flag == false">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">
                                    </ng-select>
                                </div>
                                <div class="col-4" *ngIf="flag == true">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>Loading....</ngx-spinner>

<!-- <div class="modal" id="createRole">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Create Role</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body" style="padding-left: 15%">
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Select
                            Components:</b></label>
                    <div class="col-lg-8">

                        <ng-select [items]="allComp" bindLabel="interface_name" bindValue="id" [multiple]="true" placeholder="Select Components" [(ngModel)]="selectedComp" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">



                        </ng-select>

                    </div>
                </div>
                <br>
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Whats is Role
                            Name:</b></label>
                    <div class="col-lg-8">

                        <input class="form-control" [(ngModel)]="role_name">
                    </div>
                </div>
                <br>


            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-info" (click)="addNewRole()">Add</button>
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div> -->


<ngx-spinner type="ball-atom">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

<!-- <div class="modal" id="updateRole">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title">Update Role</h4>
            </div>


            <div class="modal-body" style="padding-left: 15%">
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Selected
                            Components:</b></label>
                    <div class="col-lg-8">

                        <ng-select [items]="allComp" bindLabel="interface_name" bindValue="id" [multiple]="true" placeholder="Select Components" [(ngModel)]="selectedComp" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">



                        </ng-select>

                    </div>
                </div>
                <br>
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b> Role
                            Name:</b></label>
                    <div class="col-lg-8">

                        <input class="form-control" [(ngModel)]="role_name">
                    </div>
                </div>
                <br>
            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" (click)="updateRole()">Update</button>
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>

        </div>
    </div>
</div> -->
