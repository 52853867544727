import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service'
@Injectable({
  providedIn: 'root'
})
export class DashboardService {


  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + '/emd';
  }

  async getAllDashData(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/dashboard/getAllDashData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async getAppliedVendor(obj:any) {
    let resp = await this.http.get(this.httpUrl + '/dashboard/getAppliedVendor'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async getEmdDetails(obj:any) {
    let resp = await this.http.get(this.httpUrl + '/dashboard/getEmdDetails'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async getRefundDetails(obj:any) {
    let resp = await this.http.get(this.httpUrl + '/dashboard/getRefundDetails'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }



}
