<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">TA BILL </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                TA BILL </a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>




                            </div>




                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort style="width: 100%"
                                    class="text-center">

                                    <ng-container matColumnDef="s_no">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>S.No</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element let i=index ">
                                            <!-- {{i+1}} -->
                                            {{element.new_index}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="le_id">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>User ID</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.le_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> <b>
                                                Name</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.emp_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="trip_name">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> <b>
                                              Trip  Name</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.trip_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="applied_le_id">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> <b>
                                                Applied User </b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.applied_le_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="rembus_amt">

                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>Amount</b>
                                        </th>
                                        <td class="text-center" mat-cell flex-stretch *matCellDef="let element">
                                            {{element.rembus_amt}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="applied_date">

                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>Applied Date</b>
                                        </th>
                                        <td class="text-center" mat-cell flex-stretch *matCellDef="let element">
                                            {{element.applied_date | date: 'yyyy-MM-dd' }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="remark">

                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>Remark</b>
                                        </th>
                                        <td class="text-center" mat-cell flex-stretch *matCellDef="let element">
                                            {{element.remark }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">

                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>Status</b>
                                        </th>
                                        <td class="text-center" mat-cell flex-stretch *matCellDef="let element">
                                            {{element.life_cycle_status }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>Action</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="text-center">
                                            <button class="btn btn-outline-primary" (click)="view(element)">View
                                                Document</button>&nbsp;

                                            <button class="btn btn-outline-success"
                                                *ngIf="element.life_cycle_status=='PENDING'"
                                                (click)="approve(element)">Approve</button>&nbsp;

                                            <button class="btn btn-outline-danger"
                                                *ngIf="element.life_cycle_status=='PENDING'"
                                                (click)="cancel(element)">Reject</button>&nbsp;

                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20,30]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<!-- view photos -->

<!-- <div class="modal fade" id="memo_photo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>Memo Image</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">





        <div class="row justify-content-center" *ngIf="memoimageflag">

          <img [src]="imgURL3" height="500px" width="700px" />


        </div>





      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

      </div>
    </div>
  </div>
</div> -->
