import { Component, OnInit,AfterViewInit, ViewEncapsulation } from '@angular/core';
import {Helpers} from "../../helpers";
import {Router} from '@angular/router'
import { NgxSpinnerService } from "ngx-spinner";
import {MainService} from '../service/main.service';
import {PropertySettingService} from '../service/property-setting.service'
import { AuthenticationService } from '../../authentication.service';
@Component({
  selector: '.page-wrapper',
  templateUrl: './property-layouts.component.html',
  styleUrls: ['./property-layouts.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PropertyLayoutsComponent implements AfterViewInit {
  constructor(private settingService: PropertySettingService,private AuthenticationService:AuthenticationService,private spinner:NgxSpinnerService, private mainService: MainService,private router : Router) { }
  b_acct_id:any =-1;
  erpUser:any;
  allFields:any=[];
  allCodeValue:any=[];
  codeValueObj:any={};
  codeValueTechObj:any={};
  codeValueShowObj={};
  codeValueShowTempObj:any={};
  async ngOnInit() {
    if(localStorage.getItem('erpUser')==undefined || localStorage.getItem('erpUser')==null){
      this.router.navigate(['/login']);
    }else{
      this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
      this.b_acct_id = this.erpUser.b_acct_id;
      await this.getCodeValue();
    }
    await this.getAllLabel()
  }
  allLabel = []
	async getAllLabel() {
		var obj:any = new Object();
		obj['module_cd']='PROPERTY'
		console.log(obj,'obj for get all lebels')
		var resp = await this.AuthenticationService.getcomponentLabelvalueswithLanguage(JSON.stringify(obj));
		console.log(resp);
		if (resp['error'] == false) {
			this.AuthenticationService.allLabelProp = resp.data;
			var obj:any = new Object();
			for (let i = 0; i < resp.data.length; i++) {
				obj[resp.data[i]['language_cd'] + resp.data[i]['component_cd'] + resp.data[i]['label_no']] = resp.data[i]['value_to_show'];
			}
			console.log(obj)
			this.AuthenticationService.allLableShowObjProp = obj;
		} else {

		}
	}
  async getCodeValue(){
   this.spinner.show()
    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj:any={}
    var codeValueShowTempObj:any={};
    if(resp['error']==false){
      this.spinner.hide()
      for(var i=0;i<resp.data.length;i++){
        if(codeValueTempObj[resp.data[i]['field_code']]== undefined){
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] ={}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
    }else{
      this.spinner.hide()
    }
  }
  ngAfterViewInit() {

    // initialize layout: handlers, menu ...
    Helpers.initLayout();

  }

}

