<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        Manage Employee Posting
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Employee Posting List
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" data-toggle="tab"><i class="ti-settings"></i>
                                Create Employee
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" data-toggle="tab"><i class="ti-tasks"></i>
                                Update Employee Posting
                            </a>
                        </li>



                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">
                            <br>


                            <!-- <div class="row">
                                <div class="col-11 text-right">
                                    <button style="margin: 5px;" (click)="open_create()" class="btn btn-success">Add
                                        New Employee</button>
                                </div>
                            </div> -->


                            <div class="row">
                                <div class="col-12 text-left">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" [(ngModel)]="filterValue" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                            </div>


                            <div class="example-container" style="text-size-adjust: 2;">
                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="le_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Person Id
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.le_id}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Employee Id
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.emp_id}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Employee Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.emp_name}}
                                        </td>
                                    </ng-container>



                                    <ng-container matColumnDef="emp_fun">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Assigned Functional Area
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{empIdAssignedModule[element.arr_id]}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cost_center">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Assigned Cost Center
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{empIdPost[element.arr_id]}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Status
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.emp_status}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="effective_timestamp" style="width: 10%;">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Status Date Timestamp
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.valid_from}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action" style="width: 5%;">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action</th>
                                        <!--Action-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-primary" (click)="open_update(element)">Update</button>
                                            <!--  <button class="btn btn-outline-danger" *ngIf="element.emp_status=='ACTIVE'" (click)="inactiveEmployee(element)" style="margin: 5px;">INACTIVE</button>
                                            <button class="btn btn-outline-danger" *ngIf="element.emp_status=='INACTIVE'" (click)="deleteEmployee(element)" style="margin: 5px;">Delete</button>
                                            <button class="btn btn-outline-primary" *ngIf="element.emp_status=='INACTIVE'" (click)="activeEmployee(element)" style="margin: 5px;">ACTIVE</button> -->
                                            <!--Update-->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>

                            </div>




                            <!-- </div> -->
                        </div>

                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">


                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first" label="Search" completed="false">

                                    <br>
                                    <br>

                                    <div class="row">
                                        <div class="col-4 text-center">
                                            <h6>Enter Mobile Number/Email* </h6>
                                        </div>
                                        <div class="col-4">
                                            <input type="email" class="form-control" [(ngModel)]="joiningObj['credential']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-danger" (click)=open_list()>Cancel</button> &nbsp;&nbsp;
                                            <button class="btn btn-primary" (click)="search()">Search</button>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <!-- <button mat-button mat-raised-button class="btn btn-outline-success"
                                                 matStepperNext>Next</button> -->
                                        </div>
                                    </div>

                                </mat-step>
                                <br>
                                <br>
                                <br>
                                <mat-step [stepControl]="second" label="Identity">

                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Person Info:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-center">

                                            Person Id*
                                        </div>
                                        <div class="col-4">
                                            <input type="text" class="form-control" placeholder="system assiged" [(ngModel)]="joiningObj['le_id']" disabled>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-center">

                                            Person Name*
                                        </div>
                                        <div class="col-4">
                                            <input type="text" class="form-control" *ngIf="disable_flag==true" [(ngModel)]="joiningObj['emp_name']" disabled>
                                            <input type="text" class="form-control" *ngIf="disable_flag==false" [(ngModel)]="joiningObj['emp_name']">
                                        </div>

                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-4 text-center">

                                            Email*
                                        </div>
                                        <div class="col-4">
                                            <input type="email" class="form-control" *ngIf="disable_flag==true" [(ngModel)]="joiningObj['emp_email']" disabled>
                                            <input type="email" class="form-control" *ngIf="disable_flag==false" [(ngModel)]="joiningObj['emp_email']">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-center">

                                            Phone*
                                        </div>
                                        <div class="col-4">
                                            <input type="number" class="form-control" *ngIf="disable_flag==true" [(ngModel)]="joiningObj['emp_phone_no']" disabled>
                                            <input type="number" class="form-control" *ngIf="disable_flag==false" [(ngModel)]="joiningObj['emp_phone_no']">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-center">

                                            Person DOB*
                                        </div>
                                        <div class="col-4">
                                            <input type="date" class="form-control" *ngIf="dob_blank==false " [(ngModel)]="joiningObj['emp_dob']" disabled>
                                            <input type="date" class="form-control" *ngIf="dob_blank==true" [(ngModel)]="joiningObj['emp_dob']">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger" (click)=open_list()>Cancel</button> &nbsp;&nbsp;
                                            <!-- <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperPrevious>Back</button> &nbsp;&nbsp; -->
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-success" matStepperNext>Next</button>
                                            <!--Next-->


                                        </div>
                                    </div>



                                </mat-step>
                                <br>
                                <br>
                                <br>
                                <mat-step [stepControl]="third" label="Assignment">
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Employee Info:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Employee Id*:
                                            </h6>
                                        </div>
                                        <div class="col-4">
                                            <input type="text" class="form-control" [(ngModel)]="joiningObj['emp_id']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Functional Area:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Functional Area :
                                            </h6>
                                        </div>
                                        <div class="col-6">
                                            <ng-select [items]="allModule" (change)="selectedModule(joiningObj['module_cd'])" bindLabel="module_name" bindValue="module_cd" [multiple]="true" placeholder="" [selectableGroup]="true" [(ngModel)]="joiningObj['module_cd']" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="false" name="field_name">
                                            </ng-select>
                                        </div>
                                        <br>
                                    </div>
                                    <br>
                                    <hr>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Select Node Access:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Select Node :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                                Employee Node</button>
                                        </div>
                                        <br>
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Selected Node Path:
                                            </h6>
                                        </div>
                                        <div class="col-3 text-left">
                                            {{Obj['path_desc']}}
                                        </div>
                                    </div>
                                    <br>

                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger" (click)=open_list()>Cancel</button> &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperPrevious>Back</button> &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-success" matStepperNext >Next</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>

                                <br>
                                <br>
                                <br>

                                <mat-step [stepControl]="fourth" label="Done">
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Person Info:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-4 text-center">
                                            Person Id:
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" [(ngModel)]="joiningObj['le_id']" placeholder="system assigned" disabled>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-center">
                                            Person Name:
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" [(ngModel)]="joiningObj['emp_name']" disabled>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-center">
                                            Email:
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" [(ngModel)]="joiningObj['emp_email']" disabled>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-center">
                                            Phone:
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" [(ngModel)]="joiningObj['emp_phone_no']" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-center">
                                            Person DOB:
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" [(ngModel)]="joiningObj['emp_dob']" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-center">
                                            Employee Id:
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" [(ngModel)]="joiningObj['emp_id']" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Functional Area:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Functional Area :
                                            </h6>
                                        </div>
                                        <div class="col-6">
                                            <ng-select [items]="allModule" disabled (change)="selectedModule(joiningObj['module_cd'])" bindLabel="module_name" bindValue="module_cd" [multiple]="true" placeholder="" [selectableGroup]="true" [(ngModel)]="joiningObj['module_cd']" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="false" name="field_name">
                                            </ng-select>
                                        </div>
                                        <br>
                                    </div>
                                    <br>
                                    <hr>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Select Node Access:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>


                                    <div class="row">
                                        <br>
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Selected Node Path:
                                            </h6>
                                        </div>
                                        <div class="col-3 text-left">
                                            {{Obj['path_desc']}}
                                        </div>
                                    </div>
                                    <br>

                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger" (click)=open_list()>Cancel</button> &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperPrevious>Back</button> &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-success" (click)="addJoining()">Submit</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                    <br>
                                </mat-step>


                            </mat-horizontal-stepper>
                        </div>


                        <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-3">
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper2>
                                <mat-step [stepControl]="first" label="Assignment">
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Employee Info:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-left">
                                            Employee Id:
                                        </div>
                                        <div class="col-3">
                                            <input class="form-control" [(ngModel)]="joiningObj['emp_id']" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Functional Area:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Functional Area :<span style="color:red">*</span>
                                            </h6>
                                        </div>
                                        <div class="col-6">
                                            <ng-select [items]="allModule" (change)="selectedModule(joiningObj['module_cd'])" bindLabel="module_name" bindValue="module_cd" [multiple]="true" placeholder="" [selectableGroup]="true" [(ngModel)]="joiningObj['module_cd']" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="false" name="field_name">
                                            </ng-select>
                                        </div>
                                        <br>
                                    </div>
                                    <br>
                                    <hr>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Select Node Access:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>


                                    <div class="row">
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Select Node : <span style="color: red;">*</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                                Employee Node</button>
                                        </div>
                                        <br>
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Selected Node Path:
                                            </h6>
                                        </div>
                                        <div class="col-3 text-left">
                                            {{Obj['path_desc']}}
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger" (click)=open_list()>Cancel</button> &nbsp;&nbsp;
                                            <!-- <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperPrevious>Back</button> &nbsp;&nbsp; -->
                                            <!--Back-->
                                            <button mat-button mat-raised-button (click)=openlist() class="btn btn-outline-success" (click)="checkFunction()">Next</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                    <br>
                                </mat-step>
                                <br>
                                <br>
                                <br>

                                <mat-step [stepControl]="second" label="Done">
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Employee Info:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-left">
                                            Employee Id:
                                        </div>
                                        <div class="col-3">
                                            <input class="form-control" [(ngModel)]="joiningObj['emp_id']" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <br> <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Currently Functional Area:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Functional Area :
                                            </h6>
                                        </div>
                                        <div class="col-6">
                                            <ng-select disabled [items]="allModule" (change)="selectedModule(joiningObj['old_module_cd'])" bindLabel="module_name" bindValue="module_cd" [multiple]="true" placeholder="" [selectableGroup]="true" [(ngModel)]="joiningObj['old_module_cd']" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="false" name="field_name">
                                            </ng-select>
                                        </div>
                                        <br>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Proposed Functional Area:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Functional Area :
                                            </h6>
                                        </div>
                                        <div class="col-6">
                                            <ng-select disabled [items]="allModule" (change)="selectedModule(joiningObj['module_cd'])" bindLabel="module_name" bindValue="module_cd" [multiple]="true" placeholder="" [selectableGroup]="true" [(ngModel)]="joiningObj['module_cd']" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="false" name="field_name">
                                            </ng-select>
                                        </div>
                                        <br>
                                    </div>
                                    <br>
                                    <hr>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Currently Select Node Access:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>


                                    <div class="row">

                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Currently Selected Node Path:
                                            </h6>
                                        </div>
                                        <div class="col-3 text-left">
                                            {{Obj['old_path_desc']}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                Proposed Select Node Access:
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>


                                    <div class="row">

                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Proposed Selected Node Path:
                                            </h6>
                                        </div>
                                        <div class="col-3 text-left">
                                            {{Obj['path_desc']}}
                                        </div>
                                    </div>

                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger" (click)=open_list()>Cancel</button> &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperPrevious>Back</button> &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-success" (click)="updateprojhier()">Update</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                    <br>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ngx-spinner>Loading....</ngx-spinner>






<!-- MODAL -->

<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Employee Node</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                    <div class="col-3"></div>
                    <div class="col-3" *ngIf="i==0">
                        <h6 style="margin-left: 2%;">Select Node : <span style="color: red;"> *</span></h6>
                        <!-- -->
                    </div>
                    <div class="col-3" *ngIf="i!=0"></div>
                    <div class="col-1">

                        <span *ngIf="item['level']"> Level </span>{{item['level']}}
                        <!---  -->
                    </div>
                    <div class="col-3">
                        <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false" placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true" (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true">
                        </ng-select>

                    </div>
                    <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                        <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                aria-hidden="true"></i></button> &nbsp;&nbsp;
                        <button (click)="remove(item,i)" class="btn btn-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;">  Current Node Path :<span style="color: red;">*</span>
                        </h6>
                    </div>
                    <div class="col-6">
                        {{Obj['path_desc']}}
                    </div>

                </div>
                <br>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_act()">Submit</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
