import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BookletPurchaseService } from '../../service/booklet-purchase.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MetadataService } from '../../service/metadata.service';
import { AuthenticationService } from '../../../authentication.service';
import { MainService } from '../../service/main.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as  pdfFonts from "pdfmake/build/vfs_fonts";
import Swal from 'sweetalert2';
import { SaleService } from '../../service/sale.service';
declare var $: any;

@Component({
  selector: 'app-booklet-purchase',
  templateUrl: './booklet-purchase.component.html',
  styleUrls: ['./booklet-purchase.component.css']
})
export class BookletPurchaseComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  Obj: any = {}
  globleElement: any = {}
  constructor(public auth: AuthenticationService, private sale_service: SaleService, private metadataService: MetadataService, private bookletPurchaseService: BookletPurchaseService,
    private snackBar: MatSnackBar, public mainService: MainService, private spinner: NgxSpinnerService) { }
  dataSource: any;
  displayedColumns = ['party_id', 'party_name', 'booklet_purchase_date', 'booklet_amount', 'booklet_challan_no', 'arr_status_code', 'pmnt_status', 'stl_status', 'action'];
  partyArr: any = [];
  schemeArr: any = [];
  schemeObj: any = {};
  partyObj: any = {};
  allBookletPurchase: any = [];
  bookletPurchaseObj: any = {};
  allSchemes: any = [];
  b_acct_id: any;
  erpUser: any;
  selectedSchemeCode: any = "";

  allSubSchemes: any = [];
  partyName: any = '';

  bookletAmount: any = 0;

  async ngOnInit() {
    // this.Obj
    this.Obj['reason'] = ''

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    // await this.getAllSchemes();
    await this.getAllOffers()
  }
  offer_code_to_value: any = {}
  allOffer: any = []
  async getAllOffers() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'

    console.log(obj)
    var resp = await this.sale_service.getAllOffers(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.allOffer = resp['data']
      console.log(resp['data'], 'fetched data')
      let dummy = []
      for (let i = 0; i < this.allOffer.length; i++) {
        this.allOffer[i]['application_start_dt'] = this.allOffer[i]['application_start_dt'].split('T')[0]
        this.allOffer[i]['application_end_dt'] = this.allOffer[i]['application_end_dt'].split('T')[0]
        this.offer_code_to_value[this.allOffer[i]['offer_cd']] = this.allOffer[i]['offer_name']
        this.allOffer[i]['desc'] = this.allOffer[i]['offer_cd'] + ' - ' + this.allOffer[i]['offer_name']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  dataToPrint = []
  async print1() {
    this.spinner.show()
    let data = []
    data = this.allBookletPurchase
    var txt = this.erpUser['account_name'] + '(' + this.erpUser['account_short_name'] + ')'
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },
      pageOrientation: 'landscape',

      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    var header0 = {
      columns: [
        {
          width: '*',
          text: 'All Booklet Purchase',
          bold: true,
          alignment: 'center'
        }

      ],
    }
    var header9 = {
      columns: [
        {
          width: '*',
          text: '* Note : This is a computer generated document.',
          bold: true,
          alignment: 'left'
        }

      ],
    }
    var header1 = {
      columns: [
        {
          width: '*',
          text: 'Offer Name :',
          bold: true
        },

        {
          width: '*',
          text: this.offer_code_to_value[this.bookletPurchaseObj['offer_cd']]
        },
        {
          width: '*',
          text: ' ',
          bold: true
        },

        {
          width: '*',
          text: ''
        }

      ],
    }


    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    var tbl = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*'],
        body: [
          ['Applicant  ID', 'Applicant Name', 'Bookle Purchase Date', 'Challan ID', 'Booklet Purchase Amount', 'Status']
        ],
      }
    };
    console.log(data, 'data')
    dd.content.push(tbl);
    for (var i = 0; i < data.length; i++) {
      var arr = []
      arr.push(data[i]['party_id']);
      arr.push(data[i]['party_name']);
      arr.push(this.mainService.dateFormatChange(data[i].booklet_purchase_date));
      arr.push(data[i]['booklet_challan_no']);
      arr.push(data[i]['booklet_amount']);
      arr.push(data[i]['arr_status_code']);
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push(header9);
    this.spinner.hide()
    pdfMake.createPdf(dd).download("bookletPurchase");
  }
  async sedtToAccount(data: any) {
    var today: any = new Date();
    var date: any = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let objToSend: any = {}
    let obj: any = {}
    obj['evt_grp_dt'] = date
    obj['bus_event_type'] = 'BOOKLETPURCHASE'
    obj['demand_id'] = 1
    obj['party_id'] = data['party_id']
    obj['event_code'] = 'PROPERTY' + data['offer_cd']
    obj['event_id'] = data['id']
    obj['event_ln_id'] = data['id']
    obj['bank_acct_num'] = ''
    obj['event_desc'] = data['offer_desc']
    obj['txn_amt'] = data['booklet_amount']
    obj['invoice_id'] = data['id']
    obj['create_user_id'] = this.erpUser['user_id']
    obj['arr_num'] = data['id']
    let arr: any = []
    objToSend['data'] = arr
    objToSend['local_doc_no'] = data['offer_cd']
    objToSend['local_doc_desc'] = 'Booklet Purchase Of ' + data['offer_desc']
    objToSend['is_rec_or_pay'] = 'RECEIVABLE'
    objToSend['module_cd'] = 'PROPERTY'
    objToSend['module_doc_type'] = 'BOOKLETPURCHASE'
    objToSend['status'] = 'APPROVED'
    objToSend['total_amt'] = data['booklet_amount']
    objToSend['doc_type'] = 'CHALLAN'
    objToSend['req_data'] = "{}"
    objToSend['create_user_id'] = this.erpUser['user_id']
    objToSend['b_acct_id'] = this.b_acct_id
    objToSend['user_id'] = this.erpUser.user_id
    arr.push(obj)
    console.log(objToSend, data)
    let resp = await this.sale_service.sendToAccount(objToSend)
    console.log(resp, 'sendtoAccount')
    if (resp['error'] == false) {
      console.log('Sent to Account')
    } else {
      Swal.fire('Error', 'Error While Creating', 'error')
    }
  }


  async changeScheme() {
    this.spinner.show();
    this.Obj['reason'] = ''

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['offer_cd'] = this.bookletPurchaseObj['offer_cd']
    // obj['sub_scheme_code'] = this.bookletPurchaseObj['sub_scheme_code']
    var resp = await this.bookletPurchaseService.getAllbookletpurchase(obj);
    if (resp['error'] == false) {
      console.log(resp['data'], 'data')
      this.allBookletPurchase = resp.data;
      this.dataSource = new MatTableDataSource(this.allBookletPurchase);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();

    } else {
      this.spinner.hide();

      this.snackBar.open("Error occured while getting BookletPurchases", 'Error', {
        duration: 5000,
      });
    }
  }
  // BOOKLET_PURCHASE_PENDING
  async approve(element: any, i: any) {
    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = element.id;
    obj['arr_status_code'] = 'BOOKLET_PURCHASED'
    obj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.bookletPurchaseService.changeStatusbookletpurchase(obj);
    if (resp['error'] == false) {
      Swal.fire('Success...', 'Approved Successfully', 'success')
      // await this.sedtToAccount(element)
      await this.changeScheme();
      this.spinner.hide();


      obj['msg'] = "You have successfully purchased your booklet for " + element['offer_name'] + ".Your Application Number is " + element.party_id + " and Booklet amount is " + element['booklet_amount']
      obj['url'] = this.mainService.uiUrl + "?acct=" + this.b_acct_id
      obj['subject'] = 'Booklet Status'
      obj['reciever_mail'] = element.party_email
      obj['account'] = this.erpUser['account_name']

      var resp2 = await this.metadataService.SendEmail(obj)
      if (resp2['error'] != false) {
        this.snackBar.open("Error while Sending mail", 'Error', {
          duration: 2000,
        });
      }
    } else {
      this.spinner.hide();

      this.snackBar.open("Error occured while change status  of BookletPurchases", 'Error', {
        duration: 5000,
      });
    }
  }


  i: any
  async reject(element: any, i: any) {
    $('#myModal1').modal('show');
    if (this.Obj['reason'] != '') {
      this.spinner.show();
      var obj: any = new Object();
      obj['msg'] = "Your Application number " + element.party_id + " having  some inforamtion issue .That's why your application is being  REJECTED . please go through the below given link and reasion is : " + this.Obj['reason'] + "."

      obj['b_acct_id'] = this.b_acct_id;
      obj['id'] = element.id;
      obj['arr_status_code'] = 'BOOKLET_PURCHASE_REJECTED'
      obj['update_user_id'] = this.erpUser.user_id;
      var resp = await this.bookletPurchaseService.changeStatusbookletpurchase(obj);
      if (resp['error'] == false) {
        await this.changeScheme();
        this.spinner.hide();
        // obj['msg']="Your Application number "+ element.party_id +" having  some inforamtion issue .That's why your application is being  REJECTED . please go through the below given link and reasion is : "+this.Obj['reason']
        obj['url'] = this.mainService.uiUrl + "?acct=" + this.b_acct_id
        obj['subject'] = 'Booklet Status'
        obj['reciever_mail'] = element.party_email
        obj['account'] = this.erpUser['account_name']

        var resp2 = await this.metadataService.SendEmail(obj)
        if (resp2['error'] != false) {
          this.snackBar.open("Error while Sending mail", 'Error', {
            duration: 2000,
          });
        }
        this.Obj['reason'] = ''
        // this.Obj['reason']={}
      } else {
        this.spinner.hide();

        this.snackBar.open("Error occured while change status of BookletPurchases", 'Error', {
          duration: 5000,
        });
      }
    } else {
      Swal.fire('Information', 'Please Give The Reason', 'info')
      this.globleElement['element'] = element
      this.globleElement['i'] = i

    }
  }
  /*  async addBookletPurchase(){
     this.bookletPurchaseObj['b_acct_id'] = this.b_acct_id;
     this.bookletPurchaseObj['txn_amt'] = this.bookletAmount;
     this.bookletPurchaseObj['create_user_id']=this.erpUser.user_id;
     this.spinner.show();
     var resp = await this.bookletPurchaseService.addbookletpurchase(this.bookletPurchaseObj);
     if (resp['error'] == false) {
       await this.getAllBookletPurchase();
       this.spinner.hide();
       this.snackBar.open("Added Successfully", 'Success', {
         duration: 5000,
       });
     } else {

       this.spinner.hide();
       this.snackBar.open("Error occured while Adding Booklet Purchase", 'Error', {
         duration: 5000,
       });
     }

   }
   openUpdate(element,i){
     this.bookletPurchaseObj=element;
     $('.nav-tabs a[href="#tab-3"]').tab('show')
   }


   async updateBookletPurchase(){
     this.bookletPurchaseObj['b_acct_id'] = this.b_acct_id;
     this.bookletPurchaseObj['update_user_id']=this.erpUser.user_id;

     this.spinner.show();
     var resp = await this.bookletPurchaseService.updatebookletpurchase(this.bookletPurchaseObj);
     if (resp['error'] == false) {
       this.spinner.hide();
       this.snackBar.open("Updated Successfully", 'Success', {
         duration: 5000,
       });
     } else {

       this.spinner.hide();
       this.snackBar.open("Error occured while Updating Booklet Purchase", 'Error', {
         duration: 5000,
       });
     }
   } */

  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
