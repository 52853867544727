import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { stringify } from 'querystring';
@Injectable({
  providedIn: 'root'
})
export class LandInputService {





  httpUrl;


  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/property/dashboard";
  }

  // async  getDashBoardCount(obj:any){
  //   const resp = await this.http.get<any>(this.httpUrl+'/getAllCount' +JSON.stringify(obj)).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async view_Doc(obj:any){
  //   const resp = await this.http.post(this.main.httpUrl + '/property/location/getlocationImage', obj, { responseType: 'blob' }).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
  async createLandInput(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/locationGroup/createlocationGroup', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getLandInputData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/locationGroup/getlocationGroupdata' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getlocationGroupTargetdata(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/locationGroup/getlocationGroupTargetdata' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateLandInput(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/locationGroup/updatelocationGroup', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async deleteLandInput(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/locationGroup/inactivelocationGroup', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  //plan layout Service//
  async createpropertyGroup(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/PropertyGroup/createpropertyGroup', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getpropertygroup(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/PropertyGroup/getpropertygroup' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
// approved planlayout for offer//uttam
  async getAllplanLayoutForOffer(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/PropertyGroup/getAllplanLayoutForOffer' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getpropertyGroupdata(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/PropertyGroup/getallpropertyGroupdata' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async inactivePropertyGroup(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/PropertyGroup/inactivePropertyGroup', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async updatepropertyGroup(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/PropertyGroup/updatepropertyGroup', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  // api for property inventory
  async createproperties(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/properties/createproperties', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getpropertiesData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/properties/getpropertiesData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getpropertiesWithFeature(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/properties/getpropertiesWithFeature' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteProperties(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/properties/deleteProperties', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async updateproperties(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/properties/updateproperties', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async createCodeValueForPlanLayout(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/PropertyGroup/createcodevalue', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getProImages(obj: Object) {
    const resp = await this.http.post(this.main.httpUrl + '/property/properties/getImage', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }else{
      return
    }
  }
  async deleteImg(obj:any){
    const resp = await this.http.put(this.main.httpUrl + '/property/properties/delProImg', obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }else{
      return
    }
  }
  async addProVid(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/property/properties/addProVideo', obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }else{
      return
    }
  }
  async getAllVideo(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/property/properties/getAllVideo', obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }else{
      return
    }
  }

  async delProVid(obj:any){
    console.log(obj)
    const resp = await this.http.post(this.main.httpUrl + '/property/properties/delProVid', obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }else{
      return
    }
  }
  async getPropertyImagesObj(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/properties/getAllPropertyImage'+ obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getPropertyTypeXrePlan(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/properties/getAllproType'+ obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async changePlanLayoutStatus(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/property/PropertyGroup/changePlanLayoutStatus', obj).toPromise().then(res => {

      return res;
    });
    return resp;
  }
}
