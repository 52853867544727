<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Tender List</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item" *ngIf="list">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                All Tender List</a>
                        </li>
                        <li class="nav-item" *ngIf="create || update_tender">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"></i> Create
                                Tender </a>
                        </li>
                        <li class="nav-item" *ngIf="details">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="fa fa-plus-circle"></i> View
                                Details </a>
                        </li>




                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list" id="tab-1">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="backToCreate()">Create
                                        Tender</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4"></div>
                                <div class="col-4"></div>
                            </div>
                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" >


                                    <ng-container matColumnDef="Sr.No">
                                        <th mat-header-cell *matHeaderCellDef class="center"> Sr No.
                                        </th>
                                        <td mat-cell *matCellDef="let element let i = index"> {{i+1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tender_id">
                                        <th mat-header-cell *matHeaderCellDef class="center" > Tender ID
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.nic_tender_id}}

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="ref_no">
                                        <th mat-header-cell *matHeaderCellDef class="center"> Ref.No.
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.tender_ref_no}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="work_title">
                                        <th mat-header-cell *matHeaderCellDef class="center" style="width:12%">Work Title
                                            / Tender Name
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.work_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="publish_date" >
                                        <th mat-header-cell *matHeaderCellDef class="center" >Tender
                                            Publish date
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.publish_date}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="last_date_bit_sub" >
                                        <th mat-header-cell *matHeaderCellDef class="center">Last date
                                            for Bid Sub. </th>
                                        <td mat-cell *matCellDef="let element"> {{element.sale_end_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bit_opning_date">
                                        <th mat-header-cell *matHeaderCellDef class="center"> Bid
                                            Opening Date </th>
                                        <td mat-cell *matCellDef="let element"> {{element.bid_opening_date}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="tender_amount">
                                        <th mat-header-cell *matHeaderCellDef class="center"> Tender
                                            Amount </th>
                                        <td mat-cell *matCellDef="let element"> {{element.tender_value}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="document_amount">
                                        <th mat-header-cell *matHeaderCellDef class="center"> Documet
                                            Amount</th>
                                        <td mat-cell *matCellDef="let element"> {{element.doc_amount}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emd_amount">
                                        <th mat-header-cell *matHeaderCellDef class="center"> EMD Amount
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.emd_amount}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="center"> Status
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.life_cycle_status}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="center" style="width:14%"> Action
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <button class="btn btn-outline-warning"
                                                (click)="viewDetails(element,' ')">View
                                                Details</button>
                                            <button type="button" class="btn btn-outline-success "  *ngIf="element.life_cycle_status==='ACTIVE'"
                                                (click)="viewDetails(element,'update')">Update

                                            </button>

                                            <button  *ngIf="element.life_cycle_status==='APPROVED' " class="btn btn-outline-danger" (click)="openCalcel(element)">Cancel

                                            </button>
                                            <button class="btn btn-outline-info"
                                                *ngIf="element.life_cycle_status==='ACTIVE' "
                                                (click)="Appr(element)">Add Approval</button>
                                            <button class="btn btn-outline-warning" type="button"
                                                *ngIf="element.life_cycle_status==='UNDERAPPROVAL' ||  element.life_cycle_status==='APPROVED' "
                                                (click)="viewStatus(element)"> View Status </button>
                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>

                                </table>
                            </div>
                            <mat-paginator #paginate [pageSizeOptions]="[5,10,20]" showFirstLastButtons></mat-paginator>
                        </div>


                        <div *ngIf="create || update_tender" id="tab-2">

                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="backToList()">Back To List</button>
                                </div>
                            </div>

                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first" label="Step-1">

                                    <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                        <legend class="w-auto "
                                            style="color: rgb(184, 47, 47);display: inline-block; width: 7rem;">
                                            <h6>Basic Details</h6>
                                        </legend>

                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Project :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectProject()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{project_obj['project_path_desc']}}
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Activity :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectActivity()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{act_obj['project_path_desc']}}
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Budget :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectBudget()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{bud_obj['project_path_desc']}}
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Product :</h6>
                                            </div>
                                            <div class="col-1">
                                                <button (click)="selectProduct()"
                                                    class="btn btn-outline-primary">Select</button> &nbsp;&nbsp;
                                            </div>
                                            <div class="col-5">
                                                {{prod_obj['project_path_desc']}}
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <h6>Tender ID: </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="tenderObj['nic_tender_id']">
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6>Tender Reference No.: <span class="text-danger">*</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="tenderObj['tender_ref_no']">
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <h6>Tender Type:<span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="emdMainService.codeValueTechObj['EMD02']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="tenderObj['tender_type']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6> Form of Contract:<span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="tenderObj['form_of_contract']">
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <h6>Tender Category: </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="emdMainService.codeValueTechObj['EMD03']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="tenderObj['tender_category']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6>Tender Sub-Category: </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="emdMainService.codeValueTechObj['EMD04']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..."
                                                    [(ngModel)]="tenderObj['tender_sub_category']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <h6>No. of Covers: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="emdMainService.codeValueTechObj['EMD05']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="tenderObj['cover_no']"
                                                    (change)="numberOfCoverInformation(tenderObj['cover_no'],'')"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6> Payment Mode: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="emdMainService.codeValueTechObj['EMD06']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="tenderObj['payment_mode']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                    </fieldset>
                                    <br> <br>
                                    <!-- --------------------------Second Field Set --------------------------------------- -->

                                    <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                        <legend class="w-auto "
                                            style="color: rgb(184, 47, 47);display: inline-block; width: 9rem;">
                                            <h6>Work Item Details</h6>
                                        </legend>



                                        <br>
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3 text-center" text-center>
                                                <h6> Work Title / Tender name: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3 text-center">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="tenderWork['work_name']">
                                            </div>
                                            <div class="col-2"> </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <h6>Tender Value: <span class="text-danger">*</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="tenderWork['tender_value']">
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6>Contract Type: </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="emdMainService.codeValueTechObj['EMD07']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="tenderWork['contact_type']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>

                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <h6> Bid Validity (days): <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="tenderWork['bid_validity']">
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6> Period of Work: <span class="text-danger">*</span></h6>

                                            </div>
                                            <div class="col-1">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="tenderWork['work_period']">
                                            </div>
                                            <div class="col-2">
                                                <ng-select [items]="emdMainService.codeValueTechObj['EMD08']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="tenderWork['work_period_type']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <h6> Location: </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="tenderWork['location']">
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6>Bid Opening Place: </h6>

                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="tenderWork['bid_opening_palce']">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <h6>Pre Bid Meeting Place: </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="tenderWork['prebid_meeting_place']">
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6>Pre Bid Meeting Address: </h6>

                                            </div>
                                            <div class="col-3">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="tenderWork['prebid_meeting_address']">
                                            </div>
                                        </div>
                                        <br>
                                    </fieldset>
                                    <br>

                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>Next</button>
                                        </div>
                                    </div>
                                </mat-step>

                                <mat-step [stepControl]="second" label="Step-2">
                                    <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                        <legend class="w-auto "
                                            style="color: rgb(184, 47, 47);display: inline-block; width: 6rem;">
                                            <h6>Critical Dates</h6>
                                        </legend>



                                        <br>
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3 text-center" text-center>
                                                <h6> Tender Publish Date: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3 text-center">
                                                <input type="date" class="form-control"
                                                    [(ngModel)]="tenderObj['publish_date']">
                                            </div>
                                            <div class="col-2"> </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <h6>Bid Opening Date: <span class="text-danger">*</span> </h6>
                                            </div>
                                            <div class="col-3">

                                                <input type="date" class="form-control"
                                                    [(ngModel)]="tenderObj['bid_opening_date']">
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6>Sale Start Date: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" class="form-control"
                                                    [(ngModel)]="tenderObj['sale_start_date']">
                                            </div>

                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <h6>Sale End Date: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="date" class="form-control"
                                                    [(ngModel)]="tenderObj['sale_end_date']">
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6>Pre Bid Meeting Date: <span class="text-danger">*</span></h6>

                                            </div>
                                            <div class="col-3">
                                                <input type="date" class="form-control"
                                                    [(ngModel)]="tenderObj['prebid_meeting_date']">
                                            </div>
                                        </div>
                                        <br>

                                    </fieldset>
                                    <br><br>
                                    <!-- ---------------------------Second Fieldset -------------------------------- -->
                                    <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                        <legend class="w-auto "
                                            style="color: rgb(184, 47, 47);display: inline-block; width: 20rem;">
                                            <h6>Document Amount & Payment Instruments</h6>
                                        </legend>



                                        <br>
                                        <div class="row">
                                            <div class="col-3 ">
                                                <h6> Document Fee Type: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="emdFeeType" bindLabel="type" bindValue="type"
                                                    [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="transtionObj['doc_amount_type']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6>Document Fee: <span class="text-danger">*</span> </h6>
                                            </div>
                                            <div class="col-3 d-flex"
                                                *ngIf="transtionObj['doc_amount_type']=='Percentage'">

                                                <input type="number" class="form-control"
                                                    [(ngModel)]="transtionObj['doc_amount']" style="width: 6rem;">
                                                <span class="d-flex"> <span style="padding: .5rem 1rem;">
                                                        <h6>of </h6>
                                                    </span>
                                                    <ng-select [items]="doc_amount_depend" bindLabel="value"
                                                        bindValue="code" [multiple]="false" placeholder="Select"
                                                        [(ngModel)]="transtionObj['doc_amount_depend']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" style="width:8rem">
                                                    </ng-select>
                                                </span>

                                            </div>

                                            <div class="col-3 text-left"
                                                *ngIf="transtionObj['doc_amount_type']=='Fixed'">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="transtionObj['doc_amount']" style="width: 9rem;">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3">
                                                <h6>EMD Amount Type: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="emdFeeType" bindLabel="type" bindValue="type"
                                                    [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="transtionObj['emd_amount_type']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6>EMD Amount: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3 d-flex"
                                                *ngIf="transtionObj['emd_amount_type']=='Percentage'">

                                                <input type="number" class="form-control"
                                                    [(ngModel)]="transtionObj['emd_amount']" style="width: 6rem;">
                                                <span class="d-flex"> <span style="padding: 0.5rem 1rem;">
                                                        <h6> of </h6>
                                                    </span>
                                                    <ng-select [items]="doc_amount_depend" bindLabel="value"
                                                        bindValue="code" [multiple]="false" placeholder="Select"
                                                        [(ngModel)]="transtionObj['emd_amount_depend']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" style="width:8rem">
                                                    </ng-select>
                                                </span>

                                            </div>

                                            <div class="col-3 text-left"
                                                *ngIf="transtionObj['emd_amount_type']=='Fixed'">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="transtionObj['emd_amount']" style="width: 9rem;">
                                            </div>
                                        </div>
                                        <br>
                                        <br>
                                        <div>
                                            <div class="row">
                                                <div class="col-2">
                                                    <h6>Select Gateway </h6>
                                                </div>
                                                <div class="col-2">
                                                    <ng-select [items]="admindata" bindLabel="gateway_name"
                                                        bindValue="gateway_id" [multiple]="false"
                                                        placeholder="Select..." [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <ng-template ng-label-tmp let-item="item">
                                                            <div (click)="getAccountbyGatewayID(item.gateway_id)">
                                                                {{item.gateway_name}}
                                                            </div>
                                                        </ng-template>
                                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                                            <div (click)="getAccountbyGatewayID(item.gateway_id)">
                                                                {{item.gateway_name}}
                                                            </div>
                                                        </ng-template>

                                                    </ng-select>
                                                </div>

                                            </div>

                                            <div class="row" *ngIf="gateWayId">

                                                <div class="col-12 ml-3">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="row">#</th>
                                                                <th scope="col">Purpose</th>
                                                                <th scope="col">Account number</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of gatWayList;let i=index ">
                                                                <ng-container
                                                                    *ngIf="gatWayList[i].gateway_id==gateWayId">
                                                                    <th scope="row" class="text-left">{{i+1}}</th>
                                                                    <td class="text-left">{{item.purpose}}</td>
                                                                    <td class="text-left">{{item.account_no}}</td>
                                                                    <td class="text-left"> <input type="checkbox"
                                                                            [checked]="gatWayList[i].selected"
                                                                            (change)="clickingcheckbox(i,item.id)"></td>
                                                                </ng-container>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                    </fieldset>
                                    <br><br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>Pervoius</button>
                                            &nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>Next</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>

                                <mat-step [stepControl]="third" label="Done">

                                    <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                        <legend class="w-auto "
                                            style="color: rgb(184, 47, 47);display: inline-block; width: 13rem;">
                                            <h6>Upload Tender Documents</h6>
                                        </legend>

                                        <div class="row">
                                            <div class="col-12 text-right">
                                                <button style="margin-right: 3%;" class="btn btn-outline-success"
                                                    (click)=" addDocumentRow()">+
                                                    Add</button>
                                            </div>
                                        </div>
                                        <div class="col-12 ml-3">
                                            <table class="table">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th style="width:17%">Sr. No.</th>
                                                        <th style="width:21%">Document Type</th>
                                                        <!-- <th scope="col">Document Name</th> -->
                                                        <th style="width:26%">Upload File</th>
                                                        <th style="width:20%">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of document_details;let i=index " class="text-center">

                                                        <td  class="text-center">{{i+1}}</td>
                                                        <td class="text-center"> <ng-select
                                                                [items]="emdMainService.codeValueTechObj['EMD09']"
                                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                                placeholder="Select..." [(ngModel)]="item['doc_type']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" style="width:80%;margin-left: 10%;">
                                                            </ng-select></td>
                                                        <!-- <td class="text-left">
                                                            <input type="text" [(ngModel)]="item['doc_name']"
                                                                class="form-control" disabled>
                                                        </td> -->

                                                        <td class="text-center">
                                                            <input #file type="file" name="pimage" ng2FileSelect  accept=".pdf"
                                                                [uploader]="uploader1" class="form-control"
                                                                (change)="onFileUpload($event,file.files,i)" style="width:80%;margin-left: 20%;">
                                                        </td>
                                                        <td class="text-center">
                                                            <button class="btn btn-danger"
                                                                (click)="removeDocumentRow(i)"><i class="fa fa-trash"
                                                                    aria-hidden="true"></i></button>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </fieldset>
                                    <br><br>

                                    <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                        <legend class="w-auto "
                                            style="color: rgb(184, 47, 47);display: inline-block; width: 9rem;">
                                            <h6>Cover Information</h6>
                                        </legend>


                                        <div class="col-12 ml-3">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="row">Cover No.</th>
                                                        <th scope="col">Details in Cover</th>
                                                        <th scope="col">Description </th>
                                                        <th scope="col">Document Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of cover_information;let i=index ">
                                                        <td scope="row" class="text-left">

                                                            {{i+1}}
                                                        </td>
                                                        <td class="text-left"> <ng-select
                                                                [items]="emdMainService.codeValueTechObj['EMD10']"
                                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                                placeholder="Select..."
                                                                [(ngModel)]="item['doc_formate']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" name="chart_of_account0">
                                                            </ng-select></td>
                                                        <td class="text-left"><input type="text" name="" id=""
                                                                [(ngModel)]="item['cover_desc']" class="form-control">
                                                        </td>
                                                        <td class="text-left">
                                                            <ng-select
                                                                [items]="emdMainService.codeValueTechObj['EMD13']"
                                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                                placeholder="Select..." [(ngModel)]="item['cover_type']"
                                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                                [closeOnSelect]="true" name="chart_of_account0">
                                                            </ng-select>
                                                        </td>


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </fieldset>
                                    <br><br>

                                    <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                        <legend class="w-auto "
                                            style="color: rgb(184, 47, 47);display: inline-block; width: 12rem;">
                                            <h6>Tender Inviting Authority</h6>
                                        </legend>


                                        <div class="row">
                                            <div class="col-3">
                                                <h6>Name: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" [(ngModel)]="authorityObj['name']"
                                                    class="form-control">
                                            </div>
                                            <div class="col-3">
                                                <h6>Designation:<span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="emdMainService.codeValueTechObj['EMD11']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="authorityObj['designation']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3">
                                                <h6>Contact Detail: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3"> <input type="text"
                                                    [(ngModel)]="authorityObj['contact_detail']" class="form-control">
                                            </div>
                                            <div class="col-3">
                                                <h6>Department: <span class="text-danger">*</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="emdMainService.codeValueTechObj['EMD12']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="authorityObj['department']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                    </fieldset>
                                    <br>

                                    <div class="row">
                                        <div class="col-3">
                                            <h6>Website Link (If any) : </h6>
                                        </div>
                                        <div class="col-9">
                                            <input type="text" [(ngModel)]="tenderObj['web_link']" class="form-control">
                                        </div>
                                    </div>
                                    <br><br>

                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>Pervoius</button>
                                            &nbsp;
                                            <button *ngIf="create" mat-button mat-raised-button
                                                class="btn btn-outline-primary" (click)="submit()">Submit</button>

                                            <button *ngIf="update_tender" mat-button mat-raised-button
                                                class="btn btn-outline-primary" (click)="update()">Update</button>
                                        </div>
                                    </div>

                                </mat-step>


                            </mat-horizontal-stepper>

                        </div>



                        <div *ngIf="details" id="tab-3">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="backToList()">Back To List</button>
                                </div>
                            </div>


                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 7rem;">
                                    <h6>Basic Details</h6>
                                </legend>


                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Tender ID: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['nic_tender_id']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Tender Reference No.: <span class="text-danger">*</span> </h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['tender_ref_no']}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Tender Type:<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['tender_type']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> Form of Contract:<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['form_of_contract']}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Tender Category: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['tender_category']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Tender Sub-Category: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['tender_sub_category']}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>No. of Covers: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['cover_no']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> Payment Mode: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['payment_mode']}}
                                    </div>
                                </div>
                                <br>
                            </fieldset>
                            <br> <br>

                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 9rem;">
                                    <h6>Work Item Details</h6>
                                </legend>



                                <br>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-3 text-center" text-center>
                                        <h6> Work Title / Tender name: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-6 text-left">
                                        {{tenderObj['work_name']}}
                                    </div>

                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Tender Value: <span class="text-danger">*</span> </h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['tender_value']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Contract Type: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['contact_type']}}
                                    </div>

                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6> Bid Validity (days): <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['bid_validity']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6> Period of Work: <span class="text-danger">*</span></h6>

                                    </div>
                                    <div class="col-1 d-flex">
                                        {{tenderObj['work_period']}}
                                        <span class="d-flex">
                                            {{tenderObj['work_period_type']}}
                                        </span>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6> Location: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['location']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Bid Opening Place: </h6>

                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['bid_opening_palce']}}
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Pre Bid Meeting Place: </h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['prebid_meeting_place']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Pre Bid Meeting Address: </h6>

                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['prebid_meeting_address']}}
                                    </div>
                                </div>

                            </fieldset>
                            <br> <br>

                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 7rem;">
                                    <h6>Critical Dates</h6>
                                </legend>



                                <br>
                                <div class="row">

                                    <div class="col-3 text-center">
                                        <h6> Tender Publish Date: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['publish_date'] | date: 'dd/MM/yyyy'}}
                                    </div>
                                    <!-- <div class="col-3"></div>
                                    <div class="col-3"> </div> -->
                                    <div class="col-3 text-center">
                                        <h6>Bid Opening Date: <span class="text-danger">*</span> </h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['bid_opening_date'] | date: 'dd/MM/yyyy'}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Sale Start Date: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['sale_start_date'] | date: 'dd/MM/yyyy'}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Sale End Date: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['sale_end_date'] | date: 'dd/MM/yyyy'}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">

                                    <div class="col-3 text-center">
                                        <h6>Pre Bid Meeting Date: <span class="text-danger">*</span></h6>

                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['prebid_meeting_date'] | date: 'dd/MM/yyyy'}}
                                    </div>
                                </div>
                                <br>

                            </fieldset>
                            <br><br>

                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 21rem;">
                                    <h6>Document Amount & Payment Instruments</h6>
                                </legend>



                                <br>
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6> Document Amount Type: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['doc_amount_type']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>Document Amount: <span class="text-danger">*</span> </h6>
                                    </div>
                                    <div class="col-3 d-flex" *ngIf="tenderObj['doc_amount_type']=='Percentage'">

                                        {{tenderObj['doc_amount']}}
                                        <span class="d-flex"> <span style="padding: 0rem 1rem;">
                                                of
                                            </span>
                                            {{tenderObj['tender_value']}}
                                        </span>

                                    </div>

                                    <div class="col-3 text-left" *ngIf="tenderObj['doc_amount_type']=='Fixed'">
                                        {{tenderObj['doc_amount']}}
                                    </div>
                                </div>
                                <br>
                                <div class="row " >
                                    <div class="col-3 text-center">
                                        <h6>EMD Amount Type: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['emd_amount_type']}}
                                    </div>
                                    <div class="col-3 text-center">
                                        <h6>EMD Amount: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3 d-flex" *ngIf="tenderObj['emd_amount_type']=='Percentage'">

                                        {{tenderObj['emd_amount']}}
                                        <span class="d-flex"> <span style="padding: 0rem 1rem;">
                                                of
                                            </span>
                                            {{tenderObj['tender_value']}}
                                        </span>

                                    </div>

                                    <div class="col-3 text-left" *ngIf="tenderObj['emd_amount_type']=='Fixed'">
                                        {{tenderObj['emd_amount']}}
                                    </div>
                                </div>
                            </fieldset>
                            <br><br>

                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 13rem;">
                                    <h6>Upload Tender Documents</h6>
                                </legend>


                                <div class="col-12 ml-3">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="row">Sr. No.</th>
                                                <th scope="col">Document Type</th>
                                                <th scope="col">Document Name</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of document_details;let i=index ">

                                                <th scope="row" class="text-center">{{i+1}}</th>
                                                <td class="text-center">{{item['doc_type']}} </td>
                                                <td class="text-center">{{item['doc_name']}}</td>
                                                <td class="text-center">
                                                    <button class="btn btn-info" (click)="viewDoc(item)">View</button>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </fieldset>
                            <br><br>

                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 9rem;">
                                    <h6>Cover Information</h6>
                                </legend>


                                <div class="col-12 ml-3">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="text-center">Cover No.</th>
                                                <th class="text-center">Details in Cover</th>
                                                <th class="text-center">Description </th>
                                                <th class="text-center">Document Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of cover_information;let i=index ">
                                                <td class="text-center">{{i+1}} </td>
                                                <td class="text-center"> {{item['doc_formate']}}</td>
                                                <td class="text-center">{{item['cover_desc']}}</td>
                                                <td class="text-center">{{item['cover_type']}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </fieldset>
                            <br><br>

                            <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                                <legend class="w-auto "
                                    style="color: rgb(184, 47, 47);display: inline-block; width: 13rem;">
                                    <h6>Tender Inviting Authority</h6>
                                </legend>


                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Name: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['name']}}
                                    </div>
                                    <div class="col-3">
                                        <h6>Designation:<span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['designation']}}
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3 text-center">
                                        <h6>Contact Detail: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3"> {{tenderObj['contact_detail']}}
                                    </div>
                                    <div class="col-3">
                                        <h6>Department: <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        {{tenderObj['department']}}
                                    </div>
                                </div>
                                <br>
                            </fieldset>
                            <br>
                            <div class="row" *ngIf="tenderObj['web_link']">
                                <div class="col-4">
                                    <h6>For more details of this tender <a (click)="openLink(tenderObj['web_link'])" class="text-danger"> Click Here </a></h6>
                                </div>
                            </div>
                            <br><br>

                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>
</div>








<div class="modal" id="ACTHIER_act">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Activity</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">

                    <div class="col-12">
                        <div class="row" *ngFor="let item of act_arr,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="act_obj['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_act(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==act_arr.length-1">
                                <button (click)="add_dropdown2_act(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_act(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{act_obj['project_path_desc']}}
                    </div>

                </div>
                <br>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_act()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_act()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>






<div class="modal" id="ACTHIER_bud">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Budget</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">

                    <div class="col-12">
                        <div class="row" *ngFor="let item of bud_arr,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="bud_obj['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_bud(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==bud_arr.length-1">
                                <button (click)="add_dropdown2_bud(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_bud(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{bud_obj['project_path_desc']}}
                    </div>

                </div>
                <br>
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_bud()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_bud()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="ACTHIER_prod">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Product</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">


                    <div class="col-12">
                        <div class="row" *ngFor="let item of prod_arr,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="prod_obj['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc_prod(i)"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==prod_arr.length-1">
                                <button (click)="add_dropdown2_prod(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2_prod(item,i)" *ngIf="i!=0"
                                    class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{prod_obj['project_path_desc']}}
                    </div>

                </div>
                <br>


                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier_prod()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier_prod()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>




<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Select Project</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="row" *ngFor="let item of project_arr,let i=index">
                            <div class="col-3">
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==project_arr.length-1">
                                <button (click)="add_dropdown2(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2(item,i)" *ngIf="i!=0" class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{project_obj['project_path_desc']}}
                    </div>

                </div>
                <br>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier()"
                        data-dismiss="modal">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- WORKFLOW MODEL -->
<div class="modal fade" id="workflowmodel">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                {{ApprovalUserService.vendorflag}}
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                            [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                            placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div>
                    <table class="table table-bordered"
                        style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">

                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" style="border: black;">
                    <div class="col-4 text-right">
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit
                        </button>
                    </div>
                </div>
                <br>

                <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- VIEW WORKFLOW STATUS -->

<div class="modal" id="workflowStatus">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title">
                    <!-- Status -->Approval
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <a *ngFor="let ob of statusArr1;let i=index">
                        <div class="step completed" *ngIf="ob.status=='APPROVED'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;"
                                    *ngIf="i <statusArr1.length-1"></div>
                            </div>

                            <div class="content">
                                {{statusArr1.length-i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                ({{ob.user_name}})
                            </div>
                        </div>
                        <div class="step"
                            *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'"
                            style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circles"></div>
                            </div>
                            <div class="content">
                                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                ({{ob.user_name}})

                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr1.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                ({{ob.user_name}})
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr1.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                ({{ob.user_name}})
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">Close</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="cancel">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">Cancel Tender</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <h6>Tender ID : </h6>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="cancelObj['tender_id']" class="form-control" disabled>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <h6>Tender name : </h6>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="cancelObj['work_name']" class="form-control" disabled>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <h6>Reason For Cancellation : <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="cancelObj['reason_of_cancel']" class="form-control">
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-4">
                        <h6>Date of Cancellation : <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                        <input type="date" [(ngModel)]="cancelObj['cancel_date']" class="form-control">
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="cancel()">Submit</button>
                </div>

            </div>
        </div>
    </div>
</div>
