import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';

@Injectable({
    providedIn: 'root'
  })

  export class DefaulterService {
    httpUrl;

    constructor(private http: HttpClient, private main: MainService) {
        this.httpUrl = this.main.httpUrl + "/property/defaulter";
      }
      async createDefaulter(obj:any){
        console.log("post obj from service",obj)
        const resp = await this.http.post(this.httpUrl + '/createDefaulterMethod', obj).toPromise().then(res => {
          return res;
        });
        if (resp) {
          return resp;
        }else{
          return
        }
      }



      async  getDefaulter(obj:any){
        console.log(obj, "from services")
        const resp = this.http.get(this.httpUrl + '/getAllDefaulterMethod' + JSON.stringify(obj)).toPromise().then(res => {
          return res;
        });
        return resp;
      }
      //del
      async deleteDefaulter(obj:any){
        const resp = await this.http.put<any>(this.httpUrl + '/inactiveDefaulterMethod', obj).toPromise().then(res => {
          return res;
        });
        return resp;
      }



  }
