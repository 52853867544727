
// import { Component, OnInit, ViewChild } from '@angular/core';
// import { MainService } from '../../../service/main.service';
// import { NewEngService } from '../../../service/new-eng.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../../../service/main.service';
// import { NewEngService } from '../../service/new-eng.service';
import { NgxSpinnerService } from "ngx-spinner";
// import { InceptionService } from '../../service/inception.service';
import { InceptionService } from '../../service/inception.service'
import { EmbHierService } from "../../../service/emb-hier.service";
import { EstimateService } from '../../service/estimate.service'
import { ApprovalUserService } from '../../../../portal/service/approval-user.service';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from "sweetalert2";
import { ApproveService } from '../../../service/approve.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import { WorkFlowService } from "../../../../portal/service/work-flow.service";

//oc
// import * as converter from 'number-to-words';
//ec
import { MatStepper } from '@angular/material/stepper';
declare var $: any
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { map } from 'jquery';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FileUploader } from 'ng2-file-upload';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MasterDataService } from "../../../service/master-data.service";
// import { concatAll, filter } from "rxjs";

import { TenderService } from "../../../../emb/service/tender.service";
@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class EstimateComponent implements OnInit {
  // @HostListener('click') onClick() {
  //   window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  // }

  ebillUser: any;
  b_acct_id: any;




  constructor(private masterDataService: MasterDataService, public ApprovalUserService: ApprovalUserService, private ApproveService: ApproveService, private estimateService: EstimateService, private inceptionService: InceptionService, private embHierService: EmbHierService, public toastr: ToastrService, public mainService: MainService, private spinner: NgxSpinnerService, private http: HttpClient, private sanitizer: DomSanitizer, private tenderService: TenderService,) { }
  expandedElement: any;
  uploader: any;
  httpUrl: any;
  role_cd = '';
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem("erpUser") as any));
    this.b_acct_id = this.ebillUser["b_acct_id"];
    this.role_cd = this.ebillUser.role_cd;
    this.httpUrl = this.mainService.httpUrl2;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    await this.getinception();
    await this.getItems();
    this.obj['extraAmount'] = 0.00;
    this.obj['scheduledItemsAmount'] = 0.00;
    this.obj['totalAmtSub'] = 0
    this.obj['totalAmtAdd'] = 0
    this.obj['totalAmount'] = this.obj['extraAmount'] + this.obj['scheduledItemsAmount'];
    this.obj['finalAmt'] = this.obj['totalAmount'] + this.obj['totalAmtAdd'] - this.obj['totalAmtSub']
    await this.getEstimate()
    await this.addRowFiles()
    await this.getWorkflowlog()
    if (this.level1['role_cd'] == this.role_cd) {
      this.apprflag = true
    }
    // await this.getAllMeasurements()



  }

  allBridgeInput: any;
  async getAllInputsForBridge() {
    this.b_acct_id = this.ebillUser["b_acct_id"];
    let obj = Object();
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    let resp = await this.estimateService.getAllBridgeInputs(JSON.stringify(obj))
    if (resp["error"] == false) {
      this.allBridgeInput = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
    // console.log('alll Boqsdata =>', this.allBoqsdata)
  }


  allBoqsdata: any
  async getAllBoqs(est_id: any, est_type: any) {
    this.b_acct_id = this.ebillUser["b_acct_id"];
    let obj = Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['key'] = "BOQ"
    obj['idd'] = est_id
    obj['est_type'] = est_type
    this.spinner.show()
    let resp = await this.estimateService.getData(JSON.stringify(obj))
    if (resp["error"] == false) {
      this.allBoqsdata = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
    // console.log('alll Boqsdata =>', this.allBoqsdata)
  }

  allAnaRates: any
  async getAllAnalysisOfRate(est_id: any, est_type: any) {
    this.b_acct_id = this.ebillUser["b_acct_id"];
    let obj = Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['key'] = "AR"
    obj['idd'] = est_id
    obj['est_type'] = est_type


    this.spinner.show()
    let resp = await this.estimateService.getData(JSON.stringify(obj))
    if (resp["error"] == false) {
      this.allAnaRates = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  allBridgeInputs: any
  async getAllBridgeInputs(est_id: any, est_type: any) {
    this.b_acct_id = this.ebillUser["b_acct_id"];
    let obj = Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['key'] = "BI"
    obj['idd'] = est_id
    obj['est_type'] = est_type

    this.spinner.show()
    let resp = await this.estimateService.getData(JSON.stringify(obj))
    if (resp["error"] == false) {
      this.allBridgeInputs = resp['data']
      console.log('alll BridgeInputs!!!!!!!!!!!!! =>', this.allBridgeInputs)
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
    // console.log('alll BridgeInputs!!!!!!!!!!!!! =>', this.allBridgeInputs)

  }

  allMeasurementdata: any
  async getAllMeasurements() {
    this.b_acct_id = this.ebillUser["b_acct_id"];
    let obj = Object();
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    obj['key'] = "MES"
    let resp = await this.estimateService.getData(JSON.stringify(obj))
    if (resp["error"] == false) {
      this.allMeasurementdata = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
    // console.log('allln m =>' , this.allMeasurementdata)
  }
  applyFilter(ele: any) {

  }
  isLinear = false;
  first: any = true;
  second: any = true;
  third: any = true;
  fourth: any = true;
  fifth: any = true;
  six: any = false;
  _step_second_label: any = ' '
  _step_third_label: any = ''
  _step_forth_label: any = ' '
  _step_fifth_label: any = ' '
  _step_six_label: any = ' '
  // createObj = new Object();
  item_list: any = []
  uploadFile: any = []

  estimateCal: any = {}
  basicDetailsObj: any = {}
  create_flag = false;
  list_flag = true;
  update_flag = false;
  listmenuflag = 'active'
  createmenuflag = 'deactive'
  updatemenuflag = 'deactive'
  async create(element: any) {

    this.Obj = {}
    this.listmenuflag = 'deactive'
    this.createmenuflag = 'active'
    this.updatemenuflag = 'deactive'
    this.create_flag = true;
    this.list_flag = false;
    this.update_flag = false;
    this.uploadFile = [];
    this.budpath = null
    this.addRowSorItems = []
    this.otherChargesarr = []
    this.extraItemarr = []
    this.planing_arr = []
    await this.getAccountProjectHier()
    await this.getAccountBudgetHier();
    this.obj['totalAmount'] = 0
    this.obj['extraAmount'] = 0;
    this.obj['totalAmtAdd'] = 0
    this.obj['totalAmtSub'] = 0
    this.obj['scheduledItemsAmount'] = 0
    this.obj['finalAmt'] = 0
    await this.addRowSORItems()
    // await this.addExtraItem()
    // await this.addOtherChargesRow()
    await this.addRowFiles()
    this.Obj['est_id_p'] = this.est_id_p
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj['est_id_p'] = 0
    console.log(obj)
    this.spinner.show();
    var resp = await this.estimateService.deletepreimage(obj);
    if (resp["error"] == false) {
      this.spinner.hide();



    } else {
      this.spinner.hide();
      // this.toastr.error(resp["data"]);
      this.toastr.error(resp["data"]);
    }
    await this.getEstimate()
    this._foundation_obj = {}
    this._sub_str_obj = {}
    this._super_str_obj = {}
    this._protection_work_obj = {}
    this._bridge_obj['bridge_location'] = undefined;
    this._bridge_obj['bridge_type'] = undefined;
    this._step_second_label = ' ';
    this._step_third_label = ' '
    this._step_forth_label = ' '
    this._step_fifth_label = ' '
    this._step_six_label = ' '
    this._bridge_flag = false
    this.feilds_flag = true

    this._culvert_obj = {}
    this._bridge_obj = { bridge_location: undefined }
    this._road_obj = { road_location: undefined }
    this.isCulvert = false
    if (element != 'i') {
      console.log('aaa', this.work_list_obj)
      this.Obj['work_name'] = this.work_list_obj[element.inc_id_f]['inc_id_p']
      this.Obj['inc_id_f'] = element.inc_id_f
    }
    await this.workChanged()
  }
  async updatemenu() {
    this.Obj = {}

    this.listmenuflag = 'deactive'
    this.createmenuflag = 'deactive'
    this.updatemenuflag = 'deactive'
    Swal.fire("Warning", "Please go through the esitmate list update button", "warning");
    await this.list()
  }
  async list() {
    this.listmenuflag = 'active'
    this.createmenuflag = 'deactive'
    this.updatemenuflag = 'deactive'
    this.create_flag = false;
    this.list_flag = true;
    this.update_flag = false;
    await this.getEstimate()
  }
  addRowFiles() {
    console.log(this.uploadFile)
    if (this.uploadFile.length >= 1) {
      let k = this.uploadFile.length - 1;
      if (this.uploadFile[k]['doc_type'] == null || !(this.uploadFile[k]['upflag'])) {
        Swal.fire("Error...", "Please first complete the previous level then add for another level", "error");
        return;
      }
    }

    this.uploadFile.push({ est_doc_id: null, upflag: false });

    console.log(this.uploadFile)
  }



  async deleteRowFiles(i: any) {
    Swal.fire({

      title: "Are you sure?",
      // imageUrl: 'https://unsplash.it/400/200',
      //  imageWidth: 400,
      //  imageHeight: 200,
      // imageAlt: 'Custom image',
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show()
        let obj = Object();
        obj["b_acct_id"] = this.b_acct_id;
        obj['est_doc_id'] = this.uploadFile[i]['est_doc_id']
        this.spinner.show();
        var resp = await this.estimateService.deleteimage(obj);
        if (resp["error"] == false) {
          this.spinner.hide();
          this.uploadFile.splice(i, 1);
          Swal.fire(
            'Deleted!',
            'Deleted Successfully',
            'success'
          )

        }
        else {
          this.spinner.hide()
          Swal.fire("Error...", "Server side error", "error");
        }


      }

    });
    // let obj = Object();
    // obj["b_acct_id"] = this.b_acct_id;
    // obj['est_doc_id'] = this.uploadFile[i]['est_doc_id']
    // this.spinner.show();
    // var resp = await this.estimateService.deleteimage(obj);
    // if (resp["error"] == false) {
    //   this.spinner.hide();



    // } else {
    //   this.spinner.hide();
    //   this.toastr.errorresp["data"]);
    // }

    // this.uploadFile.splice(i, 1);


  }

  upload() {

  }

  /****************SOR POP UP Start ************************ */
  addRowSorItems: any = []

  addRowSOR() {
    $('#sorPopUp').modal('show');
  }

  group: any = [];
  si_code: any = [];
  chapter: any = [];
  cost_rate_std: any = [];
  ln: any = {}
  datasource: any;
  est_list_dataSource: any;
  est_list_dataSource2: any;

  submitprod() {

  }
  addRowDSRItems() {

  }
  saveRowDSRItems() {

  }
  addRowMoRTHItems() {

  }
  saveRowMoRTHItems() {

  }


  async addRowSORItems() {
    console.log(this.addRowSorItems.length)
    if (this.addRowSorItems.length >= 1) {
      let k = this.addRowSorItems.length - 1;
      if (this.addRowSorItems[k]['group_desc'] == null && this.addRowSorItems[k]['group_desc'] == undefined) {
        Swal.fire("Error...", "Please first complete the previous level then add for another level", "error");
        return;
      }
    }
    let index = this.addRowSorItems.length + 1;
    this.addRowSorItems.push({

      item_id: index,
      sor_source: null,
      chapter_no: null,
      cost_rate_std: null,
      sed_item_no: null,
      group_desc: null,
      sub_group_desc: 'NA',
      qty_unit: null,
      rank: undefined,
      item_qty: 0,
      item_desc: '',
      add_ded: 'add',
      excise_duity: 0,
      vat: 0,
      frieght_char: 0,
      atodl: 0,
      other_taxes: 0,
      costing_rate: 0,
      amount: 0,
      deleteflag: true
    });



    this.datasource = new MatTableDataSource(this.addRowSorItems);
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

  }

  deleteRowSORItems(i: any) {
    for (let j = 0; j < this.addRowSorItems.length; j++) {
      if (j > i) {
        this.addRowSorItems[j]["item_id"] = this.addRowSorItems[j]["item_id"] - 1;
      }
    }
    this.addRowSorItems.splice(i, 1);
  }







  saveRowSORItems() {

  }

  viewSORDoc() {

  }
  /****************SOR POP UP END ************************ */
















  /*----------------------------------Mat table--------------------------------------*/


  displayedColumns = [
    "sr_no",
    "cost_rate_std",
    "chapter",
    "si_no",
    "group",
    "item",
    "qty",
    "unit",
    "rate",
    "add_ded",
    "excise_duty",
    "vat",
    "frieght_char",
    "atodl",
    "amount",
    "action"
  ];
  estimate_list_columns = [
    "est_id",
    "work_name",
    "est_desc",
    "fund_to_use",
    // "est_date",
    // "cal_est",
    "status",
    "mode",
    "action"
  ]
  columnsToDisplayWithExpand2 = [...this.displayedColumns, 'expand'];


  getebills() {

  }

  /*------------------------ Project hier---------------------------------------------*/




  path_arr: any = [];
  path_arr1: any = [];
  prod_level: any;
  bud_level: any;
  Obj: any = {}
  async getproductforupdate() {
    if (this.planing_arrprod.length == 0) {
      this.planing_arrprod = [];
      let flag = false;
      let lv: any = [];
      let exist: any = {};
      let desc: any = "";
      let is_leaf = 0;
      let lvel = 0;
      let desired_hier: any = {};
      this.existing_prod = await [...this.prodhier];
      for (let i = 0; i < this.existing_prod.length; i++) {
        for (let j = 1; j < 8; j++) {
          if (
            this.existing_prod[i]["lvl" + j + "_cd"] ==
            this.Obj["product_cd"] &&
            this.existing_prod[i]["lvl" + j + "_cd"] != null
          ) {
            lvel = j;
            desired_hier = this.existing_prod[i];
            break;
          }
        }
      }

      console.log(lvel);

      this.prod_level = lvel;
      for (let j = 1; j <= lvel; j++) {
        let l = j;
        lv = [];
        for (let i = 0; i < this.existing_prod.length; i++) {
          flag = false;
          if (this.existing_prod[i]["lvl" + l + "_cd"] != null) {
            desc =
              this.existing_prod[i]["lvl" + l + "_cd"] +
              "-" +
              this.existing_prod[i]["lvl" + l + "_value"];

            is_leaf = this.existing_prod[i]["is_leaf"];
            exist = {
              code: this.existing_prod[i]["lvl" + l + "_cd"],
              desc: desc,
              leaf_cd: this.existing_prod[i]["leaf_cd"],
              is_leaf: is_leaf,
            };

            for (let j = 0; j < lv.length; j++) {
              if (exist["code"] == lv[j]["code"]) flag = true;
            }

            if (!flag) lv.push(exist);
          }
        }
        let x = {
          level: l,
          hier_desc: desc,
          existing_heirarchy_nodes: lv,
          hierarchy_type: desired_hier["lvl" + l + "_cd"],
        };

        this.planing_arrprod.push(x);
      }

      console.log(desired_hier);
      this.path_arr = [];
      for (let i = 0; i < 7; i++) {
        let x = i + 1;
        if (desired_hier["lvl" + x + "_cd"] != null)
          this.path_arr[i] =
            desired_hier["lvl" + x + "_cd"] +
            "-" +
            desired_hier["lvl" + x + "_value"];
      }
    }
    this.prodpath = this.path_arr.join(" --> ");
    console.log(this.path_arr);
  }
  budpath: any;
  prodpath: any;
  bud_head_cd: any;
  bud_amt: any;
  is_leaf: any;
  is_leaf_prod: any;
  prod_cd: any;
  bud_name: any;
  planing_arr: any = [];
  setHierarchyNode(item: any, i: any) {
    this.is_leaf = 0;
    console.log(item);

    for (let i = 0; i < item["existing_heirarchy_nodes"].length; i++) {
      console.log(
        item["existing_heirarchy_nodes"][i]["code"],
        item["hierarchy_type"]
      );
      console.log(
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"]
      );

      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"]
      ) {
        this.bud_head_cd = item["existing_heirarchy_nodes"][i]["code"];
        this.bud_amt = item["existing_heirarchy_nodes"][i]["remaining_amount"];
        this.is_leaf = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.bud_name = item["existing_heirarchy_nodes"][i]["desc"];
      }
    }
    console.log(item["level"], this.bud_level);

    if (item["level"] < this.bud_level) {
      for (let j = item["level"]; j < this.bud_level; j++) {
        this.planing_arr.pop();
        this.path_arr1.pop();
      }

      this.bud_level = item["level"];

      this.path_arr1[item["level"] - 1] = "";
      this.budpath = this.path_arr1.join(" --> ");
    } else {
      this.path_arr1[item["level"] - 1] = this.bud_name;
      this.budpath = this.path_arr1.join(" --> ");
    }
  }
  setprodHierarchyNode(item: any, i: any) {
    this.is_leaf_prod = 0;
    console.log(item);
    let prod_des = "";
    for (let i = 0; i < item["existing_heirarchy_nodes"].length; i++) {
      console.log(
        item["existing_heirarchy_nodes"][i]["code"],
        item["hierarchy_type"]
      );
      console.log(
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"]
      );

      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"]
      ) {
        this.is_leaf_prod = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.prod_cd = item["existing_heirarchy_nodes"][i]["code"];
        prod_des = item["existing_heirarchy_nodes"][i]["desc"];
      }
    }

    console.log(this.is_leaf_prod, this.prod_cd);
    let flag = false;
    let dummy = [];
    console.log(item["level"], this.prod_level);
    if (item["level"] < this.prod_level) {
      for (let j = item["level"]; j < this.prod_level; j++) {
        this.planing_arrprod.pop();
        this.path_arr.pop();
      }

      this.prod_level = item["level"];
      console.log(this.prod_level);

      this.path_arr[item["level"] - 1] = "";
      this.prodpath = this.path_arr.join(" --> ");
    } else {
      this.path_arr[item["level"] - 1] = prod_des;
      this.prodpath = this.path_arr.join(" --> ");
    }
  }
  existing_heirarchy_nodes = [];
  setprodHierarchyNodeforupdate(item: any, i: any) {
    this.is_leaf_prod = 0;
    console.log(item);
    let make_desc = "";
    for (let i = 0; i < item["existing_heirarchy_nodes"].length; i++) {
      console.log(
        item["existing_heirarchy_nodes"][i]["code"],
        item["hierarchy_type"]
      );
      console.log(
        item["existing_heirarchy_nodes"][i]["leaf_cd"] == item["hierarchy_type"]
      );

      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"]
      ) {
        this.is_leaf_prod = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.prod_cd = item["existing_heirarchy_nodes"][i]["code"];
        make_desc = item["existing_heirarchy_nodes"][i]["desc"];
      }

      if (
        item["existing_heirarchy_nodes"][i]["code"] == item["hierarchy_type"] &&
        item["level"] == this.prod_level
      ) {
        this.is_leaf_prod = item["existing_heirarchy_nodes"][i]["is_leaf"];
        this.prod_cd = item["existing_heirarchy_nodes"][i]["code"];
        make_desc = item["existing_heirarchy_nodes"][i]["desc"];
      }
    }
    console.log(item["level"], this.prod_level);

    if (item["level"] < this.prod_level) {
      for (let j = item["level"]; j < this.prod_level; j++) {
        this.planing_arrprod.pop();
        this.path_arr.pop();
      }

      this.prod_level = item["level"];
      this.path_arr[item["level"] - 1] = make_desc;
      this.prodpath = this.path_arr.join(" --> ");
    } else {
      this.path_arr[item["level"] - 1] = make_desc;
      this.prodpath = this.path_arr.join(" --> ");
    }
  }

  selected_row: any = {};
  async add_dropdown_prod(data: any, index: any) {
    let flag = true;
    let obj1 = {};
    if (this.planing_arrprod.length >= 7) {
      return;
      Swal.fire("Warning..", "", "warning");
    }
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (
        data["hierarchy_type"] == this.existing_heirarchy_nodes[i]["node_cd"]
      ) {
        obj1 = Object.assign({}, this.existing_heirarchy_nodes[i]);
      }
    }
    console.log(this.selected_row);
    this.selected_row = Object.assign({}, obj1);
    this.selected_row["index"] = index;

    await this.filter_data_prod();
  }

  existing_prod = [];
  async filter_data_prod() {
    let flag = false;
    let lv = [];
    let exist: any = {};
    let desc = "";
    let is_leaf = 0;
    this.existing_prod = await [...this.prodhier];

    let l = this.selected_row["index"] + 2;

    for (let i = 0; i < this.existing_prod.length; i++) {
      flag = false;
      console.log(
        this.existing_prod[i]["lvl" + (l - 1) + "_cd"],
        this.planing_arrprod[this.selected_row["index"]]["hierarchy_type"]
      );
      if (
        this.existing_prod[i]["lvl" + l + "_cd"] != null &&
        this.existing_prod[i]["lvl" + (l - 1) + "_cd"] ==
        this.planing_arrprod[this.selected_row["index"]]["hierarchy_type"]
      ) {
        desc =
          this.existing_prod[i]["lvl" + l + "_cd"] +
          "-" +
          this.existing_prod[i]["lvl" + l + "_value"];

        is_leaf = this.existing_prod[i]["is_leaf"];
        exist = {
          code: this.existing_prod[i]["lvl" + l + "_cd"],
          desc: desc,
          leaf_cd: this.existing_prod[i]["lvl" + l + "_cd"],
          is_leaf: is_leaf,
        };

        for (let j = 0; j < lv.length; j++) {
          if (exist["code"] == lv[j]["code"]) flag = true;
        }

        if (!flag) lv.push(exist);
      }
    }
    let x = { level: l, existing_heirarchy_nodes: lv };
    this.planing_arrprod.push(x);
    this.path_arr.push("");
    this.prod_level++;
    console.log(this.planing_arrprod);
  }


  prodhier = [];
  async getAccountProductHier() {
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "prod_hier";
    this.spinner.show();
    var resp = await this.embHierService.getproductHierarchy(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      let data = resp["data"];
      this.prodhier = [];
      this.prodhier = data;
      console.log(data);
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }

  planing_arrprod: any = [];
  selectproductbtn() {
    if (this.planing_arrprod.length == 0) {
      this.planing_arrprod = [];
      this.prodpath = "";
      console.log(this.prodhier);
      let b_cd = "";
      let desc = "";
      this.path_arr = [];
      this.path_arr1 = [];
      let existing_heirarchy_nodes = [];
      let flag = 0;
      for (let i = 0; i < this.prodhier.length; i++) {
        flag = 0;
        desc =
          this.prodhier[i]["lvl1_cd"] + "-" + this.prodhier[i]["lvl1_value"];
        b_cd = this.prodhier[i]["lvl1_cd"];
        let obj = { code: b_cd, desc: desc };

        for (let j = 0; j < existing_heirarchy_nodes.length; j++) {
          if (existing_heirarchy_nodes[j]["code"] == obj["code"]) {
            flag++;
          }
        }
        if (flag == 0) {
          existing_heirarchy_nodes.push(obj);
        }
      }

      this.planing_arrprod = [
        {
          level: 1,
          hier_desc: desc,
          is_leaf: 0,
          existing_heirarchy_nodes: existing_heirarchy_nodes,
        },
      ];
    }
    this.prod_level = 1;
    $("#select44").modal("show");
  }
  /*-------------------Get Work Names from inception--------------------------*/
  work_list: any = [];
  work_list_obj: any = {}
  async getinception() {
    let obj: any = {}
    obj["b_acct_id"] = this.b_acct_id;
    obj['doc_type'] = 'ESTIMATE'
    this.spinner.show();
    var resp = await this.inceptionService.getinception(JSON.stringify(obj));
    if (resp["error"] == false) {
      console.log(resp['data'])
      this.spinner.hide();
      this.work_list = []

      resp['data'].map((x: any) => {
        this.work_list.push(x)
        console.log()
        this.work_list_obj[x['inc_id_p']] = x;
      })
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }


  /*-------------------Project Hier 1---------------------------*/
  planing_arrproj: any = [];
  projecthier: any = [];
  projhierobj: any = {};
  projhierobjtype: any = {};
  projhierobjtypecd: any = {};
  projhierobjisleaf: any = {};
  async getAccountProjectHier() {
    this.planing_arrproj = []
    this.projecthier = [];
    let obj: any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    let resp = await this.embHierService.getprojectHierarchy(obj);
    if (resp["error"] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["lvl1_cd"] == 1) {
          resp.data[i]["lvl1_value"] = "EMB-root";
        }
      }
      this.projecthier = resp["data"];
      for (let i = 0; i < resp["data"].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["lvl" + j + "_value"];
          this.projhierobjtype[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["lvl" + j + "_node_type"];
          this.projhierobjtypecd[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["lvl" + j + "_user_cd"];
          this.projhierobjisleaf[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["is_leaf"];
        }
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While Getting Cost Center", "error");
    }
  }

  selectProjectPopUp() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1);
    }
    $("#ACTHIER").modal("show");
  }


  setHierarchyNodeitem(item: any, i: any) {
    console.log(item, i);
    this.check = item["hierarchy_type"];
    console.log(this.check);
    item["is_leaf"] = this.projhierobjisleaf[item["hierarchy_type"]];
    let dummy2 = [];
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]]);
    }
    this.Obj["path_desc"] = dummy2.join(" --> ");
  }

  down(item: any, k: any) {
    console.log(this.planing_arrproj);
    let patharr = [];
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      patharr.push(this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]]);
    }
    this.Obj["path_desc"] = patharr.join("-->");
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [] });
    console.log(this.planing_arrproj, item);
    let arr: any = [];
    for (let i = 0; i < this.projecthier.length; i++) {
      if (
        !arr.includes(this.projecthier[i]["lvl" + (k + 2) + "_cd"]) &&
        this.projecthier[i]["lvl" + (k + 2) + "_cd"] != null
      ) {
        if (
          (this.projecthier[i]["lvl" + (k + 1) + "_cd"] ==
            item["hierarchy_type"] ||
            k == -1) &&
          this.projecthier[i]["leaf_node_type"] != "WORK" && this.projecthier[i]["leaf_node_type"] != "INCEPTION" && this.projecthier[i]["leaf_node_type"] != "ESTIMATE"
        ) {
          this.planing_arrproj[k + 1]["existing_heirarchy_nodes"].push({
            node_cd: this.projecthier[i]["lvl" + (k + 2) + "_cd"],
            desc:
              this.projecthier[i]["lvl" + (k + 2) + "_user_cd"] +
              " - " +
              this.projecthier[i]["lvl" + (k + 2) + "_value"] +
              " - " +
              this.projecthier[i]["lvl" + (k + 2) + "_node_type"],
            is_leaf: this.projecthier[i]["is_leaf"],
          });
          arr.push(this.projecthier[i]["lvl" + (k + 2) + "_cd"]);
        }
      }
    }
  }

  remove(item: any, i: any) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop();
      let patharr = [];
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i]);

        patharr.push(
          this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]]
        );
      }
      this.Obj["path_desc"] = patharr.join("-->");
    }
  }

  check: any;
  submit_act() {
    if (
      this.planing_arrproj.length < 2 ||
      this.planing_arrproj[this.planing_arrproj.length - 1]["hierarchy_type"] ==
      null ||
      this.planing_arrproj[this.planing_arrproj.length - 1]["hierarchy_type"] ==
      null
    ) {
      Swal.fire("Warning", "Please Select at least two level", "warning");
    } else {
      $("#ACTHIER").modal("hide");
    }
  }
  /*----------------------------Budject hier-------------------------------------*/
  budhier = [];
  async getAccountBudgetHier() {
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "bud_hier";
    this.spinner.show();
    var resp = await this.embHierService.getBudgetHierarchy(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      let data = resp["data"];
      this.budhier = [];
      this.budhier = data;
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }

  existing_bud: any;
  async getbudgetforupdate() {
    console.log(this.budhier);

    this.planing_arr1 = [];
    let flag = false;
    let lv = [];
    let exist: any = {};
    let desc = "";
    let is_leaf = 0;
    let lvel = 0;
    let desired_hier: any = {};
    this.existing_bud = await [...this.budhier];
    for (let i = 0; i < this.existing_bud.length; i++) {
      for (let j = 1; j < 8; j++) {
        if (
          this.existing_bud[i]["lvl" + j + "_cd"] == this.Obj["budget_cd"] &&
          this.existing_bud[i]["lvl" + j + "_cd"] != null
        ) {
          lvel = j;
          desired_hier = this.existing_bud[i];
          break;
        }
      }
    }

    this.bud_level = lvel;
    console.log(this.bud_level);

    for (let j = 1; j <= lvel; j++) {
      let l = j;
      lv = [];
      for (let i = 0; i < this.existing_bud.length; i++) {
        flag = false;
        if (this.existing_bud[i]["lvl" + l + "_cd"] != null) {
          desc =
            this.existing_bud[i]["lvl" + l + "_cd"] +
            "-" +
            this.existing_bud[i]["lvl" + l + "_value"];

          is_leaf = this.existing_bud[i]["is_leaf"];

          exist = {
            code: this.existing_bud[i]["lvl" + l + "_cd"],
            desc: desc,
            is_leaf: is_leaf,
            leaf_cd: this.existing_bud[i]["leaf_cd"],
            remaining_amount: this.existing_bud[i]["remaining_amount"],
          };
          for (let j = 0; j < lv.length; j++) {
            if (exist["code"] == lv[j]["code"]) flag = true;
          }

          if (!flag) lv.push(exist);
        }
      }
      let x = {
        level: l,
        hier_desc: desc,
        existing_heirarchy_nodes: lv,
        hierarchy_type: desired_hier["lvl" + l + "_cd"],
      };
      this.planing_arr1.push(x);
    }

    console.log(desired_hier);
    this.path_arr1 = [];
    for (let i = 0; i < 7; i++) {
      let x = i + 1;
      if (desired_hier["lvl" + x + "_cd"] != null)
        this.path_arr1[i] =
          desired_hier["lvl" + x + "_cd"] +
          "-" +
          desired_hier["lvl" + x + "_value"];
    }

    this.budpath = this.path_arr1.join(" --> ");
    console.log(this.path_arr1);
  }

  Obj1 = {};
  ActiveNode = [];

  allRoles = [];
  allComp: any;
  selectedComp = [];
  dataSource: any;
  role_name1: any;
  role_id: any;

  planing_arr1 = [{}];
  access = [];


  budget_flag = false;

  selectbudgetbtn() {
    console.log(this.budhier);
    console.log(this.planing_arr);
    if (this.planing_arr.length == 0) {
      this.planing_arr = [];
      this.budpath = "";
      this.budget_flag = true;
      this.Obj["budget_cd"] = "";
      this.Obj["budget_amt"] = "";
      let b_cd = "";
      let desc: any = "";
      this.path_arr = [];
      this.path_arr1 = [];
      let existing_heirarchy_nodes: any = [];
      let flag = 0;
      for (let i = 0; i < this.budhier.length; i++) {
        flag = 0;
        desc = this.budhier[i]["lvl1_cd"] + "-" + this.budhier[i]["lvl1_value"];
        b_cd = this.budhier[i]["lvl1_cd"];
        let obj = { code: b_cd, desc: desc };

        for (let j = 0; j < existing_heirarchy_nodes.length; j++) {
          if (existing_heirarchy_nodes[j]["code"] == obj["code"]) {
            flag++;
          }
        }
        if (flag == 0) {
          existing_heirarchy_nodes.push(obj);
        }
      }

      this.planing_arr = [
        {
          level: 1,
          hier_desc: desc,
          is_leaf: 0,
          existing_heirarchy_nodes: existing_heirarchy_nodes,
        },
      ];
      console.log(this.planing_arr);
    }
    this.bud_level = 1;
    $("#select33").modal("show");
  }


  async add_dropdown(data: any, index: any) {
    let flag = true;
    let obj1 = {};
    if (this.planing_arr.length >= 8) {
      return;
      Swal.fire("Warning..", "", "warning");
    }
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (
        data["hierarchy_type"] == this.existing_heirarchy_nodes[i]["node_cd"]
      ) {
        obj1 = Object.assign({}, this.existing_heirarchy_nodes[i]);
      }
    }
    this.selected_row = Object.assign({}, obj1);
    this.selected_row["index"] = index;

    await this.filter_data();
  }
  existing = [];
  async filter_data() {
    let flag = false;
    let lv = [];
    let exist: any = {};
    let desc = "";
    let is_leaf = 0;
    this.existing = await [...this.budhier];

    let l = this.selected_row["index"] + 2;

    for (let i = 0; i < this.existing.length; i++) {
      if (
        this.existing[i]["lvl" + l + "_cd"] != null &&
        this.existing[i]["lvl" + (l - 1) + "_cd"] ==
        this.planing_arr[this.selected_row["index"]]["hierarchy_type"]
      ) {
        flag = false;
        desc =
          this.existing[i]["lvl" + l + "_cd"] +
          "-" +
          this.existing[i]["lvl" + l + "_value"];
        is_leaf = this.existing[i]["is_leaf"];

        exist = {
          code: this.existing[i]["lvl" + l + "_cd"],
          desc: desc,
          is_leaf: is_leaf,
          leaf_cd: this.existing[i]["leaf_cd"],
          remaining_amount: this.existing[i]["remaining_amount"],
        };
        for (let j = 0; j < lv.length; j++) {
          if (exist["code"] == lv[j]["code"]) flag = true;
        }

        if (!flag) lv.push(exist);
      }
    }
    let x = { level: l, existing_heirarchy_nodes: lv };
    this.planing_arr.push(x);
    this.bud_level++;
    this.path_arr1.push("");
  }

  remove_dropdown(data: any, i: any) {
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop();
    }

    let arr =
      this.planing_arr[this.planing_arr.length - 1]["existing_heirarchy_nodes"];
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i]["code"] ==
        this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"]
      )
        this.is_leaf = arr[i]["is_leaf"];
    }
    console.log(this.is_leaf_prod);
    let dummy = [];
    this.path_arr1.pop();
    this.bud_level = i;
    this.budpath = this.path_arr1.join(" --> ");
  }

  async submitbud() {
    console.log(this.planing_arr, this.planing_arr1);

    if (this.is_leaf == 0) {
      Swal.fire("Error", "You Have Not Selected Leaf Node", "error");
    } else {
      this.Obj["budget_cd"] = this.bud_head_cd;
      this.Obj["budget_amt"] = this.bud_amt;

      console.log(this.bud_head_cd, this.bud_amt);

      $("#select3").modal("hide");
      $("#select33").modal("hide");
    }
  }

  /*------------------file Uplaod---------------*/
  selectedFile: any = null;
  public imagePath: any;
  async refresh() {
    this.selectedFile = null
    // this.imagePath = ''
    // this.uploader.queue = [];
  }

  onFileUpload(event: any, files: any) {
    this.selectedFile = <File>event.target.files[0];
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };


  }

  async docUpload(doc_type: any, i: any) {
    console.log(this.uploadFile, "this.uploadFile")
    this.spinner.show();
    const formData = new FormData();
    console.log(this.selectedFile)
    formData.append('image', this.selectedFile, this.selectedFile.name);
    const doc_obj: any = new Object();
    var file_name = this.uploader.queue[0].some.name;
    var temp = file_name.split('.');
    var ext = temp[temp.length - 1];
    doc_obj['b_acct_id'] = this.b_acct_id;
    doc_obj['doc_name'] = file_name;
    doc_obj['est_id_f'] = 0
    doc_obj['doc_type'] = doc_type;
    const params = JSON.stringify(doc_obj);
    this.uploader.queue[0].url = this.httpUrl + '/emb/depricated/estimation/estimate/estDocUpload' + params;
    this.uploader.queue[0].upload();
    this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      var resp = JSON.parse(response)
      if (resp.error == false) {
        this.spinner.hide();
        let resp = await this.estimateService.getdocslist(JSON.stringify(doc_obj));
        if (resp['error'] == false) {

          this.uploadFile[i]['upflag'] = true
          // this.uploadFile = resp['data']
        }
        else {

        }
        Swal.fire("Success", "File Uploaded Successfully", "success");

        await this.refresh()
      } else {
        this.spinner.hide();
        Swal.fire("Oops", "Error occurred", "error");
      }
    };
  }

  imgURL5: any;
  async viewdocs(element: any) {
    console.log(element)
    var obj: any = new Object()
    obj["b_acct_id"] = this.b_acct_id
    obj["est_doc_id"] = element['est_doc_id']
    obj["doc_name"] = element['doc_name']
    console.log(obj)
    this.spinner.show();
    let resp = await this.estimateService.viewdocs(obj)
    this.spinner.hide();
    console.log("this is all document ", resp)
    if (resp) {
      var ext = element['doc_name'].split('.');
      console.log(ext[1])
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        // // this.imgURL5 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
        this.imgURL5 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);

        $('#est_docs').modal('show');
        console.log(this.imgURL5)
      } else {
        let file = new Blob([resp], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

    }

  }

  /*-------------------end------------------------*/


  /*-----------Get Sor Items-----------------*/
  sorData = []

  async getItems() {
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    this.spinner.show();
    var resp = await this.estimateService.getItems(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      console.log(resp)
      this.sorData = resp['data']
      this.sorData.map((x: any, i) => {
        x['cost_rate_desc'] = x['costing_rate_std'] + '-' + x['fin_year']
        x['si_code'] = x['sed_item_no']
        x['deleteflag'] = true
      })

      this.cost_rate_std = [...new Set(this.sorData.map((item: any) => item.cost_rate_desc))];
      console.log(resp['data'], "resp['data']")

    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  async chap(data: any, i: any) {
    console.log(i)
    console.log(data)
    console.log(this.sorData)
    console.log(this.addRowSorItems)
    let rank = this.addRowSorItems[i]['rank']
    let chapter_no = this.addRowSorItems[i]['chapter']
    let sed_item_no = this.addRowSorItems[i]['si_code']
    let group_desc = this.addRowSorItems[i]['group_desc']
    console.log(i)
    console.log(chapter_no)
    console.log(sed_item_no)
    console.log(group_desc)
    this.addRowSorItems[i].chapter = null
    this.addRowSorItems[i].si_code = null
    this.addRowSorItems[i].group_desc = null
    this.addRowSorItems[i].sub_group_desc = 'NA'
    this.addRowSorItems[i].qty_unit = null
    this.addRowSorItems[i].rank = undefined
    this.addRowSorItems[i].item_qty = 0
    this.addRowSorItems[i].item_desc = ''
    this.addRowSorItems[i].add_ded = 'add'
    this.addRowSorItems[i].excise_duity = 0
    this.addRowSorItems[i].vat = 0
    this.addRowSorItems[i].frieght_char = 0
    this.addRowSorItems[i].other_taxes = 0
    this.addRowSorItems[i].costing_rate = 0
    this.addRowSorItems[i].amount = 0
    this.addRowSorItems[i].deleteflag = true
    if (rank) {
      //   if(this.addRowSorItems[i]['rank']==rank){


      //   // chapter_no: null,
      //   // // cost_rate_std: data,
      //   // sed_item_no: null,
      //   // group_desc: null,
      //   // sub_group_desc: 'NA',
      //   // qty_unit: null,
      //   // rank:undefined,
      //   // item_qty: 0,
      //   // item_desc: '',
      //   // add_ded:'add',
      //   // excise_duity: 0,
      //   // vat: 0,
      //   // frieght_char: 0,
      //   // atodl: 0,
      //   // other_taxes: 0,
      //   // costing_rate: 0,
      //   // amount: 0,
      //   // deleteflag: true
      //   // }
      // }
      console.log(this.addRowSorItems)
      console.log(chapter_no)
      console.log(sed_item_no)
      console.log(group_desc)
      // this.addRowSorItems.map((x,j,arr)=>{
      //   console.log((x['chapter_desc']==chapter_no) && (x['sed_item_no']==sed_item_no) && (x['group_desc']==group_desc))
      //   console.log(x['chapter_desc'],chapter_no , x['sed_item_no'] ,sed_item_no ,x['group_desc'],group_desc,"kk")
      //   if((x['chapter_desc']==chapter_no) && (x['sed_item_no']==sed_item_no) && (x['group_desc']==group_desc)){
      //     // console.log("indside")
      //     this.addRowSorItems.splice(j,1);
      //   }
      // })
      console.log(this.addRowSorItems)
      let count = 0;
      let index = 0;
      for (let k = 0; k < this.addRowSorItems.length; k++) {

        // console.log(this.addRowSorItems[k]['rank'])
        // console.log(rank)
        if (this.addRowSorItems[k]['rank'] == rank) {
          count += 1;
          index = k;
          // this.addRowSorItems.splice(k, 1) ;
          console.log(this.addRowSorItems[k]['rank'])
          console.log(index)
          console.log(count)
        }
      }
      index = index + 1 - count;
      console.log(index)
      console.log(count)
      this.addRowSorItems.splice(index, count);
      // this.addRowSorItems.map((x,j)=>{
      // if(x['rank']==rank){
      // this.addRowSorItems.splice(j, 1);
      // }
      // return x['rank']!=rank
      // })
      // console.log(remainData)
      // this.addRowSorItems=remainData

    }
    this.chapter = this.sorData.filter(x => {
      console.log(data, x['cost_rate_desc'])

      return data == x['cost_rate_desc']
    })

    this.chapter = [...new Set(this.chapter.map((item: any) => item.chapter_desc))];
    console.log(this.chapter)
    for (let i = 0; i < this.addRowSorItems.length; i++) {
      await this.colculate(i)
    }
  }

  async si_code_filter(cost_rate_std: any, chapter: any, i: any) {
    console.log("sc", cost_rate_std, chapter)
    let rank = this.addRowSorItems[i]['rank']
    let chapter_no = this.addRowSorItems[i]['chapter']
    let sed_item_no = this.addRowSorItems[i]['si_code']
    let group_desc = this.addRowSorItems[i]['group_desc']
    console.log(rank, chapter)
    // this.addRowSorItems[i].chapter_no=null
    this.addRowSorItems[i].si_code = null
    this.addRowSorItems[i].group_desc = null
    this.addRowSorItems[i].sub_group_desc = 'NA'
    this.addRowSorItems[i].qty_unit = null
    this.addRowSorItems[i].rank = undefined
    this.addRowSorItems[i].item_qty = 0
    this.addRowSorItems[i].item_desc = ''
    this.addRowSorItems[i].add_ded = 'add'
    this.addRowSorItems[i].excise_duity = 0
    this.addRowSorItems[i].vat = 0
    this.addRowSorItems[i].frieght_char = 0
    this.addRowSorItems[i].other_taxes = 0
    this.addRowSorItems[i].costing_rate = 0
    this.addRowSorItems[i].amount = 0
    this.addRowSorItems[i].deleteflag = true
    // this.addRowSorItems[i]={

    //   // chapter_no: chapter,
    //   // cost_rate_std: cost_rate_std,
    //   sed_item_no:null ,
    //   group_desc: null,
    //   sub_group_desc: 'NA',
    //   qty_unit: null,
    //   rank:undefined,
    //   item_qty: 0,
    //   item_desc: '',
    //   add_ded:'add',
    //   excise_duity: 0,
    //   vat: 0,
    //   frieght_char: 0,
    //   atodl: 0,
    //   other_taxes: 0,
    //   costing_rate: 0,
    //   amount: 0,
    //   deleteflag: true
    //   }
    if (rank) {
      //   if(this.addRowSorItems[i]['rank']==rank){

      // }
      console.log(this.addRowSorItems)
      console.log(chapter_no)
      console.log(sed_item_no)
      console.log(group_desc)
      // this.addRowSorItems.map((x,j,arr)=>{
      //   console.log((x['chapter_desc']==chapter_no) && (x['sed_item_no']==sed_item_no) && (x['group_desc']==group_desc))
      //   console.log(x['chapter_desc'],chapter_no , x['sed_item_no'] ,sed_item_no ,x['group_desc'],group_desc,"kk")
      //   if((x['chapter_desc']==chapter_no) && (x['sed_item_no']==sed_item_no) && (x['group_desc']==group_desc)){
      //     // console.log("indside")
      //     this.addRowSorItems.splice(j,1);
      //   }
      // })
      console.log(this.addRowSorItems)
      let count = 0;
      let index = 0;
      for (let k = 0; k < this.addRowSorItems.length; k++) {

        // console.log(this.addRowSorItems[k]['rank'])
        // console.log(rank)
        if (this.addRowSorItems[k]['rank'] == rank) {
          count += 1;
          index = k;
          // this.addRowSorItems.splice(k, 1);
          console.log(this.addRowSorItems[k]['rank'])
          console.log(index)
          console.log(count)
        }
      }
      index = index + 1 - count;
      console.log(index)
      console.log(count)
      this.addRowSorItems.splice(index, count);
      // this.addRowSorItems.map((x,j)=>{
      // if(x['rank']==rank){
      // this.addRowSorItems.splice(j, 1);
      // }
      // return x['rank']!=rank
      // })
      // console.log(remainData)
      // this.addRowSorItems=remainData

    }
    this.si_code = this.sorData.filter(x => {
      return cost_rate_std == x['cost_rate_desc'] && chapter == x['chapter_desc']
    })

    this.si_code = [...new Set(this.si_code.map((item: any) => item.sed_item_no))];
    console.log(this.si_code)
    for (let i = 0; i < this.addRowSorItems.length; i++) {
      await this.colculate(i)
    }
  }

  async group_filter(cost_rate_std: any, chapter: any, si_code: any, i: any) {
    console.log("group", cost_rate_std, chapter, si_code)
    let rank = this.addRowSorItems[i]['rank']
    let chapter_no = this.addRowSorItems[i]['chapter']
    let sed_item_no = this.addRowSorItems[i]['si_code']
    let group_desc = this.addRowSorItems[i]['group_desc']
    console.log(rank, cost_rate_std, chapter, si_code, i)
    // this.addRowSorItems[i].chapter_no=null
    // this.addRowSorItems[i].sed_item_no=null
    this.addRowSorItems[i].group_desc = null
    this.addRowSorItems[i].sub_group_desc = 'NA'
    this.addRowSorItems[i].qty_unit = null
    this.addRowSorItems[i].rank = undefined
    this.addRowSorItems[i].item_qty = 0
    this.addRowSorItems[i].item_desc = ''
    this.addRowSorItems[i].add_ded = 'add'
    this.addRowSorItems[i].excise_duity = 0
    this.addRowSorItems[i].vat = 0
    this.addRowSorItems[i].frieght_char = 0
    this.addRowSorItems[i].other_taxes = 0
    this.addRowSorItems[i].costing_rate = 0
    this.addRowSorItems[i].amount = 0
    this.addRowSorItems[i].deleteflag = true
    // this.addRowSorItems[i]={

    //   chapter_no: chapter,
    //   cost_rate_std: cost_rate_std,
    //   sed_item_no: si_code,
    //   group_desc: null,
    //   sub_group_desc: 'NA',
    //   qty_unit: null,
    //   rank:undefined,
    //   item_qty: 0,
    //   item_desc: '',
    //   add_ded:'add',
    //   excise_duity: 0,
    //   vat: 0,
    //   frieght_char: 0,
    //   atodl: 0,
    //   other_taxes: 0,
    //   costing_rate: 0,
    //   amount: 0,
    //   deleteflag: true
    //   }
    if (rank) {
      //   if(this.addRowSorItems[i]['rank']==rank){

      // }
      console.log(this.addRowSorItems)
      console.log(chapter_no)
      console.log(sed_item_no)
      console.log(group_desc)
      // this.addRowSorItems.map((x,j,arr)=>{
      //   console.log((x['chapter_desc']==chapter_no) && (x['sed_item_no']==sed_item_no) && (x['group_desc']==group_desc))
      //   console.log(x['chapter_desc'],chapter_no , x['sed_item_no'] ,sed_item_no ,x['group_desc'],group_desc,"kk")
      //   if((x['chapter_desc']==chapter_no) && (x['sed_item_no']==sed_item_no) && (x['group_desc']==group_desc)){
      //     // console.log("indside")
      //     this.addRowSorItems.splice(j,1);
      //   }
      // })
      console.log(this.addRowSorItems)
      let count = 0;
      let index = 0;
      for (let k = 0; k < this.addRowSorItems.length; k++) {

        // console.log(this.addRowSorItems[k]['rank'])
        // console.log(rank)
        if (this.addRowSorItems[k]['rank'] == rank) {
          count += 1;
          index = k;
          // this.addRowSorItems.splice(k, 1);
          console.log(this.addRowSorItems[k]['rank'])
          console.log(index)
          console.log(count)
        }
      }
      index = index + 1 - count;
      console.log(index)
      console.log(count)
      this.addRowSorItems.splice(index, count);
      // this.addRowSorItems.map((x,j)=>{
      // if(x['rank']==rank){
      // this.addRowSorItems.splice(j, 1);
      // }
      // return x['rank']!=rank
      // })
      // console.log(remainData)
      // this.addRowSorItems=remainData

    }
    console.log(this.sorData)
    this.group = this.sorData.filter(x => {
      console.log(cost_rate_std == x['cost_rate_desc'] && chapter == x['chapter_desc'], x['cast_rate_desc'], x['chapter_desc'], x['sed_item_no'], x['code_no'])
      // console.log(chapter==x['cast_rate_desc'] && cost_rate_std==x['chapter_desc'] && si_code==x['sed_item_no'] ,x['cast_rate_desc'],x['chapter_desc'],x['sed_item_no'],x['code_no'])
      return cost_rate_std == x['cost_rate_desc'] && chapter == x['chapter_desc'] && si_code == x['sed_item_no'] || si_code == x['code_no']
    })
    console.log(this.group)

    this.group = [...new Set(this.group.map((item: any) => item.group_desc))];
    for (let i = 0; i < this.addRowSorItems.length; i++) {
      await this.colculate(i)
    }
  }
  sub_group_data = [];
  innerdata: any = []
  innerDisplayedColumns = ['Id', 'Username', 'City', 'Country'];
  async sub_group(cost_rate_std: any, chapter: any, si_code: any, group: any, i: any, element: any) {
    console.log(group)
    let rank = this.addRowSorItems[i]['rank']
    let chapter_no = this.addRowSorItems[i]['chapter']
    let sed_item_no = this.addRowSorItems[i]['si_code']
    let group_desc = this.addRowSorItems[i]['group_desc']
    // this.addRowSorItems[i].chapter_no=null
    // this.addRowSorItems[i].sed_item_no=null
    // this.addRowSorItems[i].group_desc=null
    this.addRowSorItems[i].sub_group_desc = 'NA'
    this.addRowSorItems[i].qty_unit = null
    this.addRowSorItems[i].rank = undefined
    this.addRowSorItems[i].item_qty = 0
    this.addRowSorItems[i].item_desc = ''
    this.addRowSorItems[i].add_ded = 'add'
    this.addRowSorItems[i].excise_duity = 0
    this.addRowSorItems[i].vat = 0
    this.addRowSorItems[i].frieght_char = 0
    this.addRowSorItems[i].other_taxes = 0
    this.addRowSorItems[i].costing_rate = 0
    this.addRowSorItems[i].amount = 0
    this.addRowSorItems[i].deleteflag = true
    if (rank) {
      //   if(this.addRowSorItems[i]['rank']==rank){
      //   this.addRowSorItems[i]={

      //   chapter_no: chapter,
      //   cost_rate_std: cost_rate_std,
      //   sed_item_no: si_code,
      //   group_desc: group,
      //   sub_group_desc: 'NA',
      //   qty_unit: null,
      //   rank:undefined,
      //   item_qty: 0,
      //   item_desc: '',
      //   add_ded:'add',
      //   excise_duity: 0,
      //   vat: 0,
      //   frieght_char: 0,
      //   atodl: 0,
      //   other_taxes: 0,
      //   costing_rate: 0,
      //   amount: 0,
      //   deleteflag: true
      //   }
      // }
      console.log(this.addRowSorItems)
      console.log(chapter_no)
      console.log(sed_item_no)
      console.log(group_desc)
      // this.addRowSorItems.map((x,j,arr)=>{
      //   console.log((x['chapter_desc']==chapter_no) && (x['sed_item_no']==sed_item_no) && (x['group_desc']==group_desc))
      //   console.log(x['chapter_desc'],chapter_no , x['sed_item_no'] ,sed_item_no ,x['group_desc'],group_desc,"kk")
      //   if((x['chapter_desc']==chapter_no) && (x['sed_item_no']==sed_item_no) && (x['group_desc']==group_desc)){
      //     // console.log("indside")
      //     this.addRowSorItems.splice(j,1);
      //   }
      // })
      console.log(this.addRowSorItems)
      let count = 0;
      let index = 0;
      for (let k = 0; k < this.addRowSorItems.length; k++) {

        // console.log(this.addRowSorItems[k]['rank'])
        // console.log(rank)
        if (this.addRowSorItems[k]['rank'] == rank) {
          count += 1;
          index = k;
          // this.addRowSorItems.splice(k, 1);
          console.log(this.addRowSorItems[k]['rank'])
          console.log(index)
          console.log(count)
        }
      }
      index = index + 1 - count;
      console.log(index)
      console.log(count)
      this.addRowSorItems.splice(index, count);
      // this.addRowSorItems.map((x,j)=>{
      // if(x['rank']==rank){
      // this.addRowSorItems.splice(j, 1);
      // }
      // return x['rank']!=rank
      // })
      // console.log(remainData)
      // this.addRowSorItems=remainData
      if (group == null) {
        return;
      }

    }
    console.log(i, element, "element")
    // console.log(this.datasource, "data")
    // element['innerDatasource'] = new MatTableDataSource([{"Id":1,"Username":"Hull","City":"Cleary","Country":"Seychelles"},{"Id":1,"Username":"Palmer","City":"Tuskahoma","Country":"Niger"},{"Id":1,"Username":"Brennan","City":"Sanborn","Country":"Bosnia and Herzegovina"},{"Id":1,"Username":"Gill","City":"Logan","Country":"French Guiana"},{"Id":1,"Username":"Sloan","City":"Neibert","Country":"Australia"}]);
    // console.log(cost_rate_std, chapter, si_code, group)
    // console.log(this.sorData, "this.sorData")
    let data = this.sorData.filter(x => {
      // console.log(cost_rate_std == x['cost_rate_desc'] && chapter == x['chapter_desc'], x['cast_rate_desc'], x['chapter_desc'], x['sed_item_no'], x['code_no'], x['group_desc'])
      // console.log(chapter==x['cast_rate_desc'] && cost_rate_std==x['chapter_desc'] && si_code==x['sed_item_no'] ,x['cast_rate_desc'],x['chapter_desc'],x['sed_item_no'],x['code_no'])
      return cost_rate_std == x['cost_rate_desc'] && chapter == x['chapter_desc'] && si_code == x['sed_item_no'] || si_code == x['code_no'] && group == x['group_desc']
    })
    console.log(this.addRowSorItems, this.addRowSorItems.length, "addRowSorItems", i)
    console.log(this.sorData, data)

    element['innerDatasource'] = []
    this.innerdata = []
    data.map((x: any) => {
      if (x['sub_group_desc'] == 'NA') {
        element['item_desc'] = x['item_desc']
        element['qty_unit'] = x['qty_unit']
        element['rank'] = i + 1
        this.addRowSorItems[i]['costing_rate'] = x['costing_rate']
      } else {
        this.addRowSorItems[i]['deleteflag'] = false
        x['rank'] = i + 1
        x['excise_duity'] = 0;
        x['add_ded'] = 'add'
        x['vat'] = 0;
        x['frieght_char'] = 0;
        x['atodl'] = 0;
        x['amount'] = 0

        this.addRowSorItems.splice(i + 1, 0, x)

      }
    })
    // element['innerDatasource'] = this.innerdata
    // console.log(element['innerDatasource'], "element['innerDatasource']2")

    // this.addRowSorItems = [...this.addRowSorItems, ...this.innerdata]

    console.log(this.addRowSorItems)
    for (let i = 0; i < this.addRowSorItems.length; i++) {
      await this.colculate(i)
    }
    console.log(this.addRowSorItems)
  }

  add_ded = [{ code: ' + ', value: 'add' }, { code: ' - ', value: 'less' }]


  // getProjectDetails(element: any) {
  //   console.log(element);


  // }
  async deleteGroupRow(i: any) {
    console.log(this.addRowSorItems, i)
    this.addRowSorItems.splice(i, 1);
    this.datasource = new MatTableDataSource(this.addRowSorItems);
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

  }

  async deleteSubGroupItems(i: any) {
    // console.log(element, j)
    // console.log(element['innerDatasource'])
    // let l=element['innerDatasource']
    // element['innerDatasource'].splice(j, 1);


    let group = this.addRowSorItems[i]['group_desc']
    let count = 0
    this.addRowSorItems.map((x: any) => {
      if (x['group_desc'] == group && x['sub_group_desc'] != "NA") {
        count += 1

      }
    })
    console.log(count)
    if (count == 1) {
      this.addRowSorItems[i - 1]['deleteflag'] = true;
      this.addRowSorItems.splice(i, 1);
    } else {
      this.addRowSorItems.splice(i, 1);
    }

    console.log(this.addRowSorItems)
    this.obj['scheduledItemsAmount'] = 0
    this.addRowSorItems.map((x: any) => {
      if (x['add_ded'] == 'add')
        this.obj['scheduledItemsAmount'] += x['amount'];
      else if (x['add_ded'] == 'less') {
        this.obj['scheduledItemsAmount'] -= x['amount'];
      } else {
        this.obj['scheduledItemsAmount'] += x['amount'];
      }
    })



    // await this.addRowSORItems();
    // element['innerDatasource'] = []
    // element['innerDatasource']=l
    // console.log(this.addRowSorItems)
    // let data: any = await this.sub_group1(element['cost_rate_std'], element['chapter'], element['si_code'], element['group_desc'], element)
    // console.log(data)
    // data.map(x => {
    //   if (x['sub_group_desc'] == 'NA') {
    //     element['item_desc'] = x['item_desc']
    //   } else {
    //     element['innerDatasource'].push(x)
    //   }
    // })
    // this.datasource = new MatTableDataSource([]);
    // let da = []
    // this.datasource = new MatTableDataSource(da)
    // this.datasource = new MatTableDataSource(this.addRowSorItems);
    // this.datasource.sort = this.sort;
    // this.datasource.paginator = this.paginator;


    // this.datasource['filteredData'][i]['innerDatasource'] =element['innerDatasource'];
    //  element['innerDatasource'].push(element['innerDatasource'])

    //   for (let j = 0; j < this.addRowSorItems.length; j++) {
    //     if (j > i) {
    //       this.addRowSorItems[j]["item_id"] = this.addRowSorItems[j]["item_id"] - 1;
    //     }
    //   }
    //   this.addRowSorItems.splice(i, 1);
  }


  async sub_group1(cost_rate_std: any, chapter: any, si_code: any, group: any, element: any) {
    // console.log(element, "element")
    // console.log(this.datasource, "data")
    console.log(cost_rate_std, chapter, si_code, group, element)
    // element['innerDatasource'] = new MatTableDataSource([{"Id":1,"Username":"Hull","City":"Cleary","Country":"Seychelles"},{"Id":1,"Username":"Palmer","City":"Tuskahoma","Country":"Niger"},{"Id":1,"Username":"Brennan","City":"Sanborn","Country":"Bosnia and Herzegovina"},{"Id":1,"Username":"Gill","City":"Logan","Country":"French Guiana"},{"Id":1,"Username":"Sloan","City":"Neibert","Country":"Australia"}]);
    // console.log(cost_rate_std, chapter, si_code, group)
    console.log(this.addRowSorItems, "this.addRowSorItems,")
    let data = this.addRowSorItems.filter((x: any) => {
      // console.log(cost_rate_std == x['cost_rate_desc'] && chapter == x['chapter_desc'], x['cast_rate_desc'], x['chapter_desc'], x['sed_item_no'], x['code_no'], x['group_desc'])
      // console.log(chapter==x['cast_rate_desc'] && cost_rate_std==x['chapter_desc'] && si_code==x['sed_item_no'] ,x['cast_rate_desc'],x['chapter_desc'],x['sed_item_no'],x['code_no'])
      return cost_rate_std == x['cost_rate_std'] && chapter == x['chapter'] && si_code == x['si_code'] || si_code == x['code_no'] && group == x['group_desc']
    })
    // console.log(data)
    // element['innerDatasource'] = []
    // return data+


    // console.log(this.sub_group_data)
    // console.log(this.datasource)

  }
  async colculate(i: any) {

    console.log(this.addRowSorItems[i]['amount'], "hh")
    console.log(this.addRowSorItems, i, Number(this.addRowSorItems[i]['item_qty']), Number(this.addRowSorItems[i]['costing_rate']), Number(this.addRowSorItems[i]['excise_duity']), Number(this.addRowSorItems[i]['vat']), Number(this.addRowSorItems[i]['frieght_char']), Number(this.addRowSorItems[i]['atodl']))
    this.addRowSorItems[i]['amount'] = Number(this.addRowSorItems[i]['item_qty']) * Number(this.addRowSorItems[i]['costing_rate']) + Number(this.addRowSorItems[i]['excise_duity']) + Number(this.addRowSorItems[i]['vat']) + Number(this.addRowSorItems[i]['frieght_char']) + Number(this.addRowSorItems[i]['atodl']);
    console.log(this.addRowSorItems[i]['amount'])
    let total_amount = 0
    this.obj['scheduledItemsAmount'] = 0
    this.addRowSorItems.map((x: any) => {
      if (x['add_ded'] == 'add') {
        total_amount += x['amount'];
      } else if (x['add_ded'] == 'less') {
        total_amount -= x['amount'];
      } else {
        total_amount += x['amount'];
      }
    })

    this.obj['scheduledItemsAmount'] += total_amount
    console.log("end")
  }

  sub_group_calculate(j: any) {

    // console.log(this.innerdata[j]['amount'])
    console.log(this.innerdata, j)
    console.log((this.innerdata[j]['excise_duity']), j)
    // console.log(Number(this.innerdata[j]['item_qty']) , Number(this.innerdata[j]['costing_rate']) , Number(this.innerdata[j]['excise_duty']) , Number(this.innerdata[j]['vat']) , Number(this.innerdata[j]['frieght_char']) , Number(this.innerdata[j]['atodl']))

    this.innerdata[j]['amount'] = (this.innerdata[j]['item_qty']) * (this.innerdata[j]['costing_rate']) + Number(this.innerdata[j]['excise_duity']) + Number(this.innerdata[j]['vat']) + Number(this.innerdata[j]['frieght_char']) + Number(this.innerdata[j]['atodl'])
    //  + Number(this.innerdata[j]['excise_duity']) + Number(this.innerdata[j]['vat']) + Number(this.innerdata[j]['frieght_char']) + Number(this.innerdata[j]['atodl']);
    // console.log(this.innerdata)
    // console.log(Number(this.innerdata[j]['amount']))
    let total_amount = 0
    this.innerdata.map((x: any) => {
      total_amount += x['amount']
    })

    this.obj['scheduledItemsAmount'] += total_amount

  }

  Amount = "Quantity x Rate + Excise Duty + GST + Freight Charges + Any other"





  /*--------------------Extra Item ------------------------------*/


  displayedExtraItemsColumns = [
    'sr_no',
    'item_code',
    'item_type',
    'item_desc',
    'qty_unit',
    'qty',
    'costing_unit',
    'costing_rate',
    'amount',
    'actions'
  ]

  extraItemDatasource: any;
  extraItemarr: any = []
  async addExtraItem() {
    let index = this.extraItemarr.length + 1;
    this.extraItemarr.push({

      sr_no: index,
      item_code: null,
      item_type: null,
      item_desc: null,
      qty_unit: null,
      qty: 0,
      costing_unit: null,
      cost_rate_std: null,
      costing_rate: 0,
      amount: 0,

    });
    console.log(this.extraItemarr)
    this.extraItemDatasource = new MatTableDataSource(this.extraItemarr);
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
  }
  async deleteExtraItemRow(i: any) {
    if (this.extraItemarr.length <= 1) {
      Swal.fire("Error", "This is last level can't delete ", "error");
      this.otherChargesarr[i]['cal_on_value'] = 0
      return;
    }
    console.log(this.extraItemarr, i)
    this.extraItemarr.splice(i, 1);
    await this.calculateExtraAmt(i)
    this.extraItemDatasource = new MatTableDataSource(this.extraItemarr);
    this.extraItemDatasource.sort = this.sort;
    this.extraItemDatasource.paginator = this.paginator;
  }
  obj: any = {}
  async calculateExtraAmt(i: any) {
    let totalAmt = 0
    this.extraItemarr.map((x: any) => {
      x['amount'] = Number(x['costing_rate']) * Number(x['qty'])
      totalAmt += Number(x['costing_rate']) * Number(x['qty'])
    })
    this.obj['extraAmount'] = totalAmt

  }
  /*---------------------End-------------------------------------*/


  /*---------------------Ohter Charges--------------------------*/

  otherChargesDs: any;

  displayedOtherChargesColumns = [
    'sr_no',
    'opreator',
    'type',
    'desc',
    'method',
    'value',
    'col_on',
    'col_amt',
    'actions'
  ]
  otherChargesarr: any = []
  async addOtherChargesRow() {
    let index = this.otherChargesarr.length + 1;
    this.otherChargesarr.push({
      sr_no: index,
      // opreator: '',
      // type: '',
      // desc: '',
      // method: '',
      cal_on_value: 0,
      // col_on: '',
      amount: 0,
      col_on_flag: false
    });
    console.log(this.otherChargesarr)
    this.otherChargesDs = new MatTableDataSource(this.otherChargesarr);
    this.otherChargesDs.sort = this.sort;
    this.otherChargesDs.paginator = this.paginator;
  }

  async checkValueType(cal_on_value: any, i: any) {
    let Method = cal_on_value.replace(/^\s+|\s+$/gm, '');

    console.log(Method == 'PER', Method, i, this.otherChargesarr[i])
    if (Method == 'PER') {
      this.otherChargesarr[i]['col_on_flag'] = true;
    }
    else {
      this.otherChargesarr[i]['col_on_flag'] = false;
    }
  }

  async deleteOtherChargeRow(method: any, i: any, cal_on_value: any, a: any) {
    if (this.otherChargesarr.length <= 1) {
      Swal.fire("Error", "This is last level can't delete ", "error");
      this.otherChargesarr[i]['cal_on_value'] = 0
      return;
    }

    console.log(this.otherChargesarr, i)
    this.otherChargesarr.splice(i, 1);
    await this.otherChargeCal(method, i, cal_on_value, 'ts')
    this.otherChargesDs = new MatTableDataSource(this.otherChargesarr);
    this.otherChargesDs.sort = this.sort;
    this.otherChargesDs.paginator = this.paginator;

  }

  async otherChargeCal(method: any, i: any, cal_on_value: any, checkcall: any) {

    // let selectarr=[]
    // selectarr.push(this.otherChargesarr[i])
    if (checkcall != 'ts') {
      if (this.otherChargesarr[i]['opreator'] == undefined) {
        Swal.fire("Error", "Please First select Opreator", "error");
        this.otherChargesarr[i]['cal_on_value'] = 0
        return;
      }
      if (this.otherChargesarr[i]['method'] == undefined) {
        Swal.fire("Error", "Please First select Method", "error");
        this.otherChargesarr[i]['cal_on_value'] = 0
        return;
      }
    }
    let totalAmtAdd = 0
    let totalAmtSub = 0
    if (method != 'PER') {
      this.otherChargesarr.map((x: any) => {

        if (x['opreator'] == 'add') {
          if (x['method'] == 'PER') {
            let cal_on_amt = x['cal_on'].split("-")
            x['amount'] = Number(cal_on_amt[0]) * (Number(x['cal_on_value'] / 100))
          } else {
            x['amount'] = Number(x['cal_on_value'])
          }
          totalAmtAdd += x['amount']
        } else {
          if (x['method'] == 'PER') {
            let cal_on_amt = x['cal_on'].split("-")
            x['amount'] = Number(cal_on_amt[0]) * (Number(x['cal_on_value'] / 100))
          } else {
            x['amount'] = Number(x['cal_on_value'])
          }
          totalAmtSub += x['amount']
        }

      })
    } else {
      if (checkcall != 'ts') {
        if (this.otherChargesarr[i]['cal_on'] == undefined || this.otherChargesarr[i]['cal_on'] == null) {
          return;
        }
      }

      this.otherChargesarr.map((x: any) => {
        console.log(x['opreator'])
        if (x['opreator'] == 'add') {
          if (x['method'] == 'PER') {
            let cal_on_amt = x['cal_on'].split("-")
            x['amount'] = Number(cal_on_amt[0]) * (Number(x['cal_on_value'] / 100))
          } else {
            x['amount'] = Number(x['cal_on_value'])
          }
          totalAmtAdd += x['amount']
        } else {
          if (x['method'] == 'PER') {
            let cal_on_amt = x['cal_on'].split("-")
            x['amount'] = Number(cal_on_amt[0]) * (Number(x['cal_on_value'] / 100))
          } else {
            x['amount'] = Number(x['cal_on_value'])
          }
          totalAmtSub += x['amount']
        }

      })
    }
    this.obj['totalAmtAdd'] = totalAmtAdd
    this.obj['totalAmtSub'] = totalAmtSub
    this.obj['finalAmt'] = this.obj['totalAmount'] + totalAmtAdd - totalAmtSub
  }
  spr: any
  async firstnext(stepper: MatStepper) {
    this.spr = stepper
    $("#estimatemode").modal("show");
  }

  async validationOnCreate() {
    let returnVal = null
    if (!this.Obj['est_desc'] || this.Obj['est_desc'] == null || this.Obj['est_desc'] == undefined) {
      returnVal = 'Please Enter A Valid Estimate Description ... '
      return returnVal;
    } else if (!this.Obj['budget_amt'] || this.Obj['budget_amt'] == null || this.Obj['budget_amt'] == undefined || this.Obj['budget_amt'] == 0) {
      returnVal = 'Please Select A Valid Budget ... '
      return returnVal;
    } else if (!this.Obj['est_date'] || this.Obj['est_date'] == null || this.Obj['est_date'] == undefined) {
      returnVal = 'Please Select A Valid Date ... '
      return returnVal;
    } else if (!this.Obj['fund_use'] || this.Obj['fund_use'] == null || this.Obj['fund_use'] == undefined) {
      returnVal = 'Please Select A Valid fund ... '
      return returnVal;
    } else if (!this.Obj['work_name'] || this.Obj['work_name'] == null || this.Obj['work_name'] == undefined) {
      returnVal = 'Please Select A Valid Work ... '
      return returnVal;
    } else if (!this.Obj['path_desc'] || this.Obj['path_desc'] == null || this.Obj['path_desc'] == undefined) {
      returnVal = 'Please Select A Valid Node ... '
      return returnVal;
    } else {
      return 1;
    }
  }
  _bridge_est_flag = true;
  _general_est_flag = true;
  _road_est_flag = true
  estimation_type: any;
  async bridgeEst(stepper: MatStepper) {

    let validateRes: any = null;
    validateRes = await this.validationOnCreate()
    if (validateRes != 1) {
      Swal.fire("Error...", validateRes, "error");
      return
    }

    this._bridge_obj['culvert_type'] == ""

    await this.getAllInputsForBridge()


    let flag = false
    // this.addRowSorItems.map((x: any) => {
    //   if (this.Obj['work_name'] == undefined || this.Obj['work_name'] == null || this.Obj['path_desc'] == undefined || this.Obj['path_desc'] == null || this.budpath == undefined || this.budpath == null || this.Obj['budget_cd'] == undefined || this.Obj['budget_cd'] == null || this.Obj['est_id_p'] == undefined || this.Obj['est_id_p'] == null || this.Obj['est_desc'] == null || this.Obj['est_date'] == null || this.Obj['fund_use'] == null) {
    //     Swal.fire("Error...", "Please fill all the required fields", "error");
    //     flag = true
    //     return;
    //   }

    // })

    this._step_second_label = 'Inputs'
    this._step_third_label = 'Inputs Reports'
    this._step_forth_label = 'Measurement Details'
    this._step_fifth_label = 'Analysis of Rate'
    this._step_six_label = 'BOQ for Bridge'
    this.six = false;

    this._bridge_est_flag = false
    this._general_est_flag = true
    this._road_est_flag = true
    this.submitted = false



    this._protection_work_obj = {}
    this._super_str_obj = {}
    this._sub_str_obj = {}
    this._foundation_obj = {}

    stepper.next()

    let __obj: any = this.Obj
    __obj['b_acct_id'] = this.b_acct_id
    __obj['budget_path'] = this.budpath
    __obj['user_id'] = this.ebillUser.le_id
    __obj['scheduledItemsAmount'] = 0
    __obj['extraAmount'] = 0
    __obj['finalAmt'] = 0
    __obj['extraItemarr'] = 0
    __obj['life_cycle_status'] = "INCOMP"
    __obj['status'] = 'GENERATED'
    __obj['est_type'] = 'BRIDGE'
    this.estimation_type = "BRIDGE"
    // _obj['bridge_input_data']={}
    // _obj['bridge_input_data']['fundation']=JSON.stringify(this._foundation_obj)
    // _obj['bridge_input_data']['sub_structure']=JSON.stringify(this._sub_str_obj)
    // _obj['bridge_input_data']['protection_work']=JSON.stringify(this._protection_work_obj)
    // _obj['bridge_input_data']['bridge_location']=this._bridge_obj['bridge_location']
    // _obj['bridge_input_data']['bridge_type']=this._bridge_obj['bridge_type']
    // _obj['mes_data']=this.mea_item
    // _obj['ana_data']=this._ana_items
    // _obj['boq_data']=this.boq_items

    await this.getEstimate()
    console.log('all estimate_data', this.estimate_data, __obj.est_id_p)
    await this.estimate_data.map(async (x: any) => {
      if (x.est_id_p == __obj['est_id_p'] && x.est_type == __obj['est_type']) {
        console.log('alreadyyy', x)
        if (x.life_cycle_status == "INCOMP") {
          // delete data which is already present
          console.log('deleteatingg')
          let objw = Object();
          objw["b_acct_id"] = this.b_acct_id;
          objw["est_id_p"] = x.est_id_p
          objw["est_type"] = x.est_type
          objw["life_cycle_status"] = "INCOMP"
          this.spinner.show();
          var resp = await this.estimateService.deleteline(objw);
          if (resp["error"] == false) {
            this.spinner.hide();
          }
          else {
            this.spinner.hide()
            Swal.fire("Error...", "Server side error", "error");
          }
        }
      }
    })
    console.log('__obj', __obj)

    if (this.bridgeUpdate) {
      // for setting input feilds
      await this.getAllBridgeInputs(__obj['est_id_p'], this.estimation_type);
      console.log(this.allBridgeInputs[0].bridge_location, 'inputssss =>', this.allBridgeInputs)
      this._bridge_flag = true
      this._bridge_obj['bridge_location'] = this.allBridgeInputs[0].bridge_type
      await this.bridgeType()
      this._bridge_obj['bridge_type'] = this.allBridgeInputs[0].bridge_location
      await this.inputFields();
      let element2 = this.allBridgeInputs;
      element2.map((x: any) => {
        this._foundation_obj = JSON.parse(x.foundation)
        this._sub_str_obj = JSON.parse(x.sub_structure)
        this._super_str_obj = JSON.parse(x.super_structure)
        this._protection_work_obj = JSON.parse(x.protection_work)
      })
      this.spinner.hide();

      // await this.updateBridgeArrEstimation()
      // return
    } else {
      this.spinner.show();
      /* check if it already exist (same id and type ) */

      var resp = await this.estimateService.createEstimateForBridge(__obj);
      if (resp["error"] == false) {
        // await this.submithier(this.Obj['est_id_p'])
        // await this.getEstimate()
        // Swal.fire("Success", " Successfully", "success");
        // await this.list()
        console.log('created entryyy')
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    }
  }


  async updateBridgeArrEstimation() {
    let __obj: any = this.Obj
    __obj['b_acct_id'] = this.b_acct_id
    __obj['budget_path'] = this.budpath
    __obj['user_id'] = this.ebillUser.le_id
    __obj['scheduledItemsAmount'] = 0
    __obj['extraAmount'] = 0
    __obj['finalAmt'] = 0
    __obj['extraItemarr'] = 0
    __obj['life_cycle_status'] = "ACTIVE"
    __obj['status'] = 'GENERATED'
    __obj['est_type'] = 'BRIDGE'

    console.log(' api', __obj)
    var obb: any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['id'] = __obj['est_id_p']
    obb['update_user_id'] = this.ebillUser.user_id
    obb['status'] = 'UPDATED'
    var resp2 = await this.estimateService.updateLifeCycleStatus(obb)
    if (resp2['error'] == false) {
      console.log('Success  lyf cycle status updated', __obj);
      __obj['work_name'] = __obj['inc_id_f']
      var resp = await this.estimateService.createEstimateForBridge(__obj);
      if (resp["error"] == false) {
        this.spinner.hide();
      }
      this.spinner.hide();
    }
  }
  async roadEst(stepper: MatStepper) {
    let validateRes: any = null;
    validateRes = await this.validationOnCreate()
    if (validateRes != 1) {
      Swal.fire("Error...", validateRes, "error");
      return
    }
    this._step_second_label = 'Inputs'
    this._step_third_label = 'Inputs Reports'
    this._step_forth_label = 'Measurement Details'
    this._step_fifth_label = 'Analysis of Rate'
    this._step_six_label = 'BOQ for Road'



    this._input_approach_road_obj = {}



    this._bridge_est_flag = true
    this._general_est_flag = true
    this._road_est_flag = false
    stepper.next()
    let __obj: any = this.Obj
    __obj['b_acct_id'] = this.b_acct_id
    __obj['budget_path'] = this.budpath
    __obj['user_id'] = this.ebillUser.le_id
    __obj['scheduledItemsAmount'] = 0
    __obj['extraAmount'] = 0
    __obj['finalAmt'] = 0
    __obj['extraItemarr'] = 0
    __obj['life_cycle_status'] = "INCOMP"
    __obj['status'] = 'GENERATED'
    __obj['est_type'] = 'ROAD'
    __obj['budget_cd'] = this.Obj['budget_cd']
    this.estimation_type = "ROAD"
    console.log('__obj', __obj)
    /*  delete existing  */
    await this.getEstimate()

    await this.estimate_data.map(async (x: any) => {
      if (x.est_id_p == __obj['est_id_p'] && x.est_type == __obj['est_type']) {
        console.log('alreadyyy', x)
        if (x.life_cycle_status == "INCOMP") {
          // delete data which is already present
          let objw = Object();
          objw["b_acct_id"] = this.b_acct_id;
          objw["est_id_p"] = x.est_id_p
          objw["est_type"] = x.est_type
          objw["life_cycle_status"] = "INCOMP"
          this.spinner.show();
          var resp = await this.estimateService.deleteline(objw);
          if (resp["error"] == false) {
            console.log('deleted')
            this.spinner.hide();
          }
          else {
            this.spinner.hide()
            Swal.fire("Error...", "Server side error", "error");
          }
        }
      }
    })


    this.spinner.show();
    var resp = await this.estimateService.createEstimateForBridge(__obj);
    if (resp["error"] == false) {
      // await this.submithier(this.Obj['est_id_p'])
      // await this.getEstimate()
      // Swal.fire("Success", " Successfully", "success");
      // await this.list()
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }

  }



  async generalEst(stepper: MatStepper) {

    // let flag = false
    // this.addRowSorItems.map((x: any) => {
    //   if (this.Obj['work_name'] == undefined || this.Obj['work_name'] == null || this.Obj['path_desc'] == undefined || this.Obj['path_desc'] == null || this.budpath == undefined || this.budpath == null || this.Obj['budget_cd'] == undefined || this.Obj['budget_cd'] == null || this.Obj['est_id_p'] == undefined || this.Obj['est_id_p'] == null || this.Obj['est_desc'] == null || this.Obj['est_date'] == null || this.Obj['fund_use'] == null) {
    //     Swal.fire("Error...", "Please fill all the required fields", "error");
    //     flag = true
    //     return;
    //   }

    // })
    let validateRes: any = null;
    validateRes = await this.validationOnCreate()
    if (validateRes != 1) {
      Swal.fire("Error...", validateRes, "error");
      return
    }
    this._step_second_label = 'Scheduled Items'
    this._step_third_label = 'Extra Items'
    this._step_forth_label = 'Other charges'
    this._step_fifth_label = 'Preview & Submit'
    this.six = false;
    // this.uploadFile.map((x: any) => {
    //   if (x['doc_type'] == undefined || x['doc_type'] == null || !(x['upflag'])) {
    //     Swal.fire("Error...", "Please fill all the required fields", "error");
    //     flag = true
    //     return;
    //   }

    // })
    this._bridge_est_flag = true
    this._road_est_flag = true
    // if (flag == false) {
    $("#estimatemode").modal("hide");
    stepper.next()
    this._general_est_flag = false

    // }
    if (this.update_flag == true) {
      let obj: any = {}
      obj['b_acct_id'] = this.b_acct_id;
      obj['est_id_f'] = this.est_id
      obj['step'] = 'first'
      var resp = await this.estimateService.getShudExtraOtherData(obj);
      console.log(resp)

      if (resp["error"] == false) {
        this.obj['scheduledItemsAmount'] = 0;
        this.addRowSorItems = resp['data']
        console.log(this.addRowSorItems)
        this.addRowSorItems.map((x: any) => {
          if (x['sub_group_desc'] == "NA") {
            x['deleteflag'] = false;
          } else {
            x['deleteflag'] = true;

          }
          this.obj['scheduledItemsAmount'] += x['amount'];
        })
        console.log(this.addRowSorItems)

        console.log(this.obj['scheduledItemsAmount'])

        // this.spinner.hide();
      } else {
        // this.spinner.hide();
      }
    }

    // stepper.next()


  }

  async secondnext(stepper: MatStepper) {

    let flag = false

    this.addRowSorItems.map((x: any) => {
      if (x['cost_rate_std'] == undefined || x['cost_rate_std'] == null || x['chapter'] == undefined || x['chapter'] == null || x['si_code'] == undefined || x['si_code'] == null || x['group_desc'] == undefined || x['group_desc'] == null || x['add_ded'] == undefined || x['add_ded'] == null) {
        Swal.fire("Error...", "Please fill all the required fields", "error");
        flag = true
        return;
      }

    })
    if (flag == false) {
      stepper.next()
    }

    if (this.update_flag == true) {
      let obj: any = {}
      obj['b_acct_id'] = this.b_acct_id;
      obj['est_id_f'] = this.est_id
      obj['step'] = 'second'
      var resp = await this.estimateService.getShudExtraOtherData(obj);
      console.log(resp)
      if (resp["error"] == false) {
        this.extraItemarr = resp['data']
        this.obj['extraAmount'] = 0
        this.extraItemarr.map((x: any) => {
          this.obj['extraAmount'] += x['amount']
        })
        console.log(this.extraItemarr)
        this.extraItemDatasource = new MatTableDataSource(this.extraItemarr);
        this.datasource.sort = this.sort;
        this.datasource.paginator = this.paginator;

        // this.spinner.hide();
      } else {
        // this.spinner.hide();
      }
    }

  }

  col_on_cd = [{ value: 'Total Extra Item', code: this.obj['extraAmount'] + '-' + 'Total Extra Item' }, { value: 'Total Scheduled Items', code: this.obj['scheduledItemsAmount'] + '-' + 'Total Scheduled Items' }, { value: 'Total Amount', code: this.obj['totalAmount'] + '-' + 'Total Amount' }]
  async nextThirdStep(stepper: MatStepper) {
    console.log("third", this.extraItemarr)
    let flag = false
    if (this.extraItemarr.length > 0) {
      this.extraItemarr.map((x: any) => {

        if (x['item_code'] == undefined || x['item_code'] == null || x['item_type'] == undefined || x['item_type'] == null || x['item_desc'] == undefined || x['item_desc'] == null || x['qty_unit'] == undefined || x['qty_unit'] == null || x['qty'] == undefined || x['qty'] == null || x['costing_unit'] == undefined || x['costing_unit'] == null || x['costing_rate'] == undefined || x['costing_rate'] == null) {
          Swal.fire("Error...", "Please fill all the required fields", "error");
          flag = true
          return;
        }

      })
    }
    if (flag == false) {
      stepper.next()
    }

    if (this.update_flag == true) {
      let obj: any = {}
      obj['b_acct_id'] = this.b_acct_id;
      obj['est_id_f'] = this.est_id
      obj['step'] = 'third'
      var resp = await this.estimateService.getShudExtraOtherData(obj);
      console.log(resp)
      if (resp["error"] == false) {
        this.otherChargesarr = resp["data"]
        this.otherChargesarr.map((x: any) => {
          if (x['cal_on'] == "Total Extra Item") {
            x['col_on_flag'] = true
            x['cal_on'] = this.obj['extraAmount'] + '-' + 'Total Extra Item'
            x['amount'] = Number(this.obj['extraAmount']) * (Number(x['cal_on_value'] / 100))
          } else if (x['cal_on'] == 'Total Scheduled Items') {
            x['col_on_flag'] = true
            x['cal_on'] = this.obj['scheduledItemsAmount'] + '-' + 'Total Scheduled Items'
            x['amount'] = Number(this.obj['scheduledItemsAmount']) * (Number(x['cal_on_value'] / 100))
          }
          else {
            x['col_on_flag'] = false
          }
        })

        let totalAmtAdd = 0
        let totalAmtSub = 0
        this.otherChargesarr.map((x: any) => {
          console.log(x['opreator'])
          if (x['opreator'] == 'add') {
            if (x['method'] == 'PER') {
              let cal_on_amt = x['cal_on'].split("-")
              x['amount'] = Number(cal_on_amt[0]) * (Number(x['cal_on_value'] / 100))
            } else {
              x['amount'] = Number(x['cal_on_value'])
            }
            totalAmtAdd += x['amount']
          } else {
            if (x['method'] == 'PER') {
              let cal_on_amt = x['cal_on'].split("-")
              x['amount'] = Number(cal_on_amt[0]) * (Number(x['cal_on_value'] / 100))
            } else {
              x['amount'] = Number(x['cal_on_value'])
            }
            totalAmtSub += x['amount']
          }

        })
        this.obj['totalAmtAdd'] = totalAmtAdd
        this.obj['totalAmtSub'] = totalAmtSub
        this.obj['finalAmt'] = this.obj['totalAmount'] + totalAmtAdd - totalAmtSub

        // await this.otherChargeCal(method, i, cal_on_value,'ts')
        this.otherChargesDs = new MatTableDataSource(this.otherChargesarr);
        this.otherChargesDs.sort = this.sort;
        this.otherChargesDs.paginator = this.paginator;
        // this.spinner.hide();
      } else {
        // this.spinner.hide();
      }
    }

    this.obj['totalAmount'] = this.obj['extraAmount'] + this.obj['scheduledItemsAmount'];
    this.obj['finalAmt'] = this.obj['totalAmount'] + this.obj['totalAmtAdd'] - this.obj['totalAmtSub'];
    this.col_on_cd = [{ value: 'Total Extra Item', code: this.obj['extraAmount'] + '-' + 'Total Extra Item' }, { value: 'Total Scheduled Items', code: this.obj['scheduledItemsAmount'] + '-' + 'Total Scheduled Items' }, { value: 'Total Amount', code: this.obj['totalAmount'] + '-' + 'Total Amount' }]
  }
  /*------------------------End---------------------------------*/

  async nextvalue(stepper: MatStepper) {

    console.log(this.dataSource);
    let flag = false
    if (this.otherChargesarr.length > 0) {
      this.otherChargesarr.map((x: any) => {
        if (x['opreator'] == undefined || x['opreator'] == null || x['type'] == undefined || x['type'] == null || x['desc'] == undefined || x['desc'] == null || x['method'] == undefined || x['method'] == null || x['amount'] == undefined || x['amount'] == null) {
          Swal.fire("Error...", "Please fill all the required fields", "error");
          flag = true
          return;
        }

        if (x['cal_on'] == undefined || x['cal_on'] == null) {
          x['cal_on'] = 'NA'
        }
      })
    }

    if (flag == false) {
      stepper.next()
    }

  }

  estimate_data: any;
  est_id_p: any;
  activeEstimation: any

  async getEstimate() {
    this.spinner.show();
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    let dataForTable: any
    let resp = await this.estimateService.getestimate(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.estimate_data = resp["data"];
      dataForTable = resp['data']
      console.log(this.estimate_data)
      if (this.estimate_data.length >= 1) {
        this.est_id_p = resp['data'][resp['data'].length - 1]['est_id_p']
        this.est_id_p = this.est_id_p + 1
        // await this.setEstDataForAutoFill(resp['data'])
      } else {
        this.est_id_p = 1
      }
      dataForTable = dataForTable.filter((x: any) => {
        if (x['life_cycle_status'] == "ACTIVE") {
          return x;
        }
      })
      this.activeEstimation = dataForTable

      console.log('fata for table ==>', dataForTable)

      let tempArr: any = []
      let allWorkIds = [...new Set(dataForTable.map((obj: any) => obj.inc_id_f))];
      console.log('all works ==>', allWorkIds) //ex [1,2,3,4]

      let newrTemp: any = []
      let allData: any = dataForTable
      for (let i = 0; i < allWorkIds.length; i++) {
        let allest: any = []
        allData.map((k: any) => {
          if (k.inc_id_f == allWorkIds[i]) {
            allest.push(k)
          }
        })
        let checedIds: any = []
        allData.map((l: any) => {
          if (!(checedIds.includes(l.inc_id_f))) {
            if (l.inc_id_f == allest[0].inc_id_f) {
              l['estimates'] = allest
              checedIds.push(l.inc_id_f)
            }
          }
        })
      }
      let finalArray = allData.filter((obj: any) => obj.hasOwnProperty('estimates'));
      console.log(finalArray);

      finalArray.map((x: any) => {
        let est: any = x.estimates;
        console.log('e', est)
        est.map((k: any) => {
          if (k.est_type == "BRIDGE") {
            x['isBridge'] = true
          }
          if (k.est_type == "ROAD") {
            x['isRoad'] = true
          }
          if (k.est_type == "GENERAL") {
            x['isGen'] = true
          }
        })
      })


      console.log('all final ========>', finalArray)
      dataForTable = finalArray
      this.est_list_dataSource = new MatTableDataSource(dataForTable);
      this.est_list_dataSource.sort = this.sort;
      this.est_list_dataSource.paginator = this.paginator;
      this.spinner.hide()
    } else {
      this.spinner.hide();
    }
  }

  async setEstDataForAutoFill(data: any) {
    console.log('data for auto fill for Create Estimate =>', data);
    data.map((x: any) => {
      if (x.status == "GENERATED" && x.life_cycle_status == "INCOMP") {
        this.Obj = x
      }
    })
  }
  async submit() {
    // this.spinner.show();

    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['scheduledItemsAmount'] = Number(this.obj['scheduledItemsAmount'])
    this.Obj['extraAmount'] = Number(this.obj['extraAmount'])

    this.Obj['finalAmt'] = this.obj['finalAmt']
    // console.log(this.extraItemarr)
    this.Obj['extraItemarr'] = this.extraItemarr
    this.Obj['otherChargesarr'] = this.otherChargesarr
    this.Obj['addRowSorItems'] = this.addRowSorItems
    this.Obj['user_id'] = this.ebillUser.le_id
    this.Obj['life_cycle_status'] = "ACTIVE"
    this.Obj['budget_head_cd'] = this.Obj['budget_cd']
    console.log(this.Obj)
    this.spinner.show();
    var resp = await this.estimateService.createestimate(this.Obj);
    if (resp["error"] == false) {
      await this.submithier(this.Obj['est_id_p'])
      await this.getEstimate()
      // Swal.fire("Success", " Successfully", "success");
      await this.list()
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  est_id: any;

  undertakings: any = []
  async download_ana(element: any) {
    await this.getAllBridgeInputs(element.est_id_p, element.est_type);
    let element2: any = {}
    element2 = this.allBridgeInputs


    await this.getAllAnalysisOfRate(element.est_id_p, element.est_type)
    console.log('alll allAnaRates =>', this.allAnaRates)


    this._ana_items = this.allAnaRates;
    // this.Obj['work_name'] = this.work_list_obj[element.inc_id_f]['pro_name'];

    await this.camelCase(this.work_list_obj[element.inc_id_f]['pro_name']).then((value) => {
      this.Obj['work_name'] = value
    }).catch((error) => {
      console.error(error);
    });


    this._bridge_obj['culvert_type'] = element2[0].bridge_location //Culvert2 or culvert1
    this._bridge_obj['bridge_location'] = element2[0].bridge_type //rular o urban
    this._bridge_obj['bridge_type'] = element2[0].bridge_location == "CULVERT1" || element2[0].bridge_location == "CULVERT2" ? "CULVERT" : "BOX_CULVERT"


    console.log('------------------', this._bridge_obj['bridge_location'], this._bridge_obj['bridge_type'])
    await this.anaPrint();
  }

  async download_boq(element: any) {

    await this.getAllBridgeInputs(element.est_id_p, element.est_type);
    let element2: any = {}
    element2 = this.allBridgeInputs


    await this.getAllBoqs(element.est_id_p, element.est_type);
    this.boq_items = this.allBoqsdata;
    console.log('elemedfddnt for boq data ', this.boq_items)

    this.Obj['work_name'] = this.work_list_obj[element.inc_id_f]['pro_name'];

    this._bridge_obj['culvert_type'] = element2[0].bridge_location //Culvert2 or culvert1
    this._bridge_obj['bridge_location'] = element2[0].bridge_type //rular o urban
    this._bridge_obj['bridge_type'] = element2[0].bridge_location == "CULVERT1" || element2[0].bridge_location == "CULVERT2" ? "CULVERT" : "BOX_CULVERT"


    await this.boqPrint();
  }


  async download_inputs(element: any) {
    let element2: any = []
    console.log('to print=>', element)

    await this.getAllBridgeInputs(element.est_id_p, element.est_type);


    element2 = this.allBridgeInputs;

    this._foundation_obj = !(element2[0].foundation) ? {} : JSON.parse(element2[0].foundation)
    this._sub_str_obj = !(element2[0].sub_structure) ? {} : JSON.parse(element2[0].sub_structure)
    this._super_str_obj = !(element2[0].super_structure) ? {} : JSON.parse(element2[0].super_structure)
    this._protection_work_obj = !(element2[0].protection_work) ? {} : JSON.parse(element2[0].protection_work)


    if (element2[0].est_type != "ROAD") {
      this._bridge_obj['culvert_type'] = element2[0].bridge_location //Culvert2 or culvert1
      this._bridge_obj['bridge_location'] = element2[0].bridge_type //rular o urban
      this._bridge_obj['bridge_type'] = element2[0].bridge_location == "CULVERT1" || element2[0].bridge_location == "CULVERT2" ? "CULVERT" : "BOX_CULVERT"
      await this.getAllInputsForBridge()
      await this.inputSetfomula()
    } else {
      this._road_obj['road_technique'] = element2[0].bridge_location //old new
      this._road_obj['road_location'] = element2[0].bridge_type //appr or add appr
      // await this.getAllInputsForBridge()
      await this.inputSetFormulaForRoad()
    }


    await this.camelCase(this.work_list_obj[element.inc_id_f]['pro_name']).then((value) => {
      this.Obj['work_name'] = value
    }).catch((error) => {
      console.error(error);
    });

    this._bridge_obj['bridge_type'] = element2[0].bridge_type

    console.log('here')

    if (element2[0].est_type != "ROAD") {

      await this.inputPrint();
    } else {
      await this.inputPrintForRoad()
    }

  }


  async camelCase(text: any) {
    return text.replace(/\s(\w)/g, (_: any, letter: any) => letter.toUpperCase())
      .replace(/\s/g, '')
      .replace(/^(.)/, (_: any, firstLetter: any) => firstLetter.toUpperCase())
      .replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  async downlod_meas(element: any) {
    console.log('to print=>', element)

    let element2: any = {}
    await this.getAllBridgeInputs(element.est_id_p, element.est_type);


    element2 = this.allBridgeInputs;
    console.log('to Inputs=>', this.allBridgeInputs)
    console.log('to Inputs=fffff>', this.allBridgeInputs, element2[0].bridge_location, element2[0])



    this._foundation_obj = !(element2[0].foundation) ? {} : JSON.parse(element2[0].foundation)
    this._input_approach_road_obj = !(element2[0].foundation) ? {} : JSON.parse(element2[0].foundation)
    this._sub_str_obj = !(element2[0].sub_structure) ? {} : JSON.parse(element2[0].sub_structure)
    this._super_str_obj = !(element2[0].super_structure) ? {} : JSON.parse(element2[0].super_structure)
    this._protection_work_obj = !(element2[0].protection_work) ? {} : JSON.parse(element2[0].protection_work)

    console.log('gggggggggggggggggggg', this._foundation_obj)

    if (element2[0].est_type != "ROAD") {
      this._bridge_obj['culvert_type'] = element2[0].bridge_location //Culvert2 or culvert1
      this._bridge_obj['bridge_location'] = element2[0].bridge_type //rular o urban
      this._bridge_obj['bridge_type'] = element2[0].bridge_location == "CULVERT1" || element2[0].bridge_location == "CULVERT2" ? "CULVERT" : "BOX_CULVERT"
      await this.getAllInputsForBridge()
      await this.inputSetfomula()


      await this.camelCase(this.work_list_obj[element.inc_id_f]['pro_name']).then((value) => {
        this.Obj['work_name'] = value
      }).catch((error) => {
        console.error(error);
      });

      await this.getBridgeItems()
      await this.mesurement()


    } else {
      this._road_obj['road_technique'] = element2[0].bridge_location //old new
      this._road_obj['road_location'] = element2[0].bridge_type //appr or add appr
      await this.inputFieldsForRoad()
      await this.getAllInputsForBridge()
      await this.inputSetFormulaForRoad()


      await this.camelCase(this.work_list_obj[element.inc_id_f]['pro_name']).then((value) => {
        this.Obj['work_name'] = value
      }).catch((error) => {
        console.error(error);
      });

      await this.getBridgeItems()
      await this.mesurementForRoad()
    }





    if (element2[0].est_type != "ROAD") {
      await this.measurementPrint()
    } else {
      await this.measurementPrintForRoad()
    }
  }



  bridgeUpdate: any = false
  roadUpdate: any = false
  generalUpdate: any = false


  async open_update(element: any) {
    this.spinner.show();
    console.log('to update', element)
    console.log(this.work_list_obj)
    this.Obj = element;
    console.log(this.work_list_obj[element.inc_id_f]['pro_name'])
    this.Obj['work_name'] = this.work_list_obj[element.inc_id_f]['pro_name'];
    this.Obj['inc_id_f'] = element.inc_id_f
    this.listmenuflag = 'deactive'
    this.createmenuflag = 'deactive'
    this.updatemenuflag = 'active'
    let doc_obj: any = {}
    doc_obj['b_acct_id'] = this.b_acct_id;
    doc_obj['est_id_f'] = element.est_id_p;
    let resp = await this.estimateService.getdocslist(JSON.stringify(doc_obj));
    if (resp['error'] == false) {

      this.uploadFile = []
      this.uploadFile = resp['data']
      this.spinner.hide()
    }
    else {
      this.spinner.hide()
    }


    if (element.est_type == "BRIDGE") {
      this.bridgeUpdate = true
    } else if (element.est_type == "ROAD") {
      this.bridgeUpdate = true
    } else if (element.est_type == "GENERAL") {
      this.generalUpdate = true
    }
    this.create_flag = false;
    this.list_flag = false;
    this.update_flag = true;
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['est_id_f'] = element.est_id_p;
    this.Obj['budget_amt'] = element.budget_value
    this.budpath = element.budget_cd
    this.Obj['budget_cd'] = element.budget_head_cd

    await this.getbudgetforupdate();
    this.est_id = element.est_id_p
    console.log(this.work_list)
    console.log(element.est_id_p)
    console.log(this.est_id)
    this.spinner.hide();

  }

  async update() {
    this.spinner.show();
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['scheduledItemsAmount'] = this.obj['scheduledItemsAmount']
    this.Obj['extraAmount'] = this.obj['extraAmount']
    this.Obj['finalAmt'] = this.obj['finalAmt']
    this.Obj['extraItemarr'] = this.extraItemarr
    this.Obj['otherChargesarr'] = this.otherChargesarr
    this.Obj['addRowSorItems'] = this.addRowSorItems
    console.log(this.Obj)
    var resp = await this.estimateService.updateeestimate(this.Obj);
    if (resp["error"] == false) {
      await this.getEstimate()
      Swal.fire("Success", "Updated Successfully", "success");
      await this.list()
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  async deleteest(element: any) {
    Swal.fire({

      title: "Are you sure?",
      // imageUrl: 'https://unsplash.it/400/200',
      //  imageWidth: 400,
      //  imageHeight: 200,
      // imageAlt: 'Custom image',
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show()
        let obj: any = {}
        obj['b_acct_id'] = this.b_acct_id
        obj['est_id_p'] = element['est_id_p']
        obj['est_type'] = element['est_type']
        var resp = await this.estimateService.deleteest(obj);
        if (resp["error"] == false) {
          await this.getEstimate()
          this.spinner.hide()
          Swal.fire(
            'Deleted!',
            'Deleted Successfully',
            'success'
          )
          await this.list();
        }
        else {
          this.spinner.hide()
          Swal.fire("Error...", "Server side error", "error");
        }

      }

    });
  }

  async view_dtl(element: any) {
    console.log(element)
    this.spinner.show();
    this.Obj = element;
    this.Obj['work_name'] = this.work_list_obj[element.inc_id_f]['pro_name'];
    this.Obj['totalAmount'] = element['scheduledItemsAmount'] + element['extraAmount']
    this.Obj['inc_id_f'] = element.inc_id_f
    await this.getbudgetforupdate();
    $('#view_dtl').modal('show');
    this.spinner.hide();

  }

  /*--------------------approval-------------------------------*/
  //workflow*********************************************//
  level_for_approval: any = []
  workflow_level1: any = {}
  apprflag = false;
  async getWorkflowlog() {
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE'
    obj['doc_type'] = 'ESTIMATE'
    obj['module_cd'] = "EMB"
    var resp = await this.mainService.getWorkflows(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      if (resp['data'].length != 0) {
        this.workflow_level1 = resp['data'][0]
        this.level_for_approval = resp['data']
        var currentuser_role = this.ebillUser.role_cd
        for (let i = 0; i < currentuser_role.length; i++) {
          if (this.workflow_level1['role_cd'] == currentuser_role[i]) {
            this.apprflag = true
          }
        }
        this.level1 = this.workflow_level1
      }
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }

  estimate_apprdata: any = {}
  async Appr(element: any) {
    this.estimate_apprdata = Object.assign({}, element)
    this.estimate_apprdata['level'] = 1
    this.estimate_apprdata['branch'] = 1
    this.estimate_apprdata['doc_local_no'] = element['est_id_p']
    this.estimate_apprdata['doc_type'] = 'ESTIMATE'//this.tender_apprdata['id']
    this.estimate_apprdata['node_cd'] = element['est_id_p']
    this.estimate_apprdata['module_cd'] = 'EMB'
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'ESTIMATE', 'EMB')
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.ebillUser, this.estimate_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.estimate_apprdata)
    $('#myModal2').modal('show');
  }
  forwarded_user_id: any;
  appr_remark: any;
  level1: any = {}

  message_flag = false

  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb: any = new Object();


      obb['user_id'] = this.ebillUser.user_id

      obb['user_name'] = this.ebillUser.party_name;
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'ESTIMATE'
      obb['doc_local_no'] = this.estimate_apprdata['est_id_p']
      obb['doc_desc'] = this.estimate_apprdata['est_desc']
      obb['role_cd'] = this.level1['role_cd']
      obb['module_cd'] = 'EMB'
      obb['node_cd'] = this.estimate_apprdata['node_cd']
      obb['level'] = this.level1['level']
      obb['undertaking'] = ''
      obb['branch'] = this.level1['branch']
      obb['field_value'] = 0
      console.log(this.estimate_apprdata)
      var resp = await this.mainService.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.updatestatus(this.estimate_apprdata['est_id_p'])
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {

          //  if(this.message_flag == true) await this.getle(this.selectedTender['party_id'])
        } else {
          //  if(this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        this.toastr.success("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        this.toastr.error(resp['data'])
      }
    } else {
      this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb: any = new Object();

      // var obb = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          Swal.fire("Error", "Vender concept are not allow ", 'error')
          return;
        }
        if (this.estimate_apprdata['party_id'] == null || this.estimate_apprdata['party_id'] == undefined) {
          Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
          return
        }
        obb['user_id'] = -1
        obb['vendor_id'] = this.estimate_apprdata['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }


      if (obb["user_id"] == -1) {
        let userData: any = {};
        userData['b_acct_id'] = this.b_acct_id;
        userData['user_id'] = obb["vendor_id"];
        let venderInfo = await this.mainService.getVenderName(JSON.stringify(obb));
        if (venderInfo["error"] == false) {
          obb['user_name'] = venderInfo["data"]['party_leagal_name']
        }
      } else {
        for (let i = 0; i < this.ApprovalUserService.workuser.length; i++) {
          if (this.ApprovalUserService.workuser[i]['user_id'] == obb["user_id"]) {
            let h = this.ApprovalUserService.workuser[i]['desc'].split("-");
            obb['user_name'] = h[1];
          }
        }
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'ESTIMATE'
      obb['doc_local_no'] = this.estimate_apprdata['est_id_p']
      obb['doc_desc'] = this.estimate_apprdata['est_desc']
      // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'EMB'
      obb['node_cd'] = this.estimate_apprdata['est_id_p']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.estimate_apprdata['level']
        obb['branch'] = this.estimate_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      obb['field_value'] = 0
      var resp = await this.mainService.insertTask(obb)
      if (resp['error'] == false) {

        //await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        //this.toastr.success("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        this.toastr.error(resp['data'])
      }
    } else {
      this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  // ********************************************re assign start************************************///
  // venderid
  // async getdocumentstatus(element) {
  //   let obj = Object()
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['doc_local_no'] = element['est_id_p']
  //   obj['doc_type'] = 'ESTIMATE'
  //   obj['module_cd'] = 'EMB'
  //   obj['status'] = 'PENDING'
  //   var resp = await this.portalmain.getWorkflowlog(JSON.stringify(obj))
  //   console.log(resp)
  //   if (resp['error'] == false) {

  //     if (resp['data'].length > 0) {
  //       return resp['data'][0]
  //     }
  //   }
  // }
  async updatestatus(id: any) {
    var obb: any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['id'] = id
    obb['update_user_id'] = this.ebillUser.user_id
    obb['status'] = 'UNDERAPPROVAL'
    var resp = await this.estimateService.updateestStatus(obb)
    if (resp['error'] == false) {
      await this.getEstimate()
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }

  workflowuser: any = []
  rolecdtolevelobj: any = {}
  async getWorkflowloguser() {
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'EMB'
    obj['status'] = 'ACTIVE'
    obj['doc_type'] = 'ESTIMATE'
    obj['node_cd'] = this.node_cd
    console.log('kk', obj, this.tender_apprdata)

    var resp = await this.mainService.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log('loa', this.level_for_approval)

      this.workflowuser = resp['data']
      console.log('workflowuser==', this.workflowuser)

      for (let i = 0; i < this.level_for_approval.length; i++) {
        for (let j = 0; j < this.workflowuser.length; j++) {
          let role_Cd = this.level_for_approval[i]['role_cd'].split(",");
          if (role_Cd.includes(this.workflowuser[j]['role_cd'])) {
            if (this.level_for_approval[i]['users'] == undefined) {
              this.level_for_approval[i]['users'] = this.workflowuser[j]['emp_name']
            } else if (this.level_for_approval[i]['all_user_approval_req'] == 1) {
              this.level_for_approval[i]['users'] = this.level_for_approval[i]['users'] + ',' + this.workflowuser[j]['emp_name']
            } else if (this.level_for_approval[i]['all_user_approval_req'] == 0) {
              this.level_for_approval[i]['users'] = this.level_for_approval[i]['users'] + '/' + this.workflowuser[j]['emp_name']
            }
          }
        }
      }

    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }

  statusArr: any = [];
  selectedEle: any;
  node_cd: any;
  async viewStatus(element: any) {
    this.selectedEle = element;
    var obj: any = new Object();
    console.log('nnnnnnn', element)
    obj['doc_local_no'] = element.est_id_p;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = 'ESTIMATE';
    obj['module_cd'] = 'EMB'
    this.node_cd = element.est_id_p

    var resp = await this.mainService.getWorkflowlog(JSON.stringify(obj));
    if (resp['error'] == false) {
      await this.getWorkflowloguser()
      console.log(resp.data)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = ''
          resp.data[i]['role_cd'] = "Contractor"
          resp.data[i]['user_id'] = resp['data'][i]['vendor_id'];
          resp.data[i]['is_vendor'] = true
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            console.log(this.workflowuser[j])
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }

        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }

      this.statusArr = resp.data
      console.log(this.statusArr)

      $('#myModal1').modal('show');
    } else {
      this.toastr.error(resp['data'])
    }
  }

  async submithier(id: any) {
    let level = 0;
    let obb: any = new Object();
    obb["b_acct_id"] = this.b_acct_id;
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      obb["lvl" + this.planing_arrproj[i]["level"] + "_cd"] =
        this.planing_arrproj[i]["hierarchy_type"];
      if (this.planing_arrproj[i]["level"] == 1)
        obb["lvl" + this.planing_arrproj[i]["level"] + "_value"] =
          "All Projects";
      else
        obb["lvl" + this.planing_arrproj[i]["level"] + "_value"] =
          this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]];
      obb["lvl" + this.planing_arrproj[i]["level"] + "_node_type"] =
        this.projhierobjtype[this.planing_arrproj[i]["hierarchy_type"]];
      obb["lvl" + this.planing_arrproj[i]["level"] + "_user_cd"] =
        this.projhierobjtypecd[this.planing_arrproj[i]["hierarchy_type"]];
      console.log(
        this.planing_arrproj[i]["level"],
        obb["lvl" + this.planing_arrproj[i]["level"] + "_user_cd"]
      );
      level = this.planing_arrproj[i]["level"];
    }

    obb["lvl" + (level + 1) + "_user_cd"] = "ESTIMATE" + id;
    obb["lvl" + (level + 1) + "_node_type"] = "WORK";
    obb["lvl" + (level + 1) + "_value"] = this.Obj["work_order_name"];
    obb["lvl" + (level + 1) + "_type"] = "text";
    obb["leaf_user_cd"] = "ESTIMATE" + id;
    obb["leaf_node_type"] = "ESTIMATE";
    obb["leaf_value"] = id;
    obb["module_cd"] = "EMB";

    var resp = await this.embHierService.createProjectHierarchyOnAccount(obb);
    if (resp["error"] == false) {
      this.spinner.hide();
      // let proObj = {};
      // proObj["b_acct_id"] = this.b_acct_id;
      // proObj["id"] = id;
      // proObj["project_cd"] = resp["data"];
      // let project_res = this.masterDataService.updateProjectcode(proObj);
      // if (project_res["error"] == false) {
      //   Swal.fire("Success...", "Successfuly Created", "success");
      // } else {
      //   Swal.fire("Error...", "Error While Project Code Creation..", "error");
      // }
      // await this.getAccountProjectHier();
      // await this.reset();
      Swal.fire("Success...", "Successfuly Created", "success");
    } else {
      this.spinner.hide();
      // Swal.fire("Error...", "Error While Create A New Node..", "error");
    }
  }



  /*--------------------------------------code value S-------------------------------------------*/
  _type_of_bridge: any = [{ code: 'Rulal Bridge', value: 'RULAR' }, { code: 'Urban Bridge', value: 'URBAN' }]
  _type_of_road: any = [{ code: 'Approach Road', value: 'APPR' }, { code: 'Addiational Approch Road', value: 'ADD_APPR' }]
  _type_of_rular_bridge: any = [
    { code: 'Culvert', value: 'CULVERT' },
    { code: 'Box Culvert', value: 'BOX_CULVERT' },
    { code: 'Well Foundation', value: 'WELL_FOUNDATION' }
    //  { code: 'Cause Way', value: 'CAUSE_WAY' }
  ]
  _type_of_urban_bridge: any = [
    { code: 'MDR', value: 'MDR' },
    { code: 'ODR', value: 'ODR' },
    { code: 'State Highway', value: 'STATE_HIGHWAY' },
    { code: 'Well Foundation', value: 'WELL_FOUNDATION' }
  ]
  _technique_for_road: any = [
    { code: 'Old Technique', value: 'OLD' },
    { code: 'New Technique', value: 'NEW' },
  ]
  _types_of_culvert: any = [
    { code: 'CULVERT1', value: 'Culvert with Sub Structure of Brick Masonary' },
    { code: 'CULVERT2', value: 'Culvert with Sub Structure of RCC' },
  ]

  _bride_for: any = []
  _bridge_flag: any = false
  _road_flag: any = false

  _bridge_type_hidding: any = 'Type of Rular'

  rode_type: any
  async roadType() {
    console.log('rpoda', this._road_obj['road_location']);
    if (this._road_obj['road_location']) {
      this._road_flag = true;
      this.rode_type = this._type_of_road.filter((x: any) => {
        if (x.value === this._road_obj['road_location']) {
          return x
        }
      })[0].code
    }

    await this.inputFieldsForRoad()
    console.log('rpfgdfgfdoda', this.rode_type);

  }

  async bridgeType() {
    if (this._bridge_obj['bridge_location'] == 'RULAR') {
      this._bride_for = this._type_of_rular_bridge;
      this._bridge_flag = true;
      this._bridge_obj['bridge_type'] = undefined;
      this._bridge_type_hidding = 'Type of Rular';
    }
    else if (this._bridge_obj['bridge_location'] == 'URBAN') {
      this._bride_for = this._type_of_urban_bridge;
      this._bridge_flag = true;
      this._bridge_obj['bridge_type'] = undefined;
      this._bridge_type_hidding = 'Type of Urban';
    }
    else {
      this._bride_for = [];
      this._bridge_flag = false;
      this._bridge_obj['bridge_type'] = undefined;
      await this.inputFields();
      this._foundation_obj = {}
      this._sub_str_obj = {}
      this._super_str_obj = {}
      this._protection_work_obj = {}
    }
  }
  /*--------------------------------------code value E-------------------------------------------*/

  /*---------------Input fields hide and show according to type of Bridge S-----------------------*/

  _culvert_input_foundation_arr: any = [];
  _culvert_input_sub_str_arr: any = [];
  _culvert_input_super_str_arr: any = [];
  _culvert_input_protection_work_arr: any = [];
  _foundation_obj: any = {};
  _sub_str_obj: any = {};
  _super_str_obj: any = {};
  _protection_work_obj: any = {};
  feilds_flag: any = true;
  _report_main_hedding: any = ''




  _appr_road_input_for_old_arr: any = [];
  _input_approach_road_obj: any = {};
  _report_main_hedding_for_road: any = ''

  async inputFieldsForRoad() {
    this.submitted = false
    console.log('tech t', this._road_obj['road_technique'], 'type', this._road_obj['road_location']);


    if (this._road_obj['road_technique'] == 'OLD' && this._road_obj['road_location'] == 'APPR') {
      this._appr_road_input_for_old_arr = [
        /*--B9,B10--*/ { 0: 'Length_of_one_side_of_Approach_Road', 1: 'Length_of_other_side_of_Approach_Road' },
        /*--B14,B15--*/ { 1: 'Height_of_Embankment_at_Bignning_of_one_side_of_Approach_Road', 2: 'Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road' },
        /*--B17,B24--*/ { 2: 'Height_of_Embankment_at_End_of_one_side_of_Approach_Road', 3: 'Height_of_Embankment_at_End_of_other_side_of_Approach_Road' },
        /*--B33,B43--*/ { 3: 'Height_for_Granual_Sub_Base_Grading_i_G1_in_Approach_Road', 4: 'Height_for_WBM_Grading_i_G2_in_Approach_Road' },
        /*--B44,B71--*/ { 4: 'Height_for_WBM_Grade_ii(G3)_in_Approach_Road', 5: 'No_of_sign_Board' },
        /*--B74,B75--*/ { 5: 'Ordinary_Kilometer_Stone', 6: 'Hecto_Metre_Stone' },
        /*--B78,B79--*/ { 6: 'No_of_center_Line', 7: 'Length_of_center_Line' },
        /*--B80,B81--*/ { 7: 'Wiedth_of_center_Line', 8: 'No_of_Edge_Line' },
        /*--B83--*/    { 8: 'Wiedth_of_Edge_Line', 9: 'extra_item' }
      ]
      this.feilds_flag = false;
    } else if (this._road_obj['road_technique'] == 'NEW' && this._road_obj['road_location'] == 'APPR') {
      this._appr_road_input_for_old_arr = [
        /*--B9,B10--*/ { 0: 'Length_of_one_side_of_Approach_Road', 1: 'Length_of_other_side_of_Approach_Road' },
              /*--B14,B15--*/ { 1: 'Height_of_Embankment_at_Bignning_of_one_side_of_Approach_Road', 2: 'Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road' },
              /*--B17,B24--*/ { 2: 'Height_of_Embankment_at_End_of_one_side_of_Approach_Road', 3: 'Height_of_Embankment_at_End_of_other_side_of_Approach_Road' },
              /*--B31,B41--*/ { 3: 'Height_for_Cement_Treated_Subbase', 4: 'Cement_Treated_base' },
              /*--B71,B74--*/ { 4: 'No_of_sign_Board', 5: 'Ordinary_Kilometer_Stone' },
              /*--B75,B78--*/ { 5: 'Hecto_Metre_Stone', 6: 'No_of_center_Line' },
              /*--B79,B80--*/ { 6: 'Length_of_center_Line', 7: 'Wiedth_of_center_Line' },
              /*--B81,B83--*/ { 7: 'No_of_Edge_Line', 8: 'Wiedth_of_Edge_Line' },
        // /*--Bxx--*/    {8:'Wiedth_of_Edge_Line',9:'extra_item'}
      ]
      this.feilds_flag = false;
    } else if (this._road_obj['road_technique'] == 'OLD' && this._road_obj['road_location'] == 'ADD_APPR') {
      this._appr_road_input_for_old_arr = [
        /*--B8,B9--*/ { 0: 'Length_of_one_side_of_Additional_Approach_Road', 1: 'Length_of_other_side_of_Additional_Approach_Road' },
        /*--B14,B17--*/ { 1: 'Height_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road', 2: 'Height_of_Embankment_at_End_of_one_side_of_Additional_Approach_Road' },
        /*--B24,B33--*/ { 2: 'Height_of_Embankment_at_End_of_other_side_of_Additional_Approach_Road', 3: 'Height_for_Granual_Sub_Base_Grading_i(G1)_in_Additional_Approach_Road' },
        /*--B44,B45--*/ { 3: 'Height_for_WBM_Grading_i_(G2)_in_Additional_Approach_Road', 4: 'Width_for_WBM_Grade_ii(G3)_in_Additional_Approach_Road' },
      ]
      this.feilds_flag = false
    } else if (this._road_obj['road_technique'] == 'NEW' && this._road_obj['road_location'] == 'ADD_APPR') {
      this._appr_road_input_for_old_arr = [
        /*--B9,B10--*/ { 0: 'Length_of_one_side_of_Additional_Approach_Road', 1: 'Length_of_other_side_of_Additional_Approach_Road' },
              /*--B14,B17--*/ { 1: 'Height_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road', 2: 'Height_of_Embankment_at_End_of_one_side_of_Additional_Approach_Road' },
              /*--B24,31--*/ { 2: 'Height_of_Embankment_at_End_of_other_side_of_Additional_Approach_Road', 3: 'Height_for_Cement_Treated_Subbase' },
              /*--B42,--*/ { 3: 'Height_for_Cement_Treated_base', 4: 'extra_item' },
      ]
      this.feilds_flag = false
    } else {
      this._appr_road_input_for_old_arr = [];
      this.feilds_flag = true;
    }

    this._report_main_hedding_for_road = "Input for " + this.rode_type + " Using - " + this._road_obj['road_technique'] + " technique"

  }
  isCulvert: any = false;
  async inputFields() {
    this._foundation_obj = {}
    this._sub_str_obj = {}
    this._super_str_obj = {}
    this._protection_work_obj = {};
    console.log('culvert type', this._bridge_obj['bridge_type'])
    this.submitted = false
    if (this._bridge_obj['bridge_location'] == 'RULAR' && this._bridge_obj['bridge_type'] == 'BOX_CULVERT') {
      this.isCulvert = false;

      this._culvert_input_foundation_arr = this.allBridgeInput.boxCluvert.fill._box_culvert_input_foundation_arr  // input field name for foundation
      this._culvert_input_sub_str_arr = this.allBridgeInput.boxCluvert.fill._box_culvert_input_sub_str_arr; // for sub-structure
      this._culvert_input_super_str_arr = this.allBridgeInput.boxCluvert.fill._box_culvert_input_super_str_arr; //for super-structure
      this._culvert_input_protection_work_arr = this.allBridgeInput.boxCluvert.fill._box_culvert_input_protection_work_arr; // for protection work

      this.feilds_flag = false;
      this._report_main_hedding = 'Input for Rural Road -Box Culvert'

      let text = 'Box Culvert '
      this._report_main_hedding = "Input for " + this._bridge_obj['bridge_location'] + " Road - " + text
    }
    else if (this._bridge_obj['bridge_location'] == 'RULAR' && this._bridge_obj['bridge_type'] == 'CULVERT') {
      this.isCulvert = true;

      this._culvert_input_foundation_arr = this._bridge_obj['culvert_type'] == "CULVERT1" ? this.allBridgeInput.fill._culvert_input_foundation_arr : this.allBridgeInput.fill._culvert2_input_foundation_arr; // input field name for foundation
      this._culvert_input_sub_str_arr = this._bridge_obj['culvert_type'] == "CULVERT1" ? this.allBridgeInput.fill._culvert_input_sub_str_arr : this.allBridgeInput.fill._culvert2_input_sub_str_arr; // for sub-structure

      this._culvert_input_super_str_arr = this._bridge_obj['culvert_type'] == "CULVERT1" ? this.allBridgeInput.fill._culvert_input_super_str_arr : this.allBridgeInput.fill._culvert2_input_super_str_arr; //for super-structure

      this._culvert_input_protection_work_arr = this._bridge_obj['culvert_type'] == "CULVERT1" ? this.allBridgeInput.fill._culvert_input_protection_work_arr : this.allBridgeInput.fill._culvert2_input_protection_work_arr; // for protection work

      this.feilds_flag = this._bridge_obj['culvert_type'] == null ? true : false;
      this._report_main_hedding = 'Input for Rural Road - Culvert'

      let text = this._bridge_obj['culvert_type'] == "CULVERT1" ? 'Culvert with Sub Structure of Brick Masonary' : 'Culvert with Sub Structure of RCC'
      this._report_main_hedding = "Input for " + this._bridge_obj['bridge_location'] + " Road - " + text
    } else {
      this.isCulvert = false;
      this._culvert_input_foundation_arr = [];
      this._culvert_input_sub_str_arr = [];
      this._culvert_input_protection_work_arr = [];
      this.feilds_flag = true;
    }


  }
  async culvertTypeCh() {

  }
  /*---------------Input fields hide and show according to type of Bridge S-----------------------*/

  /*--------------------------------------Input for Bridge S-------------------------------------------*/



  /*--------------Bridge stepper s------------*/
  async bridgeSecondNext(stepper: MatStepper) {
    // console.log(inputsSubmit)
    // if(inputsSubmit.invalid){
    //   return;
    // }
    console.log('akm ==> ', Object.values(this._foundation_obj).length + Object.values(this._sub_str_obj).length + Object.values(this._super_str_obj).length + Object.values(this._protection_work_obj).length)

    if (this._bridge_obj['culvert_type'] == "CULVERT1") {
      if (Object.values(this._foundation_obj).length + Object.values(this._sub_str_obj).length + Object.values(this._super_str_obj).length + Object.values(this._protection_work_obj).length != 25) {
        this.submitted = true
        return;
      }
      this.submitted = false
    }

    if (this._bridge_obj['culvert_type'] == "CULVERT2") {
      if (Object.values(this._foundation_obj).length + Object.values(this._sub_str_obj).length + Object.values(this._super_str_obj).length + Object.values(this._protection_work_obj).length != 32) {
        this.submitted = true
        return;
      }
      this.submitted = false
    }
    if (this._bridge_obj['bridge_type'] == 'BOX_CULVERT') {

      if (Object.values(this._foundation_obj).length + Object.values(this._sub_str_obj).length + Object.values(this._super_str_obj).length + Object.values(this._protection_work_obj).length != 67) {
        this.submitted = true
        return;
      }


      this.submitted = false
    }


    stepper.next()
    await this.inputSetfomula()

    await this.bridgeInput()
  }
  async brigethirdNext(stepper: MatStepper) {
    await this.getBridgeItems()

    this.measurementForRoad = false
    await this.mesurement()
    stepper.next()

  }

  measurementForRoad: any = false
  async roadThirdNext(stepper: MatStepper) {
    await this.getRoadItems()
    this.measurementForRoad = true
    await this.mesurementForRoad()
    stepper.next()
  }

  /*--------------Road stepper s------------*/
  async roadSecondNext(stepper: MatStepper) {
    console.log('road input =df=> ', this._road_obj['road_technique'], this._road_obj['road_location'], 'ss', this._input_approach_road_obj)
    // console.log('road input ==> ' , this._input_approach_road_obj )
    if (
      (this._road_obj['road_technique'] == 'OLD' && this._road_obj['road_location'] == 'APPR' && Object.values(this._input_approach_road_obj).length != 17) ||
      (this._road_obj['road_technique'] == 'NEW' && this._road_obj['road_location'] == 'ADD_APPR' && Object.values(this._input_approach_road_obj).length != 7) ||
      (this._road_obj['road_technique'] == 'OLD' && this._road_obj['road_location'] == 'ADD_APPR' && Object.values(this._input_approach_road_obj).length != 8) ||
      (this._road_obj['road_technique'] == 'NEW' && this._road_obj['road_location'] == 'APPR' && Object.values(this._input_approach_road_obj).length != 16)
    ) {
      this.submitted = true
      return;
    }
    this.submitted = false
    stepper.next()
    await this.inputSetFormulaForRoad()
    await this.roadInput()
  }

  async roadForthNext(stepper: MatStepper) {
    // itemsForRoad
    let item_obj: any = {}
    item_obj['ROAD' + '_' + this._road_obj['road_technique'] + '_' + this._road_obj['road_location']] = this.itemsForRoad.filter((x: any) => {
      return x['input_type'] = 'ROAD' + '_' + this._road_obj['road_technique'] + '_' + this._road_obj['road_location']
    })
    this.mea_item = [...item_obj['ROAD' + '_' + this._road_obj['road_technique'] + '_' + this._road_obj['road_location']]];
    await this.getAnalysisItems()
    stepper.next()
    await this.measurement()
  }





  async bridgeForthNext(stepper: MatStepper) {
    let item_obj: any = {}
    item_obj['foundation'] = this._items_foundation_arr.filter((x: any) => {
      return x['input_type'] = 'foundation'
    })
    item_obj['sub_structure'] = this._items_sub_str_arr.filter((x: any) => {
      return x['input_type'] = 'sub_structure'
    })
    item_obj['super_structure'] = this._items_super_str_arr.filter((x: any) => {
      return x['input_type'] = 'super_structure'
    })
    item_obj['protection_work'] = this._items_protection_work_arr.filter((x: any) => {
      return x['input_type'] = 'protection_work'
    })

    this.mea_item = [...item_obj['foundation'], ...item_obj['sub_structure'], ...item_obj['super_structure'], ...item_obj['protection_work']];

    await this.getAnalysisItems()
    if (this.update_flag) {
      await this.getAllAnalysisOfRate(this.Obj['est_id_p'], this.estimation_type);
      this._ana_items = this.allAnaRates
      let sno: any;
      let ref: any;
      let _last_rank_isfw: any;
      let _last_rank_oc: any;
      let _last_rank_cp: any;
      this._ana_items.map((x: any) => {
        if (x['level_cd'] != null && x['item_desc'] != null && x['quantity'] != null && x['unit'] == null) {
          if (x['item_desc'] != "Contractor's profit" && x['item_desc'] != "Overhead charges") {
            x['isFw'] = 1;
          }
        }
        if (x['item_desc'] == "Contractor's profit") {
          x['item_desc'] = 'Contractors profit'
          x['item_desc2'] = "Contractor's profit"
        } else if (x['item_desc'] == "Overhead charges") {
          // x['item_desc'] = 'Contractors profit'
          x['item_desc2'] = "Overhead charges"
        } else {
          x['item_desc2'] = x['item_desc']
        }

        if (x['item_unit'] != null) {
          // x['rate'] = 0
          x['amount'] = 0
        } else if (x['item_desc'] == 'Overhead charges' || x['item_desc'] == 'Contractors profit') {
          // x['rate'] = 0
          x['amount'] = (x['quantity'] / 100) * x['rate']
        } else {
          // x['rate'] = null
          x['amount'] = null

        }

        if (x['sno'] != null) {
          sno = x['sno']
          ref = x['ref_to_morth']
        }

        if (x['isFw'] == 1 && x['rank'] == undefined) {
          if (!_last_rank_isfw) {
            x['rank'] = 1;
          } else {
            let a = _last_rank_isfw.split('_')
            x['rank'] = Number(a[2]) + 1;
          }
          _last_rank_isfw = sno + '_' + ref + '_' + x['rank']
        }

        if (x['item_desc'] == 'Overhead charges' && x['rank'] == undefined) {
          if (!_last_rank_oc) {
            x['rank'] = 1;
          } else {
            let a = _last_rank_oc.split('_')
            x['rank'] = Number(a[2]) + 1;
          }
          _last_rank_oc = sno + '_' + ref + '_' + x['rank']
        }
        if (x['item_desc'] == 'Contractors profit' && x['rank'] == undefined) {
          if (!_last_rank_cp) {
            x['rank'] = 1;
          } else {
            let a = _last_rank_cp.split('_')
            x['rank'] = Number(a[2]) + 1;
          }
          _last_rank_cp = sno + '_' + ref + '_' + x['rank']
        }
        x['item_id'] = sno + '_' + ref

      })
      3
    }
    stepper.next()
    await this.measurement()

  }
  /*--------------Bridge stepper E------------*/
  _re_culvert_foundation_arr: any = []
  _re_culvert_foundation_obj: any = {}
  _re_culvert_sub_str_arr: any = []
  _re_culvert_sub_str_obj: any = []
  _re_culvert_super_str_arr: any = []
  _re_culvert_super_str_obj: any = []
  _re_culvert_protection_arr: any = []
  _re_culvert_protection_work_obj: any = []

  async inputSetfomula() {


    console.log('==ss>', this._bridge_obj['bridge_type']) //these are the values taken from the user
    console.log(this._sub_str_obj, "2")
    console.log(this._super_str_obj, "3")
    console.log(this._protection_work_obj, "4")

    if (this._bridge_obj['bridge_location'] == 'RULAR' && this._bridge_obj['bridge_type'] == 'BOX_CULVERT') {

      this._re_culvert_foundation_arr = this.allBridgeInput.boxCluvert.fix._re_box_culvert_foundation_arr; // .fix => are the name for the inpur field
      this._re_culvert_sub_str_arr = this.allBridgeInput.boxCluvert.fix._re_box_culvert_sub_str_arr; //for substructre ;
      this._re_culvert_super_str_arr = this.allBridgeInput.boxCluvert.fix._re_box_culvert_super_str_arr; //for super structre ;
      this._re_culvert_protection_arr = this.allBridgeInput.boxCluvert.fix._re_box_culvert_protection_arr; //for   protection work ;

    } else if (this._bridge_obj['bridge_location'] == 'RULAR' && this._bridge_obj['bridge_type'] == 'CULVERT') {
      console.log('!!!!!!!!!', this.allBridgeInput.fix._re_culvert_foundation_arr)
      this._re_culvert_foundation_arr = this._bridge_obj['culvert_type'] == "CULVERT1" ? this.allBridgeInput.fix._re_culvert_foundation_arr : this.allBridgeInput.fix._re_culvert2_foundation_arr; // .fix => are the name for the inpur field
      this._re_culvert_sub_str_arr = this._bridge_obj['culvert_type'] == "CULVERT1" ? this.allBridgeInput.fix._re_culvert_sub_str_arr : this.allBridgeInput.fix._re_culvert2_sub_str_arr; //for substructre ;
      this._re_culvert_super_str_arr = this._bridge_obj['culvert_type'] == "CULVERT1" ? this.allBridgeInput.fix._re_culvert_super_str_arr : this.allBridgeInput.fix._re_culvert2_super_str_arr; // for super structure ;
      this._re_culvert_protection_arr = this._bridge_obj['culvert_type'] == "CULVERT1" ? this.allBridgeInput.fix._re_culvert_protection_arr : this.allBridgeInput.fix._re_culvert2_protection_arr;

    }


    /* setting values for fix and formula ...*/
    console.log('ssssssssssssssssss', this._bridge_obj['bridge_type'], this._bridge_obj['bridge_location'], this._bridge_obj['culvert_type'])


    if (this._bridge_obj['culvert_type'] == "CULVERT1") {
      /*--D6--*/ this._re_culvert_foundation_obj['Overall_Length_of_Span'] = this._foundation_obj['Length_of_Clear_Span'] + (this._sub_str_obj['Width_of_Bed_Block_at_Abutment'] * 2)
      /*--D7--*/ this._re_culvert_foundation_obj['Clear_Road_(_Carriage_)_way'] = 7.5
      /*--D8--*/ this._re_culvert_foundation_obj['Length_of_culvert'] = this._foundation_obj['Number_of_Span'] * this._re_culvert_foundation_obj['Overall_Length_of_Span']
      /*--D9--*/ this._re_culvert_foundation_obj['Width_of_Culvert'] = this._re_culvert_foundation_obj['Clear_Road_(_Carriage_)_way'] + 2 * this._sub_str_obj['Width_of_Parapet_wall']
      /*--D10--*/ this._re_culvert_foundation_obj['No_of_RCC_Raft'] = 1
      //  change  due to taken value from bottom so i do
      // d11  this._re_culvert_foundation_obj['Length_of_RCC_Raft']=   (this._foundation_obj['Number_of_Span']*this._re_culvert_foundation_obj['Overall_Length_of_Span'])+(2* this._foundation_obj['Length_of_Approach_Slab'])-(2*top width of raft cutt of wall of Appron)
      // this._re_culvert_foundation_obj['Length_of_cut_of_wall_off_Appron']= this._re_culvert_foundation_obj['Length of Raft cut off wall long']
      // let temp1 = this._re_culvert_foundation_obj['Length_of_Raft_cut_off_wall_long']
      // this._re_culvert_foundation_obj['Length_of_Raft_cut_off_wall_long']=this._re_culvert_foundation_obj['Top_Width_of_raft_cut_of_wall_long']
      // this._re_culvert_foundation_obj['Top_Width_of_raft_cut_of_wall_long']=this._foundation_obj['Top_width_of_raft_cut_of_wall_short']
      let temp1 = this._foundation_obj['Top_width_of_raft_cut_of_wall_short']
      /*--D11--*/ this._re_culvert_foundation_obj['Length_of_RCC_Raft'] = (this._foundation_obj['Number_of_Span'] * this._re_culvert_foundation_obj['Overall_Length_of_Span']) + (2 * this._foundation_obj['Length_of_Approach_Slab']) - (2 * temp1)
      //  change  due to taken value from bottom so i do
      //  this._re_culvert_foundation_obj['Offset_for_width_of_Raft']=0.5
      // d12  this._re_culvert_foundation_obj['Width_of_RCC_Raft']=  this._re_culvert_foundation_obj['Clear_Road_(_Carriage_)_way'] + (2* this._sub_str_obj['Width_of_Parapet_wall'] + 2 * this._re_culvert_foundation_obj['Offset_for_width_of_Raft'])
      let temp2 = 0.5
      /*--D12--*/ this._re_culvert_foundation_obj['Width_of_RCC_Raft'] = this._re_culvert_foundation_obj['Clear_Road_(_Carriage_)_way'] + (2 * this._sub_str_obj['Width_of_Parapet_wall'] + 2 * temp2)
      /*--D14--*/ this._re_culvert_foundation_obj['No_of_Approach_Slab'] = 2
      /*--D16--*/ this._re_culvert_foundation_obj['Width_of_Approach_Slab'] = this._re_culvert_foundation_obj['Clear_Road_(_Carriage_)_way']
      /*--D17--*/ this._re_culvert_foundation_obj['No_of_Raft_cut_off_wall_long'] = 2
      //  change  due to taken value from bottom so i do
      //  this._re_culvert_foundation_obj['Length of Raft cut off wall long']= this._re_culvert_foundation_obj['Length_of_RCC_Raft']+2*Bottom Width of raft cut of wall long+2*Offset for Lean concrete
      // this._re_culvert_foundation_obj['Bottom_Width_of_raft_cut of wall long']= this._foundation_obj['Bottom_Width_of_raft_cut_of_wall_Short']
      // this._re_culvert_foundation_obj['Offset_for_Lean_concrete']
      let temp3 = 0.15
      /*--D18--*/ this._re_culvert_foundation_obj['Lenght_of_Raft_cut_off_wall_long'] = this._re_culvert_foundation_obj['Length_of_RCC_Raft'] + (2 * this._foundation_obj['Bottom_Width_of_raft_cut_of_wall_Short']) + (2 * temp3)

      /*--D19--*/ this._re_culvert_foundation_obj['Bottom_Width_of_raft_cut_of_wall_long'] = this._foundation_obj['Bottom_Width_of_raft_cut_of_wall_Short']
      /*--D20--*/ this._re_culvert_foundation_obj['Top_Width_of_raft_cut_of_wall_long'] = this._foundation_obj['Top_width_of_raft_cut_of_wall_short']
      /*--D21--*/ this._re_culvert_foundation_obj['Height_of_raft_cut_of_wall_long'] = this._foundation_obj['Height_of_raft_cut_of_wall_Short']
      /*--D22--*/ this._re_culvert_foundation_obj['No_of_Raft_cut_off_wall_Short'] = 2
      /*--D23--*/ this._re_culvert_foundation_obj['Length_of_Raft_cut_off_wall_Short'] = 9.5
      /*--D27--*/ this._re_culvert_foundation_obj['No_of_cut_off_wall_of_Appron'] = 2
      /*--D28--*/ this._re_culvert_foundation_obj['Length_of_cut_off_wall_off_Appron'] = this._re_culvert_foundation_obj['Lenght_of_Raft_cut_off_wall_long']
      /*--D30--*/ this._re_culvert_foundation_obj['Top_Width_of_cut_off_wall_of_Appron'] = this._re_culvert_foundation_obj['Top_Width_of_raft_cut_of_wall_long']
      /*--D35--*/ this._re_culvert_foundation_obj['HYSD_Steel_1.5%_of_Concrete_of_bottom_Slab'] = 0.015
      /*--D36--*/ this._re_culvert_foundation_obj['Density_of_Steel'] = 7.85
      /*--D37--*/ this._re_culvert_foundation_obj['Offset_for_width_of_Raft'] = 0.5
      /*--D38--*/ this._re_culvert_foundation_obj['Offset_for_Lean_concrete'] = 0.15


      /*--D43--*/ this._re_culvert_sub_str_obj['No_of_Abutment'] = 2
      /*--D44--*/ this._re_culvert_sub_str_obj['Length_of_Abutment'] = this._re_culvert_foundation_obj['Width_of_Culvert']
      /*--D49--*/ this._re_culvert_sub_str_obj['No_of_Pier'] = this._foundation_obj['Number_of_Span'] - 1
      /*--D50--*/ this._re_culvert_sub_str_obj['Length_of_Pier'] = this._re_culvert_foundation_obj['Width_of_Culvert']
      // this._re_culvert_sub_str_obj['Height_of_Pier']=this._sub_str_obj['Heigth_of_abutment']-this._re_culvert_sub_str_obj['Depth_of_Bed_Block_at_Abutment']
      // this._re_culvert_sub_str_obj['Depth_of_Bed_Block_at_Abutment']=0.2
      let temp4 = 0.2
      /*--D53--*/ this._re_culvert_sub_str_obj['Height_of_Pier'] = this._sub_str_obj['Height_of_abutment'] - temp4
      /*--D55--*/ this._re_culvert_sub_str_obj['No_of_triangular_Portion_of_Pier'] = this._re_culvert_sub_str_obj['No_of_Pier'] * 2
      /*--D56--*/ this._re_culvert_sub_str_obj['Length_of_triangular_Portion_of_Pier'] = this._sub_str_obj['Bottom_width_of_pier']
      // 'Width of Parapet Wall'=this._sub_str_obj['Width_of_Parapet_wall']
      let temp5 = this._sub_str_obj['Width_of_Parapet_wall']
      /*--D57--*/ this._re_culvert_sub_str_obj['Average_width_of_triangular_portion_of_pier_(_.5/2_)'] = temp5 / 2
      /*--D58--*/ this._re_culvert_sub_str_obj['Height_of_triangular_portion_of_pier'] = this._re_culvert_sub_str_obj['Height_of_Pier']
      /*--D61--*/ this._re_culvert_sub_str_obj['No_of_Bed_Block_at_Abutment'] = 2;
      /*--D62--*/ this._re_culvert_sub_str_obj['Length_of_Bed_Block_at_Abutment'] = this._re_culvert_foundation_obj['Width_of_Culvert']
      /*--D64--*/ this._re_culvert_sub_str_obj['Depth_of_Bed_Block_at_Abutment'] = 0.2
      /*--D66--*/ this._re_culvert_sub_str_obj['No_of_Bed_Block_At_Pier'] = this._re_culvert_sub_str_obj['No_of_Pier']
      /*--D67--*/ this._re_culvert_sub_str_obj['Length_of_Bed_Block_At_Pier'] = this._re_culvert_sub_str_obj['Length_of_Pier']
      /*--D68--*/ this._re_culvert_sub_str_obj['width_of_Bed_Block_At_Pier'] = this._sub_str_obj['Top_width_of_pier']
      /*--D69--*/ this._re_culvert_sub_str_obj['Depth_of_Bed_Block_At_Pier'] = 0.2
      /*--D71--*/ this._re_culvert_sub_str_obj['No_of_Wing_Wall'] = 4
      /*--D72--*/ this._re_culvert_sub_str_obj['Bottom_Length_of_Wing_Wall'] = this._foundation_obj['Length_of_Approach_Slab'] - (this._sub_str_obj['Bottom_width_of_abutment'] - this._sub_str_obj['Top_width_of_pier'])
      /*--D73--*/ this._re_culvert_sub_str_obj['Top_Length_of_Wing_Wall'] = this._foundation_obj['Length_of_Approach_Slab'] - this._sub_str_obj['Support_at_abutment_of_Approach_Slab']
      /*--D74--*/ this._re_culvert_sub_str_obj['width_of_Wing_Wall'] = 0.5
      // this._re_culvert_super_str_obj['Depth_of_Approach_Slab']=0.3
      let temp6 = 0.3
      /*--D75--*/ this._re_culvert_sub_str_obj['Depth_of_Wing_Wall'] = this._sub_str_obj['Height_of_abutment'] + this._super_str_obj['Depth_of_Deck_Slab'] - temp6
      /*--D77--*/ this._re_culvert_sub_str_obj['No_of_Parapet_Wall'] = 2
      /*--D78--*/ this._re_culvert_sub_str_obj['Length_of_Parapet_Wall'] = this._re_culvert_foundation_obj['Length_of_culvert'] + 2 * this._foundation_obj['Length_of_Approach_Slab']
      /*--D82--*/ this._re_culvert_sub_str_obj['No_of_Weep_Hole_at_Abutment'] = this._re_culvert_sub_str_obj['No_of_Abutment'] * 5
      /*--D83--*/ this._re_culvert_sub_str_obj['No_of_Weep_Hole_at_Pier'] = this._re_culvert_sub_str_obj['No_of_Pier'] * 10
      /*--D84--*/ this._re_culvert_sub_str_obj['No_of_Weep_Hole_at_wing_Wall'] = this._re_culvert_sub_str_obj['No_of_Wing_Wall'] * 5

      /*--D90--*/ this._re_culvert_super_str_obj['No_of_Deck_Slab'] = this._foundation_obj['Number_of_Span']
      /*--D91--*/ this._re_culvert_super_str_obj['Length_of_Deck_Slab'] = this._re_culvert_foundation_obj['Overall_Length_of_Span']
      /*--D92--*/ this._re_culvert_super_str_obj['Width_of_Deck_Slab'] = this._re_culvert_foundation_obj['Width_of_Culvert']
      /*--D95--*/ this._re_culvert_super_str_obj['No_of_Approach_Slab'] = 2
      /*--D96--*/ this._re_culvert_super_str_obj['Length_of_Approach_Slab'] = this._foundation_obj['Length_of_Approach_Slab']
      /*--D97--*/ this._re_culvert_super_str_obj['Width_of_Approach_Slab'] = this._re_culvert_foundation_obj['Clear_Road_(_Carriage_)_way']
      /*--D98--*/ this._re_culvert_super_str_obj['Depth_of_Approach_Slab'] = 0.3
      /*--D100--*/ this._re_culvert_super_str_obj['No_of_Wearing_Coat'] = 1
      /*--D101--*/ this._re_culvert_super_str_obj['Length_of_Wearing_Coat'] = this._re_culvert_foundation_obj['Length_of_culvert'] + (this._foundation_obj['Length_of_Approach_Slab'] * 2)
      /*--D102--*/ this._re_culvert_super_str_obj['width_of_Wearing_Coat'] = this._re_culvert_foundation_obj['Clear_Road_(_Carriage_)_way']
      /*--D103--*/ this._re_culvert_super_str_obj['Thickness_of_Wearing_Coat'] = 0.075
      /*--D105--*/ this._re_culvert_super_str_obj['No_of_Expansion_Joint'] = this._foundation_obj['Number_of_Span'] + 1
      /*--D106--*/ this._re_culvert_super_str_obj['Length_of_Expansion_Joint'] = this._re_culvert_foundation_obj['Clear_Road_(_Carriage_)_way']
      /*--D107--*/ this._re_culvert_super_str_obj['No_of_Drainage_spout'] = 2 * this._foundation_obj['Number_of_Span']

      /*--D111--*/ this._re_culvert_protection_work_obj['No_of_Launching_Appron'] = 2
      /*--D112--*/ this._re_culvert_protection_work_obj['Length_of_Launching_Appron'] = this._re_culvert_foundation_obj['Lenght_of_Raft_cut_off_wall_long']
      /*--D118--*/ this._re_culvert_protection_work_obj['No_of_Boulder_Pitching_on_Slopes'] = this._re_culvert_sub_str_obj['No_of_Wing_Wall']
      // /*--D119--*/ this._re_culvert_protection_work_obj['Length_of_Boulder_Pitching_on_Slopes'] = Math.sqrt(((this._sub_str_obj['Height_of_abutment'] + this._super_str_obj['Depth_of_Deck_Slab']) * (this._sub_str_obj['Height_of_abutment'] + this._super_str_obj['Depth_of_Deck_Slab'])) + ((this._super_str_obj['Length of Approach Slab'] + this._sub_str_obj['width of Bed Block at Abutment']) * (this._super_str_obj['Length of Approach Slab'] + this._sub_str_obj['width of Bed Block at Abutment'])))
      /*--D119--*/
      //  console.log('3210','root((D47+D93)^2 + (D96 +D63)^2)',this._sub_str_obj['Height_of_abutment'] , this._super_str_obj['Depth_of_Deck_Slab'],this._sub_str_obj['Height_of_abutment'],this._super_str_obj['Depth_of_Deck_Slab'], this._sub_str_obj['Width_of_Bed_Block_at_Abutment'],this._re_culvert_super_str_obj['Length_of_Approach_Slab'])
      this._re_culvert_protection_work_obj['Length_of_Boulder_Pitching_on_Slopes'] = Math.sqrt(
        (
          (this._sub_str_obj['Height_of_abutment'] + this._super_str_obj['Depth_of_Deck_Slab']) *
          (this._sub_str_obj['Height_of_abutment'] + this._super_str_obj['Depth_of_Deck_Slab'])
        ) +
        (
          (this._re_culvert_super_str_obj['Length_of_Approach_Slab'] + this._sub_str_obj['Width_of_Bed_Block_at_Abutment']) *
          (this._re_culvert_super_str_obj['Length_of_Approach_Slab'] + this._sub_str_obj['Width_of_Bed_Block_at_Abutment']))

      )

      /*--D120--*/ this._re_culvert_protection_work_obj['Width_of_Boulder_Pitching_on_Slopes'] = this._re_culvert_super_str_obj['Length_of_Approach_Slab'] + this._sub_str_obj['Width_of_Bed_Block_at_Abutment']
      /*--D121--*/ this._re_culvert_protection_work_obj['Thickness_of_Boulder_Pitching_on_Slop'] = 0.3
      /*--D124--*/ this._re_culvert_protection_work_obj['No_of_Filter_Below_Pitching_on_Slpoe'] = this._re_culvert_sub_str_obj['No_of_Wing_Wall']
      /*--D125--*/ this._re_culvert_protection_work_obj['Length_of_Filter_Below_Pitching_on_Slpoe'] = this._re_culvert_protection_work_obj['Length_of_Boulder_Pitching_on_Slopes']
      /*--D126--*/ this._re_culvert_protection_work_obj['Width_of_Filter_Below_Pitching_on_Slpoe'] = this._re_culvert_protection_work_obj['Width_of_Boulder_Pitching_on_Slopes']
      /*--D127--*/ this._re_culvert_protection_work_obj['Thickness_of_Filter_Below_Pitching_on_Slpoe'] = 0.15
      /*--D130--*/ this._re_culvert_protection_work_obj['No_of_Filter_Media_Behind_Abutment'] = this._re_culvert_sub_str_obj['No_of_Bed_Block_at_Abutment']
      /*--D131--*/ this._re_culvert_protection_work_obj['Length_of_Filter_Media_Behind_Abutment'] = this._re_culvert_sub_str_obj['Length_of_Bed_Block_at_Abutment']
      /*--D132--*/ this._re_culvert_protection_work_obj['Width_of_Filter_Media_Behind_Abutment'] = 0.6
      /*--D133--*/ this._re_culvert_protection_work_obj['Height_of_Filter_Media_Behind_Abutment'] = this._sub_str_obj['Height_of_abutment']
    } else if (this._bridge_obj['culvert_type'] == "CULVERT2") {

                                     /* For Foundation == this._re_culvert_foundation_obj  */
      /* D5 = D7 + 1 */ this._re_culvert_foundation_obj['No_of_Foundation'] = this._foundation_obj['Number_of_Span'] + 1;
      let d5 = this._re_culvert_foundation_obj['No_of_Foundation']
      /* D8 = D6 + D45 */ this._re_culvert_foundation_obj['Overall_Length_of_Span'] = this._foundation_obj['Length_of_clear_Span'] + this._sub_str_obj['Width_of_Abutment']
      let d8 = this._re_culvert_foundation_obj['Overall_Length_of_Span'];
      /* d9 */ this._re_culvert_foundation_obj['Clear_Road_(Carriage)_way'] = 7.4
      /* d10 = (D7*D8) + (2* 0.5* D45) */ this._re_culvert_foundation_obj['Length_of_Culvert'] = (this._foundation_obj['Number_of_Span'] * d8) + (2 * 0.5 * this._sub_str_obj['Width_of_Abutment'])
      let d9 = this._re_culvert_foundation_obj['Clear_Road_(Carriage)_way']
      let d10 = this._re_culvert_foundation_obj['Length_of_Culvert']
      /*d11 = D9+2*D67 */ this._re_culvert_foundation_obj['Width_of_Culvert'] = d9 + 2 * this._sub_str_obj['Width_Of_Parapet_Wall'];
      let d11 = this._re_culvert_foundation_obj['Width_of_Culvert']
      /*d12 */ this._re_culvert_foundation_obj['No_of_Bottom_Slab'] = 1;
      /*d13 */ this._re_culvert_foundation_obj['Length_of_Bottom_Slab'] = d10
       /*d14 */ this._re_culvert_foundation_obj['Width_of_Bottom_Slab'] = d11;
       /*d16 */ this._re_culvert_foundation_obj['No_of_cut_off_wall_long_for_Bottom_Slab'] = 2;
       /*d17 */ this._re_culvert_foundation_obj['Length_of_cut_off_wall_long_for_Bottom_Slab'] = d10;
      /*d20 = D15+D32+D34+D35  */ this._re_culvert_foundation_obj['Height_of_cut_off_wall_long_for_Bottom_Slab'] = this._foundation_obj['Height_of_Bottom_Slab'] + this._foundation_obj['Thickness_of_Brick_Masonary_work_for_Bottom_Slab'] + this._foundation_obj['Height_of_Sand_filling_above_Brick_Masonary_work_for_Bottom_Slab'] + this._foundation_obj['Thickness_of_Lean_Concrete_above_sand_filling_for_Bottom_Slab'];
      let d20 = this._re_culvert_foundation_obj['Height_of_cut_off_wall_long_for_Bottom_Slab']
      /*d21 = 2  */ this._re_culvert_foundation_obj['No_of_cut_off_wall_short_for_Bottom_Slab'] = 2;
      /*d22  = D11+2*D18  */ this._re_culvert_foundation_obj['Length_of_cut_off_wall_short_for_Bottom_Slab'] = d11 + (2 * this._foundation_obj['Bottom_Width_of_cut_of_wall_long_for_Bottom_Slab']);
       /*d23 = d18   */ this._re_culvert_foundation_obj['Bottom_Width_of_cut_of_wall_short_for_Bottom_Slab'] = this._foundation_obj['Bottom_Width_of_cut_of_wall_long_for_Bottom_Slab']
      /*d24 = d19 */ this._re_culvert_foundation_obj['Top_Width_of_cut_off_wall_short_for_Bottom_Slab'] = this._foundation_obj['Top_Width_of_cut_off_wall_long_for_Bottom_Slab']
      /*d25 = D20 */ this._re_culvert_foundation_obj['Height_of_cut_off_wall_short_for_Bottom_Slab'] = d20;
      /*d27 = 2 */ this._re_culvert_foundation_obj['No_of_Brick_Masonry_Work_Below_Bottom_Slab'] = 2
      /*d28 = d10 */ this._re_culvert_foundation_obj['Length_of_Brick_Masonry_Work_Below_Bottom_Slab'] = d10;
      /*d29 = d11 */ this._re_culvert_foundation_obj['Width_of_Brick_Masonry_Work_Below_Bottom_Slab'] = d11
      /*d36 = 0.3 */ this._re_culvert_foundation_obj['Depth_of_top_of_raft_below_ground_level'] = 0.3
      /*d37 = 0.015 */ this._re_culvert_foundation_obj['HYSD_Steel_1.5%_of_Concrete_of_bottom_Slab'] = 0.015
      let d37 = this._re_culvert_foundation_obj['HYSD_Steel_1.5%_of_Concrete_of_bottom_Slab']
      /*d38 =7.85  */ this._re_culvert_foundation_obj['Density_of_Steel'] = 7.85
      let d38 = 7.85;


                                 /* for substructre ==> this._re_culvert_sub_str_obj */


      /*d43 = 2  */ this._re_culvert_sub_str_obj['No_of_Abutment'] = 2
      let d43 = this._re_culvert_sub_str_obj['No_of_Abutment']

      /*d44 = D9+2*D45  */ this._re_culvert_sub_str_obj['Length_of_Abutment'] = d9 + (2 * this._sub_str_obj['Width_of_Abutment'])
      let d44 = this._re_culvert_sub_str_obj['Length_of_Abutment']

      /*d48 = D5-D43  */ this._re_culvert_sub_str_obj['No_of_Pier'] = d5 - 2

      /*d50 = d45  */ this._re_culvert_sub_str_obj['Width_of_Pier'] = this._sub_str_obj['Width_of_Abutment']
      let d50 = this._re_culvert_sub_str_obj['Width_of_Pier']

      /*d49 = D9+2*D50  */ this._re_culvert_sub_str_obj['Length_of_Pier'] = d9 + (2 * d50)

      /*d51  = d46  */ this._re_culvert_sub_str_obj['Height_of_Pier'] = this._sub_str_obj['Height_of_Abutment']

      /*d53  = 2  */ this._re_culvert_sub_str_obj['No_of_Back_Wall'] = 2
      let d53 = this._re_culvert_sub_str_obj['No_of_Back_Wall']

      /*d54  =D44 */ this._re_culvert_sub_str_obj['Length_of_Back_Wall'] = d44

      /*d56 = D46+D91-D101*/ this._re_culvert_sub_str_obj['Height_of_Back_Wall'] = this._sub_str_obj['Height_of_Abutment'] + this._super_str_obj['Depth_Of_RCC_M-25_Deck_Slab'] - 0.3
      let d56 = this._re_culvert_sub_str_obj['Height_of_Back_Wall']

      /*d59  = 4 */ this._re_culvert_sub_str_obj['No_of_Wing_Wall_(RCC-M25)'] = 4
      let d59 = this._re_culvert_sub_str_obj['No_of_Wing_Wall_(RCC-M25)']

      /*d60  = =D99-D55 */ this._re_culvert_sub_str_obj['Length_of_Wing_Wall_(RCC-M25)'] = this._super_str_obj['Length_Of_RCC_M-25_Approach_Slab'] - this._sub_str_obj['Width_Of_Back_Wall']
      let d60 = this._re_culvert_sub_str_obj['Length_of_Wing_Wall_(RCC-M25)']

      /*d62  = D46+D91 */ this._re_culvert_sub_str_obj['Depth_of_Wing_Wall_(RCC-M25)'] = this._sub_str_obj['Height_of_Abutment'] + this._super_str_obj['Depth_Of_RCC_M-25_Deck_Slab'];
      let d62 = this._re_culvert_sub_str_obj['Depth_of_Wing_Wall_(RCC-M25)']

      /*d63 =  0.5*D62*D60*/ this._re_culvert_sub_str_obj['Cross_Sectional_Area_of_Wing_Wall_(RCC-M25)'] = 0.5 * d62 + d60;
      /*d65 =  2 */ this._re_culvert_sub_str_obj['No_Of_Parapet_Wall_(RCC-M25)'] = 2

      /*d66 =  D10+2*D99 */ this._re_culvert_sub_str_obj['Length_Of_Parapet_Wall_(RCC-M25)'] = d10 + (2 * this._super_str_obj['Length_Of_RCC_M-25_Approach_Slab']);
      /*d71 =  D5*4-4*/ this._re_culvert_sub_str_obj['No_of_Abutment/Pier'] = d5 * 4 - 4

      /*d72 =  D44*/ this._re_culvert_sub_str_obj['Length_of_Abutment/Pier'] = d44;
      /*d75 =2*D53*/ this._re_culvert_sub_str_obj['No_of_Back/Wing_wall'] = 2 * d53;

      /*d76 = d56*/ this._re_culvert_sub_str_obj['Hieght_of_Back/Wing_wall'] = d56
      /*d77 = d73 */ this._re_culvert_sub_str_obj['Cross_Sectional_Area_of_Back/Wing_wall'] = this._sub_str_obj['Cross_Sectional_Area_of_Abutment/Pier']

      /*d79=D37*/ this._re_culvert_sub_str_obj['1.5%_of_Concrete_of_HYSD_Steel_in_Sub_Structure'] = d37
      /*d80 = D38 */ this._re_culvert_sub_str_obj['Density_of_Steel_of_HYSD_Steel_in_Sub_Structure'] = d38

      /*d82 =(D5*10)+(D59*5)   */ this._re_culvert_sub_str_obj['No_Of_Weep_Hole'] = (d5 * 10) + (d59 * 5)



                                  /* for super-structre ==> this._re_culvert_super_str_obj */
      /*d87 = d7  */ this._re_culvert_super_str_obj['No_Of_RCC_M-25_Deck_Slab'] = this._foundation_obj['Number_of_Span'];
      /*d88 = d8  */ this._re_culvert_super_str_obj['Length_of_span'] = d8
      /*d89 = d10  */ this._re_culvert_super_str_obj['Overall_Length_of_Deck'] = d10
      /*d90 = d11  */ this._re_culvert_super_str_obj['Width'] = d11

      /*d93 = 2 */ this._re_culvert_super_str_obj['No'] = 2
      /*d94 = D99-D55+D33 */ this._re_culvert_super_str_obj['Length'] = this._super_str_obj['Length_Of_RCC_M-25_Approach_Slab'] - this._sub_str_obj['Width_Of_Back_Wall'] + this._foundation_obj['Offset_length/_Lean_Concrete']
      /*d95 = D100 */ this._re_culvert_super_str_obj['Width'] = d9
      /*d96 = D35 */ this._re_culvert_super_str_obj['Thickness'] = this._foundation_obj['Thickness_of_Lean_Concrete_above_sand_filling_for_Bottom_Slab'];

      /*d98 = 2 */ this._re_culvert_super_str_obj['No_Of_Approach_Slab'] = 2
      /*d100 = D9 */ this._re_culvert_super_str_obj['Width_Of_Approach_Slab'] = d9
      /*d101 = 0.3 */ this._re_culvert_super_str_obj['Depth_Of_Approach_Slab'] = 0.3

      /*d103 = 1 */ this._re_culvert_super_str_obj['No_Of_Wearing_Coat_including_reinforcement'] = 1
      /*d104 =D10+D99*2 */ this._re_culvert_super_str_obj['Length_Of_Wearing_Coat_including_reinforcement'] = d10 + (this._super_str_obj['Length_Of_RCC_M-25_Approach_Slab'] * 2);
      /*d105 = d9 */ this._re_culvert_super_str_obj['Width_Of_Wearing_Coat_including_reinforcement'] = d9

      /*d108 = d5 */ this._re_culvert_super_str_obj['No_Of_Expansion_Joint'] = d5
      /*d109 = d9 */ this._re_culvert_super_str_obj['Length_Of_Expansion_Joint'] = d9
      /*d110 =2*D7 */ this._re_culvert_super_str_obj['No_of_Drainage_Spout'] = 2 * this._foundation_obj['Number_of_Span']





                                  /* for protection work ==> this._re_culvert_protection_work_obj */


      /*d115 = 1 */ this._re_culvert_protection_work_obj['No_of_Upstream_Side_Appron'] = 1
      /*d116 =D10+2*D99 */ this._re_culvert_protection_work_obj['length_of_Upstream_Side_Appron'] = d10 + (2 * this._super_str_obj['Length_Of_RCC_M-25_Approach_Slab'])
      let d116 = this._re_culvert_protection_work_obj['length_of_Upstream_Side_Appron']

      /*d120 = 1 */ this._re_culvert_protection_work_obj['No_of_Downstream_Side_Appron'] = 1
      /*d121 = D116 */ this._re_culvert_protection_work_obj['Length_of_Downstream_Side_Appron'] = d116

      /*d125 = 4 */ this._re_culvert_protection_work_obj['No_of_Boulder_Pitching_on_Slopes'] = 4
      let d125 = this._re_culvert_protection_work_obj['No_of_Boulder_Pitching_on_Slopes']

      /*d131 = =D125 */ this._re_culvert_protection_work_obj['No_of_Filter_Below_Pitching_on_Slope'] = d125
      /*d132 = D126 */ this._re_culvert_protection_work_obj['length_of_Filter_Below_Pitching_on_Slope'] = this._protection_work_obj['Length_of_Boulder_Pitching_on_Slopes']
      /*d133 = D127 */ this._re_culvert_protection_work_obj['Width_of_Filter_Below_Pitching_on_Slope'] = this._protection_work_obj['Width_of_Boulder_Pitching_on_Slopes']

      /*d137 = D43 */ this._re_culvert_protection_work_obj['No_of_Filter_Media_Behind_Abutment'] = d43
      /*d138 = D44-2*D61 */ this._re_culvert_protection_work_obj['Length_of_Filter_Media_Behind_Abutment'] = d44 - (2 * this._sub_str_obj['Width_Of_Wing_Wall'])
      /*d140 = D46 */ this._re_culvert_protection_work_obj['height_of_Filter_Media_Behind_Abutment'] = this._sub_str_obj['Height_of_Abutment']

      /*d143= 2 */ this._re_culvert_protection_work_obj['No_of_Boulder_Appron_in_Toe_Wall'] = 2
      /*d144= D11+D118+D123 */ this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_in_Toe_Wall'] = d11 + this._protection_work_obj['Width_of_Upstream_Side_Appron'] + this._protection_work_obj['Width_of_Downstream_Side_Appron']

    } else if (this._bridge_obj['bridge_type'] == 'BOX_CULVERT') {


      // FOR FOUNDATION ______-------

      /*--B14--*/ this._re_culvert_foundation_obj['No_of_Box_Floor_Slab'] = 1 //fix
      /*--B15--*/ this._re_culvert_foundation_obj['Length_of_Box_Floor_Slab'] = this._foundation_obj['Length_of_RCC_Box_Culvert'] + (this._foundation_obj['Offset_for_length_of_Box_Floor_Slab'] * 2) // B8 + (b11 * 2)
      /*--B16--*/ this._re_culvert_foundation_obj['Width_of_Box_Floor_Slab'] = this._foundation_obj['Width_of_RCC_Box_Culvert']  //b9
      /*--B21--*/ this._re_culvert_foundation_obj['Length_of_2nd_step_of_wing_wall'] = this._foundation_obj['Length_of_1st_step_of_wing_wall'] //b18
      /*--B24--*/ this._re_culvert_foundation_obj['Length_of_3rd_step_of_wing_wall'] = this._foundation_obj['Length_of_1st_step_of_wing_wall'] //b18
      /*--B27--*/ this._re_culvert_foundation_obj['No_of_Curtain_Wall_type1/type2'] = 1
      /*--B31--*/ this._re_culvert_foundation_obj['Length_of_2nd_step_of_Curtain_Wall_type_1'] = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1'] //b28
      /*--B34--*/ this._re_culvert_foundation_obj['Length_of_3rd_step_of_Curtain_Wall_type_1'] = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1'] //b28
      /*--B37--*/ this._re_culvert_foundation_obj['Length_of_4th_step_of_Curtain_Wall_type_1'] = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1'] //b28
      /*--B40--*/ this._re_culvert_foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_2'] = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1'] //b28
      /*--B43--*/ this._re_culvert_foundation_obj['Length_of_2nd_step_of_Curtain_Wall_type_2'] = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1'] //b28
      /*--B46--*/ this._re_culvert_foundation_obj['Length_of_3rd_step_of_Curtain_Wall_type_2'] = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1'] //b28
      /*--B50--*/ this._re_culvert_foundation_obj['Inner_Length_of_Floor_Appron'] = this._foundation_obj['Length_of_RCC_Box_Culvert']  //b8
      /*--B54--*/ this._re_culvert_foundation_obj['Length_of_Upstream_Flexible_Appron'] = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1'] //b28
      /*--B57--*/ this._re_culvert_foundation_obj['Length_of_Downstream_Flexible_Appron'] = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1'] //b28
      /*--B59--*/ this._re_culvert_foundation_obj['Height_of_Downstream_Flexible_Appron'] = this._foundation_obj['Height_of_Upstream_Flexible_Appron']  //b56
      /*--B60--*/ this._re_culvert_foundation_obj['Norm_of_HYSD_Steel_in_Foundation'] = 4 //b90 = b89 = 4
      /*--B61--*/ this._re_culvert_foundation_obj['Density_of_Steel'] = 7.85
      /*--B62--*/ this._re_culvert_foundation_obj['No_of_Sand_Filling_Behaind_Wing_Wall'] = 4 //b90 = b89 = 4
      /*--B63--*/ this._re_culvert_foundation_obj['Length_of_Sand_Filing_Behaind_Wing_Wall'] = this._foundation_obj['Length_of_1st_step_of_wing_wall'] //b18   //b18
      /*--B66--*/ this._re_culvert_foundation_obj['No_of_Sand_Filling_Behaind_Outer_Partition_Wall'] = 2 //b75 = 2
      /*--B67--*/ this._re_culvert_foundation_obj['Length_of_Sand_Filing_Behaind_Outer_Partition_Wall'] = this._foundation_obj['Width_of_RCC_Box_Culvert']    //b76 = b9
      /*--B69--*/ this._re_culvert_foundation_obj['Depth_of_Sand_Filing_Behaind_Outer_Partition_Wall'] = this._foundation_obj['Depth_of_Sand_Filing_Behaind_Wing_Wall']  //b65



      // FOR SUB STRUCTURE <|----- |>
      /*--B75--*/ this._re_culvert_sub_str_obj['No_of_Outer_Partition_Wall'] = 2 //fix
      /*--B76--*/ this._re_culvert_sub_str_obj['Length_of_Outer_Partition_Wall'] = this._foundation_obj['Width_of_RCC_Box_Culvert']  //B9
      /*--B79--*/ this._re_culvert_sub_str_obj['No_of_Inner_Partition_Wall'] = this._foundation_obj['No_of_RCC_Box_Cell'] - 1 // b5-1
      let b79 = this._re_culvert_sub_str_obj['No_of_Inner_Partition_Wall']
      /*--B80--*/ this._re_culvert_sub_str_obj['Length_of_Inner_Partition_Wall'] = this._foundation_obj['Width_of_RCC_Box_Culvert']    //b76 = b9
      /*--B82--*/ this._re_culvert_sub_str_obj['Height_of_Inner_Partition_Wall'] = this._sub_str_obj['Height_of_Outer_Partition_Wall'] //b78
      /*--B83--*/ this._re_culvert_sub_str_obj['No_of_Haunch_in_Inner_and_outer_partion_wall'] = (this._foundation_obj['No_of_RCC_Box_Cell'] * 4)  //b5 * 4
      /*--B85--*/ this._re_culvert_sub_str_obj['Height_of_Haunch_of_Inner/outer_partion_wall'] = this._foundation_obj['Height_of_RCC_Box_Cell'] //b7
      /*--B86--*/ this._re_culvert_sub_str_obj['No_of_Bracket_for_Approach_Slab'] = 2
      /*--B87--*/ this._re_culvert_sub_str_obj['Length_of_Bracket_for_Approach_Slab'] = this._foundation_obj['Width_of_RCC_Box_Culvert']   //b9
      /*--B89--*/ this._re_culvert_sub_str_obj['No_of_Wing_Wall'] = 4
      let b89 = this._re_culvert_sub_str_obj['No_of_Wing_Wall']
      /*--B90--*/ this._re_culvert_sub_str_obj['No_of_1st_step_of_Wing_Wall_above_bed_level'] = b89 // b89 = 4 (fix)
      /*--B94--*/ this._re_culvert_sub_str_obj['No_of_2nd_step_of_Wing_Wall_above_bed_level'] = b89 // b89 = 4 (fix)
      /*--B95--*/ this._re_culvert_sub_str_obj['Length_of_2nd_step_of_Wing_Wall_above_bed_level'] = this._sub_str_obj['Length_of_1st_step_of_Wing_Wall_above_bed_level'] //b91
      /*--B98--*/ this._re_culvert_sub_str_obj['No_of_3rd_step_of_Wing_Wall_above_bed_level'] = b89 //b94 = b89
      /*--B99--*/ this._re_culvert_sub_str_obj['Length_of_d_3rd_tep_of_Wing_Wall_above_bed_level'] = this._sub_str_obj['Length_of_1st_step_of_Wing_Wall_above_bed_level']  //b95
      /*--B102--*/ this._re_culvert_sub_str_obj['No_of_Weep_Hole_in_outer_Partion_Wall'] = this._re_culvert_sub_str_obj['No_of_Outer_Partition_Wall'] * 10 //b75 * 10
      /*--B103--*/ this._re_culvert_sub_str_obj['No_of_Weep_Hole_in_Inner_Partion_Wall'] = b79 * 10 //b79 * 10
      /*--B104--*/ this._re_culvert_sub_str_obj['No_of_Weep_Hole_in_Wing_Wall'] = b89 * 5 //b90 * 5 (b90 = b89)
      /*--B105--*/ this._re_culvert_sub_str_obj['Norm_of_HYSD_Steel_in_Sub_Structure'] = 0.015
      /*--B106--*/ this._re_culvert_sub_str_obj['Density_of_HYSD_steel'] = 7.85

      // FOR SUPER STRUCTURE <|----- |>
      /*--B110--*/this._re_culvert_super_str_obj['No_of_box_top_slab'] = 1
      /*--B111--*/this._re_culvert_super_str_obj['Length_of_box_top_slab'] = this._foundation_obj['Length_of_RCC_Box_Culvert']   //b8
      /*--B112--*/ this._re_culvert_super_str_obj['Width_of_box_top_Slab'] = this._foundation_obj['Width_of_RCC_Box_Culvert']  //b9
      /*--B114--*/ this._re_culvert_super_str_obj['No_of_RCC_Approach_Slab'] = 2
      let b114 = this._re_culvert_super_str_obj['No_of_RCC_Approach_Slab']
      /*--B116--*/ this._re_culvert_super_str_obj['Width_of_RCC_Approach_Slab'] = this._foundation_obj['Width_of_RCC_Box_Culvert']  //b9
      /*--B117--*/ this._re_culvert_super_str_obj['Height_of_RCC_Approach_Slab'] = 0.3
      /*--B118--*/ this._re_culvert_super_str_obj['No_of_PCC_M_15_Levelling_Course_below_Approach_Slab'] = b114 //b114
      /*--B119--*/ this._re_culvert_super_str_obj['Length_of_PCC_M_15_Levelling_Course_below_Approach_Slab'] = this._super_str_obj['Length_of_RCC_Approach_Slab'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)  //b115 + b12 * 2
      /*--B120--*/ this._re_culvert_super_str_obj['Width_of_PCC_M_15_Levelling_Course_below_Approach_Slab'] = this._foundation_obj['Width_of_RCC_Box_Culvert'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2) // b9 + (b12*2)
      /*--B121--*/ this._re_culvert_super_str_obj['Height_of_PCC_M_15_Levelling_Course_below_Approach_Slab'] = this._foundation_obj['Depth_of_PCC_15']  //b13
      /*--B122--*/ this._re_culvert_super_str_obj['No_of_Road_Kerb'] = 2
      /*--B123--*/ this._re_culvert_super_str_obj['Length_of_Road_Kerb'] = this._foundation_obj['Length_of_RCC_Box_Culvert'] + (this._super_str_obj['Length_of_RCC_Approach_Slab'] * 2) // b8 + (b115 * 2 )
      let b123 = this._re_culvert_super_str_obj['Length_of_Road_Kerb']
      /*--B127--*/ this._re_culvert_super_str_obj['Length_of_Pre_Cast_RCC_Railing'] = b123 * 2 //b123 * 2
      /*--B128--*/ this._re_culvert_super_str_obj['No_of_Asphaltic_Plug_Expansion_Joint'] = this._re_culvert_sub_str_obj['No_of_Outer_Partition_Wall']   //b75 = 2
      /*--B129--*/ this._re_culvert_super_str_obj['Length_of_Asphaltic_Plug_Expansion_Joint'] = this._foundation_obj['Carrige_Way']   //b10
      /*--B130--*/ this._re_culvert_super_str_obj['No_of_Wearing_Coat'] = 1
      /*--B131--*/ this._re_culvert_super_str_obj['Length_of_Wearing_Coat'] = this._foundation_obj['Length_of_RCC_Box_Culvert'] + (this._super_str_obj['Length_of_RCC_Approach_Slab'] * 2)  //b8 + (b115*2)
      /*--B132--*/ this._re_culvert_super_str_obj['Width_of_Wearing_Coat'] = this._foundation_obj['Carrige_Way'] //b10
      /*--B133--*/ this._re_culvert_super_str_obj['Height_of_Wearing_Coat'] = 0.0175
      /*--B134--*/ this._re_culvert_super_str_obj['No_of_Drainage_Spout'] = this._foundation_obj['No_of_RCC_Box_Cell'] * 2 //b5*2
      /*--B135--*/ this._re_culvert_super_str_obj['Norm_of_HYSD_Steel_in_Superstructure'] = 0.015
      /*--B136--*/ this._re_culvert_super_str_obj['Density_of_Steel'] = 7.85


      // FOR PROTECTION WORK <|-----|>
      /*--B142--*/  this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_floor_Appron'] = (this._re_culvert_foundation_obj['Inner_Length_of_Floor_Appron'] + this._foundation_obj['Outer_Length_of_Floor_Appron']) / 2 // (b50+b51)/2
      /*--B145--*/  this._re_culvert_protection_work_obj['No_of_Boulder_Appron_for_Upstream_Flexible_Appron'] = 1
      /*--B146--*/  this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_Upstream_Flexible_Appron'] = this._foundation_obj['Outer_Length_of_Floor_Appron'] //b51
      /*--B149--*/  this._re_culvert_protection_work_obj['No_of_Boulder_Appron_for_Downstream_Flexible_Appron'] = 1
      /*--B150--*/  this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_Downstream_Flexible_Appron'] = this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_Upstream_Flexible_Appron']  //b146
      /*--B152--*/  this._re_culvert_protection_work_obj['Height_of_Boulder_Appron_for_Downstream_Flexible_Appron'] = this._protection_work_obj['Height_of_Boulder_Appron_for_Upstream_Flexible_Appron'] //b148



    }




    Object.entries(this._re_culvert_protection_work_obj).forEach(([key, value]) => {
      this._re_culvert_protection_work_obj[key] = this.trunc2(value)
    })

    Object.entries(this._re_culvert_super_str_obj).forEach(([key, value]) => {
      this._re_culvert_super_str_obj[key] = this.trunc2(value)
    })

    Object.entries(this._re_culvert_sub_str_obj).forEach(([key, value]) => {
      this._re_culvert_sub_str_obj[key] = this.trunc2(value)
    })
    Object.entries(this._re_culvert_foundation_obj).forEach(([key, value]) => {
      this._re_culvert_foundation_obj[key] = this.trunc2(value)
    })



    console.log('==>inputssss', this._foundation_obj, "1") //these are the values taken from the user
    console.log(this._sub_str_obj, "2")
    console.log(this._super_str_obj, "3")
    console.log(this._protection_work_obj, "4")




    console.log('okkkkkkkkkkk', this._re_culvert_protection_arr, this._re_culvert_super_str_arr, this._re_culvert_sub_str_arr, 'd', this._re_culvert_foundation_arr)
  }



  /*--------------Road stepper E------------*/
  _old_tech_appr_road_arr: any = []


  async inputSetFormulaForRoad() {


    if (this._road_obj['road_location'] == 'APPR') {
      this._old_tech_appr_road_arr = this._road_obj['road_technique'] == 'OLD' ? [
        { 0: 'Total_Length_of_Approach_Road', 1: 'Length_of_Embankment_of_one_side_of_Approach_Road' },
        { 1: 'Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road', 2: 'Top_Width_of_Embankment_at_end_of_one_side_of_Approach_Road' },
        { 2: 'Bottom_Width_of_Embankment_at_end_of_one_side_of_Approach_Road', 3: 'Length_of_Embankment_of_other_side_of_Approach_Road' },
        { 3: 'Height_of_Embankment_at_Bignning_of_other_side_of_Approach_Road', 4: 'Top_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road' },
        { 4: 'Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road', 5: 'Top_Width_of_Embankment_at_end_of_other_side_of_Approach_Road' },
        { 5: 'Bottom_Width_of_Embankment_at_end_of_other_side_of_Approach_Road', 6: 'Length_of_Cement_Treated_Subbase' },
        { 6: 'Granual_Sub_Base_Grading_i(G1)_in_Approach_Road', 7: 'Width_for_WBM_Grading_i_(G2)_in_Approach_Road' },
        { 7: 'Width_for_WBM_Grade_ii(G3)_in_Approach_Road', 8: 'Length_of_Prime_Coat' },
        { 8: 'Width_of_Prime_Coat', 9: 'Length_of_Tack_Coat', },
        { 9: 'Width_of_Tack_Coat', 10: 'Length_of_Open_Graded_Premix_Surfacing' },
        { 10: 'Width_of_Open_Graded_Premix_Surfacing', 11: 'Length_of_Seal_Coat' },
        { 11: 'Width_of_Seal_Coat', 12: 'Length_of_Edge_Line' },
      ] : [
        { 0: 'Total_Length_of_Approach_Road', 1: 'Length_of_Embankment_of_one_side_of_Approach_Road' },
        { 1: 'Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road', 2: 'Top_Width_of_Embankment_at_end_of_one_side_of_Approach_Road' },
        { 2: 'Bottom_Width_of_Embankment_at_end_of_one_side_of_Approach_Road', 3: 'Length_of_Embankment_of_other_side_of_Approach_Road' },
        { 3: 'Height_of_Embankment_at_Bignning_of_other_side_of_Approach_Road', 4: 'Top_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road' },
        { 4: 'Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road', 5: 'Top_Width_of_Embankment_at_end_of_other_side_of_Approach_Road' },
        { 5: 'Bottom_Width_of_Embankment_at_end_of_other_side_of_Approach_Road', 6: 'Length_of_Cement_Treated_Subbase' },
        { 6: 'Cement_Treated_Subbase', 7: 'Cement_Treated_base' },
        { 7: 'Length_of_Tack_Coat', 8: 'Width_of_Tack_Coat' },
        { 10: 'Width_of_Open_Graded_Premix_Surfacing', 11: 'Length_of_Seal_Coat' },
        { 8: 'Length_of_Open_Graded_Premix_Surfacing', 9: 'Width_of_Seal_Coat', },
        { 9: 'Length_of_Edge_Line', 10: 'extra_item' },
      ];
      if (this._road_obj['road_technique'] == 'OLD') {
      /*--B11-- */ this._old_tech_appr_road_arr['Total_Length_of_Approach_Road'] = this._input_approach_road_obj['Length_of_one_side_of_Approach_Road'] + this._input_approach_road_obj['Length_of_other_side_of_Approach_Road']
      /*--B13-- */ this._old_tech_appr_road_arr['Length_of_Embankment_of_one_side_of_Approach_Road'] = this._input_approach_road_obj['Length_of_one_side_of_Approach_Road']
       /*--B16-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] = this._input_approach_road_obj['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] + (this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] * 4)
       /*--B18-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Approach_Road'] = this._input_approach_road_obj['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road']
        let B18 = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Approach_Road']
       /*--B19-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_one_side_of_Approach_Road'] = B18 + (4 * this._input_approach_road_obj['Height_of_Embankment_at_End_of_one_side_of_Approach_Road'])
       /*--B20-- */ this._old_tech_appr_road_arr['Length_of_Embankment_of_other_side_of_Approach_Road'] = this._input_approach_road_obj['Length_of_other_side_of_Approach_Road']
       /*--B21-- */ this._old_tech_appr_road_arr['Height_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] = this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Approach_Road']
       /*--B22-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] = this._input_approach_road_obj['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road']
       /*--B23-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] = this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road']
       /*--B25-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Approach_Road'] = this._input_approach_road_obj['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road']
       /*--B26-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_other_side_of_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Approach_Road'] + (4 * this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Approach_Road'])
       /*--B29-- */ this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
       /*--B37-- */ this._old_tech_appr_road_arr['Width_for_Granual_Sub_Base_Grading_i(G1)_in_Approach_Road'] = 7.2
       /*--B49-- */ this._old_tech_appr_road_arr['Width_for_WBM_Grading_i_(G2)_in_Approach_Road'] = 7.0
       /*--B50-- */ this._old_tech_appr_road_arr['Width_for_WBM_Grade_ii(G3)_in_Approach_Road'] = 7.0
       /*--B57-- */ this._old_tech_appr_road_arr['Length_of_Prime_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
       /*--B58-- */ this._old_tech_appr_road_arr['Width_of_Prime_Coat'] = 7.0
       /*--B61-- */ this._old_tech_appr_road_arr['Length_of_Tack_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
       /*--B62-- */ this._old_tech_appr_road_arr['Width_of_Tack_Coat'] = 7.0
       /*--B65-- */ this._old_tech_appr_road_arr['Length_of_Open_Graded_Premix_Surfacing'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
       /*--B65-- */ this._old_tech_appr_road_arr['Length_of_Open_Graded_Premix_Surfacing'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
       /*--B66-- */ this._old_tech_appr_road_arr['Width_of_Open_Graded_Premix_Surfacing'] = 7.0
       /*--B68-- */ this._old_tech_appr_road_arr['Length_of_Seal_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
       /*--B69-- */ this._old_tech_appr_road_arr['Width_of_Seal_Coat'] = 7.0
       /*--B82-- */ this._old_tech_appr_road_arr['Length_of_Edge_Line'] = this._input_approach_road_obj['Length_of_center_Line']
      } else {
        /*--B11-- */ this._old_tech_appr_road_arr['Total_Length_of_Approach_Road'] = this._input_approach_road_obj['Length_of_one_side_of_Approach_Road'] + this._input_approach_road_obj['Length_of_other_side_of_Approach_Road']
        /*--B13-- */ this._old_tech_appr_road_arr['Length_of_Embankment_of_one_side_of_Approach_Road'] = this._input_approach_road_obj['Length_of_one_side_of_Approach_Road']
        /*--B16-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] = this._input_approach_road_obj['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] + (this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] * 4)
        /*--B18-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Approach_Road'] = this._input_approach_road_obj['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road']
        let B18 = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Approach_Road']
        /*--B19-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_one_side_of_Approach_Road'] = B18 + (4 * this._input_approach_road_obj['Height_of_Embankment_at_End_of_one_side_of_Approach_Road'])
        /*--B20-- */ this._old_tech_appr_road_arr['Length_of_Embankment_of_other_side_of_Approach_Road'] = this._input_approach_road_obj['Length_of_other_side_of_Approach_Road']
        /*--B21-- */ this._old_tech_appr_road_arr['Height_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] = this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Approach_Road']
        /*--B22-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] = this._input_approach_road_obj['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road']



        /*--B23-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] = this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road']


        /*--B25-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Approach_Road'] = this._input_approach_road_obj['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road']
        /*--B26-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_other_side_of_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Approach_Road'] + (4 * this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Approach_Road'])
        /*--B29-- */ this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
        /*--B35-- */ this._old_tech_appr_road_arr['Cement_Treated_Subbase'] = 7.2
        /*--B37-- */ this._old_tech_appr_road_arr['Granual_Sub_Base_Grading_i(G1)_in_Approach_Road'] = 7.2
        /*--B47-- */ this._old_tech_appr_road_arr['Cement_Treated_base'] = 7.0

        /*--B49-- */ this._old_tech_appr_road_arr['WBM_Grading_i_(G2)_in_Approach_Road'] = 7.0
        /*--B50-- */ this._old_tech_appr_road_arr['WBM_Grade_ii(G3)_in_Approach_Road'] = 7.0
        /*--B53-- */ this._old_tech_appr_road_arr['Length_of_Tack_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
        /*--B54-- */ this._old_tech_appr_road_arr['Width_of_Tack_Coat'] = this._old_tech_appr_road_arr['Cement_Treated_base']
        /*--B57-- */ this._old_tech_appr_road_arr['Length_of_Prime_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
        /*--B58-- */ this._old_tech_appr_road_arr['Width_of_Prime_Coat'] = 7.0
        /*--B61-- */ this._old_tech_appr_road_arr['Length_of_Tack_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
        /*--B62-- */ this._old_tech_appr_road_arr['Width_of_Tack_Coat'] = 7.0
        /*--B65-- */ this._old_tech_appr_road_arr['Length_of_Open_Graded_Premix_Surfacing'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
        /*--B65-- */ this._old_tech_appr_road_arr['Length_of_Open_Graded_Premix_Surfacing'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
        /*--B66-- */ this._old_tech_appr_road_arr['Width_of_Open_Graded_Premix_Surfacing'] = 7.0
        /*--B68-- */ this._old_tech_appr_road_arr['Length_of_Seal_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_Approach_Road']
        /*--B69-- */ this._old_tech_appr_road_arr['Width_of_Seal_Coat'] = 7.0
        /*--B82-- */ this._old_tech_appr_road_arr['Length_of_Edge_Line'] = this._input_approach_road_obj['Length_of_center_Line']
      }

    }

    if (this._road_obj['road_location'] == 'ADD_APPR') {
      this._old_tech_appr_road_arr = this._road_obj['road_technique'] == 'OLD' ? [
        { 0: 'Total_Length_of_both_side_of_Additional_Approach_Road', 1: 'Length_of_Embankment_of_one_side_of_Additional_Approach_Road' },
        { 1: 'Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road', 2: 'Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road' },
        { 2: 'Top_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road', 3: 'Bottom_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road' },
        { 3: 'Length_of_Embankment_of_other_side_of_Additional_Approach_Road', 4: 'Height_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road' },
        { 4: 'Top_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road', 5: 'Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road' },
        { 5: 'Top_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road', 6: 'Bottom_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road' },
        { 6: 'Length_of_Cement_Treated_Subbase', 7: 'Width_for_Granual_Sub_Base_Grading_i(G1)_in_Additional_Approach_Road' },
        { 7: 'Length_of_Cement_Treated_Base', 8: 'WBM_Grading_i_(G2)_in_Additional_Approach_Road' },
        { 8: 'WBM_Grade_ii(G3)_in_Additional_Approach_Road', 9: 'Length_of_Prime_Coat' },
        { 9: 'Width_of_Prime_Coat', 10: 'Length_of_Tack_Coat' },
        { 10: 'Width_of_Tack_Coat', 11: 'Length_of_Open_Graded_Premix_Surfacing' },
        { 11: 'Width_of_Open_Graded_Premix_Surfacing', 12: 'Length_of_Seal_Coat' },
        { 12: 'Width_Length_of_Seal_Coat', 13: 'extra_item' }

      ] : [
        { 0: 'Total_Length_of_both_side_of_Additional_Approach_Road', 1: 'Length_of_Embankment_of_one_side_of_Additional_Approach_Road' },
        { 1: 'Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road', 2: 'Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road' },
        { 2: 'Top_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road', 3: 'Bottom_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road' },
        { 3: 'Length_of_Embankment_of_other_side_of_Additional_Approach_Road', 4: 'Height_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road' },
        { 4: 'Top_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road', 5: 'Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road' },
        { 5: 'Top_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road', 6: 'Bottom_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road' },
        { 6: 'Length_of_Cement_Treated_Subbase', 7: 'Width_for_Cement_Treated_Subbase' },
        { 7: 'Length_of_Cement_Treated_Base', 8: 'Width_for_Cement_Treated_base' },
        { 8: 'Length_of_Tack_Coat', 9: 'Width_of_Tack_Coat' },
        { 9: 'Length_of_Open_Graded_Premix_Surfacing', 10: 'Width_of_Open_Graded_Premix_Surfacing' },
        { 10: 'Length_of_Seal_Coat', 11: 'Width_Length_of_Seal_Coat' }
      ];


      if (this._road_obj['road_technique'] == 'OLD') {
              /*--C10 ..add appr-- */ this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road'] = this._input_approach_road_obj['Length_of_one_side_of_Additional_Approach_Road'] + this._input_approach_road_obj['Length_of_other_side_of_Additional_Approach_Road']

              /*--C13 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Embankment_of_one_side_of_Additional_Approach_Road'] = this._input_approach_road_obj['Length_of_one_side_of_Additional_Approach_Road']

              /*--C15 ..add appr-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] = 7.5

              /*--C16 ..add appr-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] + (4 * this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'])

               /*--C18 ..add appr-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road']

               /*--C19 ..add appr-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road'] + (4 * this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Additional_Approach_Road'])

               /*--C20 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Embankment_of_other_side_of_Additional_Approach_Road'] = this._input_approach_road_obj['Length_of_other_side_of_Additional_Approach_Road']


               /*--C21 ..add appr-- */ this._old_tech_appr_road_arr['Height_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road'] = this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road']

                /*--C22 ..add appr-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road']

                /*--C23 ..add appr-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road']

               /*--C25 ..add appr-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road']

        console.log('innn',
          this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Additional_Approach_Road']
        )
               /*--C26 ..add appr-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road'] + (4 * this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Additional_Approach_Road'])

               /*--C29 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

               /*--C37 ..add appr-- */ this._old_tech_appr_road_arr['Width_for_Granual_Sub_Base_Grading_i(G1)_in_Additional_Approach_Road'] = 3.95

               /*--C40 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Cement_Treated_Base'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

                /*--C50 ..add appr-- */ this._old_tech_appr_road_arr['WBM_Grading_i_(G2)_in_Additional_Approach_Road'] = 3.75

                /*--C51 ..add appr-- */ this._old_tech_appr_road_arr['WBM_Grade_ii(G3)_in_Additional_Approach_Road'] = 3.75

                /*--C58 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Prime_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

                /*--C59 ..add appr-- */ this._old_tech_appr_road_arr['Width_of_Prime_Coat'] = 3.75

                /*--C62 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Tack_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

                 /*--C63 ..add appr-- */ this._old_tech_appr_road_arr['Width_of_Tack_Coat'] = 3.75

                /*--C66 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Open_Graded_Premix_Surfacing'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

                /*--C67 ..add appr-- */ this._old_tech_appr_road_arr['Width_of_Open_Graded_Premix_Surfacing'] = 3.75

               /*--C69 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Seal_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

               /*--C70 ..add appr-- */ this._old_tech_appr_road_arr['Width_Length_of_Seal_Coat'] = 3.75

      } else if (this._road_obj['road_technique'] == 'NEW') {
              /*--C10 ..add appr-- */ this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road'] = this._input_approach_road_obj['Length_of_one_side_of_Additional_Approach_Road'] + this._input_approach_road_obj['Length_of_other_side_of_Additional_Approach_Road']

              /*--C13 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Embankment_of_one_side_of_Additional_Approach_Road'] = this._input_approach_road_obj['Length_of_one_side_of_Additional_Approach_Road']

              /*--C15 ..add appr-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] = 7.5

              /*--C16 ..add appr-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] + (4 * this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'])

               /*--C18 ..add appr-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road']

               /*--C19 ..add appr-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road'] + (4 * this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Additional_Approach_Road'])

               /*--C20 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Embankment_of_other_side_of_Additional_Approach_Road'] = this._input_approach_road_obj['Length_of_other_side_of_Additional_Approach_Road']


               /*--C21 ..add appr-- */ this._old_tech_appr_road_arr['Height_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road'] = this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road']

                /*--C22 ..add appr-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road']

                /*--C23 ..add appr-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road']

               /*--C25 ..add appr-- */ this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road']

               /*--C26 ..add appr-- */ this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road'] = this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road'] + (4 * this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Additional_Approach_Road'])

               /*--C29 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

               /*--C35 ..add appr-- */ this._old_tech_appr_road_arr['Width_for_Cement_Treated_Subbase'] = 3.95

               /*--C40 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Cement_Treated_Base'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

               /*--C48 ..add appr-- */ this._old_tech_appr_road_arr['Width_for_Cement_Treated_base'] = 3.75

               /*--C54 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Tack_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

               /*--C55 ..add appr-- */ this._old_tech_appr_road_arr['Width_of_Tack_Coat'] = 3.75

               /*--C66 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Open_Graded_Premix_Surfacing'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

                /*--C67 ..add appr-- */ this._old_tech_appr_road_arr['Width_of_Open_Graded_Premix_Surfacing'] = 3.75

               /*--C69 ..add appr-- */ this._old_tech_appr_road_arr['Length_of_Seal_Coat'] = this._old_tech_appr_road_arr['Total_Length_of_both_side_of_Additional_Approach_Road']

               /*--C70 ..add appr-- */ this._old_tech_appr_road_arr['Width_Length_of_Seal_Coat'] = 3.75
      }

    }




    // Object.entries(this._old_tech_appr_road_arr).forEach(([key, value]) => {
    //   this._re_culvert_protection_work_obj[key] = this.trunc2(value)
    // })
    this._re_culvert_foundation_arr = this._old_tech_appr_road_arr
    this._re_culvert_protection_arr = []
    this._re_culvert_super_str_arr = []
    this._re_culvert_sub_str_arr = []
    console.log('okkkkkkkkkk2@@@@@@k', this._re_culvert_protection_arr.length, this._re_culvert_super_str_arr, this._re_culvert_sub_str_arr, 'd', this._re_culvert_foundation_arr)

  }



  trunc2(key: any) {
    let amt = Number((key * 1000).toFixed(3))
    console.log('ssss', (Math.trunc(amt)) / 1000);

    return (Math.trunc(amt)) / 1000
  }



  async mesurementForRoad() {

    console.log('inputts s', this._input_approach_road_obj)
    console.log('inputts fix/formula', this._old_tech_appr_road_arr)

    let no: any;
    let l: any;
    let b: any;
    let h: any;
    let qty: any

    if (this._road_obj['road_technique'] == 'OLD' && this._road_obj['road_location'] == 'APPR') {

      //3.16_305
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Embankment_of_one_side_of_Approach_Road'];
      b = (this._input_approach_road_obj['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_one_side_of_Approach_Road']) / 4;
      h = (this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] + this._input_approach_road_obj['Height_of_Embankment_at_End_of_one_side_of_Approach_Road']) / 2
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_oneSide'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Embankment_of_other_side_of_Approach_Road']
      b = (this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_other_side_of_Approach_Road']) / 4
      h = this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Approach_Road'] / 2
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_otherSide'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_Granual_Sub_Base_Grading_i(G1)_in_Approach_Road']
      h = this._input_approach_road_obj['Height_for_Granual_Sub_Base_Grading_i_G1_in_Approach_Road']
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_Deduction For GSB Grade1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      // l=  b39
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_WBM_Grading_i_(G2)_in_Approach_Road']
      h = this._input_approach_road_obj['Height_for_WBM_Grading_i_G2_in_Approach_Road']
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_Deduction For WMM Grade2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_WBM_Grade_ii(G3)_in_Approach_Road']
      h = this._input_approach_road_obj['Height_for_WBM_Grade_ii(G3)_in_Approach_Road']
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_Deduction For WMM Grade3'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      // 4.1_401


      no = 1;
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_Granual_Sub_Base_Grading_i(G1)_in_Approach_Road']
      h = this._input_approach_road_obj['Height_for_Granual_Sub_Base_Grading_i_G1_in_Approach_Road']
      qty = no * l * b * h;
      this.mesurement_obj['4.1_401_Construction_of_Granual_Sub_Base_Grade_1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      // //  4.11_404
      no = 1;
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_WBM_Grading_i_(G2)_in_Approach_Road']
      h = this._input_approach_road_obj['Height_for_WBM_Grading_i_G2_in_Approach_Road']
      qty = no * l * b * h;
      this.mesurement_obj['4.11_404_Water bound Macadam Grading 2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1;
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_WBM_Grade_ii(G3)_in_Approach_Road']
      h = this._input_approach_road_obj['Height_for_WBM_Grade_ii(G3)_in_Approach_Road']
      qty = no * l * b * h;
      this.mesurement_obj['4.11_404_Water bound Macadam Grading 3'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };



      // // 5.1_502
      no = 1;
      l = this._old_tech_appr_road_arr['Length_of_Prime_Coat']
      b = this._old_tech_appr_road_arr['Width_of_Prime_Coat']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.1_502_Providing and applying primer coat with SS1 grade'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      console.log('f4');

      // //5.2_503
      no = 1;
      l = this._old_tech_appr_road_arr['Length_of_Tack_Coat']
      b = this._old_tech_appr_road_arr['Width_of_Tack_Coat']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.2_503_Tack Coat on Bituminous surfaces'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      // // 5.7_510
      no = 1;
      l = this._old_tech_appr_road_arr['Length_of_Open_Graded_Premix_Surfacing']
      b = this._old_tech_appr_road_arr['Width_of_Open_Graded_Premix_Surfacing']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.7_510_Providing, laying and rolling of open - graded premix'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      // //5.9_511
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Seal_Coat']
      b = this._old_tech_appr_road_arr['Width_of_Seal_Coat']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.9_511_Case - II : Type B'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      // 8.5_801
      no = this._input_approach_road_obj['No_of_sign_Board']
      l = ''
      b = ''
      h = ''
      qty = no;
      this.mesurement_obj['8.5_801_Providing and erecting direction and place'] = { 'No': Number(no.toFixed(2)), 'L': Number(l), 'B': Number(b), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      // // 8.14_804
      no = this._input_approach_road_obj['Ordinary_Kilometer_Stone']
      l = ''
      b = ''
      h = ''
      qty = no;
      this.mesurement_obj['8.14_804_Ordinary Kilometer Stone'] = { 'No': Number(no.toFixed(2)), 'L': Number(l), 'B': Number(b), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._input_approach_road_obj['Hecto_Metre_Stone']
      l = ''
      b = ''
      h = ''
      qty = no;
      this.mesurement_obj['8.14_804_Hecto Metre Stone'] = { 'No': Number(no.toFixed(2)), 'L': Number(l), 'B': Number(b), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      // //8.13_803
      no = this._input_approach_road_obj['No_of_center_Line']
      l = this._input_approach_road_obj['Length_of_center_Line']
      b = this._input_approach_road_obj['Wiedth_of_center_Line']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['8.13_803_center Line'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };



      console.log('f3');
      no = this._input_approach_road_obj['No_of_Edge_Line']
      l = this._old_tech_appr_road_arr['Length_of_Edge_Line']
      b = this._input_approach_road_obj['Wiedth_of_Edge_Line']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['8.13_803_Edge Line'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
    } else if (this._road_obj['road_technique'] == 'NEW' && this._road_obj['road_location'] == 'APPR') {

      //3.16_305
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Embankment_of_one_side_of_Approach_Road'];
      b = (this._input_approach_road_obj['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_one_side_of_Approach_Road']) / 4;
      h = (this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Approach_Road'] + this._input_approach_road_obj['Height_of_Embankment_at_End_of_one_side_of_Approach_Road']) / 2
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_oneSide'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Embankment_of_other_side_of_Approach_Road']
      b = (this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_other_side_of_Approach_Road']) / 4
      h = (this._old_tech_appr_road_arr['Height_of_Embankment_at_Bignning_of_other_side_of_Approach_Road'] + this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Approach_Road']) / 2
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_otherSide'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Cement_Treated_Subbase']
      h = this._input_approach_road_obj['Height_for_Cement_Treated_Subbase']
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_Deduction For CTSB'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Cement_Treated_base']
      h = this._input_approach_road_obj['Height_for_Cement_Treated_Subbase']
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_Deduction For CTB'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      // 4.7_403
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Cement_Treated_Subbase']
      h = this._input_approach_road_obj['Height_for_Cement_Treated_Subbase']
      qty = no * l * b * h;
      this.mesurement_obj['4.7_403_Cement treated Subbase (G.S.B. + 2% Cement)'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Cement_Treated_Subbase']
      h = this._input_approach_road_obj['Height_for_Cement_Treated_Subbase']
      qty = no * l * b * h;
      this.mesurement_obj['4.7_403_Cement treated base (G.S.B. + 2% Cement)'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      //  5.2_503
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Tack_Coat']
      b = this._old_tech_appr_road_arr['Width_of_Tack_Coat']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.2_503_Tack Coat on Bituminous surfaces'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      // 5.7_510
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Open_Graded_Premix_Surfacing']
      b = this._old_tech_appr_road_arr['Width_of_Open_Graded_Premix_Surfacing']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.7_510_Open - Graded Premix Surfacing'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      // 5.9_511.
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Seal_Coat']
      b = this._old_tech_appr_road_arr['Width_of_Seal_Coat']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.9_511_Seal Coat_Case - II : Type B'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      // 8.5_501
      no = 2
      l = ''
      b = ''
      h = ''
      qty = no;
      this.mesurement_obj['8.5_801_Direction and Place Identification Signs upto 0.9'] = { 'No': Number(no.toFixed(2)), 'L': Number(l), 'B': Number(b), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      //  8.14_804

      no = this._input_approach_road_obj['Ordinary_Kilometer_Stone']
      l = ''
      b = ''
      h = ''
      qty = no;
      this.mesurement_obj['8.14_804_Ordinary Kilometer Stone'] = { 'No': Number(no.toFixed(2)), 'L': Number(l), 'B': Number(b), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._input_approach_road_obj['Hecto_Metre_Stone']
      l = ''
      b = ''
      h = ''
      qty = no;
      this.mesurement_obj['8.14_804_Hecto Metre Stone'] = { 'No': Number(no.toFixed(2)), 'L': Number(l), 'B': Number(b), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      // 8.13_803
      no = this._input_approach_road_obj['No_of_center_Line']
      l = this._input_approach_road_obj['Length_of_center_Line']
      b = this._input_approach_road_obj['Wiedth_of_center_Line']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['8.13_803_center Line'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._input_approach_road_obj['No_of_Edge_Line']
      l = this._old_tech_appr_road_arr['Length_of_Edge_Line']
      b = this._input_approach_road_obj['Wiedth_of_Edge_Line']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['8.13_803_Edge Line'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

    } else if (this._road_obj['road_technique'] == 'OLD' && this._road_obj['road_location'] == 'ADD_APPR') {
      // _input_approach_road_obj  input from userrr [fill ]
      // _old_tech_appr_road_arr  [fix/formula]

      //3.16_305
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Embankment_of_one_side_of_Additional_Approach_Road']
      b = (this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road']) / 4;
      h = (this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] + this._input_approach_road_obj['Height_of_Embankment_at_End_of_one_side_of_Additional_Approach_Road']) / 2
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_oneSide'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };



      console.log('aawwss',

        this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road'])
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Embankment_of_other_side_of_Additional_Approach_Road']
      b = (
        this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road'] +
        this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road']
      ) / 4;
      h = (
        this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Additional_Approach_Road'] +
        this._old_tech_appr_road_arr['Height_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road']
      ) / 2
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_otherSide'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };





      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_Granual_Sub_Base_Grading_i(G1)_in_Additional_Approach_Road']
      h = this._input_approach_road_obj['Height_for_Granual_Sub_Base_Grading_i(G1)_in_Additional_Approach_Road']
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_GSB Grade 1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };



      no = 1
      l = 3
      b = 3.75
      h = 0.10 // dout hai
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_Deduction For GSB Grade1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      //  4.1_401
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_Granual_Sub_Base_Grading_i(G1)_in_Additional_Approach_Road']
      h = this._input_approach_road_obj['Height_for_Granual_Sub_Base_Grading_i(G1)_in_Additional_Approach_Road']
      qty = no * l * b * h;
      this.mesurement_obj['4.1_401_Construction_of_Granual_Sub_Base_Grade_1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };





      // 4.11_404
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Base']
      b = this._old_tech_appr_road_arr['WBM_Grading_i_(G2)_in_Additional_Approach_Road']
      h = this._input_approach_road_obj['Width_for_WBM_Grade_ii(G3)_in_Additional_Approach_Road']
      qty = no * l * b * h;
      this.mesurement_obj['4.11_404_Providing, laying....Grading 2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };





      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Base']
      b = this._old_tech_appr_road_arr['WBM_Grade_ii(G3)_in_Additional_Approach_Road']
      h = this._input_approach_road_obj['Width_for_WBM_Grade_ii(G3)_in_Additional_Approach_Road']
      qty = no * l * b * h;
      this.mesurement_obj['4.11_404_Providing, laying.... Grading 3'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };




      //5.1_502
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Prime_Coat']
      b = this._old_tech_appr_road_arr['Width_of_Prime_Coat']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.1_502_Prime Coat over WMM/WBM'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      // 5.2_503
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Tack_Coat']
      b = this._old_tech_appr_road_arr['Width_of_Tack_Coat']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.2_503_Tack Coat on Bituminous surfaces'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      // 5.7_510
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Open_Graded_Premix_Surfacing']
      b = this._old_tech_appr_road_arr['Width_of_Open_Graded_Premix_Surfacing']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.7_510_Open - Graded Premix Surfacing'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      // 5.9_511
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Seal_Coat']
      b = this._old_tech_appr_road_arr['Width_Length_of_Seal_Coat']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.9_511_Case - II : Type B'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };



    } else if (this._road_obj['road_technique'] == 'NEW' && this._road_obj['road_location'] == 'ADD_APPR') {
      // _input_approach_road_obj  input from userrr [fill ]
      // _old_tech_appr_road_arr  [fix/formula]

      //3.16_305
      console.log('ssssoo', this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road'])
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Embankment_of_one_side_of_Additional_Approach_Road']
      b = (this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_one_side_of_Additional_Approach_Road']) / 4
      h = (this._input_approach_road_obj['Height_of_Embankment_at_Bignning_of_one_side_of_Additional_Approach_Road'] + this._input_approach_road_obj['Height_of_Embankment_at_End_of_one_side_of_Additional_Approach_Road']) / 2
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_oneSide'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Embankment_of_other_side_of_Additional_Approach_Road']
      b = (this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Top_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Bottom_Width_of_Embankment_at_end_of_other_side_of_Additional_Approach_Road']) / 4
      h = (this._input_approach_road_obj['Height_of_Embankment_at_End_of_other_side_of_Additional_Approach_Road'] + this._old_tech_appr_road_arr['Height_of_Embankment_at_Bignning_of_other_side_of_Additional_Approach_Road']) / 2
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_otherSide'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_Cement_Treated_Subbase']
      h = this._input_approach_road_obj['Height_for_Cement_Treated_base']
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_Deduction For CTSB'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Base']
      b = this._old_tech_appr_road_arr['Width_for_Cement_Treated_base']
      h = this._input_approach_road_obj['Height_for_Cement_Treated_base']
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_Deduction For CTB'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = 600
      b = 4.05
      h = 0.125
      qty = no * l * b * h;
      this.mesurement_obj['3.16_305_GSB Grade1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      // 4.7_403

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_Cement_Treated_Subbase']
      h = this._input_approach_road_obj['Height_for_Cement_Treated_Subbase']
      qty = no * l * b * h;
      this.mesurement_obj['4.7_403_Cement Treated Subbase'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Cement_Treated_Subbase']
      b = this._old_tech_appr_road_arr['Width_for_Cement_Treated_Subbase']
      h = this._input_approach_road_obj['Height_for_Cement_Treated_Subbase']
      qty = no * l * b * h;
      this.mesurement_obj['4.7_403_Cement Treated base'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      // 5.2_503
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Tack_Coat']
      b = this._old_tech_appr_road_arr['Width_of_Tack_Coat']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.2_503_Tack Coat'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      // 5.7_510
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Open_Graded_Premix_Surfacing']
      b = this._old_tech_appr_road_arr['Width_of_Open_Graded_Premix_Surfacing']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.7_510_Open - Graded Premix Surfacing'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      //  5.9_11
      no = 1
      l = this._old_tech_appr_road_arr['Length_of_Seal_Coat']
      b = this._old_tech_appr_road_arr['Width_Length_of_Seal_Coat']
      h = ''
      qty = no * l * b;
      this.mesurement_obj['5.9_511_Seal Coat'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      // 8.5_501
      no = 2
      l = ''
      b = ''
      h = ''
      qty = no;
      this.mesurement_obj['8.5_801_Direction and Place Identification Signs upto 0.9'] = { 'No': Number(no.toFixed(2)), 'L': Number(l), 'B': Number(b), 'H/D': Number(h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
    }

    await this.mapDataForRoad()
    return
  }


  mesurement_obj: any = {}
  async mesurement() {

    console.log('measurment === ', this.mesurement_obj, this._bridge_obj['culvert_type']);

    if (this._bridge_obj['culvert_type'] == "CULVERT1") {
      let no = this._re_culvert_foundation_obj['No_of_RCC_Raft'];
      let l = this._re_culvert_foundation_obj['Length_of_RCC_Raft'];
      let b = this._re_culvert_foundation_obj['Width_of_RCC_Raft'];
      let h = this._foundation_obj['Height_of_sand_filling'] + this._foundation_obj['Height_of_RCC_Raft'] + this._foundation_obj['Depth_of_top_of_raft_below_bed_level'] + this._foundation_obj['Thickness_of_lean_concerete'];

      let qty = no * l * b * h;

      this.mesurement_obj['12.01_304_Raft'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Raft_cut_off_wall_long'];
      l = this._re_culvert_foundation_obj['Lenght_of_Raft_cut_off_wall_long'];
      b = this._re_culvert_foundation_obj['Bottom_Width_of_raft_cut_of_wall_long'] + this._foundation_obj['Thickness_of_lean_concerete'];
      h = this._re_culvert_foundation_obj['Height_of_raft_cut_of_wall_long'] + this._foundation_obj['Thickness_of_lean_concerete'];
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Raft_cut_off_wall_long'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Raft_cut_off_wall_Short'];
      l = this._re_culvert_foundation_obj['Length_of_Raft_cut_off_wall_Short'];
      b = this._foundation_obj['Bottom_Width_of_raft_cut_of_wall_Short'] + this._foundation_obj['Thickness_of_lean_concerete'];
      h = this._foundation_obj['Height_of_raft_cut_of_wall_Short'] + this._foundation_obj['Thickness_of_lean_concerete'];
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Raft_cut_off_wall_Short'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_cut_off_wall_of_Appron'];
      l = this._re_culvert_foundation_obj['Length_of_cut_off_wall_off_Appron'];
      b = this._foundation_obj['Bottom_Width_of_cut_off_wall_of_Appron'] + this._foundation_obj['Thickness_of_lean_concerete'];
      h = this._foundation_obj['Height_of_cut_off_wall_of_Appron'] + this._foundation_obj['Thickness_of_lean_concerete'];
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Cut_off_wall_long_for_Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_protection_work_obj['No_of_Launching_Appron'];
      l = this._re_culvert_protection_work_obj['Length_of_Launching_Appron'];
      b = (this._protection_work_obj['Top_width_of_launching_Appron'] + this._protection_work_obj['Bottom_width_of_launching_Appron']) / 2;
      h = this._protection_work_obj['Thickness_of_lunching_Appron'];
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Launching_Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_foundation_obj['No_of_RCC_Raft']
      l = this._re_culvert_foundation_obj['Length_of_RCC_Raft']
      b = this._re_culvert_foundation_obj['Width_of_RCC_Raft']
      h = this._foundation_obj['Thickness_of_lean_concerete']
      qty = no * l * b * h;
      console.log('aaaksjdf', no, l, b, h, qty);
      this.mesurement_obj['12.04_2100_Raft'] = {
        'No': Number(no.toFixed(2)),
        'L': Number(l.toFixed(2)),
        'B': Number(b.toFixed(2)),
        'H/D': Number(h.toFixed(2)),
        'QTY': Number(qty.toFixed(2)),
        'Unit': 'CUM'
      };

      no = this._re_culvert_foundation_obj['No_of_cut_off_wall_of_Appron']
      l = this._re_culvert_foundation_obj['Lenght_of_Raft_cut_off_wall_long']
      b = this._foundation_obj['Bottom_Width_of_raft_cut_of_wall_Short'] + this._foundation_obj['Thickness_of_lean_concerete']
      h = this._foundation_obj['Thickness_of_lean_concerete']
      qty = no * l * b * h;
      this.mesurement_obj['12.04_2100_Raft_cut_off_wall_long'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };;

      no = this._re_culvert_foundation_obj['No_of_Raft_cut_off_wall_Short']
      l = this._re_culvert_foundation_obj['Length_of_Raft_cut_off_wall_Short']
      b = this._foundation_obj['Bottom_Width_of_raft_cut_of_wall_Short'] + this._foundation_obj['Thickness_of_lean_concerete']
      h = this._foundation_obj['Thickness_of_lean_concerete']
      qty = no * l * b * h;
      this.mesurement_obj['12.04_2100_Raft_cut_off_wall_Short'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };;

      no = this._re_culvert_foundation_obj['No_of_Raft_cut_off_wall_long']
      l = this._re_culvert_foundation_obj['Length_of_cut_off_wall_off_Appron']
      b = this._foundation_obj['Bottom_Width_of_cut_off_wall_of_Appron'] + this._foundation_obj['Thickness_of_lean_concerete']
      h = this._foundation_obj['Thickness_of_lean_concerete']
      qty = no * l * b * h;
      console.log('aaaksjdf22', no, l, b, h, qty, this._foundation_obj['Bottom_Width_of_cut_off_wall_of_appron']);
      this.mesurement_obj['12.04_2100_Cut_off_wall_long_for_Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };;

      no = this._re_culvert_protection_work_obj['No_of_Filter_Media_Behind_Abutment']
      l = this._foundation_obj['Length_of_Approach_Slab']
      b = this._re_culvert_foundation_obj['Width_of_Approach_Slab']
      h = this._foundation_obj['Thickness_of_lean_concerete']
      qty = no * l * b * h;
      this.mesurement_obj['12.04_2100_Approach_slab'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Raft_cut_off_wall_Short']
      l = this._re_culvert_foundation_obj['Lenght_of_Raft_cut_off_wall_long']
      b = (this._re_culvert_foundation_obj['Bottom_Width_of_raft_cut_of_wall_long'] + this._re_culvert_foundation_obj['Top_Width_of_raft_cut_of_wall_long']) / 2
      h = this._re_culvert_foundation_obj['Height_of_raft_cut_of_wall_long']
      qty = no * l * b * h;
      this.mesurement_obj['12.05_1300_Raft_cut_off_wall_long'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
      no = this._re_culvert_foundation_obj['No_of_cut_off_wall_of_Appron']
      l = this._re_culvert_foundation_obj['Length_of_Raft_cut_off_wall_Short']
      b = (this._foundation_obj['Bottom_Width_of_raft_cut_of_wall_Short'] + this._foundation_obj['Top_width_of_raft_cut_of_wall_short']) / 2
      h = this._foundation_obj['Height_of_raft_cut_of_wall_Short']
      qty = no * l * b * h;
      this.mesurement_obj['12.05_1300_Raft_cut_off_wall_Short'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
      no = this._re_culvert_foundation_obj['No_of_cut_off_wall_of_Appron']
      l = this._re_culvert_foundation_obj['Length_of_cut_off_wall_off_Appron']
      b = (this._re_culvert_foundation_obj['Top_Width_of_cut_off_wall_of_Appron'] + this._foundation_obj['Bottom_Width_of_cut_off_wall_of_Appron']) / 2
      h = this._foundation_obj['Height_of_cut_off_wall_of_Appron']
      qty = no * l * b * h;
      this.mesurement_obj['12.05_1300_Cut_off_wall_long_for_appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_sub_str_obj['No_of_Bed_Block_at_Abutment']
      l = this._re_culvert_sub_str_obj['Length_of_Bed_Block_at_Abutment']
      b = this._sub_str_obj['Width_of_Bed_Block_at_Abutment']
      h = this._re_culvert_sub_str_obj['Depth_of_Bed_Block_at_Abutment']
      qty = no * l * b * h;
      this.mesurement_obj['13.05_1500, 1700 & 2200_Abutment'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_sub_str_obj['No_of_Bed_Block_At_Pier']
      l = this._re_culvert_sub_str_obj['Length_of_Bed_Block_At_Pier']
      b = this._re_culvert_sub_str_obj['width_of_Bed_Block_At_Pier']
      h = this._re_culvert_sub_str_obj['Depth_of_Bed_Block_At_Pier']
      qty = no * l * b * h;
      this.mesurement_obj['13.05_1500, 1700 & 2200_Pier'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      //fill

      no = this._re_culvert_super_str_obj['No_of_Expansion_Joint']
      l = this._re_culvert_super_str_obj['Length_of_Expansion_Joint']
      b = undefined
      h = undefined
      qty = no * l
      this.mesurement_obj['14.021_2607'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'RM' };

      no = this._foundation_obj['Number_of_Span'] * 2
      l = ''
      b = ''
      h = ''
      qty = no
      this.mesurement_obj['14.09_2705'] = { 'No': Number(no.toFixed(2)), 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'NO' };

      no = this._re_culvert_foundation_obj['No_of_RCC_Raft'];
      l = this._re_culvert_foundation_obj['Length_of_RCC_Raft'];
      b = this._re_culvert_foundation_obj['Width_of_RCC_Raft'];
      h = this._foundation_obj['Height_of_sand_filling'];
      qty = no * l * b * h;
      this.mesurement_obj['12.03_304'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_foundation_obj['No_of_RCC_Raft'];
      l = this._re_culvert_foundation_obj['Length_of_RCC_Raft'];
      b = this._re_culvert_foundation_obj['Width_of_RCC_Raft']
      h = this._foundation_obj['Height_of_RCC_Raft'];
      qty = no * l * b * h;
      let i27 = qty
      this.mesurement_obj['12.08_1500 , 1700 & 2100'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_super_str_obj['No_of_Approach_Slab']
      l = this._re_culvert_super_str_obj['Length_of_Approach_Slab']
      b = this._re_culvert_super_str_obj['Width_of_Approach_Slab']
      h = this._re_culvert_super_str_obj['Depth_of_Approach_Slab']
      qty = no * l * b * h;
      this.mesurement_obj['14.011_1500 , 1600 , 1700 & 2704'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_super_str_obj['No_of_Deck_Slab']
      l = this._re_culvert_super_str_obj['Length_of_Deck_Slab']
      b = this._re_culvert_super_str_obj['Width_of_Deck_Slab'];
      h = this._super_str_obj['Depth_of_Deck_Slab'];
      qty = no * l * b * h;
      let i83 = qty
      this.mesurement_obj['14.01_1500 &1600 1700_Deck_slab'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_super_str_obj['No_of_Wearing_Coat']
      l = this._re_culvert_super_str_obj['Length_of_Wearing_Coat']
      b = this._re_culvert_super_str_obj['width_of_Wearing_Coat']
      h = this._re_culvert_super_str_obj['Thickness_of_Wearing_Coat']
      qty = no * l * b * h;
      this.mesurement_obj['14.04_2702_Wearing_coat_including_reinforcement'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_protection_work_obj['No_of_Filter_Media_Behind_Abutment']
      l = this._re_culvert_protection_work_obj['Length_of_Filter_Media_Behind_Abutment']
      b = this._re_culvert_protection_work_obj['Width_of_Filter_Media_Behind_Abutment']
      h = this._re_culvert_protection_work_obj['Height_of_Filter_Media_Behind_Abutment']
      qty = no * l * b * h;
      this.mesurement_obj['13.01_710.1.4.of IRC:78 and 2504.2_filter_media'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_protection_work_obj['No_of_Launching_Appron']
      l = this._re_culvert_protection_work_obj['Length_of_Launching_Appron']
      b = (this._protection_work_obj['Top_width_of_launching_Appron'] + this._protection_work_obj['Bottom_width_of_launching_Appron']) / 2
      h = this._protection_work_obj['Thickness_of_lunching_Appron']
      qty = no * l * b * h;
      this.mesurement_obj['16.01_2503_Launching_Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_protection_work_obj['No_of_Boulder_Pitching_on_Slopes']
      l = this._re_culvert_protection_work_obj['Length_of_Boulder_Pitching_on_Slopes']
      b = this._re_culvert_protection_work_obj['Width_of_Boulder_Pitching_on_Slopes']
      h = this._re_culvert_protection_work_obj['Thickness_of_Boulder_Pitching_on_Slop']
      qty = no * l * b * h;
      this.mesurement_obj['16.04_2504_Pitching_on_slopes'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_protection_work_obj['No_of_Boulder_Pitching_on_Slopes']
      l = this._re_culvert_protection_work_obj['Length_of_Filter_Below_Pitching_on_Slpoe']
      b = this._re_culvert_protection_work_obj['Width_of_Filter_Below_Pitching_on_Slpoe']
      h = 0.15 //fix
      qty = no * l * b * h;
      this.mesurement_obj['16.05_2504_Filter_material'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = ''
      l = ''
      b = ''
      h = ''
      qty = this._re_culvert_foundation_obj['HYSD_Steel_1.5%_of_Concrete_of_bottom_Slab'] * this._re_culvert_foundation_obj['Density_of_Steel'] * 6.80
      this.mesurement_obj['13.06_Section 1600 & 2200'] = { 'No': no, 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'MT' };

      no = ''
      l = ''
      b = ''
      h = ''
      qty = this._re_culvert_foundation_obj['HYSD_Steel_1.5%_of_Concrete_of_bottom_Slab'] * this._re_culvert_foundation_obj['Density_of_Steel'] * i83
      this.mesurement_obj['14.02_1600_@1.5% of Cuncrete of Deck Slab'] = { 'No': no, 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'MT' };

      no = ''
      l = ''
      b = ''
      h = ''
      qty = this._re_culvert_foundation_obj['HYSD_Steel_1.5%_of_Concrete_of_bottom_Slab'] * this._re_culvert_foundation_obj['Density_of_Steel'] * 179.35
      this.mesurement_obj['12.04_1600_@1.5% of Cuncreet of Raft Slab'] = { 'No': no, 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'MT' };

      no = this._re_culvert_super_str_obj['No_of_Expansion_Joint']
      l = this._re_culvert_super_str_obj['Length_of_Expansion_Joint']
      b = ''
      h = ''
      qty = no * l
      this.mesurement_obj['14.021_2607'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'RM' };

      no = this._re_culvert_sub_str_obj['No_of_Weep_Hole_at_Abutment']
      l = ''
      b = ''
      h = ''
      qty = no
      this.mesurement_obj['13.08_2706 & 2200_Abutment'] = { 'No': Number(no.toFixed(2)), 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'No' };
      no = this._re_culvert_sub_str_obj['No_of_Weep_Hole_at_Pier']
      l = ''
      b = ''
      h = ''
      qty = no
      this.mesurement_obj['13.08_2706 & 2200_Pier'] = { 'No': Number(no.toFixed(2)), 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'No' };
      no = this._re_culvert_sub_str_obj['No_of_Weep_Hole_at_wing_Wall']
      l = ''
      b = ''
      h = ''
      qty = no
      this.mesurement_obj['13.08_2706 & 2200_wing_wall'] = { 'No': Number(no.toFixed(2)), 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'No' };


      //fill end
      no = this._re_culvert_sub_str_obj['No_of_Abutment']
      l = this._re_culvert_sub_str_obj['Length_of_Abutment']
      b = ''
      h = this._sub_str_obj['Height_of_abutment']
      qty = no * l * h
      this.mesurement_obj['13.02_1300 & 2200_Abutment'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'SQM' };


      no = this._re_culvert_sub_str_obj['No_of_Pier'] * 2
      l = this._re_culvert_sub_str_obj['Length_of_Pier']
      b = ''
      h = this._re_culvert_sub_str_obj['Height_of_Pier']
      qty = no * l * h

      this.mesurement_obj['13.02_1300 & 2200_Pier'] = {
        'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'SQM'
      };

      no = this._re_culvert_sub_str_obj['No_of_triangular_Portion_of_Pier'] * 2
      l = this._re_culvert_sub_str_obj['Length_of_triangular_Portion_of_Pier']
      b = ''
      h = this._re_culvert_sub_str_obj['Height_of_triangular_portion_of_pier']
      qty = no * l * h
      this.mesurement_obj['13.02_1300 & 2200_Triangular_portion_of_pier'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'SQM' };

      no = this._re_culvert_sub_str_obj['No_of_Parapet_Wall'] * 2
      l = this._re_culvert_sub_str_obj['Length_of_Parapet_Wall']
      b = ''
      h = this._sub_str_obj['Height_of_parapet_wall']
      qty = no * l * h
      this.mesurement_obj['13.02_1300 & 2200_Parapet_wall_1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'SQM' };

      no = this._re_culvert_sub_str_obj['No_of_Parapet_Wall'] * 2
      l = this._sub_str_obj['Width_of_Parapet_wall']
      b = ''
      h = this._sub_str_obj['Height_of_parapet_wall']
      qty = no * l * h
      this.mesurement_obj['13.02_1300 & 2200_Parapet_wall_2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'SQM' };

      no = this._re_culvert_sub_str_obj['No_of_Parapet_Wall']
      l = this._re_culvert_sub_str_obj['Length_of_Parapet_Wall']
      b = ''
      h = this._sub_str_obj['Width_of_Parapet_wall']
      qty = no * l * h
      this.mesurement_obj['13.02_1300 & 2200_Parapet_wall_3'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'SQM' };

      no = this._re_culvert_sub_str_obj['No_of_Bed_Block_at_Abutment']
      l = this._re_culvert_sub_str_obj['Length_of_Bed_Block_At_Pier']
      b = ''
      h = this._re_culvert_sub_str_obj['Depth_of_Bed_Block_at_Abutment']
      qty = no * l * h
      this.mesurement_obj['13.02_1300 & 2200_Deduction_for_bed_block_under_abutment'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'SQM' };

      // 1
      no = this._re_culvert_sub_str_obj['No_of_Abutment']
      l = this._re_culvert_sub_str_obj['Length_of_Abutment']
      b = (this._sub_str_obj['Bottom_width_of_abutment'] + this._sub_str_obj['Top_width_of_abutment']) / 2
      h = this._sub_str_obj['Height_of_abutment']
      qty = no * l * b * h
      this.mesurement_obj['13.01_1300 & 2200_Abutment'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
      // 2
      no = this._re_culvert_sub_str_obj['No_of_Pier']
      l = this._re_culvert_sub_str_obj['Length_of_Pier']
      b = (this._sub_str_obj['Bottom_width_of_pier'] + this._sub_str_obj['Top_width_of_pier']) / 2
      h = this._re_culvert_sub_str_obj['Height_of_Pier']
      qty = no * l * b * h
      this.mesurement_obj['13.01_1300 & 2200_Pier'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
      // 3
      no = this._re_culvert_sub_str_obj['No_of_triangular_Portion_of_Pier']
      l = (this._re_culvert_sub_str_obj['Length_of_triangular_Portion_of_Pier'] + this._sub_str_obj['Top_width_of_pier']) / 2
      b = this._re_culvert_sub_str_obj['Average_width_of_triangular_portion_of_pier_(_.5/2_)']
      h = this._re_culvert_sub_str_obj['Height_of_triangular_portion_of_pier']
      qty = no * l * b * h

      this.mesurement_obj['13.01_1300 & 2200_Triangular_portion_of_pier'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
      // 4
      no = this._re_culvert_sub_str_obj['No_of_Abutment']
      l = this._re_culvert_sub_str_obj['Length_of_Bed_Block_at_Abutment']
      b = this._sub_str_obj['Width_of_Bed_Block_at_Abutment']
      h = this._re_culvert_sub_str_obj['Depth_of_Bed_Block_at_Abutment']
      qty = no * l * b * h
      this.mesurement_obj['13.01_1300 & 2200_Deduction_for_Bed_Block_under_abutment'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
      // 5
      no = this._re_culvert_sub_str_obj['No_of_Wing_Wall']
      l = (this._re_culvert_sub_str_obj['Bottom_Length_of_Wing_Wall'] + this._re_culvert_sub_str_obj['Top_Length_of_Wing_Wall']) / 2
      b = this._re_culvert_sub_str_obj['width_of_Wing_Wall']
      h = this._re_culvert_sub_str_obj['Depth_of_Wing_Wall']
      qty = no * l * b * h
      this.mesurement_obj['13.01_1300 & 2200_Wing_Wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
      // 6
      no = this._re_culvert_sub_str_obj['No_of_Parapet_Wall']
      l = this._re_culvert_sub_str_obj['Length_of_Parapet_Wall']
      b = this._sub_str_obj['Width_of_Parapet_wall']
      h = this._sub_str_obj['Height_of_parapet_wall']
      qty = no * l * b * h
      this.mesurement_obj['13.01_1300 & 2200_Parapet_Wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
      // no=
      // l=
      // b=
      // h=
      // this.mesurement_obj['12.04_2100_Approach_slab']={'No':no,'L':l,'B':b,'H/D':h,'QTY':qty,'Unit':'CUM'};
    } else if (this._bridge_obj['culvert_type'] == "CULVERT2") {

      /*
        console.log('==>', this._foundation_obj, "1")
        console.log(this._sub_str_obj, "2")
        console.log(this._super_str_obj, "3")
        console.log(this._protection_work_obj, "4")
      */

      console.log('kkkkk=====>', this._re_culvert_foundation_obj['Width_of_Bottom_Slab'], 'j',
        this._foundation_obj['Bottom_Width_of_cut_of_wall_long_for_Bottom_Slab'], 'l',
        this._foundation_obj['Offset_length/_Lean_Concrete'])
      let no: any = 1;

      let l = this._re_culvert_foundation_obj['Length_of_cut_off_wall_long_for_Bottom_Slab'] + (2 * this._foundation_obj['Offset_length/_Lean_Concrete']);    // Input!D17+2*Input!D33
      let b = this._re_culvert_foundation_obj['Width_of_Bottom_Slab'] + (2 * this._foundation_obj['Bottom_Width_of_cut_of_wall_long_for_Bottom_Slab'] + (2 * this._foundation_obj['Offset_length/_Lean_Concrete'])) // D14 + 2* D18 + 2 * D33;
      let h = this._re_culvert_foundation_obj['Height_of_cut_off_wall_long_for_Bottom_Slab'] + this._foundation_obj['Thickness_of_Lean_Concrete_Below_Brick_Masonary_work_for_Bottom_Slab'] + this._re_culvert_foundation_obj['Depth_of_top_of_raft_below_ground_level']; // D20+ D31+ D36
      let qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Bottom_Slab_and_Cut_off_Wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };



      no = 1;
      l = this._re_culvert_protection_work_obj['length_of_Upstream_Side_Appron'] + (2 * this._foundation_obj['Offset_length/_Lean_Concrete']); // D116+2*D33
      b = this._protection_work_obj['Width_of_Upstream_Side_Appron']   //D118
      h = this._protection_work_obj['Thickness_of_Upstream_Side_Appron'] + this._re_culvert_foundation_obj['Depth_of_top_of_raft_below_ground_level']   //Input!D117+Input!D36
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Up_stream_Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = 1;
      l = this._re_culvert_protection_work_obj['length_of_Upstream_Side_Appron'] + (2 * this._foundation_obj['Offset_length/_Lean_Concrete']); // D116+2*D33
      b = this._protection_work_obj['Width_of_Downstream_Side_Appron']  // D123
      h = this._protection_work_obj['Thickness_of_Downstream_Side_Appron'] + this._re_culvert_foundation_obj['Depth_of_top_of_raft_below_ground_level']      //Input!D122+Input!D36
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Down_stream_Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = 1;
      l = this._re_culvert_foundation_obj['Length_of_Bottom_Slab']   // D13
      b = this._re_culvert_foundation_obj['Width_of_Bottom_Slab'] // D14
      h = this._foundation_obj['Thickness_of_Lean_Concrete_Below_Brick_Masonary_work_for_Bottom_Slab'] // d31
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1500 , 1700 & 2100_Below_Brick_work_for_bottom_slab'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = 2
      l = this._re_culvert_foundation_obj['Length_of_Bottom_Slab'] + (2 * this._foundation_obj['Bottom_Width_of_cut_of_wall_long_for_Bottom_Slab'] + this._foundation_obj['Offset_length/_Lean_Concrete'])   // D13+ 2* D18+ 2 *D33
      b = this._foundation_obj['Bottom_Width_of_cut_of_wall_long_for_Bottom_Slab'] + this._foundation_obj['Thickness_of_Lean_Concrete_Below_Brick_Masonary_work_for_Bottom_Slab'] // D18 + d31
      h = this._foundation_obj['Thickness_of_Lean_Concrete_Below_Brick_Masonary_work_for_Bottom_Slab'] // d31
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1500 , 1700 & 2100_Cut_off_wall_long_for_Bottom_Slab'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_foundation_obj['No_of_cut_off_wall_short_for_Bottom_Slab']  //d21
      l = this._re_culvert_foundation_obj['Length_of_cut_off_wall_short_for_Bottom_Slab']   // d22
      b = this._re_culvert_foundation_obj['Bottom_Width_of_cut_of_wall_short_for_Bottom_Slab'] + this._foundation_obj['Thickness_of_Lean_Concrete_Below_Brick_Masonary_work_for_Bottom_Slab'] // D23 + d31
      h = this._foundation_obj['Thickness_of_Lean_Concrete_Below_Brick_Masonary_work_for_Bottom_Slab'] // d31
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1500 , 1700 & 2100_Cut_off_Wall_Short'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = 1
      l = this._re_culvert_foundation_obj['Length_of_Bottom_Slab']   // d13
      b = this._re_culvert_foundation_obj['Width_of_Bottom_Slab'] //d14
      h = this._re_culvert_foundation_obj['Height_of_cut_off_wall_long_for_Bottom_Slab'] // d20
      qty = no * l * b * h;
      this.mesurement_obj['12.05_1300_Below_bottom_slab'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_foundation_obj['Width_of_Bottom_Slab']     //d16
      l = this._re_culvert_foundation_obj['Length_of_cut_off_wall_long_for_Bottom_Slab']    // d17
      b = (this._foundation_obj['Bottom_Width_of_cut_of_wall_long_for_Bottom_Slab'] + this._foundation_obj['Top_Width_of_cut_off_wall_long_for_Bottom_Slab']) / 2  // (Input!D18+Input!D19)/2
      h = this._re_culvert_foundation_obj['Height_of_cut_off_wall_long_for_Bottom_Slab'] // d20
      qty = no * l * b * h;
      this.mesurement_obj['12.05_1300_Cut_off_wall_long'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }



      no = this._re_culvert_foundation_obj['No_of_cut_off_wall_short_for_Bottom_Slab']      //d21
      l = this._re_culvert_foundation_obj['Length_of_cut_off_wall_short_for_Bottom_Slab']    // d22
      b = (this._re_culvert_foundation_obj['Bottom_Width_of_cut_of_wall_short_for_Bottom_Slab'] + this._re_culvert_foundation_obj['Top_Width_of_cut_off_wall_short_for_Bottom_Slab']) / 2  // (Input!D23+Input!D24)/2
      h = this._re_culvert_foundation_obj['Height_of_cut_off_wall_long_for_Bottom_Slab'] // d20
      qty = no * l * b * h;
      this.mesurement_obj['12.05_1300_Cut_off_wall_Short'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      no = 1
      l = this._re_culvert_foundation_obj['Length_of_Culvert']  // d10
      b = this._re_culvert_foundation_obj['Width_of_Culvert']   //d11
      h = this._foundation_obj['Height_of_Sand_filling_above_Brick_Masonary_work_for_Bottom_Slab']  // d34
      qty = no * l * b * h;
      this.mesurement_obj['12.03_304_Supply_and_Filling_of_sand_in_foundation'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }

      no = this._re_culvert_foundation_obj['No_of_Bottom_Slab'] //d12
      l = this._re_culvert_foundation_obj['Length_of_Culvert']  // d10
      b = this._re_culvert_foundation_obj['Width_of_Culvert']   //d11
      h = this._foundation_obj['Height_of_Sand_filling_above_Brick_Masonary_work_for_Bottom_Slab']  // d34
      qty = no * l * b * h;
      let i29 = qty
      this.mesurement_obj['12.08_1500 , 1700 & 2100E_RCC_M-25_in_bottom_Slab'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }



      no = ''
      l = ''
      b = ''
      h = ''
      qty = i29 * this._re_culvert_sub_str_obj['1.5%_of_Concrete_of_HYSD_Steel_in_Sub_Structure'] * this._re_culvert_sub_str_obj['Density_of_Steel_of_HYSD_Steel_in_Sub_Structure'] // (I29*Input!D37*Input!D38)

      this.mesurement_obj['12.04_1600_HYSD_Bar_Inforcement_in_foundation'] = { 'No': (no), 'L': (l), 'B': (b), 'H/D': (h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'MT' }




      no = this._re_culvert_protection_work_obj['No_of_Filter_Media_Behind_Abutment']   // D43
      l = this._re_culvert_sub_str_obj['Length_of_Abutment'] // D44
      b = this._re_culvert_sub_str_obj['Width_of_Pier']
      h = this._sub_str_obj['Height_of_Abutment'] // D46
      qty = no * l * b * h;
      let l1 = qty
      this.mesurement_obj['13.05_1500, 1700 & 2200_Abutment2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }

      no = this._re_culvert_sub_str_obj['No_of_Pier']   //D48
      l = this._re_culvert_sub_str_obj['Length_of_Pier']  //D49
      b = this._re_culvert_sub_str_obj['Width_of_Pier'] //D50
      h = this._re_culvert_sub_str_obj['Height_of_Pier'] // D51
      qty = no * l * b * h;
      let l2 = qty

      this.mesurement_obj['13.05_1500, 1700 & 2200_Pier2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }

      no = this._re_culvert_sub_str_obj['No_of_Back_Wall']      //d53
      l = this._re_culvert_sub_str_obj['Length_of_Back_Wall']  //d54
      b = this._sub_str_obj['Width_Of_Back_Wall']  //d55
      h = this._re_culvert_sub_str_obj['Hieght_of_Back/Wing_wall']  //d56
      qty = no * l * b * h;
      let l3 = qty

      this.mesurement_obj['13.05_1500, 1700 & 2200_Back_Wall2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }



      no = this._re_culvert_sub_str_obj['No_of_Abutment/Pier']  //D71
      l = this._re_culvert_sub_str_obj['Length_of_Abutment/Pier']   //d72
      b = this._sub_str_obj['Cross_Sectional_Area_of_Abutment/Pier']  //D73
      h = ''
      qty = no * l * b * h;
      let l4 = qty

      this.mesurement_obj['13.05_1500, 1700 & 2200_Haunch_for_Abutment_and_Pier'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': (h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }



      no = this._re_culvert_sub_str_obj['No_of_Back/Wing_wall']  //D75
      l = this._re_culvert_sub_str_obj['Hieght_of_Back/Wing_wall']   //d76
      b = this._re_culvert_sub_str_obj['Cross_Sectional_Area_of_Back/Wing_wall']   //D77
      h = ''
      qty = no * l * b * h;
      let l5 = qty

      this.mesurement_obj['13.05_1500, 1700 & 2200_Haunch_for_Back/Wing_Wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': (h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      no = this._re_culvert_sub_str_obj['No_Of_Parapet_Wall_(RCC-M25)']     //D65
      l = this._re_culvert_sub_str_obj['Length_Of_Parapet_Wall_(RCC-M25)']    //D66
      b = this._sub_str_obj['Width_Of_Parapet_Wall'];   //D67
      h = 0.9 //???buggg   //D68
      qty = no * l * b * h;
      let l6 = qty

      this.mesurement_obj['13.05_1500, 1700 & 2200_Parapet_Wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }



      no = this._re_culvert_sub_str_obj['No_of_Back_Wall']      //d53
      l = this._re_culvert_sub_str_obj['Length_of_Back_Wall']  //d54
      b = this._sub_str_obj['Width_Of_Back_Wall']  //d55
      h = this._re_culvert_sub_str_obj['Hieght_of_Back/Wing_wall']  //d56
      qty = no * l * b * h;
      this.mesurement_obj['13.08_2706 & 2600_Weep_Holes'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }



      no = ''
      l = ''
      b = ''
      h = ''
      qty = (l1 + l2 + l3 + l4 + l5 + l6) * 7.85 + 0.015   //I44*7.85*0.015
      this.mesurement_obj['13.6_Section 1600 & 2200_HYSD_Bar_Inforcement_in_Sub_Structure'] = { 'No': (no), 'L': (l), 'B': (b), 'H/D': (h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }




      no = this._re_culvert_super_str_obj['No']  // d93
      l = this._re_culvert_super_str_obj['Length']   // 94
      b = this._re_culvert_super_str_obj['Width'] //d95
      h = this._re_culvert_super_str_obj['Thickness']  //d96
      qty = no * l * b * h;
      this.mesurement_obj['14.10_2700_PCC_M_15_levelling'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      no = this._re_culvert_super_str_obj['No_Of_Approach_Slab']  //d98
      l = this._super_str_obj['Length_Of_RCC_M-25_Approach_Slab'] //d99
      b = this._re_culvert_super_str_obj['Width_Of_Approach_Slab']  // d100
      h = this._re_culvert_super_str_obj['Depth_Of_Approach_Slab'] // d101
      qty = no * l * b * h;
      this.mesurement_obj['14.011_1500 , 1600 , 1700 & 2704_RCC_M-25_in_Approach_slab_including_reinforcement'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      no = this._re_culvert_super_str_obj['No_Of_Wearing_Coat_including_reinforcement']   //d103
      l = this._re_culvert_super_str_obj['Length_Of_Wearing_Coat_including_reinforcement'] //d104
      b = this._re_culvert_super_str_obj['Width_Of_Wearing_Coat_including_reinforcement']    // d105
      h = this._re_culvert_super_str_obj['Length_of_Expansion_Joint'] ? this._re_culvert_super_str_obj['Length_of_Expansion_Joint'] : 0.075  // d106
      qty = no * l * b * h;
      this.mesurement_obj['14.04_2702_RCC_M-30_Wearing_coat_including_reinforcement'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      no = ''
      l = ''
      b = ''
      h = ''
      let i54 = 121.91 // will be fix letter
      qty = i54 * 7.85 * 0.15 //I54 * 7.85 *0.15
      this.mesurement_obj['14.02_1600_HYSD_Steel_work_in_Super_Structure'] = { 'No': (no), 'L': (l), 'B': (b), 'H/D': (h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      let g54 = this._re_culvert_super_str_obj['Width']
      no = this._foundation_obj['Number_of_Span'] + 1   //d7+1
      l = g54 - 1   // g54 - 1
      b = ''
      h = ''
      qty = no * l * b * h;
      this.mesurement_obj['14.021_2607_Expansion_Joint_(Strip_Seal)'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': (b), 'H/D': (h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      no = this._foundation_obj['Number_of_Span'] * 2  //d7 * 2
      l = ''
      b = ''
      h = ''
      qty = no * l * b * h;
      this.mesurement_obj['14.09_2705_Drainage_Spout'] = { 'No': Number(no.toFixed(2)), 'L': (l), 'B': (b), 'H/D': (h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      no = this._re_culvert_sub_str_obj['No_Of_Parapet_Wall_(RCC-M25)'] * 2   //d65*2
      l = this._re_culvert_sub_str_obj['Length_Of_Parapet_Wall_(RCC-M25)']  //d66
      b = this._re_culvert_sub_str_obj['width_of_Bed_Block_At_Pier'] ? this._re_culvert_sub_str_obj['width_of_Bed_Block_At_Pier'] : 0.9   // d68
      h = ''
      qty = no * l * b * h;
      this.mesurement_obj['14.016_800_Painting_with_water_based_cement_paint_on_concrete_surface'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': (h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }



      no = this._re_culvert_sub_str_obj['No_Of_Parapet_Wall_(RCC-M25)']   //d65
      l = this._re_culvert_sub_str_obj['Length_Of_Parapet_Wall_(RCC-M25)'] //d66
      b = this._sub_str_obj['Width_Of_Parapet_Wall'];    // d67
      h = ''
      qty = no * l * b * h;
      this.mesurement_obj['14.016_800_inv1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': (h), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }




      no = this._re_culvert_sub_str_obj['No_Of_Parapet_Wall_(RCC-M25)'] * 2   //d65*2
      l = ''
      b = this._sub_str_obj['Width_Of_Parapet_Wall'];
      h = this._re_culvert_sub_str_obj['width_of_Bed_Block_At_Pier'] ? this._re_culvert_sub_str_obj['width_of_Bed_Block_At_Pier'] : 0.9   // d68
      qty = no * l * b * h;
      this.mesurement_obj['14.016_800_inv2'] = { 'No': Number(no.toFixed(2)), 'L': (l), 'B': (b), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }



      no = this._re_culvert_protection_work_obj['No_of_Filter_Media_Behind_Abutment'] //D137
      l = this._re_culvert_protection_work_obj['Length_of_Filter_Media_Behind_Abutment']  // D138
      b = this._protection_work_obj['Width_of_Filter_Media_Behind_Abutment'] //D139
      h = this._re_culvert_protection_work_obj['height_of_Filter_Media_Behind_Abutment']   //D140
      qty = no * l * b * h;
      this.mesurement_obj['13.01_710.1.4.of IRC:78 and 2504.2_Providing_and_laying_of_filter_media_behind_Abutment_to_a_thickness_of_not_less_than_600_mm'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      //16.01 2503 Up_Stream_side
      no = this._re_culvert_protection_work_obj['No_of_Upstream_Side_Appron']   //d115
      l = this._re_culvert_protection_work_obj['length_of_Upstream_Side_Appron']    //d116
      b = this._protection_work_obj['Width_of_Upstream_Side_Appron']    //d118
      h = this._protection_work_obj['Thickness_of_Upstream_Side_Appron']  //d117
      qty = no * l * b * h;
      this.mesurement_obj['16.01_2503_Up_Stream_side'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      no = this._re_culvert_protection_work_obj['No_of_Downstream_Side_Appron']   //d120
      l = this._re_culvert_protection_work_obj['length_of_Upstream_Side_Appron']  //d116
      b = this._protection_work_obj['Width_of_Downstream_Side_Appron']   //d123
      h = this._protection_work_obj['Thickness_of_Upstream_Side_Appron']  //d117
      qty = no * l * b * h;
      this.mesurement_obj['16.01_2503_Down_Stream_side'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      // Boulder_Appron_in_Toe_Wall Providing_and_laying_boulder_Pitching_on_slopes 16.04_2504

      no = this._re_culvert_protection_work_obj['No_of_Boulder_Pitching_on_Slopes']   //d125
      l = this._protection_work_obj['Length_of_Boulder_Pitching_on_Slopes']  //d126
      b = this._protection_work_obj['Width_of_Boulder_Pitching_on_Slopes']  //d127
      h = this._protection_work_obj['Thickness_of_Boulder_Pitching_on_Slopes'] //d128
      qty = no * l * b * h;
      this.mesurement_obj['16.04_2504_Providing_and_laying_boulder_Pitching_on_slopes'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }


      no = this._re_culvert_protection_work_obj['No_of_Boulder_Appron_in_Toe_Wall']   //d143
      l = this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_in_Toe_Wall'] //d144
      b = this._protection_work_obj['Width_of_Boulder_Appron_in_Toe_Wall']   //d145
      h = this._protection_work_obj['Height_of_Boulder_Appron_in_Toe_Wall'] //d146
      qty = no * l * b * h;
      this.mesurement_obj['16.04_2504_Boulder_Appron_in_Toe_Wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }




      no = this._re_culvert_protection_work_obj['No_of_Boulder_Pitching_on_Slopes']// d125
      l = this._re_culvert_protection_work_obj['length_of_Filter_Below_Pitching_on_Slope']  //d132
      b = this._re_culvert_protection_work_obj['Width_of_Filter_Below_Pitching_on_Slope']   // d133
      h = this._protection_work_obj['Thickness_of_Filter_Below_Pitching_on_Slope'] // d134
      qty = no * l * b * h;
      this.mesurement_obj['16.05_2504_Filter_below_Pitching_on_Slope'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' }



    } else if (this._bridge_obj['bridge_type'] == 'BOX_CULVERT') {

      /*
      console.log('==>', this._foundation_obj, "1")
      console.log(this._sub_str_obj, "2")
      console.log(this._super_str_obj, "3")
      console.log(this._protection_work_obj, "4")
     */

      let no: any = this._re_culvert_foundation_obj['No_of_Box_Floor_Slab'];// B14
      let l = this._re_culvert_foundation_obj['Length_of_Box_Floor_Slab'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2) // b15 + (b12 * 2)
      let b = this._re_culvert_foundation_obj['Width_of_Box_Floor_Slab'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)    // b16 + (b12 *2)
      let h = this._foundation_obj['Height_of_Box_Floor_Slab'] + this._foundation_obj['Depth_of_PCC_15'] // b17 + b13
      let qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Box_Floor_slab'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_sub_str_obj['No_of_1st_step_of_Wing_Wall_above_bed_level']  //B90
      l = this._foundation_obj['Length_of_1st_step_of_wing_wall']  //B18
      b = this._foundation_obj['Width_of_1st_step_of_wing_wall'] //B19
      h = this._foundation_obj['Height_of_1st_step_of_wing_wall'] + this._foundation_obj['Height_of_2nd_step_of_wing_wall'] + this._foundation_obj['Height_of_3rd_step_of_wing_wall'] + this._foundation_obj['Depth_of_PCC_15']// B20 + B23 + B26 + B13
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Wing_Wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._foundation_obj['No_of_Floor_Appron']  //B49
      l = (this._re_culvert_foundation_obj['Inner_Length_of_Floor_Appron'] + this._foundation_obj['Outer_Length_of_Floor_Appron']) / 2 + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2) // ( b50 + b51 )/2 + (b12*2)
      b = this._foundation_obj['Width_of_Floor_Appron'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2) // B52 + (B12*2)
      h = this._foundation_obj['Height_of_Floor_Appron'] + this._foundation_obj['Depth_of_PCC_15'] //  B53 + B13
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Floor_Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_protection_work_obj['No_of_Boulder_Appron_for_Upstream_Flexible_Appron'] //B145
      l = this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_Upstream_Flexible_Appron'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2) // B146 + (B12*2)
      b = this._protection_work_obj['Width_of_Boulder_Appron_for_Upstream_Flexible_Appron'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)  //B147 + (B12*2)
      h = this._protection_work_obj['Height_of_Boulder_Appron_for_Upstream_Flexible_Appron'] + this._foundation_obj['Depth_of_PCC_15']// B148 + B13
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Up_Stream_FLexiable_Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_protection_work_obj['No_of_Boulder_Appron_for_Downstream_Flexible_Appron']    //B149
      l = this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_Downstream_Flexible_Appron'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2) // B150 + (B12*2)
      b = this._protection_work_obj['Width_of_Boulder_Appron_for_Downstream_Flexible_Appron'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2) //B151 + (B12*2)
      h = this._re_culvert_protection_work_obj['Height_of_Boulder_Appron_for_Downstream_Flexible_Appron'] + this._foundation_obj['Depth_of_PCC_15']  // B152 + B13
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Down_Stream_FLexiable_Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };



      no = this._re_culvert_foundation_obj['No_of_Curtain_Wall_type1/type2']    //B27
      l = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)   // B28 + (B12*2)
      b = this._foundation_obj['Width_of_1st_step_of_Curtain_Wall_type_1'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)  //B29 + (B12*2)
      h = this._foundation_obj['Height_of_1st_step_of_Curtain_Wall_type_1'] + this._foundation_obj['Height_of_2nd_step_of_Curtain_Wall_type_1'] + this._foundation_obj['Height_of_3rd_step_of_Curtain_Wall_type_1'] + this._foundation_obj['Height_of_4th_step_of_Curtain_Wall_type_1'] + this._foundation_obj['Depth_of_PCC_15'] // B30 + B33 + B36 + B39 + B13
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Curtain_Wall_type_1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_protection_work_obj['No_of_Boulder_Appron_for_Downstream_Flexible_Appron']   //B149
      l = this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_Downstream_Flexible_Appron'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2) // B150 + (B12*2)
      b = this._protection_work_obj['Width_of_Boulder_Appron_for_Downstream_Flexible_Appron'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)   //B151 + (B12*2)
      h = this._re_culvert_protection_work_obj['Height_of_Boulder_Appron_for_Downstream_Flexible_Appron'] + this._foundation_obj['Depth_of_PCC_15'] // B152 + B13
      qty = no * l * b * h;
      this.mesurement_obj['12.01_304_Curtain_Wall_type_2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };





      no = this._re_culvert_foundation_obj['No_of_Box_Floor_Slab']  // b14
      l = this._re_culvert_foundation_obj['Length_of_Box_Floor_Slab'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2) // b15 + (b12 * 2)
      b = this._re_culvert_foundation_obj['Width_of_Box_Floor_Slab'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)    // b16 + (b12 *2)
      h = this._foundation_obj['Depth_of_PCC_15'] // b13
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_Box_FLoor_Slab'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_sub_str_obj['No_of_1st_step_of_Wing_Wall_above_bed_level']   //  b90
      let f20 = no;
      l = this._foundation_obj['Length_of_1st_step_of_wing_wall']    //  b18

      b = this._foundation_obj['Width_of_1st_step_of_wing_wall']   //  b19
      h = this._foundation_obj['Depth_of_PCC_15']   //  b13
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_Wing_Wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._foundation_obj['No_of_Floor_Appron']  // b49
      l = (this._re_culvert_foundation_obj['Inner_Length_of_Floor_Appron'] + this._foundation_obj['Outer_Length_of_Floor_Appron']) / 2 + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)  // (b50 + b51)/2 + (b12 * 2)
      b = this._foundation_obj['Width_of_Floor_Appron'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2) // b52 + (b12 *2)
      h = this._foundation_obj['Depth_of_PCC_15']  // b13
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_Floor_Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Curtain_Wall_type1/type2']    // b27
      l = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)   // B28 + (B12*2)
      b = this._foundation_obj['Width_of_1st_step_of_Curtain_Wall_type_1'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)  //B29 + (B12*2)
      h = this._foundation_obj['Depth_of_PCC_15']     // b13
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_Curtain_Wall_type_1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_protection_work_obj['No_of_Boulder_Appron_for_Downstream_Flexible_Appron']    // b149
      l = this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_Downstream_Flexible_Appron'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)    // b150 + (b12 * 2)
      b = this._foundation_obj['Width_of_1st_step_of_Curtain_Wall_type_1'] + (this._foundation_obj['Offset_for_length_/_width_PCC_15_/_Leveling_Course'] * 2)  //B29 + (B12*2)
      h = this._foundation_obj['Depth_of_PCC_15']    // b13
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_Curtain_Wall_type_2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Curtain_Wall_type1/type2']    // b27
      l = this._foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_1']   //B28
      b = this._foundation_obj['Width_of_1st_step_of_Curtain_Wall_type_1']  // b29
      h = this._foundation_obj['Height_of_1st_step_of_Curtain_Wall_type_1']  // B30
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_1st_step_of_Curtain_Wall_type_1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Curtain_Wall_type1/type2']    // b27
      l = this._re_culvert_foundation_obj['Length_of_2nd_step_of_Curtain_Wall_type_1']   //b31
      b = this._foundation_obj['Width_of_2nd_step_of_Curtain_Wall_type_1']  //b32
      h = this._foundation_obj['Height_of_2nd_step_of_Curtain_Wall_type_1']  //b33
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_2nd_step_of_Curtain_Wall_type_1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Curtain_Wall_type1/type2']    // b27
      l = this._re_culvert_foundation_obj['Length_of_3rd_step_of_Curtain_Wall_type_1']   //b34
      b = this._foundation_obj['Width_of_3rd_step_of_Curtain_Wall_type_1']  //b35
      h = this._foundation_obj['Height_of_3rd_step_of_Curtain_Wall_type_1']   //b36
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_3rd_step_of_Curtain_Wall_type_1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Curtain_Wall_type1/type2']    // b27
      l = this._re_culvert_foundation_obj['Length_of_4th_step_of_Curtain_Wall_type_1']//b37
      b = this._foundation_obj['Width_of_4th_step_of_Curtain_Wall_type_1']  //b38
      h = this._foundation_obj['Height_of_4th_step_of_Curtain_Wall_type_1']  //b39
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_4th_step_of_Curtain_Wall_type_1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Curtain_Wall_type1/type2']    // b27
      l = this._re_culvert_foundation_obj['Length_of_1st_step_of_Curtain_Wall_type_2'] //b40
      b = this._foundation_obj['Width_of_1st_step_of_Curtain_Wall_type_2'] //b41
      h = this._foundation_obj['Height_of_1st_step_of_Curtain_Wall_type_2'] //b42
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_1st_step_of_Curtain_Wall_type_2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Curtain_Wall_type1/type2']    // b27
      l = this._re_culvert_foundation_obj['Length_of_2nd_step_of_Curtain_Wall_type_2']  //b43
      b = this._foundation_obj['Width_of_2nd_step_of_Curtain_Wall_type_2'] //b44
      h = this._foundation_obj['Height_of_2nd_step_of_Curtain_Wall_type_2'] //b45
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_2nd_step_of_Curtain_Wall_type_2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Curtain_Wall_type1/type2']    // b27
      l = this._re_culvert_foundation_obj['Length_of_3rd_step_of_Curtain_Wall_type_2'] //b46
      b = this._foundation_obj['Width_of_3rd_step_of_Curtain_Wall_type_2'] //b47
      h = this._foundation_obj['Height_of_3rd_step_of_Curtain_Wall_type_2'] //b48
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 A_3rd_step_of_Curtain_Wall_type_2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = f20 //F20
      l = this._foundation_obj['Length_of_1st_step_of_wing_wall']   //b18
      b = this._foundation_obj['Width_of_1st_step_of_wing_wall']    //b19
      h = this._foundation_obj['Height_of_1st_step_of_wing_wall']   //b20
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 B_1st_step_of_wing_wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = f20  //f20
      l = this._re_culvert_foundation_obj['Length_of_2nd_step_of_wing_wall']  //B21
      b = this._foundation_obj['Width_of_2nd_step_of_wing_wall'] //B22
      h = this._foundation_obj['Height_of_2nd_step_of_wing_wall'] //B23
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 B_2nd_step_of_wing_wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = f20 //f20
      l = this._re_culvert_foundation_obj['Length_of_3rd_step_of_wing_wall'] //B24
      b = this._foundation_obj['Width_of_3rd_step_of_wing_wall'] //B25
      h = this._foundation_obj['Height_of_3rd_step_of_wing_wall']  //B26
      qty = no * l * b * h;
      this.mesurement_obj['12.08_1200,1500,1700 B_3rd_step_of_wing_wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_foundation_obj['No_of_Box_Floor_Slab']  //b14
      l = this._re_culvert_foundation_obj['Length_of_Box_Floor_Slab'] //B15
      b = this._re_culvert_foundation_obj['Width_of_Box_Floor_Slab']   //B16
      h = this._foundation_obj['Height_of_Box_Floor_Slab']    //B17
      qty = no * l * b * h;
      let j46 = qty
      this.mesurement_obj['12.08_1200,1500,1700 C_Box_Floor_Slab'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };




      no = ''
      l = ''
      b = ''
      h = ''
      qty = j46 * this._re_culvert_foundation_obj['Norm_of_HYSD_Steel_in_Foundation'] * this._re_culvert_foundation_obj['Density_of_Steel']   //j46 * b60 * b61
      this.mesurement_obj['12.04_1600_HYSD_Steel_in_Foundation'] = { 'No': no, 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'MT' };

      no = this._re_culvert_foundation_obj['No_of_Sand_Filling_Behaind_Wing_Wall']   //b62
      l = this._re_culvert_foundation_obj['Length_of_Sand_Filing_Behaind_Wing_Wall'] //b63
      b = this._foundation_obj['Width_of_Sand_Filing_Behaind_Wing_Wall'] //b64
      h = this._foundation_obj['Depth_of_Sand_Filing_Behaind_Wing_Wall']   //b65
      qty = no * l * b * h;

      this.mesurement_obj['12.21_1207_sand_filling_beind_wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_foundation_obj['No_of_Sand_Filling_Behaind_Outer_Partition_Wall']   //b66
      l = this._re_culvert_foundation_obj['Length_of_Sand_Filing_Behaind_Outer_Partition_Wall']   //b67
      b = this._foundation_obj['Width_of_Sand_Filing_Behaind_Outer_Partition_Wall']  //b68
      h = this._re_culvert_foundation_obj['Depth_of_Sand_Filing_Behaind_Outer_Partition_Wall']  //b69
      qty = no * l * b * h;

      this.mesurement_obj['12.21_1207_Sand_Filing_Behaind_Outer_Partition_Wall'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._re_culvert_foundation_obj['Length_of_Upstream_Flexible_Appron']      //b54
      b = this._foundation_obj['Width_of_Upstream_Flexible_Appron'] //b55
      h = this._foundation_obj['Depth_of_Sand_Filling_Below_Up_Stream_Flexible_Appron'] //b70
      qty = no * l * b * h;

      this.mesurement_obj['12.21_1207_Sand Filling Below Up Stream Flexible Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = 1
      l = this._re_culvert_foundation_obj['Length_of_Downstream_Flexible_Appron']   //b57
      b = this._foundation_obj['Width_of_Downstream_Flexible_Appron'] //b58
      h = this._foundation_obj['Depth_of_Sand_Filling_Below_Down_Stream_Flexible_Appron'] //b71
      qty = no * l * b * h;
      this.mesurement_obj['12.21_1207_Sand Filling Below Down Stream Flexible Appron'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_sub_str_obj['No_of_Outer_Partition_Wall']    //b75
      l = this._re_culvert_sub_str_obj['Length_of_Inner_Partition_Wall']  //b76
      b = this._sub_str_obj['Width_of_Outer_Partition_Wall']    //b77
      h = this._sub_str_obj['Height_of_Outer_Partition_Wall']  //b78
      qty = no * l * b * h;
      let temp1 = qty
      this.mesurement_obj['13.05_1200,1500,1700F(P)_#a'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_sub_str_obj['No_of_Inner_Partition_Wall']   //b79
      l = this._re_culvert_sub_str_obj['Length_of_Inner_Partition_Wall'] //b80
      b = this._sub_str_obj['Width_of_Inner_Partition_Wall'] //b81
      h = this._re_culvert_sub_str_obj['Height_of_Inner_Partition_Wall']  //b82
      qty = no * l * b * h;
      let temp2 = qty
      this.mesurement_obj['13.05_1200,1500,1700F(P)_#b'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_sub_str_obj['No_of_Haunch_in_Inner_and_outer_partion_wall'] //b83
      l = this._sub_str_obj['Cross_Sectional_Area_of_Haunch_of_Inner/outer_partion_wall'] //b84
      b = '' //1
      h = this._re_culvert_sub_str_obj['Height_of_Haunch_of_Inner/outer_partion_wall']   //b85
      qty = no * l * h;
      let temp3 = qty
      this.mesurement_obj['13.05_1200,1500,1700F(P)_#c'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_sub_str_obj['No_of_Bracket_for_Approach_Slab']       //b86
      l = this._re_culvert_sub_str_obj['Length_of_Bracket_for_Approach_Slab']    //b87
      b = this._sub_str_obj['Cross_Sectional_Area_of_Bracket_for_Approach_Slab'] //b88
      h = ''
      qty = no * l * b
      let temp4 = qty
      this.mesurement_obj['13.05_1200,1500,1700F(P)_#d'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      let j68 = temp1 + temp2 + temp3 + temp4


      no = this._re_culvert_sub_str_obj['No_of_1st_step_of_Wing_Wall_above_bed_level']   //  b90
      l = this._sub_str_obj['Length_of_1st_step_of_Wing_Wall_above_bed_level']    //b91
      b = this._sub_str_obj['Width_of_1st_step_of_Wing_Wall_above_bed_level']  //b92
      h = this._sub_str_obj['Height_of_1st_step_of_Wing_Wall_above_bed_level']     //b93
      qty = no * l * b * h;
      this.mesurement_obj['13.05_1500,1700,2200B(P)_#1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_sub_str_obj['No_of_2nd_step_of_Wing_Wall_above_bed_level']    //b94
      l = this._re_culvert_sub_str_obj['Length_of_2nd_step_of_Wing_Wall_above_bed_level'] //b95
      b = this._sub_str_obj['Width_of_2nd_step_of_Wing_Wall_above_bed_level']    //b96
      h = this._sub_str_obj['Height_of_2nd_step_of_Wing_Wall_above_bed_level']    //b97
      qty = no * l * b * h;
      this.mesurement_obj['13.05_1500,1700,2200B(P)_#2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_sub_str_obj['No_of_3rd_step_of_Wing_Wall_above_bed_level']    //b98
      l = this._re_culvert_sub_str_obj['Length_of_d_3rd_tep_of_Wing_Wall_above_bed_level']    //b99
      b = this._sub_str_obj['Width_of_3rd_step_of_Wing_Wall_above_bed_level']    //b100
      h = this._sub_str_obj['height_of_3rd_step_of_Wing_Wall_above_bed_level']     //b101
      qty = no * l * b * h;
      this.mesurement_obj['13.05_1500,1700,2200B(P)_#3'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };



      no = this._re_culvert_sub_str_obj['No_of_Weep_Hole_in_outer_Partion_Wall']
      l = ''
      b = ''
      h = ''
      qty = no
      this.mesurement_obj['13.08_2706 & 2200_#1'] = { 'No': Number(no.toFixed(2)), 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_sub_str_obj['No_of_Weep_Hole_in_Inner_Partion_Wall']   //b103
      l = ''
      b = ''
      h = ''
      qty = no
      this.mesurement_obj['13.08_2706 & 2200_#2'] = { 'No': Number(no.toFixed(2)), 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };



      no = this._re_culvert_sub_str_obj['No_of_Weep_Hole_in_Wing_Wall']    //b104
      l = ''
      b = ''
      h = ''
      qty = no
      this.mesurement_obj['13.08_2706 & 2200_#3'] = { 'No': Number(no.toFixed(2)), 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = ''
      l = ''
      b = ''
      h = ''
      qty = j68 * this._re_culvert_sub_str_obj['Norm_of_HYSD_Steel_in_Sub_Structure'] * this._re_culvert_sub_str_obj['Density_of_HYSD_steel']    //j68 * b105 * b106
      this.mesurement_obj['13.06_1600_HYSD_Steel_in_Substructure'] = { 'No': no, 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'MT' };

      no = this._re_culvert_super_str_obj['No_of_box_top_slab'] //b110
      l = this._re_culvert_super_str_obj['Length_of_box_top_slab']   //b111
      b = this._re_culvert_super_str_obj['Width_of_box_top_Slab']  //b112
      h = this._super_str_obj['Height_of_box_top_Slab'] //b113
      qty = no * l * b * h;
      let l1 = qty
      this.mesurement_obj['14.01_1500,1600,1700_Box_Top_Slab'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_super_str_obj['No_of_Road_Kerb']   //b122
      l = this._re_culvert_super_str_obj['Length_of_Road_Kerb']  //b123
      b = (this._super_str_obj['Top_Width_of_Road_Kerb'] + this._super_str_obj['Bottom_Width_of_Road_Kerb']) / 2 // (b124 + b125)/2
      h = this._super_str_obj['Height_of_Road_Kerb']   //b126
      qty = no * l * b * h;
      let l2 = qty
      this.mesurement_obj['14.01_1500,1600,1700_Road_Kerb'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };
      let j94 = l1 + l2




      no = this._re_culvert_super_str_obj['No_of_PCC_M_15_Levelling_Course_below_Approach_Slab']  //b118
      l = this._re_culvert_super_str_obj['Length_of_PCC_M_15_Levelling_Course_below_Approach_Slab']    //b119
      b = this._re_culvert_super_str_obj['Width_of_PCC_M_15_Levelling_Course_below_Approach_Slab']     //b120
      h = this._re_culvert_super_str_obj['Height_of_PCC_M_15_Levelling_Course_below_Approach_Slab']   //b121
      qty = no * l * b * h;
      this.mesurement_obj['14.10_2700_PCC_M_15_levelling'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_super_str_obj['No_of_RCC_Approach_Slab']   //B114
      l = this._super_str_obj['Length_of_RCC_Approach_Slab']   //b115
      b = this._re_culvert_super_str_obj['Width_of_RCC_Approach_Slab']  //b116
      h = this._re_culvert_super_str_obj['Height_of_RCC_Approach_Slab'] //b117
      qty = no * l * b * h;
      this.mesurement_obj['14.011_1500,1600,1700,2200_@1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = ''
      l = this._re_culvert_super_str_obj['Length_of_Pre_Cast_RCC_Railing']  //b127
      b = ''
      h = ''
      qty = l
      this.mesurement_obj['14.06_2703,1500,1600,1700_@1'] = { 'No': no, 'L': Number(l.toFixed(2)), 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_super_str_obj['No_of_Wearing_Coat']   //b130
      l = this._re_culvert_super_str_obj['Length_of_Wearing_Coat']  //b131
      b = this._re_culvert_super_str_obj['Width_of_Wearing_Coat']   //b132
      h = this._re_culvert_super_str_obj['Height_of_Wearing_Coat']  //b133
      qty = no * l * b * h;
      this.mesurement_obj['14.04_2702_@1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_super_str_obj['No_of_Drainage_Spout']   //b134
      l = ''
      b = ''
      h = ''
      qty = no
      this.mesurement_obj['14.09_2705_@1'] = { 'No': Number(no.toFixed(2)), 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


      no = this._re_culvert_super_str_obj['No_of_Asphaltic_Plug_Expansion_Joint']    //b128
      l = this._re_culvert_super_str_obj['Length_of_Asphaltic_Plug_Expansion_Joint']  //b129
      b = ''
      h = ''
      qty = no * l;
      this.mesurement_obj['14.18_2600_@1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = ''
      l = ''
      b = ''
      h = ''
      qty = j94 * this._re_culvert_super_str_obj['Norm_of_HYSD_Steel_in_Superstructure'] * this._re_culvert_super_str_obj['Density_of_Steel']; // j94 * b135 * b136
      this.mesurement_obj['14.02_1600_@1'] = { 'No': no, 'L': l, 'B': b, 'H/D': h, 'QTY': Number(qty.toFixed(2)), 'Unit': 'MT' };


      no = this._protection_work_obj['No_of_Boulder_Appron_for_floor_Appron'] //b141
      l = this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_floor_Appron']   //b142
      b = this._protection_work_obj['Width_of_Boulder_Appron_for_floor_Appron'] //b143
      h = this._protection_work_obj['Height_of_Boulder_Appron_for_floor_Appron'] //b144
      qty = no * l * b * h;
      this.mesurement_obj['16.01_1503_@1'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_protection_work_obj['No_of_Boulder_Appron_for_Upstream_Flexible_Appron']     //b145
      l = this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_Upstream_Flexible_Appron']    //b146
      b = this._protection_work_obj['Width_of_Boulder_Appron_for_Upstream_Flexible_Appron']  //b147
      h = this._protection_work_obj['Height_of_Boulder_Appron_for_Upstream_Flexible_Appron']   //b148
      qty = no * l * b * h;
      this.mesurement_obj['16.01_1503_@2'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };

      no = this._re_culvert_protection_work_obj['No_of_Boulder_Appron_for_Downstream_Flexible_Appron']  //B149
      l = this._re_culvert_protection_work_obj['Length_of_Boulder_Appron_for_Downstream_Flexible_Appron']   //B150
      b = this._protection_work_obj['Width_of_Boulder_Appron_for_Downstream_Flexible_Appron'] //b151
      h = this._re_culvert_protection_work_obj['Height_of_Boulder_Appron_for_Downstream_Flexible_Appron']   //b152
      qty = no * l * b * h;
      this.mesurement_obj['16.01_1503_@3'] = { 'No': Number(no.toFixed(2)), 'L': Number(l.toFixed(2)), 'B': Number(b.toFixed(2)), 'H/D': Number(h.toFixed(2)), 'QTY': Number(qty.toFixed(2)), 'Unit': 'CUM' };


    }

    await this.mapData()
    // this.mesurement_obj['']={}

  }


  itemsArr: any = []
  //  [
  //   {'filter':'uperLine','group_desc':12.01,'sub_group_desc':304,item_desc:'Earth Work in excavation of foundation of structures as per drawing and technical specification, including setting out, construction of shoring and bracing, removal of stumps and other deleterious matter, dressing of sides and bottom and backfilling with approved material'},
  //   {'filter':'uperLine','group_desc':12.04,'ref_to_morth':2100,item_desc:'Plain cement concrete 1:3:6 nominal mix in foundation with crushed stone aggregate 40 mm nominal size mechanically mixed, placed in foundation and compacted by vibration including curing for 14 days.'},
  //   {'filter':'uperLine','group_desc':12.03,'ref_to_morth':304,item_desc:'Sand Filling in Foundation Trenches as per Drawing & Technical Specification '},
  //   {'filter':'uperLine','group_desc':12.08,'ref_to_morth':'1500, 1700 & 2100',item_desc:'Sand Filling in Foundation Trenches as per Drawing & Technical Specification '},
  //   // {'filter':'uperLine','group_desc':12.05,'ref_to_morth':'1300',item_desc:'Sand Filling in Foundation Trenches as per Drawing & Technical Specification '},
  //   {'filter':'uperLine','group_desc':13.02,'ref_to_morth':'1300 & 2200' ,item_desc:'Sand Filling in Foundation Trenches as per Drawing & Technical Specification '},
  //   // {'filter':'uperLine','group_desc':12.04,'ref_to_morth':1600,item_desc:'Sand Filling in Foundation Trenches as per Drawing & Technical Specification '},
  //   {'filter':'uperLine','group_desc':13.01,'ref_to_morth':'1300 & 2200',item_desc:'Sand Filling in Foundation Trenches as per Drawing & Technical Specification '},
  //   // {'filter':'uperLine','group_desc':13.05,'ref_to_morth':'1500,1700& 2200 F',item_desc:'Sand Filling in Foundation Trenches as per Drawing & Technical Specification '},
  //   // {'filter':'uperLine','group_desc':13.06,'ref_to_morth':'Section 1600 & 2200',item_desc:'Sand Filling in Foundation Trenches as per Drawing & Technical Specification '},
  //   // {'filter':'uperLine','group_desc':13.06,'ref_to_morth':'Section 1600 & 2200',item_desc:'Sand Filling in Foundation Trenches as per Drawing & Technical Specification '},
  //   // {'filter':'uperLine','group_desc':12.01,'ref_to_morth'  :304,item_desc:'Earth Work in excavation of foundation of structures as per drawing and technical specification, including setting out, construction of shoring and bracing, removal of stumps and other deleterious matter, dressing of sides and bottom and backfilling with approved material'},
  //   // {'filter':'middleLine','group_desc':12.01,item_desc:'Raft',no:5,l:40.6,b:1.125,'H/D':8,qty:9,'unit':'CUM'},
  //   // {'filter':'middleLine','group_desc':12.01,item_desc:'Raft cut off wall long',no:5,l:40.6,b:1.125,'H/D':8,qty:9,'unit':'CUM'},
  //   // {'filter':'lowerLine',Total:1375, unit:'CUM'}

  //  ]
  _items_foundation_arr: any = []
  _items_sub_str_arr: any = []
  _items_super_str_arr: any = []
  _items_protection_work_arr: any = []
  all_items: any = []
  mapData() {
    console.log('achhhhh', this.itemsArr);
    let tempArr = []
    if (this._bridge_obj['culvert_type'] == "CULVERT2") {

      for (let i = 0; i < this.itemsArr.length; i++) {
        let x = this.itemsArr[i]
        console.log(' checkpoint', x['sno'] + '_' + x['ref_to_morth']);
        if (x['sno'] + '_' + x['ref_to_morth'] == '12.01_304' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.08_1500 , 1700 & 2100' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.05_1300' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.03_304' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.08_1500 , 1700 & 2100E' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.04_1600' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.05_1500, 1700 & 2200' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.08_2706 & 2600' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.6_Section 1600 & 2200' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.10_2700' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.011_1500 , 1600 , 1700 & 2704' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.04_2702' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.02_1600' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.021_2607' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.09_2705' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.016_800' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.01_710.1.4.of IRC:78 and 2504.2' ||
          x['sno'] + '_' + x['ref_to_morth'] == '16.01_2503' ||
          x['sno'] + '_' + x['ref_to_morth'] == '16.04_2504' ||
          x['sno'] + '_' + x['ref_to_morth'] == '16.05_2504'
        ) {
          tempArr.push(x)
        } else {
          // tempArr.push(x)
        }
      }
      this.itemsArr = tempArr
    } else if (this._bridge_obj['culvert_type'] == "CULVERT1") {

      for (let i = 0; i < this.itemsArr.length; i++) {
        let x = this.itemsArr[i]
        if (x['sno'] + '_' + x['ref_to_morth'] == '12.01_304' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.04_2100' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.021_2607' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.09_2705' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.06_Section 1600 & 2200' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.04_1600' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.02_1600' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.05_1500, 1700 & 2200' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.05_1300' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.01_1300 & 2200' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.02_1300 & 2200' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.08_2706 & 2200' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.01_1500 &1600 1700' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.03_304' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.011_1500 , 1600 , 1700 & 2704' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.08_1500 , 1700 & 2100' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.04_2702' ||
          x['sno'] + '_' + x['ref_to_morth'] == '16.05_2504' ||
          x['sno'] + '_' + x['ref_to_morth'] == '16.04_2504' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.01_710.1.4.of IRC:78 and 2504.2' ||
          x['sno'] + '_' + x['ref_to_morth'] == '16.01_2503'
        ) {
          console.log('aaaaaaaa', x['sno'] + '_' + x['ref_to_morth'])
          tempArr.push(x)
        } else {
          // tempArr.push(x)
        }
      }
      this.itemsArr = tempArr
    } else if (this._bridge_obj['bridge_type'] == 'BOX_CULVERT' && this._bridge_obj['culvert_type'] == undefined) {
      // console.log('/ddd see' , this.itemsArr)

      for (let i = 0; i < this.itemsArr.length; i++) {
        let x = this.itemsArr[i]
        console.log(' checkpoint b', x['sno'] + '_' + x['ref_to_morth']);
        if (x['sno'] + '_' + x['ref_to_morth'] == '12.01_304' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.08_1200,1500,1700 A' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.08_1200,1500,1700 B' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.04_1600' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.08_1200,1500,1700 C' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.05_1200,1500,1700F(P)' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.05_1500,1700,2200B(P)' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.011_1500,1600,1700,2200' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.06_2703,1500,1600,1700' ||
          x['sno'] + '_' + x['ref_to_morth'] == '16.01_1503' ||
          x['sno'] + '_' + x['ref_to_morth'] == '16.01_2503' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.18_2600' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.01_1500,1600,1700' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.10_2700' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.06_1600' ||
          x['sno'] + '_' + x['ref_to_morth'] == '13.08_2706 & 2200' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.09_2705' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.04_2702' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.02_1600' ||
          x['sno'] + '_' + x['ref_to_morth'] == '12.21_1207'
        ) {
          console.log('/lets see', x)
          tempArr.push(x)
        } else {
          // tempArr.push(x)
        }
      }
      this.itemsArr = tempArr

    }

    let total = 0
    this.spinner.show()
    for (let i = 0; i < this.itemsArr.length; i++) {
      let x = this.itemsArr[i]


      if (this._bridge_obj['culvert_type'] == "CULVERT1") {
        if (x['sno'] + '_' + x['ref_to_morth'] == '12.01_304' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Raft'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Raft'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Raft_cut_off_wall_long'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Raft cut off wall long'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']
          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Raft_cut_off_wall_Short'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Raft cut off wall Short'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']
          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cut_off_wall_long_for_Appron'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'Cut off wall long for Appron'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total += this.itemsArr[i + 4]['QTY']
          this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Launching_Appron'])
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['item_desc'] = 'Launching Appron'
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'middleLine'
          total += this.itemsArr[i + 5]['QTY']
          this.itemsArr.splice(i + 6, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 6]['sno'] = x['sno']
          this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 6]['filter'] = 'lowerLine'
          this.itemsArr[i + 6]['Unit'] = this.itemsArr[i + 5]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.04_2100' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Raft'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Raft'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Raft_cut_off_wall_long'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Raft cut off wall long'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']
          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Raft_cut_off_wall_Short'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Raft cut off wall Short'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']
          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cut_off_wall_long_for_Appron'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'Cut off wall long for Appron'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total += this.itemsArr[i + 4]['QTY']
          console.log(this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Approach_slab'], x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Approach_slab')
          this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Approach_slab'])
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['item_desc'] = 'Approach slab'
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'middleLine'
          total += this.itemsArr[i + 5]['QTY']
          this.itemsArr.splice(i + 6, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 6]['sno'] = x['sno']
          this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 6]['filter'] = 'lowerLine'
          this.itemsArr[i + 6]['Unit'] = this.itemsArr[i + 5]['Unit']
          total = 0
        } else if (
          x['sno'] + '_' + x['ref_to_morth'] == '13.06_' && x['filter'] == 'uperLine' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.09_2705' && x['filter'] == 'uperLine' ||
          x['sno'] + '_' + x['ref_to_morth'] == '14.021_2607' && x['filter'] == 'uperLine'
        ) {
          this.itemsArr[i] = { ...x, ...this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth']] }
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.02_1300 & 2200' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Abutment'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Abutment'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Pier'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Pier'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']
          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Triangular_portion_of_pier'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Triangular portion of pier'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']
          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Parapet_wall_1'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'Parapet wall 1'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total += this.itemsArr[i + 4]['QTY']
          this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Parapet_wall_2'])
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['item_desc'] = 'Parapet wall 2'
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'middleLine'
          total += this.itemsArr[i + 5]['QTY']
          this.itemsArr.splice(i + 6, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Parapet_wall_3'])
          this.itemsArr[i + 6]['sno'] = x['sno']
          this.itemsArr[i + 6]['item_desc'] = 'Parapet wall 3'
          this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 6]['filter'] = 'middleLine'
          total += this.itemsArr[i + 6]['QTY']

          this.itemsArr.splice(i + 7, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 7]['sno'] = x['sno']
          this.itemsArr[i + 7]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 7]['filter'] = 'lowerLine'
          this.itemsArr[i + 7]['Unit'] = this.itemsArr[i + 6]['Unit']
          // total+=this.itemsArr[i+7]['QTY']


          this.itemsArr.splice(i + 8, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction_for_bed_block_under_abutment'])
          this.itemsArr[i + 8]['sno'] = x['sno']
          this.itemsArr[i + 8]['item_desc'] = 'Deduction for bed block under abutment'
          this.itemsArr[i + 8]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 8]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 8]['QTY']

          this.itemsArr.splice(i + 9, 0, { 'After_decuction': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 9]['sno'] = x['sno']
          this.itemsArr[i + 9]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 9]['filter'] = 'After_decuction'
          this.itemsArr[i + 9]['Unit'] = this.itemsArr[i + 9]['Unit']

          this.itemsArr.splice(i + 10, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 10]['sno'] = x['sno']
          this.itemsArr[i + 10]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 10]['filter'] = 'lowerLine'
          this.itemsArr[i + 10]['Unit'] = this.itemsArr[i + 1]['Unit']

          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.01_1300 & 2200' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Abutment'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Abutment'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Pier'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Pier'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']
          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Triangular_portion_of_pier'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Triangular portion of pier'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, { 'sub_total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'sub_total'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 1]['Unit']


          this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction_for_Bed_Block_under_abutment'])
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['item_desc'] = 'Deduction for Bed Block under abutment'
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 5]['QTY']

          this.itemsArr.splice(i + 6, 0, { 'After_decuction': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 6]['sno'] = x['sno']
          this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 6]['filter'] = 'After_decuction'
          this.itemsArr[i + 6]['Unit'] = this.itemsArr[i + 1]['Unit']

          this.itemsArr.splice(i + 7, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Wing_Wall'])
          this.itemsArr[i + 7]['sno'] = x['sno']
          this.itemsArr[i + 7]['item_desc'] = 'Wing Wall'
          this.itemsArr[i + 7]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 7]['filter'] = 'middleLine'
          total += this.itemsArr[i + 7]['QTY']

          this.itemsArr.splice(i + 8, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Parapet_Wall'])
          this.itemsArr[i + 8]['sno'] = x['sno']
          this.itemsArr[i + 8]['item_desc'] = 'Parapet Wall'
          this.itemsArr[i + 8]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 8]['filter'] = 'middleLine'
          total += this.itemsArr[i + 8]['QTY']

          this.itemsArr.splice(i + 9, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 9]['sno'] = x['sno'];
          this.itemsArr[i + 9]['ref_to_morth'] = x['ref_to_morth'];
          this.itemsArr[i + 9]['filter'] = 'lowerLine';
          this.itemsArr[i + 9]['Unit'] = this.itemsArr[i + 1]['Unit'];
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.05_1300' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Raft_cut_off_wall_long'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Raft cut off wall long'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Raft_cut_off_wall_Short'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Raft cut off wall Short'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']
          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cut_off_wall_long_for_appron'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Cut off wall long for appron'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']
          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno'];
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth'];
          this.itemsArr[i + 4]['filter'] = 'lowerLine';
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit'];

          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.05_1500, 1700 & 2200' && x['filter'] == 'uperLine') {
          // this.itemsArr.splice(i+1, 0, this.mesurement_obj[x['sno']+'_'+x['ref_to_morth']+'_'+'Abutment'])
          // this.itemsArr[i+1]['sno']=x['sno']
          // this.itemsArr[i+1]['item_desc']= 'RCC Grade M25'
          // this.itemsArr[i+1]['ref_to_morth']= x['ref_to_morth']
          // this.itemsArr[i+1]['filter']='middleLine'
          // total+= 0
          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Abutment'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Abutment'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']
          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Pier'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Pier'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']


          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno'];
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth'];
          this.itemsArr[i + 4]['filter'] = 'lowerLine';
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit'];

          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.02_1600' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@1.5% of Cuncrete of Deck Slab'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = '@1.5% of Cuncrete of Deck Slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.04_1600' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@1.5% of Cuncreet of Raft Slab'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = '@1.5% of Cuncreet of Raft Slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.08_2706 & 2200' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Abutment'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Abutment'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Pier'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Pier'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'wing_wall'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'wing wall'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'lowerLine'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.01_1500 &1600 1700' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_Deck_slab'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Deck slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          // this.itemsArr.splice(i+2, 0, this.mesurement_obj[x['sno']+'_'+x['ref_to_morth']+'_'+'Pier'])
          // this.itemsArr[i+2]['sno']=x['sno']
          // this.itemsArr[i+2]['item_desc']='Pier'
          // this.itemsArr[i+2]['ref_to_morth']=x['ref_to_morth']
          // this.itemsArr[i+2]['filter']='middleLine'
          // total+=this.itemsArr[i+2]['QTY']
          // this.itemsArr.splice(i+3, 0, this.mesurement_obj[x['sno']+'_'+x['ref_to_morth']+'_'+'wing_wall'])
          // this.itemsArr[i+3]['sno']=x['sno']
          // this.itemsArr[i+3]['item_desc']='wing wall'
          // this.itemsArr[i+3]['ref_to_morth']=x['ref_to_morth']
          // this.itemsArr[i+3]['filter']='middleLine'
          // total+=this.itemsArr[i+1]['QTY']
          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 1]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.01_710.1.4.of IRC:78 and 2504.2' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_filter_media'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'filtermedia'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 1]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '16.01_2503' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_Launching_Appron'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Launching Appron'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '16.04_2504' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_Pitching_on_slopes'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Pitching on slopes'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '16.05_2504' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_Filter_material'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Filter material '
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.04_2702' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_Wearing_coat_including_reinforcement'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Wearing coat including reinforcement'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.08_1500 , 1700 & 2100' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth']])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Wearing coat including reinforcement'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.011_1500 , 1600 , 1700 & 2704' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth']])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Wearing coat including reinforcement'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.03_304' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth']])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Wearing coat including reinforcement'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
      }
      if (this._bridge_obj['culvert_type'] == "CULVERT2") {

        if (x['sno'] + '_' + x['ref_to_morth'] == '12.01_304' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Bottom_Slab_and_Cut_off_Wall'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Bottom Slab and Cut off Wall'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Up_stream_Appron'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Up stream Appron'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Down_stream_Appron'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Down stream Appron'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'lowerLine'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.08_1500 , 1700 & 2100' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Below_Brick_work_for_bottom_slab'])

          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Below Brick work for bottom slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']


          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cut_off_wall_long_for_Bottom_Slab'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Cut off wall long for Bottom Slab'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']



          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cut_off_Wall_Short'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Cut off Wall Short'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'lowerLine'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.05_1300' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Below_bottom_slab'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Below bottom slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cut_off_wall_long'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Cut off wall long'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']


          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cut_off_wall_Short'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Cut off wall short'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'lowerLine'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.03_304' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Supply_and_Filling_of_sand_in_foundation'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Supply and Filling of sand in foundation'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0


        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.08_1500 , 1700 & 2100E' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'RCC_M-25_in_bottom_Slab'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'RCC M-25 in bottom Slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.04_1600' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'HYSD_Bar_Inforcement_in_foundation'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'HYSD Bar Inforcement in foundation'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0


        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.05_1500, 1700 & 2200' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Abutment2'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Abutment'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Pier2'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Pier'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Back_Wall2'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Back Wall'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'lowerLine'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit']
          // total = 0

          this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Haunch_for_Abutment_and_Pier'])
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['item_desc'] = 'Haunch for Abutment and Pier'
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'middleLine'
          total += this.itemsArr[i + 5]['QTY']

          this.itemsArr.splice(i + 6, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Haunch_for_Back/Wing_Wall'])
          this.itemsArr[i + 6]['sno'] = x['sno']
          this.itemsArr[i + 6]['item_desc'] = 'Haunch for Back/Wing Wall'
          this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 6]['filter'] = 'middleLine'
          total += this.itemsArr[i + 6]['QTY']

          this.itemsArr.splice(i + 7, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Parapet_Wall'])
          this.itemsArr[i + 7]['sno'] = x['sno']
          this.itemsArr[i + 7]['item_desc'] = 'Parapet Wall'
          this.itemsArr[i + 7]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 7]['filter'] = 'middleLine'
          total += this.itemsArr[i + 7]['QTY']

          this.itemsArr.splice(i + 8, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 8]['sno'] = x['sno']
          this.itemsArr[i + 8]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 8]['filter'] = 'lowerLine'
          this.itemsArr[i + 8]['Unit'] = this.itemsArr[i + 7]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.08_2706 & 2600' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Weep_Holes'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Weep Holes'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0


        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.6_Section 1600 & 2200' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'HYSD_Bar_Inforcement_in_Sub_Structure'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'HYSD Bar Inforcement in Sub Structure'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.10_2700' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'PCC_M_15_levelling'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'PCC M 15 levelling coarse below Approach slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.011_1500 , 1600 , 1700 & 2704' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'RCC_M-25_in_Approach_slab_including_reinforcement'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'RCC M-25 in Approach slab including reinforcement'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.04_2702' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'RCC_M-30_Wearing_coat_including_reinforcement'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'RCC M-30 Wearing coat including reinforcement'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.02_1600' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'HYSD_Steel_work_in_Super_Structure'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'HYSD Bar Inforcement in Sub Structure'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.021_2607' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Expansion_Joint_(Strip_Seal)'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Expansion Joint (Strip Seal)'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.09_2705' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Drainage_Spout'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Drainage Spout'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.016_800' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Painting_with_water_based_cement_paint_on_concrete_surface'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Painting with water based cement paint on concrete surface'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          // this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          // this.itemsArr[i + 2]['sno'] = x['sno']
          // this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          // this.itemsArr[i + 2]['filter'] = 'lowerLine'
          // this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          // total = 0

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'inv1'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = ''
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'inv2'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = ''
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'lowerLine'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.01_710.1.4.of IRC:78 and 2504.2' && x['filter'] == 'uperLine') {


          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Providing_and_laying_of_filter_media_behind_Abutment_to_a_thickness_of_not_less_than_600_mm'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and laying of filter media behind Abutment to a thickness of not less than 600 mm'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']



          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0


        } else if (x['sno'] + '_' + x['ref_to_morth'] == '16.01_2503' && x['filter'] == 'uperLine') {


          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Up_Stream_side'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Up Stream side'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Down_Stream_side'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Down Stream side'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']


          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '16.04_2504' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Providing_and_laying_boulder_Pitching_on_slopes'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and laying boulder Pitching on slopes'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Boulder_Appron_in_Toe_Wall'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Boulder Appron in Toe Wall'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']


          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '16.05_2504' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Filter_below_Pitching_on_Slope'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Filter below Pitching on Slope'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']
          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        }
      }
      if (this._bridge_obj['bridge_type'] == 'BOX_CULVERT') {

        if (x['sno'] + '_' + x['ref_to_morth'] == '12.01_304' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Box_Floor_slab'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Bottom Floor slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Wing_Wall'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Win1g Wall'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']


          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Floor_Appron'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Floor Appron'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Up_Stream_FLexiable_Appron'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'Up Stream FLexiable Appron'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total += this.itemsArr[i + 4]['QTY']

          this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Down_Stream_FLexiable_Appron'])
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['item_desc'] = 'Down Stream FLexiable Appron'
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'middleLine'
          total += this.itemsArr[i + 5]['QTY']

          this.itemsArr.splice(i + 6, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Curtain_Wall_type_1'])
          this.itemsArr[i + 6]['sno'] = x['sno']
          this.itemsArr[i + 6]['item_desc'] = 'Curtain Wall type 1'
          this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 6]['filter'] = 'middleLine'
          total += this.itemsArr[i + 6]['QTY']


          this.itemsArr.splice(i + 7, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Curtain_Wall_type_2'])
          this.itemsArr[i + 7]['sno'] = x['sno']
          this.itemsArr[i + 7]['item_desc'] = 'Curtain Wall type 2'
          this.itemsArr[i + 7]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 7]['filter'] = 'middleLine'
          total += this.itemsArr[i + 7]['QTY']


          this.itemsArr.splice(i + 8, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 8]['sno'] = x['sno']
          this.itemsArr[i + 8]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 8]['filter'] = 'lowerLine'
          this.itemsArr[i + 8]['Unit'] = this.itemsArr[i + 7]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.08_1200,1500,1700 A' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Box_FLoor_Slab'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Bottom Floor slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Wing_Wall'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Wing Wall'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Floor_Appron'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Floor Appron'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Curtain_Wall_type_1'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'Curtain Wall type 1'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total += this.itemsArr[i + 4]['QTY']

          this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Curtain_Wall_type_2'])
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['item_desc'] = 'Curtain Wall type 2'
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'middleLine'
          total += this.itemsArr[i + 5]['QTY']

          this.itemsArr.splice(i + 6, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '1st_step_of_Curtain_Wall_type_1'])
          this.itemsArr[i + 6]['sno'] = x['sno']
          this.itemsArr[i + 6]['item_desc'] = '1st step of Curtain Wall type 1'
          this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 6]['filter'] = 'middleLine'
          total += this.itemsArr[i + 6]['QTY']

          this.itemsArr.splice(i + 7, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '2nd_step_of_Curtain_Wall_type_1'])
          this.itemsArr[i + 7]['sno'] = x['sno']
          this.itemsArr[i + 7]['item_desc'] = '2nd step of Curtain Wall type 1'
          this.itemsArr[i + 7]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 7]['filter'] = 'middleLine'
          total += this.itemsArr[i + 7]['QTY']

          this.itemsArr.splice(i + 8, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '3rd_step_of_Curtain_Wall_type_1'])
          this.itemsArr[i + 8]['sno'] = x['sno']
          this.itemsArr[i + 8]['item_desc'] = '3rd step of Curtain Wall type 1'
          this.itemsArr[i + 8]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 8]['filter'] = 'middleLine'
          total += this.itemsArr[i + 8]['QTY']

          this.itemsArr.splice(i + 9, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '4th_step_of_Curtain_Wall_type_1'])
          this.itemsArr[i + 9]['sno'] = x['sno']
          this.itemsArr[i + 9]['item_desc'] = '4th step of Curtain Wall type 1'
          this.itemsArr[i + 9]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 9]['filter'] = 'middleLine'
          total += this.itemsArr[i + 9]['QTY']

          this.itemsArr.splice(i + 10, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '1st_step_of_Curtain_Wall_type_2'])
          this.itemsArr[i + 10]['sno'] = x['sno']
          this.itemsArr[i + 10]['item_desc'] = '1st step of Curtain Wall type 2'
          this.itemsArr[i + 10]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 10]['filter'] = 'middleLine'
          total += this.itemsArr[i + 10]['QTY']

          this.itemsArr.splice(i + 11, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '2nd_step_of_Curtain_Wall_type_2'])
          this.itemsArr[i + 11]['sno'] = x['sno']
          this.itemsArr[i + 11]['item_desc'] = '2nd step of Curtain Wall type 2'
          this.itemsArr[i + 11]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 11]['filter'] = 'middleLine'
          total += this.itemsArr[i + 11]['QTY']


          this.itemsArr.splice(i + 12, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '3rd_step_of_Curtain_Wall_type_2'])
          this.itemsArr[i + 12]['sno'] = x['sno']
          this.itemsArr[i + 12]['item_desc'] = '3rd step of Curtain Wall type 2'
          this.itemsArr[i + 12]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 12]['filter'] = 'middleLine'
          total += this.itemsArr[i + 12]['QTY']

          this.itemsArr.splice(i + 13, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 13]['sno'] = x['sno']
          this.itemsArr[i + 13]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 13]['filter'] = 'lowerLine'
          this.itemsArr[i + 13]['Unit'] = this.itemsArr[i + 12]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.08_1200,1500,1700 B' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '1st_step_of_wing_wall'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = '1st step of wing wall'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '2nd_step_of_wing_wall'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = '2nd step of wing wall'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '3rd_step_of_wing_wall'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = '3rd step of wing wall'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']


          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'lowerLine'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.08_1200,1500,1700 C' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Box_Floor_Slab'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Box Floor Slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']


          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.04_1600' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'HYSD_Steel_in_Foundation'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'HYSD Steel in Foundation'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '12.21_1207' && x['filter'] == 'uperLine') {


          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'sand_filling_beind_wall'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Sand Filling Behind Wall'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']


          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Sand_Filing_Behaind_Outer_Partition_Wall'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Sand Filing Behaind Outer Partition Wall'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']


          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Sand Filling Below Up Stream Flexible Appron'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Sand Filling Below Up Stream Flexible Appron'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Sand Filling Below Down Stream Flexible Appron'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'Sand Filling Below Down Stream Flexible Appron'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total += this.itemsArr[i + 4]['QTY']

          this.itemsArr.splice(i + 5, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'lowerLine'
          this.itemsArr[i + 5]['Unit'] = this.itemsArr[i + 4]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.05_1200,1500,1700F(P)' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '#a'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'A - Outer Partition Wall'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '#b'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'B - Inner Partition Wall'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '#c'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'c - Haunch of Inner and Outer Partion Wall'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '#d'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'D - Bracket for Approach Slab'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total += this.itemsArr[i + 4]['QTY']

          this.itemsArr.splice(i + 5, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'lowerLine'
          this.itemsArr[i + 5]['Unit'] = this.itemsArr[i + 4]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.05_1500,1700,2200B(P)' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '#1'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = '1st step of Wing Wall above bed level'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '#2'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = '2nd step of Wing Wall above bed level'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '#3'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = '3rd step of Wing Wall above bed level'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'lowerLine'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.08_2706 & 2200' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '#1'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'No of Weep Hole in outer Partion Wall'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '#2'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'No of Weep Hole in Inner Partion Wall'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '#3'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'No of Weep Hole in Wing Wall'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']

          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'lowerLine'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '13.06_1600' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'HYSD_Steel_in_Substructure'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'HYSD Steel in Substructure'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.01_1500,1600,1700' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Box_Top_Slab'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Box Top Slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Road_Kerb'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Road Kerb'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0




        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.10_2700' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'PCC_M_15_levelling'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'PCC M 15 levelling coarse below Approach slab'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.011_1500,1600,1700,2200' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@1'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'RCC Approach Slab M 30 Including Steel'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.06_2703,1500,1600,1700' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@1'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Pre Cast RCC Railing M 30 Including Steel'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.04_2702' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@1'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Wearing Coat  RCC  M 30 Including Steel '
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.09_2705' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@1'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Drainage Spout'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.18_2600' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@1'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Asphaltic Plug Expansion Joint'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '14.02_1600' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@1'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'HYSD Steel in Superstructure'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        } else if (x['sno'] + '_' + x['ref_to_morth'] == '16.01_1503' && x['filter'] == 'uperLine') {


          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@1'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Floor Appron'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@2'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Upstream Flexible Appron'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + '@3'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Downstream Flexible Appron'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total += this.itemsArr[i + 3]['QTY']


          this.itemsArr.splice(i + 4, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'lowerLine'
          this.itemsArr[i + 4]['Unit'] = this.itemsArr[i + 3]['Unit']
          total = 0

        }

      }
    }

    this.spinner.hide()
    this._items_foundation_arr = []
    this._items_sub_str_arr = []
    this._items_super_str_arr = []
    this._items_protection_work_arr = []



    this.itemsArr.map((x: any) => {
      if (
        x['sno'] + '_' + x['ref_to_morth'] == '12.01' + '_' + '304' ||
        x['sno'] + '_' + x['ref_to_morth'] == 12.04 + '_' + 2100 ||
        x['sno'] + '_' + x['ref_to_morth'] == 12.03 + '_' + 304 ||
        x['sno'] + '_' + x['ref_to_morth'] == 12.08 + '_' + '1500 , 1700 & 2100' ||
        x['sno'] + '_' + x['ref_to_morth'] == 12.05 + '_' + 1300 ||
        x['sno'] + '_' + x['ref_to_morth'] == 13.02 + '_' + '1300 & 2200' ||
        x['sno'] + '_' + x['ref_to_morth'] == 12.04 + '_' + '1600' ||
        x['sno'] + '_' + x['ref_to_morth'] == 12.08 + '_' + '1200,1500,1700 A' ||
        x['sno'] + '_' + x['ref_to_morth'] == 12.08 + '_' + '1200,1500,1700 B' ||
        x['sno'] + '_' + x['ref_to_morth'] == 12.08 + '_' + '1200,1500,1700 C' ||

        x['sno'] + '_' + x['ref_to_morth'] == 12.21 + '_' + '1207'
      ) {
        this._items_foundation_arr.push(x)
      } else if (
        x['sno'] + '_' + x['ref_to_morth'] == 13.01 + '_' + '1300 & 2200' ||
        x['sno'] + '_' + x['ref_to_morth'] == 13.05 + '_' + '1500, 1700 & 2200' ||
        x['sno'] + '_' + x['ref_to_morth'] == 13.05 + '_' + '1200,1500,1700F(P)' ||
        x['sno'] + '_' + x['ref_to_morth'] == 13.05 + '_' + '1500,1700,2200B(P)' ||
        x['sno'] + '_' + x['ref_to_morth'] == 13.06 + '_' + '1600' ||
        x['sno'] + '_' + x['ref_to_morth'] == 13.08 + '_' + '2706 & 2600' ||
        x['sno'] + '_' + x['ref_to_morth'] == 13.6 + '_' + 'Section 1600 & 2200' ||
        x['sno'] + '_' + x['ref_to_morth'] == 13.06 + '_' + 'Section 1600 & 2200'
      ) {
        console.log(' item arrarsgffggf', x)

        this._items_sub_str_arr.push(x)
      } else if (
        x['sno'] + '_' + x['ref_to_morth'] == '14.01' + '_' + '1500 &1600 1700' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.011 + '_' + '1500 , 1600 , 1700 & 2704' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.04 + '_' + 2702 ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.02 + '_' + 1600 ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.021 + '_' + '2607' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.09 + '_' + '2705' ||
        x['sno'] + '_' + x['ref_to_morth'] == 13.08 + '_' + '2706 & 2200' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.10 + '_' + '2700' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.011 + '_' + '1500 , 1600 , 1700 & 2704' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.01 + '_' + '1500,1600,1700' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.04 + '_' + '2702' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.02 + '_' + '1600' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.021 + '_' + '2607' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.09 + '_' + '2705' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.011 + '_' + '1500,1600,1700,2200' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.06 + '_' + '2703,1500,1600,1700' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.18 + '_' + '2600' ||
        x['sno'] + '_' + x['ref_to_morth'] == 13.08 + '_' + '2706 & 2200' ||
        x['sno'] + '_' + x['ref_to_morth'] == 14.016 + '_' + '800'
      ) {
        console.log(' item arrarsgffggfsu', x['sno'] + '_' + x['ref_to_morth'])

        this._items_super_str_arr.push(x)
      } else if (
        x['sno'] + '_' + x['ref_to_morth'] == '13.01' + '_' + '710.1.4.of IRC:78 and 2504.2' ||
        x['sno'] + '_' + x['ref_to_morth'] == 16 + '_' + '2503' ||
        x['sno'] + '_' + x['ref_to_morth'] == 16.01 + '_' + 2503 ||
        x['sno'] + '_' + x['ref_to_morth'] == '16.01' + '_' + '2503' ||
        x['sno'] + '_' + x['ref_to_morth'] == 16.01 + '_' + '1503' ||
        x['sno'] + '_' + x['ref_to_morth'] == 16.04 + '_' + '2504' ||
        x['sno'] + '_' + x['ref_to_morth'] == 16.04 + '_' + 2504 ||
        x['sno'] + '_' + x['ref_to_morth'] == '16.04' + '_' + '2504' ||
        x['sno'] + '_' + x['ref_to_morth'] == 16.04 + '_' + '2504' ||
        x['sno'] + '_' + x['ref_to_morth'] == 16.05 + '_' + '2504' ||
        x['sno'] + '_' + x['ref_to_morth'] == 16.05 + '_' + 2504
      ) {
        console.log('ss', x['sno'] + '_' + x['ref_to_morth'])
        this._items_protection_work_arr.push(x)
      } else {
        console.log('extraaaaaaa', x['sno'] + '_' + x['ref_to_morth'])
      }
    })

    this.all_items = [this._items_foundation_arr, this._items_sub_str_arr, this._items_super_str_arr, this._items_protection_work_arr]

    console.log('all', this.all_items)
  }






  itemsForRoad: any
  async mapDataForRoad() {
    await this.getRoadItems()
    console.log('item Road ===>', this.itemsArr);
    let total = 0
    for (let i = 0; i < this.itemsArr.length; i++) {
      let x = this.itemsArr[i]
      if (this._road_obj['road_technique'] == 'OLD' && this._road_obj['road_location'] == 'APPR') {
        if (x['sno'] + '_' + x['ref_to_morth'] == '3.16_305' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'oneSide'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'One Side'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'otherSide'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Other side'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']


          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction For GSB Grade1'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Deduction For GSB Grade 1'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 3]['QTY']


          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction For WMM Grade2'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'Deduction For WMM Grade 2'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']


          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 4]['QTY']

          this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction For WMM Grade3'])
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['item_desc'] = 'Deduction For WMM Grade3'
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 5]['QTY']

          this.itemsArr.splice(i + 6, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 6]['sno'] = x['sno']
          this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 6]['filter'] = 'lowerLine'
          this.itemsArr[i + 6]['Unit'] = this.itemsArr[i + 5]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '4.1_401' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Construction_of_Granual_Sub_Base_Grade_1'])

          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Construction of granular sub-base by providing close graded Material, mixing in a mechanical mix plant at OMC, carriage of mixed Material to work site, spreading in uniform layers with motor grader on prepared surface and compacting with vibratory power roller to achieve the desired density, complete as per clause 401'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '4.11_404' && x['filter'] == 'uperLine') {


          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Water bound Macadam Grading 2'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Water bound Macadam Grading 2'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Water bound Macadam Grading 3'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Water bound Macadam Grading 3'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.1_502' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Providing and applying primer coat with SS1 grade'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and applying primer coat with SS1 grade bitumen emulsion on prepared surface of granular Base including clearing of road surface and spraying primer at the rate of 0.70 to 1.00 kg/sqm using mechanical means.'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.2_503' && x['filter'] == 'uperLine') {


          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Tack Coat on Bituminous surfaces'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and applying tack coat with bitumen emulsion using emulsion pressure distributor at the rate of 0.30 to 0.35 kg per sqm on the prepared bituminous surface cleaned with mechanical broom.'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.7_510' && x['filter'] == 'uperLine') {


          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Providing, laying and rolling of open - graded premix'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing, laying and rolling of open - graded premix surfacing of 20 mm thickness composed of 13.02 mm to 5.6 mm aggregates using Viscocity grade bitumen to required line, grade and level to serve as wearing course on a previously prepared base, including mixing in a suitable hot mix plant of appropriate capacity not less than 200 tonnes/hour, laying and rolling with a smooth wheeled roller, finished to required level and grades.'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.9_511' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Case - II : Type B'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Case - II : Type B'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '8.5_801' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Providing and erecting direction and place'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and erecting direction and place identification retro-reflectorised sign as per IRC :67 made of high intensity grade sheeting vide clause 801.3, fixed over aluminium sheeting, 2 mm thick or Aluminum composite material sheet with overall thickness of 4mm with area not exceeding 0.9 sqm fixed over back support frame of min 35 x 35 x 3mm Angle mounted on a mild steel circular pipe 65 NB, firmly fixed to the ground by means of properly designed foundation with M25 grade cement concrete 45 x 45 x 60 cm, 60 cm below ground level as per approved drawing'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '8.14_804' && x['filter'] == 'uperLine') {
          console.log('okk i m d dd31')

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Ordinary Kilometer Stone'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Ordinary Kilometer Stone'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']


          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Hecto Metre Stone'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Hecto Metre Stone'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '8.13_803' && x['filter'] == 'uperLine') {
          console.log('okk i m d dd312', this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'center Line'])

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'center Line'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'center Line'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']


          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Edge Line'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Edge Line'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0
        }



        this.itemsForRoad = []
        console.log(' item arr', this.itemsArr)
        this.itemsArr.map((x: any) => {
          if (
            x['sno'] + '_' + x['ref_to_morth'] == '3.16' + '_' + '305' ||
            x['sno'] + '_' + x['ref_to_morth'] == '4.1' + '_' + '401' ||
            x['sno'] + '_' + x['ref_to_morth'] == '4.11' + '_' + '404' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.1' + '_' + '502' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.2' + '_' + '503' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.7' + '_' + '510' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.9' + '_' + '511' ||
            x['sno'] + '_' + x['ref_to_morth'] == '8.5' + '_' + '801' ||
            x['sno'] + '_' + x['ref_to_morth'] == '8.14' + '_' + '804' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '4.7' + '_' + '403' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '8.5' + '_' + '501' ||
            x['sno'] + '_' + x['ref_to_morth'] == '8.13' + '_' + '803'
          ) {
            console.log('i m here .....')
            this.itemsForRoad.push(x)
          }
        })
      }
      else if (this._road_obj['road_technique'] == 'NEW' && this._road_obj['road_location'] == 'ADD_APPR') {
        if (x['sno'] + '_' + x['ref_to_morth'] == '3.16_305' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'oneSide'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'One Side'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'otherSide'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Other side'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']

          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction For CTSB'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'Deduction For CTSB'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 4]['QTY']

          this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction For CTB'])
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['item_desc'] = 'Deduction For CTB'
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 5]['QTY']


          this.itemsArr.splice(i + 6, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'GSB Grade1'])
          this.itemsArr[i + 6]['sno'] = x['sno']
          this.itemsArr[i + 6]['item_desc'] = 'GSB Grade1'
          this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 6]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 6]['QTY']


          this.itemsArr.splice(i + 7, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 7]['sno'] = x['sno']
          this.itemsArr[i + 7]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 7]['filter'] = 'lowerLine'
          this.itemsArr[i + 7]['Unit'] = this.itemsArr[i + 5]['Unit']
          total = 0




          // this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction For WMM Grade3'])
          // this.itemsArr[i + 5]['sno'] = x['sno']
          // this.itemsArr[i + 5]['item_desc'] = 'Launching Appron'
          // this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          // this.itemsArr[i + 5]['filter'] = 'middleLine'
          // total += this.itemsArr[i + 5]['QTY']

          // this.itemsArr.splice(i + 6, 0, { 'Total': Number(total.toFixed(2)) } as any)
          // this.itemsArr[i + 6]['sno'] = x['sno']
          // this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          // this.itemsArr[i + 6]['filter'] = 'lowerLine'
          // this.itemsArr[i + 6]['Unit'] = this.itemsArr[i + 5]['Unit']
          // total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '4.7_403' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cement Treated Subbase'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Cement Treated Crushed Rock or Combination as per clause 403 and table 400.4 in Sub base.'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cement Treated base'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Cement Treated Crushed Rock or Combination as per clause 403 and table 400.4 in Sub base.'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0

        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.2_503' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Tack Coat'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and applying tack coat with bitumen emulsion using emulsion pressure distributor at the rate of 0.30 to 0.35 kg per sqm on the prepared bituminous surface cleaned with mechanical broom'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0

          // this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cement Treated base'])
          // this.itemsArr[i + 2]['sno'] = x['sno']
          // this.itemsArr[i + 2]['item_desc'] = 'Cement Treated Crushed Rock or Combination as per clause 403 and table 400.4 in Sub base.'
          // this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          // this.itemsArr[i + 2]['filter'] = 'middleLine'
          // total += this.itemsArr[i + 2]['QTY']

        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.7_510' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Open - Graded Premix Surfacing'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing, laying and rolling of open - graded premix surfacing of 20 mm thickness composed of 13.02 mm to 5.6 mm aggregates using Viscocity grade bitumen to required line, grade and level to serve as wearing course on a previously prepared base, including mixing in a suitable hot mix plant of appropriate capacity not less than 200 tonnes/hour, laying and rolling with a smooth wheeled roller, finished to required level and grades.'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.9_511' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Seal Coat'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Case - II : Type B '
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        } else if (x['sno'] + '_' + x['ref_to_morth'] == '8.5_801' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Direction and Place Identification Signs upto 0.9'])

          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and erecting direction and place identification retro-reflectorised sign as per IRC :67 made of high intensity grade sheeting vide clause 801.3, fixed over aluminium sheeting, 2 mm thick or Aluminum composite material sheet with overall thickness of 4mm with area not exceeding 0.9 sqm fixed over back support frame of min 35 x 35 x 3mm Angle mounted on a mild steel circular pipe 65 NB, firmly fixed to the ground by means of properly designed foundation with M25 grade cement concrete 45 x 45 x 60 cm, 60 cm below ground level as per approved drawing '
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }

        this.itemsForRoad = []
        console.log(' item arr', this.itemsArr)
        this.itemsArr.map((x: any) => {
          if (
            x['sno'] + '_' + x['ref_to_morth'] == '3.16' + '_' + '305' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '4.1' + '_' + '401' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '4.11' + '_' + '404' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '5.1' + '_' + '502' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.2' + '_' + '503' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.7' + '_' + '510' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.9' + '_' + '511' ||
            x['sno'] + '_' + x['ref_to_morth'] == '8.5' + '_' + '801' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '8.14' + '_' + '804' ||
            x['sno'] + '_' + x['ref_to_morth'] == '4.7' + '_' + '403'
            // x['sno'] + '_' + x['ref_to_morth'] == '8.5' + '_' + '501' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '8.13' + '_' + '803'
          ) {
            console.log('i m here .....')
            this.itemsForRoad.push(x)
          }
        })
      }
      else if (this._road_obj['road_technique'] == 'OLD' && this._road_obj['road_location'] == 'ADD_APPR') {

        if (x['sno'] + '_' + x['ref_to_morth'] == '3.16_305' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'oneSide'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'One Side'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'otherSide'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Other side'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']

          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'GSB Grade 1'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'GSB Grade 1'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 4]['QTY']

          this.itemsArr.splice(i + 5, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction For GSB Grade1'])
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['item_desc'] = 'Deduction For CTB'
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 5]['QTY']

          this.itemsArr.splice(i + 6, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 6]['sno'] = x['sno']
          this.itemsArr[i + 6]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 6]['filter'] = 'lowerLine'
          this.itemsArr[i + 6]['Unit'] = this.itemsArr[i + 5]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '4.1_401' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Construction_of_Granual_Sub_Base_Grade_1'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Construction of granular sub-base by providing close graded Material, mixing in a mechanical mix plant at OMC, carriage of mixed Material to work site, spreading in uniform layers with motor grader on prepared surface and compacting with vibratory power roller to achieve the desired density, complete as per clause 401(G1)'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '4.11_404' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Providing, laying....Grading 2'])

          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Construction of granular sub-base by providing close graded Material, mixing in a mechanical mix plant at OMC, carriage of mixed Material to work site, spreading in uniform layers with motor grader on prepared surface and compacting with vibratory power roller to achieve the desired density, complete as per clause 401(G1)          '
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']


          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Providing, laying.... Grading 3'])

          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Other side'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0

        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.1_502' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Prime Coat over WMM/WBM'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and applying primer coat with SS1 grade bitumen emulsion on prepared surface of granular Base including clearing of road surface and spraying primer at the rate of 0.70 to 1.00 kg/sqm using mechanical means.'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.2_503' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Tack Coat on Bituminous surfaces'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and applying primer coat with SS1 grade bitumen emulsion on prepared surface of granular Base including clearing of road surface and spraying primer at the rate of 0.70 to 1.00 kg/sqm using mechanical means.'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.7_510' && x['filter'] == 'uperLine') {
          console.log('whyy')
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Open - Graded Premix Surfacing'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing, laying and rolling of open - graded premix surfacing of 20 mm thickness composed of 13.02 mm to 5.6 mm aggregates using Viscocity grade bitumen to required line, grade and level to serve as wearing course on a previously prepared base, including mixing in a suitable hot mix plant of appropriate capacity not less than 200 tonnes/hour, laying and rolling with a smooth wheeled roller, finished to required level and grades.'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.9_511' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Case - II : Type B'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Case - II : Type B'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }

        this.itemsForRoad = []
        console.log(' item arr', this.itemsArr)
        this.itemsArr.map((x: any) => {
          if (
            x['sno'] + '_' + x['ref_to_morth'] == '3.16' + '_' + '305' ||
            x['sno'] + '_' + x['ref_to_morth'] == '4.1' + '_' + '401' ||
            x['sno'] + '_' + x['ref_to_morth'] == '4.11' + '_' + '404' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.1' + '_' + '502' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.2' + '_' + '503' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.7' + '_' + '510' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.9' + '_' + '511'
            // x['sno'] + '_' + x['ref_to_morth'] == '8.5' + '_' + '801' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '8.14' + '_' + '804' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '4.7' + '_' + '403' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '8.5' + '_' + '501' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '8.13' + '_' + '803'
          ) {
            console.log('i m here .....')
            this.itemsForRoad.push(x)
          }
        })
      }
      else if (this._road_obj['road_technique'] == 'NEW' && this._road_obj['road_location'] == 'APPR') {
        if (x['sno'] + '_' + x['ref_to_morth'] == '3.16_305' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'oneSide'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'One Side'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'otherSide'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Other side'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']


          this.itemsArr.splice(i + 3, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction For CTSB'])
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['item_desc'] = 'Deduction For CTSB'
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'middleLine'
          total -= this.itemsArr[i + 3]['QTY']


          this.itemsArr.splice(i + 4, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Deduction For CTB'])
          this.itemsArr[i + 4]['sno'] = x['sno']
          this.itemsArr[i + 4]['item_desc'] = 'Deduction For CTB'
          this.itemsArr[i + 4]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 4]['filter'] = 'middleLine'
          total += this.itemsArr[i + 4]['QTY']

          this.itemsArr.splice(i + 5, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 5]['sno'] = x['sno']
          this.itemsArr[i + 5]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 5]['filter'] = 'lowerLine'
          this.itemsArr[i + 5]['Unit'] = this.itemsArr[i + 4]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '4.7_403' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cement treated Subbase (G.S.B. + 2% Cement)'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Cement treated Subbase (G.S.B. + 2% Cement)'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Cement treated base (G.S.B. + 2% Cement)'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Providing, laying and spreading Material on a prepared sub grade, adding the designed quantity of cement to the spread Material, mixing in place with rotavator, grading with the motor grader and compacting with the road roller at OMC to achieve the desired unconfined compressive strength and to form a layer of base. (W.M.M. + 4% Cement)'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0

        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.2_503' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Tack Coat on Bituminous surfaces'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and applying tack coat with bitumen emulsion using emulsion pressure distributor at the rate of 0.30 to 0.35 kg per sqm on the prepared bituminous surface cleaned with mechanical broom.'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.7_510' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Open - Graded Premix Surfacing'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing, laying and rolling of open - graded premix surfacing of 20 mm thickness composed of 13.02 mm to 5.6 mm aggregates using Viscocity grade bitumen to required line, grade and level to serve as wearing course on a previously prepared base, including mixing in a suitable hot mix plant of appropriate capacity not less than 200 tonnes/hour, laying and rolling with a smooth wheeled roller, finished to required level and grades.'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '5.9_511' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Seal Coat_Case - II : Type B'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Case - II : Type B'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '8.5_801' && x['filter'] == 'uperLine') {

          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Direction and Place Identification Signs upto 0.9'])

          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Providing and erecting direction and place identification retro-reflectorised sign as per IRC :67 made of high intensity grade sheeting vide clause 801.3, fixed over aluminium sheeting, 2 mm thick or Aluminum composite material sheet with overall thickness of 4mm with area not exceeding 0.9 sqm fixed over back support frame of min 35 x 35 x 3mm Angle mounted on a mild steel circular pipe 65 NB, firmly fixed to the ground by means of properly designed foundation with M25 grade cement concrete 45 x 45 x 60 cm, 60 cm below ground level as per approved drawing '
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'lowerLine'
          this.itemsArr[i + 2]['Unit'] = this.itemsArr[i + 1]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '8.14_804' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Ordinary Kilometer Stone'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Ordinary Kilometer Stone'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']

          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Hecto Metre Stone'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Hecto Metre Stone'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0
        }
        else if (x['sno'] + '_' + x['ref_to_morth'] == '8.13_803' && x['filter'] == 'uperLine') {
          this.itemsArr.splice(i + 1, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'center Line'])
          this.itemsArr[i + 1]['sno'] = x['sno']
          this.itemsArr[i + 1]['item_desc'] = 'Ordinary Kilometer Stone'
          this.itemsArr[i + 1]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 1]['filter'] = 'middleLine'
          total += this.itemsArr[i + 1]['QTY']


          this.itemsArr.splice(i + 2, 0, this.mesurement_obj[x['sno'] + '_' + x['ref_to_morth'] + '_' + 'Edge Line'])
          this.itemsArr[i + 2]['sno'] = x['sno']
          this.itemsArr[i + 2]['item_desc'] = 'Edge Line'
          this.itemsArr[i + 2]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 2]['filter'] = 'middleLine'
          total += this.itemsArr[i + 2]['QTY']

          this.itemsArr.splice(i + 3, 0, { 'Total': Number(total.toFixed(2)) } as any)
          this.itemsArr[i + 3]['sno'] = x['sno']
          this.itemsArr[i + 3]['ref_to_morth'] = x['ref_to_morth']
          this.itemsArr[i + 3]['filter'] = 'lowerLine'
          this.itemsArr[i + 3]['Unit'] = this.itemsArr[i + 2]['Unit']
          total = 0
        }



        this.itemsForRoad = []
        console.log(' item arr', this.itemsArr)
        this.itemsArr.map((x: any) => {
          if (
            x['sno'] + '_' + x['ref_to_morth'] == '3.16' + '_' + '305' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '4.1' + '_' + '401' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '4.11' + '_' + '404' ||
            // x['sno'] + '_' + x['ref_to_morth'] == '5.1' + '_' + '502' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.2' + '_' + '503' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.7' + '_' + '510' ||
            x['sno'] + '_' + x['ref_to_morth'] == '5.9' + '_' + '511' ||
            x['sno'] + '_' + x['ref_to_morth'] == '8.5' + '_' + '801' ||
            x['sno'] + '_' + x['ref_to_morth'] == '8.14' + '_' + '804' ||
            x['sno'] + '_' + x['ref_to_morth'] == '4.7' + '_' + '403' ||
            x['sno'] + '_' + x['ref_to_morth'] == '8.5' + '_' + '501' ||
            x['sno'] + '_' + x['ref_to_morth'] == '8.13' + '_' + '803'
          ) {
            console.log('i m here .....')
            this.itemsForRoad.push(x)
          }
        })
      }
    }


    this.all_items = [this.itemsForRoad]
  }

  _culvert_obj: any = {}
  _bridge_obj: any = { bridge_location: undefined }
  _road_obj: any = { road_location: undefined }

  async bridgeInput() {

    console.log('==>inputssss finalll', this._foundation_obj, "1") //these are the values taken from the user
    console.log(this._sub_str_obj, "2")
    console.log(this._super_str_obj, "3")
    console.log(this._protection_work_obj, "4")

    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['est_id'] = this.Obj['est_id_p']
    obj['fundation'] = JSON.stringify(this._foundation_obj)
    obj['sub_structure'] = JSON.stringify(this._sub_str_obj)
    obj['protection_work'] = JSON.stringify(this._protection_work_obj)
    obj['super_structure'] = JSON.stringify(this._super_str_obj)
    obj['bridge_type'] = this._bridge_obj['bridge_location']
    obj['estimation_type'] = this.estimation_type
    obj['bridge_location'] = this._bridge_obj['bridge_type'] == "CULVERT" ? this._bridge_obj['culvert_type'] : this._bridge_obj['bridge_type']
    obj['life_cycle_status'] = 'ACTIVE'
    obj['create_user_id'] = this.ebillUser.le_id
    console.log('finallll', obj)
    if (this.bridgeUpdate) {
      //  await this.updateBridgeInput()
      return
    }
    let resp = await this.estimateService.bridgeInput(obj)
    if (resp['error'] == false) {

      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }

  async updateBridgeInput() {
    console.log('updating inputs ......')
    var obb: any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['id'] = this.Obj['est_id_p']
    obb['update_user_id'] = this.ebillUser.user_id
    obb['status'] = 'UPDATED'
    var resp2 = await this.estimateService.updateLifeCycleStatusForBridgeInput(obb)
    if (resp2['error'] == false) {
      console.log('updates status')
      let obj: any = {}
      obj['b_acct_id'] = this.b_acct_id
      obj['est_id'] = this.Obj['est_id_p']
      obj['fundation'] = JSON.stringify(this._foundation_obj)
      obj['sub_structure'] = JSON.stringify(this._sub_str_obj)
      obj['protection_work'] = JSON.stringify(this._protection_work_obj)
      obj['super_structure'] = JSON.stringify(this._super_str_obj)
      obj['bridge_type'] = this._bridge_obj['bridge_location']
      obj['bridge_location'] = this._bridge_obj['bridge_type']
      obj['life_cycle_status'] = 'ACTIVE'
      obj['create_user_id'] = this.ebillUser.le_id
      console.log(obj)
      let resp = await this.estimateService.bridgeInput(obj)
      if (resp['error'] == false) {

        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error(resp['data'])
      }
    }
  }

  async roadInput() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['est_id'] = this.Obj['est_id_p']
    obj['fundation'] = JSON.stringify(this._input_approach_road_obj)
    obj['sub_structure'] = ''
    obj['protection_work'] = ''
    obj['super_structure'] = ''
    obj['bridge_type'] = this._road_obj['road_location']
    obj['bridge_location'] = this._road_obj['road_technique']
    obj['life_cycle_status'] = 'ACTIVE'
    obj['create_user_id'] = this.ebillUser.le_id
    obj['estimation_type'] = this.estimation_type

    console.log(obj)
    this.spinner.show();
    let resp = await this.estimateService.bridgeInput(obj)
    if (resp['error'] == false) {

      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }


  /*--------------------------------------Input for Bridge E-------------------------------------------*/
  /*--------------------------------------Input Pdf print for Bridge S-------------------------------------------*/
  async inputPrint() {

    console.log('tech t', this._road_obj['road_technique'], 'type', this._road_obj['road_location']);



    var txt = "INPUTS -";
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var arr = []
        let s = txt + "     Page No. - " + currentPage
        var obj = { text: s, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    var tbl: any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [370, 373],

        body: [



        ]
      }
    };

    var arr = [];
    arr.push({ text: "Work/Youjna", bold: true });
    arr.push(this.Obj['work_name']);
    tbl.table.body.push(arr);


    if (this._road_obj['road_location']) {
      var arr = [];
      arr.push({ text: "Type of Road", bold: true });
      arr.push({ text: this._road_obj['road_location'] });
      tbl.table.body.push(arr);


      var arr = [];
      arr.push({ text: "Type of Road Technique", bold: true });
      arr.push({ text: this._road_obj['road_technique'] });
      tbl.table.body.push(arr);

      var arr = [];
      arr.push({ text: "Road Name", bold: true });
      arr.push('');
      tbl.table.body.push(arr);
    } else {
      var arr = [];
      arr.push({ text: "Type of Bridge", bold: true });
      arr.push({ text: this._bridge_obj['bridge_location'] });
      tbl.table.body.push(arr);

      var arr = [];
      arr.push({ text: "Type of bridge type", bold: true });
      arr.push({ text: this._bridge_obj['bridge_type'] });
      tbl.table.body.push(arr);
    }

    var arr = [];
    arr.push({ text: "Janpad/District", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Chetra/Zone", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Vibhag Ka Naam ", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Length", bold: true });
    arr.push(0);
    tbl.table.body.push(arr);





    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });


    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(tbl);
    dd.content.push({ text: " " });

    // dd.content.push({
    //   canvas: [
    //     { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
    //   ],
    // });

    var tbl1: any = {

      fontSize: 13,
      table: {

        headerRows: 0,
        widths: ['*'],

        body: [



        ]
      }
    };
    if (this._re_culvert_foundation_arr.length != 0) {
      var arr = [];
      arr.push({ text: "For Foundation ", bold: true, alignment: "center" });
      tbl1.table.body.push(arr);
      dd.content.push(tbl1);

      var tbl2 = {
        fontSize: 10,
        table: {
          headerRows: 1,
          widths: [182, 180, 182, 181],
          body: [

          ],
        },
      };
      dd.content.push(tbl2);
      let x: any = {}
      for (var i = 0; i < this._re_culvert_foundation_arr.length; i++) {
        x = this._re_culvert_foundation_arr[i]
        var arr = [];
        if (x[i + 1] == 'extra_item' && this._re_culvert_foundation_obj[x[i]] == undefined) {
          arr.push({ text: x[i].split('_').join(" "), bold: true, alignment: "center", colSpan: 4 });
          arr.push({ text: '', alignment: "right" });
          arr.push({ text: '', alignment: "left" });
          arr.push({ text: '', alignment: "right" });
        } else {
          arr.push({ text: x[i].split('_').join(" "), alignment: "left" });
          arr.push({ text: this._re_culvert_foundation_obj[x[i]], alignment: "right" });
          arr.push({ text: x[i + 1].split('_').join(" "), alignment: "left" });
          arr.push({ text: this._re_culvert_foundation_obj[x[i + 1]], alignment: "right" });
        }
        dd.content[dd.content.length - 1].table.body.push(arr);

      }
      dd.content.push({ text: " " });
    }


    var tbl3: any = {

      fontSize: 13,
      table: {

        headerRows: 0,
        widths: ['*'],
        body: [
        ]
      }
    };

    if (this._re_culvert_sub_str_arr.length != 0) {
      var arr = [];
      arr.push({ text: "For Sub - Structure", bold: true, alignment: "center" });
      tbl3.table.body.push(arr);
      dd.content.push(tbl3);

      var tbl4 = {
        fontSize: 10,
        table: {
          headerRows: 1,
          widths: [182, 180, 182, 181],
          body: [

          ],
        },
      };


      dd.content.push(tbl4);

      let y: any = {}
      for (var i = 0; i < this._re_culvert_sub_str_arr.length; i++) {
        y = this._re_culvert_sub_str_arr[i]
        var arr = [];
        if (y[i + 1] == 'extra_item' && this._re_culvert_sub_str_obj[y[i]] == undefined) {
          arr.push({ text: y[i].split('_').join(" "), bold: true, alignment: "center", colSpan: 4 });
          arr.push({ text: '', alignment: "right" });
          arr.push({ text: '', alignment: "left" });
          arr.push({ text: '', alignment: "right" });
        } else {
          arr.push({ text: y[i].split('_').join(" "), alignment: "left" });
          arr.push({ text: this._re_culvert_sub_str_obj[y[i]], alignment: "right" });
          if (y[i + 1] == 'extra_item') {
            y[i + 1] = '_'
          }
          arr.push({ text: y[i + 1].split('_').join(" "), alignment: "left" });
          arr.push({ text: this._re_culvert_sub_str_obj[y[i + 1]], alignment: "right" });
        }
        dd.content[dd.content.length - 1].table.body.push(arr);

      }
      dd.content.push({ text: " " });
    }

    var tbl5: any = {

      fontSize: 13,
      table: {

        headerRows: 0,
        widths: ['*'],

        body: [



        ]
      }
    };

    if (this._re_culvert_super_str_arr.length != 0) {
      var arr = [];
      arr.push({ text: "For Super - Structure", bold: true, alignment: "center" });
      tbl5.table.body.push(arr);
      dd.content.push(tbl5);

      var tbl6 = {
        fontSize: 10,
        table: {
          headerRows: 1,
          widths: [182, 180, 182, 181],
          body: [

          ],
        },
      };


      dd.content.push(tbl6);

      let z: any = {}
      for (var i = 0; i < this._re_culvert_super_str_arr.length; i++) {
        z = this._re_culvert_super_str_arr[i]
        var arr = [];
        if (z[i + 1] == 'extra_item' && this._re_culvert_super_str_obj[z[i]] == undefined) {
          arr.push({ text: z[i].split('_').join(" "), bold: true, alignment: "center", colSpan: 4 });
          arr.push({ text: '', alignment: "right" });
          arr.push({ text: '', alignment: "left" });
          arr.push({ text: '', alignment: "right" });
        } else {
          arr.push({ text: z[i].split('_').join(" "), alignment: "left" });
          arr.push({ text: this._re_culvert_super_str_obj[z[i]], alignment: "right" });
          if (z[i + 1] == 'extra_item') {
            z[i + 1] = '_'
          }
          arr.push({ text: z[i + 1].split('_').join(" "), alignment: "left" });
          arr.push({ text: this._re_culvert_super_str_obj[z[i + 1]], alignment: "right" });
        }
        dd.content[dd.content.length - 1].table.body.push(arr);
      }
      dd.content.push({ text: " " });
    }


    var tbl7: any = {

      fontSize: 13,
      table: {

        headerRows: 0,
        widths: ['*'],

        body: [



        ]
      }
    };

    if (this._re_culvert_protection_arr.length != 0) {
      var arr = [];
      arr.push({ text: "For Protection Work", bold: true, alignment: "center" });
      tbl7.table.body.push(arr);
      dd.content.push(tbl7);

      var tbl8 = {
        fontSize: 10,
        table: {
          headerRows: 1,
          widths: [182, 180, 182, 181],
          body: [

          ],
        },
      };


      dd.content.push(tbl8);

      let w: any = {}
      for (var i = 0; i < this._re_culvert_protection_arr.length; i++) {
        w = this._re_culvert_protection_arr[i]
        var arr = [];
        if (w[i + 1] == 'extra_item' && this._re_culvert_protection_work_obj[w[i]] == undefined) {
          arr.push({ text: w[i].split('_').join(" "), bold: true, alignment: "center", colSpan: 4 });
          arr.push({ text: '', alignment: "right" });
          arr.push({ text: '', alignment: "left" });
          arr.push({ text: '', alignment: "right" });
        } else {
          arr.push({ text: w[i].split('_').join(" "), alignment: "left" });
          arr.push({ text: this._re_culvert_protection_work_obj[w[i]], alignment: "right" });
          if (w[i + 1] == 'extra_item') {
            w[i + 1] = '_'
          }
          arr.push({ text: w[i + 1].split('_').join(" "), alignment: "left" });
          arr.push({ text: this._re_culvert_protection_work_obj[w[i + 1]], alignment: "right" });
        }
        dd.content[dd.content.length - 1].table.body.push(arr);

      }
    }




    pdfMake.createPdf(dd).download("Inputs");

  }
  /*--------------------------------------Input Pdf print for Road--------------------------------------------*/

  async inputPrintForRoad() {
    console.log('tech t', this._road_obj['road_technique'], 'type', this._road_obj['road_location']);


    var txt = "INPUTS -";
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var arr = []
        let s = txt + "     Page No. - " + currentPage
        var obj = { text: s, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    var tbl: any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [370, 373],

        body: [



        ]
      }
    };




    var arr = [];
    arr.push({ text: "Work/Youjna", bold: true });
    arr.push(this.Obj['work_name']);
    tbl.table.body.push(arr);



    var arr = [];
    arr.push({ text: "Type of Road", bold: true });
    arr.push({ text: this._road_obj['road_location'] });
    tbl.table.body.push(arr);


    var arr = [];
    arr.push({ text: "Type of Road Technique", bold: true });
    arr.push({ text: this._road_obj['road_technique'] });
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Road Name", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Janpad/District", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Chetra/Zone", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Vibhag Ka Naam ", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Length", bold: true });
    arr.push(0);
    tbl.table.body.push(arr);
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(tbl);
    dd.content.push({ text: " " });

    // dd.content.push({
    //   canvas: [
    //     { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
    //   ],
    // });

    var tbl1: any = {

      fontSize: 13,
      table: {

        headerRows: 0,
        widths: ['*'],

        body: [



        ]
      }
    };

    var arr = [];
    arr.push({ text: "For Approach Road ", bold: true, alignment: "center" });
    tbl1.table.body.push(arr);
    dd.content.push(tbl1);

    var tbl2 = {
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: [182, 180, 182, 181],
        body: [

        ],
      },
    };

    dd.content.push(tbl2);

    let x: any = {}
    for (var i = 0; i < this._old_tech_appr_road_arr.length; i++) {
      x = this._old_tech_appr_road_arr[i]
      var arr = [];
      if (x[i + 1] == 'extra_item' && this._old_tech_appr_road_arr[x[i]] == undefined) {
        arr.push({ text: x[i].split('_').join(" "), bold: true, alignment: "center", colSpan: 4 });
        arr.push({ text: '', alignment: "right" });
        arr.push({ text: '', alignment: "left" });
        arr.push({ text: '', alignment: "right" });
      } else {
        arr.push({ text: x[i].split('_').join(" "), alignment: "left" });
        arr.push({ text: this._old_tech_appr_road_arr[x[i]], alignment: "right" });
        arr.push({ text: x[i + 1].split('_').join(" "), alignment: "left" });
        arr.push({ text: this._old_tech_appr_road_arr[x[i + 1]], alignment: "right" });
      }
      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    dd.content.push({ text: " " });


    // var tbl3:any = {

    //   fontSize: 13,
    //   table: {

    //     headerRows: 0,
    //     widths: ['*'],

    //     body: [



    //     ]
    //   }
    // };




    pdfMake.createPdf(dd).download("Inputs");
  }


  /*--------------------------------------Measurement Pdf print for Bridge S--------------------------------------------*/





  async measurementPrint() {
    let _foundation_arr = []
    let _sub_str_arr = []
    let _super_str_arr = []
    let _protection_work_arr = []


    var txt = "Measurement -";
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var arr = []
        let s = txt + "     Page No. - " + currentPage
        var obj = { text: s, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    var tbl: any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [370, 373],

        body: [



        ]
      }
    };

    var arr = [];
    arr.push({ text: "Work/Youjna", bold: true });
    arr.push(this.Obj['work_name']);
    tbl.table.body.push(arr);


    if (this._road_obj['road_location']) {
      var arr = [];
      arr.push({ text: "Type of Road", bold: true });
      arr.push({ text: this._road_obj['road_location'] });
      tbl.table.body.push(arr);

      var arr = [];
      arr.push({ text: "Type of Road Technique", bold: true });
      arr.push({ text: this._road_obj['road_technique'] });
      tbl.table.body.push(arr);

      var arr = [];
      arr.push({ text: "Road Name", bold: true });
      arr.push('');
      tbl.table.body.push(arr);
    } else {
      var arr = [];
      arr.push({ text: "Type of Bridge", bold: true });
      arr.push({ text: this._bridge_obj['bridge_location'] });
      tbl.table.body.push(arr);

      var arr = [];
      arr.push({ text: "Type of bridge type", bold: true });
      arr.push({ text: this._bridge_obj['bridge_type'] });
      tbl.table.body.push(arr);
    }

    var arr = [];
    arr.push({ text: "Janpad/District", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Chetra/Zone", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Vibhag Ka Naam ", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Length", bold: true });
    arr.push(null);
    tbl.table.body.push(arr);





    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });


    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(tbl);
    dd.content.push({ text: " " });

    var tbl1: any = {

      fontSize: 13,
      table: {

        headerRows: 0,
        widths: ['*'],

        body: [



        ]
      }
    };

    var arr = [];
    arr.push({ text: "Details of mesurement ", bold: true, alignment: "center" });
    tbl1.table.body.push(arr);
    dd.content.push(tbl1);
    var tbl2 = {

      fontSize: 10,
      table: {
        headerRows: 2,
        widths: [40, 60, 230, 40, 50, 50, 70, 70, 70],
        body: [
          [{ rowSpan: 2, text: 'Sr. No', alignment: 'center' }, { rowSpan: 2, text: 'Ref. to MORT&H Specif.', alignment: 'center' }, { rowSpan: 2, text: 'Description ', alignment: 'center' }
            , { colSpan: 4, text: 'Measurement', alignment: 'center' }, {}, {}, {}, { rowSpan: 2, text: 'Quantity', alignment: 'center' }, { rowSpan: 2, text: 'Unit', alignment: 'center' }],
          [{}, {}, {}, { text: 'No', alignment: 'center' }, { text: 'L', alignment: 'center' }, { text: 'B', alignment: 'center' }, { text: 'H/D', alignment: 'center' },
          {}, {}],
          // ['', {}, {}, {}, {}, '', { text: 'Rs.', alignment: 'center' }, { text: 'Rs.', alignment: 'center' }, { text: 'Rs.', alignment: 'center' }]

        ],
      }
    };



    dd.content.push(tbl2);
    let w: any = {}
    let _mea_lines: any = []
    let mesuredItems: any = []
    for (let j = 0; j < this.all_items.length; j++) {
      mesuredItems = this.all_items[j]
      if (j == 0) {
        _mea_lines = []
        _mea_lines.push({ colSpan: 8, text: '1. BRIDGE', alignment: 'center' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'left' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'center' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        _mea_lines = []
        _mea_lines.push({ colSpan: 8, text: 'A - FOUNDATION', alignment: 'center' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'left' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: ' ', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(_mea_lines);
      } else if (j == 1) {
        _mea_lines = []
        _mea_lines.push({ colSpan: 8, text: 'B - SUB STRUCTURE', alignment: 'center' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'left' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: ' ', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(_mea_lines);
      } else if (j == 2) {
        _mea_lines = []
        _mea_lines.push({ colSpan: 8, text: 'C - SUPER STRUCTURE', alignment: 'center' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'left' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: ' ', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(_mea_lines);
      } else if (j == 3) {
        _mea_lines = []
        _mea_lines.push({ colSpan: 8, text: '2. PROTECTION WORK', alignment: 'center' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'left' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: ' ', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(_mea_lines);
      }
      for (let i = 0; i < mesuredItems.length; i++) {
        w = mesuredItems[i];


        if (w['filter'] == 'uperLine') {
          _mea_lines = []
          _mea_lines.push({ text: mesuredItems[i]['sno'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['ref_to_morth'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['item_desc'], alignment: 'left' })
          if (mesuredItems[i]['No'] == undefined) {
            this.itemsArr[i]['L'] = ''
            this.itemsArr[i]['B'] = ''
            this.itemsArr[i]['H/D'] = ''
            this.itemsArr[i]['QTY'] = ''
            this.itemsArr[i]['Unit'] = ''
          }
          _mea_lines.push({ text: mesuredItems[i]['No'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['L'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['B'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['H/D'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['QTY'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Unit'], alignment: 'right' })
          dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        } else if (mesuredItems[i]['filter'] == 'middleLine') {
          _mea_lines = []
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['item_desc'], alignment: 'left' })
          _mea_lines.push({ text: mesuredItems[i]['No'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['L'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['B'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['H/D'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['QTY'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Unit'], alignment: 'right' })
          dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        } else if (mesuredItems[i]['filter'] == 'sub_total') {
          _mea_lines = []
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'left' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ colSpan: 2, text: 'Sub Total', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['sub_total'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Unit'], alignment: 'right' })
          dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        } else if (mesuredItems[i]['filter'] == 'After_decuction') {
          _mea_lines = []
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'left' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ colSpan: 2, text: 'After Decuction', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['After_decuction'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Unit'], alignment: 'right' })
          dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        } else if (mesuredItems[i]['filter'] == 'lowerLine') {
          _mea_lines = []
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'left' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ colSpan: 2, text: 'Total', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Total'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Unit'], alignment: 'right' })
          dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        }
      }
    }
    pdfMake.createPdf(dd).download("measurement")

  }

  mea_item: any = []
  async measurement() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['est_id'] = this.Obj['est_id_p']
    obj['user_id'] = this.ebillUser.le_id
    obj['fin_year'] = new Date().getFullYear();
    obj['life_cycle_status'] = "ACTIVE"
    obj['est_id'] = this.Obj['est_id_p']
    obj['estimation_type'] = this.estimation_type

    this.mea_item = this.mea_item
    obj['data'] = this.mea_item

    console.log("measurement Data ===>>>>>>>> ", obj)
    obj['data'].map((x: any) => {
      Object.entries(x).forEach(([key, value]) => {
        x[key] = value == '' || !value ? null : value
      })
    })
    if (this.bridgeUpdate) {
      // await this.updateMeasurement()
      return
    }

    let resp = await this.estimateService.measurement(obj)
    if (resp['error'] == false) {

    } else {
      Swal.fire('Error', resp['data'], 'error')
    }

  }



  async updateMeasurement() {
    console.log('updating Meas ......')
    var obb: any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['id'] = this.Obj['est_id_p']
    obb['update_user_id'] = this.ebillUser.user_id
    obb['status'] = 'UPDATED'
    var resp2 = await this.estimateService.updateLifeCycleStatusForMeas(obb)
    if (resp2['error'] == false) {
      console.log('updates status')
      let obj: any = {}
      obj['b_acct_id'] = this.b_acct_id
      obj['est_id'] = this.Obj['est_id_p']
      obj['user_id'] = this.ebillUser.le_id
      obj['fin_year'] = new Date().getFullYear();
      obj['life_cycle_status'] = "ACTIVE"
      obj['est_id'] = this.Obj['est_id_p']
      this.mea_item = this.mea_item
      obj['data'] = this.mea_item
      console.log("measurement Data ===>>>>>>>> ", obj)
      let resp = await this.estimateService.measurement(obj)
      if (resp['error'] == false) {

      } else {
        Swal.fire('Error', resp['data'], 'error')
      }
      // for setting analysis field
      // await this.getAllAnalysisOfRate(obb['id']);
      // console.log('ana d =>', this.allAnaRates)
      // this._ana_items = this.allAnaRates

      //  })
      this.spinner.hide();
    }
  }
  /*--------------------------------------Measurement Pdf print for Bridge E--------------------------------------------*/

  gettotalborder(data: any) {
    if (data['sno'] + '_' + data['ref_to_morth'] == '13.02_1300 & 2200'
    ) {
      return '0px'
    } else {
      return '1px solid'
    }
  }
  getafterdedborder(data: any) {
    if (data['sno'] + '_' + data['ref_to_morth'] == '13.02_1300 & 2200') {
      return '1px solid'
    } else {
      return '0px'
    }
  }
  async checkthis(x: any) {
    if (x['sno'] + '_' + x['ref_to_morth'] != '14.02_1600' || x['sno'] + '_' + x['ref_to_morth'] != '12.04_1600') {
      return false
    } else {
      return true
    }
  }
  async checkthis1(x: any) {
    if (x['sno'] + '_' + x['ref_to_morth'] != '14.02_1600' || x['sno'] + '_' + x['ref_to_morth'] != '12.04_1600') {
      return true
    } else {
      return false
    }
  }
  getmainborder(data: any) {
    //   if(
    //     data['sno']+'_'+data['ref_to_morth']=='13.02_1300 & 2200'||
    //     data['sno']+'_'+data['ref_to_morth']=='12.03_304'||
    //     data['sno']+'_'+data['ref_to_morth']=='12.04_1600'||
    //     data['sno']+'_'+data['ref_to_morth']=='13.06_Section 1600 & 2200'||
    //     data['sno']+'_'+data['ref_to_morth']=='14.01 B (i) (P)_1500'||
    //     data['sno']+'_'+data['ref_to_morth']=='14.011_1500 , 1600 , 1700 & 2704'||
    //     data['sno']+'_'+data['ref_to_morth']=='14.011_2702'||
    //     data['sno']+'_'+data['ref_to_morth']=='14.021_2607'||
    //     data['sno']+'_'+data['ref_to_morth']=='16.01_2503 A'||
    //     data['sno']+'_'+data['ref_to_morth']=='16.04_2504 A'||
    //     data['sno']+'_'+data['ref_to_morth']=='16.05_2504'
    //     ){
    //      return '1px solid'
    // }else{
    //   return '0px'
    // }
  }


  // formatDecimal(key:any) {
  //   this._foundation_obj[key]=Number(this._foundation_obj[key].toFixed(0))
  // }
  trunc(key: any) {
    if (this._foundation_obj[key] == null) {
      return
    }
    let amt = Number((this._foundation_obj[key] * 1000).toFixed(3))
    this._foundation_obj[key] = (Math.trunc(amt)) / 1000
  }
  /*-------------------------------------- get items for Bridge E--------------------------------------------*/

  async getBridgeItems() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['cost_rate_std'] = 'MORTH';
    obj['costing_rate_std'] = 'MORTH';
    obj['filter_data'] = ['12.01_304', '12.04_2100', '12.03_304',
      '12.08_1500 , 1700 & 2100', '12.05_1300',
      '13.02_1300 & 2200', '12.04_1600', '13.01_1300 & 2200', '13.05_1500, 1700 & 2200',
      '13.06_Section 1600 & 2200', '14.01_1500 &1600 1700', '14.011_1500 , 1600 , 1700 & 2704',
      '14.04_2702', '14.02_1600', '14.021_2607',
      '14.09_2705', '13.08_2706 & 2200', '13.01_710.1.4.of IRC:78 and 2504.2',
      '16.01_2503', '16.04_2504', '16.05_2504']
    // let resp = await this.estimateService.getBridgeItems(obj)
    let resp = await this.estimateService.getAnalysisItems(obj)
    if (resp['error'] == false) {
      console.log('==========>>>>>>', resp, '4640')
      let tempArr: any = []

      resp['data'].map((x: any) => {
        let rqMorth = null;
        if (x.sno != null && x.ref_to_morth != null) {
          if (this._bridge_obj['culvert_type'] == "CULVERT2") {
            if (x['est_type'] != null) {
              let myArray = x['est_type'].split(', ').map((item: any) => item.replace(/'/g, ''));
              let elementToCheck = "Rular Bridge-->CULVERT2"; // here we will need only morth data which are associated with box culvert
              let exists = myArray.includes(elementToCheck);
              if (exists) {
                rqMorth = x.morth_id
              }
              x['est_type'] = myArray
            }
          } else if (this._bridge_obj['culvert_type'] == "CULVERT1") {
            // if (x['sno'] + '_' + x['ref_to_morth'] == '12.01_304' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '12.04_2100' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '14.021_2607' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '14.09_2705' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '13.06_Section 1600 & 2200' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '12.04_1600' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '14.02_1600' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '13.05_1500, 1700 & 2200' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '12.05_1300' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '13.01_1300 & 2200' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '13.02_1300 & 2200' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '13.08_2706 & 2200' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '14.01_1500 &1600 1700' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '12.03_304' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '14.011_1500 , 1600 , 1700 & 2704' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '12.08_1500 , 1700 & 2100' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '14.04_2702' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '16.05_2504' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '16.04_2504' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '13.01_710.1.4.of IRC:78 and 2504.2' ||
            //   x['sno'] + '_' + x['ref_to_morth'] == '16.01_2503') {
            //   rqMorth = x.morth_id
            // }
            if (x['est_type'] != null) {

              let myArray = x['est_type'].split(', ').map((item: any) => item.replace(/'/g, ''));
              let elementToCheck = "Rular Bridge-->CULVERT1"; // here we will need only morth data which are associated with box culvert
              let exists = myArray.includes(elementToCheck);
              if (exists) {
                rqMorth = x.morth_id
              }
            }
          } else if (this._bridge_obj['bridge_type'] == 'BOX_CULVERT') {

            if (x['est_type'] != null) {
              let myArray = x['est_type'].split(', ').map((item: any) => item.replace(/'/g, ''));
              let elementToCheck = "Rular Bridge-->Box Culvert"; // here we will need only morth data which are associated with box culvert
              let exists = myArray.includes(elementToCheck);
              if (exists) {
                rqMorth = x.morth_id
              }
              x['est_type'] = myArray
            }

          }

        }

        if (x.morth_id == rqMorth) {
          tempArr.push(x)
        }
      })


      // this.itemsArr = resp['data']
      this.itemsArr = tempArr
      console.log('==========>>>>>>ffffffff', this.itemsArr)

      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }

  /*-------------------------------------- get items for Bridge E--------------------------------------------*/

  items: any[] = [
    { id: 1, name: 'Item 1', value1: null, value2: null, minValue: 0, maxValue: 100 },
    { id: 2, name: 'Item 2', value1: null, value2: null, minValue: 0, maxValue: 50 },
  ];

  validateInput(index: number): boolean {
    const item = this.items[index];
    return item.value1 != null && item.value1 >= item.minValue && item.value1 <= item.maxValue &&
      item.value2 != null && item.value2 >= item.minValue && item.value2 <= item.maxValue;
  }
  submitted = false
  onSubmit() {

    if (this.items[0]['value1'] == null || this.items[0]['value2'] == null ||
      this.items[1]['value1'] == null || this.items[1]['value2'] == null) {
      this.submitted = true
      return;
    }
    this.submitted = false
    // Perform any submission logic here
    console.log(this.items);
  }


  /*------------------------ Analysis Screen S---------------------------------*/
  _ana_items: any = []
  async getAnalysisItems() {
    let _obj: any = {}
    _obj['b_acct_id'] = this.b_acct_id;
    _obj['cost_rate_std'] = 'MORTH';
    let _resp = await this.estimateService.getAnalysisItems(_obj)
    if (_resp["error"] == false) {
      this._ana_items = _resp['data']
      let sno: any;
      let ref: any;
      let _last_rank_isfw: any;
      let _last_rank_oc: any;
      let _last_rank_roy: any;
      let _last_rank_cp: any;
      this._ana_items.map((x: any) => {
        if (x['level_cd'] != null && x['item_desc'] != null && x['quantity'] != null && x['unit'] == null) {
          if (x['item_desc'] != "Contractor's profit" && x['item_desc'] != "Overhead charges" && x['item_desc'] == "Royality") {
            x['isFw'] = 1;
          }
        }
        if (x['item_desc'] == "Contractor's profit") {
          x['item_desc'] = 'Contractors profit'
          x['item_desc2'] = "Contractor's profit"
        } else if (x['item_desc'] == "Overhead charges") {
          // x['item_desc'] = 'Contractors profit'
          x['item_desc2'] = "Overhead charges"
        } else {
          x['item_desc2'] = x['item_desc']
        }

        if (x['item_unit'] != null) {
          x['rate'] = 0
          x['amount'] = 0
        } else if (x['item_desc'] == 'Overhead charges' || x['item_desc'] == 'Contractors profit') {
          x['rate'] = 0
          x['amount'] = (x['quantity'] / 100) * x['rate']
        } else if (x['item_desc'] == "Royality") {
          x['rate'] = 0
          x['amount'] = x['quantity']
        } else {
          x['rate'] = null
          x['amount'] = null
        }

        if (x['sno'] != null) {
          sno = x['sno']
          ref = x['ref_to_morth']
        }

        if (x['isFw'] == 1 && x['rank'] == undefined) {
          if (!_last_rank_isfw) {
            x['rank'] = 1;
          } else {
            let a = _last_rank_isfw.split('_')
            x['rank'] = Number(a[2]) + 1;
          }
          _last_rank_isfw = sno + '_' + ref + '_' + x['rank']
        }

        if (x['item_desc'] == 'Overhead charges' && x['rank'] == undefined) {
          if (!_last_rank_oc) {
            x['rank'] = 1;
          } else {
            let a = _last_rank_oc.split('_')
            x['rank'] = Number(a[2]) + 1;
          }
          _last_rank_oc = sno + '_' + ref + '_' + x['rank']
        }

        if (x['item_desc'] == "Royality" && x['rank'] == undefined) {
          if (!_last_rank_roy) {
            x['rank'] = 1;
          } else {
            let a = _last_rank_roy.split('_')
            x['rank'] = Number(a[2]) + 1;
          }
          _last_rank_roy = sno + '_' + ref + '_' + x['rank']
        }

        if (x['item_desc'] == 'Contractors profit' && x['rank'] == undefined) {
          if (!_last_rank_cp) {
            x['rank'] = 1;
          } else {
            let a = _last_rank_cp.split('_')
            x['rank'] = Number(a[2]) + 1;
          }
          _last_rank_cp = sno + '_' + ref + '_' + x['rank']
        }
        x['item_id'] = sno + '_' + ref

      })
    } else {
      Swal.fire('Error', 'Error while getting Analysis data', 'error');
      return;
    }

    //now we have to remove unwanted sno
    let requiredSno: any = ['12.01_304']
    let tempArr: any = []
    let rqMorth1: any;


    this._ana_items.map((x: any) => {
      if (x.sno != null && x.ref_to_morth != null) {
        // if (x['sno'] + '_' + x['ref_to_morth'] == '12.01_304') {
        //   rqMorth1 = x.morth_id
        // }

        if (this._bridge_obj['culvert_type'] == "CULVERT2") {

          if (x['est_type'] != null) {

            let myArray = x['est_type'].split(', ').map((item: any) => item.replace(/'/g, ''));

            let elementToCheck = "Rular Bridge-->CULVERT2"; // here we will need only morth data which are associated with CULVERT2

            let exists = myArray.includes(elementToCheck);
            if (exists) {
              rqMorth1 = x.morth_id
            }

          }
        } else if (this._bridge_obj['culvert_type'] == "CULVERT1") {

          if (x['est_type'] != null) {

            let myArray = x['est_type'].split(', ').map((item: any) => item.replace(/'/g, ''));

            let elementToCheck = "Rular Bridge-->CULVERT1"; // here we will need only morth data which are associated with box CULVERT1

            let exists = myArray.includes(elementToCheck);
            if (exists) {
              rqMorth1 = x.morth_id
            }
          }
        } else if (this._bridge_obj['bridge_type'] == 'BOX_CULVERT') {
          if (x['est_type'] != null) {
            let myArray = x['est_type'].split(', ').map((item: any) => item.replace(/'/g, ''));
            let elementToCheck = "Rular Bridge-->Box Culvert"; // here we will need only morth data which are associated with box culvert
            let exists = myArray.includes(elementToCheck);
            if (exists) {
              rqMorth1 = x.morth_id
            }
          }
        } else if (this._road_obj['road_location'] == 'APPR') {
          if (x['est_type'] != null) {
            let myArray = x['est_type'].split(', ').map((item: any) => item.replace(/'/g, ''));
            let elementToCheck = "Approach Road-->Old Tech"; // here we will need only morth data which are associated with approcah road
            let elementToCheck2 = "Approach Road-->New Tech";
            let exists = myArray.includes(elementToCheck);
            let exists2 = myArray.includes(elementToCheck2);
            if (exists || exists2) {
              rqMorth1 = x.morth_id
            }
          }
        } else if (this._road_obj['road_location'] == 'ADD_APPR') {
          if (x['est_type'] != null) {
            let myArray = x['est_type'].split(', ').map((item: any) => item.replace(/'/g, ''));
            let elementToCheck = "Add Approach Road-->Old Tech"; // here we will need only morth data which are associated with add. approcah road
            let elementToCheck2 = "Add Approach Road-->New Tech";
            let exists = myArray.includes(elementToCheck);
            let exists2 = myArray.includes(elementToCheck2);
            if (exists || exists2) {
              rqMorth1 = x.morth_id
            }
          }
        }
        console.log('ana filter', this._road_obj['road_location'])
      }
      if (x.morth_id == rqMorth1) {
        tempArr.push(x)
      }
    })


    if (this._bridge_obj['culvert_type'] == "CULVERT2" ||
      this._bridge_obj['culvert_type'] == "CULVERT1" ||
      this._bridge_obj['bridge_type'] == 'BOX_CULVERT' ||
      this._road_obj['road_location'] == 'APPR' ||
      this._road_obj['road_location'] == 'ADD_APPR'
    ) {
      this._ana_items = tempArr
    }

    console.log('all itemssss ====>>= >=====||', this._ana_items)

  }




  async calcute(i: number) {
    console.log('uiiiiiiiiiiiiii', i)
    if (this._ana_items[i]['rate'] == null) {
      return
    }
    let amt = Number((this._ana_items[i]['rate'] * 100).toFixed(2))
    this._ana_items[i]['rate'] = (Math.trunc(amt)) / 100

    // console.log('c' , this._ana_items[i]['item_desc']=='Overhead charges' || this._ana_items[i]['item_desc']=='Contractors profit' || this._ana_items[i]['isFw'] == 1)

    if (this._ana_items[i]['item_desc'] == 'Overhead charges' || this._ana_items[i]['item_desc'] == 'Contractors profit' || this._ana_items[i]['isFw'] == 1) {
      this._ana_items[i]['amount'] = Number(((this._ana_items[i]['quantity'] / 100) * this._ana_items[i]['rate']).toFixed(2))
    } else {

      if (this._ana_items[i]['quantity'] != null) {
        if (this._ana_items[i]['item_desc'] == "Royality") {
          this._ana_items[i]['amount'] = Number(this._ana_items[i]['rate']).toFixed(2)
        } else {
          this._ana_items[i]['amount'] = Number((this._ana_items[i]['rate'] * this._ana_items[i]['quantity']).toFixed(2))
        }
      } else {
        this._ana_items[i]['amount'] = Number((this._ana_items[i]['rate']).toFixed(2))
      }
    }
    let sno;
    let ref;
    for (let j = i - 1; j >= 0; j--) {
      if (this._ana_items[j]['sno'] != null) {
        sno = this._ana_items[j]['sno']
        if (this._ana_items[j]['ref_to_morth'] != null) {
          ref = this._ana_items[j]['ref_to_morth']
        } else {
          ref = null
        }
        break;
      }
    }
    let _tak_op;
    let _unit;
    for (let j = i - 1; j >= 0; j--) {
      if (this._ana_items[j]['unit'] != null) {
        _unit = this._ana_items[j]['unit']
        if (this._ana_items[j]['tak_op'] != null) {
          _tak_op = this._ana_items[j]['tak_op']
        }
        break;
      }
    }

    let rank_isFw;
    for (let j = i + 1; j < this._ana_items.length; j++) {
      if (this._ana_items[j]['isFw'] == 1 && this._ana_items[j]['rank'] != undefined) {
        rank_isFw = this._ana_items[j]['rank']
        break;
      }
    }
    let rank_oc;
    for (let j = i + 1; j < this._ana_items.length; j++) {
      if (this._ana_items[j]['item_desc'] == 'Overhead charges' && this._ana_items[j]['rank'] != undefined) {
        rank_oc = this._ana_items[j]['rank']
        break;
      }
    }
    let rank_roy;
    for (let j = i + 1; j < this._ana_items.length; j++) {
      if (this._ana_items[j]['item_desc'] == 'Royality' && this._ana_items[j]['rank'] != undefined) {
        rank_roy = this._ana_items[j]['rank']
        break;
      }
    }

    let rank_cp;
    for (let j = i + 1; j < this._ana_items.length; j++) {
      if (this._ana_items[j]['item_desc'] == 'Contractors profit' && this._ana_items[j]['rank'] != undefined) {
        rank_cp = this._ana_items[j]['rank']
        break;
      }
    }

    let index = 0
    for (let j = i - 1; j >= 0; j--) {
      if (this._ana_items[j]['tak_op'] != null || this._ana_items[j]['sno'] != null && this._ana_items[j]['ref_to_morth'] != null) {
        index = j
        break;
      }
    }
    let _total_isFw = 0
    let _totatl_oc = 0
    let _total_cp = 0
    let _total_cost = 0
    for (let k = index; k < this._ana_items.length; k++) {
      if (this._ana_items[k]['item_id'] == sno + '_' + ref && this._ana_items[k]['level_cd'] == null && this._ana_items[k]['quantity'] != null) {
        _totatl_oc += this._ana_items[k]['amount']
      }
      console.log('============>', this._ana_items[k])

      // console.log('============>',this._ana_items[k]['item_id']==sno+'_'+ref  && this._ana_items[k]['isFw']== 1 && this._ana_items[k]['rank']==rank_isFw ,this._ana_items[k]['item_id'] ,sno+'_'+ref  , this._ana_items[k]['isFw'] , 1 , this._ana_items[k]['rank'] ,rank_isFw)
      if (this._ana_items[k]['item_id'] == sno + '_' + ref && this._ana_items[k]['isFw'] == 1 && this._ana_items[k]['rank'] == rank_isFw) {
        this._ana_items[k]['rate'] = _totatl_oc + _total_isFw

        this._ana_items[k]['amount'] = Number(((this._ana_items[k]['quantity'] / 100) * _totatl_oc).toFixed(2))
        _total_isFw = this._ana_items[k]['amount']
      }

      if (this._ana_items[k]['item_id'] == sno + '_' + ref && this._ana_items[k]['item_desc'] == 'Overhead charges' && this._ana_items[k]['rank'] == rank_oc) {
        this._ana_items[k]['rate'] = _totatl_oc + _total_isFw

        this._ana_items[k]['amount'] = Number(((this._ana_items[k]['quantity'] / 100) * (_totatl_oc + _total_isFw)).toFixed(2))
        _total_cp = this._ana_items[k]['amount']
      }

      if (this._ana_items[k]['item_id'] == sno + '_' + ref && this._ana_items[k]['item_desc'] == 'Contractors profit' && this._ana_items[k]['rank'] == rank_cp) {
        this._ana_items[k]['rate'] = _total_cp + _totatl_oc + _total_isFw;
        this._ana_items[k]['amount'] = Number(((this._ana_items[k]['quantity'] / 100) * this._ana_items[k]['rate']).toFixed(2));
        _total_cost = this._ana_items[k]['amount'] + this._ana_items[k]['rate'];


        if (this._ana_items[k + 1] == undefined || this._ana_items[k + 1]['item_desc'] != 'Cost for ' + _tak_op + ' ' + _unit) {

          this._ana_items.splice(k + 1, 0, { 'item_id': sno + '_' + ref, 'item_desc': 'Cost for ' + _tak_op + ' ' + _unit, 'amount': Number((_total_cost).toFixed(2)) });
          this._ana_items.splice(k + 2, 0, { 'item_id': sno + '_' + ref, 'item_desc': 'Rate per ' + _unit, 'amount': Number((_total_cost / _tak_op).toFixed(2)) });
          let new_total = Number((_total_cost / _tak_op).toFixed(2))
          console.log('k ??', this._ana_items[k + 1])
          console.log('k ??', this._ana_items[k + 2])
          console.log('k ??', this._ana_items[k + 3])

          if (ref != null) {
            ref = ref.toString()
          }
          if (this._ana_items[k + 3] != undefined) {
            if (this._ana_items[k + 3]['item_desc'] == "Royality") {
              this._ana_items.splice(k + 4, 0, { 'item_id': sno + '_' + ref, 'item_desc': 'Say', 'amount': Number(new_total + Number(this._ana_items[k + 3]['quantity'])).toFixed(2) });
            } else {
              this._ana_items.splice(k + 3, 0, { 'item_id': sno + '_' + ref, 'item_desc': 'Say', 'amount': Number(this._ana_items[k + 2]['amount']).toFixed(2) });
            }
          } else {
            this._ana_items.splice(k + 3, 0, { 'item_id': sno + '_' + ref, 'item_desc': 'Say', 'amount': Number(this._ana_items[k + 2]['amount']).toFixed(2) });
          }
        } else {
          this._ana_items[k + 1]['amount'] = Number((_total_cost).toFixed(2));
          this._ana_items[k + 2]['amount'] = Number((_total_cost / _tak_op).toFixed(2));
          this._ana_items[k + 3]['amount'] = Number(this._ana_items[k + 2]['amount']).toFixed(0)
        }

      } if (this._ana_items[k]['item_id'] == sno + '_' + ref && this._ana_items[k]['item_desc'] == 'Royality' && this._ana_items[k]['rank'] == rank_roy) {


        this._ana_items[k + 1]['amount_temp'] = (this._ana_items[k - 1]['amount'] + Number(this._ana_items[k]['quantity']))

        this._ana_items[k + 1]['amount'] = Number(this._ana_items[k - 1]['amount'] + Number(this._ana_items[k]['quantity'])).toFixed(0)



        // this._ana_items[k]['rate'] = _total_cp + _totatl_oc + _total_isFw;
        // this._ana_items[k]['amount'] = _total_cost + this._ana_items[k]['amount']
        // _total_cost = this._ana_items[k]['amount'] + Number( this._ana_items[k]['quantity']);

        // if (this._ana_items[k + 1] == undefined || this._ana_items[k + 1]['item_desc'] != 'Cost for ' + _tak_op + ' ' + _unit) {
        //   this._ana_items.splice(k + 1, 0, { 'item_id': sno + '_' + ref, 'item_desc': 'Cost for ' + _tak_op + ' ' + _unit, 'amount': Number((_total_cost).toFixed(2)) });

        //   this._ana_items.splice(k + 2, 0, { 'item_id': sno + '_' + ref, 'item_desc': 'Rate per ' + _unit, 'amount': Number((_total_cost / _tak_op).toFixed(2)) });
        //   if (ref != null) {
        //     ref = ref.toString()
        //   }
        //   this._ana_items.splice(k + 3, 0, { 'item_id': sno + '_' + ref, 'item_desc': 'Say', 'amount': Number(this._ana_items[k + 2]['amount']).toFixed(2) });
        // } else {
        //   this._ana_items[k + 1]['amount'] = Number((_total_cost).toFixed(2));
        //   this._ana_items[k + 2]['amount'] = Number((_total_cost / _tak_op).toFixed(2));
        //   this._ana_items[k + 3]['amount'] = Number(this._ana_items[k + 2]['amount']).toFixed(0)
        // }
      }
    }

  }
  async boqNext(stepper: MatStepper) {
    await this.boq()
    await this.insrertAnalysisData()

    stepper.next()
  }

  async insrertAnalysisData() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['est_id'] = this.Obj['est_id_p']
    obj['user_id'] = this.ebillUser.le_id
    obj['fin_year'] = new Date().getFullYear();
    obj['life_cycle_status'] = "ACTIVE"
    obj['data'] = this._ana_items
    obj['estimation_type'] = this.estimation_type
    // console.log("Analyis Data ===>>>>>>>> ", obj)
    obj['data'].map((x: any) => {
      Object.entries(x).forEach(([key, value]) => {
        x[key] = value == '' || !value ? null : value
      })
    })
    if (this.bridgeUpdate) {
      //  await this.updateAnalysis()
      return
    }
    obj['life_cycle_status'] = "ACTIVE"
    let resp = await this.estimateService.insertAnaData(obj)
    if (resp['error'] == false) {
      console.log('Successfully Saved')
    } else {
      Swal.fire('Error', resp['data'], 'error')
    }
  }

  async updateAnalysis() {
    console.log('updating Ana ......')
    var obb: any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['id'] = this.Obj['est_id_p']
    obb['update_user_id'] = this.ebillUser.user_id
    obb['status'] = 'UPDATED'
    var resp2 = await this.estimateService.updateLifeCycleStatusForAna(obb)
    if (resp2['error'] == false) {
      console.log('updates Analysis OF rates')
      let obj: any = {}
      obj['b_acct_id'] = this.b_acct_id
      obj['est_id'] = this.Obj['est_id_p']
      obj['user_id'] = this.ebillUser.le_id
      obj['fin_year'] = new Date().getFullYear();
      obj['life_cycle_status'] = "ACTIVE"
      obj['data'] = this._ana_items
      // console.log("Analyis Data ===>>>>>>>> ", obj)
      obj['data'].map((x: any) => {
        Object.entries(x).forEach(([key, value]) => {
          x[key] = value == '' || !value ? null : value
        })
      })
      obj['life_cycle_status'] = "ACTIVE"
      let resp = await this.estimateService.insertAnaData(obj)
      if (resp['error'] == false) {
        console.log('Successfully Saved')
      } else {
        Swal.fire('Error', resp['data'], 'error')
      }
    }
  }
  async createAnalysis() {
    let _obj: any = {}
    _obj['b_acct_id'] = this.b_acct_id
    _obj['data'] = this._ana_items
    _obj['est_id'] = this.Obj['est_id_p']
    _obj['fin_year'] = new Date().getFullYear()
    _obj['user_id'] = this.ebillUser.user_id
    this.spinner.show();
    let resp = await this.estimateService.createAnalysis(_obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      // Swal.fire('Success',resp['data'],'success')
    } else {
      this.spinner.hide();
      Swal.fire('Error', resp['data'], 'error')
    }


  }

  anaPrint() {

    var txt = "Analysis -";
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var arr = []
        let s = txt + "     Page No. - " + currentPage
        var obj = { text: s, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    var tbl: any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [370, 373],

        body: [



        ]
      }
    };



    var arr = [];
    arr.push({ text: "Work/Youjna", bold: true });
    arr.push(this.Obj['work_name']);
    tbl.table.body.push(arr);


    if (this._road_obj['road_location']) {
      var arr = [];
      arr.push({ text: "Type of Road", bold: true });
      arr.push({ text: this._road_obj['road_location'] });
      tbl.table.body.push(arr);

      var arr = [];
      arr.push({ text: "Type of Road Technique", bold: true });
      arr.push({ text: this._road_obj['road_technique'] });
      tbl.table.body.push(arr);

      var arr = [];
      arr.push({ text: "Road Name", bold: true });
      arr.push('');
      tbl.table.body.push(arr);
    } else {
      var arr = [];
      arr.push({ text: "Type of Bridge", bold: true });
      arr.push({ text: this._bridge_obj['bridge_location'] });
      tbl.table.body.push(arr);

      var arr = [];
      arr.push({ text: "Type of bridge type", bold: true });
      arr.push({ text: this._bridge_obj['bridge_type'] });
      tbl.table.body.push(arr);
    }

    var arr = [];
    arr.push({ text: "Janpad/District", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Chetra/Zone", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Vibhag Ka Naam ", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Length", bold: true });
    arr.push('');
    tbl.table.body.push(arr);





    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(tbl);
    dd.content.push({ text: " " });

    var tbl1: any = {

      fontSize: 13,
      table: {

        headerRows: 0,
        widths: ['*'],

        body: [



        ]
      }
    };

    var arr = [];
    arr.push({ text: "  OF RATES", bold: true, alignment: "center" });
    tbl1.table.body.push(arr);
    dd.content.push(tbl1);
    var tbl2 = {

      fontSize: 10,
      table: {
        headerRows: 1,
        widths: [41, 70, 28, 230, 80, 80, 80, 80],
        body: [
          [{ text: 'Sr. No', alignment: 'center' }, { text: 'Ref. to MORT&H Specif.', alignment: 'center' }, {}, { text: 'Description ', alignment: 'center' }
            , { text: 'Unit', alignment: 'center' }, { text: 'Quantity', alignment: 'center' }, { text: 'Rate(Rs.)', alignment: 'center' }, { text: 'Amount', alignment: 'center' }],
        ],
      }
    };
    dd.content.push(tbl2);
    console.log(this._ana_items)
    for (let i = 0; i < this._ana_items.length; i++) {

      if (this._ana_items[i]['item_desc'] != null && this._ana_items[i]['item_desc'] != 'Say') {
        arr = []
        arr.push({ text: this._ana_items[i]['sno'], alignment: 'right' })
        arr.push({ text: this._ana_items[i]['ref_to_morth'], alignment: 'right' })
        arr.push({ text: this._ana_items[i]['level_cd'], alignment: 'center' })
        arr.push({ text: this._ana_items[i]['item_desc'], alignment: 'left' })
        arr.push({ text: this._ana_items[i]['item_unit'], alignment: 'right' })
        arr.push({ text: this._ana_items[i]['quantity'], alignment: 'right' })
        arr.push({ text: this._ana_items[i]['rate'], alignment: 'right' })
        arr.push({ text: this._ana_items[i]['amount'], alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(arr);
      } else if (this._ana_items[i]['item_desc'] != null && this._ana_items[i]['item_desc'] == 'Say') {
        arr = []
        arr.push({ text: this._ana_items[i]['sno'], alignment: 'right' })
        arr.push({ text: this._ana_items[i]['ref_to_morth'], alignment: 'right' })
        arr.push({ text: this._ana_items[i]['level_cd'], alignment: 'center' })
        arr.push({ text: this._ana_items[i]['item_desc'], bold: true, alignment: 'left' })
        arr.push({ text: this._ana_items[i]['item_unit'], alignment: 'right' })
        arr.push({ text: this._ana_items[i]['quantity'], alignment: 'right' })
        arr.push({ text: this._ana_items[i]['rate'], alignment: 'right' })
        arr.push({ text: this._ana_items[i]['amount'], bold: true, alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(arr);

      } else if (this._ana_items[i]['item_desc'] == null) {
        arr = []
        arr.push({ text: '', alignment: 'right' })
        arr.push({ text: '', alignment: 'right' })
        arr.push({ text: this._ana_items[i]['level_cd'], alignment: 'left' })
        arr.push({ text: 'Unit=' + this._ana_items[i]['unit'], alignment: 'left' })
        arr.push({ text: '', alignment: 'right' })
        arr.push({ text: '', alignment: 'right' })
        arr.push({ text: '', alignment: 'right' })
        arr.push({ text: '', alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(arr);
        arr = []
        arr.push({ text: '', alignment: 'right' })
        arr.push({ text: '', alignment: 'right' })
        arr.push({ text: this._ana_items[i]['level_cd'], alignment: 'left' })
        arr.push({ text: 'Taking Output=' + this._ana_items[i]['tak_op'], alignment: 'left' })
        arr.push({ text: '', alignment: 'right' })
        arr.push({ text: '', alignment: 'right' })
        arr.push({ text: '', alignment: 'right' })
        arr.push({ text: '', alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(arr);
      }
    }
    pdfMake.createPdf(dd).download("analysis")
  }


  /*--------------------------------------Measurement Pdf print for Road   S--------------------------------------------*/


  async measurementPrintForRoad() {
    let itemsForRoad = []
    console.log('sdg', this.Obj['work_name']);

    var txt = "Measurement -";
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var arr = []
        let s = txt + "     Page No. - " + currentPage
        var obj = { text: s, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    var tbl: any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [370, 373],

        body: [



        ]
      }
    };

    var arr = [];
    arr.push({ text: "Work/Youjna", bold: true });
    arr.push(this.Obj['work_name']);
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "Type of Bridge", bold: true });
    arr.push({ text: this._road_obj['bridge_location'] });
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Type of bridge type", bold: true });
    arr.push({ text: this._road_obj['bridge_type'] });
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Road Name", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Janpad/District", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Chetra/Zone", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Vibhag Ka Naam ", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Length", bold: true });
    arr.push(null);
    tbl.table.body.push(arr);

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(tbl);
    dd.content.push({ text: " " });

    var tbl1: any = {
      fontSize: 13,
      table: {
        headerRows: 0,
        widths: ['*'],
        body: [
        ]
      }
    };

    var arr = [];
    arr.push({ text: "Details of mesurement ", bold: true, alignment: "center" });
    tbl1.table.body.push(arr);
    dd.content.push(tbl1);
    var tbl2 = {
      fontSize: 10,
      table: {
        headerRows: 2,
        widths: [40, 60, 230, 40, 50, 50, 70, 70, 70],
        body: [
          [{ rowSpan: 2, text: 'Sr. No', alignment: 'center' }, { rowSpan: 2, text: 'Ref. to MORT&H Specif.', alignment: 'center' }, { rowSpan: 2, text: 'Description ', alignment: 'center' }
            , { colSpan: 4, text: 'Measurement', alignment: 'center' }, {}, {}, {}, { rowSpan: 2, text: 'Quantity', alignment: 'center' }, { rowSpan: 2, text: 'Unit', alignment: 'center' }],
          [{}, {}, {}, { text: 'No', alignment: 'center' }, { text: 'L', alignment: 'center' }, { text: 'B', alignment: 'center' }, { text: 'H/D', alignment: 'center' },
          {}, {}],
          // ['', {}, {}, {}, {}, '', { text: 'Rs.', alignment: 'center' }, { text: 'Rs.', alignment: 'center' }, { text: 'Rs.', alignment: 'center' }]
        ],
      }
    };
    dd.content.push(tbl2);
    let w: any = {}
    let _mea_lines: any = []
    let mesuredItems: any = []
    for (let j = 0; j < this.all_items.length; j++) {
      mesuredItems = this.all_items[j]
      if (j == 0) {
        _mea_lines = []
        _mea_lines.push({ colSpan: 8, text: '1. Road', alignment: 'center' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'left' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'center' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        _mea_lines = []
        _mea_lines.push({ colSpan: 8, text: 'A - FOUNDATION', alignment: 'center' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'left' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: ' ', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        _mea_lines.push({ text: '', alignment: 'right' })
        dd.content[dd.content.length - 1].table.body.push(_mea_lines);
      }
      for (let i = 0; i < mesuredItems.length; i++) {
        w = mesuredItems[i];
        if (w['filter'] == 'uperLine') {
          _mea_lines = []
          _mea_lines.push({ text: mesuredItems[i]['sno'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['ref_to_morth'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['item_desc'], alignment: 'left' })
          if (mesuredItems[i]['No'] == undefined) {
            this.itemsArr[i]['L'] = ''
            this.itemsArr[i]['B'] = ''
            this.itemsArr[i]['H/D'] = ''
            this.itemsArr[i]['QTY'] = ''
            this.itemsArr[i]['Unit'] = ''
          }
          _mea_lines.push({ text: mesuredItems[i]['No'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['L'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['B'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['H/D'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['QTY'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Unit'], alignment: 'right' })
          dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        } else if (mesuredItems[i]['filter'] == 'middleLine') {
          _mea_lines = []
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['item_desc'], alignment: 'left' })
          _mea_lines.push({ text: mesuredItems[i]['No'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['L'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['B'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['H/D'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['QTY'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Unit'], alignment: 'right' })
          dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        } else if (mesuredItems[i]['filter'] == 'sub_total') {
          _mea_lines = []
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'left' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ colSpan: 2, text: 'Sub Total', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['sub_total'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Unit'], alignment: 'right' })
          dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        } else if (mesuredItems[i]['filter'] == 'After_decuction') {
          _mea_lines = []
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'left' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ colSpan: 2, text: 'After Decuction', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['After_decuction'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Unit'], alignment: 'right' })
          dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        } else if (mesuredItems[i]['filter'] == 'lowerLine') {
          _mea_lines = []
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'left' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ colSpan: 2, text: 'Total', alignment: 'right' })
          _mea_lines.push({ text: '', alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Total'], alignment: 'right' })
          _mea_lines.push({ text: mesuredItems[i]['Unit'], alignment: 'right' })
          dd.content[dd.content.length - 1].table.body.push(_mea_lines);
        }
      }
    }
    pdfMake.createPdf(dd).download("measurement")

  }


  /*------------------------ Analysis Screen E-------------------*/

  /*------------------------Bill of Quantity of Bridge S----------------*/

  boq_items: any = []
  async boq() {
    this.boq_items = []
    console.log(' hidden ? ', this._road_est_flag)
    console.log('mes in boq', this.mea_item);

    let cul_item = this._road_est_flag == true ? ['12.01_304', '12.04_2100', '12.03_304', '12.21_1207',
      '12.08_1500 , 1700 & 2100', '12.05_1300',
      '13.02_1300 & 2200', '12.04_1600', '13.01_1300 & 2200', '13.05_1500, 1700 & 2200',
      '13.06_Section 1600 & 2200', '14.01_1500 &1600 1700', '14.011_1500 , 1600 , 1700 & 2704',
      '14.04_2702', '14.02_1600', '14.021_2607',
      '14.09_2705', '13.08_2706 & 2200', '13.01_710.1.4.of IRC:78 and 2504.2',
      '16.01_2503', '16.04_2504', '16.05_2504', '12.08_1200,1500,1700 A', '12.08_1200,1500,1700 B', '12.08_1200,1500,1700 C', '13.05_1200,1500,1700F(P)', '13.05_1500,1700,2200B(P)', '14.10_2700', '13.06_1600', '14.01_1500,1600,1700', '14.06_2703,1500,1600,1700', '14.18_2600', '12.21_1207', '16.01_1503'] : ['3.16_305', '4.1_401', '4.7_403', '5.2_503', '5.7_510', '5.9_511', '8.5_801', '8.14_804'];
    let item_ana_obj: any = {}
    let item_mes_obj: any = {}
    this.mea_item.map((x: any) => {
      if (cul_item.includes(x['sno'] + '_' + x['ref_to_morth']) && x['filter'] == 'uperLine') {
        // item_obj[x['sno']+'_'+x['ref_to_morth']]=x
        this.boq_items.push(x);
      }
    })

    this._ana_items.map((x: any) => {
      if (x['item_desc'] == 'Say') {
        item_ana_obj[x['item_id']] = x;
      }
    })
    this.mea_item.map((x: any) => {
      if (x['filter'] == 'lowerLine' || x['QTY'] != undefined) {
        item_mes_obj[x['sno'] + '_' + x['ref_to_morth']] = x;
      }
    })


    console.log(item_ana_obj)
    console.log(this.boq_items, '4885')
    this.boq_items.map((x: any) => {
      if (item_ana_obj[x['sno'] + '_' + x['ref_to_morth']] != undefined) {
        x['rate'] = item_ana_obj[x['sno'] + '_' + x['ref_to_morth']]['amount']
        x['amount'] = x['rate']
      } else {
        x['rate'] = 0
        x['amount'] = x['rate']
      }
    })
    this.boq_items.map((x: any) => {
      // console.log(x,x['sno'],x['ref_to_morth'],x['sno']+'_'+x['ref_to_morth'],item_mes_obj[x['sno']+'_'+x['ref_to_morth']])
      if (item_mes_obj[x['sno'] + '_' + x['ref_to_morth']] != undefined || x['filter'] == 'lowerLine' || x['QTY'] != undefined) {
        if (x['QTY'] == undefined) {
          x['qty'] = item_mes_obj[x['sno'] + '_' + x['ref_to_morth']]['Total'];
          x['Unit'] = item_mes_obj[x['sno'] + '_' + x['ref_to_morth']]['Unit'];
          x['amount'] = (x['rate'] * x['qty']); //sda
        } else {
          x['qty'] = item_mes_obj[x['sno'] + '_' + x['ref_to_morth']]['QTY'];
          x['Unit'] = item_mes_obj[x['sno'] + '_' + x['ref_to_morth']]['Unit'];
          x['amount'] = x['rate'] * x['qty'];
        }

      }
    })

    // console.log(this.boq_items,'4900')
    // console.log(this._ana_items,this.mea_item)

  }

  async estimateBridge() {
    let __obj: any = this.Obj
    __obj['budget_path'] = this.budpath
    __obj['b_acct_id'] = this.b_acct_id
    __obj['user_id'] = this.ebillUser.le_id
    __obj['status'] = 'GENERATED'
    __obj['life_cycle_status'] = 'ACTIVE'
    // _obj['bridge_input_data']={}
    // _obj['bridge_input_data']['fundation']=JSON.stringify(this._foundation_obj)
    // _obj['bridge_input_data']['sub_structure']=JSON.stringify(this._sub_str_obj)
    // _obj['bridge_input_data']['protection_work']=JSON.stringify(this._protection_work_obj)
    // _obj['bridge_input_data']['bridge_location']=this._bridge_obj['bridge_location']
    // _obj['bridge_input_data']['bridge_type']=this._bridge_obj['bridge_type']
    // _obj['mes_data']=this.mea_item
    // _obj['ana_data']=this._ana_items
    __obj['data'] = this.boq_items
    __obj['estimation_type'] = this.estimation_type

    __obj['data'].map((x: any) => {
      Object.entries(x).forEach(([key, value]) => {
        x[key] = value == '' || !value ? null : value
      })
    })

    // console.log('all data to sumit ==<><><' , __obj);
    //
    if (this.bridgeUpdate) {
      await this.finalUpdate()
      console.log('updating boq ......')
      var obb: any = new Object()
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['id'] = this.Obj['est_id_p']
      obb['update_user_id'] = this.ebillUser.user_id
      obb['status'] = 'UPDATED'
      this.spinner.show();
      var resp3 = await this.estimateService.updateLifeCycleStatusForBoq(obb)
      if (resp3['error'] == false) {
        console.log('boq status updated ')
        this.spinner.hide();
        this.list()
        Swal.fire('Success', 'Updated Successfully', 'success');
      }
      this.spinner.hide();
      //  return
    }

    this.spinner.show();
    let resp = await this.estimateService.insertBoqBridge(__obj)
    if (resp['error'] == false) {
      if (this.bridgeUpdate) {
        this.spinner.hide();
        return
      } else {
        this.spinner.hide();
      }
      var obb: any = new Object()
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['id'] = this.Obj['est_id_p']
      obb['update_user_id'] = this.ebillUser.user_id
      obb['status'] = 'ACTIVE'
      this.spinner.show();
      var resp2 = await this.estimateService.updateLifeCycleStatus(obb)
      if (resp2['error'] == false) {
        Swal.fire('Success', 'Created Successfully', 'success');
        this.list()
        this.spinner.hide();
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('Error', resp['data'], 'error')
    }

    // return
    // let resp= await this.estimateService.estimateBridge(_obj)
    // if(resp['error']==false){
    //  Swal.fire('Success',resp['data'],'success');
    // }else{
    //   Swal.fire('Error',resp['data'],'error')
    // }
  }
  async finalUpdate() {
    await this.updateBridgeArrEstimation()
    await this.updateBridgeInput()
    await this.updateMeasurement()
    await this.updateAnalysis()
  }

  async estimateRoad() {
    let __obj: any = this.Obj
    __obj['budget_path'] = this.budpath
    __obj['b_acct_id'] = this.b_acct_id
    __obj['user_id'] = this.ebillUser.le_id
    __obj['status'] = 'GENERATED'
    __obj['life_cycle_status'] = 'ACTIVE'
    __obj['data'] = this.boq_items
    __obj['estimation_type'] = this.estimation_type

    console.log('all data to sumit ==<><><', __obj);
    //----------------



    __obj['data'].map((x: any) => {
      Object.entries(x).forEach(([key, value]) => {
        x[key] = value == '' || !value ? null : value
      })
    })


    if (this.roadUpdate) {
      // await this.finalUpdate()
      // console.log('updating boq ......')
      // var obb: any = new Object()
      // obb['b_acct_id'] = this.ebillUser.b_acct_id
      // obb['id'] = this.Obj['est_id_p']
      // obb['update_user_id'] = this.ebillUser.user_id
      // obb['status'] = 'UPDATED'
      // this.spinner.show();
      // var resp3 = await this.estimateService.updateLifeCycleStatusForBoq(obb)
      // if (resp3['error'] == false) {
      //   console.log('boq status updated ')
      //   this.spinner.hide();
      //   this.list()
      //   Swal.fire('Success', 'Updated Successfully', 'success');
      // }
      // this.spinner.hide();
    }
    this.spinner.show();
    let resp = await this.estimateService.insertBoqBridge(__obj)
    if (resp['error'] == false) {
      if (this.roadUpdate) {
        this.spinner.hide();
        return
      } else {
        this.spinner.hide();
      }
      var obb: any = new Object()
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['id'] = this.Obj['est_id_p']
      obb['update_user_id'] = this.ebillUser.user_id
      obb['status'] = 'ACTIVE'
      this.spinner.show();
      var resp2 = await this.estimateService.updateLifeCycleStatus(obb)
      if (resp2['error'] == false) {
        Swal.fire('Success', 'Created Successfully', 'success');
        this.list()
        this.spinner.hide();
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('Error', resp['data'], 'error')
    }
  }


  boqPrint() {
    var txt = "BOQ -";
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var arr = []
        let s = txt + "     Page No. - " + currentPage
        var obj = { text: s, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    var tbl: any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [370, 373],

        body: [



        ]
      }
    };

    var arr = [];
    arr.push({ text: "Work/Youjna", bold: true });
    arr.push(this.Obj['work_name']);
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Type of Bridge", bold: true });
    arr.push({ text: this._bridge_obj['bridge_type'] });
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Type of bridge type", bold: true });
    arr.push({ text: this._bridge_obj['bridge_location'] });
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Road Name", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Janpad/District", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Chetra/Zone", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Vibhag Ka Naam ", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Length", bold: true });
    arr.push('');
    tbl.table.body.push(arr);





    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(tbl);
    dd.content.push({ text: " " });

    var tbl1: any = {

      fontSize: 13,
      table: {

        headerRows: 0,
        widths: ['*'],

        body: [



        ]
      }
    };

    var arr = [];
    arr.push({ text: "Bill of Quantity of Bridge", bold: true, alignment: "center" });
    tbl1.table.body.push(arr);
    dd.content.push(tbl1);
    var tbl2 = {

      fontSize: 10,
      table: {
        headerRows: 1,
        widths: [50, 78, 230, 85, 85, 85, 85],
        body: [
          [{ text: 'Sr. No', alignment: 'center' }, { text: 'Ref. to MORT&H Specif.', alignment: 'center' }, { text: 'Description ', alignment: 'center' }
            , { text: 'Unit', alignment: 'center' }, { text: 'Quantity', alignment: 'center' }, { text: 'Rate(Rs.)', alignment: 'center' }, { text: 'Amount', alignment: 'center' }],
        ],
      }
    };
    dd.content.push(tbl2);
    // console.log("sdffdUinit",this.boq_items)

    for (let i = 0; i < this.boq_items.length; i++) {
      arr = []
      arr.push({ text: this.boq_items[i]['sno'], alignment: 'right' })
      arr.push({ text: this.boq_items[i]['ref_to_morth'], alignment: 'right' })
      arr.push({ text: this.boq_items[i]['item_desc'], alignment: 'left' })
      arr.push({ text: this.boq_items[i]['unit'], alignment: 'right' })

      arr.push({ text: this.boq_items[i]['quantity'] == undefined || !this.boq_items[i]['quantity'] ? (this.boq_items[i]['qty']) : (this.boq_items[i]['quantity']).toFixed(2), alignment: 'right' })


      arr.push({ text: this.boq_items[i]['rate'] == undefined || !this.boq_items[i]['rate'] ? (this.boq_items[i]['rate']) : (this.boq_items[i]['rate']).toFixed(2), alignment: 'right' })

      // arr.push({ text: (this.boq_items[i]['rate']).toFixed(2), alignment: 'right' })


      arr.push({ text: this.boq_items[i]['amount'] == undefined || !this.boq_items[i]['amount'] ? (this.boq_items[i]['amount']) : (this.boq_items[i]['amount']).toFixed(2), alignment: 'right' })
      // arr.push({ text: (this.boq_items[i]['amount']).toFixed(2), alignment: 'right' })
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    pdfMake.createPdf(dd).download("BOQ")
  }


  /*------------------------Bill of Quantity of Bridge E----------------*/



  /*-------------------------------------- get items for Bridge E--------------------------------------------*/

  async getRoadItems() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['cost_rate_std'] = 'MORTH';
    obj['costing_rate_std'] = 'MORTH';
    obj['filter_data'] = ['12.01_304', '12.04_2100', '12.03_304', '12.08_1200,1500,1700 A',
      '12.08_1500 , 1700 & 2100', '12.05_1300',
      '13.02_1300 & 2200', '12.04_1600', '13.01_1300 & 2200', '13.05_1500, 1700 & 2200',
      '13.06_Section 1600 & 2200', '14.01_1500 &1600 1700', '14.011_1500 , 1600 , 1700 & 2704',
      '14.04_2702', '14.02_1600', '14.021_2607',
      '14.09_2705', '13.08_2706 & 2200', '13.01_710.1.4.of IRC:78 and 2504.2',
      '16.01_2503', '16.04_2504', '16.05_2504']
    // let resp = await this.estimateService.getBridgeItems(obj)
    let resp = await this.estimateService.getAnalysisItems(obj)
    if (resp['error'] == false) {
      console.log(resp, '464021')
      let tempArr: any = []
      await resp['data'].map((x: any) => {
        let rqMorth = null;
        if (x.sno != null && x.ref_to_morth != null) {
          if (x['est_type'] != null) {
            let myArray = x['est_type'].split(', ').map((item: any) => item.replace(/'/g, ''));
            let elementToCheck = 'Approach Road-->New Tech'; // here we will need only morth data which are associated with ROAD
            let exists = myArray.includes(elementToCheck);
            if (exists) {
              rqMorth = x.morth_id
            }
            x['est_type'] = myArray
          }
        }

        if (x.morth_id == rqMorth) {
          tempArr.push(x)
        }
      })
      // this.itemsArr = resp['data']
      console.log('ffff', tempArr)

      this.itemsArr = tempArr
      console.log('ffff', this.itemsArr)
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }

  /*-------------------------------------- get items for Bridge E--------------------------------------------*/

  dtlworkflow: any = [];
  tender_apprdata: any
  selectedTender: any
  selectedWork: any


  async seeworkflow(element: any) {
    console.log('element', element)

    this.selectedWork = element.inc_id_f
    // await  this.getTenderId()
    this.tender_apprdata = Object.assign({}, element);
    this.tender_apprdata["level"] = 1;
    this.tender_apprdata["branch"] = 1;
    this.tender_apprdata["doc_local_no"] = element.est_id_p
    this.tender_apprdata["doc_type"] = "ESTIMATE";
    this.tender_apprdata["node_cd"] = element.est_id_p
    this.tender_apprdata["module_cd"] = "EMB";
    await this.getWorkflowlog();

    await this.getWorkflowloguser();
    console.log('level fro appr', this.level_for_approval);
    this.dtlworkflow = this.level_for_approval;

    for (let i = 0; i < this.dtlworkflow.length; i++) {
      if (this.dtlworkflow[i]["data"] != null) {
        let data = JSON.parse(this.dtlworkflow[i]["data"]);
        let dummy = [];
        for (let j = 0; j < data.length; j++) {
          let ob;
          ob =
            data[j]["selected_field"] +
            "( " +
            data[j]["selected_oprater"] +
            "  -  " +
            data[j]["value"] +
            ")";
          dummy.push(ob);
        }
        this.dtlworkflow[i]["data_desc"] = dummy.join();
      }
    }
    $("#Z").modal("show");

    console.log('workflow')


  }

  idtovalue: any = { 0: "NO", 1: "YES" };

  toDown() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }


  isBridge: any = false
  isRoad: any = false
  isGen: any = false
  async workChanged() {
    console.log('WORK SELECTED ==>', this.Obj['work_name'])
    let temp = this.Obj['work_name']
    this.budpath = null
    this.Obj = {}
    this.Obj['work_name'] = temp
    this.isGen = false
    this.isRoad = false
    this.isBridge = false
    this.activeEstimation.map((x: any) => {
      if (this.Obj['work_name'] == x.inc_id_f) {

        if (x.est_type == "BRIDGE") {
          this.isBridge = true
        }
        if (x.est_type == "ROAD") {
          this.isRoad = true
        }
        if (x.est_type == "GENERAL") {
          this.isGen = true
        }

        if (x.status == "UNDERAPPROVAL" || x.status == "STATUS") {
          Swal.fire("Warning", `Estimation of this work already in ${x.status} state`, "warning");
          this.isGen = true
          this.isRoad = true
          this.isBridge = true

          return
        }

        if (this.isGen == true && this.isRoad == true && this.isBridge == true) {
          Swal.fire("Warning", "All Estimation have been already created for this work", "warning");
          return
        }

      }
    })


    // if (this.estimate_data.length >= 1) {
    //   this.est_id_p = resp['data'][resp['data'].length - 1]['est_id_p']
    //   this.est_id_p = this.est_id_p + 1
    //   // await this.setEstDataForAutoFill(resp['data'])
    // } else {
    //   this.est_id_p = 1
    // }

    let isMatch: any = false
    let max_estId: any = 0
    let element: any = []
    this.estimate_data = this.estimate_data.filter((n: any) => n.life_cycle_status == "ACTIVE");
    console.log('est data', this.estimate_data)

    if (this.estimate_data.length >= 1) {

      this.estimate_data.map((x: any) => {

        if (x.inc_id_f == this.Obj['work_name']) {
          console.log('m ', x.est_id_p)
          isMatch = true
          this.est_id_p = x.est_id_p
          element = x;
        }
        max_estId = Math.max(max_estId, x.est_id_p)
      })
      console.log('max', max_estId)

      if (isMatch == false) {
        this.est_id_p = max_estId + 1
      } else {
        this.Obj['path_desc'] = element.path_desc
        this.Obj['budget_amt'] = element.budget_value
        this.Obj['est_desc'] = element.est_desc
        this.Obj['est_date'] = element.est_date
        this.Obj['est_remark'] = element.est_remark
        this.Obj['fund_use'] = element.fund_use


        this.budpath = element.budget_cd
        this.Obj['budget_cd'] = element.budget_head_cd
        // this.est_id = element.est_id_p
        this.spinner.hide();
        await this.getbudgetforupdate()
      }

    } else {
      this.est_id_p = 1
    }
    this.Obj['est_id_p'] = this.est_id_p
    console.log('required e id ', this.est_id_p)


  }

  async viewEst(element: any) {
    console.log('clicked on', element)
    let finalArray: any = []
    finalArray = element.estimates
    this.allEstimateForWork = finalArray
    console.log('all final ==>', this.allEstimateForWork)

  }

  allEstimateForWork: any = []


  async toggleRowExpansion(element: any, i: any) {
    console.log('cl', element)
    if (element['estimates'].length != 1) {
      await this.viewEst(element)
      this.expandedElement = this.expandedElement === element ? null : element;
    }
  }

}
