<div class="page-content fade-in-up">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">
              Mark Attendance of Employee
            </div>
          </div>
          <div class="ibox-body">
            <ul class="nav nav-tabs tabs-line">
              <li class="nav-item">
                <a class="nav-link active" href="#tab-1" *ngIf="list_flag" data-toggle="tab" (click)="refresh()">
                  <i class="ti-bar-chart"></i> LIST
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" *ngIf="create_flag" href="#tab-2" data-toggle="tab"
                    (click)="refresh()"><i class="fa fa-plus-circle"></i> Mark attendance</a>
            </li>
              <!-- Other list items -->
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">
                
                <br>
                <div class="row">
                    
                    <div class="col-3 text-right" >
                      <h6> Select Date :</h6>
                    </div>
                    <div class="col-3  text-left" >
                      <input type="date" class="form-control" [(ngModel)]="punchobj['date']" (change)="getallpunchdata()">
                    </div>
                    <div class="col-6 text-right">
                      <button mat-button mat-raised-button class="btn btn-outline-primary"
                          style="border-radius: 20px;" (click)=open_create()>Mark Attendance</button>
                  </div>
                </div>

                <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                    <div class="col-12 text-center">
                      {{errorMsg}}
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-2"></div>
               
                  <div class="col-2"></div>
                </div>
                <br>
                  <div class="row" >
    
                    <div class=" example-container">
                      <table mat-table [dataSource]="datasource" matSort>
                        <ng-container matColumnDef="serial_no">
                          <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Serial No.
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                            {{i+1}}</td>
                        </ng-container>
                        <ng-container matColumnDef="emp_id">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee ID
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.emp_id}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="zone">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Zone ID
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.zone }} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="emp_name">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Emp Name
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.emp_name }} </td>
                        </ng-container>
                        <ng-container matColumnDef="pi_time">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch In Time
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.create_timestamp | date: 'h:mm:ss a' }} </td>
                        </ng-container>
                        <ng-container matColumnDef="pi_latitude">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch In Latitude
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.pi_latitude }} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="pi_longitude">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch In Longitude
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.pi_longitude }} </td>
                        </ng-container>
                        <ng-container matColumnDef="po_time">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch Out Time
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.update_timestamp | date: 'h:mm:ss a' }} </td>
    
                        </ng-container>
                        <ng-container matColumnDef="po_latitude">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch Out Latitude
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.po_latitude }} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="po_longitude">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch Out Latitude
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.po_longitude }} </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Attendance Date
    
                          </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.date1 }} </td>
                        </ng-container>
    
    
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                      </table>
                      <mat-paginator [pageSizeOptions]="[5,10,20,50,100]" showFirstLastButtons></mat-paginator>
                    </div>
                  </div>


               
              </div>
              <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">
                <br>
                <div class="row">
                    <div class="col-11 text-right">
                        <button mat-button mat-raised-button class="btn btn-outline-primary"
                            style="border-radius: 20px;" (click)=open_list()>Back to List</button>
                    </div>
                </div>
               
                <div class="col-6 text-right">
                  <button class="btn btn-outline-primary" *ngIf="punchout_flag" (click)="startCamera1()">Punch
                    Out</button>
                  <button class="btn btn-outline-primary" *ngIf="punchin_flag" (click)="startCamera()">Punch In</button>

                  <button class="btn btn-outline-success" (click)="capture()" *ngIf="mark_Attend">Click Photo
                  </button>
                  <button class="btn btn-outline-success" (click)="capture1()" *ngIf="mark_Attend1">Click Photo
                  </button>
                  <!-- <button class="btn btn-outline-danger" (click)="close()" *ngIf="turn_off_cam">Turn Off Camera</button> -->
                  <button class="btn btn-outline-danger" (click)="startCamera()" *ngIf="turn_on_flag">Turn ON
                    Camera</button>
                  <button class="btn btn-outline-success" *ngIf="final_submit" (click)="submit()">
                    Submit</button>
                  <button class="btn btn-outline-success" *ngIf="final_submit_punch_out" (click)="submit1()">
                    Submit</button>
                </div>
                <br>
                <div class="row" *ngIf="imgshow">
                  <div class="col-7 text-right">
                    <img  [src]="image"  alt="Captured Image">
                  </div>
                  <div class="col-5 "></div>
                </div>
                <br>
                <div class="row">
                  
                  <div class="col-12 text-center">
                    
                   
                      <canvas #canvasElement class="a"></canvas>
                      <video #videoElement autoplay class="a"></video>
                      
    
    
                    
                  </div>
                  <!-- <div class="col-5 "></div> -->
              
                </div>
               
                

                <br>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  