import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-tracking-footer]',
  templateUrl: './tracking-footer.component.html',
  styleUrls: ['./tracking-footer.component.css']
})
export class TrackingFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

