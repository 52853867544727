<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Lease Property</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag"  data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>Lease Property List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag"  data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i> Create Lease Property</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag"  data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>Update Lease Property</a>
                        </li>
                    </ul>
                    <br>

                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select Lease Layout :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="allPlan_layout" bindLabel="desc"
                                        bindValue="property_lease_group_id" [multiple]="false" placeholder=""
                                        (change)="getAllProperty()" [(ngModel)]="Obj['property_lease_group_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property5']}}">
                                        <!--Filter-->
                                    </mat-form-field>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right" *ngIf="allProp.length>0">
                                    <button class="btn btn-outline-success" (click)="print1()">Download List</button>
                                </div>
                            </div>
                            <br>
                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="plot_no">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Plot/Khasra
                                            no.</th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">{{ element.assigned_property_number }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="lease_type">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Lease Type
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{
                                            element.property_type }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="description">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'property8']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{
                                            element.description }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bigha">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Bigha </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">{{
                                            element.bigha }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="biswa">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Biswa </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{
                                            element.biswa }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="biswansi">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Biswansi
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{
                                            element.biswansi }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="kachwansi">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Kachwansi
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{
                                            element.kachwansi }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="in_sqf">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> In Sqf </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{
                                            element.in_sqf }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="area">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'property9']}} </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{
                                            element.area }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'property10']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-primary"
                                                *ngIf="element.life_cycle_status=='ACTIVE' || element.life_cycle_status=='APPROVED' "
                                                (click)="open_update(element)">{{auth.allLableShowObjProp[auth.language_cd+'property11']}}</button>
                                            <!--Update-->
                                            <button class="btn btn-outline-danger"
                                                *ngIf="element.life_cycle_status=='ACTIVE'"
                                                (click)="delete(element)">{{auth.allLableShowObjProp[auth.language_cd+'property12']}}</button>
                                            <!--Delete-->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        <div *ngIf="update_flag">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper2>
                                <mat-step [stepControl]="first"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'property13']}}">
                                    <!--Select Plan-->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Select Location Hierarchy :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="first_list" bindLabel="hier_buss_name"
                                                        bindValue="hier_type_id" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                                        [selectableGroup]="true" (change)="getAllLevls()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row" *ngFor="let item of data_list,let i=index">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Level {{i+1}} :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value"
                                                        bindValue="lvl1_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl1_cd']" (change)='setLeafNode()' [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value"
                                                        bindValue="lvl2_cd" [multiple]="false" (change)='setLeafNode()' placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()' bindLabel="lvl3_value"
                                                        bindValue="lvl3_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl3_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()' bindLabel="lvl4_value"
                                                        bindValue="lvl4_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl4_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()' bindLabel="lvl5_value"
                                                        bindValue="lvl5_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl5_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()' bindLabel="lvl6_value"
                                                        bindValue="lvl6_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl6_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()' bindLabel="lvl7_value"
                                                        bindValue="lvl7_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl7_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                </div>
                                                <div class="col-3" *ngIf="i==data_list.length-1">
                                                    <button (click)="aleratMsg()"
                                                        class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                                            aria-hidden="true"></i></button>
                                                    &nbsp;&nbsp;
                                                    <button (click)="aleratMsg()"   *ngIf="i!=0"
                                                        class="btn btn-outline-danger">
                                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property53']}}</button>
                                         </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'property19']}}">
                                    <!--Property Details-->
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property54']}}</h6>
                                                    <!--Selected Plan Layout Hierarchy:-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['path_desc']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property55']}}<span
                                                            style="color: red;"> *</span></h6>
                                                    <!--Property Number :-->
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="old_table_data"
                                                        bindLabel="assigned_property_number"
                                                        bindValue="assigned_property_number" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property55']}}"
                                                        [(ngModel)]="Obj['assigned_property_number']"
                                                         disabled
                                                        [selectableGroup]="true" (change)="setSelectPropertyDetails()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                                    </ng-select>
                                                    <!--Select Hierarchy Node-->
                                                </div>
                                                <div class="col-2">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property57']}}</h6>
                                                    <!--Area :-->
                                                </div>
                                                <div class="col-2">{{Obj['area']}}</div>
                                                <div class="col-1">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property58']}}</h6>
                                                    <!--Unit :-->
                                                </div>
                                                <div class="col-1">{{Obj['unit']}}</div>
                                            </div>
                                            <br>

                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property59']}}</h6>
                                                    <!--Property type :-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['property_type']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6> {{auth.allLableShowObjProp[auth.language_cd+'property60']}}<span
                                                            style="color: red;"> *</span></h6>
                                                </div><!-- Enter Description :-->
                                                <div class="col-3">
                                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                        [(ngModel)]="Obj['description']"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property60']}}"
                                                        class="form-control"></textarea>
                                                </div>
                                                <!--Enter Node Code-->
                                            </div>
                                            <br>
                                              <div class="row" *ngFor="let item of data,let i=index">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property62']}}</h6>
                                                 </div>
                                                <div class="col-4">
                                                    <ng-select [items]="features" bindLabel="feature_desc"
                                                        bindValue="feature_id" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property62']}}"
                                                        (change)="setRowValues2(item,i)" [(ngModel)]="item['feature_id']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                 </div>
                                                    <div class="col-3">{{item['feature_desc']}}</div>
                                                <!-- <div class="col-2">
                                                    <span *ngIf="item['feature_id']=='3'">
                                                        <h6>{{auth.allLableShowObjProp[auth.language_cd+'property64']}}
                                                        </h6>
                                                     </span>
                                                    <span *ngIf="item['feature_id']=='4'">
                                                        <button class="btn btn-outline-danger"
                                                            (click)="open_modal(item,i)">{{auth.allLableShowObjProp[auth.language_cd+'property65']}}</button>
                                                     </span>
                                                </div>  -->
                                                <!-- <div class="col-2">
                                                     <input *ngIf="item['feature_id']=='3'" type="number"
                                                        [(ngModel)]="item['feature_desc']" class="form-control" name=""
                                                        id="">
                                                    <span *ngIf="item['feature_id']=='4'"> {{item['feature_desc']}}</span>
                                                    <span *ngIf="item['feature_id']=='2'"> {{item['feature_desc']}}</span>
                                                    <span *ngIf="item['feature_id']=='1'"> {{item['feature_desc']}}</span>
                                                </div> -->
                                                <!-- <div class="col-1">
                                                    <span
                                                        *ngIf="item['feature_id']=='3'">{{auth.allLableShowObjProp[auth.language_cd+'property66']}}</span>
                                                 </div>
                                                <div class="col-1">
                                                    <span
                                                        *ngIf="item['feature_id']=='3'">{{auth.allLableShowObjProp[auth.language_cd+'property67']}}</span>
                                                 </div> -->
                                                <div class="col-2">
                                                    <button *ngIf="i==data.length-1" (click)="addRow(item,i)"
                                                        class="btn btn-outline-primary"><i class="fa fa-plus-circle"
                                                            aria-hidden="true"></i></button>

                                                    <button (click)="removeRow(item,i)" class="btn btn-outline-danger">
                                                        <i class="fa fa-trash" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                            <br>


                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property68']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property69']}}</button>
                                            <!--Next-->


                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="third"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'property36']}}">
                                    <!--Done-->
                                    <!-- <ng-template matStepLabel></ng-template> -->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property70']}}</h6>
                                                    <!-- Select Plan Layout :-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['path_desc']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property71']}}</h6>
                                                    <!--Property Number :-->
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="old_table_data"
                                                        bindLabel="assigned_property_number"
                                                        bindValue="assigned_property_number" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property72']}}"
                                                        disabled [(ngModel)]="Obj['assigned_property_number']"
                                                        [selectableGroup]="true" (change)="setSelectPropertyDetails()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                                    </ng-select>
                                                    <!--Select Hierarchy Node-->
                                                </div>
                                                <div class="col-2">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property73']}}</h6>
                                                    <!--Area :-->
                                                </div>
                                                <div class="col-2">{{Obj['area']}}</div>
                                                <div class="col-1">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property74']}}</h6>
                                                    <!--Unit :-->
                                                </div>
                                                <div class="col-1">
                                                    {{Obj['unit']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property75']}}</h6>
                                                    <!--Property type :-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['property_type']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property76']}}</h6>
                                                    <!-- Enter Description :-->
                                                </div>
                                                <div class="col-3">
                                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                        [(ngModel)]="Obj['description']" disabled
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property77']}}"
                                                        class="form-control"></textarea>
                                                </div>
                                                <!--Enter Node Code-->
                                            </div>
                                            <br>
                                             <div class="row">
                                                <div class="col-12">
                                                    <table class="table table-bordered"
                                                        style="width: 80%; margin-left: 10%;">
                                                        <thead>
                                                            <tr>
                                                                <th>Feature code</th>
                                                                 <th>Feature Description</th>
                                                             </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of data">
                                                                <td>{{item['feature_code']}}</td>
                                                                <td>{{item['feature_desc']}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br>
                                    <br>
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>Back</button>
                                        &nbsp;&nbsp;
                                        <!--Back-->
                                        <button class="btn btn-outline-success"
                                            (click)="update()">{{auth.allLableShowObjProp[auth.language_cd+'property81']}}</button>&nbsp;&nbsp;
                                        <!--Update-->
                                        <!-- <button class="btn btn-warning" >RESET</button>&nbsp;&nbsp; -->
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
