import { Injectable } from '@angular/core';
import { MainService } from '../service/main.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreateEventService {

  b_acct_id:any;
  erpUser:any;
  httpUrl:any
  constructor(private http: HttpClient, private mainService: MainService) {
    this.httpUrl = this.mainService.httpUrl
  }


  async getMultipleEventForModule(obj:any){
    const resp = await this.http.post<any>(this.mainService.httpUrl + '/account/event/getMultipleEventForModule', obj).toPromise().then(res => {
      return res;
    });
    return resp;

  }
  async createevent(obj:any){
    const res = await this.http.post<any>(this.httpUrl + '/accounts/eventgroup/addEventGroup', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

}
