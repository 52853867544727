import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MainService } from '../service/main.service';
import { CbService } from '../service/cb.service';
import { MasterDataService } from '../service/master-data.service';
import { ReferenceDataService } from '../service/reference-data.service';
import { EbillService } from '../service/ebill.service';
import { ApprovalUserService } from '../../portal/service/approval-user.service';
import { CommonDataService } from '../common _services/common-data.service';
import { EmbHierService } from '../service/emb-hier.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-cb',
  templateUrl: './cb.component.html',
  styleUrls: ['./cb.component.css']
})

export class CbComponent implements OnInit {

  constructor(private CommonDataService: CommonDataService, private embHierService: EmbHierService, public ApprovalUserService: ApprovalUserService, private ebillService: EbillService, private cbService: CbService, private masterDataService: MasterDataService, private referenceDataService: ReferenceDataService,
    public mainService: MainService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  ebillUser:any;
  b_acct_id:any;
  undertakings:any = []
  fin_year:any;
  gstArr:any = [{ code: 'cgst_sgst', value: 'CGST/SGST' }, { code: 'igst', value: 'IGST' }]
  gstArrObj:any = { cgst_sgst: 'CGST/SGST', igst: 'IGST' }
  amountTypeArr:any = [{ code: 'FIXED', value: 'FIXED' }, { code: 'PERCENTAGE', value: 'PERCENTAGE' }]
  amountTypeArrObj:any = { FIXED: 'FIXED', PERCENTAGE: 'PERCENTAGE' }
  partyArr:any = []
  partyArrObj:any = {}
  obj:any = {};
  selectAccountType:any;
  cb_status:any = [{ code: 'GENERATED', value: 'GENERATED' }, { code: 'REJECTED', value: 'REJECTED' }]
  allAccountInfo:any = []
  AllAccountObj:any = {};
  hsnArr:any = []
  gstObj:any = {}
  hsnObj:any = {}
  AllBillRow:any = [];
  AllDedRow:any = []
  rateObj:any = {}
  
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
 
  displayedColumns = ['cb_id', 'party_id', 'work_id', 'remark', 'cb_date', 'invoice_no', 'invoice_date', 'status', 'action'];
  datasource:any;
  total_amt = 0
  total_ded_amount = 0
  net_amount = 0
  cgstAmount = 0
  sgstAmount = 0
  igstAmount = 0
  totalAmount = 0
  TaxAmount = 0
  selectedStatus = ''
  systemDate = ''
  allEVents:any = []
  dedTypeObj:any = {}



  allAllocationArr:any = []
  allBudgetHier:any = []
  allProjectHier:any = []
  allProductHier:any = []
  allActivityHier:any = []
  orgShortName:any
  allRule:any = [];
  eventArr:any = [];
  allocationAmountObj:any = {}


  create_flag:boolean=false
  update_flag:boolean=false 
  list_flag:boolean=true;

  createtab(){
    this.refresh
    this.create_flag=true;
    this.list_flag=false
    this.update_flag=false
  }
  
  async listbtab(){
    this.datasource=[] 
    this.work_id=null
    this.list_flag=true
    this.create_flag=false
    this.update_flag=false
    this.ngOnInit()
    
  }

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.spinner.show();
    await this.getAssignedWork();
    await this.getAllWorkInfo();
    await this.getparty();
    await this.getAllDed();
    await this.getAllCB();
    await this.getWorkflowlog()
    await this.getAllHeirNodeList()
    this.spinner.hide();
  }

  assignedWorks:any = {}
  async getAssignedWork() {
    this.spinner.show();
    var work_ids:any = []
    work_ids = Object.values(this.CommonDataService.assignedWork);
    for (var i = 0; i < work_ids.length; i++) {
      this.assignedWorks[work_ids[i]] = 1;
    }
  }


  existing_heirarchy_nodes = []
  async getAllHeirNodeList() {
    
    this.spinner.show();
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.embHierService.getAllEmbHierarchy(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log(resp['data']);
      this.existing_heirarchy_nodes = resp['data']
    } else {
      this.spinner.hide()
      swal.fire('Error...', 'Error While EMB HIER', 'error')
    }
  }

  //workflow
  apprflag:any = false
  level1:any = {}
  level_for_approval:any = []
  workflow_level1:any = {}
  async getWorkflowlog() {
  
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE'
    obj['doc_type'] = 'CB'
    obj['module_cd'] = 'EMB'
    var resp = await this.mainService.getWorkflows(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflow_level1 = resp['data'][0]
      this.level_for_approval = resp['data']
      var currentuser_role = this.ebillUser.role_cd
      for (let i = 0; i < currentuser_role.length; i++) {
        if (this.workflow_level1 != undefined || this.workflow_level1 != null) {
          if (this.workflow_level1['role_cd'] == currentuser_role[i]) {
            this.apprflag = true
          }
        }
      }
      this.level1 = this.workflow_level1
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');
    }

  }
  //updatecbstatus
  tender_apprdata:any = {}
  async Appr(element:any) {

    this.tender_apprdata = Object.assign({}, element)
    this.tender_apprdata['level'] = 1
    this.tender_apprdata['branch'] = 1
    this.tender_apprdata['doc_local_no'] = this.tender_apprdata['cb_id']
    this.tender_apprdata['doc_type'] = 'CB'
    this.tender_apprdata['node_cd'] = this.CommonDataService.workidtonode[element['work_id']]
    this.tender_apprdata['module_cd'] = 'EMB'
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'CB', 'EMB')
    await this.ApprovalUserService.getWorkflowlogbydocno(this.ebillUser, this.tender_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.tender_apprdata)
    $('#myModal2').modal('show');
  }
  forwarded_user_id:any
  appr_remark:any
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();
      obb['user_id'] = this.ebillUser.user_id
      obb['user_name'] = this.ebillUser.party_name
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'CB'
      obb['doc_local_no'] = this.tender_apprdata['cb_id']
      obb['doc_desc'] = this.tender_apprdata['remark']
      obb['role_cd'] = this.level1['role_cd']
      obb['module_cd'] = 'EMB'
      obb['node_cd'] = this.CommonDataService.workidtonode[this.tender_apprdata['work_id']]
      obb['level'] = this.level1['level']
      obb['branch'] = this.level1['branch']
      obb['field_value'] = this.tender_apprdata['net_amt']
      var resp = await this.mainService.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.updatestatus(this.tender_apprdata['cb_id'])
        await this.getAllCB()
        this.spinner.hide();
        swal.fire("Success", "Approval Forwarded Successfully!!", 'success');
      } else {
        this.spinner.hide();
        swal.fire("Error", resp['data'], 'error');
      }
    } else {
      swal.fire("Error", "Please Select Any User..!", 'error');
      this.spinner.hide();
    }
  }
  vendorflag = false
  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();

      var obb:any = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        obb['user_id'] = -1
        obb['vendor_id'] = this.tender_apprdata['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }

      if (obb["user_id"] == -1) {
        let userData:any = {};
        userData['b_acct_id'] = this.b_acct_id;
        userData['user_id'] = obb["vendor_id"];
        let venderInfo = await this.mainService.getVenderName(JSON.stringify(obb));
        if (venderInfo["error"] == false) {
          obb['user_name'] = venderInfo["data"]['party_leagal_name']
        }
      } else {
        for (let i = 0; i < this.ApprovalUserService.workuser.length; i++) {
          if (this.ApprovalUserService.workuser[i]['user_id'] == obb["user_id"]) {
            let h = this.ApprovalUserService.workuser[i]['desc'].split("-");
            obb['user_name'] = h[1];
          }
        }
      }
      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'CB'
      obb['doc_local_no'] = this.tender_apprdata['cb_id']
      obb['doc_desc'] = this.tender_apprdata['remark']
      if(this.ApprovalUserService.vendorflag == false) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'EMB'
      obb['node_cd'] = this.CommonDataService.workidtonode[this.tender_apprdata['work_id']]
      if(this.ApprovalUserService.vendorflag == true){
        obb['level'] = this.tender_apprdata['level']
        obb['branch'] = this.tender_apprdata['branch']
      }
      else{
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      obb['field_value'] = this.tender_apprdata['net_amt']
      var resp = await this.mainService.insertTask(obb)
      if (resp['error'] == false) {
        this.spinner.hide();
      } else {
        this.spinner.hide();
        swal.fire("Error", resp['data'], 'error');
      }
    } else {
      swal.fire("Error", "Please Select Any User..!", 'error');
      this.spinner.hide();
    }
  }
  async updatestatus(id:any) {
    var obb:any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['cb_id'] = id
    obb['update_user_id'] = this.ebillUser.user_id
    obb['status'] = 'UNDERAPPROVAL'
    var resp = await this.cbService.updatecbstatus(obb)
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');
    }
  }
  AllDedList:any = [];
  dedDataArr:any = {}
  async getAllDed() {
    
    this.spinner.show()
    var resp = await this.referenceDataService.getDeductionList(JSON.stringify(this.b_acct_id));
    console.log(resp);
    if (resp['error'] == false) {
      this.AllDedList = resp.data;
      for (let i = 0; i < this.AllDedList.length; i++) {
        this.dedDataArr[this.AllDedList[i]['deduction_id']] = this.AllDedList[i]
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all ded list", 'Error', {
        duration: 5000
      });
    }
  }

  ChangeDed(deduction_id:any, i:any, j:any) {
    console.log(deduction_id);
    this.AllBillRow[i]['ded'][j]['ded_amount'] = this.dedDataArr[deduction_id]['ded_amt'];
    this.AllBillRow[i]['ded'][j]['amt_type'] = this.dedDataArr[deduction_id]['ded_type'];
  }


  AllParty:any = [];
  partyObj:any = {};
  async getparty() {
    var resp = await this.masterDataService.getparty(this.b_acct_id);
    console.log(resp);
    if (resp['error'] == false) {
      this.AllParty = resp.data;
      for (let i = 0; i < this.AllParty.length; i++) {
        this.partyObj[this.AllParty[i]['rep_le_id']] = this.AllParty[i]['party_leagal_name']
      }
    }
  }

  AllWorkInfo:any = []
  workObj:any = {};
  async getAllWorkInfo() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.masterDataService.getWorkInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      var dt = [];
      var temp = resp['data'];
      for (var i = 0; i < temp.length; i++) {
        if (this.assignedWorks[temp[i]['id']] != undefined) {
          dt.push(temp[i])
        }
      }
      this.AllWorkInfo = dt;
      for (let i = 0; i < this.AllWorkInfo.length; i++) {
        this.AllWorkInfo[i]['desc'] = this.AllWorkInfo[i]['work_order_no'] + "-" + this.AllWorkInfo[i]['work_order_name']
        this.workObj[this.AllWorkInfo[i]['work_order_no']] = this.AllWorkInfo[i]['work_order_name']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  allCBInfo = []
  async getAllCB() {
    var resp = await this.cbService.getAllCB(this.b_acct_id);
    console.log(resp);
    if (resp['error'] == false) {
      this.allCBInfo = []
      this.allCBInfo = resp.data;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all cb list", 'Error', {
        duration: 5000
      });
    }
  }
  work_id:any;
  changeList() {
    let dummy:any = []
    for (let i = 0; i < this.allCBInfo.length; i++) {
      if (this.work_id == this.allCBInfo[i]['work_id']) {
        dummy.push(this.allCBInfo[i])
      }
    }
    this.datasource = new MatTableDataSource(dummy)
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
    
  }
  refresh() {
    this.obj = {};
    this.AllBillRow = [];
    this.AllDedRow = []
    this.total_ded_amount = 0
    this.net_amount = 0
    this.total_amt = 0
    this.cgstAmount = 0
    this.sgstAmount = 0
    this.igstAmount = 0
    this.totalAmount = 0
    this.TaxAmount = 0;
    this.withheldamt = 0
  }
  withheldamt = 0;

  ChangeAmount(i:any) {
    this.AllBillRow[i]['total_amount'] = (this.AllBillRow[i]['amount']) + (this.AllBillRow[i]['cgst']) + (this.AllBillRow[i]['sgst']) + (this.AllBillRow[i]['igst'])
  }
  AllworkObj:any
  AllworkpartyObj:any
  changework() {
    this.obj['work_order_name'] = null;
    console.log(this.obj['work_id']);
    for (let i = 0; i < this.AllWorkInfo.length; i++) {
      if (this.AllWorkInfo[i]['work_order_no'] == this.obj['work_id']) {
        this.obj['work_order_name'] = this.AllWorkInfo[i]['work_order_name']
        this.obj['node_cd'] = this.AllWorkInfo[i]['node_cd']
      }
    }
    let planing_path = ''

    this.obj['path_desc'] = null
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (this.obj['node_cd'] == this.existing_heirarchy_nodes[i]['node_cd']) {
        planing_path = this.existing_heirarchy_nodes[i]['path']
      }
    }
    let dum:any = []
    dum = planing_path.split('|')
    let dummy2:any = []
    if (dum.length > 0) {
      for (let i = 0; i < dum.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dum[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    this.obj['path_desc'] = dummy2.join(' --> ')
  }
  addRow() {
    let index = this.AllBillRow.length + 1
    this.AllBillRow.push({ event_id: index, amount: 0, tax_amount: 0, cgst: 0, sgst: 0, igst: 0, ded: [], payable_amount: 0, deduction_amount: 0, total_amount: 0 })
  }
  deleteRow(i:any) {
    this.AllBillRow.splice(i, 1)
  }

  dedAdd(i:any) {
    this.AllBillRow[i]['ded'].push({ deduction_type: null, ded_amount: 0 })
  }
  deleteDed(i:any, j:any) {
    this.AllBillRow[i]['ded'].splice(j, 1)
  }

  async check() {
    var total1 = 0;
    var cgstAmount = 0;
    var sgstAmount = 0;
    var igstAmount = 0;
    var totalAmount = 0;
    this.net_amount = 0;
    let obj:any = new Object();
    for (let i = 0; i < this.AllBillRow.length; i++) {
      cgstAmount = cgstAmount + this.AllBillRow[i]['cgst']
      sgstAmount = sgstAmount + this.AllBillRow[i]['sgst']
      igstAmount = igstAmount + this.AllBillRow[i]['igst']
      let tax_amount = this.AllBillRow[i]['cgst'] + this.AllBillRow[i]['sgst'] + this.AllBillRow[i]['igst']

      this.AllBillRow[i]['tax_amount'] = parseFloat(tax_amount.toFixed(2))
      this.AllBillRow[i]['payable_amount'] = this.AllBillRow[i]['amount'] + parseFloat(tax_amount.toFixed(2))
      this.AllBillRow[i]['total_amount'] = this.AllBillRow[i]['amount'] + parseFloat(tax_amount.toFixed(2))
      let totalDed = 0

      for (let j = 0; j < this.AllBillRow[i]['ded'].length; j++) {
        if (obj[this.AllBillRow[i]['ded'][j]['deduction_id']] == undefined) {
          obj[this.AllBillRow[i]['ded'][j]['deduction_id']] = 0
        }
        if (this.AllBillRow[i]['ded'][j]['amt_type'] == 'PERCENTAGE') {
          totalDed = totalDed + (this.AllBillRow[i]['amount'] * this.AllBillRow[i]['ded'][j]['ded_amount'] / 100)
          obj[this.AllBillRow[i]['ded'][j]['deduction_id']] = obj[this.AllBillRow[i]['ded'][j]['deduction_id']] + (this.AllBillRow[i]['amount'] * this.AllBillRow[i]['ded'][j]['ded_amount'] / 100)
        } else {
          totalDed = totalDed + this.AllBillRow[i]['ded'][j]['ded_amount']
          obj[this.AllBillRow[i]['ded'][j]['deduction_id']] = obj[this.AllBillRow[i]['ded'][j]['deduction_id']] + this.AllBillRow[i]['ded'][j]['ded_amount']
        }
      }

      this.AllBillRow[i]['payable_amount'] = this.AllBillRow[i]['payable_amount'] - totalDed
      this.AllBillRow[i]['deduction_amount'] = totalDed

      this.net_amount = this.net_amount + this.AllBillRow[i]['payable_amount']

      total1 = total1 + this.AllBillRow[i]['amount'] + this.AllBillRow[i]['tax_amount']
      totalAmount = totalAmount + this.AllBillRow[i]['amount']
    }
    this.total_amt = parseFloat(total1.toFixed(2))
    this.totalAmount = parseFloat(totalAmount.toFixed(2))
    this.cgstAmount = parseFloat(cgstAmount.toFixed(2))
    this.sgstAmount = parseFloat(sgstAmount.toFixed(2))
    this.igstAmount = parseFloat(igstAmount.toFixed(2))

    var keys = Object.keys(obj)
    this.total_ded_amount = 0
    this.AllDedRow = []
    for (let i = 0; i < keys.length; i++) {
      this.AllDedRow.push({ 'deduction_name': this.dedDataArr[keys[i]]['ded_name'], 'ded_amount': obj[keys[i]] })
      this.total_ded_amount = this.total_ded_amount + obj[keys[i]]
    }
  }
  getDeductions(element:any) {
    let ob:any = JSON.parse(element.data);
    var temp_AllBillRow:any = ob['payable_rows'];

    let obj:any = new Object();
    for (let i = 0; i < temp_AllBillRow.length; i++) {
      for (let j = 0; j < temp_AllBillRow[i]['ded'].length; j++) {
        if (obj[temp_AllBillRow[i]['ded'][j]['deduction_id']] == undefined) {
          obj[temp_AllBillRow[i]['ded'][j]['deduction_id']] = 0
        }
        if (temp_AllBillRow[i]['ded'][j]['amt_type'] == 'PERCENTAGE') {
          obj[temp_AllBillRow[i]['ded'][j]['deduction_id']] = obj[temp_AllBillRow[i]['ded'][j]['deduction_id']] + (temp_AllBillRow[i]['amount'] * temp_AllBillRow[i]['ded'][j]['ded_amount'] / 100)
        } else {
          obj[temp_AllBillRow[i]['ded'][j]['deduction_id']] = obj[temp_AllBillRow[i]['ded'][j]['deduction_id']] + temp_AllBillRow[i]['ded'][j]['ded_amount']
        }
      }
      this.net_amount = this.net_amount + temp_AllBillRow[i]['payable_amount']
    }

    var keys = Object.keys(obj)
    this.allDed = []
    for (let i = 0; i < keys.length; i++) {
      this.allDed.push({ deduction_id: keys[i], 'deduction_name': this.dedDataArr[keys[i]]['ded_name'], 'ded_amount': obj[keys[i]] })
    }
  }
  allDed:any = []
  async print(element:any) {

    await this.getDeductions(element);
    var account_name=this.ebillUser['account_name'];
    var account_short_name=this.ebillUser['account_short_name'];
    var txt = account_name + "(" +account_short_name+ ") \n";
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var arr = []
        var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true, style: 'header' };
        arr.push(obj);

        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };
    var partyObj:any = this.partyObj[element['party_id']];
    var header1 = {
      columns: [
        {
          width: '*',
          text: 'CB FOR RUNNING BILL',
          bold: true,
          alignment: 'center'
        }

      ],

    }
    var header2 = {
      columns: [
        {
          width: '*',
          text: 'C.B. No. :',
          bold: true

        },
        {
          width: '*',
          text: element['cb_id']
        },
        {
          width: '*',
          text: 'PRINT DATE :',
          bold: true
        },
        {
          width: '*',
          text: this.mainService.dateformatchange(element['cb_date'])
        }
      ],

    }
    var header3 = {
      columns: [
        {
          width: '*',
          text: 'Current Bill :',
          bold: true

        },
        {
          width: '*',
          text: element.cb_id + " For Work ID.:" + element['work_id']
        },
        {
          width: '*',
          text: 'Contractor :',
          bold: true

        },
        {
          width: '*',
          text: partyObj,

        }
      ],

    }

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });


    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });


    dd.content.push(header2);

    dd.content.push(header3);

    dd.content.push({ text: " " });

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });

    var header8 = {
      columns: [
        {
          width: '*',
          text: 'Bill Description',
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'Description of Charge/Number/Date of Authority for all charges requiring special',
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'Amount',
          bold: true,
          alignment: 'right'
        }

      ],


    }
    dd.content.push({ text: " " });
    dd.content.push(header8);

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
    let ob = JSON.parse(element.data);
    var temp_AllBillRow = ob['payable_rows'];

    var totalPayable = 0;

    for (let i = 0; i < temp_AllBillRow.length; i++) {
      totalPayable = totalPayable + temp_AllBillRow[i]['amount'] + temp_AllBillRow[i]['cgst'] + temp_AllBillRow[i]['igst'] + temp_AllBillRow[i]['sgst']
      var eventObj = {
        columns: [
          {
            width: '*',
            text: temp_AllBillRow[i]['event_desc'],
            bold: false,
            alignment: 'center'
          },
          {
            width: '*',
            text: element['work_id'] + " - " + this.workObj[element['work_id']],
            bold: false,
            alignment: 'center'
          },
          {
            width: '*',
            text: temp_AllBillRow[i]['amount'],
            bold: false,
            alignment: 'right'
          }

        ],


      }

      var cgstHeader = {
        columns: [
          {
            width: '*',
            text: "",
            bold: false,
            alignment: 'center'
          },
          {
            width: '*',
            text: "CGST",
            bold: false,
            alignment: 'left'
          },
          {
            width: '*',
            text: temp_AllBillRow[i]['cgst'],
            bold: false,
            alignment: 'right'
          }

        ],


      }
      var sgstHeader = {
        columns: [
          {
            width: '*',
            text: "",
            bold: false,
            alignment: 'left'
          },
          {
            width: '*',
            text: "SGST",
            bold: false,
            alignment: 'left'
          },
          {
            width: '*',
            text: temp_AllBillRow[i]['sgst'],

            bold: false,
            alignment: 'right'
          }

        ],
      }
      var igstHeader = {
        columns: [
          {
            width: '*',
            text: "",
            bold: false,
            alignment: 'left'
          },
          {
            width: '*',
            text: "IGST",
            bold: false,
            alignment: 'left'
          },
          {
            width: '*',
            text: temp_AllBillRow[i]['igst'],

            bold: false,
            alignment: 'right'
          }

        ],
      }

      dd.content.push({ text: " " });
      dd.content.push(eventObj);
      dd.content.push({ text: " " });
      if (temp_AllBillRow[i]['cgst'] != 0) {
        dd.content.push(cgstHeader);
        dd.content.push({ text: " " });
      }
      if (temp_AllBillRow[i]['sgst'] != 0) {
        dd.content.push(sgstHeader);
        dd.content.push({ text: " " });
      }
      if (temp_AllBillRow[i]['igst'] != 0) {
        dd.content.push(igstHeader);
        dd.content.push({ text: " " });
      }



      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
      dd.content.push({ text: " " });

    }


    dd.content.push({ text: " " });
    dd.content.push({ text: "Total Payable : " + totalPayable.toFixed(2), bold: true, alignment: 'right' });

    dd.content.push({ text: " " });

    dd.content.push({ text: "Deductions : ", bold: true });
    dd.content.push({ text: " " });

    var tbl:any = {

      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*'],

        body: [
          [{ text: 'SR NO.', alignment: 'left', bold: true },
          { text: 'Dedution Name', alignment: 'left', bold: true },
          { text: 'Amount', alignment: 'right', bold: true }]
        ]
      }
    };
    var detdata = this.allDed
    var ttl = 0;
    for (var i = 0; i < detdata.length; i++) {
      var arr = [];
      arr.push({ text: i + 1 });
      arr.push({ text: detdata[i]['deduction_name'], alignment: 'left' });
      arr.push({ text: detdata[i]['ded_amount'].toFixed(2), alignment: 'right' });
      ttl = ttl + Number(detdata[i]['ded_amount'])
      tbl.table.body.push(arr)
    }
    dd.content.push(tbl)
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
    dd.content.push({ text: " " });

    dd.content.push({ text: "Total Deductions : " + ttl.toFixed(2), bold: true, alignment: 'right' });
    dd.content.push({ text: " " });
    dd.content.push({ text: "With Held Amount : " + ob.withheldamount.toFixed(2), bold: true, alignment: 'right' });
    dd.content.push({ text: " " });
    var netPayable = 0;
    var netPayable = Number(element.net_amt) - Number(ob.withheldamount);
    dd.content.push({ text: "Net Payable : " + netPayable.toFixed(2), bold: true, alignment: 'right' });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    var header11 = {
      columns: [
        {
          width: '*',
          text: "P.W.C.",
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'D.A.',
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'E.E.',
          bold: true,
          alignment: 'center'
        }


      ],


    }
    var header10 = {
      columns: [
        {
          width: '*',
          text: "Accounts Clerck",
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'Actt.',
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'AAO/AO/C.A.',
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'F.C.',
          bold: true,
          alignment: 'center'
        }

      ],


    }
    var amt_word = this.toWords(totalPayable.toFixed(2));


    dd.content.push(header11);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: "Passed for Payment of Rs." + totalPayable.toFixed(2) + " (" + amt_word + " only)", fontSize: 15 });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    dd.content.push(header10);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    dd.content.push({ text: "Above payment of Rs." + totalPayable.toFixed(2) + " (" + amt_word + " only) sanctioned.", fontSize: 15 });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    var header12 = {
      columns: [
        {
          width: '*',
          text: "Jt. Secy /Add. Secy / Secretary",
          bold: true,
          alignment: 'right'
        }
      ],
    }
    dd.content.push(header12);

    pdfMake.createPdf(dd).download('bill' + '.pdf');


  }
  toWords(s:any) {
    var th = ['', 'thousand', 'million', 'billion', 'trillion'];

    var dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    var tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    var tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    s = s.toString();
    s = s.replace(/[\, ]/g, '');
    if (s != parseFloat(s)) return 'not a number';
    var x = s.indexOf('.');
    if (x == -1) x = s.length;
    if (x > 15) return 'too big';
    var n = s.split('');
    var str = '';
    var sk = 0;
    for (var i = 0; i < x; i++) {
      if ((x - i) % 3 == 2) {
        if (n[i] == '1') {
          str += tn[Number(n[i + 1])] + ' ';
          i++;
          sk = 1;
        } else if (n[i] != 0) {
          str += tw[n[i] - 2] + ' ';
          sk = 1;
        }
      } else if (n[i] != 0) {
        str += dg[n[i]] + ' ';
        if ((x - i) % 3 == 0) str += 'hundred ';
        sk = 1;
      }
      if ((x - i) % 3 == 1) {
        if (sk) str += th[(x - i - 1) / 3] + ' ';
        sk = 0;
      }
    }
    if (x != s.length) {
      var y = s.length;
      str += 'point ';
      for (let i = x + 1; i < y; i++) str += dg[n[i]] + ' ';
    }
    return str.replace(/\s+/g, ' ');
  }

  async save() {
    await this.check()

    let data:any = new Object();
    data['payable_rows'] = this.AllBillRow;
    data['net_amt'] = this.net_amount.toFixed(2);
    data['withheldamount'] = this.withheldamt;


    let obj = Object.assign({}, this.obj);
    obj['b_acct_id'] = this.b_acct_id
    obj['net_amt'] = this.net_amount.toFixed(2)
    obj['create_user_id'] = this.ebillUser.user_id
    obj['data'] = JSON.stringify(data)
    obj['status'] = 'GENERATED';


    console.log(obj);

    this.spinner.show();
    var resp = await this.cbService.createCB(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.obj['cb_id'] = resp.data;
      await this.getAllCB();
      await this.changeList()
      swal.fire("Success", "C-Bill Added Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "Error while Adding CB!", 'error');
    }
  }

  async open_update(element:any) {

    this.update_flag=true
    
    this.create_flag=false
    this.list_flag=false

    this.obj = Object.assign({}, element);
    let ob = JSON.parse(element.data);
    await this.changework();
    this.AllBillRow = ob['payable_rows'];
    this.withheldamt = ob['withheldamount']

    await this.check();
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }


  async update() {
    await this.check()

    let data:any = new Object();

    data['payable_rows'] = this.AllBillRow;
    data['net_amt'] = this.net_amount.toFixed(2);
    data['withheldamount'] = this.withheldamt;


    let obj = Object.assign({}, this.obj);
    obj['b_acct_id'] = this.b_acct_id
    obj['net_amt'] = this.net_amount.toFixed(2)
    obj['create_user_id'] = this.ebillUser.user_id
    obj['data'] = JSON.stringify(data)


    console.log(obj);

    this.spinner.show();
    var resp = await this.cbService.updateCB(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.obj['cb_id'] = resp.data;
      await this.getAllCB();
      await this.changeList()
      swal.fire("Success", "C-Bill Update Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "Error while update CB!", 'error');
    }
  }


  async deleteCB(element:any) {
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['cb_id'] = element.cb_id;

    console.log(obj);

    this.spinner.show();
    var resp = await this.cbService.deleteCB(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      this.obj['cb_id'] = resp.data;
      await this.getAllCB();
      swal.fire("Success", "C-Bill Delete Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "Error while delete CB!", 'error');
    }
  }

}
