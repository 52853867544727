import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { PayrollService } from '../../service/payroll.service';
import { MainService } from '../../service/main.service';
import { EstablishmentService } from '../../service/establishment.service';
import { AllEmpService } from '../../service/all-emp.service'
import swal from 'sweetalert2';

// import { objectify } from 'tslint/lib/utils';
import { SettingService } from '../../service/setting.service';
import { EstablishService } from '../../service/establish.service';
import { now } from 'moment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { MatStepper } from '@angular/material/stepper';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
declare var $: any
@Component({
  selector: 'app-lpctransfer',
  templateUrl: './lpctransfer.component.html',
  styleUrls: ['./lpctransfer.component.css']
})
export class LpctransferComponent implements OnInit {
  salarySlipArr: any = [];
  salarySlipdata: any = [];
  b_acct_id: any;
  selectObj: any = {};
  allArr: any = [];
  Allleave: any = []
  ALLleave: any = []
  payableArr: any = [];
  dedArr: any = [];
  print_flag: any
  payable: any = 0;
  deduction: any = 0;
  total: any = 0;
  lines: any = [];
  sortArr: any = [];
  sortArr1: any = [];
  selectedArr: any = {}
  bankObj: any = {};
  personalInfoObj: any = {};
  allEmplyees_new: any = []
  emp_id: any
  obj3: any = []
  obj: any = {}
  otherded: any = 0
  pay: any = 0
  month: any
  obj2: any = {}
  leaveObj: any = {}
  temp_obj: any = {};
  allLeaveInfo: any = [];
  datasource: any
  allLeaveRule: any = []
  dateToday: any = "0000-00-00";
  monthlysalary: any
  Sarr: any = []
  monthArr: any = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3]
  monthObj: any = { 1: 'January', 2: 'Febuary', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December' }
  all_component: any
  constructor(private estNewService: EstablishService, private settingService: SettingService, private allEmpService: AllEmpService, private establishmentService: EstablishmentService, public mainService: MainService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private payableService: PayrollService) { }
  erpUser: any;
  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllCurrentArrangements();
  }
  async getAllCurrentArrangements() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    // obj['emp_status_code']=['ACTIVE'];
    // var resp = await this.payableService.getAllCurrentArrangements(JSON.stringify(obj));
    // Avinash Mishra
    obj['emp_status_code'] = ['INACTIVE'];
    obj['employee_current_type_code'] = 'TRANSFERRED'
    var resp = await this.payableService.getAllTransferEmployee(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allArr = resp['data'];
      // Avniash ---------------------

      let temp = this.allArr.map((item: any) => {
        if (item.employee_current_type_code == 'TRANSFERRED') {
          return item;
        }

      })

      console.log(" this is modified temp ==", temp);




      // --------------------------
      this.allEmplyees_new = [];
      for (let i = 0; i < this.allArr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, this.allArr[i]);
        obj['emp_name'] = obj['emp_name'] + "-" + this.getNumberFormat(obj['emp_id'])
        this.allEmplyees_new.push(obj)
        //         var date = new Date(this.allEmplyees_new[i][' effective_timestamp'])
        //         this.allEmplyees_new[i][' effective_timestamp']=date.toISOString().split('T')[0]
        // console.log(this.allEmplyees_new[i][' effective_timestamp'])
      }
    }
  }
  getNumberFormat(num: any) {
    return num.toString().padStart(3, "0")
  }

  //
  //

  async getAllLeaveRules() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.estNewService.getAllRules(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allLeaveRule = resp.data;
    } else {
      swal.fire('Error', 'Error while getting  all Leave Rule list ', 'error')
      // this.snackBar.open("Error while getting  all Leave Rule list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  //

  //
  //


  modify = [{
    PF: 0,
    BASIC: 0,
    GIS: 0,
    DA: 0,
    ITAX: 0,
    Comp_Alw: 0,
    MED: 0,
    HRA: 0,
    CONV: 0,

  }];
  deciablePrint() {
    this.print_flag = '';
  }
  async getLpctransfer() {
    // let today=new Date().toISOString().slice(0, 10);
    // this.today = new Date().toISOString().slice(0, 10);
    if (this.selectObj['posting_point'] == '' || this.selectObj['posting_point'] == null || this.selectObj['posting_point'] == undefined ||
      this.selectObj['retirement_date'] == '' || this.selectObj['retirement_date'] == null || this.selectObj['retirement_date'] == undefined ||
      this.selectObj['reason_of_retirement'] == '' || this.selectObj['reason_of_retirement'] == null || this.selectObj['reason_of_retirement'] == undefined ||
      this.selectObj['emp_id'] == '' || this.selectObj['emp_id'] == null || this.selectObj['emp_id'] == undefined) {
      this.print_flag = ''
      swal.fire('Warning', '* Field Required', 'warning');
      this.print_flag = ''
      return;
    }
    // if( this.selectObj['retirement_date']<today)
    // {

    // swal.fire('Warning','Transfer Date Must Be less Than Today','warning');
    // return;

    // }
    this.payableArr = [];
    this.dedArr = [];
    this.payable = 0;
    this.obj = {}
    this.deduction = 0;
    this.total = 0;
    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectObj['emp_id'];
    obj['transfer_date'] = this.selectObj['retirement_date']
    this.emp_id = this.getNumberFormat(obj['emp_id'])
    this.spinner.show();
    //sawait this.getamtsalary()
    var resp = await this.payableService.getMaxYearAndMonth(JSON.stringify(obj));
    if (resp['error'] == false) {
      var obj: any = new Object()
      obj['b_acct_id'] = this.b_acct_id;
      var resp1 = await this.payableService.getCompCode(JSON.stringify(obj));
      if (resp1['error'] == false) {
        this.all_component = resp1.data;
        console.log(this.all_component)
        console.log(this.all_component.length)

      }
      else {
        return;
      }
      for (let i = 0; i < this.all_component.length; i++) {
        this.obj2[this.all_component[i]['pay_component_code']] = 0.00
      }

      console.log(this.obj2)

      for (var i = 0; i < this.allArr.length; i++) {
        if (this.allArr[i].emp_id == this.selectObj['emp_id']) {
          this.selectedArr = this.allArr[i];
        }
      }
      if (this.selectedArr['employee_current_type_code'] == 'TRANSFERRED') {
        await this.getPaySlip(resp.data[0]['fin_year'], resp.data[0]['month'])
        this.spinner.hide();

      }
      else {
        swal.fire("Sorry", this.selectedArr['emp_name'] + " is not TRANSFERRED!", 'info');
        this.spinner.hide();
      }



      // await this.buildSlip()

    }
    //

    await this.getAllLeaveRules()

    this.Allleave = [];
    var x = this.dateToday.split('-');
    for (var i = 0; i < this.allLeaveRule.length; i++) {
      var ob: any = {}
      ob['emp_id'] = this.emp_id;
      ob['leave_code'] = this.allLeaveRule[i].leave_code;
      ob['leave_rate'] = this.allLeaveRule[i].leave_rate;
      ob['is_leave_carry_fwd'] = this.allLeaveRule[i].is_leave_carry_fwd;
      ob['renew_ind_on_year_change'] = this.allLeaveRule[i].renew_ind_on_year_change;
      ob['year'] = parseInt(x[0]);
      ob['month'] = parseInt(x[1]);
      ob['num_of_leaves'] = this.allLeaveRule[i].num_of_leaves;
      ob['leaves_remaining'] = this.allLeaveRule[i].num_of_leaves;
      ob['adjust_remaining_leaves'] = 0;
      ob['carry_forward_leaves'] = 0;
      ob['create_user_id'] = this.erpUser.user_id;
      this.Allleave.push(ob);


    }
    //

    console.log(this.Allleave)
  }

  // async getamtsalary(){
  //   var obj :any  = new  Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['emp_id'] = this.selectObj['emp_id'];
  //   obj['arr_id']=this.selectObj['arr_id'];
  // var resp2 = await this.payableService.getSalaryamtlpcTrans(JSON.stringify(obj));
  //  if(resp2['error']==false)
  //  {
  //   this.salarySlipdata=resp2.data
  //  // console.log(this.salarySlipdata)
  //  }
  //  else{
  //    return ;
  //  }

  // }

  //calculate basic da and etc //

  a: any = [{ BASIC: 0, DA: 0, GIS: 0, HRA: 0, MED: 0, WA: 0, PF: 0, Comp_Alw: 0, I_Tax: 0, CONV: 0 }]

  b: any = [];
  async buildSlip() {

    console.log(this.salarySlipArr);
    console.log(this.salarySlipdata)
    //await this.getamtsalary()
    let tenMonthArr: any = [];
    let tenMonthObj: any = {};
    for (let i = 0; i < this.salarySlipArr.length; i++) {
      if (this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']] == undefined) {
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']] = {};
      }

      if (Object.keys(this.temp_obj).length > 0 && this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['total1'] == undefined) {

        let bobj: any = {}
        for (let i = 0; i < this.all_component.length; i++) {
          bobj[this.all_component[i]['pay_component_code']] = 0.00
        }
        console.log(bobj)
        let obj2: any = {}
        for (let i = 0; i < this.all_component.length; i++) {
          obj2[this.all_component[i]['pay_component_code']] = 0.00
        }
        console.log(obj2)
        //this.temp_obj[this.salarySlipArr[i]['month']+'-'+this.salarySlipArr[i]['fin_year']]['total1']={'BASIC':0.00,'DA':0.00,'DEP':0.00,'HRA':0.00,'MA':0.00,'VA':0.00,'WA':0.00,'miscpay':[],'CCA':0.00,'LIC1':0.00,LIC2:0.00,LIC3:0.00,LIC4:0.00,LIC5:0.00,LIC6:0.00,LIC7:0.00,PF:0.00,NPS:0.00,GIS:0.00,IT:0.00,HRR:0.00,HR:0.00,VD:0.00,VADV:0.00,BLDADV1:0.00,BLDADV2:0.00,BLDADV3:0.00,PFADV:0.00,PFADV1:0.00,PFADV2:0.00,BADV:0.00,EWF:0.00,miscded:[],total:0.00,net:0.00};
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['total1'] = bobj
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['netpay'] = 0.00;
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['totalpay'] = 0.00;
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['totalded'] = 0.00;

        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['fin_year'] = this.salarySlipArr[i]['fin_year'];
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['year'] = this.salarySlipArr[i]['fin_year'];
        // this.temp_obj[this.salarySlipArr[i]['month']+'-'+this.salarySlipArr[i]['fin_year']]['num_of_days']=this.salarySlipArr[i]['num_of_days'];
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['emp_name'] = this.salarySlipArr[i]['emp_name'];
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['emp_id'] = this.salarySlipArr[i]['emp_id'];
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['month'] = this.salarySlipArr[i]['month'];
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['month'] = Number(this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['month'])
        tenMonthObj[i] = { 'month': this.salarySlipArr[i].month, 'year': this.salarySlipArr[i].fin_year }
      }

      if (this.salarySlipArr[i]['pay_code'] == 'PAY') {

        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['totalpay'] += parseFloat(this.salarySlipArr[i]['pay_component_amt'])
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['netpay'] += parseFloat(this.salarySlipArr[i]['pay_component_amt'])
        //this.totalobj[this.salarySlipArr[i]['pay_component_code']] +=this.salarySlipArr[i]['pay_component_amt']

        this.obj2[this.salarySlipArr[i]['pay_component_code']] += parseFloat(this.salarySlipArr[i]['pay_component_amt'])

      }
      else {
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['totalded'] += parseFloat(this.salarySlipArr[i]['pay_component_amt'])
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['netpay'] -= this.salarySlipArr[i]['pay_component_amt']
        //this.totalobj[this.salarySlipArr[i]['pay_component_code']] +=this.salarySlipArr[i]['pay_component_amt']
        this.obj2[this.salarySlipArr[i]['pay_component_code']] += parseFloat(this.salarySlipArr[i]['pay_component_amt'])

      }
      //console.log(this.obj2)

      if (this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['total1'][this.salarySlipArr[i]['pay_component_code']] != undefined) {
        this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['total1'][this.salarySlipArr[i]['pay_component_code']] += parseFloat(this.salarySlipArr[i]['pay_component_amt'])
        // this.Sarr[this.salarySlipArr[i]['pay_component_code']]=this.temp_obj[this.salarySlipArr[i]['month']+'-'+this.salarySlipArr[i]['fin_year']]['total1'][this.salarySlipArr[i]['pay_component_code']]
      }
      else {
        if (this.salarySlipArr[i]['pay_code'] == 'PAY') {
          this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['total1']['miscpay'].push(this.salarySlipArr[i]['pay_component_amt'])
          //this.totalobj['miscpay'] +=this.salarySlipArr[i]['pay_component_amt']
          this.obj2['miscpay'] += parseFloat(this.salarySlipArr[i]['pay_component_amt'])

        } else {
          // this.totalobj['miscded'] +=this.salarySlipArr[i]['pay_component_amt']
          this.obj2['miscded'] += parseFloat(this.salarySlipArr[i]['pay_component_amt'])

          this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['total1']['miscded'].push(this.salarySlipArr[i]['pay_component_amt'])

        }
      }

    }
    console.log(this.temp_obj)
    console.log(this.Sarr)
    for (var i = 0; i < this.salarySlipArr.length; i++) {
      if (this.salarySlipArr[i].pay_code == 'PAY') {
        this.payableArr.push(this.salarySlipArr[i]);
        this.payable += this.salarySlipArr[i].pay_component_amt;

      } else if ((this.salarySlipArr[i].pay_code == 'DED')) {
        this.dedArr.push(this.salarySlipArr[i]);
        this.deduction += this.salarySlipArr[i].pay_component_amt;

      }
    }
    console.log(this.payable)
    console.log(this.deduction)
    for (var i = 0; i < this.salarySlipArr.length; i++) {
      var ob = this.salarySlipArr[i];
      if (this.obj[ob['pay_component_code']] == undefined) {
        //this.fixedpay.push(ob['pay_component_code'])
        this.obj[ob['pay_component_code']] = ob['pay_component_amt'];
      } else {
        this.obj[ob['pay_component_code']] += ob['pay_component_amt'];
      }
    }
    for (var i = 0; i < this.dedArr.length; i++) {
      var ob = this.dedArr[i];
      if (ob['pay_component_code'] == 'PF' || ob['pay_component_code'] == 'IT' || ob['pay_component_code'] == 'HR') {

      }
      else {
        this.otherded += ob['pay_component_amt'];

      }
    }
    if (this.payableArr.length > this.dedArr.length) {
      this.lines = this.payableArr;

    } else {
      this.lines = this.dedArr;
      console.log(this.lines)
    }
    console.log(this.otherded)
    console.log(this.lines)
    this.total = parseFloat((this.payable - this.deduction).toFixed(2));
    console.log(this.total)

    const addresses = Object.values(tenMonthObj);
    tenMonthArr = addresses;

    this.sortArr = tenMonthArr.sort(
      function (a: any, b: any) {
        if (a.year == b.year) {
          return (a.month - b.month);
        } else {
          return (a.year - b.year);
        }
      });
    console.log(this.sortArr)
    var mArr = this.sortArr;
    var sarr = []
    for (let i = 0; i < mArr.length; i++) {
      if (i == mArr.length - 1) {
        // sarr.push(mArr[i]['month']+'-'+mArr[i]['year'])
        sarr.push(mArr[i]['month'])
      }
    }
    console.log(sarr)
    //console.log(this.temp_obj[this.salarySlipArr[i]['month']+'-'+this.salarySlipArr[i]['fin_year']]['month'])
    this.Sarr = Object.keys(this.temp_obj)
    console.log(this.Sarr)
    var karr = []
    for (let i = 0; i < this.Sarr.length; i++) {
      if (i == this.Sarr.length - 1) {
        karr.push(this.Sarr[i])
      }
    }
    console.log(karr)

    for (let i = 0; i < this.salarySlipArr.length; i++) {
      for (let j = 0; j < sarr.length; j++) {
        if (sarr[j] == this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['month']) {
          if (this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['total1'][this.salarySlipArr[i]['pay_component_code']] != undefined) {

            this.obj3 = this.temp_obj[this.salarySlipArr[i]['month'] + '-' + this.salarySlipArr[i]['fin_year']]['total1']

          }
        }
      }
    }
    console.log(this.obj3)
    //--------------------


    this.b.push(this.obj3);

    var key: any = []
    var key2: any = []
    for (let i in this.b[0]) {
      key.push(i)

    }
    // console.log("kiii com",key)
    for (let j in this.a[0]) {
      key2.push(j);
    }
    // console.log("kiii2 main",key2)
    // console.log("kiii2 length--",key2.length)

    var data = key2.filter((item: any, ind: any) => {
      key.filter((itm: any, i: any) => {
        if (item.includes(itm)) {
          //   console.log("yes mathck",item,itm,i,ind)
          console.log(this.b[0][itm]);
          this.a[0][key2[i]] = this.b[0][itm]
          return
        }

        return
      })
    })

    console.log("data is", key2)
    console.log("data is", this.a[0])
    // this.obj3 = []
    // this.obj3 = this.a;
    // console.log("this is the modifyed obj 3 ---", this.obj3)
    //----------------------
    let varArr = [];

    for (let i = 0; i < this.sortArr.length; i++) {
      varArr.push(this.sortArr[i].month);
    }
    console.log(varArr);
    this.sortArr1 = Object.values(this.sortArr)
    console.log(this.sortArr1)

    console.log(this.temp_obj)
    // console.log(this.totalobj)
    console.log(this.obj2)


    this.buildsalary()


  }

  //reference date
  async getDate() {
    var resp = await this.estNewService.getDate();
    if (resp['error'] == false) {
      this.dateToday = resp.data;
      console.log(this.dateToday)
    } else {

    }
  }





  //

  //
  async getPaySlip(fin_year: any, month: any) {
    this.payableArr = [];
    this.dedArr = [];
    this.payable = 0;
    this.deduction = 0;
    this.total = 0;
    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectObj['emp_id'];
    obj['arr_id'] = Number(this.selectObj['arr_id']);
    obj['fin_year'] = fin_year;
    obj['month'] = month;
    this.spinner.show();
    var resp = await this.payableService.getSalarylpcTrans(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp['data'].length <= 0) {
        this.selectObj = {}
        swal.fire("Info", 'No Data Found !', 'info');
        this.print_flag = "";
        return;
      }

      this.salarySlipArr = resp.data;
      //this.salarySlipArr['arr_id']=Number(this.selectObj['arr_id']);
      for (let i = 0; i < this.salarySlipArr.length; i++) {
        this.salarySlipArr[i]['arr_id'] = Number(this.selectObj['arr_id']);
      }
      console.log(this.salarySlipArr)
      await this.buildSlip();
      this.getArr();
      this.getPersonalInfo();
      //sthis.getamtsalary()
      //this.getBankInfo();
      this.spinner.hide();

      if (this.selectedArr['employee_current_type_code'] == 'TRANSFERRED') {
        this.print_flag = "T";
        //this.print2();
      }
    } else {
      this.spinner.hide();
    }
    // console.log(resp);

  }

  async getArr() {
    for (var i = 0; i < this.allArr.length; i++) {
      if (this.allArr[i].emp_id == this.selectObj['emp_id']) {
        this.selectedArr = this.allArr[i];
      }
    }
  }
  async getPersonalInfo() {
    this.personalInfoObj = {};
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectObj['emp_id'];
    this.selectObj['reason_of_retirement_and_date'] = this.selectObj['reason_of_retirement'] + ' ' + this.selectObj['retirement_date']
    this.spinner.show();
    var resp = await this.allEmpService.getPersonalInfo(obj);
    if (resp['error'] == false) {
      this.personalInfoObj = resp.data[0];
    }

  }
  buildsalary() {
    var date = this.selectObj['retirement_date']
    var dArr = date.split('-');
    this.month = dArr[1]
    this.monthlysalary = this.total - this.obj['DA']

  }

  deleteLeave(i: any) {
    this.Allleave.splice(i, 1)
  }

  async print2() {

    await this.getLpctransfer()
    if (this.salarySlipArr.length == 0) {
      this.selectObj = {}
      swal.fire("Info", 'No Data Found !', 'info');

      return;

    }
    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + "-" + "LPC-For-Transfer";
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt + "  Page No. - " + currentPage, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        return obj;
      },
      pageOrientation: 'landscape',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    }

    var header1 = {
      columns: [
        {
          width: '*',
          text: 'Last Pay Certificate',
          bold: true,
          alignment: "center",
          fontSize: 15
        },

      ],
    }
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1100, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });

    dd.content.push(header1);
    dd.content.push({ text: " " });
    //dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1700, y2: 0, lineWidth: 0.05 ,  bold: true}] });
    // dd.content.push({ text: " " });
    let data = [{ key: "1. Name of Officer/ Employee:", value: this.selectedArr['emp_name'] }, { key: '2. Designation of Officer/ Employee :', value: this.selectedArr['designation_code'] }, { key: '3. Govt Order and Date :', value: this.selectObj['reason_of_retirement_and_date'] }, { key: '4. Posting Point :', value: this.selectObj['posting_point'] }]

    for (let i = 0; i < data.length; i++) {
      if (!data[i]["value"]) {
        data[i]["value"] = "";
      }
    }

    var tb2 = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 0,
        widths: ['*', '*'],
        body: [[data[0]["key"], data[0]["value"]]],
      }
    };
    dd.content.push(tb2);
    for (var i = 1; i < data.length; i++) {
      var arr = []
      arr.push(data[i]["key"])
      arr.push(data[i]["value"])


      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 761, y2: 0, lineWidth: 0.05 }] });


    var header2temp = {
      columns: [
        {
          width: '*',
          text: '1. Particulars',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push({ text: " " });
    dd.content.push(header2temp);
    // dd.content.push({ text: " " });

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 90, y2: 0, lineWidth: 0.09, bold: true }] });
    dd.content.push({ text: " " });
    var sub1header2temp = {
      columns: [
        {
          width: '*',
          text: 'Pay Scale/Grade Pay:',
          bold: true,
        },
        {
          width: '*',
          text: this.selectedArr['pay_scale_code'] + "/" + this.selectedArr['grade_pay_code'],

        },
        {

          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        },
      ],
    }
    dd.content.push(sub1header2temp)
    dd.content.push({ text: " " });
    //dd.content.push(header08)
    var subheader2temp = {
      columns: [
        {
          width: '*',
          text: 'Pay',
          bold: true,
          // alignment:"center",
        },
        {
          width: '*',
          text: this.selectedArr['pay_commission_code'],

          // alignment:"center",
        },
        {

          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        },
      ],
    }
    dd.content.push(subheader2temp)
    // dd.content.push(header09)
    dd.content.push({ text: " " });


    var sub2header2temp = {
      columns: [
        {
          width: '*',
          text: 'PF:',
          bold: true,
        },
        {
          width: '*',
          text: this.obj3['PF'].toFixed(2) || 0,
          // bold: true,
        },
        {

          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        },
      ],
    }
    dd.content.push(sub2header2temp)
    dd.content.push({ text: " " });

    var header3temp = {
      columns: [
        {
          width: '*',
          text: 'a. Allowance',
          bold: true,
        },
        //  {
        //   width: '*',
        //   text: ''
        // },

        {
          width: '*',
          text: 'b. Deductions',
          bold: true,
        },

      ]
    }
    dd.content.push(header3temp)
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 90, y2: 0, lineWidth: 0.09, bold: true }] });
    dd.content.push({ text: " " });

    var sub2header3temp = {
      columns: [
        {
          width: '*',
          text: 'BASIC :' + '  ' + (this.obj3['BASIC'] ? this.obj3['BASIC'].toFixed(2) : 0),

          bold: true,
        },
        {
          width: '*',
          text: 'GIS :' + '  ' + (this.obj3['GIS'] ? this.obj3['GIS'].toFixed(2) : 0),

          bold: true,
        },
        //  {

        //   width: '*',
        //   text: '',
        //   bold: true
        // },

        // {
        //   width: '*',
        //   text: ''
        // },
      ],
    }
    dd.content.push(sub2header3temp)
    dd.content.push({ text: " " });


    var sub2header4temp = {
      columns: [
        {
          width: '*',
          text: 'DA :' + '  ' + (this.obj3['DA'] ? this.obj3['DA'].toFixed(2) : 0),
          bold: true,
        },

        {

          width: '*',
          text: 'I. Tax :' + '  ' + (this.obj3['I_Tax'] ? this.obj3['I_Tax'].toFixed(2) : 0),
          bold: true
        },


      ],
    }
    dd.content.push(sub2header4temp)
    dd.content.push({ text: " " });


    var sub2header5temp = {
      columns: [
        {
          width: '*',
          text: 'Comp Alw :' + '  ' + (this.obj3['Comp_Alw'] ? this.obj3['Comp_Alw'].toFixed(2) : 0),

          bold: true,
        },
        {
          width: '*',
          text: 'MED :' + '  ' + (this.obj3['MED'] ? this.obj3['MED'].toFixed(2) : 0),
          bold: true,
        },
      ],
    }
    dd.content.push(sub2header5temp)
    dd.content.push({ text: " " });
    var sub2header6temp = {
      columns: [
        {
          width: '*',
          text: 'HRA :' + '  ' + (this.obj3['HRA'] ? this.obj3['HRA'].toFixed(2) : 0),
          bold: true,
        },
        //    {
        //     width: '*',
        //     text: 'GIS :' +'  '+this.obj2['GIS'],
        //    // bold: true,
        //  },
        {

          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        },
      ],
    }
    dd.content.push(sub2header6temp)
    dd.content.push({ text: " " });
    var sub2header7temp = {
      columns: [
        {
          width: '*',
          text: 'CONV :' + '  ' + (this.obj3['CONV'] ? this.obj3['CONV'].toFixed(2) : 0),
          bold: true,
        },
        //    {
        //     width: '*',
        //     text: 'HRA :' +'  '+this.obj2['HRA'],
        //    // bold: true,
        //  },
        {

          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        },
      ],
    }
    dd.content.push(sub2header7temp)
    dd.content.push({ text: " " });


    var header9temp = {
      columns: [
        {
          width: '*',
          text: '2. He made over charge of office of Development Authority.',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(header9temp);
    dd.content.push({ text: " " });
    var header10temp = {
      columns: [
        {
          width: '*',
          text: '3. He is entitled to draw the following :-',
          bold: true,
          // alignment:"center",
        },

      ],
    }

    dd.content.push(header10temp);
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300, y2: 0, lineWidth: 0.09, bold: true }] });
    dd.content.push({ text: " " });

    var headersub9temp = {
      columns: [
        {
          width: '*',
          text: 'a) E.L As per Service books _______days  Due on___________',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headersub9temp);
    dd.content.push({ text: " " });

    var headertemp1 = {
      columns: [
        {
          width: '*',
          text: '(b) C.L _______days  Due on___________',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headertemp1);
    dd.content.push({ text: " " });

    let td = (this.selectObj['retirement_date']).split("-");
    let cur_date = new Date();
    let cur_year = cur_date.getFullYear();
    if (cur_year == Number(td[0])) {
      if (Number(td[1]) == this.selectedArr['inc_month']) {
        let f = (this.selectedArr['inc_month']);
        this.next_inc_date = this.monthObj[f] + ',' + (Number(cur_year) + 1);
      } else {
        this.next_inc_date = this.monthObj[this.selectedArr['inc_month']] + ',' + cur_year;
      }
    }
    else {
      this.next_inc_date = this.monthObj[this.selectedArr['inc_month']] + ',' + (Number(td[0]) + 1);
    }
    var headersub10temp = {
      columns: [
        {
          width: '*',
          text: '5. Next Increment Date ' + this.next_inc_date,
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headersub10temp);
    dd.content.push({ text: " " });
    var headersub11temp = {
      columns: [
        {
          width: '*',
          text: '5. Recoveries are to be made from the pay of the Govt. Servant detailed above. ',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headersub11temp);
    dd.content.push({ text: " " });
    var headersub11temp = {
      columns: [
        {
          width: '*',
          text: '6. He is also entitled to join time as admissible under the rule.',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headersub11temp);
    dd.content.push({ text: " " });
    var headersub12temp = {
      columns: [
        {
          width: '*',
          text: '7. The details of the Income Tax Recovered from him upto the date from begining of the current year are ',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headersub12temp);
    // dd.content.push({ text: " " });

    var headersub13temp = {
      columns: [
        {
          width: '*',
          text: '8. Letter ',
          bold: true,
          // alignment:"center",
        },
        {
          width: '*',
          text: 'Date ...................',

        },
      ],
    }
    dd.content.push(headersub13temp);
    // dd.content.push({ text: " " });

    var headersub15temp = {
      columns: [
        {
          width: '*',
          text: ' To',
          bold: true,
          // alignment:"center",
        },
      ],
    }
    dd.content.push(headersub15temp);
    // dd.content.push({ text: " " });
    var headersub16temp = {
      columns: [
        {
          width: '*',
          text: ' _______________________________________________',
          bold: true,
          // alignment:"center",
        },
      ],
    }
    dd.content.push(headersub16temp);
    // dd.content.push({ text: " " });
    var headersub17temp = {
      columns: [
        {
          width: '*',
          text: ' _____________________________________',
          bold: true,
          // alignment:"center",
        },
      ],
    }
    dd.content.push(headersub17temp);
    // dd.content.push({ text: " " });
    var headersub18temp = {
      columns: [
        {
          width: '*',
          text: 'Competent Officer /J.S/Incharge(Estt.) ',
          bold: true,
          // alignment:"center",
        },
        {
          width: "*",
          text: 'Finance Controller'
        },
      ],
    }
    dd.content.push(headersub18temp);
    dd.content.push({ text: " " });

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    // dd.content.push({ text: " " });
    // dd.content.push({ text: " " });
    // dd.content.push({ text: " " });
    // dd.content.push({ text: " " }); dd.content.push({ text: " " });
    // dd.content.push({ text: " " }); dd.content.push({ text: " " });
    // dd.content.push({ text: " " }); dd.content.push({ text: " " });
    // dd.content.push({ text: " " }); dd.content.push({ text: " " });
    // dd.content.push({ text: " " }); dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    var header_sd10 = {
      columns: [
        {
          width: '*',
          text: 'Details of Salary from March to ' + this.selectObj['retirement_date'],
          bold: true,
          alignment: "center",
          fontSize: 15
        },

      ],
    }
    dd.content.push(header_sd10);

    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1100, y2: 0, lineWidth: 0.05 }] });

    dd.content.push({ text: " " });

    let list = ['Month']
    let widths = []
    let data_keys = []
    // list.push('Month')
    widths.push('auto')
    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'PAY') {
        list.push(this.all_component[i]['pay_component_code'])
        console.log(list)
        data_keys.push(this.all_component[i]['pay_component_code'])
        widths.push('auto')
      }
    }
    list.push('Total\npay')
    widths.push('auto')

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'DED') {
        list.push(this.all_component[i]['pay_component_code']);
        data_keys.push(this.all_component[i]['pay_component_code'])

        widths.push('auto')
      }
    }
    //  // dd.content.push({ text: " " })
    list.push('Total\nDed');
    widths.push('auto')
    list.push('Net\nSal');
    // list.push('Net\nSum')
    widths.push('auto')
    var tbl: any = {
      pageSize: 'A1',
      layout: {
        hLineWidth: function (i: any, node: any) {
          return (i === 0 || i === node.table.body.length) ? 2 : 1; // header and footer horizontal lines
        },
        vLineWidth: function (i: any, node: any) {
          return (i === 0 || i === node.table.widths.length) ? 2 : 1; // header and footer vertical lines
        },
        hLineColor: function (i: any, node: any) {
          return (i === 0 || i === node.table.body.length) ? 'black' : 'black'; // header and footer horizontal line color
        },
        vLineColor: function (i: any, node: any) {
          return (i === 0 || i === node.table.widths.length) ? 'black' : 'black'; // header and footer vertical line color
        }
      },
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: [],
        body: [],
        // Define cell styles
        styles: {
          header: {
            bold: true,
            // fillColor: '#CCCCCC'
          }
        }
      }
    };
    var headerRow = [];
    for (let i = 0; i < list.length; i++) {
      headerRow.push({ text: list[i], bold: true });
      tbl.table.widths.push('auto');
    }
    tbl.table.body.push(headerRow);



    dd.content.push(tbl);

    var totalamtpay = 0
    var totalamtded = 0
    var totalamtsum = 0
    var totalamtsum1 = 0
    var mArr = this.sortArr;

    for (let i = 0; i < mArr.length; i++) {

      var arr = []
      //arr.push(i+1);
      if (i <= mArr.length - 1) {
        arr.push(this.monthObj[mArr[i]['month']] + '-' + this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['year'])
      }
      for (let j = 1; j < list.length; j++) {
        console.log(list[j])
        if (list[j] == 'Total\npay') {
          arr.push((this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalpay']).toFixed(2))
          totalamtpay += parseFloat(this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalpay'])
        }
        else if (list[j] == 'Total\nDed') {
          arr.push((this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalded']).toFixed(2))
          totalamtded += parseFloat(this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalded'])
        }
        else if (list[j] == 'Net\nSal') {
          // arr.push((this.temp_obj[mArr[i]['month']+'-'+mArr[i]['year']]['netpay']).toFixed(2))
          totalamtsum = Number(this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalpay'].toFixed(2)) - Number(this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalded'].toFixed(2))
          // totalamtsum[i]=totalamtpay+totalamtded
          totalamtsum1 += totalamtsum
          arr.push(totalamtsum.toFixed(2))
          //arr.push(totalamtsum1.toFixed(2))
          console.log(totalamtsum1)
        }
        else {
          arr.push((this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['total1'][list[j]]).toFixed(2))
        }
      }
      dd.content[dd.content.length - 1].table.body.push(arr);
    }

    var arr = []

    arr.push('Total');
    for (let j = 1; j < list.length; j++) {
      if (list[j] === 'Total\npay') {
        arr.push({ text: totalamtpay.toFixed(2), style: 'tableCellTotal' });
      } else if (list[j] === 'Total\nDed') {
        arr.push({ text: totalamtded.toFixed(2), style: 'tableCellTotal' });
      } else if (list[j] === 'Net\nSal') {
        arr.push({ text: totalamtsum1.toFixed(2), style: 'tableCellTotal' });
      } else {
        arr.push({ text: this.obj2[list[j]].toFixed(2), style: 'tableCell' });
      }
    }
    console.log(arr)
    dd.content[dd.content.length - 1].table.body.push(arr);
    //   dd.content[dd.content.length-1].table.body.push(arr1);
    console.log(dd)
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    // dd.content.push({ text: " " });
    // dd.content.push({ text: " " });

    // dd.content.push({ text: " " });
    // dd.content.push({ text: " " });
    //leave rule
    var header_sd54 = {
      columns: [
        {
          width: '*',
          text: 'Please Check the Leaves of the this Employee',
          bold: true,
          alignment: "center",
          fontSize: 15,
          style: "color: brown; min-height: 20px",
        },

      ],
    }
    dd.content.push(header_sd54);
    dd.content.push({ text: " " });

    var tbl2 = {

      // layout: 'lightHorizontalLines',
      fontSize: 13,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*'],
        heights: [30, 30, 30],



        body: [
          [{ text: 'S.No', bold: true }, { text: 'Leave Type', bold: true }, { text: 'Leaves Remaining', bold: true }]
        ]


      }
    };

    dd.content.push(tbl2);

    dd.content.push({ text: " " });
    //

    this.Allleave = [];
    var x = this.dateToday.split('-');
    for (var i = 0; i < this.allLeaveRule.length; i++) {
      var ob: any = {}
      ob['emp_id'] = this.emp_id;
      ob['leave_code'] = this.allLeaveRule[i].leave_code;
      ob['leave_rate'] = this.allLeaveRule[i].leave_rate;
      ob['is_leave_carry_fwd'] = this.allLeaveRule[i].is_leave_carry_fwd;
      ob['renew_ind_on_year_change'] = this.allLeaveRule[i].renew_ind_on_year_change;
      ob['year'] = parseInt(x[0]);
      ob['month'] = parseInt(x[1]);
      ob['num_of_leaves'] = this.allLeaveRule[i].num_of_leaves;
      ob['leaves_remaining'] = this.allLeaveRule[i].num_of_leaves;
      ob['adjust_remaining_leaves'] = 0;
      ob['carry_forward_leaves'] = 0;
      ob['create_user_id'] = this.erpUser.user_id;
      this.Allleave.push(ob);


    }
    console.log(this.Allleave.length)
    // this.Allleave = [];
    //
    for (let i = 0; i < this.allLeaveRule.length; i++) {


      // arr1.push(i + 1);

      // arr1.push(this.allLeaveRule[i]['leave_code'])
      // arr1.push(this.allLeaveRule[i]['num_of_leaves'])

      // dd.content[dd.content.length - 2].table.body.push(arr1);
      var arr1 = [
        { text: (i + 1).toString(), bold: false },
        { text: this.allLeaveRule[i]['leave_code'], bold: false },
        { text: this.allLeaveRule[i]['num_of_leaves'].toString(), bold: false }
      ];

      tbl2.table.body.push(arr1);
    }
    // dd.content.push(tbl2);
    pdfMake.createPdf(dd).open();
    this.selectObj = {}
  }
  getBase64ImageFromURL(url: any) {
    console.log(url)
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx:   any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }
  next_inc_date: any;
  async print() {
    await this.getLpctransfer()
    let img = {
      image: await this.getBase64ImageFromURL('../../../../assets/img/pcs_logo.jpg'),
      fit: [55, 55],
      alignment: 'center'
    };
    //Next Increment Date Calculation by deepankar
    let td = (this.selectObj['retirement_date']).split("-");
    let cur_date = new Date();
    let cur_year = cur_date.getFullYear();
    if (cur_year == Number(td[0])) {
      if (Number(td[1]) == this.selectedArr['inc_month']) {
        let f = (this.selectedArr['inc_month']);
        this.next_inc_date = this.monthObj[f] + ',' + (Number(cur_year) + 1);
      } else {
        this.next_inc_date = this.monthObj[this.selectedArr['inc_month']] + ',' + cur_year;
      }
    }
    else {
      this.next_inc_date = this.monthObj[this.selectedArr['inc_month']] + ',' + (Number(td[0]) + 1);
    }
    var gross_pay = this.obj3['BASIC'] + this.obj3['DA'] + this.obj3['HRA'] + this.obj3['DEP'] + this.obj3['VA'];
    var ded = this.obj3['PF'] + this.obj3['GIS'] + this.obj3['I_Tax'];
    var net_sal = gross_pay - ded;

    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")";
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt + "  Page No. - " + currentPage, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        return obj;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    }

    var header1 = {
      columns: [
        {
          width: '*',
          text: 'Last Pay Certificate',
          bold: true,
          alignment: "center",
          fontSize: 15
        },

      ],
    }
    dd.content.push(img)
    //dd.content.push({ text: " " });
    dd.content.push(header1);
    //dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1100, y2: 0, lineWidth: 0.05, bold: true }] });
    dd.content.push({ text: " " });
    var headertemp = {
      columns: [
        {
          width: '*',
          text: '1. Last pay certificate of : ',
          bold: true,
          alignment: "left",
        },


        {
          width: '*',
          text: this.selectedArr['emp_name'],
          alignment: "left",
        },
        {
          width: '*',
          text: " EMPLOYEE DESIGNATION :-  ",
          bold: true,
          alignment: "left",
        },
        {
          width: '*',
          text: this.selectedArr['designation_code'],
          alignment: "left",
        },
        // {

        //   width: '*',
        //   text: '',
        //   bold: true
        // },

        // {
        //   width: '*',
        //   text: ''
        // }
      ],
    }
    dd.content.push(headertemp);
    dd.content.push({ text: " " });

    var headersub1temp = {
      columns: [
        {
          width: '*',
          text: '  Proceeding on transfer to : ',
          bold: true,
          alignment: "left",
        },


        {
          width: '*',
          text: this.selectObj['posting_point'],
          // this.selectObj['gov_order'],
          alignment: "left",
        },

        {

          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        },
        // {

        //   width: '*',
        //   text: '',
        //   bold: true
        // },
        // {
        //   width: '*',
        //   text: ''
        // },
      ],
    }
    dd.content.push(headersub1temp);
    dd.content.push({ text: " " });

    var headersub2temp = {
      columns: [
        {
          width: '*',
          text: 'VIDE Govt No : ',
          bold: true,
          alignment: "left",
        },
        // {
        //   width: '*',
        //   text:  this.selectObj['gov_order'],
        //   alignment:"left",
        //   // this.selectObj['posting_point'],

        // },
        // {
        //   width: '*',
        //   text: 'DATE : ',
        //   bold: true,
        //   // alignment:"center",
        // },
        // {
        //   width: '*',
        //   text: this.selectObj['transfer_date'],


        // this.selectObj['gov_order'],
        //   alignment:"center",
        // },


        // }

        {

          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: ''
        }
      ],
    }

    dd.content.push(headersub2temp);
    dd.content.push({ text: " " });
    // var headersub3temp = {
    //   columns: [
    //     {
    //       width: '*',
    //       text: 'DATE : ',
    //       bold: true,
    //        alignment:"center",
    //     },
    //     {
    //       width: '*',
    //       text:  this.selectObj['transfer_date'],
    //       // this.selectObj['posting_point'],

    //     },
    //     // {
    //     //   width: '*',
    //     //   text: 'DATE : ',
    //     //   bold: true,
    //     //   // alignment:"center",
    //     // },
    //     // {
    //     //   width: '*',
    //     //   text: this.selectObj['transfer_date'],

    //     // }
    //     {

    //       width: '*',
    //       text: '',
    //       bold: true
    //     },
    //     {
    //       width: '*',
    //       text: ''
    //     }
    //   ],
    // }

    // dd.content.push(headersub3temp);
    // dd.content.push({ text: " " });

    var header1temp = {
      columns: [
        {
          width: '*',
          text: '2.  He/She has been paid upto : ',
          bold: true,
          //alignment:"center",
        },


        {
          width: '*',
          text: this.selectObj['retirement_date'],

        },
        {
          width: '*',
          text: 'at the following reference',

        },
        {

          width: '*',
          text: '',
          bold: true
        },
        // {
        //   width: '*',
        //   text: ''
        // },
      ],
    }


    dd.content.push(header1temp);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    var header2temp = {
      columns: [
        {
          width: '*',
          text: '3. Particulars',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(header2temp);
    //
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 90, y2: 0, lineWidth: 0.09, bold: true }] });
    dd.content.push({ text: " " });
    var subheader2temp = {
      columns: [
        {
          width: '*',
          text: 'Pay',
          bold: true,
          // alignment:"center",
        },
        {
          width: '*',
          text: this.selectedArr['pay_commission_code'] + "th",

          // alignment:"center",
        },
        {

          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        },
      ],
    }
    dd.content.push(subheader2temp)
    dd.content.push({ text: " " });
    var sub1header2temp = {
      columns: [
        {
          width: '*',
          text: 'Pay Scale/Grade Pay:',
          bold: true,
        },
        {
          width: '*',
          text: this.selectedArr['pay_scale_code'] + "/" + this.selectedArr['grade_pay_code'],

        },
        {

          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        },
      ],
    }
    dd.content.push(sub1header2temp)
    dd.content.push({ text: " " });
    //  var sub2header2temp={
    //   columns:[
    //     {
    //       width: '*',
    //       text: 'PF/PRAN:',
    //       bold: true,
    //    },
    //    {
    //     width: '*',
    //     text: this.personalInfoObj['pf_acct_no']+"/"+this.personalInfoObj['nps_no'],

    //  },
    //  {

    //   width: '*',
    //   text: '',
    //   bold: true
    // },

    // {
    //   width: '*',
    //   text: ''
    // },
    //   ],
    //  }
    //  dd.content.push(sub2header2temp)
    //  dd.content.push({ text: " " });

    var sub1header2atemp = {
      columns: [
        {
          width: '*',
          text: 'EMP ID:',
          bold: true,
        },
        {
          width: '*',
          text: this.getNumberFormat(this.selectedArr['emp_id']),

        },
        {

          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        },
      ],
    }
    dd.content.push(sub1header2atemp)
    dd.content.push({ text: " " });


    //    var sub1header2btemp={
    //     columns:[
    //       {
    //         width: '*',
    //         text: 'PRAN/NPS NO:',
    //         bold: true,
    //      },
    //      {
    //       width: '*',
    //       text: this.personalInfoObj['nps_no'],

    //    },
    //    {

    //     width: '*',
    //     text: '',
    //     bold: true
    //   },

    //   {
    //     width: '*',
    //     text: ''
    //   },
    //     ],
    //  }
    //  dd.content.push(sub1header2btemp)
    //  dd.content.push({ text: " " });




    var header3temp = {
      columns: [
        {
          width: '*',
          text: 'a. Allowance',
          bold: true,
        },
        {
          width: '*',
          text: ''
        },

        {
          width: '*',
          text: 'b. Deductions',
          bold: true,
        },
      ]
    }
    dd.content.push(header3temp)
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 90, y2: 0, lineWidth: 0.09, bold: true }] });
    dd.content.push({ text: " " });

    //  var tbl = {

    //   layout: 'lightHorizontalLines',
    //   fontSize: 10,
    //   table: {

    //     headerRows: 1,
    //     widths: ['auto', 'auto'],
    //     body: [
    //       ['a. Allowance',  'b. Deductions']
    //     ],
    //     bold:true
    //   }
    // };
    // dd.content.push(tbl);

    //  var arr=[]

    var header4temp = {
      columns: [
        {
          width: '*',
          text: 'BASIC : ' + '  ' + this.obj3['BASIC'],
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: 'PF : ' + '  ' + this.obj3['PF'],
        }

      ]
    }
    dd.content.push(header4temp)
    dd.content.push({ text: " " });
    var header5temp = {
      columns: [
        {
          width: '*',
          text: 'DA : ' + '  ' + this.obj3['DA'],
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: ''
          //  'NPS : ' +'  '+ this.obj3['NPS'],
        }

      ]
    }
    dd.content.push(header5temp)
    dd.content.push({ text: " " });

    var header5atemp = {
      columns: [
        {
          width: '*',
          text: 'HRA : ' + '  ' + this.obj3['HRA'],
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: ''
          // 'EWF : ' +'  '+ this.obj3['EWF'],
        }

      ]
    }
    dd.content.push(header5atemp)
    dd.content.push({ text: " " });

    var header6temp = {
      columns: [
        {
          width: '*',
          text: 'DEP : ' + '  ' + this.obj3['DEP'],
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: 'GIS : ' + '  ' + this.obj3['GIS'],
        }

      ]
    }
    dd.content.push(header6temp)
    dd.content.push({ text: " " });


    var header8temp = {
      columns: [
        {
          width: '*',
          text: 'VA : ' + '  ' + this.obj3['VA'],
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: 'IT : ' + '  ' + this.obj3['I_Tax'],
        }

      ]
    }
    dd.content.push(header8temp)
    dd.content.push({ text: " " });

    var t9 = {
      columns: [
        {
          width: '*',
          text: '',
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: ''
          //  'VD : ' +'  '+ this.obj3['VD'],
        }

      ]
    }
    dd.content.push(t9)
    dd.content.push({ text: " " });








    var t10 = {

      columns: [
        {
          width: '*',
          text: '',
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: ''
          // 'HRR : ' +'  '+ this.obj3['HRR'],
        }

      ]
    }

    var t101 = {

      columns: [
        {
          width: '*',
          text: 'Total - Gross Pay : ' + ' ' + gross_pay,
          bold: true,
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: 'Total Deduction : ' + ' ' + ded,
          bold: true,
          alignment: 'right'
        },
        {
          width: '*',
          text: '',
        }

      ]
    }

    var t102 = {

      columns: [
        {
          width: '*',
          text: '',
          bold: true,
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: 'Net Salary : ' + ' ' + net_sal,
          bold: true,
          alignment: 'right'
        },
        {
          width: '*',
          text: '',
        }

      ]
    }
    dd.content.push(t10)
    dd.content.push({ text: " " });

    dd.content.push(t101)
    dd.content.push({ text: " " });
    dd.content.push(t102)
    dd.content.push({ text: " " });
    var header9temp = {
      columns: [
        {
          width: '*',
          text: '4. He/She has handed over charge of office of " ' + this.selectedArr['designation_code'] + ' " Varanasi Development Authority(VDA)',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(header9temp);
    dd.content.push({ text: " " });



    var headersub10temp = {
      columns: [
        {
          width: '*',
          text: '5. Next Increment Date ' + this.next_inc_date,
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headersub10temp);
    dd.content.push({ text: " " });
    var headersub11temp = {
      columns: [
        {
          width: '*',
          text: '6. Recoveries are to be made from the pay of the Govt. service as detailed above. ',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headersub11temp);
    dd.content.push({ text: " " });
    var headersub11temp = {
      columns: [
        {
          width: '*',
          text: '7. He/She is also entitled to join time as admissible under the rule.',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headersub11temp);
    dd.content.push({ text: " " });
    var headersub12temp = {
      columns: [
        {
          width: '*',
          text: '8. The details of the Income Tax Recovered from him upto the date from begining of the current year are noted on the reverse ',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headersub12temp);
    dd.content.push({ text: " " });

    var header10temp = {
      columns: [
        {
          width: '*',
          text: '9. He/She is entitled to draw the following :-',
          bold: true,
          // alignment:"center",
        },

      ],
    }

    dd.content.push(header10temp);
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 300, y2: 0, lineWidth: 0.09, bold: true }] });
    dd.content.push({ text: " " });
    var headersub9temp = {
      columns: [
        {
          width: '*',
          text: 'a) E.L As per Service books _______days  Due on___________',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headersub9temp);
    dd.content.push({ text: " " });

    var headertemp1 = {
      columns: [
        {
          width: '*',
          text: '(b) C.L _______days  Due on___________',
          bold: true,
          // alignment:"center",
        },

      ],
    }
    dd.content.push(headertemp1);
    dd.content.push({ text: " " });

    var headersub13temp = {
      columns: [
        {
          width: '*',
          text: '10. Letter ',
          bold: true,
          // alignment:"center",
        },
        {
          width: "*",
          text: ''
        },
        {
          width: "*",
          text: ''
        },
        {
          width: '*',
          text: 'Date ...................',

        },
      ],
    }

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(headersub13temp);
    dd.content.push({ text: " " });

    var headersub15temp = {
      columns: [
        {
          width: '*',
          text: ' To',
          bold: true,
          // alignment:"center",
        },
      ],
    }
    dd.content.push(headersub15temp);
    dd.content.push({ text: " " });
    var headersub16temp = {
      columns: [
        {
          width: '*',
          text: ' _______________________________________________',
          bold: true,
          // alignment:"center",
        },
      ],
    }
    dd.content.push(headersub16temp);
    dd.content.push({ text: " " });
    var headersub17temp = {
      columns: [
        {
          width: '*',
          text: ' _____________________________________',
          bold: true,
          // alignment:"center",
        },
      ],
    }
    dd.content.push(headersub17temp);
    dd.content.push({ text: " " });
    var headersub18temp = {
      columns: [
        {
          width: '*',
          text: 'Competent Officer /J.S/Incharge(Estt.) ',
          bold: true,
          // alignment:"center",
        },
        {
          width: "*",
          text: ''
        },
        {
          width: "*",
          text: 'Finance Controller',
          bold: true,
        },
      ],
    }
    dd.content.push(headersub18temp);
    dd.content.push({ text: " " });
    var headersub19temp = {
      columns: [
        {
          width: '*',
          text: this.selectObj['posting_point'],
          bold: true,
          // alignment:"center",
        },
        {
          width: "*",
          text: ''
        },
        {
          width: "*",
          text: 'Varanasi Development Authority(VDA)',
          bold: true,
        },
      ],
    }
    dd.content.push(headersub19temp);
    // dd.content.push({ text: " " });
    // dd.content.push({ text: " " });
    // dd.content.push({ text: " " });


    var header_sd10 = {
      columns: [
        {
          width: '*',
          text: 'Details of Pay and Recoveries',
          bold: true,
          alignment: "center",
          fontSize: 15
        },

      ],
    }





    //let list=['SI.NO']
    let list = ['Month']
    //  let list= ['Month-Year','Basic\nPay','DA','DEP\n Allow','Total Payable','HRR','VD','PF/NPS','GIS','IT','Total/nDed','Total/nSum']

    let widths = []
    let data_keys = []
    // list.push('Month')
    //shweta  widths.push('auto')
    var d2 = this.selectObj['retirement_date'];
    const d = new Date(d2)
    d.setDate(1);
    let month = (d.getMonth() + 1);
    d.setMonth(month);
    //  console.log(d);
    const date = new Date(d);
    let year = date.getFullYear();
    let d1 = (date.getFullYear() + '-' + (date.getMonth()) + '-' + date.getDate());

    console.log("this is the starting transfer date", d1, "this is the end transfer date", d2)
    var d3 = this.selectObj['retirement_date'];
    console.log("this is the transfer date", d3)
    //shweta   for (let i = 0; i < this.all_component.length; i++) {
    //   if (this.all_component[i]['pay_code'] == 'PAY') {
    //     list.push(this.all_component[i]['pay_component_code'])
    //     console.log(list)
    //     data_keys.push(this.all_component[i]['pay_component_code'])
    //     widths.push('auto')
    //   }
    // }
    list.push('BASIC')
    widths.push('*')
    list.push('DA')
    widths.push('*')
    list.push('HRA')
    widths.push('*')
    list.push('DEP')
    widths.push('*')
    list.push('VA')
    widths.push('*')
    list.push('Total\npay')
    widths.push('*')

    // shweta for (let i = 0; i < this.all_component.length; i++) {
    //   if (this.all_component[i]['pay_code'] == 'DED') {
    //     list.push(this.all_component[i]['pay_component_code']);
    //     data_keys.push(this.all_component[i]['pay_component_code'])

    //     widths.push('auto')
    //   }
    // }

    //  // dd.content.push({ text: " " })
    list.push('IT')
    widths.push('*')
    list.push('VD')
    widths.push('*')
    list.push('HRR')
    widths.push('*')
    list.push('NPS')
    widths.push('*')
    list.push('PF')
    widths.push('*')
    list.push('EWF')
    widths.push('*')
    list.push('GIS')
    widths.push('*')
    list.push('Total\nDed');
    widths.push('*')

    list.push('Net\nSal.')
    widths.push('*')
    widths.push('*')
    var tbl:any  = {
      pageSize: 'A4',
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,

        //  widths:['*','*','*','*','*','*','*','*','*','*','*','*'],
        widths: [],
        body: [
          //  [ 'Month', 'Basic\nPay','HRA' ,'CCA','DEP\n Allow','MED','Per\npay','CONV','WA','HVDVT\nAllow','Comp\nAlw','Res\nAlw','DA','Total','LIC','PF','NPS','GIS','CPF\nGPF\nDed','Veh\nLoan','HB\nAdv','Fest\nAdv','I.\nTax','Other\nDed','HDFC','H\nRent','Misc\nDed','Cons.\nLoan','Other\nAdv','Total\nDed','Net.\nSal.' ]

          []

        ],
        // body: [
        //   ['Month-Year','Basic\nPay','DA','DEP\n Allow','Total Payable','HRR','VD','PF/NPS','GIS','IT','Total/nDed','Total/nSum']



        // ]
      }
    };




    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(header_sd10);
    dd.content.push({ text: " " });

    for (let i = 0; i < list.length; i++) {
      tbl.table.body[0].push(list[i]);
      console.log(list[i])
      tbl.table.widths.push('auto')
    }
    dd.content.push(tbl);

    var totalamtpay = 0
    var totalamtded = 0
    var totalamtsum = 0
    var totalamtsum1 = 0
    var mArr = this.sortArr;
    for (let i = 0; i < mArr.length; i++) {

      var arr = []
      //arr.push(i+1);
      if (i < mArr.length - 1) {
        arr.push(this.monthObj[mArr[i]['month']] + '-' + this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['year'])
      }
      else {
        arr.push(this.monthObj[mArr[i]['month']] + '-' + this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['year'] + "    ( From Date   " + d1 + "   to date " + d2 + ")")
      }
      for (let j = 1; j < list.length; j++) {
        console.log(list[j])
        if (list[j] == 'Total\npay') {
          arr.push((this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalpay']).toFixed(2))
          totalamtpay += this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalpay']
        }
        else if (list[j] == 'Total\nDed') {
          arr.push((this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalded']).toFixed(2))
          totalamtded += this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalded']
        }
        else if (list[j] == 'Net\nSal.') {
          // arr.push((this.temp_obj[mArr[i]['month']+'-'+mArr[i]['year']]['netpay']).toFixed(2))
          totalamtsum = Number(this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalpay'].toFixed(2)) - Number(this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['totalded'].toFixed(2))
          totalamtsum1 += totalamtsum

          arr.push(totalamtsum.toFixed(2))
        }
        else {


          arr.push((this.temp_obj[mArr[i]['month'] + '-' + mArr[i]['year']]['total1'][list[j]]).toFixed(2))
        }
      }
      dd.content[dd.content.length - 1].table.body.push(arr);
    }


    var arr = []

    arr.push('Total')
    for (let j = 1; j < list.length; j++) {
      widths.push('auto')
      if (list[j] == 'Total\npay') {
        arr.push(totalamtpay.toFixed(2))
      }
      else if (list[j] == 'Total\nDed') {
        arr.push(totalamtded.toFixed(2))
      }
      else if (list[j] == 'Net\nSal.') {
        arr.push(totalamtsum1.toFixed(2))
      }

      else {
        // sarr.push(this.totalobj[list[j]])
        arr.push(this.obj2[list[j]].toFixed(2))
      }

    }
    console.log(arr)
    dd.content[dd.content.length - 1].table.body.push(arr);
    console.log(dd)





    pdfMake.createPdf(dd).open();
  }
  changeTransfer() {
    if (this.selectObj['emp_id']) {
      for (let i = 0; i < this.allEmplyees_new.length; i++) {
        if (this.selectObj['emp_id'] == this.allEmplyees_new[i]['emp_id']) {

          var date = new Date(this.allEmplyees_new[i]['effective_timestamp'])
          this.allEmplyees_new[i]['effective_timestamp'] = date.toISOString().split('T')[0]
          // this.selectObj['retirement_date'] = this.allEmplyees_new[i]['effective_timestamp']
          this.selectObj['retirement_date'] = this.allEmplyees_new[i]['transfer_date']
          this.selectObj['reason_of_retirement'] = this.allEmplyees_new[i]['order_id']
          this.selectObj['arr_id'] = this.allEmplyees_new[i]['arr_id']
        }
      }
    } else {
      // this.selectObj['transfer_date'] = ''
      this.selectObj = {}
    }
    this.salarySlipArr = []

    this.selectObj['posting_point'] = ''
  }

}
