import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import {MainService} from './main.service';

@Injectable({
  providedIn: 'root'
})
export class AuctionAllotmentService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl =  this.main.httpUrl+ "/property/auction_allotment";
  }


  async getAuctions(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/getauctions' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getAllPropType(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/getproptype' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async FetchDetailAuction(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/fetchauctiondetails' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
}
