import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import Swal from 'sweetalert2';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as  pdfMake from "pdfmake/build/pdfmake";

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/icms";
  }

  async getComplainData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/complain/getComplaintData' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async allAssignComplain(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/report/allAssignComplain' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  async getUploaddocumentrData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/complain/getUpoadDocument' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  async getUploaddocumentrimg(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.post(this.httpUrl + '/upload/getComplainPhoto', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async insertassigncomplain(obj:any) {
    // console.log(obj, "from service")
    const resp = await this.http.post(this.httpUrl + '/complain/assignComplaindata', obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  async getinspectionData(obj:any) {
    // console.log(obj, "from service")
    const resp = await this.http.get(this.httpUrl + '/inspection/getInspectionData' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getuserinfo(obj:any) {
    // console.log(obj, "from service")
    const resp = await this.http.get(this.httpUrl + '/complain/userLogin' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getinspectionUploaddocumentrData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/inspection/getinspectionDocument' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  //
  async changeComplainStatus(obj:any) {
    const resp = await this.http.put(this.httpUrl + '/report/changeComplainStatus', obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  async changeOrderStatus(obj:any) {
    const resp = await this.http.put(this.main.httpUrl +'/icms/court-order/changeOrderStatus', obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }



  async printInspectionRep(element:any) {
    let obj:any = {}
     console.log("Report service inspection Avinash element send by litigation   ",element);
    obj['b_acct_id'] = element.b_acct_id
    // obj['complain_id'] = element.complain_id;
    obj['id'] = element.id;
    obj['doc_local_no'] = element.doc_local_no;
    obj['user_id'] = element.user_id;
    console.log("shweta",obj)
    let resp = await this.downloadReport(JSON.stringify(obj));
    if (resp['error'] == false) {
      pdfMake.createPdf(resp.dd).download('Report.pdf');
    } else {
      Swal.fire("ERROR", resp.dd, "error");
    }

  }



  async downloadReport(obj:any) {
    console.log("thgis is the download Report obj ", obj);
        const resp = await this.http.get<any>(this.httpUrl + '/report/downloadReport' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


}

