import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmdLayoutComponent } from './emd-layout.component';
import { EmdHeaderComponent } from './emd-header/emd-header.component';
import { EmdSidebarComponent } from './emd-sidebar/emd-sidebar.component';
import { EmdFooterComponent } from './emd-footer/emd-footer.component';
import { EmdHelpComponent } from '../emd-help/emd-help.component';
import { EmdAdministrationComponent } from '../emd-administration/emd-administration.component';
import { DataAssignmentComponent } from '../emd-administration/data-assignment/data-assignment.component';
import { EmdRoleComponent } from '../emd-administration/emd-role/emd-role.component';
import { EmdUserComponent } from '../emd-administration/emd-user/emd-user.component';
import { EmdSettingComponent } from '../emd-setting/emd-setting.component';
import { FieldComponent } from '../emd-setting/field/field.component';
import { CodeValueComponent } from '../emd-setting/code-value/code-value.component';
import { HierarchyComponent } from '../emd-setting/hierarchy/hierarchy.component';
import { WorkflowComponent } from '../emd-setting/workflow/workflow.component';
import { SharedModule } from '../../shared/shared.module';
// import { AgmCoreModule } from '@agm/core';
// import { ChartsModule } from 'ng2-charts';
import { NgChartsModule } from 'ng2-charts';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { EmdDashboardComponent } from '../emd-dashboard/emd-dashboard.component';
import { EMDLayoutRoutingModule } from './emd-layout-routing.module';
import { TenderComponent } from '../tender/tender.component';
import { ReactiveFormsModule } from '@angular/forms';
import { VendorComponent } from '../vendor/vendor.component';
import { PaymentComponent } from '../payment/payment.component';
import { AssignTenderComponent } from '../assign-tender/assign-tender.component';
import { EmdRefundComponent } from '../emd-refund/emd-refund.component';

@NgModule({
  declarations: [
    EmdLayoutComponent,
    EmdHeaderComponent,
    EmdSidebarComponent,
    EmdFooterComponent,
    EmdHelpComponent,
    EmdAdministrationComponent,
    DataAssignmentComponent,
    EmdRoleComponent,
    EmdUserComponent,
    EmdSettingComponent,
    FieldComponent,
    CodeValueComponent,
    HierarchyComponent,
    WorkflowComponent,
    EmdDashboardComponent,
    TenderComponent,
    VendorComponent,
    PaymentComponent,
    AssignTenderComponent,
    EmdRefundComponent,


  ],

  exports: [
    EmdLayoutComponent,
    EmdHeaderComponent,
    EmdSidebarComponent,
    EmdFooterComponent,

  ],
  imports: [
    SharedModule,
    CommonModule,
    EMDLayoutRoutingModule,
    NgSelectModule,
    NgChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    MatSlideToggleModule,
    FormsModule,
    // NgtreegridModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDialogModule,
    ReactiveFormsModule,
    // MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    // MatNativeDateModule,
    MatPaginatorModule,
    // MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    // NgApexchartsModule,
    // ReactiveFormsModule,
    MatTabsModule,
    // NgxEchartsModule,
    // NgxQRCodeModule

    // AngularEditorModule,
    // RichTextEditorModule,

    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    //   apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    // AgmCoreModule
  ]
})
export class EmdLayoutModule { }
