import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { stringify } from 'querystring';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  httpUrl;


  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl+"/property/dashboard";
  }

  async  getDashBoardCount(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/getAllCount' +JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  getSubSchemeInYears(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/getSubSchemeInYears' +JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  getAllotmentInYears(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/getAllotmentInYears' +JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  getAllProperty(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/getAllPropertyInOffer' +JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  getAllApplicantData(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/getApprovedForm' +JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async  getAllRemProperty(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/getAllRemProperty' +JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getApplicationInfo(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + '/interface/property/application/applicationInfo' + obj).toPromise().then(res => {
      //console.log(res)
      return res
    });
    return resp
  }

  async getAllPartyUploadedDocuments(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getPartyUplodedData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAllAllotmentLetterData(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + '/property/allotmentletter/getAllAllotmentLetter' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }


  async getUploadedFileofparty(obj:any){

    const resp = await this.http.post(this.main.httpUrl + '/property/allotmentletter/getImage', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }else{
      return
    }
  }

  async  allDashboardCount(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/countPropertyData' +JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  allVacantANDResevedPro(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/allVacantANDResevedPro' +JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  allSoldProp(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl+'/totalSoldPro/' +b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  propertyCostDetails(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/propertyCostDetails' +JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async partyImage(obj:any){
    // console.log(this.httpUrl+'/getUploadedFileofparty')
    const resp = await this.http.post(this.main.httpUrl  + '/property/application/getUploadedFileofparty', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }else{
      return
    }
  }


  async changeApplicationStatus(obj:any){
    const resp = await this.http.put<any>(this.httpUrl+'/changeApplicationStatus', obj).toPromise().then(res => {

      return res;
    });
    return resp;
  }


  async  totalBissinessDetails(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/totalBissinessDetails' +JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getChartProRegData(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/getChartProRegData' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async downloadStatusChange(obj:any){
    const resp = await this.http.put<any>(this.httpUrl+'/downloadStatusChange', obj).toPromise().then(res => {

      return res;
    });
    return resp;
  }
}
