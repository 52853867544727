import { PropertyLayoutsComponent } from './property/property-layouts/property-layouts.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortalLayoutsComponent } from './portal/portal-layouts/portal-layouts.component'
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { EmbLayoutsComponent } from './emb/emb-layouts/emb-layouts.component';
import { HrmsLayoutsComponent } from './hrms/hrms-layouts/hrms-layouts.component';
import { AdminLayoutsComponent } from './admin/admin-layouts/admin-layouts.component';
import { MdLayoutsComponent } from './md/md-layouts/md-layouts.component';
import { AccountsLayoutsComponent } from './accounts/accounts-layouts/accounts-layouts.component';
import { IcmsLayoutComponent } from './icms/icms-layout/icms-layout.component';
import { TrackingLayoutComponent } from './tracking/tracking-layout/tracking-layout.component'
import { VmsLayoutComponent } from './vms/vms-layout/vms-layout.component';
import { EmdLayoutComponent } from './emd/emd-layout/emd-layout.component';

const routes: Routes = [
  // ************************************SIMRAN CODE START*********************************
  { path : "" , component: PortalLayoutsComponent , loadChildren:() => import('./portal/portal-layouts/portal-layouts.module').then( m => m.PortalLayoutsModule)},
  { path : "solution-admin" , component: AdminLayoutsComponent , loadChildren:() => import('./admin/admin-layouts/admin-layouts.module').then( m => m.AdminLayoutsModule)},
  // // { path : "eng" , component: EngLayoutsComponent , loadChildren:() => import('./eng/eng-layouts/eng-layouts.module').then( m => m.EngLayoutsModule)},
  { path : "md" , component: MdLayoutsComponent , loadChildren:() => import('./md/md-layouts/md-layouts.module').then( m => m.MdLayoutsModule)},
  { path : "hrms" , component: HrmsLayoutsComponent , loadChildren:() => import('./hrms/hrms-layouts/hrms-layouts.module').then( m => m.HrmsLayoutsModule)},
  { path : "emb" , component: EmbLayoutsComponent , loadChildren:() => import('./emb/emb-layouts/emb-layouts.module').then( m => m.EmbLayoutsModule)},
  { path : "property" , component: PropertyLayoutsComponent , loadChildren:() => import('./property/property-layouts/property-layouts.module').then( m => m.PropertyLayoutsModule)},
  { path : "accounts" , component: AccountsLayoutsComponent , loadChildren:() => import('./accounts/accounts-layouts/accounts-layouts.module').then( m => m.AccountsLayoutsModule)},
  { path : "icms" , component: IcmsLayoutComponent , loadChildren:() => import('./icms/icms-layout/icms-layout.module').then( m => m.IcmsLayoutModule)},
  { path : "tracking" , component: TrackingLayoutComponent , loadChildren:() => import('./tracking/tracking-layout/tracking-layout.module').then( m => m.TrackingLayoutModule)},
  { path : "emd" , component: EmdLayoutComponent , loadChildren:() => import('./emd/emd-layout/emd-layout.module').then( m => m.EmdLayoutModule)},
  { path : "vms" , component: VmsLayoutComponent , loadChildren:() => import('./vms/vms-layout/vms-layout.module').then( m => m.VmsLayoutModule)},
  // { path : "cc" , component: CCenterLayoutComponent , loadChildren:() => import('./c-center/c-center-layout/c-center-layout.module').then( m => m.CCenterLayoutModule)},
  // { path : "cms" , component: CmsLayoutComponent , loadChildren:() => import('./cms/cms-layout/cms-layout.module').then( m => m.CmsLayoutModule)},

  // **************************************SIMRAN CODE END*************************************
      {
          "path": "login",
          "component": SigninComponent
      },
      {
          "path": "register",
          "component": SignupComponent
      },
      {
          "path": "**",
          "redirectTo": "error_404",
          "pathMatch": "full"
      },
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
