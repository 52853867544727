import { MatSort } from '@angular/material/sort';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator  } from '@angular/material/paginator';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../authentication.service';
import { ApprovalUserService } from '../../../portal/service/approval-user.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { LandInputService } from '../../service/land-input.service';
import { MainService } from '../../service/main.service';
import { MainService as mainserviceEmb } from '../../../emb/service/main.service';
import { MainService as Portalmain } from '../../../portal/service/main.service';
import { PropCommonSService } from '../../service/prop-common-s.service';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import { FileUploader } from 'ng2-file-upload';
import { SettingService } from '../../service/setting.service';
import { LocationService } from '../../service/location.service';
import { ExcelService } from '../../service/file-export.service';
import { DocumentService } from '../../service/document.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LeaseService } from '../../service/lease.service';
import { Inject } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-lease',
  templateUrl: './lease.component.html',
  styleUrls: ['./lease.component.css']
})
export class LeaseComponent implements OnInit {
  datasource:any;
  isLinear:any = false;
  first:any = true;
  selectedFile: any = null;
  second:any = true;
  radio:any = true;
  displayedColumns = ['group_id', 'hier_ident_code', 'project', 'target_name', 'count', 'total_area', 'unit', 'life_cycle_status', 'action'];
  GISArr:any = []
  third:any = true;
  fourth:any = true;
  work_id:any;
  BoqTable:any = [];
  total_sum:any = 0;
  imgURL:any;
  Obj:any = {}
  docArr:any = [{}]
  enableStep3: any;
  b_acct_id:any;
  ebillUser:any;
  planing_arr:any = [{}]
  acp_arr:any = [{}]
  public imagePath:any;
  httpUrl:any;
  uploader:any;
  uploader2:any;
  property_type :any= {}
  choosen_file:any = false
  choosen_file_uploaded:any = false
  outer_acq_row:any = [{
    dropDown: [{
      existing_heirarchy_nodes: []
    }], data: [{}]
  }]
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;
  location_idet_stdr:any = {}
  location_type_obj:any = {}
  objToSubmit:any = {}
  constructor(private spinner: NgxSpinnerService,
     private portalmain: Portalmain,
     public ApprovalUserService: ApprovalUserService,
     public mainserviceEmb: mainserviceEmb,
     private settingService: SettingService,
     private propHierS: PropHierarchyService,
     private commonS: PropCommonSService,
     private locationS: LocationService,
     private excel: ExcelService,
     public auth: AuthenticationService,
     private docService: DocumentService,
     public mainService: MainService,
     private landInput_S: LandInputService,
     private hierarchyS: PropHierarchyService,
     public toastr: ToastrService,
     @Inject(DomSanitizer)  private sanitizer: DomSanitizer ,
     private leaseservice : LeaseService
     ) { }
  allProjects = []
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getLocationList()
    await this.getpropertygroup()
    await this.getAllHier()
    await this.getAllLevls2()
    await this.getproject()
    this.Obj['total_area'] = 0
    this.httpUrl = this.mainService.httpUrl
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file:any) => { file.withCredentials = false; };
    this.uploader2 = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader2.onAfterAddingFile = (file:any) => { file.withCredentials = false; };
    for (let i = 0; i < this.mainService.codeValueTechObj['PROP0015'].length; i++) {
      this.property_type[this.mainService.codeValueTechObj['PROP0015'][i]['code']] = this.mainService.codeValueTechObj['PROP0015'][i]['value']
    }
    for (let i = 0; i < this.mainService.codeValueTechObj['PROPFLD6'].length; i++) {
      this.location_idet_stdr[this.mainService.codeValueTechObj['PROPFLD6'][i]['code']] = this.mainService.codeValueTechObj['PROPFLD6'][i]['value']
    }
    for (let i = 0; i < this.mainService.codeValueTechObj['PROPFLD5'].length; i++) {
      this.location_type_obj[this.mainService.codeValueTechObj['PROPFLD5'][i]['code']] = this.mainService.codeValueTechObj['PROPFLD5'][i]['value']
    }
    this.Obj = {}
    await this.changeList();
    await this.getAllLevls();

    //get work flow plamlayout....
    await this.getWorkflowlog();

  }
  ownership_list:any = [{type: 'Improvement Trust'},{type : 'Nazool'}]
  projecthier:any = []
  projhierobj:any = {}
  projhierobjtype:any = {}
  projhierobjtypecd:any = {}
  project_hier:any = []
  projectKeyValue:any = {}
  leaf_user_cd:any = []
  async getproject() {
    let obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'PROPERTY'
    let resp = await this.hierarchyS.getprojectHierarchy(obj)
    console.log(resp)
    for (let i = 0; i < resp.data.length; i++) {
      this.projectKeyValue[resp['data'][i]['leaf_cd']] = resp['data'][i]
      if (resp.data[i]['lvl1_value'] == "All Projects") {
        resp.data[i]['lvl1_value'] = 'PROPERTY-root'

      }
    }
    this.project_hier = resp['data']
    let arr:any = []
    let duumy:any = []
    this.leaf_user_cd = []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!arr.includes(this.project_hier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.project_hier[i]['lvl1_cd'], lvl1_value: this.project_hier[i]['lvl1_value'], lvl1_node_type: this.project_hier[i]['lvl1_node_type'] })
        arr.push(this.project_hier[i]['lvl1_cd'])
      }
      if (!this.leaf_user_cd.includes(this.project_hier[i]['leaf_user_cd'])) {
        this.leaf_user_cd.push(this.project_hier[i]['leaf_user_cd'])
      }
    }
    this.level1 = duumy
    console.log(this.project_hier, this.level1, 'klklklklkllllllllllllllllklklklk')
    console.log("Leaf User Code : ", this.leaf_user_cd);

  }
  codeValueObj:any = {}
  codeValueShowObj :any= {}
  async getCodeValue_obj() {
    this.spinner.show()
    var resp :any= await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj:any = {}
    var codeValueShowTempObj:any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }
  }
  setLeafNode() {
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        // this.objToSubmit['project_cd']=this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }
  setPath_desc() {
    let n = 0
    for (let j = 1; j <= 8; j++) {
      if (this.Obj['lvl' + j + '_cd']) {
        n = j
      }
    }
    let hier_id = 0
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl' + n + '_cd'] == this.Obj['lvl' + n + '_cd'] && this.Obj['lvl' + n + '_cd'] == this.allHier[i]['leaf_cd']) {
        {
          console.log(this.allHier[i])
          hier_id = this.allHier[i]['prop_hier_id']
        }
      }
    }
    let ob:any = this.hier_node_obj[hier_id]
    console.log(this.allPlan_layout, this.hier_node_obj, this.allHier, ob)
    let dummy2 = []
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        dummy2.push(ob['lvl' + j + '_value'])
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }
  project_arr:any = [{}]
  async add_dropdown(data:any, index:any) {
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.data.push({})
    }
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.allHier, this.Obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.allHier, this.Obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.allHier, this.Obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.allHier, this.Obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.allHier, this.Obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.allHier, this.Obj)
    }
  }
  async add_dropdown2(data:any, index:any) {
    if (index > 2) {
      Swal.fire('Information...', 'You Can Select Till Level 4 In Project Hierarchy', 'info')
      return
    }
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.project_arr.push({})
    }
  }
  submit_prj_hier() {
    // let dummy = []
    // for (let i = 1; i <= 7; i++) {
    //   if (this.Obj['lvl' + i + '_cd']) {
    //     this.Obj['node_cd'] = this.Obj['lvl' + i + '_cd']
    //     this.objToSubmit['project_cd'] = this.Obj['lvl' + i + '_cd']
    //     dummy.push(this.Obj['lvl' + i + '_value'])
    //   }
    // }
    $('#ACTHIER').modal('hide');
    // this.project_obj['project_path_desc'] = dummy.join('-->')
    this.project_arr = []
    console.log(this.project_obj)
  }
  async set_Path_desc(index:any) {
    console.log(this.project_hier, index, this.project_obj)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.project_hier, this.project_obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.project_hier, this.project_obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.project_hier, this.project_obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.project_hier, this.project_obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.project_hier, this.project_obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.project_hier, this.project_obj)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.project_obj['lvl' + i + '_cd']) {
        this.project_obj['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.project_obj['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        this.Obj['project_node_cd'] = this.project_obj['lvl' + i + '_cd']
        this.project_obj['node_cd'] = this.project_obj['lvl' + i + '_cd']
        this.project_obj['lvl' + i + '_user_cd'] = this.project_obj['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.project_obj['lvl' + i + '_cd']
        dummy.push(this.project_obj['lvl' + i + '_value'])
      }
    }
    this.project_obj['project_path_desc'] = dummy.join('-->')
    console.log(this.project_obj, this.level11, this.level2)
  }
  leaf_level:any = 0
  remove_dropdown(data:any, index:any) {
    if (this.data.length > 1) {
      this.data.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.Obj['lvl' + i + '_cd'];
    }
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }
  level11 :any= []
  project_obj:any = {}
  selectProject() {
    this.project_obj = Object.assign({}, {})
    this.project_arr = []
    this.project_arr = [{}]
    let arr:any = []
    let duumy :any= []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!arr.includes(this.project_hier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.project_hier[i]['lvl1_cd'], lvl1_value: this.project_hier[i]['lvl1_value'], lvl1_node_type: this.project_hier[i]['lvl1_node_type'] })
        arr.push(this.project_hier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER').modal('show');
  }
  remove_dropdown2(data:any, index:any) {
    if (this.project_arr.length > 1) {
      this.project_arr.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.project_obj['lvl' + i + '_cd'];
    }
    for (let i = 1; i <= 8; i++) {
      if (this.project_obj['lvl' + i + '_cd']) {
        this.project_obj['leaf_cd'] = this.project_obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }
  data:any = [{}]
  first_list:any = []
  async getAllHier() {
    this.first_list = []
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['buss_ent_type'] = 'Location'
    //console.log(obj)
    var resp = await this.propHierS.getLocationHierarchy(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.first_list = resp['data'].filter((x:any) => { return x['hier_type'] == "Planing" });
      for (let i = 0 + 1; i <= 8; i++) {
        delete this.Obj['lvl' + i + '_cd'];
      }
      this.data = []
      this.data = [{}]
    } else {

    }
  }
  allHier:any = []
  hier_node_obj :any= {}
  async getAllLevls() {
    var obj :any= new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['hier_type_id'] = this.Obj['hier_type_id']
    console.log(obj)
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp, ' data')
      this.allHier = resp.data;
      this.level1 = await this.commonS.refresh(this.allHier)
    } else {
    }

    this.Obj['hier_type_id'] = this.first_list[0]['hier_type_id']
  }
  async getAllLevls2() {
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'ACTIVE';
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      //console.log(resp, ' data')
      let data = resp.data;
      for (let i = 0; i < data.length; i++) {
        this.hier_node_obj[data[i]['prop_hier_id']] = data[i]
      }
      console.log(this.hier_node_obj, 'hier_node_obj')

    } else {
    }
  }
  level1:any = []
  level2:any = []
  level3:any = []
  level4:any = []
  level5:any = []
  level6:any = []
  level7:any = []
  list_flag = true
  create_flag = false
  update_flag = false

  locationToType:any = {}
  async getLocationList() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.locationS.getLocationData(JSON.stringify(obj));
    console.log(resp, 'list location')
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        this.locationToType[resp['data'][i]['loc_id']] = resp['data'][i]
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  open_create() {
    this.getAllLevls()
    this.Obj = Object.assign({}, {})
    if (this.dupliData.length > 0) {
      this.Obj['doc_local_no'] = this.dupliData[this.dupliData.length - 1]['property_lease_group_id']
    } else {
      this.Obj['doc_local_no'] = 1;
    }
    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
    this.pdf_fg = false;
    this.xl_fg = false;
  }
  async open_list() {
    this.project_obj['project_path_desc'] = ''
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    this.Obj = {}
    this.data = []
    this.data = [{}]
    this.datasource = new MatTableDataSource([]);
    this.datasource.sort = this.sort.toArray()[0];
    this.datasource.paginator = this.paginator.toArray()[0];
    await this.changeList()
  }
  remove() {
    if (this.outer_acq_row.length > 1) {
      this.outer_acq_row.pop()
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  export() {
    let ExpData = [{
      'one': 'Lease Type',
      'two': 'Plot/Khasra no',
      'three': 'Address Of Property',
      'four': 'Bigha',
      'five': 'Biswa',
      'six': 'Biswansi',
      'seven': 'Kachwansi',
      'eight': 'Sqf',
      'nine': 'Total Area in Sqf',
      // 'ten': 'Action',
    },
    ]
    var exp = []
    for (var i = 0; i < ExpData.length; i++) {
      var obj :any= new Object();
      obj['1'] = ExpData[i]['one']
      obj['2'] = ExpData[i]['two']
      obj['3'] = ExpData[i]['three']
      obj['4'] = ExpData[i]['four']
      obj['5'] = ExpData[i]['five']
      obj['6'] = ExpData[i]['six']
      obj['7'] = ExpData[i]['seven']
      obj['8'] = ExpData[i]['eight']
      obj['9'] = ExpData[i]['nine']
      // obj['10'] = ExpData[i]['ten']
      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, 'Numbering_Scheme_format')
  }


  onFileUpload(event:any, files:any) {
    this.pdf_fg = false;
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.choosen_file = true
    console.log(this.selectedFile)
  }
  imagePath2:any;
  imgURL2:any;
  selectedFile2:any;
  onFileUpload2(event:any, files:any) {
    this.selectedFile2 = null
    this.imagePath2 = null
    this.imgURL2 = null
    this.xl_fg = false;
    //console.log(event, files)
    this.selectedFile2 = <File>event.target.files[0];
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath2 = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL2 = reader.result;
    };
    this.choosen_file = true
  }
  reset() {
    // this.stepper1.selectedIndex=0
    // this.stepper2.selectedIndex=0
    this.Obj = {}
    this.outer_acq_row = []
    this.planing_arr = []
    this.planing_arr = [{}]
    this.acp_arr = [{}]
    this.old_documents = []
    this.old_table_data = []
    this.last_table = []
  }
  allPlan_layout:any = []
  async getpropertygroup() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['group_type'] = "LEASELAYOUT"
    //console.log(obj)
    var resp = await this.leaseservice.getpropertygroup(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allPlan_layout = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  dupliData:any;
  async changeList() {
    this.Obj['hier_type_id'] = this.first_list[0]['hier_type_id']
    let dummy = []
    for (let i = 0; i < this.allPlan_layout.length; i++) {
      if (this.allPlan_layout[i]['hier_type_id'] == this.Obj['hier_type_id']) {
        let ob = this.hier_node_obj[this.allPlan_layout[i]['prop_hier_id']]
        console.log(ob, this.hier_node_obj)
        let dummy2 = []
        for (let j = 1; j <= 8; j++) {
          if (ob['lvl' + j + '_cd']) {
            dummy2.push(ob['lvl' + j + '_value'])
          }
        }
        this.allPlan_layout[i]['path_desc'] = dummy2.join(' --> ')
        this.allPlan_layout[i]['target_name'] = ob['leaf_value']
        this.allPlan_layout[i]['target_type'] = ob['leaf_node_type']
        dummy.push(this.allPlan_layout[i])
      }
    }
    for (let i = 0; i < dummy.length; i++) {
      console.log(this.projectKeyValue[dummy[i]['project_node_cd']], dummy[i]['project_node_cd'])
      let ob:any = {}
      let desc:any = []
      ob = Object.assign({}, this.projectKeyValue[dummy[i]['project_node_cd']])
      console.log(ob, this.projectKeyValue[dummy[i]['project_node_cd']], dummy[i]['project_node_cd'])
      for (let j = 1; j <= 8; j++) {
        if (ob['lvl' + j + '_cd']) {
          desc.push(ob['lvl' + j + '_value'])
        }
      }
      dummy[i]['project_desc'] = desc.join(' --> ')

    }
    console.log(dummy, this.projectKeyValue)
    this.dupliData = dummy;
    this.datasource = new MatTableDataSource(dummy);
    this.datasource.sort = this.sort.toArray()[0];
    this.datasource.paginator = this.paginator.toArray()[0];
  }
  changeDate() {
    let date = new Date()
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var day = date.getDate(), monthIndex = date.getMonth(), year = date.getFullYear()
    return monthNames[monthIndex] + ":" + day + ':' + year + " : " + date.getHours() + " : " + date.getMinutes() + " : " + date.getSeconds()
  }
  objForPropHier:any = {}
  setProjectHierarchyInAccount() {
    let dummy:any = []
    let dummy2:any = []
    let num:any = 0
    let node_cd:any;
    for (let j = 1; j < 8; j++) {
      if (!this.project_obj['lvl' + j + "_cd"]) {
        num = j
        break
      }
    }
    let new_obj2:any = {}
    for (let i = 0; i < this.project_hier.length; i++) {
      if (this.project_hier[i]['leaf_cd'] == this.project_obj['lvl' + (num - 1) + "_cd"]) {
        new_obj2 = this.project_hier[i]
      }
    }
    console.log(this.project_obj, num)
    this.objForPropHier = Object.assign({}, this.project_obj)
    this.project_obj['lvl' + num + "_cd"] = null
    this.project_obj['lvl' + num + "_user_cd"] = 'leaseLayout'
    this.project_obj['lvl' + num + "_value"] = "Lease Layout made on " + this.changeDate()
    this.project_obj['lvl' + num + "_type"] = 'text'
    this.project_obj['lvl' + num + "_node_type"] = 'leaseLayout'
    this.project_obj['module_cd'] = 'PROPERTY'
    for (let i = 0; i < this.uploaded_file_data.length; i++) {
      let obj:any = {}
      obj = Object.assign({}, this.project_obj)
      if (!dummy2.includes(this.uploaded_file_data[i]['lease_type'])) {
        obj['lvl' + (num + 1) + "_cd"] = null
        obj['lvl' + (num + 1) + "_user_cd"] = this.uploaded_file_data[i]['lease_type']
        obj['lvl' + (num + 1) + "_value"] = this.uploaded_file_data[i]['property_type']
        obj['lvl' + (num + 1) + "_node_type"] = 'Propertytype'
        obj['lvl' + (num + 1) + "_type"] = 'text'
        dummy2.push(this.uploaded_file_data[i]['lease_type'])
      }
      if (i != 0) {
        delete obj['lvl' + num + "_type"]
      }
      console.log(obj, '601')
      obj['lvl' + (num + 2) + "_cd"] = null
      obj['lvl' + (num + 2) + "_user_cd"] = this.uploaded_file_data[i]['plot_no']
      obj['lvl' + (num + 2) + "_value"] = this.uploaded_file_data[i]['plot_no']
      obj['lvl' + (num + 2) + "_type"] = 'text'
      obj['lvl' + (num + 2) + "_node_type"] = 'Property'
      obj['b_acct_id'] = this.b_acct_id
      obj['module_cd'] = 'PROPERTY'
      obj["leaf_user_cd"] = this.uploaded_file_data[i]['plot_no']
      obj["leaf_cd"] = null
      obj["leaf_value"] = this.uploaded_file_data[i]['plot_no']
      obj["leaf_type"] = 'text'
      obj["leaf_node_type"] = 'Property'
      obj["data"] = []
      for (let m = 0; m <= 7; m++) {
        if (new_obj2['lvl' + m + '_cd'] && m != 1) {
          obj['lvl' + m + '_user_cd'] = new_obj2['lvl' + m + '_user_cd']
          obj['lvl' + m + '_cd'] = new_obj2['lvl' + m + '_cd']
        }
      }
      console.log(this.projectKeyValue, obj, '631')
      obj['lvl1_user_cd'] = this.projectKeyValue[obj['lvl2_cd']]['lvl1_user_cd']
      obj['lvl2_user_cd'] = this.projectKeyValue[obj['lvl2_cd']]['lvl2_user_cd']
      obj["data"].push(this.uploaded_file_data[i])
      dummy.push(obj)
      obj = {}
    }
    return dummy
  }

  setProjectHierarchyInAccountWithProp() {
    let dummy:any = []
    let dummy2:any = []
    let num:any = 0
    let node_cd:any;
    for (let j = 1; j < 8; j++) {
      if (!this.objForPropHier['lvl' + j + "_cd"]) {
        num = j
        node_cd = this.objForPropHier['lvl' + j + "_user_cd"]
        break
      }
    }
    let new_obj2:any = {}
    for (let i = 0; i < this.project_hier.length; i++) {
      if (this.project_hier[i]['leaf_cd'] == this.objForPropHier['lvl' + (num - 1) + "_cd"]) {
        new_obj2 = this.project_hier[i]
      }
    }
    console.log(node_cd, this.project_hier, new_obj2, num, this.objForPropHier)
    let dup:any = []

    this.objForPropHier['lvl' + num + "_cd"] = this.changeDate()
    this.objForPropHier['lvl' + num + "_user_cd"] = 'leaseLayout'
    this.objForPropHier['lvl' + num + "_value"] = "Lease Layout made on " + this.changeDate()
    this.objForPropHier['lvl' + num + "_type"] = 'text'
    this.objForPropHier['lvl' + num + "_node_type"] = 'leaseLayout'

    for (let i = 0; i < this.uploaded_file_data.length; i++) {
      let dummy3:any = []
      if (i == 0) {
        let loc_obj :any = {}
        // new_obj = {}
        loc_obj['loc_type_cd'] = 'leaseLayout'
        loc_obj['loc_id_std'] = this.ebillUser['account_short_name']
        loc_obj['loc_id_str'] = "Lease Layout made on " + this.changeDate()
        loc_obj['life_cycle_status'] = 'ACTIVE'
        loc_obj['loc_short_nm'] = "Lease Layout made on " + this.changeDate()
        dummy3.push(loc_obj)
      }
      let obj:any = {}
      obj = Object.assign({}, this.objForPropHier)
      if (!dummy2.includes(this.uploaded_file_data[i]['lease_type'])) {
        obj['lvl' + (num + 1) + "_cd"] = this.uploaded_file_data[i]['lease_type']
        obj['lvl' + (num + 1) + "_user_cd"] = this.uploaded_file_data[i]['lease_type']
        obj['lvl' + (num + 1) + "_value"] = this.uploaded_file_data[i]['property_type']
        obj['lvl' + (num + 1) + "_node_type"] = 'Propertytype'
        obj['lvl' + (num + 1) + "_type"] = 'text'
        dummy2.push(this.uploaded_file_data[i]['lease_type'])
      }
      if (i != 0) {
        delete obj['lvl' + num + "_type"]
      }
      obj['lvl' + (num + 2) + "_cd"] = this.uploaded_file_data[i]['plot_no']
      obj['lvl' + (num + 2) + "_user_cd"] = this.uploaded_file_data[i]['plot_no']
      obj['lvl' + (num + 2) + "_value"] = this.uploaded_file_data[i]['plot_no']
      obj['lvl' + (num + 2) + "_type"] = 'text'
      obj['lvl' + (num + 2) + "_node_type"] = 'Property'
      obj['b_acct_id'] = this.b_acct_id
      obj["leaf_user_cd"] = this.uploaded_file_data[i]['plot_no']
      obj["leaf_cd"] = null
      obj["leaf_value"] = this.uploaded_file_data[i]['plot_no']
      obj["leaf_type"] = 'text'
      obj["leaf_node_type"] = 'Property'
      obj["data"] = []
      // =====================
      let new_obj:any = {}
      if (!dup.includes(this.uploaded_file_data[i]['lease_type'])) {
        new_obj['loc_type_cd'] = 'Propertytype'
        new_obj['loc_id_std'] = this.ebillUser['account_short_name']
        new_obj['loc_id_str'] = this.uploaded_file_data[i]['lease_type']
        new_obj['life_cycle_status'] = 'ACTIVE'
        new_obj['loc_short_nm'] = this.uploaded_file_data[i]['lease_type']
        dummy3.push(new_obj)
        dup.push(this.uploaded_file_data[i]['lease_type'])
      }
      // new_obj = {}
      new_obj = {}
      new_obj['loc_type_cd'] = 'Property'
      new_obj['loc_id_std'] = this.ebillUser['account_short_name']
      new_obj['loc_id_str'] = this.uploaded_file_data[i]['plot_no']
      new_obj['life_cycle_status'] = 'ACTIVE'
      new_obj['loc_short_nm'] = this.uploaded_file_data[i]['plot_no']
      dummy3.push(new_obj)
      // ==========
      obj["data"] = dummy3
      for (let m = 0; m <= 7; m++) {
        if (new_obj2['lvl' + m + '_cd'] && m != 1) {
          obj['lvl' + m + '_cd'] = new_obj2['lvl' + m + '_user_cd']
        }
      }
      obj['lvl2_cd'] = this.objForPropHier['lvl2_cd']
      obj['lvl1_cd'] = this.objForPropHier['lvl1_cd']
      dummy.push(obj)
    }
    return dummy
  }
  async submit() {
    let hier_id = 0
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl' + this.leaf_level + '_cd'] == this.Obj['lvl' + this.leaf_level + '_cd'] && this.Obj['lvl' + this.leaf_level + '_cd'] == this.allHier[i]['leaf_cd']) {
        {
          console.log(this.allHier[i])
          hier_id = this.allHier[i]['prop_hier_id']
        }
      }
    }

    let doc:any = []
    if (this.Obj['csv_doc_id']) {
      doc.push(this.Obj['csv_doc_id'])
    }
    if (this.Obj['pdf_doc_id']) {
      doc.push(this.Obj['pdf_doc_id'])
    }
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['life_cycle_status'] = 'ACTIVE'
    this.Obj['doc_id'] = doc
    this.Obj['data'] = this.uploaded_file_data
    this.Obj['user_id'] = this.ebillUser.user_id
    this.Obj['prop_hier_id'] = hier_id
    this.Obj['acc_hier'] = this.setProjectHierarchyInAccount()

    // return
    if (!this.location_idet_stdr[this.ebillUser['account_short_name']]) {
      let obj:any = {}
      obj['term'] = 'PROPFLD6'
      obj['code'] = this.ebillUser['account_short_name']
      obj['value'] = this.ebillUser['account_name']
      this.code_value_arr.push(obj)
    }
    if (!this.location_type_obj['Property']) {
      let obj : any= {}
      obj['term'] = 'PROPFLD5'
      obj['code'] = 'Property'
      obj['value'] = 'Property'
      this.code_value_arr.push(obj)
    }

    if (!this.location_type_obj['leaseLayout']) {
      let obj : any= {}
      obj['term'] = 'PROPFLD5'
      obj['code'] = 'leaseLayout'
      obj['value'] = 'Lease Layout'
      this.code_value_arr.push(obj)
    }
    if (!this.location_type_obj['Propertytype']) {
      let obj : any= {}
      obj['term'] = 'PROPFLD5'
      obj['code'] = 'Propertytype'
      obj['value'] = 'Property Type'
      this.code_value_arr.push(obj)
    }
    this.Obj['group_type'] = "LEASELAYOUT"
    this.Obj['code_value_arr'] = this.code_value_arr
    console.log(this.Obj)
    if (this.Obj['hier_type_id'] && this.Obj['pdf_doc_type'] && this.Obj['csv_doc_type'] && this.choosen_file_uploaded == true && this.choosen_file_uploaded2 == true) {
      if (this.defeted_flag) {
        Swal.fire('Error...', 'There Are Some Defected Items in Uploaded Numbering Scheme', 'error')
      } else {
        console.log(this.Obj, this.project_obj)
        this.spinner.show()

        await this.insertHierarchyIntoAccount(this.setProjectHierarchyInAccount())
        if(this.duplicate_leaf || this.proj_hier) return
        if (this.code_value_arr.length > 0) {
          await this.insert_code_value()
        }
        var resp = await this.leaseservice.createpropertyLease(this.Obj)
        if (resp['error'] == false) {
            await this.insertHierarchySyncWithProp(this.setProjectHierarchyInAccountWithProp())
            this.spinner.hide()
            this.open_list()
            this.reset()
            await this.getpropertygroup()
            await this.changeList();
            this.project_obj['project_path_desc'] = ''
            Swal.fire('Success', 'Created Successfully', 'success')
        } else {
          this.spinner.hide()
          Swal.fire('Error', 'Some Error Occured While Creating', 'error')
        }
      }
    } else {
      Swal.fire('Warning...', 'Please Select All * Fields', 'warning')
    }
    // this.uploaded_file_data = []
    // }else{
    //   Swal.fire('Error...','Please Enter All * Fields','error')
    // }
  }
  async insert_code_value() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['code_value_arr'] = this.code_value_arr

    console.log(obj)
    var resp = await this.landInput_S.createCodeValueForPlanLayout(obj)
    console.log(resp)
    if (resp['error'] == false) {
      console.log()
      this.spinner.hide()
      await this.getCodeValue_obj()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured While Createing Code Value', 'error')
    }
  }
  proj_hier: boolean = false;
  duplicate_leaf: boolean = false;
  async insertHierarchyIntoAccount(data:any) {
this.proj_hier = false;
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['data'] = data
    obj['module_cd'] = 'PROPERTY'
    obj['create_user_id'] = this.ebillUser['user_id']
    let user_code_by_user = obj['data'];

//Checking for duplicate leaf
    let message:any = ''
    user_code_by_user.map((x:any) => {
      this.leaf_user_cd.map((y:any) => {
        if (x['leaf_user_cd'] == y) {
          this.duplicate_leaf = true;
          message = y;
        }
      })
    })

    console.log(this.duplicate_leaf)
    if (this.duplicate_leaf) {
      let project_path = this.project_obj['project_path_desc'].split('-->');
      this.spinner.hide();
      Swal.fire('ERROR', `Prooerty Number ${message} is already exist in ${project_path[project_path.length-1]}`, 'error');
      this.proj_hier = true;
      return;
    }


    var resp = await this.propHierS.getprojectcreateHierarchyMultiple(obj)
    console.log(resp)
    if (resp['error'] == false) {
      console.log()
      this.spinner.hide()
      await this.getCodeValue_obj()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured While Updating Project Hierarchy', 'error')
      this.proj_hier = true;
    }
  }

  async insertHierarchySyncWithProp(data:any) {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['data'] = data
    obj['hier_type_id'] = this.Obj['hier_type_id']
    obj['create_user_id'] = this.ebillUser['user_id']
    console.log(obj)
    var resp = await this.propHierS.insertSyncWithAccountHierToProp(obj)
    console.log(resp)
    if (resp['error'] == false) {
      console.log()
      this.spinner.hide()
      await this.getCodeValue_obj()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured While Creating Property Hierarchy', 'error')
    }
  }
  async update() {
    console.log(this.allHier)
    let hier_id = 0
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl' + this.leaf_level + '_cd'] == this.Obj['lvl' + this.leaf_level + '_cd'] && this.Obj['lvl' + this.leaf_level + '_cd'] == this.allHier[i]['leaf_cd']) {
        {
          console.log(this.allHier[i])
          hier_id = this.allHier[i]['prop_hier_id']
        }
      }
    }

    console.log(hier_id)
    let doc = []
    if (this.Obj['csv_doc_id']) {
      doc.push(this.Obj['csv_doc_id'])
    }
    if (this.Obj['pdf_doc_id']) {
      doc.push(this.Obj['pdf_doc_id'])
    }
    this.Obj['life_cycle_status'] = 'ACTIVE'
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['doc_id'] = doc
    this.Obj['data'] = this.uploaded_file_data
    this.Obj['user_id'] = this.ebillUser.user_id
    this.Obj['update_user_id'] = this.ebillUser.user_id
    this.Obj['prop_hier_id'] = hier_id
    this.Obj['group_type'] = "LEASELAYOUT"
    if (!this.location_idet_stdr[this.ebillUser['account_short_name']]) {
      let obj : any= {}
      obj['term'] = 'PROPFLD6'
      obj['code'] = this.ebillUser['account_short_name']
      obj['value'] = this.ebillUser['account_name']
      this.code_value_arr.push(obj)
    }
    if (!this.location_type_obj['Property']) {
      let obj : any= {}
      obj['term'] = 'PROPFLD5'
      obj['code'] = 'Property'
      obj['value'] = 'Property'
      this.code_value_arr.push(obj)
    }
  let obj1 : any= {}
    obj1['doc_id'] = this.Obj['pdf_doc_id']
    for (let i = 0; i < this.old_documents.length; i++) {
      if (i == 0) {
        this.old_documents[i]['doc_type'] = this.Obj['pdf_doc_type']
        this.old_documents[i]['doc_name'] = this.Obj['pdf_file_name']
        this.old_documents[i]['doc_id'] = this.Obj['pdf_doc_id']
      }
      if (i == 1) {
        this.old_documents[i]['doc_type'] = this.Obj['csv_doc_type']
        this.old_documents[i]['doc_name'] = this.Obj['csv_file_name']
        this.old_documents[i]['doc_id'] = this.Obj['csv_doc_id']
      }
    }
    this.Obj['upload_data'] = this.old_documents
    this.Obj['old_data'] = this.old_data
    console.log(this.Obj)
    if (this.Obj['pdf_doc_type'] && this.Obj['csv_doc_type'] && this.choosen_file_uploaded == true && this.choosen_file_uploaded2 == true) {
      if (this.uploaded_file_data.length > 0) {
        console.log(this.Obj)
        this.spinner.show()
        var resp = await this.leaseservice.updatepropertyGroup(this.Obj)
        if (resp['error'] == false) {
          this.spinner.hide()
          this.open_list()
          if (this.code_value_arr.length > 0) {
            await this.insert_code_value()
          }
          this.reset()
          await this.getpropertygroup()
          Swal.fire('Success', 'Updated Successfully', 'success')
        } else {
          this.spinner.hide()
          Swal.fire('Error', resp['data'], 'error')
        }
      } else {
        Swal.fire('Warning...', 'Please Upload New Numbering Scheme File For Update', 'warning')
      }
    }
    else {
      Swal.fire('Warning...', 'Please Select All * Fields', 'warning')
    }
    // this.uploaded_file_data = []
  }






  async onFileChange(event: any) {
    /* wire up file reader */
    this.uploaded_file_data = []
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      ////console.log(data)
      this.setData(data);
      // Data will be logged in viewarray format containing objects
    };
  }
  uploaded_file_data :any= []
  last_table:any = []
  defeted_flag:any = false
  defeted_arr:any = []
  code_value_arr :any= []
  async setData(data:any) {
    //console.log(data, 'uploaded data')
    this.defeted_flag = false
    this.uploaded_file_data = []
    // console.log(data)
    let dummy :any= []
    let unique_arr:any = []
    let unique_arr2:any = []
    for (let i = 1; i < data.length; i++) {
      let obj:any = {}
      obj['lease_type'] = data[i]['1']
      obj['plot_no'] = data[i]['2']
      obj['address'] = data[i]['3']
      obj['bigha'] = data[i]['4']
      obj['biswa'] = data[i]['5']
      obj['biswansi'] = data[i]['6']
      obj['kachwansi'] = data[i]['7']
      obj['sqf'] = data[i]['8']
      obj['total_area_sqf'] = data[i]['9']
      obj['life_cycle_status'] = 'ACTIVE'
      if (!unique_arr.includes(obj['lease_type'])) {
        unique_arr.push(obj['lease_type'])
      }
      if (!unique_arr2.includes(obj['plot_no'])) {
        unique_arr2.push(obj['plot_no'])
      } else {
        this.defeted_flag = true
        Swal.fire('Error', 'There Are Some Duplicate Property Number In The Uploaded File', 'error')
      }
      dummy.push(obj)
    }

    for (let i = 0; i < dummy.length; i++) {
      dummy[i]['lease_type'] = dummy[i]['lease_type']
      dummy[i]['plot_no'] = dummy[i]['plot_no']
      dummy[i]['address'] = dummy[i]['address']
      dummy[i]['bigha'] = dummy[i]['bigha']
      dummy[i]['biswa'] = dummy[i]['biswa']
      dummy[i]['biswansi'] = dummy[i]['biswansi']
      dummy[i]['kachwansi'] = dummy[i]['kachwansi']
      dummy[i]['sqf'] = dummy[i]['sqf']
      dummy[i]['total_area_sqf'] = dummy[i]['total_area_sqf']
      dummy[i]['life_cycle_status'] = 'ACTIVE'
      dummy[i]['acc_short_name'] = this.ebillUser['account_short_name']
      if (dummy[i]['plot_no'] != null && dummy[i]['plot_no'] != undefined) {
        this.uploaded_file_data.push(dummy[i])
      }
    }
    let dummy2:any = []
    for (let i = 0; i < unique_arr.length; i++) {
      let sum:any = 0
      let count:any = 0
      let obj2:any = {}
      for (let j = 0; j < dummy.length; j++) {
        // if (i == j) {
        //   obj2 = Object.assign({}, dummy[i])
        // }
        if (unique_arr[i] == dummy[j]['lease_type']) {
          obj2['lease_type'] = dummy[j]['lease_type']
          obj2['plot_no'] = dummy[j]['plot_no']
          obj2['address'] = dummy[j]['address']
          obj2['bigha'] = dummy[j]['bigha']
          obj2['biswa'] = dummy[j]['biswa']
          obj2['biswansi'] = dummy[j]['biswansi']
          obj2['kachwansi'] = dummy[j]['kachwansi']
          obj2['sqf'] = dummy[j]['sqf']
          obj2['total_area_sqf'] = dummy[j]['total_area_sqf']
          sum = sum + dummy[j]['total_area_sqf']
          count = count + 1
        }
      }
      obj2['total_area_sqf'] = sum.toFixed(3)
      obj2['count'] = count
      dummy2.push(obj2)
    }
    this.code_value_arr = []
    this.last_table = dummy2
    for (let i = 0; i < this.last_table.length; i++) {
      if (!this.property_type[this.last_table[i]['lease_type']] && this.last_table[i]['lease_type']) {
        let obj : any= {}
        obj['term'] = 'PROP0015'
        obj['code'] = this.last_table[i]['lease_type']
        obj['value'] = this.last_table[i]['lease_type']
        this.code_value_arr.push(obj)
      }
    }
    console.log(this.last_table, 'last_table', this.property_type, this.code_value_arr)
  }
  pdf_fg: boolean = true;
  xl_fg: boolean = true;
  async open_update(data:any) {
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
    this.Obj = Object.assign({}, data)
    let all_path = []
    this.Obj['hier_type_id'] = data['hier_type_id']
    let dummy = []
    this.project_obj = this.projectKeyValue[data['project_node_cd']]
    this.project_obj['project_node_cd'] = data['project_node_cd']
    for (let i = 1; i <= 7; i++) {
      if (this.project_obj['lvl' + i + '_cd']) {
        dummy.push(this.project_obj['lvl' + i + '_value'])
      }
    }
    this.project_obj['project_path_desc'] = dummy.join('-->')
    this.Obj = Object.assign({}, data)
    console.log("1101 ", this.Obj)
    console.log("1102 ", data)
    // if (this.Obj['pdf_doc_id'] > 0) {
    //   this.pdf_fg = true;
    // }
    await this.getAllLevls()
    await this.setPreDefinedHierrarchy(data)
    await this.getpropertyGroupdata(data['property_lease_group_id'])
    await this.getAllUploadedDocument(data['property_lease_group_id'])
    this.choosen_file_uploaded2 = true
    this.choosen_file_uploaded = true
    $('.nav-tabs a[href="#tab-7-3"]').tab('show')
  }
  async setPreDefinedHierrarchy(data:any) {
    this.data = []
    let ob = this.hier_node_obj[data['prop_hier_id']]
    await this.commonS.refresh(this.allHier)
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        let index = j
        if (index == 1) {
          this.level2 = await this.commonS.onChangeLvl1(this.allHier, ob)
        } else if (index == 2) {
          this.level3 = await this.commonS.onChangeLvl2(this.allHier, ob)
        }
        else if (index == 3) {
          this.level4 = await this.commonS.onChangeLvl3(this.allHier, ob)
        }
        else if (index == 4) {
          this.level5 = await this.commonS.onChangeLvl4(this.allHier, ob)
        }
        else if (index == 5) {
          this.level6 = await this.commonS.onChangeLvl5(this.allHier, ob)
        }
        else if (index == 6) {
          this.level7 = await this.commonS.onChangeLvl6(this.allHier, ob)
        }
        this.data.push(ob)
      }
    }
    for (let i = 1; i <= 8; i++) {
      if (ob['lvl' + i + '_cd']) {
        this.Obj['lvl' + i + '_cd'] = ob['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
    console.log(ob, this.first_list, this.allHier)
  }
  old_table_data = []
  old_data = []
  async getpropertyGroupdata(group_id:any) {
    this.old_data = []
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['property_lease_group_id'] = group_id
    obj['status'] = 'ACTIVE'
    //console.log(obj)
    var resp = await this.leaseservice.getpropertyGroupdata(JSON.stringify(obj));
    console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      let data = resp['data']
      this.old_data = data

      let dummy2:any = []
      let unique_arr :any= []
      for (let i = 0; i < data.length; i++) {
        if (!unique_arr.includes(data[i]['property_type'])) {
          unique_arr.push(data[i]['property_type'])
        }
      }
      for (let i = 0; i < unique_arr.length; i++) {
        let sum:any = 0
        let count:any = 0
        let obj2:any = {}
        for (let j = 0; j < data.length; j++) {
          if (unique_arr[i] == data[j]['property_type']) {
            obj2['lease_type'] = data[j]['property_type']
            obj2['plot_no'] = data[j]['assigned_property_number']
            obj2['address'] = data[j]['address']
            obj2['bigha'] = data[j]['bigha']
            obj2['biswa'] = data[j]['biswa']
            obj2['biswansi'] = data[j]['biswansi']
            obj2['kachwansi'] = data[j]['kachwansi']
            obj2['sqf'] = data[j]['in_sqf']
            obj2['total_area_sqf'] = data[j]['area']
            sum = sum + data[j]['area']
            count = count + 1
          }
        }
        obj2['total_area'] = sum.toFixed(3)
        obj2['count'] = count
        dummy2.push(obj2)
      }
      this.old_table_data = dummy2
      console.log(this.old_table_data, 'old table data')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  old_documents:any = []
  async getAllUploadedDocument(group_id:any) {
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_local_no'] = group_id
    obj['status'] = 'ACTIVE'
    console.log(group_id)
    var resp = await this.docService.getAllUploadedDocument(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.old_documents = resp['data']
      console.log(resp['data'])
      this.Obj['doc_local_no'] = resp['data'][0]['doc_local_no'];
      let arr = resp['data'][0]['doc_name'].split('.')
      if (arr[arr.length - 1] == 'pdf') {
        this.Obj['old_pdf_doc_type'] = resp['data'][0]['doc_type']
        this.Obj['old_pdf_doc_id'] = resp['data'][0]['doc_id']
        this.Obj['old_pdf_file_name'] = resp['data'][0]['doc_name']
        this.Obj['pdf_doc_type'] = resp['data'][0]['doc_type']
        this.Obj['pdf_doc_id'] = resp['data'][0]['doc_id']
        this.Obj['pdf_file_name'] = resp['data'][0]['doc_name']
      } else {
        this.Obj['csv_doc_type'] = resp['data'][0]['doc_type']
        this.Obj['csv_doc_id'] = resp['data'][0]['doc_id']
        this.Obj['csv_file_name'] = resp['data'][0]['doc_name']
      }
      if (resp['data'][1]) {
        let arr2 = resp['data'][1]['doc_name'].split('.')
        if (arr2[arr2.length - 1] == 'pdf') {
          this.Obj['old_pdf_doc_type'] = resp['data'][1]['doc_type']
          this.Obj['old_pdf_doc_id'] = resp['data'][1]['doc_id']
          this.Obj['old_pdf_file_name'] = resp['data'][1]['doc_name']
          this.Obj['pdf_doc_type'] = resp['data'][1]['doc_type']
          this.Obj['pdf_doc_id'] = resp['data'][1]['doc_id']
          this.Obj['pdf_file_name'] = resp['data'][1]['doc_name']

        } else {
          this.Obj['csv_doc_type'] = resp['data'][1]['doc_type']
          this.Obj['csv_doc_id'] = resp['data'][1]['doc_id']
          this.Obj['csv_file_name'] = resp['data'][1]['doc_name']
        }
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async delete(element:any) {
    console.log(element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  async finaldelete(element:any) {
    let obj : any= {}
    obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    var resp = await this.leaseservice.inactivePropertyGroup(obj)
    //console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getpropertygroup()
      await this.changeList()
      Swal.fire('Success', 'Deleted Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }
  }
  choosen_file_uploaded2 = true

  async upload() {
    this.pdf_fg = true;

    // let index = i
    if (!this.Obj['pdf_doc_type']) {
      Swal.fire('Error', 'Please Select All Fields', 'error')
    } else {
      this.spinner.show();
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      let obj:any = new Object();
      // obj = Object.assign({}, data)
      obj['b_acct_id'] = this.b_acct_id;
      obj['doc_name'] = this.uploader.queue[0].some.name;
      obj['doc_formate'] = 'pdf'
      obj['doc_type'] = this.Obj['pdf_doc_type']
      obj['user_id'] = this.ebillUser.user_id
      obj['doc_local_no'] = this.Obj['doc_local_no']
      this.Obj['pdf_file_name'] = obj['doc_name']
      obj['doc_id'] = this.Obj['pdf_doc_id']
      this.spinner.show()
      console.log(this.httpUrl)
      const params = JSON.stringify(obj);
      this.uploader.queue[0].url = this.httpUrl + '/property/PropertyDoc/uploadDoc' + params;
      this.uploader.queue[0].upload();
      this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        var resp = JSON.parse(response);
        console.log(resp, 'resp after success')
        if (resp.error == false) {
          this.uploader.queue = []

          this.spinner.hide();
          this.choosen_file = true
          this.choosen_file_uploaded = true
          this.Obj['pdf_doc_id'] = resp['data']
          this.Obj['pdf_doc_id2'] = resp['data']
          this.uploader.queue = []
          Swal.fire('Success', 'File Uploaded Successfully!!', 'success');
        } else {
          this.spinner.hide()
          Swal.fire('Error', resp.data);
        }
      }
    }


  }
  fileURL:any;
  async view() {
    //console.log(this.Obj)
    this.spinner.show();
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.Obj['pdf_doc_id']
    obj['doc_name'] = this.Obj['pdf_file_name']
    console.log(this.Obj)
    var res = await this.docService.view_Doc(obj);
    //console.log(res)
    if (res) {
      var docname = this.Obj['pdf_file_name']
      var ext = docname.split('.');
      let a = document.createElement('a'), file;
      if (ext[1].toLowerCase() == 'png' || ext[1].toLowerCase() == 'jpeg' || ext[1].toLowerCase() == 'jpg') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: 'application/pdf/image' });
        // var fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        // let file = new Blob([res], { type: 'application/pdf/image' });
        // var fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
        a.href = window.URL.createObjectURL(file);
        a.download = this.Obj['pdf_file_name'];
        a.click();
      }
      this.spinner.hide();
    }
  }
  async view3() {
    //console.log(this.Obj)
    this.spinner.show();
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.Obj['old_pdf_doc_id']
    obj['doc_name'] = this.Obj['old_pdf_file_name']
    //console.log(obj)
    var res = await this.docService.view_Doc(obj);
    //console.log(res)
    if (res) {
      var docname = this.Obj['old_pdf_file_name']
      var ext = docname.split('.');
      if (ext[1].toLowerCase() == 'png' || ext[1].toLowerCase() == 'jpeg' || ext[1].toLowerCase() == 'jpg') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: 'application/pdf/image' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
      this.spinner.hide();
    }
  }
  fileURL2:any;
  // async view2(data) {
  //   this.spinner.show();
  //   let obj : any= {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['doc_id'] = this.Obj['csv_doc_id']
  //   obj['doc_name'] = this.Obj['csv_file_name']
  //   //console.log(obj)
  //   var res = await this.docService.view_Doc(obj);
  //   //console.log(res)
  //   if (res) {
  //     var docname = this.Obj['csv_file_name']
  //     var ext = docname.split('.');
  //     if (ext[1].toLowerCase() == 'png' || ext[1].toLowerCase() == 'jpeg' || ext[1].toLowerCase() == 'jpg' || ext[1].toLowerCase() == 'xlsx') {
  //       const unsafeImageUrl = window.URL.createObjectURL(res);
  //       this.fileURL2 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
  //     } else {
  //       let file = new Blob([res], { type: 'application/pdf/image' });
  //       var fileURL2 = URL.createObjectURL(file);
  //       window.open(fileURL2);
  //     }
  //     this.spinner.hide();
  //   }
  // }
  async view2() {
    var obj:any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.Obj['csv_doc_id']
    obj['doc_name'] = this.Obj['csv_file_name']
    console.log(obj)
    this.spinner.show()
    if (this.Obj['csv_file_name'] != undefined) {
      var res = await this.docService.view_Doc(obj);
      if (res) {
        // const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
        // this.fileURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        var docname = this.Obj['csv_file_name']
        var ext = docname.split('.');

        if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
          // const unsafeImageUrl = window.URL.createObjectURL(res);
          // this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
          // window.open(this.fileURL );
          let file = new Blob([res], { type: 'image/png' });
          var fileURL = URL.createObjectURL(file);
          this.spinner.hide()
          window.open(fileURL, '_blank');

        } else if (ext[1].toLowerCase() == 'xlsx') {

          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {

          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        } else {

          let file = new Blob([res], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);


          window.open(fileURL);
          this.spinner.hide()
        }

      } else {
        this.spinner.hide()

      }
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'No document Uploaded !', 'error');

    }
  }
  async upload2() {
    // let index = i
    this.xl_fg = true;
    if (!this.Obj['csv_doc_type']) {
      Swal.fire('Error', 'Please Select All Fields', 'error')
    } else {
      this.spinner.show();
      const formData = new FormData();
      //console.log(this.selectedFile2)
      //console.log(this.selectedFile2.name)
      formData.append('file', this.selectedFile2, this.selectedFile2.name);
      let obj:any = new Object();
      obj['b_acct_id'] = this.b_acct_id;
      obj['doc_name'] = this.uploader2.queue[0].some.name;
      obj['doc_formate'] = 'xlsx'
      obj['doc_type'] = this.Obj['csv_doc_type']
      obj['user_id'] = this.ebillUser.user_id
      this.Obj['csv_file_name'] = obj['doc_name']
      obj['doc_local_no'] = this.Obj['doc_local_no']
      obj['doc_id'] = this.Obj['csv_doc_id']
      this.spinner.show()
      //console.log(obj)
      const params = JSON.stringify(obj);
      this.uploader2.queue[0].url = this.httpUrl + '/property/PropertyDoc/uploadDoc' + params;
      this.uploader2.queue[0].upload();
      this.uploader2.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        var resp = JSON.parse(response);
        //console.log(resp)
        if (resp.error == false) {
          //console.log(resp, 'resp after success')
          this.uploader2.queue = []
          this.selectedFile2 = null
          this.imagePath2 = null
          this.imgURL2 = null
          this.spinner.hide();
          this.choosen_file = true
          this.choosen_file_uploaded2 = true
          this.Obj['csv_doc_id'] = resp['data']
          this.uploader2.queue = []
          Swal.fire('Success', 'File Uploaded Successfully!!', 'success');
          this.selectedFile2 = null;
        } else {
          this.spinner.hide()
          Swal.fire('Error', resp.data);
        }
      }
    }

  }



  //workflow*********************************************//

  apprflag :any= false;
  level_for_approval:any = []
  workflow_level1:any = {}
  async getWorkflowlog() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE'
    obj['doc_type'] = 'LEASELAYOUT';
    this.apprflag = false;
    var resp = await this.portalmain.getWorkflows(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      if (resp.data.length == 0) {
        return;
      }
      this.workflow_level1 = resp['data'][0]
      this.level_for_approval = resp['data']
      let currentuser_role = this.ebillUser.role_cd
      for (let i = 0; i < currentuser_role.length; i++) {
        if (this.workflow_level1['role_cd'] == currentuser_role[i]) {
          this.apprflag = true
        }

      }
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }

  undertakings:any
  salBill_apprdata:any
  async Appr(element:any) {
    console.log(element)
    this.salBill_apprdata = Object.assign({}, element)
    this.salBill_apprdata['bill_desc'] = 'Approval for ' + element['target_name']
    this.salBill_apprdata['level'] = 1
    this.salBill_apprdata['branch'] = 1
    this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
    this.salBill_apprdata['doc_type'] = 'LEASELAYOUT'//this.salBill_apprdata['id']
    this.salBill_apprdata['node_cd'] = element['project_node_cd']
    this.salBill_apprdata['module_cd'] = 'PROPERTY';
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'LEASELAYOUT', 'PROPERTY');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      // await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'APPROVED')
      return;
    }
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.ebillUser, this.salBill_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.salBill_apprdata)
    /* await this.getAllRule();
    await this.getundertakings(element); */
    $('#myModal3').modal('show');
  }
  message_flag = false
  async getle(user_id:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp = await this.mainserviceEmb.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      await this.sendmessage(resp['data'][0]['phone_no'], resp['data'][0]['party_name']
      )
      this.spinner.hide();
      this.message_flag = false
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }

  async sendmessage(mobile:any, user_name:any) {
    //console.log(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['doc_local_no'])
    let resp = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'], this.salBill_apprdata['doc_local_no'], user_name)
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }
  }
  // async sendmessage(mobile) {
  //   console.log(mobile, 'Plan Layout of' + ' Number ' + this.salBill_apprdata['property_group_id'])
  //   let resp = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'] + ' Number ' + this.salBill_apprdata['property_group_id'])
  //   if (resp['error'] == false) {

  //     this.spinner.hide();

  //   } else {
  //     this.spinner.hide();
  //     Swal.fire("Error", resp['data'], 'error');
  //   }
  // }
  forwarded_user_id:any;
  appr_remark:any
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();



      obb['user_id'] = this.ebillUser.user_id
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'LEASELAYOUT'
      obb['doc_local_no'] = this.salBill_apprdata['doc_local_no']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
      obb['module_cd'] = 'PROPERTY'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      obb['level'] = this.ApprovalUserService.level1['level']
      obb['undertaking'] = JSON.stringify([])
      obb['branch'] = this.ApprovalUserService.level1['branch']
      // obb['field_value'] = this.salBill_apprdata['bill_amount']
      obb['field_value'] = 0
      var resp = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'UNDERAPPROVAL')
        await this.getpropertygroup()
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          // obb['user_id'] = -1
          // obb['vendor_id'] = this.selectedTender['party_id']
          if (this.message_flag == true) {
            /* await this.getle(this.selectedTender['party_id']) */
          }

        } else {
          if (this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        this.toastr.success("Approval Forwarded Successfully!!")
        console.log("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        console.log(resp['data'])
        // this.toastr.error(resp['data'])
      }
    } else {
      // this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }

  async ChangestatusSalBill(data:any, status:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = status;
    obj['id'] = data
    this.spinner.show();
    var resp = await this.landInput_S.changePlanLayoutStatus(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log('Successfully Chaged to ' + status)
    }
    else {
      this.spinner.hide();
      this.toastr.error(resp['data']);
    }
  }


  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();

      // var obb = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        // if(this.selectedTender['party_id'] == null || this.selectedTender['party_id'] == undefined){
        //   Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
        //   return
        // }
        // obb['user_id'] = -1
        // obb['vendor_id'] = this.selectedTender['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'LEASELAYOUT'
      obb['doc_local_no'] = this.salBill_apprdata['doc_local_no']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'PROPERTY'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.salBill_apprdata['level']
        obb['branch'] = this.salBill_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      // obb['field_value'] = this.salBill_apprdata['bill_amount']
      obb['field_value'] = 0
      var resp = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {

        //await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        //this.toastr.success("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        // this.toastr.error(resp['data'])
      }
    } else {
      // this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }





  workflowuser = []
  rolecdtolevelobj = {}
  async getWorkflowloguser() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'PROPERTY'
    obj['status'] = 'ACTIVE'
    obj['node_cd'] = this.salBill_apprdata['node_cd']
    console.log(obj, this.selectedTender)
    var resp = await this.mainserviceEmb.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflowuser = resp['data']

    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }
  statusArr:any = [];
  selectedEle:any;
  userRoles:any = []

  async viewStatus(element:any) {
    this.selectedEle = Object.assign({}, element);
    var obj:any = new Object();
    obj['doc_local_no'] = element.id;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = 'LEASELAYOUT';
    obj['module_cd'] = 'PROPERTY'
    var resp = await this.mainserviceEmb.getWorkflowlog(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.salBill_apprdata = Object.assign({}, element)
      this.salBill_apprdata['level'] = 1
      this.salBill_apprdata['branch'] = 1
      this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
      this.salBill_apprdata['doc_type'] = 'LEASELAYOUT'//this.salBill_apprdata['id']
      this.salBill_apprdata['node_cd'] = element['project_node_cd']
      this.salBill_apprdata['module_cd'] = 'PROPERTY';
      await this.getWorkflowloguser()
      console.log(this.userRoles, resp.data, 'user')
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = "Contractor"
          resp.data[i]['role_cd'] = "Contractor"
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }
        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }
      this.statusArr = resp.data
      console.log(this.statusArr);
      $('#myModal1').modal('show');
    } else {
      this.toastr.error(resp['data'])
    }
  }
  selectedTender = {}
  workObj = {}

}
