import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { DeductionService } from '../../service/deduction.service';
import { MainService } from '../../service/main.service';
import { ExcelService } from '../../service/file-export.service';
import { AllEmpService } from '../../service/all-emp.service';
import { timeStamp } from 'console';


declare var $: any


@Component({
  selector: 'app-deduction-report',
  templateUrl: './deduction-report.component.html',
  styleUrls: ['./deduction-report.component.css']
})
export class DeductionReportComponent implements OnInit {

  monthObj = { '1': 'January', '2': 'February', '3': 'March', '4': 'April', '5': 'May', '6': 'June', '7': 'July', '8': 'August', '9': 'September', '10': 'October', '11': 'November', '12': 'December' }
  monthEnd = { '1': 31, '2': 28, '3': 31, '4': 30, '5': 31, '6': 30, '7': 31, '8': 31, '9': 30, '10': 31, '11': 30, '12': 31 }

  constructor(private excl: ExcelService, private spinner: NgxSpinnerService, public mainService: MainService, private router: Router, private deductionService: DeductionService, private allEmpService: AllEmpService) { }
  erpUser:any ;
  b_acct_id:any ;
  pfObj :any = {}
  tdsObj:any  = {}
  dedObj :any = {}
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  tdsData:any  = []
  pfData :any = []
  displayedColumns :any = ['s_no', 'emp_id', 'emp_name', 'designation_code', 'pf_acct_no', 'pf_amount', 'pfadv_amount', 'pfadv1_amount', 'pfadv2_amount', 'total_amount'];
  datasource:any ;
  displayedColumns1 :any = ['s_no', 'emp_id', 'emp_name', 'designation_code', 'emp_pan_no', 'gross_amount', 'tds_amount'];
  datasource1:any ;
  displayedColumns2 :any = ['s_no', 'emp_id', 'emp_name', 'designation_code', 'pay_component_amt'];
  datasource2:any ;
  allVariablePay = [];
  obj:any  = {};
  Data:any
  totalPf :any = 0;
  totalAdv1:any  = 0;
  arr:any  = []
  totalAdv2:any  = 0;
  totalAdv3:any  = 0;
  totalTds:any  = 0
  totalGross :any = 0
  totalPf1:any ;
  totalded :any = 0
  dednumber :any = 0
  tdsnumber :any = 0
  pfnumber :any = 0
  pf_table_data:any  = [];
  total :any = 0;
  result :any = [];
  result1 :any = [];
  result2 :any = [];
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;
  @ViewChild('paginator2', { static: false }) paginator2!: MatPaginator;
  @ViewChild('sortCol3', { static: false }) sortCol3!: MatSort;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllEmployees();
  }

  async getAllEmployees() {
    this.spinner.show()


    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';

    var resp = await this.allEmpService.getAllPartyFields(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.arr = resp.data;

    } else {
      this.spinner.hide()

    }
  }

 async export() {
    await this.checkValidatino();
    if(this.validaation)
    {
      this.datasource = new MatTableDataSource([])
      this.datasource.paginator = this.paginator;
      return;
    }
    if(this.result.length==0)
    {
      swal.fire('Info','No Data For Export', 'info');
      this.datasource = new MatTableDataSource([])
      this.datasource.paginator = this.paginator;
      return;
    }
    this.excl.exportAsExcelFile(this.result, 'down')
  }

  export1() {
    if(this.result1.length==0)
    {
      swal.fire('Info','No Data For Export', 'info');
      this.datasource1 = new MatTableDataSource(this.result1)
      this.datasource1.paginator = this.paginator1;
      return;
    }
    this.excl.exportAsExcelFile(this.result1, 'down')
  }

  export2() {
    if(this.result2.length==0)
    {
      swal.fire('Info','No Data For Export', 'info');
      this.datasource2 = new MatTableDataSource([])
      this.datasource2.paginator = this.paginator2;
      return;
    }
    this.excl.exportAsExcelFile(this.result2, 'down')
  }
  getNumberFormat(num:any ) {
    return num.toString().padStart(3, "0")
  }
  group_concat(data:any , group_key:any , concatKey:any ) {

    let result:any  = {}

    for (let i = 0; i < data.length; i++) {
      let key:any  = ""
      let temp:any  = {}
      for (let j = 0; j < group_key.length; j++) {
        key += "_" + data[i][group_key[j]]
        temp[group_key[j]] = data[i][group_key[j]]
      }

      if (result[key] == undefined) {
        result[key] = temp
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] = data[i][concatKey[j]]
        }

      } else {
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] += data[i][concatKey[j]]
          // result[key][concatKey[j]] += "+" + data[i][concatKey[j]]
        }
      }


    }
    var ret_data :any = Object.values(result);
    for (let i = 0; i < ret_data.length; i++) {
      ret_data[i]['pay_component_amt'] = ret_data[i]['pay_component_amt'].toFixed(0)
    }
    return ret_data
  }

validaation=true

async checkValidatino()
{
  if(this.pfObj['pf_type']==''||this.pfObj['pf_type']==null|| this.pfObj['pf_type']==undefined||
  this.pfObj['month']==''||this.pfObj['month']==null|| this.pfObj['month']==undefined||
  this.pfObj['fin_year']==''|| this.pfObj['fin_year']==null|| this.pfObj['fin_year']==undefined )
{
swal.fire("Warning",'* Field Required', 'warning');
this.validaation=true
return;
}
else{
  this.validaation=false;
  return ;
}
}

  async getPfDed() {

await this.checkValidatino();
if(this.validaation)
{
  this.datasource = new MatTableDataSource([])
  this.datasource.paginator = this.paginator;
  return;
}
    var obj :any  = new  Object();
    obj = this.pfObj
    obj['b_acct_id'] = this.b_acct_id;
    obj['pay_component_code'] = ['PF', 'PFADV', 'PFADV1', 'PFADV2'];
    this.spinner.show()
    var resp = await this.deductionService.getBill(obj);
    this.totalPf = 0
    this.totalPf1 = 0;
    this.totalAdv1 = 0;
    this.totalAdv2 = 0;
    this.totalAdv3 = 0;
    this.total = 0;
    this.pfData = []
    if (resp['error'] == false) {
      console.log(resp);
      if(resp['data'].length==0)
      {
        swal.fire('info','No Data Found','info');
        this.datasource = new MatTableDataSource([])
        this.datasource.paginator = this.paginator;
        this.spinner.hide();
        return;

      }
      var dt = resp.data;
      //  this.pfnumber = dt.length
      console.log(this.pfObj)
      console.log(this.pfObj['pf_type'])
      var dt1 = this.group_concat(dt, ['emp_id', 'pay_component_code', 'pay_code',], ['pay_component_amt', 'num_of_days']);
      console.table(dt1)
      this.pfnumber = dt1.length

      for (let i = 0; i < dt.length; i++) {
        this.pfData.push(dt[i]);

        //this.totalPf = this.totalPf + dt[i]['pay_component_amt'];

        if (dt[i]['pay_component_code'] == 'PF') {

          this.totalPf = this.totalPf + dt[i]['pay_component_amt'];


        }
        if (dt[i]['pay_component_code'] == 'PFADV') {
          this.totalAdv1 = this.totalAdv1 + dt[i]['pay_component_amt'];
        }
        if (dt[i]['pay_component_code'] == 'PFADV1') {
          this.totalAdv2 = this.totalAdv2 + dt[i]['pay_component_amt'];
        }
        if (dt[i]['pay_component_code'] == 'PFADV2') {
          this.totalAdv3 = this.totalAdv3 + dt[i]['pay_component_amt'];
        }
        this.total = this.total + dt[i]['pay_component_amt'];

      }
      this.totalPf = parseInt(this.totalPf.toFixed(0))
      this.total = parseInt(this.total.toFixed(0))


      var all_emp_id = [];
      console.log(this.pfData)
      for (let i = 0; i < this.pfData.length; i++) {
        all_emp_id.push(this.pfData[i]['emp_id']);
      }


      //   console.log(all_emp_id)
      var emp_id = Array.from(new Set(all_emp_id));

      this.pf_table_data = [];

      for (let i = 0; i < emp_id.length; i++) {

        var total_amount = 0;
        var obj :any  = new  Object();

        obj['emp_id'] = emp_id[i];

        for (let j = 0; j < this.arr.length; j++) {
          if (emp_id[i] == this.arr[j]['emp_id']) {
            console.log("welcome")
            obj['pf_acct_no'] = this.arr[j]['pf_acct_no']
            obj['pf_ifsc_code'] = this.arr[j]['pf_ifsc_code']
          }
        }


        for (let j = 0; j < this.pfData.length; j++) {
          if (emp_id[i] == this.pfData[j]['emp_id']) {
            obj['designation_code'] = this.pfData[j]['designation_code'];
            obj['emp_name'] = this.pfData[j]['emp_name'];
            //   obj['pf_acct_no'] = this.pfData[j]['pf_acct_no'];

            //   obj['pf_ifsc_code'] = this.pfData[j]['pf_ifsc_code'];
            if (obj['pf_amount'] == undefined) {
              obj['pf_amount'] = 0;
            }
            if (obj['pfadv_amount'] == undefined) {
              obj['pfadv_amount'] = 0;
            }

            if (obj['pfadv1_amount'] == undefined) {
              obj['pfadv1_amount'] = 0;

            }
            if (obj['pfadv2_amount'] == undefined) {
              obj['pfadv2_amount'] = 0;
            }



            if (this.pfData[j]['pay_component_code'] == 'PF') {
              //    obj['pf_amount'] = this.pfData[j]['pay_component_amt'];
              total_amount = total_amount + this.pfData[j]['pay_component_amt']
            } else {

            }


            if (this.pfData[j]['pay_component_code'] == 'PFADV') {
              obj['pfadv_amount'] = this.pfData[j]['pay_component_amt'];
              total_amount = total_amount + this.pfData[j]['pay_component_amt']
            } else {

            }

            if (this.pfData[j]['pay_component_code'] == 'PFADV1') {
              obj['pfadv1_amount'] = this.pfData[j]['pay_component_amt'];
              total_amount = total_amount + this.pfData[j]['pay_component_amt']
            } else {

            }

            if (this.pfData[j]['pay_component_code'] == 'PFADV2') {
              obj['pfadv2_amount'] = this.pfData[j]['pay_component_amt'];
              total_amount = total_amount + this.pfData[j]['pay_component_amt']
            }

          }
        }

        total_amount = parseInt(total_amount.toFixed(0))
        obj['total_amount'] = total_amount;
        this.pf_table_data.push(obj);
      }
      this.result = this.pf_table_data;

      for (let i = 0; i < this.result.length; i++) {
        this.result[i]['serial_no'] = i + 1
        this.result[i]['emp_id'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(this.result[i]['emp_id'])
      }
      this.datasource = new MatTableDataSource(this.result)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort
      this.spinner.hide()
    } else {
      this.spinner.hide()

      swal.fire("Error", "Error while Getting PF Deductions");
    }
  }


  async getTdsDed() {

if(this.tdsObj['month']==''|| this.tdsObj['month']==null || this.tdsObj['month']==undefined  ||
   this.tdsObj['fin_year']==''|| this.tdsObj['fin_year']==null || this.tdsObj['fin_year']==undefined  )
{
  this.datasource1 = new MatTableDataSource([])
  this.datasource1.paginator = this.paginator1;
  swal.fire('Warning','* Field Required','warning');

  return;
}
    var obj :any  = new  Object();
    obj = this.tdsObj
    obj['b_acct_id'] = this.b_acct_id;


    this.spinner.show()
    var resp = await this.deductionService.getTdsReport(obj);
    if (resp['error'] == false) {
      console.log(resp);
      if(resp['data'].length==0)
      {
        swal.fire('Info', 'No Data Found', 'info');
        this.datasource1 = new MatTableDataSource([])
        this.datasource1.paginator = this.paginator1;
        this.spinner.hide();

        return;

      }
      this.tdsData = resp.data;
      this.tdsnumber = this.tdsData.length
      this.totalTds = 0
      this.totalGross = 0
      for (let i = 0; i < this.tdsData.length; i++) {
        this.totalTds = this.totalTds + this.tdsData[i]['tds_amount']
        this.totalGross = this.totalGross + this.tdsData[i]['gross_amount']

      }
      this.totalGross = parseInt(this.totalGross.toFixed(0))
      this.totalTds = parseInt(this.totalTds.toFixed(0))
      this.result1 = [];
      for (let j = 0; j < resp.data.length; j++) {
        var obj :any  = new  Object();
        for (let i = 0; i < this.displayedColumns1.length; i++) {
          if (this.displayedColumns1[i] == 'gross_amount' || this.displayedColumns1[i] == 'tds_amount') {
            obj[this.displayedColumns1[i]] = resp.data[j][this.displayedColumns1[i]].toFixed(0)
          }
          else {
            obj[this.displayedColumns1[i]] = resp.data[j][this.displayedColumns1[i]]
          }

        }
        obj['s_no'] = j + 1;
        this.result1.push(obj);
      }
      for (let i = 0; i < this.result1.length; i++) {
        this.result1[i]['serial_no'] = i + 1
        this.result1[i]['emp_id'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(this.result1[i]['emp_id'])
        for (let j = 0; j < this.arr.length; j++) {
          if (this.result1[i]['emp_id'] == this.arr[j]['emp_id']) {
            this.result1[i]['emp_pan_no'] = this.arr[j]['emp_pan_no']
            console.log(this.result1[i]['emp_pan_no'])

          }
        }
      }
      this.datasource1 = new MatTableDataSource(this.result1)
      this.datasource1.paginator = this.paginator1;
      this.datasource1.sort = this.sortCol2
      this.spinner.hide()

    } else {
      this.spinner.hide()

      swal.fire("Error", "Error while Getting PF Deductions");
    }
  }


  async getDed() {
    if(this.dedObj['fin_year']==''||this.dedObj['fin_year']==null || this.dedObj['fin_year']==undefined||
       this.dedObj['month']==''||this.dedObj['month']==null || this.dedObj['month']==undefined||
       this.dedObj['pay_component_code']==''||this.dedObj['pay_component_code']==null || this.dedObj['pay_component_code']==undefined )
       {

        swal.fire('Warning','* Field Required','warning');
        this.datasource2 = new MatTableDataSource([])
        this.datasource2.paginator = this.paginator2;
        return;
       }
    var obj :any  = new  Object();
    obj = Object.assign({}, this.dedObj)
    obj['b_acct_id'] = this.b_acct_id;
    var obj1 = []
    obj1.push(this.dedObj['pay_component_code']);
    obj['pay_component_code'] = obj1
    var resp = await this.deductionService.getBill(obj);
    this.totalded = 0;
    if (resp['error'] == false) {
      if(resp['data'].length==0)
      {
        swal.fire('Info','No Data Found','info');
        this.datasource2 = new MatTableDataSource([])
        this.datasource2.paginator = this.paginator2;
        return
      }
      this.Data = resp.data;
      var data = this.group_concat(this.Data, ['arr_id', 'pay_component_code', 'pay_code', 'emp_id', 'emp_name'], ['pay_component_amt', 'num_of_days']);
      console.table(data)
      this.Data = data
      this.dednumber = data.length
      for (let i = 0; i < resp.data.length; i++) {
        this.totalded = this.totalded + resp.data[i]['pay_component_amt']
      }
      this.totalded = parseInt(this.totalded.toFixed(0))
      this.result2 = [];
      for (let j = 0; j < this.Data.length; j++) {
        var obj :any  = new  Object();
        for (let i = 0; i < this.displayedColumns2.length; i++) {

          obj[this.displayedColumns2[i]] = resp.data[j][this.displayedColumns2[i]]
        }
        obj['s_no'] = j + 1;
        this.result2.push(obj);
      }

      var all_empid = []


      for (let i = 0; i < data.length; i++) {
        all_empid.push(data[i]['emp_id']);
      }



      //  console.log(all_emp_id)
      var emp_id = Array.from(new Set(all_empid));
      this.pf_table_data = [];
      for (let i = 0; i < emp_id.length; i++) {

        var total_amount = 0;
        var obj :any  = new  Object();
        for (let j = 0; j < this.Data.length; j++) {
          if (emp_id[i] == this.Data[j]['emp_id']) {

            obj['emp_name'] = this.Data[j]['emp_name'];

            obj['emp_id'] = this.Data[j]['emp_id'];
            obj['pay_component_amt'] = this.Data[j]['pay_component_amt']
          }

          for (let j = 0; j < resp.data.length; j++) {
            if (emp_id[i] == resp.data[j]['emp_id']) {
              obj['designation_code'] = resp.data[j]['designation_code'];
            }

          }





        }
        this.pf_table_data.push(obj);
      }


      this.result2 = this.pf_table_data;

      for (let i = 0; i < this.result2.length; i++) {
        this.result2[i]['serial_no'] = i + 1
        // this.result2[i]['emp_id']=/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(this.result[i]['emp_id'])
      }



      // for(let i=0;i<this.result2.length;i++){
      //   this.result2[i]['serial_no'] = i+1
      //   this.result2[i]['emp_id']=/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(this.result2[i]['emp_id'])
      // }
      this.datasource2 = new MatTableDataSource(this.result2)
      this.datasource2.paginator = this.paginator2;
      this.datasource2.sort = this.sortCol3

    } else {
      swal.fire("Error", "Error while Getting PF Deductions");
    }
  }


  async print() {
    await this.checkValidatino();
    if(this.validaation)
    {
      this.datasource = new MatTableDataSource([])
      this.datasource.paginator = this.paginator;
      return;
    }

    if(this.result.length==0)
    {
      swal.fire('Info','No Data For Print', 'info');
      return;
    }

// print the pdf
// var txt = this.mainService.accInfo["account_name"] + "(" + this.mainService.accInfo["account_short_name"] + ")" +
// " RECEIPT AND PAYMENT REPORT FROM " + this.mainObj['from_dt'] + " TO " + this.mainObj['to_dt'] + " ";

// end print pdf
    let printContents:any , popupWin:any ;
    printContents = document?.getElementById('p')?.innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin?.document.open();
    popupWin?.document.write(`
    <html>
      <head>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
      </head>
      <style>
      #tbl {
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        max-width: 2480px;
        page-break-after:auto
    }

    #tbl td,
    #tbl th {
        border: 1px solid #ddd;
        padding: 8px;
        width: auto;
        word-wrap: break-word;
        page-break-inside:avoid; page-break-after:auto
    }



    #tbl th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #d9edf7;
      color: black;
  }
      </style>
  <body>${printContents}</body>
    </html>`
    );
    //
    // popupWin.document.close();
    popupWin.document.close();
    popupWin.print();
    setTimeout(() => {

     popupWin.close();
   }, 3000);

  }
  printtds() {

     if(this.result1.length==0)
    {
      swal.fire('Info','No Data For Print', 'info');
      this.datasource1 = new MatTableDataSource(this.result1)
      this.datasource1.paginator = this.paginator1;
      return;
    }
    let printContents, popupWin:any ;
    printContents = document.getElementById('a')?.innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin?.document.open();
    popupWin?.document.write(`
    <html>
      <head>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
      </head>
      <style>
      #tbl {
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        max-width: 2480px;
        page-break-after:auto
    }

    #tbl td,
    #tbl th {
        border: 1px solid #ddd;
        padding: 8px;
        width: auto;
        word-wrap: break-word;
        page-break-inside:avoid; page-break-after:auto
    }



    #tbl th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #d9edf7;
      color: black;
  }
      </style>

  <body >${printContents}</body>

    </html>`
    );

    //  <body onload="window.print();window.close()">${printContents}</body> old
    popupWin.document.close();
    popupWin.print();
    setTimeout(() => {

     popupWin.close();
   }, 3000);


  }
  printded() {
    if(this.result2.length==0)
    {

      swal.fire('Info','No Data For Print', 'info');
      this.datasource2 = new MatTableDataSource([])
      this.datasource2.paginator = this.paginator2;
      return;
    }
 // ai start
 let printContents, popupWin:any ;
 printContents = document.getElementById('c')?.innerHTML;
 popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
 popupWin?.document.open();
 popupWin?.document.write(`
   <html>
     <head>
       <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
     </head>
     <style>
       #tbl {
         font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
         border-collapse: collapse;
         width: 100%;
         max-width: 2480px;
         page-break-after: auto;
       }

       #tbl td,
       #tbl th {
         border: 1px solid #ddd;
         padding: 8px;
         width: auto;
         word-wrap: break-word;
         page-break-inside: avoid;
         page-break-after: auto;
       }

       #tbl th {
         padding-top: 12px;
         padding-bottom: 12px;
         text-align: left;
         background-color: #d9edf7;
         color: black;
       }
     </style>
     <body>
       <p>${printContents}</p>
     </body>
   </html>`
 );
 popupWin.document.close();
 popupWin.print();
 setTimeout(() => {

  popupWin.close();
}, 3000);



    // ai end
  //   let printContents, popupWin;
  //   printContents = document.getElementById('c').innerHTML;
  //   popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  //   popupWin.document.open();
  //   popupWin.document.write(`
  //   <html>
  //     <head>
  //     <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
  //     </head>
  //     <style>
  //     #tbl {
  //       font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  //       border-collapse: collapse;
  //       width: 100%;
  //       max-width: 2480px;
  //       page-break-after:auto
  //   }

  //   #tbl td,
  //   #tbl th {
  //       border: 1px solid #ddd;
  //       padding: 8px;
  //       width: auto;
  //       word-wrap: break-word;
  //       page-break-inside:avoid; page-break-after:auto
  //   }



  //   #tbl th {
  //     padding-top: 12px;
  //     padding-bottom: 12px;
  //     text-align: left;
  //     background-color: #d9edf7;
  //     color: black;
  // }
  //     </style>
  //     <body onload='window.close()'>
  //     <p> ${printContents}  </p>
  //     <p> onload='window.print()'  </p></body>
  //   </html>`
  //   );
  //   popupWin.document.close();
  console.log(" this is the data for the print ", printContents)

  }
  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {

    this.datasource2.filter = filterValue.trim().toLowerCase();
  }
  applyFilter1(filterValue: string) {

    this.datasource1.filter = filterValue.trim().toLowerCase();
  }


}
