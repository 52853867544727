import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { PayrollService } from '../../service/payroll.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as  pdfFonts from "pdfmake/build/vfs_fonts"
import { timeStamp } from 'console';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-var-annual-statement',
  templateUrl: './var-annual-statement.component.html',
  styleUrls: ['./var-annual-statement.component.css']
})
export class VarAnnualStatementComponent implements OnInit {

  erpUser: any;
  b_acct_id: any;
  sortarr1: any = [];
  arr1: any = [];

  constructor(private payroll: PayrollService, private spinner: NgxSpinnerService, public mainService: MainService, private payableService: PayrollService) { }
  monthArr = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3]
  monthObj :any = { 1: 'January', 2: 'Febuary', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December' }
  monthEnd = { '1': 31, '2': 28, '3': 31, '4': 30, '5': 31, '6': 30, '7': 31, '8': 31, '9': 30, '10': 31, '11': 30, '12': 31 }

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;

    this.getAllCurrentArrangements()
  }
  allEmplyees_new: any = [];
  selectObj: any = {};
  licObj: any = {};
  personalInfoObj1: any = { emp_phone_no: '0000000000' };

  itreportdata: any = []
  itreportobj: any = {}
  allArr: any = [];
  arrC: any = [];
  //totalobj ={'BASIC':0.00,'CCA':0.00,'CONV':0.00,'CPF/GPF Ded':0.00,'Comp Alw':0.00,'Comp. Loan':0.00,'Cons. Loan':0.00,'DA':0.00,'Special Allowance':0.00,'BusAllow':0.00,'DEP-ALLOW':0.00,'Fest Adv':0.00,'GIS':0.00,'H RENT':0.00,'HB Adv':0.00,'HDFC':0.00,'HRA':0.00,'HVDT Allow':0.00,'I. Tax':0.00,'MED':0.00,'Misc Ded.':0.00,'NPS':0.00,'Other Adv':0.00,'Other Ded':0.00,'PF Advance':0.00,'Per Pay':0.00,'Res Alw':0.00,'Veh Loan':0.00,'WA':0.00,'totalpay':0.00,'totalded':0.00,'net_pay':0.00,'PF':0.00}
  totalobj :any = {}
  async getPersonalInfo() {
    this.personalInfoObj1 = { emp_phone_no: '0000000000' };
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectObj['emp_id'];
    // console.log(this.mainService.emp_id);
    //this.spinner.show();
    var resp = await this.payroll.getPersonalInfo(obj);
    console.log(resp);
    if (resp['error'] == false) {
      if (resp.data.length > 0) {
        this.personalInfoObj1 = resp.data[0];
      }
      // this.personalInfoObj1['emp_name'] = /* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(this.personalInfoObj1['emp_id'])+" - "+ this.personalInfoObj1['emp_name'];
      this.personalInfoObj1['emp_name'] = /* this.mainService.accInfo['account_short_name']+ */this.personalInfoObj1['emp_id'] + " - " + this.personalInfoObj1['emp_name'];
      console.log(this.personalInfoObj1)
    }
  }
  // getallcurrentarrangement is used to store all employee name (select employee data)
  async getAllCurrentArrangements() {
    //this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.payroll.getAllCurrentArrangements(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      //  this.spinner.hide()
      this.allArr = resp['data'];
      this.allEmplyees_new = [];
      for (let i = 0; i < this.allArr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, this.allArr[i]);
        obj['tempemp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + " - " + obj['emp_name'];
        this.allEmplyees_new.push(obj)
        this.empidtodesignation[obj['emp_id']] = obj['designation_code'];
      } console.log(this.empidtodesignation)

    } else {
      // this.spinner.hide()

    }
  }


  empidtodesignation :any = {}

  getNumberFormat(num:any ) {
    return num.toString().padStart(3, "0")
  }
  reset_flag() {
    this.selectObj['print_flag'] = false

  }

  group_concat(data:any , group_key:any , concatKey:any ) {

    let result :any = {}

    for (let i = 0; i < data.length; i++) {
      let key :any = ""
      let temp :any = {}
      for (let j = 0; j < group_key.length; j++) {
        key += "_" + data[i][group_key[j]]
        temp[group_key[j]] = data[i][group_key[j]]
      }

      if (result[key] == undefined) {
        result[key] = temp
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] = data[i][concatKey[j]]
        }

      } else {
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] += data[i][concatKey[j]]
          // result[key][concatKey[j]] += "+" + data[i][concatKey[j]]
        }
      }


    }
    var ret_data :any = Object.values(result);
    for (let i = 0; i < ret_data.length; i++) {
      ret_data[i]['pay_component_amt'] = Number(ret_data[i]['pay_component_amt'].toFixed(0))
    }
    return ret_data
  }

  changeName() {
    this.selectObj['print_flag'] = false
    console.log(this.allEmplyees_new)

    if (this.selectObj['emp_id']) {
      for (let i = 0; i < this.allEmplyees_new.length; i++) {
        if (this.selectObj['emp_id'] == this.allEmplyees_new[i]['emp_id']) {
          this.selectObj['emp_name'] = this.allEmplyees_new[i]['emp_name']
        }
      }
    } else {
      this.selectObj['emp_name'] = ''
    }
  }
  itreportdata1:any
  all_component:any
  pay_comp :any = []
  obj2:any  = []
  async itreport() {
    if (this.selectObj['emp_id'] == '' || this.selectObj['emp_id'] == null || this.selectObj['emp_id'] == undefined ||
      this.selectObj['fin_year'] == '' || this.selectObj['fin_year'] == null || this.selectObj['fin_year'] == undefined ||
      this.selectObj['end_fin_year'] == '' || this.selectObj['end_fin_year'] == null || this.selectObj['end_fin_year'] == undefined ||
      this.selectObj['month'] == '' || this.selectObj['month'] == null || this.selectObj['month'] == undefined ||
      this.selectObj['month_1'] == '' || this.selectObj['month_1'] == null || this.selectObj['month_1'] == undefined) {
      Swal.fire('Warning', '* Field Required', 'warning');
      return;

    }

    var obb:any  = new Object()
    obb['b_acct_id'] = this.b_acct_id
    obb['emp_id'] = this.selectObj['emp_id'];//this.mainService.emp_id;
    obb['fin_year'] = Number(this.selectObj['fin_year']);
    obb['end_fin_year'] = Number(this.selectObj['end_fin_year'])
    obb['month'] = Number(this.selectObj['month'])
    obb['month_1'] = Number(this.selectObj['month_1'])
    console.log(obb);
    var resp = await this.payroll.getEmpReport(JSON.stringify(obb))
    console.log(resp)

    if (resp['error'] == false) {
      this.spinner.hide()
      this.selectObj['print_flag'] = true
      this.itreportdata = resp.data
      this.itreportdata1 = resp.data
      this.itreportdata = this.group_concat(this.itreportdata, ['emp_id', 'pay_component_code', 'pay_code', 'month', 'fin_year'], ['pay_component_amt', 'num_of_days'])
      console.log(this.itreportdata)
      var all_pay_component_code = []
      for (let i = 0; i < this.itreportdata.length; i++) {
        all_pay_component_code.push(this.itreportdata[i]['pay_component_code']);
      }
      this.pay_comp = Array.from(new Set(all_pay_component_code));
      this.itreportobj = {};
      await this.getPersonalInfo();
      var obj: any = new Object();
      obj['b_acct_id'] = this.b_acct_id;
      var resp1 = await this.payableService.getAllComponent(JSON.stringify(obj));
      if (resp1['error'] == false) {
        this.all_component = resp1.data;
        console.log(this.all_component)

        for (let i = 0; i < this.all_component.length; i++) {
          //  this.obj2[i]['component_code']=this.all_component[i]['component_code']
          //  this.obj2[i]['component_code']=0.00;
          // this.obj2.push(this.all_component[i]['component_code'])
          this.obj2[this.all_component[i]['component_code']] = 0.00
          if (this.obj2['Handicapped Allowance'] == 'Handicapped Allowance') {
            this.obj2['Handicapped Allowance'] = 'Special Allowance'
          }
          if (this.obj2['BusAll'] == 'BusAll') {
            this.obj2['BusAll'] = 'BusAllow'
          }
        }
      } else {
        return;
      }
      console.log(this.obj2)

      for (let i = 0; i < this.all_component.length; i++) {
        this.arrC.push(this.all_component[i]['component_code'])
      }
      console.log(this.arrC);
      for (let i = 0; i < this.itreportdata.length; i++) {
        if (this.itreportdata[i]['month'] == 1 || this.itreportdata[i]['month'] == 2 || this.itreportdata[i]['month'] == 3) {
          this.itreportdata[i]['fin_year'] = Number(this.itreportdata[i]['fin_year']) + 1;
        }
      }
      let mObj :any = {}
      for (let i = 0; i < this.itreportdata.length; i++) {

        if (this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']] == undefined) {
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']] = {}
        }
        if (this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'] == undefined) {
          let bobj:any  = {}
          for (let i = 0; i < this.all_component.length; i++) {
            bobj[this.all_component[i]['component_code']] = 0.00
          }
          //         this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'] =  {'BASIC':0.00,'CCA':0.00,'CONV':0.00,'CPF/GPF Ded':0.00,'Comp Alw':0.00,'Comp. Loan':0.00,'Cons. Loan':0.00,'DA':0.00,'Special Allowance':0.00,'BusAllow':0.00,'carallow':0.00,'DEP-ALLOW':0.00,'subAllow':0.00,'Fest Adv':0.00,'GIS':0.00,'H RENT':0.00,'HB Adv':0.00,'HDFC':0.00,'HRA':0.00,'HVDT Allow':0.00,'I. Tax':0.00,'MED':0.00,'Misc Ded.':0.00,'NPS':0.00,'Other Adv':0.00,'Other Ded':0.00,'PF Advance':0.00,'Per Pay':0.00,'Res Alw':0.00,'Veh Loan':0.00,'WA':0.00,'totalpay':0.00,'totalded':0.00,'net_pay':0.00,'PF':0.00}
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'] = bobj
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalpay'] = 0.00
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['net_pay'] = 0.00
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['fin_year'] = this.itreportdata[i]['fin_year']
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['month'] = this.itreportdata[i]['month']
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalded'] = 0.00
          mObj[i] = { 'month': Number(this.itreportdata[i]['month']), 'fin_year': Number(this.itreportdata[i]['fin_year']) }

        }
        if (this.itreportdata[i]['pay_code'] == 'PAY') {
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalpay'] += this.itreportdata[i]['pay_component_amt']
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['net_pay'] += this.itreportdata[i]['pay_component_amt']
          // s this.totalobj[this.itreportdata[i]['pay_component_code']] += this.itreportdata[i]['pay_component_amt']
          this.obj2[this.itreportdata[i]['pay_component_code']] += this.itreportdata[i]['pay_component_amt']

        } else {
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalded'] += this.itreportdata[i]['pay_component_amt']
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['net_pay'] -= this.itreportdata[i]['pay_component_amt']
          //sthis.totalobj[this.itreportdata[i]['pay_component_code']] += this.itreportdata[i]['pay_component_amt']
          this.obj2[this.itreportdata[i]['pay_component_code']] += this.itreportdata[i]['pay_component_amt']
        }
        if (this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']] != undefined) {
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']] += this.itreportdata[i]['pay_component_amt']
        } else {
          if (this.itreportdata[i]['pay_code'] == 'PAY') {
            this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total']['miscpay'].push(this.itreportdata[i]['pay_component_amt'])
            //s this.totalobj['miscpay'] += Number(this.itreportdata[i]['pay_component_amt'])
            this.obj2['miscpay'] += Number(this.itreportdata[i]['pay_component_amt'])
          }
          else {
            //sthis.totalobj['miscded'] += Number(this.itreportdata[i]['pay_component_amt'])
            this.obj2['miscded'] += Number(this.itreportdata[i]['pay_component_amt'])
            this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total']['miscded'].push(this.itreportdata[i]['pay_component_amt'])
          }
        }
        this.itreportobj = this.itreportobj
      }
      //var arr1 = [];
      console.log(mObj)
      this.arr1 = Object.values(mObj);
      console.log(this.itreportobj, this.arr1)
      this.sortarr1 = this.arr1.sort(
        function (a:any , b:any ) {
          if (a.fin_year == b.fin_year) {
            return (a.month - b.month);
          } else {
            return (a.fin_year - b.fin_year);
          }
        });
      console.log(this.sortarr1)
    }
    else {
      this.spinner.hide()
      Swal.fire('Error..', 'Error while getting ITR', 'error')
    }
    // this.print1()
    console.log(this.itreportobj, this.itreportdata)
  }

  print1() {
    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")";
    var dd :any = {
      pageSize: 'A2',
      header: function (currentPage:any , pageCount:any ) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40], fontSize: 10, bold: true };
        return obj;
      },

      //footer: function(currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      //pageMargins: [ 10, 60, 10, 60 ],
      pageMargins: [30, 60, 30, 60],

      //pageMargins: [ 40, 20, 20, 20 ],
      content: [

      ]
    };

    var head = {
      columns: [

        {},
        {
          width: '*',
          align: 'right',
          text: ' Salary of Employee ' + this.mainService.codeValueShowObj['HR0023'][this.selectObj['fin_year']],
          bold: true
        }

        , {}

      ],

    }

    dd.content.push(head);


    var sign1 = {
      columns: [
        {
          width: '*',
          text: 'Employee Name :',
          bold: true
        },

        {
          width: '*',
          align: 'left',
          text: this.personalInfoObj1['emp_name'],
          bold: true
        },
        {
          width: '*',
          text: 'PAN NUMBER :',
          bold: true
        },
        {
          width: '*',
          align: 'left',
          text: this.personalInfoObj1['emp_pan_no'],
          bold: true
        }, {
          width: '*',
          text: 'Designation :',
          bold: true
        },
        {
          width: '*',
          align: 'left',
          text: this.empidtodesignation[this.personalInfoObj1['emp_id']],
          bold: true
        }
        //   {
        //     width: '*',
        //     align:'left',
        //     text:this.personalInfoObj1['emp_id'],
        //     bold: true
        // }
      ],

    }
    dd.content.push(sign1);
    //

    var count = 0;
    let list = ['Emp\nDetail'];
    let data_keys = [];
    let width = []
    //let width = ['auto'];
    //   width.push('200')
    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'PAY') {
        list.push(this.all_component[i]['component_code'])
        console.log(list)
        data_keys.push(this.all_component[i]['component_code'])
        width.push('auto')
      }
    }
    list.push('total\npay');
    width.push('auto');
    //    data_keys.push('gross_pay');

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'DED') {
        list.push(this.all_component[i]['component_code']);
        data_keys.push(this.all_component[i]['component_code'])

        width.push('auto')
      }
    }
    list.push('Total\nDed');
    //  data_keys.push('total');
    width.push('auto');
    list.push('Net.\nSal.');
    width.push('auto');
    // data_keys.push('net');

    console.log(width)


    //
    var tbl:any  = {

      layout: 'lightHorizontalLines',
      fontSize: 8,
      table: {

        headerRows: 1,
        //   widths: [200,'auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto'],
        widths: [],
        body: [
          // [ 'Month', 'Basic\nPay','HRA' ,'CCA','DEP\n Allow','MED','Per\npay','CONV','WA','HVDVT\nAllow','Comp\nAlw','Res\nAlw','DA','Total','LIC','PF','NPS','GIS','CPF\nGPF\nDed','Veh\nLoan','HB\nAdv','Fest\nAdv','I.\nTax','Other\nDed','HDFC','H\nRent','Misc\nDed','Cons.\nLoan','Other\nAdv','Total\nDed','Net.\nSal.' ]

          []

        ]
      }
    };


    for (let i = 0; i < list.length; i++) {
      tbl.table.body[0].push(list[i]);
      console.log(list[i])
      tbl.table.widths.push('auto')
    }
    dd.content.push(tbl);
    //  console.log(tbl1)
    var itarr = this.arr1;
    // console.log(itarr)
    console.log(dd.content, itarr)
    var totalamtpay = 0
    var totalamtded = 0
    var totalamtnet = 0
    //var itarr =[]

    //Object.keys(this.itreportobj)
    //  var fin = this.selectObj['fin_year']

    //  var fin = this.selectObj['end_fin_year']
    //  var month=this.selectObj['month']
    //  var month_1=this.selectObj['month_1']
    //  var sfin=this.selectObj['fin_year']

    console.log(this.selectObj)
    var fin = this.selectObj['fin_year']
    //   console.log(fin);

    for (let i = 0; i < itarr.length; i++) {
      var arr = []
      arr.push(this.monthObj[itarr[i]['month']] + '-' + this.itreportobj[itarr[i]['month'] + '-' + itarr[i]['fin_year']]['fin_year'])

      for (let j = 1; j < list.length; j++) {

        console.log(list[j])
        // if(list[j]=='Handicapped Allowance')
        // {
        //   list[j]='Special Allowance'
        // }
        // if(list[j]=='BusAll')
        // {
        //   list[j]='BusAllow'
        // }
        // if(list[j]=='Carallow')
        // {
        //   list[j]='carallow'
        // }
        //console.log(list[j])
        // if(list[j]='subAllow')
        // {

        // }
        //  arr.push(this.itreportobj[itarr[i]+'-'+fin][list[i]])
        // console.log(arr)
        //   console.log(this.itreportobj[list[i]])
        //s console.log(this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]])
        // this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]]=this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]]
        if (list[j] == 'total\npay') {
          arr.push(this.itreportobj[itarr[i]['month'] + '-' + itarr[i]['fin_year']]['totalpay'])
          totalamtpay += this.itreportobj[itarr[i]['month'] + '-' + itarr[i]['fin_year']]['totalpay']
        }
        else if (list[j] == 'Total\nDed') {
          arr.push(this.itreportobj[itarr[i]['month'] + '-' + itarr[i]['fin_year']]['totalded'])
          totalamtded += this.itreportobj[itarr[i]['month'] + '-' + itarr[i]['fin_year']]['totalded']
        }
        else if (list[j] == 'Net.\nSal.') {
          arr.push(this.itreportobj[itarr[i]['month'] + '-' + itarr[i]['fin_year']]['net_pay'])
          totalamtnet += this.itreportobj[itarr[i]['month'] + '-' + itarr[i]['fin_year']]['net_pay']
        }
        else {
          arr.push(this.itreportobj[itarr[i]['month'] + '-' + itarr[i]['fin_year']]['total'][list[j]])
        }

      }
      dd.content[dd.content.length - 1].table.body.push(arr);
    }

    //  s for (let i = 0; i < itarr.length; i++) {
    //     var arr = []
    //     //self added
    //     arr.push(this.monthObj[itarr[i]['month']]+'-'+this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['fin_year'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['BASIC'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['HRA']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['CCA'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['DEP-ALLOW'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['MED']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Per Pay']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['CONV'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['WA']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['HVDT Allow']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Comp Alw'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Res Alw']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['DA'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Special Allowance'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['BusAllow'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Caralllow'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['totalpay']);
    //     totalamtpay += Number(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['totalpay']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['PF'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['NPS']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['GIS']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['CPF/GPF Ded'])
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Veh Loan']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['HB Adv']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Fest Adv']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['I. Tax']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Other Ded']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['HDFC']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['H RENT']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Misc Ded.']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Comp. Loan']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Cons. Loan']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['Other Adv']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['totalded']);
    //     totalamtded +=Number(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['totalded']);
    //    // arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['totalded']);
    //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['net_pay']);
    //     totalamtnet += Number(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['net_pay'])
    //       //arr.push(this.totalobj['WA']);
    //       //  arr.push(this.itreportobj[itarr[i] + '-' + fin]['total']['totalded']);
    //       //  arr.push(this.itreportobj[itarr[i] + '-' + fin]['total']['net_pay']);
    //       //  arr.push(this.itreportobj[itarr[i] + '-' + fin]['total']['totalpay']);

    // //   for(let j=1;j<list.length;j++)
    // //   {
    // //     console.log(list[j])
    // //     console.log(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total'][list[j]])
    // //    //this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]]=this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]]
    // //   if(list[j]=='total\npay')
    // //   {
    // //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['totalpay'])
    // //     totalamtpay+=Number(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['totalpay'])
    // //   }
    // //   else if(list[j]=='Total\nDed')
    // //   {
    // //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['totalded'])
    // //     totalamtded+=Number(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['totalded'])
    // //   }
    // //   else if(list[j]=='Net.\nSal.')
    // //   {
    // //     arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['net_pay'])
    // //     totalamtnet+=Number(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total']['net_pay'])
    // //   }
    // //   else{
    // //    arr.push(this.itreportobj[itarr[i]['month']+'-'+itarr[i]['fin_year']]['total'][list[j]])

    // //   }

    // //  }
    //      dd.content[dd.content.length-1].table.body.push(arr);
    //  s   }

    var arr = []

    arr.push('Final\nTotal')

    for (let j = 1; j < list.length; j++) {
      if (list[j] == 'total\npay') {
        arr.push(totalamtpay)
      }
      else if (list[j] == 'Total\nDed') {
        arr.push(totalamtded)
      }
      else if (list[j] == 'Net.\nSal.') {
        arr.push(totalamtnet)
      }

      else {
        // sarr.push(this.totalobj[list[j]])
        arr.push(this.obj2[list[j]])
      }

    }
    console.log(arr)
    dd.content[dd.content.length - 1].table.body.push(arr);
    console.log(dd)

    this.spinner.hide()
    pdfMake.createPdf(dd).download(this.personalInfoObj1['emp_name'] + '-' + this.selectObj['fin_year']);
  }

}
