<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{auth.allLableShowObjProp[auth.language_cd+'Registry1']}}</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" (click)="refressadd()" data-toggle="tab"><i
                                    class="ti-bar-chart"></i> {{auth.allLableShowObjProp[auth.language_cd+'Registry2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refressadd()"><i
                                    class="ti-settings"></i> {{auth.allLableShowObjProp[auth.language_cd+'Registry3']}}</a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>


                            <div class="row ">
                                <div class="col-2 text-right">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'Registry4']}} :</h6>
                                </div>
                                <div class="col-3" style="margin-left: 1%;">
                                   <!--  <select class="form-control" name="generateSchemeCode" [(ngModel)]="selectedSchemeCode" (change)="getAllSubschemes()" placeholder="Scheme">
                                        <option *ngFor="let schemeArr of schemeArr" ngValue="{{schemeArr.scheme_code}}">{{schemeArr.scheme_name}}

                                        </option>
                                    </select> -->
                                    <ng-select [items]="schemeArr" bindLabel="scheme_name" (change)="getAllSubschemes()" bindValue="scheme_code" [multiple]="false" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Registry4']}}" [(ngModel)]="selectedSchemeCode" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="field_name">
                                </ng-select>
                                </div>
                                <div class="col-2 text-right">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'Registry5']}}:</h6>
                                </div>
                                <div class="col-3" style="margin-left: 1%;">
                                    <!-- <select class="form-control" name="subselectedSchemeCode" [(ngModel)]="subselectedSchemeCode" placeholder="Scheme">
                                        <option *ngFor="let subschemeArr of subschemeArr" ngValue="{{subschemeArr.sub_scheme_code}}">{{subschemeArr.sub_scheme_name}}

                                        </option>
                                    </select> -->
                                    <ng-select [items]="subschemeArr" bindLabel="sub_scheme_name" bindValue="sub_scheme_code" [multiple]="false" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Registry5']}}" [(ngModel)]="subselectedSchemeCode" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="field_name">
                                </ng-select>
                                </div>
                                <div class="col-1">
                                    <button class="btn btn-primary" (click)="getRegisteredInfo()">{{auth.allLableShowObjProp[auth.language_cd+'Registry6']}}</button>
                                </div>
                            </div>



                            <div class="example-container">
                                <div class=" table-container ">
                                    <div class="row">
                                        <div class="col-4">
                                            <mat-form-field style="margin-left: 2%">
                                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'ENGRefund7']}}">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-8 text-right">
                                            <button class="btn btn-success" (click)="export1()">{{auth.allLableShowObjProp[auth.language_cd+'Registry7']}}</button>
                                            <button class="btn btn-primary" (click)="print1()">{{auth.allLableShowObjProp[auth.language_cd+'Registry8']}}</button>

                                        </div>

                                    </div>

                                    <div class="example-container">
                                        <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                                            <ng-container matColumnDef="party_id">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>{{auth.allLableShowObjProp[auth.language_cd+'Registry9']}}</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.party_id}} </td>
                                            </ng-container>


                                            <ng-container matColumnDef="party_name">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>{{auth.allLableShowObjProp[auth.language_cd+'Registry10']}}</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element['party_name']}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="party_phone_no">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <b> {{auth.allLableShowObjProp[auth.language_cd+'Registry11']}}</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element['party_phone_no']}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="property_type_code">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Registry12']}}</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.property_type_code }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="property_no">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Registry13']}}</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.property_no }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="arr_effective_date">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Registry14']}}</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ mainService.dateFormatChange(element.arr_effective_date)}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                    {{auth.allLableShowObjProp[auth.language_cd+'Registry15']}}</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                                <button (click)="print(element)" class="btn btn-primary">{{auth.allLableShowObjProp[auth.language_cd+'Registry16']}}</button>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                                        </mat-paginator>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> {{auth.allLableShowObjProp[auth.language_cd+'Registry9']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="party_id" [(ngModel)]="party_id" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Registry9']}}">
                                </div>
                                <div class="col-4 text-left">
                                    <button class="btn btn-primary" (click)="fetch()">{{auth.allLableShowObjProp[auth.language_cd+'Registry6']}}</button>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> {{auth.allLableShowObjProp[auth.language_cd+'Registry10']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="scheme_code" [(ngModel)]="obj['party_name']" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Registry10']}}" disabled>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>  {{auth.allLableShowObjProp[auth.language_cd+'Registry17']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="scheme_code" [(ngModel)]="obj['scheme_code']" placeholder=" {{auth.allLableShowObjProp[auth.language_cd+'Registry17']}}" disabled>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>  {{auth.allLableShowObjProp[auth.language_cd+'Registry18']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="sub_scheme_code" [(ngModel)]="obj['sub_scheme_code']" placeholder=" {{auth.allLableShowObjProp[auth.language_cd+'Registry18']}}" disabled>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> {{auth.allLableShowObjProp[auth.language_cd+'Registry12']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="property_code" [(ngModel)]="obj['property_type_code']" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Registry12']}}" disabled>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'Registry13']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" name="property_no" [(ngModel)]="obj['property_no']" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Registry13']}}" disabled>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'Registry14']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" name="arr_effective_date" [(ngModel)]="obj['arr_effective_date']" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Registry14']}}">
                                </div>

                            </div>
                            <br>


                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="addNew()"> {{auth.allLableShowObjProp[auth.language_cd+'Registry19']}}</button>

                                </div>
                            </div>



                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> {{auth.allLableShowObjProp[auth.language_cd+'Applicant55']}} </p>
</ngx-spinner>
