import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from '../../service/main.service';
@Injectable({
  providedIn: 'root'
})
export class SorItemsService {

  httpUrl;
  // sorStd;
  costing_rate_std = "UPPWD-SOR";
  screen='SOR';
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;

  }


  async fetch(obj:any) {
    console.log(this.httpUrl)
    const resp = this.http.get<any>(this.httpUrl + '/depricated/item_list/item_list/getItems' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async getMaxID(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/depricated/item_list/item_list/getMaxID' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }


  async createsoritem(obj:any) {
    console.log(obj)
    const resp = this.http.post<any>(this.httpUrl + '/depricated/item_list/item_list/createItems', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async updatesoritem(obj:any) {
    const resp = this.http.put<any>(this.httpUrl + '/depricated/item_list/item_list/updateItems', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  /***********************************************Create Estimate******************************* */
  async getAllSorItems(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/depricated/item_list/item_list/getAllItems' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
}
