<div class="page-content fade-in-up">
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Payments List</div>
          </div>
          <div class="ibox-body">
            <ul class="nav nav-tabs tabs-line">
              <li class="nav-item">
                <a class="nav-link active"  data-toggle="tab"><i class="fa fa-bars" ></i>&nbsp;
                   Demolation List</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" *ngIf="complaint_inspection" data-toggle="tab"><i class="fa fa-plus-circle"
                    aria-hidden="true"></i> Assign complaint inspection
                </a>
              </li> -->

            </ul>
            <div class="tab-content">
              <div >

                <br>
                <div class="row">
                  <div class="col-4">
                    <mat-form-field style="margin-left: 2%">
                      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                    </mat-form-field>
                  </div>

                </div>


                <div class="example-container">

                  <table mat-table [dataSource]="datasource" matSort class="text-center">
                    <ng-container matColumnDef="s.no">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                        <h6>
                          <b>S.No</b>
                        </h6>
                      </th>
                      <td mat-cell *matCellDef="let element ; let i = index "> {{i+1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="case_ID">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Case ID
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                        {{element.case_id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Payment_type">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Payment Type
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                        {{element.pay_for}} </td>
                    </ng-container>



                    <ng-container matColumnDef="Amount">
                      <th mat-header-cell *matHeaderCellDef class="text-center"> Amount
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                        {{element.total_amt}}</td>
                    </ng-container>

                    <ng-container matColumnDef="Due_date">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Due Date
                        Date
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                        {{element.arr_effective_date | date}}   </td>
                    </ng-container>
                    <ng-container matColumnDef="Payment_date">
                        <th mat-header-cell *matHeaderCellDef class="text-center">Payment Date
                        </th>
                        <td mat-cell flex-stretch *matCellDef="let element">  {{element.pay_time | date}}
                          </td>
                      </ng-container>
                    <ng-container matColumnDef="Payment_status">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Payment Status
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                        {{element.status}} </td>
                    </ng-container>







                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

                    </tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                </div>

              </div>




            </div>
            <br>
          </div>
        </div>

      </div>
    </div>

  </div>



