<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account1']}}
                        <!--Bank Account Manage-->
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link {{list_sc}} " href="#tab-1" data-toggle="tab" (click)="list()"><i
                                    class="ti-bar-chart"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account2']}}
                                <!---- All Bank Account List-->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link {{create_sc}}" href="#tab-2" data-toggle="tab" (click)="create()"><i
                                    class="fa fa-plus-circle"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account3']}}
                                <!--Create Bank Account-->
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link {{update_sc}}" href="#tab-3" data-toggle="tab" *ngIf="update_flag"><i
                                    class="ti-settings"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account4']}}
                                <!--Update Bank Account-->
                            </a>
                        </li>

                    </ul>
                    <div>
                        <div id="tab-1" *ngIf="list_flag">

                            <br>


                            <!-- <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                            </div> -->

                            <div class="mat-elevation-z8">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account6']}}
                                            <!--ID-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; ">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="bank_acct_no">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account7']}}
                                            <!--Bank Account No-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{ element.bank_acct_no }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="bank_code">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account8']}}
                                            <!--Bank-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{ element.bank_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ifsc_code">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account9']}}
                                            <!--Ifsc Code-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{ element.ifsc_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="branch_code">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account10']}}
                                            <!--Branch-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{ element.branch_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bank_email">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account12']}}
                                            <!--Bank Email-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{ element.bank_email }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="bank_phone_no">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account13']}}
                                            <!--Bank Phone No-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{ element.bank_phone_no }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="bank_acct_desc">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account14']}}
                                            <!--Bank Account Description-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{ element.bank_acct_desc }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element">

                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">Update</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div id="tab-2" *ngIf="create_flag">

                            <br>
                            <form name="form" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account17']}}
                                            <!--Account No -->: <span class="text-danger">*</span>
                                        </h6>
                                    </div>
                                    <div class=" form-group col-3">
                                        <input type="number" class="form-control" name="bank_acct_no"
                                            [(ngModel)]="bankObj['bank_acct_no']" #bank_acct_no="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_acct_no.invalid }" required
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account18']}}">

                                        <!---------------- validation start-----------------------------  -->


                                        <!-- border color green -->
                                        <div [class.border_green]="bank_acct_no.dirty && !bank_acct_no.invalid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && bank_acct_no.invalid" class="error-message">
                                            <div *ngIf="bank_acct_no.errors?.['required']">Please Enter Account Number</div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->



                                    </div> <!--  enter account number -->
                                    <div class="col-3 text-right">
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account8']}}
                                            <!--Bank--> :
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <!-- <ng-select [items]="mainService.codeValueTechObj['ACC0006']" bindLabel="value"
                                        bindValue="code" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account26']}}"
                                        [(ngModel)]="bankObj['bank_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">

                                    </ng-select> -->

                                        <input type="text" class="form-control" [(ngModel)]="bankObj['bank_code']"
                                            name="bank_code" name="bank_code" #bank_code="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_code.invalid }" required
                                            disabled>

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        <h6> Conform Account Number : <span class="text-danger">*</span></h6>
                                    </div>

                                    <div class=" form-group col-3">
                                        <input type="password" class="form-control" placeholder="Conform Account Number"
                                            name="c_bank_acct_no" [(ngModel)]="bankObj['c_bank_acct_no']"
                                            #c_bank_acct_no="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && c_bank_acct_no.invalid }"
                                            required>

                                        <!---------------- validation start-----------------------------  -->


                                        <div *ngIf="c_bank_acct_no.dirty && c_bank_acct_no.valid">
                                            <div *ngIf="bank_acct_no.value != c_bank_acct_no.value">
                                                <span class="error-message">Account number not match</span>
                                                <!-- <div [class.border_red]="c_bank_acct_no.dirty && c_bank_acct_no.valid">
                                                </div> -->
                                            </div>
                                        </div>

                                        <div *ngIf="dup_acc_no == true">
                                            <span class="error-message">Duplicate Account Number</span>
                                        </div>


                                        <div *ngIf="c_bank_acct_no.value == bank_acct_no.value">
                                            <div [class.border_green]="c_bank_acct_no.dirty && c_bank_acct_no.valid">
                                            </div>
                                        </div>
                                        <div *ngIf="f.submitted && c_bank_acct_no.invalid" class="error-message">
                                            <div *ngIf="c_bank_acct_no.errors?.['required']">Please Enter Conform Account
                                                Number</div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->


                                    </div>


                                    <div class="col-3 text-right">
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account10']}}
                                            <!--Branch--> :
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <!-- <ng-select [items]="mainService.codeValueTechObj['ACC0007']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account21']}}"
                                        [(ngModel)]="bankObj['branch_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->
                                        <input type="text" class="form-control" [(ngModel)]="bankObj['branch_code']"
                                            name="branch_code" disabled>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        <!-- <h6> {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account19']}}IFSC Code :</h6> -->
                                        <h6>IFS Code : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control" placeholder="IFS Code"
                                            [(ngModel)]="bankObj['ifsc_code']" style="text-transform: uppercase;"
                                            name="ifsc_code" #ifsc_code="ngModel" (change)="fetchBankDtl()"
                                            oninput="this.value = this.value.toUpperCase()"
                                            [ngClass]="{ 'is-invalid': f.submitted && ifsc_code.invalid }" required>

                                        <!---------------- validation start-----------------------------  -->

                                        <div *ngIf="f.submitted && ifsc_code.invalid" class="error-message">
                                            <div *ngIf="ifsc_code.errors?.['required']">Please Enter IFS Code</div>
                                        </div>

                                        <!-- <div *ngIf="ifs_flag">
                                            <div [class.border_green]="ifsc_code.dirty && ifsc_code.valid">
                                               <div class="text-success;">{{ifs_mes}} </div>
                                            </div>
                                        </div> -->
                                        <div *ngIf="ifs_flag==false">
                                            <div [class.border_red]="ifsc_code.dirty && ifsc_code.valid"
                                                class="error-message"> {{ifs_mes}}
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                    <!-- <div class="col-1">


                                        <button class="btn btn-primary" (blur)="fetchBankDtl()">Verify</button>
                                    </div> -->
                                    <div class="col-3 text-right">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account12']}}
                                            <!---- Bank Email--> : <span class="text-danger">*</span>
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account23']}}"
                                            [(ngModel)]="bankObj['bank_email']" name="bank_email" #bank_email="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_email.invalid }" required
                                            email>


                                        <!---------------- validation start-----------------------------  -->


                                        <div *ngIf="f.submitted && bank_email.invalid" class="error-message">
                                            <div *ngIf="bank_email.errors?.['required']">Email is required</div>
                                            <div *ngIf="bank_email.errors?.['email']">Email must be a valid email address
                                            </div>
                                        </div>


                                        <div [class.border_green]="bank_email.dirty && bank_email.valid">
                                        </div>

                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account13']}}
                                            <!--Bank Phone No--> : <span class="text-danger">*</span>
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account24']}}"
                                            [(ngModel)]="bankObj['bank_phone_no']" name="bank_phone_no"
                                            #bank_phone_no="ngModel" minlength="10" maxlength="10"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_phone_no.invalid }" required>


                                        <!---------------- validation start-----------------------------  -->

                                        <div *ngIf="f.submitted && bank_phone_no.invalid" class="error-message">
                                            <div *ngIf="bank_phone_no.errors?.['required']">Please Enter Bank Phone Number
                                            </div>
                                            <div *ngIf="bank_phone_no.errors?.['minlength']">Mobile Number must be at least
                                                10 Digits</div>
                                        </div>
                                        <div [class.border_green]="bank_phone_no.dirty && bank_phone_no.valid">
                                        </div>


                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                    <!----enter bank phone number-->
                                    <div class="col-3 text-right">
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account14']}}
                                            <!--Bank Account Desciption -->: <span class="text-danger">*</span>
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account25']}}"
                                            [(ngModel)]="bankObj['bank_acct_desc']" name="bank_acct_desc"
                                            #bank_acct_desc="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_acct_desc.invalid }"
                                            required>

                                        <!---------------- validation start-----------------------------  -->

                                        <div [class.border_green]="bank_acct_desc.dirty && bank_acct_desc.valid">
                                        </div>


                                        <div *ngIf="f.submitted && bank_acct_desc.invalid" class="error-message">
                                            <div *ngIf="bank_acct_desc.errors?.['required']">Please Enter Bank Description
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>


                                    <!---- enter account description-->
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        <h6>
                                            Bank Address:
                                        </h6>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" class="form-control" [(ngModel)]="bankObj['bank_address']"
                                            name="bank_address" disabled>
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button type="submit" #sub
                                            class="btn btn-primary">{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account27']}}
                                            <!--Submit-->
                                        </button>
                                        <button type="reset" class="btn btn-success d-none" id="clean"> Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="tab-3" *ngIf="update_flag">

                            <br>

                            <!-- -------------------------------------New Code Start ----------------------------------- -->
                            <form name="form" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account17']}}
                                            <!--Account No -->: <span class="text-danger">*</span>
                                        </h6>
                                    </div>
                                    <div class=" form-group col-3">
                                        <input type="number" class="form-control" name="bank_acct_no"
                                            [(ngModel)]="bankObj['bank_acct_no']" #bank_acct_no="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_acct_no.invalid }" required
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account18']}}">

                                        <!---------------- validation start-----------------------------  -->


                                        <!-- border color green -->
                                        <div [class.border_green]="bank_acct_no.dirty && !bank_acct_no.invalid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && bank_acct_no.invalid" class="error-message">
                                            <div *ngIf="bank_acct_no.errors?.['required']">Please Enter Account Number</div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->



                                    </div> <!--  enter account number -->
                                    <div class="col-3 text-right">
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account8']}}
                                            <!--Bank--> :
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <!-- <ng-select [items]="mainService.codeValueTechObj['ACC0006']" bindLabel="value"
                                        bindValue="code" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account26']}}"
                                        [(ngModel)]="bankObj['bank_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">

                                    </ng-select> -->

                                        <input type="text" class="form-control" [(ngModel)]="bankObj['bank_code']"
                                            name="bank_code" name="bank_code" #bank_code="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_code.invalid }" required
                                            disabled>

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        <h6> Conform Account Number : <span class="text-danger">*</span></h6>
                                    </div>

                                    <div class=" form-group col-3">
                                        <input type="text" class="form-control" placeholder="Conform Account Number"
                                            name="c_bank_acct_no" [(ngModel)]="bankObj['c_bank_acct_no']"
                                            #c_bank_acct_no="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && c_bank_acct_no.invalid }"
                                            required>

                                        <!---------------- validation start-----------------------------  -->


                                        <div *ngIf="c_bank_acct_no.dirty && c_bank_acct_no.valid">
                                            <div *ngIf="bank_acct_no.value != c_bank_acct_no.value">
                                                <span class="error-message">Account number not match</span>
                                                <!-- <div [class.border_red]="c_bank_acct_no.dirty && c_bank_acct_no.valid">
                                                </div> -->
                                            </div>
                                        </div>


                                        <div *ngIf="dup_acc_no == true">
                                            <span class="error-message">Duplicate Account Number</span>
                                        </div>


                                        <div *ngIf="c_bank_acct_no.value == bank_acct_no.value">
                                            <div [class.border_green]="c_bank_acct_no.dirty && c_bank_acct_no.valid">
                                            </div>
                                        </div>
                                        <div *ngIf="f.submitted && c_bank_acct_no.invalid" class="error-message">
                                            <div *ngIf="c_bank_acct_no.errors?.['required']">Please Enter Conform Account
                                                Number</div>
                                        </div>

                                        <!---------------- validation end-----------------------------  -->


                                    </div>


                                    <div class="col-3 text-right">
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account10']}}
                                            <!--Branch--> :
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <!-- <ng-select [items]="mainService.codeValueTechObj['ACC0007']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account21']}}"
                                        [(ngModel)]="bankObj['branch_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->
                                        <input type="text" class="form-control" [(ngModel)]="bankObj['branch_code']"
                                            name="branch_code" disabled>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        <!-- <h6> {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account19']}}IFSC Code :</h6> -->
                                        <h6>IFS Code : <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control" placeholder="IFS Code"
                                            [(ngModel)]="bankObj['ifsc_code']" style="text-transform: uppercase;"
                                            name="ifsc_code" #ifsc_code="ngModel" (change)="fetchBankDtl()"
                                            oninput="this.value = this.value.toUpperCase()"
                                            [ngClass]="{ 'is-invalid': f.submitted && ifsc_code.invalid }" required>

                                        <!---------------- validation start-----------------------------  -->

                                        <div *ngIf="f.submitted && ifsc_code.invalid" class="error-message">
                                            <div *ngIf="ifsc_code.errors?.['required']">Please Enter IFS Code</div>
                                        </div>

                                        <!-- <div *ngIf="ifs_flag">
                                            <div [class.border_green]="ifsc_code.dirty && ifsc_code.valid">
                                               <div class="text-success;">{{ifs_mes}} </div>
                                            </div>
                                        </div> -->
                                        <div *ngIf="ifs_flag==false">
                                            <div [class.border_red]="ifsc_code.dirty && ifsc_code.valid"
                                                class="error-message"> {{ifs_mes}}
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                    <!-- <div class="col-1">


                                        <button class="btn btn-primary" (blur)="fetchBankDtl()">Verify</button>
                                    </div> -->
                                    <div class="col-3 text-right">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account12']}}
                                            <!---- Bank Email--> : <span class="text-danger">*</span>
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account23']}}"
                                            [(ngModel)]="bankObj['bank_email']" name="bank_email" #bank_email="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_email.invalid }" required
                                            email>


                                        <!---------------- validation start-----------------------------  -->


                                        <div *ngIf="f.submitted && bank_email.invalid" class="error-message">
                                            <div *ngIf="bank_email.errors?.['required']">Email is required</div>
                                            <div *ngIf="bank_email.errors?.['email']">Email must be a valid email address
                                            </div>
                                        </div>


                                        <div [class.border_green]="bank_email.dirty && bank_email.valid">
                                        </div>

                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        <h6> {{mainService.allLableShowObj[mainService.language_cd+'Bank_Account13']}}
                                            <!--Bank Phone No--> : <span class="text-danger">*</span>
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account24']}}"
                                            [(ngModel)]="bankObj['bank_phone_no']" name="bank_phone_no"
                                            #bank_phone_no="ngModel" minlength="10" maxlength="10"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_phone_no.invalid }" required>


                                        <!---------------- validation start-----------------------------  -->

                                        <div *ngIf="f.submitted && bank_phone_no.invalid" class="error-message">
                                            <div *ngIf="bank_phone_no.errors?.['required']">Please Enter Bank Phone Number
                                            </div>
                                            <div *ngIf="bank_phone_no.errors?.['minlength']">Mobile Number must be at least
                                                10 Digits</div>
                                        </div>
                                        <div [class.border_green]="bank_phone_no.dirty && bank_phone_no.valid">
                                        </div>


                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                    <!----enter bank phone number-->
                                    <div class="col-3 text-right">
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account14']}}
                                            <!--Bank Account Desciption -->: <span class="text-danger">*</span>
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Bank_Account25']}}"
                                            [(ngModel)]="bankObj['bank_acct_desc']" name="bank_acct_desc"
                                            #bank_acct_desc="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_acct_desc.invalid }"
                                            required>

                                        <!---------------- validation start-----------------------------  -->

                                        <div [class.border_green]="bank_acct_desc.dirty && bank_acct_desc.valid">
                                        </div>


                                        <div *ngIf="f.submitted && bank_acct_desc.invalid" class="error-message">
                                            <div *ngIf="bank_acct_desc.errors?.['required']">Please Enter Bank Description
                                            </div>
                                        </div>

                                    </div>

                                    <!---------------- validation end-----------------------------  -->

                                    <!---- enter account description-->
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        <h6>
                                            Bank Address:
                                        </h6>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" class="form-control" [(ngModel)]="bankObj['bank_address']"
                                            name="bank_address" disabled>
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button type="submit" #sub class="btn btn-primary">Update
                                            <!--update-->
                                        </button>
                                        <button type="reset" class="btn btn-success d-none" id="update_clean">
                                            Reset</button>
                                    </div>
                                </div>
                            </form>
                            <!-- -------------------------------------New Code End ----------------------------------- ---->

                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
