import { MainService } from '../service/main.service'
import { PropertyInfoService } from '../service/property-info.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AllotmentService } from '../service/allotment.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchemeService } from '../service/scheme.service';
import { AuctionService } from '../service/auction.service';

import { SubSchemeService } from '../service/sub-scheme.service';
import { RegistryService } from '../service/registry.service'
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { PropertyDefinitionService } from '../service/property-definition.service';
import { AuthenticationService } from '../../authentication.service';

import { ExcelService } from '../service/file-export.service';
import Swal from 'sweetalert2';
import { ApplicationService } from '../service/application.service';
import { SaleService } from '../service/sale.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
declare var $: any;

@Component({
  selector: 'app-bid-details',
  templateUrl: './bid-details.component.html',
  styleUrls: ['./bid-details.component.css']
})
export class BidDetailsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  dataSource: any;
  displayedColumns = ['s_no', 'app_name', 'phone_no', 'email', 'bid_amt'];


  constructor(public auth: AuthenticationService, private sale_service: SaleService,
    public mainService: MainService, private applicationService: ApplicationService,
    private auctionService: AuctionService, private PropertyDefinitionService: PropertyDefinitionService,
    private propInfoService: PropertyInfoService, private spinner: NgxSpinnerService,
    private SchemeService: SchemeService, private SubSchemeService: SubSchemeService) { }

  erpUser: any;
  b_acct_id: any;
  user_id: any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.getAllSchemes();
    await this.getAllOffers()
    await this.allAuctionUser()

  }

  allUsers: any = []
  allUserObj : any= {}
  async allAuctionUser() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    var resp = await this.sale_service.getAllAuctionUser(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allUsers = resp['data']
      for (let i = 0; i < this.allUsers.length; i++) {
        this.allUserObj[this.allUsers[i]['id']] = this.allUsers[i]
      }
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  offer_code_to_value: any={}
  allOffer: any = []
  async getAllOffers() {
    this.spinner.show()
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    var resp = await this.sale_service.getAllAuctionOffers(obj);
    if (resp['error'] == false) {
      this.allOffer = resp['data']
      let dummy: any = []
      for (let i = 0; i < this.allOffer.length; i++) {
        // if (this.allOffer[i]['mode_of_sale'] == "LOTTERY") {
        this.allOffer[i]['application_start_dt'] = this.allOffer[i]['application_start_dt'].split('T')[0]
        this.allOffer[i]['application_end_dt'] = this.allOffer[i]['application_end_dt'].split('T')[0]
        this.offer_code_to_value[this.allOffer[i]['offer_cd']]=this.allOffer[i]['offer_name']
        this.allOffer[i]['auction_name'] = this.allOffer[i]['auction_cd'] + '-' + this.allOffer[i]['auction_name']
        dummy.push(this.allOffer[i])
      }
      this.offerObj = dummy;
      this.allFilteredProperty=[]
      this.obj['property_no']=null
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  offerObj = []
  offerObj2 = []
  async getPropertyData() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['auction_cd'] = this.obj['auction_cd']
    var resp = await this.auctionService.getPropertyTypeByOffer(JSON.stringify(obj));
    if (resp['error'] == false) {
      let data = resp['data']
      this.offerObj2 = data
      let dummy: any = []
      let duplicte : any= []
      for (let i = 0; i < data.length; i++) {
        if (!duplicte.includes(data[i]['pro_type'])) {
          dummy.push(data[i])
          duplicte.push(data[i]['pro_type'])
        }
      }
      this.offerObj = dummy;
      this.offerObj = []
      this.obj['pro_type']=null
      this.allFilteredProperty=[]
      this.obj['property_no']=null
      this.offerObj = dummy;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }


  }

  allFilteredProperty: any = []
  async getPro_nodata() {
    this.allFilteredProperty = []
    let dummy: any = []
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['auction_xref_pro_type_id'] = this.obj['auction_xref_pro_type_id']
    var resp = await this.auctionService.getAuctionProperty(JSON.stringify(obj));
    if (resp['error'] == false) {
      let data: any = resp['data']
      this.offerObj2 = data
      let dummy: any = []
      let duplicte: any = []
      for (let i = 0; i < data.length; i++) {
        if (!duplicte.includes(data[i]['pro_type'])) {
          dummy.push(data[i])
          duplicte.push(data[i]['pro_type'])
        }
      }
      this.offerObj = dummy;
      this.offerObj = []
      this.obj['pro_type']=null
      this.allFilteredProperty=[]
      this.obj['property_no']=null
      this.allFilteredProperty = dummy;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }

  }

  setofferProNo() {
    for (let i = 0; i < this.allFilteredProperty.length; i++) {
      if (this.allFilteredProperty[i]['property_no'] == this.obj['property_no']) {
        this.obj['area'] = this.allFilteredProperty[i]['area']
        this.obj['unit'] = this.allFilteredProperty[i]['unit']
      }
    }
  }

  obj: any = {}
  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  showData: any = {}
  async view(element: any) {
    this.showData = {}
    this.showData = Object.assign({}, element)
    for (let i = 0; i < this.data.length; i++) {
      if (element['sub_scheme_code'] == this.data[i]['sub_scheme_code']) {
        this.showData['subsidised_or_non_subsidised'] = this.data[i]['subsidised_or_non_subsidised']

        this.showData['residential_or_commercial'] = this.data[i]['residential_or_commercial']

        this.showData['no_of_property'] = this.data[i]['no_of_property']

        this.showData['property_type_code'] = this.data[i]['property_type_code']

        this.showData['length'] = this.data[i]['length']

        this.showData['width'] = this.data[i]['width']

      }
    }
    //console.log(this.subschemeArr);
    //console.log(this.schemeArr);

    for (let i = 0; i < this.subschemeArr.length; i++) {
      if (element['sub_scheme_code'] == this.subschemeArr[i]['sub_scheme_code']) {
        this.showData['sub_scheme_name'] = this.subschemeArr[i]['sub_scheme_name']
        this.showData['locality'] = this.subschemeArr[i]['locality']
        this.showData['state'] = this.subschemeArr[i]['state']

      }
    }
    for (let i = 0; i < this.schemeArr.length; i++) {
      if (element['scheme_code'] == this.schemeArr[i]['scheme_code']) {
        this.showData['scheme_name'] = this.schemeArr[i]['scheme_name']
      }
    }
    $('.nav-tabs a[href="#tab-2"]').tab('show')

  }
  async approve(element: any) {
    var obj: any = new Object;
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = element.arr_id
    obj['arr_status_code'] = 'APPLICATION_APPROVED'
    this.spinner.show()
    var resp = await this.applicationService.changeApplicationStatus(obj);
    if (resp['error'] == false) {
      await this.show()
      Swal.fire('Success', 'Successfully Approved', 'success')
      this.spinner.hide();

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Some Error Occured', 'error')

    }
  }

  dataToPrint=[]
  async show() {
    this.spinner.show();
    this.obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.auctionService.getAllBids(JSON.stringify(this.obj));

    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 0; j < this.allApplication.length; j++) {
          if (this.allApplication[j]['party_id'] == resp['data'][i]['party_id']) {
            resp['data'][i]['party_name'] = this.allApplication[j]['party_name']
            resp['data'][i]['phone_no'] = this.allApplication[j]['phone_no']
            resp['data'][i]['email'] = this.allApplication[j]['email']
          }
        }
      }
      let data=this.sortArrayOfObjects(resp['data'],'bid_amt')
      this.dataToPrint=data
      console.log(data, 'all bids data');
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error While getting list', 'error')
    }
  }
  async print1() {
    this.spinner.show()
    let data: any = []
    data=this.dataToPrint
    var txt = this.erpUser['account_name'] + '(' + this.erpUser['account_short_name'] + ')'
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },
      pageOrientation: 'landscape',

      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    var header0 = {
      columns: [
        {
          width: '*',
          text: 'Auction Result',
          bold: true,
          alignment: 'center'
        }

      ],
    }
    var header9 = {
      columns: [
        {
          width: '*',
          text: '* Note : This is a computer generated document.',
          bold: true,
          alignment: 'left'
        }

      ],
    }
    var header1 = {
      columns: [
        {
          width: '*',
          text: 'Offer Name :',
          bold: true
        },

        {
          width: '*',
          text: this.offer_code_to_value[this.obj['offer_cd']]
        },
        {
          width: '*',
          text: 'Property Type',
          bold: true
        },

        {
          width: '*',
          text: this.obj['pro_type']
        }

      ],
    }

    var header2 = {
      columns: [
        {
          width: '*',
          text: 'Property Number :',
          bold: true
        },

        {
          width: '*',
          text: this.obj['property_no']
        },
        {
          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        }

      ],
    }
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header2);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    var tbl = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*', '*', '*'],
        body: [
          ['Party ID','Party Name','Party Mobile Number','Party Email','Bid AMount']
        ],
      }
    };
    console.log(data, 'data')
    dd.content.push(tbl);
    for (var i = 0; i < data.length; i++) {
      var arr = []
      arr.push(data[i]['party_id']);
      arr.push(this.allUserObj[data[i].party_id]['party_name']);
      arr.push(this.allUserObj[data[i].party_id]['phone_no']);
      arr.push(this.allUserObj[data[i].party_id]['email']);
      arr.push(data[i]['bid_amt']);
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push(header9);
    this.spinner.hide()
    pdfMake.createPdf(dd).download("auctionResult");
  }
  sortArrayOfObjects = (arr: any, key: any) => {
    return arr.sort((a: any, b: any) => {
      return b[key] - a[key];
    });
  };
  allApplication = []
  async allAuctionApplication() {
    this.spinner.show();
    this.allApplication = []
    this.obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.auctionService.getAllAuctionApplication(JSON.stringify(this.obj));
    //console.log(resp, 'all application');

    if (resp['error'] == false) {
      this.allApplication = resp['data']
    }
  }
  schemeArr = []
  async getAllSchemes() {
    this.spinner.show();

    var resp = await this.SchemeService.getScheme(this.b_acct_id);
    //console.log(resp);
    if (resp['error'] == false) {


      this.schemeArr = resp.data;
      /* for (let i = 0; i < this.schemeArr.length; i++) {
        this.schemeObject[this.schemeArr[i]['scheme_code']] = this.schemeArr[i]['scheme_name']
      } */
      this.spinner.hide();
    } else {
      this.spinner.hide();
      /*  this.snackBar.open("Error occured while getting Schemes", 'Error', {
         duration: 5000,
       }); */
    }
  }

  data = []
  async getAllPropertyInfos() {
    this.data = []
    this.obj['b_acct_id'] = this.b_acct_id
    this.spinner.show();
    var resp = await this.propInfoService.getAllPropertyType(this.obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.data = resp.data;
      //console.log(this.data, 'property');
    } else {
      this.spinner.hide();
    }
    await this.allAuctionApplication()
  }
  subschemeArr = []
  async getAllSubschemes() {
    this.spinner.show();

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['scheme_code'] = this.obj['scheme_code'];
    var resp = await this.SubSchemeService.getsubScheme(obj);
    //console.log(resp);

    if (resp['error'] == false) {
      this.subschemeArr = resp.data;


      this.spinner.hide();

    } else {

      this.spinner.hide();

    }
  }
}
