import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ChartOfAccountService } from "../../../service/chart-of-account.service";
import { LedgerService } from "../../../service/ledger.service";
import { MainService } from "../../../service/main.service";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as  pdfMake from  "pdfmake/build/pdfmake";
import { NgxSpinnerService } from "ngx-spinner";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import Swal from "sweetalert2";
import * as moment from "moment";

import * as fs from 'file-saver';

@Component({
  selector: 'app-fix-assest-reg',
  templateUrl: './fix-assest-reg.component.html',
  styleUrls: ['./fix-assest-reg.component.css']
})
export class FixAssestRegComponent implements OnInit {

  erpUser:any;
  b_acct_id:any;
  allData:any;

  selectedFinYear:any
  allFinYear:any;

  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  constructor(
    private ledgerService: LedgerService,
    private chartOfAccountService: ChartOfAccountService,
    private spinner: NgxSpinnerService,
    private mainService: MainService
  ) { }

  async ngOnInit() {
    this.erpUser =  JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllFinYear();
    await this.getAllChartOfAccount();
    await this.setFinYear();
    await this.allFixAssetReport();
  }

  mainObj:any = {};

  fin_yr:any;
 async setFinYear() {

    // if (this.selectedFinYear == undefined) {
    //   Swal.fire("warning", 'Please select any fin year...', "warning");
    //   this.mainObj['start_date'] = null;
    //   this.mainObj['end_date'] = null;
    //   return;
    // }
    this.allFinYear.map((x:any) => {
      if (x['fin_year'] == this.selectedFinYear) {
        this.mainObj['start_date'] = x['start_date'];
        this.mainObj['end_date'] = x['end_date'];
      }
    })
    this.fin_yr = this.selectedFinYear + " - "+ (Number(this.selectedFinYear) + 1)

  }

  async getAllFinYear() {
    this.spinner.show();
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.ledgerService.getAllFinYear(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.allFinYear = resp["data"];
      this.allFinYear.map((x:any) => {
        x['fin_year_desc'] = x['fin_year'] + "-" + (Number(x['fin_year']) + 1)
        if(x['status'] == "OPEN"){
          this.selectedFinYear = x['fin_year']
        }
      })

      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("error", "Error while featching all field", "error");
    }

  }










  allCoaObj :any= {};
  fixAssestCOA:any = [];
  depCOA :any= [];
  async getAllChartOfAccount() {
    this.spinner.show();
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.chartOfAccountService.getchartofaccount(obj);
    if (resp["error"] == false) {

      this.fixAssestCOA = [];
      this.depCOA = [];

      resp["data"].map((x:any) => {


        if (x['lvl4_code'] == 'CH1121' && x['is_leaf'] == 1) {
          this.fixAssestCOA.push(x["leaf_code"]);
        }

        if (x['lvl4_code'] == 'CH1123' && x['is_leaf'] == 1) {
          this.depCOA.push(x["leaf_code"]);
        }

        this.allCoaObj[x["leaf_code"]] = x["leaf_value"];
      });
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("error", "Error while featching all field", "error");
    }
  }

  async refresh() {

  }

  table_data:any = [];

  totalObj :any= {};



  getNumberOfDays(d1:any, d2:any) {
    // console.log(d1,d2)
    let start = moment(d1);
    let end = moment(d2);
    let days = end.diff(start, "days")
    console.log(days)
    return days

  }
  async allFixAssetReport() {
    // console.log('allFixAssetReport');
    this.table_data = [];
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["start_date"] = this.mainObj['start_date'];
    obj["end_date"] = this.mainObj['end_date'];
    obj["fix_coa"] = 'CH1121';
    obj["d_coa"] = 'CH1123';
    obj["fin_year"] = this.selectedFinYear;

    //*********************************************************** */

    let resp = await this.ledgerService.getFixAssestOpening(JSON.stringify(obj));
    console.log(resp);
    let d_opening :any= {};
    let f_opening :any= {};
    if (resp["error"] == false) {
      resp.data.d_opening.map((x:any) => {
        d_opening[x['lvl5_code']] = x['opening']
      })

      resp.data.f_opening.map((x:any) => {
        f_opening[x['lvl5_code']] = Number(x['opening']) * (-1)//get in DB
      })
    }


    console.log(d_opening)
    console.log(f_opening)
    console.log(this.fixAssestCOA)


    //*********************************************************** */
    let resp1 = await this.ledgerService.fixAssetRate(JSON.stringify(obj));
    console.log(resp1);
    let rate:any = {};
    let fix_to_dcoa:any = {};
    if (resp1["error"] == false) {
      resp1.data.map((x :any)=> {
        rate[x['f_coa']] = x['d_rate']
        fix_to_dcoa[x['f_coa']] = x['d_coa']
      })

      console.log(rate)
      console.log(fix_to_dcoa)

    }
    //*********************************************************** */

    let resp2 = await this.ledgerService.getFixAssestTrx(JSON.stringify(obj));
    console.log(resp2);
    let less_180 :any= {};
    let more_180:any = {};
    let sale:any = {};
    if (resp2["error"] == false) {
      console.log(resp2.data)
      resp2.data.map((x :any)=> {

        if (x['db_cd_ind'] == 'CR') {
          if (sale[x['lvl5_code']] == undefined) {
            sale[x['lvl5_code']] = 0
          }

          sale[x['lvl5_code']] += x['txn_amt']
        }
        if (x['db_cd_ind'] == 'DB') {
          if (this.getNumberOfDays( x['acct_dt'] , obj['end_date']) < 179) {
            if (less_180[x['lvl5_code']] == undefined) {
              less_180[x['lvl5_code']] = 0
            }

            less_180[x['lvl5_code']] += (x['txn_amt'] * (-1))
          } else {
            if (more_180[x['lvl5_code']] == undefined) {
              more_180[x['lvl5_code']] = 0
            }

            more_180[x['lvl5_code']] += (x['txn_amt'] * (-1))
          }
        }
      })

      console.log(sale)
      console.log(more_180)
      console.log(less_180)

    }





    //*********************************************************** */

    for (let i = 0; i < this.fixAssestCOA.length; i++) {
      let ob :any= new Object();
      ob['sno'] = i + 1;//1
      //**************************** */
      ob['chart_of_account'] = this.allCoaObj[this.fixAssestCOA[i]];//2(from db)


      if (rate[this.fixAssestCOA[i]] != undefined) {
        ob['rate'] = rate[this.fixAssestCOA[i]];//3(from db)
      } else {
        ob['rate'] = 0;//3(from db)
      }


      if (f_opening[this.fixAssestCOA[i]] != undefined) {
        ob['opening_bal'] = f_opening[this.fixAssestCOA[i]];//4(from db)
      } else {
        ob['opening_bal'] = 0;//4(from db)
      }

      if (more_180[this.fixAssestCOA[i]] != undefined) {
        ob['more_180'] = more_180[this.fixAssestCOA[i]];//5(from db)
      } else {
        ob['more_180'] = 0;//5(from db)
      }

      if (less_180[this.fixAssestCOA[i]] != undefined) {
        ob['less_180'] = less_180[this.fixAssestCOA[i]];//6(from db)
      } else {
        ob['less_180'] = 0;//6(from db)
      }

      if (sale[this.fixAssestCOA[i]] != undefined) {
        ob['sale'] = sale[this.fixAssestCOA[i]];//7(from db)
      } else {
        ob['sale'] = 0;//7(from db)
      }

      // ob['less_180'] = 0;//5(from db)
      // ob['more_180'] = 0;//6(from db)
      // ob['sale'] = 0;//7(from db)

      if (d_opening[fix_to_dcoa[this.fixAssestCOA[i]]] != undefined) {
        ob['d_opening'] = d_opening[fix_to_dcoa[this.fixAssestCOA[i]]];//9(from db)
      } else {
        ob['d_opening'] = 0;//9(from db)
      }

      // ob['d_opening'] = 0;//9(from db)
      //**************************** */

      ob['total_gross'] = ob['opening_bal'] + ob['less_180'] + ob['more_180'] - ob['sale']//8=4+5+6-7

      ob['wdv_opening'] = ob['opening_bal'] - ob['d_opening'];//14=4-9

      ob['d_more_180'] = ((ob['wdv_opening'] + ob['more_180'] - ob['sale']) * (ob['rate'])) / 100;//11=(14+5-7)*3/100
      ob['d_less_180'] = (ob['less_180'] * ob['rate']) / 200;//10=(6*3)/200
      ob['total_d'] = ob['d_opening'] + ob['d_less_180'] + ob['d_more_180'];//12=9+10+11
      ob['wdv_closing'] = ob['wdv_opening'] - ob['d_less_180'] - ob['d_more_180'] + ob['less_180'] + ob['more_180'] - ob['sale'];//13=14-10-11+5+6-7

      this.table_data.push(ob)
    }



    //*********************************************************** */

    this.totalObj['opening_bal'] = 0
    this.totalObj['less_180'] = 0
    this.totalObj['more_180'] = 0
    this.totalObj['sale'] = 0
    this.totalObj['d_opening'] = 0
    this.totalObj['total_gross'] = 0
    this.totalObj['wdv_opening'] = 0
    this.totalObj['d_less_180'] = 0
    this.totalObj['d_more_180'] = 0
    this.totalObj['total_d'] = 0
    this.totalObj['wdv_closing'] = 0
    console.table(this.table_data)
    this.table_data.map((x :any)=> {
      this.totalObj['opening_bal'] += x['opening_bal'];//4(from db)
      this.totalObj['less_180'] += x['less_180'];//5(from db)
      this.totalObj['more_180'] += x['more_180'];//6(from db)
      this.totalObj['sale'] += x['sale'];//7(from db)
      this.totalObj['d_opening'] += x['d_opening'];//9(from db)
      this.totalObj['total_gross'] += x['total_gross'];//8=4+5+6-7
      this.totalObj['wdv_opening'] += x['wdv_opening'];//14=4-9
      this.totalObj['d_less_180'] += x['d_less_180'];//10=(14+5-7)*3/100
      this.totalObj['d_more_180'] = +x['d_more_180'];
      this.totalObj['total_d'] += x['total_d'];
      this.totalObj['wdv_closing'] += x['wdv_closing'];
    })
    console.log(this.totalObj)
  }





  async export() {
    let obj:any = new Object();
    obj['start_date'] = this.mainObj['start_date']
    obj['end_date'] = this.mainObj['end_date']
    obj['fin_year'] = this.selectedFinYear;
    obj['data'] = this.table_data;
    obj['totalObj'] = this.totalObj;
    let resp :any= await this.ledgerService.createExcel(obj);
    let blob :any= new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'Fixed-Asset.xlsx');
  }


  print() {

    let d1 = this.mainObj['start_date'];
    let d2 = this.mainObj['end_date'];
    let fin_year = this.selectedFinYear + "-" + (Number(this.selectedFinYear) + 1)


    let account_name = this.erpUser['account_name'];
    let account_short_name = this.erpUser['account_short_name'];
    let txt = account_name + "(" + account_short_name + ") \n";
    let dd:any = {
      pageSize: 'A4',
      header: function (currentPage:any, pageCount:any) {
        let arr = []
        let obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'landscape',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    let h1txt = "Fixed Assets and Depreciation on Fixed Assets Forming Part of Balance Sheet as at " + this.mainObj['start_date'];
    var header1 = {
      columns: [
        {
          width: '*',
          text: h1txt,
          bold: true,
          alignment: 'center'
        }
      ],
    }

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] }); //x2:760
    dd.content.push({ text: " " });
    dd.content.push(header1);

    let tbl1 = {
      fontSize: 10,
      table: {
        headerRows: 3,
        widths: [20, 150, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40],
        // widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
        body: [
          [
            { rowSpan: 3, text: 'SR NO' }, { rowSpan: 3, text: 'Detail Of Fixed Assets', alignment: 'center' }, { rowSpan: 3, text: 'Rate ', alignment: 'center' },
            { text: 'Gross Block', alignment: 'center', colSpan: 5 }, {}, {}, {}, {}, { text: 'Depreciation Block', alignment: 'center', colSpan: 4 },
            {}, {}, {}, { text: 'Net Block', alignment: 'center', colSpan: 2 }, {}],

          [{}, {}, {}, { rowSpan: 2, text: 'Op Bal. As on ' + d2, alignment: 'center' },
          { text: 'Additions', alignment: 'center', colSpan: 2 }, {}, { rowSpan: 2, text: 'Sale', alignment: 'center', }, { rowSpan: 2, text: 'Total as on ' + d2, alignment: 'center', },
          { rowSpan: 2, text: 'Op Bal. As on ' + d1, alignment: 'center' }, { text: 'For ' + fin_year, alignment: 'center', colSpan: 2 }, {}, { rowSpan: 2, text: 'Total upto ' + d2, alignment: 'center', },
          { rowSpan: 2, text: 'W.D. V As on ' + d2, alignment: 'center', }, { rowSpan: 2, text: 'W.D. V As on ' + d1, alignment: 'center', }
          ],

          [{}, {}, {}, {}, { text: '180 Days or More ', alignment: 'center' }, { text: 'Less than 180 Days', alignment: 'center' }, {}, {}, {},
          { text: '180 Days or More ', alignment: 'center' }, { text: 'Less than 180 Days', alignment: 'center' }, {}, {}, {}],
          [{ text: '1', alignment: 'center' }, { text: '2', alignment: 'center' }, { text: '3', alignment: 'center' }, { text: '4', alignment: 'center' },
          { text: '5', alignment: 'center' }, { text: '6', alignment: 'center' }, { text: '7', alignment: 'center' }, { text: '8', alignment: 'center' },
          { text: '9', alignment: 'center' }, { text: '10', alignment: 'center' }, { text: '11', alignment: 'center' }, { text: '12', alignment: 'center' },
          { text: '13', alignment: 'center' }, { text: '14', alignment: 'center' }]
        ],
      }
    };

    dd.content.push(tbl1);
    console.log(dd.content)
    for (let i = 0; i < this.table_data.length; i++) {
      let arr = [];
      arr.push({ text: this.table_data[i]['sno'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['chart_of_account'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['rate'] + " %", alignment: 'center' });
      arr.push({ text: this.table_data[i]['opening_bal'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['more_180'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['less_180'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['sale'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['total_gross'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['d_opening'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['d_more_180'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['d_less_180'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['total_d'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['wdv_closing'], alignment: 'center' });
      arr.push({ text: this.table_data[i]['wdv_opening'], alignment: 'center' });
      dd.content[dd.content.length - 1].table.body.push(arr);
    }

    let arr = []
    arr.push({});
    arr.push({ text: 'Total', alignment: 'center', bold: true });
    arr.push({});
    arr.push({ text: this.totalObj['opening_bal'], alignment: 'center', bold: true });
    arr.push({ text: this.totalObj['more_180'], alignment: 'center', bold: true });
    arr.push({ text: this.totalObj['less_180'], alignment: 'center', bold: true });
    arr.push({ text: this.totalObj['sale'], alignment: 'center', bold: true });
    arr.push({ text: this.totalObj['total_gross'], alignment: 'center', bold: true });
    arr.push({ text: this.totalObj['d_opening'], alignment: 'center', bold: true });
    arr.push({ text: this.totalObj['d_more_180'], alignment: 'center', bold: true });
    arr.push({ text: this.totalObj['d_less_180'], alignment: 'center', bold: true });
    arr.push({ text: this.totalObj['total_d'], alignment: 'center', bold: true });
    arr.push({ text: this.totalObj['wdv_closing'], alignment: 'center', bold: true });
    arr.push({ text: this.totalObj['wdv_opening'], alignment: 'center', bold: true });
    dd.content[dd.content.length - 1].table.body.push(arr);

    pdfMake.createPdf(dd).download('Fixed-Assets' + '.pdf');






  }

}
