import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  login_with_password = environment.loginWithPassword || false;

  apiHost = environment.apiHost || "https://localhost"
  apiPort = environment.apiPort || "3006"
  version = "22-05-2021";
  httpUrl:any;
  profile_data = '';
  uiHost=environment.apiHost || "https://localhost"
  apiProtocol:any
  constructor(private http: HttpClient) {
    this.uiHost = window.location.hostname;
      ////////////////////////////
    // decide protocol based on window location
    if (this.uiHost === "localhost") {
      this.apiProtocol = "http";

    }
    else{

this.apiProtocol = "https";    }



    if (this.uiHost.startsWith("dev.erp2.upda.co.in")) {
      this.uiHost = 'dev.erp2.upda.co.in';
    } else if (this.uiHost.startsWith("uat.erp2.upda.co.in")) {
      this.uiHost = 'uat.erp2.upda.co.in';
      this.apiPort = '3006';
    } else if (this.uiHost.startsWith("preprod.erp2.upda.co.in")) {
      this.uiHost = 'preprod.erp2.upda.co.in';
      this.apiPort = '3006';
    } else if (this.uiHost.startsWith("dev.upda.co.in")) {
      this.uiHost = 'dev.upda.co.in';
      this.apiPort = '3016';
    } else if (this.uiHost.startsWith("icds.upda.co.in")) {
      this.uiHost = 'icds.upda.co.in';
      this.apiPort = '3016';
    } else if (this.uiHost.startsWith("lda.upda.co.in")) {
      this.uiHost='property.upda.co.in';
      this.login_with_password=false;
      this.apiPort = '3022';
    } else if (this.uiHost.startsWith("sealing.upda.co.in")) {
      this.uiHost = 'sealing.upda.co.in';
      this.apiPort = '3014';
      this.login_with_password=false;
    }  else if (this.uiHost.startsWith("tracking.upda.co.in")) {
      this.uiHost = 'tracking.upda.co.in';
      this.apiPort = '3026';
      this.login_with_password=false;
    }  else if (this.uiHost.startsWith("erp2.upda.co.in")) {
      this.uiHost = 'erp2.upda.co.in';
      this.apiPort = '3015';
      this.login_with_password=true;
    } else if (this.uiHost.startsWith("erplda.upda.co.in")) {
      this.uiHost = 'erplda.upda.co.in';
      this.apiPort = '3032';
      this.login_with_password=true;
    } else if (this.uiHost.startsWith("dev2.upda.co.in")) {
      this.uiHost = 'dev2.upda.co.in';
      this.apiPort = '3000';
      this.login_with_password=true;
    }
     else {

      this.uiHost = 'localhost';


      // this.uiHost='icds.upda.co.in';
      // this.apiPort = '3016';
      // this.apiProtocol = "https";

    }
    this.httpUrl = this.apiProtocol + '://' + this.uiHost + ":" + this.apiPort;
    console.log("API @ " + this.httpUrl + " is being used.");
   }

   allLanguage:any = []
   allLableShowObj:any = {};
   allLabel:any = [];
   language_cd:any;
   allLableShowObjProp:any = {};
   allLabelProp:any = [];
   allLableShowObjHR:any = {};
   allLabelHR:any = [];
   allLableShowObjSA:any = {};
   allLabelSA:any = [];
   allSystemModuleObj:any = {};
  changeLanguage() {
    if (this.language_cd == null || this.language_cd == undefined) {
      this.language_cd = 'ENG'
    }
  }

  async getSystemModule() {
    let  obj:any =  new Object();
    obj['b_acct_id'] = 0;
    var resp = await this.getModules(JSON.stringify(obj));
    if (resp['error'] == false) {
       obj = new Object();
      for (let i = 0; i < resp.data.length; i++) {
        obj[resp.data[i]['module_cd']] = resp.data[i]['module_name']
      }
      this.allSystemModuleObj = obj;

    } else {

    }
  }
  async signUp(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/signup', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }



  async login(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/login', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAllLanguages(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/systemdata/languages/getAllLanguages' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getModules(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/resource/getmodules' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getcomponentLabelvalueswithLanguage(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/systemdata/languages/getcomponentLabelvalueswithLanguage' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  // async getcomponentLabelvalueswithLanguage1(obj) {
  //   const res = await this.http.get<any>(this.httpUrl + '/systemdata/languages/getcomponentLabelvalueswithLanguage1' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return res;
  // }

//   async sendMsg(mobile) {
//     var str = "https://2factor.in/API/V1/" + this.api_key + "/SMS/" + mobile + "/AUTOGEN"
//     const resp = await this.http.get<any>(str).toPromise().then(res => {
//       return res;
//     });
//     return resp;

//   }

  async sendMsg(mobile:any) {
    let obj:any  = new Object();
    obj['mobile']=mobile;
    obj['api_key'] =this.api_key;
    const resp = await this.http.get<any>(this.httpUrl + '/systemdata/authentication/sendOTPMsg'+ JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;

  }

  api_key = '4f581079-6160-11ec-b710-0200cd936042';
  async verifyMsg(otp_entered_by_user:any, session_id:any) {
    var str = "https://2factor.in/API/V1/" + this.api_key + "/SMS/VERIFY/" + session_id + "/" + otp_entered_by_user
    const resp = await this.http.get<any>(str).toPromise().then(res => {
      return true
    }).catch(err => {
      return false
    });
    return resp;
  }

  async getaccountmodule(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/systemdata/legalentity/getaccountmodule' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCurrentrole(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/role/getcurrentroles' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCurrentroleForAccount(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/md/role/getcurrentroles' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCurrentUserFromAdmin(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/user/getcurrentuser' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCurrentUserFromMD(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/md/user/getcurrentuser' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async loginERP(obj:any) {
    console.log(obj)
    const resp = await this.http.post<any>(this.httpUrl + '/systemdata/authentication/login', obj).toPromise().then(res => {
      return res;
    });
    console.log(resp)
    return resp;
  }

  async createloginInfo(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/systemdata/loginInfo/createloginInfo', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getDataFromMobileNumberOrEmail(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/getDataFromMobileNumberOrEmail' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

}
