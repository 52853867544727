import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
// import { ThrowStmt } from "@angular/compiler";

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ApplicationService } from "../../service/application.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SchemeService } from "../../service/scheme.service";
import { SubSchemeService } from "../../service/sub-scheme.service";
import { MainService } from "../../service/main.service";
import * as  pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
declare var $: any;
import { DomSanitizer } from "@angular/platform-browser";
import { AuthenticationService } from "../../../authentication.service";
import Swal from "sweetalert2";
import { SaleService } from "../../service/sale.service";
import { MetadataService } from "../../service/metadata.service";
// import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { table } from "console";
import { style } from "@angular/animations";

@Component({
  selector: "app-lottery-slip",
  templateUrl: "./lottery-slip.component.html",
  styleUrls: ["./lottery-slip.component.css"],
})
export class LotterySlipComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  @ViewChild("paginator1", { static: false }) paginator1!: MatPaginator;
  @ViewChild("sortCol2", { static: false }) sortCol2!: MatSort;

  @ViewChild("paginator2", { static: false }) paginator2!: MatPaginator;
  @ViewChild("sortCol3", { static: false }) sortCol3!: MatSort;
  Obj:any = {};
  globleElement:any = {};
  constructor(
    private sanitizer: DomSanitizer,
    private metadataService: MetadataService,
    private sale_service: SaleService,
    public auth: AuthenticationService,
    public mainService: MainService,
    private subSchemeService: SubSchemeService,
    private schemeService: SchemeService,
    private applicationService: ApplicationService,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService
  ) { }
  dataSource:any;
  displayedColumns = ["party_id", "party_name", "applied_date", "application_amount", "application_challan_no", "arr_status_code", "pmnt_status", "stl_status", "action"];
  dataSource2:any;
  displayedColumns2 = ["party_id", "co_applicant_name", "co_applicant_father_or_husband_name", "co_applicant_email", "co_applicant_phone_no", "co_applicant_adhar_no", "co_applicant_annual_income", "co_applicant_religion", "co_applicant_photo_file_name", "co_applicant_sig_file_name"];
  schemeArr:any = [];
  schemeObj:any = {};
  statusArr:any = [{ code: "APPLICATION_APPROVED", value: "APPLICATION_APPROVED" }];
  allCatArr:any = [{ party_quota: "ALL/ANY", arr_status_code: "APPLICATION_APPROVED" }];
  allSubeArr:any = [{ party_sub_quota: "ALL/ANY", arr_status_code: "APPLICATION_APPROVED" }];
  allPropArr:any = [{ property_type: "ALL/ANY", arr_status_code: "APPLICATION_APPROVED" }];
  allPayArr:any = [{ payment_type: "ALL/ANY", arr_status_code: "APPLICATION_APPROVED" }];

  displayedColumns1 = ["party_id", "party_name", "applied_date", "application_amount", "application_challan_no", "arr_status_code", "party_quota"];
  displayedColumns3 = ["property_id", "offer_cd", "pro_type", "assigned_property_number", "area", "unit"];

  status:any = "";
  obj:any = {};
  obj1:any = {};
  fileURL:any;
  coApplicantFileURL:any;
  allApplications:any = [];
  viewDocumentObj:any = {};
  b_acct_id:any;
  erpUser:any;

  dataSource1:any;
  dataSource3:any;

  tempArr :any= [];
  proTypeArr :any= [];
  allSubSchemes :any= [];
  allSubSchemes1:any = [];
  subSchemeObj:any = {};
  partyCoApplicantObj:any = {};
  flag :any= false;
  required_doc_obj:any = {};
  optional_doc_obj :any= {};
  statusDataArr :any= [];
  imgURL:any;
  catArr:any = [];
  subArr:any = [];
  propArr :any= [];
  payArr :any= [];
  async ngOnInit() {
    this.Obj["reason"] = "";
    this.obj1["status"] = this.statusArr[0].value;
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    // this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'aimage' });
    // this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };

    await this.getAllSchemes();
    await this.getAllOffers();
    // await this.getCatStatus();
    // this.imgURL = this.mainService.profileImageUrl;
    // console.log(this.imgURL, 'imgURL')
  }
  //clear all field
  reset() {
    this.obj1["offer_cd"] = {};
    this.obj1["catstatus"] = {};
    this.obj1["substatus"] = {};
    this.obj1["prostatus"] = {};
    this.obj1["paystatus"] = {};
  }
  // get all offer data
  allOffer:any = [];
  offer_code_to_value:any = {};
  async getAllOffers() {
    this.spinner.show();
     let obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["life_cycle_status"] = "ACTIVE";

    console.log(obj);
    var resp = await this.sale_service.getAllOffers(obj);
    console.log(resp);
    if (resp["error"] == false) {
      let dummy = [];
      for(let j = 0 ; j < resp.data.length ; j++){
        if(resp.data[j]['mode_of_sale'] == 'LOTTERY'){
          dummy.push(resp.data[j])
        }
      }
      this.allOffer = dummy;
      console.log(this.allOffer, "fetched data");
      // let dummy = [];
      for (let i = 0; i < this.allOffer.length; i++) {

        this.allOffer[i]["application_start_dt"] = this.allOffer[i]["application_start_dt"].split("T")[0];
        this.allOffer[i]["application_end_dt"] = this.allOffer[i]["application_end_dt"].split("T")[0];
        this.offer_code_to_value[this.allOffer[i]["offer_cd"]] = this.allOffer[i]["offer_name"];
        this.allOffer[i]["desc"] = this.allOffer[i]["offer_cd"] + " - " + this.allOffer[i]["offer_name"];
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error While Getting Data", "error");
    }
  }
  async getAllSchemes() {
    this.spinner.show();

    var resp = await this.schemeService.getScheme(this.b_acct_id);
    if (resp["error"] == false) {
      this.schemeArr = resp.data;
      for (let i = 0; i < this.schemeArr.length; i++) {
        this.schemeObj[this.schemeArr[i]["scheme_code"]] = this.schemeArr[i]["scheme_name"];
      }
      this.spinner.hide();
    } else {
      Swal.fire(
        this.auth.allLableShowObjProp[this.auth.language_cd + "Booklet_purchase15"], this.auth.allLableShowObjProp[this.auth.language_cd + "Booklet_purchase16"], "error");
      this.spinner.hide();
    }
  }

  async changeScheme() {
    this.spinner.show();
    var obj : any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["scheme_code"] = this.obj["scheme_code"];
    var resp = await this.subSchemeService.getsubScheme(obj);
    if (resp["error"] == false) {
      this.allSubSchemes = resp.data;
      for (let i = 0; i < this.allSubSchemes.length; i++) {
        this.subSchemeObj[this.allSubSchemes[i]["sub_scheme_code"]] = this.allSubSchemes[i]["sub_scheme_name"];
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire(this.auth.allLableShowObjProp[this.auth.language_cd + "Booklet_purchase15"],this.auth.allLableShowObjProp[this.auth.language_cd + "Booklet_purchase16"],"error");
    }
  }

  async changeScheme1() {
    this.spinner.show();
    var obj : any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["scheme_code"] = this.obj1["scheme_code"];
    var resp = await this.subSchemeService.getsubScheme(obj);
    if (resp["error"] == false) {
      this.allSubSchemes1 = resp.data;
      // for (let i = 0; i < this.allSubSchemes.length; i++) {
      //   this.subSchemeObj[this.allSubSchemes[i]['sub_scheme_code']] = this.allSubSchemes[i]['sub_scheme_name']
      // }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire(this.auth.allLableShowObjProp[this.auth.language_cd + "Booklet_purchase15"],this.auth.allLableShowObjProp[this.auth.language_cd + "Booklet_purchase16"],"error");
    }
  }

  //lottery slip aplicant data
  tableData:any = [];
  uniqueCat:any = [];
  uniqueSub:any = [];
  uniquePro:any = [];
  uniquePay:any = [];
  async getStatusApplications() {
    this.spinner.show();

    var obj : any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["offer_cd"] = this.obj1["offer_cd"];
    var resp = await this.applicationService.getAllApplications(obj);
    console.log(resp, this.obj1);
    if (resp["error"] == false) {
      this.tempArr = resp.data;
      var arr = [];
      for (let i = 0; i < this.tempArr.length; i++) {
        if (this.tempArr[i]["arr_status_code"] == this.obj1["status"]) {
          arr.push(this.tempArr[i]);
        }
      }
      this.catArr = arr;
      this.obj1["catstatus"] = null;
      this.obj1["substatus"] = null;
      this.obj1["prostatus"] = null;
      this.obj1["paystatus"] = null;
      this.uniqueCat = [...new Set(this.catArr.map((item:any) => item.party_quota))];
      this.uniqueSub = [...new Set(this.catArr.map((item:any) => item.party_sub_quota))];
      this.uniquePro = [...new Set(this.catArr.map((item:any) => item.property_type))];
      this.uniquePay = [...new Set(this.catArr.map((item:any) => item.payment_option))];
      this.tableData = [...this.catArr];
      // this.statusDataArr = resp['data']
      // this.dataSource1 = new MatTableDataSource(arr);
      // this.dataSource1.sort = this.sortCol2;
      // this.dataSource1.paginator = this.paginator1;
      this.getCatStatus();
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire(this.auth.allLableShowObjProp[this.auth.language_cd + "Booklet_purchase15"],this.auth.allLableShowObjProp[this.auth.language_cd + "Booklet_purchase16"],"error");

    }
  }

  async getCatStatus() {
    this.spinner.show();
    let category = this.obj1["catstatus"];
    let subCategpry = this.obj1["substatus"];
    let property = this.obj1["prostatus"];
    let payment = this.obj1["paystatus"];
    // console.log(this.obj1);
    this.tableData = [...this.catArr].filter((d) => {
      let A = d["party_quota"];
      let B = d["party_sub_quota"];
      let C = d["property_type"];
      let E = d["payment_option"];

      if (!category && !subCategpry && !property && !payment) {
        return d;
      } else if (category == A && !subCategpry && !property && !payment) {
        return d;
      } else if (category == A && B == subCategpry && !property && !payment) {
        return d;
      } else if (category == A && !subCategpry && C == property && !payment) {
        return d;
      } else if (category == A && !subCategpry && !property && E == payment) {
        return d;
      } else if (
        category == A &&
        B == subCategpry &&
        C == property &&
        !payment
      ) {
        return d;
      } else if (
        category == A &&
        B == subCategpry &&
        !property &&
        E == payment
      ) {
        return d;
      } else if (
        category == A &&
        !subCategpry &&
        C == property &&
        E == payment
      ) {
        return d;
      } else if (!category && B == subCategpry && !property && !payment) {
        return d;
      } else if (!category && B == subCategpry && C == property && !payment) {
        return d;
      } else if (!category && B == subCategpry && !property && E == payment) {
        return d;
      } else if (
        !category &&
        B == subCategpry &&
        C == property &&
        E == payment
      ) {
        return d;
      } else if (!category && !subCategpry && C == property && !payment) {
        return d;
      } else if (!category && !subCategpry && C == property && E == payment) {
        return d;
      } else if (!category && !subCategpry && !property && E == payment) {
        return d;
      } else if (
        category == A &&
        B == subCategpry &&
        C == property &&
        E == payment
      ) {
        return d;
      }
      this.spinner.hide();

      // if (!this.obj1['catstatus'] && !this.obj1['substatus'] && !this.obj1['prostatus'] && !this.obj1['paystatus']) {
      //   return d;
      // } else if (d['party_quota'] == this.obj1['catstatus'] && !this.obj1['substatus'] && !this.obj1['prostatus'] && !this.obj1['paystatus']) {
      //   return d;
      // } else if (!this.obj1['catstatus'] && d['party_sub_quota'] == this.obj1['substatus'] && !this.obj1['prostatus'] && !this.obj1['paystatus']) {
      //   return d;
      // } else if (!this.obj1['catstatus'] && !this.obj1['substatus'] && d['property_type'] == this.obj1['prostatus'] && !this.obj1['paystatus']) {
      //   return d;
      // } else if (!this.obj1['catstatus'] && !this.obj1['substatus'] && !this.obj1['prostatus'] && d['payment_option'] ==this.obj1['paystatus']) {
      //   return d;
      // }
      //  else if (d['party_quota'] == this.obj1['catstatus'] && d['party_sub_quota'] == this.obj1['substatus'] && !this.obj1['prostatus'] && !this.obj1['paystatus']) {
      //   return d;
      // } else if (d['party_quota'] == this.obj1['catstatus'] && d['party_sub_quota'] == this.obj1['substatus'] && d['property_type'] == this.obj1['prostatus'] && !this.obj1['paystatus']) {
      //   return d;
      // }else if (d['party_quota'] == this.obj1['catstatus'] && d['party_sub_quota'] == this.obj1['substatus'] && d['property_type'] == this.obj1['prostatus'] && d['payment_option'] == this.obj1['paystatus']) {
      //   return d;
      // }
      //  else if (d['party_quota'] == this.obj1['catstatus'] && !this.obj1['substatus'] && d['property_type'] == this.obj1['prostatus'] && !this.obj1['paystatus'] ) {
      //   return d;
      // }
      // else if (d['party_quota'] == this.obj1['catstatus'] && !this.obj1['substatus'] && !this.obj1['prostatus'] && d['payment_option'] == this.obj1['paystatus'] ) {
      //   return d;
      // } else if (!this.obj1['catstatus'] && d['party_sub_quota'] == this.obj1['substatus'] && d['property_type'] == this.obj1['prostatus'] && !this.obj1['payment_option']) {
      //   return d;
      // } else if (!this.obj1['catstatus'] && d['party_sub_quota'] == this.obj1['substatus'] && !this.obj1['prostatus'] && d['payment_option']==this.obj1['payment_option']) {
      //   return d;
      // }
      // if(()||(d['party_quota'] == this.obj1['catstatus'] && !this.obj1['substatus'] && !this.obj1['prostatus'] ) || (d['party_quota'] == this.obj1['catstatus'] && d['party_sub_quota']==this.obj1['substatus'] && !this.obj1['prostatus'])
      // || (d['party_quota'] == this.obj1['catstatus'] && d['party_sub_quota']==this.obj1['substatus'] && d['property_type']==this.obj1['prostatus'])) {
      //   return d;
      // }
    });

    this.dataSource1 = new MatTableDataSource(this.tableData);
    this.dataSource1.sort = this.sortCol2;
    this.dataSource1.paginator = this.paginator1;
    this.spinner.hide();
    console.log(this.tableData);

    // } else {
    //   this.spinner.hide();

    //   Swal.fire(this.auth.allLableShowObjProp[this.auth.language_cd + 'Booklet_purchase15'], this.auth.allLableShowObjProp[this.auth.language_cd + 'Booklet_purchase16'], 'error')

    // }
  }

  // lottery slip property data
  uniqueProArr:any = [];
  tableData1:any = [];
  async getAllTypeProperty() {
    this.spinner.show();

    var obj : any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["offer_cd"] = this.obj1["offer_cd"];
    var resp = await this.sale_service.getAllPropertyByOffer(obj);
    console.log(resp, this.obj1)
    if (resp["error"] == false) {
      this.proTypeArr = resp.data;
      this.uniqueProArr = [...new Set(this.proTypeArr.map((item:any) => item.pro_type))];
      console.log(this.uniqueProArr, 'uniqueProArr')
      this.obj1["property_type"] = null;
      this.tableData1 = [...this.proTypeArr];
      console.log(this.proTypeArr)
      this.getAllProperty();
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire(this.auth.allLableShowObjProp[this.auth.language_cd + "Booklet_purchase15"], this.auth.allLableShowObjProp[this.auth.language_cd + "Booklet_purchase16"], "error");
    }
  }
  async getAllProperty() {
    this.spinner.show();
    this.tableData1 = [...this.proTypeArr].filter((d) => {
      if (!this.obj1["property_type"]) {
        return d;
      } else if (d["pro_type"] == this.obj1["property_type"]) {
        return d;
      }
      console.log(d);
    });

    this.dataSource3 = new MatTableDataSource(this.tableData1);
    this.dataSource3.sort = this.sortCol2;
    this.dataSource3.paginator = this.paginator1;
    this.spinner.hide();

    // } else {
    //   this.spinner.hide();

    //   Swal.fire(this.auth.allLableShowObjProp[this.auth.language_cd + 'Booklet_purchase15'], this.auth.allLableShowObjProp[this.auth.language_cd + 'Booklet_purchase16'], 'error')

    // }
  }

  dataToPrint = [];
  async print1() {
    this.spinner.show();
    let data = [];
    data = this.allApplications;
    var txt =
      this.erpUser["account_name"] +
      "(" +
      this.erpUser["account_short_name"] +
      ")";
     var dd:any = {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        var obj = {
          text: txt + "     Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
        };
        return obj;
      },
      pageOrientation: "landscape",

      pageMargins: [40, 60, 40, 60],
      content: [],
    };
    var header0 = {
      columns: [
        {
          width: "*",
          text: "All Applications",
          bold: true,
          alignment: "center",
        },
      ],
    };
    var header9 = {
      columns: [
        {
          width: "*",
          text: "* Note : This is a computer generated document.",
          bold: true,
          alignment: "left",
        },
      ],
    };
    var header1 = {
      columns: [
        {
          width: "*",
          text: "Offer Name :",
          bold: true,
        },

        {
          width: "*",
          text: this.offer_code_to_value[this.obj1["offer_cd"]],
        },
        {
          width: "*",
          text: " ",
          bold: true,
        },

        {
          width: "*",
          text: "",
        },
      ],
    };

    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    var tbl = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,

      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*", "*", "*"],
        body: [
          [
            "Applicant  ID",
            "Applicant Name",
            "Applied Date",
            "Application Amount",
            "Challan ID",
            "Status",
          ],
        ],
      },
    };
    console.log(data, "data");
    dd.content.push(tbl);
    for (var i = 0; i < data.length; i++) {
      var arr = [];
      arr.push(data[i]["party_id"]);
      arr.push(data[i]["party_name"]);
      arr.push(this.mainService.dateFormatChange(data[i].applied_date));
      arr.push(data[i]["application_amount"]);
      arr.push(data[i]["application_challan_no"]);
      // arr.push(data[i]['application_amount']);
      arr.push(data[i]["arr_status_code"]);
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push(header9);
    this.spinner.hide();
    pdfMake.createPdf(dd).download("allApplictaion");
  }
  //aplicant slip print
  dataToPrint1 = [];
  async printSlip() {
    this.spinner.show();
    let data = [];
    data = this.tableData;
    // var txt =
    //   this.erpUser["account_name"] +
    //   "(" +
    //   this.erpUser["account_short_name"] +
    //   ")";
    //  var dd:any = {
    //   pageSize: "A4",
    //   header: function (currentPage, pageCount) {
    //     var obj = {
    //       text: txt + "     Page No. - " + currentPage,
    //       alignment: "center",
    //       margin: [72, 40],
    //     };
    //     return obj;
    //   },
    //   pageOrientation: "portrait",

    //   pageMargins: [40, 60, 40, 60],
    //   content: [],
    // };
    // var header0 = {
    //   columns: [
    //     {
    //       width: "*",
    //       text: "All Applicant List",
    //       bold: true,
    //       alignment: "center",
    //     },
    //   ],
    // };
    // var header9 = {
    //   columns: [
    //     {
    //       width: "*",
    //       text: "* Note : This is a computer generated document.",
    //       bold: true,
    //       alignment: "left",
    //     },
    //   ],
    // };

    // dd.content.push({
    //   canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
    // });
    // dd.content.push({ text: " " });
    // dd.content.push(header0);
    // dd.content.push({ text: " " });
    // dd.content.push({
    //   canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
    // });
    // dd.content.push({ text: " " });
    // dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });

    // this.dataToPrint1=[{key:'Applicant  ID',value:data[0]['party_id']},
    //                   {key:'Challan ID',value:data[0]['application_challan_no']},
    //                   {key:'Applicant Name',value:data[0]['party_name']}]
    //                   console.log(data)
    // const slipHeader = [];
    // const width=[];
    // const requiredData = data.map((d) => {
    //   slipHeader.push({
    //     text: "APPLICANT SLIP ",
    //     bold: true,
    //     alignment: "center",
    //     fontSize: 15,
    //   });
    //   // width.push('*')
    //   // margin: 6,

    //   return {
    //     // fontSize: 10,
    //     text: [
    //       { text: "Registration No :", bold: true, fontSize: 10 },
    //       { text: d.arr_id + "\n\n", alignment: "right" },
    //       { text: "Challan ID : ", bold: true, fontSize: 10 },
    //       { text: d.application_challan_no + "\n\n", alignment: "right" },
    //       { text: "Applicant Name : ", bold: true, fontSize: 10 },
    //       { text: d.party_name + "\n\n", alignment: "right" },
    //       { text: "Father/Husband Name : ", bold: true, fontSize: 10 },
    //       { text: d.party_father_or_husband_name + "\n\n", alignment: "right" },
    //       { text: "Category : ", bold: true, fontSize: 10 },
    //       { text: d.party_quota + "\n\n", alignment: "right" },
    //       { text: "Sub Category Name : ", bold: true, fontSize: 10 },
    //       { text: d.party_sub_quota + "\n\n", alignment: "right" },
    //       { text: "Property Type: ", bold: true, fontSize: 10 },
    //       { text: d.property_type + "\n\n", alignment: "right" },
    //     ],
    //   };
    // });
    // const tableH = data.map

    // var tbl = {
    //   //layout: 'lightHorizontalLines',
    //   fontSize: 10,
    //   table: {
    //     // headerRows:'1',
    //     widths: ["*", "*", "*"],
    //     body: [slipHeader, requiredData],
    //   },
    //   // table: {

    //   //   headerRows: 1,
    //   //   widths: ['*','*'],
    //   //   body: [[this.dataToPrint1[0]["key"],this.dataToPrint1[0]["value"]]],
    //   //   // widths: ['*', '*', '*', '*', '*', '*'],
    //   //   // body: [
    //   //   //   ['Applicant  ID', 'Challan ID ', 'Applicant Name', 'Category', 'Sub Category', 'Property Type']
    //   //   // ],
    //   // }
    // };
    // console.log(data, "data");
    // dd.content.push(tbl);
    // for (var i = 0; i < this.dataToPrint1.length; i++) {
    //   var arr = []
    //   // arr.push(data[i]['party_id']);
    //   // arr.push(data[i]['application_challan_no']);
    //   // arr.push(data[i]['party_name']);
    //   // arr.push(data[i]['party_quota']);
    //   // arr.push(data[i]['party_sub_quota']);

    //   // arr.push(data[i]['application_amount']);
    //   // arr.push(data[i]['property_type']);
    //   dd.content[dd.content.length - 1].table.body.push(arr);
    // }
    // priyanshu slip
    // const slipHeader = [];
    // let requiredData  = [];
    // let requiredData1  = [];

    //   slipHeader.push({
    //     text: "APPLICANT SLIP ",
    //     bold: true,
    //     alignment: "center",
    //     fontSize: 15,
    //   });
    // let itr=0;

    // for(let i =0; i<data.length; i++){

    //   requiredData  = [];    requiredData1  = [];

    //   if(data[itr] && data[itr + 1] && data[itr + 2]){
    //     // for(let i=itr; i<itr+3; itr++){
    //       requiredData.push(

    //         {

    //         text: [
    //           { text: "Registration No z :", bold: true, fontSize: 10 },
    //           // { text: data[itr].arr_id + "\n\n", alignment: "right" },
    //           // { text: "Challan ID : ", bold: true, fontSize: 10 },
    //           // { text: data[itr].application_challan_no + "\n\n", alignment: "right" },
    //           // { text: "Applicant Name : ", bold: true, fontSize: 10 },
    //           // { text: data[itr].party_name + "\n\n", alignment: "right" },
    //           // { text: "Father/Husband Name : ", bold: true, fontSize: 10 },
    //           // { text: data[itr].party_father_or_husband_name + "\n\n", alignment: "right" },
    //           // { text: "Category : ", bold: true, fontSize: 10 },
    //           // { text: data[itr].party_quota + "\n\n", alignment: "right" },
    //           // { text: "Sub Category Name : ", bold: true, fontSize: 10 },
    //           // { text: data[itr].party_sub_quota + "\n\n", alignment: "right" },
    //           // { text: "Property Type: ", bold: true, fontSize: 10 },
    //           // { text: data[itr].property_type + "\n\n", alignment: "right" },
    //         ]
    //       });
    //     // }
    //     //
    //     var tbl = {
    //       //layout: 'lightHorizontalLines',
    //       fontSize: 10,
    //       table: {
    //         // headerRows:'1',
    //         widths: ["*", "*", "*"],
    //         body: [slipHeader, requiredData],
    //       },

    //     };

    //     dd.content.push({ text: " " });
    //     dd.content.push(tbl);
    //     dd.content.push({ text: " " });
    //     itr= itr+3;

    //   }else if(data[itr] && data[itr + 1]){
    //     // for(let i=itr; i<itr+2; itr++){
    //       requiredData.push({
    //         text: [
    //           { text: "Registration No y :", bold: true, fontSize: 10 },
    //           { text: data[itr].arr_id + "\n\n", alignment: "right" },
    //           { text: "Challan ID : ", bold: true, fontSize: 10 },
    //           { text: data[itr].application_challan_no + "\n\n", alignment: "right" },
    //           { text: "Applicant Name : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_name + "\n\n", alignment: "right" },
    //           { text: "Father/Husband Name : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_father_or_husband_name + "\n\n", alignment: "right" },
    //           { text: "Category : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_quota + "\n\n", alignment: "right" },
    //           { text: "Sub Category Name : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_sub_quota + "\n\n", alignment: "right" },
    //           { text: "Property Type: ", bold: true, fontSize: 10 },
    //           { text: data[itr].property_type + "\n\n", alignment: "right" },
    //         ]
    //       });
    //       //
    //       requiredData1.push({
    //         text: [
    //           { text: "Registration No y :", bold: true, fontSize: 10 },
    //           { text: data[itr].arr_id + "\n\n", alignment: "right" },
    //           { text: "Challan ID : ", bold: true, fontSize: 10 },
    //           { text: data[itr].application_challan_no + "\n\n", alignment: "right" },
    //           { text: "Applicant Name : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_name + "\n\n", alignment: "right" },
    //           { text: "Father/Husband Name : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_father_or_husband_name + "\n\n", alignment: "right" },
    //           { text: "Category : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_quota + "\n\n", alignment: "right" },
    //           { text: "Sub Category Name : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_sub_quota + "\n\n", alignment: "right" },
    //           { text: "Property Type: ", bold: true, fontSize: 10 },
    //           { text: data[itr].property_type + "\n\n", alignment: "right" },
    //         ]
    //       });
    //     // }
    //     //
    //     var tbl = {
    //       //layout: 'lightHorizontalLines',
    //       fontSize: 10,
    //       table: {
    //         // headerRows:'1',
    //         widths: ["*", "*", "*"],
    //         body: [slipHeader, requiredData,slipHeader, requiredData1],
    //       },

    //     };

    //     dd.content.push({ text: " " });
    //     dd.content.push(tbl);
    //     dd.content.push({ text: " " });
    //     itr= itr+2;

    //   }else if(data[itr]){
    //     // for(let i=itr; i<itr+1; itr++){
    //       requiredData.push({
    //         text: [
    //           { text: "Registration No  :", bold: true, fontSize: 10 },
    //           { text: data[itr].arr_id + "\n\n", alignment: "right" },
    //           { text: "Challan ID : ", bold: true, fontSize: 10 },
    //           { text: data[itr].application_challan_no + "\n\n", alignment: "right" },
    //           { text: "Applicant Name : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_name + "\n\n", alignment: "right" },
    //           { text: "Father/Husband Name : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_father_or_husband_name + "\n\n", alignment: "right" },
    //           { text: "Category : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_quota + "\n\n", alignment: "right" },
    //           { text: "Sub Category Name : ", bold: true, fontSize: 10 },
    //           { text: data[itr].party_sub_quota + "\n\n", alignment: "right" },
    //           { text: "Property Type: ", bold: true, fontSize: 10 },
    //           { text: data[itr].property_type + "\n\n", alignment: "right" },
    //         ]
    //       });
    //     // }
    //     //
    //     var tbl = {
    //       //layout: 'lightHorizontalLines',
    //       fontSize: 10,
    //       table: {
    //         // headerRows:'1',
    //         widths: ["*", "*", "*"],
    //         body: [slipHeader, requiredData],
    //       },

    //     };

    //     dd.content.push({ text: " " });
    //     dd.content.push(tbl);
    //     dd.content.push({ text: " " });
    //     itr= itr+1;
    //   }

    //   // requiredData.push({
    //   //   text: [
    //   //     { text: "Registration No :", bold: true, fontSize: 10 },
    //   //     // { text: d.arr_id + "\n\n", alignment: "right" },
    //   //     { text: "Challan ID : ", bold: true, fontSize: 10 },
    //   //     // { text: d.application_challan_no + "\n\n", alignment: "right" },
    //   //     { text: "Applicant Name : ", bold: true, fontSize: 10 },
    //   //     // { text: d.party_name + "\n\n", alignment: "right" },
    //   //     { text: "Father/Husband Name : ", bold: true, fontSize: 10 },
    //   //     // { text: d.party_father_or_husband_name + "\n\n", alignment: "right" },
    //   //     { text: "Category : ", bold: true, fontSize: 10 },
    //   //     // { text: d.party_quota + "\n\n", alignment: "right" },
    //   //     { text: "Sub Category Name : ", bold: true, fontSize: 10 },
    //   //     // { text: d.party_sub_quota + "\n\n", alignment: "right" },
    //   //     { text: "Property Type: ", bold: true, fontSize: 10 },
    //   //     // { text: d.property_type + "\n\n", alignment: "right" },
    //   //   ]
    //   // });

    //   // var tbl = {
    //   //   //layout: 'lightHorizontalLines',
    //   //   fontSize: 10,
    //   //   table: {
    //   //     // headerRows:'1',
    //   //     widths: ["*", "*", "*"],
    //   //     body: [slipHeader, requiredData],
    //   //   },

    //   // };

    //   // dd.content.push({ text: " " });
    //   // dd.content.push(tbl);
    //   // dd.content.push({ text: " " });

    // }

    // dd.content.push({ text: " " });
    // dd.content.push(header9);
    // this.spinner.hide();
    // pdfMake.createPdf(dd).download("allApplicantsliplist");

    this.tableData;
     var dd:any = {
      content: [

      ],

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
          alignment: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        alignment: "justify",
      },
    };
    let i = 0;
    for (let index = 0; index < this.tableData.length; index++) {
      if (this.tableData[i] && this.tableData[i + 1] && this.tableData[i + 2]) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 0,
              text: "",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Alpplicant SLIP 1.1"],
                    // ["Registration No: " + this.tableData[i]["arr_id"]],
                    // ["Challan ID : " + this.tableData[i]["application_challan_no"]],
                    // ["Applicant Name : " + this.tableData1[i]["party_name"]],
                    // [
                    //   "Father/Husband Name : " +
                    //     this.tableData1[i]["party_father_or_husband_name"],
                    // ],
                    // ["Category : " + this.tableData1[i]["party_quota"]],
                    // ["Sub Category Name : " + this.tableData1[i]["party_sub_quota"]],
                    // ["Property Type : " + this.tableData1[i]["property_type"]],
                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Alpplicant SLIP 1.2 "],
                    // ["Registration No: " + this.tableData[i+1]["arr_id"]],
                    // ["Challan ID : " + this.tableData[i+1]["application_challan_no"]],
                    // ["Applicant Name : " + this.tableData1[i+1]["party_name"]],
                    // [
                    //   "Father/Husband Name : " +
                    //     this.tableData1[i+1]["party_father_or_husband_name"],
                    // ],
                    // ["Category : " + this.tableData1[i+1]["party_quota"]],
                    // ["Sub Category Name : " + this.tableData1[i+1]["party_sub_quota"]],
                    // ["Property Type : " + this.tableData1[i+1]["property_type"]],
                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Alpplicant SLIP 1.3 "],
                    // ["Registration No: " + this.tableData[i + 2]["arr_id"]],
                    // ["Challan ID : " + this.tableData[i + 2]["application_challan_no"]],
                    // ["Property Type : " + this.tableData[i + 2]["pro_type"]],
                    // [
                    //   "Property Number : " +
                    //     this.tableData[i + 2]["assigned_property_number"],
                    // ],
                    // ["Area : " + this.tableData[i + 2]["area"]],
                    // ["Unit : " + this.tableData[i + 2]["unit"]],
                  ],
                },
              },
            ],
          });
        i = i + 3;
      } else if (this.tableData[i] && this.tableData[i + 1]) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 10,
              text: " ",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Alpplicant SLIP 2.1 "],
                    // ["Registration No: " + this.tableData[i]["arr_id"]],
                    // ["Challan ID : " + this.tableData[i]["application_challan_no"]],
                    // ["Applicant Name : " + this.tableData1[i]["party_name"]],
                    // [
                    //   "Father/Husband Name : " +
                    //     this.tableData1[i]["party_father_or_husband_name"],
                    // ],
                    // ["Category : " + this.tableData1[i]["party_quota"]],
                    // ["Sub Category Name : " + this.tableData1[i]["party_sub_quota"]],
                    // ["Property Type : " + this.tableData1[i]["property_type"]],
                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Alpplicant SLIP 2.2 "],
                    // ["Registration No: " + this.tableData[i + 1]["arr_id"]],
                    // ["Challan ID : " + this.tableData[i  + 1]["application_challan_no"]],
                    // ["Applicant Name : " + this.tableData1[i]["party_name"]],
                    // [
                    //   "Father/Husband Name : " +
                    //     this.tableData1[i + 1]["party_father_or_husband_name"],
                    // ],
                    // ["Category : " + this.tableData1[i + 1]["party_quota"]],
                    // ["Sub Category Name : " + this.tableData1[i + 1]["party_sub_quota"]],
                    // ["Property Type : " + this.tableData1[i + 1]["property_type"]],
                  ],
                },
              },
            ],
          });
        i = i + 2;
      } else if (this.tableData1[i]) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 0,
              text: "     ",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: "*",
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Alpplicant SLIP 3"],
                    // ["Registration No: " + this.tableData[i ]["arr_id"]],
                    // ["Challan ID : " + this.tableData[i ]["application_challan_no"]],
                    // ["Applicant Name : " + this.tableData1[i]["party_name"]],
                    // [
                    //   "Father/Husband Name : " +
                    //     this.tableData1[i]["party_father_or_husband_name"],
                    // ],
                    // ["Category : " + this.tableData1[i]["party_quota"]],
                    // ["Sub Category Name : " + this.tableData1[i]["party_sub_quota"]],
                    // ["Property Type : " + this.tableData1[i]["property_type"]],
                  ],
                },
              },
            ],
          });
        i = i + 1;
      }
    }

    pdfMake.createPdf(dd).download("APPLICANT SLIP");
  }
  //property slip print
  dataToPrint2 = [];

  // async printPropSlip() {
  //   this.spinner.show();
  //   let data = [];
  //   data = this.tableData1;
  //   // console.log("test done", this.tableData1)
  //   var txt =
  //     this.erpUser["account_name"] +
  //     "(" +
  //     this.erpUser["account_short_name"] +
  //     ")";
  //    var dd:any = {
  //     pageSize: "A4",
  //     header: function (currentPage, pageCount) {
  //       var obj = {
  //         text: txt + "     Page No. - " + currentPage,
  //         alignment: "center",
  //         margin: [72, 40],
  //       };
  //       return obj;
  //     },
  //     pageOrientation: "portrait",

  //     pageMargins: [40, 80, 40, 60],
  //     content: [],
  //   };
  //   var header0 = {
  //     columns: [
  //       {
  //         width: "*",
  //         text: "All Property List",
  //         bold: true,
  //         alignment: "center",
  //       },
  //     ],
  //   };
  //   var header9 = {
  //     columns: [
  //       {
  //         width: "*",
  //         text: "* Note : This is a computer generated document.",
  //         bold: true,
  //         alignment: "left",
  //       },
  //     ],
  //   };

  //   dd.content.push({
  //     canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  //   });
  //   dd.content.push({ text: " " });
  //   dd.content.push(header0);
  //   dd.content.push({ text: " " });
  //   dd.content.push({
  //     canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  //   });
  //   dd.content.push({ text: " " });
  //   // dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });

  //   const slipHeader = [];
  //   // const width=[];
  //   const requiredData = data.map((d) => {
  //     slipHeader.push({
  //       text: "Property SLIP ",
  //       bold: true,
  //       alignment: "center",
  //       fontSize: 15,
  //     });
  //     //  width.push('*','*','*')
  //     return {
  //       text: [
  //         { text: "Property Id :", bold: true, fontSize: 10 },
  //         { text: d.property_id + "\n\n", alignment: "right" },
  //         { text: "Offer Code  : ", bold: true, fontSize: 10 },
  //         { text: d.offer_cd + "\n\n", alignment: "right" },
  //         { text: "Property Type : ", bold: true, fontSize: 10 },
  //         { text: d.pro_type + "\n\n", alignment: "right" },
  //         { text: "Property Number : ", bold: true, fontSize: 10 },
  //         { text: d.assigned_property_number + "\n\n", alignment: "right" },
  //         { text: "Area : ", bold: true, fontSize: 10 },
  //         { text: d.area + "\n\n", alignment: "right" },
  //         { text: "Unit : ", bold: true, fontSize: 10 },
  //         { text: d.unit + "\n\n", alignment: "right" },
  //       ],
  //     };
  //   });

  //   var tbl = {
  //     // pageBreak: 'after',
  //     // margin: [0, 0, 0, 8],
  //     fontSize: 10,
  //     table: {
  //       headerRows: 1,
  //       widths: ["*", "*", "*"],
  //       lineBreak: "after",
  //       body: [
  //         slipHeader,
  //         requiredData,
  //         //     // ['property  ID', 'Offer Code', 'Property Type', 'Property Number', 'Area', 'Unit']
  //       ],
  //     },
  //   };
  //   console.log(data, "data");
  //   dd.content.push(tbl);
  //   // dd.content[dd.content.length - 1].push(table);
  //   // for (var i = 0; i < data.length; i++) {
  //   //   var arr = []
  //   //   arr.push(data[i]['property_id']);
  //   //   arr.push(data[i]['offer_cd']);
  //   //   arr.push(data[i]['pro_type']);
  //   //   arr.push(data[i]['assigned_property_number']);
  //   //   arr.push(data[i]['area']);
  //   //   arr.push(data[i]['unit']);
  //   // arr.push(data[i]['life_cycle_status']);
  //   //  dd.content[dd.content.length - 1].table.body.push(arr);
  //   // }
  //   dd.content.push({ text: " " });
  //   dd.content.push(header9);
  //   this.spinner.hide();
  //   pdfMake.createPdf(dd).download("all_property_list");
  //   this.pdf();
  // }

  async getApplications() {
    this.spinner.show();
    // this.Obj['reason']=
    this.Obj["reason"] = "";
    var obj : any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["offer_cd"] = this.obj1["offer_cd"];
    console.log(obj);
    var resp = await this.applicationService.getAllApplications(obj);
    console.log(resp);
    if (resp["error"] == false) {
      console.log(resp["data"]);
      this.allApplications = resp.data;
      this.dataSource = new MatTableDataSource(this.allApplications);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    } else {
      this.spinner.hide();

      Swal.fire(
        this.auth.allLableShowObjProp[
        this.auth.language_cd + "Booklet_purchase15"
        ],
        this.auth.allLableShowObjProp[
        this.auth.language_cd + "Booklet_purchase16"
        ],
        "error"
      );
    }
  }

  async reject(element:any, i:any) {
    $("#myModal1").modal("show");
    if (this.Obj["reason"] != "") {
      var obj : any= new Object();
      obj["msg"] =
        "Your Application number " +
        element.party_id +
        " having  some inforamtion issue .That's why your application is being  REJECTED . please go through the below given link and reasion is : " +
        this.Obj["reason"];

      obj["b_acct_id"] = this.b_acct_id;
      obj["id"] = element.id;
      obj["arr_status_code"] = "APPLICATION_REJECTED";
      this.spinner.show();
      var resp = await this.applicationService.changeApplicationStatus(obj);
      if (resp["error"] == false) {
        await this.getApplications();

        this.spinner.hide();
        // this.snackBar.open("Rejected Successfully", 'Success', {
        //   duration: 5000,
        // });
        Swal.fire(
          this.auth.allLableShowObjProp[
          this.auth.language_cd + "Booklet_purchase19"
          ],
          this.auth.allLableShowObjProp[
          this.auth.language_cd + "Booklet_purchase18"
          ],
          "success"
        );

        // obj['msg']="Your Application number "+ element.party_id +" having  some inforamtion issue .That's why your application is being  REJECTED . please go through the below given link and reasion is : "+this.Obj['reason']
        obj["url"] = this.mainService.uiUrl + "?acct=" + this.b_acct_id;
        obj["subject"] = "Application Status";
        obj["reciever_mail"] = element.party_email;
        obj["account"] = this.erpUser["account_name"];

        var resp2 = await this.metadataService.SendEmail(obj);
        if (resp2["error"] != false) {
          this.snackBar.open("Error while Sending mail", "Error", {
            duration: 2000,
          });
        }
      } else {
        this.spinner.hide();
        // this.snackBar.open("Request Failed", 'Error', {
        //   duration: 5000,
        // });
        Swal.fire(
          this.auth.allLableShowObjProp[
          this.auth.language_cd + "Booklet_purchase15"
          ],
          this.auth.allLableShowObjProp[
          this.auth.language_cd + "Booklet_purchase16"
          ],
          "error"
        );
      }
    } else {
      Swal.fire("Information", "Please Give The Reason", "info");
      this.globleElement["element"] = element;
      this.globleElement["i"] = i;
    }
  }
  // APPLICATION_APPROVAL_PENDING
  async refill(element:any, i:any) {
    $("#myModal3").modal("show");
    if (this.Obj["reason"] != "") {
      var obj : any= new Object();
      obj["msg"] =
        "Your Application number " +
        element.party_id +
        " having some inforamtion issue please go through the below given link and reasion is : " +
        this.Obj["reason"];

      obj["b_acct_id"] = this.b_acct_id;
      obj["id"] = element.id;
      obj["arr_status_code"] = "APPLICATION_REFILL";
      this.spinner.show();
      var resp = await this.applicationService.changeApplicationStatus(obj);
      if (resp["error"] == false) {
        await this.getApplications();
        this.spinner.hide();
        // this.snackBar.open("Refill Successfully", 'Success', {
        //   duration: 5000,
        // });
        Swal.fire(
          this.auth.allLableShowObjProp[
          this.auth.language_cd + "Booklet_purchase19"
          ],
          this.auth.allLableShowObjProp[
          this.auth.language_cd + "Booklet_purchase18"
          ],
          "success"
        );

        // obj['msg']="Your Application number "+ element.party_id +" having some inforamtion issue please go through the below given link and reasion is : "+this.Obj['reason']
        obj["url"] = this.mainService.uiUrl + "?acct=" + this.b_acct_id;
        obj["subject"] = "Application Status";
        obj["reciever_mail"] = element.party_email;
        obj["account"] = this.erpUser["account_name"];

        var resp2 = await this.metadataService.SendEmail(obj);
        if (resp2["error"] != false) {
          this.snackBar.open("Error while Sending mail", "Error", {
            duration: 2000,
          });
        }
      } else {
        this.spinner.hide();
        Swal.fire(
          this.auth.allLableShowObjProp[
          this.auth.language_cd + "Booklet_purchase15"
          ],
          this.auth.allLableShowObjProp[this.auth.language_cd + "Applicant56"],
          "error"
        );
      }
    } else {
      Swal.fire("Information", "Please Give The Reason", "info");
      this.globleElement["element"] = element;
      this.globleElement["i"] = i;
    }
  }

    i :any
  async approve(element:any, i:any) {
    var obj : any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["id"] = element.id;
    obj["arr_status_code"] = "APPLICATION_APPROVED";
    this.spinner.show();
    var resp = await this.applicationService.changeApplicationStatus(obj);
    if (resp["error"] == false) {
      // await this.sedtToAccount(element)
      await this.getApplications();
      this.spinner.hide();
      Swal.fire("Success...", "Approved Successfully", "success");
      obj["msg"] =
        "Your have been successfully applied for the property in " +
        element["offer_name"] +
        " . And now we are waiting for payment. Please complete all the formality. You will get all the updates related to this over a time.";
      obj["url"] = this.mainService.uiUrl + "?acct=" + this.b_acct_id;
      obj["subject"] = "Application Status";
      obj["reciever_mail"] = element.party_email;
      obj["account"] = this.erpUser["account_name"];
      var resp2 = await this.metadataService.SendEmail(obj);
      if (resp2["error"] != false) {
        this.snackBar.open("Error while Sending mail", "Error", {
          duration: 2000,
        });
      }
    } else {
      this.spinner.hide();
      // this.snackBar.open("Request Failed", 'Error', {
      //   duration: 5000,
      // });
      Swal.fire(
        this.auth.allLableShowObjProp[
        this.auth.language_cd + "Booklet_purchase15"
        ],
        this.auth.allLableShowObjProp[this.auth.language_cd + "Applicant56"],
        "error"
      );
    }
  }
  async sedtToAccount(data:any) {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let objToSend:any = {};
     let obj :any= {};
    obj["evt_grp_dt"] = date;
    obj["bus_event_type"] = "APPLICATION";
    obj["demand_id"] = 1;
    obj["party_id"] = data["party_id"];
    obj["event_code"] = "PROPERTY" + data["offer_cd"];
    obj["event_id"] = data["id"];
    obj["event_ln_id"] = data["id"];
    obj["bank_acct_num"] = "";
    obj["event_desc"] = data["offer_desc"];
    obj["txn_amt"] = data["application_amount"];
    obj["invoice_id"] = data["id"];
    obj["create_user_id"] = this.erpUser["user_id"];
    obj["arr_num"] = data["id"];
    let arr:any = [];
    objToSend["data"] = arr;
    objToSend["local_doc_no"] = data["offer_cd"];
    objToSend["local_doc_desc"] = "Application Of " + data["offer_desc"];
    objToSend["is_rec_or_pay"] = "RECEIVABLE";
    objToSend["module_cd"] = "PROPERTY";
    objToSend["module_doc_type"] = "APPLICATION";
    objToSend["status"] = "APPROVED";
    objToSend["total_amt"] = data["application_amount"];
    objToSend["doc_type"] = "CHALLAN";
    objToSend["req_data"] = "{}";
    objToSend["create_user_id"] = this.erpUser["user_id"];
    objToSend["b_acct_id"] = this.b_acct_id;
    objToSend["user_id"] = this.erpUser.user_id;
    arr.push(obj);
    console.log(objToSend, data);
    let resp = await this.sale_service.sendToAccount(objToSend);
    console.log(resp, "sendtoAccount");
    if (resp["error"] == false) {
      console.log("Sent to Account");
    } else {
      Swal.fire("Error", "Error While Creating", "error");
    }
  }

  async changeStatus() {
    var arr = [];

    for (let i = 0; i < this.allApplications.length; i++) {
      if (this.allApplications[i]["arr_status_code"] == this.status) {
        arr.push(this.allApplications[i]);
      }
    }
    this.statusDataArr = arr;
    this.dataSource1 = new MatTableDataSource(this.statusDataArr);
    this.dataSource1.sort = this.sortCol2;
    this.dataSource1.paginator = this.paginator1;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter1(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }
  print() {
    let printContents:any, popupWin:any;
    printContents = $("#p").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
        </head>
        <style>
        #tbl {
          font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
          border-collapse: collapse;
          width: 100%;
          max-width: 2480px;
      }

      #tbl td,
      #tbl th {
          border: 1px solid #ddd;
          padding: 8px;
          width: auto;
          overflow: hidden;
          word-wrap: break-word;
      }

      #tbl tr:nth-child(even) {
          background-color: #f2f2f2;
      }

      #tbl tr:hover {
          background-color: #ddd;
      }

      #tbl th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: rgb(63, 24, 233);
          color: white;
      }


    table {
        width: 100%;
    }


        </style>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }
  async print2() {
    let ob:any = {};
    for (let i = 0; i < this.schemeArr.length; i++) {
      if (this.schemeArr[i]["scheme_code"] == this.obj1["scheme_code"]) {
        ob["scheme_name"] = this.schemeArr[i]["scheme_name"];
      }
    }
    for (let i = 0; i < this.allSubSchemes1.length; i++) {
      if (
        this.allSubSchemes1[i]["sub_scheme_code"] ==
        this.obj1["sub_scheme_code"]
      ) {
        ob["sub_scheme_name"] = this.allSubSchemes1[i]["sub_scheme_name"];
      }
    }
    let data = [];
    var txt =
      this.mainService.accInfo["account_name"] +
      "(" +
      this.mainService.accInfo["account_short_name"] +
      ")";
     var dd:any = {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        var obj = {
          text: txt + "     Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
        };
        return obj;
      },

      pageOrientation: "landscape",

      pageMargins: [40, 60, 40, 60],
      content: [],
    };
    var header0 = {
      columns: [
        {
          width: "*",
          text: "Applcation",
          bold: true,
          alignment: "center",
        },
      ],
    };
    var header1 = {
      columns: [
        {
          width: "*",
          text: "Scheme :",
          bold: true,
        },

        {
          width: "*",
          text: ob["scheme_name"],
        },
        {
          width: "*",
          text: "Sub Scheme :",
          bold: true,
        },

        {
          width: "*",
          text: ob["sub_scheme_name"],
        },
      ],
    };
    var header2 = {
      columns: [
        {
          width: "*",
          text: "Status :",
          bold: true,
        },
        {
          width: "*",
          text: this.obj1["status"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },

        {
          width: "*",
          text: "",
        },
      ],
    };

    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header2);
    dd.content.push({ text: " " });

    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    var tbl = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*", "*"],
        body: [
          [
            "Application ID",
            "Applicant Name",
            "Applied Date",
            { text: "Application Amount", alignment: "right" },
            "Challan ID",
          ],
        ],
      },
    };
    dd.content.push(tbl);
    for (var i = 0; i < this.statusDataArr.length; i++) {
      var arr = [];
      arr.push(this.statusDataArr[i]["party_id"]);
      arr.push(this.statusDataArr[i]["party_name"]);
      arr.push({
        text: this.mainService.dateFormatChange(
          this.statusDataArr[i]["applied_date"]
        ),
        alignment: "left",
      });
      arr.push({
        text: this.statusDataArr[i]["application_amount"],
        alignment: "right",
      });
      arr.push({
        text: this.statusDataArr[i]["application_challan_no"],
        alignment: "left",
      });

      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    this.spinner.hide();
    pdfMake.createPdf(dd).download("application");
  }
  allUploadedDoc = [];
  async viewDocuments(element:any, i:any) {
    // this.fileURL = ''
    // this.viewDocumentObj = element
    this.allUploadedDoc = [];
     let obj :any= {};
    this.spinner.show();
    obj["b_acct_id"] = this.b_acct_id;
    obj["party_id"] = element["party_id"];
    console.log(obj, element);
    let resp = await this.sale_service.getAllPartyUploadedDocuments(
      JSON.stringify(obj)
    );
    console.log(resp, "response");
    if (resp["error"] == false) {
      this.spinner.hide()
      if (resp['data'] < 1) {
        Swal.fire('Info', 'Document Not Uploaded!', 'info')
      } else {
        this.allUploadedDoc = resp["data"];
        $("#myModal2").modal("show");
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Some Error Occured", "error");
    }
  }
  async view_doc(Obj:any) {
    console.log(Obj, "Obj");
    if (Obj["doc_id"]) {
      this.spinner.show();
       let obj :any= {};
      obj["b_acct_id"] = this.b_acct_id;
      obj["doc_id"] = Obj["doc_id"];
      obj["doc_name"] = Obj["file_name"];
      console.log(obj);
      var res = await this.sale_service.view_Doc(obj);
      //console.log(res)
      if (res) {
        var docname = Obj["file_name"];
        ////console.log(this.element, 'this.element')
        var ext = docname.split(".");
        let a = document.createElement("a"),
          file;
        if (
          ext[1].toLowerCase() == "png" ||
          ext[1].toLowerCase() == "jpeg" ||
          ext[1].toLowerCase() == "jpg" ||
          ext[1].toLowerCase() == ""
        ) {
          const unsafeImageUrl = window.URL.createObjectURL(res);
          this.fileURL =
            this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
          console.log("first");
        } else {
          let file = new Blob([res], { type: "application/pdf/image" });
          // var fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          a.href = window.URL.createObjectURL(file);
          a.download = Obj["file_name"];
          a.click();
        }
        this.spinner.hide();
      }
    } else {
      Swal.fire("Error...", "Please Upload The File First", "error");
    }
  }

  async viewUploaded_doc(Obj:any) {
    this.spinner.show();
     let obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_id"] = Obj["doc_id"];
    obj["doc_name"] = Obj["doc_name"];
    console.log(obj);
    var res = await this.sale_service.view_Doc(obj);
    console.log(res);
    if (res) {
      var docname = Obj["doc_name"];
      var ext = docname.split(".");
      if (
        ext[1].toLowerCase() == "png" ||
        ext[1].toLowerCase() == "jpeg" ||
        ext[1].toLowerCase() == "jpg"
      ) {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.fileURL =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: "application/pdf/image" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
      this.spinner.hide();
    }
  }
  async view(filename:any) {
    this.spinner.show();
    var obj : any= new Object();
    obj["filename"] = this.viewDocumentObj[filename];
    obj["b_acct_id"] = this.b_acct_id;
    obj["sub_scheme_code"] = this.viewDocumentObj["sub_scheme_code"];
    obj["party_id"] = this.viewDocumentObj["party_id"];
    const res = await this.applicationService.getUploadedFileofparty(obj);
    if (res) {
      // const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      // this.fileURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.spinner.hide();
      var docname = obj["filename"];
      var ext = docname.split(".");

      if (
        ext[1] == "png" ||
        ext[1] == "jpeg" ||
        ext[1] == "jpg" ||
        ext[1] == "PNG" ||
        ext[1] == "JPEG" ||
        ext[1] == "JPG"
      ) {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.fileURL =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    } else {
      this.spinner.hide();
    }
  }

  async viewCoApplicants(element:any, i:any) {
    this.spinner.show();
    this.coApplicantFileURL = "";
    this.partyCoApplicantObj = element;
    var obj : any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["party_id"] = element["party_id"];
    var resp = await this.applicationService.getCoApplicantDetail(obj);
    console.log(resp, "fghdfd");
    if (resp["error"] == false) {
      if (resp.data.length < 1) {
        this.spinner.hide();
        Swal.fire('Info', 'No Co-Applicants Found!', 'info')
      } else {
        this.dataSource2 = new MatTableDataSource(resp.data);
        this.dataSource2.sort = this.sortCol3;
        this.dataSource2.paginator = this.paginator2;
        this.spinner.hide();
        $('.nav-tabs a[href="#tab-4"]').tab("show");
      }
    } else {
      this.spinner.hide();
      this.snackBar.open("Error occured while getting Co-Applications", "Error", { duration: 5000 });
    }
  }

  async viewCoApplicantFile(element:any, filename:any) {
    this.spinner.show();
    console.log(element);
    var obj : any= new Object();
    obj["filename"] = element["co_applicant_photo_file_name"];
    obj["b_acct_id"] = this.b_acct_id;
    obj["offer_cd"] = this.partyCoApplicantObj["offer_cd"];
    obj["id"] = element["id"];
    console.log(obj);
    const res = await this.applicationService.getUploadedFileofcoapplicant(obj);
    if (res) {
      console.log(res, "res");
      // const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      // this.fileURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.spinner.hide();
      var docname = obj["filename"];
      var ext = docname.split(".");
      const unsafeImageUrl = window.URL.createObjectURL(res);
      this.imgURL =
        this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);

      // if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
      //   const unsafeImageUrl = window.URL.createObjectURL(res);
      //   this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      // } else {
      //   const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      //   this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      // }
      console.log(this.imgURL, "imgURL");
    }
  }
  async viewCoApplicantFile2(element:any, filename:any) {
    this.spinner.show();
    console.log(element);
    var obj : any= new Object();
    obj["filename"] = element["co_applicant_sig_file_name"];
    obj["b_acct_id"] = this.b_acct_id;
    obj["offer_cd"] = this.partyCoApplicantObj["offer_cd"];
    obj["id"] = element["id"];
    console.log(obj);
    const res = await this.applicationService.getUploadedFileofcoapplicant(obj);
    if (res) {
      console.log(res, "res");
      this.spinner.hide();
      var docname = obj["filename"];
      var ext = docname.split(".");
      if (
        ext[1] == "png" ||
        ext[1] == "jpeg" ||
        ext[1] == "jpg" ||
        ext[1] == "PNG" ||
        ext[1] == "JPEG" ||
        ext[1] == "JPG"
      ) {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imgURL =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
      console.log(this.imgURL, "imgURL");
    }
  }
  async viewApplicantFile_photo(element:any) {
    if (element['party_photo_file_name'] == null) {
      Swal.fire('Info', 'Photo Not Uploaded!', 'info')
    } else {
      $('#myModal4').modal('show');
      this.spinner.show();
      console.log(element);
      var obj : any= new Object();
      obj["filename"] = element["party_photo_file_name"];
      obj["b_acct_id"] = this.b_acct_id;
      obj["offer_cd"] = this.obj1["offer_cd"];
      obj["party_id"] = element["party_id"];
      console.log(obj);
      const res = await this.applicationService.getUploadedFileofparty(obj);
      if (res) {
        console.log(res, "res");
        this.spinner.hide();
        var docname = obj["filename"];
        var ext = docname.split(".");
        if (
          ext[1] == "png" ||
          ext[1] == "jpeg" ||
          ext[1] == "jpg" ||
          ext[1] == "PNG" ||
          ext[1] == "JPEG" ||
          ext[1] == "JPG"
        ) {
          const unsafeImageUrl = window.URL.createObjectURL(res);
          this.imgURL =
            this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
        } else {
          const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
          this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        }
        console.log(this.imgURL, "imgURL");
      }
    }
  }
  async viewApplicantFile_signature(element:any) {
    if (element['party_signature_file_name'] == null) {
      Swal.fire('Info', 'Signature Not Uploaded!', 'info')
    } else {
      $('#myModal4').modal('show');
      this.spinner.show();
      console.log(element);
      var obj : any= new Object();
      obj["filename"] = element["party_signature_file_name"];
      obj["b_acct_id"] = this.b_acct_id;
      obj["offer_cd"] = this.obj1["offer_cd"];
      obj["party_id"] = element["party_id"];
      console.log(obj);
      const res = await this.applicationService.getUploadedFileofparty(obj);
      if (res) {
        console.log(res, "res");
        this.spinner.hide();
        var docname = obj["filename"];
        var ext = docname.split(".");
        if (
          ext[1] == "png" ||
          ext[1] == "jpeg" ||
          ext[1] == "jpg" ||
          ext[1] == "PNG" ||
          ext[1] == "JPEG" ||
          ext[1] == "JPG"
        ) {
          const unsafeImageUrl = window.URL.createObjectURL(res);
          this.imgURL =
            this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
        } else {
          const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
          this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        }
        console.log(this.imgURL, "imgURL");
      }
    }
  }
  //pdf print try
  pdf() {
    this.tableData1;
     var dd:any = {
      content: [],

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
          alignment: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        alignment: "justify",
      },
    };
    let i = 0;
    for (let index = 0; index < this.tableData1.length; index++) {
      if (
        this.tableData1[i] &&
        this.tableData1[i + 1] &&
        this.tableData1[i + 2]
      ) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 0,
              text: "",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP"],
                    ["Property Id : " + this.tableData1[i]["property_id"]],
                    ["Offer Code : " + this.tableData1[i]["offer_cd"]],
                    ["Property Type : " + this.tableData1[i]["pro_type"]],
                    [
                      "Property Number : " +
                      this.tableData1[i]["assigned_property_number"],
                    ],
                    ["Area : " + this.tableData1[i]["area"]],
                    ["Unit : " + this.tableData1[i]["unit"]],
                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP"],
                    ["Property Id : " + this.tableData1[i + 1]["property_id"]],
                    ["Offer Code : " + this.tableData1[i + 1]["offer_cd"]],
                    ["Property Type : " + this.tableData1[i + 1]["pro_type"]],
                    [
                      "Property Number : " +
                      this.tableData1[i + 1]["assigned_property_number"],
                    ],
                    ["Area : " + this.tableData1[i + 1]["area"]],
                    ["Unit : " + this.tableData1[i + 1]["unit"]],
                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP"],
                    ["Property Id : " + this.tableData1[i + 2]["property_id"]],
                    ["Offer Code : " + this.tableData1[i + 2]["offer_cd"]],
                    ["Property Type : " + this.tableData1[i + 2]["pro_type"]],
                    [
                      "Property Number : " +
                      this.tableData1[i + 2]["assigned_property_number"],
                    ],
                    ["Area : " + this.tableData1[i + 2]["area"]],
                    ["Unit : " + this.tableData1[i + 2]["unit"]],
                  ],
                },
              },
            ],
          });
        i = i + 3;
      } else if (this.tableData1[i] && this.tableData1[i + 1]) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 10,
              text: " ",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP"],
                    ["Property Id : " + this.tableData1[i]["property_id"]],
                    ["Offer Code : " + this.tableData1[i]["offer_cd"]],
                    ["Property Type : " + this.tableData1[i]["pro_type"]],
                    [
                      "Property Number : " +
                      this.tableData1[i]["assigned_property_number"],
                    ],
                    ["Area : " + this.tableData1[i]["area"]],
                    ["Unit : " + this.tableData1[i]["unit"]],
                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP"],
                    ["Property Id : " + this.tableData1[i + 1]["property_id"]],
                    ["Offer Code : " + this.tableData1[i + 1]["offer_cd"]],
                    ["Property Type : " + this.tableData1[i + 1]["pro_type"]],
                    [
                      "Property Number : " +
                      this.tableData1[i + 1]["assigned_property_number"],
                    ],
                    ["Area : " + this.tableData1[i + 1]["area"]],
                    ["Unit : " + this.tableData1[i + 1]["unit"]],
                  ],
                },
              },
            ],
          });
        i = i + 2;
      } else if (this.tableData1[i]) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 0,
              text: "     ",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: "*",
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP"],
                    ["Property Id : " + this.tableData1[i]["property_id"]],
                    ["Offer Code : " + this.tableData1[i]["offer_cd"]],
                    ["Property Type : " + this.tableData1[i]["pro_type"]],
                    [
                      "Property Number : " +
                      this.tableData1[i]["assigned_property_number"],
                    ],
                    ["Area : " + this.tableData1[i]["area"]],
                    ["Unit : " + this.tableData1[i]["unit"]],
                  ],
                },
              },
            ],
          });
        i = i + 1;
      }
    }

    pdfMake.createPdf(dd).download("Property_SLIP");
  }
  // aplicant list responsive
  pdf2() {
    this.tableData;
     var dd:any = {
      content: [],

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
          alignment: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        alignment: "justify",
      },
    };
    let i = 0;
    for (let index = 0; index < this.tableData.length; index++) {
      if (
        this.tableData[i] &&
        this.tableData[i + 1] &&
        this.tableData[i + 2]
      ) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 0,
              text: "",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["APPLICANT SLIP"],
                    ["Registration No : " + this.tableData[i]["arr_id"]],
                    ["Challan No. : " + this.tableData[i]["application_challan_no"]],
                    ["Applicant Name : " + this.tableData[i]["party_name"]],
                    ["Father/Husband Name : " + this.tableData[i]["party_father_or_husband_name"]],
                    ["Category : " + this.tableData[i]["party_quota"]],
                    ["Sub Category Name : " + this.tableData[i]["party_sub_quota"]],
                    ["Property Type : " + this.tableData[i]["property_type"]],


                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["APPLICANT SLIP"],
                    ["Registration No : " + this.tableData[i + 1]["arr_id"],],
                    ["Challan No. : " + this.tableData[i + 1]["application_challan_no"]],
                    ["Applicant Name : " + this.tableData[i + 1]["party_name"]],
                    ["Father/Husband Name : " + this.tableData[i + 1]["party_father_or_husband_name"]],
                    ["Category : " + this.tableData[i + 1]["party_quota"]],
                    ["Sub Category Name : " + this.tableData[i + 1]["party_sub_quota"]],
                    ["Property Type : " + this.tableData[i + 1]["property_type"]],
                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["APPLICANT SLIP"],
                    ["Registration No : " + this.tableData[i + 2]["arr_id"],],
                    ["Challan No. : " + this.tableData[i + 2]["application_challan_no"]],
                    ["Applicant Name : " + this.tableData[i + 2]["party_name"]],
                    ["Father/Husband Name : " + this.tableData[i + 2]["party_father_or_husband_name"]],
                    ["Category : " + this.tableData[i + 2]["party_quota"]],
                    ["Sub Category Name : " + this.tableData[i + 2]["party_sub_quota"]],
                    ["Property Type : " + this.tableData[i + 2]["property_type"]],

                  ],
                },
              },
            ],
          });
        i = i + 3;
      } else if (this.tableData[i] && this.tableData[i + 1]) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 10,
              text: " ",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["APPLICANT SLIP"],
                    ["Registration No : " + this.tableData[i]["arr_id"],],
                    ["Challan No. : " + this.tableData[i]["application_challan_no"]],
                    ["Applicant Name : " + this.tableData[i]["party_name"]],
                    ["Father/Husband Name : " + this.tableData[i]["party_father_or_husband_name"]],
                    ["Category : " + this.tableData[i]["party_quota"]],
                    ["Sub Category Name : " + this.tableData[i]["party_sub_quota"]],
                    ["Property Type : " + this.tableData[i]["property_type"]],
                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["APPLICANT SLIP"],
                    ["Registration No : " + this.tableData[i + 1]["arr_id"],],
                    ["Challan No. : " + this.tableData[i + 1]["application_challan_no"]],
                    ["Applicant Name : " + this.tableData[i + 1]["party_name"]],
                    ["Father/Husband Name : " + this.tableData[i + 1]["party_father_or_husband_name"]],
                    ["Category : " + this.tableData[i + 1]["party_quota"]],
                    ["Sub Category Name : " + this.tableData[i + 1]["party_sub_quota"]],
                    ["Property Type : " + this.tableData[i + 1]["property_type"]],

                  ],
                },
              },
            ],
          });
        i = i + 2;
      } else if (this.tableData[i]) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 0,
              text: "     ",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: "*",
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["APPLICANT SLIP"],
                    ["Registration No : " + this.tableData[i]["arr_id"],],
                    ["Challan No. : " + this.tableData[i]["application_challan_no"]],
                    ["Applicant Name : " + this.tableData[i]["party_name"]],
                    ["Father/Husband Name : " + this.tableData[i]["party_father_or_husband_name"]],
                    ["Category : " + this.tableData[i]["party_quota"]],
                    ["Sub Category Name : " + this.tableData[i]["party_sub_quota"]],
                    ["Property Type : " + this.tableData[i]["property_type"]],

                  ],
                },
              },
            ],
          });
        i = i + 1;
      }
    }

    pdfMake.createPdf(dd).download("APPLICANT_SLIP");
  }

  //applicant list
  applicantpdf() {
    this.tableData;
     var dd:any = {
      content: [],

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
          alignment: "center",
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        alignment: "justify",
      },
    };
    let i = 0;
    for (let index = 0; index < this.tableData.length; index++) {
      if (
        this.tableData[i] &&
        this.tableData[i + 1] &&
        this.tableData[i + 2]

      ) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 0,
              text: "",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP 1.1 "],

                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP 1.2 "],

                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP 1.3 "],

                  ],
                },
              },
            ],
          });
        i = i + 3;
      } else if (this.tableData[i] && this.tableData[i + 1]) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 10,
              text: " ",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP 2.1"],

                  ],
                },
              },
              {
                style: "defaultStyle",
                width: 5,
                text: " ",
              },
              {
                width: 180,
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP 2.2 "],

                  ],
                },
              },
            ],
          });
        i = i + 2;
      } else if (this.tableData[i]) {
        dd.content.push({
          alignment: "justify",
          columns: [
            {
              style: "defaultStyle",
              width: 0,
              text: "     ",
            },
          ],
        }),
          dd.content.push({
            alignment: "justify",
            columns: [
              {
                width: "*",
                style: "defaultStyle",

                table: {
                  headerRows: 1,
                  body: [
                    ["Property SLIP 3"],
                  ],
                },
              },
            ],
          });
        i = i + 1;
      }
    }

    pdfMake.createPdf(dd).download("applicant list");
  }
  //print applicant list
  // async printApplicantSlip() {
  //   this.spinner.show();
  //   let data = [];
  //   data = this.tableData1;
  //   // console.log("test done", this.tableData1)
  //   var txt =
  //     this.erpUser["account_name"] +
  //     "(" +
  //     this.erpUser["account_short_name"] +
  //     ")";
  //    var dd:any = {
  //     pageSize: "A4",
  //     header: function (currentPage, pageCount) {
  //       var obj = {
  //         text: txt + "     Page No. - " + currentPage,
  //         alignment: "center",
  //         margin: [72, 40],
  //       };
  //       return obj;
  //     },
  //     pageOrientation: "portrait",

  //     pageMargins: [40, 80, 40, 60],
  //     content: [],
  //   };
  //   var header0 = {
  //     columns: [
  //       {
  //         width: "*",
  //         text: "All Property List",
  //         bold: true,
  //         alignment: "center",
  //       },
  //     ],
  //   };
  //   var header9 = {
  //     columns: [
  //       {
  //         width: "*",
  //         text: "* Note : This is a computer generated document.",
  //         bold: true,
  //         alignment: "left",
  //       },
  //     ],
  //   };

  //   dd.content.push({
  //     canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  //   });
  //   dd.content.push({ text: " " });
  //   dd.content.push(header0);
  //   dd.content.push({ text: " " });
  //   dd.content.push({
  //     canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  //   });
  //   dd.content.push({ text: " " });
  //   // dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });

  //   const slipHeader = [];
  //   // const width=[];
  //   const requiredData = data.map((d) => {
  //     slipHeader.push({
  //       text: "Property SLIP ",
  //       bold: true,
  //       alignment: "center",
  //       fontSize: 15,
  //     });
  //     //  width.push('*','*','*')
  //     return {
  //       text: [
  //         { text: "Property Id :", bold: true, fontSize: 10 },
  //         { text: d.property_id + "\n\n", alignment: "right" },
  //         { text: "Offer Code  : ", bold: true, fontSize: 10 },
  //         { text: d.offer_cd + "\n\n", alignment: "right" },
  //         { text: "Property Type : ", bold: true, fontSize: 10 },
  //         { text: d.pro_type + "\n\n", alignment: "right" },
  //         { text: "Property Number : ", bold: true, fontSize: 10 },
  //         { text: d.assigned_property_number + "\n\n", alignment: "right" },
  //         { text: "Area : ", bold: true, fontSize: 10 },
  //         { text: d.area + "\n\n", alignment: "right" },
  //         { text: "Unit : ", bold: true, fontSize: 10 },
  //         { text: d.unit + "\n\n", alignment: "right" },
  //       ],
  //     };
  //   });

  //   var tbl = {
  //     // pageBreak: 'after',
  //     // margin: [0, 0, 0, 8],
  //     fontSize: 10,
  //     table: {
  //       headerRows: 1,
  //       widths: ["*", "*", "*"],
  //       lineBreak: "after",
  //       body: [
  //         slipHeader,
  //         requiredData,
  //         //     // ['property  ID', 'Offer Code', 'Property Type', 'Property Number', 'Area', 'Unit']
  //       ],
  //     },
  //   };
  //   console.log(data, "data");
  //   dd.content.push(tbl);
  //   // dd.content[dd.content.length - 1].push(table);
  //   // for (var i = 0; i < data.length; i++) {
  //   //   var arr = []
  //   //   arr.push(data[i]['property_id']);
  //   //   arr.push(data[i]['offer_cd']);
  //   //   arr.push(data[i]['pro_type']);
  //   //   arr.push(data[i]['assigned_property_number']);
  //   //   arr.push(data[i]['area']);
  //   //   arr.push(data[i]['unit']);
  //   // arr.push(data[i]['life_cycle_status']);
  //   //  dd.content[dd.content.length - 1].table.body.push(arr);
  //   // }
  //   dd.content.push({ text: " " });
  //   dd.content.push(header9);
  //   this.spinner.hide();
  //   pdfMake.createPdf(dd).download("all_property_list");
  //   this.pdf();
  // }
}

