import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MainService } from '../service/main.service';
declare var $: any;
@Component({
  selector: 'app-md-dashboard',
  templateUrl: './md-dashboard.component.html',
  styleUrls: ['./md-dashboard.component.css']
})
export class MdDashboardComponent implements OnInit {

  constructor(public mainService: MainService,private spinner:NgxSpinnerService) { }
  Obj:any = {}
  erpUser:any;
  b_acct_id:any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser['b_acct_id']
    this.Obj['c1'] = 0
    this.Obj['c2'] = 0
    this.Obj['c3'] = 0
    await this.getCurrentLegalEntity()
    await this.getcurrentroleswithresources()
    await this.getCurrentUsers()
  }
  modalOpen(i:any) {
    if (i == 1) {
     
      $('#myModal1').modal('show');
    }
    if (i == 2) {
      $('#myModal2').modal('show');
    }
    if (i == 3) {
      $('#myModal3').modal('show');
    }
    if (i == 4) {
      $('#myModal4').modal('show');
    }

  }
  current_LE = []
  ind_current_LE = []
  org_current_LE = []
  async getCurrentLegalEntity() {
    this.current_LE = []
    this.ind_current_LE = []
    this.org_current_LE = []
    var resp = await this.mainService.getCurrentLegalEntity(this.b_acct_id);
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp);
      this.current_LE = resp['data']
      this.Obj['c1'] = this.current_LE.length
      for (let i = 0; i < this.current_LE.length; i++) {
        if (this.current_LE[i]['party_type'] == 'IND') {
          this.ind_current_LE.push(this.current_LE[i])
        } else {
          this.org_current_LE.push(this.current_LE[i])
        }
      }
    } else {
      Swal.fire("Error", "..Error while getting current Legal Entity", 'error');
    }
  }
  current_role = []
 
  async getcurrentroleswithresources() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    var resp = await this.mainService.getcurrentroleswithresources(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.current_role = resp['data']
      this.Obj['c3']=this.current_role.length
      console.log(resp,'getcurrentroleswithresources:dtls');
      // this.datasource = new MatTableDataSource(resp.data);
      // this.datasource.paginator = this.paginator;
      // this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }
  all_users:any=[]
  async getCurrentUsers() {
    console.log('called this funnction')
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    var resp = await this.mainService.getCurrentMdUser(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      // for (let i = 0; i < resp['data'].length; i++) {
      //   for (let j = 0; j < this.current_role.length; j++) {
      //     if (this.current_role[j]['role_cd'] == resp['data'][i]['role_cd']) {
      //       resp['data'][i]['role_name'] = this.current_role[j]['role_desc']
      //       // resp['data'][i]['role_name'] = this.current_role[j]['role_name']
      //     }
      //   }
      // }

      var emp_obj:any=new Object();

      for (let i = 0; i < resp['data'].length; i++) {
        emp_obj[resp['data'][i]['user_id']]=1;
      }

      var emp_id=Object.keys(emp_obj);

      console.log(emp_id)
      var data=[]
      for (let i = 0; i < emp_id.length; i++) {
        for (let j = 0; j < this.current_LE.length; j++) {
          if (this.current_LE[j]['le_id'] == emp_id[i]) {
            var ob:any=new Object();
            ob['user_id'] = this.current_LE[j]['le_id']
            ob['party_name'] = this.current_LE[j]['party_name']
            ob['contact_email'] = this.current_LE[j]['contact_email']
            ob['phone_no'] = this.current_LE[j]['phone_no']
            data.push(ob)
          }
        }
      }
      
      // this.Obj['c2']=resp['data'].length
      this.Obj['c2']=data.length
      this.all_users=data
      console.log(data, 'kkkkkkkkkkkkkkkkkkkkkkkkkk')
    } else {
      // this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }
}
