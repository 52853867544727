import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { AllEmpService } from '../../service/all-emp.service';
import { HrmsReportService } from '../../service/hrms-report.service';
import { MainService } from '../../service/main.service';
import { DataAccessService } from '../../service/data-access.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
declare var $: any
@Component({
  selector: 'app-lic',
  templateUrl: './lic.component.html',
  styleUrls: ['./lic.component.css']
})
export class LicComponent implements OnInit {


  constructor(private dataAccessService: DataAccessService, public mainService: MainService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private allEmpService: AllEmpService, private hrmsReportService: HrmsReportService) { }
  personalInfoObj = {};
  errorMsg = ''

  erpUser: any;
  user_id: any;
  b_acct_id: any;
  allEmployees: any = [];
  selectEmpObj: any = {};
  AllLICInfo: any = [];
  selectedLICInfo: any = [];
  Obj: any = {};
  allEmplyees_new: any = [];
  tenure: any = [{ value: 'Yearly' }, { value: 'Half-Yearly' }]
  delEl: any;
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }

  displayedColumns :any= ['id', 'company_name', 'lic_no', 'acct_no', 'tensure_of_deduction', 'ifsc_code', 'deduction_from', 'deduction_to', 'amount', 'action'];
  datasource:any;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.dataAccessService.getAssignedEmpInfo1(this.b_acct_id, this.user_id, true);
    await this.getAllEmployees();
    // this.selectEmpObj['le_id'] = this.erpUser.user_id;
    await this.changeEmployee()
  }


  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }

  // async getAllEmployees1() {
  //   this.spinner.show()
  //   var arr = []
  //   var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['emp_status'] = 'ACTIVE';

  //   var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     arr = resp.data;
  //     for (let i = 0; i < arr.length; i++) {
  //       var obj:any = new Object();
  //       obj = Object.assign({}, arr[i]);
  //       obj['tempid'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])
  //       this.allEmployees.push(obj)
  //     }
  //     this.allEmplyees_new = [];
  //     for (let i = 0; i < resp.data.length; i++) {
  //       var obj:any = new Object();
  //       obj = Object.assign({}, resp.data[i]);
  //       obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
  //       this.allEmplyees_new.push(obj)
  //     }
  //   } else {
  //     this.spinner.hide()
  //     this.snackBar.open("Error while getting employee list", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }
  async getAllEmployees() {
    this.spinner.show()
    var arr = []
    this.allEmployees = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';

    var resp = await this.allEmpService.getAllPartyFields(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      this.allEmployees = [];
      var assigned_emp = this.dataAccessService.assignedEmp2;
      for (let i = 0; i < arr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = this.getNumberFormat(obj['emp_id'])
        if (assigned_emp[arr[i]['arr_id']] != undefined) {
          this.allEmployees.push(obj)
        }

      }
      this.allEmplyees_new = [];
      for (let i = 0; i < this.allEmployees.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, this.allEmployees[i]);
        obj['emp_name'] = this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.allEmplyees_new.push(obj)
      }

    }
  }
  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
    this.Obj = {}
    if (this.selectEmpObj['le_id'] == null || this.selectEmpObj['le_id'] == undefined || this.selectEmpObj['le_id'] == "") {
      swal.fire('Warning', "Please Select At Least One Employee", 'warning')
      return;
    }
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }
  async changeEmployee() {
    this.spinner.show();
    this.selectedLICInfo = [];

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    for (let i = 0; i < this.allEmplyees_new.length; i++) {
      if (this.allEmplyees_new[i]['le_id'] == this.selectEmpObj['le_id']) {
        obj['arr_id'] = this.allEmplyees_new[i]['arr_id']
      }
    }

    console.log(obj)
    var resp = await this.allEmpService.getLicInfo(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.AllLICInfo = resp.data;
      // for (let i = 0; i < this.AllLICInfo.length; i++) {
      //   this.AllLICInfo[i]['deduction_from'] = this.mainService.dateformatchange(this.AllLICInfo[i]['deduction_from'])
      //   this.AllLICInfo[i]['deduction_to'] = this.mainService.dateformatchange(this.AllLICInfo[i]['deduction_to'])
      // }

      this.datasource = new MatTableDataSource(this.AllLICInfo)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;

      this.spinner.hide();
    } else {
      swal.fire('Error', ' Error while getting lic info list', 'error')
      // this.snackBar.open("Error while getting lic info list", 'Error', {
      //   duration: 5000
      // });
    }



  }

  openUpdate(element:any) {
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    this.errorMsg = ''
    this.Obj = Object.assign({}, element);
    let ded = this.dateformatchange(element.deduction_from)
    let det = this.dateformatchange(element.deduction_to)

    this.Obj = { ...this.Obj, deduction_from: ded, deduction_to: det }
    //   $('.nav-tabs a[href="#tab-3"]').tab('show');

    console.log(this.Obj);

  }
  dateformatchange(date:any) {
    if (date == null || date == undefined) {
      return "";
    }

    var datearr = date.split("/")
    // console.log("this si the ",datearr)
    // return datearr[2]+'/'+datearr[1]+'/'+datearr[0]
    return datearr[2] + '-' + datearr[1] + '-' + datearr[0]
  }

  // Avinash MIshra
  checexistFlaf = '';

  checkExist(value:any) {
    let obj :any= new Object()
    // console.log("this is the checkexist vlaue --",value);
    if (this.AllLICInfo.length == 0) {
      obj['data'] = 'no_data';
      obj['error'] = true
      return obj;
    }
    let temp = this.AllLICInfo.map((item:any) => {
      if (item.acct_no == value.acct_no) {
        this.checexistFlaf = 'acc'
        obj['data'] = this.checexistFlaf;
        obj['error'] = false
        return obj;

      }
      //  else if(item.ifsc_code==value.ifsc_code)
      //  {
      //    this.checexistFlaf ='ifc';
      //    obj['data']=this.checexistFlaf;
      //      obj['error']=false
      //       return obj ;
      //  }
      else if (item.lic_no == value.lic_no) {
        this.checexistFlaf = 'lic';
        obj['data'] = this.checexistFlaf;
        obj['error'] = false
        return obj;
      }
    })
    // console.log(" this is temp ", temp);
    return obj;

    // console.log("this is the checkexir flag", this.checexistFlaf, temp);

  }

  dateToday:any;
  async submitLICInfo() {
    let nameRegx = /[A-Za-z]$/;
    let ifsRegx = /[A-Z]{4}[A-Z0-9]{7}$/;
    let amountRegx = /[0-9]$/;
    let accNoRegx = /[0-9]{9,18}$/;
    console.log(this.Obj)
    if (this.Obj['company_name'] == null || this.Obj['company_name'] == undefined || this.Obj['company_name'] == '') {
      swal.fire("Error", 'Company Name can not be null', 'error')
      return
    }
    this.dateToday = new Date().toISOString().slice(0, 10);
    if (this.Obj['deduction_to'] < this.dateToday) {
      swal.fire("Error", 'Deduction To can not be less than today', 'error')
      return
    }
    if (!nameRegx.test((this.Obj['company_name']))) {
      swal.fire("Error", 'Company name in Alphabetic Format', 'error')
      return
    }
    if (this.Obj['lic_no'] == null || this.Obj['lic_no'] == undefined || this.Obj['lic_no'] == '') {
      swal.fire("Error", 'Lic Number can not be null', 'error')
      return
    }
    if (this.Obj['acct_no'] == null || this.Obj['acct_no'] == undefined || this.Obj['acct_no'] == '') {
      swal.fire("Error", 'Account Number can not be null', 'error')
      return
    }
    if (!accNoRegx.test((this.Obj['acct_no']))) {
      swal.fire("Error", 'Account Number should be in 9 to 18 digit', 'error')
      return
    }
    if (this.Obj['tensure_of_deduction'] == null || this.Obj['tensure_of_deduction'] == undefined || this.Obj['tensure_of_deduction'] == '') {
      swal.fire("Error", 'Tenure of Deduction can not be null', 'error')
      return
    }
    if (!nameRegx.test((this.Obj['tensure_of_deduction']))) {
      swal.fire("Error", 'Tenure of Deduction like Yearly,HalfYearly', 'error')
      return
    }
    if (this.Obj['ifsc_code'] == null || this.Obj['tensure_of_deduction'] == undefined || this.Obj['tensure_of_deduction'] == '') {
      swal.fire("Error", 'IFS Code can not be null', 'error')
      return
    }
    if (!ifsRegx.test((this.Obj['ifsc_code']))) {
      swal.fire("Error", 'IFS Code should be first four Capital letter and rest seven alphanumeric', 'error')
      return
    }
    if (this.Obj['deduction_from'] == null || this.Obj['deduction_from'] == undefined || this.Obj['deduction_from'] == '') {
      swal.fire("Error", 'Deduction From can not be null', 'error')
      return
    }
    if (this.Obj['deduction_to'] == null || this.Obj['deduction_to'] == undefined || this.Obj['deduction_to'] == '') {
      swal.fire("Error", 'Deduction To can not be null', 'error')
      return
    }

    if (this.Obj['amount'] == null || this.Obj['amount'] == undefined || this.Obj['amount'] == '') {
      swal.fire("Error", 'Amount can not be null', 'error')
      return
    }
    if (!amountRegx.test((this.Obj['amount']))) {
      swal.fire("Error", 'Amount not in Number Format', 'error')
      return
    }
    this.Obj['b_acct_id'] = this.b_acct_id;
    for (let i = 0; i < this.allEmplyees_new.length; i++) {
      if (this.allEmplyees_new[i]['le_id'] == this.selectEmpObj['le_id']) {
        this.Obj['arr_id'] = this.allEmplyees_new[i]['arr_id']
        this.Obj['create_user_id'] = this.allEmplyees_new[i]['id']
      }
    }
    if (this.Obj['deduction_to'] <= this.Obj['deduction_from']) {
      swal.fire("Warning", "To Date Not be Before From Date...!", 'warning');
      return;
    }

    this.spinner.show();

    // Avinash Mishra

    let resp1 = await this.checkExist(this.Obj);
    let goForward = false;
    if (resp1['error'] == true) {
      goForward = true;
    }

    if (resp1['error'] == false) {
      switch (resp1['data']) {
        case 'acc':
          swal.fire('Warning', 'Account NO Is Already Exist !', 'warning');
          goForward = false;
          break;
        // case 'ifc':
        //   swal.fire('Warning','IFC Code Is Already Exist !','warning');
        //   goForward=false;
        //   break;
        case 'lic':
          swal.fire('Warning', 'LIC NO Is Already Exist !', 'warning');
          goForward = false;
          break;
        default:
          goForward = true;
      }
      console.log("this is goforword :- ", goForward);
    }
    if (goForward == false) {
      this.spinner.hide();
      return;
    }


    // await this.checkExist(this.Obj);

    var resp = await this.allEmpService.addLicInfo(this.Obj);
    if (resp['error'] == false) {
      this.Obj = {}
      this.spinner.hide();
      await this.changeEmployee();
      swal.fire("Success", ".....LIC Info Added", 'success');


    } else {
      this.spinner.hide();
      swal.fire("Sorry", ".. Error while Adding LIC Info Of Employee!", 'error');

    }
  }

  async updateLICInfo() {
    let nameRegx = /[A-Za-z]$/;
    let ifsRegx = /[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
    let amountRegx = /[0-9]$/;
    let accNoRegx = /[0-9]{9,18}$/;
    if (this.Obj['company_name'] == null || this.Obj['company_name'] == undefined || this.Obj['company_name'] == '') {
      swal.fire("Error", 'Company Name can not be null', 'error')
      return
    }
    if (!nameRegx.test((this.Obj['company_name']))) {
      swal.fire("Error", 'Company name in Alphabetic Format', 'error')
      return
    }
    if (this.Obj['lic_no'] == null || this.Obj['lic_no'] == undefined || this.Obj['lic_no'] == '') {
      swal.fire("Error", 'Lic Number can not be null', 'error')
      return
    }
    if (this.Obj['acct_no'] == null || this.Obj['acct_no'] == undefined || this.Obj['acct_no'] == '') {
      swal.fire("Error", 'Account Number can not be null', 'error')
      return
    }
    if (!accNoRegx.test((this.Obj['acct_no']))) {
      swal.fire("Error", 'Account Number in Wrong Format', 'error')
      return
    }
    if (this.Obj['tensure_of_deduction'] == null || this.Obj['tensure_of_deduction'] == undefined || this.Obj['tensure_of_deduction'] == '') {
      swal.fire("Error", 'Tenure of Deduction can not be null', 'error')
      return
    }
    if (!nameRegx.test((this.Obj['tensure_of_deduction']))) {
      swal.fire("Error", 'Tenure of Deduction like Yearly,HalfYearly', 'error')
      return
    }
    if (this.Obj['ifsc_code'] == null || this.Obj['tensure_of_deduction'] == undefined || this.Obj['tensure_of_deduction'] == '') {
      swal.fire("Error", 'IFS Code can not be null', 'error')
      return
    }
    if (!ifsRegx.test((this.Obj['ifsc_code']))) {
      swal.fire("Error", 'IFS Code in Wrong Format', 'error')
      return
    }
    if (this.Obj['deduction_from'] == null || this.Obj['deduction_from'] == undefined || this.Obj['deduction_from'] == '') {
      swal.fire("Error", 'Deduction From can not be null', 'error')
      return
    }
    if (this.Obj['deduction_to'] == null || this.Obj['deduction_to'] == undefined || this.Obj['deduction_to'] == '') {
      swal.fire("Error", 'Deduction To can not be null', 'error')
      return
    }
    let todate = new Date().toISOString().slice(0, 10);
    if (this.Obj['deduction_to'] < todate) {
      swal.fire("Error", 'Deduction To can not be less than today', 'error')
      return
    }
    if (this.Obj['amount'] == null || this.Obj['amount'] == undefined || this.Obj['amount'] == '') {
      swal.fire("Error", 'Amount can not be null', 'error')
      return
    }
    if (!amountRegx.test((this.Obj['amount']))) {
      swal.fire("Error", 'Amount not in Number Format', 'error')
      return
    }
    this.Obj['b_acct_id'] = this.b_acct_id;
    this.Obj['update_user_id'] = this.user_id;
    this.spinner.show();
    var resp = await this.allEmpService.updateLicInfo(this.Obj);
    if (resp['error'] == false) {
      this.Obj = {}
      this.spinner.hide();
      this.update_flag = false;
      this.list_flag = true;
      await this.changeEmployee();
      //  await this.changeEmployee();
      swal.fire("Success", "LIC Info Updated", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Sorry", ".. Error while Update LIC Info Of Employee!", 'error');

    }
  }

  async deleteLICInfo(element:any) {
    this.delEl = element
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldeleteLICInfo()
      }
    })

  }
  async finaldeleteLICInfo() {
    var obj: any = new Object();

    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = this.delEl['id'];
    obj['arr_id'] = this.delEl['arr_id']
    obj['lic_seq_no'] = this.delEl['lic_seq_no']

    this.spinner.show();
    var resp = await this.allEmpService.deleteLicInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.changeEmployee();
      await this.changeEmployee();
      swal.fire("Success", "LIC Info Deleted", 'success');
    } else {
      this.spinner.hide();

      swal.fire("Sorry", "Error while deleting LIC Info Of Employee", 'error');

    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  chamgeTab() {
    this.Obj = {}
  }

}
