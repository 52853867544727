<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'md_header1']}}</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-1" (click)="refresh()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> {{mainService.allLableShowObj[mainService.language_cd+'md_header2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-7-2" (click)="refresh()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i> {{mainService.allLableShowObj[mainService.language_cd+'md_header3']}}
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-7-1">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_header4']}}">
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                </div>
                                <div class="col-4">
                                </div>
                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>



                                    <ng-container matColumnDef="designation_cd">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>{{mainService.allLableShowObj[mainService.language_cd+'md_header5']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.designation_cd }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>{{mainService.allLableShowObj[mainService.language_cd+'md_header6']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.name }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="description">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>{{mainService.allLableShowObj[mainService.language_cd+'md_header7']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.description }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="info_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>{{mainService.allLableShowObj[mainService.language_cd+'md_header8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.info_no}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="upload_file_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>{{mainService.allLableShowObj[mainService.language_cd+'md_header9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.upload_file_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>{{mainService.allLableShowObj[mainService.language_cd+'md_header10']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button (click)="view(element)" class="btn btn-primary">{{mainService.allLableShowObj[mainService.language_cd+'md_header11']}}</button>&nbsp;&nbsp;
                                            <button (click)="open_update(element)" class="btn btn-success">{{mainService.allLableShowObj[mainService.language_cd+'md_header12']}}</button>&nbsp;&nbsp;
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>

                        <div class="tab-pane fade" id="tab-7-2">

                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'md_header8']}} :</h6>
                                </div>
                                <div class="col-3">
                                    <input type="number" disabled placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_header8']}}" class="form-control" [(ngModel)]="Obj['info_no']">
                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'md_header5']}}: <span style="color: red;"> *</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'md_header5']}}" class="form-control" [(ngModel)]="Obj['designation_cd']">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'md_header6']}} :</h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'md_header6']}}" class="form-control" [(ngModel)]="Obj['name']">
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3">

                                </div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'md_header7']}} :</h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'md_header7']}}" class="form-control" [(ngModel)]="Obj['description']">
                                </div>
                                <div class="col-3">

                                </div>

                            </div>
                            <br>




                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">{{mainService.allLableShowObj[mainService.language_cd+'md_role12']}}</button>
                                </div>
                            </div>

                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-12  text-center">
                                    <img [src]="imgURL" class="img-circle" width="150" height="150">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6" style="margin-left: 20%">
                                    <input #file type="file" accept=".png, .jpg, .jpeg" name="pimage" ng2FileSelect [uploader]="uploader" class="form-control" style="border-color: black" (change)="onFileUpload($event,file.files)">
                                </div>
                                <div class="col-3">
                                    <button type="button" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-info" (click)='Upload()'><i class="fa fa-upload" aria-hidden="true">&nbsp;</i>
                                        {{mainService.allLableShowObj[mainService.language_cd+'md_header13']}} </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>