<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">
            All Case List

          </div>

        </div>
        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                All Case List
              </a>
            </li>
            <li class="nav-item" *ngIf="peshkar">
              <a class="nav-link " href="#tab-3" data-toggle="tab" (click)="caseMenu()"><i class="ti-bar-chart"></i>
                Create Case
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-announcement"></i>
                Document </a>
            </li>

          </ul>

          <div class="tab-content">
            <div class="tab-pane fade show active" id="tab-1">

              <!-- Search form -->
              <mat-form-field style="margin-left: 2%">
                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
              </mat-form-field>

              <div>

                <div class=" example-container">
                  <table mat-table [dataSource]="dataSource" matSort class="w-100">

                    <ng-container matColumnDef="s.no">
                      <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> S.No </th>
                      <td mat-cell *matCellDef="let element ; let i = index "> {{(i+1) +
                        (paginator.pageIndex*paginator.pageSize)}} </td>
                    </ng-container>


                    <!-- complain id -->
                    <ng-container matColumnDef="comp_id">
                      <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Complaint ID </th>
                      <td mat-cell *matCellDef="let element ; let i = index ">{{element.comp_id}}</td>
                    </ng-container>




                    <ng-container matColumnDef="case_id">
                      <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Case ID </th>
                      <td mat-cell *matCellDef="let element ; let i = index ">{{element.case_id}}</td>
                    </ng-container>


                    <ng-container matColumnDef="filling_date">
                      <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Filing Date </th>
                      <td mat-cell *matCellDef="let element">
                        {{element.f_date}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="auth_court">
                      <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Authorized Court </th>
                      <td mat-cell *matCellDef="let element"> Court of {{element.zone_vp}}, {{element.zone_nm}}</td>
                    </ng-container>

                    <ng-container matColumnDef="next_hearing_date">
                      <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Next Hearing Date</th>
                      <td mat-cell *matCellDef="let element">
                        {{element.h_date}} </td>
                    </ng-container>

                    <ng-container matColumnDef="case_status">
                      <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Case Status</th>
                      <td mat-cell *matCellDef="let element">
                        {{element.c_status}} </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        <button class="btn btn-outline-primary" (click)="gen_notice(element,'html')">Notice
                        </button>&nbsp;&nbsp;
                        <button class="btn btn-outline-primary" (click)="updatehearing(element)">Hearing Update
                        </button>&nbsp;&nbsp;
                        <button class="btn btn-outline-primary" (click)="updatedefendent(element)">Update Defendent
                        </button>&nbsp;&nbsp;
                        <button class="btn btn-outline-primary" (click)="note_sheet(element,s)">Note Sheet
                        </button>&nbsp;&nbsp;
                        <button class="btn btn-outline-primary" (click)="docs(element,'html')">Docs/Evidences
                        </button>&nbsp;&nbsp;
                        <button class="btn btn-outline-primary" (click)="order_upload(element)">Upload Order
                        </button>&nbsp;&nbsp;
                        <button class="btn btn-outline-info" (click)="penalty(element)">Penality
                        </button>&nbsp;&nbsp;
                        <button class="btn btn-outline-primary" (click)="case_details(element)">Case Details
                        </button>&nbsp;&nbsp;
                        <button class="btn btn-outline-primary" (click)="view_document(element)">View Document
                        </button>&nbsp;&nbsp;
                        <!-- <button class="btn btn-outline-primary" (click)="rout()">abc
                                      </button>&nbsp;&nbsp; -->
                      </td>

                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  <mat-paginator #paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                  </mat-paginator>
                </div>
              </div>


            </div>
            <div class="tab-pane fade show " id="tab-2">
              <mat-form-field style="margin-left: 2%">
                <input matInput (keyup)="applyFilter3($any($event.target).value)" placeholder="Filter">
              </mat-form-field>
              <div class=" example-container">
                <table mat-table [dataSource]="dataSource3" matSort #sortCol2="matSort">
                  <ng-container matColumnDef="case_id">
                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                      Case ID

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                      {{ element.case_id }}</td>
                  </ng-container>
                  <ng-container matColumnDef="document_name">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      Document Name
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.document_name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="document_type_code">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      Document Type Code
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                      IMAGE-{{i+1}} </td>
                  </ng-container>



                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      Action </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">

                      <button class="btn btn-primary" (click)="view_d(element)">View</button>



                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns3;">
                  </tr>
                </table>
                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                </mat-paginator>
              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <img [src]="imgURL" />



                </div>
              </div>







            </div>
            <div class="tab-pane fade show " id="tab-3">

              <div class="row">
                <div class="col-12 text-right">
                  <marquee direction="left" class="text-danger" scrolldelay="10" behavior="loop"><b>Please fill all the
                      required fields which is mark with asterisk symbol ( * )</b></marquee>
                </div>
              </div>

              <form #casefrom="ngForm" (ngSubmit)="createCase(casefrom)">


                <div class="row ">
                  <div class="col-5">
                    <hr class="hrline">
                  </div>
                  <div class="col-2 text-center">
                    <h6 id="h" style="color: brown; min-height: 20px;">
                      Complainer Details
                    </h6>
                  </div>
                  <div class="col-5">
                    <hr class="hrline">
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Complaint Type&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6>
                      :
                    </h6>
                  </div>

                  <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="obj['complian_type']"
                      placeholder="Enter the complaint type" name="complian_type" #complian_type="ngModel"
                      [ngClass]="casefrom.submitted && complian_type.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && complian_type.invalid" class="text-danger">
                      <p class="pera" *ngIf="complian_type.errors?.['required']">Complaint type is required</p>
                      <p class="pera" *ngIf="complian_type.errors?.['required']">If not available enter NA</p>
                    </span>
                  </div>

                  <div class="col-2 text-left">
                    <h6 style=" margin-left: 2%;">
                      Complain From&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6>
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <ng-select [items]="main2.codeValueTechObj['ICMS015']" bindLabel="value" bindValue="code"
                      [multiple]="false" placeholder="Choose Options" [(ngModel)]="obj['complain_from']"
                      name="complain_from" #complain_from="ngModel" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true"
                      [ngClass]="casefrom.submitted && complain_from.invalid ?'bortrue':'ff'" required>
                    </ng-select>
                    <span *ngIf="casefrom.submitted && complain_from.invalid" class="text-danger">
                      <p class="pera" *ngIf="complain_from.errors?.['required']"> Complain from is required</p>
                    </span>
                  </div>
                </div>
                <br>

                <div class="row">

                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Complainer Name&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6>
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="obj['compliner_name']"
                      placeholder="Enter complainer name" name="compliner_name" #compliner_name="ngModel"
                      [ngClass]="casefrom.submitted &&  compliner_name.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && compliner_name.invalid" class="text-danger">
                      <p class="pera" *ngIf="compliner_name.errors?.['required']">Complainer name is required</p>
                      <!-- <p class="pera" *ngIf="compliner_name.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>

                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Complainer Email
                      <!-- &nbsp;<b class="text-danger">*</b> -->
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="obj['compliner_email']"
                      placeholder="Enter complainer email" name="compliner_email" #compliner_email="ngModel"
                      [ngClass]="casefrom.submitted &&  compliner_email.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && compliner_email.invalid" class="text-danger">
                      <!-- <p class="pera" *ngIf="compliner_email.errors?.['required']"  > Complainer email is required</p> -->
                      <p class="pera" *ngIf="compliner_email.errors?.['required']">If not available enter NA</p>
                    </span>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Complainer Phone No&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="number" minlength="10" maxlength="10" class="form-control"
                      [(ngModel)]="obj['compliner_phone_no']" placeholder="Enter complainer phone no"
                      name="compliner_phone_no" #compliner_phone_no="ngModel"
                      [ngClass]="casefrom.submitted &&  compliner_phone_no.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && compliner_phone_no.invalid" class="text-danger">
                      <p class="pera" *ngIf="compliner_phone_no.errors?.['required']">Complainer phone no is required
                      </p>
                      <!-- <p class="pera" *ngIf="compliner_phone_no.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                </div>
                <br>

                <div class="row ">
                  <div class="col-5">
                    <hr class="hrline">
                  </div>
                  <div class="col-2 text-center">
                    <h6 id="h" style="color: brown; min-height: 20px;">
                      Owner Details
                    </h6>
                  </div>
                  <div class="col-5">
                    <hr class="hrline">
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Owner Name&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="obj['owner_name']"
                      placeholder="Enter owner name" name="owner_name" #owner_name="ngModel"
                      [ngClass]="casefrom.submitted &&  owner_name.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && owner_name.invalid" class="text-danger">
                      <p class="pera" *ngIf="owner_name.errors?.['required']">Owner Name is required</p>
                      <!-- <p class="pera" *ngIf="owner_name.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Owner Number
                      <!-- &nbsp;<b class="text-danger">*</b>   -->
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control" minlength="10" maxlength="10"
                      [(ngModel)]="obj['owner_mobile']" placeholder="Enter owner number" name="owner_mobile"
                      #owner_mobile="ngModel" [ngClass]="casefrom.submitted &&  owner_mobile.invalid ?'bortrue':'ff'"
                      required>
                    <span *ngIf="casefrom.submitted && owner_mobile.invalid" class="text-danger">
                      <!-- <p class="pera" *ngIf="owner_mobile.errors?.['required']"  >Owner Number is required</p> -->
                      <p class="pera" *ngIf="owner_mobile.errors?.['required']">If not available enter NA</p>
                    </span>
                  </div>
                </div>
                <br>

                <div class="row">
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Property Address&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="obj['property_address']"
                      placeholder="Enter property address" name="property_address" #property_address="ngModel"
                      [ngClass]="casefrom.submitted &&  property_address.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && property_address.invalid" class="text-danger">
                      <p class="pera" *ngIf="property_address.errors?.['required']">Property address is required</p>
                      <!-- <p class="pera" *ngIf="property_address.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Landmark&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="obj['landmark']" placeholder="Enter landmark"
                      name="landmark" #landmark="ngModel"
                      [ngClass]="casefrom.submitted &&  landmark.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && landmark.invalid" class="text-danger">
                      <p class="pera" *ngIf="landmark.errors?.['required']">Landmark is required</p>
                      <p class="pera" *ngIf="landmark.errors?.['required']">If not available enter NA</p>
                    </span>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      District&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="obj['dist']" placeholder="Enter district"
                      name="dist" #dist="ngModel" [ngClass]="casefrom.submitted &&  dist.invalid ?'bortrue':'ff'"
                      required>
                    <span *ngIf="casefrom.submitted && dist.invalid" class="text-danger">
                      <p class="pera" *ngIf="dist.errors?.['required']">District is required</p>
                      <!-- <p class="pera" *ngIf="dist.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Country&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="obj['country']" placeholder="Enter country"
                      name="country" #country="ngModel"
                      [ngClass]="casefrom.submitted &&  country.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && country.invalid" class="text-danger">
                      <p class="pera" *ngIf="country.errors?.['required']">Country is required</p>
                      <!-- <p class="pera" *ngIf="country.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Pin Code&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="number" class="form-control" minlength="6" maxlength="6" [(ngModel)]="obj['pin_code']"
                      placeholder="Enter pin code" name="pin_code" #pin_code="ngModel"
                      [ngClass]="casefrom.submitted &&  pin_code.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && pin_code.invalid" class="text-danger">
                      <p class="pera" *ngIf="pin_code.errors?.['required']">Pin code is required</p>
                      <!-- <p class="pera" *ngIf="pin_code.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Police Station&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="obj['police_station']"
                      placeholder="Enter police station" name="police_station" #police_station="ngModel"
                      [ngClass]="casefrom.submitted &&  police_station.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && police_station.invalid" class="text-danger">
                      <p class="pera" *ngIf="police_station.errors?.['required']">Police station is required</p>
                      <!-- <p class="pera" *ngIf="police_station.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      State&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="obj['state']" placeholder="Enter state"
                      name="state" #state="ngModel" [ngClass]="casefrom.submitted &&  state.invalid ?'bortrue':'ff'"
                      required>
                    <span *ngIf="casefrom.submitted && state.invalid" class="text-danger">
                      <p class="pera" *ngIf="state.errors?.['required']">State is required</p>
                      <!-- <p class="pera" *ngIf="state.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>

                </div>
                <br>
                <div class="row ">
                  <div class="col-5">
                    <hr class="hrline">
                  </div>
                  <div class="col-2 text-center">
                    <h6 id="h" style="color: brown; min-height: 20px;">
                      Other Infomation
                    </h6>
                  </div>
                  <div class="col-5">
                    <hr class="hrline">
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Inspection Date&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <input type="date" class="form-control" [(ngModel)]=" obj['inspection_date']" name="inspection_date"
                      #inspection_date="ngModel"
                      [ngClass]="casefrom.submitted &&  inspection_date.invalid ?'bortrue':'ff'" required>
                    <span *ngIf="casefrom.submitted && inspection_date.invalid" class="text-danger">
                      <p class="pera" *ngIf="inspection_date.errors?.['required']">Inspection Date is required</p>
                      <!-- <p class="pera" *ngIf="inspection_date.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                  <br>
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Complaint Date&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3 text-left">
                    <input type="date" class="form-control" [(ngModel)]="obj['complain_date']" name="complain_date"
                      #complain_date="ngModel" [ngClass]="casefrom.submitted &&  complain_date.invalid ?'bortrue':'ff'"
                      required>
                    <span *ngIf="casefrom.submitted && complain_date.invalid" class="text-danger">
                      <p class="pera" *ngIf="complain_date.errors?.['required']">Complaint Date is required</p>
                      <!-- <p class="pera" *ngIf="complain_date.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Select sections&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <ng-select [items]="section" bindLabel="section_cd" bindValue="section_cd" [multiple]="true"
                      placeholder="Choose Options" [(ngModel)]="obj['sections']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true" name="sections" #sections="ngModel"
                      [ngClass]="casefrom.submitted &&  sections.invalid ?'bortrue':'ff'" required>
                    </ng-select>
                    <span *ngIf="casefrom.submitted && sections.invalid" class="text-danger">
                      <p class="pera" *ngIf="sections.errors?.['required']">Sections is required</p>
                      <!-- <p class="pera" *ngIf="sections.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Zone&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>
                  <div class="col-3">
                    <ng-select [items]="zone_list" bindLabel="value" bindValue="code" [multiple]="false"
                      placeholder="Select Zone" (click)="sameAddress()" [(ngModel)]="obj['zone_cd']"
                      [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="zone_cd"
                      #zone_cd="ngModel" [ngClass]="casefrom.submitted &&  zone_cd.invalid ?'bortrue':'ff'" required>
                    </ng-select>
                    <span *ngIf="casefrom.submitted && zone_cd.invalid" class="text-danger">
                      <p class="pera" *ngIf="zone_cd.errors?.['required']">Zone is required</p>
                      <!-- <p class="pera" *ngIf="zone_cd.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                </div>
                <br>

                <div class="row" *ngIf="obj['zone_cd']!=undefined || obj['zone_cd']!=null">

                  <div class="col-2 text-left">
                    <h6 style="margin-left: 2%;">
                      Inspection Officer&nbsp;<b class="text-danger">*</b>
                    </h6>
                  </div>
                  <div class="col-1 text-center">
                    <h6 style="margin-left: 2%;">
                      :
                    </h6>
                  </div>

                  <div class="col-3">
                    <ng-select [items]="userbyzone" [multiple]="false" placeholder="Select Inspection officer"
                      bindLabel="desc" bindValue="user_id" [selectableGroup]="true" [selectableGroupAsModel]="false"
                      [closeOnSelect]="true" name="hierarchy" [(ngModel)]="obj['inspection_officer']"
                      name="inspection_officer" #inspection_officer="ngModel"
                      [ngClass]="casefrom.submitted &&  inspection_officer.invalid ?'bortrue':'ff'" required>
                    </ng-select>
                    <span *ngIf="casefrom.submitted && inspection_officer.invalid" class="text-danger">
                      <p class="pera" *ngIf="inspection_officer.errors?.['required']"> Inspection Officer is required
                      </p>
                      <!-- <p class="pera" *ngIf="inspection_officer.errors?.['required']"  >If not available enter NA</p> -->
                    </span>
                  </div>
                  <div class="col-3 text-left">
                    <mat-checkbox class="example-margin" (click)='sameAddress()' style="font-weight: bold;">Click for
                      other zone user </mat-checkbox>
                  </div>


                </div>
                <br *ngIf="obj['zone_cd']!=undefined || obj['zone_cd']!=null">






                <div class="row text-center">
                  <div class="col-12">
                    <!-- <button class="btn btn-outline-primary  " (click)="createCase()" type="submit">Submit -->
                    <button class="btn btn-outline-primary" type="submit">Submit </button>&nbsp;
                    <button class="btn btn-outline-primary" #resetButton type="button"
                      (click)="casefrom.resetForm({})">Reset</button>

                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <!--Shweta notice start-->
  <div class="modal fade" id="myModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg mt-0" style="max-width: 95% ;">
      <div class="modal-content " style="height:auto;margin-top: 5%;">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>CASE NOTESHEET
            </h6>
          </h5>
          <br>
          <h5 class="modal-title " id="exampleModalLongTitle">
            <h6>Note List for Case ID
            </h6>
            <!-- {{caselist['case_id']}} -->
            <h6 style="margin-left:28%"> {{caseId}}
            </h6>
          </h5> &nbsp;&nbsp;&nbsp;
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>

            <div class="flex-container">
              <div class="flex-child black">
                <br>

                <div *ngFor="let ob of shownotesheetobj; let i = index">
                  <div class="row">
                    <div class="col-1 "> </div>
                    <div class="col">
                      <b style="margin-left: 5%;color: rgb(233, 53, 89);"> {{i+1}}. &nbsp;&nbsp;&nbsp;&nbsp;
                        {{this.shownotesheetobj[i]['time']}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; By :
                        {{this.shownotesheetobj[i]['assign_user']}} </b><br>
                    </div>
                  </div>
                  <br>
                  <div class="row">

                    <div class="Avimargine" style="margin-left:5%;color: rgb(0, 106, 255)"> <b
                        style="margin-left:6%;color: rgb(18, 3, 70)"> Note :-</b>
                      &nbsp;&nbsp;{{this.shownotesheetobj[i]?.newnote}}
                      <br>
                      <span>
                        <b style="margin-left:6%;color: rgb(18, 3, 70)">Attachment* :-</b>&nbsp;&nbsp; <a role="button"
                          (click)="view_document1(shownotesheetobj,i)"
                          style="color:rgb(60, 0, 255)">{{shownotesheetobj[i]["document_type_code"]}}</a>

                      </span>
                    </div>
                  </div>
                  <br>
                  <!-- <div class="row">
       <div class="col"> <span>
                 <b  style="color: rgb(18, 3, 70)">Attachment :-</b>&nbsp;&nbsp;   <a role="button" (click)="view_document1(shownotesheetobj,i)" style="color:rgb(60, 0, 255)">{{shownotesheetobj[i]["document_type_code"]}}</a>

       </span>
              </div>
          </div> -->
                </div>




                <br>
                <div class="row text-right">


                </div>

                <div style="border: 2px solid gray; border-radius:10px;">
                  <br>
                  <div class="row">
                    <!-- <div class="col-2"></div> -->
                    <div class="col text-right">
                      New Note

                    </div>
                    <div class="col text-left">

                      <h6 style="margin-left:10%;">

                        <textarea class="form-control a" id="exampleFormControlTextarea1" rows="5" cols="70"
                          height="auto" name="text" [(ngModel)]="caselist['text_notesheet']" #text="ngModel"
                          (change)="change1()"></textarea>

                      </h6>
                    </div>
                    <!-- <div class="col-2"></div> -->
                    <div class="col-1"></div>
                  </div>
                  <br>
                  <div class="row">
                    <!-- <div class="col-2"></div> -->
                    <div class="col text-right">
                      Attachment*

                    </div>
                    <div class="col text-left">

                      <input type="file" multiple #file (change)="onFileUploadnotesheet($event,file.files)"
                        class="form-control" placeholder="Choose Photo" accept=".png,.jpeg,.jpg,.pdf" id="avinotesheet">

                    </div>
                    <!--  <div class="col-2"></div> -->
                    <div class="col-1"></div>
                  </div>


                  <div class="modal-footer " style="justify-content: center;">








                    <button type="button" class="btn btn-outline-primary "
                      (click)="submit_notesheet(caselist)">SUBMIT</button>
                    <button type="button" class="btn btn-outline-danger " (click)="close_modal()">Close</button>








                    <!-- <button type="button" class="btn btn-outline-danger " (click)="close_modal()" >Close</button>  -->




                  </div>
                </div>
                <!--
    <div class="row text-center view" >
      <button (click)="view_note()" class="btn btn-outline-success"  data-dismiss="modal">Create Note</button>&nbsp;&nbsp;

    </div> -->








                <br>
              </div>

              <!-- <div class="flex-child black" *ngIf="showdocument"> -->
              <div class="flex-child black">


                <img [src]="imgURL3" />

              </div>
            </div>



            <div class="modal-footer" style="justify-content: center;">

              <!-- <button type="button" class="btn btn-outline-danger " (click)="close_modal()" >Close</button> -->

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Shweta notice end-->
  <!-- digital signature -->
  <div class="modal fade" id="myModalDigital" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg mt-0" style="max-width: 95% ;">
      <div class="modal-content " style="height:1000px;margin-top: 5%;">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Digital Signature
            </h6>
          </h5>
          <br>
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6> Case ID
            </h6>
          </h5>;
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>{{sign['data']?.case_id}}
            </h6>
          </h5> &nbsp;&nbsp;&nbsp;
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>

            <div class="flex-container">
              <div class="flex-child black">
                <br>

                <div class="row" *ngFor="let ob of shownotesheetobj; let i = index">
                  <div class="col-12 ">
                    <b style="color: rgb(233, 53, 89);"> {{i+1}}. {{this.shownotesheetobj['time']}}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      By : {{this.Username}}</b>

                    <br>
                    <p style="margin-left:5%;color: rgb(0, 106, 255)">{{this.shownotesheetobj[i]?.newnote}} </p>
                  </div>

                  <div class="col-3 r1 text-center">

                    <a role="button" (click)="view_document1(shownotesheetobj,i)"
                      style="color:rgb(60, 0, 255)">{{shownotesheetobj[i]["document_type_code"]}}</a>
                  </div>
                </div>




                <br>
                <div class="row text-right">


                </div>

                <div class="row text-center view">
                  <button (click)="upload_sig()" data-dismiss="modal" class="btn btn-outline-success">Upload
                    Signature</button>&nbsp;&nbsp;

                </div>

                <br>
              </div>

              <div class="flex-child black" *ngIf="showdocument">


                <img [src]="imgURL3" />

              </div>
            </div>



            <div class="modal-footer" style="justify-content: center;">


            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <upload sign  > -->
  <div class="modal fade" id="myModalup_sig" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content " style="height:1000px;margin-top: 5%;">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Digital Signature
            </h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>
            <div class="row">

              <div class="col text-right">
                Note About Digital Signature :

              </div>
              <div class="col text-left">

                <h6>

                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="1" cols="70" name="text"
                    [(ngModel)]="caselist1['text_notesheet']" #text="ngModel"></textarea>

                </h6>
              </div>
              <div class="col-2"></div>
              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <!-- <div class="col-2"></div> -->
              <div class="col text-right">
                Attach Digital Signature :

              </div>
              <div class="col text-left">

                <input type="file" multiple #file (change)="onFileUploadnotesheet($event,file.files)"
                  class="form-control" placeholder="Choose Photo" accept=".png,.jpeg,.jpg,.pdf" id="aviDigitalSig">

              </div>
              <div class="col-2"></div>
              <div class="col-2"></div>
            </div>


            <div class="modal-footer " style="justify-content: center;">






              <button type="button" class="btn btn-outline-primary " data-dismiss="modal"
                (click)="submit_sig(caselist1)">SUBMIT</button>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>





  <!-- notice -->




  <!-- update defendent  -->
  <div class="modal fade" id="myModal9" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>GENERATE NOTICE</h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>

            <div class="row">
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint ID</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.complain_id}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint Date</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.comp_assign_date}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Case ID</h6>
              </div>:
              <div class="col">{{noticeArr[1]?.case_id}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Case Filing Date</h6>
              </div>:
              <div class="col">{{case_f_date}}</div>
              <!-- <div class="col">{{noticeArr[1]?.f_date}}</div> -->
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Zone</h6>
              </div>:
              <div class="col">{{noticeArr[1]?.zone_nm}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint Type</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.complian_type}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Property Address</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.property_address}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Photo by Complainer</h6>
              </div>:
              <div class="col">

                <button (click)="view_photo(1)" class="btn btn-outline-success">View Photo</button>&nbsp;&nbsp;

              </div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Inspection Officer</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.inspection_officer}} </div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Photo by Inspector</h6>
              </div>:
              <div class="col">
                <button (click)="view_photo(2)" class="btn btn-outline-success">View Photo</button>&nbsp;&nbsp;

              </div>
              <div class="col-1"></div>
            </div>

            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Is Notice Required ?</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.is__notice_required}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>View Inspection Report </h6>
              </div>:
              <div class="col">
                <button (click)="view_photo1(noticeArr)" class="btn btn-outline-success">View </button>&nbsp;&nbsp;

                <!-- <button (click)="view_photo(3)"
             class="btn btn-outline-success">View </button>&nbsp;&nbsp; -->

              </div>
              <div class="col-1"></div>
            </div>

            <br>
            <div class="row">
              <div class="col-1"></div>
              <h5><b>Notice List</b></h5>
            </div>
            <div class="row">
              <!-- <div class="col-1"></div> -->
              <div class="tab-content w-75" style="width: 97% !important;padding-left: 25px !important;">
                <div class="tab-pane fade show active" id="tab-1">

                  <!-- Search form -->
                  <mat-form-field style="margin-left: 2%">
                    <input matInput (keyup)="applyFilter4($any($event.target).value)" placeholder="Filter">
                  </mat-form-field>

                  <div>

                    <div class="example-container">
                      <table mat-table [dataSource]="dataSource4" matSort class="w-100">

                        <ng-container matColumnDef="s.no">
                          <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> S.No </th>
                          <td mat-cell *matCellDef="let element ; let i = index "> {{i+1}} </td>
                        </ng-container>





                        <ng-container matColumnDef="not_id">
                          <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Notice ID </th>
                          <td mat-cell *matCellDef="let element">
                            {{element.not_id}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="no_un_act">
                          <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Notice Under Act </th>
                          <td mat-cell *matCellDef="let element">{{element.not_act}} </td>
                        </ng-container>

                        <ng-container matColumnDef="sec">
                          <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Section </th>
                          <td mat-cell *matCellDef="let element">
                            {{element.not_sec}} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Status </th>
                          <td mat-cell *matCellDef="let element">
                            {{element.not_status}} </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        <button class="btn btn-outline-primary" *ngIf="element.not_status!='Notice Sent'" (click)="print(element)">Print
                        </button>&nbsp;&nbsp;
                          <button class="btn btn-outline-info" (click)="update(element)" *ngIf="element.not_status=='GENERATED' && vihitpa==true" >Update
                          </button>&nbsp;&nbsp;
                           <button class="btn btn-outline-info" (click)="viewNoticeDelivereddocs(element)" *ngIf="element.not_status=='Notice Delivered'">View Notice Delivered
                          </button>&nbsp;&nbsp; -->


                        <!-- Start By avinash before chat
                          <button class="btn btn-outline-primary" *ngIf="element.not_status=='Notice Sent' " (click)="view_digital_sign(element)">View
                          </button>&nbsp;&nbsp;
                          <button class="btn btn-outline-primary" *ngIf="element.not_btn==true && (element.not_status=='GENERATED' || element.not_status=='UNDER-APPROVAL')" (click)="digitalSignal(element)" >Upload Digital Sign
 End -->

                        <!-- <button class="btn btn-outline-primary" *ngIf="element.not_status=='Notice Sent' && element.not_btn==true " (click)="view_digital_sign(element)">View -->
                        <!-- <button class="btn btn-outline-primary" *ngIf="element.not_status=='Notice Sent' " (click)="view_digital_sign(element)">View
                          </button>&nbsp;&nbsp; -->
                        <!-- <button class="btn btn-outline-primary" *ngIf="element.not_btn==true && (element.not_status=='GENERATED' || element.not_status=='UNDER-APPROVAL')" data-dismiss="modal" (click)="digitalSignal(element)" >Upload Digital Sign  -->
                        <!-- <button class="btn btn-outline-primary" *ngIf="element.not_btn==true &&  element.not_status=='UNDER-APPROVAL'" data-dismiss="modal" (click)="digitalSignal(element)" >Upload Digital Sign

                          </button>&nbsp;&nbsp;

                      </td>

                  </ng-container> -->

                        <!-- Deepankar -->
                        <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Action </th>
                          <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            <button (click)="viewndreport(element)" class="btn btn-outline-success"
                              *ngIf="element.not_status=='Notice Delivered'"> View Notice Delivered
                              Report</button>&nbsp;&nbsp;
                            <button class="btn btn-outline-primary" (click)="forwardN(element)"
                              *ngIf="element.not_status=='GENERATED' && noticeFlag=='T'">Forward
                            </button>&nbsp;&nbsp;
                            <button class="btn btn-outline-primary" (click)="forwardN(element)"
                              *ngIf="element.not_status=='UNDER-APPROVAL' && noticeFlag=='UApr'">Forward
                            </button>&nbsp;&nbsp;
                            <button class="btn btn-outline-primary"
                              *ngIf="element.not_status=='UNDER-APPROVAL' || element.not_status=='GENERATED' "
                              (click)="print(element)">Download
                            </button>&nbsp;&nbsp;
                            <button class="btn btn-outline-info" (click)="update(element)"
                              *ngIf="element.not_btn!=true && (element.not_status=='GENERATED' || element.not_status=='UNDER-APPROVAL' && element.noticeFlag2=='UApr' && (element.user_id==element.forward_to || element.forward_to=='NA')) ">Update
                            </button>&nbsp;&nbsp;
                            <button class="btn btn-outline-primary" (click)="forwardN(element)"
                              *ngIf="element.not_status=='GENERATED' && element.noticeFlag1=='T'">Forward
                            </button>&nbsp;&nbsp;
                            <button class="btn btn-outline-primary" (click)="forwardN(element)"
                              *ngIf="element.not_status=='UNDER-APPROVAL' && element.noticeFlag2=='UApr' && (element.user_id==element.forward_to || element.forward_to=='NA') ">Forward
                            </button>&nbsp;&nbsp;
                            <button class="btn btn-outline-primary" *ngIf="element.not_status=='Notice Sent'  "
                              (click)="view_digital_sign(element)">View
                            </button>&nbsp;&nbsp;
                            <button class="btn btn-outline-primary"
                              *ngIf="element.not_btn==true && ( element.not_status=='UNDER-APPROVAL') && element.noticeFlag2=='UApr' && (element.user_id==element.forward_to || element.forward_to=='NA') && vihitpa==true "
                              (click)="digitalSignal(element)">Upload Digital Sign
                            </button>&nbsp;&nbsp;

                          </td>

                        </ng-container>
                        <!-- end -->



                        <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns4;"></tr>
                      </table>
                    </div>

                  </div>

                </div>

              </div>


            </div>


            <br>
          </div>

          <div class="modal-footer" style="justify-content: center;">

            <!-- <button type="button" class="btn btn-outline-primary "   *ngIf="vihitpa==true" data-dismiss="modal"(click)="generate_date(noticeArr,noticeObj)" >Generate New</button> -->
            <button type="button" class="btn btn-outline-primary " *ngIf="peshkar==true"
              (click)="generate_date(noticeArr,noticeObj)">Generate New</button>

            <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button>

          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>GENERATE NOTICE</h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>


            <div class="row">
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint ID</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.complain_id}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint Date</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.comp_assign_date }}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Case ID</h6>
              </div>:
              <div class="col">{{noticeArr[1]?.case_id}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Case Filing Date</h6>
              </div>:
              <div class="col">{{case_f_date}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Zone</h6>
              </div>:
              <div class="col">{{noticeArr[1]?.zone_nm}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint Type</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.complian_type}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Property Address</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.property_address}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Photo by Complainer</h6>
              </div>:
              <div class="col">

                <button (click)="view_photo(1)" class="btn btn-outline-success">View Photo</button>&nbsp;&nbsp;

              </div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Inspection Officer</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.inspection_officer}} </div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Photo by Inspector</h6>
              </div>:
              <div class="col">
                <button (click)="view_photo(2)" class="btn btn-outline-success">View Photo</button>&nbsp;&nbsp;

              </div>
              <div class="col-1"></div>
            </div>

            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Is Notice Required ?</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.is__notice_required}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>View Inspection Report </h6>
              </div>:
              <div class="col">
                <button (click)="view_photo1(noticeArr)" class="btn btn-outline-success">View </button>&nbsp;&nbsp;

              </div>
              <div class="col-1"></div>
            </div>

            <div class="row mt-2">

              <!--
              <div class="col-1"></div>
                -->
              <!-- <div class="col"><h6>Inspection Note</h6></div>

              <div class="col">

                {{noticeArr[0]?.insp_offi_note}}

              </div>
            -->

              <div class="col-1"></div>
              <!--6
              <div class="col"><h6>Notice To</h6></div>:

              <div class="col">

                {{noticeObj['owner_name']}}

              </div>
              6-->
              <div class="col-1"></div>

            </div>
            <div class="row mt-2" *ngIf="notice_before">
              <div class="col-1"></div>
              <div class="col">
                <h6>Reply this Notice Before *</h6>
              </div>
              <div class="col">
                <input class="form-control" type="date" placeholder="" [(ngModel)]="noticeObj['date']">
              </div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Reply this Notice Before Time *</h6>
              </div>:

              <div class="col">

                <input class="form-control" type="time" placeholder="" [(ngModel)]="noticeObj['time']">

              </div>

              <div class="col-1"></div>
            </div>


            <br>

            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col-3">
                <h6>Notice To</h6>
              </div>:<div class="col-5">
                <input class='form-control' placeholder="Type Name of Owner(if not available)"
                  [(ngModel)]="noticeObj['owner_name']" disabled>
              </div>
              <div class="col"></div>
              <div class="col-1"></div>
              <div class="col-1"></div>
              <div class="col"></div>

            </div>

            <div class="row mt-2">
              <div class="col-1"></div>

              <div class="col-3">
                <h6>Notice Under Act</h6>
              </div>:
              <div class="col-5">
                <input class='form-control' placeholder="Type Name of Owner(if not available)"
                  [(ngModel)]="noticeObj['notice_under_act']" disabled>
              </div>
              <div class="col"></div>
              <div class="col-1"></div>
              <div class="col-1"></div>
              <div class="col"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col-3">
                <h6>Sections *</h6>
              </div>:
              <div class="col-5">
                <ng-select [items]="aviTotal2" bindLabel="section_cd" bindValue="section_cd" [multiple]="false"
                  placeholder="NA" [(ngModel)]="noticeObj['section_cd']" [selectableGroup]="true"
                  [selectableGroupAsModel]="false" [closeOnSelect]="true" name="cadre_code" id="avinashSection">
                </ng-select>

              </div>
              <div class="col"></div>
              <div class="col-1"></div>
              <div class="col-1"></div>
              <div class="col"></div>
              <br>
            </div>

            <div class="modal-footer" style="justify-content: center;">

              <button type="button" class="btn btn-outline-primary " data-dismiss="modal"
                (click)="generate_notice(noticeArr,noticeObj)">Generate Notice</button>

              <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="myModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>CASE NOTESHEET</h6>
          </h5>
          <br>
          <div class="row">

            <div class="col">
              <h6>Note List for Case ID</h6>
            </div>:
            <div class="col">{{caselist['case_id']}}</div>
            <div class="col-1"></div>

          </div>

        </div>



        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>
            <div class="flex-container">

              <div class="flex-child black">
                <br>


                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Inspection Note</h6>
                  </div>
                  <div class="col">
                    {{noticeArr[0]?.insp_offi_note}}
                  </div>
                  <div class="col-1"></div>

                </div>


                <br>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Notice To</h6>
                  </div>:
                  <div class="col">
                    <input type='form-control' placeholder="Type Name of Owner(if not available)"
                      [(ngModel)]="noticeObj['owner_name']" disabled>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Notice Under Act</h6>
                  </div>:
                  <div class="col">
                    <input type='form-control' placeholder="Type Name of Owner(if not available)"
                      [(ngModel)]="noticeObj['notice_under_act']" disabled>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Sections</h6>
                  </div>:
                  <div class="col">

                    <input type='form-control' placeholder="Type Name of Owner(if not available)"
                      [(ngModel)]="noticeObj['sections']" disabled>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <button type="button" class="btn btn-primary " data-dismiss="modal"
                    (click)="submit_notice(noticeArr,noticeObj)" disabled>Generate</button>

                </div>
                <br>
              </div>
              <div class="flex-child black">
                <div class="row">
                  <h4 class="text-center" style="margin-left: 20%;margin-top: 5%;">LUCKNOW DEVELOPMENT AUTHORITY</h4>
                  <br>

                </div>
                <div class="row">
                  <h5 style="text-align: center;margin-left: 40%;">(Form-D)</h5>
                </div>
                <div class="row">
                  <h5 style="text-align: center;margin-left: 42%;">NOTICE</h5>
                </div>
                <div class="row">
                  <h6 style="text-align: right;margin-left:5%;">TO,</h6>
                </div>
                <div class="row">
                  <h6 style="text-align: right;margin-left:10%;">
                    {{noticeArr[0]?.owner_name}}
                  </h6>
                </div>
                <div class="row">
                  <h6 style="text-align: right;margin-left:10%;">
                    Address of {{noticeArr[0]?.owner_name}}
                  </h6>
                </div>
                <div class="row">
                  <h6 style="text-align: right;margin-left:10%;">
                    Notice Under Sections - {{noticeArr[0]?.sections}} of {{noticeArr[0]?.notice_under_act}}
                  </h6>
                </div>
                <div class="row">
                  <h6 style="margin-left:10%;">

                    <label for="exampleFormControlTextarea1"></label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="1" cols="60" name="text"
                      [(ngModel)]="noticeArr['text']" #text="ngModel"></textarea>

                  </h6>
                </div>
                <br> <br> <br> <br> <br> <br> <br> <br> <br> <br>
                <div class="row">
                  <h6 style="margin-right:100%;margin-left: 70%;">
                    {{noticeArr[0]?.notice_under_act}}
                  </h6>
                </div>




                <div>
                </div>
              </div>
            </div>


            <br>

          </div>


          <br>

          <div class="modal-footer" style="justify-content: center;">
            <button type="button" class="btn btn-outline-primary " data-dismiss="modal"
              (click)="submit_notice(noticeArr,noticeObj)">Submit</button>

          </div>

        </div>
        <div class="modal-footer " style="justify-content: center;">
          <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

        </div>

      </div>
    </div>
  </div>



  <!-- notice second page -->

  <div class="modal fade" id="myModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 93%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>GENERATE NOTICE</h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>
            <div class="flex-container">
              <div class="flex-child black">
                <br>

                <div class="row">
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Complaint ID</h6>
                  </div>:
                  <div class="col">{{noticeArr[0]?.complain_id}}</div>
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Complaint Date</h6>
                  </div>:
                  <div class="col">{{noticeArr[0]?.comp_assign_date }}</div>
                  <div class="col-1"></div>
                </div>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Case ID</h6>
                  </div>:
                  <div class="col">{{noticeArr[1]?.case_id}}</div>
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Case Filing Date</h6>
                  </div>:
                  <div class="col">{{case_f_date}}</div>
                  <div class="col-1"></div>
                </div>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Zone</h6>
                  </div>:
                  <div class="col">{{noticeArr[1]?.zone_nm}}</div>
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Complaint Type</h6>
                  </div>:
                  <div class="col">{{noticeArr[0]?.complian_type}}</div>
                  <div class="col-1"></div>
                </div>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Property Address</h6>
                  </div>:
                  <div class="col">{{noticeArr[0]?.property_address}}</div>
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Photo by Complainer</h6>
                  </div>:
                  <div class="col">

                    <button (click)="view_photo(1)" class="btn btn-outline-success">View Photo</button>&nbsp;&nbsp;

                  </div>
                  <div class="col-1"></div>
                </div>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Inspection Officer</h6>
                  </div>:
                  <div class="col">{{noticeArr[0]?.inspection_officer}} </div>
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Photo by Inspector</h6>
                  </div>:
                  <div class="col">
                    <button (click)="view_photo(2)" class="btn btn-outline-success">View Photo</button>&nbsp;&nbsp;

                  </div>
                  <div class="col-1"></div>
                </div>

                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>Is Notice Required ?</h6>
                  </div>:
                  <div class="col">{{noticeArr[0]?.is__notice_required}}</div>
                  <div class="col-1"></div>
                  <div class="col">
                    <h6>View Inspection Report </h6>
                  </div>:
                  <div class="col">
                    <button (click)="view_photo1(noticeArr)" class="btn btn-outline-success">View </button>&nbsp;&nbsp;

                  </div>
                  <div class="col-1"></div>
                </div>




                <br>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col-3">
                    <h6>Notice To</h6>
                  </div>:
                  <div class="col-5">
                    <input class='form-control' placeholder="Type Name of Owner(if not available)"
                      [(ngModel)]="noticeObj['owner_name']" disabled>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col-3">
                    <h6>Notice Under Act</h6>
                  </div>:

                  <div class="col">

                    <input class='form-control' placeholder="Type Name of Owner(if not available)"
                      [(ngModel)]="noticeObj['notice_under_act']" disabled>

                  </div>


                </div>
                <div class="row mt-2">
                  <div class="col-1"></div>
                  <div class="col-3">
                    <h6>Sections</h6>
                  </div>:
                  <div class="col-5">

                    <input class='form-control' placeholder="Type Name of Owner(if not available)"
                      [(ngModel)]="noticeObj['section_cd']" disabled>
                  </div>
                </div>
                <!--3
 <div class="row mt-2">
  <div class="col-1"></div>
  <button type="button" class="btn btn-primary "data-dismiss="modal"(click)="submit_notice(noticeArr,noticeObj)" disabled>Generate</button>

</div>
3-->
                <br>
              </div>



              <div class="flex-child black">
                <div class="row">


                  <ckeditor data="<h4>Write</h4>" name="content" [(ngModel)]="htmlContent" style="width: 670px;
      margin-left: 15px;"></ckeditor>
                </div>

              </div>



            </div>



            <br>

          </div>



          <br>

          <div class="modal-footer" style="justify-content: center;">
            <button type="button" class="btn btn-outline-primary " data-dismiss="modal"
              (click)="submit_notice(noticeArr,noticeObj)">Submit</button>
            <!--4 <button type="button" class="btn btn-outline-primary "data-dismiss="modal"(click)="print_notice()"  >Print</button>  4-->

          </div>

        </div>
      </div>
    </div>


  </div>


  <div class="modal fade" id="myModalUpNotice" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 93%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>UPDATE NOTICE</h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>


            <br>
            <div class="row" style="justify-content: center;">

              <div class="col-6">
                <ckeditor data="<h4>Write</h4>" name="content" [(ngModel)]="htmlContent"></ckeditor>
              </div>

            </div>

            <div class="modal-footer " style="justify-content: center;">
              <div><button type="button" class="btn btn-outline-primary " data-dismiss="modal"
                  (click)="uoDateNotice()">Update</button> </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="modal fade" id="myModalUpNoticeP" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"> -->
  <!-- AVINASH MISHRA -->

  <div class="modal fade" id="myModalUpNoticeP" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 93%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>PRINT NOTICE</h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>


            <br>

            <div class="row" style="justify-content: center;">
              <div class="col-5" style="flex-basis: 0.666667%;">
                <!-- <ngx-qrcode
          [elementType]="elementType"
          [errorCorrectionLevel]="correctionLevel"
          [value]="value"
          cssClass="bshadow"></ngx-qrcode><br>
        <br> <textarea [(ngModel)] = "value"></textarea> -->
                <!-- <a download="qr1.jpeg" [href]="value" id="qrDownload" class="btn btn-primary w-100 mt-2">Download QR 1 Code</a>
        <a  [href]="value"  download>Download QR 2 Code</a> -->
                <!-- qr download button  -->
                <div class="card" style="width: 20rem;">
                  <!-- <img class="card-img-top mx-auto mt-4" style="width: 150px;" src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&amp;data=https://example.com" alt="Card image cap"> -->

                  <!-- oc  -->
                  <!-- <ngx-qrcode
          [elementType]="elementType"
          [errorCorrectionLevel]="correctionLevel"
          [value]="value"
          cssClass="bshadow"></ngx-qrcode> -->

                  <!-- ec  -->
                  <div class="card-body">
                    <h4 class="card-title">QR Code</h4>
                    <!-- <p class="card-text">Download your code by clicking on the button below</p>
            <button id="qrdownload" class="btn btn-info" (click)="downloadQr()">Download QR Code</button> -->

                  </div>
                </div>
                <!-- qr download -->

              </div>
              <div class="col-6">
                <ckeditor data="<h4>Write</h4>" name="content" [(ngModel)]="htmlContent"></ckeditor>
              </div>
            </div>
            <div class=" modal-footer " style="justify-content: center;">
              <div><button type="button" class="btn btn-outline-primary " data-dismiss="modal"
                  (click)="print_notice()">Download</button> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="myModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>UPDATE CASE HEARING DETAILS</h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>
            <div class="modal-header" style="justify-content: center;">
              <button type="button" class="btn btn-outline-primary " (click)="UPH(objhearing)">Update Latest Hearing
                Date</button>
            </div>

            <div class="row">
              <div class="col-2"> </div>
              <div class="col-4 text-right">
                <h6>Case ID</h6>
              </div>:
              <div class="col-4 text-left">{{objhearing['case_id']}}</div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Case Filing Date</h6>
              </div>:
              <div class="col-4 text-left">{{objhearing['f_date']}}</div>

              <div class="col-2"></div>
            </div>
            <br>

            <br>
            <div class="row" *ngIf="last_h_flag">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Last Hearing Date</h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="date" disabled [(ngModel)]="objhearing['last_h_date']">
              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row" *ngIf="next_h_d_flag">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Next Hearing Date*</h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="date" [(ngModel)]="objhearing['next_h_date']">
              </div>
              <!-- <div class="col-2 "> -->
              <a (click)="rout()" role="button" style="color:rgb(60, 0, 255)">Check Availability
              </a>
              <!-- <a  routerLink="/icms/cause-list" routerLinkActive="active">Check Availability</a> -->
              <!-- <a [routerLink]="['icms/cause-list']">abc</a> -->
              <!-- <button type="button" class="btn btn-outline-primary "(click)="rout()" >Check Availability</button> -->
              <!-- </div> -->
              <!-- <div class="col-2"></div> -->
            </div>
            <br>
            <div class="row" *ngIf="next_h_t_flag">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Next Hearing Time*</h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="time" [(ngModel)]="objhearing['next_h_t']">
              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Note about Hearing</h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="text" [(ngModel)]="objhearing['note_about']">

              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Attachment*</h6>
              </div>:
              <div class="col-3">
                <input type="file" multiple #file (change)="onFileUpload($event,file.files)" id="avinash"
                  class="form-control" placeholder="Choose Photo" accept=".png,.jpeg,.jpg,.pdf">

              </div>
              <div class="col-1 text-right">

              </div>



              <br>


              <div *ngFor="let ln of uploader3; let i = index;">
                <div class="row">
                  <div class="col-2"></div>
                  <div class="col-4 text-right">
                    <h6>Attachment*</h6>
                  </div>:
                  <div class="col-3">
                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader" class="form-control"
                      style="border-color: black" (change)="onFileUpload($event,file.files)">
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer " style="justify-content: center;">



              <button type="button" class="btn btn-outline-primary " data-dismiss="modal"
                (click)="UPDATE_H(objhearing)">SUBMIT</button>

              <button type="button" class="btn btn-outline-danger  " data-dismiss="modal">Close</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="myModal5" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>ADD/UPDATE DEFENDENT</h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>



            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Case ID</h6>
              </div>:
              <div class="col-4 text-left">{{defobj['case_id']}}</div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Defendant Name</h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="text" disabled [(ngModel)]="defobj['def_name']">


              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Father/Husband Name</h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="text" [(ngModel)]="defobj['father_name']">
              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Date of Birth*</h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="date" [(ngModel)]="defobj['dob']">
              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Defendant Mobile No</h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="mobile" disabled [(ngModel)]="defobj['def_mob']">
              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <!-- <div class="row">
            <div class="col-2"></div>
            <div class="col-4 text-right"><h6>Defendant Email</h6></div>:
            <div class="col-4 text-left">
              <input class="form-control" type="email" [(ngModel)]="defobj['def_email']">
            </div>

            <div class="col-2"></div>
          </div> -->

            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Defendent Email : </h6><span style="color: red;">*</span>
              </div>

              <div class="col-4 text-left">
                <input id=" email" required [email]="Obj['email']!==''" type="text" class="form-control" name="email"
                  #email="ngModel" placeholder=" abc@example.com" [(ngModel)]="defobj['def_email']">
                <span style="color: red;" class="help-block" *ngIf="email.errors?.['required'] && email.touched">Email
                  is
                  required</span>
                <span style="color: red;" class="help-block" *ngIf="email.errors?.['email'] && email.touched">Email is
                  invalid</span>
              </div>
              <div class="col-2"></div>
            </div>

            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Proof Of ID*</h6>
              </div>:
              <div class="col-4 text-left">
                <ng-select [items]="main2.codeValueTechObj['ICMS0010']" bindLabel="value" bindValue="code"
                  [multiple]="false" placeholder="" [(ngModel)]="defobj['id_proof']" [selectableGroup]="true"
                  [selectableGroupAsModel]="false" [closeOnSelect]="true" name="cadre_code">
                </ng-select>
              </div>

              <div class="col-2"></div>
            </div>

            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Upload File*</h6>
              </div>:
              <div class="col-3">
                <input #file type="file" id="avinashDef" name="pimage" ng2FileSelect [uploader]="uploader"
                  class="form-control" style="border-color: black" (change)="onFileUpload($event,file.files)"
                  accept=".png,.jpeg,.jpg,.pdf">
              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <br>
          </div>
          <div class="modal-footer " style="justify-content: center;">






            <button type="button" class="btn btn-outline-primary " data-dismiss="modal"
              (click)="UPDATE_D(defobj)">SUBMIT</button>

            <button type="button" class="btn btn-outline-danger  " data-dismiss="modal">Close</button>

          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="myModal6" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>CASE DETAILS</h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>

            <div class="row" style="justify-content: center;">
              <!-- <div class="col-1"></div> -->

              <div>
                <span> <b> Court Details :</b> Authority Court of VP {{allcasedetailObj["cs_zone_vp"]}}&nbsp;
                  {{allcasedetailObj["zone"]}}, Lucknow
                </span>
              </div>
              <div></div>
            </div>
            <br>
            <div class="row">
              <div class="col-1"></div>
              <div class="col">
                <h6>Case ID</h6>
              </div>:
              <div class="col">{{allcasedetailObj["case_id"]}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint ID</h6>
              </div>:
              <div class="col">{{allcasedetailObj[0]?.complain_id}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint Date</h6>
              </div>:
              <!-- <div class="col">{{allcasedetailObj[0]?.complain_date | date :'mediumDate'}}</div> -->
              <div class="col">{{allcasedetailObj["complain_date"]}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Case Filing Date</h6>
              </div>:
              <div class="col">{{allcasedetailObj["filling_date"]}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Zone</h6>
              </div>:
              <div class="col">{{allcasedetailObj["zone"]}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Property Address</h6>
              </div>:
              <div class="col">{{allcasedetailObj[0]?.property_address}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Vihit Pradhikari Name</h6>
              </div>:
              <div class="col">{{allcasedetailObj["cs_zone_vp"]}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Defendant Name</h6>
              </div>:
              <div class="col">{{allcasedetailObj[0]?.owner_name}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Last Hearing Date</h6>
              </div>:
              <div class="col">{{allcasedetailObj["old_h_date_new"]}} </div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Next Hearing date</h6>
              </div>:
              <div class="col">{{allcasedetailObj["next_h_date"]}}
              </div>
              <div class="col-1"></div>
            </div>


            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Last Hearing Update</h6>
              </div>:
              <div class="col">
                {{allcasedetailObj[0]?.description}}
              </div>
              <div class="col-1"></div>
              <div class="col"></div>
              <div class="col"></div>
              <div class="col-1"></div>

            </div>




            <br>
          </div>

          <div class="modal-footer" style="justify-content: center;">



            <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button>

          </div>

        </div>
      </div>
    </div>
  </div>



  <div class="modal fade" id="myModal7" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>PENALITY IMPOSITION</h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>



            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Case ID</h6>
              </div>:
              <div class="col-4 text-left">
                {{penaltyObj['case_id']}}


              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Penality Imposed By<span style="color: red;">*</span></h6>
              </div>:
              <div class="col-4 text-left">
                {{this.Username}}


              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Penality Imposition Date<span style="color: red;">*</span></h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="date" [(ngModel)]="penaltyObj['pen_impos_date']">
              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Penality Amount (Rs)<span style="color: red;">*</span></h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="number" [(ngModel)]="penaltyObj['pen_amut']">
              </div>

              <div class="col-2"></div>
            </div>
            <br>



            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Last Date for Payment<span style="color: red;">*</span></h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="date" [(ngModel)]="penaltyObj['last_date']">

              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Reason for Penality</h6>
              </div>:
              <div class="col-4 text-left">
                <input class="form-control" type="text" [(ngModel)]="penaltyObj['reason_for_pen']">

              </div>

              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-4 text-right">
                <h6>Attachment*</h6>
              </div>:

              <div class="col-3">
                <input type="file" multiple #file (change)="onFileUpload1($event,file.files)" class="form-control"
                  placeholder="Choose Photo" accept=".png,.jpeg,.jpg,.pdf" id="avipenality">

              </div>


              <div class="col-1 text-right">

              </div>

            </div>



            <br>


            <div *ngFor="let ln of uploader5; let i = index;">
              <div class="row">
                <div class="col-2"></div>
                <div class="col-4 text-right">
                  <h6>Attachment*</h6>
                </div>:
                <div class="col-3">
                  <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader5" class="form-control"
                    style="border-color: black" (change)="onFileUpload($event,file.files)"
                    accept=".png,.jpeg,.jpg,.pdf">
                </div>
              </div>
              <br>
            </div>
          </div>
          <div class="modal-footer " style="justify-content: center;">






            <button type="button" class="btn btn-outline-primary " data-dismiss="modal"
              (click)="submit_pen(penaltyObj)">SUBMIT</button>

            <button type="button" class="btn btn-outline-danger  " data-dismiss="modal">Close</button>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="myModal8" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 90%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6><b>UPLOAD COURT ORDER</b></h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>

            <div class="row">
              <div class="col-1"></div>
              <div class="col-1">
                <h6>Case ID :-</h6>
              </div>
              <div class="col-1">{{orderupload["case_id"]}}</div>
              <div class="col-1"></div>
              <div class="col-5">
                <div class="row" *ngIf="court_order_id">
                  <div class="col-6">
                    <h6>Court Order ID :-<span style="color: red;">*</span></h6>
                  </div>
                  <div class="col-6"> <ng-select [items]="order_idArr" bindLabel="co_ord_id" bindValue="co_ord_id"
                      [multiple]="false" placeholder="" [(ngModel)]="orderupload['court_order_id']"
                      [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="cadre_code"
                      (click)="order_id_data(orderupload)" [ngModelOptions]="{standalone: true}">
                    </ng-select></div>
                </div>
              </div>
              <div class="col-2">
                <button type="button" class="btn btn-primary" (click)="select()" style="margin-left:90px">Update order
                </button>

              </div>
              <div class="col-1"></div>
            </div>
            <div class="row">
              <!-- <a role="button" (click)="select()" style="color:rgb(60, 0, 255)">Update court order data</a> -->
              <!-- <button type="button" class="btn btn-primary"(click)="select()" style="margin-left:100px">Update  order </button> -->
              <!-- <a role="button" (click)="select()" style=" color:rgb(60, 0, 255)">Update order</a> -->
            </div>
            <br>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-2">
                <h6><b>Compilation Time Limit :-</b></h6>
              </div>
            </div>
            <div class="row">
              <div class="col-3"></div>
              <div class="col-1">
                <h6>Date</h6>
              </div>
              <div class="col-2">

                <input class="form-control" disabled={{aviDate}} type="date"
                  [(ngModel)]="orderupload['comp_time_date']">
                <!-- <input class="form-control" type="date" [(ngModel)]="orderupload['comp_time_date']"> -->
              </div>
              <input type="checkbox" [(ngModel)]="aviCheck" (click)="aviUploadOrder()" checked />
              <div class="col-1"><b>OR</b></div>
              <div class="col-2">
                <h6> No. of Days </h6>
              </div>
              <div class="col-2">
                <input type="number" class="form-control" disabled={{!aviDate}} [(ngModel)]="orderupload['no_of_days']">
                <!-- <input class="form-control" [(ngModel)]="orderupload['no_of_days']" > -->
              </div>
              <div class="col-1"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Court Order Type :-<span style="color: red;">*</span></h6>
              </div>
              <div class="col-3"><ng-select [items]="main2.codeValueTechObj['ICMS009']" bindLabel="value"
                  bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="orderupload['court_order_type']"
                  [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="cadre_code">
                </ng-select></div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Court Order Date :-<span style="color: red;">*</span></h6>
              </div>
              <div class="col-2"> <input class="form-control" type="date" [(ngModel)]="orderupload['court_order_date'] "
                  [ngModelOptions]="{standalone: true}">
              </div>
              <div class="col-2"></div>
            </div>

            <br>

            <div class="row">
              <div class="col-1"></div>
              <div class="col-2 ">
                <h6>Court Order Summary :-</h6>
              </div>
              <div class="col-3  ">
                <!-- <input class="form-control" [(ngModel)]="orderupload[0]['co_summary']" > -->

                <input class="form-control" [(ngModel)]="orderupload['court_or_sumry']">
              </div>
              <div class="col-6"></div>

            </div>
            <br>



            <div class="row">
              <div class="col-12">
                <h5 style="margin-left: 3%;"><b>Address of Property </b> </h5>
              </div>
            </div>


            <br>
            <div class="row">
              <div class="col text-right">
                <h6>Address Line1 :-</h6>
              </div>
              <div class="col text-left">
                {{orderuploadObj[0]?.property_address}}

                <!-- <input class="form-control " [(ngModel)]="orderupload['add1']" > -->
              </div>
              <div class="col text-right">
                <h6>Address Line2 :-</h6>
              </div>
              <div class="col text-left "><input class="form-control" [(ngModel)]="orderupload['add2']"></div>
              <div class="col"></div>
            </div>
            <br>
            <div class="row">
              <div class="col text-right">
                <h6>Police Station :-</h6>
              </div>
              <div class="col text-left">
                {{orderuploadObj[0]?.police_station}}
              </div>
              <div class="col text-right">
                <h6>Zone :-</h6>
              </div>
              <div class="col text-left ">
                {{this.orderuploadObj['zone_name']}}
              </div>
              <div class="col"></div>
            </div>


            <br>
            <div class="row">
              <div class="col text-right">
                <h6>District :-</h6>
              </div>
              <div class="col text-left">
                {{orderuploadObj[0]?.dist}}
              </div>
              <div class="col text-right">
                <h6>State :-</h6>
              </div>
              <div class="col text-left ">
                {{orderuploadObj[0]?.state}}
              </div>
              <div class="col"></div>
            </div>
            <br>
            <div class="row">
              <div class="col text-right">
                <h6>Country :-</h6>
              </div>
              <div class="col text-left">
                {{orderuploadObj[0]?.country}}
              </div>
              <div class="col text-right">
                <h6>Pin Code :-</h6>
              </div>
              <div class="col text-left ">
                {{orderuploadObj[0]?.pin_code}}
              </div>
              <div class="col"></div>
            </div>
            <br>
            <div class="row">

              <div class="col text-right">
                <h6 style="margin-left: 29%;"><b>Landmark :-</b></h6>
              </div>
              <div class="col text-left   ">
                <h6 style="margin-inline-start:0% ;"> {{orderuploadObj[0]?.landmark}}</h6>
              </div>
              <div class="col-7"></div>
            </div>
            <br>

            <div class="row">
              <div class="col-12">
                <h5 style="margin-left: 3%;"><b>Opponent Party Details</b></h5>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>

              <div class="col text-right">
                <h6>Name</h6>
              </div>
              <div class="col-4 text-left ">
                {{orderuploadObj[0]?.owner_name}}
              </div>
              <div class="col"></div>
              <div class="col"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col text-right">
                <h6>Phone/Mobile No.</h6>
              </div>
              <div class="col-4 text-left">
                {{orderuploadObj[0]?.owner_mobile}}
              </div>
              <div class="col"></div>
              <div class="col"></div>
            </div>
            <br>

            <br>


            <div class="row mt-2" *ngIf="upload_data">
              <div class="col-1"></div>
              <div class="col-2 text-right">
                <h6>Court Order Copy*</h6>
              </div>
              <div class="col-3 text-left">
                <input type="file" multiple #file (change)="onFileUploadorder($event,file.files)" class="form-control"
                  placeholder="Choose Photo" accept=".png,.jpeg,.jpg,.pdf" id="aviuploadorder">

              </div>
              <div class="col"> </div>
              <div class="col"> </div>




              <br>



            </div>


            <br>
          </div>

          <div class="modal-footer" style="justify-content: center;">






            <button type="button" class="btn btn-outline-primary " data-dismiss="modal"
              (click)="upload_court_order(orderuploadObj,orderupload)">Submit</button>

            <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button>

          </div>

        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="myModal9" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>GENERATE NOTICE</h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>


            <div class="row">
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint ID</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.complain_id }}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint Date</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.comp_assign_date}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Case ID</h6>
              </div>:
              <div class="col">{{noticeArr[1]?.case_id}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Case Filing Date</h6>
              </div>:
              <div class="col">{{case_f_date}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Zone</h6>
              </div>:
              <div class="col">{{noticeArr[1]?.zone_nm}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint Type</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.complian_type}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Property Address</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.property_address}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Photo by Complainer</h6>
              </div>:
              <div class="col">

                <button (click)="view_photo(1)" class="btn btn-outline-success">View Photo</button>&nbsp;&nbsp;

              </div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Inspection Officer</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.inspection_officer}} </div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Photo by Inspector</h6>
              </div>:

              <div class="col-1"></div>
            </div>

            <div class="row mt-2">
              <div class="col-1"></div>
              <div class="col">
                <h6>Is Notice Required ?</h6>
              </div>:
              <div class="col">{{noticeArr[0]?.is__notice_required}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>View Inspection Report </h6>
              </div>:

              <div class="col-1"></div>
            </div>
            <!--2
           <div class="row mt-2">

              <div class="col-1"></div>

              <div class="col"><h6>Inspection Note</h6></div>

              <div class="col">

                {{noticeArr[0]?.insp_offi_note}}

              </div>

              <div class="col-1"></div>


              <div class="col-1"></div>

            </div>

 2-->

            <br>
            <div class="row">
              <div class="col-1"></div>
              <h5><b>Notice List</b></h5>
            </div>
            <div class="row">
              <div class="col-1"></div>
              <div class="tab-content">
                <div class="tab-pane fade show active" id="tab-1">

                  <!-- Search form -->
                  <mat-form-field style="margin-left: 2%">
                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                  </mat-form-field>





                  <div>

                    <!-- <div class=" example-container">
                  <table mat-table [dataSource]="dataSource" matSort  class="w-100"  >

                      <ng-container matColumnDef="s.no">
                          <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> S.No </th>
                          <td mat-cell *matCellDef="let element ; let i = index "> {{i+1}} </td>
                      </ng-container>

                      <ng-container matColumnDef="comp_id">
                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Complain ID </th>
                                             </ng-container>



                      <ng-container matColumnDef="case_id">
                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Case ID </th>
                                             </ng-container>




                      <ng-container matColumnDef="filling_date" >
                        <th mat-header-cell *matHeaderCellDef  class="left" mat-sort-header>Filling Date </th>

                    </ng-container>



                      <ng-container matColumnDef="auth_court">
                          <th mat-header-cell  *matHeaderCellDef class="left" mat-sort-header> Authorized Court </th>
                                                </ng-container>



                      <ng-container matColumnDef="next_hearing_date">
                          <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Next Hearing Date</th>

                      </ng-container>

                      <ng-container matColumnDef="case_status">
                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Case Status</th>

                    </ng-container>

                      <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action
                        </th>
                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                          <button class="btn btn-outline-primary"  (click)="print(element)">Print
                          </button>&nbsp;&nbsp;
                            <button class="btn btn-outline-info" (click)="update(element)" *ngIf="element.not_status=='NA'">Update
                            </button>&nbsp;&nbsp;
                            <button class="btn btn-outline-primary" (click)="forward(element)"  *ngIf="element.not_status=='NA'">Forward
                            </button>&nbsp;&nbsp;

                        </td>






                    </ng-container>


                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  </div> -->

                  </div>



                </div>







              </div>


            </div>

            <br>
          </div>
        </div>
        <div class="modal-footer" style="justify-content: center;">






          <button type="button" class="btn btn-outline-primary " data-dismiss="modal"
            (click)="generate_date(noticeArr,noticeObj)">Generate New Notice</button>

          <!-- <button type="button" class="btn btn-outline-primary "data-dismiss="modal"(click)="upload_signed(noticeArr,noticeObj)" >Upload Signed Notice</button> -->



        </div>

      </div>
    </div>
  </div>


  <div class="modal fade" id="myModal10" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>DOCUMENTS / EVIDANCES LIST
            </h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>


            <div class=" example-container">
              <table mat-table [dataSource]="dataSource2" matSort class="w-100">

                <ng-container matColumnDef='s.no'>
                  <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> S.No </th>
                  <td mat-cell *matCellDef="let element ; let i = index "> {{i+1}} </td>
                </ng-container>

                <ng-container matColumnDef='document_type'>
                  <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Document type </th>
                  <td mat-cell *matCellDef="let element ; let i = index ">{{element.doc_type}}</td>
                </ng-container>



                <ng-container matColumnDef='document_format'>
                  <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Document Format </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.file_name}}
                  </td>
                </ng-container>

                <ng-container matColumnDef='description'>
                  <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Description </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.description}}
                  </td>
                </ng-container>
                <ng-container matColumnDef='verify_status'>
                  <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Verification Status </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.defendant_docs_status}}
                  </td>
                </ng-container>



                <ng-container matColumnDef='action'>
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    <button class="btn btn-outline-primary" (click)="view_doc(element)">View
                    </button>&nbsp;&nbsp;
                    <button class="btn btn-outline-primary" (click)="viewStatus(element)"
                      *ngIf="element['defendant_docs_status']!='ACTIVE'">View Status
                    </button>&nbsp;&nbsp;
                    <button class="btn btn-outline-info" (click)="reject(element)"
                      *ngIf="apprflag && element['defendant_docs_status']=='ACTIVE'">Reject
                    </button>&nbsp;&nbsp;
                    <button class="btn btn-outline-info" (click)="verify(element)"
                      *ngIf="apprflag && element['defendant_docs_status']=='ACTIVE'"> Add Approval
                    </button>&nbsp;&nbsp;
                  </td>



                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
              </table>
            </div>
            <div class="modal-footer " style="justify-content: center;">


              <!-- <div class="row">
            <div class="col-sm-6 text-center">
            <button type="button" class="btn btn-primary">Generate Notice</button>
            </div>
            <div class="col-sm-6 text-right">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div> -->
              <!-- <div class="flex-parent jc-center">
            <button type="submit" class="green margin-right">Confirm</button>
            <button type="submit" class="magenta">Cancel</button>
          </div> -->



              <!-- <button type="button" class="btn btn-outline-primary "data-dismiss="modal"(click)="UPDATE_H(objhearing)" >SUBMIT</button> -->

              <button type="button" class="btn btn-outline-danger  " data-dismiss="modal">Close</button>

            </div>
          </div>
          <!-- <div class="modal-footer " style="justify-content: center;">






        <button type="button" class="btn btn-outline-danger  " data-dismiss="modal" >Close</button>

      </div> -->
        </div>
      </div>
    </div>
  </div>




  <div class="modal fade" id="myModal11" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content " style="height:1000px;margin-top: 5%;">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>NEW NOTE
            </h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col text-right">
                New Note

              </div>
              <div class="col text-left">

                <h6 style="margin-left:10%;">

                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="1" cols="70" name="text"
                    [(ngModel)]="caselist['text_notesheet']" #text="ngModel"></textarea>

                </h6>
              </div>
              <div class="col-2"></div>
              <div class="col-2"></div>
            </div>
            <br>
            <div class="row">
              <div class="col-2"></div>
              <div class="col text-right">
                Attachment*

              </div>
              <div class="col-2 text-left">

                <input type="file" multiple #file (change)="onFileUploadnotesheet($event,file.files)"
                  class="form-control" placeholder="Choose Photo" accept=".png,.jpeg,.jpg,.pdf">

              </div>
              <div class="col-2"></div>
              <div class="col-2"></div>
            </div>


            <div class="modal-footer " style="justify-content: center;">






              <button type="button" class="btn btn-outline-primary " data-dismiss="modal"
                (click)="submit_notesheet(caselist)">SUBMIT</button>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="myModal12" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>VIEW DOCUMENT
            </h6>
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>
            <div class="row">

              <div class="col-12 text-center">
                <img [src]="imgURL4" />



              </div>


            </div>
            <br>




          </div>
        </div>
      </div>
    </div>
  </div>





  <div class="modal fade" id="staticBackdrop" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Photo of site</h6>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">

            <table class="table table-bordered text-center">
              <thead class="justify-content-center">
                <tr>

                  <th class="text-center">File Name</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of complaint_image;let i =index">

                  <td>Image-{{i+1}}</td>
                  <td><button class="btn btn-outline-success" (click)="view(item)">View</button></td>

                </tr>

              </tbody>
            </table>


          </div>
          <div class="row justify-content-center" *ngIf="f">

            <img [src]="imgURL2" height="300px" width="500px" />


          </div>





        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" (click)="change()" data-dismiss="modal">Close</button>

        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="staticBackdrop1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Photo of site</h6>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field style="margin-left: 2%">
            <input matInput (keyup)="applyFilter10($any($event.target).value)" placeholder="Filter">
          </mat-form-field>
          <div class=" example-container">
            <table mat-table [dataSource]="dataSource10" matSort #sortCol2="matSort">
              <ng-container matColumnDef="case_id">
                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                  Case ID

                </th>
                <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                  {{ element.case_id }}</td>
              </ng-container>
              <ng-container matColumnDef="document_name">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                  Document Name
                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                  {{ element.document_name}} </td>
              </ng-container>

              <ng-container matColumnDef="document_type_code">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                  Document Type Code
                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                  {{ element.document_type_code}} </td>
              </ng-container>



              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                  Action </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">

                  <button class="btn btn-primary" (click)="view_d1(element)">View</button>



                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns3;">
              </tr>
            </table>
            <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
            </mat-paginator>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <img [src]="imgURL" />



            </div>
          </div>





        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" (click)="change()" data-dismiss="modal">Close</button>

        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="myModal15">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Approval</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <div class="modal-body">


          <div class="row" *ngIf="ApprovalUserService.vendorflag==false && ApprovalUserService.approvalflag==false">
            <div class="col-4 text-right">
              <h6>Select Forwarded To :</h6>
            </div>
            <div class="col-4">
              <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true"
                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
              </ng-select>
            </div>




          </div>
          <br>
          <div class="row">
            <div class="col-4 text-right">
              <h6>Remark :</h6>
            </div>
            <div class="col-4">
              <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                placeholder="Enter Your Remark" class="form-control"></textarea>

            </div>


          </div>
          <br>

          <div>
            <table class="table table-bordered"
              style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
              <thead>
                <tr style="background-color:  white;">
                  <th style="width: 20%;">S No.</th>
                  <th style="width: 20%;">Decription</th>
                  <th style="width: 60%;">Action</th>
                </tr>
              </thead>


              <tbody>
                <tr *ngFor="let ln of undertakings;let i = index">
                  <td>{{i+1}}</td>
                  <td>{{ln['desc']}}</td>
                  <td style="width: 60%;">
                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">

                  </td>
                </tr>

              </tbody>

            </table>



          </div>
          <br>
          <div class="row" style="border: black;">
            <div class="col-4 text-right">
              <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
            </div>
            <div class="col-4">
              Notify Them
            </div>
          </div>
          <br>
          <div class="row" *ngIf="ApprovalUserService.vendorflag==false &&  ApprovalUserService.approvalflag==false">
            <div class="col-12 text-center">
              <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit

              </button>
            </div>
          </div>
          <br>
          <div *ngIf="ApprovalUserService.vendorflag==false  && ApprovalUserService.approvalflag==true" class="row">
            <div class="col-12 text-center">
              <button class="btn btn-primary" data-dismiss="modal" (click)="approvedocment()">Approve
              </button>
            </div>
          </div>
          <br>
          <!-- <div *ngIf="vendorflag==true" class="row">
                  <div class="col-12 text-center">
                      <h6>OR</h6>
                  </div>
              </div> -->
          <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
            <div class="col-12 text-center">
              <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                Vendor</button>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          </div>



        </div>
        <br>
        <!-- <div class="row" style="border: black;">
                <div class="col-4 text-right" >
                    <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                </div>
                <div class="col-4">
                    Notify Them
                </div>
            </div>
            <br> -->
        <!-- <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                <div class="col-12 text-center">
                    <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit

                    </button>
                </div>
            </div>
            <br> -->

        <!-- <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                <div class="col-12 text-center">
                    <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                        Vendor</button>
                </div>
            </div> -->
        <!-- Modal footer -->
        <!-- <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div> -->

      </div>
    </div>
  </div>
  <div class="modal" id="myModal13">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Status</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <div class="modal-body">

          <div class="container">
            <a *ngFor="let ob of statusArr;let i=index">
              <div class="step completed" *ngIf="ob.status=='APPROVED'">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="barblue" style="color: blue;margin-left: 40%;" *ngIf="i <statusArr.length-1"></div>
                </div>

                <div class="content">
                  {{i+1}} - {{this.main.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}

                </div>
              </div>
              <div class="step" *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'">
                <div class="v-stepper">
                  <div class="circles"></div>

                </div>

                <div class="content">
                  {{i+1}} - {{this.main.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}


                </div>
              </div>
              <!-- active -->
              <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                  </div>
                </div>

                <div class="content">
                  {{i+1}} - {{this.main.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}


                </div>
              </div>
              <div class="step" *ngIf="ob.status=='PENDING'">
                <div class="v-stepper">
                  <div class="circle"></div>
                  <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                  </div>
                </div>

                <div class="content">
                  {{i+1}} - {{this.main.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}


                </div>
              </div>
            </a>


          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>


          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="myModal14">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Reject Approval</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <div class="modal-body">


          <div class="row">
            <div class="col-4 text-right">
              <h6>Remark :</h6>
            </div>
            <div class="col-4">
              <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                placeholder="Enter Your Remark" class="form-control"></textarea>

            </div>


          </div>
          <br>

          <div class="row" style="border: black;">
            <div class="col-4 text-right">
              <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
            </div>
            <div class="col-4">
              Notify Them
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-12 text-center">
              <button class="btn btn-primary" data-dismiss="modal" (click)="rejectedapproval()">REJECT

              </button>
            </div>
          </div>
          <br>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- Deepankar Screen strat -->
  <div class="modal fade" id="staticBackdropForNotice" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width:60%; ">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Forward Notice</h6>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <br>
          <div style="border: 2px solid gray; border-radius:10px;">
            <br>
            <br>
            <div class="row mt-2">
              <div class="col-3"></div>
              <div class="col-2"><b>Notice ID:</b></div>
              <div class="col-1"></div>
              <div class="col-4">
                <input type="text" class="form-control" [(ngModel)]="assign_Obj['not_id']" placeholder="" disabled>
              </div>
              <div class="col-2"></div>
            </div>
            <div class="row mt-2">
              <div class="col-3"></div>
              <div class="col-2"><b>Select User:</b></div>
              <div class="col-1"></div>
              <div class="col-4">
                <ng-select [items]="allSearchableEmp" [multiple]="false" placeholder="Select User" bindLabel="desc"
                  bindValue="user_id" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                  name="hierarchy" [(ngModel)]="assign_Obj1['user_id']">
                </ng-select>

              </div>
              <div class="col-2" *ngIf="role_cd_from=='PESH_RL'"> All Zone VP <input type="checkbox"
                  [(ngModel)]="all_zone_vp" (click)="checkBox()"> </div>
              <div class="col-2" *ngIf="role_cd_from=='VP'"> All Zone Peshkar <input type="checkbox"
                  [(ngModel)]="all_zone_vp" (click)="checkBox()"> </div>
            </div>
            <div class="row mt-2">
              <div class="col-3"></div>
              <div class="col-2"><b>Note:</b></div>
              <div class="col-1"></div>
              <div class="col-4">
                <textarea class="form-control" rows="4" cols="50" type="text" name="note" maxlength="150"
                  [(ngModel)]="assign_Obj1['note']" placeholder="note"></textarea>

                <div class="col-2"></div>
              </div>
              <br>
            </div>
            <br>
            <br>
            <div class="row justify-content-center mt-2">
              <button class="btn btn-outline-danger" data-dismiss="modal">Close</button>&nbsp;&nbsp;


              <button class="btn btn-outline-success" (click)="forwardNotice()">Forward</button>



            </div>
            <br>
            <br>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- Deepankar Screen end -->

  <!-- <div class="modal fade" id="myModal20" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #AED6F1;">
        <h5 class="modal-title" id="exampleModalLongTitle" ><h6>VIEW Notice Delivered
        </h6></h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
  <div style="border: 2px solid gray; border-radius:10px;">
    <br>
   <div class="row">

      <div class="col-12 text-center">
          <img [src]="imgURL5" />



      </div>
      <div class="col-12 text-center">
        {{imgURL5}}
        <iframe class="w-100" id="myFrame" ></iframe>
           <iframe src="C:\Users\test\lda\uploads\8\icms\images\notice-delivery\14-image_22_14_20.pdf" width="500" height="500"></iframe>



      </div>


   </div>
   <br>




    </div>
  </div>
  </div>
</div>
</div> -->

  <div class="modal fade" id="myModal21" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Notice Delivery Report</h6>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <div class="row justify-content-center mt-2">
              <h6>Case Details</h6>
            </div>
            <div class="row mt-3">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Complaint ID </h6>
              </div>:
              <div class="col-2">{{viewndreportObj['complain_app_id']}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint Date </h6>
              </div>:
              <div class="col">{{viewndreportObj['complain_date']}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Case ID </h6>
              </div>:
              <div class="col-2">{{viewndreportObj['case_id']}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Case Filing Date </h6>
              </div>:
              <div class="col-2">{{viewndreportObj['filing_date']}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Notice ID </h6>
              </div>:
              <div class="col-2">{{viewndreportObj['notice_id_f']}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Notice Date </h6>
              </div>:
              <div class="col-2">{{viewndreportObj['notice_date']}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Zone </h6>
              </div>:
              <div class="col-2">{{viewndreportObj['zone_name']}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6> </h6>
              </div>
              <div class="col-2"></div>
              <div class="col-1"></div>
            </div>


            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Property Address</h6>
              </div>:
              <div class="col-4">{{viewndreportObj['property_address']}}</div>

              <br>

            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Notice Act</h6>
              </div>:
              <div class="col-4">
                {{viewndreportObj['notice_act']}}
              </div>
              <div class="col-4"></div>
            </div>

            <br>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Notice Under Section</h6>
              </div>:
              <div class="col-4">
                {{viewndreportObj['section']}}
              </div>
              <div class="col-4"></div>
            </div>

            <br>
            <!-- <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Case Description</h6>
            </div>:
            <div class="col-4">
            {{viewndreportObj.notice_act}}
            </div>
            <div class="col-4"></div>
          </div>

          <br> -->
          </div>

          <div class="mt-2" style="border: 2px solid gray; border-radius:10px;">
            <div class="row justify-content-center mt-1">
              <h6>Notice Delivery Details </h6>
            </div>
            <br>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Notice Delivery Date </h6>
              </div>:
              <div class="col-2">{{viewndreportObj['notice_deliv_date']}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Notice Action </h6>
              </div>:
              <div class="col-2">{{viewndreportObj['notice_delivery_mode']}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Officer Name </h6>
              </div>:
              <div class="col-2">{{viewndreportObj['party_name']}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Officer Designation </h6>
              </div>:
              <div class="col-2">{{viewndreportObj['role_name']}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Latitude</h6>
              </div>:
              <div class="col-2">{{viewndreportObj['lat']}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Longitude </h6>
              </div>:
              <div class="col-2">
                {{viewndreportObj['long']}}
              </div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Officer Note</h6>
              </div>:
              <div class="col-2">
                {{viewndreportObj['officer_note']}}
              </div>
              <div class="col-1"></div>
              <div class="col-2">

              </div>
              <div class="col-2">

                <!-- <button class="btn btn-sm btn-outline-success" (click)="view_memo_img()">View</button>&nbsp;&nbsp; -->
              </div>
              <div class="col-1"></div>
            </div>

            <br>

          </div>
          <!-- end -->

          <!-- inspection report after final inspection -->
          <div class="mt-2" style="border: 2px solid gray; border-radius:10px;">
            <div class="row justify-content-center mt-1">
              <h6>Notice Delivery Document</h6>
            </div>
            <br>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-3">
                <h6>Notice Delivery Document</h6>
              </div>:
              <div class="col-2"><button type="button" class="btn btn-outline-info"
                  (click)="viewndreportdocs(viewndreportObj['notice_id_f'])">View</button></div>
              <div class="col-1"></div>
              <div class="col-1">
                <h6> </h6>
              </div>:
              <div class="col-2"></div>
              <div class="col-1"></div>
            </div>
            <br>




          </div>
          <!-- end -->


          <div class="modal-footer m-auto d-block text-center">
            <button type="button" class="btn btn-outline-info" (click)="downloadReport()">Download</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="notice-deliverd_images" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Notice Delivered Documents</h6>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">

            <table class="table table-bordered text-center">
              <thead class="justify-content-center">
                <tr>
                  <th class="text-center">Image</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of viewndreportObj['docs']; let i =index">
                  <td>{{item['document_type_code']}}</td>
                  <td><button class="btn btn-outline-success" (click)="viewNoticeDelivereddocs(item)">View</button></td>

                </tr>

              </tbody>
            </table>


          </div>




          <div class="row justify-content-center" *ngIf="imageflag">

            <img [src]="imgURL5" height="500px" width="700px" />


          </div>





        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

        </div>
      </div>
    </div>
  </div>



  <div class="modal fade" id="myModalreport" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #AED6F1;">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6> DETAILS</h6>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="border: 2px solid gray; border-radius:10px;">
            <div class="row justify-content-center mt-2">
              <h6>Complaint Details</h6>
            </div>
            <div class="row mt-3">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Complaint ID</h6>
              </div>:
              <div class="col-2">{{complaint_detail.complain_app_id}}</div>
              <div class="col-1"></div>
              <div class="col">
                <h6>Complaint Date</h6>
              </div>:
              <div class="col">{{complaint_detail.complain_date}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Zone</h6>
              </div>:
              <div class="col-2">{{complaint_detail.zone_name}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Complaint Type</h6>
              </div>:
              <div class="col-2">{{complaint_detail.complian_type}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Latitude</h6>
              </div>:
              <div class="col-2">{{complaint_detail.latitude}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Longitude</h6>
              </div>:
              <div class="col-2">{{complaint_detail.longitude}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Property Address</h6>
              </div>:
              <div class="col-2">{{complaint_detail.property_address}}</div>
              <div class="col-1"></div>
              <div class="col-2" *ngIf="public_photo['doc_type']=='Public'">
                <h6>Photo of Site</h6>
              </div>
              <span *ngIf="public_photo['doc_type']=='Public'">:</span>
              <div class="col-2" *ngIf="public_photo['doc_type']=='Public'">
                <button (click)="view_photosite()" class="btn btn-sm btn-outline-success">View</button>&nbsp;&nbsp;
              </div>
              <div class="col-1"></div>
            </div>

            <!-- <div class="row mt-2" *ngIf="complaint_detail['status']=='UNDER-INSPECTION'">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Assigned Officer</h6>
            </div>:
            <div class="col-2">{{complaint_detail.party_name}}</div>
            <div class="col-1"></div>
            <div class="col-2"></div>
            <div class="col-2"></div>
            <div class="col-1"></div>
          </div> -->
            <!-- <div class="row mt-2" *ngIf="complaint_detail['status']=='UNDER-INSPECTION'">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Officer's Designation</h6>
            </div>:
            <div class="col">
              (<span *ngFor="let item of userdetail; let i =index">{{item.role_name}}
                <span *ngIf="i!=userdetail.length-1 ">,</span></span>)

            </div>

          </div> -->
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Complaint Description</h6>
              </div>:
              <div class="col-4">{{complaint_detail.detail}}</div>

              <br>
              <!--  <div class="row justify-content-center">

            <div class="col-4"></div> -->
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Complaint Status</h6>
              </div>:
              <div class="col-4">
                <h6>{{complaint_detail.status}}</h6>
              </div>
              <div class="col-4"></div>
            </div>

            <br>
          </div>

          <!-- inspection report after final inspection -->
          <div class="mt-2" *ngIf="complaint_detail['status']=='INSPECTION REPORT SUBMITED'"
            style="border: 2px solid gray; border-radius:10px;">
            <div class="row justify-content-center mt-1">
              <h6>Inspection Details</h6>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Inspection ID</h6>
              </div>:
              <div class="col-2">{{inspection_detail.inspection_id}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Inspection Date</h6>
              </div>:
              <div class="col-2">{{inspection_detail.inspection_date}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Owner Name</h6>
              </div>:
              <div class="col-2">{{inspection_detail.owner_name}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Police Station</h6>
              </div>:
              <div class="col-2">{{complaint_detail.police_station}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Inspection officer</h6>
              </div>:
              <div class="col-2">{{inspection_detail.inspection_officer}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Inspection Photo</h6>
              </div>:
              <div class="col-2">
                <button class="btn btn-sm btn-outline-success"
                  (click)="view_inspection_photo()">View</button>&nbsp;&nbsp;
              </div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Officer's Designation</h6>
              </div>:
              <div class="col-2">
                <span *ngFor="let item of userdetail; let i =index"> {{ item['role_name'] }}
                  <span *ngIf="i!=userdetail.length-1">,</span></span></div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>View Memo</h6>
              </div>:
              <div class="col-2">
                <button class="btn btn-sm btn-outline-success" (click)= "view_memo_img()">View</button>&nbsp;&nbsp;
              </div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Is__Notice_Required</h6>
              </div>:
              <div class="col-2">{{inspection_detail.is__notice_required}}</div>
              <div class="col-1"></div>
              <div class="col-2">
                <h6>Notice Under Act</h6>
              </div>:
              <div class="col-2">{{inspection_detail.notice_under_act}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-2">
                <h6>Section</h6>
              </div>:
              <div class="col">

                <span *ngFor="let item of section_detail; let i =index">
                  {{item.section_cd}}
                  <span *ngIf="i!=section_detail.length-1 ">,</span>
                </span>
              </div>

            </div>
            <div class="row mt-2" *ngFor="let item of description  let i = index">
              <div class="col-2"></div>
              <div class="col-3">
                <h6>Inspection Description{{i+1}}</h6>
              </div>:
              <div class="col-6">{{item.description}}</div>
              <div class="col-1"></div>
            </div>
            <!--shweta commmited <div class="row mt-2">
            <div class="col-2"></div>
           <div class="col-3"><h6>Inspection Description notes</h6></div>:
           <div class="col-6">{{inspection_detail.insp_offi_note}}</div>
           <div class="col-1"></div>
           </div> shweta commmited -->
            <!-- <div class="row mt-2" *ngFor="let item of description ; let i = index">
            <div class="col-2"></div>
            <div class="col-3">
              <h6>Inspection Description{{i+1}}</h6>
            </div>:
            <div class="col-6">{{item.description}}</div>
            <div class="col-1"></div>
          </div> -->
            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-3">
                <h6>Inspection status</h6>
              </div>:
              <div class="col-6"><b>{{inspection_detail.life_cycle_status}}</b></div>
              <div class="col-1"></div>
            </div>
          </div>
          <!-- end -->


          <div class="modal-footer m-auto d-block text-center">
            <button type="button" class="btn btn-outline-info" (click)="download()">Download</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="inspection_photo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Inspection Photo</h6>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">

            <table class="table table-bordered text-center">
              <thead class="justify-content-center">
                <tr>
                  <th class="text-center">Image</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of inspection_image ;let i =index">
                  <td>Image{{i+1}}</td>
                  <td><button class="btn btn-outline-success" (click)="inspection_view(i)">View</button></td>

                </tr>

              </tbody>
            </table>


          </div>




          <div class="row justify-content-center" *ngIf="imageflag1">

            <img [src]="imgURL" height="500px" width="700px" />


          </div>





        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="memo_photo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Inspection Photo</h6>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">





          <div class="row justify-content-center" *ngIf="memoimageflag">

            <img [src]="imgURL3" height="500px" width="700px" />


          </div>





        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="staticBackdropsite" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Photo of site</h6>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">

            <table class="table table-bordered text-center">
              <thead class="justify-content-center">
                <tr>
                  <th class="text-center">Image</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of complaint_image;let i =index">
                  <td>Image-{{i+1}}</td>
                  <td><button class="btn btn-outline-success" (click)="view1(i)">View</button></td>

                </tr>

              </tbody>
            </table>


          </div>
          <div class="row justify-content-center" *ngIf="imageflag">

            <img [src]="imgURL1" height="500px" width="700px" />


          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

        </div>
      </div>
    </div>
  </div>
  <ngx-spinner>Loading....</ngx-spinner>
