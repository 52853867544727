<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"> {{mainService.allLableShowObj[mainService.language_cd+'Completion1']}}
                    </div>
                    <!-- Completion -->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->


                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="list_flag" class="nav-link active" href="#tab-7-1" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-line-chart"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Completion2']}}</a>
                            <!-- Completion List -->
                        </li>
                        <li class="nav-item">
                            <a *ngIf="create_flag" class="nav-link" href="#tab-7-2" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-plus-circle" aria-hidden="true"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Completion3']}}
                            </a>
                            <!-- Create Completion -->
                        </li>
                        <!--  <li class="nav-item">
                            <a class="nav-link" href="#tab-7-3" data-toggle="tab" ><i
                                    class="ti-settings"> </i> Update
                                    Completion
                            </a>
                        </li> -->


                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <br>
                            <div style="text-align: right;">
                                <button mat-button mat-raised-button class="btn btn-primary" (click)="createtab()">
                                    Create
                                </button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> {{mainService.allLableShowObj[mainService.language_cd+'Completion4']}}</h6>
                                    <!-- Select Work : -->
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allWork" bindLabel="desc" bindValue="id"
                                        placeholder="  {{mainService.allLableShowObj[mainService.language_cd+'Completion5']}}"
                                        (change)="getTenderId()" [(ngModel)]="selectedWork"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                        <!-- Select Work -->
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter2($any($event.target).value)"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Completion6']}}">
                                        <!-- Filter  -->
                                    </mat-form-field>
                                </div>

                            </div>
                            <br>
                            <div class="example-container">

                                <table mat-table [dataSource]="datasource2" matSort class="table table-bordered">

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion7']}}
                                            <!-- S No -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.sno}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion8']}}
                                            <!-- Item Description    -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ this.boqobj[element['sno']]['item_desc']}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tender_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion9']}}
                                            <!-- Tender Id -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.tender_id }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="dev_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion10']}}
                                            <!-- Completion Date -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- {{ element.dev_dt}} -->
                                            <span
                                                *ngIf="element['dev_dt']">{{mainService.dateformatchange(element['dev_dt'])}}</span>

                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dev_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion11']}}
                                            <!-- Completion type -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ compobj[element.dev_type]}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dev_quantity">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion12']}}
                                            <!-- Completion Quantity -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.dev_quantity}} </td>
                                    </ng-container>



                                    <!--   <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                           - <button class="btn btn-primary" 
                                                (click)="open_update(element)">Update</button> 
                                            <button class="btn btn-danger" 
                                                (click)="delete(element)">Delete</button>
                                                <button class="btn btn-success" style="width: 70%;" (click)="selected_lineID(element)">Print</button> 
                                        </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion13']}}
                                        </th>
                                        <!-- Action -->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button class="btn btn-info" (click)="viewStatus(element)"> {{mainService.allLableShowObj[mainService.language_cd+'Completion14']}}
                                              </button>&nbsp; -->
                                            <!-- View Status -->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        <div *ngIf="create_flag">
                            <br>
                            <div style="text-align: right;">
                                <button mat-button mat-raised-button class="btn btn-primary" (click)="listbtab()">
                                    Go-Back
                                </button>

                            </div>
                            <br>
                            <hr>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6> {{mainService.allLableShowObj[mainService.language_cd+'Completion4']}}</h6>
                                    <!-- Select Work :  -->
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allWork" bindLabel="desc" bindValue="id"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Completion5']}}"
                                        (change)="changeworkcomp()" [(ngModel)]="selectedWork"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                        <!-- Select Work  -->
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> {{mainService.allLableShowObj[mainService.language_cd+'Completion15']}}</h6>
                                    <!-- Completion Date :  -->
                                </div>
                                <div class="col-4">
                                    <input type="date" [(ngModel)]="Obj['dev_dt']" class="form-control">
                                </div>

                            </div>


                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'Completion6']}}">
                                    </mat-form-field>
                                    <!-- Filter  -->
                                </div>

                            </div>
                            <br>

                            <div class="" style="overflow-x: scroll;">

                                <table mat-table [dataSource]="datasource" matSort class="table table-bordered">

                                    <ng-container matColumnDef="sn">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion7']}}
                                            <!-- S No. -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            {{element['sno']}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="line_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion16']}}
                                            <!-- Line Description -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                [(ngModel)]="element['item_desc']" class="form-control"
                                                disabled></textarea>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="unit">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion17']}}
                                            <!-- Unit -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right"
                                            style="margin-left: 3%;">
                                            {{element['unit'] }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="work_order_q">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion18']}}
                                            <!-- Work Order Quantity -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            {{element['quantity'].toFixed(2)}} </td>
                                    </ng-container>







                                    <ng-container matColumnDef="rate">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion19']}}
                                            <!-- Rate -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            {{element['rate']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="excess_quantity">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion20']}}
                                            <!-- Already Measure/Billed Quantity -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            <input type="number" class="form-control" (change)="changeexcess(element)"
                                                [(ngModel)]="element['already_bill_quantity']" disabled>
                                    </ng-container>

                                    <ng-container matColumnDef="dev_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion11']}}
                                            <!-- Completion Type -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            {{element['dev_type']}}
                                    </ng-container>
                                    <ng-container matColumnDef="saving_quantity">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Completion12']}}
                                            <!-- Completion Quantity -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            <input type="text" class="form-control" (change)="changesaving(element)"
                                                [(ngModel)]="element['remaining_quantity']" disabled>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                            </div>

                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>

                            <br>


                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="submit()">{{mainService.allLableShowObj[mainService.language_cd+'Completion21']}}</button>
                                    <!-- Submit  -->
                                </div>
                            </div>

                        </div>
                        <!--  <div class="tab-pane fade" id="tab-7-3">
                            <mat-horizontal-stepper [linear]="isLinear" #stepper [selectedIndex]="stepperIndex">
                                <mat-step [stepControl]="first" label="Select Work Line">
                                    <br>
                                    <hr>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6> Select Project :</h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="filterService.filterObj['project']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                placeholder="Select Project" [(ngModel)]="Obj['project_cd']"
                                                (change)="getAllWorkInfo()" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="project_cd">
                                            </ng-select>
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6> Select Work :</h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="allWorkList" bindLabel="work_order_name"
                                                bindValue="id" placeholder="Select Work"
                                                (change)="changework()" [(ngModel)]="Obj['work_order_id']"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="work_order_id">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6> Completion Description :</h6>
                                        </div>
                                        <div class="col-3">
                                            <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                [(ngModel)]="Obj['dev_desc']"
                                                class="form-control"></textarea>
                                        </div>

                                        <div class="col-3 text-right">
                                            <h6>Completion Date :</h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="date" [(ngModel)]="Obj['dev_date']" class="form-control">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Zone :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{Obj['zone']}}
                                           
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>Tender Amount :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{Obj['amount']}}
                                            
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Last Deviation Amount :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{Obj['last_dev_amount']}}
                                            
                                        </div>
                                       
                                        
                                    </div>

                                    <div class="row">
                                        <div class="col-4">
                                            <mat-form-field style="margin-left: 2%">
                                                <input matInput (keyup)="applyFilter( $any($event.target).value)"
                                                    placeholder="Filter">
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <br>

                                    <div class="" style="overflow-x: scroll;">

                                        <table mat-table [dataSource]="datasource" matSort class="table table-bordered">

                                            <ng-container matColumnDef="sn">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>S
                                                    No.


                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{element['s_no']}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="line_desc">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Line Description
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                    [(ngModel)]="element['line_desc']"
                                                    class="form-control" disabled></textarea>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="unit">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Unit


                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right" style="margin-left: 3%;">
                                                    {{element['unit'] }} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="work_order_q">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Work Order Quantity
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{element['quantity'].toFixed(2)}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="ebill_amt">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Amount
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{element['lime_amt_without_tax']}}
                                            </ng-container>

                                           

                                           


                                            <ng-container matColumnDef="rate">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Rate
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{element['rate']}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="excess_quantity">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Already Measure/Billed Quantity
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    <input type="number" class="form-control"
                                                        (change)="changeexcess(element)"
                                                        [(ngModel)]="element['already_m_q']" disabled>
                                            </ng-container>

                                           
                                            <ng-container matColumnDef="saving_quantity">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Remaining Quantity
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    <input type="text" class="form-control"
                                                    (change)="changesaving(element)"
                                                        [(ngModel)]="element['remaining_q']" disabled>
                                                </td>
                                            </ng-container>
                                           
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                            </tr>
                                        </table>
                                    </div>

                                    <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>

                                    <br>
                                   
                                   
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button matStepperNext color="primary" (click)="nextClick()"
                                                style="background-color: blue;color: white;">Next</button>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="second" label="Done">
                                    <br>
                                    <hr>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6> Select Project :</h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="filterService.filterObj['project']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                placeholder="Select Project" [(ngModel)]="Obj['project_cd']"
                                                (change)="getAllWorkInfo()" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="project_cd">
                                            </ng-select>
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6> Select Work :</h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="allWorkList" bindLabel="work_order_name"
                                                bindValue="id" placeholder="Select Work"
                                                (change)="changework()" [(ngModel)]="Obj['work_order_id']"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="work_order_id">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6> Deviation Description :</h6>
                                        </div>
                                        <div class="col-3">
                                            <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                [(ngModel)]="Obj['dev_desc']"
                                                class="form-control"></textarea>
                                        </div>

                                        <div class="col-3 text-right">
                                            <h6>Deviation Date :</h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="date" [(ngModel)]="Obj['dev_date']" class="form-control">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Zone :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{Obj['zone']}}
                                            
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>Tender Amount :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{Obj['amount']}}
                                            
                                        </div>
                                        
                                    </div>


                                    
                                    <br>
                                    <div class="row">
                                      
                                        <div class="col-3 text-right">
                                            <h6>Deviation Amount :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{Obj['dev_amount']}}
                                            
                                        </div>
                                        
                                    </div>


                                    
                                    <br>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>S no.</th>
                                                <th style="width: 40%;">Line Description</th>
                                                <th>Quantity</th>
                                                <th>Rate</th>
                                                <th style="width: 10%;">Amount</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let i of viewdata;let j = index">
                                                <td>{{i['s_no']}}</td>
                                                <td style="width: 20%;">
                                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                    [(ngModel)]="i['line_desc']"
                                                    class="form-control" disabled></textarea>
                                                </td>
                                                <td>
                                                    {{i['quantity'] + i['excess_quantity']-i['saving_quantity']}}
                                                </td>
                                                <td>
                                                    {{i['rate']}}
                                                </td>
                                                
                                                <td>

                                                    {{i['dev_amount']}}

                                                </td>
                                               
                                                
                                            </tr>

                                        </tbody>

                                    </table>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button matStepperPrevious color="primary" 
                                               >Back</button>
                                                <button mat-button mat-raised-button color="primary" (click)="update()" matStepperNext>Update</button>
                                        </div>
                                      
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div> -->

                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner type="ball-atom"> {{mainService.allLableShowObj[mainService.language_cd+'Completion22']}}</ngx-spinner>
<!-- Loading.... -->
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> {{mainService.allLableShowObj[mainService.language_cd+'Completion23']}}</h4>
                <!-- Status -->
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="container">
                    <a *ngFor="let ob of statusArr;let i=index">
                        <div class="step completed" *ngIf="ob.status=='APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;"
                                    *ngIf="i <statusArr.length-1"></div>
                            </div>

                            <div class="content">
                                {{ob.level_of_approval}} - {{ob.designation_cd}} - {{ob.status}}

                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='REJECTED'">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{ob.level_of_approval}} - {{ob.designation_cd}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{ob.level_of_approval}} - {{ob.designation_cd}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{ob.level_of_approval}} - {{ob.designation_cd}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"
                       data-bs-dismiss="modal">{{mainService.allLableShowObj[mainService.language_cd+'Completion24']}}</button>
                    <!-- Close  -->
                </div>

            </div>
        </div>
    </div>
</div>