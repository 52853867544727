import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IcmsLayoutComponent } from './icms-layout.component';
import { RoleGuardService } from '../../role-guard.service';
import { IcmsDashboradComponent } from '../icms-dashborad/icms-dashborad.component';
import { IcmsAdministrationComponent } from '../icms-administration/icms-administration.component';
import { IcmsRolesComponent } from '../icms-administration/icms-roles/icms-roles.component';
import { IcmsUserComponent } from '../icms-administration/icms-user/icms-user.component';
import { IcmsDataAssigmentComponent } from '../icms-administration/icms-data-assigment/icms-data-assigment.component';
import { IcmsWorkflowComponent } from '../icms-administration/icms-workflow/icms-workflow.component';
import { IcmsCodeValueComponent } from '../icms-setting/icms-code-value/icms-code-value.component';
import { IcmsFieldComponent } from '../icms-setting/icms-field/icms-field.component';
import { IcmsSettingComponent } from '../icms-setting/icms-setting.component';
import { ComplaintComponent } from '../complaint/complaint.component';
import { PublicComplaintComponent } from '../complaint/public-complaint/public-complaint.component';
import { IcmsZoneComponent } from '../icms-setting/icms-zone/icms-zone.component';
import { IcmsHierachyComponent } from '../icms-setting/icms-hierachy/icms-hierachy.component';
import { IcmsHelpComponent } from '../icms-help/icms-help.component';
import { IcmsFaqComponent } from '../icms-help/icms-faq/icms-faq.component';
import { IcmsUserManualComponent } from '../icms-help/icms-user-manual/icms-user-manual.component';
import { IcmsTutorialComponent } from '../icms-help/icms-tutorial/icms-tutorial.component';
import { IcmsOnlineSupportComponent } from '../icms-help/icms-online-support/icms-online-support.component';
import { LitigationComponent } from '../litigation/litigation.component';
import { UserComplaintComponent } from '../complaint/user-complaint/user-complaint.component';
import { IcmsSectionComponent } from '../icms-setting/icms-section/icms-section.component';
import { AssignComplaintComponent } from '../complaint/assign-complaint/assign-complaint.component';
import { ReportsComponent } from '../reports/reports.component';
import { InspectionReportComponent } from '../reports/inspection-report/inspection-report.component';
import { CourtOrderComponent } from '../court/court-order/court-order.component';
import { SupportDepartmentComponent } from '../icms-setting/support-department/support-department.component';
import { PaymentComponent } from '../payment/payment.component';
import { PenalityComponent } from '../payment/penality/penality.component';
import { DemolitionChargesComponent } from '../payment/demolition-charges/demolition-charges.component';

import { NotificationComponent } from './notification/notification.component';
import { CauseListComponent } from '../cause-list/cause-list.component';
import { SupportLettersComponent } from '../court/support-letters/support-letters.component';
import { LetterEditorComponent } from '../letter-editor/letter-editor.component';
import { PaymentGatewayComponent } from '../icms-administration/payment-gateway/payment-gateway.component';
import { AllreportsComponent } from '../reports/allreports/allreports.component';
import { CompundingComponent } from '../payment/compunding/compunding.component';
import { RequestComponent } from '../request/request.component';
import { CompoundingProcessComponent } from '../court/compounding-process/compounding-process.component';

import { RecoveryCalculationComponent } from '../court/recovery-calculation/recovery-calculation.component';
import { CourtComponent } from '../court/court.component';
import { AssignedOrderComponent } from '../court/assigned-order/assigned-order.component';
import { CampusBoundaryComponent } from '../icms-setting/campus-boundary/campus-boundary.component';


const routes: Routes = [
  {
    path: "icms-index",
    component: IcmsDashboradComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R1' }
  },

  
  {
    path: "letter-editor",
    component: LetterEditorComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R12' }
  },

  {
    path: "notification",
    component: NotificationComponent
  },
  {
    path: "request",
    component: RequestComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R26'}
  },
  // Litigation

  {
    path: "litigation",
    component: LitigationComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R11' }
  },
  {
    path: "cause-list",
    component: CauseListComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R17' }
  },
 
  {
    "path": "administration",
    "component": IcmsAdministrationComponent,
    "children": [

      {
        path: "roles",
        component: IcmsRolesComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R4' }
      },
      {
        path: "users",
        component: IcmsUserComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R3' }
      },

      {
        path: "data-assignment",
        component: IcmsDataAssigmentComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R22' }
      },

      {
        path: "workflow",
        component: IcmsWorkflowComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R5' }
      },

      {
        path: "payment-gateway",
        component: PaymentGatewayComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R16' }
      },
    ]
  },

  {

    "path": "icms-setting",
    "component": IcmsSettingComponent,
    "children": [
      {
        path: "icms-code-value",
        component: IcmsCodeValueComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R6' }
      },
      {
        path: "icms-field",
        component: IcmsFieldComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R18' }
      },
      {
        path: "section-map",
        component: IcmsSectionComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R19' }
      },
      {
        path: "icms-zone",
        component: IcmsZoneComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R9' }
      },
      {
        path: "icms-campus-boundray",
        component: CampusBoundaryComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R9' }
      },
      {
        path: "hierachy",
        component: IcmsHierachyComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R20' }

      },
      {
        path: "support-department",
        component: SupportDepartmentComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R21' }


      }
    ]

  },

  {
    "path": "complaint",
    "component": ComplaintComponent,
    "children": [
      {
        path: "icma-public-complaint",
        component: PublicComplaintComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R13' }
      },
      {
        path: "icma-user-complaint",
        component: UserComplaintComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R10' }
      },
      {
        path: "assign-complaint",
        component: AssignComplaintComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R14' }
      },

    ]
  },


  {
    "path": "payment",
    "component": PaymentComponent,
    "children": [
      {
        path: "penality",
        component: PenalityComponent , canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R33' }
      },
      {
        path: "demolition-charges",
        component: DemolitionChargesComponent , canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R33' }
      },
      {
        path: "compounding",
        component: CompundingComponent , canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R33' }
      },
    ]
  },

  {
    "path": "reports",
    "component": ReportsComponent,
    "children": [
      {
        path: "icms-inspection-report",
        component: InspectionReportComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R15' }
      },
      {
        path: "icms-all-report",
        component: AllreportsComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R15' }
      },
    ]
  },


  {
    "path": "help",
    "component": IcmsHelpComponent,
    "children": [
      {
        path: "faq",
        component: IcmsFaqComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R8' }
      },
      {
        path: "user-manual",
        component: IcmsUserManualComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R8' }
      },
      {
        path: "tutorial",
        component: IcmsTutorialComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R8' }
      },
      {
        path: "online-support",
        component: IcmsOnlineSupportComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R8' }
      },
    ]
  },
  
  {
    "path": "court",
    "component": CourtComponent,
    "children": [
      {
        path: "court-order",
        component: CourtOrderComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R28' }
      },
      {
        path: "assigned-order",
        component: AssignedOrderComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R29' }
      },
      {
        path: "support-letters",
        component: SupportLettersComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R30' }
      },
      {
        path: "compounding-process",
        component: CompoundingProcessComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R31' }
      },
      {
        path: "recovery-calculation",
        component: RecoveryCalculationComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'ICMS-R32' }
      },

    ]
  },

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IcmsLayoutRoutingModule { }
