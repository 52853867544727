<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Manage Employee Resignation -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign1']}} </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                <!-- All Employee Resignation List -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i
                                    class="fa fa-plus-circle"></i><!--  Apply For Employee Resignation -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign3']}}

                               </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i -->
                                   <!-- class="ti-settings"></i>--> <!-- Update Employee Resignation --><!--{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign4']}}-->
                   <!-- </a>
                        </li> -->

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign5']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{element['tempid']}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{employeeObj[element.emp_id]}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="resignation_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Resignation Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <p *ngIf="element['resignation_date']">{{mainService.dateformatchange(element.resignation_date)}} </p>

                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="notice_period">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Notice Period -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.notice_period}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="resignation_status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Resignation Status -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.resignation_status}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign10']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <!--<button class="btn btn-outline-primary" (click)="openUpdate(element)">--><!-- UPDATE --><!--{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign11']}}</button>-->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">

                                    <!-- Select Employee -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign12']}} :<span style="color: red;">*</span>


                                </div>
                                <div class="col-4">
                                    <!-- <select class="form-control" [(ngModel)]="selectEmpObj['emp_id']">
                                        <option *ngFor="let obj of allEmployees" [(ngValue)]="obj.emp_id">
                                            {{obj.emp_name}}</option>
                                    </select> -->


                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name"
                                    bindValue="emp_id" [multiple]="false" placeholder=""
                                    [(ngModel)]="selectEmpObj" [selectableGroup]="true"
                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="emp_id">
                                </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">


                                    <!-- Resignation Type --> {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign13']}} :<span style="color: red;">*</span>


                                    <!-- Resignation Type -->  <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign13']}}:<span style="color: red;">*</span> -->



                                </div>
                                <div class="col-4">
                                    <input *ngIf="mainService.codeValueTechObj['resignation_type_code']==undefined" class="form-control" [(ngModel)]="ERObj['resignation_type_code']">
                                    <select *ngIf="mainService.codeValueTechObj['resignation_type_code']!=undefined" class="form-control" [(ngModel)]="ERObj['resignation_type_code']">
                                    <option *ngFor="let ob of mainService.codeValueTechObj['resignation_type_code']" (ngValue)="ob['code']">{{ob['value']}}
                                    </option>
                                </select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">

                                    <!-- Resignation Reason -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign14']}}  :<span style="color: red;">*</span>

                                </div>
                                <div class="col-4">
                                    <textarea name="res" id="res" class="form-control" type="text" [(ngModel)]="ERObj['resignation_reason']"></textarea>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Order ID  :
                                     <!-- Order ID  :<span style="color: red;">*</span>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign14']}} : -->
                                </div>
                                <div class="col-4">
                                    <input type="number" [(ngModel)]="ERObj['order_id']" class="form-control">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">

                                     Resignation Date :<span style="color: red;">*</span><!-- {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign14']}} :-->


                                </div>
                                <div class="col-4">
                                    <input type="date" [(ngModel)]="ERObj['resignation_date']" class="form-control">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitMessage()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign15']}}</button>

                                </div>
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="tab-3">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Employee Name {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign6']}} : -->
                                <!-- </div>
                                <div class="col-4">

                                    {{employeeObj[ERObj['emp_id']]}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Resignation ID {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign16']}} :-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="ERObj['id']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Effective Date {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign17']}} :-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="ERObj['effective_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Notice Period {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign8']}} :-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="ERObj['notice_period']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Resignation Action  {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign18']}}:-->
                                <!-- </div>
                                <div class="col-4">


                                    <input *ngIf="mainService.codeValueTechObj['resignation_status']==undefined" class="form-control" [(ngModel)]="ERObj['resignation_status']">
                                    <select *ngIf="mainService.codeValueTechObj['resignation_status']!=undefined" class="form-control" [(ngModel)]="ERObj['resignation_status']">
                                    <option *ngFor="let ob of mainService.codeValueTechObj['resignation_status']" [(ngValue)]="ob['code']">{{ob['value']}}
                                    </option>
                                </select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="approveResignation()"> Submit {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Resign15']}}</button>-->

                                <!-- </div>
                            </div>
                        </div>  -->

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
