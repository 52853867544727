import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { RoleGuardService } from "../../role-guard.service";
import { AttendanceComponent } from "../arr/attendance/attendance.component";
import { ComplaintComponent } from "../arr/complaint/complaint.component";
import { DeathComponent } from "../arr/death/death.component";
import { EmpPromotionComponent } from "../arr/emp-promotion/emp-promotion.component";
import { EmpResignComponent } from "../arr/emp-resign/emp-resign.component";
import { EmpTransferComponent } from "../arr/emp-transfer/emp-transfer.component";
import { EnquiryComponent } from "../arr/enquiry/enquiry.component";
import { EstablishmentComponent } from "../arr/establishment/establishment.component";
import { LeavesApplyComponent } from "../arr/leaves-apply/leaves-apply.component";
import { LeavesComponent } from "../arr/leaves/leaves.component";
import { PostingComponent } from "../arr/posting/posting.component";
import { ProbationComponent } from "../arr/probation/probation.component";
import { ReappointmentComponent } from "../arr/reappointment/reappointment.component";
import { RetirementComponent } from "../arr/retirement/retirement.component";
import { SuspensionComponent } from "../arr/suspension/suspension.component";
import { TerminationComponent } from "../arr/termination/termination.component";
import { HrAdministrationComponent } from "../hr-administration/hr-administration.component";
import { HrDataAssignmentComponent } from "../hr-administration/hr-data-assignment/hr-data-assignment.component";
import { HrHierarchyComponent } from "../hr-administration/hr-hierarchy/hr-hierarchy.component";
import { HrRolesComponent } from "../hr-administration/hr-roles/hr-roles.component";
import { HrUsersComponent } from "../hr-administration/hr-users/hr-users.component";
import { HrWorkflowComponent } from "../hr-administration/hr-workflow/hr-workflow.component";
import { HrmsDashComponent } from "../hrms-dash/hrms-dash.component";
import { HrmsFaqComponent } from "../hrms-help/hrms-faq/hrms-faq.component";
import { HrmsHelpComponent } from "../hrms-help/hrms-help.component";
import { HrmsOnlineSupportComponent } from "../hrms-help/hrms-online-support/hrms-online-support.component";
import { HrmsTutorialComponent } from "../hrms-help/hrms-tutorial/hrms-tutorial.component";
import { HrmsUserManualComponent } from "../hrms-help/hrms-user-manual/hrms-user-manual.component";
import { HrmsNotificationComponent } from "../hrms-notification/hrms-notification.component";
import { AnnualStatementComponent } from "../hrms-report/annual-statement/annual-statement.component";
import { BillReportComponent } from "../hrms-report/bill-report/bill-report.component";
import { DeductionReportComponent } from "../hrms-report/deduction-report/deduction-report.component";
import { EmpReportComponent } from "../hrms-report/emp-report/emp-report.component";
import { HrmsReportComponent } from "../hrms-report/hrms-report.component";
import { LicReportComponent } from "../hrms-report/lic-report/lic-report.component";
import { LpcComponent } from "../hrms-report/lpc/lpc.component";
import { LpctransferComponent } from "../hrms-report/lpctransfer/lpctransfer.component";
import { PayrollReportComponent } from "../hrms-report/payroll-report/payroll-report.component";
import { PensionContributionComponent } from "../hrms-report/pension-contribution/pension-contribution.component";
import { SalarySlipComponent } from "../hrms-report/salary-slip/salary-slip.component";
import { StopReportComponent } from "../hrms-report/stop-report/stop-report.component";
import { VarAnnualStatementComponent } from "../hrms-report/var-annual-statement/var-annual-statement.component";
import { CcaMatrixComponent } from "../hrms-setting/cca-matrix/cca-matrix.component";
import { CityMatrixComponent } from "../hrms-setting/city-matrix/city-matrix.component";
import { HrBudgetInfoComponent } from "../hrms-setting/hr-budget-info/hr-budget-info.component";
import { HrProductInfoComponent } from "../hrms-setting/hr-product-info/hr-product-info.component";
import { HraMatrixComponent } from "../hrms-setting/hra-matrix/hra-matrix.component";
import { HrmsCodeValueComponent } from "../hrms-setting/hrms-code-value/hrms-code-value.component";
import { HrmsFieldComponent } from "../hrms-setting/hrms-field/hrms-field.component";
import { HrmsSettingComponent } from "../hrms-setting/hrms-setting.component";
import { LeaveInfoComponent } from "../hrms-setting/leave-info/leave-info.component";
import { PayComponentComponent } from "../hrms-setting/pay-component/pay-component.component";
import { PayMatrixComponent } from "../hrms-setting/pay-matrix/pay-matrix.component";
import { SalaryComponentDefinitionComponent } from "../hrms-setting/salary-component-definition/salary-component-definition.component";
import { SectionComponent } from "../hrms-setting/section/section.component";
import { SettingPensionContributionComponent } from "../hrms-setting/setting-pension-contribution/setting-pension-contribution.component";
import { MyinfoComponent } from "../myinfo/myinfo.component";
import { AllEmpComponent } from "../party/all-emp/all-emp.component";
import { BankAccountInfoComponent } from "../party/bank-account-info/bank-account-info.component";
import { DependentInfoComponent } from "../party/dependent-info/dependent-info.component";
import { EducationInfoComponent } from "../party/education-info/education-info.component";
import { EmplistComponent } from "../party/emplist/emplist.component";
import { JoiningComponent } from "../party/joining/joining.component";
import { LicComponent } from "../party/lic/lic.component";
import { NomineeInfoComponent } from "../party/nominee-info/nominee-info.component";
import { PartyComponent } from "../party/party.component";
import { PersonalInfoComponent } from "../party/personal-info/personal-info.component";
import { AnnualIncrementComponent } from "../payroll/annual-increment/annual-increment.component";
import { FixedPayComponent } from "../payroll/fixed-pay/fixed-pay.component";
import { LeaveEncashmentComponent } from "../payroll/leave-encashment/leave-encashment.component";
import { LoanComponent } from "../payroll/loan/loan.component";
import { OtherPaymentComponent } from "../payroll/other-payment/other-payment.component";
import { PaidSalaryComponent } from "../payroll/paid-salary/paid-salary.component";
import { PayrollComponent } from "../payroll/payroll.component";
import { SalaryBillComponent } from "../payroll/salary-bill/salary-bill.component";
import { SalaryHoldAndStartComponent } from "../payroll/salary-hold-and-start/salary-hold-and-start.component";
import { AllArrearComponent } from "../payroll/variable-pay/all-arrear/all-arrear.component";
import { DaArrearComponent } from "../payroll/variable-pay/da-arrear/da-arrear.component";
import { LeaveencashArrearComponent } from "../payroll/variable-pay/leaveencash-arrear/leaveencash-arrear.component";
import { VariablePayComponent } from "../payroll/variable-pay/variable-pay.component";
import { SalaryRuleComponent } from '../hrms-setting/salary-rule/salary-rule.component';
import { ShowDataComponent } from '../arr/show-data/show-data.component';
// Avinash Mishra
import { CreateRuleComponent } from "../attendance_manag/create-rule/create-rule.component";
import { ExtraWorkComponent } from "../attendance_manag/extra_work_management/extra-work/extra-work.component";
import { LateSittingComponent } from "../attendance_manag/late-sitting/late-sitting.component";
import { LeaveListComponent } from '../attendance_manag/leave-list/leave-list.component';

import { CalendarRuleComponent } from "../attendance_manag/calendar-rule/calendar-rule.component";

import { AttenReportComponent } from '../hrms-report/atten-report/atten-report.component'
import { AcrComponent } from '../annual_conf_report/acr/acr.component';
import { BoundryMapComponent } from '../attendance_manag/boundry-map/boundry-map.component';
// import { MarkAttendnaceComponent } from '../mark-attendnace/mark-attendnace.component';
import { MarkAttendanceComponent } from '../mark-attendance/mark-attendance.component'
import { PbrMatrixComponent } from "../hrms-setting/pbr-matrix/pbr-matrix.component";
import { WelfareFundComponent } from "../hrms-setting/welfare-fund/welfare-fund.component";
import { TaBillComponent } from "../travelling-reimburement/ta-bill/ta-bill.component";
import { TourDetailComponent } from "../travelling-reimburement/tour-detail/tour-detail.component";
import { TravellingReimburementComponent } from "../travelling-reimburement/travelling-reimburement.component";
import { MkattendComponent } from "../mkattend/mkattend.component";
const routes: Routes = [
  { path: "myinfo", component: MyinfoComponent },
  { path: "mark-attendance", component: MarkAttendanceComponent },
  { path: "mkattend", component: MkattendComponent },
  {
    path: "hrms-index",
    component: HrmsDashComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "HR-R1" },
  },
  {
    path: "party/list",
    component: EmplistComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "HR-R8" },
  },
  {
    path: "notice",
    component: HrmsNotificationComponent,
  },
  {
    path: "hrms-report",
    component: HrmsReportComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "HR-R5" },
    children: [
      {
        path: "emp-report",
        component: EmpReportComponent,
      },
      {
        path: "pention-contribution",
        component: PensionContributionComponent,
      },

      {
        path: "bill-report",
        component: BillReportComponent,
      },
      {
        path: "payroll-report",
        component: PayrollReportComponent,
      },
      {
        path: "lic-report",
        component: LicReportComponent,
      },
      {
        path: "deduction-report",
        component: DeductionReportComponent,
      },
      {
        path: "pay-slip",
        component: SalarySlipComponent,
      },
      {
        path: "lpc",
        component: LpcComponent,
      },
      {
        path: "lpctransfer",
        component: LpctransferComponent,
      },
      {
        path: "annl",
        component: AnnualStatementComponent,
      },
      {
        path: "varannl",
        component: VarAnnualStatementComponent,
      },
      {
        path: "hold",
        component: StopReportComponent,
      },
      {
        path: "atten-report",
        component: AttenReportComponent,
      },
    ],
  },

  {
    path: "party",
    component: PartyComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "HR-R1" },
    children: [
      {
        path: "allemp",
        component: AllEmpComponent,
      },
      {
        path: "personal-info",
        component: PersonalInfoComponent,
      },
      {
        path: "dependent-info",
        component: DependentInfoComponent,
      },
      {
        path: "joining",
        component: JoiningComponent,
      },
      {
        path: "edu-info",
        component: EducationInfoComponent,
      },
      {
        path: "nom-info",
        component: NomineeInfoComponent,
      },
      {
        path: "bank-dtl-info",
        component: BankAccountInfoComponent,
      },
      {
        path: "lic",
        component: LicComponent,
      },
    ],
  },
  {
    path: "arr",
    component: PartyComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "HR-R7" },

    children: [
      {
        path: "establish",
        component: EstablishmentComponent,
      },
      {
        path: "posting",
        component: PostingComponent,
      },
      {
        path: "enquiry",
        component: EnquiryComponent,
      },
      {
        path: "complaint",
        component: ComplaintComponent,
      },
      {
        path: "promotion",
        component: EmpPromotionComponent,
      },
      {
        path: "probation",
        component: ProbationComponent,
      },
      {
        path: "suspension",
        component: SuspensionComponent,
      },
      {
        path: "termination",
        component: TerminationComponent,
      },
      {
        path: "retirement",
        component: RetirementComponent,
      },
      {
        path: "death",
        component: DeathComponent,
      },
      {
        path: "reappointment",
        component: ReappointmentComponent,
      },
      {
        path: "resign",
        component: EmpResignComponent,
      },
      {
        path: "transfer",
        component: EmpTransferComponent,
      },
      {
        path: "show-data",
        component: ShowDataComponent,
      },
    ],
  },
  {
    path: "arr",
    component: PartyComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "HR-R4" },

    children: [
      {
        path: "leaves",
        component: LeavesComponent,
      },
      {
        path: "leaves-apply",
        component: LeavesApplyComponent,
      },
      {
        path: "attendance",
        component: AttendanceComponent,
      },
      {
        path: "leave-list",
        component: LeaveListComponent
      },

    ],
  },
  {path: "travelling-reimburement",
  component: TravellingReimburementComponent,
  
  children: [
    {
      path: "ta-bill",
      component: TaBillComponent,
    },
    {
      path: "tour-detail",
      component: TourDetailComponent,
    },

  ],
},
  // payroal path 
  // {
  //   path: "fixed-pay",
  //   component: FixedPayComponent,
  //   canActivate: [RoleGuardService],
  //   data: { ComponentCode: "HR-R10" },
  // },
  {
    path: "payroll",
    component: PayrollComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "HR-R3" },

    children: [
      {
        path: "fixed-pay",
        component: FixedPayComponent,
      },
      {
        path: "variable-pay",
        component: VariablePayComponent,
        children: [
          {
            path: "da-arrear",
            component: DaArrearComponent,
          },
          {
            path: "leaveel-arrear",
            component: LeaveencashArrearComponent,
          },
          {
            path: "all-arrear",
            component: AllArrearComponent,
          },
        ],
      },
      {
        path: "sal-bill",
        component: SalaryBillComponent,
      },
      {
        path: "sal-start-stop",
        component: SalaryHoldAndStartComponent,
      },
      {
        path: "other-bill",
        component: OtherPaymentComponent,
      },
      {
        path: "ann_inc",
        component: AnnualIncrementComponent,
      },
      {
        path: "paid-salary",
        component: PaidSalaryComponent,
      },

      {
        path: "loan",
        component: LoanComponent,
      },
      {
        path: "el-encash",
        component: LeaveEncashmentComponent,
      },
    ],
  },
  {
    path: "setting",
    component: HrmsSettingComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "HR-R6" },

    children: [
      {
        path: "fields",
        component: HrmsFieldComponent,
      },
      {
        path: "pension-contribution",
        component: SettingPensionContributionComponent,
      },
      {
        path: "code-value",
        component: HrmsCodeValueComponent,
      },
      {
        path: "leave-def",
        component: LeaveInfoComponent,
      },
      {
        path: "salary-rule",
        component: SalaryRuleComponent
      },
      {
        path: "section-def",
        component: SectionComponent,
      },

      {
        path: "matrix",
        component: PayMatrixComponent,
      },
      {
        path: "city-matrix",
        component: CityMatrixComponent,
      },
      {
        path: "hra-matrix",
        component: HraMatrixComponent,
      },
      {
        path: "cca-matrix",
        component: CcaMatrixComponent,
      },
      {
        path: "pbr-matrix",
        component: PbrMatrixComponent,
      },
      {
        path: "welfare-fund",
        component: WelfareFundComponent,
      },
      {
        path: "salary-rules",
        component: SalaryComponentDefinitionComponent,
      },
      {
        path: "budget-info",
        component: HrBudgetInfoComponent,
      },
      {
        path: "product-info",
        component: HrProductInfoComponent,
      },
      {
        path: "pay-component",
        component: PayComponentComponent,
      },
    ],
  },
  {
    path: "help",
    component: HrmsHelpComponent,

    children: [
      { path: "doc", component: HrmsUserManualComponent },
      { path: "faq", component: HrmsFaqComponent },
      { path: "videos", component: HrmsTutorialComponent },
      { path: "online-support", component: HrmsOnlineSupportComponent },
    ],
  },
  {
    path: "administration",
    component: HrAdministrationComponent,
    children: [
      {
        path: "roles",
        component: HrRolesComponent,
      },
      {
        path: "users",
        component: HrUsersComponent,
      },
      {
        path: "hierarchy",
        component: HrHierarchyComponent,
      },
      {
        path: "data-assignment",
        component: HrDataAssignmentComponent,
      },
      {
        path: "hr-workflow",
        component: HrWorkflowComponent,
      },
    ],
  },
  {
    path: 'attendanceManagement',
    component: CreateRuleComponent
  },

  // Avinash Mishra

  {
    path: 'extrawork',
    component: ExtraWorkComponent,
  },
  {
    path: 'late_sitting',
    component: LateSittingComponent
  },
  //  {
  //   path: "leave-list",
  //   component: LeaveListComponent
  // },

  {
    path: 'attendance_manag/calendar-rule',
    component: CalendarRuleComponent
  },
  {
    path: 'attendance_manag/boundry-map',
    component: BoundryMapComponent
  },
  {
    path: 'annual_conf_report/acr',
    component: AcrComponent
  },
  

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HrmsLayoutsRoutingModule { }
