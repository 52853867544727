// import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { NgxSpinnerService } from "ngx-spinner";
// import { Router } from '@angular/router';
// import { EstablishmentService } from '../../service/establishment.service';
// import { MainService } from '../../service/main.service';
// import swal from 'sweetalert2';


import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { JoiningService } from '../../service/joining.service';
import { HrHierService } from '../../service/hr-hier.service';
import swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { EstablishmentService } from '../../service/establishment.service';
import { MainService as MD_mainService } from '../../../md/service/main.service';
import { RoleService as MD_RoleService } from '../../../md/service/role.service';
import { UserService as MD_UserService } from '../../../md/service/user.service';
import { MainService as portal_mainService } from '../../../portal/service/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import Swal from 'sweetalert2';
import { AllEmpService } from '../../service/all-emp.service';
import { DataAccessService } from '../../service/data-access.service';
declare var $: any;
declare var $: any;


@Component({
  selector: 'app-posting',
  templateUrl: './posting.component.html',
  styleUrls: ['./posting.component.css']
})
export class PostingComponent implements OnInit {




  Obj1:any ;
  displayedColumns :any = ['emp_id', 'emp_name', 'emp_fun', 'cost_center', 'action'];
  // displayedColumns = ['emp_id', 'emp_name', 'emp_fun', 'cost_center', 'effective_timestamp', 'action'];
  datasource:any ;
  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.datasource){
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.datasource){
      this.datasource.sort = value;
    }
  }

  constructor(private dataAccessService: DataAccessService, private hrHierService: HrHierService, private establishmentService: EstablishmentService, private MD_UserService: MD_UserService, private MD_RoleService: MD_RoleService, public mainService: MainService, private portal_mainS: portal_mainService, private MD_mainService: MD_mainService, private joiningservice: JoiningService, private snackBar: MatSnackBar, private router: Router, private spinner: NgxSpinnerService, private allEmpService: AllEmpService) { }



  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;


  status :any = [{ code: "ACTIVE", value: "ACTIVE" }, { code: "INACTIVE", value: "INACTIVE" }];
  obj :any = {}
  isLinear:any
  first:any
  second:any;
  third:any;
  fourth:any;
  joiningObj :any = {};
  empNameObj :any = {}
  addEstObj :any = {}
  probation :any = false;
  b_acct_id:any ;
  erpUser:any ;
  show_org_msg:any ;
  user_id:any
  emp_id :any = ''
  allEmplyees:any  = [];
  allEmplyees_new:any  = []
  modobj :any = {}
  id:any ;



  emp_status = 'ACTIVE';

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;

    await this.getAccountProjectHier();
    await this.getAllProduct();
    await this.getAllBudget();
    await this.getAllSystemRole();
    await this.getAllAccountModule();


    await this.getListOfAllEmployee();


    await this.AllEmployeeAssigedPost();
    await this.getEmployeeFunctionArea();

    this.emp_status = 'ACTIVE';

  }
search(){

}
addJoining(){

}

  ///****************************Select Node*********************************************** */

  allBudget = []
  async getAllBudget() {
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.allEmpService.getAllBugdet(obj);
    console.log(resp);
    if (resp['error'] == false) {
      this.allBudget = resp.data;
    }
  }

  allProduct = []
  async getAllProduct() {
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.allEmpService.getAllProduct(obj);
    console.log(resp);
    if (resp['error'] == false) {
      this.allProduct = resp.data;
    }
  }




  empIdPost:any  = {};
  allEmpPost:any  = []
  async AllEmployeeAssigedPost() {
    console.log("All Emp Posting")
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.establishmentService.getCurrentlyAssignedPost(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allEmpPost = resp.data;
      this.empIdPost = {};

      var data = resp.data;
      for (let i = 0; i < data.length; i++) {
        if ((data[i]['posting_type']) == 1) {
          this.empIdPost[data[i]['arr_id']] = data[i]['module_cd'] + "-" + data[i]['cost_center']
        }
      }
      for (let i = 0; i < data.length; i++) {
        if ((data[i]['posting_type']) != 1) {
          this.empIdPost[data[i]['arr_id']] = this.empIdPost[data[i]['arr_id']] + ",(" + data[i]['module_cd']/*  + "-" + data[i]['cost_center_desc'] */ + ")"
        }
      }
    } else {
      swal.fire('Error', 'Some Error Occured', 'error')
    }

    console.log(this.empIdPost)
  }

  empIdAssignedModule:any  = {};
  allEmpAssignedModule:any  = []
  async getEmployeeFunctionArea() {
    console.log("All Emp Function Area")
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.joiningservice.getEmployeeFunctionArea(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allEmpAssignedModule = resp.data;
      this.empIdAssignedModule = {};

      var data = resp.data;

      for (let i = 0; i < data.length; i++) {
        if (data[i]['role_cd'] == null || data[i]['role_cd'] == undefined) {

        } else {
          var dd = data[i]['role_cd'].split(",");
          for (let j = 0; j < dd.length; j++) {
            if (this.empIdAssignedModule[data[i]['arr_id']] == undefined) {
              this.empIdAssignedModule[data[i]['arr_id']] = this.moduleObj[this.roleToModule[dd[j]]]
            } else {
              this.empIdAssignedModule[data[i]['arr_id']] = this.empIdAssignedModule[data[i]['arr_id']] + "," + this.moduleObj[this.roleToModule[dd[j]]]
            }
          }
        }
      }

    } else {
      swal.fire('Error', 'Some Error Occured', 'error')
    }

    console.log(this.empIdAssignedModule)

  }




  allRole :any = []
  allModuleRole:any  = []
  moduleToRole :any = {};
  roleToModule :any = {};
  async getAllSystemRole() {
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_RoleService.getCurrentMdrole(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['is_system_role'] == 1) {
          this.allModuleRole.push(resp.data[i])
          this.moduleToRole[resp.data[i]['module_cd']] = resp.data[i]['role_cd']
          this.roleToModule[resp.data[i]['role_cd']] = resp.data[i]['module_cd']
        }
      }
    } else {
      swal.fire('Error', 'Some Error Occured', 'error')
    }

    console.log(this.allModuleRole)
    console.log(this.moduleToRole)
    console.log(this.roleToModule)
  }


  async getListOfAllEmployee() {
    console.log("All Emoplyee")
    this.spinner.show();
    this.allEmplyees = []
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAllPartyFields(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allEmplyees=await this.dataAccessService.filterEmployeeData(resp.data)
      console.log(this.allEmplyees)
      this.datasource = new MatTableDataSource(this.allEmplyees)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide()
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Some Error Occured', 'error')
    }
  }




  allModule :any = [];
  moduleObj :any = {}
  async getAllAccountModule() {
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_mainService.getAccountModules(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allModule = resp.data;

      for (let i = 0; i < this.allModule.length; i++) {
        this.moduleObj[this.allModule[i]['module_cd']] = this.allModule[i]['module_name']
      }

      for (let i = 0; i < this.allModule.length; i++) {
        if (this.allModule[i]['module_cd'] == 'ENG') {
          this.allModule.splice(i, 1)
        }
      }
    } else {
      swal.fire('Error', 'Some Error Occured', 'error')
    }
  }








  list_flag = true
  create_flag = false
  update_flag = false
  open_create() {
    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
  }

  async open_list() {
    this.filterValue="";
    this.applyFilter(this.filterValue)
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    this.joiningObj = {};
  }





  data = []
  disable_flag: boolean = false;
  party_exit: boolean = false;


  stepperIndex = 0;
  dob_blank = true;





  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }



  //*********************************node code****************************************************************************









  ///****************************Select Node*********************************************** */
  planing_arrproj :any = []
  projecthier:any  = []
  projhierobj :any = {}
  projhierobjtype :any = {}
  projhierobjtypecd :any = {}
  projhierobjisleaf:any  = {}

  allProject :any = [];
  async getAccountProjectHier() {
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAccountProjectHier(obj);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'HR-root'
        }
      }
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {

        var tt=[]
        for (let k = 1; k < 8; k++) {
          if (resp.data[i]['lvl' + k + '_cd']!=null) {
            tt.push(resp.data[i]['lvl' + k + '_cd'])
          }
        }
        this.dataAccessService.data_assigment=this.dataAccessService.data_assigment.filter((x:any )=>x.user_id==this.user_id)
        var exit_flag=false;
        for(let j=0;j<this.dataAccessService.data_assigment.length;j++){
          if(tt.includes(parseInt(this.dataAccessService.data_assigment[j]['node_cd']))){
            exit_flag=true;
          }
        }

        if(tt.length==1){
          exit_flag=true;
        }
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR"))
          && (resp.data[i]['module_cd'] != null || resp.data[i]['module_cd'] != undefined) && exit_flag) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;
      this.allProject = resp.data;
      this.projecthier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_user_cd']
          this.projhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']
        }
      }
    } else {
      console.log("Error in getting all project hier")
    }
  }

  open_activitypopup() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1)
    }

    console.log("Open Pop Up")
    $('#ACTHIER').modal('show');
  }


  down(item:any , k:any ) {
    console.log(k)
    console.log(item)
    console.log(this.planing_arrproj)
    let patharr = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      console.log(this.planing_arrproj[i])

      patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])


    }
    this.Obj['path_desc'] = patharr.join("-->")
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [] })
    console.log(this.planing_arrproj, item)
    let arr:any  = []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_cd'] != null) {

        if ((this.projecthier[i]['lvl' + (k + 1) + '_cd'] == item['hierarchy_type'] || k == -1) && this.projecthier[i]['leaf_node_type'] != 'HREMP') {

          if (k == 0) {
            arr = []
            for (let q = 0; q < this.joiningObj['module_cd'].length; q++) {
              if ((this.joiningObj['module_cd'][q] == this.projecthier[i]['module_cd']) && (this.projecthier[i]['lvl2_cd'] == this.projecthier[i]['leaf_cd'])) {
                console.log(this.projecthier[i])
                this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'] })
                arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
              }
            }
          }
          else {
            this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'] })
            arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
          }
        }
      }
    }
  }

  remove(item:any , i:any ) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i])

        patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
      }
      this.Obj['path_desc'] = patharr.join("-->")
    }

  }

 filterValue='';
  async open_update(element:any ) {
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
    this.joiningObj = Object.assign({}, element)
    console.log(element);
    console.log("thsi si the filter value ", this.filterValue);
    this.joiningObj['module_cd'] = [];
    this.joiningObj['old_module_cd'] = [];
    this.joiningObj['old_module_cd_data'] = [];
    for (let i = 0; i < this.allEmpAssignedModule.length; i++) {
      if (this.allEmpAssignedModule[i]['arr_id'] == element['arr_id']) {


        if (this.allEmpAssignedModule[i]['role_cd'] == null || this.allEmpAssignedModule[i]['role_cd'] == undefined) {

        } else {
          var dd = this.allEmpAssignedModule[i]['role_cd'].split(",");
          for (let j = 0; j < dd.length; j++) {
            this.joiningObj['module_cd'].push(this.roleToModule[dd[j]])
            this.joiningObj['old_module_cd'].push(this.roleToModule[dd[j]])
            this.joiningObj['old_module_cd_data'].push(this.roleToModule[dd[j]])
          }
        }
      }
    }



    for (let i = 0; i < this.allEmpPost.length; i++) {
      if (this.allEmpPost[i]['arr_id'] == element['arr_id']) {
        this.joiningObj['posting_module_cd'] = this.allEmpPost[i]['module_cd']
      }
    }


    let data:any  = {}
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]['leaf_user_cd'] == 'HRARR' + element['arr_id']) {
        data = this.projecthier[i]
        break
      }
    }
    console.log(data)
    this.planing_arrproj = []
    for (let i = 1; i < 8; i++) {
      console.log(data['lvl' + (i) + '_user_cd'])
      if (data['lvl' + (i) + '_user_cd'] != null && data['leaf_user_cd'] != data['lvl' + (i) + '_user_cd']) {
        if (i == 1) {
          this.down({ hierarchy_type: data['lvl' + (i) + '_cd'] }, -1)
          this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] = data['lvl' + (i) + '_cd']
          console.log(this.planing_arrproj)
        } else {
          this.down({ hierarchy_type: data['lvl' + (i - 1) + '_cd'] }, i - 2)
          this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] = data['lvl' + (i) + '_cd']
          this.setHierarchyNodeitem(this.planing_arrproj[this.planing_arrproj.length - 1], i - 1)

        }

      }
      this.Obj['node_type'] = data['leaf_node_type']
      this.Obj['node_cd'] = data['leaf_user_cd']
      this.Obj['node_desc'] = data['leaf_value']
    }

    this.Obj['old_path_desc'] = this.Obj['path_desc']

    console.log(this.joiningObj)
  }
  check:any
  setHierarchyNodeitem(item:any , i:any ) {
    console.log(item, i);
    this.check = item['hierarchy_type']
    console.log(this.check);
    item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']]
    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }
    this.Obj['path_desc'] = dummy2.join(' --> ');

  }

  async updateprojhier() {
    // let obb = new Object();

    // for (let i = 0; i < this.projecthier.length; i++) {
    //   if (this.projecthier[i]['leaf_user_cd'] == 'HRARR' + this.joiningObj['arr_id']) {
    //     obb['id'] = this.projecthier[i]['id']
    //   }
    // }
    // let level = 0
    // obb['b_acct_id'] = this.b_acct_id
    // console.log(this.planing_arrproj)
    // for (let i = 0; i < this.planing_arrproj.length; i++) {
    //   console.log(this.planing_arrproj[i])
    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_cd'] = this.planing_arrproj[i]['hierarchy_type']
    //   if (this.planing_arrproj[i]['level'] == 1) obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = 'All Projects'
    //   else obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = this.projhierobj[this.planing_arrproj[i]['hierarchy_type']]
    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_node_type'] = this.projhierobjtype[this.planing_arrproj[i]['hierarchy_type']]
    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'] = this.projhierobjtypecd[this.planing_arrproj[i]['hierarchy_type']]
    //   console.log(this.planing_arrproj[i]['level'], obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'])
    //   level = this.planing_arrproj[i]['level']

    // }
    // obb['lvl' + (level + 1) + '_user_cd'] = 'HRARR' + this.joiningObj['arr_id']
    // obb['lvl' + (level + 1) + '_cd'] = parseInt(this.joiningObj['node_code'])
    // obb['leaf_cd'] =parseInt( this.joiningObj['node_code'])
    // obb['lvl' + (level + 1) + '_node_type'] = 'HREMP'
    // obb['lvl' + (level + 1) + '_value'] = this.joiningObj['emp_name']
    // obb['lvl' + (level + 1) + '_type'] = 'text'
    // obb['leaf_user_cd'] = 'HRARR' + this.joiningObj['arr_id']
    // obb['leaf_node_type'] = 'HREMP';
    // obb['leaf_value'] = this.joiningObj['emp_name']
    // obb['leaf_type'] = 'text'
    // obb['module_cd'] = 'HR'
    // console.log(obb);

    var obj_new = Object();
    obj_new['b_acct_id'] = this.b_acct_id
    obj_new['create_user_id'] = this.erpUser.user_id;
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]['leaf_user_cd'] == 'HRARR' + this.joiningObj['arr_id']) {
        obj_new['current_obj'] = this.projecthier[i]
      }
    }
    this.joiningObj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']
    obj_new['destination_obj'] = {};
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]['leaf_cd'] == this.joiningObj['node_cd']) {
        obj_new['destination_obj'] = this.projecthier[i]
      }
    }
    obj_new['table_name'] = 'proj_hier';
    console.log(obj_new)

    this.spinner.show()
    var resp = await this.allEmpService.moveHierarchy(obj_new);
    console.log(resp)
    if (resp['error'] == false) {
      await this.update();
      this.spinner.hide();
    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', resp['data'], 'error');
    }
  }


  Obj :any = {}
  submit_act() {
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']);
    if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
      Swal.fire("Warning", "Please Select at least two level", 'warning')
    }
    else {

      $('#ACTHIER').modal('hide');
    }
    this.joiningObj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']
  }

  //update posting of employee
  async update() {
    // this.spinner.show()
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.joiningObj['emp_id'];
    obj['le_id'] = this.joiningObj['le_id'];
    obj['arr_id'] = this.joiningObj['arr_id'];


    var role = ['HR_EMP'];
    var post_data = [];


    var temp_module
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.planing_arrproj[1]['hierarchy_type'] == this.projecthier[i]['leaf_cd']) {
        temp_module = this.projecthier[i]['module_cd']
        post_data.push({ cost_center: this.projecthier[i]['leaf_user_cd'], module_cd: this.projecthier[i]['module_cd'], is_primary: 1 })
      }
    }

    for (let i = 0; i < this.joiningObj['module_cd'].length; i++) {
      if (temp_module != this.joiningObj['module_cd'][i]) {
        // post_data.push({ cost_center: 'NA', module_cd: this.joiningObj['module_cd'][i], is_primary: 0 })
      }
      if (role.includes(this.moduleToRole[this.joiningObj['module_cd'][i]])) {
      } else {
        role.push(this.moduleToRole[this.joiningObj['module_cd'][i]])
      }
    }

    // post_data.push({ cost_center: this.joiningObj['cost_center'], module_cd: this.joiningObj['module_cd'], is_primary: 1 })
    // for (let i = 0; i < post_data.length; i++) {
    //   post_data[i]['cost_center_desc'] = this.ccCodeToDesc[post_data[i]['cost_center']]
    //   if (role.includes(this.moduleToRole[post_data[i]['module_cd']])) {
    //   } else {
    //     role.push(this.moduleToRole[post_data[i]['module_cd']])
    //   }
    // }

    obj['role_data'] = role;
    obj['posting_data'] = post_data;
    console.log(obj);

    var resp = await this.establishmentService.updateEmpPosting(obj);
    if (resp['error'] == false) {
      setTimeout(async () => {
        await this.getListOfAllEmployee();
        await this.AllEmployeeAssigedPost();
        await this.getEmployeeFunctionArea();
        await this.getAccountProjectHier();
        // await this.changeEmployee();
        await this.open_list();
        this.spinner.hide()
        swal.fire('Success', 'Assignment Detail Updated..!', 'success')
      }, 1000);

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Some Error Occured', 'error')
    }
  }

  selectedModule(module_cd:any ) {
    if (!module_cd.includes(this.joiningObj['posting_module_cd'])) {
      this.planing_arrproj = [];
      this.Obj = {}
    }
  }

  openlist() {
    console.log(this.joiningObj)
  }



  checkFunction()
  {
    if(this.joiningObj['module_cd']==''|| this.joiningObj['module_cd']==null || this.joiningObj['module_cd']==undefined
     || this.Obj['path_desc']==undefined || this.Obj['path_desc']==null || this.Obj['path_desc']=='' )
    {
            swal.fire("Warning", '* Field Required', 'warning');
            return;
    }
    else{
      this.stepper2.next();
    }
  }









  // constructor(public mainService: MainService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private establishmentService: EstablishmentService) { }
  // personalInfoObj = {};
  // erpUser;
  // b_acct_id;
  // allEmplyees = [];
  // selectEmpObj = {};
  // selectedEmpPosting = [];
  // postingObj = {};
  // is_enforcement = [{ id: 'YES', value: 1 }, { id: 'NO', value: 0 }]

  // party_current_arrangment = {};
  // party_current_posting = {};
  // codeValueTechObj = {};
  // errorMsg = ''
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  // status = [{ code: 'ACTIVE', value: 'ACTIVE' }, { code: 'INACTIVE', value: 'INACTIVE' }]
  // displayedColumns = ['id', 'designation_code', 'section_code', 'is_enforcement', 'zone', 'posting_code', 'posting_type_code', 'posting_date', 'posting_end_date','is_head', 'order_no', 'action'];
  // empObj = {}
  // datasource;
  // datasource1;
  // newallEmplyees = []
  // isHead=[{code:0,value: 'NO'},{code:1,value: 'YES'}]
  // async ngOnInit() {
  //   this.codeValueTechObj = this.mainService.codeValueTechObj;

  //   this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')));
  //   this.b_acct_id = this.erpUser.b_acct_id;
  //   await this.getAllEmployees();
  // }
  // getNumberFormat(num) {
  //   if(num == undefined){
  //     return ""
  //   }
  //   return num.toString().padStart(3, "0")
  // }
  // async getAllEmployees() {
  //   this.spinner.show()
  //   var obj:any  = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   var resp = await this.establishmentService.getEmployeeMasterData(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     this.allEmplyees = resp.data;
  //     this.empObj = new Object
  //     for (let i = 0; i < this.allEmplyees.length; i++) {
  //       this.empObj[this.allEmplyees[i]['emp_id']] = this.allEmplyees[i]['emp_name']
  //     }
  //     this.newallEmplyees = []
  //     for (let i = 0; i < this.allEmplyees.length; i++) {
  //       var obj:any  = new Object();
  //       obj = Object.assign({}, this.allEmplyees[i]);
  //       obj['emp_name'] = /* this.mainService.accInfo['account_short_name'] + */ this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
  //       this.newallEmplyees.push(obj)
  //     }
  //     this.spinner.hide()

  //   } else {
  //     this.spinner.hide()
  //     this.snackBar.open("Error while getting employee list", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }
  // async changeEmployee() {
  //   var obj:any  = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['emp_id'] = this.selectEmpObj['emp_id'];
  //   this.spinner.show();
  //   var resp2 = await this.establishmentService.getPostingInfo(obj);
  //   if (resp2['error'] == false) {
  //     for (let i = 0; i < resp2['data'].length; i++) {
  //       for (let j = 0; j < this.is_enforcement.length; j++) {
  //         if (resp2['data'][i]['is_enforcement'] == this.is_enforcement[j]['value']) {
  //           resp2['data'][i]['is_enforcement'] = this.is_enforcement[j]['id']
  //         }
  //       }
  //     }
  //     for (let i = 0; i < resp2.data.length; i++) {
  //       resp2.data[i]['tempposting_date'] = this.mainService.dateformatchange(resp2.data[i]['posting_date'])
  //       resp2.data[i]['tempposting_end_date'] = this.mainService.dateformatchange(resp2.data[i]['posting_end_date'])

  //     }
  //     this.datasource = new MatTableDataSource(resp2.data)
  //     this.datasource.paginator = this.paginator;
  //     this.datasource.sort = this.sort
  //     this.spinner.hide();

  //   } else {
  //     this.spinner.hide()
  //     this.snackBar.open("Error while getting Current Posting ", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }
  // async submitPostingInfo() {


  //   swal.fire({
  //     title: 'Are you sure?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, Add it!'
  //   }).then((result) => {
  //     if (result.value) {
  //       this.finalsubmitPostingInfo()
  //     }
  //   })



  // }
  // async finalsubmitPostingInfo() {
  //   this.postingObj['b_acct_id'] = this.b_acct_id;
  //   this.postingObj['create_user_id'] = this.erpUser.user_id;
  //   this.postingObj['emp_id'] = this.selectEmpObj['emp_id'];
  //   this.postingObj['posting_status_code'] = 'ACTIVE';
  //   if (this.postingObj['status'] == 'ACTIVE') {
  //     this.postingObj['posting_end_date'] = '2090-10-10'
  //   }
  //   if(this.postingObj['is_enforcement']==1){
  //     if(this.postingObj['zone']==null || this.postingObj['zone']==undefined){
  //       this.postingObj['zone']=""
  //     }
  //   }else{
  //     this.postingObj['zone']=null;
  //   }

  //   this.spinner.show();
  //   var resp = await this.establishmentService.addPostingInfo(this.postingObj);
  //   if (resp['error'] == false) {
  //     this.spinner.hide();
  //     await this.changeEmployee();

  //     swal.fire("Success", "Posting Info Added", 'success');


  //   } else {
  //     this.spinner.hide();
  //     swal.fire("Sorry", "...Some Error Occured!", 'error');

  //   }
  // }

  // applyFilter(filterValue: string) {
  //   this.datasource.filter = filterValue.trim().toLowerCase();
  // }



  // async chnageStatus(element) {
  //   this.spinner.show()
  //   var obj:any  = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['id'] = element['id'];

  //   var resp2 = await this.establishmentService.deactivatePost(obj);

  //   if (resp2['error'] == false) {
  //     this.spinner.hide()
  //     this.changeEmployee();

  //     swal.fire("Success", "Post Inactive Successfully!", 'success');


  //   } else {
  //     this.spinner.hide()
  //     swal.fire("Error", "Some Error Occurred!", 'error');

  //   }
  // }

  // chamgeTab() {
  //   this.errorMsg = ''
  //   this.postingObj = {}
  // }
  // openUpdate(element) {
  //   // is_enforcement=[{id:'YES' , value:1},{id:'NO',value:0}]
  //   this.postingObj = Object.assign({}, element);
  //   for (let i = 0; i < this.is_enforcement.length; i++) {
  //     if (this.is_enforcement[i]['id'] == this.postingObj['is_enforcement']) {
  //       this.postingObj['is_enforcement'] = this.is_enforcement[i]['value']
  //     }
  //   }
  //   $('.nav-tabs a[href="#tab-3"]').tab('show');

  // }
  // async updatePostingInfo() {
  //   this.postingObj['b_acct_id'] = this.b_acct_id;
  //   this.postingObj['update_user_id'] = this.erpUser.user_id;
  //   if(this.postingObj['is_enforcement']==1){
  //     if(this.postingObj['zone']==null || this.postingObj['zone']==undefined){
  //       this.postingObj['zone']=""
  //     }
  //   }else{
  //     this.postingObj['zone']=null;
  //   }
  //   this.spinner.show();
  //   var resp = await this.establishmentService.updatePost(this.postingObj);
  //   if (resp['error'] == false) {
  //     this.spinner.hide();
  //     await this.changeEmployee();

  //     this.snackBar.open("Updated Successfully ", 'Success', {
  //       duration: 5000
  //     });

  //   } else {
  //     this.spinner.hide();
  //     this.snackBar.open("Error while Updating Posting ", 'Error', {
  //       duration: 5000
  //     });

  //   }

  // }
}
