import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//oc
// import { AgmCoreModule } from '@agm/core';
//ec
import { NgSelectModule } from '@ng-select/ng-select';
// import { ChartsModule } from 'ng2-charts';
import { NgChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from 'ng2-file-upload';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgtreegridModule } from 'ngtreegrid';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { NgApexchartsModule } from 'ng-apexcharts';
// import { ToastrModule } from 'ng6-toastr-notifications';

import { MdLayoutsRoutingModule } from './md-layouts-routing.module';
import { AccountConfigComponent } from '../account-config/account-config.component';
import { MdCreateRoleComponent } from '../md-create-role/md-create-role.component';
import { MdDashboardComponent } from '../md-dashboard/md-dashboard.component';
import { MdLegalEntityComponent } from '../md-legal-entity/md-legal-entity.component';
import { MdUserComponent } from '../md-user/md-user.component';

import { VendorFeeComponent } from '../vendor-fee/vendor-fee.component';
import { VendorTComponent } from '../vendor-t/vendor-t.component';
import { VendorsComponent } from '../vendors/vendors.component';

import { WorkflowDefinitionComponent } from '../workflow-definition/workflow-definition.component';
import { MdFooterComponent } from './md-footer/md-footer.component';
import { MdHeaderComponent } from './md-header/md-header.component';
import { MdLayoutsComponent } from './md-layouts.component';
import { MdSidebarComponent } from './md-sidebar/md-sidebar.component';


@NgModule({
  declarations: [
    MdLayoutsComponent,
    MdDashboardComponent,
    MdFooterComponent,
    MdHeaderComponent,
    MdSidebarComponent,
    MdLegalEntityComponent,
    MdCreateRoleComponent,
    MdUserComponent,
    AccountConfigComponent,
    // WorkflowDefinitionComponent,


    //oc
    // VendorsComponent,
    VendorTComponent,
    VendorFeeComponent,
    //ec
  ],
  exports: [
    MdLayoutsComponent,
    MdHeaderComponent,
    MdFooterComponent,
    MdSidebarComponent,


],
  imports: [

    CommonModule,
    MdLayoutsRoutingModule,
    //oc
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    //   apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    //ec
    NgSelectModule,
    NgChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    MatSlideToggleModule,
    FormsModule,
    // NgtreegridModule,
    MatStepperModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    MatTabsModule,
    ToastrModule.forRoot(),
  ]
})
export class MdLayoutsModule { }
