<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        Manage Arrear
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="chamgeTab()"><i class="ti-bar-chart"></i>
                                Employee All Arrear List
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"
                                    (click)="chamgeTab()"></i>
                                Add Arrear
                            </a>
                        </li>
                        <!--   <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                                Update  Arrear Detail
                            </a>
                        </li> -->

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade  show active" id="tab-1">

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Year :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="billIdObj['fin_year']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Month :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="billIdObj['month']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getbillbydate()">GET ALL BILL</button>
                                </div>
                            </div>


                            <hr>

                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)"
                                            placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-4 text-right">
                                    <button class="btn btn-primary" (click)="downloadFile()">Download Excel</button>
                                </div>
                                <div class="col-2 text-right">
                                    <button class="btn btn-primary" (click)="print()">Download Pdf</button>
                                </div> -->


                            </div>


                            <div class=" example-container">
                                <table mat-table [dataSource]="dataSource1" matSort #sortCol2="matSort">
                                    <ng-container matColumnDef="bill_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Bill Id

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element['bill_id'] }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="bill_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bill
                                            Description

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.bill_desc }} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="section_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Section

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.section_code }} </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="bill_status_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bill Status

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.bill_status_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bill_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bill Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.accrual_date)}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="print">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Download

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary"
                                                (click)="getAllBill(element,'bill')"> Detailed Bill</button>
                                                <button class="btn btn-success" style="margin: 2px;"
                                                (click)="getAllBillshort(element,'bill')">Bill</button>
                                            <!-- <button class="btn btn-primary" (click)="getAllBill(element,'summary')">Summary</button> -->
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button *ngIf="element.bill_status_code == 'GENERATED'" class="btn btn-info"
                                                (click)="statusChange(element)">APPROVED</button> -->
                                            <button *ngIf="element.bill_status_code == 'GENERATED'" class="btn btn-info"
                                                (click)="Appr(element)">Send TO Approval</button>

                                            <button *ngIf="element.bill_status_code == 'APPROVED'" class="btn btn-info"
                                                (click)="sendTOAccount(element)">sendTOAccount</button>
                                            <button
                                                *ngIf="element.bill_status_code == 'GENERATED' || element.bill_status_code == 'REJECTED'"
                                                class="btn btn-danger" (click)="deleteBill(element)">Delete</button>
                                            <button *ngIf="element.bill_status_code != 'GENERATED' "
                                                class="btn btn-primary" (click)="status(element)">View Status</button>


                                        </td>
                                    </ng-container>



                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>

                            <div *ngIf="salaryObj['type'] == 'ind'" class="row">
                                <div class="col-4 text-right">
                                    Select Employee :
                                </div>

                                <div class="col-4">
                                    <ng-select disabled [items]="allEmplyees_new" bindLabel="emp_name"
                                        bindValue="arr_id" [multiple]="true" placeholder="" [(ngModel)]="ind_emp_id"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                            </div>

                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 style="margin-left: 2%;">
                                        Select Node :
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                        Node</button>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 style="margin-left: 2%;">
                                        Selected Node :
                                    </h6>
                                </div>
                                <div class="col-4">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">

                                    Select Component
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="all_component"  bindLabel="value" bindValue="code"
                                        [multiple]="true" placeholder=""  [(ngModel)]="salaryObj['component']"   [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="false" name="field_name11">
                                    </ng-select> -->

                                    <ng-select [items]="all_component" bindLabel="value" bindValue="value" disabled
                                        [multiple]="false" placeholder="" [(ngModel)]="salaryObj['component']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name11">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Type
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="PeriodType" bindLabel="value" bindValue="value"
                                        (change)="settype()" [multiple]="false" placeholder="Select Type"
                                        [(ngModel)]="selectedendate" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div *ngIf="flag">
                                <div class="row">
                                    <div class="col-4 text-right">
                                        Select Year :
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder=""
                                            [(ngModel)]="salaryObj['fin_year']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        </ng-select>
                                    </div>

                                </div>

                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        Select Month :
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder=""
                                            [(ngModel)]="salaryObj['month']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        </ng-select>

                                    </div>

                                </div>
                                <br>


                            </div>
                            <div *ngIf="!flag">
                                <!-- <div class="row">
                                    <div class="col-4 text-right">
                                        Select Start Date :
                                    </div>
                                    <div class="col-4">
                                        <input class="form-control" type="date" [(ngModel)]="salaryObj['start_dt']">
                                    </div>
                                </div>

                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        Select End Date :
                                    </div>
                                    <div class="col-4">
                                        <input class="form-control" type="date" [(ngModel)]="salaryObj['end_dt']">
                                    </div>
                                </div> -->

                                <div class="row">
                                    <div class="col-3 text-right">
                                        Select Start Year :
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder=""
                                            [(ngModel)]="salaryObj['start_fin_year']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        </ng-select>
                                    </div>

                                    <div class="col-3 text-right">
                                        Select Start Month :
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder=""
                                            [(ngModel)]="salaryObj['start_month']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        </ng-select>

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 text-right">
                                        Select End Year :
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder=""
                                            [(ngModel)]="salaryObj['end_fin_year']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        </ng-select>
                                    </div>

                                    <div class="col-3 text-right">
                                        Select End Month :
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder=""
                                            [(ngModel)]="salaryObj['end_month']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        </ng-select>

                                    </div>

                                </div>
                                <br>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getall()">
                                        Get Arrear
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Arrear Accrual Date :
                                </div>
                                <div class="col-4">
                                    <input type="date" disabled class="form-control"
                                        [(ngModel)]="salaryObj['accrual_date']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter4($any($event.target).value)"
                                            placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4 text-center">
                                    <h6>Total Arrear Amount:</h6>{{arrear_total}}
                                </div>
                                <div class="col-4 text-right">
                                    <button class="btn btn-primary" (click)="generateArrear()">Arrear Accrual</button>
                                </div>
                            </div>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort #sort="matSort">
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Employee ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{salaryObj['emp_info'][element.arr_id]['emp_id']}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee
                                            Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ salaryObj['emp_info'][element.arr_id]['emp_name'] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee
                                            Phone No

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ salaryObj['emp_info'][element.arr_id]['emp_phone_no'] }} </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="pay_comp">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Pay
                                            Component

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_comp }} </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="total">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Amount

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.total }} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="pay_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Payable /
                                            Deduction

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_code }} </td>
                                    </ng-container> -->
                                    <!-- <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.date }} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-warning" (click)="openview(element)">View
                                                Details</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns4;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa17']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_desc" bindValue="emp_id"
                                        [multiple]="false"
                                        placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa17']}}"
                                        [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>


                                </div>
                            </div>
                            <br>



                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- BASIC: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa22']}}
                                </div>
                                <div class="col-4">
                                    {{selectEmpObj['basic']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- OLD DA: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa23']}}
                                </div>
                                <div class="col-4">
                                    {{selectEmpObj['da']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- NEW DA: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa30']}}
                                </div>
                                <div class="col-4">
                                    <input type="number" (change)="changedaupdate()" class="form-control"
                                        [(ngModel)]="selectEmpObj['new_da']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Start Date: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa19']}}
                                </div>
                                <div class="col-4">
                                    <input type="date" (change)="changedaupdate()" class="form-control"
                                        [(ngModel)]="selectEmpObj['arrear_start_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- End Date: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa20']}}
                                </div>
                                <div class="col-4">
                                    <input type="date" (change)="changedaupdate()" class="form-control"
                                        [(ngModel)]="selectEmpObj['arrear_end_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Total Arrear : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa24']}}
                                </div>
                                <div class="col-4">
                                    {{selectEmpObj['amount']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">
                                        <!-- Update -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_daa15']}}
                                    </button>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Project</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                    <div class="col-3"></div>
                    <div class="col-3" *ngIf="i==0">
                        <h6 style="margin-left: 2%;">Select Node :<span style="color: red;"> *</span></h6>
                        <!-- Select Node : -->
                    </div>
                    <div class="col-3" *ngIf="i!=0"></div>
                    <div class="col-1">

                        <span *ngIf="item['level']">Level</span>{{item['level']}}
                        <!-- Level -  -->
                    </div>
                    <div class="col-3">
                        <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd"
                            [multiple]="false" placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']"
                            [selectableGroup]="true" (change)="setHierarchyNodeitem(item,i)"
                            [selectableGroupAsModel]="true" [closeOnSelect]="true">
                        </ng-select>
                        <!-- Select  Node -->
                    </div>
                    <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                        <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                aria-hidden="true"></i></button>
                        &nbsp;&nbsp;
                        <button (click)="remove(item,i)" class="btn btn-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Current Node Path : <span style="color: red;">*</span>
                        </h6>
                    </div>
                    <div class="col-6">
                        {{Obj['path_desc']}}
                    </div>

                </div>
                <br>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submitProjectHierNode()">Submit</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- <div class="modal" id="Z" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document"> -->
<div class="modal" id="Z" >
    <div class="modal-xl">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" style="text-align: center; color: darkblue;">Arrear Details---></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>
            <div class="modal-body">

                <div *ngFor="let mth of arrear_months;let j = index">

                    <div class="row text-center" style="margin-left:50%"><h5>{{monthObj[mth['month']]+'---'+mainService.codeValueShowObj['HR0023'][mth['year']]}}</h5></div>


                    <table class="table table-bordered"
                        style="width: 95%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color: #011831; color: white;">
                                <th *ngFor="let ln of col_list;let i = index">{{ln}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln1 of billObjData[mth['month']+'-'+mth['year']];let k = index">
                                <td *ngFor="let col of col_list;let l = index">{{ln1[col]}}</td>
                            </tr>
                            <tr *ngFor="let ln1 of billObjAAData[mth['month']+'-'+mth['year']];let k = index">
                                <td *ngFor="let col of col_list;let l = index">{{ln1[col]}}</td>
                            </tr>


                        </tbody>

                    </table>


                </div>
                <hr>
                <!-- <div *ngFor="let mth of arrear_months;let j = index">

                    <div class="row text-center" style="margin-left:50%"><h5>{{mth['month']+'---'+mth['year']}}</h5></div>

                    <table class="table table-bordered"
                        style="width: 95%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th *ngFor="let ln of col_list;let i = index">{{ln}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln1 of billObjAAData[mth['month']+'-'+mth['year']];let k = index">
                                <td *ngFor="let col of col_list;let l = index">{{ln1[col]}}</td>
                            </tr>

                        </tbody>

                    </table>


                </div> -->



            </div>



            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
