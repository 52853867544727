<div class="page-content fade-in-up">

    <div class="row">
        <!-- <div class="col-lg-3 col-md-6">
            <div class="ibox bg-success color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{Obj['c1']}}</h2>
                    <button class="btn btn-primary" (click)="modalOpen(1)">VIEW</button>
                    <div class="m-b-5">Legal Entities</div><i class="fa fa-tasks widget-stat-icon"></i>
                </div>
            </div>
        </div> -->
        <div class="col-lg-3 col-md-6">
            <div class="ibox bg-success color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{Obj['c3']}}</h2>
                    <button class="btn btn-primary" (click)="modalOpen(3)">{{mainService.allLableShowObj[mainService.language_cd+'md_dashboard1']}}</button>
                    <div class="m-b-5">{{mainService.allLableShowObj[mainService.language_cd+'md_sidebar2']}}</div><i class="fa fa-users widget-stat-icon"></i>
                    <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="ibox bg-info color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{Obj['c2']}}</h2>
                    <button class="btn btn-primary" (click)="modalOpen(2)">{{mainService.allLableShowObj[mainService.language_cd+'md_dashboard1']}}</button>
                    <div class="m-b-5">{{mainService.allLableShowObj[mainService.language_cd+'md_sidebar3']}}</div><i class="fa fa-users widget-stat-icon"></i>
                    <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
                </div>
            </div>
        </div>


    </div>





    <style>
        .visitors-table tbody tr td:last-child {
            display: flex;
            align-items: center;
        }
        
        .visitors-table .progress {
            flex: 1;
        }
        
        .visitors-table .progress-parcent {
            text-align: right;
            margin-left: 10px;
        }
    </style>
</div>
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">Legal Entities</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div style="overflow-x: scroll;">
                    <table style="width: 100%;" class="table table-bordered">
                        <thead style="background-color: blue ; color: white;">
                            <tr>
                                <th>Legal Entity ID</th>
                                <th>Party Name</th>
                                <th>Party Type</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ob of current_LE;let i=index">
                                <td>{{ob['le_id']}}</td>
                                <td> {{ob['party_name']}}</td>
                                <td *ngIf="ob['party_type']=='IND'"> Individual</td>
                                <td *ngIf="ob['party_type']=='ORG'"> Organization</td>
                                <td> {{ob['contact_email']}}</td>
                                <td>{{ob['phone_no']}}</td>


                            </tr>
                        </tbody>

                    </table>

                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">{{mainService.allLableShowObj[mainService.language_cd+'md_users22']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">{{mainService.allLableShowObj[mainService.language_cd+'md_sidebar3']}}</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div style="overflow-x: scroll;">
                    <table style="width: 100%;" class="table table-bordered">
                        <thead style="background-color: blue ; color: white;">
                            <tr>
                                <!-- <th> ID</th> -->
                                <th>{{mainService.allLableShowObj[mainService.language_cd+'md_dashboard8']}}</th>
                                <th>{{mainService.allLableShowObj[mainService.language_cd+'md_dashboard9']}}</th>
                                <!-- <th>{{mainService.allLableShowObj[mainService.language_cd+'md_dashboard10']}}</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ob of all_users;let i=index">
                                <!-- <td>{{ob['id']}}</td> -->
                                <td> {{ob['party_name']}}</td>

                                <td> {{ob['phone_no']}}</td>
                                <!-- <td> {{ob['role_name']}}</td> -->


                            </tr>
                        </tbody>

                    </table>

                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">{{mainService.allLableShowObj[mainService.language_cd+'md_users22']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">{{mainService.allLableShowObj[mainService.language_cd+'md_sidebar2']}}</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div style="overflow-x: scroll;">
                    <table style="width: 100%;" class="table table-bordered">
                        <thead style="background-color: blue ; color: white;">
                            <tr>
                                <th>{{mainService.allLableShowObj[mainService.language_cd+'md_dashboard3']}}.</th>
                                <th>{{mainService.allLableShowObj[mainService.language_cd+'md_dashboard4']}}</th>
                                <th>{{mainService.allLableShowObj[mainService.language_cd+'md_dashboard5']}}</th>
                                <th>{{mainService.allLableShowObj[mainService.language_cd+'md_dashboard6']}}</th>
                                <th>{{mainService.allLableShowObj[mainService.language_cd+'md_dashboard7']}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ob of current_role;let i=index">
                                <td>{{i+1}}</td>
                                <td>{{ob['role_cd']}}</td>
                                <td> {{ob['role_desc']}}</td>

                                <td> {{ob['role_type']}}</td>
                                <td> {{ob['resource_desc']}}</td>


                            </tr>
                        </tbody>

                    </table>

                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">{{mainService.allLableShowObj[mainService.language_cd+'md_users22']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>