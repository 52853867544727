import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


import { AllEmpService } from '../../../service/all-emp.service';
import { ApprovalUserService } from '../../../../portal/service/approval-user.service';
import { BillSendToAccountService } from '../../../service/bill-send-to-account.service';
import { DataAccessService } from '../../../service/data-access.service';
import { EstablishService } from '../../../service/establish.service';
import { MainService } from '../../../service/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { PayrollService } from '../../../service/payroll.service';
import { Router } from '@angular/router';
import { SalaryService } from '../../../service/salary.service';
import { MainService as mainserviceEmb } from '../../../../emb/service/main.service';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
import swal from 'sweetalert2';

declare var $: any;


(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-all-arrear',
  templateUrl: './all-arrear.component.html',
  styleUrls: ['./all-arrear.component.css']
})
export class AllArrearComponent implements OnInit {

  erpUser: any;
  b_acct_id: any;

  currentBillObj: any = { header: {}, allEmployees: [], data: {}, sums: {} };
  allEmplyees: any = [];
  selectEmpObj: any = {};
  variablepayObj: any = {};
  codeValueTechObj: any = {};
  hold_sal: any = []
  ind_emp_id: any;
  // PeriodType = [{ value: 'CUSTOM' }, { value: 'PERIOD' }]
  PeriodType: any = [{ value: 'PERIOD' }];
  arr_id: any;
  allCurrentArrangements: any = [];
  allFixedPay: any = [];
  allEmplyees_new: any = []
  allVariablePay: any = [];
  allPosting: any = []
  billIdObj: any = {}
  salaryArr: any = [];
  allBillData: any = [];
  allRule: any = {};
  allBillId: any = [];
  actualSalaryArr: any = [];
  allAttendance: any = [];
  stopObj: any = {};
  statusArr: any = [];
  flag_sal: any = false
  dataSource1: any;
  systemDate: any
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  all_component: any = []
  salaryObj: any = { component: 'ALL', type: 'ind', accrual_date: '', b_acct_id: '', fin_year: '', month: '', section_code: '', emp_cat_code: '', post_info: {}, emp_info: {}, employement_info: {}, bank_info: {}, att_info: {}, fixed_pay_info: {}, variable_pay_info: {}, total_pay: {} }

  //displayedColumns = ['emp_id', 'emp_name', 'designation_code', 'areear_type', 'amount', 'arrear_start_dt', 'arrear_end_dt', 'status', 'action'];
  datasource: any;
  displayedColumns4: any = ['emp_id', 'emp_name', 'emp_phone_no', 'total', 'action'];
  constructor(private estNewService: EstablishService, public ApprovalUserService: ApprovalUserService, private mainserviceEmb: mainserviceEmb, private billSendToAccountService: BillSendToAccountService, public mainService: MainService, private salaryService: SalaryService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private payableService: PayrollService, private allEmpService: AllEmpService, private dataAccessService: DataAccessService) { }
  monthObj: any = { '1': 'January', '2': 'February', '3': 'March', '4': 'April', '5': 'May', '6': 'June', '7': 'July', '8': 'August', '9': 'September', '10': 'October', '11': 'November', '12': 'December' }
  monthObjShort: any = { '1': 'JAN', '2': 'FEB', '3': 'MAR', '4': 'APR', '5': 'MAY', '6': 'JUN', '7': 'JUL', '8': 'AUG', '9': 'SEP', '10': 'OCT', '11': 'NOV', '12': 'DEC' }
  monthEnd: any = { '1': 31, '2': 28, '3': 31, '4': 30, '5': 31, '6': 30, '7': 31, '8': 31, '9': 30, '10': 31, '11': 30, '12': 31 }
  selectedendate: any = 'PERIOD'
  displayedColumns1: any = ['bill_id', 'bill_desc', 'bill_status_code', 'bill_date', 'print', 'action'];

  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    var resp = await this.payableService.getSystemDate();
    this.systemDate = resp.data;
    await this.getDate();
    await this.getallcomponent()
    await this.getAllEmployees();
    await this.getAccountProjectHier();
    await this.getAllEmployees();
    await this.getAllActiveEmployees();
    this.salaryObj['accrual_date'] = this.dateToday;

  }




  flag = true;
  settype() {
    if (this.selectedendate == 'CUSTOM') {
      this.flag = false;
    } else {
      this.flag = true;
    }
  }


  dateToday: any
  async getDate() {
    var resp = await this.estNewService.getDate();
    if (resp['error'] == false) {
      this.dateToday = resp.data.split(" ")[0];
      console.log(this.dateToday)
    } else {

    }
  }
  async getallcomponent() {
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    var resp1 = await this.payableService.getAllComponent(JSON.stringify(obj));
    if (resp1['error'] == false) {
      this.all_component = [{ code: 'ALL', value: 'ALL' }]
      for (let i = 0; i < resp1.data.length; i++) {
        let obj11: any = new Object();
        obj11['code'] = resp1.data[i]['component_code']
        obj11['value'] = resp1.data[i]['component_code']
        this.all_component.push(obj11)
      }
    }
    console.log(this.all_component)
  }


  chamgeTab() {
    this.selectEmpObj = {}
  }


  fixedpay: any = []
  async fixedpayamt() {

    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;


    var resp = await this.payableService.getAllFixedPay(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.fixedpay = resp.data

      var arr = []
      for (let i = 0; i < this.fixedpay.length; i++) {
        var obj: any = new Object();
        obj = Object.assign(this.fixedpay[i]);
        if (obj['effective_end_dt'] >= this.systemDate && (obj['pay_component_code'] == 'BASIC' || obj['pay_component_code'] == 'DA')) {
          arr.push(obj)
        }



      }
      this.fixedpay = arr

    } else {


      this.spinner.hide();
      swal.fire('Error', 'Error while getting employee all Fixed Pay list ', 'error')
      // this.snackBar.open("Error while getting employee all Fixed Pay list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  encashment = []

  async getarrear() {

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectEmpObj['emp_id'];
    this.spinner.show();

    var resp1 = await this.payableService.getarrear(JSON.stringify(this.b_acct_id));
    if (resp1['error'] == false) {

      this.spinner.hide();
      this.datasource = new MatTableDataSource(resp1.data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee all Variable Pay list ', 'error')
      // this.snackBar.open("Error while getting employee all Variable Pay list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  dadata: any = []
  changeda() {
    var fixedarr: any = {}
    if (this.selectEmpObj['emp_id'][0] == 'ALL') {
      this.selectEmpObj['emp_id'] = []
      for (var i = 0; i < this.allEmplyees.length; i++) {
        this.selectEmpObj['emp_id'].push(this.allEmplyees[i]['emp_id'])
        fixedarr[this.allEmplyees[i]['emp_id']] = {}
        for (var j = 0; j < this.fixedpay.length; j++) {
          if (this.allEmplyees[i]['emp_id'] == this.fixedpay[j]['emp_id']) {

            fixedarr[this.allEmplyees[i]['emp_id']][this.fixedpay[j]['pay_component_code']] = this.fixedpay[j]['pay_component_amt']
          }

        }
      }
    }
    else {
      for (var i = 0; i < this.selectEmpObj['emp_id'].length; i++) {
        fixedarr[this.selectEmpObj['emp_id'][i]] = {}
        for (var j = 0; j < this.fixedpay.length; j++) {
          if (this.selectEmpObj['emp_id'][i] == this.fixedpay[j]['emp_id']) {

            fixedarr[this.selectEmpObj['emp_id'][i]][this.fixedpay[j]['pay_component_code']] = this.fixedpay[j]['pay_component_amt']
          }

        }
      }
    }
    var month = 0
    if (this.selectEmpObj['arrear_start_dt'] != undefined && this.selectEmpObj['arrear_end_dt'] != undefined) {
      var arrfrom = this.selectEmpObj['arrear_start_dt'].split('-')
      var arrto = this.selectEmpObj['arrear_end_dt'].split('-');
      arrto[0] = parseInt(arrto[0])
      arrto[1] = parseInt(arrto[1])
      arrfrom[0] = parseInt(arrfrom[0])
      arrfrom[1] = parseInt(arrfrom[1])
      if (arrto[0] == arrfrom[0]) {
        month = arrto[1] - arrfrom[1] + 1
      } else if (arrto[0] == arrfrom[0] + 1) {
        month = 12 - arrfrom[1] + 1 + arrto[1];

      } else {
        month = 12 - arrfrom[1] + 1 + arrto[1] + (arrto[0] - arrfrom[0] - 1) * 12;

      }

    }
    this.dadata = []
    for (var i = 0; i < this.selectEmpObj['emp_id'].length; i++) {
      if (Object.keys(fixedarr[this.selectEmpObj['emp_id'][i]]).length > 1) {
        var old_da = (fixedarr[this.selectEmpObj['emp_id'][i]]['DA'] / fixedarr[this.selectEmpObj['emp_id'][i]]['BASIC']) * 100
        var da = this.selectEmpObj['new_da'] - old_da
        var basic = fixedarr[this.selectEmpObj['emp_id'][i]]['BASIC']
        var amt = ((da / 100) * basic * month).toFixed(2);
        var obj = Object()
        obj['emp_id'] = this.selectEmpObj['emp_id'][i]
        obj['amount'] = amt
        obj['emp_id'] = this.selectEmpObj['emp_id'][i]
        for (var j = 0; j < this.allEmplyees.length; j++) {
          if (obj['emp_id'] == this.allEmplyees[j]['emp_id']) {
            obj['emp_name'] = this.allEmplyees[j]['emp_name']
          }
        }
        var data = []
        var dataobj = Object()
        dataobj['new_da'] = this.selectEmpObj['new_da']
        dataobj['da'] = old_da
        dataobj['basic'] = basic
        dataobj['arrear_start_dt'] = this.selectEmpObj['arrear_start_dt']
        dataobj['arrear_end_dt'] = this.selectEmpObj['arrear_end_dt']
        data.push(dataobj)
        obj['data'] = JSON.stringify(data)
        this.dadata.push(obj)
      }
    }


  }

  getStartAndEndDate(month: any, fin_year: any) {
    let date_period: any = { start_date: null, end_date: null }
    var year;
    if (month == '1' || month == '2' || month == '3') {
      year = parseInt(fin_year) + 1;
    } else {
      year = parseInt(fin_year);
    }
    this.getLeap(year);
    if (parseInt(month) > 9) {
      date_period.start_date = year + "-" + month + "-01"

    } else {
      date_period.start_date = year + "-0" + month + "-01"
    }
    if (parseInt(month) > 9) {
      date_period.end_date = year + "-" + month + "-" + this.monthEnd[month]

    } else {
      date_period.end_date = year + "-0" + month + "-" + this.monthEnd[month]
    }
    console.log(date_period.start_date, date_period.end_date);
    return date_period;
  }
  getStartAndEndDateForMulti(start_month: any, start_fin_year: any, end_month: any, end_fin_year: any) {
    let date_period: any = { start_date: null, end_date: null };

    //start***********************************
    let start_year;
    if (start_month == '1' || start_month == '2' || start_month == '3') {
      start_year = parseInt(start_fin_year) + 1;
    } else {
      start_year = parseInt(start_fin_year);
    }
    this.getLeap(start_year);
    if (parseInt(start_month) > 9) {
      date_period.start_date = start_year + "-" + start_month + "-01"

    } else {
      date_period.start_date = start_year + "-0" + start_month + "-01"
    }


    //end******************************
    let end_year;
    if (end_month == '1' || end_month == '2' || end_month == '3') {
      end_year = parseInt(end_fin_year) + 1;
    } else {
      end_year = parseInt(end_fin_year);
    }
    this.getLeap(end_year);

    if (parseInt(end_month) > 9) {
      date_period.end_date = end_year + "-" + end_month + "-" + this.monthEnd[end_month]

    } else {
      date_period.end_date = end_year + "-0" + end_month + "-" + this.monthEnd[end_month]
    }
    console.log(date_period.start_date, date_period.end_date);
    return date_period;
  }
  async openarear() {


    for (var i = 0; i < this.encashment.length; i++) {
      if (this.selectEmpObj['encash_id'] == this.encashment[i]['id']) {
        this.selectEmpObj = Object.assign({}, this.encashment[i]);
      }
    }
    var da = this.selectEmpObj['da']
    var basic = this.selectEmpObj['basic']
    this.selectEmpObj['da'] = (da / basic) * 100
    //this.total_amount = this.selectEmpObj['amount'] + this.selectEmpObj['paid'];
    $('.nav-tabs a[href="#tab-4"]').tab('show');
    //await this.changePaidAmount();

  }

  group_concat(data: any, group_key: any, concatKey: any) {

    let result: any = {}

    for (let i = 0; i < data.length; i++) {
      let key: any = ""
      let temp: any = {}
      for (let j = 0; j < group_key.length; j++) {
        key += "_" + data[i][group_key[j]]
        temp[group_key[j]] = data[i][group_key[j]]
      }

      if (result[key] == undefined) {
        result[key] = temp
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] = data[i][concatKey[j]]
        }

      } else {
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] += data[i][concatKey[j]]
          // result[key][concatKey[j]] += "+" + data[i][concatKey[j]]
        }
      }


    }
    var ret_data = Object.values(result);
    // for (let i = 0; i < ret_data.length; i++) {
    //   ret_data[i]['rate'] = ret_data[i]['rate'].toFixed(0)
    // }
    return ret_data
  }
  data1: any = []
  data2: any = []
  data3: any = []
  arrear_total: any = 0;


  old_salary_bill_data: any = [];
  new_salary_bill_data: any = [];
  arr_id_final: any = []
  printdata: any = {}
  arr_of_all_dates: any = [];
  empArr: any = [];
  allPresentDaysObj: any = {};
  async getall() {
    this.spinner.show();
    var year;
    if (this.salaryObj.month == '1' || this.salaryObj.month == '2' || this.salaryObj.month == '3') {
      year = parseInt(this.salaryObj.fin_year) + 1;
    } else {
      year = parseInt(this.salaryObj.fin_year);
    }

    if (this.selectedendate == 'PERIOD') {
      console.log(this.salaryObj);
      var component: any = this.salaryObj['component'];
      var sal_obj: any = new Object();
      var emp_arr: any = this.ind_emp_id;
      sal_obj['ind_emp_id'] = this.ind_emp_id;
      sal_obj['b_acct_id'] = this.b_acct_id;
      let date_range = await this.getStartAndEndDate(this.salaryObj.month, this.salaryObj.fin_year)
      sal_obj['start_dt'] = date_range.start_date;
      sal_obj['end_dt'] = date_range.end_date;
      this.arr_of_all_dates = [];
      let emp_info_obj: any = {};
      emp_info_obj = this.salaryObj.emp_info;
      let emp_info_arr: any = [];
      emp_info_arr = Object.values(emp_info_obj)
      let arr_id_arr = [];
      for (let i = 0; i < emp_arr.length; i++) {
        for (let j = 0; j < emp_info_arr.length; j++) {
          if (emp_arr[i] == emp_info_arr[j]['arr_id']) {
            arr_id_arr.push(emp_info_arr[j]['emp_id'])
            break;
          }
        }
      }
      let obj12: any = new Object();
      obj12['b_acct_id'] = this.b_acct_id;
      obj12['emp_arr'] = arr_id_arr;
      obj12['year'] = year;
      obj12['month'] = this.salaryObj.month;

      var resp12 = await this.payableService.getPresentDays(obj12);
      if (resp12['error'] == false) {
        this.empArr = [];
        this.allPresentDaysObj = {};
        this.empArr = resp12.data;
      } else {

      }
      for (let i = 0; i < this.empArr.length; i++) {
        for (let j = 0; j < emp_info_arr.length; j++) {
          if (this.empArr[i]['emp_id'] == emp_info_arr[j]['emp_id']) {
            this.empArr[i]['arr_id'] = emp_info_arr[j]['arr_id'];
            break;
          }
        }
      }
      sal_obj['empArr'] = this.empArr;
      var resp = await this.payableService.getSalaryOfEmployees(sal_obj);
      if (resp['error'] == false) {
        console.log(resp.data);
        this.data1 = [];
        this.new_salary_bill_data = []
        this.new_salary_bill_data = resp.data;

        for (let i = 0; i < resp.data.length; i++)
          if (component == 'ALL') {
            resp.data[i]['start_date'] = sal_obj['start_dt'];
            resp.data[i]['end_date'] = sal_obj['end_dt'];
            this.data1.push(resp.data[i])
          } else {
            if (resp.data[i]['pay_comp'] == component) {
              resp.data[i]['start_date'] = sal_obj['start_dt'];
              resp.data[i]['end_date'] = sal_obj['end_dt'];
              this.data1.push(resp.data[i])
            }
          }
        // this.data1 = this.group_concat(this.data1, ['arr_id', 'pay_comp', 'pay_code'], ['rate']);
        console.log(this.data1)

        var resp1 = await this.payableService.getBillForArrear(sal_obj);
        if (resp1['error'] == false) {
          this.old_salary_bill_data = []
          this.old_salary_bill_data = resp1.data;

          console.log(resp1.data);
          this.data2 = [];

          for (let i = 0; i < resp1.data.length; i++)
            if (component == 'ALL') {
              resp1.data[i]['start_date'] = sal_obj['start_dt'];
              resp1.data[i]['end_date'] = sal_obj['end_dt'];
              this.data2.push(resp1.data[i])
            } else {
              if (resp1.data[i]['pay_component_code'] == component) {
                resp1.data[i]['start_date'] = sal_obj['start_dt'];
                resp1.data[i]['end_date'] = sal_obj['end_dt'];
                this.data2.push(resp1.data[i])
              }
            }


          for (let i = 0; i < this.data2.length; i++) {
            this.data2[i]['date'] = this.data2[i]['date'].split('T')[0]
          }
          this.data2 = this.group_concat(this.data2, ['arr_id', 'pay_component_code', 'pay_code', 'date'], ['pay_component_amt']);
          console.table(this.data2)




        }

        for (let i = 0; i < this.data1.length; i++) {
          for (let j = 0; j < this.data2.length; j++) {
            if (this.data1[i]['arr_id'] == this.data2[j]['arr_id']
              && this.data1[i]['pay_comp'] == this.data2[j]['pay_component_code']
              && this.data1[i]['date'] == this.data2[j]['date']) {
              this.data1[i]['diff'] = this.data1[i]['rate'] - this.data2[j]['pay_component_amt']
              this.data1[i]['old_given'] = this.data2[j]['pay_component_amt']
              this.data1[i]['start_date'] = sal_obj['start_dt'];
              this.data1[i]['end_date'] = sal_obj['end_dt'];
            }
          }
        }

        for (let i = 0; i < this.data1.length; i++) {
          if (this.data1[i]['diff'] == undefined) {
            this.data1[i]['diff'] = this.data1[i]['rate']
            this.data1[i]['old_given'] = 0;
            this.data1[i]['start_date'] = sal_obj['start_dt'];
            this.data1[i]['end_date'] = sal_obj['end_dt'];
          }
        }
        console.log(this.data1)
        this.data3 = []
        this.arrear_total = 0
        for (let i = 0; i < this.data1.length; i++) {

          if (this.data1[i]['diff'] != 0) {
            if (this.data1[i]['pay_code'] == 'PAY') {
              this.arrear_total += this.data1[i]['diff']
            } else {
              this.arrear_total -= this.data1[i]['diff']
            }
            this.data3.push(this.data1[i])
          }
        }
        this.arrear_total = parseFloat(this.arrear_total.toFixed(2))
        console.log(this.data3)
        var data4: any = this.group_concat(this.data3, ['arr_id', 'pay_comp', 'pay_code'], ['diff']);
        console.table(data4)
        for (let i = 0; i < data4.length; i++) {
          data4[i]['diff'] = parseFloat(data4[i]['diff'].toFixed(2))
        }
        data4 = data4.filter((x: any) => { return parseFloat(x['diff'].toFixed(2)) > 0 })




        let data5Obj: any = {}
        for (let ele of data4) {

          if (data5Obj[ele['arr_id']] == undefined) {
            if (ele['pay_code'] == 'PAY') {
              data5Obj[ele['arr_id']] = ele['diff']
            } else {
              data5Obj[ele['arr_id']] = ele['diff'] * (-1)
            }

          } else {
            if (ele['pay_code'] == 'PAY') {
              data5Obj[ele['arr_id']] = data5Obj[ele['arr_id']] + ele['diff']
            } else {
              data5Obj[ele['arr_id']] = data5Obj[ele['arr_id']] - ele['diff']
            }
          }

        }

        console.log(data5Obj);
        let data6 = [];
        for (let key of Object.keys(data5Obj)) {
          data6.push({ arr_id: key, total: data5Obj[key] })
        }
        console.log(this.arr_id_final)
        this.printdata = {}
        var arrpri = []
        for (let i = 0; i < data6.length; i++) {
          if (this.printdata == undefined) {
            this.printdata = {}
            this.printdata[data6[i]['arr_id']] = {}
          }
          console.log(data6[i])
          this.printdata[data6[i]['arr_id']] = await this.calculatefun(data6[i])
          arrpri.push(this.printdata[data6[i]['arr_id']])
          console.log(this.printdata[data6[i]['arr_id']])

        }
        console.log(arrpri)
        console.log(this.printdata)

        this.datasource = new MatTableDataSource(data6)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;



      }
    }
    else {
      console.log(this.salaryObj);
      var component: any = this.salaryObj['component']
      var sal_obj: any = new Object();
      sal_obj['ind_emp_id'] = this.ind_emp_id;
      sal_obj['b_acct_id'] = this.b_acct_id;
      if (this.getStartAndEndDate(this.salaryObj['start_month'], this.salaryObj['start_fin_year'])['start_date'] >
        this.getStartAndEndDate(this.salaryObj['end_month'], this.salaryObj['end_fin_year'])['end_date']) {
        swal.fire('Error', 'Error in Period Selection....', 'error');
        this.spinner.hide()
        return
      }
      let date_range = await this.getStartAndEndDateForMulti(this.salaryObj['start_month'], this.salaryObj['start_fin_year'], this.salaryObj['end_month'], this.salaryObj['end_fin_year'])
      sal_obj['start_dt'] = date_range.start_date;
      sal_obj['end_dt'] = date_range.end_date;
      var resp = await this.payableService.getSalaryOfEmployees(sal_obj);
      if (resp['error'] == false) {
        console.log(resp.data);
        this.data1 = [];
        this.new_salary_bill_data = [];
        this.new_salary_bill_data = resp.data;

        for (let i = 0; i < resp.data.length; i++)

          if (component == 'ALL') {
            resp.data[i]['start_date'] = sal_obj['start_dt'];
            resp.data[i]['end_date'] = sal_obj['end_dt'];
            this.data1.push(resp.data[i])
          } else {
            if (resp.data[i]['pay_comp'] == component) {
              resp.data[i]['start_date'] = sal_obj['start_dt'];
              resp.data[i]['end_date'] = sal_obj['end_dt'];
              this.data1.push(resp.data[i])
            }
          }

        console.log(this.data1)
        // this.data1 = this.group_concat(this.data1, ['arr_id', 'pay_comp', 'pay_code'], ['rate']);

        var resp1 = await this.payableService.getBillForArrear(sal_obj);
        if (resp1['error'] == false) {

          console.log(resp1.data);
          this.data2 = [];
          this.old_salary_bill_data = [];
          this.old_salary_bill_data = resp1.data;

          for (let i = 0; i < resp1.data.length; i++) {
            if (component == 'ALL') {
              resp1.data[i]['start_date'] = sal_obj['start_dt'];
              resp1.data[i]['end_date'] = sal_obj['end_dt'];
              this.data2.push(resp1.data[i])
            } else {
              if (resp1.data[i]['pay_component_code'] == component) {
                resp1.data[i]['start_date'] = sal_obj['start_dt'];
                resp1.data[i]['end_date'] = sal_obj['end_dt'];
                this.data2.push(resp1.data[i])
              }
            }
          }
          for (let i = 0; i < this.data2.length; i++) {
            this.data2[i]['date'] = this.data2[i]['date'].split('T')[0]
          }
          console.log(this.data2)
          this.data2 = this.group_concat(this.data2, ['arr_id', 'pay_component_code', 'pay_code', 'date'], ['pay_component_amt']);
          console.log(this.data2)
          console.log(this.data2)

          for (let i = 0; i < this.data1.length; i++) {
            for (let j = 0; j < this.data2.length; j++) {
              if (this.data1[i]['arr_id'] == this.data2[j]['arr_id']
                && this.data1[i]['pay_comp'] == this.data2[j]['pay_component_code']
                && this.data1[i]['date'] == this.data2[j]['date']) {
                this.data1[i]['diff'] = this.data1[i]['rate'] - this.data2[j]['pay_component_amt']
                this.data1[i]['old_given'] = this.data2[j]['pay_component_amt']
                this.data1[i]['start_date'] = sal_obj['start_dt'];
                this.data1[i]['end_date'] = sal_obj['end_dt'];

              }
            }
          }
          for (let i = 0; i < this.data1.length; i++) {
            if (this.data1[i]['diff'] == undefined) {
              this.data1[i]['diff'] = this.data1[i]['rate']
              this.data1[i]['old_given'] = 0;
              this.data1[i]['start_date'] = sal_obj['start_dt'];
              this.data1[i]['end_date'] = sal_obj['end_dt'];
            }
          }
          console.log(this.data1)
          console.log(this.data1)
          this.data3 = []
          this.arrear_total = 0
          for (let i = 0; i < this.data1.length; i++) {

            if (this.data1[i]['diff'] != 0) {
              if (this.data1[i]['pay_code'] == 'PAY') {
                this.arrear_total += this.data1[i]['diff']
              } else {
                this.arrear_total -= this.data1[i]['diff']
              }
              this.data3.push(this.data1[i])
            }
          }
          this.arrear_total = parseFloat(this.arrear_total.toFixed(2))

          console.table(this.data3)

          var data4: any = this.group_concat(this.data3, ['arr_id', 'pay_comp', 'pay_code'], ['diff']);
          for (let i = 0; i < data4.length; i++) {
            data4[i]['diff'] = parseFloat(data4[i]['diff'].toFixed(2))
          }
          console.table(data4)
          data4 = data4.filter((x: any) => { return parseFloat(x['diff'].toFixed(2)) > 0 })
          console.log(data4)
          //////////////////////princy/////////////////
          console.log(this.data3)
          var topay: any = this.group_concat(this.data3, ['arr_id', 'pay_comp', 'pay_code', 'month', 'fin_year'], ['diff']);
          for (let i = 0; i < topay.length; i++) {
            topay[i]['diff'] = parseFloat(topay[i]['diff'].toFixed(2))
          }
          console.table(topay)
          topay = topay.filter((x: any) => { return parseFloat(x['diff'].toFixed(2)) > 0 })
          console.log(topay)
          let data5Obj: any = {}
          for (let ele of data4) {

            if (data5Obj[ele['arr_id']] == undefined) {
              if (ele['pay_code'] == 'PAY') {
                data5Obj[ele['arr_id']] = ele['diff']
              } else {
                data5Obj[ele['arr_id']] = ele['diff'] * (-1)
              }

            } else {
              if (ele['pay_code'] == 'PAY') {
                data5Obj[ele['arr_id']] = data5Obj[ele['arr_id']] + ele['diff']
              } else {
                data5Obj[ele['arr_id']] = data5Obj[ele['arr_id']] - ele['diff']
              }
            }

          }

          console.log(data5Obj);
          let data6 = [];
          this.arr_id_final = [];
          for (let key of Object.keys(data5Obj)) {
            data6.push({ arr_id: key, total: data5Obj[key] })
            this.arr_id_final.push(key);
          }
          console.log(this.arr_id_final)
          this.printdata = {}
          var arrpri = []
          for (let i = 0; i < data6.length; i++) {
            if (this.printdata == undefined) {
              this.printdata = {}
              this.printdata[data6[i]['arr_id']] = {}
            }
            console.log(data6[i])
            this.printdata[data6[i]['arr_id']] = await this.calculatefun(data6[i])
            arrpri.push(this.printdata[data6[i]['arr_id']])
            console.log(this.printdata[data6[i]['arr_id']])

          }
          console.log(arrpri)
          console.log(this.printdata)
          this.datasource = new MatTableDataSource(data6)
          this.datasource.paginator = this.paginator;
          this.datasource.sort = this.sort;
        }
      }
    }

    this.salaryArr = [];
    this.salaryArr = this.data3;
    console.log(this.salaryArr)
    for (var i = 0; i < this.salaryArr.length; i++) {
      this.salaryArr[i]['b_acct_id'] = this.b_acct_id;
      this.salaryArr[i]['node_cd'] = this.Obj['node_cd']
      this.salaryArr[i]['emp_id'] = this.salaryObj.emp_info[this.salaryArr[i].arr_id].emp_id;
      this.salaryArr[i]['month'] = new Date(this.salaryArr[i]['date']).getMonth() + 1;
      let year_ = new Date(this.salaryArr[i]['date']).getFullYear();
      if (this.salaryArr[i]['month'] < 4) {
        year_ = year_ - 1;
      }
      this.salaryArr[i]['fin_year'] = year_;
      if (this.selectedendate == 'PERIOD') {
        this.salaryArr[i]['bill_desc'] = "Arrear  Bill  for  " + this.monthObjShort[this.salaryObj.month] + ", " + this.salaryObj.fin_year;
      } else {
        this.salaryArr[i]['bill_desc'] = "Arrear  Bill  from  " + this.salaryArr[i]['start_date'] + " to " + this.salaryArr[i]['end_date']
      }
      this.salaryArr[i]['pay_component_code'] = this.salaryArr[i].pay_comp;
      this.salaryArr[i]['pay_code'] = this.salaryArr[i].pay_code;
      this.salaryArr[i]['bill_status_code'] = "GENERATED";
      this.salaryArr[i]['bill_type'] = "AA_BILL";
      this.salaryArr[i]['create_user_id'] = this.erpUser.user_id;
      this.salaryArr[i]['emp_name'] = this.salaryObj.emp_info[this.salaryArr[i].arr_id].emp_name;
      this.salaryArr[i]['emp_phone_no'] = this.salaryObj.emp_info[this.salaryArr[i].arr_id].emp_phone_no;
      this.salaryArr[i]['pay_component_amt'] = this.salaryArr[i]['diff']
    }
    this.spinner.hide();

  }
  tt1: any = {}
  ReverseObject(Obj: any) {
    console.log(Obj)
    var TempArr: any = [];
    var NewObj: any = [];
    for (var Key in Obj) {
      TempArr.push(Key);
    }

    console.log(Obj)

    var k = 0;
    for (let i = 0; i < TempArr.length; i++) {
      NewObj[TempArr[i] - 1] = Obj[TempArr[i]];
      k++;
    }
    NewObj[k - 1] = Obj[TempArr[0]];
    delete NewObj[-1]

    console.log(NewObj)
    return NewObj;
  }

  col_list:any = [];
  async openview(element: any) {
    await this.calculate(element);
    $('#Z').modal('show');
  }
  async calculate(element :any) {
    this.billObjData = {};
    this.billObjAAData = {};
    this.arr_id_of_emp = element['arr_id']
    let arrear_months = []
    if (this.selectedendate == 'PERIOD') {
      console.log(this.salaryObj['month']);
      console.log(this.salaryObj['fin_year']);
      arrear_months.push({ month: this.salaryObj['month'], year: this.salaryObj['fin_year'] })
    } else {
      console.log(this.salaryObj['start_month']);
      console.log(this.salaryObj['start_fin_year']);
      console.log(this.salaryObj['end_month']);
      console.log(this.salaryObj['end_fin_year']);
      let date_range = await this.getStartAndEndDateForMulti(this.salaryObj['start_month'], this.salaryObj['start_fin_year'], this.salaryObj['end_month'], this.salaryObj['end_fin_year'])
      var startDate = moment(date_range['start_date'])
      var endDate = moment(date_range['end_date'])
      while (startDate.isBefore(endDate)) {
        arrear_months.push({ month: startDate.format("MM"), year: startDate.format("YYYY") });
        startDate.add(1, 'month');
      }
    }

    console.log(arrear_months);
    this.arrear_months = [...arrear_months];

    console.log(this.new_salary_bill_data);
    console.log(this.old_salary_bill_data);
    console.log(element)
    this.new_salary_bill_data = this.new_salary_bill_data.map((element:any) => {
      element['month'] = new Date(element['date']).getMonth() + 1;
      let year_ = new Date(element['date']).getFullYear();
      if (element['month'] < 4) {
        year_ = year_ - 1;
      }
      element['fin_year'] = year_;
      return element;
    });
    this.new_salary_bill_data = this.new_salary_bill_data.map((element:any) => {
      element['bill_type'] = 'AA_BILL';
      element['pay_component_code'] = element['pay_comp']
      element['pay_component_amt'] = element['rate']
      return element;
    })
    console.log(this.new_salary_bill_data)
    let new_data = this.group_concat(this.new_salary_bill_data, ['arr_id', 'pay_component_code', 'pay_code', 'month', 'fin_year', 'bill_type'], ['pay_component_amt']).filter((ele:any )=> { return ele['arr_id'] == element['arr_id'] })
    let old_data = this.group_concat(this.old_salary_bill_data, ['arr_id', 'pay_component_code', 'pay_code', 'month', 'fin_year', 'bill_type', 'bill_id'], ['pay_component_amt']).sort((a:any, b:any) => { return a['bill_id'] > b['bill_id'] ? 1 : a['bill_id'] < b['bill_id'] ? -1 : 0 }).filter((ele:any) => { return ele['arr_id'] == element['arr_id'] })


    let obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp1 = await this.payableService.getAllComponent(JSON.stringify(obj));
    if (resp1['error'] == false) {
      this.all_component = resp1.data;
    } else {
      return;
    }



    let obb :any= new Object();
    obb['arr_id'] = '';
    this.col_list = ['Employee Details']

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'PAY') {
        obb[this.all_component[i]['component_code']] = 0
        this.col_list.push(this.all_component[i]['component_code'])
      }
    }

    obb['gross pay'] = 0;
    this.col_list.push('gross pay')

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'DED') {
        obb[this.all_component[i]['component_code']] = 0
        this.col_list.push(this.all_component[i]['component_code'])

      }
    }
    obb['total ded'] = 0
    this.col_list.push('total ded')

    obb['net sal'] = 0;
    this.col_list.push('net sal')

    console.log(obb);
    console.log(this.col_list);


    console.log(this.all_component);



    let dt:any = old_data;

    let billObj:any = {};
    for (let i = 0; i < dt.length; i++) {

      if (billObj[dt[i]['month'] + "-" + dt[i]['fin_year']] == undefined) {
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']] = {};
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'] = {}
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']] = Object.assign({}, obb)
        console.log(element.arr_id)
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['arr_id'] = parseInt(element.arr_id)
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['Employee Details'] = 'TOTAL Paid'
      }
      if (billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']] == undefined) {
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']] = {};
      }
      if (billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']] == undefined) {
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']] = Object.assign({}, obb);
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['arr_id'] = (dt[i]['arr_id']);
        console.log(this.salaryObj.employement_info[dt[i]['arr_id']]['Employee Details'] + "-" + dt[i]['bill_type'])
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['Employee Details'] = this.salaryObj.employement_info[dt[i]['arr_id']]['emp_name'] + "-" + dt[i]['bill_type']

      }

      if (dt[i]['pay_code'] == 'PAY') {
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['gross pay'] += dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['net sal'] += dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];



        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['net sal'] += dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['gross pay'] += dt[i]['pay_component_amt']
      } else {
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['total ded'] += dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['net sal'] -= dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']


        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['total ded'] += dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['net sal'] -= dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
      }
    }





    console.log(billObj)
    let dt1 :any= new_data;
    let billObjAA :any= {};
    for (let i = 0; i < dt1.length; i++) {

      if (billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']] == undefined) {
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']] = {};
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'] = {}
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']] = Object.assign({}, obb)
        console.log(element.arr_id)
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['arr_id'] = parseInt(element.arr_id)
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['Employee Details'] = 'Currently TOTAL Salary'

      }
      if (billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']] == undefined) {
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']] = {};
      }
      if (billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']] == undefined) {
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']] = Object.assign({}, obb);
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['arr_id'] = (dt1[i]['arr_id']);
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['Employee Details'] = this.salaryObj.employement_info[dt1[i]['arr_id']]['emp_name'] + "-" + dt1[i]['bill_type']

      }

      if (dt1[i]['pay_code'] == 'PAY') {
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['gross pay'] += dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['net sal'] += dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']][dt1[i]['pay_component_code']] += dt1[i]['pay_component_amt']

        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['net sal'] += dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']][dt1[i]['pay_component_code']] += dt1[i]['pay_component_amt']
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['gross pay'] += dt1[i]['pay_component_amt']
      } else {
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['total ded'] += dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['net sal'] -= dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']][dt1[i]['pay_component_code']] += dt1[i]['pay_component_amt']

        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['total ded'] += dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['net sal'] -= dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']][dt1[i]['pay_component_code']] += dt1[i]['pay_component_amt']
      }
    }




    ///***************************** */

    console.log(billObj);

    for (let i = 0; i < this.arrear_months.length; i++) {
      let tt = Object.values(billObj[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']]).map((ele:any) => {

        if (ele[element.arr_id] != undefined) {
          for (let j = 0; j < this.all_component.length; j++) {
            ele[element.arr_id][this.all_component[j]['component_code']] = ele[element.arr_id][this.all_component[j]['component_code']].toFixed(2)
          }
          console.log(ele[element.arr_id])
          return ele[element.arr_id]

        } else {
          for (let j = 0; j < this.all_component.length; j++) {
            ele['-1'][this.all_component[j]['component_code']] = ele['-1'][this.all_component[j]['component_code']].toFixed(2)
          }
          console.log(ele['-1'])
          return ele['-1']
        }
      })

      console.log(tt)
      var tt2 = await this.ReverseObject(tt);
      for (let k = 0; k < tt2.length; k++)
        for (let i = 0; i < this.col_list.length; i++) {
          if (this.col_list[i] == "gross pay" || this.col_list[i] == "total ded" || this.col_list[i] == "net sal") {
            tt2[k][this.col_list[i]] = tt2[k][this.col_list[i]].toFixed(2)
          }

        }



      this.billObjData[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']] = tt2
      let tt1 = Object.values(billObjAA[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']]).map((ele:any) => {

        if (ele[element.arr_id] != undefined) {
          for (let j = 0; j < this.all_component.length; j++) {
            ele[element.arr_id][this.all_component[j]['component_code']] = ele[element.arr_id][this.all_component[j]['component_code']].toFixed(2)
          }
          return ele[element.arr_id]
        } else {
          for (let j = 0; j < this.all_component.length; j++) {
            ele['-1'][this.all_component[j]['component_code']] = ele['-1'][this.all_component[j]['component_code']].toFixed(2)
          }
          return ele['-1']
        }
      })
      tt1 = await this.ReverseObject(tt1);
      for (let k = 0; k < tt1.length; k++)
        for (let i = 0; i < this.col_list.length; i++) {
          if (this.col_list[i] == "gross pay" || this.col_list[i] == "total ded" || this.col_list[i] == "net sal") {
            tt1[k][this.col_list[i]] = tt1[k][this.col_list[i]].toFixed(2)
          }

        }

      let k = tt1.length
      for (let i = 0; i < this.col_list.length; i++) {

        if (tt1[k] == undefined) {
          tt1[k] = {};
          //    tt1[k][this.col_list[i]]={}
        }

        if (this.col_list[i] != "Employee Details") {


          tt1[k][this.col_list[i]] = (tt1[0][this.col_list[i]] - tt[0][this.col_list[i]]).toFixed(2)
        }
        else
          tt1[k][this.col_list[i]] = "To Pay"

      }

      console.log(tt1)
      this.billObjAAData[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']] = tt1
      console.log(this.billObjAAData)
    }


    // for (let i = 0; i < this.arrear_months.length; i++) {
    //   let tt1 = Object.values(billObjAA[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']]).map(ele => {

    //     if (ele[element.arr_id] != undefined) {
    //       for(let j=0;j<this.all_component.length;j++){
    //         ele[element.arr_id][this.all_component[j]['component_code']]=ele[element.arr_id][this.all_component[j]['component_code']].toFixed(2)
    //       }
    //       return ele[element.arr_id]
    //     } else {
    //       for(let j=0;j<this.all_component.length;j++){
    //         ele['-1'][this.all_component[j]['component_code']]=ele['-1'][this.all_component[j]['component_code']].toFixed(2)
    //       }
    //       return ele['-1']
    //     }
    //   })
    //   console.log(tt1)

    //   this.billObjAAData[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']] = tt1
    //   console.log(this.billObjAAData)
    // }




  }
  async calculatefun(element :any) {
    console.log(element)
    this.billObjData = {};
    this.billObjAAData = {};
    this.arr_id_of_emp = element['arr_id']
    let arrear_months = []
    if (this.selectedendate == 'PERIOD') {
      console.log(this.salaryObj['month']);
      console.log(this.salaryObj['fin_year']);
      arrear_months.push({ month: this.salaryObj['month'], year: this.salaryObj['fin_year'] })
    } else {
      console.log(this.salaryObj['start_month']);
      console.log(this.salaryObj['start_fin_year']);
      console.log(this.salaryObj['end_month']);
      console.log(this.salaryObj['end_fin_year']);
      let date_range = await this.getStartAndEndDateForMulti(this.salaryObj['start_month'], this.salaryObj['start_fin_year'], this.salaryObj['end_month'], this.salaryObj['end_fin_year'])
      var startDate = moment(date_range['start_date'])
      var endDate = moment(date_range['end_date'])
      while (startDate.isBefore(endDate)) {
        arrear_months.push({ month: startDate.format("MM"), year: startDate.format("YYYY") });
        startDate.add(1, 'month');
      }
    }

    console.log(arrear_months);
    this.arrear_months = [...arrear_months];

    console.log(this.new_salary_bill_data);
    console.log(this.old_salary_bill_data);
    console.log(element)
    this.new_salary_bill_data = this.new_salary_bill_data.map((element:any) => {
      element['month'] = new Date(element['date']).getMonth() + 1;
      let year_ = new Date(element['date']).getFullYear();
      if (element['month'] < 4) {
        year_ = year_ - 1;
      }
      element['fin_year'] = year_;
      return element;
    });
    this.new_salary_bill_data = this.new_salary_bill_data.map((element:any) => {
      element['bill_type'] = 'AA_BILL';
      element['pay_component_code'] = element['pay_comp']
      element['pay_component_amt'] = element['rate']
      return element;
    })
    console.log(this.new_salary_bill_data)
    let new_data = this.group_concat(this.new_salary_bill_data, ['arr_id', 'pay_component_code', 'pay_code', 'month', 'fin_year', 'bill_type'], ['pay_component_amt']).filter( (ele:any) => { return ele['arr_id'] == element['arr_id'] })
    let old_data = this.group_concat(this.old_salary_bill_data, ['arr_id', 'pay_component_code', 'pay_code', 'month', 'fin_year', 'bill_type', 'bill_id'], ['pay_component_amt']).sort((a:any, b:any) => { return a['bill_id'] > b['bill_id'] ? 1 : a['bill_id'] < b['bill_id'] ? -1 : 0 }).filter((ele:any) => { return ele['arr_id'] == element['arr_id'] })


    let obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp1 = await this.payableService.getAllComponent(JSON.stringify(obj));
    if (resp1['error'] == false) {
      this.all_component = resp1.data;
    } else {
      return;
    }



    let obb :any= new Object();
    obb['arr_id'] = '';
    this.col_list = ['Employee Details']

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'PAY') {
        obb[this.all_component[i]['component_code']] = 0
        this.col_list.push(this.all_component[i]['component_code'])
      }
    }

    obb['gross pay'] = 0;
    this.col_list.push('gross pay')

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'DED') {
        obb[this.all_component[i]['component_code']] = 0
        this.col_list.push(this.all_component[i]['component_code'])

      }
    }
    obb['total ded'] = 0
    this.col_list.push('total ded')

    obb['net sal'] = 0;
    this.col_list.push('net sal')

    console.log(obb);
    console.log(this.col_list);


    console.log(this.all_component);



    let dt:any = old_data;

    let billObj :any= {};
    for (let i = 0; i < dt.length; i++) {

      if (billObj[dt[i]['month'] + "-" + dt[i]['fin_year']] == undefined) {
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']] = {};
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'] = {}
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']] = Object.assign({}, obb)
        console.log(element.arr_id)
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['arr_id'] = parseInt(element.arr_id)
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['Employee Details'] = 'TOTAL Paid'
      }
      if (billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']] == undefined) {
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']] = {};
      }
      if (billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']] == undefined) {
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']] = Object.assign({}, obb);
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['arr_id'] = (dt[i]['arr_id']);
        //console.log(this.salaryObj.employement_info[dt[i]['arr_id']]['Employee Details']+"-"+dt[i]['bill_type'])
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['Employee Details'] = this.salaryObj.employement_info[dt[i]['arr_id']]['emp_name'] + "-" + dt[i]['bill_type']

      }

      if (dt[i]['pay_code'] == 'PAY') {
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['gross pay'] += dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['net sal'] += dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];



        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['net sal'] += dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['gross pay'] += dt[i]['pay_component_amt']
      } else {
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['total ded'] += dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']]['net sal'] -= dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']][dt[i]['bill_type']][dt[i]['arr_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']


        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['total ded'] += dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']]['net sal'] -= dt[i]['pay_component_amt'];
        billObj[dt[i]['month'] + "-" + dt[i]['fin_year']]['TOTAL'][dt[i]['arr_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
      }
    }





    console.log(billObj)
    let dt1:any = new_data;
    let billObjAA:any = {};
    for (let i = 0; i < dt1.length; i++) {

      if (billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']] == undefined) {
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']] = {};
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'] = {}
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']] = Object.assign({}, obb)
        console.log(element.arr_id)
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['arr_id'] = parseInt(element.arr_id)
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['Employee Details'] = 'Currently TOTAL Salary'

      }
      if (billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']] == undefined) {
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']] = {};
      }
      if (billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']] == undefined) {
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']] = Object.assign({}, obb);
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['arr_id'] = (dt1[i]['arr_id']);
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['Employee Details'] = this.salaryObj.employement_info[dt1[i]['arr_id']]['emp_name'] + "-" + dt1[i]['bill_type']

      }

      if (dt1[i]['pay_code'] == 'PAY') {
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['gross pay'] += dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['net sal'] += dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']][dt1[i]['pay_component_code']] += dt1[i]['pay_component_amt']

        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['net sal'] += dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']][dt1[i]['pay_component_code']] += dt1[i]['pay_component_amt']
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['gross pay'] += dt1[i]['pay_component_amt']
      } else {
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['total ded'] += dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']]['net sal'] -= dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']][dt1[i]['bill_type']][dt1[i]['arr_id']][dt1[i]['pay_component_code']] += dt1[i]['pay_component_amt']

        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['total ded'] += dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']]['net sal'] -= dt1[i]['pay_component_amt'];
        billObjAA[dt1[i]['month'] + "-" + dt1[i]['fin_year']]['TOTAL'][dt1[i]['arr_id']][dt1[i]['pay_component_code']] += dt1[i]['pay_component_amt']
      }
    }




    ///***************************** */

    console.log(billObj);

    for (let i = 0; i < this.arrear_months.length; i++) {
      let tt = Object.values(billObj[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']]).map((ele:any )=> {

        if (ele[element.arr_id] != undefined) {
          for (let j = 0; j < this.all_component.length; j++) {
            ele[element.arr_id][this.all_component[j]['component_code']] = ele[element.arr_id][this.all_component[j]['component_code']].toFixed(2)
          }
          console.log(ele[element.arr_id])
          return ele[element.arr_id]

        } else {
          for (let j = 0; j < this.all_component.length; j++) {
            ele['-1'][this.all_component[j]['component_code']] = ele['-1'][this.all_component[j]['component_code']].toFixed(2)
          }
          console.log(ele['-1'])
          return ele['-1']
        }
      })

      console.log(tt)
      var tt2 = await this.ReverseObject(tt);
      for (let k = 0; k < tt2.length; k++)
        for (let i = 0; i < this.col_list.length; i++) {
          if (this.col_list[i] == "gross pay" || this.col_list[i] == "total ded" || this.col_list[i] == "net sal") {
            tt2[k][this.col_list[i]] = tt2[k][this.col_list[i]].toFixed(2)
          }

        }



      this.billObjData[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']] = tt2
      let tt1 = Object.values(billObjAA[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']]).map((ele:any) => {

        if (ele[element.arr_id] != undefined) {
          for (let j = 0; j < this.all_component.length; j++) {
            ele[element.arr_id][this.all_component[j]['component_code']] = ele[element.arr_id][this.all_component[j]['component_code']].toFixed(2)
          }
          return ele[element.arr_id]
        } else {
          for (let j = 0; j < this.all_component.length; j++) {
            ele['-1'][this.all_component[j]['component_code']] = ele['-1'][this.all_component[j]['component_code']].toFixed(2)
          }
          return ele['-1']
        }
      })
      tt1 = await this.ReverseObject(tt1);
      for (let k = 0; k < tt1.length; k++)
        for (let i = 0; i < this.col_list.length; i++) {
          if (this.col_list[i] == "gross pay" || this.col_list[i] == "total ded" || this.col_list[i] == "net sal") {
            tt1[k][this.col_list[i]] = tt1[k][this.col_list[i]].toFixed(2)
          }

        }

      let k = tt1.length
      for (let i = 0; i < this.col_list.length; i++) {

        if (tt1[k] == undefined) {
          tt1[k] = {};
          //    tt1[k][this.col_list[i]]={}
        }

        if (this.col_list[i] != "Employee Details") {


          tt1[k][this.col_list[i]] = (tt1[0][this.col_list[i]] - tt[0][this.col_list[i]]).toFixed(2)
        }
        else
          tt1[k][this.col_list[i]] = "To Pay"

      }

      console.log(tt1)
      this.billObjAAData[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']] = tt1
      console.log(this.billObjAAData)
    }

    var obj1:any = {}
    if (obj1['curr'] == undefined) {

      obj1['curr'] = {}
      obj1['prev'] = {}
    }
    obj1['prev'] = this.billObjData
    obj1['curr'] = this.billObjAAData

    console.log(obj1)


    return obj1
    ///***************************** */
    //  console.log(billObjAA);
    // for (let i = 0; i < this.arrear_months.length; i++) {
    //   let tt1 = Object.values(billObjAA[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']]).map(ele => {

    //     if (ele[element.arr_id] != undefined) {
    //       for(let j=0;j<this.all_component.length;j++){
    //         ele[element.arr_id][this.all_component[j]['component_code']]=ele[element.arr_id][this.all_component[j]['component_code']].toFixed(2)
    //       }
    //       return ele[element.arr_id]
    //     } else {
    //       for(let j=0;j<this.all_component.length;j++){
    //         ele['-1'][this.all_component[j]['component_code']]=ele['-1'][this.all_component[j]['component_code']].toFixed(2)
    //       }
    //       return ele['-1']
    //     }
    //   })
    //   console.log(tt1)

    //   this.billObjAAData[this.arrear_months[i]['month'] + "-" + this.arrear_months[i]['year']] = tt1
    //   console.log(this.billObjAAData)
    // }




  }





  arr_id_of_emp :any= -1;
  billObjData:any = {}
  billObjAAData:any = {}
  arrear_months:any
  refres() {
    this.salaryObj = { component: 'ALL', type: 'ind', accrual_date: this.dateToday, b_acct_id: '', fin_year: '', month: '', section_code: '', emp_cat_code: '', post_info: {}, emp_info: {}, employement_info: {}, bank_info: {}, att_info: {}, fixed_pay_info: {}, variable_pay_info: {}, total_pay: {} }
    this.datasource = new MatTableDataSource([])
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }


  async generateArrear() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(this.salaryArr)
    for (let i = 0; i < this.salaryArr.length; i++) {
      this.salaryArr[i]['accrual_date'] = this.salaryObj['accrual_date'];
      this.salaryArr[i]['bill_date'] = this.salaryObj['accrual_date'];
      this.salaryArr[i]['bill_amount'] = this.arrear_total;
      this.salaryArr[i]['pay_component_amt'] = this.salaryArr[i]['diff']
    }
    this.salaryArr = this.salaryArr.filter((x :any)=> { return parseFloat(x['pay_component_amt'].toFixed(2)) > 0 })
    this.salaryArr = this.salaryArr.filter((x :any)=> { return parseFloat(x['diff'].toFixed(2)) > 0 })
    obj['data'] = this.salaryArr;
    obj['printdata'] = JSON.stringify(this.printdata)
    console.log(obj)
    this.spinner.show();
    var resp = await this.payableService.generateArrear(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.refres();
      swal.fire('Success', 'Arrear Generated Successfully', 'success');
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error in Arrear Generation', 'error');
    }

  }

  //**************************************************************************** */

  async arear() {
    this.selectEmpObj['b_acct_id'] = this.b_acct_id;
    this.selectEmpObj['update_user_id'] = this.erpUser.user_id;
    // this.selectEmpObj['da'] = this.selectEmpObj['new_da']
    this.selectEmpObj['arrear_type'] = 'DA ARREAR'

    this.selectEmpObj['create_user_id'] = this.erpUser.user_id;
    this.selectEmpObj['status'] = 'GENRATED'
    this.selectEmpObj['dadata'] = this.dadata
    this.spinner.show();

    var resp = await this.payableService.insertarrayarrear(this.selectEmpObj);
    if (resp['error'] == false) {
      //await this.getAllLeaveEncashment();
      await this.getarrear();
      this.spinner.hide();
      swal.fire('Success', 'Added Successfully! ', 'success')
      // this.snackBar.open("Added Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      swal.fire('Error', ' Error while Adding !!', 'error')
      // this.snackBar.open("Error while Adding !!", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async openUpdate(element :any) {
    $('.nav-tabs a[href="#tab-3"]').tab('show');


    this.selectEmpObj = Object.assign({}, element);
    var data = JSON.parse(this.selectEmpObj['data'])[0]
    this.selectEmpObj['arrear_end_dt'] = data['arrear_end_dt']
    this.selectEmpObj['arrear_start_dt'] = data['arrear_start_dt']
    this.selectEmpObj['basic'] = data['basic']
    this.selectEmpObj['da'] = data['da']
    this.selectEmpObj['new_da'] = data['new_da']



  }
  changedaupdate() {
    var month = 0
    if (this.selectEmpObj['arrear_start_dt'] != undefined && this.selectEmpObj['arrear_end_dt'] != undefined) {
      var arrfrom = this.selectEmpObj['arrear_start_dt'].split('-')
      var arrto = this.selectEmpObj['arrear_end_dt'].split('-');
      arrto[0] = parseInt(arrto[0])
      arrto[1] = parseInt(arrto[1])
      arrfrom[0] = parseInt(arrfrom[0])
      arrfrom[1] = parseInt(arrfrom[1])
      if (arrto[0] == arrfrom[0]) {
        month = arrto[1] - arrfrom[1] + 1
      } else if (arrto[0] == arrfrom[0] + 1) {
        month = 12 - arrfrom[1] + 1 + arrto[1];

      } else {
        month = 12 - arrfrom[1] + 1 + arrto[1] + (arrto[0] - arrfrom[0] - 1) * 12;

      }

    }
    var da = this.selectEmpObj['new_da'] - this.selectEmpObj['da']
    var basic = this.selectEmpObj['basic']
    this.selectEmpObj['amount'] = ((da / 100) * basic * month).toFixed(2);


  }


  async update() {
    this.selectEmpObj['b_acct_id'] = this.b_acct_id;
    var data = []
    var obj = Object()
    obj['new_da'] = this.selectEmpObj['new_da']
    obj['da'] = this.selectEmpObj['da']
    obj['basic'] = this.selectEmpObj['basic']
    obj['arrear_start_dt'] = this.selectEmpObj['arrear_start_dt']
    obj['arrear_end_dt'] = this.selectEmpObj['arrear_end_dt']
    obj['encash_id'] = this.selectEmpObj['encash_id']
    data.push(obj)
    this.selectEmpObj['data'] = JSON.stringify(data)
    this.selectEmpObj['update_user_id'] = this.erpUser.user_id;
    this.spinner.show();
    var resp = await this.payableService.updatearrear(this.selectEmpObj);
    if (resp['error'] == false) {

      this.spinner.hide();
      await this.getarrear();
      swal.fire('Success', 'Updated Successfully! ', 'success')
      // this.snackBar.open("Updated Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      swal.fire('Error', ' Error while Updating', 'error')
      // this.snackBar.open("Error while Updating", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async delete(element :any) {

    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = [element['id']];
    var resp = await this.payableService.deletearrear(JSON.stringify(obj));
    if (resp['error'] == false) {
      await this.getarrear();
      this.spinner.hide();
      swal.fire('Success', 'Deleted Successfully ', 'success')
      // this.snackBar.open("Deleted Successfully", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while deleting Leave Encashment! ', 'error')
      // this.snackBar.open("Error while deleting Leave Encashment!!", 'Error', {
      //   duration: 5000
      // });
    }
  }


  ////******************************node waise salary*********************************** */

  planing_arrproj :any= []
  projecthier :any= []
  projhierobj:any = {}
  projhierobjtype :any= {}
  projhierobjtypecd:any = {}
  projhierobjisleaf:any = {}

  allProject = [];
  async getAccountProjectHier() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAccountProjectHier(obj);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'HR-root'
        }
      }
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {

        var tt = []
        for (let k = 1; k < 8; k++) {
          if (resp.data[i]['lvl' + k + '_cd'] != null) {
            tt.push(resp.data[i]['lvl' + k + '_cd'])
          }
        }
        this.dataAccessService.data_assigment = this.dataAccessService.data_assigment.filter((x:any) => x.user_id == this.erpUser.user_id)
        var exit_flag = false;
        for (let j = 0; j < this.dataAccessService.data_assigment.length; j++) {
          if (tt.includes(parseInt(this.dataAccessService.data_assigment[j]['node_cd']))) {
            exit_flag = true;
          }
        }

        if (tt.length == 1) {
          exit_flag = true;
        }
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR"))
          && (resp.data[i]['module_cd'] != null || resp.data[i]['module_cd'] != undefined) && exit_flag) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;
      this.allProject = resp.data;
      this.projecthier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_user_cd']
          this.projhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']
        }
      }
    } else {
      console.log("Error in getting all project hier")
    }
  }

  open_activitypopup() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1)
    }

    console.log("Open Pop Up")
    $('#ACTHIER').modal('show');
  }


  down(item:any, k:any) {
    let patharr = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }
    this.Obj['path_desc'] = patharr.join("-->")
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [] })
    console.log(this.planing_arrproj, item)
    let arr :any= []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_cd'] != null) {

        if ((this.projecthier[i]['lvl' + (k + 1) + '_cd'] == item['hierarchy_type'] || k == -1)/*  && this.projecthier[i]['leaf_node_type'] != 'HREMP' */) {
          this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'] })
          arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
        }
      }
    }
  }

  remove(item:any, i:any) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i])

        patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
      }
      this.Obj['path_desc'] = patharr.join("-->")
    }

  }

  Obj: any = {}
  submitProjectHierNode() {
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']);
    if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
      swal.fire("Warning", "Please Select at least two level", 'warning')
    }
    else {
      $('#ACTHIER').modal('hide');
    }
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'])
    console.log(this.planing_arrproj.length)
    this.Obj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'];
    var lvl_no = this.planing_arrproj.length;
    this.ind_emp_id = [];
    var temp = [];
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]['lvl' + lvl_no + '_cd'] == this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] && this.projecthier[i]['leaf_node_type'] == 'HREMP') {
        temp.push(this.projecthier[i]['leaf_user_cd'])
      }
    }

    for (let i = 0; i < temp.length; i++) {
      for (let j = 0; j < this.allEmplyees_new.length; j++) {
        if (temp[i] == 'HRARR' + this.allEmplyees_new[j]['arr_id']) {
          this.ind_emp_id.push(this.allEmplyees_new[j]['arr_id'])
        }
      }
    }

    console.log(this.ind_emp_id)
  }
  check1:any
  setHierarchyNodeitem(item:any, i:any) {
    console.log(item, i);
    this.check1 = item['hierarchy_type']
    console.log(this.check1);
    item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']]

    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }

    this.Obj['path_desc'] = dummy2.join(' --> ')
  }
  //////////////////////////////////////////////old salary////////////////////////////////
  filterallEmplyees_new :any= []
  empIdToBankAccountNumber:any = {};
  async getAllEmployees() {
    this.spinner.show()
    var arr = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';
    var resp = await this.payableService.getEmployeeMasterData(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log(resp.data)
      arr = resp.data;
      this.empIdToBankAccountNumber = {};
      for (let i = 0; i < arr.length; i++) {
        this.empIdToBankAccountNumber[arr[i]['emp_id']] = arr[i]['acct_no']
        var obj: any = new Object();
        obj = Object.assign({}, arr[i]);

        obj['tempid'] = (obj['emp_id'])
        this.allEmplyees.push(obj)
      }
      this.allEmplyees_new = [];
      for (let i = 0; i < resp.data.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, resp.data[i]);
        obj['emp_name'] = (obj['emp_id']) + "-" + obj['emp_name']
        this.allEmplyees_new.push(obj)
      }

      this.filterallEmplyees_new = await this.dataAccessService.filterEmployeeData(this.allEmplyees_new)
      for (var i = 0; i < this.allEmplyees.length; i++) {
        this.salaryObj.emp_info[this.allEmplyees[i].arr_id] = this.allEmplyees[i];
      }
    } else {
      this.spinner.hide()
      swal.fire('Error', ' Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async getAllActiveEmployees() {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    //obj['emp_status_code'] = ['ACTIVE']
    var resp = await this.payableService.getArrayAllCurrentEstablishementInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allCurrentArrangements = resp['data'];
      for (var i = 0; i < this.allCurrentArrangements.length; i++) {
        this.salaryObj.employement_info[this.allCurrentArrangements[i].arr_id] = this.allCurrentArrangements[i];

      }

    } else {
      this.spinner.hide()
    }
  }

  async getAllAttandence() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    this.salaryObj.att_info = {}
    if (this.salaryObj.month == '1' || this.salaryObj.month == '2' || this.salaryObj.month == '3') {
      obj['year'] = parseInt(this.salaryObj.fin_year) + 1;
    } else {
      obj['year'] = this.salaryObj.fin_year;
    }
    obj['month'] = this.salaryObj.month;
    var resp = await this.payableService.getAllAttendence(obj);
    if (resp['error'] == false) {
      this.allAttendance = resp.data;
      for (var i = 0; i < this.allAttendance.length; i++) {

        if (this.salaryObj.att_info[this.allAttendance[i].emp_id] == undefined) {
          this.salaryObj.att_info[this.allAttendance[i].emp_id] = [];
        }
        this.salaryObj.att_info[this.allAttendance[i].emp_id] = this.allAttendance[i];

      }
      console.log(this.salaryObj.att_info)
    } else {
      swal.fire("Error", "Error while getting Attendance Info", 'error');
    }
  }
  async getAllPosting() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.payableService.getAllPosting(obj);
    if (resp['error'] == false) {
      this.allPosting = resp.data;
      for (var i = 0; i < this.allPosting.length; i++) {

        this.salaryObj.post_info[this.allPosting[i].emp_id] = this.allPosting[i].section_code;

      }
    } else {

    }
  }

  async getVariablePay() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['year'] = this.salaryObj.fin_year;
    obj['month'] = this.salaryObj.month;
    this.salaryObj.variable_pay_info = {};
    var resp = await this.payableService.getEffectiveVariablePay(obj);
    if (resp['error'] == false) {
      this.allVariablePay = resp.data;
      for (var i = 0; i < this.allVariablePay.length; i++) {
        if ((this.allVariablePay[i].fin_year == this.salaryObj.fin_year && this.allVariablePay[i].month == this.salaryObj.month && this.allVariablePay[i].pay_code == 'DED')) {
          if (this.salaryObj.variable_pay_info[this.allVariablePay[i].emp_id] == undefined) {
            this.salaryObj.variable_pay_info[this.allVariablePay[i].emp_id] = [];
          }
          this.salaryObj.variable_pay_info[this.allVariablePay[i].emp_id].push(this.allVariablePay[i]);
        }
      }
    } else {
      swal.fire("Error", "Error while Getting All Variable Deductions", 'error');
    }
  }

  async getFixedPay() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['month'] = this.salaryObj.month;
    this.getLeap(year);

    var year;
    if (this.salaryObj.month == '1' || this.salaryObj.month == '2' || this.salaryObj.month == '3') {
      year = parseInt(this.salaryObj.fin_year) + 1;
    } else {
      year = parseInt(this.salaryObj.fin_year);
    }
    obj['year'] = year;
    obj['end_dt'] = '2090-10-10';

    this.salaryObj.fixed_pay_info = {};
    var resp = await this.payableService.getEffectiveFixedSalary(obj);
    if (resp['error'] == false) {
      this.allFixedPay = resp.data;
      for (var i = 0; i < this.allFixedPay.length; i++) {
        if (this.salaryObj.fixed_pay_info[this.allFixedPay[i].emp_id] == undefined) {
          this.salaryObj.fixed_pay_info[this.allFixedPay[i].emp_id] = [];
        }
        this.salaryObj.fixed_pay_info[this.allFixedPay[i].emp_id].push(this.allFixedPay[i]);



      }
    } else {
      swal.fire("Error", "Error while getting Fixed Pay", 'error');
    }
  }
  async getSalaryStatus() {
    var resp = await this.payableService.getstopsalary(this.b_acct_id);
    if (resp['error'] == false) {
      var dt = resp['data'];
      for (var i = 0; i < dt.length; i++) {
        if (dt[i]['status'] == 'STOP') {
          this.stopObj[dt[i]['emp_id']] = 1;
        }
      }
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee all  list ', 'error')
      // this.snackBar.open("Error while getting employee all  list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async getSalary() {
    //variable_pay
    this.spinner.show();
    await this.getFixedPay()
    // await this.getVariablePay();
    await this.getAllAttandence();
    await this.getSalaryStatus();
    await this.calculateSalary();
    this.spinner.hide();
  }
  getLeap(year:any) {
    //var year = this.attendanceObj['year'];
    var leap = false;
    if (year % 4 == 0) {
      if (year % 100 == 0) {
        // year is divisible by 400, hence the year is a leap year
        if (year % 400 == 0)
          leap = true;
        else
          leap = false;
      }
      else
        leap = true;
    }
    else
      leap = false;
    if (leap == true) {
      this.monthEnd[2] = 29;
    }


  }

  async calculateSalary() {
    console.log(this.salaryObj)
    this.salaryArr = []
    var year;
    if (this.salaryObj.month == '1' || this.salaryObj.month == '2' || this.salaryObj.month == '3') {
      year = parseInt(this.salaryObj.fin_year) + 1;
    } else {
      year = parseInt(this.salaryObj.fin_year);
    }
    var flag = false;
    var errorMsg = "";
    var activeEmps = Object.keys(this.salaryObj.employement_info);
    if (this.salaryObj['type'] == 'ind') {
      activeEmps = this.ind_emp_id;
      // this.salaryObj.section_code = this.salaryObj.post_info[activeEmps[0]]
      // this.salaryObj.emp_cat_code = this.salaryObj.emp_info[activeEmps[0]]['emp_cat_code']

    }

    var payObj:any = {};
    var accrual_date = this.salaryObj.accrual_date;
    //*********************Fix and Variable Pay Calculation */

    for (var i = 0; i < activeEmps.length; i++) {
      if (this.salaryObj.post_info[activeEmps[i]] == undefined) {
        this.salaryObj.post_info[activeEmps[i]] = "ESP";
      }
      if ((this.salaryObj.emp_info[activeEmps[i]]['emp_cat_code'] == this.salaryObj.emp_cat_code || this.salaryObj['type'] == 'ind')
        && (this.stopObj[activeEmps[i]] == undefined || this.stopObj[activeEmps[i]] == 'START')) {
        var salArr = this.salaryObj.fixed_pay_info[activeEmps[i]];
        if (salArr == undefined) {
          salArr = [];
        }
        var salObj:any = {}
        if (salArr != undefined) {
          for (var j = 0; j < salArr.length; j++) {

            var effectiveStartDate = salArr[j].effective_start_dt;
            var effectiveEndDate = salArr[j].effective_end_dt
            var x = effectiveStartDate.split('-');
            var y = effectiveEndDate.split('-');
            var effectiveStartYear = parseInt(x[0]);
            var effectiveStartMonth = parseInt(x[1]);
            var effectiveStartDay = parseInt(x[2]);
            var effectiveEndYear = parseInt(y[0]);
            var effectiveEndMonth = parseInt(y[1]);
            var effectiveEndDay = parseInt(y[2]);
            console.log(effectiveStartYear);
            console.log(effectiveStartMonth);
            console.log(effectiveStartDay);
            console.log(effectiveEndYear);
            console.log(effectiveEndMonth);
            console.log(effectiveEndDay);
            if (activeEmps[i] == '20') {
              // console.log(effectiveStartYear);
              // console.log(effectiveStartMonth);
              // console.log(effectiveStartDay);
              // console.log(effectiveEndYear);
              // console.log(effectiveEndMonth);
              // console.log(effectiveEndDay);
            }
            var currYear = parseInt(this.salaryObj.fin_year);
            var currMonth = parseInt(this.salaryObj.month);
            if (currMonth == 1 || currMonth == 2 || currMonth == 3) {
              currYear += 1;
            }

            if (currYear > effectiveStartYear || (currYear == effectiveStartYear && currMonth >= effectiveStartMonth)) {
              var start = 1;
              var end = this.monthEnd[this.salaryObj.month];
              if (currYear == effectiveStartYear && currMonth == effectiveStartMonth) {
                start = effectiveStartDay;
              }
              if (currYear == effectiveEndYear && currMonth == effectiveEndMonth) {
                end = effectiveEndDay;
                //end=end-1;
              }
              console.log(salArr[j]["pay_component_code"] + "-" + String(end - start + 1))
              if (end - start + 1 >= 0) {
                if (salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)] == undefined) {
                  salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)] = { type: 'fix', pay_component_code: salArr[j].pay_component_code, emp_id: activeEmps[i], pay_code: salArr[j].pay_code, pay_component_amt: 0, num_of_days: end - start + 1 };
                }
                if (salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)]['pay_code'] == 'PAY') {
                  salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)]['pay_component_amt'] = Math.round(salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)]['pay_component_amt'] + parseFloat((salArr[j].pay_component_amt * (end - start + 1) / this.monthEnd[this.salaryObj.month]).toFixed(2)));

                } else {
                  salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)]['pay_component_amt'] = salArr[j].pay_component_amt;
                }
                console.log(salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)]['pay_component_amt'])
                console.log(salObj)
              }
            }
          }
        }
        var keys = Object.keys(salObj);
        for (var j = 0; j < keys.length; j++) {
          this.salaryArr.push(salObj[keys[j]]);
        }
        console.log(this.salaryArr)

      }


    }
    //********************Attendance Calculation , Suspended Calculation*/
    var emps = []
    for (var i = 0; i < this.salaryArr.length; i++) {
      var present_days;
      var absent_days;
      var total_days;
      if (emps.indexOf(this.salaryArr[i].emp_id) < 0) {
        emps.push(this.salaryArr[i].emp_id);
      }

      if (this.salaryArr[i].type == 'fix' && this.salaryArr[i].pay_code == 'PAY') {

        if (payObj[this.salaryArr[i].emp_id] == undefined) {
          payObj[this.salaryArr[i].emp_id] = 0;
        }
        payObj[this.salaryArr[i].emp_id] += this.salaryArr[i].pay_component_amt;

      }
      this.salaryArr[i]['b_acct_id'] = this.b_acct_id;
      this.salaryArr[i]['emp_id'] = this.salaryArr[i].emp_id;
      this.salaryArr[i]['fin_year'] = this.salaryObj.fin_year;
      this.salaryArr[i]['month'] = this.salaryObj.month;
      if (this.salaryObj['type'] == 'ind' && this.flag_sal == true) {
        this.salaryArr[i]['bill_desc'] = "Salary  Bill for " + (this.salaryArr[i].emp_id) + "-" + this.salaryObj.emp_info[this.salaryArr[i].emp_id].emp_name/* " hold employees"+ *///" for Month "+this.monthObj[this.salaryObj.month]+", "+year;

      } else if (this.salaryObj['type'] == 'ind') {
        this.salaryArr[i]['bill_desc'] = (this.salaryArr[i].emp_id) + "-" + this.salaryObj.emp_info[this.salaryArr[i].emp_id].emp_name +/* " hold employees"+ */" Salary  Bill for " + this.monthObjShort[this.salaryObj.month] + ", " + year;
      }
      else {
        this.salaryArr[i]['bill_desc'] = this.mainService.codeValueShowObj['HR0046'][this.salaryObj.emp_cat_code] + " Salary  Bill  for  " + this.monthObjShort[this.salaryObj.month] + ", " + year;
      }
      console.log(this.monthEnd[this.salaryObj.month])
      this.salaryArr[i]['pay_component_code'] = this.salaryArr[i].pay_component_code;
      this.salaryArr[i]['pay_code'] = this.salaryArr[i].pay_code;
      this.salaryArr[i]['section_code'] = this.salaryObj.post_info[this.salaryArr[i].emp_id];
      this.salaryArr[i]['bill_status_code'] = "GENERATED";
      this.salaryArr[i]['create_user_id'] = this.erpUser.user_id;
      this.salaryArr[i]['emp_name'] = this.salaryObj.emp_info[this.salaryArr[i].emp_id].emp_name;
      this.salaryArr[i]['emp_phone_no'] = this.salaryObj.emp_info[this.salaryArr[i].emp_id].emp_phone_no;
      this.salaryArr[i]['accrual_date'] = accrual_date;
      this.salaryArr[i]['bill_date'] = accrual_date;
      //this.salaryArr[i]['num_of_days'] = this.monthEnd[this.salaryObj.month] - absent_days;
      if (this.salaryArr[i]['pay_component_code'] == 'NPS') {
        this.salaryArr[i]['pay_component_amt'] = Math.round(this.salaryArr[i]['pay_component_amt'])
      }

    }


    //***************************Deduction Normalisation */
    this.actualSalaryArr = [];
    var salObjNew:any = {}
    for (var i = 0; i < this.salaryArr.length; i++) {
      if (salObjNew[this.salaryArr[i].emp_id] == undefined) {
        salObjNew[this.salaryArr[i].emp_id] = {};
      }
      salObjNew[this.salaryArr[i].emp_id][this.salaryArr[i]["pay_component_code"] + "-" + i] = this.salaryArr[i];
    }
    var seq = ['BASIC', 'DA', 'HRA', 'CCA', 'MA', 'PB', 'CONV', 'PERSNLPAY', 'GIS'];
    for (var i = 0; i < emps.length; i++) {
      var obj = salObjNew[emps[i]];
      var keys = Object.keys(obj);

      for (var j = 0; j < seq.length; j++) {
        if (obj[seq[j]] != undefined) {
          if (obj[seq[j]].pay_code == 'PAY') {
            this.actualSalaryArr.push(obj[seq[j]]);

          } else {
            if (obj[seq[j]].pay_component_amt <= payObj[emps[i]]) {
              this.actualSalaryArr.push(obj[seq[j]]);
              payObj[emps[i]] -= obj[seq[j]].pay_component_amt;
            } else {
              obj[seq[j]].pay_component_amt = payObj[emps[i]]
              payObj[emps[i]] = 0;
              this.actualSalaryArr.push(obj[seq[j]]);

            }
          }
        }
      }
      for (var j = 0; j < keys.length; j++) {
        if (seq.indexOf(keys[j]) < 0) {
          if (obj[keys[j]].pay_code == 'PAY' || obj[keys[j]].pay_code == 'CONT') {
            this.actualSalaryArr.push(obj[keys[j]]);
          } else {
            if (obj[keys[j]].pay_component_amt <= payObj[emps[i]]) {
              this.actualSalaryArr.push(obj[keys[j]]);
              payObj[emps[i]] -= obj[keys[j]].pay_component_amt;
            } else {
              obj[keys[j]].pay_component_amt = payObj[emps[i]]
              payObj[emps[i]] = 0;
              this.actualSalaryArr.push(obj[keys[j]]);

            }
          }
        }
      }
    }

    console.log(this.actualSalaryArr)
    if (flag == false && this.flag_sal == false) {
      this.datasource = new MatTableDataSource(this.actualSalaryArr);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;

    } else if (flag == false && this.flag_sal == true) {
      console.log(this.salaryArr)
      for (let i = 0; i < this.actualSalaryArr.length; i++) {
        this.hold_sal.push(this.actualSalaryArr[i])

      }
      this.flag_sal = false
    }
    else {
      this.salaryArr = [];
      this.actualSalaryArr = [];
      swal.fire("Error", errorMsg, 'error');
      this.datasource = new MatTableDataSource(this.actualSalaryArr);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    }

  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter1(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  applyFilter4(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  displayedColumns = []
  async getbillbydate() {
    var obj: any = new Object();
    this.billIdObj['b_acct_id'] = this.b_acct_id;

    var resp = await this.payableService.getbillbydate(JSON.stringify(this.billIdObj));
    if (resp['error'] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['bill_type'] != "SAL_BILL") {
          this.allBillId.push(resp.data[i])
        }
      }
      // this.allBillId = resp.data;

      console.log(this.allBillId)

      this.dataSource1 = new MatTableDataSource(this.allBillId);
      this.dataSource1.paginator = this.paginator1
      this.dataSource1.sort = this.sortCol2;
    } else {
      swal.fire('Error', 'Error in getting All Bill ', 'error')
      // this.snackBar.open("Error in getting All Bill", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async deleteBill(element :any) {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['bill_id'] = element.bill_id;
    this.spinner.show();
    var resp = await this.payableService.deleteBill(JSON.stringify(obj));
    if (resp['error'] == false) {
      //await this.sendToApproval(resp['data']);
      await this.getAllBillID();
      //await this.insertIntoAccount(1);
      this.spinner.hide();
      swal.fire('Success', 'Bill Deleted Successfully', 'success');


    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error in Bill Deletion', 'error');
    }

  }
  async getAllBillID() {
    var obj: any = new Object();
    this.billIdObj['b_acct_id'] = this.b_acct_id;

    var resp = await this.payableService.getAllBillId(JSON.stringify(this.billIdObj));
    if (resp['error'] == false) {
      this.allBillId = resp.data;
      this.dataSource1 = new MatTableDataSource(this.allBillId);
      this.dataSource1.paginator = this.paginator1
      this.dataSource1.sort = this.sortCol2;
    } else {
      swal.fire('Error', ' Error in getting All Bill', 'error')
      // this.snackBar.open("Error in getting All Bill", 'Error', {
      //   duration: 5000
      // })
    }
  }
  totObj :any= {}
  print(printObj:any, header:any, arrearmon:any) {
    this.totObj = {}
    var file_name = header['bill_desc'];


    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + "   " + header['bill_desc'] + "   Date: " + header['accrual_date'];
    var dd :any= {
      pageSize: {
        width: 1600,
        height: 800
      },
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },


      pageOrientation: 'landscape',


      pageMargins: [20, 60, 20, 60],

      content: [

      ]
    };
    var sections = Object.keys(printObj);
    var count = 0;
    sections.length = 1
    for (var i = 0; i < sections.length; i++) {
      //  var data = printObj[sections[i]];

      var sectionText :any= { text: 'Section : ' + sections[i], fontSize: 13 };
      if (i != 0) {
        sectionText['pageBreak'] = 'before'
      }
      console.log(sections)
      // console.log(data)

      let list:any = ['Employee Details'];
      let data_keys:any = ['Employee Details'];
      let width:any = ['auto'];
      for (let i = 0; i < this.all_component.length; i++) {
        if (this.all_component[i]['pay_code'] == 'PAY') {
          list.push(this.all_component[i]['component_code'])
          data_keys.push(this.all_component[i]['component_code'])
          width.push('auto')
        }
      }
      width.push('auto')
      list.push('gross pay');
      data_keys.push('gross pay');
      width.push('auto')

      for (let i = 0; i < this.all_component.length; i++) {
        if (this.all_component[i]['pay_code'] == 'DED') {
          list.push(this.all_component[i]['component_code']);
          data_keys.push(this.all_component[i]['component_code'])

          width.push('auto')
        }
      }
      list.push('total ded');
      data_keys.push('total ded');
      width.push('auto');
      list.push('net sal');
      width.push('auto');
      data_keys.push('net sal');

      console.log(width)


      // for (let i = 0; i < list.length; i++) {
      //   tbl.table.body[0].push(list[i]);
      //   tbl.table.widths.push('auto')
      // }
      // dd.content.push(tbl);

      var emps:any= Object.keys(printObj);
      count = count + emps.length;


      console.log(emps);
      var total_obj:any = new Object();
      total_obj['emp_info'] = 'Total'
      // var arr = [];
      var fin_tot = []
      // var totObj={}

      if (this.totObj == undefined) {
        this.totObj = {}


      }
      for (var j = 0; j < emps.length; j++) {

        for (let i = 0; i < this.allEmplyees.length; i++) {
          if (this.allEmplyees[i]['arr_id'] == emps[j]) {
            var emp_name :any= this.allEmplyees[i]['emp_id'] + "-" + this.allEmplyees[i]['emp_name']
          }
        }
        var sign3:any = {
          columns: [
            {
              width: '*',
              text: emp_name,
              bold: true
            },

            {
              width: '*',
              text: '',
              bold: true
            },
            {
              width: '*',
              text: '',
              bold: true
            }


          ],

        }
        dd.content.push("\n");
        dd.content.push(sign3);
        dd.content.push("\n");

        for (var k = 0; k < arrearmon.length; k++) {
          // var obj = data['data'][emps[j]];
          // console.log(obj)
          // console.log(this.keys)
          // var obj1={}
          //   dd.content.push("\n\n");
          //  dd.content.push(arrearmon[k]['month']);
          var sign2 = {
            columns: [
              {
                width: '*',
                text: '',
                bold: true
              },

              {
                width: '*',
                text: this.monthObj[parseInt(arrearmon[k]['month'])] + '---' + this.mainService.codeValueShowObj['HR0023'][parseInt(arrearmon[k]['year'])],
                bold: true
              },
              {
                width: '*',
                text: '',
                bold: true
              }


            ],

          }
          dd.content.push("\n");
          dd.content.push(sign2);
          dd.content.push("\n");
          var tbl :any= {

            // layout: 'lightHorizontalLines',
            fontSize: 13,

            table: {

              headerRows: 1,
              // widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
              widths: [],

              body: [

                // ['Emp\nDetail', 'Basic\nPay', 'Dep.\nAllow', 'DA/Relief', 'Med\nAllow', 'Veh\nAllow', 'HRA', 'WA', 'Misc\nAllow', 'Total', 'LIC', 'PF\nDed', 'NPS', 'Group\nIns.', 'IT', 'House\nRent', 'Veh\nDed', 'Veh\nAdv.', 'Bld\nAdv.', 'PF\nAdv.', 'Bank\nAdv.', 'EWF', 'Misc\nDed', 'Net.\nSal.']
                // list
                []
              ]
            }
          };
          for (let i = 0; i < list.length; i++) {
            tbl.table.body[0].push(list[i]);
            tbl.table.widths.push('auto')
          }
          dd.content.push(tbl);


          console.log(emps[j])

          // arr.push(printObj[emps[j]]['curr']['10-2021'])
          var prev_len = printObj[emps[j]]['prev'][parseInt(arrearmon[k]['month']) + '-' + parseInt(arrearmon[k]['year'])].length
          var curr_len = printObj[emps[j]]['curr'][parseInt(arrearmon[k]['month']) + '-' + parseInt(arrearmon[k]['year'])].length



          for (let l = 0; l < prev_len; l++) {
            var obj :any= {}

            obj = printObj[emps[j]]['prev'][parseInt(arrearmon[k]['month']) + '-' + parseInt(arrearmon[k]['year'])][l]

            var arr :any= [];
            for (let x = 0; x < data_keys.length; x++) {



              arr.push(obj[data_keys[x]])


            }
            dd.content[dd.content.length - 1].table.body.push(arr);
          }

          for (let l = 0; l < curr_len; l++) {
            var obj:any = {}
            var obj1 :any= {}


            obj = printObj[emps[j]]['curr'][parseInt(arrearmon[k]['month']) + '-' + parseInt(arrearmon[k]['year'])][l]
            if (l == curr_len - 1) {
              obj1 = printObj[emps[j]]['curr'][parseInt(arrearmon[k]['month']) + '-' + parseInt(arrearmon[k]['year'])][l]
              for (let x = 0; x < data_keys.length; x++) {


                if (data_keys[x] != "Employee Details") {
                  if (obj1[data_keys[x]] == undefined || this.totObj[data_keys[x]] == undefined) {
                    //obj1[data_keys[x]]={}
                    this.totObj[data_keys[x]] = 0.00
                  }
                  console.log(obj1)
                  this.totObj[data_keys[x]] += parseFloat(obj1[data_keys[x]])
                  console.log(this.totObj)
                  //   }
                }
              }
            }
            var arrcur = [];
            for (let x = 0; x < data_keys.length; x++) {



              arrcur.push(obj[data_keys[x]])


            }
            dd.content[dd.content.length - 1].table.body.push(arrcur);
          }



          console.log(arr)





























        }
      }
      console.log(this.totObj)
      var arrfin = []
      for (let i = 0; i < data_keys.length; i++) {

        if (data_keys[i] == "Employee Details") {
          this.totObj["Employee Details"] = "final Pay"
        }
        else {
          this.totObj[data_keys[i]] = (this.totObj[data_keys[i]]).toFixed(2)
        }
        arrfin.push(this.totObj[data_keys[i]])
      }
      var tbl:any = {

        // layout: 'lightHorizontalLines',
        fontSize: 13,

        table: {

          headerRows: 1,
          // widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          widths: [],

          body: [

            // ['Emp\nDetail', 'Basic\nPay', 'Dep.\nAllow', 'DA/Relief', 'Med\nAllow', 'Veh\nAllow', 'HRA', 'WA', 'Misc\nAllow', 'Total', 'LIC', 'PF\nDed', 'NPS', 'Group\nIns.', 'IT', 'House\nRent', 'Veh\nDed', 'Veh\nAdv.', 'Bld\nAdv.', 'PF\nAdv.', 'Bank\nAdv.', 'EWF', 'Misc\nDed', 'Net.\nSal.']
            // list
            []
          ]
        }
      };
      for (let i = 0; i < list.length; i++) {
        tbl.table.body[0].push(list[i]);
        tbl.table.widths.push('auto')
      }
      dd.content.push("\n")
      dd.content.push("Final Total")
      dd.content.push(tbl);
      dd.content[dd.content.length - 1].table.body.push(arrfin);







      // dd.content[dd.content.length - 1].table.body.push(arr);

    }





    dd.content.push("\n\n");
    var sign1 = {
      columns: [
        {
          width: '*',
          text: 'PREPARED BY:',
          bold: true
        },

        {
          width: '*',
          text: 'CHECKED BY:',
          bold: true
        },
        {
          width: '*',
          text: 'SIGNED BY:',
          bold: true
        }


      ],

    }
    dd.content.push("\n\n\n");
    dd.content.push(sign1);
    dd.content.push("\n\n");
    dd.content.push({ text: "CERTIFIED:", bold: true })
    dd.content.push("\n\n");
    dd.content.push({ text: "1. That I have satisfied myself that all the salaries included in the bills drawn in the month of  the last preceding month] with the exception of those detailed below of which total has been refunded by deduction from the bill has been distributed to the proper persons and their receipts have been taken in acquittance rolls field in my office with reciept-stamp dully cancelled for every payment in access of Rs. 20 and that all leaves and promotions etc have been in the service book of the official concerned." })
    dd.content.push("\n");
    dd.content.push({ text: "2. That all persons for whom pay has been drawn in this bill have actually been entertained during the month." })
    dd.content.push("\n");

    dd.content.push({ text: "3. That all the persons for whom house-rent allowance has been shown in this bill actually occupied a rented house for which they paid rent as shown in this bill and are entitled to the allowance under the standing instructions." })
    dd.content.push("\n");

    dd.content.push({ text: "4. That all the persons in respect of whom conveyance allowance has been drawn in the bill have satisfied me that they have actually maintained the conveyance in a workable condition and have been using them." })
    dd.content.push("\n");

    dd.content.push({ text: "5. That the bill has been checked with the sanctioned in the scale register." })
    dd.content.push("\n");

    dd.content.push({ text: "Date :                                                    Signature Of Drawing Officer:" })
    dd.content.push("\n");

    dd.content.push({ text: "Pay Rs. ....................................." })




    pdfMake.createPdf(dd).download(file_name);
  }
  keys = []
  arrear_monthsprint :any= []
  async getAllBill(element:any, type:any) {
    this.currentBillObj = { header: {}, allEmployees: [], data: {}, sums: {} };

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['bill_id'] = element['bill_id'];
    this.spinner.show();

    var resp1 = await this.payableService.getAllComponent(JSON.stringify(obj));
    if (resp1['error'] == false) {
      this.all_component = []
      this.all_component = resp1.data;
    } else {
      return;
    }

    let obb :any= new Object();
    obb['emp_id'] = '';
    obb['emp_name'] = '';
    obb['designation_code'] = '';
    obb['grade_pay_code'] = '';
    obb['pay_band'] = '';
    obb['sal_acc'] = '';
    obb['pf'] = '';
    obb['pf_ifsc'] = '';

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'PAY') {
        obb[this.all_component[i]['component_code']] = 0
      }
    }

    obb['gross pay'] = 0

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'DED') {
        obb[this.all_component[i]['component_code']] = 0
      }
    }
    obb['total'] = 0
    obb['net sal'] = 0;
    obb['arrear'] = 0;
    console.log(obb)
    var resp = await this.payableService.getAllBill(JSON.stringify(obj));
    if (resp['error'] == false) {
      var billObj = {};
      var header = "";
      var dt = resp['data'];
      console.log(dt)
      dt[0]['previous'] = JSON.parse(dt[0]['previous'])
      var printobj = dt[0]['previous']
      // this.keys=Object.keys(dt[0]['previous'])

      console.log(this.keys)
      this.arrear_monthsprint = []
      var startDate = moment(dt[0]['start_date'])
      var endDate = moment(dt[0]['end_date'])
      while (startDate.isBefore(endDate)) {
        this.arrear_monthsprint.push({ month: startDate.format("MM"), year: startDate.format("YYYY") });
        startDate.add(1, 'month');

      }

      console.log(this.arrear_monthsprint);

      if (dt.length > 0) {
        header = dt[0];
      }
      // var grand = undefined;
      // var month = "";
      // var fin_year = "";
      // var fixedarr = [];
      // console.log(arr)

      // for (var i = 0; i < dt.length; i++) {
      //   //header = dt[0];
      //   if (billObj[dt[i]['section_code']] == undefined) {
      //     month = dt[i]['month'];
      //     fin_year = dt[i]['fin_year'];
      //     billObj[dt[i]['section_code']] = {};
      //     billObj[dt[i]['section_code']]['data'] = {};
      //   //  billObj[dt[i]['section_code']]['arrear'] = {};
      //     for(let j=0;j<this.keys.length;j++)
      //     {
      //       billObj[dt[i]['section_code']][this.keys[j]] = {};
      //       console.log(arr[this.keys[j]]['data'])
      //     }

      //     //{'BASIC':0.00,'DA':0.00,'DEP':0.00,'HRA':0.00,'MA':0.00,'VA':0.00,'WA':0.00,'miscpay':[],'LIC1':0.00,LIC2:0.00,LIC3:0.00,LIC4:0.00,LIC5:0.00,LIC6:0.00,LIC7:0.00,PF:0.00,GIS:0.00,IT:0.00,HRR:0.00,VD:0.00,VADV:0.00,BLDADV1:0.00,BLDADV2:0.00,BLDADV3:0.00,PFADV:0.00,PFADV1:0.00,PFADV2:0.00,BADV:0.00,EWF:0.00,miscded:[]};
      //     billObj[dt[i]['section_code']]['total'] = { 'BASIC': 0.00, 'DA': 0.00, 'DEP': 0.00, 'HRA': 0.00, 'MA': 0.00, 'VA': 0.00, 'WA': 0.00, 'miscpay': [], 'LIC1': 0.00, LIC2: 0.00, LIC3: 0.00, LIC4: 0.00, LIC5: 0.00, LIC6: 0.00, LIC7: 0.00, PF: 0.00, NPS: 0.00, GIS: 0.00, IT: 0.00, HRR: 0.00, VD: 0.00, VADV: 0.00, BLDADV1: 0.00, BLDADV2: 0.00, BLDADV3: 0.00, PFADV: 0.00, PFADV1: 0.00, PFADV2: 0.00, BADV: 0.00, EWF: 0.00, miscded: [], total: 0.00, net sal: 0.00 };
      //     if (grand == undefined) {
      //       grand = { 'BASIC': 0.00, 'DA': 0.00, 'DEP': 0.00, 'HRA': 0.00, 'MA': 0.00, 'VA': 0.00, 'WA': 0.00, 'miscpay': [], 'LIC1': 0.00, LIC2: 0.00, LIC3: 0.00, LIC4: 0.00, LIC5: 0.00, LIC6: 0.00, LIC7: 0.00, PF: 0.00, NPS: 0.00, GIS: 0.00, IT: 0.00, HRR: 0.00, VD: 0.00, VADV: 0.00, BLDADV1: 0.00, BLDADV2: 0.00, BLDADV3: 0.00, PFADV: 0.00, PFADV1: 0.00, PFADV2: 0.00, BADV: 0.00, EWF: 0.00, miscded: [], total: 0.00, net sal: 0.00 };
      //     }
      //   }
      //   if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']] == undefined) {
      //     fixedarr = []
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']] = Object.assign({}, obb);
      //     // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']] = { emp_id: '', emp_name: '', designation_code: '', grade_pay_code: '', pay_band: '', sal_acc: '', pf: '', pf_ifsc: '', 'BASIC': 0.00, 'DA': 0.00, 'DEP': 0.00, 'HRA': 0.00, 'MA': 0.00, 'VA': 0.00, 'WA': 0.00, 'miscpay': [], 'LIC1': 0.00, LIC2: 0.00, LIC3: 0.00, LIC4: 0.00, LIC5: 0.00, LIC6: 0.00, LIC7: 0.00, PF: 0.00, 'NPS': 0.00, GIS: 0.00, IT: 0.00, HRR: 0.00, VD: 0.00, VADV: 0.00, BLDADV1: 0.00, BLDADV2: 0.00, BLDADV3: 0.00, PFADV: 0.00, PFADV1: 0.00, PFADV2: 0.00, BADV: 0.00, EWF: 0.00, miscded: [], total: 0.00, net sal: 0.00 };
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['emp_id'] = (dt[i]['emp_id']);
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['emp_name'] = dt[i]['emp_name']
      //     // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['designation_code'] = this.salaryObj.employement_info[dt[i].emp_id]['designation_code'];;
      //     // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['grade_pay_code'] = "GP " + this.salaryObj.employement_info[dt[i].emp_id]['grade_pay_code'];
      //     // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pay_band'] = 'PB ' + '(' + this.salaryObj.employement_info[dt[i].emp_id]['pay_scale_code'] + ')';
      //     // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['sal_acc'] = this.salaryObj.emp_info[dt[i].emp_id]['acct_no'];
      //     // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pf'] = this.salaryObj.emp_info[dt[i].emp_id]['pf_acct_no'];
      //     // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pf_ifsc'] = this.salaryObj.emp_info[dt[i].emp_id]['pf_ifsc_code'];
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['designation_code'] = ''
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['grade_pay_code'] = ''
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pay_band'] = ''
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['sal_acc'] = ''
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pf'] = ''
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pf_ifsc'] = ''
      //     for(let j=0;j<this.keys.length;j++){
      //       billObj[dt[i]['section_code']][this.keys[j]]=arr[this.keys[j]]['data']

      //     }

      //   }



      //   if (dt[i]['pay_code'] == 'PAY') {
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['gross pay'] += dt[i]['pay_component_amt'];
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['net sal'] += dt[i]['pay_component_amt'];

      //  //   billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['arrear'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
      //    // billObj[dt[i]['section_code']]['arrear'][dt[i]['emp_id']]['gross pay'] += dt[i]['pay_component_amt'];
      //  //   billObj[dt[i]['section_code']]['arrear'][dt[i]['emp_id']]['net sal'] += dt[i]['pay_component_amt'];

      //  //   billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['arrear'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
      //  //   billObj[dt[i]['section_code']]['arrear'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
      //     // billObj[dt[i]['section_code']]['total']['total'] += dt[i]['pay_component_amt'];
      //     // billObj[dt[i]['section_code']]['total']['net sal'] += dt[i]['pay_component_amt'];
      //     // grand['total'] += dt[i]['pay_component_amt'];
      //     // grand['net sal'] += dt[i]['pay_component_amt'];

      //     // if (!fixedarr.includes(dt[i]['pay_component_code'])) {
      //     //   fixedarr.push(dt[i]['pay_component_code'])
      //     //   if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] != undefined) {
      //     //     // billObj[dt[i]['section_code']]['total'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
      //     //     // grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
      //     //   } else {
      //     //     billObj[dt[i]['section_code']]['total']['miscpay'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
      //     //     grand['miscpay'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });

      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['miscpay'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
      //     //   }
      //     // } else {
      //     //   billObj[dt[i]['section_code']]['total'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

      //     //   if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"] == undefined) {
      //     //     var temp = grand[dt[i]['pay_component_code']]
      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"] = []
      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']])
      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(dt[i]['pay_component_amt'])

      //     //     grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

      //     //   } else {
      //     //     grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(dt[i]['pay_component_amt'])
      //     //   }

      //     // }
      //   } else {
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['total'] += dt[i]['pay_component_amt'];
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['net sal'] -= dt[i]['pay_component_amt'];

      //    // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['arrear'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
      //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
      //    // billObj[dt[i]['section_code']]['arrear'][dt[i]['emp_id']]['total'] += dt[i]['pay_component_amt'];
      //   //  billObj[dt[i]['section_code']]['arrear'][dt[i]['emp_id']]['net sal'] -= dt[i]['pay_component_amt'];

      //    // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['arrear'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
      //    // billObj[dt[i]['section_code']]['arrear'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']


      //     // if (!fixedarr.includes(dt[i]['pay_component_code'])) {
      //     //   fixedarr.push(dt[i]['pay_component_code'])
      //     //   billObj[dt[i]['section_code']]['total']['net sal'] -= dt[i]['pay_component_amt'];
      //     //   grand['net sal'] -= dt[i]['pay_component_amt'];
      //     //   billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['net sal'] -= dt[i]['pay_component_amt'];
      //     //   if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] != undefined) {
      //     //     billObj[dt[i]['section_code']]['total'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
      //     //     grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
      //     //   } else {
      //     //     billObj[dt[i]['section_code']]['total']['miscded'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
      //     //     grand['miscded'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });

      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['miscded'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
      //     //   }
      //     // } else {
      //     //   billObj[dt[i]['section_code']]['total'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
      //     //   billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['net sal'] -= dt[i]['pay_component_amt'];
      //     //   if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"] == undefined) {
      //     //     var temp = grand[dt[i]['pay_component_code']]
      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"] = []
      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']])
      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(dt[i]['pay_component_amt'])

      //     //     grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

      //     //   } else {
      //     //     grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

      //     //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(dt[i]['pay_component_amt'])
      //     //   }

      //     // }
      //   }


      // }


      if (type == 'bill') {
        //   console.log(fixedarr)
        console.log(printobj, header,)
        this.print(printobj, header, this.arrear_monthsprint);
        console.log(billObj);
        console.log(header);
        //  console.log(grand);
        //  console.log(month);



      }
      else {
        //  this.print1(billObj, header, grand, month, fin_year);
      }
      this.spinner.hide()
    } else {
      this.spinner.hide();
      swal.fire("Error", "Error while printing pay bill", 'error')
    }
  }
  undertakings:any
  salBill_apprdata:any
  async Appr(element :any) {
    console.log(element)

    this.salBill_apprdata = Object.assign({}, element)
    this.salBill_apprdata['level'] = 1
    this.salBill_apprdata['branch'] = 1
    this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['bill_id']
    this.salBill_apprdata['doc_type'] = 'SAL_BILL'//this.salBill_apprdata['id']
    this.salBill_apprdata['node_cd'] = element['node_cd']
    this.salBill_apprdata['module_cd'] = 'HR';
    await this.ApprovalUserService.getWorkflowlog(this.erpUser, 'SAL_BILL', 'HR');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'APPROVED')
      return;
    }
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.erpUser, this.salBill_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.erpUser, this.salBill_apprdata)
    /* await this.getAllRule();
    await this.getundertakings(element); */
    $('#myModal3').modal('show');
  }
  async ChangestatusSalBill(bill_id:any, bill_status_code:any) {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['update_user_id'] = this.erpUser.user_id;
    obj['bill_id'] = bill_id;
    obj['bill_status_code'] = bill_status_code;
    // obj['bill_status_code'] = "UNDERAPPROVAL";
    // obj['bill_status_code'] = "APPROVED";
    this.spinner.show();
    console.log(obj)
    var resp = await this.payableService.changeStatusOfBill(obj);
    if (resp['error'] == false) {
      //await this.sendToApproval(resp['data']);
      setTimeout(async () => {
        await this.getbillbydate();
        //await this.insertIntoAccount(1);
        this.spinner.hide();
        swal.fire('Success', 'Bill Approved Successfully');
      }, 1000);


    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error in Bill Generation', 'error');
    }


  }
  async sendTOAccount(element :any) {

    await this.billSendToAccountService.sendToAccountbill(element);

    //
    await this.getbillbydate();
  }
  async status(element :any) {
    var obj: any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_local_no"] = element.bill_id;
    obj['module_cd'] = 'HR'
    obj['doc_type'] = 'SAL_BILL'
    this.spinner.show();
    var resp = await this.mainserviceEmb.getWorkflowlog(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      // await this.ApprovalUserService.getWorkflowloguser(this.erpUser, element)
      // for (let i = 0; i < this.ApprovalUserService.workflowuser.length; i++) {
      //   this.UserObj[this.ApprovalUserService.workflowuser[i]['user_id']] = this.ApprovalUserService.workflowuser[i]['emp_name']

      // }
      this.statusArr = resp['data'];
      console.log('myModal')
      $('#myModal').modal('show');
      this.spinner.hide()
    } else {
      this.spinner.hide();
      swal.fire("Error", "Error while getting status");
    }
  }
  /////////////////////////////////////////short arrear bill////////////////////////
  printshort(billObj:any, header:any, grand:any, month:any, fin_year:any) {
    var file_name = header['bill_desc'];
    console.log(billObj, header, grand)
    //var txt = "VARANASASI DEVELOPMENT AUTHORITY(VDA)   Officers/THIRD/FOURTH Category EMPLOYEES STATEMENT FOR THE MONTH OF June,2020   PIRNT DATE: 2020-10-10"
    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + "   " + header['bill_desc'] + "   Date: " + header['accrual_date'];
    var dd :any= {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },

      //footer: function(currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [40, 60, 40, 60],
      //pageMargins: [ 40, 20, 20, 20 ],
      content: [

      ]
    };
    var sections = Object.keys(billObj);
    var count = 0;
    for (var i = 0; i < sections.length; i++) {
      var data = billObj[sections[i]];

      var sectionText:any = { text: 'Section : ' + sections[i], fontSize: 8 };
      if (i != 0) {
        sectionText['pageBreak'] = 'before'
      }
      //dd.content.push(sectionText);
      let list = ['Emp\nDetail'];
      let data_keys = [];
      let width = ['auto'];
      for (let i = 0; i < this.all_component.length; i++) {
        if (this.all_component[i]['pay_code'] == 'PAY') {
          list.push(this.all_component[i]['component_code'])
          data_keys.push(this.all_component[i]['component_code'])
          width.push('auto')
        }
      }
      list.push('Total');
      data_keys.push('gross pay');

      for (let i = 0; i < this.all_component.length; i++) {
        if (this.all_component[i]['pay_code'] == 'DED') {
          list.push(this.all_component[i]['component_code']);
          data_keys.push(this.all_component[i]['component_code'])

          width.push('auto')
        }
      }
      list.push('Total Ded');
      data_keys.push('total');
      width.push('auto');
      list.push('Net.\nSal.');
      width.push('auto');
      data_keys.push('net');

      console.log(width)

      var tbl :any= {

        // layout: 'lightHorizontalLines',
        fontSize: 10,

        table: {

          headerRows: 1,
          // widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          widths: [],

          body: [

            // ['Emp\nDetail', 'Basic\nPay', 'Dep.\nAllow', 'DA/Relief', 'Med\nAllow', 'Veh\nAllow', 'HRA', 'WA', 'Misc\nAllow', 'Total', 'LIC', 'PF\nDed', 'NPS', 'Group\nIns.', 'IT', 'House\nRent', 'Veh\nDed', 'Veh\nAdv.', 'Bld\nAdv.', 'PF\nAdv.', 'Bank\nAdv.', 'EWF', 'Misc\nDed', 'Net.\nSal.']
            // list
            []
          ]
        }
      };
      for (let i = 0; i < list.length; i++) {
        tbl.table.body[0].push(list[i]);
        tbl.table.widths.push('auto')
      }
      dd.content.push(tbl);
      var emps = Object.keys(data['data']);
      count = count + emps.length;

      console.log(data['data'])
      console.log(emps);
      var total_obj:any = new Object();
      total_obj['emp_info'] = 'Total'
      for (var j = 0; j < emps.length; j++) {
        var obj = data['data'][emps[j]];
        var arr = [];
        var str = obj['emp_id'] + "\n" + obj['emp_name']
        // var str = obj['emp_id'] + "\n" + obj['emp_name'] + "\n" + obj['designation_code'] + "\n" + obj['grade_pay_code'] + "\n" + obj['pay_band'] + "\n" + "SAL A/C - " + obj['sal_acc'];
        // if (obj['pf'] != undefined && obj['pf'] != null && obj['pf'] != 0) {
        //   str += "\n" + "PF A/C - " + obj['pf']
        // }
        // if (obj['pf_ifsc'] != undefined && obj['pf_ifsc'] != null && obj['pf_ifsc'] != 0) {
        //   str += "\n" + "PF Ifsc - " + obj['pf_ifsc']
        // }
        arr.push(str);
        for (let x = 0; x < data_keys.length; x++) {
          if (total_obj[data_keys[x]] == undefined) {
            total_obj[data_keys[x]] = parseInt((obj[data_keys[x]]).toFixed(0))
          } else {
            total_obj[data_keys[x]] = total_obj[data_keys[x]] + parseInt((obj[data_keys[x]]).toFixed(0))
          }
          arr.push((obj[data_keys[x]]).toFixed(0))
        }
        // if (obj['BASIC_arr'] != undefined) {
        //   for (let i = 0; i < obj['BASIC_arr'].length; i++) {
        //     if (i == 0) {
        //       var basic = obj['BASIC_arr'][i]

        //     } else {
        //       basic = basic + "\n" + obj['BASIC_arr'][i]

        //     }


        //   }
        //   arr.push(basic);
        // } else {
        //   arr.push(obj['BASIC']);

        // }
        // if (obj['DEP_arr'] != undefined) {
        //   for (let i = 0; i < obj['DEP_arr'].length; i++) {
        //     if (i == 0) {
        //       var DEP = obj['DEP_arr'][i]

        //     } else {
        //       DEP = DEP + "\n" + obj['DEP_arr'][i]

        //     }


        //   }
        //   arr.push(DEP);
        // } else {
        //   arr.push(obj['DEP']);

        // }
        //   if (obj['DA_arr'] != undefined) {
        //     for (let i = 0; i < obj['DA_arr'].length; i++) {
        //       if (i == 0) {
        //         var DA = obj['DA_arr'][i]

        //       } else {
        //         DA = DA + "\n" + obj['DA_arr'][i]

        //       }


        //     }
        //     arr.push(DA);
        //   } else {
        //     arr.push(obj['DA']);

        //   }
        //   if (obj['MA_arr'] != undefined) {
        //     for (let i = 0; i < obj['MA_arr'].length; i++) {
        //       if (i == 0) {
        //         var MA = obj['MA_arr'][i]

        //       } else {
        //         MA = MA + "\n" + obj['MA_arr'][i]

        //       }


        //     }
        //     arr.push(MA);
        //   } else {
        //     arr.push(obj['MA']);

        //   }
        //   if (obj['VA_arr'] != undefined) {
        //     for (let i = 0; i < obj['VA_arr'].length; i++) {
        //       if (i == 0) {
        //         var VA = obj['VA_arr'][i]

        //       } else {
        //         VA = VA + "\n" + obj['VA_arr'][i]

        //       }


        //     }
        //     arr.push(VA);
        //   } else {
        //     arr.push(obj['VA']);

        //   } if (obj['HRA_arr'] != undefined) {
        //     for (let i = 0; i < obj['HRA_arr'].length; i++) {
        //       if (i == 0) {
        //         var HRA = obj['HRA_arr'][i]

        //       } else {
        //         HRA = HRA + "\n" + obj['HRA_arr'][i]

        //       }


        //     }
        //     arr.push(HRA);
        //   } else {
        //     arr.push(obj['HRA']);

        //   }
        //   if (obj['WA_arr'] != undefined) {
        //     for (let i = 0; i < obj['WA_arr'].length; i++) {
        //       if (i == 0) {
        //         var WA = obj['WA_arr'][i]

        //       } else {
        //         WA = WA + "\n" + obj['WA_arr'][i]

        //       }


        //     }
        //     arr.push(WA);
        //   } else {
        //     arr.push(obj['WA']);

        //   }
        //   /*  arr.push(obj['DEP']);
        //    arr.push(obj['DA']);
        //    arr.push(obj['MA']); */
        //   //arr.push(obj['VA']);
        //   // arr.push(obj['HRA']);
        //   // arr.push(obj['WA']);
        //   var miscpay = obj['miscpay'];
        //   var str1 = "";
        //   for (var k = 0; k < miscpay.length; k++) {
        //     if (k == 0) {
        //       str1 += miscpay[k]['code'] + " - " + miscpay[k]['amount'];
        //     } else {
        //       str1 += "\n" + miscpay[k]['code'] + " - " + miscpay[k]['amount'];
        //     }

        //   }
        //   if (str1 != "") {
        //     arr.push(str1);
        //   }
        //   else {
        //     arr.push(0.00);
        //   }
        //   arr.push(obj['total']);
        //   var licstr = obj['LIC1'] + "\n" + obj['LIC2'] + "\n" + obj['LIC3'] + "\n" + obj['LIC4'] + "\n" + obj['LIC5'] + "\n" + obj['LIC6'] + "\n" + obj['LIC7'];

        //   arr.push(licstr);
        //   if (obj['PF_arr'] != undefined) {
        //     for (let i = 0; i < obj['PF_arr'].length; i++) {
        //       if (i == 0) {
        //         var PF = obj['PF_arr'][i]

        //       } else {
        //         PF = PF + "\n" + obj['PF_arr'][i]

        //       }


        //     }
        //     arr.push(PF);
        //   } else {
        //     arr.push(obj['PF']);

        //   }
        //   if (obj['NPS_arr'] != undefined) {
        //     for (let i = 0; i < obj['NPS_arr'].length; i++) {
        //       if (i == 0) {
        //         var NPS = obj['NPS_arr'][i]

        //       } else {
        //         NPS = NPS + "\n" + obj['NPS_arr'][i]

        //       }


        //     }
        //     arr.push(NPS);
        //   } else {
        //     arr.push(obj['NPS']);

        //   }
        //   if (obj['GIS_arr'] != undefined) {
        //     for (let i = 0; i < obj['GIS_arr'].length; i++) {
        //       if (i == 0) {
        //         var GIS = obj['GIS_arr'][i]

        //       } else {
        //         GIS = GIS + "\n" + obj['GIS_arr'][i]

        //       }


        //     }
        //     arr.push(GIS);
        //   } else {
        //     arr.push(obj['GIS']);

        //   }
        //   if (obj['IT_arr'] != undefined) {
        //     for (let i = 0; i < obj['IT_arr'].length; i++) {
        //       if (i == 0) {
        //         var IT = obj['IT_arr'][i]

        //       } else {
        //         IT = IT + "\n" + obj['IT_arr'][i]

        //       }


        //     }
        //     arr.push(IT);
        //   } else {
        //     arr.push(obj['IT']);

        //   }
        //   if (obj['HRR_arr'] != undefined) {
        //     for (let i = 0; i < obj['HRR_arr'].length; i++) {
        //       if (i == 0) {
        //         var HRR = obj['HRR_arr'][i]

        //       } else {
        //         HRR = HRR + "\n" + obj['HRR_arr'][i]

        //       }


        //     }
        //     arr.push(HRR);
        //   } else {
        //     arr.push(obj['HRR']);

        //   } if (obj['VD_arr'] != undefined) {
        //     for (let i = 0; i < obj['VD_arr'].length; i++) {
        //       if (i == 0) {
        //         var VD = obj['VD_arr'][i]

        //       } else {
        //         VD = VD + "\n" + obj['VD_arr'][i]

        //       }


        //     }
        //     arr.push(VD);
        //   } else {
        //     arr.push(obj['VD']);

        //   }
        //   if (obj['VADV_arr'] != undefined) {
        //     for (let i = 0; i < obj['VADV_arr'].length; i++) {
        //       if (i == 0) {
        //         var VADV = obj['VADV_arr'][i]

        //       } else {
        //         VADV = VADV + "\n" + obj['VADV_arr'][i]

        //       }


        //     }
        //     arr.push(VADV);
        //   } else {
        //     arr.push(obj['VADV']);

        //   }
        //   /*  arr.push(obj['PF']);
        //    arr.push(obj['NPS']);
        //    arr.push(obj['GIS']);
        //    arr.push(obj['IT']);
        //    arr.push(obj['HRR']);
        //    arr.push(obj['VD']);
        //    arr.push(obj['VADV']); */
        //   var bldstr = obj['BLDADV1'] + "\n" + obj['BLDADV2'] + "\n" + obj['BLDADV3']
        //   arr.push(bldstr);
        //   var pfstr = obj['PFADV'] + "\n" + obj['PFADV1'] + "\n" + obj['PFADV2']
        //   arr.push(pfstr);
        //   arr.push(obj['BADV']);
        //   arr.push(obj['EWF']);
        //   var miscded = obj['miscded'];
        //   var str2 = "";
        //   for (var k = 0; k < miscded.length; k++) {
        //     if (k == 0) {
        //       str2 += miscded[k]['code'] + " - " + miscded[k]['amount'];
        //     } else {
        //       str2 += "\n" + miscded[k]['code'] + " - " + miscded[k]['amount'];
        //     }

        //   }
        //   if (str2 != "") {
        //     arr.push({ text: str2, fontSize: 8 });
        //   }
        //   else {
        //     arr.push(0.00);
        //   }

        // arr.push(obj['net']);

        dd.content[dd.content.length - 1].table.body.push(arr);
      }
      dd.content[dd.content.length - 1].table.body.push(Object.values(total_obj));

      // var obj = data['total'];
      // var arr = [];
      // var str = "Section : " + sections[i] + "\n";
      // str += "Total Employees : " + emps.length;

      // arr.push(str);
      // arr.push(obj['BASIC']);
      // arr.push(obj['DEP']);
      // arr.push(obj['DA']);
      // arr.push(obj['MA']);
      // arr.push(obj['VA']);
      // arr.push(obj['HRA']);
      // arr.push(obj['WA']);
      // var miscpay = obj['miscpay'];
      // var miscpayObj = {};
      // for (var k = 0; k < miscpay.length; k++) {
      //   if (miscpayObj[miscpay[k]['code']] == undefined) {
      //     miscpayObj[miscpay[k]['code']] = 0;
      //   }
      //   miscpayObj[miscpay[k]['code']] += miscpay[k]['amount'];
      // }
      // var str2 = "";
      // var keys = Object.keys(miscpayObj);
      // for (var k = 0; k < keys.length; k++) {
      //   if (k == 0) {
      //     str2 += keys[k] + " - " + miscpayObj[keys[k]];
      //   } else {
      //     str2 += "\n" + keys[k] + " - " + miscpayObj[keys[k]];;
      //   }

      // }
      // if (str2 != "") {
      //   arr.push({ text: str2, fontSize: 8 });
      // }
      // else {
      //   arr.push(0.00);
      // }
      // arr.push(obj['total']);
      // var licstr = obj['LIC1'] + "\n" + obj['LIC2'] + "\n" + obj['LIC3'] + "\n" + obj['LIC4'] + "\n" + obj['LIC5'] + "\n" + obj['LIC6'] + "\n" + obj['LIC7'];

      // arr.push(licstr);
      // arr.push(obj['PF']);
      // arr.push(obj['NPS']);
      // arr.push(obj['GIS']);
      // arr.push(obj['IT']);
      // arr.push(obj['HRR']);
      // arr.push(obj['VD']);
      // arr.push(obj['VADV']);
      // var bldstr = obj['BLDADV1'] + "\n" + obj['BLDADV2'] + "\n" + obj['BLDADV3']
      // arr.push(bldstr);
      // var pfstr = obj['PFADV'] + "\n" + obj['PFADV1'] + "\n" + obj['PFADV2']
      // arr.push(pfstr);
      // arr.push(obj['BADV']);
      // arr.push(obj['EWF']);
      // var miscded = obj['miscded'];
      // var miscdedObj = {};
      // for (var k = 0; k < miscded.length; k++) {
      //   if (miscdedObj[miscded[k]['code']] == undefined) {
      //     miscdedObj[miscded[k]['code']] = 0;
      //   }
      //   miscdedObj[miscded[k]['code']] += miscded[k]['amount'];
      // }
      // var str2 = "";
      // var keys = Object.keys(miscdedObj);
      // for (var k = 0; k < keys.length; k++) {
      //   if (k == 0) {
      //     str2 += keys[k] + " - " + miscdedObj[keys[k]];
      //   } else {
      //     str2 += "\n" + keys[k] + " - " + miscdedObj[keys[k]];;
      //   }

      // }
      // if (str2 != "") {
      //   arr.push({ text: str2, fontSize: 8 });
      // }
      // else {
      //   arr.push(0.00);
      // }

      // arr.push(obj['net']);

      // dd.content[dd.content.length - 1].table.body.push(arr);

    }
    // var totalText = { text: 'Grand Total' + "\nTotal Employees : " + count, fontSize: 10, bold: true };

    // var obj = grand;
    // var arr = []
    // arr.push(totalText);
    // arr.push(obj['BASIC']);
    // arr.push(obj['DEP']);
    // arr.push(obj['DA']);
    // arr.push(obj['MA']);
    // arr.push(obj['VA']);
    // arr.push(obj['HRA']);
    // arr.push(obj['WA']);
    // var miscpay = obj['miscpay'];
    // var miscpayObj = {};
    // for (var k = 0; k < miscpay.length; k++) {
    //   if (miscpayObj[miscpay[k]['code']] == undefined) {
    //     miscpayObj[miscpay[k]['code']] = 0;
    //   }
    //   miscpayObj[miscpay[k]['code']] += miscpay[k]['amount'];
    // }
    // var str2 = "";
    // var keys = Object.keys(miscpayObj);
    // for (var k = 0; k < keys.length; k++) {
    //   if (k == 0) {
    //     str2 += keys[k] + " - " + miscpayObj[keys[k]];
    //   } else {
    //     str2 += "\n" + keys[k] + " - " + miscpayObj[keys[k]];;
    //   }

    // }
    // if (str2 != "") {
    //   arr.push({ text: str2, fontSize: 8 });
    // }
    // else {
    //   arr.push(0.00);
    // }
    // arr.push({ text: obj['total'], bold: true });
    // var amt = obj['LIC1'] + obj['LIC2'] + obj['LIC3'] + obj['LIC4'] + obj['LIC5'] + obj['LIC6'] + obj['LIC7']
    // //var licstr=obj['LIC1']+"\n"+obj['LIC2']+"\n"+obj['LIC3']+"\n"+obj['LIC4']+"\n"+obj['LIC5']+"\n"+obj['LIC6']+"\n"+obj['LIC7'];
    // //var licstr={ text: 'Section : '+sections[i]+"\nTotal Employees : "+emps.length, fontSize: 8};
    // arr.push(amt);
    // arr.push(obj['PF']);
    // arr.push(obj['NPS']);

    // arr.push(obj['GIS']);
    // arr.push(obj['IT']);
    // arr.push(obj['HRR']);
    // arr.push(obj['VD']);
    // arr.push(obj['VADV']);
    // amt = obj['BLDADV1'] + obj['BLDADV2'] + obj['BLDADV3'];
    // //var bldstr=obj['BLDADV1']+"\n"+obj['BLDADV2']+"\n"+obj['BLDADV3']
    // arr.push(amt);
    // amt = obj['PFADV'] + obj['PFADV1'] + obj['PFADV2'];
    // //var pfstr=obj['PFADV']+"\n"+obj['PFADV1']+"\n"+obj['PFADV2']
    // arr.push(amt);
    // arr.push(obj['BADV']);
    // arr.push(obj['EWF']);
    // var miscded = obj['miscded'];
    // var miscdedObj = {};
    // for (var k = 0; k < miscded.length; k++) {
    //   if (miscdedObj[miscded[k]['code']] == undefined) {
    //     miscdedObj[miscded[k]['code']] = 0;
    //   }
    //   miscdedObj[miscded[k]['code']] += miscded[k]['amount'];
    // }
    // var str2 = "";
    // var keys = Object.keys(miscdedObj);
    // for (var k = 0; k < keys.length; k++) {
    //   if (k == 0) {
    //     str2 += keys[k] + " - " + miscdedObj[keys[k]];
    //   } else {
    //     str2 += "\n" + keys[k] + " - " + miscdedObj[keys[k]];;
    //   }

    // }
    // if (str2 != "") {
    //   arr.push({ text: str2, fontSize: 8 });
    // }
    // else {
    //   arr.push(0.00);
    // }

    // arr.push({ text: obj['net'], bold: true });

    // dd.content[dd.content.length - 1].table.body.push(arr);
    dd.content.push("\n\n");
    var sign1 = {
      columns: [
        {
          width: '*',
          text: 'PREPARED BY:',
          bold: true
        },

        {
          width: '*',
          text: 'CHECKED BY:',
          bold: true
        },
        {
          width: '*',
          text: 'SIGNED BY:',
          bold: true
        }


      ],

    }
    dd.content.push("\n\n\n");
    dd.content.push(sign1);
    dd.content.push("\n\n");
    dd.content.push({ text: "CERTIFIED:", bold: true })
    dd.content.push("\n\n");
    dd.content.push({ text: "1. That I have satisfied myself that all the salaries included in the bills drawn in the month of " + this.monthObj[month] + "/" + fin_year + " [the last preceding month] with the exception of those detailed below of which total has been refunded by deduction from the bill has been distributed to the proper persons and their receipts have been taken in acquittance rolls field in my office with reciept-stamp dully cancelled for every payment in access of Rs. 20 and that all leaves and promotions etc have been in the service book of the official concerned." })
    dd.content.push("\n");
    dd.content.push({ text: "2. That all persons for whom pay has been drawn in this bill have actually been entertained during the month." })
    dd.content.push("\n");

    dd.content.push({ text: "3. That all the persons for whom house-rent allowance has been shown in this bill actually occupied a rented house for which they paid rent as shown in this bill and are entitled to the allowance under the standing instructions." })
    dd.content.push("\n");

    dd.content.push({ text: "4. That all the persons in respect of whom conveyance allowance has been drawn in the bill have satisfied me that they have actually maintained the conveyance in a workable condition and have been using them." })
    dd.content.push("\n");

    dd.content.push({ text: "5. That the bill has been checked with the sanctioned in the scale register." })
    dd.content.push("\n");

    dd.content.push({ text: "Date :                                                    Signature Of Drawing Officer:" })
    dd.content.push("\n");

    dd.content.push({ text: "Pay Rs. ....................................." })




    pdfMake.createPdf(dd).download(file_name);
  }
  async getAllBillshort(element:any, type:any) {
    this.currentBillObj = { header: {}, allEmployees: [], data: {}, sums: {} };

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['bill_id'] = element['bill_id'];
    this.spinner.show();

    var resp1 = await this.payableService.getAllComponent(JSON.stringify(obj));
    if (resp1['error'] == false) {
      this.all_component = []
      this.all_component = resp1.data;
    } else {
      return;
    }

    let obb :any= new Object();
    obb['emp_id'] = '';
    obb['emp_name'] = '';
    obb['designation_code'] = '';
    obb['grade_pay_code'] = '';
    obb['pay_band'] = '';
    obb['sal_acc'] = '';
    obb['pf'] = '';
    obb['pf_ifsc'] = '';
    // emp_id: '', emp_name: '', designation_code: '', grade_pay_code: '', pay_band: '', sal_acc: '', pf: '', pf_ifsc: '',
    // 'BASIC': 0.00, 'DA': 0.00, 'DEP': 0.00, 'HRA': 0.00, 'MA': 0.00, 'VA': 0.00, 'WA': 0.00, 'miscpay': [], 'LIC1': 0.00, LIC2: 0.00, LIC3: 0.00, LIC4: 0.00, LIC5: 0.00, LIC6: 0.00, LIC7: 0.00, PF: 0.00, 'NPS': 0.00, GIS: 0.00, IT: 0.00, HRR: 0.00, VD: 0.00, VADV: 0.00,
    // BLDADV1: 0.00, BLDADV2: 0.00, BLDADV3: 0.00, PFADV: 0.00, PFADV1: 0.00, PFADV2: 0.00, BADV: 0.00,
    //  EWF: 0.00, miscded: [], total: 0.00, net: 0.00
    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'PAY') {
        obb[this.all_component[i]['component_code']] = 0
      }
    }

    obb['gross pay'] = 0
    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'DED') {
        obb[this.all_component[i]['component_code']] = 0
      }
    }
    obb['total'] = 0
    obb['net'] = 0;
    console.log(obb)
    var resp = await this.payableService.getAllBill(JSON.stringify(obj));
    if (resp['error'] == false) {
      var billObj:any = {};
      var header = "";
      var dt = resp['data'];
      console.log(dt)
      if (dt.length > 0) {
        header = dt[0];
      }
      var grand :any= undefined;
      var month :any= "";
      var fin_year:any = "";
      var fixedarr:any = [];

      for (var i = 0; i < dt.length; i++) {
        //header = dt[0];
        if (billObj[dt[i]['section_code']] == undefined) {
          month = dt[i]['month'];
          fin_year = dt[i]['fin_year'];
          billObj[dt[i]['section_code']] = {};
          billObj[dt[i]['section_code']]['data'] = {};//{'BASIC':0.00,'DA':0.00,'DEP':0.00,'HRA':0.00,'MA':0.00,'VA':0.00,'WA':0.00,'miscpay':[],'LIC1':0.00,LIC2:0.00,LIC3:0.00,LIC4:0.00,LIC5:0.00,LIC6:0.00,LIC7:0.00,PF:0.00,GIS:0.00,IT:0.00,HRR:0.00,VD:0.00,VADV:0.00,BLDADV1:0.00,BLDADV2:0.00,BLDADV3:0.00,PFADV:0.00,PFADV1:0.00,PFADV2:0.00,BADV:0.00,EWF:0.00,miscded:[]};
          billObj[dt[i]['section_code']]['total'] = { 'BASIC': 0.00, 'DA': 0.00, 'DEP': 0.00, 'HRA': 0.00, 'MA': 0.00, 'VA': 0.00, 'WA': 0.00, 'miscpay': [], 'LIC1': 0.00, LIC2: 0.00, LIC3: 0.00, LIC4: 0.00, LIC5: 0.00, LIC6: 0.00, LIC7: 0.00, PF: 0.00, NPS: 0.00, GIS: 0.00, IT: 0.00, HRR: 0.00, VD: 0.00, VADV: 0.00, BLDADV1: 0.00, BLDADV2: 0.00, BLDADV3: 0.00, PFADV: 0.00, PFADV1: 0.00, PFADV2: 0.00, BADV: 0.00, EWF: 0.00, miscded: [], total: 0.00, net: 0.00 };
          if (grand == undefined) {
            grand = { 'BASIC': 0.00, 'DA': 0.00, 'DEP': 0.00, 'HRA': 0.00, 'MA': 0.00, 'VA': 0.00, 'WA': 0.00, 'miscpay': [], 'LIC1': 0.00, LIC2: 0.00, LIC3: 0.00, LIC4: 0.00, LIC5: 0.00, LIC6: 0.00, LIC7: 0.00, PF: 0.00, NPS: 0.00, GIS: 0.00, IT: 0.00, HRR: 0.00, VD: 0.00, VADV: 0.00, BLDADV1: 0.00, BLDADV2: 0.00, BLDADV3: 0.00, PFADV: 0.00, PFADV1: 0.00, PFADV2: 0.00, BADV: 0.00, EWF: 0.00, miscded: [], total: 0.00, net: 0.00 };
          }
        }
        if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']] == undefined) {
          fixedarr = []
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']] = Object.assign({}, obb);
          // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']] = { emp_id: '', emp_name: '', designation_code: '', grade_pay_code: '', pay_band: '', sal_acc: '', pf: '', pf_ifsc: '', 'BASIC': 0.00, 'DA': 0.00, 'DEP': 0.00, 'HRA': 0.00, 'MA': 0.00, 'VA': 0.00, 'WA': 0.00, 'miscpay': [], 'LIC1': 0.00, LIC2: 0.00, LIC3: 0.00, LIC4: 0.00, LIC5: 0.00, LIC6: 0.00, LIC7: 0.00, PF: 0.00, 'NPS': 0.00, GIS: 0.00, IT: 0.00, HRR: 0.00, VD: 0.00, VADV: 0.00, BLDADV1: 0.00, BLDADV2: 0.00, BLDADV3: 0.00, PFADV: 0.00, PFADV1: 0.00, PFADV2: 0.00, BADV: 0.00, EWF: 0.00, miscded: [], total: 0.00, net: 0.00 };
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['emp_id'] = (dt[i]['emp_id']);
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['emp_name'] = dt[i]['emp_name']
          // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['designation_code'] = this.salaryObj.employement_info[dt[i].emp_id]['designation_code'];;
          // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['grade_pay_code'] = "GP " + this.salaryObj.employement_info[dt[i].emp_id]['grade_pay_code'];
          // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pay_band'] = 'PB ' + '(' + this.salaryObj.employement_info[dt[i].emp_id]['pay_scale_code'] + ')';
          // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['sal_acc'] = this.salaryObj.emp_info[dt[i].emp_id]['acct_no'];
          // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pf'] = this.salaryObj.emp_info[dt[i].emp_id]['pf_acct_no'];
          // billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pf_ifsc'] = this.salaryObj.emp_info[dt[i].emp_id]['pf_ifsc_code'];
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['designation_code'] = ''
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['grade_pay_code'] = ''
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pay_band'] = ''
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['sal_acc'] = ''
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pf'] = ''
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pf_ifsc'] = ''
        }

        if (dt[i]['pay_code'] == 'PAY') {
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['gross pay'] += dt[i]['pay_component_amt'];
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['net'] += dt[i]['pay_component_amt'];
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']
          // billObj[dt[i]['section_code']]['total']['total'] += dt[i]['pay_component_amt'];
          // billObj[dt[i]['section_code']]['total']['net'] += dt[i]['pay_component_amt'];
          // grand['total'] += dt[i]['pay_component_amt'];
          // grand['net'] += dt[i]['pay_component_amt'];

          // if (!fixedarr.includes(dt[i]['pay_component_code'])) {
          //   fixedarr.push(dt[i]['pay_component_code'])
          //   if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] != undefined) {
          //     // billObj[dt[i]['section_code']]['total'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
          //     // grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
          //   } else {
          //     billObj[dt[i]['section_code']]['total']['miscpay'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
          //     grand['miscpay'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });

          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['miscpay'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
          //   }
          // } else {
          //   billObj[dt[i]['section_code']]['total'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

          //   if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"] == undefined) {
          //     var temp = grand[dt[i]['pay_component_code']]
          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"] = []
          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']])
          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(dt[i]['pay_component_amt'])

          //     grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

          //   } else {
          //     grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(dt[i]['pay_component_amt'])
          //   }

          // }
        } else {
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['total'] += dt[i]['pay_component_amt'];
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['net'] -= dt[i]['pay_component_amt'];
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt']

          // if (!fixedarr.includes(dt[i]['pay_component_code'])) {
          //   fixedarr.push(dt[i]['pay_component_code'])
          //   billObj[dt[i]['section_code']]['total']['net'] -= dt[i]['pay_component_amt'];
          //   grand['net'] -= dt[i]['pay_component_amt'];
          //   billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['net'] -= dt[i]['pay_component_amt'];
          //   if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] != undefined) {
          //     billObj[dt[i]['section_code']]['total'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
          //     grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
          //   } else {
          //     billObj[dt[i]['section_code']]['total']['miscded'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
          //     grand['miscded'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });

          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['miscded'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
          //   }
          // } else {
          //   billObj[dt[i]['section_code']]['total'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
          //   billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['net'] -= dt[i]['pay_component_amt'];
          //   if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"] == undefined) {
          //     var temp = grand[dt[i]['pay_component_code']]
          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"] = []
          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']])
          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(dt[i]['pay_component_amt'])

          //     grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

          //   } else {
          //     grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

          //     billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code'] + "_arr"].push(dt[i]['pay_component_amt'])
          //   }

          // }
        }


      }
      if (type == 'bill') {
        console.log(fixedarr)
        console.log(billObj, header, grand, month, fin_year)
        this.printshort(billObj, header, grand, month, fin_year);

      }
      else {
        // this.print1(billObj, header, grand, month, fin_year);
      }
      this.spinner.hide()
    } else {
      this.spinner.hide();
      swal.fire("Error", "Error while printing pay bill", 'error')
    }
  }
}

