<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Create Roles</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>&nbsp;All Roles List</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-7-3" data-toggle="tab"><i class="ti-settings"></i> Create
                                User Role
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-7-4" data-toggle="tab"><i class="ti-settings"></i> Update
                                User Role
                            </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-7-1">
                            <br>
                            <div class=" table-container ">

                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter( $any($event.target).value)" placeholder="Filter">
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="example-container">
                                    <table style="width: 100%" mat-table [dataSource]="dataSource" matSort>
                                        <ng-container matColumnDef="role_name" sticky>
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Name</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element">
                                                {{element.role_name}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="role_cd">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>Role Code</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.role_cd}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="update">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>Role Description</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.role_desc}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="access">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>Access</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.access}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="res_cd">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>Resource</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.res_value}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="delete">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>Delete</b></th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                <button class="btn btn-primary" (click)="open_update(element)">Update</button>
                                                <button class="btn btn-danger" (click)="deleteRole(element,i)">Delete</button>


                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                                </div>
                            </div>
                        </div>



                        <div class="tab-pane fade" id="tab-7-3">
                            <br>

                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Code : <span style="color: red;">*</span> </h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="Enter Role Code" class="form-control" [(ngModel)]="Obj['role_cd']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Name : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="Enter Name" class="form-control" [(ngModel)]="Obj['role_name']">


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Description : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="Obj['role_desc']" placeholder="Enter Role Description" class="form-control"></textarea>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Select Access : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Select Access" [items]="access" bindLabel="value" bindValue="code" [selectableGroup]="true" [selectableGroupAsModel]="false" aria-placeholder="Select Access" [closeOnSelect]="false" name="hierarchy" [(ngModel)]="Obj['access']">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-left">
                                    <h6 style="margin-left: 2%;">Select Resource :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">
                                    </ng-select>
                                </div>
                                <div class="col-4">
                                   <!--  <mat-checkbox [(ngModel)]="checkBoxValue" [ngModelOptions]="{standalone: true}" (click)="checkCheckBoxvalue()">Select All Resource
                                    </mat-checkbox> -->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">Submit</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-7-4">
                            <br>

                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Code : <span style="color: red;">*</span> </h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="Enter Role Code" class="form-control" [(ngModel)]="Obj['role_cd']" disabled>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Name : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="Enter Name" class="form-control" [(ngModel)]="Obj['role_name']">


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Description : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="Obj['role_desc']" placeholder="Enter Role Description" class="form-control"></textarea>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Select Access : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Select Access " [items]="access" bindLabel="value" bindValue="code" [selectableGroup]="true" [selectableGroupAsModel]="false" aria-placeholder="Select Access" [closeOnSelect]="false" name="hierarchy" [(ngModel)]="Obj['access']">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-left">
                                    <h6 style="margin-left: 2%;">Select Resource :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4" *ngIf="flag == false">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">
                                    </ng-select>
                                </div>
                                <div class="col-4" *ngIf="flag == true">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>Loading....</ngx-spinner>

<div class="modal" id="createRole">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Create Role</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body" style="padding-left: 15%">
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Select
                            Components:</b></label>
                    <div class="col-lg-8">

                        <ng-select [items]="allComp" bindLabel="interface_name" bindValue="id" [multiple]="true" placeholder="Select Components" [(ngModel)]="selectedComp" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">



                        </ng-select>

                    </div>
                </div>
                <br>
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Whats is Role
                            Name:</b></label>
                    <div class="col-lg-8">

                        <input class="form-control" [(ngModel)]="role_name">
                    </div>
                </div>
                <br>


            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-info" (click)="addNewRole()">Add</button>
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<ngx-spinner type="ball-atom">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

<div class="modal" id="updateRole">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title">Update Role</h4>
            </div>


            <div class="modal-body" style="padding-left: 15%">
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Selected
                            Components:</b></label>
                    <div class="col-lg-8">

                        <ng-select [items]="allComp" bindLabel="interface_name" bindValue="id" [multiple]="true" placeholder="Select Components" [(ngModel)]="selectedComp" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">



                        </ng-select>

                    </div>
                </div>
                <br>
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b> Role
                            Name:</b></label>
                    <div class="col-lg-8">

                        <input class="form-control" [(ngModel)]="role_name">
                    </div>
                </div>
                <br>


            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" (click)="updateRole()">Update</button>
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>

        </div>
    </div>
</div>