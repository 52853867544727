import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import {MainService} from './main.service';
@Injectable({
  providedIn: 'root'
})
export class TransferPropertyService {

    httpUrl;
    constructor(private http: HttpClient, private main: MainService) {
      this.httpUrl = this.main.httpUrl+"/property/mutation";
     }

     async getAllotedScheme(obj:any){
      console.log("obj from service", obj)
      const resp = this.http.get<any>(this.httpUrl + '/getallOffer' + obj).toPromise().then(res => {
        return res
      });
      return resp
    }
    async getpartyData(obj:any){
       console.log("obj from service", obj)
      const resp = this.http.get<any>(this.httpUrl + '/getPratyData' + obj).toPromise().then(res => {
        return res
      });
      return resp
    }


}


