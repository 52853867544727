<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Employee Attendance Info -->
                        {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code1']}}
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refress()"><i
                                    class="ti-bar-chart"></i>
                                <!-- Employee Attendance Detail -->
                                {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code2']}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-settings"></i>
                                <!-- Add Employee Attendance -->
                                {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code3']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-announcement"></i>
                                <!-- Update Employee Attendance -->
                                {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code4']}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab"><i class="ti-announcement"></i>
                                <!-- Upload Employee Attendance -->
                                {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code5']}}</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Calendar Year : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code6']}}<span
                                        style="color:red;">*</span>
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0030']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="getObj['year']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>



                                </div>



                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Month : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code7']}}<span
                                        style="color:red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="getObj['month']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Section : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code8']}}<span
                                        style="color:red;">*</span>
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0031']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="getObj['section_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getAllAttendance()">
                                        <!-- SUBMIT -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code9']}}
                                    </button>
                                </div>
                            </div>
                            <br>
                            <hr>
                            <br>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)"
                                    placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code10']}}">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            <!-- Employee ID -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Attendance_code11']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{element.tempid}}</td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>

                                            {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ idToNameObj[element.emp_id]}}
                                        </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Employee Name -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Attendance_code12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="total_days">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Total Days -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Attendance_code13']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.total_days}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="working_days">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Total Working Days -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Attendance_code14']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.working_days}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="present_days">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Number Of Days Present -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Attendance_code15']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.present_days}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="absent_days">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Number of Days Absent -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Attendance_code16']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.absent_days}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="leave_days">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Number of Leave -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Attendance_code17']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.leave_days}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Action -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Attendance_code18']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-warning" (click)="openUpdate(element)">
                                                <!-- UPDATE -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Attendance_code19']}}
                                            </button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code20']}}<span
                                        style="color: red;">*</span>

                                </div>
                                <div class="col-4">

                                    <!-- <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id"
                                        [multiple]="false" placeholder="" [(ngModel)]="attendanceObj['emp_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name" (change)="refreshalldata()">
                                    </ng-select> -->
                                    <ng-select [items]="activeEmpArr" bindLabel="emp_name" bindValue="emp_id"
                                        [multiple]="false" placeholder="" [(ngModel)]="attendanceObj['emp_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name" (change)="refreshalldata()">
                                    </ng-select>



                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Calendar Year : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code6']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0030']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="attendanceObj['year']" [selectableGroup]="true"
                                        (ngModelChange)="changeYear()" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>



                                </div>



                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Month : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code7']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="attendanceObj['month']" [selectableGroup]="true"
                                        (ngModelChange)="checkPresenty()" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Section : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code8']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0031']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="attendanceObj['section_code']" [selectableGroup]="true"
                                        (ngModelChange)="checkAllReadyAdd()" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Working Days : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code21']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <!--shweta <input type="number" class="form-control" [(ngModel)]="attendanceObj['working_days']"  (change)="calabsday()"> -->
                                    <input type="number" class="form-control"
                                        [(ngModel)]="attendanceObj['working_days']" disabled>

                                </div>
                            </div>
                            <br>
                            <!-- Avinash  -->
                            <div class="row" *ngIf="total_p==true">
                                <div class="col-3 text-right"></div>

                                <div class="col-5" style="color: green; margin-left: 4rem; ">
                                    <div style="text-align: center;">The total attendance of the employee for <span
                                            style="color: brown;"><b>{{attendanceObj['month1']}}</b> </span> month of
                                        <span style="color: brown;"><b>{{attendanceObj['year']}}</b></span> is <span
                                            style="color: brown;">
                                            <b>{{attendanceObj['present_days']}}</b></span> <br> <span
                                            style="color: red;">Are You Sure ?</span>
                                    </div>

                                    <div style="text-align: center;"><button class="btn btn-success"
                                            (click)="agreeHr()"> Yes</button> &nbsp;&nbsp;&nbsp; <button
                                            class="btn btn-primary" (click)="disAgreeHr(element)"> No</button>
                                    </div>

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Total Present Days : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code22']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control"
                                        [(ngModel)]="attendanceObj['present_days']" (change)="calabsday()" disabled>
                                    <!-- <input type="number" class="form-control" [(ngModel)]="attendanceObj['present_days']"
                                        (change)="calabsday()" [disabled]="attendanceObj['present_days']==0 ||  agreeHr_flag==true" > -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Total Absent Days : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code23']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="attendanceObj['absent_days']" disabled>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Total Leave Days : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code24']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="attendanceObj['leave_days']" disabled>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitAttendanceInfo()"
                                        [disabled]="total_p==true">
                                        <!-- Submit --> {{mainService.allLableShowObjHR[mainService.language_cd+'
                                        Attendance_code9']}}
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code20']}}
                                    <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id"
                                        [multiple]="false" placeholder="" [(ngModel)]="updateObj['emp_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name" disabled>
                                    </ng-select>
                                </div>



                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Calender Year : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code6']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0030']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateObj['year']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>



                                </div>



                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Month : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code7']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateObj['month'] " [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Section : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code8']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0031']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateObj['section_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Total Working Days : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code14']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="updateObj['working_days']" disabled>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Total Present Days : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code22']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="updateObj['present_days']"
                                        (change)="updcalabsday()">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Total Absent Days : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code23']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="updateObj['absent_days']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Total Leave Days : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code24']}}<span
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="updateObj['leave_days']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateAttendanceInfo()">
                                        <!-- Submit -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code9']}}
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <h6>
                                        <!--Please upload the CSV File with columns Employee ID, Year, Month, Present Days, Absent Days, Leave Days, Total Days-->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+' Attendance_code25']}}
                                    </h6>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6" style="margin-left: 20%">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                        class="form-control" style="border-color: black"
                                        (change)="onFileUpload($event,file.files)">
                                </div>
                                <div class="col-3">
                                    <button type="button" [disabled]="!uploader.getNotUploadedItems().length"
                                        class="btn btn-info" (click)='upload()'><i class="fa fa-upload"
                                            aria-hidden="true">&nbsp;</i>
                                        <!-- Upload -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'
                                        Attendance_code26']}}</button>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>

<div class="modal" id="disAgreeHrList">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-light" >
                <h4 class="modal-title">
                    <div class="row">

                        <div class="col-12 text-center">
                            <b>If The User Is Present You Enter
                                <span style ="color: red;">1 </span>
                                . If The User Is Absent You Enter
                                <span style ="color: red;">0 </span> .</b>

                        </div>


                </div>
                </h4>

            </div>

            <div class="modal-body">
                <mat-form-field style="margin-left: 2%">
                    <input matInput (keyup)="applyFilter2($any($event.target).value)" placeholder="Filter">
                </mat-form-field>
                <div class=" example-container">
                    <table mat-table [dataSource]="datasource2" matSort>
                        <ng-container matColumnDef="s.n">
                            <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                <!-- Employee ID -->
                                S.No.
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                {{i+1}}</td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                Date

                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                {{ element.date }} </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                Status

                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                <!-- <input class="text-center" type="number" [(ngModel)]="element.present " [disabled]="element.reason=='SUNDAY' || element.reason=='SATURDAY'">  </td> -->
                                <!-- <input class="text-center" [(ngModel)]="element.present "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  type="number"  maxlength="1"
                  onkeydown="return /[0-1]/i.test(event.key)"  >  -->
                                <input class="text-center" [(ngModel)]="element.present"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    type="number" maxlength="1" (keypress)="validateKey($event)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="reason">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                Holiday Reason

                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                <!-- <input class="text-center" type="number" [(ngModel)]="element.present ">  -->
                                {{element.reason}}
                            </td>
                        </ng-container>



                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                        </tr>
                    </table>
                    <!-- <mat-paginator #paginator2 [pageSizeOptions]="[31]" showFirstLastButtons></mat-paginator> -->
                </div>
            </div>
            <div class="modal-footer " style="justify-content: center;">
                <button type="button" class="btn btn-primary" (click)="hrAttendanceApp()">Submit</button>
                <button type="button" class="btn btn-danger"
                    data-dismiss="modal">{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves24']}}</button>
            </div>
        </div>
    </div>
</div>
