<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Deduction Report</div>
                </div>
                <div class="ibox-body">

                    <div class="tab-pane fade show active" id="tab-1">
                        <br>
                        <form name="form" (ngSubmit)="f2.form.valid && getreportoutput()" #f2="ngForm">
                            <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                <div class="col-3 text-left">
                                    <h6>From Date : <span style="color: red;">*</span></h6>
                                </div>
                                <!-- <div class="col-3"> -->
                                <!-- <input type="date" class="form-control" [(ngModel)]="obj['from_dt']"> -->

                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="obj['from_dt']" name="from_dt"
                                        #fromDT="ngModel" [ngClass]="{ 'is-invalid': f2.submitted && fromDT.invalid }"
                                        required>
                                    <!--------------------------------------------- validation Start ------------------- -->

                                    <!-- border color green -->
                                    <div [class.border_green]="fromDT.dirty && fromDT.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f2.submitted && fromDT.invalid" class="error-message">
                                        <div *ngIf="fromDT.errors?.['required']">Please Fill From Date </div>
                                    </div>

                                    <!--------------------------------------------- validation End ------------------- -->

                                </div>
                                <!-- </div> -->
                                <div class="col-3 text-left">
                                    <h6>To Date : <span style="color: red;">*</span></h6>
                                </div>
                                <!-- <div class="col-3"> -->
                                <!-- <input type="date" class="form-control" [(ngModel)]="obj['to_dt']"> -->


                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="obj['to_dt']" name="toDT"
                                        #toDT="ngModel" [ngClass]="{ 'is-invalid': f2.submitted && toDT.invalid }"
                                        required>

                                    <!--------------------------------------------- validation Start ------------------- -->

                                    <!-- border color green -->
                                    <div [class.border_green]="toDT.dirty && toDT.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f2.submitted && toDT.invalid" class="error-message">
                                        <div *ngIf="toDT.errors?.['required']">Please Fill To Date </div>
                                    </div>

                                      <!-- If from date is greater than to date  -->
                                      <div *ngIf="fromDT.value > toDT.value" class="error-message">
                                        Please Select Correct Date
                                    </div>
                                    <!--------------------------------------------- validation End ------------------- -->
                                </div>
                                <!-- </div> -->
                            </div>
                            <br>
                            <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                <div class="col-3 text-left">
                                    <h6>Select Party :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                                        [multiple]="false" placeholder="Select Party" [(ngModel)]="obj['le_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="lvl1_code">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-left">
                                    <h6>Select Deduction : <span style="color: red;">*</span></h6>
                                </div>
                                <!-- <div class="col-3">
                                    <ng-select [items]="deduction_type" placeholder="Select Deduction"
                                        bindLabel="ded_code" bindValue="ded_code" [multiple]="false"
                                        [(ngModel)]="obj['ded_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="ded_code">
                                    </ng-select>
                                </div> -->
                                <div class="col-3">
                                    <ng-select [items]="deduction_type" bindLabel="ded_code" bindValue="ded_code"
                                        [multiple]="false" placeholder="Select Deduction" [(ngModel)]="obj['ded_code']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="ded_code" #ded_code="ngModel"
                                        [ngClass]="{ 'is-invalid': f2.submitted && ded_code.invalid }" required>
                                    </ng-select>

                                    <!--------------------------------------------- validation Start ------------------- -->

                                    <!-- border color green -->
                                    <div [class.selection-box]="ded_code.dirty && ded_code.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f2.submitted && ded_code.invalid" class="error-message">
                                        <div *ngIf="ded_code.errors?.['required']">Please Select Dedution Type</div>
                                    </div>

                                    <!--------------------------------------------- validation End ------------------- -->


                                </div>

                            </div>
                            <br>

                            <div class="col-6 text-right">

                                <!-- <button class="btn btn-primary" (click)="getreportoutput()">Submit</button> -->
                                <button class="btn btn-primary" type="submit">Submit</button>
                                <!-- <button class="btn btn-success d-none" type="reset" id="output2">Reset</button> -->
                            </div>

                        </form>

                        <br>
                        <hr>

                        <div class="row">
                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="print()">Print</button> &nbsp;&nbsp;

                                <button class="btn btn-success" (click)="export()">Export</button> &nbsp;&nbsp;
                            </div>
                        </div>

                        <br>
                        <div id="e">
                            <!-- <div class="row"> -->
                            <div class="row">
                                <div class="col-12 text-center">
                                    <h6>{{acount_name}}</h6>
                                </div>

                            </div>
                            <br>
                            <!-- <div class="row">
                                    <div class="col-12 text-center">
                                        Equity ({{obj['acc_desc']}})
                                    </div>
                                </div>
                                <br> -->
                            <div class="row">
                                <!--  <div class="col-2 text-right">
                                        <h6>Financial Year :</h6>
                                    </div>
                                    <div class="col-2">{{res_obj['fin_year']}}</div> -->
                                <div class="col-2 text-center">
                                    <h6>From :</h6>
                                </div>
                                <div class="col-4 text-center "  *ngIf="obj['from_dt']">
                                    {{mainService.dateFormatChange(obj['from_dt'])}}</div>
                                <div class="col-2 text-right">
                                    <h6>To :</h6>
                                </div>
                                <div class="col-4 text-center" *ngIf="obj['to_dt']">
                                    {{mainService.dateFormatChange(obj['to_dt'])}}
                                </div>

                            </div>
                            <br>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>SNO</th>
                                        <th>Date of Deduction</th>
                                        <th>Party Name</th>
                                        <th>Type of Deduction</th>
                                        <th>PAN of Party</th>
                                        <th>Gross Amount (Event Value)</th>
                                        <th>Deduction Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of reportdata; let i= index">
                                        <td>{{i+1}}</td>
                                        <td>{{item['ded_date'] | date:"dd/MM/yyyy"}}</td>
                                        <td>{{item['party_name']}}</td>
                                        <td style="text-align:right">{{item['ded_code']}}</td>
                                        <td style="text-align:right">{{item['pan']}}</td>
                                        <td style="text-align:right">{{item['amount']}}</td>
                                        <td style="text-align:right">{{item['deduction_amount']}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner>
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
