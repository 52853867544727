import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import {MainService} from '../../service/main.service';
// import { analyzeAndValidateNgModules } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})

export class InceptionService {
  httpUrl;
  constructor(private http: HttpClient, private main: MainService) { 
    this.httpUrl = this.main.httpUrl;
  }
  async createInception(obj:any)
  {
  
const res = await this.http.post<any>(this.httpUrl + '/depricated/estimation/inception/insertinception', obj).toPromise().then(res => {
 return res;
})
return res;
  }


async getIncept(obj:any)
{
 const res=await this.http.get<any>(this.httpUrl + '/depricated/estimation/inception/getincept',obj).toPromise().then(res=>{
   return res;
 });
 return res;
}
async getrole(obj:any)
{
const res=await this.http.get<any>(this.httpUrl+ '/depricated/estimation/inception/getrole' + JSON.stringify(obj)).toPromise().then(res => {
 return res;
})
return res;
}


  async updateinception(obj:any)
  {
   const res= await this.http.post<any>(this.httpUrl + '/depricated/estimation/inception/updateinception', obj).toPromise().then(res => {
     return res;
   })
   return res;
  }

  async updateinctStatus(obj:any)
  {
   const res= await this.http.put<any>(this.httpUrl + '/depricated/estimation/inception/updateincStatus', obj).toPromise().then(res => {
     return res;
   })
   return res;
  }
  // async updateimage(obj:any)
  // {
  //  const res= await this.http.put<any>(this.httpUrl + '/depricated/estimation/inception/updateimage', obj).toPromise().then(res => {
  //    return res;
  //  })
  //  return res;
  // }

  async getupdateinc(obj:any) {
   const resp = await this.http.get<any>(this.httpUrl + '/depricated/estimation/inception/getupdateinc' + JSON.stringify(obj)).toPromise().then(res => {
     return res;
   })
   return resp;
 }
  async getinception(obj:any) {
   const resp = await this.http.get<any>(this.httpUrl + '/depricated/estimation/inception/getinception' + obj).toPromise().then(res => {
     return res;
   })
   return resp;
 }
  async getdocslist(obj:any) {
   const resp = await this.http.get<any>(this.httpUrl + '/depricated/estimation/inception/getdocslist' + obj).toPromise().then(res => {
     return res;
   })
   return resp;
 }
 async deleteincept(obj:any) {
   console.log("this is the obj",obj);
const res =await this.http.post<any>(this.httpUrl + '/depricated/estimation/inception/deleteincepti', obj).toPromise().then(res => {
 return res;
})
return res;
 }
 async deleteunuseimage(obj:any) {
const res =await this.http.post<any>(this.httpUrl + '/depricated/estimation/inception/deleteunuseimage', obj).toPromise().then(res => {
 return res;
})
return res;
 }
 async deleteimage(obj:any) {
const res =await this.http.post<any>(this.httpUrl + '/depricated/estimation/inception/deleteimage', obj).toPromise().then(res => {
 return res;
})
return res;
 }
 async  viewdocs(obj:any) {
  const resp = await this.http.post(this.httpUrl+'/depricated/estimation/inception/viewdocs', obj, { responseType: 'blob' }).toPromise().then(res => {
    return res;
  });
  //oc
//   if (resp) {
//     return resp;
// }
//ec
return resp;
}

}
