<div class="page-content fade-in-up">

    <div class="ibox">
        <div class="ibox-head">
            <div class="ibox-title">
                Welcome , {{erpUser.party_name}}
            </div>
        </div>
        <div>
            <!-- <h6>Complaint</h6> -->
            <div *ngIf="dashboard">
                <div class="row">
                    <div class="col-4">
                        <hr class="hrline" />
                    </div>
                    <div class="col-4 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px; margin-top:5px;">
                            Tracking Management System
                        </h6>
                    </div>
                    <div class="col-4">
                        <hr class="hrline" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <!-- <div class="ibox cardpurple color-white widget-stat" > -->
                        <div class="ibox color-white widget-stat" style="background-color: #e59704;">
                            <div class="ibox-body">


                                <h2 class="m-b-5 font-strong"></h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8">Total Zones </div>
                                    </div>
                                    <div class="col-2">
                                        <h6 class="m-b-8">{{zone_count}}</h6>
                                    </div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            (click)="viewZone()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <!-- <div class="ibox cardgreen color-white widget-stat"> -->
                        <div class="ibox color-white widget-stat" style="background-color:#27a844 ;">
                            <div class="ibox-body">
                                <h2 class="m-b-5 font-strong"></h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8"> Total Employees </div>
                                    </div>
                                    <div class="col-2">
                                        <h6 class="m-b-8">{{emp_count}}</h6>
                                    </div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            (click)="userdetail()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <!-- <div class="ibox cardRed color-white widget-stat"> -->
                        <!-- <div class="ibox color-white widget-stat" style="background-color: #5e9ea0;">
                            <div class="ibox-body">
                                <h2 class="m-b-5 font-strong"></h2>
                                <div class="row d-flex">
                                    <div class="col-7">
                                        <div class="m-b-8">Active Employees </div>
                                    </div>
                                    <div class="col-1"><h6>{{active_emp_count}}</h6></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                         (click)="activeuser()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>


                            </div>
                        </div> -->
                    </div>

                </div>
                <div class="row">

                    <!-- <div class="col-lg-4 col-md-6">

                        <div class="ibox color-white widget-stat" style="background-color: #A52A2A;">



                                <h2 class="m-b-5 font-strong"></h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8">Inactive Employee </div>
                                    </div>
                                    <div class="col-2">
                                        <h6 class="m-b-8"> </h6>
                                    </div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            > View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>

                        </div>
                    </div> -->


                    <!-- <div class="col-lg-4 col-md-6">

                        <div class="ibox color-white widget-stat" style="background-color:#27a844 ;">
                            <div class="ibox-body">
                                <h2 class="m-b-5 font-strong"></h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8"> Total Employees </div>
                                    </div>
                                    <div class="col-2">
                                        <h6 class="m-b-8">{{emp_count}}</h6>
                                    </div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            (click)="userdetail()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-lg-4 col-md-6">

                        <div class="ibox color-white widget-stat" style="background-color: #5e9ea0;">
                            <div class="ibox-body">
                                <h2 class="m-b-5 font-strong"></h2>
                                <div class="row d-flex">
                                    <div class="col-7">
                                        <div class="m-b-8">Active Employees </div>
                                    </div>
                                    <div class="col-1"><h6>{{active_emp_count}}</h6></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>


                            </div>
                        </div>
                    </div> -->

                </div>
            </div>

            <div *ngIf="list_flag">
                <br>
                <div class="row mt-2">
                    <div class="col-4">
                        <mat-form-field style="margin-left: 2%">
                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            <!-- Filter  -->
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                    </div>
                    <div class="col-4 text-right">
                        <button (click)="print()" class="btn btn-outline-success" *ngIf="download_list">Download PDF</button>
                        <button (click)="export()" class="btn btn-outline-success"  *ngIf="download_list">Download Excel</button>

                        <button (click)="dashboard1()" class="btn btn-outline-primary">Dashboard</button>
                    </div>

                </div>

                <div class=" example-container">
                    <table mat-table [dataSource]="datasource" matSort>
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                id

                            </th>
                            <!--ID-->
                            <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                {{i+1}}</td>
                        </ng-container>
                        <ng-container matColumnDef="location"
                        *ngIf="displayedColumns.includes('location')">
                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                            Location Type

                        </th>`
                        <!--Location
                            Type-->
                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                            {{ element.location }} </td>
                    </ng-container>
                        <ng-container matColumnDef="zone_cd" *ngIf="displayedColumns.includes('zone_cd')">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                Zone Code

                            </th>
                            <!--Location
                                Type-->
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                {{ element.zone_cd }} </td>
                        </ng-container>



                        <ng-container matColumnDef="zone_name" *ngIf="displayedColumns.includes('zone_name')">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                Zone Name

                            </th>
                            <!--Short Name-->
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                {{ element.zone_name }} </td>
                        </ng-container>

                        <ng-container matColumnDef="emp_name" *ngIf="displayedColumns.includes('emp_name')">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                User Name
                            </th>
                            <!--Location
                                Type-->
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                {{ element.emp_name }} </td>
                        </ng-container>
                        <ng-container matColumnDef="emp_phone_no" *ngIf="displayedColumns.includes('emp_phone_no')">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                Phone no.

                            </th>
                            <!--Location
                            Type-->
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                {{ element.emp_phone_no }} </td>
                        </ng-container>

                        <ng-container matColumnDef="user_id" *ngIf="displayedColumns.includes('user_id')">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                User Id

                            </th>

                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                {{element.user_id}} </td>
                        </ng-container>
                        <ng-container matColumnDef="currentdate" *ngIf="displayedColumns.includes('currentdate')">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                Date

                            </th>

                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                {{element.currentdate | date}} </td>
                        </ng-container>




                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                Action</th>
                            <!--Action-->
                            <td mat-cell flex-stretch *matCellDef="let element" class="right">


                                <button class="btn btn-outline-success" *ngIf="element.location"
                                    (click)="view_on_map(element)">View on Map</button>

                                    <button class="btn btn-outline-success" *ngIf="element.user_id"
                                   (click)="user_cordinates(element)" >View</button>

                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                </div>



            </div>


        </div>




    </div>







</div>
<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg mt-0" style="max-width:95%;">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">
                    {{zone_name}}
                </h4>

                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>


            <div class="modal-body">
              <google-map height="620px" width="1457px" [center]="center" [zoom]= 10 (mapClick)="addMarker($event)">
                <map-marker *ngFor="let markerPosition of markerPositions" [position]="markerPosition"
                  [options]="markerOptions">
                </map-marker>
                <map-polygon [paths]="paths"></map-polygon>

              </google-map>



               <!-- oc  -->
                <!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
                    [zoomControl]="true">
                    <agm-polygon [paths]="paths" [clickable]="false" [visible]="true" (polyClick)="onPolyClick($event)">
                    </agm-polygon>
                </agm-map> -->
                <!-- ec  -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger"
                        data-dismiss="modal">{{auth.allLableShowObjProp[auth.language_cd+'Location134']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg mt-2" style="max-width:95%;">
        <div class="modal-content">


            <div class="modal-header" style="background-color:3948DB; color: white;">
                <h4 class="modal-title" style="font: bold; ">
                    Date wise report of&nbsp;&nbsp;{{user['user_name']}}
                </h4>

                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>


            <div class="modal-body">
                <br>
                  <div class="row">
                    <div class="col-1"></div>
                    <div class="col-2 pt-2"><h6>Start Date</h6></div>:
                    <div class="col-2">
                        <input type="date" class="form-control"  [(ngModel)]="Obj_date['start_date']">
                    </div>
                    <div class="col-1"></div>
                    <div class="col-2 pt-2"><h6>End Date</h6></div>:
                    <div class="col-2">
                        <input type="date" class="form-control" [(ngModel)]="Obj_date['end_date']">
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 text-center">
                        <button class="btn btn-outline-primary" (click)="submit_date()">Submit</button>
                    </div>
                  </div>
                  <br>
                  <div class="row" *ngIf="employee_data.length > 0">
                    <div class="col-12 text-center">
                     <h6>Total Distance Coverd By &nbsp; {{user['user_name']}} &nbsp; is &nbsp; {{sum}} &nbsp; m </h6>
                    </div>
                  </div>
                  <table class="table table-bordered mt-2" *ngIf="employee_data.length > 0">
                    <thead class="thead-dark">
                        <tr>
                            <th>S No.</th>
                            <th>Start Date</th>
                            <th>End Date </th>
                            <th>Start time</th>
                            <th>End Time</th>
                            <th>Start&nbsp;(latitude,longitude)</th>
                            <th>End&nbsp;(latitude,longitude)</th>
                            <th>Distance&nbsp;(In Meter)</th>
                        </tr>
                    </thead>
                    <tbody>
                         <tr *ngFor="let item of employee_data ; let i =index">
                            <th>{{i+1}}</th>
                            <th>{{item.currentdate}}</th>
                            <th>{{item.End_date}}</th>
                            <th>{{item.currenttime}}</th>
                            <th>{{item.End_time}}</th>
                            <th>{{item.start_lat_long}}</th>
                            <th>{{item.End_lat_long}}</th>
                            <th>{{item.distance}}</th>
                        </tr>
                    </tbody>
                </table>


                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger"
                        data-dismiss="modal">{{auth.allLableShowObjProp[auth.language_cd+'Location134']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
