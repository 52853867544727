<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Employee Promotion Info -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion1']}}
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-settings"></i>
                                <!-- Employee Promotion List -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion2']}}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link " href="#tab-2" data-toggle="tab"><i class="ti-settings"></i>
                                <!-- ACP Expected List -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion3']}}</a>
                        </li>



                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" aria-disabled="true" id="promote"
                                (click)="refresh()"><i class="ti-settings"></i>
                                <!-- Promote -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion4']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="#tab-5" data-toggle="tab"><i class="ti-settings"></i>
                                <!-- Add Expected ACP -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion5']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="#tab-4" data-toggle="tab"><i class="ti-settings"></i>
                                <!-- Add DPC -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion6']}}</a>
                        </li>
                    </ul>
                    <br>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>


                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            <!-- Employee Id -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion7']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{element.tempid}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            <!-- Employee Name -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{empNameMap.get(element.emp_id)}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="promotion_type_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Promotion Type -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.promotion_type_code }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="order_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Order ID -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion10']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.order_id}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="grade_pay_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Grade Pay -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion11']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.grade_pay_code}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="basic_pay">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Basic -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.basic_pay}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="promotion_interval">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Promotion Interval -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion13']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.promotion_interval}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="promotion_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Promotion Due Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion14']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.temppromotion_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="effective_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Promotion Effective Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion15']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.temppromotion_effective_dt}} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-primary" *ngIf="element.promotion_status=='GENRATED'"
                                                (click)="promote(element)">Promote</button>
                                            <button class="btn btn-outline-danger" *ngIf="element.promotion_status=='GENRATED'"
                                                (click)="reject(element)">Reject</button>
                                                <div *ngIf="element.promotion_status!='GENRATED'">{{element.promotion_status}}</div>
                                        </td>
                                    </ng-container> -->

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-2">



                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter1($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>


                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource1" matSort #sortCol2="matSort">
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            <!-- Employee Id -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion16']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{element.tempid}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion17']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name }}
                                        </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="previous_promotion">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Previous Promotion Interval

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.previous_promotion_interval}} </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="next_promotion">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--  Promotion Interval -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion18']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.promotion_interval}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="next_promotion_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--  Promotion Due Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion15']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.temppromotion_date}}
                                        </td>
                                    </ng-container>



                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion20']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-primary"
                                                (click)="promote(element)"><!-- Promote -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion21']}}</button>
                                            <!-- <button class="btn btn-danger"
                                                (click)="deleteACP1(element)">-->
                                              <!-- Delete -->
                                              <!--  {{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion22']}}</button>-->


                                            <button class="btn btn-danger" (click)="deleteMessage(element)"><!-- Delete -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion22']}}</button>




                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        <div class="tab-pane fade " id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion23']}}
                                </div>
                                <div class="col-4">

                                    <input class="form-control" type="text" [(ngModel)]="addEstObj['emp_name']"
                                        disabled>
                                </div>
                            </div>
                            <br>


                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Old Pay Band:{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion72']}} -->
                                    <!-- Old Pay Band: -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion40']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" value="{{addEstObj['old_pay_scale_code']}}"
                                        disabled>

                                    <!-- {{addEstObj['old_pay_scale_code']}} -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Old Grade Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion25']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" value="{{addEstObj['old_grade_pay_code']}}"
                                        disabled>
                                    <!-- {{addEstObj['old_grade_pay_code']}} -->
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Old Level : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion26']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" value="{{addEstObj['old_level_code']}}"
                                        disabled>
                                    <!-- {{addEstObj['old_level_code']}} -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!--  Old Basic Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion27']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" value="{{addEstObj['old_basic_pay']}}"
                                        disabled>
                                    <!-- {{addEstObj['old_basic_pay']}} -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Next Pay Band: -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion28']}}
                                    : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="htmlpayScaleArr" (change)="changePayScale()" bindLabel="value"
                                        bindValue="value" [multiple]="false" placeholder=""
                                        [(ngModel)]="addEstObj['pay_scale_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="pay_scale">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Next Grade Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion29']}}
                                    : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="htmlgradePayArr" (change)="changeGradePay()" bindLabel="value"
                                        bindValue="value" [multiple]="false" placeholder=""
                                        [(ngModel)]="addEstObj['grade_pay_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="grade_pay_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Next Level : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion30']}}
                                    : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="htmllevelArr" (change)="changeLevel()" bindLabel="value"
                                        bindValue="value" [multiple]="false" placeholder=""
                                        [(ngModel)]="addEstObj['level_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="level_code">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Next Basic Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion31']}}
                                    : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="htmlbasicPayArr" bindLabel="value" bindValue="value"
                                        [multiple]="false" placeholder="" [(ngModel)]="addEstObj['basic_pay']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Order ID : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion32']}}
                                    : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="addEstObj['order_id']">


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Promotion Effective Date : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion33']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" disabled
                                        [(ngModel)]="addEstObj['promotion_date']">


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="askAcp()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion34']}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade " id="tab-5">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!--    Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion35']}}
                                    : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">


                                    <ng-select [items]="activeEmpArr" bindLabel="emp_name" bindValue="emp_id"
                                        (change)="changeDCPEmployee()" [multiple]="false" placeholder=""
                                        [(ngModel)]="acpObj['emp_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>




                                </div>
                            </div>
                            <br>
                            <!-- shweta code started-->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- ACP Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion37']}}
                                    : <span style="color: red;">*</span>
                                </div>
                                <!-- self added -->
                                <div class="col-4">

                                    <ng-select [items]="intArr" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="" [(ngModel)]="acpObj['promotion_interval']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="grade_pay_code" (change)="incyear()">
                                    </ng-select>
                                </div>
                                <!-- self added -->
                            </div>
                            <br>
                            <!-- shweta code ended -->


                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Promotion Due Date: -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion36']}}
                                </div>
                                <div class="col-4">

                                    <input class="form-control" type="date" [(ngModel)]="acpObj['promotion_date']"
                                        disabled>

                                </div>
                            </div>
                            <br>



                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="addACP()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion38']}}</button>
                                </div>
                            </div>


                        </div>
                        <div class="tab-pane fade " id="tab-4">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!--  Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion39']}}
                                    :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">


                                    <ng-select [items]="activeEmpArr" bindLabel="emp_name" bindValue="emp_id"
                                        (change)="changeDCPEmployee()" [multiple]="false" placeholder=""
                                        [(ngModel)]="addDCPEstObj['emp_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>


                                </div>
                            </div>
                            <br>


                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Old Pay Band: -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion40']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control"
                                        value="{{addDCPEstObj['old_pay_scale_code']}}" disabled>
                                    <!-- {{addDCPEstObj['old_pay_scale_code']}} -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Old Grade Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion41']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control"
                                        value="{{addDCPEstObj['old_grade_pay_code']}}" disabled>
                                    <!-- {{addDCPEstObj['old_grade_pay_code']}} -->
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Old Level : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion42']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" value=" {{addDCPEstObj['old_level_code']}}"
                                        disabled>

                                    <!-- {{addDCPEstObj['old_level_code']}} -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Old Basic Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion43']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" value="{{addDCPEstObj['old_basic_pay']}}"
                                        disabled>

                                    <!-- {{addDCPEstObj['old_basic_pay']}} -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!--  Old Designation : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion44']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control"
                                        value="{{addDCPEstObj['old_designation_code']}}" disabled>

                                    <!-- {{addDCPEstObj['old_designation_code']}} -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Next Pay Band: -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion45']}}
                                    :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="htmlpayScaleArr" (change)="changePayScaleDCP()"
                                        bindLabel="value" bindValue="value" [multiple]="false" placeholder=""
                                        [(ngModel)]="addDCPEstObj['pay_scale_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="pay_scale">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!--  Next Grade Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion46']}}
                                    :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="htmlgradePayArr" (change)="changeGradePayDCP()"
                                        bindLabel="value" bindValue="value" [multiple]="false" placeholder=""
                                        [(ngModel)]="addDCPEstObj['grade_pay_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="grade_pay_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Next Level : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion47']}}
                                    :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="htmllevelArr" (change)="changeLevelDCP()" bindLabel="value"
                                        bindValue="value" [multiple]="false" placeholder=""
                                        [(ngModel)]="addDCPEstObj['level_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="level_code">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Next Basic Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion48']}}
                                    :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="htmlbasicPayArr" bindLabel="value" bindValue="value"
                                        [multiple]="false" placeholder="" [(ngModel)]="addDCPEstObj['basic_pay']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Next Designation : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion49']}}
                                    :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0011']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="addDCPEstObj['designation_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                    <!-- <input class="form-control" type="text" [(ngModel)]="addDCPEstObj['designation_code']"> -->

                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Order ID : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion50']}}
                                    :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="addDCPEstObj['order_id']">


                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Effective Date : -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion51']}}
                                    :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="addDCPEstObj['effective_dt']">


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="askDpc()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion52']}}</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner>
    <!-- Loading.... -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion53']}}</ngx-spinner>
<div class="modal" id="myModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <!-- Are You Sure? -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion54']}}
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <h6>
                            <!-- Following Component are being modified after this promotion.. -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion55']}}
                        </h6>
                    </div>
                </div>

                <table style="width: 100%;">
                    <tr>
                        <th>
                            <!-- Component Code -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion56']}}
                        </th>
                        <th>
                            <!-- Amount -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion57']}}
                        </th>
                        <th>
                            <!-- Type -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion58']}}
                        </th>
                        <th>
                            <!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion59']}}
                        </th>
                    </tr>
                    <tr *ngFor="let ob of salArr;let i=index">
                        <td> {{ob['pay_component_code']}}</td>
                        <td> {{ob['pay_component_amt']}}</td>
                        <td> {{ob['pay_code']}}</td>
                        <td> <button class="btn btn-danger"
                                (click)="deleteSalArr(i)"><!-- Delete -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion60']}}</button>
                        </td>
                    </tr>

                </table>



                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-info"
                        (click)="addEmpPromotion()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion61']}}</button>
                    <button type="button" class="btn btn-danger"
                        data-dismiss="modal"><!-- Close -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion62']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <!-- Are You Sure? -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion63']}}
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <h6>
                            <!-- Following Component are being modified after this promotion.. -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion64']}}
                        </h6>
                    </div>
                </div>

                <table style="width: 100%;">
                    <tr>
                        <th>
                            <!-- Component Code -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion65']}}
                        </th>
                        <th>
                            <!-- Amount -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion66']}}
                        </th>
                        <th>
                            <!-- Type -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion67']}}
                        </th>
                        <th>
                            <!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion68']}}
                        </th>
                    </tr>
                    <tr *ngFor="let ob of salArr;let i=index">
                        <td> {{ob['pay_component_code']}}</td>
                        <td> {{ob['pay_component_amt']}}</td>
                        <td> {{ob['pay_code']}}</td>
                        <td> <button class="btn btn-danger"
                                (click)="deleteSalArr(i)"><!-- Delete -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion69']}}</button>
                        </td>
                    </tr>

                </table>



                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-info"
                        (click)="addEmpDCPPromotion()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion70']}}</button>
                    <button type="button" class="btn btn-danger"
                        data-dismiss="modal"><!-- Close -->{{mainService.allLableShowObjHR[mainService.language_cd+'emp-promotion71']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
