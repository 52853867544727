import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + '/emd';
  }

  async getApprovedTender(obj :any ){
    let resp = await this.http.get(this.httpUrl + '/payment/getApprovedTender'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async getAllTender(obj :any ){
    let resp = await this.http.get(this.httpUrl + '/payment/getAllTender'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async getdetails(obj :any ){
    let resp = await this.http.get(this.httpUrl + '/payment/getdetails'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async viewDetails(obj :any ){
    let resp = await this.http.get(this.httpUrl + '/payment/viewDetails'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

/********************************************* Refund Payment  ************************************************** */

  async listOfRefundedVendor(obj :any ){
    let resp = await this.http.get(this.httpUrl + '/payment/listOfRefundedVendor'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async getListForRefund(obj :any ){
    let resp = await this.http.get(this.httpUrl + '/payment/getListForRefund'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async submitRefund(obj :any ){
    let resp = await this.http.post(this.httpUrl + '/payment/submitRefund', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

}
