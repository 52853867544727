import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../../service/establishment.service';
import {MainService} from '../../service/main.service';
import { JsonPipe } from '@angular/common';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

import Swal from 'sweetalert2';

declare var $: any

@Component({
  selector: 'app-emp-resign',
  templateUrl: './emp-resign.component.html',
  styleUrls: ['./emp-resign.component.css']
})
export class EmpResignComponent implements OnInit {

  constructor(public mainService : MainService,private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private allEmpServ: EstablishmentService) { }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns :any= ['emp_id', 'emp_name', 'resignation_date', 'notice_period', 'resignation_status', 'action'];
  datasource:any;
  employeeObj:any={};
  erpUser:any;
  b_acct_id:any;
  ERObj :any= {};
  selectEmpObj :any= {};
  completeERObj:any={};
  allEmployees :any= [];
  employeeIdtoName:any = {};
  allArr:any=[]
  newallEmplyees :any= []
  currDate:any;
  allEmplyees :any= [];
  activeEmpArr :any= [];
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllActiveEmployees();
    await this.getResignedEmployee();
    this.currDate = new Date().toISOString().slice(0, 10);
    this.ERObj['resignation_date'] = this.currDate;
  }
  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }
//   async getAllEmployees() {
// this.spinner.show()
//     var obj = new Object();
//     obj['b_acct_id'] = this.b_acct_id;
//     obj['party_status_code'] = ["JOINING", "JOINED", "LEFT"];
//     var resp = await this.allEmpServ.getEmployeeMasterData(JSON.stringify(obj));
//     if (resp['error'] == false) {
//       this.spinner.hide()
//       this.allEmployees = resp.data;

//       this.newallEmplyees = []
//       for(let i=0;i<this.allEmployees.length;i++){
//         var obj :any= new  Object();
//         obj=Object.assign({},this.allEmployees[i]);
//         obj['emp_name']=/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])+"-"+obj['emp_name']
//         this.newallEmplyees.push(obj)
//       }
      // for (let i=0; i<this.allEmployees.length ;i++){
      //   this.employeeObj[this.allEmployees[i]['emp_id']]=this.allEmployees[i]['emp_name'];
      // }
//     } else {
//       this.spinner.hide()
//       this.snackBar.open("Error while getting employee list", 'Error', {
//         duration: 5000
//       });
//     }
//   }
  async getResignedEmployee() {
    this.spinner.show()
var arr = []
var resignarr = []
    var resp = await this.allEmpServ.getAllResignation(this.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data

      for(let i=0;i<arr.length;i++){
        var obj :any= new  Object();
        obj=Object.assign({},arr[i]);
       obj['tempid']=/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])
       resignarr.push(obj)
      }
      // for(let i=0;i<this.activeEmpArr.length;i++)
      // {
      //     if(resignarr[i]['emp_id'] == this.activeEmpArr[i]['emp_id']){
      //         resignarr[i]['']
      //     }
      // }
      this.datasource = new MatTableDataSource(resignarr)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort
    } else {
      this.spinner.hide()
      Swal.fire('Error','Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async getAllActiveEmployees() {
    var obj :any= new  Object();
     obj['b_acct_id']=this.b_acct_id;
     obj['emp_status_code'] = ['ACTIVE','INACTIVE']
    var resp = await this.allEmpServ.getArrayAllCurrentEstablishementInfo(obj);
  //this.allActiveEmplyees=[]

    if (resp['error'] == false) {
      this.allEmplyees = resp.data;
      let allEmp = resp.data;
      this.activeEmpArr = []

      for (let i = 0; i < this.allEmplyees.length; i++) {
        if(this.allEmplyees[i].emp_status_code == "ACTIVE") {
          this.activeEmpArr.push(this.allEmplyees[i])
         }
      }
      this.newallEmplyees = [];
      for(let i=0;i<this.activeEmpArr.length;i++){
        var obj :any= new  Object();
        obj=Object.assign({},this.activeEmpArr[i]);
        obj['emp_name']=/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])+"-"+obj['emp_name']
        this.newallEmplyees.push(obj)
      }
      for (let i=0; i<allEmp.length ;i++){
        this.employeeObj[allEmp[i]['emp_id']]=allEmp[i]['emp_name'];
      }
      }
     else {
      Swal.fire('Error','Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  /* async getAllCurrentArrangements() {
    this.spinner.show();
    this.allArr =[];
    var resp = await this.allEmpServ.getAllCurrentArrangements(this.b_acct_id);
    if (resp['error'] == false) {
      this.allArr = resp.data;
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting All DEATHS", 'Error', {
        duration: 5000
      });
    }
  } */
  /* getCurrentArrangementOfSelected(){
    for(var i=0;i<this.allArr.length;i++){
      if(this.allArr[i].emp_id == this.ERObj['emp_id']){
        this.ERObj['arr_id'] = this.allArr[i].arr_id;
      }
    }
  } */
  /* getArrangementStatus(){
    var arr_status_code=""
    for(var i=0;i<this.allArr.length;i++){
      if(this.allArr[i].emp_id == this.ERObj['emp_id']){
        arr_status_code = this.allArr[i].emp_status_code;
      }
    }
    return arr_status_code;
  } */

async submitMessage()
{
  if(this.selectEmpObj==""||this.selectEmpObj==null||this.selectEmpObj==undefined ||
this.ERObj['resignation_reason']==''|| this.ERObj['resignation_reason']==null|| this.ERObj['resignation_reason']==undefined ||
/*this.ERObj['order_id']==''||this.ERObj['order_id']==null||this.ERObj['order_id']==undefined|| */
this.ERObj['resignation_date']==""||this.ERObj['resignation_date']==null||this.ERObj['resignation_date']==undefined)
{
   Swal.fire("Warning","* Field Required !",'warning');
   return;
}

Swal.fire({
  title: 'Are you sure?',
  icon: 'info',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes'
}).then((result) => {
  if (result.value) {
    this.addResignation()
  }
})



}


  async addResignation() {




  //  if (this.selectEmpObj==null || this.ERObj['resignation_type_code']==null || this.ERObj['resignation_reason']== null || this.ERObj['resignation_reason']==null) {
  //    swal.fire("Warning", "Firstly fill all required (*) fields", 'warning')
//return;
   // }

    this.spinner.show()
    this.ERObj['b_acct_id'] = this.b_acct_id;
    this.ERObj['emp_id'] = this.selectEmpObj;
    this.ERObj['resignation_status'] = "APPLIED";
    this.ERObj['create_user_id'] = this.erpUser.user_id;
    this.ERObj['employee_current_type_code'] = 'RESIGNED';
    this.ERObj['emp_status_code'] = 'INACTIVE';
    for(let i=0;i<this.activeEmpArr.length;i++)
    {
          if(this.ERObj['emp_id'] == this.activeEmpArr[i]['emp_id']){
            this.ERObj['arr_id'] = this.activeEmpArr[i]['arr_id'];
            break;
          }
    }
    var resp = await this.allEmpServ.addResign(this.ERObj);
    if (resp['error'] == false) {
      this.ERObj={};
      await this.getResignedEmployee();
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      this.spinner.hide();

      // this.snackBar.open("Employee Resgination Applied Successfully!", 'Success', {
      //   duration: 5000
      // });

      Swal.fire("Success",'Employee Resgination Applied Successfully!','success')

    } else {
      this.spinner.hide();
      Swal.fire('Error','Error while Applying Resignation Of Employee', 'error')
      // this.snackBar.open("Error while Applying Resignation Of Employee", 'Error', {
      //   duration: 5000
      // });
    }

  }


  openUpdate(element:any) {
    $('.nav-tabs a[href="#tab-3"]').tab('show');
    this.ERObj = Object.assign({}, element);
  }

  async approveResignation() {
    this.spinner.show();
    this.ERObj['b_acct_id'] = this.b_acct_id;
    this.ERObj['resignation_approval_user_id']  = this.erpUser.user_id;
    this.ERObj['create_user_id']  = this.erpUser.user_id;
    this.ERObj['emp_status_code'] = "NOTICE PERIOD"
        var resp = await this.allEmpServ.approveResignation(this.ERObj);
    if (resp['error'] == false) {
      await this.getResignedEmployee();
      this.spinner.hide()
      Swal.fire('Success', 'Approved Employee Resignation ', 'success')
      // this.snackBar.open("Approved Employee Resignation", 'Success', {
      //   duration: 5000
      // });
    } else {

      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee resignation approve', 'error')
      // this.snackBar.open("Error while getting employee resignation approve", 'Error', {
      //   duration: 5000
      // });
    }

  }


  complete(element:any) {
    $('.nav-tabs a[href="#tab-4"]').tab('show');
    this.completeERObj = Object.assign({}, element);
  }


  async completeResignation() {
    this.spinner.show();
    var obj:any= new Object();
    obj['b_acct_id']=this.b_acct_id;
    obj['id']=this.completeERObj['id'];
    obj['calculation_id']=this.completeERObj['calculation_id'];
    obj['emp_id']=this.completeERObj['emp_id'];
    for(let i=0;i<this.allEmployees.length;i++){
      if(this.allEmployees[i].emp_id==this.completeERObj['emp_id']){
        obj['emp_name']=this.allEmployees[i]['emp_name'];
        obj['emp_email']=this.allEmployees[i]['emp_email'];
      }
    }
    var resp = await this.allEmpServ.resignationComplete(obj);
    if (resp['error'] == false) {
      await this.getResignedEmployee();
      this.spinner.hide()
      Swal.fire('Success', 'Employee Resignation Completed', 'success')
      // this.snackBar.open(" Employee Resignation Completed", 'Success', {
      //   duration: 5000
      // });
    } else {

      this.spinner.hide()
      Swal.fire('Error','Error while getting employee resignation complete','error')
      // this.snackBar.open("Error while getting employee resignation complete", 'Error', {
      //   duration: 5000
      // });
    }

  }
  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }

}
