import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import Swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { LandInputService } from '../../service/land-input.service';

declare var $: any;
import { AuthenticationService } from '../../../authentication.service';
import { SaleService } from '../../service/sale.service';
import { DocumentService } from '../../service/document.service';

import { PropCostingService } from '../../service/prop-costing.service';
//
import { DefaulterService } from "../../service/defaulter.service";


@Component({
  selector: 'app-payment-option',
  templateUrl: './payment-option.component.html',
  styleUrls: ['./payment-option.component.css']
})
export class PaymentOptionComponent implements OnInit {
  datasource:any ;
  isLinear:any  = false;
  first:any  = true;
  selectedFile: any = null;
  second:any  = true;
  radio:any  = false;
  propObj:any  = {}
  subsidy_type:any  = [{ id: "Amount" }, { id: "Percentage" }]
  cost_type :any = [{ id: "Basic" }, { id: "Total" }]
  displayedColumsLot_fcfs = ['sno', 'mode_of_payment','interest_depend_on', 'pro_type_or_sale_value', 'frequency_of_installment', 'interest_rate', 'effective_date_from', 'action']
  displayedColumsAuction = ['sno', 'mode_of_payment','interest_depend_on', 'range', 'frequency_of_installment', 'interest_rate', 'effective_date_from', 'action']
  displayedColumns_1:any
  GISArr:any  = []
  third:any  = true;
  fourth :any = true;
  fifth :any = true
  sixth:any  = true
  work_id:any ;
  BoqTable:any  = [];
  total_sum:any  = 0;
  imgURL:any ;
  Obj :any = {}
  docArr :any = [{}]
  enableStep3: any;
  b_acct_id:any ;
  ebillUser:any ;
  planing_arr:any  = [{}]
  acp_arr:any  = [{}]
  public imagePath:any ;
  httpUrl:any ;
  radio2 :any = false;
  uploader:any ;
  dependsOnArr:any  = [{ id: "Property Type" }, { id: "Sale Value" }]
  modeOfPayment:any  = [{ id: "Installment" }, { id: "Cash Down" }]
  paymentMethodArr :any = [{ id: "fixed" }, { id: "Variable %" }]
  propertyCategoryArr:any  = [{ id: "Residential" }, { id: "Non-Residential" }]




  modeOfferAction(){
    if(!this.OfferMode){
       if(this.OfferMode==1){
          this.Obj['interest_depend_on'] = "Sale Value"
        }
    }
  }
  property_group_check_data:any
  allProp: any;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  constructor(private spinner: NgxSpinnerService, private costingService: PropCostingService, private docService: DocumentService, private sale_service: SaleService, public auth: AuthenticationService, public mainService: MainService, private landInput_S: LandInputService, private hierarchyS: PropHierarchyService, public toastr: ToastrService, private sanitizer: DomSanitizer,public DefaulterService: DefaulterService) { }
  allProjects = []
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.Obj['total_area'] = 0
    this.httpUrl = this.mainService.httpUrl
    // await this.getPaymentOption()
    await this.showdata()

  }


  withinlist1:any ;
  withinlist2:any =[];
  list_flag = true
  create_flag = false
  update_flag = false

  OfferModeList = [
    {code:1,value:"Auction"},
    {value:'FCFS/Lottery',code:2}
  ]
  OfferMode:any ;

  offerMode(mode:any ){
      this.OfferMode = mode
  }

  reset(){}

  todayformatedDate(){
    let d = new Date()
    let day:any = d.getDate()
    if(day<10){
      day = "0"+day
    }

    let month:any = d.getMonth()+1
    if(month<10){
      month = "0"+month
    }
    return d.getFullYear()+"-"+month+"-"+day
  }

  // samarjeet
  async firstWithin(item:any )
  {
    // console.log(this.withinlist1)
    this.withinlist2 = []
    // {id: 1377, field_code: 'PROPFLD51', code: '30', value: '30'}
    this.withinlist1.forEach((element:any )=> {
      console.log(element.value,item.value)
      if( item.value>=element.value){
        console.log(item.value)
        this.withinlist2.push(element)
      }
    });
  }

  //priyanshu
  defaulterlist:any ;

  async showdata() {
    let getobj:any  = {};
    getobj["b_acct_id"] = this.b_acct_id;
    console.log("get api obj", getobj);
    var resp:any  = await this.DefaulterService.getDefaulter(getobj);
    this.defaulterlist = resp['data']
    for(let i=0; i<this.defaulterlist.length;i++ ){
      this.defaulterlist[i].name = 'F-'+this.defaulterlist[i].no_of_failed_inst_default + '/ N-' + this.defaulterlist[i].no_of_notice_before_cancellation + '/ SP-' + this.defaulterlist[i].seize_period;
      console.log(this.defaulterlist[i].name,'ncbvxcjnv');

    }
    console.log(this.defaulterlist);


  }

  open_create() {
    // this.Obj['no_of_payment'] = 1
    this.Obj = {}
    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
  }
 async open_list() {
    this.OfferMode = undefined
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    await this.getPaymentOption()
  }

  addrow() {
    // this.gatwayData.push({})
  }
  removerow(index:any ) {
    // this.gatwayData.splice(index, 1)
  }
  propertyTypeData = []
  async fetchPropertyType() {
    this.propertyTypeData = []
    let obj:any  = {}
    obj = Object.assign({}, this.Obj)
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    let resp:any  = await this.costingService.getPropertyType(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      if (resp['data'].length > 0) {
        this.propertyTypeData = resp['data']
      } else {
        Swal.fire('Information', 'No Record Found On This Node..! Please Select Till Leaf Level', 'info')
      }
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Property Type', 'error')
    }
  }

  async getPaymentOption() {
    this.datasource = new MatTableDataSource([])
    if(!this.OfferMode){
      return
    }
    this.spinner.show()
    let obj:any  = {}
    obj['b_acct_id'] = this.b_acct_id
    let resp
    if(this.OfferMode==2){
      console.log(obj)
      resp = await this.costingService.getPaymentOption(JSON.stringify(obj))
      this.displayedColumns_1 = this.displayedColumsLot_fcfs
    }else if(this.OfferMode==1){
      this.displayedColumns_1 = this.displayedColumsAuction
      resp = await this.costingService.getPaymentOptionaAuction(JSON.stringify(obj))
    }

    console.log(resp)
    if (resp['error'] == false) {
      this.allProp = resp['data']
      for (let i = 0; i < this.allProp.length; i++) {
        this.allProp[i]['sno'] = i + 1
        if(this.allProp[i]['mode_of_payment']=="Installment"){
          this.allProp[i]['effective_date_from'] = this.allProp[i]['effective_date_from'].split('T')[0]
        }
      }
      this.datasource = new MatTableDataSource(this.allProp);
      this.datasource.sort = this.sort.toArray()[0];
      this.datasource.paginator = this.paginator.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async open_update(element:any) {
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
    console.log(element)
    // this.spinner.show()
    this.Obj = Object.assign({}, element)
    this.Obj['pro_type_or_sale_vaue'] = element['pro_type_or_sale_value']
    this.Obj['valueType'] = element['pay_method']
    this.Obj['first_pay_day'] = element['first_pay_day']
    // console.log(this.Obj['first_pay_day'])
    this.Obj['effective_date_from'] = this.Obj['effective_date_from'].split('T')[0]
    this.resetModeData()
    if(this.Obj['mode_of_payment'] != 'Installment')
       this.firstWithin({value:element['first_pay_day'],code:element['first_pay_day']})

    if(this.Obj['mode_of_payment'] == 'Installment')
      this.Obj['discount_day'] = element['first_pay_day']
  }
  async update() {

    this.Obj['life_cycle_status'] = 'ACTIVE'
     let create_flag = true
     this.Obj['life_cycle_status']='ACTIVE'
     console.log(this.Obj)
     if(this.OfferMode==2){
       if(this.Obj['mode_of_payment'] != 'Installment'){
         this.Obj['no_of_payment'] = 1
       }
       if (this.Obj['mode_of_payment'] == 'Installment'
        && (
        !this.Obj['property_category']||
        !this.Obj['interest_depend_on'] ||
        !this.Obj['pro_type_or_sale_vaue'] ||
        !this.Obj['interest_rate'] ||
        !this.Obj['panel_interest']  ||
        !this.Obj['no_of_defaulter'] ||
        !this.Obj['frequency_of_installment'] ||
        !this.Obj['no_of_payment']
        )
        ) {
         Swal.fire('Information', 'Please Select All * fields', 'info')
         create_flag = false
       } else if (
         this.Obj['mode_of_payment'] != 'Installment'
         && (
             !this.Obj['pro_type_or_sale_vaue']
             || !this.Obj['discount']
             || !this.Obj['no_of_payment'] || !this.Obj['valueType']
             || !this.Obj['first_pay_day']  || !this.Obj['no_of_defaulter']
             ||!this.Obj['property_category'] ||!this.Obj['discount_day']
             )) {
         Swal.fire('Information', 'Please Select All * fields', 'info')
         create_flag = false
       } else if (this.Obj['mode_of_payment'] == 'Installment' && !this.Obj['effective_date_from']) {
          Swal.fire('Information', 'Please Enter Effective Date', 'info')
          create_flag = false
       } else if (this.Obj['effective_date_from']) {
          let today = new Date().getDate()
          let dateToComp = new Date(this.Obj['effective_date_from']).getDate()
       }
     }else{
       if(this.Obj['mode_of_payment'] != 'Installment'){
         this.Obj['no_of_payment'] = 1
       }
       if (this.Obj['mode_of_payment'] == 'Installment'
        && (
        !this.Obj['property_category']||
        !this.Obj['interest_rate'] ||
        !this.Obj['panel_interest']  ||
        !this.Obj['no_of_defaulter'] ||
        !this.Obj['frequency_of_installment'] ||
        !this.Obj['range_start_from'] ||
        !this.Obj['effective_date_from'] ||
        !this.Obj['range_end_at'] ||
        !this.Obj['no_of_payment']
        )
        ) {
         Swal.fire('Information', 'Please Select All * fields', 'info')
         create_flag = false
       } else if (
         this.Obj['mode_of_payment'] != 'Installment'
         && (
              !this.Obj['discount'] ||
              !this.Obj['range_end_at'] || !this.Obj['range_start_from']
             || !this.Obj['valueType']
             || !this.Obj['no_of_defaulter']
             ||!this.Obj['property_category'] ||!this.Obj['discount_days']
             )) {
         Swal.fire('Information', 'Please Select All * fields1', 'info')
         create_flag = false
       } else if (this.Obj['mode_of_payment'] == 'Installment' && !this.Obj['effective_date_from']) {
          Swal.fire('Information', 'Please Enter Effective Date', 'info')
          create_flag = false
       }

     }
     console.log(this.Obj, create_flag, 'create_flag')
     if (create_flag == true) {
       this.spinner.show()
       this.Obj['b_acct_id'] = this.b_acct_id
       let resp;
       if(this.OfferMode==1){
         console.log("sahi wali call hui")
          resp = await this.costingService.updatePaymentOptionAuction(this.Obj)
        }else if(this.OfferMode==2){
          resp = await this.costingService.updatePaymentOption(this.Obj)
       }
       console.log(resp, 'resp')
       if (resp['error'] == false) {
         this.spinner.hide()
         this.list_flag = true
         this.create_flag = false
         this.update_flag = false
         await this.getPaymentOption()
         Swal.fire('Success...', 'Submitted Successfully.', 'success')
       } else {
         this.spinner.hide()
         Swal.fire('Error', resp['data'], 'error')
       }
     }

    }


  async delete(element:any) {
    //console.log(element)
    this.Obj = Object.assign({}, element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete()
      }
    })


  }
  async finaldelete() {
    this.Obj['b_acct_id'] = this.b_acct_id;
    this.spinner.show()
    let resp
    if(this.OfferMode==2){
      resp = await this.costingService.inactivePaymentOption(this.Obj);
    }else{
      resp = await this.costingService.inactivePaymentOptionAuction(this.Obj);
    }
    console.log(this.Obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getPaymentOption()
      this.list_flag = true
      this.create_flag = false
      this.update_flag = false
      Swal.fire('Success...', 'Deleted Successfully.', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }


  }

  setHierarchyNode(item:any , i:any ) {
    let dummy = []
    //console.log(this.planing_arr, this.existing_heirarchy_nodes, item, i)
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy.push(this.planing_arr[i]['hierarchy_type'])
    }
    let dummy2:any  = []
    if (dummy.length > 0) {
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            this.Obj['level'] = this.existing_heirarchy_nodes[j]['level']
            this.Obj['node_cd'] = this.existing_heirarchy_nodes[j]['node_cd']
          }
        }
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }
  remove_dropdown(data:any , i:any ) {
    //console.log(data, i)
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop()
    }
    let dummy:any  = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy.push(this.planing_arr[i]['hierarchy_type'])
    }
    let dummy2 :any = []
    if (dummy.length > 0) {
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            this.Obj['level'] = this.existing_heirarchy_nodes[j]['level']
            this.Obj['node_cd'] = this.existing_heirarchy_nodes[j]['node_cd']
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }
  existing_heirarchy_nodes:any  = []
  async getAllpropHierarchynodes() {
    this.spinner.show()
    this.existing_heirarchy_nodes = []
    for (let i = 0; i < this.existing_heirarchy.length; i++) {
      if (this.Obj['hier_ident_code'] == this.existing_heirarchy[i]['hier_ident_code']) {
        this.Obj['hier_buss_name'] = this.existing_heirarchy[i]['hier_buss_name']
        this.Obj['hier_type'] = this.existing_heirarchy[i]['hier_type']
        break;
      }
    }
    let obj:any  = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.Obj['hier_ident_code']
    obj['status'] = 'ACTIVE'
    //console.log(obj)
    var resp = await this.hierarchyS.getAllpropHierarchynodes(JSON.stringify(obj));
    console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy_nodes = resp['data']
      let dummy:any  = []
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        if (1 == this.existing_heirarchy_nodes[i]['level']) {
          dummy.push(this.existing_heirarchy_nodes[i])
        }
      }
      this.planing_arr[0]['existing_heirarchy_nodes'] = dummy
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  existing_heirarchy :any = []
  planaing_hierarchy :any = []
  acquisition_hierarchy :any = []
  async getAllHeirarchy() {
    this.spinner.show()
    let obj:any  = {}
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.hierarchyS.getHierarchyData(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.existing_heirarchy = resp['data']
      for (let i = 0; i < this.existing_heirarchy.length; i++) {
        this.existing_heirarchy[i]['desc'] = this.existing_heirarchy[i]['hier_ident_code'] + ' - ' + this.existing_heirarchy[i]['hier_buss_name']
      }
      let acq = []
      let planing = []
      for (let i = 0; i < this.existing_heirarchy.length; i++) {
        if (this.existing_heirarchy[i]['hier_type'] == "Acquisition") {
          acq.push(this.existing_heirarchy[i])
        }
        if (this.existing_heirarchy[i]['hier_type'] == "Planing") {
          planing.push(this.existing_heirarchy[i])
        }
      }
      this.planaing_hierarchy = planing
      this.acquisition_hierarchy = acq

      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  used_nodes :any = []
  selected_row :any = {}
  add_dropdown(data:any , index:any ) {

    //console.log(data, index)
    let flag = true
    let obj:any  = {}
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
        obj = Object.assign({}, this.existing_heirarchy_nodes[i])
      }
    }
    this.selected_row = Object.assign({}, obj)
    this.selected_row['index'] = index

    this.filter_data()
  }

  discountValueType = "fixed"
  changeDiscountPlaceHolder(){
    if(this.Obj['valueType']=="fixed"){
      this.discountValueType = "fixed"
    }else{
      this.discountValueType = "%"
    }
  }


  resetModeData() {
    let ob:any  = {}
    ob = Object.assign({}, this.Obj)
    if(this.update_flag==true){
      this.Obj = { ...this.Obj}
    }
    else{
      this.Obj = {}
    }
    this.Obj['mode_of_payment'] = ob['mode_of_payment']
    this.Obj['pro_type_or_sale_vaue'] = ob['pro_type_or_sale_vaue']
    this.Obj['frequency_of_installment'] = ob['frequency_of_installment']
    this.Obj['valueType'] = ob['valueType']
    this.Obj['interest_rate'] = ob['interest_rate']
    this.Obj['panel_interest'] = ob['panel_interest']
    this.Obj['effective_date_from'] = ob['effective_date_from']
    this.Obj['max_no_installment'] = ob['max_no_installment']

    this.withinlist1 = this.mainService.codeValueTechObj['PROPFLD51'];
    console.log(this.withinlist1)
    if(this.OfferMode==2){
      this.Obj['interest_depend_on'] = "Property Type"
    }
    console.log(this.Obj)
  }
  async submit() {

    this.Obj['life_cycle_status']='ACTIVE'
    let create_flag = true
    console.log(this.Obj)
    if(this.OfferMode==2){
      if(this.Obj['mode_of_payment'] != 'Installment'){
        this.Obj['no_of_payment'] = 1
      }

      console.log(this.Obj['pro_type_or_sale_vaue'],
      this.Obj['discount'],
      this.Obj['no_of_payment'],this.Obj['valueType'],
      this.Obj['first_pay_day'],this.Obj['no_of_defaulter'],
      this.Obj['property_category'],this.Obj['discount_days'])
      if (this.Obj['mode_of_payment'] == 'Installment'
       && (
       !this.Obj['property_category']||
       !this.Obj['interest_depend_on'] ||
       !this.Obj['pro_type_or_sale_vaue'] ||
       !this.Obj['interest_rate'] ||
       !this.Obj['panel_interest']  ||
       !this.Obj['no_of_defaulter'] ||
       !this.Obj['frequency_of_installment'] ||
       !this.Obj['no_of_payment']
       )
       ) {
        Swal.fire('Information', 'Please Select All * fields', 'info')
        create_flag = false
      } else if (
        this.Obj['mode_of_payment'] != 'Installment'
        && (
            !this.Obj['pro_type_or_sale_vaue']
            || !this.Obj['discount']
            || !this.Obj['no_of_payment'] || !this.Obj['valueType']
            || !this.Obj['first_pay_day']  || !this.Obj['no_of_defaulter']
            ||!this.Obj['property_category'] ||!this.Obj['discount_days']
            )) {
        Swal.fire('Information', 'Please Select All * fields', 'info')
        create_flag = false
      } else if (this.Obj['mode_of_payment'] == 'Installment' && !this.Obj['effective_date_from']) {
         Swal.fire('Information', 'Please Enter Effective Date', 'info')
         create_flag = false
      } else if (this.Obj['effective_date_from']) {
         let today = new Date().getDate()
         let dateToComp = new Date(this.Obj['effective_date_from']).getDate()
      }
    }else  if(this.OfferMode==1){
      if(this.Obj['mode_of_payment'] != 'Installment'){
        this.Obj['no_of_payment'] = 1
      }
      if (this.Obj['mode_of_payment'] == 'Installment'
       && (
       !this.Obj['property_category']||
       !this.Obj['interest_rate'] ||
       !this.Obj['panel_interest']  ||
       !this.Obj['no_of_defaulter'] ||
       !this.Obj['frequency_of_installment'] ||
       !this.Obj['range_start_from'] ||
       !this.Obj['effective_date_from'] ||
       !this.Obj['range_end_at'] ||
       !this.Obj['no_of_payment']
       )
       ) {
        Swal.fire('Information', 'Please Select All * fields', 'info')
        create_flag = false
      } else if (
        this.Obj['mode_of_payment'] != 'Installment'
        && (
             !this.Obj['discount'] ||
             !this.Obj['range_end_at'] || !this.Obj['range_start_from']
            || !this.Obj['valueType']
            || !this.Obj['no_of_defaulter']
            ||!this.Obj['property_category'] ||!this.Obj['discount_days']
            )) {
        Swal.fire('Information', 'Please Select All * fields1', 'info')
        create_flag = false
      } else if (this.Obj['mode_of_payment'] == 'Installment' && !this.Obj['effective_date_from']) {
         Swal.fire('Information', 'Please Enter Effective Date', 'info')
         create_flag = false
      }

    }
    console.log(this.Obj, create_flag, 'create_flag')
    if (create_flag == true) {
      this.spinner.show()
      this.Obj['b_acct_id'] = this.b_acct_id
      let resp:any ;
      if(this.OfferMode==1){
        console.log("sahi wali call hui")
        resp = await this.costingService.createPaymentOptionAuction(this.Obj)
      }else{
        resp = await this.costingService.createPaymentOption(this.Obj)
      }
      console.log(resp, 'resp')
      if (resp['error'] == false) {
        this.spinner.hide()
        this.list_flag = true
        this.create_flag = false
        this.update_flag = false
        await this.getPaymentOption()
        Swal.fire('Success...', 'Submitted Successfully.', 'success')
      } else {
        this.spinner.hide()
        Swal.fire('Error', resp['data'], 'error')
      }
    }

  }
  locationInfoData = []
  used_node_arr = []
  filter_data() {
    let dummy:any  = []
    let max_level = 0
    for (let i = 0; i < this.planaing_hierarchy.length; i++) {
      if (this.Obj['hier_ident_code'] == this.planaing_hierarchy[i]['hier_ident_code']) {
        max_level = this.planaing_hierarchy[i]['level_count']
        break;
      }
    }
    //console.log(max_level, this.selected_row, this.existing_heirarchy_nodes)
    for (let j = 1; j <= max_level; j++) {
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        let node_arr = []
        node_arr = this.existing_heirarchy_nodes[i]['path'].split('|')
        //console.log(node_arr, 'noida arr', this.existing_heirarchy_nodes[i], node_arr.includes(this.selected_row['node_cd']), this.selected_row['node_cd'])
        if (node_arr.includes(this.selected_row['node_cd'])) {
          if (this.existing_heirarchy_nodes[i]['level'] == j && this.existing_heirarchy_nodes[i]['level'] > this.selected_row['level']) {
            dummy.push(this.existing_heirarchy_nodes[i])
          }
        }
      }
      if (dummy.length > 0) {
        break;
      }
    }
    this.planing_arr.push({})
    this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = []
    this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = dummy
    console.log(dummy, 'dummy', this.planing_arr)
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

}
