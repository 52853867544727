import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from '../../service/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { PayrollService } from '../../service/payroll.service';
import { EstablishService } from '../../service/establish.service';
import { EstablishmentService } from '../../service/establishment.service';
import { AllEmpService } from '../../service/all-emp.service';
import { DataAccessService } from '../../service/data-access.service';
import { SettingService } from '../../service/setting.service';
declare var $: any;
@Component({
  selector: 'app-salary-rule',
  templateUrl: './salary-rule.component.html',
  styleUrls: ['./salary-rule.component.css']
})
export class SalaryRuleComponent implements OnInit {



  constructor(private estNewService: EstablishService, public mainService: MainService,
    private settingService: SettingService, private spinner: NgxSpinnerService,
    private dataAccessService: DataAccessService, private allEmpService: AllEmpService,
    private establishmentService: EstablishmentService, private router: Router,
    private snackBar: MatSnackBar, private payableService: PayrollService) { }
  addEstObj :any= {};
  erpUser:any;
  emp_id:any
  b_acct_id:any;
  ALLmonth :any= []
  datasource:any;
  ALLdays:any = []
  alldays:any = []
  leap :any= {}
  alldata:any = []
  allleap_days :any= []
  display: boolean = false;
  updays :any= []
  alldatadays:any = []
  allarr:any = []
  displayedColumns :any= ['MONTH', 'NO OF DAYS (GENERAL)', 'NO OF DAYS(LEAP YEAR)'];
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getmonth()
    //  await this.changeemployement();
    await this.getAccountProjectHier();
  }
  async getmonth() {
    var obj :any = new  Object()
    var resp = await this.settingService.getmonthValue(this.b_acct_id);
    console.log(resp)
    if (resp['error'] == false) {
      this.ALLmonth = resp.data
      console.log(this.ALLmonth)
    }
  }
  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  changetype() {

  }

  //m_avinash
  isDuplicate:any = false;
  async submit() {
    if (this.projherObj['herarchy_type'] == undefined || this.projherObj['herarchy_type'] == null ||
      this.projherObj['lvl'] == null || this.projherObj['lvl'] == undefined) {
      swal.fire('Warning', '* Field Required', 'warning');
      return;

    }
    var obj :any = new  Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['herarchy_type'] = this.projherObj['herarchy_type']
    obj['lvl'] = this.projherObj['lvl']
    //obj['emp_type_code']=this.addEstObj['establishment_type_code']
    var resp = await this.estNewService.getemployement(JSON.stringify(obj));

    if (resp['error'] == false) {
      this.allarr = resp.data
      // var valueArr = this.allarr.map(function(item){ return item.herarchy_type, item.lvl });
      // var isDuplicate = valueArr.some(function(item, idx){
      //     return valueArr.indexOf(item) != idx
      // });

      this.allarr.filter((x:any) => {
        if (x.herarchy_type == this.projherObj['herarchy_type'] && x.lvl == this.projherObj['lvl']) {
          this.isDuplicate = true;
        }
        else {
          this.isDuplicate = false;
        }
      })
      // console.log(!isDuplicate);
      // console.log(this.allarr)
      if (this.isDuplicate == false) {
        let obj:any = {}
        obj['b_acct_id'] = this.b_acct_id
        for (let i = 0; i < this.ALLmonth.length; i++) {
          this.ALLdays[i] = this.ALLdays[i]

          //console.log(this.ALLdays[i])

          this.alldays.push(this.ALLdays[i])

        }
        obj['month'] = this.ALLmonth
        obj['emp_type'] = this.addEstObj['establishment_type_code']
        // console.log(obj['month'])
        this.leap['leap_date'] = 29
        //console.log(this.alldays)
        obj['day'] = this.alldays
        obj['leap'] = this.leap['leap_date']
        //  console.log(obj['day'])
        //  console.log(obj['leap'])

        obj['create_user_id'] = this.erpUser.user_id;
        obj['lvl'] = this.projherObj['lvl']
        obj['herarchy_type'] = this.projherObj['herarchy_type']

        console.log("send the obj ---", obj)
        var resp1 = await this.estNewService.addcalendardays(obj);
        //  console.log(resp1)
        if (resp1['error'] == false) {
          this.ALLdays = [];
          this.leap = [];
          swal.fire("Success", "ADDED DAYS", 'success')
        }

      }
      else {
        swal.fire('Info', 'Data Already Added', 'info');
        return;
      }
    }

  }
  async open_create() {
    await this.getmonth()
    // $("#tab3").sho
    document.getElementById("ex1-tab-2")?.click()

    this.display = true

  }

  // m_avinash update
  async allupdate() {
    var obj :any = new  Object()
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj['b_acct_id'])
    obj['emp_type'] = this.addEstObj['establishment_type_code']
    obj['month'] = this.ALLmonth
    for (let i = 0; i < this.alldata.length; i++) {
      this.alldata[i]['no_of_days_gen'] = Number(this.alldata[i]['no_of_days_gen'])
      console.log(this.alldata[i]['no_of_days_gen'])
      this.updays.push(this.alldata[i]['no_of_days_gen'])
    }
    console.log(this.updays)
    obj['day'] = this.updays
    console.log(obj['day'])
    this.leap['leap_date'] = 29
    obj['leap'] = this.leap['leap_date']

    obj['lvl'] = this.projherObj['lvl']
    obj['herarchy_type'] = this.projherObj['herarchy_type']
    console.log(obj)
    // var resp = await this.establishmentService.updatesuspension(this.suspensionUpdtObj);

    var resp = await this.estNewService.updatecalendardays(obj);
    console.log(resp.data.length)
    console.log(resp)
    if (resp['error'] == false) {
      //console.log("working")
      await this.changeemployement();
      this.alldata = [];
      //$('.nav-tabs a[href="#tab-1"]').tab('show');
      swal.fire("Successfully", "Updated", 'success')
    }
    else {
      //console.log("notworking")
      swal.fire("Successfully", "Not Updated", 'success')
    }
  }

  //m_avinash
  async refrace() {
    this.planing_arrproj.length = 0
    this.alldata = [];
    this.Obj = {};
    //  this.dummy2=[]
  }




  // Avinash mIshra
  // herarchy add--------

  planing_arrproj :any= [];
  projecthier:any = []
  projhierobj :any= {}
  projhierobjtype :any= {}
  projhierobjtypecd:any = {}
  projhierobjisleaf:any = {}
  Obj :any= {}
  allProject:any = [];
  joiningObj:any = {};
  // ind_emp_id=[]




  async getAccountProjectHier() {
    var obj :any = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAccountProjectHier(obj);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'HR-root'
        }
      }
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {

        var tt = []
        for (let k = 1; k < 8; k++) {
          if (resp.data[i]['lvl' + k + '_cd'] != null) {
            tt.push(resp.data[i]['lvl' + k + '_cd'])
          }
        }
        this.dataAccessService.data_assigment = this.dataAccessService.data_assigment.filter((x:any) => x.user_id == this.erpUser.user_id)
        var exit_flag = false;
        for (let j = 0; j < this.dataAccessService.data_assigment.length; j++) {
          if (tt.includes(parseInt(this.dataAccessService.data_assigment[j]['node_cd']))) {
            exit_flag = true;
          }
        }

        if (tt.length == 1) {
          exit_flag = true;
        }
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR"))
          && (resp.data[i]['module_cd'] != null || resp.data[i]['module_cd'] != undefined) && exit_flag) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;
      this.allProject = resp.data;
      this.projecthier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_user_cd']
          this.projhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']
        }
      }
    } else {
      console.log("Error in getting all project hier")
    }
  }

  open_activitypopup() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1)
    }

    console.log("Open Pop Up", this.planing_arrproj)
    $('#ACTHIER').modal('show');
  }

  // officeTime={fromTime:'', toTime:"", grace:"", number:'', frequency:''}
  down(item:any, k:any) {
    let patharr = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }
    // this.officeTime={fromTime:'', toTime:"", grace:"", number:'', frequency:''}
    this.Obj['path_desc'] = patharr.join("-->")
    // this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [], office_time:[] })
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [] })


    console.log("planing_arrproj---", this.planing_arrproj, "item", item)
    let arr :any= []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_cd'] != null) {

        if ((this.projecthier[i]['lvl' + (k + 1) + '_cd'] == item['hierarchy_type'] || k == -1)/*  && this.projecthier[i]['leaf_node_type'] != 'HREMP' */) {

          // this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'], officeRule:this.officeTime})
          this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'] })
          arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
          // this.planing_arrproj[k+1]['office_time'].push({node_cd:this.projecthier[i]['lvl' + (k + 2) + '_cd'],...this.officeTime});
          // this.planing_arrproj[k+1]['office_time'].push({node_cd:this.projecthier[i]['lvl' + (k + 2) + '_cd']});

        }

        // this.planing_arrproj['office_time'].push(this.officeTime);

      }
      // this.planing_arrproj['Office_time'].push({ offICe_TIME:this.officeTime});
    }
  }

  remove(item:any, i:any) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i])

        patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
      }
      this.Obj['path_desc'] = patharr.join("-->")
    }

  }
  // emp_id;
  // ruleOffice=[]
  projherObj:any = {}
  async submitProjectHierNode() {
    // console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']);
    // if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
    //   Swal.fire("Warning", "Please Select at least two level", 'warning')
    // }
    // else {
    //   $('#ACTHIER').modal('hide');
    // }
    // console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'])
    // console.log(this.planing_arrproj.length)
    // this.Obj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'];
    // var lvl_no = this.planing_arrproj.length;


    if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
      swal.fire("Warning", "Please Select at least two level", 'warning')
    }
    else {
      $('#ACTHIER').modal('hide');
    }
    console.log("herarchy type-1-", this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'])
    console.log("level-1-", this.planing_arrproj.length)
    // console.log("level-2-", this.check1)
    this.Obj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'];
    var lvl_no = this.planing_arrproj.length;
    this.projherObj['herarchy_type'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']
    //  this.projherObj['lvl']=this.planing_arrproj.length;
    //  this.projherObj['lvl']=this.check1;

    // this.ruleOffice= this.planing_arrproj[this.planing_arrproj.length - 1]['office_time'].filter((avi)=> avi.node_cd==this.Obj['node_cd'])
    // console.log("this is thge ruleoffice",this.ruleOffice)

    // this.ind_emp_id = [];
    var temp = [];
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]['lvl' + lvl_no + '_cd'] == this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] && this.projecthier[i]['leaf_node_type'] == 'HREMP') {
        temp.push(this.projecthier[i]['leaf_user_cd'])
      }
    }
    // console.log('this is the temp data --', temp);
    // for (let i = 0; i < temp.length; i++) {
    //   for (let j = 0; j < this.allEmplyees_new.length; j++) {
    //     if (temp[i] == 'HRARR' + this.allEmplyees_new[j]['arr_id']) {
    //       this.ind_emp_id.push(this.allEmplyees_new[j]['arr_id'])
    //        this.emp_id=(this.allEmplyees_new[j]['emp_id'])
    //     }
    //   }
    // }

    // console.log(this.ind_emp_id)





  }

  check1:any

  setHierarchyNodeitem(item:any, i:any) {
    console.log("submit herarchy ", item, i);
    this.check1 = item['hierarchy_type']

    item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']]

    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }
    // console.log('herarchy ', this.planing_arrproj);
    // console.log('is leaf ',  item['is_leaf'] );
    console.log("check 1 is ", this.check1);
    console.log("check levelll ", i + 1);
    this.projherObj['lvl'] = i + 1
    // console.log(' leaf ',this.projherObj['leaf']=item['is_leaf'])
    // console.log(' levelll ',this.projherObj['level']=item['level'])
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }


  //  end of herarchy


  // m_avinash




  async check_rule() {

    if (this.Obj['path_desc'] == undefined || this.Obj['path_desc'] == null || this.Obj['path_desc'] == "") {
      swal.fire('Warning', '* Field Required', 'warning');
      return;

    }

    console.log(" this is hte val ==", this.projherObj['lvl']);
    await this.changeemployement();
  }


  async changeemployement() {
    var obj :any = new  Object()
    obj['b_acct_id'] = this.b_acct_id
    // console.log(obj['b_acct_id'])
    obj['emp_type_code'] = this.addEstObj['establishment_type_code']
    // console.log(obj['emp_type_code'])
    obj['herarchy_type'] = this.projherObj['herarchy_type']
    obj['lvl'] = this.projherObj['lvl']
    var resp = await this.estNewService.getemployement(JSON.stringify(obj));
    //console.log(resp)
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        swal.fire('Info', "No data available", 'info');
        this.alldata = resp.data;
        return;
      }
      this.alldata = resp.data
      console.log(this.alldata)
      //  console.log("working")
    }
    else {
      // console.log("not working")
    }
  }






}
