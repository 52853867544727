
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VmsSettingService {


  constructor(private http: HttpClient, private main: MainService) {
  }
  //fields
  async getFields(obj:any) {

    const res = await this.http.get<any>(this.main.httpUrl + '/vms/fields/getfields' + obj).toPromise().then(res => {
      return res;

    });
    return res;

  }
  async createFields(obj:any) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/vms/fields/createField', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateFields(obj:any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/vms/fields/updateField', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }



  //codeValue
  async getCodeValue() {
    const resp = await this.http.get<any>(this.main.httpUrl + '/vms/codeValue/getCodeValues').toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getFieldCodeValues(obj:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/vms/codeValue/getFieldCodeValues' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertCodeValue(obj:any) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/vms/codeValue/createCodeValue', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateCodeValues(obj:any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/vms/codeValue/updateCodeValue', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteCodeValue(obj:any) {
    const resp = await this.http.delete<any>(this.main.httpUrl + '/vms/codeValue/deleteCodeValue' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

}
