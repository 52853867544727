import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import { MainService } from '../service/main.service';
import { LedgerService } from '../service/ledger.service';
import { ContraService } from '../service/contra.service';
import { ClosingStockService } from '../service/closing-stock.service';
import { ChartOfAccountService } from '../service/chart-of-account.service';
import swal from 'sweetalert2';

declare var $: any
@Component({
  selector: 'app-closing-stock',
  templateUrl: './closing-stock.component.html',
  styleUrls: ['./closing-stock.component.css']
})
export class ClosingStockComponent implements OnInit {


  constructor(
    private closingStockService: ClosingStockService,
    private ChartOfAccountService: ChartOfAccountService,
    private ledgerService: LedgerService,
    public mainService: MainService,
    private spinner: NgxSpinnerService) { }
  erpUser:any;
  b_acct_id:any;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns = ['fin_year', 'final_stock_amt',  'type_of_accounting', 'stock_cat', 'status', 'action']; //'balancesheet_coa',
  datasource:any;
  systemDate:any;
  orgShortName:any;
  fin_year:any;
  obj :any= { data: [], valution_base: 'Quantity' };
   ob : any
  type:any = [{ code: 'PL', value: 'P&L' }, { code: 'COGS', value: 'COGS' }];
  valution_base = [{ code: 'Quantity', value: 'Quantity' }, { code: 'Amount', value: 'Amount' }];
  depletion:any = [{ code: 'PERCENTAGE', value: 'PERCENTAGE' }, { code: 'FIXED', value: 'FIXED' }];

  stock_cat = [{ code: 'Raw Stock', value: 'Raw Stock' },
  { code: 'In Progress Stock', value: 'In Progress Stock' },
  { code: 'Finished Stock', value: 'Finished Stock' }];


  qty_flag = true
  async ngOnInit() {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    var resp = await this.mainService.getSystemDate();
    this.systemDate = resp.data;
    this.orgShortName = await this.mainService.accInfo['account_short_name'];
    await this.getAllChartOfAccount();
    await this.getFinYear();
    await this.getActiveFinYear();
    await this.getAllClosingStock();
    this.obj['valution_base'] = 'Quantity';
    this.qty_flag = true;



  }
  async changeValutionBase() {
    if (this.obj['valution_base'] == 'Quantity') {
      this.qty_flag = true;
      this.obj['data'] = []
    } else {
      this.qty_flag = false;
      this.obj['data'] = []

    }

  }

  stock_item:any = [];
  Calculate() {
    this.stock_item = this.obj['data'];
    this.stock_item.map((x:any) => {
      if (x['cost_amt'] > x['real_value']) {
        x['stock_amt'] = x['real_value']
      } else {
        x['stock_amt'] = x['cost_amt']
      }
    })
  }

  cheak() {
    this.obj['final_stock_amt'] = 0;
    this.stock_item.map((x:any) => {
      this.obj['final_stock_amt'] = this.obj['final_stock_amt'] + x['stock_amt']
    })
    if (this.obj['depletion_type'] == 'FIXED') {
      this.obj['final_stock_amt'] = this.obj['final_stock_amt'] - this.obj['depletion_amt']
    } else {
      this.obj['final_stock_amt'] = this.obj['final_stock_amt'] - ((this.obj['depletion_amt'] * this.obj['final_stock_amt']) / 100)
    }
  }

  allChartOfAccountobj:any = {};
  allChartOfAccount:any = []
  async getAllChartOfAccount() {
    this.spinner.show()
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ChartOfAccountService.getchartofaccount(obj);
    if (resp['error'] == false) {
      this.allChartOfAccount = resp.data;
      for (let i = 0; i < this.allChartOfAccount.length; i++) {
        this.allChartOfAccountobj[this.allChartOfAccount[i]['leaf_code']] = this.allChartOfAccount[i]['leaf_value']
      }
      this.spinner.hide()
    } else {
      this.spinner.hide()
    }
  }
  allFinYear :any= []
  async getFinYear() {
    this.refresh()
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getAllFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allFinYear = resp.data;
      for (let i = 0; i < this.allFinYear.length; i++) {
        this.allFinYear[i]['desc'] = this.allFinYear[i]['fin_year'] + "-" + (parseInt(this.allFinYear[i]['fin_year']) + 1)
      }
      this.spinner.hide()

    } else {
      this.spinner.hide()

    }
  }

  async changeContraStatus(element:any) {
    this.spinner.show();
    let obj = Object.assign(element)
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'APPROVED'
    obj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.closingStockService.changeCloasingStockStatus(obj);
    if (resp['error'] == false) {
      await this.getAllClosingStock();
      this.spinner.hide();
      swal.fire("Success", "Approve Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while approve contra !", 'error');
    }
  }


  async getAllClosingStock() {
    this.spinner.show()
    var resp = await this.closingStockService.getAllClosingStock(JSON.stringify({ b_acct_id: this.b_acct_id }));
    if (resp['error'] == false) {
      this.spinner.hide()
      resp.data.map((x:any) => {
        x['fin_year'] = x['fin_year'] + "-" + (Number(x['fin_year']) + 1)
      })
      this.datasource = new MatTableDataSource(resp.data);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      swal.fire("Error", "..Error while getting getAllClosingStock!", 'error');
    }
  }





  async getActiveFinYear() {
    this.spinner.show()
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getActiveFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      if (resp.data.length == 0) {
        swal.fire("Warning", "..Active Financial Year Not set!", 'warning');
      } else {
        this.fin_year = resp.data[0].fin_year;
      }
    } else {
      this.spinner.hide()
      swal.fire("Error", "..Error while getting active  fin year!", 'error');
    }
  }



  addRow() {
    var obj :any= new Object();
    obj['item_desc'] = "";
    obj['rate'] = 0;
    obj['qty'] = 0;
    obj['amt'] = 0;
    this.obj.data.push(obj)
  }

  total = 0
  ChangeAmt(i:any) {
    this.total = 0;
    this.obj.data.map((x:any) => {
      this.total = this.total + (x['rate'] * x['qty'])
    })
    this.obj.data[i]['amt'] = this.obj.data[i]['qty'] * this.obj.data[i]['rate']
  }
  deleteRowUpdate(i:any) {
    this.obj['data'].splice(i, 1);
  }
  addRowUpdate() {
    var obj :any= new Object();

    obj['item_desc'] = "";
    obj['act_qty'] = 0;
    obj['act_rate'] = 0;
    obj['cost_amt'] = 0;
    obj['real_qty'] = 0;
    obj['real_rate'] = 0;
    obj['real_amt'] = 0;
    obj['cost_upto_sale'] = 0;
    obj['real_value'] = 0;
    obj['stock_amt'] = 0;

    this.obj['data'].push(obj)
  }

  deleteRow(i:any) {
    this.obj.data.splice(i, 1);
  }

  Change_act_qty(i:any) {
    this.total = 0;
    this.obj.data.map((x:any) => {
      this.total = this.total + (x['act_qty'] * x['act_rate'])
    })

    this.obj.data[i]['cost_amt'] = this.obj.data[i]['act_qty'] * this.obj.data[i]['act_rate']
    this.obj.data[i]['real_amt'] = this.obj.data[i]['act_qty'] * this.obj.data[i]['act_rate']
    this.obj.data[i]['real_qty'] = this.obj.data[i]['act_qty']
    this.obj.data[i]['real_rate'] = this.obj.data[i]['act_rate']
  }
  Change_cost_amt(i:any) {
    this.total = 0;
    this.obj.data.map((x:any) => {
      this.total = this.total + (x['cost_amt'])
    })

    this.obj.data[i]['real_amt'] = this.obj.data[i]['real_amt']
    this.obj.data[i]['real_qty'] = 0
    this.obj.data[i]['real_rate'] = 0

  }
  Change_real_rate(i:any) {
    this.obj.data[i]['real_amt'] = this.obj.data[i]['real_qty'] * this.obj.data[i]['real_rate']
  }

  Change_real_amt(i:any) {
    this.obj.data[i]['real_qty'] = 0
    this.obj.data[i]['real_rate'] = 0
  }

  Change_cost_upto_sale(i:any) {
    this.obj.data[i]['real_value'] = this.obj.data[i]['real_amt'] - this.obj.data[i]['cost_upto_sale']
  }

  async open_update(element:any) {
    this.obj = Object.assign({}, element)
    console.log(element)
    this.obj['fin_year'] = parseInt(this.obj['fin_year'])
    this.obj['fin_year_desc'] = parseInt(this.obj['fin_year']) + "-" + (Number(this.obj['fin_year']) + 1)
    this.spinner.show()
    let resp = await this.closingStockService.getClosingStockByID(JSON.stringify({ b_acct_id: this.b_acct_id, id: element['id'] }));
    if (resp['error'] == false) {
      this.obj['data'] = resp.data;
      this.stock_item = resp.data;
      this.spinner.hide()
    } else {
      this.spinner.hide()
      swal.fire("Error", "..Error while getting getClosingStockByID!", 'error');
    }
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.obj = { data: [], valution_base: 'Quantity' };
  }







  async delete(element:any) {
    this.spinner.show();
    let obj :any= new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = element['id']

    var resp = await this.closingStockService.deleteClosingStock(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllClosingStock();

      swal.fire("Success", "...Cloasing Stock Deleted Successfully!!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while deleting Cloasing Stock!", 'error');
    }
  }


  async update() {
    this.spinner.show()
    // if (!this.validation()) {
    //   return;
    // }
    this.spinner.show();
    let obj = Object.assign({}, this.obj)
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'GENERATED';
    obj['data'] = this.stock_item
    obj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.closingStockService.updateClosingStock(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllClosingStock();
      $('.nav-tabs a[href="#tab-1"]').tab('show')
      document.getElementById('reset2')!.click();
      swal.fire("Success", "...Contra Update Successfully!!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while updating contra!", 'error');

    }
  }


  // validation() {
  //   let flag = true;
  //   let quantity = false
  //   let rate = false;
  //   if (!this.obj['fin_year']) {
  //     swal.fire("Warning", "Please select Financial Year", 'warning');
  //     flag = false;
  //     return flag;
  //   }
  //   if (!this.obj['type_of_accounting']) {
  //     swal.fire("Warning", "Please select Type Of Accounting", 'warning');
  //     flag = false;
  //     return flag;
  //   }
  //   if (!this.obj['valution_base']) {
  //     swal.fire("Warning", "Please select Valution Base", 'warning');
  //     flag = false;
  //     return flag;
  //   }
  //   if (!this.obj['stock_cat']) {
  //     swal.fire("Warning", "Please select Categoery", 'warning');
  //     flag = false;
  //     return flag;
  //   }
  //   if (!this.obj['as_on_date']) {
  //     swal.fire("Warning", "Please select As On Date", 'warning');
  //     flag = false;
  //     return flag;
  //   }

  //   if (!this.obj['data'].length) {
  //     swal.fire("Warning", "Please Fill Closing Stock", 'warning');
  //     flag = false;
  //     return flag;
  //   }

  //   this.obj['data'].map(x => {
  //     if (!x['act_qty']) {
  //       quantity = true;
  //       flag = false;
  //       swal.fire('Warning', 'Please Fill Quntity', 'warning');
  //       return flag;
  //     } else if (!x['act_rate']) {
  //       rate = true;
  //       flag = false;
  //       swal.fire('Warning', 'Please Fill Rate', 'warning');
  //       return flag;
  //     }
  //   })

  //   if (!this.obj['depletion_type']) {
  //     swal.fire("Warning", "Please Select Depletion Type", 'warning');
  //     flag = false;
  //     return flag;
  //   }
  //   if (!this.obj['depletion_amt']) {
  //     swal.fire("Warning", "Please Select Depletion Amount", 'warning');
  //     flag = false;
  //     return flag;
  //   }
  //   return flag;
  // }

  async submit() {
    this.spinner.show()

    //  if (!this.validation()) {
    //   this.spinner.hide();
    //   return;
    // }
    let obj = Object.assign({}, this.obj)
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'GENERATED';
    obj['data'] = this.stock_item
    obj['create_user_id'] = this.erpUser.user_id
    var resp = await this.closingStockService.createClosingStock(obj);
    if (resp['error'] == false) {
      await this.getAllClosingStock()
      this.spinner.hide();
      $('.nav-tabs a[href="#tab-1"]').tab('show')
      document.getElementById('reset')!.click();
      swal.fire("Success", "Cloasing Stock Created Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while creating Cloasing Stock!", 'error');
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }



}
