<div class="page-content fade-in-up">
  <div class="row">

    <div class="col-lg-12 col-md-12">

      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">
            <!-- All Employees  -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp1']}}
          </div>
        </div>
        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link active" *ngIf="list_flag" data-toggle="tab"><i class="ti-bar-chart"></i>
                <!-- All Employee -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp1']}}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" *ngIf="view_flag" data-toggle="tab"><i class="ti-settings"></i>
                <!-- More Details -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp2']}}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" *ngIf="update_flag" data-toggle="tab"><i class="ti-settings"></i>
                <!-- Update Details -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp3']}}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" *ngIf="doc_flag" data-toggle="tab"><i class="ti-announcement"></i>
                <!-- Employee Document -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp4']}}</a>
            </li>
            <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-5" data-toggle="tab"><i class="ti-announcement"></i>
                                Update Date of Birth{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp5']}}
                                </a>
                        </li> -->
            <li class="nav-item">
              <a class="nav-link" *ngIf="phone_flag" data-toggle="tab"><i class="ti-settings"></i> Update Phone No
              </a>
            </li>


          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">


              <mat-form-field style="margin-left: 2%">
                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
              </mat-form-field>
              <div class=" example-container">
                <table mat-table [dataSource]="datasource" matSort>
                  <ng-container matColumnDef="emp_id">
                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                      <!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp6']}}

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                      {{element.emp_id}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="emp_name">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp7']}}
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.emp_name }} </td>
                  </ng-container>

                  <ng-container matColumnDef="emp_email">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- Employee Email -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp8']}}

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.emp_email}} </td>
                  </ng-container>

                  <ng-container matColumnDef="emp_father_name">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- Father's Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp9']}}
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.emp_father_name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="emp_phone_no">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- Employee Phone No -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp10']}}
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.emp_phone_no}} </td>
                  </ng-container>



                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp11']}}
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">

                      <button class="btn btn-primary"
                        (click)="openUpdate(element)"><!-- View More -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp12']}}
                      </button>&nbsp;&nbsp;
                      <button class="btn btn-success" (click)="viewDocumments(element)">View Documents
                      </button>&nbsp;&nbsp;
                      <button class="btn btn-info" (click)="Update(element)">Update Details
                      </button>
                      <!-- Avinash  -->
                      <button *ngIf="phDisabled==true" class="btn btn-success" (click)="updatePhoneNo(element)">Update
                        Phone No
                      </button>

                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                  </tr>
                </table>
                <mat-paginator #paginator2 [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
              </div>


            </div>
            <div class="tab-pane fade show active" *ngIf="view_flag" id="tab-2">
              <br>
              <div class="row">
                <div class="col-11 text-right">
                  <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to
                    List</button>
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-3">

                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Employee Category Code -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp14']}}
                  </h6>
                </div>
                <div class="col-3 ">
                  <h6>{{empObj['emp_cat_code']}}</h6>
                </div>
                <div class="col-3">

                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp6']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_id']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp7']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_name']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Gender -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp15']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_sex']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Email -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp16']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_email']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Phone No -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp17']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_phone_no']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- DOB -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp18']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_dob'] | date: 'dd/MM/yyyy'}}
                </div>
              </div>
              <br>

              <!-- joining date and service date -->
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Joining Date -->Joining Date
                  </h6>
                </div>
                <div class="col-3">
                  <!-- {{empObj['joining_date'] | date: 'dd/MM/yyyy'}} -->
                  {{joining_date}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Service Date -->Service Date
                  </h6>
                </div>
                <div class="col-3">
                  <!-- {{empObj['service_date'] | date: 'dd/MM/yyyy'}} -->
                  {{service_date}}
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Father's Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp9']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_father_name']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Permanent Address Line 1 -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp19']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_permanent_addr_line1']}}
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Permanent Address Line 2 -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp20']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_permanent_addr_line2']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Permanent City -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp21']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_permanent_addr_city']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Permanent Address District -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp22']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_permanent_addr_dist']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Permanent Address State -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp23']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_permanent_addr_state']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Permanent Address Pin -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp24']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_permanent_addr_pin_code']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Local Address Line -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp25']}}
                    1
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_local_addr_line1']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Local Address Line -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp25']}}
                    2
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_local_addr_line2']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Local City -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp26']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_local_addr_city']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Local Address District -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp27']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_local_addr_dist']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Local Address State -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp28']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_local_addr_state']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Local Address Pin -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp29']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_local_addr_pin_code']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Employee's PAN -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp30']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_pan_no']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Adhar No -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp31']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_adhar_no']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Mother's Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp32']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_mother_name']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Identification Mark -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp33']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['identification_mark']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Reservation Category -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp34']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['reservation_category_code']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Marital Status -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp35']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['marital_status']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Husband Name If Applicable -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp36']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_husband_name']}}
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <h6>
                    <!-- Religeon -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp37']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_religeon']}}
                </div>
                <div class="col-3 text-right">
                  <h6>
                    <!-- Nationality -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp38']}}
                  </h6>
                </div>
                <div class="col-3">
                  {{empObj['emp_nationality']}}
                </div>
              </div>
              <br>

              <br>


            </div>
            <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-3">
              <br>
              <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                <div class="col-12 text-center">
                  {{errorMsg}}
                </div>
              </div>
              <div class="row">
                <div class="col-11 text-right">
                  <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to
                    List</button>
                </div>
              </div>
              <br>




              <!-- <div class="row">
                                <div class="col-4 text-right">
                                -- Select Employee --{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp39']}} :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" (change)="changeEmployee()" bindValue="emp_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp39']}}" [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="false" name="field_name">
                                    </ng-select>
                                </div>
                            </div> -->
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp6']}}
                </div>
                <div class="col-3">

                  <!-- {{mainService.accInfo['account_short_name']}} --> <input type="text" size="10"
                    class="form-control" value="{{personalInfoObj['emp_id']}}" disabled />
                </div>
                <div class="col-3 text-right">
                  <!-- Employee Category Code -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp14']}}:
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['HR0046']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="" [(ngModel)]="personalInfoObj['emp_cat_code']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                    name="emp_cat_code">
                  </ng-select>
                </div>



              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp7']}}:
                </div>
                <div class="col-3">
                  <input class="form-control" value="{{personalInfoObj['emp_name']}}" disabled />

                </div>
                <div class="col-3 text-right">
                  <!-- Employee Email -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp8']}}:
                </div>
                <div class="col-3">

                  <input size="10" class="form-control" [(ngModel)]="personalInfoObj['emp_email']" disabled>
                </div>

              </div>
              <br>
              <!-- Avinash Mishra -->
              <div class="row">
                <div class="col-3 text-right">
                  <!-- Employee Phone No.  -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp10']}} :
                  <!-- <span *ngIf="phDisabled==false" style="color:red">*</span>: -->
                </div>
                <div class="col-3">
                  <!-- <input size="10" class="form-control" [(ngModel)]="personalInfoObj['emp_phone_no']" [disabled]="phDisabled">  -->
                  <input size="10" class="form-control" [(ngModel)]="personalInfoObj['emp_phone_no']" disabled>
                </div>
                <div class="col-3 text-right">
                  <!-- Employee DOB -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp40']}}:
                </div>
                <div class="col-3">
                  <input class="form-control" [(ngModel)]="personalInfoObj['emp_dob']" disabled>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- Father's Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp9']}} <span
                    style="color: red;">*</span>:
                </div>
                <div class="col-3">
                  <input class="form-control" [(ngModel)]="personalInfoObj['emp_father_name']">
                </div>
                <div class="col-3 text-right">
                  <!-- Employee's PAN -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp30']}} <span
                    style="color: red;">*</span>:
                </div>
                <div class="col-3">
                  <input class="form-control" [(ngModel)]="personalInfoObj['emp_pan_no']">
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- Employee Adhar No. -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp41']}}
                  <span style="color: red;">*</span>:
                </div>
                <div class="col-3">
                  <input size="12" class="form-control" [(ngModel)]="personalInfoObj['emp_adhar_no']">
                </div>
                <div class="col-3 text-right">
                  <!-- Gender -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp15']}}:
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['HR0008']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="" [(ngModel)]="personalInfoObj['emp_sex']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!--  Mother's Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp32']}} <span
                    style="color: red;">*</span>:
                </div>
                <div class="col-3">
                  <input type="text" class="form-control" [(ngModel)]="personalInfoObj['emp_mother_name']">
                </div>
                <div class="col-3 text-right">
                  <!-- Reservation Category -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp34']}}:
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['HR0037']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="" [(ngModel)]="personalInfoObj['reservation_category_code']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- Marital Status -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp35']}} :
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['HR0038']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="" [(ngModel)]="personalInfoObj['marital_status']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>

                </div>
                <div class="col-3 text-right">
                  <!-- Husband Name If Applicable -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp36']}}
                  :
                </div>
                <div class="col-3">
                  <input type="text" class="form-control" [(ngModel)]="personalInfoObj['emp_husband_name']">
                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- Religion -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp42']}} :
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['HR0039']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="" [(ngModel)]="personalInfoObj['emp_religeon']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
                <div class="col-3 text-right">
                  <!-- Nationality -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp38']}}:
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['HR0040']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="" [(ngModel)]="personalInfoObj['emp_nationality']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- Identification Mark -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp33']}} :
                </div>
                <div class="col-3">
                  <textarea class="form-control" rows="4" cols="50" type="text" name="identification_mark"
                    [(ngModel)]="personalInfoObj['identification_mark']"
                    placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp33']}}"></textarea>
                </div>


              </div>
              <br>
              <h5 style="text-align:center">
                <b><i>
                    <!-- PERMANANT ADDRESS -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp43']}}</i></b>
              </h5>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- Address Line -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp44']}} 1 :
                  <span style="color: red;">*</span>
                </div>
                <div class="col-3">
                  <textarea class="form-control" rows="4" cols="50" type="text" name="party_permanent_addr_line1"
                    [(ngModel)]="personalInfoObj['emp_permanent_addr_line1']"
                    placeholder="Current Address Line 1"></textarea>
                </div>
                <div class="col-3 text-right">
                  <!-- Address Line -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp44']}} 2 :
                  <span style="color: red;">*</span>
                </div>
                <div class="col-3">
                  <textarea class="form-control" rows="4" cols="50" type="text" name="party_permanent_addr_line2"
                    [(ngModel)]="personalInfoObj['emp_permanent_addr_line2']"
                    placeholder="Current Address Line 2"></textarea>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!--  City -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp45']}} : <span
                    style="color: red;">*</span>
                </div>
                <div class="col-3">
                  <input class="form-control" onkeydown="return /[A-Za-z ]/i.test(event.key)"
                    [(ngModel)]="personalInfoObj['emp_permanent_addr_city']">



                </div>
                <div class="col-3 text-right">
                  <!-- State -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp46']}} : <span
                    style="color: red;">*</span>
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['HR0009']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="" [(ngModel)]="personalInfoObj['emp_permanent_addr_state']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!--  District -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp47']}} : <span
                    style="color: red;">*</span>
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['HR0010']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="" [(ngModel)]="personalInfoObj['emp_permanent_addr_dist']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
                <div class="col-3 text-right">
                  <!-- PIN Code -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp48']}} : <span
                    style="color: red;">*</span>
                </div>
                <div class="col-3">

                  <input class="form-control"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    type="number" maxlength="6" [(ngModel)]="personalInfoObj['emp_permanent_addr_pin_code']">

                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-12">
                  <h6 style="color:green;text-align: center; ">Local Address Is Same As Permanent Address <input
                      type="checkbox" (click)="checkAvi($event)" /> </h6>
                </div>
              </div>
              <br>
              <h5 style="text-align: center;">
                <b><i>
                    <!-- LOCAL ADDRESS -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp49']}}</i></b>
              </h5>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- Address Line -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp44']}} 1 :
                </div>
                <div class="col-3">
                  <textarea class="form-control" rows="4" cols="50" type="text" name="party_local_addr_line1"
                    [(ngModel)]="personalInfoObj['emp_local_addr_line1']" placeholder=""
                    disabled={{aviCheck}}></textarea>
                </div>
                <div class="col-3 text-right">
                  <!-- Address Line -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp44']}} 2 :
                </div>
                <div class="col-3">
                  <textarea class="form-control" rows="4" cols="50" type="text" name="party_local_addr_line2"
                    [(ngModel)]="personalInfoObj['emp_local_addr_line2']" placeholder=""
                    disabled={{aviCheck}}></textarea>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- City -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp45']}} :
                </div>
                <div class="col-3">
                  <input class="form-control" onkeydown="return /[A-Za-z ]/i.test(event.key)"
                    [(ngModel)]="personalInfoObj['emp_local_addr_city']" disabled={{aviCheck}}>



                </div>
                <div class="col-3 text-right">
                  <!-- State -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp46']}} :
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['HR0009']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="" [(ngModel)]="personalInfoObj['emp_local_addr_state']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name"
                    disabled={{aviCheck}}>
                  </ng-select>

                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3 text-right">
                  <!-- District -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp47']}} :
                </div>
                <div class="col-3">
                  <ng-select [items]="mainService.codeValueTechObj['HR0010']" bindLabel="value" bindValue="code"
                    [multiple]="false" placeholder="" [(ngModel)]="personalInfoObj['emp_local_addr_dist']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name"
                    disabled={{aviCheck}}>
                  </ng-select>
                </div>
                <div class="col-3 text-right">
                  <!-- PIN Code -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp48']}}:
                </div>
                <div class="col-3">
                  <input class="form-control" class="form-control"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    type="number" maxlength="6" [(ngModel)]="personalInfoObj['emp_local_addr_pin_code']"
                    disabled={{aviCheck}}>

                </div>

              </div>
              <br>

              <div class="row">
                <div class="col-12 text-center">
                  <button class="btn btn-primary"
                    (click)="updatePersonalInfo()"><!-- Update -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp56']}}</button>

                </div>
              </div>
            </div>
            <div class="tab-pane fade show active" *ngIf="doc_flag" id="tab-4">
              <br>
              <div class="row">
                <div class="col-11 text-right">
                  <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to
                    List</button>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-4 text-right">
                  <!-- Select Employee -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp39']}} ::
                  <span style="color: red;"> *</span>
                </div>
                <div class="col-4">
                  <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="emp_id" [multiple]="false"
                    placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp39']}}"
                    [(ngModel)]="uploadObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                    [closeOnSelect]="true" name="field_name">
                  </ng-select>
                  <!-- {{element.emp_id}} -->
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-4 text-right">
                  <!-- Select Document Type  -->
                  {{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp50']}} : <span style="color: red;">
                    *</span>

                </div>
                <div class="col-4">
                  <ng-select [items]="mainService.codeValueTechObj['HR0045']" bindLabel="value" bindValue="code"
                    [multiple]="false"
                    placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp50']}}"
                    [(ngModel)]="uploadObj['document_type_code']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-4 text-right">
                  Upload Document : <span style="color: red;"> *</span>
                </div>
                <div class="col-4 text-center">
                  <input multiple #file type="file" id="aviDoc" name="pimage" class="form-control"
                    style="border-color: black" (change)="onFileUpload($event,file.files)">
                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <!-- <button type="button" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-info" (click)='upload()'><i class="fa fa-upload"
                                            aria-hidden="true">&nbsp;</i> Upload {{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp51']}}</button> -->
                  <button type="button" class="btn btn-info" (click)='upload()'><i class="fa fa-upload"
                      aria-hidden="true">&nbsp;</i><!-- Upload -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp51']}}</button>

                </div>
              </div>

              <hr>
              <mat-form-field style="margin-left: 2%">
                <input matInput (keyup)="applyFilter1($any($event.target).value)" placeholder="Filter">
              </mat-form-field>
              <div class=" example-container">
                <table mat-table [dataSource]="datasource1" matSort #sortCol2="matSort">
                  <ng-container matColumnDef="emp_id">
                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                      <!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp6']}}

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                      {{ element.tempid }}</td>
                  </ng-container>

                  <ng-container matColumnDef="emp_name">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp7']}}
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ empNameObj[element.emp_id] }} </td>
                  </ng-container>



                  <ng-container matColumnDef="document_name">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- Document Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp52']}}
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.document_name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="document_type">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- Document Type Code -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp53']}}
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.document_type_code}} </td>
                  </ng-container>



                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp11']}}
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">

                      <button class="btn btn-primary"
                        (click)="view(element)"><!-- View -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp54']}}</button>



                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                  </tr>
                </table>
                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                </mat-paginator>
              </div>
              <br>
              <div *ngIf="closeimage">
                <div class="row " style="justify-content: center;">
                  <button class="btn btn-primary" style="margin: 5px 4px;
                                width: 5rem;" (click)="closeImage()">Close </button>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <img [src]="imgURL" />

                    <!-- <a download="file" [href]="imgURL">{{filename}}</a> -->
                    <!-- <div>{{imgURL}}</div> -->
                  </div>

                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="tab-5">
              <br>
              <div class="row">
                <div class="col-4 text-right">
                  <!-- Select Employee -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp39']}}:
                </div>
                <div class="col-4">
                  <ng-select [items]="allEmplyees_new" bindLabel="emp_name" (change)="changeEmployee()"
                    bindValue="emp_id" [multiple]="false"
                    placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp39']}}"
                    [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                    [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-4 text-right">
                  <!-- Date of Birth -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AllEmp55']}}:
                </div>

                <div class="col-4">
                  <input type="date" class="form-control" [(ngModel)]="personalInfoObj['emp_dob']">
                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <button class="btn btn-primary" (click)="updatedob()"><!-- Update --></button>
                </div>
              </div>

              <br>
            </div>
            <!-- avinash  -->
            <div class="tab-pane fade show active" *ngIf="phone_flag" id="tab-6">
              <br>
              <div class="row">
                <div class="col-11 text-right">
                  <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to
                    List</button>
                </div>
              </div>

              <br>
              <div class="row">
                <div class="col-4 text-right">
                  <!--Old No  -->
                  Current Phone No
                </div>

                <div class="col-4">
                  <input type="number" class="form-control" [(ngModel)]="phoneNoUpdObj['old_no']" disabled>
                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-4 text-right">
                  <!--New Phone No -->
                  New Phone No <span style="color: red"> *</span>:
                </div>

                <div class="col-4">
                  <input
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    type="number" maxlength="10" class="form-control" [(ngModel)]="phoneNoUpdObj['new_no']">
                </div>

              </div>
              <br>

              <div class="row">
                <div class="col-4 text-right">
                  <!--New Phone No -->
                  Employee Name:<span style="color: red"> *</span>:
                </div>

                <div class="col-4">
                  <input class="form-control" [(ngModel)]="phoneNoUpdObj['emp_name']">
                </div>

              </div>
              <br>




              <div class="row">
                <div class="col-12 text-center">
                  <button class="btn btn-primary" (click)="submitPhoneNO()">Submit</button>
                </div>
              </div>

              <br>
            </div>

            <!-- avinash -->
          </div>

        </div>



      </div>
    </div>
  </div>

</div>
<ngx-spinner>Loading....</ngx-spinner>
