<div class="page-content fade-in-up">
  <div class="row">

    <div class="col-lg-12 col-md-12">

      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Lease Sub-Division</div>
        </div>
        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link active" href="#tab-1" *ngIf="list_flag" (click)="reset()" data-toggle="tab"><i
                  class="ti-bar-chart"></i>
                List</a>
              <!-- list details -->
            </li>

            <li class="nav-item active">
              <a class="nav-link " href="#tab-2" *ngIf="create_flag" (click)="reset()" data-toggle="tab"><i
                  class="ti-settings"></i>
                Create Lease Sub-Division</a>
              <!-- Lease list -->
            </li>
          </ul>


          <div class="tab-content">

            <div class="tab-pane fade show active" id="tab-1" *ngIf="list_flag">
              <br>
              <div class="row">
                <div class="col-4">
                  <mat-form-field style="margin-left: 2%">
                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                  </mat-form-field>
                </div>
                <div class="col-4 text-center">

                </div>
                <div class="col-4 text-right">
                  <button (click)="create()" class="btn btn-outline-primary">Create</button>
                </div>

              </div>
              <div class="example-container">

                <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                  <ng-container matColumnDef="lease_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <b>Lease Type</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{element.lease_type}} </td>
                  </ng-container>
                  <ng-container matColumnDef="file_no">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <b>File Number </b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{element.file_num}} </td>
                  </ng-container>
                  <ng-container matColumnDef="plot_no">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <b>Plot/ Khasra No</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{element.assigned_property_number}} </td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="address_of_property">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Address of Property</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.address}}</td>
                                    </ng-container> -->

                  <ng-container matColumnDef="lease_details">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application10']}}</b> -->
                      <b>Lease Details</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{element.leasee_name}} </td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="total_area_of_plot">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Total Area of Plot</b>

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.area}} </td>
                                    </ng-container> -->


                  <ng-container matColumnDef="Sub_divison">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <!-- <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application12']}}</b> -->
                      <b>Sub Division Area</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{element.area}} </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <b>Action</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      <button class="btn btn-outline-success" (click)="view(element)">View</button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                  </tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>

              </div>
            </div>

            <div class="tab2-content" id="tab-2" *ngIf="create_flag">

              <div class="row">
                <div class="col-4"></div>
                <div class="col-4 text-center"></div>
                <div class="col-4 text-right">
                  <button (click)="goback()" class="btn btn-outline-primary">Go to List</button>
                </div>

              </div>

              <br>


              <div class="row">

                <div class="col-4 text-right">
                  <h6 class="mt-2">File Number :<span style="color: red;"> *</span></h6>
                </div>
                <div class="col-4">
                  <ng-select [multiple]="false" placeholder="File Number" [selectableGroup]="true" [items]="allfile"
                    bindLabel="file_num" bindValue="file_num" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                    [(ngModel)]="Obj['fileno']" (change)="getAllData()">
                  </ng-select>
                </div>
                <div class="col-4"></div>

              </div>
              <!-- <br> -->

              <!-- <div class="row">
                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Select Location Hierarchy :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="first_list" bindLabel="hier_buss_name" bindValue="hier_type_id"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                        [selectableGroup]="true" (change)="changeList()" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true" name="chart_of_account0" [disabled]=true>
                                    </ng-select>
                                </div>
                            </div> -->
              <div class="row mt-3" *ngFor="let item of data,let i=index">
                <!-- <div class="col-3"></div> -->
                <div class="col-4 text-right">
                  <h6>Select Level {{i+1}} :<span style="color: red;"> *</span></h6>
                </div>
                <div class="col-4">
                  <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd" [multiple]="false"
                    placeholder="Select..." [(ngModel)]="Obj['lvl1_cd']" (change)='setLeafNode()'
                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0" disabled>
                  </ng-select>
                  <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd" [multiple]="false"
                    (change)='setLeafNode()' placeholder="Select..." [(ngModel)]="Obj['lvl2_cd']"
                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0" disabled>
                  </ng-select>
                  <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()' bindLabel="lvl3_value"
                    bindValue="lvl3_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']"
                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0" disabled>
                  </ng-select>
                  <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()' bindLabel="lvl4_value"
                    bindValue="lvl4_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']"
                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0" disabled>
                  </ng-select>
                  <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()' bindLabel="lvl5_value"
                    bindValue="lvl5_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']"
                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0" disabled>
                  </ng-select>
                  <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()' bindLabel="lvl6_value"
                    bindValue="lvl6_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']"
                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0" disabled>
                  </ng-select>
                  <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()' bindLabel="lvl7_value"
                    bindValue="lvl7_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']"
                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                    name="chart_of_account0" disabled>
                  </ng-select>

                </div>
                <div class="col-3" *ngIf="i==data.length-1">
                  <button (click)="add_dropdown(item,i)" class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                      aria-hidden="true"></i></button>
                  &nbsp;&nbsp;
                  <button (click)="remove_dropdown(item,i)" *ngIf="i!=0" class="btn btn-outline-danger">
                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                </div>
              </div>
              <br>
              <div class="row">

                <div class="col-4 text-right">
                  <h6 class="mt-2">Select Plot Number :<span style="color: red;"> *</span></h6>
                </div>
                <div class="col-4">
                  <ng-select [multiple]="false" placeholder="Select Plot Number" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" [(ngModel)]="Obj['plotno']" disabled>
                    <!-- [(ngModel)]="obj['offer_cd']" -->
                  </ng-select>
                </div>
                <div class="col-4"></div>

              </div>
              <br>


              <div class="row">

                <div class="col-4 text-right">
                  <h6 class="mt-2">Total Area of Plot :<span style="color: red;"> *</span></h6>
                </div>
                <div class="col-4">
                  <ng-select [multiple]="false" placeholder="Total Area of Plot" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" [(ngModel)]="Obj['areaOfPlot']" disabled>
                  </ng-select>
                </div>
                <div class="col-4"></div>

              </div>
              <br>

              <div id="rcorners">
                <div *ngFor="let item of subDivisonData;let i=index">
                  <div *ngIf="i>0">
                    <hr style="background: 1px solid black;">
                  </div>
                  <div class="row">
                    <div class="col-4 text-right">
                      <h6 class="mt-2">new Lease Name :<span style="color: red;"> *</span></h6>
                    </div>
                    <div class="col-4">
                      <input type="text" class="form-control" placeholder="Sub Lease Name"
                        [(ngModel)]="item['newLeaseName']" />
                    </div>
                    <div class="col-4"></div>
                  </div><br>
                  <div class="row">
                    <div class="col-4 text-right">
                      <h6 class="mt-2">New Lease Type :<span style="color: red;"> *</span></h6>
                    </div>
                    <div class="col-4">
                      <ng-select [multiple]="false" placeholder="New Lease Type"
                        [items]="mainService.codeValueTechObj['PROP0015']" bindLabel="code" bindValue="value"
                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                        [(ngModel)]="item['newLeaseType']">
                        <!-- [items]="lease_type" bindLabel="property_type" bindValue="property_type" -->

                      </ng-select>
                    </div>
                    <div class="col-4"></div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-4 text-right">
                      <h6 class="mt-2">New File no. :<span style="color: red;"> *</span>
                      </h6>
                    </div>
                    <div class="col-4">
                      <input type="text" class="form-control" placeholder="New File no." [(ngModel)]="item['newfileno']"
                        (change)="checkFilenum(i)" />
                    </div>
                    <div class="col-4"></div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-4 text-right">
                      <h6 class="mt-2">New Plot no. :<span style="color: red;"> *</span>
                      </h6>
                    </div>
                    <div class="col-4">
                      <input type="text" class="form-control" placeholder="New Plot no."
                        [(ngModel)]="item['newplotno']" />
                    </div>
                    <div class="col-4"></div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-4 text-right">
                      <h6 class="mt-2">New Lease Plot Area :<span style="color: red;"> *</span>
                      </h6>
                    </div>
                    <div class="col-4">
                      <input type="number" class="form-control" placeholder="New Lease Plot Area"
                        [(ngModel)]="item['newLeasePlotArea']" (change)="getRemaining(i)" />
                    </div>
                    <div class="col-4"></div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-4 text-right">
                      <h6 class="mt-2">Remaining Plot Area :<span style="color: red;"> *</span>
                      </h6>
                    </div>
                    <div class="col-4">
                      <input type="number" class="form-control" placeholder="Remaining Plot Area"
                        [(ngModel)]="item['remaining_plot_area']" disabled />
                    </div>
                    <div class="col-4"></div>
                  </div>
                  <br>

                  <div class="row">
                    <div class="col-4 text-right">
                      <h6 class="mt-2">Upload Document :</h6>
                    </div>
                    <div class="col-4">
                      <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader" class="form-control"
                        style="border-color: black" [(ngModel)]="item['newleaseedoc']"
                         (change)="onFileChange($event)">
                    </div>
                    <div class="col-4"></div>
                  </div><br>
                  <div class="row">
                    <div class="col-4 text-right">
                      <h6 class="mt-2">Sub Division Date :<span style="color: red;"> *</span></h6>
                    </div>
                    <div class="col-4">
                      <input type="date" class="form-control" [(ngModel)]="item['subdivisiondate']" />
                    </div>
                    <div class="col-4">
                      <button (click)="addmore()" *ngIf="i == subDivisonData.length - 1"
                        class="btn btn-outline-primary">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                      </button>&nbsp;
                      <button (click)="removeLease(i)" *ngIf="i != 0" class="btn btn-outline-danger">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                    <!-- <div class="col-1">
                                        </div> -->
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <button (click)="createSubDivision()" class="btn btn-outline-primary">Submit</button>
                </div>
              </div>
            </div>
          </div>
          <!-- View Details Modal -->
          <div class="modal" id="viewDetails">
            <div class="modal-dialog modal-lg" style="max-width: 90%">
              <div class="modal-content rounded-50">
                <!-- Modal Header -->
                <div class="modal-header bg-primary">
                  <h4 class="modal-title text-white" style="font: bold">
                    Sub Division Details :
                  </h4>
                  <button type="button" class="close text-white" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body" style="border-radius: 10px">
                  <div class="card" style="border: 2px solid rgb(24, 24, 97)">
                    <div style="margin: 3%">
                      <div class="content-container">

                        <div class="row mt-2 mb-2">
                          <div class="col-4"></div>
                          <div class="col-4">
                            <h2> Lease Details </h2>
                          </div>
                          <div class="col-4"></div>
                        </div>
                        <hr>

                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>File Number :</b></h5>
                          </div>
                          <div class="col-3">

                            <h5 style="width: 100%">
                              {{ viewdata["file_num"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Assigned Property Number :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                             {{viewdata['assigned_property_number']}}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Alloted Property Id :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["alloted_property_id"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Plot Number :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["assigned_property_number"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Total Area :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["rent_increment_period"] }} Years
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Address :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["address"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Lease Type :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["lease_type"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Applicant Name :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["leasee_name"] }}

                            </h5>
                          </div>
                        </div>
                        <hr>
                        <br>

                        <div class="row mt-2 mb-2">
                          <div class="col-4"></div>
                          <div class="col-4">
                            <h2> Sub Lease Details </h2>
                          </div>
                          <div class="col-4"></div>
                        </div>
                        <hr>
                        <br>

                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>New Lease Name :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ subDivison_data["leasee_name"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>New Lease Type :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ subDivison_data["lease_type"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>New File Number :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ subDivison_data["file_num"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>New Plot Number :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ subDivison_data["assigned_property_number"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>New Lease Plot Area :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ subDivison_data["area"] }} {{subDivison_data["unit"]}}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Remaining Plot Area :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ remaningplotarea }}
                            </h5>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Sub Division Date :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ subDivison_data["sub_division_dt"] | date}}
                            </h5>
                          </div>
                          <div class="col-3"></div>
                          <div class="col-3"></div>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <!-- <b>Download Allotment </b><button class="btn btn-outline-primary"
                                             (click)="download(data)">Download</button> -->
                  <button type="button" class="btn btn-outline-danger" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
