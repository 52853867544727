<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Property Ledger (General)</div>
          <!--Property-->
          <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
        </div>
        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line d-flex justify-content-between">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab"><i class="fa fa-line-chart"></i>Property Ledger List</a>
              <!--Property  ledger List-->
            </li>
            <div class="text-center" *ngIf="shwodata">
              <button class="btn btn-outline-primary" (click)="goToList()">
                Go To List
              </button>
            </div>
          </ul>
          <!-- {{propDetails_arr |json}} -->
          <div class="tab-content">
            <!-- <div *ngIf="list_flag"> -->
            <div>
              <ng-container *ngIf="!shwodata">
                <br />
                <div class="row">
                  <div class="col-3"></div>
                  <div class="col-2 text-right">
                    <h6>Enter Property ID :</h6>
                  </div>
                  <div class="col-3 text-left">
                    <input type="text" class="form-control form-control-sm"
                      [(ngModel)]="objForLed['alloted_property_id']" [disabled]="field1"
                      (keypress)="diableOtherField(1)" />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <h5 class="text-center">or</h5>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-3"></div>
                  <div class="col-2 text-right">
                    <h6>Enter Allotment ID :</h6>
                  </div>
                  <div class="col-3 text-left">
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="objForLed['allotment_no']"
                      [disabled]="field2" (keypress)="diableOtherField(2)" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col">
                    <h5 class="text-center">or</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3"></div>
                  <div class="col-2 text-right">
                    <h6>Select Scheme / Offer :</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="allOffer" bindLabel="desc" [disabled]="field3" (change)="diableOtherField(3)"
                      bindValue="offer_cd" [multiple]="false" placeholder="Select Offer"
                      [(ngModel)]="objForLed['offer_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                      [closeOnSelect]="true" name="field_name">
                    </ng-select>
                  </div>
                </div>

                <div class="row mt-3" *ngIf="field3_relatedField_1">
                  <div class="col-3"></div>
                  <div class="col-2 text-right">
                    <h6>Search Option :</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="options" [multiple]="false" placeholder="Select..."
                      [(ngModel)]="objForLed['option']" [selectableGroup]="true" [selectableGroupAsModel]="true"
                      [closeOnSelect]="true" (change)="showOptionEntry()">
                      <ng-template ng-label-tmp let-item="item">
                        <div class="" (click)="setOptionValue(item)">{{item.option}}</div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div class="" (click)="setOptionValue(item)">{{item.option}}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>

                <div class="row mt-3" *ngIf="field3_relatedField_2">
                  <div class="col-3"></div>
                  <div class="col-2 text-right">
                    <h6>Enter {{option_name}}</h6>
                  </div>
                  <div class="col-3 text-left">
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="optionvalue">
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-5"></div>

                  <div class="col-3 text-center ">
                    <!-- <button class="btn btn-outline-primary" (click)="getPaymentDetails()">Fetch Report</button> -->
                    <button class="btn btn-outline-primary" (click)="getLedgerbyProOrAllot()">
                      Fetch Report
                    </button>
                    <button class="btn btn-outline-primary ml-3" (click)="reset()">
                      Reset
                    </button>
                  </div>

                </div>
                <br />
                <!-- <div class="row">
                  <div class="col-10"></div>
                  <div class="col-2 text-center">
                    <button class="btn btn-outline-primary" (click)="printlist()">
                      Print Report
                    </button>
                  </div>
                </div> -->
                <!-- <hr> -->
                <div class="row">
                  <div class="col-5">
                    <hr class="hrline" />
                  </div>
                  <div class="col-2 text-center">
                    <h6 id="h" style="color: brown; min-height: 20px">
                      Report
                    </h6>
                  </div>
                  <div class="col-5">
                    <hr class="hrline" />
                  </div>
                </div>
                <br />
                <!-- {{reportData | json}} -->
                <div class="container-fluid" *ngIf="reportData">

                  <div class="row">

                    <div class="col-4">
                      <mat-form-field style="margin-left: 2%">
                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                          placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Allotment9']}}">
                      </mat-form-field>
                    </div>


                    <table mat-table [dataSource]="dataSource" >
                      <ng-container matColumnDef="alloted_property_id">
                        <th   mat-header-cell *matHeaderCellDef>Property ID</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.alloted_property_id }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="offer_cd">
                        <th   mat-header-cell *matHeaderCellDef>Offer Code</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.offer_cd }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="property_type">
                        <th   mat-header-cell *matHeaderCellDef>Property Type</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.property_type }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="assigned_property_number">
                        <th   mat-header-cell *matHeaderCellDef>Property</th>
                        <td mat-cell *matCellDef="let element">

                          <div class="showonhover">
                            <span style="color: blue;"><u>{{ element.assigned_property_number }}</u></span>

                            <!-- {{ element.assigned_property_number }} -->
                            <!-- <input type="text" class="form-control" [(ngModel)]="element['assigned_property_number']" disabled > -->
                            <span class="hovertext">


                              <h5 style="text-align: center"> Property Allotement Details</h5>
                           <hr>

                                <div class="row">
                                    <div class="col-3 text-left">
                                        <h6>Property Id</h6>
                                    </div>
                                    <div class="col-2 text-left">
                                        <p> :  {{element['alloted_property_id']}}</p>
                                    </div>
                                    <div class="col-3 text-left">
                                      <h6>Applicant Id </h6>
                                  </div>
                                  <div class="col-3 text-left">
                                       <p> : {{element['party_id']}}</p>
                                  </div>
                                </div>

                                <div class="row">
                                    <div class="col-3 text-left">
                                        <h6>Property No</h6>
                                    </div>
                                    <div class="col-2 text-left">
                                         <p> :  {{element['assigned_property_number']}}</p>
                                    </div>
                                    <div class="col-3 text-left">
                                      <h6>Name Of Allottee </h6>
                                  </div>
                                  <div class="col-3 text-left">
                                       <p> : {{element['party_name']}}</p>
                                  </div>

                                </div>

                                <div class="row">
                                    <div class="col-3 text-left">
                                        <h6>Property Cost </h6>
                                    </div>
                                    <div class="col-2 text-left">
                                        <p> : {{element["pro_rate"] | number:'1.2-2'}}</p>
                                    </div>
                                    <div class="col-3 text-left">
                                      <h6>Father /Husband Name </h6>
                                  </div>
                                  <div class="col-3 text-left">
                                       <p> : {{element['party_father_or_husband_name']}}</p>
                                  </div>
                                </div>
                                <div class="row">
                                    <div class="col-3 text-left">
                                        <h6>Registration No</h6>
                                    </div>
                                    <div class="col-2 text-left">
                                        <p> : REG0{{element["registration_no"]}}</p>
                                    </div>
                                    <div class="col-3 text-left">
                                      <h6> Allottee Address </h6>
                                  </div>
                                  <div class="col-3 text-left">
                                       <p> : {{element['party_permanent_addr_line']}}</p>
                                  </div>
                                </div>
                                <div class="row">
                                    <div class="col-3 text-left">
                                        <h6>Allotement Letter No </h6>
                                    </div>
                                    <div class="col-2 text-left">
                                        <p> : {{element["allotment_no"]}}</p>
                                    </div>
                                    <div class="col-3 text-left">
                                      <h6>Quota : </h6>
                                  </div>
                                  <div class="col-3 text-left">
                                       <p> : {{element['party_quota']}}</p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-3 text-left">
                                      <h6>Allotement Date</h6>
                                  </div>
                                  <div class="col-2 text-left">
                                       <p> : {{element["allotment_date"] | date}}</p>
                                  </div>
                                  <div class="col-3 text-left">
                                    <h6>Mode Of Payment </h6>
                                </div>
                                <div class="col-3 text-left">
                                     <p> : {{element['payment_option']}}</p>
                                </div>
                              </div>

                              <!-- <div class="vl">

                                <div class="row">
                                  <div class="col-3 text-left">
                                      <h6>Applicant Name</h6>
                                  </div>
                                  <div class="col-3 text-left">
                                       <p> : {{element['party_name']}}</p>
                                  </div>
                              </div>
                            </div> -->
                            </span>
                            </div>
                          <!-- {{ element.assigned_property_number }} -->
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="party_name">
                        <th   mat-header-cell *matHeaderCellDef>Allottee Name</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.party_name }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="party_father_or_husband_name">
                        <th   mat-header-cell *matHeaderCellDef>
                          Father/Husband Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.party_father_or_husband_name }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="party_phone_no">
                        <th   mat-header-cell *matHeaderCellDef>Mobile No</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.party_phone_no }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="action">
                        <th   mat-header-cell *matHeaderCellDef>Action</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                          <button class="btn btnx btn-outline-primary" (click)="show(i)" >
                            show
                          </button>
                          <br>
                          <button class="btn btnx btn-outline-danger mt-2" (click)="show1(i)" (click)="printlist(element)">
                            print
                          </button>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="
                          let row;
                          columns: displayedColumns;
                          let i = index
                        "></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[10, 20,30]" showFirstLastButtons></mat-paginator>
                  </div>
                </div>
              </ng-container>


              <div *ngIf="shwodata">
                <!-- {{dataToDisplay | json}} -->
                <div class="row py-3">
                  <!-- <div class="col-3"></div> -->
                  <div class="col-2 text-left">
                    <h6>Property No. :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["assigned_property_number"] }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Name Of Allottee :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["party_name"] }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Father's/Husband Name :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["party_father_or_husband_name"] }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <!-- <div class="col-3"></div> -->
                  <div class="col-2 text-left">
                    <h6>Full Address :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["party_permanent_addr_line"] }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Allotment Letter No. :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["allotment_no"] }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Allotment Date:</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["allotment_date"] | date}}
                  </div>
                </div>
                <br />
                <div class="row">
                  <!-- <div class="col-3"></div> -->
                  <div class="col-2 text-left">
                    <h6>Property Cost (Rs) :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["pro_rate"] }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Registration Amount (Rs) :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["application_amount"] | number:'1.2-2'}}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Amount After Registration (Rs) :</h6>
                  </div>
                  <div class="col-2">
                    {{
                    (userDetailRecord["pro_rate"] -
                    userDetailRecord["application_amount"]) | number:'1.2-2'
                    }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <!-- <div class="col-3"></div> -->
                  <div class="col-2 text-left">
                    <h6>Mode of Payment :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["payment_option"] }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Total No. of Installments :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["no_of_payment"] }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>RegistrationNo./Form No. :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["registration_no"] }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <!-- <div class="col-3"></div> -->
                  <div class="col-2 text-left">
                    <h6>Pre Installment Amount (Rs) :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["first_lumsum"] | number:'1.2-2' }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Pre Installment Start Date :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["first_lumsum_start_date"] |date }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Pre Installment End Date :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["first_lumsum_end_date"] |date }}
                  </div>
                </div>
                <br />
                <div class="row">
                  <!-- <div class="col-3"></div> -->
                  <div class="col-2 text-left">
                    <h6>Amount on which Installment have been fixed (Rs) :</h6>
                  </div>
                  <div class="col-2">
                    <!-- if second lumsum is not available  -->
                    <span *ngIf="
                        userDetailRecord['first_lumsum'] &&
                        !userDetailRecord['second_lumsum']
                      ">{{
                      (userDetailRecord["pro_rate"] -
                      userDetailRecord["application_amount"] -
                      userDetailRecord["first_lumsum"]) | number:'1.2-2'
                      }}</span>

                    <!-- if first lumsum is not available -->
                    <span *ngIf="
                        userDetailRecord['second_lumsum'] &&
                        !userDetailRecord['first_lumsum']
                      ">{{
                      (userDetailRecord["pro_rate"] -
                      userDetailRecord["application_amount"] -
                      userDetailRecord["second"]) | number:'1.2-2'
                      }}</span>
                    <!-- if first and second both are not available -->
                    <span *ngIf="
                        !userDetailRecord['first_lumsum'] &&
                        !userDetailRecord['second_lumsum']
                      ">{{
                      (userDetailRecord["pro_rate"] -
                      userDetailRecord["application_amount"]) | number:'1.2-2'
                      }}</span>
                    <!-- if both are available -->
                    <span *ngIf="
                        userDetailRecord['first_lumsum'] &&
                        userDetailRecord['second_lumsum']
                      ">{{
                     ( userDetailRecord["pro_rate"] -
                      userDetailRecord["application_amount"]-
                      userDetailRecord["first_lumsum"] -
                      userDetailRecord['second_lumsum']) | number:'1.2-2'
                      }}</span>
                  </div>
                  <div class="col-2 text-left">
                    <h6>Installment Amount (Rs) :</h6>
                  </div>
                  <div class="col-2" *ngIf="userDetailRecord['payment_option']!='Cash Down' && installmentRecord[0]['emi_amount']; else casedownamount" >
                    {{ installmentRecord[0]["emi_amount"] | number:'1.2-2' }}
                  </div>
                  <ng-template #casedownamount>
                    <div class="col-2">
                      {{userDetailRecord['first_lumsum']}}
                    </div>
                  </ng-template>

                  <div class="col-2 text-left">
                    <h6>Date of First Installment :</h6>
                  </div>
                  <div class="col-2" *ngIf="userDetailRecord['payment_option']!='Cash Down';else casedowndate">
                    {{ installmentRecord[0]["emi_start_date"].split("T")[0] |date}}
                  </div>
                  <ng-template #casedowndate>
                    <div class="col-2">
                      {{userDetailRecord['lottery_date'].split("T")[0] |date}}
                    </div>
                  </ng-template>
                </div>
                <br />
                <div class="row" *ngIf="userDetailRecord['payment_option']!='Cash Down'">
                  <div class="col-2 text-left">
                    <h6>Frequency of Installment :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["frequency_of_installment"] }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Rate of Interest included in Installment :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord["interest_rate"] }}
                  </div>
                  <div class="col-2 text-left">
                    <h6>Total Interest to be charged (in case of Default) :</h6>
                  </div>
                  <div class="col-2">
                    {{ userDetailRecord['panel_interest']  }}
                  </div>

                </div>
                <!-- <hr> -->
                <hr class="hrline" />
                <br />
                All Amount are in INR
                <div class="table-responsive">
                  <table class="table table-bordered" >
                    <thead>
                      <tr>
                        <!-- <th   scope="col" rowspan="3">S.No.</th> -->
                        <th class="headStyle"  scope="col" rowspan="3" >DueDate&End Date</th>
                        <th class="headStyle" scope="col" rowspan="3">Installment No.</th>
                        <th class="headStyle"  scope="col" rowspan="3">Installment Amt.</th>
                        <th class="headStyle"  scope="col " colspan="3">Amount Due</th>
                        <th class="headStyle"  scope="col" colspan="6">Amount Paid</th>
                        <th class="headStyle"  scope="col" colspan="4">Balance Due</th>
                        <th class="headStyle"  scope="col" rowspan="3">AdvancePaid</th>
                        <th class="headStyle"  scope="col" colspan="2">Balance Value Not Due</th>
                      </tr>
                      <tr>
                        <th class="headStyle"  scope="col " rowspan="2">Principal</th>
                        <th class="headStyle"  scope="col " rowspan="2">Interest</th>
                        <th class="headStyle"  scope="col " rowspan="2">Additional Interest</th>

                        <th class="headStyle"  scope="col" rowspan="2">Date</th>
                        <th class="headStyle"  scope="col" colspan="2">Total</th>
                        <th class="headStyle"  scope="col" rowspan="2">Principal</th>
                        <th class="headStyle"  scope="col" rowspan="2">Interest</th>
                        <th class="headStyle"  scope="col" rowspan="2">Additional Interest</th>

                        <th class="headStyle"  scope="col" rowspan="2">Principal</th>
                        <th class="headStyle"  scope="col" rowspan="2">Interest</th>
                        <th class="headStyle"  scope="col" rowspan="2">Total</th>
                        <th class="headStyle"  scope="col" rowspan="2">Additional Interest</th>

                        <th class="headStyle"  scope="col" rowspan="2">Principal</th>

                        <th class="headStyle"  scope="col" rowspan="2">Interest</th>
                      </tr>
                      <tr>
                        <th class="headStyle"  scope="col ">Installment</th>
                        <th class="headStyle"  scope="col">Income Tax/26QB</th>
                      </tr>
                    </thead>

                    <tbody>
                      <!-- {{displayListIndex | json}} -->
                      <tr>
                        <td *ngFor="let item of zero; let i = index" style="background-color: dodgerblue; color: white;">
                          {{ i + 1 }}
                        </td>
                      </tr>
                      <!-- {{displayListIndex | json}} -->
                      <!-- {{displayListIndex | json}} -->
                      <tr *ngFor="let item of displayListIndex;let i = index" >
                        <ng-container  *ngIf="item.list!=undefined" >
                          <td>
                            <div class="">
                              {{dueAndEndDate[item.list].start | date: 'dd/MM/yyyy'}}
                            </div>
                            <div class="">
                              {{dueAndEndDate[item.list].end |date: 'dd/MM/yyyy'}}
                            </div>
                          </td> <!--1. due and end data  -->
                          <td>{{intallmentNo[item.list]}}</td>                                                                         <!--2.installment no-->
                          <td>{{installmentAmount[item.list] | number:'1.2-2' }}</td>                                                  <!--3.installment Amount-->
                          <td>{{amountDue[item.list].principal | number:'1.2-2'}}</td>                                                <!--4.amount due principal -->
                          <td>{{amountDue[item.list].intrest | number:'1.2-2'}}</td>                                                  <!--5.amount due intrest -->
                          <td>{{amountDue[item.list].additionalInterest | number:'1.2-2'}}</td>                                       <!--6.amount due additional interest-->
                          <td> <div *ngFor="let d of paymentDate[item.list]">{{d | date: 'dd/MM/yyyy'}}</div></td>                                       <!--7.payment date-->
                          <td> <div *ngFor="let d of amountPaid[item.list].installment">{{d | number:'1.2-2'}}</div></td>                                             <!--8.amount paid intallment-->
                          <td></td>                                                                          <!--9.-->
                          <td>{{amountPaid[item.list].principal | number:'1.2-2'}}</td>                                               <!--10.amount paid Principal -->
                          <td>{{amountPaid[item.list].intrest | number:'1.2-2'}}</td>                                                 <!--11.amount paid intrest-->
                          <td>{{amountPaid[item.list].additionalInterest | number:'1.2-2'}}</td>                                      <!--12.amount paid additional intrest-->
                          <td>{{balanceDue[item.list].principal| number:'1.2-2' }}</td>                                               <!--13.balance due principal-->
                          <td>{{balanceDue[item.list].interest | number:'1.2-2'}}</td>                                                <!--14.balance due intrest-->
                          <td>{{balanceDue[item.list].total | number:'1.2-2'}}</td>                                                   <!--15.balance due total-->
                          <td>{{balanceDue[item.list].additionalInterest | number:'1.2-2'}}</td>                                      <!--16.balance due additional intrest-->
                          <td>{{advancePaid[item.list] | number:'1.2-2'}}</td>                                                        <!--17.advance paid-->
                          <td>{{balanceValueNotDue[item.list].principal | number:'1.2-2'}}</td>                                       <!--18.balance not due principal -->
                          <td>{{balanceValueNotDue[item.list].interest | number:'1.2-2'}}</td>                                        <!--19.balance not due interest -->
                        </ng-container >

                        <ng-container *ngIf="item.fin_list!=undefined"   >
                          <td colspan="2" class="fin-year-style">Financial year wise closing</td>                                                       <!--2.installment no     1  2-->
                          <td class="fin-year-style" >{{year_wise_installmentAmount[item.fin_list]}}</td>                                             <!--     3.installment Amount -->
                          <td class="fin-year-style" >{{year_wise_amountDue[item.fin_list].principal | number:'1.2-2'}}</td>                                                <!--4.amount due principal -->
                          <td class="fin-year-style" >{{year_wise_amountDue[item.fin_list].intrest | number:'1.2-2'}}</td>                                                  <!--5.amount due intrest -->
                          <td class="fin-year-style" >{{year_wise_amountDue[item.fin_list].additionalInterest | number:'1.2-2'}}</td>                                       <!--6.amount due additional interest-->
                          <td class="fin-year-style"></td>                                                                                 <!--7.payment date-->
                          <td class="fin-year-style" >{{year_wise_amountPaid[item.fin_list].installment | number:'1.2-2'}}</td>                                             <!--8.amount paid intallment-->
                          <td class="fin-year-style"></td>                                                                          <!--9.-->
                          <td class="fin-year-style" >{{year_wise_amountPaid[item.fin_list].principal | number:'1.2-2'}}</td>                                               <!--1item.fin_list.amount paid Principal -->
                          <td class="fin-year-style" >{{year_wise_amountPaid[item.fin_list].intrest | number:'1.2-2'}}</td>                                                 <!--11.amount paid intrest-->
                          <td class="fin-year-style" >{{year_wise_amountPaid[item.fin_list].additionalInterest | number:'1.2-2'}}</td>                                      <!--12.amount paid additional intrest-->
                          <td class="fin-year-style" >{{year_wise_balanceDue[item.fin_list].principal | number:'1.2-2'}}</td>                                               <!--13.balance due principal-->
                          <td class="fin-year-style" >{{year_wise_balanceDue[item.fin_list].interest | number:'1.2-2'}}</td>                                                <!--14.balance due intrest-->
                          <td class="fin-year-style" >{{year_wise_balanceDue[item.fin_list].total | number:'1.2-2'}}</td>                                                   <!--15.balance due total-->
                          <td class="fin-year-style" >{{year_wise_balanceDue[item.fin_list].additionalInterest | number:'1.2-2'}}</td>                                      <!--16.balance due additional intrest-->
                          <td class="fin-year-style" >{{year_wise_advancePaid[item.fin_list] | number:'1.2-2'}}</td>                                                        <!--17.advance paid-->
                          <td class="fin-year-style" >{{year_wise_balanceValueNotDue[item.fin_list].principal | number:'1.2-2'}}</td>                                       <!--18.balance not due principal -->
                          <td class="fin-year-style" >{{year_wise_balanceValueNotDue[item.fin_list].interest | number:'1.2-2'}}</td>
                        </ng-container>

                        <ng-container *ngIf="item.cumulative!=undefined"   >
                          <td colspan="2" class="fin-year-style">Cumulative Financial year wise closing </td>                                                       <!--2.installment no     1  2-->
                          <td class="fin-year-style" >{{comulative_year_installmentAmount[item.cumulative] | number:'1.2-2'}}</td>                                             <!--     3.installment Amount -->
                          <td class="fin-year-style" >{{comulative_year_amountDue[item.cumulative].principal | number:'1.2-2'}}</td>                                                <!--4.amount due principal -->
                          <td class="fin-year-style" >{{comulative_year_amountDue[item.cumulative].intrest | number:'1.2-2'}}</td>                                                  <!--5.amount due intrest -->
                          <td class="fin-year-style" >{{comulative_year_amountDue[item.cumulative].additionalInterest | number:'1.2-2'}}</td>                                       <!--6.amount due additional interest-->
                          <td class="fin-year-style"></td>                                                                                 <!--7.payment date-->
                          <td class="fin-year-style" >{{comulative_year_amountPaid[item.cumulative].installment | number:'1.2-2'}}</td>                                             <!--8.amount paid intallment-->
                          <td class="fin-year-style"></td>                                                                          <!--9.-->
                          <td class="fin-year-style" >{{comulative_year_amountPaid[item.cumulative].principal | number:'1.2-2'}}</td>                                               <!--1item.cumulative.amount paid Principal -->
                          <td class="fin-year-style" >{{comulative_year_amountPaid[item.cumulative].intrest | number:'1.2-2'}}</td>                                                 <!--11.amount paid intrest-->
                          <td class="fin-year-style" >{{comulative_year_amountPaid[item.cumulative].additionalInterest | number:'1.2-2'}}</td>                                      <!--12.amount paid additional intrest-->
                          <td class="fin-year-style" >{{comulative_year_balanceDue[item.cumulative].principal | number:'1.2-2'}}</td>                                               <!--13.balance due principal-->
                          <td class="fin-year-style" >{{comulative_year_balanceDue[item.cumulative].interest | number:'1.2-2'}}</td>                                                <!--14.balance due intrest-->
                          <td class="fin-year-style" >{{comulative_year_balanceDue[item.cumulative].total | number:'1.2-2'}}</td>                                                   <!--15.balance due total-->
                          <td class="fin-year-style" >{{comulative_year_balanceDue[item.cumulative].additionalInterest | number:'1.2-2'}}</td>                                      <!--16.balance due additional intrest-->
                          <td class="fin-year-style" >{{comulative_year_advancePaid[item.cumulative] | number:'1.2-2'}}</td>                                                        <!--17.advance paid-->
                          <td class="fin-year-style" >{{comulative_year_balanceValueNotDue[item.cumulative].principal | number:'1.2-2'}}</td>                                       <!--18.balance not due principal -->
                          <td class="fin-year-style" >{{comulative_year_balanceValueNotDue[item.cumulative].interest | number:'1.2-2'}}</td>
                        </ng-container>
                      </tr>

                    </tbody>

                  </table>
                </div>
                <br />
                <div class="row">
                  <ul>
                    <li style="font-size: 10">
                      <b>Note :-
                        <ul>
                          <li>
                            Current defaulted amount Rs. {{sum_of_15_16 | number:'1.2-2'}} (Sum of Column
                            No. 15 & 16) .
                          </li>
                          <li>
                            Final dues without rebate/discount Rs. {{sum_of_15_16_18 | number:'1.2-2'}}
                            (Sum of Column No. 15, 16 & 18) .
                          </li>
                              <!-- <li>
                                Final dues after rebate/discount Rs. 264566.00 , (
                                2% rebate/discount allowed for Rs. 4645.39 on Column
                                No. 18) .
                              </li> -->
                          <li>
                            This ledger statement is only for the display
                            purpose and information available in UPHDB will be
                            treated as final .
                          </li>
                          <li>
                            In case of any discrepancy please contact concerning
                            Estate Management Office(EMO).
                          </li>
                        </ul>
                      </b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner>Lodaing...</ngx-spinner>
