import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service'

@Injectable({
  providedIn: 'root'
})
export class TenderService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + '/emd';
  }

  async submitTender(obj :any ){
    let resp = await this.http.post(this.httpUrl + '/tender/submitTender', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async updateTender(obj :any ){
    let resp = await this.http.put(this.httpUrl + '/tender/updateTender', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async cancleTender(obj :any ){
    let resp = await this.http.put(this.httpUrl + '/tender/cancleTender', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async uploadDocument(obj :any ){
    console.log(obj)
    let resp = await this.http.post(this.httpUrl + '/upload/uploadDocument', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async getTender(obj :any ){
    let resp = await this.http.get(this.httpUrl + '/tender/getTender'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async viewDetails(obj :any ){
    let resp = await this.http.get(this.httpUrl + '/tender/tenderDetails'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async viewDoc(obj :any ){
    const resp = await this.http.post(this.httpUrl + '/upload/viewDoc', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  async changeTenderStatus(obj :any ){
    let resp = await this.http.put(this.httpUrl + '/tender/changeTenderStatus', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


  /**********************************************Assign Tender Screen ***************************************************** */
  async getAssignedTender(obj :any ){
    let resp = await this.http.get(this.httpUrl + '/tender/getAssignedTender'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async getdetails(obj :any ){
    let resp = await this.http.get(this.httpUrl + '/tender/getdetails'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


  async deleteAssignTender(obj :any ){
    let resp = await this.http.delete(this.httpUrl + '/tender/deleteAssignTender'+ obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async insertAssignTender(obj :any ){
    let resp = await this.http.post(this.httpUrl + '/tender/insertAssignTender', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


  async updateAssignTender(obj :any ){
    let resp = await this.http.put(this.httpUrl + '/tender/updateAssignTender', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


  async ChangeAssignStatus(obj :any ){
    let resp = await this.http.put(this.httpUrl + '/tender/ChangeAssignStatus', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
}
