import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
// import { AgmCoreModule } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';

import { FileUploadModule } from 'ng2-file-upload';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { ToastrModule } from 'ngx-toastr';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgtreegridModule } from 'ngtreegrid';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
// import { NgApexchartsModule } from "ng-apexcharts";
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { PropertyLayoutsRoutingModule } from './property-layouts-routing.module';
import { AdvanceServicesComponent } from '../advance-services/advance-services.component';
import { DefaulterPartyComponent } from '../advance-services/defaulter-party/defaulter-party.component';
import { PossessionComponent } from '../advance-services/possession/possession.component';
import { PropertyCancellationComponent } from '../advance-services/property-cancellation/property-cancellation.component';
import { SeizedPropertyComponent } from '../advance-services/seized-property/seized-property.component';
import { AuctionApplicationComponent } from '../auction-application/auction-application.component';
import { AuctionComponent } from '../auction/auction.component';
import { BidDetailsComponent } from '../bid-details/bid-details.component';
import { OfflineBookletPurchaseComponent } from '../offline-booklet-purchase/offline-booklet-purchase.component';
import { LandInputComponent } from '../prop-inventory/land-input/land-input.component';
import { PlanLayoutComponent } from '../prop-inventory/plan-layout/plan-layout.component';
import { PropCostingComponent } from '../prop-inventory/prop-costing/prop-costing.component';
import { PropInventoryComponent } from '../prop-inventory/prop-inventory.component';
import { PropertyInventoryComponent } from '../prop-inventory/property-inventory/property-inventory.component';
import { PropertyFaqComponent } from '../property-help/property-faq/property-faq.component';
import { PropertyHelpComponent } from '../property-help/property-help.component';
import { PropertyOnlineSupportComponent } from '../property-help/property-online-support/property-online-support.component';
import { PropertyTutorialComponent } from '../property-help/property-tutorial/property-tutorial.component';
import { PropertyUserManualComponent } from '../property-help/property-user-manual/property-user-manual.component';
import { GeneralLedgerComponent } from '../property-ledger/general-ledger/general-ledger.component';
import { PropertyLedgerComponent } from '../property-ledger/property-ledger.component';
import { AllotmentResultComponent } from '../property-party/allotment-result/allotment-result.component';
import { AllotmentComponent } from '../property-party/allotment/allotment.component';
import { ApplicationRefundComponent } from '../property-party/application-refund/application-refund.component';
import { ApplicationReportComponent } from '../property-party/application-report/application-report.component';
import { ApplicationsComponent } from '../property-party/applications/applications.component';
import { BookletPurchaseComponent } from '../property-party/booklet-purchase/booklet-purchase.component';
import { InstallmentPaymentComponent } from '../property-party/installment-payment/installment-payment.component';
import { LotterySlipComponent } from '../property-party/lottery-slip/lottery-slip.component';
import { PartyAccountsComponent } from '../property-party/party-accounts/party-accounts.component';
import { PartyInfoComponent } from '../property-party/party-info/party-info.component';
import { PartyNomineeComponent } from '../property-party/party-nominee/party-nominee.component';
import { PaymentScheduleComponent } from '../property-party/payment-schedule/payment-schedule.component';
import { PropertyPartyComponent } from '../property-party/property-party.component';
import { RegistryComponent } from '../property-party/registry/registry.component';
import { RestoreComponent } from '../property-party/restore/restore.component';
import { TransferPropertyComponent } from '../property-party/transfer-property/transfer-property.component';
import { PropertyCodeValueComponent } from '../property-setting/property-code-value/property-code-value.component';
import { PropertyDefinitionComponent } from '../property-setting/property-definition/property-definition.component';
import { PropertyFieldComponent } from '../property-setting/property-field/property-field.component';
import { PropertyInfoComponent } from '../property-setting/property-info/property-info.component';
import { PropertySettingComponent } from '../property-setting/property-setting.component';
import { SchemeComponent } from '../property-setting/scheme/scheme.component';
import { SubschemeComponent } from '../property-setting/subscheme/subscheme.component';
import { DefaulterMethodComponent } from '../proterty-administration/defaulter-method/defaulter-method.component';
import { LetterConfigurationComponent } from '../proterty-administration/letter-configuration/letter-configuration.component';
import { MutationRuleComponent } from '../proterty-administration/mutation-rule/mutation-rule.component';
import { PaymentOptionComponent } from '../proterty-administration/payment-option/payment-option.component';
import { PossessionRuleComponent } from '../proterty-administration/possession-rule/possession-rule.component';
import { PropDataAssignmentComponent } from '../proterty-administration/prop-data-assignment/prop-data-assignment.component';
import { PropGeometryComponent } from '../proterty-administration/prop-geometry/prop-geometry.component';
import { PropHeirarchyComponent } from '../proterty-administration/prop-heirarchy/prop-heirarchy.component';
import { PropLocationComponent } from '../proterty-administration/prop-location/prop-location.component';
import { PropRolesComponent } from '../proterty-administration/prop-roles/prop-roles.component';
import { PropUsersComponent } from '../proterty-administration/prop-users/prop-users.component';
import { PropWorkflowComponent } from '../proterty-administration/prop-workflow/prop-workflow.component';
import { PropertyFeatureComponent } from '../proterty-administration/property-feature/property-feature.component';
import { ProtertyAdministrationComponent } from '../proterty-administration/proterty-administration.component';
import { RegularHierarchyComponent } from '../proterty-administration/regular-hierarchy/regular-hierarchy.component';
import { FcfsComponent } from '../sale/fcfs/fcfs.component';
import { LotteryComponent } from '../sale/lottery/lottery.component';
import { SaleComponent } from '../sale/sale.component';
import { PropertyFooterComponent } from './property-footer/property-footer.component';
import { PropertyHeaderComponent } from './property-header/property-header.component';
import { PropertyLayoutsComponent } from './property-layouts.component';
import { PropertySidebarComponent } from './property-sidebar/property-sidebar.component';
import { SharedModule } from '../../shared/shared.module';
import { PropImagesComponent } from '../prop-inventory/prop-images/prop-images.component';
import { NewDashboardComponent } from '../new-dashboard/new-dashboard.component';
import { DashChartsComponent } from '../dash-charts/dash-charts.component';
// import { NgxEchartsModule } from 'ngx-echarts';
import { PropertyDashComponent } from '../property-dash/property-dash.component';
import { RefundRequestComponent } from '../advance-services/refund-request/refund-request.component';
import { AuctionBidRuleComponent } from '../auction-bid-rule/auction-bid-rule.component';
import { TermAndConditionComponent } from '../proterty-administration/term-and-condition/term-and-condition.component';
import { PublicConfigComponent } from '../property-setting/public-config/public-config.component';
import { LeaseComponent } from '../prop-inventory/lease/lease.component';
import { LeasePropertyComponent } from '../prop-inventory/lease-property/lease-property.component';
import { LeaseAllotmentComponent } from '../property-lease/lease-allotment/lease-allotment.component';
import { LeaseInspectionComponent } from '../property-lease/lease-inspection/lease-inspection.component';
import { LeaseLitigationComponent } from '../property-lease/lease-litigation/lease-litigation.component';
import { LeaseMutationComponent } from '../property-lease/lease-mutation/lease-mutation.component';
import { LeaseRenewalComponent } from '../property-lease/lease-renewal/lease-renewal.component';
import { LeaseSubDivisionComponent } from '../property-lease/lease-sub-division/lease-sub-division.component';
import { PropertyLeaseComponent } from '../property-lease/property-lease.component';
import { LeaseFreeholdComponent } from '../property-lease/lease-freehold/lease-freehold.component';
import { AuctionAllotmentComponent } from '../property-party/auction-allotment/auction-allotment.component';


@NgModule({
  declarations: [
    PropertyLayoutsComponent,
    PropertyHeaderComponent,
    PropertyFooterComponent,
    PropertySidebarComponent,
    PropertyDefinitionComponent,
    OfflineBookletPurchaseComponent,
    PropertyFeatureComponent,
    BookletPurchaseComponent,
    PartyAccountsComponent,
    PartyInfoComponent,
    PartyNomineeComponent,
    BidDetailsComponent,
    PropertyInfoComponent,
    SchemeComponent,
    PropertySettingComponent,
    ApplicationReportComponent,
    ApplicationRefundComponent,
    AllotmentComponent,
    InstallmentPaymentComponent,
    PaymentScheduleComponent,
    RegistryComponent,
    TransferPropertyComponent,
    SubschemeComponent,
    PropertyCancellationComponent,
    PropertyCodeValueComponent,
    PropertyFieldComponent,
    ApplicationsComponent,
    RestoreComponent,
    AuctionComponent,
    AuctionApplicationComponent,
    LetterConfigurationComponent,
    PropertyPartyComponent,
    PropertyHelpComponent,
    PropertyFaqComponent,
    PropertyOnlineSupportComponent,
    PropertyTutorialComponent,
    PropertyUserManualComponent,
    ProtertyAdministrationComponent,
    PropRolesComponent,
    PropUsersComponent,
    PropHeirarchyComponent,
    PropDataAssignmentComponent,
    PropGeometryComponent,
    PropWorkflowComponent,
    PropLocationComponent,
    PropInventoryComponent,
    LandInputComponent,
    RegularHierarchyComponent,
    PlanLayoutComponent,
    PropertyInventoryComponent,
    SaleComponent,
    LotteryComponent,
    FcfsComponent,
    PropCostingComponent,
    PaymentOptionComponent,
    PropertyLedgerComponent,
    DefaulterMethodComponent,
    AdvanceServicesComponent,
    DefaulterPartyComponent,
    SeizedPropertyComponent,
    PossessionComponent,
    MutationRuleComponent,
    PossessionRuleComponent,
    GeneralLedgerComponent,
    LotterySlipComponent,
    AllotmentResultComponent,
    PropImagesComponent,
    NewDashboardComponent,
    DashChartsComponent,
    PropertyDashComponent,
    RefundRequestComponent,
    AuctionBidRuleComponent,
    TermAndConditionComponent,
    PublicConfigComponent,
    LeaseComponent,
    LeasePropertyComponent,
    PropertyLeaseComponent,
    LeaseAllotmentComponent,
    LeaseMutationComponent,
    LeaseSubDivisionComponent,
    LeaseRenewalComponent,
    LeaseInspectionComponent,
    LeaseLitigationComponent,
    LeaseFreeholdComponent,
    AuctionAllotmentComponent
  ],
  exports: [
    PropertyLayoutsComponent,
    PropertyHeaderComponent,
    PropertyFooterComponent,
    PropertySidebarComponent,
],
  imports: [
    SharedModule,
    CommonModule,
    PropertyLayoutsRoutingModule,
    // AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
      // apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    NgSelectModule,
    NgChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    MatSlideToggleModule,
    FormsModule,
    // NgtreegridModule,
    MatStepperModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    // NgApexchartsModule,
    ReactiveFormsModule,
    MatTabsModule,
    // NgxEchartsModule,
    // ToastrModule.forRoot(),
    ToastrModule.forRoot()
  ]
})
export class PropertyLayoutsModule { }
