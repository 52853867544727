<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Feature</div>



                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag"  data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> List</a>

                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag"  data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Create </a>

                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag"  data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Update </a>
                        </li>
                    </ul>
                    <br>
                    <div>

                        <div *ngIf="list_flag">
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 text-center">
                                    <h6>
                                        Select Feature Group : <span style="color: red;"> *</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD52']" bindLabel="code"
                                        bindValue="value" [multiple]="false" placeholder="Select Feature Group"
                                        [(ngModel)]="Obj['feature_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-primary" (click)="getFeatures()">List</button>&nbsp;

                                    <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                </div>

                            </div>



                            <br>

                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">

                                    </mat-form-field>
                                </div>

                            </div>


                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            ID
                                        </th>

                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="feature_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>

                                            Feature Code


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.feature_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="feature_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Feature Description

                                        </th>
                                        <!--Description-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['feature_desc']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="feature_unit">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Feature Unit

                                        </th>

                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['feature_unit']}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action</th>
                                        <!--Action-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">Update</button>
                                            <!--Update-->
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element)">Delete</button>
                                            <!--Delete-->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns_1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns_1;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>



                        </div>
                        <div *ngIf="create_flag">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first" label="Option">
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3 text-center">
                                            <h6>
                                                Select Feature Group : <span style="color: red;"> *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="mainService.codeValueTechObj['PROPFLD52']"
                                                bindLabel="code" bindValue="value" [multiple]="false"
                                                placeholder="Select Feature Group" [(ngModel)]="Obj['feature_type']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <div class="row">
                                                <div class="col-2"></div>
                                                <div class="col-1 text-center">
                                                    <input type="radio" class="form-check-input" checked="checked"
                                                        name="radio" [(ngModel)]="radio" [value]="true"
                                                        style="height: 20px; width: 20px;">
                                                </div>
                                                <div class="col-3">Select Existing Feature</div>
                                                <div class="col-3"></div>
                                                <div class="col-3"></div>
                                            </div>
                                            <br>
                                            <div class="col-12 text-center">
                                                OR
                                            </div>
                                            <div class="row">
                                                <div class="col-2"></div>

                                                <div class="col-1 text-center">
                                                    <input type="radio" class="form-check-input" checked="checked"
                                                        name="radio" [(ngModel)]="radio" [value]="false"
                                                        style="height: 20px; width: 20px;">
                                                </div>
                                                <div class="col-3">Create New Feature</div>
                                                <div class="col-3"></div>
                                                <div class="col-3"></div>

                                            </div>
                                            <br>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                (click)="open_list()">Cancel</button>
                                            &nbsp;&nbsp;

                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>Next</button>

                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="second" label="Feature">
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3 ">
                                            <h6>
                                                Select Feature Group : <span style="color: red;"> *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="mainService.codeValueTechObj['PROPFLD52']"
                                                bindLabel="code" bindValue="value" [multiple]="false"
                                                placeholder="Select Feature Group" [(ngModel)]="Obj['feature_type']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="card" *ngIf="!radio">
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Enter Feature Code : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" placeholder="Enter Feature Code"
                                                    [(ngModel)]="Obj['feature_code']" class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Enter Feature Description : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                    placeholder="Enter Feature Description "
                                                    [(ngModel)]="Obj['feature_desc']" class="form-control"></textarea>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Feature Unit : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0021']"
                                                    bindLabel="code" bindValue="value" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="Obj['feature_unit']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>

                                    </div>
                                    <br>
                                    <div class="row" *ngIf="radio">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-primary" (click)="getFeaturesExceptSelectedGroup()">Search</button>&nbsp;
                                        </div>
                                    </div>
                                    <br>
                                    <div class="card" *ngIf="radio">

                                        <div class="row">
                                            <div class="col-6">
                                                <mat-form-field style="margin-left: 2%">
                                                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">

                                                </mat-form-field>
                                            </div>

                                        </div>


                                        <div class="example-container">
                                            <table mat-table [dataSource]="datasource" matSort>
                                                <ng-container matColumnDef="id">
                                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                                        ID
                                                    </th>

                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                                        class="right">
                                                        {{ element.id }}</td>
                                                </ng-container>

                                                <ng-container matColumnDef="feature_code">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>

                                                        Feature Code


                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        {{ element.feature_code }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="feature_desc">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        Feature Description

                                                    </th>
                                                    <!--Description-->
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        {{element['feature_desc']}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="feature_unit">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        Feature Unit

                                                    </th>

                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        {{element['feature_unit']}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                        Action</th>
                                                    <!--Action-->
                                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                        <button class="btn btn-outline-danger"
                                                        (click)="select(element)">Select</button>
                                                    </td>
                                                </ng-container>

                                                <tr mat-header-row *matHeaderRowDef="displayedColumns_1"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns_1;">
                                                </tr>
                                            </table>
                                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                                        </div>



                                        <!-- <div class="row" style="margin: 2%;">
                                            <div class="col-12">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>S No.</th>
                                                            <th>Feature Code</th>
                                                            <th>Feature Description</th>
                                                            <th>Unit</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of feature_list;let i=index">
                                                            <td>{{i+1}}</td>
                                                            <td>{{item['feature_code']}}</td>
                                                            <td>{{item['feature_desc']}}</td>
                                                            <td>{{item['feature_unit']}}</td>

                                                            <td>
                                                                <button class="btn btn-outline-danger"
                                                                     (click)="select(item)">Select</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> -->
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>Back</button>
                                            &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                (click)="open_list()">Cancel</button>
                                            &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>Next</button>

                                        </div>
                                    </div>

                                </mat-step>

                                <mat-step [stepControl]="third" label="done">

                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>
                                                    Select Feature Group : <span style="color: red;"> *</span>
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROPFLD52']"
                                                    bindLabel="code" bindValue="value" [multiple]="false"
                                                    placeholder="Select Feature Group" [(ngModel)]="Obj['feature_type']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0" disabled>
                                                </ng-select>
                                            </div>
                                        </div>

                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Enter Feature Code : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" disabled placeholder="Enter Feature Code"
                                                    [(ngModel)]="Obj['feature_code']" class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Enter Feature Description : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <textarea style="width: 100%; height: 80px;" id="re" name="re" disabled
                                                    placeholder="Enter Feature Description "
                                                    [(ngModel)]="Obj['feature_desc']" class="form-control"></textarea>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Feature Unit : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0021']"
                                                    bindLabel="code" bindValue="value" [multiple]="false"
                                                    placeholder="Select ..." [(ngModel)]="Obj['feature_unit']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0" disabled>
                                                </ng-select>
                                            </div>
                                            <div class="col-3"></div>
                                            <br>
                                        </div>
                                        <br>
                                    </div>
                                    <br>

                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>Back</button>
                                        &nbsp;&nbsp;
                                        <button class="btn btn-outline-success"
                                            (click)="submit()">Submit</button>&nbsp;&nbsp;
                                    </div>
                                    <br>

                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                        <div *ngIf="update_flag">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>

                                <mat-step [stepControl]="first" label="Feature">
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3 ">
                                            <h6>
                                                Select Feature Group : <span style="color: red;"> *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="mainService.codeValueTechObj['PROPFLD52']"
                                                bindLabel="code" bindValue="value" [multiple]="false"
                                                placeholder="Select Feature Group" [(ngModel)]="Obj['feature_type']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Enter Feature Code : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" placeholder="Enter Feature Code" disabled
                                                    [(ngModel)]="Obj['feature_code']" class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Enter Feature Description : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                    placeholder="Enter Feature Description "
                                                    [(ngModel)]="Obj['feature_desc']" class="form-control"></textarea>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Feature Unit : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0021']"
                                                    bindLabel="code" bindValue="value" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="Obj['feature_unit']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>

                                    </div>


                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <!-- <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>Back</button> -->
                                            &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                (click)="open_list()">Cancel</button>
                                            &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>Next</button>

                                        </div>
                                    </div>

                                </mat-step>

                                <mat-step [stepControl]="second" label="done">

                                    <div class="card">
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>
                                                    Select Feature Group : <span style="color: red;"> *</span>
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROPFLD52']"
                                                    bindLabel="code" bindValue="value" [multiple]="false"
                                                    placeholder="Select Feature Group" [(ngModel)]="Obj['feature_type']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0" disabled>
                                                </ng-select>
                                            </div>
                                        </div>

                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Enter Feature Code : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="text" disabled placeholder="Enter Feature Code"
                                                    [(ngModel)]="Obj['feature_code']" class="form-control">
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Enter Feature Description : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <textarea style="width: 100%; height: 80px;" id="re" name="re" disabled
                                                    placeholder="Enter Feature Description "
                                                    [(ngModel)]="Obj['feature_desc']" class="form-control"></textarea>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3">
                                                <h6>Select Feature Unit : <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0021']"
                                                    bindLabel="code" bindValue="value" [multiple]="false"
                                                    placeholder="Select ..." [(ngModel)]="Obj['feature_unit']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0" disabled>
                                                </ng-select>
                                            </div>
                                            <div class="col-3"></div>
                                            <br>
                                        </div>
                                        <br>
                                    </div>
                                    <br>

                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>Back</button>
                                        &nbsp;&nbsp;
                                        <button class="btn btn-outline-success"
                                            (click)="update()">Update</button>&nbsp;&nbsp;
                                    </div>
                                    <br>

                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>

                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading...</ngx-spinner>
