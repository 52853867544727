import { Component, OnInit, ViewChild } from '@angular/core';
 import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import { SettingService } from '../../service/setting.service';
import { MainService } from '../../service/main.service';
import { LedgerService } from '../../service/ledger.service';
import { ContraService } from '../../service/contra.service';
import { ChartOfAccountService } from '../../service/chart-of-account.service';
// import { BillService } from '../../service/bill.service';
// import { UserAddService } from '../../../../app/portal/service/user-add.service';

import swal from 'sweetalert2';
import * as  pdfMake from  "pdfmake/build/pdfmake";

declare var $: any

@Component({
  selector: 'app-contra',
  templateUrl: './contra.component.html',
  styleUrls: ['./contra.component.css']
})
export class ContraComponent implements OnInit {

  constructor(/* private userAdd: UserAddService, */ /* private billService: BillService, */
    private contraService: ContraService,
    private ChartOfAccountService: ChartOfAccountService,
    private ledgerService: LedgerService, public mainService: MainService, private settingService: SettingService,
    private spinner: NgxSpinnerService) { }
  erpUser:any;
  b_acct_id:any;
  allBankAccounts: any = [];
  contraObj:any = { data: [] };
  allContra :any= [];
  type = [{ code: 'CASH', value: 'CASH' }, { code: 'BANK', value: 'BANK' }];

  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns = ['voucher_no', 'remark', 'voucher_dt', 'status', 'action'];
  datasource:any;
  systemDate:any;
  orgShortName:any;
  fin_year:any;
  allChartOfAccount:any = [];


  async ngOnInit() {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    var resp = await this.mainService.getSystemDate();
    this.systemDate = resp.data;
    this.orgShortName = await this.mainService.accInfo['account_short_name'];

    await this.getAllChartOfAccount();
    await this.getBankAccount();
    await this.getActiveFinYear()
    await this.getContra();
    await this.getCashContra()

  }


  async changeContraStatus(element:any) {
    this.spinner.show();
    let obj = Object.assign(element)
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'APPROVED'
    obj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.contraService.updatecontra(obj);
    if (resp['error'] == false) {
      await this.getContra();
      this.spinner.hide();
      swal.fire("Success", "Approve Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while approve contra !", 'error');
    }
  }


  async getContra() {
    this.spinner.show()
    var resp = await this.contraService.getcontra(this.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allContra = resp['data']
      this.datasource = new MatTableDataSource(this.allContra);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      swal.fire("Error", "..Error while getting Contra!", 'error');
    }
  }

  // Following function control the dropdown of bank and cash
  showObj = ''
  selectedType() {
    this.contraObj['data'].map((x:any) => {
      if (x['type'] == "CASH") {
        this.showObj = "CASH"
      }
    })
  }


  cashMappedCOA: any = [];
  async getCashContra() {
    this.spinner.show()
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.contraService.getcashContra(obj)
    if (resp['error'] == false) {
      this.cashMappedCOA = resp['data'];
      console.log(this.allChartOfAccountobj)
      this.cashMappedCOA.map((x :any)=> {
        x['des'] = this.allChartOfAccountobj[x['chart_of_account']] + "-" + x['type']
      })
      console.log(this.cashMappedCOA)
      this.spinner.hide()
    }
    else {
      this.spinner.hide()
      swal.fire('ERROR', 'Error While Getting Cash At Bank Account', 'error')
    }
  }


  async getActiveFinYear() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getActiveFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      if (resp.data.length == 0) {
        swal.fire("Warning", "..Active Financial Year Not set!", 'warning');
      } else {
        this.fin_year = resp.data[0].fin_year;
      }
    } else {
      this.spinner.hide()
      swal.fire("Error", "..Error while getting active  fin year!", 'error');
    }
  }

  addRow() {
    var obj:any = new Object();
    obj['type'] = 'BANK';
    obj['bank_id'] = '';
    obj['desc'] = "";
    obj['dr'] = 0;
    obj['cr'] = 0;
    this.contraObj.data.push(obj)
    this.no_line = false;
  }
  deleteRowUpdate(i:any) {
    this.contraObj['data'].splice(i, 1);
  }
  addRowUpdate() {
    var obj:any = new Object();
    obj['type'] = 'bank';
    obj['bank_id'] = '';
    obj['desc'] = "";
    obj['dr'] = 0;
    obj['cr'] = 0;
    this.contraObj['data'].push(obj)
  }

  deleteRow(i:any) {
    this.contraObj.data.splice(i, 1);
  }
  open_update(element:any) {
    this.contraObj = Object.assign({}, element);
    this.contraObj['data'] = JSON.parse(element['data'])
    // this.contraObj['data'].map(x=>{
    //   if(x['type'] == 'CASH'){
    //     x['chart_of_account'] = x['bank_id']
    //   }
    // })
    console.log(this.contraObj['data'])
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.contraObj = { data: [] };
    this.contraObj['voucher_date'] = this.systemDate
  }
  bank_relation_obj :any= {}
  async getBankAccount() {
    this.spinner.show()
    var resp = await this.contraService.getMapedBankAccounts(this.b_acct_id);
    if (resp['error'] == false) {
      console.log(resp['data'])
      this.allBankAccounts = resp.data;
      this.allBankAccounts.map((x:any) => {
        this.bank_relation_obj[x['relation']] = x['bank_code']
        x['desc'] = this.allChartOfAccountobj[x['chart_of_account']] + "-" + x['bank_code'] + "-" + x['bank_acct_no'];  //+ "-" + x['ifsc_code']
      })
      console.log(this.allBankAccounts)
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  all fields list!", 'error');
    }
  }


  async process(element:any) {
    // this.spinner.show()
    let arr = JSON.parse(element.data)
    let jrnlArr = []
    const d = new Date();
    let month = d.getMonth();
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]['type'] == 'BANK') {
        if (arr[i]['cr'] > 0) {
          let ob:any = new Object

          ob['chart_of_account'] = arr[i]['chart_of_account']
          ob['db_cd_ind'] = 'CR'

          ob['jrnl_dtl_ln_id'] = 1
          ob['txn_amt'] = arr[i]['cr']
          ob['org_unit_cd'] = this.b_acct_id  //need
          ob['tgt_curr_cd'] = 'INR'
          ob['jrnl_ln_id'] = "_" + i
          ob['fin_year'] = this.fin_year  //need
          // ob['jrnl_id'] = ADDED  OR SEND BY API CODE
          ob['ld_fm'] = (month + 1)
          ob['acct_dt'] = element['voucher_date']
          // ob['jrnl_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['jrnl_desc'] = "By " + this.allChartOfAccountobj[arr[i]['chart_of_account']];
          ob['ledger_type'] = 'ACT'
          ob['prep_id'] = element['create_user_id']
          ob['appr_id'] = this.erpUser.user_id
          ob['ar_actvty_tcd'] = "Contra"
          ob['ar_actvty_lcl_num'] = element['voucher_no']
          ob['ar_src_cd'] = "ACCOUNT"
          ob['ar_cntr_ip_id'] = 0;
          ob['ar_cntr_ip_name'] = "N/A"
          ob['cp_bnk_acct_id'] = arr[i]['bank_id']
          ob['event_code'] = "N/A"
          ob['event_id'] = element.id;
          ob['ld_jrnl_ref_num'] = "ACCOUNT-CONTRA-" + element.id;
          ob['ld_jrnl_lfcycl_st_cd'] = "POSTED"
          ob['ld_jrnl_unpost_seq_num'] = 0;
          ob['ld_acct_per'] = 0
          ob['ld_cat_cd'] = 'FIN';
          ob['ld_jrnl_rvrsl_tcd'] = "I"
          ob['ld_tgt_cur_tcd'] = "FN"
          ob['ld_jrnl_entry_cd'] = "Auto"
          ob['ld_jrnl_ln_ksn'] = element['voucher_no'] + "-" + "CONTRA";
          ob['ld_jrnl_ln_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['ld_src_cd'] = "ACCOUNT";
          ob['ld_prj_cd'] = 0;
          ob['ld_pd_cd'] = 0;
          ob['ld_tech_comp_cd'] = 'ARE';
          ob['ld_jrnl_dtln_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['ld_bus_func_cd'] = "CONTRA2JV"
          ob['ld_unadj_acct_dt'] = this.systemDate
          ob['pmt_bnk_acct_id'] = 0
          ob['ld_bugt_cd'] = 0
          ob['ld_aff_cc_cd'] = 0
          ob['ld_cc_cd'] = 0
          ob['ld_jrnl_dtl_ln_ksn'] = "_" + i + "_" + i;
          ob['arr_id'] = 0;
          ob['ev_cts'] = element['voucher_date']
          jrnlArr.push(ob)

        }
        if (arr[i]['dr'] > 0) {
          let ob:any = new Object

          ob['chart_of_account'] = arr[i]['chart_of_account']
          ob['db_cd_ind'] = 'DB'

          ob['jrnl_dtl_ln_id'] = 1;
          ob['jrnl_ln_id'] = "_" + i
          ob['txn_amt'] = (-1 * arr[i]['dr'])
          ob['org_unit_cd'] = this.b_acct_id  //need
          ob['tgt_curr_cd'] = 'INR'
          ob['fin_year'] = this.fin_year  //need
          // ob['jrnl_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['jrnl_desc'] = "To " + this.allChartOfAccountobj[arr[i]['chart_of_account']];
          ob['acct_dt'] = element['voucher_date']
          // ob['jrnl_id'] = ADDED  OR SEND BY API CODE
          ob['ledger_type'] = 'ACT'
          ob['prep_id'] = element['create_user_id']
          ob['appr_id'] = this.erpUser.user_id
          ob['ar_actvty_tcd'] = "Contra"
          ob['ar_src_cd'] = "ACCOUNT"
          ob['ar_cntr_ip_id'] = 0;
          ob['ar_cntr_ip_name'] = "N/A"
          ob['cp_bnk_acct_id'] = arr[i]['bank_id']
          ob['ld_fm'] = (month + 1)
          ob['event_code'] = "N/A"
          ob['event_id'] = element.id;
          ob['ld_jrnl_ref_num'] = "ACCOUNT-CONTRA-" + element.id;
          ob['ld_jrnl_lfcycl_st_cd'] = "POSTED"
          ob['ld_jrnl_unpost_seq_num'] = 0;
          ob['ld_acct_per'] = 0
          ob['ld_cat_cd'] = 'FIN';
          ob['ld_jrnl_rvrsl_tcd'] = "I"
          ob['ld_tgt_cur_tcd'] = "FN"
          ob['ld_jrnl_entry_cd'] = "Auto"
          ob['ld_jrnl_ln_ksn'] = element['voucher_no'] + "-" + "CONTRA";
          ob['ld_jrnl_ln_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['ld_src_cd'] = "ACCOUNT";
          ob['ld_prj_cd'] = 0;
          ob['ld_pd_cd'] = 0;
          ob['ld_tech_comp_cd'] = 'ARE';
          ob['ld_jrnl_dtln_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['ld_bus_func_cd'] = "CONTRA2JV"
          ob['ld_unadj_acct_dt'] = this.systemDate
          ob['pmt_bnk_acct_id'] = 0
          ob['ld_bugt_cd'] = 0
          ob['ld_aff_cc_cd'] = 0
          ob['ld_cc_cd'] = 0
          ob['arr_id'] = 0;
          ob['ld_jrnl_dtl_ln_ksn'] = "_" + i + "_" + i;
          ob['ar_actvty_lcl_num'] = element['voucher_no']
          ob['ev_cts'] = element['voucher_date']

          jrnlArr.push(ob)

        }
      } else if (arr[i]['type'] == 'CASH') {
        if (arr[i]['cr'] > 0) {
          let ob :any= new Object

          ob['chart_of_account'] = arr[i]['chart_of_account']
          ob['db_cd_ind'] = 'CR'

          ob['jrnl_dtl_ln_id'] = 1
          ob['jrnl_ln_id'] = "_" + i
          ob['txn_amt'] = arr[i]['cr']
          ob['org_unit_cd'] = this.b_acct_id  //need
          ob['tgt_curr_cd'] = 'INR'
          ob['ld_fm'] = (month + 1)
          ob['fin_year'] = this.fin_year  //need
          // ob['jrnl_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['jrnl_desc'] = "By " + this.allChartOfAccountobj[arr[i]['chart_of_account']];
          ob['acct_dt'] = element['voucher_date']
          // ob['jrnl_id'] = ADDED  OR SEND BY API CODE
          ob['ledger_type'] = 'ACT'
          ob['prep_id'] = element['create_user_id']
          ob['appr_id'] = this.erpUser.user_id
          ob['ar_actvty_tcd'] = "Contra"
          ob['ar_src_cd'] = "ACCOUNT"
          ob['ar_cntr_ip_id'] = 0;


          ob['ar_cntr_ip_name'] = "N/A"
          // ob['cp_bnk_acct_id'] = arr[i]['bank_id']
          ob['cp_bnk_acct_id'] = arr[i]['chart_of_account']
          ob['event_code'] = "N/A"
          ob['event_id'] = 0 // element['voucher_no']
          ob['ld_jrnl_ref_num'] = "ACCOUNT-CONTRA-" + element.id;
          ob['ld_jrnl_lfcycl_st_cd'] = "PROCESSED"
          ob['ld_jrnl_unpost_seq_num'] = 0;
          ob['ld_acct_per'] = 0
          ob['ld_cat_cd'] = 'FIN';
          ob['ld_jrnl_rvrsl_tcd'] = "I"
          ob['ld_tgt_cur_tcd'] = "FN"
          ob['ld_jrnl_entry_cd'] = "Auto"
          ob['ld_jrnl_ln_ksn'] = element['voucher_no'] + "-" + "CONTRA";
          ob['ld_jrnl_ln_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['ld_src_cd'] = "ACCOUNT";
          ob['ld_prj_cd'] = 0;
          ob['ld_pd_cd'] = 0;
          ob['ld_tech_comp_cd'] = 'ARE';
          ob['ld_jrnl_dtl_ln_ksn'] = "_" + i + "_" + i;
          ob['ld_jrnl_dtln_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['ld_bus_func_cd'] = "CONTRA2JV"
          ob['ld_unadj_acct_dt'] = this.systemDate
          ob['pmt_bnk_acct_id'] = 0
          ob['ld_bugt_cd'] = 0
          ob['ld_aff_cc_cd'] = 0
          ob['ld_cc_cd'] = 0
          ob['arr_id'] = 0;
          ob['ar_actvty_lcl_num'] = element.id
          ob['ev_cts'] = element['voucher_date']

          jrnlArr.push(ob)
        }
        if (arr[i]['dr'] > 0) {
          let ob:any = new Object();

          ob['chart_of_account'] = arr[i]['chart_of_account']
          ob['db_cd_ind'] = 'DB'
          ob['ld_fm'] = (month + 1)
          ob['txn_amt'] = (-1 * arr[i]['dr'])
          ob['org_unit_cd'] = this.b_acct_id   //need
          ob['tgt_curr_cd'] = 'INR'
          ob['jrnl_dtl_ln_id'] = 1;
          ob['fin_year'] = this.fin_year  //need
          ob['acct_dt'] = element['voucher_date']
          ob['jrnl_ln_id'] = "_" + i
          // ob['jrnl_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['jrnl_desc'] = "To " + this.allChartOfAccountobj[arr[i]['chart_of_account']];
          // ob['proc_dt'] = this.systemDate //need
          ob['ledger_type'] = 'ACT'
          ob['prep_id'] = element['create_user_id']
          ob['appr_id'] = this.erpUser.user_id
          ob['ar_actvty_tcd'] = "Contra",
            ob['ar_src_cd'] = "ACCOUNT"
          ob['ar_cntr_ip_id'] = 0;
          ob['ar_cntr_ip_name'] = "N/A"
          // ob['cp_bnk_acct_id'] = arr[i]['bank_id']
          ob['cp_bnk_acct_id'] = arr[i]['chart_of_account']
          ob['event_code'] = "N/A"
          ob['event_id'] = 0 // element['voucher_no']
          ob['ld_jrnl_ref_num'] = "ACCOUNT-CONTRA-" + element.id;
          ob['ld_jrnl_lfcycl_st_cd'] = "PROCESSED"
          ob['ld_jrnl_unpost_seq_num'] = 0;
          ob['ld_acct_per'] = 0
          ob['ld_cat_cd'] = 'FIN';
          ob['ld_jrnl_rvrsl_tcd'] = "I"
          ob['ld_tgt_cur_tcd'] = "FN"
          ob['ld_jrnl_entry_cd'] = "Auto"
          ob['ld_jrnl_ln_ksn'] = element.id + "-" + "CONTRA";
          ob['ld_jrnl_dtl_ln_ksn'] = "_" + i + "_" + i;
          ob['ld_jrnl_ln_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['ld_src_cd'] = "ACCOUNT";
          ob['ld_prj_cd'] = 0;
          ob['ld_pd_cd'] = 0;
          ob['ld_tech_comp_cd'] = 'ARE';
          ob['ld_jrnl_dtln_desc'] = "ACCOUNT" + "-CONTRA-" + element.id + "-" + element['remark']
          ob['ld_bus_func_cd'] = "CONTRA2JV"
          ob['ld_unadj_acct_dt'] = this.systemDate
          ob['pmt_bnk_acct_id'] = 0
          ob['ld_bugt_cd'] = 0
          ob['ld_aff_cc_cd'] = 0
          ob['ld_cc_cd'] = 0
          ob['arr_id'] = 0;
          ob['ar_actvty_lcl_num'] = element.id
          ob['ev_cts'] = element['voucher_date']


          jrnlArr.push(ob)

        }
      }
    }
    let obj:any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'POSTED'
    obj['jrnl'] = jrnlArr
    console.log(jrnlArr);
    obj['id'] = [element.id]
    obj['update_user_id'] = this.erpUser.user_id
    var resp = await this.contraService.insertProcessedVoucherData(obj);
    if (resp['error'] == false) {
      this.contraObj['voucher_no'] = resp['data']
      await this.getContra()
      this.spinner.hide();
      swal.fire("Success", "Processed Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while processing voucher!", 'error');
    }
  }


  async delete(element:any) {
    this.spinner.show();
    let obj:any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = element['id']
    var resp = await this.contraService.deletecontra(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getContra();
      swal.fire("Success", "...Contra Deleted Successfully!!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while deleting contra!", 'error');
    }
  }


  async update(form:any) {
    // await this.validateForm(form);
    // this.spinner.show();
    // if (!this.validation()) {
    //   this.spinner.hide();
    //   return;
    // }

    if (!await this.validateForm(form)) {
      return;
    }

    let obj:any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'GENERATED';

    // let total_cr = 0;
    // let total_dr = 0;
    // let flage = false;
    // this.contraObj['data'].map(x => {
    //   total_cr += x['cr'];
    //   total_dr += x['dr'];
    //   if (x['dr'] > 0 && x['cr'] > 0) {
    //     flage = true;
    //   }

    // })
    // if (total_cr != total_dr) {
    //   swal.fire('INFO', ' Dr Amount Must Be Equal To Cr Amount ', "info")
    //   this.spinner.hide()
    //   return;
    // }
    // if (flage) {
    //   swal.fire('INFO', 'Please Fill Either Dr OR Cr Amount Not Both ', "info")
    //   this.spinner.hide()
    //   return;
    // }
    obj['data'] = JSON.stringify(this.contraObj['data'])
    obj['voucher_date'] = this.contraObj['voucher_date']
    obj['remark'] = this.contraObj['remark']
    obj['voucher_no'] = this.contraObj['voucher_no']
    obj['id'] = this.contraObj['id']
    obj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.contraService.updatecontra(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getContra();
      this.contraObj = { data: [] }
      $('.nav-tabs a[href="#tab-1"]').tab("show");
      swal.fire("Success", "...Contra Update Successfully!!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while updating contra!", 'error');

    }
  }

  // validation() {
  //   let db_cd_flag = false;
  //   let bank_flage = false;
  //   let validate = true;
  //   let total_cr = 0;
  //   let total_dr = 0;


  //   this.contraObj['data'].map(x => {
  //     total_cr += x['cr'];
  //     total_dr += x['dr'];
  //     if (x['dr'] > 0 && x['cr'] > 0) {
  //       db_cd_flag = true;
  //       validate = false;
  //     }
  //     else if (!x['bank_id']) {
  //       bank_flage = true;
  //       validate = false;
  //     }
  //   })

  //   if (bank_flage) {
  //     swal.fire('INFO', 'Please Select Bank Account ', "info")
  //     this.spinner.hide()
  //     return validate;
  //   }

  //   if (total_cr != total_dr) {
  //     swal.fire('INFO', 'Please Fill Either Dr OR Cr Amount Not Both ', "info")
  //     this.spinner.hide()
  //     validate = false;
  //     return validate;
  //   }

  //   if (db_cd_flag) {
  //     swal.fire('INFO', 'Please Fill Either Dr OR Cr Amount Not Both ', "info")
  //     this.spinner.hide()
  //     return validate;
  //   }

  //   // if (this.contraObj['data'].length % 2 != 0) {
  //   //   swal.fire('INFO', 'Please Fill Contra Correctly ', "info")
  //   //   this.spinner.hide()
  //   //   validate = false;
  //   //   return validate;
  //   // }
  //   return validate;
  // }

  no_line: boolean = false;
  message:any;


  async validateForm(form:any) {

    //***************************************validation start********************************************/
    let flag: boolean = true;
    let dr_tot = 0
    let cr_tot = 0

    if (this.contraObj['data'].length == 0 ) {
      this.no_line = true;
      this.message = "Please add contra"
      return;
    }else if( this.contraObj['data'].length % 2 !=0){
      this.no_line = true;
      this.message = "Please add correct contra"
      return;
    }


    if (!form.valid) {
      form.status = 'INVALID'
      return flag = false;
     }

    this.contraObj['data'].map((x:any) => {
      dr_tot += x['dr'];
      cr_tot += x['cr'];
      if (x['dr'] > 0 && x['cr'] > 0 || x['dr'] == x['cr']) {
        // form.status = 'INVALID';

        return flag = false;
      }
      return
    })

    if (dr_tot != cr_tot) {
      // form.status = 'INVALID';
      // swal.fire("Warinrg", "Credit amount does not match with Debit amount ", 'warning');
      this.no_line = true;
      this.message = "Credit amount does not match with Debit amount ";
      return flag = false;
    }


    return flag;

    //************************************ */ Validation end*************************************************/
  }

  async submit(form:any) {
    if (!await this.validateForm(form)) {
      console.log("Form value is : ", await this.validateForm(form))
      return;
    }

    this.spinner.show()
    let obj:any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'GENERATED';
    this.contraObj['data'].map((x:any) => {
      x['chart_of_account'] = this.bank_relation_obj[x['bank_id']];
      if (x['type'] == "CASH") {
        x['chart_of_account'] = 0;
      }
    })
    obj['data'] = JSON.stringify(this.contraObj['data'])
    obj['voucher_date'] = this.systemDate
    obj['remark'] = this.contraObj['remark']
    obj['create_user_id'] = this.erpUser.user_id
    var resp = await this.contraService.addcontra(obj);
    if (resp['error'] == false) {
      this.contraObj['voucher_no'] = resp['data']
      await this.getContra()
      this.spinner.hide();
      swal.fire("Success", "Voucher Created Successfully!", 'success');
      this.no_line=false;
      document.getElementById('reset')!.click();
      this.contraObj = { data: [] }
      $('.nav-tabs a[href="#tab-1"]').tab("show");
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while creating voucher!", 'error');
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  allChartOfAccountobj :any= {}
  async getAllChartOfAccount() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ChartOfAccountService.getchartofaccount(obj);
    if (resp['error'] == false) {
      this.allChartOfAccount = resp.data;
      for (let i = 0; i < this.allChartOfAccount.length; i++) {
        this.allChartOfAccountobj[this.allChartOfAccount[i]['leaf_code']] = this.allChartOfAccount[i]['leaf_value']
      }
      this.spinner.hide()
    } else {
      this.spinner.hide()
    }
  }

  async print1(element:any) {

    this.spinner.show()
    var data = element;
    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")";
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        let arr = []
        let obj = { text: txt + "  Page No. - " + currentPage, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'landscape',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };


    var header0 = {
      columns: [
        {
          width: '*',
          text: 'Contra Voucher',
          bold: true,
          alignment: 'center',
          fontSize: 18
        }
      ],

    }

    var header1 = {
      columns: [
        {
          width: '*',
          text: 'Voucher No :',
          bold: true
        },
        {
          width: '*',
          text: data['voucher_no']
        },
        {

          width: '*',
          text: 'Voucher Date :',
          bold: true
        },
        {
          width: '*',
          text: this.mainService.dateFormatChange(data['voucher_date'])
        }
      ]

    }
    var header2 = {
      columns: [
        {
          width: '*',
          text: 'Remark :',
          bold: true

        },
        {
          width: '*',
          text: data['remark']
        },
        {
          width: '*',
          text: ""
        },
        {
          width: '*',
          text: ""
        }
      ]

    }

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] });
    dd.content.push(header0);

    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push(header2);
    dd.content.push({ text: " " });

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] });
    dd.content.push({ text: " " });


    var dt = JSON.parse(data['data']);
    console.log(dt)

    var tbl = {

      layout: 'lightHorizontalLines',
      fontSize: 15,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*'],

        body: [
          ['SNO', 'Type', 'Head', 'Description', 'DR', 'CR']


        ]
      }
    };
    dd.content.push(tbl);
    let dr = 0;
    let cr = 0;
    for (let i = 0; i < dt.length; i++) {
      let arr = []
      arr.push(i + 1);
      arr.push(dt[i]['type'])
      if (dt[i]['type'] == 'CASH') {
        arr.push(this.allChartOfAccountobj[dt[i]['bank_id']])
      } else {
        arr.push(this.bank_relation_obj[dt[i]['bank_id']])
      }
      arr.push(dt[i]['desc'])
      arr.push(dt[i]['dr'])
      arr.push(dt[i]['cr'])
      dr = dr + dt[i]['dr'];
      cr = cr + dt[i]['cr'];
      console.log(arr)
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    var header3 = {
      columns: [
        {
          width: '*',
          text: 'Total DR :',
          bold: true

        },
        {
          width: '*',
          text: dr
        },
        {
          width: '*',
          text: 'Total CR :',
          bold: true

        },
        {
          width: '*',
          text: cr
        }
      ],

    }
    dd.content.push({ text: " " });
    dd.content.push(header3);

    pdfMake.createPdf(dd).download('contra-voucher.pdf');
    this.spinner.hide()
  }
  /***********************************Unused Code *********************************************************************** */

  // async schedule(element:any) {
  //   console.log(element)
  //   var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['data'] = [];
  //   for (var i = 0; i < this.allApproval.length; i++) {
  //     if (this.allApproval[i]['doc_type'] == 'CONTRA') {
  //       obj['data'].push({
  //         user_id: this.allApproval[i]['user_id'],
  //         level_of_approval: this.allApproval[i]['level_of_approval'],
  //         doc_type: this.allApproval[i]['doc_type'],
  //         create_user_id: this.erpUser.user_id,
  //         doc_local_no: element['voucher_no'],
  //         doc_local_desc: element['voucher_no'] + "-" + element['remark'],
  //         status: 'PENDING'
  //       })
  //     }
  //   }



  //   var resp = await this.billService.sendToApproval(obj);
  //   if (resp['error'] == false) {
  //     await this.changeContraStatus(element);
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide();

  //     swal.fire('Error', 'Error while sending the Contra to approvel', 'error');
  //   }
  // }








  // async status(element:any) {
  //   var obj:any = new Object();
  //   console.log(element);
  //   obj["b_acct_id"] = this.b_acct_id;
  //   obj["bill_id"] = element.voucher_no;
  //   console.log(obj)
  //   this.spinner.show();
  //   var resp = await this.billService.getDocumentStatus(obj);
  //   console.log(resp);
  //   if (resp['error'] == false) {
  //     this.statusArr = resp['data'];
  //     $('#myModal_Status').modal('show');
  //     this.spinner.hide()
  //   } else {
  //     this.spinner.hide();
  //     swal.fire("Error", "Error while getting status", 'error');
  //   }
  // }


  // statusArr = [];

  // allApproval = [];
  // levelOfApproval = {};
  // async getAllApproveRule() {
  //   this.spinner.show()
  //   var resp = await this.billService.getAllApproval(this.b_acct_id);
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     this.allApproval = resp['data'];
  //     for (var i = 0; i < this.allApproval.length; i++) {
  //       if (this.allApproval[i]['doc_type'] == 'CONTRA') {
  //         this.levelOfApproval[this.allApproval[i]['level_of_approval']] = this.allApproval[i];
  //       }
  //     }
  //   } else {
  //     this.spinner.hide()
  //   }
  // }
  // allUser = [];
  // UserObj = {};
  // async getUsersInfo() {
  //   this.spinner.show()
  //   var obj = { b_acct_id: this.b_acct_id };
  //   var resp = await this.userAdd.getAllUsersInfo(JSON.stringify(obj));
  //   console.log(resp);
  //   if (resp['error'] == false) {
  //     this.allUser = resp['data'];
  //     this.spinner.hide()
  //     for (let i = 0; i < this.allUser.length; i++) {
  //       this.UserObj[this.allUser[i]['user_id']] = this.allUser[i]['first_name'] + " " + this.allUser[i]['last_name']
  //       this.allUser[i]['name'] = this.allUser[i]['first_name'] + " " + this.allUser[i]['last_name']
  //     }
  //   } else {
  //     this.spinner.hide()
  //   }
  // }
}
