<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Employee Salary Hold  -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary1']}}</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                <!-- Employee Salary Hold List -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"></i>
                                <!-- Add Employee Salary Hold -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary3']}}</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="row">
                            <div class="col-4 text-right">
                                <!-- Select Status : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary32']}}
                            </div>
                            <div class="col-4">
                                <ng-select [items]="status" bindLabel="id" bindValue="id" (change)="changeTable()"
                                    [multiple]="false" placeholder="" [(ngModel)]="status_s" [selectableGroup]="true"
                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                </ng-select>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> <!-- ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary4']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary5']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.tempid}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary6']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="fin_year">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Start Financial Year -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.start_fin_year}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="fin_year2">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Stop Financial Year -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.stop_fin_year}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="month">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Start Month -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary9']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.start_month}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="month2">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Stop Month -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary10']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.stop_month}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Status -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary11']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.status}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary12']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button *ngIf="element.status == 'STOP'" class="btn btn-success"
                                                (click)="changeStatus(element)"><!-- START -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary13']}}</button>
                                            <button class="btn btn-primary"
                                                (click)="viewPaidStatus(element)"><!-- View Paid Details -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary14']}}</button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary15']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id"
                                        (change)="changeEmployee()" [multiple]="false" placeholder=""
                                        [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Financial Year : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary16']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="Obj['stop_fin_year']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                    <!-- <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="" (click)="getAscending()"
                                        [(ngModel)]="Obj['stop_fin_year']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Month : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary17']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">


                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="Obj['stop_month']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>



                            </div>
                            <br>




                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary18']}}</button>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<div class="modal" id="myModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> <!-- Start Salary -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary19']}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-4 text-right">
                        <!-- Start Financial Year : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary20']}}<span
                                                    style="color: red;">*</span>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value" bindValue="code"
                            [multiple]="false" placeholder="" [(ngModel)]="Obj['start_fin_year']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <!-- Start Month : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary21']}}<span
                                                    style="color: red;">*</span>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code"
                            [multiple]="false" placeholder="" [(ngModel)]="Obj['start_month']" [selectableGroup]="true"
                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"  (click)="changeStatusstart()"><!-- Start -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary22']}}</button>

                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal"><!-- Close -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary23']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> <!-- Paid Salary Details : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary24']}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-3 text-right"><h6><!-- Employee ID : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary25']}}</h6></div>
                    <div class="col-3">{{view_data_header['tempid']}}</div>
                    <div class="col-3 text-right"><h6><!-- Employee Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary26']}}</h6></div>
                    <div class="col-3">{{view_data_header['emp_name']}}</div>
                </div>
                <br>

                <div class="">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th><!-- S No. -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary27']}}</th>
                                <th><!-- Month -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary28']}}</th>
                                <th><!-- Year -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary29']}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let item of view_data;let i=index">
                                <td>{{i+1}}</td>
                                <!-- <td>{{item['month']}}</td> -->
                                <td>{{this.mainService.codeValueShowObj['HR0024'][item['month']]}}</td>
                                <!-- <td>{{item['fin_year']}}</td> -->
                                <td>{{this.mainService.codeValueShowObj['HR0023'][item['fin_year']]}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal"><!-- Close -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary30']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
<ngx-spinner><!-- Loading.... -->{{mainService.allLableShowObjHR[mainService.language_cd+'Salary31']}}</ngx-spinner>
