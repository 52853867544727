import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class AdministrationService {


  httpUrl;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;
  }
  async getVmsAssigneddata2(obj:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/vms/administration/data-assignment/getVmsAssigned' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertDataAssignment(obj:any) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/vms/administration/data-assignment/insertDataAssignment' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateDataAssignment(obj:any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/vms/administration/data-assignment/updateDataAssign' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

}
