<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- CB -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB1']}}
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="list_flag" class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                <!-- All CB List -->
                                {{mainService.allLableShowObj[mainService.language_cd+'EMB-CB2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="create_flag" class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>
                                <!-- Create CB  -->
                                {{mainService.allLableShowObj[mainService.language_cd+'EMB-CB3']}}</a>
                        </li>


                        <li class="nav-item">
                            <a *ngIf="update_flag" class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                                <!-- Update CB --> {{mainService.allLableShowObj[mainService.language_cd+'EMB-CB4']}}
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <br>
                            <div style="text-align: right;">
                                <button mat-button mat-raised-button class="btn btn-primary" (click)="createtab()">
                                    Create
                                </button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right ">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB40']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="AllWorkInfo" bindLabel="desc" (change)="changeList()" bindValue="work_order_no" [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB-CB40']}} " [(ngModel)]="work_id" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="work_id">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <br>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="cb_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            <!-- CB ID -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB5']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.cb_id }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Party Name -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ partyObj[element.party_id ]}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="work_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--  Work Order No -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB7']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_id }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="remark">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Remark -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB10']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.remark }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="cb_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- CB Date -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <p *ngIf="element.cb_date">{{element.cb_date}}
                                            </p>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="invoice_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Invoice No. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.invoice_no }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="invoice_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Invoice Date -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB11']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <p *ngIf="element.invoice_date">
                                                {{element.invoice_date}}</p>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Status -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.status }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB13']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-primary" *ngIf="element.status=='GENERATED'" (click)="open_update(element)">
                                                <!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB14']}}
                                            </button>
                                            <button class="btn btn-danger" *ngIf="element.status=='GENERATED'" (click)="deleteCB(element)">
                                                <!-- Delete -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB15']}}
                                            </button>
                                            <button class="btn btn-primary" (click)="print(element)">
                                                <!-- Print -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB16']}}
                                            </button>
                                            <button class="btn btn-info" (click)="Appr(element)" *ngIf="element['status'] == 'GENERATED' && apprflag == true">Add
                                                Approval
                                            </button>&nbsp;
                                            <!-- <button class="btn btn-primary" (click)="sendToAcount(element)">Send To Account</button> -->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div *ngIf="create_flag">
                            <div style="text-align: right;">
                                <button mat-button mat-raised-button class="btn btn-primary" (click)="listbtab()">
                                    Go-Back
                                      </button></div>
                            <br>

                            <div class="row">
                                <div class="col-2">
                                    <h6>
                                        <!-- Work Order No. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB7']}} :
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <ng-select [items]="AllWorkInfo" bindLabel="desc" (change)="changework()" bindValue="work_order_no" [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB-CB40']}} " [(ngModel)]="obj['work_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_id">
                                    </ng-select>

                                </div>

                                <div class="col-2">
                                    <h6>
                                        <!-- CB Date  -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB8']}}:
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <input class="form-control" type="date" [(ngModel)]="obj['cb_date']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'EMB-EMB61']}} :</h6>
                                </div>
                                <div class="col-10">
                                    {{obj['path_desc']}}
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-2">
                                    <h6>
                                        <!-- Work/ Service Name -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB17']}} :
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    {{obj['work_order_name']}}

                                </div>

                                <div class="col-2">
                                    <h6>
                                        <!-- Invoice No. -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-CB9']}}:
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <input class="form-control" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB41']}}" type="text" [(ngModel)]="obj['invoice_no']">
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2">
                                    <h6>
                                        <!-- Select Party -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB18']}} :
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <ng-select [items]="AllParty" bindLabel="party_leagal_name" bindValue="rep_le_id" [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB18']}}" [(ngModel)]="obj['party_id']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="party_id">
                                    </ng-select>

                                </div>
                                <div class="col-2">
                                    <h6>
                                        <!-- Invoice Date -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB11']}} :
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <input class="form-control" type="date" [(ngModel)]="obj['invoice_date']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2">
                                    <h6>
                                        <!-- Remark -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-CB10']}}:
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <textarea class="form-control" [(ngModel)]="obj['remark']"></textarea>
                                </div>

                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-6 text-right">
                                    <h5><b>
                                            <!-- PAYABLES -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB19']}}
                                        </b></h5>

                                </div>
                                <div class="col-6 text-right">
                                    <button class="btn btn-primary" (click)="addRow()"><i class="fa fa-plus"
                                            aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <br>
                            <div>
                                <div class="row" style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                    <div style="width: 5%;" class="text-center">

                                        <h6><b>
                                                <!-- S No. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB20']}}
                                            </b></h6>

                                    </div>
                                    <div style="width: 30%;" class="text-center">
                                        <h6><b>
                                                <!-- Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB21']}}
                                            </b></h6>
                                    </div>



                                    <div style="width: 10%;" class="text-center">
                                        <h6><b>
                                                <!-- Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB22']}}
                                            </b></h6>


                                    </div>


                                    <div style="width: 10%;" class="text-center">
                                        <h6><b>
                                                <!-- CGST Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB23']}}
                                            </b></h6>


                                    </div>
                                    <div style="width: 10%;" class="text-center">
                                        <h6><b>
                                                <!-- SGST Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB24']}}
                                            </b></h6>



                                    </div>
                                    <div style="width: 10%;" class="text-center">
                                        <h6><b>
                                                <!-- IGST Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB25']}}
                                            </b></h6>


                                    </div>

                                    <div style="width: 15%;" class="text-center">
                                        <h6><b>
                                                <!-- Total Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB26']}}
                                            </b></h6>


                                    </div>
                                    <div style="width: 10%;" class="text-center">
                                        <h6><b></b></h6>
                                    </div>
                                </div>
                                <br>
                                <div *ngFor="let ln of AllBillRow; let i = index;">
                                    <div class="row">
                                        <div class="text-center" style="width: 5%;">
                                            <h6><b> {{i+1}}.</b></h6>
                                        </div>
                                        <div style="width: 30%;">
                                            <input type="text" class="form-control" placeholder="" [(ngModel)]="ln['event_desc']">
                                        </div>

                                        <div style="width: 10%;">
                                            <input type="number" class="form-control" placeholder="" [(ngModel)]="ln['amount']" (change)="ChangeAmount(i)">
                                        </div>

                                        <div style="width: 10%;">
                                            <input type="number" class="form-control" placeholder="" [(ngModel)]="ln['cgst']" (change)="ChangeAmount(i)">
                                        </div>
                                        <div style="width: 10%;">
                                            <input type="number" class="form-control" placeholder="" [(ngModel)]="ln['sgst']" (change)="ChangeAmount(i)">
                                        </div>
                                        <div style="width: 10%;">
                                            <input type="number" class="form-control" placeholder="" [(ngModel)]="ln['igst']" (change)="ChangeAmount(i)">
                                        </div>

                                        <div style="width: 15%;">
                                            <label style="margin-left: 5%;">{{ln['total_amount']}}</label>
                                        </div>
                                        <div style="width: 10%;">
                                            <button class="btn btn-danger " (click)="deleteRow(i)"><i
                                                    class="fa fa-trash" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-6 text-right">
                                            <b>
                                                <!-- Deductions -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB27']}}:
                                            </b>
                                        </div>
                                        <div class="col-6">
                                            <button class="btn btn-primary " style="margin-left: 3%;" (click)="dedAdd(i)"><i class="fa fa-plus"
                                                    aria-hidden="true"></i></button>
                                        </div>

                                    </div><br>
                                    <div *ngFor="let ded of ln['ded']; let j = index;" style="width: 80%; margin-left: 10%;">
                                        <div class="row">
                                            <div style="width: 5%;">

                                            </div>
                                            <div class="text-center" style="width: 5%;">
                                                {{j+1}}.
                                            </div>
                                            <div style="width: 20%;">
                                                <ng-select [items]="AllDedList" placeholder="Select Deduction " bindLabel="ded_name" bindValue="deduction_id" [multiple]="false" [(ngModel)]="ded['deduction_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" (change)="ChangeDed(ded['deduction_id'],i,j)"
                                                    name="deduction_id">
                                                </ng-select>
                                            </div>
                                            <div style="width: 13%; text-align: center;" *ngIf="ded['deduction_id']!=undefined">
                                                <ng-select [items]="amountTypeArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="Select Amount Type" [(ngModel)]="ded['amt_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="amt_type">
                                                </ng-select>
                                            </div>
                                            <div style="width: 12%;" *ngIf="ded['deduction_id']!=undefined">
                                                <input type="number" class="form-control" placeholder="" [(ngModel)]="ded['ded_amount']" disabled>
                                            </div>

                                            <div style="width: 7%;">
                                                <button class="btn btn-danger " (click)="deleteDed(i,j)"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">

                                        <div class="col-12 text-center">

                                            <h6><b>
                                                    <!-- DEDUCTION AMOUNT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB28']}}:
                                                    {{ln['deduction_amount'].toFixed(2)}}
                                                </b></h6>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">

                                        <div class="col-12 text-center">

                                            <h6><b>
                                                    <!-- TOTAL PAYABLE AMOUNT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB29']}}:
                                                    {{ln['payable_amount'].toFixed(2)}}
                                                </b></h6>
                                        </div>
                                    </div>

                                    <hr>
                                </div>

                                <div class="row" style="padding-top: 12px;padding-bottom: 12px;">
                                    <div class="text-center" style="width: 5%;">
                                    </div>
                                    <div style="width: 25%;">
                                        <b>
                                            <!-- TOTAL AMOUNT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB30']}}
                                        </b>
                                    </div>
                                    <div style="width: 14%;">

                                    </div>
                                    <div style="width: 10%;" class="text-center">
                                        <b>{{totalAmount.toFixed(2)}}</b>
                                    </div>
                                    <div style="width: 10%;">

                                    </div>
                                    <div style="width: 7%;" class="text-center">
                                        <b>{{cgstAmount.toFixed(2)}}</b>

                                    </div>
                                    <div style="width: 7%;" class="text-center">
                                        <b>{{sgstAmount.toFixed(2)}}</b>
                                    </div>
                                    <div style="width: 7%;" class="text-center">
                                        <b>{{igstAmount.toFixed(2)}}</b>
                                    </div>

                                    <div style="width: 10%;" class="text-center">

                                        <b>{{net_amount.toFixed(2)}}</b>
                                    </div>

                                </div>
                            </div>

                            <br>
                            <hr>
                            <div class="row">

                                <div class="col-12 text-right">

                                    <h5><b>
                                            <!-- NET PAYABLE AMOUNT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB31']}}:
                                            {{net_amount.toFixed(2)}}
                                        </b></h5>
                                </div>

                            </div>

                            <hr>
                            <div class="row">
                                <div class="col-10 text-left">
                                    <h5><b>
                                            <!-- TOTAL DEDUCTIONS -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB32']}}
                                        </b></h5>

                                </div>

                            </div>
                            <br>
                            <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                <thead style="background-color: #1589FF; color: white;">
                                    <tr>
                                        <th>
                                            <!-- S No. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB20']}}
                                        </th>
                                        <th>
                                            <!-- Deduction Type -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB39']}}
                                        </th>
                                        <th>
                                            <!-- Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB22']}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dn of AllDedRow; let j = index;">
                                        <td>{{j+1}}</td>
                                        <td>{{dn['deduction_name']}}</td>
                                        <td> {{dn['ded_amount'].toFixed(2)}}</td>
                                    </tr>

                                </tbody>
                            </table>

                            <br>
                            <div class="row">

                                <div class="col-12 text-right">
                                    <h6><b>
                                            <!-- TOTAL DEDUCTION AMOUNT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB33']}}:
                                            {{total_ded_amount.toFixed(2)}}
                                        </b></h6>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-6"></div>
                                <div class="col-3 text-right">
                                    <h6>
                                        <!-- With Held Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB34']}} :
                                    </h6>
                                </div>
                                <div class="col-3 text-right">
                                    <input class="form-control" type="number" [(ngModel)]="withheldamt">
                                </div>
                            </div>

                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-6"></div>
                                <div class="col-3 text-right">
                                    <h6>
                                        <!-- NET PAYABLE AMOUNT AFTER WITH HELD -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB35']}} :
                                    </h6>
                                </div>
                                <div class="col-3 text-right">
                                    {{(this.net_amount - this.withheldamt).toFixed(2)}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-info" (click)="check()">
                                        <!-- Check -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB36']}}
                                    </button>&nbsp;&nbsp;
                                    <button class="btn btn-primary" (click)="save()">
                                        <!-- Save -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB37']}}
                                    </button>&nbsp;&nbsp;

                                </div>
                            </div>
                        </div>
                        <div *ngIf="update_flag">
                            <div style="text-align: right;">
                                <button mat-button mat-raised-button class="btn btn-primary" (click)="listbtab()">
                                    Go-Back
                                      </button>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-2">
                                    <h6>
                                        <!--  Work Order No. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB7']}} :
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <ng-select [items]="AllWorkInfo" bindLabel="desc" (change)="changework()" bindValue="work_order_no" [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB40']}}" [(ngModel)]="obj['work_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_id">
                                    </ng-select>
                                </div>
                                <div class="col-2">
                                    <h6>
                                        <!-- CB Date -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB8']}} :
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <input class="form-control" type="date" [(ngModel)]="obj['cb_date']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'EMB-EMB61']}} :</h6>
                                </div>
                                <div class="col-10">
                                    {{obj['path_desc']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2">
                                    <h6>
                                        <!-- Work/ Service Name  -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB17']}}:
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    {{obj['work_order_name']}}
                                </div>
                                <div class="col-2">
                                    <h6>
                                        <!-- Invoice No.  -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB9']}}:
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <input class="form-control" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB41']}}" type="text" [(ngModel)]="obj['invoice_no']">
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2">
                                    <h6>
                                        <!-- Select Party -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-CB18']}}:
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <ng-select [items]="AllParty" bindLabel="party_leagal_name" bindValue="rep_le_id" [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB18']}}" [(ngModel)]="obj['party_id']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="party_id">
                                    </ng-select>

                                </div>
                                <div class="col-2">
                                    <h6>
                                        <!-- Invoice Date -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB11']}} :
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <input class="form-control" type="date" [(ngModel)]="obj['invoice_date']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2">
                                    <h6>
                                        <!-- Remark -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB10']}} :
                                    </h6>
                                </div>
                                <div class="col-4 text-left">
                                    <textarea class="form-control" [(ngModel)]="obj['remark']"></textarea>
                                </div>

                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-6 text-right">
                                    <h5><b>
                                            <!-- PAYABLES -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB19']}}
                                        </b></h5>

                                </div>
                                <div class="col-6 text-right">
                                    <button class="btn btn-primary" (click)="addRow()"><i class="fa fa-plus"
                                            aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <br>
                            <div>
                                <div class="row" style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                    <div style="width: 5%;" class="text-center">

                                        <h6><b>
                                                <!-- S No. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB20']}}
                                            </b></h6>

                                    </div>
                                    <div style="width: 30%;" class="text-center">
                                        <h6><b>
                                                <!-- Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB21']}}
                                            </b></h6>
                                    </div>



                                    <div style="width: 10%;" class="text-center">
                                        <h6><b>
                                                <!-- Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB22']}}
                                            </b></h6>


                                    </div>


                                    <div style="width: 10%;" class="text-center">
                                        <h6><b>
                                                <!-- CGST Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB23']}}
                                            </b></h6>


                                    </div>
                                    <div style="width: 10%;" class="text-center">
                                        <h6><b>
                                                <!-- SGST Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB24']}}
                                            </b></h6>


                                    </div>
                                    <div style="width: 10%;" class="text-center">
                                        <h6><b>
                                                <!-- IGST Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB25']}}
                                            </b></h6>


                                    </div>

                                    <div style="width: 15%;" class="text-center">
                                        <h6><b>
                                                <!-- Total Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB26']}}
                                            </b></h6>


                                    </div>
                                    <div style="width: 10%;" class="text-center">
                                        <h6><b></b></h6>
                                    </div>
                                </div>
                                <br>
                                <div *ngFor="let ln of AllBillRow; let i = index;">
                                    <div class="row">
                                        <div class="text-center" style="width: 5%;">
                                            <h6><b> {{i+1}}.</b></h6>
                                        </div>
                                        <div style="width: 30%;">
                                            <input type="text" class="form-control" placeholder="" [(ngModel)]="ln['event_desc']">
                                        </div>

                                        <div style="width: 10%;">
                                            <input type="number" class="form-control" placeholder="" [(ngModel)]="ln['amount']" (change)="ChangeAmount(i)">
                                        </div>

                                        <div style="width: 10%;">
                                            <input type="number" class="form-control" placeholder="" [(ngModel)]="ln['cgst']" (change)="ChangeAmount(i)">
                                        </div>
                                        <div style="width: 10%;">
                                            <input type="number" class="form-control" placeholder="" [(ngModel)]="ln['sgst']" (change)="ChangeAmount(i)">
                                        </div>
                                        <div style="width: 10%;">
                                            <input type="number" class="form-control" placeholder="" [(ngModel)]="ln['igst']" (change)="ChangeAmount(i)">
                                        </div>

                                        <div style="width: 15%;">
                                            <label style="margin-left: 5%;">{{ln['total_amount']}}</label>
                                        </div>
                                        <div style="width: 10%;">
                                            <button class="btn btn-danger " (click)="deleteRow(i)"><i
                                                    class="fa fa-trash" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-6 text-right">
                                            <b>
                                                <!-- Deductions -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB27']}}:
                                            </b>
                                        </div>
                                        <div class="col-6">
                                            <button class="btn btn-primary " style="margin-left: 3%;" (click)="dedAdd(i)"><i class="fa fa-plus"
                                                    aria-hidden="true"></i></button>
                                        </div>

                                    </div><br>
                                    <div *ngFor="let ded of ln['ded']; let j = index;" style="width: 80%; margin-left: 10%;">
                                        <div class="row">
                                            <div style="width: 5%;">

                                            </div>
                                            <div class="text-center" style="width: 5%;">
                                                {{j+1}}.
                                            </div>
                                            <div style="width: 20%;">
                                                <ng-select [items]="AllDedList" placeholder="Select Deduction " bindLabel="ded_name" bindValue="deduction_id" [multiple]="false" [(ngModel)]="ded['deduction_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" (change)="ChangeDed(ded['deduction_id'],i,j)"
                                                    name="deduction_id">
                                                </ng-select>
                                            </div>
                                            <div style="width: 13%; text-align: center;" *ngIf="ded['deduction_id']!=undefined">
                                                <ng-select [items]="amountTypeArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="Select Amount Type" [(ngModel)]="ded['amt_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="amt_type">
                                                </ng-select>
                                            </div>
                                            <div style="width: 12%;" *ngIf="ded['deduction_id']!=undefined">
                                                <input type="number" class="form-control" placeholder="" [(ngModel)]="ded['ded_amount']" disabled>
                                            </div>

                                            <div style="width: 7%;">
                                                <button class="btn btn-danger " (click)="deleteDed(i,j)"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">

                                        <div class="col-12 text-center">

                                            <h6><b>
                                                    <!-- DEDUCTION AMOUNT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB28']}}:
                                                    {{ln['deduction_amount'].toFixed(2)}}
                                                </b></h6>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">

                                        <div class="col-12 text-center">

                                            <h6><b>
                                                    <!-- TOTAL PAYABLE AMOUNT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB29']}}:
                                                    {{ln['payable_amount'].toFixed(2)}}
                                                </b></h6>
                                        </div>
                                    </div>

                                    <hr>
                                </div>

                                <div class="row" style="padding-top: 12px;padding-bottom: 12px;">
                                    <div class="text-center" style="width: 5%;">
                                    </div>
                                    <div style="width: 25%;">
                                        <b>
                                            <!-- TOTAL AMOUNT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB30']}}
                                        </b>
                                    </div>
                                    <div style="width: 14%;">

                                    </div>
                                    <div style="width: 10%;" class="text-center">
                                        <b>{{totalAmount.toFixed(2)}}</b>
                                    </div>
                                    <div style="width: 10%;">

                                    </div>
                                    <div style="width: 7%;" class="text-center">
                                        <b>{{cgstAmount.toFixed(2)}}</b>

                                    </div>
                                    <div style="width: 7%;" class="text-center">
                                        <b>{{sgstAmount.toFixed(2)}}</b>
                                    </div>
                                    <div style="width: 7%;" class="text-center">
                                        <b>{{igstAmount.toFixed(2)}}</b>
                                    </div>

                                    <div style="width: 10%;" class="text-center">
                                        <b>{{net_amount.toFixed(2)}}</b>
                                    </div>

                                </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">

                                <div class="col-12 text-right">
                                    <h5><b>
                                            <!-- NET PAYABLE AMOUNT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB31']}}:
                                            {{net_amount.toFixed(2)}}
                                        </b></h5>
                                </div>

                            </div>

                            <hr>
                            <div class="row">
                                <div class="col-10 text-left">
                                    <h5><b>
                                            <!-- TOTAL DEDUCTIONS -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB32']}}
                                        </b></h5>
                                </div>
                            </div>
                            <br>
                            <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                <thead style="background-color: #1589FF; color: white;">
                                    <tr>
                                        <th>
                                            <!-- S No. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB20']}}
                                        </th>
                                        <th>
                                            <!-- Deduction Type -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB39']}}
                                        </th>
                                        <th>
                                            <!-- Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB22']}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dn of AllDedRow; let j = index;">
                                        <td>{{j+1}}</td>
                                        <td>{{dn['deduction_name']}}</td>
                                        <td> {{dn['ded_amount'].toFixed(2)}}</td>
                                    </tr>

                                </tbody>
                            </table>

                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <h6><b>
                                            <!-- TOTAL DEDUCTION AMOUNT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB33']}}:
                                            {{total_ded_amount.toFixed(2)}}
                                        </b></h6>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6"></div>
                                <div class="col-3 text-right">
                                    <h6>
                                        <!-- With Held Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB34']}} :
                                    </h6>
                                </div>
                                <div class="col-3 text-right">
                                    <input class="form-control" type="number" [(ngModel)]="withheldamt">
                                </div>
                            </div>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-6"></div>
                                <div class="col-3 text-right">
                                    <h6>
                                        <!-- NET PAYABLE AMOUNT AFTER WITH HELD  -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB35']}}:
                                    </h6>
                                </div>
                                <div class="col-3 text-right">
                                    {{(this.net_amount - this.withheldamt).toFixed(2)}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-info" (click)="check()">
                                        <!-- Check -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB36']}}
                                    </button>&nbsp;&nbsp;
                                    <button class="btn btn-primary" (click)="update()">
                                        <!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CB14']}}
                                    </button>&nbsp;&nbsp;

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr" [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark" placeholder="enter work order name" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div>
                    <table class="table table-bordered" style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="Approvedapproval()">Submit

                        </button>
                    </div>
                </div>
                <br>
                <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>