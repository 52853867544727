import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../../service/establishment.service';
import { MainService } from '../../service/main.service';
import { FORMERR } from 'dns';
import { FileUploader } from 'ng2-file-upload';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';

declare var $: any
@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {


  constructor(public mainService: MainService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private establishmentService: EstablishmentService) { }
  erpUser: any;
  b_acct_id: any;
  allComplaint: any = [];
  selectCompObj: any = {};
  enquiryObj: any = {};
  allApprovedComplaint: any = [];
  updtEnquiryObj: any = {};
  closeObj: any = {};
  chargesheetObj: any = {};
  updtEnquiryObj1: any = {};
  closeObj1: any = {};
  chargesheetObj1: any = {};
  enqArray: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns: any = ['id', 'en_type_code', 'en_desc', 'en_status', 'en_dt', 'action'];
  datasource: any;
  codeValueTechObj: any = {};
  selectedFile: any = null;
  isUpload: any;
  public imagePath: any;
  httpUrl: any;
  uploader: any;
  currDate: any;
  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.httpUrl = this.mainService.httpUrl;
    await this.getAllComplaint();
    await this.getEnquiry();
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    this.currDate = new Date().toISOString().slice(0, 10);
    this.enquiryObj['en_dt'] = this.currDate;
  }



  async getAllComplaint() {
    this.spinner.show()
    var resp = await this.establishmentService.getAllComplaints(this.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allComplaint = resp.data;
      this.allApprovedComplaint = [];
      for (let i = 0; i < this.allComplaint.length; i++) {
        if (this.allComplaint[i]['complaint_status'] == 'APPROVED') {
          this.allApprovedComplaint.push(this.allComplaint[i]);
        }
      }
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee all complaint list ', 'error')
      // this.snackBar.open("Error while getting employee all complaint list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async getEnquiry() {

    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    //obj['complaint_id'] = this.selectCompObj['complaint_id'];
    var resp = await this.establishmentService.getEnquiryForComplaint(obj);
    if (resp['error'] == false) {
      this.enqArray = [];
      this.spinner.hide()
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['en_status'] == 'IN PROCESS') {
          this.enqArray.push(resp.data[i]['complaint_id'])
        }
      }
      if (resp.data.length == 0) {
        swal.fire("Info", "No Data In The List !", 'info');
      }
      this.datasource = new MatTableDataSource(resp.data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting Enquiry For Complaint list', 'error')
      // this.snackBar.open("Error while getting Enquiry For Complaint list", 'Error', {
      //   duration: 5000
      // });
    }
  }



  checkComplaint() {
    for (let i = 0; i < this.enqArray.length; i++) {
      if (this.enqArray[i] == this.enquiryObj['complaint_id']) {
        this.enquiryObj['complaint_id'] = '';
        Swal.fire("Error", 'Already In Process Enquiry', 'error');
        return
      }
    }
  }
  async submitEnquiry() {
    if (this.enquiryObj['complaint_id'] == null || this.enquiryObj['complaint_id'] == undefined || this.enquiryObj['complaint_id'] == '') {
      Swal.fire("Error", 'Complaint ID can not be null', 'error')
      return
    }
    if (this.enquiryObj['en_type_code'] == null || this.enquiryObj['en_type_code'] == undefined || this.enquiryObj['en_type_code'] == '') {
      Swal.fire("Error", 'Enquiry Type  can not be null', 'error')
      return
    }
    if (this.enquiryObj['en_desc'] == null || this.enquiryObj['en_desc'] == undefined || this.enquiryObj['en_desc'] == '') {
      Swal.fire("Error", 'Enquiry Description can not be null', 'error')
      return
    }
    if (this.enquiryObj['en_dt'] == null || this.enquiryObj['en_dt'] == undefined || this.enquiryObj['en_dt'] == '') {
      Swal.fire("Error", 'Enquiry Date can not be null', 'error')
      return
    }
    if (this.enquiryObj['en_officer_name'] == null || this.enquiryObj['en_officer_name'] == undefined || this.enquiryObj['en_officer_name'] == '') {
      Swal.fire("Error", 'Enquiry Officer Name can not be null', 'error')
      return
    }
    this.enquiryObj['b_acct_id'] = this.b_acct_id;
    this.enquiryObj['create_user_id'] = this.erpUser.user_id;
    this.enquiryObj['en_status'] = 'IN PROCESS';

    this.spinner.show();
    var resp = await this.establishmentService.setupEnquiry(this.enquiryObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.enquiryObj = {};
      await this.getEnquiry();
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      swal.fire('Success', 'Enquiry Added Successfully!', 'success')
      // this.snackBar.open("Enquiry Added Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while Adding Enquiry  Of Complaint', 'error')
      // this.snackBar.open("Error while Adding Enquiry  Of Complaint", 'Error', {
      //   duration: 5000
      // });
    }
  }

  update(element: any) {
    this.updtEnquiryObj = Object.assign({}, element);
    this.updtEnquiryObj['update_dt'] = this.currDate;
    $('.nav-tabs a[href="#tab-3"]').tab('show');
  }
  async updateEnquiry() {
    if (this.updtEnquiryObj['en_progress_note'] == null || this.updtEnquiryObj['en_progress_note'] == undefined || this.updtEnquiryObj['en_progress_note'] == '') {
      Swal.fire("Error", 'Enquiry Progressing Note can not be null', 'error')
      return
    }
    if (this.updtEnquiryObj['update_dt'] == null || this.updtEnquiryObj['update_dt'] == undefined || this.updtEnquiryObj['update_dt'] == '') {
      Swal.fire("Error", 'Update Date can not be null', 'error')
      return
    }
    this.updtEnquiryObj1['b_acct_id'] = this.b_acct_id;
    this.updtEnquiryObj1['update_user_id'] = this.erpUser.user_id;
    this.updtEnquiryObj1['update_dt'] = this.updtEnquiryObj['update_dt'];
    this.updtEnquiryObj1['enquiry_id'] = this.updtEnquiryObj['id'];
    this.updtEnquiryObj1['en_progress_note'] = this.updtEnquiryObj['en_progress_note'];

    var resp = await this.establishmentService.updateEnquiry(this.updtEnquiryObj1);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.updtEnquiryObj = {};
      await this.getEnquiry();
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      swal.fire('Success', 'Enquiry Updated Successfully!', 'success')
      // this.snackBar.open("Enquiry Updated Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while Adding Complaint Of Employee', 'error')
      // this.snackBar.open("Error while Adding Complaint  Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }

  chargesheet(element: any) {
    this.chargesheetObj = Object.assign({}, element);
    this.chargesheetObj['chargesheet_dt'] = this.currDate;
    $("#uploadFile").val("")
    $('.nav-tabs a[href="#tab-4"]').tab('show');
  }
  refrace(a: any) {
    if (a == 1) {
      this.chargesheetObj = Object.assign({}, '');
      $("#uploadFile").val("")
    }
    else if (a == 0) {
      this.updtEnquiryObj = Object.assign({}, '');

    }
    else if (a == 2) {
      this.closeObj = Object.assign({}, '');
    }
  }

  // Avinash
  async aviMatch(t: any) {

    let ext = t.split(".");

    for (let i = 0; i < ext.length; i++) {
      if (ext[i] == "exe" || ext[i] == "mp4" || ext[i] == "MKV" || ext[i] == "AVI" || ext[i] == "MOV" || ext[i] == 'apk' || ext[i] == 'xlsx') {
        return 0;
      }

    }
    return
  }



  async onFileUpload(event: any, files: any) {
    this.selectedFile = <File>event.target.files[0];

    //----------Avniash Mishra----------
    for (let i = 0; i < event.target.files.length; i++) {
      let text = event.target.files[i]["name"]
      console.log("this is doc name length--", text.length);
      let res = await this.aviMatch(text);

      if (res == 0) {
        $('#uploadFile').val('');
        swal.fire('Info', `You Enter the .exe File / Video File / Apk / Excel File , Remove This First..!`, 'info');
        return;
      }
    }

    //---------------
    if (files.length === 0) {
      return;
    }

    // const mimeType = files[0].type;
    // if (mimeType.match(/image\/*/) == null) {
    //   return;
    // }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };

  }
  async submitChargesheet() {
    if (this.selectedFile == undefined || this.selectedFile == null) {
      swal.fire('info', "Please upload document ", 'warning');
      return;
    }
    if (this.chargesheetObj['chargesheet_dt'] == null || this.chargesheetObj['chargesheet_dt'] == undefined || this.chargesheetObj['chargesheet_dt'] == '') {
      Swal.fire("Error", 'Chargesheet Date can not be null', 'error')
      return
    }
    if (this.chargesheetObj['chargesheet_summary'] == null || this.chargesheetObj['chargesheet_summary'] == undefined || this.chargesheetObj['chargesheet_summary'] == '') {
      Swal.fire("Error", 'Chargesheet Summary can not be null', 'error')
      return
    }
    if (this.chargesheetObj['en_result'] == null || this.chargesheetObj['en_result'] == undefined || this.chargesheetObj['en_result'] == '') {
      Swal.fire("Error", 'Enquiry Result can not be null', 'error')
      return
    }

    this.chargesheetObj1['b_acct_id'] = this.b_acct_id;
    this.chargesheetObj1['update_user_id'] = this.erpUser.user_id;
    this.chargesheetObj1['enquiry_id'] = this.chargesheetObj['id'];
    this.chargesheetObj1['chargesheet_dt'] = this.chargesheetObj['chargesheet_dt'];
    this.chargesheetObj1['chargesheet_summary'] = this.chargesheetObj['chargesheet_summary'];
    this.chargesheetObj1['enquiry_result'] = this.chargesheetObj['en_result'];
    this.chargesheetObj1['chargesheet_status'] = 'ACTIVE';
    for (let i = 0; i < this.allComplaint.length; i++) {
      if (this.chargesheetObj['complaint_id'] == this.allComplaint[i]['id']) {
        this.chargesheetObj1['emp_id'] = this.allComplaint[i]['emp_id'];
        break;
      }
    }

    this.spinner.show();
    if (this.uploader.queue.length > 0) {
      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      this.chargesheetObj1['document_name'] = this.uploader.queue[0].some.name;
      this.chargesheetObj1['document_type_code'] = 'CHARGESHEET DOC';
      const params = JSON.stringify(this.chargesheetObj1);
      this.uploader.queue[0].url = this.httpUrl + '/hr/establishment_info/enquiry/submitChargesheet' + params;
      this.uploader.queue[0].upload();

      this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        if (!response.error) {
          this.uploader.queue = [];
          await this.getEnquiry();
          this.spinner.hide();
          this.chargesheetObj = {};
          swal.fire('Success', 'Submit Chargesheet Successfully', 'success')
        } else {
          this.spinner.hide()
          swal.fire('Error', 'Error while Submit Chargesheet', 'error');
        }
      };
    }
    else {
      var resp = await this.establishmentService.submitChargesheet(this.chargesheetObj1);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getEnquiry();
        $('.nav-tabs a[href="#tab-1"]').tab('show');
        // this.snackBar.open("Submit Chargesheet Successfully!", 'Success', {
        //   duration: 5000
        // });
        this.refrace(1);
        swal.fire("Success", "Submit Chargesheet Successfully!", 'success')

      } else {
        this.spinner.hide();
        swal.fire('Error', 'Error while Submit Chargesheet', 'error')
        // this.snackBar.open("Error while Submit Chargesheet", 'Error', {
        //   duration: 5000
        // });
      }
    }
  }

  close(element: any) {
    this.closeObj = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-5"]').tab('show');
  }
  async closeEnquiry() {
    if (this.closeObj['en_closing_note'] == null || this.closeObj['en_closing_note'] == undefined || this.closeObj['en_closing_note'] == '') {
      Swal.fire("Error", 'Closing Note can not be null', 'error')
      return
    }
    this.closeObj1['b_acct_id'] = this.b_acct_id;
    this.closeObj1['update_user_id'] = this.erpUser.user_id;
    this.closeObj1['update_dt'] = this.closeObj['update_dt'];
    this.closeObj1['enquiry_id'] = this.closeObj['id'];
    this.closeObj1['en_closing_note'] = this.closeObj['en_closing_note'];
    this.closeObj1['complaint_id'] = this.closeObj['complaint_id'];
    this.closeObj1['en_status'] = 'CLOSED';

    var resp = await this.establishmentService.closeEnquiry(this.closeObj1);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.closeObj = {};
      await this.getEnquiry();
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      swal.fire('Success', 'Enquiry Closed Successfully!', 'success')
      // this.snackBar.open("Enquiry Closed Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while Closing Complaint Of Employee', 'error')
      // this.snackBar.open("Error while Closing Complaint  Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }



  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }

}
