import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MainService as md_mainService } from '../../../md/service/main.service';
import { SettingService } from '../../service/setting.service'
import { ComplainServiceService } from '../../service/complain-service.service';
import { MainService as mainserviceEmb } from '../../../emb/service/main.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUploader } from 'ng2-file-upload';
import swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


declare var $: any


@Component({
  selector: 'app-court-order',
  templateUrl: './court-order.component.html',
  styleUrls: ['./court-order.component.css']
})
export class CourtOrderComponent implements OnInit {

  constructor(private settingService: SettingService, private spinner: NgxSpinnerService,
     private sanitizer: DomSanitizer, private mainService: md_mainService,
     private complainServiceService:ComplainServiceService,
      public mainserviceEmb: mainserviceEmb, public toastr: ToastrService,
     ) { }
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  ebillUser:any;
  b_acct_id:any;
  current_user:any;
  uploader1:any;
  httpUrl:any
  user_id:any;
  identity:any;
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.current_user = this.ebillUser.party_name;
this.user_id = this.ebillUser.user_id;

    this.uploader1 = new FileUploader({ url: this.mainService.httpUrl, itemAlias: 'image' });
    this.uploader1.onAfterAddingFile = (file:any) => { file.withCredentials = false; };

    await this.getCodeValue();
    await this.getorderData()
    await this.getAllEmployees();
  }



  displayedColumns = ["Sr.No", "case_id_f", "co_ord_id", "co_ord_date", "co_ord_type", "compliner_name", "order_status", "action"];
  supportDisplayCol = ['dep_name', 'branch_office', 'officer_name', 'letter_sub', 'signed_by', 'date', 'action']
  dataSource:any;
  supportDatsource: any = [{ dep_name: "Account", branch_office: "Hazratgunj", officer_name: 'Inspector', letter_sub: 'Subject', signed_by: 'JE Mishra' }]


  orderListObj :any= new Object(); // firs
  seleOrderStatus:any;
  orderData:any;
  forwardData:any = new Object()
  repSubData :any= new Object();
  all_court_data:any = []
  depdata :any= new Object();

  // flag variable Start
  list_fg: boolean = true;
  upload: boolean = false;
  update: boolean = false;
  order_exe: boolean = false;
  upload_fg: boolean = true;
  list_head: boolean = true;
  act_list = 'active';
  act_upload = 'deactive';
  // flag variable End

  // List functions Start
  list() {
    this.list_fg = true;
    this.upload = false;
    this.upload_fg = true;
    this.list_head = true;
    this.update = false;
    this.order_exe = false;
    this.act_list = 'active';
    this.act_upload = 'deactive';
  }

  uploadOrder() {
    this.list_fg = false;
    this.upload = true;
    this.upload_fg = true;
    this.list_head = true;

    this.update = false;
    this.order_exe = false;
    this.act_list = 'deactive';
    this.act_list = 'active';
    ;
  }


  recoveryOrder() {
    this.list_fg = false;
    this.upload = false;

    this.update = true;
    this.order_exe = false;
    this.upload_fg = false;
    this.list_head = false;
  }

  codeValueTechObj = {}
  codeValueObj = {}
  codeValueShowObj:any = {}
  async getCodeValue() {
    this.spinner.show()
    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj:any = {}
    var codeValueShowTempObj :any= {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.codeValueTechObj = this.codeValueObj;
      console.log(this.codeValueTechObj, 'uuuu')
      this.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }
  }

  async getCourtData() {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['order_status'] = this.seleOrderStatus;
    this.spinner.show();
    let resp = await this.settingService.getCourtData(JSON.stringify(obj));

    if (resp['error'] == false) {

      console.log("court data : ", resp['data'])
      this.all_court_data = resp['data']
      this.dataSource = new MatTableDataSource(resp['data'])
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
      this.spinner.hide();

    } else {
      this.spinner.hide();
      swal.fire('ERROR', 'Error Occurred While Getting Court data', 'error');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async getorderData() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    this.spinner.show();
    let resp = await this.settingService.getOrderData(JSON.stringify(obj));
    if (resp['error'] == false) {
      resp['data'].map((x:any)=>{
        x['order_st']=x['order_status'].split('_').join(' ')
      })
      this.orderData = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire('ERROR', 'Error Occurred While Getting Department', 'error');
    }
  }

  allSearchableEmp = []
  userObj:any={}
  async getAllEmployees() {
    console.log('all emp')
    this.spinner.show()
    var arr:any = []
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'ICMS'
    var resp = await this.mainService.getuserformodulerolemapping(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      this.userObj ={}
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]

        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
      }
      this.allSearchableEmp = arr
      console.log(this.allSearchableEmp)
    } else {
      this.spinner.hide()
    }
  }

  veiw_details:any = new Object();
  execution_flag:boolean=false;
  execution_data=[]
 async viewDetails(element:any) {
    $('#orderDetails').modal('show');
    this.veiw_details = Object.assign({},element) ;
    this.veiw_details['order_status'] = this.veiw_details['order_status'].split('_').join(' ');
   this.execution_data=[]
    this.before_exe=[]
    this.after_exe=[]
    console.log(element)
    this.execution_data=[]
    this.before_exe=[]
    this.after_exe=[]
  if(element['order_status'] === 'EXECUTION_COMPLETED'){
      await  this.getExecutionData(element);
  }

  }
before_exe:any=[]
after_exe:any=[]
 async getExecutionData(view_dtl:any){
  let obj:any = new Object();

  obj['b_acct_id'] = this.b_acct_id;
  obj['co_ord_id'] = view_dtl['co_ord_id']
  obj['order_status'] = view_dtl['order_status']
  this.spinner.show();
  let resp = await this.settingService.getExecutionData(JSON.stringify(obj));
  if(resp['error'] == false){
    this.execution_flag=true;
    this.execution_data= resp['data']


    this.before_exe=[]
    this.after_exe=[]

this.spinner.hide()
this.execution_data.map(x=>{
  if(x['doc_type']=="BEFORE_EXECUTION"){
    this.before_exe.push(x);
  }if(x['doc_type']=="AFTER_EXECUTION"){
    this.after_exe.push(x);
  }
})

    console.log(this.execution_data)
  }else{
    this.spinner.hide()
    console.log("Error while getting execution data");
  }
 }

 reOpenMOdel(){
  $('#orderDetails').modal('show');
 }
  //Forword screen start

  forwordFile(element:any) {
    this.forwardData={}
    this.forwardData = element;
    this.forwardData['user_id'] =0;
    // console.log(element)
    $('#forword').modal('show');
    this.condtion = "BY_ZONE"
    this.getuserinfobyzone();
  }

  same = false;
  condtion = {}
  Inzone:boolean = true;
  OuterZone:boolean = false;
  userbyzone=[]
  userbynotzone=[]
  // selected_user=[]
  sameAddress() {
    if (!this.same) {
      this.Inzone = false;
      this.OuterZone = true;
      this.same = true;
      this.condtion = "NOT_BY_ZONE"

      this.getuserinfobyzone();
    } else {
      this.Inzone = true;
      this.OuterZone = false;
      this.same = false;
      this.condtion = "BY_ZONE"

      this.getuserinfobyzone();

    }

  }

  async getuserinfobyzone() {
    let arr = []
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['condition'] = this.condtion;
    obj['node_cd'] = this.forwardData['zone_cd']
    var resp:any = await this.complainServiceService.getuserinfoByzone(JSON.stringify(obj));

    if (resp['error'] == false) {
      arr = resp['data'];

      resp['data'].map((x:any , i:any, data:any)=>{
        if(x['user_id'] == this.user_id){
        data.splice(i,i);
        }else{
               x['desc'] = x['user_id'] + ' - ' + x['emp_name'] +' - '+ x['emp_phone_no'] + ' '+ x['zone_name']
          }
        })

  if(this.condtion == "BY_ZONE"){
    this.userbyzone = resp['data'];
    return
  }else{
    this.userbynotzone = resp['data'];
  }
      // console.log(this.userbyzone);
    }  else{
      swal.fire('error', 'server side error', 'error');
    }


  }


  async submitForword() {
    if(!this.forwardData['co_ord_id'] || !this.forwardData['user_id']){
     swal.fire('ERROR','Plase Select Mandatory Field','error');
      return;
    }
    this.spinner.show();
    this.forwardData['b_acct_id'] = this.b_acct_id;
    this.forwardData['life_cycle_status'] = "ASSIGNED"
    this.forwardData['type'] = "COURT-ORDER"
    let resp = await this.settingService.forwordFile(this.forwardData);
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getCourtData();
      swal.fire('SUCCESS', 'Your File Will Forwarded ', 'success');
      $('#forword').modal('hide');
    } else {
      this.spinner.hide()
      swal.fire('ERROR', 'Error Occurred While Forrwording File', 'error');
    }




  }


  async downloadDocument(data:any) {
    this.spinner.show();
    let obj = Object.assign({}, data);
    obj['b_acct_id'] = this.b_acct_id
    obj["case_id"] = data.case_id_f;
    obj["doc_id"] = data.doc_id;
    obj['complain_id'] = data.complain_id
    obj["document_name"] = "Uploadcourtorder"
    let resp:any = await this.settingService.getAttachedDocumnet(obj);
    if(resp['error'] == false){
    obj["document_type_code"] = resp['data'][0]['document_type_code'];
    obj["doc_id"] = resp['data'][0]['doc_id'];
    const res = await this.settingService.downloadDocument(obj);
    if (res) {
      let ext = obj['document_type_code'].split('.')
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        // let fileURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        await this.downloadDoc(obj,unsafeImageUrl);
        this.spinner.hide()
      } else if (ext[1].toLowerCase() == 'xlsx') {
        let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let fileURL = URL.createObjectURL(file);
        await this.downloadDoc(obj, fileURL)
        this.spinner.hide()
      } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {
        let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        let fileURL = URL.createObjectURL(file);
        await this.downloadDoc(obj, fileURL)
        this.spinner.hide()
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        await this.downloadDoc(obj, fileURL)
        this.spinner.hide()
      }
    } else {
      swal.fire('Error','Error while downloading document','error');
      this.spinner.hide()
    }
  }else{
    this.spinner.hide();
    swal.fire('Error','Error while downloading document','error');
  }
  }

  async downloadDoc(data:any, fileURL:any) {
    //following code for download document
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = fileURL;
    a.download = data.document_type_code
    a.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      a.remove();
    }, 100);
  }

  executionImage:any;

async downloadexecutionImage(item:any){
  this.spinner.show();
  this.executionImage=''
  let obj = Object.assign({}, item);
  obj['b_acct_id'] = this.b_acct_id
  let resp = await this.settingService.downloadexecutionImage(obj);
  if(resp){
    $('#preview').modal('show');
    const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
    this.executionImage = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);

this.spinner.hide()
  }else{
    swal.fire('ERROR',"Error While Getting Image",'error');
  }
}

clearImageData(){
  this.img_before_heading=''
  this.img_after_heading=''
  this.doc_before_exe=[]
  this.doc_after_exe=[]
  this.imgURL=''
}

doc_before_exe :any=[]
doc_after_exe:any=[]
img_before_heading = "";
img_after_heading = '';
async defendentDocList(element:any){
  this.spinner.show();
  let obj :any= new Object();
  obj['b_acct_id'] = this.b_acct_id;
  obj['complain_id'] = element.complain_id;
  obj['execution_id'] = element.execution_id;
  obj['order_id'] = element.co_ord_id;
  obj['case_id'] = element.case_id_f;
  let resp :any= await this.settingService.defendentDocList(obj);
  console.log(resp['data'])
  if(element.co_ord_type == 'SelfDemolition'){
      this.img_before_heading = "DEFENDENT DOCUMENT";
      this.img_after_heading = 'EMPLOYEE DOCUMENT';
 }
  this.doc_after_exe=[]
  this.doc_before_exe=[]

  if(resp['error'] == false){
       resp['data'].map((x:any)=>{
            if(x['doc_type'] == 'AFTER_EXECUTION'){
            this.doc_after_exe.push(x);
            this.img_after_heading = 'AFTER EXECUTION';
       }
     if(x['doc_type'] == 'BEFORE_EXECUTION' || x['doc_type'] == 'DEFENDENT_REPORT' ){
      this.img_before_heading = "BEFORE EXECUTION";
       this.doc_before_exe.push(x);
      }
   })
  this.spinner.hide()
   $('#defendent_photo').modal('show');
  }else{
    this.spinner.hide()
    console.log("Error while getting Defendent document");
  }
 }


 before_img:boolean=false;
 after_img:boolean = false;


 imgURL:any;
 async viewDefendentImage(item :any, type:any){
  this.spinner.show();
  let obj = Object.assign({}, item);
  obj['b_acct_id'] = this.b_acct_id
  console.log(obj)
  let resp = await this.settingService.viewDefendentImage(obj);
  if(resp){
    const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
    if(type == 'before'){
      this.before_img = true;
      this.after_img =false;
    }
if(type == 'after'){

  this.after_img =true;
  this.before_img = false;
}

    this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    this.spinner.hide()
  }else{
    swal.fire('ERROR',"Error While Getting Defendent Image",'error');
  }
}

closeDefendentImageFlage(){
  this.before_img = false
  this.after_img = false
}








async viewStatus(element:any) {
  this.selectedEle = Object.assign({}, element);
  var obj :any= new Object();
  obj['doc_local_no'] = element.upload_id;
  obj['b_acct_id'] = this.ebillUser.b_acct_id;
  obj['doc_type'] = 'ORDER EXECUTION REPORT';
  obj['module_cd'] = 'ICMS'
  var resp = await this.mainserviceEmb.getWorkflowlog(JSON.stringify(obj));

  if (resp['error'] == false) {

    // this.salBill_apprdata = Object.assign({}, element)
    // this.salBill_apprdata['level'] = 1
    // this.salBill_apprdata['branch'] = 1
    // this.salBill_apprdata['doc_local_no'] = element['upload_id']
    // this.salBill_apprdata['doc_type'] = 'INSPECTION REPORT'//this.salBill_apprdata['id']
    // this.salBill_apprdata['node_cd'] = element['project_cd']
    // this.salBill_apprdata['module_cd'] = 'ICMS';
    await this.getWorkflowloguser(element.zone_cd)
    for (let i = 0; i < resp.data.length; i++) {
      if (resp.data[i]['user_id'] == -1) {
        resp.data[i]['name'] = "Contractor"
        resp.data[i]['role_cd'] = "Contractor"
        continue;
      }
      for (let j = 0; j < this.workflowuser.length; j++) {
        if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
          resp.data[i]['name'] = this.workflowuser[j]['emp_name']
          resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
        }
      }
      if (resp.data[i]['name'] == undefined) {
        resp.data[i]['name'] = ''

      }
    }
    this.statusArr1 = resp.data
    console.log(this.statusArr1);
    $('#workflowStatus').modal('show');
  } else {
    this.toastr.error(resp['data'])
  }
}

workflowuser = []
rolecdtolevelobj :any= {}
async getWorkflowloguser(zone_cd:any) {
  var obj:any = new Object()
  obj['b_acct_id'] = this.b_acct_id
  obj['module_cd'] = 'ICMS'
  obj['status'] = 'ACTIVE'
  obj['node_cd'] = zone_cd
  var resp = await this.mainserviceEmb.getUsersForModuleWorkflow(JSON.stringify(obj))
  if (resp['error'] == false) {
    this.spinner.hide();
    console.log(resp['data'])
    this.workflowuser = resp['data']

  } else {
    this.spinner.hide();
    this.toastr.error(resp['data'])
  }
}
statusArr1:any = [];
selectedEle:any;
userRoles = []

}




/**********************************************************Unused Code******************************************************** */
  //Forword screen end

  //report submission screen start
  // saveReportSub() {
  //   console.log(this.repSubData);
  //   swal.fire("INFO", 'Your data will Save soon', 'info');
  //   this.repSubData = {}
  //   this.list()
  // }

  // async submitReportSub() {
  //   this.repSubData['b_acct_id'] = this.b_acct_id;
  //   this.repSubData['doc_name'] = this.doc_name;
  //   this.repSubData['doc_local_name'] = this.doc_local_name;
  //   this.repSubData['create_user_id'] = this.ebillUser.user_id;
  //   this.repSubData['update_user_id'] = this.ebillUser.user_id;

  //   console.log("Form Data : ", this.repSubData)
  //   this.formData = new FormData();
  //   for (let i = 0; i < this.selectedFile.length; i++) {
  //     this.formData.append("image", this.selectedFile[i]);
  //   }

  //   this.formData.append("data", JSON.stringify(this.repSubData))
  //   let resp = await this.settingService.submitReportSub(this.formData);
  //   if (resp['error'] == false) {
  //     swal.fire("Success", "Report Submmision successfull", 'success');
  //     this.repSubData = {}
  //     this.doc_name = []
  //     this.selectedFile = []
  //     this.imgURLBefore = []
  //     this.imgURLAfter = []
  //     this.formData = new FormData();
  //     this.list();
  //   } else {
  //     swal.fire('ERROR', 'Error Occurred While Report Submmision', 'error');
  //   }

  // }

  // selectedFile: any = [];
  // imgURLBefore = [];
  // imgURLAfter = [];
  // doc_name = []
  // doc_local_name = []
  // formData = new FormData();
  // async onFileUpload(event: any, files, identity) {
  //   if (event.target.files && event.target.files[0]) {
  //     for (let i = 0; i < event.target.files.length; i++) {
  //       this.selectedFile.push(event.target.files[i]);
  //       this.doc_name.push(identity + "_" + event.target.files[i]['name']);
  //       this.doc_local_name.push(event.target.files[i]['name'])
  //       let reader = new FileReader();
  //       reader.onload = (event: any) => {
  //         // console.log(event.target.result);
  //         if (identity == 'before') {
  //           this.imgURLBefore.push(event.target.result);
  //         }
  //         if (identity == 'after') {
  //           this.imgURLAfter.push(event.target.result);
  //         }
  //       }
  //       reader.readAsDataURL(event.target.files[i]);
  //     }

  //   }
  //   console.log(this.doc_name)
  //   console.log("selectedFile : ", this.selectedFile)

  // }

  // async validateSize(input) {
  //   const fileSize = input.target.files[0].size / 1024;
  //   let numb: any = fileSize.toFixed(2);
  //   if (numb > 1024 * 10) {
  //     // alert('to big, maximum is 2MiB. You file size is: ' + numb + ' KB');
  //     swal.fire('Information', 'to big, maximum is 10MB. You file size is: ' + numb + ' KB', 'info')
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  // identity;
  // viewPhoto(identity) {
  //   this.identity = identity;
  //   $('#preview').modal('show');
  // }

  // async cancleSubReport() {
  //   this.repSubData = {}
  //   this.doc_name = []
  //   this.selectedFile = []
  //   this.imgURLBefore = []
  //   this.imgURLAfter = []

  //   this.formData = new FormData();
  //   this.list();
  // }
  //report submission screen end



  // download report from view details screen
  // downloadReport(veiw_details) {

  //   var txt = (" ORDER REPORT ");
  //   var dd = {
  //     pageSize: 'A3',
  //     header: function (currentPage, pageCount) {
  //       var arr = []
  //       var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
  //       arr.push(obj);
  //       return arr;
  //     },

  //     pageOrientation: 'portrait',
  //     pageMargins: [40, 60, 40, 60],
  //     content: [

  //     ]
  //   };

  //   var tbl = {

  //     fontSize: 10,
  //     table: {
  //       headerRows: 0,
  //       widths: ['*', '*'],
  //       body: [
  //       ]
  //     },
  //     layout: 'noBorders'
  //   };

  //   var tbl1 = {

  //     fontSize: 10,
  //     table: {
  //       headerRows: 0,
  //       widths: ['*', '*'],
  //       body: [
  //       ]
  //     },
  //     layout: 'noBorders'
  //   };

  //   var tbl2 = {

  //     fontSize: 10,
  //     table: {
  //       headerRows: 0,
  //       widths: ['*', '*'],
  //       body: [
  //       ]
  //     },
  //     layout: 'noBorders'
  //   };
  //   dd.content.push({ text: " ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });
  //   dd.content.push({ text: "ORDER DETAILS ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });
  //   dd.content.push({ text: " ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });

  //   let arr = [];

  //   arr.push({ text: "Order ID  " });
  //   arr.push(veiw_details['co_ord_id']);
  //   tbl.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Order Date" });
  //   arr.push(veiw_details['co_ord_date']);
  //   tbl.table.body.push(arr);


  //   arr = [];
  //   arr.push({ text: "Order Type" });
  //   arr.push(veiw_details['co_ord_type']);
  //   tbl.table.body.push(arr);

  //   // arr = [];
  //   // arr.push({ text: "Type of Construction" });
  //   // arr.push(veiw_details['const_type']);
  //   // tbl.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Compilation Time Limit" });
  //   arr.push(veiw_details['comp_time_limit']);
  //   tbl.table.body.push(arr);


  //   arr = [];
  //   arr.push({ text: "Compilation Day Limit" });
  //   arr.push(veiw_details['comp_day_limit']);
  //   tbl.table.body.push(arr);


  //   dd.content.push(tbl);
  //   dd.content.push({ text: " ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });
  //   dd.content.push({ text: "Address Of Property -  ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });
  //   dd.content.push({ text: " ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });




  //   arr = [];
  //   arr.push({ text: "Property Address" });
  //   arr.push(veiw_details['property_address']);
  //   tbl1.table.body.push(arr);

  //   // arr = [];
  //   // arr.push({ text: "Mohalla" });
  //   // arr.push(veiw_details['property_address']);
  //   // tbl1.table.body.push(arr);

  //   // arr = [];
  //   // arr.push({ text: "Area" });
  //   // arr.push(veiw_details['property_address']);
  //   // tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Zone/Division" });
  //   arr.push(veiw_details['zone_cd']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "District" });
  //   arr.push(veiw_details['dist']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "State" });
  //   arr.push(veiw_details['state']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Country" });
  //   arr.push(veiw_details['country']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Landmark" });
  //   arr.push(veiw_details['landmark']);
  //   tbl1.table.body.push(arr);


  //   dd.content.push(tbl1);
  //   dd.content.push({ text: " ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });

  //   dd.content.push({ text: "Opponent Party Details -  ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });
  //   dd.content.push({ text: " ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });


  //   arr = [];
  //   arr.push({ text: "Name" });
  //   arr.push(veiw_details['compliner_name']);
  //   tbl2.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Phone/Mobile No." });
  //   arr.push(veiw_details['compliner_phone_no']);
  //   tbl2.table.body.push(arr);

  //   // arr = [];
  //   // arr.push({ text: "Email" });
  //   // arr.push(veiw_details['compliner_email']);
  //   // tbl2.table.body.push(arr);

  //   // arr = [];
  //   // arr.push({ text: "Address" });
  //   // arr.push(veiw_details['property_address']);
  //   // tbl2.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Order Status" });
  //   arr.push({text:veiw_details['order_status'] , bold:true});
  //   tbl2.table.body.push(arr);

  //   dd.content.push(tbl2)
  //   pdfMake.createPdf(dd).download('Report.pdf');
  //   // swal.fire("INFO", "PDF will download soon", 'info')

  // }


  // imgURL_Profile
  // profileImageUrl


  // orderExeFg(element) {
  //   this.list_fg = false;
  //   this.upload = false;

  //   this.update = false;
  //   this.order_exe = true;
  //   this.upload_fg = false;
  //   this.list_head = false;
  //   this.repSubData = element;
  //   this.repSubData['sub_by'] = this.ebillUser.party_name;
  //   this.repSubData['sub_user_id'] = this.ebillUser.user_id;
  // }
  // List functions End

  // attachedDocCoulmns = ["Sr.No", "doc_id", "case_id", "document_type_code", "action"];
  // attachedDocDatasource
  // async getAttachedDocumnet(element) {

  //   console.log('download Doc element : ', element)
  //   let ob = new Object();
  //   ob['b_acct_id'] = this.b_acct_id;
  //   ob['case_id'] = element.case_id_f;
  //   ob["document_name"] = "Uploadcourtorder"
  //   let resp = await this.settingService.getAttachedDocumnet(ob);
  //   if (resp['error'] == false) {
  //     this.attachedDocDatasource = resp['data'];
  //     $('#attachedDoc').modal('show');
  //     console.log("Atached Doc : ", this.attachedDocDatasource)
  //   } else {
  //     swal.fire('ERROR', 'Error Occurred While Getting Department', 'error');
  //   }
  // }

