import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VmsLayoutComponent } from './vms-layout.component';
import { VmsHeaderComponent } from './vms-header/vms-header.component';
import { VmsFooterComponent } from './vms-footer/vms-footer.component';
import { VmsSidebarComponent } from './vms-sidebar/vms-sidebar.component';
// import { AgmCoreModule } from '@agm/core';
import { SharedModule } from '../../shared/shared.module';
// import { ChartsModule } from 'ng2-charts';
import { NgChartsModule } from 'ng2-charts';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { ReactiveFormsModule } from '@angular/forms';
import { VMSLayoutRoutingModule } from './vms-routing.module';
import { RouterModule } from '@angular/router';
import { VmsDashboardComponent } from '../vms-dashboard/vms-dashboard.component';
import { VendorComponent } from '../vendor/vendor.component';
import { VmsAdministrationComponent } from '../vms-administration/vms-administration.component';
import { DataAssignmentComponent } from '../vms-administration/data-assignment/data-assignment.component';
import { VmsRoleComponent } from '../vms-administration/vms-role/vms-role.component';
import { VmsUserComponent } from '../vms-administration/vms-user/vms-user.component';
import { VmsSettingComponent } from '../vms-setting/vms-setting.component';
import { CodeValueComponent } from '../vms-setting/code-value/code-value.component';
import { FieldComponent } from '../vms-setting/field/field.component';
import { HierarchyComponent } from '../vms-setting/hierarchy/hierarchy.component';
import { WorkflowComponent } from '../vms-setting/workflow/workflow.component';
import { WorkComponent } from '../vms-administration/work/work.component';
import { FAQComponent } from '../vms-administration/faq/faq.component';

@NgModule({





  declarations: [
    VmsLayoutComponent,
    VmsHeaderComponent,
    VmsFooterComponent,
    VmsSidebarComponent,
    VmsDashboardComponent,
    VendorComponent,
    VmsAdministrationComponent,
    DataAssignmentComponent,
    VmsRoleComponent,
    VmsUserComponent,
    VmsSettingComponent,
    CodeValueComponent,
    FieldComponent,
    HierarchyComponent,
    WorkflowComponent,
    WorkComponent,
    FAQComponent

  ],

  exports: [
    VmsLayoutComponent,
    VmsHeaderComponent,
    VmsFooterComponent,
    VmsSidebarComponent,

  ],


  imports: [
    CommonModule,
    SharedModule,
    CommonModule,
    NgSelectModule,
    NgChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    MatSlideToggleModule,
    FormsModule,

    // NgtreegridModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDialogModule,
    ReactiveFormsModule,
    // MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    // MatNativeDateModule,
    MatPaginatorModule,
    // MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    // NgApexchartsModule,
    MatTabsModule,
    RouterModule,
    VMSLayoutRoutingModule,
    // NgxEchartsModule,
    // NgxQRCodeModule

    // AngularEditorModule,
    // RichTextEditorModule,

    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    //   apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    // AgmCoreModule

  ],

})
export class VmsLayoutModule { }
