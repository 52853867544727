import { Injectable } from '@angular/core';
import { MainService } from '../../service/main.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class MorthService {
  httpUrl: any
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;
  }
  async getAllMorth(obj: any) {
    const resp = this.http.get<any>(this.httpUrl + '/depricated/item_list/morth/getAllMorth' + JSON.stringify(obj)).toPromise().then(res => {
      return res
    });
    return resp
  }

  async getSubAnalysis(obj: any) {
    const resp = this.http.get<any>(this.httpUrl + '/depricated/item_list/morth/getSubAnalysis' + JSON.stringify(obj)).toPromise().then(res => {
      return res
    });
    return resp
  }



  async getAllRegions(obj: any) {
    console.log('aaaaa', obj);

    const resp = this.http.get<any>(this.httpUrl + '/depricated/item_list/morth/getAllRegions' + JSON.stringify(obj)).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getChapter(obj: any) {
    console.log('aaaaabsss', obj);

    const resp = this.http.get<any>(this.httpUrl + '/depricated/item_list/morth/getChapter' + JSON.stringify(obj)).toPromise().then(res => {
      return res
    });
    return resp
  }



  async createMorthItems(obj: any) {
    console.log(this.httpUrl + '/depricated/item_list/morth/createMorthItems')
    const resp = this.http.post<any>(this.httpUrl + '/depricated/item_list/morth/createMorthItems', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async deleteMorthItems(obj: any) {
    console.log('delete ', obj);

    const res = await this.http.delete<any>(this.httpUrl + '/depricated/item_list/morth/deleteMorthItems' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return res;
  }










}
