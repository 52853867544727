<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        Welcome , {{erpUser.party_name}}

                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Overview</a>
                        </li>
                    </ul>
                    <br>
                    <div class="tab-content">

                        <div class="tab-pane fade show active" id="tab-1">

                            <div class="row">

                                <div class="col-xl-3 col-lg-6">
                                    <a routerLinkActive="active" routerLink="/vms/vms-vendor">
                                        <div class="cards l-bg-green-dark z-depth-1"
                                            style="background: linear-gradient(to right, #493240, #f09) !important; color: #fff;">
                                            <div class="cards-statistic-3 p-4">
                                                <div class="cards-icon cards-icon-large"><i
                                                        class="fa fa-address-book fa-5x"></i></div>
                                                <div class="mb-4">
                                                    <h5 class="cards-title mb-0">Total Vender</h5>
                                                </div>
                                                <div class="row align-items-center mb-2 d-flex">
                                                    <div class="col-8">
                                                        <h1 class="d-flex align-items-center mb-0">
                                                            <b>{{totalVendor}}</b>
                                                        </h1>
                                                    </div>
                                                </div>

                                                <!-- 1 -->
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div class="col-xl-3 col-lg-6">

                                    <a routerLink="/vms/administration/work" routerLinkActive="active">


                                        <div class="cards l-bg-pink-dark" style=" background: linear-gradient(to right, #057f86, rgb(190, 243, 91)) !important;
                                    color: #fff;">
                                            <div class="cards-statistic-3 p-4">
                                                <div class="cards-icon cards-icon-large"><i
                                                        class="fa fa-cogs fa-5x"></i>
                                                </div>
                                                <div class="mb-4">
                                                    <h5 class="cards-title mb-0">Total Work</h5>
                                                </div>
                                                <div class="row align-items-center mb-2 d-flex">
                                                    <div class="col-8">
                                                        <h1 class="d-flex align-items-center mb-0">
                                                            <b>{{totalWork}}</b>
                                                        </h1>
                                                    </div>
                                                </div>
                                                <!-- 2 -->
                                            </div>
                                        </div>
                                    </a>
                                </div>




                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>