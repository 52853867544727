<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">E-Bill</div>


                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="list_flag" class="nav-link active" href="#tab-7-1" data-toggle="tab" (click)='refresh()'><i
                                    class="fa fa-line-chart"></i> E-Bill List</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="create_flag" class="nav-link" href="#tab-7-2" data-toggle="tab" (click)='refresh()'><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i> Create E-Bill
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="create_epc_flag" class="nav-link" href="#tab-7-5" data-toggle="tab"  ><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i> Create EPC Mode E-Bill 
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="update_flag" class="nav-link" href="#tab-7-3" data-toggle="tab"><i class="ti-settings"
                                    aria-hidden="true"> </i> Update E-Bill Deduction
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-7-4" data-toggle="tab"><i
                                    class="ti-settings" aria-hidden="true"> </i> Update E-Bill Previous Part Rate
                            </a>
                        </li> -->
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <br>

                           <div style="text-align: right;">
                            <button mat-button mat-raised-button class="btn btn-primary" (click)="createtab()">
                                Create
                            </button>&nbsp;&nbsp;
                            <button mat-button mat-raised-button class="btn btn-primary" (click)="createEpctab()">
                                Create E-Bill EPC Mode
                            </button>
                           </div>
                            <br>
                            
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Select work :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="all_listWork" bindLabel="desc" bindValue="id" [multiple]="false" placeholder="Select Work" [(ngModel)]="selectObj['work_order_no']" (change)="getAllEBILL()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="work_id">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <button class="btn btn-danger" (click)="deleteAll()">Delete All</button> -->
                                </div>
                            </div>


                            <div class=".mat-elevation-z8">

                                <table mat-table [dataSource]="datasource" matSort #sort="matSort">

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>ID
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <div class="showonhover">
                                                <div style="color: blue;"> {{ element.id}}
                                                </div>
                                                <span class="hovertext">
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6 >Bill Ammount:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{element.bill_amt.toFixed(2)}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6>CGST:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{element.cgst.toFixed(2)}}
                                                        </div>
                                                     </div>
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6>SGST:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{element.sgst.toFixed(2)}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6>IGST:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{element.igst.toFixed(2)}}
                                                        </div>
                                                    </div>
                                                    <hr style="background-color: rgb(128, 124, 124);">
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6>Bill Ammount with GST:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{element.tot_gst.toFixed(2)}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6> Add with held:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{element.add_with_held.toFixed(2)}} 
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6> Add security:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{element.add_security.toFixed(2)}}
                                                        </div>
                                                    </div>
                                                    <hr style="background-color: rgb(128, 124, 124);">
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6>Total Payable Ammount:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{element.tot_pay.toFixed(2)}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6>Deduction Amount:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{ element.ded_amt.toFixed(2)}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6>Labour Cess:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{element.labuor_cess.toFixed(2)}}
                                                        </div>
                                                    </div>
                                                    <hr style="background-color: rgb(0, 0, 0);">
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6>Net Payable:</h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            {{element.net_pay.toFixed(2)}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <button class="btn btn-success mt-1 ml-5" (click)="view_doc(element,'TESTINGREPORT')">View Testing Report
                                                        </button>&nbsp;&nbsp;
                                                        <button class="btn btn-primary mt-1" (click)="view_doc(element,'PHOTOGRAPH')">View Photograph
                                                        </button>&nbsp;&nbsp;
                                                        <button class="btn btn-warning mt-1" (click)="view_doc(element,'ROYALTIDETAIL')">View Royalty Detail
                                                        </button>&nbsp;&nbsp;
                                                        <button class="btn btn-info mt-1" (click)="view_doc(element,'OTHERDOCUMENT')">View Other Document
                                                        </button>
                                                    </div>
                                                </span>
                                            </div> 
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="bill_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Bill Number

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.bill_no}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="bill_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bill Description

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.bill_desc}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bill_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bill Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{mainService.dateformatchange(element.bill_dt)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bill_amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Bill Amount
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.bill_amt}} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="with_amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            With Held Amount
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.withheldamount}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="with_remark">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            With Held Remark
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.withheldremanrk}} </td>
                                    </ng-container> -->
                                    <!-- <ng-container matColumnDef="cgst">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            CGST
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.cgst}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="sgst">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            SGST
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.sgst}} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bill Status
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.status}} </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="ded_amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Deduction Amount
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.ded_amt}} </td>
                                    </ng-container> -->
                                    <!-- <ng-container matColumnDef="acc_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Accounts ID
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.acc_id}} </td>
                                    </ng-container> -->


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right" >
                                            <button class="btn btn-success mt-2" (click)="viewStatus(element)">View Status</button>&nbsp;
                                            <button class="btn btn-danger mt-2 " *ngIf="element.status == 'REJECTED' || element.status == 'GENERATED'" (click)="delete(element)">Delete</button>&nbsp;
                                            <button class="btn btn-info  mt-2" (click)="print_cb(element)">Print CB</button>&nbsp;
                                            <button class="btn btn-warning mt-2" (click)="print_running_bill(element)">Print Running Bill</button>&nbsp;
                                            <button class="btn btn-primary mt-2 "  *ngIf="work_emb_mode=='GEN'"  (click)="print_emb_for_bill(element)">Print EMB FOR Bill</button>&nbsp;
                                            <button class="btn btn-info mt-2" (click)="Appr(element)" *ngIf="element['status'] == 'GENERATED' && apprflag == true">Add Approval</button>&nbsp;
                                            <button class="btn btn-success  mt-2" (click)="open_update(element)"  *ngIf="element.status != 'APPROVED'">Update Deduction</button>&nbsp;
                                            <button class="btn btn-primary mt-2" (click)="seeworkflow(element)">See Workflow Details</button>&nbsp;
                                            <button class="btn btn-primary  mt-2" (click)="reassign(element)" *ngIf="element['status'] != 'GENERATED' && element['status'] != 'APPROVED' && this.reassignflag == true">Re-Assign</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>

                        </div>
                        <div *ngIf="create_flag || create_epc_flag" >
                            <div style="text-align: right;"><button mat-button mat-raised-button class="btn btn-primary" (click)="listbtab()">
                                Go-Back
                            </button></div>
                            <br>

                            <mat-horizontal-stepper [linear]="isLinear" #stepper>
                                <mat-step [stepControl]="first" label="Select Work">
                                    <br>
                                    <!-- <div class="row">
                                        <div class="col-3 text-right">
                                            <h6> Select Project :</h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="filterService.filterObj['project']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Project" [(ngModel)]="selectObj['project_cd']" (change)="changeWork()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="project_cd">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br> -->
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Select work :</h6>
                                        </div>
                                        <div class="col-3" *ngIf="create_flag">
                                            <ng-select [items]="listWork" bindLabel="desc" bindValue="id" [multiple]="false" placeholder="Select Work" [(ngModel)]="selectObj['work_order_no']" (change)="changeWorkInTab2()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="work_order_id">
                                            </ng-select>

                                        </div>
                                        <div class="col-3" *ngIf="create_epc_flag">
                                            <ng-select [items]="listWork_epc" bindLabel="desc" bindValue="id" [multiple]="false" placeholder="Select Work" [(ngModel)]="selectObj['work_order_no']" (change)="changeWorkEpc()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="work_order_id">
                                            </ng-select>

                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>Work Order Name :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['work_order_name']}}
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'EMB-EMB61']}} :</h6>
                                        </div>
                                        <div class="col-9">
                                            <!-- <ng-select [items]="listWork" bindLabel="desc" bindValue="id" [multiple]="false" placeholder="Select Work" [(ngModel)]="selectObj['work_order_no']" (change)="changeWorkInTab2()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="work_order_id">
                                            </ng-select> -->
                                            {{path_desc}}
                                        </div>
                                     

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Work Order Value :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['work_order_value']}}
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>Work Order Date:</h6>
                                        </div>
                                        <div class="col-3">
                                            <p *ngIf="obj['work_order_dt']">
                                                {{mainService.dateformatchange(obj['work_order_dt'])}}</p>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>E-Tender Id :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['tender_nic_id']}}
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>E-Tender Reference #</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['tender_ref_no']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Budget Code :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['budget_cd']}}
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>Budget Name :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['budget_name']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Budget Value :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['budget_value']}}
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>Project Code:</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['project_cd']}}
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Is this final Bill? :*</h6>
                                        </div>
                                        <div class="col-3">

                                            <ng-select [items]="finalBill" placeholder="" bindLabel="code" bindValue="value" [multiple]="false" [(ngModel)]="obj['is_final_bill']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>Vendor :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['party_id']}} - {{partyObj[obj['party_id']]}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>E-Bill Desc :</h6>
                                        </div>
                                        <div class="col-3">
                                            <textarea class="form-control" [(ngModel)]="obj['bill_desc']"></textarea>

                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>Remaining Order Value :</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['remaining_amt']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>Last Bill No:</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['prev_bill_no']}}
                                        </div>
                                        <div *ngIf="tt==1" class="col-3 text-right">
                                            <h6>E-Bill No.:</h6>
                                        </div>
                                        <div *ngIf="tt==1" class="col-3">
                                            <input type="number" (change)="ChangeEBillNumber()" class="form-control" [(ngModel)]="e_bill_no">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row" *ngIf="tt==1">
                                        <div class="col-3 text-right">
                                            <h6>Select Activity :</h6>
                                        </div>
                                        <div class="col-3">
                                            <button  class="btn btn-primary" (click)="open_activitypopup()">Select Activity</button>
                                        </div>
                                        <div  class="col-3 text-right">
                                            <h6>Activty Path</h6>
                                        </div>
                                        <div  class="col-3">
                                            {{obj['path_desc']}}
                                        </div>

                                    </div>
                                    <br>

                                    <hr>

                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6>Upload Testing Report : </h6>
                                        </div>
                                        <div class="col-3">
                                            <h6>Upload Photograph:</h6>
                                        </div>
                                        <div class="col-3">
                                            <h6>Upload Royalti Detail:</h6>
                                        </div>
                                        <div class="col-3">
                                            <h6>Upload Other Document:</h6>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3">
                                            <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader1" class="form-control" style="border-color: black" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="onFileUpload1($event,file.files)">
                                        </div>
                                        <div class="col-3">
                                            <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader2" class="form-control" style="border-color: black" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="onFileUpload2($event,file.files)">
                                        </div>
                                        <div class="col-3">
                                            <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader3" class="form-control" style="border-color: black" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="onFileUpload3($event,file.files)">
                                        </div>
                                        <div class="col-3">
                                            <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader4" class="form-control" style="border-color: black" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="onFileUpload4($event,file.files)">
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-primary"  matStepperNext>Next</button>
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second" label="Enter Quantiy">
                                    <br>
                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> Total Calculated Amount :</h6>

                                        </div>
                                        <div class="col-6">
                                            <h6><b> {{obj['subtotal']}}</b></h6>


                                        </div>
                                    </div>
                                    
                                    <br>
                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> Over Under % :</h6>

                                        </div>
                                        <div class="col-6">
                                            <h6><b> {{selectedTender['over_under']}} -
                                                    {{selectedTender['over_under_per']  }}</b></h6>


                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> Amount After Over Under :</h6>

                                        </div>
                                        <div class="col-6">
                                            <h6>{{obj['overunderamt']}}</h6>

                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> Amount After Discount :</h6>

                                        </div>
                                        <div class="col-6">
                                            <h6>{{obj['subtotal1']}}</h6>

                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> With Held Temporary :</h6>

                                        </div>
                                        <div class="col-3">

                                            <input type="number" class="form-control" [(ngModel)]="obj['temp_withheld']" (change)="changetemp_withheld()">


                                        </div>
                                        <div class="col-3">
                                            <textarea class="form-control" [(ngModel)]="obj['temp_withheld_remark']"></textarea>
                                            <!--  <input type="text" class="form-control" [(ngModel)]="obj['temp_withheld_remark']" > -->

                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> With Held Permanent :</h6>

                                        </div>
                                        <div class="col-3">

                                            <input type="number" class="form-control" [(ngModel)]="obj['per_withheld']" (change)="changetemp_withheld()">


                                        </div>
                                        <div class="col-3">
                                            <!-- <input type="text" class="form-control" [(ngModel)]="obj['per_withheld_remark']" > -->
                                            <textarea class="form-control" [(ngModel)]="obj['per_withheld_remark']"></textarea>

                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> Release Previous Temporary Withheld :</h6>

                                        </div>
                                        <div class="col-3">

                                            <input type="number" class="form-control" [(ngModel)]="obj['rel_temp_withheld']" (change)="changetemp_withheld()">


                                        </div>
                                        <!-- <div class="col-3">
                                            <h6>{{obj['pre_gst_amt']}}</h6>
                                        </div> -->
                                    </div>
                                    <br>
                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> Amount After All With Held :</h6>

                                        </div>
                                        <div class="col-6">
                                            <h6><b> {{obj['amt_after_withheld']}}</b></h6>


                                        </div>
                                    </div>
                                    <br *ngIf="ded_pre_gst_flag">
                                    <div class="row" *ngIf="ded_pre_gst_flag">

                                        <div class="col-6 text-right">
                                            <h6> Deduct Pre GST :</h6>

                                        </div>
                                        <div class="col-3">

                                            <input type="number" class="form-control" [(ngModel)]="obj['pre_gst']" (change)="changePreGST()">


                                        </div>
                                        <div class="col-3">
                                        </div>
                                        <h6><input type="number" class="form-control" [(ngModel)]="obj['pre_gst_amt']" (change)="changePreGSTamt()">
                                        </h6>

                                    </div>
                                    <br *ngIf="this.selectedTender['labour_cess_flag'] == 1">
                                    <div class="row" *ngIf="this.selectedTender['labour_cess_flag'] == 1">

                                        <div class="col-6 text-right">
                                            <h6> Labour Cess Amount :</h6>

                                        </div>
                                        <div class="col-6">
                                            <h6><b> {{obj['labuor_cess']}}</b></h6>


                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> Work Done Amount :</h6>

                                        </div>
                                        <div class="col-6">
                                            <h6><b> {{obj['showTotal'] + obj['labuor_cess']}}</b></h6>


                                        </div>
                                    </div>
                                    <br>
                                    <div class="row" *ngIf="create_flag">
                                        <div class="col-4">
                                            <mat-form-field style="margin-left: 2%">
                                                <input matInput (keyup)="applyFilter1($any($event.target).value)" placeholder="Filter">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-4">
                                        </div>
                                        <div class="col-4 text-right">
                                            <!-- <button class="btn btn-danger" (click)="deleteAll()">Delete All</button> -->
                                        </div>
                                    </div>
                                    <br>
                                    <div class=".mat-elevation-z8" *ngIf="create_flag">

                                        <table mat-table [dataSource]="datasource1" matSort id="myHeader" #sortCol2="matSort">
                                            <ng-container matColumnDef="upquan">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Uptodate Bill Quantity
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ element.uptodatequantity}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="prevquan">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Previous Bill Quantity
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ element.prevquantity}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="crrbillquan">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Current Bill Quantity
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ element.quantity}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="s_no">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>LN


                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ element.sno}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="line_desc">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Line Description

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    <div class="showonhover">
                                                        <!-- <a class="text-left">{{element['line_desc'].substr(0,50)}}</a> -->
                                                        <input type="text" class="form-control" [(ngModel)]="element['line_desc']" disabled name="" id="">
                                                        <span class="hovertext">
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>Line Description :122222222222</h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{element['line_desc']}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>Unit :</h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{element['unit']}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6> Order Qty :</h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p> {{ element.eff_quantity }} </p>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>Order Rate :</h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{ element.eff_rate}} </p>
                                                                </div>
                                                            </div>


                                                        </span>
                                                    </div>
                                            </ng-container>





                                            <ng-container matColumnDef="eq">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Enter This E-Bill Qty

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    <input class="form-control" type="number" (change)="changeQuan(element)" [(ngModel)]="element['quantity']" *ngIf="selectedTender['type'] != 'emb_with_ebill'">
                                                    <input class="form-control" type="number" (change)="changeQuan(i)" [(ngModel)]="element['quantity']" disabled *ngIf="selectedTender['type'] == 'emb_with_ebill'">
                                                </td>
                                            </ng-container>



                                            <ng-container matColumnDef="epr">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Enter Part Rate

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">

                                                    <input class="form-control" type="number" (change)="changeRate(element)" [(ngModel)]="element['rate']" *ngIf="selectedTender['type'] != 'emb_with_ebill'">
                                                    <input class="form-control" type="number" (change)="changeRate(element)" [(ngModel)]="element['rate']" *ngIf="selectedTender['type'] == 'emb_with_ebill'">
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="uptodateamt">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Uptodate Bill Amount
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ element.uptodateamount}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="prevamt">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Previous Bill Amount
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ element.prevbillamount}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="amt">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Current Bill Amount
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ element.amount}} </td>
                                            </ng-container>

                                            <!-- <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    <span *ngIf="emb_type != 'emb_with_ebill'"> Action</span>
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">

                                                    <button class="btn btn-primary" (click)="addWorkLine(element,i)" *ngIf="element['ebill_quantity'] >0 && element['flag'] != false && emb_type != 'emb_with_ebill'">
                                                        <i class="fa fa-plus" style="font-size: 20px;"
                                                            aria-hidden="true"></i></button>
                                                </td>
                                            </ng-container> -->


                                            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                            </tr>
                                        </table>

                                        <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                        </mat-paginator>
                                    </div>

                                 
                                    <div class=".mat-elevation-z8" *ngIf="create_epc_flag">
                                        <div class="row">
                                            <div class="col-4">
                                                <mat-form-field style="margin-left: 2%">
                                                    <input matInput (keyup)="applyFilter3($any($event.target).value)"
                                                        placeholder="Filter">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-4">
                                            </div>
                                            <div class="col-4 text-right">
                                            </div>
                                        </div>
                                        <br>
    
                                        <table mat-table [dataSource]="datasource3" matSort id="myHeader"
                                        #sortCol3="matSort">

                                        <ng-container matColumnDef="s_no">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>S
                                                No.
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;"
                                                class="right">
                                                {{ element.sno}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="payment_line_desc">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Line
                                                Description

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;"
                                                class="right">
                                                <div class="showonhover">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="element['payment_line_desc']" disabled name=""
                                                        id="">
                                                    <span class="hovertext">
                                                        <div class="row">
                                                            <div class="col-3 text-left">
                                                                <h6>Line Description :</h6>
                                                            </div>
                                                            <div class="col-9 text-left">
                                                                <p>{{element['payment_line_desc']}}</p>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-3 text-left">
                                                                <h6> Payment line % :</h6>
                                                            </div>
                                                            <div class="col-9 text-left">
                                                                <p> {{ element.payment_line_per }} </p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-3 text-left">
                                                                <h6> Last Paid Payment % :</h6>
                                                            </div>
                                                            <div class="col-9 text-left">
                                                                <p> {{ element.payment_last_paid_per }} </p>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-3 text-left">
                                                                <h6>Order Rate :</h6>
                                                            </div>
                                                            <div class="col-9 text-left">
                                                                <p>{{ element.file_eff_rate}} </p>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                        </ng-container>



                                        <ng-container matColumnDef="last_paid_per">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Last Paid %

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;"
                                                class="right">
                                                <input class="form-control" type="number" disabled
                                                    [(ngModel)]="element['last_paid_per']">
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="upto_date_per">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Upto Date %

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;"
                                                class="right">
                                                <input class="form-control" type="number" disabled
                                                    [(ngModel)]="element['upto_date_per']">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="remaining_quantity">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Remaining %

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;"
                                                class="right">
                                                <input class="form-control" type="number" disabled
                                                    [(ngModel)]="element['remaining_quantity']">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="fraction">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Fraction

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;"
                                                class="right">
                                                <input class="form-control" type="text"
                                                    (change)="changePaymentFraction(element)"
                                                    [(ngModel)]="element['fraction']">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="to_pay_per">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                To Pay %

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;"
                                                class="right">
                                                <input class="form-control" type="number"
                                                    (change)="changePaymentPer(element)"
                                                    [(ngModel)]="element['quantity']">
                                            </td>
                                        </ng-container>





                                        <ng-container matColumnDef="amount">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Amount
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;"
                                                class="right">
                                                <input class="form-control" type="number" disabled
                                                    [(ngModel)]="element['amount']">
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="remark">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Remark
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;"
                                                class="right">
                                                <textarea class="form-control"
                                                    [(ngModel)]="element['remark']">></textarea>


                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns3;">
                                        </tr>
                                    </table>

                                    <mat-paginator #paginator3 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                    </mat-paginator>
                                    </div>

                                    <br>
                                    <!--  <div class="row">
                                        <div class="col-12 text-center">
                                            <h6>Part Rate From Pervious Bills Calculation</h6>
                                        </div>
                                    </div>
                                    <br>
                                    <div class=".mat-elevation-z8">

                                        <table mat-table [dataSource]="datasource2" matSort id="myHeader" #sortCol3="matSort">

                                            <ng-container matColumnDef="s_no">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>LN


                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ element.sno}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="line_desc">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Line Description

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    

                                                    <div class="showonhover">
                                                        
                                                        <input type="text" class="form-control" [(ngModel)]="boqObj[element['sno']]['item_desc']" disabled name="" id="">
                                                        <span class="hovertext">
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>Line Description :</h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{boqObj[element['sno']]['item_desc']}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>Line Unit :</h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{boqObj[element['sno']]['unit']}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>Work Order Qty :</h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{boqObj[element['sno']]['quantity']}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>Work Order Rate :</h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p> {{ element.eff_rate}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6> Paid Qty :</h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p> {{ element.quantity}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>Already Paid Rate :</h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p> {{ element.already_rate}}</p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                            </ng-container>



                                            <ng-container matColumnDef="bill_no">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bill No
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ element.bill_no}} </td>
                                            </ng-container>

                                           
                                            <ng-container matColumnDef="paid_quantity">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Paid Qty
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ element.quantity}} </td>
                                            </ng-container>




                                            <ng-container matColumnDef="part_rate">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Part Rate
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    <input class="form-control" type="number" (change)="partRateChange(i,element)" [(ngModel)]="element['new_rate']">
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="amount">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Amount

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element; let i = index;" class="right">
                                                    {{ (element.new_rate * element.quantity).toFixed(2)}} </td>


                                            </ng-container>




                                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                                            </tr>
                                        </table>
                                        <mat-paginator #paginator2 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                        </mat-paginator>
                                    </div>
                                    <br> -->

                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger" matStepperPrevious>Back</button>
                                            <button mat-button mat-raised-button class="btn btn-primary" matStepperNext (click)="step3()">Next</button>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="third" label="Enter Allowances">
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-8">

                                        </div>
                                        <div class="col-2 text-right">
                                            <h5> Total Bill Amount:</h5>

                                        </div>
                                        <div class="col-2">
                                            <h5><b> {{obj['showTotal']}}</b></h5>


                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-8">

                                        </div>
                                        <div class="col-2 text-right">
                                            <h5> Enter Exempted Amount :</h5>

                                        </div>
                                        <div class="col-2">
                                            <h5><b> <input type="number" class="form-control"
                                                        [(ngModel)]="this.obj['ex_amt']" (change)="changeexemtedamt()">
                                                </b></h5>


                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-8">

                                        </div>
                                        <div class="col-2 text-right">
                                            <h5> Taxable Amount :</h5>

                                        </div>
                                        <div class="col-2">
                                            <h5><b> {{this.obj['taxable_amt']}}</b></h5>


                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> Taxable Amount For GST:</h6>

                                        </div>
                                        <div class="col-3">

                                            <input type="number" class="form-control" [(ngModel)]="obj['tax_amt']" (change)="changetaxamt()">


                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">

                                        <div class="col-6 text-right">
                                            <h6> Select GST Type :</h6>

                                        </div>
                                        <div class="col-3">

                                            <ng-select [items]="gstarr" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select GST TYPE" [(ngModel)]="obj['gst_type']" (change)="changegsttype()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="project_cd">
                                            </ng-select>

                                        </div>
                                        <div class="col-3">
                                            <!--                                             <textarea class="form-control" [(ngModel)]="obj['temp_withheld_remark']"></textarea>
 -->
                                            <!--  <input type="text" class="form-control" [(ngModel)]="obj['temp_withheld_remark']" > -->

                                        </div>
                                    </div>
                                    <br *ngIf="gstflag == 1">
                                    <div class="row" *ngIf="gstflag == 1">

                                        <div class="col-6 text-right">
                                            <h6> CGST :</h6>

                                        </div>
                                        <div class="col-3">

                                            <input type="number" class="form-control" [(ngModel)]="obj['cgst_per_table']" (change)="changegstper()">


                                        </div>
                                        <div class="col-3">
                                            <input type="number" class="form-control" [(ngModel)]="obj['cgst_table']">
                                            <!--  <input type="text" class="form-control" [(ngModel)]="obj['temp_withheld_remark']" > -->

                                        </div>
                                    </div>
                                    <br *ngIf="gstflag == 1">
                                    <div class="row" *ngIf="gstflag == 1">

                                        <div class="col-6 text-right">
                                            <h6> SGST :</h6>

                                        </div>
                                        <div class="col-3">

                                            <input type="number" class="form-control" [(ngModel)]="obj['sgst_per_table']" (change)="changegstper()">


                                        </div>
                                        <div class="col-3">
                                            <input type="number" class="form-control" [(ngModel)]="obj['sgst_table']">
                                            <!--  <input type="text" class="form-control" [(ngModel)]="obj['temp_withheld_remark']" > -->

                                        </div>
                                    </div>
                                    <br>
                                    <div class="row" *ngIf="gstflag == 2">

                                        <div class="col-6 text-right">
                                            <h6> IGST :</h6>

                                        </div>
                                        <div class="col-3">

                                            <input type="number" class="form-control" [(ngModel)]="obj['igst_per_table']" (change)="changegstper()">


                                        </div>
                                        <div class="col-3">
                                            <input type="number" class="form-control" [(ngModel)]="obj['igst_table']">
                                            <!--  <input type="text" class="form-control" [(ngModel)]="obj['temp_withheld_remark']" > -->

                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <!-- <div class="col-3">
                                            <h6>Total Measured : {{lineTotal}}</h6>
                                        </div>
                                        <div class="col-2">
                                            <input type="number" class="form-control" placeholder="enter Line" [(ngModel)]="no_of_lines">
                    
                                        </div> -->
                                        <div class="col-6 text-right">
                                            <button class="btn btn-primary" (click)="addLine()">ADD</button>
                                        </div>
                                        <!--  <div class="col-6 text-right">
                                            <button class="btn btn-primary" (click)="addLine()">Update</button>
                                        </div>  -->
                                    </div>
                                    <br>

                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Taxable amount</th>
                                                <th scope="col">GST-TYPE</th>

                                                <th scope="col">Amount</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let gst of obj['gstarr']; let i = index;">
                                                <td>
                                                    <h6>{{gst['tax_amt']}}</h6>
                                                </td>
                                                <td>{{gst['gst_type']}}</td>

                                                <td> {{gst['gst_amt']}}
                                                </td>
                                                <td> <button class="btn btn-danger" (click)="deleteLine(i)"><i
                                                            class="fa fa-trash-o"
                                                            style="font-size: 20px;margin-left: 10%;"
                                                            aria-hidden="true"></i></button>
                                                    <!-- <button class="btn btn-primary" (click)="editgst(element,i)"><i class="fas fa-edit"
                                                        style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button> -->
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td>
                                                    <h6>{{obj['showTotal']}}</h6>
                                                </td>
                                                <td>CGST</td>
                                                <td>
                                                    <input type="number" (change)="check()" class="form-control" [(ngModel)]="obj['allowance_cgst_percentage']">
                                                </td>
                                                <td> <input type="number" (change)="checkgstamt()" class="form-control" [(ngModel)]="obj['cgst_amount']">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6>{{obj['showTotal']}}</h6>
                                                </td>
                                                <td>SGST</td>
                                                <td>
                                                    <input type="number" (change)="check()" class="form-control" [(ngModel)]="obj['allowance_sgst_percentage']">
                                                </td>
                                                <td> <input type="number" (change)="checkgstamt()" class="form-control" [(ngModel)]="obj['sgst_amount']">
                                                </td>
                                            </tr>
                                            <tr> -->
                                            <!-- <div class="row ">
                                                    <div class="col-12 text-center">
                                                        <h4>OR</h4>
                                                    </div>
                                                </div> -->
                                            <!--  <td></td>
                                                <td>OR</td>
                                                <td></td>
                                                <td></td>
                                            </tr> -->
                                            <!--  <tr>
                                                <td>
                                                    <h6>{{obj['showTotal']}}</h6>
                                                </td>
                                                <td>IGST</td>
                                                <td>
                                                    <input type="number" (change)="check()" class="form-control" [(ngModel)]="obj['allowance_igst_percentage']">
                                                </td>
                                                <td><input type="number" (change)="checkgstamt()" class="form-control" [(ngModel)]="obj['igst_amount']"></td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                    <br>
                                    <div class="row">
                                        <div class="col-8 text-right">
                                            <h6>TOTAL GST :</h6>
                                        </div>
                                        <div class="col-4 text-right">
                                            {{(obj['sgst_amount']+obj['cgst_amount']+obj['igst_amount']).toFixed(2)}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-8 text-right">
                                            <h6>TOTAL BILL AMOUNT WITH GST :</h6>
                                        </div>
                                        <div class="col-4 text-right">
                                            {{obj['bill_with_gst_amt']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-8 text-right">
                                            <h6>Add Previous With Held :</h6>
                                        </div>
                                        <div class="col-4 text-right">
                                            <input class="form-control" (change)="changeWithHeld()" type="number" [(ngModel)]="obj['add_with_held']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-8 text-right">
                                            <h6>Add Security :</h6>
                                        </div>
                                        <div class="col-4 text-right">
                                            <input class="form-control" type="number" (change)="changeWithHeld()" [(ngModel)]="obj['add_security']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-8 text-right">
                                            <h6>TOTAL PAYABLE AMOUNT :</h6>
                                        </div>
                                        <div class="col-4 text-right">
                                            {{obj['total_payable'] + obj['labuor_cess']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger" matStepperPrevious>Back</button>
                                            <button mat-button mat-raised-button class="btn btn-primary" (click)="addDefaultDed()" matStepperNext>Next</button>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="fourth" label="Enter Deductions">

                                    <br>
                                    <div class="row">
                                        <div class="col-8">

                                        </div>
                                        <div class="col-2 text-right">
                                            <h5> Total :</h5>

                                        </div>
                                        <div class="col-2">
                                            <h5><b> {{obj['total_ded_amount']}}</b></h5>


                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">

                                        <div style="padding-top: 8px;padding-bottom: 8px;width: 30%;" class="text-center border">

                                            <h6><b>Deduction</b></h6>

                                        </div>
                                        <div style="padding-top: 8px;padding-bottom: 8px;width: 30%;" class="text-center border">
                                            <h6><b>Deduction Desc</b></h6>


                                        </div>
                                        <div style="padding-top: 8px;padding-bottom: 8px;width: 20%;" class="text-center border">
                                            <h6><b> Deduction Amount</b></h6>


                                        </div>


                                        <div style="padding-top: 8px;padding-bottom: 8px;width: 10%;" class="text-center border">
                                            <h6><b>Action</b></h6>


                                        </div>
                                        <div style="width: 5%;" class="text-center">
                                            <button class="btn btn-primary" (click)="addDeductionRow()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i></button>


                                        </div>
                                    </div>
                                    <hr>
                                    <div *ngFor="let ded of obj['deduction']; let i = index;">

                                        <div class="row ">

                                            <div style="width: 30%;" class="text-left">
                                                {{dedObj[obj['deduction'][i]['deduction_id']]['ded_name']}}
                                                <!-- <ng-select [items]="dedTypeArr" placeholder="Select Deduction" bindLabel="ded_name" bindValue="deduction_id" [multiple]="false" [(ngModel)]="obj['deduction'][i]['deduction_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" (change)="changeDed(i)"
                                                    [closeOnSelect]="true" name="field_name" awesome.appendTo="body">
                                                </ng-select> -->
                                            </div>
                                            <div *ngIf="ded['apply_on']==''" style="width: 30%;" class="text-left">
                                                FIXED AMOUNT
                                                <!-- <input class="form-control" [(ngModel)]="ded['ded_desc']" placeholder="Enter Deduction description"> -->

                                            </div>
                                            <div *ngIf="ded['apply_on']=='B'" style="width: 30%;" class="text-left">
                                                APPLIED ON BILL AMOUNT @ {{ded['rate']}}
                                                <!-- <input class="form-control" [(ngModel)]="ded['ded_desc']" placeholder="Enter Deduction description"> -->

                                            </div>
                                            <div *ngIf="ded['apply_on']=='SGST'" style="width: 30%;" class="text-left">
                                                APPLIED ON SGST AMOUNT @ {{ded['rate']}}
                                                <!-- <input class="form-control" [(ngModel)]="ded['ded_desc']" placeholder="Enter Deduction description"> -->

                                            </div>
                                            <div *ngIf="ded['apply_on']=='CGST'" style="width: 30%;" class="text-left">
                                                APPLIED ON CGST AMOUNT @ {{ded['rate']}}
                                                <!-- <input class="form-control" [(ngModel)]="ded['ded_desc']" placeholder="Enter Deduction description"> -->

                                            </div>
                                            <div *ngIf="ded['apply_on']=='L'" style="width: 30%;" class="text-left">
                                                APPLIED ON SNO. {{ded['sno']}} @ {{ded['rate']}}
                                                <!-- <input class="form-control" [(ngModel)]="ded['ded_desc']" placeholder="Enter Deduction description"> -->

                                            </div>
                                            <div style="width: 20%;" class="text-right">
                                                <!-- {{ded['amt']}} -->
                                                <input type="number" class="form-control" [(ngModel)]="ded['amt']" placeholder="Enter Amount Amount" (change)="changeDedAmt()">
                                                    <div *ngIf="ded['amt'] == null || ded['amt'] == undefined" style="color: red;">*  Required Field</div>
                                            </div>

                                            <div style="width: 10%;" class="text-center">
                                                <!-- <button *ngIf="ded['apply_on']=='L'" class="btn btn-primary" (click)="addlineRow(i)"><i class="fa fa-plus"
                                                        aria-hidden="true"></i></button> -->

                                                <button class="btn btn-danger" *ngIf="ded['deduction_id']!='-1'" (click)="deleteDeduction(i)"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></button>


                                            </div>

                                        </div>



                                    </div>
                                    <br>
                                    <div class="row" style="margin-top: 10%;">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger" matStepperPrevious>Back</button>
                                            <!-- <button mat-button mat-raised-button color="accent"
                                                (click)="check()">Check</button> -->

                                            <button mat-button mat-raised-button class="btn btn-primary" matStepperNext>Next</button>

                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="fifth" label="Done">
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>BILL AMOUNT WITHOUT GST :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{obj['showTotal']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>CGST :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{obj['cgst_amount']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>SGST :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{obj['sgst_amount']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>IGST :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{obj['igst_amount']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>BILL AMOUNT WITH GST :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{obj['bill_with_gst_amt']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>ADD WITH HELD AMOUNT :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{obj['add_with_held']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>ADD SECURITY WITHHELD :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{obj['add_security']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>TOTAL PAYABLE AMOUNT :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{obj['total_payable']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>Labour Cess Amount :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{obj['labuor_cess']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>TOTAL DEDUCTIONS :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{obj['total_ded_amount']}}
                                        </div>
                                    </div>

                                    <hr>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>AMOUNT AFTER DEDUCTION :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{(obj['total_payable']-obj['total_ded_amount'] + obj['labuor_cess']).toFixed(2)}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>ENTER WITH HELD AMOUNT:</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            <input type="number" class="form-control" [(ngModel)]="obj['with_held_amt']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>ENTER WITH HELD REMARK :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            <textarea class="form-control" [(ngModel)]="obj['final_remark']"></textarea>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>NET PAYABLE AMOUNT :</h6>
                                        </div>
                                        <div class="col-3 text-right">
                                            {{(obj['total_payable']-obj['total_ded_amount'] -obj['with_held_amt'] + obj['labuor_cess']).toFixed(2) }}
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">

                                        <button mat-button mat-raised-button class="btn btn-danger" matStepperPrevious>Back</button>
                                        <!-- <button mat-button mat-raised-button color="accent"
                                            (click)="check()">Check</button> -->

                                        <button mat-button mat-raised-button class="btn btn-primary" (click)="submitBill()">Submit</button>

                                        <!-- <button mat-button mat-raised-button color="warning"
                                            (click)="stepper.reset()">RESET</button> -->
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                        <div *ngIf="update_flag">
                            <div  style="text-align: right;">
                                <button mat-button mat-raised-button class="btn btn-primary" (click)="listbtab()"> Go-Back
                                </button>

                            </div>
                           
                               

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Amount After Over Under and Discount :</h6>
                                </div>
                                <div class="col-4 ">
                                    <b> {{obj['showTotal']}}</b>
                                </div>


                            </div><br>
                            <!--  <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Add @ :</h6>
                                </div>
                                <div class="col-4 ">
                                    <input type="number" class="form-control" [(ngModel)]="obj['over_under_per']" placeholder="Enter Amount Amount" >
                                    <div class="col-2 "> <b> {{obj['over_under_amt']}}</b></div>
                                   
                                   
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Sub Total :</h6>
                                </div>
                                <div class="col-4 ">
                                    <b> {{obj['showTotal']}}</b>
                                   
                                </div>
                            </div>
                            <br> -->

                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6> With Held Temporary :</h6>

                                </div>
                                <div class="col-4">

                                    <input type="number" class="form-control" [(ngModel)]="obj['temp_withheld']" (change)="changeupdate()">


                                </div>
                                <div class="col-2">
                                    <textarea class="form-control" [(ngModel)]="obj['temp_withheld_remark']"></textarea>
                                    <!--  <input type="text" class="form-control" [(ngModel)]="obj['temp_withheld_remark']" > -->

                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6> With Held Permanent :</h6>

                                </div>
                                <div class="col-4">

                                    <input type="number" class="form-control" [(ngModel)]="obj['per_withheld']" (change)="changeupdate()">


                                </div>
                                <div class="col-2">
                                    <!-- <input type="text" class="form-control" [(ngModel)]="obj['per_withheld_remark']" > -->
                                    <textarea class="form-control" [(ngModel)]="obj['per_withheld_remark']"></textarea>

                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6> Release Previous Temporary Withheld :</h6>

                                </div>
                                <div class="col-4">

                                    <input type="number" class="form-control" [(ngModel)]="obj['rel_temp_withheld']" (change)="changeupdate()">


                                </div>
                                <!-- <div class="col-3">
                                    <h6>{{obj['pre_gst_amt']}}</h6>
                                </div> -->
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6> Amount After All With Held :</h6>

                                </div>
                                <div class="col-4">
                                    <h6><b> {{obj['amt_after_withheld']}}</b></h6>


                                </div>
                            </div>

                            <br *ngIf="ded_pre_gst_flag">
                            <div class="row" *ngIf="ded_pre_gst_flag">
                                <div class="col-4 text-right">
                                    <h6>Deduct Pre GST :</h6>
                                </div>
                                <div class="col-4 ">
                                    <input type="number" class="form-control" [(ngModel)]="obj['pre_gst']" placeholder="Enter Amount Amount" (change)="changeupdate()">



                                </div>
                                <div class="col-2 "><input type="number" class="form-control" [(ngModel)]="obj['pre_gst_amt']" placeholder="Enter Amount Amount" (change)="changeupdateamt()">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Work Done Amount :</h6>
                                </div>
                                <div class="col-4 ">
                                    <b> {{obj['g_total']}}</b>

                                </div>
                            </div>
                            <br>
                            <br>
                            <!-- <br>
                            <div class="row">
                                <div class="col-8">

                                </div>
                                <div class="col-2 text-right">
                                    <h5> Total Bill Amount:</h5>

                                </div>
                                <div class="col-2">
                                    <h5><b> {{obj['showTotal']}}</b></h5>


                                </div>
                            </div> -->
                            <br>
                            <div class="row">
                                <!-- <div class="col-4">

                                </div> -->
                                <div class="col-4 text-right">
                                    <h5> Enter Exempted Amount :</h5>

                                </div>
                                <div class="col-4">
                                    <h5><b> <input type="number" class="form-control" [(ngModel)]="this.obj['ex_amt']"
                                                (change)="changeexemtedamtupdate()">
                                        </b></h5>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <!-- <div class="col-4">

                                </div> -->
                                <div class="col-4 text-right">
                                    <h5> Taxable Amount :</h5>

                                </div>
                                <div class="col-4">
                                    <h5><b> {{this.obj['taxable_amt']}}</b></h5>


                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6> Taxable Amount For GST:</h6>

                                </div>
                                <div class="col-4">

                                    <input type="number" class="form-control" [(ngModel)]="obj['tax_amt']" (change)="changetaxamt()">


                                </div>

                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6> Select GST Type :</h6>

                                </div>
                                <div class="col-4">

                                    <ng-select [items]="gstarr" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select GST TYPE" [(ngModel)]="obj['gst_type']" (change)="changegsttype()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="project_cd">
                                    </ng-select>

                                </div>
                                <div class="col-2">
                                    <!--                                             <textarea class="form-control" [(ngModel)]="obj['temp_withheld_remark']"></textarea>
-->
                                    <!--  <input type="text" class="form-control" [(ngModel)]="obj['temp_withheld_remark']" > -->

                                </div>
                            </div>
                            <br *ngIf="gstflag == 1">
                            <div class="row" *ngIf="gstflag == 1">

                                <div class="col-4 text-right">
                                    <h6> CGST :</h6>

                                </div>
                                <div class="col-4">

                                    <input type="number" class="form-control" [(ngModel)]="obj['cgst_per_table']" (change)="changegstper()">


                                </div>
                                <div class="col-2">
                                    <input type="number" class="form-control" [(ngModel)]="obj['cgst_table']">
                                    <!--  <input type="text" class="form-control" [(ngModel)]="obj['temp_withheld_remark']" > -->

                                </div>
                            </div>
                            <br *ngIf="gstflag == 1">
                            <div class="row" *ngIf="gstflag == 1">

                                <div class="col-4 text-right">
                                    <h6> SGST :</h6>

                                </div>
                                <div class="col-4">

                                    <input type="number" class="form-control" [(ngModel)]="obj['sgst_per_table']" (change)="changegstper()">


                                </div>
                                <div class="col-2">
                                    <input type="number" class="form-control" [(ngModel)]="obj['sgst_table']">
                                    <!--  <input type="text" class="form-control" [(ngModel)]="obj['temp_withheld_remark']" > -->

                                </div>
                            </div>
                            <br>
                            <div class="row" *ngIf="gstflag == 2">

                                <div class="col-4 text-right">
                                    <h6> IGST :</h6>

                                </div>
                                <div class="col-4">

                                    <input type="number" class="form-control" [(ngModel)]="obj['igst_per_table']" (change)="changegstper()">


                                </div>
                                <div class="col-2">
                                    <input type="number" class="form-control" [(ngModel)]="obj['igst_table']">
                                    <!--  <input type="text" class="form-control" [(ngModel)]="obj['temp_withheld_remark']" > -->

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <!-- <div class="col-3">
                                    <h6>Total Measured : {{lineTotal}}</h6>
                                </div>
                                <div class="col-2">
                                    <input type="number" class="form-control" placeholder="enter Line" [(ngModel)]="no_of_lines">
            
                                </div> -->
                                <div class="col-6 text-right">
                                    <button class="btn btn-primary" (click)="addLineupdate()">ADD</button>
                                </div>
                                <!--  <div class="col-6 text-right">
                                    <button class="btn btn-primary" (click)="addLine()">Update</button>
                                </div>  -->
                            </div>
                            <br>

                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Taxable amount</th>
                                        <th scope="col">GST-TYPE</th>

                                        <th scope="col">Amount</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let gst of obj['gstarr']; let i = index;">
                                        <td>
                                            <h6>{{gst['tax_amt']}}</h6>
                                        </td>
                                        <td>{{gst['gst_type']}}</td>

                                        <td> {{gst['gst_amt']}}
                                        </td>
                                        <td> <button class="btn btn-danger" (click)="deleteLineupdate(i)"><i
                                                    class="fa fa-trash-o" style="font-size: 20px;margin-left: 10%;"
                                                    aria-hidden="true"></i></button>
                                            <!-- <button class="btn btn-primary" (click)="editgst(element,i)"><i class="fas fa-edit"
                                                style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button> -->
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td>
                                            <h6>{{obj['showTotal']}}</h6>
                                        </td>
                                        <td>CGST</td>
                                        <td>
                                            <input type="number" (change)="check()" class="form-control" [(ngModel)]="obj['allowance_cgst_percentage']">
                                        </td>
                                        <td> <input type="number" (change)="checkgstamt()" class="form-control" [(ngModel)]="obj['cgst_amount']">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h6>{{obj['showTotal']}}</h6>
                                        </td>
                                        <td>SGST</td>
                                        <td>
                                            <input type="number" (change)="check()" class="form-control" [(ngModel)]="obj['allowance_sgst_percentage']">
                                        </td>
                                        <td> <input type="number" (change)="checkgstamt()" class="form-control" [(ngModel)]="obj['sgst_amount']">
                                        </td>
                                    </tr>
                                    <tr> -->
                                    <!-- <div class="row ">
                                            <div class="col-12 text-center">
                                                <h4>OR</h4>
                                            </div>
                                        </div> -->
                                    <!--  <td></td>
                                        <td>OR</td>
                                        <td></td>
                                        <td></td>
                                    </tr> -->
                                    <!--  <tr>
                                        <td>
                                            <h6>{{obj['showTotal']}}</h6>
                                        </td>
                                        <td>IGST</td>
                                        <td>
                                            <input type="number" (change)="check()" class="form-control" [(ngModel)]="obj['allowance_igst_percentage']">
                                        </td>
                                        <td><input type="number" (change)="checkgstamt()" class="form-control" [(ngModel)]="obj['igst_amount']"></td>
                                    </tr> -->
                                </tbody>
                            </table>
                            <br>
                            <!--  <div class="row">
                                <div class="col-4 text-right">
                                    <h6>CGST :</h6>
                                </div>
                                <div class="col-4 text-right">
                                    <input type="number" class="form-control" [(ngModel)]="obj['cgst_per']" placeholder="Enter Amount Amount" (change)="changeupdate()">


                                </div>
                                <div class="col-2 "><input type="number" class="form-control" [(ngModel)]="obj['cgst_amount']" placeholder="Enter Amount " (change)="changeupdateamt()">
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>SGST :</h6>
                                </div>
                                <div class="col-4 ">
                                    <input type="number" class="form-control" [(ngModel)]="obj['sgst_per']" placeholder="Enter Amount " (change)="changeupdate()">


                                </div>
                                <div class="col-2 "><input type="number" class="form-control" [(ngModel)]="obj['sgst_amount']" placeholder="Enter Amount " (change)="changeupdateamt()">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                </div>
                                <div class="col-4 text-center ">
                                    <h6>OR</h6>
                                </div>
                                <div class="col-2 "></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>IGST :</h6>
                                </div>
                                <div class="col-4 ">
                                    <input type="number" class="form-control" [(ngModel)]="obj['igst_per']" placeholder="Enter Amount " (change)="changeupdate()">
                                </div>
                                <div class="col-2 ">
                                    <input type="number" class="form-control" [(ngModel)]="obj['igst_amount']" placeholder="Enter Amount " (change)="changeupdateamt()">
                                </div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Add Previous With Held :</h6>
                                </div>
                                <div class="col-4 text-left">
                                    <input type="number" class="form-control" [(ngModel)]="obj['add_with_held']" placeholder="Enter Amount Amount" (change)="changeupdate()">


                                </div>

                            </div><br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Add Previous With Held Security :</h6>
                                </div>
                                <div class="col-4 ">
                                    <input type="number" class="form-control" [(ngModel)]="obj['add_security']" placeholder="Enter Amount Amount" (change)="changeupdate()">


                                </div>

                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h5> Labour Cess :</h5>

                                </div>
                                <div class="col-4">
                                    <h5><b> {{obj['labuor_cess']}}</b></h5>


                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h5> Total Payable :</h5>

                                </div>
                                <div class="col-4">
                                    <h5><b> {{obj['total_pay']+ obj['labuor_cess']}}</b></h5>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-8">

                                </div>
                                <div class="col-2 text-right">
                                    <h5> Total Deduction :</h5>

                                </div>
                                <div class="col-2">
                                    <h5><b> {{obj['total_ded_amount']}}</b></h5>


                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div style="padding-top: 8px;padding-bottom: 8px;width: 30%;" class="text-center border">

                                    <h6><b>Deduction</b></h6>

                                </div>
                                <div style="padding-top: 8px;padding-bottom: 8px;width: 30%;" class="text-center border">
                                    <h6><b>Deduction Desc</b></h6>


                                </div>
                                <div style="padding-top: 8px;padding-bottom: 8px;width: 20%;" class="text-center border">
                                    <h6><b> Deduction Amount</b></h6>


                                </div>


                                <div style="padding-top: 8px;padding-bottom: 8px;width: 10%;" class="text-center border">
                                    <h6><b>Action</b></h6>


                                </div>
                                <div style="width: 5%;" class="text-center">
                                    <button class="btn btn-primary" (click)="addDeductionRowInUpdate()"><i
                                            class="fa fa-plus" aria-hidden="true"></i></button>


                                </div>
                            </div>
                            <hr>
                            <div *ngFor="let ded of obj['deduction']; let i = index;">

                                <div class="row ">

                                    <div style="width: 30%;" class="text-left">
                                        {{dedObj[obj['deduction'][i]['deduction_id']]['ded_name']}}
                                        <!-- <ng-select [items]="dedTypeArr" placeholder="Select Deduction" bindLabel="ded_name" bindValue="deduction_id" [multiple]="false" [(ngModel)]="obj['deduction'][i]['deduction_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" (change)="changeDed(i)"
                                            [closeOnSelect]="true" name="field_name" awesome.appendTo="body">
                                        </ng-select> -->
                                    </div>
                                    <div *ngIf="ded['apply_on']==''" style="width: 30%;" class="text-left">
                                        FIXED AMOUNT
                                        <!-- <input class="form-control" [(ngModel)]="ded['ded_desc']" placeholder="Enter Deduction description"> -->

                                    </div>
                                    <div *ngIf="ded['apply_on']=='B'" style="width: 30%;" class="text-left">
                                        APPLIED ON BILL AMOUNT @ {{ded['rate']}}
                                        <!-- <input class="form-control" [(ngModel)]="ded['ded_desc']" placeholder="Enter Deduction description"> -->

                                    </div>
                                    <div *ngIf="ded['apply_on']=='SGST'" style="width: 30%;" class="text-left">
                                        APPLIED ON SGST AMOUNT @ {{ded['rate']}}
                                        <!-- <input class="form-control" [(ngModel)]="ded['ded_desc']" placeholder="Enter Deduction description"> -->

                                    </div>
                                    <div *ngIf="ded['apply_on']=='CGST'" style="width: 30%;" class="text-left">
                                        APPLIED ON CGST AMOUNT @ {{ded['rate']}}
                                        <!-- <input class="form-control" [(ngModel)]="ded['ded_desc']" placeholder="Enter Deduction description"> -->

                                    </div>
                                    <div *ngIf="ded['apply_on']=='L'" style="width: 30%;" class="text-left">
                                        APPLIED ON SNO. {{ded['sno']}} @ {{ded['rate']}}
                                        <!-- <input class="form-control" [(ngModel)]="ded['ded_desc']" placeholder="Enter Deduction description"> -->

                                    </div>
                                    <div style="width: 20%;" class="text-right">
                                        <!-- {{ded['amt']}} -->
                                        <input type="number" class="form-control" [(ngModel)]="ded['amt']" placeholder="Enter Amount Amount" (change)="changeDedAmt()">
                                        <div *ngIf="ded['amt'] == null || ded['amt'] == undefined" style="color: red;">*  Required Field</div>
                                    </div>

                                    <div style="width: 10%;" class="text-center">
                                        <!-- <button *ngIf="ded['apply_on']=='L'" class="btn btn-primary" (click)="addlineRow(i)"><i class="fa fa-plus"
                                                aria-hidden="true"></i></button> -->

                                        <button class="btn btn-danger" (click)="deleteDeduction(i)" *ngIf="ded['deduction_id']!='-1'"><i
                                                class="fa fa-trash" aria-hidden="true"></i></button>


                                    </div>

                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>With Held Amount :</h6>
                                </div>
                                <div class="col-4 text-right">
                                    <input class="form-control" type="number" (change)="changeupdate()" [(ngModel)]="obj['withheldamount']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>With Held Remark :</h6>
                                </div>
                                <div class="col-4 text-right">
                                    <textarea class="form-control" type="text" [(ngModel)]="obj['withheldremanrk']"></textarea>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Net Pay :</h6>
                                </div>
                                <div class="col-4 text-right">
                                    <h6>{{obj['net_pay']+ obj['labuor_cess']}}</h6>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Submit

                                    </button>
                                </div>
                            </div>
                            <br>
                        </div>
                        <!-- <div class="tab-pane fade " id="tab-7-4">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6> Select Project :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="filterService.filterObj['project']" bindLabel="desc"
                                        bindValue="code" [multiple]="false" placeholder="Select Project"
                                        [(ngModel)]="selectObj['project_cd']" (change)="changeWork()"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="project_cd">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Select work :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="listWork" bindLabel="desc" bindValue="id" [multiple]="false"
                                        placeholder="Select Work" [(ngModel)]="selectObj['work_order_no']"
                                        (change)="getpreviouspartrate()" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>


                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter( $any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                </div>
                                <div class="col-4 text-right">
                                    <button class="btn btn-primary" (click)="Addpreviouspartrate()">Add</button>
                                </div>
                            </div>


                            <div class=".mat-elevation-z8">

                                <table mat-table [dataSource]="datasource12" matSort #sortCol12="matSort">

                                    <ng-container matColumnDef="s_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>S.NO.
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.sno}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="line_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Line
                                            Description

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ this.boqObj[element.sno]['item_desc']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="eq">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Quantiy

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.quantity}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="epr">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Rate

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.rate}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Amount
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{(element.quantity)*(element.rate)}} </td>
                                    </ng-container>




                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-danger" (click)="deleteitem(element)">Delete</button>

                                            <button class="btn btn-info" (click)="open_updateprevious(element)">Update
                                            </button>&nbsp;
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns12"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns12;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator12 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>

                        </div> -->
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>
<div class="modal" id="x">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <table class="table table-bordered" style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                    <thead>
                        <tr style="background-color: #1589FF; color: white;">
                            <th style="width: 10%;">S No.</th>
                            <!-- <th style="width: 20%;">Level</th> -->
                            <th style="width: 10%;">Role</th>
                            <th style="width: 15%;">User</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ln of pending_apprarr;let i = index">
                            <td>{{i+1}}</td>
                            <!--  <td style="width: 20%;">
                                Level-{{ln['level_of_approval']}}
                            </td> -->
                            <td style="width: 10%;">
                                {{mainService.roleCodeToRoleDescription[ln['role_cd']]}}
                            </td>
                            <td style="width: 10%;" *ngIf="ln['user_id'] != -1">
                                {{ApprovalUserService.useridtoname[ln['user_id']]}}
                            </td>
                            <td style="width: 10%;" *ngIf="ln['user_id'] == -1">
                                {{this.partyObj[ln['vendor_id']]}} - VENDOR
                            </td>
                        </tr>

                    </tbody>

                </table>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false && ApprovalUserService.approvalflag==false">
                    <div class="col-4 text-right" >
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4" >
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr" [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark" placeholder="enter work order name" class="form-control"></textarea>

                    </div>


                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false && ApprovalUserService.approvalflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="rovokedapproval()">Submit

                        </button>
                    </div>
                </div>
                <br>
              
                <div *ngIf="ApprovalUserService.vendorflag==true && ApprovalUserService.approvalflag==false" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="saveapprpending()">Forward To
                            Vendor</button>
                    </div>
                </div>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false && ApprovalUserService.approvalflag==true">
                    <p style="color: red;margin-left: 2%;margin-right: 3%;">Note -: This is the last user with the last role of the workflow for this document. If you want to revoke this user so the document will be approved automatically. Please make sure what you are doing is correct
                    </p>
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)=" Approveswal()">Approve</button>

                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal fade right" id="Z" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel" aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">See Workflow Details</h5>
                <button type="button" class="close"data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">


                <table class="table table-bordered" style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                    <thead>
                        <tr style="background-color: #1589FF; color: white;">
                            <th style="width: 10%;">S No.</th>
                            <th style="width: 20%;">Levels</th>
                            <th style="width: 20%;">Branch</th>
                            <th style="width: 20%;">Branch Logical Condition</th>
                            <th style="width: 10%;">Role</th>
                            <th style="width: 15%;">User</th>
                            <th style="width: 10%;">Role Function</th>
                            <th>Vendor Approval Required</th>
                            <th style="width: 15%;">Condition</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ln of dtlworkflow;let i = index">
                            <td>{{i+1}}</td>
                            <td style="width: 20%;">
                                Level-{{ln['level']}}
                            </td>
                            <td style="width: 20%;">
                                Branch-{{ln['branch']}}
                            </td>
                            <td style="width: 20%;">
                                {{ln['branch_logical_condition']}}
                            </td>
                            <td style="width: 10%;">
                                {{ln['role_cd']}}
                                <!-- {{mainService.roleCodeToRoleDescription[ln['role_cd']]}} -->
                            </td>
                            <td style="width: 10%;">
                                {{ln['users']}}
                            </td>
                            <td style="width: 10%;">
                                {{ln['role_function']}}
                            </td>
                            <td style="width: 10%;">
                                {{idtovalue[ln['sent_to_vendor']]}}
                            </td>
                            <td style="width: 10%;">
                                {{ln['data_desc']}}
                            </td>
                        </tr>

                    </tbody>

                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"data-bs-dismiss="modal">Close</button>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">About This</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <p>
                    E-Bill is contingent bill created electronically for accruing liabilities arising from the engineering work performed by a vendor as per terms and conditions of the awarded tender and work order issued. </p>





            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Status</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">
                    <a *ngFor="let ob of statusArr;let i=index">
                        <div class="step completed" *ngIf="ob.status=='APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;" *ngIf="i <statusArr.length-1"></div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}

                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{i+1}} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success"data-bs-dismiss="modal" (click)="certificate()">Download
                        Certificate</button>

                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr" [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark" placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div>
                    <table class="table table-bordered" style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">
                                    <!--  <button class="btn btn-success" (click)="deleteLine(i)"><i class="fa fa-check"
                                        style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button>
                                <button class="btn btn-danger" (click)="copy(ln)">
                                     <i class="fa fa-times"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> 
                                           
                                        </button> -->
                                    <!-- <button class="btn btn-warning" (click)="paste(i)"> -->
                                    <!-- <i class="fas fa-paste"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->

                                    <!-- </button>  -->
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" style="border: black;">
                    <div class="col-4 text-right" >
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="Approvedapproval()">Submit

                        </button>
                    </div>
                </div>
                <br>
                <!-- <div *ngIf="vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <h6>OR</h6>
                    </div>
                </div> -->
                <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="ded">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Add Deduction</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-2">
                        <h6>Select Deduction :</h6>
                    </div>
                    <div class="col-5">
                        <ng-select [items]="dedTypeArr" placeholder="Select Deduction" bindLabel="ded_name" bindValue="deduction_id" [multiple]="false" [(ngModel)]="newDed['deduction_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" (change)="changeDed()" [closeOnSelect]="true"
                            name="field_name" awesome.appendTo="body">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div *ngIf="newDed['apply_on'] == 'L'" class="row">
                    <div class="col-2">
                        <h6>Select Item :</h6>
                    </div>
                    <div class="col-5">
                        <ng-select [items]="embData" placeholder="Select Item" bindLabel="line_desc" bindValue="sno" [multiple]="false" [(ngModel)]="newDed['sno']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name" awesome.appendTo="body">
                        </ng-select>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                    <button type="button" class="btn btn-success" (click)="saveDed()"data-bs-dismiss="modal">SAVE</button>
                </div>

            </div>
        </div>
    </div>
</div>


<div class="modal" id="ded1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Add Deduction</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-2">
                        <h6>Select Deduction :</h6>
                    </div>
                    <div class="col-5">
                        <ng-select [items]="dedTypeArr" placeholder="Select Deduction" bindLabel="ded_name" bindValue="deduction_id" [multiple]="false" [(ngModel)]="newDed['deduction_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" (change)="changeDed()" [closeOnSelect]="true"
                            name="field_name" awesome.appendTo="body">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div *ngIf="newDed['apply_on'] == 'L'" class="row">
                    <div class="col-2">
                        <h6>Select Item :</h6>
                    </div>
                    <div class="col-5">
                        <ng-select [items]="embData" placeholder="Select Item" bindLabel="line_desc" bindValue="sno" [multiple]="false" [(ngModel)]="newDed['sno']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name" awesome.appendTo="body">
                        </ng-select>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                    <button type="button" class="btn btn-success" (click)="saveDedInUpdate()"data-bs-dismiss="modal">SAVE</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="A1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Status</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-2">
                        <h6>Select Item</h6>
                    </div>
                    <div class="col-10">
                        <ng-select [items]="boqItems" bindLabel="item_desc" bindValue="sno" [multiple]="false" placeholder="Select Project" [(ngModel)]="selectedElement['sno']" (change)="changeitem()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="project_cd">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6>Item Desc :</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['item_desc']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6>Work Order Quantity :</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['eff_quantity']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6> Effective Rate :</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['eff_rate']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6>Enter Bill Quantity :</h6>
                    </div>
                    <div class="col-4">
                        <input type="number" class="form-control" [(ngModel)]="selectedElement['bill_quantity']" (change)="changeQuantity()">
                        <!-- {{selectedElement['less']}} -->
                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-2">
                        <h6>Enter Bill Rate :</h6>
                    </div>
                    <div class="col-4">
                        <input type="number" class="form-control" [(ngModel)]="selectedElement['bill_rate']" (change)="changerate()">
                        <!-- {{selectedElement['less']}} -->
                    </div>
                </div>
                <br> -
                <div class="row">
                    <div class="col-2">
                        <h6>Enter Amount:</h6>
                    </div>
                    <div class="col-4">
                        <input type="number" class="form-control" [(ngModel)]="selectedElement['bill_amt']" disabled>

                    </div>
                </div>
                <br>
                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                    <button type="button" class="btn btn-success" (click)="savepreviouspartrate()"data-bs-dismiss="modal">SAVE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="A2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Status</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-2">
                        <h6>S.NO.</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['sno']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6>Item Desc :</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['item_desc']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6>Work Order Quantity :</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['eff_quantity']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6> Effective Rate :</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['eff_rate']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6>Enter Bill Quantity :</h6>
                    </div>
                    <div class="col-4">
                        <input type="number" class="form-control" [(ngModel)]="selectedElement['bill_quantity']" (change)="changeQuantity()">
                        <!-- {{selectedElement['less']}} -->
                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-2">
                        <h6>Enter Bill Rate :</h6>
                    </div>
                    <div class="col-4">
                        <input type="number" class="form-control" [(ngModel)]="selectedElement['bill_rate']" (change)="changerate()">
                        <!-- {{selectedElement['less']}} -->
                    </div>
                </div>
                <br> -
                <div class="row">
                    <div class="col-2">
                        <h6>Enter Amount:</h6>
                    </div>
                    <div class="col-4">
                        <input type="number" class="form-control" [(ngModel)]="selectedElement['bill_amt']" disabled>

                    </div>
                </div>
                <br>
                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                    <button type="button" class="btn btn-success" (click)="updatepreviouspartrate()"data-bs-dismiss="modal">SAVE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Activity</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row" *ngFor="let item of planing_arr,let i =index">
                    <div class="col-3"></div>
                    <div class="col-3" *ngIf="i==0">
                        <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span style="color: red;"> *</span></h6>
                        <!-- Select Node : -->
                    </div>
                    <div class="col-3" *ngIf="i!=0"></div>
                    <div class="col-1">
                        
                        <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy17']}} </span>{{item['level']}}
                        <!-- Level -  -->
                    </div>
                    <div class="col-3">
                        <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc"
                            bindValue="node_cd" [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
                            [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                            (change)="setHierarchyNode(item,i)" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true">
                        </ng-select>
                        <!-- Select  Node -->
                    </div>
                    <div class="col-2" *ngIf="i==planing_arr.length-1">
                        <button (click)="down(item,i)" class="btn btn-primary"><i
                                class="fa fa-arrow-down" aria-hidden="true"></i></button>
                        &nbsp;&nbsp;
                        <button (click)="remove(item,i)" class="btn btn-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{obj['path_desc']}}
                    </div>
                </div>
                <br>

              
                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button  class="btn btn-primary" (click)="submit_act()">Submit</button>
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>