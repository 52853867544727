import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { LedgerService } from '../service/ledger.service';
import { MainService } from '../service/main.service';
import swal from 'sweetalert2';
import { SettingService } from '../service/setting.service'
import { BpService } from '../service/bp.service'
import { RuleService } from '../service/rule.service'
import  * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts"
import { BillService } from '../service/bill.service'
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { ChartAcctMapingServiceService } from '../service/chart-acct-maping-service.service';
import { UserAddService } from '../../../app/portal/service/user-add.service';

declare var $: any
@Component({
  selector: 'app-bp',
  templateUrl: './bp.component.html',
  styleUrls: ['./bp.component.css']
})
export class BpComponent implements OnInit {

  constructor(private userAddService: UserAddService, private chartAccMapingS: ChartAcctMapingServiceService, public mainService: MainService, private billService: BillService, private ruleService: RuleService, private BPS: BpService, private settingService: SettingService, private ledgerService: LedgerService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser:any;
  b_acct_id:any;
  search_obj :any= {};
  obj :any= {};

  AllPartyObj:any = {};
  allParty:any = [];
  allAccountInfo:any = [];
  AllAccountObj :any= {};
  allBankAccounts:any;
  show_selected_table:any = 0;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;

  headerBPDataView = [{ full_name: 'Party Id' }, { full_name: 'Party Name' }, { full_name: 'Party Bank Account Number' }, { full_name: 'Party Bank Code' }
    , { full_name: 'Party Branch Code' }, { full_name: 'Party IFSA Code' }, { full_name: 'AMOUNT' }]


  list:any = ['select_flag', 'id', 'party_id', 'party_name', 'Amount', 'bp_date', 'remark', 'status', 'action'];

  datasource:any;
  allChartOfAccount :any= [];
  BP_data:any = [];
  AllBpRow :any= [{ bp_id: '', bp_date: '', bp_desc: '', bp_status: '' }];
  popUpTableData:any = []
  systemDate:any;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') || "{}"));
    this.b_acct_id = this.erpUser.b_acct_id;
    var resp = await this.billService.getSystemDate();
    this.systemDate = resp.data;
    // await this.getUsersInfo();
    // await this.getAllApproveRule();

    await this.getBankAccount();

    // await this.getAllParties();
    await this.getrepresentative();
    await this.getparty();

    await this.getJVNumber();

    await this.getallChartOfAccount();

    await this.getList();

    await this.getChartOfAccountMappingList();
    await this.getActiveFinYear();
  }

  allJVId = [];
  async getJVNumber() {
    this.spinner.show();
    var resp = await this.ledgerService.getPayableJournalID(this.b_acct_id);
    if (resp['error'] == false) {
      this.allJVId = resp.data;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while sending the bill to approvel', 'error');
    }
  }

  async schedule(element:any) {
    console.log(element)
   var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['data'] = [];
    for (var i = 0; i < this.allApproval.length; i++) {
      if (this.allApproval[i]['doc_type'] == 'BP') {
        obj['data'].push({
          user_id: this.allApproval[i]['user_id'],
          level_of_approval: this.allApproval[i]['level_of_approval'],
          doc_type: this.allApproval[i]['doc_type'],
          create_user_id: this.erpUser.user_id,
          doc_local_no: element['id'],
          doc_local_desc: element['id'] + "-" + element['remark'],
          status: 'PENDING'
        })
      }
    }



    var resp = await this.billService.sendToApproval(obj);
    if (resp['error'] == false) {
      await this.changeBPStatus(element);
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while sending the bill to approvel', 'error');
    }
  }
  async changeBPStatus(element:any) {
    // this.spinner.show();
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = element.id
    obj['status'] = 'APPROVED';
    obj['update_user_id'] = this.erpUser.user_id;

    var arr = await this.assignValuesForBill(element);
    console.log(arr);

    var resp = await this.BPS.updateStatus(obj);
    if (resp['error'] == false) {
      await this.getList();
      this.spinner.hide();
      swal.fire("Success", "Update Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while updating bp !", 'error');
    }
  }
  statusArr = [];
  async status(element:any) {
   var obj:any = new Object();
    console.log(element);
    obj["b_acct_id"] = this.b_acct_id;
    obj["bill_id"] = element.id;
    console.log(obj)
    this.spinner.show();
    var resp = await this.billService.getDocumentStatus(obj);
    console.log(resp);
    if (resp['error'] == false) {
      this.statusArr = resp['data'];
      $('#myModal_Status').modal('show');
      this.spinner.hide()
    } else {
      this.spinner.hide();
      swal.fire("Error", "Error while getting status", 'error');
    }
  }
  allApproval:any = [];
  levelOfApproval:any = {};
  async getAllApproveRule() {
    this.spinner.show()
    var resp = await this.billService.getAllApproval(this.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allApproval = resp['data'];
      for (var i = 0; i < this.allApproval.length; i++) {
        if (this.allApproval[i]['doc_type'] == 'BP') {
          this.levelOfApproval[this.allApproval[i]['level_of_approval']] = this.allApproval[i];
        }
      }
    } else {
      this.spinner.hide()
    }
  }
  allUser:any = [];
  UserObj:any = {};
  async getUsersInfo() {
   var obj:any = { b_acct_id: this.b_acct_id };
    var resp :any= await this.userAddService.getAllUsersInfo(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.allUser = resp['data'];
      for (let i = 0; i < this.allUser.length; i++) {
        this.UserObj[this.allUser[i]['user_id']] = this.allUser[i]['first_name'] + " " + this.allUser[i]['last_name']
        this.allUser[i]['name'] = this.allUser[i]['first_name'] + " " + this.allUser[i]['last_name']
      }
    } else {

    }
  }

  //////********************************************************************** */
  fin_year:any

  async getActiveFinYear() {
   var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getActiveFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp.data.length == 0) {
        swal.fire("Warning", "..Active Financial Year Not set!", 'warning');
      } else {
        this.fin_year = resp.data[0].fin_year;
      }
    } else {
      swal.fire("Error", "..Error while getting active  fin year!", 'error');
    }
  }
  coaError=false;
  async searchByParty() {
    if (!this.search_obj['chart_of_account']) {
      this.spinner.hide();
      // swal.fire("ERROR", 'Please select Chart Of Account ', 'error');
      this.coaError=true;
      return;
    }
    this.popUpTableData = []
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['party_id'] = this.search_obj['party_id']
    obj['date'] = this.systemDate
    obj['chart_of_account'] = this.search_obj['chart_of_account'];
    console.log(obj)
    var resp = await this.BPS.getbpData(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        swal.fire('No Record Found', '', 'warning')
      }
      this.spinner.hide()
      // this.popUpTableData = resp['data']
      resp['data'].map((x:any) => {
        if (x['txn_amt'] > 0) {
          this.popUpTableData.push(x);
        }
      })
      for (let i = 0; i < this.popUpTableData.length; i++) {
        this.popUpTableData[i]['select'] = false
      }
      $('.bd-example-modal-lg').modal('show');
    }
    else {
      this.spinner.hide()
      swal.fire('Some error Occured', '', 'error')
    }

  }




  async searchByJVNumber() {




    this.spinner.show()
    this.popUpTableData = []
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['jrnl_id'] = this.search_obj['jrnl_id']
    obj['date'] = this.systemDate
    obj['chart_of_account'] = this.search_obj['chart_of_account'];
    console.log(obj)
    // if (!this.search_obj['chart_of_account']) {
    //   swal.fire('EROOR', 'Please Select Chart Of Account', 'error');
    //   this.spinner.hide();
    //   return;
    // }
    // if (!this.search_obj['jrnl_id']) {
    //   swal.fire('EROOR', 'Please Select JV Number', 'error');
    //   this.spinner.hide();
    //   return;
    // }
    var resp = await this.BPS.getbpData(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        swal.fire('No Record Found', '', 'warning')
      }
      this.spinner.hide()
      // this.popUpTableData = resp['data']

      resp['data'].map((x:any) => {
        if (x['txn_amt'] > 0) {
          this.popUpTableData.push(x);
        }
      })

      for (let i = 0; i < this.popUpTableData.length; i++) {
        this.popUpTableData[i]['select'] = false
      }
      $('.bd-example-modal-lg').modal('show');
    }
    else {
      this.spinner.hide()
      swal.fire('Some error Occured', '', 'error')
    }

  }
  org_short_name:any
  async assignValuesForBill(event1:any) {
    console.log(event1)
    var event = JSON.parse(event1['data'])
    console.log(event)
    var arr1 = []

    let ob_db = new Object();
    ob_db = {
      "arr_id": event['arr_id'],
      "acct_dt": this.systemDate,
      "appr_id": this.erpUser.user_id,
      "ar_src_cd": "ACCOUNT",
      "ar_num_in_src": null,
      "ar_actvty_tcd": "BP",
      "ar_actvty_lcl_num": event1['id'],
      "ar_instr_cd": null,
      "ar_cntr_ip_id": event['party_id'],
      "ar_cntr_ip_name": this.party[event['party_id']],
      "create_timestamp": this.systemDate,
      "chart_of_account": event['chart_of_account'],
      "cp_bnk_acct_id": 0,
      "db_cd_ind": "DB",
      "event_code": event['event_code'],
      "event_id": event['event_id'],
      "ev_cts": event['bp_date'],
      "fin_year": this.fin_year,
      "jrnl_dtl_ln_id": "1",
      "jrnl_ln_id": 1,
      "jrnl_id": event['jrnl_id'],
      "jrnl_desc": 'To ' + this.allChartOfAccountObj[event['bank_chart_of_account']],
      "ledger_type": "ACT",
      "ld_jrnl_ref_num": event['ld_jrnl_ref_num'],
      "ld_jrnl_lfcycl_st_cd": "POSTED",
      "ld_jrnl_unpost_seq_num": 0,
      "ld_fm": 11,
      "ld_acct_per": 328,
      "ld_cat_cd": "FIN",
      "ld_jrnl_entry_cd": "Auto",
      "ld_tgt_cur_tcd": "FN",
      "ld_jrnl_rvrsl_tcd": "I",
      "ld_jrnl_rvrsl_dt": null,
      "ld_parent_jrnl_id": null,
      "ld_jrnl_ln_ksn": event['jrnl_id'] + "-DB",
      "ld_jrnl_ln_desc": event['jrnl_desc'],
      "ld_bk_cd": null,
      "ld_src_cd": "ACCOUNT",
      "ld_pd_cd": event['ld_pd_cd'],
      "ld_prj_cd": event['ld_prj_cd'],
      "ld_jrnl_dtl_ln_ksn": "_" + 1 + "_0",
      "ld_tech_comp_cd": "ARE",
      "ld_jrnl_dtln_desc": event['jrnl_desc'],
      "ld_bus_func_cd": "JV2BP",
      "ld_unadj_acct_dt": this.systemDate,
      "org_unit_cd": this.b_acct_id,
      "proc_dt": null,
      "prep_id": this.erpUser.user_id,
      "pmt_bnk_acct_id": 0,
      "tgt_curr_cd": "INR",
      "txn_amt": (-1) * event['txn_amt'],
      "ld_cc_cd": event['ld_cc_cd'],
      "ld_aff_cc_cd": "0",
      "ld_bugt_cd": event['ld_bugt_cd']
    }

    let ob_cr = new Object();
    ob_cr = {
      "arr_id": event['arr_id'],
      "acct_dt": this.systemDate,
      "appr_id": this.erpUser.user_id,
      "ar_src_cd": "ACCOUNT",
      "ar_num_in_src": null,
      "ar_actvty_tcd": "BP",
      "ar_actvty_lcl_num": event1['id'],
      "ar_instr_cd": null,
      "ar_cntr_ip_id": event['party_id'],
      "ar_cntr_ip_name": this.party[event['party_id']],
      "create_timestamp": this.systemDate,
      "chart_of_account": event['bank_chart_of_account'],
      // "cp_bnk_acct_id": event['org_bank_acct_no'],
      "cp_bnk_acct_id": event['relation'],
      "db_cd_ind": "CR",
      "event_code": event['event_code'],
      "event_id": event['event_id'],
      "ev_cts": event['bp_date'],
      "fin_year": this.fin_year,
      "jrnl_dtl_ln_id": "1",
      "jrnl_ln_id": 1,
      "jrnl_id": event['jrnl_id'],
      "jrnl_desc": 'By ' + this.allChartOfAccountObj[event['bank_chart_of_account']],
      "ledger_type": "ACT",
      "ld_jrnl_ref_num": event['ld_jrnl_ref_num'],
      "ld_jrnl_lfcycl_st_cd": "POSTED",
      "ld_jrnl_unpost_seq_num": 0,
      "ld_fm": 11,
      "ld_acct_per": 328,
      "ld_cat_cd": "FIN",
      "ld_jrnl_entry_cd": "Auto",
      "ld_tgt_cur_tcd": "FN",
      "ld_jrnl_rvrsl_tcd": "I",
      "ld_jrnl_rvrsl_dt": null,
      "ld_parent_jrnl_id": null,
      "ld_jrnl_ln_ksn": event['jrnl_id'] + "-CR",
      "ld_jrnl_ln_desc": event['jrnl_desc'],
      "ld_bk_cd": null,
      "ld_src_cd": "ACCOUNT",
      "ld_pd_cd": event['ld_pd_cd'],
      "ld_prj_cd": event['ld_prj_cd'],
      "ld_jrnl_dtl_ln_ksn": "_" + 1 + "_0",
      "ld_tech_comp_cd": "ARE",
      "ld_jrnl_dtln_desc": event['jrnl_desc'],
      "ld_bus_func_cd": "JV2BP",
      "ld_unadj_acct_dt": this.systemDate,
      "org_unit_cd": this.b_acct_id,
      "proc_dt": null,
      "prep_id": this.erpUser.user_id,
      "pmt_bnk_acct_id": 0,
      "tgt_curr_cd": "INR",
      "txn_amt": event['txn_amt'],
      "ld_cc_cd": event['ld_cc_cd'],
      "ld_aff_cc_cd": "0",
      "ld_bugt_cd": event['ld_bugt_cd']
    }

    console.log(ob_cr);
    console.log(ob_db);
    arr1.push(ob_cr)
    arr1.push(ob_db)

    var obj1:any = {}
    obj1['b_acct_id'] = this.b_acct_id;
    obj1['jrnl'] = arr1;
    var resp = await this.BPS.insertProcessedbp(obj1);
    if (resp['error'] == false) {
      this.spinner.hide();
      swal.fire("Success", "Processed Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while Processed  !", 'error');

    }
    return arr1;
  }
  checked_this(d:any) {
    let index = Number(d.target.value)
    if (d.target.checked) {
      this.selected_list.push(this.popUpTableData[index])
    }
    else {
      var i = 0;
      while (i < this.selected_list.length) {
        if (this.selected_list[i] === Number(d.target.value)) {
          this.selected_list.splice(i, 1);
        } else {
          ++i;
        }
      }
    }

  }
  async selected_table() { //click on save changes
    swal.fire('Items Selected Successfully', '', 'success')
    this.show_selected_table = 1
    let arr:any = []
    let projBankObj :any= {};
    for (let i = 0; i < this.popUpTableData.length; i++) {

      if (this.popUpTableData[i]['select'] == true) {
        this.popUpTableData[i]['bp_date'] = this.systemDate
        let banks = await this.getBank(this.popUpTableData[i]['proj_cd']);
        if (banks.length > 0) {
          // swal.fire('ERROR', 'Please Map Project Bank ', 'error');
          // return;
          projBankObj[this.popUpTableData[i]['proj_cd']] = banks;
        }

        arr.push(this.popUpTableData[i]);

      }
    }
    let final_arr:any = [];
    console.log("Bank Mapped on this Project : ", projBankObj)
    arr.map((x :any)=> {
      // if (x['proj_cd'] == projBankObj[x['proj_cd']][0]['project_code']) {
      if (projBankObj[x['proj_cd']] != undefined) {
        let bk = projBankObj[x['proj_cd']][0];
        console.log(bk)
        x['org_bank_acct_no'] = this.Bankdetail[bk['bank_acct_no']];
        x['old_txn_amt'] = x['txn_amt'];
        x['desc'] = this.Bankdetail[bk['bank_acct_no']]['desc'];
        x['relation'] =  bk['bank_acct_no'];

        x['desabled'] = true;
      } else {
        x['desabled'] = false;
      }
      final_arr.push(x)
    })


    this.selected_list = final_arr;
    console.log(this.selected_list)
  }

  async getBank(proc_cd:any) {
    let obj:any = {}
    obj['proj_cd'] = proc_cd;
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj);
    let resp = await this.BPS.projectMappedBank(obj);

    return resp.data;
  }


  selected_list:any = []
  select() {

  }


  Bankdetail :any= {};
  // ChartOfAccountToAccountObj = {};
  BankChartOfAccount = []
  async getChartOfAccountMappingList() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.chartAccMapingS.getRelationList(JSON.stringify(obj));
    if (resp['error'] == false) {
      // this.ChartOfAccountToAccountObj = {};
      this.Bankdetail = {};
      for (let i = 0; i < resp['data'].length; i++) {
        this.Bankdetail[resp.data[i]['relation']] = resp.data[i]
        console.log(this.allBankAccounts)
        for (let j = 0; j < this.allChartOfAccount.length; j++) {
          if (resp['data'][i]['chart_of_account'] == this.allChartOfAccount[j]['leaf_code']) {
            // resp['data'][i]['chart_of_account_desc'] = this.allChartOfAccount[j]['leaf_value']
            // resp['data'][i]['chart_of_account_code'] = resp['data'][i]['chart_of_account'];
            // resp['data'][i]['desc'] = resp['data'][i]['bank_code'] + "-" + resp['data'][i]['bank_acct_no']/* this.bankObj[resp['data'][i]['relation']]['bank_acct_no'];*/
            // this.ChartOfAccountToAccountObj[resp['data'][i]['chart_of_account_code']] = this.bankObj[resp['data'][i]['relation']]['bank_acct_no']
            if (resp['data'][i]['type'] == 'CASH') {
              resp['data'][i]['desc'] = resp['data'][i]['type']
            } else {
              resp['data'][i]['desc'] = this.bankObj[resp['data'][i]['relation']]['bank_code'] + "-" + this.bankObj[resp['data'][i]['relation']]['bank_acct_no']
            }
          }
        }
      }

      this.BankChartOfAccount = resp.data;
      if (this.BankChartOfAccount.length == 0) {
        this.spinner.hide();
        swal.fire("ERROR", "Please Map Bank With Chart Of Account ", "error");
        return;
      }
      console.log(this.BankChartOfAccount)
      this.spinner.hide();


    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  list!", 'error');
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  refresh() {

  }

  bankObj :any= { 0: { bank_acct_no: 0, id: 0 } };
  async getBankAccount() {
    var resp = await this.settingService.getBankAccounts(this.b_acct_id);
    if (resp['error'] == false) {
      this.allBankAccounts = resp.data;
      this.bankObj = { 0: { bank_acct_no: 0, id: 0 } };

      for (var i = 0; i < resp['data'].length; i++) {
        this.bankObj[resp.data[i]['id']] = resp.data[i];
        this.allBankAccounts[i]['bank_acct_desc'] = resp['data'][i]['bank_acct_desc'] + ' - ' + resp['data'][i]['bank_acct_no'] + ' - ' + resp['data'][i]['bank_code'] + ' - ' + resp['data'][i]['ifsc_code']
      }
      this.spinner.hide();

    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  all fields list!", 'error');
    }
  }
  async submit() {
    let flage = false;
    for (let i = 0; i < this.selected_list.length; i++) {
      this.selected_list[i]['bank_chart_of_account'] = this.Bankdetail[this.selected_list[i]['relation']]['chart_of_account']
      if (this.selected_list[i]['txn_amt'] > this.selected_list[i]['old_txn_amt']) {
        flage = true;
        break;
      }
      if (this.selected_list[i]['chart_of_account'] == undefined) {
        swal.fire('ERROR', 'Please Select Chart Of Account', 'error');
        return;
      }
    }
    if (flage) {
      swal.fire('ERROR', 'Amount Should Be Equal To Or Less Than Outstanding Amount', 'error');
      return;
    }

    this.spinner.show();
    let obj:any = {}
    obj = this.search_obj
    obj['b_acct_id'] = this.b_acct_id
    obj['create_user_id'] = this.erpUser.user_id

    // if (this.Bankdetail[0] == undefined) {
    //   swal.fire('ERROR', 'Select Bank Account do not contain any chart of Account', 'error');
    //   this.spinner.hide();
    //   return;
    // }

    // this.selected_list.map(x => {
    //   if (x['org_bank_acct_no'] != 0) {
    //     x['bank_chart_of_account'] = this.Bankdetail[x['org_bank_acct_no']]['chart_of_account']
    //   } else {
    //     if (this.Bankdetail[0] == undefined) {
    //       swal.fire('ERROR', 'Select Bank Account do not contain any chart of Account', 'error');
    //       this.spinner.hide();
    //       return;
    //     } else {
    //       x['bank_chart_of_account'] = this.Bankdetail[x['org_bank_acct_no']]['chart_of_account']
    //     }
    //   }
    // })


    obj['data'] = this.selected_list
    obj['status'] = 'GENERATED'

    var resp = await this.BPS.create(obj)
    if (resp['error'] == false) {
      this.spinner.hide()
      swal.fire('Successfully Submitted', '', 'success')
      await this.getList();
      this.show_selected_table = 0
    }
    else {
      this.spinner.hide()
      swal.fire("Error", "...Error while getting  all party list!", 'error');
    }
  }

  //********************************* */
  reperesentative:any = {}
  async getrepresentative() {
    var resp = await this.settingService.getAllrepresentative(JSON.stringify({ b_acct_id: this.b_acct_id }));
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        this.reperesentative[resp['data'][i]['rep_le_id']] = resp['data'][i]['party_leagal_name']

      }
    }
  }
  all_party :any= []
  async getparty() {
    var resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['party_type'] == 'ORG') {
          resp['data'][i]['party_leagal_name'] = resp['data'][i]['party_leagal_name'] + ' ( ' + this.reperesentative[resp['data'][i]['rep_le_id']] + ')'
        }
        this.party[resp['data'][i]['le_id']] = resp['data'][i]['party_leagal_name']
      }
      this.all_party = [{ le_id: 0, party_leagal_name: 'All' }];
      resp['data'].map((x:any) => {
        this.all_party.push(x);
      });
      this.obj['party_type']
    }
  }
  party:any = {}
  // async getAllParties() {
  //  var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   var resp = await this.settingService.getPartyInfoNew(this.b_acct_id);
  //   if (resp['error'] == false) {

  //     this.allParty = resp.data;
  //     for (let i = 0; i < this.allParty.length; i++) {
  //       this.party[this.allParty[i]['party_id']] = this.allParty[i]['party_name']
  //     }
  //     this.spinner.hide();

  //   } else {
  //     this.spinner.hide();
  //     swal.fire("Error", "...Error while getting  all party list!", 'error');

  //   }
  // }
  async delete(s:any) {
    this.spinner.show()
    let obj:any = {}
    obj['id'] = s['id']
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.BPS.delete(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      swal.fire('Deleted Successfully', '', 'success')
      await this.getList();
    }
    else {
      swal.fire("Error", "...Error while getting  all party list!", 'error');
      this.spinner.hide()
    }

  }
  table_data :any= []
  async getList() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.BPS.getList(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.table_data = resp['data'];
      for (let i = 0; i < this.table_data.length; i++) {
        this.table_data[i]['select_flag'] = false;
      }
      this.datasource = new MatTableDataSource(this.table_data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();

    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  all party list!", 'error');

    }
  }

  allChartOfAccountObj:any = {};
  async getallChartOfAccount() {
    this.spinner.show()
   var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ruleService.getchartofaccount(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allChartOfAccount = resp.data;
      this.allChartOfAccountObj = {};
      this.allChartOfAccount.map((x :any)=> {
        this.allChartOfAccountObj[x['leaf_code']] = x['leaf_value']
      })

      console.log(this.allChartOfAccountObj)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all chart of account info", 'Error', {
        duration: 5000
      });
    }
  }



  async print1(e:any) {

    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var arr = []
       var obj:any = { text: 'Bank Payment', alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        var obj1 = { text: "Bank Payment", alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj1);


        return arr;
      },

      pageOrientation: 'landscape',

      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };
    var header1 = {
      columns: [
        {

          width: '*',
          text: 'Party Name:',
          bold: true
        },
        {
          width: '*',
          text: this.party[e.party_id]
        },
        {

          width: '*',
          text: 'Party ID',
          bold: true
        },
        {

          width: '*',
          text: e['party_id']
        }
      ],

    }
    var header2 = {
      columns: [
        {
          width: '*',
          text: 'Amount',
          bold: true
        },
        {
          width: '*',
          text: e['bp_amount']
        },
        {

          width: '*',
          text: 'Chart Of Account :',
          bold: true
        },
        {
          width: '*',
          text: e['chart_of_account']
        }
      ],

    }
    var header3 = {
      columns: [
        {
          width: '*',
          text: 'Remark:',
          bold: true
        },
        {
          width: '*',
          text: e['remark']
        },
        {

          width: '*',
          text: 'Created User ID',
          bold: true
        },
        {
          width: '*',
          text: e['create_user_id']
        }
      ],

    }
    var header4 = {
      columns: [
        {

          width: '*',
          text: 'BP Date:',
          bold: true
        },
        {

          width: '*',
          text: this.mainService.dateFormatChange(e['bp_date'])
        },
        {

          width: '*',
          text: 'Status',
          bold: true
        },
        {
          width: '*',
          text: e['status']
        }

      ],

    }



    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header2);
    dd.content.push({ text: " " });
    dd.content.push(header3);
    dd.content.push({ text: " " });
    dd.content.push(header4);
    dd.content.push({ text: " " });

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] });



    pdfMake.createPdf(dd).download('detail.pdf');

  }
  remark:any;
  addRemark() {
    $('#myModal').modal('show');
  }
  async GenerateAdvice() {
    this.spinner.show();
    var arr = [];
    var ids = []
    var amt = 0
    for (let i = 0; i < this.table_data.length; i++) {
      if (this.table_data[i]['select_flag'] == true && this.table_data[i]['status'] == 'APPROVED') {
        arr.push(this.table_data[i]);
        ids.push(this.table_data[i]['id'])
        amt = amt + this.table_data[i]['bp_amount']
      }
    }
    if (arr.length == 0) {
      this.spinner.hide();
      $('#myModal').modal('hide');
      swal.fire('Selected Bank Payment Pending.....! ', '', 'warning');
      return;
    }

    var coa = []
    for (let i = 0; i < arr.length; i++) {
      var temp = JSON.parse(arr[i]['data']);
      coa.push(temp['org_bank_acct_no'])
    }
    var unique = coa.filter((v, i, a) => a.indexOf(v) === i);
    if (unique.length != 1) {
      this.spinner.hide();
      $('#myModal').modal('hide');
      swal.fire('Selected Bank Payment More Then one Bank Account.....! ', '', 'warning');
      return;
    }

   var obj:any = new Object();
    obj['id'] = ids.join(",");
    obj['b_acct_id'] = this.b_acct_id;
    obj['update_user_id'] = this.erpUser.user_id;
    obj['status'] = 'ADVICEGENERATED';
    var resp = await this.BPS.updateStatus(obj)
    if (resp['error'] == false) {
      await this.addAdvice(ids, amt);
      await this.getList();
      this.spinner.hide();
      $('#myModal').modal('hide');
      swal.fire('Successfully Submitted', '', 'success')
    }
    else {
      this.spinner.hide()
    }



  }

  async addAdvice(ids:any, amt:any) {
   var obj:any = new Object();
    obj['bpid'] = ids.join(",");
    obj['amount'] = amt
    obj['b_acct_id'] = this.b_acct_id;
    obj['create_user_id'] = this.erpUser.user_id;
    obj['status'] = 'PENDING';
    obj['remark'] = this.remark;
    var resp = await this.BPS.createAdvice(obj);
    if (resp['error'] == false) {
    }
    else {
    }
  }


}
