import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../../authentication.service';
import { MainService } from '../../service/main.service';
import { MainService as portal_mainService } from '../../../portal/service/main.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileService } from '../../../portal/service/profile.service';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';



@Component({
  selector: '[app-property-header]',
  templateUrl: './property-header.component.html',
  styleUrls: ['./property-header.component.css']
})
export class PropertyHeaderComponent implements AfterViewInit {

  constructor(private router: Router, public profileService: ProfileService, public portal_main: portal_mainService,
    public mainService: MainService, public auth: AuthenticationService) { }
  @ViewChild('ngSelectElement') ngSelectElement!: NgSelectComponent;
  isDropdownOpen = false;

  placeholderText: any
  selectedAction: any
  action = [
    { id: 1, name: 'Profile', route: "profile" },
    { id: 2, name: 'Logout', route: "login" },
  ];
  erpUser: any;
  home() {
    this.router.navigate(['/index'])
  }
  accInfo = {
    account_name: 'ABC'
  }
  message_count = 0;
  async ngOnInit() {
    console.log(this.auth.allLanguage, 'allLanguage')
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    await this.portal_main.getUserImage(this.erpUser['user_id']);
    await this.portal_main.getAccImage(this.erpUser['b_acct_id']);
    await this.portal_main.getAccountInfo(this.erpUser['b_acct_id']);
    await this.portal_main.getHeaderInfo(this.erpUser['b_acct_id']);
    let message = await this.portal_main.getMessageCount(this.erpUser['b_acct_id'], this.erpUser['user_id']);
    this.message_count = message.length;
    this.placeholderText = this.erpUser['party_name']

  }

  ngAfterViewInit() {
  }

  onSelectionChange2() {
    const selectedVal = this.selectedAction.route
    this.router.navigate([selectedVal]);
  }
  toggleSelect() {

    if (this.isDropdownOpen) {
      this.ngSelectElement.close();
    } else {
      this.ngSelectElement.open();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

}
