import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class NewEngService {

  httpUrl;
  // sorStd;
  costing_rate_std = "UPPWD-SOR";
  screen='SOR';
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl2;
    
  }


  async createfieldsmapping(obj:any) {
    const resp = this.http.post<any>(this.httpUrl + '/depricated/admistration/master_data/createChapterField', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async getallChapterField(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/depricated/admistration/master_data/getallChapterField' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async getChapterField(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/depricated/admistration/master_data/getChapterField' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async updateChapterField(obj:any) {
    const resp = this.http.put<any>(this.httpUrl + '/depricated/admistration/master_data/updateChapterField', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async fetch(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/depricated/admistration/sor_items/getSorItems' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async createsoritem(obj:any) {
    console.log(obj)
    const resp = this.http.post<any>(this.httpUrl + '/depricated/admistration/sor_items/createSorItems', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async updatesoritem(obj:any) {
    const resp = this.http.put<any>(this.httpUrl + '/depricated/admistration/sor_items/updateSorItems', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  /***********************************************Create Estimate******************************* */
  async getAllSorItems(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/depricated/admistration/sor_items/getAllSorItems' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }


}
