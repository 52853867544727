import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { VmsDashboardService } from '../service/vms-dashboard.service';

@Component({
  selector: 'app-vms-dashboard',
  templateUrl: './vms-dashboard.component.html',
  styleUrls: ['./vms-dashboard.component.css']
})
export class VmsDashboardComponent implements OnInit {

  constructor( private spinner: NgxSpinnerService, private dashboardService : VmsDashboardService ) {}

   erpUser:any
   b_acct_id:any
  ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.getAllData()
  }

  totalVendor:any
  totalWork:any
  async getAllData(){
    this.spinner.show()
    let obj :any= {};
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.dashboardService.getAllCount(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.totalVendor = resp['data'][0][0]['total_vendor']
      this.totalWork = resp['data'][1][0]['total_work']
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }

  }




}
