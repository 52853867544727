<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">GST Report</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i> GST Output Report</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                GST Input Report</a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <div>
                                <form name="form" (ngSubmit)="f.form.valid && getreportoutput()" #f="ngForm">
                                    <div class="row" style="margin-left: 2%;margin-right: 2;">
                                        <div class="col-4 text-left">
                                            <h6>From Date : <span style="color: red;">*</span> </h6>
                                        </div>
                                        <div class="col-4">
                                            <input type="date" class="form-control" [(ngModel)]="obj['from_dt']"
                                                name="from_dt" #from_dt="ngModel"
                                                [ngClass]="{ 'is-invalid': f.submitted && from_dt.invalid }" required>

                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.border_green]="from_dt.dirty && from_dt.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f.submitted && from_dt.invalid" class="error-message">
                                                <div *ngIf="from_dt.errors?.['required']">Please Fill From Date </div>
                                            </div>

                                            <!--------------------------------------------- validation End ------------------- -->

                                        </div>
                                    </div><br>
                                    <div class="row" style="margin-left: 2%;margin-right: 2;">
                                        <div class="col-4 text-left">
                                            <h6>To Date : <span style="color: red;">*</span></h6>
                                        </div>
                                        <div class="col-4">
                                            <input type="date" class="form-control" [(ngModel)]="obj['to_dt']"
                                                name="to_dt" #to_dt="ngModel"
                                                [ngClass]="{ 'is-invalid': f.submitted && to_dt.invalid }" required>

                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.border_green]="to_dt.dirty && to_dt.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f.submitted && to_dt.invalid" class="error-message">
                                                <div *ngIf="to_dt.errors?.['required']">Please Fill To Date </div>
                                            </div>

                                            <!-- If from date is greater than to date  -->
                                            <div *ngIf="from_dt.value > to_dt.value" class="error-message">
                                                Please Select Correct Date
                                            </div>
                                            <!--------------------------------------------- validation End ------------------- -->

                                        </div>
                                    </div>
                                    <br>
                                    <div class="row" style="margin-left: 2%;margin-right: 2;">
                                        <div class="col-4 text-left">
                                            <h6>Select Party :</h6>
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="all_party" bindLabel="party_leagal_name"
                                                bindValue="le_id" [multiple]="false" placeholder="Select Party"
                                                [(ngModel)]="obj['le_id']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="le_id"
                                                #le_id="ngModel">
                                            </ng-select>

                                            <!-- border color green -->
                                            <div [class.selection-box]="le_id.dirty && le_id.valid">
                                            </div>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-6 text-right">
                                            <button class="btn btn-primary" type="submit">Submit</button>
                                            <button class="btn btn-success d-none" id="output" type="reset">Reset</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <br>
                            <hr>

                            <div class="row">

                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" (click)="print()">Print</button>
                                    &nbsp;&nbsp;

                                    <button class="btn btn-success" (click)="export()">Export</button>
                                    &nbsp;&nbsp;
                                </div>


                            </div>

                            <br>
                            <div id="e">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>{{acount_name}}</h6>
                                    </div>

                                </div>
                                <br>

                                <div class="row">

                                    <div class="col-2 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-2" *ngIf="obj['from_dt']">{{
                                        mainService.dateFormatChange(obj['from_dt'])}}</div>
                                    <div class="col-2 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-2" *ngIf="obj['to_dt']">
                                        {{mainService.dateFormatChange(obj['to_dt'])}}</div>
                                </div>
                                <br>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>SNO</th>
                                            <th>GSTIN</th>
                                            <!-- <th>Account Code</th> -->
                                            <th>Party Name</th>
                                            <!-- <th>Rate of GST</th> -->
                                            <th>Taxable Amount</th>
                                            <th>CGST</th>
                                            <th>SGST</th>
                                            <th>IGST</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of reportdata; let i= index">
                                            <td>{{i+1}}</td>
                                            <td>{{item['gstin_no']}}</td>
                                            <!-- <td>{{obj['c_acc']}}</td> -->
                                            <td>{{item['party_name']}}</td>
                                            <!-- <td>{{item['gst_rate']}}</td> -->
                                            <td>{{item['amount']}}</td>
                                            <td style="text-align:right">{{item['cgst']}}</td>
                                            <td style="text-align:right">{{item['sgst']}}</td>
                                            <td style="text-align:right">{{item['igst']}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div>
                                <form name="form" (ngSubmit)="f2.form.valid && getGstInputReport()" #f2="ngForm">
                                    <div class="row" style="margin-left: 2%;margin-right: 2;">
                                        <div class="col-4 text-left">
                                            <h6>From Date :</h6>
                                        </div>
                                        <div class="col-4">
                                            <input type="date" class="form-control" [(ngModel)]="obj['from_dt']"
                                                name="from_dt" #fromDT="ngModel"
                                                [ngClass]="{ 'is-invalid': f2.submitted && fromDT.invalid }" required>
                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.border_green]="fromDT.dirty && fromDT.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f2.submitted && fromDT.invalid" class="error-message">
                                                <div *ngIf="fromDT.errors?.['required']">Please Fill From Date </div>
                                            </div>

                                            <!--------------------------------------------- validation End ------------------- -->

                                        </div>
                                    </div><br>
                                    <div class="row" style="margin-left: 2%;margin-right: 2;">
                                        <div class="col-4 text-left">
                                            <h6>To Date :</h6>
                                        </div>
                                        <div class="col-4">
                                            <input type="date" class="form-control" [(ngModel)]="obj['to_dt']"
                                                name="toDT" #toDT="ngModel"
                                                [ngClass]="{ 'is-invalid': f2.submitted && toDT.invalid }" required>




                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.border_green]="toDT.dirty && toDT.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f2.submitted && toDT.invalid" class="error-message">
                                                <div *ngIf="toDT.errors?.['required']">Please Fill To Date </div>
                                            </div>

                                              <!-- If from date is greater than to date  -->
                                              <div *ngIf="fromDT.value > toDT.value" class="error-message">
                                                Please Fill To Date
                                            </div>
                                            <!--------------------------------------------- validation End ------------------- -->


                                        </div>
                                    </div>
                                    <br>
                                    <div class="row" style="margin-left: 2%;margin-right: 2;">
                                        <div class="col-4 text-left">
                                            <h6>Select Party :</h6>
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="all_party" bindLabel="party_leagal_name"
                                                bindValue="le_id" [multiple]="false" placeholder="Select Party"
                                                [(ngModel)]="obj['le_id']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="select"
                                                #select="ngModel">
                                            </ng-select>

                                            <!--------------------------------------------- validation Start ------------------- -->

                                            <!-- border color green -->
                                            <div [class.selection-box]="select.dirty && select.valid">
                                            </div>
                                            <!--------------------------------------------- validation End ------------------- -->

                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-6 text-right">
                                            <!-- <button class="btn btn-primary" (click)="getGstInputReport()">Submit</button> -->
                                            <button class="btn btn-primary" type="submit"  >Submit</button>
                                            <button class="btn btn-success d-none" type="reset" id="output2" >Reset</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <br>
                            <hr>

                            <div class="row">

                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" (click)="print1()">Print</button> &nbsp;&nbsp;

                                    <button class="btn btn-success" (click)="export1()">Export</button> &nbsp;&nbsp;
                                </div>


                            </div>

                            <br>
                            <div id="e">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>{{acount_name}}</h6>
                                    </div>

                                </div>
                                <br>

                                <div class="row">

                                    <div class="col-2 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-2" *ngIf="obj['from_dt']">
                                        {{mainService.dateFormatChange(obj['from_dt'])}}</div>
                                    <div class="col-2 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-2" *ngIf="obj['to_dt']">
                                        {{mainService.dateFormatChange(obj['to_dt'])}}</div>

                                </div>
                                <br>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>SNO</th>
                                            <th>GSTIN</th>
                                            <!-- <th>Account Code</th> -->
                                            <th>Party Name</th>
                                            <!-- <th>Rate of GST</th> -->
                                            <th>Taxable Amount</th>
                                            <th>CGST</th>
                                            <th>SGST</th>
                                            <th>IGST</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of reportdata; let i= index">
                                            <td>{{i+1}}</td>
                                            <td>{{item['gstin_no']}}</td>

                                            <!-- <td>{{obj['c_acc']}}</td> -->
                                            <td>{{item['party_name']}}</td>
                                            <!-- <td>{{item['gst_rate']}}</td> -->
                                            <td>{{item['amount']}}</td>
                                            <td style="text-align:right">{{item['cgst']}}</td>
                                            <td style="text-align:right">{{item['sgst']}}</td>
                                            <td style="text-align:right">{{item['igst']}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
