import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
// import * as CanvasJS from '../../../assets/js/scripts/canvasjs.min';
// import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
// import { Label } from 'ng2-charts';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MainService } from '../service/main.service';
import { AllEmpService } from '../service/all-emp.service'
import { EstablishmentService } from '../service/establishment.service'
import { DashboardService } from '../service/dashboard.service'
import { SalaryHoldAndStartService } from '../service/salary-hold-and-start.service';
import { DataAccessService } from '../service/data-access.service';
import { from } from 'rxjs';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-hrms-dash',
  templateUrl: './hrms-dash.component.html',
  styleUrls: ['./hrms-dash.component.css']
})
export class HrmsDashComponent implements OnInit {
  constructor(private dataAccessService: DataAccessService, private DashboardService: DashboardService, public mainService: MainService, private SalaryHoldAndStartService: SalaryHoldAndStartService, private es: EstablishmentService, private allEmpService: AllEmpService, private snackBar: MatSnackBar, private router: Router, private spinner: NgxSpinnerService) { }
  erpUser: any;
  retirecount: any = 0
  retire: any = []
  b_acct_id: any;
  leftCount: any = 0;
  activeCount: any = 0;
  incompleteCount: any = 0;
  salarycount: any = 0
  allArr: any = [];
  allSalaryStop: any = [];
  allActive: any = [];
  allLeft: any = [];
  allIncomplete: any = [];
  showArr: any = []
  showArr1: any = []
  classDp: any = [];
  gradeDp: any = []
  self: any;
  systemDate: any
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    var resp = await this.DashboardService.getSystemDate();
    this.systemDate = resp.data
    //await this.getAllEmployees();
    await this.getArrangmentInfo();
    await this.getsalarystatus();
    this.buildGradePayChart();
    this.buildclassChart();
    this.buildDesignationChart()
    this.self = this;

  }
  getNumberFormat(num: any) {
    return num.toString().padStart(3, "0")
  }
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public mbarChartLabels1: string[] = ['I', 'II', 'III', 'IV', 'NA'];
  public mbarChartLabels: string[] = [];
  public mbarChartLabels2: string[] = [];

  public barChartType: any = 'bar';
  public barChartLegend: boolean = true;

  public barChartColors: Array<any> = [
    {
      backgroundColor: 'blue',
      borderColor: 'rgba(105,159,177,1)',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)'
    },
    {
      backgroundColor: 'red',
      borderColor: 'rgba(77,20,96,1)',
      pointBackgroundColor: 'rgba(77,20,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,20,96,1)'
    }
  ];
  public barChartData: any[] = [
    { data: [], label: 'GRADE PAY' },

  ];
  public barChartData1: any[] = [
    { data: [], label: 'CLASS' },

  ];
  public barChartData2: any[] = [
    { data: [], label: 'DESIGNATION' },

  ];

  // events
  public chartClicked(e: any): void {
    this.showArr1 = []
    if (e.active[0] == undefined || e.active[0] == null) {
      Swal.fire('Info', "Please click on the Bar-Line to see the detail", 'warning');
      return;

    }
    var index = e.active[0]._index;
    var gd = this.mbarChartLabels[index];
    for (var i = 0; i < this.allArr.length; i++) {
      if (this.allArr[i]['grade_pay_code'] == gd) {
        this.showArr1.push(this.allArr[i]);

      }
    }
    $('#myModal1').modal('show');
  }
  public chartClicked2(e: any): void {
    this.showArr1 = []
    if (e.active[0] == undefined || e.active[0] == null) {
      Swal.fire('Info', "Please click on the Bar-Line to see the detail", 'warning');
      return;

    }
    var index = e.active[0]._index;
    var gd = this.mbarChartLabels2[index];
    for (var i = 0; i < this.allArr.length; i++) {
      if (this.allArr[i]['designation_code'] == gd) {
        this.showArr1.push(this.allArr[i]);

      }
    }
    $('#myModal1').modal('show');
  }
  public chartClicked1(e: any): void {
    this.showArr1 = []
    if (e.active[0] == undefined || e.active[0] == null) {
      Swal.fire('Info', "Please click on the Bar-Line to see the detail", 'warning');
      return;

    }
    var index = e.active[0]._index;
    var cls = this.mbarChartLabels1[index];
    for (var i = 0; i < this.allArr.length; i++) {
      if (this.allArr[i]['class_code'] == cls) {
        this.showArr1.push(this.allArr[i]);

      }
    }
    $('#myModal1').modal('show');
  }

  async getArrangmentInfo() {

    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp_arr = await this.es.getAllCurrentArrangements(obj);
    //var  = await this.es.getAllCurrentArrangements(this.b_acct_id);

    if (resp_arr['error'] == false) {

      this.allArr = resp_arr['data'];
      console.log(this.allArr);
      await this.dataAccessService.getCurrentDataAccess(this.b_acct_id, this.erpUser['user_id'])
      this.allArr = await this.dataAccessService.filterEmployeeData(this.allArr)
      var arr = this.systemDate.split("-")
      arr[1] = parseInt(arr[1]) + 7
      if (arr[1] > 12) {
        arr[1] = parseInt(arr[1]) - 12
        arr[0] = parseInt(arr[0]) + 1
      }
      if (arr[1] < 10) {
        arr[1] = "0" + arr[1]
      }
      var date = arr[0] + "-" + arr[1] + "-" + arr[2]

      for (var i = 0; i < this.allArr.length; i++) {

        if (this.allArr[i].emp_status_code == 'JOINING') {
          this.incompleteCount++;
          this.allIncomplete.push(this.allArr[i])
        }
        if (this.allArr[i].emp_status_code == 'ACTIVE') {
          this.activeCount++;
          this.allActive.push(this.allArr[i])

        }
        if (this.allArr[i].emp_status_code == 'INACTIVE') {
          this.leftCount++;
          this.allLeft.push(this.allArr[i])

        }
        if (this.allArr[i].retirement_date <= date && this.allArr[i].emp_status_code == 'ACTIVE') {
          this.retirecount++;
          this.retire.push(this.allArr[i])
        }
      }

      var arrre = []
      for (var i = this.sortData.length - 1; i >= 0; i--) {
        arrre.push(this.sortData[i])
      }
      this.retire = arrre



    } else {
      Swal.fire('Error', `${resp_arr['data']}`, 'error')
      // this.snackBar.open(resp_arr['data'], 'Error', {
      //   duration: 5000
      // });
    }
  }

  get sortData() {
    return this.retire.sort((a:any, b:any) => {
      return <any>new Date(b.retirement_date) - <any>new Date(a.retirement_date);
    });
  }
  buildGradePayChart() {
    var dp :any= [];
    var dpTempObj :any= {};
    for (var i = 0; i < this.allActive.length; i++) {
      if (dpTempObj[this.allActive[i].grade_pay_code] == undefined) {
        dpTempObj[this.allActive[i].grade_pay_code] = 1;
      } else {
        dpTempObj[this.allActive[i].grade_pay_code]++;
      }
    }
    var keys = Object.keys(dpTempObj)
    for (var i = 0; i < keys.length; i++) {
      this.mbarChartLabels.push(keys[i]);
      this.barChartData[0]['data'].push(dpTempObj[keys[i]])
      //dp.push({y:dpTempObj[keys[i]],label: keys[i]})
    }

  }
  buildDesignationChart() {
    var dp = [];
    var dpTempObj:any = {};
    for (var i = 0; i < this.allActive.length; i++) {
      if (dpTempObj[this.allActive[i].designation_code] == undefined) {
        dpTempObj[this.allActive[i].designation_code] = 1;
      } else {
        dpTempObj[this.allActive[i].designation_code]++;
      }
    }
    var keys = Object.keys(dpTempObj)
    for (var i = 0; i < keys.length; i++) {
      this.mbarChartLabels2.push(keys[i]);
      this.barChartData2[0]['data'].push(dpTempObj[keys[i]])
      //dp.push({y:dpTempObj[keys[i]],label: keys[i]})
    }

  }
  buildclassChart() {
    //var dp = [];
    var dpTempObj :any= {};
    for (var i = 0; i < this.allActive.length; i++) {
      if (dpTempObj[this.allActive[i].class_code] == undefined) {
        dpTempObj[this.allActive[i].class_code] = 1;
      } else {
        dpTempObj[this.allActive[i].class_code]++;
      }
    }
    //var keys = Object.keys(dpTempObj)


    this.barChartData1[0].data.push(dpTempObj['I'])
    this.barChartData1[0].data.push(dpTempObj['II'])
    this.barChartData1[0].data.push(dpTempObj['III'])
    this.barChartData1[0].data.push(dpTempObj['IV'])
    this.barChartData1[0].data.push(dpTempObj['NA'])


  }



  async getsalarystatus() {
    var resp = await this.SalaryHoldAndStartService.getstopsalary(this.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allSalaryStop = resp.data;
      this.salarycount = resp.data.length
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee all  list', 'error')
      // this.snackBar.open("Error while getting employee all  list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  modalOpen(i:any) {
    this.showArr = []
    if (i == 1) {
      this.showArr = this.allIncomplete
      $('#myModal').modal('show');
    }
    if (i == 2) {
      this.showArr = this.allActive
      $('#myModal').modal('show');
    }
    if (i == 3) {
      this.showArr = this.allLeft
      $('#myModal4').modal('show');
    }
    if (i == 4) {
      this.showArr = this.allSalaryStop
      $('#myModal2').modal('show');
    }
    if (i == 5) {
      this.showArr = this.retire
      $('#myModal3').modal('show');
    }


  }

}

