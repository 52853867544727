import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { MainService } from '../../service/main.service';
import { ComplainServiceService } from '../../service/complain-service.service';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService as mainService_MD } from '../../../md/service/main.service'
import { DataControlService } from '../../service/data-control.service'

declare var $: any;
@Component({
  selector: 'app-public-complaint',
  templateUrl: './public-complaint.component.html',
  styleUrls: ['./public-complaint.component.css']
})
export class PublicComplaintComponent implements OnInit {
  datasource: any;
  displayedColumns = ['s.no', 'Complaint_ID', 'zone_cd', 'Complaint_type', 'Complaint_Date', 'Complaint_Status', 'action'];
  erpUser: any;
  b_acct_id: any;
  complaint_detail: any = {};
  complaint_image = [];
  imgURL1: any;
  assign_Obj:any = {}
  userinfo: any;
  inspection_image: any = [];
  section_detail: any = [];
  imgURL2: any;
  inspectionimageflag = false;
  memo_image: any = [];
  memoimageflag = false;
  imgURL3: any;
  user_id: any;
  Inzone = true;
  OuterZone = false;
  imgURL4: any;
  feedbackimage = false;
  accessed_zone = []
  accessed_complain = []



  constructor(public ComplainService: ComplainServiceService,
    private sanitizer: DomSanitizer, private spinner: NgxSpinnerService, private mainServiceMD: mainService_MD,
    private dataControlService: DataControlService) { }

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    this.user_id = this.erpUser.le_id
    let asscssedData = await this.dataControlService.getmappedcomplain({ user_id: this.user_id, b_acct_id: this.b_acct_id });
    this.accessed_zone = asscssedData.zone_array;
    this.accessed_complain = asscssedData.compain_array;

    console.log("asscssedData : ", asscssedData)
    await this.getAllEmployees()
    await this.getcomplaindata()
    // await this.getAllModules()
  }

  complaint = true
  complaint_inspection = false
  imageflag = false


  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  allEmplyees_new = []
  allEmployees = []
  allSearchableEmp = []
  userObj :any= {}




  async getphotodetail() {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Public"

    // console.log(obj)
    var resp :any= await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.complaint_image = resp['data']
      // console.log(this.complaint_image);
    }
  }
  async getinspectionphotodetail() {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Inspection";
    // console.log(obj)
    var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.inspection_image = resp['data']
      // console.log(this.inspection_image)
    }
  }
  async getmemophoto() {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Memo";
    // console.log(obj)
    var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.memo_image = resp['data']
      // console.log(this.memo_image)
    }
  }

  description = [];
  async viewDetails(element:any) {
    this.complaint_detail = element
    $('#myModal1').modal('show');
    this.getphotodetail();
    this.getuserinfo();
    this.inspection(element);
    this.getfeedbackdetails();
    if (this.complaint_detail['status'] == 'INSPECTION REPORT SUBMITED') {
      const obj:any = {}
      obj['b_acct_id'] = this.b_acct_id
      obj['complain_id'] = this.complaint_detail['complain_id']
      var resp:any = await this.ComplainService.getinspectionDescription(JSON.stringify(obj));
      // console.log(resp);
      if (resp['error'] == true) {
        Swal.fire('error', "server side error", 'error');
        return
      } else {
        this.description = resp['data'];
        // console.log(this.description);
      }
      return
    }

  }

  view_inspection_photo() {
    this.inspectionimageflag = false;
    $('#inspection_photo').modal('show');

  }

  view_memo_img() {
    this.memoimageflag = false;
    $('#memo_photo').modal('show');
    this.view_memo()
  }
  view_feedback() {
    $('#feedback_img').modal('show');

  }

  forward(element:any) {
    // this.complaint_detail = {};
    this.complaint_detail = element
    this.condtion = "BY_ZONE"
    this.getuserinfobyzone();
    $('#staticBackdrop2').modal('show');



  }
  inspection_req = [
    { id: 1, name: 'yes' },
    { id: 2, name: 'no' },


  ]

  async forward2() {
    this.assign_Obj['b_acct_id'] = this.b_acct_id
    this.assign_Obj['life_cycle_status'] = "ASSIGNED"
    this.assign_Obj['complain_id'] = this.complaint_detail['complain_id']
    this.assign_Obj['status'] = "UNDER-INSPECTION"
    this.assign_Obj['type'] = "complaint"
    this.assign_Obj['assigned_By'] = this.user_id
    console.log(this.assign_Obj);
    var resp:any = await this.ComplainService.insertassigncomplain(this.assign_Obj)
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {
      Swal.fire('Success', 'Complaint forward Successfully', 'success');
      this.getcomplaindata();
      $('#staticBackdrop2').modal('hide');
    }
    this.assign_Obj = {};


  }
  inspection_detail:any = {};
  async inspection(element:any) {
    this.complaint_detail = {}
    this.complaint_detail = element
    // console.log(this.complaint_detail)
    this.getinspectionphotodetail();
    this.getmemophoto();
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getinspectionData(JSON.stringify(obj))
    // console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    }
    else {

      this.section_detail = resp['data'][1]



      this.inspection_detail = Object.assign({}, ...resp['data'][0]);


      // console.log(this.inspection_detail);
      // console.log(this.section_detail)
    }

  }

  async getcomplaindata() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_from'] = 'Public'
    obj['user_id'] = this.user_id;
    obj['zone_cd'] = this.accessed_zone;
    var resp:any = await this.ComplainService.getComplainData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      // this.datasource = resp['data']
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
    }

  }

  view_photo() {
    // console.log(this.complaint_image.length)
    this.imageflag = false
    if (this.complaint_image.length > 0) {
      $('#staticBackdrop').modal('show');
      return
    }
    else {
      Swal.fire('info', "No image found", 'info');
    }

  }






  async view(i:any) {
    this.imgURL1 = null;
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.complaint_image[i]['doc_id']
    obj['file_name'] = this.complaint_image[i]['file_name']
    obj['complain_id'] = this.complaint_image[i]['complain_id']
    // console.log(obj)
    this.spinner.show();
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.imageflag = true
      // console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL1 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.spinner.hide();
        this.imgURL1 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL1 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
      // console.log(this.imgURL1, 'imgURL1')

    }


  }

  async view_inspection(i:any) {


    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.inspection_image[i]['doc_id']
    obj['file_name'] = this.inspection_image[i]['file_name']
    obj['complain_id'] = this.inspection_image[i]['complain_id']
    // console.log(obj)
    this.spinner.show();
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.inspectionimageflag = true;
      // console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL2 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.spinner.hide();
        this.imgURL2 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL2 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
    } else {
      Swal.fire('error', 'server side error', 'error');

    }

    console.log(this.imgURL2)

  }

  async view_memo() {

    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.memo_image[0]['doc_id']
    obj['file_name'] = this.memo_image[0]['file_name']
    obj['complain_id'] = this.memo_image[0]['complain_id']
    // console.log(obj)
    this.spinner.show();
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.memoimageflag = true;
      // console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL3 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.spinner.hide();
        this.imgURL3 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL3 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }


    }



  }



  open_list() {
    this.complaint = true;
    this.complaint_inspection = false;
  }

  // cancel_insp(){
  //   this.complaint = true;
  //   this.complaint_inspection = false;
  // }

  close() {
    $('#myModal1').modal('hide');

  }

  async getAllEmployees() {
    // console.log('all emp', this.b_acct_id)
    this.spinner.show()
    var arr = [];
    const obj:any = {};
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'ICMS'
    // console.log(obj)
    var resp:any = await this.mainServiceMD.getuserformodulerolemapping(JSON.stringify(obj));
    // console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      // console.log(resp, 'users')
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
      }
      this.allSearchableEmp = arr
      // console.log(this.allSearchableEmp)
    } else {
      this.spinner.hide()
    }
    // console.log(this.allEmployees, this.allEmplyees_new, 'employees')
  }

  userdetail:any = []
  async getuserinfo() {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getuserinfo(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {

      this.userdetail = resp['data']


      this.complaint_detail['party_name'] = this.userdetail[0]['party_name']

      for (let i = 0; i < this.userdetail.length; i++) {
        if (this.userdetail[i]['role_cd'] == "ICMS_EMP") {
          this.userdetail[i]['role_name'] = undefined

        }

      }

    }


  }


  // ///////////////////////////////////////get user by zone

  same = false;
  condtion = {}
  sameAddress() {
    if (!this.same) {
      this.Inzone = false;
      this.OuterZone = true;
      this.same = true;
      this.condtion = "NOT_BY_ZONE"

      this.getuserinfobyzone();
    } else {
      this.Inzone = true;
      this.OuterZone = false;
      this.same = false;
      this.condtion = "BY_ZONE"

      this.getuserinfobyzone();

    }

  }







  userbyzone = []
  userbynotzone = []
  async getuserinfobyzone() {
    let arr = []
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['condition'] = this.condtion;
    obj['node_cd'] = this.complaint_detail['zone_cd']
    var resp:any = await this.ComplainService.getuserinfoByzone(JSON.stringify(obj));
    // console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {
      arr = resp['data'];
      // console.log(this.userbyzone);
      // console.log(this.user_id);
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]['user_id'] == this.user_id) {
          arr.splice(i, 1);
        }
      }
      // console.log(arr);
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no'] + " - " + arr[i]['zone_name']
      }
      if (this.condtion == "BY_ZONE") {
        this.userbyzone = arr;
        return
      } else {
        this.userbynotzone = arr;
      }




      // console.log(this.userbyzone);
    }


  }


  // get feedback details
  feedbackdetail: any = {}
  async getfeedbackdetails() {

    console.log(this.complaint_detail)
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complaint_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getfeedbackdetails(JSON.stringify(obj));
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error');
      return
    } else {
      this.feedbackdetail = resp['data'];
      console.log(this.feedbackdetail);
    }
    this.getfeedbackdocument();

  }
  feedbackimg: any = [];
  async getfeedbackdocument() {
    console.log(this.feedbackdetail)
    console.log(this.complaint_detail)
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.feedbackdetail[0]['feedback_id']
    var resp:any = await this.ComplainService.getfeedbackdocuments(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error');
      return
    } else {
      this.feedbackimg = resp['data'];
      // console.log(this.description);
    }


  }

  async view_feedback_photo(i:any) {

    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.feedbackimg[i]['doc_id']
    obj['file_name'] = this.feedbackimg[i]['file_name']

    console.log(obj)
    this.spinner.show();
    let resp: any = await this.ComplainService.feedback(obj);
    console.log(resp);
    if (resp) {
      this.feedbackimage = true
      console.log(resp, 'res')
      this.spinner.hide();
      this.imgURL4 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.spinner.hide();
        this.imgURL4 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL4 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }

      console.log(this.imgURL4)
    }



  }

}
