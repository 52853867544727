import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from '../../role-guard.service';
import { AccountConfigComponent } from '../account-config/account-config.component';
import { MdCreateRoleComponent } from '../md-create-role/md-create-role.component';
import { MdDashboardComponent } from '../md-dashboard/md-dashboard.component';
import { MdLegalEntityComponent } from '../md-legal-entity/md-legal-entity.component';
import { MdUserComponent } from '../md-user/md-user.component';
import { VendorFeeComponent } from '../vendor-fee/vendor-fee.component';
import { VendorTComponent } from '../vendor-t/vendor-t.component';


const routes: Routes = [
  {path: "md-index",component: MdDashboardComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'AA-R1' }},
{path: "md-legal-entity",component: MdLegalEntityComponent, },
{path: "vendor_tc",component: VendorTComponent},
{path: "vendor_fee",component: VendorFeeComponent, },
{path: "md-create-role",component: MdCreateRoleComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'AA-R2' }},
{path: "md-user",component: MdUserComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'AA-R3' }},
{path: "account-config",component: AccountConfigComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'AA-R4' }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MdLayoutsRoutingModule { }
