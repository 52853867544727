import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { AllEmpService } from '../../service/all-emp.service';
import { MainService } from '../../service/main.service'
import { DataAccessService } from '../../service/data-access.service';
declare var $: any
import swal from 'sweetalert2';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-education-info',
  templateUrl: './education-info.component.html',
  styleUrls: ['./education-info.component.css']
})
export class EducationInfoComponent implements OnInit {

  constructor(private dataAccessService: DataAccessService, public mainService: MainService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private allEmpService: AllEmpService) { }
  personalInfoObj :any= {};
  erpUser:any;
  user_id:any;
  b_acct_id:any;
  allEmployees :any= [];
  selectEmpObj :any= {};
  selectedEmpEducation :any= [];
  eductionObj :any= {};
  codeValueTechObj :any= {};
  errorMsg :any= ''
  allEmplyees_new :any= [];
  delEle:any;
  @ViewChild(MatPaginator, {static: false})
 set paginator(value: MatPaginator) {
   if (this.datasource){
     this.datasource.paginator = value;
   }
 }
 @ViewChild(MatSort, {static: false})
 set sort(value: MatSort) {
   if (this.datasource){
     this.datasource.sort = value;
   }
 }

  displayedColumns = ['education_name', 'education_type_code', 'pass_year', 'action'];
  datasource:any;

  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.dataAccessService.getAssignedEmpInfo1(this.b_acct_id,this.user_id,true);
    await this.getAllEmployees();
    // this.selectEmpObj['le_id']=this.erpUser.user_id;


    //await this.changeEmployee();

    //await this.getQualifications();
  }
  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }
  async getAllEmployees1() {
    this.spinner.show()
    var arr = []
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';

    var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      this.allEmployees = []

     var assigned_emp = this.dataAccessService.assignedEmp2;
      // var assigned_emp = this.dataAccessService.getAssignedEmpInfo(this.b_acct_id,this.user_id,true);
      for (let i = 0; i < arr.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = this.getNumberFormat(obj['emp_id'])
        if (assigned_emp[arr[i]['emp_id']] != undefined) {
          this.allEmployees.push(obj)
        }

      }
      this.allEmplyees_new = [];
      for (let i = 0; i < this.allEmployees.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, this.allEmployees[i]);
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.allEmplyees_new.push(obj)
      }
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async getAllEmployees() {
    this.spinner.show()
    var arr = []
    this.allEmployees = []
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';

    var resp = await this.allEmpService.getAllPartyFields(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      // this.allEmployees=arr;
      this.allEmployees = [];
      var assignedEmp = this.dataAccessService.assignedEmp2;
      console.log("resp of the get assign data functjion ", assignedEmp)
      console.log("Assign data -",assignedEmp)
      for (let i = 0; i < arr.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = this.getNumberFormat(obj['emp_id']);
        if (assignedEmp[arr[i]['arr_id']] != undefined) {
          this.allEmployees.push(obj)
        }
      }
      console.log(this.allEmployees)
      this.allEmplyees_new = [];
      for (let i = 0; i < this.allEmployees.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, this.allEmployees[i]);
        obj['emp_name'] = this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.allEmplyees_new.push(obj)
      }

    }
  }
  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
   // console.log(this.selectEmpObj)
   if(this.selectEmpObj['le_id']==undefined || this.selectEmpObj['le_id']==null|| this.selectEmpObj['le_id']=='')
   {
Swal.fire('Warning','Please select at least one employee !', 'warning');
return;
   }
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }

  async changeEmployee() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    for (let i = 0; i < this.allEmplyees_new.length; i++) {
      if (this.allEmplyees_new[i]['le_id'] == this.selectEmpObj['le_id']) {
        obj['le_id'] = this.allEmplyees_new[i]['le_id']
      }
    }


    this.spinner.show();
    var resp = await this.allEmpService.getQualifications(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.selectedEmpEducation = resp.data;
      this.datasource = new MatTableDataSource(this.selectedEmpEducation)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort
    } else {
      this.spinner.hide()
      swal.fire('Error', ' Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }


  }

  openUpdate(element:any) {
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    this.errorMsg = ''
    //  $('.nav-tabs a[href="#tab-3"]').tab('show');
    this.eductionObj = Object.assign({}, element);

  }
  async submitEductionInfo() {
    if(this.selectedEmpEducation.length>0){
      swal.fire("Sorry", "This Employee's Education Info already Added", 'warning');
      return;
    }

    this.errorMsg = ''
    if (
      this.eductionObj['education_name'] == "" || this.eductionObj['education_name'] == undefined
      || this.eductionObj['education_type_code'] == "" || this.eductionObj['education_type_code'] == undefined
      || this.eductionObj['pass_year_code'] == null || this.eductionObj['pass_year_code'] == undefined
      || this.selectEmpObj['le_id']=="" || this.selectEmpObj['le_id']==undefined || this.selectEmpObj['le_id']==null) {

      this.errorMsg = "* fields required."


    }
    else {
      console.log("this is the asve data",this.eductionObj);
      // this.eductionObj['pass_year_code_only_Year'] = this.eductionObj['pass_year_code'].replace(/-/g,',');
      let data= this.eductionObj['pass_year_code'].split('-')
      this.eductionObj['pass_year_code_only_Year'] = data[0]
      console.log(" this is the obj", this.eductionObj);
      swal.fire({
        icon: 'info',
        title:'Are you Sure?',
        // text: 'Do You Want To Add',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.value) {
          this.finalsubmitEductionInfo()
        }
      })


    }
  }
  async finalsubmitEductionInfo() {
    this.eductionObj['b_acct_id'] = this.b_acct_id;
    this.eductionObj['create_user_id'] = this.user_id;
    for (let i = 0; i < this.allEmplyees_new.length; i++) {
      if (this.allEmplyees_new[i]['le_id'] == this.selectEmpObj['le_id']) {
        this.eductionObj['le_id'] = this.allEmplyees_new[i]['le_id']
      }
    }
    this.spinner.show();
    var resp = await this.allEmpService.addQualification(this.eductionObj);
    if (resp['error'] == false) {
      this.eductionObj = {}
      this.spinner.hide();
      await this.changeEmployee();
      // this.snackBar.open("Education Info Added" ,'Success',{
      //   duration:5000
      // });
      swal.fire("Success", "Education Info Added", 'success');


    } else {
      this.spinner.hide();

      swal.fire("Sorry", "...Some Error Occured!", 'error');

    }
  }


  async updateEductionInfo() {
    this.errorMsg = ''
    if (
      this.eductionObj['education_name'] == "" || this.eductionObj['education_name'] == undefined
      || this.eductionObj['education_type_code'] == "" || this.eductionObj['education_type_code'] == undefined
      || this.eductionObj['pass_year_code'] == null || this.eductionObj['pass_year_code'] == undefined) {

      this.errorMsg = "* fields required."


    }
    else {

      swal.fire({
        title:'Are you Sure?',
        text: 'Do You Want To Update',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Update it!'
      }).then((result) => {
        if (result.value) {
          this.finalupdateEductionInfo()
        }
      })


    }
  }
  async finalupdateEductionInfo() {

    this.eductionObj['b_acct_id'] = this.b_acct_id;
    this.eductionObj['update_user_id'] = this.user_id;
    this.spinner.show();
    var resp = await this.allEmpService.updateQualifications(this.eductionObj);
    if (resp['error'] == false) {
      this.eductionObj = {}
      this.spinner.hide();
      await this.changeEmployee();
   this. open_list();
      swal.fire("Success", "Education Info Updated", 'success');

    } else {
      this.spinner.hide();
      swal.fire("Sorry", "...Some Error Occured!", 'error');

    }
  }

  async deleteEducation(element:any) {
    this.delEle = element;
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldeleteEducation()
      }
    })
  }
  async finaldeleteEducation(){
    var obj:any = new Object();

    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = this.delEle['id'];
    obj['le_id'] = this.delEle['le_id']
    obj['edu_seq_num'] = this.delEle['edu_seq_num']

    this.spinner.show();
    var resp = await this.allEmpService.deleteQualification((obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.changeEmployee();
  swal.fire("Success", "Qualification deleted successfully", 'success');
      // this.snackBar.open(resp.data, 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      swal.fire("Error", "Qualification deleted successfully", 'error')
      // this.snackBar.open("Error while deleting Eduction Info Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }
  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  chamgeTab() {
    this.errorMsg = ''
  }

}
