
import { Component, OnInit } from '@angular/core';
// import { MainService as mainService_MD } from '../../md/service/main.service';
import { MainService } from '../../portal/service/main.service'
// import { MainService as mainService_MD } from 'md/service/main.service'
import { EditorServiceService } from '../service/editor-service.service'
@Component({
  selector: 'app-letter-editor',
  templateUrl: './letter-editor.component.html',
  styleUrls: ['./letter-editor.component.css']
})
export class LetterEditorComponent implements OnInit {

  constructor(private editorServiceService: EditorServiceService, public mainService: MainService) { }

  // editor related code
  optionsButtons:any
  advancedOptionButton:any


  // fontName
  // fontSizeRef
  writingArea:any
  linkButton:any
  alignButtons:any
  spacingButtons:any
  formatButtons:any
  scriptButtons:any
  ebillUser:any;

  to_data:any = this.editorServiceService.to_data;
  current_date:any;
  department_data:any;

  async ngOnInit() {

    // getting data from service start

    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.department_data = this.editorServiceService.department_data;
    this.current_date = this.editorServiceService.current_date;
    this.to_data = this.editorServiceService.to_data;

    if (this.to_data['appli_data']) {
      await this.setHtmlData();
    }

    console.log("to date ", this.to_data);
    console.log("Current date ", this.current_date);
    console.log("department data ", this.department_data);

    // getting data from service end



    // editor related code
    this.optionsButtons = document.querySelectorAll(".option-button");
    this.advancedOptionButton = document.querySelectorAll(".adv-option-button");
    // this.fontName = document.getElementById("fontName");
    // this.fontSizeRef = document.getElementById("fontSize");
    this.writingArea = document.getElementById("sub-body");
    // this.writingArea = document.getElementById("text-input");
    this.linkButton = document.getElementById("createLink");
    this.alignButtons = document.querySelectorAll(".align");
    this.spacingButtons = document.querySelectorAll(".spacing");
    this.formatButtons = document.querySelectorAll(".format");
    this.scriptButtons = document.querySelectorAll(".script");

    this.optionsButtons.forEach((button:any) => {
      button.addEventListener("click", () => {
        // alert("hello")
        this.modifyText(button.id, false, null);
      });
    });

    this.linkButton.addEventListener("click", () => {
      let userLink:any = prompt("Enter a URL");
      //if link has http then pass directly else add https
      if (/http/i.test(userLink)) {
        this.modifyText(this.linkButton.id, false, userLink);
      } else {
        userLink = "http://" + userLink;
        this.modifyText(this.linkButton.id, false, userLink);
      }
    });
    this.initializer();

    await this.dataManipulation();
  }


  async setHtmlData() {
    // document.getElementById('text-input').innerHTML = this.to_data['appli_data'];
    let data = JSON.parse(this.to_data['appli_data']);
    document.getElementById('sub-body')!.innerHTML = data['body']
  }


  async dataManipulation() {
    console.log("data maniipulation ")
    this.department_data.map((x:any) => {
      if (x['branch_name'] == this.to_data['branch_name']) {
        this.to_data['officer_desig'] = x['officer_desig'];
        this.to_data['branch_address'] = x['branch_address']
        this.to_data['officer_name'] = x['officer_name']
      }
    })
  }
  // samarjeet your code
  fontList = [
    "Arial",
    "Verdana",
    "Times New Roman",
    "Garamond",
    "Georgia",
    "Courier New",
    "cursive",
  ];

  initializer = () => {
    //function calls for highlighting buttons
    //No highlights for link, unlink,lists, undo,redo since they are one time operations
    this.highlighter(this.alignButtons, true);
    this.highlighter(this.spacingButtons, true);
    this.highlighter(this.formatButtons, false);
    this.highlighter(this.scriptButtons, true);

    //create options for font names
    // this.fontList.map((value) => {
    //   let option = document.createElement("option");
    //   option.value = value;
    //   option.innerHTML = value;
    //   this.fontName.appendChild(option);
    // });

    //fontSize allows only till 7
    // for (let i = 1; i <= 7; i++) {
    //   let option = document.createElement("option");
    //   option.value = `${i}`;
    //   option.innerHTML = `${i}`;
    //   this.fontSizeRef.appendChild(option);
    // }

    //default size
    // this.fontSizeRef.value = 3;
  };

  modifyText = (command:any, defaultUi:any, value:any) => {
    //execCommand executes command on selected text
    document.execCommand(command, defaultUi, value);
  };


  //Highlight clicked button
  highlighter = (className:any, needsRemoval:any) => {
    className.forEach((button:any) => {
      button.addEventListener("click", () => {
        //needsRemoval = true means only one button should be highlight and other would be normal
        if (needsRemoval) {
          let alreadyActive = false;

          //If currently clicked button is already active
          if (button.classList.contains("active")) {
            alreadyActive = true;
          }

          //Remove highlight from other buttons
          this.highlighterRemover(className);
          if (!alreadyActive) {
            //highlight clicked button
            button.classList.add("active");
          }
        } else {
          //if other buttons can be highlighted
          button.classList.toggle("active");
        }
      });
    });
  };


  highlighterRemover = (className:any) => {
    className.forEach((button:any) => {
      button.classList.remove("active");
    });
  };

  getAllcontent = () => {
    const header = document.getElementById("header")!.innerHTML
    const body = document.getElementById("sub-body")!.innerHTML
    const footer = document.getElementById("footer")!.innerHTML

    let obj = { 'header': header, 'body': body, 'footer': footer }
    // this.mainServiceMD.editorValue = body
    this.editorServiceService.to_data = obj;
    console.log(obj)
  }



}
