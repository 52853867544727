import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef, AfterViewInit } from '@angular/core';
//  import { MediaStream } from 'dom-mediacapture-record';
import { MkAttendanceService } from '../service/mk-attendance.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import * as moment from "moment";

@Component({
  selector: 'app-mark-attendance',
  templateUrl: './mark-attendance.component.html',
  styleUrls: ['./mark-attendance.component.css']
})
export class MarkAttendanceComponent implements OnInit {

  create_flag: boolean = false;
  list_flag: boolean = true;
  turn_off_cam: boolean = false;
  final_submit: boolean = false;
  video!: HTMLVideoElement;
  canvas!: HTMLCanvasElement;
  image: any;
  displayedColumns = ['serial_no', 'zone', 'emp_id', 'emp_name', 'date', 'pi_time', 'pi_latitude', 'pi_longitude', 'po_time', 'po_latitude', 'po_longitude'];

  @ViewChild('videoElement', { static: false }) videoElement!: ElementRef;
  @ViewChild('canvasElement', { static: false }) canvasElement!: ElementRef;
  datasource:any
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  constructor(public mkattendance: MkAttendanceService, private snackBar: MatSnackBar) { }
  punchin_flag = false
  showimage_flag = false
  mark_Attend = false
  // final_submit: boolean = false
  openCamera: boolean = true;
  // turn_off_cam: boolean = false
  turn_on_flag: boolean = false
  imgshow: boolean = false
  punchout_flag: boolean = false
  mark_Attend1: boolean = false
  final_submit_punch_out: boolean = false
  erpUser:any
  b_acct_id:any
  errorMsg :any= ''
  user_name:any
  currentDate: any;
  currentDate1:any
  currentDate2:any
  punchobj:any = {}
  async ngOnInit() {
    this.punchin_flag = false
    this.showimage_flag = false
    this.mark_Attend = false
    this.final_submit = false
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_name = this.erpUser.party_name
    // console.log(this.erpUser, "kkk")
    //  await this.getLocation()
    //   await this.openLocationSettings()
    //   await this.gethierarchyid()

    // console.log(formattedDate); // Output: 2023-06-14
    // console.log(this.currentDate1)
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_name = this.erpUser.party_name
    // console.log(this.erpUser, "kkk")
    await this.getLocation()
    await this.openLocationSettings()
    await this.gethierarchyid()
    this.currentDate = new Date();

    this.currentDate1 = this.currentDate.toLocaleDateString('en-GB').split('/').join('-');;
    // console.log('Current Date:', this.currentDate1);
    this.currentDate2 = moment(this.currentDate1, 'DD-MM-YYYY').format('YYYY-MM-DD');

    // console.log(formattedDate); // Output: 2023-06-14
    // console.log(this.currentDate1)
    await this.getallpunchdata1()
  }
  open_list() {
    this.list_flag = true;
    this.create_flag = false;

  }
  cameraOpen = false;
  capturedImages: string[] = [];
  video_flag: boolean = false
  canvas_flag: boolean = false
  startCamera() {
    const videoElement = this.videoElement.nativeElement;
    const canvasElement = this.canvasElement.nativeElement;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          videoElement.srcObject = stream;
          videoElement.play();
          // this.turn_off_cam = true;
          // this.final_submit = true;
          this.video = videoElement;
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
        });
    }
    this.turn_off_cam = true;
    this.mark_Attend = true
    this.showimage_flag = false
    this.punchin_flag = false
    this.turn_on_flag = false
    this.imgshow = false
    this.punchout_flag = false
    this.video_flag = true
    this.canvas_flag = true
  }
  mediaStream  :any;

  imageName:any
  async getallpunchdata1() {
    var obj :any= {}
    obj["b_acct_id"] = this.b_acct_id
    obj["le_id"] = this.erpUser.le_id
    obj["date"] = this.currentDate2
    console.log(obj)
    var resp = await this.mkattendance.getpunchdata(obj)
    console.log(resp)
    if (resp["error"] == false) {
      // console.log(resp.data[0].is_punched)
      if (resp.data.length == 0) {

        this.punchout_flag = false
        this.punchin_flag = true

      }
      else if (resp.data[0].is_punched == 0) {

        this.punchout_flag = true
        this.punchin_flag = false
      }
      else if (resp.data[0].is_punched == 1) {
        this.punchout_flag = false
        this.punchin_flag = false
        // this.table = true
      }
      this.punchobj['date'] = this.currentDate2
      this.datasource = new MatTableDataSource(resp.data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort
    }
  }
  async getallpunchdata() {
    var obj :any= {}
    obj["b_acct_id"] = this.b_acct_id
    obj["le_id"] = this.erpUser.le_id
    obj["date"] = this.punchobj['date']
    console.log(obj)
    var resp = await this.mkattendance.getpunchdata(obj)
    console.log(resp)
    if (resp["error"] == false) {
      if (resp.data.length == 0) {
        Swal.fire('Info', 'There are no Attendance Is Present In This Date ', 'info')
        this.datasource = []
      } else {
        this.datasource = new MatTableDataSource(resp.data)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort
      }


    }
  }
  async capture() {
    this.canvas = this.canvasElement.nativeElement;
    // const context = this.canvas.getContext('2d');
    // this.canvas.width = this.video.videoWidth;
    // this.canvas.height = this.video.videoHeight;
    // context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    // this.image = this.canvas.toDataURL('image/png');
    this.canvas.getContext('2d')?.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    this.image = this.canvas.toDataURL('image/png'); // You can change the format to 'image/jpeg' if desired
    console.log('Captured image:', this.image);
    await this.clearCanvas();
    this.imageName = await this.generateImageName();
    console.log('Image name:', this.imageName);
    this.video.pause();
    this.turn_off_cam = false;
    this.final_submit = false;
    this.turn_on_flag = false;
    this.mark_Attend = false
    this.showimage_flag = false
    this.punchin_flag = false
    this.final_submit = true
    this.imgshow = true
    this.punchout_flag = false
    await this.close()
  }
  async close() {
    console.log(this.mediaStream, "hh")
    if (this.video.srcObject) {
      const tracks = (this.video.srcObject as MediaStream).getTracks();
      tracks.forEach(track => track.stop());
      this.video.srcObject = null;
    }

    this.turn_off_cam = false;
    this.mark_Attend = false
    this.showimage_flag = false
    this.punchin_flag = false
    this.turn_on_flag = false
    this.punchout_flag = false

  }
  async clearCanvas() {
    const context:any = await this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
  async generateImageName() {
    const currentDate = new Date();
    console.log(currentDate)
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');

    const convertedDate = `${day}_${month}_${year}_${hours}_${minutes}_${seconds}`;

    console.log(convertedDate); // Output: 13_06_2023_17_24_45
    const timestamp = currentDate.getTime();
    const imageName = `image_${convertedDate}.png`; // You can modify the file extension if desired
    return imageName;
  }
  async stopCamera() {
    console.log("i a")

    // if (this.mediaStream && this.mediaStream.getTracks) {
    console.log("i afffffffff")

    this.mediaStream.getTracks().forEach((track:any) => track.stop());
    this.mediaStream = null;
    // }


  }
  selectedFile: any = null;
  onFileUpload(event:any, files:any) {
    console.log(event)
    this.selectedFile = <File>event.target.files[0];

    // if (files.length === 0) {
    //   return;
    // }

    // const mimeType = files[0].type;
    // if (mimeType.match(/image\/*/) == null) {
    //   return;
    // }
    // const reader = new FileReader();
    // this.imagePath = files;
    // reader.readAsDataURL(files[0]);
    // reader.onload = (_event) => {
    // };

  }

  table: boolean = false
  uploader:any;
  async submit() {
    this.video.pause()
    console.log(this.video.srcObject)

    // this.uploadImage(this.image)
    // return;
    const blobImage = this.dataURItoBlob(this.image);
    // Stop the video stream
    // if (this.video.srcObject) {
    //   const tracks = (this.video.srcObject as MediaStream).getTracks();
    //   tracks.forEach(track => track.stop());
    //   this.video.srcObject = null;
    // }
    this.openCamera = false;
    console.log('Captured image:', this.image);
    this.mark_Attend = false
    this.showimage_flag = false
    this.punchin_flag = false
    this.final_submit = false
    let formData = new FormData();

    console.log(formData)
    let obj:any = new Object();
    obj['b_acct_id'] = this.erpUser.b_acct_id;

    obj['user_id'] = this.erpUser.user_id;
    obj['latitude'] = this.latitude
    obj['longitude'] = this.longitude
    obj['emp_name'] = this.erpUser.party_name
    obj['life_cycle_status'] = 'ACTIVE'
    obj['hierarchy_id'] = this.hierid
    obj["le_id"] = this.erpUser.le_id
    obj['zone_id'] = this.empdetail['zone']
    obj["emp_id"] = this.empdetail['emp_id']
    obj['doc_type'] = 'ATTENDANCE_PI'
    obj['name'] = this.imageName
    // obj['image'] = this.image
    // console.log(obj)
    formData.append('image', blobImage, this.imageName);
    formData.append('data', JSON.stringify(obj));
    var resp = await this.mkattendance.MarkAttemdanceIn(formData)
    // console.log(resp)
    if (resp["error"] == false) {
      Swal.fire(resp.heading, resp.data, resp.icon)
      this.turn_on_flag = false
      this.turn_off_cam = false
      this.mark_Attend = false
      this.punchin_flag = false
      this.imgshow = false
      this.punchout_flag = true
      this.final_submit = false
      this.table = true
      // this.close()
      await this.getallpunchdata1()
      // this.punchobj['date']=''
      await this.open_list()
    }
    else {
      Swal.fire(resp.heading, resp.data, resp.icon)
      this.close()
    }
  }
  hierid:any
  empdetail:any = {}
  async gethierarchyid() {

    var obj :any= {}
    obj["b_acct_id"] = this.b_acct_id
    obj["le_id"] = this.erpUser.le_id

    var resp = await this.mkattendance.gethierid(obj)
    console.log(resp, "hierarchy iddd")
    if (resp["error"] == false) {
      this.hierid = resp.hierid
      this.empdetail = resp.data[0]
      // console.log(this.empdetail,"this.empdetailthis.empdetailthis.empdetail")
    }


  }


  //  async  uploadImage(base64Image: string) {
  //     // Convert the base64-encoded image to a Blob object
  //     const blobImage = this.dataURItoBlob(base64Image);

  //     // Create a FormData object and append the Blob as a file
  //     const formData = new FormData();
  //     formData.append('image', blobImage, 'image.jpg');


  //   }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
















  latitude!: number;
  longitude!: number;
  async getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          console.log(this.latitude, this.longitude)
        },
        (error) => {
          // this.punchin_flag=false
          this.snackBar.open("Your Location Service is Off. Please trun it on and try again later", 'info', {
            duration: 5000
          });
          // console.error('Error getting location:', error);
        }
      );
    } else {
      this.snackBar.open("Geolocation is not supported by this browser.", 'error', {
        duration: 5000
      });
      // console.error('Geolocation is not supported by this browser.');
    }
  }
  async openLocationSettings() {
    // Open the device/browser location settings
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        () => {
          console.log('Location enabled.');
        },
        (error) => {
          this.punchin_flag = false
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }


  //check employee inside the boundary or not

  async checkempcords() {
    var obj :any= {}
    obj["b_acct_id"] = this.b_acct_id
    obj["zone_id"] = this.empdetail['zone']
    // obj['lat'] = 26.869717
    // obj['long'] = 80.9539367
    obj['lat'] = this.latitude
    obj['long'] = this.longitude
    // console.log(obj)
    var resp = await this.mkattendance.checkcords(JSON.stringify(obj))
    console.log(resp)
    if ((resp["error"] == false) && (resp["modal"] == false)) {
      Swal.fire(resp.heading, resp.data, 'info')
      await this.getallpunchdata1()
    }
    else if ((resp["error"] == false) && (resp["modal"] == true)) {
      Swal.fire(resp.heading, resp.data, 'info')
      this.table = true
    }
    else {
      Swal.fire(resp.heading, resp.data, resp.icon)
      this.table = true
    }
  }


  startCamera1() {

    const videoElement = this.videoElement.nativeElement;
    const canvasElement = this.canvasElement.nativeElement;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          videoElement.srcObject = stream;
          videoElement.play();
          // this.turn_off_cam = true;
          // this.final_submit = true;
          this.video = videoElement;
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
        });
    }
    this.turn_off_cam = false;
    this.mark_Attend = false
    this.showimage_flag = false
    this.punchin_flag = false
    this.turn_on_flag = false
    this.imgshow = false
    this.punchout_flag = false
    this.mark_Attend1 = true
    this.table = false
    // this.close()
  }

  async capture1() {

    // console.log(event, files)
    // this.canvas.getContext('2d').drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    // this.image = this.canvas.toDataURL('image/png'); // You can change the format to 'image/jpeg' if desired
    // console.log('Captured image:', this.image);

    this.canvas = this.canvasElement.nativeElement;
    // const context = this.canvas.getContext('2d');
    // this.canvas.width = this.video.videoWidth;
    // this.canvas.height = this.video.videoHeight;
    // context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    // this.image = this.canvas.toDataURL('image/png');
    this.canvas.getContext('2d')?.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    this.image = this.canvas.toDataURL('image/png'); // You can change the format to 'image/jpeg' if desired
    console.log('Captured image:', this.image);
    await this.clearCanvas();
    this.imageName = await this.generateImageName();
    console.log('Image name:', this.imageName);
    // this.capturedImages.push(image);
    this.clearCanvas();
    this.imageName = await this.generateImageName();
    console.log('Image name:', this.imageName);
    // this.video.pause();
    this.turn_on_flag = false;
    this.mark_Attend = false
    this.mark_Attend1 = false
    this.showimage_flag = false
    this.punchin_flag = false
    this.final_submit = false
    this.imgshow = true
    this.punchout_flag = false
    this.final_submit_punch_out = true
    this.close()
  }

  async submit1() {
    this.punchobj['date'] = ''
    this.video.pause()
    console.log(this.video.srcObject)


    const blobImage = this.dataURItoBlob(this.image);

    this.openCamera = false;
    console.log('Captured image:', this.image);
    this.mark_Attend = false
    this.showimage_flag = false
    this.punchin_flag = false
    this.final_submit = false
    let formData = new FormData();


    let obj :any= new Object();
    obj['b_acct_id'] = this.erpUser.b_acct_id;

    obj['user_id'] = this.erpUser.user_id;
    obj['latitude'] = this.latitude
    obj['longitude'] = this.longitude
    obj['emp_name'] = this.erpUser.party_name
    obj['life_cycle_status'] = 'ACTIVE'
    obj['hierarchy_id'] = this.hierid
    obj["le_id"] = this.erpUser.le_id
    obj['zone_id'] = this.empdetail['zone']
    obj["emp_id"] = this.empdetail['emp_id']
    obj['doc_type'] = 'ATTENDANCE_PO'
    obj['name'] = this.imageName
    obj['is_punched'] = 1
    // obj['image'] = this.image
    console.log(obj)
    formData.append('image', blobImage, this.imageName);
    formData.append('data', JSON.stringify(obj));
    var resp = await this.mkattendance.MarkAttemdanceOut(formData)
    console.log(resp)
    if (resp["error"] == false) {
      Swal.fire(resp.heading, resp.data, resp.icon)
      this.turn_on_flag = false
      this.turn_off_cam = false
      this.mark_Attend = false
      this.punchin_flag = false
      this.imgshow = false
      this.punchout_flag = false
      this.final_submit = false
      this.mark_Attend1 = false
      this.final_submit_punch_out = false
      this.table = true
      // this.close()
      await this.getallpunchdata1()
      await this.open_list()
      // this.punchobj['date']=''
    }
    else {
      this.turn_on_flag = false
      this.turn_off_cam = false
      this.mark_Attend = false
      this.punchin_flag = false
      this.imgshow = false
      this.punchout_flag = false
      this.final_submit = false
      this.mark_Attend1 = false
      Swal.fire(resp.heading, resp.data, resp.icon)
    }
  }
  refresh() {
    // Add logic to refresh the component if needed
  }

  async open_create() {

    // this.list_flag = false;
    // this.create_flag = true;
    var obj :any= {}
    obj["b_acct_id"] = this.b_acct_id
    obj["le_id"] = this.erpUser.le_id
    obj["date"] = this.currentDate2
    console.log(obj)
    var resp = await this.mkattendance.getpunchdata(obj)
    console.log(resp)
    if (resp["error"] == false) {
      // console.log(resp.data[0].is_punched)
      if (resp.data.length == 0) {

        this.list_flag = false;
        this.create_flag = true;

      }
      else if (resp.data[0].is_punched == 0) {

        this.list_flag = false;
        this.create_flag = true;
      }
      else if (resp.data[0].is_punched == 1) {
        Swal.fire('Info', "Already Punch In And Punch Out On This Date", 'info')
        // this.table = true
      }
      this.punchobj['date'] = this.currentDate2
      this.datasource = new MatTableDataSource(resp.data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort
    }
  }
}
