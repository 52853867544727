import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from "../../service/main.service";
import { FaqService } from '../../service/faq.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
declare var $:any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {
goToUpdate() {

}

  constructor(
    public mainService: MainService, private faqService: FaqService, private spinner: NgxSpinnerService

  ) { }
  ebillUser:any
  b_acct_id:any
  is_update_open:any
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.ebillUser["b_acct_id"];
  }


  displayedColumns = [
    "id",
    "category",
    "question",
    "answer",
    "action",
  ];
  faqObj:any  = {}
  list_flag: boolean = true;
  create_flag: boolean = false;
  update_flag: boolean = false;

  datasource:any ;

  goToList() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
    this.addquestions = []
  }
  goToCreate() {
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
    this.addquestions = []
    this.addQuestionRow()
  }
  // goToUpdate() {
  //   this.list_flag = false;
  //   this.create_flag = false;
  //   this.update_flag = true;
  // }


  detele(element:any ) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  async open_update(element:any ) {
    await this.getFaqDataUsingId(element)
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  addRow(i:any , j:any ) {
    this.addquestions[i]['question_data'].push({ question: null, answer: null })
  }

  deleteRow(i:any , j:any ) {
    this.addquestions[i]['question_data'].splice(j, 1);
  }


  addquestions:any  = []
  addQuestionRow() {
    this.addquestions.push({
      category: 0,
      question_data: [{ question: null, answer: null }]
    })
  }
  deleteQuestionRow(i:any ) {
    this.addquestions.splice(i, 1);
  }


  async getFaqDataUsingId(element:any ) {
    this.spinner.show()
    let obj:any  = new Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['faq_id'] = element.faq_id;
    let resp:any  = await this.faqService.getFaqDataUsingId(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.addquestions = []
      let setObj:any  = {}
      setObj['category'] = resp['data'][0]['category'];
      setObj['question_data'] = resp['data']
      this.addquestions.push(setObj)
      console.log(this.addquestions);
      this.spinner.hide();
    } else {
      Swal.fire('Error ', 'Error while Updating', 'error');
      this.spinner.hide();
    }
  }

  async getfaqData() {
    this.spinner.show()
    this.faqObj['b_acct_id'] = this.b_acct_id
    let resp :any = await this.faqService.getfaq(this.faqObj);
    if (resp["error"] == false) {
      this.datasource = new MatTableDataSource(resp['data']);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
      this.spinner.hide();
    } else {
      this.spinner.hide();

    }
  }

  isError: boolean = false;
  async validate() {
    this.isError = false;
    for (let i = 0; i < this.addquestions.length; i++) {
      this.addquestions[i]['category'] = this.addquestions[i]['category'].trim()
      if (!this.addquestions[i]['category']) {
        this.isError = true;
        Swal.fire('Info', 'Please Select Category', 'info');
        break;
      }

      for (let j = 0; j < this.addquestions[i]['question_data'].length; j++) {
        if (!this.addquestions[i]['question_data'][j]['answer'] || !this.addquestions[i]['question_data'][j]['question']) {
          this.isError = true;
          Swal.fire('Info', 'Please Fill All Mandatory Field of ' + this.addquestions[i]['category'], 'info');
          break;
        }

      }
    }
  }
  async finaldelete(element:any ) {
    let obj = Object.assign({}, element)
    let resp = await this.faqService.deletefaqs(JSON.stringify(obj));

    if (resp['error'] == false) {
      Swal.fire('SUCCESS', 'Deleted Successfully', 'success');
      await this.getfaqData()
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', "Error Occurred While Deleting", 'error');
    }
  }

  async submit() {
    this.validate()
    if (this.isError) {
      return
    }
    this.spinner.show()
     let obj:any ={}
    obj['data'] = this.addquestions;
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.ebillUser.user_id;
    obj['acc_short_name'] = this.ebillUser.account_short_name;
    let resp = await this.faqService.addAllfaqs(obj);

    if (resp['error'] == false) {
      this.goToList()
      await this.getfaqData();
      this.addquestions = []
      $('.nav-tabs a[href="#tab-1"]').tab("show");
      this.isError = false;
      this.spinner.hide()
      Swal.fire('SUCCESS', "Added Successfully", 'success');
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', "Error while Adding FAQ's", 'error');
    }

  }


 async update() {
    this.validate()
    if (this.isError) {
      return
    }
    this.spinner.show()
     let obj:any ={}
    obj['data'] = this.addquestions;
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.ebillUser.user_id;
    obj['acc_short_name'] = this.ebillUser.account_short_name;
    let resp = await this.faqService.updatefaqs(obj);

    if (resp['error'] == false) {
      this.goToList()
      await this.getfaqData();
      this.addquestions = []
      $('.nav-tabs a[href="#tab-1"]').tab("show");
      this.isError = false;
      this.spinner.hide()
      Swal.fire('SUCCESS', "Updated Successfully", 'success');
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', "Error while Updating FAQ's", 'error');
    }

  }
}
