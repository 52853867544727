import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class ProjectBankAccService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;
  }


  async delete(obj:any){
    const resp = await this.http.delete<any>(this.httpUrl + '/accounts/projectBank/deleteProjectBankAccounts' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async create(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/accounts/projectBank/createProjectBankAccounts', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getProjectBank(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/accounts/projectBank/getProjectBankAccounts' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async update(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/accounts/projectBank/updateProjectBankAccounts', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  //Fixed Asset And Depriciation Mapping
  async saveFixedAssetMapping(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/accounts/projectBank/fixedAssetMapping', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getFixedAssetMapping(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/accounts/projectBank/getfixedAssetMapping' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

}
