import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { EmdCommonService } from '../service/emd-common.service';
import { EmdHierarchyService } from '../service/emd-hierarchy.service';
import { MainService as AdminMainService } from '../../admin/service/main.service'
import { MainService as EmdMainService } from '../service/main.service';
import { GatewayMidConfigService } from '../../accounts/service/gateway-mid-config.service';
import { FileUploader } from 'ng2-file-upload';
import { TenderService } from '../service/tender.service';
import { MainService as main } from '../service/main.service'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { ApprovalUserService } from '../../portal/service/approval-user.service';
import { MainService as mainserviceEmb } from '../../emb/service/main.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-tender',
  templateUrl: './tender.component.html',
  styleUrls: ['./tender.component.css']
})
export class TenderComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private hierarchyS: EmdHierarchyService,
    private commonS: EmdCommonService, private adminMainService: AdminMainService,
    private GatewayMidConfig: GatewayMidConfigService, public emdMainService: EmdMainService,
    private tenderService: TenderService, private main: main,
    private sanitizer: DomSanitizer, public ApprovalUserService: ApprovalUserService, private mainserviceEmb: mainserviceEmb,
    private toastr: ToastrService,private changeDetectorRef:ChangeDetectorRef) { }



@ViewChild('paginate',{static:false}) paginate!: MatPaginator;


isLinear = false;
  first:any
  second:any
  third:any
  erpUser:any
  b_acct_id:any
  user_id:any
  uploader1:any
  tenderObj:any = new Object()
  transtionObj:any = new Object()
  authorityObj :any= new Object()
  //
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.getTender();

    await this.getactivity();
    await this.getbudhier();
    await this.getprodhier();
    await this.getproject();
    await this.getGatwayList();
    await this.getConfigureData();
    this.uploader1 = new FileUploader({ url: this.emdMainService.httpUrl, itemAlias: 'image' });
    this.uploader1.onAfterAddingFile = (file:any) => { file.withCredentials = false; };
    // await this.getAllEmployees();
  }

  emdFeeType = [{ type: 'Fixed' }, { type: 'Percentage' }]
  doc_amount_depend :any= [{ value: 'Tender Velue', code: 'TENDERVALUE' }]
  document_details:any = [{ doc_type: null, doc_name: null, upload_file: null }]
  // cover_information = [{ cover_dtl: null, cover_desc: null, cover_type: null }, { cover_dtl: null, cover_desc: null, cover_type: null }]
  cover_information:any = []
  datasource!: MatTableDataSource<unknown>;
  displayedColumns:any  = ['Sr.No', 'tender_id', 'ref_no', 'work_title', 'publish_date', 'last_date_bit_sub', 'bit_opning_date', 'tender_amount', 'document_amount', 'emd_amount', 'status', 'action']
  numberOfCoverInformation(cover_no:any, data:any) {
    if (data.length > 0) { // this is for update
      for (let i = 0; i < data.length; i++) {
        this.cover_information.push({ doc_formate: null, cover_desc: null, cover_type: null, cover_no: (i + 1) })
      }
    } else {
        this.cover_information = []
        for (let i = 0; i < Number(cover_no); i++) {
          this.cover_information.push({ doc_formate: null, cover_desc: null, cover_type: null, cover_no: (i + 1) })
      }
    }

  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  list: boolean = true;
  create: boolean = false
  update_tender: boolean = false
  details: boolean = false
  cancle_tender: boolean = false;
  async backToList() {
    this.list = true;
    this.create = false
    this.update_tender = false
    this.details = false;
    this.cancle_tender = false;
    await this.refresh()
    await this.getTender()
  }

  async backToCreate() {
    await this.refresh();
    await this.addDocumentRow();
    this.list = false;
    this.create = true;
    this.update_tender = false
    this.details = false;
    this.cancle_tender = false;
  }

  // update_data;
  backToUpdate() {
    this.list = false;
    this.create = false
    this.update_tender = true;
    this.details = false;
    this.cancle_tender = false;
  }
  cancelObj:any={}
  openCalcel(element:any){
    this.cancelObj= element;
    $('#cancel').modal('show');
  }

  // backToCancle() {
  //   this.list = false;
  //   this.create = false
  //   this.update_tender = false;
  //   this.details = false
  //   this.cancle_tender = true;
  // }


  addDocumentRow() {
    console.log("document_details ", this.document_details);

    this.document_details.push({ doc_type: null, doc_name: null, upload_file: null })
  }

  removeDocumentRow(i:any) {
    this.document_details.splice(i, 1);
  }
  numOfGatway:any = []
  addPaymentGatway() {
    this.numOfGatway.push(1)
  }
  removePaymentGatway(k:any) {
    this.numOfGatway.splice(k, 1);
  }

  activity_arr:any = []
  async getactivity() {
    let obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'EMD'
    obj['table_name'] = 'activity_hier';
    let resp:any = await this.hierarchyS.getActivityHierarchy(obj)
    if (resp['error'] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'EMD-root'
        }
      }
      this.activity_arr = resp['data']
      console.log(resp['data'], 'activity Hier')

    } else {
      this.spinner.hide()
      Swal.fire('Error...', 'Error While Getting Cost Center', 'error')
    }
  }

  budhier = []
  async getbudhier() {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'bud_hier';
    this.spinner.show();
    var resp:any = await this.hierarchyS.getBudgetHierarchy(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      let data = resp['data']
      this.budhier = []
      this.budhier = data
      console.log(data, 'budget hier')
    }
    else {
      this.spinner.hide();
      Swal.fire('ERROR', `${resp['data']}`, 'error')
    }
  }
  prodhier = []
  async getprodhier() {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'prod_hier';
    this.spinner.show();
    var resp:any = await this.hierarchyS.getproductHierarchy(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      let data = resp['data']
      this.prodhier = []
      this.prodhier = data
      console.log(data, 'product hier')
    }
    else {
      this.spinner.hide();
      Swal.fire('ERROR', `${resp['data']}`, 'error')
    }
  }
  projectKeyValue:any = {}
  projectLeafCode:any = {}
  project_hier:any = []
  level11:any = []
  project_obj :any= {}
  act_obj:any = {}
  bud_obj :any= {}
  prod_obj:any = {}
  bud_arr :any= []
  objToSubmit :any= {}
  level1:any = []
  level2:any = []
  level3:any = []
  level4 :any= []
  level5 :any= []
  level6:any = []
  level7:any = []
  leaf_level:any = 0
  async getproject() {
    let obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'EMD'
    let resp:any = await this.hierarchyS.getprojectHierarchy(obj)
    console.log(resp)
    for (let i = 0; i < resp.data.length; i++) {
      this.projectKeyValue[resp['data'][i]['leaf_cd']] = resp['data'][i]
      this.projectLeafCode[resp['data'][i]['leaf_user_cd']] = resp['data'][i]
      if (resp.data[i]['lvl1_value'] == "All Projects") {
        resp.data[i]['lvl1_value'] = 'EMD-root'
      }
    }
    this.project_hier = resp['data']
    let arr:any = []
    let duumy = []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!arr.includes(this.project_hier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.project_hier[i]['lvl1_cd'], lvl1_value: this.project_hier[i]['lvl1_value'], lvl1_node_type: this.project_hier[i]['lvl1_node_type'] })
        arr.push(this.project_hier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy

  }



  project_arr:any = []
  prod_arr:any = []
  selectProject() {
    this.project_obj = Object.assign({}, {})
    this.project_arr = []
    this.project_arr = [{}]
    let arr:any= []
    let duumy = []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!arr.includes(this.project_hier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.project_hier[i]['lvl1_cd'], lvl1_value: this.project_hier[i]['lvl1_value'], lvl1_node_type: this.project_hier[i]['lvl1_node_type'] })
        arr.push(this.project_hier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER').modal('show');
  }
  selectProduct() {
    this.prod_obj = Object.assign({}, {})
    this.prod_arr = []
    this.prod_arr = [{}]
    let arr:any = []
    let duumy :any= []
    for (let i = 0; i < this.prodhier.length; i++) {
      if (!arr.includes(this.prodhier[i]['lvl1_cd'])) {
        duumy.push({
          lvl1_cd: this.prodhier[i]['lvl1_cd'],
          lvl1_value: this.prodhier[i]['lvl1_value'],
          lvl1_node_type: this.prodhier[i]['lvl1_node_type']
        })
        arr.push(this.prodhier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER_prod').modal('show');
  }

  selectActivity() {
    this.act_obj = Object.assign({}, {})
    this.bud_arr = []
    this.act_arr = [{}]
    let arr :any= []
    let duumy :any= []
    for (let i = 0; i < this.activity_arr.length; i++) {
      if (!arr.includes(this.activity_arr[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.activity_arr[i]['lvl1_cd'], lvl1_value: this.activity_arr[i]['lvl1_value'], lvl1_node_type: this.activity_arr[i]['lvl1_node_type'] })
        arr.push(this.activity_arr[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER_act').modal('show');
  }

  selectBudget() {
    this.bud_obj = Object.assign({}, {})
    this.bud_arr = []
    this.bud_arr = [{}]
    let arr :any= []
    let duumy = []
    for (let i = 0; i < this.budhier.length; i++) {
      if (!arr.includes(this.budhier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.budhier[i]['lvl1_cd'], lvl1_value: this.budhier[i]['lvl1_value'], lvl1_node_type: this.budhier[i]['lvl1_node_type'] })
        arr.push(this.budhier[i]['lvl1_cd'])  // understood very well
      }
    }
    this.level11 = duumy
    $('#ACTHIER_bud').modal('show');
  }

  admindata = []
  async getGatwayList() {
    var obj :any= new Object();
    obj['b_acct_id'] = 0
    var resp:any = await this.adminMainService.getPaymentGatwayData(JSON.stringify(obj));
    console.log(resp, 'gatway list')
    if (resp['error'] == false) {
      this.admindata = resp['data'];
      for (let i = 0; i < this.admindata.length; i++) {
        if (this.admindata[i]['life_cycle_status'] != 'ACTIVE') {
          this.admindata.splice(i, 1)
        }
      }
    }
    else {
      Swal.fire("Error", "server side Error", 'error');
    }
  }


  gatWayList:any = []
  async getConfigureData() {

    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    // console.log(this.Obj)
    var resp:any = await this.GatewayMidConfig.getGatewayConfigureData(JSON.stringify(obj));

    if (resp['error'] == false) {
      this.gatWayList = resp['data']
      console.log(resp)
    }
    else {
      Swal.fire("Error", "server side Error", 'error');
    }
  }


  gateWayId:any
  async getAccountbyGatewayID(gatewayID:any ) {
    console.log(gatewayID)
    this.gateWayId = gatewayID
  }


  gatwayData:any = []
  async clickingcheckbox(i:any, id:any) {

    console.log(i, id)

    this.gatWayList[i].selected = !this.gatWayList[i].selected
    if (this.gatWayList[i]['selected']) {
      console.log(this.gatWayList[i])
      this.gatwayData.push(this.gatWayList[i])
    }
    else {
      for (let index = 0; index < this.gatwayData.length; index++) {
        const element: any = this.gatwayData[index];
        if (element.id == id) {
          this.gatwayData.splice(index, 1)
        }
      }
    }
    this.transtionObj['purpose'] = this.gatWayList[i]['purpose']
    this.transtionObj['mid_id'] = this.gatWayList[i]['mid_id']
    console.log(this.gatwayData)
  }

  async set_Path_desc_act(index:any) {
    console.log(this.activity_arr, index, this.act_obj)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.activity_arr, this.act_obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.activity_arr, this.act_obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.activity_arr, this.act_obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.activity_arr, this.act_obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.activity_arr, this.act_obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.activity_arr, this.act_obj)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.act_obj['lvl' + i + '_cd']) {
        this.act_obj['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.act_obj['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        // this.Obj['project_node_cd'] = this.act_obj['lvl' + i + '_cd']
        this.act_obj['node_cd'] = this.act_obj['lvl' + i + '_cd']
        this.act_obj['lvl' + i + '_user_cd'] = this.act_obj['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.act_obj['lvl' + i + '_cd']
        dummy.push(this.act_obj['lvl' + i + '_value'])
      }
    }
    this.act_obj['project_path_desc'] = dummy.join('-->')
    console.log(this.act_obj, this.level11, this.level2)
  }
  async set_Path_desc(index:any) {
    console.log(this.project_hier, index, this.project_obj)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.project_hier, this.project_obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.project_hier, this.project_obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.project_hier, this.project_obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.project_hier, this.project_obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.project_hier, this.project_obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.project_hier, this.project_obj)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.project_obj['lvl' + i + '_cd']) {
        this.project_obj['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.project_obj['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        // this.Obj['project_node_cd'] = this.project_obj['lvl' + i + '_cd']
        this.project_obj['node_cd'] = this.project_obj['lvl' + i + '_cd']
        this.project_obj['lvl' + i + '_user_cd'] = this.project_obj['lvl' + i + '_user_cd']
        this.project_obj['lvl' + i + '_cd'] = this.project_obj['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.project_obj['lvl' + i + '_cd']
        dummy.push(this.project_obj['lvl' + i + '_value'])
      }
    }
    this.project_obj['project_path_desc'] = dummy.join('-->')
    console.log(this.project_obj, this.level11, this.level2)
  }
  act_arr :any= []
  async add_dropdown2(data:any, index:any) {
    if (index > 4) {
      Swal.fire('Information...', 'You Can Select Till Level 4 In Project Hierarchy', 'info')
      return
    }
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.project_arr.push({})
    }
  }
  async add_dropdown2_act(data:any, index:any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.act_arr.push({})
    }
  }
  remove_dropdown2(data :any, index:any) {
    if (this.project_arr.length > 1) {
      this.project_arr.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.project_obj['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.project_obj['lvl' + i + '_cd']) {
        this.project_obj['leaf_cd'] = this.project_obj['lvl' + i + '_cd']
        this.project_obj['node_cd'] = this.project_obj['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.project_obj['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.project_obj['project_path_desc'] = dummy.join('-->')

  }
  submit_prj_hier() {
    $('#ACTHIER').modal('hide');
    this.project_arr = []
    console.log(this.project_obj)
  }
  submit_prj_hier_act() {

    $('#ACTHIER_act').modal('hide');
    this.act_arr = []
    console.log(this.project_obj)
    console.log(this.act_obj)

  }
  submit_prj_hier_prod() {
    $('#ACTHIER_prod').modal('hide');
    this.prod_arr = []
    console.log(this.project_obj)
    console.log(this.prod_obj)
  }
  submit_prj_hier_bud() {
    $('#ACTHIER_bud').modal('hide');
    this.bud_arr = []
    console.log(this.bud_obj)
  }
  remove_dropdown2_prod(data :any, index:any) {
    if (this.prod_arr.length > 1) {
      this.prod_arr.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.prod_obj['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.prod_obj['lvl' + i + '_cd']) {
        this.prod_obj['leaf_cd'] = this.prod_obj['lvl' + i + '_cd']
        this.prod_obj['node_cd'] = this.prod_obj['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.prod_obj['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.prod_obj['project_path_desc'] = dummy.join('-->')
  }
  async add_dropdown2_prod(data :any, index:any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.prod_arr.push({})
    }
  }
  async set_Path_desc_prod(index :any ) {
    console.log(this.prodhier, index, this.prod_obj)
    if (index == 0) {  //understood very clearly
      this.level2 = await this.commonS.onChangeLvl1(this.prodhier, this.prod_obj) // understood
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.prodhier, this.prod_obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.prodhier, this.prod_obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.prodhier, this.prod_obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.prodhier, this.prod_obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.prodhier, this.prod_obj)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.prod_obj['lvl' + i + '_cd']) {
        this.prod_obj['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.prod_obj['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        // this.Obj['project_node_cd'] = this.prod_obj['lvl' + i + '_cd']
        this.prod_obj['node_cd'] = this.prod_obj['lvl' + i + '_cd']
        this.prod_obj['lvl' + i + '_user_cd'] = this.prod_obj['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.prod_obj['lvl' + i + '_cd']
        dummy.push(this.prod_obj['lvl' + i + '_value'])
      }
    }
    this.prod_obj['project_path_desc'] = dummy.join('-->')
    console.log(this.prod_obj, this.level11, this.level2)
  }

  remove_dropdown2_bud(data :any, index:any) {
    if (this.bud_arr.length > 1) {
      this.bud_arr.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.bud_obj['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.bud_obj['lvl' + i + '_cd']) {
        this.bud_obj['leaf_cd'] = this.bud_obj['lvl' + i + '_cd']
        this.bud_obj['node_cd'] = this.bud_obj['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.bud_obj['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.bud_obj['project_path_desc'] = dummy.join('-->')

  }
  async add_dropdown2_bud(data :any, index:any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.bud_arr.push({})
    }
  }
  async set_Path_desc_bud(index :any ) {
    console.log(this.budhier, index, this.bud_obj)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.budhier, this.bud_obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.budhier, this.bud_obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.budhier, this.bud_obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.budhier, this.bud_obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.budhier, this.bud_obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.budhier, this.bud_obj)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.bud_obj['lvl' + i + '_cd']) {
        this.bud_obj['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.bud_obj['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        // this.Obj['project_node_cd'] = this.bud_obj['lvl' + i + '_cd']
        this.bud_obj['node_cd'] = this.bud_obj['lvl' + i + '_cd']
        this.bud_obj['lvl' + i + '_user_cd'] = this.bud_obj['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.bud_obj['lvl' + i + '_cd']
        dummy.push(this.bud_obj['lvl' + i + '_value'])
      }
    }
    this.bud_obj['project_path_desc'] = dummy.join('-->')
    console.log(this.bud_obj, this.level11, this.level2)
  }
  remove_dropdown2_act(data :any, index:any) {
    if (this.act_arr.length > 1) {
      this.act_arr.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.act_obj['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.act_obj['lvl' + i + '_cd']) {
        this.act_obj['leaf_cd'] = this.act_obj['lvl' + i + '_cd']
        this.act_obj['node_cd'] = this.act_obj['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.act_obj['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.act_obj['project_path_desc'] = dummy.join('-->')
  }
  selectedFile: any = [];
  doc_name:any = [];

  imageUrl:any = []
  async onFileUpload(event: any, files: any, index:any) {
    if (event.target.files && event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {
        // this.document_details[index]['doc_name'] = event.target.files[i]['name']
        if ((this.selectedFile.length - 1) < index) {
          this.selectedFile.push(event.target.files[i])
          this.doc_name.push(event.target.files[i]['name'])
        } else {
          this.selectedFile[i] = event.target.files[i];
          this.doc_name[i] = event.target.files[i]['name']
        }
        this.document_details[index]['doc_name'] = this.selectedFile[index]['name']
        console.log(this.document_details)
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageUrl.push(event.target.result);
        }
        reader.readAsDataURL(event.target.files[i]);
      }

    }
    console.log("selectedFile : ", this.selectedFile)
    console.log("doc_name : ", this.doc_name)
  }


  async getTender() {
    let tender_data = []
    let obj :any = {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp:any = await this.tenderService.getTender(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp['data']);
      tender_data = resp['data'];
      tender_data.map((x:any) => {
        if (x['doc_amount_type'] == "Percentage") {
          x['doc_amount'] = ((x['tender_value'] * x['doc_amount']) / 100).toFixed(2);
        }
        if (x['emd_amount_type'] == "Percentage") {
          x['emd_amount'] = ((x['tender_value'] * x['emd_amount']) / 100).toFixed(2);
        }
      })


      this.datasource = new MatTableDataSource(resp['data']);
      this.datasource.paginator = this.paginate;
      this.changeDetectorRef.detectChanges()
      console.log(this.datasource)
    } else {
      Swal.fire('ERROR', 'Error While Getting Tender Information', 'error');
    }
  }

  isError: boolean = false;
  async filedVerification() {
    if (!this.tenderObj['tender_type'] || !this.tenderObj['tender_ref_no'] || !this.tenderObj['form_of_contract']
      || !this.tenderObj['cover_no'] || !this.tenderObj['payment_mode'] || !this.tenderObj['publish_date'] || !this.tenderObj['bid_opening_date']
      || !this.tenderObj['sale_start_date'] || !this.tenderObj['sale_end_date'] || !this.tenderObj['prebid_meeting_date'] || !this.tenderWork['work_name']
      || !this.tenderWork['tender_value'] || !this.tenderWork['bid_validity'] || !this.tenderWork['work_period']
      || !this.transtionObj['doc_amount_type'] || !this.transtionObj['doc_amount'] || !this.transtionObj['emd_amount_type'] || !this.transtionObj['emd_amount']
      || !this.authorityObj['name'] || !this.authorityObj['designation'] || !this.authorityObj['contact_detail'] || !this.authorityObj['department']
    ) {
      this.isError = true
      Swal.fire('ERROR', 'Please Fill All Mandatory Field', 'error');
    }
    return this.isError;
  }

  tenderWork :any= {}
  async submit() {
    await this.filedVerification()
    if (this.isError) {
      return;
    }
    this.document_details.map((x:any, i:any) => {
      x['doc_name'] = this.doc_name[i]
    })

    let obj :any = {}
    this.tenderObj['project_cd'] = this.project_obj['node_cd']
    this.tenderObj['budget_cd'] = this.bud_obj['node_cd']
    this.tenderObj['product_cd'] = this.prod_obj['node_cd']
    this.tenderObj['activity_cd'] = this.act_obj['node_cd']
    this.transtionObj['gateWayId'] = this.gateWayId;
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.erpUser.user_id;
    obj['tenderObj'] = this.tenderObj;
    obj['tenderWork'] = this.tenderWork;
    obj['transation'] = this.transtionObj;
    obj['document_details'] = this.document_details;
    obj['cover'] = this.cover_information;
    obj['authority'] = this.authorityObj;
    obj['document_details'] = this.document_details;

    let formData = new FormData();
    for (let i = 0; i < this.selectedFile.length; i++) {
      formData.append("image", this.selectedFile[i])
    }
    formData.append("data", JSON.stringify(obj))
    let resp:any = await this.tenderService.submitTender(formData);
    if (resp['error'] == false) {
      Swal.fire('SUCCESS', 'Submitted Successfully', 'success');
      this.gateWayId=0;
      this.backToList()
    } else {
      Swal.fire('ERROR', 'Some Erorr Occurred While Submmiting', 'error');
    }
  }


  async openView() {
    this.list = false;
    this.create = false
    this.update_tender = false
    this.details = true;
    this.cancle_tender = false;
  }

  async refresh() {
    this.tenderObj = {}
    this.tenderWork = {}
    this.transtionObj = {}
    this.document_details = []
    this.cover_information = []
    this.authorityObj = {}
    this.project_obj = {}
    this.act_obj = {}
    this.bud_obj = {}
    this.prod_obj = {}
  }


  //This methods set path on upload time

  async setProjectHierarchyPath() {
    let obj :any = {}
    this.project_hier.map((x:any) => {
      if (x['leaf_cd'] == this.tenderObj['project_cd']) {
        obj = x;
      }
    })

    let dummy = []
    for (let i = 1; i <= 7; i++) {
      if (obj['lvl' + i + '_value']) {
        dummy.push(obj['lvl' + i + '_value'])
      }
    }
    this.project_obj['project_path_desc'] = dummy.join('-->')
  }

  async setProductHierarchyPath() {
    let obj :any = {}
    this.prodhier.map(x => {
      if (x['leaf_cd'] == this.tenderObj['product_cd']) {
        obj = x;
      }
    })

    let dummy = []
    for (let i = 1; i <= 7; i++) {
      if (obj['lvl' + i + '_value']) {
        dummy.push(obj['lvl' + i + '_value'])
      }
    }
    this.prod_obj['project_path_desc'] = dummy.join('-->')


  }

  async setBudgetHierarchyPath() {
    let obj :any = {}
    this.budhier.map(x => {
      if (x['leaf_cd'] == this.tenderObj['budget_cd']) {
        obj = x;
      }
    })

    let dummy = []
    for (let i = 1; i <= 7; i++) {
      if (obj['lvl' + i + '_value']) {
        dummy.push(obj['lvl' + i + '_value'])
      }
    }
    this.bud_obj['project_path_desc'] = dummy.join('-->')

  }

  async setActivityHierarchyPath() {
    let obj :any = {}
    this.activity_arr.map((x:any) => {
      if (x['leaf_cd'] == this.tenderObj['activity_cd']) {
        obj = x;
      }
    })

    let dummy = []
    for (let i = 1; i <= 7; i++) {
      if (obj['lvl' + i + '_value']) {
        dummy.push(obj['lvl' + i + '_value'])
      }
    }
    this.act_obj['project_path_desc'] = dummy.join('-->')

  }
  //This methods set path on upload time

  async viewDetails(element:any, falg:any) {
    await this.refresh();
    let obj :any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['tender_id'] = element.tender_id;
    obj['gateWayId'] = element.gateWayId;

    let resp:any = await this.tenderService.viewDetails(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.tenderObj = resp['tender_dtls'][0]; //tender_details
      this.cover_information = resp['tender_cover'] // cover_information
      this.document_details = resp['tender_uploads'] //tender_uploads
      // this.transtionObj = resp['tender_gateway'][0]


      //This action will performe update time
      if (falg == 'update') {
        await this.backToUpdate()
        // this.numberOfCoverInformation(this.tenderObj['cover_no'], this.cover_information);
        this.tenderWork = this.tenderObj;
        this.authorityObj = this.tenderObj;
        this.transtionObj = this.tenderObj;
        this.gateWayId = resp['tender_gateway'][0]['gateway_id']
        let mid_id = resp['tender_gateway'][0]['mid_id']

        this.act_obj['node_cd'] = this.tenderObj['activity_cd'];
        this.prod_obj['node_cd'] = this.tenderObj['product_cd'];
        this.bud_obj['node_cd'] = this.tenderObj['budget_cd'];
        this.project_obj['node_cd'] = this.tenderObj['project_cd'];

        let gate_index = -1;
        for (let i = 0; i < this.gatWayList.length; i++) {
          if (this.gatWayList[i]['mid_id'] == mid_id) {
            gate_index = i;
          }
        }
        await this.clickingcheckbox(gate_index, this.gateWayId)

        await this.setProjectHierarchyPath()
        await this.setProductHierarchyPath()
        await this.setActivityHierarchyPath()
        await this.setBudgetHierarchyPath()





      } else {
        await this.openView();


      }
      console.log(this.tenderObj);
      console.log(this.cover_information);
      console.log(this.document_details);
      console.log(this.transtionObj);
    } else {
      Swal.fire('ERROR', 'Some Erorr Occurred While Getting Data', 'error');
    }

  }

  openLink(url:string){
    window.open(url,'_blank')
  }

  imgURL:any

  async viewDoc(element :any) {
    var obj :any= new Object();
    obj = element;
    obj['b_acct_id'] = this.b_acct_id
    obj['type'] = 'TENDER_DOC'
    const res = await this.tenderService.viewDoc(obj);
    this.spinner.show()
    if (res) {
      let docname = obj["doc_name"];
      let ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        this.spinner.hide()
      } else if (ext[1].toLowerCase() == 'xlsx') {
        let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        this.spinner.hide()
      } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {
        let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.spinner.hide()
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.spinner.hide()
      }
    } else {
      this.spinner.hide()
    }

  }

  async update() {
    await this.filedVerification()
    if (this.isError) {
      return;
    }


  //   if(this.doc_name.length > 0){
  //   this.document_details.map((x, i) => {
  //     if(!this.doc_name[i]){
  //       x['doc_name'] = this.doc_name[i]
  //     }
  //   })
  // }


  this.doc_name.map((x:any,i:any)=>{
    this.document_details[i]['doc_name'] = x;
    this.document_details[i]['new_update'] = true;

  })
    let obj :any = {}
    this.tenderObj['project_cd'] = this.project_obj['node_cd']
    this.tenderObj['budget_cd'] = this.bud_obj['node_cd']
    this.tenderObj['product_cd'] = this.prod_obj['node_cd']
    this.tenderObj['activity_cd'] = this.act_obj['node_cd']
    this.transtionObj['gateWayId'] = this.gateWayId;

    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.erpUser.user_id;
    obj['tenderObj'] = this.tenderObj;
    obj['tenderWork'] = this.tenderWork;
    obj['transation'] = this.transtionObj;
    obj['document_details'] = this.document_details;
    obj['cover'] = this.cover_information;
    obj['authority'] = this.authorityObj;
    let formData = new FormData();
    for (let i = 0; i < this.selectedFile.length; i++) {
      formData.append("image", this.selectedFile[i])
    }
    formData.append("data", JSON.stringify(obj))


    let resp:any = await this.tenderService.updateTender(formData);
    if (resp['error'] == false) {
      Swal.fire('SUCCESS', 'Submitted Successfully', 'success');
      this.doc_name=[]
      this.tenderObj={}
      this.transtionObj ={}
      this.backToList()
    } else {
      Swal.fire('ERROR', 'Some Erorr Occurred While Submmiting', 'error');
    }
  }

  async cancel() {
    this.cancelObj['b_acct_id'] = this.b_acct_id;
    this.cancelObj['user_id'] = this.erpUser.user_id;


    let resp:any = await this.tenderService.cancleTender(this.cancelObj);
    if (resp['error'] == false) {
      Swal.fire('SUCCESS', 'Successfully Canclled', 'success');
      $('#cancel').modal('hide');
      await this.getTender();
    } else {
      Swal.fire('ERROR', 'Some Erorr Occurred While Canclling Tender', 'error');
    }
  }


  /**************************************************************Approval Code Start******************************************* */
  undertakings:any
  salBill_apprdata:any
  async Appr(element :any) {
    $('#workflowmodel').modal('show');

    console.log(element)
    this.salBill_apprdata = Object.assign({}, element)
    this.salBill_apprdata['bill_desc'] = 'Approval for Tender : ' + element['id']
    this.salBill_apprdata['level'] = 1
    this.salBill_apprdata['branch'] = 1
    this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
    this.salBill_apprdata['doc_type'] = 'TENDER REPORT'
    this.salBill_apprdata['node_cd'] = element['node_cd']
    this.salBill_apprdata['module_cd'] = 'EMD';
    await this.ApprovalUserService.getWorkflowlog(this.erpUser, 'TENDER REPORT', 'EMD');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      // await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'APPROVED')
      return;
    }
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.erpUser, this.salBill_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.erpUser, this.salBill_apprdata)
  }


  forwarded_user_id:any;
  appr_remark:any
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb :any= new Object();
      obb['user_id'] = this.erpUser.user_id
      obb['b_acct_id'] = this.erpUser.b_acct_id
      obb['forwarded_by'] = this.erpUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'TENDER REPORT'
      obb['doc_local_no'] = this.salBill_apprdata['id']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
      obb['module_cd'] = 'EMD'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      obb['level'] = this.ApprovalUserService.level1['level']
      obb['undertaking'] = JSON.stringify([])
      obb['branch'] = this.ApprovalUserService.level1['branch']
      obb['user_name'] = this.erpUser.party_name;
      obb['field_value'] = 0
      var resp:any = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.ChangeTenderStatus(this.salBill_apprdata['id'], 'UNDERAPPROVAL')
        await this.getTender();
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          if (this.message_flag == true) {
          }

        } else {
          if (this.message_flag == true) await this.getcurrentlegalentity(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        this.toastr.success("Approval Forwarded Successfully!!")
        console.log("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        console.log(resp['data'])
      }
    } else {
      this.spinner.hide();
    }
  }
  message_flag = false

  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb :any= new Object();

      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.erpUser.b_acct_id
      obb['forwarded_by'] = this.erpUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'TENDER REPORT'
      obb['doc_local_no'] = this.salBill_apprdata['id']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['user_name'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['emp_name']
      obb['module_cd'] = 'EMD'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.salBill_apprdata['level']
        obb['branch'] = this.salBill_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      obb['field_value'] = 0
      var resp:any = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {

        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
    }
  }

  async ChangeTenderStatus(data:any, status:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = status;
    obj['id'] = data
    this.spinner.show();
    var resp:any = await this.tenderService.changeTenderStatus(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log('Successfully Chaged to ' + status)
    }
    else {
      this.spinner.hide();
      this.toastr.error(resp['data']);
    }
  }


  async getcurrentlegalentity(user_id:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp:any = await this.mainserviceEmb.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      await this.sendmessage(resp['data'][0]['phone_no'], resp['data'][0]['party_name'])
      this.spinner.hide();
      this.message_flag = false
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }

  async sendmessage(mobile:any, user_name:any) {
    let resp:any = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'], this.salBill_apprdata['id'], user_name)
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }

  statusArr1:any = [];
  selectedEle:any;
  userRoles:any = []

  async viewStatus(element :any) {
    this.selectedEle = Object.assign({}, element);
    var obj :any= new Object();
    obj['doc_local_no'] = element.id;
    obj['b_acct_id'] = this.erpUser.b_acct_id;
    obj['doc_type'] = 'TENDER REPORT'
    obj['module_cd'] = 'EMD'
    var resp:any = await this.mainserviceEmb.getWorkflowlog(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.salBill_apprdata = Object.assign({}, element)
      this.salBill_apprdata['level'] = 1
      this.salBill_apprdata['branch'] = 1
      this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
      this.salBill_apprdata['doc_type'] = 'TENDER REPORT'
      this.salBill_apprdata['node_cd'] = element['project_cd']
      this.salBill_apprdata['module_cd'] = 'EMD';
      await this.getWorkflowloguser()
      console.log(this.userRoles, resp.data, 'user')
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = "Contractor"
          resp.data[i]['role_cd'] = "Contractor"
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }
        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }
      this.statusArr1 = resp.data
      console.log(this.statusArr1);
      $('#workflowStatus').modal('show');
    } else {
      this.toastr.error(resp['data'])
    }
  }
  workflowuser = []
  rolecdtolevelobj = {}
  selectedTender = {}
  async getWorkflowloguser() {
    var obj :any= new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'EMD'
    obj['status'] = 'ACTIVE'
    obj['node_cd'] = this.salBill_apprdata['node_cd']
    console.log(obj, this.selectedTender)
    var resp:any = await this.mainserviceEmb.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflowuser = resp['data']

    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }



  }
  /**************************************************************Approval Code End******************************************* */

}



/*********************************** Unused Code *********************************************** */

 // async upload(tender_id) {
  //   this.document_details.map((x, i) => {
  //     x['doc_name'] = this.doc_name[i]
  //   })

  //   if (this.selectedFile != null) {
  //     this.spinner.show();

  // let formData = new FormData();
  //   for (let i = 0; i < this.selectedFile.length; i++) {
  //     formData.append("image", this.selectedFile[i])
  //   }
  //     const obj = new Object();
  //     obj['b_acct_id'] = this.b_acct_id;
  //     obj['document_details'] = this.document_details;
  //     obj['user_id'] = this.erpUser.user_id;
  //     obj['tender_id'] = tender_id
  //     formData.append("data", JSON.stringify(obj))
  //     let resp:any = await this.tenderService.uploadDocument(formData);
  //     if (resp['error'] == false) {
  //       Swal.fire('SUCCESS', 'Submitted Successfully', 'success');
  //       await this.getTender();
  //     } else {
  //       Swal.fire('ERROR', 'Some Erorr Occurred While Submmiting', 'error');
  //     }


  //   }
  // }
