import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-property-footer]',
  templateUrl: './property-footer.component.html',
  styleUrls: ['./property-footer.component.css']
})
export class PropertyFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
