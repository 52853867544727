import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ChartOfAccountService } from '../../../service/chart-of-account.service';
import { SettingService } from '../../../service/setting.service'
import { MainService } from '../../../service/main.service';
import { LedgerService } from '../../../service/ledger.service'
import Swal from 'sweetalert2';
import * as  pdfMake from  "pdfmake/build/pdfmake";

import { ExcelService } from '../../../service/file-export.service';
import * as pdfFonts from "pdfmake/build/vfs_fonts"
import { identity } from 'rxjs';

@Component({
  selector: 'app-ledger-report',
  templateUrl: './ledger-report.component.html',
  styleUrls: ['./ledger-report.component.css']
})
export class LedgerReportComponent implements OnInit {

  constructor(private excel: ExcelService, private ChartOfAccountService: ChartOfAccountService, public mainService: MainService, private ledgerService: LedgerService, private spinner: NgxSpinnerService, private settingService: SettingService) { }
  erpUser:any;
  b_acct_id:any;
  allParty:any = []
  obj:any = {}
  C_acc:any = []
  bal :any= []
  acount_name:any = ''

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllChartOfAccount();

    await this.getrepresentative();
    await this.getparty();

    await this.getFinYear();

    this.acount_name = this.mainService.accInfo['account_name']
  }
  res = []
  first_dropdown = []

  obj_exp:any = {}
  assets:any = [];
  liability:any = [];
  equity:any = [];
  income:any = [];
  expense:any = [];

  async getAllChartOfAccount() {
    this.spinner.show()
    let count, start;
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ChartOfAccountService.getchartofaccount(obj);
    if (resp['error'] == false) {
      this.C_acc = resp['data'];
      // resp['data'].map(x => {
      //   if (x['lvl3_code'] == 'CH112' && x['is_leaf'] == 1) {
      //     this.C_acc.push(x);
      //   }
      // })
      this.assets = []
      this.C_acc.map((x:any) => {
        if (x['lvl3_code'] == 'CH112' && x['is_leaf'] == 1) {
          this.assets.push(x);
        }
      })
      this.liability = []

      this.C_acc.map((x:any) => {
        if (x['lvl3_code'] == 'CH111' && x['is_leaf'] == 1) {
          this.liability.push(x);
        }
      })
      this.income = []

      this.C_acc.map((x:any) => {
        if (x['lvl3_code'] == 'CH122' && x['is_leaf'] == 1) {
          this.income.push(x);
        }
      })

      this.expense = []
      this.C_acc.map((x:any) => {
        if (x['lvl3_code'] == 'CH121' && x['is_leaf'] == 1) {
          this.expense.push(x);
        }
      })
      this.equity = []
      this.C_acc.map((x:any) => {
        if (x['lvl4_code'] == 'CH1111' && x['is_leaf'] == 1) {
          this.equity.push(x);
        }
      })




      this.spinner.hide();
    } else {
      this.spinner.hide()
    }
  }


  // change_c_acc() {
  //   // this.assets = this.C_acc;
  //   // this.liability = this.C_acc;
  //   // this.equity = this.C_acc;
  //   // this.income = this.C_acc;
  //   // this.expense = this.C_acc;

  //   this.C_acc.map(x => {
  //     if (x['lvl3_code'] == 'CH112' && x['is_leaf'] == 1) {
  //       this.assets.push(x);
  //     }
  //   })


  //   this.C_acc.map(x => {
  //     if (x['lvl3_code'] == 'CH1113' && x['is_leaf'] == 1) {
  //       this.liability.push(x);
  //     }
  //   })


  //   this.C_acc.map(x => {
  //     if (x['lvl3_code'] == 'CH122' && x['is_leaf'] == 1) {
  //       this.income.push(x);
  //     }
  //   })


  //   this.C_acc.map(x => {
  //     if (x['lvl3_code'] == 'CH121' && x['is_leaf'] == 1) {
  //       this.expense.push(x);
  //     }
  //   })

  //   this.C_acc.map(x => {
  //     if (x['lvl4_code'] == 'CH1111' && x['is_leaf'] == 1) {
  //       this.equity.push(x);
  //     }
  //   })

  //   // for (let i = 0; i < this.C_acc.length; i++) {
  //   //   if (this.C_acc[i]['lvl2_value'] == 'EQUITY') {
  //   //     this.equity.push(this.C_acc[i])
  //   //   }
  //   //   if (this.C_acc[i]['lvl2_value'] == 'ASSET') {
  //   //     this.assets.push(this.C_acc[i])
  //   //   }
  //   //   if (this.C_acc[i]['lvl2_value'] == 'LIABILITY') {
  //   //     this.liability.push(this.C_acc[i])
  //   //   }
  //   //   if (this.C_acc[i]['lvl2_value'] == 'INCOME') {
  //   //     this.income.push(this.C_acc[i])
  //   //   }
  //   //   if (this.C_acc[i]['lvl2_value'] == 'EXPENSE') {
  //   //     this.expense.push(this.C_acc[i])
  //   //   }

  //   // }

  // }






  cureent_year:any = []
  current_start_date:any;
  current_end_date:any;

  fin_obj:any = {}

  async getFinYear() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getAllFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.cureent_year = resp['data']
      this.spinner.hide();
      for (let i = 0; i < this.cureent_year.length; i++) {
        this.fin_obj[this.cureent_year[i]['fin_year']] = this.cureent_year[i]['fin_year'] + "-" + (Number(this.cureent_year[i]['fin_year']) + 1)

        if (this.cureent_year[i]['status'] == 'OPEN') {
          this.obj['fin_year'] = this.cureent_year[i]['fin_year']
          this.obj_lia['fin_year'] = this.cureent_year[i]['fin_year']
          this.obj_ass['fin_year'] = this.cureent_year[i]['fin_year']
          this.obj_exp['fin_year'] = this.cureent_year[i]['fin_year']
          this.obj_rev['fin_year'] = this.cureent_year[i]['fin_year']
          this.current_start_date = this.cureent_year[i]['start_date']
          this.current_end_date = this.cureent_year[i]['end_date']
          await this.setDate()
        }
      }

    } else {
      this.spinner.hide()

    }
  }
  async setDate() {
    this.obj['from_date'] = this.current_start_date;
    this.obj['to_date'] = this.current_end_date;
    this.obj_lia['from_date'] = this.current_start_date;
    this.obj_lia['to_date'] = this.current_end_date;
    this.obj_ass['from_date'] = this.current_start_date;
    this.obj_ass['to_date'] = this.current_end_date;
    this.obj_exp['from_date'] = this.current_start_date;
    this.obj_exp['to_date'] = this.current_end_date;
    this.obj_rev['from_date'] = this.current_start_date;
    this.obj_rev['to_date'] = this.current_end_date;
  }




  reperesentative :any= {}
  async getrepresentative() {
    var resp = await this.settingService.getAllrepresentative(JSON.stringify({ b_acct_id: this.b_acct_id }));
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        this.reperesentative[resp['data'][i]['rep_le_id']] = resp['data'][i]['party_leagal_name']

      }
    }
  }
  all_party:any = [];
  partyNameObj:any = {};
  partyGISTNObj :any= {};
  async getparty() {
    var resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp['error'] == false) {
      this.partyNameObj = {};
      this.partyGISTNObj = {};

      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['party_type'] == 'ORG') {
          resp['data'][i]['party_leagal_name'] = resp['data'][i]['party_leagal_name'] + ' ( ' + this.reperesentative[resp['data'][i]['rep_le_id']] + ')'
        }
        this.partyNameObj[resp['data'][i]['le_id']] = resp['data'][i]['party_leagal_name']
        this.partyGISTNObj[resp['data'][i]['le_id']] = resp['data'][i]['party_gstin_no']

      }


      this.all_party = [{ le_id: 0, party_leagal_name: 'All' }];
      resp['data'].map((x:any) => {
        this.all_party.push(x);
      });
    }
  }
  obj_lia :any= {}
  result:any = []
  final_res:any = []
  res_obj:any = {}
  result1 :any= []
  total :any= 0
  async show() {
    if (this.obj['to_date'] < this.obj['from_date']) {
      this.spinner.hide();
      Swal.fire('ERROR', 'Please Select Correct Date', 'error');
      return;
    }
    this.final_res = []
    this.total = 0
    this.spinner.show()
    this.obj['b_acct_id'] = this.b_acct_id
    this.obj['from_accounting_dt'] = this.obj['from_date']
    this.obj['to_accounting_dt'] = this.obj['to_date']
    this.obj['chart_of_account'] = this.obj['c_acc'];
    for (var i = 0; i < this.C_acc.length; i++) {
      if (this.C_acc[i]['leaf_code'] == this.obj['c_acc']) {
        this.obj['acc_desc'] = this.C_acc[i]['leaf_value']
      }
    }
    this.obj['le_id'] = this.obj['le_id']
    var resp = await this.ledgerService.getLedgerReport(JSON.stringify(this.obj))
    if (resp['error'] == false) {
      this.res_obj = this.obj;
      this.result = []
      this.result = resp['data'];
      for (let i = 0; i < this.result[0].length; i++) {
        if (this.result[0][i]['db_cd_ind'] == 'DB') {
          this.result[0][i]['txn_amt'] = (-1) * (this.result[0][i]['txn_amt'])
        }
      }


      var openObj:any = {};
      openObj['acct_dt'] = this.obj['from_date'];
      openObj['jrnl_desc'] = "Opening Balance";
      openObj['jrnl_type'] = "";
      openObj['jrnl_id'] = "";
      openObj['db'] = 0;
      openObj['cr'] = 0;



      for (let i = 0; i < this.result[1].length; i++) {

        if (this.result[1][i]['db_cd_ind'] == 'DB') {
          openObj['db'] += this.result[1][i]['txn_amt']

        } else {

          openObj['cr'] += this.result[1][i]['txn_amt']
        }

      }
      if (openObj['db'] >= openObj['cr']) {
        openObj['db'] = openObj['db'] - openObj['cr'];
        openObj['cr'] = 0;
      } else {
        openObj['cr'] = openObj['cr'] - openObj['db'];
        openObj['db'] = 0;
      }
      this.final_res.push(openObj);

      for (let i = 0; i < this.result[0].length; i++) {
        if (this.result[0][i]['db_cd_ind'] == 'DB') {
          this.result[0][i]['db'] = this.result[0][i]['txn_amt']
          this.result[0][i]['cr'] = 0
          this.total = this.total - this.result[0][i]['txn_amt']
        } else {
          this.total = this.total + this.result[0][i]['txn_amt']
          this.result[0][i]['cr'] = this.result[0][i]['txn_amt']
          this.result[0][i]['db'] = 0
        }
        this.final_res.push(this.result[0][i])
      }
      let dum :any= {}
      let db:any = 0
      let cr:any = 0
      for (let i = 0; i < this.final_res.length; i++) {
        db = db + this.final_res[i]['db']
        cr = cr + this.final_res[i]['cr']
      }

      dum['cr'] = cr
      dum['db'] = db
      dum['jrnl_desc'] = 'Total'
      this.final_res.push(dum)
      let dum2:any = {}
      let s = 0;
      if (db < cr) {
        s = cr - db
        dum2['db'] = s
      } else {
        s = db - cr
        dum2['cr'] = s
      }
      dum2['jrnl_desc'] = 'Closing Balance'
      let dum3 :any= {}
      if (db > cr) {
        dum3['db'] = db
        dum3['cr'] = s + cr
      }
      else {
        dum3['db'] = s + db
        dum3['cr'] = cr
      }

      this.final_res.push(dum2)
      this.final_res.push(dum3)

      this.spinner.hide()
    }



    else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured', 'error')
    }
  }
  // ---------print
  export1() {
    var exp = []
    for (var i = 0; i < this.final_res.length; i++) {
      var obj:any = new Object();
      if (this.final_res[i]['acct_dt']) {
        obj['Date'] = this.mainService.dateFormatChange(this.final_res[i]['acct_dt']);
      } else {
        obj['Date'] = this.final_res[i]['acct_dt'];
      } obj['Particulars'] = this.final_res[i]['jrnl_desc']
      obj['Voucher Type'] = this.final_res[i]['jrnl_type']

      obj['Voucher Number'] = this.final_res[i]['jrnl_id']
      obj['Debit(Dr)'] = this.final_res[i]['db']
      obj['Credit(Cr)'] = this.final_res[i]['cr']

      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, 'Equity_report')
  }
  export2() {
    var exp = []
    for (var i = 0; i < this.final_res2.length; i++) {
      var obj:any = new Object();
      if (this.final_res2[i]['acct_dt']) {
        obj['Date'] = this.mainService.dateFormatChange(this.final_res2[i]['acct_dt']);
      } else {
        obj['Date'] = this.final_res2[i]['acct_dt'];
      } obj['Particulars'] = this.final_res2[i]['jrnl_desc']
      obj['Voucher Type'] = this.final_res2[i]['jrnl_type']

      obj['Voucher Number'] = this.final_res2[i]['jrnl_id']
      obj['Debit(Dr)'] = this.final_res2[i]['db']
      obj['Credit(Cr)'] = this.final_res2[i]['cr']

      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, 'Liability_report')
  }
  export3() {
    var exp = []
    for (var i = 0; i < this.final_res3.length; i++) {
      var obj:any = new Object();
      if (this.final_res3[i]['acct_dt']) {
        obj['Date'] = this.mainService.dateFormatChange(this.final_res3[i]['acct_dt']);
      } else {
        obj['Date'] = this.final_res3[i]['acct_dt'];
      }
      obj['Particulars'] = this.final_res3[i]['jrnl_desc']
      obj['Voucher Type'] = this.final_res3[i]['jrnl_type']

      obj['Voucher Number'] = this.final_res3[i]['jrnl_id']
      obj['Debit(Dr)'] = this.final_res3[i]['db']
      obj['Credit(Cr)'] = this.final_res3[i]['cr']

      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, 'Asset_report')
  }
  export4() {
    var exp = []
    for (var i = 0; i < this.final_res4.length; i++) {
      var obj:any = new Object();
      if (this.final_res4[i]['acct_dt']) {
        obj['Date'] = this.mainService.dateFormatChange(this.final_res4[i]['acct_dt']);
      } else {
        obj['Date'] = this.final_res4[i]['acct_dt'];
      } obj['Particulars'] = this.final_res4[i]['jrnl_desc']
      obj['Voucher Type'] = this.final_res4[i]['jrnl_type']

      obj['Voucher Number'] = this.final_res4[i]['jrnl_id']
      obj['Debit(Dr)'] = this.final_res4[i]['db']
      obj['Credit(Cr)'] = this.final_res4[i]['cr']
      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, 'Expense_report')
  }
  export5() {
    var exp = []
    for (var i = 0; i < this.final_res5.length; i++) {
      var obj:any = new Object();
      if (this.final_res5[i]['acct_dt']) {
        obj['Date'] = this.mainService.dateFormatChange(this.final_res5[i]['acct_dt']);
      } else {
        obj['Date'] = this.final_res5[i]['acct_dt'];
      } obj['Particulars'] = this.final_res5[i]['jrnl_desc']
      obj['Voucher Type'] = this.final_res5[i]['jrnl_type']

      obj['Voucher Number'] = this.final_res5[i]['jrnl_id']
      obj['Debit(Dr)'] = this.final_res5[i]['db']
      obj['Credit(Cr)'] = this.final_res5[i]['cr']


      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, 'Income_report')
  }

  async print1() {
    this.spinner.show()
    let data = []
    if (this.final_res.length > 1) {
      for (let i = 0; i < this.final_res.length; i++) {
        let obj :any= {}
        if (this.final_res[i]['acct_dt']) {
          obj['acct_dt'] = this.mainService.dateFormatChange(this.final_res[i]['acct_dt'])
        } else {
          obj['acct_dt'] = ''
        }
        if (this.final_res[i]['db']) {
          obj['db'] = this.final_res[i]['db']
        } else {
          obj['db'] = 0
        }
        if (this.final_res[i]['cr']) {
          obj['cr'] = this.final_res[i]['cr']
        } else {
          obj['cr'] = 0
        }
        if (this.final_res[i]['jrnl_desc']) {
          obj['jrnl_desc'] = this.final_res[i]['jrnl_desc']
        } else {
          obj['jrnl_desc'] = '  '
        }
        if (this.final_res[i]['jrnl_id']) {
          obj['jrnl_id'] = this.final_res[i]['jrnl_id']
        } else {
          obj['jrnl_id'] = '  '
        }
        if (this.final_res[i]['jrnl_type']) {
          obj['jrnl_type'] = this.final_res[i]['jrnl_type']
        } else {
          obj['jrnl_type'] = '  '

        }
        data.push(obj)
      }
      var txt = this.mainService.accInfo['account_name'] + '(' + this.mainService.accInfo['account_short_name'] + ')'
      var dd:any = {
        pageSize: 'A4',
        header: function (currentPage:any, pageCount:any) {
          var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
          return obj;
        },

        pageOrientation: 'portrait',

        pageMargins: [40, 60, 40, 60],
        content: [
        ]
      };
      // Liabilities ({{obj_lia['acc_desc']}})
      var header0 = {
        columns: [
          {
            width: '*',
            text: this.obj['acc_desc'],
            // text: 'Equity (' +this.obj['acc_desc']+')',
            alignment: 'center'

          },


        ],
      }
      var header1 = {
        columns: [
          {
            width: '*',
            text: 'Chart Of Account :',
            bold: true
          },

          {
            width: '*',
            text: this.obj['acc_desc']
          },
          {
            width: '*',
            text: 'Financial Year :',
            bold: true
          },

          {
            width: '*',
            text: this.mainService.codeValueShowObj['ACC0025'][this.obj['fin_year']]
          }

        ],
      }
      var header2 = {
        columns: [
          {
            width: '*',
            text: 'From Date :',
            bold: true
          },
          {
            width: '*',
            text: this.mainService.dateFormatChange(this.obj['from_accounting_dt'])


          },
          {
            width: '*',
            text: 'To Date :',
            bold: true
          },

          {
            width: '*',
            text: this.mainService.dateFormatChange(this.obj['to_accounting_dt'])
          }
        ],
      }

      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      dd.content.push({ text: " " });
      dd.content.push(header0);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      dd.content.push({ text: " " });
      dd.content.push(header1);
      dd.content.push({ text: " " });
      dd.content.push(header2);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      var tbl = {

        layout: 'lightHorizontalLines',
        fontSize: 10,
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*'],
          body: [
            ['Date', 'Particulars', 'Voucher Type', 'Voucher Number', 'Debit(Dr)', 'Credit(Cr)']
          ],

        }
      };
      dd.content.push(tbl);
      for (var i = 0; i < data.length; i++) {
        var arr = []
        arr.push(data[i]['acct_dt']);
        arr.push(data[i]['jrnl_desc']);
        arr.push(data[i]['jrnl_type']);
        arr.push(data[i]['jrnl_id']);
        arr.push({ text: data[i]['db'], alignment: 'right' });
        arr.push({ text: data[i]['cr'], alignment: 'right' });
        dd.content[dd.content.length - 1].table.body.push(arr);
      }
      pdfMake.createPdf(dd).download("equityReport");
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('There Is No Record To Display', '', 'info')
    }
  }

  res_obj2:any = {}
  final_res2 :any= []




  async show2() {

    if (this.obj_lia['to_date'] < this.obj_lia['from_date']) {
      this.spinner.hide();
      Swal.fire('ERROR', 'Please Select Correct Date', 'error');
      return;
    }
    this.final_res2 = []
    this.total = 0
    this.spinner.show()
    this.obj_lia['b_acct_id'] = this.b_acct_id
    this.obj_lia['from_accounting_dt'] = this.obj_lia['from_date']
    this.obj_lia['to_accounting_dt'] = this.obj_lia['to_date']
    this.obj_lia['chart_of_account'] = this.obj_lia['c_acc']
    for (let i = 0; i < this.C_acc.length; i++) {
      if (this.C_acc[i]['leaf_code'] == this.obj_lia['c_acc']) {
        this.obj_lia['acc_desc'] = this.C_acc[i]['leaf_value']
      }
    }
    this.obj_lia['le_id'] = this.obj_lia['le_id']
    // console.log(this.obj_lia);
    var resp = await this.ledgerService.getLedgerReport(JSON.stringify(this.obj_lia))
    if (resp['error'] == false) {
      console.log('DATA ', resp['data']);
      this.res_obj2 = this.obj_lia
      this.result = []
      this.result = resp['data'];
      for (let i = 0; i < this.result[0].length; i++) {
        if (this.result[0][i]['db_cd_ind'] == 'DB') {
          this.result[0][i]['txn_amt'] = (-1) * (this.result[0][i]['txn_amt'])
        }
      }

      var openObj:any = {};
      openObj['acct_dt'] = this.obj_lia['from_date'];
      openObj['jrnl_desc'] = "Opening Balance";
      openObj['jrnl_type'] = "";
      openObj['jrnl_id'] = "";
      openObj['db'] = 0;
      openObj['cr'] = 0;



      for (let i = 0; i < this.result[1].length; i++) {
        if (this.result[1][i]['db_cd_ind'] == 'DB') {
          openObj['db'] += this.result[1][i]['txn_amt']

        } else {

          openObj['cr'] += this.result[1][i]['txn_amt']
        }

      }
      if (openObj['db'] >= openObj['cr']) {
        openObj['db'] = openObj['db'] - openObj['cr'];
        openObj['cr'] = 0;
      } else {
        openObj['cr'] = openObj['cr'] - openObj['db'];
        openObj['db'] = 0;
      }
      openObj['db'] = openObj['db']
      openObj['cr'] = openObj['cr']
      this.final_res2.push(openObj);

      for (let i = 0; i < this.result[0].length; i++) {
        if (this.result[0][i]['db_cd_ind'] == 'DB') {
          this.result[0][i]['db'] = this.result[0][i]['txn_amt']
          this.result[0][i]['cr'] = 0
          this.total = this.total - this.result[0][i]['txn_amt']
          this.total = Number(this.total.toFixed(2))
        } else {
          this.total = this.total + this.result[0][i]['txn_amt']
          this.result[0][i]['cr'] = this.result[0][i]['txn_amt'];
          this.result[0][i]['db'] = 0
        }

        // this.result[0][i]['jrnl_desc'] = ''
        // this.result[0][i]['acct_dt'] = this.obj_lia['from_date']
        this.final_res2.push(this.result[0][i])
      }
      let dum:any = {}
      let db :any= 0
      let cr:any = 0
      for (let i = 0; i < this.final_res2.length; i++) {
        db = db + this.final_res2[i]['db']
        cr = cr + this.final_res2[i]['cr']
      }

      dum['cr'] = cr
      dum['db'] = db
      dum['jrnl_desc'] = 'Total'
      this.final_res2.push(dum)
      let dum2:any = {}
      let s = 0;
      if (db < cr) {
        s = cr - db
        dum2['db'] = s
      } else {
        s = db - cr
        dum2['cr'] = s
      }
      dum2['jrnl_desc'] = 'Closing Balance'
      let dum3 :any= {}
      if (db > cr) {
        dum3['db'] = db
        dum3['cr'] = s + cr
      }
      else {
        dum3['db'] = s + db
        dum3['cr'] = cr
      }
      this.final_res2.push(dum2)
      this.final_res2.push(dum3)

      this.spinner.hide()
    }



    else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured', 'error')
    }
  }
  async print2() {
    this.spinner.show()
    let data = []
    if (this.final_res2.length > 1) {
      for (let i = 0; i < this.final_res2.length; i++) {
        let obj :any= {}
        if (this.final_res2[i]['acct_dt']) {
          obj['acct_dt'] = this.mainService.dateFormatChange(this.final_res2[i]['acct_dt'])
        } else {
          obj['acct_dt'] = ''
        }
        if (this.final_res2[i]['db']) {
          obj['db'] = this.final_res2[i]['db']
        } else {
          obj['db'] = 0
        }
        if (this.final_res2[i]['cr']) {
          obj['cr'] = this.final_res2[i]['cr']
        } else {
          obj['cr'] = 0
        }
        if (this.final_res2[i]['jrnl_desc']) {
          obj['jrnl_desc'] = this.final_res2[i]['jrnl_desc']
        } else {
          obj['jrnl_desc'] = '  '
        }
        if (this.final_res2[i]['jrnl_id']) {
          obj['jrnl_id'] = this.final_res2[i]['jrnl_id']
        } else {
          obj['jrnl_id'] = '  '
        }
        if (this.final_res2[i]['jrnl_type']) {
          obj['jrnl_type'] = this.final_res2[i]['jrnl_type']
        } else {
          obj['jrnl_type'] = '  '

        }
        data.push(obj)
      }
      var txt = this.mainService.accInfo['account_name'] + '(' + this.mainService.accInfo['account_short_name'] + ')'
      var dd:any = {
        pageSize: 'A4',
        header: function (currentPage:any, pageCount:any) {
          var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
          return obj;
        },

        pageOrientation: 'portrait',

        pageMargins: [40, 60, 40, 60],
        content: [
        ]
      };
      // Liabilities ({{obj_lia['acc_desc']}})
      var header0 = {
        columns: [
          {
            width: '*',
            text: this.obj_lia['acc_desc'],
            // text: 'Liabilities (' +this.obj_lia['acc_desc']+')',
            alignment: 'center'

          },


        ],
      }
      var header1 = {
        columns: [
          {
            width: '*',
            text: 'Chart Of Account :',
            bold: true
          },

          {
            width: '*',
            text: this.obj_lia['acc_desc']
          },
          {
            width: '*',
            text: 'Financial Year :',
            bold: true
          },

          {
            width: '*',
            text: this.mainService.codeValueShowObj['ACC0025'][this.obj_lia['fin_year']]
          }

        ],
      }
      var header2 = {
        columns: [
          {
            width: '*',
            text: 'From Date :',
            bold: true
          },
          {
            width: '*',
            text: this.mainService.dateFormatChange(this.obj_lia['from_accounting_dt'])


          },
          {
            width: '*',
            text: 'To Date :',
            bold: true
          },

          {
            width: '*',
            text: this.mainService.dateFormatChange(this.obj_lia['to_accounting_dt'])
          }
        ],
      }

      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      dd.content.push({ text: " " });
      dd.content.push(header0);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      dd.content.push({ text: " " });
      dd.content.push(header1);
      dd.content.push({ text: " " });
      dd.content.push(header2);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      var tbl = {

        layout: 'lightHorizontalLines',
        fontSize: 10,
        table: {

          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*'],
          body: [
            ['Date', 'Particulars', 'Voucher Type', 'Voucher Number', 'Debit(Dr)', 'Credit(Cr)']
          ],

        }
      };
      dd.content.push(tbl);
      for (var i = 0; i < data.length; i++) {
        var arr = []
        arr.push(data[i]['acct_dt']);
        arr.push(data[i]['jrnl_desc']);
        arr.push(data[i]['jrnl_type']);
        arr.push(data[i]['jrnl_id']);
        arr.push({ text: data[i]['db'], alignment: 'right' });
        arr.push({ text: data[i]['cr'], alignment: 'right' });
        dd.content[dd.content.length - 1].table.body.push(arr);
      }
      pdfMake.createPdf(dd).download("liabilityReport");
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('There Is No Record To Display', '', 'info')
    }
  }
  final_res3 :any= []
  obj_ass:any = {}
  ass_flage: boolean = false;
  async show3() {
    this.spinner.show();
    if (this.obj_ass['to_date'] < this.obj_ass['from_date']) {
      this.spinner.hide();
      this.ass_flage = true;
      return;
    }

    this.final_res3 = []
    this.total = 0
    this.spinner.show()
    this.obj_ass['b_acct_id'] = this.b_acct_id
    this.obj_ass['from_accounting_dt'] = this.obj_ass['from_date']
    this.obj_ass['to_accounting_dt'] = this.obj_ass['to_date']
    this.obj_ass['chart_of_account'] = this.obj_ass['c_acc']
    for (var i = 0; i < this.C_acc.length; i++) {
      if (this.C_acc[i]['leaf_code'] == this.obj_ass['c_acc']) {
        this.obj_ass['acc_desc'] = this.C_acc[i]['leaf_value']
      }
    }
    this.obj_ass['le_id'] = this.obj_ass['le_id']

    var resp = await this.ledgerService.getLedgerReport(JSON.stringify(this.obj_ass))
    if (resp['error'] == false) {
      this.res_obj2 = this.obj_ass
      this.result = []
      this.result = resp['data'];
      for (let i = 0; i < this.result[0].length; i++) {
        if (this.result[0][i]['db_cd_ind'] == 'DB') {
          this.result[0][i]['txn_amt'] = (-1) * (this.result[0][i]['txn_amt'])
        }
      }

      var openObj:any = {};
      openObj['acct_dt'] = this.obj_ass['from_date'];
      openObj['jrnl_desc'] = "Opening Balance";
      openObj['jrnl_type'] = "";
      openObj['jrnl_id'] = "";
      openObj['db'] = 0;
      openObj['cr'] = 0;



      for (let i = 0; i < this.result[1].length; i++) {
        if (this.result[1][i]['db_cd_ind'] == 'DB') {
          openObj['db'] += this.result[1][i]['txn_amt']

        } else {

          openObj['cr'] += this.result[1][i]['txn_amt']
        }

      }
      if (openObj['db'] >= openObj['cr']) {
        openObj['db'] = openObj['db'] - openObj['cr'];
        openObj['cr'] = 0;
      } else {
        openObj['cr'] = openObj['cr'] - openObj['db'];
        openObj['db'] = 0;
      }
      this.final_res3.push(openObj);
      for (let i = 0; i < this.result[0].length; i++) {
        if (this.result[0][i]['db_cd_ind'] == 'DB') {
          this.result[0][i]['db'] = this.result[0][i]['txn_amt']
          this.result[0][i]['cr'] = 0
          this.total = this.total - this.result[0][i]['txn_amt']
        } else {
          this.total = this.total + this.result[0][i]['txn_amt']
          this.result[0][i]['cr'] = this.result[0][i]['txn_amt']
          this.result[0][i]['db'] = 0
        }
        this.final_res3.push(this.result[0][i])
      }
      let dum :any= {}
      let db :any= 0
      let cr:any = 0
      for (let i = 0; i < this.final_res3.length; i++) {
        db = db + this.final_res3[i]['db']
        cr = cr + this.final_res3[i]['cr']
      }

      dum['cr'] = cr
      dum['db'] = db
      dum['jrnl_desc'] = 'Total'
      this.final_res3.push(dum)
      let dum2:any = {}
      let s = 0;
      if (db < cr) {
        s = cr - db
        dum2['db'] = s
      } else {
        s = db - cr
        dum2['cr'] = s
      }
      dum2['jrnl_desc'] = 'Closing Balance'
      let dum3:any = {}
      if (db > cr) {
        dum3['db'] = db
        dum3['cr'] = s + cr
      }
      else {
        dum3['db'] = s + db
        dum3['cr'] = cr
      }
      this.final_res3.push(dum2)
      this.final_res3.push(dum3)
      console.log("Final result 3 ", this.final_res3);
      this.spinner.hide()
    }



    else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured', 'error')
    }
  }
  async print3() {
    this.spinner.show()
    let data = []
    if (this.final_res3.length > 1) {
      for (let i = 0; i < this.final_res3.length; i++) {
        let obj :any= {}
        if (this.final_res3[i]['acct_dt']) {
          obj['acct_dt'] = this.mainService.dateFormatChange(this.final_res3[i]['acct_dt'])
        } else {
          obj['acct_dt'] = ''
        }
        if (this.final_res3[i]['db']) {
          obj['db'] = this.final_res3[i]['db']
        } else {
          obj['db'] = 0
        }
        if (this.final_res3[i]['cr']) {
          obj['cr'] = this.final_res3[i]['cr']
        } else {
          obj['cr'] = 0
        }
        if (this.final_res3[i]['jrnl_desc']) {
          obj['jrnl_desc'] = this.final_res3[i]['jrnl_desc']
        } else {
          obj['jrnl_desc'] = '  '
        }
        if (this.final_res3[i]['jrnl_id']) {
          obj['jrnl_id'] = this.final_res3[i]['jrnl_id']
        } else {
          obj['jrnl_id'] = '  '
        }
        if (this.final_res3[i]['jrnl_type']) {
          obj['jrnl_type'] = this.final_res3[i]['jrnl_type']
        } else {
          obj['jrnl_type'] = '  '

        }
        data.push(obj)
      }
      var txt = this.mainService.accInfo['account_name'] + '(' + this.mainService.accInfo['account_short_name'] + ')'
      var dd :any= {
        pageSize: 'A4',
        header: function (currentPage:any, pageCount:any) {
          var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
          return obj;
        },

        pageOrientation: 'portrait',

        pageMargins: [40, 60, 40, 60],
        content: [
        ]
      };
      // Liabilities ({{obj_lia['acc_desc']}})
      var header0 = {
        columns: [
          {
            width: '*',
            text: this.obj_ass['acc_desc'],
            // text: 'Assets (' +this.obj_ass['acc_desc']+')',
            alignment: 'center'

          },


        ],
      }
      var header1 = {
        columns: [
          {
            width: '*',
            text: 'Chart Of Account :',
            bold: true
          },

          {
            width: '*',
            text: this.obj_ass['acc_desc']
          },
          {
            width: '*',
            text: 'Financial Year :',
            bold: true
          },

          {
            width: '*',
            text: this.mainService.codeValueShowObj['ACC0025'][this.obj_ass['fin_year']]
          }

        ],
      }
      var header2 = {
        columns: [
          {
            width: '*',
            text: 'From Date :',
            bold: true
          },
          {
            width: '*',
            text: this.mainService.dateFormatChange(this.obj_ass['from_accounting_dt'])


          },
          {
            width: '*',
            text: 'To Date :',
            bold: true
          },

          {
            width: '*',
            text: this.mainService.dateFormatChange(this.obj_ass['to_accounting_dt'])
          }
        ],
      }

      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      dd.content.push({ text: " " });
      dd.content.push(header0);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      dd.content.push({ text: " " });
      dd.content.push(header1);
      dd.content.push({ text: " " });
      dd.content.push(header2);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      var tbl = {

        layout: 'lightHorizontalLines',
        fontSize: 10,
        table: {

          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*'],
          body: [
            ['Date', 'Particulars', 'Voucher Type', 'Voucher Number', 'Debit(Dr)', 'Credit(Cr)']
          ],

        }
      };
      dd.content.push(tbl);
      for (var i = 0; i < data.length; i++) {
        var arr = []
        arr.push(data[i]['acct_dt']);
        arr.push(data[i]['jrnl_desc']);
        arr.push(data[i]['jrnl_type']);
        arr.push(data[i]['jrnl_id']);
        arr.push({ text: data[i]['db'], alignment: 'right' });
        arr.push({ text: data[i]['cr'], alignment: 'right' });
        dd.content[dd.content.length - 1].table.body.push(arr);
      }
      pdfMake.createPdf(dd).download("assetReport");
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('There Is No Record To Display', '', 'info')
    }
  }
  final_res4:any = []

  async show4() {

    if (this.obj_exp['to_date'] < this.obj_exp['from_date']) {
      this.spinner.hide();
      Swal.fire('ERROR', 'Please Select Correct Date', 'error');
      return;
    }

    this.final_res4 = []
    this.total = 0
    this.spinner.show()
    this.obj_exp['b_acct_id'] = this.b_acct_id
    this.obj_exp['from_accounting_dt'] = this.obj_exp['from_date']
    this.obj_exp['to_accounting_dt'] = this.obj_exp['to_date']
    this.obj_exp['chart_of_account'] = this.obj_exp['c_acc']
    for (var i = 0; i < this.C_acc.length; i++) {
      if (this.C_acc[i]['leaf_code'] == this.obj_exp['c_acc']) {
        this.obj_exp['acc_desc'] = this.C_acc[i]['leaf_value']
      }
    }
    this.obj_exp['le_id'] = this.obj_exp['le_id']
    var resp = await this.ledgerService.getLedgerReport(JSON.stringify(this.obj_exp))
    if (resp['error'] == false) {
      this.res_obj2 = this.obj_exp
      this.result = []
      this.result = resp['data'];
      for (let i = 0; i < this.result[0].length; i++) {
        if (this.result[0][i]['db_cd_ind'] == 'DB') {
          this.result[0][i]['txn_amt'] = (-1) * (this.result[0][i]['txn_amt'])
        }
      }


      for (let i = 0; i < this.result[0].length; i++) {
        if (this.result[0][i]['db_cd_ind'] == 'DB') {
          this.result[0][i]['db'] = this.result[0][i]['txn_amt']
          this.result[0][i]['cr'] = 0
          this.total = this.total - this.result[0][i]['txn_amt']
        } else {
          this.total = this.total + this.result[0][i]['txn_amt']
          this.result[0][i]['cr'] = this.result[0][i]['txn_amt']
          this.result[0][i]['db'] = 0
        }

        //this.result[0][i]['jrnl_desc'] = ''
        // this.result[0][i]['acct_dt'] = this.obj_exp['from_date']
        this.final_res4.push(this.result[0][i])
      }
      let dum:any = {}
      let db:any = 0
      let cr :any= 0
      for (let i = 0; i < this.final_res4.length; i++) {
        db = db + this.final_res4[i]['db']
        cr = cr + this.final_res4[i]['cr']
      }

      dum['cr'] = cr
      dum['db'] = db
      dum['jrnl_desc'] = 'Total'
      this.final_res4.push(dum)
      let dum2:any = {}
      let s = 0;
      if (db < cr) {
        s = cr - db
        dum2['db'] = s
      } else {
        s = db - cr
        dum2['cr'] = s
      }
      dum2['jrnl_desc'] = 'To Closing Balance'
      let dum3:any = {}
      if (db > cr) {
        dum3['db'] = db
        dum3['cr'] = s + cr
      }
      else {
        dum3['db'] = s + db
        dum3['cr'] = cr
      }
      this.final_res4.push(dum2)
      this.final_res4.push(dum3)


      this.spinner.hide()
    }



    else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured', 'error')
    }
  }
  async print4() {
    this.spinner.show()
    let data = []
    if (this.final_res4.length > 1) {
      for (let i = 0; i < this.final_res4.length; i++) {
        let obj :any= {}
        if (this.final_res4[i]['acct_dt']) {
          obj['acct_dt'] = this.mainService.dateFormatChange(this.final_res4[i]['acct_dt'])
        } else {
          obj['acct_dt'] = ''
        }
        if (this.final_res4[i]['db']) {
          obj['db'] = this.final_res4[i]['db']
        } else {
          obj['db'] = 0
        }
        if (this.final_res4[i]['cr']) {
          obj['cr'] = this.final_res4[i]['cr']
        } else {
          obj['cr'] = 0
        }
        if (this.final_res4[i]['jrnl_desc']) {
          obj['jrnl_desc'] = this.final_res4[i]['jrnl_desc']
        } else {
          obj['jrnl_desc'] = '  '
        }
        if (this.final_res4[i]['jrnl_id']) {
          obj['jrnl_id'] = this.final_res4[i]['jrnl_id']
        } else {
          obj['jrnl_id'] = '  '
        }
        if (this.final_res4[i]['jrnl_type']) {
          obj['jrnl_type'] = this.final_res4[i]['jrnl_type']
        } else {
          obj['jrnl_type'] = '  '

        }
        data.push(obj)
      }
      var txt = this.mainService.accInfo['account_name'] + '(' + this.mainService.accInfo['account_short_name'] + ')'
      var dd :any= {
        pageSize: 'A4',
        header: function (currentPage:any, pageCount:any) {
          var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
          return obj;
        },

        pageOrientation: 'portrait',

        pageMargins: [40, 60, 40, 60],
        content: [
        ]
      };
      // Liabilities ({{obj_lia['acc_desc']}})
      var header0 = {
        columns: [
          {
            width: '*',
            text: this.obj_exp['acc_desc'],
            // text: 'Expence (' +this.obj_exp['acc_desc']+')',
            alignment: 'center'

          },


        ],
      }
      var header1 = {
        columns: [
          {
            width: '*',
            text: 'Chart Of Account :',
            bold: true
          },

          {
            width: '*',
            text: this.obj_exp['acc_desc']
          },
          {
            width: '*',
            text: 'Financial Year :',
            bold: true
          },

          {
            width: '*',
            text: this.mainService.codeValueShowObj['ACC0025'][this.obj_exp['fin_year']]
          }

        ],
      }
      var header2 = {
        columns: [
          {
            width: '*',
            text: 'From Date :',
            bold: true
          },
          {
            width: '*',
            text: this.mainService.dateFormatChange(this.obj_exp['from_accounting_dt'])


          },
          {
            width: '*',
            text: 'To Date :',
            bold: true
          },

          {
            width: '*',
            text: this.mainService.dateFormatChange(this.obj_exp['to_accounting_dt'])
          }
        ],
      }

      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      dd.content.push({ text: " " });
      dd.content.push(header0);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      dd.content.push({ text: " " });
      dd.content.push(header1);
      dd.content.push({ text: " " });
      dd.content.push(header2);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      var tbl = {

        layout: 'lightHorizontalLines',
        fontSize: 10,
        table: {

          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*'],
          body: [
            ['Date', 'Particulars', 'Voucher Type', 'Voucher Number', 'Debit(Dr)', 'Credit(Cr)']
          ],
        }
      };
      dd.content.push(tbl);
      for (var i = 0; i < data.length; i++) {
        var arr = []
        arr.push(data[i]['acct_dt']);
        arr.push(data[i]['jrnl_desc']);
        arr.push(data[i]['jrnl_type']);
        arr.push(data[i]['jrnl_id']);
        arr.push({ text: data[i]['db'], alignment: 'right' });
        arr.push({ text: data[i]['cr'], alignment: 'right' });
        dd.content[dd.content.length - 1].table.body.push(arr);
      }
      pdfMake.createPdf(dd).download("expenceReport");
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('There Is No Record To Display', '', 'info')
    }
  }
  obj_rev :any= {}
  final_res5:any = []
  final5 :any= []
  async show5() {
    if (this.obj_rev['to_date'] < this.obj_rev['from_date']) {
      this.spinner.hide();
      Swal.fire('ERROR', 'Please Select Correct Date', 'error');
      return;
    }
    this.final_res5 = []
    this.total = 0
    this.spinner.show()
    this.obj_rev['b_acct_id'] = this.b_acct_id
    this.obj_rev['from_accounting_dt'] = this.obj_rev['from_date']
    this.obj_rev['to_accounting_dt'] = this.obj_rev['to_date']
    this.obj_rev['chart_of_account'] = this.obj_rev['c_acc']
    for (var i = 0; i < this.C_acc.length; i++) {
      if (this.C_acc[i]['leaf_code'] == this.obj_rev['c_acc']) {
        this.obj_rev['acc_desc'] = this.C_acc[i]['leaf_value']
      }
    }
    this.obj_rev['le_id'] = this.obj_rev['le_id']
    var resp = await this.ledgerService.getLedgerReport(JSON.stringify(this.obj_rev))
    if (resp['error'] == false) {
      this.res_obj2 = this.obj_rev
      this.result = []
      this.result = resp['data'];
      for (let i = 0; i < this.result[0].length; i++) {
        if (this.result[0][i]['db_cd_ind'] == 'DB') {
          this.result[0][i]['txn_amt'] = (-1) * (this.result[0][i]['txn_amt'])
        }
      }

      for (let i = 0; i < this.result[0].length; i++) {
        if (this.result[0][i]['db_cd_ind'] == 'DB') {
          this.result[0][i]['db'] = this.result[0][i]['txn_amt']
          this.result[0][i]['cr'] = 0
          this.total = this.total - this.result[0][i]['txn_amt']
        } else {
          this.total = this.total + this.result[0][i]['txn_amt']
          this.result[0][i]['cr'] = this.result[0][i]['txn_amt']
          this.result[0][i]['db'] = 0
        }

        //this.result[0][i]['jrnl_desc'] = ''
        //this.result[0][i]['acct_dt'] = this.obj_rev['from_date']
        this.final_res5.push(this.result[0][i])
      }
      let dum :any= {}
      let db:any = 0
      let cr:any = 0
      for (let i = 0; i < this.final_res5.length; i++) {
        db = db + this.final_res5[i]['db']
        cr = cr + this.final_res5[i]['cr']
      }

      dum['cr'] = cr
      dum['db'] = db
      dum['jrnl_desc'] = 'Total'
      this.final_res5.push(dum)
      let dum2:any = {}
      let s = 0;
      if (db < cr) {
        s = cr - db
        dum2['db'] = s
      } else {
        s = db - cr
        dum2['cr'] = s
      }
      dum2['jrnl_desc'] = 'To Closing Balance'
      let dum3:any = {}
      if (db > cr) {
        dum3['db'] = db
        dum3['cr'] = s + cr
      }
      else {
        dum3['db'] = s + db
        dum3['cr'] = cr
      }
      this.final_res5.push(dum2)
      this.final_res5.push(dum3)


      this.spinner.hide()
    }



    else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured', 'error')
    }
  }
  async print5() {
    this.spinner.show()
    let data = []
    if (this.final_res5.length > 1) {
      for (let i = 0; i < this.final_res5.length; i++) {
        let obj :any= {}
        if (this.final_res5[i]['acct_dt']) {
          obj['acct_dt'] = this.mainService.dateFormatChange(this.final_res5[i]['acct_dt'])
        } else {
          obj['acct_dt'] = ''
        }
        if (this.final_res5[i]['db']) {
          obj['db'] = this.final_res5[i]['db']
        } else {
          obj['db'] = 0
        }
        if (this.final_res5[i]['cr']) {
          obj['cr'] = this.final_res5[i]['cr']
        } else {
          obj['cr'] = 0
        }
        if (this.final_res5[i]['jrnl_desc']) {
          obj['jrnl_desc'] = this.final_res5[i]['jrnl_desc']
        } else {
          obj['jrnl_desc'] = '  '
        }
        if (this.final_res5[i]['jrnl_id']) {
          obj['jrnl_id'] = this.final_res5[i]['jrnl_id']
        } else {
          obj['jrnl_id'] = '  '
        }
        if (this.final_res5[i]['jrnl_type']) {
          obj['jrnl_type'] = this.final_res5[i]['jrnl_type']
        } else {
          obj['jrnl_type'] = '  '

        }
        data.push(obj)
      }
      var txt = this.mainService.accInfo['account_name'] + '(' + this.mainService.accInfo['account_short_name'] + ')'
      var dd:any = {
        pageSize: 'A4',
        header: function (currentPage:any, pageCount:any) {
          var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
          return obj;
        },

        pageOrientation: 'portrait',

        pageMargins: [40, 60, 40, 60],
        content: [
        ]
      };
      // Liabilities ({{obj_lia['acc_desc']}})
      var header0 = {
        columns: [
          {
            width: '*',
            text: this.obj_rev['acc_desc'],
            // text: 'Income (' +this.obj_rev['acc_desc']+')',
            alignment: 'center'

          },


        ],
      }
      var header1 = {
        columns: [
          {
            width: '*',
            text: 'Chart Of Account :',
            bold: true
          },

          {
            width: '*',
            text: this.obj_rev['acc_desc']
          },
          {
            width: '*',
            text: 'Financial Year :',
            bold: true
          },

          {
            width: '*',
            text: this.mainService.codeValueShowObj['ACC0025'][this.obj_rev['fin_year']]
          }

        ],
      }
      var header2 = {
        columns: [
          {
            width: '*',
            text: 'From Date :',
            bold: true
          },
          {
            width: '*',
            text: this.mainService.dateFormatChange(this.obj_rev['from_accounting_dt'])


          },
          {
            width: '*',
            text: 'To Date :',
            bold: true
          },

          {
            width: '*',
            text: this.mainService.dateFormatChange(this.obj_rev['to_accounting_dt'])
          }
        ],
      }

      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      dd.content.push({ text: " " });
      dd.content.push(header0);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      dd.content.push({ text: " " });
      dd.content.push(header1);
      dd.content.push({ text: " " });
      dd.content.push(header2);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
      var tbl = {
        layout: 'lightHorizontalLines',
        fontSize: 10,
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*'],
          body: [
            ['Date', 'Particulars', 'Voucher Type', 'Voucher Number', 'Debit(Dr)', 'Credit(Cr)']
          ],
        }
      };
      dd.content.push(tbl);
      for (var i = 0; i < data.length; i++) {
        var arr = []
        arr.push(data[i]['acct_dt']);
        arr.push(data[i]['jrnl_desc']);
        arr.push(data[i]['jrnl_type']);
        arr.push(data[i]['jrnl_id']);
        arr.push({ text: data[i]['db'], alignment: 'right' });
        arr.push({ text: data[i]['cr'], alignment: 'right' });

        dd.content[dd.content.length - 1].table.body.push(arr);
      }
      pdfMake.createPdf(dd).download("incomeReport");
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('There Is No Record To Display', '', 'info')
    }
  }
}
