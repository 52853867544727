<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Manage Termination -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination1']}}
                         </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination2']}}   <!-- All Employee Termination List -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination3']}}<!-- Employee Termination  -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination13']}} <!--Termination Details -->
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination4']}}">
                            </mat-form-field>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="term_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                           {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination14']}} <!-- Termination ID -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.id }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination5']}}<!-- Employee ID -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.emp_id }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination6']}} <!-- Employee Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="chargesheet_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                             {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination15']}}<!--Chargesheet Date -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.chargesheet_date) }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="enq_officer_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination16']}}<!--Enquiry Officer Name -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.enq_officer_name }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="order_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Termination Order ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination17']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.term_order_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="termination_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--Termination Date--> {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination10']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.termination_date) }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="term_status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                           <!-- Termination Status -->  {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination18']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.term_status }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <!--Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination22']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" *ngIf="element.term_status != 'UNDER APPROVED' && element.term_status != 'TERMINATED'" (click)="approve(element)"><!--Add Approval -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination19']}}</button>
                                            <button class="btn btn-outline-primary" *ngIf="element.term_status == 'UNDER APPROVED'" (click)="terminate(element)"> Terminate <!--{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination19']}}--></button>
                                            <button class="btn btn-outline-success" (click)="view_status()"><!--View Status-->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination20']}}</button>
                                            <button class="btn btn-outline-danger" (click)="view_details(element)"><!--View Details-->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination21']}}</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>


                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                     <div class="col-12" style="text-align: center;">
                             <span style="color: orangered;">Already Enquired ? If No :- <input class="check" type="checkbox" #asd (click)="checkBox()" > </span>
                                     </div>

                            </div>
  <br>

                            <div class="row"  *ngIf="checkTrue==false">
                                <div class="col-4 text-right">
                                    <!-- Enquiry ID : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination23']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allChargesheetFileEnquiry" bindLabel="id" bindValue="id" (change)="checkEnquiry()" [multiple]="false" placeholder="" [(ngModel)]="terminationObj['enq_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                               </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination9']}}
                                </div>
                                <div class="col-4">
                                     <input *ngIf="checkTrue==false" class="form-control" [(ngModel)]="terminationObj['emp_name']" disabled>

                                    <ng-select *ngIf="checkTrue==true" [items]="newallEmplyees" bindLabel="emp_name"
                                    bindValue="emp_id" [multiple]="false" placeholder=""
                                    [(ngModel)]="terminationObj['emp_name']" [selectableGroup]="true"
                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="emp_id" (change)="getDetail()" [disabled]="checkTrue==false">
                                </ng-select>

                                </div>
                            </div>
                            <br>


                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                  Termination Order ID :  {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination30']}}
                                </div>
                                <div class="col-4">
                                    <input  class="form-control" [(ngModel)]="terminationObj['order_id']">

                                </div>
                            </div> -->
                            <br>

                            <div class="row"  *ngIf="checkTrue==false">
                                <div class="col-4 text-right">
                                   <!--  Enquiry Officer Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination24']}}
                                </div>
                                <div class="col-4">
                                    <input  class="form-control" [(ngModel)]="terminationObj['enq_officer_name']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row"  *ngIf="checkTrue==false">
                                <div class="col-4 text-right">
                                  <!--   Chargesheet Date : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination25']}}
                                </div>
                                <div class="col-4">
                                    <input  class="form-control" [(ngModel)]="terminationObj['chargesheet_date']" disabled>

                                </div>
                            </div>
                            <br>
                            <div class="row" >
                                <div class="col-4 text-right">
                                   <!-- Termination Date:  -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination26']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input type="date"  class="form-control" [(ngModel)]="terminationObj['termination_date']">
                                 </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                   <!-- Termination Note:  -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination27']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                 <textarea style="width: 100%;" id="desc" name="desc" [(ngModel)]="terminationObj['termination_note']"></textarea>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                  <!--  Termination Order By: --> {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination28']}}<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input type="text"  class="form-control" [(ngModel)]="terminationObj['termination_order_by']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                <!--   Upload Order Copy : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination29']}}
                                </div>
                                <div class="col-4 text-center">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                    class="form-control" style="border-color: black"
                                    (change)="onFileUpload($event,file.files)">
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitTermination()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination11']}}<!-- Submit -->

                                    </button>
                            </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="tab-3">
                            <br>
                            <div class="row">
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                   <h5><!--Termination Order ID--> {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination17']}}</h5>
                               </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                   <h5>{{viewObj['id']}}</h5>
                               </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                   <h5><!--Termination Date --> {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination10']}}</h5>
                               </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                <h5>{{viewObj['termination_date']}}</h5>
                               </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <h5><!--Employee ID--> {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination5']}}</h5>
                                </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                <h5>{{viewObj['emp_id']}}</h5>
                               </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                <h5><!--Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination6']}}</h5>
                               </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                <h5>{{viewObj['emp_name']}} </h5>
                               </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <h5><!--Enquiry ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination38']}}</h5>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <h5>{{viewObj['enq_id']}}</h5>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <h5><!--Enquiry Result -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination37']}} </h5>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <h5>{{viewObj['enq_result']}}</h5>
                                </div>
                           </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                   <h5> <!--Enquiry Officer -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination36']}}</h5>
                                </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                <h5>{{viewObj['enq_officer_name']}}</h5>
                               </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                   <h5><!--Chargesheet Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination35']}}</h5>
                               </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                <h5>{{viewObj['chargesheet_date']}}</h5>
                               </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <h5><!--Termination Order By  -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination34']}}</h5>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <h5>{{viewObj['termination_order_by']}}</h5>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <h5><!--Designation--> {{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination33']}}</h5>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <h5>{{viewObj['designation']}}</h5>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-12">
                                    <h5><!--Termination Note -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination32']}}</h5>
                                </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                <h5>{{viewObj['termination_note']}}</h5>
                               </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                <h5><!--Order Copy -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination31']}}</h5>
                               </div>
                               <div class="col-lg-3 col-md-3 col-sm-12">
                                <button class="btn btn-outline-danger" (click)="view_order()"><!--View Order-->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination39']}}</button>
                               </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <img [src]="imgURL" />
                                </div>

                            </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'hr_termination12']}}</ngx-spinner>
