import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '../../token.service';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class AcrService {

  httpUrl;


  //Avinash
  constructor(private http: HttpClient, private main: MainService, private token: TokenService) {
    this.httpUrl = this.main.httpUrl;
  }
  //  universal api for all

  async getNodecode(obj :any) {
    const resp = this.http.post<any>(this.httpUrl + '/hr/annul_conf_repo/acr/getnodeCode', obj).toPromise().then(res => {
      return res;
    })
    return resp
  }

  //case 1
  async getPendingCas1Acr(obj :any) {
    const resp = this.http.post<any>(this.httpUrl + '/hr/annul_conf_repo/acr/getpendingACR', obj).toPromise().then(res => {
      return res;
    })
    return resp
  }

  //case 2
  async getCase2Data(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/getcase2ACR', obj).toPromise().then((res) => res);
    return resp
  }
  //case 3
  async getCase3Data(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/getcase3ACR', obj).toPromise().then((res) => res);
    return resp
  }

  // =------------------- start list for send upper officer

  async getSendAcrOff1(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/getSendAcrOff1', obj).toPromise().then((res) => res);
    return resp
  }
  async getsendscr2(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/getSendAcrOff2', obj).toPromise().then((res) => res);
    return resp
  }

  // form 2
  async getsendacr_off1(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/getsendacr_off1', obj).toPromise().then((res) => res);
    return resp
  }
  async getPreviousReview(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/getPreviousReview', obj).toPromise().then((res) => res);
    return resp
  }

  // =---------------------- end list for send upper officer

  async getForm(obj :any) {
    let resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/gettingFormFillByEmployee', obj).toPromise().then((res) => res)
    return resp;
  }
  async searchFirstAcr(obj :any) {
    let resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/gettingFormFirstACR', obj).toPromise().then((res) => res)
    return resp;
  }
  async searchSecondAcr(obj :any) {
    let resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/gettingFormSecondACR', obj).toPromise().then((res) => {
      return res
    })
    return resp;
  }



  // end of universal api

  // form 1
  async saveForm1(obj: any) {

    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/cashierFormSelf', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async update_Form1(obj: any) {

    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/cashierFormSelf_Update', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async addRecord(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/reportAdd', obj).toPromise().then((res) => res);
    return resp;
  }
  async addRecord_Update(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/reportAdd_update', obj).toPromise().then((res) => res);
    return resp;
  }

  async addReview(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/reportAddCase2', obj).toPromise().then((res) => res);
    return resp;
  }
  async findReview_data(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/reportAddCase2_getdate', obj).toPromise().then((res) => res);
    return resp;
  }
  async addReview_update(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/reportAddCase2_update', obj).toPromise().then((res) => res);
    return resp;
  }




  // end form 1
  async gettingCashierList(obj :any) {
    const resp = this.http.post<any>(this.httpUrl + '/hr/annul_conf_repo/acr/listCashier', obj).toPromise().then(res => {
      return res;
    })
    return resp
  }

  // form 2

  // add report by first officer
  async form2OfficerReport(obj :any) {
    let resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/form2_officer1_report', obj).toPromise().then((res) => {
      return res
    })
    return resp
  }
  async form2OfficerReport_update(obj :any) {
    let resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/form2_officer1_report_update', obj).toPromise().then((res) => {
      return res
    })
    return resp
  }






  // data insert int he acr track table ;
  async insertAcrTrack(obj :any) {
    const resp = this.http.post<any>(this.httpUrl + '/hr/annul_conf_repo/acr/insertActTrack', obj).toPromise().then(res => {
      return res;
    })
    return resp
  }
  async billapprove(obj :any) {
    const resp = this.http.put<any>(this.httpUrl + '/hr/annul_conf_repo/acr/billapprove', obj).toPromise().then(res => {
      return res;
    })
    return resp
  }
  // last approval of track id
  async saveLastApproval(obj :any) {
    const resp = this.http.post<any>(this.httpUrl + '/hr/annul_conf_repo/acr/saveLastApproval', obj).toPromise().then(res => {
      return res;
    })
    return resp
  }

  // changing the acr status

  async changeStatusOfAcrSelf(obj :any) {
    const resp = this.http.put<any>(this.httpUrl + '/hr/annul_conf_repo/acr/changeStatusACRself', obj).toPromise().then(x => {
      return x;
    })
    return resp
  }
  async changeStatusOfACRFirst(obj :any) {
    const resp = this.http.put<any>(this.httpUrl + '/hr/annul_conf_repo/acr/changeStatusOfACRFirst', obj).toPromise().then(x => {
      return x;
    })
    return resp
  }
  async changeStatusOfACRSecond(obj :any) {
    const resp = this.http.put<any>(this.httpUrl + '/hr/annul_conf_repo/acr/changeStatusOfACRSecond', obj).toPromise().then(x => {
      return x;
    })
    return resp
  }

  async changeStatusOfAcrOfficer(obj :any) {
    const resp = this.http.put<any>(this.httpUrl + '/hr/annul_conf_repo/acr/changeStatusOfAcrOfficer', obj).toPromise().then(x => {
      return x;
    })
    return resp
  }
  async changeStatusOfAcrOfficer_form2(obj :any) {
    const resp = this.http.put<any>(this.httpUrl + '/hr/annul_conf_repo/acr/changeStatusOfAcrOfficer_form2', obj).toPromise().then(x => {
      return x;
    })
    return resp
  }

  // HISTORY track

  async getHistoryRecord(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/gethistoryRecord', obj).toPromise().then((x) => x)
    return resp
  }
  async getDetailOfForm(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/gethistoryOfForm', obj).toPromise().then((x) => x)
    return resp
  }

  async getRejectReason(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/getReason_track', obj).toPromise().then((x) => x)
    return resp
  }
  async getRejectReason_withSelf(obj :any) {
    const resp = this.http.post(this.httpUrl + '/hr/annul_conf_repo/acr/getReason_self', obj).toPromise().then((x) => x)
    return resp
  }


}
