import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
declare var $: any
import swal from 'sweetalert2';

@Component({
  selector: 'app-pay-matrix',
  templateUrl: './pay-matrix.component.html',
  styleUrls: ['./pay-matrix.component.css']
})
export class PayMatrixComponent implements OnInit {

  constructor(private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser:any;
  b_acct_id:any;
  errorMsg:any = ''

  allMatrix:any = [];
  payMatrix:any = {};


  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.datasource){
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.datasource){
      this.datasource.sort = value;
    }
  }

  displayedColumns = ['id', 'pay_commission','pay_band','grade_pay_code','level_code','index_num','basic_pay', 'action'];
  datasource:any ;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllMatrix();
    await this.getPaymatrixCode()
  }
// Avniash Mishra

allPaymatrixCode:any =[];
selectField:any ;
async getPaymatrixCode()
{
  this.spinner.show()
  var obj :any  = new  Object();
  obj['b_acct_id'] = this.b_acct_id;
  obj['field_code']='HR0016';
 let resp=await this.settingService.getPaymatrixcode(JSON.stringify(obj));
 if(resp['error']==false)
 {
  if(resp['data'].length==0)
  {
    swal.fire('Info',"No Data Found",'info');
    return;
  }
  this.allPaymatrixCode=resp.data;

 }
console.log('this is all pay matrix ', this.allPaymatrixCode);
}





  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
    this.payMatrix={}
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }

  open_update(element:any ) {
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    this.errorMsg = ''
    this.payMatrix = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.payMatrix = {};
    this.errorMsg = ''
  }

  async getAllMatrix() {
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp.data)
      this.allMatrix = resp.data;
      this.datasource = new MatTableDataSource(this.allMatrix)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();

    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all fields list", 'Error', {
        duration: 5000
      });
    }
  }
  async save() {
    this.errorMsg = ''
    // console.log(' this is the selected obj --',this.payMatrix['pay_commission'])
    if (
      this.payMatrix['pay_commission'] == "" || this.payMatrix['pay_commission'] == undefined ||
      this.payMatrix['pay_band'] == "" || this.payMatrix['pay_band'] == undefined
      || this.payMatrix['grade_pay_code'] == "" || this.payMatrix['grade_pay_code'] == undefined
      || this.payMatrix['level_code'] == "" || this.payMatrix['level_code'] == undefined
      || this.payMatrix['index_num'] == "" || this.payMatrix['index_num'] == undefined
      || this.payMatrix['basic_pay'] == "" || this.payMatrix['basic_pay'] == undefined

    ) {

      this.errorMsg = "* fields required."
    }

    else {

      swal.fire({
        title:'Are you Sure?',
        text: 'Do You Want To Add',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.value) {
          this.finalsave()
        }
      })


    }
  }
  async finalsave() {
    this.spinner.show();
    var obj:any =new Object();
    obj['b_acct_id'] = this.b_acct_id;

    this.payMatrix['create_user_id'] = this.erpUser.user_id;
    this.payMatrix['b_acct_id'] = this.b_acct_id;
    obj['matrix_data'] = [this.payMatrix];
    var resp = await this.settingService.addMatrix(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllMatrix();
      // this.snackBar.open("Matrix Added Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Matrix Added Successfully!",'success');


    } else {
      this.spinner.hide();

      swal.fire("Sorry", "..Error while Adding Matrix!",'error');

    }
  }

async update(){
  this.errorMsg = ''
  if (
    this.payMatrix['pay_commission'] == "" || this.payMatrix['pay_commission'] == undefined ||
    this.payMatrix['pay_band'] == "" || this.payMatrix['pay_band'] == undefined
    || this.payMatrix['grade_pay_code'] == "" || this.payMatrix['grade_pay_code'] == undefined
    || this.payMatrix['level_code'] == "" || this.payMatrix['level_code'] == undefined
    || this.payMatrix['index_num'] == "" || this.payMatrix['index_num'] == undefined
    || this.payMatrix['basic_pay'] == "" || this.payMatrix['basic_pay'] == undefined

  ) {

    this.errorMsg = "* fields required."
  }

  else {

    swal.fire({
      title:'Are you Sure?',
      text: 'Do You Want To Update',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!'
    }).then((result) => {
      if (result.value) {
        this.finalupdate()
      }
    })


  }
}


  async finalupdate() {
    this.spinner.show();
    this.payMatrix['update_user_id'] = this.erpUser.user_id;
    this.payMatrix['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.updateMatrix(this.payMatrix);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllMatrix();
      // this.snackBar.open("Matrix Update Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Matrix Update Successfully!",'success');


    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Updating Matrix!",'error');

    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

}
