import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { MainService as md_mainService } from "../../../../md/service/main.service";
import Swal from "sweetalert2";

declare var $: any;
@Component({
  selector: "app-role-print",
  templateUrl: "./role-print.component.html",
  styleUrls: ["./role-print.component.css"],
})
export class RolePrintComponent implements OnInit {
  list_flage = true;
  create_flage = false;
  update_flage = false;
  module_name = [
    { module_cd: "MD", module_name: "ACCOUNT ADMINISTRATION" },
    { module_cd: "EMB", module_name: "EMB" },
    { module_cd: "ACCOUNT", module_name: "ACCOUNT " },
  ];
  checkBoxValue: any = false;
  displayedColumns = ["module_name", "doc_type", "role", "action"];
  type_data = [];
  party = [];
  datasource:any;
  user_id:any;
  index:any;
  Obj:any = {};
  b_acct_id = 0;
  erpUser:any;
  le_type = [];
  allCodeValue = [];
  doc_type = [{ doc_type: "CB" }];
  Bill_amount = [{ billamount: "Bill Amount" }];
  Roles:any = [];
  constructor(
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private mainService: md_mainService
  ) {}
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  data = [
    {
      id: 1,
      acct_short_name: "AC",
      acct_full_name: "Account",
      name: "suraj kumar",
      email: "suraj@infiniseasoft.com",
      phone_no: "9984904063",
    },
  ];
  async ngOnInit() {
    this.erpUser =  JSON.parse(atob(localStorage.getItem("erpUser") as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getRoles();

    await this.getroleprint();
  }

  mapobj = {};
  findmodul_roles() {
    this.Obj["role"] = null;
    if (this.Obj["module_code"] == "MD") {
      this.Roles = this.allRolesForAA;
    }
    if (this.Obj["module_code"] == "EMB") {
      this.Roles = this.allRolesForEMB;
    }
    if (this.Obj["module_code"] == "ACCOUNT") {
      this.Roles = this.allRolesForACCOUNT;
    }
  }

  checkCheckBoxvalue() {
    let dummy:any = [];
    if (this.checkBoxValue == false) {
      for (let i = 0; i < this.Roles.length; i++) {
        dummy.push(this.Roles[i]["role_cd"]);
      }
      this.Obj["role"] = dummy;
    } else {
      this.Obj["role"] = null;
    }
  }

  async delete(element:any) {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element);
      }
    });
  }
  roles:any;
  allRolesForEMB:any = [];
  allRolesForAA:any = [];
  allRolesForACCOUNT:any = [];
  allRoles:any = [];
  roleObj:any = {};
  async getRoles() {
    var resp = await this.mainService.getApprovalroles(
      JSON.stringify({ b_acct_id: this.b_acct_id })
    );
    if (resp["error"] == false) {
      this.roles = resp.data;
      for (let i = 0; i < resp.data.length; i++) {
        this.roleObj[resp.data[i]["role_cd"]] = resp.data[i]["role_name"];
        if (resp.data[i]["is_system_role"] == 0) {
          if (resp.data[i]["module_cd"] == "EMB") {
            this.allRolesForEMB.push(resp.data[i]);
          } else if (resp.data[i]["module_cd"] == "MD") {
            this.allRolesForAA.push(resp.data[i]);
          } else if (resp.data[i]["module_cd"] == "ACCOUNT") {
            this.allRolesForACCOUNT.push(resp.data[i]);
          } else {
            this.allRoles.push(resp.data[i]);
          }
        }
      }
    }
  }

  async finaldelete(element:any) {
    let obj:any = {};
    obj = Object.assign({}, element);
    obj["b_acct_id"] = this.b_acct_id;
    this.spinner.show();
    var resp = await this.mainService.deleteRole(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getcurrentroleswithresources();
      Swal.fire("Success", resp["data"], "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp["data"], "error");
    }
  }

  async getcurrentroleswithresources() {
    this.spinner.show();
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    var resp = await this.mainService.getcurrentroleswithresources(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.spinner.hide();

      var data:any = [];
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["is_system_role"] == 0) {
          data.push(resp.data[i]);
        }
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error", "..Error while getting  roles", "error");
    }
    var j = 0;
    var a = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i]["role_name"] != "EMB Employee") {
        var b:any = {};
        b["role_cd"] = data[i]["role_cd"];
        b["role_name"] = data[i]["role_name"];
        a[j] = b;
        j++;
      }
    }

    this.Roles = a;
  }
  my_arr = [];
  async getroleprint() {
    this.my_arr = [];
    this.spinner.show();
    let obj:any = {};
    Object.assign(obj, {});
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.mainService.getroleprint(JSON.stringify(obj));

    if (resp["error"] == false) {
      this.spinner.hide();
      var data = resp["data"];
      for (let i = 0; i < data.length; i++) {
        data[i]["role"] = JSON.parse(data[i]["role"]);
      }
      this.datasource = new MatTableDataSource(data);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide();
      Swal.fire("Error", "..Error while getting  roles", "error");
    }
  }
  async gotolist() {
    this.list_flage = true;
    this.create_flage = false;
    this.update_flage = false;
    await this.getCurrentResource();
    await this.getcurrentroleswithresources();
    await this.getroleprint();
  }
  Create() {
    this.refresh();
    this.list_flage = false;
    this.create_flage = true;
    this.update_flage = false;
  }

  async getAllModules() {
    this.spinner.show();
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.mainService.getMdModules(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", "..Error while getting modules", "error");
    }
  }
  resource:any = [];
  async getCurrentResource() {
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.mainService.getMdResource(JSON.stringify(obj));
    if (resp["error"] == false) {
      let dummy = [];
      for (let i = 0; i < resp["data"].length; i++) {
        if (resp["data"][i]["module_cd"] == "EMB") {
          resp["data"][i]["desc"] =
            resp["data"][i]["module_cd"] +
            " - " +
            resp["data"][i]["resource_desc"];
          dummy.push(resp["data"][i]);
        }
      }
      this.resource = dummy;
    } else {
      Swal.fire("Error", "..Error while getting  resource", "error");
    }
  }

  viewObj = {};
  viewDetails(element:any) {
    this.viewObj = Object.assign({}, element);
    $("#B").modal("show");
  }

  changeparty() {
    for (let i = 0; i < this.party.length; i++) {
      if (this.party[i]["party_id"] == this.Obj["party_id"]) {
        this.Obj["party_leagal_name"] = this.party[i]["party_leagal_name"];
      }
    }
  }

  async refresh() {
    this.Obj = {};
  }

  back() {
    $('.nav-tabs a[href="#tab-7-1"]').tab("show");
    this.Obj = {};
  }

  async update() {
    this.Obj["b_acct_id"] = this.b_acct_id;
    this.Obj["module_code"] = this.Obj["module_code"];
    let x = JSON.stringify(this.Obj["role"]);
    this.Obj["role"] = x;
    this.spinner.show();
    if (
      this.Obj["module_code"] == null ||
      this.Obj["doc_type"] == null ||
      this.Obj["role"] == null ||
      this.Obj["field"] == null
    ) {
      this.spinner.hide();
      Swal.fire("Error", "..Complete Required Field", "error");
    } else {
      const resp = await this.mainService.updateroleprint(this.Obj);
      if (resp["error"] == false) {
        this.spinner.hide();
        this.Obj = {};
        Swal.fire("success", "Seccessfully Updated", "success");
        this.getroleprint();
        this.gotolist();
      } else {
        this.Obj = {};
        this.spinner.hide();
        Swal.fire("Error", "..Error Updation", "error");
      }
    }
  }

  async open_update(element:any) {
    this.list_flage = false;
    this.create_flage = false;
    this.update_flage = true;
    this.spinner.show();
    if (element["module_code"] == "MD") {
      this.Roles = this.allRolesForAA;
    }
    if (element["module_code"] == "EMB") {
      this.Roles = this.allRolesForEMB;
    }
    if (element["module_code"] == "ACCOUNT") {
      this.Roles = this.allRolesForACCOUNT;
    }

    this.Obj = Object.assign({}, element);

    if (element["role"]) {
      this.Obj["role"] = element["role"];
    }

    $('.nav-tabs a[href="#tab-7-3"]').tab("show");
    this.spinner.hide();
  }

  async submit() {
    this.spinner.show();
    this.Obj["b_acct_id"] = this.b_acct_id;

    let x = JSON.stringify(this.Obj["role"]);

    this.Obj["role"] = x;

    if (
      this.Obj["module_code"] == null ||
      this.Obj["doc_type"] == null ||
      this.Obj["role"] == null ||
      this.Obj["field"] == null
    ) {
      this.spinner.hide();
      Swal.fire("Error", "..Complete Required Field", "error");
    } else {
      const resp = await this.mainService.insertroleprint(this.Obj);
      if (resp["error"] == false) {
        this.spinner.hide();
        this.Obj = {};
        Swal.fire("success", "Seccessfully Inserted", "success");
        await this.getRoles();
        await this.getroleprint();
        this.gotolist();
      } else {
        this.Obj = {};
        this.spinner.hide();
        Swal.fire("Error", "..Error Insertion", "error");
      }
    }
  }

  validate(email:any) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return false;
    } else {
      return true;
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
