import { Component, AfterViewInit, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '../../../authentication.service';
import { ProfileService } from '../../../portal/service/profile.service';
import {MainService} from '../../service/main.service';

@Component({
  selector: '[app-admin-header]',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements AfterViewInit{

  constructor(public mainService: MainService,public auth:AuthenticationService,private sanitizer:DomSanitizer,private profileService:ProfileService) { }
  userInfo={emp_name:'Ram Prasad'}
  imgURL:any;
  acc_imgURL:any;
  erpUser:any={}
  async ngOnInit() {


    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any ));


    await this.getUserImage()
    await this.getAccImage()
  }
  async getAccImage() {
    const res = await this.profileService.getAccImage(this.erpUser['b_acct_id']);
    console.log(res,'accinage')
    if (res) {
      const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      this.acc_imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.mainService.accountImageUrl = this.acc_imgURL;
    }
  }
  async getUserImage(){
    const res = await this.profileService.getImage(this.erpUser['user_id']);
    console.log(res)
    if (res) {
      const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.mainService.profileImageUrl=this.imgURL;
    }
  }
  ngAfterViewInit()  {
	}

}
