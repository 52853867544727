<div id="sidebar-collapse" class="sidebar">

    <div class="admin-block d-flex" style="align-items: center;">
        <div>
            <img src="./../../../assets/img/dash/TRACKING.jpeg" class="img-circle" width="45px" />
        </div>
        <div class="admin-info">
            <div class="font-strong" (click)="home()">{{auth.allSystemModuleObj['TRACKING']}}</div>
        </div>
    </div>
    <ul class="side-menu metismenu">

        <li [hidden]="sidebar['dashbord']">
            <a class = "text-decoration-none" routerLinkActive="active" (click)="refreshCol()" routerLink="/tracking/tracking-index"><i class="sidebar-item-icon fa fa-th-large"></i>
                <span class="nav-label"> Dashboard </span>
            </a>
        </li>

        <li routerLinkActive="active"  >
            <a class = "text-decoration-none" href="#tracking"  data-bs-toggle="collapse" aria-expanded="false"
            aria-controls="tracking"><i class="sidebar-item-icon fa fa-archive"></i>
                <span class="nav-label">Tracking</span><i class="fa fa-angle-left arrow"></i></a>

            <ul class="nav-2-level collapse" id="tracking" data-bs-parent="#sidebar-collapse" routerLinkActive="in">

                <li [hidden]="sidebar['emp_tracting']">
                    <a class = "text-decoration-none" routerLink="/emp-tracking" routerLinkActive="active">Emp Tracking</a>
                </li>
                <li [hidden]="sidebar['self_tracting']">
                    <a class = "text-decoration-none" routerLink="/self-emp-tracking" routerLinkActive="active">Employee Tracking</a>
                </li>
            </ul>
        </li>

<!-- ////////////////////////////////////////////////////////////////////////////////////////////// -->

        <li routerLinkActive="active" >
            <a class = "text-decoration-none"  href="#Administration"  data-bs-toggle="collapse" aria-expanded="false"
            aria-controls="Administration" [hidden]="sidebar['administration']" ><i class="sidebar-item-icon fa fa-database"></i>
                <span class="nav-label">Administration</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse"  id="Administration" data-bs-parent="#sidebar-collapse" routerLinkActive="in">
              <div id="child1">
                  <li>
                    <a class = "text-decoration-none" href="#md" data-bs-toggle="collapse" aria-controls="md1" aria-expanded="false" [hidden]="sidebar['master_data']">
                        <span class="nav-label">Master Data</span><i class="fa fa-angle-left arrow"></i></a>
                    <ul class="nav-3-level collapse" id="md" data-bs-parent="#child1"  >

                        <li [hidden]="sidebar['code_value']">
                            <a class = "text-decoration-none" routerLink="/tracking/tracking-setting/code-value" routerLinkActive="active">Code Value</a>
                        </li>
                        <li [hidden]="sidebar['field']">
                            <a class = "text-decoration-none" routerLink="/tracking/tracking-setting/field" routerLinkActive="active">Field</a>
                        </li>

                        <li [hidden]="sidebar['boundry_map']">
                            <a class = "text-decoration-none" routerLink="/tracking/tracking-setting/boundry-map" routerLinkActive="active">Boundary Map</a>

                        </li>
                        <li [hidden]="sidebar['hierarchy']">
                            <a class = "text-decoration-none" routerLink="/tracking/tracking-setting/hierachy" routerLinkActive="active">
                                Hierachy
                            </a>
                        </li>

                    </ul>
                  </li>
                  <li>
                    <a class = "text-decoration-none"  href="#sec" data-bs-toggle="collapse" aria-controls="sec" aria-expanded="false" [hidden]="sidebar['security']">
                        <span class="nav-label">Security</span><i class="fa fa-angle-left arrow"></i></a>
                    <ul class="nav-3-level collapse" id="sec" data-bs-parent="#child1" >
                        <li>
                            <a class = "text-decoration-none" [hidden]="sidebar['role']" routerLink="/tracking/administration/roles" routerLinkActive="active">
                                Role
                            </a>
                        </li>
                        <li>
                            <a class = "text-decoration-none" [hidden]="sidebar['user']" routerLink="/tracking/administration/users" routerLinkActive="active">User</a>
                        </li>
                        <li>
                            <a class = "text-decoration-none"  [hidden]="sidebar['data_assignment']" routerLink="/tracking/administration/role-authorization" routerLinkActive="active">
                              Data Assignment
                            </a>
                        </li>
                        <!-- <li>
                            <a class = "text-decoration-none" [hidden]="sidebar['workflow']" routerLink="/tracking/administration/workflow" routerLinkActive="active">
                                Workflow
                            </a>
                        </li> -->
                    </ul>
                  </li>
              </div>
            </ul>
        </li>

        <li routerLinkActive="active">
            <a class = "text-decoration-none" href="#help"  data-bs-toggle="collapse" aria-expanded="false"
            aria-controls="help"><i class="sidebar-item-icon fa fa-question-circle"></i>
                <span class="nav-label">Help</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" id="help" data-bs-parent="#sidebar-collapse" routerLinkActive="in">
                <li>
                    <a class = "text-decoration-none" routerLink="/tracking/help/faq"
                        routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar20']}}</a>
                </li>
                <li>
                    <a class = "text-decoration-none" routerLink="/tracking/help/user-manual"
                        routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar21']}}</a>
                </li>
                <li>
                    <a class = "text-decoration-none" routerLink="/tracking/help/tutorial"
                        routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar22']}}</a>
                </li>
                <li>
                    <a class = "text-decoration-none" routerLink="/tracking/help/online-support"
                        routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar23']}}</a>
                </li>
            </ul>
        </li>
    </ul>
</div>
