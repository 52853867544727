import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort'
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { JoiningService } from '../../service/joining.service';
import { AllEmpService } from '../../service/all-emp.service';
import { DataAccessService } from '../../service/data-access.service';
import { from } from 'rxjs';
import { MainService } from '../../service/main.service'
import swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { ScriptLoaderService } from '../../../_services/script-loader.service';



declare var $: any;

@Component({
  selector: 'app-all-emp',
  templateUrl: './all-emp.component.html',
  styleUrls: ['./all-emp.component.css']
})
export class AllEmpComponent implements OnInit {

  constructor(private dataAccessService: DataAccessService, private _script: ScriptLoaderService, private sanitizer: DomSanitizer, public mainService: MainService, private allEmpService: AllEmpService, private joiningservice: JoiningService, private snackBar: MatSnackBar, private router: Router, private spinner: NgxSpinnerService) { }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  // @ViewChild('paginator2', { static: true }) paginator2: MatPaginator;
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;




  @ViewChild(MatPaginator, { static: false })
  set paginator2(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }

  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }


  // @ViewChild(MatPaginator, {static: false})
  // set paginator1(value: MatPaginator) {
  //   if (this.datasource1){
  //     this.datasource1.paginator= value;
  //   }
  // }
  @ViewChild(MatSort, { static: false })
  set sort1(value: MatSort) {
    if (this.datasource1) {
      this.datasource1.sort = value;
    }
  }

  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: false }) sort: MatSort;

  // @ViewChild('paginator0', { static: false }) paginator0: MatPaginator;
  // @ViewChild('sortCol0', { static: false }) sortCol0: MatSort;

  // @ViewChild('paginator1', { static: false }) paginator1: MatPaginator;
  // @ViewChild('sortCol1', { static: false }) sortCol1: MatSort;

  // @ViewChild('paginator1', { static: false }) paginator1: MatPaginator;
  // @ViewChild('sortCol2', { static: false }) sortCol2: MatSort;


  displayedColumns: any = ['emp_id', 'emp_name', 'emp_email', 'emp_father_name', 'emp_phone_no', 'action'];
  displayedColumns1: any = ['emp_id', 'emp_name', 'document_name', 'document_type', 'action'];
  imageBlobUrl: any;
  imgURL: any
  // selectedFile: FileList = null;
  isUpload: any;
  public imagePath: any;
  httpUrl: any;
  uploader: any;
  adhar: any = false;
  pancard: any = false;
  datasource: any;
  datasource1: any;

  b_acct_id: any;
  erpUser: any;
  party_status_code: any;
  allEmplyees: any = [];
  empObj: any = {};
  personalInfoObj: any = {};
  selectEmpObj: any = {};
  errorMsg: any = ''
  uploadObj: any = {}
  empNameObj: any = {}
  filename: any;
  project_short_name: any;
  allEmplyees_new: any = []
  Employees: any = []
  //Avinash
  phDisabled: any = false;
  async ngOnInit() {

    this.httpUrl = this.mainService.httpUrl;

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.project_short_name = this.mainService.accInfo['account_short_name'];
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    await this.getAllEmployees();

    for (let n of this.erpUser.role_cd) {
      if (n == 'HR_ADMIN') {
        this.phDisabled = true;
      }
    }
  }
  getNumberFormat(num: any) {

    if (num != undefined) {
      return num.toString().padStart(3, "0")

    } else {
      return '000';
    }
  }
  list_flag = true
  view_flag = false
  update_flag = false
  doc_flag = false
  phone_flag = false
  open_list() {

    this.list_flag = true
    this.view_flag = false
    this.update_flag = false
    this.doc_flag = false
    this.phone_flag = false
  }

  // OldAllCCRow = [];
  // async open_update(element) {
  //   this.list_flag = false
  //   this.create_flag = false
  //   this.update_flag = true
  //   this.joiningObj = Object.assign({}, element)
  //   console.log(element);
  // }
  async getAllEmployees() {
    this.spinner.show()
    var arr: any = []
    this.allEmplyees = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';

    var resp = await this.allEmpService.getAllPartyFields(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      this.allEmplyees = [];
      var assignedEmp = this.dataAccessService.assignedEmp;
      console.log(assignedEmp)
      for (let i = 0; i < arr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = this.getNumberFormat(obj['emp_id']);
        if (assignedEmp[arr[i]['arr_id']] != undefined) {
          this.allEmplyees.push(obj)
        }
      }

      this.allEmplyees_new = [];
      for (let i = 0; i < this.allEmplyees.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, this.allEmplyees[i]);
        obj['emp_name'] = this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.allEmplyees_new.push(obj)
      }
      for (let i = 0; i < this.allEmplyees.length; i++) {
        this.empNameObj[this.allEmplyees[i]['emp_id']] = this.allEmplyees[i]['emp_name']
      }
      console.log("first tabel paginator--", this.paginator2)
      this.datasource = new MatTableDataSource(this.allEmplyees)
      this.datasource.paginator = this.paginator2;
      this.datasource.sort = this.sort;
      // this.datasource.sort = this.sortCol0;
    } else {
      this.spinner.hide()

    }
  }

  joining_date: any
  service_date: any
  async openUpdate(element: any) {
    let obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['arr_id'] = element.arr_id
    let resp: any = await this.allEmpService.getJoinningDate(obj)
    console.log("this is the responce of the joinning date ", resp)
    if (resp['error'] == false) {
      this.empObj = element;
      this.list_flag = false
      this.update_flag = false
      this.doc_flag = false
      this.phone_flag = false

      if (resp['data']['joining_date'] == null ||
        resp['data']['service_date'] == null) {
        this.joining_date = ''
        this.service_date = ''
        swal.fire('Info', "This Employee Not Established yet...!", 'info')
      }
      else {
        this.joining_date = resp['data']['joining_date']
        this.service_date = resp['data']['service_date']

      }
      //  setTimeout(async () => {

      this.view_flag = true
      // }, 300);
    }
    else {
      this.service_date = ''
      this.joining_date = ''
      swal.fire('Info', 'Employee Joinnig/ Service Date Not Found', 'info');
      return
    }


    //    $('.nav-tabs a[href="#tab-2"]').tab('show');
  }

  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  changeEmployee() {

  }


  phoneNoUpdObj: any = {}
  async updatePhoneNo(element: any) {
    this.phoneNoUpdObj = {}
    this.phone_flag = true
    this.update_flag = false;
    this.doc_flag = false;
    this.aviCheck = false;
    this.list_flag = false;
    this.view_flag = false;
    // console.log(" this isthe update pgone no ", element);
    // this.phoneNoUpdObj['old_no']= element.emp_phone_no;
    // this.phoneNoUpdObj['arr_id']= element.arr_id;
    // this.phoneNoUpdObj['le_id']= element.le_id;
    // this.phoneNoUpdObj['emp_id']= element.emp_id;
    this.phoneNoUpdObj = Object.assign({}, element)
    this.phoneNoUpdObj['old_no'] = element.emp_phone_no;
    this.phoneNoUpdObj['arr_id'] = element.arr_id;
    this.phoneNoUpdObj['le_id'] = element.le_id;
    this.phoneNoUpdObj['emp_id'] = element.emp_id;
    // console.log(" this isthe update pgone no ", this.phoneNoUpdObj);

  }

  async submitPhoneNO() {
    if (this.phoneNoUpdObj['new_no'] == undefined || this.phoneNoUpdObj['new_no'] == null
      || this.phoneNoUpdObj['emp_name'] == undefined || this.phoneNoUpdObj['emp_name'] == null
    ) {
      swal.fire('Warning', '* Field Required', 'warning');
      return;
    }
    this.spinner.show();
    this.phoneNoUpdObj['b_acct_id'] = this.b_acct_id;
    console.log(" thsi si the submit button ", this.phoneNoUpdObj);
    let resp: any = await this.allEmpService.updatePhoneNo(this.phoneNoUpdObj);
    // console.log(" tihs is hte responce ", resp);
    if (resp['error'] == false) {
      this.open_list();
      setTimeout(async () => {
        this.spinner.hide();
        await this.getAllEmployees();
        swal.fire("Success", "Phone Number Updated!", 'success');
      }, 1000);
    }
    else {
      this.spinner.hide();
      swal.fire('Error', 'Some Error Occured', 'error')

    }

  }
  // Avinash Mishra

  async Update(element: any) {
    this.list_flag = false;
    this.view_flag = false;

    // for(let n of this.erpUser.role_cd)
    // {
    // if(n=='AA')
    // {
    //   this.phDisabled=false;
    // }
    // }

    this.phone_flag = false
    this.update_flag = true;
    this.doc_flag = false;
    this.aviCheck = false;
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = element.emp_id;
    this.spinner.show();
    var resp = await this.allEmpService.getPersonalInfo(obj);
    if (resp['error'] == false) {
      console.log(resp.data)
      this.spinner.hide()

      if (resp.data.length > 0)
        this.personalInfoObj = resp.data[0];
      else {
        this.personalInfoObj = {};
      }
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }




  error_exist = false;

  async updatePersonalInfo() {
    this.pancard = false;
    this.adhar = false
    console.log("lthsi is the obj", this.personalInfoObj);
    if (this.personalInfoObj['emp_pan_no'] == "" || this.personalInfoObj['emp_pan_no'] == null || this.personalInfoObj['emp_pan_no'] == undefined) {
      swal.fire("Sorry !", "* Fields Required...", 'warning');
    } else {
      this.panValidate()
    }


    if (this.personalInfoObj['emp_adhar_no'] == "" || this.personalInfoObj['emp_adhar_no'] == null || this.personalInfoObj['emp_adhar_no'] == undefined) {
      swal.fire("Sorry !", "* Fields Required...", 'warning');
    }
    else {
      this.AadharValidate()
    }


    if (this.personalInfoObj['emp_father_name'] == "" || this.personalInfoObj['emp_father_name'] == null || this.personalInfoObj['emp_father_name'] == undefined ||
      this.personalInfoObj['emp_mother_name'] == "" || this.personalInfoObj['emp_mother_name'] == null || this.personalInfoObj['emp_mother_name'] == undefined ||
      this.personalInfoObj['emp_phone_no'] == "" || this.personalInfoObj['emp_phone_no'] == null || this.personalInfoObj['emp_phone_no'] == undefined
      || this.personalInfoObj['emp_permanent_addr_pin_code'] == undefined || this.personalInfoObj['emp_permanent_addr_pin_code'] == null || this.personalInfoObj['emp_permanent_addr_pin_code'] == ''
      || this.personalInfoObj['emp_permanent_addr_dist'] == undefined || this.personalInfoObj['emp_permanent_addr_dist'] == null || this.personalInfoObj['emp_permanent_addr_dist'] == ''
      || this.personalInfoObj['emp_permanent_addr_state'] == undefined || this.personalInfoObj['emp_permanent_addr_state'] == null || this.personalInfoObj['emp_permanent_addr_state'] == ''
      || this.personalInfoObj['emp_permanent_addr_city'] == undefined || this.personalInfoObj['emp_permanent_addr_city'] == null || this.personalInfoObj['emp_permanent_addr_city'] == ''
      || this.personalInfoObj['emp_permanent_addr_line2'] == undefined || this.personalInfoObj['emp_permanent_addr_line2'] == null || this.personalInfoObj['emp_permanent_addr_line2'] == ''
      || this.personalInfoObj['emp_permanent_addr_line1'] == undefined || this.personalInfoObj['emp_permanent_addr_line1'] == null || this.personalInfoObj['emp_permanent_addr_line1'] == ''
    ) {
      console.log("iner the valid")
      swal.fire("Sorry !", " * Fields Required...", 'warning');
      return;
    }


    if (!this.pancard || !this.adhar) {
      return;
    }


    swal.fire({
      title: 'Are you Sure?',
      text: 'Do You Want To Update',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!'
    }).then((result) => {
      if (result.value) {
        this.finalupdatePersonalInfo()
      }
    })
    // }
  }
  async finalupdatePersonalInfo() {
    this.personalInfoObj['b_acct_id'] = this.b_acct_id;
    this.personalInfoObj['party_id'] = this.selectEmpObj['party_id'];
    this.personalInfoObj['update_user_id'] = this.erpUser.user_id;
    this.personalInfoObj['emp_gst_no'] = '123'

    this.spinner.show();
    var resp = await this.allEmpService.updatePersonalInfo(this.personalInfoObj);
    if (resp['error'] == false) {
      this.open_list();
      setTimeout(async () => {
        this.spinner.hide();
        await this.getAllEmployees();
        swal.fire("Success", "...Personal Info Updated!", 'success');
      }, 1000);

    } else {
      this.spinner.hide();
      swal.fire("Error", "Some Error Occurred!", 'error');

    }
  }



  async upload() {

    if (this.selectedFile.length == 0) {
      swal.fire("Sorry", "Please select At Least One File", "warning");
      return;
    }
    if (this.uploadObj['emp_id'] == null || this.uploadObj['emp_id'] == undefined) {
      swal.fire("Sorry !", "Please select At Least One Employee", "warning");
      return;
    }
    if (this.uploadObj['document_type_code'] == null || this.uploadObj['document_type_code'] == undefined) {
      swal.fire('Warning', "Select Document Type First ..!", 'warning')
      return;
    }
    const formData = new FormData();
    for (let i = 0; i < this.selectedFile.length; i++) {
      // check the file is video / xlex / audio



      // end validation
      formData.append("image", this.selectedFile[i]);
    }

    const obj: any = new Object();
    obj['b_acct_id'] = this.erpUser.b_acct_id;
    obj['document_name'] = this.doc_name;
    obj['document_type_code'] = this.uploadObj['document_type_code'];
    obj['emp_id'] = this.uploadObj['emp_id'];

    obj['create_user_id'] = this.erpUser.user_id;
    this.spinner.show()
    formData.append("data", JSON.stringify(obj));
    let resp: any = await this.allEmpService.insertDocument(formData)
    console.log("thsi si the resp ", resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.selectedFile = [];

      this.aviDocument(obj['emp_id']);
      swal.fire('Success', "File Uploaded Successfully", 'success');
      $('#aviDoc').val('');

    }

    else {
      this.spinner.hide()
      swal.fire("Error", 'File Not Uploadded..! ', 'error');
    }

  }


  // for(let j=0;j<=this.selectedFile.length;j++){

  //     const params = JSON.stringify(obj);
  //     this.uploader.queue[j].url = this.httpUrl + '/hr/establishment_info/uploadfile/uploadFile' + params;
  //     this.uploader.queue[j].upload();
  // }

  //     this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
  //       if (!response.error) {
  //         this.spinner.hide()
  //         this.aviDocument(obj['emp_id']);
  //         swal.fire('Success',"File Uploaded Successfully",'success');
  //         $('#aviDoc').val('');
  //       }




  async aviMatch(t: any) {

    let ext = t.split(".");

    for (let i = 0; i < ext.length; i++) {
      if (ext[i] == "exe" || ext[i] == "mp4" || ext[i] == "MKV" || ext[i] == "AVI" || ext[i] == "MOV" || ext[i] == "xlsx" || ext[i] == 'docx' || ext[i] == 'doc') {
        return 0;
      }

    }

    return
  }


  selectedFile: any = [];
  doc_name: any = [];

  async onFileUpload(event: any, files: any) {
    this.selectedFile = [];
    this.doc_name = [];
    console.log("this is the onfile change ");
    for (let i = 0; i < event.target.files.length; i++) {
      let text = event.target.files[i]["name"]
      let res = await this.aviMatch(text);

      if (res == 0) {
        $('#aviDoc').val('');
        swal.fire('Info', `You Select the .exe/ .docx File/ Video File, Remove This First..!`, 'info');
        return;
      }
      console.log("thsi si the table of hte employee", this.Employees)
      let nameMatch = this.Employees.filter((x: any) => {
        if (x.document_name == event.target.files[i]["name"]) {
          return x.document_name
        }
      })
      console.log("thsi si the name match", nameMatch);
      if (nameMatch.length >= 1) {
        $('#aviDoc').val('');
        swal.fire("Warning", 'File Already Exist', 'warning');
        return;
      }

      this.selectedFile.push(event.target.files[i]);
      this.doc_name.push(event.target.files[i]["name"]);

      let reader = new FileReader();
      reader.onload = (event: any) => {
        // // console.log("result--",event.target.result);
      };
      reader.readAsDataURL(event.target.files[i]);
    }

    console.log("this si the length of hte file ", files.length, event.target.files.length);


  }

  async updatedob() {
    this.personalInfoObj['b_acct_id'] = this.b_acct_id;
    this.personalInfoObj['party_id'] = this.selectEmpObj['party_id'];
    this.personalInfoObj['update_user_id'] = this.erpUser.user_id;
    this.personalInfoObj['emp_gst_no'] = '123'
    var arr: any = this.personalInfoObj['emp_dob'].split("-")
    arr[0] = parseInt(arr[0]) + 60
    if (arr[2] > 1) {

    }
    else {
      if (arr[1] == 1) {
        arr[1] = 12
        arr[0] = parseInt(arr[0]) - 1

      } else {
        arr[1] = parseInt(arr[1]) - 1
      }

    }
    if (parseInt(arr[1]) <= 7) {
      if ((parseInt(arr[1]) % 2) == 0 && (parseInt(arr[1])) != 2) {

        arr[2] = 30

      }
      else if ((parseInt(arr[1])) == 2) {
        if ((parseInt(arr[0]) % 4) == 0) {

          arr[2] = 29

        }
        else {
          arr[2] = 28
        }
      } else {
        arr[2] = 31
      }
    } else {
      if ((parseInt(arr[1]) % 2) == 0 && (parseInt(arr[1])) != 2) {

        arr[2] = 31

      }
      else if ((parseInt(arr[1])) == 2) {
        if ((parseInt(arr[0]) % 4) == 0) {

          arr[2] = 29

        }
        else {
          arr[2] = 28
        }
      } else {
        arr[2] = 30
      }
    }

    var date = arr[0] + "-" + arr[1] + "-" + arr[2]
    this.personalInfoObj['retirement_date'] = date
    this.spinner.show();
    var resp = await this.allEmpService.updatedob(this.personalInfoObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      swal.fire("Success", "...Date of Birth Updated!");
      // this.snackBar.open("...Date of Birth Updated!", 'Error', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      swal.fire("Error", "Some Error Occurred!", 'error');

    }
  }


  async viewDocumments(element: any) {

    this.list_flag = false;
    this.view_flag = false;
    this.update_flag = false;
    this.phone_flag = false
    this.doc_flag = true;
    this.imgURL = "";
    this.uploadObj = {};
    this.aviDocument(element.emp_id)
    console.log("this is the first paginator --", this.paginator2);
    // var arr :any=  []
    // var Employees = []
    // var obj:any = new  Object;
    // obj['b_acct_id'] = this.b_acct_id
    // obj['emp_id'] = element.emp_id

    // var resp = await this.allEmpService.getUploadedFiles(JSON.stringify(obj));
    // console.log(resp);
    // if (resp['error'] == false) {
    //   arr = resp.data;
    //   for (let i = 0; i < arr.length; i++) {
    //     var obj:any = new  Object();
    //     obj = Object.assign({}, arr[i]);
    //     obj['tempid'] = /* this.mainService.accInfo['account_short_name'] +  */this.getNumberFormat(obj['emp_id'])
    //     Employees.push(obj)
    //   }
    //   this.datasource1 = new MatTableDataSource(Employees)
    //   this.datasource1.paginator = this.paginator1;
    //   this.datasource1.sort = this.sortCol2
    // } else {

    // }
    $('.nav-tabs a[href="#tab-4"]').tab('show');
  }

  async aviDocument(emp_id: any) {
    var arr: any = []
    this.Employees = [];
    var obj: any = new Object;
    obj['b_acct_id'] = this.b_acct_id
    obj['emp_id'] = emp_id
    console.log("this si hte parameter of the avidocument", emp_id)
    var resp = await this.allEmpService.getUploadedFiles(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      arr = resp.data;
      for (let i = 0; i < arr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = /* this.mainService.accInfo['account_short_name'] +  */this.getNumberFormat(obj['emp_id'])
        this.Employees.push(obj)
      }
      console.log("second tabel paginator--", this.paginator1)
      this.datasource1 = new MatTableDataSource(this.Employees)
      this.datasource1.paginator = this.paginator1;
      this.datasource1.sort = this.sort1;
      // this.datasource1.sort = this.sortCol1;
    } else {

    }
  }


  async view(element: any) {
    var obj: any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['upload_id'] = element.id
    obj['filename'] = element.document_name
    this.filename = element.document_name
    this.spinner.show()
    const res = await this.allEmpService.getUploadedFileData(obj);
    if (res) {

      var docname = element.document_name;
      var ext = docname.split('.');
      console.log("thsi si the ext ", ext, "Ext length", ext[ext.length - 1])
      if (ext[ext.length - 1] == 'png' || ext[ext.length - 1] == 'jpeg' || ext[ext.length - 1] == 'jpg' || ext[ext.length - 1] == 'JPG' || ext[ext.length - 1] == 'PNG') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.closeimage = true;
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

      this.spinner.hide()

    }
  }
  closeimage = false
  async closeImage() {
    this.imgURL = ""
    this.closeimage = false;

  }

  AadharValidate() {
    var aadhar = this.personalInfoObj['emp_adhar_no'].toString();
    console.log("thsi is adharcahr ", this.personalInfoObj['emp_adhar_no'].toString(), this.personalInfoObj['emp_adhar_no'])
    var adharcardTwelveDigit = /^\d{12}$/;
    var adharSixteenDigit = /^\d{16}$/;
    if (aadhar != '') {
      if (aadhar.match(adharcardTwelveDigit)) {
        this.adhar = true;
      }
      else if (aadhar.match(adharSixteenDigit)) {
        this.adhar = true;
      }
      else {
        swal.fire("Sorry", "...Aadhar must be 12 digit....!", 'warning');
        // this.errorMsg = "* Aadhar must be 12 digit."
        this.adhar = false;
      }
    }
  }
  panValidate() {
    var pan = this.personalInfoObj['emp_pan_no'].toString();
    console.log("thi a pan card ", this.personalInfoObj['emp_pan_no'].toString(), this.personalInfoObj['emp_pan_no'])
    var panno = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (pan != '') {
      if (pan.match(panno)) {
        this.pancard = true;
      }

      else {
        swal.fire("Sorry", "...Pan is Invalid....!", 'warning');
        // this.errorMsg = "* Pan is Invalid."
        this.pancard = false;
      }
    }
  }
  applyFilter1(filterValue: string) {

    this.datasource1.filter = filterValue.trim().toLowerCase();
  }

  aviCheck: boolean = false;
  checkAvi(event: any) {

    console.log(event.target.checked)
    let x = event.target.checked;
    if (x) {
      this.aviCheck = true;
      this.personalInfoObj['emp_local_addr_line1'] = "";
      this.personalInfoObj['emp_local_addr_line2'] = "";
      this.personalInfoObj['emp_local_addr_city'] = "";
      this.personalInfoObj['emp_local_addr_state'] = "";
      this.personalInfoObj['emp_local_addr_dist'] = "";
      this.personalInfoObj['emp_local_addr_pin_code'] = "";

      console.log("checked", this.aviCheck);
    }
    else {
      this.aviCheck = false;
      console.log("unchecked", this.aviCheck);
    }

  }


}
