<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Vendor Management</div>
        </div>
        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link active" *ngIf="list" data-toggle="tab" href="#tab-1" (click)="getVendor()"><i
                  class="fa fa-line-chart"></i>&nbsp;All Vendor</a>
            </li>
            <li class="nav-item" *ngIf="create">
              <a class="nav-link" data-toggle="tab"><i class="fa fa-plus-circle" aria-hidden="true"></i>
                Create Vendor
              </a>
            </li>
            <li class="nav-item" *ngIf="update_flag">
              <a class="nav-link" data-toggle="tab"><i class="fa fa-plus-circle" aria-hidden="true"></i>
                Update Vendor
              </a>
            </li>
            <li class="nav-item" *ngIf="view_details">
              <a class="nav-link" data-toggle="tab"><i class="fa fa-plus-circle" aria-hidden="true"></i>
                Details Of Vendor
              </a>
            </li>
            <li class="nav-item"  href="#tab-4" (click)="registere()">
              <a class="nav-link" data-toggle="tab"><i class="fa fa-plus-circle" aria-hidden="true"></i>
                Registered Vendor
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div id="tab-1" class="active" *ngIf="list">
              <br>
              <div class="row">
                <div class="col-12 text-right">
                  <!-- <button class="btn btn-outline-primary" (click)=" openCreate()">Create
                    Vendor</button> -->
                </div>
              </div>
              <br><br>
              <br>
              <div class="row">
                <div class="col-4">
                  <mat-form-field style="margin-left: 2%">
                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                  </mat-form-field>
                </div>
              </div>

              <div class="example-container">
                <table mat-table [dataSource]="dataSource" matSort class="text-center">
                  <ng-container matColumnDef="s.no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <h6>
                        <b>S.No</b>
                      </h6>
                    </th>
                    <td mat-cell *matCellDef="let element ; let i = index "> {{i+1}} </td>
                  </ng-container>

                  <ng-container matColumnDef="vendor_id">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Vendor ID
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.vendor_id}} </td>
                  </ng-container>


                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Vendor Type
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.org_type}} </td>
                  </ng-container>

                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Organization name
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.org_name}} </td>
                  </ng-container>

                  <!-- <ng-container matColumnDef="category">
                                        <th mat-header-cell *matHeaderCellDef class="text-center">Category</th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.category}} </td>
                                    </ng-container> -->

                  <ng-container matColumnDef="mobile_no">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Mobile Number
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.mobile_no }} </td>
                  </ng-container>

                  <!-- <ng-container matColumnDef="valid_upto">
                                        <th mat-header-cell *matHeaderCellDef class="text-center">Valid Upto
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.valid_upto_user | date:'dd/MM/yyyy'}} </td>
                                    </ng-container> -->

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Status
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.life_cycle_status}} </td>
                  </ng-container>

                  <ng-container matColumnDef="action" style="width: 3rem;">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="text-center">
                      <button (click)="viewDetails(element)" class="btn btn-outline-primary">View
                        Details</button>&nbsp;&nbsp;
                      <!-- <button *ngIf="element.life_cycle_status=='ACTIVE'" (click)="openUpdate(element,stepper1)"
                        class="btn btn-outline-success">Update</button>&nbsp;&nbsp; -->
                      <!-- <button *ngIf="element.life_cycle_status=='ACTIVE'" (click)="openInactive(element)"
                        class="btn btn-outline-primary">Inactive</button>&nbsp;&nbsp; -->


                        <button (click)="register(element)" *ngIf="!element.le_id" class="btn btn-outline-success">Register</button>&nbsp;&nbsp;



                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;">

                  </tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
              </div>

            </div>

            <div id="tab-2" class="active" *ngIf="create || update_flag">
              <br>
              <div class="row">
                <div class="col-12 text-right">
                  <button class="btn btn-outline-primary" (click)="backToList()">Back To List</button>
                </div>
              </div>
              <br>

              <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                <mat-step [stepControl]="first" label="Step-1">
                  <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                      <h6>Enter Mobile Number : <span class="text-danger">*</span></h6>
                    </div>

                    <div class="col-3">
                      <input type="number"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                        class="form-control" (change)="onKeyUpPhone(vendorObj['mobile_no'])"
                        [(ngModel)]="vendorObj['mobile_no']" maxlength="10">

                    </div>
                    <div class="col-3"></div>
                  </div>
                  <div class="row mt-3" *ngIf="invalid_phone">
                    <div class="col-3"></div>
                    <div class="col-3"></div>
                    <div class="col-3"><span class="text-danger" *ngIf="invalid_phone">{{invalid_msg}}</span>
                    </div>
                    <div class="col-3"></div>
                  </div>

                  <br><br>
                  <div class="row">
                    <div class="col-12 text-center">
                      <button class="btn btn-outline-danger" (click)="backToList()">Cancel</button> &nbsp;&nbsp;
                      <button *ngIf="create" class="btn btn-outline-primary" (click)="search(stepper1)">Search</button>

                      <button class="btn btn-outline-primary" *ngIf="update_flag" mat-button
                        matStepperNext>Next</button>
                    </div>
                  </div>
                </mat-step>

                <mat-step [stepControl]="second" label="Step-2">

                  <!-- <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Class of Registration : <span class="text-danger">*</span> </h6>
                                        </div>
                                        <div class="col-3"><ng-select [items]="mainService.codeValueTechObj['VMS001']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                placeholder="Select calss" [(ngModel)]="vendorObj['class']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0" [disabled] = isDisabled>
                                            </ng-select></div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Registration Category : <span class="text-danger">*</span> </h6>
                                        </div>
                                        <div class="col-3"><ng-select [items]="mainService.codeValueTechObj['VMS002']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                placeholder="Select Category" [(ngModel)]="vendorObj['category']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0" [disabled] = isDisabled>
                                            </ng-select></div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br> -->

                  <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                    <legend class="w-auto "
                      style="color: rgb(184, 47, 47);display: inline-block; width: 7rem;padding-top: 0.5%;">
                      <h6>BASIC DETAILS</h6>
                    </legend>
                    <br>
                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>Mobile Number: <span class="text-danger">*</span> </h6>
                      </div>
                      <div class="col-3">
                        <input type="number" class="form-control" [(ngModel)]="vendorObj['mobile_no']" disabled>
                      </div>

                      <div class="col-3 text-center">
                        <h6>Vendor Type: <span class="text-danger">*</span> </h6>
                      </div>
                      <div class="col-3">
                        <!-- <ng-select [items]="partyType" bindLabel="value" bindValue="code"
                                                    [multiple]="false" placeholder="Select vendor Type"
                                                    [(ngModel)]="vendorObj['type']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    name="chart_of_account0" [disabled]=isDisabled>
                                                </ng-select> -->
                      </div>
                    </div>
                    <br>

                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>Vendor Name :<span class="text-danger">*</span></h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['vendor_name']">
                      </div>
                      <div class="col-3 text-center">
                        <h6> Email ID:<span class="text-danger">*</span></h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['email']" name="email2343232423"
                          autocomplete="off" (change)="checkEmail(vendorObj['email'])">
                      </div>
                    </div>

                    <div class="row mt-3" *ngIf="invalid_email">
                      <div class="col-3"></div>
                      <div class="col-3"></div>
                      <div class="col-3"></div>
                      <div class="col-3">
                        <span class="text-danger" *ngIf="invalid_email">{{email_msg}}</span>
                      </div>
                    </div>

                    <br>
                    <div class="row">
                      <div class="col-3 text-center">
                        <h6> Address : <span class="text-danger">*</span> </h6>
                      </div>
                      <div class="col-9">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['address']">
                      </div>
                    </div>
                    <br>

                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>City / District: <span class="text-danger">*</span></h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['district']">
                      </div>
                      <div class="col-3 text-center">
                        <h6>State: <span class="text-danger">*</span> </h6>
                      </div>
                      <div class="col-3">
                        <!-- <ng-select
                                                    [multiple]="false"
                                                    placeholder="Select State" [(ngModel)]="vendorObj['state']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0"
                                                    [disabled]=isDisabled>
                                                </ng-select> -->

                      </div>
                    </div>
                    <br>

                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>PAN: <span class="text-danger">*</span> </h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['pan']" maxlength="10"
                          [disabled]=isDisabled>
                      </div>
                      <div class="col-3 text-center">
                        <h6>Date of <span *ngIf="vendorObj['type']=='IND'">Birth</span> <span
                            *ngIf="vendorObj['type']!='IND'">Incorporation</span>:
                        </h6>
                      </div>
                      <div class="col-3">
                        <div *ngIf="vendorObj['type']=='IND'">
                          <input type="date" class="form-control" [(ngModel)]="vendorObj['dob']" [disabled]=isDisabled
                            (change)="validDate(vendorObj['dob'])">
                        </div>
                        <div *ngIf="vendorObj['type']!='IND'">
                          <input type="date" class="form-control" [(ngModel)]="vendorObj['dob']" [disabled]=isDisabled
                            max="{{currDate}}">
                        </div>

                      </div>
                    </div>
                    <br>

                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>GSTIN: <span class="text-danger">*</span></h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['gstin']" [disabled]=isDisabled>
                      </div>
                      <div class="col-3 text-center">
                        <h6>GST Registration Date: </h6>
                      </div>
                      <div class="col-3">
                        <input type="date" class="form-control" [(ngModel)]="vendorObj['gst_reg_date']"
                          [disabled]=isDisabled max="{{currDate}}">
                      </div>
                    </div>
                    <br>
                  </fieldset>
                  <br> <br>

                  <!--------------------------------- Representative Details  --------------------------->

                  <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;padding-top: 0.5%;"
                    *ngIf="vendorObj['type'] !='IND'">
                    <legend class="w-auto " style="color: rgb(184, 47, 47);display: inline-block; width: 13rem;">
                      <h6>REPRESENTATIVE DETAILS</h6>
                    </legend>
                    <br>
                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>Representative Name: </h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['rep_name']">
                      </div>
                      <div class="col-3 text-center">
                        <h6>Designation: </h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['rep_designation']">
                      </div>
                    </div>
                    <br>

                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>Mobile Number :</h6>
                      </div>
                      <div class="col-3">

                        <input type="number" name=""
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          placeholder="" class="form-control" [(ngModel)]="vendorObj['rep_mobile_no']" maxlength="10"
                          (change)="onKeyUpPhone(vendorObj['rep_mobile_no'])">
                        <span class="text-danger" *ngIf="invalid_phone">{{invalid_msg}}</span>
                      </div>
                      <div class="col-3 text-center">
                        <h6> Email ID:<span class="text-danger">*</span></h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['rep_email']"
                          (change)="checkEmail(vendorObj['rep_email'])" name="email" autocomplete="off">
                        <span class="text-danger" *ngIf="invalid_email">{{email_msg}}</span>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3 text-center">
                        <h6> Date of Birth: </h6>
                      </div>
                      <div class="col-3">
                        <input type="date" class="form-control" [(ngModel)]="vendorObj['rep_dob']"
                          (change)="validDate(vendorObj['rep_dob'])">
                      </div>
                      <div class="col-3"></div>
                      <div class="col-3"></div>
                    </div>
                    <br><br>
                  </fieldset>
                  <br> <br>
                  <div class="row">
                    <div class="col-12 text-center">
                      <button mat-button mat-raised-button class="btn btn-outline-danger"
                        matStepperPrevious>Previous</button>
                      &nbsp;
                      <button mat-button mat-raised-button class="btn btn-outline-primary"
                        (click)="next(stepper1)">Next</button>
                      <!--Next-->
                    </div>
                  </div>
                </mat-step>

                <mat-step [stepControl]="third" label="Done">

                  <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                    <legend class="w-auto "
                      style="color: rgb(184, 47, 47);display: inline-block; width: 16rem;padding-top: 0.5%;">
                      <h6>BANK ACCOUNT DETAILS</h6>
                    </legend>
                    <br>
                    <div class="row">
                      <div class="col-3 text-center">
                        <h6> A/C Number: <span class="text-danger">*</span> </h6>
                      </div>
                      <div class="col-3">
                        <input type="password" class="form-control" pattern="[0-9]"
                          [(ngModel)]="vendorObj['account_no']" [disabled]=isDisabled>
                      </div>
                      <div class="col-3 text-center">
                        <h6>Confirm A/C Number: <span class="text-danger">*</span> </h6>
                      </div>
                      <div class="col-3">
                        <input type="number" class="form-control" [(ngModel)]="vendorObj['conform_account_no']"
                          [disabled]=isDisabled>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>Account Holder's Name: <span class="text-danger">*</span></h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['account_holder_name']"
                          [disabled]=isDisabled>
                      </div>
                      <div class="col-3 text-center">
                        <h6>IFSC: <span class="text-danger">*</span> </h6>
                      </div>
                      <div class="col-3"> <input type="text" class="form-control" [(ngModel)]="vendorObj['ifsc']"
                          (change)="fetchBankDtl()" oninput="this.value = this.value.toUpperCase()"
                          [disabled]=isDisabled maxlength="11"><br>
                        <div style="position: relative;top: -1rem;">
                          <span class="text-success" *ngIf="ifs_flag">{{ifs_mes}}</span>
                          <span class="text-danger" *ngIf="ifs_flag==false">{{ifs_mes}}</span>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>Bank Name : </h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['bank_name']" disabled>
                      </div>
                      <div class="col-3 text-center">
                        <h6> Branch Name:</h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['branch_name']" disabled>
                      </div>
                    </div>
                    <br>

                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>Bank Address : </h6>
                      </div>
                      <div class="col-9">
                        <input type="text" class="form-control" [(ngModel)]="vendorObj['bank_address']" disabled>

                      </div>
                    </div>
                    <br><br>
                  </fieldset>
                  <br> <br>

                  <fieldset class="p-2" style="border: 3px solid #e9ecef;border-radius: 7px;">
                    <legend class="w-auto " style="color: rgb(184, 47, 47);display: inline-block; width: 11rem;">
                      <h6>DOCUMENT UPLOADS</h6>
                    </legend>
                    <br>
                    <div class="row">


                      <div class="col-3 text-center">
                        <h6>1. PAN Card: </h6>
                      </div>
                      <div class="col-3">
                        <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader1" class="form-control"
                          style="border-color: black" (change)="onFileUpload($event,file.files,'pan')"
                          accept="application/pdf">
                      </div>
                      <div class="col-3 text-center">
                      </div>
                      <div class="col-3"></div>
                    </div>
                    <br>

                    <div class="row">
                      <div class="col-3 text-center">
                        <h6>2. GSTIN : </h6>
                      </div>
                      <div class="col-3">
                        <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader1" class="form-control"
                          style="border-color: black" (change)="onFileUpload($event,file.files,'gstin')"
                          accept="application/pdf">
                      </div>
                      <div class="col-3 text-center">

                      </div>
                      <div class="col-3">

                      </div>
                    </div>

                    <br><br>
                  </fieldset>
                  <br>

                  <div class="row">
                    <div class="col-3">
                      <h6>Registration date: </h6>
                    </div>
                    <div class="col-3"><input type="date" class="form-control"
                        [(ngModel)]="vendorObj['registration_date']" name="date" [disabled]=isDisabled></div>
                    <div class="col-3">
                      <h6>Valid upto: </h6>
                    </div>
                    <div class="col-3">
                      <input type="date" class="form-control" [(ngModel)]="vendorObj['valid_upto_user']" name="date"
                        [disabled]=isDisabled>
                    </div>
                  </div>
                  <br><br>
                  <div class="row">
                    <div class="col-12 text-center">
                      <button mat-button mat-raised-button class="btn btn-outline-danger"
                        matStepperPrevious>Previous</button>
                      &nbsp;
                      <button *ngIf="create" mat-button mat-raised-button class="btn btn-outline-primary"
                        (click)="sumbitVendor()">Submit</button>

                      <button *ngIf="update_flag" mat-button mat-raised-button class="btn btn-outline-primary"
                        (click)="update()">Update</button>
                    </div>
                  </div>

                </mat-step>
              </mat-horizontal-stepper>


            </div>

            <div class="tab-3 active" *ngIf="view_details">
              <br>
              <div class="row">
                <div class="col-12 text-right">
                  <button class="btn btn-outline-primary" (click)="backToList()">Go To List</button>
                </div>

              </div>


              <br>
              <mat-horizontal-stepper #stepper>
                <mat-step [stepControl]="first" label="Company Details">
                  <br>
                  <div class="row">
                    <div class="col-10 text-right">
                      <h6>Date &nbsp;:&nbsp;</h6>
                    </div>
                    <div class="col-2 text-right">
                      <p></p>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3">
                      <h6>Name Firm/ Organiztion&nbsp;:&nbsp;<span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['org_name']}}
                    </div>
                    <div class="col-3">
                      <h6>Type Of Organization&nbsp;:&nbsp; <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['org_type']}}
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3">
                      <h6>Full Address&nbsp;:&nbsp; <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-9">
                      {{venderInfo['address']}}
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3 ">
                      <h6>City&nbsp;:&nbsp;<span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['city']}}
                    </div>
                    <div class="col-3 ">
                      <h6>State&nbsp;:&nbsp; <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['state']}}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-3">
                      <h6>Mobile Number&nbsp;:&nbsp;<span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['mobile_no']}}
                    </div>
                    <div class="col-3">
                      <h6>Phone Number&nbsp;:&nbsp;</h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['phone_no']}}
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3">
                      <h6>Email Id&nbsp;:&nbsp;<span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['email']}}
                    </div>
                    <div class="col-3">
                      <h6>Website(If Any)&nbsp;:&nbsp;</h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['website']}}
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3">
                      <h6>GSTIN&nbsp;:&nbsp;<span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['gst_no']}}
                    </div>
                    <div class="col-3">
                      <h6>Date Of Registration of GST&nbsp;:&nbsp; <span class="text-danger">*</span>
                      </h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['gst_reg_date']}}
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3">
                      <h6>PAN of Propriter/Partner&nbsp;:&nbsp;<span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['partner_pan_no']}}
                    </div>

                  </div>
                  <br>
                  <div class="row">
                    <div class="col-5">
                      <hr class="hrline">
                    </div>
                    <div class="col-2 text-center">
                      <h6 id="h" style="color: brown; min-height: 20px;">
                        Representative Details
                      </h6>
                    </div>
                    <div class="col-5">
                      <hr class="hrline">
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3">
                      <h6>Reresentative Name&nbsp;:&nbsp; <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['repre_name']}}
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3">
                      <h6>Mobile Number &nbsp;:&nbsp; <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['repre_mobile']}}
                    </div>
                    <div class="col-3">
                      <h6>Email Id &nbsp;:&nbsp;<span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['repre_email']}}
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-5">
                      <hr class="hrline">
                    </div>
                    <div class="col-2 text-center">
                      <h6 id="h" style="color: brown; min-height: 20px;">
                        Ownership / Partnership
                      </h6>
                    </div>
                    <div class="col-5">
                      <hr class="hrline">
                    </div>
                  </div>
                  <br>
                  <div class="row">

                    <div class="col-5">
                      <h6>
                        Ownership / Partnership deep / Memorandom on company&nbsp;:&nbsp;<span
                          class="text-danger">*</span>
                      </h6>
                    </div>
                    <div class="col-5">
                      <button class="btn btn-outline-success" (click)="viewDoc('Owener_pert_doc')">View</button>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-5">
                      <hr class="hrline">
                    </div>
                    <div class="col-2 text-center">
                      <h6 id="h" style="color: brown; min-height: 20px;">
                        Registration Certificate
                      </h6>
                    </div>
                    <div class="col-5">
                      <hr class="hrline">
                    </div>
                  </div>
                  <br>
                  <div class="row text-center">
                    <div class="col-12">
                      <h6>Registration Certificate / Memorandom of Company (If Partnership firm/
                        Company) <span class="text-danger">*</span></h6>
                    </div>

                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3">
                      <h6>Registration Number:<span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      <h6>{{venderInfo['reg_cirt_no']}}</h6>
                    </div>
                    <div class="col-3">
                      <h6>Valid Till:<span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      <h6>{{venderInfo['reg_cirt_valid_to']}}</h6>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3">
                      <h6>View File</h6>
                    </div>
                    <div class="col-3">
                      <button class="btn btn-outline-success" (click)="viewDoc('Registration_doc')">view</button>
                    </div>
                    <div class="col-1">
                      <!-- <button *ngIf="Registration_doc_upload_flag" (click)="upload1('Registration_doc')"
                                                        class="btn btn-primary">
                                                        Upload</button>
                                                      <button *ngIf="Registration_doc_View_flag" (click)="viewDoc(venderInfo['reg_cirt_upload_id'])"
                                                        class="btn btn-primary">
                                                        View</button> -->
                    </div>
                  </div>

                  <br>
                  <div class="row">
                    <div class="col-4">
                      <hr class="hrline">
                    </div>
                    <div class="col-4 text-center">
                      <h6 id="h" style="color: brown; min-height: 20px;">
                        Icome Tax Retrun / Balence Sheet
                      </h6>
                    </div>
                    <div class="col-4">
                      <hr class="hrline">
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="card text-center">
                      <div class="col-12 text-center">
                        <h5>Icome Tax Retrun / Balence Sheet(Last Year)&nbsp;:&nbsp;<span class="text-danger">*</span>
                        </h5>
                      </div>
                    </div>
                    <br>
                    <table class="table">
                      <thead>
                        <tr class="bg-primary text-white text-center">
                          <th scope="col">Sr. No</th>
                          <th scope="col">Assement Year</th>
                          <th scope="col">Income</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-center" *ngFor="let v of venderAccountInfo;let i=index">
                          <th scope="col">{{i+1}}</th>
                          <th scope="col">{{v.fin_year}}</th>
                          <th scope="col">{{v.income}}</th>
                          <th scope="col">{{v.turnover}}</th>
                          <th scope="col">
                            <button class="btn btn-outline-primary" (click)="viewDoc('Pl_doc'+i)">View</button>
                          </th>


                        </tr>

                      </tbody>
                    </table>



                  </div>

                  <br>
                  <div class="row">
                    <div class="col-5">
                      <hr class="hrline">
                    </div>
                    <div class="col-2 text-center">
                      <h6 id="h" style="color: brown; min-height: 20px;">
                        Registration Certificate
                      </h6>
                    </div>
                    <div class="col-5">
                      <hr class="hrline">
                    </div>
                  </div>
                  <br>
                  <div class="row text-center">
                    <div class="col-11">
                      <h6>
                        Solvency certificate ( hasiyat prman patrya) issued by district magistrate
                        <span class="text-danger">*</span>
                      </h6>
                    </div>

                  </div>
                  <br>

                  <div class="row">
                    <div class="col-3">
                      <h6>Validity form date : <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['sol_from']}}
                    </div>
                    <div class="col-3">
                      <h6>Validity to date : <span class="text-danger">*</span></h6>
                    </div>
                    <div class="col-3">
                      {{venderInfo['sol_valid_to']}}
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-3">
                      <h6>View Doc</h6>
                    </div>
                    <div class="col-3">
                      <button class="btn btn-outline-success" (click)="viewDoc('solvency_doc')">View</button>
                    </div>
                    <!-- <div class="col-1">
                                                      <button *ngIf="solvency_doc_upload_flag" (click)="upload1('solvency_doc')"
                                                        class="btn btn-primary">
                                                        Upload</button>
                                                      <button *ngIf="solvency_doc_View_flag" (click)="viewDoc(venderInfo['sol_upload_id'])"
                                                        class="btn btn-primary">
                                                        View</button>
                                                    </div> -->
                  </div>
                  <br>
                  <div class="row" *ngIf="electrical_view">
                    <div class="col-4">
                      <hr class="hrline">
                    </div>

                    <div class="col-4 text-center">
                      <h6 id="h" style="color: brown; min-height: 20px;">
                        Registration for electrical works :
                      </h6>
                    </div>
                    <div class="col-4">
                      <hr class="hrline">
                    </div>
                    <!-- </div> -->
                    <br>


                    <div class="row">
                      <div class="col text-center">
                        <h6>Registration of Electrical Works <span class="text-danger">*</span></h6>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col text-center">
                        <h6>(Approved liccence of category "A" issued by director electrical safty up
                          government)
                        </h6>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>Name of firm/Company : <span class="text-danger">*</span></h6>
                      </div>
                      <div class="col-3">
                        {{venderInfo['electrical_cert_on_firm_name']}}
                      </div>
                      <div class="col-3">
                        <h6>Licence Number<span class="text-danger">*</span></h6>
                      </div>
                      <div class="col-3">
                        {{venderInfo['electrical_cert_on_lic_no']}}
                      </div>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>Issue date : <span class="text-danger">*</span></h6>
                      </div>
                      <div class="col-3">
                        {{venderInfo['electrical_cert_issue_date']}}
                      </div>
                      <div class="col-3">
                        <h6>Valid upto : <span class="text-danger">*</span></h6>
                      </div>
                      <div class="col-3">
                        {{venderInfo['electrical_cert_valid_upto']}}
                      </div>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>View File : </h6>
                      </div>
                      <div class="col-3">
                        <button class="btn btn-outline-success" (click)="viewDoc('eletrical_work_doc')">View</button>
                        >

                      </div>
                      <!-- <div class="col-1">
                                                          <button *ngIf="eletrical_work_doc_upload_flag" (click)="upload1('eletrical_work_doc')"
                                                            class="btn btn-primary">
                                                            Upload</button>
                                                          <button *ngIf="eletrical_work_doc_View_flag"
                                                            (click)="viewDoc(venderInfo['electrical_cert_upload_id'])" class="btn btn-primary">
                                                            View</button>
                                                        </div> -->
                    </div>
                    <br>
                    <br>
                  </div>

                  <br>
                  <div class="row  text-center">
                    <div class="col-12">

                      <button class="btn btn-primary" (click)="saveVenderInfo(stepper)">
                        Next</button>
                    </div>
                  </div>



                </mat-step>

                <mat-step label="Staff Details">
                  <br>
                  <div class="row">
                    <div class="col-4">
                      <hr class="hrline">
                    </div>
                    <div class="col-4 text-center">
                      <h6 id="h" style="color: brown; min-height: 20px;">
                        EMPLOYEE'S DETAILS:
                      </h6>
                    </div>
                    <div class="col-4">
                      <hr class="hrline">
                    </div>
                  </div>
                  <br>
                  <div class="row ">
                    <div class="col-11">
                      <h6>EMPLOYEE'S DETAILS : </h6>
                    </div>
                    <div class="col-1">

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>Staff details (employed at the time of registration)</h6>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <table class="table table-striped table-hover">
                        <thead>
                          <tr class="bg-primary text-white text-center">
                            <th scope="col">Sr. No</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Designation </th>
                            <th scope="col">Work Since</th>
                            <th scope="col">Passing Year</th>
                            <th scope="col">Total Experience</th>
                            <th scope="col"> Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let v of vender_emp_info_table let o=index" class="text-center">
                            <th scope="row">{{o+1}}</th>
                            <th>{{v.emp_name}}</th>
                            <th>{{v.designation }}</th>
                            <th>{{v.work_from | date }}</th>
                            <th>{{v.passing_yr}} </th>
                            <th>
                              {{v.road_work_experience+v.other_work_experience+v.building_work_experience}}
                            </th>
                            <th> <button (click)="OpenUpdateEmpData(v,v.emp_id)" class="btn btn-primary">
                                View</button>

                          </tr>


                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br>

                  <div class="modal fade" id="EMPLOYEE">
                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document" style="max-width: 80%;">
                      <div class="modal-content">


                        <div class="modal-header">
                          <h4 class="modal-title">EMPLOYEE'S DETAILS</h4>
                          <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div class="modal-body">
                          <div class="card">
                            <div class="card-body">
                              <br>
                              <div class="row">
                                <div class="col-4">
                                  <hr class="hrline">
                                </div>
                                <div class="col-4 text-center">
                                  <h6 id="h" style="color: brown; min-height: 20px;">
                                    EMPLOYEE'S DETAILS:
                                  </h6>
                                </div>
                                <div class="col-4">
                                  <hr c lass="hrline">
                                </div>
                              </div>
                              <br>
                              <div class="div" *ngIf="vender_emp_info">
                                <div class="row">
                                  <div class="col-3">
                                    <h6>Name:<span class="text-danger">*</span></h6>

                                  </div>
                                  <div class="col-3"><input type="text" class="form-control" placeholder=""
                                      [(ngModel)]="vender_emp_info['emp_name']" disabled /></div>
                                  <div class="col-3">
                                    <h6>Designation:<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-3"><input type="text" class="form-control" placeholder=""
                                      [(ngModel)]="vender_emp_info['designation']" disabled /></div>
                                </div>
                                <br>
                                <div class="row">
                                  <div class="col-3">
                                    <h6>
                                      Working Since
                                      : <span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-3"><input type="date" class="form-control" placeholder=""
                                      [(ngModel)]="vender_emp_info['work_from']" disabled /></div>
                                  <div class="col-3">
                                    <h6>Address:<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-3"><input type="text" class="form-control" placeholder=""
                                      [(ngModel)]="vender_emp_info['emp_address']" disabled /></div>
                                </div>
                                <br>
                                <div class="row">
                                  <div class="col-3">
                                    <h6>Highest Qualification: <span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-3"><input type="text" class="form-control" placeholder=""
                                      [(ngModel)]="vender_emp_info['highest_qlf']" disabled /></div>
                                  <div class="col-3">
                                    <h6>Passing Year:<span class="text-danger">*</span></h6>
                                  </div>
                                  <div class="col-3"><input type="number" class="form-control" placeholder=""
                                      [(ngModel)]="vender_emp_info['passing_yr']" disabled /></div>
                                </div>
                              </div>
                              <br>

                              <br>
                              <div class="row">
                                <div class="col-4">
                                  <hr class="hrline">
                                </div>
                                <div class="col-4 text-center">
                                  <h6 id="h" style="color: brown; min-height: 20px;">
                                    EMPLOYEE'S EDUCATIONAL CERTIFICATE:
                                  </h6>
                                </div>
                                <div class="col-4">
                                  <hr class="hrline">
                                </div>
                              </div>
                              <br>
                              <div class="row">
                                <div class="col-3">Degree</div>
                                <div class="col-8"></div>
                                <div class="col-1">
                                  <!-- <button  class="btn btn-primary"><i
                                                                      class="fa fa-plus"></i>
                                                                    Add</button> -->
                                </div>
                              </div>
                              <table class="table table-striped table-hover">
                                <thead>
                                  <tr class="bg-primary text-white text-center">
                                    <th scope="col">Sr. No</th>
                                    <th scope="col">Degree</th>
                                    <th scope="col">Passing Year</th>
                                    <th scope="col">Roll Number</th>
                                    <th scope="col"> University / Institute</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let obj1 of degree_doc;let l=index">
                                    <th scope="row">{{l+1}}</th>
                                    <th class="text-center"> <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="degree_doc[l]['course_name']" disabled />
                                    </th>
                                    <th class="text-center"> <input type="number" class="form-control" placeholder=""
                                        [(ngModel)]="degree_doc[l]['passing_year']" disabled />
                                    </th>
                                    <th class="text-center"> <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="degree_doc[l]['roll_no']" disabled />
                                    </th>
                                    <th class="text-center"><input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="degree_doc[l]['institute_name']" disabled />
                                    </th>
                                    <th class="text-center">
                                      <button class="btn btn-outline-primary"
                                        (click)="viewDoc('degree_doc'+l)">View</button>
                                    </th>

                                  </tr>
                                </tbody>
                              </table>
                              <br>

                              <div class="row">
                                <div class="col-3">Diploma / Certifications</div>
                                <div class="col-8"></div>
                                <div class="col-1">
                                  <!-- <button  class="btn btn-primary"><i
                                                                      class="fa fa-plus"></i>
                                                                    Add</button> -->
                                </div>
                              </div>
                              <table class="table table-striped table-hover">
                                <thead>
                                  <tr class="bg-primary text-white text-center">
                                    <th scope="col">Sr. No</th>
                                    <th scope="col">Diploma </th>
                                    <th scope="col">Passing Year</th>
                                    <th scope="col">Roll number</th>
                                    <th scope="col">University / Institute</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let obj1 of diploma_doc;let i=index">
                                    <th scope="row">{{i+1}}</th>
                                    <th class="text-center"> <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="diploma_doc[i]['course_name']" disabled />
                                    </th>
                                    <th class="text-center"> <input type="number" class="form-control" placeholder=""
                                        [(ngModel)]="diploma_doc[i]['passing_year']" disabled />
                                    </th>
                                    <th class="text-center"> <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="diploma_doc[i]['roll_no']" disabled />
                                    </th>
                                    <th class="text-center"><input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="diploma_doc[i]['institute_name']" disabled />
                                    </th>

                                    <th class="text-center">
                                      <button class="btn btn-outline-primary" (click)="viewDoc('diploma_doc'+i)"> View
                                      </button>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                              <br>

                              <div class="row">
                                <div class="col-4">
                                  <hr class="hrline">
                                </div>
                                <div class="col-4 text-center">
                                  <h6 id="h" style="color: brown; min-height: 20px;">
                                    EMPLOYEE'S EXPERIENCE
                                  </h6>
                                </div>
                                <div class="col-4">
                                  <hr class="hrline">
                                </div>
                              </div>
                              <br>
                              <div class="row">
                                <table class="table table-bordered" *ngIf="vender_emp_info">
                                  <thead>
                                    <tr class="text-center">

                                      <th scope="col">Experience Type</th>
                                      <th scope="col">Year of Experience</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>

                                      <td>Road Works</td>
                                      <td *ngIf="vender_emp_info['road_work_experience_falg']==true else  dis_exp">
                                        <input type="number" class="form-control" placeholder=""
                                          [(ngModel)]="vender_emp_info['road_work_experience']" disabled />
                                      </td>

                                      <ng-template #dis_exp>
                                        <td>
                                          <input type="number" class="form-control" placeholder=""
                                            [(ngModel)]="vender_emp_info['road_work_experience']" disabled />
                                        </td>
                                      </ng-template>


                                    </tr>
                                    <tr>
                                      <td>Building Works</td>
                                      <td
                                        *ngIf="vender_emp_info['building_work_experience_flag']==true else  dis_build_exp">
                                        <input type="number" class="form-control" placeholder=""
                                          [(ngModel)]="vender_emp_info['building_work_experience']" disabled />
                                      </td>
                                      <ng-template #dis_build_exp>
                                        <td>
                                          <input type="number" class="form-control" placeholder=""
                                            [(ngModel)]="vender_emp_info['building_work_experience']" disabled />
                                        </td>
                                      </ng-template>
                                    </tr>
                                    <tr>

                                      <td>Others Works</td>
                                      <td
                                        *ngIf="vender_emp_info['other_work_experience_flag']==true else  dis_others_exp">
                                        <input type="number" class="form-control" placeholder=""
                                          [(ngModel)]="vender_emp_info['other_work_experience']" disabled />
                                      </td>
                                      <ng-template #dis_others_exp>
                                        <td>
                                          <input type="number" class="form-control" placeholder=""
                                            [(ngModel)]="vender_emp_info['other_work_experience']" disabled />
                                        </td>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>


                            <div class="modal-footer text-center">

                              <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>

                              <br>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row  text-center">
                    <div class="col-12">
                      <button matStepperPrevious class="btn btn-danger">
                        Previous </button> &nbsp;
                      <button matStepperNext class="btn btn-primary">
                        Next</button>
                    </div>
                  </div>
                </mat-step>

                <mat-step label="Director Details">
                  <br>
                  <div class="row">
                    <div class="col-4">
                      <hr class="hrline">
                    </div>
                    <div class="col-4 text-center">
                      <h6 id="h" style="color: brown; min-height: 20px;">
                        PARTNERS/ PROPERITER/DIRECTORS DETAILS
                      </h6>
                    </div>
                    <div class="col-4">
                      <hr class="hrline">
                    </div>
                  </div>
                  <br>
                  <div class="row ">
                    <div class="col-11">
                      <h6>PARTNERS/ PROPERITER/DIRECTORS DETAILS : </h6>
                    </div>
                    <div class="col-1">

                    </div>
                  </div>


                  <div class="row">
                    <div class="col-12">
                      <table class="table table-striped table-hover">
                        <thead>
                          <tr class="bg-primary text-white text-center">
                            <th scope="col">Sr. No</th>
                            <th scope="col">Director Name</th>
                            <th scope="col">Mobile No </th>
                            <th scope="col">Address </th>
                            <th scope="col">PAN </th>
                            <th scope="col">Adhar Card</th>
                            <th scope="col"> Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let f of vender_director_info_table let d=index" class="text-center">
                            <th scope="row">{{d+1}}</th>
                            <th>{{f.dir_name}}</th>
                            <th>{{f.dir_mobile_no }}</th>
                            <th>{{f.dir_address }}</th>
                            <th>{{f.dir_pan_no}} </th>
                            <th>{{f.dir_adhar_no }} </th>
                            <th>
                              <button (click)="updateDirOpen(f,f.director_id) ; viewDoc('dir_image')"
                                class="btn btn-primary">

                                View</button>

                            </th>
                          </tr>


                        </tbody>
                      </table>
                    </div>
                  </div>
                  <br>

                  <div class="modal fade" id="ACTHIER">
                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document" style="max-width: 80%;">
                      <div class="modal-content">


                        <div class="modal-header">
                          <h4 class="modal-title">PARTNERS/ PROPERITER/DIRECTORS DETAILS</h4>
                          <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>

                        <div class="modal-body">

                          <br>
                          <div class="container border p-5" *ngIf="vender_director_info">


                            <div class="d-flex">
                              <div style="margin-right: 20%;">
                                <div class="row">
                                  <div class="col-6 ">
                                    <h6>Name of Propritr / Partner : </h6>
                                  </div>
                                  <div class="col-6" style="padding-left: 16px;"><input type="text" class="form-control"
                                      placeholder="" [(ngModel)]="vender_director_info['dir_name']" disabled />
                                  </div>
                                </div><br>
                                <div class="row">
                                  <div class="col-6">
                                    <h6>Phone Number : </h6>
                                  </div>
                                  <div class="col-6" style="padding-left: 16px;">
                                    <input type="number" class="form-control" placeholder=""
                                      [(ngModel)]="vender_director_info['dir_mobile_no']" maxlength="10" required
                                      disabled />
                                  </div>
                                </div><br>
                                <div class="row">
                                  <div class="col-6">
                                    <h6>Email Id : </h6>
                                  </div>
                                  <div class="col-6" style="padding-left: 16px;"><input type="email"
                                      class="form-control" placeholder=""
                                      [(ngModel)]="vender_director_info['dir_email']" disabled />
                                  </div>
                                </div><br>
                              </div>
                              <div style="width:100px; height:60px;  text-align:center;">
                                <img [src]="imgURL" class="img-thumbnail" max-height="150px" min-heigth="150px"
                                  max-width="150px" min-width="150px" style="border: 2px solid grey;">


                              </div>
                            </div><br><br>
                            <div class="row">
                              <div class="col-3">
                                <h6> Address :</h6>
                              </div>
                              <div class="col-6">
                                <input type="text" class="form-control" placeholder=""
                                  [(ngModel)]="vender_director_info['dir_address']" disabled />
                              </div>
                              <div class="col-3">

                              </div>
                            </div><br>
                            <div class="row">
                              <div class="col-3">
                                <h6>Name on PAN</h6>
                              </div>
                              <div class="col-3">
                                <input type="text" class="form-control" placeholder=""
                                  [(ngModel)]="vender_director_info['dir_name_on_pan']" disabled />
                              </div>
                              <div class="col-3">
                                <h6>Number on PAN</h6>
                              </div>
                              <div class="col-3">
                                <input type="text" class="form-control" placeholder=""
                                  [(ngModel)]="vender_director_info['dir_pan_no']" disabled />
                              </div>
                            </div>
                            <br>
                            <div class="row">
                              <div class="col-3">
                                <h6>View Document</h6>
                              </div>
                              <div class="col-3">
                                <button class="btn btn-outline-primary" (click)="viewDoc('dir_pan_doc')">View</button>

                              </div>
                              <div class="col-3">

                              </div>

                            </div>
                            <br>
                            <div class="row">
                              <div class="col-3">
                                <h6>D.O.B. on Addhar:</h6>
                              </div>
                              <div class="col-3">
                                <input type="date" class="form-control" placeholder=""
                                  [(ngModel)]="vender_director_info['dir_dob']" disabled />
                              </div>
                              <div class="col-3">
                                <h6>Number on Addhar:</h6>
                              </div>
                              <div class="col-3">
                                <input type="number" class="form-control" placeholder=""
                                  [(ngModel)]="vender_director_info['dir_adhar_no']" disabled />
                              </div>
                            </div>
                            <br>
                            <div class="row">
                              <div class="col-3">
                                <h6>View Document</h6>
                              </div>
                              <div class="col-3">
                                <button class="btn btn-outline-primary" (click)="viewDoc('dir_adhar_doc')">View</button>

                              </div>
                              <div class="col-3">

                              </div>

                            </div>

                            <br>
                            <div class="row">
                              <div class="col-12 text-center">
                                <h6>
                                  CHARACTER CERTIFICATE ISSUED BY DISTRICT MAGISTRATE*(For
                                  Proprietor/Directors)
                                </h6>
                              </div>
                            </div><br>
                            <div class="row">
                              <div class="col-4">Name of proprietor/ directors:</div>
                              <div class="col-3"><input type="text" class="form-control" placeholder=""
                                  [(ngModel)]="vender_director_info['dir_name_on_charecter_cirt']" disabled /></div>
                              <div class="col-2">Valid Till:</div>
                              <div class="col-3"><input type="date" class="form-control" placeholder=""
                                  [(ngModel)]="vender_director_info['dir_charecter_cirt_vailid_till']" disabled /></div>
                            </div><br>
                            <div class="row">
                              <div class="col-3">
                                View Document :
                              </div>
                              <div class="col-3">
                                <button class="btn btn-outline-primary">View</button>

                              </div>
                              <div class="col-1">

                              </div>
                            </div>
                            <br>
                            <div class="row">
                              <div class="col-11">FAMILY DETAILS OF PROPRITER/ PARTNER/ DIRECTOR</div>
                              <div class="col-1">

                              </div>

                              <br>
                              <br>
                              <div class="container border p-3">

                                <table class="table table-striped table-hover">
                                  <thead>
                                    <tr class="bg-primary text-white text-center">
                                      <th scope="col">Sr. No</th>
                                      <th scope="col">Name of person </th>
                                      <th scope="col">Relation to the propriter/ partner/ director
                                      </th>
                                      <th scope="col">Adhar Number</th>
                                      <th scope="col">PAN</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let v2 of familyDD; let p=index">
                                      <th scope="row">{{p+1}}</th>
                                      <td> <input type="text" class="form-control" placeholder=""
                                          [(ngModel)]="familyDD[p]['per_name']" disabled />
                                      </td>
                                      <td> <input type="text" class="form-control" placeholder=""
                                          [(ngModel)]="familyDD[p]['type_of_relation']" disabled />
                                      </td>
                                      <td> <input type="number" class="form-control" placeholder=""
                                          [(ngModel)]="familyDD[p]['adhar_no']" disabled />
                                      </td>
                                      <td><input type="text" class="form-control" placeholder=""
                                          [(ngModel)]="familyDD[p]['pan_no']" disabled />
                                      </td>

                                      <td>

                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br>

                              </div>
                            </div>
                            <br>
                            <div class="row">
                              <div class="col-11">NOMINEE DETAILS OF PROPRITER/ PARTNER</div>
                              <div class="col-1">

                              </div>

                              <br>
                              <br>
                              <div class="container border p-3">

                                <table class="table table-striped table-hover">
                                  <thead>
                                    <tr class="bg-primary text-white text-center">
                                      <th scope="col">Sr. No</th>
                                      <th scope="col">NAME </th>
                                      <th scope="col">Nominee Share (%)</th>
                                      <th scope="col"> Address</th>
                                      <th scope="col">Relation Top Nominee</th>
                                      <th scope="col">Adhar Number</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let dir of nomiP ; let j=index">
                                      <th scope="row">{{j+1}}</th>
                                      <td> <input type="text" class="form-control" placeholder=""
                                          [(ngModel)]="nomiP[j]['nom_name']" disabled />
                                      </td>
                                      <td> <input type="number" class="form-control" placeholder=""
                                          [(ngModel)]="nomiP[j]['nom_share']" disabled />
                                      </td>
                                      <td> <input type="text" class="form-control" placeholder=""
                                          [(ngModel)]="nomiP[j]['nom_address']" disabled />
                                      </td>
                                      <td><input type="text" class="form-control" placeholder=""
                                          [(ngModel)]="nomiP[j]['relation_to_nom']" disabled />
                                      </td>
                                      <td><input type="number" class="form-control" placeholder=""
                                          [(ngModel)]="nomiP[j]['nom_adhar']" disabled />
                                      </td>

                                      <td>

                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="modal-footer text-center">


                          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">CLOSE</button>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="row  text-center">
                    <div class="col-12">
                      <button matStepperPrevious class="btn btn-danger">
                        Previous </button> &nbsp;
                      <button matStepperNext class="btn btn-primary">
                        Next</button>
                    </div>
                  </div>
                </mat-step>

                <mat-step label="Details of Machinery">
                  <br>

                  <div class="row">
                    <div class="col-4">
                      <hr class="hrline">
                    </div>
                    <div class="col-4 text-center">
                      <h6 id="h" style="color: brown; min-height: 20px;">
                        MACHINERY AND EXPERIENCE
                      </h6>
                    </div>
                    <div class="col-4">
                      <hr class="hrline">
                    </div>
                  </div>

                  <br>
                  <div class="row">
                    <div class="col-11">DETAILS OF MACHINERY / EQUIPMENTS*</div>
                    <div class="col-1">

                    </div>
                  </div>
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr class="bg-primary text-white text-center">
                        <th scope="col">Sr. No</th>
                        <th scope="col">Machine / equipments </th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Fitness Upto</th>
                        <th scope="col">Regisration Number</th>
                        <th scope="col">Date Of Purchase</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let v2 of machine; let i=index">
                        <th scope="row">{{i+1}}</th>
                        <td> <input type="text" class="form-control" placeholder="" [(ngModel)]="machine[i]['machine']"
                            disabled />
                        </td>
                        <td> <input type="number" class="form-control" placeholder=""
                            [(ngModel)]="machine[i]['quantity']" disabled />
                        </td>
                        <td> <input type="date" class="form-control" placeholder=""
                            [(ngModel)]="machine[i]['fintness_upto']" disabled />
                        </td>
                        <td><input type="text" class="form-control" placeholder="" [(ngModel)]="machine[i]['reg_no']"
                            disabled />
                        </td>
                        <td> <input type="date" class="form-control" placeholder=""
                            [(ngModel)]="machine[i]['date_of_purchase']" disabled />
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <br>
                  <br>
                  <div class="row">
                    <div class="col-11">DETAILS OF CONSTRUCTION/ DEVELOPMENTS WORKS COMPLETED IN LAST
                      FOUR YEAR*
                    </div>
                    <div class="col-1">

                    </div>
                  </div>
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr class="bg-primary text-white text-center">
                        <th scope="col">Sr. No</th>
                        <th scope="col">Department </th>
                        <th scope="col">Detail of work</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Date of start</th>
                        <th scope="col">Date OF Completion</th>
                        <th scope="col">Action</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let v3 of completion;let j=index">
                        <th scope="row">{{j+1}}</th>
                        <td> <input type="text" class="form-control" placeholder=""
                            [(ngModel)]="completion[j]['department']" disabled />
                        </td>
                        <td> <input type="text" class="form-control" placeholder=""
                            [(ngModel)]="completion[j]['detail_of_work']" disabled />
                        </td>
                        <td> <input type="number" class="form-control" placeholder=""
                            [(ngModel)]="completion[j]['amount']" disabled />
                        </td>
                        <td><input type="date" class="form-control" placeholder=""
                            [(ngModel)]="completion[j]['date_of_start']" disabled />
                        </td>
                        <td> <input type="date" class="form-control" placeholder=""
                            [(ngModel)]="completion[j]['date_of_comp']" disabled />
                        </td>

                        <td><button (click)="viewDoc('completion_doc'+j)" class="btn btn-outline-primary"> View
                          </button></td>
                      </tr>
                    </tbody>
                  </table>
                  <br>
                  <div class="row">
                    <div class="col-11">EXPERIENCE CERTIFICATE (GOVERNMENT DEPARTMENT/ GOVERNMENT
                      INSTITUTION/ PSU)
                    </div>
                    <div class="col-1">

                    </div>
                  </div>
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr class="bg-primary text-white text-center">
                        <th scope="col">Sr. No</th>
                        <th scope="col">Department </th>
                        <th scope="col">Detail of work</th>
                        <th scope="col">AMOUNT</th>
                        <th scope="col"> Date of Start</th>
                        <th scope="col">Date of completion</th>

                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let v4 of ongoing;let k=index">
                        <th scope="row">{{k+1}}</th>
                        <td> <input type="text" class="form-control" placeholder=""
                            [(ngModel)]="ongoing[k]['department']" disabled />
                        </td>
                        <td> <input type="text" class="form-control" placeholder=""
                            [(ngModel)]="ongoing[k]['detail_of_work']" disabled />
                        </td>
                        <td> <input type="number" class="form-control" placeholder="" [(ngModel)]="ongoing[k]['amount']"
                            disabled />
                        </td>
                        <td><input type="date" class="form-control" placeholder=""
                            [(ngModel)]="ongoing[k]['date_of_start']" disabled />
                        </td>
                        <td> <input type="date" class="form-control" placeholder=""
                            [(ngModel)]="ongoing[k]['date_of_comp']" disabled />
                        </td>

                        <td><button (click)="viewDoc('ongoing_doc'+k)" class="btn btn-outline-primary"> View
                          </button></td>
                      </tr>
                    </tbody>
                  </table>
                  <br>

                  <br>
                  <div class="row">
                    <div class="col-11">DETAIL OF WORKS IN PROGRESS</div>
                    <div class="col-1">

                    </div>
                  </div>
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr class="bg-primary text-white text-center">
                        <th scope="col">Sr. No</th>
                        <th scope="col">Department </th>
                        <th scope="col">Detail of work</th>
                        <th scope="col">AMOUNT</th>
                        <th scope="col">Executed Work Amount</th>
                        <th scope="col">Date To</th>

                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let obj1 of progress;let l=index">
                        <th scope="row">{{l+1}}</th>
                        <td> <input type="text" class="form-control" placeholder=""
                            [(ngModel)]="progress[l]['department']" disabled />
                        </td>
                        <td> <input type="text" class="form-control" placeholder=""
                            [(ngModel)]="progress[l]['detail_of_work']" disabled />
                        </td>
                        <td> <input type="number" class="form-control" placeholder=""
                            [(ngModel)]="progress[l]['amount']" disabled />
                        </td>
                        <td><input type="number" class="form-control" placeholder=""
                            [(ngModel)]="progress[l]['exe_work_amount']" disabled />
                        </td>
                        <td> <input type="date" class="form-control" placeholder=""
                            [(ngModel)]="progress[l]['date_of_comp']" disabled />
                        </td>

                        <td><button (click)="viewDoc('progress_doc'+l)" class="btn btn-outline-primary">View
                          </button></td>
                      </tr>
                    </tbody>
                  </table>
                  <br>
                  <div class="row  text-center">
                    <div class="col-12">
                      <button matStepperPrevious class="btn btn-danger">
                        Previous </button> &nbsp;
                      <button matStepperNext class="btn btn-primary">
                        Next</button>
                    </div>
                  </div>
                </mat-step>

                <mat-step label="Bank Details">
                  <br>
                  <div class="div" *ngIf="bank_details">

                    <div class="row">
                      <div class="col-4">
                        <hr class="hrline">
                      </div>
                      <div class="col-4 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px;">
                          BANK DETAILS
                        </h6>
                      </div>
                      <div class="col-4">
                        <hr class="hrline">
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>Name of Bank</h6>
                      </div>
                      <div class="col-3">
                        {{bank_details['bank_name']}}
                      </div>
                      <div class="col-3">
                        <h6>Bank Account Number</h6>
                      </div>
                      <div class="col-3">
                        <input type="number" class="form-control" placeholder=""
                          [(ngModel)]="bank_details['account_no']" disabled />
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>IFS Code</h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" placeholder="" [(ngModel)]="bank_details['ifs_code']"
                          disabled />
                      </div>
                      <div class="col-3">
                        <h6>Branch Name</h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" placeholder="" [(ngModel)]="bank_details['branch_name']"
                          disabled />
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6> Account Holder's Name</h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" placeholder=""
                          [(ngModel)]="bank_details['account_holder_name']" disabled />
                      </div>
                      <div class="col-3">
                        <h6>City</h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" placeholder="" [(ngModel)]="bank_details['city']"
                          disabled />
                      </div>
                    </div>
                    <br>

                    <div class="row">
                      <div class="col-4">
                        <hr class="hrline">
                      </div>
                      <div class="col-4 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px;">
                          Current Litigation
                        </h6>
                      </div>
                      <div class="col-4">
                        <hr class="hrline">
                      </div>
                    </div><br>
                    <div class="row">
                      <div class="col-3">
                        <h6>Name of other party involved</h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" placeholder=""
                          [(ngModel)]="bank_details['other_party_name']" disabled />
                      </div>
                      <div class="col-3">
                        <h6>Cause of dispute </h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" placeholder=""
                          [(ngModel)]="bank_details['cause_of_dispute']" disabled />
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>Name of court/ arbitral tribunal</h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" placeholder=""
                          [(ngModel)]="bank_details['name_of_court']" disabled />
                      </div>
                      <div class="col-3">
                        <h6>Amount of dispute </h6>
                      </div>
                      <div class="col-3">
                        <input type="number" class="form-control" placeholder=""
                          [(ngModel)]="bank_details['amount_of_dispute']" disabled />
                      </div>
                    </div>
                    <br>

                    <div class="row">
                      <div class="col-3">
                        <h6>Upload</h6>
                      </div>
                      <div class="col-3">


                      </div>
                      <div class="col-1">

                        <button (click)="viewDoc('current_litigation_doc')" class="btn btn-primary">
                          View</button>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-4">
                        <hr class="hrline">
                      </div>
                      <div class="col-4 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px;">
                          WORK METHOD SCHEDULE
                        </h6>
                      </div>
                      <div class="col-4">
                        <hr class="hrline">
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>Number of activity</h6>
                      </div>
                      <div class="col-3">
                        <input type="number" class="form-control" placeholder=""
                          [(ngModel)]="bank_details['num_of_act']" disabled />
                      </div>
                      <div class="col-3">
                        <h6>Discription </h6>
                      </div>
                      <div class="col-3">
                        <input type="text" class="form-control" placeholder="" [(ngModel)]="bank_details['des']"
                          disabled />
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>Number of Days</h6>
                      </div>
                      <div class="col-3">
                        <input type="number" class="form-control" placeholder=""
                          [(ngModel)]="bank_details['num_of_days']" disabled />

                      </div>
                      <div class="col-3">
                        <h6>Upload</h6>
                      </div>
                      <div class="col-2">

                      </div>
                      <div class="col-1">

                        <button (click)="viewDoc('method_work_doc')" class="btn btn-primary">
                          View</button>
                      </div>
                    </div>
                    <br>
                  </div>
                  <div class="row  text-center">
                    <div class="col-12">
                      <button matStepperPrevious class="btn btn-danger">
                        Previous </button> &nbsp;
                      <button matStepperNext class="btn btn-primary">
                        Next</button>
                    </div>
                  </div>



                </mat-step>
                <mat-step label="Decleration">
                  <br>
                  <div *ngIf="venderInfo">
                    <div class="row">
                      <div class="col-12 text-center">UPLOAD NOTORISED AFFIDAVITE REGARDING THAT THERE IS NO
                        RELATIVE AND BLOOD RELATION OF ANY PROPRITER/PARTNER / DIRECTOR IS WORKING IN LUCKNOW
                        DEVELOPMENT AUTHORITY</div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>DATE OF ISSUE</h6>
                      </div>
                      <div class="col-3"> <input type="date" class="form-control" placeholder=""
                          [(ngModel)]="venderInfo['notorised_no_blood_rel_issue_dt']" disabled /></div>
                      <div class="col-3">


                      </div>
                      <div class="col-3">

                        <button (click)="viewDoc('notorised_no_blood_rel_issue_doc')" class="btn btn-primary">
                          View</button>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 text-center">UPLOAD NOTORISED AFFIDATIVE REGARDING THAT THE APPLICANT/
                        FIRM/ COMPANEY IS NOT BLACK LISTED IN ANY DEPARTMENT</div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>DATE OF ISSUE</h6>
                      </div>
                      <div class="col-3"> <input type="date" class="form-control" placeholder=""
                          [(ngModel)]="venderInfo['notorised_not_black_listed_issue_dt']" disabled /></div>
                      <div class="col-3">


                      </div>
                      <div class="col-3">

                        <button (click)="viewDoc('notorised_not_black_listed_issue_doc')" class="btn btn-primary">
                          View</button>
                      </div>
                    </div>


                    <br>


                    <div class="row">
                      <div class="col-12 text-center">UPLOAD NOTORISED AFFIDATIVE REGARDING THAT YOU HAVE READ THE
                        VARIOUES TERM & CONDITION AND INFORMATION FOR CONTRACT REGESTRATION FORM AND AGREE TO
                        ABIDE
                        BY THE SAID CONDITION AND I ALSO AGREE THAT IF THE INFORMATION PROVIDED BY ME/ MYSELF IS
                        PROVIDED WRONG THEN THE AUTHORITY AS PER LAW. PLEASE UPLOAD HAND WRITTEN DECLERATION
                        BELOW.
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-3">
                        <h6>DATE OF ISSUE</h6>
                      </div>
                      <div class="col-3">
                        <input type="date" class="form-control" placeholder=""
                          [(ngModel)]="venderInfo['notorised_condition_issue_dt']" disabled />
                      </div>
                      <div class="col-3">

                      </div>
                      <div class="col-3">

                        <button (click)="viewDoc('notorised_condition_issue_doc')" class="btn btn-primary">
                          View</button>
                      </div>
                    </div>


                    <br>
                  </div>
                  <div class="row  text-center">
                    <div class="col-12">
                      <button matStepperPrevious class="btn btn-danger">
                        Previous </button> &nbsp;
                      <button class="btn btn-success" (click)="print()">Print</button>

                    </div>
                  </div>
                </mat-step>

              </mat-horizontal-stepper>


              <br>
              <br>








              <ngx-spinner>
                Loading....
              </ngx-spinner>










            </div>





          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal" id="inactive">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title">Inactive Vendor Status</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-4">
            <h6>Vendor ID : <span class="text-danger">*</span></h6>
          </div>
          <div class="col-3">
            <input type="text" [(ngModel)]="vendorObj['vendor_id']" class="form-control" disabled>
          </div>
          <div class="col-3"></div>
        </div>
        <br>
        <div class="row">
          <div class="col-2"></div>
          <div class="col-4">
            <h6>Date : <span class="text-danger">*</span> </h6>
          </div>
          <div class="col-3">
            <input type="date" [(ngModel)]="vendorObj['inactive_date']" [value]="currentDate()"
              [placeholder]="currentDate()" class="form-control">
          </div>
          <div class="col-3"></div>
        </div>
        <br>
        <div class="row">
          <div class="col-2"></div>
          <div class="col-4">
            <h6>Reason For Inactive : <span class="text-danger">*</span></h6>
          </div>
          <div class="col-3">
            <input type="text" [(ngModel)]="vendorObj['inactive_reason']" class="form-control">
          </div>
          <div class="col-3"></div>
        </div>
        <br>

        <!-- Modal footer -->
        <div class="row modal-footer">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button class="btn btn-outline-primary" (click)="inactive()">Submit</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
