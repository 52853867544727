<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        Create-Rule
                    </div>
                </div>
                <div class="ibox-body">

                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                List</a>
                            <!-- Geographic Location List-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Create Rule</a>

                        </li>




                    </ul>
                    <br>
                    <div class="tab-content" *ngIf="list_flag">
                        <br>
                        <div class="row">
                            <div class="col-4">
                                <mat-form-field style="margin-left: 2%">
                                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    <!-- Filter  -->
                                </mat-form-field>
                            </div>
                            <div class="col-4">

                            </div>
                            <div class="col-4 text-right">
                                <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                            </div>

                        </div>
                        <br>
                        <div class=" example-container">
                            <table mat-table [dataSource]="datasource" matSort>
                                <ng-container matColumnDef="si_no">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                        SI. NO.

                                    </th>
                                    <!--ID-->
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                        {{i+1}}</td>
                                </ng-container>


                                <ng-container matColumnDef="section">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        Section

                                    </th>
                                    <!--Short Name-->
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{ element.leaf_value }} </td>
                                </ng-container>

                                <ng-container matColumnDef="frequency">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        Frequency
                                    </th>
                                    <!--Unit-->
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{ element.frequency}} </td>
                                </ng-container>

                                <ng-container matColumnDef="number">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        Number
                                    </th>
                                    <!--Unit-->
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{ element.number}} </td>
                                </ng-container>

                                <ng-container matColumnDef="grace">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        Grace Time
                                    </th>
                                    <!--Unit-->
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{ element.grace}} </td>
                                </ng-container>

                                <ng-container matColumnDef="from_time">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        From Time
                                    </th>
                                    <!--Unit-->
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{ element.fromTime}} </td>
                                </ng-container>


                                <ng-container matColumnDef="to_time">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        To Time
                                    </th>
                                    <!--Unit-->
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{ element.toTime}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        </div>


                    </div>

                    <div class="tab-content" *ngIf="create_flag">
                        <br>
                        <div class="row">
                            <div class="col-12 text-right">
                                <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                            </div>
                        </div>
                        <br>
                        <div class="tab-pane fade show active">

                            <div class="row">
                                <div class="col-5 text-right">
                                    <h6 style="margin-right: 10%;">
                                        Select Node : <span style="color:red">*</span>
                                    </h6>
                                </div>
                                <div class="col-7 text-left margin-left 10%">
                                    <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                        Employee Node</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-5 text-right">
                                    <h6 style="margin-right: 10%;">
                                        Selected Node Path:<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4 text-left margin-left: 10%">
                                    <input class="form-control" [(ngModel)]="Obj['path_desc']" disabled>
                                    <!-- {{Obj['path_desc']}} -->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-5 text-right">
                                    <span style="margin-right: 10%">Office Time :<span
                                            style="color: red;">*</span></span>
                                </div>
                                <div class="col-2 text-left margin-left:10%">
                                    From - &nbsp; &nbsp; <input class="form-control" type="time" [(ngModel)]="fromTime">

                                </div>
                                <div class="col-2 text-left margin-left:10%">
                                    To - &nbsp; &nbsp; <input class="form-control" type="time" [(ngModel)]="toTime">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-5 text-right">
                                    <span style="margin-right: 10%"> Grace Allowed :<span style="color: red;">*</span>
                                    </span>
                                </div>
                                <div class="col-4 text-left margin-left:10%">
                                    In Minutes - &nbsp; &nbsp; <input class="form-control" type="number"
                                        [(ngModel)]="grace">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-5 text-right margin-right: 10%"> </div>
                                <div class="col-2 text-left margin-left:10%">
                                    In Number - &nbsp;&nbsp; <input class="form-control" type="number"
                                        [(ngModel)]="inNo"> </div>
                                <div class="col-2 text-left margin-left:10%">
                                    Frequency - &nbsp;&nbsp;
                                    <select class="form-control" [(ngModel)]="frequency">
                                        <option *ngFor="let item of frequencyPeriod" [value]="item">{{item}}</option>
                                    </select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">Submit </button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6 text-right margin-right: 10%">

                                </div>
                                <div class="col-6 text-left margin-left:10%">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6 text-right margin-right: 10%">

                                </div>
                                <div class="col-6 text-left margin-left:10%">

                                </div>
                            </div>



                            <!--
                        <div class="row">
                            <div class="col-4 text-right">
                                Select Employee :

                            </div>
                            <div class="col-4">
                                <ng-select [items]="newallEmplyees"  bindLabel="emp_name"
                                    (change)="changeEmployee()" bindValue="arr_id" [multiple]="false"
                                    placeholder="Select Employee"
                                    [(ngModel)]="arr_id" [selectableGroup]="false" [selectableGroupAsModel]="false"
                                    [closeOnSelect]="true" name="field_name">
                                </ng-select> -->
                            <!--
                                <ng-select [items]="newallEmplyees" bindLabel="emp_name" (change)="changeEmployee()" bindValue="arr_id" [multiple]="false" placeholder="Select Employee" [(ngModel)]="arr_id"
                                [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                            </ng-select> -->
                            <!-- </div>
                        </div> -->








                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <ngx-spinner>Loading....</ngx-spinner>



    <div class="modal" id="ACTHIER">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Select Employee Node</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>

                <div class="modal-body">
                    <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                        <div class="col-3"></div>
                        <div class="col-3" *ngIf="i==0">
                            <h6 style="margin-left: 2%;">Select Node : <span style="color: red;"> *</span></h6>
                            <!-- -->
                        </div>
                        <div class="col-3" *ngIf="i!=0"></div>
                        <div class="col-1">

                            <span *ngIf="item['level']"> Level </span>{{item['level']}}
                            <!---  -->
                        </div>
                        <div class="col-3">
                            <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd"
                                [multiple]="false" placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']"
                                [selectableGroup]="true" (change)="setHierarchyNodeitem(item,i)"
                                [selectableGroupAsModel]="true" [closeOnSelect]="true">
                            </ng-select>

                        </div>
                        <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                            <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                    aria-hidden="true"></i></button> &nbsp;&nbsp;
                            <button (click)="remove(item,i)" class="btn btn-danger">
                                <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                        </div>
                        <div class="col-3"></div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3 ">
                            <h6 style="margin-left: 2%;"> <span style="color: red;">*</span> Current Node Path :
                            </h6>
                        </div>
                        <div class="col-6">
                            {{Obj['path_desc']}}
                        </div>

                    </div>
                    <br>

                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button class="btn btn-primary" (click)="submitProjectHierNode()">Submit</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
