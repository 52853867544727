import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

import swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../../service/establishment.service';
import { AllEmpService } from '../../service/all-emp.service';
import { SettingService } from '../../service/setting.service';
import { MainService } from '../../service/main.service'
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { AttendaceMangService } from '../../attendance_manag/attendace-mang.service';
import { url } from 'inspector';

declare var $: any

@Component({
  selector: 'app-leaves-apply',
  templateUrl: './leaves-apply.component.html',
  styleUrls: ['./leaves-apply.component.css']
})
export class LeavesApplyComponent implements OnInit {


  constructor(private settingService: SettingService, public mainService: MainService,
    private sanitizer: DomSanitizer, private router: Router, private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar, private payableService: EstablishmentService, private allEmpService: AllEmpService,
    private attendService: AttendaceMangService) { }
  leave: any = false;
  erpUser: any;
  b_acct_id: any;
  allEmployees: any = [];
  selectObj: any = {};
  applyLeaveObj: any = {};
  allLeaves: any = []
  create_user_id: any;
  remainingLeaves: any = 0;
  newallEmplyees: any = []
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns: any = ['emp_name', 'emp_id', 'leave_code', 'num_of_leaves', 'leave_status_code', 'from_date', 'leave_reason', 'action'];
  datasource: any;

  imageBlobUrl: any;
  imgURL: any
  selectedFile: any = null;
  isUpload: any;
  public imagePath: any;
  httpUrl: any;
  uploader: any;
  allLeaveRuleInfo: any = [];
  leave_reason: any
  activeEmpArr: any = [];
  allEmp: any = [];
  employeeObj: any = {};
  leaveStatus: any = [{ code: 'APPLIED', value: 'APPLIED' }, { code: 'APPROVED', value: 'APPROVED' }, { code: 'REJECTED', value: 'REJECTED' }]

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    console.log(" this is the rep user ", this.erpUser)
    this.b_acct_id = this.erpUser.b_acct_id;
    this.httpUrl = this.mainService.httpUrl;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    await this.getAllEmployees();
    await this.getAllLeaveRuleDetails();
    await this.allEmployeeLeaveLadger();
  }


  view_reason(element: any) {
    this.leave_reason = element.leave_reason;
  }
  submitFilter() {
    this.spinner.show()
    var data = [];
    console.log(' this is the all leaves ', this.allLeaves);
    for (let i = 0; i < this.allLeaves.length; i++) {
      if (this.selectObj['year'] == this.allLeaves[i]['year'] && this.selectObj['leave_status_code'] == this.allLeaves[i]['leave_status_code']) {
        data.push(this.allLeaves[i]);
      }
    }
    console.log(' this is  the data ', data);
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < this.allEmployees.length; j++) {
        if (data[i]['emp_id'] == this.allEmployees[j]['emp_id']) {
          data[i]['emp_name'] = this.allEmployees[j]['emp_name']
          // data[i]['empId']=this.mainService.accInfo['account_short_name'] + ''+this.allEmployees['emp_id']
        }
      }
    }
    this.datasource = new MatTableDataSource(data);
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
    this.spinner.hide()
  }
  onFileUpload(event: any, files: any) {
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };

  }
  async getAllLeaveRuleDetails() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.getAllRules(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allLeaveRuleInfo = resp.data;
      for (let i = 0; i < this.allLeaveRuleInfo.length; i++) {
        this.allLeaveRuleInfo[i]['leave_value'] = this.mainService.codeValueShowObj['HR0026'][this.allLeaveRuleInfo[i]['leave_code']];
      }
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting  all Leave Rule list ', 'error')
      // this.snackBar.open("Error while getting  all Leave Rule list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  getNumberFormat(num: any) {
    return num.toString().padStart(3, "0")
  }
  async getAllEmployees() {
    // this.spinner.show()
    // var obj :any= new Object();
    // obj['b_acct_id'] = this.b_acct_id;
    // var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
    // if (resp['error'] == false) {
    //   this.spinner.hide()
    //   this.allEmployees = resp.data;

    //   this.newallEmplyees = []
    //   for (let i = 0; i < this.allEmployees.length; i++) {
    //     var obj :any= new Object();
    //     obj = Object.assign({}, this.allEmployees[i]);
    //     obj['name'] = /* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
    //     this.newallEmplyees.push(obj)
    //   }
    // } else {
    //   this.spinner.hide()
    //   swal.fire('Error', 'Error while getting employee list','error');
    // }
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status_code'] = ["ACTIVE", "INACTIVE"];
    var resp = await this.payableService.getArrayAllCurrentEstablishementInfo(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allEmployees = resp.data;
      this.allEmp = resp.data;
      this.activeEmpArr = []

      for (let i = 0; i < this.allEmployees.length; i++) {
        this.employeeObj[this.allEmployees[i]['emp_id']] = this.allEmployees[i]['emp_name'];
        if (this.allEmployees[i].emp_status_code == "ACTIVE") {
          this.activeEmpArr.push(this.allEmployees[i])
        }
      }
      this.newallEmplyees = []
      for (let i = 0; i < this.activeEmpArr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, this.activeEmpArr[i]);
        obj['name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
      }

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async allEmployeeLeaveLadger() {
    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.payableService.getAllLeaveLedger(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.allLeaves = resp.data;

    } else {
      this.spinner.hide()
      swal.fire("Error", "Error while getting Leaves", 'error');
    }
  }
  async getRemainingLeaves() {
    this.remainingLeaves = 0;
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.applyLeaveObj['emp_id'];
    var arr = this.applyLeaveObj['from_date'].split('-');
    obj['year'] = arr[0];
    obj['month'] = arr[1];
    var resp = await this.payableService.getLeaveInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      var dt = resp.data;
      for (var i = 0; i < dt.length; i++) {
        if (dt[i]['emp_id'] == this.applyLeaveObj['emp_id'] && dt[i]['leave_code'] == this.applyLeaveObj['leave_code']) {
          this.remainingLeaves = dt[i]['leaves_remaining'];
        }
      }
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while getting remaining Leaves', 'error');
    }
  }


  approveByHr_ExtLeave :any= {};


  async approvalMessage(element:any) {
    swal.fire({
      title: 'Are you Sure?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      // cancelButtonText: 'No, Take it!'
    }).then(async (result) => {
      if (result.value) {
        await this.approve(element)
      }
    })


  }



  async approve(element:any) {

    var obj1 = Object.assign({}, element);
    console.log(" this is the element of the approval ", element);
    obj1['b_acct_id'] = this.b_acct_id;
    obj1['approval_user_id'] = this.erpUser.user_id;
    obj1['leave_status_code'] = "APPROVED";
    this.spinner.show();
    this.applyLeaveObj = Object.assign({}, element);
    await this.getRemainingLeaves();
    if (this.remainingLeaves >= this.applyLeaveObj['num_of_leaves']) {
      var resp = await this.payableService.approveLeave(obj1);
      if (resp['error'] == false) {
        await this.allEmployeeLeaveLadger();
        await this.submitFilter();
        this.spinner.hide();
        swal.fire('Success', 'Leave Approved', 'success')

      }
      else {
        this.spinner.hide();
        swal.fire('Error', 'Error while changing the status of leave', 'error');

      }
    } else {
      this.spinner.hide();
      swal.fire({
        title: 'Are you Sure?',
        text: 'Can not be approved as the employee has only ' + this.remainingLeaves + ' left. Do you want to send it in extra leave ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Send It !',
        // cancelButtonText: 'No, Take it!'
      }).then((result) => {
        if (result.value) {
          // this.finalupdateDependentInfo()

          this.applyLeaveObj['b_acct_id'] = this.b_acct_id;
          this.saveExtraLeave();
          console.log(" go the the extra leave legar ", this.applyLeaveObj)



          this.approveByHr_ExtLeave = {};
          this.approveByHr_ExtLeave['id'] = element.id
          this.afterExtraLeaveAppByHr();


        }
      })
      // swal.fire('Info', 'Can not be approved as the employee has only2 ' + this.remainingLeaves + ' left. Do you want to extra leave ','info');
    }

  }
  // After axtra leave approved by to hr
  async afterExtraLeaveAppByHr() {
    this.approveByHr_ExtLeave['b_acct_id'] = this.b_acct_id;
    // let resp= await this.payableService.deleteByHr(JSON.stringify(this.approveByHr_ExtLeave))
    var resp = await this.payableService.deleteByHr(JSON.stringify(this.approveByHr_ExtLeave));
    if (resp['error'] == false) {
      await this.allEmployeeLeaveLadger();
      await this.submitFilter();
      this.spinner.hide();
      console.log('Avi delete')
    }
    else {
      this.spinner.hide();
      swal.fire('Error', 'Some Error In Data Approval', 'error');
    }
  }




  async reject(element:any) {

    var obj1 = Object.assign({}, element);

    obj1['b_acct_id'] = this.b_acct_id;
    obj1['approval_user_id'] = this.erpUser.user_id;
    obj1['leave_status_code'] = "REJECTED";
    this.spinner.show()
    var resp = await this.payableService.rejectLeave(obj1);
    if (resp['error'] == false) {
      await this.allEmployeeLeaveLadger();
      await this.submitFilter();


      this.spinner.hide();
      swal.fire('Success', 'Leave Rejected', 'success')
    }
    else {
      this.spinner.hide();
      swal.fire('Error', 'Error while changing the status of leave', 'error');
    }
  }
  //Avinash MIshra
  specficLeace :any= []
  async leavevalidation() {

    await this.allEmployeeLeaveLadger();
    // this.selectEmpObj['emp_id'] = this.applyLeaveObj['emp_id'];
    console.log(" this is the all leaves --0", this.allLeaves)

    this.allLeaves.filter((x:any) => {
      if (x.emp_id == this.applyLeaveObj['emp_id'] && x.month == this.applyLeaveObj['month'] && x.year == this.applyLeaveObj['year']) {
        this.specficLeace.push(x);
      }
    });
    console.log(' tis is the   specficLeace', this.specficLeace);
    if (this.specficLeace.length == 0) {
      this.leave = false;
      return
    }

    var formdate = this.applyLeaveObj['from_date'].split('-');
    for (let i = 0; i < this.specficLeace.length; i++) {

      if (this.specficLeace.length != 0) {

        var form_dt = this.specficLeace[i]['from_date']
        var sArr = form_dt.split('-');
        if (sArr[0] == formdate[0] && (sArr[1] >= formdate[1] || (parseInt(formdate[1]) - 1) == sArr[1]) && (this.specficLeace[i]['leave_status_code'] == 'APPROVED' || this.specficLeace[i]['leave_status_code'] == 'APPLIED')) {

          var day = 0
          for (let j = 0; j < this.specficLeace[i]['num_of_leaves']; j++) {
            var days = parseInt(sArr[2]) + day
            if ((parseInt(sArr[1]) % 2) == 0 && (parseInt(sArr[1])) != 2) {
              if (days > 30) {
                sArr[1] = parseInt(sArr[1]) + 1
                sArr[2] = 1
                days = 1
                day = 0
              }
            }
            else if ((parseInt(sArr[1])) == 2) {
              if ((parseInt(sArr[0]) % 4) == 0) {
                if (days > 29) {
                  sArr[1] = parseInt(sArr[1]) + 1
                  sArr[2] = 1
                  days = 1
                  day = 0
                }
              }
              else {
                if (days > 28) {
                  sArr[1] = parseInt(sArr[1]) + 1
                  sArr[2] = 1
                  day = 0
                }
              }
            } else {
              if (days > 31) {
                sArr[1] = parseInt(sArr[1]) + 1
                sArr[2] = 1
                days = 1
                day = 0
              }
            }
            var date
            if (days < 10) {
              date = "0" + days
            }
            else {
              date = days
            }
            var form_date = sArr[0] + "-" + sArr[1] + "-" + date
            var d = 0
            for (let k = 0; k < this.applyLeaveObj['num_of_leaves']; k++) {
              var fdays = parseInt(formdate[2]) + d
              var fdate
              if ((parseInt(formdate[1]) % 2) == 0 && (parseInt(formdate[1])) != 2) {
                if (fdays > 30) {
                  formdate[1] = parseInt(formdate[1]) + 1
                  formdate[2] = 1
                  fdays = 1
                  d = 0
                }
              }
              else if ((parseInt(formdate[1])) == 2) {
                if ((parseInt(formdate[0]) % 4) == 0) {
                  if (fdays > 29) {
                    formdate[1] = parseInt(formdate[1]) + 1
                    formdate[2] = 1
                    fdays = 1
                    d = 0
                  }
                }
                else {
                  if (fdays > 28) {
                    formdate[1] = parseInt(formdate[1]) + 1
                    formdate[2] = 1
                    fdays = 1
                    d = 0
                  }
                }
              } else {
                if (fdays > 31) {
                  formdate[1] = parseInt(formdate[1]) + 1
                  formdate[2] = 1
                  fdays = 1
                  d = 0
                }
              }
              if (fdays < 10) {
                fdate = "0" + fdays
              }
              else {
                fdate = fdays
              }
              var fordate = formdate[0] + "-" + formdate[1] + "-" + fdate

              if (form_date == fordate && (this.specficLeace[i]['leave_status_code'] == 'APPROVED' || this.specficLeace[i]['leave_status_code'] == 'APPLIED')) {
                this.leave = true;


              }
              d = d + 1
            }
            day = day + 1
          }
        }



      }


    }

  }
  refresh() {
    this.applyLeaveObj = {};
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


  async deleteMessage(element: any) {
    swal.fire({
      title: 'Are you Sure?',
      // text: 'Can not be approved as the employee has only ' + this.remainingLeaves + ' left. Do you want to send it in extra leave ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      // cancelButtonText: 'No, Take it!'
    }).then(async (result) => {
      if (result.value) {
        await this.delete(element)
      }
    })
  }


  async delete(element:any) {
    var obj1 = Object.assign({}, element);
    obj1['b_acct_id'] = this.b_acct_id;
    this.spinner.show();

    var resp = await this.payableService.deleteLeaveRecord(JSON.stringify(obj1));
    if (resp['error'] == false) {
      await this.allEmployeeLeaveLadger();
      await this.submitFilter();

      this.spinner.hide();
      swal.fire('Success', 'Leave Delete', 'success')
    }
    else {
      this.spinner.hide();
      swal.fire('Error', 'Error while delete leave', 'error');
    }
  }

  filename:any
  async view(element:any) {
    var obj: any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['upload_id'] = element.document_id
    obj['filename'] = element.document_name;
    this.filename = element.document_name;
    this.spinner.show()
    const res = await this.allEmpService.getUploadedFileData(obj);
    if (res) {

      var docname = element.document_name;
      var ext = docname.split('.');

      if (ext[ext.length - 1] == 'png' || ext[ext.length - 1] == 'jpeg' || ext[ext.length - 1] == 'jpg') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imageOpen = true
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

      this.spinner.hide()

    }
  }
  // ============== Second tab
  changeName() {
    console.log(this.newallEmplyees)
    if (this.applyLeaveObj['emp_id']) {
      for (let i = 0; i < this.newallEmplyees.length; i++) {
        if (this.applyLeaveObj['emp_id'] == this.newallEmplyees[i]['emp_id']) {
          this.applyLeaveObj['emp_name'] = this.newallEmplyees[i]['emp_name']
          break;
        }
      }
    } else {
      this.applyLeaveObj['emp_name'] = ''
    }
  }
  async applyLeave() {

    if (this.applyLeaveObj['leave_reason'] == undefined || this.applyLeaveObj['leave_reason'] == null || this.applyLeaveObj['leave_reason'] == '' ||
      this.applyLeaveObj['to_date'] == undefined || this.applyLeaveObj['to_date'] == null || this.applyLeaveObj['to_date'] == '' ||
      this.applyLeaveObj['from_date'] == undefined || this.applyLeaveObj['from_date'] == null || this.applyLeaveObj['from_date'] == '' ||
      this.applyLeaveObj['leave_code'] == undefined || this.applyLeaveObj['leave_code'] == null || this.applyLeaveObj['leave_code'] == '' ||
      this.applyLeaveObj['emp_name'] == undefined || this.applyLeaveObj['emp_name'] == null || this.applyLeaveObj['emp_name'] == '' ||
      this.applyLeaveObj['emp_id'] == undefined || this.applyLeaveObj['emp_id'] == null || this.applyLeaveObj['emp_id'] == ''
    ) {
      swal.fire("Warning", "* Field Required !", 'info');
      return;
    }

    if (this.applyLeaveObj['from_date'] > this.applyLeaveObj['to_date']) {
      swal.fire('Sorry', 'Please Select Vaild From Date and TO Date.....', 'warning')
      return;
    }
    this.spinner.show();
    this.applyLeaveObj['b_acct_id'] = this.b_acct_id;
    this.applyLeaveObj['emp_id'] = this.applyLeaveObj['emp_id'];
    this.applyLeaveObj['leave_status_code'] = "APPLIED";
    this.applyLeaveObj['application_date'] = this.applyLeaveObj['from_date'];
    this.applyLeaveObj['leave_status_code'] = "APPLIED";
    var arr = this.applyLeaveObj['from_date'].split('-');
    this.applyLeaveObj['year'] = arr[0];
    this.applyLeaveObj['month'] = arr[1];
    this.applyLeaveObj['create_user_id'] = this.erpUser.user_id;
    var one_day = 1000 * 60 * 60 * 24
    var Result = Math.round(new Date(this.applyLeaveObj['to_date']).getTime() - new Date(this.applyLeaveObj['from_date']).getTime()) / (one_day);

    // To remove the decimals from the (Result) resulting days value
    var Final_Result = Result.toFixed(0);
    this.applyLeaveObj['num_of_leaves'] = Number(Final_Result) + 1
    console.log(this.applyLeaveObj)
    await this.getRemainingLeaves();
    await this.leavevalidation();


    console.log(this.applyLeaveObj['num_of_leaves'], this.remainingLeaves, this.leave);

    if (this.remainingLeaves >= this.applyLeaveObj['num_of_leaves'] && this.leave == false) {
      if (this.uploader.queue.length > 0) {
        const formData = new FormData();
        formData.append('image', this.selectedFile, this.selectedFile.name);
        this.applyLeaveObj['document_name'] = this.uploader.queue[0].some.name;
        this.applyLeaveObj['document_type_code'] = 'LEAVE';
        const params = JSON.stringify(this.applyLeaveObj);
        this.uploader.queue[0].url = this.httpUrl + '/hr/leaveLedger/applyForLeave' + params;
        this.uploader.queue[0].upload();

        this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
          if (!response.error) {
            this.uploader.queue = [];
            await this.allEmployeeLeaveLadger();
            this.spinner.hide();
            swal.fire('Success', 'Leave Applied Successfully', 'success')
            this.applyLeaveObj = {}
          } else {
            this.spinner.hide()
            swal.fire('Error', 'Error while Applying Leave', 'error');
          }
        };
      } else {
        console.log(this.applyLeaveObj, "shhhh")
        var resp = await this.payableService.applyForLeave(JSON.stringify(this.applyLeaveObj));
        if (resp['error'] == false) {
          await this.allEmployeeLeaveLadger();
          this.spinner.hide()
          swal.fire('Success', 'Leave Applied Successfully', 'success')
          this.applyLeaveObj = {}
        } else {
          this.spinner.hide()
          swal.fire('Error', 'Error while Applying Leave', 'error');
          this.applyLeaveObj = {}
        }


      }


    } else if (this.leave == true) {
      this.spinner.hide();
      this.leave = false;
      swal.fire('Sorry', 'You Already Applied or Check Your Leaves ', 'warning')
    }
    else {
      this.spinner.hide();
      if (this.remainingLeaves > 0) {
        swal.fire("Info", 'Firstaly take the allowed (' + this.remainingLeaves + ') leaves and then apply for the extra leaves !', 'info');
        return
      }
      // else{

      // }

      swal.fire({
        title: 'Are you Sure?',
        text: 'Can not be approved as the employee has only ' + this.remainingLeaves + ' left. Do you want to extra leave ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Take it!',
        // cancelButtonText: 'No, Take it!'
      }).then(async (result) => {
        if (result.value) {
          // this.finalupdateDependentInfo()
          await this.saveExtraLeave()
          console.log(" go the the extra leave legar ")

        }
      })
      // swal.fire('Sorry', 'You have only ' + this.remainingLeaves + ' leaves left','warning')
    }


  }

  // save data in the extra leave list
  async saveExtraLeave() {
    // this.applyLeaveObj['le_id'] = this.erpUser.le_id;

    let leId = this.newallEmplyees.filter((x:any) => {
      console.log(x)
      if (x.emp_id == this.applyLeaveObj['emp_id']) {
        this.applyLeaveObj['le_id'] = x.le_id;
        return
      }
    })

    if (this.uploader.queue.length > 0) {
      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      this.applyLeaveObj['document_name'] = this.uploader.queue[0].some.name;
      this.applyLeaveObj['document_type_code'] = 'LEAVE';

      const params = JSON.stringify(this.applyLeaveObj);
      this.uploader.queue[0].url = this.httpUrl + '/hr/attendance_mang/extrawork/applyForExtraLeave' + params;
      this.uploader.queue[0].upload();

      this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        if (!response.error) {
          this.uploader.queue = [];
          // await this.allEmployeeLeaveLadger();
          this.spinner.hide();
          swal.fire('Success', 'Extra Leave Applied Successfully', 'success')
          this.applyLeaveObj = {}
        } else {
          this.spinner.hide()
          swal.fire('Error', 'Error while Applying Extra Leave', 'error');
          this.applyLeaveObj = {}
        }
      };
    } else {
      // when he have no document ----

      var resp = await this.attendService.applyForExtraLeave(JSON.stringify(this.applyLeaveObj));
      if (resp['error'] == false) {
        // await this.allEmployeeLeaveLadger();
        this.spinner.hide()
        swal.fire('Success', 'Leave Applied Successfully', 'success')
        this.applyLeaveObj = {}
      } else {
        this.spinner.hide()
        swal.fire('Error', 'Error while Applying Leave', 'error');
        this.applyLeaveObj = {}

      }


    }
  }
  imageOpen: boolean = false
  async clearImage() {
    this.imgURL = ''
    this.imageOpen = false;
    // console.log("Image length ", this.imgURL.length)
  }



}
