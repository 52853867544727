import { Component, OnInit, ElementRef } from '@angular/core';

import { HomeService } from '../service/home.service';
import { FinancialsService } from '../service/financials.service';
import { TenderService } from '../service/tender.service'
import { MasterDataService } from '../service/master-data.service'
import { EbillService } from '../service/ebill.service'
import { NgxSpinnerService } from "ngx-spinner";
// oc
// ChartDataSets, ChartFontOptions
// ec
import { ChartOptions as ChartOption, ChartType } from 'chart.js';
// Label
// import { Label } from 'ng2-charts';
import { ChartConfiguration, ChartData, ChartEvent } from 'chart.js';

import { MainService } from '../service/main.service';
import { EmbService } from '../service/emb.service';
import { DashService } from '../service/dash.service';
import Swal from 'sweetalert2';
declare var $: any;
import { Router } from '@angular/router';
import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexChart,
  ApexPlotOptions,
  ApexLegend
} from "ng-apexcharts";
import { FilterService } from '../service/filter.service';
import { start } from 'repl';
import { CommonDataService } from '../common _services/common-data.service';
import { count } from 'rxjs/operators';
import { title } from 'process';
import { EMPTY } from 'rxjs';
import { UserService } from '../service/user.service';
import { MainService as mainServiceMD } from '../../md/service/main.service';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  xaxis: any;

};
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent {
  chartData_2a: any = []
  chartData_1: any = []
  chartData_2: any = []
  chartData_3a: any = []
  chartData_3b: any = []
  chartData2: any = []
  public barChartData: any[] = [
    { data: this.chartData_1, label: 'Budget' },
    { data: this.chartData_2, label: 'Work Order' }
  ];

  public barChartData2: any[] = [
    { data: this.chartData_3a, label: 'EMB' },
    { data: this.chartData_3b, label: 'EBILL' }
  ];
  public barChartData2a: any[] = [
    {
      data: this.chartData_2a, label: 'Work (Days)',

    },
  ];
  chart_data2b: any = []
  chart_data2c: any = []
  public barChartData2b: any[] = [

    {
      data: this.chart_data2b, label: 'Work (%)'
    },
  ];

  public barChartData2c: any[] = [
    {
      data: this.chart_data2c,
      label: 'Work (%)',
    },
  ];
  chart_data4c: any = []
  public barChartData4c: any[] = [
    {
      data: this.chart_data4c,
      label: '',
    },
  ];
  chart_data1b: any = []
  public barChartData1b: any[] = [

    {
      data: this.chart_data1b, label: ''
    }
  ];

  chart_data4a: any = []
  chart_data4a_lebel: any = []
  public barChartData4a: any[] = [
    { data: this.chart_data4a, label: 'EMB' },
  ];
  chart_data4b: any = []
  chart_data4b_lebel: any = []
  public barChartData4b: any[] = [
    { data: this.chart_data4b, label: 'EBILL' },
  ];


  public chartOptions: Partial<ChartOptions>;
  // public  chartFontOptions: Partial< ChartFontOptions>;
  public chartFontOptions: any
  public chartOptions2: Partial<ChartOptions>;
  public chartOptions3: Partial<ChartOptions>;
  public chartOptions4: any;

  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'


  chartCallback: Highcharts.ChartCallbackFunction = function (chart) { } // optional function, defaults to null
  updateFlag: boolean = false; // optional boolean
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  runOutsideAngular: boolean = false; // optional boolean, defaults to false

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {},
      y: {
        min: 0
      },
    },

  };
  color1: any = []
  randomColor1() {
    for (let j = 0; j < 100; j++) {
      var letters = "0123456789ABCDEF";
      var color = '#';
      for (var i = 0; i < 6; i++)
        color += letters[(Math.floor(Math.random() * 16))];
      this.color1.push(color)
    }
    // //console.log(this.color1)
  }
  color2A: any = []
  randomColor2() {
    for (let j = 0; j < 100; j++) {
      var letters = "0123456789ABCDEF";
      var color: any = '#';
      for (var i = 0; i < 6; i++)
        color += letters[(Math.floor(Math.random() * 16))];
      this.color2.push(color)
    }
    // //console.log(this.color2)
  }

  color2: any = []
  public barChartColors: Array<any> = [
    {
      backgroundColor: this.color1,
      borderColor: this.color1,
      pointBackgroundColor: this.color1,
      pointBorderColor: this.color1,
      pointHoverBackgroundColor: this.color1,
      pointHoverBorderColor: this.color1
    }
  ];
  public barChartColors2: Array<any> = [
    {
      backgroundColor: this.color2,
      borderColor: this.color2,
      pointBackgroundColor: this.color2,
      pointBorderColor: this.color2,
      pointHoverBackgroundColor: this.color2,
      pointHoverBorderColor: this.color2
    }
  ];

  // oc
  // public barChartLabels: Label[] = [];

  public barChartLabels: any = [];
  public barChartLabels2a: any = [];
  public barChartLabels2c: any = [];
  public barChartLabels4c: any = [];
  public barChartLabels2: any = [];
  public barChartLabels2_a: any = [];
  public barChartLabels2b: any = [];
  public barChartLabels2_1: any = [];
  // ec
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  ebillUser: any;
  b_acct_id: any;
  running_late = 0
  allAssignedWorkId: any = []
  chartOptionss: Highcharts.Options
  
  obj = {}
  constructor(private mainServiceMD: mainServiceMD, private dashService: DashService, private filterService: FilterService, private homeService: HomeService, private embService: EmbService, private spinner: NgxSpinnerService, public main: MainService,
    private MasterDataService: MasterDataService, private commonS: CommonDataService, private ebillService: EbillService, private msd: MasterDataService,
    private tenderService: TenderService, private financialService: FinancialsService, private router: Router, private userService: UserService) {
    this.chartOptions = {
      series: [
        {
          data: this.data_chart1,
        }
      ],
      chart: {
        height: 450,
        width: 450,
        type: "treemap",
        fontFamily: 'bold',


      },
      dataLabels: {
        style: {
          fontSize: '100px',
          fontWeight: 'bold',
        },
      }




    };
    this.chartFontOptions = {
      defaultFontSize: 20
    }

    this.chartOptions2 = {
      series: [
        {
          name: this.data_chart2_name,
          data: this.data_chart2
        }
      ],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false
        },

      },
      dataLabels: {
        enabled: false
      },
    };

    this.chartOptions3 = {
      series: [
        {
          data: [
            {
              x: "EMB(0 Days)",
              y: [0, 0]
            },
            {
              x: "EBILL(0 Days)",
              y: [0, 0]
            },

          ]
        }
      ],
      chart: {
        height: 350,
        type: "rangeBar"
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: true
      },
    };

  // highcharts 
    this.chartOptionss = {
      series: [{
        data: [  
          { x: 2, y: 8 }, 
          { x: 4, y: 8 },
        ],
        type: 'column',

      }]
    }; // required
    
    console.log('w?' , this.barChartData2a)
  }
  data_chart1: any = [
    { x: 0, y: 0 }

  ]
  data_chart2: any = [
    ''
  ]
  data_chart3_a = [

  ]
  data_chart3_b = []
  work_amt = 0
  party = []
  party_count = 0
  billInfo = []
  ebill_count = 0
  allWork = []


  allSalaryStop = [];
  allActive = [];
  allLeft = [];
  allIncomplete = [];
  showArr = []
  showArr1 = []
  allBOQ: any;
  allBOQData = []
  allParty_c: number = 0;
  allPartyData = []
  alleBill: any;
  alleBillData = [];
  allWorkData = []
  work_count = 0
  bill_expence = 0
  emb_expence = 0
  data_chart2_name: any = [0]

  async ngOnInit() {
    this.party_count = 0
    this.billInfo = []
    this.ebill_count = 0
    this.allWork = []
    this.allWorkData = []
    this.work_count = 0
    //console.log(new Date("2021-03-31T10:42:07.000Z").getTime())
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.main.eBillUser = this.ebillUser;
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.randomColor1()
    await this.randomColor2()
    await this.getAllHeadRecord()
    await this.getAllWorkInfo();
    await this.getworklist()
    this.chartOptions = {
      series: [{ data: this.data_chart1 }],
      chart: { height: 350, type: "treemap", },
    };
    this.chartOptions = {
      series: [{ data: this.data_chart1 }],
      chart: {
        height: 350, type: "treemap",
        events: {
          click: (event, chartContext, config) => {
            //console.log(config.config.series[config.Data])
            //console.log(config.config.series[config.seriesIndex].name)
            //console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex])
            this.setChart1ModalData(config.config.series[config.seriesIndex].data[config.dataPointIndex])
          }

        }
      },
    };
    await this.getFinancialExpence()
    await this.getembapprovaldays()
    await this.getebillapprovaldays();
    this.chartOptions3 = {
      series: [{ data: this.data_chart3, name: "Average Time in Days" }],
      chart: {
        height: 350,
        type: "rangeBar"
      },
      plotOptions: {
        bar: { horizontal: false }
      },
      dataLabels: {
        enabled: false
      },
    };
    await this.getparty()
    await this.getBillInfo()
    await this.getfinancialexpencebywork()
    await this.getEmbExpenseByWork()
    await this.getEmbExpense()
    await this.getebillExpenseByWork()
    await this.getebillExpense()
    await this.getAllApproved_EMB()
    await this.getAllApproved_BILL()
    await this.get_late_work()
    await this.getlateworkamt()
    await this.getDevAmt()
    await this.getemdmeasurementtoapprovaldays()
    await this.getebillmeasurementtoapprovaldays()
    await this.getAllUserInfo()
    await this.getperformancebyuser()
    this.barChartData2a = [
      {
        data: this.chartData_2a, label: 'Work (Days)',
        colors: this.color1
      },
    ]
    // Highcharts.chart('chart-container', this.chartOptions);
    // Highcharts.chart('chart-container', {
    //   series: [{
    //     data: [
    //       { x: Date.UTC(2018, 6, 16), y: 6 },
    //       { x: Date.UTC(2018, 6, 17), y: 5 },
    //       { x: Date.UTC(2018, 6, 18), y: 8 },
    //       { x: Date.UTC(2018, 6, 19), y: 5 }
    //     ],
    //     type: 'column'
    //   }]
    // })
 
  }
  roles = [];
  roleObj = {}
  all_Users = []
  async getAllUserInfo() {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.mainServiceMD.getCurrentMdUser(JSON.stringify(obj));
    console.log(resp, 'all users')
    if (resp['error'] == false) {
      this.all_Users = resp['data']
      await this.getuserforrolemapping()
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Users', 'error')
    }
  }
  userObj: any = {};
  users: any = []
  async getuserforrolemapping() {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.mainServiceMD.getuserforrolemapping(JSON.stringify(obj));
    console.log(resp, 'all users')
    if (resp['error'] == false) {
      this.users = resp.data;
      console.log(this.users);
      for (let i = 0; i < this.users.length; i++) {
        this.userObj[this.users[i]['user_id']] = this.users[i]['emp_name']
        this.users[i]['desc'] = this.users[i]['user_id'] + " - " + this.users[i]['emp_name'] + " - " + this.users[i]['emp_phone_no']
      }
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Users', 'error')
    }
  }
  login_User: any = {}
  tab4c_data: any = []
  async getperformancebyuser() {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.dashService.getperformancebyuser(JSON.stringify(obj));
    console.log(resp, 'getperformancebyuser')
    if (resp['error'] == false) {
      let dummy = resp['data']
      for (let i = 0; i < this.all_Users.length; i++) {
        for (let j = 0; j < dummy.length; j++) {
          if (this.all_Users[i]['role_cd'] == dummy[j]['role_cd'] && this.all_Users[i]['user_id'] == dummy[j]['user_id']) {

            dummy[j]['name'] = this.userObj[this.all_Users[i]['user_id']]
            dummy[j]['desc'] = this.all_Users[i]['user_id'] + ' - ' + this.userObj[this.all_Users[i]['user_id']] + ' - ' + this.all_Users[i]['role_cd']
            // if (this.ebillUser['role_cd'] == dummy[j]['role_cd'] && this.ebillUser['user_id'] == dummy[j]['user_id']) {
            //   this.login_User = dummy[j]
            // }
            break;
          }
        }
      }
      let resp2 = this.sortArrayOfObjects(dummy, 'days')
      this.tab4c_data = resp2
      for (let i = 0; i < resp2.length; i++) {
        if (i == 0) {
          this.login_User = Object.assign({}, resp2[i])
        }
        if (i < 3) {
          this.chart_data4c.push(resp2[i]['days'])
          this.barChartLabels4c.push(resp2[i]['desc'])
        }
      }
      console.log(this.chart_data4c, this.barChartLabels4c, this.login_User, 'loginUser', 'getperformancebyuser')
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Performance By User Data', 'error')
    }
  }
  emb_exp_byWork: any = []
  async getEmbExpenseByWork() {  // ebill info
    let obj: any = {}
    console.log(this.allAssignedWorkId)
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    } this.spinner.show();
    var resp = await this.dashService.getembexpencebywork(JSON.stringify(obj));
    console.log(resp, 'getembexpencebywork')
    if (resp['error'] == false) {
      this.spinner.hide()
      this.emb_exp_byWork = resp['data']
      for (let i = 0; i < this.allWorkData.length; i++) {
        for (let j = 0; j < this.emb_exp_byWork.length; j++) {
          if (this.allWorkData[i]['id'] == this.emb_exp_byWork[j]['work_id']) {
            this.emb_exp_byWork[j]['budget_cd'] = this.allWorkData[i]['budget_cd']
            this.emb_exp_byWork[j]['budget_name'] = this.allWorkData[i]['budget_name']
            this.emb_exp_byWork[j]['project_cd'] = this.allWorkData[i]['project_cd']
            this.emb_exp_byWork[j]['work_order_value'] = Number(this.allWorkData[i]['work_order_value'])
            this.emb_exp_byWork[j]['budget_value'] = this.commonS.BudgetCodeToValue[this.allWorkData[i]['budget_cd']]
            this.emb_exp_byWork[j]['work_order_no'] = this.allWorkData[i]['work_order_no']
          }
        }
      }
      //console.log(this.emb_exp_byWork, 'emb_exp_byWork')

    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  async getEmbExpense() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    } this.spinner.show();
    var resp = await this.dashService.getembexpence(JSON.stringify(obj));
    //console.log(resp, 'getEmbExpense')
    if (resp['error'] == false) {
      if (resp['data'][0]['amount_bill']) {
        this.emb_expence = (resp['data'][0]['amount_bill']).toFixed(2)
      }
      this.spinner.hide()
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }

  ebill_exp_byWork: any = []
  async getebillExpenseByWork() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    } this.spinner.show();
    var resp = await this.dashService.getebillexpencebywork(JSON.stringify(obj));
    //console.log(resp, 'getebillexpencebywork')
    if (resp['error'] == false) {
      this.spinner.hide()
      this.ebill_exp_byWork = resp['data']
      for (let i = 0; i < this.allWorkData.length; i++) {
        for (let j = 0; j < resp['data'].length; j++) {
          if (this.allWorkData[i]['id'] == this.ebill_exp_byWork[j]['work_id']) {
            this.ebill_exp_byWork[j]['budget_cd'] = this.allWorkData[i]['budget_cd']
            this.ebill_exp_byWork[j]['budget_name'] = this.allWorkData[i]['budget_name']
            this.ebill_exp_byWork[j]['project_cd'] = this.allWorkData[i]['project_cd']
            this.ebill_exp_byWork[j]['work_order_value'] = this.allWorkData[i]['work_order_value']
            this.ebill_exp_byWork[j]['work_order_no'] = this.allWorkData[i]['work_order_no']
            this.ebill_exp_byWork[j]['budget_value'] = this.commonS.BudgetCodeToValue[this.allWorkData[i]['budget_cd']]
          }
        }
      }
      for (let i = 0; i < this.emb_exp_byWork.length; i++) {
        this.emb_exp_byWork[i]['amt'] = (this.emb_exp_byWork[i]['work_order_value'] / this.emb_exp_byWork[i]['amount']) * 100
      }
      let res = this.sortArrayOfObjects(this.emb_exp_byWork, "amt");

      for (let i = 0; i < this.ebill_exp_byWork.length; i++) {
        this.ebill_exp_byWork[i]['amt'] = (this.ebill_exp_byWork[i]['work_order_value'] / this.ebill_exp_byWork[i]['amount']) * 100
      }
      console.log(res, 'wwwwwwwwwwwwwwwwwwwwwwwwwww')
      for (let i = 0; i < res.length; i++) {
        if (i < 3) {
          this.chartData_3a.push(res[i]['amt'].toFixed(2))
          this.barChartLabels2b.push(res[i]['work_order_no'])
        }
      }
      let res2 = this.sortArrayOfObjects(this.ebill_exp_byWork, "amt");
      //console.log(res2, 'wwwwwwwwwwwwwwwwwwwwwwwwwwwsssssssSSSSSSSSSSSSSSSSSSSSSSSSs')

      for (let i = 0; i < res2.length; i++) {
        if (i < 3) {
          this.chartData_3b.push(res2[i]['amt'].toFixed(2))
        }
      }
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  async getebillExpense() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    } this.spinner.show();
    var resp = await this.dashService.getebillexpence(JSON.stringify(obj));
    console.log(resp, 'getebillExpense')
    if (resp['error'] == false) {
      this.spinner.hide()
      if (resp['data'][0]['amount_bill']) {
        this.bill_expence = (resp['data'][0]['amount_bill']).toFixed(2)
      }
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  allApproved_EMB = []
  async getAllApproved_EMB() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    } this.spinner.show();
    var resp = await this.dashService.getAllApprovedEMB(JSON.stringify(obj));
    //console.log(resp, 'approvedEMP')
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allApproved_EMB = resp['data']
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  allApproved_EBILL = []
  running_late_data = []
  async getAllApproved_BILL() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    } this.spinner.show();
    var resp = await this.dashService.getAllApprovedEBILL(JSON.stringify(obj));
    //console.log(resp, 'approvedEBILL')
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log(resp)
      this.allApproved_EBILL = resp['data']
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  all_late_work: any = []
  late_work_id: any = []
  late_work_arr: any = []
  vworkarrformodal: any = []
  async get_late_work() {  // ebill info
    let obj: any = {}
    
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    } this.spinner.show();
    var resp = await this.dashService.get_late_work(JSON.stringify(obj));
    //console.log(resp, 'get late work')
    if (resp['error'] == false) {
      this.spinner.hide()
      this.all_late_work = resp['data']
      for (let i = 0; i < this.all_late_work.length; i++) {
        if (this.all_late_work[i]['days'] < 0) {
          this.all_late_work.splice(i, 1)
        }
      }
      let res = this.sortArrayOfObjects(this.all_late_work, "days");
      this.running_late_data = res
      //console.log(this.running_late_data, 'running_late_data 00000000000000000000000000000000000')
      this.running_late = res.length
      for (let i = 0; i < this.running_late_data.length; i++) {
        for (let j = 0; j < this.allWorkData.length; j++) {
          if (this.allWorkData[j]['id'] == this.running_late_data[i]['work_id']) {
            this.running_late_data[i]['work_order_no'] = this.allWorkData[j]['work_order_no']
            this.running_late_data[i]['work_order_name'] = this.allWorkData[j]['work_order_name']
            this.running_late_data[i]['work_order_value'] = this.allWorkData[j]['work_order_value']
            this.running_late_data[i]['start_date'] = this.allWorkData[j]['start_date']
            this.running_late_data[i]['end_date'] = this.allWorkData[j]['end_date']
            // this.running_late_data[i]['work_order_no']=this.allWorkData[j]['work_order_no']
          }
        }
      }
      for (let i = 0; i < res.length; i++) {
        var Obb = Object()
        Obb['work_id'] = res[i]['work_id']
        if (i < 3) {

          this.late_work_id.push(res[i]['work_id'])
          this.chartData_2a.push(res[i]['days'])
          for (let k = 0; k < this.allWorkData.length; k++) {
            if (res[i]['work_id'] == this.allWorkData[k]['id']) {
              this.late_work_arr.push(this.allWorkData[k])
              this.barChartLabels2a.push(this.allWorkData[k]['work_order_no'])
            }


          }
        }
      }
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  rauuning_late_by_per: any = []
  async getlateworkamt() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.late_work_id.length > 0) {
      obj['work_arr'] = this.late_work_id
    } else {
      obj['work_arr'] = [0]
    } this.spinner.show();
    //console.log(obj, 'obj to send')
    var resp = await this.dashService.getLateWorkAmt(JSON.stringify(obj));
    //console.log(resp, 'late work amount')
    if (resp['error'] == false) {
      this.spinner.hide()
      let res = resp['data']
      for (let i = 0; i < res.length; i++) {
        res[i]['amt'] = (res[i]['billamt'] / res[i]['work_amt']) * 100
      }
      let res2 = this.sortArrayOfObjects(res, "amt")
      this.rauuning_late_by_per = res2
      for (let i = 0; i < this.rauuning_late_by_per.length; i++) {
        for (let j = 0; j < this.allWorkData.length; j++) {
          if (this.allWorkData[j]['id'] == this.rauuning_late_by_per[i]['work_id']) {
            this.rauuning_late_by_per[i]['work_order_no'] = this.allWorkData[j]['work_order_no']
            this.rauuning_late_by_per[i]['work_order_name'] = this.allWorkData[j]['work_order_name']
            this.rauuning_late_by_per[i]['work_order_value'] = this.allWorkData[j]['work_order_value']
            this.rauuning_late_by_per[i]['start_date'] = this.allWorkData[j]['start_date']
            this.rauuning_late_by_per[i]['end_date'] = this.allWorkData[j]['end_date']
            // this.rauuning_late_by_per[i]['work_order_no']=this.allWorkData[j]['work_order_no']
          }
        }
      }
      for (let i = 0; i < res2.length; i++) {
        if (i < 3) {
          this.late_work_id.push(res2[i]['work_id'])
          this.chart_data2b.push(res2[i]['amt'])
        }
      }
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  dev_work_for_modal: any = []
  dev_late_work: any = []
  async getDevAmt() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    } this.spinner.show();
    //console.log(obj, 'obj to send')
    var resp = await this.dashService.getdevamt(JSON.stringify(obj));
    //console.log(resp, 'dev amt')
    if (resp['error'] == false) {
      this.spinner.hide()
      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['per'] = ((resp['data'][i]['dev_amt'] / resp['data'][i]['amount']) * 100) - 100
      }
      let res = this.sortArrayOfObjects(resp['data'], "per");
      this.dev_late_work = res
      for (let i = 0; i < this.dev_late_work.length; i++) {
        for (let j = 0; j < this.allWorkData.length; j++) {
          if (this.allWorkData[j]['id'] == this.dev_late_work[i]['work_id']) {
            this.dev_late_work[i]['work_order_no'] = this.allWorkData[j]['work_order_no']
            this.dev_late_work[i]['work_order_name'] = this.allWorkData[j]['work_order_name']
            this.dev_late_work[i]['work_order_value'] = this.allWorkData[j]['work_order_value']
            this.dev_late_work[i]['start_date'] = this.allWorkData[j]['start_date']
            this.dev_late_work[i]['end_date'] = this.allWorkData[j]['end_date']
            // this.rauuning_late_by_per[i]['work_order_no']=this.allWorkData[j]['work_order_no']
          }
        }
      }
      //console.log(this.dev_late_work, 'dev late workxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
      this.dev_work_for_modal = res
      console.log(res, 'ccccccccccccccccccccc')
      for (let i = 0; i < res.length; i++) {
        // this.late_work_id.push(res[i]['work_id']);
        if (i < 3) {
          this.chart_data2c.push(res[i]['per'])
          for (let k = 0; k < this.allWorkData.length; k++) {
            if (res[i]['work_id'] == this.allWorkData[k]['id']) {
              this.barChartLabels2c.push(this.allWorkData[k]['work_order_no'])
            }
          }
        }
      }
      //console.log(this.chart_data2c, this.barChartLabels2c, 'ccccccccccccccccccccc')
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }

  sortArrayOfObjects = (arr: any, key: any) => {
    return arr.sort((a: any, b: any) => {
      return b[key] - a[key];
    });
  };

  modalOpen(c: any) {
    $('#myModal1').modal('show');

  }
  modalOpen2(c: any) {
    $('#myModal2').modal('show');
  }
  modalOpen3a(c: any) {
    $('#myModal3a').modal('show');

  }
  modalOpen3b(c: any) {
    $('#myModal3b').modal('show');
  }
  modalOpen2a(c: any) {
    $('#myModal2a').modal('show');

  }
  myModal2ab1() {
    $('#myModal2ab1').modal('show');

  }
  modalOpen2b(c: any) {
    $('#myModal2b').modal('show');

  }
  modalOpen2ba() {
    $('#myModal2ba').modal('show');

  }
  myModal2ab2() {
    $('#myModal2ab2').modal('show');

  }
  modalOpen2bb() {
    $('#myModal2bb').modal('show');

  }
  modalOpen2bc() {
    $('#myModal2bc').modal('show');

  }
  modalOpen4a() {
    $('#myModal4a').modal('show');

  }
  modalOpen4c() {
    $('#myModal4c').modal('show');

  }
  modalOpen4b() {
    $('#myModal4b').modal('show');

  }
  work_list: any = []
  modalOpen3(c: any) {

    this.work_list = []
    for (let i = 0; i < this.allWorkData.length; i++) {
      if (this.allAssignedWorkId.includes(this.allWorkData[i]['id'])) {
        this.work_list.push(this.allWorkData[i])
      }
    }
    $('#myModal3').modal('show');

  }
  click(event: any, chartContext: any, config: any) {
    console.log(event, chartContext, config);//.seriesIndex
    console.log(config); //.dataPointIndex
  }

  async getparty() {
    var resp = await this.MasterDataService.getparty(this.b_acct_id);
    //console.log(resp, 'party')
    if (resp['error'] == false) {
      this.party = resp.data;
      this.party_count = this.party.length
    }

  }
  public chartClicked1(e: any): void {
    this.showArr1 = []
    $('#myModal1').modal('show');
  }
  allworklist: any = {}
  async getwork() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MasterDataService.getWorkInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        this.allworklist[resp['data'][i]['id']] = resp['data'][i]

      }
    }
  }
  not_started_data: any = []
  in_progress_data: any = []
  in_progress_count = 0
  completed_data: any = []
  async getworklist() {
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['work_arr'] = this.allAssignedWorkId
    await this.getwork()
    let cmp: any = []
    var resp = await this.dashService.getworklist(JSON.stringify(obj));
    //console.log(resp, 'party of getworklist')
    if (resp['error'] == false) {
      let data = resp['data']
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.allWorkData.length; j++) {
          if (data[i]['work_id'] == this.allWorkData[j]['id']) {
            cmp.push(this.allWorkData[j]['id'])
            data[i]['end_date'] = this.allWorkData[j]['end_date']
            data[i]['start_date'] = this.allWorkData[j]['start_date']
            data[i]['budget_cd'] = this.allWorkData[j]['budget_cd']
            data[i]['budget_name'] = this.allWorkData[j]['budget_name']
            data[i]['work_order_no'] = this.allWorkData[j]['work_order_no']
            data[i]['work_order_value'] = this.allWorkData[j]['work_order_value']
          }
        }
      }
      let work_data: any = this.allWorkData
      for (let i = 0; i < work_data.length; i++) {
        if (!cmp.includes(work_data[i]['id']) && this.allAssignedWorkId.includes(work_data[i]['id'])) {
          this.not_started_data.push(work_data[i])
        }
      }
      //console.log(data, 'fffffffffffffffffffffffffffff')
      let in_progress = []
      let completed = []
      for (let i = 0; i < data.length; i++) {
        if (data[i]['final_ind'] == 0) {
          in_progress.push(data[i])
          data[i]['msg'] = 'In Progress'
        }
        if (data[i]['final_ind'] == 1)
          completed.push(data[i])
        data[i]['msg'] = 'Completed'
      }
      let dummy = []
      this.in_progress_data = in_progress
      this.in_progress_count = in_progress.length
      //console.log(completed, in_progress, 'jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj')
      let sum: any = {}
      sum['in_progress'] = 0
      sum['in_complete'] = 0
      sum['not_started'] = 0
      sum['progress_on_time'] = 0
      sum['progress_late'] = 0
      sum['completed_on_time'] = 0
      sum['completed_late'] = 0
      this.data_chart1 = []
      this.g_sum = sum
      let obj: any = {}
      obj['x'] = 'All Work' + ' - ' + Object.keys(this.assignedWorks).length
      obj['y'] = Object.keys(this.assignedWorks).length
      dummy.push(obj)
      let obj2: any = {}
      let obj3: any = {}
      let obj4: any = {}
      let obj5: any = {}
      let obj6: any = {}
      let obj7: any = {}
      let obj8: any = {}
      console.log(in_progress)
      console.log(completed)
      var flagarrin = []

      for (let i = 0; i < in_progress.length; i++) {
        flagarrin.push(in_progress[i]['work_id'])
        let start_time = new Date(in_progress[i]['start_date'])
        let end_time = new Date(in_progress[i]['end_date'])
        let timestamp = new Date(in_progress[i]['timestamp'])
        if (timestamp.getTime() <= end_time.getTime()) {
          sum['progress_on_time'] = sum['progress_on_time'] + 1
        }
        if (timestamp.getTime() > end_time.getTime()) {
          sum['progress_late'] = sum['progress_late'] + 1
        }
      }
      var flagarrcomp = []
      for (let i = 0; i < completed.length; i++) {
        flagarrcomp.push(completed[i]['work_id'])
        let start_time = new Date(completed[i]['start_date'])
        let end_time = new Date(completed[i]['end_date'])
        let timestamp = new Date(completed[i]['timestamp'])
        if (timestamp.getTime() <= end_time.getTime()) {
          sum['completed_on_time'] = sum['completed_on_time'] + 1
        }
        if (timestamp.getTime() > end_time.getTime()) {
          sum['completed_late'] = sum['completed_late'] + 1
        }
      }
      this.completed_data = completed
      var sumstart: any = {}
      sumstart['completed_on_time'] = 0
      sumstart['completed_late'] = 0
      console.log(this.allAssignedWorkId)
      for (let i = 0; i < this.allAssignedWorkId.length; i++) {
        if (!flagarrin.includes(this.allAssignedWorkId[i]) && !flagarrcomp.includes(this.allAssignedWorkId[i])) {
          console.log(this.allAssignedWorkId[i])
          let end_time = new Date(this.allworklist[this.allAssignedWorkId[i]]['end_date'])
          let timestamp = new Date();
          console.log(this.allworklist[this.allAssignedWorkId[i]]['end_date'])
          if (timestamp.getTime() <= end_time.getTime()) {
            sumstart['completed_on_time'] += 1
          }
          if (timestamp.getTime() > end_time.getTime()) {
            sumstart['completed_late'] = sumstart['completed_late'] + 1
          }
        }

      }
      obj2['x'] = 'In Progress' + ' - ' + in_progress.length + ' ( On Time ' + sum['progress_on_time'] + ' and late ' + sum['progress_late'] + ')'
      obj2['y'] = in_progress.length
      obj3['x'] = 'Completed' + ' - ' + completed.length + ' ( On Time ' + sum['completed_on_time'] + ' and late ' + sum['completed_late'] + ')'
      obj3['y'] = completed.length
      obj4['y'] = Object.keys(this.assignedWorks).length - (in_progress.length + completed.length) + ' ( On Time ' + sumstart['completed_on_time'] + ' and late ' + sumstart['completed_late'] + ')'
      obj4['x'] = 'Not Started' + ' - ' + obj4['y']

      obj5['x'] = 'Progress On Time'
      obj5['y'] = sum['progress_on_time']
      obj6['x'] = 'Progress On Late '
      obj6['y'] = sum['progress_late']
      obj7['x'] = 'Completed On Time'
      obj7['y'] = sum['completed_on_time']
      obj8['x'] = 'Completed On Late '
      obj8['y'] = sum['completed_late']
      dummy.push(obj4)
      dummy.push(obj2)
      dummy.push(obj3)
      this.data_chart1 = []
      this.data_chart1 = dummy


    }

  }
  workObj: any = {}
  assignedWorks: any = {}

  async getAllHeadRecord() {
    this.spinner.show();
    var work_ids: any = []
    this.allAssignedWorkId = [];
    work_ids = Object.values(this.commonS.assignedWork);
    for (var i = 0; i < work_ids.length; i++) {
      this.assignedWorks[work_ids[i]] = 1;
      this.work_count = this.work_count + 1
      if (work_ids[i] != undefined) {
        this.allAssignedWorkId.push(work_ids[i]);
      }
      console.log(this.assignedWorks)
    }
    if (this.allAssignedWorkId.length == 0) {
      this.allAssignedWorkId.push(0);
    }
  }
  async getembapprovaldays() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    this.spinner.show();
    var resp = { error: false, data: [] }//await this.dashService.getembapprovaldays(JSON.stringify(obj));
    //console.log(resp, 'getemb')
    this.data_chart3_a = resp['data']
    if (resp['error'] == false) {
      if (this.data_chart3_a.length > 0) {
        let d1: any = {}
        d1['x'] = 'EMB' + '(' + await (await this.Display(this.data_chart3_a)).toFixed(2) + ' Days)'
        d1['y'] = [0, Number(await (await this.Display(this.data_chart3_a)).toFixed(2))]
        this.data_chart3.push(d1)
      } else {
        this.data_chart3.push({
          x: "EMB(0 Days)",
          y: [0, 0]
        })

      }
      this.spinner.hide()
      //console.log(resp)
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  workmodal = []
  async getfinancialexpencebywork() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    } //console.log(obj, 'obj')
    this.spinner.show();
    var resp = await this.dashService.getfinancialexpencebywork(JSON.stringify(obj));
    //console.log(resp, '3a chart')
    if (resp['error'] == false) {
      this.spinner.hide()
      for (let i = 0; i < this.allWorkData.length; i++) {
        for (let j = 0; j < resp['data'].length; j++) {
          if (this.allWorkData[i]['id'] == resp['data'][j]['work_id']) {
            resp['data'][j]['budget_cd'] = this.allWorkData[i]['budget_cd']
            resp['data'][j]['budget_name'] = this.allWorkData[i]['budget_name']
            resp['data'][j]['project_cd'] = this.allWorkData[i]['project_cd']
            resp['data'][j]['work_order_no'] = this.allWorkData[i]['work_order_no']
            resp['data'][j]['budget_value'] = this.commonS.BudgetCodeToValue[this.allWorkData[i]['budget_cd']]
          }
        }
      }
      let res = this.sortArrayOfObjects(resp['data'], "amount");
      console.log(res, 'budget value')
      this.workmodal = res
      for (let i = 0; i < res.length; i++) {
        if (i < 3) {
          this.chartData_1[i] = res[i]['budget_value']
          this.chartData_2[i] = res[i]['amount']
          this.barChartLabels[i] = res[i]['work_order_no']
        }
      }
      //console.log(this.chartData_2, this.chartData_2, 'budget value')

    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  boqamt = 0
  async getfinance() {
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    }
    //console.log(obj, 'obj')
    this.spinner.show();
    var resp = await this.dashService.getboqamount(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log(resp)
      this.boqamt = resp['data'][0]['SUM((ti.eff_quantity+ti.already_measured)*ti.eff_rate)']
    } else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  async getFinancialExpence() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    if (this.allAssignedWorkId.length > 0) {
      obj['work_arr'] = this.allAssignedWorkId
    } else {
      obj['work_arr'] = [0]
    }
    //console.log(obj, 'obj')
    this.spinner.show();
    await this.getfinance()
    var resp = await this.dashService.getFinancialExpence(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      let work_amt_sum = 0
      for (var i = 0; i < this.allWorkData.length; i++) {
        this.workObj[this.allWorkData[i]['id']] = this.allWorkData[i];
        if (this.allAssignedWorkId.includes(this.allWorkData[i]['id'])) {
          work_amt_sum = work_amt_sum + Number(this.allWorkData[i]['work_order_value'])

        }
      }
      this.chart_data1b[0] = Number(this.boqamt)
      this.barChartLabels2_1[0] = 'Work Order';

      this.chart_data1b[1] = Number(resp['data'][0]['amount'])
      this.barChartLabels2_1[1] = 'Work';
      this.work_amt = Number(work_amt_sum.toFixed(2))
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  data_chart3: any = [

  ]


  async getebillapprovaldays() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    this.spinner.show();
    var resp = await this.dashService.getebillapprovaldays(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp['data'].length > 0) {

        let d2: any = {}
        d2['x'] = 'EBILL' + '(' + await (await this.Display(resp['data'])).toFixed(2) + ' Days)'
        d2['y'] = [Number(await (await this.Display(this.data_chart3_a)).toFixed(2)),
        Number(await (await this.Display(resp['data'])).toFixed(2))]
        this.data_chart3.push(d2)
        this.spinner.hide()
      } else {
        this.data_chart3.push({
          x: "EBILL(0 Days)",
          y: [0, 0]
        })

      }


    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }
  async Display(minarr: any) {



    var days = 0
    var flagarr: any = []
    for (let i = 0; i < minarr.length; i++) {
      if (!flagarr.includes(minarr[i]['doc_local_no'])) {
        var date1 = new Date(minarr[i]['timestmin']);
        var date2 = new Date(minarr[i]['timestmax']);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        days = days + Difference_In_Days
      }
    }

    return days / minarr.length
  }

  async getBillInfo() {  // ebill info
    let obj: any = {}
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['work_arr'] = this.allAssignedWorkId
    this.spinner.show();
    var resp = await this.dashService.getallebill(JSON.stringify(obj));
    //console.log(resp, 'bill info')
    if (resp['error'] == false) {
      this.spinner.hide()
      this.ebill_count = resp['data'].length
      this.billInfo = resp['data'];
      //console.log(this.billInfo, 'bill info')
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
  }

  g_sum = {}
  inprogress_work_arr = []
  async getAllWorkInfo() {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    var resp = await this.MasterDataService.getWorkInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allWorkData = resp['data']
      //console.log(this.allWorkData, 'all work data', this.allAssignedWorkId)
    } else {
      this.spinner.hide()
    }
  }

  avg_emb_apr_days: any = 0
  async getemdmeasurementtoapprovaldays() {  // ebill info
    let obj: any = {}
    //console.log(this.allAssignedWorkId, 'assiened works')
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['work_arr'] = this.allAssignedWorkId
    this.spinner.show();
    var resp = await this.dashService.getemdmeasurementtoapprovaldays(JSON.stringify(obj));

    //console.log()
    if (resp['error'] == false) {
      this.spinner.hide()
      //console.log(resp)
      this.avg_emb_apr_days = await this.getemdmeasurementtoapprovaldays_display(resp['data'])
      this.avg_emb_apr_days = this.avg_emb_apr_days.toFixed(2)
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
    if (this.avg_emb_apr_days > 0) {
      this.avg_emb_apr_days = this.avg_emb_apr_days
    } else {
      this.avg_emb_apr_days = 0
    }
  }
  data_for_4a_tab: any = []
  async getemdmeasurementtoapprovaldays_display(minarr: any) {



    var days = 0
    var flagarr: any = []
    for (let i = 0; i < minarr.length; i++) {
      if (!flagarr.includes(minarr[i]['doc_local_no'])) {
        var date1 = new Date(minarr[i]['timestmin']);
        var date2 = new Date(minarr[i]['timestmax']);
        //console.log(date1, date2)
        //console.log(minarr[i]['doc_local_no'])
        var Difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        // //console.log(Difference_In_Days)
        minarr[i]['days'] = Difference_In_Days
        days = days + Difference_In_Days
      }/* else{
            var Difference_In_Time = date2.getTime() - date1.getTime();

            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        } */


    }

    var data: any = {};
    var arr: any = []
    for (let i = 0; i < minarr.length; i++) {
      if (!arr.includes(minarr[i]['work_id'])) {
        arr.push(minarr[i]['work_id'])
        var obj = Object.assign({}, minarr[i]);
        obj['count'] = 1;
        obj['total_days'] = minarr[i]['days']
        data[minarr[i]['work_id']] = obj
      } else {
        data[minarr[i]['work_id']]['count']++
        data[minarr[i]['work_id']]['total_days'] = data[minarr[i]['work_id']]['total_days'] + minarr[i]['days']
      }

    }

    var res_data: any = Object.values(data);

    for (let i = 0; i < res_data.length; i++) {
      res_data[i]['avg_days'] = res_data[i]['total_days'] / res_data[i]['count']
    }

    let res = this.sortArrayOfObjects(res_data, "avg_days");
    for (let i = 0; i < this.allWorkData.length; i++) {
      for (let j = 0; j < res.length; j++) {
        if (this.allWorkData[i]['id'] == res[j]['work_id']) {
          res[j]['budget_cd'] = this.allWorkData[i]['budget_cd']
          res[j]['budget_name'] = this.allWorkData[i]['budget_name']
          res[j]['project_cd'] = this.allWorkData[i]['project_cd']
          res[j]['work_order_no'] = this.allWorkData[i]['work_order_no']
          res[j]['budget_value'] = this.commonS.BudgetCodeToValue[this.allWorkData[i]['budget_cd']]
        }
      }
    }
    this.data_for_4a_tab = res
    for (let i = 0; i < this.allWorkData.length; i++) {
      for (let j = 0; j < res.length; j++) {
        if (this.allWorkData[i]['id'] == res[j]['work_id']) {
          res[j]['budget_cd'] = this.allWorkData[i]['budget_cd']
          res[j]['budget_name'] = this.allWorkData[i]['budget_name']
          res[j]['project_cd'] = this.allWorkData[i]['project_cd']
          res[j]['work_order_no'] = this.allWorkData[i]['work_order_no']
          res[j]['budget_value'] = this.commonS.BudgetCodeToValue[this.allWorkData[i]['budget_cd']]
        }
      }
    }
    this.data_for_4a_tab = res
    //console.log(res, 'dddddddddddddddddddddddddddddddddddddd');
    //console.log(data)
    for (let i = 0; i < res.length; i++) {

      if (i < 3) {
        for (let k = 0; k < this.allWorkData.length; k++) {
          if (res[i]['work_id'] == this.allWorkData[k]['id']) {
            this.chart_data4a.push(res[i]['avg_days'])
            this.chart_data4a_lebel.push(this.allWorkData[k]['work_order_no'])
          }
        }
      }
    }
    //console.log(this.chart_data4a, this.chart_data4a_lebel, 'qqqqqqqqqqqqqqqqqqqqqqqqqqqqq')


    return days / minarr.length
  }
  avg_ebilll_apr_days: any = 0

  async getebillmeasurementtoapprovaldays() {  // ebill info
    let obj: any = {}
    //console.log(this.allAssignedWorkId, 'assiened works')
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['work_arr'] = this.allAssignedWorkId
    this.spinner.show();
    var resp = await this.dashService.getebillmeasurementtoapprovaldays(JSON.stringify(obj));
    //console.log(resp, 'rrrrrrrrrrrrrrrrrrrrrrrrr')
    if (resp['error'] == false) {
      this.spinner.hide()
      //console.log(resp)
      this.avg_ebilll_apr_days = await this.getebillmeasurementtoapprovaldays_display(resp['data'])
      //console.log(this.chart_data4b, this.chart_data4b_lebel, 'lebel and data')
      this.avg_ebilll_apr_days = this.avg_ebilll_apr_days.toFixed(2)

    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }
    if (this.avg_ebilll_apr_days > 0) {
      this.avg_ebilll_apr_days = this.avg_ebilll_apr_days
    } else {
      this.avg_ebilll_apr_days = 0
    }
  }
  data_for_4b_tab: any = []
  async getebillmeasurementtoapprovaldays_display(minarr: any) {
    var days = 0
    var flagarr: any = []
    for (let i = 0; i < minarr.length; i++) {
      if (!flagarr.includes(minarr[i]['doc_local_no'])) {
        var date1 = new Date(minarr[i]['timestmin']);
        var date2 = new Date(minarr[i]['timestmax']);
        //console.log(date1, date2)
        //console.log(minarr[i]['doc_local_no'])
        var Difference_In_Time = date2.getTime() - date1.getTime();
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        minarr[i]['days'] = Difference_In_Days
        //console.log(this.chart_data4b, this.chart_data4b_lebel, 'lebel and data')
        days = days + Difference_In_Days
      }
    }

    var data: any = {};
    var arr: any = []
    for (let i = 0; i < minarr.length; i++) {
      if (!arr.includes(minarr[i]['work_id'])) {
        arr.push(minarr[i]['work_id'])
        var obj = Object.assign({}, minarr[i]);
        obj['count'] = 1;
        obj['total_days'] = minarr[i]['days']
        data[minarr[i]['work_id']] = obj
      } else {
        data[minarr[i]['work_id']]['count']++
        data[minarr[i]['work_id']]['total_days'] = data[minarr[i]['work_id']]['total_days'] + minarr[i]['days']
      }

    }

    var res_data: any = Object.values(data);

    for (let i = 0; i < res_data.length; i++) {
      res_data[i]['avg_days'] = res_data[i]['total_days'] / res_data[i]['count']
    }

    let res = this.sortArrayOfObjects(res_data, "avg_days");
    for (let i = 0; i < this.allWorkData.length; i++) {
      for (let j = 0; j < res.length; j++) {
        if (this.allWorkData[i]['id'] == res[j]['work_id']) {
          res[j]['budget_cd'] = this.allWorkData[i]['budget_cd']
          res[j]['budget_name'] = this.allWorkData[i]['budget_name']
          res[j]['project_cd'] = this.allWorkData[i]['project_cd']
          res[j]['work_order_no'] = this.allWorkData[i]['work_order_no']
          res[j]['budget_value'] = this.commonS.BudgetCodeToValue[this.allWorkData[i]['budget_cd']]
        }
      }
    }
    this.data_for_4b_tab = res
    for (let i = 0; i < res.length; i++) {
      if (i < 3) {
        for (let k = 0; k < this.allWorkData.length; k++) {
          if (res[i]['work_id'] == this.allWorkData[k]['id']) {
            this.chart_data4b.push(res[i]['avg_days'])
            this.chart_data4b_lebel.push(this.allWorkData[k]['work_order_no'])
          }
        }
      }
    }
    return days / minarr.length
  }


  refresh() {


  }


  work_order_name: any

  physicaltab() {
    console.log('hello')
    $('.nav-tabs a[href="#tab-2"]').tab('show');
  }
  financialtab() {
    $('.nav-tabs a[href="#tab-3"]').tab('show');
  }
  performancetab() {
    $('.nav-tabs a[href="#tab-4"]').tab('show');
  }
  chart1_list = []
  chart1_list_msg = ''
  setChart1ModalData(data: any) {
    this.chart1_list = []
    //console.log(data, this.allWorkData)
    let arr = data['x'].split(' - ')
    console.log(arr, 'arr')
    console.log(this.allWorkData, data, this.allAssignedWorkId)
    if (arr[0] == 'All Work') {
      if (this.allAssignedWorkId.includes('1')) {
        console.log(arr[0], 'arr')

      }
      this.chart1_list_msg = 'All Works'
      for (let i = 0; i < this.allWorkData.length; i++) {
        console.log(this.allWorkData[i]['id'])
        if (this.allAssignedWorkId.includes(this.allWorkData[i]['id']).toString()) {
          console.log(arr[0], 'arr')
          this.chart1_list.push(this.allWorkData[i])
        }
      }
      // this.chart1_list = this.allWorkData
    } else if (arr[0] == 'Not Started') {
      this.chart1_list_msg = 'Not Started Work'
      this.chart1_list = this.not_started_data

    }
    else if (arr[0] == 'In Progress') {
      this.chart1_list_msg = 'In Progress Work'
      for (let i = 0; i < this.allWorkData.length; i++) {
        for (let j = 0; j < this.in_progress_data.length; j++) {
          if (this.in_progress_data[j]['work_id'] == this.allWorkData[i]['id']) {
            this.in_progress_data[j]['work_order_name'] = this.allWorkData[i]['work_order_name']
          }
        }
      }
      this.chart1_list = this.in_progress_data
    }
    else if (arr[0] == 'Completed') {
      this.chart1_list_msg = 'Completed Work'
      for (let i = 0; i < this.allWorkData.length; i++) {
        for (let j = 0; j < this.completed_data.length; j++) {
          if (this.completed_data[j]['work_id'] == this.allWorkData[i]['id']) {
            this.completed_data[j]['work_order_name'] = this.allWorkData[i]['work_order_name']
          }
        }
      }
      this.chart1_list = this.completed_data
    }
    //console.log(this.chart1_list)
    $('#myModalChart11').modal('show');
  }
}
