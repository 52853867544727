import { Injectable } from '@angular/core';
import { MainService } from './portal/service/main.service'
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public mainService: MainService,) { }

  erpUser:any;
  allComponentCode:any = [];
  public async isAuthenticated() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.allComponentCode = this.erpUser.assigned_component;

    if (this.allComponentCode == undefined || this.allComponentCode.length == 0) {
      this.allComponentCode = await this.getAllAssignedComponent(this.erpUser.b_acct_id, this.erpUser.role_cd);
      var erpUser1 = JSON.parse(atob(localStorage.getItem('erpUser') as any));
      erpUser1['assigned_component'] = this.allComponentCode;
      localStorage.setItem('erpUser',btoa(JSON.stringify(erpUser1)));
    }
    let ComponentCode:any = this.mainService.componentCode;
    if (this.allComponentCode.indexOf(ComponentCode) < 0) {
      return false;
    } else {
      return true;
    }

  }

  async getAllAssignedComponent(b_acct_id:any, role_cd:any) {
    let obj:any = new Object();
    obj['b_acct_id'] = b_acct_id;
    obj['role_cd'] = role_cd;
    var allComponentCode1 = [];
    var resp = await this.mainService.getAllAssignedComponents(JSON.stringify(obj));
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        allComponentCode1.push(resp['data'][i]['resource_cd'])
      }
    } else {

    }
    return allComponentCode1;


  }
}
